import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const phoneMessagesRoute: ICCRoute = {
  path: "phone-messages",
  name: "Phone Messages",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_PhoneMessages,
  children: [
    {
      path: "open-messages",
      name: "Open Messages",
      enum: CRMSViews.PhoneMessages_Open,
      component: require("./open-messages/_index").default,
    },
    {
      path: "all-messages",
      name: "All Messages",
      enum: CRMSViews.PhoneMessages_All,
      component: require("./all-messages/_index").default,
    },
    {
      path: ":id([0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
