import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IODataResponse } from "@common/models/odataResponse";
import { CancelToken } from "axios";

export const loadSearchComboBoxWithoutFilter = async <T extends {}>(
  urlAPI: string,
  filter: string,
  keySearch: string,
  cancelToken: CancelToken
): Promise<APIResponse<IODataResponse<T> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `${urlAPI}?${keySearch}=${filter}`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const loadSearchComboBox = async <T extends {}>(
  urlAPI: string,
  filter: string,
  keySearch: string,
  cancelToken: CancelToken
): Promise<APIResponse<IODataResponse<T> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `${urlAPI}?$count=true&$top=200&$skip=0${
        filter ? `&$filter=contains(${keySearch}, '${filter}')` : ""
      }`,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
