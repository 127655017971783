import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { EHelpDocumentUpdateTriggers, ICCHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/model";
import { helpDocumentRoute } from '@common/pages/settings/lookups/help-documents/route';
import { lookupsRoute } from "@common/pages/settings/lookups/route";
import { commonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { IConvertRouteDynamic } from '@common/utils/convertRouteDynamic';
import { isEqual } from "lodash";

export const getBookmarkListViewDisplayName = () => {
  return "Settings - Lookups";
};

export const getBookmarkListViewDetail = () => {
  return "Settings - Lookups - Help Document";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow?.Attachment_ID ? `- ${selectedRow?.Attachment_ID}` : "";
  return `Settings - Help Document ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow?.Attachment_ID + " - " + selectedRow?.Title]);
};

export const produceHelpDocumentSaveTrigger = (
  newHelpDocument: ICCHelpDocument,
  oldHelpDocument?: ICCHelpDocument
): ICCHelpDocument => {
  if (!oldHelpDocument) return newHelpDocument;
  newHelpDocument._SaveTriggers = [];
  if (!isEqual(oldHelpDocument.FileEntry?.FileName, newHelpDocument.FileEntry?.FileName)) {
    newHelpDocument._SaveTriggers
      .push(EHelpDocumentUpdateTriggers.JournalUpdateFile)
  }
  if (!isEqual(oldHelpDocument.Title, newHelpDocument.Title)) {
    newHelpDocument._SaveTriggers
      .push(EHelpDocumentUpdateTriggers.UpdateTitle)
  }
  return newHelpDocument;
};

export const isVisibleHelpDocument = (): boolean => {
  const commonSetting = commonCoreStore.settings;
  return getBoolValueSetting(commonSetting[ECorporateSettingsField.CorporateSettings_EnableHelpDocuments]);
}

export const isHelpDocumentPath = (route: IConvertRouteDynamic): boolean => {
  return route.path.includes(`${lookupsRoute.path}/${helpDocumentRoute.path}`)
}

export const helpDocumentHelper = {
  isHelpDocumentPath,
  isVisibleHelpDocument
}
