import { DATE_FORMAT } from "@common/constants/common-format";
import { Svc_BubbleupIdentifier } from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { useAppealDetailTabStore } from "@common/pages/appeal/_id/components/reference-sidebar/detail/store";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export const AppealDetailTab = observer(() => {
  const {
    loadAppealDetailTab,
    appealBubbleUpParent,
    appealBubbleUpAncestor,
    appealDetailTab,
    isLoading,
  } = useAppealDetailTabStore();
  const { id } = useParams<{ id: string }>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const lastSelectedRowId = lastSelectedRow?.ID;
  const appealId: number = lastSelectedRowId ?? +id;

  const getBubbleupValue = (bubbleUpObj: Svc_BubbleupIdentifier) => {
    return (
      <Link
        className="cc-detail-tab-link"
        to={{
          pathname: getPageLinkByRecordType(
            bubbleUpObj.RecordType_ENUM,
            bubbleUpObj.RecordID
          ),
        }}
      >
        {bubbleUpObj.RecordNumber}
      </Link>
    );
  };

  useEffect(() => {
    if (appealId) {
      loadAppealDetailTab(appealId);
    }
    //eslint-disable-next-line
  }, [lastSelectedRowId, appealId, parent]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!appealDetailTab) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title="Appeal ID:"
              value={appealDetailTab?.Appeal_ID.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Application ID:"
              value={appealDetailTab?.Parent_ID}
              primary
            />
            <ProductReferenceRow
              title="Status:"
              value={appealDetailTab?.StatusName}
              success
            />
            {appealDetailTab?.Ancestor?.lblParent1 !== "" && (
              <>
                <ProductReferenceRow
                  title={`${appealDetailTab.Ancestor.lblParent1}:`}
                  value={sanitizeHtml(appealDetailTab?.Ancestor?.litParent1)}
                />
                <ProductReferenceRow
                  title={`${appealDetailTab?.Ancestor?.lblParent2}:`}
                  value={sanitizeHtml(appealDetailTab?.Ancestor?.litParent2)}
                />
                <ProductReferenceRow
                  title={`${appealDetailTab?.Ancestor?.lblParent3}:`}
                  value={sanitizeHtml(appealDetailTab?.Ancestor?.litParent3)}
                />
              </>
            )}
            {appealBubbleUpAncestor && (
              <ProductReferenceRow
                title={appealBubbleUpAncestor.RecordTypeName}
                value={getBubbleupValue(appealBubbleUpAncestor)}
              />
            )}
            {appealBubbleUpParent && (
              <ProductReferenceRow
                title={appealBubbleUpParent.RecordTypeName}
                value={getBubbleupValue(appealBubbleUpParent)}
              />
            )}
          </ProductReferenceBlock>
        </PanelBarItem>
        {/* Date information panel bar */}
        <PanelBarItem
          title={<ProductReferenceHeading title="Date information:" primary />}
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title="Created:"
              value={formatDisplayValue(
                appealDetailTab?.CreatedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title="Appealed:"
              value={formatDisplayValue(
                appealDetailTab?.DateAppealed,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
