import {
  ESettingsCategoryNameProperty,
  ESettingsCategoryPropertyPathName,
} from "@app/products/property/system-admin/settings/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const listSettingsCategoryNamePropertyPath: ICCRoute[] = [
  {
    path: ESettingsCategoryPropertyPathName.Assessment,
    name: ESettingsCategoryNameProperty.Assessment,
    enum: eMenuAction.SettingsCategory_Assessment,
  },
  {
    path: ESettingsCategoryPropertyPathName.Certificate,
    name: ESettingsCategoryNameProperty.Certificate,
    enum: eMenuAction.SettingsCategory_Certificate,
  },
  {
    path: ESettingsCategoryPropertyPathName.ChangeOfOwnership,
    name: ESettingsCategoryNameProperty.ChangeOfOwnership,
    enum: eMenuAction.SettingsCategory_ChangeOfOwnership,
  },
  {
    path: ESettingsCategoryPropertyPathName.CollectionTypes,
    name: ESettingsCategoryNameProperty.CollectionTypes,
    enum: eMenuAction.SettingsCategory_CollectionTypes,
  },
  {
    path: ESettingsCategoryPropertyPathName.DigitalMailbox,
    name: ESettingsCategoryNameProperty.DigitalMailbox,
    enum: eMenuAction.SettingsCategory_DigitalMailbox,
  },
  {
    path: ESettingsCategoryPropertyPathName.DeferredDuty,
    name: ESettingsCategoryNameProperty.DeferredDuty,
    enum: eMenuAction.SettingsCategory_DeferredDuty,
  },
  {
    path: ESettingsCategoryPropertyPathName.Entity,
    name: ESettingsCategoryNameProperty.Entity,
    enum: eMenuAction.SettingsCategory_Entity,
  },
  {
    path: ESettingsCategoryPropertyPathName.Enquiry,
    name: ESettingsCategoryNameProperty.Enquiry,
    enum: eMenuAction.SettingsCategory_Enquiry,
  },
  {
    path: ESettingsCategoryPropertyPathName.GIS,
    name: ESettingsCategoryNameProperty.GIS,
    enum: eMenuAction.SettingsCategory_GIS,
  },
  {
    path: ESettingsCategoryPropertyPathName.Integration,
    name: ESettingsCategoryNameProperty.Integration,
    enum: eMenuAction.SettingsCategory_Integration,
  },
  {
    path: ESettingsCategoryPropertyPathName.Journal,
    name: ESettingsCategoryNameProperty.Journal,
    enum: eMenuAction.SettingsCategory_Journal,
  },
  {
    path: ESettingsCategoryPropertyPathName.LeaseTypes,
    name: ESettingsCategoryNameProperty.LeaseTypes,
    enum: eMenuAction.SettingsCategory_LeaseTypes,
  },
  {
    path: ESettingsCategoryPropertyPathName.Meter,
    name: ESettingsCategoryNameProperty.Meter,
    enum: eMenuAction.SettingsCategory_Meter,
  },
  {
    path: ESettingsCategoryPropertyPathName.Notice,
    name: ESettingsCategoryNameProperty.Notice,
    enum: eMenuAction.SettingsCategory_Notice,
  },
  {
    path: ESettingsCategoryPropertyPathName.Organisation,
    name: ESettingsCategoryNameProperty.Organisation,
    enum: eMenuAction.SettingsCategory_Organisation,
  },
  {
    path: ESettingsCategoryPropertyPathName.Options,
    name: ESettingsCategoryNameProperty.Options,
    enum: eMenuAction.SettingsCategory_Options,
  },
  {
    path: ESettingsCategoryPropertyPathName.Parcel,
    name: ESettingsCategoryNameProperty.Parcel,
    enum: eMenuAction.SettingsCategory_Parcel,
  },
  {
    path: ESettingsCategoryPropertyPathName.Register,
    name: ESettingsCategoryNameProperty.Register,
    enum: eMenuAction.SettingsCategory_Register,
  },
  {
    path: ESettingsCategoryPropertyPathName.Report,
    name: ESettingsCategoryNameProperty.Report,
    enum: eMenuAction.SettingsCategory_Report,
  },
  {
    path: ESettingsCategoryPropertyPathName.Scheme,
    name: ESettingsCategoryNameProperty.Scheme,
    enum: eMenuAction.SettingsCategory_Scheme,
  },
  {
    path: ESettingsCategoryPropertyPathName.Title,
    name: ESettingsCategoryNameProperty.Title,
    enum: eMenuAction.SettingsCategory_Title,
  },
  {
    path: ESettingsCategoryPropertyPathName.Tasks,
    name: ESettingsCategoryNameProperty.Tasks,
    enum: eMenuAction.SettingsCategory_Tasks,
  },
  {
    path: ESettingsCategoryPropertyPathName.Workflows,
    name: ESettingsCategoryNameProperty.Workflows,
    enum: eMenuAction.SettingsCategory_Workflows,
  },
  {
    path: ESettingsCategoryPropertyPathName.Documents,
    name: ESettingsCategoryNameProperty.Documents,
    enum: eMenuAction.SettingsCategory_Documents,
  },
];

export const settingsRoute: ICCRoute = {
  path: "settings",
  name: "Settings",
  enum: eMenuAction.SettingsCategory_Menu,
  children: [
    {
      path: "category",
      name: "Category",
      enum: eMenuAction.SettingsCategory_Category,
      children: [
        ...listSettingsCategoryNamePropertyPath,
        {
          path: ":category(assessment|certificate|change-of-ownership|collection-types|digital-mailbox|deferred-duty|entity|enquiry|gis|integration|journal|lease-types|meter|notice|organisation|options|parcel|register|report|scheme|title|tasks|workflows|documents)",
          component: require("./_category/_index").default,
        },
      ],
    },
  ],
};
