import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { BatchRecallDialog } from "@app/core/recall/components/dialogs/batch-recall";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const BatchRecallButtonListView = observer(
  (props: BatchRecallButtonListViewProps) => {
    //#region states
    const [showBatchRecallDialog, setShowBatchRecallDialog] = useState(false);
    //#endregion

    //#region global stores
    const { gridSelectedIds } = useCCProductListViewStore();
    //#endregion

    //#region handlers
    const openDialog = () => {
      setShowBatchRecallDialog(true);
    };
    const closeDialog = () => {
      setShowBatchRecallDialog(false);
    };
    //#endregion

    return (
      <>
        <CCNavButton
          title="Batch Recall"
          disabled={gridSelectedIds.length === 0}
          onClick={openDialog}
        />
        {showBatchRecallDialog ? (
          <BatchRecallDialog
            productType={props.productType}
            recordType={props.recordType}
            recordIDs={gridSelectedIds}
            mailMergeDataset={props.mailMergeDataset}
            onClose={closeDialog}
          />
        ) : null}
      </>
    );
  }
);

interface BatchRecallButtonListViewProps {
  productType: PRODUCT_TYPE_NUMBER;
  recordType: RECORDTYPE;
  mailMergeDataset: MAILMERGEDATASET;
  onCloseDialog?: () => void;
}
