import {
  getAllOdata,
  getCallBackDataByID,
  getSearchByURL,
  getSearchOdata,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/api";
import { getUrlSearchHasStateOption } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import {
  EmptyItem,
  IOptionInputPicker,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog, ICCDialogProps } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxPageChangeEvent,
  ListItemProps,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  MultiSelectPageChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent, InputProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import {
  concat,
  filter,
  find,
  first,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isEqualWith,
  isFunction,
  isNil,
  isNull,
  isObject,
  isString,
  isUndefined,
  map,
  omit,
  size,
  some,
  uniq,
  uniqWith,
} from "lodash";
import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IInputPickerSearch
  extends Omit<InputProps, "validationMessage" | "value" | "onChange"> {
  options: IOptionInputPicker;
  validationMessage?: string | null;
  visited?: boolean;
  value: any;
  onChange?: (event: ComboBoxChangeEvent) => void;
  onInputChange?: (event: InputChangeEvent) => void;
  onError?: (value: any) => void;
  dialog?: Omit<
    ICCDialogProps,
    "bodyElement" | "onClose" | "footerElement" | "width" | "height"
  >;
  customDialog?: (
    value: any,
    onClose: () => void,
    onSubmit: (value: any) => void
  ) => ReactElement;
  onOpenDialogClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  showClearButton?: boolean;
  allowCustom?: boolean;
  nameDisplay?: string | Function;
  nameDisplayMerge?: string | ((value: any) => string);
  apiUrlCallBackByID?: string;
  onClickCancel?: () => void;
  customGetData?: (searchText: string) => Promise<APIResponse<any>>;
  localSearchOptions?: ILocalSearchOptions;
  isCRMS?: boolean;
  uniqueKey?: string;
  isLazyLoadOData?: boolean;
  removeDisplayValue?: () => void;
  restoreDisplayValue?: () => void;
  keyMapGrid?: string;
  valueMapGrid?: string | number;
}

export interface ILocalSearchOptions {
  enableLocalSearch?: boolean;
  searchingPropKey?: string;
}

export const InputPickerSearch = (props: IInputPickerSearch) => {
  const {
    options,
    validationMessage,
    visited,
    className,
    value,
    onChange,
    disabled,
    customDialog,
    onOpenDialogClick,
    showClearButton,
    placeholder,
    allowCustom,
    nameDisplay,
    nameDisplayMerge,
    apiUrlCallBackByID,
    onError,
    customGetData,
    localSearchOptions = {},
    isCRMS = false,
    restoreDisplayValue,
    removeDisplayValue,
    uniqueKey,
    isLazyLoadOData = false,
    keyMapGrid,
    valueMapGrid,
    ...others
  } = props;

  const { enableLocalSearch, searchingPropKey } = localSearchOptions;
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  // Lazy Load OData
  // Showing this data in the dropdown when blur input (single mode) or searchKey is empty (multiple mode)
  const dataLazyLoadCaching = useRef<any>([]);

  const urlOData = useMemo(() => {
    return options.grid.dataUrl
      ? `${options.grid.dataUrl}?$count=true&`
      : undefined;
  }, [options.grid.dataUrl]);

  const [ODataSearchURL, setUrlODataSearch] = useState("");

  const [searchKey, setSearchKey] = useState("");
  const [typeAheadSearchResults, setTypeAheadSearchResults] = useState<any[]>(
    []
  );
  const [isLoadedOriginalData, setIsLoadedOriginalData] =
    useState<boolean>(false);
  const [gridData, setGridData] = useState<any[]>([]);
  const [gridDataOData, setGridDataOData] = useState<any[]>([]);
  const [gridOriginalData, setGridOriginalData] = useState<any[]>([]);

  const localTypeAheadSearchResults = gridData?.slice(
    0,
    options.grid.itemPerPage
  );
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);

  const [showDialog, setShowDialog] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>([]);

  //Proceed grid state
  const [stateGrid, setStateGrid] = useState(options?.grid?.state);

  //#region handle multi-select mode
  const multiselectableMode = options.grid.selectableMode === "multiple";
  const [multipleOriginalData, setMultipleOriginalData] = useState<any[]>([]);
  const [multipleSearchData, setMultipleSearchData] = useState<any[]>([]);
  const originalOData = useRef<any[]>([]);
  let isUsingOriginalOData = true;

  if (multiselectableMode) {
    originalOData.current = [];
    originalOData.current = uniqWith(
      concat(originalOData.current, multipleSearchData, value),
      isEqualWith
    );
    isUsingOriginalOData =
      !isUndefined(first(originalOData.current)) &&
      isObject(value) &&
      some(Object.keys(first(originalOData.current)), (key) =>
        includes(map(options?.grid?.columnFields, "title"), key)
      );
  }
  //#endregion

  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (isObject(value) && nameDisplay) {
      if (isString(nameDisplay)) {
        const valueDisplay =
          value?.[nameDisplay as keyof typeof value] ??
          value?.[options.boxSearch?.colSearch as keyof typeof value];
        return valueDisplay;
      }
      if (isFunction(nameDisplay)) {
        return nameDisplay(value);
      }
    }
    if (nameDisplayMerge && isArray(value)) {
      if (typeof nameDisplayMerge === "function") {
        return nameDisplayMerge(value);
      } else {
        let nameDisplay: Array<string> = [];
        value?.forEach((item) => {
          if (
            !isNil(item?.[nameDisplayMerge]) &&
            item[nameDisplayMerge]?.length > 0
          ) {
            nameDisplay.push(item?.[nameDisplayMerge]?.toString()?.trim());
          }
        });
        return multiselectableMode ? nameDisplay : nameDisplay?.join(", ");
      }
    }
    return value ?? "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, nameDisplayMerge, nameDisplay]);

  useEffect(() => {
    if (isNil(inputValue) || isEmpty(inputValue)) {
      setGridSelectedRows([]);
    }
  }, [inputValue]);

  const [multipleInputValues, setMultipleInputValues] = useState<string[]>(
    isArray(inputValue) ? inputValue : []
  );

  const handleSubmitCustomDialog = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (onChange) onChange(value);
    },
    [onChange]
  );

  const handleOnClickToChangeData = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (value?.hasOwnProperty("keyYieldForGrid")) return;
      if (isString(nameDisplayMerge)) {
        setMultipleInputValues(
          uniq(map(value, nameDisplayMerge)).map((item) => item)
        );
      }
      const apiUrlCallBackByID = options.grid.apiUrlCallBackByID;
      if (apiUrlCallBackByID && value) {
        const id = value[options.grid.primaryField as keyof typeof value];
        if (id) {
          setIsSearching(true);
          getCallBackDataByID(id, apiUrlCallBackByID).then((response) => {
            setIsSearching(false);
            if (isSuccessResponse(response)) {
              if (response.data) {
                if (onChange) onChange(response.data);
              }
            } else {
              if (onError) onError(response.error);
            }
          });
        } else {
          if (onChange) onChange(value);
        }
      } else {
        if (onChange) onChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, onError]
  );

  //#region Handle sync data selected combobox search & grid ========/
  const handleDataReceived = async (response: any) => {
    if (!multiselectableMode && response.result?.data && !isNil(valueMapGrid)) {
      let selectedItem = gridSelectedRows;
      if (
        !size(selectedItem) ||
        gridSelectedRows?.[0]?.hasOwnProperty("keyYieldForGrid")
      ) {
        selectedItem =
          find(response.result?.data, {
            [keyMapGrid ?? options?.grid?.primaryField]: valueMapGrid,
          }) ??
          find(response.result?.data, {
            [keyMapGrid ?? options?.grid?.primaryField]: `${valueMapGrid}`,
          });
      }

      if (isEmpty(selectedItem)) {
        // if empty at current page.
        setGridSelectedRows([{ keyYieldForGrid: true }]);
      } else {
        const dataSelected = isArray(selectedItem)
          ? selectedItem
          : [selectedItem];
        setGridSelectedRows(dataSelected);
      }
    }
    return response.result;
  };
  //#endregion Handle sync data selected combobox search & grid =====/

  const handleOnLoadGridDataFromAPI = async (searchText: string) => {
    if (customGetData) {
      const response = await customGetData(searchText);
      if (isSuccessResponse(response) && response?.data) {
        setGridData(response.data);
        if (!isLoadedOriginalData) {
          setGridOriginalData(response.data);
          setIsLoadedOriginalData(true);
        }
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Load data failed",
          type: "error",
          description: response.data?.Errors,
        });
      }
    }
  };

  const handleOnLocalSearch = async (searchText: string) => {
    if (!isLoadedOriginalData) {
      await handleOnLoadGridDataFromAPI("");
    }
    setGridData(() => {
      if (searchText !== "" && searchingPropKey) {
        return gridOriginalData.filter((x) =>
          x?.[searchingPropKey].toLowerCase().includes(searchText.toLowerCase())
        );
      }
      return gridOriginalData;
    });
  };

  const handleOnClickOpenDialog = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onOpenDialogClick) return onOpenDialogClick(event);
    setSearchKey("");
    if (isLazyLoadOData) {
      dataLazyLoadCaching.current = [];
    }
    setShowDialog(true);
    setStateGrid(options?.grid?.state);
    isString(nameDisplayMerge) &&
      setGridSelectedRows(
        filter(
          isUsingOriginalOData ? originalOData.current : multipleOriginalData,
          (item: any) => includes(multipleInputValues, item?.[nameDisplayMerge])
        )
      );

    if (customGetData) {
      setIsSearching(true);
      await handleOnLoadGridDataFromAPI("");
      setIsSearching(false);
    }
  };

  const getDataODataGrid = useCallback((urlOData) => {
    getAllOdata(urlOData).then((response) => {
      if (isSuccessResponse(response)) {
        setGridDataOData(response.data.value);
      }
    });
  }, []);

  useEffectOnce(() => {
    if (options.flagRedirectGridPageNoApiCall && urlOData) {
      getDataODataGrid(urlOData);
    }
  });

  const handleMultipleOriginalData = async () => {
    if (options.grid.dataUrl) {
      const urlSearchHasStateOption = getUrlSearchHasStateOption(
        debouncedSearch,
        options.boxSearch.colSearch,
        options.grid.dataUrl,
        options.grid?.state
      );

      const response = await getSearchOdata(
        urlSearchHasStateOption,
        options.grid.itemPerPage
      );
      setMultipleOriginalData(response?.data.value || []);
    }
  };

  //#region lazy load OData
  const dataCaching = useRef<any>([]);
  const pendingRequest = useRef<any>();
  const requestStarted = useRef(false);
  const skipRef = useRef(0);

  const [total, setTotal] = useState(0);

  const enabledIsLazyLoadOData = useMemo(() => {
    return isLazyLoadOData && total > options.grid.itemPerPage;
  }, [isLazyLoadOData, options.grid.itemPerPage, total]);

  const isEmptyItem = (obj: any): obj is EmptyItem => {
    if (isNil(obj) || typeof obj !== "object") return false;
    return "EmptyKey" in obj;
  };

  const emptyItem: any = useMemo(
    () => ({
      EmptyKey: 0,
      [options.grid.selectableMode === "multiple" && isString(nameDisplayMerge)
        ? nameDisplayMerge
        : isString(nameDisplay)
        ? nameDisplay
        : "Value"]: "",
    }),
    [options.grid.selectableMode, nameDisplayMerge, nameDisplay]
  );

  const loadingData: any[] = [];
  while (loadingData.length < options.grid.itemPerPage) {
    loadingData.push({ ...emptyItem });
  }

  const resetCache = () => {
    dataCaching.current.length = 0;
  };

  const shouldRequestData = useCallback(
    (skip) => {
      for (let i = 0; i < options.grid.itemPerPage; i++) {
        if (!dataCaching.current[skip + i]) {
          return true;
        }
      }
      return false;
    },
    [options.grid.itemPerPage]
  );

  const getCachedData = useCallback(
    (skip) => {
      const data: Array<any> = [];
      for (let i = 0; i < options.grid.itemPerPage; i++) {
        data.push(dataCaching.current[i + skip] || { ...emptyItem });
      }
      return data;
    },
    [emptyItem, options.grid.itemPerPage]
  );

  const requestData = useCallback(
    async (skip: number) => {
      if (!options.grid.dataUrl) return;
      if (requestStarted.current) {
        clearTimeout(pendingRequest.current);
        pendingRequest.current = setTimeout(() => {
          requestData(skip);
        }, 200);
        return;
      }

      requestStarted.current = true;

      const urlSearchHasStateOption = getUrlSearchHasStateOption(
        searchKey,
        options.boxSearch.colSearch,
        options.grid.dataUrl,
        options.grid?.state
      );

      const response = await getSearchOdata(
        urlSearchHasStateOption,
        options.grid.itemPerPage,
        skip
      );

      if (isSuccessResponse(response)) {
        const responseData: any[] = response?.data?.value;
        const items: any[] = [];
        responseData?.forEach((element, index) => {
          items.push(element);
          dataCaching.current[index + skip] = element;
        });

        if (
          skip === skipRef.current ||
          // Check for last skip
          // Reason: no set last skip value for skipRef
          skip + options.grid.itemPerPage >= total
        ) {
          let newTypeAheadSearchResults = [];
          let newMultipleSearchData = [];
          if (isArray(items)) {
            newMultipleSearchData = items;
            if (isString(nameDisplayMerge) && multiselectableMode) {
              newTypeAheadSearchResults = map(items, nameDisplayMerge);
            } else {
              newTypeAheadSearchResults = items;
            }
          }

          setTypeAheadSearchResults(newTypeAheadSearchResults);
          setMultipleSearchData(newMultipleSearchData);

          // Save data to caching list
          dataLazyLoadCaching.current.push(...newTypeAheadSearchResults);
          dataLazyLoadCaching.current = uniqWith(
            dataLazyLoadCaching.current,
            isEqual
          );
        }
        requestStarted.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      multiselectableMode,
      nameDisplayMerge,
      options.boxSearch.colSearch,
      options.grid.dataUrl,
      options.grid.itemPerPage,
      searchKey,
      total,
    ]
  );

  const pageChange = useCallback(
    async (event: ComboBoxPageChangeEvent | MultiSelectPageChangeEvent) => {
      if (searchKey === "" || event.page.skip === 0) return;
      const newSkip = event.page.skip;

      if (shouldRequestData(newSkip)) {
        requestData(newSkip);
      }

      let data = getCachedData(newSkip);
      data = data.filter((item: any) => item);

      let newTypeAheadSearchResults = [];
      let newMultipleSearchData = [];
      if (isArray(data)) {
        newMultipleSearchData = data;
        if (isString(nameDisplayMerge) && multiselectableMode) {
          newTypeAheadSearchResults = map(data, nameDisplayMerge);
        } else {
          newTypeAheadSearchResults = data;
        }
      }

      setTypeAheadSearchResults(newTypeAheadSearchResults);
      setMultipleSearchData(newMultipleSearchData);

      // No set the last skip value for skipRef
      // Reason: dropdown scroll wrong with the last skip value
      if (newSkip + options.grid.itemPerPage < total) {
        skipRef.current = newSkip;
      }
    },
    [
      searchKey,
      shouldRequestData,
      getCachedData,
      options.grid.itemPerPage,
      total,
      requestData,
      nameDisplayMerge,
      multiselectableMode,
    ]
  );
  //#endregion

  useEffect(() => {
    (async () => {
      // Only show skeleton when searching data
      if (enabledIsLazyLoadOData && debouncedSearch) {
        setTypeAheadSearchResults(loadingData);
        setMultipleSearchData(loadingData);
      }
      if (isLazyLoadOData) {
        // Reset data when searching
        dataLazyLoadCaching.current = [];
      }

      if (enableLocalSearch) {
        setIsSearching(true);
        await handleOnLocalSearch(debouncedSearch);
        setIsSearching(false);
      } else if (debouncedSearch) {
        setIsSearching(true);
        if (options.grid.dataUrl) {
          const urlSearchHasStateOption = getUrlSearchHasStateOption(
            debouncedSearch,
            options.boxSearch.colSearch,
            options.grid.dataUrl,
            options.grid?.state
          );

          setUrlODataSearch(urlSearchHasStateOption);
          if (!showDialog) {
            const response = await getSearchOdata(
              urlSearchHasStateOption,
              options.grid.itemPerPage
            );

            const responseData = response?.data?.value;
            let newTypeAheadSearchResults = [];
            let newMultipleSearchData = [];
            if (isArray(responseData)) {
              newMultipleSearchData = responseData;
              if (isString(nameDisplayMerge) && multiselectableMode) {
                newTypeAheadSearchResults = map(responseData, nameDisplayMerge);
              } else {
                newTypeAheadSearchResults = responseData;
              }
            }

            // Lazy load OData
            if (isLazyLoadOData) {
              setTotal(response?.data["@odata.count"] ?? 0);

              // Save data to caching list
              dataLazyLoadCaching.current.push(...newTypeAheadSearchResults);
              dataLazyLoadCaching.current = uniqWith(
                dataLazyLoadCaching.current,
                isEqual
              );
            }

            setTypeAheadSearchResults(newTypeAheadSearchResults);
            setMultipleSearchData(newMultipleSearchData);
          }
        } else if (options.grid.dataUrlSearch) {
          const response = await getSearchByURL(
            options.grid.dataUrlSearch,
            debouncedSearch
          );
          setTypeAheadSearchResults(
            (isString(nameDisplayMerge) && multiselectableMode
              ? map(response?.data.value, nameDisplayMerge)
              : response?.data.value) || []
          );
        } else if (customGetData) {
          await handleOnLoadGridDataFromAPI(debouncedSearch);
        }
        setIsSearching(false);
      } else if (showDialog) {
        handleMultipleOriginalData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    if (
      options.flagRedirectGridPageNoApiCall &&
      urlOData &&
      isEmpty(searchKey)
    ) {
      getDataODataGrid(urlOData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const renderItems = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;

    //Lazy load OData
    if (isLazyLoadOData && isEmptyItem(dataItem)) {
      const skeletonItem = <div className="cc-search-item-skeleton"></div>;
      return React.cloneElement(li, li.props, skeletonItem);
    }

    const itemChildren = (
      <div className="cc-search-item">
        {options.boxSearch.colShowComboboxSearch.map((col: IColumnFields) => (
          <div key={col.field}>{sanitizeHtml(dataItem[col.field] ?? "")}</div>
        ))}
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const renderMultipleItems = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;

    if (isLazyLoadOData && (isEmptyItem(dataItem) || isEmpty(dataItem))) {
      const skeletonItem = (
        <div className="cc-multiple-search-item-skeleton"></div>
      );
      return React.cloneElement(li, li.props, skeletonItem);
    }

    const itemChildren = li.props.children;
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (
    event: ComboBoxFilterChangeEvent | MultiSelectFilterChangeEvent
  ) => {
    const searchText = event.filter.value;
    setSearchKey(searchText);

    //Lazy load OData
    if (enabledIsLazyLoadOData) {
      resetCache();
      skipRef.current = 0;
    }
  };

  const onCloseDialog = () => {
    setShowDialog(false);
    setGridSelectedRows([]);
    if (isLazyLoadOData) setSearchKey("");
  };

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        <div
          style={{
            display: `${!isHTML(inputValue) ? "none" : "block"}`,
          }}
          className={`cc-input-picker-html k-textbox ${
            disabled ? "k-state-disabled" : ""
          }`}
          onClick={() => {
            if (removeDisplayValue && uniqueKey && !disabled) {
              removeDisplayValue();
              const comboboxElement = document.getElementById(uniqueKey);
              setTimeout(() => {
                comboboxElement?.focus();
              }, 100);
            }
          }}
        >
          {sanitizeHtml(inputValue)}
        </div>
        {multiselectableMode && !isCRMS ? (
          <MultiSelect
            loading={isSearching}
            data={
              enableLocalSearch
                ? localTypeAheadSearchResults
                : enabledIsLazyLoadOData && !searchKey
                ? // if lazy load and searchKey is empty => show data caching
                  dataLazyLoadCaching.current
                : typeAheadSearchResults
            }
            value={multipleInputValues}
            onChange={(event: MultiSelectChangeEvent) => {
              //Lazy load OData
              const isHaveEmptyItem = event?.value?.some((item: any) => {
                return isNil(item) || isEmpty(item);
              });
              if (isLazyLoadOData && isHaveEmptyItem) return;

              const value = event?.value ?? null;
              setTypeAheadSearchResults([]);
              handleOnClickToChangeData(
                value &&
                  (multiselectableMode || nameDisplayMerge) &&
                  isString(nameDisplayMerge) &&
                  filter(
                    isUsingOriginalOData
                      ? originalOData.current
                      : multipleOriginalData,
                    (item) => includes(value, item?.[nameDisplayMerge])
                  )
              );
            }}
            placeholder={placeholder}
            onFilterChange={(event: MultiSelectFilterChangeEvent) => {
              handleSearch(event);
            }}
            allowCustom={allowCustom}
            disabled={disabled}
            required={!others.valid}
            filterable
            virtual={
              //If lazy load && searchKey is not empty => apply virtual scrolling
              enabledIsLazyLoadOData && searchKey
                ? {
                    pageSize: options.grid.itemPerPage,
                    skip: skipRef.current,
                    total: total,
                  }
                : undefined
            }
            onPageChange={
              // If lazy load && searchKey is not empty => apply pageChange of scroll
              enabledIsLazyLoadOData && searchKey ? pageChange : undefined
            }
            itemRender={renderMultipleItems}
          />
        ) : (
          <ComboBox
            allowCustom={allowCustom}
            placeholder={placeholder}
            disabled={disabled}
            required={!others.valid}
            filterable
            suggest
            data={
              enableLocalSearch
                ? localTypeAheadSearchResults
                : enabledIsLazyLoadOData && !searchKey
                ? // if lazy load and searchKey is empty => show data caching
                  dataLazyLoadCaching.current
                : typeAheadSearchResults
            }
            loading={isSearching}
            onFilterChange={handleSearch}
            itemRender={renderItems}
            value={inputValue}
            onChange={(e: ComboBoxChangeEvent) => {
              //Lazy load OData
              if (isEmptyItem(e.target.value))
                return handleOnClickToChangeData(null);

              e.target.value
                ? handleOnClickToChangeData(
                    multiselectableMode || nameDisplayMerge
                      ? [e.target.value]
                      : e.target.value
                  )
                : handleOnClickToChangeData(null);
            }}
            popupSettings={{ className: "cc-officer-picker-search" }}
            clearButton={showClearButton}
            onBlur={() => {
              if (restoreDisplayValue) restoreDisplayValue();
            }}
            id={uniqueKey}
            style={{
              display: `${isHTML(inputValue) ? "none" : "flex"}`,
            }}
            virtual={
              // If lazy load && searchKey is not empty => apply virtual scrolling
              enabledIsLazyLoadOData && searchKey
                ? {
                    pageSize: options.grid.itemPerPage,
                    skip: skipRef.current,
                    total: total,
                  }
                : undefined
            }
            onPageChange={
              // If lazy load && searchKey is not empty => apply pageChange of scroll
              enabledIsLazyLoadOData && searchKey ? pageChange : undefined
            }
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickOpenDialog}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog &&
        (customDialog ? (
          customDialog(value, () => onCloseDialog(), handleSubmitCustomDialog)
        ) : (
          <CCDialog
            dialogWrapperClassName="cc-officer-picker-search"
            {...options.dialog}
            maxWidth={options.dialog.maxWidth ?? "60%"}
            maxHeight={options.dialog.maxHeight ?? "80%"}
            height={options.dialog.height ?? 660}
            onClose={onCloseDialog}
            bodyElement={
              <div className="cc-search-result">
                <CCLocalNotification ref={notificationRef} />
                <div className="cc-search-result-title">
                  <label className="cc-label">Search</label>
                  <Field
                    name={"searchKey"}
                    component={CCInput}
                    placeholder="Search"
                    onChange={(e: InputChangeEvent) => {
                      setSearchKey(e.value);
                    }}
                  />
                </div>
                <div className="cc-search-result-body">
                  <CCGrid
                    {...omit(options.grid, "state")}
                    /* For using odata */
                    dataUrl={
                      searchKey
                        ? ODataSearchURL
                        : !options.flagRedirectGridPageNoApiCall
                        ? urlOData
                        : undefined
                    }
                    /** Need to pass gridData & onLoadData For using api, otherwise needless*/
                    data={
                      options.flagRedirectGridPageNoApiCall
                        ? gridDataOData ?? []
                        : gridData ?? []
                    }
                    isLoading={isSearching}
                    onSelectionChange={(dataItems) => {
                      setGridSelectedRows(dataItems);
                    }}
                    selectedRows={gridSelectedRows}
                    onDataReceived={handleDataReceived}
                    onDataStateChange={(state) => setStateGrid(state)}
                    state={omit(stateGrid, "filter")}
                  />
                </div>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onCloseDialog}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={size(gridSelectedRows) === 0}
                  onClick={() => {
                    handleOnClickToChangeData(
                      multiselectableMode || nameDisplayMerge
                        ? gridSelectedRows
                        : gridSelectedRows[0]
                    );
                  }}
                >
                  Select
                </Button>
              </div>
            }
          />
        ))}
    </>
  );
};
