export const mockInspections = [
  {
    InspectionDate: new Date("7-Jan-2022 17:04"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("16-Jan-2022"),
    SpecificInstructions: "Clear Entire Property",
    Locality: "Battery Point",
    HouseNumber: "23",
    Street: "Albuera",
    StreetType: "Street",
    ParcelId: 231040,
    AssessmentNumber: "2214889",
    Owners: "I S Middleton & J M Middleton",
    Period: "2021/2022",
    InspectorUserId: "rhosking",
    CaseId: 210004,
    InspectionId: 210004,
  },
  {
    InspectionDate: new Date("05-Oct-2018 13:33"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("07-Jan-2022"),
    Locality: "West Hobart",
    HouseNumber: "39",
    Street: "Hamiton",
    StreetType: "Street",
    ParcelId: 107020,
    AssessmentNumber: "1813126",
    Owners: "C J Scort",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 210003,
    InspectionId: 210003,
  },
  {
    InspectionDate: new Date("05-Oct-2018 13:12"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "Dynnyrnc",
    HouseNumber: "19",
    Street: "Zomay",
    StreetType: "Avenue",
    ParcelId: 106750,
    AssessmentNumber: "1842908",
    Owners: "J A Durning",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 33,
    InspectionId: 54,
  },
  {
    InspectionDate: new Date("05-Oct-2018 13:09"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("05-Oct-2018"),
    Locality: "Dynnytne",
    HouseNumber: "19",
    Street: "Zomay",
    StreetType: "Avenue",
    ParcelId: 108750,
    AssessmentNumber: "1842808",
    Owners: "J A Durnong",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 33,
    InspectionId: 53,
  },
  {
    InspectionDate: new Date("05-Oct-2018 12:52"),
    Clearance: "No Hazard",
    Outcome: "No Hazard",
    ToBeClearedBy: new Date("05-Oct-2018"),
    Locality: "Dynnyme",
    HouseNumber: "11",
    Street: "Zomay",
    StreetType: "Avenue",
    ParcelId: 192915,
    AssessmentNumber: "5652761",
    Owners: "D A Potter",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 32,
    InspectionId: 52,
  },
  {
    InspectionDate: new Date("05-Oct-2018 10:51"),
    Clearance: "No Hazard",
    Outcome: "Cleared by Contractor, No Hazard",
    ToBeClearedBy: new Date("14-Aug-2018"),
    Locality: "LenahValtey",
    HouseNumber: "6",
    Street: "Yaizu",
    StreetType: "Court",
    ParcelId: 112830,
    AssessmentNumber: "2025359",
    Owners: "A M Dcnnely",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 210002,
    InspectionId: 210002,
  },
  {
    InspectionDate: new Date("27-Sep-2018 14:42"),
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "LenahValkey",
    HouseNumber: "29",
    Street: "Alwyn",
    StreetType: "Road",
    ParcelId: 235020,
    AssessmentNumber: "2657831",
    Owners: "C L Nichols & R Nichols",
    Period: "2018/2019",
    InspectorUserId: "juddl",
    CaseId: 30,
    InspectionId: 50,
  },
  {
    InspectionDate: new Date("27-Sep-2018 14:38"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "LenahValtey",
    HouseNumber: "29",
    Street: "Alwyn",
    StreetType: "Road",
    ParcelId: 235020,
    AssessmentNumber: "2657831",
    Owners: "C L Nichols & R Nichols",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 30,
    InspectionId: 49,
  },
  {
    InspectionDate: new Date("27-Sep-2018 14:35"),
    Clearance: "Clear Entire Property",
    Outcome: "Cleared, No Hazard",
    ToBeClearedBy: new Date("3-Sep-2018"),
    Locality: "LenahValfey",
    HouseNumber: "29",
    Street: "Alwyn",
    StreetType: "Road",
    ParcelId: 235020,
    AssessmentNumber: "2657831",
    Owners: "C L Nichols & R Nichols",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 30,
    InspectionId: 48,
  },
  {
    InspectionDate: new Date("27-Sep-2018 14:22"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("10-Sep-2018"),
    Locality: "West Hobart",
    HouseNumber: "4",
    Street: "Amalia",
    StreetType: "Street",
    ParcelId: 140145,
    AssessmentNumber: "5537392",
    Owners: "S W Wood & L Wood",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 29,
    InspectionId: 47,
  },
  {
    InspectionDate: new Date("27-Sep-2018 14:17"),
    Clearance: "Clear Entire Property",
    Outcome: "No Hazard",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "Lenah Valley",
    HouseNumber: "25",
    Street: "Alicides",
    StreetType: "Avenue",
    ParcelId: 112965,
    AssessmentNumber: "5478388",
    Owners: "M J Zawadzki & Z Zawadzki",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 28,
    InspectionId: 46,
  },
  {
    InspectionDate: new Date("27-Sep-2018 14:13"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "Lenah Valley",
    HouseNumber: "25",
    Street: "Alicides",
    StreetType: "Averue",
    ParcelId: 112965,
    AssessmentNumber: "5478388",
    Owners: "M J Zawadzki & Z Zawadzki",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 28,
    InspectionId: 45,
  },
  {
    InspectionDate: new Date("23-Sep-2018 10:19"),
    Clearance: "No Hazard",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("03-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "2",
    Street: "Baden",
    StreetType: "Street",
    ParcelId: 169190,
    AssessmentNumber: "5601200",
    Owners: "D R N Liancre",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 31,
    InspectionId: 51,
  },
  {
    InspectionDate: new Date("21-Sep-2018 11:18"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Glebe",
    HouseNumber: "1",
    Street: "Aberdeen",
    StreetType: "Street",
    ParcelId: 193120,
    AssessmentNumber: "2572897",
    Owners: "J M Bamford",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 24,
    InspectionId: 44,
  },
  {
    InspectionDate: new Date("19-Sep-2018 13:23"),
    Clearance: "10m Fire Break",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "West Hobart",
    HouseNumber: "9",
    Street: "Stevens Farm",
    StreetType: "Drive",
    ParcelId: 255050,
    AssessmentNumber: "3378113",
    Owners: "S W Hennessy & C J E Hennessy",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 13,
    InspectionId: 43,
  },
  {
    InspectionDate: new Date("19-Sep-2018 13:17"),
    Clearance: "To be assessed",
    Outcome: "Cleared by Contractor, No Hazard",
    ToBeClearedBy: new Date("05-Oct-2018"),
    Locality: "South Hobart",
    HouseNumber: "602",
    Street: "Huon",
    StreetType: "Road",
    ParcelId: 109715,
    AssessmentNumber: "1878213",
    Owners: "A Danerburgh",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 27,
    InspectionId: 42,
  },
  {
    InspectionDate: new Date("19-Sep-2018 13:12"),
    Clearance: "No Hazard",
    Outcome: "No Hazard",
    SpecificInstructions: "No ispection required for more than 60 days",
    Locality: "South Hobart",
    HouseNumber: "602",
    Street: "Huon",
    StreetType: "Road",
    ParcelId: 109715,
    AssessmentNumber: "1879213",
    Owners: "A Danerburgh",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 27,
    InspectionId: 41,
  },
  {
    InspectionDate: new Date("12-Sep-2018 14:24"),
    Clearance: "Clear Entire Property",
    Outcome: "No Hazard",
    ToBeClearedBy: new Date("03-Sep-2018"),
    SpecificInstructions: "nagging Mark",
    Locality: "McmtI Stuart",
    HouseNumber: "2",
    Street: "Abermete",
    StreetType: "Avenue",
    ParcelId: 112855,
    AssessmentNumber: "5478142",
    Owners: "K F Doran",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 26,
    InspectionId: 40,
  },
  {
    InspectionDate: new Date("12-Sep-2018 14:20"),
    Clearance: "To be assessed",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "Mani Stuart",
    HouseNumber: "2",
    Street: "Abermete",
    StreetType: "Avenue",
    ParcelId: 112855,
    AssessmentNumber: "5478142",
    Owners: "K F Doran",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 26,
    InspectionId: 39,
  },
  {
    InspectionDate: new Date("12-Sep-2018 14:19"),
    Clearance: "No Hazard",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("31-Aug-2018"),
    Locality: "Mont Stuart",
    HouseNumber: "2",
    Street: "Abermete",
    StreetType: "Avenue",
    ParcelId: 112855,
    AssessmentNumber: "5478142",
    Owners: "K F Doran",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 26,
    InspectionId: 38,
  },
  {
    InspectionDate: new Date("12-Sep-2018 10:39"),
    Clearance: "To be assessed",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("04-Sep-2018"),
    Locality: "South Hobart",
    HouseNumber: "10",
    Street: "Adelaide",
    StreetType: "Street",
    ParcelId: 151985,
    AssessmentNumber: "5563339",
    Owners: "A J Foster",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 25,
    InspectionId: 37,
  },
  {
    InspectionDate: new Date("12-Sep-2018 10:32"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("04-Sep-2018"),
    Locality: "South Hot-art",
    HouseNumber: "10",
    Street: "Adelaide",
    StreetType: "Street",
    ParcelId: 151985,
    AssessmentNumber: "5563339",
    Owners: "A J Foster",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 25,
    InspectionId: 36,
  },
  {
    InspectionDate: new Date("10-Sep-2018 17:11"),
    Clearance: "To be assessed",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("03-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "254",
    Street: "Churchil",
    StreetType: "Avenue",
    ParcelId: 171300,
    AssessmentNumber: "5605797",
    Owners: "L Zheng",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 22,
    InspectionId: 35,
  },
  {
    InspectionDate: new Date("10-Sep-2018 16:18"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Site to be inspected",
    ToBeClearedBy: new Date("02-Sep-2018"),
    Locality: "Glebe",
    HouseNumber: "1",
    Street: "Aberdeen",
    StreetType: "Street",
    ParcelId: 193120,
    AssessmentNumber: "2572897",
    Owners: "J M Bamford",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 24,
    InspectionId: 34,
  },
  {
    InspectionDate: new Date("10-Sep-2018 15:16"),
    Clearance: "To be assessed",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("28-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "254",
    Street: "Our chi",
    StreetType: "Avenue",
    ParcelId: 171300,
    AssessmentNumber: "5605797",
    Owners: "L Zheng",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 22,
    InspectionId: 33,
  },
  {
    InspectionDate: new Date("05-Sep-2018 16:36"),
    Clearance: "To be assessed",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("05-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "2",
    Street: "Adams",
    StreetType: "Street",
    ParcelId: 168440,
    AssessmentNumber: "5599593",
    Owners: "S J Hansen-Lohrey & C F Hansen-Loh",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 23,
    InspectionId: 32,
  },
  {
    InspectionDate: new Date("05-Sep-2018 14:03"),
    Clearance: "To be assessed",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "254",
    Street: "Churchill",
    StreetType: "Avenue",
    ParcelId: 171300,
    AssessmentNumber: "5605797",
    Owners: "L Zheng",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 22,
    InspectionId: 31,
  },
  {
    InspectionDate: new Date("04-Sep-2018 14:10"),
    Clearance: "Clear Entire Property",
    Outcome: "No Hazard",
    ToBeClearedBy: new Date("12-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "12",
    Street: "Ada",
    StreetType: "Crescent",
    ParcelId: 233770,
    AssessmentNumber: "2298074",
    Owners: "A M M Baker",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 21,
    InspectionId: 30,
  },
  {
    InspectionDate: new Date("04-Sep-2018 8:25"),
    Clearance: "To be assessed",
    Outcome: "No Hazard",
    ToBeClearedBy: new Date("19-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: "6",
    Street: "Ada",
    StreetType: "Descent",
    ParcelId: 206565,
    AssessmentNumber: "5682581",
    Owners: "A Grilas",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 20,
    InspectionId: 29,
  },
  {
    InspectionDate: new Date("01-Sep-2018 8:12"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("30-Aug-2018"),
    SpecificInstructions: "Test place holder",
    Locality: "Sandy Bay",
    HouseNumber: "6",
    Street: "Ada",
    StreetType: "Descent",
    ParcelId: 206565,
    AssessmentNumber: "5682581",
    Owners: "A Grilas",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 20,
    InspectionId: 28,
  },
  {
    InspectionDate: new Date("03-Sep-2018 14:34"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    SpecificInstructions: "Test…",
    Locality: "West Hobart",
    HouseNumber: "4",
    Street: "Stevens Farm",
    StreetType: "Dave",
    ParcelId: 253925,
    AssessmentNumber: "3283878",
    Owners: "D K McKay",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 19,
    InspectionId: 27,
  },
  {
    InspectionDate: new Date("03-Sep-2018 11:03"),
    Clearance: "No Hazard",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("05-Oct-2018"),
    Locality: "Lenah Valley",
    HouseNumber: "33",
    Street: "Abides",
    StreetType: "Avenue",
    ParcelId: 112945,
    AssessmentNumber: "5478337",
    Owners: "N M Tierney",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 18,
    InspectionId: 26,
  },
  {
    InspectionDate: new Date("03-Sep-2018 10:51"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("02-Sep-2018"),
    Locality: "Fem Tree",
    HouseNumber: "4",
    Street: "Stephenson",
    StreetType: "Place",
    ParcelId: 157265,
    AssessmentNumber: "5575057",
    Owners: "c L Dergess",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 6,
    InspectionId: 25,
  },
  {
    InspectionDate: new Date("03-Sep-2018 9:07"),
    Clearance: "Clear Entire Property",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("10-Aug-2018"),
    Locality: "Glebe",
    HouseNumber: "20",
    Street: "Aberdeen",
    StreetType: "Street",
    ParcelId: 100135,
    AssessmentNumber: "1228154",
    Owners: "B G Badger",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 17,
    InspectionId: 24,
  },
  {
    InspectionDate: new Date("03-Sep-2018 8:58"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("31-Aug-2018"),
    Locality: "New Town",
    HouseNumber: "1",
    Street: "Aired",
    StreetType: "Street",
    ParcelId: 113095,
    AssessmentNumber: "5478679",
    Owners: "M Broadby",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 16,
    InspectionId: 23,
  },
  {
    InspectionDate: new Date("02-Sep-2018 9:44"),
    Clearance: "Clear Entire Property",
    Outcome: "Cleared, No Hazard",
    ToBeClearedBy: new Date("07-Jan-2022"),
    Locality: "Sandy Bay",
    HouseNumber: "4",
    Street: "Adams",
    StreetType: "Street",
    ParcelId: 168445,
    AssessmentNumber: "5599606",
    Owners: "R Newton",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 15,
    InspectionId: 22,
  },
  {
    InspectionDate: new Date("31-Aug-2018 15:26"),
    Clearance: "Clear Entire Property",
    Outcome: "Not Cleared, Set Contractor to clear",
    ToBeClearedBy: new Date("30-Aug-2018"),
    Locality: "West Hobart",
    HouseNumber: "9",
    Street: "Stevens Farm",
    StreetType: "Dave",
    ParcelId: 255050,
    AssessmentNumber: "3378113",
    Owners: "S W Hemessy & C J E Hennessy",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 13,
    InspectionId: 21,
  },
  {
    InspectionDate: new Date("31-Aug-2018 15:19"),
    Clearance: "30m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("05-Oct-2018"),
    Locality: "Ridgeway",
    HouseNumber: "3",
    Street: "Ridgeway",
    StreetType: "Road",
    ParcelId: 163675,
    AssessmentNumber: "5588931",
    Owners: "C Hawkins",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 14,
    InspectionId: 20,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:52"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("10-Aug-2018"),
    Locality: "Hobart",
    HouseNumber: "12-16",
    Street: "Bathurst",
    StreetType: "Street",
    ParcelId: 194430,
    Owners: "Unrversiy Of T aimania",
    Period: "2018/2019",
    InspectorUserId: "wisem",
    CaseId: 10,
    InspectionId: 18,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:51"),
    Clearance: "Remove All Undergrowth",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("31-Aug-2018"),
    Locality: "New Town",
    HouseNumber: "57",
    Street: "Montagu",
    StreetType: "Street",
    ParcelId: 128385,
    AssessmentNumber: "3007670",
    Owners: "M J Gray",
    Period: "2018/2019",
    InspectorUserId: "kleineb",
    CaseId: 111,
    InspectionId: 17,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:44"),
    Clearance: "20m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("07-Jan-2022"),
    Locality: "New Town",
    HouseNumber: "57",
    Street: "Montagu",
    StreetType: "Sheet",
    ParcelId: 128385,
    AssessmentNumber: "3007670",
    Owners: "M J Gray",
    Period: "2018/2019",
    InspectorUserId: "watchormr",
    CaseId: 111,
    InspectionId: 15,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:43"),
    Clearance: "10m Fire Break",
    Outcome: "Cleared, No Hazard",
    ToBeClearedBy: new Date("30-Aug-2018"),
    SpecificInstructions:
      "Slash long grass and weeds along boundary of neighbouring property to a height less than 100mm",
    Locality: "Dynnyme",
    HouseNumber: "178",
    Street: "Waterworks",
    StreetType: "Road",
    ParcelId: 252510,
    AssessmentNumber: "3067614",
    Owners: "D M Cutano",
    Period: "2018/2019",
    InspectorUserId: "graym",
    CaseId: 12,
    InspectionId: 16,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:35"),
    Clearance: "No Hazard",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("05-Oct-2018"),
    SpecificInstructions: "please clear goese",
    Locality: "South Hobart",
    HouseNumber: "3",
    Street: "Lawley",
    StreetType: "Crescent",
    ParcelId: 160760,
    AssessmentNumber: "5582644",
    Owners: "J M Aten & A Aten",
    Period: "2018/2019",
    InspectorUserId: "watchormr",
    CaseId: 8,
    InspectionId: 12,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:33"),
    Clearance: "30m Fire Break",
    Outcome: "No Hazard",
    ToBeClearedBy: new Date("10-Aug-2018"),
    SpecificInstructions: "Establish fooglove garden over entire property",
    Locality: "Fem Tree",
    HouseNumber: "791",
    Street: "Huon",
    StreetType: "Road",
    ParcelId: 159455,
    AssessmentNumber: "5579920",
    Owners: "S D Johnson",
    Period: "2018/2019",
    InspectorUserId: "kleineb",
    CaseId: 7,
    InspectionId: 11,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:32"),
    Clearance: "30m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("31-Aug-2018"),
    SpecificInstructions: "overgrown vegetation",
    Locality: "Hobart",
    HouseNumber: "12-16",
    Street: "Bathurst",
    StreetType: "Sheet",
    ParcelId: 194430,
    Owners: "Unrversiy Of Tasmania",
    Period: "2018/2019",
    InspectorUserId: "graym",
    CaseId: 10,
    InspectionId: 14,
  },
  {
    InspectionDate: new Date("30-Aug-2018 10:27"),
    Clearance: "No Hazard",
    Outcome: "Cleared, No Hazard",
    ToBeClearedBy: new Date("07-Jan-2022"),
    Locality: "Fem Tree",
    HouseNumber: "791",
    Street: "Huon",
    StreetType: "Road",
    ParcelId: 159455,
    AssessmentNumber: "5579920",
    Owners: "S D Johnson",
    Period: "2018/2019",
    InspectorUserId: "watchormr",
    CaseId: 7,
    InspectionId: 10,
  },
  {
    InspectionDate: new Date("04-Sep-2018 8:25"),
    Clearance: "20m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("10-Aug-2018"),
    Locality: "Battery Point",
    HouseNumber: "4-6",
    Street: "Albuera",
    StreetType: "Street",
    ParcelId: 152320,
    Owners: "J A Durnong",
    Period: "2017/2018",
    InspectorUserId: "lassigl",
    CaseId: 2,
    InspectionId: 2,
  },
  {
    InspectionDate: new Date("01-Sep-2018 8:12"),
    Clearance: "20m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("31-Aug-2018"),
    SpecificInstructions: "this is a test",
    Locality: "West Hobart",
    HouseNumber: "3",
    Street: "Allambee",
    StreetType: "Crescent",
    ParcelId: 193345,
    AssessmentNumber: "5653828",
    Owners: "D A Potter",
    Period: "2017/2018",
    InspectorUserId: "lassigl",
    CaseId: 1,
    InspectionId: 1,
  },
  {
    InspectionDate: new Date("03-Sep-2018 14:34"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("07-Jan-2022"),
    Locality: "Dynnyrnc",
    HouseNumber: "6",
    Street: "Adelaide",
    StreetType: "Street",
    ParcelId: 151975,
    AssessmentNumber: "5563312",
    Owners: "A M Dcnnely",
    Period: "2017/2018",
    InspectorUserId: "hosking",
    CaseId: 10002,
    InspectionId: 10002,
  },
];
