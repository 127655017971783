import { mockSearchAssessment } from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/components/search-assessment/mock";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadSearchAssessment = async (filter: FilterDescriptor) => {
  const data = mockSearchAssessment.slice();
  await sleep(500);
  let result: any = filterBy(data, filter);
  if (result.length === 0) {
    result = [];
  }
  return result;
};
