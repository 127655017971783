import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Svc_ParentRecordDetails } from "@components/layout/cc-header/components/app-search/components/item-search-result/model";

export const getParentAttachment = async (
  id: number,
  recordType: RECORDTYPE
): Promise<APIResponse<Svc_ParentRecordDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_ParentRecordDetails>(
      `api/core/internal/core/${id}/parent`,
      {
        params: {
          recordType: recordType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
