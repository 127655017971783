import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AssessmentsAssociationsTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/associations/_index";
import { AssessmentDetailTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { removeUnusedProperty } from "@app/products/property/assessments/compliance/candidates-land-rent/util";
import { LandTaxExemptionButton } from "@app/products/property/assessments/compliance/candidates/components/action-bar/buttons/land-tax-exemption/_index";
import { PopulateCandidatesButton } from "@app/products/property/assessments/compliance/candidates/components/action-bar/buttons/populate-candidates/_index";
import { PopulateCandidatesDialog } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/populate-candidates/_index";
import { IPopulateCandidateDialog } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/populate-candidates/model";
import { complianceCandidateColList } from "@app/products/property/assessments/compliance/candidates/config";
import { VO_Compliance_Candidates } from "@app/products/property/assessments/compliance/candidates/model";
import { useComplianceCandidateStore } from "@app/products/property/assessments/compliance/candidates/store";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/compliance/candidates/util";
import { InitiateReviewButton } from "@app/products/property/assessments/compliance/component/buttons/initiate-review/_index";
import { NoReviewRequiredButton } from "@app/products/property/assessments/compliance/component/buttons/no-review-required/_index";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { State } from "@progress/kendo-data-query";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Compliance_Candidates>();

export default observer(() => {
  const {
    isLoading,
    populateCandidateData,
    isOpenPopulateDialog,
    setIsOpenPopulateDialog,
    resetStore,
  } = useComplianceCandidateStore();
  const { setIsShowFilterBar, isEmptyData } = useCCProductListViewStore();

  const [requestParamsString, setRequestParamsString] = useState<string>("");
  const [currentGridDataState, setCurrentGridDataState] = useState<State>({
    group: [{ field: nameOf("NA_Name"), dir: "asc" }],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDetailTab /> },
      {
        title: "Map",
        component: <MapTab addressField="Property_Name_Address_Locality" />,
      },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab componentNumber={eComponent.Assessment} />
        ),
      },
      {
        title: "Related",
        component: <AssessmentsAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            //Hyperlink open Assessment -> Use Assessment
            recordType={RECORDTYPE.CommunityProperty_Assessment}
          />
        ),
      },
    ],
  });
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <InitiateReviewButton buttonName="Initiate review" />
        <NoReviewRequiredButton buttonName="No review required" />
        <LandTaxExemptionButton />
      </CCNavButton>,
      <PopulateCandidatesButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Compliance}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
        keyField={"Assessment_Id"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (populateCandidateData && !isEmptyData) {
      const requestParams: IPopulateCandidateDialog = removeUnusedProperty(
        cloneDeep({
          //TODO: will remove when Manjesh update the API
          ...populateCandidateData,
          assessmentNumberFrom: undefined,
          assessmentNumberTo: undefined,
        })
      );
      const requestParamsStr =
        Object.keys(requestParams)
          ?.map((key) => {
            const value = requestParams[key as keyof IPopulateCandidateDialog];
            return `${key}=${value}`;
          })
          ?.join(",") ?? "";

      setRequestParamsString(requestParamsStr);
      setIsShowFilterBar(false);
      const newFilter = [];
      if (populateCandidateData.assessmentNumberFrom) {
        newFilter.push({
          field: nameOf("Assess_Reference"),
          value: populateCandidateData.assessmentNumberFrom,
          operator: "gte",
        });
      }
      if (populateCandidateData.assessmentNumberTo) {
        newFilter.push({
          field: nameOf("Assess_Reference"),
          value: populateCandidateData.assessmentNumberTo,
          operator: "lte",
        });
      }
      if (newFilter?.length > 0) {
        setIsShowFilterBar(true);
        setCurrentGridDataState({
          ...currentGridDataState,
          filter: currentGridDataState?.filter
            ? {
                ...currentGridDataState.filter,
                filters: newFilter,
                logic: "and",
              }
            : {
                filters: newFilter,
                logic: "and",
              },
        });
      } else if ((currentGridDataState.filter?.filters?.length ?? 0) > 0) {
        setCurrentGridDataState({
          ...currentGridDataState,
          filter: currentGridDataState?.filter
            ? { ...currentGridDataState.filter, logic: "and", filters: [] }
            : { logic: "and", filters: [] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateCandidateData]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  return (
    <>
      <CCProductListView
        dataUrl={`/odata/property/internal/compliancecandidatesregister/GetComplianceCandidates(${
          requestParamsString ?? ""
        })?$count=true&`}
        columnFields={complianceCandidateColList}
        primaryField={nameOf("Assessment_Id")}
        state={currentGridDataState}
        onDataStateChange={(state: State) => setCurrentGridDataState(state)}
      />
      {isOpenPopulateDialog && (
        <PopulateCandidatesDialog
          onClose={() => setIsOpenPopulateDialog(false)}
        />
      )}
    </>
  );
});
