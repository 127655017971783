import { isSuccessResponse } from "@common/apis/util";
import { putMeetingCompleteAction } from "@common/pages/actions/components/action-bar/buttons/meeting-complete/api";
import { actionStore } from "@common/pages/actions/[id]/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class MeetingCompleteButtonStore {
  private _isLoading: boolean = false;
  private _isNew: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  setIsNew = (isNew: boolean) => {
    runInAction(() => {
      this._isNew = isNew;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  meetingCompleteAction = async (id: number) => {
    this.setIsLoading(true);
    const response = await putMeetingCompleteAction(id);
    if (isSuccessResponse(response)) {
      actionStore.reLoadAction().then(() => {
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          type: "success",
          title: "Meeting complete successfully",
        });
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Meeting complete failed",
        type: "error",
        description: response.data?.Errors,
      });
    }

    this.setIsLoading(false);
  };
}
export const meetingCompleteStore = new MeetingCompleteButtonStore();
const meetingCompleteStoreContext = createContext(meetingCompleteStore);
export const useMeetingCompleteButtonStore = () => {
  return useContext(meetingCompleteStoreContext);
};
