import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";
import "./_index.scss";

export const ContactFromNarDialog = (props: any) => {
  return (
    <CCDialog
      maxWidth="65%"
      maxHeight="60%"
      titleHeader={"Pick Contact From Nar"}
      onClose={props.onCloseDialog}
      bodyElement={
        <div className="cc-contact-from-nar-dialog">
          <label className="cc-label">Search</label>
          <FormElement>
            <div className="cc-contact-form-row">
              <div className="cc-field">
                <label className="cc-label">NAR number</label>

                <Input className="cc-form-control" />
              </div>
              <div className="cc-field">
                <label className="cc-label">First name</label>

                <Input className="cc-form-control" />
              </div>

              <div className="cc-field">
                <label className="cc-label"> Last name / organisation</label>

                <Input className="cc-form-control" />
              </div>
              <div className="cc-field">
                <label className="cc-label">Address</label>

                <Input className="cc-form-control" />
              </div>
              <div className="cc-field">
                <label className="cc-label">Phone</label>

                <Input className="cc-form-control" />
              </div>
              <div className="cc-field cc-contact-field-submit">
                <Button themeColor="primary">Find</Button>
              </div>
            </div>
          </FormElement>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={props.onCloseDialog}>
            Cancel
          </Button>
          <Button className="cc-dialog-button" themeColor="primary">
            Select
          </Button>
        </div>
      }
    />
  );
};
