import {
  DTO_Charge_LOVs,
  DTO_Workflow_AssessmentModifyChargeDetail,
  ModifChargeDetailsRequestObjFromSpatial,
} from "@app/products/property/assessments/components/dialogs/modify-charge-details/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowModifyChargeDetail = async (
  data: ModifChargeDetailsRequestObjFromSpatial
): Promise<APIResponse<DTO_Workflow_AssessmentModifyChargeDetail>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifychargedetail/new`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewModifyChargeDetail = async (
  mode: WorkflowProcessMode,
  payload: DTO_Workflow_AssessmentModifyChargeDetail | undefined
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_AssessmentModifyChargeDetail>
  >
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifychargedetail/process/${mode}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getModifyChargeDetailDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_AssessmentModifyChargeDetail>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/modifychargedetail/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataModifyChargeDetail =
  | [
      APIResponse<DTO_Charge_LOVs>,
      APIResponse<DTO_Workflow_AssessmentModifyChargeDetail>
    ]
  | APIResponse<DTO_Charge_LOVs | DTO_Workflow_AssessmentModifyChargeDetail>
  | undefined;
export const getInitialDataModifyChargeDetail = async (
  workflowDraftId: number | undefined,
  data: ModifChargeDetailsRequestObjFromSpatial
): Promise<IGetInitialDataModifyChargeDetail> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Charge_LOVs>(
        `api/property/internal/workflow/modifyChargeDetail/lovs`
      ),
      !isNil(workflowDraftId)
        ? getModifyChargeDetailDraft(workflowDraftId)
        : postNewWorkflowModifyChargeDetail(data),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
