import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCurrentByStatus: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Ward,
    title: "Ward",
    width: 150,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ApplicationNo,
    title: "Application Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.AppealNo,
    title: "Appeal Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CouncilRepresentation,
    title: "Council Representation",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ReasonForPermit,
    title: "Proposal",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DateLodged,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.ApplicationOfficerDecision,
    title: "Officer Decision",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ApplicationDecision,
    title: "Council Decision",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ApplicationDateDecided,
    title: "Decision Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DateAppealed,
    title: "Appeal Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Grounds,
    title: "Grounds",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.HearingDate,
    title: "Hearing Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.HearingType,
    title: "Hearing Type",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Decision,
    title: "Tribunal Decision",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DecisionDate,
    title: "Tribunal Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.TribunalMember,
    title: "Tribunal Member",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DecisionStatus,
    title: "Tribunal Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.KeyIssues,
    title: "Key Issues",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DecisionPolicyIssues,
    title: "Decision/Policy Issues",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CouncilPosition,
    title: "Council Position",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CouncilPositionDate,
    title: "Position Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.HearingCost,
    title: "Cost",
    width: 150,
  },
];
