import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colInspections: IColumnFields[] = [
  {
    field: "InspectionDate",
    title: "Inspection Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "Clearance",
    title: "Clearance",
  },
  {
    field: "Outcome",
    title: "Outcome",
  },
  {
    field: "ClearBy",
    title: "Clear By",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "SpecificInstructions",
    title: "Specific Instructions",
  },
  {
    field: "Inspector",
    title: "Inspector",
  },
  {
    field: "DocsExist",
    title: "Docs Exist",
  },
  {
    field: "AdditionalNotes",
    title: "Additional Notes",
  },
  {
    field: "InspectionId",
    title: "Inspection ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
