import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { UpdateChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/update/_index";
import { DeletePICChargeRunButton } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/buttons/delete/_index";
import { PropertyChargeAndNoticeRunsForm } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/general/_index";
import { ChargeRunPIC } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/pic/_index";
import { ChargeAndNoticeRunsDetailTab } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/reference-sidebar/detail/_index";
import { ePICChargeRunsType } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/model";
import { usePICChargeRunsStore } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/store";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { eAccordion } from "@app/products/property/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPropertyChargeAndNoticeRuns = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    picChargeRun,
    picChargeRunsId,
    isLoading,
    responseLoadError,
    loadPICChargeRuns,
  } = usePICChargeRunsStore();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        picChargeRunsId,
        picChargeRun?.ChargeName,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `PIC charge runs - ${picChargeRunsId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Property,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Charge_Run_PIC,
      Record_ID: picChargeRunsId ?? 0,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.PIC_Charge_Run_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Charge_Run_PIC}
        />
      ),
    },
    {
      accordionId: eAccordion.PIC_Charge_Run_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.PIC_Charge_Run}
        />
      ),
    },
    {
      accordionId: eAccordion.PIC_Charge_Run_PIC,
      title: "PIC",
      component: <ChargeRunPIC />,
      isVisible: !isEqual(
        picChargeRun?.ChargeRunType,
        ePICChargeRunsType.RateRun
      ),
    },
  ];

  const getFormTitle = () => {
    return `PIC Charge Run ${picChargeRunsId}${
      picChargeRun?.ChargeName ? `: ${picChargeRun?.ChargeName}` : ""
    }`;
  };

  const isUpdated = picChargeRun && picChargeRun?.UpdatedBy ? true : false;

  const tagInTitle = isUpdated ? "Updated" : "Proposed";

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="PIC Charge Run" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPICChargeRuns(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle()} badge={tagInTitle} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <DeletePICChargeRunButton disabled={isUpdated} />
                <UpdateChargeRun
                  disabled={isUpdated}
                  isPicChargeRun={picChargeRun?.CRT_Is_Pic ?? false}
                />
              </CCNavButton>,

              <CCNavButton title={"Add"} type="sub">
                <CCNavButton title={"Add item"} />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Charge_Run_PIC}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Charge_Run_PIC}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {picChargeRun && (
                  <CCGeneralPanel
                    component={<PropertyChargeAndNoticeRunsForm />}
                  />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <ChargeAndNoticeRunsDetailTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={
                            RECORDTYPE.CommunityProperty_Charge_Run_PIC
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
