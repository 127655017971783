import { assetsManagerRoute } from "@app/products/ams/assets/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const amsRoute: ICCRoute = {
  path: "ams",
  name: "Community Asset",
  enum: eProductType.CommunityAsset,
  children: [
    assetsManagerRoute,
    {
      name: "Reports",
      path: "reports",
      children: [
        {
          name: "Computer Asset Report",
          path: "computerassetreport",
          component:
            require("./assets/reports/computer-asset-report/_index.tsx")
              .default,
        },
        {
          name: "Computer Warranty Report",
          path: "computerwarrantyreport",
          component:
            require("./assets/reports/computer-warranty-report/_index.tsx")
              .default,
        },
      ],
    },
  ],
};
