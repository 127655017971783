import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const PropertyLookupPickerMessage = "PropertyLookupPickerMessage";
export const propertyLookupPickerStatuses = [0];
const nameOf = nameOfFactory<DTO_Assessment>();
export const colDDAssessmentLookup: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    width: "120px",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
    width: "150px",
  },
  {
    field: nameOf("Assess_Balance_Outstanding"),
    title: "Outstanding Balance",
    width: "120px",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
    width: "150px",
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
    width: "150px",
  },
  {
    field: nameOf("Assess_Legal_Description"),
    title: "Legal Description",
    width: "150px",
  },
];
