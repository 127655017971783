import { DTO_AssociatedItem_Supplementary } from "@app/products/property/components/associations/components/supplementary/model";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Supplementary>();

export const colAssociationsSupplementary: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: DTO_AssociatedItem_Supplementary) => {
      return `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/${dataItem.Id}`;
    },
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Supp_Name"),
    title: "Supplementary Name",
  },
  {
    field: nameOf("Supp_Reference"),
    title: "Supplementary Reference",
  },
  {
    field: nameOf("Supp_Date"),
    title: "Supplementary Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Supp_Notes"),
    title: "Supplementary Notes",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Instalment_Plan"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("Supp_Creation_DateTime"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Supp_Creation_UserId"),
    title: "Created By",
  },
];
