import { getFullAddressHTML } from "@common/utils/formatting";
import { isEmpty, isNil, isString } from "lodash";

export const requiredAddressValidation = (object: any) => {
  const value = getFullAddressHTML(object);
  const message = "This field is required.";
  if (isNil(value)) return message;
  if (isString(value) && (isEmpty(value.trim()) || isEmpty(value)))
    return message;
  return "";
};
