import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  ChargeViews,
  DTO_Assessment_ChargeViews,
  DTO_Assessment_Charges,
} from "./model";

export const getPropertyAssessmentChargeViews = async (): Promise<
  APIResponse<DTO_Assessment_ChargeViews | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_ChargeViews>(
      `/api/property/internal/assessmentchargeviews`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPropertyAssessmentAllCharges = async (
  assessmentId: number,
  view: ChargeViews = ChargeViews.All,
  ratingPeriodId?: number,
  showCancelledCharges: boolean = false,
  showPaidCharges: boolean = false,
  loadDetails: boolean = true
): Promise<APIResponse<DTO_Assessment_Charges | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_Charges>(
      `/api/property/internal/assessment/${assessmentId}/charges`,
      {
        params: {
          view,
          ratingPeriodId,
          showCancelledCharges,
          showPaidCharges,
          loadDetails,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
