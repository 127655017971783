import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { KeywordTabDetails } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/detail/model";

export const getKeywordTabDetailsById = async (
  keywordID: number
): Promise<APIResponse<KeywordTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<KeywordTabDetails>(
      `/api/core/internal/keyword/${keywordID}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
