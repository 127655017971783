import { mockAddMemo } from "@app/products/property/meters/[id]/components/action-bar/dialogs/memo/mock";
import { sleep } from "@common/utils/common";

export const loadMemoData = async () => {
  await sleep(300);
  return {
    ...mockAddMemo,
    MemoId: Math.floor(Math.random() * 100) + 1,
    Date: new Date(),
    ChangedOn: new Date(),
  };
};
