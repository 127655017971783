import { ChecklistQuestion } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";

import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { CCEditor } from "@components/cc-editor/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import Loading from "@components/loading/Loading";
import { EditorChangeEvent } from "@progress/kendo-react-editor";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import { default as React } from "react";

export const HINT_FORM_STEP = "HintStep";
export const HintStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOf = nameOfFactory<ChecklistQuestion>();
const FormStepElement = observer(
  ({
    formRenderProps,
    options = {
      isDisabled: false,
    },
  }: IFormStepElement) => {
    const { onChange } = formRenderProps;
    const { isFirstLoadingCheckListQuestionDialog } =
      useDynamicQuestionListsStore();

    if (isFirstLoadingCheckListQuestionDialog) {
      return <Loading isLoading={isFirstLoadingCheckListQuestionDialog} />;
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Hint" />
            <Field
              name={nameOf("Hint")}
              component={CCEditor}
              disabled={options?.isDisabled}
              onChange={(event: EditorChangeEvent) => {
                onChange(nameOf("Hint"), {
                  value: event.html,
                });
              }}
            />
          </div>
        </div>
      </section>
    );
  }
);
