import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const RecordsContentPlus = observer(() => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const fieldContentPlusInfoKey = getConfigDataField(
    ECorporateSettingsField.bluepointContentPlus_FileInfoKey
  );
  const fieldContentPlusDocumentURL = getConfigDataField(
    ECorporateSettingsField.bluepointContentPlus_LaunchUrl
  );
  const fieldContentPlusSearchMethod = getConfigDataField(
    ECorporateSettingsField.bluepointContentPlus_FolderSearchMethod
  );
  const fieldContentPlusAttachmentTitleFormat = getConfigDataField(
    ECorporateSettingsField.bluepointContentPlus_AttachmentTitleFormat
  );

  return (
    <>
      {fieldContentPlusInfoKey && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldContentPlusInfoKey}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldContentPlusDocumentURL && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldContentPlusDocumentURL}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldContentPlusSearchMethod && (
        <div className="cc-form-cols-1">
          <CCSearchComboBoxGlobalSettings
            data={fieldContentPlusSearchMethod}
            isEditing={isEditing}
          />
        </div>
      )}

      {fieldContentPlusAttachmentTitleFormat && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={fieldContentPlusAttachmentTitleFormat}
            isEditing={isEditing}
          />
        </div>
      )}
    </>
  );
});
