import { ICCRoute } from "@common/constants/ICCRoute";

export const contactActiveRoute: ICCRoute = {
  path: "active",
  name: "Active",
  children: [
    {
      path: "all",
      name: "All",
    },
    {
      path: "abc",
      name: "ABC",
    },
    {
      path: "def",
      name: "DEF",
    },
    {
      path: "ghi",
      name: "GHI",
    },
    {
      path: "jkl",
      name: "JKL",
    },
    {
      path: "mno",
      name: "MNO",
    },
    {
      path: "pqrs",
      name: "PQRS",
    },
    {
      path: "tuv",
      name: "TUV",
    },
    {
      path: "wxyz",
      name: "WXYZ",
    },
    {
      path: ":filter(all|abc|def|ghi|jkl|mno|pqrs|tuv|wxyz)",
      component: require("./_filter/_index").default,
    },
  ],
};
