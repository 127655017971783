import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useActionTypesStore } from "@common/pages/settings/lookups/action-types/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const LookupActionTypesHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { actionType } = useActionTypesStore();
  const actionTypeId: number =
    actionType?.ActionType_ID ?? lastSelectedRow?.ID ?? 0;
  return (
    <Journal
      id={actionTypeId}
      triggersReload={actionType}
      recordType={RECORDTYPE.CORE_ActionType}
    />
  );
});
