import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { getPicTransactions } from "@app/products/property/pic/[id]/components/child-screens/transactions/api";
import { colPicTransactions } from "@app/products/property/pic/[id]/components/child-screens/transactions/config";
import { DTO_PIC_Transaction } from "@app/products/property/pic/[id]/components/child-screens/transactions/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { isArray } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class PicTransactionsStore {
  private _transactions?: DTO_PIC_Transaction[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _transactionCols: IColumnFields[] = colPicTransactions;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get transactions() {
    return toJS(this._transactions);
  }
  setTransactions = (transactions?: DTO_PIC_Transaction[]) => {
    runInAction(() => {
      this._transactions = transactions;
    });
  };

  get transactionCols() {
    return toJS(this._transactionCols);
  }
  setTransactionCols = (transactionCols: IColumnFields[]) => {
    runInAction(() => {
      this._transactionCols = transactionCols;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._transactions = [];
      this._isLoading = false;
      this._transactionCols = [];
      this._responseLoadError = undefined;
    });
  };

  loadTransactions = async (picId: number) => {
    this.setIsLoading(true);
    const [transactionData, transactionCols] = await Promise.all([
      getPicTransactions(picId),
      loadViewConfiguresColumns(
        ViewConfiguration.PIC_Transactions,
        colPicTransactions
      ),
    ]);
    let errorResponse = undefined;
    let newTransaction: DTO_PIC_Transaction[] | undefined = undefined;
    let newTransactionCols: IColumnFields[] = [];
    if (isSuccessResponse(transactionData) && isArray(transactionCols)) {
      newTransaction = transactionData.data?.Transactions;
      const transactionsTotals: any = transactionData.data?.Totals ?? [];
      newTransactionCols =
        transactionCols?.map((item: IColumnFields) => ({
          ...item,
          calculateFooterCellValue: () => transactionsTotals?.[item.field],
        })) ?? [];
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setTransactions(newTransaction);
    this.setTransactionCols(newTransactionCols);
    this.setIsLoading(false);
  };
}

const picTransactionsStoreContext = createContext(new PicTransactionsStore());
export const usePicTransactionsStore = () => {
  return useContext(picTransactionsStoreContext);
};
