import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const CRMSPhoneMessagesContactTab = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { lastSelectedId } = useCCProductListViewStore();
  const phoneMessageId = id ? parseInt(id) : lastSelectedId;
  const { crmsPhoneMessage } = useCRMSPhoneMessageStore();

  return (
    <ContactsTab
      id={phoneMessageId}
      recordType={RECORDTYPE.CUSTOMERSERVICE_PhoneMessage}
      triggerReload={crmsPhoneMessage}
    />
  );
});
