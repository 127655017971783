import { eventEmitter } from "@/App";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { postHMReopenPremises } from "@app/products/hm/premises/[id]/components/buttons/reopen-premises/api";
import { PremisesReopenWorkflowRequest } from "@app/products/hm/premises/[id]/components/buttons/reopen-premises/model";
import { HM_PREMISES_GET_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  Premises,
  Premises_Status,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import {
  ProvideReason,
  ReasonDialogPlaceID,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IHMReopenPremisesButtonProps {
  isDisabled?: boolean;
}
export const HMReopenPremisesButton = observer(
  ({ isDisabled }: IHMReopenPremisesButtonProps) => {
    const { dataForms, submitFormGetData, setMiddlewareSubmitFormOptions } =
      useFlexibleFormStore();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();

    const premisesObj = dataForms?.GeneralForm as Svc_Premises;
    const premisesData = premisesObj?.Premises as Premises;

    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [objReasonReopen, setObjReasonReopen] = useState<ProvideReason>();

    const handleOnCloseDialog = () => {
      setIsShowDialog(false);
    };

    const getObjReasonReopen = () => {
      const objReason = new ProvideReason();
      objReason.Title_Text = "Reopen Premises";
      objReason.ReasonLabel_Text = "Reason";
      objReason.Flag_ShowDate = false;

      setObjReasonReopen(objReason);
    };

    const handleClickReopen = async () => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataForm = await submitFormGetData("GeneralForm");
      if (!isNil(dataForm?.Premises)) {
        setObjReasonReopen(undefined);
        getObjReasonReopen();
        setIsShowDialog(true);
      }
    };

    const handleSubmitDialog = async (provideReason: ProvideReason) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const generalForm = await submitFormGetData("GeneralForm");
      let premises: Premises = generalForm?.Premises;

      if (premises) {
        const responseRequest = {
          Premises: premises,
          ProvideReason: provideReason,
        } as PremisesReopenWorkflowRequest;

        await reopenPremisesButtonSlider.fetchApi({
          initialData: responseRequest,
        });
      }
    };

    const reopenPremisesButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const reactivateReopenRequest: PremisesReopenWorkflowRequest =
              initialData;
            return postHMReopenPremises(reactivateReopenRequest, cancelToken());
          },
          handleSuccess: async () => {
            setObjReasonReopen(undefined);
            handleOnCloseDialog();
            await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
            eventEmitter.emit(TabTableEventType.RefreshData);
            pushNotificationPortal({
              title: "Premises reopen successfully.",
              type: "success",
            });
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Reopen premises failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
              placeId: ReasonDialogPlaceID,
            });
          },
        },
      ],
    });

    return premisesData?.Status_ENUM === Premises_Status.Closed ? (
      <>
        <CCNavButton
          title="Reopen premises"
          onClick={handleClickReopen}
          disabled={isDisabled || reopenPremisesButtonSlider.isFetching}
        />
        {isShowDialog ? (
          <ReasonDialog
            onClose={handleOnCloseDialog}
            onSubmit={handleSubmitDialog}
            isLoading={reopenPremisesButtonSlider.isFetching}
            initialData={objReasonReopen}
          />
        ) : null}
      </>
    ) : null;
  }
);
