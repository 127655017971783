import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { getMailmergeContent } from "@common/pages/settings/mail-merge/api";
import {
  MailMergeContent,
  QueryParams,
} from "@common/pages/settings/mail-merge/model";
import { extractAttachmentInfo } from "@common/pages/settings/mail-merge/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import fileDownload from "js-file-download";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";

enum DefaultFileCredential {
  FileName = "mail-merge-document",
  Extension = "txt",
}

const MailMergeDownload = () => {
  //Store + Hooks
  const { pushNotification } = useCCAppNotificationStore();
  const location = useLocation();

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState<APIResponseError>();
  const [mailMergeCredentials, setMailMergeCredentials] =
    useState<QueryParams>();
  const [mailMergeContent, setMailMergeContent] = useState<MailMergeContent>();

  const searchParams = new URLSearchParams(location.search);
  const loadMergeDocument = async (mailMergeCredentials: QueryParams) => {
    setIsLoading(true);
    const response = await getMailmergeContent(
      mailMergeCredentials?.batchId ?? "",
      mailMergeCredentials?.owner ?? ""
    );
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      const contentDisposition = response.headers?.["content-disposition"];
      const attachmentInfo = {
        data: response.data,
        fileCredential: extractAttachmentInfo(contentDisposition),
      };
      downloadFile(attachmentInfo);
      setMailMergeContent(attachmentInfo);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error ?? response.statusText,
      };
    }
    setErrorResponse(errorResponse);
    setIsLoading(false);
  };

  const downloadFile = async (mailMergeContent: MailMergeContent) => {
    // Construct the filename with extension
    const fileName =
      mailMergeContent?.fileCredential?.fileName ||
      DefaultFileCredential.FileName;
    const extension =
      mailMergeContent?.fileCredential?.extension ||
      DefaultFileCredential.Extension;
    const fileNameWithExtension = `${fileName}.${extension}`;

    if (mailMergeContent?.data) {
      fileDownload(mailMergeContent.data, fileNameWithExtension);
    } else {
      pushNotification({
        title: "Can't download file, please reload page",
        type: "error",
      });
    }
  };
  const mailMergeFileTitle = useMemo(() => {
    const fileCredential = mailMergeContent?.fileCredential || {
      fileName: DefaultFileCredential.FileName,
      extension: DefaultFileCredential.Extension,
    };
    return `${fileCredential.fileName}.${fileCredential.extension}`;
  }, [mailMergeContent]);

  useEffectOnce(() => {
    const queryParams = Array.from(searchParams).reduce(
      (extractedURL: any, [key, value]) => {
        if (value.startsWith("enc=")) {
          extractedURL["owner"] = value.substring(4);
          extractedURL["isEncrypted"] = true;
        } else {
          extractedURL[key] = value;
        }
        return extractedURL as QueryParams;
      },
      {}
    );
    setMailMergeCredentials(queryParams);
    loadMergeDocument(queryParams);
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Download"} />
      {errorResponse ? (
        <CCLoadFailed
          onReload={() =>
            mailMergeCredentials ? loadMergeDocument(mailMergeCredentials) : {}
          }
          responseError={errorResponse}
        />
      ) : (
        <>
          <FormTitle title={mailMergeFileTitle} />
          <CCManagePageActionBar
            leftActions={
              <CCNavButton
                title="Download"
                onClick={() =>
                  mailMergeContent ? downloadFile(mailMergeContent) : {}
                }
              />
            }
          />
          <CCAppNotification />
        </>
      )}
    </>
  );
};
export default MailMergeDownload;
