import { colChargeRunRebatesApplied } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebates-applied/config";
import { VO_Charge_Run_RebateApplied } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebates-applied/model";
import { useChargeRunRebateAppliedStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebates-applied/store";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const nameOfRebateApplied = nameOfFactory<VO_Charge_Run_RebateApplied>();
export const ChargeRunRebatesApplied = observer(() => {
  const { chargeRunsId } = useChargeRunsStore();
  const {
    viewConfiguration,
    responseLoadError,
    isLoading,
    loadChargeRunRebateAppliedConfig,
    resetStore,
  } = useChargeRunRebateAppliedStore();

  useEffect(() => {
    loadChargeRunRebateAppliedConfig();
    return () => {
      resetStore();
    };
  }, [loadChargeRunRebateAppliedConfig, resetStore]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadChargeRunRebateAppliedConfig();
      }}
    />
  ) : (
    <CCGrid
      dataUrl={`/odata/property/internal/chargerunrebatesappliedregister/${chargeRunsId}?$count=true&`}
      columnFields={processDynamicColumns(
        colChargeRunRebatesApplied,
        viewConfiguration
      )}
      primaryField={nameOfRebateApplied("Levy_Calculation_Id")}
      itemPerPage={10}
    />
  );
});
