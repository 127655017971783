import { filter, maxBy } from "lodash";
import { DTO_LOV_InstalmentPlan } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/instalments/model";
import { DTO_LOV_Number } from "@common/models/odataResponse";

export const latestInstalmentDueDate = (
  instalmentPlanLov: DTO_LOV_Number[],
  value: any
) => {
  const filteredInstalmentPlans = filter(
    instalmentPlanLov,
    (plan: DTO_LOV_InstalmentPlan) =>
      value?.toString()?.includes(plan?.Code?.toString())
  );

  return maxBy(
    filteredInstalmentPlans as unknown as DTO_LOV_InstalmentPlan[],
    (plan: DTO_LOV_InstalmentPlan) => new Date(plan?.DueDate ?? "")
  )?.DueDate;
};
