import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { concessionCardsRoute } from "./concession-cards/route";
import { rebateClaimsRoute } from "./rebate-claims/route";
import { rebateEntitlementsRoute } from "./rebate-entitlement-exceptions/route";

export const rebatesRoute: ICCRoute = {
  path: "rebates",
  name: "Rebates",
  enum: eMenuAction.Rebates_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.RebateEntitlement_List,
      component: require("./list/_index").default,
    },
    // {
    //   path: "rebate-claims",
    //   name: "Rebate Claims",
    //   component: require("./rebate-claims/_index").default,
    // },
    rebateClaimsRoute,
    rebateEntitlementsRoute,
    concessionCardsRoute,
  ],
};
