import { IProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/require-approval/model";
import { RequireApprovalDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/require-approval/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { reptAndRespLetterForApprovalValidatorList } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-rept-and-resp-letter-for-approval/config";
import { useSendReptAndRespLetterForApprovalButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-rept-and-resp-letter-for-approval/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const SendReptAndRespLetterForApprovalButton = observer(() => {
  const { ppr, onSubmit, pprId } = usePPRStore();
  const {
    isLoading,
    setIsShowDialog,
    isShowDialog,
    handleSendReptAndRespLetterForApproval,
  } = useSendReptAndRespLetterForApprovalButtonStore();

  const isVisible = useMemo(() => {
    return reptAndRespLetterForApprovalValidatorList.some(
      (item: Application_Status) => item === ppr?.Status_ENUM
    );
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Send report and response letters for approval"
        onClick={onSubmit}
        isLoading={isLoading}
        name={PPRSubmitActions.SendReptAndRespLetterForApproval}
      />

      {isShowDialog && (
        <RequireApprovalDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          isLoading={isLoading}
          onSubmit={(data: IProvideReason) => {
            handleSendReptAndRespLetterForApproval(pprId, data);
          }}
        />
      )}
    </>
  ) : null;
});
