import { ContactForm } from "@app/core/contacts/_id/components/child-screens/general/_index";
import { ContactSubmitActions } from "@app/core/contacts/_id/model";
import { useContactStore } from "@app/core/contacts/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewContact = observer(() => {
  const { isLoading, responseLoadError, onSubmit, reloadContact } =
    useContactStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Contact"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            reloadContact();
          }}
        />
      ) : (
        <>
          <FormTitle title={"New Contact"} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ContactSubmitActions.New}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <ContactForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
