import { Tooltip } from "@progress/kendo-react-tooltip";
import React from "react";
import "../TooltipValidator/TooltipValidator.scss";

export const TooltipValidator = (props: any) => {
  const content = props.content || "This field is required.";

  return (
    <Tooltip
      position="auto"
      className="cc-tooltip-validator"
      content={(contentProps) => (
        <div>
          {!props.content && (
            <div className="cc-validator-result">Validation Result</div>
          )}

          {contentProps.title}
        </div>
      )}
      anchorElement="target"
      showCallout={false}
    >
      <div
        className="cc-tooltip-icon fas fa-star-of-life"
        title={content}
      ></div>
    </Tooltip>
  );
};
