import { useNewDebtRecoveryDialogStore } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/store";
import { EListSubmitButton } from "@app/products/property/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";
interface ConfirmCloseButtonProps {
  onClose: () => void;
  onConfirmYes: (e: any) => void;
  onConfirmNo: () => void;
}

export const ConfirmCloseButton = observer(
  ({ onConfirmYes, onClose, onConfirmNo }: ConfirmCloseButtonProps) => {
    const { isLoadingOnDialogCancel, isLoadingOnDialog } =
      useNewDebtRecoveryDialogStore();
    return (
      <ConfirmDialog
        title={"Confirmation"}
        message={"Do you wish to retain this workflow?"}
        height={"auto"}
        btnCancelVisible={false}
        btnYesId={EListSubmitButton.ConfirmCloseYes}
        isLoadingNo={isLoadingOnDialogCancel}
        isLoadingYes={isLoadingOnDialog}
        onConfirmNo={() => onConfirmNo()}
        onClosePopup={() => onClose()}
        onAsyncConfirm={(e) => onConfirmYes(e)}
      />
    );
  }
);
