import { FinanceFeeParentSection } from "@app/core/fees/[id]/components/child-screens/general/components/form-element/components/parent-section/_index";
import { Fee } from "@app/core/fees/[id]/model";
import { useFeesStore } from "@app/core/fees/[id]/store";
import { replaceBrTagWithBreakLine } from "@app/core/fees/[id]/util";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { postPickFeeType } from "@app/core/invoice/invoice-item-accordion/api";
import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import {
  FINANCEEXPORTOVERRIDEREASON,
  FINANCEMETHOD,
  FINANCESYSTEM,
} from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FeeType } from "@common/pages/settings/lookups/fee-types/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { mapEnum, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isNil, isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IFeesFormElementProps {
  formRenderProps: FormRenderProps;
}
const nameOf = nameOfFactory<Fee>();
const nameOfFeeType = nameOfFactory<FeeType>();

export const FeesFormElement = observer(
  ({ formRenderProps }: IFeesFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const {
      financeFee,
      isSuperAdmin,
      parents,
      setLitDisplayStatus,
      setIsLoading,
    } = useFeesStore();
    const { settings } = useCommonCoreStore();
    const { pushNotification } = useCCAppNotificationStore();
    const isNew = useIsNew();

    const isEnableSubsidies = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Enable_Subsidies]
    );
    const isEnableMultiLineInvoice = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
    );
    const subsidiesLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Subsidies_Label]
    );

    const financeSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FinanceSystem]
    );
    const isEnableUpToGlassFinance = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
    );

    const financeMethod =
      getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
      ) ?? FINANCEMETHOD.None;

    const feeType = valueGetter(nameOf("FeeType"));
    const amount = valueGetter(nameOf("Amount"));
    const GST = valueGetter(nameOf("GST"));
    const amountEXGST = valueGetter(nameOf("AmountEXGST"));

    const handleChangeCreditNote = (event: SwitchChangeEvent) => {
      onChange(nameOf("Flag_CreditNote"), { value: event.value });
      onChange(nameOf("Description"), {
        value: event.value ? "" : financeFee?.FinanceFee?.Description,
      });
      checkCreditNote(event.value);
    };

    const checkCreditNote = (flagCreditNote: boolean) => {
      if (amount) {
        onChange(nameOf("Amount"), {
          value: returnAmount(amount, flagCreditNote),
        });
      }
      if (GST) {
        let litGST = GST.toString();
        litGST =
          litGST.indexOf("-") > -1 ? litGST.substring(2) : litGST.substring(1);
        onChange(nameOf("GST"), {
          value: returnAmount(litGST, flagCreditNote),
        });
      }
      if (amountEXGST) {
        let litAmountEXGST = amountEXGST.toString();
        litAmountEXGST =
          litAmountEXGST.indexOf("-") > -1
            ? litAmountEXGST.substring(2)
            : litAmountEXGST.substring(1);
        onChange(nameOf("AmountEXGST"), {
          value: returnAmount(litAmountEXGST, flagCreditNote),
        });
      }
      if (flagCreditNote) {
        setLitDisplayStatus("** Credit Note **");
      } else {
        setLitDisplayStatus(undefined);
      }
    };

    const returnAmount = (amount: string, isNegative: boolean) => {
      let feeAmount = 0;
      feeAmount = parseFloat(amount);
      feeAmount = Math.abs(feeAmount);

      if (isNegative) feeAmount = feeAmount * -1;
      return feeAmount;
    };

    const feeTypeGST =
      feeType &&
      feeType?.Flag_GSTApplies &&
      feeType?.DefaultAmount_GSTPercentage > 0;

    const enableUpToGlassFinance =
      isEnableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct;

    const exportToFinance = valueGetter(nameOf("ExportedToFinance"));

    const isAmountEditable = useMemo(() => {
      let editable = true;
      if (enableUpToGlassFinance && !isSuperAdmin) {
        if (exportToFinance) {
          editable = false;
        }
      }
      return editable;
    }, [enableUpToGlassFinance, exportToFinance, isSuperAdmin]);

    const ancestorBubbleUpSourceIdentifier =
      financeFee?.FinanceFee?._BubbleUps?.find(
        (bubbleUp: BubbleUpIdentifier) =>
          mapEnum(bubbleUp?.BubbleUpType_ENUM, BubbleUpType) ===
          BubbleUpType.Ancestor
      )?.SourceIdentifier;

    const isInfringementTicket =
      ancestorBubbleUpSourceIdentifier &&
      parseInt(ancestorBubbleUpSourceIdentifier?._RecordSourceType_ENUM) ===
        RECORDTYPE.INFRINGEMENTS_InfringementTicket;

    const isExportDetailsShow =
      ancestorBubbleUpSourceIdentifier && isInfringementTicket
        ? valueGetter(nameOf("ExportOverrideReason_ENUM")) !==
          FINANCEEXPORTOVERRIDEREASON.PENDING
        : true;

    const isExportOverrideDetailsShow =
      ancestorBubbleUpSourceIdentifier && isInfringementTicket
        ? valueGetter(nameOf("ExportOverrideReason_ENUM")) ===
          FINANCEEXPORTOVERRIDEREASON.PENDING
        : true;

    const amountDueLabel = useMemo(() => {
      if (feeTypeGST) {
        return "Amount Due (INC GST):";
      }
      if (valueGetter(nameOf("SecurePay_Reference"))) {
        return "Pending Payment:";
      }
      return "Amount Due:";
    }, [feeTypeGST, valueGetter]);

    const osFees = useMemo(() => {
      let fee = 0;
      const totalPaymentAmount = financeFee?.TotalPaymentAmount;

      if (isNumber(totalPaymentAmount) && !isNil(totalPaymentAmount)) {
        if (valueGetter(nameOf("TotalSubsidyAmount"))) {
          fee =
            valueGetter(nameOf("Amount")) -
            valueGetter(nameOf("TotalSubsidyAmount")) -
            totalPaymentAmount;
        } else {
          fee = valueGetter(nameOf("Amount")) - totalPaymentAmount;
        }
      }
      return fee;
    }, [financeFee?.TotalPaymentAmount, valueGetter]);

    const isDisabledFeeType = useMemo(
      () => enableUpToGlassFinance && !isSuperAdmin && exportToFinance,
      [enableUpToGlassFinance, exportToFinance, isSuperAdmin]
    );

    const handleChangeFeeType = async (data: any) => {
      setIsLoading(true);
      const response = await postPickFeeType(
        financeFee?.FinanceFee?._BubbleUps ?? [],
        data?.ID
      );
      setIsLoading(false);
      const newFee = response?.data?.ReturnObj;
      if (isSuccessResponse(response) && newFee) {
        onChange(nameOf("FeeType"), {
          value: newFee?.FeeType,
        });
        onChange(nameOf("FeeType_ID"), { value: data.ID });
        if (newFee?.FeeType) {
          onChange(nameOf("Description"), { value: newFee.Description });
          if (!isNil(newFee?.FeeType?.DefaultAmount)) {
            onChange(nameOf("Amount"), { value: newFee.Amount });
          }
          if (
            newFee?.FeeType.Flag_GSTApplies &&
            newFee?.FeeType.DefaultAmount_GSTPercentage > 0
          ) {
            onChange(nameOf("AmountEXGST"), { value: newFee.AmountEXGST });
            onChange(nameOf("GST"), { value: newFee.GST });
          }
          if (
            newFee?.FeeType?.NextEffectiveDate &&
            newFee?.FeeType.NextEffectiveDate <= new Date() &&
            !isNil(newFee?.FeeType?.NextAmount)
          ) {
            onChange(nameOf("Amount"), { value: newFee.Amount });
            onChange(nameOf("AmountEXGST"), { value: newFee.AmountEXGST });
            onChange(nameOf("GST"), { value: newFee.GST });
          }
          onChange(nameOf("CostCode"), { value: newFee.CostCode });
          if (newFee?.FeeType?.Bond && newFee?.FeeType.Bond === true) {
            onChange(nameOf("Bond"), { value: newFee.Bond });
            onChange(nameOf("BT_Name"), { value: newFee.BT_Name });
          }
        }
      } else {
        pushNotification({
          type: "error",
          autoClose: false,
          title: response?.error ?? "Select fee type failed",
        });
      }
    };

    return (
      <FormElement>
        <FinanceFeeParentSection recordType={parents?.recordType} />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Fee type" isMandatory />
              <Field
                name={nameOf("FeeType")}
                component={FeeTypesPicker}
                isGetDetail={false}
                selectableMode="single"
                onPickFeeTypes={handleChangeFeeType}
                textField={nameOfFeeType("Name")}
                validator={requiredValidator}
                productType={parents?.productType}
                disabled={isDisabledFeeType}
              />
            </div>

            {feeType && feeType.Refund && (
              <div className="cc-field">
                <CCLabel title="Credit note" />
                <Field
                  name={nameOf("Flag_CreditNote")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_CreditNote"))}
                  onChange={handleChangeCreditNote}
                />
              </div>
            )}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel
                title={feeTypeGST ? "Amount (INC GST)" : "Amount"}
                isMandatory
              />
              <Field
                name={nameOf("Amount")}
                component={CCNumericTextBox}
                format={CURRENCY_FORMAT.CURRENCY2}
                validator={requiredValidator}
                readOnly={!isAmountEditable}
              />
            </div>

            {feeTypeGST && (
              <>
                <div className="cc-field">
                  <CCValueField
                    label="GST"
                    value={valueGetter(nameOf("GST"))}
                    format={CURRENCY_FORMAT.CURRENCY2}
                  />
                </div>

                <div className="cc-field">
                  <CCValueField
                    label="Amount (EX GST)"
                    value={valueGetter(nameOf("AmountEXGST"))}
                    format={CURRENCY_FORMAT.CURRENCY2}
                  />
                </div>
              </>
            )}
          </div>

          {isEnableSubsidies ? (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label={
                    subsidiesLabel ? `Total ${subsidiesLabel}` : "Total subsidy"
                  }
                  value={valueGetter(nameOf("TotalSubsidyAmount")) ?? "0"}
                />
              </div>

              <div className="cc-field">
                <CCValueField
                  label={
                    subsidiesLabel
                      ? `Total Amount after ${subsidiesLabel}`
                      : "Total amount"
                  }
                  value={valueGetter(nameOf("TotalAmountInclSubsidy")) ?? "0"}
                />
              </div>
            </div>
          ) : null}

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Service date" />
              <Field
                name={nameOf("ServiceDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={isEnableMultiLineInvoice}
              />
            </div>

            <div className="cc-field">
              <CCLabel title="Invoiced" />
              <Field
                name={nameOf("InvoicedDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={
                  isEnableMultiLineInvoice ||
                  (valueGetter(nameOf("ExportedToFinance")) &&
                    valueGetter(nameOf("InvoicedDate")))
                }
              />
            </div>

            <div className="cc-field">
              <CCLabel title="Payment due" />
              <Field
                name={nameOf("InvoicePayByDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={isEnableMultiLineInvoice}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCValueField
                label={
                  financeSystem === FINANCESYSTEM.Proclaim
                    ? "RAM process counter"
                    : "Debtor number"
                }
                value={valueGetter(nameOf("DebtorNumber"))}
              />
            </div>

            <div className="cc-field">
              <CCValueField
                value={valueGetter(nameOf("InvoiceNumber"))}
                label="Invoice number"
              />
            </div>

            <div className="cc-field">
              <CCValueField
                value={valueGetter(nameOf("CostCode"))}
                label="Cost code"
              />
            </div>
          </div>

          {valueGetter(nameOf("SecurePay_Reference")) ? (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  value={valueGetter(nameOf("SecurePay_Reference"))}
                  label="Payment reference"
                />
              </div>
            </div>
          ) : null}

          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel
                title="Description"
                isMandatory={
                  valueGetter(nameOf("Flag_CreditNote")) &&
                  feeType &&
                  feeType.Refund
                }
              />
              <Field
                name={nameOf("Description")}
                component={CCTextArea}
                placeholder="Description"
                rows={2}
                // The function auto-generate fee returns the < br/> tag to show the new line in the HUB but The RC doesn't need it
                value={replaceBrTagWithBreakLine(
                  valueGetter(nameOf("Description"))
                )}
                validator={
                  valueGetter(nameOf("Flag_CreditNote")) &&
                  feeType &&
                  feeType.Refund
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          </div>

          {parents?.bond || valueGetter(nameOf("Bond")) ? (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Bond type name" />
                <Field name={nameOf("BT_Name")} component={CCInput} />
              </div>
            </div>
          ) : null}
        </section>

        {isNew ? null : (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCValueField
                    value={valueGetter(nameOf("BPayReference"))}
                    label="BPay reference"
                  />
                </div>

                <div className="cc-field">
                  <CCValueField
                    value={valueGetter(nameOf("BillPayReference"))}
                    label="BillPay reference"
                  />
                </div>

                <div className="cc-field">
                  <CCValueField
                    value={valueGetter(nameOf("BPAY_iCRN"))}
                    label="Bpay iCRN"
                  />
                </div>

                <div className="cc-field">
                  <CCValueField
                    value={valueGetter(nameOf("KeyLineReference"))}
                    label="Keyline reference"
                  />
                </div>

                <div className="cc-field">
                  <CCValueField
                    value={valueGetter(nameOf("OCRReference"))}
                    label="OCR reference"
                  />
                </div>
              </div>
            </section>
          </>
        )}

        {financeSystem !== FINANCESYSTEM.None ? (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Exported to finance" />
                  <Field
                    name={nameOf("ExportedToFinance")}
                    checked={valueGetter(nameOf("ExportedToFinance")) ?? false}
                    component={CCSwitch}
                    disabled={enableUpToGlassFinance && !isSuperAdmin}
                  />
                </div>

                {isExportDetailsShow ? (
                  <>
                    <div className="cc-field">
                      <CCValueField
                        label="Batch number"
                        value={valueGetter(nameOf("ExportedToFinanceBatch"))}
                      />
                    </div>
                    <div className="cc-field">
                      <CCValueField
                        label="Date"
                        value={valueGetter(nameOf("ExportedToFinanceDate"))}
                        format={DATE_FORMAT.DATE}
                      />
                    </div>
                  </>
                ) : null}

                {isExportOverrideDetailsShow ? (
                  <div className="cc-field">
                    <CCValueField
                      label="Export override reason"
                      value={valueGetter(nameOf("ExportOverrideReason_Name"))}
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </>
        ) : null}

        {!enableUpToGlassFinance && !isNew ? (
          <>
            <hr className="cc-divider" />
            <div className="cc-field">
              <CCValueField
                label={amountDueLabel}
                value={osFees ?? "Amount"}
                format={osFees ? CURRENCY_FORMAT.CURRENCY2 : ""}
              />
            </div>
          </>
        ) : null}
      </FormElement>
    );
  }
);
