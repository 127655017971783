export const JournalBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Journal_Number
      ? `- ${selectedRow.Journal_Number}`
      : "";
    return `Property - Journals ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    const dynamicDetail = "Journal " + (selectedRow.Journal_Number ?? "");
    return `${dynamicDetail}`;
  },
};
