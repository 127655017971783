export interface IAccounts {
  Register: string;
  AccountReference: string;
  AccountDescription?: string;
  PropertyAddress?: string;
  AccountId: number | null;
}

export enum SearchType {
  AccountReference = "Account Reference",
  AccountId = "Account Id",
}
export enum SearchField {
  AccountReference = "_searchByAccountReference",
  AccountId = "_searchByAccountId",
}

export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AccountReference]: SearchField.AccountReference,
  [SearchType.AccountId]: SearchField.AccountId,
};

export interface IAccountsStep {
  Accounts: IAccounts[];
}
