import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { MoveOrgStructureButton } from "@common/pages/settings/security/org-structure/components/action-bar/buttons/move-org-unit/_index";
import { NewOrgStructureButton } from "@common/pages/settings/security/org-structure/components/action-bar/buttons/new-keywords/_index";
import { colSecurityOrgStructure } from "@common/pages/settings/security/org-structure/config";
import { SECURITY_ORG_STRUCTURE_LIST_VIEW_URL } from "@common/pages/settings/security/org-structure/constant";
import { SecurityOrgStructureBookmark } from "@common/pages/settings/security/org-structure/util";
import { SecurityOrgStructureTabDetails } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/detail/_index";
import { SecurityOrgStructureHistoryTab } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/history/_index";
import { SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE } from "@common/pages/settings/security/org-structure/_id/constant";
import { OrgUnit_ListView } from "@common/pages/settings/security/org-structure/_id/model";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<OrgUnit_ListView>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SECURITY_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <NewOrgStructureButton disabled={!hasPermission} />,
      <MoveOrgStructureButton disabled={!hasPermission} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_OrgUnit}
        detail={SecurityOrgStructureBookmark.getBookmarkDetail}
        displayName={SecurityOrgStructureBookmark.getBookmarkDisplayName}
        listViewDetail={SecurityOrgStructureBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          SecurityOrgStructureBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SecurityOrgStructureTabDetails /> },
      {
        title: "History",
        component: <SecurityOrgStructureHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colSecurityOrgStructure}
      primaryField={nameOf("ID")}
      dataUrl={SECURITY_ORG_STRUCTURE_LIST_VIEW_URL}
      state={{
        sort: [{ field: nameOf("Hierarchy"), dir: "asc" }],
      }}
      disabled={!hasPermission}
    />
  );
};
