import { useLookupStateRoadDetailsStore } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/detail/store";
import { useLookupStateRoadStore } from "@common/pages/settings/lookups/state-roads/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export const LookupStateRoadTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { stateRoad } = useLookupStateRoadStore();
  const {
    lookupStateRoadDetails,
    loadLookupStateRoadDetails,
    isLoading,
    responseLoadError,
  } = useLookupStateRoadDetailsStore();

  useEffect(() => {
    const stateRoadID = stateRoad?.StateRoad_ID ?? lastSelectedRow?.ID;
    if (stateRoadID) {
      loadLookupStateRoadDetails(stateRoadID);
    }
    //eslint-disable-next-line
  }, [lastSelectedRow, stateRoad]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() =>
          loadLookupStateRoadDetails(
            stateRoad?.StateRoad_ID ?? lastSelectedRow?.ID
          )
        }
      />
    );

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Road name:"}
          value={lookupStateRoadDetails?.RoadName}
        />
        <ProductReferenceRow
          title={"Road number:"}
          value={lookupStateRoadDetails?.RoadNumber}
        />
        <ProductReferenceRow
          title={"Road ID number:"}
          value={lookupStateRoadDetails?.RoadIDNo}
        />
        <ProductReferenceRow
          title={"Road start name:"}
          value={lookupStateRoadDetails?.RoadStartName}
        />
        <ProductReferenceRow
          title={"Road start locality:"}
          value={lookupStateRoadDetails?.RoadStartLocality}
        />
        <ProductReferenceRow
          title={"Road end name:"}
          value={lookupStateRoadDetails?.RoadEndName}
        />
        <ProductReferenceRow
          title={"Road end locality:"}
          value={lookupStateRoadDetails?.RoadEndLocality}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
