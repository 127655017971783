import { history } from "@/AppRoutes";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { IConfigActivitiesTownPlanning } from "@common/pages/home/components/townplanning/model";
import { useTownPlanningDashboardStore } from "@common/pages/home/components/townplanning/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
export const TownPlanningActivity = observer(() => {
  const { townPlanningActivities, isLoadingDashboardTownPlanning } =
    useTownPlanningDashboardStore();

  return (
    <div className="cc-activity-group">
      <div className="cc-activity-group-title">
        {getProductTitle(PRODUCT_TYPE.TownPlanning)}
      </div>
      <div className="cc-activity-group-container">
        {isLoadingDashboardTownPlanning ? (
          <Loading isLoading />
        ) : (
          townPlanningActivities?.map(
            (item: IConfigActivitiesTownPlanning, index: number) => {
              return (
                <div
                  className="cc-activity-item"
                  onClick={() => {
                    if (item?.link) {
                      ///TODO: Remove this line when we have a solution for storing grid state
                      localStorage.removeItem("gridStoreURL");
                      history.push(item.link, item.childData);
                    }
                  }}
                  key={index}
                >
                  <div className="cc-activity-name">{item.title}</div>
                  <div className="cc-activity-number">{item.value}</div>
                </div>
              );
            }
          )
        )}
      </div>
    </div>
  );
});
