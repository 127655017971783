import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const allDocumentsRoute: ICCRoute = {
  path: "all-documents",
  name: "All Documents",
  enum: eMenuAction.Compliance_ActionsAndLetters_AllDocuments,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Compliance_ActionsAndLetters_AllDocuments_All,
      component: require("./all/_index").default,
    },
  ],
};
