import { DTO_Create_Supplementary_LOVs_Response } from "@app/products/property/supplementary-rates/[id]/components/dialogs/new-supplementary/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getNewSupplementaryLOVs = async (): Promise<
  APIResponse<DTO_Create_Supplementary_LOVs_Response>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Create_Supplementary_LOVs_Response>(
      `/api/property/internal/Supplementary/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
