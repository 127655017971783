import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataJournalAssessmentRaiseInterest,
  postProcessJournalAssessmentRaiseInterest,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/api";
import { RaiseInterestFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/components/form-elements/raise-interest/_index";
import { TaskScheduleFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/components/form-elements/task-schedule/_index";
import { EKeysOfStepsJournalAssessmentRaiseInterest } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/config";
import { DTO_Workflow_JournalAssessmentRaiseInterest } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { useRefreshData } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/useRefreshData";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IJournalAssessmentRaiseInterestDialog {
  onClose: () => void;
  isShowAssessmentInfo?: boolean;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  assessmentId?: number;
}
export const JournalAssessmentRaiseInterestDialog = observer(
  ({
    onClose,
    isShowAssessmentInfo = false,
    dataFromActionList,
    isSaveOnNextStep = false,
    prefixTitle,
    suffixTitle,
    assessmentId,
  }: IJournalAssessmentRaiseInterestDialog) => {
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const {
      isIncompleteMode,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      isFromActionList,
    } = usePropertyWorkflow(dataFromActionList);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [
      journalAssessmentRaiseInterestInitData,
      setJournalAssessmentRaiseInterestInitData,
    ] = useState<DTO_Workflow_JournalAssessmentRaiseInterest>();
    const [lovsJournalData, setLovsJournalData] = useState<DTO_Journal_LOVs>();

    const { refreshPage } = useRefreshData(
      journalAssessmentRaiseInterestInitData?.WorkflowHeader
    );

    const initialValues = useMemo(() => {
      let initRaiseInterest: any = {};
      let initTaskChedule: any = {};
      const workflowDetail =
        journalAssessmentRaiseInterestInitData?.WorkflowDetail;

      if (workflowDetail) {
        initRaiseInterest = {
          ...initRaiseInterest,
          AssessmentId: workflowDetail.AssessmentId,
          AssessmentReference: workflowDetail.AssessmentReference,
          InterestFromDate: workflowDetail.InterestFromDate,
          InterestToDate: workflowDetail.InterestToDate,
          TransactionDate: workflowDetail.TransactionDate,
          TransactionType: workflowDetail.TransactionType,
        };
        initTaskChedule = {
          ...initTaskChedule,
          TaskType: workflowDetail.TaskType,
          TaskName: workflowDetail.TaskName,
          EmailRecipients: workflowDetail.EmailRecipients,
          RunImmediately: workflowDetail.RunImmediately,
          RunAt: workflowDetail.RunAt,
          Journal_Number: workflowDetail.Journal_Number,
        };
      }

      return {
        [EKeysOfStepsJournalAssessmentRaiseInterest.RaiseInterest]:
          initRaiseInterest,
        [EKeysOfStepsJournalAssessmentRaiseInterest.TaskSchedule]:
          initTaskChedule,
        [EKeysOfStepsJournalAssessmentRaiseInterest.Comments]: {},
        [EKeysOfStepsJournalAssessmentRaiseInterest.Documents]: {},
      };
    }, [journalAssessmentRaiseInterestInitData]);

    const steps: IStep[] = [
      {
        label: "Raise interest",
        initialValues: initialValues.RaiseInterest,
        component: RaiseInterestFormStep,
        visible: true,
        key: EKeysOfStepsJournalAssessmentRaiseInterest.RaiseInterest,
        options: {
          isReadOnly,
          isShowAssessmentInfo,
          lovsJournalData,
        },
      },
      {
        label: "Task schedule",
        initialValues: initialValues.TaskSchedule,
        component: TaskScheduleFormStep,
        visible: true,
        key: EKeysOfStepsJournalAssessmentRaiseInterest.TaskSchedule,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        initialValues: {},
        visible: true,
        key: EKeysOfStepsJournalAssessmentRaiseInterest.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: {},
        visible: true,
        key: EKeysOfStepsJournalAssessmentRaiseInterest.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_Assessment_Raise_Interest,
        },
      },
    ];

    //Get labels
    const [assessmentLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) || `${assessmentLabel} Raise Interest`;
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader, assessmentLabel]);

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    const handleParkProcess = async (
      payload: DTO_Workflow_JournalAssessmentRaiseInterest
    ) => {
      setIsLoadingPark(true);

      const parkProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentRaiseInterest> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                `Raise ${assessmentLowercaseLabel} interest was parked successfully.`,
              type: "success",
            });
          },
          defaultFailedMessage: `Raise ${assessmentLowercaseLabel} interest could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => setIsLoadingPark(false);

      await handleProcessWorkflow(parkProps, setLoading);
    };

    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_JournalAssessmentRaiseInterest
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The raise ${assessmentLowercaseLabel} interest will be created based on the information provided. Are you sure you want to submit?`,
      });
    };

    const handleFinishProcess = async (
      payload: DTO_Workflow_JournalAssessmentRaiseInterest
    ) => {
      const finishProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentRaiseInterest> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            clearSelectedItems();
            refreshPage(() =>
              pushNotification({
                title:
                  e?.Notification ??
                  `Raise ${assessmentLowercaseLabel} interest was completed successfully.`,
                type: "success",
              })
            );
          },
          defaultFailedMessage: `Raise ${assessmentLowercaseLabel} interest could not be completed.`,
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {};
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessJournalAssessmentRaiseInterest,
          dataCancel: data,
          defaultSuccessMessage: `Raise ${assessmentLowercaseLabel} interest was cancelled successfully.`,
          defaultErrorMessage: `Raise ${assessmentLowercaseLabel} interest could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };

    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_JournalAssessmentRaiseInterest>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessJournalAssessmentRaiseInterest(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_JournalAssessmentRaiseInterest
    ) => {
      setIsLoadingClose(true);

      const retainProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentRaiseInterest> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ??
                `Raise ${assessmentLowercaseLabel} interest was parked successfully.`,
              type: "success",
            });
          },
          defaultFailedMessage: `Raise ${assessmentLowercaseLabel} interest could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      await handleProcessWorkflow(retainProps, setLoading);
    };

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      const newData = { ...data };
      const processPayload = processData(newData);

      return handleSaveAndNext(processPayload);
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_JournalAssessmentRaiseInterest,
      isCloseDialog: boolean = false
    ): Promise<boolean> => {
      const response = await postProcessJournalAssessmentRaiseInterest(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      const defaultSuccessMessage = `Raise ${assessmentLowercaseLabel} interest was saved successfully.`;
      const defaultFailedMessage = `The raise ${assessmentLowercaseLabel} interest process could not be saved.`;

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.Notification ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
          });
        return false;
      }
    };

    const processData = (data: any) => {
      const workflowDetail: any = {
        ...data.RaiseInterest,
        ...data.TaskSchedule,
      };
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialDataJournalAssessmentRaiseInterest(
        id,
        assessmentId
      );

      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1]) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          setLovsJournalData(lovsJournal?.data);
          setJournalAssessmentRaiseInterestInitData({
            WorkflowDetail: workflowData?.data?.WorkflowDetail,
            WorkflowHeader: workflowData?.data?.WorkflowHeader,
          });
          if (workflowData?.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData?.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ||
                workflowDraftId ||
                0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          const responseError = response as unknown as APIResponse<
            | DTO_Workflow_JournalAssessmentRaiseInterest
            | DTO_Journal_LOVs
            | ResponsePacket
          >;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialValues={initialValues}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            badge={statusBadge}
            maxWidth="800px"
            maxHeight="600px"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={renderProps.nextButton.disabled || isLoadingPark}
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={isLoadingOnNext || isLoadingPark}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {isToBeApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id="cc-next-step-button"
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      isLoadingOnNext || renderProps.nextButton.disabled
                    }
                    iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                  </Button>
                )}
              </div>
            }
          />
        )}
      />
    );
  }
);
