import { eventEmitter } from "@/App";
import { CollectionDialog } from "@app/products/property/collections/components/action-bar/dialogs/collection/_index";
import { DTO_Collection } from "@app/products/property/collections/components/action-bar/dialogs/collection/model";
import { postNewCollection } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/new-collection/api";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { toString } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";

export const ModifyCollectionButton = observer(() => {
  const { lastSelectedRow, isEmptyData, gridSelectedRows } =
    useCCProductListViewStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const initFormValue = useMemo(
    () => ({
      Collection_Name: lastSelectedRow?.Collection_Name,
      Collection_Type_Id: toString(lastSelectedRow?.Collection_Type_Id),
      Collection_Is_Active: lastSelectedRow?.Collection_Is_Active,
      Collection_Show_in_List: lastSelectedRow?.Collection_Show_in_List ?? true,
      Collection_Id: lastSelectedRow?.Collection_Id,
      Collection_Notes: lastSelectedRow?.Collection_Notes,
    }),
    [lastSelectedRow]
  );

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && !lastSelectedRow) ||
      gridSelectedRows.length !== 1 ||
      isEmptyData
    );
  }, [lastSelectedRow, isEmptyData, gridSelectedRows]);

  const handleSubmit = async (data: DTO_Collection) => {
    setIsLoading(true);
    const successMessage = "Modify collection was successful";
    const errorMessage = "Modify collection failed";
    const response = await postNewCollection(data);
    if (isSuccessResponse(response)) {
      pushNotification({
        title: successMessage,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      setIsShowDialog(false);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: errorMessage,
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Collections_Modify}
      >
        <CCNavButton
          title="Modify collection"
          onClick={() => {
            setIsShowDialog(true);
          }}
          disabled={isDisabled}
        />
      </PrivilegeWrapper>
      {isShowDialog && (
        <CollectionDialog
          onClose={() => setIsShowDialog(false)}
          isLoading={isLoading}
          notification={notificationRef}
          handleSubmit={handleSubmit}
          initFormValue={initFormValue}
          isModify
        />
      )}
    </>
  );
});
