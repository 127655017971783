import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderTrimNewComponents } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import {
  TRIMNUMBERINGMETHOD,
  TRIMSTORAGEMETHOD,
} from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsTrimEnquiryForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showNewTrim =
      parseInt(
        valueGetter(
          ECorporateSettingsField.TRIMAdmin_EnquiryStorageMethod.toString()
        )
      ) === TRIMSTORAGEMETHOD.CREATE;

    const showTrimNumberPrefix =
      parseInt(
        valueGetter(
          ECorporateSettingsField.TRIMAdmin_EnquiryNumberMethod.toString()
        )
      ) === TRIMNUMBERINGMETHOD.SYSTEM;
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <CCSearchComboBoxGlobalSettings
            data={
              configData[ECorporateSettingsField.TRIMAdmin_EnquiryStorageMethod]
            }
            isEditing={isEditing}
          />
          {showNewTrim && (
            <>
              {renderTrimNewComponents(
                formRenderProps,
                isEditing,
                configData[
                  ECorporateSettingsField.TRIMAdmin_EnquiryPrimaryContainerName
                ],
                configData[
                  ECorporateSettingsField
                    .TRIMAdmin_PlanningEnquiryClassification
                ],
                configData[
                  ECorporateSettingsField.TRIMAdmin_EnquiryPrimaryRecordType
                ]
              )}
              <InputGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.TRIMAdmin_EnquiryTitleFormat
                  ]
                }
                isEditing={isEditing}
              />
              <CCSearchComboBoxGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.TRIMAdmin_EnquiryNumberMethod
                  ]
                }
                isEditing={isEditing}
              />
              {showTrimNumberPrefix && (
                <>
                  <InputGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField.TRIMAdmin_EnquiryNumberPrefix
                      ]
                    }
                    isEditing={isEditing}
                  />
                </>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
);
