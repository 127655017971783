import { NUMBER_FORMAT } from "@common/constants/common-format";
import { SearchComponentCell } from "@common/pages/search/components/search-results/components/custom-cells/search-component/_index";
import {
  DTO_ApplicationSearch_Item,
  IListingProps,
} from "@common/pages/search/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOfSearchHome = nameOfFactory<IListingProps>();
const nameOfSearchProperty = nameOfFactory<DTO_ApplicationSearch_Item>();

export const colSearchResults: IColumnFields[] = [
  {
    field: nameOfSearchHome("ID"),
    title: "Reference",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
  },
  {
    field: nameOfSearchHome("RowNumber"),
    title: "Rank",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOfSearchHome("RecordType_Name"),
    title: "Record Type",
  },
  {
    field: nameOfSearchHome("Description"),
    title: "Description",
    width: 1200,
  },
];

export const colSearchProperty: IColumnFields[] = [
  {
    field: nameOfSearchProperty("Component"),
    title: "Component",
    locked: true,
    cell: SearchComponentCell,
  },
  {
    field: nameOfSearchProperty("AccountNumber"),
    title: "Account Number",
  },
  {
    field: nameOfSearchProperty("AccountReference"),
    title: "Account Reference",
  },
  {
    field: nameOfSearchProperty("Name"),
    title: "Name",
  },
  {
    field: nameOfSearchProperty("Address"),
    title: "Address",
  },
  {
    field: nameOfSearchProperty("Description"),
    title: "Description",
  },
  {
    field: nameOfSearchProperty("SearchItemType"),
    title: "Type",
  },
];
