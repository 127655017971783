import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSPriorityofAccessQuestions: IColumnFields[] = [
  {
    field: CRSFieldMapping.QuestionNumber,
    title: "Question Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.Cat_Order, title: "Group Sort" },
  { field: CRSFieldMapping.CategoryName, title: "Group" },
  { field: CRSFieldMapping.SortOrder, title: "Question Sort" },
  { field: CRSFieldMapping.Question, title: "Question Text" },
  //Note
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Owner Depend",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Owner Single",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Owner Points",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  //

  { field: CRSFieldMapping.Weight_CBC, title: "Weight CBC" },
  { field: CRSFieldMapping.Weight_HBC, title: "Weight HBC" },
  { field: CRSFieldMapping.Weight_3yo, title: "Weight 3yo" },
  { field: CRSFieldMapping.Weight_4yo, title: "Weight 4yo" },
  //Note
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Portal Visable",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Portal CBC",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Portal HBC",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Portal 3yo",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Portal 4yo",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  //
];
