import LookupStore from "@app/products/ams/api/data/lookups/store";
import { LOOKUPNAME } from "@app/products/ams/constants/ams.lookups";
import { AmsUrl } from "@app/products/ams/constants/ams.url";
import { IAsset, IAssetInfo, IAssetLOVs } from "@app/products/ams/model/asset";
import { IComment } from "@app/products/ams/model/comment";
import authHeader from "@common/apis/auth-header";
import { APIResponseStatus } from "@common/constants/response-status";
import { globalStoreInstance } from "@common/stores/global/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import axios from "axios";
import {
  action,
  configure,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import mockData from "./assetregister-data.json";

configure({ enforceActions: "always" }); // Strict mode: runInAction
export default class AssetRegisterStore {
  navHeight = 0;
  selectedAssetId: string | null = null;
  asset: IAsset | null = null;
  assetLOVs: IAssetLOVs | null = null;
  totalComments: number | null = null;
  totalActions: number | null = null;
  submitting = false;
  assetLoaded = false;
  assetUpdated = false;
  newAssetId = 0;
  _lookUpStore: LookupStore | null = null; // TODO: Need to test again

  constructor(lookUpStore: LookupStore) {
    this._lookUpStore = lookUpStore; // TODO: Need to test again
    makeObservable(this, {
      navHeight: observable,
      selectedAssetId: observable,
      asset: observable,
      assetLOVs: observable,
      totalComments: observable,
      totalActions: observable,
      submitting: observable,
      assetLoaded: observable,
      assetUpdated: observable,
      newAssetId: observable,
      setNavHeight: action,
      setAssetUpdated: action,
      setNewAssetId: action,
      setSelectedAssetId: action,
      setTotalComments: action,
      setTotalActions: action,
      clearAsset: action,
      loadAsset: action,
      editAsset: action,
      createAsset: action,
      addComment: action,
    });
  }

  // getCategoryDescriptor = (asset: IAsset): string => {
  //   let retVal = "N/A";
  //   let cat = this.assetLOVs?.AssetCategories.find(
  //     (e) => e.Key === asset.assetCategoryId
  //   );
  //   if (cat) {
  //     retVal = cat.Value;
  //   }
  //   return retVal;
  // };

  // getStatusDescriptor = (asset: IAsset): string => {
  //   const { dataStores: { lookupStore: {getLookupItemDescription} } } = useStores();
  //   let retVal = "N/A";
  //   //let status = this.assetLOVs?.AssetStatuses.find((e) => e.Key === asset.statusId);
  //   let status = getLookupItemDescription('AssetStatus', asset.statusId);
  //   if (status) {
  //     retVal = status;
  //   }
  //   return retVal;
  // };

  // getOwnershipDescriptor = (asset: IAsset): string => {
  //   let retVal = "N/A";
  //   let owner = this.assetLOVs?.Ownerships.find((e) => e.Key === asset.ownershipTypeLupId);
  //   if (owner) {
  //     retVal = owner.Value;
  //   }
  //   return retVal;
  // };

  getAssetInfo(asset: IAsset): IAssetInfo {
    const mock = (name: string, itemId: number | null) => ""; // TODO: Need to test again
    const getLookupItemDescription =
      this._lookUpStore?.getLookupItemDescription || mock;
    return {
      AssetNumber: asset.assetNumber,
      AssetDescription: asset.assetDescription,
      AssetCategory: getLookupItemDescription(
        LOOKUPNAME.AssetCategory,
        asset.assetCategoryId
      ),
      DesignLife: asset.designLife,
      WarrantyEndDate: asset.warrantyEndDate,
      // warrantyEndDate.getDate() +
      // "/" +
      // warrantyEndDate.getMonth() +
      // 1 +
      // "/" +
      // warrantyEndDate.getFullYear(),
      //CurrentValue: asset.finCurrentValue.toString(),
      AssetStatus: getLookupItemDescription(
        LOOKUPNAME.AssetStatus,
        asset.assetStatusId
      ),
      //Ownership: getLookupItemDescription('AssetStatus', asset.ownershipTypeLupId),
      //ConditionRating: "3.6",
      //RemainingLife: asset.remainingLife.toString(),
      //ReplacementCost: asset.replacementCost.toString(),
      EmployeeCustodian: asset.employeeCustodian,
      EmployeeNumber: asset.employeeNumber,
      Location: getLookupItemDescription(LOOKUPNAME.location, asset.locationId),
      DepartmentName: getLookupItemDescription(
        LOOKUPNAME.department,
        asset.departmentId
      ),
      CostCentre: getLookupItemDescription(
        LOOKUPNAME.costCentre,
        asset.costCentreId
      ),
      OsProduct: getLookupItemDescription(
        LOOKUPNAME.osProduct,
        asset.osProductId
      ),
    };
  }

  setNavHeight = (height: number) => {
    this.navHeight = height;
  };

  setAssetUpdated = (value: boolean) => {
    this.assetUpdated = value;
  };

  setNewAssetId = (value: number) => {
    this.newAssetId = value;
  };

  setSelectedAssetId = (id: string) => {
    this.selectedAssetId = id;
  };

  setTotalComments = (total: number) => {
    this.totalComments = total;
  };

  setTotalActions = (total: number) => {
    this.totalActions = total;
  };

  clearAsset = () => {
    this.asset = null;
  };

  // @action loadAssetLOVs = async () => {
  //   try {

  //     if (!this.assetLOVs) {
  //       const lookups: IAssetLOVs = {
  //         // AssetStatuses: [
  //         //   { Key: 1, Value: "Pending" },
  //         //   { Key: 2, Value: "Current" },
  //         //   { Key: 3, Value: "Historic" },
  //         // ],
  //         // AssetCategories: [
  //         //   { Key: 1, Value: "Bridges" },
  //         //   { Key: 2, Value: "Roads" },
  //         //   { Key: 3, Value: "Fleet" },
  //         //   { Key: 4, Value: "Buildings" },
  //         // ],
  //         // Divisions: [
  //         //   { Key: 1, Value: "Roads" },
  //         //   { Key: 2, Value: "Parks" },
  //         //   { Key: 3, Value: "Administration" },
  //         //   { Key: 4, Value: "Facilities" },
  //         // ],
  //         // Ownerships: [
  //         //   { Key: 1, Value: "Owned" },
  //         //   { Key: 2, Value: "Private" },
  //         //   { Key: 3, Value: "Public" },displayToast
  //         // ],
  //         // Managers: [
  //         //   { Key: 1, Value: "Brian Blessed" },
  //         //   { Key: 2, Value: "John Doe" },
  //         //   { Key: 3, Value: "Karen Smith" },
  //         // ],
  //         // Priorities: [
  //         //   { Key: 1, Value: "High" },
  //         //   { Key: 2, Value: "Medium" },
  //         //   { Key: 3, Value: "Low" },
  //         // ],
  //       };
  //       this.assetLOVs = lookups;
  //     }
  //   } catch (error) {
  //     runInAction( () => {});
  //     Toast.displayToast({
  //       content: "🚀 Load asset Lovs Fail !",
  //       type: "error",
  //     });
  //     console.console(error);
  //   }
  // };

  loadAsset = async (id: string) => {
    try {
      const response = await axios.get(
        `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.ASSET_REGISTER_ITEM}${id}`,
        {
          headers: authHeader(),
        }
      );

      if (
        !response ||
        response.status !== APIResponseStatus.SUCCESS ||
        !response.data
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "error",
          title: `load Asset with id: ${id} fail`,
          description: response.data?.Errors ?? response.statusText,
        });
        console.warn(`loadAsset with id: ${id} fail`);
        return;
      }

      runInAction(() => {
        //let date: Date = newDate..getUTCDate.getDate();
        const item = response.data;
        const mockAri = mockData[0] as IAsset;
        this.asset = {
          ...mockAri,
          id: item.Id,
          assetNumber: item.AssetNumber,
          assetDescription: item.AssetDescription,
          assetStatusId: item.AssetStatusId,
          assetCategoryId: item.AssetCategoryId,
          designLife: item.DesignLife,
          //remainingLife: item.RemainingLife,
          //installDate: new Date(item.InstallDate),
          warrantyEndDate: item.WarrantyEndDate,
          serialNumber: item.SerialNumber,
          equipmentNumber: item.EquipmentNumber,

          employeeCustodian: item.EmployeeCustodian,
          employeeNumber: item.EmployeeNumber,
          departmentId: item.DepartmentId,
          costCentreId: item.CostCentreId,
          locationId: item.LocationId,
          osProductId: item.OsProductId,
          //finCurrentValue: item.CurrentValue,
          //replacementCost: item.ReplacementCost,
          //purchaseDate: new Date(item.InstallDate),
          //purchaseDate: item.InstallDate,
          //divisionLupId: 0,
          //isReportable: true,
          //ownershipTypeLupId: 1,
          //primaryServiceManagerLupId: 2,
        };

        // switch (item.AssetCategory) {
        //   case "Roads":
        //     this.asset.assetCategoryId = 2;
        //     this.asset.divisionLupId = 1;
        //     break;
        //   case "Bridges":
        //     this.asset.assetCategoryId = 1;
        //     this.asset.divisionLupId = 1;
        //     break;
        //   case "Buildings":
        //     this.asset.assetCategoryId = 4;
        //     this.asset.divisionLupId = 4;
        //     break;
        // }
        switch (item.Status) {
          case "Pending":
            this.asset.assetStatusId = 1;
            break;
          case "Current":
            this.asset.assetStatusId = 2;
            break;
          case "Historic":
            this.asset.assetStatusId = 3;
            break;
        }
        // this.asset = response.data;
        this.assetLoaded = true;
      });
    } catch (error) {
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: `Load asset fail`,
      });
      console.error("Load asset fail", error);
    }
  };

  /*   @action loadTempAsset = async (id: string) => {
    let response: IResponseData = {
      data: null,
      headers: null,
      status: APIReponseStatus.BAD_REQUEST,
    };
    //let asset: IAsset;
    try {
      response = await axios.get(
        `${process.env.REACT_APP_BASE_ODATA_URL}/odata/ams/internal/assetregisteritems?$count=true&`,
        { headers: authHeader() }
      );
      const item = response.data.firstOrDefault(
        (a: any) => a.Id.toString() === id
      );
      if (item) {
        let newDate: Date = new Date();
        //let date: Date = newDate..getUTCDate.getDate();
        const asset: IAsset = {
          assetId: item.Id,
          assetNumber: item.AssetNumber,
          assetDescription: item.AssetDescription,
          statusId: 0,
          assetCategoryId: 0,
          designLife: item.DesignLife,
          remainingLife: item.RemainingLife,
          installDate: new Date(item.InstallDate),
          finCurrentValue: item.CurrentValue,
          replacementCost: item.ReplacementCost,
          purchaseDate: new Date(item.InstallDate),
          divisionLupId: 0,
          reportable: true,
          ownershipTypeLupId: 0,
          primaryServiceManagerLupId: 0,
        };
        return asset;
      }
      //temp.push(item.DisplayName);

      return null;
    } catch (error) {
      console.console(`getProprietorData fail: ${error}`);
      response.data = error.response.data;
      response.headers = error.response.headers;
      response.status = error.response.status;
      return response;
    }
  }; */

  editAsset = async (asset: IAsset) => {
    this.submitting = true;
    try {
      //this is so we match our model on the api end otherwise it wont deserialise.
      //will be fixed when revise models to match both sides.
      const tfAsset: IAsset = {
        id: asset.id,
        assetNumber: asset.assetNumber,
        assetDescription: asset.assetDescription,
        assetCategoryId: asset.assetCategoryId,
        designLife: asset.designLife,
        equipmentNumber: asset.equipmentNumber,
        serialNumber: asset.serialNumber,
        assetStatusId: asset.assetStatusId,
        warrantyEndDate: asset.warrantyEndDate,
        employeeCustodian: asset.employeeCustodian,
        employeeNumber: asset.employeeNumber,
        departmentId: asset.departmentId,
        locationId: asset.locationId,
        costCentreId: asset.costCentreId,
        osProductId: asset.osProductId,
      };
      const response = await axios.put(
        `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.ASSET_REGISTER_ITEM}${asset.id}`,
        tfAsset,
        {
          headers: authHeader(),
        }
      );
      if (
        !response ||
        response.status !== APIResponseStatus.SUCCESS ||
        !response.data
      ) {
        console.warn(`editAsset with id: ${asset.id} fail`);
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "error",
          title: `Saved fail`,
          description: response.data?.Errors ?? response.statusText,
        });
        return;
      }

      runInAction(() => {
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "success",
          title: `Saved successfully`,
        });
        this.assetUpdated = true;
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: `Saved fail`,
      });
      console.error("Save asset fail", error);
    }
  };

  createAsset = async (asset: IAsset) => {
    this.submitting = true;
    try {
      const response = await axios.post(
        `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.ASSET_REGISTER_ITEM}${asset.id}`,
        asset,
        {
          headers: authHeader(),
        }
      );
      if (
        !response ||
        response.status !== APIResponseStatus.SUCCESS ||
        !response.data
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "error",
          title: `createAsset with id: ${asset.id} fail`,
          description: response.data?.Errors ?? response.statusText,
        });
        console.warn(`createAsset with id: ${asset.id} fail`);
        return;
      }

      runInAction(() => {
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "success",
          title: `Create asset successfully`,
          description: response.data?.Errors ?? response.statusText,
        });
        this.submitting = false;
      });
      this.setNewAssetId(response.data.Id);
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: `Create asset fail`,
      });
      console.error("Create Asset fail", error);
    }
  };

  addComment = async (comment: IComment) => {
    this.submitting = true;
    try {
      const response = await axios.post(
        `${globalStoreInstance.allSetting.baseAPIUrl}/comment/${comment.ID}`,
        comment,
        {
          headers: authHeader(),
        }
      );
      if (
        !response ||
        response.status !== APIResponseStatus.SUCCESS ||
        !response.data
      ) {
        console.warn(`addComment with id: ${comment.ID} fail`);
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "error",
          title: `Add comment fail`,
          description: response.data?.Errors ?? response.statusText,
        });
        return;
      }

      runInAction(() => {
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "success",
          title: `Add comment successfully`,
          description: response.data?.Errors ?? response.statusText,
        });
        this.assetUpdated = true;
        this.submitting = false;
      });
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: `Add comment fail`,
      });
      console.error("Add Comment Fail", error);
    }
  };
}
