import { IOfficers } from "@components/cc-contact-manager/model";
import { CCContactManager } from "@components/cc-contact-manager/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

import { eventEmitter } from "@/App";
import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessResponse } from "@common/apis/util";
import { postRemoveSTSiteUser } from "@common/pages/settings/security/security-templates/components/action-bar/buttons/remove-user/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";

export const RemoveSiteUsersSTButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { gridSelectedIds, gridSelectedRows, setGridSelectedRows } =
    useCCProductListViewStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const handleSubmit = async (values: IOfficers[]) => {
    const securityTemplateIds: number[] = gridSelectedIds.filter(
      (securityTemplateId) => securityTemplateId !== null
    );
    const siteUserIds: number[] = values.map((value) => value.ID);
    const response = await postRemoveSTSiteUser(
      securityTemplateIds,
      siteUserIds
    );
    if (isSuccessResponse(response)) {
      setGridSelectedRows([]);
      clearNotifications();
      pushNotification({
        title: "Deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
    } else {
      pushNotification({
        autoClose: false,
        description: response.data.Errors ?? response.statusText,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title={"Remove user"}
        disabled={!gridSelectedRows.length}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <CCContactManager
          dataUrl={CORE_OFFICERS_ODATA}
          setShowDialog={setIsShowDialog}
          onSubmit={handleSubmit}
          isCloseDialogAfterSubmit={true}
          onClose={() => setIsShowDialog(false)}
        />
      )}
    </>
  );
});
