import { EListSubmitButton } from "@app/products/property/model";

export const listSubmitButton = [
  EListSubmitButton.Finish,
  EListSubmitButton.Park,
  EListSubmitButton.Save,
  EListSubmitButton.ConfirmCloseYes,
  EListSubmitButton.ConfirmCloseNo,
  EListSubmitButton.Close,
  EListSubmitButton.Cancel,
];
