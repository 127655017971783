import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { IntegratedReportSecurity } from "@common/pages/report/integrated-reports/component/dialogs/share-report/model";

export const postShareReport = async (
  shareReport: IntegratedReportSecurity
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/core/internal/integratedreports/sharereports`,
      shareReport
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
