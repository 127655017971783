import { serviceStandardPublishToWeb } from "@app/products/crms/service-standards/components/action-bar/buttons/publish-to-web/api";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IPublishToWebButtonProps {
  disabled?: boolean;
}

export const PublishToWebButton = observer(
  ({ disabled = false }: IPublishToWebButtonProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { settings } = useCommonCoreStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const corporateSettingsCEPTenantId = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_CEP_TenantId]
    );
    const isVisible = useMemo(() => {
      return (
        !isNil(corporateSettingsCEPTenantId) &&
        !isEmpty(corporateSettingsCEPTenantId)
      );
    }, [corporateSettingsCEPTenantId]);

    const handleOnClick = async () => {
      setIsLoading(true);
      const response = await serviceStandardPublishToWeb();
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        pushNotification({
          title:
            "Publish To Web has been queued to run. You will be emailed on completion.",
          type: "info",
        });
      } else {
        pushNotification({
          title: "Publish to web failed.",
          type: "error",
          autoClose: false,
        });
      }
    };

    return isVisible ? (
      <CCNavButton
        title="Publish to Web"
        disabled={disabled}
        onClick={handleOnClick}
        isLoading={isLoading}
      />
    ) : null;
  }
);
