import { mockTransactionType } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/mock";
import { ICalculateValueBalance } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/model";
import {
  calculateValueAdjustment,
  calculateValueNewBalance,
  validatorBalanceAdjustment,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";

export const BALANCE_ADJUSTMENT_FORM_STEP = "Balance Adjustment";
export const BalanceAdjustmentFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorBalanceAdjustment}
    />
  );
};

const FormStepElement = ({ nameOf, formRenderProps }: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Transaction date</label>
          <Field
            name={nameOf("TransactionDate")}
            component={CCDateTimePicker}
            format={DATETIME_FORMAT.DATETIME_CONTROL}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Transaction type
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={nameOf("TransactionType")}
            placeholder="Transaction type"
            data={mockTransactionType}
            component={CCComboBox}
            textField="Value"
            dataItemKey="Key"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Reference
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={nameOf("Reference")}
            component={CCInput}
            placeholder="Reference"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Description</label>
          <Field
            name={nameOf("Description")}
            placeholder="Description"
            rows={3}
            component={CCTextArea}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Notes</label>
          <Field
            name={nameOf("Notes")}
            placeholder="Notes"
            rows={3}
            component={CCTextArea}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Existing balance</label>
          <Field
            name={nameOf("ExistingBalance")}
            component={CCCurrencyInput}
            value={getFieldValue("ExistingBalance")}
            disabled
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Adjustment <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={nameOf("Adjustment")}
            component={CCCurrencyInput}
            onChange={(event: NumericTextBoxChangeEvent) => {
              const propsCalculateValueNewBalance: ICalculateValueBalance = {
                existValue: getFieldValue("ExistingBalance"),
                value: event.value,
              };
              onChange(nameOf("NewBalance"), {
                value: calculateValueNewBalance(propsCalculateValueNewBalance),
              });
              onChange(nameOf("Adjustment"), { value: event.value });
            }}
            value={getFieldValue("Adjustment")}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            New balance <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={nameOf("NewBalance")}
            component={CCCurrencyInput}
            value={getFieldValue("NewBalance")}
            onChange={(event: NumericTextBoxChangeEvent) => {
              const propsCalculateValueAdjustment: ICalculateValueBalance = {
                existValue: getFieldValue("ExistingBalance"),
                value: event.value,
              };
              onChange(nameOf("Adjustment"), {
                value: calculateValueAdjustment(propsCalculateValueAdjustment),
              });
              onChange(nameOf("NewBalance"), { value: event.value });
            }}
          />
        </div>
      </div>
    </section>
  );
};
