import { GridColumnMenuProps } from "@progress/kendo-react-grid";
import React from "react";

export interface ISelectionColumnMenuProps extends GridColumnMenuProps {
  selectedStatus?: "allPage" | "thisPage";
  isReadOnly?: boolean;
  handleHeaderSelectionChange: (
    checked: boolean,
    isSelectAll?: boolean
  ) => void;
}

export const SelectionColumnMenu = ({
  selectedStatus,
  onCloseMenu,
  handleHeaderSelectionChange,
  isReadOnly = false,
}: ISelectionColumnMenuProps) => {
  return (
    <div className="cc-grid-selection-column-menu">
      <div
        className={`k-columnmenu-item ${
          selectedStatus === "thisPage" ? "k-selected" : ""
        }
        ${isReadOnly && "read-only"}`}
        onClick={() => {
          if (isReadOnly) return;
          handleHeaderSelectionChange(true);
          if (onCloseMenu) onCloseMenu();
        }}
      >
        <span className="k-icon k-i-drag-and-drop" />
        Select this page
      </div>
      <div
        className={`k-columnmenu-item ${
          selectedStatus === "allPage" ? "k-selected" : ""
        }
        ${isReadOnly && "read-only"}`}
        onClick={() => {
          if (isReadOnly) return;
          handleHeaderSelectionChange(true, true);
          if (onCloseMenu) onCloseMenu();
        }}
      >
        <span className="k-icon k-i-categorize" />
        Select all
      </div>
    </div>
  );
};
