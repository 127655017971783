import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colBuyers } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/config";
import {
  DTO_COOLeaseTransferBuyer,
  ILeaseTransfersDetailProps,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/model";
import { useChangesOfOwnershipLeaseTransfersStore } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/store";
import "../_index.scss";

const nameOfBuyers = nameOfFactory<DTO_COOLeaseTransferBuyer>();
export const Buyers = observer(
  ({ leaseTransfersId }: ILeaseTransfersDetailProps) => {
    const {
      responseLoadError,
      changesOfOwnershipLeaseTransferBuyers,
      isLoading,
      colBuyersConfiguration,
      loadChangesOfOwnershipLeaseTransferBuyers,
      resetStore,
    } = useChangesOfOwnershipLeaseTransfersStore();

    useEffect(() => {
      loadChangesOfOwnershipLeaseTransferBuyers(leaseTransfersId);
      return () => {
        resetStore();
      };
    }, [
      leaseTransfersId,
      resetStore,
      loadChangesOfOwnershipLeaseTransferBuyers,
    ]);

    return (
      <div className="cc-changes-of-ownership-lease-transfers-children-grid">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : responseLoadError ? (
          <div>
            <CCLoadFailed
              responseError={responseLoadError}
              onReload={() => {
                loadChangesOfOwnershipLeaseTransferBuyers(leaseTransfersId);
              }}
            />
          </div>
        ) : (
          <CCGrid
            data={changesOfOwnershipLeaseTransferBuyers ?? []}
            columnFields={processDynamicColumns(
              colBuyers,
              colBuyersConfiguration
            )}
            primaryField={nameOfBuyers("ACT_Lease_Transfer_Buyer_Id")}
          />
        )}
      </div>
    );
  }
);
