import { NoticeRunDetail } from "@app/products/property/schemes/notice-runs/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { colNoticeRun } from "./config";
import { mockNoticeRunTable } from "./mock";

interface INoticeRunFormElementProps {
  formRenderProps: FormRenderProps;
}

export const NoticeRunFormElement = observer(
  ({ formRenderProps }: INoticeRunFormElementProps) => {
    const nameOf = nameOfFactory<NoticeRunDetail>();
    const { pushNotification } = useCCAppNotificationStore();
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    return (
      <FormElement className="cc-schemes-notice-form-body">
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-field">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Notice run ID</label>
                <Field
                  readOnly
                  name={nameOf("NoticeRunId")}
                  placeholder={"Notice run id"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Issue date</label>
                <Field
                  disabled
                  name={nameOf("IssueDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Printed or exported on</label>
                <Field
                  disabled
                  name={nameOf("PrintedExported")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Due date</label>
                <Field
                  disabled
                  name={nameOf("DueDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Created by</label>
                <Field
                  readOnly
                  name={nameOf("CreatedBy")}
                  placeholder={"Created by"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Created on</label>
                <Field
                  disabled
                  name={nameOf("CreateOn")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Accounts in the notice run</label>
            <CCGrid
              className="cc-schemes-notice-run-grid"
              data={mockNoticeRunTable}
              columnFields={colNoticeRun}
              primaryField="Id"
            />
          </div>
        </section>
      </FormElement>
    );
  }
);
