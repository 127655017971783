import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const landManagementRoute: ICCRoute = {
  path: "landManagement",
  name: "landManagement",
  enum: eProductType.LandManagement,
  children: [
  ],
};
