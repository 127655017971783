import { ITagHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ITagHistory>();

export const colRegisterTagHistory: IColumnFields[] = [
  {
    field: nameOf("AnimalType"),
    title: "Animal Type",
  },
  {
    field: nameOf("CurrentTagNo"),
    title: "Current Tag Number",
  },
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PreviousTagNo"),
    title: "Previous Tag Number",
  },
  {
    field: nameOf("HistoryRAC"),
    title: "History RAC",
  },
  {
    field: nameOf("HistoryStatus"),
    title: "History Status",
  },
  {
    field: nameOf("HistoryDescription"),
    title: "History Description",
  },
  {
    field: nameOf("HistoryUserId"),
    title: "History User ID",
  },
  {
    field: nameOf("AccountStatus"),
    title: "Account Status",
  },
  {
    field: nameOf("AccountDescription"),
    title: "Account Description",
  },
  {
    field: nameOf("AccountPropertyAddress"),
    title: "Account Property Address",
  },
  {
    field: nameOf("AccountPropertyLocality"),
    title: "Account Property Locality",
  },
  {
    field: nameOf("AccountId"),
    title: "Account ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
