import { ICON_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colPotentialDuplicateEntities: IColumnFields[] = [
  { field: "Name", title: "Name" },
  {
    field: "NoOfEntities",
    title: "Number of Contacts",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "IsExcluded",
    title: "Is Excluded",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
];
export const colPotentialDuplicateEntitiesDetail: IColumnFields[] = [
  { field: "Name", title: "Name" },
  { field: "Address", title: "Address" },
  {
    field: "Gender",
    title: "Gender",
  },
  {
    field: "EntityId",
    title: "Contact ID",
    style: formatStyleUnRelevant,
  },
];
