import { eventEmitter } from "@/App";
import { ParcelConstraintsEventType } from "@app/products/property/parcels/[id]/components/child-screens/constraints/constant";
import { DTO_Constraint } from "@app/products/property/parcels/[id]/components/child-screens/constraints/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Constraint>();

export const colParcelConstraints: IColumnFields[] = [
  {
    field: nameOf("CategoryName"),
    title: "Category",
    handleOnClick: (dataItem: DTO_Constraint) => {
      eventEmitter.emit(
        ParcelConstraintsEventType.constraintsDetails,
        dataItem.ParcelConstraintId
      );
    },
  },
  {
    field: nameOf("ConstraintName"),
    title: "Constraint Name",
  },
  {
    field: nameOf("ConstraintCode"),
    title: "Constraint Code",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("ParcelConstraintId"),
    title: "Parcel Constraint ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
