import { eventEmitter, msalInstance } from "@/App";
import logo from "@assets/img/logo.png";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import AuthService from "@common/apis/auth.service";
import CoreService from "@common/apis/core.service";
import { isSuccessResponse } from "@common/apis/util";
import { ISettings } from "@common/models/settings";
import { ThemeEventType } from "@common/pages/home/constant";
import { ILogin } from "@common/pages/login/model";
import {
  getRememberAccount,
  getSessionRedirectURL,
  removeSessionRedirectURL,
  setRememberAccount,
} from "@common/pages/login/util";
import { LOGIN_MODE } from "@common/pages/settings/constant";
import {
  getSiteUserById,
  getSiteUserLOV,
} from "@common/pages/settings/security/site-users/_id/api";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  globalStoreInstance,
  useGlobalStore,
} from "@common/stores/global/store";
import { nameOfFactory } from "@common/utils/common";
import { aesEncrypt } from "@common/utils/cryptography";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { KEY_ENTER } from "@components/cc-input/contant";
import { CCInput } from "@components/cc-input/_index";
import { CCLogo } from "@components/cc-logo/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import HomeService from "@components/layout/api";
import { UserInfo } from "@components/layout/model";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { ThemeType } from "@styles/constant";
import axios from "axios";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./_index.scss";
const nameOf = nameOfFactory<ILogin>();

export const login = () => {
  const authRedirectCallBack = async (error: any, response: any) => {
    if (error) {
      console.error("authRedirectCallBack fail", error);
    } else {
      if (response.tokenType === "id_token") {
        // @TODO
      } else if (response.tokenType === "access_token") {
        // @TODO
      } else if (response.tokenType === "Bearer") {
        //Config cookie when logging by Azure
        const data = new URLSearchParams();
        data.append("tokenType", response?.tokenType);
        data.append("accessToken", response?.accessToken);
        data.append("username", response?.account?.username);
        await axios.post("/api/authentication/cookie", data);
        msalInstance
          .acquireTokenSilent({
            scopes: globalStoreInstance.allSetting.azureAD?.scopes || [],
            account: msalInstance.getAllAccounts()[0],
          })
          .then((responseAPI: any) => {
            const userData = {
              token: responseAPI.accessToken,
              // username: response.idTokenClaims.preferred_username,
              // userDisplayname: responseAPI?.account?.name,
              // userID: 0,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location.href = "/home";
          });
      }
    }
  };

  msalInstance
    .handleRedirectPromise()
    .then((response: any) => authRedirectCallBack(null, response))
    .catch((error: any) => authRedirectCallBack(error, null));
  if (msalInstance.getAllAccounts().length === 0) {
    msalInstance
      .loginRedirect({
        scopes: globalStoreInstance.allSetting.azureAD?.scopes || [],
      })
      .catch((error: any) => {
        console.error("Login Redirect Azure fail", error);
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect({
            scopes: globalStoreInstance.allSetting.azureAD?.scopes || [],
          });
        } else {
          console.warn("Login Redirect warning");
        }
      });
  } else {
    msalInstance
      .acquireTokenSilent({
        scopes: globalStoreInstance.allSetting.azureAD?.scopes || [],
        account: msalInstance.getAllAccounts()[0],
      })
      .then((response: any) => {
        const userData = {
          token: response.accessToken,
          username: response.idTokenClaims.preferred_username,
          userDisplayname: response?.account?.name,
          userID: 0,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        window.location.href = "/home";
        console.debug("acquireTokenSilent", {
          userData: response,
        });
      })
      .catch((error: any) => {
        console.error("Azure acquireTokenSilent  fail", error);
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect({
            scopes: globalStoreInstance.allSetting.azureAD?.scopes || [],
          });
        } else {
          console.error("Azure InteractionRequiredAuthError", error);
        }
      });
  }
};

export const LoginPage = observer(() => {
  const { allSetting, setCurrentUserInfo } = useGlobalStore();
  const { headerBarLogo } = useCommonCoreStore();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, clearErrorNotification, clearNotifications } =
    useCCAppNotificationStore();
  const { accounts } = useMsal();

  useEffect(() => {
    if (AuthService.getCurrentUser()) {
      history.push("/home");
    } else {
      if (
        globalStoreInstance.allSetting.authenticationProvider ===
        LOGIN_MODE.AZUREAD
      ) {
        setIsLoading(true);
        login();
      }
    }
    loadSettingFromService();
    return () => clearErrorNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    accounts,
    globalStoreInstance.allSetting.authenticationProvider,
  ]);

  const loadSettingFromService = async () => {
    setIsLoading(true);
    await CoreService.getSettings().then((response: ISettings) => {
      globalStoreInstance.setSettings(response);
      setIsLoading(false);
    });
  };

  const handleOnLoginFail = (errorMessage?: string | null) => {
    pushNotification({
      title: errorMessage ?? "Unknown error",
      type: "error",
      autoClose: false,
    });
  };

  const handleLogin = async (data: ILogin) => {
    sessionStorage.setItem("login", "true");
    try {
      setIsLoading(true);
      const result = await AuthService.login(
        data.username,
        aesEncrypt(data.password)
      );

      if (!result || !result.data || !result.data.access_token) {
        handleOnLoginFail(result.data.error_description);
        setIsLoading(false);
      } else {
        const userData = {
          token: result.data.access_token,
          username: data.username,
          userDisplayname: "",
          userID: 0,
        };
        localStorage.setItem("userData", JSON.stringify(userData));

        //getCurrentUser Info to check need change password
        const currentUserInfoResp = await HomeService.getUserInfo();
        if (currentUserInfoResp) {
          const currentUserInfo: UserInfo = {
            userDisplayName: currentUserInfoResp.data.UserDisplayName,
            UserPKID: currentUserInfoResp.data.User_LID?.toString(),
            EKEY: currentUserInfoResp.data.EnterpriseKey,
            email: currentUserInfoResp.data.UserName,
            MemberGUID: currentUserInfoResp.data.UserGuid,
            userName: currentUserInfoResp.data.UserName,
            bResetPasswordOnFirstLogon:
              currentUserInfoResp.data.bResetPasswordOnFirstLogon,
            FlagCouncillor: currentUserInfoResp.data.FlagCouncillor ?? false,
          };
          setCurrentUserInfo(currentUserInfo);

          if (currentUserInfo.bResetPasswordOnFirstLogon) {
            history.push("/change-password", { userName: data.username });
            return;
          }
        }

        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            userID: currentUserInfoResp?.data?.User_LID ?? 0,
          })
        );

        setRememberAccount(
          data.isRememberMe
            ? {
                username: data.username,
                isRememberMe: data.isRememberMe,
              }
            : undefined
        );
        const locationCache = getSessionRedirectURL();
        if (locationCache) {
          removeSessionRedirectURL();
          history.push(locationCache);
          setIsLoading(false);
        } else {
          if (currentUserInfoResp.data.User_LID) {
            let themeDefaultAndActiveSetFromServer = undefined;
            const listNameThemeActive: string[] = [];

            /* ====  LIST THEME ACTIVE  ==== */
            const responseSiteUserLOV = await getSiteUserLOV();
            const themeLOV = responseSiteUserLOV.data?.Theme ?? [];
            const themeActive =
              themeLOV
                .filter((item) => {
                  if (
                    item.Platform === "Central" &&
                    item.Sys_DBRowState === "Active"
                  ) {
                    listNameThemeActive.push(item.Theme_DisplayName);
                    if (item.Theme_Name === "Default") {
                      themeDefaultAndActiveSetFromServer = ThemeType.Default =
                        item.Theme_DisplayName;
                    }
                  }
                  return (
                    item.Platform === "Central" &&
                    item.Sys_DBRowState === "Active"
                  );
                })
                .sort((a, b) => a.Theme_LKP_ID - b.Theme_LKP_ID) ?? [];
            /* ====  / LIST THEME ACTIVE  ==== */

            /* ====  CURRENT THEME FROM SERVER  ==== */
            let currentThemeServer: string | null | undefined = undefined;
            if (currentUserInfoResp.data.User_LID) {
              /* ====  Get current theme  ==== */
              // TODO: this action takes only theme name so it affects performance
              const response = await getSiteUserById(
                currentUserInfoResp.data.User_LID ?? 0
              );
              if (isSuccessResponse(response) && response?.data) {
                currentThemeServer =
                  response.data?.Contact_SiteUser?.Theme_Name;
              }
              /* ====  / Get current theme  ==== */
            }
            /* ====  / CURRENT THEME FROM SERVER  ==== */

            /* ====  THEME DEFAULT ACTIVE OR THEME FIRST ITEM IN ARRAY THEME ACTIVE  ==== */
            let themeDefault = undefined;
            if (themeDefaultAndActiveSetFromServer) {
              themeDefault = themeDefaultAndActiveSetFromServer;
            } else {
              themeDefault =
                themeActive[0]?.Theme_DisplayName ?? ThemeType.Default;
            }
            /* ====  / THEME DEFAULT ACTIVE OR THEME FIRST ITEM IN ARRAY THEME ACTIVE  ==== */
            let chosenTheme = undefined;
            if (currentThemeServer) {
              if (listNameThemeActive.includes(currentThemeServer)) {
                chosenTheme = currentThemeServer;
              } else {
                chosenTheme = themeDefault;
              }
            } else {
              chosenTheme = themeDefault;
            }
            localStorage.setItem("OOTheme", chosenTheme);
            eventEmitter.emit(ThemeEventType.theme, chosenTheme);
            return;
          }
          history.push("/home");
          clearNotifications();
          setIsLoading(false);
        }
      }
      // else setIsLoginFailed(true);
    } catch (e) {
      console.error("Login fail", {
        handleLogin: e,
      });
      setIsLoading(false);
      // setIsLoginFailed(true);
      handleOnLoginFail();
    }
  };

  const initialData = useMemo(() => {
    return getRememberAccount();
  }, []);

  return allSetting.authenticationProvider === LOGIN_MODE.AZUREAD ? (
    <Loading isLoading />
  ) : (
    <div className="cc-login-page">
      <div className="cc-login-card">
        <div className="cc-login-header">
          {headerBarLogo ? (
            <CCLogo
              className="cc-login-logo"
              alt="Ready Community"
              src={headerBarLogo}
            />
          ) : (
            <img className="cc-login-logo" src={logo} alt="Ready Community" />
          )}
          <div className="cc-login-company-name">ready community</div>
        </div>
        <CCAppNotification />
        <Form
          initialValues={initialData}
          key={JSON.stringify(initialData)}
          onSubmit={(values) => handleLogin(values as ILogin)}
          render={({ valid, valueGetter, onSubmit }: FormRenderProps) => {
            const isRememberMe = valueGetter(nameOf("isRememberMe"));
            const onPressEnterSubmit = (e: any) => {
              if (e?.charCode === KEY_ENTER) onSubmit(e);
            };
            return (
              <FormElement autoComplete={isRememberMe ? "on" : "off"}>
                <fieldset className="k-form-fieldset cc-login-form-fieldset">
                  <div className="cc-field">
                    <label className="cc-login-form-label">
                      Username<span>&nbsp;*</span>
                    </label>
                    <Field
                      component={CCInput}
                      placeholder="Username"
                      name={nameOf("username")}
                      validator={requiredValidator}
                      onKeyPress={onPressEnterSubmit}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-login-form-label">
                      Password<span>&nbsp;*</span>
                    </label>
                    <Field
                      component={CCInput}
                      type={"password"}
                      placeholder={"Password"}
                      name={nameOf("password")}
                      validator={requiredValidator}
                      onKeyPress={onPressEnterSubmit}
                    />
                  </div>

                  <div className="cc-login-inline-group">
                    <div className="cc-login-switch-container">
                      <label>Remember me</label>
                      <div className="cc-login-switch">
                        <Field
                          name={nameOf("isRememberMe")}
                          checked={isRememberMe}
                          component={CCSwitch}
                        />
                        <label>{isRememberMe ? "YES" : "NO"}</label>
                      </div>
                    </div>
                    <Button
                      className="cc-login-button"
                      type="submit"
                      themeColor="primary"
                      disabled={!valid}
                    >
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin cc-login-button-icon" />
                      ) : null}
                      <span> LOG IN</span>
                    </Button>
                  </div>
                </fieldset>
              </FormElement>
            );
          }}
        />
        <div className="cc-login-card-footer">
          <Link className="cc-login-link" to="/forgot-password">
            Forgotten Password
          </Link>
        </div>
      </div>
    </div>
  );
});
