import { ILodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/model";
import { LodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/_index";
import { putChangeLodgeDate } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-lodged-date/api";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangedLodgedDateButton = observer(() => {
  const { setPPRWithLoading, pprId } = usePPRStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();

  const handleSubmit = async (data: ILodgeApplicationDialog) => {
    if (!pprId) return;
    setIsLoading(true);
    const response = await putChangeLodgeDate(pprId, data);
    setIsLoading(false);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      clearNotifications();
      setPPRWithLoading(response?.data?.Application);
      pushNotification({
        type: "success",
        title: "Lodged date changed successfully",
      });
    } else {
      pushNotification({
        title: "Change lodged date failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
        autoClose: false,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Change lodged date"
        isLoading={isLoading}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <LodgeApplicationDialog
          isLoading={isLoading}
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
});
