import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";

export const bankAccountNameValidator = (value: string) => {
  return stringLengthValidator(value, 1, 100);
};

export const bankAccountNumberValidator = (value: string) => {
  const validatorMessage = stringLengthValidator(value, 1, 9);
  if (validatorMessage) {
    return validatorMessage;
  }
  const regexNumber = new RegExp(/^[0-9]+$/);
  if (!regexNumber.test(value)) {
    return "Bank account number must be numberic";
  }
  return "";
};
