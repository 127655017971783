import { history } from "@/AppRoutes";
import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { mapRecordType, RECORDTYPE } from "@common/constants/recordtype";
import { DBRowState } from "@common/models/baseClassStandard";
import { IdentityPacket } from "@common/models/identityPacket";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { getAppeal } from "@common/pages/appeal/_id/api";
import {
  getAppealHearing,
  getAppealHearingLOVS,
  getParentSummary,
  putAppealHearing,
} from "@common/pages/appeal/_id/appeal-hearing/_id/api";
import { APPEAL_HEARING_ROUTE } from "@common/pages/appeal/_id/appeal-hearing/_id/constant";
import {
  AppealHearing,
  AppealHearingLOVsNew,
  AppealHearingSubmitActions,
} from "@common/pages/appeal/_id/appeal-hearing/_id/model";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import {
  Ancestor,
  IAppeal,
  IParentAppealSection,
} from "@common/pages/appeal/_id/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AppealHearingStore {
  private _appealHearing?: AppealHearing = undefined;
  private _appeal?: IAppeal = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _appealHearingId?: number = undefined;
  private _appealId?: number = undefined;
  private _parent?: any = undefined;
  private _ancestor?: Ancestor = undefined;
  private _lovs?: AppealHearingLOVsNew = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get appealHearing() {
    return toJS(this._appealHearing);
  }
  setAppealHearing = (appealHearing?: AppealHearing | undefined) => {
    runInAction(() => {
      this._appealHearing = appealHearing;
    });
  };
  get appeal() {
    return toJS(this._appeal);
  }
  setAppeal = (appeal?: IAppeal | undefined) => {
    runInAction(() => {
      this._appeal = appeal;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentAppealSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get appealHearingId() {
    return toJS(this._appealHearingId);
  }
  setAppealHearingId = (appealHearingId?: number) => {
    runInAction(() => {
      this._appealHearingId = appealHearingId;
    });
  };

  get appealId() {
    return toJS(this._appealId);
  }
  setAppealId = (appealId?: number) => {
    runInAction(() => {
      this._appealId = appealId;
    });
  };

  get lovs() {
    return this._lovs;
  }
  setLovs = (lovs?: AppealHearingLOVsNew) => {
    runInAction(() => {
      this._lovs = lovs;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._appealHearing = undefined;
      this._appeal = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._appealHearingId = undefined;
      this._appealId = undefined;
      this._parent = undefined;
      this._ancestor = undefined;
      this._lovs = undefined;
    });
  };

  loadParent = async (parent: IParentAppealSection, isNew?: boolean) => {
    this.setParent(parent);
    const response = await getParentSummary(parent?.id, parent?.recordType);
    //TODO: dynamic ProductType
    const lovResponse = await getAppealHearingLOVS(
      PRODUCT_TYPE_NUMBER.TownPlanning
    );
    if (isSuccessResponse(lovResponse) && lovResponse.data) {
      const newLovs = lovResponse.data;
      const processHearingDuration =
        newLovs?.HearingDuration?.map((item) => item.Value) ?? [];
      this.setLovs({
        ...newLovs,
        HearingDuration: processHearingDuration ?? [],
      });
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load application data failed",
      });
      return false;
    }

    if (isSuccessResponse(response) && response.data) {
      if (isNew) {
        this.setAppealHearing({
          ...parent?.data,
        } as AppealHearing);
      }
      this.setAncestor(response.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load application data failed",
      });
      return false;
    }
  };

  loadHearingNew = async (appealId?: number): Promise<boolean> => {
    if (appealId === undefined) return false;
    this.setIsLoading(true);
    let errorResponse = undefined;
    const response = await getAppeal(appealId);
    if (isSuccessResponse(response)) {
      const sourceIdentifier = response.data?._BubbleUps.find(
        (bubbleUp: BubbleUpIdentifier) =>
          bubbleUp.BubbleUpType_ENUM === BubbleUpType.Parent
      )?.SourceIdentifier;
      if (sourceIdentifier) {
        const newParent = {
          id: sourceIdentifier._RecordSource_ID,
          recordType:
            mapRecordType[sourceIdentifier._RecordSourceType_ENUM] ??
            RECORDTYPE.TP_PPRApplication,
        };
        await this.loadParent(newParent, false);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setAppeal(response.data);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };
  loadAppealHearing = async (appealHearingId?: number): Promise<boolean> => {
    if (appealHearingId === undefined) return false;
    this.setIsLoading(true);
    let newAppeal = undefined;
    let errorResponse = undefined;
    const response = await getAppealHearing(appealHearingId);
    if (isSuccessResponse(response)) {
      newAppeal = response.data;
      if (newAppeal?.Sys_DBRowState === DBRowState.Inactive) {
        appNotificationStore.pushNotification({
          type: "info",
          autoClose: false,
          title:
            "Important Note: You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
        });
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    const sourceIdentifier = newAppeal?.Appeal?._BubbleUps.find(
      (bubbleUp: BubbleUpIdentifier) =>
        bubbleUp.BubbleUpType_ENUM === BubbleUpType.Parent
    )?.SourceIdentifier;
    if (sourceIdentifier) {
      const newParent = {
        id: sourceIdentifier._RecordSource_ID,
        recordType:
          mapRecordType[sourceIdentifier._RecordSourceType_ENUM] ??
          RECORDTYPE.TP_PPRApplication,
      };
      await this.loadParent(newParent, false);
    }
    this.setAppealHearing(newAppeal);
    this.setAppealHearingId(appealHearingId);
    this.setAppealId(newAppeal?.Appeal_ID);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };

  saveAppealHearing = async (
    appealHearing: AppealHearing,
    action: AppealHearingSubmitActions
  ) => {
    //Save appeal hearing
    this.setIsLoading(true);
    const response = await putAppealHearing(appealHearing);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        if (this.appealHearingId) {
          await this.loadAppealHearing(
            response.data?.ID ?? this.appealHearingId
          );
        }
        await this.runActions(action, response.data);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Appeal could not be saved",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Appeal could not be saved",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };
  runActions = async (
    action: AppealHearingSubmitActions,
    data: IdentityPacket
  ) => {
    let parentActionRes = undefined;
    switch (action) {
      case AppealHearingSubmitActions.Save:
        appNotificationStore.pushNotification({
          title: "Appeal hearing updated successfully",
          type: "success",
        });
        break;
      case AppealHearingSubmitActions.New:
        if (parentActionRes !== true) {
          const appealId = /\d+/g.exec(window.location.pathname)?.[0];

          history.replace(
            `${APPEAL_ROUTE}/${appealId}${APPEAL_HEARING_ROUTE}/${data.ID}`
          );
          appNotificationStore.pushNotification({
            title: "Appeal hearing created successfully",
            type: "success",
          });
        }
        break;
      default:
        break;
    }
  };
}

const appealHearingStoreContext = createContext(new AppealHearingStore());
export const useAppealHearingStore = () => {
  return useContext(appealHearingStoreContext);
};
