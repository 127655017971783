import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Journal } from "@app/core/journal/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadAllBuilding } from "@app/products/building/api";
import { NewBuildingButton } from "@app/products/building/components/action-bar/buttons/new-building/_index";
import { BuildingActionBarNavDropdown } from "@app/products/building/components/action-bar/nav-dropdown/_index";
import { BuildingFieldMapping } from "@app/products/building/model";
import { buildingRoute } from "@app/products/building/route";
import { BuildingSystemAdminNatureOfWorkBookmark } from "@app/products/building/system-admin/nature-of-work/util";
import { systemAdminRoute } from "@app/products/building/system-admin/route";
import { BuildingBookmark } from "@app/products/building/util";
import { BuildingContactsTab } from "@app/products/building/[id]/components/reference-sidebar/contacts/_index";
import { BuildingDetailTab } from "@app/products/building/[id]/components/reference-sidebar/detail/_index";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colBuildingSystemAdminNatureOfWork } from "./config";

export default () => {
  useCCListViewActionBar({
    title: buildingRoute.name,

    leftComponents: [
      <BuildingActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <NewBuildingButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Building}
        recordType={RECORDTYPE.TP_BuildingApplication}
        dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_BuildingApplication}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Building}
        recordType={RECORDTYPE.TP_BuildingApplication}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={BUILDING_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Building}
        recordType={RECORDTYPE.BUILDING}
        detail={BuildingBookmark.getBookmarkDetail}
        displayName={BuildingBookmark.getBookmarkDisplayName}
        listViewDetail={
          BuildingSystemAdminNatureOfWorkBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          BuildingSystemAdminNatureOfWorkBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <BuildingDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <BuildingContactsTab /> },
      {
        title: "History",
        component: (
          <Journal id={0} recordType={RECORDTYPE.TP_BuildingApplication} />
        ),
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colBuildingSystemAdminNatureOfWork}
      data={loadAllBuilding() || []}
      primaryField="ID"
      state={{
        sort: [
          { field: BuildingFieldMapping.ApplicationCategory_Name, dir: "desc" },
        ],
      }}
    />
  );
};
