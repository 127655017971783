import { IKeyValuePacket } from "@common/models/keyValuePacket";

export enum SearchParcelType {
  Address = "Address",
  Name = "Name",
  LegalDescription = "Legal Description",
  ParcelId = "Parcel Id",
  ParcelReference = "Parcel Reference",
}
export enum SearchParcelField {
  Address = "SearchAddress",
  Name = "SearchName",
  LegalDescription = "SearchLegalDescription",
  ParcelId = "SearchParcelId",
  ParcelReference = "SearchParcelReference",
}
export interface IAssociateParcelLookup {
  ParcelSearchBy: string;
  ParcelSearchValue: string;
  ParcelLookup: IParcelLookup[];
  SearchOptions: string[];
  _option?: {
    SearchParcel: {
      Data: IKeyValuePacket[];
      Loading: boolean;
    };
  };
}

export interface IParcelLookup {
  ParcelReference: string;
  ParcelId: number;
  Address: string;
  Locality?: string;
  SideOfStreet?: string;
  MapNumber?: number;
  MapReference?: string;
  LegalDescription: string;
  ElectoralDivision?: string;
  LandArea?: string;
  Name: string;
  LandCategory?: string;
  Owners?: string;
  Zoning?: string;
  LandUses?: string;
  Status?: string;
}
export const textParcelFieldMapping: {
  [key: string]: string;
} = {
  [SearchParcelType.Address]: SearchParcelField.Address,
  [SearchParcelType.Name]: SearchParcelField.Name,
  [SearchParcelType.LegalDescription]: SearchParcelField.LegalDescription,
  [SearchParcelType.ParcelId]: SearchParcelField.ParcelId,
  [SearchParcelType.ParcelReference]: SearchParcelField.ParcelReference,
};
