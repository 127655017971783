import { useSettingPropertyStore } from "@app/products/property/system-admin/settings/_category/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const PropertyActionBarSettingNew = observer(() => {
  const history: any = useHistory();
  const { isEditable, urlNewForm, setIsNew } = useSettingPropertyStore();

  const handleNewButton = () => {
    history.push(history.location.pathname, {
      requestUrl: urlNewForm,
      isNew: true,
    });
    setIsNew(true);
  };

  return (
    <>{isEditable && <CCNavButton title="New" onClick={handleNewButton} />}</>
  );
});
