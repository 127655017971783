import { getDocumentsOptionClassificationLOV } from "@app/core/attachment/components/dialogs/api";
import {
  getDocumentContentDownload,
  getDocumentsOptionStatusLOV,
} from "@app/core/documents/components/dialog/manage-document/api";
import {
  changeKeyMapRadioGroupComponentClassification,
  changeKeyMapRadioGroupComponentStatus,
  checkIsValidFile,
} from "@app/core/documents/components/dialog/manage-document/util";
import {
  Attachment,
  DOCUMENTSTATUS,
  FileStreamEntry,
  IManageDocument,
} from "@app/core/documents/model";
import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { RadioGroup, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";
import fileDownload from "js-file-download";
import { isEmpty } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<Attachment>();
interface IManageDocumentProps {
  onClose: () => void;
  onSubmit: (data: Attachment, onlyStatus?: boolean) => void;
  attachmentInfo: Attachment | undefined;
  isUpdating?: boolean;
  isLoading?: boolean;
}
export const ManageDocumentDialog = ({
  onClose,
  onSubmit,
  attachmentInfo,
  isUpdating = false,
  isLoading = false,
}: IManageDocumentProps) => {
  const [isReplaceFile, setIsReplaceFile] = useState(false);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { settings } = useCommonCoreStore();
  const filterTypesStr: string =
    settings[ECorporateSettingsField.CorporateSettings_FileExtension]?.Value;
  const isEnableAttachmentSizeRestriction = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_EnableAttachmentSizeRestriction]
  );
  const attachmentSizeRestrictionSize = getNumberValueSetting(
    settings[ECorporateSettingsField.Global_AttachmentSizeRestrictionSize]
  );
  const [dataDocumentsOptionStatusLOV, setDataDocumentsOptionStatusLOV] =
    useState<IKeyValuePacket[]>();
  const [
    dataDocumentsOptionsClassificationLOV,
    setDataDocumentsOptionsClassificationLOV,
  ] = useState<IKeyValuePacket[]>();
  const isEnablePPR = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_Application_PPR]
  );
  const parentBubleUp = attachmentInfo?.BubbleUps?.find(
    (x: BubbleUpIdentifier) => x.BubbleUpType_ENUM === BubbleUpType.Parent
  );
  const isPSAR =
    parentBubleUp?.SourceIdentifier?._RecordSourceType_ENUM ===
    RECORDTYPE[RECORDTYPE.TP_PSA_Application];

  useEffectOnce(() => {
    getDocumentsOptionStatusLOV().then((response) => {
      customLogger(
        "Core documents, Manage document, getDocumentsOptionStatusLOV"
      ).info(response?.data);
      if (isSuccessResponse(response)) {
        if (response?.data) setDataDocumentsOptionStatusLOV(response?.data);
      } else {
        notificationRef.current?.pushNotification({
          title: `Documents OptionStatus LOV load failed`,
          type: "warning",
        });
      }
    });

    getDocumentsOptionClassificationLOV().then((response) => {
      customLogger(
        "Core documents, Manage document, getDocumentsOptionClassificationLOV"
      ).info(response?.data);
      if (isSuccessResponse(response)) {
        if (response?.data)
          setDataDocumentsOptionsClassificationLOV(
            response?.data as IKeyValuePacket[]
          );
      } else {
        notificationRef.current?.pushNotification({
          title: `Documents Options Classification load failed`,
          type: "warning",
        });
      }
    });
  });

  const allowedExtensions = useMemo(() => {
    if (!isEmpty(filterTypesStr)) {
      return filterTypesStr
        .toUpperCase()
        .split("|")
        .map((item) => item.replace(".", "").replace("*", ""));
    }
  }, [filterTypesStr]);

  const maxFileSize = useMemo(() => {
    if (isEnableAttachmentSizeRestriction && attachmentSizeRestrictionSize) {
      return attachmentSizeRestrictionSize;
    }
  }, [attachmentSizeRestrictionSize, isEnableAttachmentSizeRestriction]);

  const handleOnDownload = async () => {
    if (attachmentInfo?.Attachment_ID) {
      const response = await getDocumentContentDownload(
        attachmentInfo?.Attachment_ID
      );
      if (isSuccessResponse(response)) {
        fileDownload(response.data, attachmentInfo.FileEntry?.FileName ?? "");
      } else {
        const idError = attachmentInfo?.Attachment_ID
          ? ` [${attachmentInfo?.Attachment_ID}]`
          : "";
        notificationRef.current?.pushNotification({
          title: `Unable to locate attachment${idError}. Refer to your legacy record management system to locate this document.`,
          type: "error",
          autoClose: false,
        });
      }
    } else {
      const idError = attachmentInfo?.Attachment_ID
        ? ` [${attachmentInfo?.Attachment_ID}]`
        : "";
      notificationRef.current?.pushNotification({
        title: `Unable to locate attachment${idError}. Refer to your legacy record management system to locate this document.`,
        type: "error",
        autoClose: false,
      });
    }
  };

  const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
    const data = event.values as IManageDocument;
    if (!attachmentInfo) return;

    if (data?._option?.FileUpload && !isEmpty(data?._option?.FileUpload)) {
      const fileUpload = data?._option?.FileUpload[0];
      let fileStreamEntry: FileStreamEntry = {};
      if (fileUpload?.getRawFile) {
        const fileRaw = fileUpload?.getRawFile();
        fileStreamEntry.FileName = fileRaw?.name;
        fileStreamEntry.ContentType = fileRaw?.type;
        fileStreamEntry.FileSize = fileRaw?.size;
        const reader: any = new FileReader();
        reader.onloadend = async () => {
          const attachmentBase64 = encode(await reader.result);
          fileStreamEntry.Base64FileAttachment = attachmentBase64;
          onSubmit(
            {
              ...data,
              FileEntry: fileStreamEntry,
              Title: data?._option?.Title ?? fileUpload?.name,
            },
            false
          );
        };
        reader.readAsArrayBuffer(fileRaw);
      }
    } else {
      onSubmit(data, true);
    }
  };

  return (
    <Form
      initialValues={attachmentInfo}
      key={JSON.stringify(attachmentInfo)}
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter } = formRenderProps;

        return (
          <CCDialog
            titleHeader={"Manage Document"}
            height={"auto"}
            maxWidth={"35%"}
            onClose={onClose}
            bodyElement={
              isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Document</label>
                        <Field
                          name={nameOf("Title")}
                          component={CCInput}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Produced on</label>
                        <Field
                          name={nameOf("Sys_CreatedDate")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          disabled
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Created by</label>
                        <Field
                          name={nameOf("Sys_CreatedBy")}
                          component={CCInput}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Status</label>
                        <Field
                          name={nameOf("Status_ENUM")}
                          component={RadioGroup}
                          data={
                            dataDocumentsOptionStatusLOV
                              ? changeKeyMapRadioGroupComponentStatus(
                                  dataDocumentsOptionStatusLOV
                                )
                              : []
                          }
                        />
                      </div>
                      {isPSAR && isEnablePPR && (
                        <div className="cc-field">
                          <label className="cc-label">
                            Document classification
                          </label>
                          <Field
                            name={nameOf("DocumentClassification_ENUM")}
                            component={RadioGroup}
                            data={
                              dataDocumentsOptionsClassificationLOV
                                ? changeKeyMapRadioGroupComponentClassification(
                                    dataDocumentsOptionsClassificationLOV
                                  )
                                : []
                            }
                          />
                        </div>
                      )}
                      {attachmentInfo?.Status_ENUM === DOCUMENTSTATUS.Draft && (
                        <>
                          <div className="cc-field">
                            <label className="cc-label">
                              Would you like to replace the existing file?
                            </label>
                            <CCSwitch
                              checked={isReplaceFile}
                              onChange={(event: SwitchChangeEvent) => {
                                setIsReplaceFile(event.value);
                              }}
                            />
                          </div>
                          {isReplaceFile && (
                            <>
                              <div className="cc-field">
                                <label className="cc-label">
                                  New file
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={"_option.FileUpload"}
                                  component={CCUploadFile}
                                  autoUpload={false}
                                  batch={false}
                                  multiple={false}
                                  restrictions={{
                                    allowedExtensions: allowedExtensions,
                                    maxFileSize: maxFileSize,
                                  }}
                                  defaultFiles={[]}
                                  withCredentials={false}
                                  files={formRenderProps.valueGetter(
                                    "_option.FileUpload"
                                  )}
                                  onAdd={(event: UploadOnAddEvent) => {
                                    onChange("_option.FileUpload", {
                                      value: event.newState,
                                    });
                                    if (
                                      event.affectedFiles &&
                                      event.affectedFiles.length > 1
                                    ) {
                                      notificationRef.current?.pushNotification(
                                        {
                                          title: `The attached files exceed the maximum file allowed (1)`,
                                          type: "warning",
                                        }
                                      );
                                    }
                                  }}
                                  onRemove={(event: UploadOnRemoveEvent) => {
                                    onChange("_option.FileUpload", {
                                      value: event.newState,
                                    });
                                  }}
                                  showActionButtons={false}
                                  validator={requiredValidator}
                                />
                              </div>
                              <div>
                                <label className="cc-label">
                                  Title
                                  <CCTooltip
                                    type={"info"}
                                    position="auto"
                                    content={
                                      "Enter a value to be used as the common Title for all the attachments, OR Leave blank to use the physical file name of each attachment as it’s title"
                                    }
                                  />
                                </label>
                                <Field
                                  name={"_option.Title"}
                                  component={CCInput}
                                  placeholder={"Title"}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </section>
                </FormElement>
              )
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button className="cc-dialog-button" onClick={handleOnDownload}>
                  Download
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  disabled={
                    !formRenderProps.valid ||
                    (isReplaceFile &&
                      !checkIsValidFile(valueGetter("_option.FileUpload")))
                  }
                  iconClass={isUpdating ? "fas fa-spinner fa-spin" : ""}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
