import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const documentTemplateRoute: ICCRoute = {
  path: "document-templates",
  name: "Document Templates",
  component: require("./list/_index").default,
  enum: eMenuAction.SettingsCategory_DocumentTemplates,
  children: [
    {
      path: ":id",
      component: require("./[id]/_index").default,
    },
  ],
};
