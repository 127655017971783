import { nameOfDDAuthorisation } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/constant";
import {
  bankAccountNameValidator,
  bankAccountNumberValidator,
} from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/util";
import { validateBSB } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/components/form-element/direct-debits/util";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DDAuthorisationBankingStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(() => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Bank account name" isMandatory />
          <Field
            component={CCInput}
            name={nameOfDDAuthorisation("Bank_Account_Name")}
            validator={bankAccountNameValidator}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="BSB" isMandatory />
          <Field
            name={nameOfDDAuthorisation("BSB_No")}
            component={CCMaskedTextBox}
            mask="000-000"
            placeholder="BSB"
            validator={validateBSB}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Bank account number" isMandatory />
          <Field
            name={nameOfDDAuthorisation("Bank_Account_No")}
            component={CCInput}
            placeholder="Bank account number"
            // [11737] Confirmation 21/05/2024 - No longer than 9 and numberic
            validator={bankAccountNumberValidator}
          />
        </div>
      </div>
    </section>
  );
});
