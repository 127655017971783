import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ProcessPool } from "@common/pages/settings/system-admin/job-queue/model";

const nameOf = nameOfFactory<ProcessPool>();
export const colJobQueueFailures: IColumnFields[] = [
  {
    field: nameOf("DatePooled"),
    title: "Date Pooled",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RecordSourceTypeName"),
    title: "Record Type",
  },
  {
    field: nameOf("RecordSource_ID"),
    title: "Record Source ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ProcessTypeName"),
    title: "Process Type",
  },
  {
    field: nameOf("ProcessPooledBy"),
    title: "Job Queue By",
  },
  {
    field: nameOf("BatchNo"),
    title: "Batch No",
  },
  {
    field: nameOf("SubBatchNo"),
    title: "Sub Batch No",
  },
  {
    field: nameOf("SourceDescription"),
    title: "Source Description",
  },
  {
    field: nameOf("ErrorMessage"),
    title: "Error Message",
  },
  {
    field: nameOf("Description"),
    title: "Comments",
  },
];
