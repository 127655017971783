import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";
import { KEY_CODES } from "@common/utils/constant";

interface INavigateSkippingDisabledItemParams<T> {
  event: KeyboardEvent;
  data: T[];
  selectedItem: T;
  fieldName: string;
  onChange: (
    name: string,
    options: {
      value: any;
    }
  ) => void;
}

/**
 * Navigates through items using ArrowUp and ArrowDown keys, skipping disabled items.
 *
 * @param {INavigateSkippingDisabledItemParams} params - An object containing all parameters required for the function.
 * @param params.event - Keyboard event for navigation.
 * @param params.data - List of items in the ComboBox.
 * @param params.selectedItem - Currently selected value.
 * @param params.fieldName - Field name to update.
 * @param params.onChange - Callback function to update the selected item.
 */
export const navigateSkippingDisabledItem = <
  T extends DTO_LOV | DTO_LOV_Number
>({
  event,
  data,
  selectedItem,
  fieldName,
  onChange,
}: INavigateSkippingDisabledItemParams<T>) => {
  const keyCode = event.key;

  // Find the index of the currently selected item in the data array
  const currentIndex = data.indexOf(selectedItem);

  // Find the enabled item to select
  let validItemToSelect: T | undefined;

  // If the "ArrowDown" key was pressed: move to the next enabled item in the list
  if (keyCode === KEY_CODES.ARROW_DOWN) {
    validItemToSelect = data
      .slice(currentIndex + 1) // Get a sub-array starting from the next item
      .find((item) => !item.disabled); // Find the first item in the sub-array that is not disabled
  }
  // If the "ArrowUp" key was pressed: move to the previous enabled item in the list
  else if (keyCode === KEY_CODES.ARROW_UP) {
    validItemToSelect = data
      .slice(0, currentIndex) // Get a sub-array from the start up to the current item (excluding the current item)
      .reverse() // Reverse the sub-array to start searching from the item before the current one
      .find((item) => !item.disabled); // Find the first item in the reversed sub-array that is not disabled
  }

  if (validItemToSelect) {
    onChange(fieldName, { value: validItemToSelect?.Code });
  }
};
