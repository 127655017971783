import { IAttributeData } from "@app/products/property/model";
import { getAttributesUDA } from "@app/products/property/parcels/[id]/components/child-screens/attributes-uda/api";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ParcelAttributesUDAStore {
  private _attributesUDA?: IAttributeData[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get attributesUDA() {
    return this._attributesUDA;
  }
  setAttributesUDA = (attributesUDA?: IAttributeData[]) => {
    runInAction(() => {
      this._attributesUDA = attributesUDA;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  loadAttributesUDA = async (parcelId: number) => {
    this.setIsLoading(true);
    const newAttributes = await getAttributesUDA(parcelId);
    this.setAttributesUDA(
      newAttributes && newAttributes?.ItemData
        ? Object.keys(newAttributes?.ItemData).map((key, index) => ({
            Attribute: key,
            Value: newAttributes.ItemData[key as any],
            Id: index,
          }))
        : undefined
    );
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._attributesUDA = undefined;
    });
  };
}

const parcelAttributesUDAStoreContext = createContext(
  new ParcelAttributesUDAStore()
);
export const useParcelAttributesUDAStore = () => {
  return useContext(parcelAttributesUDAStoreContext);
};
