import {
  DictionaryTabTableType,
  GetTabTableCountRequest,
} from "@app/core/tab-table/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getTabTableCount = async ({
  id,
  recordType,
  relatedTypes,
  infringeKey,
  showIPinContacts,
  applicationNumber,
  invoiceNumber,
  recordsFileNumber,
  propertyAssessmentNoKey,
  addressLine1,
  suburb,
  personKey,
  schemeKey,
  refNoKey,
  ancestorRecordType,
  ancestorRecordId,
}: GetTabTableCountRequest): Promise<APIResponse<DictionaryTabTableType>> => {
  try {
    const ignoreEmptyString = (str: any) =>
      str?.trim() !== "" ? str : undefined;
    return await CoreAPIService.getClient().get(
      `/api/core/internal/tab-table-count/${id}`,
      {
        params: {
          recordType,
          relatedTypes: relatedTypes.toString(),
          infringeKey: ignoreEmptyString(infringeKey),
          showIPinContacts,
          applicationNumber,
          invoiceNumber: ignoreEmptyString(invoiceNumber),
          recordsFileNumber: ignoreEmptyString(recordsFileNumber),
          propertyAssessmentNoKey: ignoreEmptyString(propertyAssessmentNoKey),
          addressLine1: ignoreEmptyString(addressLine1),
          suburb: ignoreEmptyString(suburb),
          personKey,
          schemeKey,
          refNoKey: ignoreEmptyString(refNoKey),
          ancestorRecordType,
          ancestorRecordId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
