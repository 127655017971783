import { ChangeOfNameAddrDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeOfNameAddrButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();
  return (
    <>
      <CCNavButton
        title="Change of name/address"
        onClick={() => setIsShowDialog(true)}
      />

      {isShowDialog && (
        <ChangeOfNameAddrDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={() => {
            pushNotification({
              title: "Register accounts name/Address changed successfully",
              type: "success",
            });
            setIsShowDialog(false);
          }}
        />
      )}
    </>
  );
});
