import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putChangePassword } from "@common/pages/change-password/api";
import { validateConfirmPassword } from "@common/pages/change-password/util";
import { useGlobalStore } from "@common/stores/global/store";
import { validateRequired } from "@common/utils/field-validators";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export const ChangePasswordPage = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const { currentUserInfo, setCurrentUserInfo } = useGlobalStore();
  const history = useHistory();

  /* ====  Form Submit  ==== */
  const handleSendRequest = async (data: any) => {
    data.UserName = currentUserInfo?.userName;
    setIsLoading(true);
    // Call Api
    try {
      const response = await putChangePassword(data);
      setIsLoading(false);
      if (
        isSuccessResponse(response) &&
        response.data.ErrorStatus === IdentityPacketErrorStatus.Success
      ) {
        pushNotification({
          title: response.data?.message ?? "Change password successfully",
          type: "success",
        });
        localStorage.removeItem("userData");
        setCurrentUserInfo(null);
        setTimeout(function () {
          history.push("/login");
        }, 3000);
      } else {
        pushNotification({
          title: response.data?.Errors ?? "Can't change password",
          type: "error",
          autoClose: false,
        });
      }
    } catch (error) {
      pushNotification({
        title: "Error connecting to server",
        type: "error",
        autoClose: false,
      });
    }
  };
  /* ====  / Form Submit  ==== */

  return (
    <div className="cc-reset-password-page">
      <div className="cc-reset-password-card">
        <div className="cc-reset-password-header">
          <img
            className="cc-reset-password-logo"
            src="/static/media/logo.daa78c7f.png"
            alt="Ready Community"
          />
          <div className="cc-reset-password-company-name">
            Community Central
          </div>
        </div>
        <CCAppNotification />
        <Form
          validator={validateConfirmPassword}
          onSubmit={handleSendRequest}
          render={(fromRenderProps: FormRenderProps) => (
            <FormElement>
              <fieldset className="k-form-fieldset cc-reset-password-form-fieldset">
                <div className="cc-field">
                  <label className="cc-reset-password-form-label">
                    Current password
                  </label>
                  <Field
                    validator={validateRequired}
                    component={CCInput}
                    type="password"
                    placeholder="Current password"
                    name="CurrentPassword"
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-reset-password-form-label">
                    New password
                  </label>
                  <Field
                    validator={validateRequired}
                    component={CCInput}
                    type="password"
                    placeholder="New password"
                    name="NewPassword"
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-reset-password-form-label">
                    Confirm new password
                  </label>
                  <Field
                    validator={validateRequired}
                    component={CCInput}
                    type="password"
                    placeholder="Confirm new password"
                    name="ConfirmPassword"
                  />
                </div>

                <div className="cc-reset-password-inline-group-ok">
                  <Button
                    className="cc-reset-password-button-ok"
                    type="submit"
                    disabled={isLoading}
                    themeColor="primary"
                  >
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin cc-reset-password-button-icon" />
                    ) : null}
                    <span> RESET</span>
                  </Button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
});
