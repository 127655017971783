import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { Svc_AttachmentRecordType } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";

export const getTrimRecordTypeInputPickerOption = (
  option?: Partial<IOptionInputPicker>,
  grid?: Partial<ICCGridProps>
): IOptionInputPicker => {
  const nameOfTrimRecordType = nameOfFactory<Svc_AttachmentRecordType>();

  return {
    dialog: {
      titleHeader: option?.dialog?.titleHeader ?? "",
      maxWidth: option?.dialog?.maxWidth ?? "60%",
    },
    boxSearch: option?.boxSearch ?? {
      colSearch: nameOfTrimRecordType("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfTrimRecordType("Name"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfTrimRecordType("Name") },
        { title: "Description", field: nameOfTrimRecordType("Description") },
      ],
      primaryField: nameOfTrimRecordType("ID"),
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [{ field: nameOfTrimRecordType("Name"), dir: "asc" }],
      },
      ...grid,
    },
  };
};
