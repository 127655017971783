import { isSuccessResponse } from "@common/apis/util";
import { putValidateAppeal } from "@common/pages/appeal/_id/api";
import { IAppeal } from "@common/pages/appeal/_id/model";
import { appealStore } from "@common/pages/appeal/_id/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AppealSendForApprovalButtonStore {
  private _isShowDialog: boolean = false;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  handleSendForApproval = async (appeal: IAppeal) => {
    this.setIsLoading(true);
    const response = await putValidateAppeal(appeal);
    await appealStore.reLoadAppeal();
    this.setIsLoading(false);
    if (isSuccessResponse(response)) {
      if (response.data?.Notifications?.length === 0) {
        this.setIsShowDialog(true);
      } else {
        appNotificationStore.pushNotification({
          autoClose: true,
          title: "Send for approval failed",
          description: response.data?.Notifications,
          type: "warning",
          onClose: () => this.setIsShowDialog(true),
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        description: response.data?.Errors,
        type: "error",
      });
    }
  };
}
export const appealSendForApprovalButtonStore =
  new AppealSendForApprovalButtonStore();
const appealSendForApprovalButtonStoreContext = createContext(
  appealSendForApprovalButtonStore
);
export const useAppealSendForApprovalStore = () => {
  return useContext(appealSendForApprovalButtonStoreContext);
};
