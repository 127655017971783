import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<MapshareSuggestion>();

export const colAddressValidation: IColumnFields[] = [
  {
    field: nameOf("text"),
    title: "Address",
    width: 500,
  },
];
