import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { gridDefaultProps } from "@components/cc-grid/config";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { getViewConfiguration } from "@components/cc-product-list-view/api";
import { PRODUCT_LIST_VIEW_SELECTION_SPEED } from "@components/cc-product-list-view/config";
import Loading from "@components/loading/Loading";
import {
  AggregateDescriptor,
  GroupDescriptor,
} from "@progress/kendo-data-query";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce } from "react-use";
export interface IGridDetailParcelProps extends ICCGridProps {}

export const GridDetailParcel = observer((props: IGridDetailParcelProps) => {
  const {
    isShowSlideBar,
    gridData,
    gridColumns,
    gridDataState,
    gridSelectedRows,
    gridExportFormat,
    setGridExportFormat,
    setGridData,
    setGridColumns,
    setGridDataState,
    setGridSelectedRows,
    setGridSelectedIds,
    resetStore,
    setDisableRowField,
    setCanSelectField,
    setGridTotalSelected,
  } = useDetailParcelListViewStore();

  const {
    data,
    state,
    columnFields,
    selectableMode = "multiple",
    itemPerPage,
    dataUrl,
    selectedRows,
    disableRowField,
    canSelectField,
    primaryField,
  } = props;
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRowsState, setSelectedRowsState] = useState<any[]>(
    selectedRows ?? []
  );
  const [totalSelectedRows, setTotalSelectedRows] = useState<number>(
    selectedRows?.length ?? 0
  );
  const menuActionEnumId = params.get("menuActionEnumId");
  const userViewId = params.get("userViewId");

  useDebounce(
    () => {
      setGridSelectedRows(selectedRowsState);
      setGridSelectedIds(selectedRowsState.map((item) => item[primaryField]));
    },
    PRODUCT_LIST_VIEW_SELECTION_SPEED,
    [selectedRowsState]
  );
  useDebounce(
    () => {
      setGridTotalSelected(totalSelectedRows);
    },
    PRODUCT_LIST_VIEW_SELECTION_SPEED,
    [totalSelectedRows]
  );

  useEffect(() => {
    setCanSelectField(canSelectField);
  }, [canSelectField, setCanSelectField]);

  useEffect(() => {
    setDisableRowField(disableRowField);
  }, [disableRowField, setDisableRowField]);

  useEffect(() => {
    if (!dataUrl) {
      setGridData(data);
    }
    setIsLoading(false);
  }, [data, setGridData, dataUrl]);

  useEffect(() => {
    if (selectedRows) {
      setGridSelectedRows(selectedRows);
      setGridSelectedIds(selectedRows.map((item) => item[primaryField]));
    }
  }, [primaryField, selectedRows, setGridSelectedIds, setGridSelectedRows]);

  useEffect(() => {
    if (state) {
      const { sort, filter, group, take, skip } = state;
      let newDataState = {
        sort: sort !== undefined ? sort : gridDefaultProps.state?.sort,
        filter: filter !== undefined ? filter : gridDefaultProps.state?.filter,
        group: group !== undefined ? group : gridDefaultProps.state?.group,
        skip: skip !== undefined ? skip : gridDefaultProps.state?.skip,
        take:
          take !== undefined
            ? take
            : itemPerPage || gridDefaultProps.state?.take,
      };

      let groups = newDataState.group;
      if (
        groups &&
        groups?.some((group: GroupDescriptor) => !group?.aggregates)
      ) {
        const aggregates: AggregateDescriptor[] = [
          { field: primaryField, aggregate: "count" },
        ];
        groups.forEach(
          (group: GroupDescriptor) => (group.aggregates = aggregates)
        );
        newDataState.group = groups;
      }
      setGridDataState(newDataState);
    }
  }, [state, setGridDataState, itemPerPage, primaryField]);

  useEffect(() => {
    setGridColumns(columnFields);
  }, [columnFields, setGridColumns]);

  const handleOnDataChange = useCallback(
    (data) => {
      setGridData(data);
    },
    [setGridData]
  );
  const handleOnDataStateChange = useCallback(
    (state) => {
      setGridDataState(state);
    },
    [setGridDataState]
  );
  const handleOnSelectionChange = useCallback(
    (dataItem: any[]) => {
      if (!isShowSlideBar) {
        setGridSelectedRows(dataItem);
        setGridSelectedIds(dataItem.map((item) => item[primaryField]));
      } else {
        //Use Debounce
        setSelectedRowsState(dataItem);
      }
    },
    [primaryField, setGridSelectedIds, setGridSelectedRows, isShowSlideBar]
  );

  const handleOnChangeTotalSelect = useCallback(
    (totalSelectedRows: number) => {
      if (!isShowSlideBar) {
        setGridTotalSelected(totalSelectedRows);
      } else {
        //Use Debounce
        setTotalSelectedRows(totalSelectedRows);
      }
    },
    [setGridTotalSelected, isShowSlideBar]
  );

  useEffect(() => {
    return () => {
      resetStore();
    };
  }, [resetStore]);

  useEffect(() => {
    if (menuActionEnumId !== null && userViewId !== null) {
      setIsLoading(true);
      getViewConfiguration(
        0,
        PRODUCT_TYPE.CommunityProperty,
        +menuActionEnumId,
        +userViewId
      ).then((configs) => {
        if (configs) {
          setGridColumns(
            configs
              .filter((conf) => !conf.IsHidden)
              .map((config) => {
                const find = columnFields.find(
                  (col) => col.field === config.ColumnName
                );
                if (find) {
                  return {
                    ...find,
                    title: config.DataColumn,
                  };
                }
                return {
                  field: config.ColumnName,
                  title: config.DataColumn,
                };
              })
          );
        } else {
          setGridColumns(columnFields);
        }
        setIsLoading(false);
      });
    } else {
      setGridColumns(columnFields);
    }
  }, [menuActionEnumId, userViewId, columnFields, setGridColumns]);

  return (
    <>
      {!isLoading ? (
        <CCGrid
          {...props}
          exportFormat={gridExportFormat}
          onExported={setGridExportFormat}
          itemPerPage={itemPerPage}
          selectableMode={selectableMode}
          isAutoHiddenPager={false}
          style={{ height: "100%" }}
          pagerType={"input"}
          className="detail-parcel-view-grid"
          data={gridData}
          state={gridDataState}
          columnFields={gridColumns}
          selectedRows={gridSelectedRows}
          onDataChange={handleOnDataChange}
          onDataStateChange={handleOnDataStateChange}
          onSelectionChange={handleOnSelectionChange}
          onTotalSelectedRowChange={handleOnChangeTotalSelect}
        />
      ) : (
        <Loading isLoading={isLoading} isFullScreen />
      )}
    </>
  );
});
