import { ContactClassification } from "@app/core/contacts/_id/model";
import {
  contactFiltersDefaultExternal,
  relationshipFiltersAdditionalOnly,
  relationshipFiltersAnimalKennel,
  relationshipFiltersHealthManagerPremises,
  relationshipFiltersTownPlanning,
} from "@app/core/contacts/components/dialogs/additional-contact/config";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const processAdditionalContactOption = (
  recordType: RECORDTYPE
): {
  contactFilters: ContactClassification[];
  relationshipFilters: ContactRelationshipType[];
} => {
  switch (recordType) {
    case RECORDTYPE.CORE_Register:
    case RECORDTYPE.TP_PPRApplication:
      return {
        contactFilters: contactFiltersDefaultExternal,
        relationshipFilters: relationshipFiltersTownPlanning,
      };
    case RECORDTYPE.HealthManager_Premises:
      return {
        contactFilters: contactFiltersDefaultExternal,
        relationshipFilters: relationshipFiltersHealthManagerPremises,
      };
    case RECORDTYPE.Animals_Kennel:
      return {
        contactFilters: contactFiltersDefaultExternal,
        relationshipFilters: relationshipFiltersAnimalKennel,
      };
    case RECORDTYPE.Animals_Registration:
    case RECORDTYPE.Animals_PoundRegister:
    case RECORDTYPE.LLP_Permit:
    case RECORDTYPE.CORE_Inspection:
      return {
        contactFilters: contactFiltersDefaultExternal,
        relationshipFilters: relationshipFiltersAdditionalOnly,
      };
    default:
      return {
        contactFilters: [],
        relationshipFilters: [],
      };
  }
};
