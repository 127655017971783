import { IActionsAccordion } from "@app/core/actions/action-accordion/model";
import { getUrlAction } from "@app/products/town-planning/ppr/actions/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IActionsAccordion>();

export const colActionsAccordion: IColumnFields[] = [
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: getUrlAction,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DueDate"),
    title: "Due On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
];
