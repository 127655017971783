import {
  booleanValuesList,
  getItemLogic,
  getListsOfType,
  IOptionGetCurrentFilter,
  logicDataFilterList,
} from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-grid-column-menu-filter/config";
import React, { useState } from "react";

export const CustomFilterRender = (props: any) => {
  const {
    onChange: onChangeForm,
    valueGetter,
    column,
    filter,
    filterUI,
    hideSecondFilter,
    ...others
  } = props;
  const { filter: filterType } = column;
  const currentValueFilter = (option: keyof IOptionGetCurrentFilter) => {
    const cloneFilter = [...filter?.filters] ?? [];
    const findCurrentFilterField = cloneFilter?.find(
      (item: any) =>
        item?.filters?.[0]?.field?.toString() === column.field.toString()
    );
    let resultFilter = undefined;

    if (option === "between-filter") {
      const logic = findCurrentFilterField?.logic;
      return getItemLogic(logic);
    } else {
      if (findCurrentFilterField)
        resultFilter = findCurrentFilterField?.filters[option];
    }
    return resultFilter;
  };

  const [firstFilter, setFirstFilter] = useState<any>(() => {
    onChangeForm("initialFilterFirst", {
      value: {
        name: column.field,
        operator:
          currentValueFilter(0)?.operator ??
          getListsOfType(column?.filter)?.[0]?.operator,
        value: currentValueFilter(0)?.value,
      },
    });

    let operator =
      filterType === "boolean"
        ? currentValueFilter(0)?.value
        : currentValueFilter(0)?.operator ??
          getListsOfType(column?.filter)?.[0]?.operator;

    if (props?.columnConfig?.filterByEnum) {
      operator = "eq";
    }

    return {
      field: column.field,
      value: currentValueFilter(0)?.value,
      operator: operator,
      operators: getListsOfType(column?.filter),
      booleanValues: booleanValuesList,
      onChange: (props: any) => {
        const { name, value, operator } = props;
        onChangeForm(name, {
          value: name.includes("logic") ? operator : value,
        });
        setFirstFilter((pre: any) => {
          return { ...pre, value: value, operator: operator };
        });
      },
      filterType: column.filter,
    };
  });

  const [secondFilter, setSecondFilter] = useState<any>(() => {
    onChangeForm("initialFilterSecond", {
      value: {
        name: column.field,
        operator:
          currentValueFilter(1)?.operator ??
          getListsOfType(column?.filter)?.[0]?.operator,
        value: currentValueFilter(1)?.value,
      },
    });
    return {
      field: column.field,
      value: currentValueFilter(1)?.value,
      operator:
        currentValueFilter(1)?.operator ??
        getListsOfType(column?.filter)?.[0]?.operator,
      operators: getListsOfType(column?.filter),
      booleanValues: booleanValuesList,
      onChange: (props: any) => {
        const { name, value, operator } = props;
        onChangeForm(name, {
          value: name.includes("logic") ? operator : value,
        });
        setSecondFilter((pre: any) => {
          return { ...pre, value: value, operator: operator };
        });
      },
      filterType: column.filter,
    };
  });

  const [logicBetweenFilter, setLogicBetweenFilter] = useState<any>(() => {
    onChangeForm("initialLogicBetweenFilter", {
      value: currentValueFilter("between-filter")?.operator,
    });
    return {
      logicData: logicDataFilterList,
      logicValue: currentValueFilter("between-filter")?.operator,
      onLogicChange: (event: any) => {
        const { value } = event;
        onChangeForm("Between-Filter", { value: value?.operator });
        setLogicBetweenFilter((pre: any) => {
          return { ...pre, logicValue: value?.operator };
        });
      },
    };
  });

  const propsFilterUI = {
    firstFilterProps: firstFilter,
    secondFilterProps: secondFilter,
    logicBetweenFilter: logicBetweenFilter,
    hideFirstFilter:
      filterType === "date" && secondFilter?.operator === "isnull",
    hideSecondFilter:
      (filterType === "date" && firstFilter?.operator === "isnull") ||
      hideSecondFilter,
    ...others,
  };

  return <>{filterUI(propsFilterUI)}</>;
};
