import { WorkflowTypes } from "@app/products/property/model";
import { DTO_Supplementary_AssessmentLevy_LOVs } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_AssessmentLevy_Flags } from "./model";

export const getAssessmentLevyLOVs = async (
  assessmentId: number,
  workflowType: WorkflowTypes,
  primaryLandUseId?: number
): Promise<APIResponse<DTO_Supplementary_AssessmentLevy_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Supplementary_AssessmentLevy_LOVs>(
      `api/property/internal/supplementary/assessmentlevy/lovs/${assessmentId}?workflowType=${workflowType}${
        primaryLandUseId ? "&primaryLandUseId=" + primaryLandUseId : ""
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAssessmentLevyFlags = async (
  supplementaryAssessmentId: number,
  levyId: number
): Promise<APIResponse<DTO_AssessmentLevy_Flags>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_AssessmentLevy_Flags>(
      `api/property/internal/supplementary/assessessmentlevyflags/${supplementaryAssessmentId}/${levyId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
