import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitAccount>();
export const colDDAuthorisationSelectAccount: IColumnFields[] = [
  {
    field: nameOf("Integration_Module_Type_Name"),
    title: "Module Type",
    locked: true,
  },
  {
    field: nameOf("Account_Number"),
    title: "Account Number",
  },
  {
    field: nameOf("Payment_Frequency_Name"),
    title: "Payment Frequency",
  },
  {
    field: nameOf("Start_Date"),
    title: "Start Date",
    format: DATE_FORMAT.DATE_CONTROL,
  },
  {
    field: nameOf("End_Date"),
    title: "End Date",
    format: DATE_FORMAT.DATE_CONTROL,
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
];
