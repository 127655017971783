import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { SaveButtonSystemAdminPermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/components/buttons/save/_index";
import { PermitTypeForm } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/_index";
import { TitlePermitTypeManagePage } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { PermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

const NewManagePermitTypeComponent = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const permitType = dataForms?.GeneralForm as PermitType;
  return (
    <>
      <FormTitle title={`New ${TitlePermitTypeManagePage}`} />
      <CCManagePageActionBar
        leftActions={[<SaveButtonSystemAdminPermitType />]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {permitType && <PermitTypeForm />}
          </div>
        </div>
      </div>
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewManagePermitTypeComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.LLPermits },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.LLPermits_Form_PermitType,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewManagePermitType = renderWithPermissionsWrapper(renderProps);
