import { DOCUMENT_STATUS_NUNBER } from "@app/core/documents/model";
import { getDocumentsByIdAndProductType } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/api";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SendForApprovalButtonStore {
  private _isShowDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
    });
  };

  validation = async (id: number) => {
    const response = await getDocumentsByIdAndProductType(
      id,
      RECORDTYPE.CORE_FurtherInformationRequest,
      DOCUMENT_STATUS_NUNBER.Draft
    );
    if (isSuccessResponse(response)) {
      if (response.data?.length) {
        this.setIsShowDialog(true);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title:
            "You cannot complete this request until you attach any 'DRAFT' documents",
          type: "error",
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          response?.error ??
          "Further information validation could not be completed.",
        type: "error",
      });
    }
  };
}
export const sendForApprovalButtonStore = new SendForApprovalButtonStore();
const sendForApprovalButtonStoreContext = createContext(
  sendForApprovalButtonStore
);
export const useSendForApprovalStore = () => {
  return useContext(sendForApprovalButtonStoreContext);
};
