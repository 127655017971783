import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { observer } from "mobx-react-lite";
import React from "react";

export const PSARCommunicationButton = observer(() => {
  const { settings } = useCommonProductStore();

  const displayOtherRegisters = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_DisplayRegister]
  );

  return !displayOtherRegisters ? (
    <CommunicationButtonListView
      productType={PRODUCT_TYPE_NUMBER.TownPlanning}
      recordType={RECORDTYPE.TP_PSA_Application}
    />
  ) : null;
});
