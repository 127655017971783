import { Journal } from "@app/core/journal/_index";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const PSARHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { ppr } = usePSARStore();
  const params: { id: string } = useParams();
  const pprId: number =
    lastSelectedRow && lastSelectedRow?.ID
      ? lastSelectedRow?.ID
      : parseInt(params.id);
  return (
    <Journal
      id={pprId}
      triggersReload={ppr}
      recordType={RECORDTYPE.TP_PSA_Application}
    />
  );
});
