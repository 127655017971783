import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ContactCentralNameDetailTab } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/_index";
import DetailComponent from "@app/products/property/contacts-central-names/potential-duplicate-entities/_shares/components/detail/_index";
import { colPotentialDuplicateEntities } from "@app/products/property/contacts-central-names/potential-duplicate-entities/_shares/config";
import { ExcludeButton } from "@app/products/property/contacts-central-names/potential-duplicate-entities/components/action-bar/buttons/exclude/_index";
import { RemoveExclusionButton } from "@app/products/property/contacts-central-names/potential-duplicate-entities/components/action-bar/buttons/remove-exclusion/_index";
import { mockNameStreetLocality } from "@app/products/property/contacts-central-names/potential-duplicate-entities/name-street-locality/mock";
import { contactRoute } from "@app/products/property/contacts-central-names/route";
import { getBookmarkListViewDisplayName } from "@app/products/property/contacts-central-names/util";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkListViewDetail } from "./util";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={contactRoute.path} />,
    ],
    centerComponents: [
      <ExcludeButton />,
      <RemoveExclusionButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Entity}
        recordType={RECORDTYPE.CommunityProperty_Entity}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Entity}
        keyField="Id"
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Entity}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ContactCentralNameDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Entity}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      data={mockNameStreetLocality}
      columnFields={colPotentialDuplicateEntities}
      primaryField={"Id"}
      detail={DetailComponent}
    />
  );
};
