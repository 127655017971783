import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { CommonFormFinance } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance";
import { IOptionsFinanceSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/config";
import {
  IOptionsFutureFeeSettings,
  optionsFutureFeeSettings,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/future-fee-settings/config";

import { sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const dataSort = useMemo(() => {
      const data = dataSetting.SettingFields;
      const dataTransform: IOptionsFutureFeeSettings[] = data.map(
        (item: SettingField) => {
          let itemTransform = optionsFutureFeeSettings(item, formRenderProps);
          return itemTransform;
        }
      );
      return sortBy(dataTransform, "index", "asc");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1 cc-form-cols-finance">
            {dataSort.map((field: IOptionsFinanceSettings, index: number) => {
              return (
                field.options.showField && (
                  <CommonFormFinance
                    key={index}
                    item={field.item}
                    options={field.options}
                    formRenderProps={formRenderProps}
                  />
                )
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
