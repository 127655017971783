import { VO_Officer } from "@common/models/officer";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchOfficerConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

const nameOf = nameOfFactory<VO_Officer>();

export const colSearchOfficer: IColumnFields[] = [
  {
    field: nameOf("FullName"),
    title: "Full Name",
    width: 250,
  },
];
