import { ICCRoute } from "@common/constants/ICCRoute";

export const recallRoute: ICCRoute = {
  path: "recall",
  name: "recall",
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
