import { useAppealHearingCalendarStore } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { saveAs } from "@progress/kendo-file-saver";
import { formatDate } from "@telerik/kendo-intl";
import { observer } from "mobx-react-lite";
import React from "react";
const ICAL = require("ical.js");

export const ExportCalendarButton = observer(() => {
  const { hearingCalendar } = useAppealHearingCalendarStore();

  const exportCalendar = () => {
    if (hearingCalendar) {
      let component = new ICAL.Component(["vcalendar", [], []]);
      component.updatePropertyWithValue(
        "prodid",
        "-//Telerik Inc.//NONSGML RadScheduler//EN"
      );
      component.updatePropertyWithValue("version", "2.0");
      let schedulerEvents = hearingCalendar.filter(
        (item) => item.title && item.title !== ""
      );

      for (let i = 0; i < schedulerEvents.length; i++) {
        let schedulerEvent = schedulerEvents[i];
        let vevent = new ICAL.Component("vevent");
        let event = new ICAL.Event(vevent);

        event.uid = schedulerEvent.recurrenceId
          ? schedulerEvent.recurrenceId.toString()
          : schedulerEvent.id.toString();

        event.summary = schedulerEvent.title ?? "";
        event.description = schedulerEvent.description ?? "";
        event.startDate = ICAL.Time.fromDateTimeString(
          formatDate(schedulerEvent.start as Date, "yyyy-MM-ddTHH:mm:ssZ")
        );
        event.endDate = ICAL.Time.fromDateTimeString(
          formatDate(schedulerEvent.end as Date, "yyyy-MM-ddTHH:mm:ssZ")
        );

        component.addSubcomponent(vevent);
      }
      saveAs(
        "data:text/calendar," + component.toString(),
        "TownPlanningAppealHearings.ics"
      );
    }
  };

  return <CCNavButton title={"Export Calendar"} onClick={exportCalendar} />;
});
