import {
  ILineageTreeList,
  ITreeListState,
} from "@app/products/property/components/lineage/components/lineage-tree-list/model";
import { getLineageDataTree } from "@app/products/property/components/lineage/util";
import { nameOfFactory } from "@common/utils/common";
import { gridConfig } from "@components/cc-grid/config";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  extendDataItem,
  mapTree,
  TreeList,
  TreeListCellProps,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
  TreeListPageChangeEvent,
} from "@progress/kendo-react-treelist";
import * as React from "react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

const subItemsField: string = "children";
const expandField: string = "expanded";
const nameOf = nameOfFactory<ILineageTreeList>();

const customCellRender = (
  defaultRendering: React.ReactElement<HTMLTableCellElement> | null,
  props: TreeListCellProps,
  lineageURL: string
): React.ReactElement<HTMLTableCellElement> | null => {
  const {
    hasChildren,
    level = [0],
    expanded,
    dataItem,
    field,
    expandable,
    style,
    className,
    colSpan,
    onExpandChange,
  } = props;
  if (!field || !dataItem?.[field]) return defaultRendering;
  const valueAsString = dataItem[field]?.toString() ?? "";
  let icons = [];
  if (expandable) {
    icons.push(
      ...level
        .slice(1)
        .map((_x, i) => <span className="k-icon k-i-none" key={i} />)
    );
    if (hasChildren) {
      icons.push(
        <span
          className={`k-icon k-i-${expanded ? "collapse" : "expand"}`}
          key="expand-collapse"
          onClick={(event) => onExpandChange(event, dataItem, level)}
        />
      );
    } else {
      icons.push(<span className="k-icon k-i-none" key={icons.length} />);
    }
  }

  return (
    <td style={style} className={className} colSpan={colSpan}>
      {icons}
      {dataItem?.Id ? (
        <Link to={`${lineageURL}/${dataItem.Id}`}>{valueAsString}</Link>
      ) : (
        valueAsString
      )}
    </td>
  );
};

interface ITreeListLineageProps {
  data: Array<any>;
  url: string;
  currentTitle: string;
}

export const TreeListLineage = ({
  currentTitle,
  data,
  url,
}: ITreeListLineageProps) => {
  const [treeListState, setTreeListState] = useState<ITreeListState>({
    data: [...getLineageDataTree(data)],
    expanded: [],
    skip: 0,
    take: 10,
  });

  const onExpandChange = (event: TreeListExpandChangeEvent) => {
    setTreeListState({
      ...treeListState,
      expanded: event.value
        ? treeListState?.expanded?.filter((Id) => Id !== event.dataItem?.Id)
        : [...treeListState?.expanded, event.dataItem?.Id],
    });
  };

  const treeListData = useMemo(() => {
    const { data, expanded } = treeListState;
    const expandedList: number[] = expanded ?? [];
    return mapTree(data, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        [expandField]: expandedList.includes(item.Id),
      })
    );
  }, [treeListState]);

  const onPageChange = (event: TreeListPageChangeEvent) => {
    const { skip, take } = event;
    setTreeListState({
      ...treeListState,
      skip,
      take,
    });
  };

  const lineageColumnFields = useMemo((): TreeListColumnProps[] => {
    return [
      {
        title: currentTitle,
        field: nameOf("Text"),
        expandable: true,
      },
    ];
  }, [currentTitle]);

  return (
    <TreeList
      {...treeListState}
      expandField={expandField}
      subItemsField={subItemsField}
      onExpandChange={onExpandChange}
      data={treeListData ?? []}
      columns={lineageColumnFields}
      dataItemKey={nameOf("Id")}
      onPageChange={onPageChange}
      cellRender={(
        defaultRendering: React.ReactElement<HTMLTableCellElement> | null,
        props: TreeListCellProps
      ) => customCellRender(defaultRendering, props, url)}
      pager={(props) => (
        <Pager
          buttonCount={gridConfig.paging.buttonCount}
          previousNext={gridConfig.paging.previousNext}
          pageSizes={gridConfig.paging.pageSizes}
          skip={treeListState.skip ?? 0}
          take={treeListState.take ?? 0}
          {...props}
        />
      )}
    />
  );
};
