import { history } from "@/AppRoutes";
import { MAILMERGEDOCUMENTUSAGE } from "@app/core/documents/model";
import { MAILMERGESAVEFORMAT } from "@app/core/new-mail-merge/dialogs/model";
import { isSuccessResponse } from "@common/apis/util";
import { DBRowState } from "@common/models/baseClassStandard";
import { getDocumentTemplateById } from "@common/pages/settings/lookups/documents/_id/api";
import { eBooleanType } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/model";
import { SETTINGS_LOOKUPS_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/documents/_id/constant";

import {
  CCDocument_Ext,
  ILookupDocumentParentSection,
} from "@common/pages/settings/lookups/documents/_id/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface CloneNewDocumentButtonProps {
  disabled?: boolean;
}
export const CloneNewDocumentButton = observer(
  ({ disabled }: CloneNewDocumentButtonProps) => {
    const { gridSelectedRows } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const documentId: number[] =
      gridSelectedRows?.map((item) => item?.ID) ?? [];
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async () => {
      let newDocument = undefined;
      setIsLoading(true);
      const response = await getDocumentTemplateById(documentId[0]);
      setIsLoading(false);

      if (isSuccessResponse(response)) {
        newDocument = response?.data as CCDocument_Ext;
        history.push(`${SETTINGS_LOOKUPS_DOCUMENTS_ROUTE}/new`, {
          parentDocument: {
            ...newDocument,
            Document_ID: 0,
            Name: "",
            Sys_TimeStamp: "",
            Sys_TransactionDate: new Date(),
            Sys_DBRowState: DBRowState.Active,
            FileEntry: null,
            Document_Usage_ENUM_Int:
              MAILMERGEDOCUMENTUSAGE.CustomDocumentTemplates,
            _options: {
              SaveAsFormat:
                response?.data?.SaveFormat_ENUM_Int === MAILMERGESAVEFORMAT.Pdf
                  ? eBooleanType.TRUE
                  : eBooleanType.FALSE,
            },
          } as CCDocument_Ext,
        } as ILookupDocumentParentSection);
      } else {
        pushNotification({
          title: "Document load failed",
          type: "error",
          autoClose: false,
          description: response.error ?? response.statusText,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Clone New"
          onClick={handleOnClick}
          disabled={documentId?.length !== 1 || disabled}
          iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
        />
      </>
    );
  }
);
