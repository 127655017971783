import { IProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/require-approval/model";
import {
  putSendReptAndRespLetterForApproval,
  putSendReptAndRespLetterForApprovalValidate,
} from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-rept-and-resp-letter-for-approval/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SendReptAndRespLetterForApprovalButtonStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };
  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  handleSendReptAndRespLetterForApprovalValidate = async (id?: number) => {
    if (!id) return;
    this.setIsLoading(true);
    const response = await putSendReptAndRespLetterForApprovalValidate(id);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearErrorNotification();
      this.setIsShowDialog(true);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        description: response.data?.Errors ?? response.statusText,
        type: "error",
      });
    }
    this.setIsLoading(false);
  };

  handleSendReptAndRespLetterForApproval = async (
    id?: number,
    data?: IProvideReason
  ) => {
    if (!id) return;
    this.setIsLoading(true);
    const response = await putSendReptAndRespLetterForApproval(
      id,
      data?.Comment
    );
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearNotifications();
      PPRStoreInstance.setPPRWithLoading(response.data?.Application);

      appNotificationStore.pushNotification({
        type: "success",
        title:
          "Report and response letters sent to delegated approver for approval",
      });
      this.setIsShowDialog(false);
      PPRStoreInstance.reLoadPPR().then(() => {
        if (
          response.data?.Notifications &&
          response.data?.Notifications.length > 0
        ) {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Send report and response letter",
            type: "warning",
            description: response.data?.Notifications,
          });
        }
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          "The report and response letters could not be sent to the team leader for approval.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const sendReptAndRespLetterForApprovalButtonStore =
  new SendReptAndRespLetterForApprovalButtonStore();
const sendReptAndRespLetterForApprovalButtonContext = createContext(
  sendReptAndRespLetterForApprovalButtonStore
);
export const useSendReptAndRespLetterForApprovalButtonStore = () => {
  return useContext(sendReptAndRespLetterForApprovalButtonContext);
};
