import { Permit } from "@app/products/local-laws/[id]/model";
import { Svc_Permit_Workflow_LodgePermitRequest } from "@app/products/local-laws/components/toolbar/buttons/lodge-permit/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { LLP_NEXTACTION } from "@common/constants/enumerations";
import { IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postSetupLodgePermit = async (
  permit: Permit,
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<LLP_NEXTACTION> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/locallaw/internal/permit-register/lodge-set-up`,
      permit,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postLodgePermit = async (
  lodgePermitRequest: Svc_Permit_Workflow_LodgePermitRequest,
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<Permit> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/locallaw/internal/permit-register/lodge`,
      lodgePermitRequest,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
