import { history } from "@/AppRoutes";
import { StateRoad } from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import {
  getStateRoadById,
  postStateRoad,
} from "@common/pages/settings/lookups/state-roads/_id/api";
import { SETTINGS_LOOKUPS_STATE_ROADS_ROUTE } from "@common/pages/settings/lookups/state-roads/_id/constant";
import {
  ILookupStateRoadParentSection,
  LookupStateRoadActions,
} from "@common/pages/settings/lookups/state-roads/_id/model";

import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class LookupStateRoadStore {
  private _stateRoad?: StateRoad = undefined;
  private _isLoading: boolean = false;
  private _isInactive: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _parentSection?: ILookupStateRoadParentSection = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get stateRoad() {
    return toJS(this._stateRoad);
  }
  setStateRoad = (stateRoad?: StateRoad) => {
    runInAction(() => {
      this._stateRoad = stateRoad;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: ILookupStateRoadParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get stateRoadId() {
    return toJS(this.stateRoad?.StateRoad_ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._stateRoad = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._parentSection = undefined;
      this._onSubmit = undefined;
    });
  };

  reloadStateRoad = async (): Promise<boolean> => {
    if (this.stateRoadId) {
      return await this.loadStateRoad(this.stateRoadId);
    }
    return false;
  };

  loadStateRoad = async (
    stateRoadId: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    if (!isNew) {
      const response = await getStateRoadById(stateRoadId);
      if (isSuccessResponse(response) && response.data) {
        this.setStateRoad(response?.data);
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }

      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({
          ...this._parentSection,
          notification: [],
        } as ILookupStateRoadParentSection);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveStateRoad = async (
    stateRoadInfo: StateRoad,
    action: LookupStateRoadActions
  ) => {
    this.setIsLoading(true);
    const response = await postStateRoad(stateRoadInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.stateRoadId) await this.loadStateRoad(this.stateRoadId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "State road could not be saved",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  runActions = (action: LookupStateRoadActions, response?: APIResponse) => {
    switch (action) {
      case LookupStateRoadActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "State road saved successfully",
          type: "success",
        });
        break;
      case LookupStateRoadActions.New:
        appNotificationStore.clearNotifications();
        history.replace(
          `${SETTINGS_LOOKUPS_STATE_ROADS_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              {
                title: "State road saved successfully",
                type: "success",
              },
            ],
          }
        );
        break;
      default:
        break;
    }
  };
}

export const stateRoadInstance = new LookupStateRoadStore();
const stateRoadContext = createContext(stateRoadInstance);
export const useLookupStateRoadStore = () => {
  return useContext(stateRoadContext);
};
