import {
  DTO_ChargeRun_Lovs,
  DTO_Workflow_ChargeRun,
} from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export type IGetInitialDataCreateChargeRun =
  | [APIResponse<DTO_ChargeRun_Lovs>, APIResponse<DTO_Workflow_ChargeRun>]
  | APIResponse<DTO_ChargeRun_Lovs | DTO_Workflow_ChargeRun>
  | undefined;

export const getInitialDataCreateChargeRun = async (
  workflowDraftId: number | undefined
): Promise<IGetInitialDataCreateChargeRun> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_ChargeRun_Lovs>(
        `/api/property/internal/workflow/createchargerun/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowCreateChargeRun(workflowDraftId)
        : getNewWorkflowCreateChargeRun(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowCreateChargeRun = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ChargeRun>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createchargerun/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowCreateChargeRun = async (): Promise<
  APIResponse<DTO_Workflow_ChargeRun>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createchargerun/new`,
      {}
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessCreateChargeRun = async (
  workflowType: WorkflowProcessMode,
  payload: DTO_Workflow_ChargeRun
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ChargeRun>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createchargerun/process/${workflowType}`,
      payload
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
