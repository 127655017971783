import { DTO_Supplementary_Transactions } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getSupplementaryTransactionDetail = async (
  id: number,
  transactionId: number
): Promise<APIResponse<DTO_Supplementary_Transactions | undefined>> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Supplementary_Transactions>(
        `api/property/internal/supplementary/${id}/transaction/${transactionId}`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
