import { RaiseAssessmentInterestButton } from "@app/products/property/assessments/components/action-bar/buttons/raise-assessment-interest/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessmentRaiseAssessmentInterestButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  return (
    <RaiseAssessmentInterestButton
      isDisabled={gridSelectedRows?.length !== 1}
    />
  );
});
