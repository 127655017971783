import { colAddressForParcel } from "@app/products/property/parcels/[id]/components/child-screens/general/components/form-element/components/address-for-parcel/config";
import {
  DTO_ParcelAddress,
  DTO_ParcelAddress_Extend,
} from "@app/products/property/parcels/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import React from "react";
interface IAddressForParcelProps {
  data: DTO_ParcelAddress[];
}
const nameOfAddress = nameOfFactory<DTO_ParcelAddress_Extend>();
export const AddressForParcel = ({ data }: IAddressForParcelProps) => {
  return (
    <div className="cc-field cc-address-for-parcel">
      <CCLabel title="Addresses" />
      <CCGrid
        className="cc-address-for-parcel-grid"
        columnFields={colAddressForParcel}
        data={data || []}
        primaryField={nameOfAddress("Id")}
      />
    </div>
  );
};
