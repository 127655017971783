import { removeItemFromGridStoreURLStored } from "@components/cc-product-list-view/components/hooks/util";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  SeriesClickEvent,
} from "@progress/kendo-react-charts";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export const CrmsPieChart = observer((props: any) => {
  const history = useHistory();
  const { queryDateTime, data, groupName, name, route } = props;

  const CrmsTypeChart = () => {
    return (
      <Chart
        style={{ height: 250 }}
        onSeriesClick={(event: SeriesClickEvent) => {
          const category = event.category;
          removeItemFromGridStoreURLStored(); //clear session store state to apply the new state of chart
          history.push(route, {
            unresolvedPieChartState: {
              category: category,
              isFromDashboard: true,
            },
          });
        }}
      >
        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            overlay={{
              gradient: "glass",
            }}
            tooltip={{ visible: true }}
            data={data}
            categoryField="Label"
            field="Value"
            visible={true}
          />
        </ChartSeries>
        <ChartLegend position="right" orientation="vertical" />
      </Chart>
    );
  };

  return (
    <div className="cc-insight-group">
      <div className="cc-group-name">{groupName}</div>
      <h4>{name}</h4>
      <div className="cc-date-time-description">
        as of {queryDateTime ?? ""}
      </div>
      <div className="cc-insight-group-container">
        {data && <CrmsTypeChart />}
      </div>
    </div>
  );
});
