import { useNoticeRunCollectionStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/collection-selections/store";
import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CollectionSelectionsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { onChange, valueGetter } = formRenderProps;
    const { collectionLOVs } = useNoticeRunCollectionStepStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const collectionLov = useMemo(() => {
      return collectionLOVs?.Collections ?? [];
    }, [collectionLOVs?.Collections]);

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Include
                <CCTooltip content="Only assessments included in one of the selected collections are to be included" />
              </label>
              <Field
                name={nameOf("CollectionsToInclude")}
                component={CCMultiSelectDropdown}
                data={collectionLov ?? []}
                onChange={(event: MultiSelectChangeEvent) => {
                  let value = event?.value;
                  if (value?.length) {
                    value = value.map((item: DTO_LOV_Number) => item.Code);
                  }
                  onChange(nameOf("CollectionsToInclude"), {
                    value: value,
                  });
                }}
                value={getMultipleSelectValue(
                  getFieldValue("CollectionsToInclude"),
                  collectionLov,
                  "Code"
                )}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Exclude
                <CCTooltip content="Assessments included in any of the selected collections are to be excluded" />
              </label>
              <Field
                name={nameOf("CollectionsToExclude")}
                component={CCMultiSelectDropdown}
                data={collectionLov ?? []}
                onChange={(event: MultiSelectChangeEvent) => {
                  let value = event?.value;
                  if (value?.length) {
                    value = value.map((item: DTO_LOV_Number) => item.Code);
                  }
                  onChange(nameOf("CollectionsToExclude"), {
                    value: value,
                  });
                }}
                value={getMultipleSelectValue(
                  getFieldValue("CollectionsToExclude"),
                  collectionLov,
                  "Code"
                )}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
