import { find } from "lodash";
import React from "react";

export const GetHelpText = (idHelpText: string) => {
  return find(helpTextList, ["id", idHelpText])?.content;
};

const helpTextList = [
  {
    id: "1341",
    content: (
      <div id="1341">
        <p>
          <b>Help Hint [1341]</b>
        </p>
        <p>
          The Completed action that will be created when you send the inspection
          report.
        </p>
        <br />
        {/* <img src="[WEBSITE_URL]/Resources/Help/Images/ActionType_Sent.png" /></div> */}
      </div>
    ),
  },
  {
    id: "1342",
    content: (
      <div id="1342">
        <p>
          <b>Help Hint [1342]</b>
        </p>
        <p>
          The pending action that will be created if you do not send the Report.
        </p>
        <br />
        {/* <img src="[WEBSITE_URL]/Resources/Help/Images/ActionType_Sent.png" /> */}
      </div>
    ),
  },
  {
    id: "1343",
    content: (
      <div id="1343">
        <p>
          <b>Help Hint [1343]</b>
        </p>
        <p>
          <b>Simple Inspection</b>
        </p>
        <br />
        <p>
          Simple inspection is a predefined inspection. Enabling this will cause
          the inspection to always affect the schedule. This setting also
          controls the compliance keyword values listed on the inspection
          screen.
        </p>
        {/* <BR /><BR />
        <img src="[WEBSITE_URL]/Resources/Help/Images/Compliance-Simple-Options.png" />
        <BR /><BR />
        <img src="[WEBSITE_URL]/Resources/Help/Images/Inspection-Compliance-Simple.png" /> */}
      </div>
    ),
  },
  {
    id: "SearchWarrantOption",
    content: (
      <div id="SearchWarrantOption">
        <p>
          <b>Help Hint [SearchWarrantOption]</b>
        </p>
        <br />
        <p>
          HSR product disabled: HSR has the ability to create search warrants
          and allocate them. Enable this feature to allow the user to assign a
          search warrant to the inspection record.
        </p>
      </div>
    ),
  },
];
