import { RequestJournalObj } from "@app/products/property/journals/not-yet-in-journal/components/action-bar/dialog/add-to-journal/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postAddToJournal = async (
  requestBody: RequestJournalObj
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/journal/create/`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
