import { EWatchStatusLoading } from "@common/stores/route/model";
import {
  isArray,
  isEqual,
  isNil,
  keys,
  mergeWith,
  pickBy,
  sortBy,
} from "lodash";
import {
  configure,
  makeAutoObservable,
  reaction,
  runInAction,
  toJS,
} from "mobx";
import { createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
configure({ enforceActions: "always" });

type TDataRoute = Partial<Record<string, any>>;
type TWatchStatusApiCallDone =
  | EWatchStatusLoading
  | EWatchStatusLoading[]
  | string
  | string[]
  | undefined;

class RouteDataStore {
  private _dataRoutes: Partial<Record<string, TDataRoute>> = {};
  private _route: string = window.location.pathname;
  private _prevRoute = this._route;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.dataRoute,
      (currentDataRoute) => {
        this.handleGetApiCallDone(currentDataRoute);
      }
    );

    //#region Watch change route ========/
    reaction(
      () => this._route,
      (newRoute) => {
        // Set current route when first load store or change route
        this.setCurrentRoute(newRoute);
        if (this._prevRoute !== newRoute) {
          this.setDataRoute(this._prevRoute, {});
          this._prevRoute = newRoute;
        }
      },
      {
        fireImmediately: true,
      }
    );
    //#endregion Watch change route =====/
  }

  setWatchStatusApiCallDone = (data?: TWatchStatusApiCallDone) => {
    runInAction(() => {
      if (isNil(data)) {
        this.pushDataRoute({
          watchStatusApiCallDone: undefined,
        });
      } else {
        this.pushDataRoute({
          watchStatusApiCallDone: data,
        });
      }
    });
  };

  loadingActive = (data?: TWatchStatusApiCallDone): boolean => {
    const currentDataRoute = this._dataRoutes[window.location.pathname];
    if (isNil(currentDataRoute?.loadingObject)) return false;
    const loadingDoneList = keys(
      pickBy(currentDataRoute.loadingObject, (value) => value === false)
    ) as EWatchStatusLoading[];
    const allEnumStatusLoading = Object.keys(EWatchStatusLoading);
    if (isNil(data)) {
      return loadingDoneList.length === allEnumStatusLoading.length;
    } else {
      const loadingDoneListRequired = loadingDoneList.filter(
        (item: EWatchStatusLoading) => data.includes(item)
      );
      return isEqual(sortBy(loadingDoneListRequired), sortBy(data));
    }
  };

  isLoadingFor = (
    data: EWatchStatusLoading | EWatchStatusLoading[]
  ): boolean => {
    const currentDataRoute = this._dataRoutes[window.location.pathname];
    if (isNil(currentDataRoute?.loadingObject)) return false;
    return isArray(data)
      ? data.some((item) => currentDataRoute.loadingObject[item])
      : !!currentDataRoute.loadingObject[data];
  };
  get isLoadingPage(): boolean {
    const currentDataRoute = this._dataRoutes[window.location.pathname];
    if (isNil(currentDataRoute?.loadingObject)) return false;
    return Object.values(currentDataRoute.loadingObject).some(
      (value) => value === true
    );
  }
  isApiCallDone = () => {
    const currentDataRoute = this._dataRoutes[window.location.pathname];
    return toJS(currentDataRoute?.isApiCallDone);
  };

  handleGetApiCallDone = (currentDataRoute: any) => {
    let status = false;
    if (isNil(currentDataRoute?.watchStatusApiCallDone)) {
      status = this.loadingActive() && currentDataRoute?.isApiCallStarted;
    } else {
      status =
        this.loadingActive(currentDataRoute?.watchStatusApiCallDone) &&
        currentDataRoute?.isApiCallStarted;
    }
    if (status) {
      runInAction(() => {
        this.pushDataRoute({
          isApiCallDone: true,
          isApiCallStarted: false,
        });
      });
    }
    return status;
  };

  setLoadingList = (data: EWatchStatusLoading | string, status: boolean) => {
    runInAction(() => {
      this.pushDataRoute({
        isApiCallStarted: true,
        loadingObject: {
          [data]: status,
        },
      });
    });
  };
  clearLoadingList = () => {
    runInAction(() => {
      this.pushDataRoute({
        loadingObject: {},
        isApiCallStarted: false,
        watchStatusApiCallDone: undefined,
      });
    });
  };

  //#region CORE FUNCTION ========/
  get currentRoute() {
    return this._route;
  }
  setCurrentRoute(route: string) {
    this._route = route;
  }
  get dataRouteAll() {
    return toJS(this._dataRoutes);
  }
  get dataRoute() {
    return toJS(this._dataRoutes[this._route]) as TDataRoute;
  }
  setDataRoute = (route: string, data: Partial<Record<string, any>>) => {
    runInAction(() => {
      this._dataRoutes[route] = data;
    });
  };
  pushDataRoute = (newDataRoute: any) => {
    const currentDataRoute = this._dataRoutes[window.location.pathname];
    this.setDataRoute(
      window.location.pathname,
      mergeWith(
        currentDataRoute,
        newDataRoute,
        (objValue, srcValue, key, obj) => {
          if (objValue !== srcValue && typeof srcValue === "undefined") {
            obj[key] = srcValue;
          }
        }
      )
    );
  };
  clearCurrentRouteData = () => {
    this.setDataRoute(window.location.pathname, {});
  };
  //#endregion CORE FUNCTION =====/
}
export const routeDataStoreInstance = new RouteDataStore();
const routeDataStoreContext = createContext(routeDataStoreInstance);
export const useRouteDataStore = () => {
  const location = useLocation();
  useEffect(() => {
    // Reset current route when change route
    if (routeDataStoreInstance.currentRoute !== location.pathname) {
      routeDataStoreInstance?.setCurrentRoute(location.pathname);
    }
    return () => {
      // Reset current route when unmount
      routeDataStoreInstance?.setCurrentRoute(location.pathname);
    };
  }, [location.pathname]);
  return useContext(routeDataStoreContext);
};
