import { getDisplayTextWithDashes } from "@common/utils/common";
import { getLabel } from "@app/products/property/components/label/config";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Id,
    selectedRow.Property_Address,
    selectedRow.Property_Locality,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Master Properties`;
};
