import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getDirectDebitAuthorisationAccounts = async (
  id: string | number,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitAccount[]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitAccount[]>(
      `api/receipting/internal/directdebitauthorisation/${id}/accounts`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
