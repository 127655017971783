import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { TypeSubmitActions } from "@common/constants/enumerations";
import { PRODUCT_TYPE, PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { LookupHelpDocumentForm } from "@common/pages/settings/lookups/help-documents/_id/components/child-screens/general/_index";
import { LookupHelpDocumentTabHistory } from "@common/pages/settings/lookups/help-documents/_id/components/reference-sidebar/history/_index";
import { useLookupHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/store";
import { isVisibleHelpDocument } from "@common/pages/settings/lookups/help-documents/util";
import { usePermissionStore } from "@common/pages/settings/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedLookupHelpDocument = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const {
    helpDocumentId,
    helpDocument,
    loadHelpDocument,
    isLoading,
    responseLoadError,
    onSubmit,
    isInactive,
  } = useLookupHelpDocument();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } = usePermissionStore()
  const { isLoadingPermission, checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_HelpDocument,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const [showSlideBar, setShowSlideBar] = useState(true);
  const [permissionLoadingFunc, setPermissionLoadingFunc] = useState<boolean>(false);

  const managePageUrl = window.location.pathname;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        helpDocument?.Attachment_ID,
        helpDocument?.Title,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Settings - Help Document - ${helpDocument?.Attachment_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Link,
      Record_ID: helpDocumentId ?? 0,
    },
  ];

  const handleCheckPermission = async () => {
    setPermissionLoadingFunc(true)
    await checkPermissionForCurrentMenu(FormActionCheckPermission.GLOBAL_SETTINGS, PRODUCT_TYPE_NUMBER.Core)
    setPermissionLoadingFunc(false)
  }

  useEffectOnce(() => {
    handleCheckPermission()
    return () => {
      resetStore()
    }
  })

  if (!isVisibleHelpDocument()) {
    return <CCNotFoundPage title='No permission' subTitle="" />;
  }

  return (
    <LoadingPermissionWrapper
      isLoadingPermission={isLoadingPermission || permissionLoadingFunc}
    >
      <>
        <Loading isLoading={isLoading} isFullScreen />
        <FormNavigation title='Help Document' />
        {responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => loadHelpDocument(parseInt(id))}
          />
        ) : (
          <>
            <FormTitle title={helpDocument?.Title || ""} />
            <CCManagePageActionBar
              leftActions={[
                <CCNavButton
                  title='Save'
                  name={TypeSubmitActions.Save}
                  onClick={onSubmit}
                  disabled={isInactive || !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_EDIT)}
                />,
              ]}
              rightActions={[
                <CCNavButton
                  title='Slide Bar'
                  iconClass="fal fa-info-circle"
                  isActive={showSlideBar}
                  onClick={() => {
                    setShowSlideBar(!showSlideBar);
                  }}
                  disabled={isInactive}
                />,
                <ActionBarBookmarkIcon
                  bookmarkList={bookmarkList}
                  disabled={isInactive}
                />,
              ]}
            />

            <div className="cc-manage-container">
              <div className="cc-form cc-manage-left">
                <CCAppNotification />
                <div className="cc-manage-form-body">
                  {helpDocument && <LookupHelpDocumentForm />}
                </div>
              </div>
              {showSlideBar && (
                <div className="cc-manage-right">
                  <ReferenceSideBar
                    components={[
                      {
                        title: 'History',
                        component: <LookupHelpDocumentTabHistory />,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    </LoadingPermissionWrapper>

  );
});
