import { getComplianceRestrictionsById } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/buttons/modify-functionality-restrictions/api";
import { colModifyFunctionalityRestrictions } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/dialog/modify-functionality-restrictions/config";
import { V_Compliance_Functionality } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/dialog/modify-functionality-restrictions/model";
import { functionalitiesValid } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/dialog/modify-functionality-restrictions/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useResponsibleOfficerStore } from "@common/stores/responsible-officer/store";
import { nameOfFactory } from "@common/utils/common";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IFunctionalityRestrictionsDialogProps {
  title: string;
  isLoading: boolean;
  complianceId: number;
  onClose: () => void;
  onSubmit: (dataItem: V_Compliance_Functionality[]) => void;
}

const nameOf = nameOfFactory<V_Compliance_Functionality>();

export const FunctionalityRestrictionsDialog = observer(
  ({
    title,
    isLoading = false,
    complianceId,
    onClose,
    onSubmit,
  }: IFunctionalityRestrictionsDialogProps) => {
    const [functionalities, setFunctionalities] = useState<
      V_Compliance_Functionality[]
    >([]);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isLoadingInitData, setIsLoadingInitData] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const { responsibleOfficer } = useResponsibleOfficerStore();

    const validNotifications = useMemo((): IAppNotificationItem[] => {
      return functionalitiesValid(functionalities);
    }, [functionalities]);

    const handleSubmit = () => {
      const submitData = functionalities.map(
        (functionality: V_Compliance_Functionality) => {
          return {
            ...functionality,
            CF_Is_Restricted: !!(
              functionality?.CF_Restrict_From || functionality?.CF_Restrict_To
            ),
            CF_Modified_On: new Date(),
            CF_Modified_By: responsibleOfficer?.FullName ?? "",
          };
        }
      );
      onSubmit(submitData);
    };

    const loadInitialValue = async () => {
      setIsLoadingInitData(true);
      const response = await getComplianceRestrictionsById(complianceId);
      setIsLoadingInitData(false);

      if (isSuccessResponse(response) && response?.data) {
        setFunctionalities(response?.data);
      } else {
        const errorResponse = {
          status: response.status,
          error: response.error,
        };
        setResponseLoadError(errorResponse);
      }
    };

    useEffectOnce(() => {
      loadInitialValue();
    });

    return (
      <Form
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit: onFormSubmit } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth="45%"
                height="auto"
                disabled={isLoading}
                titleHeader={title}
                onClose={onClose}
                bodyElement={
                  <div className="cc-form">
                    {responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          setResponseLoadError(undefined);
                          loadInitialValue();
                        }}
                      />
                    ) : (
                      <>
                        <CCLocalNotification
                          defaultNotifications={validNotifications}
                        />
                        <CCGrid
                          data={functionalities}
                          columnFields={colModifyFunctionalityRestrictions}
                          primaryField={nameOf("Functionality_Name")}
                          editableMode="cell"
                          isLoading={isLoadingInitData}
                          itemPerPage={10}
                          onDataChange={(
                            data: V_Compliance_Functionality[]
                          ) => {
                            setIsModified(true);
                            setFunctionalities(data);
                          }}
                        />
                      </>
                    )}
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={validNotifications.length > 0 || !isModified}
                      themeColor="primary"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onSubmit={onFormSubmit}
                      className={"cc-dialog-button"}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
