import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Workflow_ModifyGIS,
  RequestTitleObj,
} from "@app/products/property/titles/[id]/components/dialogs/modify-gis-reference-title/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";
import { isNil } from "lodash";

export const loadInitialDataTitleGISReference = async (
  titleId: number | string,
  workflowId?: number | string
): Promise<APIResponse<DTO_Workflow_ModifyGIS>> => {
  try {
    if (!isNil(workflowId)) {
      return await CoreAPIService.getClient().get(
        `/api/property/int/workflow/title/modifygis/${workflowId}`
      );
    }
    return await CoreAPIService.getClient().post(
      `/api/property/int/workflow/title/modifygis/new`,
      {
        TitleId: titleId,
      } as RequestTitleObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: (errorResponse.data as ResponsePacket)?.Errors,
    };
  }
};

export const postWorkflowTitleGISReference = async (
  workflowType: WorkflowProcessMode,
  payload: DTO_Workflow_ModifyGIS
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyGIS>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/workflow/title/modifygis/process/${workflowType}`,
      payload
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
