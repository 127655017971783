import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import {
  IIssueDocumentButtonProps,
  IssueDocumentButton,
} from "@app/products/property/components/action-bar/button/issue-document/_index";
import React from "react";

export const IssueDocumentCertificateButton = (
  props: IIssueDocumentButtonProps
) => {
  const { reLoadCertificate } = useCertificateStore();
  return (
    <IssueDocumentButton
      titleButton="Issue certificate"
      titleDialog="Issue Certificate Document"
      reloadManagePage={reLoadCertificate}
      {...props}
    />
  );
};
