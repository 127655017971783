import { getGISAccordion } from "@app/products/property/components/gis-accordion/api";
import { colGISReferenceAccordion } from "@app/products/property/components/gis-accordion/config";
import { GISAccordionEventType } from "@app/products/property/components/gis-accordion/constant";
import {
  DTO_Component_GIS_Reference,
  DTO_GIS_Reference,
} from "@app/products/property/components/gis-accordion/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IGisAccordionProps {
  apiUrl: string;
}
const nameOf = nameOfFactory<DTO_GIS_Reference>();
export const GISAccordion = ({ apiUrl }: IGisAccordionProps) => {
  const [gisData, setGISData] = useState<DTO_Component_GIS_Reference>();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadData = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getGISAccordion(apiUrl);
    if (isSuccessResponse(response) && response?.data) {
      setGISData(response.data);
    } else {
      setResponseLoadError({
        status: response?.status,
        error: response?.error ?? "Load GIS data failed",
      });
    }
    setIsLoading(false);
  };

  useAddUniqueEventEmitter([
    {
      eventType: GISAccordionEventType.reloadGISReferenceGrid,
      listener: () => loadData(),
    },
  ]);

  useEffectOnce(() => {
    loadData();
  });

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  }

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCGrid
            data={gisData?.GIS_Reference ?? []}
            columnFields={colGISReferenceAccordion ?? []}
            primaryField={nameOf("GIS_Interface_Id")}
          />
        </div>
      </div>
    </div>
  );
};
