import { loadMeterDetailById } from "@app/products/property/meters/[id]/api";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class MeterStore {
  private _meter?: IMeterFormGeneral = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get meter() {
    return this._meter;
  }
  setMeter = (meter?: IMeterFormGeneral) => {
    runInAction(() => {
      this._meter = meter;
    });
  };
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get meterId() {
    return this._meter?.Id;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._meter = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
  loadMeter = async (meterId: number) => {
    this.setIsLoading(true);
    const response = await loadMeterDetailById(meterId);
    let errorResponse = undefined;
    let newMeter = undefined;
    if (isSuccessResponse(response)) {
      newMeter = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setMeter(newMeter);
    this.setIsLoading(false);
  };
}

const meterStoreContext = createContext(new MeterStore());
export const useMeterStore = () => {
  return useContext(meterStoreContext);
};
