import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { aesEncrypt } from "@common/utils/cryptography";

interface IChangePassword {
  UserName: string;
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}
export const putChangePassword = async (
  obj: IChangePassword
): Promise<APIResponse<any>> => {
  try {
    const data = {
      UserName: obj.UserName,
      CurrentPassword: aesEncrypt(obj.CurrentPassword),
      NewPassword: aesEncrypt(obj.NewPassword),
      ConfirmPassword: aesEncrypt(obj.ConfirmPassword),
    };
    return await CoreAPIService.getClient().put<any>(
      `/api/core/internal/change-password`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
