import { apiCorePostFeeTypesImport } from "@app/core/components/common/utils";
import { Attachment } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postImportFeeTypeList = async (
  attachment: Attachment,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCorePostFeeTypesImport(),
      attachment,
      { cancelToken }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
