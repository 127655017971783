import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { SecurityAction } from "@common/pages/settings/security/security-templates/model";

export const postSecurityActionsAllow = async (
  securityTemplateId: number,
  requestBody: SecurityAction[]
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/security-templates/allow/${securityTemplateId}`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
