import { getIdentifyCandidates } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/candidates/api";
import { DTO_DebtRecovery_IdentityOption } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/candidates/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
interface ICandidatesDialog {
  onClose: () => void;
}
const nameof = nameOfFactory<DTO_DebtRecovery_IdentityOption>();
export const CandidatesDialog = observer(({ onClose }: ICandidatesDialog) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] =
    useState<DTO_DebtRecovery_IdentityOption>();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadIdentifyCandidates = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getIdentifyCandidates();
    if (isSuccessResponse(response) && response?.data?.DebtRecoveryOptions) {
      setInitialValues(response?.data?.DebtRecoveryOptions);
    } else {
      setResponseLoadError({
        status: response?.status,
        error: response?.error ?? "Load identify candidate Data failed.",
      });
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadIdentifyCandidates();
  });

  const handleSubmit = () => {
    onClose();
  };

  return (
    <CCDialog
      titleHeader="Identify Candidates"
      onClose={onClose}
      maxWidth="55%"
      maxHeight="70%"
      bodyElement={
        isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <>
            {responseLoadError && !initialValues ? (
              <div>
                <CCLoadFailed
                  responseError={responseLoadError}
                  onReload={() => {
                    loadIdentifyCandidates();
                  }}
                />
              </div>
            ) : (
              <div className="cc-new-arrangement cc-form">
                <Form
                  initialValues={initialValues}
                  render={(formRenderProps: FormRenderProps) => {
                    const { valueGetter } = formRenderProps;
                    return (
                      <FormElement>
                        <div className="cc-candidates-dialog cc-form">
                          <div className="cc-form-cols-1">
                            <section className="cc-field-group">
                              <div className="cc-form-cols-1">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    <div className="cc-label-field-inline-group">
                                      <div className="cc-label-field-inline-group-left">
                                        <div className="cc-right-label">
                                          Has at least
                                        </div>
                                        <div className="cc-right-content">
                                          <Field
                                            name={nameof("Minimum_Amount")}
                                            component={CCCurrencyInput}
                                            min={0}
                                          />
                                        </div>
                                      </div>
                                      <div className="cc-label-field-inline-group-right">
                                        <div className="cc-left-label">
                                          overdue for
                                        </div>
                                        <div className="cc-custom-input-group">
                                          <Field
                                            name={nameof("Overdue_for_X_days")}
                                            component={CCNumericTextBox}
                                            min={0}
                                            width="80px"
                                          />
                                          <div className="cc-input-group-postfix">
                                            days
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="cc-form-cols-1">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    <div className="cc-label-field-inline-group">
                                      <div className="cc-label-field-inline-group-left">
                                        <div className="cc-right-label">
                                          Exclude if
                                        </div>
                                        <div className="cc-right-content">
                                          <Field
                                            name={nameof(
                                              "Minimum_Payment_Amount"
                                            )}
                                            component={CCCurrencyInput}
                                            min={0}
                                          />
                                        </div>
                                      </div>
                                      <div className="cc-label-field-inline-group-right">
                                        <div className="cc-left-label">
                                          has been paid in the last
                                        </div>
                                        <div className="cc-custom-input-group">
                                          <Field
                                            name={nameof(
                                              "Payment_Period_in_Days"
                                            )}
                                            component={CCNumericTextBox}
                                            min={0}
                                            width="80px"
                                          />
                                          <div className="cc-input-group-postfix">
                                            days
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>

                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Notice produced within last
                                  </label>
                                  <div className="cc-custom-input-group">
                                    <Field
                                      name="NoticeProducedWithinLast"
                                      component={CCNumericTextBox}
                                      min={0}
                                    />
                                    <div className="cc-input-group-postfix">
                                      days
                                    </div>
                                  </div>
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Only includes Candidates with
                                  </label>
                                  <div className="cc-custom-input-group">
                                    <Field
                                      name={nameof("Minimum_No_of_Priors")}
                                      component={CCNumericTextBox}
                                      min={0}
                                    />
                                    <div className="cc-input-group-postfix cc-postfix-fixed-width">
                                      prior Debt Recoveries
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <br />
                            <section className="cc-field-group">
                              <label className="cc-label">
                                Exclude assessments with an active
                              </label>
                              <div className="cc-custom-sub-panel-bar">
                                <div className="cc-form-cols-2">
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Debt recovery exemption
                                    </label>
                                    <Field
                                      name={nameof("Exclude_If_Exemption")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_If_Exemption"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Direct debit authority
                                    </label>
                                    <Field
                                      name={nameof("Exclude_If_Direct_Debit")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_If_Direct_Debit"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Debt recovery
                                    </label>
                                    <Field
                                      name={nameof("Exclude_If_Debt_Recovery")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_If_Debt_Recovery"
                                      )}
                                    />
                                  </div>

                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Rebate entitlement
                                    </label>
                                    <Field
                                      name={nameof(
                                        "Exclude_If_Rebate_Entitlement"
                                      )}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_If_Rebate_Entitlement"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Arrangement
                                    </label>
                                    <Field
                                      name={nameof("Exclude_If_Arrangement")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_If_Arrangement"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Ratepayer in a notice group
                                    </label>
                                    <Field
                                      name={nameof(
                                        "Exclude_If_In_Notice_Group"
                                      )}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_If_In_Notice_Group"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Minimum payment made
                                    </label>
                                    <Field
                                      name={nameof(
                                        "Exclude_if_Minimum_Payment_Made"
                                      )}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Exclude_if_Minimum_Payment_Made"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Only include Candidates with priors
                                    </label>
                                    <Field
                                      name={nameof(
                                        "Only_Include_Debtors_with_Priors"
                                      )}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        "Only_Include_Debtors_with_Priors"
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                            <br />
                            <section className="cc-field-group">
                              <label className="cc-label">
                                Details to show
                              </label>
                              <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Breakup amounts due by period
                                  </label>
                                  <Field
                                    name={nameof(
                                      "Breakup_Amounts_Due_By_Period"
                                    )}
                                    component={CCSwitch}
                                    checked={valueGetter(
                                      "BreakupAmountsDueByPeriod"
                                    )}
                                  />
                                </div>
                                <div className="cc-form-cols-2">
                                  <div className="cc-field">
                                    <label className="cc-label">Period 1</label>
                                    <div className="cc-custom-input-group">
                                      <Field
                                        name={nameof("Days_In_Period_1")}
                                        component={CCNumericTextBox}
                                        min={0}
                                      />
                                      <div className="cc-input-group-postfix">
                                        days
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">Period 2</label>
                                    <div className="cc-custom-input-group">
                                      <Field
                                        name={nameof("Days_In_Period_2")}
                                        component={CCNumericTextBox}
                                        min={0}
                                      />
                                      <div className="cc-input-group-postfix">
                                        days
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">Period 3</label>
                                    <div className="cc-custom-input-group">
                                      <Field
                                        name={nameof("Days_In_Period_3")}
                                        component={CCNumericTextBox}
                                        min={0}
                                      />
                                      <div className="cc-input-group-postfix">
                                        days
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">Period 4</label>
                                    <div className="cc-custom-input-group">
                                      <Field
                                        name={nameof("Days_In_Period_4")}
                                        component={CCNumericTextBox}
                                        min={0}
                                      />
                                      <div className="cc-input-group-postfix">
                                        days
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">Period 5</label>
                                    <div className="cc-custom-input-group">
                                      <Field
                                        name={nameof("Days_In_Period_5")}
                                        component={CCNumericTextBox}
                                        min={0}
                                      />
                                      <div className="cc-input-group-postfix">
                                        days
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </FormElement>
                    );
                  }}
                />
              </div>
            )}
          </>
        )
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Identify
          </Button>
        </div>
      }
    />
  );
});
