import { ExistedRoster } from "@app/products/crms/system-admin/rosters/[id]/components/forms/existed/_index";
import { NewRoster } from "@app/products/crms/system-admin/rosters/[id]/components/forms/new/_index";
import { useRosterStore } from "@app/products/crms/system-admin/rosters/[id]/store";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManageRoaster = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { resetStore, loadRoster, setParentSection } = useRosterStore();
  const location = useLocation();
  const notificationState = location.state as IPSARApplicationParentSection;

  useEffect(() => {
    if (notificationState && notificationState?.notification) {
      setParentSection(notificationState);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState]);

  useEffect(() => {
    loadRoster(parseInt(id), isNew);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  if (isNew) {
    return <NewRoster />;
  }
  return <ExistedRoster />;
};

export default observer(ManageRoaster);
