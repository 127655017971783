import { leaseTransfersRoute } from "@app/products/property/changes-of-ownership/lease-transfers/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const changesOfOwnershipRoute: ICCRoute = {
  path: "changes-of-ownership",
  name: "Changes Of Ownership",
  enum: eMenuAction.Change_of_Ownership_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Change_of_Ownership_List,
      component: require("./list/_index").default,
    },
    {
      path: "change-of-associated-name-address",
      name: "Change Of Associated Name Address",
      enum: eMenuAction.Change_of_Associated_Name_Address,
      component: require("./change-of-associated-name-address/_index").default,
    },
    {
      path: "change-of-name-address",
      name: "Change Of Name Address",
      enum: eMenuAction.Change_of_Name_Address,
      component: require("./change-of-name-address/_index").default,
    },
    {
      path: "full-transfer-of-ownership",
      name: "Full Transfer Of Ownership",
      enum: eMenuAction.Transfer_of_Ownership_Full,
      component: require("./full-transfer-of-ownership/_index").default,
    },
    {
      path: "partial-transfer-of-ownership",
      name: "Partial Transfer Of Ownership",
      enum: eMenuAction.Transfer_of_Ownership_Partial,
      component: require("./partial-transfer-of-ownership/_index").default,
    },
    //For LLS
    {
      path: "notice-of-sales",
      name: "Notice Of Sales",
      enum: eMenuAction.Change_of_Ownership_Lease_Transfers,
      component: require("./notice-of-sales/_index").default,
    },
    //For ACT
    leaseTransfersRoute,
  ],
};
