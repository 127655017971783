import { ICCRoute } from "@common/constants/ICCRoute";
export const dynamicListsRoute: ICCRoute = {
  path: "dynamic-lists",
  name: "Dynamic Question Lists",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
