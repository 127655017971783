import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";

export const TabDetails = ({
  formRenderProps,
}: {
  formRenderProps: FormRenderProps;
}) => {
  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Assess number</label>
            <Field
              name={"PropertyAssessment.PID_No"}
              component={Input}
              placeholder={"Assess number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Assessment ID</label>
            <Field
              name={"PropertyAssessment.Assessment_ID"}
              component={Input}
              placeholder={"Assessment ID"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">RIN</label>
            <Field
              name={"PropertyAssessment.RIN"}
              component={Input}
              placeholder={"RIN"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Ward</label>
            <Field
              name={"PropertyAssessment.Ward"}
              component={Input}
              placeholder={"Ward"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Area</label>
            <Field
              name={"PropertyAssessment.Area"}
              component={Input}
              placeholder={"Area"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Parish</label>
            <Field
              name={"PropertyAssessment.Parish"}
              component={Input}
              placeholder={"Parish"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Crown allotment</label>
            <Field
              name={"PropertyAssessment.CrownAllotment"}
              component={Input}
              placeholder={"Crown allotment"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Section</label>
            <Field
              name={"PropertyAssessment.Section"}
              component={Input}
              placeholder={"Section"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Planning reference number</label>
            <Field
              name={"PropertyAssessment.PlanRef"}
              component={Input}
              placeholder={"Planning reference number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">LP/PS</label>
            <Field
              name={"PropertyAssessment.LP_PS"}
              component={Input}
              placeholder={"LP/PS"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Volume</label>
            <Field
              name={"PropertyAssessment.Volume"}
              component={Input}
              placeholder={"Volume"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Folio</label>
            <Field
              name={"PropertyAssessment.Folio"}
              component={Input}
              placeholder={"Folio"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">County</label>
            <Field
              name={"PropertyAssessment.County"}
              component={Input}
              placeholder={"County"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Township</label>
            <Field
              name={"PropertyAssessment.Township"}
              component={Input}
              placeholder={"Township"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Valuation number</label>
            <Field
              name={"PropertyAssessment.Valuation_Number"}
              component={Input}
              placeholder={"Valuation number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Certificate of title</label>
            <Field
              name={"PropertyAssessment.CertOfTitle"}
              component={Input}
              placeholder={"Certificate of title"}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Parcel reference</label>
            <Field
              name={"PropertyAssessment.PlanRef"}
              component={Input}
              placeholder={"Parcel reference"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Parcel ID</label>
            <Field
              name={"PropertyAssessment.Parcel_ID"}
              component={CCNumericTextBox}
              spinners={false}
              format={NUMBER_FORMAT.NUMBER2}
              placeholder={"Parcel ID"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Land size</label>
            <Field
              name={"PropertyAssessment.Assessment_LandSize"}
              component={CCNumericTextBox}
              spinners={false}
              format={NUMBER_FORMAT.NUMBER1}
              placeholder={"Land size"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Block</label>
            <Field
              name={"PropertyAssessment.Block"}
              component={Input}
              placeholder={"Block"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Lot</label>
            <Field
              name={"PropertyAssessment.LotNo"}
              component={Input}
              placeholder={"Lot"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Zone</label>
            {/* TODO: Integrate later */}
            <Field
              name={"PropertyAssessment.Zones"}
              component={Input}
              readOnly
              placeholder={"Legal description"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Overlay</label>
            {/* TODO: Integrate later */}
            <Field
              name={"PropertyAssessment.Overlay"}
              component={Input}
              readOnly
              placeholder={"Legal description"}
            />
          </div>
          <div className="cc-field cc-col-span-3">
            <label className="cc-label">Legal description</label>
            <Field
              name={"PropertyAssessment.Legal_Description"}
              component={Input}
              placeholder={"Legal description"}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
