import { rgbaToHex } from "@common/utils/color";
import { Button } from "@progress/kendo-react-buttons";
import {
  ColorPicker,
  ColorPickerChangeEvent,
  ColorPickerProps,
} from "@progress/kendo-react-inputs";
import React, { useCallback } from "react";
import "./_index.scss";

export interface ICCColorPickerProps extends ColorPickerProps {}
export const CCColorPicker = ({
  value,
  onChange,
  disabled,
  view = "gradient",
  gradientSettings = {
    opacity: false,
  },
  ...others
}: ICCColorPickerProps) => {
  const handleOnChange = useCallback(
    (event: ColorPickerChangeEvent) => {
      if (onChange) onChange({ ...event, value: rgbaToHex(event.value) });
    },
    [onChange]
  );

  return (
    <div className="cc-color-picker">
      <ColorPicker
        {...others}
        disabled={disabled}
        view={view}
        gradientSettings={gradientSettings}
        value={value}
        onChange={handleOnChange}
      />
      <Button
        icon="close"
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault();
          handleOnChange({
            value: "",
          } as ColorPickerChangeEvent);
        }}
      />
    </div>
  );
};
