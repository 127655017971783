import { getDDessionLineBySessionNumber } from "@app/products/direct-debit/sessions/[id]/components/accordions/transfer/api";
import { colDirectDebitSessionLine } from "@app/products/direct-debit/sessions/[id]/components/accordions/transfer/config";
import { DirectDebitSessionLine } from "@app/products/direct-debit/sessions/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DirectDebitSessionLine>();
interface IDDSessionLineBySessionNumberProps {
  sessionID: number;
}
export const DDSessionLineBySessionNumber = ({
  sessionID,
}: IDDSessionLineBySessionNumberProps) => {
  const { cancelToken } = useCancelRequest();

  const [transfers, setTransfers] = useState<DirectDebitSessionLine[]>();

  const ddSessionLineBySessionNumberSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => getDDessionLineBySessionNumber(sessionID, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitSessionLine[]> = dataFromApi;
          setTransfers(response?.data ?? []);
        },
      },
    ],
  });

  useEffectOnce(() => {
    ddSessionLineBySessionNumberSlider.fetchApi();
  });

  return (
    <CCGrid
      errorComponent={ddSessionLineBySessionNumberSlider.errorComponent}
      isLoading={ddSessionLineBySessionNumberSlider.isFetching}
      columnFields={colDirectDebitSessionLine}
      data={transfers ?? []}
      primaryField={nameOf("Session_ID")}
    />
  );
};
