const trim = (str: string) => {
  return str.replace(/^\s+|\s+$/gm, "");
};
const toHex = (num: number) => {
  const hex = num.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};
export const rgbaToHex = (rgba: string) => {
  if (rgba === "") return "";
  const parts = rgba.substring(rgba.indexOf("(")).split(",");
  const r = parseInt(trim(parts[0].substring(1)), 10);
  const g = parseInt(trim(parts[1]), 10);
  const b = parseInt(trim(parts[2]), 10);
  return "#" + toHex(r) + toHex(g) + toHex(b);
};
