import { PPREnquiryFormElement } from "@app/products/town-planning/ppr/enquiries/_id/components/child-screens/general/components/form-element/_index";
import {
  PPREnquirySubmitActions,
  Register,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const PPREnquiryForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { pprEnquiry, savePPREnquiry, setOnSubmit } = usePPREnquiryStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget.name as PPREnquirySubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in PPREnquirySubmitActions)) return;

    if (!isValid) return;
    if (
      !isModified &&
      [PPREnquirySubmitActions.Save, PPREnquirySubmitActions.New].includes(
        action
      )
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const pprInfo = values as Register;
    savePPREnquiry(pprInfo, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, pprEnquiry]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={pprEnquiry}
        key={JSON.stringify(pprEnquiry)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <PPREnquiryFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
