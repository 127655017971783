import {
  DTO_Workflow_AreaOfResponsibilityModify,
  RequestAreaOfResponsibilityModifyObj,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/model";
import { DTO_Certificate_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
export const postProcessModifyAreaOfResponsibility = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_AreaOfResponsibilityModify
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_AreaOfResponsibilityModify>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificateaormodify/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialModifyAreaOfResponsibility =
  | [
      APIResponse<DTO_Certificate_LOVs>,
      APIResponse<DTO_Workflow_AreaOfResponsibilityModify>
    ]
  | APIResponse<DTO_Certificate_LOVs | DTO_Workflow_AreaOfResponsibilityModify>
  | undefined;
export const getInitialModifyAreaOfResponsibility = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  certificateId: number | undefined = 0,
  aorId: number | null = null
): Promise<IGetInitialModifyAreaOfResponsibility> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `api/property/int/certificate/lovs`
      ),
      isFromActionList && workflowDraftId
        ? getWorkflowModifyAreaOfResponsibility(workflowDraftId)
        : getNewWorkflowModifyAreaOfResponsibility({
            CertificateId: certificateId,
            AORId: aorId,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowModifyAreaOfResponsibility = async (
  payload: RequestAreaOfResponsibilityModifyObj
): Promise<APIResponse<DTO_Workflow_AreaOfResponsibilityModify>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificateaormodify/new`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowModifyAreaOfResponsibility = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_AreaOfResponsibilityModify>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/workflow/certificateaormodify/${workflowDraftId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
