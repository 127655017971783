import { formatDateByKendo } from "@common/utils/formatting";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const CCDateInputCell = (props: GridCellProps) => {
  const { dataItem } = props;
  const field = props.field || "";

  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  let defaultRendering = dataValue;

  defaultRendering = (
    <td>
      {!dataValue || dataValue === null
        ? ""
        : formatDateByKendo(dataValue.toString())}
    </td>
  );

  return props.render
    ? props.render.call(undefined, defaultRendering, props)
    : defaultRendering;
};
