import { AnimalsRegister } from "@app/products/animals/model";
import { AddAnimalsLookupButton } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/animals-lookup/components/add-animals-lookup-button/_index";
import { colAnimalsLookup } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/animals-lookup/config";
import {
  Event,
  EventAnimalsLookup,
  EventMapObj,
  EventUpdateTriggers,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();
const nameOfAnimalsLookup = nameOfFactory<EventAnimalsLookup>();

export interface AnimalRegistrationsLookupProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

export const AnimalRegistrationsLookup = observer(
  ({ formRenderProps, isDisabled }: AnimalRegistrationsLookupProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { uiControl } = useCRMSEventStore();

    const [selectedItems, setSelectedItems] = useState<EventAnimalsLookup[]>(
      []
    );
    const [animalRegisterData, setAnimalRegisterData] = useState<
      EventAnimalsLookup[]
    >(uiControl?.LitAnimalNoView?.Dataset as EventAnimalsLookup[]);

    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState(false);

    const handleAddAnimalsLookup = (animalRegisters: AnimalsRegister[]) => {
      // update animal register data
      const newAnimalRegisterData: EventAnimalsLookup[] =
        animalRegisterData ?? [];
      animalRegisters?.forEach((animalRegister: AnimalsRegister) => {
        const animalRegisterExisted = animalRegisterData?.some(
          (animalRegisterDataItem: EventAnimalsLookup) =>
            animalRegisterDataItem.Registration_ID === animalRegister.ID
        );
        if (!animalRegisterExisted) {
          newAnimalRegisterData?.push({
            Registration_ID: animalRegister.ID,
            Registration_Number: animalRegister.RegistrationNumber,
            AnimalName: animalRegister.AnimalName,
            AnimalOwner: animalRegister.AnimalOwner,
            Breed: animalRegister.Breed,
            TagNo: animalRegister.TagNo,
          });
        }
      });

      setAnimalRegisterData(newAnimalRegisterData);

      // update animal register ids
      const animalRegisterIds = newAnimalRegisterData
        ?.map((dataItem: EventAnimalsLookup) => dataItem.Registration_ID)
        ?.join("|");

      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("Animal_RegisterIDs")}`,
        { value: animalRegisterIds }
      );

      // update save triggers
      let saveTriggers: EventUpdateTriggers[] =
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`
        ) ?? [];

      if (!saveTriggers.includes(EventUpdateTriggers.LinkAnimalRegistration)) {
        saveTriggers.push(EventUpdateTriggers.LinkAnimalRegistration);
      }

      if (saveTriggers.includes(EventUpdateTriggers.UnlinkAnimalRegistration)) {
        saveTriggers = saveTriggers.filter(
          (saveTrigger: EventUpdateTriggers) =>
            saveTrigger !== EventUpdateTriggers.UnlinkAnimalRegistration
        );
      }

      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`, {
        value: saveTriggers,
      });
    };

    const handleDeleteAnimalsLookup = () => {
      // update animal register data
      const ids = selectedItems.map(
        (dataItem: EventAnimalsLookup) => dataItem.Registration_ID
      );

      if (ids.length > 0) {
        const newAnimalRegisterData = animalRegisterData?.filter(
          (dataItem: EventAnimalsLookup) =>
            !ids.includes(dataItem.Registration_ID)
        );

        setAnimalRegisterData(() => newAnimalRegisterData);

        // update animal register ids
        const animalRegisterIds = newAnimalRegisterData
          ?.map((dataItem: EventAnimalsLookup) => dataItem.Registration_ID)
          ?.join("|");
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("Animal_RegisterIDs")}`,
          { value: animalRegisterIds }
        );

        // update save triggers
        let saveTriggers: EventUpdateTriggers[] =
          valueGetter(
            `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`
          ) ?? [];

        if (
          !saveTriggers.includes(EventUpdateTriggers.UnlinkAnimalRegistration)
        ) {
          saveTriggers.push(EventUpdateTriggers.UnlinkAnimalRegistration);
        }

        if (saveTriggers.includes(EventUpdateTriggers.LinkAnimalRegistration)) {
          saveTriggers = saveTriggers.filter(
            (saveTrigger: EventUpdateTriggers) =>
              saveTrigger !== EventUpdateTriggers.LinkAnimalRegistration
          );
        }

        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`,
          {
            value: saveTriggers,
          }
        );

        // reset selected items
        setSelectedItems([]);
        setIsShowConfirmDeleteDialog(false);
      }
    };

    useEffect(() => {
      setAnimalRegisterData(
        uiControl?.LitAnimalNoView?.Dataset as EventAnimalsLookup[]
      );
    }, [uiControl?.LitAnimalNoView?.Dataset]);

    return (
      <>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Animal registrations" />
              <CCGrid
                columnFields={colAnimalsLookup}
                primaryField={nameOfAnimalsLookup("Registration_ID")}
                data={animalRegisterData ?? []}
                selectableMode={"multiple"}
                selectedRows={selectedItems}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <AddAnimalsLookupButton onSubmit={handleAddAnimalsLookup} />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove"
                      onClick={() => setIsShowConfirmDeleteDialog(true)}
                      disabled={selectedItems.length === 0}
                    />
                  </div>
                }
                onSelectionChange={(dataItem: EventAnimalsLookup[]) => {
                  setSelectedItems(dataItem ?? []);
                }}
                itemPerPage={15}
                isAutoHiddenPager={false}
                readOnly={isDisabled}
              />
            </div>
          </div>

          {isShowConfirmDeleteDialog && (
            <ConfirmDialog
              title={"Confirm Deletion"}
              subMessage={`Are you sure you want to delete ${
                selectedItems.length > 1 ? "these items" : "this item"
              }?`}
              onClosePopup={() => {
                setIsShowConfirmDeleteDialog(false);
              }}
              onConfirmYes={handleDeleteAnimalsLookup}
            />
          )}
        </section>
      </>
    );
  }
);
