import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { crmsRoute } from "@app/products/crms/route";
import { DeleteCategoryButton } from "@app/products/crms/system-admin/categories/components/action-bar/buttons/delete-category/_index";
import { NewCategoryButton } from "@app/products/crms/system-admin/categories/components/action-bar/buttons/new-category/_index";
import { CRMSSystemAdminCategoryDetailsTab } from "@app/products/crms/system-admin/categories/components/reference-sidebar/details/_index";
import { CRMSSystemAdminCategoryHistoryTab } from "@app/products/crms/system-admin/categories/components/reference-sidebar/history/_index";
import { colCategories } from "@app/products/crms/system-admin/categories/config";
import { CRMSSystemAdminCategoryBookmark } from "@app/products/crms/system-admin/categories/util";
import {
  CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE,
  CRMS_SYSTEM_ADMIN_CATEGORY_URL,
} from "@app/products/crms/system-admin/constant";
import { ServiceStandardCategories } from "@app/products/crms/system-admin/model";
import { systemAdminRoute } from "@app/products/crms/system-admin/route";
import { CRMSSystemAdminBookmark } from "@app/products/crms/system-admin/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<ServiceStandardCategories>();

export default () => {
  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="New" type="sub">
        <NewCategoryButton />
      </CCNavButton>,
      <DeleteCategoryButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_ServiceStandard}
        detail={CRMSSystemAdminBookmark.getBookmarkDetail}
        displayName={CRMSSystemAdminBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSSystemAdminCategoryBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CRMSSystemAdminCategoryBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSSystemAdminCategoryDetailsTab /> },
      {
        title: "History",
        component: <CRMSSystemAdminCategoryHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colCategories}
      primaryField={nameOf("ID")}
      dataUrl={CRMS_SYSTEM_ADMIN_CATEGORY_URL}
      state={{ sort: [{ field: nameOf("Category"), dir: "asc" }] }}
    />
  );
};
