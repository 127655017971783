import { getMeterData } from "@components/cc-search-meter/api";
import { meterSearchMinCharacter } from "@components/cc-search-meter/config";
import {
  IMeterSearch,
  IMeterSearchBy,
} from "@components/cc-search-meter/model";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import React, { ReactElement, useState } from "react";

interface ICCSearchMeter extends ComboBoxProps {
  minCharacter?: number;
  apiUrl?: string;
  searchBy: IMeterSearchBy;
}

export const CCSearchMeter = (props: ICCSearchMeter) => {
  const { value, searchBy, onChange, apiUrl = "", minCharacter } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataMeter, setDataMeter] = useState<IMeterSearch[]>([]);

  const handleSearchMeter = (event: ComboBoxFilterChangeEvent) => {
    let minCharacterApply: number = minCharacter
      ? minCharacter
      : meterSearchMinCharacter[searchBy.Key] ?? 1;
    if (event.filter.value.length >= minCharacterApply) {
      setDataMeter([]);
      setIsLoading(true);
      getMeterData(event.filter, apiUrl).then((data) => {
        setDataMeter(data);
        setIsLoading(false);
      });
    } else {
      setDataMeter([]);
    }
  };

  const itemRender = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <span>
        {li.props.children} {` - ${dataItem.PropertyAddress}`}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <ComboBox
      suggest
      filterable
      loading={isLoading}
      data={dataMeter}
      textField={searchBy.Key}
      onFilterChange={handleSearchMeter}
      value={value}
      onChange={onChange}
      placeholder={`Type ${searchBy.Value}`}
      itemRender={itemRender}
    />
  );
};
