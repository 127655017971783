import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SettingLocalLawsManageStore {
  private _isEditing: boolean = false;
  private _isLoading: boolean = false;
  private _isLoadingButtonGenerateKey: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEditing(): boolean {
    return toJS(this._isEditing);
  }
  setIsEditing = (isEditing: boolean) => {
    runInAction(() => {
      this._isEditing = isEditing;
    });
  };

  get isLoadingButtonGenerateKey(): boolean {
    return toJS(this._isLoadingButtonGenerateKey);
  }
  setIsLoadingButtonGenerateKey = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingButtonGenerateKey = isLoading;
    });
  };

  get isLoading(): boolean {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
}

const settingLocalLawsManageStoreContext = createContext(
  new SettingLocalLawsManageStore()
);
export const useLocalLawsSettingManageStore = () => {
  return useContext(settingLocalLawsManageStoreContext);
};
