import { HMNoticeView } from "@app/products/hm/model";

export const HMNoticeBookmark = {
  getListViewTitle() {
    return "Health Manager - Notices";
  },
  getRecordTitle() {
    return "Health Manager - Notice";
  },
  getListViewName(viewName: string) {
    return `Health Manager - Notices - ${viewName}`;
  },
  getListViewDetailRecord(selectedRow: HMNoticeView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Health Manager - Notice ${dynamicDisplayName}`;
  },
};
