import {
  DTO_ChargeRun_LevySummary,
  eLevySummaryTypes,
} from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getChargeRunLevySummary = async (
  id: number | undefined,
  levySumaryType: eLevySummaryTypes
): Promise<APIResponse<DTO_ChargeRun_LevySummary | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChargeRun_LevySummary>(
      `api/property/internal/chargerun/${id}/levy-summary?view=${levySumaryType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
