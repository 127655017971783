import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SiteUserForm } from "@common/pages/settings/security/site-users/_id/components/child-screens/general/_index";
import { SiteUserTabDetails } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/_index";
import { SiteUserHistoryTab } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/history/_index";
import { SiteUserSubmitActions } from "@common/pages/settings/security/site-users/_id/model";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedSiteUser = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { onSubmit, isLoading, siteUser, responseLoadError, loadSiteUser } =
    useSiteUserStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getDisplayTextWithDashes([
        siteUser?.Contact_SiteUser?.Contact_ID,
        siteUser?.Contact_SiteUser?.DisplayName,
      ]),
      LinkUrl: managePageUrl,
      LinkText: `Settings - Security - Site Users - ${
        siteUser?.Contact_SiteUser?.Contact_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Contact,
      Record_ID: siteUser?.Contact_SiteUser?.Contact_ID ?? 0,
    },
  ];
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Site User"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSiteUser(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={siteUser?.Contact_SiteUser?.DisplayName} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={SiteUserSubmitActions.Save}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {siteUser && <SiteUserForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <SiteUserTabDetails />,
                    },
                    {
                      title: "History",
                      component: <SiteUserHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
