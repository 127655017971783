import { ManageAsset } from "@app/products/ams/assets/[id]/child-screen/general/component/_index";
import { initNewAsset } from "@app/products/ams/assets/[id]/child-screen/general/config";
import { useAssetManagerStore } from "@app/products/ams/assets/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const AssetManagerForm = observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { asset, createAsset, editAsset, loadAsset } = useAssetManagerStore();

  const handleSubmit = (event: any) => {
    if (event) {
      if (isNew) {
        createAsset(event, isNew);
      } else {
        editAsset(event).then(() => {
          loadAsset(id);
        });
      }
    }
  };
  return (
    <div className="cc-form">
      <Form
        initialValues={isNew ? initNewAsset : asset}
        key={JSON.stringify(isNew ? initNewAsset : asset)}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <ManageAsset formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
