import {
  DTO_Certificate_LOVs,
  DTO_Certificate_Summary,
} from "@app/products/property/certificates/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetInitialCertificate =
  | [APIResponse<DTO_Certificate_LOVs>, APIResponse<DTO_Certificate_Summary>]
  | APIResponse<DTO_Certificate_LOVs>
  | APIResponse<DTO_Certificate_Summary>
  | undefined;

export const getCertificateSummaryById = async (
  certificateId: number
): Promise<IGetInitialCertificate> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `/api/property/int/certificate/lovs`
      ),
      CoreAPIService.getClient().get<DTO_Certificate_Summary>(
        `/api/property/int/certificate/${certificateId}/summary`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
