import {
  IAssessmentGroup,
  IAssessmentQuestion,
  responseCode,
} from "@app/products/ams/model/store";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { useObserver } from "mobx-react-lite";
import React from "react";

interface IProps {
  questionCfg: IAssessmentQuestion;
  parentGroup: IAssessmentGroup;
  isDragging: boolean;
}

interface radioButtonDataItem {
  label: string;
  value: responseCode;
}

const InspectionQuestionDataEntry: React.FC<IProps> = ({
  questionCfg,
  parentGroup,
  isDragging,
}) => {
  const data: radioButtonDataItem[] = [];
  questionCfg.allowedResponses.forEach((r) => {
    data.push({ label: r, value: r });
  });

  return useObserver(() => {
    const firstQuestionNumber = parentGroup.sortedQuestions[0].questionNo;

    return (
      <div style={{ opacity: isDragging ? 0.4 : 1, clear: "both" }}>
        {questionCfg.questionHeading ? (
          <div
            className={
              questionCfg.questionNo === firstQuestionNumber
                ? "row"
                : "row mt-2"
            }
          >
            <div className="col-1"></div>
            <div className="col-auto">
              <h6>
                <span>{questionCfg.questionHeading}</span>
              </h6>
            </div>
          </div>
        ) : null}

        <div className="row mb-2">
          <div className="col-1">{questionCfg.questionNo}</div>
          <div className="col-auto">{questionCfg.questionDetail}</div>
        </div>

        <div className="row mb-2">
          <div className="col-1" />
          <div className="col-auto">
            <RadioGroup data={data} layout={"horizontal"} />
          </div>
        </div>

        {questionCfg.showComment ? (
          <div className="row mb-2">
            <div className="col-1" />
            <div className="col-7">
              <span>
                <CCTextArea className="cc-form-control" rows={4} />
              </span>
            </div>
          </div>
        ) : null}

        {questionCfg.showYesNoOption ? (
          <div className="row">
            <div className="col-1" />
            <label className="label col-auto pt-1">Corrected On site?</label>
            <div className="col-auto">
              <CCSwitch />
            </div>
          </div>
        ) : null}
      </div>
    );
  });
};

export default InspectionQuestionDataEntry;
