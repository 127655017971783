import {
  IListDialog,
  TypesActionsWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ViewConfiguration, WorkflowTypes } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { colSecondaryWorkflowReadOnly } from "@app/products/property/actions/components/detail/components/secondary-workflow/config";
import { APIResponseError } from "@common/apis/model";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useEffectOnce } from "react-use";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import Loading from "@components/loading/Loading";

interface ISecondaryWorkflowsProps {
  workflowDraftId: number;
  typeList?: TypesActionsWorkflow;
  isReadOnly?: boolean;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}

const nameOf = nameOfFactory<VO_Workflow_Draft>();
export const SecondaryWorkflows = observer(
  ({
    workflowDraftId,
    typeList,
    isReadOnly = true,
    isFromActionList = true,
    isIncompleteMode = false,
  }: ISecondaryWorkflowsProps) => {
    const { setListDialog, resetData } = usePropertyWorkflowStore();
    const [secondaryWorkflowCols, setSecondaryWorkflowCols] = useState<
      IColumnFields[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const loadViewConfig = async () => {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const newColSecondaryWorkflowReadOnly = colSecondaryWorkflowReadOnly.map(
        (column: IColumnFields) => {
          if (column.field === nameOf("Workflow")) {
            return {
              ...column,
              handleOnClick: (event: any) => {
                let listDialog: IListDialog[] = [
                  {
                    type: event.Workflow_Type_Enum as WorkflowTypes,
                    data: event,
                    typeList: typeList,
                    props: {
                      isReadOnly: isReadOnly,
                      isFromActionList: isFromActionList,
                      isIncompleteMode: isIncompleteMode,
                    },
                  },
                ];
                setListDialog(listDialog);
              },
            };
          }
          return column;
        }
      );
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.OData_SecondaryWorkflows,
        newColSecondaryWorkflowReadOnly
      );
      if (Array.isArray(response)) {
        setSecondaryWorkflowCols(response);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadViewConfig();
      return () => resetData();
    });

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfig();
          }}
        />
      );

    return (
      <CCGrid
        dataUrl={`/odata/property/internal/workflowdraftregister/GetSecondaryWorkflows(primaryWorkflowId = ${workflowDraftId})?$count=true&`}
        columnFields={secondaryWorkflowCols}
        primaryField={nameOf("Workflow_Draft_Id")}
      />
    );
  }
);
