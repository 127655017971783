import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colCSLLicencesByType = [
  {
    field: "LicenceNumber",
    title: "Licence Number",
    locked: true,
    linkTo: (dataItem: any) => `${CSL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "Applicant",
    title: "Licensee",
  },
  {
    field: "Category",
    title: "Licence Category",
  },
  {
    field: "FormattedAddress",
    title: "Address",
  },
  {
    field: "Suburb",
    title: "Suburb",
  },
  {
    field: "DateExpires",
    title: "Expires",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "PestType_Display",
    title: "Pesticides Permitted",
  },
  {
    field: "BusinessType_Display",
    title: "Type of Work",
  },
];
