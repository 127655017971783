import { DeleteButton } from "@app/core/delete/buttons/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import {
  CRMS_SYSTEM_ADMIN_LOOKUP_TABLES_ROUTE,
  CRMS_SYSTEM_ADMIN_LOOKUP_TABLES_URL,
} from "@app/products/crms/system-admin/constant";
import { NewLookupTableButton } from "@app/products/crms/system-admin/lookup-tables/components/action-bar/buttons/new-lookup-table/_index";
import { CRMSSystemAdminLookupTablesDetailsTab } from "@app/products/crms/system-admin/lookup-tables/components/reference-sidebar/details/_index";
import { CRMSSystemAdminLookupTableHistoryTab } from "@app/products/crms/system-admin/lookup-tables/components/reference-sidebar/history/_index";
import { colLookupTables } from "@app/products/crms/system-admin/lookup-tables/config";
import { CRMSSystemAdminLookupTablesBookmark } from "@app/products/crms/system-admin/lookup-tables/util";
import { LookupTables } from "@app/products/crms/system-admin/model";
import { systemAdminRoute } from "@app/products/crms/system-admin/route";
import { CRMSSystemAdminBookmark } from "@app/products/crms/system-admin/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<LookupTables>();
const gridId = getUUID();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_LookupTable,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <NewLookupTableButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <DeleteButton
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        recordType={RECORDTYPE.CUSTOMERSERVICE_LookupTable}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_SYSTEM_ADMIN_LOOKUP_TABLES_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_LookupTable}
        detail={CRMSSystemAdminBookmark.getBookmarkDetail}
        displayName={CRMSSystemAdminBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSSystemAdminLookupTablesBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CRMSSystemAdminLookupTablesBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <CRMSSystemAdminLookupTablesDetailsTab />,
      },
      {
        title: "History",
        component: <CRMSSystemAdminLookupTableHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={colLookupTables}
        primaryField={nameOf("ID")}
        dataUrl={CRMS_SYSTEM_ADMIN_LOOKUP_TABLES_URL}
        state={{
          group: [{ field: nameOf("LookupTableType_Name"), dir: "asc" }],
          sort: [{ field: nameOf("Description"), dir: "asc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
