import { getAssociatedContacts } from "@app/products/property/contacts-central-names/[id]/components/child-screens/associated-contacts/api";
import { colAssociatedContacts } from "@app/products/property/contacts-central-names/[id]/components/child-screens/associated-contacts/config";
import { DTO_Name_Address } from "@app/products/property/contacts-central-names/[id]/components/child-screens/associated-contacts/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
const nameOfNameAddress = nameOfFactory<DTO_Name_Address>();
export const AssociatedContacts = observer(() => {
  const { contactId } = useContactsCentralNamesStore();
  const [isIncludePastNames, setIsIncludePastNames] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [associatedContacts, setAssociatedContacts] = useState<
    DTO_Name_Address[]
  >([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const handleOnChangeIncludePastNames = (event: CheckboxChangeEvent) => {
    setIsIncludePastNames(event.value);
  };
  useEffect(() => {
    if (!contactId) return;
    loadAssociatedContacts(contactId, isIncludePastNames);
  }, [contactId, isIncludePastNames]);

  const loadAssociatedContacts = async (
    contactId: number | string,
    isIncludePastNames: boolean
  ) => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getAssociatedContacts(contactId, isIncludePastNames);
    if (isSuccessResponse(response)) {
      setAssociatedContacts(response.data?.AssociatedContacts ?? []);
    } else {
      const errorResponse = {
        status: response.status,
        error: response.error,
      };
      setResponseLoadError(errorResponse);
    }
    setIsLoading(false);
  };
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (!contactId) return;
          loadAssociatedContacts(contactId, isIncludePastNames);
        }}
      />
    );
  return (
    <>
      <div className="cc-grid-control-right">
        <Checkbox
          title="Include previous contacts"
          label="Include previous contacts"
          checked={isIncludePastNames}
          onChange={handleOnChangeIncludePastNames}
        />
      </div>
      <CCGrid
        className="cc-transactions-grid"
        data={associatedContacts ?? []}
        columnFields={colAssociatedContacts}
        isLoading={isLoading}
        primaryField={nameOfNameAddress("Contact_Name_Address_Id")}
      />
    </>
  );
});
