import { HMPremisesInspectionScheduleView } from "@app/products/hm/insp-schedule/model";
import { ICalendarData } from "@components/cc-calendar/model";
import { setHours } from "date-fns";

//TODO enhance later just fix temporary
export const processHMInspScheduleCalendarData = (
  rawData: HMPremisesInspectionScheduleView[]
): ICalendarData[] => {
  return rawData.map((data: HMPremisesInspectionScheduleView) => {
    const newData: ICalendarData = {
      id: data?.ID,
      title: data?.CalendarSubject ?? "",
      start: setHours(new Date(data?.NextInspection as Date), 9),
      end: setHours(new Date(data?.NextInspection as Date), 17),
    };
    return newData;
  });
};
