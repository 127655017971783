import { loadEnforcementContacts } from "@app/products/building/registers/enforcements/[id]/components/reference-sidebar/contacts/api";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const EnforcementContactsTab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ICommonContactItemRender[] | undefined>();
  useEffectOnce(() => {
    setIsLoading(true);
    loadEnforcementContacts().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  return <ContactsSidebar data={data || []} />;
};
