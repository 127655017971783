import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { eAnimalsPoundRegister } from "@app/products/animals/system-admin/settings/_id/main/constant";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();

  const eNumberFormat = ECorporateSettingsField.Animals_PoundNumberFormat;

  const prefixSuffixData = [
    getConfigDataField(ECorporateSettingsField.Animals_PoundNumberPrefix),
    getConfigDataField(ECorporateSettingsField.Animals_PoundNumberSuffix),
  ];

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <ComboBoxGlobalSettings
          data={getConfigDataField(eNumberFormat)}
          isEditing={isEditing}
          onChange={(e: ComboBoxChangeEvent) => {
            onChange(`${eNumberFormat}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eNumberFormat}`),
            getConfigDataField(eNumberFormat)?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />

        {prefixSuffixData.map((fieldValue: SettingField | undefined) => (
          <InputGlobalSettings data={fieldValue} isEditing={isEditing} />
        ))}

        <NumericTextboxGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.Animals_PoundNumberLength
          )}
          isEditing={isEditing}
          min={0}
        />

        <NumericTextboxGlobalSettings
          data={getConfigDataField(eAnimalsPoundRegister)}
          isEditing={isEditing}
          min={0}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
