import { getViewPreRenewalAnimalsDetails } from "@app/products/animals/renewals/components/button/batch-renew/api";
import {
  PreRenewalAnimalsDetails,
  Renewal_Method,
} from "@app/products/animals/renewals/components/button/batch-renew/model";
import { generateColPreRenewalAnimalsDetails } from "@app/products/animals/renewals/components/dialog/animals-details-list/config";
import { AnimalsQuickUpdateDialog } from "@app/products/animals/renewals/components/dialog/quick-update-registration/_index";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAnimalsDetailListDialogProps {
  onClose: () => void;
  onSubmit: (
    value: PreRenewalAnimalsDetails[],
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  isLoading?: boolean;
  animalRegisterIDs: number[];
  renewalMethod: Renewal_Method;
}

const nameOf = nameOfFactory<PreRenewalAnimalsDetails>();
export const AnimalsDetailListDialog = ({
  onClose,
  onSubmit,
  isLoading,
  animalRegisterIDs,
  renewalMethod = Renewal_Method.IssueFeesUponRenewalNotice,
}: IAnimalsDetailListDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [initialData, setInitialData] = useState<PreRenewalAnimalsDetails[]>(
    []
  );
  const [submitData, setSubmitData] = useState<PreRenewalAnimalsDetails[]>([]);
  const [isLoadingGrid, setIsLoadingGrid] = useState<boolean>(false);
  const [isShowQuickUpdateDialog, setIsShowQuichUpdateDialog] =
    useState<number>(0);
  const generateColPreRenewalConfig =
    generateColPreRenewalAnimalsDetails(renewalMethod);

  const setupColumnFields = useMemo(
    () =>
      generateColPreRenewalConfig.map((col: IColumnFields) => {
        if (col.field === nameOf("RegistrationNumber")) {
          col.handleOnClick = async (dataItem: PreRenewalAnimalsDetails) => {
            setIsShowQuichUpdateDialog(dataItem.ID);
          };
        }
        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnSubmit = () => {
    onSubmit(submitData as PreRenewalAnimalsDetails[], notificationRef);
  };

  const handleOnSubmitQuickUpdate = async () => {
    setIsShowQuichUpdateDialog(0);
    await loadInitialData();
  };

  const loadInitialData = async () => {
    let errorResponse = undefined;
    setIsLoadingGrid(true);
    const response = await getViewPreRenewalAnimalsDetails(animalRegisterIDs);
    setIsLoadingGrid(false);
    if (isSuccessResponse(response) && response?.data) {
      const preRenewalAnimalsDetails: PreRenewalAnimalsDetails[] =
        response?.data ?? [];
      setInitialData(preRenewalAnimalsDetails);
      setSubmitData(preRenewalAnimalsDetails);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error ?? "Load pre-renewal animals details failed.",
      };
    }
    setResponseLoadError(errorResponse);
  };

  useEffectOnce(() => {
    loadInitialData();
  });

  return (
    <>
      <Form
        initialValues={initialData}
        key={JSON.stringify(initialData)}
        render={(formRenderProps: FormRenderProps) => {
          const handleDataChangeGrid = (dataRow: any, fieldChange: string) => {
            const rowId = dataRow?.ID;
            let newData = [...submitData];
            // update value NewTagNo
            newData = newData.map((item: PreRenewalAnimalsDetails) => {
              if (item.ID === rowId && fieldChange === nameOf("NewTagNo")) {
                return {
                  ...item,
                  NewTagNo: dataRow[nameOf("NewTagNo")] ?? null,
                };
              }
              return item;
            });
            setSubmitData(newData);
          };

          return (
            <CCDialog
              onClose={onClose}
              maxWidth="55%"
              height="auto"
              titleHeader="Animals"
              bodyElement={
                <div className="cc-form cc-calculate-grid-dialog">
                  <FormElement className="cc-field-group cc-grid-dialog-content">
                    {isLoadingGrid ? (
                      <Loading isLoading={isLoadingGrid} />
                    ) : responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={async () => {
                          await loadInitialData();
                        }}
                      />
                    ) : (
                      <>
                        <CCLocalNotification ref={notificationRef} />
                        <CCGrid
                          data={initialData}
                          columnFields={setupColumnFields}
                          primaryField={nameOf("ID")}
                          isLoading={isLoadingGrid}
                          editableMode="cell"
                          onDataRowChange={(dataRow: any, fieldChange: any) => {
                            handleDataChangeGrid(dataRow, fieldChange);
                          }}
                        />
                      </>
                    )}
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={
                      isLoading || isLoadingGrid || initialData.length === 0
                    }
                    onClick={handleOnSubmit}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Batch Renew
                  </Button>
                </div>
              }
            />
          );
        }}
      />
      {isShowQuickUpdateDialog !== 0 && (
        <AnimalsQuickUpdateDialog
          registrationId={isShowQuickUpdateDialog}
          onClose={() => setIsShowQuichUpdateDialog(0)}
          onSubmit={handleOnSubmitQuickUpdate}
        />
      )}
    </>
  );
};
