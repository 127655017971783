import { mockParkingPermitContactTab } from "@app/products/parking-permits/mock";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import React from "react";

export const ParkingPermitsContactTab = () => {
  return (
    <ContactsSidebar
      data={mockParkingPermitContactTab as ICommonContactItemRender[]}
    />
  );
};
