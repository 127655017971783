import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

interface IDropDownEditCellProps extends ICustomEditCell {
  dropdownList: any[];
  textField: string;
  dataItemKey: string;
}

export const DropDownEditCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  dropdownList,
  textField,
  dataItemKey,
}: IDropDownEditCellProps) => {
  return (
    <DropDownList
      textField={textField}
      dataItemKey={dataItemKey}
      data={dropdownList}
      value={getDropdownValue(value, dropdownList, textField)}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value?.[textField],
          });
        }
      }}
    />
  );
};
