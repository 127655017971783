import { colAssociationsSessions } from "@app/products/property/components/associations/components/sessions/config";
import { DTO_AssociatedItem_Sessions } from "@app/products/property/components/associations/components/sessions/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsSessionsProps {
  associationsSessionsInfo: DTO_AssociatedItem_Sessions[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Sessions>();

export const AssociationsSessions = ({
  associationsSessionsInfo,
}: IAssociationsSessionsProps) => {
  return (
    <CCGrid
      data={associationsSessionsInfo || []}
      columnFields={colAssociationsSessions}
      primaryField={nameOf("Id")}
    />
  );
};
