import { ICCRoute } from "@common/constants/ICCRoute";

export const securityTemplatessRoute: ICCRoute = {
  path: "security-templates",
  name: "Security Templates",
  component: require("../_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("../_id/_index").default,
    },
  ],
};
