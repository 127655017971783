import { history } from "@/AppRoutes";
import { getCSLById, postCSL, putCSL } from "@app/products/csl/[id]/api";
import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import {
  CSLRecordsState,
  CSLRegisterForm,
  CSLRegisterRequest,
} from "@app/products/csl/[id]/model";
import { convertCSLResponseToForm } from "@app/products/csl/[id]/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
class CslStore {
  private _csl?: CSLRegisterForm = undefined;
  private _cslRecords?: CSLRecordsState = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get csl() {
    return toJS(this._csl);
  }
  setCsl = (csl?: CSLRegisterForm) => {
    runInAction(() => {
      this._csl = csl;
    });
  };

  get cslRecords() {
    return toJS(this._cslRecords);
  }
  setCslRecords = (cslRecords?: CSLRecordsState) => {
    runInAction(() => {
      this._cslRecords = cslRecords;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get cslId() {
    return toJS(this.csl?.Licence_ID);
  }

  //Action

  resetStore = () => {
    runInAction(() => {
      this._csl = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadCsl = async (cslId: string, recordType: number) => {
    this.setIsLoading(true);
    const response = await getCSLById(cslId, recordType);
    let errorResponse = undefined;
    if (Array.isArray(response)) {
      const [csl, cslRecords] = response;
      const valueCsl: CSLRegisterForm | undefined = convertCSLResponseToForm(
        csl.data
      );
      const valueCslRecords: CSLRecordsState | undefined = cslRecords.data;
      this.setCsl(valueCsl);
      this.setCslRecords(valueCslRecords);
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  createCSL = async (csl: CSLRegisterRequest) => {
    this.setIsLoading(true);
    const response = await postCSL(csl);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "The CSL was created successfully.",
        type: "success",
        onClose: () => {
          history.push(`${CSL_ROUTE}/${response.data.Licence_ID ?? 2928380}`);
        },
      });
    } else {
      appNotificationStore.pushNotification({
        title: response?.error ?? "The CSL could not be created.",
        type: "error",
        autoClose: false,
      });
    }

    this.setIsLoading(false);
  };

  updateCSL = async (csl: CSLRegisterRequest) => {
    this.setIsLoading(true);
    const response = await putCSL(csl);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "The CSL was updated successfully.",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        title: response?.error ?? "The CSL could not be updated.",
        type: "error",
        autoClose: false,
      });
    }
    this.setIsLoading(false);
  };
}

const cslStoreContext = createContext(new CslStore());
export const useCslStore = () => {
  return useContext(cslStoreContext);
};
