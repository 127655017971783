import { CoreAPIService } from "@/common/apis/coreAPIService";
import { PPREnquiryIdentityPacket } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponse } from "@common/apis/model";

export const putRejectResponse = async (
  id: number,
  comments: string = ""
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/${id}/rejectresponse`,
      JSON.stringify(comments)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
