import { SimplePropertyDetailFormElement } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/_index";
import {
  checkEmptySimpleAddressForm,
  checkModifySimpleAddressForm,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/util";
import { Address_BuildAddress } from "@app/products/town-planning/ppr/[id]/model";
import {
  Address,
  AddressClassification,
} from "@app/products/waste-water/[id]/model";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
  getDisplayStreetName,
} from "@common/utils/formatting";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty, isEqual } from "lodash";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface IApplicationDescriptionProps {
  initialData?: any;
  onClose: () => void;
  onSubmit: (value: any) => void;
  isLoading: boolean;
  notification?: IAppNotificationItemAddProps;
}
export const SimplePropertyDetailDialog = ({
  onClose,
  onSubmit,
  isLoading,
  notification,
  initialData,
}: IApplicationDescriptionProps) => {
  const [originalFormData, setOriginalFormData] = useState<Address>();
  const handleOnSubmit = (value: any) => {
    if (!value.Address) {
      onClose();
      return;
    }
    if (checkEmptySimpleAddressForm(value.Address)) {
      value.Address.VMAS_Verified = undefined;
    }
    if (value.Address.VMAS_Verified) {
      if (!isEqual(originalFormData, value.Address)) {
        value.Address.VMAS_Verified = false;
      }
    } else {
      if (checkModifySimpleAddressForm(value.Address)) {
        value.Address.VMAS_Verified = false;
      }
    }
    delete value._option;
    const buildAddress: Address_BuildAddress = { ...value };
    buildAddress.Address.AddressClassification_ENUM =
      AddressClassification.Property;
    buildAddress.bAddressChanged = true;
    buildAddress.Address.UnitNo =
      (!isEmpty(
        getDisplayFloorNumber(buildAddress?.Address?.PropertyAssessment)
      )
        ? `${getDisplayFloorNumber(buildAddress?.Address?.PropertyAssessment)} `
        : "") +
      getDisplayBuildingUnitNumber(buildAddress?.Address?.PropertyAssessment);
    buildAddress.Address.StreetNo = getDisplayHouseNumber(
      buildAddress?.Address?.PropertyAssessment
    );
    buildAddress.Address.StreetName = getDisplayStreetName(
      buildAddress?.Address?.PropertyAssessment
    );
    onSubmit(buildAddress as Address_BuildAddress);
  };

  useEffectOnce(() => {
    setOriginalFormData(initialData?.Address);
  });

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialData}
      render={(formRenderProps: FormRenderProps) => {
        const { valid, onSubmit: onFormSubmit, modified } = formRenderProps;

        return (
          <CCDialog
            maxWidth="60%"
            height="auto"
            maxHeight="90%"
            titleHeader="Property Details"
            onClose={onClose}
            bodyElement={
              <div className="cc-form">
                <SimplePropertyDetailFormElement
                  formRenderProps={formRenderProps}
                  setOriginalFormData={setOriginalFormData}
                  notification={notification}
                />
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={onFormSubmit}
                  type="submit"
                  disabled={
                    !valid ||
                    !modified ||
                    isLoading ||
                    formRenderProps.valueGetter("IsSearchLoading")
                  }
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
