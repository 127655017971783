import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { getHomepageLovs } from "@common/pages/settings/homepage/homepage-shortcuts/api";
import {
  getHomepageViewById,
  postSaveHomepageViews,
} from "@common/pages/settings/homepage/homepage-views/[id]/api";
import { HomePageView } from "@common/pages/settings/homepage/homepage-views/model";
import { ProductListing } from "@common/pages/settings/homepage/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { SyntheticEvent, createContext, useContext } from "react";

configure({ enforceActions: "always" });
class HomePageViewsStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _homepageViews?: HomePageView = undefined;
  private _homepageLovs?: ProductListing[] = undefined;
  private _onSubmit?: (event: SyntheticEvent<any>) => void = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get homepageLovs() {
    return this._homepageLovs;
  }
  setHomepageLovs = (homepageLovs?: ProductListing[]) => {
    runInAction(() => {
      this._homepageLovs = homepageLovs;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get homepageViewsId() {
    return this._homepageViews?.View_Id;
  }

  get homepageViews() {
    return this._homepageViews;
  }
  setHomepageViews = (homepageViews?: HomePageView) => {
    runInAction(() => {
      this._homepageViews = homepageViews;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  reloadHomepageViews = async () => {
    if (this.homepageViewsId) {
      await this.loadHomepageViews(this.homepageViewsId);
    }
  };

  loadHomepageViews = async (homepageId: number) => {
    this.setIsLoading(true);
    const [response, responseLovs] = await Promise.all([
      getHomepageViewById(homepageId),
      getHomepageLovs(),
    ]);
    this.setIsLoading(false);

    if (
      isSuccessResponse(response) &&
      response?.data &&
      isSuccessResponse(responseLovs) &&
      responseLovs?.data
    ) {
      this.setHomepageViews(response.data);
      this.setHomepageLovs(responseLovs.data);

      // Push notification
      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }
    } else {
      this.setResponseLoadError({
        status: response.status ?? responseLovs.status,
        error: response.error ?? responseLovs.error,
      });
    }
  };

  submitAction = async (
    homepageView: HomePageView,
    action: ActionSubmitActions
  ) => {
    if (action === ActionSubmitActions.Save) {
      this.setIsLoading(true);
      const response = await postSaveHomepageViews(homepageView);
      this.setIsLoading(false);

      if (isSuccessResponse(response) && response?.data) {
        this.reloadHomepageViews();
        this.setNotification({
          type: "success",
          title: "Record successfully saved.",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Record could not be saved",
          type: "error",
          description: response.error ?? response.data?.Errors,
        });
      }
    }
  };
}

const homePageViewsStoreContext = createContext(new HomePageViewsStore());
export const useHomePageViewsStore = () => {
  return useContext(homePageViewsStoreContext);
};
