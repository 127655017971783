import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { PagerProps } from "@progress/kendo-react-data-tools";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export interface ICustomPagerPagesizeAllProps extends PagerProps {
  totalSelectedRows: number;
  setGridDataState: (state: State, isFromProp?: boolean) => void;
  setIsUseCustomPagerPagesizeAll: (value: boolean) => void;
  isShowLabelItemPerPage?: boolean;
}

/**
 * This custom pager is used only when the selected pagesize is "All"
 */
export const CustomPagerPagesizeAll = (props: ICustomPagerPagesizeAllProps) => {
  const {
    total,
    pageSizes,
    totalSelectedRows,
    setGridDataState,
    setIsUseCustomPagerPagesizeAll,
    isShowLabelItemPerPage = true,
  } = props;

  const [pageNumber, setPageNumber] = useState<string>("1");

  return (
    <div className="k-pager k-pager-md">
      <div className="k-pager-numbers-wrap">
        <Button
          className="k-button-flat k-button-flat-base k-icon-button k-pager-nav k-pager-first k-disabled"
          iconClass="k-icon k-i-caret-alt-to-left k-button-icon"
        />
        <Button
          className="k-button-flat k-button-flat-base k-icon-button k-pager-nav k-disabled"
          iconClass="k-icon k-i-caret-alt-left k-button-icon"
        />
        <span className="k-pager-input k-label">
          page
          <span className="k-textbox k-input k-input-md k-rounded-md k-input-solid">
            <Input
              defaultValue="1"
              value={pageNumber}
              onChange={(event: InputChangeEvent) => {
                setPageNumber(event.value);
              }}
              onBlur={() => {
                setPageNumber("1");
              }}
            />
          </span>
          of 1
        </span>
        <Button
          className="k-button-flat k-button-flat-base k-icon-button k-pager-nav k-disabled"
          iconClass="k-icon k-i-caret-alt-right k-button-icon"
        />
        <Button
          className="k-button k-button-md k-button-flat k-button-flat-base k-icon-button k-pager-nav k-pager-last k-disabled"
          iconClass="k-icon k-i-caret-alt-to-right k-button-icon"
        />
      </div>
      <span className="k-pager-sizes">
        <DropDownList
          name={"GridPagesizes"}
          data={pageSizes}
          defaultValue="All"
          onChange={(event: DropDownListChangeEvent) => {
            setGridDataState({ take: event.value });
            setIsUseCustomPagerPagesizeAll(false);
          }}
        />
        {isShowLabelItemPerPage ? "items/page" : ""} ㅤ {totalSelectedRows} item
        {totalSelectedRows > 1 ? "s" : ""} selected
      </span>
      <div className="k-pager-info">
        1 - {total} of {total} items
      </div>
    </div>
  );
};
