import { getDisplayTextWithDashes } from "@common/utils/common";

export const getCRSBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `CRS - ${dynamicDisplayName}`;
};

export const getCRSBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow?.ChildDisplayName,
    "Main Application",
  ]);
};
