import { ICouncilContact } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfContact = nameOfFactory<ICouncilContact>();

export const colCouncilDetail: IColumnFields[] = [
  {
    field: nameOfContact("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfContact("Position"),
    title: "Position",
  },
  {
    field: nameOfContact("OrgStructure"),
    title: "Organisation Structure",
  },
  {
    field: nameOfContact("Email"),
    title: "Email",
  },
  {
    field: nameOfContact("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOfContact("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOfContact("Mobile"),
    title: "Mobile",
  },
];
