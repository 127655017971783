import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { getLovPIC } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/api";
import { DTO_PIC_LOVs } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import {
  DTO_Workflow_PIC_Reactivate,
  RequestPICReactivateObj,
} from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postReactivatePIC = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_PIC_Reactivate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_PIC_Reactivate>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/picreactivate/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataNewPIC =
  | [APIResponse<DTO_PIC_LOVs>, APIResponse<DTO_Workflow_PIC_Reactivate>]
  | APIResponse<DTO_PIC_LOVs | DTO_Workflow_PIC_Reactivate>;

export const getInitialDataReactivatePIC = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined,
  picId?: number,
  statusId: number = 0
): Promise<IGetInitialDataNewPIC> => {
  try {
    return await Promise.all([
      getLovPIC(workflowType),
      !isNil(workflowDraftId)
        ? getWorkflowReactivatePIC(workflowDraftId)
        : getNewWorkflowReactivatePIC({
            PIC_Id: picId ?? 0,
            StatusId: statusId,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowReactivatePIC = async (
  object: RequestPICReactivateObj
): Promise<APIResponse<DTO_Workflow_PIC_Reactivate>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/picreactivate/new`,
      object
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowReactivatePIC = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_PIC_Reactivate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/picreactivate/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
