import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { checkIsPPR } from "@app/core/further-info/[id]/util";
import { putSendForApproval } from "@app/core/further-info/components/buttons/work-flow/send-for-approval/api";
import { useSendForApprovalStore } from "@app/core/further-info/components/buttons/work-flow/send-for-approval/store";
import { SendForApprovalDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/_index";
import { ISendForApproval } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

export const SendForApprovalButton = observer(() => {
  const { furtherInfo, onSubmit, furtherInfoId, reLoadFurtherInfo } =
    useFurtherInfoStore();
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();
  const { isShowDialog, setIsShowDialog } = useSendForApprovalStore();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const history = useHistory();

  const isVisible = useMemo(() => {
    if (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoRequestedDraft ||
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoRejected
    )
      return true;
    if (
      (furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionDeclined ||
        furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionRequested) &&
      checkIsPPR(furtherInfo)
    )
      return true;
    return false;
  }, [furtherInfo]);

  const handleSubmit = async ({ SelectedDocumentIDs }: ISendForApproval) => {
    if (!furtherInfoId) return;
    setIsLoading(true);
    const response = await putSendForApproval(
      furtherInfoId,
      SelectedDocumentIDs
    );
    customLogger("Core Further info, putSendForApproval").info(response?.data);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        setIsShowDialog(false);
        clearErrorNotification();
        setNotifications([]);
        if (history.action !== "POP") {
          history.goBack();
        } else {
          reLoadFurtherInfo().then(() => {
            pushNotification({
              type: "success",
              title: "Record saved successfully",
            });
          });
        }
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Record could not be saved",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: response.statusText ?? "Record could not be saved",
          type: "error",
        },
      ]);
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title={"Send for approval"}
        onClick={onSubmit}
        name={FurtherInfoSubmitActions.SendForApproval}
      />
      {isShowDialog && (
        <SendForApprovalDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          isSubmitLoading={isLoading}
          notifications={notifications}
          parentData={{
            Id: furtherInfoId,
            recordType: RECORDTYPE.CORE_FurtherInformationRequest,
          }}
        />
      )}
    </>
  ) : null;
});
