import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsFinancePaidFees: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.ReferenceNumber,
    title: "Permit Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: ParkingPermitsFieldMapping.PermitHolder, title: "Name", width: 150 },
  {
    field: ParkingPermitsFieldMapping.CreatedDate,
    title: "Recorded",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  { field: ParkingPermitsFieldMapping.FeeType, title: "Type", width: 150 },
  {
    field: ParkingPermitsFieldMapping.AmountIssued,
    title: "Issued",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.AmountPaid,
    title: "Paid",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.ReceiptNumber,
    title: "Receipt Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.ReceiptDate,
    title: "Receipt Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.ReceiptedBy,
    title: "Receipted By",
    width: 150,
  },
  { field: ParkingPermitsFieldMapping.Method, title: "Method", width: 150 },
  {
    field: ParkingPermitsFieldMapping.CostCode,
    title: "Cost Code",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.SecurePay_Reference,
    title: "Payment Reference",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
