import { CoreAPIService } from "@/common/apis/coreAPIService";
import { ExtendTargetDate } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/extend-target-date/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse } from "@common/apis/model";

export const putPSARExtendTargetDate = async (
  id: number,
  reqBody: ExtendTargetDate
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `api/tp/ppr/internal/application/psar/${id}/extendtargetdate`,
      reqBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
