import { getECertificateRequestById } from "@app/products/property/certificates/e-certificate-requests/[id]/components/reference-sidebar/detail/api";
import { IECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ECertificateRequestDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [eCertificateRequestInfo, setECertificateRequestInfo] = useState<
    IECertificateRequest | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const eCertificateRequestId: number | string =
    lastSelectedRow?.ID ?? params.id;
  useEffect(() => {
    if (eCertificateRequestId) {
      setIsLoading(true);
      getECertificateRequestById(eCertificateRequestId).then((data) => {
        setECertificateRequestInfo(data);
        setIsLoading(false);
      });
    }
  }, [eCertificateRequestId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!eCertificateRequestInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <>
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Agent Reference: "}
            value={eCertificateRequestInfo.AgentReference}
          />
          <ProductReferenceRow value={eCertificateRequestInfo.MessageType} />
          <ProductReferenceRow
            title={"Source: "}
            value={eCertificateRequestInfo.Source}
          />
          <ProductReferenceRow
            title={"Status: "}
            value={eCertificateRequestInfo.OrderStatus}
          />

          <ProductReferenceRow
            title={"Name: "}
            value={eCertificateRequestInfo.CertificateName}
          />
          <ProductReferenceRow
            title={"Number: "}
            value={eCertificateRequestInfo.CertificateNumber}
          />
        </ProductReferenceBlock>
      </>
    </ProductReferenceBody>
  );
});
