import { Application_Decision_Number } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/model";
import { GetFriendlyName } from "@app/products/town-planning/ppr/components/action-bar/dialogs/approve-decision/util";
import { Application_Decision } from "@app/products/town-planning/ppr/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const listPSARApprovalDecisions: IKeyValuePacket[] = [
  {
    Key: Application_Decision_Number.Gazetted,
    Value: GetFriendlyName(Application_Decision.Gazetted),
  },
  {
    Key: Application_Decision_Number.PSAAbandoned,
    Value: GetFriendlyName(Application_Decision.PSAAbandoned),
  },
  {
    Key: Application_Decision_Number.Withdrawn,
    Value: GetFriendlyName(Application_Decision.Withdrawn),
  },
];
