import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colValuationDetail } from "./config";
import "./_index.scss";

const nameOf = nameOfFactory<VO_Supplementary_Levy_Calculation>();
export const ValuationsDetails = ({ dataItem }: GridDetailRowProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfig, setColsConfig] = useState<IColumnFields[]>([]);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.SupplementaryRates_Charges,
      colValuationDetail
    );
    if (Array.isArray(response)) {
      setColsConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <div className="cc-valuations-detail">
      <CCLabel title="Levy calculations" />
      <CCGrid
        dataUrl={`odata/property/internal/supplementaryratesassessmentlevychargesregister/${dataItem?.Supplementary_Assessment_Id}?$count=true&`}
        columnFields={colsConfig}
        primaryField={nameOf("Supplementary_Levy_Calc_Id")}
      />
    </div>
  );
};
