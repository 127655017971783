import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import {
  putSendResponse,
  putSendResponseApplicant,
} from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/send-response/api";
import { EnquiriesSendResponseDialog } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/not-for-vr/_index";
import { IEnquiriesSendResponse } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/not-for-vr/model";
import {
  PPREnquirySubmitActions,
  RegisterStatus,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { UITriggerRegister } from "@common/constants/uiTrigger";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const EnquiriesSendResponseButton = observer(() => {
  const { onSubmit, pprEnquiry, pprEnquiryId, setPPREnquiryWithLoading } =
    usePPREnquiryStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowSendResponseDialog, setIsShowSendResponseDialog] =
    useState<boolean>(false);
  const [isShowCommunicationDialog, setIsShowCommunicationDialog] =
    useState<boolean>(false);
  const [notifications, setNotifications] =
    useState<IAppNotificationItemAddProps>();

  useAddUniqueEventEmitter([
    {
      eventType: UITriggerRegister.SendResponse.toString(),
      listener: () => {
        setIsShowSendResponseDialog(true);
      },
    },
  ]);

  const handleSendResponse = async (data: IEnquiriesSendResponse) => {
    if (!pprEnquiryId || !pprEnquiry) return;
    setIsLoading(true);

    const response = await putSendResponse(pprEnquiryId, data);
    setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearErrorNotification();
      setPPREnquiryWithLoading(response.data?.Register);
      setIsShowSendResponseDialog(false);
      setIsShowCommunicationDialog(true);
    } else {
      setNotifications({
        autoClose: false,
        title: "Response send failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
      setIsLoading(false);
    }
  };

  const handleCommunicationSubmit = async (data: ICommunication) => {
    if (!pprEnquiryId) return;
    const response = await postSendCommunication(
      data,
      RECORDTYPE.CORE_Register
    );
    if (isSuccessResponse(response)) {
      const resp = await putSendResponseApplicant(pprEnquiryId);
      setIsShowCommunicationDialog(false);
      appNotificationStore.clearNotifications();
      if (isSuccessIdentityPacket(resp)) {
        setPPREnquiryWithLoading(resp.data?.Register);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Response applicant sent successfully",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          type: "error",
          title: "Sent response applicant failed",
          description: resp.data?.Errors ?? resp.statusText,
        });
      }
    } else {
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        autoClose: false,
        type: "error",
        title: "The communication could not be sent.",
        description: response?.error ?? response.statusText,
      });
    }
  };

  const isVisible = useMemo(() => {
    return RegisterStatus.Approved === pprEnquiry?.Status_ENUM;
  }, [pprEnquiry]);

  return isVisible ? (
    <>
      <CCNavButton
        title={"Send response"}
        onClick={onSubmit}
        name={PPREnquirySubmitActions.SendResponse}
      />
      {isShowSendResponseDialog && (
        <EnquiriesSendResponseDialog
          onClosePopup={() => setIsShowSendResponseDialog(false)}
          onSubmit={handleSendResponse}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          notifications={notifications}
        />
      )}

      {isShowCommunicationDialog && (
        <CommunicationDialog
          recipientTypes={recipientTypesCommunicationDialog(
            RECORDTYPE.CORE_Register
          )}
          onClose={() => setIsShowCommunicationDialog(false)}
          onSubmit={handleCommunicationSubmit}
          recordType={RECORDTYPE.CORE_Register}
          productType={PRODUCT_TYPE_NUMBER.Core}
          documentStatus={DOCUMENTSTATUS.Final}
          recordID={pprEnquiryId}
        />
      )}
    </>
  ) : null;
});
