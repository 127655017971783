import { PROPERTY_PIC_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/constant";
import { DTO_AssociatedItem_PICChargeRun } from "@app/products/property/components/associations/components/pic-charge-run/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_PICChargeRun>();
export const colListPICChargeRuns: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
    locked: true,
    linkTo: (dataItem: DTO_AssociatedItem_PICChargeRun) =>
      `${PROPERTY_PIC_CHARGE_RUNS_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("ChargeRun_Type"),
    title: "Type",
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("Instalment_Plan"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("Charge_Date"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChargeRun_Name"),
    title: "Name",
  },
  {
    field: nameOf("ChargeRun_Code"),
    title: "Code",
  },
  {
    field: nameOf("ChargeRun_Description"),
    title: "Description",
  },
  {
    field: nameOf("Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
