import { PrivateRoute } from "@/AppRoutes";
import { ProductRouterSwitch } from "@/ProductRouterSwitch";
import { FormRoute } from "@common/constants/menu";
import { ManageContact } from "@common/pages/contacts/manage-contact/_index";
import React from "react";
import { match } from "react-router-dom";

interface IProps {
  match: match;
}

export function OldContactRouter(props: IProps) {
  return (
    <ProductRouterSwitch>
      <PrivateRoute
        strict
        path={[props.match.url + `${FormRoute.FORM_ROUTE}/:id(new|[0-9]+)`]} // '/manageregistration/:id',
        component={ManageContact}
      />
    </ProductRouterSwitch>
  );
}
