import { mockOrderedData } from "@app/products/property/deferred-duty/notice-runs/components/dialogs/new-deferred-duty-notice-run/mock";
import { INewDeferredDutyNoticeRunFormProps } from "@app/products/property/deferred-duty/notice-runs/components/dialogs/new-deferred-duty-notice-run/model";
import { handleValidateForm } from "@app/products/property/deferred-duty/notice-runs/components/dialogs/new-deferred-duty-notice-run/util";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface IDeferredDutyNoticeRunDialogProps {
  onClose: () => void;
  onOkButton?: () => void;
  noticeRunInfo?: INewDeferredDutyNoticeRunFormProps | undefined;
}

export const DeferredDutyNoticeRunDialog = ({
  onClose,
  onOkButton,
  noticeRunInfo,
}: IDeferredDutyNoticeRunDialogProps) => {
  const nameOf = nameOfFactory<INewDeferredDutyNoticeRunFormProps>();

  const formValidated = useMemo(() => {
    if (!noticeRunInfo) return {};
    return { ...noticeRunInfo };
  }, [noticeRunInfo]);

  return (
    <div className="cc-new-deferred-duty-notice-run-dialog">
      <Form
        initialValues={formValidated}
        validator={handleValidateForm}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="30%"
              titleHeader={"New Deferred Duty Notice Run"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  <div className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Notice run name</label>
                        <Field
                          name={nameOf("NoticeRunName")}
                          component={CCInput}
                          placeholder="Notice run name"
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Issue date</label>
                        <Field
                          name={nameOf("IssueDate")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          min={new Date()}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          How are the notices to be ordered?
                        </label>
                        <Field
                          name={nameOf("Ordered")}
                          component={CCSearchComboBox}
                          data={mockOrderedData}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          value={getDropdownValue(
                            formRenderProps.valueGetter("Ordered"),
                            mockOrderedData,
                            nameOfLov("Code")
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            formRenderProps.onChange("Ordered", {
                              value: event.target.value?.Code ?? null,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Period start
                          <CCTooltip
                            type="validator"
                            content={
                              "Period Start must be less than Period End"
                            }
                          />
                        </label>
                        <Field
                          name={nameOf("PeriodStart")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          max={
                            new Date(formRenderProps.valueGetter("PeriodEnd"))
                          }
                        />
                      </div>
                      <div className="cc-field ">
                        <label className="cc-label">
                          Period end
                          <CCTooltip
                            type="validator"
                            content={
                              "Period End must be greater than Period Start"
                            }
                          />
                        </label>
                        <Field
                          name={nameOf("PeriodEnd")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          min={
                            new Date(formRenderProps.valueGetter("PeriodStart"))
                          }
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Selection criteria</label>
                        <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Minimum amount due
                                <CCTooltip content="If $0.00 then all balances will be included." />
                              </label>
                              <Field
                                name={nameOf("MinimumAmountDue")}
                                defaultValue={0.01}
                                component={CCCurrencyInput}
                                min={0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onOkButton}
                    disabled={!formRenderProps.valid}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        )}
      />
    </div>
  );
};
