import { PPRFormElement } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/_index";
import {
  Application,
  PPRSubmitActions,
} from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const PPRForm = observer(() => {
  //#region STORE ========/
  const { pushNotification } = useCCAppNotificationStore();
  const { ppr, savePPR, setOnSubmit } = usePPRStore();
  //#endregion STORE =====/

  const isNew = useIsNew();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget.name as PPRSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in PPRSubmitActions)) return;

    if (!isValid) return;
    if (
      !isModified &&
      [PPRSubmitActions.Save, PPRSubmitActions.New].includes(action) &&
      ![PPRSubmitActions.ChangePickOfficer].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const pprInfo = values as Application;
    savePPR(pprInfo, action, isNew);
  };

  useEffect(() => {
    if (submitRef.current) {
      setOnSubmit(submitRef.current);
    }
    //eslint-disable-next-line
  }, [submitRef.current, ppr]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={ppr}
        key={JSON.stringify(ppr)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<PPRFormElement formRenderProps={formRenderProps} />}
            />
          );
        }}
      />
    </div>
  );
});
