export enum KEYWORD_TYPE {
  SystemInitialise = 0,
  Core_Language = 1,
  Core_Country = 2,
  Core_State = 3,
  Core_Region = 4,
  Core_Area = 5,
  Core_LGA = 6,
  Core_AddressBoxType = 7,
  Core_InspectionComplianceSimple = 8,
  Core_SendFromAddress = 9,
  Core_Salutation = 10,
  Core_JobTitle = 11,
  Core_ContactAddressType = 12,
  Core_ContactRole = 13,
  Core_NoiseWord = 14,
  Core_NoticeRisk = 15,
  Core_ActionMeetingLocation = 19,
  Core_InspectionReason = 20,
  Core_InspectionCompliance = 21,
  Core_InspectionCompetence = 22,
  Core_InspectionAction = 23,
  Core_InspectionOffence = 24,
  Core_AssessmentCompliance = 25,
  Core_AuditCompliance = 26,
  Core_ActionOutcome = 27,
  Core_Municipality = 28,
  Core_PressRegion = 29,
  Core_SampleCategory = 30,
  Core_SampleLocation = 31,
  Core_SampleCompliance = 32,
  Core_ContactCategory = 33,
  Core_DocumentCategory = 34,
  Core_ActionType = 60,
  Core_DeadlineType = 61,
  Core_NoticeShortDescription = 69,
  Core_RegisterNonComplianceSubStatus = 70,
  Core_HearingLocation = 71,
  Core_Magistrate = 72,
  Core_Verdict = 73,
  Core_ProsecutionType = 74,
  Core_VCATReviewOutcome = 75,
  Core_ComplaintSource = 76,
  Core_Finding = 77,
  Core_Outcome = 78,
  Core_Charge = 79,
  Core_Bank = 80,
  Core_AppealGrounds = 81,
  Core_AppealPosition = 82,
  Core_AppealHearingType = 83,
  Core_LandUse = 84,
  Core_AppealDecision = 85,
  Core_RegisterPlanningEnquiryType = 86,
  Core_RegisterCouncilConsentOutcome = 87,
  Core_RegisterDemolitionOutcome = 88,
  Core_RegisterNonComplianceCategory = 89,
  Core_RegisterPlansToComplyOutcome = 90,
  Core_RegisterSecondaryConsentOutcome = 91,
  Core_RegisterESItem = 92,
  Core_InsuranceCompany = 93,
  Core_ReferralTrigger = 94,
  Core_ReferralInfoAttached = 95,
  Core_ReferralPolicy = 96,
  Core_SampleSurveyOutcome = 97,
  Core_SampleTestApplied = 98,
  Core_SampleLabOutcome = 99,
  Core_Contact_IncorporatedBodyType = 100,
  Core_RegisterCoolingTowerType = 101,
  Core_RegisterSwimmingPoolType = 102,
  Core_RegisterCoolingTowerMaintenancePrograms = 103,
  Core_RegisterCoolingTowerDecontaminationProcedures = 104,
  Core_AppealDuration = 105,
  Core_ComplaintSubStatus = 106,
  Core_RegisterNonComplianceOutcome = 107,
  Core_RegisterBuildingPoolSpaType = 108,
  Core_RegisterEnforcementType = 109,
  Core_OtherAgenciesNotified = 110,
  Core_RegisterCouncilConsentAssessmentSub = 112,
  Core_Judgement = 113,
  Core_RegisterCouncilConsentRegulationCategory = 114,
  Core_Address_StreetType = 150,
  Core_SampleReason = 151,
  Core_SampleGroup = 152,
  Core_SampleStatus = 153,
  Core_SampleTestStatus = 154,
  Core_FIDays = 155,
  Core_BusinessType = 156,
  Core_WorkProposed = 157,
  Core_LocationDescription = 158,
  Core_Invoice = 159,
  Core_IncidentStatus = 160,
  Core_Occupation = 161,
  Core_Course = 162,
  Core_ContactVIPType = 163,
  Core_HomePage_ResultsFilter = 164,
  Core_RegisterBuildingEnforcementType = 165,
  Core_Qualification = 166,
  Core_AuthorisedActs = 167,
  Core_Priority = 168,
  Core_NoticeCategory = 169,
  Core_InspectionArea = 170,
  Core_OccupationSector = 171,
  Core_InspectionRisk = 172,
  Core_RegisterPlanningEnquiryCategory = 173,
  Core_RegisterPlanningPermitReferralEnquiryOutcome = 174,
  Core_BusinessTier = 175,
  Core_VoltsUnit = 176,
  Core_AmpsUnit = 177,
  Core_Origin = 178,
  Core_InterviewOutcome = 179,
  Core_ConditionTheme = 180,
  Core_RestrictionTheme = 181,
  Core_NoticeType = 182,
  Core_Legislation = 183,
  Core_Undertaking_Status = 184,
  Core_ActionTypeTag = 185,
  Core_EmploymentType = 186,
  Core_Investigation_Source = 187,
  Core_Investigation_RelatedEntities_Notifed = 188,
  Core_InformationSharing_Direction = 189,
  Core_InformationSharing_Type = 190,
  Core_InformationSharing_ReferralDecisionMadeBy = 191,
  Core_InformationSharing_ReasonGroundsForReferral = 192,
  Core_InformationSharing_VCATDetermination = 193,
  Core_InformationSharing_VCATOrder = 194,
  Core_InformationSharing_FromTo = 195,
  Core_Action_ImmediateActionReason = 196,
  Core_Action_ShowCauseProposedAction = 197,
  Core_Action_ShowCauseProcessResult = 198,
  Core_NCItemResult_Undertaking = 199,
  Core_Investigation_Action_Outcome = 201,
  Core_QualificationType = 202,
  Core_Undertaking_Source = 203,
  Core_Undertaking_ClosureReason = 204,
  Core_Undertaking_RevocationSource = 205,
  Core_Undertaking_DisplaySuppressReason = 206,
  Core_Undertaking_Category = 207,
  Core_InspectionLocation = 208,
  Core_NationalPoliceCheckResult = 209,
  Core_DisabilityType = 210,
  Core_DisabilitySubType = 211,
  Core_AboriginalStatus = 212,
  Core_ExternalDocumentType = 213,
  Core_GenderPronoun = 214,
  Core_PNFCompliance = 215,
  Core_NoticeStatus = 216,
  Inspection_Assessment_Comments = 217,
  Core_AssessmentActionType = 218,
  Core_AssessmentActionOutcome = 219,
  Core_AssessmentActionRequestedBy = 220,
  Core_Education_RequiredFor = 221,
  Core_Allegation_TypeofConduct = 222,
  Core_Allegation_Classification = 223,
  Core_Allegation_Findings = 224,
  Core_Education_DeliveryMethod = 225,
  Core_Investigation_report_Provide_To = 226,
  Core_Investigation_WhyWithholdInvestigationReport = 227,
  Core_WebLinkCategory = 228,
  Core_EducationProvider_Status = 229,
  Core_IBRASubRegion = 230,
  Core_SLMRegion = 231,
  Core_Condition_Category = 232,
  Core_Condition_Source = 233,
  Core_Condition_SourceRemoval = 234,
  Core_Condition_DisplaySuppressReason = 235,
  Core_LicenceType = 236,
  Core_Gender = 237,
  Core_RegisterStreetProtectionStatus = 238,
  Core_ProsecutionStatus = 239,
  Core_NoticeRectificationRequirements = 241,
  Core_CommunityGroup = 244,
  Core_Dialog_PickActionType = 3092,
  CUSTOMERSERVICE_BULLETINBOARD_CATEGORY = 1300,
  CUSTOMERSERVICE_LocationRegisterType = 1305,
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_Species = 1306,
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_Height = 1307,
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_DBH = 1308,
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_ConditionRating = 1309,
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_HazardRating = 1310,
  CUSTOMERSERVICE_CD_Animal_Attack_Severity = 1311,
  CUSTOMERSERVICE_Incident_Category = 1312,
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleMake = 1313,
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleModel = 1314,
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleColour = 1315,
  CUSTOMERSERVICE_CommunityEngagement_SearchKey = 1316,
  CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToDW = 1317,
  CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToServiceUser = 1318,
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUserDisabilityType = 1319,
  CUSTOMERSERVICE_CD_DisabilityWorker_NecessaryActions = 1322,
  CUSTOMERSERVICE_CD_DisabilityWorker_Priority = 1323,
  CUSTOMERSERVICE_CD_DisabilityWorker_ReferralFromOtherEntity = 1324,
  CUSTOMERSERVICE_CD_DisabilityWorker_ConductAttentionDetails = 1325,
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUsersHarmed = 1326,
  CUSTOMERSERVICE_CD_DisabilityWorker_ConcernRaisedToAnotherAgency = 1327,
  CUSTOMERSERVICE_CD_DisabilityWorker_HumanRightsIssues = 1328,
  CUSTOMERSERVICE_CD_DisabilityWorker_PractiseIssues = 1329,
  CUSTOMERSERVICE_CD_DisabilityWorker_HeardAboutUs = 1330,
  CUSTOMERSERVICE_CD_DisabilityWorker_Outcome = 1331,
  CUSTOMERSERVICE_CD_DisabilityWorker_OutcomeActions = 1332,
  CUSTOMERSERVICE_CD_DisabilityWorker_AddressBy = 1333,
  CUSTOMERSERVICE_CD_DisabilityWorker_ContactTime = 1334,
  CUSTOMERSERVICE_CD_DisabilityWorker_NDISStatus = 1335,
  CUSTOMERSERVICE_IssueClassification = 1336,
  CUSTOMERSERVICE_IssueOutcome = 1337,
  CUSTOMERSERVICE_ActionsTaken = 1338,
  CUSTOMERSERVICE_CD_DisabilityWorker_Place = 1339,
  CUSTOMERSERVICE_CD_DisabilityWorker_ConcernDetails = 1340,
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceType = 1341,
  CUSTOMERSERVICE_CD_DisabilityWorker_SatisfactionLevel = 1342,
  CUSTOMERSERVICE_CD_DisabilityWorker_ReferredToEntity = 1343,
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUserAffectedCount = 1345,
  CUSTOMERSERVICE_CD_DisabilityWorker_SomeoneAtRisk = 1346,
  CUSTOMERSERVICE_CD_DisabilityWorker_Agencies = 1347,
  CUSTOMERSERVICE_CD_DisabilityWorker_SubOutcome = 1348,
  CUSTOMERSERVICE_CD_DisabilityWorker_Confidentiality = 1349,
  CUSTOMERSERVICE_CD_DisabilityWorker_ConductContravened = 1350,
  CUSTOMERSERVICE_IssueSubClassification = 1351,
  CUSTOMERSERVICE_IssueSource = 1352,
  CUSTOMERSERVICE_TypeOfWork = 1353,
  CUSTOMERSERVICE_AdditionalEmergencyServices = 1354,
  CUSTOMERSERVICE_CD_DisabilityWorker_ReasonToWitholdNoticeOfReceipt = 1355,
  CUSTOMERSERVICE_CD_DisabilityWorker_Outcome_PrimaryResolutionType = 1356,
  SRU_RegistrationCategory = 1402,
  SRU_LinkedConsortium = 1403,
  SRU_IssueSource = 1404,
  SRU_IssueType = 1405,
  SRU_UnregisteredActivity = 1408,
  SRU_IssueCategory = 1409,
  SRU_ComplianceType = 1410,
  SRU_RevokeRegistrationReason = 1411,
  SRU_ServiceType = 1412,
  SRU_RegisteredProvider = 1413,
  SRU_PlannedMonitoring = 1414,
  SRU_UndertakingStatus = 1415,
  SRU_ResidentProfile = 1416,
  SRU_StatementStatus = 1417,
  SRU_SAVVICluster = 1418,
  SRU_FireRiskArea = 1419,
  SRU_Agency = 1420,
  SRU_SeizureType = 1421,
  SRU_DispositionType = 1422,
  SRU_IncidentType = 1423,
  SRU_TransactionType = 1424,
  SRU_FireStatus = 1425,
  SRU_AppointmentReason = 1426,
  SRU_LapseRegistrationReason = 1429,
  SRU_RegistrationStatementType = 1430,
  SRU_RegistrationUndertakingLegislation = 1431,
  SRU_EnforcementPlan_RiskClassification = 1432,
  SRU_EnforcementPlan_AgreedMonitoringResponse = 1433,
  SRU_EnforcementPlan_AttitudeToTheInsepction = 1434,
  SRU_EnforcementPlan_AttitudeToTheNonCompliances = 1435,
  SRU_EnforcementPlan_DurationOfTheNonCompliance = 1436,
  SRU_EnforcementPlan_AttitudeToCorrectingNonCompliance = 1437,
  SRU_EnforcementPlan_ImpactOnResident = 1438,
  SRU_EnforcementPlan_MedicationIssue = 1439,
  SRU_EnforcementPlan_NumberOfResidentsAffected = 1440,
  SRU_EnforcementPlan_TypeOfResponseAdministrative = 1441,
  SRU_EnforcementPlan_TypeOfResponseStatutoryEnforcement = 1442,
  SRU_EnforcementPlan_TypeOfResponseStrategicAction = 1443,
  SRU_ReportableIncident_AttendedBy = 1445,
  SRU_ReportableIncident_ClientOrCaseManagedBy = 1446,
  SRU_ReportableIncident_NotifiedBy = 1447,
  SRU_ReportableIncident_TransferOfResident = 1448,
  SRU_ReportableIncident_ReactiveMonitoringInspection = 1449,
  SRU_ReportableIncident_Legislative = 1450,
  SRU_StateGovernmentAssistedType = 1451,
  SRU_IssueSubSource = 1452,
  SRU_ReportableIncident_Position_NotifyingPerson = 1453,
  SRU_ComplianceNotice_RiskClassification = 1454,
  SRU_Registration_ClosureType = 1455,
  SRU_Organisation_ClosureType = 1456,
  DWCMS_Insurance_CurrencyCert = 1501,
  DWCMS_NonStandardNameType = 1502,
  DWCMS_DisciplinaryActionType = 1503,
  DWCMS_Jurisdiction = 1504,
  DWCMS_LanguageSkillAssessment = 1506,
  DWCMS_ImpairmentAssessmentOutcome = 1507,
  DWCMS_ScopeOfRecencyOfPracticeAssessment = 1508,
  DWCMS_CriminalHistoryRiskAssessment = 1509,
  DWCMS_CriminalHistoryAssessmentOutcome = 1510,
  DWCMS_RegulatoryIssuesOtherJurisdictionAssessmentOutcome = 1511,
  DWCMS_WhoMadeDetermination = 1513,
  DWCMS_QualificationsOutcome = 1514,
  DWCMS_ExperienceOutcome = 1515,
  DWCMS_ProfessionalCompetenceOutcome = 1516,
  DWCMS_SubmissionBoardType = 1521,
  DWCMS_FinalDetermination = 1522,
  DWCMS_ReasonRegistrationRefused = 1523,
  DWCMS_ReasonRegistrationDifferentDivisionType = 1524,
  DWCMS_ProfessionalMembership = 1525,
  DWCMS_SourceOfProhibitionOrder = 1526,
  DWCMS_CurrentProhibition = 1527,
  DWCMS_GroundsOfTheOrder = 1528,
  DWCMS_ProhibitedFrom = 1529,
  DWCMS_ReasonSuppressingDisplay = 1530,
  DWCMS_Duration = 1531,
  DWCMS_ProhibitionOrderType = 1532,
  DWCMS_SourceType = 1533,
  DWCMS_NonVDWCDisciplinaryActionType = 1534,
  DWCMS_RegulatoryBody = 1535,
  DWCMS_DADisciplinaryActionKind = 1536,
  DWCMS_DAOrderType = 1537,
  DWCMS_InsuranceType = 1538,
  DWCMS_ApplicationPathway = 1539,
  DWCMS_TrainingAssessmentOutcome = 1540,
  DWCMS_ImpairmentType = 1541,
  DWCMS_PriorDisciplinaryActionNotification = 1542,
  DWCMS_ProfessionalRegistrationJurisdiction = 1543,
  DWCMS_NameOfRegistrationBody = 1544,
  DWCMS_PrimaryPlaceOfPracticeSource = 1545,
  DWCMS_ServiceProvider_Site = 1546,
  DWCMS_SuspensionCancellationAssessmentOutcome = 1547,
  DWCMS_ProhibitionBanningAssessmentOutcome = 1548,
  DWCMS_BoardRequests = 1549,
  DWCMS_ApplicationOrigin = 1550,
  DWCMS_Caution_Source = 1551,
  DWCMS_Caution_SourceRecord = 1552,
  DWCMS_Reprimand_Source = 1553,
  DWCMS_ApplicationStatus_Rollback = 1554,
  DWCMS_Registration_Indicator = 1555,
  HealthManager_FSVerified = 1940,
  HealthManager_HealthArea = 1946,
  HealthManager_ClosePremises_Reasons = 1947,
  HealthManager_FoodSafetyProgram_Type = 1948,
  HealthManager_FoodSafetyProgram_Templates = 1949,
  HealthManager_Liquor_type = 1950,
  HealthManager_PrivateWater_Type = 1951,
  HealthManager_Condition = 1952,
  HealthManager_NC_RefrigerationType = 1962,
  HealthManager_NC_FSP_Records = 1963,
  HealthManager_Tobacco_ActionType = 1965,
  HealthManager_Tobacco_Offences = 1966,
  HealthManager_Tobacco_InspectionType = 1967,
  HealthManager_TradewasteActivity = 1968,
  HealthManager_TradewastePretreatment = 1969,
  HealthManager_TradewasteUnits = 1970,
  HealthManager_Foods = 1971,
  HealthManager_DW_RMP = 1972,
  HealthManager_DW_Standard_RMP = 1973,
  HealthManager_DW_Source = 1974,
  HealthManager_DW_Treatment = 1975,
  HealthManager_DW_EColi_Monitor = 1976,
  HealthManager_DW_Chem_Monitor = 1977,
  HealthManager_WW_Treatment = 1978,
  HMOnline_PremisesPriorityDescription = 1979,
  HMOnline_PremisesPriorityDescriptionReporting = 1980,
  HealthManager_ScopeOfService = 1981,
  HealthManager_SpecificInfrastructure = 1982,
  HealthManager_TypeOfAnaesthesia = 1983,
  HealthManager_SubStatus = 1984,
  Wastewater_WaterAuthority = 4000,
  Wastewater_RiskLevel = 4001,
  Wastewater_Status = 4002,
  Wastewater_EffluentDisposal = 4003,
  Wastewater_ApplicationType = 4004,
  Wastewater_ProductCategory = 4005,
  Wastewater_ProductSubCategory = 4006,
  Wastewater_ProductMaterial = 4007,
  Wastewater_AuthorisationType = 4008,
  Wastewater_Classification = 4009,
  Wastewater_SystemTypeClassification = 4010,
  Wastewater_PermitConditions = 4011,
  Wastewater_ConstructionType = 4012,
  NCRisk_ImportanceRating = 5001,
  NCRisk_CapacityToEscalate = 5002,
  NCRisk_ScaleOfBreach = 5003,
  NCRisk_UrgencyRating = 5004,
  NCRisk_LevelOfEnforcement = 5005,
  NCItemLocation = 5013,
  NC_Reason_Not_Notified = 5014,
  CRS_RelationToChild = 6000,
  CRS_SubsidyCardType = 6001,
  CRS_PortalSupportDocumentType = 6002,
  CRS_SurveyOptions_1 = 6003,
  CRS_SurveyOptions_2 = 6004,
  CRS_SurveyOptions_3 = 6005,
  CRS_SurveyOptions_4 = 6006,
  CRS_SurveyOptions_5 = 6007,
  CRS_SurveyOptions_6 = 6008,
  CRS_SurveyOptions_7 = 6009,
  CRS_SurveyOptions_8 = 6010,
  CRS_SurveyOptions_9 = 6011,
  TP_ApplicationRating = 7001,
  TP_ApplicationBuilding_UseOfBuilding = 7002,
  TP_ApplicationBuilding_BCA = 7003,
  TP_ApplicationBuilding_ExtWallMaterial = 7005,
  TP_ApplicationBuilding_RoofCladdingMaterial = 7006,
  TP_ApplicationBuilding_FrameMaterial = 7007,
  TP_ApplicationBuilding_FloorMaterial = 7008,
  TP_ApplicationBuilding_BAL = 7009,
  TP_ApplicationBuilding_LandType = 7010,
  TP_ApplicationBuilding_LandOwnership = 7011,
  TP_AssessmentPlanner_Zone = 7012,
  TP_AssessmentPlanner_Overlay = 7013,
  TP_AssessmentPlanner_PermitTrigger = 7014,
  TP_AssessmentPlanner_StrategicObjective = 7017,
  TP_ApplicationDescription = 7018,
  TP_CertifierRegistrationNumber = 7019,
  TP_ESDDwellings = 7020,
  TP_ESDNonResFloorSpace = 7021,
  TP_ESDGrossFloorArea = 7022,
  TP_CertificationCellRef = 7023,
  TP_ApplicationBuilding_CouncilConcentRegulation = 7024,
  TP_Application_ProposedFinalOutcome = 7025,
  TP_ExtensionOfTimeQuestions = 7026,
  TP_Recommendations = 7027,
  TP_ApplicationBuilding_EnqPropertyInfoRequestCategory = 7028,
  Core_ReasonForPermit = 7029,
  Building_CouncilConsent_DocumentChecklist = 7030,
  Building_PrivateSurveyor_DocumentChecklist = 7031,
  Building_PrivateSurveyor_DocumentChecklistCategory = 7032,
  Planning_EnquiryMethod = 7034,
  TP_ExtensionOfTimeDelegate = 7035,
  TP_ApplicationBuilding_OwnerBuilder = 7036,
  TP_ApplicationBuilding_ReportingAuthority = 7037,
  TP_EnquiryClosureReason = 7038,
  Building_Specific_Work = 7039,
  Building_TypeOf_Construction = 7040,
  Building_BCA_Version = 7041,
  Building_Whole_Part = 7042,
  TP_Assessment_Executive_Summary = 7043,
  Building_Stage_Description = 7044,
  TP_ApplicationBuilding_PoolSpaRegulation = 7045,
  Building_Soil_Classification = 7046,
  TP_ApplicationBuilding_EnergyEfficiencyRating = 7047,
  TP_ApplicationBuilding_PoolSpaBarrierType = 7049,
  TP_ApplicationPNF_ForestCode = 7050,
  TP_ApplicationPNF_OGRFReviewReason = 7051,
  TP_ApplicationPNF_FOpsNotificationType = 7052,
  TP_Application_Condition_Stage = 7053,
  TP_PNF_ReviewReason = 7054,
  TP_PNF_Outcome = 7055,
  TP_PNF_ReasonForClosure = 7056,
  TP_PNF_ReasonForTemination = 7057,
  TP_ApplicationPNF_Tenure = 7058,
  Building_Certificate_Type = 7059,
  TP_PNF_ReasonForApplication = 7060,
  TP_Application_Assessment_WorkingHours = 7061,
  TP_Application_Assessment_CommercialVehiclesAccessFrequency = 7062,
  TP_PNF_LEP_Zone = 7063,
  Building_CouncilConsent_Type = 7064,
  TP_PNF_SLM_Zone = 7065,
  TP_Application_SubStatus = 7066,
  TP_ApplicationType = 7067,
  TP_Precinct = 7068,
  TP_HousingType = 7069,
  DISRES_ReissueReason = 10001,
  DISRES_PermitHolder_Organisation = 10002,
  DISRES_ProofOfResidency = 10003,
  DISRES_ProofOfVehicleRegistration = 10004,
  DISRES_ProofOfMembership = 10005,
  DisRes_VehicleColour = 10006,
  MCH_RelationToChild = 11001,
  LLP_Purpose = 12001,
  LLP_Placement = 12002,
  LLP_Make = 12003,
  LLP_Model = 12004,
  LLP_Area = 12005,
  Animals_Colour = 13001,
  Animals_ProofOfSterilisation = 13002,
  Animals_ProofOfObedience = 13003,
  Animals_DogBreeds = 13004,
  Animals_CatBreeds = 13005,
  Animals_OtherBreeds = 13006,
  Animals_TagColour = 13007,
  Animals_DeactivateReason = 13008,
  Animals_Size = 13009,
  Animals_Condition = 13010,
  Animals_Disposition = 13011,
  Animals_Kennel_DeactivateReason = 13012,
  Animals_MicrochipProofExemption = 13013,
  CSM_Reg19Drugs = 14001,
  CSM_Pests = 14002,
  CSM_PoisonSchedule = 14003,
  CSM_Condition = 14004,
  CSM_Reg25Poison = 14005,
  CSM_Cyanide = 14006,
  CSM_TypeOfPoison = 14007,
  CSM_TypeOfPesticideWork = 14008,
  CSM_TypeOfPesticide = 14009,
  CSM_Activity = 14010,
  CSM_EndorsementSpeciality = 14011,
  CSM_SubstanceForm = 14012,
  CSM_Poisons_ProductType = 14013,
  CSM_Poisons_Region = 14014,
  CSM_Poisons_PestType = 14015,
  CSM_Poisons_Species = 14016,
  CSM_Poisons_DensityOfPest = 14017,
  CSM_Poisons_ControlAlreadyImplemented = 14018,
  CSM_Poisons_RecommendedAction = 14019,
  CSM_Poisons_Product = 14020,
  CSM_Poisons_Product_Measurement = 14021,
  CSM_Poisons_AssessmentType = 14022,
  CSM_Poisons_BaitDeliveryMethod = 14023,
  CSM_Poisons_RiskAssessmentArea = 14024,
  CSM_Poisons_PestAnimalProgram = 14025,
  CSM_Poisons_BaitType = 14026,
  CSM_Poisons_TargetPestSpecies = 14027,
  CSM_Poisons_PesticideType = 14028,
  CSM_Poisons_AnimalType = 14029,
  CSM_Poisons_Risk_Likelihood = 14030,
  CSM_Poisons_Risk_Consequence = 14031,
  CSM_Poisons_Risk_Analysis = 14032,
  CSM_Poisons_BaitingLocation = 14033,
  CSM_Poisons_NumberOfWeeksOfReplacementBaiting = 14034,
  CSM_Poisons_ControlType = 14035,
  CSM_Poisons_NumberOfPest = 14036,
  CSM_Poisons_ObservationType = 14037,
  CSM_Poisons_Pest_Age = 14038,
  CSM_Poisons_Pest_Gender = 14039,
  CSM_Poisons_AccurateObservationPercentage = 14040,
  AMS_AppointmentFormat = 15001,
  EMS_CurrentEventStatus = 15501,
  EMS_EventTypeClassification = 15502,
  EMS_EventTypeLevel = 15503,
  EMS_EventTypeGroup = 15504,
  TrainingManager_SkillLevel = 16001,
  TrainingManager_RenewableMethod = 16002,
  TrainingManager_TrainingVenue = 16003,
  Radiation_TypeOfDealings = 16701,
  Radiation_CompetencyReq = 16702,
  Radiation_UseCategory = 16703,
  Radiation_UseSubCategory = 16704,
  Radiation_EvidenceOfCompetency = 16705,
  Radiation_WorkflowStatus = 16706,
  Radiation_SecurityCheck = 16707,
  Radiation_TypeOfSources = 16708,
  Radiation_AccreditationType = 16709,
  Radiation_AccreditationCategory = 16710,
  Radiation_COfCOptions = 16711,
  Radiation_DeActivationReason = 16712,
  Radiation_Manufacturer = 16713,
  Radiation_PlaceDescription = 16714,
  Radiation_PrincipalFunction = 16715,
  Radiation_Radionuclides = 16716,
  Radiation_ReActivationReason = 16717,
  Radiation_SourceSecurityCategory = 16718,
  Radiation_SourcePhysicalForm = 16719,
  Radiation_SourceSecurityRating = 16720,
  Radiation_ThirdPartyAuditor = 16721,
  Radiation_PermittedRadiationSource = 16722,
  Radiation_PermittedPractice = 16723,
  Radiation_RadionuclideActivityUnit = 16724,
  Radiation_Model = 16725,
  Radiation_ResearchProjectDoseUnit = 16726,
  Radiation_DisposalMethod = 16727,
  Radiation_DisposalFacilitatorLocation = 16728,
  NSPR_DeactivateReason = 17001,
  NSPR_ReactivateReason = 17002,
  SSMS_InstallationAuthority = 18001,
  SSMS_VICROADSOFFICE = 18002,
  SSMS_SpeedSignSize = 18003,
  SSMS_SpeedSignType = 18004,
  SSMS_SpeedSignVarCal = 18005,
  PORTAL_Document_Optional = 27000,
  PORTAL_Document_Mandatory = 27001,
  OOSYS_TP_ApplicationStatus = 28000,
  OOSYS_WorkFlowProcessStatus = 28001,
  OOSYS_RAD_RegisterStatus = 28002,
  OOSYS_DWCMS_ApplicationRegistrationStatus = 28003,
  CEM_Denomination = 30001,
  CEM_Section = 30002,
  CEM_DepthOfGrave = 30003,
  INFRINGEMENTS__VehicleMake = 30101,
  INFRINGEMENTS_VehicleModel = 30102,
  INFRINGEMENTS_VehicleColour = 30103,
  INFRINGEMENTS_VehicleType = 30104,
  INFRINGEMENTS_Weather = 30105,
  INFRINGEMENTS_SignType = 30106,
  INFRINGEMENTS_SignExemption = 30107,
  INFRINGEMENTS_DeliveryMethod = 30108,
  INFRINGEMENTS_TicketFormat = 30109,
  INFRINGEMENTS_SignTimes = 30110,
  INFRINGEMENTS_AdressArea = 30111,
  INFRINGEMENTS_State = 30112,
  FLEET_CurrentEventStatus = 31000,
  FLEET_EventTypeClassification = 31001,
  FLEET_EventTypeLevel = 31002,
  FLEET_EventTypeGroup = 31003,
  FLEET_DriveType = 31004,
  FLEET_LicenceType = 31005,
  FLEET_Location = 31006,
  LandManagement_Enquiry_Type = 32000,
  LandManagement_Compliance_Type = 32001,
  LandManagement_Term = 32002,
  LandManagement_Species = 32003,
}

export enum KEYWORD_TYPE_STR {
  SystemInitialise = "SystemInitialise",
  Core_Language = "Core_Language",
  Core_Country = "Core_Country",
  Core_State = "Core_State",
  Core_Region = "Core_Region",
  Core_Area = "Core_Area",
  Core_LGA = "Core_LGA",
  Core_AddressBoxType = "Core_AddressBoxType",
  Core_InspectionComplianceSimple = "Core_InspectionComplianceSimple",
  Core_SendFromAddress = "Core_SendFromAddress",
  Core_Salutation = "Core_Salutation",
  Core_JobTitle = "Core_JobTitle",
  Core_ContactAddressType = "Core_ContactAddressType",
  Core_ContactRole = "Core_ContactRole",
  Core_NoiseWord = "Core_NoiseWord",
  Core_NoticeRisk = "Core_NoticeRisk",
  Core_ActionMeetingLocation = "Core_ActionMeetingLocation",
  Core_InspectionReason = "Core_InspectionReason",
  Core_InspectionCompliance = "Core_InspectionCompliance",
  Core_InspectionCompetence = "Core_InspectionCompetence",
  Core_InspectionAction = "Core_InspectionAction",
  Core_InspectionOffence = "Core_InspectionOffence",
  Core_AssessmentCompliance = "Core_AssessmentCompliance",
  Core_AuditCompliance = "Core_AuditCompliance",
  Core_ActionOutcome = "Core_ActionOutcome",
  Core_Municipality = "Core_Municipality",
  Core_PressRegion = "Core_PressRegion",
  Core_SampleCategory = "Core_SampleCategory",
  Core_SampleLocation = "Core_SampleLocation",
  Core_SampleCompliance = "Core_SampleCompliance",
  Core_ContactCategory = "Core_ContactCategory",
  Core_DocumentCategory = "Core_DocumentCategory",
  Core_ActionType = "Core_ActionType",
  Core_DeadlineType = "Core_DeadlineType",
  Core_NoticeShortDescription = "Core_NoticeShortDescription",
  Core_RegisterNonComplianceSubStatus = "Core_RegisterNonComplianceSubStatus",
  Core_HearingLocation = "Core_HearingLocation",
  Core_Magistrate = "Core_Magistrate",
  Core_Verdict = "Core_Verdict",
  Core_ProsecutionType = "Core_ProsecutionType",
  Core_VCATReviewOutcome = "Core_VCATReviewOutcome",
  Core_ComplaintSource = "Core_ComplaintSource",
  Core_Finding = "Core_Finding",
  Core_Outcome = "Core_Outcome",
  Core_Charge = "Core_Charge",
  Core_Bank = "Core_Bank",
  Core_AppealGrounds = "Core_AppealGrounds",
  Core_AppealPosition = "Core_AppealPosition",
  Core_AppealHearingType = "Core_AppealHearingType",
  Core_LandUse = "Core_LandUse",
  Core_AppealDecision = "Core_AppealDecision",
  Core_RegisterPlanningEnquiryType = "Core_RegisterPlanningEnquiryType",
  Core_RegisterCouncilConsentOutcome = "Core_RegisterCouncilConsentOutcome",
  Core_RegisterDemolitionOutcome = "Core_RegisterDemolitionOutcome",
  Core_RegisterNonComplianceCategory = "Core_RegisterNonComplianceCategory",
  Core_RegisterPlansToComplyOutcome = "Core_RegisterPlansToComplyOutcome",
  Core_RegisterSecondaryConsentOutcome = "Core_RegisterSecondaryConsentOutcome",
  Core_RegisterESItem = "Core_RegisterESItem",
  Core_InsuranceCompany = "Core_InsuranceCompany",
  Core_ReferralTrigger = "Core_ReferralTrigger",
  Core_ReferralInfoAttached = "Core_ReferralInfoAttached",
  Core_ReferralPolicy = "Core_ReferralPolicy",
  Core_SampleSurveyOutcome = "Core_SampleSurveyOutcome",
  Core_SampleTestApplied = "Core_SampleTestApplied",
  Core_SampleLabOutcome = "Core_SampleLabOutcome",
  Core_Contact_IncorporatedBodyType = "Core_Contact_IncorporatedBodyType",
  Core_RegisterCoolingTowerType = "Core_RegisterCoolingTowerType",
  Core_RegisterSwimmingPoolType = "Core_RegisterSwimmingPoolType",
  Core_RegisterCoolingTowerMaintenancePrograms = "Core_RegisterCoolingTowerMaintenancePrograms",
  Core_RegisterCoolingTowerDecontaminationProcedures = "Core_RegisterCoolingTowerDecontaminationProcedures",
  Core_AppealDuration = "Core_AppealDuration",
  Core_ComplaintSubStatus = "Core_ComplaintSubStatus",
  Core_RegisterNonComplianceOutcome = "Core_RegisterNonComplianceOutcome",
  Core_RegisterBuildingPoolSpaType = "Core_RegisterBuildingPoolSpaType",
  Core_RegisterEnforcementType = "Core_RegisterEnforcementType",
  Core_OtherAgenciesNotified = "Core_OtherAgenciesNotified",
  Core_RegisterCouncilConsentAssessmentSub = "Core_RegisterCouncilConsentAssessmentSub",
  Core_Judgement = "Core_Judgement",
  Core_RegisterCouncilConsentRegulationCategory = "Core_RegisterCouncilConsentRegulationCategory",
  Core_Address_StreetType = "Core_Address_StreetType",
  Core_SampleReason = "Core_SampleReason",
  Core_SampleGroup = "Core_SampleGroup",
  Core_SampleStatus = "Core_SampleStatus",
  Core_SampleTestStatus = "Core_SampleTestStatus",
  Core_FIDays = "Core_FIDays",
  Core_BusinessType = "Core_BusinessType",
  Core_WorkProposed = "Core_WorkProposed",
  Core_LocationDescription = "Core_LocationDescription",
  Core_Invoice = "Core_Invoice",
  Core_IncidentStatus = "Core_IncidentStatus",
  Core_Occupation = "Core_Occupation",
  Core_Course = "Core_Course",
  Core_ContactVIPType = "Core_ContactVIPType",
  Core_HomePage_ResultsFilter = "Core_HomePage_ResultsFilter",
  Core_RegisterBuildingEnforcementType = "Core_RegisterBuildingEnforcementType",
  Core_Qualification = "Core_Qualification",
  Core_AuthorisedActs = "Core_AuthorisedActs",
  Core_Priority = "Core_Priority",
  Core_NoticeCategory = "Core_NoticeCategory",
  Core_InspectionArea = "Core_InspectionArea",
  Core_OccupationSector = "Core_OccupationSector",
  Core_InspectionRisk = "Core_InspectionRisk",
  Core_RegisterPlanningEnquiryCategory = "Core_RegisterPlanningEnquiryCategory",
  Core_RegisterPlanningPermitReferralEnquiryOutcome = "Core_RegisterPlanningPermitReferralEnquiryOutcome",
  Core_BusinessTier = "Core_BusinessTier",
  Core_VoltsUnit = "Core_VoltsUnit",
  Core_AmpsUnit = "Core_AmpsUnit",
  Core_Origin = "Core_Origin",
  Core_InterviewOutcome = "Core_InterviewOutcome",
  Core_ConditionTheme = "Core_ConditionTheme",
  Core_RestrictionTheme = "Core_RestrictionTheme",
  Core_NoticeType = "Core_NoticeType",
  Core_Legislation = "Core_Legislation",
  Core_Undertaking_Status = "Core_Undertaking_Status",
  Core_ActionTypeTag = "Core_ActionTypeTag",
  Core_EmploymentType = "Core_EmploymentType",
  Core_Investigation_Source = "Core_Investigation_Source",
  Core_Investigation_RelatedEntities_Notifed = "Core_Investigation_RelatedEntities_Notifed",
  Core_InformationSharing_Direction = "Core_InformationSharing_Direction",
  Core_InformationSharing_Type = "Core_InformationSharing_Type",
  Core_InformationSharing_ReferralDecisionMadeBy = "Core_InformationSharing_ReferralDecisionMadeBy",
  Core_InformationSharing_ReasonGroundsForReferral = "Core_InformationSharing_ReasonGroundsForReferral",
  Core_InformationSharing_VCATDetermination = "Core_InformationSharing_VCATDetermination",
  Core_InformationSharing_VCATOrder = "Core_InformationSharing_VCATOrder",
  Core_InformationSharing_FromTo = "Core_InformationSharing_FromTo",
  Core_Action_ImmediateActionReason = "Core_Action_ImmediateActionReason",
  Core_Action_ShowCauseProposedAction = "Core_Action_ShowCauseProposedAction",
  Core_Action_ShowCauseProcessResult = "Core_Action_ShowCauseProcessResult",
  Core_NCItemResult_Undertaking = "Core_NCItemResult_Undertaking",
  Core_Investigation_Action_Outcome = "Core_Investigation_Action_Outcome",
  Core_QualificationType = "Core_QualificationType",
  Core_Undertaking_Source = "Core_Undertaking_Source",
  Core_Undertaking_ClosureReason = "Core_Undertaking_ClosureReason",
  Core_Undertaking_RevocationSource = "Core_Undertaking_RevocationSource",
  Core_Undertaking_DisplaySuppressReason = "Core_Undertaking_DisplaySuppressReason",
  Core_Undertaking_Category = "Core_Undertaking_Category",
  Core_InspectionLocation = "Core_InspectionLocation",
  Core_NationalPoliceCheckResult = "Core_NationalPoliceCheckResult",
  Core_DisabilityType = "Core_DisabilityType",
  Core_DisabilitySubType = "Core_DisabilitySubType",
  Core_AboriginalStatus = "Core_AboriginalStatus",
  Core_ExternalDocumentType = "Core_ExternalDocumentType",
  Core_GenderPronoun = "Core_GenderPronoun",
  Core_PNFCompliance = "Core_PNFCompliance",
  Core_NoticeStatus = "Core_NoticeStatus",
  Inspection_Assessment_Comments = "Inspection_Assessment_Comments",
  Core_AssessmentActionType = "Core_AssessmentActionType",
  Core_AssessmentActionOutcome = "Core_AssessmentActionOutcome",
  Core_AssessmentActionRequestedBy = "Core_AssessmentActionRequestedBy",
  Core_Education_RequiredFor = "Core_Education_RequiredFor",
  Core_Allegation_TypeofConduct = "Core_Allegation_TypeofConduct",
  Core_Allegation_Classification = "Core_Allegation_Classification",
  Core_Allegation_Findings = "Core_Allegation_Findings",
  Core_Education_DeliveryMethod = "Core_Education_DeliveryMethod",
  Core_Investigation_report_Provide_To = "Core_Investigation_report_Provide_To",
  Core_Investigation_WhyWithholdInvestigationReport = "Core_Investigation_WhyWithholdInvestigationReport",
  Core_WebLinkCategory = "Core_WebLinkCategory",
  Core_EducationProvider_Status = "Core_EducationProvider_Status",
  Core_IBRASubRegion = "Core_IBRASubRegion",
  Core_SLMRegion = "Core_SLMRegion",
  Core_Condition_Category = "Core_Condition_Category",
  Core_Condition_Source = "Core_Condition_Source",
  Core_Condition_SourceRemoval = "Core_Condition_SourceRemoval",
  Core_Condition_DisplaySuppressReason = "Core_Condition_DisplaySuppressReason",
  Core_LicenceType = "Core_LicenceType",
  Core_Gender = "Core_Gender",
  Core_RegisterStreetProtectionStatus = "Core_RegisterStreetProtectionStatus",
  Core_ProsecutionStatus = "Core_ProsecutionStatus",
  Core_Dialog_PickActionType = "Core_Dialog_PickActionType",
  CUSTOMERSERVICE_BULLETINBOARD_CATEGORY = "CUSTOMERSERVICE_BULLETINBOARD_CATEGORY",
  CUSTOMERSERVICE_LocationRegisterType = "CUSTOMERSERVICE_LocationRegisterType",
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_Species = "CUSTOMERSERVICE_CD_TreeManagement_Inspection_Species",
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_Height = "CUSTOMERSERVICE_CD_TreeManagement_Inspection_Height",
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_DBH = "CUSTOMERSERVICE_CD_TreeManagement_Inspection_DBH",
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_ConditionRating = "CUSTOMERSERVICE_CD_TreeManagement_Inspection_ConditionRating",
  CUSTOMERSERVICE_CD_TreeManagement_Inspection_HazardRating = "CUSTOMERSERVICE_CD_TreeManagement_Inspection_HazardRating",
  CUSTOMERSERVICE_CD_Animal_Attack_Severity = "CUSTOMERSERVICE_CD_Animal_Attack_Severity",
  CUSTOMERSERVICE_Incident_Category = "CUSTOMERSERVICE_Incident_Category",
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleMake = "CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleMake",
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleModel = "CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleModel",
  CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleColour = "CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleColour",
  CUSTOMERSERVICE_CommunityEngagement_SearchKey = "CUSTOMERSERVICE_CommunityEngagement_SearchKey",
  CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToDW = "CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToDW",
  CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToServiceUser = "CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToServiceUser",
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUserDisabilityType = "CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUserDisabilityType",
  CUSTOMERSERVICE_CD_DisabilityWorker_NecessaryActions = "CUSTOMERSERVICE_CD_DisabilityWorker_NecessaryActions",
  CUSTOMERSERVICE_CD_DisabilityWorker_Priority = "CUSTOMERSERVICE_CD_DisabilityWorker_Priority",
  CUSTOMERSERVICE_CD_DisabilityWorker_ReferralFromOtherEntity = "CUSTOMERSERVICE_CD_DisabilityWorker_ReferralFromOtherEntity",
  CUSTOMERSERVICE_CD_DisabilityWorker_ConductAttentionDetails = "CUSTOMERSERVICE_CD_DisabilityWorker_ConductAttentionDetails",
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUsersHarmed = "CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUsersHarmed",
  CUSTOMERSERVICE_CD_DisabilityWorker_ConcernRaisedToAnotherAgency = "CUSTOMERSERVICE_CD_DisabilityWorker_ConcernRaisedToAnotherAgency",
  CUSTOMERSERVICE_CD_DisabilityWorker_HumanRightsIssues = "CUSTOMERSERVICE_CD_DisabilityWorker_HumanRightsIssues",
  CUSTOMERSERVICE_CD_DisabilityWorker_PractiseIssues = "CUSTOMERSERVICE_CD_DisabilityWorker_PractiseIssues",
  CUSTOMERSERVICE_CD_DisabilityWorker_HeardAboutUs = "CUSTOMERSERVICE_CD_DisabilityWorker_HeardAboutUs",
  CUSTOMERSERVICE_CD_DisabilityWorker_Outcome = "CUSTOMERSERVICE_CD_DisabilityWorker_Outcome",
  CUSTOMERSERVICE_CD_DisabilityWorker_OutcomeActions = "CUSTOMERSERVICE_CD_DisabilityWorker_OutcomeActions",
  CUSTOMERSERVICE_CD_DisabilityWorker_AddressBy = "CUSTOMERSERVICE_CD_DisabilityWorker_AddressBy",
  CUSTOMERSERVICE_CD_DisabilityWorker_ContactTime = "CUSTOMERSERVICE_CD_DisabilityWorker_ContactTime",
  CUSTOMERSERVICE_CD_DisabilityWorker_NDISStatus = "CUSTOMERSERVICE_CD_DisabilityWorker_NDISStatus",
  CUSTOMERSERVICE_IssueClassification = "CUSTOMERSERVICE_IssueClassification",
  CUSTOMERSERVICE_IssueOutcome = "CUSTOMERSERVICE_IssueOutcome",
  CUSTOMERSERVICE_ActionsTaken = "CUSTOMERSERVICE_ActionsTaken",
  CUSTOMERSERVICE_CD_DisabilityWorker_Place = "CUSTOMERSERVICE_CD_DisabilityWorker_Place",
  CUSTOMERSERVICE_CD_DisabilityWorker_ConcernDetails = "CUSTOMERSERVICE_CD_DisabilityWorker_ConcernDetails",
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceType = "CUSTOMERSERVICE_CD_DisabilityWorker_ServiceType",
  CUSTOMERSERVICE_CD_DisabilityWorker_SatisfactionLevel = "CUSTOMERSERVICE_CD_DisabilityWorker_SatisfactionLevel",
  CUSTOMERSERVICE_CD_DisabilityWorker_ReferredToEntity = "CUSTOMERSERVICE_CD_DisabilityWorker_ReferredToEntity",
  CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUserAffectedCount = "CUSTOMERSERVICE_CD_DisabilityWorker_ServiceUserAffectedCount",
  CUSTOMERSERVICE_CD_DisabilityWorker_SomeoneAtRisk = "CUSTOMERSERVICE_CD_DisabilityWorker_SomeoneAtRisk",
  CUSTOMERSERVICE_CD_DisabilityWorker_Agencies = "CUSTOMERSERVICE_CD_DisabilityWorker_Agencies",
  CUSTOMERSERVICE_CD_DisabilityWorker_SubOutcome = "CUSTOMERSERVICE_CD_DisabilityWorker_SubOutcome",
  CUSTOMERSERVICE_CD_DisabilityWorker_Confidentiality = "CUSTOMERSERVICE_CD_DisabilityWorker_Confidentiality",
  CUSTOMERSERVICE_CD_DisabilityWorker_ConductContravened = "CUSTOMERSERVICE_CD_DisabilityWorker_ConductContravened",
  CUSTOMERSERVICE_IssueSubClassification = "CUSTOMERSERVICE_IssueSubClassification",
  CUSTOMERSERVICE_IssueSource = "CUSTOMERSERVICE_IssueSource",
  CUSTOMERSERVICE_TypeOfWork = "CUSTOMERSERVICE_TypeOfWork",
  CUSTOMERSERVICE_AdditionalEmergencyServices = "CUSTOMERSERVICE_AdditionalEmergencyServices",
  CUSTOMERSERVICE_CD_DisabilityWorker_ReasonToWitholdNoticeOfReceipt = "CUSTOMERSERVICE_CD_DisabilityWorker_ReasonToWitholdNoticeOfReceipt",
  CUSTOMERSERVICE_CD_DisabilityWorker_Outcome_PrimaryResolutionType = "CUSTOMERSERVICE_CD_DisabilityWorker_Outcome_PrimaryResolutionType",
  SRU_RegistrationCategory = "SRU_RegistrationCategory",
  SRU_LinkedConsortium = "SRU_LinkedConsortium",
  SRU_IssueSource = "SRU_IssueSource",
  SRU_IssueType = "SRU_IssueType",
  SRU_UnregisteredActivity = "SRU_UnregisteredActivity",
  SRU_IssueCategory = "SRU_IssueCategory",
  SRU_ComplianceType = "SRU_ComplianceType",
  SRU_RevokeRegistrationReason = "SRU_RevokeRegistrationReason",
  SRU_ServiceType = "SRU_ServiceType",
  SRU_RegisteredProvider = "SRU_RegisteredProvider",
  SRU_PlannedMonitoring = "SRU_PlannedMonitoring",
  SRU_UndertakingStatus = "SRU_UndertakingStatus",
  SRU_ResidentProfile = "SRU_ResidentProfile",
  SRU_StatementStatus = "SRU_StatementStatus",
  SRU_SAVVICluster = "SRU_SAVVICluster",
  SRU_FireRiskArea = "SRU_FireRiskArea",
  SRU_Agency = "SRU_Agency",
  SRU_SeizureType = "SRU_SeizureType",
  SRU_DispositionType = "SRU_DispositionType",
  SRU_IncidentType = "SRU_IncidentType",
  SRU_TransactionType = "SRU_TransactionType",
  SRU_FireStatus = "SRU_FireStatus",
  SRU_AppointmentReason = "SRU_AppointmentReason",
  SRU_LapseRegistrationReason = "SRU_LapseRegistrationReason",
  SRU_RegistrationStatementType = "SRU_RegistrationStatementType",
  SRU_RegistrationUndertakingLegislation = "SRU_RegistrationUndertakingLegislation",
  SRU_EnforcementPlan_RiskClassification = "SRU_EnforcementPlan_RiskClassification",
  SRU_EnforcementPlan_AgreedMonitoringResponse = "SRU_EnforcementPlan_AgreedMonitoringResponse",
  SRU_EnforcementPlan_AttitudeToTheInsepction = "SRU_EnforcementPlan_AttitudeToTheInsepction",
  SRU_EnforcementPlan_AttitudeToTheNonCompliances = "SRU_EnforcementPlan_AttitudeToTheNonCompliances",
  SRU_EnforcementPlan_DurationOfTheNonCompliance = "SRU_EnforcementPlan_DurationOfTheNonCompliance",
  SRU_EnforcementPlan_AttitudeToCorrectingNonCompliance = "SRU_EnforcementPlan_AttitudeToCorrectingNonCompliance",
  SRU_EnforcementPlan_ImpactOnResident = "SRU_EnforcementPlan_ImpactOnResident",
  SRU_EnforcementPlan_MedicationIssue = "SRU_EnforcementPlan_MedicationIssue",
  SRU_EnforcementPlan_NumberOfResidentsAffected = "SRU_EnforcementPlan_NumberOfResidentsAffected",
  SRU_EnforcementPlan_TypeOfResponseAdministrative = "SRU_EnforcementPlan_TypeOfResponseAdministrative",
  SRU_EnforcementPlan_TypeOfResponseStatutoryEnforcement = "SRU_EnforcementPlan_TypeOfResponseStatutoryEnforcement",
  SRU_EnforcementPlan_TypeOfResponseStrategicAction = "SRU_EnforcementPlan_TypeOfResponseStrategicAction",
  SRU_ReportableIncident_AttendedBy = "SRU_ReportableIncident_AttendedBy",
  SRU_ReportableIncident_ClientOrCaseManagedBy = "SRU_ReportableIncident_ClientOrCaseManagedBy",
  SRU_ReportableIncident_NotifiedBy = "SRU_ReportableIncident_NotifiedBy",
  SRU_ReportableIncident_TransferOfResident = "SRU_ReportableIncident_TransferOfResident",
  SRU_ReportableIncident_ReactiveMonitoringInspection = "SRU_ReportableIncident_ReactiveMonitoringInspection",
  SRU_ReportableIncident_Legislative = "SRU_ReportableIncident_Legislative",
  SRU_StateGovernmentAssistedType = "SRU_StateGovernmentAssistedType",
  SRU_IssueSubSource = "SRU_IssueSubSource",
  SRU_ReportableIncident_Position_NotifyingPerson = "SRU_ReportableIncident_Position_NotifyingPerson",
  SRU_ComplianceNotice_RiskClassification = "SRU_ComplianceNotice_RiskClassification",
  SRU_Registration_ClosureType = "SRU_Registration_ClosureType",
  SRU_Organisation_ClosureType = "SRU_Organisation_ClosureType",
  DWCMS_Insurance_CurrencyCert = "DWCMS_Insurance_CurrencyCert",
  DWCMS_NonStandardNameType = "DWCMS_NonStandardNameType",
  DWCMS_DisciplinaryActionType = "DWCMS_DisciplinaryActionType",
  DWCMS_Jurisdiction = "DWCMS_Jurisdiction",
  DWCMS_LanguageSkillAssessment = "DWCMS_LanguageSkillAssessment",
  DWCMS_ImpairmentAssessmentOutcome = "DWCMS_ImpairmentAssessmentOutcome",
  DWCMS_ScopeOfRecencyOfPracticeAssessment = "DWCMS_ScopeOfRecencyOfPracticeAssessment",
  DWCMS_CriminalHistoryRiskAssessment = "DWCMS_CriminalHistoryRiskAssessment",
  DWCMS_CriminalHistoryAssessmentOutcome = "DWCMS_CriminalHistoryAssessmentOutcome",
  DWCMS_RegulatoryIssuesOtherJurisdictionAssessmentOutcome = "DWCMS_RegulatoryIssuesOtherJurisdictionAssessmentOutcome",
  DWCMS_WhoMadeDetermination = "DWCMS_WhoMadeDetermination",
  DWCMS_QualificationsOutcome = "DWCMS_QualificationsOutcome",
  DWCMS_ExperienceOutcome = "DWCMS_ExperienceOutcome",
  DWCMS_ProfessionalCompetenceOutcome = "DWCMS_ProfessionalCompetenceOutcome",
  DWCMS_SubmissionBoardType = "DWCMS_SubmissionBoardType",
  DWCMS_FinalDetermination = "DWCMS_FinalDetermination",
  DWCMS_ReasonRegistrationRefused = "DWCMS_ReasonRegistrationRefused",
  DWCMS_ReasonRegistrationDifferentDivisionType = "DWCMS_ReasonRegistrationDifferentDivisionType",
  DWCMS_ProfessionalMembership = "DWCMS_ProfessionalMembership",
  DWCMS_SourceOfProhibitionOrder = "DWCMS_SourceOfProhibitionOrder",
  DWCMS_CurrentProhibition = "DWCMS_CurrentProhibition",
  DWCMS_GroundsOfTheOrder = "DWCMS_GroundsOfTheOrder",
  DWCMS_ProhibitedFrom = "DWCMS_ProhibitedFrom",
  DWCMS_ReasonSuppressingDisplay = "DWCMS_ReasonSuppressingDisplay",
  DWCMS_Duration = "DWCMS_Duration",
  DWCMS_ProhibitionOrderType = "DWCMS_ProhibitionOrderType",
  DWCMS_SourceType = "DWCMS_SourceType",
  DWCMS_NonVDWCDisciplinaryActionType = "DWCMS_NonVDWCDisciplinaryActionType",
  DWCMS_RegulatoryBody = "DWCMS_RegulatoryBody",
  DWCMS_DADisciplinaryActionKind = "DWCMS_DADisciplinaryActionKind",
  DWCMS_DAOrderType = "DWCMS_DAOrderType",
  DWCMS_InsuranceType = "DWCMS_InsuranceType",
  DWCMS_ApplicationPathway = "DWCMS_ApplicationPathway",
  DWCMS_TrainingAssessmentOutcome = "DWCMS_TrainingAssessmentOutcome",
  DWCMS_ImpairmentType = "DWCMS_ImpairmentType",
  DWCMS_PriorDisciplinaryActionNotification = "DWCMS_PriorDisciplinaryActionNotification",
  DWCMS_ProfessionalRegistrationJurisdiction = "DWCMS_ProfessionalRegistrationJurisdiction",
  DWCMS_NameOfRegistrationBody = "DWCMS_NameOfRegistrationBody",
  DWCMS_PrimaryPlaceOfPracticeSource = "DWCMS_PrimaryPlaceOfPracticeSource",
  DWCMS_ServiceProvider_Site = "DWCMS_ServiceProvider_Site",
  DWCMS_SuspensionCancellationAssessmentOutcome = "DWCMS_SuspensionCancellationAssessmentOutcome",
  DWCMS_ProhibitionBanningAssessmentOutcome = "DWCMS_ProhibitionBanningAssessmentOutcome",
  DWCMS_BoardRequests = "DWCMS_BoardRequests",
  DWCMS_ApplicationOrigin = "DWCMS_ApplicationOrigin",
  DWCMS_Caution_Source = "DWCMS_Caution_Source",
  DWCMS_Caution_SourceRecord = "DWCMS_Caution_SourceRecord",
  DWCMS_Reprimand_Source = "DWCMS_Reprimand_Source",
  DWCMS_ApplicationStatus_Rollback = "DWCMS_ApplicationStatus_Rollback",
  DWCMS_Registration_Indicator = "DWCMS_Registration_Indicator",
  HealthManager_FSVerified = "HealthManager_FSVerified",
  HealthManager_HealthArea = "HealthManager_HealthArea",
  HealthManager_ClosePremises_Reasons = "HealthManager_ClosePremises_Reasons",
  HealthManager_FoodSafetyProgram_Type = "HealthManager_FoodSafetyProgram_Type",
  HealthManager_FoodSafetyProgram_Templates = "HealthManager_FoodSafetyProgram_Templates",
  HealthManager_Liquor_type = "HealthManager_Liquor_type",
  HealthManager_PrivateWater_Type = "HealthManager_PrivateWater_Type",
  HealthManager_Condition = "HealthManager_Condition",
  HealthManager_NC_RefrigerationType = "HealthManager_NC_RefrigerationType",
  HealthManager_NC_FSP_Records = "HealthManager_NC_FSP_Records",
  HealthManager_Tobacco_ActionType = "HealthManager_Tobacco_ActionType",
  HealthManager_Tobacco_Offences = "HealthManager_Tobacco_Offences",
  HealthManager_Tobacco_InspectionType = "HealthManager_Tobacco_InspectionType",
  HealthManager_TradewasteActivity = "HealthManager_TradewasteActivity",
  HealthManager_TradewastePretreatment = "HealthManager_TradewastePretreatment",
  HealthManager_TradewasteUnits = "HealthManager_TradewasteUnits",
  HealthManager_Foods = "HealthManager_Foods",
  HealthManager_DW_RMP = "HealthManager_DW_RMP",
  HealthManager_DW_Standard_RMP = "HealthManager_DW_Standard_RMP",
  HealthManager_DW_Source = "HealthManager_DW_Source",
  HealthManager_DW_Treatment = "HealthManager_DW_Treatment",
  HealthManager_DW_EColi_Monitor = "HealthManager_DW_EColi_Monitor",
  HealthManager_DW_Chem_Monitor = "HealthManager_DW_Chem_Monitor",
  HealthManager_WW_Treatment = "HealthManager_WW_Treatment",
  HMOnline_PremisesPriorityDescription = "HMOnline_PremisesPriorityDescription",
  HMOnline_PremisesPriorityDescriptionReporting = "HMOnline_PremisesPriorityDescriptionReporting",
  HealthManager_ScopeOfService = "HealthManager_ScopeOfService",
  HealthManager_SpecificInfrastructure = "HealthManager_SpecificInfrastructure",
  HealthManager_TypeOfAnaesthesia = "HealthManager_TypeOfAnaesthesia",
  HealthManager_SubStatus = "HealthManager_SubStatus",
  Wastewater_WaterAuthority = "Wastewater_WaterAuthority",
  Wastewater_RiskLevel = "Wastewater_RiskLevel",
  Wastewater_Status = "Wastewater_Status",
  Wastewater_EffluentDisposal = "Wastewater_EffluentDisposal",
  Wastewater_ApplicationType = "Wastewater_ApplicationType",
  Wastewater_ProductCategory = "Wastewater_ProductCategory",
  Wastewater_ProductSubCategory = "Wastewater_ProductSubCategory",
  Wastewater_ProductMaterial = "Wastewater_ProductMaterial",
  Wastewater_AuthorisationType = "Wastewater_AuthorisationType",
  Wastewater_Classification = "Wastewater_Classification",
  Wastewater_SystemTypeClassification = "Wastewater_SystemTypeClassification",
  Wastewater_PermitConditions = "Wastewater_PermitConditions",
  Wastewater_ConstructionType = "Wastewater_ConstructionType",
  NCRisk_ImportanceRating = "NCRisk_ImportanceRating",
  NCRisk_CapacityToEscalate = "NCRisk_CapacityToEscalate",
  NCRisk_ScaleOfBreach = "NCRisk_ScaleOfBreach",
  NCRisk_UrgencyRating = "NCRisk_UrgencyRating",
  NCRisk_LevelOfEnforcement = "NCRisk_LevelOfEnforcement",
  NCItemLocation = "NCItemLocation",
  NC_Reason_Not_Notified = "NC_Reason_Not_Notified",
  CRS_RelationToChild = "CRS_RelationToChild",
  CRS_SubsidyCardType = "CRS_SubsidyCardType",
  CRS_PortalSupportDocumentType = "CRS_PortalSupportDocumentType",
  CRS_SurveyOptions_1 = "CRS_SurveyOptions_1",
  CRS_SurveyOptions_2 = "CRS_SurveyOptions_2",
  CRS_SurveyOptions_3 = "CRS_SurveyOptions_3",
  CRS_SurveyOptions_4 = "CRS_SurveyOptions_4",
  CRS_SurveyOptions_5 = "CRS_SurveyOptions_5",
  CRS_SurveyOptions_6 = "CRS_SurveyOptions_6",
  CRS_SurveyOptions_7 = "CRS_SurveyOptions_7",
  CRS_SurveyOptions_8 = "CRS_SurveyOptions_8",
  CRS_SurveyOptions_9 = "CRS_SurveyOptions_9",
  TP_ApplicationRating = "TP_ApplicationRating",
  TP_ApplicationBuilding_UseOfBuilding = "TP_ApplicationBuilding_UseOfBuilding",
  TP_ApplicationBuilding_BCA = "TP_ApplicationBuilding_BCA",
  TP_ApplicationBuilding_ExtWallMaterial = "TP_ApplicationBuilding_ExtWallMaterial",
  TP_ApplicationBuilding_RoofCladdingMaterial = "TP_ApplicationBuilding_RoofCladdingMaterial",
  TP_ApplicationBuilding_FrameMaterial = "TP_ApplicationBuilding_FrameMaterial",
  TP_ApplicationBuilding_FloorMaterial = "TP_ApplicationBuilding_FloorMaterial",
  TP_ApplicationBuilding_BAL = "TP_ApplicationBuilding_BAL",
  TP_ApplicationBuilding_LandType = "TP_ApplicationBuilding_LandType",
  TP_ApplicationBuilding_LandOwnership = "TP_ApplicationBuilding_LandOwnership",
  TP_AssessmentPlanner_Zone = "TP_AssessmentPlanner_Zone",
  TP_AssessmentPlanner_Overlay = "TP_AssessmentPlanner_Overlay",
  TP_AssessmentPlanner_PermitTrigger = "TP_AssessmentPlanner_PermitTrigger",
  TP_AssessmentPlanner_StrategicObjective = "TP_AssessmentPlanner_StrategicObjective",
  TP_ApplicationDescription = "TP_ApplicationDescription",
  TP_CertifierRegistrationNumber = "TP_CertifierRegistrationNumber",
  TP_ESDDwellings = "TP_ESDDwellings",
  TP_ESDNonResFloorSpace = "TP_ESDNonResFloorSpace",
  TP_ESDGrossFloorArea = "TP_ESDGrossFloorArea",
  TP_CertificationCellRef = "TP_CertificationCellRef",
  TP_ApplicationBuilding_CouncilConcentRegulation = "TP_ApplicationBuilding_CouncilConcentRegulation",
  TP_Application_ProposedFinalOutcome = "TP_Application_ProposedFinalOutcome",
  TP_ExtensionOfTimeQuestions = "TP_ExtensionOfTimeQuestions",
  TP_Recommendations = "TP_Recommendations",
  TP_ApplicationBuilding_EnqPropertyInfoRequestCategory = "TP_ApplicationBuilding_EnqPropertyInfoRequestCategory",
  Core_ReasonForPermit = "Core_ReasonForPermit",
  Building_CouncilConsent_DocumentChecklist = "Building_CouncilConsent_DocumentChecklist",
  Building_PrivateSurveyor_DocumentChecklist = "Building_PrivateSurveyor_DocumentChecklist",
  Building_PrivateSurveyor_DocumentChecklistCategory = "Building_PrivateSurveyor_DocumentChecklistCategory",
  Planning_EnquiryMethod = "Planning_EnquiryMethod",
  TP_ExtensionOfTimeDelegate = "TP_ExtensionOfTimeDelegate",
  TP_ApplicationBuilding_OwnerBuilder = "TP_ApplicationBuilding_OwnerBuilder",
  TP_ApplicationBuilding_ReportingAuthority = "TP_ApplicationBuilding_ReportingAuthority",
  TP_EnquiryClosureReason = "TP_EnquiryClosureReason",
  Building_Specific_Work = "Building_Specific_Work",
  Building_TypeOf_Construction = "Building_TypeOf_Construction",
  Building_BCA_Version = "Building_BCA_Version",
  Building_Whole_Part = "Building_Whole_Part",
  TP_Assessment_Executive_Summary = "TP_Assessment_Executive_Summary",
  Building_Stage_Description = "Building_Stage_Description",
  TP_ApplicationBuilding_PoolSpaRegulation = "TP_ApplicationBuilding_PoolSpaRegulation",
  Building_Soil_Classification = "Building_Soil_Classification",
  TP_ApplicationBuilding_EnergyEfficiencyRating = "TP_ApplicationBuilding_EnergyEfficiencyRating",
  TP_ApplicationBuilding_PoolSpaBarrierType = "TP_ApplicationBuilding_PoolSpaBarrierType",
  TP_ApplicationPNF_ForestCode = "TP_ApplicationPNF_ForestCode",
  TP_ApplicationPNF_OGRFReviewReason = "TP_ApplicationPNF_OGRFReviewReason",
  TP_ApplicationPNF_FOpsNotificationType = "TP_ApplicationPNF_FOpsNotificationType",
  TP_Application_Condition_Stage = "TP_Application_Condition_Stage",
  TP_PNF_ReviewReason = "TP_PNF_ReviewReason",
  TP_PNF_Outcome = "TP_PNF_Outcome",
  TP_PNF_ReasonForClosure = "TP_PNF_ReasonForClosure",
  TP_PNF_ReasonForTemination = "TP_PNF_ReasonForTemination",
  TP_ApplicationPNF_Tenure = "TP_ApplicationPNF_Tenure",
  Building_Certificate_Type = "Building_Certificate_Type",
  TP_PNF_ReasonForApplication = "TP_PNF_ReasonForApplication",
  TP_Application_Assessment_WorkingHours = "TP_Application_Assessment_WorkingHours",
  TP_Application_Assessment_CommercialVehiclesAccessFrequency = "TP_Application_Assessment_CommercialVehiclesAccessFrequency",
  TP_PNF_LEP_Zone = "TP_PNF_LEP_Zone",
  Building_CouncilConsent_Type = "Building_CouncilConsent_Type",
  TP_PNF_SLM_Zone = "TP_PNF_SLM_Zone",
  TP_Application_SubStatus = "TP_Application_SubStatus",
  TP_ApplicationType = "TP_ApplicationType",
  TP_Precinct = "TP_Precinct",
  TP_HousingType = "TP_HousingType",
  DISRES_ReissueReason = "DISRES_ReissueReason",
  DISRES_PermitHolder_Organisation = "DISRES_PermitHolder_Organisation",
  DISRES_ProofOfResidency = "DISRES_ProofOfResidency",
  DISRES_ProofOfVehicleRegistration = "DISRES_ProofOfVehicleRegistration",
  DISRES_ProofOfMembership = "DISRES_ProofOfMembership",
  DisRes_VehicleColour = "DisRes_VehicleColour",
  MCH_RelationToChild = "MCH_RelationToChild",
  LLP_Purpose = "LLP_Purpose",
  LLP_Placement = "LLP_Placement",
  LLP_Make = "LLP_Make",
  LLP_Model = "LLP_Model",
  LLP_Area = "LLP_Area",
  Animals_Colour = "Animals_Colour",
  Animals_ProofOfSterilisation = "Animals_ProofOfSterilisation",
  Animals_ProofOfObedience = "Animals_ProofOfObedience",
  Animals_DogBreeds = "Animals_DogBreeds",
  Animals_CatBreeds = "Animals_CatBreeds",
  Animals_OtherBreeds = "Animals_OtherBreeds",
  Animals_TagColour = "Animals_TagColour",
  Animals_DeactivateReason = "Animals_DeactivateReason",
  Animals_Size = "Animals_Size",
  Animals_Condition = "Animals_Condition",
  Animals_Disposition = "Animals_Disposition",
  Animals_Kennel_DeactivateReason = "Animals_Kennel_DeactivateReason",
  Animals_MicrochipProofExemption = "Animals_MicrochipProofExemption",
  CSM_Reg19Drugs = "CSM_Reg19Drugs",
  CSM_Pests = "CSM_Pests",
  CSM_PoisonSchedule = "CSM_PoisonSchedule",
  CSM_Condition = "CSM_Condition",
  CSM_Reg25Poison = "CSM_Reg25Poison",
  CSM_Cyanide = "CSM_Cyanide",
  CSM_TypeOfPoison = "CSM_TypeOfPoison",
  CSM_TypeOfPesticideWork = "CSM_TypeOfPesticideWork",
  CSM_TypeOfPesticide = "CSM_TypeOfPesticide",
  CSM_Activity = "CSM_Activity",
  CSM_EndorsementSpeciality = "CSM_EndorsementSpeciality",
  CSM_SubstanceForm = "CSM_SubstanceForm",
  CSM_Poisons_ProductType = "CSM_Poisons_ProductType",
  CSM_Poisons_Region = "CSM_Poisons_Region",
  CSM_Poisons_PestType = "CSM_Poisons_PestType",
  CSM_Poisons_Species = "CSM_Poisons_Species",
  CSM_Poisons_DensityOfPest = "CSM_Poisons_DensityOfPest",
  CSM_Poisons_ControlAlreadyImplemented = "CSM_Poisons_ControlAlreadyImplemented",
  CSM_Poisons_RecommendedAction = "CSM_Poisons_RecommendedAction",
  CSM_Poisons_Product = "CSM_Poisons_Product",
  CSM_Poisons_Product_Measurement = "CSM_Poisons_Product_Measurement",
  CSM_Poisons_AssessmentType = "CSM_Poisons_AssessmentType",
  CSM_Poisons_BaitDeliveryMethod = "CSM_Poisons_BaitDeliveryMethod",
  CSM_Poisons_RiskAssessmentArea = "CSM_Poisons_RiskAssessmentArea",
  CSM_Poisons_PestAnimalProgram = "CSM_Poisons_PestAnimalProgram",
  CSM_Poisons_BaitType = "CSM_Poisons_BaitType",
  CSM_Poisons_TargetPestSpecies = "CSM_Poisons_TargetPestSpecies",
  CSM_Poisons_PesticideType = "CSM_Poisons_PesticideType",
  CSM_Poisons_AnimalType = "CSM_Poisons_AnimalType",
  CSM_Poisons_Risk_Likelihood = "CSM_Poisons_Risk_Likelihood",
  CSM_Poisons_Risk_Consequence = "CSM_Poisons_Risk_Consequence",
  CSM_Poisons_Risk_Analysis = "CSM_Poisons_Risk_Analysis",
  CSM_Poisons_BaitingLocation = "CSM_Poisons_BaitingLocation",
  CSM_Poisons_NumberOfWeeksOfReplacementBaiting = "CSM_Poisons_NumberOfWeeksOfReplacementBaiting",
  CSM_Poisons_ControlType = "CSM_Poisons_ControlType",
  CSM_Poisons_NumberOfPest = "CSM_Poisons_NumberOfPest",
  CSM_Poisons_ObservationType = "CSM_Poisons_ObservationType",
  CSM_Poisons_Pest_Age = "CSM_Poisons_Pest_Age",
  CSM_Poisons_Pest_Gender = "CSM_Poisons_Pest_Gender",
  CSM_Poisons_AccurateObservationPercentage = "CSM_Poisons_AccurateObservationPercentage",
  AMS_AppointmentFormat = "AMS_AppointmentFormat",
  EMS_CurrentEventStatus = "EMS_CurrentEventStatus",
  EMS_EventTypeClassification = "EMS_EventTypeClassification",
  EMS_EventTypeLevel = "EMS_EventTypeLevel",
  EMS_EventTypeGroup = "EMS_EventTypeGroup",
  TrainingManager_SkillLevel = "TrainingManager_SkillLevel",
  TrainingManager_RenewableMethod = "TrainingManager_RenewableMethod",
  TrainingManager_TrainingVenue = "TrainingManager_TrainingVenue",
  Radiation_TypeOfDealings = "Radiation_TypeOfDealings",
  Radiation_CompetencyReq = "Radiation_CompetencyReq",
  Radiation_UseCategory = "Radiation_UseCategory",
  Radiation_UseSubCategory = "Radiation_UseSubCategory",
  Radiation_EvidenceOfCompetency = "Radiation_EvidenceOfCompetency",
  Radiation_WorkflowStatus = "Radiation_WorkflowStatus",
  Radiation_SecurityCheck = "Radiation_SecurityCheck",
  Radiation_TypeOfSources = "Radiation_TypeOfSources",
  Radiation_AccreditationType = "Radiation_AccreditationType",
  Radiation_AccreditationCategory = "Radiation_AccreditationCategory",
  Radiation_COfCOptions = "Radiation_COfCOptions",
  Radiation_DeActivationReason = "Radiation_DeActivationReason",
  Radiation_Manufacturer = "Radiation_Manufacturer",
  Radiation_PlaceDescription = "Radiation_PlaceDescription",
  Radiation_PrincipalFunction = "Radiation_PrincipalFunction",
  Radiation_Radionuclides = "Radiation_Radionuclides",
  Radiation_ReActivationReason = "Radiation_ReActivationReason",
  Radiation_SourceSecurityCategory = "Radiation_SourceSecurityCategory",
  Radiation_SourcePhysicalForm = "Radiation_SourcePhysicalForm",
  Radiation_SourceSecurityRating = "Radiation_SourceSecurityRating",
  Radiation_ThirdPartyAuditor = "Radiation_ThirdPartyAuditor",
  Radiation_PermittedRadiationSource = "Radiation_PermittedRadiationSource",
  Radiation_PermittedPractice = "Radiation_PermittedPractice",
  Radiation_RadionuclideActivityUnit = "Radiation_RadionuclideActivityUnit",
  Radiation_Model = "Radiation_Model",
  Radiation_ResearchProjectDoseUnit = "Radiation_ResearchProjectDoseUnit",
  Radiation_DisposalMethod = "Radiation_DisposalMethod",
  Radiation_DisposalFacilitatorLocation = "Radiation_DisposalFacilitatorLocation",
  NSPR_DeactivateReason = "NSPR_DeactivateReason",
  NSPR_ReactivateReason = "NSPR_ReactivateReason",
  SSMS_InstallationAuthority = "SSMS_InstallationAuthority",
  SSMS_VICROADSOFFICE = "SSMS_VICROADSOFFICE",
  SSMS_SpeedSignSize = "SSMS_SpeedSignSize",
  SSMS_SpeedSignType = "SSMS_SpeedSignType",
  SSMS_SpeedSignVarCal = "SSMS_SpeedSignVarCal",
  PORTAL_Document_Optional = "PORTAL_Document_Optional",
  PORTAL_Document_Mandatory = "PORTAL_Document_Mandatory",
  OOSYS_TP_ApplicationStatus = "OOSYS_TP_ApplicationStatus",
  OOSYS_WorkFlowProcessStatus = "OOSYS_WorkFlowProcessStatus",
  OOSYS_RAD_RegisterStatus = "OOSYS_RAD_RegisterStatus",
  OOSYS_DWCMS_ApplicationRegistrationStatus = "OOSYS_DWCMS_ApplicationRegistrationStatus",
  CEM_Denomination = "CEM_Denomination",
  CEM_Section = "CEM_Section",
  CEM_DepthOfGrave = "CEM_DepthOfGrave",
  INFRINGEMENTS__VehicleMake = "INFRINGEMENTS__VehicleMake",
  INFRINGEMENTS_VehicleModel = "INFRINGEMENTS_VehicleModel",
  INFRINGEMENTS_VehicleColour = "INFRINGEMENTS_VehicleColour",
  INFRINGEMENTS_VehicleType = "INFRINGEMENTS_VehicleType",
  INFRINGEMENTS_Weather = "INFRINGEMENTS_Weather",
  INFRINGEMENTS_SignType = "INFRINGEMENTS_SignType",
  INFRINGEMENTS_SignExemption = "INFRINGEMENTS_SignExemption",
  INFRINGEMENTS_DeliveryMethod = "INFRINGEMENTS_DeliveryMethod",
  INFRINGEMENTS_TicketFormat = "INFRINGEMENTS_TicketFormat",
  INFRINGEMENTS_SignTimes = "INFRINGEMENTS_SignTimes",
  INFRINGEMENTS_AdressArea = "INFRINGEMENTS_AdressArea",
  INFRINGEMENTS_State = "INFRINGEMENTS_State",
  FLEET_CurrentEventStatus = "FLEET_CurrentEventStatus",
  FLEET_EventTypeClassification = "FLEET_EventTypeClassification",
  FLEET_EventTypeLevel = "FLEET_EventTypeLevel",
  FLEET_EventTypeGroup = "FLEET_EventTypeGroup",
  FLEET_DriveType = "FLEET_DriveType",
  FLEET_LicenceType = "FLEET_LicenceType",
  FLEET_Location = "FLEET_Location",
  LandManagement_Enquiry_Type = "LandManagement_Enquiry_Type",
  LandManagement_Compliance_Type = "LandManagement_Compliance_Type",
  LandManagement_Term = "LandManagement_Term",
  LandManagement_Species = "LandManagement_Species",
}

export enum Core_ActionOutcome_KEYWORDGROUPTYPE {
  SystemInitialise = 0,
  ConciliationAction = 1,
  RegulatoryAction = 2,
  StandardAction = 3,
}
