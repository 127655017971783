import {
  AddCommentButton,
  ICommentButtonProps,
} from "@app/core/comments/components/buttons/add-comment/_index";
import React from "react";

interface ICommentPropertyButtonProps
  extends Pick<ICommentButtonProps, "id" | "recordType" | "isDisabled"> {}

export const AddCommentPropertyButton = (
  props: ICommentPropertyButtonProps
) => {
  return (
    <AddCommentButton {...props} isUseDropDownForTitle shouldApplyWrapper />
  );
};
