import { RebateCalcForSelectedEntitlements } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/calculations/components/detail/_index";
import { colEntitlementCalculation } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/calculations/config";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const CalculationsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Only adjust rebates from" isMandatory />
          <Field
            name={nameOf("OnlyAdjustRebatesFrom")}
            component={CCDatePicker}
            disabled={options?.isReadOnly}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={!options.isReadOnly ? requiredValidator : undefined}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Entitlements by rating period" />
          <CCGrid
            data={[]}
            selectableMode={options?.isReadOnly ? undefined : "single"}
            primaryField="EntitlementId"
            columnFields={colEntitlementCalculation}
            readOnly={options?.isReadOnly}
            detail={RebateCalcForSelectedEntitlements}
          />
        </div>
      </div>

      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Total rebates adjustment" />
          <Field
            component={CCValueField}
            name={nameOf("TotalRebatesAdjustment")}
            format={CURRENCY_FORMAT.CURRENCY1}
          />
        </div>
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Transaction description" isMandatory />
          <Field
            component={CCInput}
            name={nameOf("TransactionDescription")}
            validator={!options.isReadOnly ? requiredValidator : undefined}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Transaction notes" />
          <Field component={CCInput} name={nameOf("TransactionNotes")} />
        </div>
      </div>
    </section>
  );
};
