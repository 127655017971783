import { proposedAssessmentMaxNumberValidator } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/form-steps/proposed-assessment/util";
import { finalDecisionRAListData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/config";
import { PPRPermitCompleted } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  numberValidator,
  requiredValidator,
} from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const PROPOSED_ASSESSMENT_FORM_STEP = "ProposedAssessment";

export const ProposedAssessmentFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, options }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const nameOf = nameOfFactory<PPRPermitCompleted>();
  return (
    <div className="cc-form">
      {options.isVisibleProposedFinal && (
        <>
          <section className="cc-field-group">
            <div className="cc-field">
              <label className="cc-label">Planning permit completion</label>
              <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Planning permit decision complete by responsible authority
                    </label>
                    <Field
                      name={nameOf("Flag_DecisionCompletedByRA")}
                      component={CCSwitch}
                      checked={valueGetter(
                        nameOf("Flag_DecisionCompletedByRA")
                      )}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Final decision of responsible authority
                      <CCTooltip type="validator" position="right" />
                    </label>
                    <Field
                      name={nameOf("RADecision_ENUM")}
                      textField="Value"
                      dataItemKey="Key"
                      data={finalDecisionRAListData}
                      component={CCSearchComboBox}
                      validator={requiredValidator}
                      isUseDefaultOnchange
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Endorsed planning permit saved in PPR
                    </label>
                    <Field
                      name={nameOf("Flag_EndorsedPermitSavedInPPR")}
                      component={CCSwitch}
                      checked={valueGetter(
                        nameOf("Flag_EndorsedPermitSavedInPPR")
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </>
      )}

      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">
            Transport data proposed in the planning permit application
          </label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Proposed application details has been filled in
                </label>
                <Field
                  name={nameOf("Flag_ProposedApplicationDetailsFilled")}
                  component={CCSwitch}
                  checked={valueGetter(
                    nameOf("Flag_ProposedApplicationDetailsFilled")
                  )}
                />
              </div>
            </div>
            {!valueGetter(nameOf("Flag_ProposedApplicationDetailsFilled")) && (
              <section className="cc-field-group">
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional dwellings
                      <CCTooltip
                        type="validator"
                        position="right"
                        content={"Total number of additional dwellings on site"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfAdditionalDwellings_Proposed")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL1}
                      placeholder={"Additional dwellings"}
                      validator={[
                        requiredValidator,
                        proposedAssessmentMaxNumberValidator,
                      ]}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional bicycle spaces
                      <CCTooltip
                        type="info"
                        position="right"
                        content={
                          "Total number of additional bicycle spaces/parking on site"
                        }
                      />
                    </label>
                    <Field
                      name={nameOf(
                        "TotalNumberOfAdditionalBicycleSpaces_Proposed"
                      )}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL1}
                      placeholder={"Additional bicycle spaces"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Number of showers
                      <CCTooltip
                        type="info"
                        position="right"
                        content={
                          "Total number of showers (for end of trip facilities only)"
                        }
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfShowers_Proposed")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL1}
                      placeholder={"Number of showers"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional car parks
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total number of additional car parks on site"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfAdditionalCarParks_Proposed")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL1}
                      placeholder={"Additional car parks"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">Additional crossovers</label>
                    <Field
                      name={nameOf(
                        "TotalNumberOfAdditionalCrossovers_Proposed"
                      )}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL1}
                      placeholder={"Additional crossovers"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional commercial space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional commercial floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf(
                        "TotalAdditionalFloorSpace_Commercial_Proposed"
                      )}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional commercial space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional retail space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional retail floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Retail_Proposed")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional retail space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional industrial space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional industrial floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf(
                        "TotalAdditionalFloorSpace_Industrial_Proposed"
                      )}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional industrial space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional government space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional government floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf(
                        "TotalAdditionalFloorSpace_Government_Proposed"
                      )}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional government space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional education space
                    </label>
                    <Field
                      name={nameOf(
                        "TotalAdditionalFloorSpace_Education_Proposed"
                      )}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional education space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">Additional health space</label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Health_Proposed")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional health space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional other space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional other floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Other_Proposed")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional other space"}
                      validator={proposedAssessmentMaxNumberValidator}
                    />
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </section>
      <br />
      {options.isVisibleProposedFinal && (
        <>
          <section className="cc-field-group">
            <div className="cc-field">
              <label className="cc-label">
                Transport data finalised in the planning permit application
              </label>
              <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional dwellings
                      <CCTooltip
                        type="validator"
                        position="right"
                        content={"Total number of additional dwellings on site"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfAdditionalDwellings")}
                      component={CCNumericTextBox}
                      format={NUMBER_FORMAT.NUMBER2}
                      placeholder={"Additional dwellings"}
                      validator={numberValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional bicycle spaces
                      <CCTooltip
                        type="info"
                        position="right"
                        content={
                          "Total number of additional bicycle spaces/parking on site"
                        }
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfAdditionalBicycleSpaces")}
                      component={CCNumericTextBox}
                      format={NUMBER_FORMAT.NUMBER2}
                      placeholder={"Additional bicycle spaces"}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Number of showers
                      <CCTooltip
                        type="info"
                        position="right"
                        content={
                          "Total number of showers (for end of trip facilities only)"
                        }
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfShowers")}
                      component={CCNumericTextBox}
                      format={NUMBER_FORMAT.NUMBER2}
                      placeholder={"Number of showers"}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional car parks
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total number of additional car parks on site"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalNumberOfAdditionalCarParks")}
                      component={CCNumericTextBox}
                      format={NUMBER_FORMAT.NUMBER2}
                      placeholder={"Additional car parks"}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">Additional crossovers</label>
                    <Field
                      name={nameOf("TotalNumberOfAdditionalCrossovers")}
                      component={CCNumericTextBox}
                      format={NUMBER_FORMAT.NUMBER2}
                      placeholder={"Additional crossovers"}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional commercial space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional commercial floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Commercial")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional commercial space"}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional retail space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional retail floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Retail")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional retail space"}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional industrial space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional industrial floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Industrial")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional industrial space"}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional government space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional government floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Government")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional government space"}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional education space
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Education")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional education space"}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">Additional health space</label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Health")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional health space"}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Additional other space
                      <CCTooltip
                        type="info"
                        position="right"
                        content={"Total additional other floor area (sqm)"}
                      />
                    </label>
                    <Field
                      name={nameOf("TotalAdditionalFloorSpace_Other")}
                      component={CCNumericTextBox}
                      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
                      placeholder={"Additional other space"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </>
      )}
    </div>
  );
};
