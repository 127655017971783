import { ExistedLookupTables } from "@app/products/crms/system-admin/lookup-tables/[id]/components/forms/existed/_index";
import { NewLookupTables } from "@app/products/crms/system-admin/lookup-tables/[id]/components/forms/new/_index";
import { useLookupTableStore } from "@app/products/crms/system-admin/lookup-tables/[id]/store";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

const ManageLookupTables = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { resetStore, loadLookupTable, setParentSection } =
    useLookupTableStore();
  const location = useLocation();
  const notificationState = location.state as IPSARApplicationParentSection;

  useEffect(() => {
    if (notificationState && notificationState?.notification) {
      setParentSection(notificationState);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState]);

  useEffect(() => {
    loadLookupTable(parseInt(id), isNew);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  if (isNew) {
    return <NewLookupTables />;
  }
  return <ExistedLookupTables />;
};

export default observer(ManageLookupTables);
