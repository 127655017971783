import { DTO_Assessment_Charges } from "@app/products/property/assessments/[id]/components/child-screens/charges/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { loadPropertyAssessmentChargeDetail } from "./api";
import { colBalances, colInstalments, colLevies } from "./config";

const ChargesDetails = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const [isLoading, setIsLoading] = useState(true);

  const [columnLevies, setColumnLevies] = useState(colLevies);
  const [columnInstalments, setColumnInstalments] = useState(colInstalments);
  const [columnBalances, setColumnBalances] = useState(colBalances);

  const [data, setData] = useState<DTO_Assessment_Charges>();
  useEffectOnce(() => {
    setIsLoading(true);
    loadPropertyAssessmentChargeDetail(dataItem.assessmentId, dataItem.Id).then(
      (response) => {
        setData(response);
        if (
          !response?.Charges.Charges ||
          response.Charges.Charges.length === 0
        ) {
          setIsLoading(false);
          return;
        }

        const chargeLeviesTotals: any =
          response?.Charges.Charges[0].ChargeLevies.Totals;
        columnLevies.forEach((item) => {
          if (Object.keys(chargeLeviesTotals).includes(item.field)) {
            item.footerCell = (
              <CurrencyCell value={chargeLeviesTotals[item.field]} />
            );
          }
        });

        setColumnLevies(columnLevies);

        const chargeInstalmentsTotals: any =
          response?.Charges.Charges[0].ChargeInstalments.Totals;
        columnInstalments.forEach((item) => {
          if (Object.keys(chargeInstalmentsTotals).includes(item.field)) {
            item.footerCell = (
              <CurrencyCell value={chargeInstalmentsTotals[item.field]} />
            );
          }
        });
        setColumnInstalments(columnInstalments);

        const chargeBalancesTotals: any =
          response?.Charges.Charges[0].ChargeBalances.Totals;
        columnBalances.forEach((item) => {
          if (Object.keys(chargeBalancesTotals).includes(item.field)) {
            item.footerCell = (
              <CurrencyCell value={chargeBalancesTotals[item.field]} />
            );
          }
        });
        setColumnBalances(columnBalances);

        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  });

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Levies",
      component: (
        <CCGrid
          className="cc-charges-levies-grid"
          data={data?.Charges.Charges[0].ChargeLevies.Levies || []}
          columnFields={columnLevies}
          primaryField="Id"
        />
      ),
    },
    {
      title: "Instalments",
      component: (
        <CCGrid
          className="cc-charges-instalments-grid"
          data={data?.Charges.Charges[0].ChargeInstalments.Instalments || []}
          columnFields={columnInstalments}
          primaryField="Id"
        />
      ),
    },
    {
      title: "Balances",
      component: (
        <CCGrid
          className="cc-charges-balance-grid"
          data={data?.Charges.Charges[0].ChargeBalances.Balances || []}
          columnFields={columnBalances}
          primaryField="ID"
        />
      ),
    },
  ];
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-charges-detail-grid">
      <CustomPanelBar listPanelBar={listPanelBar} sort={false} />
    </div>
  );
};
export default ChargesDetails;
