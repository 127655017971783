import InspectionAssessment from "@app/products/ams/components/inspection/InspectionAssessment";
import KanbanBoard from "@app/products/ams/components/kanban/KanbanBoard";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import ManageCategory from "@app/products/ams/forms/ManageCategory";
import ManageWorkOrder from "@app/products/ams/forms/ManageWorkOrder";
import { amsRoute } from "@app/products/ams/route";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

export function AmsRouter(props: any) {
  return (
    <ProductRouterSwitch>
      {amsRoute.children &&
        convertRouteDynamic(amsRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element => {
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}

      {/* <PrivateRoute
          path={AmsRoute.WORK_ORDER_REGISTER_PATH}
          component={WorkOrderGrid}
        /> */}
      <PrivateRoute
        path={[AmsRoute.WORK_ORDER_NEW_PATH]}
        component={ManageWorkOrder}
      />

      <PrivateRoute
        path={[AmsRoute.WORK_ORDER_MANAGE_PATH + ":id"]}
        component={(props: any) => (
          <ManageWorkOrder id={props.match.params.id} />
        )}
      />

      <Route path={AmsRoute.CATEGORY_MANAGE_PATH} component={ManageCategory} />

      <Route
        path={AmsRoute.ASSESSMENT_MANAGE_PATH}
        component={InspectionAssessment}
      />

      <Route path={AmsRoute.KANBAN_MANAGE_PATH} component={KanbanBoard} />
    </ProductRouterSwitch>
  );
}
