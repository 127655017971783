import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { colNoticeGroupsDetail } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notice-groups/components/notice-groups-details/config";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { getNoticeRunsNoticeGroups } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/notice-groups/components/notice-groups-details/api";
import { VO_Notice_Assessment_NoticeGroup } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { useEffectOnce } from "react-use";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useParams } from "react-router-dom";
import { nameOfFactory } from "@common/utils/common";
import Loading from "@components/loading/Loading";

const nameOf = nameOfFactory<VO_Notice_Assessment_NoticeGroup>();
export const NoticeGroupsDetail = ({ dataItem }: GridDetailRowProps) => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assessmentNoticeGroupData, setAssessmentNoticeGroupData] =
    useState<VO_Notice_Assessment_NoticeGroup[]>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const loadLovs = async () => {
    setIsLoading(true);
    const response = await getNoticeRunsNoticeGroups(id, dataItem?.Code);
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      setAssessmentNoticeGroupData(response?.data?.value);
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error ?? "Load failed",
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadLovs();
  });

  if (isLoading) return <Loading isLoading />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadLovs();
        }}
      />
    );
  }

  return (
    <CCGrid
      isLoading={isLoading}
      data={assessmentNoticeGroupData ?? []}
      columnFields={colNoticeGroupsDetail}
      primaryField={nameOf("Assessment_Id")}
    />
  );
};
