import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const mockContacts: ICommonContactItemRender[] = [
  {
    ID: "3173054",
    Type: "Owner",
    DisplayName: "Hamish Martin",
    WorkPhone: "653-198",
    Mobile: "196-260-8553",
    PreferredMethod_ENUM: "0",
    Email: "hasmish@openoffice.com.au",
  },
];
