export enum eCertificateType {
  Assessment = 1,
  Parcel = 2,
  ViewConfiguration = 3,
  PIC = 4,
  PICNew = 5,
  PICDelete = 6,
  Lot = 7,
}

export enum eCertificateTypeComponent {
  PIC = "PIC",
  Parcel = "Parcel",
  Assessment = "Assessment",
}

export enum eField {
  Assessments = "Assessments",
  Parcels = "Parcels",
  Lot = "Lot",
}
