import {
  OPTIONS_FORM_STEP,
  OptionsFormStep,
} from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/components/form-elements/options/_index";
import {
  RESULTS_FORM_STEP,
  ResultsFormStep,
} from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/components/form-elements/results/_index";
import { defaultOptions } from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/config";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React from "react";

interface IImportSchemeAccountsDialog {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
const defaultTitle = "Scheme Account Import";

const steps: IStep[] = [
  {
    label: "Options",
    initialValues: defaultOptions,
    component: OptionsFormStep,
    visible: true,
    key: OPTIONS_FORM_STEP,
  },
  {
    label: "Results",
    component: ResultsFormStep,
    visible: true,
    key: RESULTS_FORM_STEP,
  },
];

export const ImportSchemeAccountsDialog = observer(
  ({
    onClose,
    onSubmit,
    isIncompleteMode = false,
    isFromActionList = false,
  }: IImportSchemeAccountsDialog) => {
    return (
      <CCFormStep
        onSubmit={onSubmit}
        initialSteps={steps}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="45%"
            maxHeight="50%"
            titleHeader={defaultTitle}
            onClose={onClose}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Park
                  </Button>
                )}

                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {renderProps.nextButton.label === "Finish" ? (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={(event: any) => {
                      event.preventDefault();
                      renderProps.resetAllStepsAfter();
                      renderProps.moveToStep();
                    }}
                  >
                    Clear Imports
                  </Button>
                ) : undefined}
                <Button
                  themeColor="primary"
                  id="cc-next-step-button"
                  disabled={renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {renderProps.nextButton.label === "Finish"
                    ? "Update Account"
                    : renderProps.nextButton.label}
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
