import { validatorDate } from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/util";
import {
  readingInstructionsData,
  readingRouteData,
} from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/meter-location/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const METER_LOCATION_FORM_STEP = "MeterLocation";
export const MeterLocationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Installation date</label>
            <Field
              name={nameOf("InstallationDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Initial meter reading</label>
            <Field
              name={nameOf("InitialMeterReading")}
              min={1}
              component={NumericTextBox}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Installed by</label>
            <Field
              name={nameOf("InstalledBy")}
              placeholder="Installed by"
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Meter location</label>
            <Field
              name={nameOf("MeterLocation")}
              placeholder="Meter location"
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Reading instructions</label>
            <Field
              name={nameOf("ReadingInstructions")}
              data={readingInstructionsData}
              textField="Value"
              dataItemKey="Key"
              component={CCSearchComboBox}
              value={getDropdownValue(
                valueGetter("ReadingInstructions"),
                readingInstructionsData,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange("ReadingInstructions", {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Special instructions</label>
            <Field
              name={nameOf("SpecialInstructions")}
              component={CCTextArea}
              rows={3}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Keys are requirements</label>
            <Field
              name={nameOf("KeysAreRequirements")}
              defaultChecked={getFieldValue(nameOf("KeysAreRequirements"))}
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Reading route</label>
            <Field
              name={nameOf("ReadingRoute")}
              data={readingRouteData}
              textField="Value"
              dataItemKey="Key"
              component={CCSearchComboBox}
              value={getDropdownValue(
                valueGetter("ReadingRoute"),
                readingRouteData,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange("ReadingRoute", {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Sequence within route</label>
            <Field
              name={nameOf("SequenceWithinRoute")}
              placeholder="Sequence within route"
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Next scheduled reading date</label>
            <Field
              name={nameOf("NextScheduledReadingDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              value={
                getFieldValue("NextScheduledReadingDate")
                  ? new Date(getFieldValue("NextScheduledReadingDate"))
                  : new Date()
              }
              min={new Date()}
              validator={validatorDate}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Reading frequency</label>
            <div className="cc-custom-input-group">
              <Field
                name={nameOf("ReadingFrequency")}
                placeholder={"Reading frequency"}
                min={1}
                component={NumericTextBox}
              />
              <div className="cc-input-group-postfix">month(s)</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
