import { EnquiriesLodgeApplicationButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/lodge-application/_index";
import { PPREnquiryForm } from "@app/products/town-planning/ppr/enquiries/_id/components/child-screens/general/_index";
import { PPREnquirySubmitActions } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewEnquiries = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();

  const { isLoading, responseLoadError, onSubmit, loadPPREnquiry, pprEnquiry } =
    usePPREnquiryStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Planning Enquiry"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPPREnquiry(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title={pprEnquiry?.Status_ENUM} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <EnquiriesLodgeApplicationButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={PPREnquirySubmitActions.New}
              />,
              <CCNavButton title={"View"} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {pprEnquiry && <PPREnquiryForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
