import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";

export const bookingContactData = {
  FirstName: "Paige",
  LastName: "Gutmann",
  OrganisationName: "Champlin Schmitt Web Service",
  Mobile: "1900-654-321",
  HomePhone: "202-555-0136",
  WorkPhone: "302-410-7918",
  Email: "paige.gutmann17@gmail.com",
};

export const dropdownStatusData: IKeyValuePacket[] = [
  { Key: 1, Value: "Committee" },
  { Key: 2, Value: "Delegate" },
  { Key: 3, Value: "Minister" },
  { Key: 4, Value: "Senior Statutory Planner" },
  { Key: 5, Value: "Tribunal" },
  { Key: 6, Value: "DPC" },
  { Key: 7, Value: "Development Control Unit" },
  { Key: 8, Value: "Sydney Nth Planning Panel(JRPP)" },
  { Key: 9, Value: "Local Planning Panel" },
  { Key: 10, Value: "Land and Environment Court" },
];

export const parentPage: IParentSection[] = [
  {
    key: 1,
    label: "Booked facilities",
    value: "Ronald Reserve, John reserve, Sports Ground",
  },
  {
    key: 2,
    label: "Facility booking dates",
    value:
      "Ronald Reserve: [ 07/10/2018 01:00 PM ] John reserve: [ 26/02/2021 09:00 AM ] Sports Ground: [ 02/10/2018 03:00 PM ]",
  },
  { key: 3, label: "Event types", value: "Cricket, Football" },
];
