import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { TownPlanningApiUrl } from "@app/products/town-planning/config";
import { ICovenantType } from "@app/products/town-planning/[id]/components/child-screens/general/components/form-element/model";
import { formatRichText } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { FormRenderProps } from "@progress/kendo-react-form";
export const colAppCategory: IColumnFields[] = [
  { title: "Category Name", field: "Name", width: 150 },
  { title: "Target Days", field: "TargetDays", width: 100 },
  {
    title: "Town Planning",
    field: "TownPlanning",
    width: 100,
  },
  {
    title: "Certification",
    field: "Certification",
    width: 100,
  },
];

export const colFeeSchedule: IColumnFields[] = [
  { title: "Regulation", field: "Name", width: 150 },
  { title: "Class", field: "Class", width: 100 },
  { title: "Description", field: "Description", width: 100 },
  { title: "Apply To", field: "ApplyTo", width: 100 },
];

export const colPlanner: IColumnFields[] = [
  { title: "Name", field: "Name", width: 150 },
  { title: "Position", field: "Position", width: 150 },
  { title: "Email", field: "Email", width: 150 },
  { title: "Work Phone", field: "Phone", width: 150 },
];

export const colFinalOutcomeProposed: IColumnFields[] = [
  { title: "Name", field: "Name" },
];
export const covenantType: Array<ICovenantType> = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "N/A", value: "n/a" },
];

export const appCategoryFields = (formRenderProps: FormRenderProps) => ({
  isMultiSelect: true,
  titleHeader: "Pick Categories",
  placeholder: "Application category",
  tableTypes: colAppCategory,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: "ApplicationCategories_Namelist",
  value: formRenderProps
    .valueGetter("ApplicationCategories_Namelist")
    ?.map((item: string) => formatRichText(item))
    ?.join(", "),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("ApplicationCategories_Namelist", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});

export const feeScheduleFields = (formRenderProps: FormRenderProps) => ({
  isMultiSelect: true,
  titleHeader: "Fees Schedules",
  placeholder: "Fee Schedule",
  tableTypes: colFeeSchedule,
  url: CORE_INSPECTION_TYPES_ODATA,
  disabled: true,
  fieldName: "FeeSchedules_Namelist",
  value: formRenderProps.valueGetter("FeeSchedules_Namelist")?.join(", "),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("FeeSchedules_Namelist", {
        value: event.detail.map((fee: any) => fee.Name),
      });
    }
  },
});

export const plannerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Officer",
  placeholder: "Planner",
  tableTypes: colPlanner,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  disabled: true,
  fieldName: "Planner",
  value: formRenderProps.valueGetter("Planner"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("Planner", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("Planner_ID", {
        value: event.detail.ID,
      });
      formRenderProps.onChange("Planner", {
        value: event.detail.Name,
      });
    }
  },
});
export const finalOutcomeProposedFields = (
  formRenderProps: FormRenderProps
) => ({
  titleHeader: "Proposed Final Outcome",
  placeholder: "Proposed Final Outcome",
  tableTypes: colPlanner,
  url: TownPlanningApiUrl.GET_FINAL_OUTCOME_PROPOSE_TOWNPLANING,
  disabled: true,
  fieldName: "FinalOutcomeProposed",
  value: formRenderProps.valueGetter("FinalOutcomeProposed"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("FinalOutcomeProposed", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("FinalOutcomeProposed", {
        value: event.detail.ID,
      });
    }
  },
});
export const preAppMetting_CoucilOfficerFields = (
  formRenderProps: FormRenderProps
) => ({
  titleHeader: "Pick Officer",
  placeholder: "Officer",
  tableTypes: colPlanner,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  disabled: true,
  fieldName: "PreApplicationMeeting_CouncilOfficer",
  value: formRenderProps.valueGetter("PreApplicationMeeting_CouncilOfficer"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("PreApplicationMeeting_CouncilOfficer", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("Planner_ID", {
        value: event.detail.ID,
      });
      formRenderProps.onChange("PreApplicationMeeting_CouncilOfficer", {
        value: event.detail.Name,
      });
    }
  },
});

export const enforcementIssue_CouncilOfficerFields = (
  formRenderProps: FormRenderProps
) => ({
  titleHeader: "Pick Officer",
  placeholder: "Officer",
  tableTypes: colPlanner,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  disabled: true,
  fieldName: "EnforcementIssue_CouncilOfficer",
  value: formRenderProps.valueGetter("EnforcementIssue_CouncilOfficer"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("EnforcementIssue_CouncilOfficer", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("Planner_ID", {
        value: event.detail.ID,
      });
      formRenderProps.onChange("EnforcementIssue_CouncilOfficer", {
        value: event.detail.Name,
      });
    }
  },
});
