import { eventEmitter } from "@/App";
import { Attachment } from "@app/core/documents/model";
import { postImportData } from "@app/core/import/buttons/api";
import { ImportDialog } from "@app/core/import/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { customLogger } from "@common/utils/logger";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IImportButtonProps {
  buttonTitle: string;
  apiURL: string;
  primaryImportFieldName?: string;
  description?: React.ReactNode;
  disabled?: boolean;
}

export const ImportButton = observer(
  ({
    buttonTitle,
    apiURL,
    primaryImportFieldName,
    description,
    disabled,
  }: IImportButtonProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (
      data: Attachment,
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      setIsLoading(true);
      const response = await postImportData(data, apiURL);
      customLogger("Core Import, ImportButton, postImportData").info(
        response?.data
      );
      if (isSuccessIdentityPacket(response)) {
        pushNotification({
          title: "Items imported successfully ",
          type: "success",
        });
        setIsShowDialog(false);
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
      } else {
        notificationRef?.current?.pushNotification({
          title: "Import items failed",
          type: "error",
          autoClose: false,
          description: response.data?.Errors ?? response?.error,
        });
      }
      setIsLoading(false);
    };
    return (
      <>
        <CCNavButton
          title={buttonTitle}
          onClick={() => setIsShowDialog(true)}
          disabled={disabled}
        />
        {isShowDialog && (
          <ImportDialog
            onClose={() => setIsShowDialog(false)}
            onSubmit={onSubmit}
            isLoadingButton={isLoading}
            description={description}
            primaryImportFieldName={primaryImportFieldName}
          />
        )}
      </>
    );
  }
);
