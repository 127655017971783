import React from "react";
import "./_index.scss";
interface IContactTitleProps {
  title: string;
  isGolden?: boolean;
}
export const ContactTitle = ({ title, isGolden = false }: IContactTitleProps) => {
  return (
    <>
      <p>{title}</p>
      {isGolden && <p className="cc-golden-badge">Golden Contact</p>}
    </>
  );
};
