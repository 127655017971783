import { getCSLById } from "@app/products/csl/[id]/components/reference-sidebar/api";
import { CSLRegisterForm, CSLResponse } from "@app/products/csl/[id]/model";
import { convertCSLResponseToForm } from "@app/products/csl/[id]/util";
import { formatDateByKendo, splitCamelCase } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const CSLDetailTab = observer(() => {
  const [cslInfo, setCslInfo] = useState<CSLRegisterForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const cslId = params.id || lastSelectedRow.ID;
  useEffect(() => {
    setIsLoading(true);
    getCSLById(cslId).then((data?: CSLResponse) => {
      if (data) {
        setCslInfo(convertCSLResponseToForm(data));
      } else if (lastSelectedRow) {
        // TODO: remove when integrate with api
        setCslInfo({
          ...lastSelectedRow,
          LicenceNumber: lastSelectedRow.LicenceNumber,
          Status_ENUM: lastSelectedRow.Status,
          issued: lastSelectedRow.DateIssued,
          expires: lastSelectedRow.DateExpires,
        });
      }
      setIsLoading(false);
    });
  }, [cslId, lastSelectedRow]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Licence Number:"}
        value={cslInfo?.LicenceNumber?.toString() || ""}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={splitCamelCase(cslInfo?.Status_ENUM || "")}
          success
        />
        <ProductReferenceRow
          title={"Issued:"}
          value={cslInfo?.issued ? formatDateByKendo(cslInfo?.issued) : ""}
        />
        <ProductReferenceRow
          title={"Expires:"}
          value={cslInfo?.expires ? formatDateByKendo(cslInfo?.expires) : ""}
        />
        <ProductReferenceRow image="https://kottke.org/plus/misc/images/ai-faces-01.jpg" />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
