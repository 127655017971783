import { CoreAPIService } from "@/common/apis/coreAPIService";
import { PPREnquiryIdentityPacket } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponse } from "@common/apis/model";

export const putApproveResponse = async (
  id: number
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `api/tp/ppr/internal/enquiry/${id}/approveresponse`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
