import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const updateProductListing = async (
  data: any
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put<any>(
      `/api/core/internal/settings/save-product-listing`,
      JSON.stringify(data)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
