import {
  getElementsForScreenReference,
  getIRowFieldsForGroupContainer,
  getModelsForConfigSet,
} from "@app/products/ams/api/data/catalogues/catalogue-store";
import { AmsDateInput } from "@app/products/ams/components/fields-input/ams-date-input/_index";
import { AmsDropDownList } from "@app/products/ams/components/fields-input/ams-drop-down-list/_index";
import { AmsSwitchInput } from "@app/products/ams/components/fields-input/ams-switch-input/_index";
import { AmsTextInput } from "@app/products/ams/components/fields-input/ams-text-input/_index";
import {
  getProperty,
  hasOwnProperty,
  IFieldCfgScreenReference,
  IGroupContainerLayout,
  IModelConfigurationSet,
  IModelData,
  IRowFields,
  modelContext,
} from "@app/products/ams/model/catalogue";
import { PanelBar } from "@progress/kendo-react-layout";
import React, { Fragment, useState } from "react";
import { GroupPanelWrapper } from "../components/GroupPanelWrapper";
import PanelBarTitle from "../components/PanelBarTitle";

export interface ILookupConfig {
  data: any;
  textField: string;
  dataItemKey: string;
  value: any;
}

export interface IProps {
  modelData: IModelData[];
  modelConfigSet: IModelConfigurationSet;
  designerMode?: boolean;
  configureLookup?: (
    fldCfgSr: IFieldCfgScreenReference,
    record: any
  ) => ILookupConfig;
  moveItem?: (dragItemIdx: number, hoverItemIdx: number) => void;
}

export const EntityDataDisplay: React.FC<IProps> = ({
  modelData,
  modelConfigSet,
  designerMode,
  configureLookup,
  moveItem,
}) => {
  const [modelDataArray, setModelDataArray] = useState(modelData);
  const modelDefs = getModelsForConfigSet(modelConfigSet);

  const splitter = "¿";
  const generateName = (fcsr: IFieldCfgScreenReference): string => {
    //i could generate a hash or something shorter, but this is easier to read in debug when trying work out whats going on
    return `${fcsr.modelDefRef.context}${splitter}${fcsr.modelDefRef.modelName}${splitter}${fcsr.propertyName}`;
  };

  const parseName = (controlName: string): IFieldCfgScreenReference => {
    const elements = controlName.split(splitter);
    let retVal: IFieldCfgScreenReference = {
      modelDefRef: { modelName: "", context: "catalogue" },
      propertyName: "",
      ordinalPosition: -1,
    };

    //probably change this to 'find' the screen ref using this data from the groupContainer....
    if (elements.length === 3) {
      retVal.modelDefRef.context = elements[0] as modelContext;
      retVal.modelDefRef.modelName = elements[1];
      retVal.propertyName = elements[2];
      //we arent passing back the ordinal position here which is why i think we need to find it in the groupContainer. depends how/when we use it
    }

    return retVal;
  };

  const handleInputChange = (event: any) => {
    const fldCfgSr = parseName(event.target.name);
    const [, , data] = getElementsForScreenReference(
      modelConfigSet,
      modelDefs,
      modelDataArray,
      fldCfgSr
    );
    var allButEditing = modelDataArray.filter(
      (r) =>
        !(
          r.modelReference.context === data.modelReference.context &&
          r.modelReference.modelName === data.modelReference.modelName
        )
    );
    var record = data.records[0];
    var val = hasOwnProperty(event.target.value, "key")
      ? event.target.value.key
      : event.target.value;
    data.records[0] = {
      ...record,
      [fldCfgSr.propertyName]: val,
    };
    allButEditing.push(data);
    setModelDataArray(allButEditing);
  };

  return (
    <Fragment>
      <PanelBar>
        {modelConfigSet.groupContainers
          .sort((a, b) => a.ordinalPosition - b.ordinalPosition)
          .map((grp: IGroupContainerLayout) => {
            return (
              <GroupPanelWrapper
                currentItem={{
                  name: grp.groupContainerName,
                  ordinalPosition: grp.ordinalPosition,
                }}
                expanded={true}
                title={<PanelBarTitle title={grp.groupContainerName} />}
                moveItem={moveItem}
              >
                {getIRowFieldsForGroupContainer(grp).map(
                  (rowFields: IRowFields) => (
                    <div className="form-row">
                      {rowFields.fields.map(
                        (fldCfgSr: IFieldCfgScreenReference) => {
                          const [fldCfg, fldDef, data] =
                            getElementsForScreenReference(
                              modelConfigSet,
                              modelDefs,
                              modelDataArray,
                              fldCfgSr
                            );
                          //eventually handle collections of data, then we need a key on the records.
                          const record = data.records[0];

                          if (fldCfg.isVisibile && fldDef) {
                            switch (fldDef.dataType) {
                              case "string":
                              case "number":
                                let lupCfg: ILookupConfig | undefined =
                                  undefined;
                                if (
                                  fldCfg.lookupName &&
                                  configureLookup !== undefined
                                ) {
                                  lupCfg = configureLookup(fldCfgSr, record);
                                }

                                if (lupCfg && lupCfg.data) {
                                  return (
                                    <AmsDropDownList
                                      name={generateName(fldCfgSr)}
                                      label={fldDef.labelName}
                                      data={lupCfg.data}
                                      textField={lupCfg.textField}
                                      dataItemKey={lupCfg.dataItemKey}
                                      value={lupCfg.value}
                                      onChange={handleInputChange}
                                    />
                                  );
                                } else {
                                  return (
                                    <AmsTextInput
                                      name={generateName(fldCfgSr)}
                                      label={fldDef.labelName}
                                      value={getProperty(
                                        record,
                                        fldDef.propertyName
                                      )}
                                      isMandatory={fldDef.isMandatory}
                                      disabled={
                                        fldCfg.isReadonly || designerMode
                                      }
                                      onChange={handleInputChange}
                                    />
                                  );
                                }

                              case "date":
                                return (
                                  <AmsDateInput
                                    name={generateName(fldCfgSr)}
                                    label={fldDef.labelName}
                                    value={
                                      new Date(
                                        getProperty(record, fldDef.propertyName)
                                      )
                                    }
                                    onChange={handleInputChange}
                                    disabled={fldCfg.isReadonly || designerMode}
                                  />
                                );
                              case "boolean":
                                return (
                                  <AmsSwitchInput
                                    name={generateName(fldCfgSr)}
                                    label={fldDef.labelName}
                                    checked={getProperty(
                                      record,
                                      fldDef.propertyName
                                    )}
                                    onChange={handleInputChange}
                                    disabled={fldCfg.isReadonly || designerMode}
                                  />
                                );
                              default:
                                return null;
                            }
                          } else {
                            return null;
                          }
                        }
                      )}
                    </div>
                  )
                )}
              </GroupPanelWrapper>
            );
          })}
      </PanelBar>
    </Fragment>
  );
};

export default EntityDataDisplay;

EntityDataDisplay.defaultProps = {
  designerMode: false,
};
