/* eslint-disable react-hooks/exhaustive-deps */
import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { getKeywordById } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/api";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import { BubbleUpType, SiteUser } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useIsNew } from "@common/hooks/useIsNew";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import {
  convertBreakLineString,
  getOptionInputPickerActionType,
} from "@common/pages/actions/[id]/components/child-screens/general/components/form-element/util";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { useActionDetailTabStore } from "@common/pages/actions/[id]/components/reference-sidebar/details/store";
import { ACTIONSTATUS, Svc_ActionType } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import {
  getActionsTypeDetail,
  getCalculateTargetDate,
} from "@common/pages/actions/api";
import {
  Action,
  ActionUpdateTriggers,
  dropdownStatusDataWithEnumeratedID,
  dropdownStatusDataWithoutEnumeratedID,
} from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { GetMappedProduct } from "@common/utils/mapping";
import {
  sanitizeHtml,
  sanitizeHtmlWithConfigs,
  sanitizeWithoutParse,
} from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { isHTML, isHTMLWithAllTag } from "@components/cc-input-picker/util";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCShadowRootWrapper } from "@components/cc-shadow-root-wrapper/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Action>();
const nameOfActionType = nameOfFactory<Svc_ActionType>();

export const ActionFormElement = observer(({ formRenderProps }: IProps) => {
  const { pushNotification } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const { onChange, valueGetter } = formRenderProps;
  const {
    action,
    ancestor,
    parent,
    pprActionOutcome,
    assessmentActionOutcome,
    actionDirection,
    setIsMeeting,
    isInactive,
    defaultOfficer,
    setIsLoadingAction,
  } = useActionStore();
  const { actionBubbleUpParent, actionBubbleUpAncestor } =
    useActionDetailTabStore();
  const [siteAddress, setSiteAddress] = useState("");
  const [recordBy, setRecordBy] = useState(action?.RecordedBy?.DisplayName);
  const [actionBy, setActionBy] = useState(action?.ActionOfficer?.DisplayName);
  const { settings } = useCommonCoreStore();
  const [statusDataList, setStatusDataList] = useState<IKeyValuePacket[]>();

  const isShowActionRequired = useMemo(() => {
    if (valueGetter("ActionType.Flag_IsMeeting")) return false;
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsInformationSharing")
    )
      return false;
    return true;
  }, [valueGetter("ActionType")]);

  const isAllFieldDisable = useMemo(() => {
    return (
      isInactive ||
      (action?.Status_ENUM === ACTIONSTATUS.COMPLETE && !isShowActionRequired)
    );
  }, [isInactive]);
  const ActionField = useCallback(
    (props: FieldProps) => {
      const { disabled, ...others } = props;
      return <Field {...others} disabled={isAllFieldDisable} />;
    },
    [isInactive]
  );

  const handleOnPickActionType = async (value: IRespActionType | null) => {
    if (!value) {
      onChange(nameOf("ActionType"), {
        value: value,
      });
      onChange(nameOf("ActionType_ID"), {
        value: value,
      });
      onChange(nameOf("Description"), {
        value: "",
      });
      setIsMeeting(false);
      onChange(nameOf("ActionTypeTags_CSV"), {
        value: value,
      });
      onChange(nameOf("Status_ENUM"), {
        value: ACTIONSTATUS.SystemInitialise,
      });
      return;
    }
    setIsLoadingAction(true);
    const response = await getActionsTypeDetail(value.ID);
    if (isSuccessResponse(response)) {
      if (response.data) {
        onChange(nameOf("ActionType"), {
          value: response.data,
        });
        onChange(nameOf("ActionType_ID"), {
          value: value.ID,
        });
        onChange(nameOf("Description"), {
          value: response.data?.Description ?? "",
        });
        if (
          response.data?.DefaultActionOfficer &&
          response.data?.DefaultActionOfficer_ID
        ) {
          setActionBy(response.data.DefaultActionOfficer.DisplayName);
          onChange(nameOf("ActionOfficer"), {
            value: response.data?.DefaultActionOfficer,
          });
          onChange(nameOf("ActionOfficer_ID"), {
            value: response.data?.DefaultActionOfficer_ID,
          });
        }

        setIsMeeting(response.data.Flag_IsMeeting);

        if (
          response.data.Flag_IsMeeting ||
          response.data.Flag_IsReferral ||
          response.data.IsApproval
        ) {
          onChange(nameOf("ActionRequired"), {
            value: true,
          });
        } else {
          onChange(nameOf("ActionRequired"), {
            value: response.data.ActionRequired,
          });
        }
        onChange(nameOf("ActionTypeTags_CSV"), {
          value: response.data.ActionTypeTags_CSV,
        });
        onChange(nameOf("Status_ENUM"), {
          value: ACTIONSTATUS.NEW,
        });

        // Calculate target date
        const crrDate = new Date();
        if (value?.TargetDays) {
          const dateRecorded = encodeURIComponent(crrDate.toISOString());
          const targetDateResponse = await getCalculateTargetDate(
            dateRecorded,
            value.TargetDays,
            response.data.Include_Weekend_Days
          );
          if (
            isSuccessResponse(targetDateResponse) &&
            targetDateResponse.data
          ) {
            onChange(nameOf("DateTarget"), {
              value: targetDateResponse.data,
            });
          } else {
            pushNotification({
              title: "Calculating target date failed",
              type: "error",
              autoClose: false,
            });
          }
        } else {
          onChange(nameOf("DateTarget"), {
            value: crrDate,
          });
        }
      }
    } else {
      pushNotification({
        title: "Action type is not found",
        type: "error",
        autoClose: false,
      });
    }

    setIsLoadingAction(false);
  };

  const handleOnPickOfficer = async (value: SiteUser) => {
    if (value === null) {
      setActionBy(undefined);
      onChange(nameOf("ActionOfficer"), {
        value: value,
      });
      onChange(nameOf("ActionOfficer_ID"), {
        value: value,
      });
      return;
    }
    const response = await getSiteUser(value.Contact_ID);
    if (isSuccessResponse(response) && response?.data) {
      setActionBy(response.data.FirstName + " " + response.data.LastName);
      onChange(nameOf("ActionOfficer"), {
        value: value,
      });
      onChange(nameOf("ActionOfficer_ID"), {
        value: response.data.Contact_ID,
      });
    } else {
      pushNotification({
        title: "Officer is not found",
        type: "error",
        autoClose: false,
      });
    }
  };

  const handleOnPickRecordBy = async (value: SiteUser) => {
    if (value === null) {
      setRecordBy(undefined);
      onChange(nameOf("RecordedBy"), {
        value: value,
      });
      onChange(nameOf("RecordedBy_ID"), {
        value: value,
      });
      return;
    }

    const response = await getSiteUser(value.Contact_ID);
    if (isSuccessResponse(response) && response?.data) {
      setRecordBy(response.data.FirstName + " " + response.data.LastName);
      onChange(nameOf("RecordedBy"), {
        value: value,
      });
      onChange(nameOf("RecordedBy_ID"), {
        value: value.Contact_ID,
      });
    } else {
      pushNotification({
        title: "RecordBy is not found",
        type: "error",
        autoClose: false,
      });
    }
  };

  const onPickCouncillors = async (value: SiteUser) => {
    if (!value) {
      setRecordBy(undefined);
      onChange(nameOf("Councillors"), {
        value: null,
      });
      return;
    }
    onChange(nameOf("Councillors"), {
      value: value.FirstName + " " + value.LastName,
    });
  };

  // ------ Handle logic section ------
  // Common
  const updateSaveTriggers = (triggers: ActionUpdateTriggers) => {
    let saveTriggers: ActionUpdateTriggers[] =
      valueGetter("_SaveTriggers") ?? [];

    if (!Array.isArray(saveTriggers)) saveTriggers = [];

    if (!saveTriggers?.some((item) => item === triggers)) {
      saveTriggers?.push(triggers);
      onChange(nameOf("_SaveTriggers"), {
        value: saveTriggers,
      });
    }
  };
  const handleOnChangeApplicant = (event: ComboBoxChangeEvent) => {
    const value = event.value;
    onChange(nameOf("ExternalActionOfficer_RID"), { value: null });
    onChange(nameOf("ExternalActionOfficer"), {
      value: {
        ContactRelationshipType_ENUM: ContactRelationshipType.Applicant,
        Contact: value,
      },
    });
    updateSaveTriggers(ActionUpdateTriggers.UpdateExternalContact);
  };

  const onChangeDateTarget = useCallback(
    (event: DatePickerChangeEvent) => {
      onChange(nameOf("DateTarget"), { value: event.value });
    },
    [onChange]
  );

  const onChangeDateSend = useCallback(
    (event: DatePickerChangeEvent) => {
      onChange(nameOf("DateSent"), { value: event.value });
    },
    [onChange]
  );

  const getStatusList = async () => {
    const keyValue = action?.InformationSharing_Direction_KWD;

    if (keyValue) {
      const response = await getKeywordById(keyValue);
      if (isSuccessResponse(response) && response.data) {
        if (response.data != null && response.data.Enumerated_ID === 1) {
          setStatusDataList(dropdownStatusDataWithEnumeratedID);
        } else setStatusDataList(dropdownStatusDataWithoutEnumeratedID);
      } else {
        pushNotification({
          title: response?.error ?? "Load status list fail",
          type: "error",
          autoClose: false,
        });
      }
    }
  };

  const getDefaultOfficer = async () => {
    if (defaultOfficer && !action?.ActionOfficer?.DisplayName) {
      setActionBy(defaultOfficer?.FirstName + " " + defaultOfficer?.LastName);
      onChange(nameOf("ActionOfficer"), {
        value: defaultOfficer,
      });
      onChange(nameOf("ActionOfficer_ID"), {
        value: defaultOfficer?.Contact_ID,
      });
    }
  };

  const getDefaultRecordedBy = async () => {
    if (defaultOfficer && !action?.RecordedBy_ID) {
      setRecordBy(defaultOfficer?.FirstName + " " + defaultOfficer?.LastName);
      onChange(nameOf("RecordedBy"), {
        value: defaultOfficer,
      });
      onChange(nameOf("RecordedBy_ID"), {
        value: defaultOfficer?.Contact_ID,
      });
    }
  };

  const isRegulatoryAction = useMemo(() => {
    return (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsRegulatoryAction") &&
      valueGetter("ActionType.IsRegulatoryAction") === true
    );
  }, [valueGetter("ActionType")]);
  const isAssessment = useMemo(() => {
    return (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsAssessment") &&
      valueGetter("ActionType.IsAssessment") === true
    );
  }, [valueGetter("ActionType")]);
  const isConciliationAction = useMemo(() => {
    return (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsConciliation") &&
      valueGetter("ActionType.IsConciliation") === true
    );
  }, [valueGetter("ActionType")]);
  const isShowAddress = useMemo(() => {
    if (valueGetter("ActionType.DefaultLocation_KWD")) {
      const keyValue = action?.ActionType?.DefaultLocation_KWD;
      if (action?.ActionType != null && keyValue) {
        getKeywordById(keyValue).then((response) => {
          if (isSuccessResponse(response) && response.data) {
            setSiteAddress(response.data.Name);
            return (
              response.data.Name &&
              response.data.Keyword_ID ===
                action?.ActionType?.DefaultLocation_KWD
            );
          } else {
            pushNotification({
              title: response?.error,
              type: "error",
              autoClose: false,
            });
          }
        });
      }
    }
    return false;
  }, [valueGetter("ActionType"), pushNotification]);
  const isShowOutCome = useMemo(() => {
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_HasOutcome") &&
      valueGetter("ActionType.Flag_IsMeeting") === false
    )
      return true;
    if (
      (isRegulatoryAction || isConciliationAction || isAssessment) &&
      valueGetter("ActionType.Flag_HasOutcome")
    )
      return true;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowCommunicationDetails = useMemo(() => {
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsInformationSharing")
    )
      return false;
    if (isNew) return false;
    let value = valueGetter("Description");
    if (value) {
      const result = isHTML(value);
      return result;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType"), valueGetter("Description")]);

  const isDetailContainHTML = useMemo(() => {
    const value = valueGetter("Description");
    if (value) {
      return isHTMLWithAllTag(value) || isHTML(value);
    }
    return false;
  }, [valueGetter("Description")]);

  const isShowDetailField = useMemo(() => {
    if (
      !getBoolValueSetting(
        settings[
          ECorporateSettingsField
            .CorporateSettings_Action_ShowDetailsForMeetings
        ]
      ) &&
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_IsMeeting")
    )
      return false;
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsInformationSharing")
    )
      return false;

    // Apply this logic for only existed page
    if (
      !isNew &&
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.ActionType_ID") ===
        getNumberValueSetting(
          settings[ECorporateSettingsField.Global_Communication_ActionType]
        )
    ) {
      return false;
    }
    if (!isNew && sanitizeWithoutParse(valueGetter("Description"))) {
      let value = sanitizeWithoutParse(valueGetter("Description"));
      const result = isHTML(value);
      if (result) return false;
    }
    if (isAssessment) return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valueGetter("ActionType"),
    actionBubbleUpParent,
    actionBubbleUpAncestor,
    parent,
  ]);
  const isShowCouncillor = useMemo(() => {
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_IsMeeting") &&
      valueGetter("ActionType.Name").toUpperCase() === "CONSULTATIVE"
    )
      return true;
    if (
      (valueGetter("ActionType") != null &&
        valueGetter("ActionType.IsInformationSharing")) ||
      isRegulatoryAction ||
      isConciliationAction ||
      isAssessment
    )
      return false;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowReferralFields = useMemo(() => {
    if (
      isAssessment ||
      isRegulatoryAction ||
      isConciliationAction ||
      (valueGetter("ActionType") != null &&
        valueGetter("ActionType.IsInformationSharing") &&
        valueGetter("ActionType.IsInformationSharing"))
    )
      return false;
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_IsReferral")
    )
      return true;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowInformationSharing = useMemo(() => {
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsInformationSharing")
    )
      return true;
    if (isRegulatoryAction || isConciliationAction || isAssessment)
      return false;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowClosureDetails = useMemo(() => {
    if (
      valueGetter("Status_ENUM") === ACTIONSTATUS.COMPLETE ||
      valueGetter("Status_ENUM") === ACTIONSTATUS.APPROVED ||
      valueGetter("Status_ENUM") === ACTIONSTATUS.NOTAPPROVED
    ) {
      if (valueGetter("ActionRequired") === true) {
        return true;
      }
      return false;
    }
    if (
      (valueGetter("ActionType") != null &&
        valueGetter("ActionType.IsInformationSharing")) ||
      isConciliationAction
    )
      return false;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType"), valueGetter("ActionRequired")]);

  const isDisabled =
    isInactive ||
    (valueGetter(nameOf("Status_ENUM")) === ACTIONSTATUS.COMPLETE &&
      !isShowActionRequired);

  const isShowExternalContact = useMemo(() => {
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_ExternalOfficer")
    )
      return true;
    if (
      (valueGetter("ActionType") != null &&
        valueGetter("ActionType.IsInformationSharing")) ||
      isRegulatoryAction ||
      isConciliationAction ||
      isAssessment
    )
      return false;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowDateRecorded = useMemo(() => {
    if (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_IsMeeting")
    )
      return false;
    if (
      valueGetter("ActionType") != null &&
      (valueGetter("ActionType.IsInformationSharing") ||
        valueGetter("ActionType..Flag_IsMeeting"))
    )
      return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowDateSent = useMemo(() => {
    if (
      (valueGetter("ActionType") != null &&
        valueGetter("ActionType.Flag_IsMeeting")) ||
      isRegulatoryAction ||
      isConciliationAction ||
      isAssessment
    )
      return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowDue = useMemo(() => {
    if (
      valueGetter("ActionRequired") === true ||
      valueGetter("ActionType.Flag_IsMeeting") === true
    )
      return true;
    if (isRegulatoryAction || isConciliationAction || isAssessment)
      return false;
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionRequired"), valueGetter("ActionType")]);
  const isShowTag = useMemo(() => {
    if (
      (valueGetter("ActionType") != null &&
        valueGetter("ActionType.IsInformationSharing")) ||
      isRegulatoryAction ||
      isConciliationAction ||
      isAssessment
    )
      return false;
    return getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_ActionType_Tags]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType"), settings]);
  const isShowMeetingLocation = useMemo(() => {
    return (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_HasOutcome") &&
      valueGetter("ActionType.Flag_IsMeeting") === true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowOutComeNotes = useMemo(() => {
    return (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.Flag_HasOutcome") &&
      valueGetter("ActionType.isOutcomeNotes")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);
  const isShowAssessmentOutcome = useMemo(() => {
    return (
      valueGetter("ActionType") != null &&
      valueGetter("ActionType.IsAssessment") &&
      valueGetter("ActionType.IsAssessment") === true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter("ActionType")]);

  const productTypeId = useMemo(() => {
    if (isNew && ancestor) {
      return GetMappedProduct(
        ancestor.RecordSourceIdentifier_Ancestor._RecordSourceType_ENUM?.toString()
      );
    }
    if (action) {
      const ancestor = action?.BubbleUps?.find(
        (bubbleUp) => bubbleUp.BubbleUpType_ENUM === BubbleUpType.Ancestor
      );
      if (ancestor) {
        return GetMappedProduct(
          ancestor.SourceIdentifier._RecordSourceType_ENUM
        );
      }
    }
    return undefined;
  }, [ancestor, action]);

  useEffect(() => {
    setActionBy(action?.ActionOfficer?.DisplayName);
    setRecordBy(action?.RecordedBy?.DisplayName);
    if (isNew) {
      onChange(nameOf("DateRecorded"), {
        value: new Date(),
      });
      onChange(nameOf("ActionRequired"), {
        value: false,
      });
      getDefaultOfficer();
      getDefaultRecordedBy();
      if (parent?.actionType) {
        handleOnPickActionType(parent.actionType);
      }
    }
  }, [action, defaultOfficer]);

  useEffectOnce(() => {
    getStatusList();
  });

  return (
    <FormElement>
      {ancestor && ancestor.lblParent1 !== "" && (
        <>
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <CCValueField
                label={ancestor.lblParent1}
                value={sanitizeHtml(ancestor.litParent1)}
              />
              <CCValueField
                label={ancestor.lblParent2}
                value={sanitizeHtml(ancestor.litParent2)}
              />
              <CCValueField
                label={ancestor.lblParent3}
                value={sanitizeHtml(ancestor.litParent3)}
              />
            </div>
          </section>
          <hr className="cc-divider" />
        </>
      )}

      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Action type
              <CCTooltip type="validator" position="right" />
            </label>
            <ActionField
              placeholder="Type here to find your action type..."
              name={nameOf("ActionType")}
              nameDisplay={nameOfActionType("Name")}
              component={InputPickerSearch}
              valueMapGrid={valueGetter(nameOf("ActionType_ID"))}
              validator={requiredValidator}
              onChange={handleOnPickActionType}
              options={getOptionInputPickerActionType(productTypeId)}
              onError={(error: any) => {
                pushNotification({
                  title: error,
                  type: "warning",
                });
              }}
              isLazyLoadOData
            />
          </div>

          {isShowOutCome && (
            <div className="cc-field">
              <label className="cc-label">
                Outcome{" "}
                {!valueGetter("ActionType.IsShowCauseReq") && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <ActionField
                name={nameOf("ActionOutcome_KWD")}
                component={CCSearchComboBox}
                data={pprActionOutcome ?? []}
                textField="Name"
                dataItemKey="Keyword_ID"
                validator={requiredValidator}
                isUseDefaultOnchange
              />
            </div>
          )}

          {isShowAssessmentOutcome && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Outcome
                  {valueGetter("ActionType.AssessmentAction_Status_ENUM") ===
                    ACTIONSTATUS.COMPLETE && (
                    <CCTooltip type="validator" position="right" />
                  )}
                </label>
                <ActionField
                  name={nameOf("AssessmentAction_Outcome_KWD")}
                  component={CCSearchComboBox}
                  data={assessmentActionOutcome ?? []}
                  textField="Name"
                  dataItemKey="Keyword_ID"
                  isUseDefaultOnchange
                  validator={
                    valueGetter("ActionType.AssessmentAction_Status_ENUM") ===
                    ACTIONSTATUS.COMPLETE
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Assessment type</label>
                <ActionField
                  name={nameOf("AssessmentAction_Type_Name")}
                  value={valueGetter("AssessmentAction_Type_Name")}
                  component={CCSearchComboBox}
                  data={[]}
                  textField="Value"
                  dataItemKey="Key"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Status</label>
                <ActionField
                  name={nameOf("AssessmentAction_Status_Name")}
                  value={valueGetter("AssessmentAction_Status_Name")}
                  component={CCSearchComboBox}
                  data={[]}
                  textField="Value"
                  dataItemKey="Key"
                />
              </div>
            </div>
          )}

          {isConciliationAction && (
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <ActionField
                name={nameOf("Status_ENUM")}
                value={valueGetter("Status_ENUM")}
                component={CCSearchComboBox}
                data={[]}
                textField="Value"
                dataItemKey="Key"
                validator={requiredValidator}
              />
            </div>
          )}

          {isShowMeetingLocation && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Location
                  <CCTooltip type="validator" position="right" />
                </label>
                <ActionField
                  name={nameOf("ActionOutcome_KWD")}
                  component={CCSearchComboBox}
                  data={pprActionOutcome ?? []}
                  textField="Name"
                  dataItemKey="Keyword_ID"
                  value={getDropdownValue(
                    valueGetter(nameOf("ActionOutcome_KWD")),
                    pprActionOutcome ??
                      valueGetter("ActionType.DefaultLocation_KWD"),
                    "Keyword_ID"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange("ActionOutcome_KWD", {
                      value: event.value?.Keyword_ID ?? null,
                    });
                  }}
                />
              </div>
              {isShowAddress && (
                <>
                  <CCValueField label={siteAddress} />
                  <CCValueField
                    label="Address"
                    value={ancestor ? sanitizeHtml(ancestor.litParent1) : ""}
                  />
                </>
              )}
            </div>
          )}

          {isShowDetailField && (
            <div className="cc-field">
              <label className="cc-label">
                Details
                <CCTooltip type="validator" position="right" />
              </label>
              {isDisabled ? (
                <div className="cc-details-description">
                  <pre className="cc-details-description-pre">
                    {valueGetter("Description")}
                  </pre>
                </div>
              ) : (
                <>
                  <ActionField
                    name={nameOf("Description")}
                    component={CCTextArea}
                    rows={8}
                    validator={requiredValidator}
                  />
                  {isDetailContainHTML ? (
                    <div className="cc-html-warning">
                      All HTML tag will be removed
                    </div>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
        {isShowOutComeNotes && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Outcome notes</label>
              <ActionField
                name={nameOf("OutcomeNotes")}
                rows={8}
                value={valueGetter("OutcomeNotes")}
                component={CCTextArea}
              />
            </div>
          </div>
        )}

        {isShowCommunicationDetails && (
          <div className="cc-form-cols-1">
            <div className="cc-field cc-communication-details">
              <label className="cc-label">Communication details</label>
              <CCShadowRootWrapper key={valueGetter("Description")}>
                {sanitizeHtmlWithConfigs(
                  convertBreakLineString(valueGetter("Description")),
                  // FORCE_BODY to keep the style in <head>
                  { FORCE_BODY: true }
                )}
              </CCShadowRootWrapper>
            </div>
          </div>
        )}

        {isShowCouncillor && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Councillor</label>
              <ActionField
                name={nameOf("Councillors")}
                component={InputPickerSearch}
                keyMapGrid={"Name"} // Will remove after API return correct data (has Councillor_ID)
                valueMapGrid={valueGetter(nameOf("Councillors"))}
                options={optionInputPickerOfficer}
                placeholder={"Councillors"}
                onChange={onPickCouncillors}
              />
            </div>
          </div>
        )}
        {isShowReferralFields && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Referral trigger</label>
              <ActionField
                name={"ReferralTrigger_Keyword"}
                placeholder={"Referral Trigger"}
                component={KeyWordsPicker}
                keyWordType={KEYWORD_TYPE.Core_ReferralTrigger}
                productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                onPickKeyWords={(value: CoreKeyword[]) => {
                  onChange("ReferralTrigger_Keyword", {
                    value: value?.map((item) => {
                      return { Key: item?.Keyword_ID, Value: item?.Name };
                    }),
                  });
                }}
                isGetDetail={false}
                textProduce={(data: IKeyValuePacket[]) =>
                  data
                    .reduce((pre, cur) => pre + ", " + cur.Value, "")
                    .slice(1)
                    .trim()
                }
                selectableMode={"multiple"}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Info attached</label>
              <ActionField
                name={"InfoAttached_Keyword"}
                placeholder={"Information Attached"}
                component={KeyWordsPicker}
                keyWordType={KEYWORD_TYPE.Core_ReferralInfoAttached}
                productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                onPickKeyWords={(value: CoreKeyword[]) => {
                  onChange("InfoAttached_Keyword", {
                    value: value?.map((item) => {
                      return { Key: item?.Keyword_ID, Value: item?.Name };
                    }),
                  });
                }}
                isGetDetail={false}
                onError={(error: any) => {
                  pushNotification({
                    title: error,
                    type: "warning",
                  });
                }}
              />
            </div>
            {valueGetter("ActionType.Policy_Keyword") ?? (
              <div className="cc-field">
                <label className="cc-label">Policy</label>
                <ActionField
                  name={"Policy_Keyword"}
                  placeholder={"Policy"}
                  component={KeyWordsPicker}
                  keyWordType={KEYWORD_TYPE.Core_ReferralPolicy}
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  onPickKeyWords={(value: CoreKeyword[]) => {
                    onChange("Policy_Keyword", {
                      value: value?.map((item) => {
                        return { Key: item?.Keyword_ID, Value: item?.Name };
                      }),
                    });
                  }}
                  isGetDetail={false}
                  onError={(error: any) => {
                    pushNotification({
                      title: error,
                      type: "warning",
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
        <br />
        <hr className="cc-divider" />
        <br />
      </div>

      {isShowInformationSharing && (
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Direction
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={nameOf("InformationSharing_Direction_KWD")}
                component={CCSearchComboBox}
                data={actionDirection ?? []}
                textField="Name"
                dataItemKey="Keyword_ID"
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Status
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={nameOf("Status_ENUM")}
                textField="Value"
                dataItemKey="Key"
                component={CCSearchComboBox}
                placeholder={"Status"}
                data={statusDataList ?? []}
                isUseDefaultOnchange
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                From/To
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={nameOf("InformationSharing_FromTo_Name")}
                component={CCSearchComboBox}
                placeholder={"Direction"}
                data={[]}
                textField="Value"
                dataItemKey="Key"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Type
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={nameOf("InformationSharing_FromTo_KWD")}
                component={CCSearchComboBox}
                placeholder={"Status"}
                data={[]}
                textField="Value"
                dataItemKey="Key"
              />
            </div>

            {/* divOtherFromTo */}
            <div className="cc-field">
              <label className="cc-label">
                Other
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={nameOf("InformationSharing_FromTo_Name")}
                component={CCTextArea}
              />
            </div>
          </div>
          <br />
        </div>
      )}

      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          {isShowActionRequired && (
            <div className="cc-field">
              <label className="cc-label">
                Action required
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("ActionRequired")}
                component={CCSwitch}
                checked={valueGetter(nameOf("ActionRequired"))}
                disabled={
                  !isNew ||
                  isInactive ||
                  valueGetter("ActionType.Flag_IsReferral") ||
                  action?.Status_ENUM === ACTIONSTATUS.COMPLETE
                }
              />
            </div>
          )}

          <div className="cc-field">
            <label className="cc-label">
              {valueGetter("ActionType.Flag_IsMeeting")
                ? "Officer"
                : "Action by"}
              <CCTooltip type="validator" position="right" />
            </label>
            <ActionField
              name={nameOf("ActionOfficer")}
              value={actionBy}
              component={InputPickerSearch}
              valueMapGrid={valueGetter(nameOf("ActionOfficer_ID"))}
              placeholder={
                valueGetter("ActionType.Flag_IsMeeting")
                  ? "Officer"
                  : "Action by"
              }
              onChange={handleOnPickOfficer}
              options={optionInputPickerOfficer}
              validator={requiredValidator}
            />
          </div>

          {isShowExternalContact && (
            <div className="cc-field">
              <label className="cc-label">
                {valueGetter("ActionType.Flag_IsReferral")
                  ? "Referred to"
                  : "External contact"}
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={"ExternalActionOfficer.Contact"}
                component={ContactPicker}
                validator={requiredValidator}
                onChange={handleOnChangeApplicant}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick Contact Errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                disabledButton
              />
            </div>
          )}
        </div>

        {isShowDateRecorded && (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Recorded by
                <CCTooltip type="validator" position="right" />
              </label>
              <ActionField
                name={nameOf("RecordedBy")}
                value={recordBy}
                component={InputPickerSearch}
                valueMapGrid={valueGetter(nameOf("RecordedBy_ID"))}
                options={optionInputPickerOfficer}
                placeholder={"Recorded by"}
                onChange={handleOnPickRecordBy}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Recorded on"
                format={DATE_FORMAT.DATE}
                value={valueGetter("DateRecorded")}
              />
            </div>
            {isShowDateSent && (
              <div className="cc-field">
                <label className="cc-label">Date sent</label>
                <ActionField
                  name={nameOf("DateSent")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  onChange={onChangeDateSend}
                />
              </div>
            )}
          </div>
        )}

        <div className="cc-form-cols-3">
          {isShowDue && (
            <div className="cc-field">
              {valueGetter("ActionType.Flag_IsMeeting") ? (
                <>
                  {action?.Status_ENUM === ACTIONSTATUS.NEW || isNew ? (
                    <>
                      <label className="cc-label">
                        Meeting date
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <ActionField
                        name={nameOf("DateTarget")}
                        component={CCDateTimePicker}
                        format={DATETIME_FORMAT.DATETIME_CONTROL}
                        validator={requiredValidator}
                        onChange={onChangeDateTarget}
                      />
                    </>
                  ) : (
                    <CCValueField
                      label="Meeting date"
                      value={valueGetter("DateTarget")}
                      format={DATETIME_FORMAT.DATETIME}
                    />
                  )}
                </>
              ) : (
                <>
                  {(action?.Status_ENUM === ACTIONSTATUS.NEW || isNew) &&
                  valueGetter("ActionRequired") ? (
                    <>
                      <label className="cc-label">
                        Due on
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <ActionField
                        name={nameOf("DateTarget")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                        validator={requiredValidator}
                        onChange={onChangeDateTarget}
                      />
                    </>
                  ) : (
                    <CCValueField
                      label="Due on"
                      value={valueGetter("DateTarget")}
                      format={DATE_FORMAT.DATE}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {isShowTag && (
            <CCValueField
              label="Tags"
              value={valueGetter(nameOf("ActionTypeTags_CSV"))}
            />
          )}
        </div>
      </div>
      <br />

      {isShowClosureDetails && (
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              label="Completed on"
              value={action?.DateCompleted}
              format={DATE_FORMAT.DATE}
            />
            <CCValueField
              label="Completed by"
              value={action?.CompletedBy?.DisplayName}
            />
            <CCValueField
              label="Completion details"
              value={action?.ClosureComment}
            />
          </div>
        </div>
      )}
      <br />
    </FormElement>
  );
});
