//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";

export const colHMFSRNoticeTypes = [
  {
    field: HMFieldMapping.Name,
    title: "Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Classification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.FSRType,
    title: "FSR Type",
    width: 150,
  },
  {
    field: HMFieldMapping.FSRCode,
    title: "FSR Code",
    width: 150,
  },
  {
    field: HMFieldMapping.PenaltyUnits,
    title: "Penalty Units",
    width: 150,
  },
  {
    field: HMFieldMapping.Description,
    title: "Description",
    width: 150,
  },
];
