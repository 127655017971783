import { ICCRoute } from "@common/constants/ICCRoute";

export const poolsOrSpasRoute: ICCRoute = {
  path: "pools-or-spas",
  name: "Pools / Spas",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "decommissioned",
      name: "Decommissioned",
      component: require("./decommissioned/_index").default,
    },
    {
      path: "my-schedule",
      name: "My Schedule",
      component: require("./my-schedule/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
