import { IExternalGisMenuItems } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/launch-external-gis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IExternalGisMenuItems>();
export const colExternalGisMenuItems: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Menu Item Name",
  },
  {
    field: nameOf("URL"),
    title: "Url",
    handleOnClick: (dataItem: IExternalGisMenuItems) => {
      if (dataItem?.URL) {
        window.open(dataItem.URL, "_blank");
      }
    },
    isGrow: true,
  },
];

export const processDataLaunchExternalGis = (
  data: IExternalGisMenuItems[]
): string => {
  // Map the data array to a string array
  // Value format define: ExternalMenuItemID|ExternalMenuItemName|URL
  const parts = data.map((item) => `${item.ID}|${item.Name}|${item.URL}`);

  // Join the string array with the delimiter
  const result = parts.join(";");
  return result;
};
