import { V_Assessment } from "@app/products/property/assessments/compliance/[id]/components/forms/existed/components/form-steps/modify-compliance/components/form-elements/compliance/model";
import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<V_Assessment>();
//All titles will be replaced by column name data from view configuration API
export const colModifyComplianceAssessmentRegister: IColumnFields[] = [
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },

  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Levy_Unit_Type"),
    title: "Unit Type",
  },
  {
    field: nameOf("Assess_Unit_Entitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Unit_Percentage"),
    title: "Unit Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Unit_Type_Percentage"),
    title: "Unit Type Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation 1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation 3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation 4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
