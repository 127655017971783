import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE, mapRecordType } from "@common/constants/recordtype";

export const GetMappedProduct = (recordType?: string) => {
  if (!recordType) return PRODUCT_TYPE.Core;
  const recordTypeNumber = mapRecordType?.[recordType];
  switch (recordTypeNumber) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return PRODUCT_TYPE.CustomerService;
    case RECORDTYPE.DisRes_Permit:
      return PRODUCT_TYPE.DISRES;
    case RECORDTYPE.HealthManager_Premises:
      return PRODUCT_TYPE.HealthManager;
    case RECORDTYPE.WW_System:
      return PRODUCT_TYPE.WasteWater;
    case RECORDTYPE.WW_Installation:
    case RECORDTYPE.WW_Scheme:
    case RECORDTYPE.WW_Product:
      return PRODUCT_TYPE.WasteWaterSPI;
    case RECORDTYPE.CRS_Registration:
    case RECORDTYPE.CRS_FourYOKinder:
    case RECORDTYPE.CRS_HomeBasedCare:
    case RECORDTYPE.CRS_ThreeYOKinder:
    case RECORDTYPE.CRS_CentreBasedCare:
      return PRODUCT_TYPE.CRS;
    case RECORDTYPE.Animals_Registration:
    case RECORDTYPE.Animals_Kennel:
    case RECORDTYPE.Animals_Notices:
    case RECORDTYPE.Animals_PoundRegister:
      return PRODUCT_TYPE.Animals;
    case RECORDTYPE.CORE_Register_StreetProtection:
      return PRODUCT_TYPE.StreetProtection;
    case RECORDTYPE.LLP_Permit:
      return PRODUCT_TYPE.LLPermits;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_PPRApplication:
      return PRODUCT_TYPE.TownPlanning;
    case RECORDTYPE.TP_BuildingApplication:
      return PRODUCT_TYPE.Building;
    case RECORDTYPE.TP_DevAppsApplication:
      return PRODUCT_TYPE.DevApps;
    case RECORDTYPE.CSM_Licence:
      return PRODUCT_TYPE.CSM;
    case RECORDTYPE.NSP_Register:
      return PRODUCT_TYPE.NSPR;
    case RECORDTYPE.EMS_Facility:
    case RECORDTYPE.EMS_FacilityFeature:
    case RECORDTYPE.EMS_EventBooking:
      return PRODUCT_TYPE.EventManagementSystem;
    case RECORDTYPE.RAD_Register_Licence:
    case RECORDTYPE.RAD_Register_Place:
    case RECORDTYPE.RAD_Register_Source:
    case RECORDTYPE.RAD_Register_Accreditation:
      return PRODUCT_TYPE.Radiation;
    case RECORDTYPE.DWCMS_Application:
    case RECORDTYPE.DWCMS_Worker:
    case RECORDTYPE.DWCMS_Registration:
      return PRODUCT_TYPE.DWCMS;
    case RECORDTYPE.SRU_Application:
    case RECORDTYPE.SRU_Organisation:
    case RECORDTYPE.SRU_Registration:
    case RECORDTYPE.SRU_Facility:
    case RECORDTYPE.SRU_RegistrationAdministration:
    case RECORDTYPE.SRU_RegistrationStatement:
    case RECORDTYPE.SRU_Infringement:
    case RECORDTYPE.SRU_SuspendAdmission:
    case RECORDTYPE.SRU_RegistrationSanction:
      return PRODUCT_TYPE.StandardsAndRegulation;
    case RECORDTYPE.INFRINGEMENTS_InfringementTicket:
    case RECORDTYPE.INFRINGEMENTS_InfringementOffence:
    case RECORDTYPE.INFRINGEMENTS_InfringementCategory:
    case RECORDTYPE.INFRINGEMENTS_InfringementAnimals:
      return PRODUCT_TYPE.Infringements;
    case RECORDTYPE.TP_LMApplication:
      return PRODUCT_TYPE.LandManagement;
    case RECORDTYPE.CEM_Entry:
    case RECORDTYPE.CEM_Registration:
      return PRODUCT_TYPE.Cemeteries;

    case RECORDTYPE.CommunityProperty_Assessment:
    case RECORDTYPE.CommunityProperty_Parcel:
    case RECORDTYPE.CommunityProperty_Title:
    case RECORDTYPE.CommunityProperty_Assessment_Transaction:
    case RECORDTYPE.CommunityProperty_Certificate:
    case RECORDTYPE.CommunityProperty_Register_Account:
    case RECORDTYPE.CommunityProperty_Supplementary:
    case RECORDTYPE.CommunityProperty_Meter:
    case RECORDTYPE.CommunityProperty_Scheme_Account:
    case RECORDTYPE.CommunityProperty_Certificate_Request:
    case RECORDTYPE.CommunityProperty_Charge:
    case RECORDTYPE.CommunityProperty_Charge_Balances:
    case RECORDTYPE.CommunityProperty_Notice_Group:
    case RECORDTYPE.CommunityProperty_Street_Locality:
    case RECORDTYPE.CommunityProperty_GIS_Selection:
    case RECORDTYPE.CommunityProperty_Certificates_Type:
    case RECORDTYPE.CommunityProperty_Active_Certificates:
    case RECORDTYPE.CommunityProperty_Active_Certificates_for_Responsible_Area:
    case RECORDTYPE.CommunityProperty_Register:
    case RECORDTYPE.CommunityProperty_Master_Property:
    case RECORDTYPE.CommunityProperty_Scheme:
    case RECORDTYPE.CommunityProperty_Meter_Reading_Route:
    case RECORDTYPE.CommunityProperty_Meter_Reading_Device:
    case RECORDTYPE.CommunityProperty_Utility:
    case RECORDTYPE.CommunityProperty_Scheme_Account_Transaction:
    case RECORDTYPE.CommunityProperty_Meter_Installation:
    case RECORDTYPE.CommunityProperty_Register_Account_Transaction:
    case RECORDTYPE.CommunityProperty_Land_Tax_Exemption:
    case RECORDTYPE.CommunityProperty_ACT_Lease_Transfer:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Account:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Scheme:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Transaction:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Instalments:
    case RECORDTYPE.CommunityProperty_Entity:
    case RECORDTYPE.CommunityProperty_Entity_Name_and_Address:
    case RECORDTYPE.CommunityProperty_Assessment_Levy_Id:
    case RECORDTYPE.CommunityProperty_Tasks_Run_Id:
    case RECORDTYPE.CommunityProperty_Tasks_Id:
    case RECORDTYPE.CommunityProperty_Collection:
    case RECORDTYPE.CommunityProperty_MenuItem:
    case RECORDTYPE.CommunityProperty_Journal:
    case RECORDTYPE.CommunityProperty_Advanced_Search:
    case RECORDTYPE.CommunityProperty_Report:
    case RECORDTYPE.CommunityProperty_Home:
    case RECORDTYPE.CommunityProperty_Charge_Instalment:
    case RECORDTYPE.CommunityProperty_Charge_Levy:
    case RECORDTYPE.CommunityProperty_Application_Search_No_Results:
    case RECORDTYPE.CommunityProperty_Session:
    case RECORDTYPE.CommunityProperty_Associated_Name:
    case RECORDTYPE.CommunityProperty_Lease:
    case RECORDTYPE.CommunityProperty_Debt_Recovery:
    case RECORDTYPE.CommunityProperty_Assessment_Rebate_Entitlement:
    case RECORDTYPE.CommunityProperty_Arrangement:
    case RECORDTYPE.CommunityProperty_Change_of_Ownership:
    case RECORDTYPE.CommunityProperty_Supplementary_Assessment:
    case RECORDTYPE.CommunityProperty_Arrangement_Letter:
    case RECORDTYPE.CommunityProperty_Name_and_Address:
    case RECORDTYPE.CommunityProperty_Debt_Recovery_Action:
    case RECORDTYPE.CommunityProperty_Debt_Recovery_PaymentsReceived:
    case RECORDTYPE.CommunityProperty_Debt_Recovery_Exemption:
    case RECORDTYPE.CommunityProperty_Mail_Box:
    case RECORDTYPE.CommunityProperty_Financial_Summary:
    case RECORDTYPE.CommunityProperty_Rebate_Claim:
    case RECORDTYPE.CommunityProperty_Concession_Card:
    case RECORDTYPE.CommunityProperty_Valuation:
    case RECORDTYPE.CommunityProperty_Address:
      return PRODUCT_TYPE.CommunityProperty;
    default:
      return PRODUCT_TYPE.Core; //Core is always included
  }
};

export const GetMappedProductNumber = (recordType?: RECORDTYPE) => {
  if (!recordType) return PRODUCT_TYPE.Core;
  switch (recordType) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return PRODUCT_TYPE_NUMBER.CustomerService;
    case RECORDTYPE.DisRes_Permit:
      return PRODUCT_TYPE_NUMBER.DISRES;
    case RECORDTYPE.HealthManager_Premises:
      return PRODUCT_TYPE_NUMBER.HealthManager;
    case RECORDTYPE.WW_System:
      return PRODUCT_TYPE_NUMBER.WasteWater;
    case RECORDTYPE.WW_Installation:
    case RECORDTYPE.WW_Scheme:
    case RECORDTYPE.WW_Product:
      return PRODUCT_TYPE_NUMBER.WasteWaterSPI;
    case RECORDTYPE.CRS_Registration:
    case RECORDTYPE.CRS_FourYOKinder:
    case RECORDTYPE.CRS_HomeBasedCare:
    case RECORDTYPE.CRS_ThreeYOKinder:
    case RECORDTYPE.CRS_CentreBasedCare:
      return PRODUCT_TYPE_NUMBER.CRS;
    case RECORDTYPE.Animals_Registration:
    case RECORDTYPE.Animals_Kennel:
    case RECORDTYPE.Animals_Notices:
    case RECORDTYPE.Animals_PoundRegister:
      return PRODUCT_TYPE_NUMBER.Animals;
    case RECORDTYPE.CORE_Register_StreetProtection:
      return PRODUCT_TYPE_NUMBER.StreetProtection;
    case RECORDTYPE.LLP_Permit:
      return PRODUCT_TYPE_NUMBER.LLPermits;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_PPRApplication:
      return PRODUCT_TYPE_NUMBER.TownPlanning;
    case RECORDTYPE.TP_BuildingApplication:
      return PRODUCT_TYPE_NUMBER.Building;
    case RECORDTYPE.TP_DevAppsApplication:
      return PRODUCT_TYPE_NUMBER.DevApps;
    case RECORDTYPE.CSM_Licence:
      return PRODUCT_TYPE_NUMBER.CSM;
    case RECORDTYPE.NSP_Register:
      return PRODUCT_TYPE_NUMBER.NSPR;
    case RECORDTYPE.EMS_Facility:
    case RECORDTYPE.EMS_FacilityFeature:
    case RECORDTYPE.EMS_EventBooking:
      return PRODUCT_TYPE_NUMBER.EventManagementSystem;
    case RECORDTYPE.RAD_Register_Licence:
    case RECORDTYPE.RAD_Register_Place:
    case RECORDTYPE.RAD_Register_Source:
    case RECORDTYPE.RAD_Register_Accreditation:
      return PRODUCT_TYPE_NUMBER.Radiation;
    case RECORDTYPE.DWCMS_Application:
    case RECORDTYPE.DWCMS_Worker:
    case RECORDTYPE.DWCMS_Registration:
      return PRODUCT_TYPE_NUMBER.DWCMS;
    case RECORDTYPE.SRU_Application:
    case RECORDTYPE.SRU_Organisation:
    case RECORDTYPE.SRU_Registration:
    case RECORDTYPE.SRU_Facility:
    case RECORDTYPE.SRU_RegistrationAdministration:
    case RECORDTYPE.SRU_RegistrationStatement:
    case RECORDTYPE.SRU_Infringement:
    case RECORDTYPE.SRU_SuspendAdmission:
    case RECORDTYPE.SRU_RegistrationSanction:
      return PRODUCT_TYPE_NUMBER.StandardsAndRegulation;
    case RECORDTYPE.INFRINGEMENTS_InfringementTicket:
    case RECORDTYPE.INFRINGEMENTS_InfringementOffence:
    case RECORDTYPE.INFRINGEMENTS_InfringementCategory:
    case RECORDTYPE.INFRINGEMENTS_InfringementAnimals:
      return PRODUCT_TYPE_NUMBER.Infringements;
    case RECORDTYPE.TP_LMApplication:
      return PRODUCT_TYPE_NUMBER.LandManagement;
    case RECORDTYPE.CEM_Entry:
    case RECORDTYPE.CEM_Registration:
      return PRODUCT_TYPE_NUMBER.Cemeteries;

    case RECORDTYPE.CommunityProperty_Assessment:
    case RECORDTYPE.CommunityProperty_Parcel:
    case RECORDTYPE.CommunityProperty_Title:
    case RECORDTYPE.CommunityProperty_Assessment_Transaction:
    case RECORDTYPE.CommunityProperty_Certificate:
    case RECORDTYPE.CommunityProperty_Register_Account:
    case RECORDTYPE.CommunityProperty_Supplementary:
    case RECORDTYPE.CommunityProperty_Meter:
    case RECORDTYPE.CommunityProperty_Scheme_Account:
    case RECORDTYPE.CommunityProperty_Certificate_Request:
    case RECORDTYPE.CommunityProperty_Charge:
    case RECORDTYPE.CommunityProperty_Charge_Balances:
    case RECORDTYPE.CommunityProperty_Notice_Group:
    case RECORDTYPE.CommunityProperty_Street_Locality:
    case RECORDTYPE.CommunityProperty_GIS_Selection:
    case RECORDTYPE.CommunityProperty_Certificates_Type:
    case RECORDTYPE.CommunityProperty_Active_Certificates:
    case RECORDTYPE.CommunityProperty_Active_Certificates_for_Responsible_Area:
    case RECORDTYPE.CommunityProperty_Register:
    case RECORDTYPE.CommunityProperty_Master_Property:
    case RECORDTYPE.CommunityProperty_Scheme:
    case RECORDTYPE.CommunityProperty_Meter_Reading_Route:
    case RECORDTYPE.CommunityProperty_Meter_Reading_Device:
    case RECORDTYPE.CommunityProperty_Utility:
    case RECORDTYPE.CommunityProperty_Scheme_Account_Transaction:
    case RECORDTYPE.CommunityProperty_Meter_Installation:
    case RECORDTYPE.CommunityProperty_Register_Account_Transaction:
    case RECORDTYPE.CommunityProperty_Land_Tax_Exemption:
    case RECORDTYPE.CommunityProperty_ACT_Lease_Transfer:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Account:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Scheme:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Transaction:
    case RECORDTYPE.CommunityProperty_Deferred_Duty_Instalments:
    case RECORDTYPE.CommunityProperty_Entity:
    case RECORDTYPE.CommunityProperty_Entity_Name_and_Address:
    case RECORDTYPE.CommunityProperty_Assessment_Levy_Id:
    case RECORDTYPE.CommunityProperty_Tasks_Run_Id:
    case RECORDTYPE.CommunityProperty_Tasks_Id:
    case RECORDTYPE.CommunityProperty_Collection:
    case RECORDTYPE.CommunityProperty_MenuItem:
    case RECORDTYPE.CommunityProperty_Journal:
    case RECORDTYPE.CommunityProperty_Advanced_Search:
    case RECORDTYPE.CommunityProperty_Report:
    case RECORDTYPE.CommunityProperty_Home:
    case RECORDTYPE.CommunityProperty_Charge_Instalment:
    case RECORDTYPE.CommunityProperty_Charge_Levy:
    case RECORDTYPE.CommunityProperty_Application_Search_No_Results:
    case RECORDTYPE.CommunityProperty_Session:
    case RECORDTYPE.CommunityProperty_Associated_Name:
    case RECORDTYPE.CommunityProperty_Lease:
    case RECORDTYPE.CommunityProperty_Debt_Recovery:
    case RECORDTYPE.CommunityProperty_Assessment_Rebate_Entitlement:
    case RECORDTYPE.CommunityProperty_Arrangement:
    case RECORDTYPE.CommunityProperty_Change_of_Ownership:
    case RECORDTYPE.CommunityProperty_Supplementary_Assessment:
    case RECORDTYPE.CommunityProperty_Arrangement_Letter:
    case RECORDTYPE.CommunityProperty_Name_and_Address:
    case RECORDTYPE.CommunityProperty_Debt_Recovery_Action:
    case RECORDTYPE.CommunityProperty_Debt_Recovery_PaymentsReceived:
    case RECORDTYPE.CommunityProperty_Debt_Recovery_Exemption:
    case RECORDTYPE.CommunityProperty_Mail_Box:
    case RECORDTYPE.CommunityProperty_Financial_Summary:
    case RECORDTYPE.CommunityProperty_Rebate_Claim:
    case RECORDTYPE.CommunityProperty_Concession_Card:
    case RECORDTYPE.CommunityProperty_Valuation:
    case RECORDTYPE.CommunityProperty_Address:
      return PRODUCT_TYPE_NUMBER.CommunityProperty;
    default:
      return PRODUCT_TYPE_NUMBER.Core;
  }
};
