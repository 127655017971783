import { IProperty } from "@app/products/property/fire-prevention/hazld-case/[id]/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";

const nameOf = nameOfFactory<IProperty>();
export const colProperty = [
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOf("AssessNumber"),
    title: "Assess Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AssessStatus"),
    title: "Assess Status",
  },
  {
    field: nameOf("ParcelId"),
    title: "Parcel ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
