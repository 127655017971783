import { ICCRoute } from "@common/constants/ICCRoute";

export const financeRoute: ICCRoute = {
  path: "finance",
  name: "Finance",
  children: [
    {
      path: "os-fees",
      name: "OS Fees",
      component: require("./os-fees/_index").default,
    },
    {
      path: "all-fees",
      name: "All Fees",
      component: require("./all-fees/_index").default,
    },
    {
      path: "not-sent-to-finance",
      name: "Not Sent to Finance",
      component: require("./not-sent-to-finance/_index").default,
    },
    {
      path: "sent-to-finance",
      name: "Sent to Finance",
      component: require("./sent-to-finance/_index").default,
    },
  ],
};
