import { INewInspectionFormData } from "@app/products/property/fire-prevention/components/dialogs/new-inspection/model";

export const mockNewInspectionData: INewInspectionFormData = {
  Property: [],
  CommentsInPreviousCases: [],
  Date: new Date(),
  LastInspection: "",
  Outcome: "No Hazard",
  OutcomeValues: [
    { Code: "1", Name: "No Hazard" },
    { Code: "2", Name: "Owners to clear property" },
    { Code: "3", Name: "Site to be inspected" },
  ],
  ClearanceValues: [
    { Code: "1", Name: "10m Fire Bleak" },
    { Code: "2", Name: "20m Fire Break" },
    { Code: "3", Name: "30m Fire Break" },
    { Code: "4", Name: "5m Fire Break" },
    { Code: "5", Name: "Case on Hold" },
    { Code: "6", Name: "Clear Entire Property" },
    { Code: "7", Name: "No Hazard" },
    { Code: "8", Name: "Remove All Undergrowth" },
    { Code: "9", Name: "To be assessed" },
    { Code: "10", Name: "Withdrawn" },
  ],
};
