import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const complaintsRoute: ICCRoute = {
  path: "complaints",
  name: "Complaints",
  enum: LLPermitsMenu.Complaints,
  children: [
    {
      path: "event-by-refno",
      name: "By RefNo",
      enum: LLPermitsMenu.EventsByRefNo,
      component: require("./by-ref-no/_index").default,
    },
    {
      path: "event-by-category",
      name: "By Category",
      enum: LLPermitsMenu.AllEventsByCategory,
      component: require("./by-category/_index").default,
    },
    {
      path: "event-by-status",
      name: "By Status",
      enum: LLPermitsMenu.AllEventsByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-date",
      name: "By Date",
      enum: LLPermitsMenu.ComplaintsByDate,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: LLPermitsMenu.ComplaintsByStatus,
      component: require("./by-status/_index").default,
    },
    {
      path: "by-category",
      name: "By Status",
      enum: LLPermitsMenu.ComplaintsByCategory,
      component: require("./by-status/_index").default,
    },
    {
      path: "unrelated",
      name: "Unrelated",
      enum: LLPermitsMenu.ComplaintsByDateUnrelated,
      component: require("./by-status/_index").default,
    },
  ],
};
