import { isShowFieldsPersonStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/util";
import {
  EDeliveryOptions,
  optionIncorporatedBody,
  optionOrganisation,
  optionPartnership,
  optionPerson,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import { unitAddressContact } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/model";
import {
  DTO_Entity_Detail,
  DTO_Entity_PhysicalAddress,
  DTO_Entity_PostalAddress,
  EKeysOfNewContactSteps,
  MODE_CONTACT,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { isEmpty, isNil } from "lodash";

export const checkTypeContact = (value?: number): MODE_CONTACT => {
  if (isNil(value)) return MODE_CONTACT.UNKNOWN;
  if (optionPerson.includes(value)) {
    return MODE_CONTACT.PERSON;
  } else if (optionOrganisation.includes(value)) {
    return MODE_CONTACT.ORGANISATION;
  } else if (optionIncorporatedBody.includes(value)) {
    return MODE_CONTACT.INCORPORATED_BODY;
  } else if (optionPartnership.includes(value)) {
    return MODE_CONTACT.PARTNERSHIP;
  }
  return MODE_CONTACT.UNKNOWN;
};

export const processContactAddresses = (
  deliveryValue: EDeliveryOptions,
  typeValue: number,
  unitType: string,
  floorType: string,
  valueGetter: (name: string) => any,
  getFieldValue: (name: string) => any
) => {
  const addressFieldsPersonStep: Array<keyof DTO_Entity_Detail> = [
    "CareOf",
    "Job_Description",
    "Organisation",
  ];
  const addressValuesPersonStep: string[] = addressFieldsPersonStep.map(
    (addressField: keyof DTO_Entity_Detail) =>
      valueGetter(`${EKeysOfNewContactSteps.Person}.${addressField}`)
  );

  const addressFieldsPhysicalStep: Array<keyof DTO_Entity_PhysicalAddress> = [
    "Unit_Number",
    "Floor_Number",
    "House_Number",
    "Street_Name",
    "Street_Type",
    "Additional_Address",
    "Locality",
    "State",
    "Post_Code",
    "Postal_Delivery_Number",
    "Postal_Delivery_Type",
    "Address",
    "Country",
  ];
  const addressValuesPhysicalStep: string[] = addressFieldsPhysicalStep.map(
    (addressField: keyof DTO_Entity_PhysicalAddress) =>
      getFieldValue(addressField)
  );

  return formatAddresses(
    deliveryValue,
    isShowFieldsPersonStep(typeValue)
      ? valueGetter(`${EKeysOfNewContactSteps.Person}.AttentionOf`)
      : "",
    ...addressValuesPersonStep,
    unitType,
    floorType,
    getFieldValue(
      propertyFieldGroupedByDelivery[deliveryValue as EDeliveryOptions]
    ),
    ...addressValuesPhysicalStep
  );
};

const concatAddresses = (addresses: string[], linkingWord: string = " ") => {
  return addresses.filter((address) => !isEmpty(address)).join(linkingWord);
};

const formatUnitAndFloorAddress = (
  houseNumber: string,
  unitType: string,
  unitNumber: string,
  streetName: string,
  streetType: string,
  floorType: string,
  floorNumber: string
) => {
  let unitGroup = "";
  let floorGroup = "";
  if (floorType || floorNumber) {
    unitGroup = concatAddresses([unitType, unitNumber]);
    if (unitGroup) {
      unitGroup += "\r\n";
    }
    floorGroup = concatAddresses([
      floorType,
      floorNumber,
      houseNumber,
      streetName,
      streetType,
    ]);
  } else {
    if (
      (Object as any).values(unitAddressContact).includes(unitType) &&
      !isEmpty(houseNumber) &&
      !isEmpty(unitNumber)
    ) {
      unitGroup = unitNumber + "/" + houseNumber;
      unitGroup = concatAddresses([unitGroup, streetName, streetType]);
    } else {
      unitGroup = concatAddresses([
        unitType,
        unitNumber,
        houseNumber,
        streetName,
        streetType,
      ]);
    }
  }
  return concatAddresses([unitGroup, floorGroup], "");
};

const formatAddresses = (
  deliveryValue: EDeliveryOptions,
  attentionOf: string = "",
  careOf: string = "",
  jobDescription: string = "",
  organisation: string = "",
  unitType: string = "",
  floorType: string = "",
  propertyName: string = "",
  unitNumber: string = "",
  floorNumber: string = "",
  houseNumber: string = "",
  streetName: string = "",
  streetType: string = "",
  additionalAddress: string = "",
  locality: string = "",
  state: string = "",
  postCode: string = "",
  postalDeliveryNumber: string = "",
  postalDeliveryType: string = "",
  address: string = "",
  country: string = ""
) => {
  const attentionOfFormatted = attentionOf ? `Attn: ${attentionOf}` : "";
  const careOfFormatted = careOf ? `c/- ${careOf}` : "";
  switch (deliveryValue) {
    case EDeliveryOptions.STANDARD: {
      const unitAndFloorGroup = formatUnitAndFloorAddress(
        houseNumber,
        unitType,
        unitNumber,
        streetName,
        streetType,
        floorType,
        floorNumber
      );
      const localityGroup = concatAddresses([locality, state, postCode]);
      const addresses = [
        attentionOfFormatted,
        careOfFormatted,
        jobDescription,
        organisation,
        propertyName,
        unitAndFloorGroup,
        additionalAddress,
        localityGroup,
      ];
      return concatAddresses(addresses, "\r\n");
    }
    case EDeliveryOptions.SPECIAL: {
      const postalGroup = concatAddresses([
        postalDeliveryType,
        postalDeliveryNumber,
      ]);
      const localityGroup = concatAddresses([locality, state, postCode]);
      const addresses = [
        attentionOfFormatted,
        careOfFormatted,
        jobDescription,
        organisation,
        additionalAddress,
        postalGroup,
        localityGroup,
      ];
      return concatAddresses(addresses, "\r\n");
    }

    case EDeliveryOptions.INTERNATIONAL: {
      const addresses = [
        attentionOfFormatted,
        careOfFormatted,
        jobDescription,
        organisation,
        propertyName,
        address,
        country,
      ];
      return concatAddresses(addresses, "\r\n");
    }
    case EDeliveryOptions.DXMail: {
      const dxmailNumberFormatted = postalDeliveryNumber
        ? `DX: ${postalDeliveryNumber}`
        : "";
      const addresses = [dxmailNumberFormatted, locality];
      return concatAddresses(addresses, "\r\n");
    }
    default:
      return "";
  }
};

const standardDeliveryFields: Array<keyof DTO_Entity_PostalAddress> = [
  "Delivery",
  "Property_Name",
  "Unit_Type",
  "Unit_Number",
  "Floor_Type",
  "Floor_Number",
  "House_Number",
  "Street_Name",
  "Street_Type",
  "Additional_Address",
  "Locality",
  "State",
  "Post_Code",
  "Country",
  "AddressFormatted",
  "Same_As_Physical",
];

const specialDeliveryFields: Array<keyof DTO_Entity_PostalAddress> = [
  "Delivery",
  "Postal_Delivery_Type",
  "Postal_Delivery_Number",
  "Additional_Address",
  "Locality",
  "State",
  "Post_Code",
  "Country",
  "AddressFormatted",
  "Same_As_Physical",
];

const internationalDeliveryFields: Array<keyof DTO_Entity_PostalAddress> = [
  "Delivery",
  "Property_Name",
  "Address",
  "Country",
  "AddressFormatted",
  "Same_As_Physical",
];

const dXMailDeliveryFields: Array<keyof DTO_Entity_PostalAddress> = [
  "Delivery",
  "Postal_Delivery_Number",
  "Locality",
  "AddressFormatted",
  "Same_As_Physical",
];

const addressFieldsGropedByDelivery: {
  [key in EDeliveryOptions]: Array<keyof DTO_Entity_PostalAddress>;
} = {
  [EDeliveryOptions.STANDARD]: standardDeliveryFields,
  [EDeliveryOptions.SPECIAL]: specialDeliveryFields,
  [EDeliveryOptions.INTERNATIONAL]: internationalDeliveryFields,
  [EDeliveryOptions.DXMail]: dXMailDeliveryFields,
};

export const propertyFieldGroupedByDelivery: {
  [key in EDeliveryOptions]: string;
} = {
  [EDeliveryOptions.STANDARD]: "Standard_Property_Name",
  [EDeliveryOptions.INTERNATIONAL]: "International_Property_Name",
  [EDeliveryOptions.SPECIAL]: "Property_Name",
  [EDeliveryOptions.DXMail]: "Property_Name",
};

export const processAddressData = (
  deliveryValue: EDeliveryOptions,
  originalData: DTO_Entity_PostalAddress
) => {
  let processedData: any = {};
  addressFieldsGropedByDelivery[deliveryValue].forEach((field) => {
    processedData[field] = originalData[field];
  });
  const propertyNameField =
    propertyFieldGroupedByDelivery[deliveryValue as EDeliveryOptions];
  processedData["Property_Name"] =
    originalData[propertyNameField as keyof DTO_Entity_PhysicalAddress];
  return processedData;
};
