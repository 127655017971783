import { APIResponseError } from "@common/apis/model";

export interface DTO_Certificate_AreaBalances {
  CertificateId: number;
  AreaOfBalances: DTO_Certificate_AreaOfBalance[];
  AreaOfBalancesTotals: DTO_Certificate_AreaOfBalanceTotal;
  Rebates: DTO_Certificate_Rebate[];
  RebatesTotals: DTO_Certificate_RebateTotal;
  Balance_Owing: number | null;
  Discount_Allowed: number | null;
}

export interface DTO_Certificate_AreaOfBalance {
  Financial_Area_Id: number;
  Financial_Area_Name: string;
  Gross_Amount_Levied: number | null;
  Rebates: number | null;
  Current_Balance: number | null;
  Current_Interest_Balance: number | null;
  Arrears_Balance: number | null;
  Arrears_Interest_Balance: number | null;
  Deferred_Balance: number | null;
  Deferred_Interest_Balance: number | null;
  Postponed_Balance: number | null;
  Postponed_Interest_Balance: number | null;
}

export interface DTO_Certificate_AreaOfBalanceTotal {
  Gross_Amount_Levied: number | null;
  Rebates: number | null;
  Current_Balance: number | null;
  Current_Interest_Balance: number | null;
  Arrears_Balance: number | null;
  Arrears_Interest_Balance: number | null;
  Deferred_Balance: number | null;
  Deferred_Interest_Balance: number | null;
  Postponed_Balance: number | null;
  Postponed_Interest_Balance: number | null;
}

export interface DTO_Certificate_Rebate {
  Rebate_Type_Id: number;
  Rebate_Type_Name: string;
  Rebate_Amount: number | null;
}

export interface DTO_Certificate_RebateTotal {
  Rebate_Amount: number | null;
}
export interface DTO_Certificate_Rebate {
  Rebate_Type_Id: number;
  Rebate_Type_Name: string;
  Rebate_Amount: number | null;
}

export enum CertificateMode {
  ALL = 1,
  DATA = 2,
}

export interface IErrorResponseCertificate {
  View?: APIResponseError;
  Data?: APIResponseError;
}
