import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class SettingDeleteButtonStore {
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
}

const settingDeleteButtonStoreContext = createContext(
  new SettingDeleteButtonStore()
);
export const useSettingDeleteButtonStore = () => {
  return useContext(settingDeleteButtonStoreContext);
};
