import { AssociateMeterDialog } from "@app/products/property/registers/[id]/components/dialogs/associate-meter/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const AssociateMeterButton = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  return (
    <>
      <CCNavButton
        title="Associate meter"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <AssociateMeterDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={(data: any): void => {
            setIsShowDialog(false);
            pushNotification({
              title: "Meter was associated to account successfully",
              type: "success",
            });
          }}
        />
      )}
    </>
  );
};
