import { TownPlanningForm } from "@app/products/town-planning/[id]/components/child-screens/general/_index";
import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewTownPlanning = observer(() => {
  const { isLoading } = useTownPlanningStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Town Planning"} />

      <FormTitle title="New Town Planning" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Amend permit"} />
            <CCNavButton title={"Appealed"} />
            <CCNavButton title={"Audit"} />
            <CCNavButton title={"Number appeal"} />
            <CCNavButton title={"Plans to comply"} />
            <CCNavButton title={"Secondary consent"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Additional address"} />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Advertising"} />
            <CCNavButton title={"Appeal"} />
            <CCNavButton title={"Attachment"} />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Communication"} />
            <CCNavButton title={"Complaint"} />
            <CCNavButton title={"Condition - ad hoc"} />
            <CCNavButton title={"Condition - std"} />
            <CCNavButton title={"Fee - ad hoc"} />
            <CCNavButton title={"Fee - calculated"} />
            <CCNavButton title={"Fee - multiple"} />
            <CCNavButton title={"Further information request"} />
            <CCNavButton title={"IP - ad hoc"} />
            <CCNavButton title={"IP - from file"} />
            <CCNavButton title={"IP - from map"} />
            <CCNavButton title={"IP - from properties"} />
            <CCNavButton title={"Meeting"} />
            <CCNavButton title={"Notice"} />
            <CCNavButton title={"Referral"} />
            <CCNavButton title={"Related"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
          <CCNavButton title={"More Options"} type="more">
            <CCNavButton title={"Application details"} />
            <CCNavButton title={"Certification"} />
          </CCNavButton>,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<TownPlanningForm />} />
          </div>
        </div>
      </div>
    </>
  );
});
