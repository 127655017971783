import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsFileit {
  formRenderProps: FormRenderProps;
}
export const RecordsFileit = observer(({ formRenderProps }: IRecordsFileit) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  //Fileit field
  const fieldFileitUsername = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_UserName
  );
  const fieldFileitPassword = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_Password
  );
  const fieldFileitRootFolder = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_WorkingFolder
  );
  const fieldFIleitAuthenticationServiceName = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_AuthenticationServiceName
  );
  const fieldFIleitDocumentManagementServiceName = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_DocumentManagementServiceName
  );
  const fieldFileitContentServiceName = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_ContentServiceName
  );
  const fieldFileitSchemaURL = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_URL
  );
  const fieldFileitDocumentURL = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_DocumentURL
  );
  const fieldSaveCommunicationsFileit = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Fileit_SaveCommunications
  );

  return (
    <>
      <div className="cc-form-cols-1">
        {fieldFileitUsername && (
          <InputGlobalSettings
            data={fieldFileitUsername}
            isEditing={isEditing}
          />
        )}

        {fieldFileitPassword && (
          <InputGlobalSettings
            data={fieldFileitPassword}
            isEditing={isEditing}
          />
        )}

        {fieldFileitRootFolder && (
          <InputGlobalSettings
            data={fieldFileitRootFolder}
            isEditing={isEditing}
          />
        )}

        {fieldFIleitAuthenticationServiceName && (
          <InputGlobalSettings
            data={fieldFIleitAuthenticationServiceName}
            isEditing={isEditing}
          />
        )}

        {fieldFIleitDocumentManagementServiceName && (
          <InputGlobalSettings
            data={fieldFIleitDocumentManagementServiceName}
            isEditing={isEditing}
          />
        )}

        {fieldFileitContentServiceName && (
          <InputGlobalSettings
            data={fieldFileitContentServiceName}
            isEditing={isEditing}
          />
        )}

        {fieldFileitSchemaURL && (
          <InputGlobalSettings
            data={fieldFileitSchemaURL}
            isEditing={isEditing}
          />
        )}

        {fieldFileitDocumentURL && (
          <InputGlobalSettings
            data={fieldFileitDocumentURL}
            isEditing={isEditing}
          />
        )}
      </div>

      {fieldSaveCommunicationsFileit && (
        <SwitchGlobalSettings
          data={fieldSaveCommunicationsFileit}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      )}
    </>
  );
});
