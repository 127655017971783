import { getNoticeRunsById } from "@app/products/property/schemes/notice-runs/[id]/api";
import { NoticeRunDetail } from "@app/products/property/schemes/notice-runs/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SchemesNoticeRunsStore {
  private _schemesNoticeRuns?: NoticeRunDetail = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get schemesNoticeRuns() {
    return toJS(this._schemesNoticeRuns);
  }
  setSchemesNoticeRuns = (schemesNoticeRuns?: NoticeRunDetail) => {
    runInAction(() => {
      this._schemesNoticeRuns = schemesNoticeRuns;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get schemesNoticeRunsId() {
    return toJS(this.schemesNoticeRuns?.NoticeRunId);
  }
  //Action

  resetStore = () => {
    runInAction(() => {
      this._schemesNoticeRuns = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadNoticeRuns = async (schemeNoticeRunsId: number) => {
    this.setIsLoading(true);
    const response = await getNoticeRunsById(schemeNoticeRunsId);
    let errorResponse = undefined;
    let newSchemeNoticeRuns = undefined;
    if (isSuccessResponse(response)) {
      newSchemeNoticeRuns = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setSchemesNoticeRuns(newSchemeNoticeRuns);
    this.setIsLoading(false);
  };
}

const schemesNoticeRunsStoreContext = createContext(
  new SchemesNoticeRunsStore()
);
export const useSchemesNoticeRunsStore = () => {
  return useContext(schemesNoticeRunsStoreContext);
};
