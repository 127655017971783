import { CRMSSystemAdminCategoryFormElement } from "@app/products/crms/system-admin/categories/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { ServiceStandardCategory } from "@app/products/crms/system-admin/categories/[id]/model";
import { useCRMSSytemAdminCategoryStore } from "@app/products/crms/system-admin/categories/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const CRMSSystemAminCategoryForm = observer(() => {
  const { crmsCategory, setOnSubmit, submitAction } =
    useCRMSSytemAdminCategoryStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const { pushNotification } = useCCAppNotificationStore();

  const isNew = useIsNew();

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, crmsCategory]);

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { isValid, values, isModified } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;

    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    submitAction(values as ServiceStandardCategory, isNew, actionSubmit);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={crmsCategory}
        key={JSON.stringify(crmsCategory)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<CRMSSystemAdminCategoryFormElement />}
            />
          );
        }}
      />
    </div>
  );
});
