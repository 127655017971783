import { Portal_ALS_Return_Staging_File } from "@app/products/property/annual-land-stock-returns/import-files/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Portal_ALS_Return_Staging_File>();
export const alsrColList: IColumnFields[] = [
  {
    field: nameOf("PARSF_FileName"),
    title: "File Name",
    locked: true,
  },
  {
    field: nameOf("PARSF_FileType"),
    title: "Type",
  },
  {
    field: nameOf("PARSF_DateCreated"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PARSF_FileSize_KB"),
    title: "File Size (KB)",
  },
  {
    field: nameOf("PARSF_Processed_Status_Name"),
    title: "Processed Status",
  },
  {
    field: nameOf("PARSF_Processed_Date"),
    title: "Processed Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Portal_ALS_Return_Staging_File_Id"),
    title: "File ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
