import { eventEmitter } from "@/App";
import { RejectDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/reject/_index";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowProcessMode } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ConfirmRejectDialog = observer(() => {
  const {
    isLoadingReject,
    dataForRejectDialog,
    setDataForRejectDialog,
    setIsLoadingReject,
    resetStore,
  } = useConfirmRejectDialogStore();
  const { removeDialog, listDialog } = usePropertyWorkflowStore();
  const { clearSelectedItems } = useCCProductListViewStore();

  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleRejectWorkflow = async (value: any) => {
    if (!dataForRejectDialog) return;

    setNotifications([]);
    setIsLoadingReject(true);
    appNotificationStore.clearErrorNotification();

    const {
      rejectCallback,
      dataReject,
      defaultSuccessMessage,
      defaultErrorMessage,
    } = dataForRejectDialog;
    const rejectData = {
      ...dataReject,
      WorkflowHeader: {
        ...dataReject?.WorkflowHeader,
        WorkflowDraft: {
          ...dataReject?.WorkflowHeader?.WorkflowDraft,
          WD_Rejection_Reason_Id: value?.Reason,
          WD_Rejection_Additional_Comments: value?.Comments,
        },
      },
    };
    const response = await rejectCallback(
      WorkflowProcessMode.Reject,
      rejectData
    );
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      appNotificationStore.pushNotification({
        title: response.data?.Notification ?? defaultSuccessMessage,
        type: "success",
      });

      setDataForRejectDialog(undefined);

      if (listDialog?.[0]?.type) {
        removeDialog(listDialog[0].type);
      }
      if (listDialog?.[0]?.typeList) {
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: response?.data?.ErrorMessage ?? defaultErrorMessage,
          type: "error",
        },
      ]);
    }
    setIsLoadingReject(false);
  };

  useEffectOnce(() => {
    return () => resetStore();
  });

  return (
    <>
      {dataForRejectDialog && (
        <RejectDialog
          onSubmitData={handleRejectWorkflow}
          onClose={() => {
            setNotifications([]);
            setDataForRejectDialog(undefined);
          }}
          isLoadingSendData={isLoadingReject}
          notifications={notifications}
        />
      )}
    </>
  );
});
