import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import {
  BubbleUpIdentifier,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export enum FurtherInfoSubmitActions {
  Save = "Save",
  New = "New",
  InfoReceived = "InfoReceived",
  InfoNotReceived = "InfoNotReceived",
  Cancel = "Cancel",
  InfoAdequate = "InfoAdequate",
  InfoInadequate = "InfoInadequate",
  AdditionalInfoRequired = "AdditionalInfoRequired",
  RejectLetter = "RejectLetter",
  Reinstate = "Reinstate",
  SendForApproval = "SendForApproval",
  ApproveLetter = "ApproveLetter",
  SendLetterToRAandApplicant = "SendLetterToRAandApplicant",
}
export const fiSubmitInWorkflowActions: FurtherInfoSubmitActions[] = [
  FurtherInfoSubmitActions.AdditionalInfoRequired,
];

export type FurtherInfoParentActions = PPRSubmitActions.RequestFI;
export interface IParentFISection {
  id: number;
  recordType: RECORDTYPE;
  data?: FurtherInformationRequest;
  parentAction?: FurtherInfoParentActions;
  notification?: IAppNotificationItemAddProps[];
}

export interface FurtherInformationRequest extends DTOBaseClass_Standard {
  FurtherInformationRequest_ID: number;
  Status_ENUM: FURTHERINFOSTATUS;
  Status_Name: string;
  DueStatus_Name: string;
  Details: string;
  DateRequested: Date;
  DateDue: Date | null;
  NumberOfDaysGiven: number | null;
  OriginalDateDue: Date | null;
  DateInfoReceived: Date | null;
  InformationAdequate: boolean | null;
  ExtensionRequested: boolean | null;
  ExtensionDateRequested: Date | null;
  ExtensionNumberOfDaysGiven: number | null;
  ExtensionGranted: boolean | null;
  ExtensionDateIssued: Date | null;
  Officer: SiteUser;
  Officer_ID: number | null;
  FI_Source?: FURTHERINFOSOURCE | null;
  _BubbleUps: BubbleUpIdentifier[];
  OrignalFI_ID: number | null;
  DateInfoNotReceived: string | null;
  FI_NotReceivedComments: string;
}

export interface FI_Status {
  StatusOnly: string;
  StatusDate: string;
}

export interface FurtherInfo_Light {
  FurtherInformationRequest_ID: number;
  Status_ENUM: FURTHERINFOSTATUS;
  Status_Name: string;
  Details: string;
}

export enum FURTHERINFOSTATUS {
  SystemInitialise = "SystemInitialise",
  InfoRequested = "InfoRequested",
  InfoReceived = "InfoReceived",
  Cancelled = "Cancelled",
  ExtensionRequested = "ExtensionRequested",
  ExtensionGranted = "ExtensionGranted",
  Archived = "Archived",
  ExtensionDeclined = "ExtensionDeclined",
  InfoReceivedAwaitingAssessment = "InfoReceivedAwaitingAssessment",
  Lapsed = "Lapsed",
  InfoWaitingForApproval = "InfoWaitingForApproval",
  InfoApproved = "InfoApproved",
  InfoRejected = "InfoRejected",
  InfoRequestedDraft = "InfoRequestedDraft",
  InfoNotReceived = "InfoNotReceived",
  InfoRequestedNew = "InfoRequestedNew",
}

export enum FURTHERINFOSOURCE {
  Planning = "Planning",
  Building = "Building",
  LM_Register = "LM_Register",
}
//Parent
export interface RecordSourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: RECORDTYPE | null;
  _RecordSourceType_Name: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
}

export interface Ancestor {
  AncestorIsSameAsParent: boolean;
  lblParent1: string;
  lblParent2: string;
  lblParent3: string;
  lblParent4: string;
  lblParent5: string;
  lblParent6: string;
  litParent1: string;
  litParent2: string;
  litParent3: string;
  litParent4: string;
  litParent5: string;
  litParent6: string;
  linktext: string;
  lblParentHeader: string;
  lblParentDescription: string;
  litParentDescription: string;
  RecordSourceIdentifier: RecordSourceIdentifier;
  lblAncestor1: string;
  lblAncestor2: string;
  lblAncestor3: string;
  lblAncestor4: string;
  litAncestor1: string;
  litAncestor2: string;
  litAncestor3: string;
  litAncestor4: string;
  LinkText_Ancestor: string;
  RecordSourceIdentifier_Ancestor: RecordSourceIdentifier;
  IsSensitiveApplication: boolean;
  AncestorHasRequiredInspectionType: IKeyValuePacket[];
}
