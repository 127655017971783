import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postSendCommunication = async (
  ids: number[]
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/communicationqueue/send`,
      ids
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
