import { colAssociationsRegisterAccount } from "@app/products/property/components/associations/components/register-account/config";
import { DTO_AssociatedItem_RegisterAccount } from "@app/products/property/components/associations/components/register-account/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsRegisterAccountProps {
  associationsRegisterAccountInfo:
    | DTO_AssociatedItem_RegisterAccount[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_RegisterAccount>();

export const AssociationsRegisterAccount = ({
  associationsRegisterAccountInfo,
}: IAssociationsRegisterAccountProps) => {
  return (
    <CCGrid
      className="cc-register-account-grid"
      data={associationsRegisterAccountInfo || []}
      columnFields={colAssociationsRegisterAccount}
      primaryField={nameOf("Id")}
    />
  );
};
