import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colSchemeAccountNotices: IColumnFields[] = [
  { field: "NoticeNumber", title: "Notice Number" },
  { field: "IssueDate", title: "Issue Date", format: DATE_FORMAT.DATE },
  { field: "DueDate", title: "Due Date", format: DATE_FORMAT.DATE },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "AmountDue",
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "OpeningBalance",
    title: "Opening Balance",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "OpeningAmountDue",
    title: "Opening Amount Due",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "PrincipalBecomingDue",
    title: "Principal Becoming Due",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "CurrentAmountDue",
    title: "Current Amount Due",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "PrincipalArrears",
    title: "Principal Arrears",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "ArrearsAmountDue",
    title: "Arrears Amount Due",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "PrincipalDue",
    title: "Principal Due",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: "PayoutAmount",
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  { field: "NameAddress", title: "Name Address" },
  { field: "RunDate", title: "Run Date", format: DATE_FORMAT.DATE },
  { field: "CreatedBy", title: "Created By" },
  {
    field: "AccountNoticeId",
    title: "Account Notice ID",
    style: formatStyleUnRelevant,
  },
];
