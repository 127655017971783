import { getJournalDetailById } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/api";
import { DTO_Journal_TabDetails } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/model";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const JournalDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [journalInfo, setJournalInfo] = useState<
    DTO_Journal_TabDetails | undefined
  >();
  const { journal } = useJournalStore();
  const { lastSelectedRow } = useCCProductListViewStore();

  const params: { id: string } = useParams();
  const journalId: any = useMemo(() => {
    return params?.id ?? lastSelectedRow?.Journal_Number;
  }, [lastSelectedRow, params]);

  useEffect(() => {
    if (journalId) {
      setIsLoading(true);
      getJournalDetailById(journalId).then((response: any) => {
        setJournalInfo(response);
        setIsLoading(false);
      });
    }
  }, [journalId, journal]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!journalInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Journal:"}
              value={journalInfo?.Journal_Number + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Date:"}
                value={formatDisplayValue(
                  journalInfo?.Jnl_DateTime,
                  DATETIME_FORMAT.DATETIME
                )}
              />
              <ProductReferenceRow
                title={"Name:"}
                value={journalInfo?.Jnl_Description}
              />
              <ProductReferenceRow
                title={"Type:"}
                value={journalInfo?.Journal_Type_Name}
              />
              <ProductReferenceRow
                title={"Status:"}
                value={journalInfo?.Journal_Status_Name}
              />
              <ProductReferenceRow
                title={"Number of transactions:"}
                value={formatDisplayValue(
                  journalInfo?.Jnl_No_of_Transactions,
                  NUMBER_FORMAT.NUMBER1
                )}
              />
              <ProductReferenceRow
                title={"Total:"}
                value={formatDisplayValue(
                  journalInfo?.Journal_Total,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
