import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IUpdateMasterMeterDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export default function UpdateMasterMeterDialog({
  onClosePopup,
  onConfirmYes,
}: IUpdateMasterMeterDialog) {
  return (
    <ConfirmDialog
      title={"Update Master Meter"}
      subMessage={"Do you wish to Update Master Meter to Sub Meter?"}
      message={""}
      height={"auto"}
      width={"25%"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
}
