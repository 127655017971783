import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { HMPremisesInspectionScheduleView } from "@app/products/hm/insp-schedule/model";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";

const nameOfPremisesInspScheduleView =
  nameOfFactory<HMPremisesInspectionScheduleView>();
export const colHMInspScheduleInspectionsDue = [
  {
    field: nameOfPremisesInspScheduleView("TradingName"),
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: HMPremisesInspectionScheduleView) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconColumnViewConfigAlert,
  {
    field: nameOfPremisesInspScheduleView("RegNo"),
    title: "Registration Number",
  },
  {
    field: nameOfPremisesInspScheduleView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfPremisesInspScheduleView("Address"),
    title: "Address",
  },
  {
    field: nameOfPremisesInspScheduleView("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOfPremisesInspScheduleView("RegistrationFrom"),
    title: "Registration From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("RegistrationTo"),
    title: "Registration To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("NextInspection"),
    title: "Next Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("LastCompliance"),
    title: "Last Compliance",
  },
  {
    field: nameOfPremisesInspScheduleView("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOfPremisesInspScheduleView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfPremisesInspScheduleView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfPremisesInspScheduleView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfPremisesInspScheduleView("FSP"),
    title: "FSP Type",
  },
  {
    field: nameOfPremisesInspScheduleView("FSPContact"),
    title: "FSS Contact",
  },
  {
    field: nameOfPremisesInspScheduleView("Area"),
    title: "Area",
  },
];
