import { loadViewConfiguresColumns } from "@app/products/property/api";
import { centrelinkCustomerConfirmationRoute } from "@app/products/property/centrelink-customer-confirmation/route";
import { CreateRequestsButton } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/action-bar/buttons/create-requests/_index";
import { ProduceRequestFileButton } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/action-bar/buttons/produce-request-file/_index";
import { RemoveCardButton } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/action-bar/buttons/remove-card/_index";
import { EditEntityDialog } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/edit-entity/_index";
import { CentreLinkDetailSidebar } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/sidebar/details/_index";
import { colViewRequestFile } from "@app/products/property/centrelink-customer-confirmation/view-request-file/config";
import { CENTRELINKCUSTOMERCONFIRMATION_VIEWREQUESTFILE_LIST_VIEW_URL } from "@app/products/property/centrelink-customer-confirmation/view-request-file/constant";
import { VO_CentreLink_Request } from "@app/products/property/centrelink-customer-confirmation/view-request-file/model";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { IColumnFields } from "@components/cc-grid/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_CentreLink_Request>();
export default () => {
  const [showDialog, setShowDialog] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown
        category={centrelinkCustomerConfirmationRoute.path}
      />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CreateRequestsButton />
        <ProduceRequestFileButton />
        <RemoveCardButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CentreLinkDetailSidebar /> },
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.CentrelinkCustomerConfirmation_ViewRequestFile,
      colViewRequestFile
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <>
      <CCProductListView
        dataUrl={CENTRELINKCUSTOMERCONFIRMATION_VIEWREQUESTFILE_LIST_VIEW_URL}
        columnFields={colsConfigured}
        primaryField={nameOf("Entity_Id")}
        state={{ sort: [{ field: nameOf("ENA_Name"), dir: "desc" }] }}
      />
      {showDialog && (
        <EditEntityDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={() => {
            setShowDialog(false);
            pushNotification({
              title: "Edited successfully",
              type: "success",
            });
          }}
        />
      )}
    </>
  );
};
