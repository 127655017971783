import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colActiveByRespArea: IColumnFields[] = [
  {
    field: "CertificateNumber",
    title: "Certificate Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${PROPERTY_CERTIFICATE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "IsUrgent",
    title: "Is Urgent",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "RequiredBy",
    title: "Required By",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: "OriginatedOn",
    title: "Originated On",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: "GeneralCompletedOn",
    title: "General Completed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "RatingCompletedOn",
    title: "Rating Completed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "TownPlanningCompletedOn",
    title: "Town Planning Completed On",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
  {
    field: "BuildingCompletedOn",
    title: "Building Completed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "HealthCompletedOn",
    title: "Health Completed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "CivilCompletedOn",
    title: "Civil Completed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "GeneralComments",
    title: "General Comments",
  },
  {
    field: "RatingComments",
    title: "Rating Comments",
  },
  {
    field: "TownPlaningComments",
    title: "Town Planing Comments",
  },
  {
    field: "BuildingComments",
    title: "Building Comments",
  },
  {
    field: "HealthComments",
    title: "Health Comments",
  },
  {
    field: "CivilComments",
    title: "Civil Comments",
  },
  {
    field: "Applicant",
    title: "Applicant",
  },
  {
    field: "ApplicantReference",
    title: "Applicant Reference",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "PropertyLocality",
    title: "Property Locality",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "IssuedOn",
    title: "Issued On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Issuedby",
    title: "Issued By",
  },
  {
    field: "CertificateType",
    title: "Certificate Type",
  },
  {
    field: "CertificateStatus",
    title: "Certificate Status",
  },
  {
    field: "ID",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
