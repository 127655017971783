import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { VO_Supplementary_Levies } from "@app/products/property/supplementary-rates/[id]/components/child-screens/levies/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { colRatesLevies } from "./config";

const nameOf = nameOfFactory<VO_Supplementary_Levies>();

export const SupplementaryRatesLevies = () => {
  const { id } = useParams<{ id: string }>();
  const [isOnlyChanges, setIsOnlyChanges] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedCols, setProcessedCols] = useState<IColumnFields[]>([]);

  const loadSupplementaryRatesLeviesConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    let response = await loadViewConfiguresColumns(
      ViewConfiguration.Supplementary_Levies,
      colRatesLevies
    );
    if (Array.isArray(response)) {
      setProcessedCols(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadSupplementaryRatesLeviesConfig();
  });

  const handleChange = (event: CheckboxChangeEvent) => {
    setIsOnlyChanges(event.value);
  };

  return (
    <div className="cc-supplementary-rates-levies">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left"></div>
        <div className="cc-grid-control-right">
          <Checkbox
            title="Only show levies that have changed in the supplementary"
            label="Only show levies that have changed in the supplementary"
            onChange={handleChange}
            checked={isOnlyChanges}
          />
        </div>
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadSupplementaryRatesLeviesConfig();
          }}
        />
      ) : (
        <>
          <CCGrid
            dataUrl={`odata/property/internal/supplementaryratesleviesregister/${id}?onlychanges=${isOnlyChanges}&$count=true&`}
            columnFields={processedCols}
            primaryField={nameOf("Levy_Name")}
          />
        </>
      )}
    </div>
  );
};
