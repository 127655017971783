import { IDeferredDutyNoticeRunSummary } from "@app/products/property/deferred-duty/notice-runs/[id]/model";

export const mockNoticeRun: IDeferredDutyNoticeRunSummary = {
  NoticeRunID: 16,
  Run: "Statement For the Period of 1 July 2016 to 31 Dec 2016",
  IssueDate: new Date("11-Jan-2017"),
  PeriodStart: new Date("1-Jul-2016"),
  PeriodEnd: new Date("31-Dec-2016"),
  CreateBy: "Kannika Rangsayapan",
  CreateOn: new Date("11-Jan-2017 0:00"),
};
