import { VO_Supp_Assessment_Levy } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supp_Assessment_Levy>();
//All titles will be replaced by column name data from view configuration API
export const colAssessmentLevy: IColumnFields[] = [
  {
    field: nameOf("LC_Name"),
    title: "Levy Category",
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("AL_Levy_Quantity_Amount"),
    title: "Quantity or Amount",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("AL_Percentage"),
    title: "Percentage of Levy to Apply",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("AL_Base_Adjustment_Rate"),
    title: "Percentage of Amount",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("AL_RateByValue_Adjustment_Rate"),
    title: "Percentage of Rate by Valn",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },

  {
    field: nameOf("LRCM_Method"),
    title: "Land Rent Calculation Method",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("SAL_Old_Active_From_Date"),
    title: "Previous Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SAL_New_Active_From_Date"),
    title: "New Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SAL_New_Active_To_Date"),
    title: "New Active to Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SAL_Has_Levy_been_Changed"),
    title: "Has Changed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Supp_Adjustment"),
    title: "Supplementary Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SAL_Change_UserId"),
    title: "Last Change By",
  },
  {
    field: nameOf("SAL_Change_DateTime"),
    title: "Last Change On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assessment_Levy_Id"),
    title: "Assessment Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const getSupplementaryLeviesDataUrl = (
  supplementaryAssessmentId: number
) => {
  return `/odata/property/internal/supplementaryratesassessmentleviesregister/${supplementaryAssessmentId}?$count=true&`;
};
