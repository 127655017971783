import { useRebateClaimStore } from "@app/products/property/assessments/rebates/rebate-claims/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistedRebateClaim from "./components/forms/existed/_index";
import NewRebateClaim from "./components/forms/new/_index";

const ManagePropertyAssessmentRebateClaim = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const rebateClaimId = params.id;
  const { loadRebateClaim, resetStore, isLoading } = useRebateClaimStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (isNew) return;
    loadRebateClaim(parseInt(rebateClaimId));
  }, [rebateClaimId, isNew, loadRebateClaim]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  if (isNew) {
    return <NewRebateClaim />;
  }
  return <ExistedRebateClaim />;
});

export default ManagePropertyAssessmentRebateClaim;
