import { colMemos } from "@app/products/property/meters/[id]/components/child-screens/memos/config";
import { IMeterMemo } from "@app/products/property/meters/[id]/components/child-screens/memos/model";
import { nameOfFactory, sleep } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
export const nameOf = nameOfFactory<IMeterMemo>();
class MeterMemoStore {
  private _memos: IMeterMemo[] = [];
  private _isLoading: boolean = false;
  private _selectedMemo?: IMeterMemo = undefined;
  private _gridColumns: IColumnFields[] = colMemos;
  constructor() {
    makeAutoObservable(this);
    this.setGridColumns(
      colMemos.map((column) =>
        column.field === nameOf("MemoId")
          ? {
              ...column,
              handleOnClick: (dataItem) => {
                this.setSelectedMemo(dataItem);
              },
            }
          : column
      )
    );
  }

  get memos() {
    return this._memos;
  }
  get isLoading() {
    return this._isLoading;
  }

  get selectedMemo() {
    return this._selectedMemo;
  }
  get gridColumns() {
    return this._gridColumns;
  }

  setMemos = (memos: IMeterMemo[]) => {
    runInAction(() => {
      this._memos = memos;
    });
  };
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setSelectedMemo = (selectedMemo?: IMeterMemo) => {
    runInAction(() => {
      this._selectedMemo = selectedMemo;
    });
  };
  setGridColumns = (gridColumns: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumns = gridColumns;
    });
  };

  //Action
  loadMemos = async () => {
    this.setIsLoading(true);
    await sleep(1000);
    this.setIsLoading(false);
  };

  addMemos = async (newMemo: IMeterMemo) => {
    this.setIsLoading(true);
    await sleep(1000);
    let newMemos = [...this.memos];
    newMemos.unshift(newMemo);
    this.setMemos(newMemos);
    this.setIsLoading(false);
  };

  editMemos = async (newMemo: IMeterMemo) => {
    this.setIsLoading(true);
    await sleep(1000);
    let newMemos = [...this.memos];
    newMemos = newMemos.map((memo) =>
      memo.MemoId === newMemo.MemoId
        ? { ...newMemo, ChangedOn: new Date() }
        : memo
    );
    this.setMemos(newMemos);
    this.setIsLoading(false);
  };
}
const meterMemoStoreContext = createContext(new MeterMemoStore());
export const useMeterMemoStore = () => {
  return useContext(meterMemoStoreContext);
};
