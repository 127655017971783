import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { DTO_Entity } from "@app/products/property/parcels/[id]/components/child-screens/entities/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_Entity>();

export const colParcelAssociatedEntities: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    //TODO waiting confirm link
    linkTo: (dataItem: DTO_Entity) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem[nameOf("Id")]}`,
  },
  {
    field: nameOf("EntityRole"),
    title: "Role",
  },
  {
    field: nameOf("Address"),
    title: "Address Formatted",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
