import { RECORDTYPE } from "@common/constants/recordtype";

export enum TabTableType {
  //#region Core
  Addresses = 0,
  Actions = 1,
  Comments = 2,
  Contacts = 3,
  Related = 4,
  PPRAppeals = 5,
  PlansToComply = 6,
  Appeals = 7,
  FurtherInfo = 8,
  Enquiries = 9,
  PPREnquiries = 10,
  Documents = 11,
  Documents_Exhibition = 12,
  Documents_Working = 13,
  Documents_Hearing = 14,
  Inspections = 15,
  Notices = 16,
  Fees = 17,
  ContactRoles = 18,
  Invoices = 19,
  Alerts = 20,
  Events = 21,
  Bonds = 22,
  FinanceTransaction = 23,
  Conditions = 24,
  Samples = 25,
  // #endregion

  //#region Direct Debit
  DDTransfers = 40,
  DDAccount = 41,
  //#endregion
}
export type DictionaryTabTableType = {
  [key in string]?: number;
};

export interface GetTabTableCountRequest {
  id: number;
  recordType: RECORDTYPE;
  relatedTypes: TabTableType[];
  infringeKey?: string | null;
  showIPinContacts?: boolean | null;
  applicationNumber?: number | null;
  invoiceNumber?: string | null;
  recordsFileNumber?: string | null;
  propertyAssessmentNoKey?: string | null;
  addressLine1?: string | null;
  suburb?: string | null;
  personKey?: number | null;
  schemeKey?: number | null;
  refNoKey?: string | null;
  ancestorRecordType?: number | null;
  ancestorRecordId?: number | null;
}
