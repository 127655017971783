import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { listRoute } from "./list/route";

export const arrangementsRoute: ICCRoute = {
  path: "arrangements",
  name: "Arrangements",
  enum: eMenuAction.Arrangement_Folder,
  children: [
    listRoute,
    {
      path: "letters-produced",
      name: "Letters Produced",
      enum: eMenuAction.Arrangement_ProduceLetters,
      component: require("./letters-produced/_index").default,
    },
  ],
};
