import {
  addDynamicColumnsWithStandardColumns,
  processDynamicColumns,
} from "@app/products/property/assessments/[id]/components/child-screens/interest/util";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colPendingInterestChild } from "./config";

const InterestDetails = (props: GridDetailRowProps) => {
  const [colPendingInterestChildUpdate, setColPendingInterestChildUpdate] =
    useState(colPendingInterestChild);

  useEffectOnce(() => {
    const dynamicCols: any = props.dataItem?.PendingInterest?.DynamicColumns;
    if (Array.isArray(dynamicCols) && dynamicCols.length > 0) {
      const columnsAfterProcessing = processDynamicColumns(dynamicCols);
      const standardColumn = cloneDeep(colPendingInterestChildUpdate);
      const newColumns = addDynamicColumnsWithStandardColumns(
        columnsAfterProcessing,
        standardColumn
      );
      const PendingInterestsChildTotals: any =
        props.dataItem?.PendingInterest?.Totals;
      for (const totalItem in PendingInterestsChildTotals) {
        newColumns.forEach((item) => {
          if (totalItem === item.field) {
            item.footerCell = (
              <CurrencyCell value={PendingInterestsChildTotals[totalItem]} />
            );
          }
        });
      }
      setColPendingInterestChildUpdate(newColumns);
    }
  });

  return (
    <div className="cc-interest-detail-grid">
      <label className="cc-label">Pending interest</label>
      <CCGrid
        className="cc-interest-detail-grid"
        data={props?.dataItem?.PendingInterest?.PendingInterests}
        columnFields={colPendingInterestChildUpdate}
        primaryField="Id"
      />
    </div>
  );
};
export default InterestDetails;
