import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { WasteWaterSystemRegister } from "./model";

const nameOf = nameOfFactory<WasteWaterSystemRegister>();

export const colWastewaterSystemsRegister: IColumnFields[] = [
  {
    field: nameOf("PermitNumber"),
    title: "Permit Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: nameOf("Applicant"),
    title: "Applicant",
  },
  {
    field: nameOf("Owner"),
    title: "Owner",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOf("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("TypeOfApplication"),
    title: "Permit Type",
  },
  {
    field: nameOf("FileNumber"),
    title: "File Number",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("SystemName"),
    title: "System Name",
  },
  {
    field: nameOf("SystemType"),
    title: "System Type",
  },
  {
    field: nameOf("Received"),
    title: "Received",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PTI"),
    title: "PTI",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CTU"),
    title: "ATU",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("WaterAuthority"),
    title: "Authority",
  },
  {
    field: nameOf("Plumber"),
    title: "Plumber",
  },
  {
    field: nameOf("Risk"),
    title: "Risk Level",
  },
  {
    field: nameOf("CertificateNo"),
    title: "Certificate Number",
  },
  {
    field: nameOf("SerialNo"),
    title: "Serial Number",
  },
];
