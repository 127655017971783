import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import {
  IIssueDocumentButtonProps,
  IssueDocumentButton,
} from "@app/products/property/components/action-bar/button/issue-document/_index";
import React from "react";

export const IssueComplianceDocumentButton = (
  props: IIssueDocumentButtonProps
) => {
  const { reLoadCompliance } = useComplianceStore();
  return (
    <IssueDocumentButton
      titleButton="Issue document"
      titleDialog="Issue Compliance Document"
      reloadManagePage={reLoadCompliance}
      {...props}
    />
  );
};
