import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { APPEAL_HEARING_ROUTE } from "@common/pages/appeal/_id/appeal-hearing/_id/constant";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { IAdditionalHearing } from "./model";
const nameOf = nameOfFactory<IAdditionalHearing>();
export const colAdditionalHearing: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => {
      return `${APPEAL_ROUTE}/${dataItem.AppealId}${APPEAL_HEARING_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("HearingType"),
    title: "Hearing Type",
  },
  {
    field: nameOf("HearingDate"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Duration"),
    title: "Duration",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
];
