import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { mMemo } from "./model";

const nameOf = nameOfFactory<mMemo>();

export const colRegisterMemos: IColumnFields[] = [
  {
    field: nameOf("MemoDate"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("Memo"),
    title: "Memo",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("Id"),
    title: "Memo ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
