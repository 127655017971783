import { comboBoxSearchData } from "@app/products/property/assessments/arrangements/components/dialogs/new-arrangements/config";

export const mockNewArrangements = [
  {
    Assessment: "3857217 ‑ 2 Mount Street",
    AssessmentNumber: 3857217,
    ArrangementDate: "26-Aug-2021 11:18",
    AmountOfInitialDebt: 1149,
    Applicant: "D & M COSTA",
    Frequency: "Monthly",
    InitialPaymentOn: "26-Aug-2021",
    InitialPaymentOf: 0,
    PeriodicAmountStartOn: "26-Aug-2021",
    PeriodicAmountStartOf: 104,
    NoOfInstalment: 11,
    Comments: "",
    CalculatedSchedule: [
      {
        InstalmentNumber: 1,
        DueDate: "26-Aug-2021",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 2,
        DueDate: "26-Sep-2021",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 3,
        DueDate: "26-Oct-2021",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 4,
        DueDate: "26-Nov-2021",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 5,
        DueDate: "26-Dec-2021",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 6,
        DueDate: "26-Jan-2022",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 7,
        DueDate: "26-Feb-2022",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 8,
        DueDate: "26-Mar-2022",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 9,
        DueDate: "26-Apr-2022",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 10,
        DueDate: "26-May-2022",
        InstalmentAmount: 104,
      },
      {
        InstalmentNumber: 11,
        DueDate: "26-Jun-2022",
        InstalmentAmount: 109,
      },
    ],
  },
  {
    Assessment: "2980 ‑ 49 Matthie Road, Barragup",
    AssessmentNumber: 2980,
    AmountOfInitialDebt: 10742.51,
    Applicant: "MJ Borgward Nominees Pty Lid",
    ArrangementDate: "2-Mar-2021 10:14",
    Frequency: "Fortnightly",
    InitialPaymentOn: "5-Mar-2021",
    InitialPaymentOf: 0,
    PeriodicAmountStartOn: "5-Mar-2021",
    PeriodicAmountStartOf: 134,
    NoOfInstalment: 8,
    Comments: "",
    CalculatedSchedule: [
      {
        InstalmentNumber: 1,
        DueDate: "5-Mar-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 2,
        DueDate: "19-Mar-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 3,
        DueDate: "2-Apr-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 4,
        DueDate: "16-Apr-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 5,
        DueDate: "30-Apr-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 6,
        DueDate: "14-May-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 7,
        DueDate: "28-May-2021",
        InstalmentAmount: 134,
      },
      {
        InstalmentNumber: 8,
        DueDate: "11-Jun-2021",
        InstalmentAmount: 134,
      },
    ],
  },
  {
    Assessment: "1901 ‑ 12 Poplar Street, Dwellingup",
    AssessmentNumber: 1901,
    AmountOfInitialDebt: 3566.02,
    Applicant: "Jeannie-Marie O'Malley",
    ArrangementDate: "5-Mar-2021 9:11",
    Frequency: "Monthly",
    InitialPaymentOn: "30-Mar-2021",
    InitialPaymentOf: 0,
    PeriodicAmountStartOn: "30-Mar-2021",
    PeriodicAmountStartOf: 140,
    NoOfInstalment: 25,
    Comments: "",
    CalculatedSchedule: [
      {
        InstalmentNumber: 1,
        DueDate: "30-Mar-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 2,
        DueDate: "30-Apr-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 3,
        DueDate: "30-May-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 4,
        DueDate: "30-Jun-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 5,
        DueDate: "30-Jul-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 6,
        DueDate: "30-Aug-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 7,
        DueDate: "30-Sep-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 8,
        DueDate: "30-Oct-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 9,
        DueDate: "30-Nov-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 10,
        DueDate: "30-Dec-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 11,
        DueDate: "30-Jan-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 12,
        DueDate: "30-Mar-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 13,
        DueDate: "30-Apr-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 14,
        DueDate: "30-May-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 15,
        DueDate: "30-Jun-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 16,
        DueDate: "30-Jul-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 17,
        DueDate: "30-Aug-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 18,
        DueDate: "30-Sep-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 19,
        DueDate: "30-Oct-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 20,
        DueDate: "30-Nov-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 21,
        DueDate: "30-Dec-2022",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 22,
        DueDate: "30-Jan-2023",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 23,
        DueDate: "30-Mar-2023",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 24,
        DueDate: "30-Apr-2023",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 24,
        DueDate: "30-May-2023",
        InstalmentAmount: 66.02,
      },
    ],
  },

  {
    Assessment: "1564 ‑ 4234 South Western Highway. North Dandalup",
    AssessmentNumber: 1564,
    AmountOfInitialDebt: 1670.69,
    Applicant: "Cameron J McDonald",
    ArrangementDate: "10-Mar-2021 9:18",
    Frequency: "Weekly",
    InitialPaymentOn: "11-Mar-2021",
    InitialPaymentOf: 0,
    PeriodicAmountStartOn: "11-Mar-2021",
    PeriodicAmountStartOf: 100,
    NoOfInstalment: 17,
    Comments: "",
    CalculatedSchedule: [
      {
        InstalmentNumber: 1,
        DueDate: "11-Mar-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 2,
        DueDate: "18-Mar-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 3,
        DueDate: "25-Mar-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 4,
        DueDate: "1-Apr-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 5,
        DueDate: "8-Apr-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 6,
        DueDate: "15-Apr-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 7,
        DueDate: "22-Apr-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 8,
        DueDate: "29-Apr-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 9,
        DueDate: "6-May-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 10,
        DueDate: "13-May-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 11,
        DueDate: "20-May-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 12,
        DueDate: "27-May-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 13,
        DueDate: "3-Jun-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 14,
        DueDate: "10-Jun-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 15,
        DueDate: "17-Jun-2021",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 16,
        DueDate: "24-Jun-20212",
        InstalmentAmount: 140,
      },
      {
        InstalmentNumber: 17,
        DueDate: "1-Jul-2021",
        InstalmentAmount: 140,
      },
    ],
  },
];

export const listFrequency = [
  { Code: "0", Name: "Custom" },
  { Code: "1", Name: "Weekly" },
  { Code: "2", Name: "Fortnightly" },
  { Code: "3", Name: "Monthly" },
  { Code: "4", Name: "Two Monthly" },
  { Code: "5", Name: "Three Monthly" },
];

export const initialForm = {
  Assessment: null,
  AssessmentNumber: 0,
  ArrangementDate: new Date(),
  AmountOfInitialDebt: 0,
  Applicant: "",
  Frequency: listFrequency[3],
  InitialPaymentOn: new Date(),
  InitialPaymentOf: 0,
  PeriodicAmountStartOn: new Date(),
  PeriodicAmountStartOf: 0,
  NoOfInstalment: 1,
  Comments: "",
  CalculatedSchedule: [],
  ...comboBoxSearchData,
};
