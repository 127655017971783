import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ISendForApproval } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/model";
import { SendForApprovalDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/_index";
import { useSendForApprovalButtonStore } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/send-for-approval/store";
import {
  PPREnquirySubmitActions,
  RegisterStatus,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { UITriggerRegister } from "@common/constants/uiTrigger";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const SendForApprovalButton = observer(() => {
  const {
    isLoading,
    selectedDocumentIDs,
    isShowCommentDialog,
    setSelectedDocumentIDs,
    setIsShowCommentDialog,
    handleSendForTLApproval,
  } = useSendForApprovalButtonStore();
  const { onSubmit, pprEnquiry, pprEnquiryId } = usePPREnquiryStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const isVisible = useMemo(() => {
    if (
      pprEnquiry?.Status_ENUM === RegisterStatus.Lodged ||
      pprEnquiry?.Status_ENUM === RegisterStatus.Rejected
    ) {
      return true;
    }

    return false;
  }, [pprEnquiry]);

  const objReason = useMemo(() => {
    const retVal = new ProvideReason();
    retVal.Title_Text = "Require Approval";
    retVal.ReasonLabel_Text = "Comments";
    retVal.Flag_ShowDate = false;
    retVal.Reason = "";
    return retVal;
  }, []);

  useAddUniqueEventEmitter([
    {
      eventType: UITriggerRegister.SendForApproval.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const handleSelectDocumentSubmit = (data: ISendForApproval) => {
    setSelectedDocumentIDs(data.SelectedDocumentIDs);
    setIsShowDialog(false);
    setIsShowCommentDialog(true);
  };

  const handleProvideReasonSubmit = (
    value: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    handleSendForTLApproval(
      notificationRef,
      pprEnquiryId,
      selectedDocumentIDs,
      value.Reason
    );
  };

  return (
    <>
      {isVisible ? (
        <CCNavButton
          title="Send for approval"
          onClick={onSubmit}
          name={PPREnquirySubmitActions.SendForApproval}
        />
      ) : null}

      {isShowDialog && (
        <SendForApprovalDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSelectDocumentSubmit}
          parentData={{
            Id: pprEnquiryId,
            recordType: RECORDTYPE.CORE_Register,
          }}
        />
      )}

      {isShowCommentDialog && (
        <ReasonDialog
          onClose={() => setIsShowCommentDialog(false)}
          onSubmit={handleProvideReasonSubmit}
          isLoading={isLoading}
          initialData={objReason}
        />
      )}
    </>
  );
});
