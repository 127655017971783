import { useAssessmentComplianceAccordionStore } from "@app/products/property/assessments/[id]/components/child-screens/compliance/store";
import {
  getLandTaxExemptionLOVs,
  postProcessCreateLandTaxExemption,
} from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/land-tax-exemption/api";
import { defaultWorkflowHeaderLandTaxExempt } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/land-tax-exemption/config";
import {
  DTO_LandTaxExemption,
  DTO_LandTaxExemption_LOVs,
  DTO_WorkflowDetail_CreateLandTaxExemption,
} from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/land-tax-exemption/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DTO_LandTaxExemption>();

interface ILandTaxExemptionDialog {
  onClose: () => void;
  assessmentId: number | undefined;
  titleHeaderDialog?: string;
  onSubmitDialog?: (event: any) => void;
}

export const LandTaxExemptionDialog = observer(
  ({
    onClose,
    assessmentId,
    titleHeaderDialog = "Land Tax Exemption",
  }: ILandTaxExemptionDialog) => {
    //Store
    const { pushNotification } = useCCAppNotificationStore();
    const { loadAssessmentCompliance } =
      useAssessmentComplianceAccordionStore();

    //State
    const [landTaxExemptLOVs, setLandTaxExemptLOVs] =
      useState<DTO_LandTaxExemption_LOVs>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    //ref local notification
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    /**
     * Process data before sending to API
     * @param data
     */
    const processData: any = (data: any) => {
      const landTaxExemptionData = {
        ...data,
        Assessment_Id: parseInt(assessmentId + ""),
      };
      if (landTaxExemptionData && landTaxExemptionData._option) {
        delete landTaxExemptionData._option;
      }

      const workflowDetail: DTO_WorkflowDetail_CreateLandTaxExemption = {
        LandTaxExemption: landTaxExemptionData as DTO_LandTaxExemption,
        SelectedSecondaryWorkflow: [],
      };
      return {
        WorkflowHeader: defaultWorkflowHeaderLandTaxExempt,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * Handle submit
     * @param values
     */
    const handleSubmit = async (values: any) => {
      setIsSubmitting(true);
      const response = await postProcessCreateLandTaxExemption(
        WorkflowProcessMode.Save,
        processData(values)
      );
      setIsSubmitting(false);
      const defaultSuccessMessage =
        "The land tax exemption was created successfully.";
      const defaultFailedMessage = "The land tax exemption was created failed.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        onClose();
        pushNotification({
          type: "success",
          title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
        });

        loadAssessmentCompliance(assessmentId);
      } else {
        notificationRef.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
      }
    };

    /**
     * Load land tax exemption LOVs
     */
    const loadLOVs = async () => {
      setIsLoading(true);
      const response = await getLandTaxExemptionLOVs();
      let errorResponse = undefined;
      if (isSuccessResponse(response)) {
        setLandTaxExemptLOVs(response?.data?.dtoLandTaxExemptionLOVs);
      } else {
        errorResponse = {
          status: response?.status,
          error: response?.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadLOVs();
    });

    return (
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { modified, valid, onSubmit, onChange, valueGetter } =
            formRenderProps;
          return (
            <CCDialog
              titleHeader={titleHeaderDialog}
              onClose={onClose}
              height="380px"
              maxWidth="40%"
              bodyElement={
                <div
                  className="cc-land-tax-exemption-dialog cc-form"
                  style={{ height: "100%" }}
                >
                  {
                    //Loading
                    isLoading ? (
                      <Loading isLoading />
                    ) : //Load other data failed
                    responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          loadLOVs();
                        }}
                      />
                    ) : (
                      <FormElement className="cc-field-group">
                        <CCLocalNotification ref={notificationRef} />
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              To apply from
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("Active_From_Date")}
                              component={CCDatePicker}
                              max={
                                valueGetter(nameOf("Active_To_Date")) ??
                                undefined
                              }
                              validator={requiredValidator}
                              format={DATE_FORMAT.DATE_CONTROL}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">To</label>
                            <Field
                              name={nameOf("Active_To_Date")}
                              component={CCDatePicker}
                              min={
                                valueGetter(nameOf("Active_From_Date")) ??
                                undefined
                              }
                              format={DATE_FORMAT.DATE_CONTROL}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Type
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("LandTaxExemptionType")}
                              component={CCSearchComboBox}
                              data={
                                landTaxExemptLOVs?.LandTaxExemption_Type ?? []
                              }
                              validator={requiredValidator}
                              value={getDropdownValue(
                                valueGetter(nameOf("LandTaxExemptionType")),
                                landTaxExemptLOVs?.LandTaxExemption_Type ?? [],
                                "Code"
                              )}
                              textField="Name"
                              dataItemKey="Code"
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("LandTaxExemptionType", {
                                  value: event.value?.Code ?? null,
                                });
                                onChange("LandTaxExemptionTypeName", {
                                  value: event.value?.Name ?? null,
                                });
                              }}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Reason
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("Reason")}
                              component={CCTextArea}
                              placeholder="Reason"
                              rows={3}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                      </FormElement>
                    )
                  }
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                    themeColor="primary"
                    disabled={!modified || !valid || isSubmitting}
                    onClick={onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
