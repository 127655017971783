import { DTO_AssessmentRebateEntitlement } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import EntitledToRebateCardsDetails from "./components/entitled-to-rebate/_index";
import { colConcessionCardsDetail } from "./config";

const ConcessionCardsDetails = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const assessmentId = dataItem.assessmentId;
  const [dataConcessionCardsDetail, setDataConcessionCardsDetail]: any =
    useState<DTO_AssessmentRebateEntitlement[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (
      !dataItem.AssessmentRebateEntitlements ||
      dataItem.AssessmentRebateEntitlements.length === 0
    ) {
      setIsLoading(false);
      return;
    }
    setDataConcessionCardsDetail(
      dataItem.AssessmentRebateEntitlements.map(
        (item: DTO_AssessmentRebateEntitlement) => ({
          ...item,
          assessmentId,
        })
      )
    );
    setIsLoading(false);
  }, [assessmentId, dataItem]);

  return (
    <>
      <label className="cc-label">
        Associated assessment rebate entitlements
      </label>
      <CCGrid
        isLoading={isLoading}
        className="cc-concession-cards-details-grid"
        data={dataConcessionCardsDetail}
        columnFields={colConcessionCardsDetail}
        primaryField="Id"
        detail={EntitledToRebateCardsDetails}
      />
    </>
  );
};
export default ConcessionCardsDetails;
