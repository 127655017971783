import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { isEmpty, isNil } from "lodash";

const checkMobileLocal = (value: string) => {
  let valueAutoFormat = value?.replace(/\s+/g, "");
  //#region Check Local ========/
  const rgMobilePhoneLocal = /(^04)(\d{2})(\d{3})(\d{3})(\d+|)/g;
  const isMobilePhoneLocal = rgMobilePhoneLocal.exec(valueAutoFormat);
  rgMobilePhoneLocal.lastIndex = 0;
  if (isMobilePhoneLocal && isEmpty(isMobilePhoneLocal[5])) {
    valueAutoFormat = valueAutoFormat.replace(rgMobilePhoneLocal, `$1$2 $3 $4`);
    return valueAutoFormat;
  }
  //#endregion Check Local =====/
  return "";
};

const checkMobileInternational = (value: string) => {
  let valueAutoFormat = value?.replace(/\s+/g, "");
  //#region Check International ========/
  const rgMobilePhoneInternational =
    /(^\+61|^61)(04|4)(\d{2})(\d{3})(\d{3})(\d+|)/g;
  const isMobilePhoneInternational =
    rgMobilePhoneInternational.exec(valueAutoFormat);
  // rgMobilePhoneInternational.lastIndex = 0;
  if (isMobilePhoneInternational && isEmpty(isMobilePhoneInternational[6])) {
    valueAutoFormat = valueAutoFormat.replace(
      rgMobilePhoneInternational,
      `+61 4$3 $4 $5`
    );
    return valueAutoFormat;
  }
  //#endregion Check International =====/
  return "";
};

const checkLandlineLocal = (value: string) => {
  let valueAutoFormat = value?.replace(/\s+/g, "");
  //#region Check Local ========/
  const rgLandPhoneLocal = /^(02|03|07|08)(\d{4})(\d{4})(\d+|)/g;
  const isLandPhoneLocal = rgLandPhoneLocal.exec(valueAutoFormat);
  rgLandPhoneLocal.lastIndex = 0;
  if (isLandPhoneLocal && isEmpty(isLandPhoneLocal[4])) {
    valueAutoFormat = valueAutoFormat.replace(rgLandPhoneLocal, `$1 $2 $3`);
    return valueAutoFormat;
  } else if (valueAutoFormat.length === 8) {
    const rgLandPhoneNoPrefixLocal = /^(\d{4})(\d{4})(\d+|)/g;
    const isLandPhoneLocal = rgLandPhoneNoPrefixLocal.exec(valueAutoFormat);
    if (isLandPhoneLocal && isEmpty(isLandPhoneLocal[3])) {
      valueAutoFormat = valueAutoFormat.replace(
        rgLandPhoneNoPrefixLocal,
        `$1 $2`
      );
      return valueAutoFormat;
    }
  }
  //#endregion Check Local =====/
  return "";
};

const checkLandlineInternational = (value: string) => {
  let valueAutoFormat = value?.replace(/\s+/g, "");
  //#region Check International ========/
  const rgLandPhoneInternational =
    /(^\+61|^61)(02|2|03|3|07|7|08|8)(\d{4})(\d{4})(\d+|)/g;
  const isLandPhoneInternational =
    rgLandPhoneInternational.exec(valueAutoFormat);
  rgLandPhoneInternational.lastIndex = 0;

  if (isLandPhoneInternational && isEmpty(isLandPhoneInternational[5])) {
    valueAutoFormat = valueAutoFormat.replace(
      rgLandPhoneInternational,
      (_match, _p1, p2, p3, p4) => {
        return `+61 ${p2.replace(0, "")} ${p3} ${p4}`;
      }
    );
    return valueAutoFormat;
  }
  //#endregion Check International =====/
  return "";
};

const checkFreeCellLocal = (value: string) => {
  let valueAutoFormat = value?.replace(/\s+/g, "");
  const rgTollFreeLocal = /^(1300|1800)(\d{3})(\d{3})(\d+|)/g;
  const isTollFreeLocal = rgTollFreeLocal.exec(valueAutoFormat);
  rgTollFreeLocal.lastIndex = 0;

  const rgTollFreeLocal2 = /^(13)(\d{2})(\d{2})(\d+|)/g;
  const isTollFreeLocal2 = rgTollFreeLocal2.exec(valueAutoFormat);
  rgTollFreeLocal2.lastIndex = 0;

  if (isTollFreeLocal && isEmpty(isTollFreeLocal[4])) {
    valueAutoFormat = valueAutoFormat.replace(rgTollFreeLocal, `$1 $2 $3`);
    return valueAutoFormat;
  } else if (isTollFreeLocal2 && isEmpty(isTollFreeLocal2[4])) {
    valueAutoFormat = valueAutoFormat.replace(rgTollFreeLocal2, `$1 $2 $3`);
    return valueAutoFormat;
  }
  return "";
};

const checkIsValueEmptyAndUndefined = (value: string | undefined) => {
  return isNil(value) && isEmpty(value);
};

export const handleValidatePhone = (
  value: string,
  mode: EModePhoneNumber,
  readOnly: boolean | undefined,
  disabled: boolean | undefined
) => {
  //#region Uncomment when the requirement is changes ==============================/
  // const skipMode = true; //NOTE: Skip all Mode, always check and combine all rule
  //#endregion Uncomment when the requirement is changes ==============================/
  let hasError = false;
  let valueAutoFormat = value?.replace(/\s+/g, "") ?? "";
  let countValue = valueAutoFormat?.length;
  let isInternational =
    /^\+/.exec(valueAutoFormat) ||
    (/^61/.exec(valueAutoFormat) && valueAutoFormat?.length > 8);

  if (isInternational) {
    if (
      !readOnly &&
      !disabled &&
      (valueAutoFormat === "+" || valueAutoFormat === "+6")
    ) {
      hasError = true;
      valueAutoFormat = value;
      return { valueAutoFormat, hasError };
    }
    if (/^\+61|^61/.exec(valueAutoFormat)) {
      //#region Uncomment when the requirement is changes ==============================/
      // if (!skipMode && mode !== EModePhoneNumber.Fax) {
      //   if (/(^\+61|^61)(04|4|02|2|03|3|07|7|08|8)/g.exec(valueAutoFormat)) {
      //     //#region Australia ========/
      //     if (mode === EModePhoneNumber.Private) {
      //       if (checkMobileInternational(value)) {
      //         valueAutoFormat = checkMobileInternational(value);
      //       } else if (checkLandlineInternational(value)) {
      //         valueAutoFormat = checkLandlineInternational(value);
      //       } else {
      //         hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //           ? true
      //           : false;
      //       }
      //     }
      //     if (mode === EModePhoneNumber.Mobile) {
      //       if (checkMobileInternational(value)) {
      //         valueAutoFormat = checkMobileInternational(value);
      //       } else {
      //         hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //           ? true
      //           : false;
      //       }
      //     }
      //     if (
      //       mode === EModePhoneNumber.Business ||
      //       mode === EModePhoneNumber.WorkPhone
      //     ) {
      //       if (checkLandlineInternational(value)) {
      //         valueAutoFormat = checkLandlineInternational(value);
      //       } else {
      //         hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //           ? true
      //           : false;
      //       }
      //     }
      //     if (mode === EModePhoneNumber.HomePhone) {
      //       if (checkLandlineInternational(value)) {
      //         valueAutoFormat = checkLandlineInternational(value);
      //       } else {
      //         hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //           ? true
      //           : false;
      //       }
      //     }
      //     //#endregion Australia =====/
      //   } else if (
      //     (/(^\+61)(1300|1800)/g.exec(valueAutoFormat) &&
      //       valueAutoFormat?.length === 13) ||
      //     (/(^61)(1300|1800)/g.exec(valueAutoFormat) &&
      //       valueAutoFormat?.length === 12) ||
      //     (/(^\+61)(13)/g.exec(valueAutoFormat) &&
      //       valueAutoFormat?.length === 9)
      //   ) {
      //     hasError = false;
      //   } else {
      //     valueAutoFormat = value;
      //     hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //       ? true
      //       : false;
      //   }
      // }
      // else {
      //#endregion Uncomment when the requirement is changes ==============================/
      if (checkMobileInternational(value)) {
        valueAutoFormat = checkMobileInternational(value);
      } else if (checkLandlineInternational(value)) {
        valueAutoFormat = checkLandlineInternational(value);
      } else if (
        (/(^\+61)(1300|1800)/g.exec(valueAutoFormat) &&
          valueAutoFormat?.length === 13) ||
        (/(^61)(1300|1800)/g.exec(valueAutoFormat) &&
          valueAutoFormat?.length === 12) ||
        (/(^\+61)(13)/g.exec(valueAutoFormat) && valueAutoFormat?.length === 9)
      ) {
        hasError = false;
      } else {
        hasError = true;
      }
      //#region Uncomment when the requirement is changes ==============================/
      // }
      //#endregion Uncomment when the requirement is changes ==============================/
    } else {
      hasError = false;
    }
  } else {
    //#region Local Australia ==============================/
    if (
      /(04|4|02|2|03|3|07|7|08|8|1300|1800|13)/g.exec(valueAutoFormat) ||
      countValue === 8
    ) {
      //#region Uncomment when the requirement is changes ==============================/

      // if (!skipMode && mode !== EModePhoneNumber.Fax) {
      //   if (mode === EModePhoneNumber.Private) {
      //     if (checkMobileLocal(value)) {
      //       valueAutoFormat = checkMobileLocal(value);
      //     } else if (checkLandlineLocal(value)) {
      //       valueAutoFormat = checkLandlineLocal(value);
      //     } else {
      //       hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //         ? true
      //         : false;
      //     }
      //   }
      //   if (mode === EModePhoneNumber.Mobile) {
      //     if (checkMobileLocal(value)) {
      //       valueAutoFormat = checkMobileLocal(value);
      //     } else {
      //       hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //         ? true
      //         : false;
      //     }
      //   }
      //   if (
      //     mode === EModePhoneNumber.Business ||
      //     mode === EModePhoneNumber.WorkPhone
      //   ) {
      //     if (checkLandlineLocal(value)) {
      //       valueAutoFormat = checkLandlineLocal(value);
      //     } else if (checkFreeCellLocal(value)) {
      //       valueAutoFormat = checkFreeCellLocal(value);
      //     } else {
      //       hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //         ? true
      //         : false;
      //     }
      //   }
      //   if (mode === EModePhoneNumber.HomePhone) {
      //     if (checkLandlineLocal(value)) {
      //       valueAutoFormat = checkLandlineLocal(value);
      //     } else {
      //       hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat)
      //         ? true
      //         : false;
      //     }
      //   }
      // } else {
      //#endregion Uncomment when the requirement is changes ==============================/
      if (checkMobileLocal(value)) {
        valueAutoFormat = checkMobileLocal(value);
      } else if (checkLandlineLocal(value)) {
        valueAutoFormat = checkLandlineLocal(value);
      } else if (checkFreeCellLocal(value)) {
        valueAutoFormat = checkFreeCellLocal(value);
      } else {
        hasError = true;
      }
      //#region Uncomment when the requirement is changes ==============================/
      // }
      //#endregion Uncomment when the requirement is changes ==============================/
    }
    //#endregion Local Australia ===========================/
    else {
      valueAutoFormat = value;
      hasError = !checkIsValueEmptyAndUndefined(valueAutoFormat) ? true : false;
    }
  }

  if (readOnly || disabled) hasError = false;
  return { valueAutoFormat, hasError };
};

export const validatePhoneNumberFunction = (
  value: string,
  required?: boolean
) => {
  // Return empty string if value is nil and not required
  if (!required && isNil(value)) {
    return "";
  }
  // Return required message if value is nil or empty and required
  if (required && (isNil(value) || isEmpty(value))) {
    return "This field is required.";
  }
  // Check for invalid phone number format
  if (value?.includes("hasError")) {
    return "Phone number is not in a valid format.";
  }
  // If none of the conditions are met, return an empty string
  return "";
};

export const validatePhoneNumber = (value: string) =>
  validatePhoneNumberFunction(value);

export const validatePhoneNumberRequired = (value: string) =>
  validatePhoneNumberFunction(value, true);

export const handleFormatPhoneValue = (
  value: string,
  mode: EModePhoneNumber,
  readOnly: boolean | undefined,
  disabled: boolean | undefined
) => {
  let result = value;
  const resultValidate = handleValidatePhone(value, mode, readOnly, disabled);
  if (resultValidate.valueAutoFormat !== value) {
    result = resultValidate.valueAutoFormat;
  }
  if (resultValidate.hasError && !isEmpty(result)) {
    result = value + "hasError";
  }
  return { result, resultValidate };
};
