import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { NoticeRunRequestForm } from "@app/products/property/schemes/notice-runs/[id]/components/child-screens/general/components/_index";
import { ExportSchemeNoticeButton } from "@app/products/property/schemes/notice-runs/[id]/components/forms/existed/components/action-bar/buttons/export-scheme-notice/_index";
import { NoticeRunDetailReference } from "@app/products/property/schemes/notice-runs/[id]/components/reference-sidebar/details/_index";
import { useSchemesNoticeRunsStore } from "@app/products/property/schemes/notice-runs/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedNoticeRun = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const {
    loadNoticeRuns,
    schemesNoticeRuns,
    schemesNoticeRunsId,
    isLoading,
    responseLoadError,
  } = useSchemesNoticeRunsStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text:
        "Schemes " +
        (schemesNoticeRuns?.NoticeRunId
          ? `- ${schemesNoticeRuns?.NoticeRunId}`
          : ""),
      LinkUrl: managePageUrl,
      LinkText: `Property - Schemes - ${schemesNoticeRunsId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: schemesNoticeRunsId,
    },
  ];

  const getFormTitle = () => {
    return `ID ${schemesNoticeRuns?.NoticeRunId}`;
  };

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Notice Run" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadNoticeRuns(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle()} badge={""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Allocate to planner"} />
                <CCNavButton title={"Failure"} />
                <CCNavButton title={"Revision received"} />
                <ExportSchemeNoticeButton noticeRunInfo={schemesNoticeRuns} />
                <CCNavButton title={"Withdraw application"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Scheme}
                />
                <CCNavButton title={"Add contacts"} />
                <CCNavButton title={"Add documents"} />
                <CCNavButton title={"Add fees"} />
                <CCNavButton title={"Add samples"} />
                <CCNavButton title={"Add inspection"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} type="sub">
                <CCNavButton title={"View notices"} onClick={() => {}} />
              </CCNavButton>,

              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"ePlanning - development"} />
                <CCNavButton title={"Portal links"} />
                <CCNavButton title={"Certification"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {schemesNoticeRuns && (
                  <CCGeneralPanel component={<NoticeRunRequestForm />} />
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <NoticeRunDetailReference />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
