import { NoticeClassification } from "@common/constants/enumerations";
import { SystemFilter } from "@common/pages/settings/lookups/components/system-filter/_index";
import { NoticeType } from "@common/pages/settings/lookups/notice-types/_id/model";
import { useNoticeTypesStore } from "@common/pages/settings/lookups/notice-types/_id/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

interface ILookupNoticeTypeFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<NoticeType>();

export const LookupNoticeTypeFormElement = observer(
  ({ formRenderProps }: ILookupNoticeTypeFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { noticeTypeLovs, isInactive } = useNoticeTypesStore();

    const NoticeTypeField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement className="cc-notice-type-form-elements">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Name" isMandatory />
              <NoticeTypeField
                name={nameOf("Name")}
                component={CCInput}
                placeholder="Name"
                validator={requiredValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Section of legislation" />
              <NoticeTypeField
                name={nameOf("Description")}
                component={CCTextArea}
                rows={4}
                placeholder="Section of legislation"
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Classification" isMandatory />
              <NoticeTypeField
                name={nameOf("NoticeClassification_ENUM")}
                textField="Value"
                dataItemKey="Key"
                data={noticeTypeLovs?.NoticeClassification ?? []}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOf("NoticeClassification_ENUM")),
                  noticeTypeLovs?.NoticeClassification ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("NoticeClassification_ENUM"), {
                    value: event.value?.Key,
                  });
                  onChange(nameOf("NoticeClassification_Name"), {
                    value: event.value?.Value,
                  });
                }}
                validator={requiredValidator}
              />
            </div>
            {valueGetter(nameOf("NoticeClassification_ENUM")) ===
              NoticeClassification.Infringement && (
              <div className="cc-field">
                <CCLabel title="Penalty units" />
                <NoticeTypeField
                  name={nameOf("PenaltyUnits")}
                  component={CCNumericTextBox}
                  min={0}
                />
              </div>
            )}
            <div className="cc-field">
              <CCLabel title="Code" />
              <NoticeTypeField
                name={nameOf("Code")}
                component={CCInput}
                placeholder="Code"
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="FSR code" />
              <NoticeTypeField
                name={nameOf("FSRCode_ENUM")}
                textField="Value"
                dataItemKey="Key"
                data={noticeTypeLovs?.FSRCode ?? []}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  valueGetter(nameOf("FSRCode_ENUM")),
                  noticeTypeLovs?.FSRCode ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("FSRCode_ENUM"), {
                    value: event.value?.Key,
                  });
                  onChange(nameOf("FSRCode_Name"), {
                    value: event.value?.Value,
                  });
                }}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Expose to API" />
              <NoticeTypeField
                name={nameOf("Flag_ExposeToAPI")}
                checked={valueGetter(nameOf("Flag_ExposeToAPI")) ?? false}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Mandatory compliance due" />
              <NoticeTypeField
                name={nameOf("Flag_MandatoryComplianceDue")}
                checked={
                  valueGetter(nameOf("Flag_MandatoryComplianceDue")) ?? false
                }
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Sort code" />
              <NoticeTypeField
                name={nameOf("SortCode")}
                component={CCInput}
                placeholder="Sort code"
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <SystemFilter
                formRenderProps={formRenderProps}
                readOnly={isInactive}
              ></SystemFilter>
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
