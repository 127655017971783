import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { AssessmentDebtRecoveryAssociationTab } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/associations/_index";
import { AssessmentDebtRecoveryDetailTab } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/detail/_index";
import { ASSESSMENTS_DEBT_RECOVERY_ROUTE } from "@app/products/property/assessments/debt-recovery/[id]/constant";
import { DebtRecoveryDetailComponent } from "@app/products/property/assessments/debt-recovery/list/all/components/detail/_index";
import { DEBT_RECOVERY_LIST_VIEW_URL } from "@app/products/property/assessments/debt-recovery/list/all/constant";
import { VO_Assessment_Debt_Recovery } from "@app/products/property/assessments/debt-recovery/list/all/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/debt-recovery/list/util";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { NewDebtRecoveryButton } from "@app/products/property/components/action-bar/button/new-debt-recovery/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colListAll } from "./config";

const nameOf = nameOfFactory<VO_Assessment_Debt_Recovery>();
const gridId = getUUID();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewDebtRecoveryButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Debt_Recovery}
        recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
        keyField={"Debt_Recovery_Id"}
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton gridId={gridId} />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ASSESSMENTS_DEBT_RECOVERY_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDebtRecoveryDetailTab /> },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab componentNumber={eComponent.Debt_Recovery} />
        ),
      },
      {
        title: "Related",
        component: <AssessmentDebtRecoveryAssociationTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      gridId={gridId}
      dataUrl={DEBT_RECOVERY_LIST_VIEW_URL}
      detail={DebtRecoveryDetailComponent}
      columnFields={colListAll}
      primaryField={nameOf("Debt_Recovery_Id")}
      state={{
        sort: [{ field: "Debt_Recovery_Id", dir: "desc" }],
      }}
    />
  );
};
