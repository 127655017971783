import { Svc_ContactDetails } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { ContactLookahead_JSON } from "@common/models/contact";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfInternalContact = nameOfFactory<Svc_ContactDetails>();
const nameOfExternalContact = nameOfFactory<ContactLookahead_JSON>();

export const colInternalContact: IColumnFields[] = [
  {
    field: nameOfInternalContact("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfInternalContact("Email"),
    title: "Email",
  },
  {
    field: nameOfInternalContact("LoginName"),
    title: "Login Name",
  },
];

export const colExternalContact: IColumnFields[] = [
  {
    field: nameOfExternalContact("AddressBook"),
    title: "Address Book",
  },
  {
    field: nameOfExternalContact("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfExternalContact("Phone"),
    title: "Phone",
  },
  {
    field: nameOfExternalContact("ContactInfo"),
    title: "Address",
  },
];
