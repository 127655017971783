import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { cloneDeep } from "lodash";

export const transformDataGlobalSettingsModule = (SettingFields: any[]) => {
  return cloneDeep(SettingFields).map((item: any) => {
    item.contentGroup = item.Title;
    const configArr = item.contentGroup.split("-");
    item.Title = configArr[1] ?? configArr[0];
    const configs = configArr[0];
    item.group = configs.split("|")[0];
    item.Type = configs.split("|")[1];

    if (item.Type === "Title") {
      if (item.group === `${PRODUCT_TYPE_NUMBER.HealthManager}`) {
        item.Title = "Health Manager Settings";
      }
      if (item.group === `${PRODUCT_TYPE_NUMBER.TownPlanning}`) {
        item.Title = "TownPlanning / PNF Settings";
      }
      if (item.group === `${PRODUCT_TYPE_NUMBER.CustomerService}`) {
        item.Title = "Customer Service (CRMS) Settings";
      }
      if (item.group === `${PRODUCT_TYPE_NUMBER.CSM}`) {
        item.Title = "CSM Settings";
      }
      if (item.group === `${PRODUCT_TYPE_NUMBER.LLPermits}`) {
        item.Title = "Local Laws Permit (LLP) Settings";
      }
    }
    return item;
  });
};
