import {
  IPropertyActions,
  VO_Workflow_Draft_Related,
} from "@app/products/property/components/child-screen/actions/model";
import { getUrlAction } from "@app/products/town-planning/ppr/actions/util";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPropertyActions>();
const nameOfWorkflows = nameOfFactory<VO_Workflow_Draft_Related>();

export const colWorkflows: IColumnFields[] = [
  {
    field: nameOfWorkflows("Workflow_Draft_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
  },
  {
    field: nameOfWorkflows("Workflow"),
    title: "Workflow",
  },
  {
    field: nameOfWorkflows("Workflow_Status_Name"),
    title: "Status",
  },
  {
    field: nameOfWorkflows("Workflow_Approval_Status_Name"),
    title: "Approval Status",
  },
  {
    field: nameOfWorkflows("Assessment_Group"),
    title: "Region",
  },
  {
    field: nameOfWorkflows("Created_By"),
    title: "Officer",
  },
  {
    field: nameOfWorkflows("Created_On"),
    title: "Created",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfWorkflows("Modified_On"),
    title: "Modified",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfWorkflows("Approved_By"),
    title: "Approved By",
  },
  {
    field: nameOfWorkflows("Approved_On"),
    title: "Approved",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfWorkflows("Rejected_By"),
    title: "Rejected By",
  },
  {
    field: nameOfWorkflows("Rejected_On"),
    title: "Rejected",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfWorkflows("Rejected_Reason"),
    title: "Rejected Reason",
  },
  {
    field: nameOfWorkflows("Cancelled_By"),
    title: "Cancelled By",
  },
  {
    field: nameOfWorkflows("Cancelled_On"),
    title: "Cancelled",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfWorkflows("Cancelled_Reason"),
    title: "Cancelled Reason",
  },
  {
    field: nameOfWorkflows("Parent_Id"),
    title: "Parent ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];

export const colPropertyActions: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: getUrlAction,
  },
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action By",
  },
  {
    field: nameOf("Description"),
    title: "Details",
  },
  {
    field: nameOf("DueDate"),
    title: "Due On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Outcome"),
    title: "Outcome",
  },
];
