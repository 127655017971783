import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const MessageHTMLCell = (props: GridCellProps) => {
  const { dataItem, columnIndex } = props;

  return (
    <td key={columnIndex} role="gridcell">
      {sanitizeHtml(dataItem.Message)}
    </td>
  );
};
