export enum EDeferredDutySearchBy {
  "Property Address",
  "Account Description",
  "Assessment Owner/Ratepayer",
  "Assessment Id",
  "DDA Account Id",
  "Account Number",
}

export interface DTO_DeferredDuty_AccountLookupObj {
  LookupKey: number;
  LookupValue: string;
  Statuses: number[];
}

export interface DTO_DeferredDuty_AccountLookup {
  DeferredDutyAccounts: DTO_DeferredDuty_Account[];
}

export interface DTO_DeferredDuty_AccountLookupObj {
  LookupKey: number;
  LookupValue: string;
  Statuses: number[];
}

export interface DTO_DeferredDuty_Account {
  AccountId: number;
  AccountNumber: number | null;
  AccountName: string;
  AssessmentNumber: number | null;
  AssessmentReference: string;
  PropertyAddress: string;
  Description: string;
  LegalDescription: string;
  Status: string;
  DutyBalance: number | null;
  Interest: number | null;
  OtherAmount: number | null;
  DDSTextColour: number | null;
  AssessmentId: number | null;
}
export enum DDAccountStatus {
  NewAccount = 0,
  Active = 1,
  UnabletoCalculateStatus = 2,
  ActiveNoInterest = 3,
  Inactive = 9,
  PaidOut = 10,
  Overpaid = 11,
  DeferredDutyCancelled = 12,
}
