import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const llsTendersRoute: ICCRoute = {
  path: "tenders",
  name: "Tenders",
  enum: LLPermitsMenu.Tenders,
  children: [
    {
      path: "register",
      name: "Register",
      enum: LLPermitsMenu.LLSTendersRegister,
      component: require("../lls-permits/register/_index").default,
    },
    {
      path: "by-region",
      name: "By Region",
      enum: LLPermitsMenu.LLSTendersRegisterByRegion,
      component: require("../lls-permits/register/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: LLPermitsMenu.LLSTendersRegisterByOfficer,
      component: require("../lls-permits/register/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: LLPermitsMenu.LLSTendersRegisterByType,
      component: require("../lls-permits/register/_index").default,
    },
    {
      path: "bt-status",
      name: "By Status",
      enum: LLPermitsMenu.LLSTendersRegisterByStatus,
      component: require("../lls-permits/register/_index").default,
    },
  ],
};
