import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IRespActionType>();

export const colActionTypePickerDialog: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  { title: "Description", field: nameOf("Description") },
];
