import { requiredValidator } from "@common/utils/field-validators";

export const booleanValuesList = [
  {
    text: "Yes",
    operator: true,
  },
  {
    text: "No",
    operator: false,
  },
];

export const enumValuesList = [
  { text: "Is equal to", operator: "eq" },
  { text: "Is not equal", operator: "neq" },
];

export const getListsOfType = (type?: string) => {
  switch (type) {
    case "numeric":
      return [
        { text: "Is equal to", operator: "eq" },
        { text: "Is not equal", operator: "neq" },
        { text: "Is greater than or equal to", operator: "gte" },
        { text: "Is greater than", operator: "gt" },
        { text: "Is less than or equal to", operator: "lte" },
        { text: "Is less than", operator: "lt" },
        { text: "Is null", operator: "isnull" },
        { text: "Is not null", operator: "isnotnull" },
      ];
    case "date":
      return [
        {
          text: "Is after",
          operator: "gt",
        },
        {
          text: "Is before",
          operator: "lt",
        },
        { text: "Is null", operator: "isnull" },
      ];
    case "boolean":
      return booleanValuesList;
    case "text":
    default:
      return [
        { text: "Contains", operator: "contains" },
        { text: "Does not contain", operator: "doesnotcontain" },
        { text: "Is equal to", operator: "eq" },
        { text: "Is not equal to", operator: "neq" },
        { text: "Starts with", operator: "startswith" },
        { text: "Ends with", operator: "endswith" },
        { text: "Is null", operator: "isnull" },
        { text: "Is not null", operator: "isnotnull" },
        { text: "Is empty", operator: "isempty" },
        { text: "Is not empty", operator: "isnotempty" },
      ];
  }
};

export const logicDataFilterList = [
  {
    text: "And",
    operator: "and",
  },
  {
    text: "Or",
    operator: "or",
  },
];
export interface IOptionGetCurrentFilter {
  0: number;
  1: number;
  "between-filter": string;
}

export const listUniqueNameOperator = ["operator-1", "operator-2"];

export const getItemLogic = (value?: string) => {
  if (value === "or") return logicDataFilterList[1];
  return logicDataFilterList[0];
};

export const listOperatorsNull: string[] = [
  "isnull",
  "isnotnull",
  "isempty",
  "isnotempty",
];

export const isIncludeListNull = (operator?: string | Function): boolean => {
  if (!operator || typeof operator === "function") return false;
  return listOperatorsNull.includes(operator);
};

export const isValidValue = (data: any) => {
  if (requiredValidator(data).length) return false;
  return true;
};

export enum configEnumNameFilter {
  Logic_1 = "operator-1_logic",
  Value_1 = "operator-1",
  Logic_2 = "operator-2_logic",
  Value_2 = "operator-2",
}
