import {
  BuildDialog_DateExtended,
  nameOfDateExtended,
} from "@app/core/components/dialogs/date-extended/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

export interface IDateExtendedDialogProps {
  initialValues: BuildDialog_DateExtended;
  notifications?: IAppNotificationItem[];
  isLoading?: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (dataItem: BuildDialog_DateExtended) => void;
}

export const DateExtendedDialog = ({
  initialValues,
  notifications,
  isLoading = false,
  isSubmitting = false,
  onClose,
  onSubmit,
}: IDateExtendedDialogProps) => {
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const data = event.values as BuildDialog_DateExtended;
    onSubmit(data);
  };

  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, valid } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="25%"
              height="auto"
              titleHeader={initialValues.WindowTitle}
              onClose={onClose}
              disabled={isSubmitting}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <>
                    {notifications?.length ? (
                      <div>
                        <CCLocalNotification
                          defaultNotifications={notifications}
                        />
                      </div>
                    ) : null}
                    <div className="cc-form">
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              {initialValues.DateCaption}
                              <CCTooltip
                                type="validator"
                                content={`${initialValues?.DateCaption}  " must be filled"`}
                              />
                            </label>
                            <Field
                              name={nameOfDateExtended("SelectedDate")}
                              component={CCDatePicker}
                              validator={requiredValidator}
                              format={DATE_FORMAT.DATE_CONTROL}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              {initialValues.CommentCaption}
                              {initialValues?.MandatoryComment && (
                                <CCTooltip
                                  type="validator"
                                  content={`${initialValues?.CommentCaption}  " must be filled"`}
                                />
                              )}
                            </label>
                            <Field
                              name={nameOfDateExtended("Comment")}
                              component={CCTextArea}
                              rows={5}
                              validator={
                                initialValues?.MandatoryComment
                                  ? requiredValidator
                                  : undefined
                              }
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  </>
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!valid}
                    themeColor="primary"
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                    iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
