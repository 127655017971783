import { useRejectLetterAppealButtonStore } from "@common/pages/appeal/_id/components/buttons/workflow/reject-letter/store";
import {
  APPEALSTATUS,
  AppealSubmitActions,
} from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const RejectLetterAppealButton = observer(() => {
  const { isLoading } = useRejectLetterAppealButtonStore();
  const { appeal, onSubmit } = useAppealStore();
  const isVisible = useMemo(
    () => appeal?.WorkFlowStatus_ENUM === APPEALSTATUS.AwaitingApproval,
    [appeal]
  );

  return isVisible ? (
    <CCNavButton
      title={"Reject letter"}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={onSubmit}
      name={AppealSubmitActions.RejectLetter}
    />
  ) : null;
});
