import { ICCRoute } from "@common/constants/ICCRoute";
export const stateRoadsRoute: ICCRoute = {
  path: "state-roads",
  name: "State Roads",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
