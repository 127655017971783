import { SAP } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SAP>();
export const colSAPContact: IColumnFields[] = [
  {
    field: nameOf("SAP_Number"),
    title: "SAP",
    editable: true,
  },
  {
    field: nameOf("Region_Name"),
    title: "Region",
  },
];
