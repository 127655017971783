import {
  DTO_NoticeOutputHistory,
  DTO_NoticeRunTotal,
} from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOfNotices = nameOfFactory<DTO_NoticeRunTotal>();
export const colNotices: IColumnFields[] = [
  {
    field: nameOfNotices("Description"),
    title: "Description",
  },
  {
    field: nameOfNotices("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    width: 150,
  },
  {
    field: nameOfNotices("InterestBalance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    width: 150,
  },
  {
    field: nameOfNotices("Total"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
    width: 150,
  },
];
const nameOfHistory = nameOfFactory<DTO_NoticeOutputHistory>();
export const colOutputHistory: IColumnFields[] = [
  {
    field: nameOfHistory("ProduceOn"),
    title: "Produce on",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHistory("ProduceBy"),
    title: "Produce by",
  },
  {
    field: nameOfHistory("NoticeGroup"),
    title: "Notice group",
  },
  {
    field: nameOfHistory("OutputFormat"),
    title: "Output format",
  },
  {
    field: nameOfHistory("FileName"),
    title: "File name",
  },
];
