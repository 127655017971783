import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedPropertyCertificate } from "@app/products/property/certificates/[id]/components/forms/existed/_index";
import { NewPropertyCertificate } from "@app/products/property/certificates/[id]/components/forms/new/_index";
import { managePagePropertyWrapper } from "@app/products/property/components/action-bar/property-workflow/component/hoc/manage-page-property-wrapper";

const ManagePropertyCertificate = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const certificateId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { resetStore, loadCertificate, isLoading } = useCertificateStore();
  const location = useLocation();
  const state: any = location?.state;
  const history = useHistory();

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      history.replace(location.pathname, "");
    }
    loadCertificate(+certificateId, notification);
  }, [
    certificateId,
    isNew,
    loadCertificate,
    state,
    history,
    location.pathname,
  ]);

  if (isLoading) return <Loading isLoading isFullScreen />;

  if (isNew) {
    return <NewPropertyCertificate />;
  }
  return <ExistedPropertyCertificate />;
});

export default managePagePropertyWrapper(ManagePropertyCertificate);
