import {
  appCategoryFields,
  covenantType,
  enforcementIssue_CouncilOfficerFields,
  feeScheduleFields,
  finalOutcomeProposedFields,
  plannerFields,
  preAppMetting_CoucilOfficerFields,
} from "@app/products/town-planning/[id]/components/child-screens/general/components/form-element/config";
import { mockType } from "@app/products/town-planning/[id]/components/child-screens/general/components/form-element/mock";
import { TownPlanningRegisterForm } from "@app/products/town-planning/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  notEmptyArrayValidator,
  numberValidator,
  textAreaValidator,
} from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import { TownPlanningParentSection } from "./components/parent-section/_index";

interface ITownPlanningFormProps {
  formRenderProps: FormRenderProps;
}

export const TownPlanningFormElement = observer(
  ({ formRenderProps }: ITownPlanningFormProps) => {
    const isNew = useIsNew();
    const nameOf = nameOfFactory<TownPlanningRegisterForm>();
    const { valid, valueGetter, modified, onChange, onSubmit } =
      formRenderProps;
    const { pushNotification } = useCCAppNotificationStore();
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (modified) {
        onSubmit(event);
        return;
      }
      if (!valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    return (
      <FormElement onSubmit={onSubmit}>
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <TownPlanningParentSection {...formRenderProps} />
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-col-span-2">
              <div className="cc-field">
                <label className="cc-label">
                  Application category
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("ApplicationCategories")}
                  {...appCategoryFields(formRenderProps)}
                  component={InputPicker}
                  validator={notEmptyArrayValidator}
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Fee schedule
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("FeeSchedules_Namelist")}
                {...feeScheduleFields(formRenderProps)}
                component={InputPicker}
                validator={notEmptyArrayValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Reason for permit
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("ReasonForPermit")}
                placeholder={"Reason for permit"}
                rows={8}
                value={valueGetter(nameOf("ReasonForPermit"))}
                component={CCTextArea}
                validator={textAreaValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Pre application meeting?</label>
              <Field
                name={nameOf("Flag_PreApplicationMeeting")}
                checked={valueGetter(nameOf("Flag_PreApplicationMeeting"))}
                component={CCSwitch}
              />
            </div>

            {valueGetter(nameOf("Flag_PreApplicationMeeting")) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Officer</label>
                  <Field
                    name={nameOf("PreApplicationMeeting_CouncilOfficer")}
                    {...preAppMetting_CoucilOfficerFields(formRenderProps)}
                    component={InputPicker}
                  />
                </div>

                <div className="cc-field">
                  <label className="cc-label">Date</label>
                  <Field
                    name={nameOf("PreApplicationMeeting_Date")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              </>
            )}
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Enforcement issue?</label>
              <Field
                name={nameOf("Flag_EnforcementIssue")}
                checked={valueGetter(nameOf("Flag_EnforcementIssue"))}
                component={CCSwitch}
              />
            </div>

            {valueGetter(nameOf("Flag_EnforcementIssue")) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Officer</label>
                  <Field
                    name={nameOf("EnforcementIssue_CouncilOfficer")}
                    {...enforcementIssue_CouncilOfficerFields(formRenderProps)}
                    component={InputPicker}
                  />
                </div>

                <div className="cc-field">
                  <label className="cc-label">Date</label>
                  <Field
                    name={nameOf("EnforcementIssue_Date")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              </>
            )}
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Covenant disclaimer</label>
              <Field
                name={nameOf("ConventDisclaimer")}
                component={RadioGroup}
                data={covenantType}
                className="cc-form-cols-3"
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Cost of work
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("CostOfWorks")}
                min={0}
                step={0.1}
                placeholder="Cost of work"
                component={CCNumericTextBox}
                validator={numberValidator}
                format={CURRENCY_FORMAT.CURRENCY2}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Planner</label>
              <Field
                name={nameOf("Planner")}
                {...plannerFields(formRenderProps)}
                component={InputPicker}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={nameOf("FileNo")}
                component={CCInput}
                placeholder={"File number"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={nameOf("DebtorNumber")}
                component={CCInput}
                placeholder={"Debtor number"}
              />
            </div>
            <CCValueField
              label="OS fees"
              value={valueGetter(nameOf("OsFees"))}
              style={formatOSFees(valueGetter(nameOf("OsFees")))}
              format={CURRENCY_FORMAT.CURRENCY1}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Proposed final outcome</label>
              <Field
                name={nameOf("FinalOutcomeProposed")}
                {...finalOutcomeProposedFields(formRenderProps)}
                component={InputPicker}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="Further information"
              value={valueGetter(nameOf("FurtherInfo"))}
            />
            <CCValueField
              label="Advertising"
              value={valueGetter(nameOf("Advertising"))}
            />
            <CCValueField
              label="Meeting"
              value={valueGetter(nameOf("Meeting"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        {!isNew && valueGetter("Decision_ENUM") && (
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <CCValueField
                label="Decision"
                value={valueGetter(nameOf("Decision_ENUM"))}
              />
              <div className="cc-field">
                <label className="cc-label">Decided</label>
                <Field
                  name={nameOf("DecisionDate")}
                  component={CCDatePicker}
                  defaultValue={new Date()}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Type</label>
                <Field
                  name={nameOf("DecisionType_ENUM")}
                  dataItemKey="Key"
                  textField="Value"
                  data={mockType}
                  value={getDropdownValue(
                    valueGetter(nameOf("DecisionType_ENUM")),
                    mockType,
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("DecisionType_ENUM"), {
                      value: event.target.value?.Key ?? null,
                    });
                  }}
                  component={CCSearchComboBox}
                />
              </div>
            </div>
          </div>
        )}
      </FormElement>
    );
  }
);
