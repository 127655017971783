import {
  AnimalFinanceViewTypeConfig,
  IAnimalsFinanceViewBySettingProps,
  Svc_Animals_Finance,
} from "@app/products/animals/finance/model";

import { PROPERTYSYSTEM, RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<Svc_Animals_Finance>();
export const generateAnimalsFinanceViewBySetting = ({
  settings,
  defaultConfig = [],
  listType,
}: IAnimalsFinanceViewBySettingProps) => {
  if (!settings) return defaultConfig;

  const propertySystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
  );
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const debtorNumberLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
  );

  const isAnimalsDisplayAnimalDebtorNo = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber
    ]
  );
  const isAnimalEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("AppBalance"):
        return isAnimalEnableMultiLineInvoice;
      case nameOf("DebtorNo"):
        if (listType === AnimalFinanceViewTypeConfig.DebtorSummaryAll) {
          if (isAnimalsDisplayAnimalDebtorNo) {
            return true;
          }
          return false;
        }
        return true;
      case nameOf("DebtorNumber"):
        if (
          listType === AnimalFinanceViewTypeConfig.DebtorSummaryOutstanding ||
          listType === AnimalFinanceViewTypeConfig.InvoiceNotSentToFinance ||
          listType === AnimalFinanceViewTypeConfig.InvoiceSentToFinance
        ) {
          if (
            (listType === AnimalFinanceViewTypeConfig.InvoiceNotSentToFinance ||
              listType === AnimalFinanceViewTypeConfig.InvoiceSentToFinance) &&
            debtorNumberLabel
          ) {
            col.title = debtorNumberLabel;
          }

          if (isAnimalsDisplayAnimalDebtorNo) {
            return true;
          }
          return false;
        }
        return true;
      case nameOf("FileNumber"):
        if (recordsSystem === RECORDSSYSTEM.None) {
          return false;
        }
        return true;
      case nameOf("AssessmentNo"):
        if (propertySystem === PROPERTYSYSTEM.None) {
          return false;
        }
        return true;
      default:
        return true;
    }
  });
};
