import { colContacts } from "@app/products/property/assessments/[id]/components/child-screens/contacts/config";
import { DTO_AssociatedEntity } from "@app/products/property/assessments/[id]/components/child-screens/contacts/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<DTO_AssociatedEntity>();
export const ContactDetailComponent = ({ dataItem }: GridDetailRowProps) => {
  return (
    <CCGrid
      className="cc-contact-children-grid"
      data={dataItem?.AssociatedEntities || []}
      columnFields={colContacts}
      primaryField={nameOf("Contact_Name_Address_Id")}
      isLimitDetailWidth
    />
  );
};
