export const mockLetterProduced = [
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "7-Feb-2015 11:08",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 66,
    ArrangementDate: "7-Feb-2015 11:06",
    AssessmentNumber: 679546,
    Status: "Replaced",
    ApplicantName: "Mr J R & Mrs J Woodward",
    PropertyAddress: "5 Archer St, Archies Creek",
    ID: 81,
    AssessmentId: 1,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "1-Feb-2015 15:36",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 65,
    ArrangementDate: "1-Feb-2015 15:34",
    AssessmentNumber: 681817,
    Status: "Cancelled",
    ApplicantName: "Mr M G & Mrs M Terry",
    PropertyAddress: "50 Princes St, Archies Creek",
    ID: 80,
    AssessmentId: 2,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "7-Jan-2015 10:36",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 51,
    ArrangementDate: "3-Nov-2013 12:31",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 79,
    AssessmentId: 3,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "8-Jun-2014 17:33",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 47,
    ArrangementDate: "26-Aug-2013 15:31",
    AssessmentNumber: 680598,
    Status: "Paid Out",
    ApplicantName: "Mr J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    ID: 78,
    AssessmentId: 4,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "24-May-2014 16:59",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 62,
    ArrangementDate: "11-May-2014 16:32",
    AssessmentNumber: 681712,
    Status: "Paid Out",
    ApplicantName: "Mr J L Hussain",
    PropertyAddress: "2885 Benor Rd, Archies Creek",
    ID: 77,
    AssessmentId: 5,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "19-May-2014 15:36",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 63,
    ArrangementDate: "19-May-2014 15:27",
    AssessmentNumber: 681774,
    Status: "Paid Out",
    ApplicantName: "Miss C T Downing",
    PropertyAddress: "39 Berry St, Almurta",
    ID: 76,
    AssessmentId: 6,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "19-May-2014 09:32",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 51,
    ArrangementDate: "3-Nov-2013 12:31",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 75,
    AssessmentId: 7,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "12-May-2014 09:51",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 48,
    ArrangementDate: "26-Aug-2013 17:15",
    AssessmentNumber: 2990389,
    Status: "Paid Out",
    ApplicantName: "Mr A G Phillips",
    PropertyAddress: "124 Blackwood Rd, Archies Creek",
    ID: 74,
    AssessmentId: 8,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "11-May-2014 17:18",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 47,
    ArrangementDate: "26-Aug-2013 15:31",
    AssessmentNumber: 680598,
    Status: "Paid Out",
    ApplicantName: "MrJ C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    ID: 73,
    AssessmentId: 9,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "11-May-2014 17:09",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 56,
    ArrangementDate: "6-Jan-2014 1736",
    AssessmentNumber: 681774,
    Status: "Replaced",
    ApplicantName: "Miss C T Downing",
    PropertyAddress: "39 Berry St, Almurta",
    ID: 72,
    AssessmentId: 10,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "11-May-2014 16:36",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 62,
    ArrangementDate: "11-May-2014 16:32",
    AssessmentNumber: 681712,
    Status: "Paid Out",
    ApplicantName: "Mr J L Hussain",
    PropertyAddress: "2885 Benor Rd, Archies Creek",
    ID: 71,
    AssessmentId: 11,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "11-May-2014 16:17",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 59,
    ArrangementDate: "22-Apr-2014 09:26",
    AssessmentNumber: 681273,
    Status: "Paid Out",
    ApplicantName: "Mr D S Salter",
    PropertyAddress: "+3 Benak East St, Archies Creek",
    ID: 70,
    AssessmentId: 12,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "11-May-2014 10:01",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 51,
    ArrangementDate: "3-Nov-2013 12:31",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 69,
    AssessmentId: 13,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "10-May-2014 10:14",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 61,
    ArrangementDate: "10-May-2014 10:12",
    AssessmentNumber: 681479,
    Status: "Paid Out",
    ApplicantName: "Mr M Raciti",
    PropertyAddress: "2 Belgrave-Gembrook Pde, Archies Creek",
    ID: 68,
    AssessmentId: 14,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "6-May-2014 11:11",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 60,
    ArrangementDate: "5-May-2014 11:09",
    AssessmentNumber: 682323,
    Status: "Cancelled",
    ApplicantName: "Mr J J J & Mrs M A L O'Malley",
    PropertyAddress: "52 Bluegrass St, Archies Creek",
    ID: 67,
    AssessmentId: 15,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "6-Jan-2014 16:56",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 55,
    ArrangementDate: "8-Dec-2013 17:32",
    AssessmentNumber: 678294,
    Status: "Paid Out",
    ApplicantName: "Mr M C & Mrs S K Bares",
    PropertyAddress: "1375 Abrehart St, Archies Creek",
    ID: 66,
    AssessmentId: 16,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "6-Jan-2014 1648",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 47,
    ArrangementDate: "26-Aug-2013 15:31",
    AssessmentNumber: 680598,
    Status: "Paid Out",
    ApplicantName: "Mr K C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    ID: 65,
    AssessmentId: 17,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "6-Jan-2014 16:47",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 40,
    ArrangementDate: "11-May-2013 14:46",
    AssessmentNumber: 680582,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs A Italiani",
    PropertyAddress: "147 Bass Ave, Bass",
    ID: 64,
    AssessmentId: 18,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "6-Jan-2014 15:29",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 51,
    ArrangementDate: "3-Nov-2013 12:31",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 63,
    AssessmentId: 19,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "8-Dec-2013 1734",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 55,
    ArrangementDate: "8-Dec-2013 17:32",
    AssessmentNumber: 678294,
    Status: "Paid Out",
    ApplicantName: "Mr M  & Mrs S K Bares",
    PropertyAddress: "1375 Abrehart St, Archies Creek",
    ID: 62,
    AssessmentId: 20,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "19-Nov-2013 12:16",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 49,
    ArrangementDate: "31-Aug-2013 10:38",
    AssessmentNumber: 682583,
    Status: "Paid Out",
    ApplicantName: "Miss G & Miss G & Miss S Lahoud",
    PropertyAddress: "20 Bourkes Creek St, Almurta",
    ID: 61,
    AssessmentId: 21,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "16-Nov-2013 17:07",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 46,
    ArrangementDate: "S-Jul-2013 15:34",
    AssessmentNumber: 679326,
    Status: "Paid Out",
    ApplicantName: "Mr K J & Ms KK & Mr SJ & Ms S Rao",
    PropertyAddress: "8 Amphlett St, Almurta",
    ID: 60,
    AssessmentId: 22,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "6-Nov-2013 12:37",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 53,
    ArrangementDate: "6-Nov-2013 12:34",
    AssessmentNumber: 681157,
    Status: "Paid Out",
    ApplicantName: "Mr P J Shorten & Mrs M P Crocker",
    PropertyAddress: "55 Beatties St, Almurta",
    ID: 59,
    AssessmentId: 23,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "3-Nov-2013 12:34",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 51,
    ArrangementDate: "3-Nov-2013 12:31",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 58,
    AssessmentId: 24,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "14-Oct-2013 09:58",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 50,
    ArrangementDate: "13-Oct-2013 14:04",
    AssessmentNumber: 682120,
    Status: "Paid Out",
    ApplicantName: "Ms S C Catt",
    PropertyAddress: "71 Blackwood Rd, Archies Creek",
    ID: 57,
    AssessmentId: 25,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "19-Aug-2013 14:31",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 43,
    ArrangementDate: "2-Jun-2013 13:14",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 56,
    AssessmentId: 26,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "28-Jul-2013 15:57",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 45,
    ArrangementDate: "16-Jun-2013 16:44",
    AssessmentNumber: 680716,
    Status: "Paid Out",
    ApplicantName: "Mrs G Curtis",
    PropertyAddress: "310 Luke St, Archies Creek",
    ID: 55,
    AssessmentId: 27,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "20-Jul-2013 15:17",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 44,
    ArrangementDate: "15-Jun-2013 11:17",
    AssessmentNumber: 681946,
    Status: "Paid Out",
    ApplicantName: "Mr S J EDWARDS & Ms K CUMMING",
    PropertyAddress: "30 Buckleigh Lane, Archies Creek",
    ID: 54,
    AssessmentId: 28,
  },
  {
    Type: "Multiple Payments in Arrears 2009/10",
    ProducedOn: "20-Jul-2013 11:38",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 43,
    ArrangementDate: "22-Jun-2013 13:14",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 53,
    AssessmentId: 29,
  },
  {
    Type: "‘Single Payment in Arrears 2009/10",
    ProducedOn: "20-Jul-2013 11:36",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 43,
    ArrangementDate: "22-Jun-2013 13:14",
    AssessmentNumber: 681060,
    Status: "Paid Out",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    ID: 52,
    AssessmentId: 30,
  },
  {
    Type: "Arrangement Letter 2009/10",
    ProducedOn: "8-Jul-2013 15:44",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 46,
    ArrangementDate: "S-Jul-2013 15:34",
    AssessmentNumber: 679326,
    Status: "Paid Out",
    ApplicantName: "Mr K J & Ms K K & Mr K S J & Ms S Rao",
    PropertyAddress: "8 Amphlett St, Almurta",
    ID: 51,
    AssessmentId: 31,
  },
  {
    Type: "Multiple Payments in Arrears 2008/09",
    ProducedOn: "7-Jun-2013 11:17",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 45,
    ArrangementDate: "16-Jun-2013 16:44",
    AssessmentNumber: 680716,
    Status: "Paid Out",
    ApplicantName: "Mrs G Curtis",
    PropertyAddress: "310 Luke St, Archies Creek",
    ID: 50,
    AssessmentId: 32,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "16-Jun-2013 16:46",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 45,
    ArrangementDate: "16-Jun-2013 16:44",
    AssessmentNumber: 680716,
    Status: "Paid Out",
    ApplicantName: "Mrs G Curtis",
    PropertyAddress: "310 Luke St, Archies Creek",
    ID: 49,
    AssessmentId: 33,
  },
  {
    Type: "Multiple Payments in Arrears 2008/09",
    ProducedOn: "16-Jun-2013 1026",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 21,
    ArrangementDate: "15-May-2012 10:19",
    AssessmentNumber: 680598,
    Status: "Paid Out",
    ApplicantName: "Mr J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    ID: 48,
    AssessmentId: 34,
  },
  {
    Type: "‘Single Payment in Arrears 2008/09",
    ProducedOn: "16-Jun-2013 1022",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 40,
    ArrangementDate: "11-May-2013 14:46",
    AssessmentNumber: 680582,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs A Italiani",
    PropertyAddress: "147 Bass Ave, Bass",
    ID: 47,
    AssessmentId: 35,
  },
  {
    Type: "‘Single Payment in Arrears 2008/09",
    ProducedOn: "16-Jun-2013 10:21",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 39,
    ArrangementDate: "11-May-2013 14:38",
    AssessmentNumber: 2972159,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs A Italiani",
    PropertyAddress: "11 Bass Ave, Bass",
    ID: 46,
    AssessmentId: 36,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "15-Jun-2013 11:20",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 44,
    ArrangementDate: "15-Jun-2013 11:17",
    AssessmentNumber: 681946,
    Status: "Paid Out",
    ApplicantName: "Mr S J EDWARDS & Ms K CUMMING",
    PropertyAddress: "30 Buckleigh Lane, Archies Creek",
    ID: 45,
    AssessmentId: 37,
  },
  {
    Type: "Multiple Payments in Arrears 2008/09",
    ProducedOn: "21-May-2013 15:15",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 21,
    ArrangementDate: "15-May-2012 10:19",
    AssessmentNumber: 680598,
    Status: "Paid Out",
    ApplicantName: "MrJ Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    ID: 44,
    AssessmentId: 38,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "12-May-2013 15:20",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 41,
    ArrangementDate: "12-May-2013 15:17",
    AssessmentNumber: 682523,
    Status: "Replaced",
    ApplicantName: "Ms J C Hirsh",
    PropertyAddress: "1 Tiree Ave, Archies Creek",
    ID: 43,
    AssessmentId: 39,
  },
  {
    Type: "Multiple Payments in Arrears 2008/09",
    ProducedOn: "12-May-2013 09:57",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 35,
    ArrangementDate: "23-Jan-2013 09:29",
    AssessmentNumber: 682287,
    Status: "Cancelled",
    ApplicantName: "Mrs M V Clayton",
    PropertyAddress: "775-910 Bluebird St, Anderson",
    ID: 42,
    AssessmentId: 40,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "11-May-2013 1451",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 39,
    ArrangementDate: "11-May-2013 14:38",
    AssessmentNumber: 2972159,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs A Italiani",
    PropertyAddress: "1 Bass Ave, Bass",
    ID: 41,
    AssessmentId: 41,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "11-May-2013 14:48",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 40,
    ArrangementDate: "11-May-2013 14:46",
    AssessmentNumber: 680582,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs A Italiani",
    PropertyAddress: "147 Bass Ave, Bass",
    ID: 40,
    AssessmentId: 42,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "11-May-2013 14:40",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 39,
    ArrangementDate: "11-May-2013 14:38",
    AssessmentNumber: 2972159,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs A Italiani",
    PropertyAddress: "1 Bass Ave, Bass",
    ID: 39,
    AssessmentId: 43,
  },
  {
    Type: "Multiple Payments in Arrears 2008/09",
    ProducedOn: "11-May-2013 11:14",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 37,
    ArrangementDate: "12-Feb-2013 15:35",
    AssessmentNumber: 679546,
    Status: "Paid Out",
    ApplicantName: "Mr J R & Mrs J Woodward",
    PropertyAddress: "5 Archer St, Archies Creek",
    ID: 38,
    AssessmentId: 44,
  },
  {
    Type: "Multiple Payments in Arrears 2008/09",
    ProducedOn: "30-Apr-2013 13:09",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 38,
    ArrangementDate: "19-Feb-2013 14:27",
    AssessmentNumber: 681273,
    Status: "Paid Out",
    ApplicantName: "Mr D S Salter",
    PropertyAddress: "3 Benak East St, Archies Creek",
    ID: 37,
    AssessmentId: 45,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "2-Mar-2013 17:14",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 38,
    ArrangementDate: "19-Feb-2013 14:27",
    AssessmentNumber: 681273,
    Status: "Paid Out",
    ApplicantName: "Mr D S Salter",
    PropertyAddress: "+3 Benak East St, Archies Creek",
    ID: 36,
    AssessmentId: 46,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "12-Feb-2013 15:48",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 37,
    ArrangementDate: "12-Feb-2013 15:35",
    AssessmentNumber: 679546,
    Status: "Paid Out",
    ApplicantName: "Mr J R & Mrs J Woodward",
    PropertyAddress: "5 Archer St, Archies Creek",
    ID: 35,
    AssessmentId: 47,
  },
  {
    Type: "‘Single Payment in Arrears 2008/09",
    ProducedOn: "2-Feb-2013 11:00",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 27,
    ArrangementDate: "22-Oct-2012 11:01",
    AssessmentNumber: 679810,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs D M Kingston",
    PropertyAddress: "375 Atkins St, Archies Creek",
    ID: 34,
    AssessmentId: 48,
  },
  {
    Type: "Single Payment in Arrears 2008/09",
    ProducedOn: "29-Jan-2013 11:20",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 21,
    ArrangementDate: "15-May-2012 10:19",
    AssessmentNumber: 680598,
    Status: "Paid Out",
    ApplicantName: "Mr J Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    ID: 33,
    AssessmentId: 49,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "23-Jan-2013 9:32",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 35,
    ArrangementDate: "23-Jan-2013 09:29",
    AssessmentNumber: 682287,
    Status: "Cancelled",
    ApplicantName: "Mrs M V Clayton",
    PropertyAddress: "775-910 Bluebird St, Anderson",
    ID: 32,
    AssessmentId: 50,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "14-Jan-2013 17:56",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 34,
    ArrangementDate: "14-Jan-2013 17:54",
    AssessmentNumber: 682583,
    Status: "Paid Out",
    ApplicantName: "Miss G & Miss G & Miss S Lahoud",
    PropertyAddress: "20 Bourkes Creek St, Almurta",
    ID: 31,
    AssessmentId: 51,
  },
  {
    Type: "‘Single Payment in Arrears 2008/09",
    ProducedOn: "13-Jan-2013 9:32",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 33,
    ArrangementDate: "5-Jan-2013 12:45",
    AssessmentNumber: 682583,
    Status: "Paid Out",
    ApplicantName: "Miss G & Miss G & Miss S Lahoud",
    PropertyAddress: "20 Bourkes Creek St, Almurta",
    ID: 30,
    AssessmentId: 52,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "23-Dec-2012 14:52",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 32,
    ArrangementDate: "23-Dec-2012 14:21",
    AssessmentNumber: 678671,
    Status: "Paid Out",
    ApplicantName: "Mr E Papageorgiou",
    PropertyAddress: "181 Aidens Cres, Archies Creek",
    ID: 29,
    AssessmentId: 53,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "23-Dec-2012 14:20",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 31,
    ArrangementDate: "23-Dec-2012 13:57",
    AssessmentNumber: 678671,
    Status: "Replaced",
    ApplicantName: "Mr E Papageorgiou",
    PropertyAddress: "181 Aidens Cres, Archies Creek",
    ID: 28,
    AssessmentId: 54,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "8-Dec-2012 16:38",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 29,
    ArrangementDate: "8-Dec-2012 16:36",
    AssessmentNumber: 681774,
    Status: "Replaced",
    ApplicantName: "Miss C T Downing",
    PropertyAddress: "39 Berry St, Almurta",
    ID: 27,
    AssessmentId: 55,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "27-Nov-2012 14:59",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 28,
    ArrangementDate: "27-Nov-2012 14:57",
    AssessmentNumber: 682850,
    Status: "Paid Out",
    ApplicantName: "Mrs E E Whelan",
    PropertyAddress: "34 Bromby Ave, Almurta",
    ID: 26,
    AssessmentId: 56,
  },
  {
    Type: "Arrangement Letter 2008/09",
    ProducedOn: "22-Oct-2012 11:03",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 27,
    ArrangementDate: "22-Oct-2012 11:01",
    AssessmentNumber: 679810,
    Status: "Paid Out",
    ApplicantName: "Mr A & Mrs D M Kingston",
    PropertyAddress: "375 Atkins St, Archies Creek",
    ID: 25,
    AssessmentId: 57,
  },
  {
    Type: "Multiple Payments in Arrears 2007/08",
    ProducedOn: "16-Jul-2012 10:45",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 24,
    ArrangementDate: "3-Jun-2012 17:06",
    AssessmentNumber: 678413,
    Status: "Paid Out",
    ApplicantName: "Mr S S Turner",
    PropertyAddress: "256 Aclare St, Archies Creek",
    ID: 24,
    AssessmentId: 58,
  },
  {
    Type: "Single Payment in Arrears 2007/08",
    ProducedOn: "24-Jun-2012 9:39",
    ProducedBy: "Barbara HOGAN",
    ArrangementNumber: 22,
    ArrangementDate: "21-May-2012 15:08",
    AssessmentNumber: 680334,
    Status: "Paid Out",
    ApplicantName: "MrJ Icao & Mrs E Tanquiamco-Icao",
    PropertyAddress: "21 Barbara Rd, Cape Paterson",
    ID: 23,
    AssessmentId: 59,
  },
];
