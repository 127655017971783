import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { Svc_CommunicationTemplate } from "@common/pages/settings/communication/template/model";
import { SETTINGS_COMMUNICATION_TEMPLATE_ROUTE } from "@common/pages/settings/communication/template/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_CommunicationTemplate>();

export const colCommunicationTemplate: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: Svc_CommunicationTemplate) =>
      `${SETTINGS_COMMUNICATION_TEMPLATE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("ShowInDialogue"),
    title: "Show in Dialog",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("CommunicationUsageName"),
    title: "Communication Usage",
  },
  {
    field: nameOf("Dataset_Name"),
    title: "Dataset Name",
  },
];
