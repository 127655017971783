import { getDebtRecoveryRestrictionsById } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/modify-functionality-restrictions/api";
import { colDebtRecoveryModifyFunctionalityRestrictions } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/config";
import { V_Debt_Recovery_Functionality } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/model";
import { functionalitiesDRFValid } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useResponsibleOfficerStore } from "@common/stores/responsible-officer/store";
import { nameOfFactory } from "@common/utils/common";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IFunctionalityRestrictionsDialogProps {
  title: string;
  isLoading: boolean;
  debtRecoveryId: number;
  onClose: () => void;
  onSubmit: (dataItem: V_Debt_Recovery_Functionality[]) => void;
}

const nameOf = nameOfFactory<V_Debt_Recovery_Functionality>();

export const DebtRecoveryFunctionalityRestrictionsDialog = observer(
  ({
    title,
    isLoading = false,
    debtRecoveryId,
    onClose,
    onSubmit,
  }: IFunctionalityRestrictionsDialogProps) => {
    const [functionalities, setFunctionalities] = useState<
      V_Debt_Recovery_Functionality[]
    >([]);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [isLoadingInitData, setIsLoadingInitData] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const { responsibleOfficer } = useResponsibleOfficerStore();

    const validNotifications = useMemo((): IAppNotificationItem[] => {
      return functionalitiesDRFValid(functionalities);
    }, [functionalities]);

    const handleSubmit = () => {
      const submitData = functionalities.map(
        (functionality: V_Debt_Recovery_Functionality) => {
          return {
            ...functionality,
            DRF_Is_Restricted: !!(
              functionality?.DRF_Restrict_From || functionality?.DRF_Restrict_To
            ),
            DRF_Modified_On: new Date(),
            DRF_Modified_By: responsibleOfficer?.FullName ?? "",
          };
        }
      );
      onSubmit(submitData);
    };

    const loadInitialValue = async () => {
      setIsLoadingInitData(true);
      const response = await getDebtRecoveryRestrictionsById(debtRecoveryId);
      setIsLoadingInitData(false);

      if (isSuccessResponse(response) && response?.data) {
        setFunctionalities(response?.data);
      } else {
        const errorResponse = {
          status: response.status,
          error: response.error,
        };
        setResponseLoadError(errorResponse);
      }
    };

    useEffectOnce(() => {
      loadInitialValue();
    });

    return (
      <Form
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit: onFormSubmit } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth="45%"
                height="auto"
                disabled={isLoading}
                titleHeader={title}
                onClose={onClose}
                bodyElement={
                  <div className="cc-form">
                    {responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          setResponseLoadError(undefined);
                          loadInitialValue();
                        }}
                      />
                    ) : (
                      <>
                        <CCLocalNotification
                          defaultNotifications={validNotifications}
                        />
                        <CCGrid
                          data={functionalities}
                          columnFields={
                            colDebtRecoveryModifyFunctionalityRestrictions
                          }
                          primaryField={nameOf("Functionality_Name")}
                          editableMode="cell"
                          isLoading={isLoadingInitData}
                          itemPerPage={10}
                          onDataChange={(data) => {
                            setIsModified(true);
                            setFunctionalities(data);
                          }}
                        />
                      </>
                    )}
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={validNotifications.length > 0 || !isModified}
                      themeColor="primary"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onClick={onFormSubmit}
                      className={"cc-dialog-button"}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
