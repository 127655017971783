import { isAfter } from "date-fns";

export const handleValidateForm: any = (data: any) => {
  const start = data.Start;
  const end = data.End;
  // const issueDate = issueDateGetter(data);

  let errorMessage = {};

  if (start && end) {
    if (isAfter(new Date(start), new Date(end))) {
      errorMessage = {
        ...errorMessage,
        Start: "Reporting Period Start must be less than Reporting Period End",
        End: "Reporting Period End must be greater than Reporting Period Start",
      };
    }
  }

  return errorMessage;
};

export const requiredValidatorDatePickerField = (data: any) => {
  return data ? undefined : " ";
};
