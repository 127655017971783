import { toggleLevyCalculations } from "@app/products/property/supplementary-rates/[id]/components/buttons/tools/toggle-levy-calculations/api";
import { useSupplementaryRatesDetailsStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/store";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ToggleLevyCalculationsButton = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { supplementaryRatesId } = useSupplementaryRatesStore();
  const { supplementaryRatesDetails, setSupplementaryRatesDetails } =
    useSupplementaryRatesDetailsStore();

  const [showConfirmationDialog, setIsShowConfirmationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggleLevyCalculations = async () => {
    setIsLoading(true);
    const response = await toggleLevyCalculations(supplementaryRatesId);
    setIsLoading(false);
    if (isSuccessResponse(response) && response.data) {
      setIsShowConfirmationDialog(false);
      // Reload supplementary Details accordion
      setSupplementaryRatesDetails(response.data);
      pushNotification({
        title: "Toggle levy calculation successfully.",
        type: "success",
      });
    } else {
      setIsShowConfirmationDialog(false);
      pushNotification({
        autoClose: false,
        title: "Toggle levy calculation failed.",
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Toggle levy calculations"
        onClick={() => setIsShowConfirmationDialog(true)}
      />
      {showConfirmationDialog && (
        <ConfirmDialog
          title="Confirmation"
          subMessage={`Are you sure you wish to ${
            supplementaryRatesDetails?.SupplementaryDetail
              ?.EnableLevyCalculations
              ? "turn off the enable levy calculations?"
              : "run the calculations? The calculations will run in the background and levies will be updated shortly."
          }`}
          onClosePopup={() => setIsShowConfirmationDialog(false)}
          onAsyncConfirm={handleToggleLevyCalculations}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
