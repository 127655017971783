import { getDisplayTextWithDashes } from "@common/utils/common";

export const CertificateBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    return `Property - Certificate - ${selectedRow.Cert_Number}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Cert_Number,
      selectedRow.Certificate_Type_Name,
    ]);
  },
};

//TODO: Delete after integrate api for all list-views, use the one above
export const MockCertificateBookmark = {
  getBookmarkDisplayName() {
    return "Property - Certificate - 12354";
  },
  getBookmarkDetail() {
    return "12354 - Planning 149 Certificate";
  },
};
