import {
  getAssessmentTransactions,
  getAssessmentTransactionsViews,
} from "@app/products/property/assessments/[id]/components/child-screens/transactions/api";
import {
  colTransactions,
  defaultSortTransactions,
} from "@app/products/property/assessments/[id]/components/child-screens/transactions/config";
import {
  DTO_Transaction,
  mTransactionView,
} from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AssessmentTransactionStore {
  private _isLoading: boolean = false;
  private _isGridLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _views: mTransactionView[] = [];
  private _view?: mTransactionView = undefined;
  private _showCancelled: boolean = false;

  private _gridData?: DTO_Transaction[] = undefined;
  private _columnFields: IColumnFields[] = colTransactions;
  private _gridDataState?: State = undefined;
  private _gridSelectedRows: DTO_Transaction[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  get isGridLoading() {
    return this._isGridLoading;
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }

  get gridData() {
    return this._gridData;
  }
  get columnFields() {
    return this._columnFields;
  }
  get view() {
    return this._view;
  }
  get views() {
    return this._views;
  }
  get showCancelled() {
    return this._showCancelled;
  }
  get gridDataState() {
    return this._gridDataState;
  }
  get gridSelectedRows() {
    return this._gridSelectedRows;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  setIsGridLoading = (isGridLoading: boolean) => {
    runInAction(() => {
      this._isGridLoading = isGridLoading;
    });
  };
  setView = (view?: mTransactionView) => {
    runInAction(() => {
      this._view = view;
    });
  };
  setViews = (views: mTransactionView[]) => {
    runInAction(() => {
      this._views = views;
    });
  };
  setShowCancelled = (showCancelled: boolean) => {
    runInAction(() => {
      this._showCancelled = showCancelled;
    });
  };

  setGridData = (gridData?: DTO_Transaction[]) => {
    runInAction(() => {
      this._gridData = gridData;
    });
  };

  setGridDataState = (gridDataState?: State) => {
    runInAction(() => {
      this._gridDataState = gridDataState;
    });
  };
  setGridSelectedRows = (gridSelectedRows: DTO_Transaction[]) => {
    runInAction(() => {
      this._gridSelectedRows = gridSelectedRows;
    });
  };
  setColumnFields = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._columnFields = columnFields;
    });
  };

  get gridSelectedTransactionIds(): number[] {
    let listSelected: any = [...this._gridSelectedRows];
    listSelected =
      listSelected?.map((item: DTO_Transaction) => item["TransactionId"]) ?? [];
    return toJS(listSelected);
  }
  //Action

  loadViews = async () => {
    this.setIsLoading(true);
    const newViews = await getAssessmentTransactionsViews();
    let dataViews: mTransactionView[] = [];
    if (newViews?.data?.Views?.length) {
      dataViews = newViews?.data?.Views?.map((item, index) => ({
        ...item,
        Key: index + 1,
      }));
    }
    this.setViews(dataViews?.length ? dataViews : []);
    this.setView(dataViews?.length ? dataViews?.[0] : undefined);

    this.setIsLoading(false);
  };

  loadTransactions = async (
    assessmentId: number,
    year: number = 0,
    view: number,
    showCancelledTransactions: boolean,
    loadAllocation: boolean = false
  ) => {
    this.setIsGridLoading(true);
    this.setResponseLoadError(undefined);
    const newTransactions = await getAssessmentTransactions(
      assessmentId,
      year,
      view,
      showCancelledTransactions,
      loadAllocation
    );

    let newGridData: DTO_Transaction[] | undefined = undefined;
    let newGridCols: IColumnFields[] = colTransactions;

    if (newTransactions && newTransactions?.Transactions) {
      const transactionsTotals: any = newTransactions.Transactions?.Totals;
      newGridData = newTransactions.Transactions?.Transactions;
      newGridCols = newGridCols.map((item: IColumnFields) => ({
        ...item,
        calculateFooterCellValue: () => transactionsTotals?.[item.field],
      }));
      const groupField = newTransactions.Transactions.GroupByFieldNames;
      let defaultGridDataState: State = { sort: defaultSortTransactions };
      if (groupField) {
        defaultGridDataState = {
          ...defaultGridDataState,
          group: groupField.map((field) => ({ field: field, dir: "desc" })),
        };
      }
      this.setGridDataState(defaultGridDataState);
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    this.setGridData(newGridData);
    this.setIsGridLoading(false);
    this.setColumnFields(newGridCols);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isGridLoading = false;
      this._showCancelled = false;
      this._gridData = [];
      this._columnFields = colTransactions;
      this._gridDataState = undefined;
      this._gridSelectedRows = [];
      this._view = undefined;
      this._views = [];
    });
  };
}

export const assessmentTransactionsStoreInstance =
  new AssessmentTransactionStore();
const assessmentTransactionStoreContext = createContext(
  assessmentTransactionsStoreInstance
);
export const useAssessmentTransactionStore = () => {
  return useContext(assessmentTransactionStoreContext);
};
