import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface INewTransactionPICButtonProps {
  listPICId?: number[];
  journalNumber?: number;
}
export const NewTransactionPICButton = observer(
  (props: INewTransactionPICButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    return (
      <CCNavButton
        title="New transaction"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_PIC_AddTransaction,
              data: {},
              props: {
                ...props,
              },
            },
          ]);
        }}
      />
    );
  }
);
