import { Svc_Contact_ContactRelationshipType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/components/contact-relationship-types-dialog/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Contact_ContactRelationshipType>();
export const colContactType: IColumnFields[] = [
  {
    field: nameOf("ContactRelationshipType"),
    title: "Name",
    width: 1200,
  },
];
