import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_Request_Base } from "./../../../../model";

export interface DTO_Workflow_AssociateAssessment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_AssociateAssessment;
}

export interface DTO_WorkflowDetail_AssociateAssessment {
  Details: DTO_AssociateAssessmentDetails;
  Associations: DTO_AssociateAssessmentAssociations;
  SelectedSecondaryWorkflow: number[];
  SelectedCollection: Collection[];
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}

export interface RequestObjFromSpatial extends DTO_Request_Base {
  NameAddressId?: number;
  AssessmentId?: number;
}

export interface DTO_AssociateAssessmentDetails {
  Reference: string;
  ApplicantName: string;
  ReasonId?: number;
}

export interface DTO_AssociateAssessmentAssociations {
  Ratepayer: string;
  AssociationDate: Date;
  NameAddressId: number | null;
  AssessmentId: number | null;
  Assessments: DTO_Assessment[];
  UnAffectedAssessments: DTO_Assessment[];
  Selected_Primary_Assessment_Id: number | null;
}

export interface DTO_Assessment {
  Assessment_Id: number;
  AssessmentNumber: string;
  Assess_Property_Address: string;
  Legal_Description: string;
  Assessment_Group_Name: string;
  IsPrimaryAssessment: boolean;
  ExcludeFromAssociate: boolean;
  Ratepayer_Name: string;
  ActiveFrom: Date | null;
  ActiveTo: Date | null;
  DifferentialCategory: string;
  Assess_Property_Name: string;
}

export enum EKeysOfSteps {
  Details = "Details",
  Associations = "Associations",
  Comments = "Comments",
  Documents = "Documents",
  Collection = "SelectedCollection",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Details,
  EKeysOfSteps.Associations,
  EKeysOfSteps.Collection,
  EKeysOfSteps.SecondaryWorkflow,
];
