import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
export const renderGeneralSwitchListEnums = [
  ECorporateSettingsField.Global_AlwaysEditMode,
  ECorporateSettingsField.Global_ShowOSActions,
  ECorporateSettingsField.CorporateSettings_UseImageGallery,
  ECorporateSettingsField.Global_ShowOnlyActiveDocument,
  ECorporateSettingsField.Global_IncludePostInPreferredCommMethod,
  ECorporateSettingsField.Global_Contact_PreferredMethod,
  ECorporateSettingsField.Global_AllowMultipleExpandedMenus,
  ECorporateSettingsField.Global_EnableAttachmentSizeRestriction,
];
export const renderGeneral2SwitchListEnums = [
  ECorporateSettingsField.Global_AllowFuzzySearch,
  ECorporateSettingsField.Global_IncludeContactsInHomeSearch,
  ECorporateSettingsField.Global_Homepage_Search_IncludeAttachments,
  ECorporateSettingsField.RelatedTabLoadOnClick,
];

export const renderGeneral3SwitchListEnums = [
  ECorporateSettingsField.CorporateSettings_EnableCrystalReports,
  ECorporateSettingsField.CorporateSettings_SinglePageMode,
  ECorporateSettingsField.CorporateSettings_EnableHelpDocuments,
  ECorporateSettingsField.Global_ShowContactIntegrationLogs,
];
