import { IAccountsNoticeRun } from "@app/products/property/deferred-duty/notice-runs/[id]/components/child-screens/general/components/form-element/model";

export const mockAccountsNoticeRun: IAccountsNoticeRun[] = [
  {
    AccountNumber: 2522804,
    Assessment: "569-128-27",
    OwnerNameAddress:
      "Barry Walls Carpentary & Building P/I89 Partridge Way VIC 3814",
    PrincipalBalance: 14750.0,
    OpeningBalance: 16128.46,
    PaymentsSinceLastNotice: -650.0,
    InterestRaised: 152.68,
    InterestBalance: 881.14,
    BalanceOutstanding: 15631.14,
    NoticeForNumber: 5,
    PropertyAddress: "6/63 Kenneth PI, Jam Jerrup",
    DDSAccountId: 1379,
    NoticeId: 11621,
    Assessment_Id: 1,
  },
  {
    AccountNumber: 2090752,
    Assessment: "709-85-3-18",
    OwnerNameAddress:
      "16 Cheriton DrALTONA MEADOWS VIC 30280X00F37FF54F0FFC4B81D5",
    PrincipalBalance: 7500.0,
    OpeningBalance: 8143.67,
    PaymentsSinceLastNotice: -520.0,
    InterestRaised: 78.68,
    InterestBalance: 202.35,
    BalanceOutstanding: 7702.35,
    NoticeForNumber: 3,
    PropertyAddress: "-383 Rosalie Cd South Dudley",
    DDSAccountId: 2017,
    NoticeId: 11622,
    Assessment_Id: 2,
  },
  {
    AccountNumber: 2165374,
    Assessment: "101-86-22-29",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 5984.0,
    OpeningBalance: 8022.43,
    PaymentsSinceLastNotice: -884.0,
    InterestRaised: 65.36,
    InterestBalance: 1219.79,
    BalanceOutstanding: 7203.79,
    NoticeForNumber: 10,
    PropertyAddress: "16 Bells Cres, Grantville",
    DDSAccountId: 453,
    NoticeId: 11623,
    Assessment_Id: 3,
  },
  {
    AccountNumber: 2650347,
    Assessment: "125-75-132",
    OwnerNameAddress:
      "39 Outlook Rd 0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC",
    PrincipalBalance: 7014.45,
    OpeningBalance: 9450.86,
    PaymentsSinceLastNotice: -1026.0,
    InterestRaised: 76.22,
    InterestBalance: 1486.63,
    BalanceOutstanding: 8501.08,
    NoticeForNumber: 10,
    PropertyAddress: "57 0x00F37FF54F0FFC4B81 D56DBE3258019A010",
    DDSAccountId: 462,
    NoticeId: 11624,
    Assessment_Id: 4,
  },
  {
    AccountNumber: 2090458,
    Assessment: "709-81-23-1",
    OwnerNameAddress:
      "1/129 Marine Pde 0X00F37FF54F0FFC4B81 0x00F3?FF50X00F37FF54F0F",
    PrincipalBalance: 8950.0,
    OpeningBalance: 10492.88,
    PaymentsSinceLastNotice: -1500.0,
    InterestRaised: 98.2,
    InterestBalance: 141.08,
    BalanceOutstanding: 9091.08,
    NoticeForNumber: 2,
    PropertyAddress: "60 Rosalie Cd South Dudley",
    DDSAccountId: 2263,
    NoticeId: 11625,
    Assessment_Id: 5,
  },
  {
    AccountNumber: 2802151,
    Assessment: "910-45-1-14",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 14848.5,
    OpeningBalance: 16192.21,
    PaymentsSinceLastNotice: -1040.0,
    InterestRaised: 155.99,
    InterestBalance: 459.7,
    BalanceOutstanding: 15308.2,
    NoticeForNumber: 3,
    PropertyAddress: "Pioneer St 0x00F37FF54F0FFC4B81 D56DBE3258",
    DDSAccountId: 1953,
    NoticeId: 11626,
    Assessment_Id: 6,
  },
  {
    AccountNumber: 1567904,
    Assessment: "571-10-24",
    OwnerNameAddress:
      "19 Delbridge St 0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FF",
    PrincipalBalance: 15410.0,
    OpeningBalance: 16525.46,
    PaymentsSinceLastNotice: -960.0,
    InterestRaised: 160.96,
    InterestBalance: 316.42,
    BalanceOutstanding: 15726.42,
    NoticeForNumber: 2,
    PropertyAddress: "14 Tingara St Summerlands",
    DDSAccountId: 2148,
    NoticeId: 11627,
    Assessment_Id: 7,
  },
  {
    AccountNumber: 1515816,
    Assessment: "567-1-1",
    OwnerNameAddress: "0X00F37FF54F0FFC4B81 0x00F37FF5",
    PrincipalBalance: 12625.0,
    OpeningBalance: 17019.54,
    PaymentsSinceLastNotice: -1950.0,
    InterestRaised: 137.95,
    InterestBalance: 2582.49,
    BalanceOutstanding: 15207.49,
    NoticeForNumber: 10,
    PropertyAddress: "Ajax St Scenic Estate - Phillip Island (Surf Beach)",
    DDSAccountId: 457,
    NoticeId: 11628,
    Assessment_Id: 8,
  },
  {
    AccountNumber: 1750279,
    Assessment: "609-515-17",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 6973.0,
    OpeningBalance: 9288.45,
    PaymentsSinceLastNotice: -1703.0,
    InterestRaised: 79.74,
    InterestBalance: 692.19,
    BalanceOutstanding: 7665.19,
    NoticeForNumber: 6,
    PropertyAddress: "166 Cameron St Kongwak",
    DDSAccountId: 1309,
    NoticeId: 11629,
    Assessment_Id: 9,
  },
  {
    AccountNumber: 1744870,
    Assessment: "607-472-8",
    OwnerNameAddress: "32 Halford StALTONA MEADOWS VIC 3028",
    PrincipalBalance: 3939.2,
    OpeningBalance: 7924.72,
    PaymentsSinceLastNotice: -1434.0,
    InterestRaised: 47.09,
    InterestBalance: 2598.61,
    BalanceOutstanding: 6537.81,
    NoticeForNumber: 10,
    PropertyAddress: "Seymour Cres, The Gurdies",
    DDSAccountId: 183,
    NoticeId: 11630,
    Assessment_Id: 10,
  },
  {
    AccountNumber: 1494260,
    Assessment: "564-11-6",
    OwnerNameAddress: "Ensor, D H & c M320 Blackshaws Rd VIC 3802",
    PrincipalBalance: 6700.0,
    OpeningBalance: 8917.38,
    PaymentsSinceLastNotice: -1625.0,
    InterestRaised: 76.58,
    InterestBalance: 668.96,
    BalanceOutstanding: 7368.96,
    NoticeForNumber: 5,
    PropertyAddress: "46 Scott Lee PI, Wattle Bank",
    DDSAccountId: 1327,
    NoticeId: 11631,
    Assessment_Id: 11,
  },
  {
    AccountNumber: 1354951,
    Assessment: "445-4-8",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 8240.0,
    OpeningBalance: 10970.0,
    PaymentsSinceLastNotice: -2015.0,
    InterestRaised: 94.25,
    InterestBalance: 809.25,
    BalanceOutstanding: 9049.25,
    NoticeForNumber: 6,
    PropertyAddress: "255 Greenhill Cres, Unk",
    DDSAccountId: 1321,
    NoticeId: 11632,
    Assessment_Id: 12,
  },
  {
    AccountNumber: 1803735,
    Assessment: "601-279-2-58",
    OwnerNameAddress: "Tyssen I & S53 Sargood StNEWPORT NSW 2106",
    PrincipalBalance: 3884.0,
    OpeningBalance: 5160.39,
    PaymentsSinceLastNotice: -949.0,
    InterestRaised: 44.42,
    InterestBalance: 371.81,
    BalanceOutstanding: 4255.81,
    NoticeForNumber: 6,
    PropertyAddress: "6/5 Loves **SEE PAUL ST** Cd Churchill Island",
    DDSAccountId: 1366,
    NoticeId: 11634,
    Assessment_Id: 13,
  },
  {
    AccountNumber: 1705297,
    Assessment: "603-114-3",
    OwnerNameAddress:
      "3 Gordon St 0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4E",
    PrincipalBalance: 11675.0,
    OpeningBalance: 12986.82,
    PaymentsSinceLastNotice: -1040.0,
    InterestRaised: 123.44,
    InterestBalance: 395.26,
    BalanceOutstanding: 12070.26,
    NoticeForNumber: 3,
    PropertyAddress: "324 Venture St Loch",
    DDSAccountId: 1952,
    NoticeId: 11635,
    Assessment_Id: 14,
  },
  {
    AccountNumber: 2697613,
    Assessment: "106-21-22-87",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 6225.0,
    OpeningBalance: 9174.11,
    PaymentsSinceLastNotice: -1300.0,
    InterestRaised: 70.02,
    InterestBalance: 1719.13,
    BalanceOutstanding: 7944.13,
    NoticeForNumber: 8,
    PropertyAddress: "Stanton St Cowes",
    DDSAccountId: 802,
    NoticeId: 11636,
    Assessment_Id: 15,
  },
  {
    AccountNumber: 2203116,
    Assessment: "706-84-2",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 5246.73,
    OpeningBalance: 8168.0,
    PaymentsSinceLastNotice: -1200.0,
    InterestRaised: 59.17,
    InterestBalance: 1780.44,
    BalanceOutstanding: 7027.17,
    NoticeForNumber: 10,
    PropertyAddress: "54 Derby Cd Pioneer Bay",
    DDSAccountId: 363,
    NoticeId: 11637,
    Assessment_Id: 16,
  },
  {
    AccountNumber: 1588974,
    Assessment: "572-56-3",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 15440.0,
    OpeningBalance: 16708.62,
    PaymentsSinceLastNotice: -942.5,
    InterestRaised: 161.11,
    InterestBalance: 487.23,
    BalanceOutstanding: 15927.23,
    NoticeForNumber: 3,
    PropertyAddress: "45 Lyons St Lance Creek",
    DDSAccountId: 1926,
    NoticeId: 11638,
    Assessment_Id: 17,
  },
  {
    AccountNumber: 1683902,
    Assessment: "602-158-5",
    OwnerNameAddress: "p M O'Driscoll215 Ruby-Fairbank Rd VIC 3980",
    PrincipalBalance: 11875.0,
    OpeningBalance: 12870.24,
    PaymentsSinceLastNotice: -728.0,
    InterestRaised: 123.92,
    InterestBalance: 391.16,
    BalanceOutstanding: 12266.16,
    NoticeForNumber: 3,
    PropertyAddress: "80 Howey St Williamstown North",
    DDSAccountId: 1899,
    NoticeId: 11639,
    Assessment_Id: 18,
  },
  {
    AccountNumber: 1974724,
    Assessment: "616-453-6",
    OwnerNameAddress:
      "0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FFC4B81 D56DBE:",
    PrincipalBalance: 11777.0,
    OpeningBalance: 12189.0,
    PaymentsSinceLastNotice: -523.0,
    InterestRaised: 81.31,
    InterestBalance: 81.31,
    BalanceOutstanding: 11858.31,
    NoticeForNumber: 1,
    PropertyAddress: "Meadowview Loop, Wattle Bank",
    DDSAccountId: 2532,
    NoticeId: 11640,
    Assessment_Id: 19,
  },
  {
    AccountNumber: 1572931,
    Assessment: "571-35-20",
    OwnerNameAddress:
      "69 Country Club Dr 0X00F37FF54F0FFC4B810x00F37FF50X00F37FF54F0F",
    PrincipalBalance: 11770.0,
    OpeningBalance: 13043.43,
    PaymentsSinceLastNotice: -855.0,
    InterestRaised: 123.53,
    InterestBalance: 541.96,
    BalanceOutstanding: 12311.96,
    NoticeForNumber: 4,
    PropertyAddress: "3 Cochranes PI, Summerlands",
    DDSAccountId: 1696,
    NoticeId: 11642,
    Assessment_Id: 20,
  },
  {
    AccountNumber: 1626056,
    Assessment: "577-60-6",
    OwnerNameAddress: "22 Bear St^contig 0X00F37FF54F0FFC4B81 0x00F37FF5",
    PrincipalBalance: 4951.0,
    OpeningBalance: 8892.64,
    PaymentsSinceLastNotice: -1287.0,
    InterestRaised: 57.04,
    InterestBalance: 2711.68,
    BalanceOutstanding: 7662.68,
    NoticeForNumber: 10,
    PropertyAddress: "18 Rachael PI, Temp",
    DDSAccountId: 295,
    NoticeId: 11643,
    Assessment_Id: 21,
  },
  {
    AccountNumber: 2241818,
    Assessment: "578-52-6",
    OwnerNameAddress:
      "1 Lakeview Tee 0X00F37FF54F0FFC4B81 0x00F37FF50X00F37FF54F0FF",
    PrincipalBalance: 9814.5,
    OpeningBalance: 10936.55,
    PaymentsSinceLastNotice: -780.0,
    InterestRaised: 103.46,
    InterestBalance: 445.51,
    BalanceOutstanding: 10260.01,
    NoticeForNumber: 4,
    PropertyAddress: "317 Plane St Rhyll",
    DDSAccountId: 1706,
    NoticeId: 11644,
    Assessment_Id: 22,
  },
  {
    AccountNumber: 2543801,
    Assessment: "713-68-3",
    OwnerNameAddress: "Common Property - Body CorporateRMB 4082 VIC 3981",
    PrincipalBalance: 8880.0,
    OpeningBalance: 11026.45,
    PaymentsSinceLastNotice: -1560.0,
    InterestRaised: 98.32,
    InterestBalance: 684.77,
    BalanceOutstanding: 9564.77,
    NoticeForNumber: 5,
    PropertyAddress: "20 Hopkins Cd Surf Beach",
    DDSAccountId: 1456,
    NoticeId: 11645,
    Assessment_Id: 23,
  },
  {
    AccountNumber: 2118790,
    Assessment: "706-5-3",
    OwnerNameAddress: "Little G J & R K83a Powell St VIC 3807",
    PrincipalBalance: 12090.0,
    OpeningBalance: 17004.96,
    PaymentsSinceLastNotice: -1976.0,
    InterestRaised: 133.07,
    InterestBalance: 3072.03,
    BalanceOutstanding: 15162.03,
    NoticeForNumber: 10,
    PropertyAddress: "7-11 Kenway St Pioneer Bay",
    DDSAccountId: 393,
    NoticeId: 11646,
    Assessment_Id: 24,
  },
  {
    AccountNumber: 2612921,
    Assessment: "569-153-21",
    OwnerNameAddress: "27 Fernhill Rd 0X00F37FF54F0FFC4B81 0x00F37FF5",
    PrincipalBalance: 1198.7,
    OpeningBalance: 3110.51,
    PaymentsSinceLastNotice: -780.0,
    InterestRaised: 16.15,
    InterestBalance: 1147.96,
    BalanceOutstanding: 2346.66,
    NoticeForNumber: 10,
    PropertyAddress: "14 Morris PI, Jam Jerrup",
    DDSAccountId: 503,
    NoticeId: 11647,
    Assessment_Id: 25,
  },
];
