import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyTitleFormElement } from "./components/form-element/_index";

export const PropertyTitleForm = observer(() => {
  const { title } = useTitleStore();
  const { pushNotification } = useCCAppNotificationStore();
  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const { isModified, isValid } = event;
    if (!isValid) return;
    if (!isModified) {
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={title?.titleSummary}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <PropertyTitleFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
