import { getDisplayTextWithDashes } from "@common/utils/common";
export const LookupActionTypeBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },

  getBookmarkListViewDetail() {
    return "Settings - Lookups - Action Types";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Settings - Action Types ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow.ID + ", " + selectedRow.Name]);
  },
};
