import { DTO_DeferredDuty_Account_Instalment } from "@app/products/property/deferred-duty/components/action-bar/dialogs/calculate-instalment/model";
export const mockCalculateInstalment: DTO_DeferredDuty_Account_Instalment[] = [
  {
    Date: "Sun Sep 20 2020 09:18:19 GMT+0700 (Indochina Time)",
    InstalmentNumber: 129,
    InstalmentAmount: 87.95,
    InstalmentBalance: 0,
    PaymentReceivedDate: "Tue Aug 03 2021 22:21:50 GMT+0700 (Indochina Time)",
    Id: 0,
  },
  {
    Date: "Wed Jan 01 2020 03:04:22 GMT+0700 (Indochina Time)",
    InstalmentNumber: 118,
    InstalmentAmount: 130,
    InstalmentBalance: 11,
    PaymentReceivedDate: "Sat Apr 25 2020 15:24:50 GMT+0700 (Indochina Time)",
    Id: 1,
  },
  {
    Date: "Sat Sep 05 2020 11:01:30 GMT+0700 (Indochina Time)",
    InstalmentNumber: 118,
    InstalmentAmount: 167,
    InstalmentBalance: 0,
    PaymentReceivedDate: "Sat Feb 01 2020 00:14:24 GMT+0700 (Indochina Time)",
    Id: 2,
  },
  {
    Date: "Sun Sep 20 2020 09:18:19 GMT+0700 (Indochina Time)",
    InstalmentNumber: 111,
    InstalmentAmount: 84.25,
    InstalmentBalance: 0,
    PaymentReceivedDate: "Tue Aug 03 2021 22:21:50 GMT+0700 (Indochina Time)",
    Id: 3,
  },
  {
    Date: "Sun Sep 20 2020 09:18:19 GMT+0700 (Indochina Time)",
    InstalmentNumber: 55,
    InstalmentAmount: 218.65,
    InstalmentBalance: 44,
    PaymentReceivedDate: "Tue Aug 03 2021 22:21:50 GMT+0700 (Indochina Time)",
    Id: 4,
  },
  {
    Date: "Sun Sep 20 2020 09:18:19 GMT+0700 (Indochina Time)",
    InstalmentNumber: 31,
    InstalmentAmount: 394,
    InstalmentBalance: 16.5,
    PaymentReceivedDate: "Tue Aug 03 2021 22:21:50 GMT+0700 (Indochina Time)",
    Id: 5,
  },
  {
    Date: "Sun Sep 20 2020 09:18:19 GMT+0700 (Indochina Time)",
    InstalmentNumber: 127,
    InstalmentAmount: 131,
    InstalmentBalance: 16.5,
    PaymentReceivedDate: "Tue Aug 03 2021 22:21:50 GMT+0700 (Indochina Time)",
    Id: 6,
  },
];
