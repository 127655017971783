import { InterestRateDialog } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/_index";
import { IInterestRateDialogData } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/model";
import {
  getMaxEffectiveFrom,
  sortByEffectiveForm,
} from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/components/dialogs/interest-rate/util";
import { colInterestRate } from "@app/products/property/schemes/[id]/components/forms/existed/components/dialogs/add-interest-rate/config";
import {
  ISchemesInterestRate,
  eSchemesInterestRateStatus,
} from "@app/products/property/schemes/[id]/model";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCNote } from "@components/cc-note/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { set } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
interface IAddInterestRateDialogProps {
  initialData: ISchemesInterestRate[];
  onClose: () => void;
  onSubmit: (data: ISchemesInterestRate[]) => void;
}

export const AddInterestRateDialog = ({
  initialData,
  onClose,
  onSubmit,
}: IAddInterestRateDialogProps) => {
  const [currentInterestRateData, setCurrentInterestRateData] =
    useState<ISchemesInterestRate[]>(initialData);
  const [selectedInterestRate, setSelectedInterestRateData] = useState<
    ISchemesInterestRate[]
  >([]);
  const [currentInterestRate, setCurrentInterestRate] = useState<
    ISchemesInterestRate | undefined
  >(undefined);
  const [isNewInterestRate, setIsNewInterestRate] = useState(false);
  const [showInterestRateDialog, setShowInterestRateDialog] = useState(false);
  const [currentMaxEffectiveFrom, setCurrentMaxEffectiveFrom] = useState<Date>(
    new Date()
  );
  const [currentEffectiveFromInUse, setCurrentEffectiveFromInUse] =
    useState<Date>(new Date());
  const [state, setState] = useState({
    skip: 0,
    take: 5,
  });
  const nameOf = nameOfFactory<ISchemesInterestRate>();
  const handleAddInterestRate = () => {
    setIsNewInterestRate(true);
    setShowInterestRateDialog(true);
    setCurrentInterestRate({
      EffectiveFrom: set(currentMaxEffectiveFrom, {
        date: currentMaxEffectiveFrom.getDate() + 1,
      }),
      InterestRate: null,
      PenaltyRate: null,
      Status: eSchemesInterestRateStatus.NEW,
      Id: getUUID(),
    });
  };

  const handleRemoveInterestRate = () => {
    setCurrentInterestRateData(
      _.differenceBy(
        currentInterestRateData,
        selectedInterestRate.filter((item) => item.Status !== "In Use"),
        "Id"
      )
    );
  };

  const getFields = colInterestRate.map((col) => {
    if (col.field === "EffectiveFrom") {
      col.handleOnClick = (dataItem: ISchemesInterestRate) => {
        if (dataItem.Status !== "In Use") {
          setIsNewInterestRate(false);
          setCurrentInterestRate(dataItem);
          setShowInterestRateDialog(true);
        }
      };
    }
    return col;
  });

  useEffect(() => {
    if (!currentInterestRateData.length) return;
    setCurrentMaxEffectiveFrom(getMaxEffectiveFrom(currentInterestRateData));
    if (
      currentInterestRateData.filter(
        (item) => item.Status === eSchemesInterestRateStatus.IN_USE
      ).length
    ) {
      setCurrentEffectiveFromInUse(
        getMaxEffectiveFrom(
          currentInterestRateData.filter(
            (item) => item.Status === eSchemesInterestRateStatus.IN_USE
          )
        )
      );
    }

    if (
      isNewInterestRate &&
      Math.floor(currentInterestRateData.length % 5) >= 1
    ) {
      setState({
        skip: Math.floor(currentInterestRateData.length / 5) * 5,
        take: 5,
      });
    }
  }, [currentInterestRateData, isNewInterestRate]);

  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <>
            <CCDialog
              titleHeader={"Add Interest Rate"}
              onClose={onClose}
              maxWidth="35%"
              maxHeight="55%"
              bodyElement={
                <div className="cc-form">
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <CCGrid
                      data={currentInterestRateData}
                      columnFields={getFields}
                      primaryField={"Id"}
                      onSelectionChange={(dataItem: any) => {
                        setSelectedInterestRateData(dataItem);
                      }}
                      disableRowField={nameOf("IsDisabledRow")}
                      selectableMode={"multiple"}
                      state={state}
                      toolbar={
                        <div className="cc-grid-tools-bar">
                          <Button
                            iconClass="fas fa-plus"
                            title="Add Interest Rate"
                            onClick={handleAddInterestRate}
                          />
                          <Button
                            iconClass="fas fa-minus"
                            title="Remove Interest Rate"
                            onClick={handleRemoveInterestRate}
                            disabled={!selectedInterestRate.length}
                          />
                        </div>
                      }
                    />
                    <CCNote
                      message={`The interest rate in use cannot be edited or removed.`}
                    />
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={() => {
                      onSubmit(currentInterestRateData);
                    }}
                    type={"submit"}
                  >
                    OK
                  </Button>
                </div>
              }
            />
            {showInterestRateDialog && (
              <InterestRateDialog
                data={
                  {
                    IsNew: isNewInterestRate,
                    MaxEffectiveFrom: currentMaxEffectiveFrom,
                    EffectiveFromInUse: currentEffectiveFromInUse,
                    InterestRateData: currentInterestRateData,
                    InterestRate: currentInterestRate,
                  } as IInterestRateDialogData
                }
                onClose={() => {
                  setShowInterestRateDialog(false);
                }}
                onSubmit={(data: ISchemesInterestRate[]) => {
                  setCurrentInterestRateData(sortByEffectiveForm(data));
                  setShowInterestRateDialog(false);
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};
