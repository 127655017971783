import { PremisesRegisterWorkflowRequest } from "@app/products/hm/premises/[id]/components/dialogs/register-premises/model";
import { HM_PREMISES_RENEWAL_OPTION_DIALOG_PLACE_ID } from "@app/products/hm/premises/[id]/constant";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IHMPremisesRegisterDialogProps {
  isLoading?: boolean;
  initialData?: IKeyValuePacket[];
  onClose: () => void;
  onSubmitDialog: (value: any) => void;
}

const nameOfPremisesRegisterWorkflowRequest =
  nameOfFactory<PremisesRegisterWorkflowRequest>();
const nameOfIKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export const HMPremisesRenewalOptionsDialog = observer(
  ({
    isLoading = false,
    initialData,
    onClose,
    onSubmitDialog,
  }: IHMPremisesRegisterDialogProps) => {
    const handleOnSubmitClick = async (event: FormSubmitClickEvent) => {
      onSubmitDialog(event?.values);
    };

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={{
          AnniversaryYear: null,
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, valueGetter, onChange } = formRenderProps;
          return (
            <CCDialog
              maxWidth="35%"
              height="auto"
              disabled={isLoading}
              yieldNotificationPortal={
                HM_PREMISES_RENEWAL_OPTION_DIALOG_PLACE_ID
              }
              titleHeader="Renewal Options"
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel title="Name" isMandatory />
                        <Field
                          name={nameOfPremisesRegisterWorkflowRequest(
                            "AnniversaryYear"
                          )}
                          component={CCComboBox}
                          textField={nameOfIKeyValuePacket("Value")}
                          dataItemKey={nameOfIKeyValuePacket("Key")}
                          data={initialData ?? []}
                          validator={requiredValidator}
                          onChange={(value: ComboBoxChangeEvent) => {
                            onChange(
                              nameOfPremisesRegisterWorkflowRequest(
                                "AnniversaryYear"
                              ),
                              {
                                value: value?.value?.Key,
                              }
                            );
                          }}
                          value={getDropdownValue(
                            valueGetter(
                              nameOfPremisesRegisterWorkflowRequest(
                                "AnniversaryYear"
                              )
                            ),
                            initialData ?? [],
                            nameOfIKeyValuePacket("Key")
                          )}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    disabled={!formRenderProps?.valid || isLoading}
                    className={"cc-dialog-button"}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
