import { getDisplayTextWithDashes } from "@common/utils/common";

export const BuildingBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Building ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return (
      getDisplayTextWithDashes([
        selectedRow?.AssessmentNo,
        selectedRow?.Applicant,
      ]) || "N/A"
    );
  },
};
