export const UNRESOLVED_EVENTS_BY_STATUS_URL =
  "/crms/unresolved-events/by-status";

export const UNRESOLVED_EVENTS_MY_EVENT_URL =
  "/crms/unresolved-events/my-events";

export const PHONE_MESSAGE_OPEN_MESSAGES_URL =
  "/crms/phone-messages/open-messages";

export const UNRESOLVED_EVENTS_BY_ORG_STRUCTURE_URL =
  "/crms/unresolved-events/by-org-structure";

export const CRMS_ALL_EVENTS_BY_REFNO_URL = "/crms/all-events/by-refno";
