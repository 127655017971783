import { PCBACategories } from "@app/products/hm/model";
import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { convertToTitleCase } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

export const generateHMPremisesPCBAColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  // Column Modifiers
  const columnDebtorNumberModifier = (column: IColumnFields) => {
    const debtorNumberLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );
    const title = debtorNumberLabel
      ? convertToTitleCase(debtorNumberLabel)
      : column.title;
    return { ...column, title };
  };

  const columnRegistrationNumberModifier = (column: IColumnFields) => {
    const registrationNumberLabel = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_RegistrationNoLabel]
    );
    const title = registrationNumberLabel
      ? convertToTitleCase(registrationNumberLabel)
      : column.title;
    return { ...column, title };
  };

  const columnMapper = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("DebtorNo"):
        return columnDebtorNumberModifier(column);
      case nameOfHMPremisesView("RegNo"):
        return columnRegistrationNumberModifier(column);
      default:
        return column;
    }
  };

  // Column Filters
  const columnFilterProprietorAddress = () => {
    return getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowProprietorAddress]
    );
  };

  const showPCBACategories = getNumberValueSetting(
    settings[ECorporateSettingsField.HealthManager_PCBACategories]
  );

  const columnFilterPCBACategories = (
    showPCBACategories: number,
    pcbaCategory: PCBACategories
  ) => {
    return (showPCBACategories & pcbaCategory) === pcbaCategory;
  };

  const columnFilter = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("ProprietorAddress"):
        return columnFilterProprietorAddress();
      case nameOfHMPremisesView("PCBA_BodyPiercing"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.BodyPiercing
        );
      case nameOfHMPremisesView("PCBA_BodyTreatments"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.BodyTreatments
        );
      case nameOfHMPremisesView("PCBA_ColonicIrrigation"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.ColonicIrrigation
        );
      case nameOfHMPremisesView("PCBA_CosmeticTattooing"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.CosmeticTattooing
        );
      case nameOfHMPremisesView("PCBA_CuticleCutting"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.CuticleCutting
        );
      case nameOfHMPremisesView("PCBA_Dermabrasion"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Dermabrasion
        );
      case nameOfHMPremisesView("PCBA_Accupuncture"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.DryNeedlingAccupuncture
        );
      case nameOfHMPremisesView("PCBA_EarPiercing"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.EarPiercing
        );
      case nameOfHMPremisesView("PCBA_Electrolysis"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Electrolysis
        );
      case nameOfHMPremisesView("PCBA_EyebrowEyelashTinting"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.EyebrowEyelashTinting
        );
      case nameOfHMPremisesView("PCBA_EyelashExtensions"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.EyelashExtensions
        );
      case nameOfHMPremisesView("PCBA_Facials"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Facials
        );
      case nameOfHMPremisesView("PCBA_FacialsMakeup"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.FacialsMakeUp
        );
      case nameOfHMPremisesView("PCBA_Footscraping"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Footscraping
        );
      case nameOfHMPremisesView("PCBA_Footspa"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Footspa
        );
      case nameOfHMPremisesView("PCBA_Hairdressing"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Hairdressing
        );
      case nameOfHMPremisesView("PCBA_HairExtensions"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.HairExtensions
        );
      case nameOfHMPremisesView("PCBA_HennaTattoos"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.HennaTattoos
        );
      case nameOfHMPremisesView("PCBA_LaserTreatment"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.LaserTreatment
        );
      case nameOfHMPremisesView("PCBA_Makeup"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.MakeUp
        );
      case nameOfHMPremisesView("PCBA_ManicurePedicure"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.ManicurePedicure
        );
      case nameOfHMPremisesView("PCBA_NailTreatment"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.NailTreatment
        );
      case nameOfHMPremisesView("PCBA_SprayTan"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.SprayTan
        );
      case nameOfHMPremisesView("PCBA_Tattooing"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Tattooing
        );
      case nameOfHMPremisesView("PCBA_TemporaryMakeUp"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.TemporaryMakeUp
        );
      case nameOfHMPremisesView("PCBA_Threading"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Threading
        );
      case nameOfHMPremisesView("PCBA_Waxing"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Waxing
        );
      case nameOfHMPremisesView("PCBA_Wigs"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Wigs
        );
      case nameOfHMPremisesView("PCBA_Other"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Other
        );
      case nameOfHMPremisesView("PCBA_PRP"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.PRP
        );
      case nameOfHMPremisesView("PCBA_Microblading"):
        return columnFilterPCBACategories(
          showPCBACategories,
          PCBACategories.Microblading
        );
      default:
        return true;
    }
  };

  return cloneDeep(defaultConfig).filter(columnFilter).map(columnMapper);
};
