import {
  DTO_Component_External_Process,
  DTO_Variable,
  eSqlDataType,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import { isUndefined, toString } from "lodash";

export const processPayloadData = (
  variablesData: DTO_Variable[],
  data?: DTO_Component_External_Process
) => {
  return variablesData.map((variable: DTO_Variable) => {
    const matchedVariable = data?.Variables.find(
      (dataVariable: DTO_Variable) =>
        dataVariable.Variable_Id === variable.Variable_Id
    );

    if (isUndefined(matchedVariable?.Variable_Default)) {
      const { Variable_Default, ...rest } = variable;
      return rest;
    }

    return {
      ...variable,
      Variable_Default: matchedVariable.Variable_Default,
      Variable_Value:
        processValue(variable.Variable_Value, variable.Variable_Data_Type) ??
        processValue(variable.Variable_Default, variable.Variable_Data_Type),
    };
  });
};

const processValue = (value: string, dataType: string) => {
  if (dataType === eSqlDataType.BIT) {
    return toString(!!+value);
  }

  return value;
};
