import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  DTO_ChangeOfOwnership_LeaseTransferBuyers,
  DTO_ChangeOfOwnership_LeaseTransferExceptions,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/model";

export const getChangesOfOwnershipLeaseTransferBuyers = async (
  leaseTransferId: number
): Promise<
  APIResponse<DTO_ChangeOfOwnership_LeaseTransferBuyers | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChangeOfOwnership_LeaseTransferBuyers>(
      `api/property/int/changeofownership/leasetransfer/${leaseTransferId}/buyers`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getChangesOfOwnershipLeaseTransferExceptions = async (
  leaseTransferId: number,
  assessmentId: number
): Promise<
  APIResponse<DTO_ChangeOfOwnership_LeaseTransferExceptions | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChangeOfOwnership_LeaseTransferExceptions>(
      `api/property/int/changeofownership/leasetransfer/${leaseTransferId}/${assessmentId}/exceptions`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
