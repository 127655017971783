import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import ModifyMeterInstallationDialog from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-installation/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IModifyInstallationButtonProps {
  onModify: () => void;
}

export const ModifyInstallationButton = observer(
  ({ onModify }: IModifyInstallationButtonProps) => {
    const [
      showModifyMeterInstallationDialog,
      setShowModifyMeterInstallationDialog,
    ] = useState<boolean>(false);
    const { setIsModifyInstallation } = useMeterInstallationStore();
    return (
      <>
        <CCNavButton
          title="Modify installation"
          onClick={() => {
            setShowModifyMeterInstallationDialog(true);
            setIsModifyInstallation(true);
          }}
        />

        {showModifyMeterInstallationDialog && (
          <ModifyMeterInstallationDialog
            onClose={() => {
              setShowModifyMeterInstallationDialog(false);
            }}
            onModify={() => {
              onModify();
              setShowModifyMeterInstallationDialog(false);
            }}
          />
        )}
      </>
    );
  }
);
