import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { ConditionsSection } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/conditions-section/_index";
import { DetailsSection } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/details-section/_index";
import { FeesSection } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/fee-section/_index";
import {
  PermitType,
  PermitTypeUiControl,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IPermitTypeFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<PermitType>();

export const PermitTypeFormElement = observer(
  ({ formRenderProps }: IPermitTypeFormElementProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { isDeleted } = dataForms?.GeneralUIControl as PermitTypeUiControl;
    return (
      <FormElement id="cc-form-manage-page-general">
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Name" isMandatory />
              <Field
                component={CCInput}
                name={nameOf("Name")}
                validator={requiredValidator}
                disabled={isDeleted}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Description" />
              <Field
                component={CCTextArea}
                name={nameOf("Description")}
                rows={3}
                disabled={isDeleted}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <DetailsSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <FeesSection formRenderProps={formRenderProps} />
        <hr className="cc-divider" />
        <ConditionsSection formRenderProps={formRenderProps} />
      </FormElement>
    );
  }
);
