import {
  colActions,
  colBalances,
  colFunctionalityRestrictions,
  colOwner,
} from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/form-element/config";
import { mockFinancialGroup } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/form-element/mock";
import {
  BalanceTypesEnum,
  DTO_DebtRecovery,
  DTO_DebtRecovery_Balance,
  DTO_FunctionalityRestriction,
  DTO_Owner,
  DebtRecoveryAction,
} from "@app/products/property/assessments/debt-recovery/[id]/model";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IDebtRecoveryFormElementProps {
  formRenderProps: FormRenderProps;
}
const nameOf = nameOfFactory<DTO_DebtRecovery>();
const nameOfOwner = nameOfFactory<DTO_Owner>();
const nameOfDebtRecoveryAction = nameOfFactory<DebtRecoveryAction>();
const nameOfFunctionalityRestrictions =
  nameOfFactory<DTO_FunctionalityRestriction>();

export const DebtRecoveryFormElement = observer(
  ({ formRenderProps }: IDebtRecoveryFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const {
      debtRecovery,
      debtRecoveryFunctionalities,
      setDebtRecoveryActionSelectedRow,
    } = useDebtRecoveryStore();
    const { onChange, valueGetter } = formRenderProps;
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const balancesData: DTO_DebtRecovery_Balance[] = useMemo(() => {
      const data = debtRecovery?.Balance;
      return [
        {
          Type: BalanceTypesEnum.Charges,
          InitialDept: data?.InitialDebtCharges ?? 0,
          DebtBalances: data?.DebtbalanceCharges ?? 0,
        },
        {
          Type: BalanceTypesEnum.Interest,
          InitialDept: data?.InitialDebtInterests ?? 0,
          DebtBalances: data?.DebtbalanceInterests ?? 0,
        },
        {
          Type: BalanceTypesEnum.Cost,
          InitialDept: data?.InitialDebtCosts ?? 0,
          DebtBalances: data?.DebtbalanceCosts ?? 0,
        },
        {
          Type: BalanceTypesEnum.Total,
          InitialDept: data?.InitialDebtTotal ?? 0,
          DebtBalances: data?.DebtbalanceTotal ?? 0,
        },
      ];
    }, [debtRecovery]);

    return (
      <FormElement className="cc-debt-recovery-form-body">
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Debt recovery ID</label>
              <Field
                readOnly
                name={nameOf("DebtRecoveryId")}
                placeholder={"Debt recovery id"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Field
                readOnly
                name={nameOf("DebtRecoveryType")}
                placeholder={"Type"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Responsible officer</label>
              <Field
                readOnly
                name={nameOf("ResponsibleOfficer")}
                placeholder={"Responsible officer"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Active from</label>
              <Field
                disabled
                name={nameOf("ActiveFrom")}
                placeholder={"Active from"}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Active to</label>
              <Field
                disabled
                name={nameOf("ActiveTo")}
                placeholder={"Active to"}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                readOnly
                name={nameOf("Status")}
                placeholder={"Status"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Solicitor reference</label>
              <Field
                readOnly
                name={"SolicitorReference"}
                placeholder={"Solicitor reference"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Financial group</label>
              <Field
                disabled
                name={"FinancialGroup"}
                component={CCMultiSelectDropdown}
                //TODO: Add data from api
                data={mockFinancialGroup}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                placeholder="Financial group"
                onChange={(event: MultiSelectChangeEvent) => {
                  onChange("FinancialGroup", {
                    value: event.value,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Show alert on assessment</label>
              <Field
                disabled
                //TODO: Waiting backend update DTO
                name={"ShowAlertOnAssessment"}
                placeholder={"Show alert on assessment"}
                checked={valueGetter("ShowAlertOnAssessment")}
                component={CCSwitch}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Created on</label>
              <Field
                disabled
                name={nameOf("CreatedOn")}
                placeholder={"Created on"}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Created by</label>
              <Field
                readOnly
                name={nameOf("CreatedBy")}
                placeholder={"Created by"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Changed on</label>
              <Field
                disabled
                name={nameOf("ChangedOn")}
                placeholder={"Changed on"}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Changed by</label>
              <Field
                readOnly
                name={nameOf("ChangedBy")}
                placeholder={"Create on"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Ratepayers</label>
              <Field
                readOnly
                name={nameOf("RatePayer")}
                placeholder={"Ratepayers"}
                rows={3}
                component={CCTextArea}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Owners</label>
              <CCGrid
                className="cc-debtor-grid"
                data={debtRecovery?.Owners ?? []}
                columnFields={colOwner}
                primaryField={nameOfOwner("EntityId")}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Functionality restrictions</label>
              <CCGrid
                className="cc-functionality-restrictions-grid"
                data={debtRecoveryFunctionalities}
                columnFields={colFunctionalityRestrictions}
                primaryField={nameOfFunctionalityRestrictions(
                  "FunctionalityName"
                )}
              />
            </div>
            <div className="cc-field cc-col-span-1">
              <label className="cc-label">Balances</label>
              <CCGrid
                className="cc-balances-grid"
                data={balancesData}
                columnFields={colBalances}
                primaryField={nameOfFunctionalityRestrictions(
                  "FunctionalityName"
                )}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Documents issued, cost applied, etc.
              </label>
              <CCGrid
                className="cc-actions-grid"
                data={valueGetter(nameOf("Actions")) ?? []}
                columnFields={colActions}
                primaryField={nameOfDebtRecoveryAction("DebtRecoveryActionId")}
                selectableMode="single"
                onSelectionChange={(dataItem: DebtRecoveryAction[]) => {
                  setDebtRecoveryActionSelectedRow(dataItem?.[0]);
                }}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
