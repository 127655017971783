import { isSuccessResponse } from "@common/apis/util";
import {
  BubbleupType_ENUM,
  Svc_BubbleupIdentifier,
} from "@common/pages/actions/[id]/components/reference-sidebar/details/model";
import { getAppealDetailTab } from "@common/pages/appeal/_id/components/reference-sidebar/detail/api";
import { AppealTabDetails } from "@common/pages/appeal/_id/components/reference-sidebar/detail/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AppealDetailTabStore {
  private _appealDetailTab?: AppealTabDetails = undefined;
  private _appealBubbleUpParent?: Svc_BubbleupIdentifier = undefined;
  private _appealBubbleUpAncestor?: Svc_BubbleupIdentifier = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get appealDetailTab() {
    return toJS(this._appealDetailTab);
  }
  setAppealDetailTab = (appealDetailTab?: AppealTabDetails | undefined) => {
    runInAction(() => {
      this._appealDetailTab = appealDetailTab;
    });
  };

  get appealBubbleUpParent() {
    return toJS(this._appealBubbleUpParent);
  }
  setAppealBubbleUpParent = (
    appealBubbleUpParent: Svc_BubbleupIdentifier | undefined
  ) => {
    runInAction(() => {
      this._appealBubbleUpParent = appealBubbleUpParent;
    });
  };

  get appealBubbleUpAncestor() {
    return toJS(this._appealBubbleUpAncestor);
  }
  setAppealBubbleUpAncestor = (
    appealBubbleUpAncestor: Svc_BubbleupIdentifier | undefined
  ) => {
    runInAction(() => {
      this._appealBubbleUpAncestor = appealBubbleUpAncestor;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._appealDetailTab = undefined;
      this._isLoading = false;
      this._appealBubbleUpParent = undefined;
      this._appealBubbleUpAncestor = undefined;
    });
  };

  loadAppealDetailTab = async (appealId: number) => {
    this.setIsLoading(true);
    getAppealDetailTab(appealId).then((response) => {
      if (Array.isArray(response)) {
        const [detailTabResponse, bubbleupDetailsResponse] = response;
        const detailTabData = detailTabResponse?.data;
        const bubbleupDetailsData = bubbleupDetailsResponse?.data;
        if (
          isSuccessResponse(detailTabResponse) &&
          isSuccessResponse(bubbleupDetailsResponse) &&
          detailTabData
        ) {
          this.setAppealDetailTab(detailTabData);

          if (bubbleupDetailsData) {
            //Set parent info
            this.setAppealBubbleUpParent(
              bubbleupDetailsData.BubbleList.find(
                (bubbleUpItem: Svc_BubbleupIdentifier) =>
                  bubbleUpItem.BubbleupType_ENUM === BubbleupType_ENUM.Parent
              )
            );

            //Set ancestor info
            if (!bubbleupDetailsData.AncestorIsSameAsParent) {
              this.setAppealBubbleUpAncestor(
                bubbleupDetailsData.BubbleList.find(
                  (bubbleUpItem: Svc_BubbleupIdentifier) =>
                    bubbleUpItem.BubbleupType_ENUM ===
                    BubbleupType_ENUM.Ancestor
                )
              );
            }
          }
        } else {
          //TODO:Enhance
          //console.log("Detail load failed");
        }
        this.setIsLoading(false);
      }
    });
  };
}
const AppealDetailTabStoreContext = createContext(new AppealDetailTabStore());
export const useAppealDetailTabStore = () => {
  return useContext(AppealDetailTabStoreContext);
};
