import { DTO_Role } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { DTO_LOV_Number } from "@common/models/odataResponse";

export enum EDialogContact {
  Update = 1,
  New = 2,
  Formatted = 3,
}

export interface DTO_Entity_Details {
  Entity_Id: number;
  Entity_Name_Address_Id: number;
  Role_Name: string;
  Name: string;
  Address: string;
  isnew: boolean;
}

export interface ILovContact {
  Role: DTO_Role[];
  NoticeGroup: DTO_LOV_Number[];
}
