import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";
import { DTO_LevyBalance } from "./model";
const nameOf = nameOfFactory<DTO_LevyBalance>();
export const colByLevyRatingPeriod: IColumnFields[] = [
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("TotalBalanceOwing"),
    title: "Total Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
];

export const colByLevyByFinancialArea: IColumnFields[] = [
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Interest"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TotalBalanceOwing"),
    title: "Total Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
