import { IOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/model";
import { PickOrgStructurePicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingDropdown } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-dropdown/_index";
import { GlobalSettingText } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/global-text-input/_index";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { configData, getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter } = formRenderProps;
  const fieldStaffGroup = getConfigDataField(
    ECorporateSettingsField.AdhocGroup_AllStaff
  );
  const staffGroupDisplayValue =
    formRenderProps?.valueGetter(
      `option.ValueDisplayDetails_${ECorporateSettingsField.AdhocGroup_AllStaff}`
    )[0] ?? "";

  const handleOnChangeOrgStructure = (selectedValue: IOrgUnit | null) => {
    formRenderProps?.onChange(
      `option.ValueDisplayDetails_${ECorporateSettingsField.AdhocGroup_AllStaff}`,
      {
        value: [selectedValue?.Hierarchy],
      }
    );
    formRenderProps?.onChange(fieldStaffGroup?.FieldName ?? "", {
      value: selectedValue?.ID,
    });
  };

  const orgLevels = valueGetter(
    ECorporateSettingsField.CorporateSettings_OrgStructureLevels.toString()
  );

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <GlobalSettingDropdown
          enumParam={
            ECorporateSettingsField.CorporateSettings_OrgStructureLevels
          }
        />
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_LabelOrg1}
        />
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_LabelOrg2}
        />
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_LabelOrg3}
        />
        {(orgLevels === "4" || orgLevels === "5") && (
          <GlobalSettingText
            enumParam={ECorporateSettingsField.CorporateSettings_LabelOrg4}
          />
        )}
        {orgLevels === "5" && (
          <GlobalSettingText
            enumParam={ECorporateSettingsField.CorporateSettings_LabelOrg5}
          />
        )}
        {fieldStaffGroup && (
          <div className="cc-field">
            <CCLabel title={fieldStaffGroup.Title} isMandatory={false} />
            <Field
              name={fieldStaffGroup.FieldName}
              component={PickOrgStructurePicker}
              value={staffGroupDisplayValue}
              allowClear={false}
              adhocGroup
              disabled={!isEditing}
              onPickOrgStructure={handleOnChangeOrgStructure}
            />
          </div>
        )}
      </div>
    </section>
  );
});
