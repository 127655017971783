import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCorporateAuthStore } from "@common/pages/settings/security/corporate-authorisations/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CorpAuthHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { corporateAuthId, corporateAuth } = useCorporateAuthStore();
  const corpAuthId: number = corporateAuthId ?? lastSelectedRow?.ID;
  return (
    <Journal
      id={corpAuthId}
      triggersReload={corporateAuth}
      recordType={RECORDTYPE.CORE_SiteUser_CorporateAuthorisation}
    />
  );
});
