import { APIResponse } from "@common/apis/model";
import { getDashboardViewResultSet } from "@common/pages/home/components/event-section/components/dashboard-view/api";
import { colEvent } from "@common/pages/home/components/event-section/components/dashboard-view/config";
import {
  Svc_DashboardViewResultRow,
  Svc_DashboardViewResultSet,
  Svc_DashboardViews,
} from "@common/pages/home/components/event-section/components/dashboard-view/model";
import {
  getLinkPageNavigate,
  isHTML,
} from "@common/pages/home/components/event-section/components/dashboard-view/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import Loading from "@components/loading/Loading";
import { orderBy } from "lodash";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<Svc_DashboardViewResultRow>();
export const DashboardView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardViews, setDashboardViews] = useState<
    Svc_DashboardViews | undefined
  >();
  useEffectOnce(() => {
    setIsLoading(true);
    getDashboardViewResultSet(true)
      .then((response: APIResponse<Svc_DashboardViews | undefined>) => {
        if (response?.data) setDashboardViews(response.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setIsLoading(false);
      });
  });

  const renderColumnFields = (colField: IColumnFields[]) => {
    return colField.map((column: IColumnFields) => {
      if (column.field === nameOf("MainDetail")) {
        return {
          ...column,
          linkTo: (dataItem: Svc_DashboardViewResultRow) => {
            const linkPageNavigate = getLinkPageNavigate(
              dataItem.RecordType_ENUM
            );
            return `${linkPageNavigate}/${dataItem.ID}`;
          },
        };
      }
      return column;
    });
  };

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {orderBy(dashboardViews?.Views ?? [], ["SortIndex"])?.map(
            (item: Svc_DashboardViewResultSet, index: number) => {
              let newItemData = item?.Rows?.length ? [...item.Rows] : [];
              if (newItemData?.length) {
                newItemData = newItemData.map(
                  (item: Svc_DashboardViewResultRow) =>
                    item?.MainDetail && isHTML(item.MainDetail)
                      ? {
                          ...item,
                          MainDetail: item.MainDetail.replace(
                            /<[^>]+>|&nbsp;/g,
                            ""
                          ),
                        }
                      : item
                );
              }
              return (
                <div key={index}>
                  <div className="cc-event-title">
                    {item?.ViewHeading}
                    {newItemData?.length > 0 && ` (${newItemData.length})`}
                  </div>
                  <CCGrid
                    className="cc-event-grid"
                    data={newItemData ?? []}
                    columnFields={renderColumnFields(colEvent)}
                    primaryField={nameOf("ID")}
                  />
                </div>
              );
            }
          )}
        </>
      )}
    </>
  );
};
