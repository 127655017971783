import React from 'react';

interface IProps {}

export const PhoneMessagesTab = (props: IProps) => {
  return (
    <div className={'pt-4'}>
      <b>This is Phone Messages Tab !</b>
    </div>
  );
};
