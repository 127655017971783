export const mockCSL = [
  {
    ID: 1,
    LicenceNumber: 76324,
    Applicant: "Tania Jones",
    Category: "Shoes",
    FormattedAddress: "Suite 798",
    Suburb: "Luxembourg",
    DateExpires: new Date("2021-04-27T12:09:21.071Z"),
    PestType_Display: "Georgia",
    BusinessType_Display: "compressing Metal",
    DateLodged: "2020-07-16T01:42:08.455Z",
    DateApproved: "2021-12-14T08:24:39.832Z",
    RenewalStatus: "Ohio",
    AmountOS: 47421,
    DateIssued: "2021-09-15T19:27:11.930Z",
    PostalAddress: "Suite 347",
    Business: "Shoes",
    Email: "Sadye.Paucek@yahoo.com",
    TradingName: "Ergonomic Steel Pizza",
    DatePhoto: "DatePhoto 1",
    DateDue: "2021-09-17T10:07:37.913Z",
    ReferenceNumber: 87785,
    CreatedDate: "2020-04-14T03:10:16.827Z",
    FeeType: "Ergonomic",
    AmountIssued: 74930,
    AmountOutstanding: 7625,
    CostCode: 34236,
    LicenceCategory: "Kids",
    DateInspected: "2021-06-08T00:19:40.082Z",
    InspectionType: "InspectionType 1",
    Officer: "Ernesto Lemke",
    Compliance: "Compliance 1",
    Reason: "Reason 1",
    Postcode: "Postcode 1",
    Name: "Marcos Wiza",
    Code: "Code 1",
    Flag_PestControl: "Flag_PestControl 1",
    Type: "Type 1",
    CurrentFee: 73339,
    FutureFee: 56577,
    FutureFeeDate: "2021-03-29T11:46:43.074Z",
    Group: "Group 1",
    SortIndex: "SortIndex 1",
    Title: "engage Associate Object-based",
    Status: "Active",
    Condition: "Condition 1",
  },
  {
    ID: 2,
    LicenceNumber: 95835,
    Applicant: "Eddie VonRueden",
    Category: "Kids",
    FormattedAddress: "Apt. 537",
    Suburb: "Poland",
    DateExpires: new Date("2021-10-27T17:15:24.716Z"),
    PestType_Display: "moderator",
    BusinessType_Display: "Brazilian Real",
    DateLodged: "2020-09-17T08:04:38.748Z",
    DateApproved: "2021-03-01T15:39:45.755Z",
    RenewalStatus: "Group Agent",
    AmountOS: 91653,
    DateIssued: "2021-06-27T21:08:37.204Z",
    PostalAddress: "Apt. 286",
    Business: "Clothing",
    Email: "Jennifer35@gmail.com",
    TradingName: "Rustic Steel Chair",
    DatePhoto: "DatePhoto 2",
    DateDue: "2021-01-13T21:38:06.920Z",
    ReferenceNumber: 33000,
    CreatedDate: "2020-10-18T22:59:50.676Z",
    FeeType: "Metal",
    AmountIssued: 54853,
    AmountOutstanding: 64315,
    CostCode: 13348,
    LicenceCategory: "Automotive",
    DateInspected: "2021-02-05T08:55:57.799Z",
    InspectionType: "InspectionType 2",
    Officer: "Lisa Stokes IV",
    Compliance: "Compliance 2",
    Reason: "Reason 2",
    Postcode: "Postcode 2",
    Name: "Ludwig Heathcote",
    Code: "Code 2",
    Flag_PestControl: "Flag_PestControl 2",
    Type: "Type 2",
    CurrentFee: 57631,
    FutureFee: 91672,
    FutureFeeDate: "2021-06-29T00:17:06.745Z",
    Group: "Group 2",
    SortIndex: "SortIndex 2",
    Title: "Rapids Chair",
    Status: "Active",
    Condition: "Condition 2",
  },
  {
    ID: 3,
    LicenceNumber: 44486,
    Applicant: "Judge Borer",
    Category: "Electronics",
    FormattedAddress: "Apt. 852",
    Suburb: "Sweden",
    DateExpires: new Date("2021-01-16T05:06:12.287Z"),
    PestType_Display: "Singapore Dollar",
    BusinessType_Display: "channels platforms mobile",
    DateLodged: "2020-12-08T22:06:18.621Z",
    DateApproved: "2021-05-17T21:41:16.355Z",
    RenewalStatus: "Reduced",
    AmountOS: 75126,
    DateIssued: "2021-06-07T00:58:26.577Z",
    PostalAddress: "Apt. 750",
    Business: "Movies",
    Email: "Savion_Legros@hotmail.com",
    TradingName: "Generic Granite Table",
    DatePhoto: "DatePhoto 3",
    DateDue: "2021-07-26T19:52:37.162Z",
    ReferenceNumber: 33740,
    CreatedDate: "2020-05-08T13:58:40.017Z",
    FeeType: "Senior",
    AmountIssued: 73558,
    AmountOutstanding: 45714,
    CostCode: 85024,
    LicenceCategory: "Sports",
    DateInspected: "2021-09-20T20:19:16.763Z",
    InspectionType: "InspectionType 3",
    Officer: "Laurie Bogisich",
    Compliance: "Compliance 3",
    Reason: "Reason 3",
    Postcode: "Postcode 3",
    Name: "Chance Dicki",
    Code: "Code 3",
    Flag_PestControl: "Flag_PestControl 3",
    Type: "Type 3",
    CurrentFee: 32862,
    FutureFee: 11964,
    FutureFeeDate: "2021-03-28T23:40:45.390Z",
    Group: "Group 3",
    SortIndex: "SortIndex 3",
    Title: "FTP",
    Status: "Active",
    Condition: "Condition 3",
  },
  {
    ID: 4,
    LicenceNumber: 92540,
    Applicant: "Miss Savannah Murazik",
    Category: "Industrial",
    FormattedAddress: "Apt. 698",
    Suburb: "Portugal",
    DateExpires: new Date("2021-06-19T07:54:59.955Z"),
    PestType_Display: "Cheese",
    BusinessType_Display: "killer",
    DateLodged: "2020-02-01T00:45:14.694Z",
    DateApproved: "2021-11-09T14:35:05.475Z",
    RenewalStatus: "expedite",
    AmountOS: 76417,
    DateIssued: "2021-11-30T22:32:40.901Z",
    PostalAddress: "Apt. 358",
    Business: "Beauty",
    Email: "Roxanne.Bergstrom57@hotmail.com",
    TradingName: "Practical Frozen Chair",
    DatePhoto: "DatePhoto 4",
    DateDue: "2021-12-14T03:12:30.722Z",
    ReferenceNumber: 93980,
    CreatedDate: "2021-01-09T11:54:07.457Z",
    FeeType: "Garden",
    AmountIssued: 5074,
    AmountOutstanding: 78361,
    CostCode: 53570,
    LicenceCategory: "Books",
    DateInspected: "2021-10-28T00:14:30.847Z",
    InspectionType: "InspectionType 4",
    Officer: "Krystal Ruecker",
    Compliance: "Compliance 4",
    Reason: "Reason 4",
    Postcode: "Postcode 4",
    Name: "Salvador Balistreri",
    Code: "Code 4",
    Flag_PestControl: "Flag_PestControl 4",
    Type: "Type 4",
    CurrentFee: 9745,
    FutureFee: 66313,
    FutureFeeDate: "2021-06-05T13:14:39.435Z",
    Group: "Group 4",
    SortIndex: "SortIndex 4",
    Title: "salmon",
    Status: "Active",
    Condition: "Condition 4",
  },
  {
    ID: 5,
    LicenceNumber: 94045,
    Applicant: "Ladarius Prosacco",
    Category: "Movies",
    FormattedAddress: "Apt. 185",
    Suburb: "Venezuela",
    DateExpires: new Date("2021-11-25T07:39:00.185Z"),
    PestType_Display: "Specialist",
    BusinessType_Display: "interfaces Personal Loan Account Diverse",
    DateLodged: "2020-04-12T21:23:16.553Z",
    DateApproved: "2021-09-17T19:11:30.646Z",
    RenewalStatus: "Home",
    AmountOS: 99852,
    DateIssued: "2021-04-27T13:25:09.789Z",
    PostalAddress: "Suite 741",
    Business: "Automotive",
    Email: "Keagan.Berge74@hotmail.com",
    TradingName: "Tasty Wooden Hat",
    DatePhoto: "DatePhoto 5",
    DateDue: "2021-11-30T11:05:46.312Z",
    ReferenceNumber: 72462,
    CreatedDate: "2020-07-26T05:32:43.302Z",
    FeeType: "Generic",
    AmountIssued: 13473,
    AmountOutstanding: 47300,
    CostCode: 4865,
    LicenceCategory: "Music",
    DateInspected: "2021-11-11T02:29:19.017Z",
    InspectionType: "InspectionType 5",
    Officer: "Ricky Oberbrunner",
    Compliance: "Compliance 5",
    Reason: "Reason 5",
    Postcode: "Postcode 5",
    Name: "Jovanny Gerlach",
    Code: "Code 5",
    Flag_PestControl: "Flag_PestControl 5",
    Type: "Type 5",
    CurrentFee: 93980,
    FutureFee: 70500,
    FutureFeeDate: "2021-05-13T15:04:50.294Z",
    Group: "Group 5",
    SortIndex: "SortIndex 5",
    Title: "parse open-source",
    Status: "Active",
    Condition: "Condition 5",
  },
  {
    ID: 6,
    LicenceNumber: 28493,
    Applicant: "Clare Tillman",
    Category: "Jewelery",
    FormattedAddress: "Suite 406",
    Suburb: "Bermuda",
    DateExpires: new Date("2021-04-14T04:35:44.079Z"),
    PestType_Display: "index",
    BusinessType_Display: "Gourde US Dollar Kids",
    DateLodged: "2020-06-07T03:18:25.393Z",
    DateApproved: "2021-06-14T16:46:21.525Z",
    RenewalStatus: "Compatible Kuwait",
    AmountOS: 42368,
    DateIssued: "2022-01-05T06:38:10.614Z",
    PostalAddress: "Apt. 850",
    Business: "Tools",
    Email: "Agustin.Langosh83@gmail.com",
    TradingName: "Refined Wooden Towels",
    DatePhoto: "DatePhoto 6",
    DateDue: "2021-11-09T19:57:15.510Z",
    ReferenceNumber: 18461,
    CreatedDate: "2020-06-18T05:25:46.763Z",
    FeeType: "Money Market Account",
    AmountIssued: 18714,
    AmountOutstanding: 81775,
    CostCode: 68577,
    LicenceCategory: "Kids",
    DateInspected: "2021-12-04T09:05:18.043Z",
    InspectionType: "InspectionType 6",
    Officer: "Deanna Baumbach",
    Compliance: "Compliance 6",
    Reason: "Reason 6",
    Postcode: "Postcode 6",
    Name: "Ardella Cummerata",
    Code: "Code 6",
    Flag_PestControl: "Flag_PestControl 6",
    Type: "Type 6",
    CurrentFee: 82071,
    FutureFee: 40855,
    FutureFeeDate: "2021-10-15T09:02:03.642Z",
    Group: "Group 6",
    SortIndex: "SortIndex 6",
    Title: "SSL redundant",
    Status: "Active",
    Condition: "Condition 6",
  },
  {
    ID: 7,
    LicenceNumber: 34662,
    Applicant: "Elda King DDS",
    Category: "Industrial",
    FormattedAddress: "Apt. 376",
    Suburb: "Madagascar",
    DateExpires: new Date("2021-01-15T19:42:36.644Z"),
    PestType_Display: "CSS",
    BusinessType_Display: "Gloves",
    DateLodged: "2020-05-02T23:29:30.639Z",
    DateApproved: "2021-04-12T06:46:18.931Z",
    RenewalStatus: "invoice GB",
    AmountOS: 68878,
    DateIssued: "2021-02-02T00:49:03.938Z",
    PostalAddress: "Apt. 210",
    Business: "Garden",
    Email: "Eriberto_Grimes97@hotmail.com",
    TradingName: "Generic Cotton Hat",
    DatePhoto: "DatePhoto 7",
    DateDue: "2021-04-10T00:39:21.710Z",
    ReferenceNumber: 36844,
    CreatedDate: "2020-07-24T05:55:40.098Z",
    FeeType: "Savings Account",
    AmountIssued: 75914,
    AmountOutstanding: 72775,
    CostCode: 98156,
    LicenceCategory: "Electronics",
    DateInspected: "2021-02-06T22:24:27.711Z",
    InspectionType: "InspectionType 7",
    Officer: "Floyd Casper",
    Compliance: "Compliance 7",
    Reason: "Reason 7",
    Postcode: "Postcode 7",
    Name: "Houston Mann I",
    Code: "Code 7",
    Flag_PestControl: "Flag_PestControl 7",
    Type: "Type 7",
    CurrentFee: 52602,
    FutureFee: 69383,
    FutureFeeDate: "2021-06-23T12:58:11.460Z",
    Group: "Group 7",
    SortIndex: "SortIndex 7",
    Title: "Arkansas Executive",
    Status: "Active",
    Condition: "Condition 7",
  },
  {
    ID: 8,
    LicenceNumber: 98974,
    Applicant: "Sister Gerhold",
    Category: "Jewelery",
    FormattedAddress: "Suite 081",
    Suburb: "New Caledonia",
    DateExpires: new Date("2021-09-21T06:34:54.380Z"),
    PestType_Display: "Bedfordshire",
    BusinessType_Display: "US Dollar hack",
    DateLodged: "2020-02-01T19:39:37.970Z",
    DateApproved: "2021-10-31T21:06:34.943Z",
    RenewalStatus: "project Checking Account",
    AmountOS: 13312,
    DateIssued: "2021-08-05T05:13:22.268Z",
    PostalAddress: "Apt. 350",
    Business: "Beauty",
    Email: "Fausto.Rosenbaum@gmail.com",
    TradingName: "Refined Plastic Chips",
    DatePhoto: "DatePhoto 8",
    DateDue: "2021-06-19T02:34:54.175Z",
    ReferenceNumber: 53121,
    CreatedDate: "2020-04-04T09:04:52.724Z",
    FeeType: "Refined",
    AmountIssued: 36281,
    AmountOutstanding: 57568,
    CostCode: 73614,
    LicenceCategory: "Health",
    DateInspected: "2021-01-29T21:40:51.037Z",
    InspectionType: "InspectionType 8",
    Officer: "Deja Hauck",
    Compliance: "Compliance 8",
    Reason: "Reason 8",
    Postcode: "Postcode 8",
    Name: "Casey Davis",
    Code: "Code 8",
    Flag_PestControl: "Flag_PestControl 8",
    Type: "Type 8",
    CurrentFee: 6518,
    FutureFee: 57383,
    FutureFeeDate: "2021-06-05T17:39:48.660Z",
    Group: "Group 8",
    SortIndex: "SortIndex 8",
    Title: "Fresh withdrawal Intelligent Wooden Shirt",
    Status: "Active",
    Condition: "Condition 8",
  },
];
