const mockListPropertyAssociations = {
  "Assessment": [
    {
      "Association": "Owner",
      "BalanceOwing": "546.13",
      "AssessmentNumber": "678537",
      "AssessmentReference": "7498247",
      "PropertyAddress": "18 Agnes St, Archies Creek M Terzoglou",
      "Ratepayer": "m Terzoglou",
      "LegalDescription": "LOT 4 DP 166829",
      "Balance": "546.13",
      "BalanceOutstanding": "546.13",
      "BeingPostponed": "No",
      "Group": "Standard",
      "ValuationGroup": "Hunters Hi",
      "Type": "Common Property",
      "Status": "Active",
      "LandUser": "Residential",
      "AssessmentId": "526"
    }
  ],
  "Parcel": [
    {
      "Association": "Owner",
      "ParcelReference": "248",
      "PropertyAddress": "18 Agnes St Archies Creek",
      "Locality": "Archies Creek",
      "LegalDescription": "Lot 4 DP166829",
      "CensusDistrict": "1381501",
      "ElectoralDivision": "North",
      "StateCodeElectoralDivision": "Lane Cove",
      "FederalElectoralDivision": "North Sydney",
      "LandArea": "575,4 m2",
      "Zones": "R2 Low Density Residential",
      "LandUses": "House",
      "OwnersName": "Mr A S & Mrs M Zantis",
      "Status": "Active",
      "ParcelId": "1,634"
    }
  ],
  "Title": [
    {
      "Association": "Owner",
      "ReferenceNumber": "678537",
      "LegalDescription": "Lot 4 DP166829",
      "Type": "Lot",
      "Lot": "4",
      "PlanType": "Deposited Plan",
      "PlanNumber": "166829",
      "Volume": "10501",
      "Folio": "730",
      "County": "Cumberland",
      "Parish": "Hunter's Hill",
      "Status": "Active",
      "LandArea": "731.6 m2",
      "IsAreaSurveyed": "No",
      "Coverage": "Base Parcel",
      "Owners": "B D R & R C Ross",
      "Address": "11 McShane St",
      "Locality": "Nar Nar Goon",
      "TitleId": "6232"
    }
  ]
}
export default mockListPropertyAssociations;
