import { ApplicationCategoryFee } from "@app/products/town-planning/ppr/[id]/model";
import {
  getCalculatedFeeType,
  getFeeTypes,
  getFeeTypesById,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/api";
import { IFeeTypeLOVs } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import {
  getFeeDefaultAmount,
  getFeeNextAmount,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  requiredValidator,
  validateRequired,
} from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddFeeTypesDialogProps {
  onClose: () => void;
  onSubmit: (value: any) => void;
  initialData: ApplicationCategoryFee[];
}

export const AddFeeTypesDialog = ({
  onClose,
  onSubmit,
  initialData,
}: IAddFeeTypesDialogProps) => {
  const [feeTypeLOVs, setFeeTypeLOVs] = useState<IFeeTypeLOVs | undefined>();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const loadFeeTypeLOVs = async () => {
    setIsLoading(true);
    const [feeTypes, calculatedFeeType] = await Promise.all([
      getFeeTypes(),
      getCalculatedFeeType(),
    ]);
    if (isSuccessResponse(feeTypes) && isSuccessResponse(calculatedFeeType)) {
      if (feeTypes.data && calculatedFeeType.data)
        setFeeTypeLOVs({
          FeeTypes: feeTypes.data,
          CalculatedFee: calculatedFeeType.data,
        });
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    loadFeeTypeLOVs();
  });

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    if (
      initialData.some(
        (feeType: ApplicationCategoryFee) =>
          feeType.ApplicationCategoryFeeType_ID === event.values.FeeType.ID
      )
    ) {
      notificationRef.current?.pushNotification({
        title: `Fee type"${event.values.FeeType.Name}" already exists, please choose another fee type`,
        type: "error",
        autoClose: false,
      });
      return;
    }
    onSubmit(event.values);
  };
  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={{
        InheritFee: false,
      }}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onChange } = formRenderProps;
        const inheritFee = valueGetter("InheritFee");
        const feeType = valueGetter("FeeType");
        const handleInheritFeeChange = async (event: SwitchChangeEvent) => {
          onChange("InheritFee", { value: event.value });
          if (event.value) {
            const response = await getFeeTypesById(feeType?.ID);
            if (isSuccessResponse(response) && response.data) {
              const NextAmount = getFeeNextAmount(response.data);
              const DefaultAmount = getFeeDefaultAmount(response.data);
              if (NextAmount && new Date(NextAmount) <= new Date()) {
                if (NextAmount && NextAmount > 0)
                  onChange("FeeAmount", { value: NextAmount });
              } else {
                if (DefaultAmount && DefaultAmount > 0)
                  onChange("FeeAmount", { value: DefaultAmount });
              }
            }
          }
        };
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="25%"
              titleHeader={"Add Fees Type"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => loadFeeTypeLOVs()}
                    />
                  ) : (
                    <>
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-field-group">
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              Fee type
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={"FeeType"}
                              component={CCSearchComboBox}
                              data={feeTypeLOVs?.FeeTypes || []}
                              textField={"Name"}
                              dataItemKey={"ID"}
                              validator={validateRequired}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Calculated fee type
                            </label>
                            <Field
                              name={"CalculatedFeeType"}
                              component={CCSearchComboBox}
                              data={feeTypeLOVs?.CalculatedFee || []}
                              textField={"Name"}
                              dataItemKey={"ID"}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Inherit fee</label>
                            <Field
                              name={"InheritFee"}
                              component={CCSwitch}
                              checked={inheritFee}
                              onChange={handleInheritFeeChange}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              {valueGetter("CalculatedFeeType")
                                ? "Calculated"
                                : "Amount"}
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={"FeeAmount"}
                              component={CCCurrencyInput}
                              validator={requiredValidator}
                              disabled={inheritFee}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    disabled={!formRenderProps.valid}
                    onClick={formRenderProps.onSubmit}
                  >
                    Add Fee Type
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
