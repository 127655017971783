import { ICCRoute } from "@common/constants/ICCRoute";

export const userRoute: ICCRoute = {
  path: "user",
  name: "User",
  children: [
    {
      path: "change-password",
      name: "Change Password",
      component: require("./change-password/_index").default,
    },
  ],
};
