import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IntegrationSettingEnums } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/constant";
import {
  AUSPOSTFORMAT,
  EXPORT_FORMAT_VALUE,
  FINANCE_API_VALUE,
  FINANCE_METHOD_VALUE,
  FINANCE_SYSTEM_VALUE,
  IMPORT_METHOD_VALUE,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/model";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty } from "lodash";

export const ShowFinanceExportCustomFields = (
  OnlyStdNCus: boolean,
  exportFormatValue: string
) => {
  IntegrationSettingEnums.divFinanceExportFormat.isVisible = true;

  //Added custom formatting for the path fee export 28/07/2017 supported only Standard/Custom
  if (exportFormatValue === EXPORT_FORMAT_VALUE.Format1) {
    // Format1.Enabled = !OnlyStdNCus;
  }
  if (exportFormatValue === EXPORT_FORMAT_VALUE.Format2) {
    // Format2.Enabled = !OnlyStdNCus;
  }

  switch (exportFormatValue) {
    case EXPORT_FORMAT_VALUE.Custom:
      IntegrationSettingEnums.divCustomeFeeFieldsforExport.isVisible = true;
      IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = false;
      break;
  }
};

export const logic = (formRenderProps: FormRenderProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const listEnumDisable = [];
  const listIsRequired = [];

  const financeMethodValue = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceMethod.toString()
  );
  const financeSystemValue = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceSystem.toString()
  );
  // const enableUpToGlassFinanceValue = valueGetter(
  //   ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance.toString()
  // );
  const directExportValue = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceDirectExport.toString()
  );
  const exportFormatValue = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceExportFormat.toString()
  );
  const financeApiValue = valueGetter(
    ECorporateSettingsField.CorporateSettings_U2GFinanceAPISystem.toString()
  );
  const importMethodValue = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceImportMethod.toString()
  );

  const rblCreateDebtorNumber = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber.toString()
  );
  const rblExportDebtors = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceExportDebtors.toString()
  );
  const rblEnableDebtorUserSelection = valueGetter(
    ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection.toString()
  );
  const rblCreateInvoiceNumber = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceAllocateInvoiceNumber.toString()
  );
  const rblCreateReceiptNumber = valueGetter(
    ECorporateSettingsField.CorporateSettings_FinanceAllocateReceiptNumber.toString()
  );
  const ausPost = valueGetter(
    ECorporateSettingsField.CorporateSettings_AusPostFormat.toString()
  );
  const rblUpdateFinanceStaging = valueGetter(
    ECorporateSettingsField.CorporateSettings_UpdateFinanceStaging.toString()
  );
  const rblLookUpBondType = valueGetter(
    ECorporateSettingsField.CorporateSettings_BondLookup.toString()
  );
  const rblEnableUpToGlassFinance = valueGetter(
    ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance.toString()
  );
  const ddlFinanceAPISystem = valueGetter(
    ECorporateSettingsField.CorporateSettings_U2GFinanceAPISystem.toString()
  );
  const rblEnableSubsidy = valueGetter(
    ECorporateSettingsField.CorporateSettings_Enable_Subsidies.toString()
  );
  const txtSubsidiesLabel = valueGetter(
    ECorporateSettingsField.CorporateSettings_Subsidies_Label.toString()
  );
  IntegrationSettingEnums.divSAPFinanceSystemSettings.isVisible = false;
  IntegrationSettingEnums.divFinanceConnectionMethod.isVisible = false;
  IntegrationSettingEnums.divFinanceImportMethod.isVisible = false;
  IntegrationSettingEnums.divFinanceImportSQL.isVisible = false;
  IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = false;
  IntegrationSettingEnums.divFinanceExportFormat.isVisible = false;
  IntegrationSettingEnums.divFinanceConnectionString.isVisible = false;
  IntegrationSettingEnums.divFinanceExportFolder.isVisible = false;
  IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = false;
  IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = false;
  IntegrationSettingEnums.divFinanceBatchNo.isVisible = false;
  IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = false;
  IntegrationSettingEnums.divCustomQuery.isVisible = false;
  IntegrationSettingEnums.divReceiptLastNumber.isVisible = false;
  IntegrationSettingEnums.divReceiptNumberLength.isVisible = false;
  IntegrationSettingEnums.divReceiptNumberPrefix.isVisible = false;
  IntegrationSettingEnums.divReceiptNumberSuffix.isVisible = false;
  IntegrationSettingEnums.divImportFolder.isVisible =
    IntegrationSettingEnums.divImportFileFormat.isVisible = false;
  IntegrationSettingEnums.divCashlinkReceipting.isVisible = false;

  IntegrationSettingEnums.divProcessedImportFileFolder.isVisible = false;
  IntegrationSettingEnums.divImportFileName.isVisible = false;
  IntegrationSettingEnums.divBalanceSheetFileName.isVisible = false;

  IntegrationSettingEnums.dvQuote.isVisible = false;
  IntegrationSettingEnums.divFinanceDirectExport.isVisible = false;
  IntegrationSettingEnums.divFinanceDirectExportSQL.isVisible = false;
  IntegrationSettingEnums.divFinanceDirectExportConnectionString.isVisible =
    false;
  IntegrationSettingEnums.divFinanceAutoReceiptFees.isVisible =
    financeSystemValue !== FINANCE_SYSTEM_VALUE.None;
  IntegrationSettingEnums.dvF1TransactionType.isVisible = false;
  IntegrationSettingEnums.divUpdateFinance.isVisible = false;
  IntegrationSettingEnums.divCustomeFeeFieldsforExport.isVisible = false;
  IntegrationSettingEnums.divCustomeFeeFieldsforExport_Oracle.isVisible = false;
  IntegrationSettingEnums.divIDPrefix.isVisible = false;

  IntegrationSettingEnums.divLastImportedDate.isVisible = false; //for Localized payment import
  IntegrationSettingEnums.divUpToGlassFinanceSettings.isVisible = false; //UpToGlassSettings
  IntegrationSettingEnums.divCommunityFinanceAPISettings.isVisible = false; //UpToGlassSettings
  IntegrationSettingEnums.divFinanceAPISettings.isVisible = false; //UpToGlassSettings
  IntegrationSettingEnums.divUseCommunityFinanceAPI.isVisible = false;
  IntegrationSettingEnums.divPreFinanceSummaryPaymentInformationDetails.isVisible =
    false;
  IntegrationSettingEnums.divBondLookUp.isVisible = false;
  IntegrationSettingEnums.divFinanceCollectNewExport.isVisible = false;

  IntegrationSettingEnums.divOracleAdditionalSettings.isVisible = false;

  //The seeds are read-only at the moment, we need a function to write back to the Client Sequence generator table
  // txtLastDebtorNumber.RenderMode = ControlRenderMode.Read;
  // txtLastInvoiceNumber.RenderMode = ControlRenderMode.Read;
  //TODO
  // txtLastQuoteNumber.RenderMode = ControlRenderMode.Read;
  // txtLastReceiptNumber.RenderMode = ControlRenderMode.Read;
  IntegrationSettingEnums.divDellBoomiAccountBalanceSettings.isVisible = false;
  IntegrationSettingEnums.divTechOneExportFields.isVisible = false;
  IntegrationSettingEnums.divCheckDebtorNumber.isVisible = false;
  IntegrationSettingEnums.divSubsidySettings.isVisible = false;

  if (financeMethodValue === FINANCE_METHOD_VALUE.Direct) {
    IntegrationSettingEnums.divEnableUpToGlassFinance.isVisible = true;
  } else {
    IntegrationSettingEnums.divEnableUpToGlassFinance.isVisible = false;
  }

  switch (financeSystemValue) {
    case FINANCE_SYSTEM_VALUE["Community Finance"]:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;

      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;

      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceDirectExport.isVisible = true;

      //#38207
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true; // as per justin for moonevally am fixing this

      //Export method
      if (directExportValue) {
        IntegrationSettingEnums.divFinanceExportFormat.isVisible = false;
        if (financeApiValue === FINANCE_API_VALUE.None) {
          IntegrationSettingEnums.divFinanceDirectExportSQL.isVisible = true;
          IntegrationSettingEnums.divFinanceDirectExportConnectionString.isVisible =
            true;
          IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
        } else {
          IntegrationSettingEnums.divFinanceAutoReceiptFees.isVisible = false;
          IntegrationSettingEnums.divFinanceConnectionString.isVisible = false;
        }
      } else {
        IntegrationSettingEnums.divFinanceExportFormat.isVisible = true;

        // exportFormatValue = EXPORT_FORMAT_VALUE.Custom;
        if (exportFormatValue !== EXPORT_FORMAT_VALUE.Custom) {
          onChange(
            ECorporateSettingsField.CorporateSettings_FinanceExportFormat.toString(),
            { value: EXPORT_FORMAT_VALUE.Custom }
          );
        }
        // ddlFInanceExportFormat.Enabled = false;
        listEnumDisable.push(
          ECorporateSettingsField.CorporateSettings_FinanceExportFormat
        );

        ShowFinanceExportCustomFields(true, exportFormatValue);
      }
      //Import method
      //DD: Import method initially implemented for Cashlink and import file was spot selection
      //CommunityFinance using the 'File' IMPORT METHOD and expected to have Import Folder (UNC Path) selected
      // ENUMS.IMPORTMETHOD importMethod = (ENUMS.IMPORTMETHOD)Int32.Parse(ddlFinanceImportMethod.ChildComboBox.SelectedValue);
      if (importMethodValue === IMPORT_METHOD_VALUE.File) {
        IntegrationSettingEnums.divImportFolder.isVisible = true;
        IntegrationSettingEnums.divImportFileFormat.isVisible = true;
      }
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE.TechnologyOne:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;

      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;

      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceDirectExport.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;

      ////#38207
      //IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;// as per justin for moonevally am fixing this

      //Export method
      if (directExportValue) {
        IntegrationSettingEnums.divFinanceExportFormat.isVisible = false;
        if (financeApiValue === FINANCE_API_VALUE.None) {
          IntegrationSettingEnums.divFinanceDirectExportSQL.isVisible = true;
          IntegrationSettingEnums.divFinanceDirectExportConnectionString.isVisible =
            true;
          IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
        } else {
          IntegrationSettingEnums.divFinanceAutoReceiptFees.isVisible = false;
          IntegrationSettingEnums.divFinanceConnectionString.isVisible = false;
        }
      } else {
        IntegrationSettingEnums.divFinanceExportFormat.isVisible = false;
        // exportFormatValue = EXPORT_FORMAT_VALUE.Standard
        if (exportFormatValue !== EXPORT_FORMAT_VALUE.Standard) {
          onChange(
            ECorporateSettingsField.CorporateSettings_FinanceExportFormat.toString(),
            { value: EXPORT_FORMAT_VALUE.Standard }
          );
        }

        // ddlFInanceExportFormat.Enabled = false;
        listEnumDisable.push(
          ECorporateSettingsField.CorporateSettings_FinanceExportFormat
        );
        //ShowFinanceExportCustomFields(true);
      }
      //Import method
      //DD: Import method initially implemented for Cashlink and import file was spot selection
      //CommunityFinance using the 'File' IMPORT METHOD and expected to have Import Folder (UNC Path) selected
      // ENUMS.IMPORTMETHOD importMethod1 = (ENUMS.IMPORTMETHOD)Int32.Parse(ddlFinanceImportMethod.ChildComboBox.SelectedValue);
      if (importMethodValue === IMPORT_METHOD_VALUE.File) {
        IntegrationSettingEnums.divImportFolder.isVisible = true;
        IntegrationSettingEnums.divImportFileFormat.isVisible = true;
        IntegrationSettingEnums.divProcessedImportFileFolder.isVisible = true;
        IntegrationSettingEnums.divImportFileName.isVisible = true;
        IntegrationSettingEnums.divBalanceSheetFileName.isVisible = true;
      }
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE.Greentree:
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.dvQuote.isVisible = true;

      break;
    case FINANCE_SYSTEM_VALUE.FinanceOne:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = true;
      IntegrationSettingEnums.dvF1TransactionType.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      IntegrationSettingEnums.divIDPrefix.isVisible = true;
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      ShowFinanceExportCustomFields(true, exportFormatValue);
      break;
    case FINANCE_SYSTEM_VALUE["FinanceOne - CRS"]:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = true;
      IntegrationSettingEnums.dvF1TransactionType.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE.FinanceOnePathway:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.dvF1TransactionType.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;

      break;
    case FINANCE_SYSTEM_VALUE.ComputronPathway:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.dvF1TransactionType.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE.Authority:
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      ShowFinanceExportCustomFields(false, exportFormatValue);
      IntegrationSettingEnums.divCustomQuery.isVisible = true;

      IntegrationSettingEnums.divFinanceDirectExport.isVisible = true;
      if (directExportValue) {
        IntegrationSettingEnums.divFinanceDirectExportSQL.isVisible = true;
        IntegrationSettingEnums.divFinanceDirectExportConnectionString.isVisible =
          true;
      }
      break;
    case FINANCE_SYSTEM_VALUE.Proclaim:
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      //             IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      IntegrationSettingEnums.divCustomQuery.isVisible = true;

      break;
    case FINANCE_SYSTEM_VALUE.Pathway:
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = true;
      ShowFinanceExportCustomFields(true, exportFormatValue);

      break;
    case FINANCE_SYSTEM_VALUE.Collect:
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divFinanceCollectNewExport.isVisible = true;
      //IntegrationSettingEnums.divFinanceImportSQL.isVisible = true;/
      break;
    case FINANCE_SYSTEM_VALUE.MYOB:
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE.Computron:
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      //    IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.dvF1TransactionType.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE["Computron Debtors"]:
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.dvF1TransactionType.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      IntegrationSettingEnums.divFinanceImportSQL.isVisible = true;
      IntegrationSettingEnums.divCustomQuery.isVisible = true;
      IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = true;
      break;
    case FINANCE_SYSTEM_VALUE.CashLink:
      IntegrationSettingEnums.divFinanceImportMethod.isVisible = true;

      switch (importMethodValue) {
        case IMPORT_METHOD_VALUE.File:
          break;
        case IMPORT_METHOD_VALUE.Database:
          IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
          IntegrationSettingEnums.divFinanceImportSQL.isVisible = true;
          IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
          break;
      }

      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divCashlinkReceipting.isVisible = true;

      break;
    case FINANCE_SYSTEM_VALUE["Synergy Soft"]:
      IntegrationSettingEnums.divFinanceConnectionString.isVisible = true;
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divFinanceBatchNo.isVisible = true;
      IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = true;
      ShowFinanceExportCustomFields(false, exportFormatValue);
      IntegrationSettingEnums.divCustomQuery.isVisible = true;

      // exportFormatValue = EXPORT_FORMAT_VALUE.Custom;
      if (exportFormatValue !== EXPORT_FORMAT_VALUE.Custom) {
        onChange(
          ECorporateSettingsField.CorporateSettings_FinanceExportFormat.toString(),
          { value: EXPORT_FORMAT_VALUE.Custom }
        );
      }

      // ddlFInanceExportFormat.Enabled = false;
      listEnumDisable.push(
        ECorporateSettingsField.CorporateSettings_FinanceExportFormat
      );

      IntegrationSettingEnums.divCustomeFeeFieldsforExport.isVisible = true;
      IntegrationSettingEnums.divFinanceTransactionTypes.isVisible = false;

      IntegrationSettingEnums.divFinanceDirectExport.isVisible = true;
      if (directExportValue) {
        IntegrationSettingEnums.divFinanceDirectExportSQL.isVisible = true;
        IntegrationSettingEnums.divFinanceDirectExportConnectionString.isVisible =
          true;
      }
      break;
    case FINANCE_SYSTEM_VALUE["TechOne CIAnyWhere"]:
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divTechOneExportFields.isVisible = true;

      break;
    case FINANCE_SYSTEM_VALUE.Oracle:
      IntegrationSettingEnums.divFinanceExportFolder.isVisible = true;
      IntegrationSettingEnums.divFinanceExportEmailAddress.isVisible = true;
      IntegrationSettingEnums.divCustomeFeeFieldsforExport.isVisible = true;
      IntegrationSettingEnums.divCustomeFeeFieldsforExport_Oracle.isVisible =
        true;
      //IntegrationSettingEnums.divTechOneExportFields.isVisible = true;

      break;
    case FINANCE_SYSTEM_VALUE.SAP:
      IntegrationSettingEnums.divSAPFinanceSystemSettings.isVisible = true;
      break;
    default:
      break;
  }

  IntegrationSettingEnums.divDebtorNumberLastNumber.isVisible = false;
  IntegrationSettingEnums.divDebtorNumberLength.isVisible = false;
  if (rblCreateDebtorNumber) {
    IntegrationSettingEnums.divDebtorNumberLastNumber.isVisible = true;
    IntegrationSettingEnums.divDebtorNumberLength.isVisible = true;
  } else {
    IntegrationSettingEnums.divCheckDebtorNumber.isVisible = true;
  }

  IntegrationSettingEnums.divExportDebtorFolder.isVisible = false;
  IntegrationSettingEnums.divExportDebtorFormat.isVisible = false;
  IntegrationSettingEnums.divOnlyExportDebtorsWithFees.isVisible = false;
  IntegrationSettingEnums.divExportDebtorFieldSeparator.isVisible = false;
  if (rblExportDebtors) {
    IntegrationSettingEnums.divExportDebtorFolder.isVisible = true;
    IntegrationSettingEnums.divExportDebtorFormat.isVisible = true;
    IntegrationSettingEnums.divOnlyExportDebtorsWithFees.isVisible = true;
    IntegrationSettingEnums.divExportDebtorFieldSeparator.isVisible = true;
  }

  IntegrationSettingEnums.divDebtorUserSelection.isVisible = false;
  if (rblEnableDebtorUserSelection) {
    IntegrationSettingEnums.divDebtorUserSelection.isVisible = true;
  }

  IntegrationSettingEnums.divInvoiceLastNumber.isVisible = false;
  IntegrationSettingEnums.divInvoiceNumberLength.isVisible = false;
  IntegrationSettingEnums.divInvoiceNumberPrefix.isVisible = false;
  IntegrationSettingEnums.divInvoiceNumberSuffix.isVisible = false;
  if (rblCreateInvoiceNumber) {
    IntegrationSettingEnums.divInvoiceLastNumber.isVisible = true;
    IntegrationSettingEnums.divInvoiceNumberLength.isVisible = true;
    IntegrationSettingEnums.divInvoiceNumberPrefix.isVisible = true;
    IntegrationSettingEnums.divInvoiceNumberSuffix.isVisible = true;
  }

  IntegrationSettingEnums.divReceiptLastNumber.isVisible = false;
  IntegrationSettingEnums.divReceiptNumberLength.isVisible = false;
  IntegrationSettingEnums.divReceiptNumberPrefix.isVisible = false;
  IntegrationSettingEnums.divReceiptNumberSuffix.isVisible = false;

  if (rblCreateReceiptNumber) {
    IntegrationSettingEnums.divReceiptLastNumber.isVisible = true;
    IntegrationSettingEnums.divReceiptNumberLength.isVisible = true;
    IntegrationSettingEnums.divReceiptNumberPrefix.isVisible = true;
    IntegrationSettingEnums.divReceiptNumberSuffix.isVisible = true;
  }

  IntegrationSettingEnums.trKeyLineCode.isVisible = true;
  IntegrationSettingEnums.trKeyLineTID.isVisible = true;

  // AUSPOSTFORMAT ausPost = (AUSPOSTFORMAT)Convert.ToInt32(ddlAusPostMethod.SelectedValue);

  //if ((AUSPOSTFORMAT)Convert.ToInt32(ddlAusPostMethod.SelectedValue) === AUSPOSTFORMAT.Stonnington)
  if (
    ausPost === AUSPOSTFORMAT.Stonnington ||
    ausPost === AUSPOSTFORMAT.Hobart ||
    ausPost === AUSPOSTFORMAT.Darebin ||
    ausPost === AUSPOSTFORMAT.ShireofMurray ||
    ausPost === AUSPOSTFORMAT.MOD09v02
  ) {
    IntegrationSettingEnums.trKeyLineCode.isVisible = false;
    IntegrationSettingEnums.trKeyLineTID.isVisible = false;
  }

  if (rblUpdateFinanceStaging) {
    IntegrationSettingEnums.divUpdateFinance.isVisible = true;
    // txtUpdateFinanceStagingConnection.IsRequired = true;
    // txtUpdateFinanceStagingQuery.IsRequired = true;
    listIsRequired.push(
      ECorporateSettingsField.CorporateSettings_FinanceStagingConnectionString,
      ECorporateSettingsField.CorporateSettings_UpdateFinanceStagingQuerySQL
    );
  }

  if (rblLookUpBondType) {
    IntegrationSettingEnums.divBondLookUp.isVisible = true;
    // txtBondConnectionString.IsRequired = true;
    // txtBondQuery.IsRequired = true;
    listIsRequired.push(
      ECorporateSettingsField.CorporateSettings_BondConnectionString,
      ECorporateSettingsField.CorporateSettings_BondLookUpQuery
    );
  }

  //Making if FinanceMethod is Localized then show customQuery always. and last import date always - mofified for CRS Payment import Localized Feb 14 2018
  if (financeMethodValue === FINANCE_METHOD_VALUE.Localized) {
    IntegrationSettingEnums.divCustomQuery.isVisible = true;
    IntegrationSettingEnums.divLastImportedDate.isVisible = true;
  }

  if (
    financeMethodValue === FINANCE_METHOD_VALUE.Direct &&
    rblEnableUpToGlassFinance
  ) {
    IntegrationSettingEnums.divCustomQuery.isVisible = false;
    IntegrationSettingEnums.divFinanceReferenceMethod.isVisible = false;
    IntegrationSettingEnums.divFinanceImportMethod.isVisible = false;
    IntegrationSettingEnums.divFinanceImportSQL.isVisible = false;
    IntegrationSettingEnums.divPreFinanceSummaryPaymentInformationDetails.isVisible =
      true;

    IntegrationSettingEnums.divFinanceImportEmailAddress.isVisible = true;

    if (
      financeSystemValue === FINANCE_SYSTEM_VALUE["Community Finance"] ||
      financeSystemValue === FINANCE_SYSTEM_VALUE["TechOne CIAnyWhere"] ||
      financeSystemValue === FINANCE_SYSTEM_VALUE.Oracle
    ) {
      IntegrationSettingEnums.divUseCommunityFinanceAPI.isVisible = true;

      if (
        ddlFinanceAPISystem !== "" &&
        ddlFinanceAPISystem !== "_" &&
        financeApiValue !== FINANCE_API_VALUE.None
      ) {
        switch (financeApiValue) {
          case FINANCE_API_VALUE["Community Finance"]:
            IntegrationSettingEnums.divFinanceAPISettings.isVisible = true;
            IntegrationSettingEnums.divCommunityFinanceAPISettings.isVisible =
              true;
            break;
          case FINANCE_API_VALUE["Port Phillip Dell Boomi"]:
            IntegrationSettingEnums.divFinanceAPISettings.isVisible = true;
            IntegrationSettingEnums.divDellBoomiAccountBalanceSettings.isVisible =
              true;
            break;
          case FINANCE_API_VALUE.Oracle:
            IntegrationSettingEnums.divFinanceAPISettings.isVisible = true;
            IntegrationSettingEnums.divOracleAdditionalSettings.isVisible =
              true;
            //IntegrationSettingEnums.divDellBoomiAccountBalanceSettings.isVisible = true;
            break;
        }
      } else {
        IntegrationSettingEnums.divUpToGlassFinanceSettings.isVisible = true;
      }
    } else {
      IntegrationSettingEnums.divUpToGlassFinanceSettings.isVisible = true;
    }
  }

  if (rblEnableSubsidy) {
    IntegrationSettingEnums.divSubsidySettings.isVisible = true;
  } else {
    // txtSubsidiesLabel.Text = string.Empty;
    if (!isEmpty(txtSubsidiesLabel)) {
      onChange(
        ECorporateSettingsField.CorporateSettings_Subsidies_Label.toString(),
        { value: "" }
      );
    }
  }
  return {
    IntegrationSettingEnums,
    listEnumDisable,
    listIsRequired,
  };
};
