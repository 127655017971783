import { IDataPick } from "@common/pages/settings/system-admin/task-scheduling/components/pick-day-of-week/_index";
import {
  DayOfWeekType,
  ScheduleJobType,
  eForADuration,
  eRepeatJob,
} from "@common/pages/settings/system-admin/task-scheduling/model";

export const daysOfWeekData: IDataPick[] = [
  { value: DayOfWeekType.SUN, label: "SUN" },
  { value: DayOfWeekType.MON, label: "MON" },
  { value: DayOfWeekType.TUE, label: "TUE" },
  { value: DayOfWeekType.WED, label: "WED" },
  { value: DayOfWeekType.THU, label: "THU" },
  { value: DayOfWeekType.FRI, label: "FRI" },
  { value: DayOfWeekType.SAT, label: "SAT" },
];

export const jobTypeOptions: {
  label: string;
  value: ScheduleJobType;
}[] = [
  {
    label: "Daily",
    value: ScheduleJobType.Daily,
  },
  {
    label: "Weekly",
    value: ScheduleJobType.Weekly,
  },
];

/**
 * Data options for the "repeat time" dropdown
 */
export const repeatJobOptions: {
  Value: string;
  Key: eRepeatJob;
}[] = [
  {
    Value: "30 minutes",
    Key: eRepeatJob.ThirtyMinutes,
  },
  {
    Value: "1 hour",
    Key: eRepeatJob.OneHour,
  },
  {
    Value: "6 hours",
    Key: eRepeatJob.SixHours,
  },
  {
    Value: "12 hours",
    Key: eRepeatJob.TwelveHours,
  },
];

/**
 * Data options for the "for a duration" dropdown
 */
export const forADurationOptions: {
  Value: string;
  Key: eForADuration;
}[] = [
  {
    Value: "Indefinitely",
    Key: eForADuration.Indefinitely,
  },
  {
    Value: "30 minutes",
    Key: eForADuration.ThirtyMinutes,
  },
  {
    Value: "1 hour",
    Key: eForADuration.OneHour,
  },
  {
    Value: "6 hours",
    Key: eForADuration.SixHours,
  },
  {
    Value: "12 hours",
    Key: eForADuration.TwelveHours,
  },
  {
    Value: "18 hours",
    Key: eForADuration.EighteenHours,
  },
];
