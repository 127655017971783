import { DeleteButton } from "@app/core/delete/buttons/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { colAnimalsInspectionPlanner } from "@app/products/animals/inspection-planner/inspection-plans/config";
import {
  InspectionPlannerType,
  Svc_Animals_InspectionPlanner,
} from "@app/products/animals/inspection-planner/model";
import { getAnimalsInspectionPlanner } from "@app/products/animals/inspection-planner/util";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { inspectionPlannerRoute } from "../route";

const nameOf = nameOfFactory<Svc_Animals_InspectionPlanner>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_InspectionPlanner,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={inspectionPlannerRoute.path} />,
    ],
    centerComponents: [
      // TODO: Implement later
      // <CCNavButton title="Inspection" onClick={() => {}} />,
      <DeleteButton
        recordType={RECORDTYPE.Core_InspectionPlanner}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      // TODO: Implement when create manage page
      // <ActionBarInfoIcon />,
      // <ListViewBookmarkIcon
      //   linkUrl={ANIMALS_ROUTE}
      //   productType={PRODUCT_TYPE.Animals}
      //   recordType={RECORDTYPE.Core_InspectionPlanner}
      //   detail={InspectionPlannerBookmark.getBookmarkDetail}
      //   displayName={InspectionPlannerBookmark.getBookmarkDisplayName}
      //   listViewDetail={
      //     AnimalInspectionPlannerOverDueBookmark.getBookmarkListViewDetail
      //   }
      //   listViewDisplayName={
      //     AnimalInspectionPlannerOverDueBookmark.getBookmarkListViewDisplayName
      //   }
      // />,
    ],
  });

  // TODO: Implement when create manage page
  // useReferenceSideBar({
  //   referenceComponents: [
  //   ],
  // });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colAnimalsInspectionPlanner}
        dataUrl={getAnimalsInspectionPlanner(InspectionPlannerType.OverDue)}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
