import {
  DTO_WorkflowHeader,
  WorkflowTypes,
} from "@app/products/property/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { postUpdateChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/update/api";
import { DTO_Workflow_ChargeRunUpdate } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/update/model";
import { observer } from "mobx-react-lite";

interface IUpdateChargeRunProps {
  disabled?: boolean;
  isPicChargeRun: boolean;
}

export const UpdateChargeRun = observer(
  ({ disabled, isPicChargeRun }: IUpdateChargeRunProps) => {
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    const { reLoadChargeRuns } = useChargeRunsStore();
    const workflowHeader: DTO_WorkflowHeader = {
      WorkflowDraft: {
        Workflow_Draft_Id: 0,
        WD_Workflow_Status: 2,
        WD_Workflow_Type: 0,
      },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    };
    const processData: DTO_Workflow_ChargeRunUpdate = {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: { ChargeRunId: +id, IsPicChargeRun: isPicChargeRun },
      WorkflowTypes: WorkflowTypes.Update_ChargeRun,
    };

    const handleUpdateChargeRun = async () => {
      setIsLoading(true);
      const response = await postUpdateChargeRun(processData);
      setIsLoading(false);
      setIsShowConfirmDialog(false);
      if (response?.data?.IsSuccess) {
        reLoadChargeRuns().then(() => {
          pushNotification({
            type: "success",
            title:
              "Charge Run update successfully sent to the job queue. You will be notified once the job has been completed.",
          });
        });
      } else {
        pushNotification({
          type: "error",
          title:
            response?.data?.ErrorMessage ??
            "Update charge run could not be completed",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Update"
          onClick={() => setIsShowConfirmDialog(true)}
          disabled={disabled}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            message={`Do you wish to update Charge Run ${id}`}
            maxWidth={"40%"}
            subMessage={
              <p>
                After the charge run update, applicable levies (if any) are
                deferred and credits re-allocated.
                <br />
                NB certain data once updated cannot be rolled back (eg
                Assess_Pay_In_Full_Flag)
              </p>
            }
            onClosePopup={() => setIsShowConfirmDialog(false)}
            isLoadingYes={isLoading}
            onAsyncConfirm={() => {
              return handleUpdateChargeRun();
            }}
          />
        )}
      </>
    );
  }
);
