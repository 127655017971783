export const mockAttributes = [
  {
    Attribute: "1996 Land Value",
    Value: "331000",
    ID: "1",
  },
  {
    Attribute: "96 Reval Prop Num",
    Value: "400000000010",
    ID: "2",
  },
  {
    Attribute: "96 Revaluation Date",
    Value: "10796",
    ID: "3",
  },
  {
    Attribute: "Area(SQM)",
    Value: "866.3000",
    ID: "4",
  },
  {
    Attribute: "CCeS_PCC_DoclD",
    ID: "5",
  },
  {
    Attribute: "Classification",
    ID: "6",
  },
  {
    Attribute: "Clause 12 applies",
    ID: "7",
  },
  {
    Attribute: "Clause 18",
    ID: "8",
  },
  {
    Attribute: "Conservation Item",
    Value: "NO",
    ID: "9",
  },
  {
    Attribute: "Contaminated a",
    ID: "10",
  },
  {
    Attribute: "Contaminated b",
    ID: "11",
  },
  {
    Attribute: "Contaminated c",
    ID: "12",
  },
  {
    Attribute: "Contaminated d",
    ID: "13",
  },
  {
    Attribute: "Contributory Item",
    Value: "NO",
    ID: "14",
  },
  {
    Attribute: "DCP10 applies",
    ID: "15",
  },
  {
    Attribute: "DCP11 applies",
    ID: "16",
  },
  {
    Attribute: "DCP14 applies",
    ID: "17",
  },
  {
    Attribute: "DCP15 applies",
    ID: "18",
  },
  {
    Attribute: "DCP16 applies",
    ID: "19",
  },
  {
    Attribute: "DCP17 applies",
    ID: "20",
  },
  {
    Attribute: "dcp 21 applies",
    ID: "21",
  },
  {
    Attribute: "DCP number",
    Value: "DCP Numbers 12/15",
    ID: "22",
  },
  {
    Attribute: "dimensions",
    Value: "16.72X51.79/51.82",
    ID: "23",
  },
  {
    Attribute: "Foreshore BL",
    ID: "24",
  },
  {
    Attribute: "FSPA",
    Value: "YES",
    ID: "25",
  },
  {
    Attribute: "GarbageZone 2015",
    Value: "Wednesday Zone A",
    ID: "26",
  },
  {
    Attribute: "Has Draft LEP",
    ID: "27",
  },
  {
    Attribute: "Heritage item",
    Value: "Not Heritage",
    ID: "28",
  },
  {
    Attribute: "Land for acquiition",
    ID: "29",
  },
  {
    Attribute: "NewYearsEvePass",
    Value: "0",
    ID: "30",
  },
  {
    Attribute: "NYEPass 2007",
    ID: "31",
  },
  {
    Attribute: "NYEPass 2008",
    ID: "32",
  },
  {
    Attribute: "NYEPass 2009",
    ID: "33",
  },
  {
    Attribute: "NYEPass 2010",
    ID: "34",
  },
  {
    Attribute: "NYEPass 2011",
    ID: "35",
  },
  {
    Attribute: "NYEPass 2012",
    ID: "36",
  },
  {
    Attribute: "NYEPass 2013",
    ID: "37",
  },
  {
    Attribute: "NYEPass 2014",
    ID: "38",
  },
  {
    Attribute: "NYEPass 2015",
    ID: "39",
  },
  {
    Attribute: "NYEPass 2016",
    ID: "40",
  },
  {
    Attribute: "NYEPass 2017",
    ID: "41",
  },
  {
    Attribute: "Owner Name 1",
    Value: "Mr Crockford Duncan Rob",
    ID: "42",
  },
  {
    Attribute: "Owner Name 2",
    Value: "Mrs Crockford Beryl Edwir",
    ID: "43",
  },
  {
    Attribute: "Owner Name 3",
    ID: "44",
  },
  {
    Attribute: "Owner Name 4",
    ID: "45",
  },
  {
    Attribute: "Owner Name 5",
    ID: "46",
  },
  {
    Attribute: "Owner Name 6",
    ID: "47",
  },
  {
    Attribute: "Parking comm",
    ID: "48",
  },
  {
    Attribute: "Postponed code",
    ID: "49",
  },
  {
    Attribute: "Property File Numbe",
    Value: "1010/36",
    ID: "50",
  },
  {
    Attribute: "1996 Land Value",
    Value: "331000",
    ID: "51",
  },
  {
    Attribute: "96 Reval Prop Num",
    Value: "400000000010",
    ID: "52",
  },
  {
    Attribute: "96 Revaluation Date",
    Value: "10796",
    ID: "53",
  },
  {
    Attribute: "Area(SQM)",
    Value: "866.3000",
    ID: "54",
  },
  {
    Attribute: "Conservation Item",
    Value: "NO",
    ID: "55",
  },
  {
    Attribute: "Contributory Item",
    Value: "NO",
    ID: "56",
  },
  {
    Attribute: "DCP number",
    Value: "DCP Numbers 12/15",
    ID: "57",
  },
  {
    Attribute: "dimensions",
    Value: "16.72X51.79/51.82",
    ID: "58",
  },
  {
    Attribute: "FSPA",
    Value: "YES",
    ID: "59",
  },
  {
    Attribute: "GarbageZone_2015",
    Value: "Wednesday Zone A",
    ID: "60",
  },
  {
    Attribute: "Heritage item",
    Value: "Not Heritage",
    ID: "61",
  },
  {
    Attribute: "NewYearsEvePass_",
    Value: "0",
    ID: "62",
  },
  {
    Attribute: "Owner Name 1",
    Value: "Mr Crockford Duncan Rob",
    ID: "63",
  },
  {
    Attribute: "Owner Name 2",
    Value: "Mrs Crockford Beryl Edwir",
    ID: "64",
  },
  {
    Attribute: "Property File Numbe",
    Value: "1010/36",
    ID: "65",
  },
  {
    Attribute: "Riding/Ward",
    Value: "North",
    ID: "66",
  },
  {
    Attribute: "SREP2005 item",
    Value: "NO",
    ID: "67",
  },
];
