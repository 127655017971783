import { HMFieldMapping } from "@app/products/hm/model";
export const colHMSystemAdminClassifications = [
  {
    field: HMFieldMapping.Name,
    title: "Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_FoodPremises,
    title: "Food",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_TobaccoPremises,
    title: "Tobacco",
    width: 150,
  },
  {
    field: HMFieldMapping.Is_DrinkingWater,
    title: "Drinking Water",
    width: 150,
  },
  {
    field: HMFieldMapping.Description,
    title: "Description",
    width: 150,
  },
];
