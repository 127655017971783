import { IAssessmentMeters } from "@app/products/property/assessments/[id]/components/child-screens/meters/model";

export const mockMeters: IAssessmentMeters[] = [
  {
    Utility: "Water Usage",
    MeterNumber: "00A105659",
    ReadingRoute: "Cannonvale 2",
    SequenceInRoute: 210700,
    Category: "Whitsunday Water Usage (W1)",
    UsageEntitlement: 1,
    UsagePercentage: 0.0278,
    LastReading: 9022,
    LastRead: new Date("6-Jun-2020"),
    NumberOfDials: 5,
    NextReadDate: new Date("28-Oct-2020"),
    ReadingFrequencyInMonths: 4,
    AverageDailyUsage: 2.552,
    UnitOfMeasure: "Kiloliter",
    MeterLocation: "Rechecked by Council Plumbers 21/3 - 6406",
    AssignedReadingDevice: "PDA_ALL",
    AssignedReader: "Meter Reader 1",
    InstallationDate: new Date("5-Oct-2010"),
    ScheduleRead: true,
    BillingGroup: "Standard Periodic Billing",
    MeterInstallationId: 4333,
    Status: "Active",
    MeterId: 2875,
    Detail: [
      {
        Utility: "Water Usage",
        FromDate: new Date("2-Jul-2011"),
        ToDate: new Date("30-Jul-2012"),
        NumberOfDays: 364,
        MeterNumber: "00HC00916C",
        Percent: 0.0278,
        GrossUsage: 60.5484,
        ChargeableUsage: 60.5484,
        DailyAverageUsage: 0.1663,
        TariffTotal: 32.08,
        Amount: 32.08,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 2.2013,
        Unit: "kl",
        UsageTariffUnits: 2.2013,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32594,
      },
      {
        Usage: 58.3354,
        Unit: "kl",
        UsageTariffUnits: 58.3354,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 32.08,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32594,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("2-Jul-2011"),
        ToDate: new Date("30-Jul-2012"),
        NumberOfDays: 364,
        MeterNumber: "00A105659",
        Percent: 0.0278,
        GrossUsage: 85.3738,
        ChargeableUsage: 85.3738,
        DailyAverageUsage: 0.2345,
        TariffTotal: 45.24,
        Amount: 45.24,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 3.1039,
        Unit: "kl",
        UsageTariffUnits: 3.1039,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32925,
      },
      {
        Usage: 82.2534,
        Unit: "kl",
        UsageTariffUnits: 82.2534,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 45.24,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32925,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("30-Jul-2012"),
        ToDate: new Date("30-Jul-2013"),
        NumberOfDays: 365,
        MeterNumber: "00HC00916C",
        Percent: 0.0278,
        GrossUsage: 159.016,
        ChargeableUsage: 159.016,
        DailyAverageUsage: 0.4357,
        TariffTotal: 84.26,
        Amount: 84.26,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 5.7813,
        Unit: "kl",
        UsageTariffUnits: 5.7813,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32630,
      },
      {
        Usage: 153.204,
        Unit: "kl",
        UsageTariffUnits: 153.204,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 84.26,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32630,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("30-Jul-2012"),
        ToDate: new Date("30-Jul-2013"),
        NumberOfDays: 365,
        MeterNumber: "00A105659",
        Percent: 0.0278,
        GrossUsage: 14.9564,
        ChargeableUsage: 14.9564,
        DailyAverageUsage: 0.041,
        TariffTotal: 7.93,
        Amount: 7.93,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 0.5438,
        Unit: "kl",
        UsageTariffUnits: 0.5438,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32961,
      },
      {
        Usage: 14.4097,
        Unit: "kl",
        UsageTariffUnits: 14.4097,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 7.93,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32961,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("30-Jul-2013"),
        ToDate: new Date("31-Oct-2013"),
        NumberOfDays: 123,
        MeterNumber: "00HC00916C",
        Percent: 0.0278,
        GrossUsage: 51.7636,
        ChargeableUsage: 51.7636,
        DailyAverageUsage: 0.4208,
        TariffTotal: 27.43,
        Amount: 27.43,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 1.8819,
        Unit: "kl",
        UsageTariffUnits: 1.8819,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32666,
      },
      {
        Usage: 49.8716,
        Unit: "kl",
        UsageTariffUnits: 498716,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 27.43,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32666,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("30-Jul-2013"),
        ToDate: new Date("31-Oct-2013"),
        NumberOfDays: 123,
        MeterNumber: "00A105659",
        Percent: 0.0278,
        GrossUsage: 3.9198,
        ChargeableUsage: 3.9198,
        DailyAverageUsage: 0.0319,
        TariffTotal: 2.08,
        Amount: 2.08,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 0.1425,
        Unit: "kl",
        UsageTariffUnits: 0.1425,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32997,
      },
      {
        Usage: 3.7765,
        Unit: "kl",
        UsageTariffUnits: 3.7765,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 2.08,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32997,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("31-Oct-2013"),
        ToDate: new Date("28-Feb-2014"),
        NumberOfDays: 120,
        MeterNumber: "00HC00916C",
        Percent: 0.0278,
        GrossUsage: 55.4888,
        ChargeableUsage: 55.4888,
        DailyAverageUsage: 0.4624,
        TariffTotal: 29.4,
        Amount: 29.4,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 2.0174,
        Unit: "kl",
        UsageTariffUnits: 2.0174,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32702,
      },
      {
        Usage: 53.4607,
        Unit: "kl",
        UsageTariffUnits: 53.4607,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 29.4,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32702,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("31-Oct-2013"),
        ToDate: new Date("28-Feb-2014"),
        NumberOfDays: 120,
        MeterNumber: "00A105659",
        Percent: 0.0278,
        GrossUsage: 4.6982,
        ChargeableUsage: 4.6982,
        DailyAverageUsage: 0.0392,
        TariffTotal: 2.49,
        Amount: 2.49,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 0.1708,
        Unit: "kl",
        UsageTariffUnits: 0.1708,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 33033,
      },
      {
        Usage: 4.5265,
        Unit: "kl",
        UsageTariffUnits: 4.5265,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 2.49,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 33033,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("28-Feb-2014"),
        ToDate: new Date("30-Jul-2014"),
        NumberOfDays: 122,
        MeterNumber: "00HC00916C",
        Percent: 0.0278,
        GrossUsage: 46.3982,
        ChargeableUsage: 46.3982,
        DailyAverageUsage: 0.3803,
        TariffTotal: 24.59,
        Amount: 24.59,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 1.6869,
        Unit: "kl",
        UsageTariffUnits: 1.6869,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32738,
      },
      {
        Usage: 44.7023,
        Unit: "kl",
        UsageTariffUnits: 44.7023,
        UnitSecond: "kl",
        Rate: 0.55,
        TariffAmount: 24.59,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 32738,
      },
      {
        Utility: "Water Usage",
        FromDate: new Date("28-Feb-2014"),
        ToDate: new Date("30-Jul-2014"),
        NumberOfDays: 122,
        MeterNumber: "00A105659",
        Percent: 0.0278,
        GrossUsage: 6.1716,
        ChargeableUsage: 6.1716,
        DailyAverageUsage: 0.0506,
        TariffTotal: 3.27,
        Amount: 3.27,
        Tariff: "Whitsunday Water Usage (W1)",
        Usage: 0.2244,
        Unit: "kl",
        UsageTariffUnits: 0.2244,
        UnitSecond: "kl",
        Rate: 0,
        TariffAmount: 0,
        Levy: "Water Usage Whitsunday",
        JournalNumber: 1036,
        UsageId: 33069,
      },
    ],
  },
  {
    Utility: "Water Usage",
    MeterNumber: "00HC00916C",
    ReadingRoute: "Cannonvale 2",
    SequenceInRoute: 210700,
    Category: "Whitsunday Water Usage (W1)",
    UsageEntitlement: 1,
    UsagePercentage: 0.0278,
    LastReading: 36961,
    LastRead: new Date("6-Jun-2020"),
    NumberOfDials: 6,
    NextReadDate: new Date("28-Oct-2020"),
    ReadingFrequencyInMonths: 4,
    AverageDailyUsage: 10.9745,
    UnitOfMeasure: "Kiloliter",
    MeterLocation: "Back right of Resort",
    AssignedReadingDevice: "PDA_ALL",
    AssignedReader: "Meter Reader 1",
    InstallationDate: new Date("05-Oct-2010"),
    ScheduleRead: true,
    BillingGroup: "Standard Periodic Billing",
    MeterInstallationId: 4334,
    MeterSize: 80,
    MeterType: "Mains",
    Status: "Active",
    MeterId: 2873,
  },
];
