import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import { UITownPlanning } from "@app/products/town-planning/model";
import { putSendLetterToRAandApplicantPlansToComply } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-letter-to-ra-applicant/api";
import { usePlansToComplySendLetterToRAandApplicantButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-letter-to-ra-applicant/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const SendLetterToRAandApplicantButton = observer(() => {
  const { isLoading } = usePlansToComplySendLetterToRAandApplicantButtonStore();
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);

  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePPRStore();

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.SendLetterToRAAndApplicant.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(
    () =>
      (ppr?.Status_ENUM === Application_Status.LetterApprovedPlansAdequate ||
        ppr?.Status_ENUM ===
          Application_Status.LetterApprovedPlansInAdequate) &&
      ppr.Flag_PlansToComply,
    [ppr]
  );

  const handleCommunicationSubmit = async (data: ICommunication) => {
    clearNotifications();
    const response = await postSendCommunication(
      data,
      RECORDTYPE.TP_PPRApplication // Add recordType based on default recordType of CommunicationDialog
    );
    if (isSuccessIdentityPacket(response)) {
      if (pprId) {
        const resp = await putSendLetterToRAandApplicantPlansToComply(pprId);
        setIsShowDialog(false);
        if (isSuccessIdentityPacket(resp)) {
          setPPRWithLoading(resp.data.Application);
          pushNotification({
            type: "success",
            title: "Send letter to RA & applicant successfully",
          });
          if (
            response.data?.Notifications &&
            response.data?.Notifications.length > 0
          ) {
            pushNotification({
              autoClose: false,
              title: "Send letter to RA & applicant",
              type: "warning",
              description: response.data?.Notifications,
            });
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Send letter to RA & applicant failed",
            type: "error",
            description: resp.data?.Errors || resp.statusText,
          });
        }
      }
    } else {
      setIsShowDialog(false);
      pushNotification({
        autoClose: false,
        title: "Send communication failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title={"Send letter to responsible authority and applicant"}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onSubmit}
        name={PPRSubmitActions.SendLetterToRAandApplicant}
      />
      {isShowDialog && (
        <CommunicationDialog
          recipientTypes={recipientTypesCommunicationDialog()}
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleCommunicationSubmit}
          productType={PRODUCT_TYPE_NUMBER.TownPlanning}
          documentStatus={DOCUMENTSTATUS.Final}
          recordID={pprId}
        />
      )}
    </>
  ) : null;
});
