export const LOAD_FAILED_SUB_TITLE = {
  DEFAULT: "There are some problems with loading data",
  SECURITY_DENIED: "You are not authorised to perform this action.",
};

export const LOAD_FAILED_TITLE = {
  DEFAULT: "Load Failed",
  SERVER_ERROR: "Server Error",
  REQUEST_TIME_OUT: "Request Timeout",
};
