import { listRoute } from "@app/products/property/assessments/deferments/list/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const defermentsRoute: ICCRoute = {
  path: "deferments",
  name: "Deferments",
  enum: eMenuAction.Deferments_Folder,
  children: [listRoute],
};
