import { CustomReferenceSlideBarLocality } from "@app/products/property/parcels/locality-street-address/components/custom-reference-slide-bar-locality/_index";
import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const LocalityStreetAddressActionBarInfoIcon = observer(() => {
  const { setIsShowSlideBar, isShowSlideBar, isEmptyData } =
    useDetailParcelListViewStore();

  return (
    <>
      <CCNavButton
        iconClass="fal fa-info-circle"
        title="Show Information"
        disabled={isEmptyData}
        onClick={() => {
          setIsShowSlideBar(!isShowSlideBar);
        }}
        isActive={isShowSlideBar && !isEmptyData}
      />
      <CustomReferenceSlideBarLocality />
    </>
  );
});
