import { DTO_LLS_Annualstock } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/model";
import {
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_LLS_Annualstock>();
export const AnnualStockCol: IColumnFields[] = [
  {
    field: nameOf("LodgementYear"),
    title: "Lodgement Year",
  },
  {
    field: nameOf("LodgementDate"),
    title: "Lodgement Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("TotalDSE"),
    title: "Total DSE",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NativeVegetation"),
    title: "Native Vegetation (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("NPWSConservationArea"),
    title: "NPWS Conservation Area (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("Grapevine"),
    title: "Grapevine (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("Pasture"),
    title: "Pasture (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("Cropping"),
    title: "Cropping (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("Sugarcane"),
    title: "Sugarcane (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("BeefCattle"),
    title: "Beef Cattle",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("DairyCattle"),
    title: "Dairy Cattle",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sheep"),
    title: "Sheep",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Pigs"),
    title: "Pigs",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Goats"),
    title: "Goats",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Deer"),
    title: "Deer",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Horses"),
    title: "Horses (inc Donkeys)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Bison"),
    title: "Bison",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Buffalo"),
    title: "Buffalo",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Camels"),
    title: "Camels",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Alpacas"),
    title: "Alpacas",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Llamas"),
    title: "Llamas",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Emus"),
    title: "Emus",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Ostriches"),
    title: "Ostriches",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IntensiveLivestockCattle"),
    title: "Intensive Livestock Cattle",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IntensiveLivestockSheep"),
    title: "Intensive Livestock Sheep",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IntensiveLivestockPigs"),
    title: "Intensive Livestock Pigs",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AreaForIntensiveSheepAndCattle"),
    title: "Area for Intensive Sheep & Cattle (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("AreaForPiggery"),
    title: "Area for Piggery (ha)",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },
  {
    field: nameOf("Poultry"),
    title: "Poultry",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("ScanInfo"),
    title: "Scan information",
  },
];
