import { INewCertificate } from "@app/products/property/certificates/[id]/components/dialogs/new-certificate/model";
import { nameOfLov } from "@app/products/property/model";
import { getDropdownValue } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useMemo, useRef, useState } from "react";
import "./_index.scss";
import {
  colAssessmentIncludedCertificate,
  colParcelIncludedCertificate,
  comboBoxSearchData,
} from "./config";
import { certificateTypes, mockNewCertificate } from "./mock";

interface INewArrangementDialog {
  onClose: () => void;
  onSubmit: (certificate: any) => void;
}

export const NewCertificateDialog = (props: INewArrangementDialog) => {
  const timeout = useRef<any>();
  const [initialValues, setInitialValues] = useState<INewCertificate>({
    Type: certificateTypes[0]?.Name,
    Parcels: [],
    ParcelsSelected: [],
    Assessments: [],
    AssessmentsSelected: [],
    ...comboBoxSearchData,
  });

  const filterData = (filter: FilterDescriptor) => {
    const data = mockNewCertificate.slice();
    return filterBy(data, filter);
  };
  const handleSearch = (
    event: ComboBoxFilterChangeEvent,
    fieldName: string,
    characters: number,
    formRenderProps: FormRenderProps
  ) => {
    const { onChange } = formRenderProps;
    if (event.filter.value.length >= characters) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onChange(`_option.${fieldName}.Data`, {
          value: filterData(event.filter),
        });
        onChange(`_option.${fieldName}.Loading`, { value: false });
      }, 1000);
      onChange(`_option.${fieldName}.Loading`, { value: true });
    } else {
      onChange(`_option.${fieldName}.Data`, {
        value: [],
      });
    }
  };
  const handleSelectionChange = (event: ComboBoxChangeEvent) => {
    if (
      event.value &&
      event.value.ParcelDetail &&
      event.value.AssessmentDetail &&
      !initialValues.Parcels.find(
        (parcel) => parcel.ParcelId === event.value.ParcelDetail.ParcelId
      ) &&
      !initialValues.Assessments.find(
        (assessment) =>
          assessment.AssessmentId === event.value.AssessmentDetail.AssessmentId
      )
    ) {
      setInitialValues({
        ...initialValues,
        Parcels: [
          ...initialValues.Parcels,
          ...[{ ...event.value.ParcelDetail }],
        ],
        ParcelsSelected: [
          ...initialValues.ParcelsSelected,
          ...[{ ...event.value.ParcelDetail }],
        ],
        Assessments: [
          ...initialValues.Assessments,
          ...[{ ...event.value.AssessmentDetail }],
        ],
        AssessmentsSelected: [
          ...initialValues.AssessmentsSelected,
          ...[{ ...event.value.AssessmentDetail }],
        ],
      });
    }
  };

  const handleSubmit = () => {
    props.onSubmit({
      Parcels: initialValues.ParcelsSelected,
      Assessments: initialValues.AssessmentsSelected,
      Type: initialValues.Type,
    });
  };
  const formValidated = useMemo(() => {
    return (
      initialValues.Type &&
      (initialValues.AssessmentsSelected.length > 0 ||
        initialValues.ParcelsSelected.length)
    );
  }, [initialValues]);
  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const getValue = formRenderProps.valueGetter;
        const parcels = getValue("Parcels");
        const assessments = getValue("Assessments");
        const listPanelBar: IListPanelBar[] = [
          {
            title: "Parcel(s) included in certificate",
            expanded: true,
            component: (
              <CCGrid
                selectableMode="multiple"
                data={parcels ?? []}
                primaryField={"ParcelId"}
                columnFields={colParcelIncludedCertificate}
                selectedRows={initialValues.ParcelsSelected}
                onSelectionChange={(dataItem) => {
                  setInitialValues({
                    ...initialValues,
                    ParcelsSelected: dataItem,
                  });
                }}
              />
            ),
          },
          {
            title: "Assessment(s) included in certificate",
            expanded: true,
            component: (
              <CCGrid
                selectableMode="multiple"
                data={assessments ?? []}
                selectedRows={initialValues.AssessmentsSelected}
                primaryField={"AssessmentId"}
                columnFields={colAssessmentIncludedCertificate}
                onSelectionChange={(dataItem) => {
                  setInitialValues({
                    ...initialValues,
                    AssessmentsSelected: dataItem,
                  });
                }}
              />
            ),
          },
        ];
        return (
          <CCDialog
            titleHeader={`New ${
              getDropdownValue(
                getValue("Type"),
                certificateTypes,
                nameOfLov("Name")
              )?.Name ?? ""
            }`}
            onClose={props.onClose}
            maxHeight="70%"
            maxWidth="65%"
            bodyElement={
              <div className="cc-new-certificate cc-form">
                <FormElement
                  onSubmit={formRenderProps.onSubmit}
                  className="cc-field-group"
                >
                  <section className="cc-field-group">
                    <div className="cc-field">
                      <label className="cc-label">Certificate type</label>
                      <Field
                        name="Type"
                        component={CCSearchComboBox}
                        validator={validateRequired}
                        data={certificateTypes}
                        textField={nameOfLov("Name")}
                        dataItemKey={nameOfLov("Code")}
                        value={getDropdownValue(
                          getValue("Type"),
                          certificateTypes,
                          nameOfLov("Code")
                        )}
                        onChange={(event: ComboBoxChangeEvent) => {
                          formRenderProps.onChange("Type", {
                            value: event.target.value?.Code ?? null,
                          });
                        }}
                      />
                    </div>
                  </section>
                  <hr className="cc-divider" />
                  <section className="cc-field-group">
                    <label className="cc-label">
                      Select the properties that the certificate is to cover
                    </label>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Assessment</label>
                        <Field
                          name="Assessment"
                          filterable
                          textField="Assessment"
                          onFilterChange={(
                            event: ComboBoxFilterChangeEvent
                          ) => {
                            handleSearch(
                              event,
                              "Assessment",
                              4,
                              formRenderProps
                            );
                          }}
                          data={getValue("_option.Assessment.Data")}
                          loading={getValue("_option.Assessment.Loading")}
                          onChange={handleSelectionChange}
                          placeholder="Type assessment number"
                          component={CCComboBox}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Parcel</label>
                        <Field
                          name="Parcel"
                          filterable
                          textField="Parcel"
                          onFilterChange={(
                            event: ComboBoxFilterChangeEvent
                          ) => {
                            handleSearch(event, "Parcel", 2, formRenderProps);
                          }}
                          data={getValue("_option.Parcel.Data")}
                          loading={getValue("_option.Parcel.Loading")}
                          onChange={handleSelectionChange}
                          placeholder="Enter parcel reference"
                          component={CCComboBox}
                        />
                      </div>

                      <div className="cc-field">
                        <CustomPanelBar
                          expandMode="multiple"
                          listPanelBar={listPanelBar}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={!formValidated}
                  onClick={handleSubmit}
                >
                  Create Certificate
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
