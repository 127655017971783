import { FormAction } from "@app/products/crms/model";
import { DDCreateAccountDialog } from "@app/products/direct-debit/accounts/components/dialogs/create-account";
import { IDirectDebitAccountFormStepProps } from "@app/products/direct-debit/accounts/components/dialogs/create-account/steps/general";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import { colDDAuthorisationSelectAccount } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/components/steps/select-accounts/config";
import { nameOfDDAuthorisation } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/constant";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { clone } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

const nameOfDirectDebitAccount = nameOfFactory<DirectDebitAccount>();

export const DDAuthorisationSelectAccountsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ formRenderProps, options }: IFormStepElement) => {
    const { onChange, valueGetter } = formRenderProps;
    const { checkPermissions, isLoadingPermission } = options;

    const [isShowAccountDialog, setIsShowAccountDialog] = useState(false);
    const [selectedRows, setSelectedRows] = useState<
      IDirectDebitAccountFormStepProps[]
    >([]);
    const [accountEditData, setAccountEditData] =
      useState<IDirectDebitAccountFormStepProps | null>(null);

    const handleAddAccount = (data: IDirectDebitAccountFormStepProps) => {
      let oldAccounts = valueGetter(nameOfDDAuthorisation("Accounts")) ?? [];
      const newAccount = accountEditData
        ? data
        : { ...data, Account_ID: getUUID() };

      if (accountEditData) {
        oldAccounts = oldAccounts.filter(
          (account: IDirectDebitAccountFormStepProps) =>
            account.Account_ID !== newAccount.Account_ID
        );
      }
      const newAccounts = [...oldAccounts, newAccount];

      onChange(nameOfDDAuthorisation("Accounts"), {
        value: newAccounts,
      });
      setIsShowAccountDialog(false);
      setAccountEditData(null);
    };

    const handleRemove = () => {
      const ids = selectedRows.map(
        (account: DirectDebitAccount) => account.Account_ID
      );

      const currentAccounts =
        valueGetter(nameOfDDAuthorisation("Accounts")) ?? [];
      const newAccounts = currentAccounts.filter(
        (account: DirectDebitAccount) => !ids.includes(account.Account_ID)
      );
      setSelectedRows([]);
      onChange(nameOfDDAuthorisation("Accounts"), { value: newAccounts });
    };

    const newCols = useMemo(() => {
      let cloneCols = clone(colDDAuthorisationSelectAccount);
      cloneCols = cloneCols.map((col) => {
        if (
          col.field === nameOfDirectDebitAccount("Integration_Module_Type_Name")
        ) {
          col.handleOnClick = (dataItem: IDirectDebitAccountFormStepProps) => {
            setAccountEditData(dataItem);
            setIsShowAccountDialog(true);
          };
        }
        return col;
      });
      return cloneCols;
    }, []);

    const isDisabledRemoveButton = useMemo(() => {
      return (
        (valueGetter(nameOfDDAuthorisation("Accounts")) ?? []).length === 0 ||
        selectedRows?.length === 0
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows?.length]);

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Accounts" />
              <CCGrid
                data={valueGetter(nameOfDDAuthorisation("Accounts")) ?? []}
                selectableMode={"multiple"}
                columnFields={newCols}
                selectedRows={selectedRows}
                primaryField={"Account_ID"}
                onSelectionChange={(dataItem: any) => {
                  setSelectedRows(dataItem ?? []);
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass={
                        isLoadingPermission
                          ? "fas fa-spinner fa-spin"
                          : "fas fa-plus"
                      }
                      onClick={() => setIsShowAccountDialog(true)}
                      disabled={
                        !checkPermissions([
                          FormAction.CORE_ALLOW_NEW,
                          FormAction.CORE_ALLOW_SAVE,
                        ])
                      }
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={isDisabledRemoveButton}
                      onClick={handleRemove}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </section>
        {isShowAccountDialog && (
          <DDCreateAccountDialog
            isFromAuthorisation
            onClose={() => {
              setIsShowAccountDialog(false);
              setAccountEditData(null);
            }}
            authorisationSubmit={handleAddAccount}
            initValues={accountEditData}
          />
        )}
      </>
    );
  }
);
