import { RegisterDetailTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getPPREnquiryDetailsTabById = async (
  pprEnquiryId: number
): Promise<APIResponse<RegisterDetailTab | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<RegisterDetailTab>(
      `/api/tp/ppr/internal/enquiry/${pprEnquiryId}/detailtab`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
