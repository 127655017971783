import { SpecialCharacter } from "@app/products/crms/[id]/model";

export const formatABN = (strABN: string) => {
  if (!strABN) {
    return strABN;
  }

  strABN = strABN.replace(/\D/g, "");
  if (strABN.length === 11) {
    return `${strABN.substring(0, 2)} ${strABN.substring(
      2,
      3
    )} ${strABN.substring(5, 3)} ${strABN.substring(8, 3)}`;
  }
  if (strABN.length === 9) {
    return `${strABN.substring(0, 3)} ${strABN.substring(
      3,
      3
    )} ${strABN.substring(6, 3)}`;
  }

  return strABN;
};

export const replaceSpecialCharacter = (strItem: string) => {
  return strItem
    .replaceAll("/", SpecialCharacter.SlashMark)
    .replaceAll("?", SpecialCharacter.QuestionMark)
    .replaceAll(":", SpecialCharacter.ColonMark)
    .replaceAll("&", SpecialCharacter.PlusSymbolMark)
    .replaceAll("+", SpecialCharacter.PlusMark)
    .replaceAll("#", SpecialCharacter.HashtagMark)
    .replaceAll(".", SpecialCharacter.DotMark);
};
