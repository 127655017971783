import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postRemoveAssessmentTransaction = async (
  journalNumber?: number | null,
  transactionId?: number
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `/api/property/internal/journal/${journalNumber}/transactions/${transactionId}/remove`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postRemovePICTransaction = async (
  journalNumber?: number | null,
  transactionId?: number
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `/api/property/internal/journal/${journalNumber}/pictransactions/${transactionId}/remove`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
