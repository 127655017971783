import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export interface DTO_Workflow_CertificateReplace {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_CertificateReplace;
}

export interface DTO_WorkflowDetail_CertificateReplace {
  CertificateId: number | null;
  Details: DTO_Details;
  CertificateDetails: DTO_CertificateDetails;
}

export interface DTO_Details {
  Reference: string;
  ApplicantName: string;
  ReasonId: number | null;
}

export interface DTO_CertificateDetails {
  CertificateType: number | null;
  Assessments: DTO_Assessment[];
  Parcels: DTO_Parcel[];
  Titles: DTO_Title[];
  SelectedPICs: DTO_PIC_Associations;
}

export interface DTO_Title {
  AssociatedPICAreaSet: number | null;
  Frontage: number | null;
  County: string;
  Parish: string;
  Section: string;
  LinkedAssessments: DTO_Title_LinkedAssessment[];
  AssociatedPICArea: number | null;
  PIC_Title_Id: number | null;
  Active_From: Date | null;
  Active_To: Date | null;
  AssociationFactorPercentage: number | null;
  GISReference: string;
  AssociatedPICAreaTotal: number | null;
  NameAddress: string;
  StatusId: number | null;
  Coverage: string;
  LandArea: number | null;
  DivisionDate: Date | null;
  Status: string;
  Folio: string;
  Volume: string;
  Lot: string;
  PlanNumber: string;
  PlanType: string;
  ReferenceNumber: number | null;
  LegalDescription: string;
  TitleId: number;
  IsAreaSurveyed: boolean;
}

export interface DTO_Title_LinkedAssessment {
  Assessment_Id: number;
  LinkedTitles: number[];
}

export interface DTO_PIC_Associations {
  PIC_Ids: number[];
  PICs: DTO_PIC[];
  Selected_Primary_PIC_Id: number | null;
}

export interface DTO_PIC {
  PIC_Id: number;
  Property_Address: string;
  PIC_Number: string;
  Pic_Manager: string;
  Trading_Name: string;
  PIC_Status: string;
  District: string;
  RLP_Board: string;
}

export interface DTO_Assessment {
  Valuation_1: number | null;
  Valuation_2: number | null;
  Valuation_3: number | null;
  Valuation_4: number | null;
  Valuation_5: number | null;
  Valuation_6: number | null;
  Valuation_7: number | null;
  Valuation_8: number | null;
  Valuation_9: number | null;
  Valuation_10: number | null;
  Valuation_11: number | null;
  Valuation_12: number | null;
  Valuation_13: number | null;
  Valuation_14: number | null;
  Valuation_15: number | null;
  Valuation_16: number | null;
  Valuation_17: number | null;
  Valuation_18: number | null;
  Valuation_19: number | null;
  PIC_Assessment_Id: number | null;
  Is_Primary_Assessment: boolean | null;
  LinkedTitles: DTO_Assessment_LinkedTitle[];
  AS_Is_Assessment_Editable: boolean;
  Assessment_Id: number;
  Assess_Number: number | null;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement: number | null;
  Unit_Entitlement_Percentage: number | null;
  Unit_Type: string;
  Valuation_20: number | null;
  Unit_Type_Percentage: number | null;
  Assess_Balance_Outstanding: number | null;
  Assess_Being_Postponed: boolean;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Assessment_Type_Name: string;
  Assessment_Status: number;
  Assessment_Status_Name: string;
  Primary_Assess_Land_Use_Name: string;
  Assess_Balance: number;
}

export interface DTO_Assessment_LinkedTitle {
  Title_Id: number;
  LinkedAssessments: number[];
}

export interface DTO_Certificate_LOVs {
  CertificateType: DTO_LOV_CertType[];
  RequestSource: DTO_LOV[];
  UDAL: DTO_LOV_UDAL[];
  RatingPeriods: DTO_LOV[];
  Applicants: DTO_LOV[];
  States: DTO_LOV[];
  Countries: DTO_LOV[];
  Localities: DTO_LOV[];
  UDAs: DTO_LOV[];
  ReasonForCreate: DTO_LOV[];
  Officers: DTO_LOV[];
}

export interface DTO_LOV_UDAL {
  Code: string;
  Name: string;
  UDAL_Text: string;
  UDA_Attribute_Id: number;
}

export interface DTO_LOV_CertType {
  Code: string;
  Name: string;
  Component: eComponent;
  DynamicQuestionListId: number | null;
}

export enum ReplaceCertificateKeysOfSteps {
  Details = "Details",
  CertificateDetails = "CertificateDetails",
}

export const keysOfSendSteps = [
  ReplaceCertificateKeysOfSteps.Details,
  ReplaceCertificateKeysOfSteps.CertificateDetails,
];

export interface RequestCertificateReplaceObj {
  Certificate_Id: number;
}
