import { getLinkNavigate } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/installed-modules-settings/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink } from "react-router-dom";
interface IViewModule {
  formRenderProps: FormRenderProps;
  data: any;
}
export const ViewModule = observer(({ formRenderProps, data }: IViewModule) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter, onChange } = formRenderProps;

  const switchComponent = (data: any) => {
    if (!data) return null;
    data.splice(0, 1);
    return data.map((item: any) => {
      if (item?.Type === "Hidden") {
        return null;
      } else if (item?.Type === "Link") {
        const link = getLinkNavigate(item.group);
        return (
          <div className="cc-field">
            <label className="cc-label">{item?.Title}</label>
            <NavLink
              to={link}
              className="cc-grid-link-text"
              onClick={(event) => (link ? {} : event.preventDefault())}
            >
              Detail System Settings
            </NavLink>
          </div>
        );
      } else if (item?.Type === "Dropdown") {
        const changeTypeValue = (value: string) =>
          filter(item?.LookupItems, { Id: value })[0];
        return (
          <div className="cc-field">
            <label className="cc-label">{item?.Title}</label>
            <Field
              name={item?.FieldName}
              component={CCSearchComboBox}
              data={item?.LookupItems}
              value={changeTypeValue(valueGetter(item.FieldName))}
              textField="Name"
              dataItemKey="Id"
              disabled={!isEditing}
              onChange={(e: ComboBoxChangeEvent) => {
                onChange(item?.FieldName, {
                  value: e.value?.Id ?? null,
                });
                onChange(data[4]?.FieldName, {
                  value: e.value?.Id === "3" ? "1" : "0", // follow radium side
                });
              }}
            />
          </div>
        );
      } else if (item?.Type === "Switch") {
        return (
          <div className="cc-field">
            <label className="cc-label">{item?.Title}</label>
            <Field
              name={item?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={valueGetter(item?.FieldName)}
            />
          </div>
        );
      } else if (item?.Type === "Input") {
        const dynamicColumn = data[0].Type === "Link" ? 2 : 1; // Use for dynamic size field
        return (
          <div className={`cc-field cc-col-span-${dynamicColumn}`}>
            <label className="cc-label">
              {item?.Title}
              {item?.IsMandatory && (
                <CCTooltip type="validator" position="right" />
              )}
            </label>
            <Field
              name={item?.FieldName}
              component={CCInput}
              placeholder={item?.Title}
              disabled={!isEditing}
              validator={item?.IsMandatory ? requiredValidator : undefined}
            />
          </div>
        );
      }
      return null;
    });
  };
  const dynamicColumnWrap = data[1]?.Type === "Input" ? 3 : 5; // Use for dynamic size field
  return (
    <section className="cc-field-group">
      <label className="cc-label">{data[0]?.Title}</label>
      <div
        className={`cc-custom-sub-panel-bar cc-form-cols-${dynamicColumnWrap}`}
      >
        {switchComponent(data)}
      </div>
    </section>
  );
});
