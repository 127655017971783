import { getDebtRecoveryDeferredDuty } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/api";
import { DTO_DebtRecovery_DeferredDuty } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class DebtRecoveryDeferredDuttyStore {
  private _deferredDutty?: DTO_DebtRecovery_DeferredDuty = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get deferredDutty() {
    return this._deferredDutty;
  }
  setDeferredDutty = (deferredDutty?: DTO_DebtRecovery_DeferredDuty) => {
    runInAction(() => {
      this._deferredDutty = deferredDutty;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this.setDeferredDutty(undefined);
      this.setIsLoading(false);
    });
  };

  loadDeferredDutty = async (debtRecoveryId: number) => {
    this.setIsLoading(true);
    const deferredDutty = await getDebtRecoveryDeferredDuty(debtRecoveryId);
    this.setDeferredDutty(deferredDutty);
    this.setIsLoading(false);
  };
}

const debtRecoveryDeferredDuttyStoreContext = createContext(
  new DebtRecoveryDeferredDuttyStore()
);
export const useDebtRecoveryDeferredDuttyStore = () => {
  return useContext(debtRecoveryDeferredDuttyStoreContext);
};
