import { pprApplicationCategoryDisplay } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { Overlay, Zone } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import {
  ASSESSMENT_FORM_STEP,
  AssessmentFormStep,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/form-steps/assessment/_index";
import {
  PROPOSED_ASSESSMENT_FORM_STEP,
  ProposedAssessmentFormStep,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/form-steps/proposed-assessment/_index";
import { IProposedAssessment } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React, { useMemo } from "react";

interface IOfficerAssessmentDialogProps {
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: IProposedAssessment) => void;
}

export const OfficerAssessmentDialog = ({
  isLoading,
  onClose,
  onSubmit,
}: IOfficerAssessmentDialogProps) => {
  const { ppr } = usePPRStore();
  const steps: IStep[] = [
    {
      label: "Application details - proposed",
      component: ProposedAssessmentFormStep,
      visible: true,
      key: PROPOSED_ASSESSMENT_FORM_STEP,
    },
    {
      label: "Assessment",
      component: AssessmentFormStep,
      visible: true,
      key: ASSESSMENT_FORM_STEP,
    },
  ];
  const initialValues: IProposedAssessment = useMemo(() => {
    let zones: IKeyValuePacket[] = [];
    let overlays: IKeyValuePacket[] = [];
    let zonesDisplay: string = "";
    let overlaysDisplay: string = "";
    if (
      ppr?.AssessmentPlanner?.AssessmentPlanner_Zones &&
      ppr?.AssessmentPlanner?.AssessmentPlanner_Zones.length > 0
    ) {
      zones = ppr?.AssessmentPlanner?.AssessmentPlanner_Zones.map(
        (zone: Zone): IKeyValuePacket => {
          return { Key: zone.Zone_KWD, Value: zone.Zone_Name };
        }
      );
      zonesDisplay = ppr?.AssessmentPlanner?.AssessmentPlanner_Zones.reduce(
        (pre, cur) => pre + ", " + cur.Zone_Name,
        ""
      ).slice(1);
    }
    if (
      ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays &&
      ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays.length > 0
    ) {
      overlays = ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays.map(
        (overlay: Overlay): IKeyValuePacket => {
          return { Key: overlay.Overlay_KWD, Value: overlay.Overlay_Name };
        }
      );
      overlaysDisplay =
        ppr?.AssessmentPlanner?.AssessmentPlanner_Overlays.reduce(
          (pre, cur) => pre + ", " + cur.Overlay_Name,
          ""
        ).slice(1);
    }
    return {
      Flag_ProposedApplicationDetailsFilled: false,
      TotalNumberOfAdditionalDwellings_Proposed: null,
      TotalNumberOfAdditionalBicycleSpaces_Proposed: null,
      TotalNumberOfShowers_Proposed: null,
      TotalNumberOfAdditionalCarParks_Proposed: null,
      TotalNumberOfAdditionalCrossovers_Proposed: null,
      TotalAdditionalFloorSpace_Commercial_Proposed: null,
      TotalAdditionalFloorSpace_Retail_Proposed: null,
      TotalAdditionalFloorSpace_Industrial_Proposed: null,
      TotalAdditionalFloorSpace_Government_Proposed: null,
      TotalAdditionalFloorSpace_Education_Proposed: null,
      TotalAdditionalFloorSpace_Health_Proposed: null,
      TotalAdditionalFloorSpace_Other_Proposed: null,
      SaveTriggers: [],
      //Step 2
      ApplicationCategories: [],
      Zones: [],
      Overlays: [],
      _option: {
        ApplicationCategories: ppr
          ? ppr?.EPlanning_ID && !ppr?.ApplicationCategories_Namelist?.length
            ? ppr?.EPlanning_Categories
            : pprApplicationCategoryDisplay(ppr?.ApplicationCategories)
          : null,
        Zones: zones,
        Overlays: overlays,
        ZonesDisplay: zonesDisplay,
        OverlaysDisplay: overlaysDisplay,
      },
    };
  }, [ppr]);

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      initialValues={initialValues}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="65%"
          titleHeader={"Officer Assessment"}
          onClose={onClose}
          bodyElement={renderProps.children}
          disabled={isLoading}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
