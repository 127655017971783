import { getViewConfigurations } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { JournalDetailTab } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { JournalAuthorisedBookmark } from "@app/products/property/journals/authorised/util";
import { CreateSessionButton } from "@app/products/property/journals/list/components/action-bar/buttons/create-session/_index";
import { colJournals } from "@app/products/property/journals/list/config";
import { PROPERTY_JOURNALS_LISTS_VIEW_URL } from "@app/products/property/journals/list/constant";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { journalsRoute } from "@app/products/property/journals/route";
import { JournalBookmark } from "@app/products/property/journals/util";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_JournalList>();

export default observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedJournalsCol, setProcessedJournalsCol] =
    useState<IColumnFields[]>(colJournals);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton type="sub-workflow" title="Workflow">
        {/* Task 15818 - Create Session in Journal Authorised list: The same logic with Create Session in the Journal List */}
        <CreateSessionButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_JOURNALS_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        detail={JournalBookmark.getBookmarkDetail}
        displayName={JournalBookmark.getBookmarkDisplayName}
        listViewDetail={JournalAuthorisedBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          JournalAuthorisedBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <JournalDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Journal}
          />
        ),
      },
    ],
  });

  const loadConfiguration = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.OData_Journal).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedJournalsCol([]);
          setIsLoading(false);
          return;
        }
        setProcessedJournalsCol(processDynamicColumns(colJournals, viewConfig));
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadConfiguration();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadConfiguration();
        }}
      />
    );
  if (isLoading) return <Loading isLoading isFullScreen />;

  return (
    <CCProductListView
      columnFields={processedJournalsCol}
      primaryField={nameOf("Journal_Number")}
      dataUrl={PROPERTY_JOURNALS_LISTS_VIEW_URL}
      state={{
        filter: {
          filters: [
            {
              field: nameOf("Journal_Status_Id"),
              operator: "eq",
              value: 2,
            },
          ],
          logic: "and",
        },
      }}
    />
  );
});
