import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE } from "@common/pages/settings/security/org-structure/_id/constant";
import { OrgUnit_ListView } from "@common/pages/settings/security/org-structure/_id/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<OrgUnit_ListView>();

export const colSecurityOrgStructure: IColumnFields[] = [
  {
    field: nameOf("Hierarchy"),
    title: "Hierarchy",
    locked: true,
    linkTo: (dataItem: OrgUnit_ListView) =>
      `${SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("OrgLevel"),
    title: "Org Level",
  },
  {
    field: nameOf("OrgUnit_Name"),
    title: "Org Unit Name",
  },
  {
    field: nameOf("Manager"),
    title: "Manager",
  },
  {
    field: nameOf("Phone"),
    title: "Phone",
  },
  {
    field: nameOf("AdhocGroup"),
    title: "Ad hoc Group",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
