import { Registration } from "@app/products/animals/[id]/model";
import { getQuickRegistrationDetails } from "@app/products/animals/renewals/components/button/batch-renew/api";
import {
  QuickRegistrationDetailsRequest,
  QuickRegistrationDetailsResponse,
} from "@app/products/animals/renewals/components/button/batch-renew/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAnimalsReceiptBoxDialogProps {
  onClose: () => void;
  animalRegisterIDs: number[];
  animalRegistration?: Registration;
  titleDialog?: string;
}

const nameOf = nameOfFactory<QuickRegistrationDetailsResponse>();
export const AnimalsReceiptBoxDialog = ({
  onClose,
  animalRegisterIDs,
  animalRegistration,
  titleDialog = "Animal Quick Registration Details",
}: IAnimalsReceiptBoxDialogProps) => {
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialData, setInitialData] =
    useState<QuickRegistrationDetailsResponse>();

  const loadInitialData = async () => {
    let errorResponse = undefined;
    setIsLoading(true);
    const requestBody = {
      RegistrationIDs: animalRegisterIDs,
      Registration: !isNil(animalRegistration) ? animalRegistration : null,
    } as QuickRegistrationDetailsRequest;
    const response = await getQuickRegistrationDetails(requestBody);
    setIsLoading(false);
    if (isSuccessResponse(response) && response?.data?.ReturnObj) {
      setInitialData(response?.data?.ReturnObj);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error ?? "Load quick registration details failed.",
      };
    }
    setResponseLoadError(errorResponse);
  };

  useEffectOnce(() => {
    loadInitialData();
  });

  return (
    <Form
      initialValues={initialData}
      key={JSON.stringify(initialData)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;
        return (
          <CCDialog
            onClose={onClose}
            maxWidth="30%"
            height="auto"
            titleHeader={titleDialog}
            bodyElement={
              <div className="cc-form cc-calculate-grid-dialog">
                <FormElement className="cc-field-group cc-grid-dialog-content">
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={async () => {
                        await loadInitialData();
                      }}
                    />
                  ) : (
                    <div className="cc-form-cols-1">
                      <CCValueField
                        label="Registration number"
                        value={valueGetter(nameOf("LblRegNo"))}
                      />
                      <CCValueField
                        label="Tag number"
                        value={valueGetter(nameOf("LblTag"))}
                      />
                      <CCValueField
                        label="Fee ID"
                        value={valueGetter(nameOf("LblFee"))}
                      />
                      <CCValueField
                        label="Invoice number"
                        value={valueGetter(nameOf("LblInvoiceNo"))}
                      />
                    </div>
                  )}
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Close
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
