import { ContactFormElement } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/_index";
import { partnerValidator } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/util";
import {
  ContactSubmitActions,
  Svc_Contact,
} from "@app/core/contacts/_id/model";
import { useContactStore } from "@app/core/contacts/_id/store";
import { produceContactSaveTrigger } from "@app/core/contacts/_id/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const ContactForm = observer(() => {
  const { setOnSubmit, contact, saveContact, lovs } = useContactStore();
  const { pushNotification } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isValid, isModified } = event;
    const action = event.event?.currentTarget.name as ContactSubmitActions;
    if (!(action in ContactSubmitActions)) return;
    if (!isValid) return;

    if (
      !isModified &&
      [ContactSubmitActions.Save, ContactSubmitActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    saveContact(
      produceContactSaveTrigger(values as Svc_Contact, contact),
      action
    );
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, contact, lovs]);

  const formKey =
    JSON.stringify(contact) + JSON.stringify(lovs) + isNew.toString();

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={contact}
        key={formKey}
        validator={partnerValidator}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <ContactFormElement
                  formRenderProps={formRenderProps}
                  lovs={lovs}
                  isNew={isNew}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
