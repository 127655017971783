import { ICCRoute } from "@common/constants/ICCRoute";

export const myActionsRoute: ICCRoute = {
  path: "my-actions",
  name: "My Actions",
  children: [
    {
      path: "recorded-on",
      name: "Recorded On",
      component: require("./recorded-on/_index").default,
    },
    {
      path: "status",
      name: "Status",
      component: require("./status/_index").default,
    },
    {
      path: "type",
      name: "Type",
      component: require("./type/_index").default,
    },
    {
      path: "outstanding",
      name: "Outstanding",
      component: require("./outstanding/_index").default,
    },
    {
      path: "overdue",
      name: "Overdue",
      component: require("./overdue/_index").default,
    },
  ],
};
