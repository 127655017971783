import { Asset } from "@app/products/crms/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Asset>();

export const colAssetPickerSearch: IColumnFields[] = [
  {
    field: nameOf("SiteCode"),
    title: "Site Code",
    width: "120px",
  },
  {
    field: nameOf("SiteName"),
    title: "Site Name",
    width: "200px",
  },
  {
    field: nameOf("StreetName"),
    title: "Location Name",
    width: "130px",
  },
  {
    field: nameOf("Postcode"),
    title: "Post Code",
    width: "100px",
  },
  {
    field: nameOf("StreetName"),
    title: "Street Name",
    width: "160px",
  },
  {
    field: nameOf("SuburbArea"),
    title: "Suburb",
    width: "100px",
  },
];

export const searchAssetConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};
