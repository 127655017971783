import { DTO_LOV } from "@common/models/odataResponse";

export const mockScheme: DTO_LOV[] = [
  { Code: "1", Name: "Como St, Berrys Rd. Ferres Rd and Part of Madigan Way" },
  { Code: "2", Name: "Cornish Road Special Charge Scheme" },
  { Code: "3", Name: "Dovon\\Belgrave Ave Special Charge Scheme" },
  { Code: "4", Name: "George and Mary Street Bunyip Special Charge Scheme" },
  { Code: "5", Name: "Guest Road Special Charge Scheme" },
  { Code: "6", Name: "Henry Rd Special Charge Scheme Sewerage" },
  { Code: "7", Name: "Island Road KWR Special Charge Scheme" },
  { Code: "8", Name: "Jefferson Road Special Charge Scheme" },
  { Code: "9", Name: "Majestic Dr Special Charge Scheme" },
  { Code: "10", Name: "McBride Road Special Charge Scheme" },
  { Code: "11", Name: "Mill St Special Charge Scheme" },
  { Code: "12", Name: "Miscellaneous/Conversion Schemes" },
];
