import { DTO_SubdivisionsProgeny_LOVs } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getSubdivisionProgenyLovs = async (
  titleIds: number[]
): Promise<APIResponse<DTO_SubdivisionsProgeny_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_SubdivisionsProgeny_LOVs>(
      `/api/property/internal/subdivisionsconsolidations/progeny/lovs?ids=${titleIds.toString()}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
