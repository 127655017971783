import { loadPropertyAssessmentRating } from "@app/products/property/assessments/[id]/components/child-screens/rating/api";
import {
  DTO_Assessment,
  DTO_Assessment_Levy,
  DTO_Assessment_Rating,
} from "@app/products/property/assessments/[id]/components/child-screens/rating/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AssessmentRatingStore {
  private _rating?: DTO_Assessment_Rating = undefined;
  private _isLoading: boolean = false;
  private _assessment: DTO_Assessment | undefined = undefined;
  private _assessmentLevies: DTO_Assessment_Levy[] | undefined = [];
  private _isIncludeHistorical: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get rating(): DTO_Assessment_Rating | undefined {
    return this._rating;
  }
  setRating = (rating?: DTO_Assessment_Rating) => {
    runInAction(() => {
      this._rating = rating;
    });
  };

  get assessmentLevies(): DTO_Assessment_Levy[] | undefined {
    return this._assessmentLevies;
  }
  setAssessmentLevies = (assessmentLevies?: DTO_Assessment_Levy[]) => {
    runInAction(() => {
      this._assessmentLevies = assessmentLevies;
    });
  };

  get assessment(): DTO_Assessment | undefined {
    return this._assessment;
  }
  setAssessment = (assessment?: DTO_Assessment) => {
    runInAction(() => {
      this._assessment = assessment;
    });
  };

  get isIncludeHistorical(): boolean {
    return this._isIncludeHistorical;
  }
  setIsIncludeHistorical = (isIncludeHistorical: boolean) => {
    runInAction(() => {
      this._isIncludeHistorical = isIncludeHistorical;
    });
  };

  get isLoading(): boolean {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._rating = undefined;
      this._assessment = undefined;
      this._isLoading = false;
      this._assessmentLevies = undefined;
      this._isIncludeHistorical = false;
    });
  };

  loadRating = async (assessmentId: number, isIncludeHistorical?: boolean) => {
    this.setIsLoading(true);
    const newRating = await loadPropertyAssessmentRating(
      assessmentId,
      isIncludeHistorical
    );
    this.setRating(newRating);
    this.setAssessment(newRating?.Assessment);
    this.setAssessmentLevies(
      newRating?.AssessmentLevies ? newRating?.AssessmentLevies : []
    );
    this.setIsLoading(false);
  };
}

const assessmentRatingStoreContext = createContext(new AssessmentRatingStore());
export const useAssessmentRatingStore = () => {
  return useContext(assessmentRatingStoreContext);
};
