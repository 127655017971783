import { ICCRoute } from "@common/constants/ICCRoute";

export const renewalsRoute: ICCRoute = {
  path: "renewals",
  name: "Renewals",
  children: [
    {
      path: "requires-renewal-fee",
      name: "Requires Renewal Fee",
      component: require("./requires-renewal-fee/_index").default,
    },
    {
      path: "requires-renewal-notice",
      name: "Requires Renewal Notice",
      component: require("./requires-renewal-notice/_index").default,
    },
    {
      path: "renewal-notice-sent",
      name: "Renewal Notice Sent",
      component: require("./renewal-notice-sent/_index").default,
    },
  ],
};
