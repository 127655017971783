import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCAppNotificationItem } from "@components/cc-app-notification/components/notification-item/_index";
import { Slide } from "@progress/kendo-react-animation";
import React, { useEffect, useRef } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface ICCAppNotificationContainerProps {
  removeNotification: (notificationId: string, isCallOnClose?: boolean) => void;
  notifications: IAppNotificationItem[];
  isLocalNotification?: boolean;
}
export const CCAppNotificationContainer = ({
  removeNotification,
  notifications,
  isLocalNotification = false,
}: ICCAppNotificationContainerProps) => {
  const notificationContainerRef = useRef<HTMLDivElement | null>(null);
  const animationContainerElement =
    notificationContainerRef?.current?.querySelector(".k-animation-container");

  const newNotificationsLength = notifications.length;
  const currentNotificationsLength = useRef<number>(notifications.length);

  useEffectOnce(() => {
    return () => {
      notifications.forEach((notification) => {
        removeNotification(notification.id);
      });
    };
  });

  useEffect(() => {
    //Check new notification
    if (notifications.length > currentNotificationsLength.current) {
      //Scroll to the top
      if (animationContainerElement) animationContainerElement.scrollTo(0, 0);
    }
    currentNotificationsLength.current = newNotificationsLength;
    // eslint-disable-next-line
  }, [newNotificationsLength]);

  return (
    <div
      className={`cc-app-notification-container ${
        notifications.length > 0 &&
        isLocalNotification &&
        "cc-local-notifications"
      }`}
      ref={notificationContainerRef}
    >
      <Slide direction={"down"}>
        {notifications.map((notification) => (
          <CCAppNotificationItem
            key={notification.id}
            {...notification}
            onUnmount={(notification) => {
              removeNotification(notification.id, false);
            }}
            onClose={(notification) => {
              removeNotification(notification.id);
            }}
          />
        ))}
      </Slide>
    </div>
  );
};
