import { AmsMenu } from "@app/products/ams/constants/ams.menu";
import { animalRoute } from "@app/products/animals/route";
import { buildingRoute } from "@app/products/building/route";
import { crmsRoute } from "@app/products/crms/route";
import { crsRoute } from "@app/products/crs/route";
import { cslRoute } from "@app/products/csl/route";
import { eventManagementRoute } from "@app/products/event-management/route";
import { hmRoute } from "@app/products/hm/route";
import { localLawsRoute } from "@app/products/local-laws/route";
import { parkingPermitsRoute } from "@app/products/parking-permits/route";
import { propertyRoute } from "@app/products/property/route";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { townPlanningRoute } from "@app/products/town-planning/route";
import { wasteWaterRoute } from "@app/products/waste-water/route";
import { ContactsMenu } from "@common/constants/contacts.menu";
import { GeneralRoute } from "@common/constants/menu";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { actionsRoute } from "@common/pages/actions/route";
import { reportsRoute } from "@common/pages/report/route";
import { settingsRoute } from "@common/pages/settings/route";
import { globalStoreInstance } from "@common/stores/global/store";
import { getMenuItems } from "@common/utils/common";
import { IMainMenuItem } from "@components/layout/model";

export interface IProductControl {
  name: string;
  key: string;
  menu: IMainMenuItem[];
  root: string;
  type?: string;
  flag?: boolean;
}
export const getProductTitle = (
  productEnum: string,
  customValue?: string
): string => {
  if (customValue) {
    return customValue;
  }
  const result = globalStoreInstance.getActiveProduct(productEnum)
    ?.Product_Label
    ? globalStoreInstance.getActiveProduct(productEnum)?.Product_Label
    : getProductLocalName(productEnum);

  return result || "";
};

export const getProductLocalName = (productEnum: string) => {
  for (const item of productControlMapping) {
    if (item.key === productEnum) {
      return item.name;
    }
  }
  return productEnum;
};

export const productControlMapping: IProductControl[] = [
  {
    name: "ANIMALS",
    key: PRODUCT_TYPE.Animals,
    menu: getMenuItems(animalRoute.children, `/${animalRoute.path}`),
    root: GeneralRoute.ANIMALS_PATH,
  },
  {
    name: "APPOINTMENT",
    key: PRODUCT_TYPE.AppointmentBookingSystem,
    menu: [],
    root: GeneralRoute.APPOINTMENT_PATH,
  },
  {
    name: "ASSET MANAGER",
    key: PRODUCT_TYPE.CommunityAsset,
    menu: AmsMenu,
    root: GeneralRoute.AMS_PATH,
  },
  {
    name: "BUILDING",
    key: PRODUCT_TYPE.Building,
    menu: getMenuItems(buildingRoute.children, `/${buildingRoute.path}`),
    root: GeneralRoute.BUILDING_PATH,
  },
  {
    name: "CRMS",
    key: PRODUCT_TYPE.CustomerService,
    menu: getMenuItems(crmsRoute.children, `/${crmsRoute.path}`),
    root: GeneralRoute.CRMS_PATH,
  },
  {
    name: "CRS",
    key: PRODUCT_TYPE.CRS,
    menu: getMenuItems(crsRoute.children, `/${crsRoute.path}`),
    root: GeneralRoute.CRS_PATH,
  },
  {
    name: "CSL",
    key: PRODUCT_TYPE.CSM,
    menu: getMenuItems(cslRoute.children, GeneralRoute.CSL_PATH),
    root: GeneralRoute.CSL_PATH,
  },
  // {
  //   name: "EVENT MANAGEMENT",
  //   key: PRODUCT_TYPE.EventManagementSystem,
  //   menu: EventManagementMenu,
  //   root: GeneralRoute.EVENTMANAGEMENT_PATH,
  // },
  {
    name: "EVENT MANAGEMENT",
    key: PRODUCT_TYPE.EventManagementSystem,
    menu: getMenuItems(
      eventManagementRoute.children,
      `/${eventManagementRoute.path}`
    ),
    root: GeneralRoute.EVENTMANAGEMENT_PATH,
  },
  {
    name: "HEALTH MANAGER",
    key: PRODUCT_TYPE.HealthManager,
    menu: getMenuItems(hmRoute.children, `/${hmRoute.path}`),
    root: GeneralRoute.HEALTH_MANAGER,
  },
  {
    name: "PARKING PERMITS",
    key: PRODUCT_TYPE.DISRES,
    menu: getMenuItems(
      parkingPermitsRoute.children,
      `/${parkingPermitsRoute.path}`
    ),
    root: GeneralRoute.PARKING_PERMITS_PATH,
  },
  {
    name: "LOCAL LAWS",
    key: PRODUCT_TYPE.LLPermits,
    menu: getMenuItems(localLawsRoute.children, `/${localLawsRoute.path}`),
    root: GeneralRoute.LOCALLAWS_PATH,
  },
  {
    name: "PROPERTY",
    key: PRODUCT_TYPE.CommunityProperty,
    menu: getMenuItems(propertyRoute.children, GeneralRoute.PROPERTY_PATH),
    root: GeneralRoute.PROPERTY_PATH,
  },
  {
    name: "WASTE WATER",
    key: PRODUCT_TYPE.WasteWater,
    menu: getMenuItems(wasteWaterRoute.children, `/${wasteWaterRoute.path}`),
    root: GeneralRoute.WASTEWATER_PATH,
  },
  {
    name: "TOWN PLANNING",
    key: PRODUCT_TYPE.TownPlanning,
    menu: getMenuItems(
      townPlanningRoute.children,
      `/${townPlanningRoute.path}`
    ),
    root: GeneralRoute.TOWNPLANNING_PATH,
  },
  {
    name: "PPR",
    key: PRODUCT_TYPE.TownPlanning,
    menu: getMenuItems(pprRoute.children, `/${pprRoute.path}`),
    root: GeneralRoute.TOWNPLANNING_PATH,
  },
  {
    name: "ACTIONS",
    key: PRODUCT_TYPE.Action,
    menu: getMenuItems(actionsRoute.children, `/${actionsRoute.path}`),
    root: GeneralRoute.ACTIONS_PATH,
    type: "core",
  },
  {
    name: "CONTACTS",
    key: PRODUCT_TYPE.Contact,
    menu: ContactsMenu,
    root: GeneralRoute.CONTACTS_PATH,
    type: "core",
  },
  {
    name: "REPORTS",
    key: PRODUCT_TYPE.Reports,
    menu: getMenuItems(reportsRoute.children, `/${reportsRoute.path}`),
    root: GeneralRoute.REPORT_PATH,
    type: "core",
  },
  {
    name: "SETTINGS",
    key: PRODUCT_TYPE.Core,
    menu: getMenuItems(settingsRoute.children, `/${settingsRoute.path}`),
    root: GeneralRoute.SETTINGS_PATH,
    type: "core",
  },
];
