import { isSuccessResponse } from "@common/apis/util";
import { getPPRDashboard } from "@common/pages/home/components/townplanning/api";
import {
  IConfigActivitiesTownPlanning,
  IProcessDataPPRDashboardEnquiriesByDueStatus,
  PPRDashboard,
  PPRDashboardActivities,
  PPRDashboardCurrentReferralsByRegion,
  PPRDashboardInsights,
  PPRDashboardPlanningReferralResults,
} from "@common/pages/home/components/townplanning/model";
import {
  processDataActivitiesTownPlanning,
  processDataEnquiriesByDueStatus,
} from "@common/pages/home/components/townplanning/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class TownPlanningDashboardStore {
  private _townPlanningActivities?: IConfigActivitiesTownPlanning[] = undefined;
  private _currentReferralsByRegion: PPRDashboardCurrentReferralsByRegion[] =
    [];
  private _planningReferralResults: PPRDashboardPlanningReferralResults[] = [];
  private _enquiriesByDueStatus?: IProcessDataPPRDashboardEnquiriesByDueStatus =
    undefined;
  private _queryDateTimeTownPlanningDashboard: string = "";
  private _isLoadingDashboardTownPlanning: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingDashboardTownPlanning() {
    return this._isLoadingDashboardTownPlanning;
  }
  setIsLoadingDashboardTownPlanning = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDashboardTownPlanning = isLoading;
    });
  };

  get townPlanningActivities() {
    return toJS(this._townPlanningActivities);
  }
  setTownPlanningActivities = (
    townPlanningActivities?: IConfigActivitiesTownPlanning[]
  ) => {
    runInAction(() => {
      this._townPlanningActivities = townPlanningActivities;
    });
  };

  get currentReferralsByRegion() {
    return toJS(this._currentReferralsByRegion);
  }
  setCurrentReferralsByRegion = (
    currentReferralsByRegion: PPRDashboardCurrentReferralsByRegion[]
  ) => {
    runInAction(() => {
      this._currentReferralsByRegion = currentReferralsByRegion;
    });
  };

  get planningReferralResults() {
    return toJS(this._planningReferralResults);
  }
  setPlanningReferralResults = (
    planningReferralResults: PPRDashboardPlanningReferralResults[]
  ) => {
    runInAction(() => {
      this._planningReferralResults = planningReferralResults;
    });
  };

  get enquiriesByDueStatus() {
    return toJS(this._enquiriesByDueStatus);
  }
  setEnquiriesByDueStatus = (
    enquiriesByDueStatus?: IProcessDataPPRDashboardEnquiriesByDueStatus
  ) => {
    runInAction(() => {
      this._enquiriesByDueStatus = enquiriesByDueStatus;
    });
  };

  get queryDateTimeTownPlanningDashboard() {
    return toJS(this._queryDateTimeTownPlanningDashboard);
  }
  setQueryDateTimeTownPlanningDashboard = (queryDateTime: string) => {
    runInAction(() => {
      this._queryDateTimeTownPlanningDashboard = queryDateTime;
    });
  };

  //Action
  loadTownPlanningDashboard = async () => {
    this.setIsLoadingDashboardTownPlanning(true);
    const response = await getPPRDashboard();
    if (isSuccessResponse(response)) {
      const data: PPRDashboard = response.data;
      const queryDateTime: string = data?.QueryDateTime;
      const activities: PPRDashboardActivities = data?.Activities;
      const insights: PPRDashboardInsights = data?.Insights;

      if (activities) {
        const newActivities: IConfigActivitiesTownPlanning[] =
          processDataActivitiesTownPlanning(activities);
        this.setTownPlanningActivities(newActivities);
      }

      if (queryDateTime)
        this.setQueryDateTimeTownPlanningDashboard(queryDateTime);
      if (insights) {
        const {
          EnquiriesByDueStatus,
          CurrentReferralsByRegion,
          OverallPlanningReferralResults,
        } = insights;
        const dataEnquiriesByDueStatus =
          processDataEnquiriesByDueStatus(EnquiriesByDueStatus);

        if (dataEnquiriesByDueStatus)
          this.setEnquiriesByDueStatus(dataEnquiriesByDueStatus);
        if (EnquiriesByDueStatus)
          this.setCurrentReferralsByRegion(CurrentReferralsByRegion);
        if (OverallPlanningReferralResults)
          this.setPlanningReferralResults(OverallPlanningReferralResults);
      }
    }
    this.setIsLoadingDashboardTownPlanning(false);
  };
}

export const townPlanningDashboardStore = new TownPlanningDashboardStore();
const townPlanningDashboardStoreContext = createContext(
  townPlanningDashboardStore
);
export const useTownPlanningDashboardStore = () => {
  return useContext(townPlanningDashboardStoreContext);
};
