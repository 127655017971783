import { NumberCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/custom-cell/number-cell/_index";
import { PercentageCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/custom-cell/percentage-cell/_index";
import { RebateEntitleCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/custom-cell/rebate-entitle-cell/_index";
import { DTO_RebateType_RatingPeriod } from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { IconEditCell } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/components/icon-edit-cell/_index";
import {
  BOOLEAN_FORMAT,
  DECIMAL_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_RebateType_RatingPeriod>();
export const colRebateToBeEntitled: IColumnFields[] = [
  {
    field: nameOf("Is_Entitle"),
    title: "Entitle?",
    format: ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS,
    editable: true,
    editCell: RebateEntitleCell,
  },
  {
    field: nameOf("RT_Name"),
    title: "Rebate Type",
  },
  {
    field: nameOf("RT_Description"),
    title: "Description",
  },
  {
    field: nameOf("RTRP_Rate"),
    title: "Rate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("RT_Is_ParcelBased"),
    title: "Is Lot Based",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Is_ConcessionHeld"),
    title: "Concession Card Held",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ApplyCurtilage"),
    title: "Apply Curtilage",
    format: BOOLEAN_FORMAT.BOOLEAN,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOf("OverrideCurtilage"),
    title: "Override Curtilage",
    format: BOOLEAN_FORMAT.BOOLEAN,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOf("CurtilageRate"),
    title: "Curtilage Rate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    editable: true,
    editCell: PercentageCell,
  },
  {
    field: nameOf("CurtilateArea"),
    title: "Assess Land Area",
    format: DECIMAL_FORMAT.DECIMAL_2_DIGIT,
    editable: true,
    editCell: NumberCell,
  },
  {
    field: nameOf("Percentage"),
    title: "Percentage of Full Rebate",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    editable: true,
    editCell: PercentageCell,
  },
  {
    field: nameOf("Rebate_Type_Id"),
    title: "Rebate Type ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
