import {
  apiCoreAddressGetMapshareByUserId,
  apiCoreAddressMapshare,
  apiCoreAddressMapshareCandidate,
} from "@app/core/components/common/utils";
import {
  DELWPMapshareServiceCandidateAddress,
  DELWPMapshareServiceSuggestions,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { Address } from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
//@ TODO: Handle error when unify the API respone structure with onshore team
export const getAddressMapShare = async (
  address: Address
): Promise<APIResponse<DELWPMapshareServiceSuggestions | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<DELWPMapshareServiceSuggestions>(
      apiCoreAddressMapshare(),
      { ...address }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCandidateAddress = async (
  key: string
): Promise<APIResponse<DELWPMapshareServiceCandidateAddress | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DELWPMapshareServiceCandidateAddress>(
      apiCoreAddressMapshareCandidate(key)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getOriginalAddress = async (
  userfld: string
): Promise<APIResponse<Address | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Address>(
      apiCoreAddressGetMapshareByUserId(userfld)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
