import { IPreferred } from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class PreferredStore {
  private _preferred: IPreferred = {
    usePreferred: false,
    useEmail: true,
    useSMS: false,
    sendCopyToMe: false,
    sendCopyToMeBCC: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get preferred() {
    return this._preferred;
  }

  setPreferred = (preferred: IPreferred) => {
    runInAction(() => {
      this._preferred = preferred;
    });
  };
  //Action
  resetStore = () => {
    runInAction(() => {
      this._preferred = {
        usePreferred: false,
        useEmail: true,
        useSMS: false,
        sendCopyToMe: false,
        sendCopyToMeBCC: false,
      };
    });
  };

  loadPreferred = async () => {
    this.setPreferred({
      usePreferred: false,
      useEmail: true,
      useSMS: false,
      sendCopyToMe: false,
      sendCopyToMeBCC: false,
    });
  };
}

const toStepContext = createContext(new PreferredStore());
export const useToStepStore = () => {
  return useContext(toStepContext);
};
