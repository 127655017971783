import { ISubdivisionDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/subdivision-detail/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCInputPicker,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import pluralize from "pluralize";
import React from "react";

const nameOf = nameOfFactory<ISubdivisionDetail>();

export interface ISubdivisionDetailProps extends ICCInputPickerProps {}
export const SubdivisionDetail = (props: ISubdivisionDetailProps) => {
  return (
    <CCInputPicker
      {...props}
      textProduce={(value: ISubdivisionDetail) =>
        value.LD_Allotments
          ? `${value.LD_Allotments} ${pluralize(
              "Allotment",
              value.LD_Allotments
            )}`
          : ""
      }
      customDialog={(value: ISubdivisionDetail, onClose, onSubmit) => (
        <Form
          initialValues={value}
          key={JSON.stringify(value)}
          onSubmit={onSubmit}
          render={(formRenderProps: FormRenderProps) => {
            const { valid, onSubmit: onFormSubmit, modified } = formRenderProps;
            return (
              <CCDialog
                titleHeader={"Subdivision Details"}
                onClose={onClose}
                maxWidth="30%"
                height={"auto"}
                bodyElement={
                  <div className="cc-form">
                    <FormElement className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Number of allotments
                          </label>
                          <Field
                            name={nameOf("LD_Allotments")}
                            placeholder={"Number of allotments"}
                            component={CCNumericTextBox}
                            format={NUMBER_FORMAT.NUMBER2}
                          />
                        </div>
                      </div>

                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">DoT stat of comp</label>
                          <Field
                            name={nameOf("LD_StatementOfCompliance")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      </div>

                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">DoT certified</label>
                          <Field
                            name={nameOf("LD_Certified")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      </div>
                    </FormElement>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      onClick={onFormSubmit}
                      type={"submit"}
                      disabled={!valid || !modified}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            );
          }}
        />
      )}
    />
  );
};
