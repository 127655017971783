export const SITE_USER_ROUTE = "/settings/security/site-users";
export const DEFAULT_THEME_NAME = "Default";
export const readyTheme = {
  Theme_Name: "ReadyCommunity",
  Theme_DisplayName: "Ready",
};
export const centralThemeCondition = {
  Platform: "Central",
  Sys_DBRowState: "Active",
};
