import { observable, makeObservable } from "mobx";

export interface IEditingQuestion {
    groupName: string;
    questionNo: number;
}

export class EditingQuestion implements IEditingQuestion{
    groupName: string;
    questionNo: number;

    constructor(edQuestion: IEditingQuestion) {
        makeObservable(this, {
            groupName: observable,
            questionNo: observable
        });

        this.groupName = edQuestion.groupName;
        this.questionNo = edQuestion.questionNo;
    }

}