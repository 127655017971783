import { loadInspectionByCaseId } from "@app/products/property/fire-prevention/components/action-bar/buttons/re-inspection/api";
import { loadSearchCaseId } from "@app/products/property/fire-prevention/components/dialogs/re-inspection/api";
import {
  ACCEPT_FORMAT,
  colReInspectionCommentsInPreviousCases,
  colReInspectionProperty,
  comboBoxSearchData,
  MAX_FILE_SIZE,
} from "@app/products/property/fire-prevention/components/dialogs/re-inspection/config";
import {
  IReInspectionFormData,
  OUTCOME_TYPE,
} from "@app/products/property/fire-prevention/components/dialogs/re-inspection/model";
import SelectPropertyDialog from "@app/products/property/fire-prevention/components/dialogs/select-property/_index";
import {
  IPropertyData,
  IPropertyGridData,
} from "@app/products/property/fire-prevention/components/dialogs/select-property/model";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT, DATETIME_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isUndefined } from "lodash";
import React, { useRef, useState } from "react";

interface IReInspectionProps {
  oneEnterAndNew: () => void;
  onEnterAndDone: () => void;
  onClose: () => void;
  formData: IReInspectionFormData | undefined;
}

export const ReInspectionDialog = ({
  oneEnterAndNew,
  onEnterAndDone,
  onClose,
  formData: initialValues,
}: IReInspectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [showSelectPropertyDialog, setShowSelectPropertyDialog] =
    useState(false);
  const handleValidateForm: any = (
    event: React.SyntheticEvent<any>,
    formRenderProps: FormRenderProps,
    onEnter: () => void
  ) => {
    if (
      formRenderProps.modified &&
      formRenderProps.valueGetter("Property")?.length > 0
    ) {
      formRenderProps.onSubmit(event);
      onEnter();
      return;
    }

    if (!formRenderProps.valid) return;

    if (formRenderProps.valueGetter("Property").length === 0) {
      notificationRef.current?.pushNotification({
        title: "Property should be selected",
        type: "warning",
      });
    } else {
      notificationRef.current?.pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    }
  };

  const handleSearchCaseId = (
    event: ComboBoxFilterChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    let numLetters = 1;
    const { onChange } = formRenderProps;
    if (event.filter.value.length >= numLetters) {
      onChange("_option.CaseId.Loading", { value: true });
      loadSearchCaseId(event.filter).then((data) => {
        onChange("_option.CaseId.Data", { value: data });
        onChange("_option.CaseId.Loading", { value: false });
      });
    } else {
      onChange("_option.CaseId.Data", { value: [] });
    }
  };

  const onChangeSelectProperty = (
    formRenderProps: FormRenderProps,
    value: IPropertyData
  ) => {
    const newValue: IPropertyGridData = formRenderProps
      .valueGetter("Property")
      .slice()
      .concat(value);

    formRenderProps.onChange("Property", { value: newValue });
  };

  return (
    <div className="cc-re-inspection-dialog cc-form">
      <Form
        initialValues={{ ...formData, ...comboBoxSearchData }}
        onSubmit={(data: any) => {}}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, valueGetter } = formRenderProps;
          const outcomeValue = valueGetter("Outcome");
          const isDisableClearanceBy =
            isUndefined(outcomeValue) ||
            outcomeValue === OUTCOME_TYPE.CLEARED_NO_HAZARD ||
            outcomeValue === OUTCOME_TYPE.NO_HAZARD;

          const handleSearchCaseIdResult = (event: ComboBoxChangeEvent) => {
            onChange("CaseId", { value: null });
            if (!event.value) return;
            setIsLoading(true);
            const caseId = event.value;
            if (!caseId) return;
            loadInspectionByCaseId(caseId).then(
              (data: IReInspectionFormData) => {
                setFormData(data);
                if (!data.CaseId) {
                  notificationRef.current?.pushNotification({
                    title: "Case ID does not exist.",
                    type: "warning",
                  });
                }
                setIsLoading(false);
              }
            );
            onChange("CaseId", { value: event.value });
          };

          return (
            <FormElement>
              <CCDialog
                maxWidth="45%"
                maxHeight="80%"
                titleHeader={"Re-inspection"}
                onClose={onClose}
                bodyElement={
                  isLoading ? (
                    <Loading isLoading />
                  ) : (
                    <div className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">Property</label>
                            <CCGrid
                              className="cc-re-inspection-property"
                              data={valueGetter("Property") || []}
                              columnFields={colReInspectionProperty}
                              primaryField="Id"
                              toolbar={
                                <div className="cc-grid-tools-bar">
                                  <Button
                                    iconClass="fas fa-plus"
                                    title="Select"
                                    onClick={(event: any) => {
                                      event.preventDefault();
                                      setShowSelectPropertyDialog(true);
                                    }}
                                  />
                                </div>
                              }
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Comments in previous cases
                            </label>
                            <CCGrid
                              className="cc-re-inspection-property"
                              data={
                                valueGetter("CommentsInPreviousCases") || []
                              }
                              columnFields={
                                colReInspectionCommentsInPreviousCases
                              }
                              primaryField="Id"
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Comments for this case
                            </label>
                            <Field
                              name="CommentsForThisCase"
                              component={CCTextArea}
                              placeholder="Comments for this case"
                              rows={3}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Inspection details
                            </label>
                            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Case ID</label>
                                  <Field
                                    name={"CaseId"}
                                    filterable
                                    suggest
                                    data={valueGetter("_option.CaseId.Data")}
                                    loading={valueGetter(
                                      "_option.CaseId.Loading"
                                    )}
                                    placeholder="Case ID"
                                    component={CCComboBox}
                                    onFilterChange={(
                                      event: ComboBoxFilterChangeEvent
                                    ) => {
                                      handleSearchCaseId(
                                        event,
                                        formRenderProps
                                      );
                                    }}
                                    onChange={handleSearchCaseIdResult}
                                    validator={requiredValidator}
                                  />
                                </div>
                              </div>
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Date</label>
                                  <Field
                                    name={"Date"}
                                    component={CCDateTimePicker}
                                    format={DATETIME_FORMAT.DATETIME_CONTROL}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Last inspection
                                  </label>
                                  <Field
                                    disabled
                                    name={"LastInspection"}
                                    component={CCDateTimePicker}
                                    format={DATETIME_FORMAT.DATETIME_CONTROL}
                                  />
                                </div>
                              </div>
                              <div className="cc-form-cols-1">
                                <div className="cc-field">
                                  <label className="cc-label">Outcome</label>
                                  <Field
                                    name={"Outcome"}
                                    data={valueGetter("OutcomeValues")}
                                    component={CCSearchComboBox}
                                    textField={nameOfLov("Name")}
                                    dataItemKey={nameOfLov("Code")}
                                    value={getDropdownValue(
                                      valueGetter("Outcome"),
                                      valueGetter("OutcomeValues"),
                                      nameOfLov("Code")
                                    )}
                                    onChange={(event: ComboBoxChangeEvent) => {
                                      onChange("Outcome", {
                                        value: event.target.value?.Code ?? null,
                                      });
                                    }}
                                  />
                                </div>

                                {!isDisableClearanceBy && (
                                  <React.Fragment>
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Clearance
                                      </label>
                                      <Field
                                        name={"Clearance"}
                                        data={valueGetter("ClearanceValues")}
                                        component={CCSearchComboBox}
                                        textField={nameOfLov("Name")}
                                        dataItemKey={nameOfLov("Code")}
                                        value={getDropdownValue(
                                          valueGetter("Clearance"),
                                          valueGetter("ClearanceValues"),
                                          nameOfLov("Code")
                                        )}
                                        onChange={(
                                          event: ComboBoxChangeEvent
                                        ) => {
                                          onChange("Clearance", {
                                            value:
                                              event.target.value?.Code ?? null,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="cc-field">
                                      <label className="cc-label">By</label>
                                      <Field
                                        name={"ClearanceBy"}
                                        format={DATE_FORMAT.DATE_CONTROL}
                                        component={CCDatePicker}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Additional notes
                                  </label>
                                  <Field
                                    name="AdditionalNotes"
                                    component={CCTextArea}
                                    placeholder="Additional notes"
                                    rows={3}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Specific instructions
                                  </label>
                                  <Field
                                    name="SpecificInstructions"
                                    component={CCTextArea}
                                    textField="searchText"
                                    placeholder="Specific instructions"
                                    rows={3}
                                  />
                                </div>
                              </div>
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Copy instructions from previous inspection
                                  </label>

                                  <Field
                                    name={
                                      "CopyInstructionsFromPreviousInspection"
                                    }
                                    defaultChecked={valueGetter(
                                      "CopyInstructionsFromPreviousInspection"
                                    )}
                                    component={CCSwitch}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Add attachment
                                  </label>

                                  <Field
                                    name={"AddAttachment"}
                                    defaultChecked={valueGetter(
                                      "AddAttachment"
                                    )}
                                    component={CCSwitch}
                                  />
                                </div>
                              </div>
                              {valueGetter("AddAttachment") && (
                                <div className="cc-form-cols-1">
                                  <div className="cc-field">
                                    <CCUploadFile
                                      batch={false}
                                      multiple
                                      accept={ACCEPT_FORMAT.join(",")}
                                      withCredentials={false}
                                      defaultFiles={[]}
                                      restrictions={{
                                        maxFileSize: MAX_FILE_SIZE,
                                        allowedExtensions: ACCEPT_FORMAT,
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {showSelectPropertyDialog && (
                            <SelectPropertyDialog
                              onClose={() => setShowSelectPropertyDialog(false)}
                              onSubmit={(e) => {
                                onChangeSelectProperty(formRenderProps, e);
                                setShowSelectPropertyDialog(false);
                              }}
                              listPropertyExistedData={valueGetter("Property")}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      name="EnterAndNew"
                      themeColor="primary"
                      className={"cc-dialog-button"}
                      type="button"
                      onClick={(e) => {
                        handleValidateForm(e, formRenderProps, oneEnterAndNew);
                      }}
                      disabled={!formRenderProps.valid || isLoading}
                    >
                      Enter & New
                    </Button>
                    <Button
                      name="EnterAndDone"
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      type="button"
                      onClick={(e) => {
                        handleValidateForm(e, formRenderProps, onEnterAndDone);
                      }}
                      disabled={!formRenderProps.valid || isLoading}
                    >
                      Enter & Done
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    </div>
  );
};
