import { DBRowState } from "@app/products/crms/[id]/model";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  IParentPermitSection,
  Permit,
  Permit_Status,
} from "@app/products/local-laws/[id]/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

interface ISameApplicationPermitButtonProps {
  isDisabled?: boolean;
}
export const SameApplicationPermitButton = observer(
  ({ isDisabled }: ISameApplicationPermitButtonProps) => {
    //#region STORE ========/
    const {
      dataForms,
      isLoadingForm,
      submitFormGetData,
      setMiddlewareSubmitFormOptions,
    } = useFlexibleFormStore();
    const history = useHistory();
    //#endregion STORE =====/

    //#region DEFINED ========/
    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return (
        permitData?.Status_ENUM === Permit_Status.PermitIssued ||
        permitData?.Status_ENUM === Permit_Status.PermitDeactivated ||
        permitData?.Status_ENUM === Permit_Status.PermitRefused ||
        permitData?.Status_ENUM === Permit_Status.PermitWithdrawn
      );
    }, [dataForms]);

    const isRecordDeleted = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return permitData?.Sys_DBRowState === DBRowState.Inactive;
    }, [dataForms]);
    //#endregion DEFINED =====/

    //#region METHOD ========/
    const handleClickButton = async () => {
      setMiddlewareSubmitFormOptions({
        skipCheckModified: true,
      });
      const generalForm = await submitFormGetData("GeneralForm");
      let permit: Permit = generalForm?.Permit;

      if (permit) {
        history.push(`${LOCAL_LAWS_PERMIT_ROUTE}/new`, {
          parent: {
            isSameDetails: true,
            data: permit,
          } as IParentPermitSection,
        });
      }
    };
    //#endregion METHOD =====/

    return (
      <CCNavButton
        title="Same application"
        invisible={!isVisible}
        onClick={handleClickButton}
        disabled={isLoadingForm || isDisabled || isRecordDeleted}
      />
    );
  }
);
