import { ICreateLeakageDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/leakage/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isAfter } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ConfirmDateDialog } from "./../confirm-date-leakage/_index";
import { handleValidateForm } from "./util";

const nameOf = nameOfFactory<ICreateLeakageDialog>();
interface ILeakageDialog {
  onClose: () => void;
  onSubmit: (event: any) => void;
  initialData: any;
}

export const LeakageDialog = observer(
  ({ onClose, onSubmit, initialData }: ILeakageDialog) => {
    const [confirmDialog, setConfirmDialog] = useState<any>();
    const getConfirm = async () => {
      return await new Promise((res) => {
        setConfirmDialog({ actionCallback: res });
      });
    };

    const handleSubmit = (event: any) => {
      if (isAfter(event.values.LeakageOccurredUpTo, new Date())) {
        getConfirm().then((data) => {
          setConfirmDialog(undefined);
          if (data) {
            onSubmit(event.values);
          }
        });
      } else {
        onSubmit(event.values);
      }
    };

    return (
      <>
        <Form
          onSubmitClick={handleSubmit}
          initialValues={initialData}
          validator={handleValidateForm}
          render={(formRenderProps: FormRenderProps) => {
            const { valueGetter } = formRenderProps;
            const leakageOccurredApproxFrom = valueGetter(
              "LeakageOccurredApproxFrom"
            );
            const leakageOccurredUpTo = valueGetter("LeakageOccurredUpTo");

            return (
              <FormElement>
                <CCDialog
                  height="auto"
                  maxWidth="35%"
                  titleHeader={"Meter Leakage"}
                  onClose={onClose}
                  bodyElement={
                    <>
                      <div className="cc-form cc-create-journal-dialog">
                        <section className="cc-field-group">
                          <section>
                            <label className="cc-label">Meter</label>
                            <div className="cc-custom-sub-panel-bar">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Meter ID</label>
                                  <Field
                                    name={nameOf("ID")}
                                    component={CCInput}
                                    readOnly
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Meter number
                                  </label>
                                  <Field
                                    name={nameOf("MeterNumber")}
                                    component={CCInput}
                                    readOnly
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Property address
                                  </label>
                                  <Field
                                    name={nameOf("PropertyAddress")}
                                    component={CCInput}
                                    readOnly
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Account names
                                  </label>
                                  <Field
                                    name={nameOf("AccountNames")}
                                    component={CCInput}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                          <section>
                            <div className="cc-form-cols-2">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Leakage occurred approximately from
                                  <CCTooltip type="validator" />
                                </label>
                                <Field
                                  name={nameOf("LeakageOccurredApproxFrom")}
                                  component={CCDatePicker}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  max={
                                    leakageOccurredUpTo
                                      ? leakageOccurredUpTo
                                      : undefined
                                  }
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Leakage occurred up to
                                  <CCTooltip type="validator" />
                                </label>
                                <Field
                                  name={nameOf("LeakageOccurredUpTo")}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  component={CCDatePicker}
                                  min={
                                    leakageOccurredApproxFrom
                                      ? leakageOccurredApproxFrom
                                      : undefined
                                  }
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Estimated loss in volume
                                </label>
                                <Field
                                  name={nameOf("EstimatedLeakage")}
                                  component={CCNumericTextBox}
                                  value={valueGetter(
                                    nameOf("EstimatedLeakage")
                                  )}
                                  min={0}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Is leakage to be taken off usage for period?
                                </label>
                                <Field
                                  name={nameOf("IsLeakageTaken")}
                                  component={CCSwitch}
                                  checked={valueGetter(
                                    nameOf("IsLeakageTaken")
                                  )}
                                />
                              </div>
                              <div className="cc-col-span-2">
                                <label className="cc-label">
                                  Description of the leakage
                                </label>
                                <Field
                                  name={nameOf("DescriptionOfLeak")}
                                  component={CCTextArea}
                                  value={valueGetter(
                                    nameOf("DescriptionOfLeak")
                                  )}
                                />
                              </div>
                            </div>
                          </section>
                        </section>
                      </div>
                    </>
                  }
                  footerElement={
                    <div className={"cc-dialog-footer-actions-right"}>
                      <Button className={"cc-dialog-button"} onClick={onClose}>
                        Cancel
                      </Button>

                      <Button
                        className={"cc-dialog-button"}
                        disabled={!formRenderProps.valid}
                        themeColor="primary"
                        onClick={formRenderProps.onSubmit}
                      >
                        OK
                      </Button>
                      {confirmDialog && (
                        <ConfirmDateDialog confirmDialog={confirmDialog} />
                      )}
                    </div>
                  }
                />
              </FormElement>
            );
          }}
        />
      </>
    );
  }
);
