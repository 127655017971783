import { getAssessmentDeferments } from "@app/products/property/assessments/[id]/components/child-screens/deferments/api";
import {
  DTO_Assessment_Deferments,
  DTO_Deferments,
} from "@app/products/property/assessments/[id]/components/child-screens/deferments/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AssessmentDefermentsStore {
  private _assessmentDeferments?: DTO_Assessment_Deferments = undefined;
  private _isLoading: boolean = false;
  private _selectedAssessmentDeferments?: DTO_Deferments[] = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get assessmentDeferments() {
    return toJS(this._assessmentDeferments);
  }
  setAssessmentDeferments = (
    assessmentDeferments: DTO_Assessment_Deferments | undefined
  ) => {
    runInAction(() => {
      this._assessmentDeferments = assessmentDeferments;
    });
  };

  get selectedAssessmentDeferments() {
    return toJS(this._selectedAssessmentDeferments);
  }

  setSelectedAssessmentDeferments = (
    selectedAssessmentDeferments: DTO_Deferments[] | undefined
  ) => {
    runInAction(() => {
      this._selectedAssessmentDeferments = selectedAssessmentDeferments;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._assessmentDeferments = undefined;
      this._isLoading = false;
      this._selectedAssessmentDeferments = undefined;
    });
  };

  loadAssessmentDeferments = async (assessmentId: number) => {
    this.resetStore();
    this.setIsLoading(true);
    const data = await getAssessmentDeferments(assessmentId);
    this.setAssessmentDeferments(data?.data);
    this.setIsLoading(false);
  };

  reloadAssessmentDeferments = async (assessmentId: number | undefined) => {
    if (assessmentId) return await this.loadAssessmentDeferments(assessmentId);
    return false;
  };
}

const assessmentDefermentsStoreContext = createContext(
  new AssessmentDefermentsStore()
);
export const useAssessmentDefermentsStore = () => {
  return useContext(assessmentDefermentsStoreContext);
};
