import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IOrgUnit {
  Name?: string;
  OrgLevel?: string;
  OrgUnitName?: string;
  Manager?: string;
}
export interface IManager {
  Name?: string;
  Position?: string;
  OrgStructure?: string;
  Email?: string;
  Phone?: string;
  Mobile?: string;
}

export enum EModeCategoryDialog {
  Category = 1,
  Subcategory = 2,
  SubCategoryLevel3 = 3,
  SubCategoryLevel4 = 4,
}

export interface IShowDialogCategory {
  type: EModeCategoryDialog;
  data: string;
  dataLov?: IKeyValuePacket[];
}
export interface IShowDialogSearchKey {
  data: string;
}
