import { CONTACT_ROUTE } from "@app/core/contacts/_id/constant";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { NoData } from "@components/no-data/NoData";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./_index.scss";

export interface ICommonContactSidebarProps {
  data: ICommonContactItemRender[];
}

export const ContactsSidebar: React.FC<ICommonContactSidebarProps> = observer(
  (props) => {
    return (
      <div className="cc-contact-side-page">
        {(!props.data || props.data.length === 0) && <NoData />}
        {props.data.length > 0 &&
          props.data.map((item: any, index) => (
            <CommonContactItemRender dataItem={item} key={index} />
          ))}
      </div>
    );
  }
);

const CommonContactItemRender = (props: any) => {
  const dataItem: ICommonContactItemRender = props.dataItem;
  const [address, setAddress] = useState<string | undefined>();
  return (
    <div className="cc-contact-card">
      <div className="cc-contact-field cc-contact-field-title">
        <Link
          className={`cc-contact-link ${
            dataItem?.ContactAlert ? "cc-contact-alert" : ""
          }`}
          to={`${CONTACT_ROUTE}/${dataItem.Contact_Id}`}
        >
          {dataItem.DisplayName || "N/A"}
        </Link>
        {dataItem?.Type && <span>&nbsp; ({dataItem.Type})</span>}
      </div>

      <div className="cc-contact-field">
        Email:{" "}
        {(
          <a
            className={`cc-contact-link ${
              dataItem.PreferredMethod_ENUM === "3"
                ? "cc-contact-link-preferred"
                : " "
            }`}
            href={`mailto:${dataItem.Email}`}
            title={
              dataItem.PreferredMethod_ENUM === "3"
                ? "This is the preferred contact method"
                : undefined
            }
          >
            {dataItem.Email}
          </a>
        ) || "N/A"}
      </div>

      <div className="cc-contact-field">
        Mobile:{" "}
        {(
          <a
            title={
              dataItem.PreferredMethod_ENUM === "5"
                ? "This is the preferred contact method"
                : undefined
            }
            href={`tel:${dataItem.Mobile}`}
            className={`cc-contact-link ${
              dataItem.PreferredMethod_ENUM === "5"
                ? "cc-contact-link-preferred"
                : " "
            }`}
          >
            {dataItem.Mobile}
          </a>
        ) || "N/A"}
      </div>
      <div className="cc-contact-field">
        Work phone:{" "}
        {(
          <a
            title={
              dataItem.PreferredMethod_ENUM === "1"
                ? "This is the preferred contact method"
                : undefined
            }
            href={`tel:${dataItem.WorkPhone}`}
            className={`cc-contact-link ${
              dataItem.PreferredMethod_ENUM === "1"
                ? "cc-contact-link-preferred"
                : " "
            }`}
          >
            {dataItem.WorkPhone}
          </a>
        ) || "N/A"}
      </div>
      {dataItem.PostalAddress ? (
        <div className="cc-contact-field">
          Address:{" "}
          {(
            <span
              title={
                dataItem.PreferredMethod_ENUM === "8"
                  ? "This is the preferred contact method"
                  : undefined
              }
              onClick={() => setAddress(dataItem.PostalAddress)}
              className={`cc-contact-link ${
                dataItem.PreferredMethod_ENUM === "8"
                  ? "cc-contact-link-preferred"
                  : " "
              }`}
            >
              {dataItem.PostalAddress}
            </span>
          ) || "N/A"}
        </div>
      ) : null}
      {address && (
        <CCDialog
          titleHeader={"Map View"}
          maxHeight={"80%"}
          maxWidth={"80%"}
          onClose={() => {
            setAddress(undefined);
          }}
          bodyElement={<MapSTripTab address={address} />}
        />
      )}
      <Tooltip anchorElement="target" position="bottom" openDelay={200} />
    </div>
  );
};
