import { useInfoInadequateButtonStore } from "@app/core/further-info/components/buttons/work-flow/info-inadequate/store";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const InfoInadequateButton = observer(() => {
  const { onSubmit, furtherInfo } = useFurtherInfoStore();
  const { isLoading } = useInfoInadequateButtonStore();
  const isVisible = useMemo(() => {
    if (!furtherInfo) return false;
    return (
      [
        FURTHERINFOSTATUS.ExtensionDeclined,
        FURTHERINFOSTATUS.ExtensionRequested,
        FURTHERINFOSTATUS.ExtensionGranted,
        FURTHERINFOSTATUS.InfoReceivedAwaitingAssessment,
      ].includes(furtherInfo?.Status_ENUM) && furtherInfo?.DateInfoReceived
    );
  }, [furtherInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Information inadequate"}
      isLoading={isLoading}
      onClick={onSubmit}
      name={FurtherInfoSubmitActions.InfoInadequate}
    />
  ) : null;
});
