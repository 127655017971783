import { getSupplementaryRatesAssessmentDetail } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/components/detail/api";
import {
  DTO_Supplementary_AssessmentDetail,
  DTO_Supplementary_Assessment_Levy,
  DTO_Supplementary_Assessment_RatingPeriod,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import { nameOfFactory } from "@common/utils/common";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
export const nameOf = nameOfFactory<DTO_Supplementary_AssessmentDetail>();
class SupplementaryRatesDetailAssessmentStore {
  private _supplementaryAssessmentByLevy: DTO_Supplementary_Assessment_Levy[] =
    [];
  private _supplementaryAssessmentByRatingPeriod: DTO_Supplementary_Assessment_RatingPeriod[] =
    [];
  private _isLoadingDetail: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get supplementaryAssessmentByRatingPeriod() {
    return this._supplementaryAssessmentByRatingPeriod;
  }
  setSupplementaryAssessmentByRatingPeriod = (
    supplementaryAssessmentByRatingPeriod: DTO_Supplementary_Assessment_RatingPeriod[]
  ) => {
    runInAction(() => {
      this._supplementaryAssessmentByRatingPeriod =
        supplementaryAssessmentByRatingPeriod;
    });
  };

  get supplementaryAssessmentByLevy() {
    return this._supplementaryAssessmentByLevy;
  }
  setSupplementaryAssessmentByLevy = (
    supplementaryAssessmentByLevy: DTO_Supplementary_Assessment_Levy[]
  ) => {
    runInAction(() => {
      this._supplementaryAssessmentByLevy = supplementaryAssessmentByLevy;
    });
  };

  get isLoadingDetail() {
    return this._isLoadingDetail;
  }
  setIsLoadingDetail = (isLoadingDetail: boolean) => {
    runInAction(() => {
      this._isLoadingDetail = isLoadingDetail;
    });
  };

  //Action
  resetStoreDetail = () => {
    runInAction(() => {
      this._isLoadingDetail = false;
      this._supplementaryAssessmentByRatingPeriod = [];
      this._supplementaryAssessmentByLevy = [];
    });
  };

  loadAssessmentsDetail = async (supplementaryId?: number) => {
    this.setIsLoadingDetail(true);
    if (!supplementaryId) return;
    const data = await getSupplementaryRatesAssessmentDetail(supplementaryId);
    if (!data?.SupplementaryAssessmentChildItems) return;
    const supplementaryAssessmentByLevyArray =
      data.SupplementaryAssessmentChildItems?.supplementaryAssessmentByLevy?.map(
        (element: DTO_Supplementary_Assessment_Levy, index: number) => {
          return { ...element, Id: index };
        }
      );
    this.setSupplementaryAssessmentByLevy(
      supplementaryAssessmentByLevyArray || []
    );
    this.setSupplementaryAssessmentByRatingPeriod(
      data.SupplementaryAssessmentChildItems
        ?.supplementareyAssessmentByRatingPeriod || []
    );
    this.setIsLoadingDetail(false);
  };
}
const supplementaryRatesAssessmentDetailStoreContext = createContext(
  new SupplementaryRatesDetailAssessmentStore()
);
export const useSupplementaryRatesDetailAssessmentStore = () => {
  return useContext(supplementaryRatesAssessmentDetailStoreContext);
};
