import { ContactClassification } from "@app/core/contacts/_id/model";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { colContactPicker } from "@common/pages/profile/components/input-picker/officer-picker/config";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

const nameOf = nameOfFactory<Svc_ContactDetails>();

export interface IOfficerPickerProps extends ICCInputPickerProps {
  onPickOfficer: (
    value: IOfficer | Svc_ContactDetails | null,
    name: string
  ) => void;
  title?: string;
  selectableMode?: "single" | "multiple" | "none";
  classification?: ContactClassification;
}
export const ContactPicker = (props: IOfficerPickerProps) => {
  const {
    title,
    selectableMode,
    classification = ContactClassification.SiteUser,
    onChange,
    onError,
    onPickOfficer,
    name,
    textField,
    textProduce,
    valueField,
    valueProduce,
    ...others
  } = props;

  const handleOnChangeOfficer = (event: ICCInputPickerChangeEvent) => {
    const value: IOfficer | null | Svc_ContactDetails = event.value;
    if (onPickOfficer) onPickOfficer(value, name ?? "");
  };

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: title ?? "Pick Officer",
        maxWidth: "60%",
      }}
      grid={{
        columnFields: colContactPicker,
        primaryField: nameOf("Id"),
        selectableMode: selectableMode ?? "single",
        dataUrl: `/odata/core/internal/contacts/GetContactsByClassification(classification=${classification})?$count=true&`,
        isAutoHiddenPager: false,
        itemPerPage: 10,
      }}
      onChange={handleOnChangeOfficer}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
    />
  );
};
