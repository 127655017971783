import { PRODUCT_TYPE } from "@common/constants/productType";
import AssetManagementActivity from "@common/pages/home/components/activity/components/ams-activity/_index";
import HealthManagerActivity from "@common/pages/home/components/activity/components/hma-activity/_index";
import LocalLawsActivity from "@common/pages/home/components/activity/components/local-laws-activity/_index";
import AnimalsActivity from "@common/pages/home/components/animals/activities/_index";
import CrmsActivity from "@common/pages/home/components/crms/activities/_index";
import { PropertyActivity } from "@common/pages/home/components/property/activities/_index";
import { TownPlanningActivity } from "@common/pages/home/components/townplanning/activities/_index";
import { IDashboardModelActivity } from "@common/pages/home/model";
import { useGlobalStore } from "@common/stores/global/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IActivity {
  activityData: IDashboardModelActivity;
}

export const Activity = observer(({ activityData }: IActivity) => {
  const { isVisibleAndIsCommunityCentral, productsActive } = useGlobalStore();

  const [currentLoadedBlock, setCurrentLoadedBlock] = useState(0);

  const [inspectionThisMonth, setInspectionThisMonth] = useState(
    activityData.inspectionThisMonth
  );
  const [inspectionDueThisWeek, setInspectionDueThisWeek] = useState(
    activityData.inspectionDueThisWeek
  );
  const [newRegistrations, setNewRegistrations] = useState(
    activityData.newRegistrations
  );
  const [inspectionFinished, setInspectionFinished] = useState(
    activityData.inspectionFinished
  );

  //@TODO : Asset Property

  const isVisibleTownPlanning =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.TownPlanning) === "visible";
  const isVisibleHealthManager =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.HealthManager) === "visible";
  const isVisibleCommunityAsset =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CommunityAsset) === "visible";
  const isVisibleLLPermits =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.LLPermits) === "visible";
  const isVisibleCommunityProperty =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CommunityProperty) ===
    "visible";
  const isVisibleCrmsActivity =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CustomerService) === "visible";
  const isVisibleAnimalsActivity =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.Animals) === "visible";

  useEffect(() => {
    setInspectionThisMonth(activityData.inspectionThisMonth);
    setInspectionDueThisWeek(activityData.inspectionDueThisWeek);
    setNewRegistrations(activityData.newRegistrations);
    setInspectionFinished(activityData.inspectionFinished);
  }, [activityData]);

  useEffectOnce(() => {
    let productCounter = 0;
    if (isVisibleHealthManager) {
      productCounter++;
    }
    if (isVisibleCommunityAsset) {
      productCounter++;
    }
    if (isVisibleLLPermits) {
      productCounter++;
    }
    setCurrentLoadedBlock(productCounter);
  });
  return (
    <div className="cc-home-section cc-activity-section">
      <div className="cc-section-heading">Activities</div>
      <div
        className="cc-section-content"
        style={Object.assign(
          currentLoadedBlock < 3
            ? { columnGap: "8rem" }
            : { justifyContent: "space-between" }
        )}
      >
        {productsActive.length > 0 ? (
          <>
            {isVisibleHealthManager && (
              <HealthManagerActivity
                inspectionThisMonth={inspectionThisMonth}
                inspectionDueThisWeek={inspectionDueThisWeek}
                newRegistrations={newRegistrations}
                inspectionFinished={inspectionFinished}
              />
            )}
            {isVisibleTownPlanning && <TownPlanningActivity />}
            {isVisibleCrmsActivity && <CrmsActivity />}
            {isVisibleAnimalsActivity && <AnimalsActivity />}
            {isVisibleCommunityAsset && <AssetManagementActivity />}
            {isVisibleLLPermits && (
              <LocalLawsActivity
                permitsThisMonth={5122}
                renewalsDueThisWeek={211}
                newRegistrations={125}
              />
            )}
            {isVisibleCommunityProperty && <PropertyActivity />}
          </>
        ) : (
          <Loading isLoading />
        )}
      </div>
    </div>
  );
});
