import { DTO_Document_Info } from "@app/products/property/components/action-bar/button/view-document/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postViewDocument = async (
  documentId: number | string
): Promise<APIResponse<DTO_Document_Info | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/document/${documentId}/view`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
