import { DTO_DeferredDuty_Account } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/model";
import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";

export enum EKeysOfStepsAddDDTransaction {
  DeferredDutyJournal = "Transaction",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfStepsAddDDTransaction = [
  EKeysOfStepsAddDDTransaction.DeferredDutyJournal,
];

export interface DTO_Workflow_JournalAddDDTransaction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalAddDDTransaction;
}

export interface DTO_WorkflowDetail_JournalAddDDTransaction
  extends DTO_WorkflowDetail_Base {
  JournalNumber: number;
  Transaction: DTO_DDTransaction;
  Accounts: DTO_DeferredDuty_Account[];
}

export interface DTO_DDTransaction {
  AccountId: number;
  TransactionTypeId: number | null;
  TransactionDate: Date | null;
  Reference: string;
  Description: string;
  Notes: string;
  DeferredDutyAmount: number | null;
  InterestAmount: number | null;
  OtherAmount: number | null;
}

export interface RequestJournalAddDDTransactionObj {
  JournalNumber: number;
}

export interface ELookupAccountSearchBy {}
