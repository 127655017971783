import { CSL_SYSTEM_ADMIN_CONDITIONS_ROUTE } from "@app/products/csl/system-admin/conditions/[id]/constant";

export const colCSLSystemAdminConditions = [
  {
    field: "Group",
    title: "Group",
    linkTo: (dataItem: any) =>
      `${CSL_SYSTEM_ADMIN_CONDITIONS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "SortIndex",
    title: "Sort",
  },
  {
    field: "Title",
    title: "Title",
  },
  {
    field: "Condition",
    title: "Condition",
  },
];
