import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAddInvoiceItemButtonProps {
  disabled?: boolean;
  visible?: boolean;
}

export const AddInvoiceItemButton = observer(
  ({ disabled, visible = true }: IAddInvoiceItemButtonProps) => {
    const { lstExportedFeeIDs, onSubmit } = useInvoiceStore();

    if (!visible || (lstExportedFeeIDs && lstExportedFeeIDs.length > 0))
      return null;

    return (
      <Button
        type="button"
        iconClass="fas fa-plus"
        title="Add invoice item"
        onClick={onSubmit}
        name={ActionSubmitActions.AddInvoiceItem}
        disabled={disabled}
      />
    );
  }
);
