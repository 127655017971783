import { PROPERTY_PARCEL_REGISTER_ALL_LIST_VIEW_URL } from "@app/products/property/parcels/list/constant";
import { VO_Parcel } from "@app/products/property/parcels/list/model";
import { GridDetailParcel } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/_index";
import { nameOfFactory } from "@common/utils/common";
import React from "react";
import { colDetailParcel } from "./config";
const nameOf = nameOfFactory<VO_Parcel>();
export const DetailParcel = (props: any) => {
  return (
    <GridDetailParcel
      className="cc-detail-parcel-locality-street-address"
      dataUrl={`${PROPERTY_PARCEL_REGISTER_ALL_LIST_VIEW_URL}$filter=Street_Locality_Id eq ${props.dataItem.Street_Locality_Id}&`}
      columnFields={colDetailParcel}
      primaryField={nameOf("Parcel_Id")}
    />
  );
};
