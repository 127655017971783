import { ServiceStandardCategory } from "@app/products/crms/system-admin/categories/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const loadCategoryById = async (
  id: number
): Promise<APIResponse<ServiceStandardCategory | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ServiceStandardCategory>(
      `api/crms/internal/system-admin/category/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveCategory = async (
  serviceStandardCategory: ServiceStandardCategory
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/crms/internal/system-admin/category",
      serviceStandardCategory
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
