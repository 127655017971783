import { useJournalStore } from "@app/products/property/journals/[id]/store";
import Loading from "@components/loading/Loading";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { PropertyJournalFormElement } from "./components/form-element/_index";

export const PropertyJournalForm = observer((): ReactElement => {
  const { journal, isLoading } = useJournalStore();
  if (isLoading) return <Loading isLoading />;
  return (
    <div className="cc-form">
      <Form
        // onSubmit={handleSubmit}
        initialValues={journal}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyJournalFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
