import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { ChatSidebar } from "@app/core/chat/ask-ai/reference-sidebar/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AddConditionWithParentButton } from "@app/core/condition/components/buttons/add-condition/_index";
import { AddStdConditionButton } from "@app/core/condition/components/buttons/add-standard-condition/_index";
import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { AlertsButton } from "@app/core/contacts/components/buttons/alert/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { AddInspectionsWithParentButton } from "@app/core/inspections/inspections-button/_index";
import { AddInvoiceWithParentButton } from "@app/core/invoice/invoice-button/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddNoticeWithParentButton } from "@app/core/notices/notices-button/_index";
import { AddRecallWithParentButton } from "@app/core/recall/components/buttons/add-recall/_index";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { HMClosePremisesButton } from "@app/products/hm/premises/[id]/components/buttons/close-premises/_index";
import { HMPremisesRegisterButton } from "@app/products/hm/premises/[id]/components/buttons/register-premises/_index";
import { HMReopenPremisesButton } from "@app/products/hm/premises/[id]/components/buttons/reopen-premises/_index";
import { SavePremisesButton } from "@app/products/hm/premises/[id]/components/buttons/save/_index";
import { PremiseForm } from "@app/products/hm/premises/[id]/components/child-screens/general/_index";
import { AccordionsHmPremises } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premises-accordions/_index";
import { generateBadge } from "@app/products/hm/premises/[id]/components/forms/exist/util";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseDetailsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/details/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import { HM_PREMISES_GET_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IPremisesCCFormOptions,
  Premises,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { checkIsInactivePremises } from "@app/products/hm/premises/[id]/util";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { renderBubbleUps } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ExistHMManagePremise = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { alertSelectedRows } = useAlertsAccordionStore();
  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
  });
  const allowSaveAndEdit = checkPermissions(FormAction.CORE_ALLOW_EDIT);
  const isAllowedRegistration = checkPermissions(
    FormAction.HM_REGISTERREGISTRATION
  );

  const { checkPermissions: checkPermissionsCore } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Alert,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const isInactive = checkIsInactivePremises();

  const [isShowSlideBar, setIsShowSlideBar] = useState(true);
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const premiseObj = dataForms?.GeneralForm as Svc_Premises;
  const premiseData = premiseObj?.Premises as Premises;
  const premisesCCFormOptions: IPremisesCCFormOptions =
    dataForms?.CCFormOptions;

  const { settings } = useCommonCoreStore();
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );

  const isUseSimpleConditions = getBoolValueSetting(
    settings[ECorporateSettingsField.HealthManager_SimpleConditions]
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: "Health Manager - Premises",
      LinkUrl: managePageUrl,
      LinkText: `Heath Manager - Premises - ${premiseData?.Premises_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HealthManager,
      RecordType_ENUM: RECORDTYPE.HealthManager_Premises,
      Record_ID: premiseData?.Premises_ID ? +premiseData?.Premises_ID : 0,
    },
  ];

  const title = useMemo(() => {
    let result = premiseData?.RegistrationNumber ?? "";
    if (premiseData?.TradingName) {
      result += ` - ${premiseData?.TradingName}`;
    }
    return result;
  }, [premiseData?.RegistrationNumber, premiseData?.TradingName]);

  const isDisabledRegisterPremisesBtn = useMemo(() => {
    return isInactive || !isAllowedRegistration;
  }, [isAllowedRegistration, isInactive]);

  const reloadAlertHM = async () => {
    await fetchApiByAlias(HM_PREMISES_GET_SLIDER);
  };

  const workflowButtonMemorized = useMemo(() => {
    return (
      <CCNavButton
        title={"Workflow"}
        className="cc-workflow-button"
        type="sub-workflow"
      >
        <CCNavButton title="Alerts" type="dropdown">
          <AlertsButton
            recordType={RECORDTYPE.HealthManager_Premises}
            onReload={reloadAlertHM}
            contactId={premiseData?.Premises_ID}
            isDisabled={
              !checkPermissionsCore(FormAction.CORE_ALLOW_NEW) || isInactive
            }
          />
          <AlertsButton
            isModifyButton
            isDisabled={
              !checkPermissionsCore(FormAction.CORE_ALLOW_EDIT) ||
              alertSelectedRows?.length !== 1 ||
              isInactive
            }
            recordType={RECORDTYPE.HealthManager_Premises}
            onReload={reloadAlertHM}
            contactId={premiseData?.Premises_ID}
          />
        </CCNavButton>
        <HMPremisesRegisterButton isDisabled={isDisabledRegisterPremisesBtn} />
        <HMReopenPremisesButton
          isDisabled={
            !checkPermissions(FormAction.CORE_ALLOW_EDIT) ||
            !checkPermissions(FormAction.HM_REOPENPREMISES) ||
            isInactive
          }
        />
        <HMClosePremisesButton
          isDisabled={
            !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION) ||
            !checkPermissions(FormAction.HM_CLOSEPREMISES) ||
            isInactive
          }
        />
      </CCNavButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alertSelectedRows?.length,
    checkPermissionsCore,
    isDisabledRegisterPremisesBtn,
    isInactive,
    premiseData?.Premises_ID,
  ]);

  return (
    <>
      <FormTitle title={title} badge={generateBadge(premiseData)} />
      <CCManagePageActionBar
        leftActions={[
          workflowButtonMemorized,
          <SavePremisesButton isDisabled={isInactive || !allowSaveAndEdit} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION) ||
                isInactive
              }
            />
            <AdditionalContactButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_CONTACT) ||
                isInactive
              }
            />
            <AddAttachmentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              disabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT) ||
                isInactive
              }
            />
            <AddCommentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT) ||
                isInactive
              }
            />
            <CommunicationButtonListView
              recordId={premiseData?.Premises_ID}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              recordType={RECORDTYPE.HealthManager_Premises}
              isDisabled={
                // !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION) || not security in Radium
                isInactive
              }
            />
            <CCNavButton
              title="Conditions"
              id={`cc-nav-${premiseData?.Premises_ID}`}
              type="dropdown"
              invisible={isUseSimpleConditions}
              disabled={
                isInactive || !premiseData?.PremisesType?.Flag_Conditions
              }
            >
              <AddConditionWithParentButton
                id={premiseData?.Premises_ID}
                recordType={RECORDTYPE.HealthManager_Premises}
                productType={PRODUCT_TYPE_NUMBER.HealthManager}
                bubbleUps={renderBubbleUps(
                  premiseData?.Premises_ID,
                  RECORDTYPE.HealthManager_Premises
                )}
                isCondition
                isDisabled={
                  isInactive || !premiseData?.PremisesType?.Flag_Conditions
                }
                title="Custom"
              />
              <AddStdConditionButton
                title="Standard"
                recordType={RECORDTYPE.HealthManager_Premises}
                productType={PRODUCT_TYPE_NUMBER.HealthManager}
                recordID={premiseData?.Premises_ID}
                isDisabled={
                  isInactive || !premiseData?.PremisesType?.Flag_Conditions
                }
              />
            </CCNavButton>
            <AddInspectionsWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_INSPECTION) ||
                isInactive ||
                !premiseData?.PremisesType?.Flag_Inspections
              }
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
            />
            <AddInvoiceWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              isDisabled={isInactive}
              isVisible={isEnableMultiLineInvoice}
            />
            <AddDocumentButton
              dataSetFilter={MAILMERGEDATASET?.HealthManager_Premises}
              recordType={RECORDTYPE.HealthManager_Premises}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              id={premiseData?.Premises_ID}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT) ||
                isInactive
              }
            />
            <AddNoticeWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              isDisabled={
                !checkPermissions(FormAction.CORE_TOOLBAR_ADD_NOTICE) ||
                isInactive ||
                !premisesCCFormOptions?.PremisesType?.Flag_Notices
              }
            />
            <AddRecallWithParentButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              bubbleUps={renderBubbleUps(
                premiseData?.Premises_ID,
                RECORDTYPE.HealthManager_Premises
              )}
              productType={PRODUCT_TYPE_NUMBER.HealthManager}
              isDisabled={isInactive}
            />
            <AddRelatedButton
              id={premiseData?.Premises_ID}
              recordType={RECORDTYPE.HealthManager_Premises}
              disabled={isInactive}
            />
          </CCNavButton>,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={isShowSlideBar}
            onClick={() => {
              setIsShowSlideBar(!isShowSlideBar);
            }}
          />,

          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <CCAppNotification />
          <div className="cc-manage-form-body">
            {premiseData && <PremiseForm />}
            <AccordionsHmPremises
              premiseData={premiseData}
              isRecordDeleted={isInactive}
            />
          </div>
        </div>

        {isShowSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                { title: "Details", component: <PremiseDetailsTab /> },
                {
                  title: "Map",
                  component: (
                    <MapTab
                      address={premiseData?.SiteAddress?.Formatted_SingleLine}
                      zoom={18}
                      objViewOnMapRequest={
                        {
                          ReturnControllName: "GISKey",
                          GISKey: premiseData?.SiteAddress?.AssessmentNo ?? "",
                          ObjAddress: premiseData?.SiteAddress,
                          Module: "",
                          Layer: "",
                          ProductType: ProductType.HealthManager,
                        } as Svc_ViewOnMap
                      }
                      isInManagePage
                    />
                  ),
                },
                { title: "Contacts", component: <PremiseContactsTab /> },
                {
                  title: "History",
                  component: <PremiseHistoryTab />,
                },
                {
                  title: "Ask AI",
                  isVisible: useAskAIServiceStore().isShowAskAI,
                  component: (
                    <ChatSidebar
                      productId={PRODUCT_TYPE_NUMBER.HealthManager}
                    />
                  ),
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
