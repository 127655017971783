import { DTO_AssociatedItem_Certificate } from "@app/products/property/components/associations/components/certificate/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Certificate>();

export const colAssociationsCertificate: IColumnFields[] = [
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("CertificateNumber"),
    title: "Certificate Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("OriginatedDateTime"),
    title: "Date/Time Originated",
    format: DATETIME_FORMAT.DATETIME_CONTROL,
  },
  {
    field: nameOf("ApplicantName"),
    title: "Applicant",
  },
  {
    field: nameOf("ApplicantReference"),
    title: "Applicant Reference",
  },
  {
    field: nameOf("BalanceOwing"),
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("IssuedDate"),
    title: "Issued On",
    format: DATETIME_FORMAT.DATETIME_CONTROL,
  },
  {
    field: nameOf("IssuedBy"),
    title: "Issued By",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Id"),
    title: "Certificate ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
