//should be able to infer a lot of this from the models eventually. starting off similar to how we do catalogues now first.

export type fieldDataType = 'string' | 'number' | 'date' | 'boolean';
export type modelContext = 'assetCategory' | 'catalogue' | 'featureSet';

//so we have a definitionContext and a configurationContext ? eg the definitionContext could be 'assetRegister' but the configurationContext is the 'assetCategory' ?

export interface IModelReference {
  modelName: string; //used something a little more generic than cataloguename
  context: modelContext; //this is so we can discern between say a catalogue and category definition
}

/*maybe this splits then into two objects? e.g. 
- IFieldDefintion (the true baseline defintion)
- IFieldConfiguration (configuration for the field on a given asset category etc) */
export interface IFieldDefinition {
  propertyName: string;
  dataType: fieldDataType;
  labelName: string;
  isMandatory: boolean;
  isReadonly: boolean;
}

//the beauty of this split is we can map data from the same catalogue/feature sets differently in different categories
export interface IFieldConfiguration {
  propertyName: string; //this is what we use to find the IFieldDefinition
  isVisibile: boolean; //indicates if we are showing the field or not
  isReadonly: boolean; //inidicates if user input is allowed or that this may be a calculated / resolve field from other data
  lookupName: string; //if we are using a lookup, which one is it?

  formatString: string; //if we need a format string, say for decimals, dates etc
  placeHolder: string; //some descriptor for the placeholder

  //this probably moves out to the equivalent of screen layout afterwards, but just put here for now
  //groupContainer: string; //name of the group we are displayed in
  //groupContainerOrdinal: number; //just an ordering of the fields
}

export interface IRowFields {
  rowNum: number;
  fields: IFieldCfgScreenReference[];
}

//then we can start to hang rules, validations etc off of fields...this is all getting very bpfish very quickly. leave this bit for now.

//so our model defintion becomes what i was terming 'global' before which is just the baseline definition
export interface IModelDefinition {
  modelReference: IModelReference;
  fields: IFieldDefinition[];
}

export interface IModelConfiguration {
  //ie this modelConfigRef is configuration pertaining to the modelDefRef specified
  //modelConfigRef now just lives on the IModelConfigurationSet
  //modelConfigRef: IModelReference; //eg Roads / assetCategory
  modelDefRef: IModelReference;  //eg AssetRegister / assetCategory
  
  fields: IFieldConfiguration[];
}

export interface IFieldDefModelReference {
  modelDefRef: IModelReference;
  fieldDef : IFieldDefinition;
}

//a pointer to the fieldCfg from a screen component, eg the IGroupContainerLayout
//can use this reference to resolve the IFieldConfiguration instance on the appropriate IModelConfiguration
export interface IFieldCfgScreenReference  {
  modelDefRef: IModelReference;
  propertyName : string;
  ordinalPosition: number;
}


export interface IGroupContainerLayout {
  groupContainerName : string;
  ordinalPosition: number;
  fields : IFieldCfgScreenReference[];

}

export interface IModelConfigurationSet {
  modelConfigRef: IModelReference; //model Reference of the 'primary' entity in this context.
  modelConfigs: IModelConfiguration[]; //collection of all the properties being display from all the related models here

  //this now has all the groups and all the field 'pointers' back to the config.
  //just need to rewrite the code that interprets all this (and mock up some more json to suit)
  groupContainers: IGroupContainerLayout[];
}

export interface IModelData {
  modelReference: IModelReference; //essentially the modelDefRef
  records: any[]; //can probably make this of a type as supplied once i get my head around typescript generics
}

//from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html#keyof-and-lookup-types
export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key]; // Inferred type is T[K]
}

export function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  obj[key] = value;
}

export function hasOwnProperty<X extends {}, Y extends PropertyKey>
  (obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}
