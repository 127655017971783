import { IMetersDetail } from "@app/products/property/registers/[id]/components/child-screens/meters/components/detail/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IMetersDetail>();

export const colMetersDetail: IColumnFields[] = [
  { field: nameOf("Utility"), title: "Utility" },

  { field: nameOf("FromDate"), title: "From Date", format: DATE_FORMAT.DATE },

  { field: nameOf("ToDate"), title: "To Date", format: DATE_FORMAT.DATE },

  {
    field: nameOf("NumberOfDays"),
    title: "Number of Days",
    format: NUMBER_FORMAT.NUMBER2,
  },

  { field: nameOf("AssessmentNumber"), title: "Assessment Number" },

  { field: nameOf("MeterNumber"), title: "Meter Number" },

  {
    field: nameOf("Percentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },

  {
    field: nameOf("GrossUsage"),
    title: "Gross Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },

  {
    field: nameOf("ChargeableUsage"),
    title: "Chargeable Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },

  {
    field: nameOf("DailyAverageUsage"),
    title: "Daily Average Usage",
    format: DECIMAL_FORMAT.DECIMAL_4_DIGIT,
  },

  {
    field: nameOf("TariffTotal"),
    title: "Tariff Total",
    format: CURRENCY_FORMAT.CURRENCY2,
  },

  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY2,
  },

  { field: nameOf("Tariff"), title: "Tariff" },

  { field: nameOf("Usage"), title: "Usage", format: NUMBER_FORMAT.NUMBER2 },

  { field: nameOf("UsageUnit"), title: "Unit" },

  {
    field: nameOf("UsageTariffUnits"),
    title: "Usage (Tariff Units)",
    format: NUMBER_FORMAT.NUMBER2,
  },

  { field: nameOf("UsageTariffUnitsUnit"), title: "Unit" },

  { field: nameOf("Rate"), title: "Rate", format: NUMBER_FORMAT.NUMBER2 },

  {
    field: nameOf("TariffAmount"),
    title: "Tariff Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    field: nameOf("JournalNumber"),
    title: "Journal Number",
  },

  {
    field: nameOf("UsageId"),
    title: "Usage ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
