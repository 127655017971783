import {
  colAlternateAddresses,
  colProprietor,
  colPurchaser,
} from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/form-element/config";
import {
  mockAlternateAddresses,
  mockProprietor,
  mockPurchaser,
} from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/form-element/mock";
import {
  IAlternateAddress,
  IProprietor,
  IPurchaser,
} from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/form-element/model";
import { IECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";

interface IECertificateRequestFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfIPurchaser = nameOfFactory<IPurchaser>();
const nameOfIProprietor = nameOfFactory<IProprietor>();
const nameOfIAlternateAddress = nameOfFactory<IAlternateAddress>();

export const ECertificateRequestFormElement = observer(
  ({ formRenderProps }: IECertificateRequestFormElementProps): ReactElement => {
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    const nameOf = nameOfFactory<IECertificateRequest>();
    const { pushNotification } = useCCAppNotificationStore();
    const { modified, valid, onSubmit, onChange } = formRenderProps;

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (modified) {
        onSubmit(event);
        return;
      }
      if (!valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    return (
      <FormElement className="cc-e-certificate-request-form-body">
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Applicant</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Additional reference</label>
                  <Field
                    readOnly
                    name={nameOf("AdditionalReference")}
                    placeholder={"Additional reference"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Name</label>
                  <Field
                    readOnly
                    name={nameOf("Name")}
                    placeholder={"Name"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Address</label>
                  <Field
                    readOnly
                    name={nameOf("Address")}
                    placeholder={"Address"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <Field
                    readOnly
                    label="Email address"
                    name={nameOf("EmailAddress")}
                    placeholder={"Email address"}
                    component={CCInputEmail}
                    validator={validateEmail}
                    onChangeForm={onChange}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Phone</label>
                  <Field
                    readOnly
                    name={nameOf("Phone")}
                    placeholder={"Phone"}
                    component={CCInputPhone}
                    validator={validatePhoneNumber}
                    onChangeForm={onChange}
                    skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                    mode={EModePhoneNumber.Private}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Fax</label>
                  <Field
                    readOnly
                    name={nameOf("Fax")}
                    placeholder={"Fax"}
                    component={CCInputPhone}
                    validator={validatePhoneNumber}
                    onChangeForm={onChange}
                    skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                    mode={EModePhoneNumber.Fax}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">DX</label>
                  <Field
                    readOnly
                    name={nameOf("DX")}
                    placeholder={"DX"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Country</label>
                  <Field
                    readOnly
                    name={nameOf("Country")}
                    placeholder={"Country"}
                    component={CCInput}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cc-field cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Request registered on</label>
                <Field
                  readOnly
                  name={nameOf("DateRequestRegistered")}
                  placeholder={"Request registered on"}
                  component={CCDateTimePicker}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Settlement date</label>
                <Field
                  readOnly
                  name={nameOf("SettlementDate")}
                  placeholder={"Settlement date"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Time stamp</label>
                <Field
                  readOnly
                  name={nameOf("TimeStamp")}
                  placeholder={"Time stamp"}
                  component={CCDateTimePicker}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Certificate request ID</label>
                <Field
                  readOnly
                  name={nameOf("ID")}
                  placeholder={"Certificate request id"}
                  component={CCInput}
                  value={"62"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Preferred delivery method</label>
                <Field
                  readOnly
                  name={nameOf("PreferredDeliveryMethod")}
                  placeholder={"Preferred delivery method"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Requested certificate</label>
                <Field
                  readOnly
                  name={nameOf("CertificateCode")}
                  placeholder={"Requested certificate"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Proprietor</label>
                <CCGrid
                  data={mockProprietor}
                  primaryField={nameOfIProprietor("Id")}
                  columnFields={colProprietor}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Purchaser</label>
                <CCGrid
                  data={mockPurchaser}
                  primaryField={nameOfIPurchaser("Id")}
                  columnFields={colPurchaser}
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Requested property details</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Display address</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Street locality</label>
                      <Field
                        readOnly
                        name={nameOf("StreetLoc")}
                        placeholder={"Street locality"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Street name</label>
                      <Field
                        readOnly
                        name={nameOf("StreetName")}
                        placeholder={"Street name"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Suburb</label>
                      <Field
                        readOnly
                        name={nameOf("Suburb")}
                        placeholder={"Suburb"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Postcode</label>
                      <Field
                        readOnly
                        name={nameOf("Postcode")}
                        placeholder={"Postcode"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cc-field cc-form-cols-1">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Property PFI</label>
                    <Field
                      readOnly
                      name={nameOf("PropertyPFI")}
                      placeholder={"Property PFI"}
                      component={CCInput}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Parcel PFI</label>
                    <Field
                      readOnly
                      name={nameOf("ParcelPFI")}
                      placeholder={"Parcel PFI"}
                      component={CCInput}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Parcel ID</label>
                    <Field
                      readOnly
                      name={nameOf("ParcelId")}
                      placeholder={"Parcel id"}
                      component={CCInput}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Municipality</label>
                    <Field
                      readOnly
                      name={nameOf("Municipality")}
                      placeholder={"Municipality"}
                      component={CCInput}
                      value={"HOBSONS BAY CITY COUNCIL"}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Parish</label>
                    <Field
                      readOnly
                      name={nameOf("Parish")}
                      placeholder={"Parish"}
                      component={CCInput}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Extra</label>
                    <Field
                      readOnly
                      name={nameOf("Extra")}
                      placeholder={"Extra"}
                      component={CCTextArea}
                      rows={3}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Alternate addresses</label>
                    <CCGrid
                      data={mockAlternateAddresses}
                      primaryField={nameOfIAlternateAddress("Id")}
                      columnFields={colAlternateAddresses}
                    />
                  </div>
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Parsed address</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Unit type</label>
                      <Field
                        readOnly
                        name={nameOf("UnitType")}
                        placeholder={"Unit type"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Unit number 1</label>
                      <Field
                        readOnly
                        name={nameOf("UnitNumber1")}
                        placeholder={"Unit number 1"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Unit number 2</label>
                      <Field
                        readOnly
                        name={nameOf("UnitNumber2")}
                        placeholder={"Unit number 2"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Floor type</label>
                      <Field
                        readOnly
                        name={nameOf("FloorType")}
                        placeholder={"Floor type"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Floor number</label>
                      <Field
                        readOnly
                        name={nameOf("FloorNumber")}
                        placeholder={"Floor number"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Street number 1</label>
                      <Field
                        readOnly
                        name={nameOf("StreetNumber1")}
                        placeholder={"Street number 1"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Street number 2</label>
                      <Field
                        readOnly
                        name={nameOf("StreetNumber2")}
                        placeholder={"Street number 2"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Street name</label>
                      <Field
                        readOnly
                        name={nameOf("PropertyStreetName")}
                        placeholder={"Street name"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Street type</label>
                      <Field
                        readOnly
                        name={nameOf("StreetType")}
                        placeholder={"Street type"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Street suffix</label>
                      <Field
                        readOnly
                        name={nameOf("StreetSuffix")}
                        placeholder={"Street suffix"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Suburb</label>
                      <Field
                        readOnly
                        name={nameOf("PropertySuburb")}
                        placeholder={"Suburb"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Postcode</label>
                      <Field
                        readOnly
                        name={nameOf("PropertyPostcode")}
                        placeholder={"Postcode"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Property name 1</label>
                      <Field
                        readOnly
                        name={nameOf("PropertyName1")}
                        placeholder={"Property name 1"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Property name 2</label>
                      <Field
                        readOnly
                        name={nameOf("PropertyName2")}
                        placeholder={"Property name 2"}
                        component={CCInput}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Location</label>
                      <Field
                        readOnly
                        name={nameOf("LocationDescription")}
                        placeholder={"Location"}
                        component={CCInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
