import { getDisplayTextWithDashes } from "@common/utils/common";

export const PropertyRegisterListBookmark = {
  getBookmarkListViewDisplayName() {
    return "Property - Register";
  },

  getBookmarkListViewDetail() {
    return "Property - Register - List";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
    return `Property - Register ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow.Name, selectedRow.Id]);
  },
};
