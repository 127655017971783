import {
  IViewOnMapByGISButtonProps,
  ViewOnMapByGISButton,
} from "@app/core/view-on-map/components/buttons/view-map-by-gis/_index";
import { GIS } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import MapView from "@components/map/MapView";
import { compact, isArray, isBoolean, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IMapTabProps extends IViewOnMapByGISButtonProps {
  address?: string;
  addressField?: string | string[];
  zoom?: number;
  onError?: (errorMessage: string) => void;
}
export const MapTab = observer(
  ({
    address,
    addressField = "Property_Name_Address_Locality",
    onError,
    zoom = 10,
    //Properties of IViewOnMapByGISButtonProps
    objViewOnMapRequest,
    recordType,
    isInManagePage = undefined,
    isDisabled = false,
  }: IMapTabProps) => {
    const { lastSelectedRow } = useCCProductListViewStore();
    const { settings } = useCommonCoreStore();
    const searchAddress = useMemo(() => {
      if (address) return address;

      let addressSearch = undefined;
      if (lastSelectedRow) {
        if (isArray(addressField)) {
          const addressSearchArr = addressField.map(
            (field) => lastSelectedRow?.[field]
          );
          addressSearch = compact(addressSearchArr).join(", ");
        } else {
          addressSearch = lastSelectedRow?.[addressField];
        }
      }
      return addressSearch;
    }, [address, addressField, lastSelectedRow]);
    const oCorpGIS = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_GIS]
    );

    const isShowMapByGis = useMemo(() => {
      return (
        !isNil(oCorpGIS) &&
        ![
          GIS.None,
          GIS.Exponare,
          GIS.Latitude,
          GIS.MapInfo,
          GIS.GoogleMaps,
        ].includes(oCorpGIS) &&
        isBoolean(isInManagePage)
      );
    }, [oCorpGIS, isInManagePage]);

    return (
      <>
        {isShowMapByGis ? (
          <div className="cc-map-by-gis">
            <ViewOnMapByGISButton
              objViewOnMapRequest={objViewOnMapRequest}
              recordType={recordType}
              isInManagePage={isInManagePage}
              isDisabled={isDisabled}
            />
          </div>
        ) : null}
        <div className="cc-ref-map-container">
          <MapView
            zoom={zoom}
            width="100%"
            height="100%"
            address={searchAddress}
            onError={onError}
          />
        </div>
      </>
    );
  }
);
