import { loadViewConfiguresColumns } from "@app/products/property/api";
import { LotDetailsChildren } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/components/lot-details-children/_index";
import { lotDetailsColumns } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/config";
import { VO_NoticeOfSale_Title } from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOf = nameOfFactory<VO_NoticeOfSale_Title>();
export const LotDetails = (props: GridDetailRowProps) => {
  const { dataItem } = props;

  //Use state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedColumns, setProcessedColumns] = useState<IColumnFields[]>([]);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_NoticeOfSale_Titles,
      lotDetailsColumns
    );
    setIsLoading(false);
    if (Array.isArray(response)) {
      setProcessedColumns(response);
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load failed",
      });
    }
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCGrid
      className="cc-notice-of-sale-list-children-grid"
      dataUrl={`odata/property/internal/noticeofsaletitlesregister?noticeofsale_Id=${dataItem.NoticeofSale_Id}&$count=true&`}
      columnFields={processedColumns}
      primaryField={nameOf("LRS_Title_Id")}
      detail={LotDetailsChildren}
    />
  );
};
