import { RegistrationFeeRulesType } from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAddFeeRuleButtonProps {
  feeRulesType: RegistrationFeeRulesType;
}

export const AddFeeRuleButton = observer(
  ({ feeRulesType }: IAddFeeRuleButtonProps) => {
    const { setupRegistrationFeeRulesDialog } = useAnimalTypeStore();

    const handleOnclickAddRule = () => {
      setupRegistrationFeeRulesDialog(feeRulesType);
    };

    return (
      <Button
        type="button"
        iconClass="fas fa-plus"
        title="Add fee rule"
        onClick={handleOnclickAddRule}
      />
    );
  }
);
