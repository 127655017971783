import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colFeeSchedule: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Regulation,
    title: "Regulation",
    width: 500,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Class,
    title: "Class",
    width: 500,
  },
  {
    field: TownPlanningFieldMapping.Description,
    title: "Description",
    width: 500,
  },
  {
    field: TownPlanningFieldMapping.ApplyTo_Name,
    title: "Apply To",
    width: 500,
  },
];
