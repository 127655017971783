import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatStyleProminent } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

export const mappingGridBalances: any = {
  Arrears: "Arrears",
  Current: "Current",
};

export const colAssessment: IColumnFields[] = [
  {
    field: "BalancesType",
    title: "Type",
  },
  {
    field: "Balance",
    title: "Charges Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "InterestBalance",
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "Total",
    title: "Total",
    width: 150,
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
