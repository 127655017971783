import React, { useState, Fragment } from "react";
import Card from "./Card";
import DropWrapper from "./DropWrapper";
import Col from "./Col";
import { data, statuses } from "./sample-data";
import "./KanbanBoard.scss";

import { PageTitleBackNav } from "../page/PageTitleBackNav";
import { PageTitleHeader } from "../page/PageTitleHeader";
import { PageTitleMenuRow } from "../page/PageTitleMenuRow";
import { PageTitleMenuButton } from "../page/PageTitleMenuButton";

const KanbanBoard = () => {
  const [items, setItems] = useState(data);

  const onDrop = (item: any, monitor: any, theStatus: any) => {
    const mapping = statuses.find((si) => si.status === theStatus);

    setItems((prevState) => {
      const newItems = prevState
        .filter((i) => i.id !== item.id)
        .concat({ ...item, status: theStatus, icon: mapping!.icon });
      return [...newItems];
    });
  };

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const item = items[dragIndex];
    setItems((prevState) => {
      const newItems = prevState.filter((i, idx) => idx !== dragIndex);
      newItems.splice(hoverIndex, 0, item);
      return [...newItems];
    });
  };

  return (
    <Fragment>
      <PageTitleBackNav title="Inspection" />
      <div className="container-fluid pl-4 " style={{ height: "85vh" }}>
        <PageTitleHeader pillheading="001" descriptiveHeading="Kanban Board" />
        <PageTitleMenuRow>
          <PageTitleMenuButton buttonText="Save" />
          <PageTitleMenuButton buttonText="More Options" />
        </PageTitleMenuRow>
        <br />

        <div className={"row-kanban"}>
          {statuses.map((s) => {
            return (
              <div key={s.status} className={"col-wrapper"}>
                <h2 className={"col-header"}>{s.status.toUpperCase()}</h2>
                <DropWrapper onDrop={onDrop} theStatus={s.status}>
                  <Col>
                    {items
                      .filter((i) => i.status === s.status)
                      .map((i, idx) => (
                        <Card
                          key={i.id}
                          item={i}
                          index={idx}
                          moveItem={moveItem}
                          statusDef={s}
                        />
                      ))}
                  </Col>
                </DropWrapper>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default KanbanBoard;
