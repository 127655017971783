import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { dataSerilogSinkType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/logging/forms/serilog-settings/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const SerilogSettingsForm = ({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;

  const data = useDataSetting(configData, [
    "Logging_Serilog_SinkType",
    "Logging_Serilog_SeqUrl",
    "Logging_Serilog_SeqEnvironment",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel
            title={data.Logging_Serilog_SinkType?.Title ?? ""}
            isMandatory={data.Logging_Serilog_SinkType?.IsMandatory}
          />
          <Field
            name={data.Logging_Serilog_SinkType?.FieldName ?? ""}
            component={CCSearchComboBox}
            data={dataSerilogSinkType}
            textField={"Value"}
            dataItemKey={"Key"}
            disabled={!isEditing}
            isUseDefaultOnchange
          />
        </div>
        {valueGetter(data.Logging_Serilog_SinkType?.FieldName as string) ? (
          <>
            <InputSettings
              data={data.Logging_Serilog_SeqUrl}
              isEditing={isEditing}
            />
            <InputSettings
              data={data.Logging_Serilog_SeqEnvironment}
              isEditing={isEditing}
            />
          </>
        ) : null}
      </div>
    </section>
  );
};

export default observer(SerilogSettingsForm);
