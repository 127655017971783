import { DTO_Functionality } from "@app/products/property/assessments/compliance/[id]/model";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
const nameOf = nameOfFactory<DTO_Functionality>();
export const colFunctionalityRestrictions: IColumnFields[] = [
  {
    field: nameOf("FunctionalityName"),
    title: "Functionality",
  },
  {
    field: nameOf("IsRestricted"),
    title: "Restrict",
    cell: (props: GridCellProps) => {
      const { dataItem } = props;
      return (
        <TextCell
          value={dataItem?.RestrictFrom || dataItem?.RestrictTo ? "Yes" : "No"}
        />
      );
    },
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("RestrictFrom"),
    title: "From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RestrictTo"),
    title: "To",
    format: DATE_FORMAT.DATE,
  },
];
