import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IApplicationSettingTextProps {
  enumParam: ECorporateSettingsField;
}

export const ApplicationSettingText = (props: IApplicationSettingTextProps) => {
  const { configData } = useSettingPPRStore();
  const { isEditing } = useSettingPPRManageStore();
  const { enumParam } = props;

  if (!configData) return <></>;
  return (
    configData[enumParam.toString()] && (
      <div className="cc-field">
        <label className="cc-label">
          {configData[enumParam.toString()].Title ?? ""}
          {configData[enumParam.toString()].IsMandatory && (
            <CCTooltip type="validator" position="right" />
          )}
          {configData[enumParam.toString()].HelpText && (
            <CCTooltip
              type="custom"
              content=" "
              position="auto"
              clickToOpen
              customTemplate={
                <>
                  {sanitizeHtml(
                    configData[enumParam.toString()].HelpText ?? ""
                  )}
                </>
              }
            >
              <i className="fa fa-info-circle ml-1 text-accent" />
            </CCTooltip>
          )}
        </label>
        {configData[enumParam.toString()].Description && (
          <label className="cc-settings-description">
            {sanitizeHtml(configData[enumParam.toString()].Description ?? "")}
          </label>
        )}
        <Field
          name={configData[enumParam.toString()].FieldName}
          component={CCInput}
          disabled={!isEditing}
          validator={
            configData[enumParam.toString()].IsMandatory
              ? requiredValidator
              : undefined
          }
        />
      </div>
    )
  );
};
