import {
  colDeferments,
  colDeferredCharges,
} from "@app/products/property/assessments/[id]/components/child-screens/deferments/config";
import {
  DTO_Charges,
  DTO_Deferments,
  DTO_Totals,
} from "@app/products/property/assessments/[id]/components/child-screens/deferments/model";
import { useAssessmentDefermentsStore } from "@app/products/property/assessments/[id]/components/child-screens/deferments/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { scrollToView } from "@common/utils/animation";
import { nameOfFactory } from "@common/utils/common";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { RefObject, useEffect, useState } from "react";

interface IDefermentsProps {
  defermentsRef?: RefObject<any>;
}
export const Deferments = observer(({ defermentsRef }: IDefermentsProps) => {
  const [refTemp, setRefTemp] = useState<RefObject<any> | undefined>(undefined);
  const [deferementChargesCol, setDeferementChargesCol] = useState<
    IColumnFields[]
  >([]);
  const nameOfDeferments = nameOfFactory<DTO_Deferments>();
  const nameOfDeferredCharges = nameOfFactory<DTO_Charges>();
  const {
    isLoading,
    assessmentDeferments,
    loadAssessmentDeferments,
    resetStore,
    selectedAssessmentDeferments,
    setSelectedAssessmentDeferments,
  } = useAssessmentDefermentsStore();
  const { assessmentId } = useAssessmentStore();

  useEffect(() => {
    setRefTemp(defermentsRef);
  }, [defermentsRef]);

  useEffect(() => {
    if (assessmentId) {
      scrollToView(refTemp);
      loadAssessmentDeferments(assessmentId);
    }
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId, loadAssessmentDeferments, resetStore]);

  useEffect(() => {
    const tempCol = cloneDeep(colDeferredCharges);
    if (assessmentDeferments?.DefermentCharges?.DefermentCharges?.length) {
      tempCol.forEach((item: IColumnFields) => {
        if (
          Object.keys(
            assessmentDeferments?.DefermentCharges?.Total ?? {}
          ).includes(item.field)
        ) {
          const colField = item.field as keyof DTO_Totals;
          if (
            !isNil(assessmentDeferments?.DefermentCharges?.Total?.[colField])
          ) {
            item.footerCell = (
              <CurrencyCell
                value={
                  assessmentDeferments?.DefermentCharges?.Total?.[
                    colField
                  ] as number
                }
              />
            );
          }
        }
      });
    }
    setDeferementChargesCol(tempCol);
  }, [assessmentDeferments]);

  return (
    <div className="cc-field-group" ref={defermentsRef}>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Deferments</label>
          <CCGrid
            isLoading={isLoading}
            selectableMode="single"
            selectedRows={selectedAssessmentDeferments ?? []}
            onSelectionChange={(dataItem: DTO_Deferments[]) => {
              if (dataItem) {
                setSelectedAssessmentDeferments(dataItem);
              }
            }}
            data={assessmentDeferments?.AssessmentDeferments ?? []}
            columnFields={colDeferments}
            primaryField={nameOfDeferments("AssessmentDefermentId")}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Deferred charges</label>
          <CCGrid
            isLoading={isLoading}
            data={
              assessmentDeferments?.DefermentCharges?.DefermentCharges ?? []
            }
            columnFields={deferementChargesCol}
            primaryField={nameOfDeferredCharges("AssessmentDefermentId")}
          />
        </div>
      </div>
    </div>
  );
});
