import { colCriteriaNewSearch } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/components/form-elements/criteria/config";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const CRITERIA_FORM_STEP = "Criteria";

const validator = (values: any) => {
  if (!values || values.Criteria.length === 0) {
    return "Please select at least one type";
  }
  return undefined;
};

export const CriteriaFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={CriteriaStepElement}
      validator={validator}
    />
  );
};

const CriteriaStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const criteriaList = getFieldValue("CriteriaList");
  const criteriaValue = getFieldValue("Criteria");

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCGrid
            data={criteriaList}
            selectedRows={criteriaValue}
            primaryField="Key"
            selectableMode="multiple"
            itemPerPage={20}
            columnFields={colCriteriaNewSearch}
            onSelectionChange={(dataItem: any) => {
              onChange(nameOf("Criteria"), {
                value: [...dataItem],
              });
            }}
          />
        </div>
      </div>
    </section>
  );
};
