import { ODATA_GET_CONTACTS } from "@app/core/contacts/components/dialogs/pick-contact/constant";

export interface IgenerateGetContactURLParam {
  classificationFilters?: string;
  hideCouncillors?: string;
  orgStructureFilter?: string;
  showOnlyCouncillors?: string;
}
export const generateGetContactURL = (param: IgenerateGetContactURLParam) => {
  let odataURL = ODATA_GET_CONTACTS;
  const paramHasValue = Object.entries(param).filter(
    (item: [string, string]) => item[1] !== undefined || item[1] !== ""
  );
  const paramsArray = paramHasValue?.reduce((paramsArray: string[], item) => {
    paramsArray.push(`${item[0]}=${item[1]}`);
    return paramsArray;
  }, []);
  return `${odataURL}(${paramsArray.join(",")})`;
};
