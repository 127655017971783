import { sleep } from "@common/utils/common";
export const postAllocate = async (data: any) => {
  await sleep(2000);
  // mock for success and fail cases
  if (data?.Reason === "false") {
    return false;
  } else {
    return true;
  }
};
export const postReallocate = async (data: any) => {
  await sleep(2000);
  return true;
};
export const postReject = async (data: any) => {
  await sleep(2000);
  // mock for success and fail cases
  if (data?.Reason === "false") {
    return false;
  } else {
    return true;
  }
};
export const postSendBack = async (data: any) => {
  await sleep(2000);
  return true;
};
export const postCancel = async (data: any) => {
  await sleep(2000);
  return true;
};

// common api abandon, delete other later
export const abandonWorkflow = async () => {
  await sleep(2000);
  return true;
};
export const retainWorkflow = async () => {
  await sleep(2000);
  return true;
};

export const postRejectModifyAssessment = async (data: any) => {
  await sleep(2000);
  // mock for success and fail cases
  if (data?.Reason === "false") {
    return { status: 500, data: { IsSuccess: false } };
  } else {
    return { status: 200, data: { IsSuccess: true } };
  }
};
