import { getDebtRecoveryDetailsTabById } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/detail/api";
import { DTO_Debt_Recovery_TabDetails } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const AssessmentDebtRecoveryDetailTab = observer(() => {
  const [debtRecoveryInfo, setDebtRecoveryInfo] = useState<
    DTO_Debt_Recovery_TabDetails | undefined
  >();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const debtRecoveryId =
    parseInt(params.id) || lastSelectedRow.DebtRecoveryId || undefined;

  const loadData = () => {
    if (!debtRecoveryId) return;
    setIsLoading(true);
    getDebtRecoveryDetailsTabById(debtRecoveryId).then((response) => {
      let errorResponse = undefined;
      if (isSuccessResponse(response) && response.data) {
        setDebtRecoveryInfo(response.data);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? "Load detail failed",
        };
      }
      setIsLoading(false);
      setResponseLoadError(errorResponse);
    });
  };

  useEffect(() => {
    if (debtRecoveryId) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtRecoveryId]);

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!debtRecoveryInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Debt Recovery Id:"}
              value={
                debtRecoveryInfo?.DebtRecoveryDetail?.DebtRecoveryId?.toString() ??
                ""
              }
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Ratepayer:"}
                value={debtRecoveryInfo?.DebtRecoveryDetail?.RatePayer}
              />
              <ProductReferenceRow
                title={"Type:"}
                value={debtRecoveryInfo?.DebtRecoveryDetail?.DebtRecoveryType}
              />
              <ProductReferenceRow
                title={"Charges:"}
                value={formatDisplayValue(
                  debtRecoveryInfo?.DebtRecoveryDetail?.Charges,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Interests:"}
                value={formatDisplayValue(
                  debtRecoveryInfo?.DebtRecoveryDetail?.Interests,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Costs:"}
                value={formatDisplayValue(
                  debtRecoveryInfo?.DebtRecoveryDetail?.Costs,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Total:"}
                value={formatDisplayValue(
                  debtRecoveryInfo?.DebtRecoveryDetail?.Total,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
