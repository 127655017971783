import { getViewConfigurations } from "@app/products/property/api";
import { ERROR_MESSAGE_FROM_TO_DATE } from "@app/products/property/assessments/components/util";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { getSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/api";
import { eOptionSearchAssessmentLookup } from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import {
  DTO_Assessment,
  fnt_Assessment_LookupResult,
} from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { AddTitleLookupDialog } from "@app/products/property/components/dialogs/add-title-lookup/_index";
import { getSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/api";
import { eOptionSearchTitleLookup } from "@app/products/property/components/dialogs/add-title-lookup/config";
import {
  DTO_Title,
  fnt_Title_LookupResult,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import {
  colAssessments,
  colTitles,
} from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/related/config";
import { validatorRelatedGrid } from "@app/products/property/parcels/components/form-steps/create-parcel/components/form-elements/related/utils";
import {
  DTO_CreateParcel_Title,
  EParcelGridError,
  EParcelGridType,
  EParcelLoadingType,
} from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfTitle = nameOfFactory<DTO_CreateParcel_Title>();
export const RelatedFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validatorRelatedGrid : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      setIsLoadingInStep: () => {},
      isModifyParcel: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //Get labels
    const [
      titleLabelLowercase,
      titleLabel,
      assessmentLabel,
      assessmentLabelLowercase,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Title,
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.AssessmentLowercase,
    ]);
    //get value
    const assessments = getFieldValue("AssociateAssessments") ?? [];
    const assessmentSelected = getFieldValue("AssessmentSelected") ?? [];
    const loadingMode = getFieldValue("_option.Loading");
    //state
    const [isShowDialog, setIsShowDialog] = useState<EParcelGridType>();
    const titles = getFieldValue("AssociateTitles") ?? [];
    const titleSelected = getFieldValue("_option.TitleSelected") ?? [];
    const associationDate = getFieldValue("AssociationDate") ?? null;

    const [columnsTitle, setColumnsTitle] =
      useState<IColumnFields[]>(colTitles);
    const [columnsAssessment, setColumnsAssessment] =
      useState<IColumnFields[]>(colAssessments);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);

    /**
     * handle add item
     * @param data
     * @param field
     */
    const handleAddItem = async (data: fnt_Assessment_LookupResult[]) => {
      const listAssessmentId = data
        .map((item) => item.Assessment_Id)
        .toString();
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.DataAssessment,
      });
      options?.setIsLoadingInStep(true);
      const responseAssessmentLookup = await getSearchAssessmentLookup({
        LookupKey: eOptionSearchAssessmentLookup.AssessmentId,
        LookupValue: listAssessmentId,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (
        isSuccessResponse(responseAssessmentLookup) &&
        responseAssessmentLookup?.data
      ) {
        const resAssessment = responseAssessmentLookup?.data?.Assessments ?? [];
        const newData: DTO_Assessment[] =
          resAssessment?.map((item: DTO_Assessment) => {
            return {
              ...item,
              Active_From: associationDate,
              AssociationFactorPercentage: 100,
            };
          }) ?? [];
        const previousAssessment = assessments?.length ? [...assessments] : [];
        const assessmentData = processCombineData(
          previousAssessment,
          newData,
          "Assessment_Id"
        );
        onChange(nameOf("AssociateAssessments"), {
          value: assessmentData,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLabelLowercase} failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteItem = () => {
      const assessmentId = assessmentSelected?.[0]?.Assessment_Id;
      const newAssessment = assessments.filter(
        (item: fnt_Assessment_LookupResult) =>
          item.Assessment_Id !== assessmentId
      );
      onChange(nameOf("AssociateAssessments"), {
        value: newAssessment,
      });
      onChange(nameOf("AssessmentSelected"), {
        value: [],
      });
    };

    // Handle logic for Title grid
    /**
     * handle add item for Title)
     * @param data
     */
    const handleAddItemFolio = async (data: fnt_Title_LookupResult[]) => {
      const listTitleId = data.map((item) => item.Title_Id).toString();
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.DataTitle,
      });
      options?.setIsLoadingInStep(true);
      const responseTitleLookup = await getSearchTitleLookup({
        LookupKey: eOptionSearchTitleLookup.TitleId,
        LookupValue: listTitleId,
        Statuses: [0], //default task 12866
      });
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(responseTitleLookup) && responseTitleLookup?.data) {
        const resTitle = responseTitleLookup?.data?.Titles ?? [];
        const newData: DTO_Title[] =
          resTitle?.map((item: DTO_Title) => {
            return {
              ...item,
              Active_From: associationDate,
              AssociationFactorPercentage: 100,
            };
          }) ?? [];
        const previousTitles = titles?.length ? [...titles] : [];
        const newDataCombine = processCombineData(
          previousTitles,
          newData,
          nameOfTitle("TitleId")
        );
        onChange(nameOf("AssociateTitles"), {
          value: newDataCombine,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load ${titleLabelLowercase} failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    const handleDataChange = async (
      dataRow: any,
      gridName: string,
      primaryKey: "TitleId" | "Assessment_Id",
      gridData: DTO_CreateParcel_Title[]
    ) => {
      const id = dataRow?.[primaryKey];
      let newGridData = [...gridData];
      newGridData = gridData.map((item: any) =>
        item?.[primaryKey] === id ? { ...dataRow } : item
      );
      onChange(nameOf(gridName), { value: newGridData });
    };

    const handleDeleteItemFolio = () => {
      const folioId = titleSelected?.[0]?.TitleId;
      const newFolio = titles.filter(
        (item: DTO_CreateParcel_Title) => folioId !== item.TitleId
      );
      onChange(nameOf("AssociateTitles"), {
        value: newFolio,
      });
      onChange(nameOf("_option.TitleSelected"), {
        value: [],
      });
    };

    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Grid,
      });
      options?.setIsLoadingInStep?.(true);
      const response = await Promise.all([
        getViewConfigurations(ViewConfiguration.Parcel_Assessments),
        getViewConfigurations(ViewConfiguration.TitleSearch),
      ]);
      onChange(nameOf("_option.Loading"), { value: undefined });
      options?.setIsLoadingInStep?.(false);
      const [viewAssessment, viewTitle] = response;
      let errorResponse = undefined;
      if (Array.isArray(response) && viewAssessment?.data && viewTitle?.data) {
        if (viewAssessment?.data) {
          setColumnsAssessment(
            processDynamicColumns(
              colAssessments,
              viewAssessment?.data?.ColumnDefinitions?.Columns
            )
          );
        }
        if (viewTitle?.data) {
          setColumnsTitle(
            processDynamicColumns(
              colTitles,
              viewTitle?.data?.ColumnDefinitions?.Columns
            )
          );
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Load view configuration failed",
        };
      }
      setResponseLoadError(errorResponse);
    };

    const messageError = useMemo(() => {
      if (errors?.[nameOf("")] === EParcelGridError.TitleLength) {
        return `At least one ${titleLabelLowercase} is required`;
      }
      if (errors?.[nameOf("")] === EParcelGridError.Title) {
        return ERROR_MESSAGE_FROM_TO_DATE;
      }
      return "";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    if (loadingMode === EParcelLoadingType.Grid)
      return <Loading isLoading={loadingMode} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfiguration();
          }}
        />
      );

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-field">
            <CCLabel title="Association date" isMandatory />
            <Field
              name={nameOf("AssociationDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
              validator={!options?.isReadOnly ? validateRequired : undefined}
            />
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-field">
            <CCLabel title={titleLabel} errorMessage={messageError} />
            <CCGrid
              isLoading={loadingMode === EParcelLoadingType.DataTitle}
              readOnly={options?.isReadOnly}
              data={titles ?? []}
              columnFields={columnsTitle ?? []}
              primaryField={nameOfTitle("TitleId")}
              selectableMode={!options?.isReadOnly ? "single" : undefined}
              editableMode={!options?.isReadOnly ? "cell" : undefined}
              onSelectionChange={(dataItems: DTO_CreateParcel_Title[]) => {
                onChange(nameOf("_option.TitleSelected"), {
                  value: dataItems ?? [],
                });
              }}
              onDataRowChange={(dataRow) =>
                handleDataChange(dataRow, "AssociateTitles", "TitleId", titles)
              }
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title={`Add ${titleLabelLowercase}`}
                    onClick={() => {
                      setIsShowDialog(EParcelGridType.Title);
                    }}
                    disabled={options?.isReadOnly}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title={`Remove ${titleLabelLowercase}`}
                    onClick={handleDeleteItemFolio}
                    disabled={
                      options?.isReadOnly ||
                      titleSelected?.length < 1 ||
                      (titleSelected[0]?.ParcelTitleId &&
                        options?.isModifyParcel)
                    }
                  />
                </div>
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={assessmentLabel}
              errorMessage={
                errors?.[nameOf("")] === EParcelGridError.Assessment
                  ? ERROR_MESSAGE_FROM_TO_DATE
                  : ""
              }
            />
            <CCGrid
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    type="button"
                    title={`Add ${assessmentLabelLowercase}`}
                    onClick={() => {
                      setIsShowDialog(EParcelGridType.Assessment);
                    }}
                    disabled={options?.isReadOnly}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title={`Remove ${assessmentLabelLowercase}`}
                    disabled={
                      options?.isReadOnly ||
                      assessmentSelected?.length <= 0 ||
                      (assessmentSelected[0]?.AssessmentParcelId &&
                        options?.isModifyParcel)
                    }
                    onClick={handleDeleteItem}
                  />
                </div>
              }
              isLoading={loadingMode === EParcelLoadingType.DataAssessment}
              data={assessments ?? []}
              selectedRows={assessmentSelected ?? []}
              editableMode={!options?.isReadOnly ? "cell" : undefined}
              selectableMode={!options?.isReadOnly ? "single" : undefined}
              primaryField={"Assessment_Id"}
              columnFields={columnsAssessment ?? []}
              onSelectionChange={(dataItem: fnt_Assessment_LookupResult[]) => {
                onChange(nameOf("AssessmentSelected"), {
                  value: dataItem ?? [],
                });
              }}
              onDataRowChange={(dataRow) =>
                handleDataChange(
                  dataRow,
                  "AssociateAssessments",
                  "Assessment_Id",
                  assessments
                )
              }
              readOnly={options?.isReadOnly}
            />
          </div>
        </section>
        {isShowDialog === EParcelGridType.Title && (
          <AddTitleLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddTitle={(data: fnt_Title_LookupResult[]) => {
              setIsShowDialog(undefined);
              handleAddItemFolio(data);
            }}
          />
        )}
        {isShowDialog === EParcelGridType.Assessment && (
          <AddAssessmentLookupDialog
            onClose={() => setIsShowDialog(undefined)}
            handleAddAssessment={(data: fnt_Assessment_LookupResult[]) => {
              handleAddItem(data);
              setIsShowDialog(undefined);
            }}
          />
        )}
      </>
    );
  }
);
