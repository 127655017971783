import { postContactRelationship } from "@app/core/contacts/components/dialogs/additional-contact/api";
import { AdditionalContactDialogForm } from "@app/core/contacts/components/dialogs/additional-contact/components/additional-contact-dialog-form/_index";
import {
  initialValues,
  LoadingState,
} from "@app/core/contacts/components/dialogs/additional-contact/contants";
import { IAdditionalContactDialog } from "@app/core/contacts/components/dialogs/additional-contact/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useRef, useState } from "react";

interface AdditionalContactDialogProps {
  onClose: () => void;
  onSubmit: (isSuccess: boolean) => void;
  id?: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
}
export const AdditionalContactDialog = ({
  onClose,
  onSubmit,
  recordType,
  id,
  bubbleUps,
}: AdditionalContactDialogProps) => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingState>(
    LoadingState.NONE
  );
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const handleSubmit = async (values: any) => {
    const { Contact, RelationshipType } = values as IAdditionalContactDialog;
    if (isNil(id) || isNil(RelationshipType) || !Contact?.ID) return;
    setLoadingStatus(LoadingState.SAVE);
    const response = await postContactRelationship({
      RecordSourceType: recordType,
      RecordSourceId: id,
      ContactId: Contact.ID,
      ContactRelationshipType: RelationshipType,
      BubbleUps: bubbleUps,
    });
    customLogger("Core Contact, Add contact, postContactRelationship").info(
      response?.data
    );
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        onSubmit(true);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Additional could not be added",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: response?.error ?? "Additional could not be added",
        type: "error",
      });
    }
    setLoadingStatus(LoadingState.NONE);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <AdditionalContactDialogForm
            formRenderProps={formRenderProps}
            recordType={recordType}
            setLoadingStatus={setLoadingStatus}
            loadingStatus={loadingStatus}
            onClose={onClose}
            notificationRef={notificationRef}
          />
        );
      }}
    />
  );
};
