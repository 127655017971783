import { getKeywords } from "@common/apis/coreKeyword";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { filterBy } from "@progress/kendo-data-query";
import {
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { isNil } from "lodash";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface ICCKeyValueSearch extends ComboBoxProps {
  keywordType?: KEYWORD_TYPE;
  productType?: PRODUCT_TYPE_NUMBER;
  onReceiveData?: (data: any) => void;
}
const nameOf = nameOfFactory<CoreKeyword>();

export const CCKeyValueSearch = ({
  value,
  onChange,
  data,
  disabled,
  placeholder,
  keywordType,
  productType,
  onReceiveData = () => {},
  ...others
}: ICCKeyValueSearch) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalData, setOriginalData] = useState<CoreKeyword[]>(data ?? []);
  const [dataSearch, setDataSearch] = useState<CoreKeyword[]>([]);
  const displayValue = useMemo(() => {
    if (isNil(value)) return "";

    if (typeof value === "string" && originalData.length > 0) {
      const newDisplayValue = originalData.find(
        (keyValue) => keyValue.Name === value
      );

      return newDisplayValue;
    } else {
      return value;
    }
  }, [originalData, value]);

  useEffectOnce(() => {
    if (keywordType && productType) {
      setIsLoading(true);
      getKeywords(keywordType, productType).then((response) => {
        if (response?.data) {
          onReceiveData(response.data);
          setOriginalData(response.data);
          setDataSearch(response.data);
        }
      });
      setIsLoading(false);
    }
  });

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    setDataSearch([]);
    setIsLoading(true);
    setDataSearch(filterBy(originalData, event.filter));
    setIsLoading(false);
  };

  return (
    <CCComboBox
      suggest
      filterable
      disabled={disabled}
      loading={isLoading}
      data={dataSearch}
      textField={nameOf("Name")}
      onFilterChange={handleSearch}
      value={displayValue}
      onChange={onChange}
      placeholder={placeholder}
      {...others}
    />
  );
};
