import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyCertificateFormElement } from "./components/form-element/_index";

export const PropertyCertificateForm = observer(() => {
  const { certificate } = useCertificateStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={certificate}
        key={JSON.stringify(certificate)}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyCertificateFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
