import { loadInstallationData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/add-master-sub-meter/api";
import { initialValuesAddMeter } from "@app/products/property/meters/[id]/components/action-bar/dialogs/add-master-sub-meter/config";
import { IAddSubMeterData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/add-master-sub-meter/model";
import { MasterSubEnum } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
export const nameOf = nameOfFactory<IAddSubMeterData>();

export interface IAddMasterSubMeterDialogProps {
  onClose: () => void;
  onSubmit: (values: any, event?: React.SyntheticEvent<any>) => void;
}
export const AddMasterSubMeterDialog = observer(
  ({ onSubmit, onClose }: IAddMasterSubMeterDialogProps) => {
    const { associationMeter } = useMeterInstallationStore();
    const [initialValues, setInitialValues] = useState<IAddSubMeterData>(
      initialValuesAddMeter
    );

    const isExistingMaster = useMemo(() => {
      return associationMeter.some(
        (meter) =>
          meter.MasterSub === MasterSubEnum.MasterMeter && !meter.AssociatedTo
      );
    }, [associationMeter]);

    useEffect(() => {
      const { _option } = initialValuesAddMeter;
      setInitialValues({
        ...initialValuesAddMeter,
        MasterSub: isExistingMaster
          ? MasterSubEnum.SubMeter
          : MasterSubEnum.MasterMeter,
        _option: {
          ..._option,
          MasterSub: {
            Data: Object.values(MasterSubEnum).map((e) => ({
              label: e,
              value: e,
              disabled: e === MasterSubEnum.MasterMeter && isExistingMaster,
            })),
          },
        },
      });
    }, [isExistingMaster]);

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          const {
            valueGetter,
            valid,
            modified,
            onSubmit: handleSubmit,
            onChange,
          } = formRenderProps;

          const handleSearchMeter = (event: ComboBoxFilterChangeEvent) => {
            const { value } = event.filter;

            const numLetters = 2;
            if (value && value.length >= numLetters) {
              onChange("_option.MeterSearch.Loading", { value: true });
              loadInstallationData(event.filter).then((data) => {
                onChange("_option.MeterSearch.Data", {
                  value: data,
                });

                onChange("_option.MeterSearch.Loading", { value: false });
              });
            } else {
              onChange("_option.MeterSearch.Data", { value: [] });
              onChange(nameOf("MeterSearch"), { value: undefined });
            }
          };
          const handleSearchMeterResult = (event: ComboBoxChangeEvent) => {
            const { value } = event;
            onChange(nameOf("MeterSearch"), { value: value });

            onChange(nameOf("MeterNo"), {
              value: value?.MeterNo,
            });
            onChange("_option.InstallationMeter.Data", {
              value: value?.Installation ?? [],
            });
            onChange(nameOf("InstallationMeter"), {
              value: value?.Installation?.[0],
            });
          };

          const installationMeterData = valueGetter(
            "_option.InstallationMeter.Data"
          );
          return (
            <CCDialog
              titleHeader={"Add Master/Sub Meter Association"}
              onClose={onClose}
              maxWidth={"25%"}
              height={"auto"}
              bodyElement={
                <FormElement className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Are you associating a master or sub meter?
                        </label>
                        <Field
                          name={nameOf("MasterSub") ?? null}
                          component={RadioGroup}
                          data={valueGetter("_option.MasterSub.Data")}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Meter <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("MeterSearch") ?? null}
                          validator={requiredValidator}
                          placeholder={"Meter number"}
                          component={CCComboBox}
                          suggest
                          filterable
                          data={valueGetter("_option.MeterSearch.Data") ?? []}
                          loading={valueGetter("_option.MeterSearch.Loading")}
                          textField="MeterNo"
                          onFilterChange={handleSearchMeter}
                          onChange={handleSearchMeterResult}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Which installation of the meter?
                        </label>
                        <Field
                          name={nameOf("InstallationMeter") ?? null}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          component={CCSearchComboBox}
                          disabled={
                            !installationMeterData ||
                            !installationMeterData.length
                          }
                          data={installationMeterData}
                          value={getDropdownValue(
                            valueGetter(nameOf("InstallationMeter")),
                            installationMeterData,
                            nameOfLov("Code")
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange(nameOf("InstallationMeter"), {
                              value: event.target.value?.Code ?? null,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Associated date <CCTooltip type="validator" />
                        </label>
                        <Field
                          validator={requiredValidator}
                          name={nameOf("AssociatedFrom") ?? null}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={handleSubmit}
                    disabled={!valid || !modified}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
