import { ANIMAL_TYPE_ROUTE } from "@app/products/animals/system-admin/animal-types/[id]/constant";
import { Svc_Animals_TypeView } from "@app/products/animals/system-admin/animal-types/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_TypeView>();
export const colAnimalsSystemAdminAnimalTypes: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: Svc_Animals_TypeView) => {
      return `${ANIMAL_TYPE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: nameOf("MaxNo"),
    title: "Max Number",
    format: NUMBER_FORMAT.NUMBER,
  },
  {
    field: nameOf("MaxNoLarge"),
    title: "Max Number (Large)",
    format: NUMBER_FORMAT.NUMBER,
  },
];
