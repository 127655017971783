import {
  ConcessionCard,
  DTO_Owners,
  DTO_Rebate_Concession_Card,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { isValidValueWithMask } from "@common/utils/formatting";
import { isAfter } from "date-fns";
import {
  cloneDeep,
  concat,
  filter,
  find,
  flatten,
  map,
  some,
  uniq,
} from "lodash";

export const concessionCardsFormValidator = (values: any) => {
  const owners = values?.Owners ?? [];
  let hasError = false;
  owners.forEach((owner: DTO_Owners) => {
    const concessionCards = owner?.ConcessionCards ?? [];
    concessionCards.forEach((card: DTO_Rebate_Concession_Card) => {
      const presentedDate = card.Presented_Date;
      if (presentedDate && isAfter(new Date(presentedDate), new Date())) {
        hasError = true;
      }
    });
  });
  if (hasError) {
    return "Presented date of the concession card(s) must be less than or equal to today's date";
  }

  const wrongFormatItems = flatten(
    map(owners, (owner: DTO_Owners) =>
      filter(
        owner?.ConcessionCards,
        (rebateCard: DTO_Rebate_Concession_Card) =>
          rebateCard.Is_Active &&
          !isValidValueWithMask(
            rebateCard?.Card_Number,
            rebateCard?.Card_Number_InputMask
          )
      )
    )
  );

  if (wrongFormatItems.length) {
    return "Some card numbers are in the wrong format.";
  }
  return undefined;
};

// Get data from valueGetter("") -> it does not match with the existing model so using any here
export const getUniqueEntitledOwnerIds = (rebatePeriodsData: any[]) => {
  const entitledOwnerIds = flatten(
    map(
      filter(
        rebatePeriodsData,
        (rebatePeriod: any) => rebatePeriod?.Is_Entitle
      ),
      (rebatePeriod: any) =>
        map(
          filter(rebatePeriod.Owners, (owner: any) => owner?.Is_Entitle),
          (entitledOwner: any) => entitledOwner.Entity_Id
        )
    )
  );

  return uniq(entitledOwnerIds);
};

export const convertToRebateConcessionCardDTO = (
  obj: ConcessionCard
): DTO_Rebate_Concession_Card => {
  return {
    Id: obj.ConcessionCardId,
    Concession_Card: obj.Name,
    Is_Active: false,
    Presented_Date: new Date(),
    Card_Number_InputMask: obj.CardNumberInputMask,
  } as DTO_Rebate_Concession_Card;
};

export const getRebateConcessionCards = (
  activeConcessionCards: ConcessionCard[]
): DTO_Rebate_Concession_Card[] => {
  return map(activeConcessionCards, convertToRebateConcessionCardDTO);
};

export const handleConcessionCardsData = (
  { dataItem }: any,
  owners: DTO_Owners[],
  rebateConcessionCards: DTO_Rebate_Concession_Card[],
  isWorkflowCompleted: boolean
): { ConcessionCards: DTO_Rebate_Concession_Card[] } => {
  const owner = find(
    owners,
    (owner: DTO_Owners) => owner.Entity_Id === dataItem?.Entity_Id
  );
  const concessionCards = owner?.ConcessionCards || [];
  const updatedRebateConcessionCards = map(
    rebateConcessionCards,
    (rebateCard: DTO_Rebate_Concession_Card) => {
      const matchItem = find(concessionCards, { Id: rebateCard.Id });

      return matchItem
        ? {
            ...rebateCard,
            Is_Active: matchItem?.Is_Active,
            Card_Number: matchItem?.Card_Number,
            Presented_Date: matchItem?.Presented_Date,
          }
        : rebateCard;
    }
  );

  return {
    ConcessionCards: isWorkflowCompleted
      ? concessionCards
      : updatedRebateConcessionCards,
  };
};

export const filterAndUpdateOwners = (
  owners: DTO_Owners[],
  rebateConcessionCards: DTO_Rebate_Concession_Card[]
): DTO_Owners[] => {
  return map(cloneDeep(owners), (owner: DTO_Owners) => {
    const filteredConcessionCards = filter(
      owner?.ConcessionCards,
      (card: DTO_Rebate_Concession_Card) =>
        some(
          rebateConcessionCards,
          (rebateConcessionCard: DTO_Rebate_Concession_Card) =>
            rebateConcessionCard.Id === card.Id
        )
    );
    const additionalConcessionCards = filter(
      rebateConcessionCards,
      (rebateConcessionCard: DTO_Rebate_Concession_Card) =>
        !some(
          owner?.ConcessionCards,
          (card: DTO_Rebate_Concession_Card) =>
            card.Id === rebateConcessionCard.Id
        )
    );
    const combinedConcessionCards = concat(
      filteredConcessionCards ?? [],
      additionalConcessionCards
    );
    const updatedConcessionCards = map(
      combinedConcessionCards,
      (rebateConcessionCard: DTO_Rebate_Concession_Card) => {
        const originalCard = find(
          owner?.ConcessionCards,
          (card: DTO_Rebate_Concession_Card) =>
            card.Id === rebateConcessionCard.Id
        );

        return originalCard
          ? {
              ...rebateConcessionCard,
              Is_Active: originalCard?.Is_Active,
              Card_Number: originalCard?.Card_Number,
              Presented_Date: originalCard?.Presented_Date,
            }
          : rebateConcessionCard;
      }
    );

    return {
      ...owner,
      ConcessionCards: updatedConcessionCards,
    };
  });
};
