import { EClientSequenceKey } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { CC_SETTINGS_SEQCONTROL } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

// #region Last number used field
export const eLLPermit = `${CC_SETTINGS_SEQCONTROL}${EClientSequenceKey.LLP_Permit}`;

// #region General
export const GE_GeneralSettingsCorporateSetting = [
  ECorporateSettingsField.LLP_ExcludeInactive,
  ECorporateSettingsField.LLP_UsePermitEventName,
  ECorporateSettingsField.LLP_RaiseFeeOnLodgement,
  ECorporateSettingsField.LLP_ShowPermitEffectiveDates,
  ECorporateSettingsField.LLP_AllowMultipleAddresses,
  ECorporateSettingsField.LLP_ShowRefuseButton,
  ECorporateSettingsField.LLP_Views_Conditions_DisplayConditionsInGroup,
  ECorporateSettingsField.LLP_ShowApproveButton,
];

export const GE_MailMergeCorporateSetting = [
  ECorporateSettingsField.LLP_MailMergeSignature,
];

export const GE_CommunicationCorporateSetting = [
  ECorporateSettingsField.LLP_Communication_DefaultSenderEmail_KeywordID,
];
// #endregion General

// #region Numbering
export const NU_NumberSettingsCorporateSetting = [
  ECorporateSettingsField.LLP_NumberFormat,
  ECorporateSettingsField.LLP_NumberPrefix,
  ECorporateSettingsField.LLP_NumberSuffix,
  ECorporateSettingsField.LLP_NumberLength,
  eLLPermit,
  ECorporateSettingsField.LLP_DefaultDebtorNo,
  ECorporateSettingsField.LLP_DebtorNumberPrefix,
];
// #endregion Numbering

// #region Inspections
export const IN_InspectionsSettingsCorporateSetting = [
  ECorporateSettingsField.LLP_EnableTemperatureRecord,
  ECorporateSettingsField.LLP_EnableFSPObserved,
];
// #endregion Inspections

// #region Records
export const RC_RecordsSettingsCorporateSetting = [];
export const RC_AttachmentTitleFormatCorporateSetting = [];
export const RC_UseContainerPartForSearchCorporateSetting = [];
export const RC_SharePointSettingsCorporateSetting = [];
// #endregion Inspections
