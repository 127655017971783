import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkListViewDisplayName = () => {
  return "Settings - Lookups";
};

export const getBookmarkListViewDetail = () => {
  return "Settings - Lookups - Regions";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Region_ID
    ? `- ${selectedRow.Region_ID}`
    : "";
  return `Lookups - Regions ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Region_ID + ", " + selectedRow.Region_Name,
  ]);
};
