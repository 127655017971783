import { DTO_Journal } from "@app/products/property/journals/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { APIResponse } from "@common/apis/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum AuthoriseType {
  AuthoriseJournals = "AuthoriseJournals",
  AuthoriseBy = "AuthoriseBy", //Currently, Hide the "Authorise by" until we have a further design.
  AssignAuthorisationTo = "AssignAuthorisationTo",
}

export enum EAuthoriseJournalSteps {
  Authorise = "Authorise",
  Comments = "Comments",
}

export interface DTO_Workflow_JournalAuthorise {
  WorkflowDetail: DTO_WorkflowDetail_JournalAuthorise;
  WorkflowHeader: DTO_WorkflowHeader;
}

export type IGetInitialDataAuthoriseJournal =
  | [APIResponse<DTO_Journal_LOVs>, APIResponse<DTO_Workflow_JournalAuthorise>]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalAuthorise>
  | undefined;

export interface DTO_WorkflowDetail_JournalAuthorise {
  Journal: DTO_Journal;
  CreateSession: boolean;
  SessionName: string;
  SelectedSecondaryWorkflow: number[];
  _options?: any;
}

//Only apply DTO for this button
export interface DTO_Journal_LOVs {
  Officer: DTO_LOV[];
}
