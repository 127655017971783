import { useAssessmentChargesStore } from "@app/products/property/assessments/[id]/components/child-screens/charges/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
import ChargesDetails from "./components/detail/_index";
import { DTO_Charge } from "./model";
import "./_index.scss";
const nameOf = nameOfFactory<DTO_Charge>();

export const Charges = observer(() => {
  //store
  const { assessmentId } = useAssessmentStore();
  const {
    isLoading,
    isGridLoading,
    chargesData,
    newColumnCharges,
    gridDataState,
    chargesViewsData,
    chargesView,
    showCancelled,
    showPaidCharges,
    responseLoadError,
    setShowCancelled,
    setChargesView,
    setShowPaidCharges,
    setChargesSelected,
    loadViews,
    loadCharges,
    resetStore,
  } = useAssessmentChargesStore();

  //The first time load view
  useEffectOnce(() => {
    loadViews();
    return () => resetStore();
  });

  //Load data after calling view success
  useEffect(() => {
    if (!chargesView || assessmentId === undefined) return;
    loadCharges(
      assessmentId,
      chargesView?.Id,
      chargesView?.RatingPeriod?.Id,
      showCancelled,
      showPaidCharges,
      false
    );
  }, [showCancelled, chargesView, assessmentId, loadCharges, showPaidCharges]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViews();
        }}
      />
    );

  return (
    <div className="cc-property-panel-charges">
      <div className="cc-grid-control-left">
        <label className="cc-control-item cc-label">View</label>
        <DropDownList
          className="cc-control-item cc-charge-view"
          data={chargesViewsData ?? []}
          textField="Name"
          value={chargesView}
          disabled={isGridLoading}
          onChange={(event: DropDownListChangeEvent) => {
            setChargesView(event.target.value);
          }}
        />
        <Checkbox
          className="cc-control-item"
          title="Show cancelled charges"
          label="Show cancelled charges"
          checked={showCancelled}
          disabled={isGridLoading}
          onChange={(event: CheckboxChangeEvent) => {
            setShowCancelled(event.value);
          }}
        />
        <Checkbox
          title="Show paid charges"
          label="Show paid charges"
          className="cc-control-item"
          checked={showPaidCharges}
          disabled={isGridLoading}
          onChange={(event: CheckboxChangeEvent) => {
            setShowPaidCharges(event.value);
          }}
        />
      </div>
      <CCGrid
        state={gridDataState}
        data={chargesData}
        isLoading={isGridLoading}
        columnFields={newColumnCharges}
        primaryField={nameOf("Id")}
        detail={ChargesDetails}
        selectableMode={"multiple"}
        onSelectionChange={(dataItem: DTO_Charge[]) => {
          setChargesSelected(dataItem);
        }}
      />
    </div>
  );
});
