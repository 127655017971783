//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";

export const colHMFSRKeywords = [
  {
    field: HMFieldMapping.Name,
    title: "Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.KeywordType_Name,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.IsActive,
    title: "Status",
    width: 150,
  },
  {
    field: HMFieldMapping.SortIndex,
    title: "Sort",
    width: 150,
  },
  {
    field: HMFieldMapping.FSRType,
    title: "FSR Type",
    width: 150,
  },
  {
    field: HMFieldMapping.FSRCode,
    title: "FSR Code",
    width: 150,
  },
  {
    field: HMFieldMapping.Description,
    title: "Description",
    width: 150,
  },
  {
    field: HMFieldMapping.Code,
    title: "Code",
    width: 150,
  },
];
