import { getDisplayTextWithDashes } from "@common/utils/common";

export const getDocumentDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Id ? `- ${selectedRow.Id}` : "";
  return `Document Template ${dynamicDisplayName}`;
};

export const getDocumentDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.Id, selectedRow.Name]);
};

export const getDocumentListViewDisplayName = () => {
  return "Document Template - List";
};
