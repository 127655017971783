import { PPREnquiryChangedCompleteDateButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/change-complete-date/_index";
import { PPREnquiryChangedLodgedDateButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/change-lodged-date/_index";
import { PPREnquiryChangeStatusButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/change-status/_index";
import { RegisterStatus } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const PPREnquiryToolsButton = observer(() => {
  const { pprEnquiry } = usePPREnquiryStore();

  const isVisible = useMemo(() => {
    return (
      pprEnquiry?.Status_ENUM !== RegisterStatus.New &&
      pprEnquiry?.Status_ENUM !== RegisterStatus.NA
    );
  }, [pprEnquiry]);

  return isVisible ? (
    <CCNavButton title={"Tool"} type="sub">
      <PPREnquiryChangedCompleteDateButton />
      <PPREnquiryChangedLodgedDateButton />
      <PPREnquiryChangeStatusButton />
    </CCNavButton>
  ) : null;
});
