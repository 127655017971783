import {
  apiCoreRefusageRegisterType,
  apiCoreRelatedRecords,
} from "@app/core/components/common/utils";
import {
  ICoreRelated,
  ICoreRelatedParams,
} from "@app/core/related/related-accordion/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getCoreRelated = async (
  relatedParam: ICoreRelatedParams
): Promise<APIResponse<ICoreRelated[]>> => {
  try {
    return await CoreAPIService.getClient().get<ICoreRelated[]>(
      apiCoreRelatedRecords(),
      {
        params: {
          recordType: relatedParam.RecordType,
          recordId: relatedParam.RecordID,
          invoiceNumber: relatedParam?.InvoiceNumber || null,
          assessmentNumberKey: relatedParam?.AssessmentNumberKey || null,
          addressLine1:
            relatedParam?.AddressLine1 &&
            relatedParam?.AddressLine1?.trim().length > 0
              ? relatedParam.AddressLine1
              : null,
          suburb: relatedParam?.Suburb || null,
          personKey: relatedParam?.PersonKey,
          wasteWaterSchemeKey: relatedParam?.WasteWaterSchemeKey,
          csmRefNoKey: relatedParam?.CsmRefNoKey || null,
          registerType: relatedParam?.RegisterType,
          ancestorRecordType: relatedParam?.AncestorRecordType,
          ancestorRecordId: relatedParam?.AncestorRecordId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getRegisterType = async (
  registerId: number
): Promise<APIResponse<number>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreRefusageRegisterType(registerId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
