import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { getKeywordTabDetailsById } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/detail/api";
import { KeywordTabDetails } from "@common/pages/settings/lookups/keywords/_id/components/reference-sidebar/detail/model";
import { useKeywordsStore } from "@common/pages/settings/lookups/keywords/_id/store";
import { formatDisplayValue } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const LookupKeywordsTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { keyword } = useKeywordsStore();
  const [isLoading, setIsLoading] = useState(false);
  const [keywordTabDetailsInfo, setKeywordTabDetailsInfo] =
    useState<KeywordTabDetails>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const keywordID = keyword?.Keyword_ID ?? lastSelectedRow?.Keyword_ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getKeywordTabDetailsById(keywordID);
    if (isSuccessResponse(response)) {
      setKeywordTabDetailsInfo(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!keywordID) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [keyword, keywordID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Keyword type:"}
          value={keywordTabDetailsInfo?.KeywordType_Name}
        />
        <ProductReferenceRow
          title={"Name:"}
          value={keywordTabDetailsInfo?.Name}
        />
        <ProductReferenceRow
          title={"Description:"}
          value={keywordTabDetailsInfo?.Description}
        />
        <ProductReferenceRow
          title={"Full description:"}
          value={sanitizeHtml(keywordTabDetailsInfo?.FullDescription ?? "")}
        />
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDisplayValue(
            keywordTabDetailsInfo?.CreatedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Created by:"}
          value={keywordTabDetailsInfo?.CreatedBy}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
