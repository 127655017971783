import { isNil } from "lodash";

export const NOT_INSPECTED_VIEW =
  "odata/hm/internal/inspectionschedule/GetViewNotInspected()?$count=true&";

export const getInspScheduleViewOData = (officerName?: string) => {
  if (isNil(officerName)) {
    return `odata/hm/internal/inspectionschedule/GetViewInspectionSchedule()?$count=true&`;
  }
  return `odata/hm/internal/inspectionschedule/GetViewInspectionSchedule(officerName='${officerName.toString()}')?$count=true&`;
};

export const getInspScheduleAuditViewOData = (officerName?: string) => {
  if (isNil(officerName)) {
    return `odata/hm/internal/inspectionschedule/GetViewAuditSchedule()?$count=true&`;
  }
  return `odata/hm/internal/inspectionschedule/GetViewAuditSchedule(officerName='${officerName.toString()}')?$count=true&`;
};
