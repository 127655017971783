import AmsRootStore from "../ams-root-store";
import AssetCategoryStore from "./asset-categories/store";
import AssetRegisterStore from "./asset-register/store";
import InspectionAssessmentStore from "./inspections-assessments/store";
import LookupStore from "./lookups/store";
import WorkOrderStore from "./workorders/store";

export default class DataStore {
  //pointer back to the root store incase we need to pass it down at all to other stores that might need to cross cut and get other data. eg work order might need hit lookups one day.
  rootStore: AmsRootStore;

  lookupStore: LookupStore;
  workOrderStore: WorkOrderStore;
  assetCategoryStore: AssetCategoryStore;
  assetRegisterStore: AssetRegisterStore;
  inspectionAssessmentStore: InspectionAssessmentStore;

  //once converted add catalogue-store to here

  constructor(rootStore: AmsRootStore) {
    this.rootStore = rootStore;
    this.lookupStore = new LookupStore();
    this.workOrderStore = new WorkOrderStore();
    this.assetCategoryStore = new AssetCategoryStore();
    this.assetRegisterStore = new AssetRegisterStore(this.lookupStore); // TODO: Need to test again
    this.inspectionAssessmentStore = new InspectionAssessmentStore();
  }
}
