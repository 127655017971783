import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putCheckDueActions = async (): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/core/internal/actions/outstanding`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
