import {
  IPPROfficerAllocation,
  PPROfficerAllocation,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/allocate-to-officer/model";
import { AllocateToOfficerDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/allocate-to-officer/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putOfficerAllocate } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/allocate-to-officer/api";
import { useAllocateToOfficerStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/allocate-to-officer/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const AllocateToOfficerButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { ppr, setPPRWithLoading, onSubmit, pprId } = usePPRStore();
  const { setIsShowDialog, isShowDialog, isLoading, setIsLoading } =
    useAllocateToOfficerStore();
  const [notification, setNotification] =
    useState<IAppNotificationItemAddProps>();

  const isVisible = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.Lodged && !ppr?.Flag_PlansToComply
    );
  }, [ppr]);
  const initialData: IPPROfficerAllocation = useMemo(() => {
    return {
      ReasonForPermit: ppr?.ReasonForPermit,
      ApplicationCategories: ppr?.ApplicationCategories,
      Rating_KWD: ppr?.Rating_KWD,
      Rating_Name: ppr?.Rating_Name,
      Planner: ppr?.Planner,
      Planner_ID: ppr?.Planner_ID,
      AllocationComments: ppr?.AllocationComments,
      SaveTriggers: [],
    };
  }, [ppr]);

  const handleSubmit = async (data: PPROfficerAllocation) => {
    if (!pprId) return;
    setIsLoading(true);
    const res = await putOfficerAllocate(pprId, data);
    setNotification(undefined);
    if (isSuccessIdentityPacket(res)) {
      setIsShowDialog(false);
      clearNotifications();
      setPPRWithLoading(res.data.Application);
      pushNotification({
        type: "success",
        title: "Officer allocated successfully",
      });
      if (res.data?.Notifications && res.data?.Notifications.length > 0) {
        pushNotification({
          autoClose: false,
          title: "Allocate to officer",
          type: "warning",
          description: res.data?.Notifications,
        });
      }
    } else {
      setNotification({
        title: "Allocate to officer failed",
        type: "error",
        autoClose: false,
        description: res.data?.Errors ?? res?.error,
      });
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Allocate to officer"
        onClick={onSubmit}
        name={PPRSubmitActions.AllocateToOfficer}
      />

      {isShowDialog && (
        <AllocateToOfficerDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          initialData={initialData}
          isLoading={isLoading}
          notification={notification}
        />
      )}
    </>
  ) : null;
});
