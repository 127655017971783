export const mockTransactions = [
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("11-Dec-2013 0:00"),
    TransType: "WA01",
    TransactionAmount: 70.35,
    IsCancelled: false,
    Reference: "METCON",
    Description: "WAT 03/04 1",
    Notes: "Consumption* A",
    JournalNumber: "1",
    SessionNumber: "1",
    UserId: "ra",
    IsProposed: false,
    CreatedOn: "13-Jun-2014 12:36",
    TransactionId: "19268",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("26-Mar-2014 0:00"),
    TransType: "WA02",
    TransactionAmount: 88.4,
    IsCancelled: false,
    Reference: "METCON",
    Description: "WAT 03/04 2",
    Notes: "Consumption* A",
    JournalNumber: "1",
    SessionNumber: "1",
    UserId: "ar",
    IsProposed: false,
    CreatedOn: "13-Jun-2014 12:36",
    TransactionId: "19272",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("11-Aug-2014 0:00"),
    TransType: "WA03",
    TransactionAmount: 50.6,
    IsCancelled: false,
    Description: "Water Consumption",
    Notes: "Usage: 94KL",
    JournalNumber: "150",
    SessionNumber: "132",
    UserId: "ARC1",
    IsProposed: false,
    CreatedOn: "11-Aug-2014 12:11",
    Source: "Utility",
    TransactionId: "214247",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("15-Dec-2014 0:00"),
    TransType: "WA03",
    TransactionAmount: 65.45,
    IsCancelled: false,
    Description: "Water Consumption",
    Notes: "Usage: 121KL",
    JournalNumber: "733",
    SessionNumber: "617",
    UserId: "ARC1",
    IsProposed: false,
    CreatedOn: "7-Dec-2014 13:30",
    Source: "Utility",
    TransactionId: "260515",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("29-Apr-2015 0:00"),
    TransType: "Usage",
    TransactionAmount: 81.85,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 145kl",
    JournalNumber: "1290",
    SessionNumber: "1051",
    UserId: "ARC1",
    IsProposed: false,
    CreatedOn: "27-Apr-2015 11:11",
    Source: "Change of Ownership",
    TransactionId: "308886",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("4-Aug-2015 0:00"),
    TransType: "Usage",
    TransactionAmount: 40.7,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 76kl",
    JournalNumber: "1711",
    SessionNumber: "1353",
    UserId: "ARC1",
    IsProposed: false,
    CreatedOn: "2-Aug-2015 10:58",
    Source: "Change of Ownership",
    TransactionId: "335492",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("9-Dec-2015 0:00"),
    TransType: "Usage",
    TransactionAmount: 33.6,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 58kl",
    JournalNumber: "2436",
    SessionNumber: "1973",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "6-Dec-2015 14:54",
    Source: "Change of Ownership",
    TransactionId: "386989",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("19-Apr-2016 0:00"),
    TransType: "Usage",
    TransactionAmount: 80.36,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 133kl",
    JournalNumber: "2950",
    SessionNumber: "2410",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "12-Apr-2016 15:24",
    Source: "Change of Ownership",
    TransactionId: "411241",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("4-Sep-2016 0:00"),
    TransType: "Usage",
    TransactionAmount: 36.6,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 63kl",
    JournalNumber: "3580",
    SessionNumber: "2903",
    UserId: "AA147",
    IsProposed: false,
    CreatedOn: "31-Aug-2016 9:19",
    Source: "Change of Ownership",
    TransactionId: "444713",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("22-Dec-2016 0:00"),
    TransType: "Usage",
    TransactionAmount: 49.5,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 77kl",
    JournalNumber: "4322",
    SessionNumber: "3451",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "20-Dec-2016 16:01",
    Source: "Change of Ownership",
    TransactionId: "484493",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("26-Apr-2017 0:00"),
    TransType: "Usage",
    TransactionAmount: 62.04,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 96kl",
    JournalNumber: "4887",
    SessionNumber: "3905",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "26-Apr-2017 11:12",
    Source: "Change of Ownership",
    TransactionId: "517177",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("26-Jul-2017 0:00"),
    TransType: "Usage",
    TransactionAmount: 43.56,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 67kl",
    JournalNumber: "5280",
    SessionNumber: "4261",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "20-Jul-2017 14:24",
    Source: "Change of Ownership",
    TransactionId: "554576",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("4-Dec-2017 0:00"),
    TransType: "Usage",
    TransactionAmount: 31.39,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 45kl",
    JournalNumber: "6080",
    SessionNumber: "4913",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "27-Nov-2017 13:53",
    Source: "Change of Ownership",
    TransactionId: "622718",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("15-Apr-2018 0:00"),
    TransType: "Usage",
    TransactionAmount: 38.69,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 55kl",
    JournalNumber: "6736",
    SessionNumber: "5395",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "11-Apr-2018 9:21",
    Source: "Change of Ownership",
    TransactionId: "652707",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("12-Aug-2018 0:00"),
    TransType: "Usage",
    TransactionAmount: 43.07,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 61 kl",
    JournalNumber: "7224",
    SessionNumber: "5820",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "7-Aug-2018 8:37",
    Source: "Change of Ownership",
    TransactionId: "685405",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("9-Dec-2018 0:00"),
    TransType: "Usage",
    TransactionAmount: 60.83,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 81 kl",
    JournalNumber: "7891",
    SessionNumber: "6392",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "5-Dec-2018 14:44",
    Source: "Change of Ownership",
    TransactionId: "726456",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("2-Apr-2019 0:00"),
    TransType: "Usage",
    TransactionAmount: 59.29,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 79kl",
    JournalNumber: "8416",
    SessionNumber: "6897",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "26-Mar-2019 15:08",
    Source: "Change of Ownership",
    TransactionId: "775189",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("22-Jul-2019 0:00"),
    TransType: "Usage",
    TransactionAmount: 255.3,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 245kl",
    JournalNumber: "8900",
    SessionNumber: "7278",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "17-Jul-2019 13:30",
    Source: "Change of Ownership",
    TransactionId: "798615",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("9-Dec-2019 0:00"),
    TransType: "Usage",
    TransactionAmount: 63.91,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 85kl",
    JournalNumber: "9511",
    SessionNumber: "7833",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "7-Dec-2019 11:06",
    Source: "Change of Ownership",
    TransactionId: "858785",
  },
  {
    AssessmentNumber: "32160450",
    DateTime: new Date("31-Mar-2020 0:00"),
    TransType: "Usage",
    TransactionAmount: 70.07,
    IsCancelled: false,
    Description: "Water Usage Charge",
    Notes: "Usage: 93kl",
    JournalNumber: "9989",
    SessionNumber: "8273",
    UserId: "BP135",
    IsProposed: false,
    CreatedOn: "30-Mar-2020 7:23",
    Source: "Change of Ownership",
    TransactionId: "914341",
  },
];
