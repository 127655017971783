import { DATE_FORMAT } from "@common/constants/common-format";
import { NotificationType } from "@common/constants/notificationtype";
import { ContactRegisterForm } from "@common/pages/contacts/manage-contact/model";
import { useGlobalStore } from "@common/stores/global/store";
import { nameOfFactory, populateValue } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox, RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IContactFormElementProps {
  formRenderProps: FormRenderProps;
}

const ContactFormElement = ({ formRenderProps }: IContactFormElementProps) => {
  const { setIsNotify, setNotificationType, setNotificationsList } =
    useGlobalStore();
  const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
  const nameOf = nameOfFactory<ContactRegisterForm>();

  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }

    if (!formRenderProps.valid) return;

    setIsNotify(true);
    setNotificationType(NotificationType.Warning);
    setNotificationsList(["The form is not modified"]);

    setTimeout(() => {
      setIsNotify(false);
    }, 3000);
  };
  const getValue = formRenderProps.valueGetter;
  const genderData = [
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Male",
      value: "male",
    },
  ];
  return (
    <FormElement
      onSubmit={formRenderProps.onSubmit}
      className="cc-contact-form-element"
    >
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Entity ID</label>
            <Field
              name={nameOf("EntityId")}
              component={NumericTextBox}
              disabled
              placeholder={"Entity Id"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Application number</label>
            <Field
              name={nameOf("ApplicationNumber")}
              component={NumericTextBox}
              disabled
              placeholder={"Application Number"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Display name</label>
            <Field
              name={nameOf("DisplayName")}
              component={CCInput}
              placeholder={"Display Name"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">First name</label>
            <Field
              name={nameOf("FirstName")}
              component={CCInput}
              placeholder={"First Name"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Last name</label>
            <Field
              name={nameOf("LastName")}
              component={CCInput}
              placeholder={"Last Name"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Distinction</label>
            <Field
              name={nameOf("Distinction")}
              component={CCInput}
              placeholder={"Distinction"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Occupation</label>
            <Field
              name={nameOf("Occupation")}
              component={CCInput}
              placeholder={"Occupation"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Gender</label>
            <Field
              data={genderData}
              name={nameOf("Gender")}
              component={RadioGroup}
              layout="horizontal"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Date of birth</label>
            <Field
              name={nameOf("DateOfBirth")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Date of death</label>
            <Field
              name={nameOf("DateOfDeath")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Private address</label>
            <div className="cc-label-field">
              {populateValue(getValue("PrivateAddress"))}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Work address</label>
            <div className="cc-label-field">
              {populateValue(getValue("WorkAddress"))}
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">More address</label>
            <div className="cc-label-field">
              {populateValue(getValue("MoreAddress"))}
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Home phone</label>
            <Field
              name={nameOf("HomePhone")}
              placeholder={"Home Phone"}
              component={CCInputPhone}
              validator={validatePhoneNumber}
              onChangeForm={formRenderProps.onChange}
              skipValidatePhoneMask={!isEnableValidatePhoneNumber}
              mode={EModePhoneNumber.HomePhone}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Mobile</label>
            <Field
              name={nameOf("Mobile")}
              placeholder={"Mobile"}
              component={CCInputPhone}
              validator={validatePhoneNumber}
              onChangeForm={formRenderProps.onChange}
              skipValidatePhoneMask={!isEnableValidatePhoneNumber}
              mode={EModePhoneNumber.Mobile}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Work phone</label>
            <Field
              name={nameOf("WorkPhone")}
              placeholder={"Work Phone"}
              component={CCInputPhone}
              validator={validatePhoneNumber}
              onChangeForm={formRenderProps.onChange}
              skipValidatePhoneMask={!isEnableValidatePhoneNumber}
              mode={EModePhoneNumber.WorkPhone}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Contact classification name</label>
            <Field
              name={nameOf("ContactClassification_Name")}
              component={CCInput}
              placeholder={"Contact Classification Name"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Preferred method name</label>
            <Field
              name={nameOf("PreferredMethod_Name")}
              component={CCInput}
              placeholder={"PreferredMethod Name"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Fax</label>
            <Field
              name={nameOf("Fax")}
              placeholder={"Fax"}
              component={CCInputPhone}
              validator={validatePhoneNumber}
              onChangeForm={formRenderProps.onChange}
              skipValidatePhoneMask={!isEnableValidatePhoneNumber}
              mode={EModePhoneNumber.Fax}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">NAR number</label>
            <Field
              name={nameOf("NARNumber")}
              component={CCInput}
              placeholder={"NAR Number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">NAR updated</label>
            <Field
              name={nameOf("NARUpdated")}
              component={CCInput}
              placeholder={"NAR Updated"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Send SMS alerts</label>
            <Field
              name={nameOf("Flag_SendSMSAlerts")}
              component={CCInput}
              placeholder={"Flag_SendSMSAlerts"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Custom tags</label>
            <Field
              name={nameOf("Custom_Tags")}
              component={CCInput}
              placeholder={"Custom Tags"}
            />
          </div>
          <div className="cc-field">
            <Field
              label="Email"
              name={nameOf("Email")}
              placeholder={"Email"}
              component={CCInputEmail}
              validator={validateEmail}
              onChangeForm={formRenderProps.onChange}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Website</label>
            <Field
              name={nameOf("Website")}
              component={CCInput}
              placeholder={"Website"}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Preferred address</label>
            <Field
              name={nameOf("PreferredAddress")}
              component={CCInput}
              placeholder={"PreferredAddress"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Organisation</label>
            <Field
              name={nameOf("Organisation")}
              component={CCInput}
              placeholder={"Organisation"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postal address</label>
            <Field
              name={nameOf("PostalAddress")}
              component={CCInput}
              placeholder={"Postal address"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Last changed</label>
            <Field
              name={nameOf("LastChanged")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Changed By</label>
            <Field
              name={nameOf("ChangedBy")}
              component={CCInput}
              disabled
              placeholder={"Changed By"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Is obsolete</label>
            <Field name={nameOf("IsObsolete")} component={CCSwitch} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Notes</label>
            <Field
              name={nameOf("Notes")}
              component={CCTextArea}
              disabled
              placeholder={"Notes"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <label className="cc-label">Electoral commission</label>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Person number</label>
            <Field
              name={nameOf("PersonNumber")}
              component={NumericTextBox}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Silent enrolment</label>
            <Field name={nameOf("SilentEnrolment")} component={CCSwitch} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Voter registration</label>
            <Field
              name={nameOf("VoterRegistration")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Update date</label>
            <Field
              name={nameOf("UpdateDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
        </div>
      </section>
    </FormElement>
  );
};

export default observer(ContactFormElement);
