import { loadDebtRecoveryAssessmentData } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/assessments/api";
import { DTO_DebtRecovery_Assessment } from "@app/products/property/assessments/debt-recovery/[id]/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class DebtRecoveryAssessmentStore {
  private _assessment?: DTO_DebtRecovery_Assessment = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get assessment() {
    return this._assessment;
  }
  setAssessment = (assessment?: DTO_DebtRecovery_Assessment) => {
    runInAction(() => {
      this._assessment = assessment;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this.setAssessment(undefined);
      this.setIsLoading(false);
    });
  };

  loadAssessment = async (debtRecoveryId: number) => {
    this.setIsLoading(true);
    const assessment = await loadDebtRecoveryAssessmentData(debtRecoveryId);
    this.setAssessment(assessment);
    this.setIsLoading(false);
  };
}

const debtRecoveryAssessmentStoreContext = createContext(
  new DebtRecoveryAssessmentStore()
);
export const useDebtRecoveryAssessmentStore = () => {
  return useContext(debtRecoveryAssessmentStoreContext);
};
