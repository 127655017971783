import { ICalculateValueBalance } from "@/app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/model";
import { isEmpty, isNull, isNumber, isUndefined } from "lodash";

export const validatorBalanceAdjustment = (values: any): string | undefined => {
  const transactionType: string | undefined = values["TransactionType"];
  const reference: string | undefined = values["Reference"];
  const adjustment: number | undefined | null = values["Adjustment"];
  const newBalance: number | undefined | null = values["NewBalance"];
  if (
    transactionType &&
    reference &&
    !isEmpty(reference.trim()) &&
    isNumber(adjustment) &&
    isNumber(newBalance)
  )
    return undefined;
  return "Please complete all mandatory fields.";
};

export const calculateValueAdjustment = ({
  existValue,
  value,
}: ICalculateValueBalance): number | null => {
  if (isNullOrUndefined(existValue, value)) return null;
  if (!isNumber(existValue)) existValue = 0;
  if (!isNumber(value)) value = 0;

  return value - existValue;
};

export const calculateValueNewBalance = ({
  existValue,
  value,
}: ICalculateValueBalance): number | null => {
  if (isNullOrUndefined(existValue, value)) return null;
  if (!isNumber(existValue)) existValue = 0;
  if (!isNumber(value)) value = 0;

  return value + existValue;
};

const isNullOrUndefined = (
  firstValue?: number | null,
  secondValue?: number | null
): boolean => {
  return (
    (isNull(firstValue) || isUndefined(firstValue)) &&
    (isNull(secondValue) || isUndefined(secondValue))
  );
};
