import { UnitPercentageCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/components/unit-percentage-cell/_index";
import { UnitRoleCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/components/unit-role-cell/_index";
import {
  DTO_Contact,
  DTO_Entity_Details,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { PercentageCell } from "@components/cc-grid/components/grid-cells/percentage/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { ICCTooltipProps } from "@components/cc-tooltip/_index";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
const nameOfNames = nameOfFactory<DTO_Entity_Details>();
const nameOfContact = nameOfFactory<DTO_Contact>();

export const fieldName = {
  Names: nameOfContact("Contacts"),
  NameSelected: "_option.NameSelected",
  SearchName: "SearchName",
  Role: nameOfNames("Role_Name"),
  Percentage: nameOfNames("Percentage"),
  Name: nameOfNames("Name"),
  PrimaryKey: nameOfNames("Entity_Id"),
  UniqueKey: nameOfNames("Id"),
  FirstEntity: "_option.FirstEntity",
  AttentionOf: nameOfContact("Attention_Of"),
  CareOf: nameOfContact("Care_Of"),
  Address: nameOfContact("Address"),
  Locality: nameOfContact("Locality"),
  State: nameOfContact("State"),
  Country: nameOfContact("Country"),
  Postcode: nameOfContact("Postcode"),
  DPID: nameOfContact("DPID"),
  NoticeId: nameOfContact("Notice_Id"),
  FormattedNameAddress: nameOfContact("Formatted_Name_Address"),
  isNeedToResetFormatted: "_option.IsNeedToResetFormatted",
  isPreventPopulateNames: "_option.IsPreventPopulateNames",
  InitAssociatedNamesDetails: "InitAssociatedNamesDetails",
  InitRatePayers: "InitRatePayers",
  RemovedNamesHasUnavailableRole: "_option.RemovedNamesHasUnavailableRole",
};

export const colNames: IColumnFields[] = [
  { field: nameOfNames("Name"), title: "Name" },
  {
    field: nameOfNames("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    href: (dataItem: DTO_Entity_Details) => ({
      href: `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
      target: "_blank",
    }),
  },
  {
    field: nameOfNames("Role_Name"),
    title: "Role",
    minWidth: 150,
    editCell: UnitRoleCell,
    editable: true,
  },
  {
    field: nameOfNames("Percentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    editCell: UnitPercentageCell,
    editable: true,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(fieldName.Percentage, gridData),
    footerCell: (
      <PercentageCell
        style={(value) => ({
          textAlign: "right",
          color: value === 100 ? "var(--success)" : "var(--error)",
        })}
      />
    ),
  },
  { field: nameOfNames("Address"), title: "Address" },
  {
    field: nameOfNames("Entity_Name_Address_Id"),
    title: "Contact Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colNamesHiddenPercenCol: IColumnFields[] = colNames.filter(
  (col) => col.field !== fieldName.Percentage
);

export const changeOfOwnershipNameStepTooltips: ICCTooltipProps[] = [
  {
    type: "validator",
    position: "right",
    content:
      "You must have at least one record to go to the next step and role is required",
  },
  {
    type: "info",
    position: "right",
    content: "Percentage is only required for the Owner role.",
  },
];
