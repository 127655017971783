import { PROPERTY_ASSESSMENT_FINANCIAL_SUMMARIES_ROUTE } from "@app/products/property/assessments/financial-summaries/[id]/constant";
import { VO_Assessment_Financial_Summary } from "@app/products/property/assessments/financial-summaries/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Assessment_Financial_Summary>();

export const colFinancialSummaries: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_FINANCIAL_SUMMARIES_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("Summarised_By"),
    title: "Summarised By",
  },
  {
    field: nameOf("TransactionDefaultPeriod"),
    title: "Transaction Default Period",
  },
  {
    field: nameOf("SessionDefaultPeriod"),
    title: "Session Default Period",
  },
  {
    field: nameOf("IncludePostponedCharges"),
    title: "Include Postponed Charges",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IncludeDeferredArrears"),
    title: "Include Deferred Arrears",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IncludeAmountsYetToUpdate"),
    title: "Include Amounts Yet to Update",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IncludeProposedAmounts"),
    title: "Include Proposed Amounts",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
