import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postImportLeaseTransfer = async (
  formData: FormData
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `api/property/int/leasetransfer/import`,
      formData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
