import { apiCoreBatchAcknowledgeEvents } from "@app/core/components/common/utils";
import { CS_LookupTable } from "@app/products/crms/[id]/model";
import {
  EventBatchAcknowledge,
  EventBatchAcknowledgeResponse,
} from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getAcknowledgeMethodLovs = async (
  type: CS_LookupTable
): Promise<APIResponse<IKeyValuePacket[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      "/api/crms/internal/system-admin/lookup-table/get-lookup-values-lov",
      {
        params: {
          type: type,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveAcknowledgement = async (
  eventBatchAcknowledge: EventBatchAcknowledge
): Promise<APIResponse<IIdentityPacket<EventBatchAcknowledgeResponse>>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<EventBatchAcknowledgeResponse>
    >(apiCoreBatchAcknowledgeEvents(), eventBatchAcknowledge);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
