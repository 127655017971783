import { KeyValuePair } from "@app/core/documents/model";
import { Event } from "@app/products/crms/[id]/model";
export interface EventClose {
  ShowCallReturnRequired: boolean;
  DateClosed: Date | null;
  ClosureComment: string;
  ClosureComment_LookupValue_IDs: number[];
  AcknowledgementRequired: boolean;
  DateAcknowledged: Date | null;
  AcknowledgedMethod_ID: number | null;
  ShowFinalNotification: boolean;
  FinalNotificationText: string;
  DateFinalNoticeSent: Date | null;
  FinalNoticeSentMethod_ID: number | null;
  FinalNoticeSentMethod_Display: string;
  ShowNotifyCustomer: boolean;
  LockNotifyCustomer: boolean;
  NotifyCustomer: boolean | null;
  ShowSameIssue: boolean;
  SameIssue: boolean | null;
  EnvHealthIssue_ID: number | null;
  EnvHealthAction_ID: number | null;
  EnvHealthResult_ID: number | null;
  EnvHealthFoodType_ID: number | null;
  ShowHealthFields: boolean;
  CallReturned: boolean | null;
  EnableNuisanceAct: boolean | null;
  EnableSchoolPatrols: boolean | null;
  NoOfvisits_SchoolPatrols: number | null;
  TimeTaken_SchoolPatrols: number | null;
  NoOfExpitations_SchoolPatrols: number | null;
  TimeTaken_NuisanceAct: number | null;
  ComplexityGrouped_NuisanceAct: number | null;
  NoOfAbatement_NuisanceAct: number | null;
  NoOfExpitations_NuisanceAct: number | null;
  Flag_AssetSendToAssetSystemOnClosure: boolean | null;
  AssetEnquiryRefNo: string;
  SendAssetToReflect: boolean | null;
  CommunicationMethod_Mobile: string;
  CommunicationMethod_WorkPhone: string;
  CommunicationMethod_HomePhone: string;
  CommunicationMethod_Email: string;
  CommunicationMethod_Fax: string;
  ShowInternalComments: boolean;
  RequestedByPreferred: boolean;
  RequestedByPreferredEmail: boolean;
  RequestedByPreferredHomePhone: boolean;
  RequestedByPreferredWorkPhone: boolean;
  RequestedByPreferredMobile: boolean;
  RequestedByPreferredFax: boolean;
  RequestedByEmail: boolean;
  RequestedBySMS: boolean;
  RequestedByInternal: boolean;
  RequestedByFax: boolean;
  DateSent: Date | null;
}

export interface EventCloseLovs {
  AcknowledgedHow: KeyValuePair<number, string>[];
  FinalNoticeSentBy: KeyValuePair<number, string>[];
  ComplexityGrouped_NuisanceAct: KeyValuePair<number, string>[];
  HealthIssue: KeyValuePair<number, string>[];
  HealthAction: KeyValuePair<number, string>[];
  HealthResult: KeyValuePair<number, string>[];
  HealthFoodType: KeyValuePair<number, string>[];
}

export interface EventCloseSetup {
  EventClose: EventClose;
  EventCloseLovs: EventCloseLovs;
}

export interface CloseEventRequest {
  EventClose: EventClose;
  Event: Event;
}

export enum CS_CommunicationMethod {
  Default = 0,
  Email = 1,
  Fax = 2,
  InPerson = 3,
  Letter = 4,
  Other = 5,
  Telephone = 6,
  Correspondence = 7,
  Preferred = 8,
  SMS = 9,
}
