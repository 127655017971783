import { IBuildingEssentialSafetyFormData } from "@app/products/building/registers/essential-safety/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { loadBuildingEssentialSafetyById } from "./api";

export const EssentialSafetyDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [essentialSafetyInfo, setEssentialSafetyInfo] = useState<
    IBuildingEssentialSafetyFormData | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  useEffect(() => {
    if ((lastSelectedRow && lastSelectedRow?.ID) || params.id) {
      const essentialSafetyId: any = lastSelectedRow?.ID || params.id;
      setIsLoading(true);
      loadBuildingEssentialSafetyById(essentialSafetyId).then((data) => {
        setEssentialSafetyInfo(data);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, params.id]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!essentialSafetyInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Register Number:"}
              value={essentialSafetyInfo?.RegisterNumber?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Status:"}
              value={essentialSafetyInfo?.Status}
              success
            />
            <ProductReferenceRow
              title={"OS Fees:"}
              value={formatDisplayValue(
                essentialSafetyInfo?.OSFees,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
            <ProductReferenceRow
              title={"Created Date:"}
              value={formatDisplayValue(
                essentialSafetyInfo?.CreatedDate,
                DATE_FORMAT.DATE
              )}
            />
            <ProductReferenceRow
              title={"Lodged Date:"}
              value={formatDisplayValue(
                essentialSafetyInfo?.Lodged,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
