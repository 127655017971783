import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import {
  COMMON_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colMyApplications: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.LodgedDate,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Postcode,
    title: "Postcode",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.StatusWithSubStatus,
    title: "Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.AppealDays,
    title: "Appeal Days",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Planner,
    title: "Planner",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ReasonForPermit,
    title: "Reason for Permit",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Rating,
    title: "Rating",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Decision,
    title: "Decision",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DecisionDate,
    title: "Decision Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.FinalDecision,
    title: "Final Decision",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.FinalDecisionDate,
    title: "Final Decision Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Applicant,
    title: "Applicant",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Contact,
    title: "Contact",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ApplicantAddress,
    title: "Address",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.FileNo,
    title: "File Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.AssessmentNo,
    title: "Assess Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Ward,
    title: "Ward",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CostOfWorks,
    title: "Cost of Works",
    width: 150,
    format: COMMON_FORMAT.COST_GRID,
  },
  {
    field: TownPlanningFieldMapping.StatDays,
    title: "Stat Days",
    width: 150,
  },
];
