import { getRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/api";
import { IRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class RegisterTransactionsStore {
  private _registerTransactions?: IRegisterTransactions[] = undefined;
  private _isLoading: boolean = false;
  private _selectedRegisterTransactions: IRegisterTransactions[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get registerTransactions() {
    return toJS(this._registerTransactions);
  }
  setRegisterTransactions = (
    registerTransactions: IRegisterTransactions[] | undefined
  ) => {
    runInAction(() => {
      this._registerTransactions = registerTransactions;
    });
  };

  get selectedRegisterTransactions() {
    return toJS(this._selectedRegisterTransactions);
  }
  setSelectedRegisterTransactions = (
    selectedRegisterTransactions: IRegisterTransactions[] | undefined
  ) => {
    runInAction(() => {
      this._selectedRegisterTransactions = selectedRegisterTransactions ?? [];
    });
  };
  get isEmptySelectedTransaction(): boolean {
    return toJS(this._selectedRegisterTransactions).length === 0;
  }

  //Action

  resetStore = () => {
    runInAction(() => {
      this._registerTransactions = undefined;
      this._isLoading = false;
      this._selectedRegisterTransactions = [];
    });
  };

  loadRegisterTransactions = async () => {
    this.setIsLoading(true);
    const data = await getRegisterTransactions();
    this.setRegisterTransactions(data);
    this.setIsLoading(false);
  };
}
const registerTransactionsStoreContext = createContext(
  new RegisterTransactionsStore()
);
export const useRegisterTransactionsStore = () => {
  return useContext(registerTransactionsStoreContext);
};
