import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { journalsRoute } from "@app/products/property/journals/route";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { colJournals } from "@app/products/property/journals/list/config";
import { IColumnFields } from "@components/cc-grid/model";
import { useEffectOnce } from "react-use";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { PROPERTY_MY_JOURNAL_LISTS_VIEW_URL } from "@app/products/property/journals/my-journal/constant";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { nameOfFactory } from "@common/utils/common";

const nameOf = nameOfFactory<VO_JournalList>();

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColList, setProcessedColList] =
    useState<IColumnFields[]>(colJournals);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_Journal,
      colJournals
    );
    if (Array.isArray(response)) {
      setProcessedColList(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    (async () => await loadViewConfig())();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      columnFields={processedColList}
      dataUrl={PROPERTY_MY_JOURNAL_LISTS_VIEW_URL}
      primaryField={nameOf("Journal_Number")}
      state={{ sort: [{ field: nameOf("Journal_Number"), dir: "desc" }] }}
    />
  );
};
