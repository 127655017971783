import { ExistManageBuilding } from "@app/products/building/[id]/components/forms/existed/_index";
import { NewManageBuilding } from "@app/products/building/[id]/components/forms/new/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React from "react";

const ManageBuilding = observer(() => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewManageBuilding />;
  }

  return <ExistManageBuilding />;
});

export default ManageBuilding;
