import {
  DropDownList,
  DropDownListChangeEvent,
  DropDownListPageChangeEvent,
  DropDownListProps,
  VirtualizationSettings,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import React, { useCallback, useMemo, useState } from "react";
interface ICCDropDownListProps {
  validationMessage?: string | null;
  visited?: boolean;
  isKeyValueDropdown?: boolean;
  total?: number;
  pageSize?: number;
}
const DEFAULT_TEXT_FIELD = "Value";
const DEFAULT_VALUE_FIELD = "Key";

export const CCDropDownList = (
  props: ICCDropDownListProps & DropDownListProps
) => {
  const {
    validationMessage,
    visited,
    isKeyValueDropdown,
    value,
    onChange,
    data,
    textField,
    dataItemKey,
    total,
    pageSize,
    ...others
  } = props;

  const [state, setState] = useState({
    skip: 0,
    subsetData: (data ?? []).slice(0, pageSize),
  });

  const pageChange = (event: DropDownListPageChangeEvent) => {
    const skip = event.page.skip;
    const take = event.page.take;
    const newSubsetData = (data ?? []).slice(skip, skip + take);

    setState({
      subsetData: newSubsetData,
      skip: skip,
    });
  };

  const virtual: VirtualizationSettings | undefined = useMemo(
    () =>
      total && pageSize && total > 0 && pageSize > 0
        ? {
            total: total,
            pageSize: pageSize,
            skip: state.skip,
          }
        : undefined,

    [total, pageSize, state.skip]
  );

  const getPropsDataFields = (data?: string, defaultValue?: string) => {
    if (isKeyValueDropdown) return data ? data : defaultValue;
    if (data) return data;
    return undefined;
  };

  const newTextField = getPropsDataFields(textField, DEFAULT_TEXT_FIELD);
  const newDataItemKey = getPropsDataFields(dataItemKey, DEFAULT_VALUE_FIELD);

  const dropdownValue = useMemo(() => {
    if (isKeyValueDropdown)
      return data?.find(
        (obj) => obj[newDataItemKey ?? DEFAULT_VALUE_FIELD] === value
      );
    return value;
  }, [value, data, isKeyValueDropdown, newDataItemKey]);

  const handleOnChange = useCallback(
    (event: DropDownListChangeEvent) => {
      if (!onChange) return;
      if (isKeyValueDropdown)
        return onChange({
          ...event,
          value: event.value[newDataItemKey ?? DEFAULT_VALUE_FIELD],
        });
      onChange(event);
    },
    [onChange, newDataItemKey, isKeyValueDropdown]
  );

  return (
    <>
      <DropDownList
        data={total && pageSize ? state.subsetData : data}
        value={dropdownValue}
        onChange={handleOnChange}
        textField={newTextField}
        dataItemKey={newDataItemKey}
        virtual={virtual}
        onPageChange={total && pageSize ? pageChange : undefined}
        {...others}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
