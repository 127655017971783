import {
  EventLookupTableLNK,
  ServiceStandardUpdateTriggers,
} from "@app/products/crms/[id]/model";
import { CRMSServiceStandardFormElement } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import {
  EventLookupTableLNKWithName,
  ServiceStandard,
  ServiceStandardMapObj,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import "./_index.scss";

export const CRMSServiceStandardForm = observer(() => {
  const isNew = useIsNew();
  const { pushNotification } = useCCAppNotificationStore();
  const {
    crmsServiceStandard,
    setOnSubmit,
    submitAction,
    serviceStandardLovs,
  } = useCRMSServiceStandardStore();

  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const issueLocationTypes = crmsServiceStandard?.IssueLocationTypes;
  const stdLovsIssueLocationTypes = serviceStandardLovs?.IssueLocationTypes;
  const resolutionResponses = crmsServiceStandard?.ResolutionResponses;
  const stdLovsResolutionResponses = serviceStandardLovs?.ResolutionResponses;

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const { values, isValid, isModified } = event;
    const serviceStandard = values.ServiceStandard;

    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    if (
      !serviceStandard?._SaveTriggers?.includes(
        ServiceStandardUpdateTriggers.SaveServiceStandard
      )
    ) {
      serviceStandard?._SaveTriggers?.push(
        ServiceStandardUpdateTriggers.SaveServiceStandard
      );
    }

    let newIssueLocationTypes: EventLookupTableLNK[] = [];

    if (
      serviceStandard.IssueLocationTypes &&
      serviceStandard.IssueLocationTypes.length > 0
    ) {
      newIssueLocationTypes = serviceStandard.IssueLocationTypes.map(
        (item: any) => {
          const { Name, ...newItem } = item;
          return newItem;
        }
      );
    }

    const newValues = {
      ...serviceStandard,
      IssueLocationTypes: newIssueLocationTypes ?? [],
    };

    if (newValues) {
      submitAction(
        newValues as ServiceStandard,
        isNew,
        ActionSubmitActions.Save
      );
    }
  };

  const newIssueLocationTypes = useMemo(() => {
    const issueLocationTypesIds = issueLocationTypes?.map(
      (item: EventLookupTableLNK) => item.LookupTable_Id
    );
    let data: EventLookupTableLNKWithName[] = [];
    if (stdLovsIssueLocationTypes && issueLocationTypesIds) {
      data = stdLovsIssueLocationTypes.filter(
        (item: EventLookupTableLNKWithName) =>
          issueLocationTypesIds.includes(item.LookupTable_Id)
      );
    }

    return data;
  }, [issueLocationTypes, stdLovsIssueLocationTypes]);

  const newResolutionResponses = useMemo(() => {
    const resolutionResponsesIds = resolutionResponses?.map(
      (item: EventLookupTableLNK) => item.LookupTable_Id
    );

    let data: EventLookupTableLNKWithName[] = [];
    if (stdLovsResolutionResponses && resolutionResponsesIds) {
      data = stdLovsResolutionResponses.filter(
        (item: EventLookupTableLNKWithName) =>
          resolutionResponsesIds.includes(item.LookupTable_Id)
      );
    }

    return data;
  }, [resolutionResponses, stdLovsResolutionResponses]);

  const initialValues: ServiceStandardMapObj = useMemo(() => {
    return {
      ServiceStandard: {
        ...crmsServiceStandard,
        IssueLocationTypes: newIssueLocationTypes,
        ResolutionResponses: newResolutionResponses,
      },
    } as ServiceStandardMapObj;
  }, [crmsServiceStandard, newIssueLocationTypes, newResolutionResponses]);

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, crmsServiceStandard]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CRMSServiceStandardFormElement formRenderProps={formRenderProps} />
          );
        }}
      />
    </div>
  );
});
