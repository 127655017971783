import { DTO_Journal_LevyDetails } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-charges/components/form-elements/charge/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CancelToken } from "axios";

export const getLevyDetail = async (
  levyId: number,
  ratingPeriodId: number,
  isProRata: boolean = false,
  fromDate: Date | null = null,
  amount: number = 0,
  options?: { token: CancelToken }
): Promise<APIResponse<DTO_Journal_LevyDetails>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `api/property/internal/journal/levydetails`,
      {
        params: {
          levyId: levyId,
          ratingPeriodId: ratingPeriodId,
          isProRata: isProRata,
          fromDate: fromDate,
          amount: amount,
        },
        cancelToken: options?.token,
      }
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
