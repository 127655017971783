import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const picChargeRunsRoute: ICCRoute = {
  path: "pic-charge-runs",
  name: "PIC Charge Runs",
  enum: eMenuAction.ChargeRun_PICChargeRun_List,
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
