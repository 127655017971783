import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RegionTabDetails } from "./model";

export const getRegionDetailsTabById = async (
  regionID: number
): Promise<APIResponse<RegionTabDetails | null>> => {
  try {
    return await CoreAPIService.getClient().get<RegionTabDetails>(
      `/api/core/internal/region/${regionID}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
