export const ParkingPermitsFieldMapping = {
  ProofofResidency1: "Proof of Residency 1",
  ProofofResidency2: "Proof of Residency 2",
  ProofofMembership: "Proof of Membership",
  RegistrationState: "Registration State",
  ProofofRegistration: "Proof of Registration",
  Colour: "Colour",
  Make_Model: "Make_Model",
  OriginalIssueDate: "Original Issue Date",
  PermitAreaAllowed: "PermitAreaAllowed",
  BusinessPhone: "BusinessPhone",
  MobilePhone: "MobilePhone",
  FileNumber: "FileNumber",
  IssueDate: "IssueDate",
  IssueTime: "IssueTime",
  ExpiryTime: "ExpiryTime",
  DebtorNumber: "DebtorNumber",
  OSFees: "OSFees",
  HomePhone: "HomePhone",
  OnlyValid: "OnlyValid",
  Comments: "Comments",
  SpecialPurpose: "SpecialPurpose",
  PropertyNumber: "PropertyNumber",
  VehicleType: "VehicleType",
  ContactPermitHolder: "ContactPermitHolder",
  AddressPermitHolder: "AddressPermitHolder",
  OnBehalfOf: "OnBehalfOf",
  BehalfofProps: "BehalfofProps",
  IsSameAsPermitAddress: "IsSameAsPermitAddress",
  PermitAddress: "PermitAddress",
  PostalAddress: "PostalAddress",
  ContactNumPerson: "ContactNumPerson",
  ContactPerson: "ContactPerson",
  PermitType_Name: "PermitType_Name",
  PermitHolder_DisplayName: "PermitHolder_DisplayName",
  StreetName: "StreetName",
  Suburb: "Suburb",
  Address_Search: "Address_Search",
  Permit_Status: "Permit_Status",
  Permit_SubStatus: "Permit_SubStatus",
  IssuedDate: "IssuedDate",
  ExpiryDate: "ExpiryDate",
  PermitNumber: "PermitNumber",
  RegNo: "RegNo",
  PermitArea: "PermitArea",
  ZoneCode: "ZoneCode",
  ZoneName: "ZoneName",
  PermitCategory_Name: "PermitCategory_Name",
  StaffArea: "StaffArea",
  StaffOrg: "StaffOrg",
  FeesOutstanding: "FeesOutstanding",
  PrintStatus: "PrintStatus",
  Status: "Status",
  Permit_DisplayStatus: "Permit_DisplayStatus",
  PermitHolder_Email: "PermitHolder_Email",
  ReferenceNumber: "ReferenceNumber",
  PermitHolder: "PermitHolder",
  CreatedDate: "CreatedDate",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  InvoiceNumber: "InvoiceNumber",
  SecurePay_Reference: "SecurePay_Reference",
  AmountPaid: "AmountPaid",
  ReceiptNumber: "ReceiptNumber",
  ReceiptDate: "ReceiptDate",
  ReceiptedBy: "ReceiptedBy",
  Method: "Method",
  SentToFinanceDate: "SentToFinanceDate",
  SentToFinanceBatch: "SentToFinanceBatch",
  Sys_DBRowState: "Sys_DBRowState",
  ResidentAreaCode: "ResidentAreaCode",
  PermitRenewalDate: "PermitRenewalDate",
  MaximumPermits: "MaximumPermits",
  NoOfFreePermits: "NoOfFreePermits",
  NoOfCost1: "NoOfCost1",
  Cost1: "Cost1",
  NoOfCost2: "NoOfCost2",
  Cost2: "Cost2",
  NoOfCost3: "NoOfCost3",
  Cost3: "Cost3",
  AllowableSuburbs: "AllowableSuburbs",
  AreaBoundaries: "AreaBoundaries",
  Area: "Area",
  Code: "Code",
  Type: "Type",
  RenewalDate: "RenewalDate",
  FullAddress: "FullAddress",
  AssessmentNo: "AssessmentNo",
  Reason: "Reason",
  Sys_CreatedBy: "Sys_CreatedBy",
  PermitSubType: "PermitSubType",
  PropertyType: "PropertyType",
  Org: "Org",
  Street: "Street",
  RenewalStatus: "RenewalStatus",
  LetterSent: "LetterSent",
};
export class ParkingPermits {
  // LOAD BY DEFAULT
  id?: string | number = "";
  ID?: string | number = "";
  PermitNumber?: string | number = "";
  PermitHolder?: string = "";
  PermitType?: string = "";
  Status?: string = "";
  Registration_ID: number = 1;
  IssueDate?: string = "";
  ExpiryDate?: string = "";
  IssueTime?: string = "";
  ExpiryTime?: string = "";
}
export interface IParkingPermits {
  ID?: string | number;
  PermitNo?: string;
  PermitType?: string;
  PostalAddress?: string;
  FileNumber?: number;
  OSFees?: number;
  Status_ENUM?: string;
  DebtorNumber?: number;
  ContactPermitHolder?: string;
  ContactPerson?: string;
  PropertyNumber?: number;
  PermitAddress?: string;
  IsSameAsPermitAddress?: boolean;
  IsPropertyAddress?: boolean;
  OnBehalfOf?: boolean;
  PermitHolder?: string;
  IssueDate?: string;
  ExpiryDate?: string;
  IssueTime?: string;
  ExpiryTime?: string;
  IsSameAsPermitAdd?: boolean;
  ContactNumPerson?: string;
  PermitType_Name?: string;
  PermitHolder_DisplayName?: string;
  StreetName?: string;
  Suburb?: string;
  Address_Search?: string;
  Permit_Status?: string;
  Permit_SubStatus?: string;
  IssuedDate?: string;
  PermitNumber?: number;
  RegNo?: number;
  PermitArea?: string;
  ZoneCode?: number;
  PermitCategory_Name?: string;
  StaffArea?: string;
  StaffOrg?: string;
  FeesOutstanding?: string;
  PrintStatus?: string;
  Status?: string;
  Permit_DisplayStatus?: string;
  PermitHolder_Email?: string;
  ReferenceNumber?: number;
  CreatedDate?: string;
  FeeType?: string;
  AmountIssued?: string;
  AmountOutstanding?: number;
  CostCode?: number;
  InvoiceNumber?: number;
  SecurePay_Reference?: number;
  AmountPaid?: string;
  ReceiptNumber?: number;
  ReceiptDate?: string;
  ReceiptedBy?: string;
  Method?: string;
  SentToFinanceDate?: string;
  SentToFinanceBatch?: number;
  Sys_DBRowState?: string;
  ResidentAreaCode?: number;
  PermitRenewalDate?: string;
  MaximumPermits?: number;
  NoOfFreePermits?: number;
  NoOfCost1?: number;
  Cost1?: number;
  NoOfCost2?: number;
  Cost2?: number;
  NoOfCost3?: number;
  Cost3?: number;
  AllowableSuburbs?: string;
  AreaBoundaries?: string;
  Area?: string;
  Code?: number;
  Type?: string;
  RenewalDate?: string;
  FullAddress?: string;
  AssessmentNo?: number;
  Reason?: string;
  Sys_CreatedBy?: string;
  PermitSubType?: string;
  PropertyType?: string;
  Org?: string;
  Street?: string;
  RenewalStatus?: string;
  LetterSent?: string;
  ZoneName?: string;
}

export interface IParkingPermitsForm {
  ID: string | number;
  PermitType_Name: string;
  PermitHolder_DisplayName: string;
  StreetName: string;
  Suburb: string;
  Address_Search: string;
  Permit_Status: string;
  Permit_SubStatus: string;
  IssuedDate: string;
  ExpiryDate: string;
  PermitNumber: number;
  RegNo: number;
  PermitArea: string;
  ZoneCode: number;
  ZoneName: string;
  PermitCategory_Name: string;
  StaffArea: string;
  StaffOrg: string;
  FeesOutstanding: string;
  PrintStatus: string;
  Status: string;
  Permit_DisplayStatus: string;
  PermitHolder_Email: string;
  ReferenceNumber: number;
  PermitHolder: string;
  CreatedDate: string;
  FeeType: string;
  AmountIssued: string;
  AmountOutstanding: number;
  CostCode: number;
  InvoiceNumber: number;
  SecurePay_Reference: number;
  AmountPaid: string;
  ReceiptNumber: number;
  ReceiptDate: string;
  ReceiptedBy: string;
  Method: string;
  SentToFinanceDate: string;
  SentToFinanceBatch: number;
  Sys_DBRowState: string;
  ResidentAreaCode: number;
  PermitRenewalDate: string;
  MaximumPermits: number;
  NoOfFreePermits: number;
  NoOfCost1: number;
  Cost1: number;
  NoOfCost2: number;
  Cost2: number;
  NoOfCost3: number;
  Cost3: number;
  AllowableSuburbs: string;
  AreaBoundaries: string;
  Area: string;
  Code: number;
  Type: string;
  RenewalDate: string;
  FullAddress: string;
  AssessmentNo: number;
  Reason: string;
  Sys_CreatedBy: string;
  PermitSubType: string;
  PropertyType: string;
  Org: string;
  Street: string;
  RenewalStatus: string;
  LetterSent: string;
}
export class IParkingPermitsRecordsState {
  actions: number = 0;
  comments: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  samples: number = 0;
}
export interface IParkingPermitsRegister {
  ID: string | number;
  PermitNo: string;
  PermitType: string;
  PermitHolder: string;
  PostalAddress: string;
  FileNumber: number;
  OSFees: string;
  Status_ENUM: string;
  DebtorNumber: number;
  ContactPermitHolder: string;
  ContactPerson: string;
  PropertyNumber: number;
  PermitAddress: string;
  IsSameAsPermitAdd: boolean;
  IsPropertyAddress: boolean;
  OnBehalfOf: boolean;
}
