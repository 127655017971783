import {
  getFromDate,
  getToDate,
} from "@app/products/ams/api/helpers/date-helper";
import { DTO_Supplementary_AssessmentLevy } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/assessment-levies/dialogs/assessment-levy/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { isAfter } from "date-fns";

export const handleValidateForm: any = (
  data: DTO_Supplementary_AssessmentLevy
) => {
  const fromDate = data.Active_From_Date
    ? getFromDate(data.Active_From_Date)
    : null;
  const toDate = data.Active_To_Date ? getToDate(data.Active_To_Date) : null;

  let errorMessage = {};

  if (fromDate && toDate) {
    if (isAfter(fromDate, toDate)) {
      errorMessage = {
        ...errorMessage,
        Active_From_Date: `Date input must be before ${formatDateByKendo(
          toDate,
          DATE_FORMAT.DATE_CONTROL
        )}`,
        Active_To_Date: `Date input must be after ${formatDateByKendo(
          fromDate,
          DATE_FORMAT.DATE_CONTROL
        )}`,
      };
    }
  }

  return errorMessage;
};
