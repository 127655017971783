import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { GET_SETTING_PPR } from "@common/configs/setting";
import { SiteBaseURL } from "@common/models/settings";
import { globalStoreInstance } from "@common/stores/global/store";

let MODE: "api" | "fake" = "api"; //@TODO:remove after done
export const getSettingsCorePPR = async (): Promise<
  APIResponse<SiteBaseURL>
> => {
  try {
    //@TODO:remove after done
    if (MODE === "fake")
      return {
        data: {
          DataType: 0,
          IsVisible: true,
          Value: "2", //PPR mode
        },
        headers: {},
        config: {},
        status: 200,
        statusText: "Ok",
      };
    return await CoreAPIService.getClient().get<SiteBaseURL>(
      `${globalStoreInstance.allSetting.baseAPIUrl}${GET_SETTING_PPR}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
