import { useInfoReceivedStore } from "@app/core/further-info/components/buttons/work-flow/info-received/store";
import {
  IInfoReceived,
  InfoReceivedDialog,
} from "@app/core/further-info/components/dialogs/info-received/_index";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { checkIsPPR } from "@app/core/further-info/[id]/util";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const InfoReceivedButton = observer(() => {
  const { isShowDialog, setIsShowDialog } = useInfoReceivedStore();
  const { furtherInfo, onSubmit, reLoadFurtherInfo } = useFurtherInfoStore();
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();
  const { ppr } = usePPRStore();

  const handleAddInfoReceived = (dataItem: IInfoReceived) => {
    setIsShowDialog(false);
    clearErrorNotification();
    reLoadFurtherInfo().then(() => {
      pushNotification({
        type: "success",
        title: "Record saved successfully",
      });
    });
  };
  const isVisible = useMemo(() => {
    if (
      ppr?.HasExtensionGranted === false &&
      !furtherInfo?.ExtensionGranted &&
      !FURTHERINFOSTATUS.InfoReceivedAwaitingAssessment
    )
      return true;
    if (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoRequestedDraft ||
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoRequested
    )
      return true;
    if (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionRequested &&
      !furtherInfo?.DateInfoReceived &&
      checkIsPPR(furtherInfo)
    )
      return true;
    if (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.ExtensionGranted &&
      !furtherInfo?.DateInfoReceived
    )
      return true;
    return false;
  }, [ppr, furtherInfo]);

  return isVisible ? (
    <>
      <CCNavButton
        title={"Further information received"}
        onClick={onSubmit}
        name={FurtherInfoSubmitActions.InfoReceived}
      />
      {isShowDialog && (
        <InfoReceivedDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleAddInfoReceived}
        />
      )}
    </>
  ) : null;
});
