import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import {
  Roster,
  RosterHandlerRequest,
  RosterHandlerResponse,
} from "@app/products/crms/system-admin/rosters/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getRosterById = async (
  rosterId: number
): Promise<APIResponse<Roster>> => {
  try {
    return await CoreAPIService.getClient().get<Roster>(
      `/api/crms/internal/system-admin/roster/${rosterId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getRosterTypes = async (): Promise<
  APIResponse<KeyValuePacket[]>
> => {
  try {
    return await CoreAPIService.getClient().get<KeyValuePacket[]>(
      "/api/crms/internal/system-admin/roster/roster-types"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveRoster = async (
  roster: Roster
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/system-admin/roster",
      roster
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postRosterEventHandler = async (
  rosterRequest: RosterHandlerRequest
): Promise<APIResponse<IIdentityPacket<RosterHandlerResponse>>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<RosterHandlerResponse>
    >(
      "/api/crms/internal/system-admin/roster/form-event-handler",
      rosterRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
