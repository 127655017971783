import { DTO_Levies } from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/model";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { colChargeLevies } from "./config";
const ChargeLevies = (props: GridDetailRowProps) => {
  const [dataChargeLevies, setDataChargeLevies] = useState<DTO_Levies[]>([]);
  const [columnsChargeLevies, setColumnsChargeLevies] =
    useState<IColumnFields[]>(colChargeLevies);

  useEffect(() => {
    const data = props.dataItem.ChargeLevies?.Levies;
    if (data) {
      setDataChargeLevies(data);
    }
    const chargeLeviesTotals: any = props.dataItem.ChargeLevies?.Totals;
    if (chargeLeviesTotals) {
      colChargeLevies.forEach((item) => {
        if (Object.keys(chargeLeviesTotals).includes(item.field)) {
          item.footerCell = (
            <CurrencyCell value={chargeLeviesTotals[item.field]} />
          );
        }
      });
      setColumnsChargeLevies(colChargeLevies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Charge levies</label>
            <CCGrid
              data={dataChargeLevies}
              columnFields={columnsChargeLevies}
              primaryField="Id"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChargeLevies;
