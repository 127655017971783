import { odataLookupNoticeTypesView } from "@app/core/components/common/utils";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Svc_NoticeType } from "@common/models/noticeTypes";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { LookupNoticeTypesTabDetails } from "@common/pages/settings/lookups/notice-types/_id/component/reference-sidebar/detail/_index";
import { LookupNoticeTypesHistoryTab } from "@common/pages/settings/lookups/notice-types/_id/component/reference-sidebar/history/_index";
import { NewNoticeTypesButton } from "@common/pages/settings/lookups/notice-types/components/action-bar/buttons/new-notice-type/_index";
import { colLookupsNoticeTypes } from "@common/pages/settings/lookups/notice-types/config";
import { SETTINGS_LOOKUPS_NOTICETYPES_ROUTE } from "@common/pages/settings/lookups/notice-types/constant";
import { LookupNoticeTypesBookmark } from "@common/pages/settings/lookups/notice-types/util";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_NoticeType>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [<NewNoticeTypesButton disabled={!hasPermission} />],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_LOOKUPS_NOTICETYPES_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_NoticeType}
        detail={LookupNoticeTypesBookmark.getBookmarkDetail}
        displayName={LookupNoticeTypesBookmark.getBookmarkDisplayName}
        listViewDetail={LookupNoticeTypesBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          LookupNoticeTypesBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <LookupNoticeTypesTabDetails /> },
      {
        title: "History",
        component: <LookupNoticeTypesHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colLookupsNoticeTypes}
      primaryField={nameOf("ID")}
      dataUrl={odataLookupNoticeTypesView(PRODUCT_TYPE_NUMBER.Core)}
      disabled={!hasPermission}
      state={{ sort: [{ field: nameOf("SortCode"), dir: "asc" }] }}
    />
  );
};
