import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class InfoNotReceivedButtonStore {
  private _isShowDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
    });
  };
}
export const infoNotReceivedStore = new InfoNotReceivedButtonStore();
const infoNotReceivedStoreContext = createContext(infoNotReceivedStore);
export const useInfoNotReceivedStore = () => {
  return useContext(infoNotReceivedStoreContext);
};
