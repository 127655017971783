import { eventEmitter } from "@/App";
import { deleteComment, saveComment } from "@app/core/comments/api";
import { AddCommentDialog } from "@app/core/comments/components/dialogs/add-comment/_index";
import { DeleteCommentDialog } from "@app/core/comments/components/dialogs/delete-comment/_index";
import { Comments } from "@app/core/comments/model";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";

import { odataGetComments } from "@app/core/components/common/utils";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colCommentWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/config";
import { COMMENT_DIALOG_MODE } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/model";
import { ViewConfiguration } from "@app/products/property/model";
import { BubbleUpType } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const CommentsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOfComments = nameOfFactory<Comments>();
const COMMENT_GRID_ID = getUUID();

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {},
  localNotificationRef,
  loadFailedStep,
  setIsLoadingStep = () => {},
  setLoadFailedStep = () => {},
}: IFormStepElement) => {
  const {
    isReadOnly = false,
    workflowDraftId = INVALID_WORKFLOW_DRAFT_ID,
    recordType = RECORDTYPE.CommunityProperty_Assessment,
  } = options;

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState<boolean>(false);
  const [isLoadingDeleteDialog, setIsLoadingDeleteDialog] =
    useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [commentCols, setCommentCols] = useState<IColumnFields[]>([]);
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const commentSelected: Comments = getFieldValue("CommentSelected");
  const [commentDialogMode, setCommentDialogMode] =
    useState<COMMENT_DIALOG_MODE>(COMMENT_DIALOG_MODE.Hide);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const handleOnSubmit = async (data: Comments) => {
    setIsLoadingSubmit(true);
    const requestBody: Comments = {
      ID:
        commentDialogMode === COMMENT_DIALOG_MODE.New ? 0 : commentSelected?.ID,
      Comment: data.Comment ?? "",
      Title: data.Title,
      BubbleUps: [
        {
          PKID: 0,
          SourceIdentifier: {
            _RecordSource_ID: 0,
            _RecordSourceType_ENUM: recordType.toString(),
            _RecordSource_ColumnName: "",
            _RecordSource_StringID: "",
          },
          BubbleUpType_ENUM: BubbleUpType.Ancestor,
          BubbleUpType_Name: BubbleUpType.Ancestor.toString(),
          Sys_CreatedDate: new Date(),
          Sys_DeactivationDate: null,
        },
        {
          PKID: 0,
          SourceIdentifier: {
            _RecordSource_ID: 0,
            _RecordSourceType_ENUM: recordType.toString(),
            _RecordSource_ColumnName: "",
            _RecordSource_StringID: "",
          },
          BubbleUpType_ENUM: BubbleUpType.Parent,
          BubbleUpType_Name: BubbleUpType.Parent.toString(),
          Sys_CreatedDate: new Date(),
          Sys_DeactivationDate: null,
        },
      ],
      workFlowDraftID: workflowDraftId,
    };
    const defaultSuccessMessage = "Comment saved successfully";
    const defaultErrorMessage = "Save comment failed";

    const response = await saveComment(requestBody as any, recordType);
    if (
      isSuccessResponse(response) &&
      response?.data?.ErrorStatus === IdentityPacketErrorStatus.Success
    ) {
      localNotificationRef?.current?.pushNotification({
        title: response?.data?.response?.Notifications ?? defaultSuccessMessage,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [COMMENT_GRID_ID],
      });
      setCommentDialogMode(COMMENT_DIALOG_MODE.Hide);
    } else {
      notificationRef?.current?.pushNotification({
        title: response?.data?.Errors ?? defaultErrorMessage,
        type: "error",
        autoClose: false,
      });
    }
    setIsLoadingSubmit(false);
  };

  const handleOnDelete = async () => {
    setIsLoadingDeleteDialog(true);
    const defaultSuccessMessage = "Comment deleted successfully";
    const defaultErrorMessage = "Delete comment failed";

    await deleteComment(
      commentSelected?.ID,
      RECORDTYPE.CommunityProperty_Workflow
    ).then((response) => {
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        localNotificationRef?.current?.pushNotification({
          title: response?.data?.SuccessMessage || defaultSuccessMessage,
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [COMMENT_GRID_ID],
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response?.data?.ErrorMessage || defaultErrorMessage,
          type: "error",
          autoClose: false,
        });
      }
    });
    setIsLoadingDeleteDialog(false);
    setIsShowDeleteDialog(false);
    onChange(nameOf("CommentSelected"), { value: undefined });
  };

  const newCommentColumns = useMemo(() => {
    if (options?.isReadOnly) return colCommentWorkflow;
    return commentCols.map((col: IColumnFields) => {
      if (col.field === nameOfComments("ID")) {
        return {
          ...col,
          handleOnClick: (dataRow: Comments) => {
            onChange(nameOf("CommentSelected"), { value: dataRow });
            setCommentDialogMode(COMMENT_DIALOG_MODE.Edit);
          },
        };
      }
      return col;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, commentCols]);

  const loadCommentConfig = async () => {
    setIsLoadingStep(true);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Comment,
      colCommentWorkflow
    );
    setIsLoadingStep(false);
    if (Array.isArray(response)) {
      setCommentCols(response);
    } else {
      setLoadFailedStep({
        onReload: () => {
          loadCommentConfig();
        },
        responseError: {
          status: response.status,
          error: response.error ?? "Load failed",
        },
      });
      return;
    }
  };

  useEffectOnce(() => {
    loadCommentConfig();
  });

  if (loadFailedStep) {
    return (
      <CCLoadFailed
        onReload={loadFailedStep?.onReload}
        responseError={loadFailedStep?.responseError}
      />
    );
  }

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field cc-comments-comment-dialog">
          <label className="cc-label">Comments</label>
          <CCGrid
            gridId={COMMENT_GRID_ID}
            dataUrl={odataGetComments(recordType, true, workflowDraftId)}
            columnFields={newCommentColumns}
            readOnly={isReadOnly}
            selectableMode="single"
            state={{
              sort: [{ field: nameOfComments("ID"), dir: "desc" }],
            }}
            selectedRows={commentSelected ? [{ ...commentSelected }] : []}
            primaryField={nameOfComments("ID")}
            onSelectionChange={(dataItem: Comments[]) => {
              //Click item in grid
              if (dataItem[0]?.ID) {
                onChange(nameOf("CommentSelected"), { value: dataItem[0] });
              }
              //Click group row
              else {
                onChange(nameOf("CommentSelected"), { value: undefined });
              }
            }}
            itemPerPage={10}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  className="cc-edit-field-button"
                  iconClass="fa fa-plus"
                  title="Add Comment"
                  disabled={
                    !workflowDraftId ||
                    workflowDraftId === INVALID_WORKFLOW_DRAFT_ID
                  }
                  onClick={() => {
                    setCommentDialogMode(COMMENT_DIALOG_MODE.New);
                  }}
                />
                <Button
                  iconClass="fas fa-minus"
                  title="Remove Comment(s)"
                  disabled={isNil(commentSelected)}
                  onClick={() => {
                    setIsShowDeleteDialog(true);
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
      {isShowDeleteDialog && (
        <DeleteCommentDialog
          onClose={() => {
            setIsShowDeleteDialog(false);
          }}
          onSubmit={() => {
            handleOnDelete();
          }}
          isLoading={isLoadingDeleteDialog}
          isHideReasonField
        />
      )}
      {(commentDialogMode === COMMENT_DIALOG_MODE.New ||
        commentDialogMode === COMMENT_DIALOG_MODE.Edit) && (
        <AddCommentDialog
          onClose={() => {
            setCommentDialogMode(COMMENT_DIALOG_MODE.Hide);
          }}
          onSubmit={handleOnSubmit}
          recordType={recordType}
          notificationRef={notificationRef}
          initialValue={commentSelected}
          isEdit={commentDialogMode === COMMENT_DIALOG_MODE.Edit}
          isLoadingSubmit={isLoadingSubmit}
          isUseDropDownForTitle
        />
      )}
    </section>
  );
};
