import { getFullAddressSingleLine } from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import MapView from "@components/map/MapView";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useMemo, useRef } from "react";
import "./_index.scss";

export const MAP_FORM_STEP = "Map";
export const MapStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  setStepsVisible,
}: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const gisLatitudeValue = valueGetter(
    "Address.PropertyAssessment.GIS_Latitude"
  );
  const gisLongitudeValue = valueGetter(
    "Address.PropertyAssessment.GIS_Longitude"
  );
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const gisLatitude = useMemo(() => {
    if (!isNil(gisLatitudeValue)) {
      return +gisLatitudeValue;
    }
    return 0;
  }, [gisLatitudeValue]);

  const gisLongitude = useMemo(() => {
    if (!isNil(gisLongitudeValue)) {
      return +gisLongitudeValue;
    }
    return 0;
  }, [gisLongitudeValue]);

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">GIS (latitude, longitude)</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.GIS_Lat_Lng"}
              placeholder={"GIS (latitude, longitude)"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map number</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.AddressMapNumber"}
              placeholder={"Map number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map reference</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.AddressMapReference"}
              placeholder={"Map reference"}
              onChange={(event: any) => {
                onChange("Address.PropertyAssessment.AddressMapReference", {
                  value: event.value,
                });
                onChange("Address.Map_Reference", { value: event.value });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">PFI</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.Address_PFI"}
              placeholder={"PFI"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postal delivery</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.Address_PostalDeliveryID"}
              placeholder={"Postal delivery"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">GIS (latitude)</label>
            <Field
              component={CCNumericTextBox}
              name={"Address.PropertyAssessment.GIS_Latitude"}
              placeholder={"GIS (latitude)"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">GIS (longitude)</label>
            <Field
              component={CCNumericTextBox}
              name={"Address.PropertyAssessment.GIS_Longitude"}
              placeholder={"GIS (longitude)"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street map reference</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.SL_MapReference"}
              placeholder={"Street map reference"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street map number</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.SL_MapNumber"}
              placeholder={"Street map number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street PFI</label>
            <Field
              component={CCInput}
              name={"Address.PropertyAssessment.Street_Road_PFI"}
              placeholder={"Street PFI"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street even start</label>
            <Field
              component={CCNumericTextBox}
              name={"Address.PropertyAssessment.SL_EvenStartRange"}
              placeholder={"Street even start"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street even end</label>
            <Field
              component={CCNumericTextBox}
              name={"Address.PropertyAssessment.SL_EvenEndRange"}
              placeholder={"Street even end"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street odd start</label>
            <Field
              component={CCNumericTextBox}
              name={"Address.PropertyAssessment.SL_OddStartRange"}
              placeholder={"Street odd start"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street odd end</label>
            <Field
              component={CCNumericTextBox}
              name={"Address.PropertyAssessment.SL_OddEndRange"}
              placeholder={"Street odd end"}
            />
          </div>
        </div>
      </section>
      <div className="cc-site-address-map">
        <MapView
          width="100%"
          height="300px"
          coordinate={{
            lat: gisLatitude,
            lng: gisLongitude,
          }}
          address={getFullAddressSingleLine(valueGetter("Address"))}
          onError={(errorMessage: string) =>
            notificationRef.current?.pushNotification({
              title: errorMessage,
              type: "warning",
            })
          }
        />
      </div>
    </>
  );
};
