import { DTO_Title_Names } from "@app/products/property/titles/[id]/components/child-screens/title-contacts/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getTitleContacts = async (
  titleId: string | number,
  isIncludePastNames: boolean = true,
  cancelToken: CancelToken
): Promise<APIResponse<DTO_Title_Names>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/title/${titleId}/names`,
      {
        params: {
          loadPastNames: isIncludePastNames,
        },
        cancelToken,
      }
    );
    return response;
  } catch (error) {
    return handleCatchResponse(error);
  }
};
