export enum ReverseTransactionKeysOfSteps {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  Transactions = "Transactions",
  Comments = "Comments",
  Documents = "Documents",
}

export const reverseTransactionKeysOfSendSteps = [
  ReverseTransactionKeysOfSteps.NewJournal,
  ReverseTransactionKeysOfSteps.Transactions,
];
