import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { deleteAppeals } from "@app/products/town-planning/ppr/permit-appeals/components/action-bar/buttons/delete/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

export const AppealDeleteButton = observer(() => {
  const { gridSelectedRows, setGridSelectedRows } = useCCProductListViewStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleOnSubmit = async () => {
    setIsDeleting(true);
    const ids: number[] = gridSelectedRows?.map((item) => item?.ID) ?? [];
    const response = await deleteAppeals(ids);
    setIsDeleting(false);
    setShowConfirmDeleteDialog(false);
    if (isSuccessResponse(response)) {
      setGridSelectedRows([]);
      clearNotifications();
      pushNotification({
        title: `${pluralize("Appeal", ids.length)} deleted successfully`,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
    } else {
      pushNotification({
        autoClose: false,
        title: `Delete ${pluralize("appeal", ids.length)} failed`,
        description: response.data.Errors ?? response.statusText,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Delete"
        disabled={!gridSelectedRows?.length}
        isLoading={isDeleting}
        onClick={() => {
          setShowConfirmDeleteDialog(true);
        }}
      />
      {showConfirmDeleteDialog && (
        <ConfirmDialog
          title={"Confirm Deletion"}
          subMessage={"Are you sure you want to delete this item?"}
          onClosePopup={() => {
            setShowConfirmDeleteDialog(false);
          }}
          onConfirmYes={handleOnSubmit}
        />
      )}
    </>
  );
});
