import { IAssessmentSpecialReadingList } from "@app/products/property/meters/special-reading-list/model";

export const mockAssessmentSpecialReadingList: IAssessmentSpecialReadingList[] =
  [
    {
      ScheduledReadDate: new Date("7-Oct-2021"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 11500000.0001,
      PropertyAddress: "Nr Recreat.Cnt Sh.Har Rd/abel,",
      Name: "I G Plessa & (Estate Of R Plessa)",
      Comments: "Moving out of house",
      AssignedReadingDevice: "PDA_ALL",
      ReadingOfficer: "1, Meter Reader",
      CreatedOn: new Date("27-Sep-2021 14:39"),
      CreatedBy: "rhosking",
      AssessmentId: 200,
      MeterSpecialReadId: 26,
    },
    {
      ScheduledReadDate: new Date("14-Mar-2015"),
      DateRead: new Date("14-Mar-2015 12:01"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("14-Mar-2015 12:04"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 1,
    },
    {
      ScheduledReadDate: new Date("12-May-2015"),
      DateRead: new Date("12-May-2015 14:53"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("12-May-2015 14:55"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 5,
    },
    {
      ScheduledReadDate: new Date("16-Jun-2015"),
      DateRead: new Date("16-Jun-2015 10:59"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("16-Jun-2015 11:00"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 6,
    },
    {
      ScheduledReadDate: new Date("20-Jun-2015"),
      DateRead: new Date("20-Jun-2015 0:00"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("21-Jun-2015 9:15"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 7,
    },
    {
      ScheduledReadDate: new Date("20-Jun-2015"),
      DateRead: new Date("20-Jun-2015 0:00"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("21-Jun-2015 9:15"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 8,
    },
    {
      ScheduledReadDate: new Date("22-Jun-2015"),
      DateRead: new Date("22-Jun-2015 9:49"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("23-Jun-2015 9:50"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 9,
    },
    {
      ScheduledReadDate: new Date("22-Jun-2015"),
      DateRead: new Date("22-Jun-2015 15:06"),
      SpecialReadType: "Request for meter read outside cycle",
      CreatedOn: new Date("23-Jun-2015 15:06"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 10,
    },
    {
      ScheduledReadDate: new Date("20-May-2015"),
      DateRead: new Date("30-Jun-2015 11:31"),
      SpecialReadType: "Request for meter read outside cycle",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      CreatedOn: new Date("9-May-2015 9:50"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 4,
    },
    {
      ScheduledReadDate: new Date("10-May-2015"),
      DateRead: new Date("1-Jul-2015 9:57"),
      SpecialReadType: "Request for meter read outside cycle",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      CreatedOn: new Date("9-May-2015 9:34"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 3,
    },
    {
      ScheduledReadDate: new Date("16-May-2015"),
      DateRead: new Date("1-Jul-2015 14:34"),
      SpecialReadType: "Request for meter read outside cycle",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      CreatedOn: new Date("9-May-2015 9:26"),
      CreatedBy: "KF302",
      MeterSpecialReadId: 2,
    },
    {
      ScheduledReadDate: new Date("17-Aug-2017"),
      DateRead: new Date("13-Nov-2017 11:33"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 23300000.0001,
      PropertyAddress: ",",
      Name: "S & VRigas",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 6080,
      CreatedOn: new Date("17-Aug-2017 15:33"),
      CreatedBy: "JP505",
      AssessmentId: 657,
      MeterSpecialReadId: 12,
    },
    {
      ScheduledReadDate: new Date("15-Aug-2017"),
      DateRead: new Date("14-Nov-2017 11:48"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 91700000.0016,
      PropertyAddress: ",",
      Name: "TADORAN",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 6080,
      CreatedOn: new Date("15-Aug-2017 14:35"),
      CreatedBy: "JP505",
      AssessmentId: 2690,
      MeterSpecialReadId: 11,
    },
    {
      ScheduledReadDate: new Date("24-Jan-2018"),
      DateRead: new Date("8-Mar-2018 8:09"),
      SpecialReadType: "New Meter",
      AssessmentNumber: 252697000,
      PropertyAddress: ",",
      Description: "L0/BUP71480:PAR TAWVALE - 'GARDENIA GARDENS BUP71480 -",
      Name: "M & M BOGOVIC",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 6736,
      CreatedOn: new Date("24-Jan-2018 14:11"),
      CreatedBy: "JP505",
      AssessmentId: 5561,
      MeterSpecialReadId: 13,
    },
    {
      ScheduledReadDate: new Date("24-Jan-2018"),
      DateRead: new Date("26-Jun-2018 8:57"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 252697000.0002,
      PropertyAddress: ",",
      Name: "CGA Investments (Vic) Pty Ltd ATF 3 Amigos Unit Trust",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7224,
      CreatedOn: new Date("24-Jan-2018 14:12"),
      CreatedBy: "JP505",
      AssessmentId: 5563,
      MeterSpecialReadId: 14,
    },
    {
      ScheduledReadDate: new Date("22-Feb-2018"),
      DateRead: new Date("27-Jun-2018 14:45"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 31600000.0007,
      PropertyAddress: "48 Coral Esplanade, Cannonvale",
      Name: "Common Property - Body Corporate",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7224,
      CreatedOn: new Date("22-Feb-2018 16:23"),
      CreatedBy: "JP505",
      AssessmentId: 825,
      MeterSpecialReadId: 16,
    },
    {
      ScheduledReadDate: new Date("25-Feb-2018"),
      DateRead: new Date("28-Jun-2018 9:22"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 33810000.0033,
      PropertyAddress: "28 Island Drive, Cannonvale",
      Name: "TTN NGUYEN",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7224,
      CreatedOn: new Date("25-Feb-2018 11:44"),
      CreatedBy: "JP505",
      AssessmentId: 996,
      MeterSpecialReadId: 17,
    },
    {
      ScheduledReadDate: new Date("10-Mar-2018"),
      DateRead: new Date("29-Jun-2018 8:52"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 5040007,
      PropertyAddress: ",",
      Name: "WDay",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7224,
      CreatedOn: new Date("10-Mar-2018 9:29"),
      CreatedBy: "JP505",
      AssessmentId: 9793,
      MeterSpecialReadId: 18,
    },
    {
      ScheduledReadDate: new Date("18-Apr-2018"),
      DateRead: new Date("29-Jun-2018 10:23"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 78200000.0001,
      PropertyAddress: ",",
      Name: "Christine & Stephen Collins & Cordner",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7224,
      CreatedOn: new Date("18-Apr-2018 11:49"),
      CreatedBy: "JP505",
      AssessmentId: 2199,
      MeterSpecialReadId: 19,
    },
    {
      ScheduledReadDate: new Date("8-Feb-2018"),
      DateRead: new Date("29-Jun-2018 12:03"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 99400000.0005,
      PropertyAddress: ",",
      Name: "Charmaine Pete. Rolls",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7224,
      CreatedOn: new Date("8-Feb-2018 9:53"),
      CreatedBy: "JP505",
      AssessmentId: 9623,
      MeterSpecialReadId: 15,
    },
    {
      ScheduledReadDate: new Date("18-Aug-2018"),
      DateRead: new Date("7-Nov-2018 8:49"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 12900000.0002,
      PropertyAddress: ",",
      Name: "A & E M DRAGOVIC & D MERCIECA & P C MORGAN",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7891,
      CreatedOn: new Date("18-Aug-2018 14:34"),
      CreatedBy: "JP505",
      AssessmentId: 290,
      MeterSpecialReadId: 20,
    },
    {
      ScheduledReadDate: new Date("28-Aug-2018"),
      DateRead: new Date("8-Nov-2018 9:14"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 23400000.0012,
      PropertyAddress: ",",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 7891,
      CreatedOn: new Date("28-Aug-2018 12:21"),
      CreatedBy: "JP505",
      AssessmentId: 697,
      MeterSpecialReadId: 21,
    },
    {
      ScheduledReadDate: new Date("28-Jan-2019"),
      DateRead: new Date("3-Mar-2019 11:06"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 89540000.0028,
      PropertyAddress: ",",
      Name: "CL BROWN",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 8416,
      CreatedOn: new Date("28-Jan-2019 12:34"),
      CreatedBy: "JP505",
      AssessmentId: 10080,
      MeterSpecialReadId: 23,
    },
    {
      ScheduledReadDate: new Date("28-Nov-2018"),
      DateRead: new Date("4-Mar-2019 8:49"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 119200000.0013,
      PropertyAddress: "9 Cedar Crescent Jubilee Pocket",
      Name: "Common Property - Body Corporate",
      AssignedReadingDevice: "PDA1",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 8416,
      CreatedOn: new Date("28-Nov-2018 10:12"),
      CreatedBy: "JP505",
      AssessmentId: 3662,
      MeterSpecialReadId: 22,
    },
    {
      ScheduledReadDate: new Date("8-May-2019"),
      DateRead: new Date("29-Jun-2019 15:03"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 88270000,
      PropertyAddress: "4 Golden Orchid Drive, Airlie Beach",
      Description: "L0/SP133882PAR CONWAY - 'WATER'S EDGE AIRLIE BEACH CTS",
      Name: "H M STONE",
      AssignedReadingDevice: "PDA_ALL",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 8900,
      CreatedOn: new Date("8-May-2019 9:21"),
      CreatedBy: "JP505",
      AssessmentId: 2509,
      MeterSpecialReadId: 24,
    },
    {
      ScheduledReadDate: new Date("5-May-2020"),
      DateRead: new Date("8-Jun-2020 9:55"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 118710300.0005,
      PropertyAddress: ",",
      AssignedReadingDevice: "PDA ALL",
      ReadingOfficer: "1, Meter Reader",
      JournalNumber: 10350,
      CreatedOn: new Date("5-May-2020 13:40"),
      CreatedBy: "JP505",
      AssessmentId: 3438,
      MeterSpecialReadId: 25,
    },
    {
      ScheduledReadDate: new Date("14-Jan-2022"),
      DateRead: new Date("14-Jan-2022 4:39"),
      SpecialReadType: "Change of Ownership",
      AssessmentNumber: 4700032,
      PropertyAddress: ",",
      Name: "Common Property - Body Corporate",
      AssignedReadingDevice: "PDA ALL",
      ReadingOfficer: "1, Meter Reader",
      FeeAmount: 100.0,
      CreatedOn: new Date("14-Jan-2022 4:38"),
      CreatedBy: "community central dev",
      AssessmentId: 100,
      MeterSpecialReadId: 27,
    },
  ];
