import { DTO_SubdivisionsProgeny_LOVs } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/model";
import { DTO_SubdivisionsConsolidations_LOVs } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class SubdivideConsolidateDialogStore {
  private _assessmentId?: number | undefined = undefined;
  private _subdivisionsConsolidationsLOVs?: DTO_SubdivisionsConsolidations_LOVs =
    undefined;
  private _progenyLOVs?: DTO_SubdivisionsProgeny_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get subdivisionsConsolidationsLOVs() {
    return this._subdivisionsConsolidationsLOVs;
  }
  setSubdivisionsConsolidationsLOVs = (
    subdivisionsConsolidationsLOVs?: DTO_SubdivisionsConsolidations_LOVs
  ) => {
    runInAction(() => {
      this._subdivisionsConsolidationsLOVs = subdivisionsConsolidationsLOVs;
    });
  };

  get progenyLOVs() {
    return this._progenyLOVs;
  }
  setProgenyLOVs = (progenyLOVs?: DTO_SubdivisionsProgeny_LOVs) => {
    runInAction(() => {
      this._progenyLOVs = progenyLOVs;
    });
  };

  get assessmentId() {
    return this._assessmentId;
  }
  setAssessmentId = (assessmentId?: number) => {
    runInAction(() => {
      this._assessmentId = assessmentId;
    });
  };
}

const subdivideConsolidateDialogStoreContext = createContext(
  new SubdivideConsolidateDialogStore()
);
export const useSubdivideConsolidateDialogStore = () => {
  return useContext(subdivideConsolidateDialogStoreContext);
};
