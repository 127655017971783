import { eventEmitter } from "@/App";
import { postJournalStatus } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/complete-journal/api";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IDeauthoriseJournalButtonProps {
  disabled?: boolean;
}

export const DeauthoriseJournalButton = observer(
  ({ disabled = false }: IDeauthoriseJournalButtonProps) => {
    const { reLoadJournal, journalId } = useJournalStore();
    const { lastSelectedId } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();

    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDeauthoriseJournal = async () => {
      setIsLoading(true);
      const deauthoriseId = journalId ?? lastSelectedId;
      const response = await postJournalStatus(1, deauthoriseId);

      if (isSuccessResponse(response) && response.data?.IsSuccess) {
        reLoadJournal().then(() => {
          pushNotification({
            type: "success",
            title:
              response?.data?.SuccessMessage ??
              "Journal deauthorised successfully",
          });
          eventEmitter.emit(CCGridEventType.RefreshOData);
        });
      } else {
        pushNotification({
          type: "error",
          title: response.data?.ErrorMessage ?? "Deauthorise journal failed",
          autoClose: false,
        });
      }
      setIsLoading(false);
      setIsShowConfirmDialog(false);
    };
    return (
      <>
        <CCNavButton
          title="Deauthorise"
          disabled={disabled}
          onClick={() => setIsShowConfirmDialog(true)}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              <span>
                Deauthorising a journal, changes the status of the journals
                transactions to proposed excluding them from assessment
                balances.
                <br />
                Do you wish to continue?
              </span>
            }
            isLoadingYes={isLoading}
            onClosePopup={() => setIsShowConfirmDialog(false)}
            onAsyncConfirm={handleDeauthoriseJournal}
          />
        )}
      </>
    );
  }
);
