import { getViewConfigurations } from "@app/products/property/api";
import { getSearchPicLookup } from "@app/products/property/components/dialogs/add-pic-lookup/api";
import {
  colPicLookup,
  picSearchBy,
} from "@app/products/property/components/dialogs/add-pic-lookup/config";
import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { isNumberModePicSearch } from "@app/products/property/components/dialogs/add-pic-lookup/util";
import { IFilterSearch } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { ComboboxSearchMessage } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";

import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import axios, { CancelTokenSource } from "axios";
import { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddPICLookupDialogProps {
  onClose: () => void;
  handleAddPIC: (data: DTO_PIC[]) => void;
  isLoadingFinish?: boolean;
  statusParamSearch?: number[];
}
const nameOf = nameOfFactory<DTO_PIC>();
export const AddPICLookupDialog = ({
  onClose,
  handleAddPIC,
  isLoadingFinish = false,
  statusParamSearch = [],
}: IAddPICLookupDialogProps) => {
  const cancelRequest = useRef<CancelTokenSource>();
  const [filter, setFilter] = useState<IFilterSearch>({
    searchValue: "",
    searchBy: picSearchBy?.[0],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] =
    useState<IColumnFields[]>(colPicLookup);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<DTO_PIC[]>([]);
  const [selectedPIC, setSelectedPIC] = useState<DTO_PIC[]>([]);

  const debouncedSearch = useDebounce(filter?.searchValue ?? "", 500);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  useEffect(() => {
    (async () => {
      if (
        !isNil(debouncedSearch) &&
        filter &&
        filter?.searchValue?.length >= 1 &&
        !isNil(filter?.searchBy?.Code)
      ) {
        notificationRef.current?.resetNotifications();
        setIsSearching(true);
        cancelRequest.current?.cancel(ComboboxSearchMessage);
        cancelRequest.current = axios.CancelToken.source();
        const response = await getSearchPicLookup(
          {
            LookupKey: filter?.searchBy.Code,
            LookupValue: debouncedSearch,
            Statuses: statusParamSearch,
          },
          cancelRequest.current
        );
        if (isSuccessResponse(response) && response.data) {
          setSearchResult(response.data.Pics ?? []);
          setIsSearching(false);
        } else if (response.error !== ComboboxSearchMessage) {
          setIsSearching(false);
          notificationRef.current?.setNotifications([
            {
              title: response.error ?? "PIC lookup search failed",
              type: "error",
              autoClose: false,
              id: "1",
            },
          ]);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, filter?.searchBy?.Code]);

  const loadConfigurationView = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.PIC_Lookup).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedColumns([]);
          setIsLoading(false);
          return;
        }
        setProcessedColumns(processDynamicColumns(colPicLookup, viewConfig));
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadConfigurationView();
  });

  return (
    <Form
      onSubmitClick={() => handleAddPIC(selectedPIC)}
      initialValues={{
        PICSearchBy: picSearchBy?.[0],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <CCDialog
          titleHeader="PIC Lookup"
          maxWidth="50%"
          height="auto"
          onClose={onClose}
          disabled={isLoading || isLoadingFinish}
          bodyElement={
            <FormElement className="cc-form">
              <section className="cc-field-group">
                <CCLocalNotification ref={notificationRef} />
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Search by" />
                    <Field
                      name={"PICSearchBy"}
                      placeholder={"Search by"}
                      component={CCDropDownList}
                      data={picSearchBy ?? []}
                      textField={"Name"}
                      dataItemKey={"Code"}
                      onChange={(event: DropDownListChangeEvent) => {
                        setFilter((pre: IFilterSearch) => {
                          return {
                            searchValue: "",
                            searchBy: event?.value,
                          };
                        });
                        formRenderProps.onChange("PICSearchBy", {
                          value: event.value,
                        });
                        formRenderProps.onChange("PICSearchValue", {
                          value: "",
                        });
                      }}
                    />
                  </div>
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">
                      Search value
                      <CCTooltip
                        type="info"
                        content="PIC ID is a
                        number"
                        position="auto"
                      />
                    </label>
                    <Field
                      name={"PICSearchValue"}
                      placeholder={"Search value"}
                      component={CCInput}
                      type={
                        isNumberModePicSearch(filter?.searchBy?.Code)
                          ? "number"
                          : "string"
                      }
                      onChange={(event: InputChangeEvent) => {
                        setFilter((pre: IFilterSearch) => {
                          return { ...pre, searchValue: event?.value };
                        });
                        formRenderProps.onChange("PICSearchValue", {
                          value: event.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  {isLoading ? (
                    <Loading isLoading />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        loadConfigurationView();
                      }}
                    />
                  ) : (
                    <div className="cc-field">
                      <CCGrid
                        columnFields={processedColumns ?? []}
                        primaryField={nameOf("PIC_Id")}
                        data={searchResult ?? []}
                        isLoading={isSearching}
                        selectableMode="multiple"
                        onSelectionChange={(dataItem: any[]) => {
                          setSelectedPIC(dataItem);
                        }}
                      />
                    </div>
                  )}
                </div>
              </section>
            </FormElement>
          }
          footerElement={
            <div className="cc-dialog-footer-actions-right">
              <Button
                className="cc-dialog-button"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="cc-dialog-button"
                iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                themeColor="primary"
                onClick={formRenderProps.onSubmit}
                type="submit"
                disabled={
                  !formRenderProps.valid ||
                  !formRenderProps.modified ||
                  selectedPIC?.length === 0
                }
              >
                OK
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
