import { ProduceClaimFileButton } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/action-bar/buttons/produce-claim-file/_index";
import { RemoveTransactionButton } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/action-bar/buttons/remove-transaction/_index";
import { RebateClaimForm } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/child-screens/general/_index";
import { RebateClaimDetailTab } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/reference-sidebar/detail/_index";
import { useRebateClaimStore } from "@app/products/property/assessments/rebates/rebate-claims/[id]/store";
import { eComponent } from "@app/products/property/components/associations/model";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { Label } from "@common/stores/products/config";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const ExistedRebateClaim = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { rebateClaim, isLoading, responseLoadError, loadRebateClaim } =
    useRebateClaimStore();

  const getBookmarkDetail = useMemo(() => {
    const dynamicDetail =
      (rebateClaim?.RebateClaim_Name
        ? `${rebateClaim?.RebateClaim_Name} - `
        : "") + id;
    return `${dynamicDetail}`;
  }, [id, rebateClaim]);
  const { currentUserInfo } = useGlobalStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getBookmarkDetail,
      LinkUrl: managePageUrl,
      LinkText: `Property - ${assessmentLabel} - ${id}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: +id,
    },
  ];

  const getFormTitle = () => {
    return `Name: ${rebateClaim?.RebateClaim_Name}, ID: ${rebateClaim?.Id}`;
  };

  const listPanelBar: IPropertyPanelBar[] = [
    //@TODO: Apply child screen
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="REBATE CLAIM" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadRebateClaim(+id)}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle()} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <ProduceClaimFileButton />
                <RemoveTransactionButton />
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Add comments"} />
                <CCNavButton title={"Add contacts"} />
                <CCNavButton title={"Add documents"} />
                <CCNavButton title={"Add fees"} />
                <CCNavButton title={"Add samples"} />
                <CCNavButton title={"Add inspection"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {rebateClaim && (
                  <CCGeneralPanel component={<RebateClaimForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Rebate_Claim}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <RebateClaimDetailTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Rebate_Claim}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedRebateClaim;
