import { UnitEntitlementInputCell } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/components/form-elements/associations/components/custom-cell/unit-entitlement-input-cell/_index";
import { UnitTypeCell } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/components/form-elements/associations/components/custom-cell/unit-type-cell/_index";
import { DTO_MP_Assessment } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/model";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_MP_Assessment>();
export const colAssessment: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  { field: nameOf("Ratepayer_Name"), title: "Ratepayer" },
  {
    field: nameOf("Unit_Entitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
    editable: true,
    editCell: UnitEntitlementInputCell,
  },
  {
    field: nameOf("Unit_Entitlement_Percentage"),
    title: "Unit Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Unit_Type"),
    title: "Unit Type",
    editable: true,
    editCell: UnitTypeCell,
  },
  {
    field: nameOf("Unit_Type_Percentage"),
    title: "Unit Type Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "Unimproved Value",
    title: "Unimproved Value",
  },
  {
    field: "Average Unimproved Value",
    title: "Average Unimproved Value",
  },
  {
    field: "Part Land Tax UV",
    title: "Part Land Tax UV",
  },
  {
    field: "Rateable UV",
    title: "Rateable UV",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
