import { HMPremiseRoute } from "@app/products/hm/model";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import React from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

interface IHMPremisesByTypeInsightsProps {
  premiseByType: any;
  queryDateTime: any;
}

const HMPremisesByTypensights = (
  premisesByTypensightsData: IHMPremisesByTypeInsightsProps
) => {
  const history = useHistory();

  const PremisesTypeChart = () => {
    return (
      <div id="cc-premises-type-chart">
        <div className="cc-chart-container">
          <Chart style={{ height: 250, overflow: "auto" }}>
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                overlay={{
                  gradient: "glass",
                }}
                tooltip={{ visible: true }}
                data={premisesByTypensightsData.premiseByType}
                categoryField="name"
                field="share"
                visible={true}
              />
            </ChartSeries>
            <ChartLegend
              position="right"
              orientation="horizontal"
              width={100}
              height={5000}
              visible={false}
            />
          </Chart>
        </div>
        <div
          className="cc-chart-description"
          style={
            premisesByTypensightsData.premiseByType.length > 13
              ? { overflow: "auto" }
              : {}
          }
        >
          <Chart
            style={
              premisesByTypensightsData.premiseByType.length > 13
                ? { height: 250, width: 2750 }
                : { height: 250 }
            }
          >
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                overlay={{
                  gradient: "glass",
                }}
                tooltip={{ visible: true }}
                data={premisesByTypensightsData.premiseByType}
                categoryField="name"
                field="share"
                visible={false}
              />
            </ChartSeries>
            <ChartLegend
              position="left"
              orientation="vertical"
              visible={true}
            />
          </Chart>
        </div>
      </div>
    );
  };

  return (
    <div
      className="cc-insight-group"
      onClick={(e) => {
        history.push(HMPremiseRoute.HM_PREMISES_REGISTER);
      }}
    >
      <div className="cc-group-name">
        {getProductTitle(PRODUCT_TYPE.HealthManager)}
      </div>
      <h4>Premises by Type</h4>
      <div className="cc-date-time-description">
        as of {premisesByTypensightsData.queryDateTime}
      </div>
      <div className="cc-insight-group-container">
        <PremisesTypeChart />
      </div>
    </div>
  );
};
export default HMPremisesByTypensights;
