import { PROPERTY_ALSR_IMPORT_FILES_LIST_VIEW_URL } from "@app/products/property/annual-land-stock-returns/constant";
import { AnnualLandStockReturnsListWorkflowButtons } from "@app/products/property/annual-land-stock-returns/import-files/components/action-bar/workflow/_index";
import { alsrColList } from "@app/products/property/annual-land-stock-returns/import-files/config";
import { Portal_ALS_Return_Staging_File } from "@app/products/property/annual-land-stock-returns/import-files/model";
import { annualLandStockReturnsRoute } from "@app/products/property/annual-land-stock-returns/route";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Portal_ALS_Return_Staging_File>();
export default observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColList, setProcessedColList] =
    useState<IColumnFields[]>(alsrColList);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown
        category={annualLandStockReturnsRoute.path}
      />,
    ],
    centerComponents: [<AnnualLandStockReturnsListWorkflowButtons />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_AlS_Return_Import_Files,
      alsrColList
    );
    if (Array.isArray(response)) {
      setProcessedColList(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={processedColList}
      dataUrl={PROPERTY_ALSR_IMPORT_FILES_LIST_VIEW_URL}
      primaryField={nameOf("PARSF_FileName")}
    />
  );
});
