import { DTO_Dashboard_Graph } from "@common/pages/home/components/property/model";
import { usePropertyDashboardStore } from "@common/pages/home/components/property/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyPieChart } from "./components/pie-chart/_index";

export const PropertyInsights = observer(() => {
  const { propertyDashboard, isLoadingDashboardProperty } =
    usePropertyDashboardStore();
  return (
    <>
      {isLoadingDashboardProperty ? (
        <Loading isLoading />
      ) : (
        <>
          {propertyDashboard &&
            propertyDashboard?.Graphs.map((item: DTO_Dashboard_Graph) => {
              return (
                <PropertyPieChart
                  key={item.Id}
                  type="column"
                  tooltip={{ visible: true }}
                  data={item.Values}
                  name={item.Name}
                />
              );
            })}
        </>
      )}
    </>
  );
});
