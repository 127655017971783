import { odataCoreGetPublicHolidays } from "@app/core/components/common/utils";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { PublicHolidayTabDetails } from "@common/pages/settings/lookups/public-holidays/_id/components/reference-sidebar/detail/_index";
import { YearDropDown } from "@common/pages/settings/lookups/public-holidays/_id/components/year-dropdown/_index";
import { PUBLIC_HOLIDAYS_ROUTE } from "@common/pages/settings/lookups/public-holidays/_id/constant";
import { generateColPublicHolidaysGrid } from "@common/pages/settings/lookups/public-holidays/config";
import {
  SettingState,
  Svc_View_LookUpDatesList,
} from "@common/pages/settings/lookups/public-holidays/model";
import {
  PublicHolidaysBookmark,
  PublicHolidaysDetailsBookmark,
} from "@common/pages/settings/lookups/public-holidays/util";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_View_LookUpDatesList>();

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const settingState: SettingState = useMemo(() => {
    return {
      isHaveACT: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_ACTHOLIDAYS]
      ),
      isHaveNSW: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_NSWHOLIDAYS]
      ),
      isHaveVIC: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_VICHOLIDAYS]
      ),
      isHaveSA: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_SAHOLIDAYS]
      ),
      isHaveTAS: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_TASHOLIDAYS]
      ),
      isHaveWA: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_WAHOLIDAYS]
      ),
      isHaveNT: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_NTHOLIDAYS]
      ),
      isHaveQLD: getBoolValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_QLDHOLIDAYS]
      ),
    };
  }, [settings]);

  const { gridSelectedRows } = useCCProductListViewStore();

  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [<YearDropDown />],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={PUBLIC_HOLIDAYS_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.Core_Bookmark}
        detail={PublicHolidaysDetailsBookmark.getBookmarkDetail}
        displayName={PublicHolidaysDetailsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          PublicHolidaysBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={PublicHolidaysBookmark.getBookmarkListViewDetail}
        gridSelectedIds={gridSelectedRows.map((item: any) => item.Hol_Year)}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PublicHolidayTabDetails /> },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={generateColPublicHolidaysGrid(false, settingState)}
      primaryField={nameOf("ID")}
      dataUrl={odataCoreGetPublicHolidays()}
      disabled={!hasPermission}
    />
  );
});
