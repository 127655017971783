import { CSLSystemSettingCategoriesForm } from "@app/products/csl/[id]/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Toast } from "@components/toast";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import React from "react";
export interface ICSLSystemAdminCategoriesFormElementProps {
  isNew: boolean;
  formRenderProps: FormRenderProps;
}
export const CSLSystemAdminCategoriesFormElement = ({
  formRenderProps,
}: ICSLSystemAdminCategoriesFormElementProps) => {
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    Toast.displayToast({
      content: "🚀 The form is not modified !",
      type: "warning",
    });
  };

  const { valueGetter, onChange } = formRenderProps;

  const cslFeeType = [
    { Key: 1, Value: "Planning Fee 1" },
    { Key: 2, Value: "Planning Fee 2" },
    { Key: 3, Value: "Planning Fee 3" },
    { Key: 4, Value: "Planning Fee 4" },
    { Key: 5, Value: "Planning Fee 5" },
  ];

  const cslClassification = [
    { Key: 1, Value: "Licence to Possess Regulation 25 Poisons" },
    { Key: 2, Value: "Standard ACT" },
  ];

  const nameOf = nameOfFactory<CSLSystemSettingCategoriesForm>();

  return (
    <FormElement onSubmit={formRenderProps.onSubmit}>
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      {/* SECTION 1 */}
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Name
              <CCTooltip type="validator" position="right" />
            </label>

            <Field name={nameOf("Name")} component={CCInput} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Classification
              <CCTooltip type="validator" position="right" />
            </label>

            <Field
              name={nameOf("Classification")}
              textField="Value"
              dataItemKey="Key"
              data={cslClassification}
              component={CCSearchComboBox}
              validator={requiredValidator}
              value={getDropdownValue(
                valueGetter(nameOf("Classification")),
                cslClassification,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Classification"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Licence code
              <CCTooltip type="validator" position="right" />
            </label>
            <Field name={nameOf("Code")} component={CCInput} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Fee type
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("FeeType")}
              textField="Value"
              dataItemKey="Key"
              data={cslFeeType}
              component={CCSearchComboBox}
              validator={requiredValidator}
              value={getDropdownValue(
                valueGetter(nameOf("FeeType")),
                cslFeeType,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("FeeType"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              Posions
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Poisons")}
              defaultChecked={valueGetter("Posions")}
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Pest control</label>
            <Field
              name={nameOf("PestControl")}
              defaultChecked={valueGetter("PestControl")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              Renewable <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Renewable")}
              defaultChecked={valueGetter("Renewable ")}
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Max renew</label>
            <Field name={nameOf("MaxRenews")} component={CCInput} />
          </div>
        </div>
      </div>
      <hr className="cc-divider" />
      {/* SECSION 2 */}
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Section of act</label>
            <Field
              name={nameOf("SectionOfAct")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Section of act 2</label>
            <Field
              name={nameOf("SectionOfAct2")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Section of act 3</label>
            <Field
              name={nameOf("SectionOfAct3")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Authorised activities</label>
            <Field
              name={nameOf("AuthorisedActivities")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Authorised activities 2</label>
            <Field
              name={nameOf("AuthorisedActivities2")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Authorised activities 3</label>
            <Field
              name={nameOf("AuthorisedActivities3")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Notes</label>
            <Field name={nameOf("Notes")} rows={6} component={CCTextArea} />
          </div>
        </div>
      </div>
      <hr className="cc-divider" />

      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Reg 25 poisons</label>
            <Field
              name={nameOf("TrainingSkills")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Purpose of</label>
            <Field name={nameOf("PurposeOf")} rows={6} component={CCTextArea} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Persons application to access</label>
            <Field
              name={nameOf("PersonsApprovedToAccess")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Max quantity</label>
            <Field
              name={nameOf("MaxQuantity")}
              rows={1}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Persons approved to access</label>
            <Field
              name={nameOf("PersonsApprovedToAccess")}
              rows={6}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Misc 1</label>
            <Field name={nameOf("Misc1")} rows={6} component={CCTextArea} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Misc 2</label>
            <Field name={nameOf("Misc2")} rows={6} component={CCTextArea} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Misc 3</label>
            <Field name={nameOf("Misc3")} rows={6} component={CCTextArea} />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Theft and loss policy required </label>
            <Field
              name={nameOf("TheftLoss")}
              defaultChecked={valueGetter("TheftLoss")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Code of practice for transport and storage of drugs required{" "}
            </label>
            <Field
              name={nameOf("Transport")}
              defaultChecked={valueGetter("Transport")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Report required </label>
            <Field
              name={nameOf("VetDrugReport")}
              defaultChecked={valueGetter("VetDrugReport")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Payment by journal required </label>
            <Field
              name={nameOf("PaymentJournal")}
              defaultChecked={valueGetter("PaymentJournal")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Licensee is part of country health network{" "}
            </label>
            <Field
              name={nameOf("CountryHealthNetwork")}
              defaultChecked={valueGetter("Renewable")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Wholesale report </label>
            <Field
              name={nameOf("WholeSaleReport")}
              defaultChecked={valueGetter("WholeSaleReport")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Ethics committee approval </label>
            <Field
              name={nameOf("EthicsCommitteeApproval")}
              defaultChecked={valueGetter("EthicsCommitteeApproval")}
              component={CCSwitch}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Grid
              className="grid_action"
              sortable={true}
              pageable={true}
              resizable
              selectedField="selected"
              filterOperators={{
                text: [
                  { text: "grid.filterContainsOperator", operator: "contains" },
                ],
                numeric: [{ text: "grid.filterEqOperator", operator: "eq" }],
                date: [
                  { text: "grid.filterAfterOperator", operator: "gt" },
                  { text: "grid.filterBeforeOperator", operator: "lt" },
                ],
              }}
            >
              <Column
                field="Sort"
                title="Sort"
                width="200px"
                filter="date"
                format="{0:D}"
                className="text-overflow-ellipsis"
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter {...props} expanded={true} />
                )}
              />
              <Column
                field="Title"
                title="Title"
                width="200px"
                className="text-overflow-ellipsis"
              />
              <Column
                className=" text-overflow-ellipsis"
                field="Condition"
                title="Condition"
                width="200px"
              />
            </Grid>
          </div>
        </div>
      </div>
    </FormElement>
  );
};
