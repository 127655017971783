import { appNoFields } from "@app/products/building/registers/easements/[id]/components/child-screens/general/components/form-element/config";
import {
  cslDecision,
  parentPage,
} from "@app/products/building/registers/easements/[id]/components/child-screens/general/components/form-element/mock";
import { IBuildingRegisterEasementFormData } from "@app/products/building/registers/easements/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface IBuildingRegisterEasementFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<IBuildingRegisterEasementFormData>();

export const BuildingRegisterEasementFormElement = observer(
  ({ formRenderProps }: IBuildingRegisterEasementFormElementProps) => {
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const getValue = formRenderProps.valueGetter;

    const [showAddressPopup, setShowAddressPopup] = useState(false);

    return (
      <FormElement>
        {showAddressPopup && (
          <RegisterAddressPopup
            onClosePopup={() => setShowAddressPopup(false)}
          />
        )}

        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        {/* SECTION 1 */}
        <div>
          {/* ROW 1 */}
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Contact
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  onClick={() => {
                    history.push(`/managecontactrelationship/${"123"}`, {
                      parentPage,
                      recordType: "11000",
                    });
                  }}
                  autoComplete="off"
                  placeholder="Contact"
                  name={nameOf("Contact")}
                  className="disabled"
                  component={InputPicker}
                  validator={validateRequired}
                />
              </div>
              <CCValueField
                label="Address"
                value={getValue("ContactAddress")}
              />
              <CCValueField
                label="Contact"
                value={getValue("ContactContact")}
              />
            </div>
          </div>
          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Site address <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  onClick={() => setShowAddressPopup(true)}
                  name={"Address"}
                  placeholder="Site address"
                  readOnly
                  validator={validateRequired}
                  component={InputPicker}
                />
              </div>
              <CCValueField label="Owner" value={getValue("Owners")} />
              <CCValueField label="Details" value={getValue("Details")} />
            </div>
            <div />
          </div>
          <hr className="cc-divider" />

          {/* SECTION 2*/}
          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Description</label>
                <Field
                  name={nameOf("Description")}
                  placeholder="Description"
                  component={CCTextArea}
                  rows={6}
                />
              </div>
            </div>
            {/* ROW 2 */}

            <div className="cc-form-cols-3">
              <CCValueField label="Officer" value={getValue("Officer")} />
              <div className="cc-field">
                <label className="cc-label">Application number</label>
                <Field
                  component={InputPicker}
                  {...appNoFields(formRenderProps)}
                  name={nameOf("AppNo")}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">File number</label>
                <Field
                  name={nameOf("RefNumber")}
                  placeholder="File number"
                  component={CCInput}
                />
              </div>
            </div>
            {/* ROW 3 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Lodged
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  validator={validateRequired}
                  name={nameOf("Lodged")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Decision</label>
                <Field
                  name={nameOf("Type")}
                  textField="Value"
                  dataItemKey="Key"
                  data={cslDecision}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Decision date</label>
                <Field
                  name={nameOf("Complete")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              </div>
            </div>
          </div>
          <hr className="cc-divider" />

          <div className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <CCValueField
                label="OS fees"
                value={getValue(nameOf("OutstandingFees"))}
                style={formatOSFees(getValue(nameOf("OutstandingFees")))}
                format={CURRENCY_FORMAT.CURRENCY1}
              />
              <div className="cc-field">
                <label className="cc-label">Debtor number</label>
                <Field
                  name={nameOf("DebtorNumber")}
                  component={CCInput}
                  placeholder="Debtor number"
                />
              </div>
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
