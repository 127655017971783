import { ICCRoute } from "@common/constants/ICCRoute";

export const allActionsRoute: ICCRoute = {
  path: "all-actions",
  name: "All Actions",
  children: [
    {
      path: "recorded-on",
      name: "Recorded On",
      component: require("./recorded-on/_index").default,
    },
    {
      path: "status",
      name: "Status",
      component: require("./status/_index").default,
    },
    {
      path: "type",
      name: "Type",
      component: require("./type/_index").default,
    },
    {
      path: "action-by",
      name: "Action By",
      component: require("./action-by/_index").default,
    },
  ],
};
