import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IDeleteCommentDialogProps {
  onClose: () => void;
  onSubmit: (data: IDeleteConfirmation) => void;
  isLoading?: boolean;
  isHideReasonField?: boolean;
}

export const DeleteCommentDialog = ({
  onClose,
  onSubmit,
  isLoading,
  isHideReasonField,
}: IDeleteCommentDialogProps) => {
  //Apply for ACTRO: Don't have reason field
  if (isHideReasonField) {
    return (
      <ConfirmDialog
        onClosePopup={onClose}
        title={"Remove Comment"}
        message={""}
        subMessage={"Are you sure you wish to remove the selected comment?"}
        onAsyncConfirm={() => {
          onSubmit({ Reason: "" });
        }}
        isLoadingYes={isLoading}
      />
    );
  }
  //Apply for PPR: Have reason field
  return (
    <ConfirmDelete
      onClose={onClose}
      handleSubmit={onSubmit}
      isDeleting={isLoading}
      contentDelete={"Comment"}
      header={"Confirm Deletion"}
    />
  );
};
