import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_ChangeOfOwnership_Documents } from "@app/products/property/changes-of-ownership/list/detail/model";

const nameOf = nameOfFactory<VO_ChangeOfOwnership_Documents>();
export const colChangeOfOwnershipListDetails: IColumnFields[] = [
  {
    field: nameOf("COO_Effective_Date"),
    title: "Effective Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("CODT_Name"),
    title: "Type",
  },
  {
    field: nameOf("Purchaser_Name"),
    title: "New Owners",
  },
  {
    field: nameOf("COD_Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("COD_Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("Change_of_Ownership_Id"),
    title: "Change Of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("COO_Document_Id"),
    title: "COO Document Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
