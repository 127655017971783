import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const PPREnquiryContactsTab = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { pprEnquiry } = usePPREnquiryStore();
  const { lastSelectedRow } = useCCProductListViewStore();
  const enquiryId = id ? parseInt(id) : lastSelectedRow?.ID;

  return (
    <ContactsTab
      id={enquiryId}
      recordType={RECORDTYPE.CORE_Register}
      triggerReload={pprEnquiry}
    />
  );
});
