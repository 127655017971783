import { DTO_Charge_Levy } from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_Charge_Levy>();
export const colChargeLevies: IColumnFields[] = [
  {
    field: nameOf("RatingPeriodName"),
    title: "Rating Period",
  },
  {
    field: nameOf("ChargeDate"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LevyName"),
    title: "Levy",
  },
  {
    field: nameOf("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NettAmount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Calculation"),
    title: "Calculation",
  },
  {
    field: nameOf("ChargeName"),
    title: "Name",
  },
  {
    field: nameOf("ChargeDescription"),
    title: "Description",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
