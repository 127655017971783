import { Region } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getRegionById = async (
  regionID: number
): Promise<APIResponse<Region | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Region>(
      `/api/core/internal/region/${regionID}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postRegion = async (
  requestBody: Region
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<Region>(
      `/api/core/internal/region`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
