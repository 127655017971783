import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const EditChangeOfOwnershipListButton = observer(() => {
  const { gridSelectedRows, lastSelectedId } = useCCProductListViewStore();
  const { setListDialog } = usePropertyWorkflowStore();
  const isDisableEditButton = useMemo(() => {
    return gridSelectedRows?.length !== 1;
  }, [gridSelectedRows]);

  return (
    <>
      <CCNavButton
        title="Edit change of ownership"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Edit_Change_Of_Ownership,
              data: {},
              props: {
                changeOfOwnershipId: lastSelectedId,
              },
            },
          ]);
        }}
        disabled={isDisableEditButton}
      />
    </>
  );
});
