import {
  DTO_LandTaxExemption_LOVs_Response,
  DTO_Workflow_CreateLandTaxExemption,
} from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/land-tax-exemption/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getLandTaxExemptionLOVs = async (): Promise<
  APIResponse<DTO_LandTaxExemption_LOVs_Response>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_LandTaxExemption_LOVs_Response>(
      `/api/property/internal/landtaxexempt-lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessCreateLandTaxExemption = async (
  workflowType: WorkflowProcessMode,
  data?: DTO_Workflow_CreateLandTaxExemption
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateLandTaxExemption>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/landtaxexemption/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
