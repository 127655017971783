import { CONDITION_NEW_ROUTE } from "@app/core/condition/[id]/constant";
import {
  Condition,
  IParentConditionSection,
} from "@app/core/condition/[id]/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddConditionButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  bubbleUps?: BubbleUpIdentifier[];
  data?: Condition;
  isCondition?: boolean;
  isDisabled?: boolean;
  title?: string;
  validateOnClick?: () => boolean;
}

export const AddConditionWithParentButton = ({
  id,
  recordType,
  productType,
  bubbleUps,
  isDisabled = false,
  isCondition = true,
  data,
  title = "Condition",
  validateOnClick,
}: IAddConditionButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title={title}
      onClick={() => {
        let checkValidateOnClick = true;
        if (validateOnClick) {
          checkValidateOnClick = validateOnClick();
        }
        if (checkValidateOnClick) {
          history.push(CONDITION_NEW_ROUTE, {
            parent: {
              id,
              recordType,
              productType,
              bubbleUps: cloneDeep(bubbleUps),
              isCondition,
              data,
            } as IParentConditionSection,
          });
        }
      }}
      disabled={isDisabled}
    />
  );
};
