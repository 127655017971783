import { eventEmitter } from "@/App";
import { eComponent } from "@app/products/property/components/associations/model";
import { ITEMS_ACCORDION_GRID_ID } from "@app/products/property/journals/[id]/components/child-screens/items/constant";
import { useJournalTransactionStore } from "@app/products/property/journals/[id]/components/child-screens/items/store";
import {
  postRemoveAssessmentTransaction,
  postRemovePICTransaction,
} from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/remove-transaction/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

interface IRemoveTransactionButtonProps {
  disabled?: boolean;
  componentNumber?: eComponent;
  nameForTransactionId: string;
}

export const RemoveTransactionButton = observer(
  ({
    disabled = false,
    componentNumber,
    nameForTransactionId,
  }: IRemoveTransactionButtonProps) => {
    const {
      selectedTransaction,
      setSelectedTransaction,
      loadJournalItemsData,
      loadItemsConfigurationColumns,
    } = useJournalTransactionStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { id } = useParams<{ id: string }>();
    const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
      useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
      if (
        !isNil(componentNumber) &&
        !isNil(id) &&
        selectedTransaction &&
        !isNil(selectedTransaction?.[`${nameForTransactionId}`])
      ) {
        setIsLoading(true);
        let response = null;
        const journalNumber = parseInt(id);
        const transactionID = selectedTransaction?.[`${nameForTransactionId}`];
        switch (componentNumber) {
          case eComponent.Assessment:
            response = await postRemoveAssessmentTransaction(
              journalNumber,
              transactionID
            );
            break;
          case eComponent.PIC:
            response = await postRemovePICTransaction(
              journalNumber,
              transactionID
            );
            break;
        }
        setIsLoading(false);
        if (response !== null) {
          if (isSuccessResponse(response) && response?.data?.IsSuccess) {
            setIsShowDeleteConfirm(false);
            pushNotification({
              type: "success",
              title:
                response?.data?.SuccessMessage ??
                "Transaction removed successfully",
            });
            switch (componentNumber) {
              case eComponent.PIC:
                await loadJournalItemsData(id);
                break;
              case eComponent.Assessment:
              default:
                loadItemsConfigurationColumns(componentNumber, id);
                eventEmitter.emit(CCGridEventType.RefreshOData, {
                  gridIds: [ITEMS_ACCORDION_GRID_ID],
                });
                break;
            }
            setSelectedTransaction(undefined);
          } else {
            pushNotification({
              type: "error",
              title:
                response?.data?.ErrorMessage ?? "Remove transaction failed",
              autoClose: false,
            });
            setIsShowDeleteConfirm(false);
          }
        }
      }
      setIsShowDeleteConfirm(false);
    };
    return (
      <>
        <CCNavButton
          title="Remove"
          disabled={disabled}
          onClick={() => setIsShowDeleteConfirm(true)}
        />
        {isShowDeleteConfirm && (
          <ConfirmDialog
            title="Confirm Delete"
            subMessage="Do you wish to delete the selected transaction from this journal?"
            isLoadingYes={isLoading}
            onClosePopup={() => setIsShowDeleteConfirm(false)}
            onAsyncConfirm={() => {
              return handleSubmit();
            }}
          />
        )}
      </>
    );
  }
);
