import { DTO_DeferredDuty_Account_Transaction } from "@app/products/property/deferred-duty/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Transaction>();
export const colJournalDeferredDutyAccountTransaction: IColumnFields[] = [
  {
    field: nameOf("AccountNumber"),
    title: "Account Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("TransactionDate"),
    title: "Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("TransactionType"),
    title: "Transaction Type",
  },
  {
    field: nameOf("JournalNumber"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("DeferredDutyAmount"),
    title: "Duty Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TransactionAmount"),
    title: "Transaction Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("UserId"),
    title: "UserId",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Create On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("IsProposed"),
    title: "Is Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsCancelled"),
    title: "Is Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AccountTransactionId"),
    title: "Account Transaction Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AccountId"),
    title: "DDS AccountId",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
