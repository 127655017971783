import { useCertificateComplianceStore } from "@app/products/property/certificates/[id]/components/child-screens/compliance/store";
import { EFieldAreaResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/config";
import { DTO_Certificate_AreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/model";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DTO_Certificate_AreaOfResponsibility>();
export const CertificateCompliance = observer(() => {
  const {
    data,
    lovsCertificate,
    loadCertificateCompliance,
    resetStore,
    isLoading,
    responseLoadError,
  } = useCertificateComplianceStore();
  const { certificateId } = useCertificateStore();
  const udalConfigField = data?.UDAL?.[0];

  useEffectOnce(() => {
    if (certificateId) {
      loadCertificateCompliance(certificateId);
    }
    return () => resetStore();
  });
  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (certificateId) {
            loadCertificateCompliance(certificateId);
          }
        }}
      />
    );

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              {udalConfigField?.UDA_Label ?? ""}
            </label>
            <CCSearchComboBox
              name={`CCSearchComboBox-${udalConfigField?.UDA_Attribute_Id}`}
              data={
                lovsCertificate?.[EFieldAreaResponsibility.Compliance] ?? []
              }
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                udalConfigField?.UDA_Code ?? "",
                lovsCertificate?.[EFieldAreaResponsibility.Compliance] ?? [],
                nameOfLov("Code")
              )}
              disabled
              className="cc-form-control"
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              {udalConfigField?.UDA_Label ?? ""} details
            </label>
            <CCTextArea
              className="cc-form-control"
              placeholder={"Legal action details"}
              name={`CCTextArea-${udalConfigField?.UDA_Attribute_Id}`}
              rows={3}
              readOnly
              value={udalConfigField?.UDA_Text ?? ""}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Completed</label>
            <CCSwitch
              name={nameOf("Completed")}
              checked={data?.Completed}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Completed on</label>
            <CCDatePicker
              className="cc-form-control"
              name={nameOf("Completion_DateTime")}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled
              value={data?.Completion_DateTime}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Completed by</label>
            <CCInput
              className="cc-form-control"
              name={nameOf("Completion_UserId")}
              value={data?.Completion_UserId}
              readOnly
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Comments</label>
            <CCTextArea
              className="cc-form-control"
              placeholder={"Comments"}
              name={nameOf("AOR_Comments")}
              rows={3}
              value={data?.AOR_Comments}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
});
