import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { DDAccountDetailsSection } from "@app/products/direct-debit/accounts/[id]/components/general/form-element/account-details-session/_index";
import { DDAccountGeneralSection } from "@app/products/direct-debit/accounts/[id]/components/general/form-element/general-session/_index";
import { DDAccountPaymentDetailsSection } from "@app/products/direct-debit/accounts/[id]/components/general/form-element/payment-details-session/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export interface IDirectDebitAccountFormElementProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

export const DirectDebitAccountFormElement = ({
  formRenderProps,
  isDisabled = false,
}: IDirectDebitAccountFormElementProps) => {
  return (
    <FormElement>
      <DDAccountGeneralSection
        formRenderProps={formRenderProps}
        isDisabled={isDisabled}
      />
      <CCGeneralPanel
        title="Account details"
        isSubPanel
        component={
          <DDAccountDetailsSection
            formRenderProps={formRenderProps}
            isDisabled={isDisabled}
          />
        }
      />
      <CCGeneralPanel
        title="Payment details"
        isSubPanel
        component={
          <DDAccountPaymentDetailsSection
            formRenderProps={formRenderProps}
            isDisabled={isDisabled}
          />
        }
      />
    </FormElement>
  );
};
