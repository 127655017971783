import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { DTO_Journal } from "@app/products/property/journals/model";
import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";

export enum EKeysOfStepsPICNewTransaction {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  PIC = "PIC",
  Comments = "Comments",
  Documents = "Documents",
  Workflow = "Workflow",
}

export enum ELabelPICNewTransaction {
  WhichJournal = "Journal",
  NewJournal = "New journal",
  PIC = "PIC",
  Comments = "Comments",
  Documents = "Documents",
  Workflow = "Workflow",
}

export const keysOfStepsPICNewTransaction = [
  EKeysOfStepsPICNewTransaction.NewJournal,
  EKeysOfStepsPICNewTransaction.PIC,
];

export interface DTO_Workflow_JournalPICAddTransaction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalPICAddTransaction;
}

export interface DTO_WorkflowDetail_JournalPICAddTransaction
  extends DTO_WorkflowDetail_Base {
  JournalMode: number;
  JournalNumber?: number;
  CreateJournal: DTO_Journal;
  PIC: DTO_Process_JournalPIC;
  NavigatedFrom: TransactionAddFrom;
}

export interface DTO_Process_JournalPIC {
  Transaction: DTO_PICTransaction;
  PIC_Ids: number[];
  PICs: DTO_PIC[];
}

export interface DTO_PICTransaction {
  PICId?: number;
  TransactionTypeId?: number;
  TransactionDate?: Date;
  Reference: string;
  Description: string;
  Notes: string;
  ReceiptNo: string;
  TransactionAmount?: number;
  TransactionSourceId?: number;
  IsProposed?: boolean;
}

export interface RequestJournalPICAddTransactionObj {
  JournalNumber?: number;
  PIC_Ids?: number[];
}

export enum TransactionAddFrom {
  JournalManage = "JournalManage",
  PICManage = "PICManage",
}
