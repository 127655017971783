import { useAdditionalInfoRequiredButtonStore } from "@app/core/further-info/components/buttons/work-flow/additional-info-required/store";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AdditionalInfoRequiredButton = observer(() => {
  const { onSubmit, furtherInfo } = useFurtherInfoStore();
  const { isLoading } = useAdditionalInfoRequiredButtonStore();
  const isVisible = useMemo(() => {
    if (!furtherInfo) return false;
    return (
      furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoReceived &&
      !furtherInfo?.InformationAdequate
    );
  }, [furtherInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Additional information required"}
      isLoading={isLoading}
      onClick={onSubmit}
      name={FurtherInfoSubmitActions.AdditionalInfoRequired}
    />
  ) : null;
});
