import { getSharePointDocumentLibraries } from "@app/products/town-planning/ppr/[id]/components/input-picker/share-point-document/api";
import { colSharePointDocumentPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/share-point-document/config";
import { isSuccessResponse } from "@common/apis/util";
import { Svc_SharepointDocumentLibrary } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";

const nameOf = nameOfFactory<Svc_SharepointDocumentLibrary>();

export interface ISharePointDocumentProps extends ICCInputPickerProps {
  onPickTypes: (value: any) => void;
  onInputChange: (value: any) => void;
  recordsTypeListData: any[];
  isSharePointDocumentLibraries?: boolean;
  selectableMode?: "single" | "multiple" | "none";
}
export const SharePointDocumentPicker = (props: ISharePointDocumentProps) => {
  const {
    onPickTypes,
    onInputChange,
    recordsTypeListData,
    onChange,
    onError,
    selectableMode,
    textField,
    textProduce,
    valueField,
    valueProduce,
    isSharePointDocumentLibraries = false,
    ...other
  } = props;
  const [data, setData] = useState<Svc_SharepointDocumentLibrary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: Svc_SharepointDocumentLibrary = event.value;
    if (onPickTypes) onPickTypes(value);
  };

  const handleOnLoadData = () => {
    setIsLoading(true);
    getSharePointDocumentLibraries(isSharePointDocumentLibraries).then(
      (response) => {
        setIsLoading(false);
        if (isSuccessResponse(response)) {
          if (response?.data) {
            setData(response.data ?? []);
          } else {
            setData([]);
          }
        }
      }
    );
  };

  return (
    <CCInputPicker
      {...other}
      dialog={{
        titleHeader: isSharePointDocumentLibraries
          ? "SharePoint Document Libraries"
          : "Document Set - Content Type",
        maxWidth: "50%",
      }}
      grid={{
        columnFields: colSharePointDocumentPicker,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode,
        data: data,
        isAutoHiddenPager: false,
      }}
      onLoadData={handleOnLoadData}
      onChange={handleOnChange}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
      readOnly={false}
      onInputChange={onInputChange}
      isLoading={isLoading}
    />
  );
};
