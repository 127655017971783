import {
  addItemToGridStoreURLStored,
  generateKeyStored,
  isKeepStore,
  removeItemFromGridStoreURLStored,
} from "@components/cc-product-list-view/components/hooks/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { useEffect, useMemo } from "react";

export enum EListType {
  Normal = "Normal",
  Setting = "Setting",
}

export const useStoreStateListView = (
  listType: EListType,
  resetStore: () => void,
  isClearStoreState: boolean
) => {
  const isNormalList: boolean = useMemo(() => {
    return listType === EListType.Normal;
  }, [listType]);

  //Key check for Setting ListView => Request URL
  const { requestUrl, productListViewId } = useCCProductListViewStore();
  const newRequestUrl: string = useMemo(() => {
    return generateKeyStored(productListViewId, requestUrl);
  }, [requestUrl, productListViewId]);

  //Key check for Normal ListView => PathName
  const pathName: string = useMemo(() => {
    return generateKeyStored(productListViewId, window.location.pathname);
  }, [productListViewId]);

  const storeUrl: string | undefined = useMemo(() => {
    return isNormalList ? pathName : newRequestUrl;
  }, [isNormalList, pathName, newRequestUrl]);

  const isKeepStoreState: boolean = useMemo(() => {
    return isKeepStore(storeUrl);
  }, [storeUrl]);

  //Delete gridStoreURL in localStorage when reload page
  const handleBeforeUnload = () => {
    removeItemFromGridStoreURLStored();
  };
  window.addEventListener("beforeunload", handleBeforeUnload);

  useEffect(() => {
    if (!isKeepStoreState || isClearStoreState) {
      resetStore();
      addItemToGridStoreURLStored(storeUrl);
      return () => {
        if (isClearStoreState) {
          removeItemFromGridStoreURLStored();
          resetStore();
        }
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [resetStore, isClearStoreState, isKeepStoreState, storeUrl]);

  return { isKeepStoreState };
};
