import { RegionForm } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/child-screens/general/_index";
import { RegionDetailTab } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/reference-sidebar/detail/_index";
import { RegionHistoryTab } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/reference-sidebar/history/_index";
import { useRegionStore } from "@app/products/town-planning/ppr/system-admin/regions/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedRegion = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const { region, loadRegion, isLoading, responseLoadError, isInactive } =
    useRegionStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        region?.Region_ID,
        region?.Region_Name,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Region - ${region?.Region_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: region?.Region_ID ?? 0,
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <RegionDetailTab /> },
      {
        title: "History",
        component: <RegionHistoryTab />,
      },
    ],
  });

  const RegionNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Region"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadRegion(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={"Region"} />
          <CCManagePageActionBar
            leftActions={[
              <RegionNavButton title={"Save"} htmlFor={"btn-submit"} />,
            ]}
            rightActions={[
              <RegionNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {region && <RegionForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
