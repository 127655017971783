import { DIRECT_DEBIT_STATUS_CODES } from "@app/products/direct-debit/constant";
import { DBRowStateWithoutArchive } from "@app/products/direct-debit/model";
import { DBRowStateCustomCell } from "@app/products/direct-debit/system-admin/components/db-row-state-custom-cell/_index";
import {
  DirectDebitStatusCode,
  DirectDebitStatusCodeView,
} from "@app/products/direct-debit/system-admin/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitStatusCodeView>();

export const colStatusCodes: IColumnFields[] = [
  {
    field: nameOf("Direct_Debit_Bank_Acc_Name"),
    title: "Bank Account",
    locked: true,
    linkTo: (dataItem: DirectDebitStatusCode) => {
      return (
        `${DIRECT_DEBIT_STATUS_CODES}/` + dataItem.Direct_Debit_Status_Code_ID
      );
    },
  },
  {
    field: nameOf("Status_Code"),
    title: "Status Code",
  },
  {
    field: nameOf("Status_Description"),
    title: "Description",
  },
  {
    field: nameOf("Is_Rejected"),
    title: "Is Rejected",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Sys_DBRowState"),
    title: "Status",
    filterByEnum: DBRowStateWithoutArchive,
    cell: DBRowStateCustomCell,
  },
  {
    field: nameOf("Direct_Debit_Status_Code_ID"),
    title: "Status Code ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
