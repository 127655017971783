import { colPPRActionsWithTagCol } from "@app/products/town-planning/ppr/actions/config";
import { TOWN_PLANNING_PPR_ALL_ACTION_VIEW_URL } from "@app/products/town-planning/ppr/actions/contants";
import { IPPRActions } from "@app/products/town-planning/ppr/actions/model";
import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { NewActionButton } from "@common/pages/actions/[id]/components/button/new-action/_index";
import {
  ActionsContactsTabSwitch,
  ActionsDetailTabSwitch,
  ActionsHistoryTabSwitch,
} from "@common/pages/actions/[id]/components/reference-sidebar/details-switch/_index";
import { ActionListViewBookmarkIcon } from "@common/pages/actions/[id]/components/reference-sidebar/lisview-bookmark/_index";
import { CheckDueActionsButton } from "@common/pages/actions/components/action-bar/buttons/check-due-actions/_index";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkDetail, getBookmarkDisplayName } from "../util";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

const nameOf = nameOfFactory<IPPRActions>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [<NewActionButton />, <CheckDueActionsButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ActionListViewBookmarkIcon
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ActionsDetailTabSwitch /> },
      { title: "Contacts", component: <ActionsContactsTabSwitch /> },
      {
        title: "History",
        component: <ActionsHistoryTabSwitch />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPPRActionsWithTagCol}
      primaryField={nameOf("ID")}
      state={{
        sort: [{ field: nameOf("RecordedDate"), dir: "desc" }],
      }}
      dataUrl={TOWN_PLANNING_PPR_ALL_ACTION_VIEW_URL}
    />
  );
};
