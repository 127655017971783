import { ICCRoute } from "@common/constants/ICCRoute";
export const fiRequestsRoute: ICCRoute = {
  path: "fi-requests",
  name: "FI Requests",
  children: [
    {
      path: "all",
      name: "All",
      component: require("./all/_index").default,
    },
  ],
};
