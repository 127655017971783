import { CRSForm } from "@app/products/crs/[id]/components/child-screens/general/_index";
import { useCRSStore } from "@app/products/crs/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewCRS = observer(() => {
  const { isLoading } = useCRSStore();
  return (
    <div className="cc-new-crs">
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Application"} />
      <FormTitle title={"New Main Application"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Save & exit"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Attachment"} />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Communication"} />
            <CCNavButton title={"Document"} />
          </CCNavButton>,

          <CCNavButton title={"View"} type="sub">
            <CCNavButton title={"Documents"} />
            <CCNavButton title={"Journal"} />
            <CCNavButton title={"Project"} />
          </CCNavButton>,
          <CCNavButton title={"Remove duplicate application"} />,
          <CCNavButton title={"Siblings"} type="sub">
            <CCNavButton title={"Create new siblings"} />
            <CCNavButton title={"Link additional siblings"} />
            <CCNavButton title={"Unlink siblings"} />
          </CCNavButton>,
          <CCNavButton title={"Publish"} type="sub">
            <CCNavButton title={"Documents"} />
          </CCNavButton>,
          <CCNavButton title={"Home"} />,
          <CCNavButton title={"More Options"} type="more">
            <CCNavButton title={"Centre based care"} />
            <CCNavButton title={"3yo kindergarten"} />
            <CCNavButton title={"4yo kindergarten"} />
            <CCNavButton title={"Information updated"} />
            <CCNavButton title={"Archive"} />
          </CCNavButton>,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<CRSForm />} />
          </div>
        </div>
      </div>
    </div>
  );
});
