import { DTO_AssessmentRebateEntitlement } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class EntitledToRebateStore {
  private _selectedEntitledToRebate?: DTO_AssessmentRebateEntitlement[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get selectedEntitledToRebate() {
    return this._selectedEntitledToRebate;
  }

  get selectedEntitledToRebateIDs() {
    return (
      this._selectedEntitledToRebate?.map(
        (entitledToRebateItem: DTO_AssessmentRebateEntitlement) =>
          entitledToRebateItem.Id
      ) ?? []
    );
  }

  setSelectedEntitledToRebate = (
    selectedEntitledToRebate: DTO_AssessmentRebateEntitlement[] | undefined
  ) => {
    runInAction(() => {
      this._selectedEntitledToRebate = selectedEntitledToRebate;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._selectedEntitledToRebate = [];
    });
  };
}

const entitledToRebateStoreContext = createContext(new EntitledToRebateStore());
export const useEntitledToRebateStore = () => {
  return useContext(entitledToRebateStoreContext);
};
