import {
  apiCoreCreateScheduledJob,
  apiCoreDeleteScheduledJobs,
  apiCoreGetScheduleJob,
  apiCoreReinstateScheduledJob,
  apiCoreRunScheduledJobs,
  apiCoreScheduleJobLovs,
  apiCoreUpdateScheduledJob,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  IQuartzJobKey,
  ScheduleJob_New,
  ScheduleJob_PrimaryKey,
  SchedulingJob_LOVs,
  Svc_ScheduleJobUpdate,
} from "@common/pages/settings/system-admin/task-scheduling/model";

export const getScheduledJobData = async (
  jobkey: string,
  group: string
): Promise<APIResponse<ScheduleJob_New | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ScheduleJob_New>(
      apiCoreGetScheduleJob(),
      {
        params: {
          jobkey,
          group,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getJobSchedulingLovs = async (): Promise<
  APIResponse<SchedulingJob_LOVs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<SchedulingJob_LOVs>(
      apiCoreScheduleJobLovs()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postJobsScheduling = async (
  data: ScheduleJob_New
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreCreateScheduledJob(),
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const removeJobsScheduling = async (
  data: ScheduleJob_PrimaryKey[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreDeleteScheduledJobs(),
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postUpdateJobsScheduling = async (
  data: Svc_ScheduleJobUpdate
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreUpdateScheduledJob(),
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postReinstateScheduling = async (
  data: IQuartzJobKey
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreReinstateScheduledJob(),
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const runJobsScheduling = async (
  data: ScheduleJob_PrimaryKey
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreRunScheduledJobs(),
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
