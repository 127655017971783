import { DTO_Assessment } from "@app/products/property/assessments/components/form-steps/associate-assessment/model";
import { nameOfFactory } from "@common/utils/common";

const nameOfAssociate = nameOfFactory<DTO_Assessment>();

const unAffectedAssociateExcludeField: string[] = [
  nameOfAssociate("IsPrimaryAssessment"),
  nameOfAssociate("ActiveFrom"),
  nameOfAssociate("ActiveTo"),
];
export const isUnAffectedAssociateColField = (fieldName: string) => {
  return !unAffectedAssociateExcludeField.includes(fieldName);
};
