import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_ContactDetails>();

export const colContactPicker: IColumnFields[] = [
  { title: "Name", field: nameOf("DisplayName") },
  { title: "Position", field: nameOf("Position") },
  { title: "Org Structure", field: nameOf("OrgStructure") },
  { title: "Email", field: nameOf("Email") },
  { title: "Work Phone", field: nameOf("WorkPhone") },
  { title: "Home Phone", field: nameOf("HomePhone") },
  { title: "Mobile", field: nameOf("Mobile") },
];
