import { feesRoute } from "@app/products/csl/fees/route";
import { inspectionsRoute } from "@app/products/csl/inspections/route";
import { licencesRoute } from "@app/products/csl/licences/route";
import { renewalsRoute } from "@app/products/csl/renewals/route";
import { reviewRoute } from "@app/products/csl/review/route";
import { systemAdminRoute } from "@app/products/csl/system-admin/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const cslRoute: ICCRoute = {
  path: "csl",
  name: "CSL",
  enum: eProductType.CSM,
  children: [
    licencesRoute,
    renewalsRoute,
    reviewRoute,
    feesRoute,
    inspectionsRoute,
    systemAdminRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
