import { CCSwitch } from "@components/cc-switch/_index";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";

interface IProps {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (arg0: SwitchChangeEvent) => void;
}

export const AmsSwitchInput: React.FC<IProps> = ({
  name,
  label,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <div className={`cc-ams-switch-input`}>
      <label className="cc-label">{label}</label>
      <div className="cc-switch">
        <CCSwitch
          name={name}
          checked={checked}
          disabled={disabled ?? false}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
