import { getCreateAssessmentLOVs } from "@app/products/property/assessments/components/form-steps/modify-assessment/api";
import { DTO_Collection } from "@app/products/property/collections/components/action-bar/dialogs/collection/model";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { nameOfLov } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  requiredValidatorAndMaxLength,
} from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface INewCollectionDialogProps {
  onClose: () => void;
  handleSubmit: (data: DTO_Collection) => void;
  isLoading: boolean;
  isModify?: boolean;
  notification?: React.MutableRefObject<ICCLocalNotificationHandle | null>;
  initFormValue?: DTO_Collection;
}

const nameOf = nameOfFactory<DTO_Collection>();

export const CollectionDialog = observer(
  ({
    onClose,
    handleSubmit,
    isLoading,
    notification,
    initFormValue,
    isModify = false,
  }: INewCollectionDialogProps) => {
    const [isLoadingLoadLOV, setIsLoadingLoadLOV] = useState<boolean>(false);
    const { assessmentCollectionStepLOVs, setAssessmentCollectionStepLOVs } =
      useAssessmentCollectionStepStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const loadCollectionLOVs = async () => {
      setIsLoadingLoadLOV(true);
      const response = await getCreateAssessmentLOVs();
      if (isSuccessResponse(response) && response?.data) {
        setAssessmentCollectionStepLOVs(
          response.data.dtoCreate_Assessment_LOVs
        );
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: response.error ?? "Load collections LOV failed",
          type: "error",
        });
      }
      setIsLoadingLoadLOV(false);
    };

    useEffectOnce(() => {
      (async () => await loadCollectionLOVs())();
    });

    const LOVofCollectionType = useMemo(() => {
      if (
        assessmentCollectionStepLOVs?.Collection_Type &&
        assessmentCollectionStepLOVs?.Collection_Type.length > 0
      ) {
        return assessmentCollectionStepLOVs?.Collection_Type;
      }
      return [];
    }, [assessmentCollectionStepLOVs?.Collection_Type]);

    return (
      <Form
        initialValues={initFormValue}
        onSubmitClick={(event: FormSubmitClickEvent) => {
          const data: DTO_Collection = event.values;
          handleSubmit(data);
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit, valid, modified } = formRenderProps;
          const getFieldValue = (name: keyof DTO_Collection) =>
            valueGetter(nameOf(name));
          return (
            <CCDialog
              maxWidth="50%"
              height="auto"
              disabled={isLoading}
              titleHeader={isModify ? "Modify Collection" : "New Collection"}
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form cc-new-collection-dialog">
                  <section className="cc-field-group">
                    <CCLocalNotification ref={notification} />
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Collection type
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("Collection_Type_Id")}
                          component={CCSearchComboBox}
                          data={LOVofCollectionType}
                          textField={nameOfLov("Name")}
                          dataItemKey={nameOfLov("Code")}
                          validator={requiredValidator}
                          isUseDefaultOnchange
                          isLoading={isLoadingLoadLOV}
                          disabled={isLoadingLoadLOV}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Name
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("Collection_Name")}
                          placeholder={"Name"}
                          validator={(value) =>
                            requiredValidatorAndMaxLength(value, 100)
                          }
                          component={CCInput}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Notes</label>
                        <Field
                          name={nameOf("Collection_Notes")}
                          component={CCTextArea}
                          rows={3}
                        />
                      </div>
                    </div>
                    {isModify && (
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">
                            Collection is active
                          </label>
                          <Field
                            name={nameOf("Collection_Is_Active")}
                            checked={getFieldValue("Collection_Is_Active")}
                            component={CCSwitch}
                          />
                        </div>
                      </div>
                    )}
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Show collection in lists
                        </label>
                        <Field
                          name={nameOf("Collection_Show_in_List")}
                          checked={getFieldValue("Collection_Show_in_List")}
                          component={CCSwitch}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    disabled={
                      isLoading || !valid || isLoadingLoadLOV || !modified
                    }
                    className={"cc-dialog-button"}
                    onClick={onSubmit}
                  >
                    Done
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
