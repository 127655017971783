import {
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colBillingDetails: IColumnFields[] = [
  {
    field: "Type",
    title: "Type",
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  { field: "BillingGroup", title: "Billing Group" },
  {
    field: "UsageEntitlement",
    title: "Usage Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "UsagePercentage",
    title: "Usage Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "DateAttached",
    title: "Date Attached",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DateRemoved",
    title: "Date Removed",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "ReadingOnRemoval",
    title: "Reading on Removal",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "Improvements",
    title: "Improvements",
  },
  {
    field: "PrimaryLandUse",
    title: "Primary Land Use",
  },
  {
    field: "AssessmentType",
    title: "Assessment Type",
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
