import { DATE_FORMAT } from "@common/constants/common-format";
import {
  AccessControl,
  IReportInfo,
} from "@common/pages/report/integrated-reports/component/dialogs/share-report/model";
import { NameCell } from "@common/pages/report/integrated-reports/component/dialogs/share-report/until";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IReportInfo>();
const nameOfReporters = nameOfFactory<AccessControl>();

export const colShareReport: IColumnFields[] = [
  {
    field: nameOfReporters("Contact_DisplayName"),
    title: "Name",
    width: 550,
    cell: NameCell,
  },
];

export const colReportInfo: IColumnFields[] = [
  {
    field: nameOf("ReportName"),
    title: "Report Name",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("Owner"),
    title: "Owner",
  },

  {
    field: nameOf("LastModifiedBy"),
    title: "Last Modified By",
  },
  {
    field: nameOf("LastModifiedDate"),
    title: "Last Modified Date",
    format: DATE_FORMAT.DATE,
  },
];
