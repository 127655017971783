import { DataTableTrimLocation } from "@app/products/town-planning/ppr/[id]/components/input-picker/trim-location-picker/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const searchLocationOnly = async (
  searchText: string
): Promise<APIResponse<DataTableTrimLocation>> => {
  try {
    return await CoreAPIService.getClient().post<any>(
      `/api/core/internal/settings/records-trim-get-locations`,
      JSON.stringify(searchText)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
