import { apiCoreGetActiontypes } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ActionType } from "@common/models/actionType";

export const getActionTypeById = async (
  actionTypeId: number
): Promise<APIResponse<ActionType | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ActionType>(
      `api/core/internal/actions/actiontypes/${actionTypeId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postActionType = async (
  requestBody: ActionType
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<ActionType>(
      apiCoreGetActiontypes(),
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
