export const maxWidthForTabletAndMobileDevice: number = 1366;
export const maxWidthForLaptopAndDesktopDevice: number = 1920;

export const CC_NAV_DIALOG_CLASS_NAME: string = ".cc-nav-type-dropdown";
export const CC_DIALOG_VISIBLE_CLASS_NAME: string = "cc-dialog-visible";

export const defaultSize = {
  width: "95%",
  height: "85%",
  maxWidth: "100%",
};
