import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type INSWEplanningPortalFormProps = {
  formRenderProps: FormRenderProps;
};

export const NSWEplanningPortalForm = observer(
  ({ formRenderProps }: INSWEplanningPortalFormProps) => {
    const { valueGetter } = formRenderProps;
    const { getConfigDataField } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    const fieldEnableIntegration = getConfigDataField(
      ECorporateSettingsField.TP_Enable_NSW_ePlanning_Portal_Integration
    );
    const fieldBaseURL = getConfigDataField(
      ECorporateSettingsField.TP_NSW_ePlanning_Portal_BaseURL
    );
    const fieldAPIKey = getConfigDataField(
      ECorporateSettingsField.TP_NSW_ePlanning_Portal_APIKey
    );
    const fieldCouncilID = getConfigDataField(
      ECorporateSettingsField.TP_NSW_ePlanning_Portal_CouncilID
    );
    const fieldPortalURL = getConfigDataField(
      ECorporateSettingsField.EPlanning_StatePortalURL
    );
    const fieldPortalDisplayName = getConfigDataField(
      ECorporateSettingsField.EPlanning_StatePortalDisplayName
    );
    const fieldAddToRecords = getConfigDataField(
      ECorporateSettingsField.TP_NSW_ePlanning_Portal_AddToRecords
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldEnableIntegration && (
            <div className="cc-field">
              <CCLabel title={fieldEnableIntegration?.Title ?? ""} />
              <Field
                name={fieldEnableIntegration.FieldName}
                component={CCSwitch}
                checked={valueGetter(fieldEnableIntegration.FieldName)}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldEnableIntegration &&
            valueGetter(fieldEnableIntegration.FieldName) && (
              <>
                {fieldBaseURL && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldBaseURL?.Title ?? ""}
                      isMandatory={fieldBaseURL.IsMandatory}
                    />
                    <Field
                      name={fieldBaseURL.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                      validator={
                        fieldBaseURL.IsMandatory ? requiredValidator : undefined
                      }
                    />
                  </div>
                )}
                {fieldAPIKey && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldAPIKey?.Title ?? ""}
                      isMandatory={fieldAPIKey.IsMandatory}
                    />
                    <Field
                      name={fieldAPIKey.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                      validator={
                        fieldAPIKey.IsMandatory ? requiredValidator : undefined
                      }
                    />
                  </div>
                )}
                {fieldCouncilID && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldCouncilID?.Title ?? ""}
                      isMandatory={fieldCouncilID.IsMandatory}
                    />
                    <Field
                      name={fieldCouncilID.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                      validator={
                        fieldCouncilID.IsMandatory
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
                {fieldPortalURL && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldPortalURL?.Title ?? ""}
                      isMandatory={fieldPortalURL.IsMandatory}
                    />
                    <Field
                      name={fieldPortalURL.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                      validator={
                        fieldPortalURL.IsMandatory
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
                {fieldPortalDisplayName && (
                  <div className="cc-field">
                    <CCLabel
                      title={fieldPortalDisplayName?.Title ?? ""}
                      isMandatory={fieldPortalDisplayName.IsMandatory}
                    />
                    <Field
                      name={fieldPortalDisplayName.FieldName}
                      component={CCInput}
                      disabled={!isEditing}
                      validator={
                        fieldPortalDisplayName.IsMandatory
                          ? requiredValidator
                          : undefined
                      }
                    />
                  </div>
                )}
              </>
            )}
          <SwitchSettings
            data={fieldAddToRecords}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
