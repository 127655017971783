import { ICollectionSelectionResponse } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/collection-selections/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunCollectionStepStore {
  private _collectionLOVs?: ICollectionSelectionResponse = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get collectionLOVs() {
    return toJS(this._collectionLOVs);
  }
  setCollectionLOVs = (collectionLOVs?: ICollectionSelectionResponse) => {
    runInAction(() => {
      this._collectionLOVs = collectionLOVs;
    });
  };
}

const noticeRunCollectionStepStoreContext = createContext(
  new NoticeRunCollectionStepStore()
);
export const useNoticeRunCollectionStepStore = () => {
  return useContext(noticeRunCollectionStepStoreContext);
};
