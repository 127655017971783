import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { EnterSpecialReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/_index";
import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import { getRegisterSpecialReadingData } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/api";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const EnterSpecialReadingButton = observer(() => {
  const [showEnterSpecialReadingDialog, setShowEnterSpecialReading] =
    useState<boolean>(false);
  const [specialReadingData, setSpecialReadingData] = useState<
    ISpecialReadingDialogData | undefined
  >();
  const { register } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { metersSelected } = useRegisterMetersStore();

  return (
    <>
      <CCNavButton
        title="Enter special reading"
        onClick={() => {
          if (!register) return;
          getRegisterSpecialReadingData(register, metersSelected).then(
            (data: ISpecialReadingDialogData) => {
              if (!data) return;
              setSpecialReadingData(data);
              setShowEnterSpecialReading(true);
            }
          );
        }}
        disabled={!metersSelected.length}
      />

      {showEnterSpecialReadingDialog && (
        <EnterSpecialReadingDialog
          onClose={() => {
            setShowEnterSpecialReading(false);
          }}
          onEnterAndExit={() => {
            setShowEnterSpecialReading(false);
            pushNotification({
              title: "Special reading entered successfully",
              type: "success",
            });
          }}
          specialReadingData={specialReadingData}
        />
      )}
    </>
  );
});
