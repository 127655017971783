import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { CollectionsButton } from "@app/products/property/components/action-bar/button/collections/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { DeferredDutyAddToJournalButton } from "@app/products/property/deferred-duty/[id]/components/action-bar/button/add-to-journal/_index";
import { ReverseDeferredDutyAccountTransactionButton } from "@app/products/property/deferred-duty/[id]/components/action-bar/button/reverse-transaction/_index";
import { TransferDeferredDutyAccountTransactionButton } from "@app/products/property/deferred-duty/[id]/components/action-bar/button/transfer-transaction/_index";
import DeferredDutyAttributes from "@app/products/property/deferred-duty/[id]/components/child-screens/attributes/_index";
import { DeferredDutyBalances } from "@app/products/property/deferred-duty/[id]/components/child-screens/balances/_index";
import { PropertyDeferredDutyGeneral } from "@app/products/property/deferred-duty/[id]/components/child-screens/general/components/_index";
import { DeferredDutyNotices } from "@app/products/property/deferred-duty/[id]/components/child-screens/notices/_index";
import { DeferredDutyTransaction } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/_index";
import { DeferredDutyAssociationsTab } from "@app/products/property/deferred-duty/[id]/components/reference-sidebar/associations/_index";
import { DeferredDutyDetailTab } from "@app/products/property/deferred-duty/[id]/components/reference-sidebar/details/_index";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { CalCulateInstalmentButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/calculate-instalment/_index";
import { ModifyDeferredDutyButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/modify-deferred-duty/_index";
import { RaiseDeferredDutyInterestButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/raise-interest/_index";
import { WriteOffBalancesButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/write-off-balances/_index";
import { eAccordion } from "@app/products/property/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedDeferredDutyRequest = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const {
    deferredDuty,
    isLoading,
    responseLoadError,
    loadDeferredDuty,
    deferredDutyAccountId,
  } = useDeferredDutyStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        deferredDuty?.AssessmentId,
        deferredDuty?.PropertyAddress,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Deferred Duty - ${deferredDutyAccountId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: deferredDutyAccountId ?? 0,
    },
  ];

  const formTitle = useMemo(() => {
    const assessmentId = deferredDuty?.AssessmentId;
    const propertyAddress = deferredDuty?.PropertyAddress;

    if (assessmentId && propertyAddress) {
      return `${assessmentId} - ${propertyAddress}`;
    }

    return (assessmentId || propertyAddress || "N/A") + "";
  }, [deferredDuty]);

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Deferred_Duty_Actions_And_Workflows,
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Deferred_Duty_Account}
          componentNumber={eComponent.Deferred_Duty_Account}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit
    //       id={parseInt(id)}
    //       componentNumber={eComponent.Deferred_Duty_Account}
    //     />
    //   ),
    // },
    {
      accordionId: eAccordion.Deferred_Duty_Notices,
      title: "Notices",
      component: <DeferredDutyNotices />,
    },
    {
      accordionId: eAccordion.Deferred_Duty_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Deferred_Duty_Account}
        />
      ),
    },
    {
      accordionId: eAccordion.Deferred_Duty_Collections,
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.Deferred_Duty_Account}
        />
      ),
    },
    {
      accordionId: eAccordion.Deferred_Duty_Transactions,
      title: "Transactions",
      component: <DeferredDutyTransaction />,
    },
    {
      accordionId: eAccordion.Deferred_Duty_Balances,
      title: "Balances",
      component: <DeferredDutyBalances />,
    },
    {
      accordionId: eAccordion.Deferred_Duty_Attributes,
      title: "Attributes",
      isVisible: false,
      component: <DeferredDutyAttributes />,
    },
    {
      accordionId: eAccordion.Deferred_Duty_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Deferred_Duty_Account}
        />
      ),
    },
    {
      accordionId: eAccordion.Deferred_Duty_Related,
      title: "Related",
      component: (
        <PropertyAssociations
          id={deferredDutyAccountId}
          componentNumber={eComponent.Deferred_Duty_Account}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Deferred Duty" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDeferredDuty(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={formTitle}
            badge={deferredDuty?.Status?.trim() || ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <ModifyDeferredDutyButton />
                <CalCulateInstalmentButton />
                <CollectionsButton
                  id={parseInt(id)}
                  propertyComponent={eComponent.Deferred_Duty_Account}
                />
                <CCNavButton title={"Journal "} type="dropdown">
                  <DeferredDutyAddToJournalButton />
                  <RaiseDeferredDutyInterestButton />
                  <ReverseDeferredDutyAccountTransactionButton />
                  <TransferDeferredDutyAccountTransactionButton />
                  <WriteOffBalancesButton />
                  {/* Hide now, new feature to be implemented in the future
                  <DeferredDutyAdjustBalancesButton /> */}
                </CCNavButton>
              </CCNavButton>,

              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={
                    RECORDTYPE.CommunityProperty_Deferred_Duty_Account
                  }
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={
                    RECORDTYPE.CommunityProperty_Deferred_Duty_Account
                  }
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={
                    MAILMERGEDATASET.COMMUNITYPROPERTY_Deferred_Duty
                  }
                  recordType={
                    RECORDTYPE.CommunityProperty_Deferred_Duty_Account
                  }
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={
                    RECORDTYPE.CommunityProperty_Deferred_Duty_Account
                  }
                />
                <CCNavButton title={"Contact"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {deferredDuty && (
                  <CCGeneralPanel component={<PropertyDeferredDutyGeneral />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Deferred_Duty_Account}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <DeferredDutyDetailTab />,
                    },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={+id}
                          componentNumber={eComponent.Deferred_Duty_Account}
                        />
                      ),
                    },
                    {
                      title: "Related",
                      component: <DeferredDutyAssociationsTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
