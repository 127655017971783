import { mockResultViewData } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/components/form-elements/results/mock";
import { validatorNameField } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/components/form-elements/results/util";
import { nameOfLov } from "@app/products/property/model";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React from "react";

export const RESULTS_FORM_STEP = "Results";

export const ResultsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={ResultsStepElement}
    />
  );
};

const ResultsStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">
            List view <CCTooltip type="validator" position="right" />
          </label>
          <Field
            data={mockResultViewData}
            name={nameOf("ListView")}
            validator={requiredValidator}
            component={CCSearchComboBox}
            textField={nameOfLov("Name")}
            dataItemKey={nameOfLov("Code")}
            value={getDropdownValue(
              valueGetter(nameOf("ListView")),
              mockResultViewData,
              nameOfLov("Code")
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(nameOf("ListView"), {
                value: event.target.value?.Code ?? null,
              });
            }}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Name</label>
          <Field
            name={nameOf("Name")}
            component={CCInput}
            placeholder="Enter name or description of the search"
            validator={validatorNameField}
            onBlur={() => {
              //check when clicking out field => Will fill default value
              if (
                valueGetter(nameOf("ListView")) &&
                (isNil(valueGetter(nameOf("Name"))) ||
                  !valueGetter(nameOf("Name"))?.trim().length)
              ) {
                onChange(nameOf("Name"), {
                  value: valueGetter(nameOf("ListView")),
                });
              }
            }}
          />
        </div>
      </div>
    </section>
  );
};
