import { ContactClassification } from "@app/core/contacts/_id/model";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";

//Contact Classification Filters
//Default External
export const contactFiltersDefaultExternal = [
  ContactClassification.Person,
  ContactClassification.Company,
  ContactClassification.Partnership,
];

//Relationship Filters
//Additional Only
export const relationshipFiltersAdditionalOnly = [
  ContactRelationshipType.AdditionalContact,
];

//TownPlanning
export const relationshipFiltersTownPlanning = [
  ContactRelationshipType.AdditionalContact,
  ContactRelationshipType.AdditionalContact_Accountant,
  ContactRelationshipType.AdditionalContact_AdditionalApplicant,
  ContactRelationshipType.AdditionalContact_Architect,
  ContactRelationshipType.AdditionalContact_Builder,
  ContactRelationshipType.AdditionalContact_Conveyancer,
  ContactRelationshipType.AdditionalContact_Draftsman,
  ContactRelationshipType.AdditionalContact_Designer,
  ContactRelationshipType.AdditionalContact_Engineer,
  ContactRelationshipType.AdditionalContact_EstateAgent,
  ContactRelationshipType.AdditionalContact_InterestedParty,
  ContactRelationshipType.AdditionalContact_Landlord,
  ContactRelationshipType.AdditionalContact_Practitioner,
  ContactRelationshipType.AdditionalContact_PreferredContact,
  ContactRelationshipType.AdditionalContact_PrivateCertifier,
  ContactRelationshipType.AdditionalContact_PrivateInspector,
  ContactRelationshipType.AdditionalContact_PropertyOwner,
  ContactRelationshipType.AdditionalContact_Solicitor,
  ContactRelationshipType.AdditionalContact_Surveyor,
  ContactRelationshipType.AdditionalContact_Tenant,
  ContactRelationshipType.AdditionalContact_Witness,
  ContactRelationshipType.AdditionalContact_NominatedLevyPayer,
  ContactRelationshipType.AdditionalContact_Payee,
  ContactRelationshipType.Plumber,
];

//Health Manager Premises
export const relationshipFiltersHealthManagerPremises = [
  ContactRelationshipType.AdditionalContact,
  ContactRelationshipType.AdditionalContact_Accountant,
  ContactRelationshipType.AdditionalContact_Auditor,
  ContactRelationshipType.AdditionalContact_BusinessOwner,
  ContactRelationshipType.AdditionalContact_Director,
  ContactRelationshipType.AdditionalContact_EstateAgent,
  ContactRelationshipType.AdditionalContact_EventCoordinator,
  ContactRelationshipType.HM_AdditionalContact_FSSSecondary,
  ContactRelationshipType.HM_AdditionalContact_FSSPrevious,
  ContactRelationshipType.HM_AdditionalContact_InfectionControlOfficer,
  ContactRelationshipType.AdditionalContact_Landlord,
  ContactRelationshipType.AdditionalContact_ManagementCompany,
  ContactRelationshipType.AdditionalContact_Manager,
  ContactRelationshipType.AdditionalContact_President,
  ContactRelationshipType.HM_AdditionalContact_PreviousProprietor,
  ContactRelationshipType.AdditionalContact_PropertyOwner,
  ContactRelationshipType.AdditionalContact_PublicOfficer,
  ContactRelationshipType.HM_AdditionalContact_RecallContact,
  ContactRelationshipType.AdditionalContact_Solicitor,
  ContactRelationshipType.AdditionalContact_Supervisor,
  ContactRelationshipType.AdditionalContact_BuildingOwner,
  ContactRelationshipType.AdditionalContact_BuildingManager,
  ContactRelationshipType.AdditionalContact_SystemOwner,
  ContactRelationshipType.AdditionalContact_SystemManager,
  ContactRelationshipType.AdditionalContact_AfterHoursContact,
  ContactRelationshipType.AdditionalContact_Doctor,
  ContactRelationshipType.AdditionalContact_Counsellor,
];

//Animal Kennel
export const relationshipFiltersAnimalKennel = [
  ContactRelationshipType.AdditionalContact,
  ContactRelationshipType.Animals_AdditionalContact_KennelOwner,
];
