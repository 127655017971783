import { CentreLinkResponseBatchDetailsFormElement } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/details/components/form-element/_index";
import { useCentreLinkResponseBatchStore } from "@app/products/property/centrelink-customer-confirmation/view-responses/store";
import { Form } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const CentreLinkResponseBatchDetailsForm = observer(() => {
  const { responseBatchDetails } = useCentreLinkResponseBatchStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={responseBatchDetails}
        key={JSON.stringify(responseBatchDetails)}
        render={() => <CentreLinkResponseBatchDetailsFormElement />}
      />
    </div>
  );
});
