import {
  CS_CommunicationMethod,
  EventClose,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import { CCPickEventClosure } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-event-closure/_index";
import { CS_LookupTable } from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { Contact } from "@app/products/waste-water/[id]/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { join } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./index.scss";

export interface ICloseEventDialogProps {
  onClosePopup: () => void;
  onSubmit: (data: EventClose) => void;
  notifications?: IAppNotificationItemAddProps;
  crmsEventClose: EventClose;
  isLoading: boolean;
  isPhoneMsg?: boolean;
}

export const CloseEventDialog = observer(
  ({
    crmsEventClose,
    onClosePopup,
    onSubmit,
    isLoading,
    isPhoneMsg = false,
  }: ICloseEventDialogProps) => {
    const {
      notificationCloseEvent,
      setNotificationCloseEvent,
      crmsEventCloseLovs,
      crmsEvent,
    } = useCRMSEventStore();
    const {
      notification: notificationPhoneMsg,
      setNotification: setNotificationPhoneMsg,
    } = useCRMSPhoneMessageStore();

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const nameOf = nameOfFactory<EventClose>();
    const [isShowPickEventClosure, setIsShowPickEventClosure] = useState(false);
    const [communicationMethodText, setCommunicationMethodText] = useState("");
    const [initialValues, setInitialValues] = useState<any>(null);

    const [isFinalNotificationTextChange, setIsFinalNotificationTextChange] =
      useState(false);

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      onSubmit(event.values as EventClose);
    };

    const isEnableAcknowledge = useMemo(() => {
      if (
        crmsEventClose.AcknowledgementRequired ||
        crmsEventClose.CallReturned
      ) {
        return true;
      }
      return false;
    }, [crmsEventClose]);

    const isFinalNotification = useMemo(() => {
      return crmsEvent?.ServiceStandard.Targets_FinalNotification;
    }, [crmsEvent]);

    const isShowInternalComments = useMemo(
      () => crmsEventClose.ShowInternalComments,
      [crmsEventClose]
    );

    useEffect(() => {
      if (notificationCloseEvent && !isPhoneMsg) {
        notificationRef.current?.pushNotification(notificationCloseEvent, () =>
          setNotificationCloseEvent(undefined)
        );
      }

      if (notificationPhoneMsg && isPhoneMsg) {
        notificationRef.current?.pushNotification(notificationPhoneMsg, () =>
          setNotificationPhoneMsg(undefined)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationCloseEvent, notificationPhoneMsg]);

    const communicationMethod = () => {
      let value = "";

      if (crmsEventClose.RequestedByPreferred) {
        switch (true) {
          case crmsEventClose.RequestedByPreferredMobile:
            value = crmsEventClose.CommunicationMethod_Mobile;
            break;
          case crmsEventClose.RequestedByPreferredEmail:
            value = crmsEventClose.CommunicationMethod_Email;
            break;
          case crmsEventClose.RequestedByPreferredWorkPhone:
            value = crmsEventClose.CommunicationMethod_WorkPhone;
            break;
          case crmsEventClose.RequestedByPreferredHomePhone:
            value = crmsEventClose.CommunicationMethod_HomePhone;
            break;
          case crmsEventClose.RequestedByPreferredFax:
            value = crmsEventClose.CommunicationMethod_Fax;
            break;
          default:
            value = "";
            break;
        }
      } else {
        const finalNoticeSentMethodDisplay =
          crmsEventClose.FinalNoticeSentMethod_Display;
        const allCommunicationMethod = Object.keys(CS_CommunicationMethod);

        if (allCommunicationMethod.includes(finalNoticeSentMethodDisplay)) {
          value = "";
        }
      }

      setCommunicationMethodText(value);
    };

    useEffectOnce(() => {
      const eventClose = crmsEventClose;
      const hasFinalNoticeSentMethod =
        crmsEventCloseLovs?.FinalNoticeSentBy?.some(
          (item) => item.Key === eventClose?.FinalNoticeSentMethod_ID
        );
      if (!hasFinalNoticeSentMethod) {
        eventClose.FinalNoticeSentMethod_ID = null;
        eventClose.FinalNoticeSentMethod_Display = "";
      }
      setInitialValues(eventClose);
    });

    useEffect(() => {
      communicationMethod();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crmsEventClose]);

    const isContact = (contact?: SiteUser | Contact) => {
      if (!contact) return false;
      return (
        !!contact._HomePhone ||
        !!contact._WorkPhone ||
        !!contact._Mobile ||
        !!contact.Email
      );
    };

    const isShowNotifyCustomer = useMemo(() => {
      return (
        isContact(crmsEvent?.RequestedBy?.Contact) ||
        isContact(crmsEvent?.RequestedBy_SiteUser)
      );
    }, [crmsEvent]);

    const filterIDs = useMemo(() => {
      const closureCommentIDs =
        crmsEventClose?.ClosureComment_LookupValue_IDs ?? [];
      const filterString = join(closureCommentIDs, "|");
      return filterString ? `,filterIDs='${filterString}'` : "";
    }, [crmsEventClose?.ClosureComment_LookupValue_IDs]);

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={(event: FormSubmitClickEvent) => handleSubmit(event)}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, modified, valid, onChange, valueGetter } =
            formRenderProps;

          const handleByChange = (event: ComboBoxChangeEvent) => {
            onChange("FinalNoticeSentMethod_ID", {
              value: event.target.value?.Key ?? null,
            });
            onChange("FinalNoticeSentMethod_Display", {
              value: event.target.value?.Value ?? null,
            });

            finalNoticeSentByChanged();
          };

          const finalNoticeSentByChanged = () => {
            const keyOfEnumAsString = valueGetter(
              "FinalNoticeSentMethod_Display"
            );
            const indexOfValue = Object.keys(CS_CommunicationMethod).indexOf(
              keyOfEnumAsString
            );

            if (indexOfValue === -1) return;

            switch (Object.values(CS_CommunicationMethod)[indexOfValue]) {
              case CS_CommunicationMethod.Preferred:
                onChange("RequestedByPreferred", { value: true });
                break;
              case CS_CommunicationMethod.Email:
                onChange("RequestedByPreferred", { value: false });
                onChange("RequestedByInternal", { value: true });
                onChange("RequestedByEmail", { value: true });
                break;
              case CS_CommunicationMethod.SMS:
                onChange("RequestedByPreferred", { value: false });
                onChange("RequestedBySMS", { value: true });
                onChange("RequestedByInternal", { value: true });
                break;
              case CS_CommunicationMethod.Fax:
                onChange("RequestedByPreferred", { value: false });
                onChange("RequestedByFax", { value: true });
                onChange("RequestedByInternal", { value: true });
                break;
              default:
                onChange("RequestedByPreferred", { value: false });
                onChange("RequestedByInternal", { value: true });
                onChange("RequestedByEmail", { value: false });
                onChange("RequestedBySMS", { value: false });
                onChange("RequestedByFax", { value: false });
                break;
            }
            getCommunicationMethod();
          };

          const getCommunicationMethod = () => {
            let communicationMethodText = "";
            if (valueGetter("RequestedByPreferred")) {
              switch (true) {
                case valueGetter("RequestedByPreferredMobile"):
                  communicationMethodText = valueGetter(
                    "CommunicationMethod_Mobile"
                  );
                  break;
                case valueGetter("RequestedByPreferredEmail"):
                  communicationMethodText = valueGetter(
                    "CommunicationMethod_Email"
                  );
                  break;
                case valueGetter("RequestedByPreferredWorkPhone"):
                  communicationMethodText = valueGetter(
                    "CommunicationMethod_WorkPhone"
                  );
                  break;
                case valueGetter("RequestedByPreferredHomePhone"):
                  communicationMethodText = valueGetter(
                    "CommunicationMethod_HomePhone"
                  );
                  break;
                case valueGetter("RequestedByPreferredFax"):
                  communicationMethodText = valueGetter(
                    "CommunicationMethod_Fax"
                  );
                  break;
                default:
                  communicationMethodText = "";
                  break;
              }
            } else {
              const allCommunicationMethod = Object.keys(
                CS_CommunicationMethod
              );

              if (
                allCommunicationMethod.includes(
                  valueGetter("FinalNoticeSentMethod_Display")
                )
              ) {
                communicationMethodText = "";
              }
            }
            setCommunicationMethodText(communicationMethodText);
          };

          return (
            <FormElement>
              <CCDialog
                maxWidth="30%"
                height="auto"
                titleHeader={"Close Event"}
                onClose={() => {
                  onClosePopup();
                }}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Date closed
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("DateClosed")}
                            component={CCDateTimePicker}
                            validator={requiredValidator}
                            format={DATETIME_FORMAT.DATETIME_CONTROL}
                            disabled={isLoading}
                          />
                        </div>
                        {isShowInternalComments && (
                          <div className="cc-field">
                            <div className="cc-label cc-justify-between cc-align-item">
                              <div className="cc-flex">
                                Closure detail (to be inserted in the journal)
                                <CCTooltip type="validator" position="right" />
                              </div>
                              <Button
                                type="button"
                                iconClass="fas fa-plus"
                                onClick={() => {
                                  setIsShowPickEventClosure(true);
                                }}
                                disabled={isLoading}
                                title="Insert a Standard Resolution"
                              />
                            </div>
                            <Field
                              name={nameOf("ClosureComment")}
                              component={CCTextArea}
                              placeholder="Closure detail"
                              validator={requiredValidator}
                              rows={3}
                              readOnly={isLoading}
                            />
                          </div>
                        )}
                      </div>
                    </section>

                    {isEnableAcknowledge ? (
                      <section className="cc-field-group cc-margin-top">
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              Acknowledged date
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              validator={
                                isEnableAcknowledge
                                  ? requiredValidator
                                  : undefined
                              }
                              name={nameOf("DateAcknowledged")}
                              component={CCDatePicker}
                              format={DATE_FORMAT.DATE_CONTROL}
                              disabled={isLoading}
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">
                              Acknowledged how
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("AcknowledgedMethod_ID")}
                              component={CCSearchComboBox}
                              validator={
                                isEnableAcknowledge
                                  ? requiredValidator
                                  : undefined
                              }
                              data={crmsEventCloseLovs?.AcknowledgedHow ?? []}
                              textField={"Value"}
                              dataItemKey={"Key"}
                              isUseDefaultOnchange
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                      </section>
                    ) : (
                      <>
                        <hr className="cc-divider" />
                        <Error>Acknowledgement not required</Error>
                        <hr className="cc-divider" />
                      </>
                    )}

                    {isFinalNotification && (
                      <section className="cc-field-group cc-margin-top">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <div className="cc-label cc-justify-between cc-align-item">
                              <div className="cc-flex">
                                Final notification text (This text will be
                                visible to the customer/requestor)
                                <CCTooltip type="validator" position="right" />
                              </div>
                              <Button
                                type="button"
                                iconClass="fas fa-plus"
                                onClick={() => {
                                  setIsShowPickEventClosure(true);
                                  setIsFinalNotificationTextChange(true);
                                }}
                                disabled={isLoading}
                                title="Insert a Standard Resolution"
                              />
                            </div>

                            <Field
                              name={nameOf("FinalNotificationText")}
                              component={CCTextArea}
                              placeholder="Final notice sent"
                              rows={3}
                              readOnly={isLoading}
                              validator={
                                isFinalNotification
                                  ? requiredValidator
                                  : undefined
                              }
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-col-span-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Final notice sent
                                <CCTooltip type="validator" position="right" />
                              </label>
                              <Field
                                name={nameOf("DateFinalNoticeSent")}
                                component={CCDatePicker}
                                format={DATE_FORMAT.DATE_CONTROL}
                                disabled={isLoading}
                                validator={
                                  isFinalNotification
                                    ? requiredValidator
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-1 ">
                            <div className="cc-field cc-col-span-1">
                              <label className="cc-label">
                                By
                                <CCTooltip type="validator" position="right" />
                              </label>
                              <Field
                                name={nameOf("FinalNoticeSentMethod_ID")}
                                component={CCSearchComboBox}
                                validator={
                                  isFinalNotification
                                    ? requiredValidator
                                    : undefined
                                }
                                data={
                                  crmsEventCloseLovs?.FinalNoticeSentBy ?? []
                                }
                                textField={"Value"}
                                dataItemKey={"Key"}
                                disabled={isLoading}
                                onChange={handleByChange}
                                value={getDropdownValue(
                                  valueGetter("FinalNoticeSentMethod_ID"),
                                  crmsEventCloseLovs?.FinalNoticeSentBy ?? [],
                                  "Key"
                                )}
                              />
                            </div>
                            <CCValueField
                              className="cc-col-span-1"
                              value={communicationMethodText}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2">
                          {isShowNotifyCustomer && (
                            <div className="cc-field">
                              <label className="cc-label">
                                Notify customer of event closure
                                <CCTooltip
                                  type="info"
                                  content="If this option is ticked, an email will be sent to the customer notifying them that he event has been closed"
                                />
                              </label>
                              <Field
                                name={nameOf("NotifyCustomer")}
                                component={CCSwitch}
                                value={valueGetter(nameOf("NotifyCustomer"))}
                                disabled={isLoading}
                              />
                            </div>
                          )}

                          {/* Implement later */}
                          {/* <div className="cc-field">
                            <label className="cc-label">
                              Close same issue events
                              <CCTooltip
                                type="info"
                                content="If this option is ticked, all events with the same issue will also be closed"
                              />
                            </label>
                            <Field
                              name={nameOf("SameIssue")}
                              component={CCSwitch}
                              value={valueGetter(nameOf("SameIssue"))}
                              disabled={isLoading}
                            />
                          </div> */}
                        </div>
                      </section>
                    )}
                    {/* TODO: Implement later */}
                    {/* <div className="cc-field-group">
                            <label className="cc-label">Health Issue</label>
                            <div className="cc-form-cols-2 cc-custom-sub-panel-bar">
                              <div className="cc-field">
                                <label className="cc-label">Issue</label>
                                <Field
                                  name={nameOf("EnvHealthIssue_ID")}
                                  component={CCSearchComboBox}
                                  validator={requiredValidator}
                                  data={[]}
                                  textField={nameOfCoreKeyword("Name")}
                                  dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">Action</label>
                                <Field
                                  name={nameOf("EnvHealthAction_ID")}
                                  component={CCSearchComboBox}
                                  validator={requiredValidator}
                                  data={[]}
                                  textField={nameOfCoreKeyword("Name")}
                                  dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">Result</label>
                                <Field
                                  name={nameOf("EnvHealthResult_ID")}
                                  component={CCSearchComboBox}
                                  validator={requiredValidator}
                                  data={[]}
                                  textField={nameOfCoreKeyword("Name")}
                                  dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">Food Type</label>
                                <Field
                                  name={nameOf("EnvHealthFoodType_ID")}
                                  component={CCSearchComboBox}
                                  validator={requiredValidator}
                                  data={[]}
                                  textField={nameOfCoreKeyword("Name")}
                                  dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cc-field-group">
                            <label className="cc-label">School Patrols</label>
                            <div className="cc-form-cols-2 cc-custom-sub-panel-bar">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Total Number Of visits{" "}
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("NoOfvisits_SchoolPatrols")}
                                  component={CCNumericTextBox}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Total Time Taken (HH:MM)
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("TimeTaken_SchoolPatrols")}
                                  component={CCDatePicker}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Total Number Of Expiations Issued
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("NoOfExpitations_SchoolPatrols")}
                                  component={CCNumericTextBox}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cc-field-group">
                            <label className="cc-label">Nuisance Act</label>
                            <div className="cc-form-cols-2 cc-custom-sub-panel-bar">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Total Time Taken (HH:MM)
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("TimeTaken_NuisanceAct")}
                                  component={CCDatePicker}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Complexity
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("ComplexityGrouped_NuisanceAct")}
                                  component={CCSearchComboBox}
                                  validator={requiredValidator}
                                  data={[]}
                                  textField={nameOfCoreKeyword("Name")}
                                  dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Total Number Of Abatement Notices
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("NoOfAbatement_NuisanceAct")}
                                  component={CCNumericTextBox}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Total Number Of Expiations Issued
                                  <CCTooltip
                                    type="validator"
                                    position="right"
                                  />
                                </label>
                                <Field
                                  name={nameOf("NoOfExpitations_NuisanceAct")}
                                  component={CCNumericTextBox}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Create REFLECT Request on Closure (If this option
                              is ticked, events will send to REFLECT asset
                              system)
                            </label>
                            <Field
                              name={nameOf("SendAssetToReflect")}
                              component={CCSwitch}
                              value={valueGetter(nameOf("SendAssetToReflect"))}
                            />
                          </div> */}

                    {isShowPickEventClosure && (
                      <CCPickEventClosure
                        isMultiple={false}
                        dataUrl={`/odata/crms/internal/lookuptable/GetCurrentLookupValues(lookupTableType=${CS_LookupTable.EventClosureComment}${filterIDs})?$count=true&`}
                        onClose={() => setIsShowPickEventClosure(false)}
                        onSubmit={(data) => {
                          setIsShowPickEventClosure(false);
                          onChange(
                            isFinalNotificationTextChange
                              ? nameOf("FinalNotificationText")
                              : nameOf("ClosureComment"),
                            {
                              value: data?.Description,
                            }
                          );
                          setIsFinalNotificationTextChange(false);
                        }}
                      />
                    )}
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClosePopup();
                      }}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!modified || !valid || isLoading}
                      themeColor="primary"
                      onClick={onSubmit}
                      className={"cc-dialog-button"}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
