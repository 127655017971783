import {
  DTOBaseClass_Standard,
  ElementControl,
} from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { Contact } from "@app/products/town-planning/[id]/model";
import { StateRoad } from "@app/products/town-planning/ppr/[id]/model";
import {
  CouncilConsentAssessment,
  RegisterStatus,
  RegisterType,
  RegisterUpdateTriggers,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import {
  BubbleUpIdentifier,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Address } from "@common/input-pickers/address/model";
import { Keyword_Tags } from "@common/models/keyword";
import { Council } from "@components/cc-pick-council/model";

export interface LocationRegisterMapObj {
  LocationRegister: Register;
}
export interface Register extends DTOBaseClass_Standard {
  Register_ID: number;
  Legacy_LID: string;
  Register_Number: string;
  RegisterType_ENUM: RegisterType;
  RegisterType_Name: string;
  Status_ENUM: RegisterStatus;
  Status_Name: string;
  Description: string;
  AdditionalInfo: string;
  Comment1: string;
  Comment2: string;
  ProductType_ENUM: PRODUCT_TYPE_NUMBER | null;
  ProductType_Name: string;
  IsBondDataNonEditable: boolean;
  IsBondExported: boolean | null;
  Contact: Contact_Relation;
  Contact_RID: number | null;
  Contact2: Contact_Relation;
  Contact2_RID: number | null;
  Officer: SiteUser;
  Officer_ID: number | null;
  BuildSurveyor_ID: number | null;
  BuildSurveyor: SiteUser;
  Address: Address;
  Address_ID: number | null;
  AdditionalAddresses: Address[];
  Type_KWD: number | null;
  Outcome_KWD: number | null;
  Source_KWD: number | null;
  Category_KWD: number | null;
  Regulations_KWD: number | null;
  BarrierTypes_KWD: number | null;
  Regulations_Description: string;
  BarrierTypes_Description: string;
  Building_UseOfBuilding_KeyWord: KeyValuePacket[];
  Building_UseOfBuilding_Keyword_Display: string;
  Outcome_Description: string;
  ReferenceNumber: string;
  PlanForSubDivision: string;
  NatureOfProposedBuildingWorks: string;
  MinimumHeightAboveGroundLevel: string;
  Flag_CopyRequiredWithResponse: boolean | null;
  ReferenceNumber2: string;
  FileNumber: string;
  ParentFileNumber: string;
  FrequencyOfInspection: number | null;
  InsurancePolicyNumber: string;
  BondReceiptNumber: string;
  BondAmount: number | null;
  DamageAmount: number | null;
  RefundAmount: number | null;
  CostOfWorks: number | null;
  CouncilConsentAssessment_OBJ: CouncilConsentAssessment;
  DebtorNumber: string;
  CouncilConsentType_KWD: number | null;
  CouncilConsentPermitType_ID: number | null;
  CT_Make: string;
  CT_Model: string;
  CT_Serial: string;
  CT_Application: string;
  CT_Location: string;
  CT_FreqOfOperation: string;
  Flag_BiocideElimFitted: boolean | null;
  Flag_DriftElimFittted: boolean | null;
  Flag_DriftCover: boolean | null;
  Flag_DriftBelow: boolean | null;
  Flag_DriftRemove: boolean | null;
  Pool_TurnOver: string;
  Pool_Siting: string;
  Pool_DisinfectantDosing: string;
  Pool_WaterTreatment: string;
  Pool_Filtration: string;
  Pool_WaterDisposal: string;
  Pool_Flag_CyanuricAcid: boolean | null;
  Pool_Flag_SharedCirculation: boolean | null;
  Pool_Flag_Contracted: boolean | null;
  Pool_Volume: number | null;
  BuildingPermitNumber: string;
  Date_Construction: Date | null;
  LetterNumber: string;
  Date_LetterSent: Date | null;
  Date_CertificateOfCompliance: Date | null;
  Pool_IsWorksCarriedOut: boolean | null;
  Pool_WorksCarriedOut_Desc: string;
  Pool_RegisteredDate: Date | null;
  Pool_ReRegisteredDate: Date | null;
  RenewalFee_ID: number | null;
  Date_Renewed: Date | null;
  Date_Lodgement_CertificateOfBarrierNC: Date | null;
  Date_WorksIssued_AfterDateOfConstruction: Date | null;
  Date_Recorded: Date | null;
  Date_Lodged: Date | null;
  Date_Start: Date | null;
  Date_Due: Date | null;
  Date_Complete: Date | null;
  Date_BondReceived: Date | null;
  Date_BondReturned: Date | null;
  ProposedStartDate: Date | null;
  ProposedFinishDate: Date | null;
  Date_LastInspectionCalculated: Date | null;
  Date_NextInspectionCalculated: Date | null;
  Flag_RequiresCertificate: boolean | null;
  Flag_RenewalFeeCreated: boolean | null;
  Flag_RenewalNoticeSent: boolean | null;
  Flag_Anonymous: boolean | null;
  Flag_Justified: boolean | null;
  Date_Occurred: Date | null;
  Date_Notified: Date | null;
  Date_Notified2: Date | null;
  Date_Notified3: Date | null;
  Date_FirstInspection: Date | null;
  Date_LastInspection: Date | null;
  Date_ReminderSent: Date | null;
  Items_Keyword_CSV: string;
  Date_Expiry: Date | null;
  RiskAssess_HasBreachOccured: boolean | null;
  RiskAssess_LevelOfEnforcement_KWD: number | null;
  RiskAssess_LevelOfEnforcement_Name: string;
  RiskAssess_ImportanceRating_KWD: number | null;
  RiskAssess_ImportanceRating_Name: string;
  RiskAssess_CapacityToEscalate_KWD: number | null;
  RiskAssess_CapacityToEscalate_Name: string;
  RiskAssess_ScaleOfBreach_KWD: number | null;
  RiskAssess_ScaleOfBreach_Name: string;
  RiskAssess_UrgencyRating_KWD: number | null;
  RiskAssess_UrgencyRating_Name: string;
  RiskAssess_AssessDetails: string;
  EPlanning_ID: number | null;
  EPlanning_Username: string;
  EPlanning_DisplayName: string;
  EPlanning_Categories: string;
  EnquiryMethod_KWD: number | null;
  ResponsibleAuthority_ID: number | null;
  ResponsibleAuthority: Council;
  VRStreetID: number | null;
  VRStreet: StateRoad;
  VR_OfficeID: number | null;
  VROffice: string;
  TeamLeader_ID: number | null;
  TeamLeader: SiteUser;
  Date_Responded: Date | null;
  OriginReferral_KWD: number | null;
  RejectionResponse_Notes: string;
  OfficerRecommendationNotes: string;
  Decision_Name: string;
  DecisionType_KWD: number | null;
  DecisionType_Name: string;
  FinalDecision_Name: string;
  FinalDecisionType_KWD: number | null;
  FinalDecisionType_Name: string;
  FinalDecisionDate: Date | null;
  EXT_Option_1: boolean | null;
  EXT_Option_2: boolean | null;
  EXT_Date_1: Date | null;
  EXT_Date_2: Date | null;
  EXT_Officer_1: string;
  EXT_Officer_2: string;
  EXT_Comment_1: string;
  EXT_Comment_2: string;
  ExistingUse: string;
  FormattedAuthorName: string;
  Items_Keyword: KeyValuePacket[];
  Items_Text: string;
  BubbleUps: BubbleUpIdentifier[];
  SaveTriggers: RegisterUpdateTriggers[];
  _SaveTriggers: RegisterUpdateTriggers[];
}

export interface LocationRegisterLovs {
  RegisterTypes: KeyValuePacket[];
  Types: KeyValuePacket[];
}

export class ContactRelation {
  ContactRelationshipType_ENUM: string = "";
  ContactRelationshipType_Name: string = "";
  Contact: Contact = new Contact();
  DisplayName: string = "";
  BubbleUps: BubbleUpIdentifier[] = [];
  _ExtensionType: string[] = [];
  ExtensionType: string[] = [];
  Extension?: string = "";
  PersonRole_KWD: number | null = null;
  PersonRole: any = {};
  RelationshipComment: string = "";
  _KeywordTags: Keyword_Tags[] = [];
  KeywordTags: Keyword_Tags[] = [];
  SaveTriggers: string[] = [];
}

export interface LocationRegisterHandlerRequest {
  LocationRegisterFormAction: Svc_FormAction_LocationRegister;
  Register: Register;
  LocationRegisterArgs: any;
  IsFirstTimeLoad: boolean;
}

export enum Svc_FormAction_LocationRegister {
  SystemInitialise,
  Form_PickAddress,
  Form_PickContact,
}

export interface LocationRegisterHandlerResponse {
  Register: Register;
  UIControl: LocationRegisterUIControl;
}

export interface LocationRegisterUIControl {
  LitContact: ElementControl;
  LitContactContact: ElementControl;
  LitContactAddress: ElementControl;
  LitAddress: ElementControl;
  LitOwners: ElementControl;
  LitDetails: ElementControl;
}

export interface MultiTenancyView {
  ID: number;
  RegisterType_Name: string;
  Date_Recorded: Date | null;
  Date_Lodged: Date | null;
  Date_Due: Date | null;
  Date_Start: Date | null;
  Date_Complete: Date | null;
  Description: string;
  ReferenceNumber: string;
  Register_Number: string;
  Address: string;
  Locality: string;
  LegalDescription: string;
  Type: string;
  Category: string;
  Outcome: string;
  Status: string;
  Officer: string;
  Contact: string;
  PostalAddress: string;
}
