import { renderOptionInputPickerKeyword } from "@app/core/components/common/config";
import { ODATA_GET_CONTACTS } from "@app/core/contacts/components/dialogs/pick-contact/constant";
import { renderOptionActionOfficer } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { PremisesBatchUpdateLocationState } from "@app/products/hm/components/action-bar/buttons/batch-update/model";
import {
  ICCOptionsPremisesBatchUpdate,
  IPremisesBatchUpdate,
} from "@app/products/hm/components/batch-update/model";
import {
  nameOfCoreKeyword,
  nameOfPremisesBatchUpdate,
  nameOfPremisesBatchUpdateOption,
} from "@app/products/hm/components/batch-update/util";
import {
  optionPremisesTypes,
  Svc_HMPremisesType,
} from "@app/products/hm/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { getKeywords } from "@common/apis/coreKeyword";
import { APIResponse } from "@common/apis/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { CoreKeyword } from "@common/models/coreKeyword";
import { Svc_ContactDetails } from "@common/pages/profile/components/input-picker/officer-picker/model";
import { onChanges } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { mapKeys } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

interface IPremisesBatchUpdateFormElementProps {
  formRenderProps: FormRenderProps;
}
const checkValidDate = (value: Date) => {
  return validatorDateValueWithMinMax(value, null, new Date());
};
export const PremisesBatchUpdateFormElement = observer(
  ({ formRenderProps }: IPremisesBatchUpdateFormElementProps) => {
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { valueGetter, onChange } = formRenderProps;

    const batchUpdate = {
      value: (item: keyof IPremisesBatchUpdate) =>
        valueGetter(nameOfPremisesBatchUpdate(item)),

      change: (dataUpdateObject: Partial<IPremisesBatchUpdate>) => {
        const dataUpdateObjectWithNameOf = mapKeys(
          dataUpdateObject,
          (_value, key) =>
            nameOfPremisesBatchUpdate(key as keyof IPremisesBatchUpdate)
        );
        onChanges(onChange, dataUpdateObjectWithNameOf);
      },

      option: (item: keyof ICCOptionsPremisesBatchUpdate["CCFormOptions"]) =>
        valueGetter(nameOfPremisesBatchUpdateOption(item)),

      changeOption: (
        dataUpdateObject: Partial<
          ICCOptionsPremisesBatchUpdate["CCFormOptions"]
        >
      ) => {
        const dataUpdateObjectWithNameOf = mapKeys(
          dataUpdateObject,
          (_value, key) =>
            nameOfPremisesBatchUpdateOption(
              key as keyof ICCOptionsPremisesBatchUpdate["CCFormOptions"]
            )
        );
        onChanges(onChange, dataUpdateObjectWithNameOf);
      },
    };

    const location = useLocation();
    const locationState = location.state as PremisesBatchUpdateLocationState;
    const gridSelectedIds = locationState?.gridSelectedIds;
    const isSelectedAll = locationState?.isSelectedAll;

    useEffect(() => {
      batchUpdate.change({
        SelectedRows: isSelectedAll ? [] : gridSelectedIds,
        IsSelectedAll: isSelectedAll,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectedAll, gridSelectedIds]);

    const isDisabled = useMemo(() => {
      return (
        !gridSelectedIds || (gridSelectedIds && gridSelectedIds.length < 1)
      );
    }, [gridSelectedIds]);

    //#region HANDLE ONCHANGE ========/
    const handleChangeArea = (keyword: CoreKeyword) => {
      batchUpdate.changeOption({
        AreaValue: keyword,
      });
      batchUpdate.change({
        Keyword_ID: keyword?.Keyword_ID ?? null,
      });
    };

    const handleChangeFspTemplate = (keyword: CoreKeyword) => {
      batchUpdate.changeOption({
        FSPTemplateValue: keyword,
      });
      batchUpdate.change({
        FSPTemplate_KWD: keyword?.Keyword_ID ?? null,
      });
    };

    const handleChangeClosureType = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      batchUpdate.changeOption({
        ClosePremises_ClosureTypeValue: value,
      });
      batchUpdate.change({
        ClosureType_KWD: value?.Keyword_ID ?? null,
      });
    };

    const handleChangeActionOfficer = (data: Svc_ContactDetails) => {
      batchUpdate.change({
        Officer_ID: data?.Id ?? null,
      });
      batchUpdate.changeOption({
        OfficerData: data,
      });
    };

    const handleChangePremisesType = (premisesType: Svc_HMPremisesType) => {
      batchUpdate.change({
        PremisesType_ID: premisesType?.ID ?? null,
      });
      batchUpdate.changeOption({
        PremisesTypeData: premisesType,
      });
    };
    //#endregion HANDLE ONCHANGE =====/

    //#region SLIDER ========/
    const closureTypeLovSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: () => {
            return getKeywords(
              KEYWORD_TYPE.HealthManager_ClosePremises_Reasons,
              PRODUCT_TYPE_NUMBER.HealthManager
            );
          },
          handleSuccess: async ({ dataFromApi }) => {
            const response: APIResponse<CoreKeyword[] | undefined> =
              dataFromApi;
            batchUpdate.changeOption({
              ClosePremises_ClosureTypeData: response.data ?? [],
            });
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Load closure type failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });
    //#endregion SLIDER =====/

    useEffect(() => {
      if (batchUpdate.value("Close_Premises")) closureTypeLovSlider.fetchApi();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchUpdate.value("Close_Premises")]);

    return (
      <FormElement>
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update area" />
              <Field
                name={nameOfPremisesBatchUpdate("Change_Area")}
                component={CCSwitch}
                checked={batchUpdate.value("Change_Area")}
                disabled={isDisabled}
              />
            </div>
            {batchUpdate.value("Change_Area") ? (
              <div className="cc-field">
                <CCLabel title="Health area" isMandatory />
                <Field
                  name={nameOfPremisesBatchUpdateOption("AreaValue")}
                  nameDisplay="Name"
                  placeholder="Select area"
                  component={InputPickerSearch}
                  valueMapGrid={batchUpdate.value("Keyword_ID")}
                  onChange={handleChangeArea}
                  validator={requiredValidator}
                  options={renderOptionInputPickerKeyword(
                    PRODUCT_TYPE_NUMBER.HealthManager,
                    KEYWORD_TYPE.HealthManager_HealthArea,
                    "Health Area"
                  )}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update FSP template" />
              <Field
                name={nameOfPremisesBatchUpdate("Change_FSP_Template")}
                component={CCSwitch}
                checked={batchUpdate.value("Change_FSP_Template")}
                disabled={isDisabled}
              />
            </div>
            {batchUpdate.value("Change_FSP_Template") ? (
              <div className="cc-field">
                <CCLabel title="FSP templates" isMandatory />
                <Field
                  name={nameOfPremisesBatchUpdateOption("FSPTemplateValue")}
                  nameDisplay="Name"
                  placeholder="Select FSP template"
                  component={InputPickerSearch}
                  valueMapGrid={batchUpdate.value("FSPTemplate_KWD")}
                  onChange={handleChangeFspTemplate}
                  validator={requiredValidator}
                  options={renderOptionInputPickerKeyword(
                    PRODUCT_TYPE_NUMBER.Core,
                    KEYWORD_TYPE.HealthManager_FoodSafetyProgram_Templates,
                    "FSP templates"
                  )}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update officer" />
              <Field
                name={nameOfPremisesBatchUpdate("Change_Officer")}
                component={CCSwitch}
                checked={batchUpdate.value("Change_Officer")}
                disabled={isDisabled}
              />
            </div>
            {batchUpdate.value("Change_Officer") ? (
              <div className="cc-field">
                <CCLabel title="Officer" isMandatory />
                <Field
                  name={nameOfPremisesBatchUpdateOption("OfficerData")}
                  nameDisplay="DisplayName"
                  placeholder="Select action officer"
                  component={InputPickerSearch}
                  valueMapGrid={batchUpdate.value("Officer_ID")}
                  onChange={handleChangeActionOfficer}
                  validator={requiredValidator}
                  options={renderOptionActionOfficer(
                    `${ODATA_GET_CONTACTS}(classificationFilters='2',hideCouncillors=false)`
                  )}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update premises type" />
              <Field
                name={nameOfPremisesBatchUpdate("Change_Premises_Type")}
                component={CCSwitch}
                checked={batchUpdate.value("Change_Premises_Type")}
                disabled={isDisabled}
              />
            </div>
            {batchUpdate.value("Change_Premises_Type") ? (
              <div className="cc-field">
                <CCLabel title="Premises types" isMandatory />
                <Field
                  name={nameOfPremisesBatchUpdateOption("PremisesTypeData")}
                  nameDisplay="Name"
                  placeholder="Select premises type"
                  component={InputPickerSearch}
                  valueMapGrid={batchUpdate.value("PremisesType_ID")}
                  onChange={handleChangePremisesType}
                  validator={requiredValidator}
                  options={optionPremisesTypes}
                />
              </div>
            ) : null}
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Close premises" />
              <Field
                name={nameOfPremisesBatchUpdate("Close_Premises")}
                component={CCSwitch}
                checked={batchUpdate.value("Close_Premises")}
                disabled={isDisabled}
              />
            </div>
            {batchUpdate.value("Close_Premises") ? (
              <>
                <div className="cc-field">
                  <CCLabel title="Closure Type" isMandatory />
                  <Field
                    name={nameOfPremisesBatchUpdateOption(
                      "ClosePremises_ClosureTypeValue"
                    )}
                    component={CCSearchComboBox}
                    validator={requiredValidator}
                    data={batchUpdate.option("ClosePremises_ClosureTypeData")}
                    textField={nameOfCoreKeyword("Name")}
                    dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                    onChange={handleChangeClosureType}
                    disabled={isDisabled}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Date closed" />
                  <Field
                    name={nameOfPremisesBatchUpdate("Date_Closed")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    max={new Date()}
                    validator={checkValidDate}
                  />
                </div>
              </>
            ) : null}
          </div>
          {batchUpdate.value("Close_Premises") ? (
            <div className="cc-form-cols-3">
              <div className="cc-field" />
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Reason" />
                <Field
                  name={nameOfPremisesBatchUpdate("ClosureReason")}
                  component={CCTextArea}
                  disabled={isDisabled}
                  placeholder="Comment reason"
                  rows={3}
                />
              </div>
            </div>
          ) : null}
        </div>
      </FormElement>
    );
  }
);
