import { RECALL_NEW_ROUTE } from "@app/core/recall/[id]/constant";
import { IParentRecallSection } from "@app/core/recall/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddRecallButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  bubbleUps?: BubbleUpIdentifier[];
  isDisabled?: boolean;
  title?: string;
}

export const AddRecallWithParentButton = ({
  id,
  recordType,
  productType,
  bubbleUps,
  isDisabled = false,
  title = "Recall",
}: IAddRecallButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title={title}
      onClick={() => {
        history.push(RECALL_NEW_ROUTE, {
          parent: {
            id,
            recordType,
            productType,
            bubbleUps: cloneDeep(bubbleUps),
          } as IParentRecallSection,
        });
      }}
      disabled={isDisabled}
    />
  );
};
