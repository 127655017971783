import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const securitySettingsRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: "Security_SecuritySettings",
    component: require("./forms/security-settings").default,
  },
  {
    name: "Security_WebServiceSecuritySettings",
    component: require("./forms/web-service-security-settings").default,
  },
  {
    name: "Security_SSLSettings",
    component: require("./forms/ssl-settings").default,
  },
];
