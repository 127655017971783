import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Create_Supplementary_LOVs_Response,
  DTO_Workflow_New_Supplementary,
} from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getWorkflowNewSupplementary = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_New_Supplementary>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/newsupplementary/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewSupplementary = async (
  requestObjSpatial: any = {}
): Promise<APIResponse<DTO_Workflow_New_Supplementary>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/newsupplementary/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataSupplementary =
  | [
      APIResponse<DTO_Create_Supplementary_LOVs_Response>,
      APIResponse<DTO_Workflow_New_Supplementary>
    ]
  | APIResponse<
      DTO_Create_Supplementary_LOVs_Response | DTO_Workflow_New_Supplementary
    >;

export const getInitialDataNewSupplementary = async (
  workflowDraftId?: number
): Promise<IGetInitialDataSupplementary> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Create_Supplementary_LOVs_Response>(
        `/api/property/internal/Supplementary/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowNewSupplementary(workflowDraftId)
        : getNewWorkflowNewSupplementary(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewSupplementary = async (
  mode: WorkflowProcessMode,
  newSupplementary: DTO_Workflow_New_Supplementary | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_New_Supplementary>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/newsupplementary/process/${mode}`,
      newSupplementary
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
