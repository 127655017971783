import { getFirePreventionSummaryById } from "@app/products/property/fire-prevention/hazld-case/[id]/api";
import { IFirePreventionSummary } from "@app/products/property/fire-prevention/hazld-case/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class FirePreventionStore {
  private _firePrevention?: IFirePreventionSummary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get firePrevention() {
    return this._firePrevention;
  }
  setFirePrevention = (firePrevention?: IFirePreventionSummary) => {
    runInAction(() => {
      this._firePrevention = firePrevention;
    });
  };
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get firePreventionId() {
    return this.firePrevention?.CaseId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._firePrevention = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
  loadFirePrevention = async (firePreventionId: string) => {
    this.setIsLoading(true);
    const response = await getFirePreventionSummaryById(firePreventionId);
    let errorResponse = undefined;
    let newFirePrevention = undefined;
    if (isSuccessResponse(response)) {
      newFirePrevention = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setFirePrevention(newFirePrevention);
    this.setIsLoading(false);
  };
}

const firePreventionStoreContext = createContext(new FirePreventionStore());
export const useFirePreventionStore = () => {
  return useContext(firePreventionStoreContext);
};
