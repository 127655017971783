import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_Journal_Transactions, DTO_Journal_TotalAmount } from "./model";

export const getJournalItemDetail = async (
  id: number,
  transactionId: number
): Promise<APIResponse<DTO_Journal_Transactions | undefined>> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Journal_Transactions>(
        `api/property/internal/journal/${id}/transactions/${transactionId}/items`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getJournalItems = async (
  id: string
): Promise<APIResponse<DTO_Journal_Transactions | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/journal/${id}/transactions?year=0&view=0&loadAllocation=false&showCancelledTransactions=false`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getJournalTotalAmount = async (
  id: string
): Promise<APIResponse<DTO_Journal_TotalAmount | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/journal/${id}/totalamount`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
