import { ICCRoute } from "@common/constants/ICCRoute";

export const crmsApplicationsRoute: ICCRoute = {
  path: "crms-applications",
  name: "CRMS Applications",
  children: [
    {
      path: "draft",
      name: "Draft",
      component: require("./draft/_index").default,
    },
  ],
};
