import { PropertyChargeAndNoticeRunsForm } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/general/_index";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewPropertyChargeAndNoticeRuns = observer(() => {
  const { isLoading } = useChargeRunsStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Charge Run" />
      <FormTitle title="New Charge Run" />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <CCNavButton title={"Add item"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<PropertyChargeAndNoticeRunsForm />} />
          </div>
        </div>
      </div>
    </>
  );
});
