import { IAlert } from "@app/core/contacts/components/accordions/alerts/model";
import {
  getAlert,
  getAlertLovs,
} from "@app/core/contacts/components/dialogs/alert/api";
import { IAlertLovs } from "@app/core/contacts/components/dialogs/alert/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AlertDialogStore {
  private _responseLoadError?: APIResponseError = undefined;
  private _isLoadingDialog: boolean = false;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _alertModify?: IAlert = undefined;
  private _alertLovs?: IAlertLovs[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingDialog() {
    return toJS(this._isLoadingDialog);
  }
  setIsLoadingDialog = (isLoadingDialog: boolean) => {
    runInAction(() => {
      this._isLoadingDialog = isLoadingDialog;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get alertModify() {
    return toJS(this._alertModify);
  }
  setAlertModify = (alertModify?: IAlert) => {
    runInAction(() => {
      this._alertModify = alertModify;
    });
  };

  get alertLovs() {
    return toJS(this._alertLovs);
  }
  setAlertLovs = (alertLovs?: IAlertLovs[]) => {
    runInAction(() => {
      this._alertLovs = alertLovs;
    });
  };

  resetStore = () => {
    this._responseLoadError = undefined;
    this._isLoadingDialog = false;
    this._notification = undefined;
    this._alertModify = undefined;
    this._alertLovs = undefined;
  };

  loadAlert = async (alertID: number) => {
    this.setIsLoadingDialog(true);
    let errorResponse = undefined;
    if (alertID === 0) {
      // New Alert
      const responseLovs = await getAlertLovs();
      if (isSuccessResponse(responseLovs) && responseLovs.data) {
        this.setAlertLovs(responseLovs.data);
      } else {
        errorResponse = {
          status: responseLovs.status,
          error: responseLovs.error,
        };
      }
    } else {
      // Modify Alert
      const [response, responseLovs] = await Promise.all([
        getAlert(alertID),
        getAlertLovs(),
      ]);

      if (
        isSuccessResponse(response) &&
        response.data &&
        isSuccessResponse(responseLovs) &&
        responseLovs.data
      ) {
        this.setAlertModify(response.data);
        this.setAlertLovs(responseLovs.data);
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
    }
    this.setIsLoadingDialog(false);
    this.setResponseLoadError(errorResponse);
  };
}

export const alertDialogStore = new AlertDialogStore();
const alertsDialogStoreContext = createContext(alertDialogStore);
export const useAlertsDialogStore = () => {
  return useContext(alertsDialogStoreContext);
};
