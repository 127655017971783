import { isNil } from "lodash";

export const generateGISViewUrl = (
  id: number | string,
  currentUrl?: string
) => {
  if (isNil(currentUrl)) return undefined;
  const gisViewUrl = currentUrl.replace(/\{0\}/g, id.toString());
  return gisViewUrl;
};
