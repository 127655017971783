import { VO_AuditHistory } from "@app/products/property/audit-history/list/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";

export const loadAuditHistoryDetailTab = async (
  auditId: number
): Promise<APIResponse<IODataResponse<VO_AuditHistory> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      IODataResponse<VO_AuditHistory>
    >(`/odata/property/internal/audithistoryregister/${auditId}`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
