import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";
import { DTO_Charge } from "./model";

const nameOf = nameOfFactory<DTO_Charge>();

export const colCharges: IColumnFields[] = [
  {
    field: nameOf("Date"),
    title: "Change",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("RebatesGiven"),
    title: "Rebates Given",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("NettAmount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("RatingPeriod"),
    title: "Rating Period",
  },
  {
    field: nameOf("InstallmentPlanType"),
    title: "Installment Plan",
  },
];
