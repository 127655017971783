import { V_Supp_Assess_Rating_Period } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/rating-periods/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<V_Supp_Assess_Rating_Period>();
//All titles will be replaced by column name data from view configuration API
export const colRatingPeriod: IColumnFields[] = [
  {
    field: nameOf("RP_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("SARP_Calculate_Adjustments"),
    title: "Calculate Adjustments",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Supp_Adjustment"),
    title: "Supplementary Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("New_Val_Date_of_Valuation"),
    title: "New Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Val_Issue_Date"),
    title: "New Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Val_Effective_Date"),
    title: "New Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Valuation_1"),
    title: "New Unimproved Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("New_Valuation_2"),
    title: "New Average Unimproved Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("New_Valuation_3"),
    title: "New Part Land Tax UV",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("New_Valuation_4"),
    title: "New Rateable UV",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Old_Val_Date_of_Valuation"),
    title: "Old Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Val_Issue_Date"),
    title: "Old Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Val_Effective_Date"),
    title: "Old Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Valuation_1"),
    title: "Previous Unimproved Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Old_Valuation_2"),
    title: "Previous Average Unimproved Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Old_Valuation_3"),
    title: "Previous Part Land Tax UV",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Old_Valuation_4"),
    title: "Previous Rateable UV",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Rating_Period_Id"),
    title: "Rating Period ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
