import { AnimalType } from "@app/products/animals/model";
import { AnimalTypeFormElement } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useRef } from "react";

export const AnimalTypeForm = observer(() => {
  const isNew = useIsNew();
  const { pushNotification } = useCCAppNotificationStore();
  const { animalType, submitAction, setOnSubmit } = useAnimalTypeStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid && actionSubmit !== ActionSubmitActions.ChangeCategory) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    submitAction(
      {
        ...values,
      } as AnimalType,
      isNew,
      actionSubmit
    );
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, animalType]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={animalType}
        key={JSON.stringify(animalType)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <AnimalTypeFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
