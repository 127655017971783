import { ChangeApplicationNumberDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/change-application-number/_index";
import { putPSARChangeApplicationNumber } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-application-number/api";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { ChangeApplicationNumber } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-application-number/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PSARChangeApplicationNumberButton = observer(() => {
  const { pprId, ppr, setPPRWithLoading } = usePSARStore();
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showApplicationNumberDialog, setShowApplicationNumberDialog] =
    useState(false);
  const [initialValues, setInitialValues] = useState<ChangeApplicationNumber>();

  const handleOnClick = () => {
    setInitialValues({
      ApplicationNumber:
        ppr?.ApplicationNumber !== 0 ? ppr?.ApplicationNumber : undefined,
      ApplicationYear:
        ppr?.ApplicationYear !== 0
          ? ppr?.ApplicationYear
          : new Date().getFullYear(),
      AmendmentReference:
        !isNil(ppr?.AmendmentReference) && ppr?.AmendmentReference !== 0
          ? ppr?.AmendmentReference
          : undefined,
      PlansToComplyReference:
        !isNil(ppr?.PlansToComplyReference) && ppr?.PlansToComplyReference !== 0
          ? ppr?.PlansToComplyReference
          : undefined,
      RevisionReference: ppr?.RevisionReference,
      FormattedNumber: ppr?.ApplicationNumberDisplay,
    });
    setShowApplicationNumberDialog(true);
  };

  const handleOnSubmit = async (data: ChangeApplicationNumber) => {
    if (!pprId) return;
    setIsSubmitting(true);
    const response = await putPSARChangeApplicationNumber(pprId, data);
    setIsSubmitting(false);
    setShowApplicationNumberDialog(false);
    if (isSuccessIdentityPacket(response)) {
      clearNotifications();
      setPPRWithLoading(response?.data?.Application);
      pushNotification({
        type: "success",
        title: "Application number changed successfully",
      });
    } else {
      pushNotification({
        title: "Change application number failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
        autoClose: false,
      });
    }
  };

  return (
    <>
      <CCNavButton title="Change application number" onClick={handleOnClick} />
      {showApplicationNumberDialog && (
        <ChangeApplicationNumberDialog
          isSubmitting={isSubmitting}
          initialValues={initialValues}
          onClose={() => {
            setShowApplicationNumberDialog(false);
          }}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  );
});
