import { MAILMERGEDATASET } from "@app/core/communication/dialogs/constant";
import { Communication_AdditionalRecipientType } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";

export const defaultToStep = {
  UseSMS: false,
  UseEmail: true,
  UsePreferred: false,
  EmailCounter: 0,
  SmsCounter: 0,
  WarningCounter: 0,
  ErrorCounter: 0,
};
export const defaultMessageTemplateStep = {
  IncludeMe: false,
  SendCopyToMeBCC: false,
  Preview: true,
  EmailContent: "Email Content.",
  Key: 0,
  SubjectContent: "",
  SMSContent: "Message content will be displayed within this chat-bubble.",
};
export const defaultAttachmentStep = {
  AttachmentCount: 0,
};
export const defaultTemplateStep = {
  MailMergeDataset: MAILMERGEDATASET.HM_PREMISES,
  RecordType: RECORDTYPE.HealthManager_Premises,
};

export const recipientTypesCommunicationDialog = (
  recordType: RECORDTYPE = RECORDTYPE.TP_PPRApplication
) => {
  // CRMS
  if (recordType === RECORDTYPE.CUSTOMERSERVICE_Event) {
    const recipientTypes = [
      Communication_AdditionalRecipientType.ActionOfficer,
      Communication_AdditionalRecipientType.Requestor,
      Communication_AdditionalRecipientType.Councillor,
      Communication_AdditionalRecipientType.Coordinator,
      Communication_AdditionalRecipientType.OnBehalfOf,
    ];
    return recipientTypes.join(",");
  }

  // PPR
  if (
    recordType === RECORDTYPE.CORE_FurtherInformationRequest || // FI request
    recordType === RECORDTYPE.TP_PPRApplication || // PPR application
    recordType === RECORDTYPE.CORE_Register || // Planning Enquiry
    recordType === RECORDTYPE.TP_PSA_Application // PSA application
  ) {
    return "1,2";
  } else if ((recordType = RECORDTYPE.CORE_Appeal)) {
    // Appeals
    return "1,2,3";
  }
  return null;
};
