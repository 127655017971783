import { IKeyValuePacket } from "@common/models/keyValuePacket";

export class CSLResponse {
  // _SaveTriggers:                       any[];
  Sys_TimeStamp: string = "";
  Licence_ID: number = 0;
  LicenceCategory_ID: number = 0;
  LicenceCategory?: LicenceCategory;
  Status_ENUM: string = "";
  LicencePeriod_ENUM: string = "";
  LicenceHistory: string = "";
  LicenceNumber: string = "";
  FileNumber: string = "";
  ReferenceNumber: string = "";
  DebtorNumber: string = "";
  DrugsPossessed: string = "";
  StorageArrangements: string = "";
  MaxQuantity: string = "";
  PurposeOf: string = "";
  Dosage: string = "";
  IntendedToBeSoldTo: string = "";
  FumigationCourseGrading: string = "";
  LocalityUsed: string = "";
  LocalityStored: string = "";
  OfficersLocations: string = "";
  DamageCaused: string = "";
  AmountRequired: string = "";
  AccessPrecautions: string = "";
  PollutionPrecautions: string = "";
  AgSafeAccredited: string = "";
  Misc1: string = "";
  Misc2: string = "";
  Misc3: string = "";
  ExemptedPersons: string = "";
  PersonsApprovedToOrder: string = "";
  PersonsApprovedToAccess: string = "";
  Patient: string = "";
  EmployerFreeText: string = "";
  AdditionalLocations: string = "";
  Reg25Poisons: string = "";
  LicenceHistoryCount: number = 0;
  TimesRenewed: number = 0;
  Flag_SignedDeclaration: boolean = false;
  Flag_WrittenConsent: boolean = false;
  Flag_EthicsCommitteeApproval: boolean = false;
  Flag_CatBApproval: boolean = false;
  Flag_CatANotification: boolean = false;
  Flag_Research: boolean = false;
  Flag_RenewalFeeCreated: boolean = false;
  Flag_RenewalNoticeSent: boolean = false;
  Flag_RequiresCertificate: boolean = false;
  Flag_FullTechnicianHolder: boolean = false;
  Flag_CertOfCompanyReg: boolean = false;
  Flag_CertOfTradingNameReg: boolean = false;
  Flag_EmployerSignedDec: boolean = false;
  Flag_SupervisorSignedDec: boolean = false;
  Flag_TrainingSkillsDec: boolean = false;
  Flag_PhotographMeetsReq: boolean = false;
  Flag_WitnessMeetsReq: boolean = false;
  Flag_WitnessSignedDec: boolean = false;
  Flag_StatementOfAttainment: boolean = false;
  Flag_OnJobCompetencyAssessment: boolean = false;
  Flag_SpecifyMedicines: boolean = false;
  Flag_TheftLossPolicyRequired: boolean = false;
  Flag_TransportCodeRequired: boolean = false;
  Flag_VetDrugUseReportRequired: boolean = false;
  Flag_PaymentJournalRequired: boolean = false;
  Flag_CountryHealthNetwork: boolean = false;
  Flag_FumigationCourse: boolean = false;
  Flag_BiosecuritySAApproval: boolean = false;
  Flag_WholesaleReport: boolean = false;
  Flag_KnownEmployer: boolean = false;
  Flag_DoNotRenew: boolean = false;
  Applicant?: Applicant;
  Applicant_RID?: number = 0;
  Business?: Business;
  Business_RID: number = 0;
  Supplier?: Supplier;
  Supplier_RID: number = 0;
  AssessingOfficer_ID: number = 0;
  AssessingOfficer_Name: string = "";
  ApprovingOfficer_ID: number = 0;
  ApprovingOfficer_Name: string = "";
  DateLodged?: Date;
  DateExpires?: Date;
  DateIssued?: Date;
  DateEthicsCommitteeApproval?: Date;
  Date_VetDrugUseReportRequired?: Date;
  ConditionTreated_Display: string = "";
  RegDrugPoison: any[] = [];
  RegDrugPoison_Namelist: any[] = [];
  TypesofPoison: string = "";
  PoisonSchedule: any[] = [];
  PoisonSchedule_Namelist: any[] = [];
  PestType: any[] = [];
  PestType_Namelist: any[] = [];
  BusinessType: any[] = [];
  BusinessType_Namelist: any[] = [];
  LocalityUsedAddress?: Address;
  LocalityUsedAddress_ID: number = 0;
  LocalityStoredAddress?: Address;
  LocalityStoredAddress_ID: number = 0;
  Activity_IDs: any[] = [];
  Activity_CSV: string = "";
  EndorsementSpecialistSpeciality_IDs: any[] = [];
  EndorsementSpecialistSpeciality_CSV: string = "";
  ApplicationDetails?: ApplicationDetails;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate?: Date;
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate?: Date;
  Sys_ModifiedDate?: Date;
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
  SupervisedBy: string = "";
  StorageLocation: string = "";
  ResearchProgramName: string = "";
  EthicsCommitteeApproval: string = "";
}

export interface Applicant {
  _BubbleUps: BubbleUp[];
  _ExtensionType: any[];
  _KeywordTags: any[];
  _SaveTriggers: any[];
  Sys_TimeStamp: string;
  RID: number;
  ContactRelationshipType_ENUM: string;
  Contact: ApplicantContact;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface ApplicantContact {
  Sys_TimeStamp: string;
  _PreferredMethod_Formatted: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _ExtensionType: string[];
  _SaveTriggers: any[];
  Salutation: string;
  GenderPronoun: string;
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: string;
  PreferredMethod_ENUM: string;
  NARNumber: string;
  Flag_SendSMSAlerts: boolean;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  ContactRoles_Count: number;
  Organisation: string;
  PostalAddress: Address;
  AlternateAddress: Address;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  SynchDate: Date;
  Extension: PurpleExtension;
  ContactAlert: string;
  Flag_Duplicate: boolean;
  IsContactCopied: boolean;
  ABNValid: boolean;
  ACNValid: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface AddressBook {
  Sys_TimeStamp: string;
  _ExtensionType: any[];
  Sys_EnterpriseKey: string;
  Sys_TransactionDate: Date;
  AddressBook_ID: number;
  AddressBook_Name: string;
  AddressBookSection_ENUM: string;
  ProductType_ENUM: string;
  IsSystemRecord: boolean;
}

export interface Address {
  _BubbleUps: BubbleUp[];
  Sys_TimeStamp: string;
  Address_ID: number;
  AddressClassification_ENUM: string;
  ContactAddressType_Name: string;
  PropertyName: string;
  UnitNo: string;
  StreetNo: string;
  StreetName: string;
  Suburb: string;
  State: string;
  Postcode: string;
  LGA: string;
  AreaCode: string;
  Region: string;
  Location_Description: string;
  Map_Reference: string;
  AssessmentNo: string;
  NearestCrossStreet: string;
  PoBoxNo: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Country: string;
  PropertyType: string;
  PropertySubType: string;
  RIN: string;
  ComplexAddress: boolean;
  Flag_StreetName_HasParts: boolean;
  StreetNamePart_Name: string;
  StreetNamePart_Type: string;
  PropertyAssessment_ID?: number;
  PropertyAssessment: PropertyAssessment;
  PostalDelivery_Identifier: string;
  PostalDelivery_Barcode: string;
  VMAS_GeoCode: string;
  PropertyAlert: string;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
  AddressBoxType_KWD?: number;
  AddressBoxType_Name?: string;
  VMAS_Verified?: boolean;
}

export interface PropertyAssessment {
  PropertyAssessment_ID: number;
  Flag_PrimaryAddress: boolean;
  Flag_RuralHouseNumber: boolean;
  Flag_OutsideProperty: boolean;
  CrownAllotment: string;
  LotNo: string;
  County: string;
  CertOfTitle: string;
  Valuation_Number: string;
  Owners_Name: string;
  Owners_Attention_Of: string;
  Owners_Care_Of: string;
  Owners_Address: string;
  Owners_Locality: string;
  Owners_State: string;
  Owners_Postcode: string;
  RatePayers_Name: string;
  RatePayers_Attention_Of: string;
  RatePayers_Care_Of: string;
  RatePayers_Address: string;
  RatePayers_Locality: string;
  RatePayers_State: string;
  RatePayers_Postcode: string;
  RatePayers_Phone: string;
  RatePayers_Email: string;
  RatePayers_Mobile: string;
  RatePayers_Fax: string;
}

export interface BubbleUp {
  PKID: number;
  SourceIdentifier: SourceIdentifier;
  BubbleUpType_ENUM: BubbleUpTypeENUM;
  Sys_CreatedDate: Date;
}

export enum BubbleUpTypeENUM {
  Ancestor = "Ancestor",
  Parent = "Parent",
}

export interface SourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: RecordSourceTypeENUM;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
}

export enum RecordSourceTypeENUM {
  CSMLicence = "CSM_Licence",
}

export interface PurpleExtension {
  VerificationQuestion: string;
  VerificationResponse: string;
  JobTitle: string;
  Organisation: string;
  LicenceNo: string;
  SecondLanguage: string;
}

export interface ApplicationDetails {
  DateLodged: Date;
  Flag_LicenceCategoryPlansReceived: boolean;
}

export interface Business {
  _BubbleUps: BubbleUp[];
  _ExtensionType: any[];
  _KeywordTags: any[];
  _SaveTriggers: any[];
  Sys_TimeStamp: string;
  RID: number;
  ContactRelationshipType_ENUM: string;
  Contact: BusinessContact;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface BusinessContact {
  Sys_TimeStamp: string;
  _PreferredMethod_Formatted: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _ExtensionType: string[];
  _SaveTriggers: any[];
  Salutation: string;
  GenderPronoun: string;
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: string;
  PreferredMethod_ENUM: string;
  Flag_SendSMSAlerts: boolean;
  Email: string;
  Website: string;
  ABN: string;
  ContactRoles_Count: number;
  PreferredAddress: Address;
  Organisation: string;
  PostalAddress: Address;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  SynchDate: Date;
  Extension: FluffyExtension;
  ContactAlert: string;
  Flag_Duplicate: boolean;
  IsContactCopied: boolean;
  ABNValid: boolean;
  ACNValid: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface FluffyExtension {
  JobTitle: string;
  Organisation: string;
  LicenceNo: string;
  SecondLanguage: string;
}

export interface LicenceCategory {
  Sys_TimeStamp: string;
  LicenceCategory_ID: number;
  Name: string;
  Notes: string;
  Code: string;
  SectionOfAct: string;
  AuthorisedActivities: string;
  SectionOfAct2: string;
  AuthorisedActivities2: string;
  SectionOfAct3: string;
  AuthorisedActivities3: string;
  RenewalFeeAmount_1Year: number;
  RenewalFeeAmount_3Year: number;
  Flag_PestControl: boolean;
  Flag_Poisons: boolean;
  Flag_Renewable: boolean;
  RenewableAmount: number;
  Type_ENUM: string;
  Flag_PaymentJournalRequired: boolean;
  Flag_CountryHealthNetwork: boolean;
  Flag_WholesaleReport: boolean;
  Flag_EthicsCommitteeApproval: boolean;
  AgSafeAccredited: string;
  EmployerFreeText: string;
  DrugsPossessed: string;
  PurposeOf: string;
  StorageArrangements: string;
  MaxQuantity: string;
  Patient: string;
  ConditionTreated_Display: string;
  PersonsApprovedToOrder: string;
  PersonsApprovedToAccess: string;
  AmountRequired: string;
  IntendedToBeSoldTo: string;
  ExemptedPersons: string;
  Misc1: string;
  Misc2: string;
  Misc3: string;
  OfficersLocations: string;
  AdditionalLocations: string;
  Reg25Poisons: string;
  RenewalMethod_AnniversaryOptions: any[];
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface Supplier {
  _BubbleUps: BubbleUp[];
  _ExtensionType: any[];
  _KeywordTags: any[];
  _SaveTriggers: any[];
  Sys_TimeStamp: string;
  RID: number;
  ContactRelationshipType_ENUM: string;
  Contact: SupplierContact;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface SupplierContact {
  Sys_TimeStamp: string;
  _PreferredMethod_Formatted: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _ExtensionType: string[];
  _SaveTriggers: any[];
  TradingName: string;
  ContactPerson: string;
  Contact_ID: number;
  DisplayName: string;
  ContactClassification_ENUM: string;
  PreferredMethod_ENUM: string;
  Flag_SendSMSAlerts: boolean;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  ContactRoles_Count: number;
  Organisation: string;
  PostalAddress: Address;
  AlternateAddress: Address;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  SynchDate: Date;
  Extension: TentacledExtension;
  ContactAlert: string;
  Flag_Duplicate: boolean;
  IsContactCopied: boolean;
  ABNValid: boolean;
  ACNValid: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}

export interface TentacledExtension {
  Organisation: string;
  LicenceNo: string;
}

export class CSL {
  // LOAD BY DEFAULT
  id?: string | number = "";
  ID?: string | number = "";
  ApplicationNumber?: string;
  PermitNumber?: string;
  PermitType?: string;
  Date_PermitIssued?: string;
  RefNo?: string;
  AssessmentNo?: string;
  Flag_PrivateCertifier?: string;
  IsPrivateCertifier?: string;
  Applicant?: string;
  Builder?: string;
  Category?: string;
  BCA?: string;
  UseOfBuilding?: string;
  ReasonForPermit?: string;
  SiteAddress?: string;
  Locality?: string;
  Owner?: string;
  Status?: string;
  ApplicantRequires?: string;
  Date_Lodged?: string;
  Date_AllInspComplete?: string;
  Date_DueToCommence?: string;
  Date_Commenced?: string;
  Date_DueToComplete?: string;
  Date_Completed?: string;
  Date_CertificateIssued?: string;
  FileNo?: string;
  ValuationNo?: string;
  LegalDescription?: string;
  BuildingOfficer?: string;
  Surveyor?: string;
  SurveyorNumber?: string;
  StatDays?: string;
  Date_Issued?: string;
  Date_ReminderSent?: string;
  PayingFee?: string;
  CarryingOutWork?: string;
  WorkProposed?: string;
  Address?: string;
  BuildingAppNo?: string;
  BuildingPermitNo?: string;
  Date_BuildingIssued?: string;
  Date_Commencing?: string;
  BondAmount?: string;
  BondReceived?: string;
  BondReturned?: string;
  Date_FirstInspection?: string;
  Date_LastInspection?: string;
  TargetDate?: string;
  CILFee?: string;
  Flag_CILPaid?: string;
  LettersDisplay?: string;
  DateInspected?: string;
  InspectionType?: string;
  Officer?: string;
  Compliance?: string;
  Reason?: string;
  FacilityRegion?: string;
  Description?: string;
  DateTarget?: string;
  Source?: string;
  Location?: string;
  RequestedBy?: string;
  ContactDetails?: string;
  DueStatus?: string;
  DateRecorded?: string;
  RecordedBy?: string;
  OrgStructure?: string;
  ActionOfficer?: string;
  Coordinator?: string;
  DateWork?: string;
  FileNumber?: string;
  EnquiryRefNo?: string;
  Event_ReferenceNoAdditional?: string;
  OnBehalfOf?: string;
  Flag_ITSupport?: string;
  Flag_Safety?: string;
  Flag_Risk?: string;
  Flag_VIP?: string;
  DateServed?: string;
  ApplicantName?: string;
  Type?: string;
  Risk?: string;
  DateDue?: string;
  DateRevoked?: string;
  Register_Number?: string;
  Contact?: string;
  ReferenceNumber?: string;
  Date_Complete?: string;
  Date_Construction?: string;
  BarrierStandard?: string;
  BarrierType?: string;
  Date_CertificateOfCompliance?: string;
  Pool_RegisteredDate?: string;
  Date_Lodgement_CertificateOfBarrierNC?: string;
  Date_WorkIssued?: string;
  Work_CarriedOut?: string;
  AmountOS?: string;
  Flag_RenewalNoticeSent?: string;
  Outcome?: string;
  Regulation?: string;
  Date_Due?: string;
  Date_NextInspection?: string;
  Date_Start?: string;
  Date_Occurred?: string;
  ContactName?: string;
  CreatedDate?: string;
  FeeType?: string;
  AmountIssued?: string;
  AmountOutstanding?: string;
  CostCode?: string;
  AmountPaid?: string;
  ReceiptNumber?: string;
  ReceiptDate?: string;
  ReceiptedBy?: string;
  Method?: string;
  InvoiceNumber?: string;
  DebtorNumber?: string;
  SecurePay_Reference?: string;
  Contact2?: string;
  LevelOfEnforcement?: string;
  Number?: string;
  DateOffence?: string;
  RegisterType?: string;
  VicSmart?: string;
  ePlanningCategory?: string;
  ePlanningLodgedBy?: string;
  Comments?: string;
  ApplicationCategory_Name?: string;
  ApplicationCategory_Description?: string;
  Code?: string;
  SortIndex?: string;
  IsPublishToWeb?: string;
  PublishName?: string;
  PublishDescription?: string;
  Title?: string;
  ConditionCode?: string;
  Condition?: string;
  Name?: string;
  Multiplier?: string;
  Value?: string;
  MinimumValue?: string;
  TargetDays?: string;
  Category_1?: string;
  Category_2?: string;
  ObservationSummary?: string;
  ComplianceInstruction?: string;
}
export interface ICSLFormData {
  ID?: string;
  LicenceCategory?: string;
  LicenceNumber?: string;
  Applicant?: string;
  Applicant_Address?: string;
  Applicant_PostalAddress?: string;
  Applicant_TradingName?: string;
  Applicant_Phone?: string;
  Applicant_Mobile?: string;
  Applicant_Email?: string;
  Contact?: string;
  Contact_Position?: string;
  Contact_Phone?: string;
  Contact_Mobile?: string;
  Contact_Email?: string;
  LocalityUsed?: string;
  OfficersLocations?: string;
  Schedule4Drugs?: string;
  MaxQuantity?: string;
  Misc1?: string;
  Misc2?: string;
  Misc3?: string;
  TheftLoss: boolean;
  Transport: boolean;
  VetDrugReport: boolean;
  PaymentJournal: boolean;
  CountryHealthNetwork: boolean;
  WholeSaleReport: boolean;
  EthicsCommitteeApproval: boolean;
  AssessingOfficer?: string;
  ApprovingOfficer?: string;
  Lodged?: string;
  Approved?: string;
  Issued?: string;
  SignedDeclaration: boolean;
  Expires: string;
  Status: string;
  DoNotRenew: boolean;
}
export class ICSL {
  // LOAD BY DEFAULT
  id?: string | number = "";
  ID?: string | number = "";
  ApplicationNumber?: string;
  PermitNumber?: string;
  PermitType?: string;
  Date_PermitIssued?: string;
  RefNo?: string;
  AssessmentNo?: string;
  Flag_PrivateCertifier?: string;
  IsPrivateCertifier?: string;
  Applicant?: string;
  Builder?: string;
  Category?: string;
  BCA?: string;
  UseOfBuilding?: string;
  ReasonForPermit?: string;
  SiteAddress?: string;
  Locality?: string;
  Owner?: string;
  Status?: string;
  ApplicantRequires?: string;
  Date_Lodged?: string;
  Date_AllInspComplete?: string;
  Date_DueToCommence?: string;
  Date_Commenced?: string;
  Date_DueToComplete?: string;
  Date_Completed?: string;
  Date_CertificateIssued?: string;
  FileNo?: string;
  ValuationNo?: string;
  LegalDescription?: string;
  BuildingOfficer?: string;
  Surveyor?: string;
  SurveyorNumber?: string;
  StatDays?: string;
  Date_Issued?: string;
  Date_ReminderSent?: string;
  PayingFee?: string;
  CarryingOutWork?: string;
  WorkProposed?: string;
  Address?: string;
  BuildingAppNo?: string;
  BuildingPermitNo?: string;
  Date_BuildingIssued?: string;
  Date_Commencing?: string;
  BondAmount?: string;
  BondReceived?: string;
  BondReturned?: string;
  Date_FirstInspection?: string;
  Date_LastInspection?: string;
  TargetDate?: string;
  CILFee?: string;
  Flag_CILPaid?: string;
  LettersDisplay?: string;
  DateInspected?: string;
  InspectionType?: string;
  Officer?: string;
  Compliance?: string;
  Reason?: string;
  FacilityRegion?: string;
  Description?: string;
  DateTarget?: string;
  Source?: string;
  Location?: string;
  RequestedBy?: string;
  ContactDetails?: string;
  DueStatus?: string;
  DateRecorded?: string;
  RecordedBy?: string;
  OrgStructure?: string;
  ActionOfficer?: string;
  Coordinator?: string;
  DateWork?: string;
  FileNumber?: string;
  EnquiryRefNo?: string;
  Event_ReferenceNoAdditional?: string;
  OnBehalfOf?: string;
  Flag_ITSupport?: string;
  Flag_Safety?: string;
  Flag_Risk?: string;
  Flag_VIP?: string;
  DateServed?: string;
  ApplicantName?: string;
  Type?: string;
  Risk?: string;
  DateDue?: string;
  DateRevoked?: string;
  Register_Number?: string;
  Contact?: string;
  ReferenceNumber?: string;
  Date_Complete?: string;
  Date_Construction?: string;
  BarrierStandard?: string;
  BarrierType?: string;
  Date_CertificateOfCompliance?: string;
  Pool_RegisteredDate?: string;
  Date_Lodgement_CertificateOfBarrierNC?: string;
  Date_WorkIssued?: string;
  Work_CarriedOut?: string;
  AmountOS?: string;
  Flag_RenewalNoticeSent?: string;
  Outcome?: string;
  Regulation?: string;
  Date_Due?: string;
  Date_NextInspection?: string;
  Date_Start?: string;
  Date_Occurred?: string;
  ContactName?: string;
  CreatedDate?: string;
  FeeType?: string;
  AmountIssued?: string;
  AmountOutstanding?: string;
  CostCode?: string;
  AmountPaid?: string;
  ReceiptNumber?: string;
  ReceiptDate?: string;
  ReceiptedBy?: string;
  Method?: string;
  InvoiceNumber?: string;
  DebtorNumber?: string;
  SecurePay_Reference?: string;
  Contact2?: string;
  LevelOfEnforcement?: string;
  Number?: string;
  DateOffence?: string;
  RegisterType?: string;
  VicSmart?: string;
  ePlanningCategory?: string;
  ePlanningLodgedBy?: string;
  Comments?: string;
  ApplicationCategory_Name?: string;
  ApplicationCategory_Description?: string;
  Code?: string;
  SortIndex?: string;
  IsPublishToWeb?: string;
  PublishName?: string;
  PublishDescription?: string;
  Title?: string;
  ConditionCode?: string;
  Condition?: string;
  Name?: string;
  Multiplier?: string;
  Value?: string;
  MinimumValue?: string;
  TargetDays?: string;
  Category_1?: string;
  Category_2?: string;
  ObservationSummary?: string;
  ComplianceInstruction?: string;
}

export class CSLRecordsState {
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  services: number = 0;
  samples: number = 0;
  conditions: number = 0;
  related: number = 0;
  events: number = 0;
}

export class CSLSystemSettingCategoriesForm {
  // LOAD BY DEFAULT
  ID?: string | number;
  Name?: string;
  Classification?: string;
  Code?: string;
  FeeType?: string;
  Poisons?: boolean;
  PestControl?: boolean;
  Renewable?: boolean;
  MaxRenews?: string;

  SectionOfAct?: string;
  SectionOfAct2?: string;
  SectionOfAct3?: string;
  AuthorisedActivities?: string;
  AuthorisedActivities2?: string;
  AuthorisedActivities3?: string;
  Notes?: string;

  TrainingSkills?: string;
  PurposeOf?: string;
  Storage?: string;
  MaxQuantity?: string;
  PersonsApprovedToAccess?: string;
  Misc1?: string;
  Misc2?: string;
  Misc3?: string;

  TheftLoss?: boolean;
  Transport?: boolean;
  VetDrugReport?: boolean;
  PaymentJournal?: boolean;
  CountryHealthNetwork?: boolean;
  WholeSaleReport?: boolean;
  EthicsCommitteeApproval?: boolean;
}

export class CSLSystemAdminConditionsFormData {
  ID?: string | number;
  SortOrder?: number;
  Group?: string;
  Title?: string;
  EnterableFields?: string;
  Condition?: string;
}

export class CSLRegisterRequest extends CSLResponse {}

export class CSLRegisterForm extends CSLResponse {
  licenceCategory: string = "";
  licensee: string = "";
  address: string = "";
  postalAddress: string = "";
  tradingName: string = "";
  phone: string = "";
  mobile: string = "";
  emailOrWeb: string = "";
  OSFees: string = "";
  contact: string = "";
  contactPosition: string = "";
  contactPhone: string = "";
  contactMobile: string = "";
  contactEmail: string = "";

  siteUsed: string = "";
  officersOrLocations: string = "";
  drugsPossessed: string = "";
  maxQuantity: string = "";
  pestsInvolved: string = "";

  misc1: string = "";
  misc2: string = "";
  misc3: string = "";

  // Boolean Flag get from API
  assessingOfficer: string = "";
  approvingOfficer: string = "";
  lodged: string = "";
  approved: string = "";
  signedForm: string = "";
  issued: string = "";
  expires: string = "";
}

export class CSL_LOVs {
  LicenceCategories: IKeyValuePacket[] = [];
  BusinessTypes: [] = [];
  Pests: [] = [];
  PoisonSchedules: [] = [];
  Conditions: [] = [];
  Reg19Drugs: [] = [];
  Reg25Poisons: [] = [];
  Cyanide: [] = [];
  TypeOfPoison: [] = [];
  TypeOfPesticide: [] = [];
  TypeOfPesticideWork: [] = [];
  Activity: [] = [];
  EndorsementSpeciality: [] = [];
}

export enum LicenceType {
  SIDL_IMPREST_DRUG_LICENCE = "2928353",
  SDL_DRUG_LICENCE = "2928350",
  R25_REGULATION = "2928357",
  DEH_REGULATION_SODIUM = "2928359",
  // MOCK
  REGULATION19 = "2928360",
  S7_RETAIL_SELLER = "2928361",
  WHOLESALE_DEALER = "2928362",
  MANUFACTURER = "2928363",
  RESEARCH_INSTRUCTION_OR_ANALYSIS = "2928364",
  S2_MEDICINE_SELLER = "2928365",
  STANDARD_ACT = "2928366",
  PEST_CONTROLLER = "2928367",
  PEST_MANAGEMENT_TECHNICIAN_FULL = "2928368",
  EXEMPTION = "2928369",
  PEST_MANAGEMENT_TECHNICIAN_LIMITED = "2928370",
}
