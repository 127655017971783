import { DTO_Entity } from "@app/products/property/parcels/[id]/components/child-screens/entities/model";
import { isFieldVisible } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCValueField } from "@components/cc-value-field/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";

interface IEntitiesDetailProps {
  dataItem: DTO_Entity;
}

const EntitiesDetail: React.FC<GridDetailRowProps> = ({
  dataItem,
}: IEntitiesDetailProps) => {
  const phoneNumber = dataItem.PhoneNumber;
  return (
    <div className="cc-parcel-entities-detail">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField label="Name" value={dataItem.Name} />
            <CCValueField label="Address" value={dataItem.Address} />
            {isFieldVisible(dataItem.GenderVisibility) && (
              <CCValueField label="Gender" value={dataItem.Gender} />
            )}
          </div>
          <div className="cc-form-cols-3">
            {isFieldVisible(dataItem.DateOfBirthVisibility) && (
              <CCValueField
                label="Date of birth"
                value={dataItem.DateOfBirth}
                format={DATE_FORMAT.DATE}
              />
            )}
            {isFieldVisible(dataItem.OccupationVisibility) && (
              <CCValueField label="Occupation" value={dataItem.Occupation} />
            )}
            {isFieldVisible(dataItem.EmailAddressVisibility) && (
              <CCValueField
                label="Email address"
                value={dataItem.EmailAddress}
              />
            )}
          </div>
          <div className="cc-form-cols-3">
            {isFieldVisible(phoneNumber.MobilePhoneNumberVisibility) && (
              <CCValueField
                label="Phone number mobile"
                value={phoneNumber.PhoneNumberMobile}
              />
            )}
            {isFieldVisible(phoneNumber.PrivatePhoneNumberVisibility) && (
              <CCValueField
                label="Phone number private"
                value={phoneNumber.PhoneNumberPrivate}
              />
            )}
            {isFieldVisible(phoneNumber.BusinessPhoneNumberVisibility) && (
              <CCValueField
                label="Phone number business"
                value={phoneNumber.PhoneNumberBusiness}
              />
            )}
            {isFieldVisible(phoneNumber.FaxPhoneNumberVisibility) && (
              <CCValueField label="Fax number" value={phoneNumber.FaxNumber} />
            )}
          </div>
          <div className="cc-form-cols-1">
            {isFieldVisible(dataItem.NotesVisibility) && (
              <CCValueField label="Notes" value={dataItem.Notes} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntitiesDetail;
