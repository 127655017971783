import { DTO_Journal_Add_Transaction_LOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Workflow_JournalPICAddTransaction,
  RequestJournalPICAddTransactionObj,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessNewPICTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_JournalPICAddTransaction
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_JournalPICAddTransaction>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/pictransactionadd/process/${workflowType}`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataPICNewTransaction =
  | [
      APIResponse<DTO_Journal_Add_Transaction_LOVs>,
      APIResponse<DTO_Workflow_JournalPICAddTransaction>
    ]
  | APIResponse<
      DTO_Journal_Add_Transaction_LOVs | DTO_Workflow_JournalPICAddTransaction
    >
  | undefined;
export const getInitialDataPICNewTransaction = async (
  workflowDraftId: number | undefined = 0,
  journalNumber?: number,
  listPIC: number[] = []
): Promise<IGetInitialDataPICNewTransaction> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_Add_Transaction_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      !isNil(workflowDraftId) && workflowDraftId !== 0
        ? getWorkflowNewPICTransaction(workflowDraftId)
        : getNewWorkflowNewPICTransaction({
            JournalNumber: journalNumber,
            PIC_Ids: listPIC,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewPICTransaction = async (
  object: RequestJournalPICAddTransactionObj
): Promise<APIResponse<DTO_Workflow_JournalPICAddTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/pictransactionadd/new`,
      object
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowNewPICTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalPICAddTransaction>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `api/property/internal/workflow/pictransactionadd/${workflowDraftId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
