import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import { getLevyCalculationGridData } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/levy-calculation/api";
import { DTO_Supplementary_Assessment_Adjustment } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/levy-calculation/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatNumberByKendo,
  formatStyleCurrency,
} from "@common/utils/formatting";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IHeading {
  heading: string;
  total: number;
}

export const LevyCalculationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const Heading = ({ heading, total }: IHeading) => {
  return (
    <div className="cc-levy-heading">
      <label className="cc-label">{heading}</label>
      <label className="cc-label" style={formatStyleCurrency(total)}>
        {formatNumberByKendo(total, CURRENCY_FORMAT.CURRENCY1)}
      </label>
    </div>
  );
};

const nameOfLevyCalculation =
  nameOfFactory<VO_Supplementary_Levy_Calculation>();
const FormStepElement = observer(
  ({
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
  }: IFormStepElement) => {
    //Use store
    const { supplementaryAssessmentId } = useSupplementaryRatesStore();

    const total = (
      data: VO_Supplementary_Levy_Calculation[],
      field: keyof Pick<
        VO_Supplementary_Levy_Calculation,
        | "SLC_Old_X_Nett_Amount"
        | "SLC_New_X_Nett_Amount"
        | "SLC_Supp_Nett_Amount"
      >
    ) => {
      return data.reduce(
        (acc: number, curr: VO_Supplementary_Levy_Calculation) => {
          return acc + (curr?.[field] ?? 0);
        },
        0
      );
    };

    //Use state
    const [oldChargesCol, setOldChargesCol] = useState<IColumnFields[]>([]);
    const [newChargesCol, setNewChargesCol] = useState<IColumnFields[]>([]);
    const [adjustmentsCol, setAdjustmentsCol] = useState<IColumnFields[]>([]);
    const [oldChargesData, setOldChargesData] = useState<
      VO_Supplementary_Levy_Calculation[]
    >([]);
    const [newChargesData, setNewChargesData] = useState<
      VO_Supplementary_Levy_Calculation[]
    >([]);
    const [adjustmentsData, setAdjustmentsData] = useState<
      VO_Supplementary_Levy_Calculation[]
    >([]);
    const [totalAmount, setTotalAmount] =
      useState<DTO_Supplementary_Assessment_Adjustment>();

    const loadLevyCalculation = async () => {
      setIsLoadingStep(true);
      const response = await getLevyCalculationGridData(
        supplementaryAssessmentId
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        const [
          responseOldChargesViewConfig,
          responseNewChargesViewConfig,
          responseAdjustmentsViewConfig,
          responseOldChargesOData,
          responseNewChargesOData,
          responseAdjustmentsOData,
          responseLevyCalculationTotalAmount,
        ] = response;
        const dataOldChargesOData = responseOldChargesOData?.data?.value;
        const dataNewChargesOData = responseNewChargesOData?.data?.value;
        const dataAdjustmentsOData = responseAdjustmentsOData?.data?.value;
        if (
          Array.isArray(responseOldChargesViewConfig) &&
          Array.isArray(responseNewChargesViewConfig) &&
          Array.isArray(responseAdjustmentsViewConfig) &&
          isSuccessResponse(responseOldChargesOData) &&
          isSuccessResponse(responseNewChargesOData) &&
          isSuccessResponse(responseAdjustmentsOData) &&
          isSuccessResponse(responseLevyCalculationTotalAmount) &&
          responseLevyCalculationTotalAmount?.data &&
          dataOldChargesOData &&
          dataNewChargesOData &&
          dataAdjustmentsOData
        ) {
          setOldChargesCol(responseOldChargesViewConfig);
          setNewChargesCol(responseNewChargesViewConfig);
          setAdjustmentsCol(responseAdjustmentsViewConfig);
          setOldChargesData(dataOldChargesOData);
          setNewChargesData(dataNewChargesOData);
          setAdjustmentsData(dataAdjustmentsOData);
          setTotalAmount(responseLevyCalculationTotalAmount.data);
        } else {
          let responseError = responseOldChargesViewConfig as APIResponseError;
          switch (true) {
            case !Array.isArray(responseNewChargesViewConfig):
              responseError = responseNewChargesViewConfig as APIResponseError;
              break;
            case !Array.isArray(responseAdjustmentsViewConfig):
              responseError = responseAdjustmentsViewConfig as APIResponseError;
              break;
            case !isSuccessResponse(responseOldChargesOData):
              responseError = responseOldChargesOData;
              break;
            case !isSuccessResponse(responseNewChargesOData):
              responseError = responseNewChargesOData;
              break;
            case !isSuccessResponse(responseAdjustmentsOData):
              responseError = responseAdjustmentsOData;
              break;
            case !isSuccessResponse(responseLevyCalculationTotalAmount):
              responseError =
                responseLevyCalculationTotalAmount as APIResponseError;
              break;
          }
          setLoadFailedStep({
            onReload: () => {
              loadLevyCalculation();
            },
            responseError: {
              status: responseError.status,
              error: responseError.error ?? "Load failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        setLoadFailedStep({
          onReload: () => {
            loadLevyCalculation();
          },
          responseError: {
            status: responseError.status,
            error: responseError.error ?? "Load failed",
          },
        });
      }
    };

    const handleCalculateFooterCellValue = useCallback(
      (col: IColumnFields) => {
        if (isNil(totalAmount)) return col;
        const keys = Object.keys(totalAmount);
        const totalKey = `Total_${col.field}`;
        if (!keys.includes(totalKey)) return col;
        return {
          ...col,
          footerCell: <CurrencyCell />,
          calculateFooterCellValue: () =>
            totalAmount[totalKey as keyof typeof totalAmount],
        };
      },
      [totalAmount]
    );

    useEffect(() => {
      if (totalAmount && oldChargesCol && newChargesData && adjustmentsData) {
        const newOldChargesCol = oldChargesCol.map((col: IColumnFields) => {
          return handleCalculateFooterCellValue(col);
        });
        const newNewChargesCol = newChargesCol.map((col: IColumnFields) => {
          return handleCalculateFooterCellValue(col);
        });
        const newAdjustmentsCol = adjustmentsCol.map((col: IColumnFields) => {
          return handleCalculateFooterCellValue(col);
        });
        setOldChargesCol(newOldChargesCol);
        setNewChargesCol(newNewChargesCol);
        setAdjustmentsCol(newAdjustmentsCol);
      }
      // eslint-disable-next-line
    }, [totalAmount]);

    useEffectOnce(() => {
      loadLevyCalculation();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Heading
              heading="Charges based on previous levies & valuations"
              total={total(oldChargesData, "SLC_Old_X_Nett_Amount")}
            />
            <CCGrid
              data={oldChargesData}
              columnFields={oldChargesCol}
              primaryField={nameOfLevyCalculation("Supplementary_Levy_Calc_Id")}
            />
          </div>
          <div className="cc-field">
            <Heading
              heading="Charges based on new levies & valuations"
              total={total(newChargesData, "SLC_New_X_Nett_Amount")}
            />
            <CCGrid
              data={newChargesData}
              columnFields={newChargesCol}
              primaryField={nameOfLevyCalculation("Supplementary_Levy_Calc_Id")}
            />
          </div>
          <div className="cc-field">
            <Heading
              heading="Supplementary levy adjustments"
              total={total(adjustmentsData, "SLC_Supp_Nett_Amount")}
            />
            <CCGrid
              data={adjustmentsData}
              columnFields={adjustmentsCol}
              primaryField={nameOfLevyCalculation("Supplementary_Levy_Calc_Id")}
            />
          </div>
        </div>
      </section>
    );
  }
);
