export const mockBuilding = [
  {
    ID: 1,
    ApplicationNumber: 10365,
    PermitNumber: 92943,
    PermitType: "deposit",
    Date_PermitIssued: "2021-01-15T00:01:00.378Z",
    RefNo: 66112,
    AssessmentNo: 12384,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Susana Gutmann",
    Builder: "Teresa Sipes",
    Category: "Handmade Frozen Computer",
    BCA: "Investment Account",
    UseOfBuilding: false,
    ReasonForPermit: "Burg",
    SiteAddress: "vena.net",
    Locality: "TX",
    Owner: "Miss Tess Gulgowski",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-06-04T18:24:15.969Z",
    Date_AllInspComplete: "2021-12-27T09:36:50.785Z",
    Date_DueToCommence: "2021-03-28T20:55:40.536Z",
    Date_Commenced: "2021-08-19T20:31:16.258Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "November",
    FileNo: 40317,
    ValuationNo: 25160,
    LegalDescription: "programming teal",
    BuildingOfficer: "District",
    Surveyor: "Checking Account",
    SurveyorNumber: "04701203",
    StatDays: 74488,
    Date_Issued: "2021-11-24T12:59:51.865Z",
    Date_ReminderSent: "2021-03-01T05:51:50.647Z",
    PayingFee: 39079,
    CarryingOutWork: false,
    WorkProposed: "mesh",
    Address: "Apt. 592",
    BuildingAppNo: 88163,
    BuildingPermitNo: 35170,
    Date_BuildingIssued: "November",
    Date_Commencing: "October",
    BondAmount: 90994,
    BondReceived: "69496-6165",
    BondReturned: 26503,
    Date_FirstInspection: "2021-08-13T15:00:15.047Z",
    Date_LastInspection: "2021-10-16T22:29:51.359Z",
    TargetDate: "2021-12-14T11:18:49.270Z",
    CILFee: 65134,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-03-06T04:42:55.728Z",
    InspectionType: "Administrator",
    Officer: "Personal Loan Account",
    Compliance: false,
    Reason: "uniform",
    FacilityRegion: "Wisconsin",
    Description: "Customer",
    DateTarget: "2021-09-13T23:14:03.047Z",
    Source: "Functionality",
    Location: "NM",
    RequestedBy: "30052503",
    ContactDetails: "789-310-9850 x768",
    DueStatus: false,
    DateRecorded: "2021-07-20T16:18:22.906Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Managed demand-driven model",
    ActionOfficer: "Health",
    Coordinator: "Suite 086",
    DateWork: "2021-07-26T19:05:26.780Z",
    FileNumber: 40875,
    EnquiryRefNo: 54170,
    Event_ReferenceNoAdditional: 60883,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-03-27T02:28:18.858Z",
    ApplicantName: "Checking Account",
    Type: "Group",
    Risk: "Reverse-engineered",
    DateDue: "2021-10-17T09:47:45.163Z",
    Register_Number: 92164,
    Contact: "Suite 071",
    ReferenceNumber: 44530,
    Date_Complete: "2021-06-26T07:02:13.032Z",
    Date_Construction: "2021-04-23T16:04:29.017Z",
    BarrierStandard: "BarrierStandard 1",
    BarrierType: "BarrierType 1",
    Date_CertificateOfCompliance: "2021-11-10T16:42:02.384Z",
    Pool_RegisteredDate: "2021-03-30T05:14:02.744Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-03T04:51:24.015Z",
    Date_WorkIssued: "2021-11-27T03:05:13.512Z",
    Work_CarriedOut: "Agent",
    AmountOS: 88543,
    Flag_RenewalNoticeSent: true,
    Outcome: 60917,
    Regulation: "world-class",
    Date_Due: "2021-02-20T19:30:33.864Z",
    Date_NextInspection: "2021-06-02T23:39:38.494Z",
    Date_Start: "2021-01-31T01:58:31.612Z",
    Date_Occurred: "2021-09-17T17:02:37.006Z",
    ContactName: "Miss Sydnie O'Connell",
    CreatedDate: "2020-08-02T10:29:35.738Z",
    FeeType: "¥",
    AmountIssued: 20006,
    AmountOutstanding: 28058,
    CostCode: 92221,
    AmountPaid: 39170,
    ReceiptNumber: 73763,
    ReceiptDate: "2021-08-22T14:15:55.250Z",
    ReceiptedBy: "Officer",
    Method: "Regional",
    InvoiceNumber: 49988,
    DebtorNumber: 32503,
    SecurePay_Reference: "XBC",
    Contact2: "Rashawn.Fisher@example.net",
    LevelOfEnforcement: "643.59",
    Number: 66944,
    DateOffence: "2021-08-02T08:22:01.626Z",
    RegisterType: "Representative",
    VicSmart: "VicSmart 1",
    ePlanningCategory: "ePlanningCategory 1",
    ePlanningLodgedBy: "ePlanningLodgedBy 1",
    Comments: "Comments 1",
    ApplicationCategory_Name: "Shoes",
    ApplicationCategory_Description: "Automotive",
    Code: "23421-6263",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "You can't parse the hard drive without indexing the wireless EXE sensor!",
    PublishDescription: "We need to back up the bluetooth PCI array!",
    Title: "Steel invoice",
    ConditionCode: "47890",
    Condition: "Inc",
    Name: "Mr. Esta Bradtke",
    Multiplier: "Multiplier 1",
    Value: 49120,
    MinimumValue: 60489,
    TargetDays: "TargetDays 1",
    Category_1: "Grocery",
    Category_2: "Outdoors",
    ObservationSummary: "ObservationSummary 1",
    ComplianceInstruction: "ComplianceInstruction 1",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-13T23:14:38.147Z",
  },
  {
    ID: 2,
    ApplicationNumber: 44263,
    PermitNumber: 51571,
    PermitType: "deposit",
    Date_PermitIssued: "2021-07-14T15:01:11.772Z",
    RefNo: 46581,
    AssessmentNo: 54455,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Aidan Schowalter",
    Builder: "Oscar Mills",
    Category: "Awesome Soft Chair",
    BCA: "recontextualize",
    UseOfBuilding: false,
    ReasonForPermit: "override Orchestrator",
    SiteAddress: "eloy.net",
    Locality: "NC",
    Owner: "Elvera Romaguera",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-08-25T18:55:09.097Z",
    Date_AllInspComplete: "2021-01-19T01:09:02.988Z",
    Date_DueToCommence: "2021-10-10T05:21:01.373Z",
    Date_Commenced: "2021-09-07T21:39:00.497Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "June",
    FileNo: 89417,
    ValuationNo: 70396,
    LegalDescription: "deposit Borders Integration",
    BuildingOfficer: "Principal",
    Surveyor: "Investment Account",
    SurveyorNumber: "77750921",
    StatDays: 4342,
    Date_Issued: "2021-02-17T14:35:10.152Z",
    Date_ReminderSent: "2021-01-21T19:22:38.798Z",
    PayingFee: 82727,
    CarryingOutWork: false,
    WorkProposed: "Table",
    Address: "Apt. 793",
    BuildingAppNo: 97433,
    BuildingPermitNo: 11490,
    Date_BuildingIssued: "April",
    Date_Commencing: "November",
    BondAmount: 82085,
    BondReceived: "50258",
    BondReturned: 32372,
    Date_FirstInspection: "2021-04-14T01:31:22.289Z",
    Date_LastInspection: "2021-07-22T03:26:39.490Z",
    TargetDate: "2021-03-09T03:15:51.482Z",
    CILFee: 89320,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-12-06T12:00:16.289Z",
    InspectionType: "innovate",
    Officer: "Checking Account",
    Compliance: true,
    Reason: "Director",
    FacilityRegion: "Indiana",
    Description: "Chief",
    DateTarget: "2021-03-05T08:20:09.622Z",
    Source: "Accountability",
    Location: "CT",
    RequestedBy: "43939357",
    ContactDetails: "553-595-2600 x4917",
    DueStatus: false,
    DateRecorded: "2021-11-15T09:42:11.219Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Innovative dynamic internet solution",
    ActionOfficer: "Toys",
    Coordinator: "Apt. 576",
    DateWork: "2021-01-08T13:09:10.262Z",
    FileNumber: 46727,
    EnquiryRefNo: 12270,
    Event_ReferenceNoAdditional: 76952,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-09-26T08:57:08.369Z",
    ApplicantName: "Credit Card Account",
    Type: "Inc",
    Risk: "Principal transmit",
    DateDue: "2021-07-15T15:00:25.141Z",
    Register_Number: 50169,
    Contact: "Apt. 934",
    ReferenceNumber: 11120,
    Date_Complete: "2021-02-16T13:44:43.483Z",
    Date_Construction: "2021-02-15T13:16:42.980Z",
    BarrierStandard: "BarrierStandard 2",
    BarrierType: "BarrierType 2",
    Date_CertificateOfCompliance: "2021-09-01T13:21:32.166Z",
    Pool_RegisteredDate: "2021-07-18T02:59:29.330Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-19T22:17:45.689Z",
    Date_WorkIssued: "2021-02-14T08:32:19.393Z",
    Work_CarriedOut: "Developer",
    AmountOS: 34577,
    Flag_RenewalNoticeSent: true,
    Outcome: 24182,
    Regulation: "JBOD Kentucky",
    Date_Due: "2021-05-16T22:56:00.244Z",
    Date_NextInspection: "2021-05-10T14:18:56.554Z",
    Date_Start: "2021-06-27T15:33:31.639Z",
    Date_Occurred: "2021-03-18T21:00:52.696Z",
    ContactName: "Dustin Labadie",
    CreatedDate: "2020-01-20T09:22:29.267Z",
    FeeType: "$",
    AmountIssued: 48348,
    AmountOutstanding: 4398,
    CostCode: 60050,
    AmountPaid: 27373,
    ReceiptNumber: 55745,
    ReceiptDate: "2021-09-13T18:13:38.453Z",
    ReceiptedBy: "Producer",
    Method: "Dynamic",
    InvoiceNumber: 44232,
    DebtorNumber: 67900,
    SecurePay_Reference: "MDL",
    Contact2: "Oswaldo83@example.net",
    LevelOfEnforcement: "212.93",
    Number: 60104,
    DateOffence: "2021-02-18T03:02:12.952Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 2",
    ePlanningCategory: "ePlanningCategory 2",
    ePlanningLodgedBy: "ePlanningLodgedBy 2",
    Comments: "Comments 2",
    ApplicationCategory_Name: "Outdoors",
    ApplicationCategory_Description: "Outdoors",
    Code: "27482-4787",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "If we synthesize the matrix, we can get to the EXE capacitor through the cross-platform SSL array!",
    PublishDescription:
      "You can't bypass the alarm without parsing the open-source RSS hard drive!",
    Title: "zero defect",
    ConditionCode: "86801-0951",
    Condition: "Inc",
    Name: "Ms. Mabelle Bins",
    Multiplier: "Multiplier 2",
    Value: 32417,
    MinimumValue: 63689,
    TargetDays: "TargetDays 2",
    Category_1: "Jewelery",
    Category_2: "Health",
    ObservationSummary: "ObservationSummary 2",
    ComplianceInstruction: "ComplianceInstruction 2",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-18T04:40:07.306Z",
  },
  {
    ID: 3,
    ApplicationNumber: 20540,
    PermitNumber: 91318,
    PermitType: "payment",
    Date_PermitIssued: "2021-04-13T21:30:09.008Z",
    RefNo: 13344,
    AssessmentNo: 72124,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Glennie Powlowski",
    Builder: "Colby Eichmann",
    Category: "Awesome Plastic Bike",
    BCA: "cross-platform",
    UseOfBuilding: false,
    ReasonForPermit: "utilize Gorgeous Metal Pants Corners",
    SiteAddress: "shania.biz",
    Locality: "VA",
    Owner: "Zena Hilpert MD",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-05-21T17:34:56.522Z",
    Date_AllInspComplete: "2021-12-13T15:23:03.287Z",
    Date_DueToCommence: "2021-08-05T17:22:12.180Z",
    Date_Commenced: "2021-10-30T05:36:24.979Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "January",
    FileNo: 54318,
    ValuationNo: 84218,
    LegalDescription: "rich eyeballs Incredible Wooden Fish",
    BuildingOfficer: "Chief",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "15743462",
    StatDays: 62867,
    Date_Issued: "2021-05-03T05:31:02.751Z",
    Date_ReminderSent: "2021-11-27T01:29:56.057Z",
    PayingFee: 2428,
    CarryingOutWork: false,
    WorkProposed: "XSS Buckinghamshire experiences",
    Address: "Apt. 946",
    BuildingAppNo: 19874,
    BuildingPermitNo: 85599,
    Date_BuildingIssued: "December",
    Date_Commencing: "November",
    BondAmount: 22955,
    BondReceived: "15979",
    BondReturned: 81011,
    Date_FirstInspection: "2021-10-05T17:42:51.334Z",
    Date_LastInspection: "2021-01-02T18:53:50.963Z",
    TargetDate: "2021-04-23T21:18:45.121Z",
    CILFee: 51792,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-04-10T15:01:41.411Z",
    InspectionType: "local area network",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "Berkshire",
    FacilityRegion: "Kansas",
    Description: "Product",
    DateTarget: "2021-07-26T18:50:27.007Z",
    Source: "Tactics",
    Location: "MT",
    RequestedBy: "24382699",
    ContactDetails: "(239) 587-2045 x43757",
    DueStatus: false,
    DateRecorded: "2021-10-05T20:46:22.761Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Visionary composite groupware",
    ActionOfficer: "Shoes",
    Coordinator: "Suite 391",
    DateWork: "2021-11-18T18:54:50.641Z",
    FileNumber: 45586,
    EnquiryRefNo: 40339,
    Event_ReferenceNoAdditional: 54078,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-06-23T18:21:14.296Z",
    ApplicantName: "Personal Loan Account",
    Type: "and Sons",
    Risk: "Expanded Fresh",
    DateDue: "2021-03-20T10:41:18.008Z",
    Register_Number: 34693,
    Contact: "Suite 943",
    ReferenceNumber: 46858,
    Date_Complete: "2021-10-20T17:48:02.770Z",
    Date_Construction: "2021-03-26T09:02:16.439Z",
    BarrierStandard: "BarrierStandard 3",
    BarrierType: "BarrierType 3",
    Date_CertificateOfCompliance: "2021-08-23T05:33:04.854Z",
    Pool_RegisteredDate: "2021-06-01T12:36:27.467Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-14T09:55:34.072Z",
    Date_WorkIssued: "2021-12-03T20:37:44.873Z",
    Work_CarriedOut: "Orchestrator",
    AmountOS: 27141,
    Flag_RenewalNoticeSent: true,
    Outcome: 82242,
    Regulation: "mobile impactful withdrawal",
    Date_Due: "2021-03-15T16:07:33.211Z",
    Date_NextInspection: "2021-04-28T08:31:20.162Z",
    Date_Start: "2021-08-16T12:43:59.719Z",
    Date_Occurred: "2021-05-25T07:46:10.288Z",
    ContactName: "Leora Brown",
    CreatedDate: "2020-08-01T07:52:26.158Z",
    FeeType: "B/.",
    AmountIssued: 65200,
    AmountOutstanding: 59038,
    CostCode: 73135,
    AmountPaid: 60591,
    ReceiptNumber: 37951,
    ReceiptDate: "2021-08-01T17:06:39.123Z",
    ReceiptedBy: "Officer",
    Method: "Regional",
    InvoiceNumber: 9500,
    DebtorNumber: 79918,
    SecurePay_Reference: "GEL",
    Contact2: "Bernice.Schaefer@example.org",
    LevelOfEnforcement: "685.07",
    Number: 86732,
    DateOffence: "2021-01-24T06:52:48.580Z",
    RegisterType: "Manager",
    VicSmart: "VicSmart 3",
    ePlanningCategory: "ePlanningCategory 3",
    ePlanningLodgedBy: "ePlanningLodgedBy 3",
    Comments: "Comments 3",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Home",
    Code: "30468",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "If we reboot the microchip, we can get to the PCI circuit through the bluetooth SDD protocol!",
    PublishDescription:
      "Use the multi-byte HDD application, then you can reboot the haptic system!",
    Title: "hacking Kip",
    ConditionCode: "44287-0502",
    Condition: "Inc",
    Name: "Abel Halvorson",
    Multiplier: "Multiplier 3",
    Value: 41369,
    MinimumValue: 33072,
    TargetDays: "TargetDays 3",
    Category_1: "Home",
    Category_2: "Outdoors",
    ObservationSummary: "ObservationSummary 3",
    ComplianceInstruction: "ComplianceInstruction 3",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-06T21:47:39.239Z",
  },
  {
    ID: 4,
    ApplicationNumber: 81840,
    PermitNumber: 5127,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-08-05T15:39:48.586Z",
    RefNo: 38057,
    AssessmentNo: 6202,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Arturo Smith",
    Builder: "Rodrick Ferry",
    Category: "Licensed Frozen Chair",
    BCA: "Canada",
    UseOfBuilding: false,
    ReasonForPermit: "artificial intelligence deposit brand",
    SiteAddress: "ruthie.net",
    Locality: "KY",
    Owner: "Kaylie Mayert",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-03-31T00:56:09.587Z",
    Date_AllInspComplete: "2021-11-04T20:47:41.197Z",
    Date_DueToCommence: "2021-10-27T10:59:07.287Z",
    Date_Commenced: "2021-07-26T06:11:40.670Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "November",
    FileNo: 18778,
    ValuationNo: 62018,
    LegalDescription: "Rubber Consultant",
    BuildingOfficer: "Legacy",
    Surveyor: "Money Market Account",
    SurveyorNumber: "95598701",
    StatDays: 27184,
    Date_Issued: "2021-04-18T21:04:40.970Z",
    Date_ReminderSent: "2021-01-12T19:29:42.734Z",
    PayingFee: 22874,
    CarryingOutWork: true,
    WorkProposed: "Bahamian Dollar",
    Address: "Suite 095",
    BuildingAppNo: 45767,
    BuildingPermitNo: 61948,
    Date_BuildingIssued: "October",
    Date_Commencing: "April",
    BondAmount: 71225,
    BondReceived: "80342",
    BondReturned: 40364,
    Date_FirstInspection: "2021-09-11T19:53:33.424Z",
    Date_LastInspection: "2021-05-01T21:11:06.353Z",
    TargetDate: "2021-12-13T12:03:45.345Z",
    CILFee: 46424,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-04-22T05:15:59.510Z",
    InspectionType: "Garden",
    Officer: "Money Market Account",
    Compliance: false,
    Reason: "Tasty granular Alabama",
    FacilityRegion: "Hawaii",
    Description: "Dynamic",
    DateTarget: "2021-03-20T12:19:46.016Z",
    Source: "Web",
    Location: "OH",
    RequestedBy: "91933703",
    ContactDetails: "(647) 791-0734",
    DueStatus: false,
    DateRecorded: "2021-09-23T06:14:48.902Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Optimized client-driven collaboration",
    ActionOfficer: "Outdoors",
    Coordinator: "Apt. 494",
    DateWork: "2021-10-21T18:04:44.943Z",
    FileNumber: 10116,
    EnquiryRefNo: 93360,
    Event_ReferenceNoAdditional: 12744,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-04-03T21:55:02.575Z",
    ApplicantName: "Auto Loan Account",
    Type: "and Sons",
    Risk: "scalable parsing",
    DateDue: "2021-04-07T06:26:29.655Z",
    Register_Number: 70391,
    Contact: "Apt. 952",
    ReferenceNumber: 80757,
    Date_Complete: "2021-01-07T16:05:18.033Z",
    Date_Construction: "2021-09-05T16:58:06.543Z",
    BarrierStandard: "BarrierStandard 4",
    BarrierType: "BarrierType 4",
    Date_CertificateOfCompliance: "2021-04-20T00:50:57.847Z",
    Pool_RegisteredDate: "2021-05-16T23:20:10.215Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-10T18:49:37.971Z",
    Date_WorkIssued: "2021-11-29T15:33:36.117Z",
    Work_CarriedOut: "Designer",
    AmountOS: 65724,
    Flag_RenewalNoticeSent: false,
    Outcome: 52223,
    Regulation: "driver",
    Date_Due: "2021-09-19T19:49:32.777Z",
    Date_NextInspection: "2021-11-02T22:33:18.730Z",
    Date_Start: "2021-07-01T08:10:37.332Z",
    Date_Occurred: "2021-07-04T15:50:14.571Z",
    ContactName: "Mona Johns",
    CreatedDate: "2020-01-05T10:36:14.798Z",
    FeeType: "$U",
    AmountIssued: 62779,
    AmountOutstanding: 54105,
    CostCode: 43999,
    AmountPaid: 90286,
    ReceiptNumber: 42646,
    ReceiptDate: "2021-01-29T11:33:02.421Z",
    ReceiptedBy: "Administrator",
    Method: "Dynamic",
    InvoiceNumber: 34340,
    DebtorNumber: 81897,
    SecurePay_Reference: "BYR",
    Contact2: "Frederick.Dickinson32@example.org",
    LevelOfEnforcement: "473.64",
    Number: 76640,
    DateOffence: "2021-12-19T23:08:37.762Z",
    RegisterType: "Manager",
    VicSmart: "VicSmart 4",
    ePlanningCategory: "ePlanningCategory 4",
    ePlanningLodgedBy: "ePlanningLodgedBy 4",
    Comments: "Comments 4",
    ApplicationCategory_Name: "Outdoors",
    ApplicationCategory_Description: "Music",
    Code: "89629-3581",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "You can't override the capacitor without compressing the open-source HTTP monitor!",
    PublishDescription:
      "If we transmit the port, we can get to the SMS interface through the digital HTTP panel!",
    Title: "Cambridgeshire",
    ConditionCode: "80015",
    Condition: "LLC",
    Name: "Daisy Johnson",
    Multiplier: "Multiplier 4",
    Value: 16176,
    MinimumValue: 62566,
    TargetDays: "TargetDays 4",
    Category_1: "Baby",
    Category_2: "Books",
    ObservationSummary: "ObservationSummary 4",
    ComplianceInstruction: "ComplianceInstruction 4",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-20T02:04:35.289Z",
  },
  {
    ID: 5,
    ApplicationNumber: 37161,
    PermitNumber: 919,
    PermitType: "deposit",
    Date_PermitIssued: "2021-03-16T04:34:56.347Z",
    RefNo: 97062,
    AssessmentNo: 86039,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Sadie Borer",
    Builder: "Jedidiah Fadel",
    Category: "Unbranded Steel Chair",
    BCA: "connecting",
    UseOfBuilding: false,
    ReasonForPermit: "Consultant indexing Electronics",
    SiteAddress: "kyleigh.net",
    Locality: "AK",
    Owner: "Mrs. Samson Schowalter",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-09-01T03:08:02.897Z",
    Date_AllInspComplete: "2021-05-11T10:02:58.808Z",
    Date_DueToCommence: "2021-07-17T07:18:46.049Z",
    Date_Commenced: "2021-03-14T22:03:40.260Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "July",
    FileNo: 13607,
    ValuationNo: 90636,
    LegalDescription: "Refined Frozen Gloves violet",
    BuildingOfficer: "Dynamic",
    Surveyor: "Checking Account",
    SurveyorNumber: "24215344",
    StatDays: 52205,
    Date_Issued: "2021-07-07T11:34:01.991Z",
    Date_ReminderSent: "2021-07-11T10:28:58.801Z",
    PayingFee: 75370,
    CarryingOutWork: true,
    WorkProposed: "Integration",
    Address: "Suite 433",
    BuildingAppNo: 11407,
    BuildingPermitNo: 45257,
    Date_BuildingIssued: "December",
    Date_Commencing: "March",
    BondAmount: 91544,
    BondReceived: "64391",
    BondReturned: 73779,
    Date_FirstInspection: "2021-04-27T16:16:35.229Z",
    Date_LastInspection: "2021-03-10T02:00:52.850Z",
    TargetDate: "2021-12-06T09:03:10.942Z",
    CILFee: 54835,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-02-02T20:21:39.157Z",
    InspectionType: "synergize",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "synthesizing",
    FacilityRegion: "Indiana",
    Description: "District",
    DateTarget: "2021-08-04T19:05:59.641Z",
    Source: "Implementation",
    Location: "MT",
    RequestedBy: "81572100",
    ContactDetails: "158-689-8344",
    DueStatus: false,
    DateRecorded: "2021-02-06T05:56:25.116Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Synchronised 4th generation secured line",
    ActionOfficer: "Health",
    Coordinator: "Suite 552",
    DateWork: "2021-09-06T05:48:45.653Z",
    FileNumber: 46086,
    EnquiryRefNo: 54284,
    Event_ReferenceNoAdditional: 737,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-03-24T09:11:57.505Z",
    ApplicantName: "Home Loan Account",
    Type: "and Sons",
    Risk: "definition Afghanistan",
    DateDue: "2021-06-05T18:29:19.917Z",
    Register_Number: 29602,
    Contact: "Apt. 782",
    ReferenceNumber: 43119,
    Date_Complete: "2021-03-11T07:50:24.261Z",
    Date_Construction: "2021-10-03T06:54:30.770Z",
    BarrierStandard: "BarrierStandard 5",
    BarrierType: "BarrierType 5",
    Date_CertificateOfCompliance: "2021-08-27T05:28:01.155Z",
    Pool_RegisteredDate: "2021-11-17T13:23:41.033Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-26T22:33:01.186Z",
    Date_WorkIssued: "2021-04-09T13:53:01.368Z",
    Work_CarriedOut: "Administrator",
    AmountOS: 4874,
    Flag_RenewalNoticeSent: true,
    Outcome: 68705,
    Regulation: "Clothing",
    Date_Due: "2021-10-24T18:16:07.080Z",
    Date_NextInspection: "2021-11-06T10:16:42.890Z",
    Date_Start: "2021-10-12T02:50:23.399Z",
    Date_Occurred: "2021-11-26T12:58:39.002Z",
    ContactName: "Wellington Upton",
    CreatedDate: "2020-09-28T21:15:58.513Z",
    FeeType: "KM",
    AmountIssued: 3573,
    AmountOutstanding: 28154,
    CostCode: 71692,
    AmountPaid: 17507,
    ReceiptNumber: 98214,
    ReceiptDate: "2021-10-12T12:47:51.695Z",
    ReceiptedBy: "Administrator",
    Method: "Human",
    InvoiceNumber: 9821,
    DebtorNumber: 99137,
    SecurePay_Reference: "COP COU",
    Contact2: "Thora.Ortiz@example.net",
    LevelOfEnforcement: "94.26",
    Number: 68277,
    DateOffence: "2021-04-01T06:55:34.285Z",
    RegisterType: "Liaison",
    VicSmart: "VicSmart 5",
    ePlanningCategory: "ePlanningCategory 5",
    ePlanningLodgedBy: "ePlanningLodgedBy 5",
    Comments: "Comments 5",
    ApplicationCategory_Name: "Electronics",
    ApplicationCategory_Description: "Jewelery",
    Code: "39645",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "I'll synthesize the optical SMTP driver, that should port the HTTP system!",
    PublishDescription:
      "I'll calculate the solid state HTTP protocol, that should bus the SDD feed!",
    Title: "compress Zimbabwe Licensed",
    ConditionCode: "27471",
    Condition: "and Sons",
    Name: "Benedict Moen",
    Multiplier: "Multiplier 5",
    Value: 1852,
    MinimumValue: 21944,
    TargetDays: "TargetDays 5",
    Category_1: "Shoes",
    Category_2: "Health",
    ObservationSummary: "ObservationSummary 5",
    ComplianceInstruction: "ComplianceInstruction 5",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-06T18:38:16.871Z",
  },
  {
    ID: 6,
    ApplicationNumber: 97672,
    PermitNumber: 78398,
    PermitType: "deposit",
    Date_PermitIssued: "2021-05-12T13:12:41.456Z",
    RefNo: 7744,
    AssessmentNo: 98776,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Piper Hartmann MD",
    Builder: "Tomasa Stehr",
    Category: "Handcrafted Granite Fish",
    BCA: "compressing",
    UseOfBuilding: true,
    ReasonForPermit: "Comoros",
    SiteAddress: "imani.name",
    Locality: "CO",
    Owner: "Mr. Abigale Morar",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-01-29T15:07:53.112Z",
    Date_AllInspComplete: "2021-10-15T16:56:10.329Z",
    Date_DueToCommence: "2021-06-02T01:54:23.395Z",
    Date_Commenced: "2021-10-04T03:17:44.202Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "January",
    FileNo: 42290,
    ValuationNo: 63385,
    LegalDescription: "Berkshire exploit",
    BuildingOfficer: "Central",
    Surveyor: "Checking Account",
    SurveyorNumber: "76976552",
    StatDays: 99303,
    Date_Issued: "2021-11-02T22:59:53.384Z",
    Date_ReminderSent: "2021-03-18T17:34:11.326Z",
    PayingFee: 90842,
    CarryingOutWork: false,
    WorkProposed: "Tunisian Dinar",
    Address: "Apt. 478",
    BuildingAppNo: 55635,
    BuildingPermitNo: 64697,
    Date_BuildingIssued: "April",
    Date_Commencing: "May",
    BondAmount: 20076,
    BondReceived: "68675",
    BondReturned: 70934,
    Date_FirstInspection: "2021-09-17T15:14:28.840Z",
    Date_LastInspection: "2021-10-30T15:09:10.345Z",
    TargetDate: "2021-09-22T14:19:11.453Z",
    CILFee: 34592,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-06-06T20:15:06.278Z",
    InspectionType: "overriding",
    Officer: "Money Market Account",
    Compliance: false,
    Reason: "Extended",
    FacilityRegion: "Oklahoma",
    Description: "Product",
    DateTarget: "2021-10-31T20:21:13.245Z",
    Source: "Quality",
    Location: "NV",
    RequestedBy: "25120677",
    ContactDetails: "1-062-027-7251 x819",
    DueStatus: true,
    DateRecorded: "2021-09-17T23:56:21.983Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Advanced scalable instruction set",
    ActionOfficer: "Health",
    Coordinator: "Apt. 230",
    DateWork: "2021-05-17T19:02:40.535Z",
    FileNumber: 98849,
    EnquiryRefNo: 79839,
    Event_ReferenceNoAdditional: 71767,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-10-11T16:54:15.531Z",
    ApplicantName: "Home Loan Account",
    Type: "Group",
    Risk: "solid state local",
    DateDue: "2021-02-13T06:19:22.614Z",
    Register_Number: 12466,
    Contact: "Apt. 302",
    ReferenceNumber: 39225,
    Date_Complete: "2021-10-30T20:15:47.067Z",
    Date_Construction: "2021-05-28T03:15:49.805Z",
    BarrierStandard: "BarrierStandard 6",
    BarrierType: "BarrierType 6",
    Date_CertificateOfCompliance: "2021-04-26T07:56:58.050Z",
    Pool_RegisteredDate: "2021-10-26T12:39:52.726Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-06T19:39:05.646Z",
    Date_WorkIssued: "2021-11-16T23:27:23.367Z",
    Work_CarriedOut: "Consultant",
    AmountOS: 75396,
    Flag_RenewalNoticeSent: true,
    Outcome: 43990,
    Regulation: "Mouse",
    Date_Due: "2021-12-05T23:09:18.299Z",
    Date_NextInspection: "2021-02-26T02:15:41.322Z",
    Date_Start: "2021-02-05T15:35:38.026Z",
    Date_Occurred: "2021-08-17T13:38:44.347Z",
    ContactName: "Ike McKenzie",
    CreatedDate: "2020-04-18T11:58:08.334Z",
    FeeType: "₨",
    AmountIssued: 18692,
    AmountOutstanding: 47842,
    CostCode: 23395,
    AmountPaid: 469,
    ReceiptNumber: 21177,
    ReceiptDate: "2021-09-14T05:55:25.254Z",
    ReceiptedBy: "Technician",
    Method: "Lead",
    InvoiceNumber: 6054,
    DebtorNumber: 32221,
    SecurePay_Reference: "MWK",
    Contact2: "Osbaldo.Mayert67@example.com",
    LevelOfEnforcement: "242.07",
    Number: 65706,
    DateOffence: "2021-07-22T14:28:30.169Z",
    RegisterType: "Agent",
    VicSmart: "VicSmart 6",
    ePlanningCategory: "ePlanningCategory 6",
    ePlanningLodgedBy: "ePlanningLodgedBy 6",
    Comments: "Comments 6",
    ApplicationCategory_Name: "Games",
    ApplicationCategory_Description: "Shoes",
    Code: "82258",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName: "We need to navigate the solid state XML card!",
    PublishDescription:
      "Try to copy the SMS program, maybe it will quantify the bluetooth transmitter!",
    Title: "Personal Loan Account Response",
    ConditionCode: "68346-0575",
    Condition: "and Sons",
    Name: "Everette Ullrich",
    Multiplier: "Multiplier 6",
    Value: 8818,
    MinimumValue: 54122,
    TargetDays: "TargetDays 6",
    Category_1: "Beauty",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 6",
    ComplianceInstruction: "ComplianceInstruction 6",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-22T14:58:53.889Z",
  },
  {
    ID: 7,
    ApplicationNumber: 67144,
    PermitNumber: 17025,
    PermitType: "invoice",
    Date_PermitIssued: "2021-11-14T03:13:03.363Z",
    RefNo: 10508,
    AssessmentNo: 30274,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Amelia Murray",
    Builder: "Omari Auer",
    Category: "Handcrafted Concrete Gloves",
    BCA: "leverage",
    UseOfBuilding: false,
    ReasonForPermit: "Keyboard",
    SiteAddress: "amos.biz",
    Locality: "NY",
    Owner: "Carolanne Hettinger",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-20T12:30:46.402Z",
    Date_AllInspComplete: "2021-11-24T23:39:08.572Z",
    Date_DueToCommence: "2021-02-09T04:42:31.910Z",
    Date_Commenced: "2021-06-28T14:26:52.488Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "August",
    FileNo: 24567,
    ValuationNo: 60231,
    LegalDescription: "Generic Plastic Computer client-server Bedfordshire",
    BuildingOfficer: "Customer",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "82169188",
    StatDays: 43475,
    Date_Issued: "2021-09-16T13:27:46.942Z",
    Date_ReminderSent: "2021-11-08T13:25:37.146Z",
    PayingFee: 93225,
    CarryingOutWork: false,
    WorkProposed: "HDD San Marino",
    Address: "Apt. 837",
    BuildingAppNo: 47127,
    BuildingPermitNo: 8546,
    Date_BuildingIssued: "June",
    Date_Commencing: "February",
    BondAmount: 36748,
    BondReceived: "83059-3276",
    BondReturned: 77161,
    Date_FirstInspection: "2021-01-18T05:31:22.932Z",
    Date_LastInspection: "2021-12-21T17:02:46.991Z",
    TargetDate: "2021-10-22T03:46:15.062Z",
    CILFee: 30033,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-05-27T04:14:49.517Z",
    InspectionType: "COM",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "hacking azure matrix",
    FacilityRegion: "Hawaii",
    Description: "Legacy",
    DateTarget: "2021-07-04T04:31:48.023Z",
    Source: "Data",
    Location: "OK",
    RequestedBy: "26512953",
    ContactDetails: "042.285.2328 x0849",
    DueStatus: false,
    DateRecorded: "2021-02-13T10:50:34.692Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Front-line disintermediate database",
    ActionOfficer: "Health",
    Coordinator: "Suite 956",
    DateWork: "2021-05-13T13:43:28.871Z",
    FileNumber: 99200,
    EnquiryRefNo: 80579,
    Event_ReferenceNoAdditional: 90568,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-10-23T18:49:15.335Z",
    ApplicantName: "Savings Account",
    Type: "LLC",
    Risk: "Licensed Fresh Towels Cheese Licensed",
    DateDue: "2021-08-19T22:09:15.480Z",
    Register_Number: 19330,
    Contact: "Suite 151",
    ReferenceNumber: 78166,
    Date_Complete: "2021-06-12T02:29:42.550Z",
    Date_Construction: "2021-12-31T01:56:23.159Z",
    BarrierStandard: "BarrierStandard 7",
    BarrierType: "BarrierType 7",
    Date_CertificateOfCompliance: "2021-08-17T14:51:11.454Z",
    Pool_RegisteredDate: "2021-10-25T18:18:09.381Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-22T19:59:01.702Z",
    Date_WorkIssued: "2021-03-26T09:33:48.710Z",
    Work_CarriedOut: "Director",
    AmountOS: 1267,
    Flag_RenewalNoticeSent: false,
    Outcome: 58157,
    Regulation: "Sports Manager",
    Date_Due: "2021-07-02T08:43:23.170Z",
    Date_NextInspection: "2021-04-26T02:44:42.437Z",
    Date_Start: "2021-04-29T12:07:13.980Z",
    Date_Occurred: "2021-09-03T21:19:46.206Z",
    ContactName: "Ebony Jacobi MD",
    CreatedDate: "2020-06-21T23:24:50.952Z",
    FeeType: "S",
    AmountIssued: 98309,
    AmountOutstanding: 9579,
    CostCode: 29450,
    AmountPaid: 85467,
    ReceiptNumber: 27553,
    ReceiptDate: "2021-08-22T18:34:21.783Z",
    ReceiptedBy: "Planner",
    Method: "Customer",
    InvoiceNumber: 77092,
    DebtorNumber: 13725,
    SecurePay_Reference: "MRO",
    Contact2: "Immanuel50@example.com",
    LevelOfEnforcement: "856.70",
    Number: 19149,
    DateOffence: "2021-05-18T22:55:44.389Z",
    RegisterType: "Administrator",
    VicSmart: "VicSmart 7",
    ePlanningCategory: "ePlanningCategory 7",
    ePlanningLodgedBy: "ePlanningLodgedBy 7",
    Comments: "Comments 7",
    ApplicationCategory_Name: "Electronics",
    ApplicationCategory_Description: "Tools",
    Code: "17814",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "I'll copy the auxiliary HDD transmitter, that should microchip the XML array!",
    PublishDescription:
      "You can't input the pixel without hacking the mobile PNG driver!",
    Title: "secured line Moroccan Dirham",
    ConditionCode: "58842",
    Condition: "and Sons",
    Name: "Marianna Heidenreich",
    Multiplier: "Multiplier 7",
    Value: 61264,
    MinimumValue: 48188,
    TargetDays: "TargetDays 7",
    Category_1: "Grocery",
    Category_2: "Electronics",
    ObservationSummary: "ObservationSummary 7",
    ComplianceInstruction: "ComplianceInstruction 7",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-05T06:26:07.964Z",
  },
  {
    ID: 8,
    ApplicationNumber: 93979,
    PermitNumber: 79343,
    PermitType: "invoice",
    Date_PermitIssued: "2021-04-03T20:41:53.558Z",
    RefNo: 19562,
    AssessmentNo: 83853,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Edmond Strosin",
    Builder: "Burnice Volkman Jr.",
    Category: "Handcrafted Cotton Fish",
    BCA: "Self-enabling",
    UseOfBuilding: true,
    ReasonForPermit: "Libyan Arab Jamahiriya quantifying",
    SiteAddress: "darron.biz",
    Locality: "CA",
    Owner: "Melvina Huel",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-01T03:14:45.177Z",
    Date_AllInspComplete: "2021-02-21T19:12:41.756Z",
    Date_DueToCommence: "2021-11-16T06:25:01.003Z",
    Date_Commenced: "2021-02-05T09:02:12.058Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "December",
    FileNo: 96230,
    ValuationNo: 62419,
    LegalDescription: "Australian Dollar sky blue Shoes",
    BuildingOfficer: "Central",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "71432056",
    StatDays: 99323,
    Date_Issued: "2021-04-08T08:22:01.612Z",
    Date_ReminderSent: "2021-06-23T08:30:22.252Z",
    PayingFee: 86482,
    CarryingOutWork: true,
    WorkProposed: "Personal Loan Account Incredible Metal Hat circuit",
    Address: "Suite 873",
    BuildingAppNo: 55553,
    BuildingPermitNo: 49525,
    Date_BuildingIssued: "March",
    Date_Commencing: "March",
    BondAmount: 794,
    BondReceived: "28456",
    BondReturned: 37948,
    Date_FirstInspection: "2021-08-20T22:31:20.951Z",
    Date_LastInspection: "2021-05-20T03:15:01.882Z",
    TargetDate: "2021-04-05T23:51:36.775Z",
    CILFee: 50550,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-07-11T17:14:23.970Z",
    InspectionType: "Fresh",
    Officer: "Auto Loan Account",
    Compliance: false,
    Reason: "Checking Account Innovative",
    FacilityRegion: "Wyoming",
    Description: "Legacy",
    DateTarget: "2021-08-23T09:50:47.510Z",
    Source: "Program",
    Location: "MI",
    RequestedBy: "90742427",
    ContactDetails: "785.303.5592",
    DueStatus: true,
    DateRecorded: "2021-05-31T22:18:09.127Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Managed object-oriented alliance",
    ActionOfficer: "Clothing",
    Coordinator: "Suite 796",
    DateWork: "2021-04-16T18:34:02.726Z",
    FileNumber: 53359,
    EnquiryRefNo: 23710,
    Event_ReferenceNoAdditional: 4544,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-04-17T10:57:17.485Z",
    ApplicantName: "Investment Account",
    Type: "Group",
    Risk: "Cambridgeshire North Carolina Specialist",
    DateDue: "2021-01-05T02:10:50.047Z",
    Register_Number: 37881,
    Contact: "Apt. 516",
    ReferenceNumber: 26901,
    Date_Complete: "2021-06-21T08:41:45.012Z",
    Date_Construction: "2021-01-28T11:55:15.089Z",
    BarrierStandard: "BarrierStandard 8",
    BarrierType: "BarrierType 8",
    Date_CertificateOfCompliance: "2021-05-16T08:57:18.714Z",
    Pool_RegisteredDate: "2021-12-04T19:32:17.382Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-29T10:25:41.971Z",
    Date_WorkIssued: "2021-08-13T14:31:52.287Z",
    Work_CarriedOut: "Strategist",
    AmountOS: 60437,
    Flag_RenewalNoticeSent: true,
    Outcome: 95677,
    Regulation: "open-source withdrawal focus group",
    Date_Due: "2021-03-11T23:04:07.128Z",
    Date_NextInspection: "2021-05-18T06:49:43.639Z",
    Date_Start: "2021-06-08T00:31:27.472Z",
    Date_Occurred: "2021-12-25T21:58:38.451Z",
    ContactName: "Mrs. Cecil Buckridge",
    CreatedDate: "2020-09-25T05:31:17.822Z",
    FeeType: "Gs",
    AmountIssued: 11896,
    AmountOutstanding: 36513,
    CostCode: 78609,
    AmountPaid: 24428,
    ReceiptNumber: 56278,
    ReceiptDate: "2021-10-02T19:08:27.399Z",
    ReceiptedBy: "Engineer",
    Method: "Corporate",
    InvoiceNumber: 28837,
    DebtorNumber: 97900,
    SecurePay_Reference: "MAD",
    Contact2: "Fermin.Gusikowski13@example.net",
    LevelOfEnforcement: "584.41",
    Number: 65708,
    DateOffence: "2021-05-05T12:42:15.562Z",
    RegisterType: "Manager",
    VicSmart: "VicSmart 8",
    ePlanningCategory: "ePlanningCategory 8",
    ePlanningLodgedBy: "ePlanningLodgedBy 8",
    Comments: "Comments 8",
    ApplicationCategory_Name: "Kids",
    ApplicationCategory_Description: "Music",
    Code: "82801-4700",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName: "We need to reboot the digital CSS program!",
    PublishDescription:
      "If we synthesize the microchip, we can get to the SCSI port through the auxiliary FTP application!",
    Title: "invoice Electronics",
    ConditionCode: "01647-0054",
    Condition: "and Sons",
    Name: "Hester Jerde",
    Multiplier: "Multiplier 8",
    Value: 44296,
    MinimumValue: 35730,
    TargetDays: "TargetDays 8",
    Category_1: "Home",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 8",
    ComplianceInstruction: "ComplianceInstruction 8",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-06T11:52:40.830Z",
  },
  {
    ID: 9,
    ApplicationNumber: 47300,
    PermitNumber: 35420,
    PermitType: "invoice",
    Date_PermitIssued: "2021-09-08T05:04:45.681Z",
    RefNo: 40436,
    AssessmentNo: 77125,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Ellen Jenkins",
    Builder: "Delfina Watsica",
    Category: "Unbranded Plastic Keyboard",
    BCA: "Home Loan Account",
    UseOfBuilding: true,
    ReasonForPermit: "Auto Loan Account invoice",
    SiteAddress: "duane.name",
    Locality: "FL",
    Owner: "Dustin Sauer",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-09-03T17:59:04.673Z",
    Date_AllInspComplete: "2021-06-04T00:05:46.920Z",
    Date_DueToCommence: "2021-03-13T21:16:04.055Z",
    Date_Commenced: "2021-01-03T17:57:28.175Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "January",
    FileNo: 46337,
    ValuationNo: 84367,
    LegalDescription: "override",
    BuildingOfficer: "District",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "79099748",
    StatDays: 32909,
    Date_Issued: "2021-04-09T02:02:26.661Z",
    Date_ReminderSent: "2021-11-26T07:10:23.197Z",
    PayingFee: 68547,
    CarryingOutWork: true,
    WorkProposed: "Computer Bedfordshire Organized",
    Address: "Apt. 877",
    BuildingAppNo: 87361,
    BuildingPermitNo: 71551,
    Date_BuildingIssued: "January",
    Date_Commencing: "January",
    BondAmount: 44605,
    BondReceived: "36399-0075",
    BondReturned: 44240,
    Date_FirstInspection: "2021-02-05T13:29:22.557Z",
    Date_LastInspection: "2021-05-08T11:09:59.899Z",
    TargetDate: "2021-08-28T15:11:46.651Z",
    CILFee: 57732,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-04-28T21:25:51.432Z",
    InspectionType: "Alaska",
    Officer: "Investment Account",
    Compliance: true,
    Reason: "Granite",
    FacilityRegion: "Massachusetts",
    Description: "Future",
    DateTarget: "2021-04-16T11:01:29.712Z",
    Source: "Intranet",
    Location: "OR",
    RequestedBy: "83121594",
    ContactDetails: "238.826.8843 x19144",
    DueStatus: false,
    DateRecorded: "2021-07-16T19:16:05.008Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Horizontal user-facing framework",
    ActionOfficer: "Games",
    Coordinator: "Suite 576",
    DateWork: "2021-05-18T08:23:18.259Z",
    FileNumber: 28527,
    EnquiryRefNo: 20790,
    Event_ReferenceNoAdditional: 2185,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-01-23T14:22:04.315Z",
    ApplicantName: "Auto Loan Account",
    Type: "LLC",
    Risk: "JBOD",
    DateDue: "2021-01-01T10:18:24.951Z",
    Register_Number: 95637,
    Contact: "Apt. 843",
    ReferenceNumber: 77471,
    Date_Complete: "2021-03-08T10:45:43.596Z",
    Date_Construction: "2021-03-22T23:34:49.970Z",
    BarrierStandard: "BarrierStandard 9",
    BarrierType: "BarrierType 9",
    Date_CertificateOfCompliance: "2021-01-14T23:53:55.172Z",
    Pool_RegisteredDate: "2021-05-13T18:56:28.685Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-01T10:17:52.629Z",
    Date_WorkIssued: "2021-09-03T17:59:26.604Z",
    Work_CarriedOut: "Associate",
    AmountOS: 40927,
    Flag_RenewalNoticeSent: false,
    Outcome: 78273,
    Regulation: "Awesome Associate",
    Date_Due: "2021-06-08T07:20:35.221Z",
    Date_NextInspection: "2021-09-22T23:28:54.957Z",
    Date_Start: "2021-04-05T05:59:30.379Z",
    Date_Occurred: "2021-01-06T07:24:09.486Z",
    ContactName: "Zoila Hickle",
    CreatedDate: "2020-08-06T20:32:38.669Z",
    FeeType: "﷼",
    AmountIssued: 51356,
    AmountOutstanding: 6562,
    CostCode: 99475,
    AmountPaid: 12532,
    ReceiptNumber: 22416,
    ReceiptDate: "2021-03-16T12:16:48.415Z",
    ReceiptedBy: "Associate",
    Method: "Future",
    InvoiceNumber: 39645,
    DebtorNumber: 78669,
    SecurePay_Reference: "JOD",
    Contact2: "Rory66@example.net",
    LevelOfEnforcement: "15.10",
    Number: 78831,
    DateOffence: "2021-07-13T16:41:22.302Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 9",
    ePlanningCategory: "ePlanningCategory 9",
    ePlanningLodgedBy: "ePlanningLodgedBy 9",
    Comments: "Comments 9",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Kids",
    Code: "51995",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "The JBOD pixel is down, navigate the primary program so we can copy the CSS monitor!",
    PublishDescription:
      "Try to program the THX driver, maybe it will compress the wireless capacitor!",
    Title: "Shirt impactful Solutions",
    ConditionCode: "04147",
    Condition: "Inc",
    Name: "Kiel Skiles",
    Multiplier: "Multiplier 9",
    Value: 46373,
    MinimumValue: 54493,
    TargetDays: "TargetDays 9",
    Category_1: "Clothing",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 9",
    ComplianceInstruction: "ComplianceInstruction 9",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-01-06T08:02:34.122Z",
  },
  {
    ID: 10,
    ApplicationNumber: 37418,
    PermitNumber: 4685,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-10-03T23:17:37.500Z",
    RefNo: 20791,
    AssessmentNo: 15916,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Sincere Pagac",
    Builder: "Giles Stamm",
    Category: "Rustic Steel Keyboard",
    BCA: "plum",
    UseOfBuilding: false,
    ReasonForPermit: "payment Practical",
    SiteAddress: "effie.org",
    Locality: "MI",
    Owner: "Mack Reynolds",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-22T06:11:57.073Z",
    Date_AllInspComplete: "2021-12-23T02:56:33.325Z",
    Date_DueToCommence: "2021-07-30T22:26:29.798Z",
    Date_Commenced: "2021-05-06T09:58:30.515Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "July",
    FileNo: 44702,
    ValuationNo: 31679,
    LegalDescription: "Agent",
    BuildingOfficer: "Human",
    Surveyor: "Investment Account",
    SurveyorNumber: "15800334",
    StatDays: 27024,
    Date_Issued: "2021-06-28T23:05:52.387Z",
    Date_ReminderSent: "2021-05-10T12:01:05.131Z",
    PayingFee: 5854,
    CarryingOutWork: false,
    WorkProposed: "COM quantifying Sleek",
    Address: "Suite 048",
    BuildingAppNo: 67003,
    BuildingPermitNo: 22313,
    Date_BuildingIssued: "January",
    Date_Commencing: "January",
    BondAmount: 45104,
    BondReceived: "32127-4803",
    BondReturned: 79043,
    Date_FirstInspection: "2021-12-12T11:33:22.074Z",
    Date_LastInspection: "2021-01-07T13:51:45.610Z",
    TargetDate: "2021-06-01T22:57:21.880Z",
    CILFee: 42920,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-12-01T20:39:15.198Z",
    InspectionType: "next-generation",
    Officer: "Auto Loan Account",
    Compliance: false,
    Reason: "Grass-roots mobile",
    FacilityRegion: "Nevada",
    Description: "Senior",
    DateTarget: "2021-12-01T01:18:32.007Z",
    Source: "Data",
    Location: "NM",
    RequestedBy: "35752896",
    ContactDetails: "1-944-749-4900",
    DueStatus: false,
    DateRecorded: "2021-09-26T16:16:37.542Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Universal empowering matrix",
    ActionOfficer: "Electronics",
    Coordinator: "Suite 444",
    DateWork: "2021-04-05T11:49:32.159Z",
    FileNumber: 19597,
    EnquiryRefNo: 67108,
    Event_ReferenceNoAdditional: 61646,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-03-12T12:51:07.541Z",
    ApplicantName: "Investment Account",
    Type: "LLC",
    Risk: "Movies Idaho",
    DateDue: "2021-05-07T09:00:12.571Z",
    Register_Number: 56928,
    Contact: "Apt. 215",
    ReferenceNumber: 21691,
    Date_Complete: "2021-09-13T05:53:43.111Z",
    Date_Construction: "2021-05-01T21:25:24.132Z",
    BarrierStandard: "BarrierStandard 10",
    BarrierType: "BarrierType 10",
    Date_CertificateOfCompliance: "2021-02-24T23:54:01.002Z",
    Pool_RegisteredDate: "2021-06-27T21:09:52.139Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-14T20:02:49.336Z",
    Date_WorkIssued: "2021-08-31T16:51:13.234Z",
    Work_CarriedOut: "Representative",
    AmountOS: 81629,
    Flag_RenewalNoticeSent: true,
    Outcome: 18663,
    Regulation: "Representative Object-based navigate",
    Date_Due: "2021-10-14T06:12:37.361Z",
    Date_NextInspection: "2021-07-03T00:23:49.099Z",
    Date_Start: "2021-03-21T17:39:05.161Z",
    Date_Occurred: "2021-07-23T01:45:48.949Z",
    ContactName: "Santina Schmitt",
    CreatedDate: "2020-02-29T13:08:59.632Z",
    FeeType: "kr",
    AmountIssued: 55838,
    AmountOutstanding: 3586,
    CostCode: 34919,
    AmountPaid: 82873,
    ReceiptNumber: 69003,
    ReceiptDate: "2021-03-07T02:11:29.602Z",
    ReceiptedBy: "Engineer",
    Method: "Central",
    InvoiceNumber: 19419,
    DebtorNumber: 16962,
    SecurePay_Reference: "LKR",
    Contact2: "Hassan_Berge@example.org",
    LevelOfEnforcement: "46.17",
    Number: 59101,
    DateOffence: "2021-03-14T01:04:39.503Z",
    RegisterType: "Coordinator",
    VicSmart: "VicSmart 10",
    ePlanningCategory: "ePlanningCategory 10",
    ePlanningLodgedBy: "ePlanningLodgedBy 10",
    Comments: "Comments 10",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Baby",
    Code: "73412",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "You can't bypass the circuit without parsing the haptic SAS pixel!",
    PublishDescription: "We need to connect the auxiliary SDD sensor!",
    Title: "Lane Communications Credit Card Account",
    ConditionCode: "79846-7186",
    Condition: "Inc",
    Name: "Allene Kertzmann",
    Multiplier: "Multiplier 10",
    Value: 67638,
    MinimumValue: 85898,
    TargetDays: "TargetDays 10",
    Category_1: "Outdoors",
    Category_2: "Kids",
    ObservationSummary: "ObservationSummary 10",
    ComplianceInstruction: "ComplianceInstruction 10",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-20T23:50:31.178Z",
  },
  {
    ID: 11,
    ApplicationNumber: 78815,
    PermitNumber: 85189,
    PermitType: "invoice",
    Date_PermitIssued: "2021-04-07T23:45:38.462Z",
    RefNo: 21755,
    AssessmentNo: 96628,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Josh Schmidt",
    Builder: "Troy Rempel",
    Category: "Unbranded Metal Bacon",
    BCA: "Investor",
    UseOfBuilding: false,
    ReasonForPermit: "Consultant bricks-and-clicks",
    SiteAddress: "tia.info",
    Locality: "AL",
    Owner: "Myrtis Hills",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-04-17T16:20:31.169Z",
    Date_AllInspComplete: "2021-07-13T07:23:33.180Z",
    Date_DueToCommence: "2021-11-21T14:55:28.538Z",
    Date_Commenced: "2021-10-12T08:21:19.292Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "November",
    FileNo: 57656,
    ValuationNo: 43520,
    LegalDescription: "Refined Wooden Bacon Concrete systemic",
    BuildingOfficer: "Future",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "17350689",
    StatDays: 82247,
    Date_Issued: "2021-02-27T15:00:36.200Z",
    Date_ReminderSent: "2021-02-19T01:48:54.595Z",
    PayingFee: 93094,
    CarryingOutWork: true,
    WorkProposed: "CSS Illinois override",
    Address: "Apt. 187",
    BuildingAppNo: 65075,
    BuildingPermitNo: 93512,
    Date_BuildingIssued: "March",
    Date_Commencing: "August",
    BondAmount: 45426,
    BondReceived: "77833-5987",
    BondReturned: 31320,
    Date_FirstInspection: "2021-08-26T09:56:30.919Z",
    Date_LastInspection: "2021-09-29T21:26:31.084Z",
    TargetDate: "2021-04-23T12:25:42.714Z",
    CILFee: 25306,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-06-03T13:52:09.082Z",
    InspectionType: "invoice",
    Officer: "Auto Loan Account",
    Compliance: false,
    Reason: "Concrete dynamic Croatian Kuna",
    FacilityRegion: "Kentucky",
    Description: "Legacy",
    DateTarget: "2021-03-09T01:29:40.706Z",
    Source: "Applications",
    Location: "WI",
    RequestedBy: "89895278",
    ContactDetails: "1-997-039-9942 x078",
    DueStatus: true,
    DateRecorded: "2021-08-03T12:29:22.909Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Synchronised multimedia capability",
    ActionOfficer: "Clothing",
    Coordinator: "Apt. 406",
    DateWork: "2021-10-05T14:49:39.034Z",
    FileNumber: 62325,
    EnquiryRefNo: 34015,
    Event_ReferenceNoAdditional: 50989,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-03-15T20:22:40.537Z",
    ApplicantName: "Investment Account",
    Type: "Group",
    Risk: "Rufiyaa",
    DateDue: "2021-11-23T12:50:43.569Z",
    Register_Number: 83098,
    Contact: "Apt. 561",
    ReferenceNumber: 1221,
    Date_Complete: "2021-10-17T05:37:50.280Z",
    Date_Construction: "2021-12-08T13:41:56.022Z",
    BarrierStandard: "BarrierStandard 11",
    BarrierType: "BarrierType 11",
    Date_CertificateOfCompliance: "2021-12-15T16:48:20.010Z",
    Pool_RegisteredDate: "2021-11-25T04:31:31.887Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-10T09:06:29.268Z",
    Date_WorkIssued: "2021-06-28T01:18:56.937Z",
    Work_CarriedOut: "Assistant",
    AmountOS: 99653,
    Flag_RenewalNoticeSent: true,
    Outcome: 25028,
    Regulation: "Concrete",
    Date_Due: "2021-12-13T16:16:30.743Z",
    Date_NextInspection: "2021-01-25T20:55:27.282Z",
    Date_Start: "2021-05-30T09:34:55.918Z",
    Date_Occurred: "2021-09-26T00:09:56.552Z",
    ContactName: "Dr. Bryana West",
    CreatedDate: "2020-09-14T01:16:05.915Z",
    FeeType: "₭",
    AmountIssued: 70134,
    AmountOutstanding: 39137,
    CostCode: 1881,
    AmountPaid: 28957,
    ReceiptNumber: 36487,
    ReceiptDate: "2021-01-11T13:01:17.180Z",
    ReceiptedBy: "Orchestrator",
    Method: "Human",
    InvoiceNumber: 46126,
    DebtorNumber: 1711,
    SecurePay_Reference: "XPD",
    Contact2: "Eldora_Schulist@example.com",
    LevelOfEnforcement: "309.25",
    Number: 7086,
    DateOffence: "2021-12-25T23:30:55.726Z",
    RegisterType: "Administrator",
    VicSmart: "VicSmart 11",
    ePlanningCategory: "ePlanningCategory 11",
    ePlanningLodgedBy: "ePlanningLodgedBy 11",
    Comments: "Comments 11",
    ApplicationCategory_Name: "Sports",
    ApplicationCategory_Description: "Grocery",
    Code: "22532-0073",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "parsing the capacitor won't do anything, we need to parse the cross-platform PNG sensor!",
    PublishDescription:
      "You can't parse the program without quantifying the wireless PNG bus!",
    Title: "Fresh",
    ConditionCode: "64432",
    Condition: "and Sons",
    Name: "Susie Rosenbaum",
    Multiplier: "Multiplier 11",
    Value: 41200,
    MinimumValue: 14152,
    TargetDays: "TargetDays 11",
    Category_1: "Computers",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 11",
    ComplianceInstruction: "ComplianceInstruction 11",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-14T06:51:25.904Z",
  },
  {
    ID: 12,
    ApplicationNumber: 6429,
    PermitNumber: 6599,
    PermitType: "invoice",
    Date_PermitIssued: "2021-09-05T16:45:59.472Z",
    RefNo: 43394,
    AssessmentNo: 83023,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Kayden Mills",
    Builder: "Rocky McKenzie",
    Category: "Refined Steel Soap",
    BCA: "Research",
    UseOfBuilding: false,
    ReasonForPermit: "vertical synthesizing",
    SiteAddress: "mark.biz",
    Locality: "NE",
    Owner: "Abelardo Jaskolski IV",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-12-28T01:29:40.465Z",
    Date_AllInspComplete: "2021-04-02T19:41:21.185Z",
    Date_DueToCommence: "2021-08-26T13:13:43.764Z",
    Date_Commenced: "2021-04-27T12:30:48.750Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "October",
    FileNo: 65523,
    ValuationNo: 84849,
    LegalDescription: "Kids",
    BuildingOfficer: "District",
    Surveyor: "Checking Account",
    SurveyorNumber: "09312495",
    StatDays: 46137,
    Date_Issued: "2021-11-07T03:58:43.100Z",
    Date_ReminderSent: "2021-10-22T07:49:00.246Z",
    PayingFee: 61927,
    CarryingOutWork: true,
    WorkProposed: "AI Home Loan Account Gorgeous",
    Address: "Suite 703",
    BuildingAppNo: 94376,
    BuildingPermitNo: 87360,
    Date_BuildingIssued: "July",
    Date_Commencing: "February",
    BondAmount: 83870,
    BondReceived: "32162-5514",
    BondReturned: 8053,
    Date_FirstInspection: "2021-07-27T11:02:27.559Z",
    Date_LastInspection: "2021-05-22T21:59:32.675Z",
    TargetDate: "2021-04-01T09:28:52.273Z",
    CILFee: 43178,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-04-07T00:31:15.045Z",
    InspectionType: "Bedfordshire",
    Officer: "Savings Account",
    Compliance: false,
    Reason: "Towels",
    FacilityRegion: "New Mexico",
    Description: "Central",
    DateTarget: "2021-02-14T23:30:50.632Z",
    Source: "Accountability",
    Location: "WY",
    RequestedBy: "05342200",
    ContactDetails: "492-556-3623",
    DueStatus: true,
    DateRecorded: "2021-06-09T13:18:40.336Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Horizontal zero administration groupware",
    ActionOfficer: "Music",
    Coordinator: "Suite 625",
    DateWork: "2021-04-30T09:38:40.302Z",
    FileNumber: 71028,
    EnquiryRefNo: 58150,
    Event_ReferenceNoAdditional: 73044,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-03-07T03:49:04.093Z",
    ApplicantName: "Home Loan Account",
    Type: "Group",
    Risk: "calculating",
    DateDue: "2021-09-19T12:38:37.138Z",
    Register_Number: 97895,
    Contact: "Suite 456",
    ReferenceNumber: 23786,
    Date_Complete: "2021-05-18T21:00:04.726Z",
    Date_Construction: "2021-02-08T07:09:09.449Z",
    BarrierStandard: "BarrierStandard 12",
    BarrierType: "BarrierType 12",
    Date_CertificateOfCompliance: "2021-02-20T13:27:28.442Z",
    Pool_RegisteredDate: "2021-07-17T12:02:29.911Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-10T20:50:28.892Z",
    Date_WorkIssued: "2021-08-20T06:03:57.753Z",
    Work_CarriedOut: "Supervisor",
    AmountOS: 93037,
    Flag_RenewalNoticeSent: true,
    Outcome: 69677,
    Regulation: "Garden",
    Date_Due: "2021-08-15T11:44:02.209Z",
    Date_NextInspection: "2021-12-14T06:34:33.933Z",
    Date_Start: "2021-08-14T12:31:34.818Z",
    Date_Occurred: "2021-04-04T12:44:59.054Z",
    ContactName: "Fredy Strosin DDS",
    CreatedDate: "2020-03-30T20:36:17.223Z",
    FeeType: "﷼",
    AmountIssued: 17989,
    AmountOutstanding: 80512,
    CostCode: 1730,
    AmountPaid: 30210,
    ReceiptNumber: 39561,
    ReceiptDate: "2021-01-13T09:59:44.346Z",
    ReceiptedBy: "Administrator",
    Method: "Corporate",
    InvoiceNumber: 99445,
    DebtorNumber: 85199,
    SecurePay_Reference: "GTQ",
    Contact2: "Dulce.Senger@example.org",
    LevelOfEnforcement: "754.93",
    Number: 92277,
    DateOffence: "2021-07-18T18:05:59.801Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 12",
    ePlanningCategory: "ePlanningCategory 12",
    ePlanningLodgedBy: "ePlanningLodgedBy 12",
    Comments: "Comments 12",
    ApplicationCategory_Name: "Toys",
    ApplicationCategory_Description: "Books",
    Code: "81188-1422",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "I'll compress the redundant RSS port, that should hard drive the JSON bus!",
    PublishDescription: "We need to parse the wireless CSS microchip!",
    Title: "Iceland Krona invoice",
    ConditionCode: "80104",
    Condition: "and Sons",
    Name: "Rogelio Nader",
    Multiplier: "Multiplier 12",
    Value: 7166,
    MinimumValue: 53433,
    TargetDays: "TargetDays 12",
    Category_1: "Games",
    Category_2: "Electronics",
    ObservationSummary: "ObservationSummary 12",
    ComplianceInstruction: "ComplianceInstruction 12",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-09-02T00:42:10.000Z",
  },
  {
    ID: 13,
    ApplicationNumber: 29824,
    PermitNumber: 30177,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-05-02T18:14:25.128Z",
    RefNo: 63146,
    AssessmentNo: 82555,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Mr. Guy Ullrich",
    Builder: "Aron Witting Jr.",
    Category: "Ergonomic Fresh Bacon",
    BCA: "red",
    UseOfBuilding: true,
    ReasonForPermit: "intermediate Programmable Right-sized",
    SiteAddress: "olaf.net",
    Locality: "AL",
    Owner: "Monroe Kuhic",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-09-15T17:24:00.556Z",
    Date_AllInspComplete: "2021-06-27T03:54:51.855Z",
    Date_DueToCommence: "2021-02-11T08:36:22.152Z",
    Date_Commenced: "2021-03-20T02:39:39.845Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "August",
    FileNo: 16011,
    ValuationNo: 95010,
    LegalDescription: "bus out-of-the-box Wooden",
    BuildingOfficer: "National",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "34351011",
    StatDays: 18947,
    Date_Issued: "2021-05-17T06:28:59.165Z",
    Date_ReminderSent: "2021-11-24T02:41:42.825Z",
    PayingFee: 72155,
    CarryingOutWork: true,
    WorkProposed: "Public-key Mobility Senior",
    Address: "Suite 629",
    BuildingAppNo: 43458,
    BuildingPermitNo: 68516,
    Date_BuildingIssued: "March",
    Date_Commencing: "February",
    BondAmount: 34459,
    BondReceived: "44533",
    BondReturned: 49073,
    Date_FirstInspection: "2021-12-27T21:18:14.079Z",
    Date_LastInspection: "2021-08-11T07:50:52.186Z",
    TargetDate: "2021-06-16T04:48:23.563Z",
    CILFee: 57673,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-09-04T04:15:54.103Z",
    InspectionType: "visualize",
    Officer: "Money Market Account",
    Compliance: true,
    Reason: "Moroccan Dirham XML Burg",
    FacilityRegion: "Maine",
    Description: "Forward",
    DateTarget: "2021-08-19T16:22:33.612Z",
    Source: "Integration",
    Location: "KS",
    RequestedBy: "10623143",
    ContactDetails: "(611) 588-8256 x6205",
    DueStatus: false,
    DateRecorded: "2021-11-15T15:00:14.212Z",
    RecordedBy: "Checking Account",
    OrgStructure: "User-friendly neutral neural-net",
    ActionOfficer: "Movies",
    Coordinator: "Apt. 893",
    DateWork: "2021-03-31T21:43:21.684Z",
    FileNumber: 49213,
    EnquiryRefNo: 69866,
    Event_ReferenceNoAdditional: 77110,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-06-15T10:08:39.986Z",
    ApplicantName: "Personal Loan Account",
    Type: "Inc",
    Risk: "Practical National",
    DateDue: "2021-02-26T14:55:54.343Z",
    Register_Number: 6653,
    Contact: "Suite 267",
    ReferenceNumber: 61772,
    Date_Complete: "2021-06-03T22:07:52.801Z",
    Date_Construction: "2021-04-19T21:37:38.837Z",
    BarrierStandard: "BarrierStandard 13",
    BarrierType: "BarrierType 13",
    Date_CertificateOfCompliance: "2021-11-23T17:49:40.031Z",
    Pool_RegisteredDate: "2021-02-15T09:25:46.940Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-12T17:53:18.173Z",
    Date_WorkIssued: "2021-07-27T09:02:10.813Z",
    Work_CarriedOut: "Architect",
    AmountOS: 10184,
    Flag_RenewalNoticeSent: false,
    Outcome: 48516,
    Regulation: "European Unit of Account 17(E.U.A.-17) Clothing",
    Date_Due: "2021-11-14T06:45:34.708Z",
    Date_NextInspection: "2021-01-17T14:09:57.877Z",
    Date_Start: "2021-01-04T07:01:49.461Z",
    Date_Occurred: "2021-04-13T20:38:05.159Z",
    ContactName: "Brad Kulas",
    CreatedDate: "2020-01-18T10:28:07.409Z",
    FeeType: "₩",
    AmountIssued: 60311,
    AmountOutstanding: 93575,
    CostCode: 94073,
    AmountPaid: 33701,
    ReceiptNumber: 58334,
    ReceiptDate: "2021-12-15T10:57:08.489Z",
    ReceiptedBy: "Developer",
    Method: "Direct",
    InvoiceNumber: 63718,
    DebtorNumber: 66575,
    SecurePay_Reference: "DZD",
    Contact2: "Leonor_Dicki@example.com",
    LevelOfEnforcement: "941.82",
    Number: 34981,
    DateOffence: "2021-01-25T01:41:34.549Z",
    RegisterType: "Executive",
    VicSmart: "VicSmart 13",
    ePlanningCategory: "ePlanningCategory 13",
    ePlanningLodgedBy: "ePlanningLodgedBy 13",
    Comments: "Comments 13",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Clothing",
    Code: "94963",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "quantifying the bandwidth won't do anything, we need to connect the multi-byte AGP transmitter!",
    PublishDescription:
      "The SDD hard drive is down, navigate the auxiliary program so we can navigate the SMS bus!",
    Title: "Concrete",
    ConditionCode: "24725-3415",
    Condition: "Inc",
    Name: "Joanie Turner III",
    Multiplier: "Multiplier 13",
    Value: 72252,
    MinimumValue: 37188,
    TargetDays: "TargetDays 13",
    Category_1: "Industrial",
    Category_2: "Books",
    ObservationSummary: "ObservationSummary 13",
    ComplianceInstruction: "ComplianceInstruction 13",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-01T06:32:23.323Z",
  },
  {
    ID: 14,
    ApplicationNumber: 43162,
    PermitNumber: 95042,
    PermitType: "invoice",
    Date_PermitIssued: "2021-07-29T00:50:59.161Z",
    RefNo: 89886,
    AssessmentNo: 5313,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Theresia DuBuque",
    Builder: "Melvina Willms",
    Category: "Practical Steel Towels",
    BCA: "Liberian Dollar",
    UseOfBuilding: true,
    ReasonForPermit: "Borders",
    SiteAddress: "lucie.com",
    Locality: "OK",
    Owner: "Cathrine Homenick",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-01-03T05:22:50.913Z",
    Date_AllInspComplete: "2021-01-22T02:48:15.131Z",
    Date_DueToCommence: "2021-12-05T03:12:30.790Z",
    Date_Commenced: "2021-08-26T07:26:24.593Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "August",
    FileNo: 61717,
    ValuationNo: 95265,
    LegalDescription: "impactful",
    BuildingOfficer: "Global",
    Surveyor: "Money Market Account",
    SurveyorNumber: "21491723",
    StatDays: 78662,
    Date_Issued: "2021-06-17T19:51:32.041Z",
    Date_ReminderSent: "2021-12-07T08:13:22.463Z",
    PayingFee: 85558,
    CarryingOutWork: false,
    WorkProposed: "payment Small Soft Chips",
    Address: "Apt. 660",
    BuildingAppNo: 40561,
    BuildingPermitNo: 37106,
    Date_BuildingIssued: "September",
    Date_Commencing: "March",
    BondAmount: 14229,
    BondReceived: "84931",
    BondReturned: 14864,
    Date_FirstInspection: "2021-07-26T13:11:39.694Z",
    Date_LastInspection: "2021-10-17T19:34:07.870Z",
    TargetDate: "2021-07-23T03:03:40.402Z",
    CILFee: 40457,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-05-27T22:55:49.757Z",
    InspectionType: "Soap",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "Unbranded",
    FacilityRegion: "Massachusetts",
    Description: "Principal",
    DateTarget: "2021-04-22T03:48:02.021Z",
    Source: "Infrastructure",
    Location: "LA",
    RequestedBy: "52511357",
    ContactDetails: "(712) 741-7095 x1368",
    DueStatus: false,
    DateRecorded: "2021-04-28T05:17:31.498Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Stand-alone value-added implementation",
    ActionOfficer: "Baby",
    Coordinator: "Suite 209",
    DateWork: "2021-11-15T21:00:59.259Z",
    FileNumber: 44201,
    EnquiryRefNo: 89551,
    Event_ReferenceNoAdditional: 33482,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-06-07T09:03:25.455Z",
    ApplicantName: "Checking Account",
    Type: "and Sons",
    Risk: "Dominican Peso Ergonomic Concrete Pizza",
    DateDue: "2021-06-08T07:16:13.612Z",
    Register_Number: 55704,
    Contact: "Suite 124",
    ReferenceNumber: 51861,
    Date_Complete: "2021-06-25T00:01:51.439Z",
    Date_Construction: "2021-04-12T00:03:01.226Z",
    BarrierStandard: "BarrierStandard 14",
    BarrierType: "BarrierType 14",
    Date_CertificateOfCompliance: "2021-12-14T19:56:03.416Z",
    Pool_RegisteredDate: "2021-09-24T03:47:51.688Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-05T02:26:44.091Z",
    Date_WorkIssued: "2021-07-02T10:30:38.720Z",
    Work_CarriedOut: "Designer",
    AmountOS: 70886,
    Flag_RenewalNoticeSent: false,
    Outcome: 81714,
    Regulation: "input",
    Date_Due: "2021-03-20T08:53:28.285Z",
    Date_NextInspection: "2021-06-05T21:38:47.129Z",
    Date_Start: "2021-09-14T17:42:32.922Z",
    Date_Occurred: "2021-08-20T11:18:53.409Z",
    ContactName: "Corene Ankunding",
    CreatedDate: "2020-01-12T13:45:07.394Z",
    FeeType: "₡",
    AmountIssued: 67979,
    AmountOutstanding: 31915,
    CostCode: 2301,
    AmountPaid: 51697,
    ReceiptNumber: 45019,
    ReceiptDate: "2021-01-15T18:38:30.680Z",
    ReceiptedBy: "Engineer",
    Method: "Dynamic",
    InvoiceNumber: 91433,
    DebtorNumber: 98046,
    SecurePay_Reference: "XBC",
    Contact2: "Donnell.Schneider51@example.com",
    LevelOfEnforcement: "645.81",
    Number: 60576,
    DateOffence: "2021-04-14T18:08:32.833Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 14",
    ePlanningCategory: "ePlanningCategory 14",
    ePlanningLodgedBy: "ePlanningLodgedBy 14",
    Comments: "Comments 14",
    ApplicationCategory_Name: "Games",
    ApplicationCategory_Description: "Beauty",
    Code: "08554-1834",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "Try to back up the SCSI transmitter, maybe it will transmit the haptic application!",
    PublishDescription:
      "Try to parse the FTP capacitor, maybe it will quantify the primary matrix!",
    Title: "interface olive leading edge",
    ConditionCode: "48407-4548",
    Condition: "and Sons",
    Name: "Alec Dibbert",
    Multiplier: "Multiplier 14",
    Value: 54722,
    MinimumValue: 90266,
    TargetDays: "TargetDays 14",
    Category_1: "Home",
    Category_2: "Baby",
    ObservationSummary: "ObservationSummary 14",
    ComplianceInstruction: "ComplianceInstruction 14",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-01-06T19:19:08.718Z",
  },
  {
    ID: 15,
    ApplicationNumber: 10416,
    PermitNumber: 35997,
    PermitType: "payment",
    Date_PermitIssued: "2021-07-04T15:42:43.301Z",
    RefNo: 58845,
    AssessmentNo: 52015,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Marcelle Corwin",
    Builder: "Kaden Feil",
    Category: "Handcrafted Plastic Pants",
    BCA: "Concrete",
    UseOfBuilding: true,
    ReasonForPermit: "withdrawal",
    SiteAddress: "keith.org",
    Locality: "NC",
    Owner: "Tyreek Lemke",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-12-21T05:43:34.946Z",
    Date_AllInspComplete: "2021-02-26T10:01:48.374Z",
    Date_DueToCommence: "2021-09-09T10:32:54.858Z",
    Date_Commenced: "2021-02-11T21:38:13.427Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "September",
    FileNo: 97107,
    ValuationNo: 78075,
    LegalDescription: "Gloves reintermediate alliance",
    BuildingOfficer: "Principal",
    Surveyor: "Money Market Account",
    SurveyorNumber: "33357099",
    StatDays: 61867,
    Date_Issued: "2021-05-19T17:35:33.657Z",
    Date_ReminderSent: "2021-06-04T00:31:58.685Z",
    PayingFee: 4363,
    CarryingOutWork: false,
    WorkProposed: "Michigan Sausages Executive",
    Address: "Suite 490",
    BuildingAppNo: 9864,
    BuildingPermitNo: 48548,
    Date_BuildingIssued: "July",
    Date_Commencing: "September",
    BondAmount: 57331,
    BondReceived: "52361-3703",
    BondReturned: 56866,
    Date_FirstInspection: "2021-12-03T19:08:31.878Z",
    Date_LastInspection: "2021-12-31T01:33:59.494Z",
    TargetDate: "2021-03-11T17:12:47.629Z",
    CILFee: 46239,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-05-07T11:10:54.304Z",
    InspectionType: "mobile",
    Officer: "Savings Account",
    Compliance: false,
    Reason: "Ville",
    FacilityRegion: "Indiana",
    Description: "Product",
    DateTarget: "2021-10-06T05:16:41.972Z",
    Source: "Marketing",
    Location: "WY",
    RequestedBy: "90238411",
    ContactDetails: "780-742-7396 x578",
    DueStatus: false,
    DateRecorded: "2021-10-11T14:08:50.278Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Managed contextually-based emulation",
    ActionOfficer: "Health",
    Coordinator: "Apt. 790",
    DateWork: "2021-02-21T15:10:09.908Z",
    FileNumber: 4101,
    EnquiryRefNo: 82953,
    Event_ReferenceNoAdditional: 43008,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-08-13T14:47:46.490Z",
    ApplicantName: "Personal Loan Account",
    Type: "Group",
    Risk: "Hat Small",
    DateDue: "2021-03-21T09:55:20.584Z",
    Register_Number: 71913,
    Contact: "Suite 003",
    ReferenceNumber: 55177,
    Date_Complete: "2021-12-18T15:59:08.138Z",
    Date_Construction: "2021-12-06T04:26:57.465Z",
    BarrierStandard: "BarrierStandard 15",
    BarrierType: "BarrierType 15",
    Date_CertificateOfCompliance: "2021-05-09T00:15:35.240Z",
    Pool_RegisteredDate: "2021-04-04T07:43:02.816Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-26T18:41:29.680Z",
    Date_WorkIssued: "2021-03-27T22:37:14.722Z",
    Work_CarriedOut: "Administrator",
    AmountOS: 34321,
    Flag_RenewalNoticeSent: true,
    Outcome: 7550,
    Regulation: "Pennsylvania payment",
    Date_Due: "2021-08-28T02:16:08.047Z",
    Date_NextInspection: "2021-04-26T22:33:51.268Z",
    Date_Start: "2021-08-23T00:38:45.702Z",
    Date_Occurred: "2021-05-24T02:59:54.277Z",
    ContactName: "Colleen Collier",
    CreatedDate: "2020-07-14T21:29:23.479Z",
    FeeType: "Php",
    AmountIssued: 23824,
    AmountOutstanding: 95784,
    CostCode: 39142,
    AmountPaid: 77482,
    ReceiptNumber: 13005,
    ReceiptDate: "2021-01-19T12:39:43.576Z",
    ReceiptedBy: "Manager",
    Method: "Internal",
    InvoiceNumber: 51784,
    DebtorNumber: 95651,
    SecurePay_Reference: "AUD",
    Contact2: "Kitty21@example.org",
    LevelOfEnforcement: "606.49",
    Number: 69447,
    DateOffence: "2021-11-10T14:18:44.521Z",
    RegisterType: "Agent",
    VicSmart: "VicSmart 15",
    ePlanningCategory: "ePlanningCategory 15",
    ePlanningLodgedBy: "ePlanningLodgedBy 15",
    Comments: "Comments 15",
    ApplicationCategory_Name: "Beauty",
    ApplicationCategory_Description: "Clothing",
    Code: "25138",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Try to copy the JBOD bandwidth, maybe it will override the redundant microchip!",
    PublishDescription:
      "If we hack the card, we can get to the PCI pixel through the haptic ADP sensor!",
    Title: "Spurs Mountains revolutionary",
    ConditionCode: "17870",
    Condition: "Inc",
    Name: "Ms. Dolly Schumm",
    Multiplier: "Multiplier 15",
    Value: 43365,
    MinimumValue: 85844,
    TargetDays: "TargetDays 15",
    Category_1: "Music",
    Category_2: "Books",
    ObservationSummary: "ObservationSummary 15",
    ComplianceInstruction: "ComplianceInstruction 15",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-13T04:54:30.807Z",
  },
  {
    ID: 16,
    ApplicationNumber: 51285,
    PermitNumber: 87747,
    PermitType: "invoice",
    Date_PermitIssued: "2021-12-11T08:36:53.190Z",
    RefNo: 28166,
    AssessmentNo: 17271,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Mr. Anissa Mosciski",
    Builder: "Miss Pascale Rolfson",
    Category: "Practical Cotton Pizza",
    BCA: "PCI",
    UseOfBuilding: false,
    ReasonForPermit: "Generic Beauty",
    SiteAddress: "melvina.name",
    Locality: "AK",
    Owner: "Lilly Swift Sr.",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-04-03T09:12:30.021Z",
    Date_AllInspComplete: "2021-12-09T08:55:28.033Z",
    Date_DueToCommence: "2021-10-11T01:37:45.230Z",
    Date_Commenced: "2021-11-03T22:45:30.180Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "April",
    FileNo: 36707,
    ValuationNo: 15921,
    LegalDescription: "olive Denmark wireless",
    BuildingOfficer: "Corporate",
    Surveyor: "Investment Account",
    SurveyorNumber: "18084901",
    StatDays: 87235,
    Date_Issued: "2021-08-30T09:46:27.328Z",
    Date_ReminderSent: "2021-09-20T13:03:20.272Z",
    PayingFee: 24441,
    CarryingOutWork: false,
    WorkProposed: "Nicaragua",
    Address: "Suite 582",
    BuildingAppNo: 58219,
    BuildingPermitNo: 11887,
    Date_BuildingIssued: "March",
    Date_Commencing: "April",
    BondAmount: 74501,
    BondReceived: "77801",
    BondReturned: 98972,
    Date_FirstInspection: "2021-02-19T13:27:50.910Z",
    Date_LastInspection: "2021-07-30T15:35:52.780Z",
    TargetDate: "2021-07-16T13:44:27.765Z",
    CILFee: 10319,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-01-12T08:04:59.049Z",
    InspectionType: "navigate",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "Avon",
    FacilityRegion: "Indiana",
    Description: "Customer",
    DateTarget: "2021-05-28T03:56:27.201Z",
    Source: "Directives",
    Location: "VT",
    RequestedBy: "50102333",
    ContactDetails: "998.705.3706",
    DueStatus: false,
    DateRecorded: "2021-05-20T08:37:15.244Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Sharable 24 hour open architecture",
    ActionOfficer: "Computers",
    Coordinator: "Apt. 452",
    DateWork: "2021-11-05T06:31:14.769Z",
    FileNumber: 47935,
    EnquiryRefNo: 26498,
    Event_ReferenceNoAdditional: 95102,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-04-11T16:48:51.528Z",
    ApplicantName: "Credit Card Account",
    Type: "and Sons",
    Risk: "mobile Up-sized",
    DateDue: "2021-09-05T17:48:21.395Z",
    Register_Number: 40114,
    Contact: "Suite 069",
    ReferenceNumber: 39768,
    Date_Complete: "2021-05-10T00:17:50.293Z",
    Date_Construction: "2021-04-26T09:38:35.890Z",
    BarrierStandard: "BarrierStandard 16",
    BarrierType: "BarrierType 16",
    Date_CertificateOfCompliance: "2021-08-21T01:19:56.237Z",
    Pool_RegisteredDate: "2021-02-27T14:27:10.721Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-06T21:42:54.661Z",
    Date_WorkIssued: "2021-08-14T10:31:06.631Z",
    Work_CarriedOut: "Planner",
    AmountOS: 93150,
    Flag_RenewalNoticeSent: false,
    Outcome: 86100,
    Regulation: "zero tolerance Technician",
    Date_Due: "2021-08-27T16:10:30.922Z",
    Date_NextInspection: "2021-12-08T11:07:42.603Z",
    Date_Start: "2021-11-02T06:14:58.785Z",
    Date_Occurred: "2021-09-07T09:52:28.725Z",
    ContactName: "Morton Schuster",
    CreatedDate: "2020-10-04T11:51:37.496Z",
    FeeType: "P",
    AmountIssued: 99673,
    AmountOutstanding: 30863,
    CostCode: 22682,
    AmountPaid: 91113,
    ReceiptNumber: 18995,
    ReceiptDate: "2021-01-25T13:37:51.601Z",
    ReceiptedBy: "Supervisor",
    Method: "Product",
    InvoiceNumber: 23632,
    DebtorNumber: 46614,
    SecurePay_Reference: "KHR",
    Contact2: "Mireya25@example.net",
    LevelOfEnforcement: "537.43",
    Number: 53394,
    DateOffence: "2021-05-01T06:35:29.044Z",
    RegisterType: "Agent",
    VicSmart: "VicSmart 16",
    ePlanningCategory: "ePlanningCategory 16",
    ePlanningLodgedBy: "ePlanningLodgedBy 16",
    Comments: "Comments 16",
    ApplicationCategory_Name: "Baby",
    ApplicationCategory_Description: "Tools",
    Code: "81754",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Try to quantify the ADP matrix, maybe it will override the mobile monitor!",
    PublishDescription:
      "The THX protocol is down, transmit the online interface so we can compress the CSS array!",
    Title: "Internal quantifying",
    ConditionCode: "81997",
    Condition: "and Sons",
    Name: "Rita Gusikowski",
    Multiplier: "Multiplier 16",
    Value: 13353,
    MinimumValue: 28541,
    TargetDays: "TargetDays 16",
    Category_1: "Automotive",
    Category_2: "Toys",
    ObservationSummary: "ObservationSummary 16",
    ComplianceInstruction: "ComplianceInstruction 16",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-11T11:44:14.662Z",
  },
  {
    ID: 17,
    ApplicationNumber: 72760,
    PermitNumber: 44195,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-01-02T12:35:47.649Z",
    RefNo: 50813,
    AssessmentNo: 78849,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Dedric Schultz DDS",
    Builder: "Felton Walter",
    Category: "Incredible Frozen Bacon",
    BCA: "Product",
    UseOfBuilding: true,
    ReasonForPermit: "Bike override",
    SiteAddress: "devyn.com",
    Locality: "IA",
    Owner: "Maximo Wolf",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-12-28T05:36:59.069Z",
    Date_AllInspComplete: "2021-05-02T06:00:08.620Z",
    Date_DueToCommence: "2021-03-09T17:31:35.804Z",
    Date_Commenced: "2021-02-13T04:46:16.701Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "October",
    FileNo: 86024,
    ValuationNo: 20715,
    LegalDescription: "Money Market Account bypassing",
    BuildingOfficer: "Central",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "73941861",
    StatDays: 86706,
    Date_Issued: "2021-05-05T23:11:30.687Z",
    Date_ReminderSent: "2021-01-18T07:54:39.293Z",
    PayingFee: 22381,
    CarryingOutWork: true,
    WorkProposed: "International networks Gloves",
    Address: "Apt. 294",
    BuildingAppNo: 55414,
    BuildingPermitNo: 43074,
    Date_BuildingIssued: "February",
    Date_Commencing: "January",
    BondAmount: 94597,
    BondReceived: "28972",
    BondReturned: 91380,
    Date_FirstInspection: "2021-11-14T05:26:35.060Z",
    Date_LastInspection: "2021-12-12T15:57:13.666Z",
    TargetDate: "2021-12-24T03:09:46.192Z",
    CILFee: 176,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-12-24T20:44:21.662Z",
    InspectionType: "program",
    Officer: "Savings Account",
    Compliance: false,
    Reason: "deposit Cambridgeshire compressing",
    FacilityRegion: "Washington",
    Description: "District",
    DateTarget: "2021-01-12T08:40:09.615Z",
    Source: "Assurance",
    Location: "WI",
    RequestedBy: "70753933",
    ContactDetails: "985-701-8952 x235",
    DueStatus: false,
    DateRecorded: "2021-03-24T10:30:10.644Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Self-enabling stable collaboration",
    ActionOfficer: "Baby",
    Coordinator: "Suite 343",
    DateWork: "2021-02-21T17:32:22.033Z",
    FileNumber: 50232,
    EnquiryRefNo: 5634,
    Event_ReferenceNoAdditional: 55120,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-09-11T05:58:51.385Z",
    ApplicantName: "Checking Account",
    Type: "LLC",
    Risk: "Product",
    DateDue: "2021-05-15T13:55:19.196Z",
    Register_Number: 44536,
    Contact: "Apt. 793",
    ReferenceNumber: 10864,
    Date_Complete: "2021-06-09T17:12:53.346Z",
    Date_Construction: "2021-08-08T21:40:28.466Z",
    BarrierStandard: "BarrierStandard 17",
    BarrierType: "BarrierType 17",
    Date_CertificateOfCompliance: "2021-12-15T23:03:09.584Z",
    Pool_RegisteredDate: "2021-02-15T18:14:38.577Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-08T17:39:56.288Z",
    Date_WorkIssued: "2021-05-26T07:28:19.048Z",
    Work_CarriedOut: "Architect",
    AmountOS: 10707,
    Flag_RenewalNoticeSent: false,
    Outcome: 22055,
    Regulation: "motivating Zloty",
    Date_Due: "2021-09-24T13:43:17.960Z",
    Date_NextInspection: "2021-12-14T22:40:33.335Z",
    Date_Start: "2021-12-02T04:14:05.945Z",
    Date_Occurred: "2021-01-03T12:26:48.090Z",
    ContactName: "Dr. Zoila Greenfelder",
    CreatedDate: "2020-04-04T04:29:22.313Z",
    FeeType: "kr",
    AmountIssued: 6170,
    AmountOutstanding: 42134,
    CostCode: 41488,
    AmountPaid: 23352,
    ReceiptNumber: 20338,
    ReceiptDate: "2021-09-29T17:45:10.737Z",
    ReceiptedBy: "Manager",
    Method: "Direct",
    InvoiceNumber: 51540,
    DebtorNumber: 2346,
    SecurePay_Reference: "XAF",
    Contact2: "Dangelo13@example.net",
    LevelOfEnforcement: "29.96",
    Number: 90994,
    DateOffence: "2021-03-19T20:15:49.733Z",
    RegisterType: "Designer",
    VicSmart: "VicSmart 17",
    ePlanningCategory: "ePlanningCategory 17",
    ePlanningLodgedBy: "ePlanningLodgedBy 17",
    Comments: "Comments 17",
    ApplicationCategory_Name: "Tools",
    ApplicationCategory_Description: "Toys",
    Code: "40819-5176",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName: "We need to calculate the 1080p IB alarm!",
    PublishDescription:
      "I'll generate the virtual TCP alarm, that should bandwidth the RSS alarm!",
    Title: "Gorgeous Metal Shoes Chief Refined",
    ConditionCode: "89529-5321",
    Condition: "Group",
    Name: "Melyna Ullrich",
    Multiplier: "Multiplier 17",
    Value: 94605,
    MinimumValue: 31478,
    TargetDays: "TargetDays 17",
    Category_1: "Tools",
    Category_2: "Health",
    ObservationSummary: "ObservationSummary 17",
    ComplianceInstruction: "ComplianceInstruction 17",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-17T15:17:19.990Z",
  },
  {
    ID: 18,
    ApplicationNumber: 30433,
    PermitNumber: 36682,
    PermitType: "deposit",
    Date_PermitIssued: "2021-04-14T04:26:23.050Z",
    RefNo: 22199,
    AssessmentNo: 10321,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Onie Breitenberg",
    Builder: "Mrs. Stefanie Gutkowski",
    Category: "Small Fresh Ball",
    BCA: "Sleek",
    UseOfBuilding: false,
    ReasonForPermit: "array",
    SiteAddress: "lexus.info",
    Locality: "IA",
    Owner: "Dax Pollich",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-11-05T22:34:32.644Z",
    Date_AllInspComplete: "2021-08-30T10:08:55.959Z",
    Date_DueToCommence: "2021-10-10T09:05:35.857Z",
    Date_Commenced: "2021-05-25T15:13:43.093Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "June",
    FileNo: 64482,
    ValuationNo: 27478,
    LegalDescription: "Checking Account",
    BuildingOfficer: "Investor",
    Surveyor: "Checking Account",
    SurveyorNumber: "64548800",
    StatDays: 73147,
    Date_Issued: "2021-08-08T03:25:13.553Z",
    Date_ReminderSent: "2021-04-17T01:15:00.142Z",
    PayingFee: 91748,
    CarryingOutWork: true,
    WorkProposed: "ADP superstructure copy",
    Address: "Apt. 427",
    BuildingAppNo: 59963,
    BuildingPermitNo: 49684,
    Date_BuildingIssued: "July",
    Date_Commencing: "July",
    BondAmount: 32475,
    BondReceived: "48042",
    BondReturned: 90318,
    Date_FirstInspection: "2021-12-03T20:23:37.488Z",
    Date_LastInspection: "2021-02-10T18:54:49.537Z",
    TargetDate: "2021-04-16T03:34:58.373Z",
    CILFee: 10795,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-01-31T03:37:00.410Z",
    InspectionType: "azure",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "Dalasi blue",
    FacilityRegion: "Ohio",
    Description: "Forward",
    DateTarget: "2021-08-09T04:48:41.032Z",
    Source: "Identity",
    Location: "SC",
    RequestedBy: "07050513",
    ContactDetails: "176.642.4415 x746",
    DueStatus: false,
    DateRecorded: "2021-02-22T09:58:04.475Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Fundamental 24/7 budgetary management",
    ActionOfficer: "Jewelery",
    Coordinator: "Apt. 423",
    DateWork: "2021-08-26T23:56:23.711Z",
    FileNumber: 45995,
    EnquiryRefNo: 80120,
    Event_ReferenceNoAdditional: 20499,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-07-07T22:42:05.280Z",
    ApplicantName: "Auto Loan Account",
    Type: "Inc",
    Risk: "PNG JBOD",
    DateDue: "2021-10-27T06:47:44.179Z",
    Register_Number: 46170,
    Contact: "Suite 206",
    ReferenceNumber: 83643,
    Date_Complete: "2021-08-17T13:15:23.916Z",
    Date_Construction: "2021-12-08T04:10:34.730Z",
    BarrierStandard: "BarrierStandard 18",
    BarrierType: "BarrierType 18",
    Date_CertificateOfCompliance: "2021-08-06T11:42:37.984Z",
    Pool_RegisteredDate: "2021-05-02T14:25:13.173Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-18T17:56:26.499Z",
    Date_WorkIssued: "2021-05-16T12:31:58.133Z",
    Work_CarriedOut: "Manager",
    AmountOS: 63380,
    Flag_RenewalNoticeSent: true,
    Outcome: 16771,
    Regulation: "Buckinghamshire",
    Date_Due: "2021-04-28T17:17:54.105Z",
    Date_NextInspection: "2021-05-13T11:13:56.592Z",
    Date_Start: "2021-02-18T02:03:24.266Z",
    Date_Occurred: "2021-09-27T16:59:57.745Z",
    ContactName: "Deja Hoppe",
    CreatedDate: "2020-06-28T22:08:50.791Z",
    FeeType: "$",
    AmountIssued: 77587,
    AmountOutstanding: 97659,
    CostCode: 32008,
    AmountPaid: 92702,
    ReceiptNumber: 6547,
    ReceiptDate: "2021-04-14T10:49:37.546Z",
    ReceiptedBy: "Agent",
    Method: "Product",
    InvoiceNumber: 92311,
    DebtorNumber: 2500,
    SecurePay_Reference: "SRD",
    Contact2: "Melba.Cruickshank@example.net",
    LevelOfEnforcement: "212.52",
    Number: 82016,
    DateOffence: "2021-04-21T14:27:24.809Z",
    RegisterType: "Specialist",
    VicSmart: "VicSmart 18",
    ePlanningCategory: "ePlanningCategory 18",
    ePlanningLodgedBy: "ePlanningLodgedBy 18",
    Comments: "Comments 18",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Automotive",
    Code: "75390-5747",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "I'll compress the solid state RAM panel, that should interface the RAM feed!",
    PublishDescription:
      "You can't connect the matrix without synthesizing the back-end SSL hard drive!",
    Title: "Global initiatives Auto Loan Account",
    ConditionCode: "12472",
    Condition: "LLC",
    Name: "Valentina Hagenes",
    Multiplier: "Multiplier 18",
    Value: 71657,
    MinimumValue: 4091,
    TargetDays: "TargetDays 18",
    Category_1: "Jewelery",
    Category_2: "Movies",
    ObservationSummary: "ObservationSummary 18",
    ComplianceInstruction: "ComplianceInstruction 18",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-08-08T14:34:21.751Z",
  },
  {
    ID: 19,
    ApplicationNumber: 74289,
    PermitNumber: 59057,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-01-30T01:14:44.070Z",
    RefNo: 35120,
    AssessmentNo: 13127,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Kory McDermott",
    Builder: "Mallory Corwin",
    Category: "Generic Frozen Car",
    BCA: "payment",
    UseOfBuilding: false,
    ReasonForPermit: "Lead Liaison Knoll",
    SiteAddress: "jewell.net",
    Locality: "MN",
    Owner: "Zoie Hegmann",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-09-20T23:33:29.302Z",
    Date_AllInspComplete: "2021-08-22T14:52:16.093Z",
    Date_DueToCommence: "2021-09-22T11:02:26.225Z",
    Date_Commenced: "2021-01-29T15:51:33.449Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "January",
    FileNo: 93602,
    ValuationNo: 52472,
    LegalDescription: "Unbranded Rubber Table",
    BuildingOfficer: "Dynamic",
    Surveyor: "Money Market Account",
    SurveyorNumber: "95060005",
    StatDays: 50436,
    Date_Issued: "2021-09-06T16:48:29.119Z",
    Date_ReminderSent: "2021-09-30T18:05:03.656Z",
    PayingFee: 37639,
    CarryingOutWork: false,
    WorkProposed: "Niger database monitor",
    Address: "Suite 638",
    BuildingAppNo: 56186,
    BuildingPermitNo: 85060,
    Date_BuildingIssued: "June",
    Date_Commencing: "March",
    BondAmount: 97517,
    BondReceived: "32633",
    BondReturned: 19320,
    Date_FirstInspection: "2021-09-30T16:10:31.120Z",
    Date_LastInspection: "2021-11-21T03:02:33.737Z",
    TargetDate: "2021-03-07T04:52:01.951Z",
    CILFee: 44258,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-07-16T20:59:08.074Z",
    InspectionType: "Plains",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "knowledge base Cambridgeshire",
    FacilityRegion: "Wyoming",
    Description: "International",
    DateTarget: "2021-06-19T21:22:27.906Z",
    Source: "Optimization",
    Location: "HI",
    RequestedBy: "20116555",
    ContactDetails: "915.043.0614 x01883",
    DueStatus: true,
    DateRecorded: "2021-02-22T22:32:14.318Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Diverse mobile success",
    ActionOfficer: "Kids",
    Coordinator: "Apt. 952",
    DateWork: "2021-04-26T19:49:05.942Z",
    FileNumber: 46782,
    EnquiryRefNo: 1012,
    Event_ReferenceNoAdditional: 88072,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-02-17T09:54:09.269Z",
    ApplicantName: "Personal Loan Account",
    Type: "Group",
    Risk: "hack",
    DateDue: "2021-12-26T20:50:04.030Z",
    Register_Number: 93734,
    Contact: "Apt. 109",
    ReferenceNumber: 3576,
    Date_Complete: "2021-10-26T11:11:40.529Z",
    Date_Construction: "2021-08-30T08:32:31.191Z",
    BarrierStandard: "BarrierStandard 19",
    BarrierType: "BarrierType 19",
    Date_CertificateOfCompliance: "2021-04-14T01:50:05.903Z",
    Pool_RegisteredDate: "2021-11-12T03:01:47.374Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-10T02:08:00.561Z",
    Date_WorkIssued: "2021-10-03T11:11:20.671Z",
    Work_CarriedOut: "Manager",
    AmountOS: 21017,
    Flag_RenewalNoticeSent: false,
    Outcome: 25605,
    Regulation: "productivity unleash forecast",
    Date_Due: "2021-09-04T22:27:43.175Z",
    Date_NextInspection: "2021-04-21T04:15:51.982Z",
    Date_Start: "2021-01-07T16:28:10.424Z",
    Date_Occurred: "2021-08-21T05:47:49.022Z",
    ContactName: "Kelly Lind",
    CreatedDate: "2020-04-08T15:55:12.542Z",
    FeeType: "RM",
    AmountIssued: 25144,
    AmountOutstanding: 17540,
    CostCode: 43251,
    AmountPaid: 63842,
    ReceiptNumber: 73074,
    ReceiptDate: "2021-11-01T05:22:48.137Z",
    ReceiptedBy: "Manager",
    Method: "Internal",
    InvoiceNumber: 72535,
    DebtorNumber: 7317,
    SecurePay_Reference: "ZWL",
    Contact2: "Alejandra25@example.net",
    LevelOfEnforcement: "166.77",
    Number: 95735,
    DateOffence: "2021-10-08T13:23:15.515Z",
    RegisterType: "Manager",
    VicSmart: "VicSmart 19",
    ePlanningCategory: "ePlanningCategory 19",
    ePlanningLodgedBy: "ePlanningLodgedBy 19",
    Comments: "Comments 19",
    ApplicationCategory_Name: "Sports",
    ApplicationCategory_Description: "Jewelery",
    Code: "07180-5989",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName: "We need to index the open-source ADP bus!",
    PublishDescription: "We need to synthesize the multi-byte TCP protocol!",
    Title: "Legacy Universal motivating",
    ConditionCode: "86396-4745",
    Condition: "Inc",
    Name: "Marjory Lebsack",
    Multiplier: "Multiplier 19",
    Value: 70582,
    MinimumValue: 60138,
    TargetDays: "TargetDays 19",
    Category_1: "Health",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 19",
    ComplianceInstruction: "ComplianceInstruction 19",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-26T20:10:10.799Z",
  },
  {
    ID: 20,
    ApplicationNumber: 60238,
    PermitNumber: 43079,
    PermitType: "payment",
    Date_PermitIssued: "2021-08-08T00:18:55.978Z",
    RefNo: 31371,
    AssessmentNo: 16722,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Leila Cassin",
    Builder: "Nick Smitham",
    Category: "Small Plastic Pants",
    BCA: "Shoes",
    UseOfBuilding: false,
    ReasonForPermit: "bypassing",
    SiteAddress: "davon.name",
    Locality: "KY",
    Owner: "Claire Crooks",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-03-14T10:14:36.551Z",
    Date_AllInspComplete: "2021-08-07T09:29:53.806Z",
    Date_DueToCommence: "2021-10-12T13:39:43.741Z",
    Date_Commenced: "2021-02-10T07:57:20.658Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "March",
    FileNo: 48031,
    ValuationNo: 36944,
    LegalDescription: "Virginia",
    BuildingOfficer: "National",
    Surveyor: "Investment Account",
    SurveyorNumber: "08054188",
    StatDays: 20661,
    Date_Issued: "2021-10-23T06:13:05.650Z",
    Date_ReminderSent: "2021-12-18T19:43:29.417Z",
    PayingFee: 77068,
    CarryingOutWork: false,
    WorkProposed: "Fresh",
    Address: "Apt. 457",
    BuildingAppNo: 46634,
    BuildingPermitNo: 64438,
    Date_BuildingIssued: "June",
    Date_Commencing: "April",
    BondAmount: 99315,
    BondReceived: "25536",
    BondReturned: 30257,
    Date_FirstInspection: "2021-10-01T01:59:21.013Z",
    Date_LastInspection: "2021-07-23T11:02:31.813Z",
    TargetDate: "2021-06-05T14:08:45.832Z",
    CILFee: 15296,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-01-23T06:01:14.249Z",
    InspectionType: "Agent",
    Officer: "Investment Account",
    Compliance: true,
    Reason: "mobile Nepalese Rupee",
    FacilityRegion: "Virginia",
    Description: "Product",
    DateTarget: "2021-04-29T14:38:48.973Z",
    Source: "Applications",
    Location: "SC",
    RequestedBy: "43148326",
    ContactDetails: "1-929-379-5239 x0392",
    DueStatus: true,
    DateRecorded: "2021-02-05T06:20:00.165Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Reduced real-time installation",
    ActionOfficer: "Shoes",
    Coordinator: "Suite 972",
    DateWork: "2021-10-15T18:47:02.932Z",
    FileNumber: 87752,
    EnquiryRefNo: 42312,
    Event_ReferenceNoAdditional: 23628,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-09-02T19:26:26.245Z",
    ApplicantName: "Checking Account",
    Type: "Inc",
    Risk: "conglomeration",
    DateDue: "2021-08-08T23:51:31.167Z",
    Register_Number: 78065,
    Contact: "Suite 611",
    ReferenceNumber: 64020,
    Date_Complete: "2021-11-07T22:16:58.899Z",
    Date_Construction: "2021-03-23T19:05:39.913Z",
    BarrierStandard: "BarrierStandard 20",
    BarrierType: "BarrierType 20",
    Date_CertificateOfCompliance: "2021-11-12T21:56:35.856Z",
    Pool_RegisteredDate: "2021-09-09T01:16:46.053Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-11-28T05:15:09.306Z",
    Date_WorkIssued: "2021-01-06T16:16:38.501Z",
    Work_CarriedOut: "Facilitator",
    AmountOS: 15961,
    Flag_RenewalNoticeSent: true,
    Outcome: 69030,
    Regulation: "Toys wireless",
    Date_Due: "2021-12-21T13:41:23.569Z",
    Date_NextInspection: "2021-10-10T03:36:01.541Z",
    Date_Start: "2021-06-19T10:24:26.578Z",
    Date_Occurred: "2021-08-18T14:24:33.194Z",
    ContactName: "Jeromy Lemke",
    CreatedDate: "2020-01-17T21:16:36.737Z",
    FeeType: "$",
    AmountIssued: 7507,
    AmountOutstanding: 18808,
    CostCode: 58221,
    AmountPaid: 60197,
    ReceiptNumber: 58436,
    ReceiptDate: "2021-03-17T23:19:18.431Z",
    ReceiptedBy: "Administrator",
    Method: "Internal",
    InvoiceNumber: 3094,
    DebtorNumber: 21704,
    SecurePay_Reference: "JMD",
    Contact2: "Rod7@example.com",
    LevelOfEnforcement: "318.79",
    Number: 50803,
    DateOffence: "2021-08-21T21:01:25.352Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 20",
    ePlanningCategory: "ePlanningCategory 20",
    ePlanningLodgedBy: "ePlanningLodgedBy 20",
    Comments: "Comments 20",
    ApplicationCategory_Name: "Industrial",
    ApplicationCategory_Description: "Movies",
    Code: "49274",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "The SMS sensor is down, hack the primary capacitor so we can input the XML card!",
    PublishDescription:
      "The RAM system is down, copy the redundant bandwidth so we can back up the PNG transmitter!",
    Title: "input",
    ConditionCode: "98782",
    Condition: "Group",
    Name: "Gabrielle Heidenreich",
    Multiplier: "Multiplier 20",
    Value: 98301,
    MinimumValue: 18758,
    TargetDays: "TargetDays 20",
    Category_1: "Toys",
    Category_2: "Jewelery",
    ObservationSummary: "ObservationSummary 20",
    ComplianceInstruction: "ComplianceInstruction 20",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-14T07:27:55.800Z",
  },
  {
    ID: 21,
    ApplicationNumber: 84104,
    PermitNumber: 28946,
    PermitType: "invoice",
    Date_PermitIssued: "2021-02-10T13:56:36.139Z",
    RefNo: 43756,
    AssessmentNo: 93801,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Jevon Kihn",
    Builder: "Nina Volkman",
    Category: "Gorgeous Concrete Gloves",
    BCA: "Rustic Granite Pants",
    UseOfBuilding: false,
    ReasonForPermit: "Architect copy",
    SiteAddress: "ivory.com",
    Locality: "MO",
    Owner: "Josiah Parisian",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-08-21T13:27:04.216Z",
    Date_AllInspComplete: "2021-03-11T16:32:51.604Z",
    Date_DueToCommence: "2021-10-05T13:55:16.954Z",
    Date_Commenced: "2021-08-05T12:16:34.586Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "April",
    FileNo: 49348,
    ValuationNo: 67187,
    LegalDescription: "tan reinvent",
    BuildingOfficer: "Future",
    Surveyor: "Investment Account",
    SurveyorNumber: "34265441",
    StatDays: 92015,
    Date_Issued: "2021-08-22T05:21:54.299Z",
    Date_ReminderSent: "2021-09-15T19:19:16.914Z",
    PayingFee: 35265,
    CarryingOutWork: false,
    WorkProposed: "client-server multi-byte Borders",
    Address: "Suite 372",
    BuildingAppNo: 19806,
    BuildingPermitNo: 69708,
    Date_BuildingIssued: "May",
    Date_Commencing: "April",
    BondAmount: 87945,
    BondReceived: "62694-6888",
    BondReturned: 37069,
    Date_FirstInspection: "2021-03-07T06:34:39.783Z",
    Date_LastInspection: "2021-05-04T12:18:30.273Z",
    TargetDate: "2021-02-14T21:40:40.845Z",
    CILFee: 18427,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-01-24T20:28:13.935Z",
    InspectionType: "Shoes",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "Fantastic pink",
    FacilityRegion: "Ohio",
    Description: "Forward",
    DateTarget: "2021-04-18T20:21:40.374Z",
    Source: "Quality",
    Location: "IN",
    RequestedBy: "10522644",
    ContactDetails: "1-916-348-0973 x1784",
    DueStatus: true,
    DateRecorded: "2021-10-19T19:00:42.331Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Monitored actuating implementation",
    ActionOfficer: "Sports",
    Coordinator: "Suite 372",
    DateWork: "2021-04-30T22:09:43.824Z",
    FileNumber: 89119,
    EnquiryRefNo: 18047,
    Event_ReferenceNoAdditional: 81534,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-11-19T04:30:21.889Z",
    ApplicantName: "Savings Account",
    Type: "and Sons",
    Risk: "Legacy Throughway optical",
    DateDue: "2021-01-25T06:22:46.914Z",
    Register_Number: 65886,
    Contact: "Suite 301",
    ReferenceNumber: 9739,
    Date_Complete: "2021-11-27T04:23:32.663Z",
    Date_Construction: "2021-08-20T03:37:09.699Z",
    BarrierStandard: "BarrierStandard 21",
    BarrierType: "BarrierType 21",
    Date_CertificateOfCompliance: "2021-05-08T08:08:20.594Z",
    Pool_RegisteredDate: "2021-09-26T11:23:26.137Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-19T00:19:34.040Z",
    Date_WorkIssued: "2021-10-30T20:15:28.572Z",
    Work_CarriedOut: "Strategist",
    AmountOS: 97588,
    Flag_RenewalNoticeSent: false,
    Outcome: 54146,
    Regulation: "Credit Card Account",
    Date_Due: "2021-10-06T14:43:59.128Z",
    Date_NextInspection: "2021-01-26T10:37:00.563Z",
    Date_Start: "2021-05-22T00:35:57.644Z",
    Date_Occurred: "2021-06-04T23:00:55.930Z",
    ContactName: "Shanie Stiedemann",
    CreatedDate: "2020-04-14T18:36:31.013Z",
    FeeType: "₫",
    AmountIssued: 39454,
    AmountOutstanding: 35061,
    CostCode: 38721,
    AmountPaid: 66513,
    ReceiptNumber: 24854,
    ReceiptDate: "2021-08-26T07:48:18.386Z",
    ReceiptedBy: "Supervisor",
    Method: "Principal",
    InvoiceNumber: 40628,
    DebtorNumber: 72375,
    SecurePay_Reference: "STD",
    Contact2: "Flavio.Pfeffer65@example.org",
    LevelOfEnforcement: "340.29",
    Number: 7294,
    DateOffence: "2021-11-09T12:37:16.757Z",
    RegisterType: "Coordinator",
    VicSmart: "VicSmart 21",
    ePlanningCategory: "ePlanningCategory 21",
    ePlanningLodgedBy: "ePlanningLodgedBy 21",
    Comments: "Comments 21",
    ApplicationCategory_Name: "Beauty",
    ApplicationCategory_Description: "Tools",
    Code: "10271-8982",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "If we hack the bandwidth, we can get to the RAM bandwidth through the back-end HDD firewall!",
    PublishDescription:
      "You can't reboot the sensor without generating the primary XSS port!",
    Title: "Rubber Buckinghamshire",
    ConditionCode: "84208-5589",
    Condition: "LLC",
    Name: "Ms. Coy Hane",
    Multiplier: "Multiplier 21",
    Value: 37326,
    MinimumValue: 30037,
    TargetDays: "TargetDays 21",
    Category_1: "Sports",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 21",
    ComplianceInstruction: "ComplianceInstruction 21",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-10T17:47:52.055Z",
  },
  {
    ID: 22,
    ApplicationNumber: 48503,
    PermitNumber: 11428,
    PermitType: "deposit",
    Date_PermitIssued: "2021-02-27T13:39:17.641Z",
    RefNo: 85304,
    AssessmentNo: 5777,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Leanne Considine",
    Builder: "Mckenzie Gleason",
    Category: "Tasty Granite Towels",
    BCA: "Fantastic",
    UseOfBuilding: true,
    ReasonForPermit: "Forint Borders",
    SiteAddress: "harvey.org",
    Locality: "ND",
    Owner: "Lucy Runolfsdottir",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-03-26T08:22:18.670Z",
    Date_AllInspComplete: "2021-11-17T19:12:54.174Z",
    Date_DueToCommence: "2021-08-29T22:02:35.576Z",
    Date_Commenced: "2021-08-17T18:49:28.322Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "July",
    FileNo: 81877,
    ValuationNo: 94143,
    LegalDescription: "TCP orange SQL",
    BuildingOfficer: "Dynamic",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "73812655",
    StatDays: 85462,
    Date_Issued: "2021-07-05T13:25:51.634Z",
    Date_ReminderSent: "2021-04-12T19:43:47.689Z",
    PayingFee: 70688,
    CarryingOutWork: true,
    WorkProposed: "Wooden",
    Address: "Suite 301",
    BuildingAppNo: 37775,
    BuildingPermitNo: 42315,
    Date_BuildingIssued: "May",
    Date_Commencing: "October",
    BondAmount: 31918,
    BondReceived: "71384",
    BondReturned: 11882,
    Date_FirstInspection: "2021-07-13T06:07:59.932Z",
    Date_LastInspection: "2021-03-01T17:25:02.419Z",
    TargetDate: "2021-06-06T09:58:16.573Z",
    CILFee: 80583,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-12-10T22:43:21.770Z",
    InspectionType: "Terrace",
    Officer: "Personal Loan Account",
    Compliance: false,
    Reason: "Music Re-contextualized",
    FacilityRegion: "Montana",
    Description: "Investor",
    DateTarget: "2021-12-07T12:59:21.236Z",
    Source: "Web",
    Location: "WA",
    RequestedBy: "54342685",
    ContactDetails: "(224) 707-0236 x143",
    DueStatus: false,
    DateRecorded: "2021-05-11T23:35:02.696Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Mandatory optimizing complexity",
    ActionOfficer: "Movies",
    Coordinator: "Suite 163",
    DateWork: "2021-04-23T07:07:19.797Z",
    FileNumber: 16290,
    EnquiryRefNo: 53491,
    Event_ReferenceNoAdditional: 77977,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-05-28T13:42:04.518Z",
    ApplicantName: "Savings Account",
    Type: "and Sons",
    Risk: "Baht",
    DateDue: "2021-03-27T06:50:46.715Z",
    Register_Number: 80563,
    Contact: "Apt. 517",
    ReferenceNumber: 96362,
    Date_Complete: "2021-01-29T20:57:46.923Z",
    Date_Construction: "2021-10-27T05:06:20.938Z",
    BarrierStandard: "BarrierStandard 22",
    BarrierType: "BarrierType 22",
    Date_CertificateOfCompliance: "2021-08-19T20:39:04.099Z",
    Pool_RegisteredDate: "2021-09-13T07:37:19.766Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-20T03:18:06.672Z",
    Date_WorkIssued: "2021-02-02T05:28:07.112Z",
    Work_CarriedOut: "Associate",
    AmountOS: 72929,
    Flag_RenewalNoticeSent: true,
    Outcome: 42352,
    Regulation: "Human Graphical User Interface Supervisor",
    Date_Due: "2021-09-23T08:41:11.925Z",
    Date_NextInspection: "2021-09-16T09:00:00.008Z",
    Date_Start: "2021-05-20T18:57:20.538Z",
    Date_Occurred: "2021-05-20T06:25:17.202Z",
    ContactName: "Everette Schumm",
    CreatedDate: "2020-12-02T22:27:24.636Z",
    FeeType: "J$",
    AmountIssued: 96098,
    AmountOutstanding: 30574,
    CostCode: 60050,
    AmountPaid: 78615,
    ReceiptNumber: 14881,
    ReceiptDate: "2021-09-20T11:02:26.320Z",
    ReceiptedBy: "Associate",
    Method: "Direct",
    InvoiceNumber: 60845,
    DebtorNumber: 54400,
    SecurePay_Reference: "HRK",
    Contact2: "Nicola_Hilll@example.com",
    LevelOfEnforcement: "661.46",
    Number: 43993,
    DateOffence: "2021-02-04T15:03:04.570Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 22",
    ePlanningCategory: "ePlanningCategory 22",
    ePlanningLodgedBy: "ePlanningLodgedBy 22",
    Comments: "Comments 22",
    ApplicationCategory_Name: "Home",
    ApplicationCategory_Description: "Tools",
    Code: "55352",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "You can't quantify the panel without calculating the virtual RAM matrix!",
    PublishDescription: "We need to input the virtual PNG transmitter!",
    Title: "lime Fantastic",
    ConditionCode: "32592",
    Condition: "LLC",
    Name: "Delia Mohr",
    Multiplier: "Multiplier 22",
    Value: 40599,
    MinimumValue: 41816,
    TargetDays: "TargetDays 22",
    Category_1: "Home",
    Category_2: "Tools",
    ObservationSummary: "ObservationSummary 22",
    ComplianceInstruction: "ComplianceInstruction 22",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-26T03:06:41.406Z",
  },
  {
    ID: 23,
    ApplicationNumber: 80860,
    PermitNumber: 97839,
    PermitType: "invoice",
    Date_PermitIssued: "2021-04-24T01:53:57.187Z",
    RefNo: 94515,
    AssessmentNo: 15586,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Cale Gulgowski",
    Builder: "Jeff Gulgowski",
    Category: "Licensed Plastic Chicken",
    BCA: "infrastructure",
    UseOfBuilding: true,
    ReasonForPermit: "solid state",
    SiteAddress: "kelsi.com",
    Locality: "AZ",
    Owner: "Dante Haley PhD",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-08-21T20:31:00.989Z",
    Date_AllInspComplete: "2021-04-22T09:24:23.642Z",
    Date_DueToCommence: "2021-02-10T14:54:12.212Z",
    Date_Commenced: "2021-01-12T17:48:56.266Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "January",
    FileNo: 26481,
    ValuationNo: 79473,
    LegalDescription: "Hawaii hard drive Beauty",
    BuildingOfficer: "Investor",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "97914175",
    StatDays: 81278,
    Date_Issued: "2021-08-01T11:14:33.546Z",
    Date_ReminderSent: "2021-12-07T21:46:37.178Z",
    PayingFee: 70908,
    CarryingOutWork: true,
    WorkProposed: "Gorgeous Rubber Chicken Rubber",
    Address: "Apt. 748",
    BuildingAppNo: 95469,
    BuildingPermitNo: 86820,
    Date_BuildingIssued: "August",
    Date_Commencing: "September",
    BondAmount: 99034,
    BondReceived: "95577-2648",
    BondReturned: 33516,
    Date_FirstInspection: "2021-12-28T12:54:54.971Z",
    Date_LastInspection: "2021-08-07T18:24:16.721Z",
    TargetDate: "2021-05-10T14:58:00.523Z",
    CILFee: 96813,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-10-25T16:19:15.658Z",
    InspectionType: "sensor",
    Officer: "Personal Loan Account",
    Compliance: false,
    Reason: "extend Sleek Granite Car digital",
    FacilityRegion: "Colorado",
    Description: "Dynamic",
    DateTarget: "2021-09-22T11:19:08.898Z",
    Source: "Configuration",
    Location: "WY",
    RequestedBy: "27801249",
    ContactDetails: "(329) 906-2610 x5967",
    DueStatus: true,
    DateRecorded: "2021-05-07T06:59:07.927Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Synergistic well-modulated internet solution",
    ActionOfficer: "Outdoors",
    Coordinator: "Suite 490",
    DateWork: "2021-02-02T18:56:59.422Z",
    FileNumber: 84486,
    EnquiryRefNo: 72238,
    Event_ReferenceNoAdditional: 42726,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-02-20T23:14:59.662Z",
    ApplicantName: "Home Loan Account",
    Type: "LLC",
    Risk: "Gorgeous Plastic Sausages disintermediate",
    DateDue: "2021-04-09T13:01:24.984Z",
    Register_Number: 51835,
    Contact: "Suite 735",
    ReferenceNumber: 85020,
    Date_Complete: "2021-05-22T05:30:29.156Z",
    Date_Construction: "2021-01-05T02:11:22.533Z",
    BarrierStandard: "BarrierStandard 23",
    BarrierType: "BarrierType 23",
    Date_CertificateOfCompliance: "2021-05-11T03:19:06.151Z",
    Pool_RegisteredDate: "2021-10-30T23:50:37.583Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-15T09:06:26.344Z",
    Date_WorkIssued: "2021-12-23T22:05:05.537Z",
    Work_CarriedOut: "Officer",
    AmountOS: 10371,
    Flag_RenewalNoticeSent: false,
    Outcome: 28729,
    Regulation: "hack Aruban Guilder Heights",
    Date_Due: "2021-04-28T02:13:40.472Z",
    Date_NextInspection: "2021-12-20T11:15:12.540Z",
    Date_Start: "2021-10-07T04:24:07.291Z",
    Date_Occurred: "2021-03-23T03:38:31.391Z",
    ContactName: "Kathryne Sporer",
    CreatedDate: "2020-06-07T03:51:11.229Z",
    FeeType: "R$",
    AmountIssued: 38321,
    AmountOutstanding: 87517,
    CostCode: 89159,
    AmountPaid: 47851,
    ReceiptNumber: 26803,
    ReceiptDate: "2021-11-05T06:59:48.180Z",
    ReceiptedBy: "Assistant",
    Method: "Internal",
    InvoiceNumber: 94516,
    DebtorNumber: 80587,
    SecurePay_Reference: "TTD",
    Contact2: "Teresa_VonRueden35@example.net",
    LevelOfEnforcement: "105.42",
    Number: 12240,
    DateOffence: "2021-11-26T01:33:17.492Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 23",
    ePlanningCategory: "ePlanningCategory 23",
    ePlanningLodgedBy: "ePlanningLodgedBy 23",
    Comments: "Comments 23",
    ApplicationCategory_Name: "Sports",
    ApplicationCategory_Description: "Beauty",
    Code: "77177",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "If we reboot the firewall, we can get to the PNG interface through the redundant HDD program!",
    PublishDescription:
      "You can't hack the sensor without indexing the wireless PCI alarm!",
    Title: "PCI",
    ConditionCode: "61474",
    Condition: "LLC",
    Name: "Urban Parisian",
    Multiplier: "Multiplier 23",
    Value: 48375,
    MinimumValue: 7605,
    TargetDays: "TargetDays 23",
    Category_1: "Grocery",
    Category_2: "Industrial",
    ObservationSummary: "ObservationSummary 23",
    ComplianceInstruction: "ComplianceInstruction 23",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-16T05:30:11.092Z",
  },
  {
    ID: 24,
    ApplicationNumber: 36358,
    PermitNumber: 22369,
    PermitType: "payment",
    Date_PermitIssued: "2021-08-18T20:59:50.137Z",
    RefNo: 82412,
    AssessmentNo: 97516,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Pearl Bernhard",
    Builder: "Else Kshlerin DVM",
    Category: "Ergonomic Granite Hat",
    BCA: "Credit Card Account",
    UseOfBuilding: false,
    ReasonForPermit: "Cambridgeshire",
    SiteAddress: "bettie.info",
    Locality: "IA",
    Owner: "Lola Stark",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-09-30T01:53:06.525Z",
    Date_AllInspComplete: "2021-03-20T22:59:52.420Z",
    Date_DueToCommence: "2021-11-20T07:58:49.124Z",
    Date_Commenced: "2021-12-15T18:08:12.750Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "January",
    FileNo: 57603,
    ValuationNo: 78278,
    LegalDescription: "South Carolina Buckinghamshire",
    BuildingOfficer: "Senior",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "88331964",
    StatDays: 27720,
    Date_Issued: "2021-06-26T23:47:33.732Z",
    Date_ReminderSent: "2021-12-22T01:02:19.831Z",
    PayingFee: 41773,
    CarryingOutWork: true,
    WorkProposed: "RSS Solutions West Virginia",
    Address: "Suite 827",
    BuildingAppNo: 95377,
    BuildingPermitNo: 72493,
    Date_BuildingIssued: "October",
    Date_Commencing: "October",
    BondAmount: 59049,
    BondReceived: "33895",
    BondReturned: 43769,
    Date_FirstInspection: "2021-07-02T06:02:01.872Z",
    Date_LastInspection: "2021-02-01T00:44:38.896Z",
    TargetDate: "2021-09-14T05:43:20.177Z",
    CILFee: 34467,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-03-13T17:01:19.738Z",
    InspectionType: "Home Loan Account",
    Officer: "Checking Account",
    Compliance: true,
    Reason: "Keys motivating optical",
    FacilityRegion: "Arkansas",
    Description: "Central",
    DateTarget: "2021-04-12T17:39:24.395Z",
    Source: "Implementation",
    Location: "MT",
    RequestedBy: "69081446",
    ContactDetails: "(416) 784-5211 x1211",
    DueStatus: true,
    DateRecorded: "2021-07-01T11:51:37.414Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Multi-lateral even-keeled frame",
    ActionOfficer: "Shoes",
    Coordinator: "Suite 346",
    DateWork: "2021-06-10T17:29:26.018Z",
    FileNumber: 13602,
    EnquiryRefNo: 71342,
    Event_ReferenceNoAdditional: 55020,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-12-13T05:24:50.274Z",
    ApplicantName: "Home Loan Account",
    Type: "Inc",
    Risk: "backing up Unbranded Fresh Salad bandwidth",
    DateDue: "2021-09-18T16:38:59.178Z",
    Register_Number: 40544,
    Contact: "Suite 523",
    ReferenceNumber: 607,
    Date_Complete: "2021-06-13T19:46:51.984Z",
    Date_Construction: "2021-12-03T18:36:30.978Z",
    BarrierStandard: "BarrierStandard 24",
    BarrierType: "BarrierType 24",
    Date_CertificateOfCompliance: "2021-07-17T04:57:17.600Z",
    Pool_RegisteredDate: "2021-09-08T18:03:47.487Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-29T11:08:52.576Z",
    Date_WorkIssued: "2021-08-14T09:25:27.377Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 28290,
    Flag_RenewalNoticeSent: true,
    Outcome: 56047,
    Regulation: "homogeneous Regional Cayman Islands",
    Date_Due: "2021-07-09T23:12:32.709Z",
    Date_NextInspection: "2021-02-27T12:12:52.393Z",
    Date_Start: "2021-07-11T06:46:46.877Z",
    Date_Occurred: "2021-09-09T11:14:15.279Z",
    ContactName: "Donna Hessel",
    CreatedDate: "2020-12-18T03:04:00.880Z",
    FeeType: "kr",
    AmountIssued: 97645,
    AmountOutstanding: 34176,
    CostCode: 52437,
    AmountPaid: 71765,
    ReceiptNumber: 3874,
    ReceiptDate: "2021-05-03T13:37:29.721Z",
    ReceiptedBy: "Executive",
    Method: "Direct",
    InvoiceNumber: 32769,
    DebtorNumber: 1868,
    SecurePay_Reference: "GIP",
    Contact2: "Ova.Beahan@example.net",
    LevelOfEnforcement: "973.98",
    Number: 16858,
    DateOffence: "2021-07-20T11:31:40.902Z",
    RegisterType: "Architect",
    VicSmart: "VicSmart 24",
    ePlanningCategory: "ePlanningCategory 24",
    ePlanningLodgedBy: "ePlanningLodgedBy 24",
    Comments: "Comments 24",
    ApplicationCategory_Name: "Games",
    ApplicationCategory_Description: "Shoes",
    Code: "35175-8691",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "Try to synthesize the PCI bandwidth, maybe it will transmit the solid state microchip!",
    PublishDescription:
      "If we synthesize the bandwidth, we can get to the PNG feed through the wireless HDD firewall!",
    Title: "Plastic initiative copying",
    ConditionCode: "19491",
    Condition: "and Sons",
    Name: "Vivian Kozey",
    Multiplier: "Multiplier 24",
    Value: 26555,
    MinimumValue: 96721,
    TargetDays: "TargetDays 24",
    Category_1: "Baby",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 24",
    ComplianceInstruction: "ComplianceInstruction 24",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-21T12:55:51.234Z",
  },
  {
    ID: 25,
    ApplicationNumber: 55826,
    PermitNumber: 32142,
    PermitType: "invoice",
    Date_PermitIssued: "2021-03-19T21:40:23.240Z",
    RefNo: 54074,
    AssessmentNo: 55912,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Devan Zboncak",
    Builder: "Nikolas Langworth",
    Category: "Tasty Plastic Salad",
    BCA: "Practical Metal Hat",
    UseOfBuilding: false,
    ReasonForPermit: "collaborative Florida",
    SiteAddress: "annabell.info",
    Locality: "AR",
    Owner: "Ms. Aaliyah Sawayn",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-04-02T22:46:04.039Z",
    Date_AllInspComplete: "2021-04-27T03:07:43.510Z",
    Date_DueToCommence: "2021-07-15T09:09:36.876Z",
    Date_Commenced: "2021-07-31T23:26:40.037Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "March",
    FileNo: 3964,
    ValuationNo: 8600,
    LegalDescription: "invoice",
    BuildingOfficer: "Dynamic",
    Surveyor: "Checking Account",
    SurveyorNumber: "35062791",
    StatDays: 9361,
    Date_Issued: "2021-12-29T18:53:50.356Z",
    Date_ReminderSent: "2021-07-27T18:30:01.999Z",
    PayingFee: 61565,
    CarryingOutWork: false,
    WorkProposed: "regional Orchestrator wireless",
    Address: "Apt. 979",
    BuildingAppNo: 6647,
    BuildingPermitNo: 41910,
    Date_BuildingIssued: "January",
    Date_Commencing: "May",
    BondAmount: 5769,
    BondReceived: "44155",
    BondReturned: 71670,
    Date_FirstInspection: "2021-08-08T17:31:42.117Z",
    Date_LastInspection: "2021-01-20T20:42:01.812Z",
    TargetDate: "2021-05-31T04:37:11.535Z",
    CILFee: 32879,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-08-14T14:21:49.668Z",
    InspectionType: "coherent",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "Gorgeous Intuitive override",
    FacilityRegion: "Virginia",
    Description: "Central",
    DateTarget: "2021-01-22T01:34:51.555Z",
    Source: "Intranet",
    Location: "CA",
    RequestedBy: "88117185",
    ContactDetails: "618.691.3487 x98970",
    DueStatus: true,
    DateRecorded: "2021-12-06T13:47:57.132Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Switchable optimizing pricing structure",
    ActionOfficer: "Books",
    Coordinator: "Apt. 676",
    DateWork: "2021-09-06T10:19:06.734Z",
    FileNumber: 49819,
    EnquiryRefNo: 21388,
    Event_ReferenceNoAdditional: 2379,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-01-31T18:54:12.764Z",
    ApplicantName: "Savings Account",
    Type: "Inc",
    Risk: "drive",
    DateDue: "2021-02-24T15:03:09.026Z",
    Register_Number: 75610,
    Contact: "Suite 839",
    ReferenceNumber: 10272,
    Date_Complete: "2021-06-05T19:48:47.785Z",
    Date_Construction: "2021-01-24T04:14:49.915Z",
    BarrierStandard: "BarrierStandard 25",
    BarrierType: "BarrierType 25",
    Date_CertificateOfCompliance: "2021-10-24T14:05:37.775Z",
    Pool_RegisteredDate: "2021-12-20T22:31:32.059Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-02T11:33:43.344Z",
    Date_WorkIssued: "2021-04-19T09:55:03.230Z",
    Work_CarriedOut: "Manager",
    AmountOS: 49509,
    Flag_RenewalNoticeSent: false,
    Outcome: 43882,
    Regulation: "Frozen Checking Account",
    Date_Due: "2021-10-30T12:05:43.471Z",
    Date_NextInspection: "2021-10-30T00:34:20.071Z",
    Date_Start: "2021-08-25T00:09:38.521Z",
    Date_Occurred: "2021-09-01T09:23:29.255Z",
    ContactName: "Timothy Cruickshank",
    CreatedDate: "2020-05-02T11:47:43.594Z",
    FeeType: "Kč",
    AmountIssued: 58827,
    AmountOutstanding: 827,
    CostCode: 69169,
    AmountPaid: 31574,
    ReceiptNumber: 6010,
    ReceiptDate: "2021-05-30T22:25:47.057Z",
    ReceiptedBy: "Liaison",
    Method: "Direct",
    InvoiceNumber: 29963,
    DebtorNumber: 90111,
    SecurePay_Reference: "TOP",
    Contact2: "Arlene.Crist@example.org",
    LevelOfEnforcement: "664.71",
    Number: 68839,
    DateOffence: "2021-10-25T02:56:35.718Z",
    RegisterType: "Coordinator",
    VicSmart: "VicSmart 25",
    ePlanningCategory: "ePlanningCategory 25",
    ePlanningLodgedBy: "ePlanningLodgedBy 25",
    Comments: "Comments 25",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Movies",
    Code: "05412-1964",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "If we generate the matrix, we can get to the COM panel through the multi-byte HTTP driver!",
    PublishDescription:
      "The PCI card is down, input the haptic pixel so we can connect the HDD program!",
    Title: "leverage Strategist application",
    ConditionCode: "86142-5275",
    Condition: "Inc",
    Name: "Richie Bernhard",
    Multiplier: "Multiplier 25",
    Value: 48676,
    MinimumValue: 93253,
    TargetDays: "TargetDays 25",
    Category_1: "Automotive",
    Category_2: "Movies",
    ObservationSummary: "ObservationSummary 25",
    ComplianceInstruction: "ComplianceInstruction 25",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-13T20:48:52.738Z",
  },
  {
    ID: 26,
    ApplicationNumber: 4459,
    PermitNumber: 36929,
    PermitType: "payment",
    Date_PermitIssued: "2021-04-19T04:20:56.132Z",
    RefNo: 82198,
    AssessmentNo: 81390,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Cyrus Lemke",
    Builder: "Heath Aufderhar",
    Category: "Sleek Metal Keyboard",
    BCA: "Mount",
    UseOfBuilding: false,
    ReasonForPermit: "Investment Account Shirt monitor",
    SiteAddress: "mason.com",
    Locality: "VA",
    Owner: "Josianne Gutkowski",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-06-06T16:06:18.478Z",
    Date_AllInspComplete: "2021-08-07T19:10:10.216Z",
    Date_DueToCommence: "2021-10-15T13:05:50.630Z",
    Date_Commenced: "2021-01-16T20:30:24.403Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "February",
    FileNo: 69905,
    ValuationNo: 88743,
    LegalDescription: "Light vertical extranet",
    BuildingOfficer: "International",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "50771058",
    StatDays: 40568,
    Date_Issued: "2021-09-04T23:23:55.041Z",
    Date_ReminderSent: "2021-01-13T20:21:55.662Z",
    PayingFee: 41630,
    CarryingOutWork: false,
    WorkProposed: "invoice Analyst multi-byte",
    Address: "Suite 852",
    BuildingAppNo: 92910,
    BuildingPermitNo: 52536,
    Date_BuildingIssued: "October",
    Date_Commencing: "December",
    BondAmount: 40569,
    BondReceived: "27763",
    BondReturned: 22004,
    Date_FirstInspection: "2021-07-03T03:35:35.590Z",
    Date_LastInspection: "2021-04-29T09:19:11.810Z",
    TargetDate: "2021-08-08T13:36:06.238Z",
    CILFee: 26474,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-12-01T22:15:41.143Z",
    InspectionType: "Tasty Metal Tuna",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "Bike yellow Turkish Lira",
    FacilityRegion: "Kentucky",
    Description: "Dynamic",
    DateTarget: "2021-09-22T04:46:13.724Z",
    Source: "Assurance",
    Location: "KY",
    RequestedBy: "61817648",
    ContactDetails: "864.599.0051",
    DueStatus: false,
    DateRecorded: "2021-04-17T18:02:34.838Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Proactive encompassing Graphic Interface",
    ActionOfficer: "Health",
    Coordinator: "Apt. 419",
    DateWork: "2021-02-12T09:05:48.135Z",
    FileNumber: 48693,
    EnquiryRefNo: 12724,
    Event_ReferenceNoAdditional: 16241,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-01-07T21:42:44.169Z",
    ApplicantName: "Auto Loan Account",
    Type: "and Sons",
    Risk: "Concrete",
    DateDue: "2021-07-15T10:24:25.596Z",
    Register_Number: 24189,
    Contact: "Suite 410",
    ReferenceNumber: 36880,
    Date_Complete: "2021-06-07T16:06:46.697Z",
    Date_Construction: "2021-05-14T13:50:47.508Z",
    BarrierStandard: "BarrierStandard 26",
    BarrierType: "BarrierType 26",
    Date_CertificateOfCompliance: "2021-10-24T06:35:51.913Z",
    Pool_RegisteredDate: "2021-07-19T06:10:09.669Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-21T10:04:17.045Z",
    Date_WorkIssued: "2021-02-20T21:58:52.507Z",
    Work_CarriedOut: "Producer",
    AmountOS: 61759,
    Flag_RenewalNoticeSent: false,
    Outcome: 95315,
    Regulation: "Ohio Fantastic Rubber Soap",
    Date_Due: "2021-12-04T00:38:01.730Z",
    Date_NextInspection: "2021-03-14T21:31:28.031Z",
    Date_Start: "2021-05-01T04:01:59.208Z",
    Date_Occurred: "2021-06-20T16:53:06.968Z",
    ContactName: "Bonita Murphy",
    CreatedDate: "2020-10-19T05:44:31.642Z",
    FeeType: "$",
    AmountIssued: 54315,
    AmountOutstanding: 62546,
    CostCode: 52710,
    AmountPaid: 96472,
    ReceiptNumber: 46175,
    ReceiptDate: "2021-04-10T11:15:49.736Z",
    ReceiptedBy: "Orchestrator",
    Method: "Corporate",
    InvoiceNumber: 46042,
    DebtorNumber: 76949,
    SecurePay_Reference: "TZS",
    Contact2: "Shane.Parker63@example.org",
    LevelOfEnforcement: "162.21",
    Number: 8918,
    DateOffence: "2021-03-17T20:21:15.457Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 26",
    ePlanningCategory: "ePlanningCategory 26",
    ePlanningLodgedBy: "ePlanningLodgedBy 26",
    Comments: "Comments 26",
    ApplicationCategory_Name: "Sports",
    ApplicationCategory_Description: "Health",
    Code: "36813-6218",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Try to quantify the XSS array, maybe it will index the auxiliary card!",
    PublishDescription:
      "Try to compress the SMTP card, maybe it will program the optical matrix!",
    Title: "Fish Fantastic Plastic Hat",
    ConditionCode: "55655-6985",
    Condition: "Inc",
    Name: "Norene Rosenbaum",
    Multiplier: "Multiplier 26",
    Value: 78095,
    MinimumValue: 10793,
    TargetDays: "TargetDays 26",
    Category_1: "Music",
    Category_2: "Automotive",
    ObservationSummary: "ObservationSummary 26",
    ComplianceInstruction: "ComplianceInstruction 26",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-23T01:00:08.077Z",
  },
  {
    ID: 27,
    ApplicationNumber: 20928,
    PermitNumber: 55528,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-02-15T01:40:42.235Z",
    RefNo: 34593,
    AssessmentNo: 23151,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Sonny Bashirian",
    Builder: "Zita Cassin III",
    Category: "Intelligent Granite Towels",
    BCA: "North Carolina",
    UseOfBuilding: true,
    ReasonForPermit: "scale",
    SiteAddress: "cassidy.info",
    Locality: "MI",
    Owner: "Dr. Thalia Leannon",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-04-08T18:21:04.406Z",
    Date_AllInspComplete: "2021-02-02T07:58:32.069Z",
    Date_DueToCommence: "2021-12-04T10:37:30.578Z",
    Date_Commenced: "2021-10-10T23:44:41.961Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "May",
    FileNo: 11977,
    ValuationNo: 90390,
    LegalDescription: "out-of-the-box revolutionary Shoal",
    BuildingOfficer: "Direct",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "68877781",
    StatDays: 28786,
    Date_Issued: "2021-05-12T16:15:15.482Z",
    Date_ReminderSent: "2021-09-08T04:09:19.170Z",
    PayingFee: 66684,
    CarryingOutWork: false,
    WorkProposed: "schemas metrics tangible",
    Address: "Apt. 521",
    BuildingAppNo: 33869,
    BuildingPermitNo: 19307,
    Date_BuildingIssued: "May",
    Date_Commencing: "October",
    BondAmount: 47034,
    BondReceived: "35880-3433",
    BondReturned: 85327,
    Date_FirstInspection: "2021-08-02T18:00:21.652Z",
    Date_LastInspection: "2021-09-17T21:05:42.815Z",
    TargetDate: "2021-05-07T10:35:23.287Z",
    CILFee: 49426,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-10-13T16:13:40.043Z",
    InspectionType: "Spur",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "Forward",
    FacilityRegion: "New York",
    Description: "Dynamic",
    DateTarget: "2021-08-20T22:18:29.359Z",
    Source: "Configuration",
    Location: "SD",
    RequestedBy: "72959066",
    ContactDetails: "(657) 260-2599",
    DueStatus: false,
    DateRecorded: "2021-08-10T12:43:21.735Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Triple-buffered zero defect matrix",
    ActionOfficer: "Shoes",
    Coordinator: "Apt. 881",
    DateWork: "2021-03-19T14:57:41.589Z",
    FileNumber: 91458,
    EnquiryRefNo: 57317,
    Event_ReferenceNoAdditional: 79771,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-02-02T23:53:43.389Z",
    ApplicantName: "Home Loan Account",
    Type: "and Sons",
    Risk: "Response",
    DateDue: "2021-06-06T12:47:15.831Z",
    Register_Number: 26356,
    Contact: "Suite 686",
    ReferenceNumber: 88193,
    Date_Complete: "2021-01-31T10:41:59.351Z",
    Date_Construction: "2021-11-15T01:06:00.145Z",
    BarrierStandard: "BarrierStandard 27",
    BarrierType: "BarrierType 27",
    Date_CertificateOfCompliance: "2021-06-07T17:04:11.765Z",
    Pool_RegisteredDate: "2021-01-10T22:40:15.132Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-01-02T03:37:03.500Z",
    Date_WorkIssued: "2021-11-26T13:04:08.761Z",
    Work_CarriedOut: "Associate",
    AmountOS: 84757,
    Flag_RenewalNoticeSent: true,
    Outcome: 25008,
    Regulation: "Vision-oriented",
    Date_Due: "2021-12-10T11:11:15.556Z",
    Date_NextInspection: "2021-05-05T17:01:48.254Z",
    Date_Start: "2021-10-06T01:05:40.323Z",
    Date_Occurred: "2021-02-23T22:10:07.370Z",
    ContactName: "Vaughn Kutch IV",
    CreatedDate: "2020-02-11T06:40:37.397Z",
    FeeType: "$b",
    AmountIssued: 99270,
    AmountOutstanding: 37780,
    CostCode: 30690,
    AmountPaid: 39972,
    ReceiptNumber: 39069,
    ReceiptDate: "2021-07-12T01:18:50.427Z",
    ReceiptedBy: "Agent",
    Method: "Central",
    InvoiceNumber: 3587,
    DebtorNumber: 17798,
    SecurePay_Reference: "UAH",
    Contact2: "Queenie.McCullough49@example.net",
    LevelOfEnforcement: "220.05",
    Number: 26396,
    DateOffence: "2021-03-16T07:55:17.409Z",
    RegisterType: "Officer",
    VicSmart: "VicSmart 27",
    ePlanningCategory: "ePlanningCategory 27",
    ePlanningLodgedBy: "ePlanningLodgedBy 27",
    Comments: "Comments 27",
    ApplicationCategory_Name: "Garden",
    ApplicationCategory_Description: "Grocery",
    Code: "44765",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "You can't connect the microchip without copying the open-source RSS protocol!",
    PublishDescription:
      "If we connect the pixel, we can get to the PCI application through the multi-byte XML transmitter!",
    Title: "primary Representative",
    ConditionCode: "62450",
    Condition: "Group",
    Name: "Dewayne Labadie",
    Multiplier: "Multiplier 27",
    Value: 65287,
    MinimumValue: 98905,
    TargetDays: "TargetDays 27",
    Category_1: "Home",
    Category_2: "Jewelery",
    ObservationSummary: "ObservationSummary 27",
    ComplianceInstruction: "ComplianceInstruction 27",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-08-27T18:16:55.772Z",
  },
  {
    ID: 28,
    ApplicationNumber: 49730,
    PermitNumber: 93410,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-04-25T14:24:32.066Z",
    RefNo: 64274,
    AssessmentNo: 25014,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Arturo Marvin",
    Builder: "Rosalee Leffler",
    Category: "Handcrafted Frozen Bacon",
    BCA: "Vietnam",
    UseOfBuilding: false,
    ReasonForPermit: "Licensed Handmade Steel Sausages",
    SiteAddress: "lorenzo.net",
    Locality: "ID",
    Owner: "Vanessa Hegmann",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-02-10T13:50:39.161Z",
    Date_AllInspComplete: "2021-03-25T20:11:00.292Z",
    Date_DueToCommence: "2021-06-24T19:26:47.676Z",
    Date_Commenced: "2021-12-16T23:41:17.314Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "January",
    FileNo: 61379,
    ValuationNo: 38276,
    LegalDescription: "experiences",
    BuildingOfficer: "Global",
    Surveyor: "Investment Account",
    SurveyorNumber: "11099091",
    StatDays: 96896,
    Date_Issued: "2021-09-14T19:24:10.899Z",
    Date_ReminderSent: "2021-12-19T10:31:56.857Z",
    PayingFee: 42634,
    CarryingOutWork: false,
    WorkProposed: "matrix payment",
    Address: "Apt. 605",
    BuildingAppNo: 96209,
    BuildingPermitNo: 40054,
    Date_BuildingIssued: "May",
    Date_Commencing: "March",
    BondAmount: 93605,
    BondReceived: "62035-7514",
    BondReturned: 22816,
    Date_FirstInspection: "2021-06-11T15:42:10.246Z",
    Date_LastInspection: "2021-08-31T05:41:29.605Z",
    TargetDate: "2021-10-19T17:14:20.133Z",
    CILFee: 48895,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-06-01T14:48:52.535Z",
    InspectionType: "Global",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "Steel Configurable",
    FacilityRegion: "Michigan",
    Description: "Internal",
    DateTarget: "2021-11-09T02:33:33.772Z",
    Source: "Solutions",
    Location: "CA",
    RequestedBy: "22476547",
    ContactDetails: "1-912-695-9327 x8533",
    DueStatus: true,
    DateRecorded: "2021-01-27T13:52:37.589Z",
    RecordedBy: "Investment Account",
    OrgStructure: "User-friendly high-level circuit",
    ActionOfficer: "Health",
    Coordinator: "Apt. 347",
    DateWork: "2021-09-25T09:13:44.428Z",
    FileNumber: 31294,
    EnquiryRefNo: 73105,
    Event_ReferenceNoAdditional: 43959,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-10-06T04:52:08.987Z",
    ApplicantName: "Credit Card Account",
    Type: "LLC",
    Risk: "Home Loan Account",
    DateDue: "2021-07-17T18:45:36.695Z",
    Register_Number: 57404,
    Contact: "Suite 450",
    ReferenceNumber: 94994,
    Date_Complete: "2021-07-07T03:11:10.428Z",
    Date_Construction: "2021-02-26T14:09:47.633Z",
    BarrierStandard: "BarrierStandard 28",
    BarrierType: "BarrierType 28",
    Date_CertificateOfCompliance: "2021-05-02T09:33:43.362Z",
    Pool_RegisteredDate: "2021-02-03T10:03:19.357Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-01-30T06:54:13.523Z",
    Date_WorkIssued: "2021-08-04T13:13:24.477Z",
    Work_CarriedOut: "Architect",
    AmountOS: 2187,
    Flag_RenewalNoticeSent: false,
    Outcome: 85579,
    Regulation: "Montenegro Mills navigating",
    Date_Due: "2021-05-20T09:05:28.797Z",
    Date_NextInspection: "2021-11-16T13:22:23.043Z",
    Date_Start: "2021-03-26T02:50:13.187Z",
    Date_Occurred: "2021-06-04T18:05:52.354Z",
    ContactName: "Haley Kovacek",
    CreatedDate: "2020-05-15T12:24:47.574Z",
    FeeType: "Lt",
    AmountIssued: 25026,
    AmountOutstanding: 43684,
    CostCode: 59322,
    AmountPaid: 16843,
    ReceiptNumber: 60481,
    ReceiptDate: "2021-04-24T21:27:23.190Z",
    ReceiptedBy: "Engineer",
    Method: "Senior",
    InvoiceNumber: 49332,
    DebtorNumber: 9165,
    SecurePay_Reference: "BMD",
    Contact2: "Franz.Huel@example.net",
    LevelOfEnforcement: "981.87",
    Number: 61182,
    DateOffence: "2021-12-21T06:38:49.672Z",
    RegisterType: "Liaison",
    VicSmart: "VicSmart 28",
    ePlanningCategory: "ePlanningCategory 28",
    ePlanningLodgedBy: "ePlanningLodgedBy 28",
    Comments: "Comments 28",
    ApplicationCategory_Name: "Jewelery",
    ApplicationCategory_Description: "Home",
    Code: "69528-6839",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Try to bypass the GB transmitter, maybe it will quantify the virtual pixel!",
    PublishDescription:
      "The SDD array is down, index the online pixel so we can bypass the THX circuit!",
    Title: "integrate relationships",
    ConditionCode: "41575-6552",
    Condition: "Group",
    Name: "Miss Wilmer Jerde",
    Multiplier: "Multiplier 28",
    Value: 51211,
    MinimumValue: 3450,
    TargetDays: "TargetDays 28",
    Category_1: "Electronics",
    Category_2: "Grocery",
    ObservationSummary: "ObservationSummary 28",
    ComplianceInstruction: "ComplianceInstruction 28",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-27T15:40:29.111Z",
  },
  {
    ID: 29,
    ApplicationNumber: 95694,
    PermitNumber: 28494,
    PermitType: "payment",
    Date_PermitIssued: "2021-11-07T02:58:03.657Z",
    RefNo: 16530,
    AssessmentNo: 62877,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Nia Renner",
    Builder: "Grayson Hirthe",
    Category: "Handmade Soft Keyboard",
    BCA: "copy",
    UseOfBuilding: false,
    ReasonForPermit: "generate",
    SiteAddress: "kenya.com",
    Locality: "IN",
    Owner: "Ms. Sebastian Mertz",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-05-23T09:08:53.205Z",
    Date_AllInspComplete: "2021-03-27T05:22:16.493Z",
    Date_DueToCommence: "2021-08-06T00:23:12.087Z",
    Date_Commenced: "2021-02-01T20:11:45.056Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "December",
    FileNo: 66710,
    ValuationNo: 39115,
    LegalDescription: "Fantastic Wooden Chair Open-source emulation",
    BuildingOfficer: "Dynamic",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "99265187",
    StatDays: 71407,
    Date_Issued: "2021-05-12T11:00:44.323Z",
    Date_ReminderSent: "2021-10-10T22:25:02.963Z",
    PayingFee: 91888,
    CarryingOutWork: false,
    WorkProposed: "viral online",
    Address: "Apt. 879",
    BuildingAppNo: 18372,
    BuildingPermitNo: 84129,
    Date_BuildingIssued: "December",
    Date_Commencing: "March",
    BondAmount: 65929,
    BondReceived: "48966-7097",
    BondReturned: 60433,
    Date_FirstInspection: "2021-01-13T10:34:52.279Z",
    Date_LastInspection: "2021-03-28T19:00:06.575Z",
    TargetDate: "2021-09-19T11:10:31.706Z",
    CILFee: 11319,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-09-20T19:23:24.327Z",
    InspectionType: "Computer",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "New Taiwan Dollar multi-byte Rubber",
    FacilityRegion: "Kentucky",
    Description: "Legacy",
    DateTarget: "2021-03-11T17:08:19.041Z",
    Source: "Security",
    Location: "VA",
    RequestedBy: "87212679",
    ContactDetails: "070.953.1339 x888",
    DueStatus: true,
    DateRecorded: "2021-09-27T15:22:30.976Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Face to face incremental knowledge user",
    ActionOfficer: "Automotive",
    Coordinator: "Apt. 948",
    DateWork: "2021-12-20T06:21:45.120Z",
    FileNumber: 49889,
    EnquiryRefNo: 1226,
    Event_ReferenceNoAdditional: 55637,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-05-10T10:32:18.556Z",
    ApplicantName: "Savings Account",
    Type: "Inc",
    Risk: "Maryland Dynamic",
    DateDue: "2021-10-30T05:41:46.171Z",
    Register_Number: 40308,
    Contact: "Apt. 660",
    ReferenceNumber: 81938,
    Date_Complete: "2021-07-14T09:54:32.727Z",
    Date_Construction: "2021-06-14T04:01:29.507Z",
    BarrierStandard: "BarrierStandard 29",
    BarrierType: "BarrierType 29",
    Date_CertificateOfCompliance: "2021-07-02T00:41:52.711Z",
    Pool_RegisteredDate: "2021-02-28T08:02:18.365Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-25T03:14:50.005Z",
    Date_WorkIssued: "2021-06-28T15:59:46.323Z",
    Work_CarriedOut: "Representative",
    AmountOS: 97138,
    Flag_RenewalNoticeSent: false,
    Outcome: 83740,
    Regulation: "Uruguay Computers",
    Date_Due: "2021-11-16T10:21:13.839Z",
    Date_NextInspection: "2021-10-07T08:41:44.314Z",
    Date_Start: "2021-01-01T09:41:15.875Z",
    Date_Occurred: "2021-06-30T15:33:03.951Z",
    ContactName: "Hipolito Lind DVM",
    CreatedDate: "2020-12-14T20:00:09.065Z",
    FeeType: "лв",
    AmountIssued: 90919,
    AmountOutstanding: 20235,
    CostCode: 11309,
    AmountPaid: 91775,
    ReceiptNumber: 35410,
    ReceiptDate: "2021-08-22T07:02:36.475Z",
    ReceiptedBy: "Director",
    Method: "Internal",
    InvoiceNumber: 27215,
    DebtorNumber: 70239,
    SecurePay_Reference: "RUB",
    Contact2: "Yesenia.OConner@example.com",
    LevelOfEnforcement: "47.94",
    Number: 97373,
    DateOffence: "2021-05-09T16:09:43.817Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 29",
    ePlanningCategory: "ePlanningCategory 29",
    ePlanningLodgedBy: "ePlanningLodgedBy 29",
    Comments: "Comments 29",
    ApplicationCategory_Name: "Electronics",
    ApplicationCategory_Description: "Home",
    Code: "67589-2977",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "You can't synthesize the hard drive without connecting the digital GB capacitor!",
    PublishDescription:
      "The COM monitor is down, generate the digital protocol so we can override the HTTP panel!",
    Title: "haptic Mauritius Rupee",
    ConditionCode: "87165-5529",
    Condition: "and Sons",
    Name: "Josefina O'Kon",
    Multiplier: "Multiplier 29",
    Value: 42355,
    MinimumValue: 29826,
    TargetDays: "TargetDays 29",
    Category_1: "Kids",
    Category_2: "Home",
    ObservationSummary: "ObservationSummary 29",
    ComplianceInstruction: "ComplianceInstruction 29",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-20T06:46:51.791Z",
  },
  {
    ID: 30,
    ApplicationNumber: 21759,
    PermitNumber: 50456,
    PermitType: "payment",
    Date_PermitIssued: "2021-02-13T06:11:06.193Z",
    RefNo: 87531,
    AssessmentNo: 86888,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Miss Skye Lebsack",
    Builder: "Ivy Jacobs",
    Category: "Refined Soft Bacon",
    BCA: "Avon",
    UseOfBuilding: false,
    ReasonForPermit: "Shoes customized Metical",
    SiteAddress: "llewellyn.org",
    Locality: "AL",
    Owner: "Antonio Brown",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-03-28T16:35:44.061Z",
    Date_AllInspComplete: "2021-05-26T02:28:50.262Z",
    Date_DueToCommence: "2021-11-15T06:59:22.604Z",
    Date_Commenced: "2021-10-06T06:45:30.946Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "March",
    FileNo: 23677,
    ValuationNo: 27065,
    LegalDescription: "paradigms Identity dot-com",
    BuildingOfficer: "Lead",
    Surveyor: "Savings Account",
    SurveyorNumber: "76322236",
    StatDays: 21120,
    Date_Issued: "2021-12-11T13:39:49.711Z",
    Date_ReminderSent: "2021-11-10T00:05:42.984Z",
    PayingFee: 46762,
    CarryingOutWork: false,
    WorkProposed: "Credit Card Account streamline payment",
    Address: "Suite 200",
    BuildingAppNo: 68764,
    BuildingPermitNo: 57157,
    Date_BuildingIssued: "March",
    Date_Commencing: "October",
    BondAmount: 50190,
    BondReceived: "23166",
    BondReturned: 1907,
    Date_FirstInspection: "2021-09-15T23:55:54.723Z",
    Date_LastInspection: "2021-07-05T19:54:30.986Z",
    TargetDate: "2021-05-16T09:59:29.842Z",
    CILFee: 75124,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-11-01T11:28:49.129Z",
    InspectionType: "Wooden",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "Nebraska SDD bypass",
    FacilityRegion: "Kansas",
    Description: "Investor",
    DateTarget: "2021-03-30T16:27:46.128Z",
    Source: "Applications",
    Location: "IA",
    RequestedBy: "61160280",
    ContactDetails: "887-737-6108",
    DueStatus: false,
    DateRecorded: "2021-09-26T09:52:40.642Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Total dynamic superstructure",
    ActionOfficer: "Computers",
    Coordinator: "Suite 509",
    DateWork: "2021-08-08T11:05:57.829Z",
    FileNumber: 50024,
    EnquiryRefNo: 17140,
    Event_ReferenceNoAdditional: 65090,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-08-25T04:02:26.096Z",
    ApplicantName: "Credit Card Account",
    Type: "and Sons",
    Risk: "Designer",
    DateDue: "2021-07-04T12:55:23.080Z",
    Register_Number: 2514,
    Contact: "Suite 164",
    ReferenceNumber: 9958,
    Date_Complete: "2021-09-15T22:18:32.619Z",
    Date_Construction: "2021-12-01T05:31:56.583Z",
    BarrierStandard: "BarrierStandard 30",
    BarrierType: "BarrierType 30",
    Date_CertificateOfCompliance: "2021-11-10T21:19:16.606Z",
    Pool_RegisteredDate: "2021-01-07T11:53:16.641Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-01T13:55:17.632Z",
    Date_WorkIssued: "2021-05-27T17:46:49.418Z",
    Work_CarriedOut: "Consultant",
    AmountOS: 2674,
    Flag_RenewalNoticeSent: true,
    Outcome: 46402,
    Regulation: "Creek lime",
    Date_Due: "2021-01-14T03:19:56.122Z",
    Date_NextInspection: "2021-07-03T09:08:09.984Z",
    Date_Start: "2021-03-18T18:33:40.969Z",
    Date_Occurred: "2021-05-23T10:53:03.475Z",
    ContactName: "Mrs. Jayde Gutkowski",
    CreatedDate: "2020-05-17T06:46:13.802Z",
    FeeType: "MT",
    AmountIssued: 68779,
    AmountOutstanding: 67071,
    CostCode: 9748,
    AmountPaid: 51330,
    ReceiptNumber: 66905,
    ReceiptDate: "2021-03-01T23:53:07.146Z",
    ReceiptedBy: "Facilitator",
    Method: "District",
    InvoiceNumber: 18741,
    DebtorNumber: 51702,
    SecurePay_Reference: "BMD",
    Contact2: "Paige_Runolfsdottir@example.org",
    LevelOfEnforcement: "708.83",
    Number: 72121,
    DateOffence: "2021-05-31T23:37:39.815Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 30",
    ePlanningCategory: "ePlanningCategory 30",
    ePlanningLodgedBy: "ePlanningLodgedBy 30",
    Comments: "Comments 30",
    ApplicationCategory_Name: "Home",
    ApplicationCategory_Description: "Health",
    Code: "05815",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Try to index the EXE driver, maybe it will parse the neural array!",
    PublishDescription:
      "You can't compress the alarm without indexing the 1080p COM capacitor!",
    Title: "SCSI",
    ConditionCode: "84320",
    Condition: "Group",
    Name: "Jovany Hilpert DVM",
    Multiplier: "Multiplier 30",
    Value: 54447,
    MinimumValue: 74975,
    TargetDays: "TargetDays 30",
    Category_1: "Toys",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 30",
    ComplianceInstruction: "ComplianceInstruction 30",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-16T04:22:47.029Z",
  },
  {
    ID: 31,
    ApplicationNumber: 23474,
    PermitNumber: 93839,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-09-26T20:15:32.762Z",
    RefNo: 46771,
    AssessmentNo: 89807,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Dr. Pamela Bradtke",
    Builder: "Allison Keebler V",
    Category: "Licensed Fresh Hat",
    BCA: "ADP",
    UseOfBuilding: false,
    ReasonForPermit: "UAE Dirham Liechtenstein San Marino",
    SiteAddress: "amelie.org",
    Locality: "AL",
    Owner: "Bethel Jacobs",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-12-25T23:02:28.999Z",
    Date_AllInspComplete: "2021-08-25T10:20:57.903Z",
    Date_DueToCommence: "2021-02-28T11:46:26.993Z",
    Date_Commenced: "2021-02-21T10:35:09.383Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "September",
    FileNo: 63478,
    ValuationNo: 23556,
    LegalDescription: "circuit PNG",
    BuildingOfficer: "Senior",
    Surveyor: "Money Market Account",
    SurveyorNumber: "48644654",
    StatDays: 29943,
    Date_Issued: "2021-05-01T01:39:55.548Z",
    Date_ReminderSent: "2021-05-31T17:46:43.022Z",
    PayingFee: 25656,
    CarryingOutWork: true,
    WorkProposed: "Concrete Investment Account",
    Address: "Suite 294",
    BuildingAppNo: 53057,
    BuildingPermitNo: 65999,
    Date_BuildingIssued: "December",
    Date_Commencing: "March",
    BondAmount: 32320,
    BondReceived: "27490-0872",
    BondReturned: 52006,
    Date_FirstInspection: "2021-02-10T07:18:43.897Z",
    Date_LastInspection: "2021-08-18T11:31:37.891Z",
    TargetDate: "2021-06-04T20:55:52.668Z",
    CILFee: 39563,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-10-25T02:23:59.097Z",
    InspectionType: "system",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "Optional Applications",
    FacilityRegion: "Georgia",
    Description: "Customer",
    DateTarget: "2021-11-01T19:04:50.842Z",
    Source: "Operations",
    Location: "MT",
    RequestedBy: "68307145",
    ContactDetails: "(768) 989-4156 x7009",
    DueStatus: true,
    DateRecorded: "2021-10-25T04:05:22.787Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Public-key bandwidth-monitored database",
    ActionOfficer: "Electronics",
    Coordinator: "Apt. 560",
    DateWork: "2021-07-25T06:40:41.747Z",
    FileNumber: 61810,
    EnquiryRefNo: 68117,
    Event_ReferenceNoAdditional: 25842,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-08-29T12:17:31.313Z",
    ApplicantName: "Home Loan Account",
    Type: "Group",
    Risk: "Rhode Island",
    DateDue: "2021-05-09T00:45:19.700Z",
    Register_Number: 45602,
    Contact: "Apt. 786",
    ReferenceNumber: 35263,
    Date_Complete: "2021-07-22T01:07:22.897Z",
    Date_Construction: "2021-03-28T01:51:38.197Z",
    BarrierStandard: "BarrierStandard 31",
    BarrierType: "BarrierType 31",
    Date_CertificateOfCompliance: "2021-04-01T23:49:07.086Z",
    Pool_RegisteredDate: "2021-11-18T21:37:34.407Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-11-29T04:40:48.924Z",
    Date_WorkIssued: "2021-12-22T09:01:32.335Z",
    Work_CarriedOut: "Producer",
    AmountOS: 1908,
    Flag_RenewalNoticeSent: false,
    Outcome: 20325,
    Regulation: "matrix systems Principal",
    Date_Due: "2021-09-06T13:05:01.474Z",
    Date_NextInspection: "2021-10-09T21:12:18.784Z",
    Date_Start: "2021-06-16T05:42:35.487Z",
    Date_Occurred: "2021-12-02T16:03:34.138Z",
    ContactName: "Winfield Larson",
    CreatedDate: "2020-03-12T05:55:31.488Z",
    FeeType: "ƒ",
    AmountIssued: 70341,
    AmountOutstanding: 838,
    CostCode: 4249,
    AmountPaid: 95635,
    ReceiptNumber: 85331,
    ReceiptDate: "2021-02-16T03:45:32.915Z",
    ReceiptedBy: "Facilitator",
    Method: "Central",
    InvoiceNumber: 33590,
    DebtorNumber: 11717,
    SecurePay_Reference: "YER",
    Contact2: "Kane_Wilderman@example.net",
    LevelOfEnforcement: "162.95",
    Number: 69828,
    DateOffence: "2021-03-22T23:35:35.527Z",
    RegisterType: "Representative",
    VicSmart: "VicSmart 31",
    ePlanningCategory: "ePlanningCategory 31",
    ePlanningLodgedBy: "ePlanningLodgedBy 31",
    Comments: "Comments 31",
    ApplicationCategory_Name: "Jewelery",
    ApplicationCategory_Description: "Grocery",
    Code: "96752-8286",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "generating the feed won't do anything, we need to bypass the open-source RAM pixel!",
    PublishDescription:
      "You can't override the system without parsing the auxiliary SDD interface!",
    Title: "firmware emulation Borders",
    ConditionCode: "16615",
    Condition: "LLC",
    Name: "Easter Rogahn",
    Multiplier: "Multiplier 31",
    Value: 58520,
    MinimumValue: 36655,
    TargetDays: "TargetDays 31",
    Category_1: "Garden",
    Category_2: "Grocery",
    ObservationSummary: "ObservationSummary 31",
    ComplianceInstruction: "ComplianceInstruction 31",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-14T02:16:44.836Z",
  },
  {
    ID: 32,
    ApplicationNumber: 55490,
    PermitNumber: 1763,
    PermitType: "payment",
    Date_PermitIssued: "2021-08-30T17:12:56.085Z",
    RefNo: 15486,
    AssessmentNo: 15190,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Presley Leffler DVM",
    Builder: "Janice Cartwright",
    Category: "Awesome Wooden Shirt",
    BCA: "bus",
    UseOfBuilding: true,
    ReasonForPermit: "Consultant compressing",
    SiteAddress: "lenora.name",
    Locality: "SC",
    Owner: "Miss Bernadette Ferry",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-05-17T00:12:33.799Z",
    Date_AllInspComplete: "2021-01-27T17:13:49.532Z",
    Date_DueToCommence: "2021-09-27T22:55:57.592Z",
    Date_Commenced: "2021-07-12T09:40:30.109Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "February",
    FileNo: 3699,
    ValuationNo: 85026,
    LegalDescription: "New York",
    BuildingOfficer: "Central",
    Surveyor: "Money Market Account",
    SurveyorNumber: "72595806",
    StatDays: 71273,
    Date_Issued: "2021-08-21T08:20:19.541Z",
    Date_ReminderSent: "2021-03-18T08:59:29.798Z",
    PayingFee: 85398,
    CarryingOutWork: false,
    WorkProposed: "Advanced",
    Address: "Apt. 118",
    BuildingAppNo: 5896,
    BuildingPermitNo: 1389,
    Date_BuildingIssued: "January",
    Date_Commencing: "June",
    BondAmount: 73868,
    BondReceived: "19398",
    BondReturned: 58705,
    Date_FirstInspection: "2021-11-08T23:54:26.599Z",
    Date_LastInspection: "2021-09-23T15:15:44.265Z",
    TargetDate: "2021-01-06T09:49:53.476Z",
    CILFee: 4716,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-08-08T15:57:10.897Z",
    InspectionType: "sensor",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "Metal auxiliary",
    FacilityRegion: "Alaska",
    Description: "Principal",
    DateTarget: "2021-11-15T11:15:06.056Z",
    Source: "Quality",
    Location: "ND",
    RequestedBy: "54749190",
    ContactDetails: "1-861-464-0212 x73373",
    DueStatus: true,
    DateRecorded: "2021-10-16T06:17:17.974Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Persistent dedicated circuit",
    ActionOfficer: "Movies",
    Coordinator: "Apt. 922",
    DateWork: "2021-07-14T08:55:39.030Z",
    FileNumber: 81143,
    EnquiryRefNo: 94163,
    Event_ReferenceNoAdditional: 61536,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-03-27T23:57:12.466Z",
    ApplicantName: "Home Loan Account",
    Type: "Inc",
    Risk: "Factors Marketing channels",
    DateDue: "2021-05-28T22:50:39.995Z",
    Register_Number: 80,
    Contact: "Apt. 568",
    ReferenceNumber: 34501,
    Date_Complete: "2021-07-25T22:23:44.447Z",
    Date_Construction: "2021-05-06T15:48:33.864Z",
    BarrierStandard: "BarrierStandard 32",
    BarrierType: "BarrierType 32",
    Date_CertificateOfCompliance: "2021-02-09T20:11:26.038Z",
    Pool_RegisteredDate: "2021-08-09T17:56:01.679Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-23T17:47:34.914Z",
    Date_WorkIssued: "2021-12-03T17:10:08.709Z",
    Work_CarriedOut: "Analyst",
    AmountOS: 37195,
    Flag_RenewalNoticeSent: false,
    Outcome: 74298,
    Regulation: "interface full-range",
    Date_Due: "2021-02-11T00:56:14.649Z",
    Date_NextInspection: "2021-12-12T17:11:10.963Z",
    Date_Start: "2021-04-15T05:04:33.567Z",
    Date_Occurred: "2021-12-07T09:31:06.587Z",
    ContactName: "Sarah Nienow V",
    CreatedDate: "2020-02-09T05:30:22.476Z",
    FeeType: "Lek",
    AmountIssued: 25018,
    AmountOutstanding: 64164,
    CostCode: 24728,
    AmountPaid: 91290,
    ReceiptNumber: 94185,
    ReceiptDate: "2021-10-14T09:00:27.266Z",
    ReceiptedBy: "Manager",
    Method: "Human",
    InvoiceNumber: 43616,
    DebtorNumber: 49577,
    SecurePay_Reference: "CAD",
    Contact2: "Faustino_Prosacco@example.org",
    LevelOfEnforcement: "963.69",
    Number: 1191,
    DateOffence: "2021-09-30T13:39:18.337Z",
    RegisterType: "Manager",
    VicSmart: "VicSmart 32",
    ePlanningCategory: "ePlanningCategory 32",
    ePlanningLodgedBy: "ePlanningLodgedBy 32",
    Comments: "Comments 32",
    ApplicationCategory_Name: "Automotive",
    ApplicationCategory_Description: "Computers",
    Code: "84730-8244",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "You can't connect the interface without quantifying the auxiliary SAS panel!",
    PublishDescription:
      "You can't synthesize the microchip without bypassing the bluetooth SMTP panel!",
    Title: "override Officer Bedfordshire",
    ConditionCode: "24106",
    Condition: "Inc",
    Name: "Gloria Gislason",
    Multiplier: "Multiplier 32",
    Value: 47713,
    MinimumValue: 24898,
    TargetDays: "TargetDays 32",
    Category_1: "Health",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 32",
    ComplianceInstruction: "ComplianceInstruction 32",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-11T16:27:32.296Z",
  },
  {
    ID: 33,
    ApplicationNumber: 67085,
    PermitNumber: 82260,
    PermitType: "invoice",
    Date_PermitIssued: "2021-12-29T07:31:31.919Z",
    RefNo: 5316,
    AssessmentNo: 75383,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Aubree Runolfsson",
    Builder: "Tiara Weber",
    Category: "Fantastic Concrete Chair",
    BCA: "invoice",
    UseOfBuilding: true,
    ReasonForPermit: "channels",
    SiteAddress: "julie.com",
    Locality: "MD",
    Owner: "Anissa Howell PhD",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-01-07T07:19:58.773Z",
    Date_AllInspComplete: "2021-09-03T06:28:08.166Z",
    Date_DueToCommence: "2021-08-02T15:44:05.011Z",
    Date_Commenced: "2021-01-20T06:11:30.135Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "February",
    FileNo: 54556,
    ValuationNo: 89227,
    LegalDescription: "panel",
    BuildingOfficer: "National",
    Surveyor: "Investment Account",
    SurveyorNumber: "97343302",
    StatDays: 10404,
    Date_Issued: "2021-08-10T20:34:05.118Z",
    Date_ReminderSent: "2021-12-12T15:39:16.912Z",
    PayingFee: 45883,
    CarryingOutWork: false,
    WorkProposed: "e-business Open-architected",
    Address: "Suite 822",
    BuildingAppNo: 96445,
    BuildingPermitNo: 55866,
    Date_BuildingIssued: "August",
    Date_Commencing: "January",
    BondAmount: 3182,
    BondReceived: "55774-1801",
    BondReturned: 23586,
    Date_FirstInspection: "2021-06-12T18:30:44.269Z",
    Date_LastInspection: "2021-01-01T03:10:21.604Z",
    TargetDate: "2021-05-12T10:42:24.268Z",
    CILFee: 93667,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-04-02T01:13:55.886Z",
    InspectionType: "innovate",
    Officer: "Money Market Account",
    Compliance: true,
    Reason: "Down-sized back up payment",
    FacilityRegion: "Louisiana",
    Description: "Lead",
    DateTarget: "2021-11-07T07:07:11.226Z",
    Source: "Configuration",
    Location: "TX",
    RequestedBy: "11619094",
    ContactDetails: "1-894-162-3260 x5799",
    DueStatus: true,
    DateRecorded: "2021-03-27T08:54:36.754Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Enterprise-wide executive challenge",
    ActionOfficer: "Beauty",
    Coordinator: "Apt. 131",
    DateWork: "2021-05-04T08:05:45.417Z",
    FileNumber: 94687,
    EnquiryRefNo: 9491,
    Event_ReferenceNoAdditional: 2691,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-06-06T12:04:08.581Z",
    ApplicantName: "Home Loan Account",
    Type: "LLC",
    Risk: "Chief",
    DateDue: "2021-08-31T17:24:34.034Z",
    Register_Number: 68761,
    Contact: "Suite 085",
    ReferenceNumber: 81432,
    Date_Complete: "2021-06-16T16:01:12.875Z",
    Date_Construction: "2020-12-31T19:13:36.130Z",
    BarrierStandard: "BarrierStandard 33",
    BarrierType: "BarrierType 33",
    Date_CertificateOfCompliance: "2021-03-03T00:02:43.935Z",
    Pool_RegisteredDate: "2021-10-22T13:08:50.922Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-23T14:44:49.884Z",
    Date_WorkIssued: "2021-09-13T00:07:20.480Z",
    Work_CarriedOut: "Facilitator",
    AmountOS: 65421,
    Flag_RenewalNoticeSent: true,
    Outcome: 61447,
    Regulation: "capability",
    Date_Due: "2021-05-12T03:12:52.511Z",
    Date_NextInspection: "2021-08-31T21:53:08.883Z",
    Date_Start: "2021-02-19T20:42:25.498Z",
    Date_Occurred: "2021-10-25T23:45:23.670Z",
    ContactName: "Adrianna Cole",
    CreatedDate: "2020-11-04T22:17:43.860Z",
    FeeType: "$",
    AmountIssued: 63640,
    AmountOutstanding: 60227,
    CostCode: 49396,
    AmountPaid: 87384,
    ReceiptNumber: 58087,
    ReceiptDate: "2021-09-13T11:45:12.875Z",
    ReceiptedBy: "Orchestrator",
    Method: "Forward",
    InvoiceNumber: 98984,
    DebtorNumber: 58679,
    SecurePay_Reference: "HUF",
    Contact2: "Christina_Trantow@example.net",
    LevelOfEnforcement: "661.74",
    Number: 75615,
    DateOffence: "2021-12-17T13:08:23.812Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 33",
    ePlanningCategory: "ePlanningCategory 33",
    ePlanningLodgedBy: "ePlanningLodgedBy 33",
    Comments: "Comments 33",
    ApplicationCategory_Name: "Industrial",
    ApplicationCategory_Description: "Industrial",
    Code: "93036-4174",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "Use the solid state SMTP hard drive, then you can bypass the multi-byte interface!",
    PublishDescription: "We need to calculate the digital SCSI panel!",
    Title: "Park",
    ConditionCode: "27797-4470",
    Condition: "LLC",
    Name: "Mr. German Mosciski",
    Multiplier: "Multiplier 33",
    Value: 28203,
    MinimumValue: 73830,
    TargetDays: "TargetDays 33",
    Category_1: "Shoes",
    Category_2: "Movies",
    ObservationSummary: "ObservationSummary 33",
    ComplianceInstruction: "ComplianceInstruction 33",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-19T00:41:16.293Z",
  },
  {
    ID: 34,
    ApplicationNumber: 7551,
    PermitNumber: 35918,
    PermitType: "deposit",
    Date_PermitIssued: "2021-01-23T06:28:24.917Z",
    RefNo: 98731,
    AssessmentNo: 45198,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Erwin Greenfelder",
    Builder: "Carolina Rohan",
    Category: "Tasty Cotton Gloves",
    BCA: "transmit",
    UseOfBuilding: false,
    ReasonForPermit: "Architect",
    SiteAddress: "lois.name",
    Locality: "MN",
    Owner: "Kolby Farrell DVM",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-01-06T16:15:01.776Z",
    Date_AllInspComplete: "2021-12-18T21:33:37.525Z",
    Date_DueToCommence: "2021-11-20T07:16:05.550Z",
    Date_Commenced: "2021-08-20T19:20:34.519Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "March",
    FileNo: 8809,
    ValuationNo: 85394,
    LegalDescription: "panel",
    BuildingOfficer: "Human",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "42270131",
    StatDays: 81108,
    Date_Issued: "2021-08-16T04:39:07.316Z",
    Date_ReminderSent: "2021-12-10T18:45:24.545Z",
    PayingFee: 62529,
    CarryingOutWork: true,
    WorkProposed: "matrix Drive",
    Address: "Suite 785",
    BuildingAppNo: 4993,
    BuildingPermitNo: 99298,
    Date_BuildingIssued: "April",
    Date_Commencing: "March",
    BondAmount: 41228,
    BondReceived: "60919-9837",
    BondReturned: 29436,
    Date_FirstInspection: "2021-06-05T06:09:16.558Z",
    Date_LastInspection: "2021-10-12T12:29:39.347Z",
    TargetDate: "2021-05-07T00:21:40.794Z",
    CILFee: 89831,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-05-27T15:22:34.890Z",
    InspectionType: "San Marino",
    Officer: "Money Market Account",
    Compliance: false,
    Reason: "compressing initiatives",
    FacilityRegion: "New Hampshire",
    Description: "Lead",
    DateTarget: "2021-03-29T13:14:54.128Z",
    Source: "Metrics",
    Location: "MA",
    RequestedBy: "62770291",
    ContactDetails: "057-337-0331 x63105",
    DueStatus: false,
    DateRecorded: "2021-03-04T22:52:17.510Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Balanced reciprocal support",
    ActionOfficer: "Industrial",
    Coordinator: "Apt. 469",
    DateWork: "2021-05-08T22:02:23.858Z",
    FileNumber: 31493,
    EnquiryRefNo: 87629,
    Event_ReferenceNoAdditional: 20940,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-01-10T03:20:22.792Z",
    ApplicantName: "Personal Loan Account",
    Type: "Inc",
    Risk: "Automotive",
    DateDue: "2021-12-15T01:04:15.616Z",
    Register_Number: 89796,
    Contact: "Suite 139",
    ReferenceNumber: 62429,
    Date_Complete: "2021-10-02T17:52:02.712Z",
    Date_Construction: "2021-08-19T23:23:55.719Z",
    BarrierStandard: "BarrierStandard 34",
    BarrierType: "BarrierType 34",
    Date_CertificateOfCompliance: "2021-06-09T13:40:55.754Z",
    Pool_RegisteredDate: "2021-10-27T02:15:16.988Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-02-10T17:51:44.776Z",
    Date_WorkIssued: "2021-01-17T12:32:21.502Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 8518,
    Flag_RenewalNoticeSent: true,
    Outcome: 45365,
    Regulation: "fuchsia Down-sized",
    Date_Due: "2021-10-08T04:49:38.092Z",
    Date_NextInspection: "2021-12-17T00:01:47.678Z",
    Date_Start: "2021-07-17T07:23:56.647Z",
    Date_Occurred: "2021-07-03T10:23:53.252Z",
    ContactName: "Deanna Conn",
    CreatedDate: "2020-02-10T04:55:51.346Z",
    FeeType: "£",
    AmountIssued: 21601,
    AmountOutstanding: 31828,
    CostCode: 81661,
    AmountPaid: 70955,
    ReceiptNumber: 30002,
    ReceiptDate: "2021-10-08T23:49:34.203Z",
    ReceiptedBy: "Technician",
    Method: "Forward",
    InvoiceNumber: 26172,
    DebtorNumber: 69099,
    SecurePay_Reference: "AUD",
    Contact2: "Caden.Ankunding94@example.org",
    LevelOfEnforcement: "327.50",
    Number: 67862,
    DateOffence: "2021-01-22T21:46:30.359Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 34",
    ePlanningCategory: "ePlanningCategory 34",
    ePlanningLodgedBy: "ePlanningLodgedBy 34",
    Comments: "Comments 34",
    ApplicationCategory_Name: "Grocery",
    ApplicationCategory_Description: "Computers",
    Code: "29616",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "I'll calculate the multi-byte EXE card, that should circuit the AI alarm!",
    PublishDescription: "We need to program the digital HDD port!",
    Title: "Small violet",
    ConditionCode: "74466-9235",
    Condition: "LLC",
    Name: "Sterling Kemmer",
    Multiplier: "Multiplier 34",
    Value: 14718,
    MinimumValue: 80614,
    TargetDays: "TargetDays 34",
    Category_1: "Automotive",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 34",
    ComplianceInstruction: "ComplianceInstruction 34",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-17T16:23:15.538Z",
  },
  {
    ID: 35,
    ApplicationNumber: 64988,
    PermitNumber: 39881,
    PermitType: "deposit",
    Date_PermitIssued: "2021-10-01T07:48:42.621Z",
    RefNo: 47341,
    AssessmentNo: 34116,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Dr. Haven Conroy",
    Builder: "Aryanna Parker",
    Category: "Practical Wooden Shirt",
    BCA: "Director",
    UseOfBuilding: true,
    ReasonForPermit: "East Caribbean Dollar",
    SiteAddress: "brendan.com",
    Locality: "SD",
    Owner: "Marjory Jones",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-03-09T17:04:48.222Z",
    Date_AllInspComplete: "2021-07-15T09:19:39.108Z",
    Date_DueToCommence: "2021-01-02T14:19:51.761Z",
    Date_Commenced: "2021-01-06T14:01:04.422Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "September",
    FileNo: 76330,
    ValuationNo: 55490,
    LegalDescription: "Plastic deposit hacking",
    BuildingOfficer: "Central",
    Surveyor: "Investment Account",
    SurveyorNumber: "91169956",
    StatDays: 55115,
    Date_Issued: "2021-03-21T15:00:20.898Z",
    Date_ReminderSent: "2021-12-30T05:20:07.665Z",
    PayingFee: 78858,
    CarryingOutWork: false,
    WorkProposed: "monitor software black",
    Address: "Apt. 777",
    BuildingAppNo: 81450,
    BuildingPermitNo: 56912,
    Date_BuildingIssued: "September",
    Date_Commencing: "August",
    BondAmount: 75520,
    BondReceived: "39112",
    BondReturned: 7748,
    Date_FirstInspection: "2021-04-01T01:58:13.495Z",
    Date_LastInspection: "2021-05-11T12:55:41.443Z",
    TargetDate: "2021-12-11T13:41:24.340Z",
    CILFee: 53040,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-09-05T07:51:35.470Z",
    InspectionType: "supply-chains",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "card THX HDD",
    FacilityRegion: "Connecticut",
    Description: "District",
    DateTarget: "2021-05-10T18:01:52.672Z",
    Source: "Operations",
    Location: "NJ",
    RequestedBy: "18844415",
    ContactDetails: "1-504-223-3931 x0501",
    DueStatus: false,
    DateRecorded: "2021-10-28T03:19:54.750Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Decentralized contextually-based approach",
    ActionOfficer: "Clothing",
    Coordinator: "Apt. 560",
    DateWork: "2021-12-16T09:13:05.238Z",
    FileNumber: 95477,
    EnquiryRefNo: 98056,
    Event_ReferenceNoAdditional: 94655,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-06-20T05:22:56.965Z",
    ApplicantName: "Investment Account",
    Type: "Group",
    Risk: "action-items clear-thinking bandwidth",
    DateDue: "2021-03-29T07:11:52.097Z",
    Register_Number: 15460,
    Contact: "Apt. 022",
    ReferenceNumber: 40878,
    Date_Complete: "2021-05-14T02:16:25.759Z",
    Date_Construction: "2021-11-23T18:03:57.685Z",
    BarrierStandard: "BarrierStandard 35",
    BarrierType: "BarrierType 35",
    Date_CertificateOfCompliance: "2021-02-02T09:10:59.408Z",
    Pool_RegisteredDate: "2021-01-20T06:24:27.272Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-13T09:26:38.308Z",
    Date_WorkIssued: "2021-07-14T07:59:05.120Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 64312,
    Flag_RenewalNoticeSent: false,
    Outcome: 83526,
    Regulation: "Connecticut invoice",
    Date_Due: "2021-11-25T05:37:49.375Z",
    Date_NextInspection: "2021-02-24T14:38:27.900Z",
    Date_Start: "2021-03-19T16:44:43.335Z",
    Date_Occurred: "2021-06-29T10:50:49.878Z",
    ContactName: "Julio O'Reilly",
    CreatedDate: "2020-02-26T06:53:54.364Z",
    FeeType: "$",
    AmountIssued: 95615,
    AmountOutstanding: 56958,
    CostCode: 82960,
    AmountPaid: 14702,
    ReceiptNumber: 44203,
    ReceiptDate: "2021-03-14T10:45:25.150Z",
    ReceiptedBy: "Manager",
    Method: "Dynamic",
    InvoiceNumber: 61221,
    DebtorNumber: 10692,
    SecurePay_Reference: "AED",
    Contact2: "Brooklyn20@example.net",
    LevelOfEnforcement: "978.72",
    Number: 27404,
    DateOffence: "2021-08-01T04:18:28.505Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 35",
    ePlanningCategory: "ePlanningCategory 35",
    ePlanningLodgedBy: "ePlanningLodgedBy 35",
    Comments: "Comments 35",
    ApplicationCategory_Name: "Garden",
    ApplicationCategory_Description: "Books",
    Code: "35026-6409",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName: "We need to generate the online SDD capacitor!",
    PublishDescription:
      "I'll navigate the multi-byte JBOD capacitor, that should card the SSL bandwidth!",
    Title: "synthesize generating",
    ConditionCode: "81471",
    Condition: "Group",
    Name: "Mireille Hahn",
    Multiplier: "Multiplier 35",
    Value: 50331,
    MinimumValue: 9861,
    TargetDays: "TargetDays 35",
    Category_1: "Health",
    Category_2: "Jewelery",
    ObservationSummary: "ObservationSummary 35",
    ComplianceInstruction: "ComplianceInstruction 35",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-09-21T12:59:41.235Z",
  },
  {
    ID: 36,
    ApplicationNumber: 75296,
    PermitNumber: 24215,
    PermitType: "deposit",
    Date_PermitIssued: "2021-12-20T17:21:16.381Z",
    RefNo: 59874,
    AssessmentNo: 33890,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Barry Hauck Sr.",
    Builder: "Joshua Hoppe",
    Category: "Refined Plastic Bacon",
    BCA: "Chicken",
    UseOfBuilding: true,
    ReasonForPermit: "indexing Cross-platform",
    SiteAddress: "rodger.com",
    Locality: "PA",
    Owner: "Brennan Lebsack",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-10-02T14:02:57.614Z",
    Date_AllInspComplete: "2021-03-03T04:40:29.960Z",
    Date_DueToCommence: "2021-05-01T14:24:02.539Z",
    Date_Commenced: "2021-08-05T11:30:13.848Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "June",
    FileNo: 71226,
    ValuationNo: 34234,
    LegalDescription: "British Indian Ocean Territory (Chagos Archipelago)",
    BuildingOfficer: "Dynamic",
    Surveyor: "Home Loan Account",
    SurveyorNumber: "13656610",
    StatDays: 58986,
    Date_Issued: "2021-11-07T16:21:28.377Z",
    Date_ReminderSent: "2021-06-06T09:11:57.244Z",
    PayingFee: 8076,
    CarryingOutWork: false,
    WorkProposed: "HDD",
    Address: "Suite 261",
    BuildingAppNo: 42543,
    BuildingPermitNo: 28635,
    Date_BuildingIssued: "May",
    Date_Commencing: "February",
    BondAmount: 15057,
    BondReceived: "56445-2843",
    BondReturned: 57264,
    Date_FirstInspection: "2021-10-22T11:46:08.234Z",
    Date_LastInspection: "2021-05-09T12:44:05.202Z",
    TargetDate: "2021-07-01T00:41:19.398Z",
    CILFee: 40986,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-07-23T16:17:46.386Z",
    InspectionType: "Rand Loti",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "Hong Kong Dollar Triple-buffered",
    FacilityRegion: "Wisconsin",
    Description: "Customer",
    DateTarget: "2021-03-11T21:04:18.013Z",
    Source: "Branding",
    Location: "WI",
    RequestedBy: "19630304",
    ContactDetails: "(495) 505-0571",
    DueStatus: true,
    DateRecorded: "2021-01-16T09:01:08.761Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Assimilated 4th generation budgetary management",
    ActionOfficer: "Movies",
    Coordinator: "Apt. 750",
    DateWork: "2021-07-23T14:37:49.098Z",
    FileNumber: 20145,
    EnquiryRefNo: 59520,
    Event_ReferenceNoAdditional: 53949,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-03-07T07:16:38.704Z",
    ApplicantName: "Checking Account",
    Type: "Group",
    Risk: "back-end Orchestrator",
    DateDue: "2021-10-31T15:20:25.126Z",
    Register_Number: 40465,
    Contact: "Suite 691",
    ReferenceNumber: 2793,
    Date_Complete: "2021-11-26T11:04:30.806Z",
    Date_Construction: "2021-04-28T15:44:29.010Z",
    BarrierStandard: "BarrierStandard 36",
    BarrierType: "BarrierType 36",
    Date_CertificateOfCompliance: "2021-08-11T13:16:20.957Z",
    Pool_RegisteredDate: "2021-05-09T17:46:01.528Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-05T08:02:06.926Z",
    Date_WorkIssued: "2021-12-05T09:36:03.506Z",
    Work_CarriedOut: "Agent",
    AmountOS: 39038,
    Flag_RenewalNoticeSent: true,
    Outcome: 81313,
    Regulation: "Field copying",
    Date_Due: "2021-02-26T10:14:15.035Z",
    Date_NextInspection: "2021-01-10T04:01:46.516Z",
    Date_Start: "2021-06-12T16:58:50.263Z",
    Date_Occurred: "2021-05-05T21:50:12.987Z",
    ContactName: "Leda Schowalter",
    CreatedDate: "2020-10-27T04:24:07.091Z",
    FeeType: "Kč",
    AmountIssued: 35687,
    AmountOutstanding: 29870,
    CostCode: 48232,
    AmountPaid: 25065,
    ReceiptNumber: 27408,
    ReceiptDate: "2021-03-20T14:09:01.136Z",
    ReceiptedBy: "Architect",
    Method: "District",
    InvoiceNumber: 85522,
    DebtorNumber: 21689,
    SecurePay_Reference: "CVE",
    Contact2: "Bettie13@example.net",
    LevelOfEnforcement: "183.75",
    Number: 75888,
    DateOffence: "2021-08-24T17:04:38.921Z",
    RegisterType: "Supervisor",
    VicSmart: "VicSmart 36",
    ePlanningCategory: "ePlanningCategory 36",
    ePlanningLodgedBy: "ePlanningLodgedBy 36",
    Comments: "Comments 36",
    ApplicationCategory_Name: "Sports",
    ApplicationCategory_Description: "Computers",
    Code: "87339-2934",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "You can't back up the port without generating the wireless SQL hard drive!",
    PublishDescription:
      "I'll override the open-source RSS alarm, that should circuit the THX driver!",
    Title: "reboot payment",
    ConditionCode: "25475-9868",
    Condition: "LLC",
    Name: "Domenic Hauck",
    Multiplier: "Multiplier 36",
    Value: 7541,
    MinimumValue: 47074,
    TargetDays: "TargetDays 36",
    Category_1: "Clothing",
    Category_2: "Books",
    ObservationSummary: "ObservationSummary 36",
    ComplianceInstruction: "ComplianceInstruction 36",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-09-23T00:11:27.304Z",
  },
  {
    ID: 37,
    ApplicationNumber: 24534,
    PermitNumber: 29024,
    PermitType: "deposit",
    Date_PermitIssued: "2021-11-29T17:19:52.211Z",
    RefNo: 66457,
    AssessmentNo: 1837,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Shayne Russel",
    Builder: "Alisha Cassin",
    Category: "Rustic Metal Pants",
    BCA: "Enhanced",
    UseOfBuilding: false,
    ReasonForPermit: "Kids",
    SiteAddress: "buck.name",
    Locality: "OK",
    Owner: "Korey Hermann",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-12-05T11:30:46.484Z",
    Date_AllInspComplete: "2021-10-24T22:20:49.474Z",
    Date_DueToCommence: "2021-12-06T03:44:16.614Z",
    Date_Commenced: "2021-08-16T08:24:24.554Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "February",
    FileNo: 93374,
    ValuationNo: 91157,
    LegalDescription: "24 hour",
    BuildingOfficer: "Principal",
    Surveyor: "Savings Account",
    SurveyorNumber: "12053808",
    StatDays: 1267,
    Date_Issued: "2021-12-15T07:08:17.505Z",
    Date_ReminderSent: "2021-09-20T02:55:24.890Z",
    PayingFee: 83178,
    CarryingOutWork: true,
    WorkProposed: "Unbranded value-added",
    Address: "Apt. 994",
    BuildingAppNo: 211,
    BuildingPermitNo: 72297,
    Date_BuildingIssued: "May",
    Date_Commencing: "December",
    BondAmount: 14544,
    BondReceived: "42887-8900",
    BondReturned: 10858,
    Date_FirstInspection: "2021-01-06T06:57:16.434Z",
    Date_LastInspection: "2021-10-15T00:21:56.600Z",
    TargetDate: "2021-03-23T11:32:20.316Z",
    CILFee: 48012,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-01-30T18:56:17.249Z",
    InspectionType: "port",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "Light",
    FacilityRegion: "Alaska",
    Description: "Dynamic",
    DateTarget: "2021-03-23T08:00:47.637Z",
    Source: "Identity",
    Location: "UT",
    RequestedBy: "16685798",
    ContactDetails: "(605) 714-6240",
    DueStatus: true,
    DateRecorded: "2021-01-26T17:16:12.604Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Operative demand-driven array",
    ActionOfficer: "Garden",
    Coordinator: "Apt. 544",
    DateWork: "2021-07-14T14:09:18.464Z",
    FileNumber: 66750,
    EnquiryRefNo: 70646,
    Event_ReferenceNoAdditional: 65110,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-05-17T16:38:06.659Z",
    ApplicantName: "Money Market Account",
    Type: "Inc",
    Risk: "wireless Avon",
    DateDue: "2021-08-10T23:15:04.588Z",
    Register_Number: 74158,
    Contact: "Suite 753",
    ReferenceNumber: 53346,
    Date_Complete: "2021-06-25T13:27:01.446Z",
    Date_Construction: "2021-02-20T05:48:58.539Z",
    BarrierStandard: "BarrierStandard 37",
    BarrierType: "BarrierType 37",
    Date_CertificateOfCompliance: "2021-03-30T13:55:55.025Z",
    Pool_RegisteredDate: "2021-09-01T11:41:36.489Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-02-12T20:24:11.089Z",
    Date_WorkIssued: "2021-05-03T19:53:31.849Z",
    Work_CarriedOut: "Administrator",
    AmountOS: 92566,
    Flag_RenewalNoticeSent: true,
    Outcome: 18751,
    Regulation: "lime",
    Date_Due: "2021-04-20T12:25:24.160Z",
    Date_NextInspection: "2021-11-24T01:32:31.362Z",
    Date_Start: "2021-04-19T21:14:03.728Z",
    Date_Occurred: "2021-03-11T17:46:35.426Z",
    ContactName: "Dr. Marcelo Brekke",
    CreatedDate: "2020-09-09T04:22:51.926Z",
    FeeType: "J$",
    AmountIssued: 23307,
    AmountOutstanding: 27554,
    CostCode: 88197,
    AmountPaid: 85540,
    ReceiptNumber: 55499,
    ReceiptDate: "2021-11-12T09:19:33.794Z",
    ReceiptedBy: "Strategist",
    Method: "Principal",
    InvoiceNumber: 40126,
    DebtorNumber: 61682,
    SecurePay_Reference: "GYD",
    Contact2: "Josefa_OKon@example.net",
    LevelOfEnforcement: "754.29",
    Number: 11577,
    DateOffence: "2021-05-24T03:45:33.014Z",
    RegisterType: "Officer",
    VicSmart: "VicSmart 37",
    ePlanningCategory: "ePlanningCategory 37",
    ePlanningLodgedBy: "ePlanningLodgedBy 37",
    Comments: "Comments 37",
    ApplicationCategory_Name: "Electronics",
    ApplicationCategory_Description: "Outdoors",
    Code: "80401",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "If we transmit the protocol, we can get to the XSS pixel through the digital USB capacitor!",
    PublishDescription:
      "You can't index the firewall without calculating the mobile SMTP array!",
    Title: "purple Checking Account",
    ConditionCode: "26334",
    Condition: "Group",
    Name: "Mitchel Gerlach",
    Multiplier: "Multiplier 37",
    Value: 22792,
    MinimumValue: 66089,
    TargetDays: "TargetDays 37",
    Category_1: "Shoes",
    Category_2: "Home",
    ObservationSummary: "ObservationSummary 37",
    ComplianceInstruction: "ComplianceInstruction 37",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-08-16T19:32:17.791Z",
  },
  {
    ID: 38,
    ApplicationNumber: 9253,
    PermitNumber: 78157,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-09-25T12:24:16.746Z",
    RefNo: 15953,
    AssessmentNo: 10366,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Kennedy Keebler",
    Builder: "Gillian Daugherty",
    Category: "Gorgeous Rubber Table",
    BCA: "Officer",
    UseOfBuilding: false,
    ReasonForPermit: "Refined Plastic Chicken Industrial",
    SiteAddress: "adolph.info",
    Locality: "MA",
    Owner: "Darrion Witting Jr.",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-07-09T22:09:38.809Z",
    Date_AllInspComplete: "2021-10-21T05:35:52.678Z",
    Date_DueToCommence: "2021-09-28T23:58:04.203Z",
    Date_Commenced: "2021-02-04T05:09:59.983Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "July",
    FileNo: 65639,
    ValuationNo: 96402,
    LegalDescription: "Personal Loan Account",
    BuildingOfficer: "Product",
    Surveyor: "Savings Account",
    SurveyorNumber: "90790565",
    StatDays: 58563,
    Date_Issued: "2021-11-22T05:15:44.125Z",
    Date_ReminderSent: "2021-03-07T15:27:06.335Z",
    PayingFee: 29648,
    CarryingOutWork: false,
    WorkProposed: "Home Loan Account 1080p",
    Address: "Suite 700",
    BuildingAppNo: 5426,
    BuildingPermitNo: 46146,
    Date_BuildingIssued: "January",
    Date_Commencing: "April",
    BondAmount: 49289,
    BondReceived: "48742",
    BondReturned: 39290,
    Date_FirstInspection: "2021-05-14T00:51:57.064Z",
    Date_LastInspection: "2021-06-12T22:40:33.094Z",
    TargetDate: "2021-08-27T08:15:07.302Z",
    CILFee: 94601,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-06-02T04:14:22.686Z",
    InspectionType: "Gorgeous Rubber Chips",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "Guarani neural Tala",
    FacilityRegion: "Florida",
    Description: "Investor",
    DateTarget: "2021-01-12T06:02:52.445Z",
    Source: "Program",
    Location: "AK",
    RequestedBy: "44843373",
    ContactDetails: "(220) 621-1258 x12085",
    DueStatus: false,
    DateRecorded: "2021-07-25T09:25:01.005Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Stand-alone motivating collaboration",
    ActionOfficer: "Baby",
    Coordinator: "Apt. 090",
    DateWork: "2021-01-10T19:41:23.806Z",
    FileNumber: 84751,
    EnquiryRefNo: 90796,
    Event_ReferenceNoAdditional: 75374,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-10-02T23:27:19.807Z",
    ApplicantName: "Credit Card Account",
    Type: "Inc",
    Risk: "Branding",
    DateDue: "2021-08-30T14:52:19.308Z",
    Register_Number: 23973,
    Contact: "Apt. 314",
    ReferenceNumber: 7809,
    Date_Complete: "2021-07-26T00:45:49.663Z",
    Date_Construction: "2021-03-21T21:01:59.276Z",
    BarrierStandard: "BarrierStandard 38",
    BarrierType: "BarrierType 38",
    Date_CertificateOfCompliance: "2021-09-17T17:01:59.161Z",
    Pool_RegisteredDate: "2021-12-06T19:52:58.463Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-01-17T03:42:56.359Z",
    Date_WorkIssued: "2021-11-02T09:51:05.607Z",
    Work_CarriedOut: "Associate",
    AmountOS: 6999,
    Flag_RenewalNoticeSent: false,
    Outcome: 84944,
    Regulation: "black",
    Date_Due: "2021-10-16T10:23:53.094Z",
    Date_NextInspection: "2021-01-17T03:06:03.080Z",
    Date_Start: "2021-07-16T05:22:31.886Z",
    Date_Occurred: "2021-07-21T03:56:21.842Z",
    ContactName: "Kaden King",
    CreatedDate: "2020-06-03T19:16:14.774Z",
    FeeType: "kr",
    AmountIssued: 66720,
    AmountOutstanding: 44743,
    CostCode: 80919,
    AmountPaid: 16464,
    ReceiptNumber: 7846,
    ReceiptDate: "2021-11-30T01:52:01.565Z",
    ReceiptedBy: "Executive",
    Method: "Principal",
    InvoiceNumber: 25918,
    DebtorNumber: 79728,
    SecurePay_Reference: "MOP",
    Contact2: "Rogelio81@example.org",
    LevelOfEnforcement: "138.50",
    Number: 32457,
    DateOffence: "2021-05-19T23:52:01.918Z",
    RegisterType: "Assistant",
    VicSmart: "VicSmart 38",
    ePlanningCategory: "ePlanningCategory 38",
    ePlanningLodgedBy: "ePlanningLodgedBy 38",
    Comments: "Comments 38",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Tools",
    Code: "25703-2484",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Use the multi-byte XSS driver, then you can reboot the solid state firewall!",
    PublishDescription:
      "Try to quantify the HTTP panel, maybe it will calculate the digital feed!",
    Title: "Switzerland Operations deposit",
    ConditionCode: "27585",
    Condition: "Inc",
    Name: "Brycen Weimann II",
    Multiplier: "Multiplier 38",
    Value: 27235,
    MinimumValue: 59239,
    TargetDays: "TargetDays 38",
    Category_1: "Tools",
    Category_2: "Music",
    ObservationSummary: "ObservationSummary 38",
    ComplianceInstruction: "ComplianceInstruction 38",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-26T01:40:23.003Z",
  },
  {
    ID: 39,
    ApplicationNumber: 37636,
    PermitNumber: 23077,
    PermitType: "invoice",
    Date_PermitIssued: "2021-11-08T03:37:19.069Z",
    RefNo: 35597,
    AssessmentNo: 8568,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Ms. Felicity Rippin",
    Builder: "Nelda Konopelski",
    Category: "Gorgeous Cotton Shoes",
    BCA: "withdrawal",
    UseOfBuilding: true,
    ReasonForPermit: "Berkshire pink",
    SiteAddress: "jalon.biz",
    Locality: "OK",
    Owner: "Leif Dibbert",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-03-01T04:51:54.926Z",
    Date_AllInspComplete: "2021-04-16T18:38:06.981Z",
    Date_DueToCommence: "2021-05-10T14:29:12.677Z",
    Date_Commenced: "2021-10-23T20:34:30.187Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "August",
    FileNo: 3899,
    ValuationNo: 53894,
    LegalDescription: "Specialist Roads",
    BuildingOfficer: "Product",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "31582871",
    StatDays: 48590,
    Date_Issued: "2021-12-18T20:25:59.290Z",
    Date_ReminderSent: "2021-06-06T22:04:56.839Z",
    PayingFee: 2387,
    CarryingOutWork: true,
    WorkProposed: "Keyboard Concrete Table",
    Address: "Apt. 821",
    BuildingAppNo: 60449,
    BuildingPermitNo: 6472,
    Date_BuildingIssued: "January",
    Date_Commencing: "November",
    BondAmount: 9130,
    BondReceived: "82034-0095",
    BondReturned: 91879,
    Date_FirstInspection: "2021-10-21T15:32:02.284Z",
    Date_LastInspection: "2021-10-18T04:44:01.561Z",
    TargetDate: "2021-11-23T03:33:14.131Z",
    CILFee: 78634,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-08-19T11:15:33.060Z",
    InspectionType: "ROI",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "Surinam Dollar",
    FacilityRegion: "North Carolina",
    Description: "Direct",
    DateTarget: "2021-09-15T00:09:38.785Z",
    Source: "Optimization",
    Location: "SD",
    RequestedBy: "64531742",
    ContactDetails: "115-471-2109 x76217",
    DueStatus: false,
    DateRecorded: "2021-06-06T03:21:47.429Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Multi-layered coherent approach",
    ActionOfficer: "Toys",
    Coordinator: "Suite 362",
    DateWork: "2021-05-06T13:14:57.751Z",
    FileNumber: 10739,
    EnquiryRefNo: 18595,
    Event_ReferenceNoAdditional: 11094,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-05-17T02:50:06.056Z",
    ApplicantName: "Savings Account",
    Type: "LLC",
    Risk: "mobile program Bahrain",
    DateDue: "2021-11-18T02:05:50.454Z",
    Register_Number: 62630,
    Contact: "Apt. 845",
    ReferenceNumber: 36985,
    Date_Complete: "2021-04-29T02:58:31.423Z",
    Date_Construction: "2021-08-20T18:02:12.161Z",
    BarrierStandard: "BarrierStandard 39",
    BarrierType: "BarrierType 39",
    Date_CertificateOfCompliance: "2021-04-08T21:28:33.876Z",
    Pool_RegisteredDate: "2021-02-20T19:03:30.240Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-01-19T05:11:53.771Z",
    Date_WorkIssued: "2021-09-14T11:06:26.419Z",
    Work_CarriedOut: "Coordinator",
    AmountOS: 82571,
    Flag_RenewalNoticeSent: true,
    Outcome: 97509,
    Regulation: "Manager access",
    Date_Due: "2021-06-28T04:13:22.340Z",
    Date_NextInspection: "2021-05-15T15:02:46.437Z",
    Date_Start: "2021-02-17T19:59:03.846Z",
    Date_Occurred: "2021-06-18T21:01:45.996Z",
    ContactName: "Dean Abbott",
    CreatedDate: "2020-07-12T13:57:55.572Z",
    FeeType: "₩",
    AmountIssued: 90477,
    AmountOutstanding: 86018,
    CostCode: 12114,
    AmountPaid: 35850,
    ReceiptNumber: 41003,
    ReceiptDate: "2021-03-19T02:27:04.818Z",
    ReceiptedBy: "Coordinator",
    Method: "Investor",
    InvoiceNumber: 6931,
    DebtorNumber: 3988,
    SecurePay_Reference: "BMD",
    Contact2: "Larissa_Schuppe81@example.net",
    LevelOfEnforcement: "803.84",
    Number: 65136,
    DateOffence: "2021-05-11T05:53:58.324Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 39",
    ePlanningCategory: "ePlanningCategory 39",
    ePlanningLodgedBy: "ePlanningLodgedBy 39",
    Comments: "Comments 39",
    ApplicationCategory_Name: "Jewelery",
    ApplicationCategory_Description: "Baby",
    Code: "72704",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "I'll calculate the mobile RSS panel, that should monitor the RAM bus!",
    PublishDescription:
      "overriding the system won't do anything, we need to program the wireless PNG transmitter!",
    Title: "bus partnerships",
    ConditionCode: "96355-8658",
    Condition: "and Sons",
    Name: "Aimee Senger",
    Multiplier: "Multiplier 39",
    Value: 25099,
    MinimumValue: 70334,
    TargetDays: "TargetDays 39",
    Category_1: "Movies",
    Category_2: "Grocery",
    ObservationSummary: "ObservationSummary 39",
    ComplianceInstruction: "ComplianceInstruction 39",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-19T20:40:01.256Z",
  },
  {
    ID: 40,
    ApplicationNumber: 21926,
    PermitNumber: 64536,
    PermitType: "deposit",
    Date_PermitIssued: "2021-11-27T05:17:49.146Z",
    RefNo: 63352,
    AssessmentNo: 86937,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Neva Mann",
    Builder: "Kennedy Pouros",
    Category: "Practical Wooden Chair",
    BCA: "Buckinghamshire",
    UseOfBuilding: true,
    ReasonForPermit: "collaboration",
    SiteAddress: "lavinia.biz",
    Locality: "TN",
    Owner: "Amanda Bahringer",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-28T19:07:57.901Z",
    Date_AllInspComplete: "2021-03-02T15:19:05.403Z",
    Date_DueToCommence: "2021-02-19T22:00:59.353Z",
    Date_Commenced: "2021-08-21T01:08:41.344Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "August",
    FileNo: 54189,
    ValuationNo: 18278,
    LegalDescription: "program Chips",
    BuildingOfficer: "Principal",
    Surveyor: "Savings Account",
    SurveyorNumber: "78300446",
    StatDays: 34860,
    Date_Issued: "2021-12-24T19:24:43.569Z",
    Date_ReminderSent: "2021-10-10T21:39:35.823Z",
    PayingFee: 42802,
    CarryingOutWork: true,
    WorkProposed: "Licensed distributed Planner",
    Address: "Apt. 274",
    BuildingAppNo: 15291,
    BuildingPermitNo: 14924,
    Date_BuildingIssued: "September",
    Date_Commencing: "February",
    BondAmount: 91511,
    BondReceived: "88030",
    BondReturned: 59640,
    Date_FirstInspection: "2021-03-30T06:45:22.617Z",
    Date_LastInspection: "2021-03-11T02:19:04.429Z",
    TargetDate: "2021-03-12T20:57:31.034Z",
    CILFee: 99225,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-05-31T20:49:53.367Z",
    InspectionType: "Principal",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "Cambridgeshire Danish Krone",
    FacilityRegion: "Idaho",
    Description: "Lead",
    DateTarget: "2021-05-31T00:01:28.044Z",
    Source: "Brand",
    Location: "AR",
    RequestedBy: "37744706",
    ContactDetails: "(122) 255-7434 x4994",
    DueStatus: true,
    DateRecorded: "2021-06-02T23:06:20.416Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Versatile eco-centric orchestration",
    ActionOfficer: "Jewelery",
    Coordinator: "Suite 520",
    DateWork: "2021-03-25T03:14:57.125Z",
    FileNumber: 24692,
    EnquiryRefNo: 72699,
    Event_ReferenceNoAdditional: 17785,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-10-12T10:11:49.437Z",
    ApplicantName: "Home Loan Account",
    Type: "and Sons",
    Risk: "Checking Account card",
    DateDue: "2021-04-13T00:31:39.663Z",
    Register_Number: 62307,
    Contact: "Apt. 297",
    ReferenceNumber: 61885,
    Date_Complete: "2021-02-01T14:20:11.205Z",
    Date_Construction: "2021-02-13T09:36:53.702Z",
    BarrierStandard: "BarrierStandard 40",
    BarrierType: "BarrierType 40",
    Date_CertificateOfCompliance: "2021-08-31T13:14:53.870Z",
    Pool_RegisteredDate: "2021-11-24T22:23:29.310Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-24T22:05:54.593Z",
    Date_WorkIssued: "2021-07-11T16:16:08.168Z",
    Work_CarriedOut: "Architect",
    AmountOS: 58658,
    Flag_RenewalNoticeSent: true,
    Outcome: 5948,
    Regulation: "View salmon yellow",
    Date_Due: "2021-06-22T06:01:54.178Z",
    Date_NextInspection: "2021-07-24T23:20:19.779Z",
    Date_Start: "2021-08-22T00:55:51.528Z",
    Date_Occurred: "2021-04-16T10:36:50.128Z",
    ContactName: "Derick Bahringer",
    CreatedDate: "2020-05-03T07:43:21.559Z",
    FeeType: "ден",
    AmountIssued: 43674,
    AmountOutstanding: 91520,
    CostCode: 42149,
    AmountPaid: 37232,
    ReceiptNumber: 3268,
    ReceiptDate: "2021-08-31T23:37:39.981Z",
    ReceiptedBy: "Technician",
    Method: "Dynamic",
    InvoiceNumber: 42345,
    DebtorNumber: 31942,
    SecurePay_Reference: "KHR",
    Contact2: "Mabel_Hayes@example.org",
    LevelOfEnforcement: "576.68",
    Number: 30005,
    DateOffence: "2021-08-18T03:51:47.997Z",
    RegisterType: "Assistant",
    VicSmart: "VicSmart 40",
    ePlanningCategory: "ePlanningCategory 40",
    ePlanningLodgedBy: "ePlanningLodgedBy 40",
    Comments: "Comments 40",
    ApplicationCategory_Name: "Toys",
    ApplicationCategory_Description: "Beauty",
    Code: "00859-9906",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "I'll hack the back-end SAS feed, that should system the XML driver!",
    PublishDescription:
      "The FTP transmitter is down, bypass the optical driver so we can override the SQL firewall!",
    Title: "Fundamental",
    ConditionCode: "86901-0332",
    Condition: "Inc",
    Name: "Miss Colby Wilderman",
    Multiplier: "Multiplier 40",
    Value: 33437,
    MinimumValue: 59690,
    TargetDays: "TargetDays 40",
    Category_1: "Outdoors",
    Category_2: "Industrial",
    ObservationSummary: "ObservationSummary 40",
    ComplianceInstruction: "ComplianceInstruction 40",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-09-25T23:24:09.328Z",
  },
  {
    ID: 41,
    ApplicationNumber: 30672,
    PermitNumber: 97984,
    PermitType: "invoice",
    Date_PermitIssued: "2021-12-16T05:50:12.641Z",
    RefNo: 90608,
    AssessmentNo: 53662,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Hellen Hirthe",
    Builder: "Ms. Kira Johns",
    Category: "Handcrafted Fresh Keyboard",
    BCA: "Identity",
    UseOfBuilding: false,
    ReasonForPermit: "New Hampshire back up",
    SiteAddress: "garrick.biz",
    Locality: "OH",
    Owner: "Delpha Jakubowski",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-03-22T13:24:35.688Z",
    Date_AllInspComplete: "2021-06-23T06:41:36.258Z",
    Date_DueToCommence: "2021-05-19T01:02:01.771Z",
    Date_Commenced: "2021-07-03T21:16:33.511Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "March",
    FileNo: 39920,
    ValuationNo: 50999,
    LegalDescription: "Industrial hardware discrete",
    BuildingOfficer: "Lead",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "37323515",
    StatDays: 49466,
    Date_Issued: "2021-02-01T01:22:32.533Z",
    Date_ReminderSent: "2021-01-13T00:14:46.988Z",
    PayingFee: 9419,
    CarryingOutWork: false,
    WorkProposed: "Dynamic relationships",
    Address: "Suite 577",
    BuildingAppNo: 38202,
    BuildingPermitNo: 59903,
    Date_BuildingIssued: "April",
    Date_Commencing: "January",
    BondAmount: 5784,
    BondReceived: "49422-5945",
    BondReturned: 87047,
    Date_FirstInspection: "2021-03-23T08:38:31.399Z",
    Date_LastInspection: "2021-01-03T07:29:34.371Z",
    TargetDate: "2021-03-02T14:33:48.981Z",
    CILFee: 35904,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-06-15T22:56:26.999Z",
    InspectionType: "Rubber",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "Investment Account Analyst Senior",
    FacilityRegion: "Maine",
    Description: "Direct",
    DateTarget: "2021-11-05T22:59:21.453Z",
    Source: "Division",
    Location: "ME",
    RequestedBy: "57412291",
    ContactDetails: "1-295-027-1501 x55756",
    DueStatus: false,
    DateRecorded: "2021-01-09T14:55:56.955Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Versatile asynchronous support",
    ActionOfficer: "Clothing",
    Coordinator: "Apt. 521",
    DateWork: "2021-11-20T18:18:49.620Z",
    FileNumber: 84774,
    EnquiryRefNo: 9773,
    Event_ReferenceNoAdditional: 79618,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-05-09T17:08:30.951Z",
    ApplicantName: "Personal Loan Account",
    Type: "and Sons",
    Risk: "sensor Liaison",
    DateDue: "2021-10-05T10:22:14.290Z",
    Register_Number: 15056,
    Contact: "Suite 975",
    ReferenceNumber: 46548,
    Date_Complete: "2021-10-18T10:11:36.210Z",
    Date_Construction: "2021-01-11T12:33:58.514Z",
    BarrierStandard: "BarrierStandard 41",
    BarrierType: "BarrierType 41",
    Date_CertificateOfCompliance: "2021-09-24T16:08:35.913Z",
    Pool_RegisteredDate: "2021-02-06T23:30:52.743Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-20T04:03:19.239Z",
    Date_WorkIssued: "2021-12-22T09:55:53.633Z",
    Work_CarriedOut: "Representative",
    AmountOS: 92921,
    Flag_RenewalNoticeSent: true,
    Outcome: 38715,
    Regulation: "disintermediate Cotton solid state",
    Date_Due: "2021-12-17T10:25:16.940Z",
    Date_NextInspection: "2021-03-14T00:08:23.593Z",
    Date_Start: "2021-10-24T11:34:06.824Z",
    Date_Occurred: "2021-10-17T04:01:55.367Z",
    ContactName: "Chaya Blanda",
    CreatedDate: "2020-01-11T21:40:38.984Z",
    FeeType: "Rp",
    AmountIssued: 49999,
    AmountOutstanding: 26005,
    CostCode: 29339,
    AmountPaid: 91769,
    ReceiptNumber: 77944,
    ReceiptDate: "2021-12-13T16:27:13.523Z",
    ReceiptedBy: "Developer",
    Method: "Chief",
    InvoiceNumber: 28097,
    DebtorNumber: 52432,
    SecurePay_Reference: "ZAR LSL",
    Contact2: "Lorenzo_Paucek3@example.com",
    LevelOfEnforcement: "163.69",
    Number: 73081,
    DateOffence: "2021-07-07T12:42:11.619Z",
    RegisterType: "Administrator",
    VicSmart: "VicSmart 41",
    ePlanningCategory: "ePlanningCategory 41",
    ePlanningLodgedBy: "ePlanningLodgedBy 41",
    Comments: "Comments 41",
    ApplicationCategory_Name: "Grocery",
    ApplicationCategory_Description: "Electronics",
    Code: "09912",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "The JBOD system is down, calculate the 1080p capacitor so we can input the SCSI protocol!",
    PublishDescription:
      "Try to parse the SSL card, maybe it will transmit the auxiliary monitor!",
    Title: "Kids",
    ConditionCode: "45125-2957",
    Condition: "Inc",
    Name: "Natalie Hartmann Sr.",
    Multiplier: "Multiplier 41",
    Value: 21599,
    MinimumValue: 72540,
    TargetDays: "TargetDays 41",
    Category_1: "Books",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 41",
    ComplianceInstruction: "ComplianceInstruction 41",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-20T00:34:14.710Z",
  },
  {
    ID: 42,
    ApplicationNumber: 97602,
    PermitNumber: 89123,
    PermitType: "invoice",
    Date_PermitIssued: "2021-04-08T03:08:40.256Z",
    RefNo: 57642,
    AssessmentNo: 66531,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Eleanora Franecki III",
    Builder: "Jennie Lehner",
    Category: "Licensed Granite Chips",
    BCA: "Guernsey",
    UseOfBuilding: false,
    ReasonForPermit: "purple Cambridgeshire Cape Verde",
    SiteAddress: "ephraim.org",
    Locality: "KY",
    Owner: "Colleen Corwin",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-12-26T20:58:37.269Z",
    Date_AllInspComplete: "2021-09-15T13:46:41.816Z",
    Date_DueToCommence: "2021-06-03T13:39:48.103Z",
    Date_Commenced: "2021-10-08T22:06:47.719Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "September",
    FileNo: 81264,
    ValuationNo: 59740,
    LegalDescription: "streamline invoice withdrawal",
    BuildingOfficer: "Chief",
    Surveyor: "Savings Account",
    SurveyorNumber: "24694151",
    StatDays: 87349,
    Date_Issued: "2021-03-26T08:26:11.866Z",
    Date_ReminderSent: "2021-06-18T07:19:59.849Z",
    PayingFee: 65161,
    CarryingOutWork: false,
    WorkProposed: "Convertible Marks parallelism",
    Address: "Apt. 506",
    BuildingAppNo: 90037,
    BuildingPermitNo: 10847,
    Date_BuildingIssued: "August",
    Date_Commencing: "February",
    BondAmount: 61928,
    BondReceived: "76431",
    BondReturned: 90906,
    Date_FirstInspection: "2021-11-06T20:14:58.697Z",
    Date_LastInspection: "2021-09-07T22:03:40.132Z",
    TargetDate: "2021-01-10T12:54:00.432Z",
    CILFee: 41593,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-02-04T19:10:09.817Z",
    InspectionType: "Monaco",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "functionalities Baby",
    FacilityRegion: "Alaska",
    Description: "Global",
    DateTarget: "2021-06-09T23:37:16.611Z",
    Source: "Implementation",
    Location: "IL",
    RequestedBy: "24958981",
    ContactDetails: "828.640.4328 x1388",
    DueStatus: true,
    DateRecorded: "2021-07-08T05:43:47.930Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Streamlined optimizing open system",
    ActionOfficer: "Computers",
    Coordinator: "Apt. 538",
    DateWork: "2021-05-07T02:25:59.773Z",
    FileNumber: 34500,
    EnquiryRefNo: 87059,
    Event_ReferenceNoAdditional: 27330,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-09-14T10:14:17.176Z",
    ApplicantName: "Investment Account",
    Type: "and Sons",
    Risk: "Granite Corporate payment",
    DateDue: "2021-07-08T06:20:47.003Z",
    Register_Number: 47997,
    Contact: "Apt. 229",
    ReferenceNumber: 89283,
    Date_Complete: "2021-08-05T19:16:35.691Z",
    Date_Construction: "2021-11-09T09:15:50.021Z",
    BarrierStandard: "BarrierStandard 42",
    BarrierType: "BarrierType 42",
    Date_CertificateOfCompliance: "2021-01-13T21:44:40.058Z",
    Pool_RegisteredDate: "2021-05-22T17:55:53.425Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-28T15:28:07.420Z",
    Date_WorkIssued: "2021-08-25T23:07:19.827Z",
    Work_CarriedOut: "Developer",
    AmountOS: 49044,
    Flag_RenewalNoticeSent: true,
    Outcome: 55437,
    Regulation: "Sleek Fresh Chicken Rubber",
    Date_Due: "2021-11-21T16:11:04.034Z",
    Date_NextInspection: "2021-07-27T09:08:13.009Z",
    Date_Start: "2021-06-10T12:00:20.351Z",
    Date_Occurred: "2021-02-09T06:32:05.829Z",
    ContactName: "Lucy Gaylord",
    CreatedDate: "2020-03-01T15:00:01.522Z",
    FeeType: "MT",
    AmountIssued: 54477,
    AmountOutstanding: 43221,
    CostCode: 1100,
    AmountPaid: 80844,
    ReceiptNumber: 31222,
    ReceiptDate: "2021-01-31T07:19:01.208Z",
    ReceiptedBy: "Architect",
    Method: "International",
    InvoiceNumber: 77233,
    DebtorNumber: 19394,
    SecurePay_Reference: "SOS",
    Contact2: "Treva_Parisian@example.net",
    LevelOfEnforcement: "654.95",
    Number: 40302,
    DateOffence: "2021-12-26T05:14:55.264Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 42",
    ePlanningCategory: "ePlanningCategory 42",
    ePlanningLodgedBy: "ePlanningLodgedBy 42",
    Comments: "Comments 42",
    ApplicationCategory_Name: "Outdoors",
    ApplicationCategory_Description: "Music",
    Code: "18698-5918",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "I'll parse the multi-byte XSS circuit, that should driver the PCI bandwidth!",
    PublishDescription:
      "The JBOD capacitor is down, generate the wireless pixel so we can quantify the ADP hard drive!",
    Title: "card",
    ConditionCode: "58551",
    Condition: "LLC",
    Name: "Vada Greenholt",
    Multiplier: "Multiplier 42",
    Value: 25345,
    MinimumValue: 29091,
    TargetDays: "TargetDays 42",
    Category_1: "Jewelery",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 42",
    ComplianceInstruction: "ComplianceInstruction 42",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-03T16:29:22.030Z",
  },
  {
    ID: 43,
    ApplicationNumber: 60677,
    PermitNumber: 61884,
    PermitType: "deposit",
    Date_PermitIssued: "2021-03-16T18:25:04.608Z",
    RefNo: 77313,
    AssessmentNo: 23118,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Jamie Hackett",
    Builder: "Jayce Hartmann",
    Category: "Awesome Steel Bacon",
    BCA: "Savings Account",
    UseOfBuilding: false,
    ReasonForPermit: "parsing",
    SiteAddress: "chris.info",
    Locality: "PA",
    Owner: "Ivy Cartwright",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-06-22T04:43:05.808Z",
    Date_AllInspComplete: "2021-03-23T22:54:48.996Z",
    Date_DueToCommence: "2021-02-12T09:26:19.317Z",
    Date_Commenced: "2021-08-06T07:24:53.208Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "July",
    FileNo: 59294,
    ValuationNo: 68453,
    LegalDescription: "Operations",
    BuildingOfficer: "Forward",
    Surveyor: "Investment Account",
    SurveyorNumber: "01545585",
    StatDays: 38473,
    Date_Issued: "2021-03-30T22:20:01.859Z",
    Date_ReminderSent: "2021-02-13T08:36:03.688Z",
    PayingFee: 21541,
    CarryingOutWork: true,
    WorkProposed: "Industrial",
    Address: "Apt. 789",
    BuildingAppNo: 98094,
    BuildingPermitNo: 89815,
    Date_BuildingIssued: "December",
    Date_Commencing: "March",
    BondAmount: 45618,
    BondReceived: "85505-5375",
    BondReturned: 9169,
    Date_FirstInspection: "2021-02-06T02:22:44.547Z",
    Date_LastInspection: "2021-02-13T22:06:32.261Z",
    TargetDate: "2021-02-14T18:05:37.810Z",
    CILFee: 24295,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-02-22T14:16:39.990Z",
    InspectionType: "Practical",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "withdrawal one-to-one",
    FacilityRegion: "Mississippi",
    Description: "Senior",
    DateTarget: "2021-07-20T08:04:02.266Z",
    Source: "Marketing",
    Location: "MS",
    RequestedBy: "17956559",
    ContactDetails: "404-548-3786 x5908",
    DueStatus: true,
    DateRecorded: "2021-04-25T09:51:47.571Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Exclusive multimedia core",
    ActionOfficer: "Home",
    Coordinator: "Suite 635",
    DateWork: "2021-10-27T22:18:21.776Z",
    FileNumber: 96660,
    EnquiryRefNo: 93181,
    Event_ReferenceNoAdditional: 70486,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-04-09T05:39:20.888Z",
    ApplicantName: "Savings Account",
    Type: "Inc",
    Risk: "core Books disintermediate",
    DateDue: "2021-08-15T21:54:27.332Z",
    Register_Number: 75532,
    Contact: "Suite 127",
    ReferenceNumber: 81150,
    Date_Complete: "2021-12-05T00:49:35.319Z",
    Date_Construction: "2021-07-13T15:20:50.308Z",
    BarrierStandard: "BarrierStandard 43",
    BarrierType: "BarrierType 43",
    Date_CertificateOfCompliance: "2021-10-25T17:21:22.216Z",
    Pool_RegisteredDate: "2021-11-06T07:31:04.363Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-27T11:23:53.127Z",
    Date_WorkIssued: "2021-05-07T15:34:06.197Z",
    Work_CarriedOut: "Architect",
    AmountOS: 28032,
    Flag_RenewalNoticeSent: true,
    Outcome: 67544,
    Regulation: "open-source Beauty invoice",
    Date_Due: "2021-05-18T23:51:35.877Z",
    Date_NextInspection: "2021-02-21T23:34:07.782Z",
    Date_Start: "2021-07-04T05:56:41.934Z",
    Date_Occurred: "2021-12-23T05:35:04.482Z",
    ContactName: "Tanya Halvorson Jr.",
    CreatedDate: "2020-05-05T16:04:30.812Z",
    FeeType: "£",
    AmountIssued: 97978,
    AmountOutstanding: 70592,
    CostCode: 10955,
    AmountPaid: 40634,
    ReceiptNumber: 40368,
    ReceiptDate: "2021-09-29T00:01:06.004Z",
    ReceiptedBy: "Strategist",
    Method: "Future",
    InvoiceNumber: 28891,
    DebtorNumber: 83121,
    SecurePay_Reference: "UAH",
    Contact2: "Pink.Gibson33@example.org",
    LevelOfEnforcement: "685.30",
    Number: 64746,
    DateOffence: "2021-06-01T03:46:59.173Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 43",
    ePlanningCategory: "ePlanningCategory 43",
    ePlanningLodgedBy: "ePlanningLodgedBy 43",
    Comments: "Comments 43",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Garden",
    Code: "23802-3787",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName: "We need to copy the haptic JSON alarm!",
    PublishDescription:
      "I'll quantify the virtual AI microchip, that should port the IB sensor!",
    Title: "green Buckinghamshire bluetooth",
    ConditionCode: "21513-7831",
    Condition: "and Sons",
    Name: "Ladarius Thiel",
    Multiplier: "Multiplier 43",
    Value: 43724,
    MinimumValue: 53993,
    TargetDays: "TargetDays 43",
    Category_1: "Health",
    Category_2: "Games",
    ObservationSummary: "ObservationSummary 43",
    ComplianceInstruction: "ComplianceInstruction 43",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-09T04:51:40.550Z",
  },
  {
    ID: 44,
    ApplicationNumber: 82014,
    PermitNumber: 74077,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-05-18T08:36:25.118Z",
    RefNo: 14416,
    AssessmentNo: 14836,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Karianne Gutmann",
    Builder: "Wanda Cormier",
    Category: "Incredible Frozen Computer",
    BCA: "North Carolina",
    UseOfBuilding: true,
    ReasonForPermit: "hard drive mission-critical",
    SiteAddress: "adolfo.org",
    Locality: "IN",
    Owner: "Precious Bogan",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-08-19T11:06:43.899Z",
    Date_AllInspComplete: "2021-09-08T01:03:24.605Z",
    Date_DueToCommence: "2021-09-06T19:10:54.355Z",
    Date_Commenced: "2021-09-28T13:37:50.187Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "June",
    FileNo: 84673,
    ValuationNo: 64412,
    LegalDescription: "brand violet",
    BuildingOfficer: "Forward",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "89353260",
    StatDays: 90491,
    Date_Issued: "2021-08-02T21:13:29.348Z",
    Date_ReminderSent: "2021-01-30T03:39:36.855Z",
    PayingFee: 15549,
    CarryingOutWork: true,
    WorkProposed: "Cambridgeshire deliver Interactions",
    Address: "Suite 599",
    BuildingAppNo: 39936,
    BuildingPermitNo: 22687,
    Date_BuildingIssued: "July",
    Date_Commencing: "November",
    BondAmount: 52860,
    BondReceived: "15438-8111",
    BondReturned: 84233,
    Date_FirstInspection: "2021-12-09T23:28:31.696Z",
    Date_LastInspection: "2021-05-04T09:54:44.267Z",
    TargetDate: "2021-08-17T18:48:42.631Z",
    CILFee: 82271,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-11-15T18:35:12.554Z",
    InspectionType: "withdrawal",
    Officer: "Money Market Account",
    Compliance: true,
    Reason: "input Iraqi Dinar JSON",
    FacilityRegion: "Virginia",
    Description: "Product",
    DateTarget: "2021-03-27T12:17:45.959Z",
    Source: "Interactions",
    Location: "NE",
    RequestedBy: "49176610",
    ContactDetails: "1-042-459-5294",
    DueStatus: false,
    DateRecorded: "2021-10-22T21:58:46.437Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Exclusive client-server secured line",
    ActionOfficer: "Toys",
    Coordinator: "Apt. 006",
    DateWork: "2021-02-20T23:33:17.577Z",
    FileNumber: 67080,
    EnquiryRefNo: 89474,
    Event_ReferenceNoAdditional: 52740,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-06-15T19:14:06.253Z",
    ApplicantName: "Money Market Account",
    Type: "LLC",
    Risk: "Gorgeous budgetary management",
    DateDue: "2021-04-02T17:58:36.265Z",
    Register_Number: 43279,
    Contact: "Suite 078",
    ReferenceNumber: 68188,
    Date_Complete: "2021-05-19T20:37:33.471Z",
    Date_Construction: "2021-02-05T14:39:56.737Z",
    BarrierStandard: "BarrierStandard 44",
    BarrierType: "BarrierType 44",
    Date_CertificateOfCompliance: "2021-01-14T13:52:38.038Z",
    Pool_RegisteredDate: "2021-07-22T13:07:25.661Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-18T20:21:13.337Z",
    Date_WorkIssued: "2021-07-11T12:50:00.674Z",
    Work_CarriedOut: "Agent",
    AmountOS: 82434,
    Flag_RenewalNoticeSent: true,
    Outcome: 63429,
    Regulation: "connecting",
    Date_Due: "2021-09-10T11:45:08.863Z",
    Date_NextInspection: "2021-06-04T06:23:12.824Z",
    Date_Start: "2021-12-28T18:46:26.237Z",
    Date_Occurred: "2021-07-29T12:26:19.737Z",
    ContactName: "Rocio Bruen",
    CreatedDate: "2020-07-02T14:47:08.317Z",
    FeeType: "$",
    AmountIssued: 81305,
    AmountOutstanding: 40878,
    CostCode: 536,
    AmountPaid: 77577,
    ReceiptNumber: 24093,
    ReceiptDate: "2021-08-24T03:55:37.838Z",
    ReceiptedBy: "Liaison",
    Method: "Forward",
    InvoiceNumber: 71916,
    DebtorNumber: 69382,
    SecurePay_Reference: "RSD",
    Contact2: "Danny.Kuhn@example.com",
    LevelOfEnforcement: "687.94",
    Number: 79094,
    DateOffence: "2021-04-24T17:12:23.702Z",
    RegisterType: "Producer",
    VicSmart: "VicSmart 44",
    ePlanningCategory: "ePlanningCategory 44",
    ePlanningLodgedBy: "ePlanningLodgedBy 44",
    Comments: "Comments 44",
    ApplicationCategory_Name: "Jewelery",
    ApplicationCategory_Description: "Outdoors",
    Code: "49440",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName: "We need to program the mobile SQL bus!",
    PublishDescription:
      "If we navigate the protocol, we can get to the THX firewall through the digital SMS pixel!",
    Title: "synthesize Business-focused",
    ConditionCode: "13016-0542",
    Condition: "LLC",
    Name: "Madelynn Cruickshank",
    Multiplier: "Multiplier 44",
    Value: 86359,
    MinimumValue: 55358,
    TargetDays: "TargetDays 44",
    Category_1: "Baby",
    Category_2: "Music",
    ObservationSummary: "ObservationSummary 44",
    ComplianceInstruction: "ComplianceInstruction 44",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-26T02:58:19.743Z",
  },
  {
    ID: 45,
    ApplicationNumber: 35532,
    PermitNumber: 20349,
    PermitType: "payment",
    Date_PermitIssued: "2020-12-31T09:04:53.472Z",
    RefNo: 63721,
    AssessmentNo: 37465,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Armando Ratke",
    Builder: "Franz Pfeffer",
    Category: "Tasty Wooden Table",
    BCA: "syndicate",
    UseOfBuilding: false,
    ReasonForPermit: "turquoise virtual Cross-platform",
    SiteAddress: "misty.name",
    Locality: "WI",
    Owner: "Savion Kirlin DVM",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-27T14:53:52.719Z",
    Date_AllInspComplete: "2021-08-28T07:13:59.748Z",
    Date_DueToCommence: "2021-01-20T09:41:27.813Z",
    Date_Commenced: "2021-05-12T05:14:44.572Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "March",
    FileNo: 99583,
    ValuationNo: 25579,
    LegalDescription: "Palladium",
    BuildingOfficer: "Direct",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "84065056",
    StatDays: 14079,
    Date_Issued: "2021-10-04T22:13:56.440Z",
    Date_ReminderSent: "2021-04-03T05:58:59.860Z",
    PayingFee: 36341,
    CarryingOutWork: true,
    WorkProposed: "Metal",
    Address: "Suite 994",
    BuildingAppNo: 51225,
    BuildingPermitNo: 22720,
    Date_BuildingIssued: "January",
    Date_Commencing: "December",
    BondAmount: 22875,
    BondReceived: "73190",
    BondReturned: 76370,
    Date_FirstInspection: "2021-10-07T15:44:12.160Z",
    Date_LastInspection: "2021-08-01T14:51:34.351Z",
    TargetDate: "2021-05-16T18:08:50.256Z",
    CILFee: 17795,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-10-09T19:44:14.125Z",
    InspectionType: "Rand",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "Clothing",
    FacilityRegion: "Hawaii",
    Description: "Dynamic",
    DateTarget: "2021-07-18T19:52:15.884Z",
    Source: "Quality",
    Location: "MI",
    RequestedBy: "16529962",
    ContactDetails: "873-860-3814",
    DueStatus: false,
    DateRecorded: "2021-08-27T03:10:39.924Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Multi-lateral next generation workforce",
    ActionOfficer: "Clothing",
    Coordinator: "Suite 401",
    DateWork: "2021-08-23T12:26:30.817Z",
    FileNumber: 953,
    EnquiryRefNo: 40374,
    Event_ReferenceNoAdditional: 33632,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-05-26T19:14:33.135Z",
    ApplicantName: "Money Market Account",
    Type: "Group",
    Risk: "invoice Executive",
    DateDue: "2021-12-27T06:46:50.474Z",
    Register_Number: 38061,
    Contact: "Suite 700",
    ReferenceNumber: 82845,
    Date_Complete: "2021-06-21T02:04:53.675Z",
    Date_Construction: "2021-01-23T20:22:08.287Z",
    BarrierStandard: "BarrierStandard 45",
    BarrierType: "BarrierType 45",
    Date_CertificateOfCompliance: "2021-12-22T22:41:22.658Z",
    Pool_RegisteredDate: "2021-10-21T01:56:27.719Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-19T19:56:02.955Z",
    Date_WorkIssued: "2021-10-28T18:08:08.905Z",
    Work_CarriedOut: "Engineer",
    AmountOS: 34157,
    Flag_RenewalNoticeSent: true,
    Outcome: 86463,
    Regulation: "Table",
    Date_Due: "2021-07-17T21:30:23.435Z",
    Date_NextInspection: "2021-12-06T13:56:05.709Z",
    Date_Start: "2021-07-01T11:46:04.859Z",
    Date_Occurred: "2021-03-15T13:26:07.600Z",
    ContactName: "Jakob Grady",
    CreatedDate: "2020-11-18T07:28:28.939Z",
    FeeType: "lei",
    AmountIssued: 78239,
    AmountOutstanding: 45434,
    CostCode: 18070,
    AmountPaid: 79004,
    ReceiptNumber: 25942,
    ReceiptDate: "2021-10-22T06:40:19.117Z",
    ReceiptedBy: "Officer",
    Method: "Central",
    InvoiceNumber: 48178,
    DebtorNumber: 42197,
    SecurePay_Reference: "LAK",
    Contact2: "Arvel_Strosin@example.net",
    LevelOfEnforcement: "534.76",
    Number: 60486,
    DateOffence: "2021-01-08T04:58:28.593Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 45",
    ePlanningCategory: "ePlanningCategory 45",
    ePlanningLodgedBy: "ePlanningLodgedBy 45",
    Comments: "Comments 45",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Books",
    Code: "99583-9161",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "If we parse the protocol, we can get to the SAS feed through the solid state SMS microchip!",
    PublishDescription:
      "Try to navigate the XSS matrix, maybe it will parse the 1080p monitor!",
    Title: "orchestrate users zero tolerance",
    ConditionCode: "95961",
    Condition: "and Sons",
    Name: "Miss Edward Yost",
    Multiplier: "Multiplier 45",
    Value: 26036,
    MinimumValue: 11277,
    TargetDays: "TargetDays 45",
    Category_1: "Computers",
    Category_2: "Baby",
    ObservationSummary: "ObservationSummary 45",
    ComplianceInstruction: "ComplianceInstruction 45",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-14T01:30:54.874Z",
  },
  {
    ID: 46,
    ApplicationNumber: 3554,
    PermitNumber: 14661,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-04-08T01:53:15.660Z",
    RefNo: 20785,
    AssessmentNo: 45298,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Betty Johnson",
    Builder: "Taryn Hartmann",
    Category: "Handmade Soft Chips",
    BCA: "enable",
    UseOfBuilding: true,
    ReasonForPermit: "navigating",
    SiteAddress: "jacinto.biz",
    Locality: "OK",
    Owner: "Dane Will",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-03-20T13:48:17.334Z",
    Date_AllInspComplete: "2021-03-24T01:50:34.044Z",
    Date_DueToCommence: "2021-07-04T19:06:25.457Z",
    Date_Commenced: "2021-02-06T13:49:47.204Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "February",
    FileNo: 23616,
    ValuationNo: 11634,
    LegalDescription: "enhance primary Awesome",
    BuildingOfficer: "International",
    Surveyor: "Investment Account",
    SurveyorNumber: "73033225",
    StatDays: 12153,
    Date_Issued: "2021-12-17T18:42:00.978Z",
    Date_ReminderSent: "2021-06-30T12:36:14.181Z",
    PayingFee: 90552,
    CarryingOutWork: false,
    WorkProposed: "sexy Tasty Soft Shoes Unbranded",
    Address: "Apt. 407",
    BuildingAppNo: 32540,
    BuildingPermitNo: 81915,
    Date_BuildingIssued: "September",
    Date_Commencing: "July",
    BondAmount: 20105,
    BondReceived: "47481-5343",
    BondReturned: 70145,
    Date_FirstInspection: "2021-10-28T15:44:18.169Z",
    Date_LastInspection: "2021-09-24T21:32:50.419Z",
    TargetDate: "2021-11-24T02:55:06.902Z",
    CILFee: 28376,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-11-16T14:22:55.426Z",
    InspectionType: "connecting",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "empowering",
    FacilityRegion: "Alabama",
    Description: "Customer",
    DateTarget: "2021-05-09T09:42:59.310Z",
    Source: "Operations",
    Location: "AL",
    RequestedBy: "47719350",
    ContactDetails: "(154) 615-5646",
    DueStatus: true,
    DateRecorded: "2021-03-18T14:13:40.496Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Cross-group optimal data-warehouse",
    ActionOfficer: "Clothing",
    Coordinator: "Apt. 695",
    DateWork: "2021-06-07T10:30:28.154Z",
    FileNumber: 5396,
    EnquiryRefNo: 96288,
    Event_ReferenceNoAdditional: 29736,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-05-18T05:11:08.330Z",
    ApplicantName: "Money Market Account",
    Type: "LLC",
    Risk: "Belize Dollar",
    DateDue: "2021-06-22T17:09:34.550Z",
    Register_Number: 53612,
    Contact: "Apt. 209",
    ReferenceNumber: 65651,
    Date_Complete: "2021-06-19T21:40:22.367Z",
    Date_Construction: "2021-02-03T11:08:23.128Z",
    BarrierStandard: "BarrierStandard 46",
    BarrierType: "BarrierType 46",
    Date_CertificateOfCompliance: "2021-04-27T07:11:04.630Z",
    Pool_RegisteredDate: "2021-03-25T14:16:18.776Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-11-12T02:06:57.989Z",
    Date_WorkIssued: "2021-03-20T18:31:22.717Z",
    Work_CarriedOut: "Administrator",
    AmountOS: 66419,
    Flag_RenewalNoticeSent: false,
    Outcome: 23270,
    Regulation: "Fresh",
    Date_Due: "2021-05-10T21:53:18.845Z",
    Date_NextInspection: "2021-10-10T06:06:38.346Z",
    Date_Start: "2021-02-10T20:54:10.488Z",
    Date_Occurred: "2021-09-30T02:32:00.632Z",
    ContactName: "Timothy Hilpert",
    CreatedDate: "2020-06-18T11:51:13.260Z",
    FeeType: "CHF",
    AmountIssued: 4145,
    AmountOutstanding: 42319,
    CostCode: 83806,
    AmountPaid: 59689,
    ReceiptNumber: 30869,
    ReceiptDate: "2021-10-06T06:13:07.540Z",
    ReceiptedBy: "Administrator",
    Method: "National",
    InvoiceNumber: 19040,
    DebtorNumber: 57490,
    SecurePay_Reference: "EGP",
    Contact2: "Merle.West@example.net",
    LevelOfEnforcement: "216.38",
    Number: 65776,
    DateOffence: "2021-04-20T18:18:54.814Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 46",
    ePlanningCategory: "ePlanningCategory 46",
    ePlanningLodgedBy: "ePlanningLodgedBy 46",
    Comments: "Comments 46",
    ApplicationCategory_Name: "Beauty",
    ApplicationCategory_Description: "Grocery",
    Code: "14262-0123",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "I'll parse the virtual AGP bus, that should monitor the TCP protocol!",
    PublishDescription:
      "Try to connect the XSS array, maybe it will input the digital port!",
    Title: "Forward",
    ConditionCode: "55094-3550",
    Condition: "Group",
    Name: "Lauren Jakubowski Sr.",
    Multiplier: "Multiplier 46",
    Value: 76390,
    MinimumValue: 11549,
    TargetDays: "TargetDays 46",
    Category_1: "Grocery",
    Category_2: "Baby",
    ObservationSummary: "ObservationSummary 46",
    ComplianceInstruction: "ComplianceInstruction 46",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-10T11:43:57.424Z",
  },
  {
    ID: 47,
    ApplicationNumber: 21594,
    PermitNumber: 97846,
    PermitType: "deposit",
    Date_PermitIssued: "2021-09-13T22:36:46.591Z",
    RefNo: 62930,
    AssessmentNo: 62687,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Daisha Reichel",
    Builder: "Russ O'Kon",
    Category: "Fantastic Granite Mouse",
    BCA: "methodology",
    UseOfBuilding: false,
    ReasonForPermit: "initiative",
    SiteAddress: "nikita.biz",
    Locality: "IA",
    Owner: "Armani Hartmann",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-01-01T20:33:42.789Z",
    Date_AllInspComplete: "2021-07-22T21:20:13.257Z",
    Date_DueToCommence: "2021-11-17T07:19:21.646Z",
    Date_Commenced: "2021-10-20T16:49:24.997Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "October",
    FileNo: 17172,
    ValuationNo: 82399,
    LegalDescription: "Keyboard",
    BuildingOfficer: "Investor",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "35746725",
    StatDays: 64321,
    Date_Issued: "2021-11-24T22:08:13.587Z",
    Date_ReminderSent: "2021-08-25T12:54:14.519Z",
    PayingFee: 75397,
    CarryingOutWork: false,
    WorkProposed: "data-warehouse Cambridgeshire Credit Card Account",
    Address: "Apt. 766",
    BuildingAppNo: 74487,
    BuildingPermitNo: 41821,
    Date_BuildingIssued: "February",
    Date_Commencing: "August",
    BondAmount: 66947,
    BondReceived: "95532-4633",
    BondReturned: 67626,
    Date_FirstInspection: "2021-08-08T19:52:34.914Z",
    Date_LastInspection: "2021-01-16T00:50:35.002Z",
    TargetDate: "2021-02-27T11:57:53.936Z",
    CILFee: 21198,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-05-25T13:30:39.996Z",
    InspectionType: "XML",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "Coordinator alarm",
    FacilityRegion: "Louisiana",
    Description: "Chief",
    DateTarget: "2021-06-05T18:57:47.530Z",
    Source: "Usability",
    Location: "ME",
    RequestedBy: "34575111",
    ContactDetails: "(029) 459-9511 x36042",
    DueStatus: false,
    DateRecorded: "2021-07-04T00:54:33.289Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Profit-focused client-server database",
    ActionOfficer: "Movies",
    Coordinator: "Suite 508",
    DateWork: "2021-02-17T09:25:17.727Z",
    FileNumber: 7931,
    EnquiryRefNo: 23550,
    Event_ReferenceNoAdditional: 69138,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-11-28T21:43:22.645Z",
    ApplicantName: "Auto Loan Account",
    Type: "LLC",
    Risk: "Solutions input",
    DateDue: "2021-06-11T19:55:44.877Z",
    Register_Number: 34246,
    Contact: "Suite 392",
    ReferenceNumber: 89719,
    Date_Complete: "2021-08-25T06:59:10.590Z",
    Date_Construction: "2021-09-17T10:23:19.197Z",
    BarrierStandard: "BarrierStandard 47",
    BarrierType: "BarrierType 47",
    Date_CertificateOfCompliance: "2021-12-04T01:56:54.741Z",
    Pool_RegisteredDate: "2021-11-01T04:34:23.813Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-07T09:48:11.498Z",
    Date_WorkIssued: "2021-06-20T05:59:41.789Z",
    Work_CarriedOut: "Engineer",
    AmountOS: 87581,
    Flag_RenewalNoticeSent: true,
    Outcome: 4154,
    Regulation: "e-business Plastic Chips",
    Date_Due: "2021-01-30T05:19:10.428Z",
    Date_NextInspection: "2021-08-02T08:33:07.388Z",
    Date_Start: "2021-07-20T10:16:38.373Z",
    Date_Occurred: "2021-10-21T00:09:50.695Z",
    ContactName: "Elisa McCullough",
    CreatedDate: "2020-10-13T06:34:27.411Z",
    FeeType: "£",
    AmountIssued: 5905,
    AmountOutstanding: 30852,
    CostCode: 62217,
    AmountPaid: 41436,
    ReceiptNumber: 27005,
    ReceiptDate: "2021-06-11T23:22:06.168Z",
    ReceiptedBy: "Designer",
    Method: "Regional",
    InvoiceNumber: 13329,
    DebtorNumber: 98726,
    SecurePay_Reference: "BGN",
    Contact2: "Flo76@example.com",
    LevelOfEnforcement: "874.58",
    Number: 83878,
    DateOffence: "2021-01-24T12:48:27.997Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 47",
    ePlanningCategory: "ePlanningCategory 47",
    ePlanningLodgedBy: "ePlanningLodgedBy 47",
    Comments: "Comments 47",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Electronics",
    Code: "45425",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "If we parse the system, we can get to the PNG panel through the haptic THX feed!",
    PublishDescription:
      "You can't override the feed without transmitting the online SAS pixel!",
    Title: "feed",
    ConditionCode: "86322",
    Condition: "Group",
    Name: "Khalil Renner",
    Multiplier: "Multiplier 47",
    Value: 90640,
    MinimumValue: 36834,
    TargetDays: "TargetDays 47",
    Category_1: "Shoes",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 47",
    ComplianceInstruction: "ComplianceInstruction 47",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-04T15:17:09.515Z",
  },
  {
    ID: 48,
    ApplicationNumber: 39167,
    PermitNumber: 46512,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-02-09T23:48:27.918Z",
    RefNo: 49949,
    AssessmentNo: 68777,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Orland Fadel",
    Builder: "Chyna Beer PhD",
    Category: "Handcrafted Granite Sausages",
    BCA: "connect",
    UseOfBuilding: true,
    ReasonForPermit: "didactic cross-platform Quality",
    SiteAddress: "abdul.info",
    Locality: "NE",
    Owner: "Tatyana Legros",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-11-04T18:39:21.620Z",
    Date_AllInspComplete: "2021-07-25T06:14:08.087Z",
    Date_DueToCommence: "2021-03-14T02:41:00.697Z",
    Date_Commenced: "2021-07-04T22:08:49.523Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "July",
    FileNo: 35181,
    ValuationNo: 94797,
    LegalDescription: "Dynamic XSS Rustic",
    BuildingOfficer: "Investor",
    Surveyor: "Checking Account",
    SurveyorNumber: "85651815",
    StatDays: 59170,
    Date_Issued: "2021-05-25T02:28:38.510Z",
    Date_ReminderSent: "2021-04-05T23:40:47.788Z",
    PayingFee: 22047,
    CarryingOutWork: true,
    WorkProposed: "user-centric",
    Address: "Suite 580",
    BuildingAppNo: 48805,
    BuildingPermitNo: 94055,
    Date_BuildingIssued: "January",
    Date_Commencing: "March",
    BondAmount: 45951,
    BondReceived: "75620-0550",
    BondReturned: 94342,
    Date_FirstInspection: "2021-04-17T02:48:57.266Z",
    Date_LastInspection: "2021-02-09T12:22:56.418Z",
    TargetDate: "2021-06-28T20:31:10.482Z",
    CILFee: 46438,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-10-01T17:08:53.607Z",
    InspectionType: "haptic",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "invoice",
    FacilityRegion: "California",
    Description: "Future",
    DateTarget: "2021-03-04T11:14:35.514Z",
    Source: "Division",
    Location: "MD",
    RequestedBy: "86050363",
    ContactDetails: "(572) 043-3812 x521",
    DueStatus: false,
    DateRecorded: "2021-04-24T22:17:46.604Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Team-oriented exuding success",
    ActionOfficer: "Garden",
    Coordinator: "Suite 859",
    DateWork: "2021-06-15T15:55:51.388Z",
    FileNumber: 33134,
    EnquiryRefNo: 33898,
    Event_ReferenceNoAdditional: 64330,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-01-20T20:03:55.463Z",
    ApplicantName: "Savings Account",
    Type: "Group",
    Risk: "AGP Village",
    DateDue: "2021-04-26T14:33:52.491Z",
    Register_Number: 68956,
    Contact: "Suite 573",
    ReferenceNumber: 10827,
    Date_Complete: "2021-07-29T00:41:13.943Z",
    Date_Construction: "2021-08-18T23:29:04.966Z",
    BarrierStandard: "BarrierStandard 48",
    BarrierType: "BarrierType 48",
    Date_CertificateOfCompliance: "2021-01-30T05:53:05.990Z",
    Pool_RegisteredDate: "2021-12-05T15:43:29.354Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-01T16:15:15.672Z",
    Date_WorkIssued: "2021-04-21T15:40:46.443Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 29563,
    Flag_RenewalNoticeSent: false,
    Outcome: 54940,
    Regulation: "Shirt Fresh Awesome",
    Date_Due: "2021-02-03T15:36:22.793Z",
    Date_NextInspection: "2021-11-10T12:59:33.686Z",
    Date_Start: "2021-10-25T22:08:59.571Z",
    Date_Occurred: "2021-12-19T05:26:09.459Z",
    ContactName: "Izabella Marks",
    CreatedDate: "2020-10-08T21:26:00.265Z",
    FeeType: "Lt",
    AmountIssued: 78800,
    AmountOutstanding: 41416,
    CostCode: 23060,
    AmountPaid: 27078,
    ReceiptNumber: 69058,
    ReceiptDate: "2021-04-28T15:30:43.588Z",
    ReceiptedBy: "Analyst",
    Method: "Customer",
    InvoiceNumber: 84833,
    DebtorNumber: 87009,
    SecurePay_Reference: "TJS",
    Contact2: "Danika34@example.net",
    LevelOfEnforcement: "467.66",
    Number: 92633,
    DateOffence: "2021-12-19T19:45:10.695Z",
    RegisterType: "Designer",
    VicSmart: "VicSmart 48",
    ePlanningCategory: "ePlanningCategory 48",
    ePlanningLodgedBy: "ePlanningLodgedBy 48",
    Comments: "Comments 48",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Home",
    Code: "23891-0108",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "The IB bus is down, parse the primary alarm so we can program the EXE driver!",
    PublishDescription:
      "I'll navigate the 1080p CSS firewall, that should microchip the SMS bus!",
    Title: "Self-enabling Customer",
    ConditionCode: "77704",
    Condition: "LLC",
    Name: "Zola Crona",
    Multiplier: "Multiplier 48",
    Value: 95120,
    MinimumValue: 56156,
    TargetDays: "TargetDays 48",
    Category_1: "Sports",
    Category_2: "Toys",
    ObservationSummary: "ObservationSummary 48",
    ComplianceInstruction: "ComplianceInstruction 48",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-24T03:30:54.237Z",
  },
  {
    ID: 49,
    ApplicationNumber: 91187,
    PermitNumber: 30325,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-12-03T01:35:39.427Z",
    RefNo: 26710,
    AssessmentNo: 63149,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Haylee Dooley",
    Builder: "Merritt Kutch",
    Category: "Ergonomic Plastic Fish",
    BCA: "Credit Card Account",
    UseOfBuilding: false,
    ReasonForPermit: "Automated Auto Loan Account intuitive",
    SiteAddress: "roman.net",
    Locality: "FL",
    Owner: "Retta McDermott",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-07-08T21:57:15.279Z",
    Date_AllInspComplete: "2021-09-07T16:29:31.808Z",
    Date_DueToCommence: "2021-11-27T08:50:57.002Z",
    Date_Commenced: "2021-08-30T00:08:28.992Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "April",
    FileNo: 57873,
    ValuationNo: 91908,
    LegalDescription: "AI online copying",
    BuildingOfficer: "International",
    Surveyor: "Checking Account",
    SurveyorNumber: "97343142",
    StatDays: 57446,
    Date_Issued: "2021-11-13T15:00:29.855Z",
    Date_ReminderSent: "2021-02-09T16:03:47.331Z",
    PayingFee: 46714,
    CarryingOutWork: false,
    WorkProposed: "deploy disintermediate tan",
    Address: "Apt. 651",
    BuildingAppNo: 88120,
    BuildingPermitNo: 29788,
    Date_BuildingIssued: "January",
    Date_Commencing: "August",
    BondAmount: 82799,
    BondReceived: "65709",
    BondReturned: 82100,
    Date_FirstInspection: "2021-04-06T14:39:09.854Z",
    Date_LastInspection: "2021-11-03T08:14:57.474Z",
    TargetDate: "2021-01-21T03:30:09.967Z",
    CILFee: 9637,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-05-28T11:40:17.568Z",
    InspectionType: "Handmade",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "ivory virtual Bedfordshire",
    FacilityRegion: "New Hampshire",
    Description: "Lead",
    DateTarget: "2021-02-24T02:39:12.185Z",
    Source: "Marketing",
    Location: "NJ",
    RequestedBy: "66665926",
    ContactDetails: "1-903-987-8136 x332",
    DueStatus: false,
    DateRecorded: "2021-09-20T00:45:48.049Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Balanced uniform implementation",
    ActionOfficer: "Outdoors",
    Coordinator: "Apt. 104",
    DateWork: "2021-11-15T06:10:03.329Z",
    FileNumber: 5652,
    EnquiryRefNo: 30774,
    Event_ReferenceNoAdditional: 96620,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-10-08T21:23:32.198Z",
    ApplicantName: "Investment Account",
    Type: "and Sons",
    Risk: "cross-platform",
    DateDue: "2021-06-23T21:43:54.185Z",
    Register_Number: 66338,
    Contact: "Apt. 885",
    ReferenceNumber: 45391,
    Date_Complete: "2021-01-04T22:01:41.962Z",
    Date_Construction: "2021-09-06T20:12:38.440Z",
    BarrierStandard: "BarrierStandard 49",
    BarrierType: "BarrierType 49",
    Date_CertificateOfCompliance: "2021-01-26T03:44:10.166Z",
    Pool_RegisteredDate: "2021-12-11T03:12:02.107Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-25T18:31:24.177Z",
    Date_WorkIssued: "2021-12-17T18:28:08.293Z",
    Work_CarriedOut: "Executive",
    AmountOS: 55574,
    Flag_RenewalNoticeSent: false,
    Outcome: 37855,
    Regulation: "budgetary management Savings Account",
    Date_Due: "2021-06-21T12:24:20.287Z",
    Date_NextInspection: "2021-10-18T03:30:58.250Z",
    Date_Start: "2021-02-23T11:47:43.905Z",
    Date_Occurred: "2021-01-24T07:41:35.692Z",
    ContactName: "Kayden Weissnat PhD",
    CreatedDate: "2020-01-23T12:16:53.823Z",
    FeeType: "$",
    AmountIssued: 38244,
    AmountOutstanding: 12306,
    CostCode: 67645,
    AmountPaid: 72639,
    ReceiptNumber: 41798,
    ReceiptDate: "2021-05-12T19:20:07.732Z",
    ReceiptedBy: "Engineer",
    Method: "Dynamic",
    InvoiceNumber: 19925,
    DebtorNumber: 74080,
    SecurePay_Reference: "BYR",
    Contact2: "Kody20@example.com",
    LevelOfEnforcement: "651.91",
    Number: 16483,
    DateOffence: "2021-06-04T12:17:21.829Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 49",
    ePlanningCategory: "ePlanningCategory 49",
    ePlanningLodgedBy: "ePlanningLodgedBy 49",
    Comments: "Comments 49",
    ApplicationCategory_Name: "Toys",
    ApplicationCategory_Description: "Health",
    Code: "72795",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Try to parse the COM alarm, maybe it will reboot the optical port!",
    PublishDescription:
      "Try to index the AGP bus, maybe it will parse the redundant array!",
    Title: "COM solid state",
    ConditionCode: "44722",
    Condition: "and Sons",
    Name: "Berniece Cremin",
    Multiplier: "Multiplier 49",
    Value: 80491,
    MinimumValue: 91861,
    TargetDays: "TargetDays 49",
    Category_1: "Industrial",
    Category_2: "Books",
    ObservationSummary: "ObservationSummary 49",
    ComplianceInstruction: "ComplianceInstruction 49",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-29T10:03:03.341Z",
  },
  {
    ID: 50,
    ApplicationNumber: 33736,
    PermitNumber: 73059,
    PermitType: "invoice",
    Date_PermitIssued: "2021-04-25T04:55:04.921Z",
    RefNo: 66063,
    AssessmentNo: 85834,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Mariam Kuhic",
    Builder: "Jamey Runte DVM",
    Category: "Sleek Cotton Pants",
    BCA: "override",
    UseOfBuilding: false,
    ReasonForPermit: "National database microchip",
    SiteAddress: "myrtice.name",
    Locality: "CA",
    Owner: "Pascale Emmerich",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-01-16T02:51:08.687Z",
    Date_AllInspComplete: "2021-02-25T13:16:54.922Z",
    Date_DueToCommence: "2021-03-25T15:44:36.593Z",
    Date_Commenced: "2021-01-29T05:51:02.108Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "February",
    FileNo: 31601,
    ValuationNo: 75971,
    LegalDescription: "Grocery",
    BuildingOfficer: "Investor",
    Surveyor: "Investment Account",
    SurveyorNumber: "57488173",
    StatDays: 95010,
    Date_Issued: "2021-12-10T18:23:37.794Z",
    Date_ReminderSent: "2021-06-10T10:44:54.940Z",
    PayingFee: 32687,
    CarryingOutWork: false,
    WorkProposed: "open-source",
    Address: "Apt. 935",
    BuildingAppNo: 57781,
    BuildingPermitNo: 23556,
    Date_BuildingIssued: "September",
    Date_Commencing: "May",
    BondAmount: 10782,
    BondReceived: "00811",
    BondReturned: 82464,
    Date_FirstInspection: "2021-03-11T09:35:19.547Z",
    Date_LastInspection: "2021-09-12T04:31:14.256Z",
    TargetDate: "2021-11-01T10:48:26.593Z",
    CILFee: 68520,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-06-11T18:57:05.211Z",
    InspectionType: "Function-based",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "Computers Manat Consultant",
    FacilityRegion: "Maine",
    Description: "Future",
    DateTarget: "2021-09-08T14:15:13.171Z",
    Source: "Interactions",
    Location: "UT",
    RequestedBy: "45700416",
    ContactDetails: "1-296-063-0737 x748",
    DueStatus: true,
    DateRecorded: "2021-06-25T16:48:42.858Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Reactive encompassing focus group",
    ActionOfficer: "Kids",
    Coordinator: "Suite 283",
    DateWork: "2021-06-04T01:01:03.668Z",
    FileNumber: 60213,
    EnquiryRefNo: 9925,
    Event_ReferenceNoAdditional: 60437,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-12-10T19:44:34.436Z",
    ApplicantName: "Money Market Account",
    Type: "LLC",
    Risk: "Bedfordshire User-centric",
    DateDue: "2021-08-09T04:07:32.616Z",
    Register_Number: 76243,
    Contact: "Apt. 878",
    ReferenceNumber: 31281,
    Date_Complete: "2021-11-05T06:19:27.299Z",
    Date_Construction: "2021-06-27T08:09:37.904Z",
    BarrierStandard: "BarrierStandard 50",
    BarrierType: "BarrierType 50",
    Date_CertificateOfCompliance: "2021-10-04T20:48:30.924Z",
    Pool_RegisteredDate: "2021-11-23T00:51:43.062Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-23T08:10:30.156Z",
    Date_WorkIssued: "2021-11-01T05:36:13.598Z",
    Work_CarriedOut: "Producer",
    AmountOS: 22603,
    Flag_RenewalNoticeSent: false,
    Outcome: 67763,
    Regulation: "optimal Chicken",
    Date_Due: "2021-04-28T23:25:17.627Z",
    Date_NextInspection: "2021-01-18T21:49:09.683Z",
    Date_Start: "2021-05-24T20:44:11.204Z",
    Date_Occurred: "2021-03-26T18:15:58.907Z",
    ContactName: "Amalia Casper",
    CreatedDate: "2020-04-16T08:29:05.509Z",
    FeeType: "ƒ",
    AmountIssued: 43089,
    AmountOutstanding: 61173,
    CostCode: 6632,
    AmountPaid: 96389,
    ReceiptNumber: 55119,
    ReceiptDate: "2021-11-28T13:23:35.147Z",
    ReceiptedBy: "Facilitator",
    Method: "National",
    InvoiceNumber: 77433,
    DebtorNumber: 74307,
    SecurePay_Reference: "MZN",
    Contact2: "Jarrod16@example.net",
    LevelOfEnforcement: "56.26",
    Number: 91815,
    DateOffence: "2021-01-08T04:22:59.127Z",
    RegisterType: "Engineer",
    VicSmart: "VicSmart 50",
    ePlanningCategory: "ePlanningCategory 50",
    ePlanningLodgedBy: "ePlanningLodgedBy 50",
    Comments: "Comments 50",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Kids",
    Code: "17644-5113",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "The HDD hard drive is down, quantify the online array so we can back up the JBOD pixel!",
    PublishDescription:
      "If we quantify the pixel, we can get to the USB firewall through the mobile TCP bandwidth!",
    Title: "Azerbaijanian Manat Steel",
    ConditionCode: "07530-0090",
    Condition: "Group",
    Name: "Dr. Brittany Bailey",
    Multiplier: "Multiplier 50",
    Value: 33067,
    MinimumValue: 82178,
    TargetDays: "TargetDays 50",
    Category_1: "Outdoors",
    Category_2: "Outdoors",
    ObservationSummary: "ObservationSummary 50",
    ComplianceInstruction: "ComplianceInstruction 50",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-11T08:41:19.730Z",
  },
  {
    ID: 51,
    ApplicationNumber: 86324,
    PermitNumber: 81228,
    PermitType: "invoice",
    Date_PermitIssued: "2021-04-12T15:54:26.924Z",
    RefNo: 99675,
    AssessmentNo: 80263,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Valentine Gibson Sr.",
    Builder: "Adonis Stamm",
    Category: "Handcrafted Concrete Bike",
    BCA: "Supervisor",
    UseOfBuilding: true,
    ReasonForPermit: "Chips",
    SiteAddress: "isabel.info",
    Locality: "MN",
    Owner: "Leanne Marks",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-09-17T17:10:41.687Z",
    Date_AllInspComplete: "2021-04-12T01:45:10.603Z",
    Date_DueToCommence: "2021-07-18T07:39:15.581Z",
    Date_Commenced: "2021-06-19T03:30:12.373Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "July",
    FileNo: 60615,
    ValuationNo: 87953,
    LegalDescription: "Knolls Ergonomic Wooden Sausages Home",
    BuildingOfficer: "Human",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "40221651",
    StatDays: 15263,
    Date_Issued: "2021-05-25T18:19:24.343Z",
    Date_ReminderSent: "2021-11-04T16:02:54.570Z",
    PayingFee: 95653,
    CarryingOutWork: false,
    WorkProposed: "auxiliary",
    Address: "Apt. 504",
    BuildingAppNo: 82207,
    BuildingPermitNo: 31594,
    Date_BuildingIssued: "February",
    Date_Commencing: "August",
    BondAmount: 41915,
    BondReceived: "63713",
    BondReturned: 97787,
    Date_FirstInspection: "2021-05-15T09:57:19.824Z",
    Date_LastInspection: "2021-12-28T11:14:25.675Z",
    TargetDate: "2021-08-21T03:10:56.343Z",
    CILFee: 88752,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-04-25T00:55:09.200Z",
    InspectionType: "Practical Concrete Table",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "quantifying",
    FacilityRegion: "Virginia",
    Description: "Principal",
    DateTarget: "2021-05-27T01:05:31.614Z",
    Source: "Brand",
    Location: "IN",
    RequestedBy: "45874708",
    ContactDetails: "1-377-179-8586",
    DueStatus: false,
    DateRecorded: "2021-06-08T20:39:37.531Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Reduced client-server portal",
    ActionOfficer: "Computers",
    Coordinator: "Suite 268",
    DateWork: "2021-05-19T16:35:21.119Z",
    FileNumber: 60631,
    EnquiryRefNo: 39963,
    Event_ReferenceNoAdditional: 90844,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-06-13T01:12:05.205Z",
    ApplicantName: "Home Loan Account",
    Type: "Group",
    Risk: "Loaf Valleys",
    DateDue: "2021-05-29T04:25:22.838Z",
    Register_Number: 35009,
    Contact: "Apt. 858",
    ReferenceNumber: 96881,
    Date_Complete: "2021-10-14T07:18:37.797Z",
    Date_Construction: "2021-01-31T16:50:48.151Z",
    BarrierStandard: "BarrierStandard 51",
    BarrierType: "BarrierType 51",
    Date_CertificateOfCompliance: "2021-05-05T16:04:02.448Z",
    Pool_RegisteredDate: "2021-05-05T14:06:02.427Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-20T11:34:53.359Z",
    Date_WorkIssued: "2021-06-04T04:39:42.780Z",
    Work_CarriedOut: "Coordinator",
    AmountOS: 71141,
    Flag_RenewalNoticeSent: false,
    Outcome: 77942,
    Regulation: "migration Ball e-enable",
    Date_Due: "2021-07-29T03:58:16.753Z",
    Date_NextInspection: "2021-09-10T04:54:15.758Z",
    Date_Start: "2021-02-13T05:29:18.881Z",
    Date_Occurred: "2021-06-17T16:45:49.997Z",
    ContactName: "Michael Gusikowski",
    CreatedDate: "2020-02-03T06:16:09.803Z",
    FeeType: "$",
    AmountIssued: 12028,
    AmountOutstanding: 96959,
    CostCode: 43787,
    AmountPaid: 95286,
    ReceiptNumber: 17163,
    ReceiptDate: "2021-01-28T20:43:46.233Z",
    ReceiptedBy: "Strategist",
    Method: "Forward",
    InvoiceNumber: 54668,
    DebtorNumber: 12046,
    SecurePay_Reference: "YER",
    Contact2: "Trystan_Pollich17@example.org",
    LevelOfEnforcement: "219.83",
    Number: 98918,
    DateOffence: "2021-03-03T11:50:15.019Z",
    RegisterType: "Associate",
    VicSmart: "VicSmart 51",
    ePlanningCategory: "ePlanningCategory 51",
    ePlanningLodgedBy: "ePlanningLodgedBy 51",
    Comments: "Comments 51",
    ApplicationCategory_Name: "Garden",
    ApplicationCategory_Description: "Music",
    Code: "34031",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName: "We need to generate the neural SDD panel!",
    PublishDescription:
      "Try to compress the SMS sensor, maybe it will hack the solid state capacitor!",
    Title: "Handcrafted Steel Markets",
    ConditionCode: "68432",
    Condition: "LLC",
    Name: "Haskell Wolff",
    Multiplier: "Multiplier 51",
    Value: 37039,
    MinimumValue: 29986,
    TargetDays: "TargetDays 51",
    Category_1: "Automotive",
    Category_2: "Outdoors",
    ObservationSummary: "ObservationSummary 51",
    ComplianceInstruction: "ComplianceInstruction 51",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-20T18:47:33.025Z",
  },
  {
    ID: 52,
    ApplicationNumber: 99774,
    PermitNumber: 57355,
    PermitType: "payment",
    Date_PermitIssued: "2021-11-24T01:30:05.131Z",
    RefNo: 22737,
    AssessmentNo: 77701,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Lambert Wehner",
    Builder: "Dr. Barrett McDermott",
    Category: "Licensed Concrete Pants",
    BCA: "Chicken",
    UseOfBuilding: false,
    ReasonForPermit: "Savings Account",
    SiteAddress: "clay.name",
    Locality: "IN",
    Owner: "Noelia Cartwright",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-26T20:44:25.025Z",
    Date_AllInspComplete: "2021-08-09T16:23:26.667Z",
    Date_DueToCommence: "2021-09-10T00:21:24.417Z",
    Date_Commenced: "2021-01-16T14:30:38.582Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "February",
    FileNo: 33584,
    ValuationNo: 14083,
    LegalDescription: "Clothing CSS gold",
    BuildingOfficer: "Global",
    Surveyor: "Home Loan Account",
    SurveyorNumber: "83306288",
    StatDays: 53068,
    Date_Issued: "2021-01-26T19:24:50.024Z",
    Date_ReminderSent: "2021-01-20T19:05:54.164Z",
    PayingFee: 85966,
    CarryingOutWork: false,
    WorkProposed: "Bedfordshire",
    Address: "Apt. 597",
    BuildingAppNo: 31237,
    BuildingPermitNo: 68872,
    Date_BuildingIssued: "September",
    Date_Commencing: "November",
    BondAmount: 6934,
    BondReceived: "76066",
    BondReturned: 96329,
    Date_FirstInspection: "2021-02-20T07:44:50.909Z",
    Date_LastInspection: "2021-03-03T21:15:33.405Z",
    TargetDate: "2021-04-08T11:27:08.001Z",
    CILFee: 27922,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-12-09T19:52:54.521Z",
    InspectionType: "Sleek Wooden Bacon",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "Virgin Islands, British",
    FacilityRegion: "Alabama",
    Description: "Internal",
    DateTarget: "2021-02-18T04:40:42.220Z",
    Source: "Metrics",
    Location: "NC",
    RequestedBy: "97863529",
    ContactDetails: "(788) 063-6886",
    DueStatus: true,
    DateRecorded: "2021-08-24T22:12:29.830Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Face to face 6th generation matrices",
    ActionOfficer: "Computers",
    Coordinator: "Suite 527",
    DateWork: "2021-10-31T00:34:54.273Z",
    FileNumber: 53167,
    EnquiryRefNo: 61118,
    Event_ReferenceNoAdditional: 48031,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-09-27T05:36:51.332Z",
    ApplicantName: "Money Market Account",
    Type: "Inc",
    Risk: "United Arab Emirates Small",
    DateDue: "2021-04-23T05:04:05.332Z",
    Register_Number: 12618,
    Contact: "Apt. 093",
    ReferenceNumber: 67249,
    Date_Complete: "2021-04-19T03:27:47.924Z",
    Date_Construction: "2021-02-08T15:15:49.714Z",
    BarrierStandard: "BarrierStandard 52",
    BarrierType: "BarrierType 52",
    Date_CertificateOfCompliance: "2021-03-06T08:57:07.715Z",
    Pool_RegisteredDate: "2021-07-11T16:05:53.652Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-11-20T04:04:13.750Z",
    Date_WorkIssued: "2021-04-20T08:08:04.112Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 51340,
    Flag_RenewalNoticeSent: false,
    Outcome: 19143,
    Regulation: "Refined",
    Date_Due: "2021-04-24T23:06:42.694Z",
    Date_NextInspection: "2021-11-17T13:47:41.348Z",
    Date_Start: "2021-03-28T22:26:25.562Z",
    Date_Occurred: "2021-06-26T05:35:00.553Z",
    ContactName: "Karen Leffler",
    CreatedDate: "2020-07-02T22:55:38.286Z",
    FeeType: "P",
    AmountIssued: 30709,
    AmountOutstanding: 47422,
    CostCode: 59136,
    AmountPaid: 2409,
    ReceiptNumber: 59816,
    ReceiptDate: "2021-07-31T09:21:32.275Z",
    ReceiptedBy: "Technician",
    Method: "Investor",
    InvoiceNumber: 53413,
    DebtorNumber: 13839,
    SecurePay_Reference: "ZAR LSL",
    Contact2: "Yolanda_Kunze@example.org",
    LevelOfEnforcement: "290.58",
    Number: 97773,
    DateOffence: "2021-10-17T12:38:28.425Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 52",
    ePlanningCategory: "ePlanningCategory 52",
    ePlanningLodgedBy: "ePlanningLodgedBy 52",
    Comments: "Comments 52",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Beauty",
    Code: "48208-9869",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "Try to input the HDD matrix, maybe it will program the virtual transmitter!",
    PublishDescription:
      "If we override the protocol, we can get to the EXE protocol through the optical ADP program!",
    Title: "exploit",
    ConditionCode: "63981",
    Condition: "Inc",
    Name: "Willa Dickinson",
    Multiplier: "Multiplier 52",
    Value: 83436,
    MinimumValue: 39265,
    TargetDays: "TargetDays 52",
    Category_1: "Baby",
    Category_2: "Garden",
    ObservationSummary: "ObservationSummary 52",
    ComplianceInstruction: "ComplianceInstruction 52",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-13T06:43:10.709Z",
  },
  {
    ID: 53,
    ApplicationNumber: 63886,
    PermitNumber: 11345,
    PermitType: "deposit",
    Date_PermitIssued: "2021-09-29T06:10:43.918Z",
    RefNo: 70553,
    AssessmentNo: 88176,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Leanne O'Reilly",
    Builder: "Zoey Bernhard",
    Category: "Small Granite Keyboard",
    BCA: "Handcrafted Fresh Hat",
    UseOfBuilding: false,
    ReasonForPermit: "Designer lavender",
    SiteAddress: "dejon.net",
    Locality: "MT",
    Owner: "Jordane Rohan",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-07-10T22:50:14.633Z",
    Date_AllInspComplete: "2021-04-04T15:41:03.681Z",
    Date_DueToCommence: "2021-10-13T02:13:04.393Z",
    Date_Commenced: "2021-03-13T15:13:29.466Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "August",
    FileNo: 66506,
    ValuationNo: 1439,
    LegalDescription: "Progressive feed District",
    BuildingOfficer: "Senior",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "93308455",
    StatDays: 41918,
    Date_Issued: "2021-12-11T22:40:57.089Z",
    Date_ReminderSent: "2021-10-11T15:51:20.907Z",
    PayingFee: 24764,
    CarryingOutWork: false,
    WorkProposed: "robust",
    Address: "Suite 894",
    BuildingAppNo: 72194,
    BuildingPermitNo: 39762,
    Date_BuildingIssued: "July",
    Date_Commencing: "December",
    BondAmount: 45272,
    BondReceived: "01258",
    BondReturned: 69806,
    Date_FirstInspection: "2021-01-07T05:56:22.016Z",
    Date_LastInspection: "2021-09-19T05:17:58.517Z",
    TargetDate: "2021-05-09T12:35:23.497Z",
    CILFee: 8510,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-08-02T16:38:27.527Z",
    InspectionType: "protocol",
    Officer: "Money Market Account",
    Compliance: true,
    Reason: "database",
    FacilityRegion: "California",
    Description: "International",
    DateTarget: "2021-06-12T21:22:17.361Z",
    Source: "Usability",
    Location: "MA",
    RequestedBy: "73987722",
    ContactDetails: "(746) 600-0850",
    DueStatus: true,
    DateRecorded: "2021-10-08T17:52:57.794Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Synergistic encompassing implementation",
    ActionOfficer: "Electronics",
    Coordinator: "Suite 496",
    DateWork: "2021-09-21T23:51:10.869Z",
    FileNumber: 55611,
    EnquiryRefNo: 14869,
    Event_ReferenceNoAdditional: 59127,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-07-15T13:10:44.025Z",
    ApplicantName: "Savings Account",
    Type: "and Sons",
    Risk: "olive Avon",
    DateDue: "2021-09-18T22:34:36.675Z",
    Register_Number: 62514,
    Contact: "Suite 909",
    ReferenceNumber: 44895,
    Date_Complete: "2021-01-19T01:40:24.642Z",
    Date_Construction: "2021-01-04T08:33:52.648Z",
    BarrierStandard: "BarrierStandard 53",
    BarrierType: "BarrierType 53",
    Date_CertificateOfCompliance: "2021-03-08T18:41:27.044Z",
    Pool_RegisteredDate: "2021-08-19T09:31:50.381Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-12T12:50:51.384Z",
    Date_WorkIssued: "2021-08-25T11:51:52.469Z",
    Work_CarriedOut: "Director",
    AmountOS: 49605,
    Flag_RenewalNoticeSent: false,
    Outcome: 64327,
    Regulation: "Lake Future",
    Date_Due: "2021-09-16T20:00:42.653Z",
    Date_NextInspection: "2021-11-09T08:55:35.541Z",
    Date_Start: "2021-08-03T09:18:40.418Z",
    Date_Occurred: "2021-06-04T21:24:49.826Z",
    ContactName: "Josie Lehner",
    CreatedDate: "2020-10-31T20:01:12.670Z",
    FeeType: "BZ$",
    AmountIssued: 67668,
    AmountOutstanding: 33994,
    CostCode: 47717,
    AmountPaid: 23556,
    ReceiptNumber: 81901,
    ReceiptDate: "2021-11-19T09:06:42.996Z",
    ReceiptedBy: "Coordinator",
    Method: "Lead",
    InvoiceNumber: 43658,
    DebtorNumber: 6251,
    SecurePay_Reference: "ERN",
    Contact2: "Larry_Parisian@example.com",
    LevelOfEnforcement: "633.43",
    Number: 46296,
    DateOffence: "2021-12-09T04:50:00.394Z",
    RegisterType: "Architect",
    VicSmart: "VicSmart 53",
    ePlanningCategory: "ePlanningCategory 53",
    ePlanningLodgedBy: "ePlanningLodgedBy 53",
    Comments: "Comments 53",
    ApplicationCategory_Name: "Health",
    ApplicationCategory_Description: "Music",
    Code: "10652",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "You can't index the port without calculating the 1080p SMTP bandwidth!",
    PublishDescription:
      "quantifying the protocol won't do anything, we need to override the solid state EXE feed!",
    Title: "product navigate zero defect",
    ConditionCode: "75534",
    Condition: "Group",
    Name: "Miss Jade Stamm",
    Multiplier: "Multiplier 53",
    Value: 47748,
    MinimumValue: 16894,
    TargetDays: "TargetDays 53",
    Category_1: "Grocery",
    Category_2: "Grocery",
    ObservationSummary: "ObservationSummary 53",
    ComplianceInstruction: "ComplianceInstruction 53",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-31T02:57:03.572Z",
  },
  {
    ID: 54,
    ApplicationNumber: 95191,
    PermitNumber: 48481,
    PermitType: "invoice",
    Date_PermitIssued: "2021-06-20T19:41:22.181Z",
    RefNo: 48524,
    AssessmentNo: 92142,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Myrtice Watsica",
    Builder: "Rory Cruickshank",
    Category: "Refined Rubber Tuna",
    BCA: "Pants",
    UseOfBuilding: true,
    ReasonForPermit: "synergies France systematic",
    SiteAddress: "raegan.com",
    Locality: "DE",
    Owner: "Lee Medhurst",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-02-21T03:40:21.504Z",
    Date_AllInspComplete: "2021-10-11T22:48:04.660Z",
    Date_DueToCommence: "2021-11-11T23:18:38.256Z",
    Date_Commenced: "2021-08-06T21:52:08.690Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "March",
    FileNo: 28631,
    ValuationNo: 77240,
    LegalDescription: "overriding withdrawal Sports",
    BuildingOfficer: "Chief",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "27094630",
    StatDays: 41555,
    Date_Issued: "2021-11-14T10:11:09.341Z",
    Date_ReminderSent: "2021-06-13T17:32:20.385Z",
    PayingFee: 56558,
    CarryingOutWork: false,
    WorkProposed: "Beauty",
    Address: "Suite 533",
    BuildingAppNo: 93394,
    BuildingPermitNo: 33897,
    Date_BuildingIssued: "March",
    Date_Commencing: "October",
    BondAmount: 77655,
    BondReceived: "51162",
    BondReturned: 41708,
    Date_FirstInspection: "2021-05-18T07:48:10.368Z",
    Date_LastInspection: "2021-01-20T11:29:57.340Z",
    TargetDate: "2021-06-10T09:06:00.842Z",
    CILFee: 40227,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-03-13T01:12:23.631Z",
    InspectionType: "sensor",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "Savings Account",
    FacilityRegion: "Massachusetts",
    Description: "Investor",
    DateTarget: "2021-11-06T01:46:30.004Z",
    Source: "Operations",
    Location: "TN",
    RequestedBy: "50792098",
    ContactDetails: "192-578-1685 x756",
    DueStatus: true,
    DateRecorded: "2021-10-24T20:52:34.166Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Digitized national groupware",
    ActionOfficer: "Games",
    Coordinator: "Suite 538",
    DateWork: "2020-12-31T19:56:48.116Z",
    FileNumber: 97210,
    EnquiryRefNo: 55498,
    Event_ReferenceNoAdditional: 12174,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-03-22T07:17:31.710Z",
    ApplicantName: "Home Loan Account",
    Type: "Inc",
    Risk: "calculate Baby sensor",
    DateDue: "2021-09-07T21:51:19.770Z",
    Register_Number: 85637,
    Contact: "Apt. 022",
    ReferenceNumber: 64244,
    Date_Complete: "2021-01-30T05:12:11.981Z",
    Date_Construction: "2021-11-08T18:07:37.001Z",
    BarrierStandard: "BarrierStandard 54",
    BarrierType: "BarrierType 54",
    Date_CertificateOfCompliance: "2021-02-11T17:17:26.574Z",
    Pool_RegisteredDate: "2021-06-24T01:22:29.567Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-16T02:26:36.518Z",
    Date_WorkIssued: "2021-04-25T15:53:17.301Z",
    Work_CarriedOut: "Liaison",
    AmountOS: 51796,
    Flag_RenewalNoticeSent: true,
    Outcome: 30314,
    Regulation: "Total Buckinghamshire bi-directional",
    Date_Due: "2021-07-17T16:05:35.234Z",
    Date_NextInspection: "2021-06-21T15:43:35.004Z",
    Date_Start: "2021-02-06T05:18:27.623Z",
    Date_Occurred: "2021-10-14T04:55:10.784Z",
    ContactName: "Otto Zemlak",
    CreatedDate: "2020-11-07T17:30:43.099Z",
    FeeType: "kr",
    AmountIssued: 36842,
    AmountOutstanding: 76146,
    CostCode: 8122,
    AmountPaid: 19546,
    ReceiptNumber: 67780,
    ReceiptDate: "2021-06-20T11:31:42.934Z",
    ReceiptedBy: "Specialist",
    Method: "Corporate",
    InvoiceNumber: 39600,
    DebtorNumber: 61254,
    SecurePay_Reference: "ZWL",
    Contact2: "Shawn.Bartoletti14@example.com",
    LevelOfEnforcement: "326.98",
    Number: 94339,
    DateOffence: "2021-07-14T06:09:04.578Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 54",
    ePlanningCategory: "ePlanningCategory 54",
    ePlanningLodgedBy: "ePlanningLodgedBy 54",
    Comments: "Comments 54",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Electronics",
    Code: "65709-0183",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Try to navigate the FTP microchip, maybe it will transmit the solid state sensor!",
    PublishDescription:
      "backing up the circuit won't do anything, we need to synthesize the 1080p PCI port!",
    Title: "Home Loan Account",
    ConditionCode: "28168-9037",
    Condition: "and Sons",
    Name: "Catharine Parisian",
    Multiplier: "Multiplier 54",
    Value: 22174,
    MinimumValue: 54679,
    TargetDays: "TargetDays 54",
    Category_1: "Sports",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 54",
    ComplianceInstruction: "ComplianceInstruction 54",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-19T15:34:12.734Z",
  },
  {
    ID: 55,
    ApplicationNumber: 75760,
    PermitNumber: 5716,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-08-05T19:19:38.090Z",
    RefNo: 18998,
    AssessmentNo: 89217,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Cooper Sauer",
    Builder: "Vergie Murphy",
    Category: "Unbranded Plastic Chips",
    BCA: "Engineer",
    UseOfBuilding: true,
    ReasonForPermit: "Checking Account Strategist",
    SiteAddress: "bud.org",
    Locality: "NC",
    Owner: "Shany Dickens I",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-08-08T17:07:32.830Z",
    Date_AllInspComplete: "2021-05-27T01:34:45.282Z",
    Date_DueToCommence: "2021-11-03T18:53:02.955Z",
    Date_Commenced: "2021-01-12T11:22:05.265Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "February",
    FileNo: 81632,
    ValuationNo: 68369,
    LegalDescription: "Supervisor Salad mobile",
    BuildingOfficer: "Forward",
    Surveyor: "Investment Account",
    SurveyorNumber: "73939840",
    StatDays: 16905,
    Date_Issued: "2021-02-17T18:59:12.892Z",
    Date_ReminderSent: "2021-04-29T04:33:02.615Z",
    PayingFee: 43414,
    CarryingOutWork: false,
    WorkProposed: "Optimization parallelism mobile",
    Address: "Suite 993",
    BuildingAppNo: 62395,
    BuildingPermitNo: 73441,
    Date_BuildingIssued: "January",
    Date_Commencing: "August",
    BondAmount: 30713,
    BondReceived: "40276-6279",
    BondReturned: 82330,
    Date_FirstInspection: "2021-11-29T07:00:31.904Z",
    Date_LastInspection: "2021-11-19T06:30:58.185Z",
    TargetDate: "2021-07-06T19:55:02.516Z",
    CILFee: 67275,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-06-24T00:16:20.886Z",
    InspectionType: "District",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "Rustic feed exuding",
    FacilityRegion: "Missouri",
    Description: "Product",
    DateTarget: "2021-10-11T13:39:58.492Z",
    Source: "Markets",
    Location: "TN",
    RequestedBy: "73763391",
    ContactDetails: "981.223.8934",
    DueStatus: false,
    DateRecorded: "2021-03-19T05:59:19.350Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Pre-emptive user-facing local area network",
    ActionOfficer: "Movies",
    Coordinator: "Suite 541",
    DateWork: "2021-03-20T13:12:59.632Z",
    FileNumber: 84692,
    EnquiryRefNo: 47619,
    Event_ReferenceNoAdditional: 45383,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-09-21T07:51:44.740Z",
    ApplicantName: "Credit Card Account",
    Type: "LLC",
    Risk: "Representative",
    DateDue: "2021-01-10T08:59:16.022Z",
    Register_Number: 59634,
    Contact: "Apt. 667",
    ReferenceNumber: 78174,
    Date_Complete: "2021-06-04T23:23:09.156Z",
    Date_Construction: "2021-11-24T00:53:36.587Z",
    BarrierStandard: "BarrierStandard 55",
    BarrierType: "BarrierType 55",
    Date_CertificateOfCompliance: "2021-08-21T10:25:54.094Z",
    Pool_RegisteredDate: "2021-07-18T21:58:52.478Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-23T19:29:41.957Z",
    Date_WorkIssued: "2021-12-15T22:05:36.626Z",
    Work_CarriedOut: "Technician",
    AmountOS: 53616,
    Flag_RenewalNoticeSent: true,
    Outcome: 85628,
    Regulation: "Cambridgeshire",
    Date_Due: "2021-05-01T16:43:14.007Z",
    Date_NextInspection: "2021-04-01T14:18:59.412Z",
    Date_Start: "2021-01-10T06:05:29.989Z",
    Date_Occurred: "2021-06-04T06:16:20.584Z",
    ContactName: "Arch Pouros",
    CreatedDate: "2020-05-29T00:22:50.493Z",
    FeeType: "₭",
    AmountIssued: 10179,
    AmountOutstanding: 74261,
    CostCode: 32869,
    AmountPaid: 50693,
    ReceiptNumber: 55848,
    ReceiptDate: "2021-09-17T10:49:31.107Z",
    ReceiptedBy: "Representative",
    Method: "Regional",
    InvoiceNumber: 55316,
    DebtorNumber: 37564,
    SecurePay_Reference: "ZAR",
    Contact2: "Larue91@example.com",
    LevelOfEnforcement: "723.05",
    Number: 45465,
    DateOffence: "2021-09-07T10:53:29.678Z",
    RegisterType: "Supervisor",
    VicSmart: "VicSmart 55",
    ePlanningCategory: "ePlanningCategory 55",
    ePlanningLodgedBy: "ePlanningLodgedBy 55",
    Comments: "Comments 55",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Tools",
    Code: "73355-0750",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "You can't transmit the system without indexing the open-source SMTP hard drive!",
    PublishDescription:
      "Try to synthesize the FTP interface, maybe it will transmit the solid state driver!",
    Title: "Auto Loan Account Plastic redefine",
    ConditionCode: "72315-3284",
    Condition: "Group",
    Name: "Mckenzie Schuppe",
    Multiplier: "Multiplier 55",
    Value: 46187,
    MinimumValue: 29533,
    TargetDays: "TargetDays 55",
    Category_1: "Music",
    Category_2: "Games",
    ObservationSummary: "ObservationSummary 55",
    ComplianceInstruction: "ComplianceInstruction 55",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-25T16:43:53.696Z",
  },
  {
    ID: 56,
    ApplicationNumber: 33918,
    PermitNumber: 90486,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-06-18T00:53:54.376Z",
    RefNo: 5724,
    AssessmentNo: 84893,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Johnny Effertz",
    Builder: "Elias Swaniawski",
    Category: "Small Plastic Car",
    BCA: "Accountability",
    UseOfBuilding: true,
    ReasonForPermit: "Shoes Ireland Delaware",
    SiteAddress: "colten.biz",
    Locality: "CA",
    Owner: "Bettie Johnson",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-05-28T10:47:58.008Z",
    Date_AllInspComplete: "2021-10-13T20:18:28.651Z",
    Date_DueToCommence: "2021-09-01T12:34:35.724Z",
    Date_Commenced: "2021-12-22T07:44:20.262Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "September",
    FileNo: 43291,
    ValuationNo: 72354,
    LegalDescription: "solutions neural Fantastic",
    BuildingOfficer: "Senior",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "21068720",
    StatDays: 55889,
    Date_Issued: "2021-06-10T18:55:09.548Z",
    Date_ReminderSent: "2021-02-25T14:27:24.469Z",
    PayingFee: 46035,
    CarryingOutWork: false,
    WorkProposed: "Ergonomic Fantastic Frozen Pants approach",
    Address: "Apt. 206",
    BuildingAppNo: 69379,
    BuildingPermitNo: 4055,
    Date_BuildingIssued: "July",
    Date_Commencing: "December",
    BondAmount: 30403,
    BondReceived: "42730",
    BondReturned: 13380,
    Date_FirstInspection: "2021-07-28T21:32:32.277Z",
    Date_LastInspection: "2021-06-11T09:50:19.904Z",
    TargetDate: "2021-07-08T21:51:23.809Z",
    CILFee: 57370,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-09-25T23:46:37.127Z",
    InspectionType: "haptic",
    Officer: "Investment Account",
    Compliance: true,
    Reason: "Quality-focused",
    FacilityRegion: "New Hampshire",
    Description: "Investor",
    DateTarget: "2021-08-31T16:17:47.467Z",
    Source: "Applications",
    Location: "AK",
    RequestedBy: "85232626",
    ContactDetails: "1-210-474-8537",
    DueStatus: false,
    DateRecorded: "2021-05-06T15:14:55.924Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Robust grid-enabled neural-net",
    ActionOfficer: "Games",
    Coordinator: "Apt. 069",
    DateWork: "2021-12-23T17:14:46.539Z",
    FileNumber: 95120,
    EnquiryRefNo: 60455,
    Event_ReferenceNoAdditional: 37754,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-02-09T09:45:52.874Z",
    ApplicantName: "Money Market Account",
    Type: "and Sons",
    Risk: "Executive synergize deposit",
    DateDue: "2021-05-18T19:36:11.588Z",
    Register_Number: 80139,
    Contact: "Suite 992",
    ReferenceNumber: 74321,
    Date_Complete: "2021-01-17T10:32:56.468Z",
    Date_Construction: "2021-07-29T06:49:09.382Z",
    BarrierStandard: "BarrierStandard 56",
    BarrierType: "BarrierType 56",
    Date_CertificateOfCompliance: "2021-08-01T13:37:37.951Z",
    Pool_RegisteredDate: "2021-05-09T00:35:47.623Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-02T05:47:04.284Z",
    Date_WorkIssued: "2021-07-12T08:52:56.538Z",
    Work_CarriedOut: "Strategist",
    AmountOS: 21166,
    Flag_RenewalNoticeSent: true,
    Outcome: 87187,
    Regulation: "enhance Central indigo",
    Date_Due: "2021-01-08T09:43:06.191Z",
    Date_NextInspection: "2021-04-11T16:13:04.042Z",
    Date_Start: "2021-10-07T18:57:46.928Z",
    Date_Occurred: "2021-09-09T23:26:26.122Z",
    ContactName: "Henry Yost",
    CreatedDate: "2020-04-22T00:58:28.983Z",
    FeeType: "$",
    AmountIssued: 61195,
    AmountOutstanding: 33893,
    CostCode: 44313,
    AmountPaid: 62867,
    ReceiptNumber: 61302,
    ReceiptDate: "2021-07-01T20:37:38.754Z",
    ReceiptedBy: "Administrator",
    Method: "Senior",
    InvoiceNumber: 26692,
    DebtorNumber: 9492,
    SecurePay_Reference: "MKD",
    Contact2: "Noelia_Paucek@example.org",
    LevelOfEnforcement: "155.08",
    Number: 76111,
    DateOffence: "2021-05-15T01:53:34.662Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 56",
    ePlanningCategory: "ePlanningCategory 56",
    ePlanningLodgedBy: "ePlanningLodgedBy 56",
    Comments: "Comments 56",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Beauty",
    Code: "21913",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName: "We need to back up the mobile XSS pixel!",
    PublishDescription:
      "I'll index the redundant SAS matrix, that should driver the SAS feed!",
    Title: "microchip Data",
    ConditionCode: "59781-4629",
    Condition: "Group",
    Name: "Jerry Herman",
    Multiplier: "Multiplier 56",
    Value: 73931,
    MinimumValue: 42427,
    TargetDays: "TargetDays 56",
    Category_1: "Outdoors",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 56",
    ComplianceInstruction: "ComplianceInstruction 56",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-21T04:41:25.265Z",
  },
  {
    ID: 57,
    ApplicationNumber: 84532,
    PermitNumber: 3457,
    PermitType: "payment",
    Date_PermitIssued: "2021-04-02T23:22:50.315Z",
    RefNo: 47195,
    AssessmentNo: 13120,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Ms. Adolfo Jones",
    Builder: "Vladimir Moen",
    Category: "Small Plastic Hat",
    BCA: "East Caribbean Dollar",
    UseOfBuilding: true,
    ReasonForPermit: "backing up auxiliary",
    SiteAddress: "eudora.name",
    Locality: "NJ",
    Owner: "Amira Kunde",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-08-07T03:49:56.376Z",
    Date_AllInspComplete: "2021-08-04T01:06:48.211Z",
    Date_DueToCommence: "2021-12-07T13:30:17.367Z",
    Date_Commenced: "2021-12-24T07:45:58.259Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "April",
    FileNo: 91935,
    ValuationNo: 24457,
    LegalDescription: "Awesome Plastic Ball",
    BuildingOfficer: "Future",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "06576182",
    StatDays: 82449,
    Date_Issued: "2021-12-18T16:38:31.037Z",
    Date_ReminderSent: "2021-05-28T17:52:55.433Z",
    PayingFee: 14587,
    CarryingOutWork: false,
    WorkProposed: "bus",
    Address: "Suite 298",
    BuildingAppNo: 94591,
    BuildingPermitNo: 27556,
    Date_BuildingIssued: "December",
    Date_Commencing: "May",
    BondAmount: 35128,
    BondReceived: "83773-6993",
    BondReturned: 88588,
    Date_FirstInspection: "2021-02-08T05:47:08.803Z",
    Date_LastInspection: "2021-12-21T23:56:46.480Z",
    TargetDate: "2021-01-21T11:36:14.953Z",
    CILFee: 31658,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-06-21T22:24:56.303Z",
    InspectionType: "yellow",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "withdrawal Hat",
    FacilityRegion: "West Virginia",
    Description: "Future",
    DateTarget: "2021-09-22T15:51:02.395Z",
    Source: "Creative",
    Location: "IN",
    RequestedBy: "43671407",
    ContactDetails: "858-392-2915",
    DueStatus: false,
    DateRecorded: "2021-09-10T03:52:44.573Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Synergistic interactive toolset",
    ActionOfficer: "Kids",
    Coordinator: "Apt. 416",
    DateWork: "2021-07-21T08:09:03.839Z",
    FileNumber: 55338,
    EnquiryRefNo: 83150,
    Event_ReferenceNoAdditional: 14994,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-09-28T01:43:19.873Z",
    ApplicantName: "Money Market Account",
    Type: "and Sons",
    Risk: "connecting Pants Savings Account",
    DateDue: "2021-09-14T13:17:16.107Z",
    Register_Number: 77479,
    Contact: "Apt. 020",
    ReferenceNumber: 92998,
    Date_Complete: "2021-12-08T09:14:20.453Z",
    Date_Construction: "2021-12-05T20:47:13.456Z",
    BarrierStandard: "BarrierStandard 57",
    BarrierType: "BarrierType 57",
    Date_CertificateOfCompliance: "2021-06-29T10:21:46.445Z",
    Pool_RegisteredDate: "2021-01-03T17:22:22.108Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-08T22:10:27.272Z",
    Date_WorkIssued: "2021-12-09T12:13:19.816Z",
    Work_CarriedOut: "Director",
    AmountOS: 86763,
    Flag_RenewalNoticeSent: true,
    Outcome: 38831,
    Regulation:
      "Money Market Account Ways European Unit of Account 9(E.U.A.-9)",
    Date_Due: "2021-12-26T08:30:42.784Z",
    Date_NextInspection: "2021-11-14T03:16:19.866Z",
    Date_Start: "2021-11-09T03:23:12.945Z",
    Date_Occurred: "2021-06-15T09:42:00.880Z",
    ContactName: "Francesca Labadie DDS",
    CreatedDate: "2020-04-27T00:26:29.005Z",
    FeeType: "ƒ",
    AmountIssued: 6342,
    AmountOutstanding: 89746,
    CostCode: 38976,
    AmountPaid: 48321,
    ReceiptNumber: 32719,
    ReceiptDate: "2021-08-27T16:46:50.625Z",
    ReceiptedBy: "Officer",
    Method: "District",
    InvoiceNumber: 75532,
    DebtorNumber: 62444,
    SecurePay_Reference: "XPT",
    Contact2: "Mallie20@example.com",
    LevelOfEnforcement: "733.51",
    Number: 2004,
    DateOffence: "2021-05-11T02:50:09.231Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 57",
    ePlanningCategory: "ePlanningCategory 57",
    ePlanningLodgedBy: "ePlanningLodgedBy 57",
    Comments: "Comments 57",
    ApplicationCategory_Name: "Computers",
    ApplicationCategory_Description: "Health",
    Code: "01250-6992",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "compressing the capacitor won't do anything, we need to navigate the mobile IB pixel!",
    PublishDescription:
      "Use the bluetooth CSS monitor, then you can calculate the multi-byte circuit!",
    Title: "full-range Bacon Games",
    ConditionCode: "74270-1922",
    Condition: "and Sons",
    Name: "Ethyl Kuphal",
    Multiplier: "Multiplier 57",
    Value: 72822,
    MinimumValue: 51144,
    TargetDays: "TargetDays 57",
    Category_1: "Computers",
    Category_2: "Music",
    ObservationSummary: "ObservationSummary 57",
    ComplianceInstruction: "ComplianceInstruction 57",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-06T23:18:47.049Z",
  },
  {
    ID: 58,
    ApplicationNumber: 50250,
    PermitNumber: 57177,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-11-11T10:20:55.737Z",
    RefNo: 39122,
    AssessmentNo: 84118,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Cali Steuber",
    Builder: "Mozell Abernathy Jr.",
    Category: "Rustic Fresh Bike",
    BCA: "Iranian Rial",
    UseOfBuilding: true,
    ReasonForPermit: "invoice",
    SiteAddress: "morton.com",
    Locality: "ME",
    Owner: "Bernhard Wolff V",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-02-27T04:22:00.492Z",
    Date_AllInspComplete: "2021-05-05T00:34:35.940Z",
    Date_DueToCommence: "2021-01-18T11:23:32.030Z",
    Date_Commenced: "2021-01-15T00:54:38.991Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "May",
    FileNo: 4778,
    ValuationNo: 18563,
    LegalDescription: "Iraqi Dinar Bedfordshire Handcrafted Rubber Pants",
    BuildingOfficer: "International",
    Surveyor: "Savings Account",
    SurveyorNumber: "15575507",
    StatDays: 200,
    Date_Issued: "2021-02-22T05:57:58.213Z",
    Date_ReminderSent: "2021-11-25T10:08:35.480Z",
    PayingFee: 80834,
    CarryingOutWork: false,
    WorkProposed: "Checking Account",
    Address: "Apt. 126",
    BuildingAppNo: 24736,
    BuildingPermitNo: 9118,
    Date_BuildingIssued: "October",
    Date_Commencing: "November",
    BondAmount: 81668,
    BondReceived: "62130-1722",
    BondReturned: 10498,
    Date_FirstInspection: "2021-05-26T18:21:47.714Z",
    Date_LastInspection: "2021-06-29T04:04:05.572Z",
    TargetDate: "2021-10-01T00:19:52.054Z",
    CILFee: 32173,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-12-24T17:22:22.822Z",
    InspectionType: "Integration",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "Home Loan Account Chair Infrastructure",
    FacilityRegion: "New Jersey",
    Description: "Senior",
    DateTarget: "2021-05-01T10:50:08.654Z",
    Source: "Division",
    Location: "OK",
    RequestedBy: "97088666",
    ContactDetails: "168.690.3344 x651",
    DueStatus: false,
    DateRecorded: "2021-01-22T07:40:25.775Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Monitored multi-tasking artificial intelligence",
    ActionOfficer: "Toys",
    Coordinator: "Apt. 193",
    DateWork: "2021-05-16T18:31:39.823Z",
    FileNumber: 23374,
    EnquiryRefNo: 72541,
    Event_ReferenceNoAdditional: 44265,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-09-07T07:45:52.542Z",
    ApplicantName: "Credit Card Account",
    Type: "Inc",
    Risk: "generate",
    DateDue: "2021-12-08T14:31:58.244Z",
    Register_Number: 48089,
    Contact: "Suite 371",
    ReferenceNumber: 76439,
    Date_Complete: "2021-10-06T05:48:34.148Z",
    Date_Construction: "2021-04-05T10:45:46.346Z",
    BarrierStandard: "BarrierStandard 58",
    BarrierType: "BarrierType 58",
    Date_CertificateOfCompliance: "2021-04-27T05:42:39.949Z",
    Pool_RegisteredDate: "2021-12-03T12:30:03.124Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-25T13:16:58.420Z",
    Date_WorkIssued: "2021-06-05T17:15:49.116Z",
    Work_CarriedOut: "Facilitator",
    AmountOS: 91662,
    Flag_RenewalNoticeSent: false,
    Outcome: 13947,
    Regulation: "deposit",
    Date_Due: "2021-09-11T20:26:14.165Z",
    Date_NextInspection: "2021-11-08T19:53:41.991Z",
    Date_Start: "2021-12-23T00:48:53.546Z",
    Date_Occurred: "2021-05-26T07:44:14.376Z",
    ContactName: "Richie Bernhard",
    CreatedDate: "2020-05-25T06:04:12.922Z",
    FeeType: "£",
    AmountIssued: 83165,
    AmountOutstanding: 54721,
    CostCode: 49550,
    AmountPaid: 70631,
    ReceiptNumber: 75654,
    ReceiptDate: "2021-04-26T10:23:23.166Z",
    ReceiptedBy: "Engineer",
    Method: "Forward",
    InvoiceNumber: 45071,
    DebtorNumber: 93210,
    SecurePay_Reference: "LRD",
    Contact2: "Alyce79@example.net",
    LevelOfEnforcement: "21.70",
    Number: 49878,
    DateOffence: "2021-07-12T06:31:27.089Z",
    RegisterType: "Administrator",
    VicSmart: "VicSmart 58",
    ePlanningCategory: "ePlanningCategory 58",
    ePlanningLodgedBy: "ePlanningLodgedBy 58",
    Comments: "Comments 58",
    ApplicationCategory_Name: "Home",
    ApplicationCategory_Description: "Music",
    Code: "28385",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "Use the virtual SCSI driver, then you can synthesize the solid state bandwidth!",
    PublishDescription:
      "Try to compress the IB program, maybe it will input the neural alarm!",
    Title: "Brand Incredible transmit",
    ConditionCode: "00569-3844",
    Condition: "LLC",
    Name: "Mike McCullough",
    Multiplier: "Multiplier 58",
    Value: 31292,
    MinimumValue: 63801,
    TargetDays: "TargetDays 58",
    Category_1: "Computers",
    Category_2: "Health",
    ObservationSummary: "ObservationSummary 58",
    ComplianceInstruction: "ComplianceInstruction 58",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-28T10:53:37.002Z",
  },
  {
    ID: 59,
    ApplicationNumber: 79895,
    PermitNumber: 77752,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-12-02T03:12:35.643Z",
    RefNo: 60997,
    AssessmentNo: 95990,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Sammie Berge",
    Builder: "Valerie Stamm",
    Category: "Licensed Soft Pizza",
    BCA: "Illinois",
    UseOfBuilding: false,
    ReasonForPermit: "cross-platform 24 hour",
    SiteAddress: "angel.com",
    Locality: "SC",
    Owner: "Boris Veum",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-03-06T10:14:58.235Z",
    Date_AllInspComplete: "2021-02-12T23:59:29.022Z",
    Date_DueToCommence: "2021-12-14T02:49:08.734Z",
    Date_Commenced: "2021-02-05T14:29:27.478Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "September",
    FileNo: 38472,
    ValuationNo: 73843,
    LegalDescription: "Belgium AI SDD",
    BuildingOfficer: "Central",
    Surveyor: "Savings Account",
    SurveyorNumber: "22072101",
    StatDays: 31485,
    Date_Issued: "2021-08-02T22:54:01.844Z",
    Date_ReminderSent: "2021-11-16T05:45:19.583Z",
    PayingFee: 19028,
    CarryingOutWork: false,
    WorkProposed: "Chicken",
    Address: "Apt. 851",
    BuildingAppNo: 77239,
    BuildingPermitNo: 20842,
    Date_BuildingIssued: "March",
    Date_Commencing: "September",
    BondAmount: 11491,
    BondReceived: "45857-8095",
    BondReturned: 61739,
    Date_FirstInspection: "2021-07-21T12:24:26.978Z",
    Date_LastInspection: "2021-12-13T01:37:48.712Z",
    TargetDate: "2021-10-06T06:44:48.175Z",
    CILFee: 20942,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-05-15T03:52:49.045Z",
    InspectionType: "Right-sized",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "strategize copying",
    FacilityRegion: "New Hampshire",
    Description: "Direct",
    DateTarget: "2021-08-12T05:53:21.907Z",
    Source: "Accounts",
    Location: "HI",
    RequestedBy: "08426972",
    ContactDetails: "827-703-3198 x0098",
    DueStatus: false,
    DateRecorded: "2021-02-04T04:32:40.800Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Multi-channelled 24 hour synergy",
    ActionOfficer: "Tools",
    Coordinator: "Apt. 470",
    DateWork: "2021-07-15T08:49:10.884Z",
    FileNumber: 84445,
    EnquiryRefNo: 26802,
    Event_ReferenceNoAdditional: 32493,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-10-30T21:36:37.527Z",
    ApplicantName: "Auto Loan Account",
    Type: "LLC",
    Risk: "Intelligent New Hampshire",
    DateDue: "2021-04-01T15:38:17.226Z",
    Register_Number: 14923,
    Contact: "Suite 311",
    ReferenceNumber: 60540,
    Date_Complete: "2021-10-23T19:26:39.649Z",
    Date_Construction: "2021-11-18T08:11:57.357Z",
    BarrierStandard: "BarrierStandard 59",
    BarrierType: "BarrierType 59",
    Date_CertificateOfCompliance: "2021-04-17T09:48:28.387Z",
    Pool_RegisteredDate: "2021-01-28T16:18:45.500Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-07T08:43:29.104Z",
    Date_WorkIssued: "2021-06-01T14:20:07.375Z",
    Work_CarriedOut: "Representative",
    AmountOS: 79657,
    Flag_RenewalNoticeSent: true,
    Outcome: 76626,
    Regulation: "empower Sleek Nevada",
    Date_Due: "2021-01-20T11:04:04.777Z",
    Date_NextInspection: "2021-01-26T00:08:42.609Z",
    Date_Start: "2021-10-11T08:59:53.906Z",
    Date_Occurred: "2021-06-24T03:55:03.868Z",
    ContactName: "Loy Armstrong I",
    CreatedDate: "2020-11-23T11:25:12.844Z",
    FeeType: "TT$",
    AmountIssued: 18688,
    AmountOutstanding: 85239,
    CostCode: 21940,
    AmountPaid: 53045,
    ReceiptNumber: 15800,
    ReceiptDate: "2021-04-12T08:00:31.331Z",
    ReceiptedBy: "Analyst",
    Method: "Legacy",
    InvoiceNumber: 60588,
    DebtorNumber: 97907,
    SecurePay_Reference: "ISK",
    Contact2: "Layla_Shields@example.net",
    LevelOfEnforcement: "525.92",
    Number: 89961,
    DateOffence: "2021-02-25T22:16:45.581Z",
    RegisterType: "Associate",
    VicSmart: "VicSmart 59",
    ePlanningCategory: "ePlanningCategory 59",
    ePlanningLodgedBy: "ePlanningLodgedBy 59",
    Comments: "Comments 59",
    ApplicationCategory_Name: "Music",
    ApplicationCategory_Description: "Jewelery",
    Code: "47081",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "I'll synthesize the multi-byte RAM firewall, that should system the PNG program!",
    PublishDescription:
      "I'll back up the 1080p SCSI application, that should interface the SDD system!",
    Title: "tertiary calculating Program",
    ConditionCode: "44223",
    Condition: "Group",
    Name: "Riley Hamill",
    Multiplier: "Multiplier 59",
    Value: 20099,
    MinimumValue: 374,
    TargetDays: "TargetDays 59",
    Category_1: "Industrial",
    Category_2: "Baby",
    ObservationSummary: "ObservationSummary 59",
    ComplianceInstruction: "ComplianceInstruction 59",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-11-28T02:16:43.805Z",
  },
  {
    ID: 60,
    ApplicationNumber: 30166,
    PermitNumber: 44522,
    PermitType: "deposit",
    Date_PermitIssued: "2021-03-13T06:27:58.903Z",
    RefNo: 84285,
    AssessmentNo: 16020,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Dion Kautzer",
    Builder: "Vivian Wintheiser",
    Category: "Intelligent Rubber Bacon",
    BCA: "haptic",
    UseOfBuilding: true,
    ReasonForPermit: "Future Industrial Games",
    SiteAddress: "idell.name",
    Locality: "ND",
    Owner: "Emely Wiza III",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-01-18T00:14:46.642Z",
    Date_AllInspComplete: "2021-06-04T14:15:49.476Z",
    Date_DueToCommence: "2021-03-31T17:39:28.435Z",
    Date_Commenced: "2021-08-31T10:46:11.926Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "June",
    FileNo: 21785,
    ValuationNo: 54959,
    LegalDescription: "Small Handmade Granite Chips parsing",
    BuildingOfficer: "Product",
    Surveyor: "Savings Account",
    SurveyorNumber: "34616783",
    StatDays: 58454,
    Date_Issued: "2021-08-19T06:09:49.378Z",
    Date_ReminderSent: "2021-02-24T20:44:52.779Z",
    PayingFee: 51438,
    CarryingOutWork: true,
    WorkProposed: "Vermont",
    Address: "Suite 956",
    BuildingAppNo: 98347,
    BuildingPermitNo: 63522,
    Date_BuildingIssued: "March",
    Date_Commencing: "December",
    BondAmount: 59879,
    BondReceived: "62004-0978",
    BondReturned: 56333,
    Date_FirstInspection: "2021-01-08T02:53:59.376Z",
    Date_LastInspection: "2021-06-12T06:49:16.271Z",
    TargetDate: "2021-03-30T09:26:44.730Z",
    CILFee: 25906,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-04-29T18:22:12.229Z",
    InspectionType: "Steel",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "Frozen capacitor",
    FacilityRegion: "North Carolina",
    Description: "Internal",
    DateTarget: "2021-12-27T20:23:26.435Z",
    Source: "Accountability",
    Location: "OH",
    RequestedBy: "05409168",
    ContactDetails: "876.320.1014 x5431",
    DueStatus: false,
    DateRecorded: "2021-09-03T21:34:37.586Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Right-sized reciprocal workforce",
    ActionOfficer: "Tools",
    Coordinator: "Suite 559",
    DateWork: "2021-07-19T22:51:40.732Z",
    FileNumber: 83970,
    EnquiryRefNo: 80492,
    Event_ReferenceNoAdditional: 5165,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-06-20T19:39:07.475Z",
    ApplicantName: "Savings Account",
    Type: "LLC",
    Risk: "Money Market Account AI",
    DateDue: "2021-04-27T07:15:06.840Z",
    Register_Number: 17993,
    Contact: "Apt. 434",
    ReferenceNumber: 58691,
    Date_Complete: "2021-05-04T09:23:49.258Z",
    Date_Construction: "2021-11-18T04:09:03.185Z",
    BarrierStandard: "BarrierStandard 60",
    BarrierType: "BarrierType 60",
    Date_CertificateOfCompliance: "2021-04-10T09:21:36.618Z",
    Pool_RegisteredDate: "2021-07-19T15:40:37.257Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-05T11:19:15.485Z",
    Date_WorkIssued: "2021-06-07T11:24:37.894Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 90456,
    Flag_RenewalNoticeSent: true,
    Outcome: 92317,
    Regulation: "flexibility haptic payment",
    Date_Due: "2021-02-27T17:26:16.442Z",
    Date_NextInspection: "2021-04-24T18:14:33.742Z",
    Date_Start: "2021-06-06T07:06:27.756Z",
    Date_Occurred: "2021-10-29T21:12:06.850Z",
    ContactName: "Diego Nolan",
    CreatedDate: "2020-11-12T03:34:27.654Z",
    FeeType: "KM",
    AmountIssued: 4370,
    AmountOutstanding: 17531,
    CostCode: 36768,
    AmountPaid: 21097,
    ReceiptNumber: 27253,
    ReceiptDate: "2021-11-14T02:36:48.089Z",
    ReceiptedBy: "Technician",
    Method: "Global",
    InvoiceNumber: 60661,
    DebtorNumber: 53168,
    SecurePay_Reference: "NZD",
    Contact2: "Rupert.Nitzsche@example.org",
    LevelOfEnforcement: "183.10",
    Number: 51235,
    DateOffence: "2021-07-09T20:38:10.996Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 60",
    ePlanningCategory: "ePlanningCategory 60",
    ePlanningLodgedBy: "ePlanningLodgedBy 60",
    Comments: "Comments 60",
    ApplicationCategory_Name: "Health",
    ApplicationCategory_Description: "Music",
    Code: "99278-0860",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "programming the array won't do anything, we need to program the wireless SSL capacitor!",
    PublishDescription:
      "parsing the sensor won't do anything, we need to parse the primary XML protocol!",
    Title: "SAS Frozen",
    ConditionCode: "17452",
    Condition: "and Sons",
    Name: "Wilmer Kihn MD",
    Multiplier: "Multiplier 60",
    Value: 42472,
    MinimumValue: 94807,
    TargetDays: "TargetDays 60",
    Category_1: "Health",
    Category_2: "Grocery",
    ObservationSummary: "ObservationSummary 60",
    ComplianceInstruction: "ComplianceInstruction 60",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-09-30T13:34:39.300Z",
  },
  {
    ID: 61,
    ApplicationNumber: 37147,
    PermitNumber: 43591,
    PermitType: "payment",
    Date_PermitIssued: "2021-09-21T13:54:28.451Z",
    RefNo: 97849,
    AssessmentNo: 37112,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Ms. Giuseppe Cole",
    Builder: "Mrs. Ottis Treutel",
    Category: "Ergonomic Wooden Mouse",
    BCA: "Web",
    UseOfBuilding: true,
    ReasonForPermit: "Industrial grow",
    SiteAddress: "bradford.name",
    Locality: "CA",
    Owner: "Jamaal Schumm",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-06-11T02:57:40.327Z",
    Date_AllInspComplete: "2021-08-25T04:02:04.500Z",
    Date_DueToCommence: "2021-10-31T07:36:30.390Z",
    Date_Commenced: "2021-04-16T21:19:46.097Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "January",
    FileNo: 33002,
    ValuationNo: 18912,
    LegalDescription: "Shoes payment mobile",
    BuildingOfficer: "Senior",
    Surveyor: "Investment Account",
    SurveyorNumber: "04081044",
    StatDays: 98876,
    Date_Issued: "2021-08-16T12:05:33.939Z",
    Date_ReminderSent: "2021-09-30T08:46:28.594Z",
    PayingFee: 91128,
    CarryingOutWork: true,
    WorkProposed: "Awesome Concrete Hat heuristic",
    Address: "Suite 885",
    BuildingAppNo: 71767,
    BuildingPermitNo: 4607,
    Date_BuildingIssued: "August",
    Date_Commencing: "March",
    BondAmount: 98234,
    BondReceived: "71109",
    BondReturned: 32901,
    Date_FirstInspection: "2021-01-09T21:36:26.350Z",
    Date_LastInspection: "2021-04-18T21:02:26.818Z",
    TargetDate: "2021-04-10T12:32:15.677Z",
    CILFee: 88152,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-07-30T07:47:20.917Z",
    InspectionType: "Group",
    Officer: "Home Loan Account",
    Compliance: true,
    Reason: "SQL",
    FacilityRegion: "New Mexico",
    Description: "Product",
    DateTarget: "2021-10-23T06:35:29.681Z",
    Source: "Operations",
    Location: "IN",
    RequestedBy: "72386004",
    ContactDetails: "580.915.4100 x610",
    DueStatus: true,
    DateRecorded: "2021-06-11T21:50:13.884Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Centralized regional encryption",
    ActionOfficer: "Outdoors",
    Coordinator: "Apt. 945",
    DateWork: "2021-10-20T02:49:05.735Z",
    FileNumber: 94688,
    EnquiryRefNo: 2175,
    Event_ReferenceNoAdditional: 55794,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-07-05T10:49:04.691Z",
    ApplicantName: "Home Loan Account",
    Type: "Inc",
    Risk: "Communications Unbranded Plastic Bike Buckinghamshire",
    DateDue: "2021-07-12T07:53:48.568Z",
    Register_Number: 18543,
    Contact: "Suite 940",
    ReferenceNumber: 2331,
    Date_Complete: "2021-03-22T17:52:34.379Z",
    Date_Construction: "2021-06-14T04:37:00.708Z",
    BarrierStandard: "BarrierStandard 61",
    BarrierType: "BarrierType 61",
    Date_CertificateOfCompliance: "2021-08-10T08:54:39.182Z",
    Pool_RegisteredDate: "2021-12-30T18:13:56.871Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-02-24T11:53:35.447Z",
    Date_WorkIssued: "2021-07-02T10:05:57.459Z",
    Work_CarriedOut: "Director",
    AmountOS: 73841,
    Flag_RenewalNoticeSent: true,
    Outcome: 68285,
    Regulation: "override Orchestrator",
    Date_Due: "2021-03-07T17:30:36.994Z",
    Date_NextInspection: "2021-01-14T12:47:45.395Z",
    Date_Start: "2021-01-28T11:24:31.091Z",
    Date_Occurred: "2021-01-29T23:24:59.925Z",
    ContactName: "Emmalee Huel",
    CreatedDate: "2020-06-09T14:52:08.198Z",
    FeeType: "лв",
    AmountIssued: 34346,
    AmountOutstanding: 15213,
    CostCode: 52963,
    AmountPaid: 75733,
    ReceiptNumber: 50500,
    ReceiptDate: "2021-01-03T22:39:15.226Z",
    ReceiptedBy: "Administrator",
    Method: "Internal",
    InvoiceNumber: 1326,
    DebtorNumber: 77588,
    SecurePay_Reference: "BRL",
    Contact2: "Chadd.Grimes35@example.org",
    LevelOfEnforcement: "817.47",
    Number: 92413,
    DateOffence: "2021-09-04T06:46:39.462Z",
    RegisterType: "Developer",
    VicSmart: "VicSmart 61",
    ePlanningCategory: "ePlanningCategory 61",
    ePlanningLodgedBy: "ePlanningLodgedBy 61",
    Comments: "Comments 61",
    ApplicationCategory_Name: "Games",
    ApplicationCategory_Description: "Shoes",
    Code: "18746-2311",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Use the auxiliary GB sensor, then you can bypass the solid state microchip!",
    PublishDescription:
      "The HTTP panel is down, calculate the wireless driver so we can back up the SAS pixel!",
    Title: "e-tailers",
    ConditionCode: "05320-2634",
    Condition: "Group",
    Name: "Vicenta Glover",
    Multiplier: "Multiplier 61",
    Value: 39354,
    MinimumValue: 99985,
    TargetDays: "TargetDays 61",
    Category_1: "Garden",
    Category_2: "Tools",
    ObservationSummary: "ObservationSummary 61",
    ComplianceInstruction: "ComplianceInstruction 61",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-23T13:22:02.490Z",
  },
  {
    ID: 62,
    ApplicationNumber: 89389,
    PermitNumber: 81958,
    PermitType: "payment",
    Date_PermitIssued: "2021-12-14T07:37:11.999Z",
    RefNo: 44715,
    AssessmentNo: 20672,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Miss Jessy Bahringer",
    Builder: "Angeline Howe",
    Category: "Incredible Soft Shoes",
    BCA: "Fantastic Plastic Fish",
    UseOfBuilding: false,
    ReasonForPermit: "circuit Sao Tome and Principe Organized",
    SiteAddress: "elva.com",
    Locality: "IL",
    Owner: "Waldo Crona",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-12-16T12:07:45.769Z",
    Date_AllInspComplete: "2021-09-15T07:53:16.667Z",
    Date_DueToCommence: "2021-04-24T06:31:24.832Z",
    Date_Commenced: "2021-11-12T22:07:15.005Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "June",
    FileNo: 70698,
    ValuationNo: 79545,
    LegalDescription: "Drive Music",
    BuildingOfficer: "Forward",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "15420123",
    StatDays: 43200,
    Date_Issued: "2021-02-14T11:11:37.393Z",
    Date_ReminderSent: "2021-01-05T12:23:17.809Z",
    PayingFee: 79299,
    CarryingOutWork: true,
    WorkProposed: "B2B black even-keeled",
    Address: "Suite 238",
    BuildingAppNo: 73048,
    BuildingPermitNo: 55320,
    Date_BuildingIssued: "December",
    Date_Commencing: "April",
    BondAmount: 77402,
    BondReceived: "62509",
    BondReturned: 74777,
    Date_FirstInspection: "2021-05-30T00:25:31.064Z",
    Date_LastInspection: "2021-05-03T16:24:28.750Z",
    TargetDate: "2021-09-26T03:42:35.140Z",
    CILFee: 99096,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-04-13T16:31:13.744Z",
    InspectionType: "GB",
    Officer: "Savings Account",
    Compliance: false,
    Reason: "Customer",
    FacilityRegion: "Kansas",
    Description: "Direct",
    DateTarget: "2021-02-11T08:26:16.535Z",
    Source: "Configuration",
    Location: "PA",
    RequestedBy: "71392272",
    ContactDetails: "1-438-693-0687 x6812",
    DueStatus: true,
    DateRecorded: "2021-04-06T22:51:59.223Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Down-sized motivating artificial intelligence",
    ActionOfficer: "Books",
    Coordinator: "Suite 305",
    DateWork: "2021-12-28T07:28:29.551Z",
    FileNumber: 92552,
    EnquiryRefNo: 53561,
    Event_ReferenceNoAdditional: 34994,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-04-14T18:54:46.549Z",
    ApplicantName: "Credit Card Account",
    Type: "Inc",
    Risk: "Green success",
    DateDue: "2021-02-26T03:50:57.146Z",
    Register_Number: 78090,
    Contact: "Suite 080",
    ReferenceNumber: 24632,
    Date_Complete: "2021-03-11T23:16:19.562Z",
    Date_Construction: "2021-06-26T14:24:35.362Z",
    BarrierStandard: "BarrierStandard 62",
    BarrierType: "BarrierType 62",
    Date_CertificateOfCompliance: "2021-06-23T21:31:44.406Z",
    Pool_RegisteredDate: "2021-04-17T15:31:48.478Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-30T11:22:45.279Z",
    Date_WorkIssued: "2021-09-13T02:00:55.756Z",
    Work_CarriedOut: "Engineer",
    AmountOS: 40713,
    Flag_RenewalNoticeSent: true,
    Outcome: 61102,
    Regulation: "Saint Martin",
    Date_Due: "2021-10-24T17:03:03.672Z",
    Date_NextInspection: "2021-04-14T04:30:45.891Z",
    Date_Start: "2021-06-18T03:34:52.815Z",
    Date_Occurred: "2021-01-26T21:58:25.470Z",
    ContactName: "Ferne Hickle",
    CreatedDate: "2020-09-10T01:41:11.631Z",
    FeeType: "лв",
    AmountIssued: 61549,
    AmountOutstanding: 30893,
    CostCode: 94212,
    AmountPaid: 87775,
    ReceiptNumber: 63204,
    ReceiptDate: "2021-04-18T23:49:33.252Z",
    ReceiptedBy: "Representative",
    Method: "Central",
    InvoiceNumber: 98576,
    DebtorNumber: 7999,
    SecurePay_Reference: "GNF",
    Contact2: "Lisette.Schmidt@example.org",
    LevelOfEnforcement: "880.30",
    Number: 7715,
    DateOffence: "2021-09-04T17:48:14.438Z",
    RegisterType: "Assistant",
    VicSmart: "VicSmart 62",
    ePlanningCategory: "ePlanningCategory 62",
    ePlanningLodgedBy: "ePlanningLodgedBy 62",
    Comments: "Comments 62",
    ApplicationCategory_Name: "Health",
    ApplicationCategory_Description: "Music",
    Code: "29638",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName: "We need to navigate the primary HDD port!",
    PublishDescription:
      "The SAS monitor is down, override the back-end port so we can override the PNG port!",
    Title: "Lead",
    ConditionCode: "72630",
    Condition: "LLC",
    Name: "Johanna Spencer",
    Multiplier: "Multiplier 62",
    Value: 93263,
    MinimumValue: 21508,
    TargetDays: "TargetDays 62",
    Category_1: "Industrial",
    Category_2: "Shoes",
    ObservationSummary: "ObservationSummary 62",
    ComplianceInstruction: "ComplianceInstruction 62",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-22T04:43:43.251Z",
  },
  {
    ID: 63,
    ApplicationNumber: 68332,
    PermitNumber: 11605,
    PermitType: "payment",
    Date_PermitIssued: "2021-01-03T21:40:50.041Z",
    RefNo: 20865,
    AssessmentNo: 58234,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Modesto Crooks",
    Builder: "Mrs. Norwood Feil",
    Category: "Incredible Granite Cheese",
    BCA: "Cotton",
    UseOfBuilding: false,
    ReasonForPermit: "IB",
    SiteAddress: "madaline.org",
    Locality: "VA",
    Owner: "Juwan Rosenbaum",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-05-15T15:50:03.145Z",
    Date_AllInspComplete: "2021-09-26T06:30:06.234Z",
    Date_DueToCommence: "2021-05-10T00:55:53.638Z",
    Date_Commenced: "2021-05-21T07:27:59.682Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "December",
    FileNo: 75525,
    ValuationNo: 55736,
    LegalDescription: "ROI Plastic",
    BuildingOfficer: "Dynamic",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "51151127",
    StatDays: 68683,
    Date_Issued: "2021-04-18T23:10:45.335Z",
    Date_ReminderSent: "2021-04-09T21:36:13.625Z",
    PayingFee: 53316,
    CarryingOutWork: true,
    WorkProposed: "Solomon Islands Dollar",
    Address: "Apt. 157",
    BuildingAppNo: 25463,
    BuildingPermitNo: 40259,
    Date_BuildingIssued: "June",
    Date_Commencing: "September",
    BondAmount: 1305,
    BondReceived: "93943",
    BondReturned: 74233,
    Date_FirstInspection: "2021-07-06T15:06:23.383Z",
    Date_LastInspection: "2021-03-13T15:51:54.538Z",
    TargetDate: "2021-12-07T04:07:55.867Z",
    CILFee: 50363,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-12-21T13:04:20.388Z",
    InspectionType: "Avon",
    Officer: "Checking Account",
    Compliance: true,
    Reason: "pixel back up Aruba",
    FacilityRegion: "Michigan",
    Description: "Dynamic",
    DateTarget: "2021-08-28T07:40:55.020Z",
    Source: "Directives",
    Location: "IN",
    RequestedBy: "35718381",
    ContactDetails: "134.245.0775",
    DueStatus: true,
    DateRecorded: "2021-01-21T04:36:42.197Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Future-proofed full-range software",
    ActionOfficer: "Automotive",
    Coordinator: "Apt. 020",
    DateWork: "2021-05-19T04:54:02.856Z",
    FileNumber: 35369,
    EnquiryRefNo: 44878,
    Event_ReferenceNoAdditional: 5908,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-02-16T01:08:18.328Z",
    ApplicantName: "Investment Account",
    Type: "Group",
    Risk: "Fantastic Rubber Pizza quantifying",
    DateDue: "2021-11-19T09:54:38.103Z",
    Register_Number: 80356,
    Contact: "Suite 150",
    ReferenceNumber: 37873,
    Date_Complete: "2021-09-26T16:04:07.949Z",
    Date_Construction: "2021-01-17T07:47:00.418Z",
    BarrierStandard: "BarrierStandard 63",
    BarrierType: "BarrierType 63",
    Date_CertificateOfCompliance: "2021-09-02T08:26:19.514Z",
    Pool_RegisteredDate: "2021-07-04T17:35:04.284Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-14T21:55:28.271Z",
    Date_WorkIssued: "2020-12-31T13:21:25.455Z",
    Work_CarriedOut: "Orchestrator",
    AmountOS: 192,
    Flag_RenewalNoticeSent: false,
    Outcome: 18424,
    Regulation: "Shore back-end Svalbard & Jan Mayen Islands",
    Date_Due: "2021-09-03T05:38:31.451Z",
    Date_NextInspection: "2021-08-08T17:24:19.572Z",
    Date_Start: "2021-06-19T19:24:22.611Z",
    Date_Occurred: "2021-01-05T00:35:04.501Z",
    ContactName: "Mrs. Halie Kihn",
    CreatedDate: "2020-01-24T14:18:34.656Z",
    FeeType: "$",
    AmountIssued: 36083,
    AmountOutstanding: 44390,
    CostCode: 55572,
    AmountPaid: 93855,
    ReceiptNumber: 17922,
    ReceiptDate: "2021-04-24T19:47:49.593Z",
    ReceiptedBy: "Administrator",
    Method: "Dynamic",
    InvoiceNumber: 79855,
    DebtorNumber: 7153,
    SecurePay_Reference: "VUV",
    Contact2: "Angus_Senger@example.net",
    LevelOfEnforcement: "221.46",
    Number: 99401,
    DateOffence: "2021-09-28T11:32:14.565Z",
    RegisterType: "Producer",
    VicSmart: "VicSmart 63",
    ePlanningCategory: "ePlanningCategory 63",
    ePlanningLodgedBy: "ePlanningLodgedBy 63",
    Comments: "Comments 63",
    ApplicationCategory_Name: "Grocery",
    ApplicationCategory_Description: "Kids",
    Code: "08787-6320",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "I'll override the 1080p HDD matrix, that should application the COM sensor!",
    PublishDescription:
      "The FTP array is down, reboot the 1080p port so we can hack the CSS sensor!",
    Title: "backing up Planner",
    ConditionCode: "37192",
    Condition: "Inc",
    Name: "Josiane Wunsch",
    Multiplier: "Multiplier 63",
    Value: 10043,
    MinimumValue: 40988,
    TargetDays: "TargetDays 63",
    Category_1: "Shoes",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 63",
    ComplianceInstruction: "ComplianceInstruction 63",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-15T14:08:04.615Z",
  },
  {
    ID: 64,
    ApplicationNumber: 17492,
    PermitNumber: 53717,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-08-06T04:03:56.093Z",
    RefNo: 81438,
    AssessmentNo: 30748,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Anjali Nader",
    Builder: "Karli Satterfield",
    Category: "Handmade Frozen Shoes",
    BCA: "yellow",
    UseOfBuilding: true,
    ReasonForPermit: "impactful Garden",
    SiteAddress: "bennie.biz",
    Locality: "OH",
    Owner: "Isac Medhurst",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-05-13T11:20:54.493Z",
    Date_AllInspComplete: "2021-03-15T15:19:03.204Z",
    Date_DueToCommence: "2021-04-02T17:54:36.190Z",
    Date_Commenced: "2021-03-07T11:44:59.969Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "February",
    FileNo: 84742,
    ValuationNo: 45090,
    LegalDescription: "parse Borders",
    BuildingOfficer: "National",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "33535443",
    StatDays: 5279,
    Date_Issued: "2021-02-26T05:01:53.940Z",
    Date_ReminderSent: "2021-09-19T16:10:58.542Z",
    PayingFee: 99643,
    CarryingOutWork: false,
    WorkProposed: "turquoise RSS",
    Address: "Apt. 737",
    BuildingAppNo: 31634,
    BuildingPermitNo: 22527,
    Date_BuildingIssued: "March",
    Date_Commencing: "February",
    BondAmount: 32020,
    BondReceived: "43091",
    BondReturned: 69024,
    Date_FirstInspection: "2021-05-09T22:28:46.284Z",
    Date_LastInspection: "2021-03-04T18:15:43.336Z",
    TargetDate: "2021-11-15T18:30:52.502Z",
    CILFee: 95205,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-07-09T19:27:42.673Z",
    InspectionType: "dynamic",
    Officer: "Auto Loan Account",
    Compliance: false,
    Reason: "transmitting",
    FacilityRegion: "Virginia",
    Description: "Principal",
    DateTarget: "2021-09-18T05:00:52.195Z",
    Source: "Quality",
    Location: "TX",
    RequestedBy: "57015986",
    ContactDetails: "(715) 568-2511",
    DueStatus: false,
    DateRecorded: "2021-07-31T16:06:05.736Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Total clear-thinking hierarchy",
    ActionOfficer: "Computers",
    Coordinator: "Suite 255",
    DateWork: "2021-08-06T21:41:28.206Z",
    FileNumber: 14394,
    EnquiryRefNo: 63861,
    Event_ReferenceNoAdditional: 28904,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-03-20T20:48:49.137Z",
    ApplicantName: "Investment Account",
    Type: "Inc",
    Risk: "British Indian Ocean Territory (Chagos Archipelago)",
    DateDue: "2021-04-26T12:00:35.126Z",
    Register_Number: 1527,
    Contact: "Apt. 510",
    ReferenceNumber: 80131,
    Date_Complete: "2021-08-02T01:03:22.493Z",
    Date_Construction: "2021-02-18T23:45:47.464Z",
    BarrierStandard: "BarrierStandard 64",
    BarrierType: "BarrierType 64",
    Date_CertificateOfCompliance: "2021-07-02T02:09:53.996Z",
    Pool_RegisteredDate: "2021-05-15T15:12:38.489Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-10T23:41:51.243Z",
    Date_WorkIssued: "2021-07-04T18:33:04.391Z",
    Work_CarriedOut: "Analyst",
    AmountOS: 14495,
    Flag_RenewalNoticeSent: false,
    Outcome: 30573,
    Regulation: "Fantastic",
    Date_Due: "2021-04-09T10:36:41.840Z",
    Date_NextInspection: "2021-05-24T04:35:32.459Z",
    Date_Start: "2021-01-31T12:52:12.911Z",
    Date_Occurred: "2021-09-27T09:52:08.402Z",
    ContactName: "Ayana Zulauf",
    CreatedDate: "2020-01-11T16:20:21.270Z",
    FeeType: "RD$",
    AmountIssued: 1918,
    AmountOutstanding: 327,
    CostCode: 49689,
    AmountPaid: 64667,
    ReceiptNumber: 40590,
    ReceiptDate: "2021-08-27T11:41:45.685Z",
    ReceiptedBy: "Technician",
    Method: "Lead",
    InvoiceNumber: 84322,
    DebtorNumber: 58342,
    SecurePay_Reference: "PGK",
    Contact2: "Jaida.Anderson28@example.com",
    LevelOfEnforcement: "852.08",
    Number: 89890,
    DateOffence: "2021-03-10T05:17:36.595Z",
    RegisterType: "Representative",
    VicSmart: "VicSmart 64",
    ePlanningCategory: "ePlanningCategory 64",
    ePlanningLodgedBy: "ePlanningLodgedBy 64",
    Comments: "Comments 64",
    ApplicationCategory_Name: "Electronics",
    ApplicationCategory_Description: "Beauty",
    Code: "27076-8464",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "programming the port won't do anything, we need to copy the mobile FTP feed!",
    PublishDescription:
      "You can't compress the feed without transmitting the 1080p SQL pixel!",
    Title: "morph index",
    ConditionCode: "25116-8989",
    Condition: "LLC",
    Name: "Jena Adams",
    Multiplier: "Multiplier 64",
    Value: 50766,
    MinimumValue: 86768,
    TargetDays: "TargetDays 64",
    Category_1: "Sports",
    Category_2: "Games",
    ObservationSummary: "ObservationSummary 64",
    ComplianceInstruction: "ComplianceInstruction 64",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-08T22:32:39.108Z",
  },
  {
    ID: 65,
    ApplicationNumber: 68041,
    PermitNumber: 69512,
    PermitType: "invoice",
    Date_PermitIssued: "2021-12-25T12:29:59.390Z",
    RefNo: 31879,
    AssessmentNo: 22124,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Jazlyn Williamson",
    Builder: "Preston Welch",
    Category: "Fantastic Concrete Bacon",
    BCA: "back up",
    UseOfBuilding: true,
    ReasonForPermit: "Buckinghamshire protocol",
    SiteAddress: "terrence.biz",
    Locality: "LA",
    Owner: "Robbie Schmitt",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-07-05T08:53:16.212Z",
    Date_AllInspComplete: "2021-10-24T22:07:58.729Z",
    Date_DueToCommence: "2021-08-03T11:48:07.708Z",
    Date_Commenced: "2021-06-18T17:26:55.577Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "January",
    FileNo: 8534,
    ValuationNo: 20511,
    LegalDescription: "Regional",
    BuildingOfficer: "Legacy",
    Surveyor: "Money Market Account",
    SurveyorNumber: "39307693",
    StatDays: 58697,
    Date_Issued: "2021-07-07T04:34:08.083Z",
    Date_ReminderSent: "2021-02-15T05:07:34.438Z",
    PayingFee: 24332,
    CarryingOutWork: true,
    WorkProposed: "FTP web-enabled Buckinghamshire",
    Address: "Apt. 212",
    BuildingAppNo: 23737,
    BuildingPermitNo: 92314,
    Date_BuildingIssued: "April",
    Date_Commencing: "March",
    BondAmount: 27016,
    BondReceived: "44450",
    BondReturned: 39318,
    Date_FirstInspection: "2021-02-17T07:19:35.848Z",
    Date_LastInspection: "2021-05-14T11:07:15.796Z",
    TargetDate: "2021-11-14T03:58:31.777Z",
    CILFee: 3592,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-04-12T21:37:09.860Z",
    InspectionType: "portals",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "Research reintermediate withdrawal",
    FacilityRegion: "Indiana",
    Description: "Forward",
    DateTarget: "2021-07-26T15:44:07.572Z",
    Source: "Response",
    Location: "MT",
    RequestedBy: "15323549",
    ContactDetails: "(816) 108-5805",
    DueStatus: false,
    DateRecorded: "2021-11-11T09:58:58.656Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Synchronised logistical initiative",
    ActionOfficer: "Sports",
    Coordinator: "Suite 763",
    DateWork: "2021-03-05T00:36:49.846Z",
    FileNumber: 32748,
    EnquiryRefNo: 24843,
    Event_ReferenceNoAdditional: 43350,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-03-28T21:58:31.664Z",
    ApplicantName: "Money Market Account",
    Type: "Group",
    Risk: "withdrawal Engineer Buckinghamshire",
    DateDue: "2021-05-22T18:36:46.709Z",
    Register_Number: 32910,
    Contact: "Apt. 180",
    ReferenceNumber: 55147,
    Date_Complete: "2021-06-17T00:52:44.493Z",
    Date_Construction: "2021-04-10T12:30:01.974Z",
    BarrierStandard: "BarrierStandard 65",
    BarrierType: "BarrierType 65",
    Date_CertificateOfCompliance: "2021-01-06T08:24:54.181Z",
    Pool_RegisteredDate: "2021-07-10T06:53:47.764Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-02T07:37:29.375Z",
    Date_WorkIssued: "2021-05-13T07:29:19.603Z",
    Work_CarriedOut: "Assistant",
    AmountOS: 2931,
    Flag_RenewalNoticeSent: true,
    Outcome: 83376,
    Regulation: "Bedfordshire",
    Date_Due: "2021-02-20T17:25:45.120Z",
    Date_NextInspection: "2021-08-03T08:09:05.211Z",
    Date_Start: "2021-07-26T15:57:37.456Z",
    Date_Occurred: "2021-01-27T20:27:41.443Z",
    ContactName: "Kacey Ernser",
    CreatedDate: "2020-10-03T00:51:47.744Z",
    FeeType: "lei",
    AmountIssued: 27147,
    AmountOutstanding: 32737,
    CostCode: 48360,
    AmountPaid: 37244,
    ReceiptNumber: 65587,
    ReceiptDate: "2021-03-09T08:07:39.237Z",
    ReceiptedBy: "Engineer",
    Method: "Forward",
    InvoiceNumber: 25387,
    DebtorNumber: 26544,
    SecurePay_Reference: "MYR",
    Contact2: "Maximilian.Schiller@example.com",
    LevelOfEnforcement: "549.33",
    Number: 72384,
    DateOffence: "2021-07-21T14:20:48.314Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 65",
    ePlanningCategory: "ePlanningCategory 65",
    ePlanningLodgedBy: "ePlanningLodgedBy 65",
    Comments: "Comments 65",
    ApplicationCategory_Name: "Music",
    ApplicationCategory_Description: "Industrial",
    Code: "64831-4746",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "If we calculate the alarm, we can get to the SQL panel through the optical SMS monitor!",
    PublishDescription:
      "I'll generate the cross-platform XML protocol, that should panel the TCP system!",
    Title: "Automotive Louisiana Fish",
    ConditionCode: "25267-3850",
    Condition: "Inc",
    Name: "Precious Graham",
    Multiplier: "Multiplier 65",
    Value: 35906,
    MinimumValue: 27084,
    TargetDays: "TargetDays 65",
    Category_1: "Jewelery",
    Category_2: "Electronics",
    ObservationSummary: "ObservationSummary 65",
    ComplianceInstruction: "ComplianceInstruction 65",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-21T22:55:15.477Z",
  },
  {
    ID: 66,
    ApplicationNumber: 63769,
    PermitNumber: 6201,
    PermitType: "invoice",
    Date_PermitIssued: "2021-07-07T20:20:37.098Z",
    RefNo: 85817,
    AssessmentNo: 11704,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Brianne Pollich",
    Builder: "Gregorio Shanahan",
    Category: "Fantastic Plastic Tuna",
    BCA: "South Carolina",
    UseOfBuilding: false,
    ReasonForPermit: "program",
    SiteAddress: "zachary.com",
    Locality: "NE",
    Owner: "Opal Marks",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-04-14T13:54:19.034Z",
    Date_AllInspComplete: "2021-05-15T15:15:38.143Z",
    Date_DueToCommence: "2021-07-24T14:25:54.035Z",
    Date_Commenced: "2021-06-20T09:44:02.049Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "June",
    FileNo: 20002,
    ValuationNo: 3431,
    LegalDescription: "Buckinghamshire Soft wireless",
    BuildingOfficer: "Product",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "54163632",
    StatDays: 17205,
    Date_Issued: "2021-03-01T14:55:14.277Z",
    Date_ReminderSent: "2021-08-28T19:47:18.177Z",
    PayingFee: 21556,
    CarryingOutWork: false,
    WorkProposed: "Turkish Lira",
    Address: "Apt. 251",
    BuildingAppNo: 28406,
    BuildingPermitNo: 58795,
    Date_BuildingIssued: "August",
    Date_Commencing: "April",
    BondAmount: 9183,
    BondReceived: "52080",
    BondReturned: 64903,
    Date_FirstInspection: "2021-12-30T21:42:38.305Z",
    Date_LastInspection: "2021-02-21T21:36:29.363Z",
    TargetDate: "2021-01-22T21:34:18.084Z",
    CILFee: 53949,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-04-26T17:25:41.621Z",
    InspectionType: "multimedia",
    Officer: "Checking Account",
    Compliance: true,
    Reason: "24/365",
    FacilityRegion: "Missouri",
    Description: "District",
    DateTarget: "2021-12-26T02:01:32.236Z",
    Source: "Division",
    Location: "AL",
    RequestedBy: "96748811",
    ContactDetails: "1-237-714-6055 x04065",
    DueStatus: false,
    DateRecorded: "2021-02-11T06:50:44.401Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Diverse regional core",
    ActionOfficer: "Grocery",
    Coordinator: "Apt. 674",
    DateWork: "2021-05-17T13:45:37.033Z",
    FileNumber: 27654,
    EnquiryRefNo: 54618,
    Event_ReferenceNoAdditional: 72751,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-06-02T05:13:16.689Z",
    ApplicantName: "Checking Account",
    Type: "LLC",
    Risk: "quantifying",
    DateDue: "2021-04-13T19:48:30.688Z",
    Register_Number: 36748,
    Contact: "Apt. 592",
    ReferenceNumber: 12889,
    Date_Complete: "2021-02-10T19:40:02.207Z",
    Date_Construction: "2021-03-19T01:19:57.694Z",
    BarrierStandard: "BarrierStandard 66",
    BarrierType: "BarrierType 66",
    Date_CertificateOfCompliance: "2021-07-03T17:25:44.374Z",
    Pool_RegisteredDate: "2021-06-10T18:40:45.608Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-01T14:22:58.477Z",
    Date_WorkIssued: "2021-09-26T06:08:22.898Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 46797,
    Flag_RenewalNoticeSent: false,
    Outcome: 13145,
    Regulation: "Handcrafted Central IB",
    Date_Due: "2021-09-21T20:21:26.335Z",
    Date_NextInspection: "2021-12-04T21:54:55.935Z",
    Date_Start: "2021-10-06T05:04:17.794Z",
    Date_Occurred: "2021-01-17T00:18:08.891Z",
    ContactName: "Brandyn Gibson",
    CreatedDate: "2020-02-15T00:19:09.637Z",
    FeeType: "¥",
    AmountIssued: 75811,
    AmountOutstanding: 38643,
    CostCode: 39667,
    AmountPaid: 17140,
    ReceiptNumber: 95265,
    ReceiptDate: "2021-06-01T09:21:03.050Z",
    ReceiptedBy: "Strategist",
    Method: "District",
    InvoiceNumber: 98377,
    DebtorNumber: 68379,
    SecurePay_Reference: "ERN",
    Contact2: "Lexus78@example.org",
    LevelOfEnforcement: "338.21",
    Number: 78947,
    DateOffence: "2021-07-19T20:16:46.128Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 66",
    ePlanningCategory: "ePlanningCategory 66",
    ePlanningLodgedBy: "ePlanningLodgedBy 66",
    Comments: "Comments 66",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Jewelery",
    Code: "28173-4740",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "Use the 1080p USB monitor, then you can bypass the 1080p alarm!",
    PublishDescription:
      "Use the haptic EXE alarm, then you can calculate the solid state bus!",
    Title: "Wells",
    ConditionCode: "38202",
    Condition: "and Sons",
    Name: "Ethyl Kilback",
    Multiplier: "Multiplier 66",
    Value: 95445,
    MinimumValue: 60999,
    TargetDays: "TargetDays 66",
    Category_1: "Toys",
    Category_2: "Games",
    ObservationSummary: "ObservationSummary 66",
    ComplianceInstruction: "ComplianceInstruction 66",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-22T19:37:05.772Z",
  },
  {
    ID: 67,
    ApplicationNumber: 69230,
    PermitNumber: 71447,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-01-17T05:29:33.234Z",
    RefNo: 66205,
    AssessmentNo: 40913,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Ms. Reed Morissette",
    Builder: "Makayla Gerlach",
    Category: "Licensed Fresh Pizza",
    BCA: "North Dakota",
    UseOfBuilding: false,
    ReasonForPermit: "mobile",
    SiteAddress: "don.net",
    Locality: "LA",
    Owner: "Merritt Jast",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-07-18T16:51:04.245Z",
    Date_AllInspComplete: "2021-07-26T06:35:30.489Z",
    Date_DueToCommence: "2021-05-19T19:14:10.345Z",
    Date_Commenced: "2021-02-02T20:59:46.886Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "March",
    FileNo: 95202,
    ValuationNo: 50607,
    LegalDescription: "Rwanda Franc next-generation Automotive",
    BuildingOfficer: "Direct",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "54837838",
    StatDays: 26747,
    Date_Issued: "2021-02-08T16:26:40.058Z",
    Date_ReminderSent: "2021-09-05T23:18:27.151Z",
    PayingFee: 99676,
    CarryingOutWork: false,
    WorkProposed: "Won",
    Address: "Suite 741",
    BuildingAppNo: 14001,
    BuildingPermitNo: 9480,
    Date_BuildingIssued: "February",
    Date_Commencing: "March",
    BondAmount: 55218,
    BondReceived: "74900",
    BondReturned: 19528,
    Date_FirstInspection: "2021-10-07T14:33:02.059Z",
    Date_LastInspection: "2021-09-17T18:25:29.449Z",
    TargetDate: "2021-07-10T22:01:21.328Z",
    CILFee: 11197,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-11-05T13:14:18.637Z",
    InspectionType: "RSS",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "invoice encryption",
    FacilityRegion: "Minnesota",
    Description: "Future",
    DateTarget: "2021-12-11T05:35:18.675Z",
    Source: "Configuration",
    Location: "MS",
    RequestedBy: "56530195",
    ContactDetails: "759-668-7360 x65995",
    DueStatus: true,
    DateRecorded: "2021-08-03T05:03:06.725Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Intuitive local toolset",
    ActionOfficer: "Automotive",
    Coordinator: "Suite 476",
    DateWork: "2021-05-11T19:36:52.797Z",
    FileNumber: 60442,
    EnquiryRefNo: 58972,
    Event_ReferenceNoAdditional: 52346,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-08-25T21:05:32.822Z",
    ApplicantName: "Credit Card Account",
    Type: "Inc",
    Risk: "empower transmit facilitate",
    DateDue: "2021-12-26T02:20:03.943Z",
    Register_Number: 50746,
    Contact: "Apt. 857",
    ReferenceNumber: 7934,
    Date_Complete: "2021-11-15T16:21:13.443Z",
    Date_Construction: "2021-12-16T01:34:15.626Z",
    BarrierStandard: "BarrierStandard 67",
    BarrierType: "BarrierType 67",
    Date_CertificateOfCompliance: "2021-06-29T16:19:01.567Z",
    Pool_RegisteredDate: "2021-02-09T03:16:00.643Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-23T13:16:44.904Z",
    Date_WorkIssued: "2021-06-17T16:28:16.683Z",
    Work_CarriedOut: "Associate",
    AmountOS: 53772,
    Flag_RenewalNoticeSent: false,
    Outcome: 75569,
    Regulation: "Central Avon",
    Date_Due: "2021-05-07T18:49:50.384Z",
    Date_NextInspection: "2021-09-27T08:40:23.364Z",
    Date_Start: "2021-10-09T09:49:30.819Z",
    Date_Occurred: "2021-03-23T01:50:36.621Z",
    ContactName: "Allene Ullrich",
    CreatedDate: "2020-07-29T07:26:48.118Z",
    FeeType: "R",
    AmountIssued: 96725,
    AmountOutstanding: 97795,
    CostCode: 40558,
    AmountPaid: 70976,
    ReceiptNumber: 58180,
    ReceiptDate: "2021-11-01T14:42:15.238Z",
    ReceiptedBy: "Liaison",
    Method: "Direct",
    InvoiceNumber: 45035,
    DebtorNumber: 37742,
    SecurePay_Reference: "TJS",
    Contact2: "Pamela.Zboncak@example.net",
    LevelOfEnforcement: "560.57",
    Number: 1488,
    DateOffence: "2021-04-21T20:34:56.985Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 67",
    ePlanningCategory: "ePlanningCategory 67",
    ePlanningLodgedBy: "ePlanningLodgedBy 67",
    Comments: "Comments 67",
    ApplicationCategory_Name: "Shoes",
    ApplicationCategory_Description: "Health",
    Code: "38555-2480",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "I'll quantify the digital AI circuit, that should transmitter the IB pixel!",
    PublishDescription:
      "Use the 1080p CSS matrix, then you can reboot the bluetooth port!",
    Title: "Cambridgeshire Car withdrawal",
    ConditionCode: "19702",
    Condition: "and Sons",
    Name: "Henry Upton",
    Multiplier: "Multiplier 67",
    Value: 68810,
    MinimumValue: 79745,
    TargetDays: "TargetDays 67",
    Category_1: "Games",
    Category_2: "Games",
    ObservationSummary: "ObservationSummary 67",
    ComplianceInstruction: "ComplianceInstruction 67",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-22T20:16:26.640Z",
  },
  {
    ID: 68,
    ApplicationNumber: 3975,
    PermitNumber: 2236,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-06-02T09:44:06.394Z",
    RefNo: 5269,
    AssessmentNo: 30459,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Vivian Bauch",
    Builder: "Yvette Schneider",
    Category: "Ergonomic Granite Sausages",
    BCA: "quantify",
    UseOfBuilding: true,
    ReasonForPermit: "SSL Shoes matrices",
    SiteAddress: "alena.name",
    Locality: "NV",
    Owner: "Mrs. Willy Lesch",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-07-13T04:57:26.050Z",
    Date_AllInspComplete: "2021-07-02T09:54:32.231Z",
    Date_DueToCommence: "2021-09-09T10:35:42.138Z",
    Date_Commenced: "2021-07-08T07:03:56.131Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "August",
    FileNo: 27640,
    ValuationNo: 70386,
    LegalDescription: "Avon intangible redefine",
    BuildingOfficer: "Dynamic",
    Surveyor: "Checking Account",
    SurveyorNumber: "92779243",
    StatDays: 93150,
    Date_Issued: "2021-11-24T02:42:07.226Z",
    Date_ReminderSent: "2021-12-21T13:58:26.739Z",
    PayingFee: 53931,
    CarryingOutWork: false,
    WorkProposed: "asymmetric Program deposit",
    Address: "Suite 726",
    BuildingAppNo: 15426,
    BuildingPermitNo: 2491,
    Date_BuildingIssued: "February",
    Date_Commencing: "July",
    BondAmount: 97955,
    BondReceived: "81154-5157",
    BondReturned: 95505,
    Date_FirstInspection: "2021-05-18T19:15:03.760Z",
    Date_LastInspection: "2021-07-06T19:48:38.951Z",
    TargetDate: "2021-03-25T14:40:12.496Z",
    CILFee: 32022,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-06-23T07:01:21.650Z",
    InspectionType: "supply-chains",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "Botswana North Carolina Beauty",
    FacilityRegion: "Delaware",
    Description: "Senior",
    DateTarget: "2021-08-05T20:17:38.704Z",
    Source: "Operations",
    Location: "WV",
    RequestedBy: "43919380",
    ContactDetails: "1-163-140-7914 x0733",
    DueStatus: false,
    DateRecorded: "2021-02-23T15:48:19.057Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Cloned discrete instruction set",
    ActionOfficer: "Tools",
    Coordinator: "Suite 737",
    DateWork: "2021-03-04T20:41:18.645Z",
    FileNumber: 65326,
    EnquiryRefNo: 50453,
    Event_ReferenceNoAdditional: 54272,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-07-19T13:37:20.815Z",
    ApplicantName: "Investment Account",
    Type: "Group",
    Risk: "Villages",
    DateDue: "2021-05-14T06:29:54.421Z",
    Register_Number: 4179,
    Contact: "Suite 300",
    ReferenceNumber: 54347,
    Date_Complete: "2021-11-05T20:19:42.657Z",
    Date_Construction: "2021-04-20T13:07:22.709Z",
    BarrierStandard: "BarrierStandard 68",
    BarrierType: "BarrierType 68",
    Date_CertificateOfCompliance: "2021-12-25T04:58:25.144Z",
    Pool_RegisteredDate: "2021-08-19T11:04:54.836Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-13T17:16:23.554Z",
    Date_WorkIssued: "2021-08-02T17:16:08.658Z",
    Work_CarriedOut: "Supervisor",
    AmountOS: 620,
    Flag_RenewalNoticeSent: false,
    Outcome: 81818,
    Regulation: "parallelism methodologies Fantastic Rubber Car",
    Date_Due: "2021-03-22T15:04:16.565Z",
    Date_NextInspection: "2021-07-01T10:40:53.802Z",
    Date_Start: "2021-11-29T21:49:34.748Z",
    Date_Occurred: "2021-06-05T06:55:32.910Z",
    ContactName: "Mrs. Everardo Torp",
    CreatedDate: "2020-03-19T07:16:57.457Z",
    FeeType: "₨",
    AmountIssued: 58971,
    AmountOutstanding: 97810,
    CostCode: 97465,
    AmountPaid: 66057,
    ReceiptNumber: 35540,
    ReceiptDate: "2021-02-03T07:17:06.362Z",
    ReceiptedBy: "Designer",
    Method: "Dynamic",
    InvoiceNumber: 44284,
    DebtorNumber: 29240,
    SecurePay_Reference: "UAH",
    Contact2: "Sarah_Weber36@example.com",
    LevelOfEnforcement: "286.46",
    Number: 78864,
    DateOffence: "2021-02-04T09:18:24.897Z",
    RegisterType: "Coordinator",
    VicSmart: "VicSmart 68",
    ePlanningCategory: "ePlanningCategory 68",
    ePlanningLodgedBy: "ePlanningLodgedBy 68",
    Comments: "Comments 68",
    ApplicationCategory_Name: "Tools",
    ApplicationCategory_Description: "Sports",
    Code: "41513",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "You can't calculate the bus without synthesizing the neural RAM panel!",
    PublishDescription:
      "You can't reboot the bus without copying the digital IB firewall!",
    Title: "generating",
    ConditionCode: "87702",
    Condition: "LLC",
    Name: "Domenico Yundt",
    Multiplier: "Multiplier 68",
    Value: 5721,
    MinimumValue: 75417,
    TargetDays: "TargetDays 68",
    Category_1: "Industrial",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 68",
    ComplianceInstruction: "ComplianceInstruction 68",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-01-29T18:11:40.334Z",
  },
  {
    ID: 69,
    ApplicationNumber: 57278,
    PermitNumber: 53463,
    PermitType: "payment",
    Date_PermitIssued: "2021-12-08T02:15:30.651Z",
    RefNo: 95894,
    AssessmentNo: 77569,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Rebeca Satterfield II",
    Builder: "Paige Roob",
    Category: "Licensed Steel Pizza",
    BCA: "compress",
    UseOfBuilding: false,
    ReasonForPermit: "AI dot-com",
    SiteAddress: "jaunita.biz",
    Locality: "CO",
    Owner: "Walton Zemlak",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-09-29T20:25:10.658Z",
    Date_AllInspComplete: "2021-02-22T21:31:28.059Z",
    Date_DueToCommence: "2021-07-20T23:49:24.079Z",
    Date_Commenced: "2021-06-14T11:30:07.754Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "April",
    FileNo: 92439,
    ValuationNo: 62290,
    LegalDescription: "quantifying Enhanced Tools",
    BuildingOfficer: "District",
    Surveyor: "Investment Account",
    SurveyorNumber: "98582904",
    StatDays: 26793,
    Date_Issued: "2021-11-15T17:18:54.709Z",
    Date_ReminderSent: "2021-12-15T10:53:29.865Z",
    PayingFee: 88578,
    CarryingOutWork: true,
    WorkProposed: "Consultant",
    Address: "Apt. 444",
    BuildingAppNo: 60001,
    BuildingPermitNo: 19533,
    Date_BuildingIssued: "June",
    Date_Commencing: "December",
    BondAmount: 24353,
    BondReceived: "17459-5487",
    BondReturned: 59839,
    Date_FirstInspection: "2021-02-12T03:46:26.136Z",
    Date_LastInspection: "2021-08-21T22:58:49.906Z",
    TargetDate: "2021-06-07T18:23:15.118Z",
    CILFee: 59384,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-08-08T21:58:32.011Z",
    InspectionType: "zero tolerance",
    Officer: "Investment Account",
    Compliance: true,
    Reason: "paradigms Heights Gorgeous Rubber Gloves",
    FacilityRegion: "North Dakota",
    Description: "Direct",
    DateTarget: "2021-05-21T14:55:25.939Z",
    Source: "Marketing",
    Location: "SD",
    RequestedBy: "80563033",
    ContactDetails: "(008) 281-5167 x6454",
    DueStatus: false,
    DateRecorded: "2021-10-12T09:47:15.477Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Vision-oriented 4th generation function",
    ActionOfficer: "Sports",
    Coordinator: "Suite 590",
    DateWork: "2021-08-18T05:08:44.020Z",
    FileNumber: 48768,
    EnquiryRefNo: 98109,
    Event_ReferenceNoAdditional: 4690,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-06-11T02:50:36.187Z",
    ApplicantName: "Auto Loan Account",
    Type: "Group",
    Risk: "web services Intelligent",
    DateDue: "2021-06-29T15:04:43.890Z",
    Register_Number: 55300,
    Contact: "Apt. 461",
    ReferenceNumber: 35086,
    Date_Complete: "2021-12-10T02:29:50.267Z",
    Date_Construction: "2021-10-10T19:41:22.729Z",
    BarrierStandard: "BarrierStandard 69",
    BarrierType: "BarrierType 69",
    Date_CertificateOfCompliance: "2021-10-24T20:43:30.373Z",
    Pool_RegisteredDate: "2021-10-13T01:49:45.711Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-18T09:15:38.492Z",
    Date_WorkIssued: "2021-09-06T10:42:16.005Z",
    Work_CarriedOut: "Associate",
    AmountOS: 54222,
    Flag_RenewalNoticeSent: false,
    Outcome: 55470,
    Regulation: "standardization",
    Date_Due: "2021-03-21T15:21:52.471Z",
    Date_NextInspection: "2021-05-26T06:05:43.085Z",
    Date_Start: "2021-09-24T18:06:16.087Z",
    Date_Occurred: "2021-01-24T17:09:21.433Z",
    ContactName: "Jaquan Dibbert",
    CreatedDate: "2020-06-06T02:32:29.953Z",
    FeeType: "kr",
    AmountIssued: 44078,
    AmountOutstanding: 34068,
    CostCode: 14634,
    AmountPaid: 97321,
    ReceiptNumber: 77257,
    ReceiptDate: "2021-04-22T15:45:34.899Z",
    ReceiptedBy: "Agent",
    Method: "Central",
    InvoiceNumber: 51405,
    DebtorNumber: 94389,
    SecurePay_Reference: "FKP",
    Contact2: "Torey.Jenkins@example.net",
    LevelOfEnforcement: "755.79",
    Number: 94808,
    DateOffence: "2021-11-17T10:19:25.208Z",
    RegisterType: "Strategist",
    VicSmart: "VicSmart 69",
    ePlanningCategory: "ePlanningCategory 69",
    ePlanningLodgedBy: "ePlanningLodgedBy 69",
    Comments: "Comments 69",
    ApplicationCategory_Name: "Baby",
    ApplicationCategory_Description: "Computers",
    Code: "14588-9934",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "Try to connect the PNG protocol, maybe it will bypass the wireless program!",
    PublishDescription:
      "Use the solid state SCSI protocol, then you can connect the mobile interface!",
    Title: "Motorway International Program",
    ConditionCode: "33642",
    Condition: "Inc",
    Name: "Chanelle Littel",
    Multiplier: "Multiplier 69",
    Value: 99717,
    MinimumValue: 33649,
    TargetDays: "TargetDays 69",
    Category_1: "Toys",
    Category_2: "Health",
    ObservationSummary: "ObservationSummary 69",
    ComplianceInstruction: "ComplianceInstruction 69",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-11T21:14:22.920Z",
  },
  {
    ID: 70,
    ApplicationNumber: 83529,
    PermitNumber: 78196,
    PermitType: "deposit",
    Date_PermitIssued: "2021-04-23T21:47:27.876Z",
    RefNo: 7738,
    AssessmentNo: 47878,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Tyson Hilll",
    Builder: "Norval Reichel",
    Category: "Generic Wooden Shoes",
    BCA: "hacking",
    UseOfBuilding: true,
    ReasonForPermit: "Assistant",
    SiteAddress: "glen.info",
    Locality: "MA",
    Owner: "Alexys Bradtke",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-06-02T10:59:47.230Z",
    Date_AllInspComplete: "2021-08-31T21:44:21.009Z",
    Date_DueToCommence: "2021-07-14T08:27:53.033Z",
    Date_Commenced: "2021-08-18T04:37:07.287Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "July",
    FileNo: 24983,
    ValuationNo: 19225,
    LegalDescription: "Mall Direct",
    BuildingOfficer: "Direct",
    Surveyor: "Home Loan Account",
    SurveyorNumber: "11655013",
    StatDays: 42678,
    Date_Issued: "2021-02-27T17:43:59.367Z",
    Date_ReminderSent: "2021-12-12T20:12:59.693Z",
    PayingFee: 21995,
    CarryingOutWork: true,
    WorkProposed: "Frozen",
    Address: "Apt. 943",
    BuildingAppNo: 85489,
    BuildingPermitNo: 98228,
    Date_BuildingIssued: "March",
    Date_Commencing: "September",
    BondAmount: 56068,
    BondReceived: "73469-2782",
    BondReturned: 86129,
    Date_FirstInspection: "2021-07-14T22:52:50.044Z",
    Date_LastInspection: "2021-03-15T05:02:28.129Z",
    TargetDate: "2021-11-22T16:31:08.208Z",
    CILFee: 70621,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-10-14T13:16:24.750Z",
    InspectionType: "navigating",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "COM",
    FacilityRegion: "Hawaii",
    Description: "District",
    DateTarget: "2021-11-10T19:28:53.389Z",
    Source: "Infrastructure",
    Location: "WY",
    RequestedBy: "62338265",
    ContactDetails: "304-148-0190 x955",
    DueStatus: false,
    DateRecorded: "2021-07-10T19:35:05.064Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Triple-buffered 24 hour project",
    ActionOfficer: "Books",
    Coordinator: "Apt. 613",
    DateWork: "2021-12-09T00:40:31.657Z",
    FileNumber: 67249,
    EnquiryRefNo: 75392,
    Event_ReferenceNoAdditional: 70505,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-10-19T00:35:50.482Z",
    ApplicantName: "Money Market Account",
    Type: "Group",
    Risk: "maximized Licensed Rubber Soap",
    DateDue: "2021-08-28T22:18:12.268Z",
    Register_Number: 71603,
    Contact: "Apt. 304",
    ReferenceNumber: 25476,
    Date_Complete: "2021-05-12T02:12:35.343Z",
    Date_Construction: "2021-10-25T11:24:25.324Z",
    BarrierStandard: "BarrierStandard 70",
    BarrierType: "BarrierType 70",
    Date_CertificateOfCompliance: "2021-05-15T13:23:10.296Z",
    Pool_RegisteredDate: "2021-02-06T03:26:33.191Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-07T14:28:38.772Z",
    Date_WorkIssued: "2021-12-11T09:30:39.437Z",
    Work_CarriedOut: "Producer",
    AmountOS: 73503,
    Flag_RenewalNoticeSent: true,
    Outcome: 36654,
    Regulation: "synergize",
    Date_Due: "2021-11-14T07:22:33.931Z",
    Date_NextInspection: "2021-09-07T10:19:10.968Z",
    Date_Start: "2021-03-19T14:47:56.870Z",
    Date_Occurred: "2021-01-18T04:14:33.746Z",
    ContactName: "Gerson Lakin",
    CreatedDate: "2020-04-11T21:10:24.807Z",
    FeeType: "£",
    AmountIssued: 18644,
    AmountOutstanding: 99802,
    CostCode: 80080,
    AmountPaid: 735,
    ReceiptNumber: 99335,
    ReceiptDate: "2021-09-12T17:53:29.437Z",
    ReceiptedBy: "Director",
    Method: "Internal",
    InvoiceNumber: 76095,
    DebtorNumber: 4392,
    SecurePay_Reference: "SZL",
    Contact2: "Dangelo7@example.com",
    LevelOfEnforcement: "381.01",
    Number: 61519,
    DateOffence: "2021-07-15T15:11:10.013Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 70",
    ePlanningCategory: "ePlanningCategory 70",
    ePlanningLodgedBy: "ePlanningLodgedBy 70",
    Comments: "Comments 70",
    ApplicationCategory_Name: "Tools",
    ApplicationCategory_Description: "Automotive",
    Code: "14150",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "Try to program the COM panel, maybe it will hack the haptic bus!",
    PublishDescription:
      "Use the mobile CSS driver, then you can bypass the multi-byte alarm!",
    Title: "optical installation Cotton",
    ConditionCode: "09249-8268",
    Condition: "and Sons",
    Name: "Estel Sauer",
    Multiplier: "Multiplier 70",
    Value: 80714,
    MinimumValue: 90619,
    TargetDays: "TargetDays 70",
    Category_1: "Jewelery",
    Category_2: "Kids",
    ObservationSummary: "ObservationSummary 70",
    ComplianceInstruction: "ComplianceInstruction 70",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-01-18T15:48:41.744Z",
  },
  {
    ID: 71,
    ApplicationNumber: 7798,
    PermitNumber: 12717,
    PermitType: "invoice",
    Date_PermitIssued: "2021-10-25T17:20:01.209Z",
    RefNo: 18871,
    AssessmentNo: 61138,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Jermaine Gleichner",
    Builder: "Johnathon Nicolas",
    Category: "Fantastic Steel Hat",
    BCA: "Networked",
    UseOfBuilding: true,
    ReasonForPermit: "Engineer",
    SiteAddress: "ryley.org",
    Locality: "ME",
    Owner: "Rogers Hayes",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-05-25T22:34:19.551Z",
    Date_AllInspComplete: "2021-09-25T17:05:26.233Z",
    Date_DueToCommence: "2021-12-15T18:47:03.235Z",
    Date_Commenced: "2021-06-04T13:50:35.810Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "July",
    FileNo: 9060,
    ValuationNo: 64726,
    LegalDescription: "primary",
    BuildingOfficer: "Human",
    Surveyor: "Home Loan Account",
    SurveyorNumber: "00465482",
    StatDays: 31942,
    Date_Issued: "2021-07-15T11:44:16.462Z",
    Date_ReminderSent: "2021-10-13T21:54:53.617Z",
    PayingFee: 90620,
    CarryingOutWork: true,
    WorkProposed: "EXE programming",
    Address: "Apt. 299",
    BuildingAppNo: 14642,
    BuildingPermitNo: 33977,
    Date_BuildingIssued: "September",
    Date_Commencing: "February",
    BondAmount: 62085,
    BondReceived: "27434",
    BondReturned: 85516,
    Date_FirstInspection: "2021-09-09T16:52:35.097Z",
    Date_LastInspection: "2021-04-30T18:57:12.255Z",
    TargetDate: "2021-07-12T12:07:40.778Z",
    CILFee: 45491,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-10-29T16:10:12.417Z",
    InspectionType: "quantify",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "solid state",
    FacilityRegion: "Nevada",
    Description: "Senior",
    DateTarget: "2021-08-06T09:26:59.489Z",
    Source: "Marketing",
    Location: "MO",
    RequestedBy: "99896919",
    ContactDetails: "(559) 626-8595 x34813",
    DueStatus: true,
    DateRecorded: "2021-01-14T06:19:18.558Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Grass-roots web-enabled alliance",
    ActionOfficer: "Electronics",
    Coordinator: "Suite 609",
    DateWork: "2021-10-25T08:16:41.454Z",
    FileNumber: 42905,
    EnquiryRefNo: 80273,
    Event_ReferenceNoAdditional: 22735,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-06-21T17:59:49.047Z",
    ApplicantName: "Personal Loan Account",
    Type: "and Sons",
    Risk: "viral fault-tolerant",
    DateDue: "2021-06-30T00:24:06.015Z",
    Register_Number: 48557,
    Contact: "Apt. 854",
    ReferenceNumber: 6656,
    Date_Complete: "2021-09-30T08:24:35.163Z",
    Date_Construction: "2021-01-03T16:17:36.790Z",
    BarrierStandard: "BarrierStandard 71",
    BarrierType: "BarrierType 71",
    Date_CertificateOfCompliance: "2021-07-09T00:36:03.613Z",
    Pool_RegisteredDate: "2021-04-18T10:21:09.776Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-22T13:03:41.033Z",
    Date_WorkIssued: "2021-12-21T00:53:19.111Z",
    Work_CarriedOut: "Analyst",
    AmountOS: 56725,
    Flag_RenewalNoticeSent: true,
    Outcome: 29257,
    Regulation: "multi-byte project sensor",
    Date_Due: "2021-09-14T00:55:30.432Z",
    Date_NextInspection: "2021-12-07T19:43:43.134Z",
    Date_Start: "2021-01-04T16:43:50.122Z",
    Date_Occurred: "2021-06-16T18:27:55.921Z",
    ContactName: "Alf Doyle",
    CreatedDate: "2020-07-31T15:35:16.175Z",
    FeeType: "$",
    AmountIssued: 27368,
    AmountOutstanding: 66683,
    CostCode: 14269,
    AmountPaid: 94728,
    ReceiptNumber: 70501,
    ReceiptDate: "2021-08-03T23:42:38.434Z",
    ReceiptedBy: "Liaison",
    Method: "Global",
    InvoiceNumber: 92752,
    DebtorNumber: 3620,
    SecurePay_Reference: "SGD",
    Contact2: "Bobby76@example.com",
    LevelOfEnforcement: "333.40",
    Number: 9879,
    DateOffence: "2021-12-01T16:39:28.472Z",
    RegisterType: "Planner",
    VicSmart: "VicSmart 71",
    ePlanningCategory: "ePlanningCategory 71",
    ePlanningLodgedBy: "ePlanningLodgedBy 71",
    Comments: "Comments 71",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Grocery",
    Code: "28229-8895",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "parsing the bus won't do anything, we need to connect the bluetooth PNG sensor!",
    PublishDescription:
      "Use the haptic COM panel, then you can bypass the back-end panel!",
    Title: "content copy protocol",
    ConditionCode: "46416-5698",
    Condition: "Group",
    Name: "Jasper Feil",
    Multiplier: "Multiplier 71",
    Value: 30374,
    MinimumValue: 94197,
    TargetDays: "TargetDays 71",
    Category_1: "Shoes",
    Category_2: "Industrial",
    ObservationSummary: "ObservationSummary 71",
    ComplianceInstruction: "ComplianceInstruction 71",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-22T02:21:40.846Z",
  },
  {
    ID: 72,
    ApplicationNumber: 12384,
    PermitNumber: 42601,
    PermitType: "deposit",
    Date_PermitIssued: "2021-01-19T17:19:15.587Z",
    RefNo: 80647,
    AssessmentNo: 23039,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Rogers Cartwright",
    Builder: "Winston Keeling",
    Category: "Fantastic Metal Keyboard",
    BCA: "GB",
    UseOfBuilding: false,
    ReasonForPermit: "gold frame transitional",
    SiteAddress: "maximillian.com",
    Locality: "CT",
    Owner: "Delia Swift",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-11-22T05:25:47.396Z",
    Date_AllInspComplete: "2021-02-12T15:06:12.730Z",
    Date_DueToCommence: "2021-05-07T13:15:01.366Z",
    Date_Commenced: "2021-10-15T13:06:30.231Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "October",
    FileNo: 41025,
    ValuationNo: 99068,
    LegalDescription: "virtual Steel",
    BuildingOfficer: "Forward",
    Surveyor: "Money Market Account",
    SurveyorNumber: "23111301",
    StatDays: 69069,
    Date_Issued: "2021-11-04T10:47:26.086Z",
    Date_ReminderSent: "2021-06-11T13:46:50.611Z",
    PayingFee: 12098,
    CarryingOutWork: false,
    WorkProposed: "Tools",
    Address: "Suite 549",
    BuildingAppNo: 27952,
    BuildingPermitNo: 77449,
    Date_BuildingIssued: "December",
    Date_Commencing: "March",
    BondAmount: 93504,
    BondReceived: "66381",
    BondReturned: 2859,
    Date_FirstInspection: "2021-06-11T21:39:43.027Z",
    Date_LastInspection: "2021-04-21T05:38:59.526Z",
    TargetDate: "2021-09-14T15:58:59.991Z",
    CILFee: 86055,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-12-02T22:04:35.168Z",
    InspectionType: "Wisconsin",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "TCP SCSI",
    FacilityRegion: "Iowa",
    Description: "Dynamic",
    DateTarget: "2021-09-28T02:10:32.748Z",
    Source: "Metrics",
    Location: "MN",
    RequestedBy: "65500395",
    ContactDetails: "286-717-9168 x350",
    DueStatus: true,
    DateRecorded: "2021-11-15T02:51:49.749Z",
    RecordedBy: "Investment Account",
    OrgStructure: "De-engineered static projection",
    ActionOfficer: "Music",
    Coordinator: "Apt. 162",
    DateWork: "2021-07-05T07:06:05.932Z",
    FileNumber: 41940,
    EnquiryRefNo: 27644,
    Event_ReferenceNoAdditional: 74485,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-10-09T18:00:27.759Z",
    ApplicantName: "Savings Account",
    Type: "and Sons",
    Risk: "Plastic Executive",
    DateDue: "2021-10-29T10:16:32.684Z",
    Register_Number: 93894,
    Contact: "Suite 944",
    ReferenceNumber: 5940,
    Date_Complete: "2021-02-11T16:31:33.711Z",
    Date_Construction: "2021-08-11T17:08:24.523Z",
    BarrierStandard: "BarrierStandard 72",
    BarrierType: "BarrierType 72",
    Date_CertificateOfCompliance: "2021-07-22T08:51:12.131Z",
    Pool_RegisteredDate: "2021-01-24T14:51:39.367Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-18T17:44:27.164Z",
    Date_WorkIssued: "2021-03-13T18:34:38.259Z",
    Work_CarriedOut: "Designer",
    AmountOS: 66238,
    Flag_RenewalNoticeSent: false,
    Outcome: 70161,
    Regulation: "Balanced",
    Date_Due: "2021-11-04T19:13:52.887Z",
    Date_NextInspection: "2021-11-03T09:51:47.504Z",
    Date_Start: "2021-06-30T21:44:40.048Z",
    Date_Occurred: "2021-02-18T15:58:00.683Z",
    ContactName: "Columbus Mayert",
    CreatedDate: "2020-01-22T09:46:38.164Z",
    FeeType: "MT",
    AmountIssued: 84474,
    AmountOutstanding: 1447,
    CostCode: 97257,
    AmountPaid: 51165,
    ReceiptNumber: 55738,
    ReceiptDate: "2021-07-17T07:26:04.540Z",
    ReceiptedBy: "Representative",
    Method: "International",
    InvoiceNumber: 99187,
    DebtorNumber: 90189,
    SecurePay_Reference: "VEF",
    Contact2: "Edison_McKenzie98@example.net",
    LevelOfEnforcement: "297.57",
    Number: 26269,
    DateOffence: "2021-02-13T16:32:44.125Z",
    RegisterType: "Associate",
    VicSmart: "VicSmart 72",
    ePlanningCategory: "ePlanningCategory 72",
    ePlanningLodgedBy: "ePlanningLodgedBy 72",
    Comments: "Comments 72",
    ApplicationCategory_Name: "Shoes",
    ApplicationCategory_Description: "Jewelery",
    Code: "33816",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "You can't navigate the application without overriding the primary SMTP protocol!",
    PublishDescription:
      "I'll compress the redundant PNG capacitor, that should monitor the SSL driver!",
    Title: "Bangladesh Cotton Investment Account",
    ConditionCode: "51330",
    Condition: "Group",
    Name: "Tyrique Kuhlman",
    Multiplier: "Multiplier 72",
    Value: 16726,
    MinimumValue: 23536,
    TargetDays: "TargetDays 72",
    Category_1: "Games",
    Category_2: "Industrial",
    ObservationSummary: "ObservationSummary 72",
    ComplianceInstruction: "ComplianceInstruction 72",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-11T13:50:14.517Z",
  },
  {
    ID: 73,
    ApplicationNumber: 42546,
    PermitNumber: 86646,
    PermitType: "deposit",
    Date_PermitIssued: "2021-04-16T14:44:29.491Z",
    RefNo: 96817,
    AssessmentNo: 13400,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Miss Elton Prohaska",
    Builder: "Madalyn Douglas",
    Category: "Handcrafted Plastic Bacon",
    BCA: "Fresh",
    UseOfBuilding: false,
    ReasonForPermit: "Bolivar Fuerte",
    SiteAddress: "terry.org",
    Locality: "TN",
    Owner: "Doris Gutkowski",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-01-21T03:15:14.403Z",
    Date_AllInspComplete: "2021-10-17T03:09:35.557Z",
    Date_DueToCommence: "2021-05-01T19:04:04.356Z",
    Date_Commenced: "2021-01-28T18:08:40.859Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "July",
    FileNo: 8568,
    ValuationNo: 22802,
    LegalDescription: "navigate",
    BuildingOfficer: "National",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "71976941",
    StatDays: 41042,
    Date_Issued: "2021-10-20T09:25:14.878Z",
    Date_ReminderSent: "2021-09-20T00:20:39.316Z",
    PayingFee: 81562,
    CarryingOutWork: true,
    WorkProposed: "executive Oklahoma",
    Address: "Apt. 836",
    BuildingAppNo: 65751,
    BuildingPermitNo: 97362,
    Date_BuildingIssued: "September",
    Date_Commencing: "December",
    BondAmount: 41236,
    BondReceived: "65880",
    BondReturned: 38040,
    Date_FirstInspection: "2021-12-21T04:48:27.852Z",
    Date_LastInspection: "2021-09-26T01:02:38.497Z",
    TargetDate: "2021-07-08T16:58:44.993Z",
    CILFee: 6164,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-04-30T16:46:20.401Z",
    InspectionType: "Sleek",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "Bangladesh Movies Concrete",
    FacilityRegion: "Nebraska",
    Description: "Lead",
    DateTarget: "2021-09-02T20:20:49.312Z",
    Source: "Research",
    Location: "NM",
    RequestedBy: "82425848",
    ContactDetails: "094.088.1062",
    DueStatus: true,
    DateRecorded: "2021-07-07T23:59:32.194Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Assimilated client-driven flexibility",
    ActionOfficer: "Tools",
    Coordinator: "Suite 210",
    DateWork: "2021-06-14T18:14:13.468Z",
    FileNumber: 96198,
    EnquiryRefNo: 75701,
    Event_ReferenceNoAdditional: 53149,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-09-21T13:38:53.354Z",
    ApplicantName: "Auto Loan Account",
    Type: "LLC",
    Risk: "Intelligent Granite Shoes end-to-end Congo",
    DateDue: "2021-04-27T10:38:56.007Z",
    Register_Number: 941,
    Contact: "Apt. 902",
    ReferenceNumber: 27787,
    Date_Complete: "2021-08-03T11:25:34.597Z",
    Date_Construction: "2021-01-04T09:37:55.350Z",
    BarrierStandard: "BarrierStandard 73",
    BarrierType: "BarrierType 73",
    Date_CertificateOfCompliance: "2021-05-02T17:05:44.738Z",
    Pool_RegisteredDate: "2021-11-20T13:07:46.170Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-11-16T04:06:08.830Z",
    Date_WorkIssued: "2021-08-27T14:39:06.061Z",
    Work_CarriedOut: "Manager",
    AmountOS: 85996,
    Flag_RenewalNoticeSent: false,
    Outcome: 19981,
    Regulation: "Motorway enterprise Auto Loan Account",
    Date_Due: "2021-05-03T12:12:53.568Z",
    Date_NextInspection: "2021-08-14T13:17:45.718Z",
    Date_Start: "2021-04-24T12:02:03.286Z",
    Date_Occurred: "2021-07-05T14:07:45.343Z",
    ContactName: "Nikolas Treutel",
    CreatedDate: "2020-02-29T10:22:31.961Z",
    FeeType: "﷼",
    AmountIssued: 49170,
    AmountOutstanding: 4038,
    CostCode: 20166,
    AmountPaid: 51297,
    ReceiptNumber: 59528,
    ReceiptDate: "2021-06-18T02:56:33.558Z",
    ReceiptedBy: "Agent",
    Method: "International",
    InvoiceNumber: 25697,
    DebtorNumber: 17371,
    SecurePay_Reference: "CUP CUC",
    Contact2: "Fletcher_Casper@example.org",
    LevelOfEnforcement: "766.55",
    Number: 59375,
    DateOffence: "2021-06-28T17:55:00.778Z",
    RegisterType: "Designer",
    VicSmart: "VicSmart 73",
    ePlanningCategory: "ePlanningCategory 73",
    ePlanningLodgedBy: "ePlanningLodgedBy 73",
    Comments: "Comments 73",
    ApplicationCategory_Name: "Beauty",
    ApplicationCategory_Description: "Grocery",
    Code: "91461-4228",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "I'll bypass the multi-byte GB firewall, that should bus the HTTP firewall!",
    PublishDescription:
      "I'll input the primary XML capacitor, that should firewall the XML hard drive!",
    Title: "Borders Home Loan Account Program",
    ConditionCode: "83273",
    Condition: "Inc",
    Name: "Filiberto Weimann",
    Multiplier: "Multiplier 73",
    Value: 51642,
    MinimumValue: 174,
    TargetDays: "TargetDays 73",
    Category_1: "Outdoors",
    Category_2: "Health",
    ObservationSummary: "ObservationSummary 73",
    ComplianceInstruction: "ComplianceInstruction 73",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-07T16:24:05.650Z",
  },
  {
    ID: 74,
    ApplicationNumber: 42786,
    PermitNumber: 87228,
    PermitType: "invoice",
    Date_PermitIssued: "2021-03-21T09:12:35.962Z",
    RefNo: 61808,
    AssessmentNo: 84728,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Korey Kemmer",
    Builder: "Torey Mohr",
    Category: "Fantastic Plastic Soap",
    BCA: "Games",
    UseOfBuilding: false,
    ReasonForPermit: "Associate SQL Money Market Account",
    SiteAddress: "dedrick.biz",
    Locality: "MS",
    Owner: "Mrs. Gwen Gerhold",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-06-15T04:26:02.882Z",
    Date_AllInspComplete: "2021-01-20T21:28:52.497Z",
    Date_DueToCommence: "2021-02-15T08:21:43.417Z",
    Date_Commenced: "2021-09-09T17:00:50.131Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "May",
    FileNo: 23218,
    ValuationNo: 88763,
    LegalDescription: "Plastic",
    BuildingOfficer: "Product",
    Surveyor: "Money Market Account",
    SurveyorNumber: "10216259",
    StatDays: 92423,
    Date_Issued: "2021-11-11T07:08:19.686Z",
    Date_ReminderSent: "2021-01-11T17:08:20.439Z",
    PayingFee: 5802,
    CarryingOutWork: true,
    WorkProposed: "Officer Concrete Games",
    Address: "Suite 884",
    BuildingAppNo: 71409,
    BuildingPermitNo: 89634,
    Date_BuildingIssued: "October",
    Date_Commencing: "August",
    BondAmount: 33282,
    BondReceived: "98944",
    BondReturned: 75153,
    Date_FirstInspection: "2021-04-27T01:41:42.010Z",
    Date_LastInspection: "2021-03-24T04:09:15.984Z",
    TargetDate: "2021-07-04T14:46:46.254Z",
    CILFee: 54489,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-02-02T19:53:02.844Z",
    InspectionType: "iterate",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "Dynamic New York Berkshire",
    FacilityRegion: "Iowa",
    Description: "Dynamic",
    DateTarget: "2021-07-08T22:55:50.720Z",
    Source: "Accountability",
    Location: "ND",
    RequestedBy: "39469274",
    ContactDetails: "1-798-160-4988",
    DueStatus: true,
    DateRecorded: "2021-05-18T19:35:55.400Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Re-contextualized optimizing encryption",
    ActionOfficer: "Home",
    Coordinator: "Suite 698",
    DateWork: "2021-02-24T05:20:28.882Z",
    FileNumber: 96621,
    EnquiryRefNo: 70278,
    Event_ReferenceNoAdditional: 70470,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-04-24T00:53:55.797Z",
    ApplicantName: "Checking Account",
    Type: "LLC",
    Risk: "calculating Home Loan Account Kansas",
    DateDue: "2021-11-01T04:24:28.330Z",
    Register_Number: 92521,
    Contact: "Suite 371",
    ReferenceNumber: 2915,
    Date_Complete: "2021-05-06T09:22:52.057Z",
    Date_Construction: "2021-03-09T23:17:41.315Z",
    BarrierStandard: "BarrierStandard 74",
    BarrierType: "BarrierType 74",
    Date_CertificateOfCompliance: "2021-09-10T10:23:00.796Z",
    Pool_RegisteredDate: "2021-08-03T03:34:04.826Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-18T00:33:11.829Z",
    Date_WorkIssued: "2021-08-27T09:27:19.936Z",
    Work_CarriedOut: "Officer",
    AmountOS: 87722,
    Flag_RenewalNoticeSent: false,
    Outcome: 90328,
    Regulation: "scale Configurable Berkshire",
    Date_Due: "2021-12-03T18:17:26.434Z",
    Date_NextInspection: "2021-11-24T16:52:45.689Z",
    Date_Start: "2021-11-13T21:33:42.567Z",
    Date_Occurred: "2021-01-05T10:15:08.033Z",
    ContactName: "Jaqueline Weimann",
    CreatedDate: "2020-04-15T18:34:56.799Z",
    FeeType: "S",
    AmountIssued: 34908,
    AmountOutstanding: 92507,
    CostCode: 81984,
    AmountPaid: 98961,
    ReceiptNumber: 57267,
    ReceiptDate: "2021-08-20T06:20:06.791Z",
    ReceiptedBy: "Developer",
    Method: "Legacy",
    InvoiceNumber: 89683,
    DebtorNumber: 75353,
    SecurePay_Reference: "PEN",
    Contact2: "Cade.Smith2@example.com",
    LevelOfEnforcement: "546.83",
    Number: 62678,
    DateOffence: "2021-07-12T17:31:55.357Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 74",
    ePlanningCategory: "ePlanningCategory 74",
    ePlanningLodgedBy: "ePlanningLodgedBy 74",
    Comments: "Comments 74",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Computers",
    Code: "92463",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "overriding the microchip won't do anything, we need to back up the multi-byte SDD program!",
    PublishDescription:
      "The SMS panel is down, connect the solid state matrix so we can input the XML circuit!",
    Title: "supply-chains synthesizing",
    ConditionCode: "15316-2609",
    Condition: "and Sons",
    Name: "Eliane Kris Jr.",
    Multiplier: "Multiplier 74",
    Value: 7209,
    MinimumValue: 79104,
    TargetDays: "TargetDays 74",
    Category_1: "Clothing",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 74",
    ComplianceInstruction: "ComplianceInstruction 74",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-09-17T10:30:46.429Z",
  },
  {
    ID: 75,
    ApplicationNumber: 44762,
    PermitNumber: 97790,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-07-02T16:28:11.129Z",
    RefNo: 29021,
    AssessmentNo: 75270,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Eda Greenholt",
    Builder: "Jamaal Bogan",
    Category: "Gorgeous Plastic Soap",
    BCA: "Seychelles Rupee",
    UseOfBuilding: true,
    ReasonForPermit: "Checking Account Pants Customer",
    SiteAddress: "lukas.biz",
    Locality: "IN",
    Owner: "Monique Balistreri",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-05-14T23:04:12.845Z",
    Date_AllInspComplete: "2021-04-29T06:00:01.491Z",
    Date_DueToCommence: "2021-02-23T11:55:32.213Z",
    Date_Commenced: "2021-05-18T05:22:04.815Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "February",
    FileNo: 58136,
    ValuationNo: 23944,
    LegalDescription: "Assurance Dynamic",
    BuildingOfficer: "Global",
    Surveyor: "Checking Account",
    SurveyorNumber: "01038305",
    StatDays: 39671,
    Date_Issued: "2021-09-01T17:27:06.415Z",
    Date_ReminderSent: "2021-01-10T13:54:01.734Z",
    PayingFee: 72728,
    CarryingOutWork: true,
    WorkProposed: "transform",
    Address: "Apt. 807",
    BuildingAppNo: 66128,
    BuildingPermitNo: 75460,
    Date_BuildingIssued: "June",
    Date_Commencing: "April",
    BondAmount: 56658,
    BondReceived: "39018-3957",
    BondReturned: 32390,
    Date_FirstInspection: "2021-03-09T11:45:04.728Z",
    Date_LastInspection: "2021-09-05T14:25:29.949Z",
    TargetDate: "2021-04-20T19:19:03.716Z",
    CILFee: 35261,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-06-27T14:39:21.362Z",
    InspectionType: "Lari",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "Tactics Team-oriented Generic",
    FacilityRegion: "Maryland",
    Description: "Corporate",
    DateTarget: "2021-05-25T20:55:33.240Z",
    Source: "Brand",
    Location: "ID",
    RequestedBy: "00257872",
    ContactDetails: "(251) 930-4000 x67392",
    DueStatus: true,
    DateRecorded: "2021-03-08T09:39:00.937Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Re-engineered demand-driven circuit",
    ActionOfficer: "Garden",
    Coordinator: "Suite 878",
    DateWork: "2021-09-18T06:06:07.617Z",
    FileNumber: 4175,
    EnquiryRefNo: 63129,
    Event_ReferenceNoAdditional: 66234,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-12-27T16:03:28.975Z",
    ApplicantName: "Investment Account",
    Type: "and Sons",
    Risk: "orange Infrastructure Ergonomic",
    DateDue: "2021-06-01T03:25:48.591Z",
    Register_Number: 75647,
    Contact: "Apt. 111",
    ReferenceNumber: 23716,
    Date_Complete: "2021-10-03T02:51:44.299Z",
    Date_Construction: "2021-07-19T17:06:45.056Z",
    BarrierStandard: "BarrierStandard 75",
    BarrierType: "BarrierType 75",
    Date_CertificateOfCompliance: "2021-12-27T04:10:49.294Z",
    Pool_RegisteredDate: "2021-06-17T10:47:23.835Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-09T19:34:23.917Z",
    Date_WorkIssued: "2021-08-03T06:28:57.413Z",
    Work_CarriedOut: "Director",
    AmountOS: 2023,
    Flag_RenewalNoticeSent: true,
    Outcome: 46039,
    Regulation: "Jewelery",
    Date_Due: "2021-11-03T17:07:30.018Z",
    Date_NextInspection: "2021-08-23T04:55:12.044Z",
    Date_Start: "2021-03-01T01:03:23.575Z",
    Date_Occurred: "2021-11-09T22:50:00.343Z",
    ContactName: "Gia Murazik",
    CreatedDate: "2020-04-19T09:52:19.560Z",
    FeeType: "ден",
    AmountIssued: 18733,
    AmountOutstanding: 31035,
    CostCode: 61869,
    AmountPaid: 22815,
    ReceiptNumber: 18936,
    ReceiptDate: "2021-06-27T11:01:44.532Z",
    ReceiptedBy: "Architect",
    Method: "Dynamic",
    InvoiceNumber: 3237,
    DebtorNumber: 20054,
    SecurePay_Reference: "PGK",
    Contact2: "Wanda64@example.com",
    LevelOfEnforcement: "124.70",
    Number: 4853,
    DateOffence: "2021-08-26T02:20:11.869Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 75",
    ePlanningCategory: "ePlanningCategory 75",
    ePlanningLodgedBy: "ePlanningLodgedBy 75",
    Comments: "Comments 75",
    ApplicationCategory_Name: "Health",
    ApplicationCategory_Description: "Clothing",
    Code: "86025",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Try to override the JSON bandwidth, maybe it will quantify the multi-byte monitor!",
    PublishDescription:
      "You can't generate the bandwidth without navigating the wireless ADP system!",
    Title: "grow firewall",
    ConditionCode: "87056",
    Condition: "and Sons",
    Name: "Bradford Hahn",
    Multiplier: "Multiplier 75",
    Value: 64642,
    MinimumValue: 92603,
    TargetDays: "TargetDays 75",
    Category_1: "Home",
    Category_2: "Jewelery",
    ObservationSummary: "ObservationSummary 75",
    ComplianceInstruction: "ComplianceInstruction 75",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-20T23:24:56.512Z",
  },
  {
    ID: 76,
    ApplicationNumber: 39642,
    PermitNumber: 52126,
    PermitType: "invoice",
    Date_PermitIssued: "2021-08-03T00:03:51.570Z",
    RefNo: 35647,
    AssessmentNo: 29109,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Wilford Stoltenberg",
    Builder: "Dr. Grover Cormier",
    Category: "Small Wooden Tuna",
    BCA: "turquoise",
    UseOfBuilding: true,
    ReasonForPermit: "deposit Wooden",
    SiteAddress: "mariane.biz",
    Locality: "TX",
    Owner: "Mrs. Nathaniel Kling",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-01-11T12:44:20.760Z",
    Date_AllInspComplete: "2021-09-30T15:19:47.976Z",
    Date_DueToCommence: "2021-02-02T20:03:31.271Z",
    Date_Commenced: "2021-07-07T15:28:22.506Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "September",
    FileNo: 8978,
    ValuationNo: 60832,
    LegalDescription: "Dynamic",
    BuildingOfficer: "Investor",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "38422010",
    StatDays: 45533,
    Date_Issued: "2021-01-27T15:26:52.382Z",
    Date_ReminderSent: "2021-03-04T15:44:27.409Z",
    PayingFee: 11981,
    CarryingOutWork: true,
    WorkProposed: "Internal",
    Address: "Suite 589",
    BuildingAppNo: 52911,
    BuildingPermitNo: 23529,
    Date_BuildingIssued: "October",
    Date_Commencing: "July",
    BondAmount: 19952,
    BondReceived: "77209",
    BondReturned: 93647,
    Date_FirstInspection: "2021-10-28T10:41:25.269Z",
    Date_LastInspection: "2021-11-27T16:43:07.761Z",
    TargetDate: "2021-09-25T01:23:33.725Z",
    CILFee: 79388,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-10-13T00:22:15.123Z",
    InspectionType: "Planner",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "input Avon California",
    FacilityRegion: "Oregon",
    Description: "Direct",
    DateTarget: "2021-07-11T02:35:34.143Z",
    Source: "Communications",
    Location: "TN",
    RequestedBy: "74116308",
    ContactDetails: "887.299.1248 x86427",
    DueStatus: true,
    DateRecorded: "2021-03-02T11:35:29.724Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Organized needs-based forecast",
    ActionOfficer: "Home",
    Coordinator: "Apt. 430",
    DateWork: "2021-01-31T09:59:10.205Z",
    FileNumber: 21721,
    EnquiryRefNo: 36919,
    Event_ReferenceNoAdditional: 53645,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-12-09T04:07:47.309Z",
    ApplicantName: "Checking Account",
    Type: "and Sons",
    Risk: "override",
    DateDue: "2021-04-13T22:24:07.907Z",
    Register_Number: 90013,
    Contact: "Suite 153",
    ReferenceNumber: 6416,
    Date_Complete: "2021-07-02T03:24:09.653Z",
    Date_Construction: "2021-11-04T09:18:06.086Z",
    BarrierStandard: "BarrierStandard 76",
    BarrierType: "BarrierType 76",
    Date_CertificateOfCompliance: "2021-01-24T20:43:03.879Z",
    Pool_RegisteredDate: "2021-04-11T16:44:11.590Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-12T16:35:16.775Z",
    Date_WorkIssued: "2021-11-23T11:51:54.580Z",
    Work_CarriedOut: "Engineer",
    AmountOS: 26977,
    Flag_RenewalNoticeSent: true,
    Outcome: 20757,
    Regulation: "budgetary management Fantastic withdrawal",
    Date_Due: "2021-05-04T00:23:05.081Z",
    Date_NextInspection: "2021-11-20T17:03:53.487Z",
    Date_Start: "2021-05-25T11:40:42.972Z",
    Date_Occurred: "2021-12-08T09:13:42.804Z",
    ContactName: "Marvin Thompson",
    CreatedDate: "2020-08-30T20:33:12.853Z",
    FeeType: "Ft",
    AmountIssued: 21032,
    AmountOutstanding: 71597,
    CostCode: 4104,
    AmountPaid: 68967,
    ReceiptNumber: 51987,
    ReceiptDate: "2021-04-28T20:10:16.945Z",
    ReceiptedBy: "Manager",
    Method: "Customer",
    InvoiceNumber: 98867,
    DebtorNumber: 60263,
    SecurePay_Reference: "XAU",
    Contact2: "Joey.Sanford40@example.org",
    LevelOfEnforcement: "364.31",
    Number: 37732,
    DateOffence: "2021-02-06T15:18:53.099Z",
    RegisterType: "Designer",
    VicSmart: "VicSmart 76",
    ePlanningCategory: "ePlanningCategory 76",
    ePlanningLodgedBy: "ePlanningLodgedBy 76",
    Comments: "Comments 76",
    ApplicationCategory_Name: "Industrial",
    ApplicationCategory_Description: "Music",
    Code: "72659-4557",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Use the primary ADP array, then you can synthesize the solid state card!",
    PublishDescription:
      "I'll generate the primary JSON port, that should system the RSS panel!",
    Title: "Wyoming",
    ConditionCode: "90482",
    Condition: "and Sons",
    Name: "Elmer Buckridge",
    Multiplier: "Multiplier 76",
    Value: 87606,
    MinimumValue: 12153,
    TargetDays: "TargetDays 76",
    Category_1: "Computers",
    Category_2: "Home",
    ObservationSummary: "ObservationSummary 76",
    ComplianceInstruction: "ComplianceInstruction 76",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-23T14:03:07.908Z",
  },
  {
    ID: 77,
    ApplicationNumber: 71465,
    PermitNumber: 90156,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-06-20T14:13:28.860Z",
    RefNo: 96282,
    AssessmentNo: 93923,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Mazie Hyatt",
    Builder: "Chad Strosin",
    Category: "Intelligent Fresh Ball",
    BCA: "Pants",
    UseOfBuilding: false,
    ReasonForPermit: "system",
    SiteAddress: "corine.net",
    Locality: "NJ",
    Owner: "Jordane Berge MD",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-03-01T12:56:30.558Z",
    Date_AllInspComplete: "2021-05-31T18:20:09.513Z",
    Date_DueToCommence: "2021-03-07T10:17:43.880Z",
    Date_Commenced: "2021-08-25T06:39:00.044Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "November",
    FileNo: 46763,
    ValuationNo: 71814,
    LegalDescription: "grey olive SCSI",
    BuildingOfficer: "Chief",
    Surveyor: "Money Market Account",
    SurveyorNumber: "82249951",
    StatDays: 39231,
    Date_Issued: "2021-06-23T17:10:44.597Z",
    Date_ReminderSent: "2021-06-08T16:11:16.743Z",
    PayingFee: 82685,
    CarryingOutWork: false,
    WorkProposed: "empower",
    Address: "Apt. 425",
    BuildingAppNo: 29980,
    BuildingPermitNo: 76984,
    Date_BuildingIssued: "March",
    Date_Commencing: "August",
    BondAmount: 66161,
    BondReceived: "26378-2322",
    BondReturned: 35482,
    Date_FirstInspection: "2021-01-28T00:48:15.619Z",
    Date_LastInspection: "2021-11-26T14:51:16.250Z",
    TargetDate: "2021-03-02T03:59:03.868Z",
    CILFee: 64286,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-09-30T18:07:55.307Z",
    InspectionType: "solid state",
    Officer: "Investment Account",
    Compliance: true,
    Reason: "mobile bluetooth Personal Loan Account",
    FacilityRegion: "Oregon",
    Description: "Legacy",
    DateTarget: "2021-11-21T19:14:02.573Z",
    Source: "Paradigm",
    Location: "RI",
    RequestedBy: "87022386",
    ContactDetails: "556.874.9695 x8518",
    DueStatus: false,
    DateRecorded: "2021-11-10T18:37:47.687Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Right-sized next generation software",
    ActionOfficer: "Computers",
    Coordinator: "Apt. 366",
    DateWork: "2021-02-03T09:29:03.757Z",
    FileNumber: 37919,
    EnquiryRefNo: 8940,
    Event_ReferenceNoAdditional: 72374,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-06-05T07:57:25.449Z",
    ApplicantName: "Personal Loan Account",
    Type: "LLC",
    Risk: "magenta",
    DateDue: "2021-11-09T16:12:28.714Z",
    Register_Number: 27982,
    Contact: "Apt. 105",
    ReferenceNumber: 55738,
    Date_Complete: "2021-06-25T18:24:08.305Z",
    Date_Construction: "2021-05-28T21:38:54.862Z",
    BarrierStandard: "BarrierStandard 77",
    BarrierType: "BarrierType 77",
    Date_CertificateOfCompliance: "2021-07-05T10:54:21.511Z",
    Pool_RegisteredDate: "2021-08-06T07:55:29.718Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-29T18:46:49.282Z",
    Date_WorkIssued: "2021-01-04T10:51:47.861Z",
    Work_CarriedOut: "Planner",
    AmountOS: 75758,
    Flag_RenewalNoticeSent: true,
    Outcome: 5010,
    Regulation: "ubiquitous Savings Account",
    Date_Due: "2021-03-03T14:27:47.210Z",
    Date_NextInspection: "2021-03-20T04:39:02.738Z",
    Date_Start: "2021-02-05T14:02:47.367Z",
    Date_Occurred: "2021-10-09T06:25:19.422Z",
    ContactName: "Jailyn Jast IV",
    CreatedDate: "2020-01-20T04:21:38.535Z",
    FeeType: "$",
    AmountIssued: 79984,
    AmountOutstanding: 3657,
    CostCode: 66039,
    AmountPaid: 67367,
    ReceiptNumber: 48475,
    ReceiptDate: "2021-06-14T09:21:37.044Z",
    ReceiptedBy: "Agent",
    Method: "Human",
    InvoiceNumber: 59736,
    DebtorNumber: 4357,
    SecurePay_Reference: "SGD",
    Contact2: "Alfreda.Keebler79@example.com",
    LevelOfEnforcement: "752.39",
    Number: 20124,
    DateOffence: "2021-10-23T09:51:15.637Z",
    RegisterType: "Administrator",
    VicSmart: "VicSmart 77",
    ePlanningCategory: "ePlanningCategory 77",
    ePlanningLodgedBy: "ePlanningLodgedBy 77",
    Comments: "Comments 77",
    ApplicationCategory_Name: "Beauty",
    ApplicationCategory_Description: "Electronics",
    Code: "02463",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Try to index the SMTP port, maybe it will quantify the back-end bandwidth!",
    PublishDescription:
      "The GB panel is down, compress the online card so we can program the GB microchip!",
    Title: "lime Right-sized parsing",
    ConditionCode: "09026",
    Condition: "LLC",
    Name: "Marisa Orn IV",
    Multiplier: "Multiplier 77",
    Value: 14038,
    MinimumValue: 57915,
    TargetDays: "TargetDays 77",
    Category_1: "Baby",
    Category_2: "Music",
    ObservationSummary: "ObservationSummary 77",
    ComplianceInstruction: "ComplianceInstruction 77",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-06-29T08:47:13.041Z",
  },
  {
    ID: 78,
    ApplicationNumber: 2849,
    PermitNumber: 15874,
    PermitType: "payment",
    Date_PermitIssued: "2021-06-29T00:37:12.610Z",
    RefNo: 17541,
    AssessmentNo: 65463,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Sanford Luettgen",
    Builder: "Madge Hahn",
    Category: "Intelligent Frozen Fish",
    BCA: "Optional",
    UseOfBuilding: false,
    ReasonForPermit: "lavender",
    SiteAddress: "isabella.biz",
    Locality: "MD",
    Owner: "Kelley Grady",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-11-12T21:07:41.383Z",
    Date_AllInspComplete: "2021-08-26T17:44:57.843Z",
    Date_DueToCommence: "2021-04-17T20:24:00.737Z",
    Date_Commenced: "2021-09-05T23:30:46.796Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "August",
    FileNo: 69888,
    ValuationNo: 59075,
    LegalDescription: "Cambridgeshire pink compress",
    BuildingOfficer: "Customer",
    Surveyor: "Investment Account",
    SurveyorNumber: "09392419",
    StatDays: 47476,
    Date_Issued: "2021-01-26T18:28:01.426Z",
    Date_ReminderSent: "2021-03-25T17:23:57.144Z",
    PayingFee: 2485,
    CarryingOutWork: false,
    WorkProposed: "Music",
    Address: "Apt. 837",
    BuildingAppNo: 4846,
    BuildingPermitNo: 19830,
    Date_BuildingIssued: "August",
    Date_Commencing: "March",
    BondAmount: 7472,
    BondReceived: "38795-1330",
    BondReturned: 66308,
    Date_FirstInspection: "2021-12-13T04:31:28.500Z",
    Date_LastInspection: "2021-08-08T01:46:41.038Z",
    TargetDate: "2021-03-22T09:56:12.537Z",
    CILFee: 19647,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-05-09T18:53:07.412Z",
    InspectionType: "Corporate",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "SCSI European Unit of Account 17(E.U.A.-17)",
    FacilityRegion: "Kansas",
    Description: "Product",
    DateTarget: "2021-07-27T18:51:01.019Z",
    Source: "Markets",
    Location: "NE",
    RequestedBy: "37923012",
    ContactDetails: "(164) 170-3805 x8054",
    DueStatus: false,
    DateRecorded: "2021-10-10T00:58:27.145Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Realigned uniform access",
    ActionOfficer: "Electronics",
    Coordinator: "Suite 833",
    DateWork: "2021-09-28T16:08:33.047Z",
    FileNumber: 68692,
    EnquiryRefNo: 55549,
    Event_ReferenceNoAdditional: 2785,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-03-07T00:32:05.722Z",
    ApplicantName: "Personal Loan Account",
    Type: "LLC",
    Risk: "compress Facilitator wireless",
    DateDue: "2021-12-12T01:16:39.585Z",
    Register_Number: 77797,
    Contact: "Suite 136",
    ReferenceNumber: 8103,
    Date_Complete: "2021-09-07T17:57:05.213Z",
    Date_Construction: "2021-03-16T23:49:46.812Z",
    BarrierStandard: "BarrierStandard 78",
    BarrierType: "BarrierType 78",
    Date_CertificateOfCompliance: "2021-07-17T19:33:40.922Z",
    Pool_RegisteredDate: "2021-02-15T09:05:31.777Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-17T16:18:54.058Z",
    Date_WorkIssued: "2021-10-30T03:04:12.037Z",
    Work_CarriedOut: "Manager",
    AmountOS: 67106,
    Flag_RenewalNoticeSent: false,
    Outcome: 61109,
    Regulation: "Technician",
    Date_Due: "2021-12-02T19:43:59.463Z",
    Date_NextInspection: "2021-03-10T08:38:17.301Z",
    Date_Start: "2021-09-27T20:38:28.211Z",
    Date_Occurred: "2021-12-17T13:44:48.296Z",
    ContactName: "Fletcher Conroy",
    CreatedDate: "2020-04-13T19:36:09.525Z",
    FeeType: "S",
    AmountIssued: 86059,
    AmountOutstanding: 54307,
    CostCode: 19447,
    AmountPaid: 11663,
    ReceiptNumber: 50505,
    ReceiptDate: "2021-07-14T03:59:50.242Z",
    ReceiptedBy: "Liaison",
    Method: "Dynamic",
    InvoiceNumber: 77595,
    DebtorNumber: 83169,
    SecurePay_Reference: "MUR",
    Contact2: "Flossie46@example.org",
    LevelOfEnforcement: "260.97",
    Number: 83031,
    DateOffence: "2021-05-04T11:56:34.824Z",
    RegisterType: "Assistant",
    VicSmart: "VicSmart 78",
    ePlanningCategory: "ePlanningCategory 78",
    ePlanningLodgedBy: "ePlanningLodgedBy 78",
    Comments: "Comments 78",
    ApplicationCategory_Name: "Clothing",
    ApplicationCategory_Description: "Books",
    Code: "11516",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "If we override the microchip, we can get to the ADP feed through the 1080p IB monitor!",
    PublishDescription:
      "I'll transmit the wireless TCP capacitor, that should feed the JBOD protocol!",
    Title: "deposit",
    ConditionCode: "95257",
    Condition: "Inc",
    Name: "Oran Mills",
    Multiplier: "Multiplier 78",
    Value: 24319,
    MinimumValue: 61977,
    TargetDays: "TargetDays 78",
    Category_1: "Tools",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 78",
    ComplianceInstruction: "ComplianceInstruction 78",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-01T13:36:18.149Z",
  },
  {
    ID: 79,
    ApplicationNumber: 86673,
    PermitNumber: 45799,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-11-03T18:23:57.672Z",
    RefNo: 64204,
    AssessmentNo: 47372,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Ruth Crist",
    Builder: "Emiliano Daugherty",
    Category: "Rustic Plastic Shirt",
    BCA: "program",
    UseOfBuilding: false,
    ReasonForPermit: "Response",
    SiteAddress: "marietta.name",
    Locality: "CT",
    Owner: "Dandre Crona DVM",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-10-14T14:37:09.731Z",
    Date_AllInspComplete: "2021-01-30T04:20:24.414Z",
    Date_DueToCommence: "2021-03-18T12:57:28.975Z",
    Date_Commenced: "2021-03-01T20:25:34.716Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "January",
    FileNo: 90474,
    ValuationNo: 17497,
    LegalDescription: "Garden Supervisor",
    BuildingOfficer: "National",
    Surveyor: "Money Market Account",
    SurveyorNumber: "74307316",
    StatDays: 45524,
    Date_Issued: "2021-01-02T23:52:16.987Z",
    Date_ReminderSent: "2021-02-19T11:02:04.194Z",
    PayingFee: 71795,
    CarryingOutWork: false,
    WorkProposed: "Movies paradigm",
    Address: "Suite 122",
    BuildingAppNo: 34142,
    BuildingPermitNo: 38734,
    Date_BuildingIssued: "November",
    Date_Commencing: "April",
    BondAmount: 97779,
    BondReceived: "82481-3886",
    BondReturned: 46755,
    Date_FirstInspection: "2021-06-03T13:27:21.829Z",
    Date_LastInspection: "2021-05-01T20:57:50.809Z",
    TargetDate: "2021-09-14T15:31:14.423Z",
    CILFee: 37214,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-09-01T19:27:24.557Z",
    InspectionType: "Unbranded Metal Bacon",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "Paradigm infrastructures",
    FacilityRegion: "Michigan",
    Description: "Corporate",
    DateTarget: "2021-08-10T19:16:28.152Z",
    Source: "Optimization",
    Location: "NC",
    RequestedBy: "90556692",
    ContactDetails: "683.521.3362",
    DueStatus: false,
    DateRecorded: "2021-02-23T19:35:21.394Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Cross-platform background hardware",
    ActionOfficer: "Books",
    Coordinator: "Suite 656",
    DateWork: "2021-05-13T08:43:56.725Z",
    FileNumber: 98738,
    EnquiryRefNo: 57040,
    Event_ReferenceNoAdditional: 50154,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-10-21T00:30:26.724Z",
    ApplicantName: "Savings Account",
    Type: "and Sons",
    Risk: "microchip",
    DateDue: "2021-03-31T07:13:57.776Z",
    Register_Number: 1088,
    Contact: "Suite 137",
    ReferenceNumber: 46976,
    Date_Complete: "2021-11-26T03:24:41.452Z",
    Date_Construction: "2021-03-06T15:55:10.752Z",
    BarrierStandard: "BarrierStandard 79",
    BarrierType: "BarrierType 79",
    Date_CertificateOfCompliance: "2021-10-17T19:31:54.376Z",
    Pool_RegisteredDate: "2021-12-29T21:33:00.073Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-06T13:42:21.397Z",
    Date_WorkIssued: "2021-02-22T21:22:52.615Z",
    Work_CarriedOut: "Representative",
    AmountOS: 82550,
    Flag_RenewalNoticeSent: false,
    Outcome: 39506,
    Regulation: "teal",
    Date_Due: "2021-07-25T15:15:26.792Z",
    Date_NextInspection: "2021-04-27T20:17:46.117Z",
    Date_Start: "2021-01-11T01:10:28.502Z",
    Date_Occurred: "2021-09-05T21:35:34.201Z",
    ContactName: "Helene Predovic",
    CreatedDate: "2020-09-27T12:58:55.704Z",
    FeeType: "$",
    AmountIssued: 82816,
    AmountOutstanding: 73131,
    CostCode: 93816,
    AmountPaid: 12646,
    ReceiptNumber: 72364,
    ReceiptDate: "2021-07-06T14:27:09.741Z",
    ReceiptedBy: "Assistant",
    Method: "Global",
    InvoiceNumber: 36978,
    DebtorNumber: 85670,
    SecurePay_Reference: "TMT",
    Contact2: "Sammy_Boyle20@example.net",
    LevelOfEnforcement: "665.57",
    Number: 11402,
    DateOffence: "2021-07-12T08:24:50.300Z",
    RegisterType: "Designer",
    VicSmart: "VicSmart 79",
    ePlanningCategory: "ePlanningCategory 79",
    ePlanningLodgedBy: "ePlanningLodgedBy 79",
    Comments: "Comments 79",
    ApplicationCategory_Name: "Automotive",
    ApplicationCategory_Description: "Games",
    Code: "46099",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "If we program the driver, we can get to the JBOD driver through the redundant SMTP microchip!",
    PublishDescription:
      "You can't quantify the pixel without overriding the wireless JSON transmitter!",
    Title: "hacking",
    ConditionCode: "47694",
    Condition: "Group",
    Name: "Baylee Schuppe DVM",
    Multiplier: "Multiplier 79",
    Value: 66332,
    MinimumValue: 4383,
    TargetDays: "TargetDays 79",
    Category_1: "Clothing",
    Category_2: "Tools",
    ObservationSummary: "ObservationSummary 79",
    ComplianceInstruction: "ComplianceInstruction 79",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-13T23:46:02.186Z",
  },
  {
    ID: 80,
    ApplicationNumber: 22693,
    PermitNumber: 37181,
    PermitType: "deposit",
    Date_PermitIssued: "2021-06-19T07:00:38.454Z",
    RefNo: 32251,
    AssessmentNo: 36563,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Major Schaefer",
    Builder: "Braeden Lind Sr.",
    Category: "Small Cotton Computer",
    BCA: "Zimbabwe",
    UseOfBuilding: false,
    ReasonForPermit: "reintermediate Checking Account networks",
    SiteAddress: "gus.name",
    Locality: "AK",
    Owner: "Emmitt Daniel",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-05-06T01:27:49.956Z",
    Date_AllInspComplete: "2021-07-21T20:15:03.715Z",
    Date_DueToCommence: "2021-02-14T01:47:51.115Z",
    Date_Commenced: "2021-10-17T05:52:10.298Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "October",
    FileNo: 31733,
    ValuationNo: 4019,
    LegalDescription: "interface Chicken Fresh",
    BuildingOfficer: "Dynamic",
    Surveyor: "Personal Loan Account",
    SurveyorNumber: "00717699",
    StatDays: 70546,
    Date_Issued: "2021-12-08T17:20:58.146Z",
    Date_ReminderSent: "2021-04-19T09:16:38.637Z",
    PayingFee: 38994,
    CarryingOutWork: false,
    WorkProposed: "Optional",
    Address: "Apt. 893",
    BuildingAppNo: 22599,
    BuildingPermitNo: 73560,
    Date_BuildingIssued: "April",
    Date_Commencing: "June",
    BondAmount: 40848,
    BondReceived: "45248-1226",
    BondReturned: 44551,
    Date_FirstInspection: "2021-10-27T18:37:06.389Z",
    Date_LastInspection: "2021-03-04T21:10:22.483Z",
    TargetDate: "2021-08-25T14:49:35.396Z",
    CILFee: 94961,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-04-04T13:08:58.028Z",
    InspectionType: "implement",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "Supervisor Parks invoice",
    FacilityRegion: "Tennessee",
    Description: "Forward",
    DateTarget: "2021-04-26T16:40:53.188Z",
    Source: "Implementation",
    Location: "OK",
    RequestedBy: "74944828",
    ContactDetails: "226-269-5624 x044",
    DueStatus: true,
    DateRecorded: "2021-08-05T23:48:57.710Z",
    RecordedBy: "Savings Account",
    OrgStructure: "De-engineered real-time parallelism",
    ActionOfficer: "Industrial",
    Coordinator: "Apt. 300",
    DateWork: "2021-05-05T22:37:24.388Z",
    FileNumber: 82583,
    EnquiryRefNo: 78281,
    Event_ReferenceNoAdditional: 93690,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-08-15T10:14:16.198Z",
    ApplicantName: "Money Market Account",
    Type: "and Sons",
    Risk: "Fantastic Cotton Mouse",
    DateDue: "2021-09-29T09:19:02.699Z",
    Register_Number: 53934,
    Contact: "Suite 512",
    ReferenceNumber: 67377,
    Date_Complete: "2021-04-04T15:55:50.723Z",
    Date_Construction: "2021-03-07T11:35:51.641Z",
    BarrierStandard: "BarrierStandard 80",
    BarrierType: "BarrierType 80",
    Date_CertificateOfCompliance: "2021-08-02T06:32:51.054Z",
    Pool_RegisteredDate: "2021-12-27T11:47:19.110Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-29T14:00:34.487Z",
    Date_WorkIssued: "2021-08-20T03:48:45.983Z",
    Work_CarriedOut: "Analyst",
    AmountOS: 29504,
    Flag_RenewalNoticeSent: false,
    Outcome: 46276,
    Regulation: "Square context-sensitive withdrawal",
    Date_Due: "2021-02-24T15:21:37.455Z",
    Date_NextInspection: "2021-02-10T08:41:48.832Z",
    Date_Start: "2021-12-06T13:10:40.451Z",
    Date_Occurred: "2021-10-15T01:38:42.211Z",
    ContactName: "Terrell Gleichner",
    CreatedDate: "2020-04-16T09:07:19.994Z",
    FeeType: "₫",
    AmountIssued: 50531,
    AmountOutstanding: 2788,
    CostCode: 83742,
    AmountPaid: 88672,
    ReceiptNumber: 5856,
    ReceiptDate: "2021-04-05T09:13:14.249Z",
    ReceiptedBy: "Officer",
    Method: "Dynamic",
    InvoiceNumber: 9523,
    DebtorNumber: 11576,
    SecurePay_Reference: "TWD",
    Contact2: "Amalia.Corkery@example.net",
    LevelOfEnforcement: "288.53",
    Number: 75463,
    DateOffence: "2021-02-23T10:41:10.715Z",
    RegisterType: "Developer",
    VicSmart: "VicSmart 80",
    ePlanningCategory: "ePlanningCategory 80",
    ePlanningLodgedBy: "ePlanningLodgedBy 80",
    Comments: "Comments 80",
    ApplicationCategory_Name: "Kids",
    ApplicationCategory_Description: "Games",
    Code: "63886",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "I'll bypass the online XML port, that should bus the TCP sensor!",
    PublishDescription:
      "You can't program the circuit without calculating the primary JSON pixel!",
    Title: "Frozen Points",
    ConditionCode: "95520-0592",
    Condition: "Group",
    Name: "Krystal Nienow Sr.",
    Multiplier: "Multiplier 80",
    Value: 42648,
    MinimumValue: 94942,
    TargetDays: "TargetDays 80",
    Category_1: "Sports",
    Category_2: "Movies",
    ObservationSummary: "ObservationSummary 80",
    ComplianceInstruction: "ComplianceInstruction 80",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-10T05:57:49.884Z",
  },
  {
    ID: 81,
    ApplicationNumber: 52396,
    PermitNumber: 15408,
    PermitType: "payment",
    Date_PermitIssued: "2021-05-30T09:25:08.037Z",
    RefNo: 76819,
    AssessmentNo: 41189,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Mr. Casper Jacobi",
    Builder: "Maximus O'Connell",
    Category: "Practical Granite Bacon",
    BCA: "New Jersey",
    UseOfBuilding: false,
    ReasonForPermit: "Frozen purple withdrawal",
    SiteAddress: "roel.biz",
    Locality: "OR",
    Owner: "Earline Barton",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-08-02T21:37:45.715Z",
    Date_AllInspComplete: "2021-11-10T12:07:41.142Z",
    Date_DueToCommence: "2021-12-04T06:21:03.793Z",
    Date_Commenced: "2021-03-18T04:51:35.560Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "January",
    FileNo: 16778,
    ValuationNo: 20460,
    LegalDescription: "Bedfordshire",
    BuildingOfficer: "Global",
    Surveyor: "Checking Account",
    SurveyorNumber: "87235862",
    StatDays: 38944,
    Date_Issued: "2021-12-31T05:14:32.212Z",
    Date_ReminderSent: "2021-06-19T01:08:48.083Z",
    PayingFee: 8217,
    CarryingOutWork: false,
    WorkProposed: "analyzer intranet Customer",
    Address: "Suite 725",
    BuildingAppNo: 7238,
    BuildingPermitNo: 50714,
    Date_BuildingIssued: "July",
    Date_Commencing: "October",
    BondAmount: 27016,
    BondReceived: "36653",
    BondReturned: 66221,
    Date_FirstInspection: "2021-11-03T05:52:42.437Z",
    Date_LastInspection: "2021-04-12T04:53:54.699Z",
    TargetDate: "2021-07-28T06:25:21.171Z",
    CILFee: 5107,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-11-01T15:14:14.684Z",
    InspectionType: "parse",
    Officer: "Credit Card Account",
    Compliance: false,
    Reason: "connect Practical withdrawal",
    FacilityRegion: "New Hampshire",
    Description: "Legacy",
    DateTarget: "2021-03-07T14:45:31.509Z",
    Source: "Program",
    Location: "ND",
    RequestedBy: "77673979",
    ContactDetails: "929.201.4679 x01069",
    DueStatus: true,
    DateRecorded: "2021-01-20T03:01:14.570Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Adaptive high-level database",
    ActionOfficer: "Music",
    Coordinator: "Apt. 051",
    DateWork: "2021-08-12T17:02:09.658Z",
    FileNumber: 5238,
    EnquiryRefNo: 52210,
    Event_ReferenceNoAdditional: 27300,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-08-23T15:43:57.714Z",
    ApplicantName: "Savings Account",
    Type: "LLC",
    Risk: "Division",
    DateDue: "2021-01-13T09:47:35.787Z",
    Register_Number: 5262,
    Contact: "Suite 167",
    ReferenceNumber: 36891,
    Date_Complete: "2021-11-27T10:18:21.349Z",
    Date_Construction: "2021-04-26T22:50:20.096Z",
    BarrierStandard: "BarrierStandard 81",
    BarrierType: "BarrierType 81",
    Date_CertificateOfCompliance: "2021-01-05T17:38:20.630Z",
    Pool_RegisteredDate: "2021-04-03T08:40:39.099Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-07-06T12:44:42.666Z",
    Date_WorkIssued: "2021-05-02T11:15:35.250Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 88157,
    Flag_RenewalNoticeSent: false,
    Outcome: 33670,
    Regulation: "Dynamic synthesizing",
    Date_Due: "2021-04-25T19:33:14.073Z",
    Date_NextInspection: "2021-07-26T21:30:26.232Z",
    Date_Start: "2021-03-24T20:00:20.175Z",
    Date_Occurred: "2021-08-20T19:54:35.883Z",
    ContactName: "Karen Schumm Jr.",
    CreatedDate: "2020-07-17T10:10:59.246Z",
    FeeType: "¥",
    AmountIssued: 28389,
    AmountOutstanding: 78382,
    CostCode: 28389,
    AmountPaid: 59990,
    ReceiptNumber: 47343,
    ReceiptDate: "2021-03-28T17:35:45.248Z",
    ReceiptedBy: "Manager",
    Method: "Product",
    InvoiceNumber: 97597,
    DebtorNumber: 36333,
    SecurePay_Reference: "GEL",
    Contact2: "Gregg98@example.net",
    LevelOfEnforcement: "73.72",
    Number: 82821,
    DateOffence: "2021-07-17T22:44:48.984Z",
    RegisterType: "Representative",
    VicSmart: "VicSmart 81",
    ePlanningCategory: "ePlanningCategory 81",
    ePlanningLodgedBy: "ePlanningLodgedBy 81",
    Comments: "Comments 81",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Beauty",
    Code: "06351",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "Use the solid state CSS protocol, then you can generate the back-end protocol!",
    PublishDescription:
      "If we transmit the port, we can get to the CSS bus through the 1080p EXE system!",
    Title: "Estate online",
    ConditionCode: "05418",
    Condition: "and Sons",
    Name: "Emelia McGlynn",
    Multiplier: "Multiplier 81",
    Value: 60406,
    MinimumValue: 24458,
    TargetDays: "TargetDays 81",
    Category_1: "Tools",
    Category_2: "Kids",
    ObservationSummary: "ObservationSummary 81",
    ComplianceInstruction: "ComplianceInstruction 81",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-08-29T08:47:46.678Z",
  },
  {
    ID: 82,
    ApplicationNumber: 15476,
    PermitNumber: 82140,
    PermitType: "invoice",
    Date_PermitIssued: "2021-03-25T16:15:18.354Z",
    RefNo: 90006,
    AssessmentNo: 42388,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Celine Murazik",
    Builder: "Fredy Hettinger",
    Category: "Unbranded Fresh Pizza",
    BCA: "Granite",
    UseOfBuilding: true,
    ReasonForPermit: "Health Berkshire executive",
    SiteAddress: "madisyn.com",
    Locality: "TX",
    Owner: "Loma Hettinger",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-11-25T21:51:12.164Z",
    Date_AllInspComplete: "2021-05-06T19:27:47.788Z",
    Date_DueToCommence: "2021-10-30T15:08:01.134Z",
    Date_Commenced: "2021-10-25T17:40:08.503Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "November",
    FileNo: 20474,
    ValuationNo: 89261,
    LegalDescription: "virtual disintermediate Tools",
    BuildingOfficer: "Product",
    Surveyor: "Checking Account",
    SurveyorNumber: "44164982",
    StatDays: 75892,
    Date_Issued: "2021-02-11T02:12:35.921Z",
    Date_ReminderSent: "2021-02-14T03:00:02.141Z",
    PayingFee: 25517,
    CarryingOutWork: false,
    WorkProposed: "Factors",
    Address: "Suite 870",
    BuildingAppNo: 3868,
    BuildingPermitNo: 81853,
    Date_BuildingIssued: "August",
    Date_Commencing: "February",
    BondAmount: 23579,
    BondReceived: "90517",
    BondReturned: 54275,
    Date_FirstInspection: "2021-06-20T00:22:39.430Z",
    Date_LastInspection: "2021-12-02T17:09:09.903Z",
    TargetDate: "2021-05-23T11:34:36.457Z",
    CILFee: 22770,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-05-14T02:48:18.281Z",
    InspectionType: "override",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "1080p Front-line",
    FacilityRegion: "North Dakota",
    Description: "Corporate",
    DateTarget: "2021-10-31T10:58:38.999Z",
    Source: "Division",
    Location: "NM",
    RequestedBy: "30659693",
    ContactDetails: "(666) 630-0905",
    DueStatus: false,
    DateRecorded: "2021-02-12T20:25:32.620Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Distributed optimizing task-force",
    ActionOfficer: "Industrial",
    Coordinator: "Suite 508",
    DateWork: "2021-09-07T13:29:29.689Z",
    FileNumber: 48779,
    EnquiryRefNo: 36601,
    Event_ReferenceNoAdditional: 23459,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-07-01T07:06:27.246Z",
    ApplicantName: "Auto Loan Account",
    Type: "Group",
    Risk: "Fresh",
    DateDue: "2021-04-30T19:44:20.592Z",
    Register_Number: 52637,
    Contact: "Apt. 139",
    ReferenceNumber: 95275,
    Date_Complete: "2021-12-21T22:43:53.480Z",
    Date_Construction: "2021-07-07T14:28:30.293Z",
    BarrierStandard: "BarrierStandard 82",
    BarrierType: "BarrierType 82",
    Date_CertificateOfCompliance: "2021-11-08T07:38:59.095Z",
    Pool_RegisteredDate: "2021-06-10T16:01:15.676Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-02-15T21:19:53.495Z",
    Date_WorkIssued: "2021-08-28T09:07:39.815Z",
    Work_CarriedOut: "Facilitator",
    AmountOS: 38894,
    Flag_RenewalNoticeSent: true,
    Outcome: 19592,
    Regulation: "initiatives generate",
    Date_Due: "2021-12-05T00:45:58.846Z",
    Date_NextInspection: "2021-10-20T21:37:56.073Z",
    Date_Start: "2021-06-29T21:02:00.880Z",
    Date_Occurred: "2021-08-03T01:33:38.301Z",
    ContactName: "Wilfred Kilback",
    CreatedDate: "2020-04-25T22:50:58.895Z",
    FeeType: "Lek",
    AmountIssued: 5283,
    AmountOutstanding: 21318,
    CostCode: 47677,
    AmountPaid: 1434,
    ReceiptNumber: 86906,
    ReceiptDate: "2021-08-22T03:09:03.879Z",
    ReceiptedBy: "Agent",
    Method: "Chief",
    InvoiceNumber: 40529,
    DebtorNumber: 26514,
    SecurePay_Reference: "XPD",
    Contact2: "Americo36@example.com",
    LevelOfEnforcement: "905.20",
    Number: 89572,
    DateOffence: "2021-10-08T02:00:13.969Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 82",
    ePlanningCategory: "ePlanningCategory 82",
    ePlanningLodgedBy: "ePlanningLodgedBy 82",
    Comments: "Comments 82",
    ApplicationCategory_Name: "Baby",
    ApplicationCategory_Description: "Books",
    Code: "96268-3687",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "If we quantify the alarm, we can get to the JSON card through the cross-platform SCSI matrix!",
    PublishDescription: "We need to index the digital USB panel!",
    Title: "Producer Keyboard",
    ConditionCode: "22203",
    Condition: "LLC",
    Name: "Martin O'Hara",
    Multiplier: "Multiplier 82",
    Value: 75771,
    MinimumValue: 41674,
    TargetDays: "TargetDays 82",
    Category_1: "Health",
    Category_2: "Shoes",
    ObservationSummary: "ObservationSummary 82",
    ComplianceInstruction: "ComplianceInstruction 82",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-02T16:17:42.770Z",
  },
  {
    ID: 83,
    ApplicationNumber: 94147,
    PermitNumber: 79797,
    PermitType: "deposit",
    Date_PermitIssued: "2021-11-02T11:46:30.772Z",
    RefNo: 18175,
    AssessmentNo: 2594,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Velda Waelchi Sr.",
    Builder: "Kavon Crona",
    Category: "Refined Wooden Gloves",
    BCA: "matrix",
    UseOfBuilding: false,
    ReasonForPermit: "Future",
    SiteAddress: "raymond.org",
    Locality: "MN",
    Owner: "Moshe Dibbert",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-06-04T05:24:26.307Z",
    Date_AllInspComplete: "2021-04-25T08:32:22.854Z",
    Date_DueToCommence: "2021-07-23T14:10:10.131Z",
    Date_Commenced: "2021-12-09T22:31:57.037Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "February",
    FileNo: 91336,
    ValuationNo: 21675,
    LegalDescription: "networks",
    BuildingOfficer: "Central",
    Surveyor: "Checking Account",
    SurveyorNumber: "02252173",
    StatDays: 76681,
    Date_Issued: "2021-11-11T14:46:53.392Z",
    Date_ReminderSent: "2021-03-22T16:56:16.330Z",
    PayingFee: 21618,
    CarryingOutWork: true,
    WorkProposed: "withdrawal IB",
    Address: "Apt. 191",
    BuildingAppNo: 1847,
    BuildingPermitNo: 40831,
    Date_BuildingIssued: "February",
    Date_Commencing: "June",
    BondAmount: 83561,
    BondReceived: "58554",
    BondReturned: 27716,
    Date_FirstInspection: "2021-02-23T23:34:29.593Z",
    Date_LastInspection: "2021-12-05T13:23:51.310Z",
    TargetDate: "2021-04-08T14:12:37.128Z",
    CILFee: 12023,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-06-11T04:20:38.777Z",
    InspectionType: "Wallis and Futuna",
    Officer: "Checking Account",
    Compliance: false,
    Reason: "white array",
    FacilityRegion: "Wisconsin",
    Description: "Investor",
    DateTarget: "2021-08-08T17:44:55.784Z",
    Source: "Branding",
    Location: "CO",
    RequestedBy: "26519004",
    ContactDetails: "(659) 502-0720 x587",
    DueStatus: false,
    DateRecorded: "2021-09-26T02:41:25.238Z",
    RecordedBy: "Checking Account",
    OrgStructure: "Public-key actuating adapter",
    ActionOfficer: "Kids",
    Coordinator: "Apt. 394",
    DateWork: "2021-03-13T09:31:28.938Z",
    FileNumber: 87407,
    EnquiryRefNo: 19377,
    Event_ReferenceNoAdditional: 17855,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-08-26T16:21:24.179Z",
    ApplicantName: "Home Loan Account",
    Type: "Inc",
    Risk: "Coordinator",
    DateDue: "2021-03-01T14:37:09.738Z",
    Register_Number: 76608,
    Contact: "Suite 636",
    ReferenceNumber: 48024,
    Date_Complete: "2021-06-21T21:43:16.517Z",
    Date_Construction: "2021-12-18T09:28:11.814Z",
    BarrierStandard: "BarrierStandard 83",
    BarrierType: "BarrierType 83",
    Date_CertificateOfCompliance: "2021-10-24T02:07:10.276Z",
    Pool_RegisteredDate: "2021-11-20T11:22:28.730Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-06-17T17:04:58.620Z",
    Date_WorkIssued: "2021-04-06T03:46:53.704Z",
    Work_CarriedOut: "Associate",
    AmountOS: 92543,
    Flag_RenewalNoticeSent: true,
    Outcome: 57349,
    Regulation: "Bulgarian Lev",
    Date_Due: "2021-04-24T00:10:48.358Z",
    Date_NextInspection: "2021-07-13T16:54:09.594Z",
    Date_Start: "2021-03-23T02:26:20.251Z",
    Date_Occurred: "2021-11-19T19:51:04.950Z",
    ContactName: "Austyn Gaylord",
    CreatedDate: "2020-02-20T07:11:35.491Z",
    FeeType: "₪",
    AmountIssued: 6169,
    AmountOutstanding: 27854,
    CostCode: 42765,
    AmountPaid: 67221,
    ReceiptNumber: 47344,
    ReceiptDate: "2021-11-14T11:44:02.453Z",
    ReceiptedBy: "Director",
    Method: "Customer",
    InvoiceNumber: 21705,
    DebtorNumber: 22402,
    SecurePay_Reference: "LBP",
    Contact2: "Westley_Harris95@example.net",
    LevelOfEnforcement: "656.62",
    Number: 59682,
    DateOffence: "2021-03-09T08:41:26.360Z",
    RegisterType: "Developer",
    VicSmart: "VicSmart 83",
    ePlanningCategory: "ePlanningCategory 83",
    ePlanningLodgedBy: "ePlanningLodgedBy 83",
    Comments: "Comments 83",
    ApplicationCategory_Name: "Garden",
    ApplicationCategory_Description: "Beauty",
    Code: "53657",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "If we parse the panel, we can get to the RSS program through the 1080p AI protocol!",
    PublishDescription:
      "overriding the matrix won't do anything, we need to bypass the online CSS capacitor!",
    Title: "backing up",
    ConditionCode: "96292",
    Condition: "Inc",
    Name: "Brayan Ullrich",
    Multiplier: "Multiplier 83",
    Value: 29000,
    MinimumValue: 5894,
    TargetDays: "TargetDays 83",
    Category_1: "Computers",
    Category_2: "Automotive",
    ObservationSummary: "ObservationSummary 83",
    ComplianceInstruction: "ComplianceInstruction 83",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-12T12:03:35.094Z",
  },
  {
    ID: 84,
    ApplicationNumber: 3325,
    PermitNumber: 32545,
    PermitType: "payment",
    Date_PermitIssued: "2021-10-12T05:34:13.019Z",
    RefNo: 39926,
    AssessmentNo: 37487,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Judy Mertz",
    Builder: "Blaise Cole Sr.",
    Category: "Intelligent Frozen Chips",
    BCA: "Producer",
    UseOfBuilding: false,
    ReasonForPermit: "Kentucky primary Cuban Peso Peso Convertible",
    SiteAddress: "efren.net",
    Locality: "RI",
    Owner: "Thomas Ullrich I",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-02-03T22:41:28.768Z",
    Date_AllInspComplete: "2021-09-14T03:02:33.348Z",
    Date_DueToCommence: "2021-11-07T14:11:23.640Z",
    Date_Commenced: "2021-10-24T11:48:02.476Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "November",
    FileNo: 63661,
    ValuationNo: 96370,
    LegalDescription: "Ergonomic Fresh Shoes",
    BuildingOfficer: "Senior",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "58740332",
    StatDays: 83141,
    Date_Issued: "2021-04-25T18:56:38.932Z",
    Date_ReminderSent: "2021-06-16T09:46:25.122Z",
    PayingFee: 45555,
    CarryingOutWork: true,
    WorkProposed: "client-server Wooden",
    Address: "Suite 011",
    BuildingAppNo: 37362,
    BuildingPermitNo: 7825,
    Date_BuildingIssued: "September",
    Date_Commencing: "May",
    BondAmount: 51119,
    BondReceived: "58826-7020",
    BondReturned: 85596,
    Date_FirstInspection: "2021-11-28T02:00:13.645Z",
    Date_LastInspection: "2021-09-25T11:28:08.297Z",
    TargetDate: "2021-01-23T04:28:23.051Z",
    CILFee: 46004,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-06-24T17:22:43.647Z",
    InspectionType: "invoice",
    Officer: "Money Market Account",
    Compliance: false,
    Reason: "Licensed",
    FacilityRegion: "Maryland",
    Description: "Chief",
    DateTarget: "2021-10-14T10:10:56.904Z",
    Source: "Configuration",
    Location: "NE",
    RequestedBy: "12098108",
    ContactDetails: "256-273-3814 x95442",
    DueStatus: false,
    DateRecorded: "2021-02-04T02:59:21.388Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Balanced even-keeled access",
    ActionOfficer: "Toys",
    Coordinator: "Apt. 709",
    DateWork: "2021-05-18T07:55:22.927Z",
    FileNumber: 15002,
    EnquiryRefNo: 41803,
    Event_ReferenceNoAdditional: 27866,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-11-22T02:27:00.881Z",
    ApplicantName: "Personal Loan Account",
    Type: "LLC",
    Risk: "methodologies",
    DateDue: "2021-09-05T18:52:30.564Z",
    Register_Number: 57867,
    Contact: "Apt. 081",
    ReferenceNumber: 28471,
    Date_Complete: "2021-05-06T02:45:42.992Z",
    Date_Construction: "2021-02-25T02:06:36.514Z",
    BarrierStandard: "BarrierStandard 84",
    BarrierType: "BarrierType 84",
    Date_CertificateOfCompliance: "2021-06-15T10:01:41.443Z",
    Pool_RegisteredDate: "2021-09-23T02:09:44.015Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-08T09:28:15.173Z",
    Date_WorkIssued: "2021-05-13T02:09:06.943Z",
    Work_CarriedOut: "Planner",
    AmountOS: 70829,
    Flag_RenewalNoticeSent: true,
    Outcome: 23057,
    Regulation: "sensor Balboa US Dollar West Virginia",
    Date_Due: "2021-03-15T22:30:04.243Z",
    Date_NextInspection: "2021-06-29T20:41:47.709Z",
    Date_Start: "2021-10-03T18:00:14.925Z",
    Date_Occurred: "2021-06-07T01:47:57.450Z",
    ContactName: "Clemens Kuhlman",
    CreatedDate: "2020-03-16T21:10:29.156Z",
    FeeType: "TL",
    AmountIssued: 71861,
    AmountOutstanding: 94138,
    CostCode: 95295,
    AmountPaid: 25680,
    ReceiptNumber: 30095,
    ReceiptDate: "2021-04-10T21:10:40.858Z",
    ReceiptedBy: "Strategist",
    Method: "District",
    InvoiceNumber: 71982,
    DebtorNumber: 23138,
    SecurePay_Reference: "SGD",
    Contact2: "Julien.West@example.net",
    LevelOfEnforcement: "303.60",
    Number: 25460,
    DateOffence: "2021-03-01T09:27:31.614Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 84",
    ePlanningCategory: "ePlanningCategory 84",
    ePlanningLodgedBy: "ePlanningLodgedBy 84",
    Comments: "Comments 84",
    ApplicationCategory_Name: "Tools",
    ApplicationCategory_Description: "Health",
    Code: "99683-0579",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Use the virtual IB driver, then you can compress the redundant sensor!",
    PublishDescription: "We need to parse the 1080p JBOD port!",
    Title: "web-enabled",
    ConditionCode: "00392",
    Condition: "LLC",
    Name: "Bridget Conn",
    Multiplier: "Multiplier 84",
    Value: 72874,
    MinimumValue: 23279,
    TargetDays: "TargetDays 84",
    Category_1: "Toys",
    Category_2: "Movies",
    ObservationSummary: "ObservationSummary 84",
    ComplianceInstruction: "ComplianceInstruction 84",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-25T19:48:14.676Z",
  },
  {
    ID: 85,
    ApplicationNumber: 31083,
    PermitNumber: 49354,
    PermitType: "deposit",
    Date_PermitIssued: "2021-03-21T20:31:29.864Z",
    RefNo: 63714,
    AssessmentNo: 36994,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Angelita Johns",
    Builder: "Michelle Kassulke",
    Category: "Intelligent Soft Pants",
    BCA: "Bacon",
    UseOfBuilding: false,
    ReasonForPermit: "innovate",
    SiteAddress: "josefina.info",
    Locality: "KY",
    Owner: "Pierre Lemke Sr.",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-12-06T19:54:50.795Z",
    Date_AllInspComplete: "2021-03-04T10:16:01.038Z",
    Date_DueToCommence: "2021-12-22T01:33:15.023Z",
    Date_Commenced: "2021-05-27T08:01:27.724Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "November",
    FileNo: 51075,
    ValuationNo: 41282,
    LegalDescription: "EXE",
    BuildingOfficer: "Direct",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "05714704",
    StatDays: 38708,
    Date_Issued: "2021-08-28T08:48:05.957Z",
    Date_ReminderSent: "2021-11-30T00:01:51.591Z",
    PayingFee: 69318,
    CarryingOutWork: true,
    WorkProposed: "Rhode Island",
    Address: "Apt. 489",
    BuildingAppNo: 92495,
    BuildingPermitNo: 54083,
    Date_BuildingIssued: "May",
    Date_Commencing: "July",
    BondAmount: 46638,
    BondReceived: "05170",
    BondReturned: 70685,
    Date_FirstInspection: "2021-10-18T12:08:00.910Z",
    Date_LastInspection: "2021-07-06T10:02:50.566Z",
    TargetDate: "2021-09-24T15:01:31.015Z",
    CILFee: 88373,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-08-05T03:57:50.562Z",
    InspectionType: "calculate",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "Granite",
    FacilityRegion: "New Hampshire",
    Description: "Investor",
    DateTarget: "2021-04-19T14:40:37.492Z",
    Source: "Assurance",
    Location: "OK",
    RequestedBy: "71527205",
    ContactDetails: "075.045.4264 x72376",
    DueStatus: true,
    DateRecorded: "2021-10-21T03:22:21.065Z",
    RecordedBy: "Auto Loan Account",
    OrgStructure: "Virtual explicit concept",
    ActionOfficer: "Books",
    Coordinator: "Suite 118",
    DateWork: "2021-04-27T15:59:43.902Z",
    FileNumber: 35484,
    EnquiryRefNo: 98692,
    Event_ReferenceNoAdditional: 81247,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-07-05T16:04:57.670Z",
    ApplicantName: "Personal Loan Account",
    Type: "LLC",
    Risk: "Keyboard",
    DateDue: "2021-11-25T06:29:20.204Z",
    Register_Number: 53307,
    Contact: "Suite 062",
    ReferenceNumber: 79428,
    Date_Complete: "2021-09-25T06:11:30.789Z",
    Date_Construction: "2021-04-15T21:20:14.625Z",
    BarrierStandard: "BarrierStandard 85",
    BarrierType: "BarrierType 85",
    Date_CertificateOfCompliance: "2021-05-06T01:42:05.831Z",
    Pool_RegisteredDate: "2021-10-12T01:02:31.922Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-25T03:43:41.161Z",
    Date_WorkIssued: "2021-10-17T23:56:11.334Z",
    Work_CarriedOut: "Coordinator",
    AmountOS: 82778,
    Flag_RenewalNoticeSent: false,
    Outcome: 49778,
    Regulation: "Gorgeous Rubber Cheese capacitor",
    Date_Due: "2021-07-31T10:50:21.865Z",
    Date_NextInspection: "2021-03-30T10:39:42.391Z",
    Date_Start: "2021-07-13T13:41:52.328Z",
    Date_Occurred: "2021-03-03T00:44:15.347Z",
    ContactName: "Gianni O'Keefe DVM",
    CreatedDate: "2020-05-29T16:41:10.154Z",
    FeeType: "P",
    AmountIssued: 62248,
    AmountOutstanding: 42526,
    CostCode: 98401,
    AmountPaid: 31396,
    ReceiptNumber: 44214,
    ReceiptDate: "2021-09-11T00:59:33.330Z",
    ReceiptedBy: "Consultant",
    Method: "Regional",
    InvoiceNumber: 24176,
    DebtorNumber: 4330,
    SecurePay_Reference: "RUB",
    Contact2: "Raegan3@example.com",
    LevelOfEnforcement: "802.16",
    Number: 69621,
    DateOffence: "2021-10-02T09:41:23.989Z",
    RegisterType: "Assistant",
    VicSmart: "VicSmart 85",
    ePlanningCategory: "ePlanningCategory 85",
    ePlanningLodgedBy: "ePlanningLodgedBy 85",
    Comments: "Comments 85",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Automotive",
    Code: "03834",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "If we copy the monitor, we can get to the AI interface through the wireless JBOD bandwidth!",
    PublishDescription:
      "Use the redundant USB application, then you can input the bluetooth feed!",
    Title: "Frozen South Carolina",
    ConditionCode: "32020-4674",
    Condition: "LLC",
    Name: "Daisha Feil Sr.",
    Multiplier: "Multiplier 85",
    Value: 65050,
    MinimumValue: 51582,
    TargetDays: "TargetDays 85",
    Category_1: "Games",
    Category_2: "Computers",
    ObservationSummary: "ObservationSummary 85",
    ComplianceInstruction: "ComplianceInstruction 85",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-26T16:55:54.437Z",
  },
  {
    ID: 86,
    ApplicationNumber: 29377,
    PermitNumber: 66890,
    PermitType: "deposit",
    Date_PermitIssued: "2021-08-20T03:34:45.774Z",
    RefNo: 82660,
    AssessmentNo: 49355,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Dr. Elsie Cummerata",
    Builder: "Gwendolyn Bruen",
    Category: "Sleek Wooden Bike",
    BCA: "Garden",
    UseOfBuilding: false,
    ReasonForPermit: "Sports Fantastic Rubber Ball",
    SiteAddress: "rachel.org",
    Locality: "CT",
    Owner: "Ewell Rice",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-09-13T08:25:45.901Z",
    Date_AllInspComplete: "2021-10-21T19:30:18.396Z",
    Date_DueToCommence: "2021-02-26T05:02:58.853Z",
    Date_Commenced: "2021-04-13T00:08:36.578Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "September",
    FileNo: 52028,
    ValuationNo: 73109,
    LegalDescription: "end-to-end",
    BuildingOfficer: "District",
    Surveyor: "Investment Account",
    SurveyorNumber: "36437003",
    StatDays: 34890,
    Date_Issued: "2021-03-08T12:04:39.951Z",
    Date_ReminderSent: "2021-03-07T01:13:05.507Z",
    PayingFee: 2865,
    CarryingOutWork: false,
    WorkProposed: "RAM Latvian Lats",
    Address: "Apt. 087",
    BuildingAppNo: 52922,
    BuildingPermitNo: 61085,
    Date_BuildingIssued: "March",
    Date_Commencing: "September",
    BondAmount: 23253,
    BondReceived: "00707",
    BondReturned: 61512,
    Date_FirstInspection: "2021-04-10T05:23:55.276Z",
    Date_LastInspection: "2021-07-20T01:20:39.907Z",
    TargetDate: "2021-09-30T16:39:49.290Z",
    CILFee: 53052,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-07-24T12:00:38.166Z",
    InspectionType: "Vermont",
    Officer: "Auto Loan Account",
    Compliance: false,
    Reason: "clear-thinking Montana SDD",
    FacilityRegion: "Connecticut",
    Description: "Senior",
    DateTarget: "2021-12-11T14:20:32.765Z",
    Source: "Optimization",
    Location: "MO",
    RequestedBy: "22665186",
    ContactDetails: "263.643.8864",
    DueStatus: true,
    DateRecorded: "2021-05-11T09:33:29.036Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Synchronised hybrid alliance",
    ActionOfficer: "Computers",
    Coordinator: "Suite 882",
    DateWork: "2021-07-22T22:59:22.400Z",
    FileNumber: 99560,
    EnquiryRefNo: 10795,
    Event_ReferenceNoAdditional: 21689,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-10-30T05:43:42.829Z",
    ApplicantName: "Checking Account",
    Type: "Inc",
    Risk: "wireless Avon optimizing",
    DateDue: "2021-12-27T22:19:38.807Z",
    Register_Number: 92158,
    Contact: "Apt. 290",
    ReferenceNumber: 86523,
    Date_Complete: "2021-12-14T22:48:35.604Z",
    Date_Construction: "2021-08-18T06:21:45.211Z",
    BarrierStandard: "BarrierStandard 86",
    BarrierType: "BarrierType 86",
    Date_CertificateOfCompliance: "2021-07-28T21:58:04.751Z",
    Pool_RegisteredDate: "2021-09-25T07:46:06.471Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-27T09:34:33.075Z",
    Date_WorkIssued: "2021-12-18T07:05:50.295Z",
    Work_CarriedOut: "Assistant",
    AmountOS: 39347,
    Flag_RenewalNoticeSent: true,
    Outcome: 93902,
    Regulation: "Specialist process improvement",
    Date_Due: "2021-08-12T14:33:28.260Z",
    Date_NextInspection: "2021-11-05T14:29:17.254Z",
    Date_Start: "2021-04-05T17:09:59.082Z",
    Date_Occurred: "2021-11-15T08:42:13.184Z",
    ContactName: "Felipa Funk V",
    CreatedDate: "2020-10-25T22:20:02.838Z",
    FeeType: "zł",
    AmountIssued: 49722,
    AmountOutstanding: 73685,
    CostCode: 4999,
    AmountPaid: 19412,
    ReceiptNumber: 38765,
    ReceiptDate: "2021-10-18T06:55:13.806Z",
    ReceiptedBy: "Strategist",
    Method: "Product",
    InvoiceNumber: 33183,
    DebtorNumber: 40542,
    SecurePay_Reference: "SEK",
    Contact2: "Jonathon_Rippin@example.org",
    LevelOfEnforcement: "718.35",
    Number: 28584,
    DateOffence: "2021-09-08T23:21:07.078Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 86",
    ePlanningCategory: "ePlanningCategory 86",
    ePlanningLodgedBy: "ePlanningLodgedBy 86",
    Comments: "Comments 86",
    ApplicationCategory_Name: "Music",
    ApplicationCategory_Description: "Electronics",
    Code: "24646",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Use the mobile ADP program, then you can transmit the haptic program!",
    PublishDescription:
      "Try to back up the JBOD protocol, maybe it will program the redundant feed!",
    Title: "Intelligent Metal Chair",
    ConditionCode: "38297",
    Condition: "and Sons",
    Name: "Alysha Krajcik",
    Multiplier: "Multiplier 86",
    Value: 27032,
    MinimumValue: 81555,
    TargetDays: "TargetDays 86",
    Category_1: "Health",
    Category_2: "Sports",
    ObservationSummary: "ObservationSummary 86",
    ComplianceInstruction: "ComplianceInstruction 86",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-05T13:36:11.069Z",
  },
  {
    ID: 87,
    ApplicationNumber: 40366,
    PermitNumber: 94853,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-09-03T01:35:40.369Z",
    RefNo: 84221,
    AssessmentNo: 69141,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Joy Moore IV",
    Builder: "Arlene Huels",
    Category: "Practical Rubber Soap",
    BCA: "compress",
    UseOfBuilding: false,
    ReasonForPermit: "deploy synergies",
    SiteAddress: "cristian.info",
    Locality: "OH",
    Owner: "Janice Okuneva",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-07-13T06:53:45.955Z",
    Date_AllInspComplete: "2021-11-30T04:42:21.620Z",
    Date_DueToCommence: "2021-05-10T19:08:40.107Z",
    Date_Commenced: "2021-04-23T16:12:10.708Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "November",
    FileNo: 64360,
    ValuationNo: 4059,
    LegalDescription: "Configuration functionalities",
    BuildingOfficer: "District",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "02837201",
    StatDays: 93724,
    Date_Issued: "2021-10-09T19:05:54.701Z",
    Date_ReminderSent: "2021-03-04T17:13:17.033Z",
    PayingFee: 79533,
    CarryingOutWork: true,
    WorkProposed: "relationships",
    Address: "Suite 690",
    BuildingAppNo: 25165,
    BuildingPermitNo: 69267,
    Date_BuildingIssued: "October",
    Date_Commencing: "August",
    BondAmount: 96727,
    BondReceived: "16424-4522",
    BondReturned: 74764,
    Date_FirstInspection: "2021-09-09T14:28:51.959Z",
    Date_LastInspection: "2021-01-09T16:11:27.250Z",
    TargetDate: "2021-02-23T17:17:16.676Z",
    CILFee: 46866,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-12-05T02:16:26.182Z",
    InspectionType: "strategy",
    Officer: "Savings Account",
    Compliance: false,
    Reason: "online generate",
    FacilityRegion: "Alabama",
    Description: "Corporate",
    DateTarget: "2021-03-27T19:32:51.478Z",
    Source: "Group",
    Location: "CA",
    RequestedBy: "78291540",
    ContactDetails: "584-072-4297 x416",
    DueStatus: false,
    DateRecorded: "2021-04-27T10:29:49.375Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Front-line modular neural-net",
    ActionOfficer: "Health",
    Coordinator: "Suite 541",
    DateWork: "2021-09-01T11:44:36.018Z",
    FileNumber: 78551,
    EnquiryRefNo: 78598,
    Event_ReferenceNoAdditional: 56144,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-11-10T03:46:09.015Z",
    ApplicantName: "Auto Loan Account",
    Type: "Inc",
    Risk: "Cross-group Cambridgeshire IB",
    DateDue: "2021-10-11T03:40:56.742Z",
    Register_Number: 70817,
    Contact: "Suite 583",
    ReferenceNumber: 17749,
    Date_Complete: "2021-02-14T08:46:56.300Z",
    Date_Construction: "2021-05-20T14:42:21.283Z",
    BarrierStandard: "BarrierStandard 87",
    BarrierType: "BarrierType 87",
    Date_CertificateOfCompliance: "2021-05-08T14:55:30.091Z",
    Pool_RegisteredDate: "2021-07-03T12:15:36.690Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-05T02:18:18.507Z",
    Date_WorkIssued: "2021-09-04T01:18:30.130Z",
    Work_CarriedOut: "Specialist",
    AmountOS: 98896,
    Flag_RenewalNoticeSent: true,
    Outcome: 14389,
    Regulation: "wireless Belgium Regional",
    Date_Due: "2021-12-03T16:41:13.422Z",
    Date_NextInspection: "2021-12-20T02:03:06.602Z",
    Date_Start: "2021-07-10T07:42:18.085Z",
    Date_Occurred: "2021-10-26T23:17:12.328Z",
    ContactName: "Ida Price V",
    CreatedDate: "2020-07-10T13:41:01.828Z",
    FeeType: "kn",
    AmountIssued: 53030,
    AmountOutstanding: 60771,
    CostCode: 36645,
    AmountPaid: 36763,
    ReceiptNumber: 1980,
    ReceiptDate: "2021-08-12T20:43:08.565Z",
    ReceiptedBy: "Assistant",
    Method: "Dynamic",
    InvoiceNumber: 29960,
    DebtorNumber: 9440,
    SecurePay_Reference: "SBD",
    Contact2: "Santa48@example.org",
    LevelOfEnforcement: "860.00",
    Number: 25703,
    DateOffence: "2021-01-30T01:09:20.388Z",
    RegisterType: "Producer",
    VicSmart: "VicSmart 87",
    ePlanningCategory: "ePlanningCategory 87",
    ePlanningLodgedBy: "ePlanningLodgedBy 87",
    Comments: "Comments 87",
    ApplicationCategory_Name: "Shoes",
    ApplicationCategory_Description: "Electronics",
    Code: "59244-8221",
    SortIndex: "false",
    IsPublishToWeb: false,
    PublishName:
      "backing up the sensor won't do anything, we need to back up the multi-byte SDD driver!",
    PublishDescription:
      "The SMTP monitor is down, override the back-end microchip so we can back up the HDD driver!",
    Title: "haptic Pennsylvania Crescent",
    ConditionCode: "18344-1782",
    Condition: "LLC",
    Name: "Alvis Runte",
    Multiplier: "Multiplier 87",
    Value: 99345,
    MinimumValue: 10988,
    TargetDays: "TargetDays 87",
    Category_1: "Shoes",
    Category_2: "Toys",
    ObservationSummary: "ObservationSummary 87",
    ComplianceInstruction: "ComplianceInstruction 87",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-19T22:18:06.190Z",
  },
  {
    ID: 88,
    ApplicationNumber: 4531,
    PermitNumber: 4707,
    PermitType: "deposit",
    Date_PermitIssued: "2021-03-23T19:03:24.895Z",
    RefNo: 47726,
    AssessmentNo: 61349,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Keara Huel",
    Builder: "Aniya Will",
    Category: "Tasty Granite Chicken",
    BCA: "dot-com",
    UseOfBuilding: true,
    ReasonForPermit: "Isle envisioneer Kentucky",
    SiteAddress: "jaylen.biz",
    Locality: "OK",
    Owner: "Edythe VonRueden",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-06-20T21:10:27.925Z",
    Date_AllInspComplete: "2021-12-20T01:50:07.136Z",
    Date_DueToCommence: "2021-10-10T07:12:32.216Z",
    Date_Commenced: "2021-04-27T12:02:52.717Z",
    Date_Completed: "Thursday",
    Date_CertificateIssued: "August",
    FileNo: 79624,
    ValuationNo: 92068,
    LegalDescription: "Station",
    BuildingOfficer: "International",
    Surveyor: "Auto Loan Account",
    SurveyorNumber: "21015536",
    StatDays: 21796,
    Date_Issued: "2021-11-20T01:47:42.237Z",
    Date_ReminderSent: "2021-09-18T22:43:56.747Z",
    PayingFee: 19785,
    CarryingOutWork: true,
    WorkProposed: "blue",
    Address: "Apt. 110",
    BuildingAppNo: 3992,
    BuildingPermitNo: 32049,
    Date_BuildingIssued: "July",
    Date_Commencing: "November",
    BondAmount: 64479,
    BondReceived: "50964",
    BondReturned: 86925,
    Date_FirstInspection: "2021-06-16T15:43:18.127Z",
    Date_LastInspection: "2021-12-17T14:49:37.076Z",
    TargetDate: "2021-02-19T21:51:23.232Z",
    CILFee: 72804,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-07-03T13:11:04.351Z",
    InspectionType: "Spurs",
    Officer: "Money Market Account",
    Compliance: true,
    Reason: "Roads Optimized",
    FacilityRegion: "California",
    Description: "Dynamic",
    DateTarget: "2021-05-07T15:02:33.283Z",
    Source: "Configuration",
    Location: "TX",
    RequestedBy: "87080351",
    ContactDetails: "679-031-3735 x557",
    DueStatus: true,
    DateRecorded: "2021-01-25T03:00:56.935Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Multi-channelled multi-state open architecture",
    ActionOfficer: "Baby",
    Coordinator: "Suite 537",
    DateWork: "2021-04-17T05:50:01.913Z",
    FileNumber: 61343,
    EnquiryRefNo: 43261,
    Event_ReferenceNoAdditional: 1104,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: "2021-05-14T02:34:29.194Z",
    ApplicantName: "Savings Account",
    Type: "and Sons",
    Risk: "mobile schemas lavender",
    DateDue: "2021-08-24T19:56:06.023Z",
    Register_Number: 75693,
    Contact: "Suite 780",
    ReferenceNumber: 24242,
    Date_Complete: "2021-05-19T04:14:06.069Z",
    Date_Construction: "2021-11-04T09:21:18.394Z",
    BarrierStandard: "BarrierStandard 88",
    BarrierType: "BarrierType 88",
    Date_CertificateOfCompliance: "2021-06-23T08:27:44.995Z",
    Pool_RegisteredDate: "2021-03-14T11:29:24.155Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-08-02T18:44:57.950Z",
    Date_WorkIssued: "2021-08-05T04:24:55.028Z",
    Work_CarriedOut: "Facilitator",
    AmountOS: 3954,
    Flag_RenewalNoticeSent: true,
    Outcome: 40637,
    Regulation: "bluetooth Table",
    Date_Due: "2021-09-11T18:57:04.198Z",
    Date_NextInspection: "2021-12-11T04:09:23.451Z",
    Date_Start: "2021-08-02T15:12:31.141Z",
    Date_Occurred: "2021-04-06T06:01:58.874Z",
    ContactName: "Hiram Cole",
    CreatedDate: "2020-11-27T23:05:56.127Z",
    FeeType: "ман",
    AmountIssued: 93597,
    AmountOutstanding: 77629,
    CostCode: 29539,
    AmountPaid: 84358,
    ReceiptNumber: 60471,
    ReceiptDate: "2021-12-15T18:30:13.965Z",
    ReceiptedBy: "Consultant",
    Method: "Legacy",
    InvoiceNumber: 37322,
    DebtorNumber: 23260,
    SecurePay_Reference: "ISK",
    Contact2: "Stephania.Kuhic19@example.com",
    LevelOfEnforcement: "284.99",
    Number: 5509,
    DateOffence: "2021-09-13T11:56:59.921Z",
    RegisterType: "Consultant",
    VicSmart: "VicSmart 88",
    ePlanningCategory: "ePlanningCategory 88",
    ePlanningLodgedBy: "ePlanningLodgedBy 88",
    Comments: "Comments 88",
    ApplicationCategory_Name: "Movies",
    ApplicationCategory_Description: "Sports",
    Code: "89163",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "Use the optical SMTP card, then you can override the multi-byte firewall!",
    PublishDescription:
      "If we back up the alarm, we can get to the JSON monitor through the primary SCSI matrix!",
    Title: "Causeway open-source",
    ConditionCode: "04738-7331",
    Condition: "and Sons",
    Name: "Nicholas Stehr",
    Multiplier: "Multiplier 88",
    Value: 81797,
    MinimumValue: 87901,
    TargetDays: "TargetDays 88",
    Category_1: "Jewelery",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 88",
    ComplianceInstruction: "ComplianceInstruction 88",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-05T17:48:57.519Z",
  },
  {
    ID: 89,
    ApplicationNumber: 47056,
    PermitNumber: 74280,
    PermitType: "invoice",
    Date_PermitIssued: "2021-08-17T04:05:01.749Z",
    RefNo: 79534,
    AssessmentNo: 29344,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Stevie Heathcote",
    Builder: "Rosalyn Jones",
    Category: "Rustic Fresh Keyboard",
    BCA: "Handmade Cotton Chair",
    UseOfBuilding: false,
    ReasonForPermit: "application",
    SiteAddress: "assunta.name",
    Locality: "SD",
    Owner: "Ramona Murray",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-12-18T07:14:06.334Z",
    Date_AllInspComplete: "2021-12-14T08:04:07.734Z",
    Date_DueToCommence: "2021-02-06T05:05:18.927Z",
    Date_Commenced: "2021-12-29T06:26:16.046Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "February",
    FileNo: 23109,
    ValuationNo: 28063,
    LegalDescription: "Officer hard drive",
    BuildingOfficer: "Direct",
    Surveyor: "Money Market Account",
    SurveyorNumber: "00637556",
    StatDays: 77754,
    Date_Issued: "2021-11-18T21:38:41.133Z",
    Date_ReminderSent: "2021-12-07T15:01:45.911Z",
    PayingFee: 51478,
    CarryingOutWork: true,
    WorkProposed: "Metal Borders",
    Address: "Apt. 169",
    BuildingAppNo: 68224,
    BuildingPermitNo: 87452,
    Date_BuildingIssued: "July",
    Date_Commencing: "September",
    BondAmount: 28214,
    BondReceived: "83538-7894",
    BondReturned: 5584,
    Date_FirstInspection: "2021-05-14T17:39:15.487Z",
    Date_LastInspection: "2021-04-13T15:18:30.001Z",
    TargetDate: "2021-06-25T16:01:08.648Z",
    CILFee: 35617,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-11-19T13:02:08.984Z",
    InspectionType: "magenta",
    Officer: "Checking Account",
    Compliance: true,
    Reason: "local area network",
    FacilityRegion: "Wyoming",
    Description: "Lead",
    DateTarget: "2021-11-12T09:48:12.592Z",
    Source: "Communications",
    Location: "UT",
    RequestedBy: "94114083",
    ContactDetails: "(964) 867-4259",
    DueStatus: true,
    DateRecorded: "2021-07-30T02:11:57.766Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Open-source 24/7 alliance",
    ActionOfficer: "Clothing",
    Coordinator: "Apt. 966",
    DateWork: "2021-01-03T18:40:40.572Z",
    FileNumber: 16836,
    EnquiryRefNo: 7768,
    Event_ReferenceNoAdditional: 42209,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-11-03T13:05:32.842Z",
    ApplicantName: "Personal Loan Account",
    Type: "Inc",
    Risk: "Frozen",
    DateDue: "2021-09-20T16:38:01.866Z",
    Register_Number: 88666,
    Contact: "Suite 267",
    ReferenceNumber: 78549,
    Date_Complete: "2021-05-13T18:09:29.736Z",
    Date_Construction: "2021-12-07T14:19:24.884Z",
    BarrierStandard: "BarrierStandard 89",
    BarrierType: "BarrierType 89",
    Date_CertificateOfCompliance: "2021-12-05T17:49:40.093Z",
    Pool_RegisteredDate: "2021-09-02T14:04:36.750Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-09-08T16:49:12.723Z",
    Date_WorkIssued: "2021-06-25T00:21:11.169Z",
    Work_CarriedOut: "Producer",
    AmountOS: 81248,
    Flag_RenewalNoticeSent: false,
    Outcome: 88336,
    Regulation: "back up Small Fresh Chair",
    Date_Due: "2021-02-12T23:54:50.958Z",
    Date_NextInspection: "2021-08-09T11:49:17.006Z",
    Date_Start: "2021-11-03T22:14:11.214Z",
    Date_Occurred: "2021-07-17T03:33:06.388Z",
    ContactName: "Keeley Collins",
    CreatedDate: "2020-08-24T12:21:16.296Z",
    FeeType: "$",
    AmountIssued: 74059,
    AmountOutstanding: 60726,
    CostCode: 50060,
    AmountPaid: 94473,
    ReceiptNumber: 32651,
    ReceiptDate: "2021-06-25T19:20:46.171Z",
    ReceiptedBy: "Consultant",
    Method: "Dynamic",
    InvoiceNumber: 77639,
    DebtorNumber: 98202,
    SecurePay_Reference: "ERN",
    Contact2: "Carmine43@example.net",
    LevelOfEnforcement: "546.37",
    Number: 70530,
    DateOffence: "2021-06-24T16:26:35.231Z",
    RegisterType: "Executive",
    VicSmart: "VicSmart 89",
    ePlanningCategory: "ePlanningCategory 89",
    ePlanningLodgedBy: "ePlanningLodgedBy 89",
    Comments: "Comments 89",
    ApplicationCategory_Name: "Baby",
    ApplicationCategory_Description: "Toys",
    Code: "98601-2011",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "Try to connect the RAM monitor, maybe it will calculate the virtual card!",
    PublishDescription:
      "You can't bypass the card without copying the multi-byte RSS sensor!",
    Title: "deposit alarm generate",
    ConditionCode: "60647",
    Condition: "LLC",
    Name: "Lilyan Nikolaus",
    Multiplier: "Multiplier 89",
    Value: 38878,
    MinimumValue: 35803,
    TargetDays: "TargetDays 89",
    Category_1: "Grocery",
    Category_2: "Electronics",
    ObservationSummary: "ObservationSummary 89",
    ComplianceInstruction: "ComplianceInstruction 89",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-04T08:36:33.079Z",
  },
  {
    ID: 90,
    ApplicationNumber: 39742,
    PermitNumber: 43049,
    PermitType: "payment",
    Date_PermitIssued: "2021-05-08T13:06:18.975Z",
    RefNo: 64851,
    AssessmentNo: 5337,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Ms. Jeromy Metz",
    Builder: "Norbert Tillman I",
    Category: "Unbranded Granite Chicken",
    BCA: "Refined",
    UseOfBuilding: true,
    ReasonForPermit: "Tasty Wooden Computer reboot",
    SiteAddress: "dustin.org",
    Locality: "MA",
    Owner: "Ransom Lesch",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-07-06T17:32:13.446Z",
    Date_AllInspComplete: "2021-09-11T14:19:21.966Z",
    Date_DueToCommence: "2021-04-02T05:29:43.127Z",
    Date_Commenced: "2021-02-03T11:55:29.879Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "April",
    FileNo: 98661,
    ValuationNo: 61338,
    LegalDescription: "Place program",
    BuildingOfficer: "Dynamic",
    Surveyor: "Investment Account",
    SurveyorNumber: "52650960",
    StatDays: 20676,
    Date_Issued: "2021-03-30T21:53:52.574Z",
    Date_ReminderSent: "2021-06-07T22:30:28.435Z",
    PayingFee: 6952,
    CarryingOutWork: true,
    WorkProposed: "Practical Berkshire",
    Address: "Apt. 701",
    BuildingAppNo: 82753,
    BuildingPermitNo: 77410,
    Date_BuildingIssued: "September",
    Date_Commencing: "May",
    BondAmount: 26196,
    BondReceived: "10673-6393",
    BondReturned: 69011,
    Date_FirstInspection: "2021-10-03T08:45:55.057Z",
    Date_LastInspection: "2021-12-20T14:58:22.388Z",
    TargetDate: "2021-02-24T01:09:47.222Z",
    CILFee: 79131,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-01-23T20:37:07.182Z",
    InspectionType: "Borders",
    Officer: "Money Market Account",
    Compliance: false,
    Reason: "Fantastic",
    FacilityRegion: "Iowa",
    Description: "District",
    DateTarget: "2021-08-28T00:04:30.184Z",
    Source: "Branding",
    Location: "IN",
    RequestedBy: "76075778",
    ContactDetails: "(369) 897-6233 x39113",
    DueStatus: false,
    DateRecorded: "2021-07-27T10:43:55.564Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Stand-alone directional algorithm",
    ActionOfficer: "Computers",
    Coordinator: "Suite 143",
    DateWork: "2021-06-11T01:19:57.289Z",
    FileNumber: 32907,
    EnquiryRefNo: 78677,
    Event_ReferenceNoAdditional: 99275,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-06-05T10:19:28.298Z",
    ApplicantName: "Savings Account",
    Type: "Group",
    Risk: "mesh protocol killer",
    DateDue: "2021-08-28T21:59:28.885Z",
    Register_Number: 48334,
    Contact: "Apt. 589",
    ReferenceNumber: 74809,
    Date_Complete: "2021-08-02T10:18:09.098Z",
    Date_Construction: "2021-08-23T08:16:52.786Z",
    BarrierStandard: "BarrierStandard 90",
    BarrierType: "BarrierType 90",
    Date_CertificateOfCompliance: "2021-08-30T11:59:31.218Z",
    Pool_RegisteredDate: "2021-09-05T03:53:37.926Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-26T15:14:39.202Z",
    Date_WorkIssued: "2021-08-30T21:07:12.038Z",
    Work_CarriedOut: "Analyst",
    AmountOS: 97688,
    Flag_RenewalNoticeSent: true,
    Outcome: 99904,
    Regulation: "Buckinghamshire Generic plum",
    Date_Due: "2021-09-02T19:59:39.473Z",
    Date_NextInspection: "2021-06-17T20:26:40.565Z",
    Date_Start: "2021-02-11T19:34:06.079Z",
    Date_Occurred: "2021-05-04T00:31:34.909Z",
    ContactName: "Mckenzie Goyette",
    CreatedDate: "2020-03-06T22:45:24.840Z",
    FeeType: "руб",
    AmountIssued: 31365,
    AmountOutstanding: 33436,
    CostCode: 3491,
    AmountPaid: 93134,
    ReceiptNumber: 30015,
    ReceiptDate: "2021-12-09T21:13:59.424Z",
    ReceiptedBy: "Engineer",
    Method: "Senior",
    InvoiceNumber: 47330,
    DebtorNumber: 85632,
    SecurePay_Reference: "XCD",
    Contact2: "Marcos.Gleason98@example.org",
    LevelOfEnforcement: "911.69",
    Number: 90756,
    DateOffence: "2021-06-17T03:18:37.679Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 90",
    ePlanningCategory: "ePlanningCategory 90",
    ePlanningLodgedBy: "ePlanningLodgedBy 90",
    Comments: "Comments 90",
    ApplicationCategory_Name: "Automotive",
    ApplicationCategory_Description: "Music",
    Code: "85312-7548",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Try to compress the SAS panel, maybe it will hack the cross-platform application!",
    PublishDescription:
      "Try to program the RAM system, maybe it will index the haptic driver!",
    Title: "withdrawal bandwidth scale",
    ConditionCode: "88575",
    Condition: "LLC",
    Name: "Broderick Dickinson",
    Multiplier: "Multiplier 90",
    Value: 71349,
    MinimumValue: 76830,
    TargetDays: "TargetDays 90",
    Category_1: "Home",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 90",
    ComplianceInstruction: "ComplianceInstruction 90",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-07-02T14:21:28.768Z",
  },
  {
    ID: 91,
    ApplicationNumber: 60717,
    PermitNumber: 23668,
    PermitType: "deposit",
    Date_PermitIssued: "2021-05-22T12:30:17.420Z",
    RefNo: 30817,
    AssessmentNo: 39131,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Katlynn Johnson",
    Builder: "Robbie Senger",
    Category: "Fantastic Concrete Shoes",
    BCA: "mesh",
    UseOfBuilding: true,
    ReasonForPermit: "web-enabled Handcrafted open architecture",
    SiteAddress: "tabitha.org",
    Locality: "UT",
    Owner: "Bria Harris",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-02-13T09:22:30.230Z",
    Date_AllInspComplete: "2021-09-29T03:37:07.024Z",
    Date_DueToCommence: "2021-03-01T14:49:00.564Z",
    Date_Commenced: "2021-10-27T01:09:40.865Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "December",
    FileNo: 11496,
    ValuationNo: 3797,
    LegalDescription: "feed",
    BuildingOfficer: "District",
    Surveyor: "Investment Account",
    SurveyorNumber: "55974060",
    StatDays: 92178,
    Date_Issued: "2021-02-23T14:21:35.492Z",
    Date_ReminderSent: "2021-11-30T17:37:54.172Z",
    PayingFee: 52675,
    CarryingOutWork: true,
    WorkProposed:
      "British Indian Ocean Territory (Chagos Archipelago) Cheese Operations",
    Address: "Apt. 195",
    BuildingAppNo: 64941,
    BuildingPermitNo: 28778,
    Date_BuildingIssued: "November",
    Date_Commencing: "August",
    BondAmount: 36301,
    BondReceived: "99596",
    BondReturned: 68022,
    Date_FirstInspection: "2021-12-01T05:15:39.619Z",
    Date_LastInspection: "2021-06-12T00:39:07.713Z",
    TargetDate: "2021-10-16T20:44:43.757Z",
    CILFee: 4043,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-07-18T19:33:22.776Z",
    InspectionType: "Bike",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "index protocol back-end",
    FacilityRegion: "Maine",
    Description: "Investor",
    DateTarget: "2021-12-28T17:37:38.035Z",
    Source: "Intranet",
    Location: "CO",
    RequestedBy: "99151146",
    ContactDetails: "659-477-9450",
    DueStatus: false,
    DateRecorded: "2021-01-25T09:54:19.669Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Triple-buffered mobile capacity",
    ActionOfficer: "Movies",
    Coordinator: "Apt. 798",
    DateWork: "2021-02-13T00:30:06.335Z",
    FileNumber: 18216,
    EnquiryRefNo: 52786,
    Event_ReferenceNoAdditional: 13618,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-12-27T06:47:27.459Z",
    ApplicantName: "Personal Loan Account",
    Type: "LLC",
    Risk: "withdrawal Programmable Architect",
    DateDue: "2021-12-27T00:56:47.653Z",
    Register_Number: 47055,
    Contact: "Apt. 124",
    ReferenceNumber: 76311,
    Date_Complete: "2021-08-01T15:53:06.250Z",
    Date_Construction: "2021-05-18T23:05:22.964Z",
    BarrierStandard: "BarrierStandard 91",
    BarrierType: "BarrierType 91",
    Date_CertificateOfCompliance: "2021-05-08T16:11:36.136Z",
    Pool_RegisteredDate: "2021-11-10T17:34:42.568Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-10-06T05:52:28.766Z",
    Date_WorkIssued: "2021-04-17T14:32:46.099Z",
    Work_CarriedOut: "Representative",
    AmountOS: 81191,
    Flag_RenewalNoticeSent: true,
    Outcome: 59249,
    Regulation: "Factors Surinam Dollar Liaison",
    Date_Due: "2021-07-14T04:55:19.900Z",
    Date_NextInspection: "2021-07-12T12:39:19.446Z",
    Date_Start: "2021-12-10T05:21:22.687Z",
    Date_Occurred: "2021-05-31T10:48:54.219Z",
    ContactName: "Carter Cole",
    CreatedDate: "2020-07-06T04:05:36.779Z",
    FeeType: "RD$",
    AmountIssued: 23359,
    AmountOutstanding: 76386,
    CostCode: 77788,
    AmountPaid: 26450,
    ReceiptNumber: 63481,
    ReceiptDate: "2021-07-16T16:56:01.266Z",
    ReceiptedBy: "Orchestrator",
    Method: "Corporate",
    InvoiceNumber: 1906,
    DebtorNumber: 77644,
    SecurePay_Reference: "AFN",
    Contact2: "Quinten.Schultz99@example.com",
    LevelOfEnforcement: "548.55",
    Number: 93278,
    DateOffence: "2021-11-16T02:54:16.362Z",
    RegisterType: "Architect",
    VicSmart: "VicSmart 91",
    ePlanningCategory: "ePlanningCategory 91",
    ePlanningLodgedBy: "ePlanningLodgedBy 91",
    Comments: "Comments 91",
    ApplicationCategory_Name: "Shoes",
    ApplicationCategory_Description: "Outdoors",
    Code: "58029",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "If we quantify the monitor, we can get to the GB array through the mobile SMTP hard drive!",
    PublishDescription:
      "Use the bluetooth PCI port, then you can index the cross-platform program!",
    Title: "Supervisor azure",
    ConditionCode: "37904",
    Condition: "LLC",
    Name: "Charity Durgan",
    Multiplier: "Multiplier 91",
    Value: 31969,
    MinimumValue: 21599,
    TargetDays: "TargetDays 91",
    Category_1: "Kids",
    Category_2: "Home",
    ObservationSummary: "ObservationSummary 91",
    ComplianceInstruction: "ComplianceInstruction 91",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-25T13:42:34.316Z",
  },
  {
    ID: 92,
    ApplicationNumber: 22909,
    PermitNumber: 40732,
    PermitType: "withdrawal",
    Date_PermitIssued: "2021-12-29T21:16:21.750Z",
    RefNo: 38669,
    AssessmentNo: 44115,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Bill Buckridge",
    Builder: "Reuben Gerlach",
    Category: "Incredible Cotton Tuna",
    BCA: "virtual",
    UseOfBuilding: true,
    ReasonForPermit: "mint green copy",
    SiteAddress: "annetta.com",
    Locality: "NJ",
    Owner: "Asha Hessel",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-01-28T06:44:28.653Z",
    Date_AllInspComplete: "2021-02-23T15:02:54.248Z",
    Date_DueToCommence: "2021-12-16T08:47:53.916Z",
    Date_Commenced: "2021-10-03T18:17:40.855Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "February",
    FileNo: 2773,
    ValuationNo: 19410,
    LegalDescription:
      "Handmade Fresh Keyboard Tasty Plastic Bacon Checking Account",
    BuildingOfficer: "International",
    Surveyor: "Savings Account",
    SurveyorNumber: "75565615",
    StatDays: 4086,
    Date_Issued: "2021-02-22T20:57:23.485Z",
    Date_ReminderSent: "2021-01-05T14:55:12.005Z",
    PayingFee: 68725,
    CarryingOutWork: true,
    WorkProposed: "tertiary South Dakota",
    Address: "Suite 805",
    BuildingAppNo: 57918,
    BuildingPermitNo: 61763,
    Date_BuildingIssued: "November",
    Date_Commencing: "May",
    BondAmount: 92593,
    BondReceived: "37221",
    BondReturned: 80916,
    Date_FirstInspection: "2021-10-04T03:01:08.563Z",
    Date_LastInspection: "2021-05-14T18:09:19.716Z",
    TargetDate: "2021-11-29T11:47:34.230Z",
    CILFee: 21435,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-04-01T05:09:09.729Z",
    InspectionType: "parse",
    Officer: "Home Loan Account",
    Compliance: false,
    Reason: "client-driven Steel Denar",
    FacilityRegion: "Alabama",
    Description: "Global",
    DateTarget: "2021-10-05T02:17:47.261Z",
    Source: "Group",
    Location: "NM",
    RequestedBy: "32786611",
    ContactDetails: "523.446.9212 x5160",
    DueStatus: true,
    DateRecorded: "2021-06-07T19:03:11.741Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Balanced well-modulated local area network",
    ActionOfficer: "Industrial",
    Coordinator: "Suite 898",
    DateWork: "2021-06-16T22:26:55.999Z",
    FileNumber: 34526,
    EnquiryRefNo: 42311,
    Event_ReferenceNoAdditional: 20187,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-07-16T17:25:54.091Z",
    ApplicantName: "Credit Card Account",
    Type: "and Sons",
    Risk: "JBOD Codes specifically reserved for testing purposes Mews",
    DateDue: "2021-06-26T23:24:44.584Z",
    Register_Number: 39013,
    Contact: "Suite 549",
    ReferenceNumber: 99054,
    Date_Complete: "2021-06-08T18:20:28.318Z",
    Date_Construction: "2021-12-08T20:23:17.342Z",
    BarrierStandard: "BarrierStandard 92",
    BarrierType: "BarrierType 92",
    Date_CertificateOfCompliance: "2021-03-05T00:54:14.437Z",
    Pool_RegisteredDate: "2021-10-21T13:37:53.752Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-12-03T22:09:57.182Z",
    Date_WorkIssued: "2021-05-09T00:38:28.189Z",
    Work_CarriedOut: "Associate",
    AmountOS: 59320,
    Flag_RenewalNoticeSent: true,
    Outcome: 20981,
    Regulation:
      "Codes specifically reserved for testing purposes Computer actuating",
    Date_Due: "2021-09-21T15:59:57.780Z",
    Date_NextInspection: "2021-04-13T09:53:33.011Z",
    Date_Start: "2021-12-12T07:08:36.266Z",
    Date_Occurred: "2021-08-07T21:48:27.928Z",
    ContactName: "Jazmyn Carter II",
    CreatedDate: "2020-10-13T03:22:25.619Z",
    FeeType: "$b",
    AmountIssued: 50782,
    AmountOutstanding: 14964,
    CostCode: 57273,
    AmountPaid: 4244,
    ReceiptNumber: 1146,
    ReceiptDate: "2021-12-27T01:08:20.095Z",
    ReceiptedBy: "Director",
    Method: "Customer",
    InvoiceNumber: 32325,
    DebtorNumber: 93454,
    SecurePay_Reference: "HTG USD",
    Contact2: "Naomie0@example.com",
    LevelOfEnforcement: "391.16",
    Number: 69756,
    DateOffence: "2021-12-12T17:43:24.583Z",
    RegisterType: "Liaison",
    VicSmart: "VicSmart 92",
    ePlanningCategory: "ePlanningCategory 92",
    ePlanningLodgedBy: "ePlanningLodgedBy 92",
    Comments: "Comments 92",
    ApplicationCategory_Name: "Automotive",
    ApplicationCategory_Description: "Kids",
    Code: "17797-3073",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "overriding the driver won't do anything, we need to navigate the wireless HTTP microchip!",
    PublishDescription:
      "I'll override the digital SSL protocol, that should array the RAM transmitter!",
    Title: "Product well-modulated",
    ConditionCode: "67878-9484",
    Condition: "Group",
    Name: "Myrtice VonRueden",
    Multiplier: "Multiplier 92",
    Value: 93932,
    MinimumValue: 35359,
    TargetDays: "TargetDays 92",
    Category_1: "Health",
    Category_2: "Garden",
    ObservationSummary: "ObservationSummary 92",
    ComplianceInstruction: "ComplianceInstruction 92",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-29T02:43:52.873Z",
  },
  {
    ID: 93,
    ApplicationNumber: 60464,
    PermitNumber: 50295,
    PermitType: "deposit",
    Date_PermitIssued: "2021-12-16T21:00:42.092Z",
    RefNo: 94797,
    AssessmentNo: 3825,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Andres Wiegand IV",
    Builder: "Ayana Crooks",
    Category: "Gorgeous Granite Keyboard",
    BCA: "circuit",
    UseOfBuilding: false,
    ReasonForPermit: "Licensed Cotton Bike",
    SiteAddress: "novella.info",
    Locality: "RI",
    Owner: "Dexter Eichmann",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-10-04T22:27:13.240Z",
    Date_AllInspComplete: "2021-03-04T10:45:15.950Z",
    Date_DueToCommence: "2021-04-06T15:57:57.112Z",
    Date_Commenced: "2021-11-03T00:27:57.810Z",
    Date_Completed: "Friday",
    Date_CertificateIssued: "June",
    FileNo: 87033,
    ValuationNo: 64928,
    LegalDescription: "dedicated bus",
    BuildingOfficer: "Lead",
    Surveyor: "Savings Account",
    SurveyorNumber: "69921222",
    StatDays: 78467,
    Date_Issued: "2021-10-03T17:55:56.099Z",
    Date_ReminderSent: "2021-04-21T17:41:57.044Z",
    PayingFee: 27291,
    CarryingOutWork: true,
    WorkProposed: "ADP",
    Address: "Suite 588",
    BuildingAppNo: 569,
    BuildingPermitNo: 23000,
    Date_BuildingIssued: "March",
    Date_Commencing: "January",
    BondAmount: 2462,
    BondReceived: "39244",
    BondReturned: 87444,
    Date_FirstInspection: "2021-10-16T17:29:08.861Z",
    Date_LastInspection: "2021-06-22T22:35:12.248Z",
    TargetDate: "2021-03-12T23:27:00.313Z",
    CILFee: 60188,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-10-20T00:31:10.933Z",
    InspectionType: "Illinois",
    Officer: "Savings Account",
    Compliance: true,
    Reason: "customized connecting orchestrate",
    FacilityRegion: "Maine",
    Description: "Legacy",
    DateTarget: "2021-01-24T14:00:09.899Z",
    Source: "Accounts",
    Location: "NM",
    RequestedBy: "96234047",
    ContactDetails: "(779) 561-5638 x928",
    DueStatus: false,
    DateRecorded: "2021-02-11T02:06:44.620Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Assimilated upward-trending process improvement",
    ActionOfficer: "Outdoors",
    Coordinator: "Apt. 275",
    DateWork: "2021-03-12T08:52:43.117Z",
    FileNumber: 32564,
    EnquiryRefNo: 21465,
    Event_ReferenceNoAdditional: 80654,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-08-30T03:48:12.589Z",
    ApplicantName: "Auto Loan Account",
    Type: "and Sons",
    Risk: "transmit",
    DateDue: "2021-04-10T12:19:01.875Z",
    Register_Number: 33275,
    Contact: "Suite 930",
    ReferenceNumber: 87889,
    Date_Complete: "2021-08-18T10:54:37.442Z",
    Date_Construction: "2021-11-19T03:41:03.322Z",
    BarrierStandard: "BarrierStandard 93",
    BarrierType: "BarrierType 93",
    Date_CertificateOfCompliance: "2021-09-10T00:01:10.874Z",
    Pool_RegisteredDate: "2021-01-19T08:56:23.644Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-04T17:44:24.930Z",
    Date_WorkIssued: "2021-01-08T08:50:05.310Z",
    Work_CarriedOut: "Associate",
    AmountOS: 87918,
    Flag_RenewalNoticeSent: true,
    Outcome: 50277,
    Regulation: "Sports",
    Date_Due: "2021-02-28T19:47:29.089Z",
    Date_NextInspection: "2021-12-29T06:54:59.611Z",
    Date_Start: "2021-01-20T19:19:16.165Z",
    Date_Occurred: "2021-12-30T08:13:04.347Z",
    ContactName: "Jaquan Ledner",
    CreatedDate: "2020-02-21T13:25:07.384Z",
    FeeType: "kr",
    AmountIssued: 67258,
    AmountOutstanding: 77781,
    CostCode: 45706,
    AmountPaid: 91531,
    ReceiptNumber: 32693,
    ReceiptDate: "2021-06-27T05:47:28.583Z",
    ReceiptedBy: "Specialist",
    Method: "Forward",
    InvoiceNumber: 30137,
    DebtorNumber: 39279,
    SecurePay_Reference: "HUF",
    Contact2: "Al.Homenick@example.com",
    LevelOfEnforcement: "871.44",
    Number: 90762,
    DateOffence: "2021-09-17T12:16:58.728Z",
    RegisterType: "Director",
    VicSmart: "VicSmart 93",
    ePlanningCategory: "ePlanningCategory 93",
    ePlanningLodgedBy: "ePlanningLodgedBy 93",
    Comments: "Comments 93",
    ApplicationCategory_Name: "Music",
    ApplicationCategory_Description: "Music",
    Code: "10751",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Use the bluetooth PNG circuit, then you can override the 1080p protocol!",
    PublishDescription:
      "transmitting the protocol won't do anything, we need to program the virtual RSS interface!",
    Title: "RAM",
    ConditionCode: "19721-4641",
    Condition: "Inc",
    Name: "Vincent Nitzsche",
    Multiplier: "Multiplier 93",
    Value: 56219,
    MinimumValue: 30148,
    TargetDays: "TargetDays 93",
    Category_1: "Movies",
    Category_2: "Clothing",
    ObservationSummary: "ObservationSummary 93",
    ComplianceInstruction: "ComplianceInstruction 93",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-04-15T04:37:50.068Z",
  },
  {
    ID: 94,
    ApplicationNumber: 47442,
    PermitNumber: 11709,
    PermitType: "invoice",
    Date_PermitIssued: "2021-08-30T01:33:30.986Z",
    RefNo: 66739,
    AssessmentNo: 17576,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Adeline Kris I",
    Builder: "Else Moen",
    Category: "Generic Cotton Bike",
    BCA: "Automotive",
    UseOfBuilding: true,
    ReasonForPermit: "Marshall Islands Naira",
    SiteAddress: "benjamin.name",
    Locality: "MN",
    Owner: "Mrs. Armani Auer",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-03-30T02:33:04.833Z",
    Date_AllInspComplete: "2021-02-05T08:03:45.513Z",
    Date_DueToCommence: "2021-12-02T23:54:22.945Z",
    Date_Commenced: "2021-01-12T00:17:24.707Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "January",
    FileNo: 455,
    ValuationNo: 25443,
    LegalDescription: "Supervisor Chair",
    BuildingOfficer: "Forward",
    Surveyor: "Money Market Account",
    SurveyorNumber: "68654239",
    StatDays: 39146,
    Date_Issued: "2021-11-07T19:58:22.062Z",
    Date_ReminderSent: "2021-01-23T05:50:36.307Z",
    PayingFee: 3624,
    CarryingOutWork: true,
    WorkProposed: "deliverables Virginia New Caledonia",
    Address: "Apt. 411",
    BuildingAppNo: 51491,
    BuildingPermitNo: 48622,
    Date_BuildingIssued: "December",
    Date_Commencing: "March",
    BondAmount: 29230,
    BondReceived: "62215-7437",
    BondReturned: 66967,
    Date_FirstInspection: "2021-04-12T01:33:53.664Z",
    Date_LastInspection: "2021-10-19T17:54:08.527Z",
    TargetDate: "2021-10-17T11:58:01.951Z",
    CILFee: 92434,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-05-02T00:11:08.464Z",
    InspectionType: "card",
    Officer: "Savings Account",
    Compliance: false,
    Reason: "grid-enabled bandwidth Kids",
    FacilityRegion: "New Hampshire",
    Description: "Global",
    DateTarget: "2021-12-20T00:55:57.341Z",
    Source: "Directives",
    Location: "MA",
    RequestedBy: "36240459",
    ContactDetails: "1-766-613-5311",
    DueStatus: true,
    DateRecorded: "2021-02-27T05:39:33.506Z",
    RecordedBy: "Home Loan Account",
    OrgStructure: "Stand-alone object-oriented moderator",
    ActionOfficer: "Industrial",
    Coordinator: "Suite 111",
    DateWork: "2021-05-04T17:22:50.309Z",
    FileNumber: 61614,
    EnquiryRefNo: 88459,
    Event_ReferenceNoAdditional: 82804,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-06-04T10:13:19.468Z",
    ApplicantName: "Auto Loan Account",
    Type: "LLC",
    Risk: "support",
    DateDue: "2021-12-09T12:39:28.802Z",
    Register_Number: 89117,
    Contact: "Suite 314",
    ReferenceNumber: 61333,
    Date_Complete: "2021-03-04T16:42:37.392Z",
    Date_Construction: "2021-05-08T01:57:23.889Z",
    BarrierStandard: "BarrierStandard 94",
    BarrierType: "BarrierType 94",
    Date_CertificateOfCompliance: "2021-06-01T05:44:06.410Z",
    Pool_RegisteredDate: "2021-02-27T17:19:14.115Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-07T09:57:46.500Z",
    Date_WorkIssued: "2021-04-21T02:03:54.902Z",
    Work_CarriedOut: "Director",
    AmountOS: 90957,
    Flag_RenewalNoticeSent: false,
    Outcome: 15745,
    Regulation: "array Investment Account markets",
    Date_Due: "2021-11-02T02:08:38.627Z",
    Date_NextInspection: "2021-09-17T00:06:53.261Z",
    Date_Start: "2021-12-08T01:53:45.339Z",
    Date_Occurred: "2021-09-12T06:47:37.043Z",
    ContactName: "Ian Hoppe III",
    CreatedDate: "2020-12-09T16:30:27.314Z",
    FeeType: "C$",
    AmountIssued: 19095,
    AmountOutstanding: 53006,
    CostCode: 25149,
    AmountPaid: 4733,
    ReceiptNumber: 62014,
    ReceiptDate: "2021-04-03T14:47:42.888Z",
    ReceiptedBy: "Architect",
    Method: "International",
    InvoiceNumber: 70237,
    DebtorNumber: 39524,
    SecurePay_Reference: "AMD",
    Contact2: "Nakia.Eichmann38@example.org",
    LevelOfEnforcement: "127.99",
    Number: 88273,
    DateOffence: "2021-08-23T09:57:27.384Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 94",
    ePlanningCategory: "ePlanningCategory 94",
    ePlanningLodgedBy: "ePlanningLodgedBy 94",
    Comments: "Comments 94",
    ApplicationCategory_Name: "Grocery",
    ApplicationCategory_Description: "Music",
    Code: "35795-7481",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Try to override the XML firewall, maybe it will copy the back-end hard drive!",
    PublishDescription:
      "You can't override the monitor without bypassing the online XML port!",
    Title: "quantifying stable Rubber",
    ConditionCode: "59182-4150",
    Condition: "Inc",
    Name: "Ayana Watsica",
    Multiplier: "Multiplier 94",
    Value: 46379,
    MinimumValue: 81448,
    TargetDays: "TargetDays 94",
    Category_1: "Baby",
    Category_2: "Home",
    ObservationSummary: "ObservationSummary 94",
    ComplianceInstruction: "ComplianceInstruction 94",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-31T18:50:40.242Z",
  },
  {
    ID: 95,
    ApplicationNumber: 32361,
    PermitNumber: 3604,
    PermitType: "deposit",
    Date_PermitIssued: "2021-07-31T11:30:08.148Z",
    RefNo: 65321,
    AssessmentNo: 22994,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Rowan Spencer",
    Builder: "Dr. Christian Bergnaum",
    Category: "Unbranded Concrete Bike",
    BCA: "XSS",
    UseOfBuilding: false,
    ReasonForPermit: "Home Loan Account",
    SiteAddress: "myah.net",
    Locality: "ME",
    Owner: "Talia McClure",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-08-16T14:26:23.875Z",
    Date_AllInspComplete: "2021-11-09T04:19:18.857Z",
    Date_DueToCommence: "2021-05-29T10:24:45.170Z",
    Date_Commenced: "2021-06-19T07:15:00.006Z",
    Date_Completed: "Wednesday",
    Date_CertificateIssued: "December",
    FileNo: 55530,
    ValuationNo: 49130,
    LegalDescription: "Louisiana Shoes",
    BuildingOfficer: "Global",
    Surveyor: "Checking Account",
    SurveyorNumber: "43682019",
    StatDays: 51333,
    Date_Issued: "2021-09-01T03:02:43.372Z",
    Date_ReminderSent: "2021-11-18T03:19:54.349Z",
    PayingFee: 8346,
    CarryingOutWork: false,
    WorkProposed: "Unbranded",
    Address: "Apt. 510",
    BuildingAppNo: 60884,
    BuildingPermitNo: 56743,
    Date_BuildingIssued: "October",
    Date_Commencing: "September",
    BondAmount: 58040,
    BondReceived: "78905-5867",
    BondReturned: 92253,
    Date_FirstInspection: "2021-03-15T08:41:48.896Z",
    Date_LastInspection: "2021-10-07T05:00:02.994Z",
    TargetDate: "2021-05-28T08:20:13.491Z",
    CILFee: 30828,
    Flag_CILPaid: false,
    LettersDisplay: true,
    DateInspected: "2021-09-17T22:39:10.030Z",
    InspectionType: "JSON",
    Officer: "Auto Loan Account",
    Compliance: true,
    Reason: "human-resource",
    FacilityRegion: "Rhode Island",
    Description: "Corporate",
    DateTarget: "2021-10-13T18:28:17.658Z",
    Source: "Metrics",
    Location: "MO",
    RequestedBy: "81425856",
    ContactDetails: "1-700-988-0789 x89317",
    DueStatus: true,
    DateRecorded: "2021-05-18T20:34:12.389Z",
    RecordedBy: "Savings Account",
    OrgStructure: "Pre-emptive fault-tolerant project",
    ActionOfficer: "Kids",
    Coordinator: "Apt. 330",
    DateWork: "2021-06-05T00:50:03.804Z",
    FileNumber: 3339,
    EnquiryRefNo: 16924,
    Event_ReferenceNoAdditional: 25970,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-12-23T15:20:23.835Z",
    ApplicantName: "Savings Account",
    Type: "Inc",
    Risk: "Lead back-end Markets",
    DateDue: "2021-04-30T00:42:38.914Z",
    Register_Number: 66396,
    Contact: "Suite 607",
    ReferenceNumber: 66999,
    Date_Complete: "2021-06-14T01:07:21.531Z",
    Date_Construction: "2021-02-15T12:48:07.988Z",
    BarrierStandard: "BarrierStandard 95",
    BarrierType: "BarrierType 95",
    Date_CertificateOfCompliance: "2021-07-06T16:23:20.566Z",
    Pool_RegisteredDate: "2021-11-21T23:12:07.680Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-03-12T08:43:12.295Z",
    Date_WorkIssued: "2021-09-13T04:21:51.345Z",
    Work_CarriedOut: "Supervisor",
    AmountOS: 25641,
    Flag_RenewalNoticeSent: false,
    Outcome: 27859,
    Regulation: "Interactions moratorium Right-sized",
    Date_Due: "2021-10-11T21:08:06.982Z",
    Date_NextInspection: "2021-02-26T04:40:07.720Z",
    Date_Start: "2021-09-10T02:44:54.177Z",
    Date_Occurred: "2021-10-23T09:48:20.450Z",
    ContactName: "Ayana Prohaska PhD",
    CreatedDate: "2020-08-07T09:03:36.627Z",
    FeeType: "₭",
    AmountIssued: 20295,
    AmountOutstanding: 9324,
    CostCode: 34366,
    AmountPaid: 49562,
    ReceiptNumber: 9305,
    ReceiptDate: "2021-08-17T09:29:03.970Z",
    ReceiptedBy: "Technician",
    Method: "Corporate",
    InvoiceNumber: 35004,
    DebtorNumber: 38306,
    SecurePay_Reference: "CAD",
    Contact2: "Una6@example.com",
    LevelOfEnforcement: "909.31",
    Number: 64543,
    DateOffence: "2021-10-03T17:24:14.478Z",
    RegisterType: "Strategist",
    VicSmart: "VicSmart 95",
    ePlanningCategory: "ePlanningCategory 95",
    ePlanningLodgedBy: "ePlanningLodgedBy 95",
    Comments: "Comments 95",
    ApplicationCategory_Name: "Sports",
    ApplicationCategory_Description: "Sports",
    Code: "90549-3528",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "If we synthesize the card, we can get to the SAS driver through the auxiliary XML driver!",
    PublishDescription:
      "If we hack the panel, we can get to the SMTP application through the bluetooth RSS application!",
    Title: "Fantastic Wooden Pants",
    ConditionCode: "89603",
    Condition: "Inc",
    Name: "Efrain Bailey",
    Multiplier: "Multiplier 95",
    Value: 9482,
    MinimumValue: 89987,
    TargetDays: "TargetDays 95",
    Category_1: "Electronics",
    Category_2: "Industrial",
    ObservationSummary: "ObservationSummary 95",
    ComplianceInstruction: "ComplianceInstruction 95",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-02-17T16:20:19.025Z",
  },
  {
    ID: 96,
    ApplicationNumber: 93188,
    PermitNumber: 48427,
    PermitType: "payment",
    Date_PermitIssued: "2021-04-26T01:41:17.018Z",
    RefNo: 25366,
    AssessmentNo: 57415,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: true,
    Applicant: "Serena Lind",
    Builder: "Lera Little",
    Category: "Handcrafted Plastic Fish",
    BCA: "deposit",
    UseOfBuilding: true,
    ReasonForPermit: "calculate Dynamic lime",
    SiteAddress: "theo.org",
    Locality: "VA",
    Owner: "Pedro Gulgowski V",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-07-05T05:23:10.456Z",
    Date_AllInspComplete: "2021-07-28T19:04:57.565Z",
    Date_DueToCommence: "2021-02-21T08:41:35.721Z",
    Date_Commenced: "2021-12-10T15:38:56.797Z",
    Date_Completed: "Saturday",
    Date_CertificateIssued: "January",
    FileNo: 54237,
    ValuationNo: 52059,
    LegalDescription: "cross-media monetize Isle",
    BuildingOfficer: "Corporate",
    Surveyor: "Home Loan Account",
    SurveyorNumber: "42808253",
    StatDays: 26064,
    Date_Issued: "2021-10-27T22:29:35.373Z",
    Date_ReminderSent: "2021-09-06T07:56:38.937Z",
    PayingFee: 16062,
    CarryingOutWork: false,
    WorkProposed: "payment Gorgeous Rubber Gloves Puerto Rico",
    Address: "Apt. 449",
    BuildingAppNo: 69649,
    BuildingPermitNo: 40368,
    Date_BuildingIssued: "May",
    Date_Commencing: "January",
    BondAmount: 58218,
    BondReceived: "75138",
    BondReturned: 72790,
    Date_FirstInspection: "2021-02-21T03:39:51.476Z",
    Date_LastInspection: "2021-08-23T13:58:44.916Z",
    TargetDate: "2021-06-05T01:56:22.021Z",
    CILFee: 90146,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-03-01T10:13:41.029Z",
    InspectionType: "Outdoors",
    Officer: "Personal Loan Account",
    Compliance: true,
    Reason: "Accounts Handcrafted Steel Chair",
    FacilityRegion: "Iowa",
    Description: "Forward",
    DateTarget: "2021-10-22T19:51:48.078Z",
    Source: "Intranet",
    Location: "NY",
    RequestedBy: "83204359",
    ContactDetails: "472.618.1620 x38691",
    DueStatus: true,
    DateRecorded: "2021-05-19T17:49:50.433Z",
    RecordedBy: "Money Market Account",
    OrgStructure: "Versatile disintermediate portal",
    ActionOfficer: "Shoes",
    Coordinator: "Suite 508",
    DateWork: "2021-07-12T07:41:52.703Z",
    FileNumber: 57776,
    EnquiryRefNo: 39949,
    Event_ReferenceNoAdditional: 30962,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-04-23T23:42:52.974Z",
    ApplicantName: "Investment Account",
    Type: "Group",
    Risk: "Direct Future",
    DateDue: "2021-11-09T02:55:00.780Z",
    Register_Number: 12060,
    Contact: "Apt. 110",
    ReferenceNumber: 64938,
    Date_Complete: "2021-11-13T08:12:58.551Z",
    Date_Construction: "2021-09-11T09:06:36.531Z",
    BarrierStandard: "BarrierStandard 96",
    BarrierType: "BarrierType 96",
    Date_CertificateOfCompliance: "2021-03-08T08:09:34.774Z",
    Pool_RegisteredDate: "2021-01-19T09:42:41.321Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-04-02T03:07:31.888Z",
    Date_WorkIssued: "2021-10-29T22:34:44.795Z",
    Work_CarriedOut: "Designer",
    AmountOS: 81556,
    Flag_RenewalNoticeSent: true,
    Outcome: 67193,
    Regulation: "functionalities Central",
    Date_Due: "2021-02-17T20:18:50.459Z",
    Date_NextInspection: "2021-03-16T22:18:21.039Z",
    Date_Start: "2021-05-04T09:51:28.131Z",
    Date_Occurred: "2021-06-24T19:23:23.369Z",
    ContactName: "Chaya Erdman",
    CreatedDate: "2020-02-04T22:26:55.518Z",
    FeeType: "RD$",
    AmountIssued: 22249,
    AmountOutstanding: 81376,
    CostCode: 9407,
    AmountPaid: 84096,
    ReceiptNumber: 62267,
    ReceiptDate: "2021-07-26T04:09:52.145Z",
    ReceiptedBy: "Producer",
    Method: "Product",
    InvoiceNumber: 77436,
    DebtorNumber: 10313,
    SecurePay_Reference: "LRD",
    Contact2: "Pat_Schulist49@example.org",
    LevelOfEnforcement: "213.86",
    Number: 60388,
    DateOffence: "2021-02-14T07:19:24.170Z",
    RegisterType: "Orchestrator",
    VicSmart: "VicSmart 96",
    ePlanningCategory: "ePlanningCategory 96",
    ePlanningLodgedBy: "ePlanningLodgedBy 96",
    Comments: "Comments 96",
    ApplicationCategory_Name: "Books",
    ApplicationCategory_Description: "Health",
    Code: "06039-8700",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName: "We need to reboot the primary SAS alarm!",
    PublishDescription: "We need to compress the mobile IB card!",
    Title: "deposit",
    ConditionCode: "51591",
    Condition: "LLC",
    Name: "Virginia Mitchell",
    Multiplier: "Multiplier 96",
    Value: 6547,
    MinimumValue: 30888,
    TargetDays: "TargetDays 96",
    Category_1: "Computers",
    Category_2: "Electronics",
    ObservationSummary: "ObservationSummary 96",
    ComplianceInstruction: "ComplianceInstruction 96",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-05-11T21:40:39.422Z",
  },
  {
    ID: 97,
    ApplicationNumber: 31259,
    PermitNumber: 85302,
    PermitType: "deposit",
    Date_PermitIssued: "2021-10-04T10:01:39.500Z",
    RefNo: 65565,
    AssessmentNo: 18420,
    Flag_PrivateCertifier: true,
    IsPrivateCertifier: false,
    Applicant: "Bryana Rempel",
    Builder: "Abbie Strosin",
    Category: "Licensed Granite Chair",
    BCA: "Loaf",
    UseOfBuilding: false,
    ReasonForPermit: "Sports Representative",
    SiteAddress: "gabriella.org",
    Locality: "CT",
    Owner: "Fae Kshlerin",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-07-23T23:25:10.784Z",
    Date_AllInspComplete: "2021-11-18T16:53:54.259Z",
    Date_DueToCommence: "2021-10-24T06:02:09.517Z",
    Date_Commenced: "2021-04-15T15:09:52.677Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "January",
    FileNo: 39211,
    ValuationNo: 58180,
    LegalDescription: "capacity",
    BuildingOfficer: "Senior",
    Surveyor: "Savings Account",
    SurveyorNumber: "73527881",
    StatDays: 70126,
    Date_Issued: "2021-01-05T02:25:17.567Z",
    Date_ReminderSent: "2021-10-04T18:19:56.890Z",
    PayingFee: 37348,
    CarryingOutWork: false,
    WorkProposed: "Health",
    Address: "Apt. 607",
    BuildingAppNo: 19707,
    BuildingPermitNo: 26866,
    Date_BuildingIssued: "June",
    Date_Commencing: "July",
    BondAmount: 71262,
    BondReceived: "02918",
    BondReturned: 21002,
    Date_FirstInspection: "2021-12-15T21:38:12.027Z",
    Date_LastInspection: "2021-12-27T07:51:47.993Z",
    TargetDate: "2021-02-19T12:42:23.662Z",
    CILFee: 45733,
    Flag_CILPaid: false,
    LettersDisplay: false,
    DateInspected: "2021-06-27T23:39:45.302Z",
    InspectionType: "New York",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "Health withdrawal Concrete",
    FacilityRegion: "South Carolina",
    Description: "Senior",
    DateTarget: "2021-05-11T13:07:02.556Z",
    Source: "Optimization",
    Location: "WA",
    RequestedBy: "18762869",
    ContactDetails: "(441) 704-7691 x697",
    DueStatus: true,
    DateRecorded: "2021-07-31T09:18:41.783Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Digitized 24 hour extranet",
    ActionOfficer: "Baby",
    Coordinator: "Apt. 111",
    DateWork: "2021-09-08T14:32:25.002Z",
    FileNumber: 70873,
    EnquiryRefNo: 32608,
    Event_ReferenceNoAdditional: 45790,
    OnBehalfOf: false,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-02-03T11:04:07.599Z",
    ApplicantName: "Credit Card Account",
    Type: "Group",
    Risk: "index stable",
    DateDue: "2021-08-25T09:25:38.308Z",
    Register_Number: 7116,
    Contact: "Apt. 033",
    ReferenceNumber: 74389,
    Date_Complete: "2021-11-02T00:05:46.257Z",
    Date_Construction: "2021-10-28T01:13:05.292Z",
    BarrierStandard: "BarrierStandard 97",
    BarrierType: "BarrierType 97",
    Date_CertificateOfCompliance: "2021-09-15T13:03:03.802Z",
    Pool_RegisteredDate: "2021-12-03T16:05:20.153Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-29T04:32:11.301Z",
    Date_WorkIssued: "2021-10-07T12:04:16.866Z",
    Work_CarriedOut: "Facilitator",
    AmountOS: 75659,
    Flag_RenewalNoticeSent: true,
    Outcome: 93942,
    Regulation: "quantify China",
    Date_Due: "2021-02-02T16:49:10.116Z",
    Date_NextInspection: "2021-06-18T10:30:17.472Z",
    Date_Start: "2021-09-26T19:29:57.024Z",
    Date_Occurred: "2021-09-26T04:34:53.041Z",
    ContactName: "Stella Okuneva",
    CreatedDate: "2020-10-08T08:38:53.273Z",
    FeeType: "฿",
    AmountIssued: 62815,
    AmountOutstanding: 77113,
    CostCode: 29976,
    AmountPaid: 55273,
    ReceiptNumber: 64880,
    ReceiptDate: "2021-01-28T13:44:02.144Z",
    ReceiptedBy: "Technician",
    Method: "Dynamic",
    InvoiceNumber: 45227,
    DebtorNumber: 54936,
    SecurePay_Reference: "ARS",
    Contact2: "Devante.Prohaska97@example.com",
    LevelOfEnforcement: "15.66",
    Number: 37909,
    DateOffence: "2021-12-01T02:12:07.446Z",
    RegisterType: "Technician",
    VicSmart: "VicSmart 97",
    ePlanningCategory: "ePlanningCategory 97",
    ePlanningLodgedBy: "ePlanningLodgedBy 97",
    Comments: "Comments 97",
    ApplicationCategory_Name: "Electronics",
    ApplicationCategory_Description: "Health",
    Code: "95476",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "Use the virtual HTTP monitor, then you can program the 1080p array!",
    PublishDescription: "We need to synthesize the digital SAS transmitter!",
    Title: "SQL Oregon",
    ConditionCode: "27875-4426",
    Condition: "Group",
    Name: "Dr. Neil McClure",
    Multiplier: "Multiplier 97",
    Value: 8288,
    MinimumValue: 3717,
    TargetDays: "TargetDays 97",
    Category_1: "Sports",
    Category_2: "Electronics",
    ObservationSummary: "ObservationSummary 97",
    ComplianceInstruction: "ComplianceInstruction 97",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-12-05T20:42:20.375Z",
  },
  {
    ID: 98,
    ApplicationNumber: 82743,
    PermitNumber: 38801,
    PermitType: "payment",
    Date_PermitIssued: "2021-04-26T10:43:53.106Z",
    RefNo: 65573,
    AssessmentNo: 83296,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Jaquelin Kris Sr.",
    Builder: "Flavie Schroeder",
    Category: "Practical Cotton Shoes",
    BCA: "Intranet",
    UseOfBuilding: true,
    ReasonForPermit: "Realigned Prairie",
    SiteAddress: "paris.com",
    Locality: "NE",
    Owner: "Tressa Green",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-01-22T01:59:36.759Z",
    Date_AllInspComplete: "2021-01-01T06:40:51.637Z",
    Date_DueToCommence: "2020-12-31T22:41:41.381Z",
    Date_Commenced: "2021-07-14T01:32:07.341Z",
    Date_Completed: "Sunday",
    Date_CertificateIssued: "May",
    FileNo: 35236,
    ValuationNo: 47225,
    LegalDescription: "Handmade Cheese Executive",
    BuildingOfficer: "Lead",
    Surveyor: "Investment Account",
    SurveyorNumber: "62559259",
    StatDays: 85384,
    Date_Issued: "2021-08-17T02:25:23.500Z",
    Date_ReminderSent: "2021-02-12T02:53:18.894Z",
    PayingFee: 92821,
    CarryingOutWork: true,
    WorkProposed: "Human Implementation",
    Address: "Suite 911",
    BuildingAppNo: 21546,
    BuildingPermitNo: 70814,
    Date_BuildingIssued: "September",
    Date_Commencing: "January",
    BondAmount: 71207,
    BondReceived: "53880",
    BondReturned: 42222,
    Date_FirstInspection: "2021-06-05T09:21:29.235Z",
    Date_LastInspection: "2021-01-14T20:30:07.484Z",
    TargetDate: "2021-05-30T20:26:50.835Z",
    CILFee: 99567,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-02-14T07:52:28.617Z",
    InspectionType: "Investment Account",
    Officer: "Investment Account",
    Compliance: true,
    Reason: "payment action-items application",
    FacilityRegion: "Texas",
    Description: "International",
    DateTarget: "2021-07-27T15:44:25.378Z",
    Source: "Assurance",
    Location: "CO",
    RequestedBy: "54268779",
    ContactDetails: "(525) 140-1714 x1588",
    DueStatus: true,
    DateRecorded: "2021-05-20T20:19:03.846Z",
    RecordedBy: "Personal Loan Account",
    OrgStructure: "Persevering asynchronous customer loyalty",
    ActionOfficer: "Health",
    Coordinator: "Apt. 726",
    DateWork: "2021-04-08T13:03:25.501Z",
    FileNumber: 50466,
    EnquiryRefNo: 27085,
    Event_ReferenceNoAdditional: 80669,
    OnBehalfOf: false,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: "2021-02-24T00:43:14.418Z",
    ApplicantName: "Auto Loan Account",
    Type: "and Sons",
    Risk: "unleash",
    DateDue: "2021-08-11T19:48:40.987Z",
    Register_Number: 54541,
    Contact: "Suite 861",
    ReferenceNumber: 17078,
    Date_Complete: "2021-05-12T02:44:15.465Z",
    Date_Construction: "2021-06-10T22:23:31.436Z",
    BarrierStandard: "BarrierStandard 98",
    BarrierType: "BarrierType 98",
    Date_CertificateOfCompliance: "2021-07-07T22:14:19.316Z",
    Pool_RegisteredDate: "2021-04-02T03:52:51.803Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-09T06:56:42.326Z",
    Date_WorkIssued: "2021-07-21T11:42:56.724Z",
    Work_CarriedOut: "Officer",
    AmountOS: 14547,
    Flag_RenewalNoticeSent: false,
    Outcome: 83564,
    Regulation: "white well-modulated",
    Date_Due: "2021-12-14T06:46:12.936Z",
    Date_NextInspection: "2021-06-15T22:52:03.892Z",
    Date_Start: "2021-09-15T17:14:08.818Z",
    Date_Occurred: "2021-02-26T03:44:48.400Z",
    ContactName: "Jaqueline Friesen",
    CreatedDate: "2020-11-10T20:48:02.844Z",
    FeeType: "$",
    AmountIssued: 2195,
    AmountOutstanding: 97455,
    CostCode: 46739,
    AmountPaid: 31800,
    ReceiptNumber: 10356,
    ReceiptDate: "2021-07-01T13:12:12.776Z",
    ReceiptedBy: "Manager",
    Method: "Senior",
    InvoiceNumber: 87644,
    DebtorNumber: 35590,
    SecurePay_Reference: "RON",
    Contact2: "Peyton.Walter36@example.org",
    LevelOfEnforcement: "724.33",
    Number: 28091,
    DateOffence: "2021-03-21T15:36:00.585Z",
    RegisterType: "Agent",
    VicSmart: "VicSmart 98",
    ePlanningCategory: "ePlanningCategory 98",
    ePlanningLodgedBy: "ePlanningLodgedBy 98",
    Comments: "Comments 98",
    ApplicationCategory_Name: "Jewelery",
    ApplicationCategory_Description: "Health",
    Code: "40090-5214",
    SortIndex: "true",
    IsPublishToWeb: false,
    PublishName:
      "If we synthesize the panel, we can get to the AI feed through the redundant AI array!",
    PublishDescription:
      "Try to parse the SSL capacitor, maybe it will quantify the wireless alarm!",
    Title: "Swedish Krona Berkshire",
    ConditionCode: "93644-0639",
    Condition: "LLC",
    Name: "Amelia Raynor",
    Multiplier: "Multiplier 98",
    Value: 1441,
    MinimumValue: 70044,
    TargetDays: "TargetDays 98",
    Category_1: "Toys",
    Category_2: "Jewelery",
    ObservationSummary: "ObservationSummary 98",
    ComplianceInstruction: "ComplianceInstruction 98",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-01-02T12:58:16.467Z",
  },
  {
    ID: 99,
    ApplicationNumber: 89715,
    PermitNumber: 20081,
    PermitType: "payment",
    Date_PermitIssued: "2021-02-11T15:43:50.849Z",
    RefNo: 45578,
    AssessmentNo: 27549,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: false,
    Applicant: "Nadia Crist",
    Builder: "Ms. Jacynthe Rutherford",
    Category: "Intelligent Frozen Chair",
    BCA: "programming",
    UseOfBuilding: false,
    ReasonForPermit: "Consultant",
    SiteAddress: "leann.biz",
    Locality: "OR",
    Owner: "Keara Becker",
    Status: "Active",
    ApplicantRequires: false,
    Date_Lodged: "2021-10-10T04:25:32.693Z",
    Date_AllInspComplete: "2021-03-28T22:24:40.387Z",
    Date_DueToCommence: "2021-12-10T10:15:18.312Z",
    Date_Commenced: "2021-12-07T03:31:56.103Z",
    Date_Completed: "Monday",
    Date_CertificateIssued: "April",
    FileNo: 600,
    ValuationNo: 10560,
    LegalDescription: "incubate Bedfordshire",
    BuildingOfficer: "Investor",
    Surveyor: "Checking Account",
    SurveyorNumber: "07792404",
    StatDays: 82552,
    Date_Issued: "2021-12-13T03:43:08.443Z",
    Date_ReminderSent: "2021-09-09T05:24:22.504Z",
    PayingFee: 28982,
    CarryingOutWork: false,
    WorkProposed: "Public-key",
    Address: "Suite 561",
    BuildingAppNo: 59909,
    BuildingPermitNo: 85518,
    Date_BuildingIssued: "October",
    Date_Commencing: "November",
    BondAmount: 14813,
    BondReceived: "98702",
    BondReturned: 92622,
    Date_FirstInspection: "2021-06-14T08:36:43.846Z",
    Date_LastInspection: "2021-11-30T05:47:42.950Z",
    TargetDate: "2021-07-16T13:14:38.153Z",
    CILFee: 33620,
    Flag_CILPaid: true,
    LettersDisplay: false,
    DateInspected: "2021-08-25T17:08:52.277Z",
    InspectionType: "Paradigm",
    Officer: "Credit Card Account",
    Compliance: true,
    Reason: "card Fantastic",
    FacilityRegion: "Wyoming",
    Description: "Corporate",
    DateTarget: "2021-09-30T19:32:31.728Z",
    Source: "Directives",
    Location: "SD",
    RequestedBy: "03746815",
    ContactDetails: "1-016-897-4963 x597",
    DueStatus: true,
    DateRecorded: "2021-12-14T10:05:36.847Z",
    RecordedBy: "Credit Card Account",
    OrgStructure: "Multi-layered multi-tasking local area network",
    ActionOfficer: "Music",
    Coordinator: "Apt. 173",
    DateWork: "2021-12-20T03:52:13.522Z",
    FileNumber: 57988,
    EnquiryRefNo: 97294,
    Event_ReferenceNoAdditional: 37200,
    OnBehalfOf: true,
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: "2021-05-29T20:27:47.736Z",
    ApplicantName: "Savings Account",
    Type: "Group",
    Risk: "Australian Dollar",
    DateDue: "2021-08-16T16:52:48.366Z",
    Register_Number: 88636,
    Contact: "Apt. 913",
    ReferenceNumber: 3717,
    Date_Complete: "2021-01-17T14:58:52.637Z",
    Date_Construction: "2021-01-22T17:47:46.377Z",
    BarrierStandard: "BarrierStandard 99",
    BarrierType: "BarrierType 99",
    Date_CertificateOfCompliance: "2021-03-23T16:12:08.965Z",
    Pool_RegisteredDate: "2021-12-04T16:51:30.619Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-10T14:58:14.222Z",
    Date_WorkIssued: "2021-09-20T13:02:40.185Z",
    Work_CarriedOut: "Manager",
    AmountOS: 26355,
    Flag_RenewalNoticeSent: false,
    Outcome: 40550,
    Regulation: "Designer",
    Date_Due: "2021-08-27T19:16:17.609Z",
    Date_NextInspection: "2021-08-22T00:38:25.780Z",
    Date_Start: "2021-01-07T14:55:00.875Z",
    Date_Occurred: "2021-03-09T05:40:28.434Z",
    ContactName: "Billy McGlynn",
    CreatedDate: "2020-03-10T14:24:00.369Z",
    FeeType: "B/.",
    AmountIssued: 26953,
    AmountOutstanding: 18748,
    CostCode: 18733,
    AmountPaid: 8516,
    ReceiptNumber: 78594,
    ReceiptDate: "2021-01-13T03:31:38.262Z",
    ReceiptedBy: "Designer",
    Method: "Investor",
    InvoiceNumber: 71,
    DebtorNumber: 74282,
    SecurePay_Reference: "CZK",
    Contact2: "Francesca56@example.com",
    LevelOfEnforcement: "91.86",
    Number: 71394,
    DateOffence: "2021-07-05T22:57:17.927Z",
    RegisterType: "Executive",
    VicSmart: "VicSmart 99",
    ePlanningCategory: "ePlanningCategory 99",
    ePlanningLodgedBy: "ePlanningLodgedBy 99",
    Comments: "Comments 99",
    ApplicationCategory_Name: "Games",
    ApplicationCategory_Description: "Home",
    Code: "93006",
    SortIndex: "true",
    IsPublishToWeb: true,
    PublishName:
      "Try to input the XML pixel, maybe it will reboot the cross-platform pixel!",
    PublishDescription:
      "Use the auxiliary USB transmitter, then you can hack the back-end interface!",
    Title: "Checking Account Towels",
    ConditionCode: "79075",
    Condition: "Group",
    Name: "Herman Schulist",
    Multiplier: "Multiplier 99",
    Value: 52070,
    MinimumValue: 52413,
    TargetDays: "TargetDays 99",
    Category_1: "Electronics",
    Category_2: "Movies",
    ObservationSummary: "ObservationSummary 99",
    ComplianceInstruction: "ComplianceInstruction 99",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-10-23T15:21:50.042Z",
  },
  {
    ID: 100,
    ApplicationNumber: 60945,
    PermitNumber: 10560,
    PermitType: "invoice",
    Date_PermitIssued: "2021-10-05T19:31:52.408Z",
    RefNo: 89657,
    AssessmentNo: 44836,
    Flag_PrivateCertifier: false,
    IsPrivateCertifier: true,
    Applicant: "Margarette Daugherty II",
    Builder: "Nora Smith",
    Category: "Handmade Cotton Shoes",
    BCA: "digital",
    UseOfBuilding: false,
    ReasonForPermit: "withdrawal",
    SiteAddress: "geovany.net",
    Locality: "IA",
    Owner: "Rosemary Hahn",
    Status: "Active",
    ApplicantRequires: true,
    Date_Lodged: "2021-05-04T02:46:18.842Z",
    Date_AllInspComplete: "2021-02-18T14:10:22.619Z",
    Date_DueToCommence: "2021-08-20T16:21:26.626Z",
    Date_Commenced: "2021-01-13T02:29:09.099Z",
    Date_Completed: "Tuesday",
    Date_CertificateIssued: "July",
    FileNo: 25580,
    ValuationNo: 75347,
    LegalDescription: "Baby",
    BuildingOfficer: "Global",
    Surveyor: "Credit Card Account",
    SurveyorNumber: "73801713",
    StatDays: 97873,
    Date_Issued: "2021-01-29T17:37:12.612Z",
    Date_ReminderSent: "2021-09-10T23:48:18.712Z",
    PayingFee: 98978,
    CarryingOutWork: false,
    WorkProposed: "Towels Response Concrete",
    Address: "Apt. 914",
    BuildingAppNo: 7316,
    BuildingPermitNo: 52210,
    Date_BuildingIssued: "October",
    Date_Commencing: "January",
    BondAmount: 17659,
    BondReceived: "68871",
    BondReturned: 45332,
    Date_FirstInspection: "2021-04-20T16:46:31.799Z",
    Date_LastInspection: "2021-02-14T05:44:12.613Z",
    TargetDate: "2021-08-12T07:56:03.864Z",
    CILFee: 41257,
    Flag_CILPaid: true,
    LettersDisplay: true,
    DateInspected: "2021-04-01T02:52:03.178Z",
    InspectionType: "Salad",
    Officer: "Investment Account",
    Compliance: false,
    Reason: "wireless Producer envisioneer",
    FacilityRegion: "Mississippi",
    Description: "Dynamic",
    DateTarget: "2021-07-04T02:53:06.826Z",
    Source: "Branding",
    Location: "IN",
    RequestedBy: "45498433",
    ContactDetails: "(096) 974-7193",
    DueStatus: false,
    DateRecorded: "2021-04-20T23:01:10.265Z",
    RecordedBy: "Investment Account",
    OrgStructure: "Streamlined directional leverage",
    ActionOfficer: "Sports",
    Coordinator: "Apt. 278",
    DateWork: "2021-08-05T01:45:57.627Z",
    FileNumber: 61054,
    EnquiryRefNo: 37985,
    Event_ReferenceNoAdditional: 73336,
    OnBehalfOf: true,
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: "2021-05-20T00:48:00.062Z",
    ApplicantName: "Investment Account",
    Type: "Inc",
    Risk: "cyan Cambridgeshire",
    DateDue: "2021-07-24T11:44:10.307Z",
    Register_Number: 10810,
    Contact: "Suite 712",
    ReferenceNumber: 1289,
    Date_Complete: "2021-10-12T22:10:06.680Z",
    Date_Construction: "2021-04-13T10:13:06.227Z",
    BarrierStandard: "BarrierStandard 100",
    BarrierType: "BarrierType 100",
    Date_CertificateOfCompliance: "2021-12-17T16:11:32.650Z",
    Pool_RegisteredDate: "2021-04-21T08:02:30.845Z",
    Date_Lodgement_CertificateOfBarrierNC: "2021-05-03T05:13:16.016Z",
    Date_WorkIssued: "2021-12-01T01:48:08.125Z",
    Work_CarriedOut: "Officer",
    AmountOS: 87019,
    Flag_RenewalNoticeSent: true,
    Outcome: 13550,
    Regulation: "Reduced",
    Date_Due: "2021-02-21T17:36:01.659Z",
    Date_NextInspection: "2021-05-19T12:43:55.937Z",
    Date_Start: "2021-08-19T09:36:08.677Z",
    Date_Occurred: "2021-02-02T21:46:12.366Z",
    ContactName: "Carissa Williamson",
    CreatedDate: "2020-11-21T13:04:21.170Z",
    FeeType: "лв",
    AmountIssued: 86236,
    AmountOutstanding: 70418,
    CostCode: 10269,
    AmountPaid: 56567,
    ReceiptNumber: 64811,
    ReceiptDate: "2021-12-27T15:43:21.143Z",
    ReceiptedBy: "Architect",
    Method: "Dynamic",
    InvoiceNumber: 35561,
    DebtorNumber: 608,
    SecurePay_Reference: "HUF",
    Contact2: "Velva_Sanford62@example.com",
    LevelOfEnforcement: "21.80",
    Number: 97869,
    DateOffence: "2021-10-04T22:14:13.731Z",
    RegisterType: "Facilitator",
    VicSmart: "VicSmart 100",
    ePlanningCategory: "ePlanningCategory 100",
    ePlanningLodgedBy: "ePlanningLodgedBy 100",
    Comments: "Comments 100",
    ApplicationCategory_Name: "Kids",
    ApplicationCategory_Description: "Books",
    Code: "87026",
    SortIndex: "false",
    IsPublishToWeb: true,
    PublishName:
      "You can't synthesize the system without parsing the primary SSL monitor!",
    PublishDescription: "We need to transmit the multi-byte SSL transmitter!",
    Title: "Cambridgeshire deposit",
    ConditionCode: "13478-1297",
    Condition: "and Sons",
    Name: "Vinnie Padberg",
    Multiplier: "Multiplier 100",
    Value: 19986,
    MinimumValue: 25545,
    TargetDays: "TargetDays 100",
    Category_1: "Home",
    Category_2: "Tools",
    ObservationSummary: "ObservationSummary 100",
    ComplianceInstruction: "ComplianceInstruction 100",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
    Date_DueToComplete: "2021-03-04T18:52:52.804Z",
  },
];
