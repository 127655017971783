export enum AmsRoute {
  AMS_PATH = "/ams",

  ASSET_REGISTER_PATH = "/ams/assets/register",
  ASSET_NEW_PATH = "/ams/assets/manage/new", // old   ASSET_NEW_PATH = "/ams/assets/new",
  ASSET_MANAGE_PATH = "/ams/assets/manage/",

  CATEGORY_MANAGE_PATH = "/ams/category/manage",
  ASSESSMENT_MANAGE_PATH = "/ams/assessment/manage",
  KANBAN_MANAGE_PATH = "/ams/kanban/manage",

  COMPUTER_ASSET_REPORT_PATH = "/ams/reports/computerassetreport",
  COMPUTER_WARRANTY_REPORT_PATH = "/ams/reports/computerwarrantyreport",

  WORK_ORDER_REGISTER_PATH = "/ams/workorder/register",
  WORK_ORDER_NEW_PATH = "/ams/workorder/new",
  WORK_ORDER_MANAGE_PATH = "/ams/workorder/manage/",

  ADMIN_WORK_ORDER_CONFIGURATION = "/ams/adminworkorder/register",
  ADMIN_SETTINGS = "/ams/settings",
  ASSET_MANAGE_DETAIL = "/ams/assets/manage",
}
