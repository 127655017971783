import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSAllEventsByOrgStructureBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - All Events`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - All Events - By Org Structure`;
  },
};
