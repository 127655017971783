/**
 * Parse Decimal Number to Array Decimal base on binary
 * @example 265 is 100001001 => [1,1000,100000000] => [1, 8, 256]
 */
export const decimalToArrayDecimal = (num: number) => {
  if (num === 0) return [0];
  let listNum: number[] = [];
  const numStr = num.toString(2);
  for (let i = 0; i < numStr.length; i++) {
    if (numStr[numStr.length - i - 1] === "1") {
      const binaryNum = 1 << i;
      listNum.push(binaryNum);
    }
  }
  return listNum;
};

/**
 * Parse Array Decimal to Decimal Number base on binary
 * @example  [1, 8, 256] => [1,1000,100000000] => 100001001 => 265
 */
export const arrayDecimalToDecimal = (listNum: number[]) => {
  if (listNum.length === 0) return 0;
  return listNum.reduce((p, c) => p | c, 0);
};
