import {
  IAssociationParcel,
  IAttribute,
  IRegistersSummary
} from "@app/products/property/registers/[id]/model";

export const mockRegistersAssociatedParcels: IAssociationParcel[] = [
  {
    ParcelReference: "MGW",
    LegalDescription: "Section MGW",
    Status: "Cemetery_Section",
    ParcelId: 10045,
  },
];
export const mockRegisterSummaryAttributes: IAttribute[] = [
  {
    Attribute: "Account Type",
    Value: "Cremation",
    Id: 1,
  },
  {
    Attribute: "DC Age Text",
    Value: "73",
    Id: 2,
  },
  {
    Attribute: "DC Birth Date",
    Value: "11 Sep 1946 12:00 AM",
    Id: 3,
  },
  {
    Attribute: "DC Death Date",
    Value: "14 May 2019 12:00 AM",
    Id: 4,
  },
  {
    Attribute: "Grant Number",
    Value: "1320",
    Id: 5,
  },
  {
    Attribute: "Grave Reopened",
    Value: "No",
    Id: 6,
  },
  {
    Attribute: "INT Ashes Receipt",
    Value: "26 Aug 2019 12:00 AM",
    Id: 7,
  },
  {
    Attribute: "INT CauseOfDeath",
    Value: "Unknown",
    Id: 8,
  },
  {
    Attribute: "INT Coffin Depth",
    Value: "Ashes",
    Id: 9,
  },
];
export const mockRegisterSummary: IRegistersSummary = {
  AccountName: "L J GILES\r\n14 Silver St\r\nLysterfield VIC 3156",
  AssociatedParcels: mockRegistersAssociatedParcels,
  Attributes: mockRegisterSummaryAttributes,
};
