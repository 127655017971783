import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import { useIsEditable } from "@common/hooks/useIsEditable";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

interface IModifySchemeProps {
  disabled?: boolean;
}
export const ModifySchemeButton = ({ disabled }: IModifySchemeProps) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const schemeId = params.id;
  const isEditable = useIsEditable();

  return (
    <>
      <CCNavButton
        disabled={disabled}
        title="Modify scheme"
        onClick={() => {
          if (!isEditable) {
            history.push(`${PROPERTY_SCHEMES_ROUTE}/${schemeId}?mode=edit`);
            return;
          }
          history.push(`${PROPERTY_SCHEMES_ROUTE}/${schemeId}`);
        }}
      />
    </>
  );
};
