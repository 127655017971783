import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { ExistManageSecurityTemplate } from "@common/pages/settings/security/security-templates/_id/forms/existed/_index";
import { NewSecurityTemplate } from "@common/pages/settings/security/security-templates/_id/forms/new/_index";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { ISecurityTemplateNotificationSection } from "@common/pages/settings/security/security-templates/model";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageSecurityTemplate = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const securityTemplateId = parseInt(params.id);
  const { resetStore, loadSecurityTemplate, setNotificationSection } =
    useSecurityTemplateStore();
  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      setNotificationSection(
        location.state as ISecurityTemplateNotificationSection
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) {
      loadSecurityTemplate(securityTemplateId, isNew);
    }
    // eslint-disable-next-line
  }, [securityTemplateId, isNew, isHasPermission]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.SECURITY_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      {isNew ? <NewSecurityTemplate /> : <ExistManageSecurityTemplate />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageSecurityTemplate);
