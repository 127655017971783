import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { BELL_CHARACTER } from "@common/constants/characters";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsCustomRecordsFieldForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter, onChange } = formRenderProps;
    const maxRows = 10;
    const maxCols = 3;

    const numberOfRows = valueGetter(
      ECorporateSettingsField.TP_Records_CustomExportColumnCount.toString()
    );
    const customRecordFieldArr = valueGetter(
      ECorporateSettingsField.TP_Records_infoXpert_CustomExportFields.toString()
    )
      ?.toString()
      .split(BELL_CHARACTER);
    const customRecordFieldValues = Array.from(
      {
        length: maxRows * maxCols,
      },
      (_, index: number) => customRecordFieldArr?.[index]
    );
    const handleOnClickApply = () => {
      const numberOfRowsDisplayValue = valueGetter(
        ECorporateSettingsField[
          ECorporateSettingsField.TP_Records_CustomExportColumnCount
        ]
      );
      onChange(
        ECorporateSettingsField.TP_Records_CustomExportColumnCount.toString(),
        { value: numberOfRowsDisplayValue }
      );
    };
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={configData["AvailableExportFields"]}
              isEditing={isEditing}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <label className="cc-label">
            {
              configData[
                ECorporateSettingsField.TP_Records_CustomExportColumnCount
              ].Title
            }
          </label>
        </div>
        <div className="custom-form-cols-4">
          <label className="cc-label">&nbsp;</label>
          <div className="cc-field custom-nummeric-minwidth">
            <Field
              name={
                configData[
                  ECorporateSettingsField.TP_Records_CustomExportColumnCount
                ].FieldName
              }
              component={CCNumericTextBox}
              disabled={!isEditing}
              defaultValue={maxRows}
              max={maxRows}
            />
          </div>
          <div className="custom-form-cols-2 align-selfEnd">
            <div className="cc-field custom-button-minwidth">
              <Button
                type="button"
                onClick={handleOnClickApply}
                className="cc-dialog-button"
                disabled={!isEditing}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
        <div className="custom-form-cols-4">
          <label className="cc-label">&nbsp;</label>
          <label className="cc-label">Properties SetName</label>
          <label className="cc-label">Fields</label>
          <label className="cc-label">OO Fields</label>
        </div>
        <div className="custom-form-cols-4">
          {Array.from({ length: numberOfRows }, (_, index: number) => {
            const firstColIndex = index * maxCols;
            return Array.from({ length: maxCols }, (_, colIndex: number) => {
              return (
                <>
                  {colIndex === 0 && (
                    <label className="custom-label">{index + 1}</label>
                  )}
                  <div className="cc-field">
                    <Field
                      name={index.toString()}
                      component={CCInput}
                      value={
                        customRecordFieldValues?.[firstColIndex + colIndex]
                      }
                      onChange={(e: InputChangeEvent) => {
                        customRecordFieldValues[firstColIndex + colIndex] =
                          e.value;
                        onChange(
                          ECorporateSettingsField.TP_Records_infoXpert_CustomExportFields.toString(),
                          {
                            value: customRecordFieldValues.join(BELL_CHARACTER),
                          }
                        );
                      }}
                      disabled={!isEditing}
                    />
                  </div>
                </>
              );
            });
          })}
        </div>
      </section>
    );
  }
);
