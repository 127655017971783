import { colFees } from "@app/core/fees/config";
import { IFee } from "@app/core/fees/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IFeesProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<IFee>();
const Fees: React.FC<IFeesProps> = ({ id, recordType }) => {
  return (
    <CCGrid
      columnFields={colFees}
      primaryField={nameOf("ID")}
      dataUrl={
        id === undefined
          ? undefined
          : `/odata/core/internal/fees/getfees(key=${id}, recordType=${recordType})?$count=true&`
      }
    />
  );
};

export default Fees;
