import { ICON_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const SettingFieldMapping = {
  FirstName: "FirstName",
  LastName: "LastName",
  LoginName: "UserName",
  Email: "Email",
  SecurityTemplates: "SecurityTemplates",
  Active: "IsActive",
  Locked: "IsLocked",
  OnLeave: "OnLeave",
  EXTUser: "IsExternalUser",
  Councillor: "IsCouncillor",
  Title: "Title",
  FieldName: "FieldName",
  DisplayOrder: "DisplayOrder",
  MaxLength: "MaxLength",
  EditGroupId: "EditGroupId",
  DataType: "DataType",
  ItemId: "ItemId",
  SettingCategoryName: "SettingCategoryName",
  IsEditable: "IsEditable",
  Name: "Name",
};

//#region Premise
export const colSettingSiteUsers: IColumnFields[] = [
  {
    field: SettingFieldMapping.LoginName,
    title: "Login Name",
    width: 150,
    locked: true,
    linkTo: (dataItem) => {
      return `/settings/security/managesiteusers/` + dataItem.ID;
    },
  },
  {
    field: SettingFieldMapping.FirstName,
    title: "First Name",
    width: 150,
  },
  {
    field: SettingFieldMapping.LastName,
    title: "Last Name",
    width: 150,
  },
  {
    field: SettingFieldMapping.Email,
    title: "Email",
    width: 150,
  },
  {
    field: SettingFieldMapping.SecurityTemplates,
    title: "Security Templates",
    width: 150,
  },
  {
    field: SettingFieldMapping.Active,
    title: "Active",
    width: 150,
  },
  {
    field: SettingFieldMapping.OnLeave,
    title: "On Leave?",
    width: 150,
  },
  {
    field: SettingFieldMapping.Locked,
    title: "Locked?",
    width: 150,
  },
  {
    field: SettingFieldMapping.EXTUser,
    title: "External User",
    width: 150,
  },
  {
    field: SettingFieldMapping.Councillor,
    title: "Councillor",
    width: 150,
  },
];

export const SettingCategory: IColumnFields[] = [
  {
    field: SettingFieldMapping.ItemId,
    title: "ItemId",
    width: 300,
    primaryKey: "Title",
    dataType: "text",
  },
  {
    field: SettingFieldMapping.Title,
    title: "Title",
    width: 300,
    dataType: "text",
  },
];

export const SettingListIsActiveHasItemID = (
  title: any,
  description: any
): IColumnFields[] => {
  const titleHeader = title ? title : "Title";
  const descriptionHeader = description ? description : "Description";
  return [
    {
      field: SettingFieldMapping.Title,
      title: titleHeader,
      primaryKey: "ItemId",
      dataType: "text",
    },
    {
      field: "Description",
      title: descriptionHeader,
      dataType: "text",
    },
    {
      field: "IsActive",
      title: "Is Active",
      format: ICON_FORMAT.ICON_BOOLEAN,
      dataType: "boolean",
    },
  ];
};

export const SettingListHasItemID = (
  title: any,
  description: any
): IColumnFields[] => {
  const titleHeader = title ? title : "Title";
  const descriptionHeader = description ? description : "Description";
  return [
    {
      field: SettingFieldMapping.Title,
      title: titleHeader,
      primaryKey: "ItemId",
      dataType: "text",
    },
    {
      field: "Description",
      title: descriptionHeader,
      dataType: "text",
    },
  ];
};

export const SettingList = (title: any, description: any): IColumnFields[] => {
  const titleHeader = title ? title : "Title";
  const descriptionHeader = description ? description : "Description";
  return [
    {
      field: SettingFieldMapping.Title,
      title: titleHeader,
      primaryKey: "Title",
      dataType: "text",
    },

    {
      field: "Description",
      title: descriptionHeader,
      dataType: "text",
    },
  ];
};

export const SettingListIsActive = (
  title: any,
  description: any
): IColumnFields[] => {
  const titleHeader = title ? title : "Title";
  const descriptionHeader = description ? description : "Description";
  return [
    {
      field: SettingFieldMapping.Title,
      title: titleHeader,
      primaryKey: "Title",
      dataType: "text",
    },
    {
      field: "Description",
      title: descriptionHeader,
      dataType: "text",
    },
    {
      field: "IsActive",
      title: "Is Active",
      format: ICON_FORMAT.ICON_BOOLEAN,
      dataType: "boolean",
    },
  ];
};

export const settingFields: IColumnFields[] = [
  {
    field: SettingFieldMapping.Title,
    title: "Title",
    width: 300,
    primaryKey: "Title",
    dataType: "text",
  },
  {
    field: SettingFieldMapping.DataType,
    title: "Data Type",
    width: 300,
    dataType: "text",
  },
  {
    field: SettingFieldMapping.DisplayOrder,
    title: "Display Order",
    width: 300,
    dataType: "numeric",
  },
  {
    field: SettingFieldMapping.FieldName,
    title: "Field Name",
    width: 300,
    dataType: "text",
  },
  {
    field: SettingFieldMapping.DataType,
    title: "Data Type",
    width: 300,
    dataType: "text",
  },
  {
    field: SettingFieldMapping.MaxLength,
    title: "Max Length",
    width: 300,
    dataType: "numeric",
  },
  {
    field: SettingFieldMapping.EditGroupId,
    title: "EditGroupId",
    width: 300,
    dataType: "numeric",
  },
];
