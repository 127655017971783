import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { ReactNode, useRef, useState } from "react";
import MapView from "../map/MapView";
import "./ReferenceSidebar.scss";
// @TODO: Remove this component
export interface IReferenceSidebarProps<TData> {
  isNew?: boolean | false;
  data?: TData;
  title?: string;
  isViewMap?: boolean;
  isViewContact?: boolean;
  isViewDetail?: boolean;
  isViewHistory?: boolean;
  isViewAssociations?: boolean;
  nodeReference?: ReactNode;
  listNode?: ISidebarTab[];
  nodeContactsSideBar?: ReactNode;
  nodeJournal?: ReactNode;
  nodeAssociations?: ReactNode;
}
export interface ISidebarTab {
  nodeTitle: string;
  nodeRef: ReactNode;
}

export const ReferenceSidebar = ({
  isNew,
  title,
  isViewMap = true,
  isViewContact = true,
  isViewDetail = true,
  isViewHistory = true,
  isViewAssociations = false,
  nodeReference,
  listNode,
  nodeContactsSideBar,
  nodeJournal,
  nodeAssociations,
}: IReferenceSidebarProps<{}>) => {
  const tabstripRef = useRef<any | null>(null);
  const nextBtnRef = useRef<HTMLSpanElement | null>(null);
  const prevBtnRef = useRef<HTMLSpanElement | null>(null);

  const [selectedTab, setSelectedTab] = useState(0);
  const [referenceData] = useState<any>({
    inspection: {},
    parent: {},
  });

  // useEffect(() => {
  handleTabLeftRightArrowChange();
  // }, [tabstripRef.current]);

  if (!referenceData.inspection || !referenceData.parent) {
    return null;
  }

  function handleTabLeftRightArrowChange() {
    let scrollAmount = 0;
    let step = 100;
    let tabstrip = tabstripRef.current;

    if (tabstrip) {
      let nextBtn = nextBtnRef.current;
      let prevBtn = prevBtnRef.current;
      let headerTab = document.querySelector(".reference-tabstrip ul");
      step = headerTab
        ? (headerTab.scrollWidth - headerTab.clientWidth) / 2 + 1
        : 0;

      if (headerTab && headerTab.scrollWidth - headerTab.clientWidth < 20) {
        if (nextBtn) nextBtn.classList.add("hidden");
        if (prevBtn) prevBtn.classList.add("hidden");
      } else {
        if (nextBtn) nextBtn.classList.remove("hidden");
        if (prevBtn) prevBtn.classList.remove("hidden");
      }

      if (headerTab && headerTab.scrollLeft === 0) {
        if (prevBtn && !prevBtn.classList.contains("hidden"))
          prevBtn.classList.add("hidden");
        headerTab.classList.add("start");
      }

      if (nextBtn && nextBtn.getAttribute("listener") !== "true") {
        var end = headerTab ? headerTab.scrollWidth - headerTab.clientWidth : 0;
        nextBtn.addEventListener("click", function () {
          if (scrollAmount + step < end) scrollAmount += step;
          else scrollAmount = end;
          if (headerTab) {
            headerTab.scroll({
              top: 0,
              left: scrollAmount,
              behavior: "smooth",
            });
          }

          if (scrollAmount > 0) {
            if (prevBtn && prevBtn.classList.contains("hidden"))
              prevBtn.classList.remove("hidden");
          }

          if (scrollAmount >= end) {
            if (nextBtn && !nextBtn.classList.contains("hidden"))
              nextBtn.classList.add("hidden");
          }
        });
        nextBtn.setAttribute("listener", "true");
      }

      if (prevBtn && prevBtn.getAttribute("listener") !== "true") {
        prevBtn.addEventListener("click", function () {
          if (scrollAmount - step > 0) scrollAmount -= step;
          else scrollAmount = 0;
          if (headerTab) {
            headerTab.scroll({
              top: 0,
              left: scrollAmount,
              behavior: "smooth",
            });
          }

          if (scrollAmount === 0) {
            if (prevBtn && !prevBtn.classList.contains("hidden"))
              prevBtn.classList.add("hidden");
          }

          if (scrollAmount < end) {
            if (nextBtn && nextBtn.classList.contains("hidden"))
              nextBtn.classList.remove("hidden");
          }
        });
        prevBtn.setAttribute("listener", "true");
      }
    }
  }

  return (
    <div className="cc-old-reference-sidebar">
      <span ref={nextBtnRef} className="tabstrip-next hidden">
        <i className="fa fa-caret-right"></i>
      </span>
      <span ref={prevBtnRef} className="tabstrip-prev hidden">
        <i className="fa fa-caret-left"></i>
      </span>

      <TabStrip
        ref={tabstripRef}
        className="reference-tabstrip"
        selected={selectedTab}
        onSelect={(e) => {
          setSelectedTab(e.selected);
        }}
      >
        {isViewDetail && (
          <TabStripTab title={"Details"}>{nodeReference}</TabStripTab>
        )}
        {isViewMap && (
          <TabStripTab title="Map">
            <div className="cc-ref-map-container">
              <MapView
                width="100%"
                height="100%"
                coordinate={{
                  lat: -37.91717440420193,
                  lng: 145.15567905157695,
                }}
                zoom={18}
              />
            </div>
          </TabStripTab>
        )}
        {isViewContact && (
          <TabStripTab title="Contacts">{nodeContactsSideBar}</TabStripTab>
        )}
        {isViewAssociations && (
          <TabStripTab title="Associations">{nodeAssociations}</TabStripTab>
        )}
        {!isNew && isViewHistory && (
          <TabStripTab title="History">{nodeJournal}</TabStripTab>
        )}
      </TabStrip>
    </div>
  );
};
