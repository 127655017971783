import { getCategorySettings } from "@app/products/property/system-admin/settings/api";
import {
  SettingField,
  SettingFolder,
  SettingsDataType
} from "@app/products/property/system-admin/settings/model";
import {
  settingGridFieldColumn,
  settingGridFieldValue
} from "@app/products/property/system-admin/settings/util";
import { useSettingConfirmExitStore } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/store";
import { ConfirmExit } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/_index";
import ExistedChildSettingManageForm from "@app/products/property/system-admin/settings/_id/components/dialogs/existed/_index";
import NewChildSettingManageForm from "@app/products/property/system-admin/settings/_id/components/dialogs/new/_index";
import { ICheckMandatoryList } from "@app/products/property/system-admin/settings/_id/model";
import { usePropertySettingManagePageStore } from "@app/products/property/system-admin/settings/_id/store";
import { isSuccessResponse } from "@common/apis/util";
import { getDropdownValue } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import Loading from "@components/loading/Loading";
import { ISettingListOptionLoading } from "@components/setting/setting-list-option/model";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface ISettingListOptionProps {
  name: string;
  data: any;
  label: string;
  isMandatory?: boolean;
  description?: string;
  onChangeCustom: ({ event, fieldsData }: any) => void;
  isEditing?: boolean;
  isEnabledEditButton?: boolean;
  handleOnCancel: any;
  handleOnEdit: any;
  formRenderProps: FormRenderProps;
  isNew: boolean;
  isChild: boolean;
  groupId: number;
  checkMandatoryList: any;
  setCheckMandatoryList: (arg0: ICheckMandatoryList) => void;
}

export const SettingListOption: React.FC<ISettingListOptionProps> = observer(
  ({
    name,
    label,
    isMandatory = false,
    description = "",
    data = [],
    onChangeCustom: onChange,
    isEditing,
    isEnabledEditButton,
    handleOnCancel,
    handleOnEdit,
    formRenderProps,
    isNew,
    isChild,
    checkMandatoryList = {},
    setCheckMandatoryList,
  }) => {
    const filterSelectedValue = (gridData: any, isNew: boolean) => {
      if (!gridData) return [];
      if (isNew) return gridData;
      return gridData.filter((i: any) => i?.IsSelected === true);
    };

    const fieldName = name;
    const { showNotification, emptyNotification } =
      usePropertySettingManagePageStore();
    const { confirmChildExit, setConfirmChildExit } =
      useSettingConfirmExitStore();
    const [dataGrid, setDataGrid] = useState<any>(
      settingGridFieldValue(
        filterSelectedValue(data?.SettingsFolders ?? [], isNew)
      )
    );
    const [gridFieldsData, setGridFieldsData] = useState<any>();
    const [listSelected, setListSelected] = useState<any>([]);
    const [columns, setColumns] = useState<IColumnFields[]>(
      settingGridFieldColumn(data.GridFields)
    );
    const [isEditable, setIsEditable] = useState(false);
    const [templateOneFolderAdding, setTemplateOneFolderAdding] = useState<
      SettingFolder | {}
    >({});
    const [loadingMode, setLoadingMode] = useState<
      ISettingListOptionLoading | undefined
    >();
    const [isShowDialogManageForm, setIsShowDialogManageForm] = useState(false);
    const [isNewDialogManageForm, setIsNewDialogManageForm] = useState(false);
    const [isShowDialogExit, setIsShowDialogExit] = useState(false);
    const [isLoadingEditingMode, setIsLoadingEditingMode] = useState(false);
    const [titleDialog, setTitleDialog] = useState("");
    const [subData, setSubData] = useState<any>({
      data: null,
      isNew: false,
      countLengthData: 0,
    });
    useEffectOnce(() => {
      return () => {
        emptyNotification();
      };
    });

    useEffect(() => {
      if (isEditing) {
        getSelectedList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, dataGrid]);

    const primaryKeyDynamic = useMemo(() => {
      let primaryField = "ItemId";
      const key = dataGrid?.[0]?.IdField;
      if (key) primaryField = key;
      return primaryField;
    }, [dataGrid]);

    /**
     * set IsSelected for List Data
     * @param newData
     * @param selectedList
     * @returns
     */
    const processDataChange = (newData: any, selectedList: any) => {
      const listSelectedId = getSelectedListIds(selectedList);
      newData?.forEach((item: any) => {
        if (listSelectedId.includes(item[primaryKeyDynamic])) {
          item.IsSelected = true;
        } else {
          item.IsSelected = false;
        }
      });
      setDataGrid(newData);
      onChange({ event: newData, fieldsData: gridFieldsData });
    };

    useEffect(() => {
      if (
        showNotification.name &&
        showNotification.name.length > 0 &&
        showNotification.data &&
        name === showNotification.name
      ) {
        if (showNotification.data?.isAdd) {
          setIsNewDialogManageForm(false);
          const dataAfterAdding = showNotification.data.data;
          const ItemId = new Date().getTime() % 1000000;
          const getItem = processStructureItemAddNew(dataAfterAdding);
          const gridFields = processGridFieldsAddNew(dataAfterAdding);
          if (getItem && gridFields) {
            if (templateOneFolderAdding) {
              const record = {
                ...templateOneFolderAdding,
                ...getItem,
                GridFields: gridFields,
                ItemId,
                IsSelected: true,
              };
              setDataGrid((preData: any[]) => [record, ...preData]);
              setListSelected((preSelected: any[]) => {
                const selectedList = preSelected ?? [];
                return [record, ...selectedList];
              });
              processDataChange(
                [record, ...dataGrid],
                [record, ...listSelected]
              );
            }
          }
        } else {
          const dataAfterUpdate = showNotification.data;
          if (!dataAfterUpdate) return;
          const checkId = dataAfterUpdate.ItemId;
          if (!dataGrid) return;
          const getItem = dataAfterUpdate.SettingFields.map(
            (itemField: SettingField) => {
              let valueField = itemField?.Value;
              if (
                itemField.DataType === SettingsDataType.Lookup &&
                !isNil(itemField.Value)
              ) {
                valueField = getDropdownValue(
                  itemField?.Value,
                  itemField?.LookupItems ?? [],
                  "Id"
                )?.Name;
              }
              return {
                [itemField.FieldName]: valueField,
              };
            }
          );
          const newData = dataGrid.map((item: any) => {
            const dataFields = {};
            let gridFields = [];
            // eslint-disable-next-line eqeqeq
            if (item.ItemId == checkId) {
              for (let property in item) {
                if (property?.includes("-")) {
                  const propName = property.split("-")[1];
                  const getValue = getItem.find(
                    (i: any) => Object.keys(i)?.[0] === propName
                  );
                  Object.assign(dataFields, { [property]: getValue[propName] });
                  const field = { Name: property, Value: getValue[propName] };
                  gridFields.push(field);
                }
              }
              return {
                ...item,
                ...dataFields,
                SettingFields: dataAfterUpdate.SettingFields,
                GridFields: gridFields,
              };
            }
            return item;
          });

          if (newData && name === showNotification.name) {
            onChange({ event: newData, fieldsData: gridFieldsData });
            setDataGrid(newData);
            emptyNotification();
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNotification]);

    const handleProcessListInitial = async (editingMode: boolean = false) => {
      if (editingMode) {
        const requestUrl = `/${data.ProductType_Enum}/${data.SettingCategoryName}/${data.Name}/${data.ItemId}`;
        setIsLoadingEditingMode(true);
        const response = await getCategorySettings(requestUrl);
        if (isSuccessResponse(response) && response?.data?.SettingsFolders) {
          const processData = settingGridFieldValue(
            response.data.SettingsFolders
          );
          if (processData && processData.length > 0) {
            setDataGrid(processData);
            setGridFieldsData(response.data.GridFields);
          }
          const cols = settingGridFieldColumn(response.data.GridFields);
          const newColumns = cols.map((itemField: any) => {
            const linkField = data.LinkField;
            if (itemField.field.split("-")[1] !== linkField) return itemField;
            if (itemField?.linkTo) {
              delete itemField.linkTo;
            }
            return {
              ...itemField,
              disableFieldName: "IsEditable",
              handleOnClick: (event: any) => {
                if (event.SettingFields) {
                  setSubData({ data: event, isNew: false });
                  setIsShowDialogManageForm(true);
                  setTitleDialog(event.Title);
                }
              },
            };
          });
          setColumns(newColumns);
        }
        setIsLoadingEditingMode(false);
      } else {
        setListSelected([]);
        setColumns(settingGridFieldColumn(data.GridFields));
        setDataGrid(
          settingGridFieldValue(
            filterSelectedValue(data?.SettingsFolders ?? [], isNew)
          )
        );
      }
      setIsEditable(data.IsEditable);
    };

    useEffect(() => {
      handleProcessListInitial(isEditing);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    /**
     * process structure Item After Adding New
     * @param dataAfterAdding
     * @returns
     */
    const processStructureItemAddNew = (dataAfterAdding: any) => {
      if (dataAfterAdding) {
        return dataAfterAdding.SettingFields.map((itemField: SettingField) => {
          let valueField = itemField?.Value;
          if (
            itemField.DataType === SettingsDataType.Lookup &&
            !isNil(itemField.Value)
          ) {
            valueField = getDropdownValue(
              itemField?.Value,
              itemField?.LookupItems ?? [],
              "Id"
            )?.Name;
          }
          return {
            ["cc-" + itemField.FieldName]: valueField,
          };
        }).reduce(function (result: any, current: any) {
          return Object.assign(result, current);
        }, {});
      }
      return undefined;
    };

    /**
     * process Grid Fields After Adding New
     * @param dataAfterAdding
     * @returns
     */
    const processGridFieldsAddNew = (dataAfterAdding: any) => {
      if (dataAfterAdding) {
        let gridFields = [];
        gridFields = dataAfterAdding.SettingFields.map(
          (itemField: SettingField) => {
            if (!isNil(itemField?.Value)) {
              let valueField = itemField.Value;
              if (
                itemField.DataType === SettingsDataType.Lookup &&
                !isNil(itemField.Value)
              ) {
                valueField = getDropdownValue(
                  itemField?.Value,
                  itemField?.LookupItems ?? [],
                  "Id"
                )?.Name;
              }
              return {
                Name: "cc-" + itemField.FieldName,
                Value: valueField,
              };
            } else {
              return {
                Name: "cc-" + itemField.FieldName,
              };
            }
          }
        );
        if (gridFields.length > 0) {
          return gridFields;
        }
      }
      return undefined;
    };

    /**
     * Handle close dialog when user are editing
     */
    const onCloseDialogChildSettingManageForm = () => {
      if (confirmChildExit) setIsShowDialogExit(true);
      else setIsShowDialogManageForm(false);
    };

    /**
     * Handle close dialog after done successfully
     */
    const onCloseDialogChildAfterSuccess = () => {
      setIsShowDialogManageForm(false);
    };

    /**
     * handle selection change
     * set enable and disable button delete
     * @param event
     */
    const handleSelectionChange = (event: any) => {
      processDataChange(dataGrid, event);
    };

    /**
     * Dialog child setting manage form: step 2
     * Passing all data as props
     */
    const props_Dialog_ChildSettingManageForm = {
      subData,
      titleDialog,
      onCloseDialogChildSettingManageForm,
      onCloseDialogChildAfterSuccess,
      fieldName,
    };

    /**
     * Handle button Add button
     * Call API get structure form
     * set open form
     */
    const handleAdd = async () => {
      const requestUrl = `/${data.ProductType_Enum}/${data.SettingCategoryName}/${data.Name}/-1`;
      setLoadingMode(ISettingListOptionLoading.LoadingGetAdd);
      const response = await getCategorySettings(requestUrl);
      if (isSuccessResponse(response)) {
        if (response?.data?.hasOwnProperty("SettingsFolders")) {
          const field = response?.data?.SettingsFolders[0];
          setGridFieldsData(response?.data?.GridFields);
          setTemplateOneFolderAdding(field);
          data.GridFields = response?.data?.GridFields;
          setSubData({
            data: field,
            isNew: true,
            lastID:
              dataGrid.length > 0 ? dataGrid[dataGrid.length - 1].ItemId : 0,
          });
          setTitleDialog(field.Title);
          setIsShowDialogManageForm(true);
          setIsNewDialogManageForm(true);
        }
      }
      setLoadingMode(undefined);
    };

    /**
     * Get list selected items
     * @returns
     */
    const getSelectedList = () => {
      if (dataGrid && Array.isArray(dataGrid) && dataGrid.length !== 0) {
        const newData = dataGrid.filter(
          (item: any) => item.IsSelected === true
        );
        setListSelected(newData);
        return newData;
      }
    };

    /**
     * Handle delete button
     */
    const handleDelete = () => {
      const getListIds: any = getSelectedListIds(listSelected);
      if (getListIds?.length !== 0) {
        const newData = dataGrid.filter(
          (element: any) => !getListIds.includes(element.ItemId)
        );
        setListSelected([]);
        processDataChange(newData, []);
      }
    };
    /**
     * Get list selected Id
     * @param list
     * @returns
     */
    const getSelectedListIds = (list: any) => {
      if (list && list.length !== 0) {
        return list.map((item: any) => item[primaryKeyDynamic]);
      }
      return [];
    };

    const isDisabledDelete = useMemo(() => {
      return isEditable && isEditing && (isNil(listSelected) || listSelected?.length < 1)
    }, [isEditable, isEditing, listSelected])


    if (isLoadingEditingMode) return <Loading isLoading />;
    return (
      <div className="cc-setting-list-option">
        <div className="cc-list-flex">
          <DescriptionLabel title={label} description={description} />
          {isMandatory && <CCTooltip type="validator" position="right" />}
        </div>
        <div className={checkMandatoryList?.[name] ? "cc-error-mandatory" : ""}>
          <CCGrid
            data={dataGrid}
            itemPerPage={10}
            columnFields={columns}
            primaryField={primaryKeyDynamic}
            selectedRows={listSelected}
            selectableMode={isEditing || isNew ? "multiple" : undefined}
            onSelectionChange={(dataItem) => {
              setListSelected(dataItem);
              handleSelectionChange(dataItem);
            }}
            toolbar={
              isEditable && isEditing ? (
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass={
                      loadingMode === ISettingListOptionLoading.LoadingGetAdd
                        ? "fas fa-spinner fa-spin"
                        : "fas fa-plus"
                    }
                    title="Add"
                    onClick={(event: any) => {
                      event.preventDefault();
                      handleAdd();
                    }}
                    disabled={
                      loadingMode === ISettingListOptionLoading.LoadingGetAdd
                    }
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Delete"
                    disabled={isDisabledDelete}
                    onClick={(event: any) => {
                      event.preventDefault();
                      handleDelete();
                    }}
                  />
                </div>
              ) : undefined
            }
          />
        </div>
        {
          /**
           * Dialog child setting manage form: step 3
           * Show dialog: isShowDialogManageForm
           * Show dialogExit: isShowDialogExit
           */

          isShowDialogManageForm && (
            <>
              {isNewDialogManageForm ? (
                <NewChildSettingManageForm
                  {...props_Dialog_ChildSettingManageForm}
                />
              ) : (
                  <ExistedChildSettingManageForm
                    {...props_Dialog_ChildSettingManageForm}
                  />
                )}
              {isShowDialogExit && (
                <ConfirmExit
                  onClose={() => setIsShowDialogExit(false)}
                  onClick={() => {
                    setIsShowDialogExit(false);
                    setConfirmChildExit(false);
                    setIsShowDialogManageForm(false);
                  }}
                />
              )}
            </>
          )
        }
      </div>
    );
  }
);
