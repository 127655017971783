import {
  EditorTools,
  EditorToolsSettings,
  EditorUtils,
} from "@progress/kendo-react-editor";
import { ColorPickerChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { convertRgbaToRgb } from "./util";
const { ForeColor } = EditorTools;

export const ForeColorCustom = (props: any) => {
  const [foreColorEditor, setForeColorEditor] = useState<string>();

  const colorPickerProps = {
    // default settings
    ...EditorToolsSettings.foreColor.colorPickerProps,
    // custom settings
    onChange: (event: ColorPickerChangeEvent) => {
      const rgbFromRgba = convertRgbaToRgb(event.value);
      setForeColorEditor(rgbFromRgba);
      EditorUtils.applyInlineStyle(props?.view, {
        style: "color",
        value: rgbFromRgba,
      });
    },
    value: foreColorEditor,
  };
  return <ForeColor {...props} colorPickerProps={colorPickerProps} />;
};
