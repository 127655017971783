import { getViewConfigurations } from "@app/products/property/api";
import { AddLandUsesDialog } from "@app/products/property/components/dialogs/add-land-uses-dialog/_index";
import { postSearchLandUseLookup } from "@app/products/property/components/dialogs/add-land-uses-dialog/api";
import { colSelectLandUses } from "@app/products/property/components/dialogs/add-land-uses-dialog/config";
import {
  DTO_LandUse,
  VO_Land_Use,
} from "@app/products/property/components/dialogs/add-land-uses-dialog/model";
import { ViewConfiguration } from "@app/products/property/model";
import { EParcelLoadingType } from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const LandUseFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfVOLandUseGrid = nameOfFactory<VO_Land_Use>();
const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    localNotificationRef,
    options = {
      isReadOnly: false,
      setIsLoadingInStep: () => {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    //get initial data grid
    const currentLandUses: VO_Land_Use[] = getFieldValue("") ?? [];
    const listSelected: VO_Land_Use[] = getFieldValue("SelectedLandUse") ?? [];
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [isShowSelectLandUsesDialog, setIsShowSelectLandUsesDialog] =
      useState<boolean>(false);

    const loadingMode = getFieldValue("_option.Loading");
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [landUseColumns, setLandUseColumns] =
      useState<IColumnFields[]>(colSelectLandUses);

    /**
     * handle delete item
     */
    const handleDelete = () => {
      const selectedIDs = listSelected.map(
        (item: VO_Land_Use) => item.Land_Use_Id
      );
      const newLandUses = currentLandUses.filter(
        (record: VO_Land_Use) => !selectedIDs.includes(record.Land_Use_Id)
      );
      onChange(nameOf(""), { value: newLandUses });
      onChange(nameOf("SelectedLandUse"), { value: [] });
    };

    const handleAddLandUse = async (data: VO_Land_Use[]) => {
      const listLandUseId = data?.map((item: VO_Land_Use) => item.Land_Use_Id);
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Data,
      });
      options?.setIsLoadingInStep(true);
      const responseLandUseLookup = await postSearchLandUseLookup(
        listLandUseId ?? []
      );
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (
        isSuccessResponse(responseLandUseLookup) &&
        responseLandUseLookup?.data
      ) {
        const responseLandUse = responseLandUseLookup?.data?.LandUses ?? [];
        const newData = responseLandUse?.map((item: DTO_LandUse) => item);
        const previousLandUse = currentLandUses?.length
          ? [...currentLandUses]
          : [];
        const landUseData = processCombineData(
          previousLandUse,
          newData,
          "Land_Use_Id"
        );
        onChange(nameOf(""), {
          value: landUseData,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load zone failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Grid,
      });
      options?.setIsLoadingInStep?.(true);
      const response = await getViewConfigurations(
        ViewConfiguration.LandUseLookup
      );
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      options?.setIsLoadingInStep?.(false);
      let errorResponse = undefined;
      if (isSuccessResponse(response) && response?.data) {
        setLandUseColumns(
          processDynamicColumns(
            colSelectLandUses,
            response?.data?.ColumnDefinitions?.Columns
          )
        );
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Load view configuration failed",
        };
      }
      setResponseLoadError(errorResponse);
    };

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    if (loadingMode === EParcelLoadingType.Grid) return <Loading isLoading />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfiguration();
          }}
        />
      );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              isLoading={loadingMode === EParcelLoadingType.Data}
              data={currentLandUses}
              columnFields={landUseColumns ?? []}
              primaryField={nameOfVOLandUseGrid("Land_Use_Id")}
              readOnly={options?.isReadOnly}
              selectableMode="multiple"
              selectedRows={listSelected}
              onSelectionChange={(dataItem: VO_Land_Use[]) => {
                dataItem &&
                  onChange(nameOf("SelectedLandUse"), { value: dataItem });
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Select Zones"
                    onClick={() => setIsShowSelectLandUsesDialog(true)}
                  />
                  <Button
                    className="cc-edit-field-button"
                    iconClass="fa fa-minus"
                    title="Delete"
                    disabled={!listSelected?.length}
                    onClick={() => {
                      setIsShowConfirmDeletion(true);
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
        {isShowSelectLandUsesDialog && (
          <AddLandUsesDialog
            onClose={() => setIsShowSelectLandUsesDialog(false)}
            handleSelectLandUse={(data: VO_Land_Use[]) => {
              handleAddLandUse(data);
              setIsShowSelectLandUsesDialog(false);
            }}
          />
        )}
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete the selected item(s)?"}
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
      </section>
    );
  }
);
