import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const noticeGroupsRoute: ICCRoute = {
  path: "notice-groups",
  name: "Notice groups",
  enum: eMenuAction.Notice_Groups_Folder,
  children: [
    {
      path: "act-housing",
      name: "ACT Housing",
      enum: eMenuAction.Notice_Groups_ACT_Housing,
      component: require("./act-housing/_index").default,
    },
    {
      path: "dha-owns-and-pays-rates",
      name: "DHA - Owns and pays rates",
      enum: eMenuAction.Notice_Groups_DHA_Owns_And_Pays_Rates,
      component: require("./dha-owns-and-pays-rates/_index").default,
    },
    {
      path: "echo-housing",
      name: "ECHO Housing",
      enum: eMenuAction.Notice_Groups_ECHO_Housing,
      component: require("./echo-housing/_index").default,
    },
  ],
};
