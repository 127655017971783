import { VO_Transaction } from "@app/products/property/journals/session-list/[id]/components/child-screens/transactions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";

const nameOf = nameOfFactory<VO_Transaction>();

export const colSessionTransactions: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Session_Number"),
    title: "Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Session_Name"),
    title: "Session Name",
  },
  {
    field: nameOf("Transaction_Type"),
    title: "Type",
  },
  {
    field: nameOf("Transaction_Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Transaction_Receipt_Number"),
    title: "Receipt Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Transaction_DateTime"),
    title: "Transaction Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Transaction_Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  {
    field: nameOf("Transaction_Description"),
    title: "Description",
  },
  {
    field: nameOf("Transaction_Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Transaction_IsProposed"),
    title: "Is Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Transaction_IsCancelled"),
    title: "Is Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Transaction_AutoAllocate"),
    title: "Auto Allocate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Transaction_FinancialYear"),
    title: "Financial Year",
  },
  {
    field: nameOf("Transaction_CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("Transaction_CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Assessment_Property_Address"),
    title: "Assessment Property Address",
  },
  {
    field: nameOf("Assessment_NumberX"),
    title: "Assessment Property Address",
  },
  {
    field: nameOf("Assessment_Ratepayer"),
    title: "Assessment Ratepayer",
  },
  {
    field: nameOf("Supplementary_Id"),
    title: "Supp Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Transaction_Id"),
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
