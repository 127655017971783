import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { useDebounce } from "@common/hooks/useDebounce";
import { colContactRelationshipTypeDialog } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/components/contact-relationship-types-dialog/config";
import { CONTACT_RELATIONSHIP_TYPES_ODATA } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/components/contact-relationship-types-dialog/constant";
import { Svc_Contact_ContactRelationshipType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/forms/communication-settings/components/contact-relationship-types-dialog/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import "./_index.scss";

interface IContactRelationshipTypeDialogProps {
  onClose: () => void;
  handleSubmit: (
    contactRelationshipTypes: Svc_Contact_ContactRelationshipType[]
  ) => void;
}
const nameOf = nameOfFactory<Svc_Contact_ContactRelationshipType>();
export const ContactRelationshipTypeDialog = ({
  onClose,
  handleSubmit,
}: IContactRelationshipTypeDialogProps) => {
  const urlOData = CONTACT_RELATIONSHIP_TYPES_ODATA;
  const [urlODataSearch, setUrlODataSearch] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>([]);

  const debouncedSearch = useDebounce(searchKey, 500);

  useEffect(() => {
    (async () => {
      if (debouncedSearch) {
        setIsSearching(true);
        setUrlODataSearch(
          getUrlSearchInputPicker(
            searchKey,
            nameOf("ContactRelationshipType"),
            urlOData
          )
        );
        setIsSearching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Form
      onSubmitClick={() => {
        handleSubmit(
          gridSelectedRows.map((item) => ({
            Id: item.Id,
            ContactRelationshipType: item.ContactRelationshipType,
          }))
        );
        onClose();
      }}
      render={(formRenderProps: FormRenderProps) => (
        <CCDialog
          titleHeader="Contact Relationship Types"
          maxWidth="60%"
          maxHeight="80%"
          height={660}
          onClose={onClose}
          bodyElement={
            <FormElement className="cc-form">
              <section className="cc-field-group">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Search</label>
                    <Field
                      name="searchKey"
                      component={CCInput}
                      placeholder="Search"
                      onChange={(e: InputChangeEvent) => {
                        setSearchKey(e.value);
                      }}
                    />
                  </div>
                  <div className="cc-field cc-search-field">
                    <CCGrid
                      columnFields={colContactRelationshipTypeDialog}
                      dataUrl={searchKey ? urlODataSearch : urlOData}
                      isLoading={isSearching}
                      primaryField={nameOf("Id")}
                      selectableMode="multiple"
                      selectedRows={gridSelectedRows}
                      onSelectionChange={(dataItems) => {
                        setGridSelectedRows(dataItems);
                      }}
                      itemPerPage={10}
                      isAutoHiddenPager={false}
                    />
                  </div>
                </div>
              </section>
            </FormElement>
          }
          footerElement={
            <div className="cc-dialog-footer-actions-right">
              <Button
                className="cc-dialog-button"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="cc-dialog-button"
                type="submit"
                themeColor="primary"
                disabled={gridSelectedRows.length === 0}
                onClick={formRenderProps.onSubmit}
              >
                Select
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
