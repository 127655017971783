import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const PublicPortalTPSettingsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();
    const { settings } = useCommonCoreStore();

    const addressDefaultState = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AddressDefaultState]
    );

    const fieldPublicPortalEnabled = getConfigDataField(
      ECorporateSettingsField.TP_PortalEnabled
    );
    const fieldPortalURL = getConfigDataField(
      ECorporateSettingsField.TP_EPlanningURL
    );
    const fieldPortalDisplayName = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisplayName
    );
    const fieldObjCombineDocs = getConfigDataField(
      ECorporateSettingsField.TP_ObjectionCombineDocs
    );
    const fieldAnnotateLeftMargin = getConfigDataField(
      ECorporateSettingsField.TP_EPlanningAnnotateLeftMargin
    );
    const fieldEnableePlanningMailMerge = getConfigDataField(
      ECorporateSettingsField.TP_MailMerge_EnableePlanning
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {fieldPublicPortalEnabled && (
            <div className="cc-field">
              <CCLabel
                title={fieldPublicPortalEnabled?.Title ?? ""}
                isMandatory={fieldPublicPortalEnabled.IsMandatory}
              />
              <Field
                name={fieldPublicPortalEnabled?.FieldName}
                component={CCSwitch}
                disabled={!isEditing || addressDefaultState === "NSW"}
                checked={
                  addressDefaultState === "NSW"
                    ? false
                    : formRenderProps?.valueGetter(
                        fieldPublicPortalEnabled?.FieldName
                      )
                }
              />
            </div>
          )}
          {fieldPortalURL &&
            addressDefaultState !== "NSW" &&
            formRenderProps?.valueGetter(
              ECorporateSettingsField.TP_PortalEnabled.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalURL?.Title ?? ""}
                  isMandatory={fieldPortalURL.IsMandatory}
                />
                <Field
                  name={fieldPortalURL?.FieldName}
                  component={CCInput}
                  disabled={!isEditing}
                  validator={
                    fieldPortalURL.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
            )}
          {fieldPortalDisplayName && addressDefaultState !== "NSW" && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalDisplayName?.Title ?? ""}
                isMandatory={fieldPortalDisplayName.IsMandatory}
              />
              <Field
                name={fieldPortalDisplayName?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldPortalDisplayName.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldObjCombineDocs && addressDefaultState !== "NSW" && (
            <SwitchSettings
              data={fieldObjCombineDocs}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          {fieldAnnotateLeftMargin && addressDefaultState !== "NSW" && (
            <div className="cc-field">
              <InputSettings
                data={fieldAnnotateLeftMargin}
                isEditing={isEditing}
                colSpan={2}
              />
            </div>
          )}
          {fieldEnableePlanningMailMerge && addressDefaultState !== "NSW" && (
            <SwitchSettings
              data={fieldEnableePlanningMailMerge}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>
      </section>
    );
  }
);
