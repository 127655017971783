import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";

export const parentPage: IParentSection[] = [];

export const dropdownCouncilConsent: IKeyValuePacket[] = [
  { Key: 1, Value: "Committee" },
  { Key: 2, Value: "Delegate" },
  { Key: 3, Value: "Minister" },
  { Key: 4, Value: "Senior Statutory Planner" },
  { Key: 5, Value: "Tribunal" },
  { Key: 6, Value: "DPC" },
  { Key: 7, Value: "Development Control Unit" },
  { Key: 8, Value: "Sydney Nth Planning Panel(JRPP)" },
  { Key: 9, Value: "Local Planning Panel" },
  { Key: 10, Value: "Land and Environment Court" },
];
