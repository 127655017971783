import { Journal } from "@app/core/journal/_index";
import { useCRMSSytemAdminSubCategoryStore } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const CRMSSystemAdminSubCategoryHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { subCategoryId } = useParams<{ subCategoryId: string }>();
  const { crmsSubCategory } = useCRMSSytemAdminSubCategoryStore();
  const id: number = subCategoryId ? parseInt(subCategoryId) : lastSelectedId;
  return (
    <Journal
      id={id}
      triggersReload={crmsSubCategory}
      recordType={RECORDTYPE.CUSTOMERSERVICE_ServiceStandardSubCategory}
    />
  );
});
