import { apiCoreSaveMailMergeDocument } from "@app/core/components/common/utils";
import { MailMergeSelectDocument } from "@app/core/new-mail-merge/buttons/components/buttons/model";
import { FormAction } from "@app/products/crms/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

export const saveMailMergeDocument = async (
  productType: PRODUCT_TYPE_NUMBER,
  reqBody: MailMergeSelectDocument,
  formAction: FormAction
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreSaveMailMergeDocument(productType, formAction),
      reqBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
