import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import {
  behalfOfProps,
  commentsProps,
  contactPersonProps,
  fileNumberProps,
  isSamePermitAddressProps,
  onBehalfOfProps,
  permitHolderProps,
  postalAddressProps,
} from "@app/products/parking-permits/util";
import { IParkingPermitsFormElementProps } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/_index";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { populateValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { addDays } from "date-fns";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const ParkingPermitsFormDisabled = ({
  formRenderProps,
  typePermit,
  sameAddressData,
}: IParkingPermitsFormElementProps) => {
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const history = useHistory();
  const navigateToContactRelationship = () => {
    history.push(`/managecontactrelationship/${"1300"}`, {
      recordType: "210",
    });
  };

  const getValue = formRenderProps.valueGetter;
  return (
    <>
      {showAddressPopup && (
        <RegisterAddressPopup onClosePopup={() => setShowAddressPopup(false)} />
      )}

      {typePermit === "Individual" ? (
        <>
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">Individual/Org</label>
                <div className="cc-label-field">Individual</div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Permit category</label>
                <div className="cc-label-field">
                  Disabled - Driver/Passenger
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Permit code</label>
                <div className="cc-label-field">1A (Blue)</div>
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Permit holder <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  onClick={navigateToContactRelationship}
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.PermitHolder}
                  component={InputPicker}
                  validator={requiredValidator}
                  defaultValue={
                    sameAddressData && sameAddressData.PermitHolder_DisplayName
                  }
                  {...permitHolderProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Contact</label>
                <div className="cc-label-field">
                  {populateValue(
                    sameAddressData
                      ? sameAddressData.PermitHolder_Email
                      : getValue(ParkingPermitsFieldMapping.ContactPermitHolder)
                  )}
                </div>
              </div>
            </div>
            {/* ROW 2 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">
                  Contact person&nbsp;
                  <Button
                    fillMode="flat"
                    className="cc-parking-permits-owner-button"
                    iconClass="fa fa-chevron-circle-down"
                    onClick={() => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ContactPerson,
                        {
                          value: sameAddressData
                            ? sameAddressData.PermitHolder_DisplayName
                            : getValue(ParkingPermitsFieldMapping.PermitHolder),
                        }
                      );
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ContactNumPerson,
                        {
                          value: sameAddressData
                            ? sameAddressData.PermitHolder_Email
                            : getValue(
                                ParkingPermitsFieldMapping.ContactPermitHolder
                              ),
                        }
                      );
                    }}
                  />
                </label>

                <Field
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.ContactPerson}
                  {...contactPersonProps(formRenderProps)}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Contact</label>
                <div className="cc-label-field">
                  {populateValue(
                    getValue(ParkingPermitsFieldMapping.ContactNumPerson)
                  )}
                </div>
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">On behalf of?</label>
                <Field
                  label="On behalf of?"
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.OnBehalfOf}
                  component={Checkbox}
                  {...onBehalfOfProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label"> &nbsp;</label>
                <Field
                  onClick={navigateToContactRelationship}
                  autoComplete="off"
                  name={"Behalfof"}
                  component={InputPicker}
                  {...behalfOfProps(
                    formRenderProps,
                    !getValue(ParkingPermitsFieldMapping.OnBehalfOf),
                    !getValue(ParkingPermitsFieldMapping.OnBehalfOf)
                  )}
                />
              </div>
            </div>
            {/* ROW 2 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Permit address &nbsp;
                  <Button
                    fillMode="flat"
                    className="cc-parking-permits-owner-button"
                    iconClass="fa fa-chevron-circle-down"
                    onClick={() => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.PermitAddress,
                        {
                          value: getValue(
                            ParkingPermitsFieldMapping.AddressPermitHolder
                          ),
                        }
                      );
                    }}
                  />
                </label>

                <div className="cc-label-field">
                  {populateValue(
                    sameAddressData
                      ? sameAddressData.StreetName
                      : getValue(ParkingPermitsFieldMapping.PermitAddress)
                  )}
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Property number</label>
                <div className="cc-label-field">
                  {populateValue(
                    getValue(ParkingPermitsFieldMapping.PropertyNumber)
                  )}
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Permit area allowed</label>
                <div className="cc-label-field">
                  {populateValue(
                    getValue(ParkingPermitsFieldMapping.PermitAreaAllowed)
                  )}
                </div>
              </div>
            </div>
            {/* ROW 3 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Proof of residency 1{" "}
                  <CCTooltip type="validator" position="right" />
                </label>

                <div className="cc-label-field">
                  {populateValue(
                    getValue(ParkingPermitsFieldMapping.ProofofResidency1)
                  )}
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Proof of residency 2</label>

                <div className="cc-label-field">
                  {populateValue(
                    getValue(ParkingPermitsFieldMapping.ProofofResidency2)
                  )}
                </div>
              </div>
            </div>
            {/* ROW 4 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">File number</label>

                <Field
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.FileNumber}
                  {...fileNumberProps(formRenderProps)}
                  component={CCInput}
                />
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">Same as permit address</label>
                <Field
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.IsSameAsPermitAddress}
                  component={Checkbox}
                  {...isSamePermitAddressProps(formRenderProps, getValue)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Property address?</label>
                <Field
                  label="Property address?"
                  autoComplete="off"
                  name={"IsPropertyAddress"}
                  component={Checkbox}
                />
              </div>
            </div>

            {/* ROW 2 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">On behalf of?</label>
                <Field
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.OnBehalfOf}
                  component={Checkbox}
                  {...onBehalfOfProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">&nbsp;</label>
                <Field
                  onClick={navigateToContactRelationship}
                  autoComplete="off"
                  name={"Behalfof"}
                  component={InputPicker}
                  {...behalfOfProps(
                    formRenderProps,
                    !getValue(ParkingPermitsFieldMapping.OnBehalfOf),
                    !getValue(ParkingPermitsFieldMapping.OnBehalfOf)
                  )}
                />
              </div>
            </div>
            {/* ROW 3 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">Postal address</label>

                <Field
                  onClick={() => setShowAddressPopup(true)}
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.PostalAddress}
                  component={InputPicker}
                  {...postalAddressProps(
                    formRenderProps,
                    getValue,
                    getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress),
                    getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress)
                  )}
                />
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Comments</label>

                <Field
                  name={ParkingPermitsFieldMapping.Comments}
                  component={CCInput}
                  {...commentsProps(formRenderProps)}
                />
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">
                  Permit holder <CCTooltip type="validator" position="right" />
                </label>

                <Field
                  onClick={navigateToContactRelationship}
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.PermitHolder}
                  component={InputPicker}
                  validator={requiredValidator}
                  defaultValue={
                    sameAddressData && sameAddressData.PermitHolder_DisplayName
                  }
                  {...permitHolderProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Contact</label>

                <div className="cc-label-field">
                  {populateValue(
                    sameAddressData
                      ? sameAddressData.PermitHolder_Email
                      : getValue(ParkingPermitsFieldMapping.ContactPermitHolder)
                  )}
                </div>
              </div>
            </div>
            {/* ROW 2 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">
                  Contact person{" "}
                  <Button
                    fillMode="flat"
                    className="cc-parking-permits-owner-button"
                    iconClass="fa fa-chevron-circle-down"
                    onClick={() => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ContactPerson,
                        {
                          value: sameAddressData
                            ? sameAddressData.PermitHolder_DisplayName
                            : getValue(ParkingPermitsFieldMapping.PermitHolder),
                        }
                      );
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ContactNumPerson,
                        {
                          value: sameAddressData
                            ? sameAddressData.PermitHolder_Email
                            : getValue(
                                ParkingPermitsFieldMapping.ContactPermitHolder
                              ),
                        }
                      );
                    }}
                  />
                </label>

                <Field
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.ContactPerson}
                  component={CCInput}
                  {...contactPersonProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Contact</label>
                <div className="cc-label-field">
                  {populateValue(
                    getValue(ParkingPermitsFieldMapping.ContactNumPerson)
                  )}
                </div>
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">Same as permit address</label>
                <Field
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.IsSameAsPermitAddress}
                  component={Checkbox}
                  {...isSamePermitAddressProps(formRenderProps, getValue)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Property address?</label>
                <Field
                  autoComplete="off"
                  name={"IsPropertyAddress"}
                  component={Checkbox}
                />
              </div>
            </div>

            {/* ROW 2 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">On behalf of?</label>
                <Field
                  label="On behalf of?"
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.OnBehalfOf}
                  component={Checkbox}
                  {...onBehalfOfProps(formRenderProps)}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">&nbsp;</label>
                <Field
                  onClick={navigateToContactRelationship}
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.OnBehalfOf}
                  component={InputPicker}
                  {...behalfOfProps(
                    formRenderProps,
                    !getValue(ParkingPermitsFieldMapping.OnBehalfOf),
                    !getValue(ParkingPermitsFieldMapping.OnBehalfOf)
                  )}
                />
              </div>
            </div>
            {/* ROW 3 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">Postal address</label>

                <Field
                  onClick={() => setShowAddressPopup(true)}
                  autoComplete="off"
                  name={ParkingPermitsFieldMapping.PostalAddress}
                  component={InputPicker}
                  {...postalAddressProps(
                    formRenderProps,
                    getValue,
                    getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress),
                    getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress)
                  )}
                />
              </div>
            </div>
          </section>

          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Issue date <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={ParkingPermitsFieldMapping.IssueDate}
                  defaultValue={new Date()}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Issue time</label>

                <Field
                  value={
                    getValue(ParkingPermitsFieldMapping.IssueTime)
                      ? new Date(getValue(ParkingPermitsFieldMapping.IssueTime))
                      : new Date()
                  }
                  name={ParkingPermitsFieldMapping.IssueTime}
                  component={TimePicker}
                />
              </div>
            </div>
            {/* ROW 2 */}
            <div className="cc-form-cols-3 ">
              <div className="cc-field">
                <label className="cc-label">Expiry date</label>

                <Field
                  name={ParkingPermitsFieldMapping.ExpiryDate}
                  format={DATE_FORMAT.DATE_CONTROL}
                  defaultValue={addDays(new Date(), 2)}
                  component={CCDatePicker}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Expiry time</label>

                <Field
                  value={
                    getValue(ParkingPermitsFieldMapping.ExpiryTime)
                      ? new Date(
                          getValue(ParkingPermitsFieldMapping.ExpiryTime)
                        )
                      : new Date()
                  }
                  name={ParkingPermitsFieldMapping.ExpiryTime}
                  component={TimePicker}
                />
              </div>
            </div>
          </section>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* ROW 1 */}
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Comments</label>

                <Field
                  name={ParkingPermitsFieldMapping.Comments}
                  component={CCInput}
                  {...commentsProps(formRenderProps)}
                />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
