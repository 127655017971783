import { PSAR_PlannerDecision_Number } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { formatDateByKendo } from "@common/utils/formatting";

export const putPSARApproveReportAndResponse = async (
  id: number,
  decision: PSAR_PlannerDecision_Number,
  decisionDate: Date
): Promise<APIResponse<PPRApplicationIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/${id}/approved?decisionDate=${formatDateByKendo(
        decisionDate,
        DATE_FORMAT.DATE
      )}&decision=${decision}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPlannerDecisions = async (): Promise<
  APIResponse<IKeyValuePacket[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `/api/tp/ppr/internal/application/psar/decisions`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
