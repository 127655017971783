export const COMMUNICATION_API_ROUTE = "/api/core/internal/communication";
export enum MAILMERGEDATASET {
  HM_PREMISES = 102,
}

export enum ECommunication_Icon {
  BellIcon = "fal fa-bell-slash",
  EmailIcon = "fal fa-envelope",
  PhoneIcon = "fal fa-phone",
  SMSIcon = "fal fa-comment-dots",
  PlusIcon = "fal fa-plus",
  MinusIcon = "fal fa-minus",
}
