import { CoreAPIService } from "@/common/apis/coreAPIService";
import {
  Application,
  PPRApplicationIdentityPacket,
} from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse } from "@common/apis/model";

export const putLodgeApplication = async (
  applicationId: number,
  dateLodged: Date
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/${applicationId}/lodge`,
      JSON.stringify(dateLodged)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putCreateLodgeApplication = async (
  application: Application
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/createandlodge`,
      application
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
