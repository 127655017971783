import { AmsLLSComputerWarrantyReport } from "@app/products/ams/assets/reports/computer-warranty-report/model";
import { AmsUrl } from "@app/products/ams/constants/ams.url";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getComputerWarranty = async (): Promise<
  APIResponse<AmsLLSComputerWarrantyReport | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `${AmsUrl.GET_COMPUTER_WARRANTY_REPORT}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
