import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
export const listFieldsConfig: ECorporateSettingsField[] = [
  ECorporateSettingsField.TP_Menu_Label_PSA,
  ECorporateSettingsField.TP_PSA_LabelFor_ReadytoSendtocouncilForAuth,
  ECorporateSettingsField.TP_PSA_LabelFor_Supported,
  ECorporateSettingsField.TP_PSA_LabelFor_NotSupported,
  ECorporateSettingsField.TP_PSA_LabelFor_Deferred,
  ECorporateSettingsField.TP_PSA_LabelFor_SentMinisterAuth,
  ECorporateSettingsField.TP_PSA_LabelFor_MinisterAuthorised,
  ECorporateSettingsField.TP_PSA_LabelFor_SentMinisterApproval,
  ECorporateSettingsField.TP_PSA_LabelFor_MinisterApproval,
  ECorporateSettingsField.TP_PSA_LabelFor_Menu_Exhibition,
  ECorporateSettingsField.TP_PSA_LabelFor_CouncilDecision,
  ECorporateSettingsField.TP_PSA_LabelFor_CouncilConsideration,
  ECorporateSettingsField.TP_PSA_LabelFor_BtnWithdrawApp,
  ECorporateSettingsField.TP_PSA_LabelFor_FldAppCategory,
];
