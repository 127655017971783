import { apiCoreDocumentsByDocumentIds } from "@app/core/components/common/utils";
import { IDocument } from "@app/core/new-mail-merge/dialogs/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IIdentityPacket } from "@common/models/identityPacket";

export const postLoadDocByIds = async (
  documentIDs: number[]
): Promise<APIResponse<IIdentityPacket<IDocument[]> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<IDocument[]>>(
      apiCoreDocumentsByDocumentIds(),
      documentIDs
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
