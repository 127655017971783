import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import {
  Permit,
  Permit_Status,
  Svc_Permit,
  Svc_Permit_Workflow_ProvideReason,
} from "@app/products/local-laws/[id]/model";
import { postDeactivatePermit } from "@app/products/local-laws/components/toolbar/buttons/deactivate/api";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import {
  ProvideReason,
  ReasonDialogPlaceID,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
interface IDeactivatePermitButtonProps {
  isDisabled?: boolean;
}

export const DeactivatePermitButton = observer(
  ({ isDisabled }: IDeactivatePermitButtonProps) => {
    //#region STORE ========/
    const {
      dataForms,
      isLoadingForm,
      submitFormGetData,
      setMiddlewareSubmitFormOptions,
      updateFormFields,
    } = useFlexibleFormStore();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    //#region STATES ========/
    const [objReasonDeactivate, setObjReasonDeactivate] =
      useState<ProvideReason>();
    const [isShowWorkflowDeactivate, setIsShowWorkflowDeactivate] =
      useState<boolean>(false);
    //#endregion STATES =====/

    //#region DEFINED ========/
    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return permitData?.Status_ENUM === Permit_Status.PermitIssued;
    }, [dataForms]);
    //#endregion DEFINED =====/

    //#region METHOD ========/
    const objDeactivate = () => {
      const objReason = new ProvideReason();
      objReason.Title_Text = "Deactivate Permit";
      objReason.ReasonLabel_Text = "Reason";
      objReason.Flag_ShowDate = false;

      setObjReasonDeactivate(objReason);
    };

    const isOpenDeactivateWfDialog = useMemo(() => {
      setObjReasonDeactivate(undefined);
      if (isShowWorkflowDeactivate) objDeactivate();

      return isShowWorkflowDeactivate;
    }, [isShowWorkflowDeactivate]);

    const handleOnCloseDialog = () => {
      setIsShowWorkflowDeactivate(false);
      setObjReasonDeactivate(undefined);
    };

    const handleWorkflowDeactivate = async (provideReason: ProvideReason) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const generalForm = await submitFormGetData("GeneralForm");
      let permit: Permit = generalForm?.Permit;

      if (permit) {
        permit.TypeOfDescription = permit.TypeOfDescription ?? "";
        permit.ReferenceNumber = permit.ReferenceNumber ?? "";
        permit.FileNumber = permit.FileNumber ?? "";
        permit.InsurancePolicyNumber = permit.InsurancePolicyNumber ?? "";
        permit.Description = permit.Description ?? "";

        const responseRequest = {
          Permit: permit,
          ProvideReason: provideReason,
        } as Svc_Permit_Workflow_ProvideReason;

        await deactivatePermitButtonSlider.fetchApi({
          initialData: responseRequest,
        });
      }
    };

    const deactivatePermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const deactivatePermitRequest: Svc_Permit_Workflow_ProvideReason =
              initialData;
            return postDeactivatePermit(deactivatePermitRequest, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IIdentityPacket<Permit>> = dataFromApi;
            if (response?.data?.ReturnObj) {
              const permitFormData = response?.data?.ReturnObj as Permit;
              //#region save data to store ========/
              updateFormFields("GeneralForm", {
                Permit: permitFormData,
              } as Svc_Permit);

              setIsShowWorkflowDeactivate(false);
              setObjReasonDeactivate(undefined);

              eventEmitter.emit(CCJournalEventType.RefreshData);
              eventEmitter.emit(TabTableEventType.RefreshData);

              pushNotificationPortal({
                title: "Permit deactivated successfully.",
                type: "success",
              });
            } else {
              pushNotificationPortal({
                placeId: ReasonDialogPlaceID,
                title: "Deactivated permit failed.",
                type: "error",
                autoClose: false,
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              placeId: ReasonDialogPlaceID,
              title: "Deactivated permit failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataForm = await submitFormGetData("GeneralForm");
      if (!isNil(dataForm?.Permit)) {
        setIsShowWorkflowDeactivate(true);
      }
    };
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Deactivate Permit"
          invisible={!isVisible}
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />

        {isOpenDeactivateWfDialog && (
          <ReasonDialog
            onClose={handleOnCloseDialog}
            onSubmit={(value: ProvideReason) => handleWorkflowDeactivate(value)}
            isLoading={deactivatePermitButtonSlider.isFetching}
            initialData={objReasonDeactivate}
          />
        )}
      </>
    );
  }
);
