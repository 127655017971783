import { ContactRegisterForm } from "@common/pages/contacts/manage-contact/model";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import ContactFormElement from "./components/contact-form-element/_index";
import { contactFormValidator } from "./util";

export interface IContactFormProps {
  contactInfo?: ContactRegisterForm;
  setIsLoading: (status: boolean) => void;
}

export const ContactForm = ({
  contactInfo,
  setIsLoading,
}: IContactFormProps) => {
  const handleSubmit = async (data: any) => {
    setIsLoading(true);

    setIsLoading(false);
  };

  // Check if data is existed in Update Mode
  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={contactInfo}
        validator={contactFormValidator}
        render={(formRenderProps: FormRenderProps) => (
          <ContactFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
};
