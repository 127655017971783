import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { VO_Certificate_Type } from "@app/products/property/certificates/by-type/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Certificate_Type>();
export const columnsCertificateByType: IColumnFields[] = [
  {
    field: nameOf("Cert_Number"),
    title: "Certificate Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_CERTIFICATE_ROUTE}/${dataItem?.Certificate_Id}`,
  },
  {
    field: nameOf("Certificate_Type_Name"),
    title: "Certificate Type",
  },
  {
    field: nameOf("Cert_Applicant_Name"),
    title: "Applicant",
  },
  {
    field: nameOf("Cert_Applicant_Reference"),
    title: "Applicant Reference",
  },
  {
    field: nameOf("Property_Reference"),
    title: "Property Referencer",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Property_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Cert_Description"),
    title: "Description",
  },
  {
    field: nameOf("Cert_Originated_DateTime"),
    title: "Originated On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Cert_Is_Urgent"),
    title: "Is Urgent",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Expected_Completion_Days"),
    title: "Expected Completion Date",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Cert_Required_By_DateTime"),
    title: "Required By",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Cert_Issued_DateTime"),
    title: "Issued On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Cert_Issued_By"),
    title: "Issued By",
  },
  {
    field: nameOf("Elapsed_Days"),
    title: "Elapsed Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Cert_Balance_Owing"),
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Certificate_Status_Name"),
    title: "Certificate Status",
  },
  {
    field: nameOf("Certificate_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
