import { useBuildingRegistersEssentialSafetyStore } from "@app/products/building/registers/essential-safety/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { BuildingRegisterEssentialSafetyFormElement } from "./components/form-element/_index";

export const BuildingRegisterEssentialSafetyForm = observer(
  (): ReactElement => {
    const isNew = useIsNew();
    const {
      buildingRegistersEssentialSafety,
      createBuildingEssentialSafety,
      updateBuildingEssentialSafety,
    } = useBuildingRegistersEssentialSafetyStore();

    const handleSubmit = (data: any) => {
      if (isNew) {
        createBuildingEssentialSafety(data);
      } else {
        updateBuildingEssentialSafety(data);
      }
    };

    // Check if data is existed in Update Mode
    return (
      <div className="cc-form">
        <Form
          onSubmit={handleSubmit}
          initialValues={buildingRegistersEssentialSafety}
          render={(formRenderProps: FormRenderProps) => (
            <BuildingRegisterEssentialSafetyFormElement
              formRenderProps={formRenderProps}
            />
          )}
        />
      </div>
    );
  }
);
