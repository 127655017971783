import { ENFORCEMENTS_MANAGE_ROUTE } from "@app/products/building/registers/enforcements/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewEnforcementButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Enforcement"
      onClick={() => {
        history.push(`${ENFORCEMENTS_MANAGE_ROUTE}/new`);
      }}
    />
  );
};
