import { useCslStore } from "@app/products/csl/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedCSL } from "./components/forms/existed/_index";
import { NewCSL } from "./components/forms/new/_index";

const ManageCSL = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadCsl, resetStore } = useCslStore();

  useEffectOnce(() => {
    loadCsl(id, RECORDTYPE.CSM_Licence);
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewCSL />;
  }

  return <ExistedCSL />;
});
export default ManageCSL;
