import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colFieldBalance: IColumnFields[] = [
  {
    field: "CurrentLevelName",
    title: "Default",
    format: undefined,
  },
  {
    field: "_Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "_InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "_Total",
    title: "Total",
    width: 150,
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];

export const colCharges: IColumnFields[] = [
  {
    field: "DueDate",
    title: "Due Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "EffectiveDate",
    title: "Effective Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "TotalBalance",
    title: "Total Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Reference",
    title: "Reference",
  },
  {
    field: "RatingPeriod",
    title: "Rating Period",
  },
  {
    field: "ChargeName",
    title: "Name",
  },
  {
    field: "InstalmentNumber",
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "InstalmentPlanName",
    title: "Instalment Plan",
  },
  {
    field: "LevyName",
    title: "Levy",
  },
  {
    field: "FinancialArea",
    title: "Financial Area",
  },
  {
    field: "InstalmentAmount",
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "BalanceStatus",
    title: "Balance Status",
  },
];
