import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { isNil } from "lodash";
export const validateBSB = (value: any) => {
  const regexBSb = new RegExp(/^\d{3}-\d{3}$/);
  if (isNil(value) || value?.length === 0) return "This field is required.";
  return !isNil(value) && value?.length && regexBSb.test(value)
    ? undefined
    : "BSB must be 6 digits.";
};

export const bankCodeValidator = (value: any) =>
  stringLengthValidator(value, 1, 10);
export const accountNameValidator = (value: any) =>
  stringLengthValidator(value, 1, 50);
export const accountNumberValidator = (value: any) =>
  stringLengthValidator(value, 1, 20);
export const securityNameValidator = (value: any) =>
  stringLengthValidator(value, 0, 20);
export const securityNumberValidator = (value: any) =>
  stringLengthValidator(value, 0, 10);
