import { AddMeetingDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/add-meeting/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { Action, IParentActionSection } from "@common/pages/actions/model";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

interface IAddMeetingButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  data?: Action;
}

export const AddMeetingButton = observer(
  ({ id, recordType, data }: IAddMeetingButtonProps) => {
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const history = useHistory();

    const handleSubmit = (actionType: IRespActionType) => {
      setIsShowDialog(false);
      history.push(`${ACTIONS_ROUTE}/new`, {
        parent: {
          id: id,
          recordType: recordType,
          data: {
            ActionOfficer_ID: data?.ActionOfficer_ID,
            ActionOfficer: data?.ActionOfficer,
          } as Action,
          actionType: actionType,
        } as IParentActionSection,
      });
    };

    return (
      <>
        <CCNavButton
          title="Meeting"
          onClick={() => {
            setIsShowDialog(true);
          }}
        />
        {isShowDialog && (
          <AddMeetingDialog
            onClose={() => {
              setIsShowDialog(false);
            }}
            onSubmit={handleSubmit}
          />
        )}
      </>
    );
  }
);
