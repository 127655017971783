import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { threeY0KinderRoute } from "./3yo-kinder/route";
import { fourY0KinderRoute } from "./4yo-kinder/route";
import { allocationsRoute } from "./allocations/route";
import { applicationsRoute } from "./applications/route";
import { centreBasedCareRoute } from "./centre-based-care/route";
import { centresRoute } from "./centres/route";
import { communicationRoute } from "./communication/route";
import { exportsRoute } from "./exports/route";
import { feesRoute } from "./fees/route";
import { homeBasedCareRoute } from "./home-based-care/route";
import { infoWorkflowRoute } from "./info-workflow/route";
import { portalRoute } from "./portal/route";
import { priorityOfAccessRoute } from "./priority-of-access/route";
import { systemAdminRoute } from "./system-admin/route";
import { userRoute } from "./user/route";

export const crsRoute: ICCRoute = {
  path: "crs",
  name: "Crs",
  enum: eProductType.CRS,
  children: [
    applicationsRoute,
    infoWorkflowRoute,
    centreBasedCareRoute,
    homeBasedCareRoute,
    threeY0KinderRoute,
    fourY0KinderRoute,
    centresRoute,
    feesRoute,
    allocationsRoute,
    communicationRoute,
    exportsRoute,
    portalRoute,
    priorityOfAccessRoute,
    systemAdminRoute,
    userRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
