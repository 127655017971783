import { IPPRActions } from "@app/products/town-planning/ppr/actions/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { actionLaunchRoute } from "@common/pages/actions/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPPRActions>();

export const colPPRActions: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "Reference Number",
    linkTo: (dataItem: IPPRActions) =>
      `${actionLaunchRoute(dataItem.FormIdentifier_ENUM)}/${dataItem.ID}`,
    locked: true,
    ignoreGlobalFilter: true,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("RecordedDate"),
    title: "Recorded On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Officer"),
    title: "Action By",
  },
  {
    field: nameOf("Description"),
    title: "Details",
  },
  {
    field: nameOf("DueDate"),
    title: "Due On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("CompletedDate"),
    title: "Completed Day",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
  {
    field: nameOf("ParentDescription"),
    title: "Parent Description",
  },
];

export const colPPRActionsWithTagCol: IColumnFields[] = [
  ...colPPRActions,
  {
    field: nameOf("Tag"),
    title: "Tags",
  },
];
