import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { getCommunicationQueueTabDetailsById } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/api";
import { CommunicationQueueDetailsTabEventType } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/config";
import { Svc_CommunicationQueueTabDetails } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/model";
import { useCommunicationQueueStore } from "@common/pages/settings/communication/queue/_id/store";
import {
  formatBooleanValue,
  formatDateByKendo,
} from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const CommunicationQueueTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { communicationQueue, communicationQueueId } =
    useCommunicationQueueStore();
  const [isLoading, setIsLoading] = useState(false);
  const [
    communicationQueueTabDetailsInfo,
    setCommunicationQueueTabDetailsInfo,
  ] = useState<Svc_CommunicationQueueTabDetails>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const communicationQueueID = communicationQueueId ?? lastSelectedRow?.ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getCommunicationQueueTabDetailsById(
      communicationQueueID
    );
    if (isSuccessResponse(response)) {
      setCommunicationQueueTabDetailsInfo(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useAddUniqueEventEmitter([
    {
      eventType: CommunicationQueueDetailsTabEventType.RefreshData,
      listener: () => {
        loadDetailData();
      },
    },
  ]);

  useEffect(() => {
    if (!communicationQueueID) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [communicationQueue, communicationQueueID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Communication Type:"}
          value={communicationQueueTabDetailsInfo?.CommunicationTypeName}
        />
        <ProductReferenceRow
          title={"Date Queued:"}
          value={formatDateByKendo(
            communicationQueueTabDetailsInfo?.DateQueued
          )}
        />
        <ProductReferenceRow
          title={"Create Date:"}
          value={formatDateByKendo(
            communicationQueueTabDetailsInfo?.CreatedDate
          )}
        />
        <ProductReferenceRow
          title={"Send:"}
          value={formatBooleanValue(
            communicationQueueTabDetailsInfo?.CommunciationSend,
            BOOLEAN_FORMAT.BOOLEAN
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
