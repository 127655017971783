import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const ApplicationAdvertisingForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    if (!configData) return <></>;

    const commonNumericTextComponent = (enumParam: ECorporateSettingsField) => {
      return (
        configData[enumParam.toString()] && (
          <div className="cc-field">
            <CCLabel
              title={configData[enumParam.toString()].Title ?? ""}
              isMandatory={configData[enumParam.toString()].IsMandatory}
            />
            <Field
              name={configData[enumParam.toString()].FieldName}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER1}
              disabled={!isEditing}
              validator={
                configData[enumParam.toString()].IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )
      );
    };

    const commonCurrencyComponent = (enumParam: ECorporateSettingsField) => {
      return (
        configData[enumParam.toString()] && (
          <div className="cc-field">
            <CCLabel
              title={configData[enumParam.toString()].Title ?? ""}
              isMandatory={configData[enumParam.toString()].IsMandatory}
            />
            <Field
              name={configData[enumParam.toString()].FieldName}
              component={CCCurrencyInput}
              disabled={!isEditing}
              validator={
                configData[enumParam.toString()].IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {commonCurrencyComponent(
            ECorporateSettingsField.TP_AdvertisingFlatRate
          )}
          {commonNumericTextComponent(
            ECorporateSettingsField.TP_AdvertisingFlatRateIncludesLetters
          )}
          {commonNumericTextComponent(
            ECorporateSettingsField.TP_AdvertisingFlatRateIncludesSigns
          )}
          {commonCurrencyComponent(
            ECorporateSettingsField.TP_AdvertisingAdditionalLetters
          )}
          {commonCurrencyComponent(
            ECorporateSettingsField.TP_AdvertisingAdditionalSigns
          )}
          {commonCurrencyComponent(
            ECorporateSettingsField.TP_AdvertisingPublishing
          )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_EnableAdvertisingFee}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
