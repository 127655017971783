import { ReturnMessage } from "@app/products/property/system-admin/document-template/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postCancelSession = async (
  sessionNumber: number
): Promise<APIResponse<ReturnMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/session/${sessionNumber}/cancel`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const putUpdateSession = async (
  sessionNumberList: number[]
): Promise<APIResponse<ReturnMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/property/int/session/JournalsUpdateSession`,
      sessionNumberList
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
