import {
  URL_SEARCH_BY_ASSESSMENT_ID,
  URL_SEARCH_BY_ASSESSMENT_NUMBER,
  URL_SEARCH_BY_ASSESSMENT_REFERENCE,
  URL_SEARCH_BY_HOLDING_NAME,
  URL_SEARCH_BY_VALUATION_NUMBER_FORMATTED,
} from "@app/products/property/components/action-bar/dialog/add-assessment/constant";
import {
  IAddAssessmentDataForm,
  ISearchBy,
  eSearchAssessmentBy,
} from "@app/products/property/components/action-bar/dialog/add-assessment/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

const { AssessmentId, ValuationNumber } = eSearchAssessmentBy;

export const searchByData: ISearchBy[] = [
  {
    Key: AssessmentId,
    Value: "Assessment ID",
  },
  {
    Key: ValuationNumber,
    Value: "Valuation number",
  },
];

export const defaultAddAssessment: IAddAssessmentDataForm = {
  _option: {
    SearchBy: {
      Data: searchByData,
      Value: searchByData[0],
    },
    Assessment: {
      Data: [],
      Loading: false,
    },
  },
};

export const getUrlApiSearchBy = {
  [eSearchAssessmentBy.AssessmentId]: URL_SEARCH_BY_ASSESSMENT_ID,
  [eSearchAssessmentBy.AssessmentNumber]: URL_SEARCH_BY_ASSESSMENT_NUMBER,
  [eSearchAssessmentBy.ValuationNumber]:
    URL_SEARCH_BY_VALUATION_NUMBER_FORMATTED,
  [eSearchAssessmentBy.AssessmentReference]: URL_SEARCH_BY_ASSESSMENT_REFERENCE,
  [eSearchAssessmentBy.HoldingName]: URL_SEARCH_BY_HOLDING_NAME,
};

export const searchConfig = {
  typeSpeed: 500,
  numberLetterSearch: 1,
};

export const regCheckDecimalNumber = /^[0-9-]+\.([0-9]+|)$|^[0-9]+$/g;
export const regCheckIntegerNumber = /^[0-9]*$/g;
export const regCheckFormatReference = /(-|)[0-9-]+-([0-9]+|)$|^(-|)[0-9]+$/g;

export const getOptionRegex = {
  [eSearchAssessmentBy.AssessmentId]: regCheckIntegerNumber,
  [eSearchAssessmentBy.AssessmentNumber]: regCheckDecimalNumber,
  [eSearchAssessmentBy.ValuationNumber]: regCheckDecimalNumber,
  [eSearchAssessmentBy.AssessmentReference]: regCheckFormatReference,
  [eSearchAssessmentBy.HoldingName]: /.*/g,
};

export const defaultWorkflowHeader: DTO_WorkflowHeader = {
  WorkflowDraft: {
    Workflow_Draft_Id: 0,
    WD_Workflow_Status: 2,
  },
  WorkflowApprovals: [],
  AvailableSecondaryWorkflows: [],
};
