import { DTO_Title } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/components/form-elements/associations-parcel/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import { DTO_PIC } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { NumericTextBoxCustomCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/custom-cell/numeric-text-box-custom-cell/_index";
import React from "react";

const nameOfPIC = nameOfFactory<DTO_PIC>();
export const colPICAmalgamate: IColumnFields[] = [
  {
    field: nameOfPIC("PIC_Number"),
    title: "PIC",
  },
  {
    field: nameOfPIC("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfPIC("Trading_Name"),
    title: "Trading Name",
  },
  {
    field: nameOfPIC("Pic_Manager"),
    title: "Pic Manager",
  },
  { field: nameOfPIC("PIC_Status"), title: "PIC Manager Status" },
  { field: nameOfPIC("District"), title: "District" },
  { field: nameOfPIC("RLP_Board"), title: "RLP Board" },
  {
    field: nameOfPIC("PIC_Id"),
    title: "PIC ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfTitle = nameOfFactory<DTO_Title>();
export const colFoliosAssessment: IColumnFields[] = [
  {
    field: nameOfTitle("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("Status"),
    title: "Status",
  },
  {
    field: nameOfTitle("Lot"),
    title: "Lot",
  },
  {
    field: nameOfTitle("AssociationFactorPercentage"),
    title: "Factor %",
    format: NUMBER_FORMAT.NUMBER2,
    editable: true,
    editCell: (props: ICustomEditCell) => (
      <NumericTextBoxCustomCell {...props} format={NUMBER_FORMAT.NUMBER2} />
    ),
  },
  {
    field: nameOfTitle("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfTitle("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOfTitle("Section"),
    title: "Section",
  },
  {
    field: nameOfTitle("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOfTitle("Volume"),
    title: "Volume",
  },
  {
    field: nameOfTitle("Folio"),
    title: "Folio",
  },
  {
    field: nameOfTitle("County"),
    title: "County",
  },
  {
    field: nameOfTitle("Parish"),
    title: "Parish",
  },
  {
    field: nameOfTitle("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitle("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfTitle("LandArea"),
    title: "Land Area (m2)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfTitle("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOfTitle("TitleId"),
    title: "Lot ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
