import { odataCoreGetDocuments } from "@app/core/components/common/utils";
import { colCrmsDocument } from "@app/products/crms/components/crms-documents/config";
import { ICRMSDocument } from "@app/products/crms/components/crms-documents/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface ICRMSDocumentsProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<ICRMSDocument>();
const CRMSDocuments: React.FC<ICRMSDocumentsProps> = ({ id, recordType }) => {
  return (
    <div>
      <CCGrid
        columnFields={colCrmsDocument}
        primaryField={nameOf("ID")}
        dataUrl={
          id === undefined ? undefined : odataCoreGetDocuments(id, recordType)
        }
      />
    </div>
  );
};

export default CRMSDocuments;
