import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSSystemAdminHBCCompatScore: IColumnFields[] = [
  {
    field: CRSFieldMapping.WeightingPriority_Name,
    title: "Priority",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: CRSFieldMapping.WeightingPriority_Score,
    title: "Score",
  },
];
