import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCColorPicker } from "@components/cc-color-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IRegisterStyleSettingsFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegisterStyleSettingsForm = observer(
  ({ formRenderProps }: IRegisterStyleSettingsFormProps) => {
    const { valueGetter } = formRenderProps;
    const { configData = {} } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    //#region  fields
    const fieldFlagFreezePanes =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_Flag_FreezePanes.toString()
      ];
    const fieldHeaderRowTextColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_HeaderRowTextColor.toString()
      ];
    const fieldHeaderRowCellColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_HeaderRowCellColor.toString()
      ];
    const fieldHeaderColumnTextColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_HeaderColumnTextColor.toString()
      ];
    const fieldHeaderColumnCellColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_HeaderColumnCellColor.toString()
      ];
    const fieldCellTextColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_CellTextColor.toString()
      ];
    const fieldCellColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_CellColor.toString()
      ];
    const fieldCellColorAlt =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_CellColorAlt.toString()
      ];
    const fieldTopBorderColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_TopBorderColor.toString()
      ];
    const fieldBottomBorderColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_BottomBorderColor.toString()
      ];
    const fieldLeftBorderColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_LeftBorderColor.toString()
      ];
    const fieldRightBorderColor =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_RightBorderColor.toString()
      ];
    //#endregion

    const isFlagFreezePanes = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_Export_Flag_FreezePanes.toString()
    );

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={fieldFlagFreezePanes?.Title ?? ""} />
              <Field
                name={fieldFlagFreezePanes?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={isFlagFreezePanes}
              />
            </div>
          </div>
          {isFlagFreezePanes && (
            <div className="cc-custom-sub-panel-bar">
              <CCPanelsBar
                mode="multiple"
                initialPanels={[
                  {
                    title: "First Row",
                    isKeepMounted: true,
                    isExpanded: true,
                    component: (
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <CCLabel
                            title={fieldHeaderRowTextColor?.Title ?? ""}
                          />
                          <Field
                            name={fieldHeaderRowTextColor?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel
                            title={fieldHeaderRowCellColor?.Title ?? ""}
                          />
                          <Field
                            name={fieldHeaderRowCellColor?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "First Column",
                    isKeepMounted: true,
                    isExpanded: true,
                    component: (
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <CCLabel
                            title={fieldHeaderColumnTextColor?.Title ?? ""}
                          />
                          <Field
                            name={fieldHeaderColumnTextColor?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel
                            title={fieldHeaderColumnCellColor?.Title ?? ""}
                          />
                          <Field
                            name={fieldHeaderColumnCellColor?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Cell Style",
                    isKeepMounted: true,
                    isExpanded: true,
                    component: (
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <CCLabel title={fieldCellTextColor?.Title ?? ""} />
                          <Field
                            name={fieldCellTextColor?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title={fieldCellColor?.Title ?? ""} />
                          <Field
                            name={fieldCellColor?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title={fieldCellColorAlt?.Title ?? ""} />
                          <Field
                            name={fieldCellColorAlt?.FieldName}
                            component={CCColorPicker}
                            disabled={!isEditing}
                            on
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Border Colours",
                    isKeepMounted: true,
                    isExpanded: true,
                    component: (
                      <div className="cc-field-group">
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCLabel title={fieldTopBorderColor?.Title ?? ""} />
                            <Field
                              name={fieldTopBorderColor?.FieldName}
                              component={CCColorPicker}
                              disabled={!isEditing}
                              on
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel
                              title={fieldRightBorderColor?.Title ?? ""}
                            />
                            <Field
                              name={fieldRightBorderColor?.FieldName}
                              component={CCColorPicker}
                              disabled={!isEditing}
                              on
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-3">
                          <div className="cc-field">
                            <CCLabel
                              title={fieldBottomBorderColor?.Title ?? ""}
                            />
                            <Field
                              name={fieldBottomBorderColor?.FieldName}
                              component={CCColorPicker}
                              disabled={!isEditing}
                              on
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel
                              title={fieldLeftBorderColor?.Title ?? ""}
                            />
                            <Field
                              name={fieldLeftBorderColor?.FieldName}
                              component={CCColorPicker}
                              disabled={!isEditing}
                              on
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          )}
        </section>
      </>
    );
  }
);
