import { DTO_Journal } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { DTO_WorkflowHeader, eVisibility } from "@app/products/property/model";
import { DTO_Supplementary_Create } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { DTO_LOV_Number } from "@common/models/odataResponse";

export enum EKeysOfStepsNewNoticeRun {
  NoticeType = "NoticeType",
  Attribute = "Attributes",
  Instalment = "InstalmentPlans",
  Journal = "Journals",
  NewJournal = "CreateJournal",
  Supplementary = "Supplementary",
  NewSupplementary = "CreateSupplementary",
  Assessment = "Assessment",
  Group = "Groups",
  Collection = "Collections",
  Execution = "Execution",
  Option = "Options",
  Comment = "Comments",
}

export enum ELabelNewNoticeRun {
  NoticeType = "Notice type",
  Attribute = "Attributes",
  Instalment = "Instalments",
  Journal = "Journal",
  NewJournal = "New journal",
  Supplementary = "Supplementaries",
  NewSupplementary = "New supplementary",
  Assessment = "Assessment",
  Group = "Groups",
  Collection = "Collections",
  Execution = "Execution",
  Option = "Options",
  Comment = "Comments",
}

export const keysOfStepsNewNoticeRun = [
  EKeysOfStepsNewNoticeRun.NewJournal,
  EKeysOfStepsNewNoticeRun.Attribute,
  EKeysOfStepsNewNoticeRun.Option,
  EKeysOfStepsNewNoticeRun.Collection,
  EKeysOfStepsNewNoticeRun.NewSupplementary,
  EKeysOfStepsNewNoticeRun.Group,
  EKeysOfStepsNewNoticeRun.Assessment,
  EKeysOfStepsNewNoticeRun.Instalment,
  EKeysOfStepsNewNoticeRun.Execution,
];

export interface DTO_Workflow_NoticeRunCreate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_NoticeRunCreate;
}

export interface DTO_WorkflowDetail_NoticeRunCreate {
  SupplementaryMode: number;
  JournalMode: number;
  CreateJournal: DTO_Journal;
  ComponentNumber: number;
  Options: DTO_Process_NoticeRun_Options;
  Collections: DTO_Process_NoticeRun_Collections;
  CreateSupplementary: DTO_Supplementary_Create;
  Groups: DTO_Process_NoticeRun_Groups;
  Supplementaries: number[];
  Journals: number[];
  Assessment: DTO_Process_NoticeRun_Assessment;
  Attributes: DTO_Process_NoticeRun_Attributes;
  Notice_Type_Id: number;
  Notice_Run_Id: number | null;
  InstalmentPlans: DTO_Process_NoticeRun_InstalmentPlans;
  Execution: DTO_Process_NoticeRun_Execution;
}

export interface DTO_Process_NoticeRun_Assessment {
  MinimumNoticeAmount?: number;
  Instalment_Plan_Id?: number;
  NoNoticeIssuedAfter?: Date;
  NoChangeOfOwnershipAfter?: Date;
  NoActiveArrangement: boolean;
  NoInterestExemption: boolean;
  ExcludeInstalments: boolean;
  DirectDebitSchemesToBeExcluded: number[];
  NoOutstandingChargeInDiscountPeriod: boolean;
  EntitledToRebates: boolean;
  NotEntitledToRebates: boolean;
  HasInstalmentCharge: boolean;
}

export interface DTO_Process_NoticeRun_Groups {
  IncludeUnGroupedNotices: boolean;
  AssessmentGroups: number[];
  AssessmentStatuses: number[];
  AssessmentTypes: number[];
  NoticeGroups: number[];
}

export interface DTO_Process_NoticeRun_Collections {
  CollectionsToExclude: number[];
  CollectionsToInclude: number[];
}

export interface DTO_Process_NoticeRun_Options {
  UseUtilityAgent: boolean;
  IncludeBalances: boolean;
  IncludeIfNotInChargeRun: boolean;
  NoticeOrdering_Id: number | null;
  FinancialAreas: number[];
  DocumentTemplateId?: number;
}

export interface DTO_Process_NoticeRun_InstalmentPlans {
  SpecificRange: boolean;
  DueFrom?: Date;
  DueTo?: Date;
  InstalmentPlans: number[];
  PayInFullAssessmentsOnly: boolean;
}

export interface DTO_Process_NoticeRun_Execution {
  EmailRecipients: string;
  RunImmediately: boolean;
  RunAt: Date | null;
}
export interface RequestNoticeRunCreateObj {
  ComponentNumber: number;
}

export interface DTO_Process_NoticeRun_Attributes {
  Rating_Period_Id?: number;
  Charge_Run_Id?: number;
  Financial_Group_Id?: number;
  SetTransactionDateToIssueDate: boolean;
  IssueDate: Date;
  Name: string;
}

export interface DTO_NoticeRun_LOVs {
  Collections?: DTO_LOV_Number[];
  AssessmentStatuses?: DTO_LOV_Number[];
  DirectDebitSchemes?: DTO_LOV_Number[];
  NoticeOrderings?: DTO_LOV_NoticeOrdering[];
  FinancialGroups?: DTO_LOV_Number[];
  FinancialAreas?: DTO_LOV_Number[];
  SupplementaryTypes?: DTO_LOV_Number[];
  AssessmentTypes?: DTO_LOV_Number[];
  RatingPeriodId?: number | null;
  InstalmentPlans?: DTO_LOV_Number[];
  ChargeRun?: DTO_LOV_Number[];
  RatingPeriods?: DTO_LOV_Number[];
  AssessmentGroups?: DTO_LOV_Number[];
  NoticeGroups?: DTO_LOV_Number[];
  NoticeTypes?: DTO_LOV_NoticeType[];
  InstalmentPlanId?: number | null;
  JournalTypes?: DTO_LOV_Number[];
  AssessmentType?: DTO_LOV_Number[];
  ValuationTypeTobeUsed?: DTO_LOV_Number[];
  NoticeGroupsGrid?: DTO_LOV_Number[];
  NoticeOutputFormats?: DTO_LOV_Number[];
  AssessType?: DTO_LOV_Number[];
  DocumentTemplates?: DTO_LOV_Number[];
}
export interface IListConfigStepCreateNoticeRun {
  keyNameStep: EKeysOfStepsNewNoticeRun;
  flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun;
}

export enum EFlagVisibilityStepCreateNoticeRun {
  Attribute = "TabAttributeVisibility",
  Assessment = "TabAssessmentVisibility",
  Collection = "TabCollectionVisibility",
  Groups = "TabGroupsVisibility",
  Options = "TabOptionsVisibility",
  Journal = "TabJournalVisibility",
  Supplementary = "TabSupplementaryVisibility",
  Instalment = "TabInstalmentsVisibility",
}
export interface DTO_LOV_NoticeType {
  Code: number;
  Name: string;
  ComponentNumber: number;
  TabAttributeVisibility: eVisibility;
  TabAssessmentVisibility: eVisibility;
  TabGroupsVisibility: eVisibility;
  TabCollectionVisibility: eVisibility;
  TabOptionsVisibility: eVisibility;
  TabJournalVisibility: eVisibility;
  TabSupplementaryVisibility: eVisibility;
  TabInstalmentsVisibility: eVisibility;
  ChargeRunVisibility: eVisibility;
  AssessmentExclusionsVisibility: eVisibility;
  SetTransactionDateToIssueDateVisibility: eVisibility;
  DefaultDocumentTemplateId?: number;
}

export interface DTO_LOV_NoticeOrdering {
  Code: number;
  Name: string;
  ComponentNumber: number;
}

export interface IFilterLOVsNoticeRun {
  chargeRunId?: number;
  financialGroupId?: number;
}

export const NOTICE_RUN = "Create Notice Run";

export enum ENoticeType {
  "RATE_NOTICE_FROM_CHARGE_RUN" = 10,
  "RATE_NOTICE_FROM_CHARGE_RUN_AND_JOURNALS" = 11,
  "INSTALMENT_NOTICES" = 20,
  "AMOUNTS_DUE" = 21,
  "REMINDER_NOTICES" = 30,
  "FINAL_NOTICES" = 31,
  "SUPPLEMENTARY_NOTICES" = 40,
  "SEPARATE_SUPPLEMENTARY_NOTICES_PER_PERIOD" = 42,
  "NOTICES_FOR_JOURNALS_OF_ASSESSMENT_CHARGES" = 50,
  "NOTICES_FOR_JOURNALS_OF_ACCOUNT_TRANSACTIONS" = 51,
  "REGISTER_ACCOUNT_BALANCES" = 60,
  "VALUATION_NOTICES" = 70,
  "LAND_RENT_ANNUAL_ASSESSMENT_NOTICES" = 71,
  "LAND_RENT_ANNUAL_INSTALMENT_NOTICES" = 72,
  "LAND_RENT_ARREARS_NOTICES" = 73,
  "VIC_FSPL_RETURN" = 80,
  "VIC_FSPL_REMITTANCE" = 81,
  "VIC_FSPL_RECONCILIATION" = 82,
  "NSW_FESL_QUARTERLY_LAND_CLASSIFICATION_REPORT" = 85,
  "NSW_FESL_QUARTERLY_FINANCIAL_REPORT" = 86,
  "NSW_FESL_ANNUAL_FINANCIAL_RETURN" = 87,
}
