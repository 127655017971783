import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { DOCUMENT_TEMPLATE_ROUTE } from "@app/products/property/system-admin/document-template/constant";
import { CloneDocumentButton } from "@app/products/property/system-admin/document-template/list/component/buttons/clone-new-document/_index";
import { DeactivateButton } from "@app/products/property/system-admin/document-template/list/component/buttons/deactivate-document/_index";
import { NewDocumentButton } from "@app/products/property/system-admin/document-template/list/component/buttons/new-document/_index";
import { colDocumentTemplate } from "@app/products/property/system-admin/document-template/list/config";
import { PROPERTY_DOCUMENT_TEMPLATE_LIST_VIEW_URL } from "@app/products/property/system-admin/document-template/list/constant";
import { VO_DocumentTemplate } from "@app/products/property/system-admin/document-template/list/model";
import {
  getDocumentDetail,
  getDocumentDisplayName,
  getDocumentListViewDisplayName,
} from "@app/products/property/system-admin/document-template/list/util";
import { documentTemplateRoute } from "@app/products/property/system-admin/document-template/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import React from "react";
const nameOf = nameOfFactory<VO_DocumentTemplate>();
export default () => {
  useCCListViewActionBar({
    title: documentTemplateRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={documentTemplateRoute.path} />,
    ],
    centerComponents: [
      <NewDocumentButton />,
      <CloneDocumentButton />,
      <DeactivateButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ListViewBookmarkIcon
        linkUrl={DOCUMENT_TEMPLATE_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        detail={getDocumentDetail}
        displayName={getDocumentDisplayName}
        listViewDisplayName={getDocumentListViewDisplayName}
        listViewDetail={getDocumentListViewDisplayName}
      />,
    ],
  });

  return (
    <CCProductListView
      columnFields={colDocumentTemplate}
      primaryField={nameOf("Id")}
      dataUrl={PROPERTY_DOCUMENT_TEMPLATE_LIST_VIEW_URL}
    />
  );
};
