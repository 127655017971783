import { IActionsBeingApprovedDetailTab } from "@app/products/property/actions/being-approved-list/components/reference-sidebar/detail/model";

export const mockActionsBeingApprovedDetailTab: IActionsBeingApprovedDetailTab =
  {
    Component: "Holding",
    Workflow: "Create Holding",
    Region: "South West",
    WorkflowStatus: "Completed",
    UserId: "randallh",
    Created: new Date("4/12/2022"),
    Modified: new Date("4/12/2022"),
    ApprovalStatus: "Being Approved (LLS Officer)",
    FunctionalGroup: "Holding Approval",
  };
