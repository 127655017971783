import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsPermitsAllPermits: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.PermitType_Name,
    title: "Permit Type",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.PermitHolder_DisplayName,
    title: "Holder",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.StreetName,
    title: "Street Name",
    width: 150,
  },
  { field: ParkingPermitsFieldMapping.Suburb, title: "Suburb", width: 150 },
  {
    field: ParkingPermitsFieldMapping.Address_Search,
    title: "Address",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.Permit_Status,
    title: "Status",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.Permit_SubStatus,
    title: "Sub Status",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.IssuedDate,
    title: "Issue Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.ExpiryDate,
    title: "Expiry Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.PermitNumber,
    title: "Permit Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.PermitArea,
    title: "Permit Area",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.ZoneCode,
    title: "Zone Code",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.ZoneName,
    title: "Zone Name",
    width: 150,
  },
];
