import { getTitleContacts } from "@app/products/property/titles/[id]/components/child-screens/title-contacts/api";
import { colTitleContacts } from "@app/products/property/titles/[id]/components/child-screens/title-contacts/config";
import { DTO_TitleEntity } from "@app/products/property/titles/[id]/components/child-screens/title-contacts/model";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { APIResponse } from "@common/apis/model";
import { useCallApiHandleLoadFailed } from "@common/hooks/useCallApiHandleLoadFailed";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
const nameOfNameAddress = nameOfFactory<DTO_TitleEntity>();
export const TitleContacts = observer(() => {
  const { titleId } = useTitleStore();
  const [isIncludePastNames, setIsIncludePastNames] = useState<boolean>(false);
  const { cancelToken } = useCancelRequest();
  const handleOnChangeIncludePastNames = (event: CheckboxChangeEvent) => {
    setIsIncludePastNames(event.value);
  };
  const [titleContacts, setTitleContacts] = useState<DTO_TitleEntity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!titleId) return null;

  let errorComponent: JSX.Element | null = null;
  errorComponent = useCallApiHandleLoadFailed({
    isActive: !isNil(titleId) && !isNil(isIncludePastNames),
    setIsLoading,
    api: async () =>
      getTitleContacts(titleId, isIncludePastNames, cancelToken()),
    watch: [titleId, isIncludePastNames],
    handler: async (response: APIResponse<any> | undefined) => {
      setTitleContacts(response?.data?.Entities ?? []);
    },
  });

  return (
    <>
      <div className="cc-grid-control-right">
        <Checkbox
          title="Include previous contacts"
          label="Include previous contacts"
          checked={isIncludePastNames}
          onChange={handleOnChangeIncludePastNames}
          disabled={isLoading}
        />
      </div>

      <CCGrid
        errorComponent={errorComponent}
        className="cc-title-contacts-grid"
        data={titleContacts ?? []}
        columnFields={colTitleContacts}
        isLoading={isLoading}
        primaryField={nameOfNameAddress("Contact_Name_Address_Id")}
      />
    </>
  );
});
