import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const parcelRoute: ICCRoute = {
  path: "parcels",
  name: "Parcels",
  enum: eMenuAction.Parcel_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Parcel_SearchList,
      component: require("./list/_index").default,
    },
    {
      path: "locality-street-address",
      name: "Locality Street Address",
      component: require("./locality-street-address/_index").default,
      enum: eMenuAction.Parcel_Generate_Locality_Street_Address_MenuItems,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
