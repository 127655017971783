import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_SiteUser_TabDetail } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/model";

export const getSiteUserTabDetailsById = async (
  siteUserId: number
): Promise<APIResponse<Svc_SiteUser_TabDetail | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_SiteUser_TabDetail>(
      `/api/core/internal/siteuser/${siteUserId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
