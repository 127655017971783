import { getUUID } from "@common/utils/common";

export enum Application_Status {
  Unspecified = "Unspecified", //0
  NewApplication = "NewApplication", //1
  Lodged = "Lodged", //2
  AllocatedToPlanner = "AllocatedToPlanner", //3
  AssessedByPlanner = "AssessedByPlanner", //4
  ReadyForDecision = "ReadyForDecision", //5
  PermitIssued = "PermitIssued", //6
  NoticeOfDecision = "NoticeOfDecision", //7
  PermitRefused = "PermitRefused", //8
  ApplicationComplete = "ApplicationComplete", //9
  RevisionReceived = "RevisionReceived", //10
  DevelopmentApproved = "DevelopmentApproved", //11
  Withdrawn = "Withdrawn", //12
  Lapsed = "Lapsed", //13
  FurtherInfo = "FurtherInfo", //14
  ReadyToAdvertise = "ReadyToAdvertise", //15
  Advertising = "Advertising", //16
  Appealed = "Appealed", //17
  AllocatedToBuildingOfficer = "AllocatedToBuildingOfficer", //18
  AssessedByBuildingOfficer = "AssessedByBuildingOfficer", //19
  BuildingPermitIssued = "BuildingPermitIssued", //20
  BuildingCertificateIssued = "BuildingCertificateIssued", //21
  BuildingPermitRefused = "BuildingPermitRefused", //22
  ReadyForCertificate = "ReadyForCertificate", //23
  PermitReceived = "PermitReceived", //24
  ReadyForBuildingAllocation = "ReadyForBuildingAllocation", //25
  ePlanningNewApplication = "ePlanningNewApplication", //26
  ePlanningNewRevision = "ePlanningNewRevision", //27
  ePlanningNewAmendment = "ePlanningNewAmendment", //28
  FurtherInfoReceived = "FurtherInfoReceived", //29
  FurtherInfoBuilding = "FurtherInfoBuilding", //30
  FurtherInfoBuildingReceived = "FurtherInfoBuildingReceived", //31
  AmendmentIssued = "AmendmentIssued", //32
  AmendmentRefused = "AmendmentRefused", //33
  AdvertisingComplete = "AdvertisingComplete", //34
  PreliminaryAssessment = "PreliminaryAssessment", //35
  PlannerAssessment = "PlannerAssessment", //36
  Meeting = "Meeting", //37
  MeetingComplete = "MeetingComplete", //38
  ReadyForMeeting = "ReadyForMeeting", //39
  Referred = "Referred", //40
  AwaitingCertification = "AwaitingCertification", //41
  Certified = "Certified", //42
  StatementOfComplianceIssued = "StatementOfComplianceIssued", //43
  Complete = "Complete", //44
  Refused = "Refused", //45
  Expired = "Expired", //47
  ePlanningNewSubdivision = "ePlanningNewSubdivision", //48
  ePlanningNewPlansToComply = "ePlanningNewPlansToComply", //49
  RevisionDeclined = "RevisionDeclined", //50
  RevisionAccepted = "RevisionAccepted", //51
  AppealComplete = "AppealComplete", //52
  ComplyIssued = "ComplyIssued", //53
  ComplyRefused = "ComplyRefused", //54
  Validated = "Validated", //55
  PermitNotRequired = "PermitNotRequired", //56
  PermitComplete = "PermitComplete", //57
  CouncilDecisionPending = "CouncilDecisionPending", //58
  CouncilAdopted = "CouncilAdopted", //59
  MinisterDecisionPending = "MinisterDecisionPending", //60
  PendingGazettal = "PendingGazettal", //61
  MinisterAuthorisationPending = "MinisterAuthorisationPending", //62
  ReadyToSendToMinister = "ReadyToSendToMinister", //63
  CouncilFinalDecisionPending = "CouncilFinalDecisionPending", //64
  ReadyToSendToCouncilForAuthorisation = "ReadyToSendToCouncilForAuthorisation", //65
  ReadyToSendToCouncilForAdoption = "ReadyToSendToCouncilForAdoption", //66
  AmendmentGazetted = "AmendmentGazetted", //67
  ReadyForExhibition = "ReadyForExhibition", //68
  Exhibition = "Exhibition", //69
  ExhibitionComplete = "ExhibitionComplete", //70
  ReadyToSendToCouncilForConsideration = "ReadyToSendToCouncilForConsideration", //71
  AllocatedToPlumbingOfficer = "AllocatedToPlumbingOfficer", //72
  AssessedByPlumbingOfficer = "AssessedByPlumbingOfficer", //73
  PlumbingCertificateIssued = "PlumbingCertificateIssued", //74
  Superseded = "Superseded", //75
  ReceivedFromPortal = "ReceivedFromPortal", //76
  ApprovedByTeamLeader = "ApprovedByTeamLeader", //77
  ReadyToSendForApproval = "ReadyToSendForApproval", //78
  Submitted = "Submitted", //79
  CompletionCertificateIssued = "CompletionCertificateIssued", //80
  FurtherInfoNotRequired = "FurtherInfoNotRequired", //81
  AdvertisingNotRequired = "AdvertisingNotRequired", //82
  MeetingNotRequired = "MeetingNotRequired", //83
  PlannerPrelimAssessment = "PlannerPrelimAssessment", //84
  InPrincipleSupportAssessmentComplete = "InPrincipleSupportAssessmentComplete", //85
  InPrincipleSupportDecisionComplete = "InPrincipleSupportDecisionComplete", //86
  TeamLeaderDecisionPending = "TeamLeaderDecisionPending", //87
  LetterApprovedPlansAdequate = "LetterApprovedPlansAdequate", //88
  LetterApprovedPlansInAdequate = "LetterApprovedPlansInAdequate", //89
  ReadyToReSendToTeamLeader = "ReadyToReSendToTeamLeader", //90
  Referral = "Referral", //91
  ReadyForNoticeOfCompletion = "ReadyForNoticeOfCompletion", //92
  FurtherInfoWaitingForApproval = "FurtherInfoWaitingForApproval", //93
  FurtherInfoApproved = "FurtherInfoApproved", //94
  RequestedToHeardPanel = "RequestedToHeardPanel", //95
  PanelReportApprovedByTeamLeader = "PanelReportApprovedByTeamLeader", //96
  PanelReportRefusedByTeamLeader = "PanelReportRefusedByTeamLeader", //97
  PanelReportSentForApproval = "PanelReportSentForApproval", //98
  SentReportForPanelHearing = "SentReportForPanelHearing", //99
  ReferralReceived = "ReferralReceived", //100
  ReferralLodged = "ReferralLodged", //101
  AllocatedToOfficer = "AllocatedToOfficer", //102
  MinorAssessment = "MinorAssessment", //103
  FinalAssessment = "FinalAssessment", //104
  ReadyForRecommendation = "ReadyForRecommendation", //105
  ReadyForResponse = "ReadyForResponse", //106
  ResponseSent = "ResponseSent", //107
  PendingForCILPayment = "PendingForCILPayment", //108
  StateEPlanningNewApplication = "StateEPlanningNewApplication", //109
  Draft = "Draft", //110
  PNFLodge = "PNFLodge", //111
  PNFAllocated = "PNFAllocated", //112
  AssessedByPNFOfficer = "AssessedByPNFOfficer", //113
  WaitingForDraftResponse = "WaitingForDraftResponse", //114
  PreparingDraftAgreement = "PreparingDraftAgreement", //115
  WaitingSignedAgreement = "WaitingSignedAgreement", //116
  CheckingSignedAgreement = "CheckingSignedAgreement", //117
  PNFPendingApproval = "PNFPendingApproval", //118
  Current = "Current", //119
  NewVersion = "NewVersion", //120
  PNFExpired = "PNFExpired", //121
  PNFTerminated = "PNFTerminated", //122
  PNFWithdrawn = "PNFWithdrawn", //123
  PNFClose = "PNFClose", //124
  PlumbingNotifiableCertificateIssued = "PlumbingNotifiableCertificateIssued", //125
  EOIReceived = "EOIReceived", //126
  Proposed = "Proposed", //127
  NEW = "NEW", //128
  AssessedByOfficer = "AssessedByOfficer", //129
  Active = "Active", //130
  SignedAgreementReceived = "SignedAgreementReceived", //131
  DelegateConsent = "DelegateConsent", //132
  NotificationIssued = "NotificationIssued", //133
  CertificationIssued = "CertificationIssued", //134
  Inactive = "Inactive", //135
  Amend = "Amend", //136
  Cancelled = "Cancelled", //137
}

export enum Application_Status_Int {
  Unspecified = 0,
  NewApplication = 1,
  Lodged = 2,
  AllocatedToPlanner = 3,
  AssessedByPlanner = 4,
  ReadyForDecision = 5,
  PermitIssued = 6,
  NoticeOfDecision = 7,
  PermitRefused = 8,
  ApplicationComplete = 9,
  RevisionReceived = 10,
  DevelopmentApproved = 11,
  Withdrawn = 12,
  Lapsed = 13,
  FurtherInfo = 14,
  ReadyToAdvertise = 15,
  Advertising = 16,
  Appealed = 17,
  AllocatedToBuildingOfficer = 18,
  AssessedByBuildingOfficer = 19,
  BuildingPermitIssued = 20,
  BuildingCertificateIssued = 21,
  BuildingPermitRefused = 22,
  ReadyForCertificate = 23,
  PermitReceived = 24,
  ReadyForBuildingAllocation = 25,
  ePlanningNewApplication = 26,
  ePlanningNewRevision = 27,
  ePlanningNewAmendment = 28,
  FurtherInfoReceived = 29,
  FurtherInfoBuilding = 30,
  FurtherInfoBuildingReceived = 31,
  AmendmentIssued = 32,
  AmendmentRefused = 33,
  AdvertisingComplete = 34,
  PreliminaryAssessment = 35,
  PlannerAssessment = 36,
  Meeting = 37,
  MeetingComplete = 38,
  ReadyForMeeting = 39,
  Referred = 40,
  AwaitingCertification = 41,
  Certified = 42,
  StatementOfComplianceIssued = 43,
  Complete = 44,
  Refused = 45,
  Expired = 47,
  ePlanningNewSubdivision = 48,
  ePlanningNewPlansToComply = 49,
  RevisionDeclined = 50,
  RevisionAccepted = 51,
  AppealComplete = 52,
  ComplyIssued = 53,
  ComplyRefused = 54,
  Validated = 55,
  PermitNotRequired = 56,
  PermitComplete = 57,
  CouncilDecisionPending = 58,
  CouncilAdopted = 59,
  MinisterDecisionPending = 60,
  PendingGazettal = 61,
  MinisterAuthorisationPending = 62,
  ReadyToSendToMinister = 63,
  CouncilFinalDecisionPending = 64,
  ReadyToSendToCouncilForAuthorisation = 65,
  ReadyToSendToCouncilForAdoption = 66,
  AmendmentGazetted = 67,
  ReadyForExhibition = 68,
  Exhibition = 69,
  ExhibitionComplete = 70,
  ReadyToSendToCouncilForConsideration = 71,
  AllocatedToPlumbingOfficer = 72,
  AssessedByPlumbingOfficer = 73,
  PlumbingCertificateIssued = 74,
  Superseded = 75,
  ReceivedFromPortal = 76,
  ApprovedByTeamLeader = 77,
  ReadyToSendForApproval = 78,
  Submitted = 79,
  CompletionCertificateIssued = 80,
  FurtherInfoNotRequired = 81,
  AdvertisingNotRequired = 82,
  MeetingNotRequired = 83,
  PlannerPrelimAssessment = 84,
  InPrincipleSupportAssessmentComplete = 85,
  InPrincipleSupportDecisionComplete = 86,
  TeamLeaderDecisionPending = 87,
  LetterApprovedPlansAdequate = 88,
  LetterApprovedPlansInAdequate = 89,
  ReadyToReSendToTeamLeader = 90,
  Referral = 91,
  ReadyForNoticeOfCompletion = 92,
  FurtherInfoWaitingForApproval = 93,
  FurtherInfoApproved = 94,
  RequestedToHeardPanel = 95,
  PanelReportApprovedByTeamLeader = 96,
  PanelReportRefusedByTeamLeader = 97,
  PanelReportSentForApproval = 98,
  SentReportForPanelHearing = 99,
  ReferralReceived = 100,
  ReferralLodged = 101,
  AllocatedToOfficer = 102,
  MinorAssessment = 103,
  FinalAssessment = 104,
  ReadyForRecommendation = 105,
  ReadyForResponse = 106,
  ResponseSent = 107,
  PendingForCILPayment = 108,
  StateEPlanningNewApplication = 109,
  Draft = 110,
  PNFLodge = 111,
  PNFAllocated = 112,
  AssessedByPNFOfficer = 113,
  WaitingForDraftResponse = 114,
  PreparingDraftAgreement = 115,
  WaitingSignedAgreement = 116,
  CheckingSignedAgreement = 117,
  PNFPendingApproval = 118,
  Current = 119,
  NewVersion = 120,
  PNFExpired = 121,
  PNFTerminated = 122,
  PNFWithdrawn = 123,
  PNFClose = 124,
  PlumbingNotifiableCertificateIssued = 125,
  EOIReceived = 126,
  Proposed = 127,
  NEW = 128,
  AssessedByOfficer = 129,
  Active = 130,
  SignedAgreementReceived = 131,
  DelegateConsent = 132,
  NotificationIssued = 133,
  CertificationIssued = 134,
  Inactive = 135,
  Amend = 136,
  Cancelled = 137,
}

export const ApplicationStatusFriendlyNames: {
  [key in Application_Status]?: string;
} = {
  [Application_Status.Unspecified]: "Unspecified",
  [Application_Status.NewApplication]: "New Application",
  [Application_Status.Lodged]: "Lodged",
  [Application_Status.AllocatedToPlanner]: "Allocated",
  [Application_Status.AssessedByPlanner]: "Assessed By Planner",
  [Application_Status.Validated]: "Validated",
  [Application_Status.ReadyForDecision]: "Ready For Decision",
  [Application_Status.PermitIssued]: "Permit Issued",
  [Application_Status.PermitNotRequired]: "Permit Not Required",
  [Application_Status.PermitRefused]: "Permit Refused",
  [Application_Status.PermitComplete]: "Permit Complete",
  [Application_Status.AmendmentIssued]: "Amendment Issued",
  [Application_Status.AmendmentRefused]: "Amendment Refused",
  [Application_Status.NoticeOfDecision]: "Notice Of Decision",

  [Application_Status.RevisionReceived]: "Revision Received",
  [Application_Status.Withdrawn]: "Withdrawn",
  [Application_Status.Lapsed]: "Lapsed",
  [Application_Status.FurtherInfo]: "Further Information",
  [Application_Status.FurtherInfoReceived]: "Further Information Complete",
  [Application_Status.Advertising]: "Advertising",
  [Application_Status.ReadyToAdvertise]: "Ready To Advertise",
  [Application_Status.AdvertisingComplete]: "Advertising Complete",
  [Application_Status.DevelopmentApproved]: "Development Approved",
  [Application_Status.Appealed]: "Appealed",
  [Application_Status.AppealComplete]: "Appeal Complete",

  [Application_Status.PreliminaryAssessment]: "Preliminary Assessment",
  [Application_Status.PlannerAssessment]: "Planner Assessment",

  [Application_Status.ReadyForMeeting]: "Ready for Meeting",
  [Application_Status.Meeting]: "Meeting",
  [Application_Status.MeetingComplete]: "Meeting Complete",

  [Application_Status.ComplyIssued]: "Plans to Comply Issued",
  [Application_Status.ComplyRefused]: "Plans to Comply Refused",

  [Application_Status.FurtherInfoBuilding]: "Building Further Information",
  [Application_Status.FurtherInfoBuildingReceived]:
    "Building further information received",
  [Application_Status.ReadyForBuildingAllocation]: "Planning Permit Issued",
  [Application_Status.AllocatedToBuildingOfficer]:
    "Allocated To Building Officer",
  [Application_Status.AssessedByBuildingOfficer]:
    "Assessed By Building Officer",
  [Application_Status.BuildingPermitIssued]: "Building Permit Issued",
  [Application_Status.BuildingPermitRefused]: "Building Permit Refused",
  [Application_Status.BuildingCertificateIssued]: "Building Certificate Issued",
  [Application_Status.PermitReceived]: "Permit Received",
  [Application_Status.ReadyForCertificate]: "Ready For Certificate",
  [Application_Status.ReadyForNoticeOfCompletion]:
    "Ready For Notice Of Completion",
  [Application_Status.ApplicationComplete]: "Application Complete",

  [Application_Status.ePlanningNewApplication]: "New Application",
  [Application_Status.ePlanningNewAmendment]: "New Amendment",
  [Application_Status.ePlanningNewRevision]: "New Revision",
  [Application_Status.ePlanningNewPlansToComply]: "New Plans to Comply",
  [Application_Status.ePlanningNewSubdivision]: "New Subdivision",
  [Application_Status.PendingForCILPayment]:
    "Building Permit Pending - CIL Payment",
  //SubDiv
  [Application_Status.Referred]: "Referred",
  [Application_Status.Certified]: "Certified",
  [Application_Status.AwaitingCertification]: "Awaiting Certification",
  [Application_Status.Complete]: "Application Complete",
  [Application_Status.StatementOfComplianceIssued]:
    "Statement Of Compliance Issued",
  [Application_Status.Refused]: "Refused",
  [Application_Status.Expired]: "Expired",

  //SubDiv Referral
  [Application_Status.ReferralReceived]: "Referral Received",
  [Application_Status.ReferralLodged]: "Referral Lodged",
  [Application_Status.AllocatedToOfficer]: "Allocated To Officer",
  [Application_Status.MinorAssessment]: "Minor Assessment Complete",
  [Application_Status.FinalAssessment]: "Final Assessment Complete",
  [Application_Status.ReadyForRecommendation]: "Ready For Recommendation",
  [Application_Status.ReadyForResponse]: "Ready For Response",
  [Application_Status.ResponseSent]: "Response Sent",

  //PSA
  [Application_Status.CouncilDecisionPending]: "Council Decision Pending",
  [Application_Status.CouncilAdopted]: "Council Adopted",
  [Application_Status.MinisterDecisionPending]: "Minister Decision Pending",
  [Application_Status.PendingGazettal]: "Pending Gazettal",
  [Application_Status.MinisterAuthorisationPending]:
    "Minister Authorisation Pending",
  [Application_Status.ReadyToSendToMinister]: "Ready To Send To Minister",
  [Application_Status.CouncilFinalDecisionPending]:
    "Council Final Decision Pending",
  [Application_Status.ReadyToSendToCouncilForAuthorisation]:
    "Ready To Send To Council For Authorisation",
  [Application_Status.ReadyToSendToCouncilForAdoption]:
    "Ready To Send To Council For Adoption",
  [Application_Status.AmendmentGazetted]: "Amendment Gazetted",
  [Application_Status.ReadyForExhibition]: "Ready For Exhibition",
  [Application_Status.Exhibition]: "Exhibition",
  [Application_Status.ExhibitionComplete]: "Exhibition Complete",
  [Application_Status.ReadyToSendToCouncilForConsideration]:
    "Ready To Send To Council For Consideration",

  //Plumber
  [Application_Status.AllocatedToPlumbingOfficer]:
    "Allocated To Plumbing Officer",
  [Application_Status.AssessedByPlumbingOfficer]:
    "Assessed By Plumbing Officer",
  [Application_Status.PlumbingCertificateIssued]: "Plumbing Certificate Issued",
  //PPR

  [Application_Status.ApprovedByTeamLeader]: "Authorised By TL",
  [Application_Status.ReadyToSendForApproval]:
    "Ready To Send For Approval By TL",

  //Submitted
  [Application_Status.Submitted]: "Submitted",

  //Added By HS
  [Application_Status.CompletionCertificateIssued]:
    "Certificate of Completion Issued",

  // New Statuses created for WorkFlow
  [Application_Status.FurtherInfoNotRequired]:
    "Further Information Not Required",
  [Application_Status.AdvertisingNotRequired]: "Advertising Not Required",
  [Application_Status.MeetingNotRequired]: "Meeting Not Required",
  [Application_Status.PlannerPrelimAssessment]: "Planner Prelim Assessment",
  [Application_Status.InPrincipleSupportAssessmentComplete]:
    "In Principle Support Assessment Complete",
  [Application_Status.InPrincipleSupportDecisionComplete]:
    "In Principle Support Decision Complete",
  [Application_Status.TeamLeaderDecisionPending]: "Awaiting TL decision",
  [Application_Status.LetterApprovedPlansAdequate]:
    "Letter Approved - Materials Adequate",
  [Application_Status.LetterApprovedPlansInAdequate]:
    "Letter Approved - Materials Inadequate",
  [Application_Status.ReadyToReSendToTeamLeader]:
    "Letter Ready to ReSend to TL",
  [Application_Status.FurtherInfoWaitingForApproval]:
    "Further Information Awaiting Approval",
  [Application_Status.FurtherInfoApproved]: "Further Information Approved",

  //PSAR
  [Application_Status.RequestedToHeardPanel]: "Request To Be Heard",
  [Application_Status.PanelReportApprovedByTeamLeader]:
    "Panel Report Approved By TL",
  [Application_Status.PanelReportRefusedByTeamLeader]:
    "Panel Report Refused By TL",
  [Application_Status.PanelReportSentForApproval]:
    "Panel Report Sent for Approval By TL",
  [Application_Status.SentReportForPanelHearing]:
    "Report Sent for Panel Hearing",

  //NSW ePlanning
  [Application_Status.StateEPlanningNewApplication]:
    "State Portal New Application",

  //PNF
  [Application_Status.Draft]: "Draft",
  [Application_Status.PNFLodge]: "Lodged",
  [Application_Status.PNFAllocated]: "Allocated",
  [Application_Status.AssessedByPNFOfficer]: "Assessed By Officer",
  [Application_Status.WaitingForDraftResponse]: "Waiting For Response",
  [Application_Status.PreparingDraftAgreement]: "Preparing Agreement",
  [Application_Status.WaitingSignedAgreement]: "Waiting for Signed Agreement",
  [Application_Status.CheckingSignedAgreement]: "Checking Signed Agreement",
  [Application_Status.PNFPendingApproval]: "Pending Approval",
  [Application_Status.Current]: "Approved",
  [Application_Status.NewVersion]: "New Version",
  [Application_Status.PNFExpired]: "Expired",
  [Application_Status.PNFTerminated]: "Terminated",
  [Application_Status.PNFWithdrawn]: "Withdrawn",
  [Application_Status.PNFClose]: "Closed Prior To Approval",

  [Application_Status.EOIReceived]: "Pending",
  [Application_Status.Proposed]: "Proposed",
  [Application_Status.NEW]: "New",
  [Application_Status.AssessedByOfficer]: "Assessed By Officer",
  [Application_Status.Active]: "Active",
  [Application_Status.SignedAgreementReceived]: "Signed Agreement Received",
  [Application_Status.DelegateConsent]: "Delegate Review Complete",
  [Application_Status.NotificationIssued]: "Notification Issued",
  [Application_Status.CertificationIssued]: "Certification Issued",

  [Application_Status.Cancelled]: "Cancelled",
};

export const PPREventType = {
  RefreshApplication: getUUID(),
};
