import { FormAction } from "@app/products/crms/model";

export const crmsExternalUserEventsListViewURL = (
  formAction: FormAction,
  isShowOpenEventsOnly: boolean = false
) => {
  let params = "";
  if (isShowOpenEventsOnly) {
    params = `,showOpenEventsOnly=${isShowOpenEventsOnly}`;
  }
  return `odata/crms/internal/events/GetExternalUserEvents(formAction=${formAction}${params})?$count=true&`;
};
