import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export interface IDatePickerSettingsProps {
  data: SettingField | undefined;
  isEditing: boolean;
  readOnly?: boolean;
  isFieldVisible?: boolean;
  formRenderProps: FormRenderProps | undefined;
}

export const DatePickerSettings = ({
  data,
  isEditing,
  readOnly = false,
  isFieldVisible = true,
  formRenderProps,
}: IDatePickerSettingsProps) => {
  return data && isFieldVisible ? (
    <div className="cc-field">
      <label className="cc-label">
        {data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      <Field
        name={data?.FieldName}
        format={DATE_FORMAT.DATE_CONTROL}
        component={DatePicker}
        disabled={readOnly || !isEditing}
        value={
          formRenderProps?.valueGetter(data?.FieldName) &&
          new Date(formRenderProps?.valueGetter(data?.FieldName))
        }
        onChange={(event: DatePickerChangeEvent) => {
          formRenderProps?.onChange(data?.FieldName, {
            value: event.target.value,
          });
        }}
        validator={data?.IsMandatory ? requiredValidator : undefined}
      />
    </div>
  ) : null;
};
