import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field } from "@progress/kendo-react-form";
import { InputProps } from "@progress/kendo-react-inputs";
import React from "react";
import "../_index.scss";

interface IApplicationSettingTextProps {
  enumParam: ECorporateSettingsField;
}

export const GlobalSettingText = (
  props: IApplicationSettingTextProps & InputProps
) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { enumParam, ...others } = props;

  if (!configData) return <></>;
  return configData[enumParam.toString()] ? (
    <div className="cc-field">
      <CCLabel
        title={configData[enumParam.toString()].Title ?? ""}
        isMandatory={configData[enumParam.toString()].IsMandatory}
      />
      {configData[enumParam.toString()]?.Description ? (
        <label className="cc-label global-setting-description">
          {configData[enumParam.toString()].Description}
        </label>
      ) : null}
      <Field
        name={configData[enumParam.toString()].FieldName}
        component={CCInput}
        disabled={!isEditing}
        validator={
          configData[enumParam.toString()].IsMandatory
            ? requiredValidator
            : undefined
        }
        {...others}
      />
    </div>
  ) : null;
};
