import { IReadingDetail } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { colGISReference } from "@app/products/property/registers/[id]/config";
import { IGISReferenceGrid } from "@app/products/property/registers/[id]/model";
import { gisReferenceDataArrayToGrid } from "@app/products/property/registers/[id]/util";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";
interface IInstallationReadingDetailsProps {
  data?: IReadingDetail;
}
export const InstallationReadingDetails = ({
  data,
}: IInstallationReadingDetailsProps) => {
  const nameOfGISReferenceGrid = nameOfFactory<IGISReferenceGrid>();
  return (
    <div className="cc-reading-details cc-field-group">
      <label className="cc-label">Reading details</label>
      <div className="cc-custom-sub-panel-bar cc-form-cols-1">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Next scheduled reading date</label>
            <DatePicker
              className="cc-form-control"
              value={data?.NextScheduledReadingDate}
              disabled
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Reading frequency</label>
            <div className="cc-custom-input-group">
              <NumericTextBox
                disabled
                className="cc-form-control"
                value={data?.ReadingFrequency}
                placeholder={"Reading frequency"}
                min={0}
              />
              <div className="cc-input-group-postfix">month(s)</div>
            </div>
          </div>
        </div>
        <div className="cc-reading-details-route">
          <label className="cc-label">Route</label>
          <div className="cc-custom-sub-panel-bar cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Route name</label>
                <DropDownList
                  className="cc-form-control"
                  data={data?.ListRouteName || []}
                  value={data?.RouteName}
                  disabled
                  textField="Value"
                  dataItemKey="Key"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Sequence within route</label>
                <NumericTextBox
                  disabled
                  className="cc-form-control"
                  format={NUMBER_FORMAT.NUMBER2}
                  value={data?.SequenceWithinRoute}
                  placeholder={"Sequence within route"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Assigned reading device</label>
                <Input
                  className="cc-form-control"
                  value={data?.AssignedReadingDevice}
                  readOnly
                  placeholder={"Assigned reading device"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Device assigned to</label>
                <Input
                  className="cc-form-control"
                  value={data?.DeviceAssignedTo}
                  readOnly
                  placeholder={"Device assigned to"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-reading-details-property">
          <label className="cc-label">Property</label>
          <div className="cc-custom-sub-panel-bar cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <label className="cc-label">
                  Automatically determine property address from associated
                  assessment
                </label>
                <CCSwitch checked={data?.AutoProperty} disabled />
              </div>
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Property name</label>
                <Input
                  disabled
                  className="cc-form-control"
                  value={data?.PropertyName}
                  readOnly
                  placeholder={"Property name"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Property address</label>
                <Input
                  className="cc-form-control"
                  value={data?.PropertyAddress}
                  readOnly
                  placeholder={"Property address"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Locality</label>
                <DropDownList
                  className="cc-form-control"
                  data={data?.ListRouteName}
                  value={data?.Locality}
                  disabled
                  textField="Value"
                  dataItemKey="Key"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-reading-details-location">
          <div className=" cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Meter location</label>
              <Input
                disabled
                className="cc-form-control"
                value={data?.MeterLocation}
                readOnly
                placeholder={"Meter location"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">GIS references</label>
              <CCGrid
                className="cc-gis-reference-grid-data"
                columnFields={colGISReference}
                primaryField={nameOfGISReferenceGrid("GISReference")}
                data={gisReferenceDataArrayToGrid(data?.GISReference || [])}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reading instructions</label>
              <DropDownList
                className="cc-form-control"
                data={data?.ListReadingInstructions || []}
                value={data?.ReadingInstructions}
                disabled
                textField="Value"
                dataItemKey="Key"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Keys are required</label>
              <CCSwitch checked={data?.KeysAreRequired} disabled />
            </div>
          </div>
        </div>
        <div className="cc-reading-details-contact">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Contact name</label>
              <Input
                readOnly
                className="cc-form-control"
                value={data?.ContactName}
                placeholder={"Contact name"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Contact phone</label>
              <Input
                readOnly
                className="cc-form-control"
                value={data?.ContactPhone}
                placeholder={"Contact phone"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Phone before reading</label>
              <CCSwitch checked={data?.PhoneBeforeReading} disabled />
            </div>
            <div className="cc-field">
              <label className="cc-label">Email before reading</label>
              <CCSwitch checked={data?.EmailBeforeReading} disabled />
            </div>
            <div className="cc-field">
              <label className="cc-label">Email address</label>
              <CCTextArea
                className="cc-form-control"
                value={data?.EmailAddress}
                readOnly
                rows={3}
                placeholder={"Email address"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
