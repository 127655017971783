import { CORE_GET_HOMEPAGE_USER_BY_VIEWS_ODATA } from "@app/core/components/common/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SITE_USER_ROUTE } from "@common/pages/profile/constant";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { Svc_HomepagePageUser } from "@common/pages/settings/homepage/model";
import { colHomepageUserByViews } from "@common/pages/settings/homepage/users-by-views/config";
import {
  HomepageUserByViewsBookmark,
  HomepageUsersByViewsDetailsBookmark,
} from "@common/pages/settings/homepage/users-by-views/util";
import { settingsRoute } from "@common/pages/settings/route";
import { SiteUserTabDetails } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/_index";
import { SiteUserHistoryTab } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/history/_index";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_HomepagePageUser>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.HOMEPAGE_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SITE_USER_ROUTE}
        productType={PRODUCT_TYPE.Contact}
        recordType={RECORDTYPE.CORE_Contact}
        detail={HomepageUsersByViewsDetailsBookmark.getBookmarkDetail}
        displayName={HomepageUsersByViewsDetailsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          HomepageUserByViewsBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={HomepageUserByViewsBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SiteUserTabDetails /> },
      {
        title: "History",
        component: <SiteUserHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colHomepageUserByViews}
      primaryField={nameOf("PrimaryKey")}
      dataUrl={`${CORE_GET_HOMEPAGE_USER_BY_VIEWS_ODATA}?$count=true&`}
      state={{
        group: [{ field: nameOf("HomePageView") }],
        sort: [
          {
            field: nameOf("LastName"),
            dir: "asc",
          },
          {
            field: nameOf("FirstName"),
            dir: "asc",
          },
        ],
      }}
      selectableMode="single"
      disabled={!hasPermission}
    />
  );
};
