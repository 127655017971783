import { DTO_AssessmentCharge } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/model";
import { CCDateInputCell } from "@app/products/property/components/grid/components/cc-date-input-cell/_index";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<DTO_AssessmentCharge>();
export const colAdjustCharges: IColumnFields[] = [
  {
    field: nameOf("Adjustment_Amount"),
    title: "Adjustment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Adjustment_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("ChargeDate"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
    cell: CCDateInputCell,
  },
  {
    field: nameOf("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("GrossAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("RebatesGiven"),
    title: "Rebates Given",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("RebatesGiven", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Balance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Charge_Interest_Balance"),
    title: "Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("RatingPeriod"),
    title: "Rating Period",
  },
  {
    field: nameOf("Charge_Id"),
    title: "Charge ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];

export const ADJUST_CHARGE_VALIDATOR_MESSAGE =
  "At least one levy amount requires adjustment.";
