export const CSLSystemAdminCategoriesBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `CSL - System Admin - Categories ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return "Licence Category";
  },
  getBookmarkListViewDisplayName() {
    return "CSL - System Admin";
  },

  getBookmarkListViewDetail() {
    return "CSL - System Admin - Categories";
  },
};
