import { DTO_AreaOfResponsibility } from "@app/products/property/certificates/[id]/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AreaOfResponsibility>();

export const colAreaResponsibility: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Area of Responsibilities",
  },
  {
    field: nameOf("Completed"),
    title: "Completed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Completion_DateTime"),
    title: "Completed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Completion_UserId"),
    title: "Completed By",
  },
  {
    field: nameOf("Comments"),
    title: "Comments",
  },
  {
    field: nameOf("Elapsed_Days"),
    title: "Elapsed Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AreaOfResponsibility_Id"),
    title: "Area of Responsibility ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
