import {
  CouncilConsentAssessmentQuestion,
  RegisterStatus,
  RegisterType,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import {
  Address,
  Contact_Relation,
} from "@app/products/waste-water/[id]/model";

export const pprEnquirySettingEnums = [];
export class CouncilConsentAssessment {
  Questions: CouncilConsentAssessmentQuestion[] = [];
  ParentKWDs: number[] = [];
}

export class RegisterPPREnquiry {
  Status_ENUM: RegisterStatus = RegisterStatus.New;
  RegisterType_ENUM: RegisterType = RegisterType.PlanningEnquiry;
  Date_Recorded: Date | null = new Date();
  Date_Lodged: Date | null = null;
  Date_Complete: Date | null = null;
  FrequencyOfInspection: number | null = 0;
  Outcome_KWD: number | null = null;
  Category_KWD: number | null = null;
  Source_KWD: number | null = null;
  Type_KWD: number | null = null;
  Date_Start: Date | null = null;
  Regulations_KWD: number | null = null;
  Regulations_Description: string = "";
  Building_UseOfBuilding_Keyword_Display: string = "";
  FileNumber: string = "";
  Outcome_Description: string = "";
  Description: string = "";
  ReferenceNumber2: string = "";
  ReferenceNumber: string = "";
  CT_Make: string = "";
  CT_Model: string = "";
  CT_Application: string = "";
  CT_Location: string = "";
  CT_FreqOfOperation: string = "";
  BondReceiptNumber: string = "";
  InsurancePolicyNumber: string = "";
  Flag_BiocideElimFitted: boolean | null = false;
  Flag_DriftElimFittted: boolean | null = false;
  Flag_DriftCover: boolean | null = false;
  Flag_DriftBelow: boolean | null = false;
  Flag_DriftRemove: boolean | null = false;
  Decision_Name: string = "";
  DecisionType_KWD: number | null = null;
  DecisionType_Name: string = "";
  FinalDecision_Name: string = "";
  FinalDecisionType_KWD: number | null = null;
  FinalDecisionType_Name: string = "";
  FinalDecisionDate: Date | null = null;
  Date_Expiry: Date | null = null;
  IsBondDataNonEditable: boolean | null = false;
  CouncilConsentAssessment_OBJ = new CouncilConsentAssessment();
  AdditionalAddresses: Address[] = [];
  Address?: Address = undefined;
  Contact?: Contact_Relation = undefined;
}
