import {
  apiCoreLocationRegister,
  apiCoreLocationRegisterById,
  apiCoreLocationRegisterHandler,
  apiCoreLocationRegisterLovs,
  apiCoreLocationRegisterSetupPickAddress,
} from "@app/core/components/common/utils";
import {
  LocationRegisterHandlerRequest,
  LocationRegisterHandlerResponse,
  LocationRegisterLovs,
  Register,
} from "@app/core/location-register/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const loadLocationRegisterById = async (
  id: number
): Promise<APIResponse<Register | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Register>(
      apiCoreLocationRegisterById(id)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getLocationRegisterLovs = async (): Promise<
  APIResponse<LocationRegisterLovs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<LocationRegisterLovs>(
      apiCoreLocationRegisterLovs()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveLocationRegister = async (
  register: Register
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreLocationRegister(),
      register
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const locationRegisterHandler = async (
  register: LocationRegisterHandlerRequest
): Promise<
  APIResponse<IIdentityPacket<LocationRegisterHandlerResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<LocationRegisterHandlerResponse>
    >(apiCoreLocationRegisterHandler(), register);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupPickSiteAddress = async (
  register: Register
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >(apiCoreLocationRegisterSetupPickAddress(), register);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
