import { DTO_Certificate } from "@app/products/property/parcels/[id]/components/child-screens/certificates/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Certificate>();
export const colParcelCertificates: IColumnFields[] = [
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Number"),
    title: "Certificate Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("OriginatedDateTime"),
    title: "Date/Time Originated",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ApplicantName"),
    title: "Applicant",
  },
  {
    field: nameOf("ApplicantReference"),
    title: "Applicant Reference",
  },
  {
    field: nameOf("BalanceOwing"),
    format: CURRENCY_FORMAT.CURRENCY1,
    title: "Balance Owing",
  },
  {
    field: nameOf("IssuedDate"),
    title: "Issued On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("IssuedBy"),
    title: "Issued By",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Id"),
    title: "Certificate ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
