import {
  KBPage,
  KBPageIDsRequest,
} from "@app/products/crms/knowledge-base/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const loadGuidanceByEventId = async (
  eventID: number
): Promise<APIResponse<IIdentityPacket<KBPage[] | undefined>>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<KBPage[]>>(
      `api/crms/internal/knowledge-base/get-kb-by-event/${eventID}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const loadGuidanceByIds = async (
  kbPageIDsRequest: KBPageIDsRequest
): Promise<APIResponse<IIdentityPacket<KBPage[]> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<KBPage[]>>(
      "api/crms/internal/knowledge-base",
      kbPageIDsRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
