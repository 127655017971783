export interface HMInspectionView {
  ID: number;
  DateInspected: Date;
  DateInspectedMonth: Date;
  TimeStarted: Date | null;
  TimeCompleted: Date | null;
  ReferenceNo: string;
  InspectionType: string;
  Officer: string;
  Compliance: string;
  Reason: string;
  TradingName: string;
  ReferenceNumber: string;
  Address: string;
  Area: string;
  PremisesClassification: string;
  RiskClassification: string;
  CreatedDate: Date;
  CreatedBy: string;
  PremisesType: string;
  NCItemDescription: string;
  NCItemLocation: string;
  NCItemDue: Date | null;
  NCItemRisk: string;
  NCItemResult_ID: number | null;
  SignoffOfficer: string;
  SignoffDate: Date | null;
}

export enum HealthManagerInspectionViewType {
  Inspection,
  Assessment,
  Audit,
  OSNCItems,
  Supervisor,
}
