import { useAssessmentAmountOwingStore } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/store";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCValueField } from "@components/cc-value-field/_index";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { colAssessment, mappingGridBalances } from "./config";
import { IGridBalances } from "./model";

export const AssessmentAmountsOwing = observer(() => {
  const { amountOwing } = useAssessmentAmountOwingStore();
  const [dataGridBalances, setDataGridBalances] = useState<IGridBalances[]>([]);

  const [columnFields, setColumnFields] =
    useState<IColumnFields[]>(colAssessment);

  useEffect(() => {
    if (!amountOwing || !amountOwing.Totals) return;
    let newData: IGridBalances[] = [];
    Object.keys(amountOwing.Totals).forEach((key: string) => {
      const balanceType = mappingGridBalances[key];
      if (balanceType) {
        const cloneTotals: any = { ...amountOwing.Totals };
        newData.push({
          BalancesType: balanceType,
          ...cloneTotals[key],
        });
      }
    });
    const totalsRow: any = amountOwing.Totals.Totals;
    const newColumnFields = colAssessment.map((col: IColumnFields) => ({
      ...col,
      calculateFooterCellValue: col.footerCell
        ? () => totalsRow[col.field]
        : undefined,
    }));
    setColumnFields(newColumnFields);
    setDataGridBalances(newData);
  }, [amountOwing]);

  const totals = amountOwing ? amountOwing.Totals : undefined;
  if (!totals) return <NoData vertical />;
  return (
    <div className="cc-current-period-rates">
      <div className="cc-form">
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Assessment amounts owing</label>
              <CCGrid
                className="cc-charges-grid"
                data={dataGridBalances}
                columnFields={columnFields}
                primaryField="BalancesType"
              />
            </div>
          </div>
        </section>
        {totals?.CurrentPeriodRates ? (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <label className="cc-label">Current period rates</label>
              <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
                <CCValueField
                  label="Charges raised"
                  value={totals.CurrentPeriodRates?.ChargesRaised}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
                <CCValueField
                  label="Rebates"
                  value={totals.CurrentPeriodRates?.Rebates}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
                <CCValueField
                  label="Nett rates"
                  value={totals.CurrentPeriodRates?.NettRates}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
            </section>
          </>
        ) : null}
      </div>
      <br />
    </div>
  );
});
