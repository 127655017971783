import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { NewSubCategoryButton } from "@app/products/crms/system-admin/categories/[id]/components/forms/components/action-bar/buttons/new-subcategory/_index";
import { CRMSSystemAminCategoryForm } from "@app/products/crms/system-admin/categories/[id]/components/forms/components/child-screens/general/_index";
import { useCRMSSytemAdminCategoryStore } from "@app/products/crms/system-admin/categories/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewCRMSSystemAminCategory = observer(() => {
  const { crmsCategory, isLoading, responseLoadError, loadCategory, onSubmit } =
    useCRMSSytemAdminCategoryStore();
  const { settings } = useCommonCoreStore();

  const isNew = useIsNew();

  const cxLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCategory(0, isNew)}
        />
      ) : (
        <>
          <FormNavigation title={`${cxLabel ?? CRMS_PRODUCT_NAME} Category`} />
          <FormTitle title={"New Service Standard Category"} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
              <NewSubCategoryButton disabled={true} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsCategory && <CRMSSystemAminCategoryForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
