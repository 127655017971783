import { DTO_Create_Deferment_LOVs } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ModifyDefermentDialogStore {
  private _modifyDefermentLOVs?: DTO_Create_Deferment_LOVs = undefined;
  // rename DTO after model api update

  constructor() {
    makeAutoObservable(this);
  }

  get modifyDefermentLOVs() {
    return this._modifyDefermentLOVs;
  }
  setModifyDefermentLOVs = (modifyDefermentLOVs?: any) => {
    runInAction(() => {
      this._modifyDefermentLOVs = modifyDefermentLOVs;
    });
  };
}

const modifyDefermentDialogStoreContext = createContext(
  new ModifyDefermentDialogStore()
);
export const useModifyDefermentDialogStore = () => {
  return useContext(modifyDefermentDialogStoreContext);
};
