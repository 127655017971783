import { DTO_LOV_Transaction } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { DTO_LOV_Levy } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import {
  DTO_LOV_SuppType,
  Financial_Group,
} from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";

export enum EKeysOfStepsAddTransaction {
  AssessmentJournal = "AssessmentTransaction",
  NewChargeDetail = "NewCharge",
  DeferredDutyJournal = "DeferredDuty",
  AllocationChargeBalances = "AllocationChargeBalance",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfStepsAddTransaction = [
  EKeysOfStepsAddTransaction.AssessmentJournal,
  EKeysOfStepsAddTransaction.NewChargeDetail,
];

export interface DTO_Workflow_JournalAddTransaction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalAddAssessmentTransaction;
}

export interface RequestJournalAddTransactionObj {
  Journal_Number: number;
}

export interface DTO_Assessment_ChargeBalance {
  AssessmentId: number;
  ChargeBalances: DTO_ChargeBalance[];
}

export interface DTO_WorkflowDetail_JournalAddAssessmentTransaction {
  AssessmentTransaction: DTO_AssessmentTransaction;
  Assessments: DTO_Assessment[];
  NewCharge: DTO_AssessmentTransactionCharge;
  AllocationChargeBalance: DTO_AllocationChargeBalance;
}

export interface DTO_AssessmentTransactionCharge {
  ChargeDate: Date | null;
  TransactionDate: Date | null;
  TransactionType: number | null;
  RatingPeriod: number | null;
  LevyId: number;
  Code: string;
  Name: string;
  Description: string;
  Amount: number;
  InstalmentPlanId: number | null;
  ApplyRemainingEntitlements: boolean;
}

export interface DTO_ManualChargeBalance {
  Charge_Balances_Id: number;
  BalanceAmount: number;
  Interest: number;
}

export interface DTO_DDTransaction {
  AccountId: number;
  TransactionTypeId: number | null;
  TransactionDate: Date | null;
  Reference: string;
  Description: string;
  Notes: string;
  DeferredDutyAmount: number | null;
  InterestAmount: number | null;
  OtherAmount: number | null;
}

export interface DTO_AssessmentTransaction {
  Journal_Number: number;
  Transaction_Type: number;
  Amount: number;
  TransactionDateTime: Date;
  TransactionReference: string;
  TransactionDescription: string;
  TransactionNotes: string;
  AllocationMode: number;
  PaymentAllocationNo: number | null;
  Instalment_Plan_Id: number | null;
  AssessmentRebateEntitlementId: number | null;
}

export interface DTO_AllocationChargeBalance {
  ChargeBalance: DTO_ChargeBalance[];
  ManualChargeBalances: DTO_ManualChargeBalance[];
}

export interface DTO_Assessment {
  Valuation_3: number | null;
  Valuation_4: number | null;
  Valuation_5: number | null;
  Valuation_6: number | null;
  Valuation_7: number | null;
  Valuation_8: number | null;
  Valuation_9: number | null;
  Valuation_10: number | null;
  Valuation_11: number | null;
  Valuation_12: number | null;
  Valuation_13: number | null;
  Valuation_14: number | null;
  Valuation_15: number | null;
  Valuation_16: number | null;
  Valuation_17: number | null;
  Valuation_18: number | null;
  Valuation_2: number | null;
  Valuation_1: number | null;
  Primary_Assess_Land_Use_Name: string;
  Assessment_Status: number;
  Assessment_Id: number;
  Assess_Number: number | null;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement: number | null;
  Valuation_19: number | null;
  Unit_Entitlement_Percentage: number | null;
  Unit_Type_Percentage: number | null;
  Assess_Balance: number;
  Assess_Balance_Outstanding: number | null;
  Assess_Being_Postponed: boolean;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Unit_Type: string;
  Valuation_20: number | null;
}

export interface DTO_Journal_Add_Transaction_LOVs {
  AssessmentType: DTO_LOV_Number[];
  JournalTypes: DTO_LOV_Transaction[];
  TransactionTypes: DTO_LOV_Transaction[];
  CurrentRatingPeriodId: number;
  RatingPeriods: DTO_LOV_Number[];
  InstalmentPlans: DTO_LOV_Number[];
  PaymentAllocations: DTO_LOV_Number[];
  Levy: DTO_LOV_Levy[];
  RebateType: DTO_LOV_Number[];
  Financial_Groups: Financial_Group[];
  ValuationTypeTobeUsed: DTO_LOV[];
  IsRebateTypeTransaction: boolean;
  AssessRebateEntitlements: DTO_LOV_Number[];
  SupplementaryType: DTO_LOV_SuppType[];
  Officer: DTO_LOV_Number[];
  DDTransactionTypes: DTO_LOV[];
  ChargeTransactionClassifications: number[];
  PICTransactionTypes: DTO_LOV_Number[];
}

export interface DTO_Assessment_ChargeBalance {
  AssessmentId: number;
  ChargeBalances: DTO_ChargeBalance[];
}
export interface DTO_ChargeBalance {
  Charge_Balances_Id: number;
  CB_Original_Amount: number | null;
  CB_Balance_Amount: number | null;
  CB_Interest: number | null;
  CA_Charge_Date: Date | null;
  CI_Instalment_Number: number | null;
  Levy_Name: string;
  CI_Due_Date: Date | null;
  CA_Name: string;
  CA_Code: string;
  Adjusted_Amount: number | null;
  Adjusted_Interest: number | null;
}
