import { DTO_LOV_CertType } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";

export enum eCertificateType {
  Assessment = 1,
  Parcel = 2,
  ViewConfiguration = 3,
  PIC = 4,
  PICNew = 5,
  PICDelete = 6,
  Lot = 7,
  CertificateType = 8,
}

export enum eCertificateTypeComponent {
  PIC = "PIC",
  Parcel = "Parcel",
  Assessment = "Assessment",
  Lot = "Lot",
}

export enum eCertificateTypeName {
  AdverseAffectations = "Adverse Affectations",
}

export interface DTO_Certificate_Detail_LOVs {
  CertificateType: DTO_LOV_CertType[];
}
