import { AnnualStockCol } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/config";
import { DTO_LLS_Annualstock } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/model";
import { useAnnualStockAssessmentStore } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
interface IAnnualStockColAssessmentAccordionProps {
  assessmentId: number;
}
const nameOf = nameOfFactory<DTO_LLS_Annualstock>();
export const AnnualStock = observer(
  ({ assessmentId }: IAnnualStockColAssessmentAccordionProps) => {
    // Store
    const {
      annualStock,
      loadAnnualStock,
      isLoading,
      responseLoadError,
      resetStore,
    } = useAnnualStockAssessmentStore();

    useEffectOnce(() => {
      loadAnnualStock(+assessmentId);
      return () => {
        resetStore();
      };
    });

    if (isLoading) {
      return <Loading isLoading={isLoading} />;
    }

    if (responseLoadError) {
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadAnnualStock(+assessmentId);
          }}
        />
      );
    }
    return (
      <div className="cc-property-annual-returns">
        <CCGrid
          className="cc-property-annual-returns-grid"
          data={annualStock?.AnnualStockList ?? []}
          columnFields={AnnualStockCol}
          primaryField={nameOf("LLSReturnId")}
        />
      </div>
    );
  }
);
