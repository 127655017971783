import { SETTING_LOOKUP_LOCAL_GOVERNMENT_VIEW_URL } from "@common/pages/settings/lookups/local-government/constants";
import { ILocalGovernment } from "@common/pages/settings/lookups/local-government/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import { colPickCouncil } from "@components/cc-pick-council/config";
import { Council } from "@components/cc-pick-council/model";
import React from "react";

interface ICCPickCouncilProps extends ICCInputPickerProps {
  onPickCouncil: (value: ILocalGovernment | null) => void;
  selectableMode?: "single" | "multiple" | "none";
  dataUrl: string;
}
const nameOf = nameOfFactory<Council>();
export const CCPickCouncil = ({
  onChange,
  onError,
  onPickCouncil,
  selectableMode = "single",
  dataUrl = SETTING_LOOKUP_LOCAL_GOVERNMENT_VIEW_URL,
  ...others
}: ICCPickCouncilProps) => {
  const handleOnChangeCouncil = (event: ICCInputPickerChangeEvent) => {
    const value: ILocalGovernment | null = event.value;
    if (onPickCouncil) onPickCouncil(value);
  };
  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "Pick Council",
        maxWidth: "75%",
      }}
      grid={{
        columnFields: colPickCouncil,
        primaryField: nameOf("ID"),
        selectableMode: selectableMode,
        dataUrl: dataUrl,
        isAutoHiddenPager: false,
        state: { sort: [{ field: nameOf("Name"), dir: "asc" }] },
      }}
      onChange={handleOnChangeCouncil}
    />
  );
};
