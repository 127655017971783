import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
export const listFieldsConfig: ECorporateSettingsField[] = [
  ECorporateSettingsField.TP_PSAShowApplicantType,
  ECorporateSettingsField.TP_PSAShowFeeRequired,
  ECorporateSettingsField.TP_PSAShowPlanningPermitNo,
  ECorporateSettingsField.TP_PSAAllowMultiSelectAppCategory,
  ECorporateSettingsField.TP_PSAShowPresetPanelDateOnAdv,
  ECorporateSettingsField.TP_PSASetDtAdvStartsReadyOnly,
  ECorporateSettingsField.TP_PSASetDtAdvClosesReadyOnly,
];
