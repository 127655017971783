import { Valuation } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/dialogs/new-valuation/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
interface INewValuationDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isSubmitting: boolean;
  notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>;
}

const nameOf = nameOfFactory<Valuation>();
export const NewValuationDialog = ({
  onClose,
  onSubmit,
  isSubmitting,
  notificationRef,
}: INewValuationDialogProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, modified, valid } = formRenderProps;
        return (
          <CCDialog
            maxWidth="30%"
            maxHeight="45%"
            titleHeader="New Valuation"
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <CCLocalNotification ref={notificationRef} />
                <section className="cc-field-group">
                  <div className="cc-form-cols-2">
                    <div className="cc-field cc-col-span-2">
                      <CCLabel title="Effective date" isMandatory />
                      <Field
                        name={nameOf("EffectiveDate")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="cc-field cc-col-span-2">
                      <CCLabel title="Issue date" />
                      <Field
                        name={nameOf("IssuedDate")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                      />
                    </div>
                    <div className="cc-field cc-col-span-2">
                      <CCLabel title="Date of valuation" isMandatory />
                      <Field
                        name={nameOf("ValuationDate")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  className="cc-dialog-button"
                  disabled={!modified || isSubmitting || !valid}
                  iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
                  onClick={onSubmit}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
