export const mockInError = [
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78703,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "62",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78722,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "81",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78715,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "74",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78703,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "59",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78714,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "73",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78725,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "84",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78710,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "69",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78723,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "82",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78720,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "79",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78709,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "68",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78697,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "56",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78704,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "63",
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78707,
    DateRequestRegistered: new Date("4-Jul-2017"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7F1A",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "66",
  },
  {
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 80811,
    DateRequestRegistered: new Date("21-Sep-17"),
    OrderStatus: "In Error",
    ExceptionDescription: "Error Message",
    ApplicantName: "0x0042E04868448847950B1",
    ApplicantAddress: "0x0042E04868448847950B1334A7",
    ID: "94",
  },
];
