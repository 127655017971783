import { IPickSiteUser } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/model";
import { PPRPSAReferral } from "@app/products/town-planning/ppr/psa-referrals/register/model";
import { nameOfFactory } from "@common/utils/common";
import { ICalendarData } from "@components/cc-calendar/model";

const nameOf = nameOfFactory<PPRPSAReferral>();
export const modelFields: ICalendarData = {
  id: nameOf("ID"),
  title: nameOf("CalendarSubject"),
  start: nameOf("DirectionsHearingDate"),
  end: nameOf("HearingDateEnd"),
};

export const defaultCalendarDropDownData: IPickSiteUser = {
  ID: 0,
  Name: "Everyone",
};
