import { ChangeStatus } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/model";
import { PPREnquiryIdentityPacket } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getEnquiryStatus = async (): Promise<
  APIResponse<IKeyValuePacket[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `api/tp/ppr/internal/enquiry/status`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putEnquiryStatus = async (
  id: number,
  changeStatus: ChangeStatus
): Promise<APIResponse<PPREnquiryIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `api/tp/ppr/internal/enquiry/${id}/changestatus`,
      changeStatus
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
