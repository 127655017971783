import { Svc_UpdateKennelNoticeSentRequest } from "@app/products/animals/kennels-renewals/requires-renewal-notice/components/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postUpdateRenewalNoticeSent = async (
  updateKennelNoticeSentRequest: Svc_UpdateKennelNoticeSentRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/animals/internal/kennel/update-renewal-notice-sent?isNoticeSent=${updateKennelNoticeSentRequest.isNoticeSent}`,
      updateKennelNoticeSentRequest.kennelsIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
