import { VO_DeferredDuty } from "@app/products/property/deferred-duty/list/all/model";
import { PROPERTY_DEFERRED_DUTY_ROUTE } from "@app/products/property/deferred-duty/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<VO_DeferredDuty>();

export const colPropertyDeferredDutyAll: IColumnFields[] = [
  {
    field: nameOf("Account_Number"),
    title: "Account Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => {
      return `${PROPERTY_DEFERRED_DUTY_ROUTE}/${dataItem.Schema_Acccount_ID}`;
    },
  },
  {
    field: nameOf("Assessment"),
    title: "Assessment (S-S-B-U)",
  },
  { field: nameOf("Account_Status"), title: "Account Status", width: 150 },
  {
    field: nameOf("Account_Name"),
    title: "Account Name",
  },
  {
    field: nameOf("DeferredDuty_balance"),
    title: "Deferred Duty Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("DeferredDuty_balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Interest_Balance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Interest_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Balance_OutStanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Balance_OutStanding"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Payment_Amount"),
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Payment_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Interest_Raised_Up_To"),
    title: "Interest Raised Up To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Overiding_Interest_Rate"),
    title: "Overiding Interest Rate",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Number_Of_Installment"),
    title: "Number of Instalments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Installment_Amount"),
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Installment_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Other_Balance"),
    title: "Other Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Other_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  { field: nameOf("Alert_Message"), title: "Alert Message", width: 150 },
  {
    field: nameOf("Account_Description"),
    title: "Account Description",
  },
  {
    field: nameOf("Comment"),
    title: "Comment",
  },
  {
    field: nameOf("RatePayer_Name_Address"),
    title: "Ratepayer Name Address",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: nameOf("Last_Changed_By"),
    title: "Last Changed By",
  },
  {
    field: nameOf("Last_Changed_On"),
    title: "Last Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Schema_Acccount_ID"),
    title: "Scheme Account ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
