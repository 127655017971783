import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { eCertificateRequestsRoute } from "./e-certificate-requests/route";

export const certificateRoute: ICCRoute = {
  path: "certificate",
  name: "Certificates",
  enum: eMenuAction.Certificate_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Certificate_List,
      component: require("./list/_index").default,
    },
    {
      path: "active-by-resp-area",
      name: "Active Certificates By Responsible Area",
      enum: eMenuAction.Certificate_List_Active,
      component: require("./active-by-resp-area/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: eMenuAction.Certificate_List_Active_By_Type,
      component: require("./by-type/_index").default,
    },
    eCertificateRequestsRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
