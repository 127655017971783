import { DTO_Owners } from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { PERCENTAGE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfOwner = nameOfFactory<DTO_Owners>();
export const colOwnersConcessionCard: IColumnFields[] = [
  {
    field: nameOfOwner("Name"),
    title: "Owner/Life Tenant",
  },
  {
    field: nameOfOwner("Ownership_Percentage"),
    title: "Ownership Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
];
