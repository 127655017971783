import { CORE_GET_HOME_PAGE_SHORTCUTS } from "@app/core/components/common/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { HomepageShortcutsHistoryTab } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/components/reference-sidebar/history/_index";
import { AddUsersButton } from "@common/pages/settings/homepage/homepage-shortcuts/components/add-user-button/_index";
import { RemoveUsersButton } from "@common/pages/settings/homepage/homepage-shortcuts/components/remove-user-button/_index";
import { colHomepageShortcutsListView } from "@common/pages/settings/homepage/homepage-shortcuts/config";
import { HOMEPAGE_SHORTCUTS_ROUTE } from "@common/pages/settings/homepage/homepage-shortcuts/constant";
import { Svc_HomepageShortcuts } from "@common/pages/settings/homepage/homepage-shortcuts/model";
import {
  HomepageShortcutsBookmark,
  HomepageShortcutsDetailsBookmark,
} from "@common/pages/settings/homepage/homepage-shortcuts/util";
import { HomepageType } from "@common/pages/settings/homepage/model";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_HomepageShortcuts>();
export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.HOMEPAGE_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="Tools" type="more">
        <AddUsersButton homepageType={HomepageType.HomepageShortcut} />
        <RemoveUsersButton homepageType={HomepageType.HomepageShortcut} />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={HOMEPAGE_SHORTCUTS_ROUTE}
        productType={PRODUCT_TYPE.HomePage}
        recordType={RECORDTYPE.Core_HomePageView}
        detail={HomepageShortcutsDetailsBookmark.getBookmarkDetail}
        displayName={HomepageShortcutsDetailsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          HomepageShortcutsBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={HomepageShortcutsBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <HomepageShortcutsHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colHomepageShortcutsListView}
      primaryField={nameOf("ID")}
      dataUrl={CORE_GET_HOME_PAGE_SHORTCUTS}
      state={{ sort: [{ field: nameOf("ProductType_Name"), dir: "asc" }] }}
      disabled={!hasPermission}
    />
  );
});
