import { DTO_Session_Journals } from "@app/products/property/journals/session-list/[id]/model";
import { colDataUpdateGeneralDialog } from "@app/products/property/journals/session-list/components/dialogs/session-dialog/config";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IRollbackStatusDialog {
  onClosePopup: () => void;
  journalInfo?: DTO_Session_Journals | undefined;
  onOKButton?: () => void;
}
export interface IDataGridDialog {
  Session_Number: number;
  Session_Name: string;
}
export default function RollbackStatusDialog({
  onClosePopup,
  journalInfo,
}: IRollbackStatusDialog) {
  const { gridSelectedRows } = useCCProductListViewStore();

  const [data, setData] = useState<IDataGridDialog[]>([]);

  useEffectOnce(() => {
    if (!journalInfo) {
      const dataAfterMap = gridSelectedRows.map((item: any) => {
        return {
          Session_Number: item.sessionNumber,
          Session_Name: item.name,
        };
      });
      setData(dataAfterMap);
    } else {
      const getOneRecord: any = {
        Session_Number: journalInfo.SessionNumber,
        Session_Name: journalInfo.Session_Name,
      };
      setData([getOneRecord]);
    }
  });

  return (
    <CCDialog
      height="auto"
      width="auto"
      titleHeader={"Rollback Confirmation"}
      onClose={onClosePopup}
      bodyElement={
        <>
          <h6>The selected session will be rolled-back:</h6>
          <CCGrid
            data={data}
            columnFields={colDataUpdateGeneralDialog}
            primaryField="SessionNumber"
          />
        </>
      }
      footerElement={
        <div className={"cc-dialog-footer-actions-right"}>
          <Button className={"cc-dialog-button"} onClick={onClosePopup}>
            Cancel
          </Button>

          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={onClosePopup}
          >
            OK
          </Button>
        </div>
      }
    />
  );
}
