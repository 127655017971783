import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { LookupStateRoadFormElement } from "@common/pages/settings/lookups/state-roads/_id/components/child-screens/general/components/form-element/_index";
import {
  LookupStateRoadActions,
  StateRoad,
} from "@common/pages/settings/lookups/state-roads/_id/model";
import { useLookupStateRoadStore } from "@common/pages/settings/lookups/state-roads/_id/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const LookupStateRoadForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { stateRoad, saveStateRoad, setOnSubmit } = useLookupStateRoadStore();
  const { settings } = useCommonCoreStore();

  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const defaultState: string =
    settings[
      ECorporateSettingsField.CorporateSettings_AddressDefaultState
    ]?.Value.toString();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const action = event.event?.currentTarget.name as LookupStateRoadActions;
    if (!(action in LookupStateRoadActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [LookupStateRoadActions.Save, LookupStateRoadActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    const stateRoadInfo = values as StateRoad;

    saveStateRoad(
      {
        ...stateRoadInfo,
        RoadState: !isEmpty(stateRoadInfo?.RoadState)
          ? stateRoadInfo?.RoadState
          : defaultState,
      },
      action
    );
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, stateRoad]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={stateRoad}
        key={JSON.stringify(stateRoad)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <LookupStateRoadFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
