import {
  deleteAttachments,
  postAttachment,
} from "@app/core/documents/components/documents-input/api";
import { IAttachmentFile } from "@app/core/documents/components/documents-input/model";
import {
  getFileUploadValidationMessage,
  toAttachmentFiles,
} from "@app/core/documents/components/documents-input/util";
import { Attachment } from "@app/core/documents/model";
import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { getUUID } from "@common/utils/common";
import { customLogger } from "@common/utils/logger";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  Upload,
  UploadFileRestrictions,
  UploadOnAddEvent,
} from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";
import { isEmpty, isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import "./_index.scss";

export interface ICCDocumentsInputProps {
  mode?: "single" | "multiple";
  value?: Attachment[];
  onChange?: (attachments: IAttachmentFile[]) => void;
  disabled?: boolean;
  title?: string;
  onClick?: (attachment: Attachment) => void;
  restrictions?: UploadFileRestrictions | undefined;
  isUploadOnAdd?: boolean;
}
export const CCDocumentsInput = ({
  value = [],
  mode = "multiple",
  onChange,
  onClick,
  title = "View File",
  disabled = false,
  restrictions,
  isUploadOnAdd = true,
}: ICCDocumentsInputProps) => {
  const [attachments, setAttachments] = useState<IAttachmentFile[]>(
    toAttachmentFiles(value)
  );

  const updateAttachment = useCallback(
    (attachment: IAttachmentFile) => {
      setAttachments((currentAtt) => {
        const newAttachments = currentAtt.map((att) =>
          att._uid === attachment._uid ? { ...att, ...attachment } : att
        );
        if (onChange) onChange(newAttachments);
        return newAttachments;
      });
    },
    [onChange]
  );

  const pushAttachment = useCallback(
    (attachment: IAttachmentFile) => {
      if (mode === "single") {
        setAttachments([attachment]);
        return;
      }
      setAttachments((currentAtt) => [...currentAtt, attachment]);
    },
    [mode]
  );

  const removeAttachment = useCallback(
    (uid: string) => {
      setAttachments((currentAtt) => {
        const newAttachments =
          mode === "single"
            ? []
            : currentAtt.filter((att, i) => att._uid !== uid);
        if (onChange) onChange(newAttachments);
        return newAttachments;
      });
    },
    [mode, onChange]
  );

  const handleOnAdd = useCallback(
    ({ newState }: UploadOnAddEvent) => {
      if (mode === "single") {
        newState = [newState[newState.length - 1]];
      }
      newState.forEach((kendoFile) => {
        const fileJs = kendoFile.getRawFile ? kendoFile.getRawFile() : null;
        if (!kendoFile || !fileJs) return;

        let newAttachment = {
          _uid: getUUID(),
          Attachment_ID: 0,
          FileEntry: {
            FileName: fileJs.name,
            ContentType: fileJs.type,
            FileSize: fileJs.size,
          },
          Title: fileJs.name,
          Category_KWD: null,
          FileNumber: "",
          TRIMUri: "",
        } as IAttachmentFile;

        if (
          !kendoFile?.validationErrors ||
          isEmpty(kendoFile?.validationErrors)
        ) {
          newAttachment = {
            ...newAttachment,
            _isLoading: true,
            _isFailed: false,
          } as IAttachmentFile;
          pushAttachment(newAttachment);
          let reader = new FileReader();
          reader.readAsArrayBuffer(fileJs);
          reader.onload = () => {
            if (reader.result) {
              const attachmentBase64 = encode(reader.result as ArrayBuffer);
              if (!newAttachment.FileEntry) return;
              newAttachment.FileEntry.Base64FileAttachment = attachmentBase64;
              updateAttachment(newAttachment);
              if (isUploadOnAdd) {
                postAttachment(newAttachment).then((response) => {
                  newAttachment._isLoading = false;
                  if (
                    isSuccessResponse(response) &&
                    response.data.ErrorStatus ===
                      IdentityPacketErrorStatus.Success
                  ) {
                    newAttachment.Attachment_ID = response.data.ID;
                  } else {
                    newAttachment._isFailed = true;
                  }
                  updateAttachment(newAttachment);
                });
              } else {
                newAttachment._isLoading = false;
                updateAttachment(newAttachment);
              }
            }
          };
          return;
        }
        newAttachment = {
          ...newAttachment,
          _isLoading: false,
          _isFailed: true,
          _validationErrors: getFileUploadValidationMessage(
            kendoFile?.validationErrors?.[0]
          ),
        } as IAttachmentFile;
        pushAttachment(newAttachment);
        updateAttachment(newAttachment);
      });
    },
    [mode, pushAttachment, updateAttachment, isUploadOnAdd]
  );

  const handleOnRemove = useCallback(
    (attachment: IAttachmentFile) => {
      if (!attachment.Attachment_ID || attachment._isFailed) {
        removeAttachment(attachment._uid);
        return;
      }

      let newAttachment = { ...attachment };
      newAttachment._isLoading = true;
      updateAttachment(newAttachment);
      deleteAttachments([attachment.Attachment_ID]).then((response) => {
        customLogger("Core documents, deleteAttachments").info(response?.data);
        if (
          isSuccessResponse(response) &&
          response.data.ErrorStatus === IdentityPacketErrorStatus.Success &&
          response.data.SuccessCount === 1
        ) {
          removeAttachment(attachment._uid);
        } else {
          newAttachment._isLoading = false;
          newAttachment._isFailed = true;
          updateAttachment(newAttachment);
        }
      });
    },
    [updateAttachment, removeAttachment]
  );

  useEffect(() => {
    if (!isEqual(value, attachments)) setAttachments(toAttachmentFiles(value));
    // eslint-disable-next-line
  }, [value]);

  return (
    <div
      className={`cc-documents-input${
        disabled ? " cc-documents-input-disabled" : ""
      }`}
    >
      <Upload
        files={[]}
        onAdd={handleOnAdd}
        restrictions={restrictions}
        autoUpload={false}
      />
      {attachments?.map((attachment, index) => (
        <div className="cc-document">
          <div className="cc-document-icon-file">
            {attachment._isLoading ? (
              <i className="fas fa-spinner fa-spin" />
            ) : attachment._isFailed ? (
              <i className="fas fa-times-circle" />
            ) : (
              <i
                className="k-icon k-i-file"
                onClick={() => {
                  if (onClick) onClick(attachment);
                }}
                title={title}
              />
            )}
          </div>
          <div className="cc-document-name">
            {attachment.FileEntry?.FileName}
            {attachment._validationErrors && (
              <Error> {attachment._validationErrors} </Error>
            )}
          </div>
          <div className="cc-document-icon-remove">
            <Button
              disabled={attachment._isLoading}
              icon="close"
              title="Remove"
              fillMode={null}
              onClick={(event) => {
                event.preventDefault();
                handleOnRemove(attachment);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
