import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { HomePageShortcutsForm } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/components/child-screens/general/_index";
import { HomepageShortcutsHistoryTab } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/components/reference-sidebar/history/_index";
import { useHomePageShortcutsStore } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/store";
import { renderHomepageTitle } from "@common/pages/settings/homepage/homepage-shortcuts/util";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ManageHomepageShortcuts = () => {
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const {
    homepageShortcuts,
    homepageShortcutsId,
    onSubmit,
    loadHomepageShortcuts,
    homepageShortcutsLovs,
    isLoading,
    responseLoadError,
  } = useHomePageShortcutsStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);
  const managePageUrl = window.location.pathname;

  useEffect(() => {
    if (isHasPermission) {
      loadHomepageShortcuts(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isHasPermission]);

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([homepageShortcuts?.FunctionHeading])}`,
      LinkUrl: managePageUrl,
      LinkText: `Settings - Homepage - Homepage shortcuts - ${
        homepageShortcutsId ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HomePage,
      RecordType_ENUM: RECORDTYPE.Core_HomePageFunction,
      Record_ID: homepageShortcutsId ? +homepageShortcutsId : 0,
    },
  ];

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.HOMEPAGE_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Homepage Shortcut"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadHomepageShortcuts(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={renderHomepageTitle(
              homepageShortcutsLovs,
              homepageShortcuts
            )}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title="Save"
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {homepageShortcuts && <HomePageShortcutsForm />}
              </div>
            </div>
            {showSlideBar && homepageShortcuts && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "History",
                      component: <HomepageShortcutsHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageHomepageShortcuts);
