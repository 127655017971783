import { getDebtRecovery } from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/api";
import {
  DTO_DebtRecoveryExemption,
  DTO_DebtRecovery_Ext,
} from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import { convert } from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/util";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AssessmentDebtRecoveryStore {
  private _debtRecovery?: DTO_DebtRecovery_Ext[] = undefined;
  private _debtRecoveryExemptions?: DTO_DebtRecoveryExemption[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get debtRecovery() {
    return this._debtRecovery;
  }
  setDebtRecovery = (debtRecovery?: DTO_DebtRecovery_Ext[]) => {
    runInAction(() => {
      this._debtRecovery = debtRecovery;
    });
  };

  get debtRecoveryExemptions() {
    return this._debtRecoveryExemptions;
  }
  setDebtRecoveryExemptions = (
    debtRecoveryExemptions?: DTO_DebtRecoveryExemption[]
  ) => {
    runInAction(() => {
      this._debtRecoveryExemptions = debtRecoveryExemptions;
    });
  };

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get isLoading() {
    return this._isLoading;
  }

  //Action
  loadDebtRecovery = async (assessmentId: number, view: number) => {
    this.setIsLoading(true);
    const debtRecovery = await getDebtRecovery(assessmentId, view);
    if (debtRecovery?.DebtRecoveries) {
      let debtRecoveryDataConvert: DTO_DebtRecovery_Ext[] = [];
      debtRecoveryDataConvert = debtRecovery?.DebtRecoveries.map((item) => {
        return convert(item);
      });
      this.setDebtRecovery(debtRecoveryDataConvert);
    }
    this.setDebtRecoveryExemptions(debtRecovery?.DebtRecoveryExemptions || []);
    this.setIsLoading(false);
  };
}

const assessmentDebtRecoveryStoreContext = createContext(
  new AssessmentDebtRecoveryStore()
);
export const useAssessmentDebtRecoveryStore = () => {
  return useContext(assessmentDebtRecoveryStoreContext);
};
