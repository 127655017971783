import { GroupLevySumary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/group-levy-summary/_index";
import { useChargeRunLevySumaryGroupStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/components/group-levy-summary/store";
import { levyBalancesGrids } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/utils";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";

export const ChargeRunRebateLevySummary = observer(() => {
  const { isLoading } = useChargeRunLevySumaryGroupStore();
  const [groupTypeSelected, setGroupTypeSelected] = useState(
    levyBalancesGrids[2]
  );
  const handleChangeGrid = (event: DropDownListChangeEvent) => {
    setGroupTypeSelected(event.target.value);
  };
  return (
    <div className="cc-charge-notice-run-levy-summary">
      <div className="cc-actions cc-grid-control-left">
        <span className="cc-control-item cc-label">View</span>
        <div className="cc-field">
          <CCDropDownList
            className="cc-form-control"
            data={levyBalancesGrids}
            textField="title"
            dataItemKey="id"
            value={groupTypeSelected}
            onChange={handleChangeGrid}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="cc-charge-notice-run-levy-balance-grid-container">
        <GroupLevySumary groupTypeId={groupTypeSelected?.id} />
      </div>
    </div>
  );
});
