import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CommunicationQueue } from "@common/pages/settings/communication/queue/_id/model";

export const getCommunicationQueueById = async (
  communicationQueueId: number
): Promise<APIResponse<CommunicationQueue | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<CommunicationQueue>(
      `api/core/internal/communciationqueue/${communicationQueueId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
