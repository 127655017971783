import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { THandleCatchResponse } from "@common/apis/model";
import { handleCatchResponse, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { statusAliasFetchDoneEventType } from "@common/hooks/flexible-fetch-data/constant";
import {
  IUseFlexibleFetchData,
  TApiProps,
} from "@common/hooks/flexible-fetch-data/model";
import { flexibleFetchDataStoreInstance } from "@common/hooks/flexible-fetch-data/store";
import {
  errorPromiseAllResponse,
  isSkipSlides,
} from "@common/hooks/flexible-fetch-data/util";
import { customLogger } from "@common/utils/logger";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { isArray } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const fetchApiByAlias = async (alias: string, data?: TApiProps) => {
  eventEmitter.emit(alias, data);
  await flexibleFetchDataStoreInstance?.waitingFetch;
  return flexibleFetchDataStoreInstance.isSuccess;
};

export const cancelRequestByAlias = async (alias: string) => {
  eventEmitter.emit(alias, "cancelRequest");
};

export const useAfterAliasFetchDone = (alias: string, callBack: Function) => {
  const aliasFetchDone = useRef("");
  useEffect(() => {
    if (aliasFetchDone.current === alias) callBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasFetchDone.current]);

  useAddUniqueEventEmitter([
    {
      eventType: statusAliasFetchDoneEventType,
      listener: (alias: string) => {
        aliasFetchDone.current = alias;
      },
    },
  ]);
};

export const useFlexibleFetchData = ({
  slides,
  isActive = true,
  alias,
  beforeFetch,
  cancelRequest,
}: IUseFlexibleFetchData) => {
  const initialDataRef = useRef<any>();
  const paramRef = useRef<any>();
  const dataFromBeforeFetchRef = useRef<any>();
  const dataFromApiOfSlide = useRef<Partial<Record<number, any>>>({});
  const refTimeOut = useRef<number | null>(null);

  const [isFetching, setIsFetching] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [errorContent, setErrorContent] = useState<{
    initialData: any;
    dataFromBeforeFetch?: any;
    errorFromApi: THandleCatchResponse;
  }>();

  useEffect(() => {
    setIsDone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useAddUniqueEventEmitter([
    {
      eventType: alias ?? "NoEvent",
      listener: (data?: TApiProps | string) => {
        if (data === "cancelRequest") {
          handleCancelRequest();
        } else {
          fetchApi(data as TApiProps);
        }
      },
    },
  ]);

  const handleDebounce = (callBack: Function, delay: number) => {
    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = window.setTimeout(async () => {
      callBack();
    }, delay);
  };

  const handleCancelRequest = () => {
    if (cancelRequest) {
      cancelRequest();
    } else {
      customLogger("useFlexibleFetchData - handleCancelRequest").error(
        `cancelRequest is undefined for alias ${alias}`
      );
    }
    setIsFetching(false);
  };

  const fetchApi = useCallback(
    async (props?: TApiProps) => {
      if (!isActive) return;

      if (cancelRequest) {
        cancelRequest();
      } else {
        if (isFetching) return;
      }

      //#region handle waiting fetchDataByAlias ========/
      alias && flexibleFetchDataStoreInstance?.resetWaitingFetch();
      //#endregion handle waiting fetchDataByAlias =====/

      const { initialData, skipSlide } = props || {};
      if (initialData) {
        initialDataRef.current = initialData;
      }
      if (beforeFetch) {
        dataFromBeforeFetchRef.current = beforeFetch({
          initialData: initialDataRef.current,
        });
      }

      let index = 0;
      for (const slide of slides) {
        const currentSlide = index + 1;
        if (skipSlide && isSkipSlides(currentSlide, skipSlide)) {
          index++;
          continue;
        }
        let apiError = null;
        let apiResult = null;
        try {
          setErrorContent(undefined);
          setIsFetching(true);
          const dataFetch = {
            dataFromExtractParam: paramRef.current,
            dataFromBeforeFetch: dataFromBeforeFetchRef.current,
            initialData: initialDataRef.current,
            dataFromApiOfSlide: dataFromApiOfSlide.current,
          };
          const slideFetchResult = await slide.fetch(dataFetch);
          if (isArray(slideFetchResult)) {
            apiResult = await Promise.all(slideFetchResult);
            apiError = errorPromiseAllResponse(apiResult);
          } else {
            apiResult = await slideFetchResult;
            if (!isSuccessResponse(apiResult)) {
              apiError = apiResult;
            }
          }
          //#region Handle Error ========/
          if (apiError) {
            //#region handle waiting fetchDataByAlias ========/
            flexibleFetchDataStoreInstance?.handleNext();
            flexibleFetchDataStoreInstance.setIsSuccess(false);
            //#endregion handle waiting fetchDataByAlias =====/

            const dataError = {
              initialData: initialDataRef.current,
              dataFromBeforeFetch: dataFromBeforeFetchRef.current,
              errorFromApi: handleCatchResponse(apiError),
              dataFromApiOfSlide: dataFromApiOfSlide.current,
            };
            setErrorContent(dataError);

            if (apiError?.status === APIResponseStatus.CANCELLED) {
              if (slide.handleCancelRequest) {
                slide.handleCancelRequest(dataError);
              }
              break;
            }
            setIsFetching(false);
            if (slide.handleError) {
              slide.handleError(dataError);
            }
            break;
          }
          //#endregion Handle Error =====/

          //#region Handle Success ========/
          dataFromApiOfSlide.current[currentSlide] = apiResult;

          const dataSuccess = {
            dataFromApi: apiResult,
            dataFromExtractParam: paramRef.current,
            dataFromBeforeFetch: dataFromBeforeFetchRef.current,
            initialData: initialDataRef.current,
            dataFromApiOfSlide: dataFromApiOfSlide.current,
          };

          if (slide.extractParamForNextAPI) {
            paramRef.current = slide.extractParamForNextAPI(dataSuccess);
          }
          if (slide.handleSuccess) {
            setIsFetching(false);
            const runSlides = await slide.handleSuccess(dataSuccess);
            if (runSlides === false) {
              setIsDone(true);
              //#region handle waiting fetchDataByAlias ========/
              flexibleFetchDataStoreInstance?.handleNext();
              flexibleFetchDataStoreInstance.setIsSuccess(true);
              //#endregion handle waiting fetchDataByAlias =====/
              break;
            }
          }
          //#endregion Handle Success =====/

          //#region Handle return status ========/
          if (index === slides.length - 1) {
            setIsDone(true);
            setIsFetching(false);
            //#region handle waiting fetchDataByAlias ========/
            flexibleFetchDataStoreInstance?.handleNext();
            flexibleFetchDataStoreInstance.setIsSuccess(true);
            //#endregion handle waiting fetchDataByAlias =====/
          }
          //#endregion Handle return status =====/
        } catch (err) {
          customLogger("useFlexibleFetchData").error(err);
          setIsFetching(false);
          break;
        }
        index++;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, slides]
  );

  let errorComponent: JSX.Element | null = null;
  errorComponent = useMemo(() => {
    if (errorContent?.errorFromApi) {
      return (
        <CCLoadFailed
          key={JSON.stringify(errorContent.errorFromApi)}
          responseError={errorContent.errorFromApi}
          onReload={() => fetchApi(initialDataRef.current)}
          isLoadingButton={isFetching}
        />
      );
    }
    return null;
  }, [errorContent, isFetching, fetchApi]);

  alias && isDone && eventEmitter.emit(statusAliasFetchDoneEventType, alias);

  return {
    fetchApi,
    isFetching,
    errorComponent,
    errorResponse: errorContent?.errorFromApi,
    isDone,
    cancelRequest: handleCancelRequest,
    debounce: handleDebounce,
  };
};
