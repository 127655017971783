import { AmsUrl } from "@app/products/ams/constants/ams.url";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import {
  IAmsDashBoardActivity,
  IAmsDashBoardWarrantyChart,
} from "@common/pages/home/model";
import { globalStoreInstance } from "@common/stores/global/store";

export const getDashboardData = async () => {
  return await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/dashboard/summary`
  );
};

export const getAssetActivityDashboard =
  async (): Promise<IAmsDashBoardActivity> => {
    const response: IResponseData = await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.GET_DASHBOARD_ASSET_COUNT_TILES}`
    );
    if (
      response.status !== APIResponseStatus.SUCCESS ||
      !response.data ||
      response.data.length === 0
    ) {
      return {
        Asset_Count: 0,
        Asset_Category_Count: 0,
        Warranty_3_month: 0,
      };
    }
    return response.data[0];
  };

export const getAssetWarrantyChart =
  async (): Promise<IAmsDashBoardWarrantyChart> => {
    const response: IResponseData = await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}${AmsUrl.GET_DASHBOARD_WARRANTY_CHART}`
    );

    let result: IAmsDashBoardWarrantyChart = {
      categorydescription: "Asset warranty by category",
      expiredinlast2months: 50,
      expiresin1month: 30,
      expiresbetweennext1and2months: 12,
      expiresbetweennext2and3months: 3,
    };

    if (
      response.status !== APIResponseStatus.SUCCESS ||
      !response.data ||
      response.data.length === 0
    ) {
      return result;
    }

    response.data.forEach((item: IAmsDashBoardWarrantyChart) => {
      result.expiredinlast2months += item.expiredinlast2months;
      result.expiresin1month += item.expiresin1month;
      result.expiresbetweennext1and2months +=
        item.expiresbetweennext1and2months;
      result.expiresbetweennext2and3months +=
        item.expiresbetweennext2and3months;
    });

    result = {
      ...result,
      expiredinlast2months: result.expiredinlast2months,
      expiresin1month: result.expiresin1month,
      expiresbetweennext1and2months: result.expiresbetweennext1and2months,
      expiresbetweennext2and3months: result.expiresbetweennext2and3months,
    };
    return result;
  };
