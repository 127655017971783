import { ExistManageInspection } from "@app/products/hm/inspections/[id]/components/forms/exist/_index";
import { NewManageInspection } from "@app/products/hm/inspections/[id]/components/forms/new/_index";
import { useInspectionsStore } from "@app/products/hm/inspections/[id]/store";
import { usePremisesStore } from "@app/products/hm/premises/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

const ManageInspection = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadInspections, resetStore } = useInspectionsStore();
  const { loadPremises, premisesId: parentId } = usePremisesStore();

  //#region Load inspection selection
  useEffect(() => {
    if (!isNew) {
      return;
    }
    if (parentId) loadPremises(parentId);
  }, [isNew, loadPremises, parentId]);

  useEffect(() => {
    if (isNew) return;
    loadInspections(id, parentId);
    return () => {
      resetStore();
    };
  }, [id, isNew, loadInspections, resetStore, parentId]);

  if (isNew) {
    return <NewManageInspection />;
  }

  return <ExistManageInspection />;
});

export default ManageInspection;
