import { getOfficerSearch } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/api";
import { ResponsibleOfficerSearch } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/components/responsible-officer/_index";
import { mockFinancialGroup } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/components/form-elements/detail/mock";
import { useNewDebtRecoveryDialogStore } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/store";
import { nameOfLov } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useGlobalStore } from "@common/stores/global/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  ComboBoxChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = { isReadOnly: false, workflowDraftId: 0 },
    isLoadingStep,
    setIsLoadingStep = () => {},
  }: IFormStepElement) => {
    const { currentUserInfo } = useGlobalStore();
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { debtRecoveryLOVs } = useNewDebtRecoveryDialogStore();
    const debtRecoveryTypeData =
      debtRecoveryLOVs?.dtoDebtRecovery_LOVs?.DebtRecoveryType ?? [];
    useEffectOnce(() => {
      getOfficer(
        options.workflowDraftId
          ? getFieldValue("ResponsibleOfficer")
          : currentUserInfo?.userDisplayName
      );
    });

    const getOfficer = async (valueSearch: string | undefined) => {
      const filterParam: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
          {
            logic: "and",
            filters: [
              {
                field: "FullName",
                operator: "contains",
                value: valueSearch,
              },
            ],
          },
        ],
      };
      setIsLoadingStep(true);
      const response = await getOfficerSearch(filterParam);
      if (isSuccessResponse(response) && response?.data?.value?.[0]) {
        onChange(nameOf("CurrentResponsibleOfficerUser"), {
          value: response.data.value[0],
        });
        onChange(nameOf("ResponsibleOfficer"), {
          value: response.data.value[0]?.FullName,
        });
      }
      setIsLoadingStep(false);
    };

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Debt recovery date</label>
            <Field
              name={nameOf("DebtRecoveryDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Assessment (S-S-B-U)</label>
            <Field
              name={nameOf("Assessment.AssessmentReference")}
              component={CCInput}
              readOnly
            />
          </div>

          <div className="cc-field">
            <Field
              name={nameOf("Assessment.CombinedString")}
              component={CCTextArea}
              rows={3}
              readOnly
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Include other assessments with balances for the same owner
            </label>
            <Field
              name={nameOf("IncludeOtherAssessment")}
              checked={getFieldValue("IncludeOtherAssessment")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Include outstanding charges with a Due date up to
            </label>
            <Field
              name={nameOf("DueDateUpTo")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Type</label>
            <Field
              name={nameOf("DebtRecoveryTypeId")}
              component={CCSearchComboBox}
              data={debtRecoveryTypeData}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Solicitor reference</label>
            <Field
              name={nameOf("SolicitorReference")}
              component={CCInput}
              placeholder={"Solicitor reference"}
              readOnly={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Financial group</label>
            <Field
              name={nameOf("FinancialGroup")}
              component={CCMultiSelectDropdown}
              //TODO: Add data from api
              data={mockFinancialGroup}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              placeholder="Financial group"
              onChange={(event: MultiSelectChangeEvent) => {
                onChange(nameOf("FinancialGroup"), {
                  value: event.value,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Responsible officer</label>
            <Field
              name={nameOf("CurrentResponsibleOfficerUser")}
              component={ResponsibleOfficerSearch}
              textField={"FullName"}
              value={getFieldValue("CurrentResponsibleOfficerUser")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("CurrentResponsibleOfficerUser"), {
                  value: event.value,
                });
                onChange(nameOf("ResponsibleOfficer"), {
                  value: event.value?.FullName,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
