import { IRecordsTypes } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IRecordsTypes>();

export const colRecordTypesPicker: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  { title: "Description", field: nameOf("Description") },
];
