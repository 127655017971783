import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { handleFormatPhoneValue } from "@components/cc-input-phone/util";
import { KEY_ENTER } from "@components/cc-input/contant";
import { Input, InputProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React, { useEffect, useMemo, useState } from "react";

interface ICCInputPhoneProps {
  validationMessage?: string | null;
  visited?: boolean;
  readOnly: boolean | undefined;
  disabled: boolean | undefined;
  value: string;
  name: string;
  mode: EModePhoneNumber;
  skipValidatePhoneMask?: boolean;
  onChangeForm: (
    name: string,
    options: {
      value: string;
    }
  ) => void;
}

export const CCInputPhone = (props: ICCInputPhoneProps & InputProps) => {
  const {
    validationMessage,
    visited,
    readOnly,
    disabled,
    value,
    name,
    onChangeForm,
    skipValidatePhoneMask = true,
    mode,
    ...others
  } = props;
  const [valuePhoneNumber, setValuePhoneNumber] = useState(value);
  const [isFirstInit, setIsFirstInit] = useState<boolean>(true);

  const initValue = useMemo(() => {
    const { result } = handleFormatPhoneValue(value, mode, readOnly, disabled);
    return result;
  }, [disabled, mode, readOnly, value]);

  useEffect(() => {
    if (isFirstInit) return;
    if (!skipValidatePhoneMask && !readOnly && !disabled) {
      const { result, resultValidate } = handleFormatPhoneValue(
        valuePhoneNumber,
        mode,
        readOnly,
        disabled
      );

      onChangeForm(name, {
        value: result,
      });
      setValuePhoneNumber(resultValidate.hasError ? valuePhoneNumber : result);
    } else {
      onChangeForm(name, {
        value: valuePhoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePhoneNumber]);

  return (
    <>
      <Input
        {...others}
        readOnly={readOnly}
        disabled={disabled}
        value={
          isFirstInit
            ? initValue?.replace(/hasError/g, "")
            : valuePhoneNumber?.replace(/hasError/g, "")
        }
        onChange={(e) => {
          setIsFirstInit(false);
          const valueOnlyNumberAndPlusFirst = e.value.replace(
            /[^+\d\s]|(?<=\+)\D+|(?<=.)\+/g,
            ""
          );
          setValuePhoneNumber(valueOnlyNumberAndPlusFirst);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.keyCode === KEY_ENTER) e.preventDefault();
          if (props.onKeyPress) props.onKeyPress(e);
        }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
