import { DTO_LOV_Number } from "@common/models/odataResponse";

export enum eAssessmentAllocation {
  NEW_CHARGES = 0,
  CHARGES_BALANCES = 1,
  SEQUENCE = 2,
}
export interface IAssessmentJournalTransactionStepsLOVs {
  TransactionTypes?: DTO_LOV_Transaction[];
  PaymentAllocations?: DTO_LOV_Number[];
  IsRebateTypeTransaction?: boolean;
  AssessRebateEntitlements?: DTO_LOV_Number[];
  DDTransactionTypes?: DTO_LOV_Number[];
}

export interface DTO_LOV_Transaction {
  Code: number;
  Name: string;
  Classifications: string;
  Financial_Groups: number[];
}
