import { loadViewConfiguresColumns } from "@app/products/property/api";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { AddToJournalButton } from "@app/products/property/journals/not-yet-in-journal/pic-transactions/action-bar/buttons/add-to-journal/_index";
import { colListJournalPICTransaction } from "@app/products/property/journals/not-yet-in-journal/pic-transactions/config";
import {
  PROPERTY_JOURNALS_PIC_TRANSACTION_ROUTE,
  PROPERTY_JOURNALS_PIC_TRANSACTION_URL,
} from "@app/products/property/journals/not-yet-in-journal/pic-transactions/constant";
import { VO_PIC_Transaction_NotIn_Journal } from "@app/products/property/journals/not-yet-in-journal/pic-transactions/model";
import { JournalPICTransactionBookmark } from "@app/products/property/journals/not-yet-in-journal/pic-transactions/util";
import { journalsRoute } from "@app/products/property/journals/route";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<VO_PIC_Transaction_NotIn_Journal>();
export default observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColList, setProcessedColList] = useState<IColumnFields[]>(
    colListJournalPICTransaction
  );

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_PICTransactionsNotInJournal,
      colListJournalPICTransaction
    );
    if (Array.isArray(response)) {
      setProcessedColList(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton type="sub-workflow" title="Workflow">
        <AddToJournalButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        isDisabledRecordListView
        linkUrl={PROPERTY_JOURNALS_PIC_TRANSACTION_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        listViewDetail={JournalPICTransactionBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          JournalPICTransactionBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <NoData />, // TODO: Implement later
      },
    ],
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      dataUrl={PROPERTY_JOURNALS_PIC_TRANSACTION_URL}
      columnFields={processedColList}
      primaryField={nameOf("PIC_Transaction_Id")}
    />
  );
});
