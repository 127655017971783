import { ExistedTownPlanning } from "@app/products/town-planning/[id]/components/forms/existed/_index";
import { NewTownPlanning } from "@app/products/town-planning/[id]/components/forms/new/_index";
import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageTownPlanning = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadTownPlanning, resetStore } = useTownPlanningStore();

  useEffect(() => {
    if (isNew) return;
    loadTownPlanning(id);
    return () => {
      resetStore();
    };
  }, [isNew, loadTownPlanning, resetStore, id]);

  if (isNew) {
    return <NewTownPlanning />;
  }
  return <ExistedTownPlanning />;
};

export default observer(ManageTownPlanning);
