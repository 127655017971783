import { ChatMessageTemplateProps } from "@progress/kendo-react-conversational-ui";
import DOMPurify from "dompurify";
import Markdown from "markdown-to-jsx";
import React from "react";

// Custom message template to render Markdown content safely
interface ExtendedChatMessageTemplateProps extends ChatMessageTemplateProps {
  isLoading: boolean; // Add the isLoading property
}

export const MessageTemplate: React.FC<ExtendedChatMessageTemplateProps> = ({
  item,
  isLoading,
}) => {
  const markdownText = item.text || "";
  const cleanHTML = DOMPurify.sanitize(markdownText); // Sanitize after markdown conversion if using dangerouslySetInnerHTML
  const messageClass = item.author.id === 0 ? "bot-message" : "user-message";
  const isBotTyping =
    item.author.id === 0 && isLoading && item.text === "Bot is responding...";

  return (
    <div className={messageClass}>
      {isBotTyping ? (
        <div className="message-text typing-indicator">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      ) : (
        <div className="message-text">
          <Markdown>{cleanHTML}</Markdown>
        </div>
      )}
    </div>
  );
};
