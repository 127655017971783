import { CCDialog } from "@components/cc-dialog/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import "./_index.scss";
export const PermitsDisabledQuestionDialog = (props: any) => {
  const [typePermit, setTypePermit] = useState("");
  const [listAnswer, setListAnswer] = useState({
    answer1: false,
    answer2: false,
    answer3: false,
    answer4: false,
  });
  const rdoPermitType = [
    { label: "Individual", value: "Individual", className: "task blue" },
    { label: "Organisation", value: "Organisation", className: "task blue" },
  ];
  const rdoPermitTypeOnChange = (e: any) => {
    setTypePermit(e.value);
  };
  return (
    <CCDialog
      maxWidth="80%"
      titleHeader={"Permits Disabled Question"}
      onClose={props.onCloseDialog}
      bodyElement={
        <div className="cc-full-width cc-permits-disabled-question">
          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <h6>Individual or Organisation</h6>
              <RadioGroup
                layout={"horizontal"}
                data={rdoPermitType}
                value={typePermit}
                onChange={rdoPermitTypeOnChange}
              />
            </div>
          </div>
          <div className="cc-question"></div>
          {typePermit === "Individual" && (
            <>
              <div className="cc-field-group">
                <div className="cc-form-cols-2">
                  <h6>Q1. Is the applicant a Resident of the Municipality?</h6>
                  <CCSwitch
                    checked={listAnswer.answer1}
                    onChange={(e) => {
                      setListAnswer({
                        ...listAnswer,
                        answer1: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {listAnswer.answer1 && (
                <div className="cc-field-group">
                  <div className="cc-form-cols-2">
                    <h6>
                      Q2. Is the disability chronic, acute or intellectual?
                    </h6>
                    <CCSwitch
                      checked={listAnswer.answer2}
                      onChange={(e) => {
                        setListAnswer({
                          ...listAnswer,
                          answer2: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {listAnswer.answer1 && listAnswer.answer2 && (
                <div className="cc-field-group">
                  <div className="cc-form-cols-2">
                    <h6>Q3. Is the disability diagnosed as permanent?</h6>
                    <CCSwitch
                      checked={listAnswer.answer3}
                      onChange={(e) => {
                        setListAnswer({
                          ...listAnswer,
                          answer3: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {listAnswer.answer1 &&
                listAnswer.answer2 &&
                listAnswer.answer3 && (
                  <div className="cc-field-group">
                    <div className="cc-form-cols-2">
                      <h6>Q4. Can the applicant drive?</h6>
                      <CCSwitch
                        checked={listAnswer.answer4}
                        onChange={(e) => {
                          setListAnswer({
                            ...listAnswer,
                            answer4: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}

              {listAnswer.answer1 &&
                listAnswer.answer2 &&
                listAnswer.answer3 &&
                listAnswer.answer4 && (
                  <>
                    <div className="cc-field-group">
                      <div className="cc-form-cols-2">
                        <h6 className="cc-text-error">BLUE FULL PERMIT</h6>
                        <h6>ISSUE AN 'A' PERMIT FOR DRIVER/PASSENGER</h6>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={props.onCloseDialog}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={() => {
              props.onSubmitDialog(typePermit);
            }}
          >
            Continue
          </Button>
        </div>
      }
    />
  );
};
