import { IPopulateDocumentsDialog } from "@app/products/property/assessments/compliance/actions-and-letters/components/action-bar/dialog/populate-documents/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ComplianceDocumentsStore {
  private _isLoading: boolean = false;
  private _isOpenDocumentIssuedDialog: boolean = false;
  private _populateDocumentsData?: IPopulateDocumentsDialog = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isOpenDocumentIssuedDialog() {
    return this._isOpenDocumentIssuedDialog;
  }
  setIsOpenDocumentIssuedDialog = (isOpenDocumentIssuedDialog: boolean) => {
    runInAction(() => {
      this._isOpenDocumentIssuedDialog = isOpenDocumentIssuedDialog;
    });
  };

  get populateDocumentsData() {
    return this._populateDocumentsData;
  }
  setPopulateDocumentsData = (
    populateDocumentsData?: IPopulateDocumentsDialog
  ) => {
    runInAction(() => {
      this._populateDocumentsData = populateDocumentsData;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isOpenDocumentIssuedDialog = false;
      this._populateDocumentsData = undefined;
    });
  };

  loadDocuments = async (IssuedFrom?: Date | null, IssuedTo?: Date | null) => {
    this.setPopulateDocumentsData({
      IssuedFrom,
      IssuedTo,
    });
    this.setIsOpenDocumentIssuedDialog(false);
  };
}

const complianceDocumentsStoreContext = createContext(
  new ComplianceDocumentsStore()
);

export const useComplianceDocumentsStore = () => {
  return useContext(complianceDocumentsStoreContext);
};
