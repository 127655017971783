import {
  IGetTitleRequestObject,
  VO_Assessment_Subdivision_Title,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";

export const getTitles = async ({
  assessmentId,
  searchKey,
  searchValue,
}: IGetTitleRequestObject): Promise<
  APIResponse<IODataResponse<VO_Assessment_Subdivision_Title> | undefined>
> => {
  try {
    const params = {
      id: assessmentId ?? undefined,
      [searchKey as string]: searchValue ?? undefined,
    };
    return await CoreAPIService.getClient().get(
      `/odata/property/internal/assessmentsubdivisiontitleregister?$count=true`,
      { params }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
