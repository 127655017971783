import { DTO_DeferredDuty_Account_Instalment } from "@app/products/property/deferred-duty/components/action-bar/dialogs/calculate-instalment/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Instalment>();

export const colDeferredDutyCaculate: IColumnFields[] = [
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InstalmentNumber"),
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("InstalmentAmount"),
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InstalmentBalance"),
    title: "Instalment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PaymentReceivedDate"),
    title: "Payment Received Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Id"),
    title: "Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
