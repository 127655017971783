import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { DTO_Transaction } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React from "react";
import { colTransactions } from "./config";
export const TRANSACTION_FORM_STEP = "Transaction";

const nameOfTransaction = nameOfFactory<DTO_Transaction>();
export const TransactionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
  },
}: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Transactions</label>
          <CCGrid
            className="cc-transactions-grid"
            data={getFieldValue("Transactions") || []}
            columnFields={colTransactions}
            primaryField={nameOfTransaction("Id")}
            readOnly
          />
        </div>
      </div>
    </section>
  );
};
