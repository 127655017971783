import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmIncompleteReadingDialogProps {
  onClosePopup: () => void;
  onConfirmYes: () => void;
  subMessage: string;
}
export const ConfirmIncompleteReadingDialog = ({
  onClosePopup,
  onConfirmYes,
  subMessage,
}: IConfirmIncompleteReadingDialogProps) => {
  return (
    <ConfirmDialog
      height={"auto"}
      width={"auto"}
      title={"Incomplete Work Record"}
      message={""}
      subMessage={subMessage}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
