import { ICCRoute } from "@common/constants/ICCRoute";

export const registersRoute: ICCRoute = {
  path: "registers",
  name: "Registers",
  children: [
    {
      path: "planning-enquiries",
      name: "Planning Enquiries",
      component: require("./planning-enquiries/_index").default,
    },
    {
      path: "planning-certificate",
      name: "Planning Certificate",
      component: require("./planning-certificate/_index").default,
    },
    {
      path: "secondary-consent",
      name: "Secondary Consent",
      component: require("./secondary-consent/_index").default,
    },
    {
      path: "extension-of-time",
      name: "Extension Of Time",
      component: require("./extension-of-time/_index").default,
    },
  ],
};
