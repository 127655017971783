import { postHouseKeeping } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/api";
import AssessmentHouseKeepingDialog from "@app/products/property/assessments/components/dialogs/house-keeping/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const HousekeepingButton = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const [showHouseKeepingDialog, setShowHouseKeepingDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePostHouseKeeping = async () => {
    setIsLoading(true);
    const response = await postHouseKeeping();
    setIsLoading(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      pushNotification({
        title:
          response.data?.SuccessMessage ??
          "A House Keeping task is created successfully",
        type: "success",
      });
    } else {
      pushNotification({
        autoClose: false,
        title:
          response.data?.ErrorMessage ??
          "A House Keeping task is already being processed or is on the job queue.",
        type: "error",
      });
    }
    setShowHouseKeepingDialog(false);
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.House_Keeping_Queue}
      >
        <CCNavButton
          title="Housekeeping"
          onClick={() => setShowHouseKeepingDialog(true)}
        />
      </PrivilegeWrapper>
      {showHouseKeepingDialog && (
        <AssessmentHouseKeepingDialog
          onClosePopup={() => {
            setShowHouseKeepingDialog(false);
          }}
          onAsyncConfirm={() => {
            handlePostHouseKeeping();
          }}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
