import {
  DTO_Component_Associations,
  eComponent,
} from "@app/products/property/components/associations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadAssociations = async (
  componentNumber: eComponent,
  id: number | string,
  includeInactive: boolean,
  includeSecondaryAssociations: boolean
): Promise<APIResponse<DTO_Component_Associations | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_Associations>(
      `api/property/int/associations/${componentNumber}/${id}`,
      {
        params: {
          includeInactive,
          includeSecondaryAssociations,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
