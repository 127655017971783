import { DeleteButton } from "@app/core/delete/buttons/_index";
import { CORE_LOCATION_REGISTER_ROUTE } from "@app/core/location-register/[id]/constant";
import { LocationRegisterDetailsTab } from "@app/core/location-register/components/reference-sidebar/details/_index";
import { LocationRegisterHistoryTab } from "@app/core/location-register/components/reference-sidebar/history/_index";
import { NewLocationRegisterButton } from "@app/products/crms/components/action-bar/buttons/new-location-register/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import { CRMS_SYSTEM_ADMIN_LOCATION_REGISTER } from "@app/products/crms/system-admin/constant";
import { colLocationRegister } from "@app/products/crms/system-admin/location-register/config";
import { CRMSSystemAdminLocationRegisterBookmark } from "@app/products/crms/system-admin/location-register/util";
import { LocationRegisterView } from "@app/products/crms/system-admin/model";
import { systemAdminRoute } from "@app/products/crms/system-admin/route";
import { CRMSSystemAdminBookmark } from "@app/products/crms/system-admin/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<LocationRegisterView>();
const gridId = getUUID();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_ManageLocationRegister,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={systemAdminRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="New" type="sub">
        <NewLocationRegisterButton
          isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
        />
      </CCNavButton>,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Register}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CORE_LOCATION_REGISTER_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Register}
        detail={CRMSSystemAdminBookmark.getBookmarkDetail}
        displayName={CRMSSystemAdminBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSSystemAdminLocationRegisterBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CRMSSystemAdminLocationRegisterBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <LocationRegisterDetailsTab />,
      },
      {
        title: "History",
        component: <LocationRegisterHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={colLocationRegister}
        primaryField={nameOf("ID")}
        dataUrl={CRMS_SYSTEM_ADMIN_LOCATION_REGISTER}
        state={{
          sort: [{ field: nameOf("Name"), dir: "asc" }],
          group: [{ field: nameOf("RegisterType"), dir: "asc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
