import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IUnAssociateDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export default function UnAssociateDialog({
  onClosePopup,
  onConfirmYes,
}: IUnAssociateDialog) {
  return (
    <ConfirmDialog
      title={"Un-Associate Parcel Confirmation"}
      subMessage={"Do you wish to Un-Associate the selected Parcels?"}
      message={""}
      height={"auto"}
      width={"25%"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
}
