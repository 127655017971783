import { useIsNew } from "@common/hooks/useIsNew";
import { AppealHearingFormElement } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/components/form-element/_index";
import {
  AppealHearing,
  AppealHearingSubmitActions,
} from "@common/pages/appeal/_id/appeal-hearing/_id/model";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";

export const AppealHearingForm = observer(() => {
  const isNew = useIsNew();
  const {
    setOnSubmit,
    appealHearing,
    saveAppealHearing,
    loadHearingNew,
    appeal,
  } = useAppealHearingStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleSubmit = (event: FormSubmitClickEvent) => {
    const { values, isValid, isModified } = event;
    const action = event.event?.currentTarget
      .name as AppealHearingSubmitActions;
    if (!(action in AppealHearingSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [
        AppealHearingSubmitActions.Save,
        AppealHearingSubmitActions.New,
      ].includes(action)
    ) {
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    }
    saveAppealHearing(values as AppealHearing, action);
  };
  useEffect(() => {
    const appealId = /\d+/g.exec(window.location.pathname)?.[0];
    if (isNew && appealId) {
      loadHearingNew(parseInt(appealId));
    }
    // eslint-disable-next-line
  }, [isNew]);
  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);
  const initValues = useMemo(() => {
    return isNew ? { ...appeal, AppealHearing_ID: 0 } : appealHearing;
  }, [isNew, appeal, appealHearing]);
  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initValues}
        key={JSON.stringify(initValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <AppealHearingFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
