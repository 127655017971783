import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { AssociatedAssessments } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/associated-assessments/_index";
import { LeviesSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/levies-summary/_index";

export const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  return (
    <div className="cc-detail-list">
      <div className="cc-field-group">
        <div className="cc-field-group">
          <LeviesSummary data={dataItem} />
        </div>
        <br />
        <div className="cc-field-group">
          <AssociatedAssessments data={dataItem} />
        </div>
      </div>
    </div>
  );
};
