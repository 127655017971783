import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const financeRoute: ICCRoute = {
  path: "finance",
  name: "Finance",
  enum: LLPermitsMenu.Finance,
  children: [
    {
      path: "debtor-summary-all",
      name: "All Debtor Summary",
      enum: LLPermitsMenu.DebtorSummaryAll,
      component: require("./debtor-summary-all/_index").default,
    },
    {
      path: "os-debtor-summary",
      name: "OS Debtor Summary",
      enum: LLPermitsMenu.DebtorSummaryOutstanding,
      component: require("./os-debtor-summary/_index").default,
    },
    {
      path: "os-fees",
      name: "Os Fees",
      enum: LLPermitsMenu.FeesOutstanding,
      component: require("./os-fees/_index").default,
    },
    {
      path: "paid-fees",
      name: "Paid Fees",
      enum: LLPermitsMenu.FeesPaid,
      component: require("./paid-fees/_index").default,
    },
    {
      path: "fees-all",
      name: "Fees All",
      enum: LLPermitsMenu.FeesAll,
      component: require("./fees-all/_index").default,
    },
    {
      path: "not-sent-to-finance",
      name: "Not Sent To Finance",
      enum: LLPermitsMenu.FeesNotSentToFinance,
      component: require("./not-sent-to-finance/_index").default,
    },
    {
      path: "sent-to-finance",
      name: "Sent To Finance",
      enum: LLPermitsMenu.FeesSentToFinance,
      component: require("./sent-to-finance/_index").default,
    },
    {
      path: "invoices-not-sent-to-finance",
      name: "Invoices Not Sent To Finance",
      enum: LLPermitsMenu.InvoicesNotSentToFinance,
      component: require("./invoices-not-sent-to-finance/_index").default,
    },
    {
      path: "invoices-sent-to-finance",
      name: "Invoices Sent To Finance",
      enum: LLPermitsMenu.InvoicesSentToFinance,
      component: require("./invoices-sent-to-finance/_index").default,
    },
  ],
};
