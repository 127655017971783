import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { animalsPickerOptions } from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/config";
import {
  AnimalPoundRegisterSettingValue,
  CRMSEventPicker,
  PoundRegister,
  PoundRegisterHandlerRequest,
  Svc_FormAction_PoundRegister,
  Svc_PoundRegister,
} from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { getAnimalTypeByID } from "@app/products/animals/system-admin/animal-types/[id]/api";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DebtorNumberPicker } from "@common/input-pickers/debtor-number/_index";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import {
  getDropdownValue,
  nameOfFactory,
  YesNoDropDownValue,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { capitalizeFirstLetter, formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

interface IPoundRegisterAnimalSectionProps {
  formRenderProps: FormRenderProps;
  getSettingValue: AnimalPoundRegisterSettingValue | undefined;
}

const nameOfDebtorNumber = nameOfFactory<RefNumberUsage>();
const nameOf = nameOfFactory<PoundRegister>();
const nameOfMapObj = nameOfFactory<Svc_PoundRegister>();
const getNameOf = (name: keyof PoundRegister) =>
  `${nameOfMapObj("PoundRegister")}.${nameOf(name)}`;

export const PoundRegisterAnimalSection = observer(
  ({ formRenderProps, getSettingValue }: IPoundRegisterAnimalSectionProps) => {
    const { onChange, valueGetter } = formRenderProps;
    const {
      loadPoundRegisterHandle,
      populateBreeds,
      setIsLoading,
      poundRegisterId,
      poundRegisterLOVs,
      poundRegisterBreedLOVs,
      ownerReadOnly,
      isSuperAdmin,
      OSFee,
      isDeleted,
    } = useAnimalPoundRegisterStore();
    const { pushNotification } = useCCAppNotificationStore();
    const poundRegisterObj = valueGetter(nameOfMapObj("PoundRegister"));
    const ownerDisplay = `${getNameOf("Owner")}.Contact.DisplayName`;
    const alternativeContactDisplay = `${getNameOf(
      "AlternativeContact"
    )}.Contact.DisplayName`;
    //#region Function Handler
    const animalsPickerHandler = async (eventValue: CRMSEventPicker) => {
      let params: PoundRegisterHandlerRequest = {
        FormAction: Svc_FormAction_PoundRegister.Form_PickAnimal,
        PoundRegister: poundRegisterObj,
        PoundRegisterArgs: eventValue?.ID ?? 0,
      };

      loadPoundRegisterHandle(params, "Change animals failed.");
    };

    const handleAnimalTypeChange = async (event: ComboBoxChangeEvent) => {
      if (event.target.value?.Key) {
        var animalTypeID = event.target.value.Key;
        setIsLoading(true);
        onChange(getNameOf("AnimalType_ID"), {
          value: animalTypeID,
        });

        const response = await getAnimalTypeByID(animalTypeID);
        if (isSuccessResponse(response) && response?.data) {
          const newAnimalType = response.data;
          onChange(getNameOf("AnimalType"), {
            value: newAnimalType,
          });
        } else {
          pushNotification({
            autoClose: false,
            title: "Reload animal type failed.",
            type: "error",
          });
        }

        populateBreeds(
          valueGetter(getNameOf("AnimalType_ID")),
          valueGetter(getNameOf("AnimalType"))
        );
        setIsLoading(false);
      } else {
        onChange(getNameOf("AnimalType_ID"), {
          value: null,
        });
      }
    };

    const handleChangeOwner = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      onChange(`${getNameOf("Owner")}.Contact`, { value: value });

      if (!isNil(value)) {
        let params: PoundRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PoundRegister.Form_PickOwner,
          PoundRegister: poundRegisterObj,
          PoundRegisterArgs: {
            Owner: {
              Contact: value,
            },
          },
        };

        loadPoundRegisterHandle(params, "Change owner failed.");
      } else {
        onChange(`${getNameOf("Owner_RID")}`, { value: null });
      }
    };

    const handleChangeAlternativeContact = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      onChange(`${getNameOf("AlternativeContact")}.Contact`, { value: value });

      if (!isNil(value)) {
        let params: PoundRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PoundRegister.Form_PickAlternativeContact,
          PoundRegister: poundRegisterObj,
          PoundRegisterArgs: {
            AlternativeContact: {
              Contact: value,
            },
          },
        };

        loadPoundRegisterHandle(params, "Change alternative contact failed.");
      } else {
        onChange(`${getNameOf("AlternativeContact_RID")}`, { value: null });
      }
    };

    //Debtor picker
    const documentDebtorNumbers: RefNumberUsage[] = valueGetter(
      `option.DebtorNumber`
    ) ?? [
      {
        Record_Id: null,
        Sys_DebtorNumber: valueGetter(getNameOf("Sys_DebtorNumber")),
      },
    ];

    const handleOnChangeDebtorNumberValue = (value: RefNumberUsage) => {
      onChange(getNameOf("Sys_DebtorNumber"), {
        value: value?.Sys_DebtorNumber ?? "",
      });
      onChange(`option.DebtorNumber`, {
        value: [value],
      });
    };
    //#endregion

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Animal registration" />
            <Field
              component={InputPickerSearch}
              name={getNameOf("AnimalsRegistration_No")}
              valueMapGrid={valueGetter(getNameOf("AnimalsRegistration_ID"))}
              options={animalsPickerOptions}
              onChange={animalsPickerHandler}
              isCRMS
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Animal type" />
            <Field
              name={getNameOf("AnimalType_ID")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.AnimalType ?? []}
              value={getDropdownValue(
                valueGetter(getNameOf("AnimalType_ID")),
                poundRegisterLOVs?.AnimalType ?? []
              )}
              onChange={handleAnimalTypeChange}
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Gender" />
            <Field
              name={getNameOf("Gender_ENUM")}
              textField="Value"
              dataItemKey="Key"
              data={poundRegisterLOVs?.Gender ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Animal name" />
            <Field
              name={getNameOf("AnimalName")}
              component={CCInput}
              placeholder={"Animal name"}
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Age" />
            <Field
              name={getNameOf("Age")}
              placeholder={"Age"}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              min={0}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Tag number" />
            <Field
              name={getNameOf("TagNumber")}
              component={CCInput}
              placeholder={"Tag number"}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Colour" />
            <Field
              name={getNameOf("Colour_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.Colour ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Microchipped" />
            <Field
              name={getNameOf("Flag_Microchipped")}
              dataItemKey="Key"
              textField="Value"
              data={YesNoDropDownValue}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          {valueGetter(getNameOf("Flag_Microchipped")) === true ? (
            <div className="cc-field">
              <CCLabel title="Microchip number" />
              <Field
                name={getNameOf("MicrochipNumber")}
                component={CCInput}
                placeholder={"Microchip number"}
                disabled={isDeleted}
              />
            </div>
          ) : null}
          <div className="cc-field">
            <CCLabel title="Secondary colour" />
            <Field
              name={getNameOf("SecondaryColour_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.Colour ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Breed" />
            <Field
              name={getNameOf("Breed_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterBreedLOVs}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Cross breed" />
            <Field
              name={getNameOf("CrossBreed_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterBreedLOVs}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Size" />
            <Field
              name={getNameOf("Size_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.Size ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Condition" />
            <Field
              name={getNameOf("Condition_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.Condition ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Disposition" />
            <Field
              name={getNameOf("Disposition_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.Disposition ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Dangerous?" />
            <Field
              name={getNameOf("Flag_Dangerous")}
              dataItemKey="Key"
              textField="Value"
              data={YesNoDropDownValue}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Menacing?" />
            <Field
              name={getNameOf("Flag_Menacing")}
              dataItemKey="Key"
              textField="Value"
              data={YesNoDropDownValue}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          {ownerReadOnly ? (
            <CCValueField label="Owner" value={valueGetter(ownerDisplay)} />
          ) : (
            <div className="cc-field">
              <CCLabel title="Owner" />
              <Field
                uniqueKey="PoundRegisterOwnerPicker"
                name={`${getNameOf("Owner")}.Contact`}
                component={ContactPicker}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick owner errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder="Select owner"
                onChange={handleChangeOwner}
                displayValue={valueGetter(ownerDisplay)}
                disabled={isDeleted}
              />
            </div>
          )}

          <div className="cc-field">
            <CCLabel title="Alternative contact" />
            <Field
              uniqueKey="PoundRegisterAlternativePicker"
              name={`${getNameOf("AlternativeContact")}.Contact`}
              component={ContactPicker}
              onError={(error: any) => {
                pushNotification({
                  type: "error",
                  title: "Pick owner errors",
                  description: error,
                  autoClose: false,
                });
              }}
              placeholder="Select alternative contact"
              onChange={handleChangeAlternativeContact}
              displayValue={valueGetter(alternativeContactDisplay)}
              disabled={isDeleted}
            />
          </div>

          <div className="cc-field">
            <CCLabel
              title={
                capitalizeFirstLetter(
                  getSettingValue?.corporateSettingsDebtorNumberLabel
                ) ?? "Debtor number"
              }
              isMandatory={
                getSettingValue?.corporateSettingsDebtorNumberMandatory
              }
            />
            {getSettingValue?.corporateSettingsEnableDebtorUserSelection ? (
              <Field
                name={getNameOf("Sys_DebtorNumber")}
                value={documentDebtorNumbers[0]}
                placeholder={"Debtor number"}
                component={DebtorNumberPicker}
                disabled={
                  isDeleted ||
                  (!isSuperAdmin &&
                    getSettingValue?.corporateSettingsFinanceAllocateDebtorNumber)
                }
                onlyDisableInput
                isSharePointDocumentLibraries
                selectableMode="single"
                onPickHandle={handleOnChangeDebtorNumberValue}
                onInputChange={(event: ICCInputPickerChangeEvent) => {
                  onChange(getNameOf("Sys_DebtorNumber"), {
                    value: event?.value ?? "",
                  });
                  onChange(`option.DebtorNumber`, {
                    value: [
                      {
                        Record_Id: null,
                        Sys_DebtorNumber: event.value,
                      },
                    ],
                  });
                }}
                validator={
                  getSettingValue?.corporateSettingsDebtorNumberMandatory
                    ? requiredValidator
                    : undefined
                }
                contactId={parseInt(
                  valueGetter(`${getNameOf("Owner")}.Contact.Contact_ID`)
                )}
                recordId={poundRegisterId}
                recordType={RECORDTYPE.Animals_PoundRegister}
                textField={nameOfDebtorNumber("Sys_DebtorNumber")}
                textProduce={(data: RefNumberUsage) => data.Sys_DebtorNumber}
              />
            ) : (
              <Field
                name={getNameOf("Sys_DebtorNumber")}
                component={CCInput}
                placeholder={"Debtor number"}
                disabled={
                  isDeleted ||
                  (!isSuperAdmin &&
                    getSettingValue?.corporateSettingsFinanceAllocateDebtorNumber)
                }
                validator={
                  getSettingValue?.corporateSettingsDebtorNumberMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            )}
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Judgement" />
            <Field
              name={getNameOf("Judgement_KWD")}
              dataItemKey="Key"
              textField="Value"
              data={poundRegisterLOVs?.Judgement ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={isDeleted}
            />
          </div>
          <CCValueField
            label="Pound fees"
            value={OSFee}
            style={formatOSFees(OSFee)}
            format={CURRENCY_FORMAT.CURRENCY1}
          />
          <CCValueField label="Infringement number" value={null} />
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Further comments" />
            <Field
              name={getNameOf("FurtherComments")}
              component={CCTextArea}
              rows={3}
              disabled={isDeleted}
            />
          </div>
        </div>
      </section>
    );
  }
);
