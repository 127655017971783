import { DYNAMIC_LISTS_ROUTE } from "@common/pages/settings/lookups/dynamic-lists/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface INewDynamicQuestionListsButtonProps {
  disabled?: boolean;
}
export const NewDynamicQuestionListsButton = ({
  disabled,
}: INewDynamicQuestionListsButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${DYNAMIC_LISTS_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
