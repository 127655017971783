import {
  DTO_DebtRecoveryExemption,
  DTO_DebtRecovery_Ext,
  IDebtRecoveryOptions,
} from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import { useAssessmentDebtRecoveryStore } from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import Loading from "@components/loading/Loading";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AssessmentDebtRecoveryDetail } from "./components/detail/_index";
import {
  colDebtRecovery,
  colDebtRecoveryExemptions,
  mappingDebtRecoveryView,
} from "./config";
const nameOf = nameOfFactory<DTO_DebtRecovery_Ext>();
const nameOfDebtRecoveryExemption = nameOfFactory<DTO_DebtRecoveryExemption>();
export const AssessmentDebtRecovery = observer(() => {
  const [filter, setFilter] = useState<{ view: number }>({ view: 0 });
  const { assessmentId } = useAssessmentStore();
  const {
    isLoading,
    loadDebtRecovery: loadMemo,
    debtRecovery,
    debtRecoveryExemptions,
  } = useAssessmentDebtRecoveryStore();
  const options: IDebtRecoveryOptions[] = [
    {
      label: "Ratepayer",
      value: "Ratepayer",
    },
    {
      label: "Assessment",
      value: "Assessment",
    },
  ];
  const [valueRadioGroup, setValueRadioGroup] = useState<string>();
  const handleOnViewDebtRecoveryChange = (event: RadioGroupChangeEvent) => {
    const { value } = event;
    setFilter({
      ...filter,
      view: mappingDebtRecoveryView[value],
    });
    setValueRadioGroup(value);
  };
  useEffect(() => {
    if (assessmentId === undefined) return;
    loadMemo(assessmentId, filter.view);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  if (isLoading) return <Loading isLoading />;
  return (
    <div className="cc-assessment-debt-recovery cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Debt recovery history for</label>
            <RadioGroup
              data={options}
              layout="horizontal"
              onChange={handleOnViewDebtRecoveryChange}
              value={valueRadioGroup}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Debt recovery history</label>
            <CCGrid
              data={debtRecovery || []}
              columnFields={colDebtRecovery}
              primaryField={nameOf("Debt_RecoveryId")}
              detail={AssessmentDebtRecoveryDetail}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Debt recovery exemptions given </label>
            <CCGrid
              data={debtRecoveryExemptions || []}
              columnFields={colDebtRecoveryExemptions}
              primaryField={nameOf("Debt_RecoveryId")}
              state={{
                filter: {
                  filters: [
                    {
                      logic: "or",
                      filters: [
                        {
                          field: nameOfDebtRecoveryExemption("ActiveTo"),
                          operator: "isnull",
                        },
                      ],
                    },
                  ],
                  logic: "or",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
