export enum eComplianceHistoryFor {
  RATEPAYER_OWNERS = 0,
  ASSESSMENT = 1,
}

export interface DTO_Assessment_Compliance {
  Compliances: DTO_Compliance[];
  LandTaxExemptions: DTO_Assessment_Compliance_LandTaxExemption[];
}

export interface DTO_Compliance {
  ComplianceId: number;
  AssessmentId: number;
  ComplianceTypeName: string;
  Name: string;
  Status: string;
  AlertMessage: string;
  Compliance_Date_From: Date | null;
  Compliance_Date_To: Date | null;
  Assessment10_3: string;
  Assessment_S_S_B_U: string;
  Assessment_Legal_Description: string;
  Assessment_Property_Address: string;
  Responsible_Officer: string;
}

export interface DTO_Assessment_Compliance_LandTaxExemption {
  Land_Tax_Exemption_Id: number | null;
  Assessment_Id: number | null;
  Name: string;
  Type: string;
  Reason: string;
  Date_From: Date | null;
  Date_To: Date | null;
  Created_By: string;
  Created_On: Date | null;
  Modified_By: string;
  Modified_On: Date | null;
}
