import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RecordCountPacket } from "@common/models/identityPacket";

export const getSyncContactsFromNAR = async (): Promise<
  APIResponse<RecordCountPacket>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/core/internal/settings/contacts-sync-from-nar`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
