import { ResponseMessage } from "@app/products/property/model";
import { DTO_Supplementary } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/create-supplementary/model";
import { DTO_Supplementary_Import } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getCreateSupplementaryFromImportValuationLOVs = async (): Promise<
  APIResponse<DTO_Supplementary_Import | undefined>
> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Supplementary_Import>(
        `api/property/internal/supplementary/import/create/lovs`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const createSupplementaryFromValuation = async (
  payload: DTO_Supplementary
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/import/create`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
