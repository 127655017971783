export const mockActiveByRespArea = [
  {
    CertificateNumber: 17328,
    IsUrgent: false,
    RequiredBy: new Date("15-May-21"),
    OriginatedOn: new Date("11-May-21"),
    Applicant: "Info Track Ply .",
    ApplicantReference: "10804",
    PropertyAddress: "9 Bower Rd ",
    PropertyLocality: "Almurta ",
    LegalDescription: "Lot 100 DP1212808 V8925 F551 ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 8337,
  },
  {
    CertificateNumber: 12149,
    IsUrgent: false,
    RequiredBy: new Date("2-Jul-20"),
    OriginatedOn: new Date("27-Jun-20"),
    TownPlanningCompletedOn: new Date("5-Jul-20"),
    Applicant: "Roper A. Steggall",
    PropertyAddress: "90 Mount St ",
    PropertyLocality: "Archies Creek",
    LegalDescription: "Lot 56 SP1512",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 7719,
  },
  {
    CertificateNumber: 17100,
    IsUrgent: false,
    RequiredBy: new Date("7-Jun-20"),
    OriginatedOn: new Date("2-Jun-20"),
    Applicant: "M 0 Variety Trading Ply Limited via",
    ApplicantReference: "36928610:60455787",
    PropertyAddress: "6 Massey St ",
    LegalDescription: "LOT 3 DP 523587",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 7671,
  },
  {
    CertificateNumber: 12056,
    IsUrgent: false,
    RequiredBy: new Date("16-Feb-20"),
    OriginatedOn: new Date("10-Feb-20"),
    TownPlanningCompletedOn: new Date("29-Feb-20"),
    Applicant: "Global Information Services",
    ApplicantReference: "732414-RLS/31 40803",
    PropertyAddress: "15 Bower Rd ",
    PropertyLocality: "Almurta",
    LegalDescription: "Lot 41 SP88807",
    CertificateType: "Planning 149 Certificate",
    CertificateStatus: "Data Capture Complete",
    ID: 7445,
  },
  {
    CertificateNumber: 22566,
    IsUrgent: false,
    RequiredBy: new Date("29-Nov-19"),
    OriginatedOn: new Date("19-Nov-19"),
    Applicant: "John Kenneth Rennie",
    PropertyAddress: "99 Beasley St",
    PropertyLocality: "Archies Creek",
    LegalDescription: "Lot 16 DP9243",
    CertificateType: "Planning 149(5) Certificate ",
    CertificateStatus: "Being Edited After Completion",
    ID: 7310,
  },
  {
    CertificateNumber: 11812,
    IsUrgent: false,
    RequiredBy: new Date("2-Mar-19"),
    OriginatedOn: new Date("25-Feb-19"),
    Applicant: "Elson Pow Corporate Service Pavia SAI Global Property",
    ApplicantReference: "26923298:46330684",
    PropertyAddress: "Brew St",
    PropertyLocality: "Archies Creek",
    LegalDescription: "Lot 3 SP6693",
    Description:
      "Manually generated to cater for applicants request on Lot 2 SP 88115",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Being Edited After Completion ",
    ID: 6649,
  },
  {
    CertificateNumber: 16678,
    IsUrgent: false,
    RequiredBy: new Date("28-Feb-19"),
    OriginatedOn: new Date(" 23 Jan 2019"),
    Applicant: "Avantro",
    PropertyAddress: "61 Blaxland St ",
    LegalDescription: "Lot D DP404183;Lot A DP406926 ",
    Description:
      "Certificate manually generated to cater for customer request on Lot 2 SP 88114. Refer doc ID 224021.",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete",
    ID: 6568,
  },
  {
    CertificateNumber: 16661,
    IsUrgent: false,
    RequiredBy: new Date("19-Jan-19"),
    OriginatedOn: new Date(" 09 Jan 2019"),
    Applicant: "Info Track Ply Ltd",
    ApplicantReference: "14423",
    PropertyAddress: "51 Blaxland St",
    PropertyLocality: "Archies Creek",
    LegalDescription: "Lot 2 SP88114 WOL 9790 FOL 992 ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete",
    ID: 6525,
  },
  {
    CertificateNumber: 16693,
    IsUrgent: false,
    RequiredBy: new Date("20-Dec-18"),
    OriginatedOn: new Date("15-Dec-18"),
    Applicant: "Global X Information Services",
    ApplicantReference: "696916 - 143588",
    PropertyAddress: "64-66 Gladesville Rd ",
    LegalDescription: "Lot 2 DP1165918",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Being Edited After Completion ",
    ID: 6491,
  },
  {
    CertificateNumber: 16637,
    IsUrgent: false,
    RequiredBy: new Date("19-Dec-18"),
    OriginatedOn: new Date("8-Dec-18"),
    Applicant: "Brighton Lawyers",
    ApplicantReference: "I-PRO",
    PropertyAddress: "6 Bendanelle Rd",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 2 DP1198879 V9964 F790 ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Being Edited After Completion ",
    ID: 6479,
  },
  {
    CertificateNumber: 22292,
    IsUrgent: false,
    RequiredBy: new Date("29-Sep-18"),
    OriginatedOn: new Date("19-Sep-18"),
    TownPlanningCompletedOn: new Date("21-Sep-19"),
    Applicant: "Mr Paul Schremmer",
    PropertyAddress: "U 1/3 AIDens Cres ",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 9 DP29009",
    CertificateType: "Planning 149(5) Certificate ",
    CertificateStatus: "Data Capture Complete",
    ID: 6276,
  },
  {
    CertificateNumber: 16981,
    IsUrgent: false,
    RequiredBy: new Date("20-Jun-18"),
    OriginatedOn: new Date("9-Jun-18"),
    PropertyAddress: "28 Blackwood Rd ",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 5 SP88490 V8051 F189 ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Being compiled",
    ID: 6041,
  },
  {
    CertificateNumber: 16379,
    IsUrgent: false,
    RequiredBy: new Date("18-Mar-18"),
    OriginatedOn: new Date("11-Mar-18"),
    Applicant: "Info Track Ply Ltd",
    ApplicantReference: "7164",
    PropertyAddress: "2 Bower Rd",
    PropertyLocality: "Almurta",
    LegalDescription: "Lot 28 SP88807",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 5817,
  },
  {
    CertificateNumber: 16369,
    IsUrgent: false,
    RequiredBy: new Date("5-Mar-18"),
    OriginatedOn: new Date("26-Feb-18"),
    Applicant: "Maquarie Lawyers Parramatta",
    PropertyAddress: "17 Bower Rd",
    PropertyLocality: "Almurta",
    LegalDescription: "Lot 42 SP88807",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 5777,
  },
  {
    CertificateNumber: 21992,
    IsUrgent: false,
    RequiredBy: new Date("5-Nov-17"),
    OriginatedOn: new Date("30-Oct-2017"),
    TownPlanningCompletedOn: new Date("31-Oct-2017"),
    Applicant: "Ms Stephanie Lai",
    PropertyAddress: "62 Battunga Ave ",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 2 DP505825",
    CertificateType: "Planning 149(5) Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 5538,
  },
  {
    CertificateNumber: 16266,
    IsUrgent: false,
    RequiredBy: new Date("20-Oct-2017"),
    OriginatedOn: new Date("11-Oct-2017"),
    Applicant: "Fowler Pt A. Sons",
    ApplicantReference: "16884461:31731996 ",
    PropertyAddress: "36 Bower Rd",
    PropertyLocality: "Almurta",
    LegalDescription: "Lot 5 DP2955 V10823 F644 ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 5479,
  },
  {
    CertificateNumber: 11961,
    IsUrgent: false,
    RequiredBy: new Date("30-Sep-17"),
    OriginatedOn: new Date("25-Sep-17"),
    TownPlanningCompletedOn: new Date("02-Oct-2017"),
    Applicant: "Nash Allen Williams A. Walton",
    ApplicantReference: "23351:MAN:McOuarrie/1077152 ",
    PropertyAddress: "2 Benor Rd",
    PropertyLocality: "Almurta",
    LegalDescription: "Lot 1 DP16155",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 5427,
  },
  {
    CertificateNumber: 16187,
    IsUrgent: false,
    RequiredBy: new Date("29-Jul-17"),
    OriginatedOn: new Date("29-Jul-17"),
    Applicant: "Global Information Services",
    ApplicantReference: "640042:ER:RIITANO:G0564 ",
    PropertyAddress: "44 Brew St",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "LOT 19 DP 514877",
    Description:
      "Manual certificate generated - doc ID 176132 - application was for new lot in subdivision ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 5289,
  },
  {
    CertificateNumber: 16052,
    IsUrgent: false,
    RequiredBy: new Date("8-Mar-17"),
    OriginatedOn: new Date("1-Mar-17"),
    Applicant: "Legalstream Australia Ply Limited",
    ApplicantReference: "75152/11524/S603/jpd 16729 Attenbourough ",
    PropertyAddress: "8 Blackwood Rd",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 8 SP87453 V11404 F796 ",
    Description:
      "Manual certificate created - doc ID 175830 - application was for new lot in subdivision ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 4926,
  },
  {
    CertificateNumber: 16051,
    IsUrgent: false,
    RequiredBy: new Date("6-Mar-17"),
    OriginatedOn: new Date("27-Feb-17"),
    Applicant: "Hicksons Lawyers",
    ApplicantReference: "TRACEY MILESVI 23678",
    PropertyAddress: "5 Blackwood Rd",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 5 SP87453 V11404 F796 ",
    Description:
      "Manual certificate created - doc ID 175831 - application was for new lot in subdivision ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 4924,
  },
  {
    CertificateNumber: 16050,
    IsUrgent: false,
    RequiredBy: new Date("6-Mar-17"),
    OriginatedOn: new Date("27-Feb-17"),
    Applicant: "Info Track Ply Ltd",
    PropertyAddress: "6 Blackwood Rd",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 6 SP87453 V11404 F796 ",
    Description:
      "Manual certificate created - doc ID 174910 - application was for new lot in subdivision",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 4923,
  },
  {
    CertificateNumber: 16039,
    IsUrgent: false,
    RequiredBy: new Date("20-Feb-17"),
    OriginatedOn: new Date("12-Feb-17"),
    Applicant: "klo Track Ply .",
    ApplicantReference: "24701/984321 ",
    PropertyAddress: "3 Blackwood Rd ",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 3 SP87453 V11404 F796 ",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 4875,
  },
  {
    CertificateNumber: 11168,
    IsUrgent: false,
    RequiredBy: new Date("8-Jul-16"),
    OriginatedOn: new Date("26-Jun-16"),
    TownPlanningCompletedOn: new Date("2-Jul-16"),
    Applicant: "Meyer Solomon &Associates",
    ApplicantReference: "MS:212149:sb ",
    PropertyAddress: "2 Margaret St",
    PropertyLocality: "Cape Woolamai ",
    LegalDescription: "Lot 19 SP1029",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 4431,
  },
  {
    CertificateNumber: 15989,
    IsUrgent: false,
    RequiredBy: new Date("29-Dec-19"),
    OriginatedOn: new Date("21-Dec-19"),
    Applicant: "Moisson Legal",
    ApplicantReference: "2357094",
    PropertyAddress: "115 Beaconhill St ",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "LOT 2 DP 513022",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 3359,
  },
  {
    CertificateNumber: 21191,
    IsUrgent: false,
    RequiredBy: new Date("11-Sep-19"),
    OriginatedOn: new Date("6-Sep-19"),
    TownPlanningCompletedOn: new Date("7-Sep-19"),
    Applicant: "Williams Roncolato Lawyers",
    ApplicantReference: "PR:KC:18348 ",
    PropertyAddress: "20 Anzac St",
    PropertyLocality: "Anderson ",
    LegalDescription: "Lot 2 DP13418",
    CertificateType: "Planning 149(5) Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 3143,
  },
  {
    CertificateNumber: 10602,
    IsUrgent: false,
    RequiredBy: new Date("9-Dec-13"),
    OriginatedOn: new Date("2-Dec-13"),
    TownPlanningCompletedOn: new Date("7-Dec-13"),
    Applicant: "R Hazlett A. Co",
    ApplicantReference: "SANDT 76259 ",
    PropertyAddress: "300 Barr St",
    PropertyLocality: "Almurta",
    LegalDescription: "Lot 631 DP1139232",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 2510,
  },
  {
    CertificateNumber: 10970,
    IsUrgent: false,
    RequiredBy: new Date("18-May-13"),
    OriginatedOn: new Date("11-May-13"),
    TownPlanningCompletedOn: new Date("17-Sep-13"),
    Applicant: "Tone Wheeler 0 Environs Studio",
    ApplicantReference: "Wong - 582",
    PropertyAddress: "49 Benor Rd",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot 1 DP9217",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 2029,
  },
  {
    CertificateNumber: 19959,
    IsUrgent: false,
    RequiredBy: new Date("18-Feb-13"),
    OriginatedOn: new Date("11-Feb-13"),
    Applicant: "R Hazlett A. Co",
    PropertyAddress: "6 Argyle St",
    PropertyLocality: "Almurta",
    LegalDescription: "LOT F DP 449599",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 1848,
  },
  {
    CertificateNumber: 10920,
    IsUrgent: false,
    RequiredBy: new Date("16-Feb-13"),
    OriginatedOn: new Date("11-Feb-13"),
    TownPlanningCompletedOn: new Date("27-Feb-13"),
    Applicant: "Andresakis &Associates",
    PropertyAddress: "279 Banjo Paterson Si ",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "Lot Y DP317723",
    CertificateType: "Planning 149 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 1849,
  },
  {
    CertificateNumber: 19950,
    IsUrgent: false,
    RequiredBy: new Date("6-Feb-13"),
    OriginatedOn: new Date("29-Jan-13"),
    Applicant: "The Conveyancing Shop",
    ApplicantReference: "6273/526115",
    PropertyAddress: "65 Amanda St",
    PropertyLocality: "Archies Creek ",
    LegalDescription: "LOT 6 SP 7045",
    CertificateType: "Rates 603 Certificate ",
    CertificateStatus: "Data Capture Complete ",
    ID: 1830,
  },
  {
    CertificateNumber: 20930,
    IsUrgent: false,
    RequiredBy: new Date("21-Nov-11"),
    OriginatedOn: new Date("19-Nov-11"),
    TownPlanningCompletedOn: new Date("15-Nov-11"),
    Applicant: "DTA Lawyers tp ‘..1/ n r-, n o.c",
    ApplicantReference: "FALLSCHEER",
    PropertyAddress: "21-23 BrIDle St",
    PropertyLocality: "Cape Woolamai",
    LegalDescription: "Lot 3 SP3227;Lot 17 SP3227",
    CertificateType: "Planning 149(5) Certificate",
    CertificateStatus: "Data Capture Complete",
    ID: 1088,
  },
];
