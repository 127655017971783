import { colAmountsOwing } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/amounts-owing/components/form/config";
import { DTO_Amount_Owing } from "@app/products/property/contacts-central-names/[id]/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<DTO_Amount_Owing>();
export const AmountsOwingDetail = observer(() => {
  const { contact, isLoading, setSelectedSingleAssessment } =
    useContactsCentralNamesStore();

  return (
    <CCGrid
      isLoading={isLoading}
      data={contact?.AmountOwing ?? []}
      columnFields={colAmountsOwing}
      primaryField={nameOf("Assessment_Id")}
      selectableMode="single"
      onSelectionChange={(dataItem: any) => {
        setSelectedSingleAssessment(dataItem[0]);
      }}
    />
  );
});
