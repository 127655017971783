import { SessionTransactionDetail } from "@app/products/property/journals/session-list/[id]/components/child-screens/transactions/components/details/_index";
import { VO_Transaction } from "@app/products/property/journals/session-list/[id]/components/child-screens/transactions/model";
import { useSessionTransactionStore } from "@app/products/property/journals/session-list/[id]/components/child-screens/transactions/store";
import { nameOfFactory } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOf = nameOfFactory<VO_Transaction>();

export const SessionTransactions = observer(() => {
  const {
    columnFields,
    transactionView,
    isLoading,
    isLoadingGrid,
    responseLoadErrorView,
    showCancelledTransactions,
    viewOption,
    setTransactionView,
    setShowCancelledTransactions,
    loadItemsConfigurationColumns,
    resetStore,
    loadViews,
    setSelectedTransaction,
  } = useSessionTransactionStore();
  const { id } = useParams<{ id: string }>();

  const odataUrl = useMemo(() => {
    return `odata/property/internal/sessiontransactionsregister/${parseInt(
      id
    )}?&cancelled=${showCancelledTransactions}&view=${
      transactionView?.Id
    }&year=${transactionView?.FinancialYear?.Year ?? 0}&$count=true&`;
  }, [id, showCancelledTransactions, transactionView]);

  useEffectOnce(() => {
    loadViews();
    loadItemsConfigurationColumns();
    return () => resetStore();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadErrorView)
    return (
      <CCLoadFailed
        responseError={responseLoadErrorView}
        onReload={() => {
          loadViews();
        }}
      />
    );

  return (
    <div className="cc-property-session-transactions">
      <div className="cc-grid-control-left">
        <label className="cc-control-item cc-label">View</label>
        <CCDropDownList
          className="cc-control-item cc-items-view"
          data={viewOption}
          textField="Name"
          dataItemKey="TempId"
          onChange={(event: DropDownListChangeEvent) => {
            setTransactionView(event.value);
          }}
          defaultValue={viewOption?.[0]}
        />
        <Checkbox
          className="cc-control-item"
          title="Show cancelled transactions"
          label="Show cancelled transactions"
          checked={showCancelledTransactions}
          onChange={(event: CheckboxChangeEvent) => {
            setShowCancelledTransactions(event.value);
          }}
        />
      </div>

      <CCGrid
        dataUrl={odataUrl}
        columnFields={columnFields}
        selectableMode="single"
        isLoading={isLoadingGrid}
        onSelectionChange={(dataItem: VO_Transaction[]) =>
          setSelectedTransaction(dataItem?.[0])
        }
        primaryField={nameOf("Transaction_Id")}
        detail={SessionTransactionDetail}
      />
    </div>
  );
});
