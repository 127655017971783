import { odataCoreGetContacts } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const getContactsTab = async (
  id: number,
  recordType: RECORDTYPE
): Promise<
  APIResponse<{ value: ICommonContactItemRender[] } | undefined | any>
> => {
  try {
    return await CoreAPIService.getClient().get(
      odataCoreGetContacts(id, recordType)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
