import { colRegisterMemos } from "@app/products/property/registers/[id]/components/child-screens/memos/config";
import { useRegisterMemoStore } from "@app/products/property/registers/[id]/components/child-screens/memos/store";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useEffect } from "react";
import { mMemo } from "./model";

const nameOf = nameOfFactory<mMemo>();

export const RegisterMemos = () => {
  const { isLoading, memo, loadMemo } = useRegisterMemoStore();
  const { registerId } = useRegisterStore();

  useEffect(() => {
    if (!registerId) return;
    loadMemo(registerId);
  }, [loadMemo, registerId]);

  return (
    <CCGrid
      isLoading={isLoading}
      className="cc-register-memos-grid"
      data={memo}
      columnFields={colRegisterMemos}
      primaryField={nameOf("Id")}
    />
  );
};
