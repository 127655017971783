import { StatClockStatus } from "@common/constants/enumerations";

/**
 * @deprecated DTOBaseClass_Standard with enum string
 */
export interface DTOBaseClass_Standard {
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: DBRowState;
  Sys_TimeStamp: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ReferenceNumber: string;
  Sys_StatClockStatus_ENUM: StatClockStatus;
  Sys_StatClockStatus_Name: string;
  Sys_FileNumber: string;
  Sys_DebtorNumber: string;
  Sys_ExternalReference: string;
}

/**
 * @deprecated DBRowState with enum string
 */
export enum DBRowState {
  Inactive = "Inactive",
  Active = "Active",
  Archive = "Archive",
}
