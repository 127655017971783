import { IPickTags } from "@common/pages/settings/lookups/fee-types/_id/components/general/pick-tags-for-fee/_index";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPickTags>();

export const colLPickTagsForFee: IColumnFields[] = [
  {
    field: nameOf("ProductTypeName"),
    title: "Product",
  },
  {
    field: nameOf("Value"),
    title: "Tag",
  },
];
