import NewInspectionDialog from "@app/products/property/fire-prevention/components/dialogs/new-inspection/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const NewInspectionButton = observer(() => {
  const [showNewInspectionDialog, setShowNewInspectionDialog] = useState(false);

  const { pushNotification } = useCCAppNotificationStore();
  return (
    <React.Fragment>
      <CCNavButton
        title="New Inspection"
        onClick={() => {
          setShowNewInspectionDialog(true);
        }}
      />

      {showNewInspectionDialog && (
        <NewInspectionDialog
          onEnterAndDone={() => {
            setShowNewInspectionDialog(false);
            pushNotification({
              title: "New inspection created successfully",
              type: "success",
            });
          }}
          onEnterAndNew={() => {
            setShowNewInspectionDialog(false);
            setTimeout(() => {
              setShowNewInspectionDialog(true);
            }, 1000);
            pushNotification({
              title: "New inspection created successfully",
              type: "success",
            });
          }}
          onClose={() => {
            setShowNewInspectionDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
});
