import { colBalanceOutstandingSAP } from "@app/products/property/assessments/[id]/components/child-screens/general/components/form-element/config";
import { DTO_Balance_OutStanding } from "@app/products/property/assessments/[id]/model";
import { getSAPData } from "@app/products/property/components/child-screen/sap-transactions/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CommunityProperty } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IBalanceOutStandingSAPProps {
  sapId?: string;
}
const nameOfFinancialAreaBalanceSAP = nameOfFactory<DTO_Balance_OutStanding>();
export const BalanceOutStandingSAP = ({
  sapId,
}: IBalanceOutStandingSAPProps) => {
  const { isLLS } = CommunityProperty.getFlagOfStates();

  const [data, setData] = useState<DTO_Balance_OutStanding[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const isEmptySapId = isEmpty(sapId);

  const loadData = async () => {
    if (!isEmptySapId && isLLS) {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const response = await getSAPData(sapId);
      if (isSuccessResponse(response) && response?.data) {
        const result = JSON.parse(response?.data) as DTO_Balance_OutStanding[];
        setData(result ?? []);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    loadData();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );

  return isLLS ? (
    <div>
      <CCLabel title={"Balance outstanding"} />
      <CCGrid
        className="cc-balance-outstanding-sap-grid"
        data={data ?? []}
        columnFields={colBalanceOutstandingSAP}
        primaryField={nameOfFinancialAreaBalanceSAP("DocumentID")}
      />
    </div>
  ) : null;
};
