import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { ExistedLookupDocument } from "@common/pages/settings/lookups/documents/_id/components/forms/existed/_index";
import { NewLookupDocument } from "@common/pages/settings/lookups/documents/_id/components/forms/new/_index";
import { ILookupDocumentParentSection } from "@common/pages/settings/lookups/documents/_id/model";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManageLookupDocument = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const documentId = params.id;
  const location = useLocation();
  const { resetStore, loadDocument, setParentSection } =
    useLookupDocumentStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ILookupDocumentParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) loadDocument(parseInt(documentId), isNew);
    return () => {
      resetMenu();
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, isNew, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper setIsHasPermission={setIsHasPermission}>
      {isNew ? <NewLookupDocument /> : <ExistedLookupDocument />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageLookupDocument);
