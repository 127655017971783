import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const fsrRoute: ICCRoute = {
  path: "fsr",
  name: "FSR",
  enum: HealthManagerMenu.FSR,
  children: [
    {
      path: "log",
      name: "Log",
      enum: HealthManagerMenu.FSRLog,
      component: require("./log/_index").default,
    },
    {
      path: "premises-report",
      name: "Premises Report",
      enum: HealthManagerMenu.PremisesByFSRType,
      component: require("./premises-report/_index").default,
    },
    {
      path: "premises-types",
      name: "Premises Types",
      enum: HealthManagerMenu.FSRPremisesTypes,
      component: require("./premises-types/_index").default,
    },
    {
      path: "inspections-types",
      name: "Inspections Types",
      enum: HealthManagerMenu.FSRInspectionTypes,
      component: require("./inspections-types/_index").default,
    },
    {
      path: "notice-types",
      name: "Notice Types",
      enum: HealthManagerMenu.FSRNoticeTypes,
      component: require("./notice-types/_index").default,
    },
    {
      path: "complaint-types",
      name: "Complaint Types",
      enum: HealthManagerMenu.FSRComplaintTypes,
      component: require("./complaint-types/_index").default,
    },
    {
      path: "keywords",
      name: "Keywords",
      enum: HealthManagerMenu.FSRKeywords,
      component: require("./keywords/_index").default,
    },
  ],
};
