import { getSAPData } from "@app/products/property/components/child-screen/sap-transactions/api";
import { colSAPTransactions } from "@app/products/property/components/child-screen/sap-transactions/config";
import { ISAPTransaction } from "@app/products/property/components/child-screen/sap-transactions/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

const nameOf = nameOfFactory<ISAPTransaction>();
interface ISAPTransactionProps {
  sapId?: string;
}
export const SAPTransaction = ({ sapId }: ISAPTransactionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [sapTransactionData, setSAPTransactionData] = useState<
    ISAPTransaction[]
  >([]);
  const isEmptySapId = isEmpty(sapId);

  const loadData = async () => {
    if (!isEmptySapId) {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const response = await getSAPData(sapId, true);
      if (isSuccessResponse(response) && response?.data) {
        const result = JSON.parse(response?.data) as ISAPTransaction[];
        setSAPTransactionData(result ?? []);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isEmptySapId) loadData();
    // eslint-disable-next-line
  }, [sapId]);

  if (isLoading) return <Loading isLoading />;

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (!isEmptySapId) loadData();
        }}
      />
    );

  return (
    <div className="cc-sap-transactions cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              isLoading={isLoading}
              data={sapTransactionData ?? []}
              columnFields={colSAPTransactions}
              primaryField={nameOf("ExternalReferenceID")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
