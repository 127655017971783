import { ContactClassification } from "@app/core/contacts/_id/model";
import { ContactDialog } from "@app/core/contacts/components/dialogs/contact/_index";
import {
  getContact,
  searchContacts,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/api";
import {
  colSearchContacts,
  searchContactsConfig,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/config";
import { isSuccessResponse } from "@common/apis/util";
import { ContactLookahead_JSON } from "@common/models/contact";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { IColumnFields } from "@components/cc-grid/model";
import { isHTML } from "@components/cc-input-picker/util";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNull, isString, isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  MouseEventHandler,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IContactPickerProps extends ComboBoxProps {
  visited?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  disabledButton?: boolean;
  onError?: (value: any) => void;
  isLoadDetail?: boolean;
  textField?: string;
  textProduce?: (value: any) => string | undefined;
  contactClassification: ContactClassification;
  contactClassificationFilters?: ContactClassification[];
  removeDisplayValue?: () => void;
  restoreDisplayValue?: () => void;
  displayValue?: string;
  uniqueKey?: string;
}
export const ContactPicker = observer((props: IContactPickerProps) => {
  const {
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onButtonClick,
    onError,
    isLoadDetail = true,
    textField = value?.DisplayName ?? "",
    textProduce,
    contactClassification,
    contactClassificationFilters,
    removeDisplayValue,
    restoreDisplayValue,
    displayValue,
    uniqueKey,
    ...others
  } = props;

  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ContactLookahead_JSON[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const inputDisplayName = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (displayValue && (isHTML(displayValue) || isString(displayValue)))
      return displayValue;
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value?.DisplayName ?? "";
  }, [value, textField, textProduce, displayValue]);

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    if (searchText.length < searchContactsConfig.minCharacters) return;

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      setIsLoading(true);
      searchContacts(event.filter.value, contactClassification).then(
        (response) => {
          if (isSuccessResponse(response)) {
            setData(response.data ?? []);
          } else {
            if (onError) onError(response.error);
          }
          setIsLoading(false);
        }
      );
    }, searchContactsConfig.typeSpeed);
  };

  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (!onChange) return;
    const newValue = event.value as ContactLookahead_JSON | null;
    if (!newValue) return onChange({ ...event, value: null });
    if (!isLoadDetail)
      return onChange({
        ...event,
        value: { ...newValue, Contact_ID: newValue.ID },
      });

    setIsLoading(true);
    getContact(newValue.ID).then((response) => {
      if (isSuccessResponse(response)) {
        if (onChange) {
          onChange({ ...event, value: response.data ?? null });
        }
      } else {
        if (onError) onError(response.error);
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        <div
          style={{ display: `${!isHTML(inputDisplayName) ? "none" : "block"}` }}
          className={`cc-input-picker-html k-textbox ${
            others.disabled ? "k-state-disabled" : ""
          }`}
          onClick={() => {
            if (removeDisplayValue && uniqueKey && !others.disabled) {
              removeDisplayValue();
              const comboboxElement = document.getElementById(uniqueKey);
              setTimeout(() => {
                comboboxElement?.focus();
              }, 100);
            }
          }}
        >
          {sanitizeHtml(inputDisplayName)}
        </div>

        <ComboBox
          {...others}
          id={uniqueKey}
          style={{
            display: `${isHTML(inputDisplayName) ? "none" : "flex"}`,
          }}
          filterable
          suggest
          data={data}
          loading={isLoading}
          onFilterChange={handleSearch}
          header={
            <div className="cc-search-header">
              {colSearchContacts.map((col: IColumnFields) => (
                <div key={col.field} style={{ width: col.width }}>
                  {col.title}
                </div>
              ))}
            </div>
          }
          itemRender={ItemRender}
          value={inputDisplayName}
          onChange={handleOnChange}
          popupSettings={{ className: "cc-contact-picker-search" }}
          onBlur={restoreDisplayValue}
        />

        <Button
          disabled={props.disabledButton}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          title="Redirect to contact manage page"
          onClick={(event) => {
            event.preventDefault();
            setShowDialog(true);
            if (onButtonClick) return onButtonClick(event);
          }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog && (
        <ContactDialog
          initialValue={value}
          onClose={() => {
            setShowDialog(false);
          }}
          excludePartnership={
            contactClassification === ContactClassification.Partnership
          }
          contactClassificationFilters={contactClassificationFilters}
          onSubmit={(contact) => {
            setShowDialog(false);
            if (onChange)
              onChange({
                value: { ...contact, ID: contact?.Contact_ID },
              } as ComboBoxChangeEvent);
          }}
        />
      )}
    </>
  );
});

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {colSearchContacts.map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {sanitizeHtml(dataItem[col.field] ?? "")}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
