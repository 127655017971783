import { NextPromiseRefType } from "@common/hooks/flexible-fetch-data/model";
import { configure, makeAutoObservable } from "mobx";
configure({ enforceActions: "always" });

class FlexibleFetchDataStore {
  private _nextPromiseRef: NextPromiseRefType = {
    promise: null,
    resolve: null,
  };
  private _isSuccess = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isSuccess() {
    return this._isSuccess;
  }
  setIsSuccess = (status: boolean) => {
    this._isSuccess = status;
  };

  get waitingFetch() {
    return this._nextPromiseRef.promise;
  }
  resetWaitingFetch = () => {
    this._nextPromiseRef.promise = new Promise((resolve) => {
      this._nextPromiseRef.resolve = resolve;
    });
  };
  handleNext = () => {
    if (this._nextPromiseRef.resolve) {
      this._nextPromiseRef.resolve();
      this.resetWaitingFetch();
    }
  };
}
export const flexibleFetchDataStoreInstance = new FlexibleFetchDataStore();
