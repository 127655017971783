import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { CommunicationDialog } from "@app/core/communication/dialogs/_index";
import { recipientTypesCommunicationDialog } from "@app/core/communication/dialogs/config";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { DOCUMENTSTATUS } from "@app/core/documents/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putSendLetterToVcatApplicant } from "@common/pages/appeal/_id/components/buttons/workflow/send-letter-to-vcat-applicant/api";
import { useAppealSendLetterToVcatApplicantButtonStore } from "@common/pages/appeal/_id/components/buttons/workflow/send-letter-to-vcat-applicant/store";
import {
  APPEALSTATUS,
  AppealSubmitActions,
} from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const SendLetterToVcatApplicant = observer(() => {
  const { isShowDialog, setIsShowDialog, isLoading } =
    useAppealSendLetterToVcatApplicantButtonStore();
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();

  const { appeal, appealId, onSubmit, reLoadAppeal } = useAppealStore();

  const isVisible = useMemo(
    () => appeal?.WorkFlowStatus_ENUM === APPEALSTATUS.TeamLeaderApproved,
    [appeal]
  );

  const handleCommunicationSubmit = async (data: ICommunication) => {
    clearErrorNotification();
    const response = await postSendCommunication(data, RECORDTYPE.CORE_Appeal);
    if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
      if (appealId) {
        const resp = await putSendLetterToVcatApplicant(appealId);
        setIsShowDialog(false);
        if (
          (resp.data && resp.data.ErrorStatus === "HasErrors") ||
          !resp.data
        ) {
          clearErrorNotification();
          pushNotification({
            autoClose: false,
            type: "error",
            description: resp.data?.Errors || resp.statusText,
          });
        } else {
          reLoadAppeal().then(() => {
            pushNotification({
              type: "success",
              title: "Send Letter to VCAT & Applicant successfully",
            });
          });
        }
      }
    } else {
      setIsShowDialog(false);
      clearErrorNotification();
      pushNotification({
        autoClose: false,
        type: "error",
        description: response.data?.Errors,
      });
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title={"Send letter to VCAT and applicant"}
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onSubmit}
        name={AppealSubmitActions.SendLetterToVcatApplicant}
      />
      {isShowDialog && (
        <CommunicationDialog
          recipientTypes={recipientTypesCommunicationDialog(
            RECORDTYPE.CORE_Appeal
          )}
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleCommunicationSubmit}
          productType={PRODUCT_TYPE_NUMBER.TownPlanning}
          recordType={RECORDTYPE.CORE_Appeal}
          documentStatus={DOCUMENTSTATUS.Final}
          recordID={appealId}
        />
      )}
    </>
  ) : null;
});
