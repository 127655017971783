export enum EDialogStepAssociationAmalgamate {
  PICNew = 1,
  PICDelete = 2,
}

export enum EErrorStandardAssociation {
  PIC = "InvalidPIC",
  Holding = "InvalidHolding",
}

export enum EAmalgamatePICGridType {
  PIC = "PIC",
  Holding = "Holding",
}
