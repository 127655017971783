import { RaiseAssessmentInterestCommonButton } from "@app/products/property/assessments/components/action-bar/buttons/raise-assessment-interest/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IRaiseInterestButtonProps {
  isShowAssessmentInfo?: boolean;
  buttonName?: string;
  isDisabled?: boolean;
}
export const RaiseAssessmentInterestButton = observer(
  ({
    isShowAssessmentInfo = false,
    buttonName = "Raise interest",
    isDisabled = false,
  }: IRaiseInterestButtonProps) => {
    const { lastSelectedRow } = useCCProductListViewStore();
    const { id } = useParams<{ id: string }>();

    const assessmentId = useMemo(() => {
      return lastSelectedRow?.Assessment_Id ?? +id;
    }, [lastSelectedRow, id]);

    return (
      <RaiseAssessmentInterestCommonButton
        buttonName={buttonName}
        isDisabled={isDisabled}
        isShowAssessmentInfo={isShowAssessmentInfo}
        assessmentID={assessmentId}
      />
    );
  }
);
