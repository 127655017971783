import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { HomePageViewsForm } from "@common/pages/settings/homepage/homepage-views/[id]/components/child-screens/general/_index";
import { HomepageViewsHistoryTab } from "@common/pages/settings/homepage/homepage-views/[id]/components/reference-sidebar/history/_index";
import { useHomePageViewsStore } from "@common/pages/settings/homepage/homepage-views/[id]/store";
import { ProductListing } from "@common/pages/settings/homepage/model";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ManageHomepageViews = observer(() => {
  const {
    isLoading,
    responseLoadError,
    homepageLovs,
    homepageViews,
    homepageViewsId,
    loadHomepageViews,
    onSubmit,
  } = useHomePageViewsStore();
  const { currentUserInfo } = useGlobalStore();
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);
  const managePageUrl = window.location.pathname;

  useEffect(() => {
    if (isHasPermission) {
      loadHomepageViews(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isHasPermission]);

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([homepageViews?.ViewHeading])}`,
      LinkUrl: managePageUrl,
      LinkText: `Homepage - Homepage views - ${homepageViewsId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HomePage,
      RecordType_ENUM: RECORDTYPE.Core_HomePageView,
      Record_ID: homepageViewsId ? +homepageViewsId : 0,
    },
  ];

  const renderTitle = () => {
    const productType = homepageLovs?.find(
      (item: ProductListing) =>
        item.ProductType_ENUM === homepageViews?.ProductType_ENUM
    );

    let title = productType?.ProductType_Name ?? "";
    if (homepageViews?.ViewHeading) {
      if (title) {
        title += " - " + homepageViews?.ViewHeading;
      } else {
        title = homepageViews?.ViewHeading;
      }
    }
    return title;
  };

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.HOMEPAGE_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Homepage View" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadHomepageViews(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={renderTitle()} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title="Save"
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {homepageViews && <HomePageViewsForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "History",
                      component: <HomepageViewsHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </CheckPermissionSettingWrapper>
  );
});

export default ManageHomepageViews;
