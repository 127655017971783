import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import {
  getPPREnquiryById,
  postPPREnquiry,
  putValidateAndSaveRegister,
} from "@app/products/town-planning/ppr/enquiries/_id/api";
import { approveResponseButtonStore } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/approve-response/store";
import { createApplicationButtonStore } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/create-application/store";
import { RegisterPPREnquiry } from "@app/products/town-planning/ppr/enquiries/_id/config";
import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import {
  IPPREnquiryParentSection,
  PPREnquirySubmitActions,
  pprSubmitWithValidateActions,
  Register,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { convertToEnquiryFormData } from "@app/products/town-planning/ppr/enquiries/_id/util";
import { getKeywords } from "@common/apis/coreKeyword";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { UITriggerRegister } from "@common/constants/uiTrigger";
import { DBRowState } from "@common/models/baseClassStandard";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { EnquiryDetailTabEventType } from "./components/reference-sidebar/detail/_index";

configure({ enforceActions: "always" });

class pprEnquiryStore {
  private _pprEnquiry?: Register = undefined;
  private _pprEnquiryType?: CoreKeyword[] = [];
  private _pprEnquiryMethod?: CoreKeyword[] = [];
  private _pprEnquiryOutcome?: CoreKeyword[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPPREnquiryParentSection = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get pprEnquiry() {
    return toJS(this._pprEnquiry);
  }
  setPPREnquiry = (pprEnquiry?: any) => {
    runInAction(() => {
      this._pprEnquiry = pprEnquiry;
    });
  };

  setPPREnquiryWithLoading = async (pprEnquiry?: Register) => {
    const newEnquiry = await convertToEnquiryFormData(pprEnquiry);
    eventEmitter.emit(EnquiryDetailTabEventType.RefreshData);
    runInAction(() => {
      this._isLoading = true;
      this._pprEnquiry = newEnquiry;
      this._isLoading = false;
    });
  };

  get pprEnquiryType() {
    return toJS(this._pprEnquiryType);
  }
  setPPREnquiryType = (pprEnquiryType?: CoreKeyword[]) => {
    runInAction(() => {
      this._pprEnquiryType = pprEnquiryType;
    });
  };

  get pprEnquiryMethod() {
    return toJS(this._pprEnquiryMethod);
  }
  setPPREnquiryMethod = (pprEnquiryMethod?: CoreKeyword[]) => {
    runInAction(() => {
      this._pprEnquiryMethod = pprEnquiryMethod;
    });
  };

  get pprEnquiryOutcome() {
    return toJS(this._pprEnquiryOutcome);
  }
  setPPREnquiryOutcome = (pprEnquiryOutcome?: CoreKeyword[]) => {
    runInAction(() => {
      this._pprEnquiryOutcome = pprEnquiryOutcome;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPPREnquiryParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get pprEnquiryId() {
    return toJS(this.pprEnquiry?.Register_ID);
  }

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._pprEnquiry = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._parentSection = undefined;
    });
  };

  reloadPPREnquiry = async (): Promise<boolean> => {
    if (this.pprEnquiryId) {
      return await this.loadPPREnquiry(this.pprEnquiryId);
    }
    return false;
  };

  loadPPREnquiry = async (
    pprEnquiryId: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    // Load LOVs data
    const resEnquiryType = await getKeywords(
      KEYWORD_TYPE.Core_RegisterPlanningEnquiryType,
      PRODUCT_TYPE_NUMBER.TownPlanning
    );
    if (isSuccessResponse(resEnquiryType)) {
      this.setPPREnquiryType(resEnquiryType.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: resEnquiryType?.error ?? "The enquiry type could not be loaded.",
        type: "error",
      });
    }

    const resEnquiryMethod = await getKeywords(
      KEYWORD_TYPE.Planning_EnquiryMethod,
      PRODUCT_TYPE_NUMBER.TownPlanning
    );
    if (isSuccessResponse(resEnquiryMethod)) {
      this.setPPREnquiryMethod(resEnquiryMethod.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          resEnquiryMethod?.error ?? "The enquiry method could not be loaded.",
        type: "error",
      });
    }

    const resEnquiryOutcome = await getKeywords(
      KEYWORD_TYPE.Core_RegisterPlanningPermitReferralEnquiryOutcome,
      PRODUCT_TYPE_NUMBER.TownPlanning
    );
    if (isSuccessResponse(resEnquiryOutcome)) {
      this.setPPREnquiryOutcome(resEnquiryOutcome.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          resEnquiryOutcome?.error ??
          "The enquiry outcome could not be loaded.",
        type: "error",
      });
    }

    if (isNew) {
      const newEnquiry = new RegisterPPREnquiry();
      if (this.parentSection?.pprApplication) {
        newEnquiry.Address = this.parentSection?.pprApplication?.SiteAddress;
        newEnquiry.Address.Address_ID = 0;
        newEnquiry.Contact = this.parentSection?.pprApplication?.Applicant;
        newEnquiry.Contact.RID = 0;
        newEnquiry.ReferenceNumber =
          this.parentSection?.pprApplication?.ApplicationNumberDisplay;
        this.setParentSection({
          ...this._parentSection,
          pprApplication: undefined,
        } as IPPREnquiryParentSection);
      }
      this.setPPREnquiry(newEnquiry);
    } else {
      let newPPR = undefined;
      const response = await getPPREnquiryById(pprEnquiryId);
      if (isSuccessResponse(response) && response.data) {
        newPPR = await convertToEnquiryFormData(response.data);
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setPPREnquiry(newPPR);
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParentSection({
          ...this._parentSection,
          notification: [],
        } as IPPREnquiryParentSection);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  savePPREnquiry = async (
    pprEnquiryInfo: Register,
    action: PPREnquirySubmitActions
  ) => {
    this.setIsLoading(true);
    if (pprSubmitWithValidateActions.includes(action)) {
      await this.validateSave(action, pprEnquiryInfo);
      this.setIsLoading(false);
    } else {
      const response = await postPPREnquiry(
        pprEnquiryInfo,
        this.parentSection?.parentID,
        this.parentSection?.recordType
      );
      this.setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        if (this.pprEnquiryId) await this.loadPPREnquiry(this.pprEnquiryId);
        this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "The enquiry application could not be saved.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };

  reLoadPPREnquiry = async (): Promise<boolean> => {
    if (this.pprEnquiryId) {
      return await this.loadPPREnquiry(this.pprEnquiryId);
    }
    return false;
  };

  handleValidateSave = async (
    id: number,
    workflow: UITriggerRegister,
    saveEnquiry: boolean,
    register: Register
  ) => {
    const response = await putValidateAndSaveRegister(
      id,
      workflow,
      saveEnquiry,
      register
    );
    if (isSuccessIdentityPacket(response)) {
      let newEnquiry: any = await convertToEnquiryFormData(
        response.data?.Register
      );
      this.setPPREnquiry(newEnquiry);
      eventEmitter.emit(workflow.toString());
      appNotificationStore.clearErrorNotification();
    } else {
      if (response.data?.Register) {
        let newEnquiry: any = await convertToEnquiryFormData(
          response.data?.Register
        );
        this.setPPREnquiry(newEnquiry);
      }
      appNotificationStore.pushNotification({
        autoClose: false,
        description: response.data?.Errors ?? response.statusText,
        type: "error",
      });
    }
  };

  runActions = (action: PPREnquirySubmitActions, response?: APIResponse) => {
    switch (action) {
      case PPREnquirySubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Enquiry application saved successfully.",
          type: "success",
        });
        break;
      case PPREnquirySubmitActions.New:
        appNotificationStore.clearNotifications();
        history.replace(
          `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              {
                title: "Enquiry application saved successfully.",
                type: "success",
              },
            ],
          }
        );
        break;

      case PPREnquirySubmitActions.CreateApplication:
        createApplicationButtonStore.createApplication(this._pprEnquiry);
        break;
      case PPREnquirySubmitActions.ApproveResponse:
        approveResponseButtonStore.approveResponse(this.pprEnquiryId);
        break;
      default:
        break;
    }
  };

  validateSave = async (
    action: PPREnquirySubmitActions,
    pprInfo?: Register
  ) => {
    switch (action) {
      case PPREnquirySubmitActions.NotForVR:
        if (this.pprEnquiryId && pprInfo) {
          await this.handleValidateSave(
            this.pprEnquiryId,
            UITriggerRegister.NotForVR,
            true,
            pprInfo
          );
        }
        break;
      case PPREnquirySubmitActions.LodgeApplication:
        if (this.pprEnquiryId && pprInfo) {
          await this.handleValidateSave(
            this.pprEnquiryId,
            UITriggerRegister.Lodge,
            true,
            pprInfo
          );
        }
        break;
      case PPREnquirySubmitActions.CreateAndLodgeApplication:
        if (pprInfo) {
          eventEmitter.emit(UITriggerRegister.Lodge.toString(), pprInfo);
        }
        break;
      case PPREnquirySubmitActions.SendForApproval:
        if (this.pprEnquiryId && pprInfo) {
          await this.handleValidateSave(
            this.pprEnquiryId,
            UITriggerRegister.SendForApproval,
            true,
            pprInfo
          );
        }
        break;
      case PPREnquirySubmitActions.RejectResponse:
        if (this.pprEnquiryId && pprInfo) {
          await this.handleValidateSave(
            this.pprEnquiryId,
            UITriggerRegister.RejectedResponse,
            true,
            pprInfo
          );
        }
        break;
      case PPREnquirySubmitActions.SendResponse:
        if (this.pprEnquiryId && pprInfo) {
          await this.handleValidateSave(
            this.pprEnquiryId,
            UITriggerRegister.SendResponse,
            true,
            pprInfo
          );
        }
        break;
      default:
        break;
    }
  };
}

export const pprEnquiryInstance = new pprEnquiryStore();
const pprEnquiryContext = createContext(pprEnquiryInstance);
export const usePPREnquiryStore = () => {
  return useContext(pprEnquiryContext);
};
