import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import {
  BubbleUpType,
  Svc_BubbleUpIdentifier,
} from "@app/core/inspections/[id]/model";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { SaveRecallButton } from "@app/core/recall/[id]/components/button/save";
import { RecallForm } from "@app/core/recall/[id]/components/general/_index";
import { AccordionsRecall } from "@app/core/recall/[id]/components/general/form-element/components/recall-accordions/_index";
import { RecallHistoryTab } from "@app/core/recall/[id]/components/slidebar/history/_index";
import { TitleRecallManagePage } from "@app/core/recall/[id]/constant";
import { RecallDetailsTab } from "@app/core/recall/[id]/reference-sidebar/details/_index";
import { Recall } from "@app/core/recall/model";
import { DBRowState } from "@app/products/crms/[id]/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { mapEnum, renderBubbleUps } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
export const ExistManageRecall = observer(() => {
  const { id } = useParams<{ id: string }>();

  // #region STORE
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  // #endregion

  const [showSlideBar, setShowSlideBar] = useState(true);

  const recall = dataForms?.GeneralForm as Recall;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `${TitleRecallManagePage} - ${id ?? 0}`,
      Text: TitleRecallManagePage,
      LinkUrl: window.location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Recall,
      Record_ID: +id,
    },
  ];

  const isInactive = useMemo(() => {
    return !!(
      mapEnum(recall?.Sys_DBRowState, DBRowState) === DBRowState.Inactive &&
      recall?.Recall_ID
    );
  }, [recall?.Sys_DBRowState, recall?.Recall_ID]);

  // #region BubbleUps
  const parentBubbleUps = useMemo(
    () =>
      recall?._BubbleUps?.find(
        (bubbleUp: Svc_BubbleUpIdentifier) =>
          mapEnum(bubbleUp.BubbleUpType_ENUM, BubbleUpType) ===
          BubbleUpType.Parent
      ),
    [recall?._BubbleUps]
  );

  const recallBubbleUps = useMemo(
    () =>
      renderBubbleUps(
        parentBubbleUps?.SourceIdentifier?._RecordSource_ID ?? 0,
        parentBubbleUps?.SourceIdentifier?._RecordSourceType_ENUM ??
          RECORDTYPE.SystemInitialise,
        recall?.Recall_ID,
        RECORDTYPE.CORE_Recall
      ),
    [
      parentBubbleUps?.SourceIdentifier?._RecordSourceType_ENUM,
      parentBubbleUps?.SourceIdentifier?._RecordSource_ID,
      recall?.Recall_ID,
    ]
  );
  // #endregion

  // Mail merge and communication setup
  const [mailMergeDataSet, setMailMergeDataSet] = useState(
    MAILMERGEDATASET.CORE_Recall
  );
  const [productType, setProductType] = useState(PRODUCT_TYPE_NUMBER.Core);
  useEffect(() => {
    switch (parentBubbleUps?.SourceIdentifier?._RecordSourceType_ENUM) {
      case RECORDTYPE.HealthManager_Premises:
        setMailMergeDataSet(MAILMERGEDATASET.HealthManager_Recall);
        setProductType(PRODUCT_TYPE_NUMBER.HealthManager);
        break;
      default:
        break;
    }
  }, [parentBubbleUps?.SourceIdentifier?._RecordSourceType_ENUM]);

  return (
    <>
      <FormTitle title={TitleRecallManagePage} />
      <CCManagePageActionBar
        leftActions={[
          <SaveRecallButton isDisabled={isInactive} />,
          <CCNavButton title={"Add"} type="sub" disabled={isInactive}>
            <AddActionWithParentButton
              id={recall?.Recall_ID}
              recordType={RECORDTYPE.CORE_Recall}
              bubbleUps={recallBubbleUps}
              disabled={isInactive}
            />
            <AddAttachmentButton
              id={recall?.Recall_ID}
              recordType={RECORDTYPE.CORE_Recall}
              bubbleUps={recallBubbleUps}
              disabled={isInactive}
            />
            <AddCommentButton
              id={recall?.Recall_ID}
              recordType={RECORDTYPE.CORE_Recall}
              bubbleUps={recallBubbleUps}
              isDisabled={isInactive}
            />
            <CommunicationButtonListView
              recordId={recall?.Recall_ID}
              productType={productType}
              recordType={RECORDTYPE.CORE_Recall}
              isDisabled={isInactive}
            />
            <AddDocumentButton
              dataSetFilter={mailMergeDataSet}
              recordType={RECORDTYPE.CORE_Recall}
              productType={PRODUCT_TYPE_NUMBER.Core}
              id={recall?.Recall_ID}
              bubbleUps={recallBubbleUps}
              buttonLabel={"Document"}
              isDisabled={isInactive}
            />
          </CCNavButton>,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {recall && <RecallForm />}
            <AccordionsRecall isRecordDeleted={isInactive} />
          </div>
        </div>

        {/* Handle later after Confirmation */}
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                { title: "Details", component: <RecallDetailsTab /> },
                {
                  title: "History",
                  component: <RecallHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
