import { getValueName } from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/components/form-elements/details/util";
import {
  DTO_LOV_ChargeRunType,
  DTO_LOV_ChargeRunType_Assess_Group,
} from "@app/products/property/charge-and-notice-runs/charge-runs/components/form-steps/create-charge-run/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  ComboBoxChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";

interface ICalculateNameProps {
  typeId?: number;
  periodId?: number;
  chargeDateValue?: Date | null;
}

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ nameOf, options, formRenderProps }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const chargeRunTypeId = getFieldValue("Charge_Run_TypeId");
    const ratingPeriodId = getFieldValue("Rating_Period_Id");
    const chargeDate = getFieldValue("ChargeDate");
    const name = getFieldValue("Name");
    const assessmentGroupsToBeIncluded = getFieldValue(
      "AssessmentGroupsToBeIncluded"
    );

    //Get labels
    const [assessmentGroupsLabel, assessmentLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.AssessmentGroups,
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    const chargeRunLOVsData = useMemo(() => {
      return {
        chargeRunType: (options?.lovsChargeRunData?.ChargeRunType?.filter(
          (item: DTO_LOV_ChargeRunType) =>
            item.CRT_Is_Pic === options?.isFromPICChargeRun
        ) ?? []) as DTO_LOV_ChargeRunType[],
        ratingPeriod: options?.lovsChargeRunData?.RatingPeriod ?? [],
        instalmentplanTypes:
          options?.lovsChargeRunData?.InstalmentplanTypes ?? [],
        assessmentGroups: options?.lovsChargeRunData?.AssessmentGroups ?? [],
        chargeRunTypeAssessmentGroups:
          options?.lovsChargeRunData?.ChargeRunTypeAssessmentGroups ?? [],
      };
    }, [options]);

    const onChangeAssessmentGroupsToBeIncluded = (chargeRunCode: number) => {
      if (chargeRunCode) {
        const chargeRunTypeAssessmentGroups =
          chargeRunLOVsData.chargeRunTypeAssessmentGroups
            ?.filter(
              (item: DTO_LOV_ChargeRunType_Assess_Group) =>
                item.Code === chargeRunCode
            )
            ?.map((item: DTO_LOV_ChargeRunType_Assess_Group) => item.Group);
        const listAssGroupByChargeTypeId =
          chargeRunLOVsData.assessmentGroups?.filter((item: DTO_LOV) =>
            chargeRunTypeAssessmentGroups.includes(+item.Code)
          );
        onChange(nameOf("AssessmentGroupsToBeIncluded"), {
          value: listAssGroupByChargeTypeId ?? [],
        });
      }
    };

    const calculateName = useCallback(
      ({
        typeId = chargeRunTypeId,
        periodId = ratingPeriodId,
        chargeDateValue = chargeDate,
      }: ICalculateNameProps) => {
        const typeDisplay = typeId
          ? getValueName(typeId, chargeRunLOVsData.chargeRunType, "Name") +
            " for "
          : "";
        const ratingDisplay = periodId
          ? getValueName(periodId, chargeRunLOVsData.ratingPeriod, "Name") +
            "; "
          : "";
        const chargeDateDisPlay = chargeDateValue
          ? formatDisplayValue(chargeDateValue, DATE_FORMAT.DATE)
          : "";

        onChange(nameOf("Name"), {
          value: `${typeDisplay}${ratingDisplay}${chargeDateDisPlay}`,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [chargeDate, chargeRunTypeId, ratingPeriodId]
    );

    useEffect(() => {
      if (isNil(name)) {
        calculateName({});
      }

      if (chargeRunTypeId && isEmpty(assessmentGroupsToBeIncluded)) {
        onChangeAssessmentGroupsToBeIncluded(chargeRunTypeId);
      }
      // eslint-disable-next-line
    }, [chargeRunTypeId, name]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Type
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Charge_Run_TypeId")}
              component={CCSearchComboBox}
              data={chargeRunLOVsData.chargeRunType}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              value={getDropdownValue(
                getFieldValue("Charge_Run_TypeId"),
                chargeRunLOVsData.chargeRunType,
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                const chargeRunCode = event.value?.Code ?? null;
                onChange(nameOf("Charge_Run_TypeId"), {
                  value: chargeRunCode,
                });
                onChange(nameOf("Code"), {
                  value: event?.value?.Type_Code ?? "",
                });
                onChangeAssessmentGroupsToBeIncluded(chargeRunCode);
                calculateName({ typeId: chargeRunCode });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Rating period
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Rating_Period_Id")}
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={chargeRunLOVsData.ratingPeriod}
              value={getDropdownValue(
                getFieldValue("Rating_Period_Id"),
                chargeRunLOVsData.ratingPeriod,
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Rating_Period_Id"), {
                  value: event.value?.Code ?? null,
                });
                calculateName({ periodId: event.value?.Code ?? null });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Charge date</label>
            <Field
              name={nameOf("ChargeDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
              onChange={(event: DatePickerChangeEvent) => {
                const chargeDateValue = event.target?.value ?? null;
                onChange(nameOf("ChargeDate"), {
                  value: chargeDateValue,
                });
                calculateName({ chargeDateValue: chargeDateValue });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Code</label>
            <Field
              name={nameOf("Code")}
              component={CCInput}
              placeholder="Code"
              maxLength={10}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Name</label>
            <Field
              name={nameOf("Name")}
              component={CCInput}
              placeholder="Name"
              maxLength={100}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Instalment plan
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Instalment_Plan_Id")}
              component={CCSearchComboBox}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={chargeRunLOVsData.instalmentplanTypes}
              value={getDropdownValue(
                getFieldValue("Instalment_Plan_Id"),
                chargeRunLOVsData.instalmentplanTypes,
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Instalment_Plan_Id"), {
                  value: event.value?.Code ?? null,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              component={CCTextArea}
              rows={4}
              placeholder="Description"
              maxLength={250}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        <div>
          <label className="cc-label">Selection criteria</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  {/* ACTRO: Assessment groups, LLS: Regions */}
                  {assessmentGroupsLabel}
                </label>
                <Field
                  style={{ width: "100%" }}
                  name={nameOf("AssessmentGroupsToBeIncluded")}
                  data={chargeRunLOVsData.assessmentGroups}
                  component={CCMultiSelectDropdown}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  onChange={(event: MultiSelectChangeEvent) => {
                    onChange(nameOf("AssessmentGroupsToBeIncluded"), {
                      value: event.value,
                    });
                  }}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Custom where
                  <CCTooltip
                    type="info"
                    position="right"
                    content={`The Selection Criteria is in the form of a SQL WHERE clause for the ${assessmentLowercaseLabel} table eg. ${assessmentLabel}_Type in (1,2,3) and can be used to refine the ${assessmentLowercaseLabel} for which the charge run is to apply.`}
                  />
                </label>
                <Field
                  name={nameOf("Assess_Selection_Criteria")}
                  component={CCTextArea}
                  rows={4}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);
