export const validatorMessageTemplateStep = (values: any) => {
  if (!values.SubjectContent) {
    return "Please enter subject content";
  } else {
    let removeHtmlTag = values.SubjectContent.replace("<p>", "");
    removeHtmlTag = removeHtmlTag.replace("</p>", "");
    let checkContent = removeHtmlTag.replace(/&nbsp;+/g, "").trim();
    if (checkContent === "") {
      return "Please enter subject content";
    }
  }
  return undefined;
};
