import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { ReactElement, useEffect } from "react";
import { useEffectOnce } from "react-use";
import { useCCListViewActionBarStore } from "../store";

export interface IUseListViewActionBarProps {
  title?: string;
  leftComponents?: ReactElement[];
  centerComponents?: ReactElement[];
  rightComponents?: ReactElement[];
  searchComponent?: ReactElement;
}

export const useCCListViewActionBar = ({
  title = "",
  leftComponents = [],
  centerComponents = [],
  rightComponents = [],
  searchComponent,
}: IUseListViewActionBarProps) => {
  const {
    setTitle,
    setLeftComponents,
    setCenterComponents,
    setRightComponents,
    setSearchComponent,
    resetDefault,
  } = useCCListViewActionBarStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    setTitle(title);
    setLeftComponents(leftComponents);
    setCenterComponents(centerComponents);
    setRightComponents(rightComponents);
    setSearchComponent(searchComponent);
    //eslint-disable-next-line
  }, [
    centerComponents,
    leftComponents,
    resetMenu,
    rightComponents,
    title,
    searchComponent,
  ]);

  useEffectOnce(() => {
    return () => {
      resetDefault();
      resetMenu();
    };
  });
};
