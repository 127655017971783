import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { Buyers } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/buyers/_index";
import { Exceptions } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/exceptions/_index";

export const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  const leaseTransfersId = dataItem?.ACT_Lease_Transfer_Id;
  const assessmentId = dataItem?.Assessment_Id;

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Buyers",
      component: <Buyers leaseTransfersId={leaseTransfersId} />,
    },
    {
      title: "Exceptions",
      component: (
        <Exceptions
          leaseTransfersId={leaseTransfersId}
          assessmentId={assessmentId}
        />
      ),
    },
  ];

  return <CustomPanelBar listPanelBar={listPanelBar} sort={false} />;
};
