import { Comments, DeleteComment } from "@app/core/comments/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTO_LOV } from "@common/models/odataResponse";

export const getTitleLovs = async (
  recordType: RECORDTYPE
): Promise<APIResponse<DTO_LOV[]>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_LOV[]>(
      `/api/core/int/comment/lovs/${recordType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveComment = async (
  requestBody: Comments,
  recordType: RECORDTYPE
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/internal/comment/AddComment/${recordType}`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const deleteComment = async (
  id: number,
  recordType: RECORDTYPE,
  reason?: string | null
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/core/internal/comment/DeleteComment/${recordType}`,
      {
        ID: id,
        Reason: reason,
      } as DeleteComment
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
