import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadSchemeAccountDetailTab } from "./api";
import { ISchemeAccountDetailTab } from "./model";

export const SchemeAccountDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [schemeAccountInfo, setSchemeInfo] = useState<
    ISchemeAccountDetailTab | undefined
  >();

  const params: { id: string } = useParams();
  const accountId = params.id;
  useEffect(() => {
    if (accountId) {
      setIsLoading(true);
      loadSchemeAccountDetailTab(accountId).then((data) => {
        setSchemeInfo(data);
        setIsLoading(false);
      });
    }
  }, [accountId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!schemeAccountInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Scheme Account ID:"}
          value={schemeAccountInfo.AccountId}
        />
        <ProductReferenceRow
          title={"Scheme:"}
          value={schemeAccountInfo.Scheme}
        />
        <ProductReferenceRow
          title={"Assessment ID:"}
          value={schemeAccountInfo.AssessmentId}
        />
        <ProductReferenceRow
          title={"Overdue:"}
          value={formatDisplayValue(
            schemeAccountInfo.Overdue,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
        <ProductReferenceRow
          title={"Due:"}
          value={formatDisplayValue(
            schemeAccountInfo.Due,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
        <ProductReferenceRow
          title={"Balance Owing:"}
          value={formatDisplayValue(
            schemeAccountInfo.BalanceOwing,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
        <ProductReferenceRow
          title={"Payout Amount: "}
          value={formatDisplayValue(
            schemeAccountInfo.PayoutAmount,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
