/* ====  Option Input Picker Rating  ==== */
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { colKeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/config";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";

const nameOf = nameOfFactory<CoreKeyword>();

export const optionInputPickerZone: IOptionInputPicker = {
  dialog: {
    titleHeader: "Zone",
  },
  boxSearch: {
    colSearch: nameOf("Name"),
    colShowComboboxSearch: [
      {
        title: "Name",
        field: nameOf("Name"),
      },
    ],
  },
  grid: {
    columnFields: colKeyWordsPicker,
    primaryField: "Keyword_ID",
    dataUrl:
      "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=7012,productType=22)",
    selectableMode: "multiple",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOf("Name"),
          dir: "asc",
        },
      ],
    },
  },
};

export const optionInputPickerOverlay: IOptionInputPicker = Object.assign(
  { ...optionInputPickerZone },
  {
    dialog: {
      titleHeader: "Overlay",
    },
    grid: Object.assign(
      { ...optionInputPickerZone.grid },
      {
        dataUrl:
          "/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=7013,productType=22)",
      }
    ),
  }
);
