import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { VO_Compliance_W_Assessment } from "@app/products/property/assessments/compliance/[id]/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Compliance_W_Assessment>();

export const includeAllComplianceColList: IColumnFields[] = [
  {
    field: nameOf("Compliance_Id"),
    title: "Compliance ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => ({
      pathname: `${PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}/${dataItem.Compliance_Id}`,
      search: "?mode=edit",
    }),
  },
  { field: nameOf("Name"), title: "Name", width: 150 },
  { field: nameOf("Type"), title: "Type" },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Alert_Message"),
    title: "Alert Message",
    width: 250,
  },
  {
    field: nameOf("From_Date"),
    title: "Date From",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("To_Date"),
    title: "Date To",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assessmen_Reference"),
    title: "Assessment (10.3)",
  },
  {
    field: nameOf("Property_Address"),
    title: "Assessment Property Address",

    width: 300,
  },
  {
    field: nameOf("AssessmentId"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Responsible_Officer"),
    title: "Responsible Officer",

    width: 200,
  },
];
