import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ExistedLookupHelpDocument } from "./components/forms/existed/_index";
import { NewLookupHelpDocument } from "./components/forms/new/_index";
import { ILookupHelpDocumentParentSection } from "./model";
import { useLookupHelpDocument } from "./store";

const ManageLookupHelpDocument = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const helpDocumentId = params.id;
  const location = useLocation();
  const { resetStore, loadHelpDocument, setParentSection } =
    useLookupHelpDocument();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ILookupHelpDocumentParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    loadHelpDocument(parseInt(helpDocumentId), isNew);
    return () => {
      resetMenu();
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpDocumentId, isNew]);

  if (isNew) {
    return <NewLookupHelpDocument />;
  }
  return <ExistedLookupHelpDocument />;
};

export default observer(ManageLookupHelpDocument);
