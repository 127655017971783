import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { IColumnFields } from "@components/cc-grid/model";

export const ActionTypeColumn: IColumnFields[] = [
  { title: "Name", field: "Name" },
  { title: "Description", field: "Description" },
  { title: "Has Outcome", field: "HasOutcome" },
];

export const OfficerColumns: IColumnFields[] = [
  { title: "Name", field: "Name" },
  { title: "Position", field: "Position" },
  { title: "Email", field: "Email" },
  { title: "Phone", field: "Phone" },
];

export const ComplaintTypeColumns: IColumnFields[] = [
  { title: "Category", field: "Category" },
  { title: "Sub Category", field: "SubCategory" },
];

export enum ActionsRoute {
  ACTIONS_MYACTIONS_BYRECORDEDDATE = "/actions/myactions/byrecordeddate",
  ACTIONS_MYACTIONS_BYSTATUS = "/actions/myactions/bystatus",
  ACTIONS_MYACTIONS_BYTYPE = "/actions/myactions/bytype",
  ACTIONS_MYACTIONS_OUTSTANDING = "/actions/myactions/outstanding",
  ACTIONS_MYACTIONS_OVERDUE = "/actions/myactions/overdue",
  ACTIONS_OUTSTANDINGACTIONS_BYRECORDEDDATE = "/actions/outstandingactions/byrecordeddate",
  ACTIONS_OUTSTANDINGACTIONS_BYSTATUS = "/actions/outstandingactions/bystatus",
  ACTIONS_OUTSTANDINGACTIONS_BYTYPE = "/actions/outstandingactions/bytype",
  ACTIONS_OUTSTANDINGACTIONS_BYOFFICER = "/actions/outstandingactions/byofficer",
  ACTIONS_ALLACTIONS_BYRECORDEDDATE = "/actions/allactions/byrecordeddate",
  ACTIONS_ALLACTIONS_BYSTATUS = "/actions/allactions/bystatus",
  ACTIONS_ALLACTIONS_BYTYPE = "/actions/allactions/bytype",
  ACTIONS_ALLACTIONS_BYOFFICER = "/actions/allactions/byofficer",
  ACTIONS_DETAIL = "/actions/manageregistration",
}

export class NewAction {
  ActionRequired: boolean = false;
  Status_ENUM: ACTIONSTATUS = ACTIONSTATUS.NEW;
  DateRecorded: Date = new Date();
  Description: string = "";
}
