import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { CreateParcelButton } from "@app/products/property/parcels/components/action-bar/buttons/create-parcel/_index";
import { ModifyParcelButton } from "@app/products/property/parcels/components/action-bar/buttons/modify-parcel/_index";
import { colList } from "@app/products/property/parcels/list/config";
import { PROPERTY_PARCEL_REGISTER_ALL_LIST_VIEW_URL } from "@app/products/property/parcels/list/constant";
import { VO_Parcel } from "@app/products/property/parcels/list/model";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/parcels/list/util";
import { parcelRoute } from "@app/products/property/parcels/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/parcels/util";
import { ParcelsAssociationsTab } from "@app/products/property/parcels/[id]/components/reference-sidebar/associations/_index";
import { ParcelDetailTab } from "@app/products/property/parcels/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<VO_Parcel>();

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CCNavButton title={"Parcel"} type="dropdown">
          <CreateParcelButton />
          <ModifyParcelButton />
        </CCNavButton>
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Parcel}
        recordType={RECORDTYPE.CommunityProperty_Parcel}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Parcel}
        keyField={"Parcel_Id"}
      />,
    ],
    leftComponents: [
      <PropertyActionBarNavDropdown category={parcelRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_PARCEL_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <ParcelDetailTab />,
      },
      { title: "Map", component: <MapTab /> },
      {
        title: "Contacts",
        component: <PropertyContactTab componentNumber={eComponent.Parcel} />,
      },
      {
        title: "Related",
        component: <ParcelsAssociationsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Parcel}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colList}
      primaryField={nameOf("Parcel_Id")}
      dataUrl={PROPERTY_PARCEL_REGISTER_ALL_LIST_VIEW_URL}
    />
  );
};
