import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const LookupDocumentTabHistory = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { document } = useLookupDocumentStore();
  const documentId: number = document?.Document_ID ?? lastSelectedRow?.ID ?? 0;
  return (
    <Journal
      id={documentId}
      triggersReload={document}
      recordType={RECORDTYPE.CORE_Document}
    />
  );
});
