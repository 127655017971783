import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { IDataForReallocateDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/model";

configure({ enforceActions: "always" });
class ConfirmReallocateDialogStore {
  private _dataForReallocateDialog?: IDataForReallocateDialog = undefined;
  private _isLoadingReallocate: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get dataForReallocateDialog(): IDataForReallocateDialog | undefined {
    return this._dataForReallocateDialog;
  }
  setDataForReallocateDialog = (
    dataForReallocateDialog?: IDataForReallocateDialog
  ) => {
    runInAction(() => {
      this._dataForReallocateDialog = dataForReallocateDialog;
    });
  };

  get isLoadingReallocate(): boolean {
    return this._isLoadingReallocate;
  }
  setIsLoadingReallocate = (isLoadingReallocate: boolean) => {
    runInAction(() => {
      this._isLoadingReallocate = isLoadingReallocate;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoadingReallocate = false;
      this._dataForReallocateDialog = undefined;
    });
  };
}

const confirmReallocateDialogStoreContext = createContext(
  new ConfirmReallocateDialogStore()
);
export const useConfirmReallocateDialogStore = () => {
  return useContext(confirmReallocateDialogStoreContext);
};
