import { history } from "@/AppRoutes";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import {
  getCommunicationTemplateById,
  getCommunicationTemplateLOVs,
  postCommunicationTemplate,
} from "@common/pages/settings/communication/template/_id/api";
import { SETTINGS_COMMUNICATION_TEMPLATE_ROUTE } from "@common/pages/settings/communication/template/_id/constant";
import {
  CommunicationTemplate,
  CommunicationTemplateLOVs,
  CommunicationTemplateSubmitActions,
  CommunicationUsage,
  ICommunicationTemplateParentSection,
} from "@common/pages/settings/communication/template/_id/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CommunicationTemplateStore {
  private _communicationTemplate?: CommunicationTemplate = undefined;
  private _communicationTemplateLOVs?: CommunicationTemplateLOVs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _parentSection?: ICommunicationTemplateParentSection = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: ICommunicationTemplateParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get communicationTemplate() {
    return toJS(this._communicationTemplate);
  }
  setCommunicationTemplate = (
    communicationTemplate?: CommunicationTemplate
  ) => {
    runInAction(() => {
      this._communicationTemplate = communicationTemplate;
    });
  };

  get communicationTemplateLOVs() {
    return toJS(this._communicationTemplateLOVs);
  }
  setCommunicationTemplateLOVs = (
    communicationTemplateLOVs?: CommunicationTemplateLOVs
  ) => {
    runInAction(() => {
      this._communicationTemplateLOVs = communicationTemplateLOVs;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._communicationTemplate = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._parentSection = undefined;
      this._onSubmit = undefined;
    });
  };

  get communicationTemplateId() {
    return toJS(this.communicationTemplate?.CommunicationTemplate_ID);
  }

  loadCommunicationTemplate = async (
    communicationTemplateID: number,
    isNew?: boolean
  ) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    //load LOVs
    const responseLOVs = await getCommunicationTemplateLOVs();

    if (isSuccessResponse(responseLOVs) && responseLOVs.data) {
      this.setCommunicationTemplateLOVs({
        CommunicationUsageList: responseLOVs.data.CommunicationUsageList,
        Dataset: responseLOVs.data.Dataset,
      });
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    appNotificationStore.clearNotifications();
    if (isNew) {
      this.setCommunicationTemplate({} as CommunicationTemplate);
      if (this._parentSection?.parentId) {
        let parentCommunicationTemplate: any = undefined;
        const response = await getCommunicationTemplateById(
          this._parentSection?.parentId
        );
        if (isSuccessResponse(response)) {
          parentCommunicationTemplate = response.data as CommunicationTemplate;

          parentCommunicationTemplate.CommunicationUsage_ENUM =
            this._communicationTemplateLOVs?.CommunicationUsageList?.find(
              (usage: IKeyValuePacket) =>
                parentCommunicationTemplate.CommunicationUsage_ENUM ===
                usage.Key
            )?.Key ?? null;

          parentCommunicationTemplate.Dataset_ENUM =
            this._communicationTemplateLOVs?.Dataset?.find(
              (usage: IKeyValuePacket) =>
                parentCommunicationTemplate.Dataset_ENUM === usage.Key
            )?.Key ?? null;

          this.setCommunicationTemplate({
            CommunicationUsage_ENUM:
              CommunicationUsage.CORE_CustomCommunicationTemplate,
            CommunicationUsage_Name:
              this._communicationTemplateLOVs?.CommunicationUsageList?.find(
                (usage: IKeyValuePacket) =>
                  CommunicationUsage.CORE_CustomCommunicationTemplate ===
                  usage.Key
              )?.Value || null,
            CommunicationUsage_ENUM_int:
              CommunicationUsage.CORE_CustomCommunicationTemplate,
            Dataset_ENUM: parentCommunicationTemplate?.Dataset_ENUM,
            Dataset_Name: parentCommunicationTemplate?.Dataset_Name,
            Subject: parentCommunicationTemplate?.Subject,
            SMSBody: parentCommunicationTemplate?.SMSBody,
            EmailBody: parentCommunicationTemplate?.EmailBody,
            FaxBody: parentCommunicationTemplate?.FaxBody,
            ShowInCommunicationDialog:
              parentCommunicationTemplate?.ShowInCommunicationDialog,
            MailMergeDocument_ID:
              parentCommunicationTemplate?.MailMergeDocument_ID,
            CommunicationTemplate_Name: "",
          } as CommunicationTemplate);
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Get communication template to clone new failed",
            type: "error",
            description: response?.error ?? response?.statusText,
          });
        }
      }
    } else {
      let newCommunicationTemplate: any = undefined;
      const response = await getCommunicationTemplateById(
        communicationTemplateID
      );
      if (isSuccessResponse(response)) {
        newCommunicationTemplate = response.data as CommunicationTemplate;

        //check exist value in dropdown field
        newCommunicationTemplate.CommunicationUsage_ENUM =
          this._communicationTemplateLOVs?.CommunicationUsageList?.find(
            (usage: IKeyValuePacket) =>
              newCommunicationTemplate.CommunicationUsage_ENUM === usage.Key
          )?.Key ?? null;

        newCommunicationTemplate.Dataset_ENUM =
          this._communicationTemplateLOVs?.Dataset?.find(
            (usage: IKeyValuePacket) =>
              newCommunicationTemplate.Dataset_ENUM === usage.Key
          )?.Key ?? null;

        this.setCommunicationTemplate(newCommunicationTemplate);
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  saveCommunicationTemplate = async (
    communicationTemplateInfo: CommunicationTemplate,
    action: CommunicationTemplateSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postCommunicationTemplate(communicationTemplateInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.communicationTemplateId)
        await this.loadCommunicationTemplate(this.communicationTemplateId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save communication template failed",
        type: "error",
        description: response.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (
    action: CommunicationTemplateSubmitActions,
    response?: APIResponse
  ) => {
    switch (action) {
      case CommunicationTemplateSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Communication template saved successfully",
          type: "success",
        });
        break;
      case CommunicationTemplateSubmitActions.New:
        if (isSuccessResponse(response)) {
          history.replace(
            `${SETTINGS_COMMUNICATION_TEMPLATE_ROUTE}/${response?.data?.ID}`
          );
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Save communication template failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        break;
      default:
        break;
    }
  };
}

export const communicationTemplateStore = new CommunicationTemplateStore();
const communicationTemplateStoreContext = createContext(
  communicationTemplateStore
);
export const useCommunicationTemplateStore = () => {
  return useContext(communicationTemplateStoreContext);
};
