import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingEBuildingNewApplications: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ApplicationNumber,
    title: "Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.RegisterType,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.CreatedDate,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.VicSmart,
    title: "VicSmart",
  },
  {
    field: BuildingFieldMapping.Applicant,
    title: "Applicant",
  },
  {
    field: BuildingFieldMapping.ePlanningCategory,
    title: "Category",
  },
  {
    field: BuildingFieldMapping.SiteAddress,
    title: "Site Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.Owner,
    title: "Owner",
  },
  {
    field: BuildingFieldMapping.ePlanningLodgedBy,
    title: "Lodged By",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.Comments,
    title: "Comments",
  },
];
