import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { HearingCalendarDropdown } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/components/action-bar/calendar-dropdown/_index";
import { ExportCalendarButton } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/components/action-bar/export-calendar/_index";
import { useAppealHearingCalendarStore } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/store";

import { pprRoute } from "@app/products/town-planning/ppr/route";
import { IConfigActivitiesChildDataTownPlanning } from "@common/pages/home/components/townplanning/model";
import { CalendarViewEnum } from "@components/cc-calendar/model";
import { CCCalendar } from "@components/cc-calendar/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce, useUpdateEffect } from "react-use";
import "./_index.scss";

export default observer(() => {
  const {
    hearingCalendar,
    loadAppealHearingCalendar,
    isLoading,
    responseLoadError,
    loadAppealHearingCalendarById,
    selectedSiteUser,
  } = useAppealHearingCalendarStore();

  const location = useLocation();

  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />, <HearingCalendarDropdown />],
    centerComponents: [<ExportCalendarButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });

  useEffectOnce(() => {
    loadAppealHearingCalendar();
  });

  useUpdateEffect(() => {
    if (selectedSiteUser) {
      loadAppealHearingCalendarById(selectedSiteUser.ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSiteUser]);

  const defaultView = useMemo(() => {
    const newLocationState: IConfigActivitiesChildDataTownPlanning | undefined =
      location.state as any;
    if (newLocationState?.defaultView) {
      window.history.replaceState({}, "");
      return newLocationState.defaultView;
    } else {
      return CalendarViewEnum.MONTH;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cc-hearing-calendar-container">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAppealHearingCalendar()}
        />
      ) : (
        <CCCalendar
          // @TODO Open Appeals manage, use when complete this Page
          detailUrl=""
          data={hearingCalendar}
          defaultView={defaultView}
        />
      )}
    </div>
  );
});
