import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSSystemAdminLookupTablesBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - System Admin`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - System Admin - Lookup Tables`;
  },
};
