import { isNil } from "lodash";

export const getValueName = <T extends { Code: string | number; Name: string }>(
  fieldValue: number | null | undefined,
  listData: T[],
  key: keyof T
) => {
  if (isNil(fieldValue) || isNil(listData)) return;
  const item = listData?.find(
    (item) => item.Code.toString() === fieldValue.toString()
  );

  return item ? item[key] : null;
};
