import { apiCoreSettingOptions } from "@app/core/components/common/utils";
import { SettingFolder } from "@app/products/property/system-admin/settings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_Assessment_Summary } from "./model";

export type ILoadAssessmentSummaryById =
  | [APIResponse<DTO_Assessment_Summary>, APIResponse<SettingFolder>]
  | APIResponse<DTO_Assessment_Summary | SettingFolder>
  | undefined;
export const loadAssessmentSummaryById = async (
  assessmentId: number
): Promise<ILoadAssessmentSummaryById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Assessment_Summary>(
        `/api/property/internal/assessment/${assessmentId}/summary`
      ),
      CoreAPIService.getClient().get<SettingFolder>(apiCoreSettingOptions()),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
