import { ReAssignDialog } from "@app/products/property/fire-prevention/components/dialogs/re-assign/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ReAssignButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();

  const isDisabled = useMemo(() => {
    return !(
      gridSelectedRows.length &&
      gridSelectedRows?.every(
        (item) => item.AssignedContractor === "<To be Assigned>"
      )
    );
  }, [gridSelectedRows]);
  return (
    <>
      <CCNavButton
        title="Re-assign"
        onClick={() => setIsShowDialog(true)}
        disabled={isDisabled}
      />

      {isShowDialog && (
        <ReAssignDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
        />
      )}
    </>
  );
});
