import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IApproveDecisionDialogData {
  DecisionDate?: Date;
  Decision: IKeyValuePacket;
  DOTNotified?: Date;
}

export enum Application_Decision_Number {
  Unspecified = 0,
  Issued = 1,
  NOD = 2,
  Refused = 3,
  Withdrawn = 4,
  Lapsed = 5,
  Failure = 6,
  RefusedByPlanning = 7,
  PSASupported = 8,
  PSANotSupported = 9,
  PSADeferred = 10,
  PSAAdopted = 11,
  PSAAbandoned = 12,
  Authorised = 13,
  AuthorisedWithCondition = 14,
  AuthorisationRefused = 15,
  PSAAdoptedWithChanges = 16,
  OBJECTION = 17,
  NOOBJECTION = 18,
  NOOBJECTIONSUBJECTTOCONDITIONS = 19,
  VRRESPONDED = 20,
  Approved = 21,
  ApprovedSubjectToCondition = 22,
  ApprovedSubjectToStandardCondition = 23,
  PermitNotRequired = 24,
  PanelReportRefused = 25,
  SentToPanelHearing = 26,
  PSARSupportedWithChanges = 27,
  Gazetted = 28,
  GrantPermit = 29,
  GrantPermitWithConditions = 30,
  PermitRefused = 31,
  DeferredCommencement = 32,
  Cancelled = 33,
}
