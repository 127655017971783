import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colOnHoldCases: IColumnFields[] = [
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  { field: "Period", title: "Period" },
];
