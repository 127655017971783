import { DTO_Create_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { APIResponseError } from "@common/apis/model";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

class AmalgamateAssessmentStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _lovAmalgamateAssessment?: DTO_Create_Assessment_LOVs = undefined;
  private _isLoadingOnDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseError() {
    return toJS(this._responseLoadError);
  }

  setResponseError = (responseError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseError;
    });
  };

  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get lovsAmalgamateAssessment() {
    return this._lovAmalgamateAssessment;
  }

  setLOVsAmalgamateAssessment = (
    amalgamateLovs?: DTO_Create_Assessment_LOVs
  ) => {
    runInAction(() => {
      this._lovAmalgamateAssessment = amalgamateLovs;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._lovAmalgamateAssessment = undefined;
    });
  };
}

const amalgamateAssessmentContext = createContext(
  new AmalgamateAssessmentStore()
);

export const useAmalgamateAssessmentStore = () => {
  return useContext(amalgamateAssessmentContext);
};
