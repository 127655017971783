import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const fieldName = {
  Names: "Names",
  NameSelected: "NameSelected",
  SearchName: "SearchName",
  Name: "Name",
  Address: "Address",
  PrimaryKey: "ID",
};

export const colNames: IColumnFields[] = [
  { field: fieldName.Name, title: "Name", width: 200 },
  { field: fieldName.Address, title: "Address", width: 300 },
  {
    field: fieldName.PrimaryKey,
    title: "Entity Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
