import { IColumnFields } from "@components/cc-grid/model";

export const colInfringements: IColumnFields[] = [
  {
    field: "InfringementType",
    title: "Infringement Type",
  },
  {
    field: "Reference",
    title: "Reference",
  },
];
