import { getViewConfigurations } from "@app/products/property/api";
import { colRebatesEntitiesAssociated } from "@app/products/property/assessments/rebates/list/components/detail/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class RebatesDetailStore {
  private _columnConfigRebatesDetail: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get columnConfigRebatesDetail() {
    return this._columnConfigRebatesDetail;
  }
  setColumnConfigRebatesDetail = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnConfigRebatesDetail = columnConfig;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  /**
   * Calling api get configuration column
   * process columns
   */
  loadViewConfigurationRebatesDetail = async () => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let configColumn: IColumnFields[] = [...colRebatesEntitiesAssociated];
    configColumn = configColumn.map((item: any) => {
      if (item.field === "Entity_Name") {
        return {
          ...item,
          linkTo: (dataItem: { Entity_Id: number }) =>
            `/contacts/manageregistration/${dataItem.Entity_Id}`,
        };
      }
      return item;
    });
    const response = await getViewConfigurations(
      ViewConfiguration.Assessment_RebateEntitlementEntities
    );
    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (viewConfig?.length) {
        configColumn = processDynamicColumns(configColumn, viewConfig);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setColumnConfigRebatesDetail(configColumn);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnConfigRebatesDetail([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
    });
  };

  //reload
  reLoadApiConfig = () => {
    this.loadViewConfigurationRebatesDetail();
  };
}

const rebatesDetailStoreContext = createContext(new RebatesDetailStore());
export const useRebatesDetailStore = () => {
  return useContext(rebatesDetailStoreContext);
};
