import { ISessionGridDialog } from "@app/products/property/journals/session-list/components/dialogs/session-dialog/model";
import { VO_Journal_SessionList } from "@app/products/property/journals/session-list/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCNote } from "@components/cc-note/_index";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { colDataUpdateGeneralDialog } from "./config";
interface ISessionDialogProps {
  onClosePopup: () => void;
  onOkPopup: () => void;
  listItem: ISessionGridDialog[];
  msg?: string;
  title: string;
  warning?: string;
  isSubmitting?: boolean;
  notificationRef?: React.MutableRefObject<ICCLocalNotificationHandle | null>;
}
const nameOf = nameOfFactory<VO_Journal_SessionList>();
export default function SessionDialog({
  onClosePopup,
  onOkPopup,
  listItem,
  msg,
  title,
  warning,
  isSubmitting = false,
  notificationRef,
}: ISessionDialogProps) {
  return (
    <div className="cc-session-list-dialog">
      <CCDialog
        height="auto"
        maxWidth="30%"
        titleHeader={title}
        onClose={onClosePopup}
        bodyElement={
          <div className="cc-form">
            <CCLocalNotification ref={notificationRef} />
            <h6>{msg}</h6>
            {listItem && (
              <div className="cc-field">
                <CCGrid
                  data={listItem}
                  columnFields={colDataUpdateGeneralDialog}
                  primaryField={nameOf("Session_Number")}
                />
              </div>
            )}
            {warning && <CCNote message={warning} />}
          </div>
        }
        footerElement={
          <div className={"cc-dialog-footer-actions-right"}>
            <Button className={"cc-dialog-button"} onClick={onClosePopup}>
              Cancel
            </Button>
            <Button
              className={"cc-dialog-button"}
              iconClass={isSubmitting ? "fas fa-spinner fa-spin" : ""}
              disabled={isSubmitting}
              themeColor="primary"
              onClick={onOkPopup}
            >
              OK
            </Button>
          </div>
        }
      />
    </div>
  );
}
