import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { GET_CATEGORY_SETTINGS } from "@common/configs/setting";
import { globalStoreInstance } from "@common/stores/global/store";

export const getDataSetting = async (url: string): Promise<any | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}${GET_CATEGORY_SETTINGS}${url}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.HeaderMessage,
    };
  }
};
