import { Suburb } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Suburb>();
export const colPickSuburb: IColumnFields[] = [
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
];
