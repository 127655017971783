import { getAppealsByApplicationNumber } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/api";
import { PPRAppeal } from "@app/products/town-planning/ppr/permit-appeals/all/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AppealCompleteDialogStore {
  private _isLoadingDialog: boolean = false;
  private _appeals?: IKeyValuePacket[] = undefined;
  private _appealDecisions?: CoreKeyword[] = undefined;
  private _notifications: ICCNotification[] = [];
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }

  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get appealDecisions() {
    return this._appealDecisions;
  }

  setappealDecisions = (appealDecisions?: CoreKeyword[]) => {
    runInAction(() => {
      this._appealDecisions = appealDecisions;
    });
  };

  get appeals() {
    return this._appeals;
  }

  setAppeals = (appeals?: IKeyValuePacket[]) => {
    runInAction(() => {
      this._appeals = appeals;
    });
  };

  get isLoadingDialog() {
    return this._isLoadingDialog;
  }

  setIsLoadingDialog = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDialog = isLoading;
    });
  };

  get notifications() {
    return this._notifications;
  }

  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._appeals = undefined;
      this._appealDecisions = undefined;
      this._isLoadingDialog = false;
      this._notifications = [];
    });
  };

  loadFormData = async (applicationNumber: string) => {
    this.setIsLoadingDialog(true);
    let appeals = undefined;
    let appealDecisions = undefined;
    let errorResponse = undefined;
    const [appealResponse, appealDecisionResponse] = await Promise.all([
      getAppealsByApplicationNumber(applicationNumber),
      getKeywords(KEYWORD_TYPE.Core_AppealDecision, PRODUCT_TYPE_NUMBER.Core),
    ]);

    if (
      isSuccessResponse(appealResponse) &&
      isSuccessResponse(appealDecisionResponse)
    ) {
      let keyValues: Array<IKeyValuePacket> = appealResponse.data.value.map(
        (pprAppeal: PPRAppeal) => {
          let dateAppealed = pprAppeal.DateAppealed.toLocaleString("default", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            Key: pprAppeal.ID,
            Value: `${pprAppeal.AppealNo} - ${dateAppealed} - ${pprAppeal.Grounds} - ${pprAppeal.Status}`,
          };
        }
      );

      appeals = keyValues;
      appealDecisions = appealDecisionResponse.data;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    this.setResponseLoadError(errorResponse);
    this.setAppeals(appeals);
    this.setappealDecisions(appealDecisions);
    this.setIsLoadingDialog(false);
  };
}
export const appealCompleteDialogStore = new AppealCompleteDialogStore();
const appealCompleteDialogStoreContext = createContext(
  appealCompleteDialogStore
);
export const useAppealCompleteDialogStore = () => {
  return useContext(appealCompleteDialogStoreContext);
};
