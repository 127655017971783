import { SiteUser } from "@app/products/waste-water/[id]/model";
import { SiteUserCredential } from "@common/pages/settings/security/site-users/_id/model";

export interface IOfficer {
  ID: number;
  Name?: string;
  Position?: string;
  Email?: string;
  WorkPhone?: string;
  Mobile?: string;
  Role?: string;
}
export interface NewSiteUser {
  AuthenticationType: LoginAuthenticationType;
  UserName: string;
  UserPassword: string;
  PasswordQuestion: string;
  PasswordAnswer: string;
  bResetPasswordOnFirstLogon: boolean;
  EnterpriseKey_Set: string;
  EnterpriseRole_Set: EnterpriseRoleFunction;
  Contact_SiteUser: SiteUser;
  siteUserCredential: SiteUserCredential;
}
export enum EnterpriseRoleFunction {
  SystemInitialise = "SystemInitialise", // 0
  OOEnterpriseAdmin = "OOEnterpriseAdmin", // 1
  ProductAdmin = "ProductAdmin", // 2
  ProductUser = "ProductUser", // 3
  ServiceDeskAdmin = "ServiceDeskAdmin", // 4
  OODaemon = "OODaemon", // 7
  PortalUser = "PortalUser", // 11
}

export enum LoginAuthenticationType {
  None = "None", // 0
  Forms = "Forms", // 1
  WindowsUser = "WindowsUser", // 2
  Live = "Live", // 3
  OpenIDConnect = "OpenIDConnect", // 4
  SAML2 = "SAML2", // 4
  ThirdPartyProvider = "ThirdPartyProvider", // 1000
}
