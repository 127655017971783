import { getDisplayTextWithDashes } from "@common/utils/common";

export const refAuthRegisterBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `PPR - Register ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ApplicationNumber,
      selectedRow.SiteAddress,
    ]);
  },

  getBookmarkListViewDisplayName() {
    return "PPR - All Applications";
  },

  getBookmarkListViewDetail() {
    return "PPR - All Applications - RefAuth Register";
  },
};
