import { getBuildingRegistersCertificatesById } from "@app/products/building/registers/certificates/[id]/components/reference-sidebar/detail/api";
import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

export const CertificatesDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [certificatesInfo, setCertificatesInfo] = useState<
    IBuildingRegistersCertificatesFormData | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const certificatesId: string | number = lastSelectedRow?.ID ?? params.id;
  useEffect(() => {
    setIsLoading(true);
    getBuildingRegistersCertificatesById(certificatesId).then((data) => {
      setCertificatesInfo(data);
      setIsLoading(false);
    });
  }, [certificatesId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!certificatesInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded
          title={
            <ProductReferenceHeading
              title={"Register Number:"}
              value={certificatesInfo?.RegisterNumber?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Lodged Date:"}
              value={formatDisplayValue(
                certificatesInfo?.LodgedDate,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
