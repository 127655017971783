import {
  DTO_Certificate_LOVs,
  DTO_Workflow_CertificateCreate,
} from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessNewCertificate = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_CertificateCreate
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CertificateCreate>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificatecreate/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialNewCertificate =
  | [
      APIResponse<DTO_Certificate_LOVs>,
      APIResponse<DTO_Workflow_CertificateCreate>
    ]
  | APIResponse<DTO_Certificate_LOVs | DTO_Workflow_CertificateCreate>
  | undefined;
export const getInitialNewCertificate = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined = 0
): Promise<IGetInitialNewCertificate> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `api/property/int/certificate/lovs?workflowType=${workflowType}`
      ),
      workflowDraftId !== 0
        ? getWorkflowNewCertificate(workflowDraftId)
        : getNewWorkflowNewCertificate(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewCertificate = async (): Promise<
  APIResponse<DTO_Workflow_CertificateCreate>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificatecreate/new`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowNewCertificate = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CertificateCreate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/certificatecreate/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
