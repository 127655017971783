import {
  ServiceStandardActionTypeLink,
  ServiceStandardUpdateTriggers,
} from "@app/products/crms/[id]/model";
import { ActionPlusButton } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/automatic-actions/components/action-plus-button/_index";
import { colActions } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/automatic-actions/config";
import {
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardMapObj,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();
const nameOfServiceStandardActionTypeLink =
  nameOfFactory<ServiceStandardActionTypeLink>();

export interface AutomaticActionstProps {
  formRenderProps: FormRenderProps;
}

export const AutomaticActions = observer(
  ({ formRenderProps }: AutomaticActionstProps) => {
    const { valueGetter, onChange } = formRenderProps;

    const {
      serviceStandardChangeHandler,
      setIsExpandedActionAccordion,
      accessRight,
    } = useCRMSServiceStandardStore();

    const [listSelected, setListSelected] = useState<
      ServiceStandardActionTypeLink[]
    >([]);
    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState(false);

    const serviceStandardFormObj = valueGetter(
      nameOfServiceStandardMapObj("ServiceStandard")
    );

    const workflowActionsList = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "WorkflowActions"
      )}`
    );

    const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

    const handleAddAction = (data: IRespActionType[]) => {
      const ids = data?.map((item: IRespActionType) => item.ID) ?? [];
      if (ids.length > 0) {
        const params: ServiceStandardHandlerRequest = {
          ServiceStandardFormAction: Svc_FormAction.PickAutoActions,
          ServiceStandard: serviceStandardFormObj,
          EventArgs: ids ?? [],
          IsFirstTimeLoad: false,
        };

        serviceStandardChangeHandler(params, "Pick action type was failed.");
        setIsExpandedActionAccordion(true);
      }
    };

    const handleDeleteAction = () => {
      const ids = listSelected.map(
        (item: ServiceStandardActionTypeLink) => item.ActionType_ID
      );
      if (ids.length > 0) {
        const newList = workflowActionsList?.filter(
          (item: ServiceStandardActionTypeLink) =>
            !ids.includes(item.ActionType_ID)
        );

        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "WorkflowActions"
          )}`,
          { value: newList }
        );

        const saveTriggers =
          valueGetter(
            `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "_SaveTriggers"
            )}`
          ) ?? [];
        if (
          !saveTriggers.includes(
            ServiceStandardUpdateTriggers.UpdateAutomaticActions
          )
        ) {
          saveTriggers.push(
            ServiceStandardUpdateTriggers.UpdateAutomaticActions
          );
          onChange(
            `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "_SaveTriggers"
            )}`,
            { value: saveTriggers }
          );
        }

        setListSelected([]);
        setIsShowConfirmDeleteDialog(false);
      }
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Actions" />
            <CCGrid
              columnFields={colActions}
              primaryField={nameOfServiceStandardActionTypeLink(
                "ActionType_ID"
              )}
              data={valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "WorkflowActions"
                )}`
              )}
              selectableMode={"multiple"}
              selectedRows={listSelected}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <ActionPlusButton onClick={handleAddAction} />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove"
                    onClick={() => setIsShowConfirmDeleteDialog(true)}
                    disabled={listSelected.length === 0}
                  />
                </div>
              }
              onSelectionChange={(dataItem: any[]) => {
                setListSelected(dataItem ?? []);
              }}
              itemPerPage={50}
              isAutoHiddenPager={false}
              readOnly={isDisabled}
            />
          </div>
        </div>

        {isShowConfirmDeleteDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={`Are you sure you want to delete ${
              listSelected.length > 1 ? "these items" : "this item"
            }?`}
            onClosePopup={() => {
              setIsShowConfirmDeleteDialog(false);
            }}
            onConfirmYes={handleDeleteAction}
          />
        )}
      </section>
    );
  }
);
