import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const complaintsRoute: ICCRoute = {
  path: "complaints",
  name: "Complaints",
  enum: HealthManagerMenu.Complaints,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: HealthManagerMenu.ComplaintsByDate,
      //mock route: not implemented yet
      //component: require("./by-date/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: HealthManagerMenu.ComplaintsByStatus,
      //mock route: not implemented yet
      //component: require("./by-status/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      enum: HealthManagerMenu.ComplaintsByCategory,
      //mock route: not implemented yet
      //component: require("./by-category/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: HealthManagerMenu.ComplaintsByOfficer,
      //mock route: not implemented yet
      //component: require("./by-officer/_index").default,
    },
    {
      path: "closed-by-officer",
      name: "Closed By Action Officer",
      enum: HealthManagerMenu.ClosedComplaintsByOfficer,
      //mock route: not implemented yet
      //component: require("./closed-by-officer/_index").default,
    },
    {
      path: "unrelated-by-date",
      name: "Unrelated - By Date",
      enum: HealthManagerMenu.ComplaintsByDateUnrelated,
      //mock route: not implemented yet
      //component: require("./unrelated-by-date/_index").default,
    },
    {
      path: "unrelated-by-status",
      name: "Unrelated - By Status",
      enum: HealthManagerMenu.ComplaintsByStatusUnrelated,
      //mock route: not implemented yet
      //component: require("./unrelated-by-status/_index").default,
    },
    {
      path: "unrelated-by-category",
      name: "Unrelated - By Category",
      enum: HealthManagerMenu.ComplaintsByCategoryUnrelated,
      //mock route: not implemented yet
      //component: require("./unrelated-by-category/_index").default,
    },
    {
      path: "unrelated-by-officer",
      name: "Unrelated - By Officer",
      enum: HealthManagerMenu.ComplaintsByOfficerUnrelated,
      //mock route: not implemented yet
      //component: require("./unrelated-by-officer/_index").default,
    },
    {
      path: "unrelated-closed-by-officer",
      name: "Unrelated - Closed By Action Officer",
      enum: HealthManagerMenu.ClosedComplaintsByOfficerUnrelated,
      //mock route: not implemented yet
      //component: require("./unrelated-closed-by-officer/_index").default,
    },
    {
      path: "by-refno",
      name: "By RefNo",
      enum: HealthManagerMenu.EventsByRefNo,
      component: require("./by-refno/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      enum: HealthManagerMenu.AllEventsByCategory,
      component: require("./by-category/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      enum: HealthManagerMenu.AllEventsByStatus,
      component: require("./by-status/_index").default,
    },
  ],
};
