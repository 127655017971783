import {
  EFlagVisibilityStepCreateNoticeRun,
  EKeysOfStepsNewNoticeRun,
  IListConfigStepCreateNoticeRun,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";

export const listConfigStepCreateNoticeRun: IListConfigStepCreateNoticeRun[] = [
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Attribute,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Attribute,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Instalment,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Instalment,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Journal,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Journal,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.NewJournal,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Journal,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Supplementary,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Supplementary,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.NewSupplementary,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Supplementary,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Assessment,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Assessment,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Group,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Groups,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Collection,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Collection,
  },
  {
    keyNameStep: EKeysOfStepsNewNoticeRun.Option,
    flagVisibilityStep: EFlagVisibilityStepCreateNoticeRun.Options,
  },
];

export enum eNoticeType {
  AssessmentNoticeFromChargeRun = 10,
  InstalmentNotices = 20,
  LandRentAnnualAssessmentNotices = 71,
  RateNoticeFromChargeRunAndJournals = 11,
  ReminderNoticesArrears = 30,
  SupplementaryNotices = 40,
  ValuationNotices = 70,
}

export const dataWhichSupplementaryNoticeRun = [
  {
    label: "Existing supplementary created by me",
    value: SupplementaryMode.SelectFromMyList,
  },
  {
    label: "Existing supplementary",
    value: SupplementaryMode.SelectFromList,
  },
];

export const dataWhichJournalNoticeRun = [
  {
    label: "Existing journal created by me",
    value: JournalMode.SelectFromMyList,
  },
  {
    label: "Existing journal",
    value: JournalMode.SelectFromList,
  },
];
