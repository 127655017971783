import { DTO_Debt_Recovery_TabDetails } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getDebtRecoveryDetailsTabById = async (
  debtRecoveryId: number
): Promise<APIResponse<DTO_Debt_Recovery_TabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Debt_Recovery_TabDetails>(
      `/api/property/internal/debtrecovery/${debtRecoveryId}/details`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
