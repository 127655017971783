import { colAssessmentAccountAssociated } from "@app/products/property/meters/[id]/components/child-screens/general/components/form-element/config";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { DATE_FORMAT, DECIMAL_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Toast } from "@components/toast";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React, { ReactElement } from "react";

interface IProps {
  isNew: boolean;
  formRenderProps: FormRenderProps;
}

export function MeterFormElement({ formRenderProps }: IProps): ReactElement {
  const nameOf = nameOfFactory<IMeterFormGeneral>();
  const getValue = formRenderProps.valueGetter;

  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    Toast.displayToast({
      content: "🚀 The form is not modified !",
      type: "warning",
    });
  };

  return (
    <FormElement className="cc-meter-form-body">
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Meter details</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Meter number</label>
                <Field
                  disabled
                  name={nameOf("MeterNumber")}
                  placeholder={"Meter number"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Category</label>
                <Field
                  disabled
                  name={nameOf("Category")}
                  placeholder={"Category"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Make</label>
                <Field
                  disabled
                  name={nameOf("Make")}
                  placeholder={"Make"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Type</label>
                <Field
                  disabled
                  name={nameOf("Type")}
                  placeholder={"Type"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Condition</label>
                <Field
                  disabled
                  name={nameOf("Condition")}
                  placeholder={"Condition"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Status</label>
                <Field
                  disabled
                  name={nameOf("Status")}
                  placeholder={"Status"}
                  component={CCInput}
                />
              </div>
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Purchase date</label>
                <Field
                  disabled
                  name={nameOf("PurchaseDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Retest date</label>
                <Field
                  disabled
                  name={nameOf("RetestDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Installed on</label>
                <Field
                  disabled
                  name={nameOf("InstalledOn")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Number of dials</label>
                <Field
                  disabled
                  name={nameOf("NumberOfDials")}
                  placeholder={"Number of dials"}
                  component={NumericTextBox}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Meter size</label>
                <Field
                  disabled
                  name={nameOf("MeterSize")}
                  placeholder={"Meter size"}
                  component={NumericTextBox}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Average daily</label>
                <Field
                  disabled
                  name={nameOf("AverageDaily")}
                  placeholder={"Average daily"}
                  component={NumericTextBox}
                  format={DECIMAL_FORMAT.DECIMAL1}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Unit of measure</label>
                <Field
                  disabled
                  name={nameOf("UnitOfMeasure")}
                  placeholder={"Unit of measure"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Estimated usage since last</label>
                <Field
                  disabled
                  name={nameOf("EstimatedUsageSinceLast")}
                  placeholder={"Estimated usage since last"}
                  component={CCInput}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <label className="cc-label">Reading details</label>
        <div className="cc-custom-sub-panel-bar cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Route</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Route name</label>
                  <Field
                    disabled
                    name={nameOf("RouteName")}
                    placeholder={"Route name"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Sequence within route</label>
                  <Field
                    disabled
                    name={nameOf("SequenceWithinRoute")}
                    placeholder={"Sequence within route"}
                    component={CCInput}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Assigned reading device</label>
                  <Field
                    disabled
                    name={nameOf("AssignedReadingDevice")}
                    placeholder={"Assigned reading device"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Device assigned to</label>
                  <Field
                    disabled
                    name={nameOf("DeviceAssignedTo")}
                    placeholder={"Device assigned to"}
                    component={CCInput}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Meter location</label>
              <Field
                disabled
                name={nameOf("MeterLocation")}
                placeholder={"Meter location"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reading instructions</label>
              <Field
                disabled
                name={nameOf("ReadingInstructions")}
                placeholder={"Reading instructions"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Special instructions</label>
              <Field
                disabled
                name={nameOf("SpecialInstructions")}
                placeholder={"Special instructions"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Phone before reading</label>
              <Field
                name={nameOf("PhoneBeforeReading")}
                checked={getValue(nameOf("PhoneBeforeReading"))}
                component={CCSwitch}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Keys are required</label>
              <Field
                name={nameOf("KeysAreRequired")}
                checked={getValue(nameOf("KeysAreRequired"))}
                component={CCSwitch}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Email before reading</label>
              <Field
                name={nameOf("EmailBeforeReading")}
                checked={getValue(nameOf("EmailBeforeReading"))}
                component={CCSwitch}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Contact name</label>
              <Field
                disabled
                name={nameOf("ContactName")}
                placeholder={"Contact name"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Contact phone</label>
              <Field
                disabled
                name={nameOf("ContactPhone")}
                placeholder={"Contact phone"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <Field
                label="Email address"
                disabled
                name={nameOf("EmailAddress")}
                placeholder={"Email address"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={formRenderProps.onChange}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Next scheduled reading date</label>
              <Field
                disabled
                name={nameOf("NextScheduledReadingDate")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reading</label>
              <div className="cc-custom-input-group">
                <Field
                  disabled
                  name={nameOf("Reading")}
                  placeholder={"Reading"}
                  component={NumericTextBox}
                />
                <div className="cc-input-group-postfix">month(s)</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Last reading</label>
          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Reading date</label>
                <Field
                  disabled
                  name={nameOf("ReadingDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Number of days</label>
                <Field
                  disabled
                  name={nameOf("NumberOfDays")}
                  placeholder={"Number of days"}
                  component={NumericTextBox}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Reading</label>
                <Field
                  disabled
                  name={nameOf("LastReading")}
                  placeholder={"Reading"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Usage</label>
                <div className="cc-custom-input-group">
                  <Field
                    disabled
                    name={nameOf("Usage")}
                    placeholder={"Usage"}
                    component={NumericTextBox}
                  />
                  <div className="cc-input-group-postfix">kl</div>
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Average daily</label>
                <div className="cc-custom-input-group">
                  <Field
                    disabled
                    name={nameOf("LRDAverageDaily")}
                    placeholder={"Average daily"}
                    component={NumericTextBox}
                    format={DECIMAL_FORMAT.DECIMAL1}
                  />
                  <div className="cc-input-group-postfix">kl</div>
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Status</label>
                <Field
                  disabled
                  name={nameOf("LRDStatus")}
                  placeholder={"Status"}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Reader</label>
                <Field
                  disabled
                  name={nameOf("Reader")}
                  placeholder={"Reader"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Comment</label>
                <Field
                  disabled
                  name={nameOf("Comment")}
                  placeholder={"Comment"}
                  component={CCInput}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">
            Assessments/Accounts associated with meter
          </label>
          <CCGrid
            data={getValue(nameOf("AssessmentsAccountsAssociated"))}
            columnFields={colAssessmentAccountAssociated}
            primaryField={"AssessmentId"}
          />
        </div>
      </section>
    </FormElement>
  );
}
