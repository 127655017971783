import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface IMeterReading {
  PropertyAddress?: string;
  AccountNames?: string;
  ReadingStatus?: IKeyValuePacket;
  PreviousReadingDate: Date;
  PreviousReading: number;
  CurrentReadingDate?: Date;
  CurrentReading?: number;
  NumberOfDays?: number;
  Usage?: string;
  UnitOfMeasure?: string;
  ReadingOfficer?: string;
  Comment?: string;
  MeterNumber: string;
  MeterId: number;
  Status: METER_STATUS;
}

export enum OPTIONS_SEARCH {
  METER_NUMBER = "Meter Number",
  METER_ID = "Meter Id",
}
export enum SearchField {
  MeterNumber = "_searchByAssessmentNumber",
  MeterId = "_searchByAssessmentId",
}

export const textFieldMapping: {
  [key: string]: string;
} = {
  [OPTIONS_SEARCH.METER_NUMBER]: SearchField.MeterNumber,
  [OPTIONS_SEARCH.METER_ID]: SearchField.MeterId,
};

export const MAX_READING = 9999;

export enum BUTTONS {
  ENTER = "Enter",
  ENTER_AND_EXIT = "EnterAndExit",
}

export enum METER_STATUS {
  ACTIVE = "Active",
  RETIRED = "Retired",
  BEING_TESTED = "Being Tested",
}
