import { getMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/api";
import { colRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/config";
import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class RegisterMetersStore {
  private _isLoading: boolean = false;
  private _gridColumns: IColumnFields[] = colRegisterMeters;
  private _metersData: IRegisterMeters[] = [];
  private _metersSelected: IRegisterMeters[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get gridColumns() {
    return this._gridColumns;
  }

  setGridColumns = (gridColumns: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumns = gridColumns;
    });
  };

  get metersData() {
    return this._metersData;
  }
  setMetersData = (MetersData: IRegisterMeters[]) => {
    runInAction(() => {
      this._metersData = MetersData;
    });
  };

  get metersSelected() {
    return this._metersSelected;
  }
  setMetersSelected = (meterSelected: IRegisterMeters[]) => {
    runInAction(() => {
      this._metersSelected = meterSelected;
    });
  };

  //Action

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._gridColumns = colRegisterMeters;
      this._metersData = [];
      this._metersSelected = [];
    });
  };
  loadMeters = async () => {
    this.setIsLoading(true);
    const data = await getMeters();
    this.setMetersData(data);
    this.setIsLoading(false);
  };
}
const RegisterMetersStoreContext = createContext(new RegisterMetersStore());
export const useRegisterMetersStore = () => {
  return useContext(RegisterMetersStoreContext);
};
