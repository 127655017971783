import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IRegistersExtensionOfTimeFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegistersExtensionOfTimeForm = observer(
  ({ formRenderProps }: IRegistersExtensionOfTimeFormProps) => {
    const { valueGetter } = formRenderProps;
    const { getConfigDataField } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    const fieldExtensionOfTimeQuestions = getConfigDataField(
      ECorporateSettingsField.TP_ExtensionOfTimeQuestions
    );
    const fieldExtentionOfTimeChecklistName = getConfigDataField(
      ECorporateSettingsField.TP_ExtentionOfTimeChecklistName
    );
    const fieldSendAllocationEmailToPlanner_EOT = getConfigDataField(
      ECorporateSettingsField.TP_SendAllocationEmailToPlanner_EOT
    );
    const fieldSendRequesterEmailWhenAllocatePlanner_EOT = getConfigDataField(
      ECorporateSettingsField.TP_SendRequesterEmailWhenAllocatePlanner_EOT
    );
    const fieldNotifyAdminByCCWhenAllocatedToRequestor_EOT = getConfigDataField(
      ECorporateSettingsField.TP_NotifyAdminByCCWhenAllocatedToRequestor_EOT
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldExtensionOfTimeQuestions && (
            <div className="cc-field">
              <CCLabel title={fieldExtensionOfTimeQuestions?.Title ?? ""} />
              <Field
                name={fieldExtensionOfTimeQuestions.FieldName}
                component={CCSwitch}
                checked={valueGetter(fieldExtensionOfTimeQuestions.FieldName)}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldExtentionOfTimeChecklistName && (
            <div className="cc-field">
              <CCLabel
                title={fieldExtentionOfTimeChecklistName?.Title ?? ""}
                isMandatory={fieldExtentionOfTimeChecklistName.IsMandatory}
              />
              <Field
                name={fieldExtentionOfTimeChecklistName.FieldName}
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldSendAllocationEmailToPlanner_EOT && (
            <div className="cc-field">
              <CCLabel
                title={fieldSendAllocationEmailToPlanner_EOT?.Title ?? ""}
              />
              <Field
                name={fieldSendAllocationEmailToPlanner_EOT.FieldName}
                component={CCSwitch}
                checked={valueGetter(
                  fieldSendAllocationEmailToPlanner_EOT.FieldName
                )}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldSendRequesterEmailWhenAllocatePlanner_EOT && (
            <div className="cc-field">
              <CCLabel
                title={
                  fieldSendRequesterEmailWhenAllocatePlanner_EOT?.Title ?? ""
                }
              />
              <Field
                name={fieldSendRequesterEmailWhenAllocatePlanner_EOT.FieldName}
                component={CCSwitch}
                checked={valueGetter(
                  fieldSendRequesterEmailWhenAllocatePlanner_EOT.FieldName
                )}
                disabled={!isEditing}
              />
            </div>
          )}
          {fieldNotifyAdminByCCWhenAllocatedToRequestor_EOT && (
            <div className="cc-field">
              <CCLabel
                title={
                  fieldNotifyAdminByCCWhenAllocatedToRequestor_EOT?.Title ?? ""
                }
                isMandatory={
                  fieldNotifyAdminByCCWhenAllocatedToRequestor_EOT.IsMandatory
                }
              />
              <Field
                name={
                  fieldNotifyAdminByCCWhenAllocatedToRequestor_EOT.FieldName
                }
                component={CCInput}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
