import { LeakageDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/leakage/_index";
import { IOccurrences } from "@app/products/property/meters/[id]/components/child-screens/leakage/model";
import { useMeterLeakageStore } from "@app/products/property/meters/[id]/components/child-screens/leakage/store";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
export const EditLeakageButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedLeakage, editOccurrence } = useMeterLeakageStore();
  const { meter } = useMeterStore();
  const { pushNotification } = useCCAppNotificationStore();

  const meterInfoData = useMemo(() => {
    if (selectedLeakage && selectedLeakage?.length > 0) {
      return { ...meter, ...selectedLeakage[0] };
    }
    return { ...meter };
  }, [meter, selectedLeakage]);

  return (
    <>
      <CCNavButton
        title={"Edit"}
        disabled={selectedLeakage?.length !== 1}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <LeakageDialog
          initialData={meterInfoData}
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={(event) => {
            const editDataOccurrence: IOccurrences = {
              LeakageOccurredApproxFrom: event.LeakageOccurredApproxFrom,
              LeakageOccurredUpTo: event.LeakageOccurredUpTo,
              EstimatedLeakage: event?.EstimatedLeakage,
              DescriptionOfLeak: event?.DescriptionOfLeak,
              EnteredBy: "communitycentral_dev",
              EnteredOn: new Date(),
              MeterLeakageId: event.MeterLeakageId,
              IsLeakageTaken: event?.IsLeakageTaken,
            };
            pushNotification({
              title: `Meter leakage edited successfully.`,
              type: "success",
            });
            editOccurrence(editDataOccurrence);
          }}
        />
      )}
    </>
  );
});
