import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import {
  CCNumericTextBox,
  ICCNumericTextBoxProps,
} from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import { NumericTextBoxProps } from "@progress/kendo-react-inputs";
import React from "react";

export interface INumericTextboxGlobalSettings {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
  colSpan?: number;
  suffixCustom?: string; // unit label behind the field
}

export const NumericTextboxGlobalSettings: React.FC<
  INumericTextboxGlobalSettings & NumericTextBoxProps & ICCNumericTextBoxProps
> = ({
  data,
  isEditing,
  isFieldVisible = true,
  colSpan,
  suffixCustom,
  ...others
}) => {
  return data && isFieldVisible ? (
    <div className={`cc-field${colSpan ? " cc-col-span-" + colSpan : ""}`}>
      <label className="cc-label">
        {data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      {data?.Description && (
        <label className="cc-settings-description">
          {sanitizeHtml(data?.Description)}
        </label>
      )}
      <div className="cc-custom-input-group">
        <Field
          name={data?.FieldName ?? others?.name}
          component={CCNumericTextBox}
          disabled={!isEditing}
          validator={data.IsMandatory ? requiredValidator : undefined}
          {...others}
        />
        {suffixCustom ? (
          <div className="cc-input-group-postfix ">{suffixCustom}</div>
        ) : null}
      </div>
    </div>
  ) : null;
};
