import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { PercentageAreaTextBoxCustomCell } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/components/custom-cell/percentage-area-text-box-custom-cell/_index";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Title>();
export const colFoliosToBeLinked: IColumnFields[] = [
  {
    field: nameOf("ReferenceNumber"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("Lot"),
    title: "Lot",
  },
  {
    field: nameOf("AssociatedPICAreaSet"),
    title: "% Area",
    format: PERCENTAGE_FORMAT.PERCENTAGE_1,
    editable: true,
    editCell: PercentageAreaTextBoxCustomCell,
    width: 150,
  },
  {
    field: nameOf("Active_From"),
    title: "Active from",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Active_To"),
    title: "Active to",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOf("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOf("Section"),
    title: "Section",
  },
  {
    field: nameOf("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOf("Volume"),
    title: "Volume",
  },
  {
    field: nameOf("Folio"),
    title: "Folio",
  },
  {
    field: nameOf("County"),
    title: "County",
  },
  {
    field: nameOf("Parish"),
    title: "Parish",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area (m2)",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOf("TitleId"),
    title: "Title Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
