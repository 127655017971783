import { NUMBER_FORMAT } from "@common/constants/common-format";
import { FSR_CODE } from "@common/constants/fsrCode";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Keyword } from "@common/models/keyword";
import { TreePacket } from "@common/models/treePacket";
import { SystemFilter } from "@common/pages/settings/lookups/components/system-filter/_index";
import { useKeywordsStore } from "@common/pages/settings/lookups/keywords/_id/store";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCEditor } from "@components/cc-editor/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { EditorChangeEvent } from "@progress/kendo-react-editor";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

interface ILookupKeywordFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Keyword>();
const nameOfKeywordType = nameOfFactory<TreePacket>();

export const LookupKeywordFormElement = observer(
  ({ formRenderProps }: ILookupKeywordFormElementProps) => {
    const isNew = useIsNew();
    const { settings } = useCommonProductStore();
    const { valueGetter, onChange } = formRenderProps;
    const {
      keyword,
      keywordType,
      keywordDataByType,
      loadKeywordDataByType,
      isInactive,
    } = useKeywordsStore();

    const [isParentKeywordEnable, setIsParentKeywordEnable] = useState(false);
    const [isTargetDaysEnable, setIsTargetDaysEnable] = useState(false);
    const [isPublishToWebEnable, setIsPublishToWebEnable] = useState(false);
    const [isRestrictedEnable, setIsRestrictedEnable] = useState(false);
    const [isFSRCodeEnable, setIsFSRCodeEnable] = useState(false);
    const [isURLEnable, setIsURLEnable] = useState(false);
    const [isLinkENUMSEnable, setIsLinkENUMSEnable] = useState(false);
    const [isModulesUsedEnable, setIsModulesUsedEnable] = useState(false);
    const [isExpiryMonthsEnable, setIsExpiryMonthsEnable] = useState(false);
    const [isCodeNumericType, setIsCodeNumericType] = useState(false);
    const [targetDaysLabel, setTargetDaysLabel] = useState("Target days");
    const [codeLabel, setCodeLabel] = useState("Code");
    const [restrictedLabel, setRestrictedLabel] = useState("Restricted");
    const [linkENUMSLabel, setLinkENUMSLabel] = useState("Colour code");

    const isEnablePPR = getBoolValueSetting(
      settings[ECorporateSettingsField.TP_Application_PPR]
    );
    const isCategoryEnable = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Keyword_Categories]
    );
    const isSecurityEnable = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Keyword_Security]
    );

    useEffectOnce(() => {
      !isNew &&
        showHideByKeywordType(
          KEYWORD_TYPE[keyword?.KeywordType_ENUM as KEYWORD_TYPE]
        );
    });

    const handleKeywordTypeOnChange = (value: TreePacket) => {
      onChange("KeywordType_ENUM", {
        value: KEYWORD_TYPE[parseInt(value?.Key)] ?? null,
      });
      loadKeywordDataByType(parseInt(value?.Key) as KEYWORD_TYPE);
      showHideByKeywordType(parseInt(value?.Key) as KEYWORD_TYPE);
    };

    const setLinkEnum = (label: string) => {
      setLinkENUMSLabel(label);
      setIsLinkENUMSEnable(true);
    };

    const showHideByKeywordType = (keywordType?: any) => {
      setIsParentKeywordEnable(false);
      setIsTargetDaysEnable(false);
      setIsPublishToWebEnable(false);
      setIsRestrictedEnable(false);
      setIsFSRCodeEnable(false);
      setIsURLEnable(false);
      setIsLinkENUMSEnable(false);
      setIsModulesUsedEnable(false);
      setIsExpiryMonthsEnable(false);
      setIsCodeNumericType(false);
      setTargetDaysLabel("Target days");
      setCodeLabel("Code");
      setRestrictedLabel("Restricted");
      setLinkENUMSLabel("Colour code");

      if (keywordDataByType?.ModulesUsed !== "") setIsModulesUsedEnable(true);
      switch (keywordType) {
        case KEYWORD_TYPE.TP_ApplicationBuilding_CouncilConcentRegulation:
          setRestrictedLabel("Mandatory");
          setIsRestrictedEnable(true);
          setIsParentKeywordEnable(true);
          setIsTargetDaysEnable(true);
          setIsPublishToWebEnable(true);
          break;
        case KEYWORD_TYPE.Core_RegisterPlanningEnquiryType:
          setIsTargetDaysEnable(true);
          break;
        case KEYWORD_TYPE.Building_CouncilConsent_DocumentChecklist:
          setIsPublishToWebEnable(true);
          break;
        case KEYWORD_TYPE.Building_PrivateSurveyor_DocumentChecklist:
          setRestrictedLabel("Mandatory");
          setIsRestrictedEnable(true);
          setIsPublishToWebEnable(true);
          setIsParentKeywordEnable(true);
          break;
        case KEYWORD_TYPE.Animals_DogBreeds:
          setRestrictedLabel("Restricted");
          setIsRestrictedEnable(true);
          setIsPublishToWebEnable(true);
          break;
        case KEYWORD_TYPE.Animals_CatBreeds:
        case KEYWORD_TYPE.Animals_Colour:
        case KEYWORD_TYPE.TP_ApplicationBuilding_UseOfBuilding:
        case KEYWORD_TYPE.Core_RegisterBuildingPoolSpaType:
        case KEYWORD_TYPE.TP_ApplicationBuilding_LandType:
        case KEYWORD_TYPE.TP_ApplicationBuilding_LandOwnership:
        case KEYWORD_TYPE.Core_InsuranceCompany:
        case KEYWORD_TYPE.TP_ApplicationBuilding_RoofCladdingMaterial:
        case KEYWORD_TYPE.TP_ApplicationBuilding_ExtWallMaterial:
        case KEYWORD_TYPE.Radiation_UseCategory:
        case KEYWORD_TYPE.Radiation_UseSubCategory:
        case KEYWORD_TYPE.Radiation_PermittedRadiationSource:
        case KEYWORD_TYPE.Radiation_TypeOfSources:
        case KEYWORD_TYPE.Core_OccupationSector:
          setIsPublishToWebEnable(true);
          break;
        case KEYWORD_TYPE.TP_ApplicationBuilding_BCA:
          setIsPublishToWebEnable(true);
          setIsParentKeywordEnable(true);
          setIsCodeNumericType(true);
          setCodeLabel("Class");
          break;
        case KEYWORD_TYPE.Wastewater_RiskLevel:
          setIsTargetDaysEnable(true);
          setTargetDaysLabel("Default frequency");
          break;
        case KEYWORD_TYPE.Core_AppealDuration:
          setIsTargetDaysEnable(true);
          setTargetDaysLabel("Duration in hours");
          break;
        case KEYWORD_TYPE.Core_Priority:
          setLinkEnum("Colour code");
          break;
        case KEYWORD_TYPE.Core_InspectionArea:
          //if (this.cbxSystemFilter.Items.FindByValue(((int)SYSENUMS.ProductType.Animals).ToString()) != null)
          //this.cbxSystemFilter.Items.FindByValue(((int)SYSENUMS.ProductType.Animals).ToString()).Selected = true;
          break;
        case KEYWORD_TYPE.Core_ReferralTrigger: //PPR
          if (isEnablePPR) {
            setIsTargetDaysEnable(true);
            setTargetDaysLabel("Reply in(days)");
          }
          break;
        case KEYWORD_TYPE.Core_Occupation:
          setIsPublishToWebEnable(true);
          setIsParentKeywordEnable(true);
          break;
        case KEYWORD_TYPE.Core_RegisterPlanningEnquiryCategory: //PPR
          if (isEnablePPR) {
            setIsTargetDaysEnable(true);
          }
          break;
        case KEYWORD_TYPE.CRS_PortalSupportDocumentType:
          setIsExpiryMonthsEnable(true);
          break;
        case KEYWORD_TYPE.SRU_IssueType:
          setIsParentKeywordEnable(true);
          setLinkEnum("Issue type");
          break;
        case KEYWORD_TYPE.Wastewater_ProductSubCategory:
        case KEYWORD_TYPE.CUSTOMERSERVICE_CD_VehicleImpoundChecklist_VehicleModel:
        case KEYWORD_TYPE.Core_RegisterCouncilConsentAssessmentSub:
        case KEYWORD_TYPE.NCRisk_ImportanceRating:
        case KEYWORD_TYPE.NCRisk_CapacityToEscalate:
        case KEYWORD_TYPE.NCRisk_ScaleOfBreach:
        case KEYWORD_TYPE.NCRisk_UrgencyRating:
        case KEYWORD_TYPE.NCRisk_LevelOfEnforcement:
        case KEYWORD_TYPE.SRU_ServiceType:
        case KEYWORD_TYPE.INFRINGEMENTS_VehicleModel:
        case KEYWORD_TYPE.CEM_Section:
        case KEYWORD_TYPE.Core_DisabilitySubType:
        case KEYWORD_TYPE.CUSTOMERSERVICE_CD_DisabilityWorker_SubOutcome:
        case KEYWORD_TYPE.CUSTOMERSERVICE_IssueClassification:
        case KEYWORD_TYPE.CUSTOMERSERVICE_IssueSubClassification:
        case KEYWORD_TYPE.Core_Allegation_Classification:
          setIsParentKeywordEnable(true);
          break;
        case KEYWORD_TYPE.SRU_IssueCategory:
          setLinkEnum("Issue category");
          break;
        case KEYWORD_TYPE.CUSTOMERSERVICE_IssueSource:
          setLinkEnum("Issue source");
          break;
        case KEYWORD_TYPE.CUSTOMERSERVICE_CD_DisabilityWorker_Outcome:
          setLinkEnum("Type");
          break;
        case KEYWORD_TYPE.Core_InformationSharing_Direction:
          setLinkEnum("Direction");
          break;
        case KEYWORD_TYPE.Core_InformationSharing_Type:
          setLinkEnum("Referral");
          break;
        case KEYWORD_TYPE.Core_InformationSharing_FromTo:
          setLinkEnum("Other");
          break;
        case KEYWORD_TYPE.Core_ActionOutcome:
          setLinkEnum("Action outcome group");
          break;
        case KEYWORD_TYPE.Core_ExternalDocumentType:
          setLinkEnum("External document type");
          break;
        case KEYWORD_TYPE.CUSTOMERSERVICE_CD_DisabilityWorker_RelationshipToDW:
          setLinkEnum("Relationship to disability worker");
          break;
        case KEYWORD_TYPE.CUSTOMERSERVICE_CD_DisabilityWorker_NDISStatus:
          setLinkEnum("NDIS QSC status");
          break;
        case KEYWORD_TYPE.Core_Undertaking_ClosureReason:
          setLinkEnum("Closure reason classification");
          break;
        case KEYWORD_TYPE.Core_InspectionLocation:
          setIsLinkENUMSEnable(true);
          break;
        case KEYWORD_TYPE.Core_Investigation_RelatedEntities_Notifed:
          setLinkEnum("Related entities to be notifed");
          break;
        case KEYWORD_TYPE.DWCMS_NonVDWCDisciplinaryActionType:
          setLinkEnum("Disciplinary action type");
          break;
        case KEYWORD_TYPE.DWCMS_SourceType:
          setLinkEnum("Source type");
          break;
        case KEYWORD_TYPE.DWCMS_PrimaryPlaceOfPracticeSource:
          setLinkEnum("Source");
          break;
        case KEYWORD_TYPE.DWCMS_ApplicationPathway:
          setLinkEnum("Pathway");
          break;
        case KEYWORD_TYPE.DWCMS_ApplicationOrigin:
          setLinkEnum("Origin");
          break;
        case KEYWORD_TYPE.Inspection_Assessment_Comments:
          setLinkEnum("Assessment types");
          break;
        case KEYWORD_TYPE.Core_WebLinkCategory:
          setLinkEnum("Web link category");
          break;
        case KEYWORD_TYPE.DWCMS_DADisciplinaryActionKind:
          setLinkEnum("Kind of disciplinary action");
          break;
        case KEYWORD_TYPE.DWCMS_DAOrderType:
          setLinkEnum("Disciplinary action orderType");
          break;
        case KEYWORD_TYPE.DWCMS_ApplicationStatus_Rollback:
          setLinkEnum("Application status");
          break;
        case KEYWORD_TYPE.Radiation_DisposalFacilitatorLocation:
          setLinkEnum("System value");
          break;
        case KEYWORD_TYPE.Core_InspectionCompliance:
        case KEYWORD_TYPE.Core_AssessmentCompliance:
        case KEYWORD_TYPE.Core_Verdict:
        case KEYWORD_TYPE.Core_ProsecutionType:
        case KEYWORD_TYPE.HealthManager_FoodSafetyProgram_Templates:
        case KEYWORD_TYPE.Core_AuditCompliance:
          setIsFSRCodeEnable(true);
          break;

        default: {
          setLinkENUMSLabel("System value");
          break;
        }
      }
    };

    const KeywordField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement className="cc-keyword-form-elements">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Keyword type" isMandatory={true}></CCLabel>
              <KeywordField
                name={nameOf("KeywordType_ENUM")}
                dataItemKey={nameOfKeywordType("Key")}
                textField={nameOfKeywordType("Value")}
                component={CCSearchComboBox}
                data={keywordType ?? []}
                value={getDropdownValue(
                  KEYWORD_TYPE[
                    valueGetter(nameOf("KeywordType_ENUM"))
                  ]?.toString(),
                  keywordType
                )}
                onChange={(event: ComboBoxChangeEvent) =>
                  handleKeywordTypeOnChange(event.value)
                }
                disabled={!isNew}
                validator={requiredValidator}
              />
            </div>
            {isParentKeywordEnable && (
              <div className="cc-field">
                <label className="cc-label">Parent type</label>
                <KeywordField
                  name={nameOf("Parent_KWD")}
                  dataItemKey={nameOfKeywordType("Key")}
                  textField={nameOfKeywordType("Value")}
                  component={CCSearchComboBox}
                  data={keywordDataByType?.ParentKeyword ?? []}
                  value={getDropdownValue(
                    valueGetter(nameOf("Parent_KWD")),
                    keywordDataByType?.ParentKeyword
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("Parent_KWD"), {
                      value: event.target.value?.Key ?? null,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            {isCategoryEnable && (
              <div className="cc-field">
                <label className="cc-label">Category</label>
                <KeywordField
                  name={nameOf("Category")}
                  component={CCInput}
                  placeholder={"Category"}
                />
              </div>
            )}
            <div className="cc-field">
              <CCLabel title="Name" isMandatory={true} />
              <KeywordField
                name={nameOf("Name")}
                component={CCInput}
                placeholder={"Name"}
                validator={requiredValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <KeywordField
                name={nameOf("Description")}
                component={CCTextArea}
                rows={3}
                placeholder={"Description"}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Full description</label>
              <KeywordField
                name={nameOf("FullDescription")}
                component={CCEditor}
                value={valueGetter("FullDescription")}
                contentStyle={{ height: 200 }}
                placeholder={"Full Description"}
                onChange={(event: EditorChangeEvent) => {
                  onChange(nameOf("FullDescription"), {
                    value: event.html,
                  });
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            {!isNew && (
              <div className="cc-field">
                <label className="cc-label">Active</label>
                <KeywordField
                  name={nameOf("Sys_DBRowState")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Sys_DBRowState"))}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Sort index</label>
              <KeywordField
                name={nameOf("SortOrderIndex")}
                component={CCNumericTextBox}
                placeholder={"Sort Index"}
                format={NUMBER_FORMAT.NUMBER2}
              />
            </div>
            {isTargetDaysEnable && (
              <div className="cc-field">
                <label className="cc-label">{targetDaysLabel}</label>
                <KeywordField
                  name={nameOf("TargetDays")}
                  component={CCNumericTextBox}
                  placeholder={targetDaysLabel}
                  format={NUMBER_FORMAT.NUMBER2}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">{codeLabel}</label>
              <KeywordField
                name={nameOf("Code")}
                component={isCodeNumericType ? CCNumericTextBox : CCInput}
                placeholder={"Code"}
              />
            </div>
            {isPublishToWebEnable && (
              <div className="cc-field">
                <label className="cc-label">Publish to web</label>
                <KeywordField
                  name={nameOf("IsPublishToWeb")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("IsPublishToWeb"))}
                />
              </div>
            )}
            {isRestrictedEnable && (
              <div className="cc-field">
                <label className="cc-label">{restrictedLabel}</label>
                <KeywordField
                  name={nameOf("IsRestricted")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("IsRestricted"))}
                />
              </div>
            )}
            {isFSRCodeEnable && (
              <div className="cc-field">
                <label className="cc-label">FSR code</label>
                <KeywordField
                  name={nameOf("FSRCode_ENUM")}
                  dataItemKey={nameOfKeywordType("Key")}
                  textField={nameOfKeywordType("Value")}
                  component={CCSearchComboBox}
                  data={keywordDataByType?.FSRCode ?? []}
                  value={getDropdownValue(
                    FSR_CODE[valueGetter(nameOf("FSRCode_ENUM")) ?? "NA"],
                    keywordDataByType?.FSRCode
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    onChange(nameOf("FSRCode_ENUM"), {
                      value: FSR_CODE[event.target.value?.Key] ?? null,
                    });
                  }}
                />
              </div>
            )}
            {isURLEnable && (
              <div className="cc-field">
                <label className="cc-label">URL</label>
                <KeywordField
                  name={nameOf("URL")}
                  component={CCInput}
                  placeholder={"URL"}
                />
              </div>
            )}
            {isLinkENUMSEnable && (
              <div className="cc-field">
                <label className="cc-label">{linkENUMSLabel}</label>
                <KeywordField
                  name={nameOf("Enumerated_ID")}
                  dataItemKey={nameOfKeywordType("Key")}
                  textField={nameOfKeywordType("Value")}
                  component={CCSearchComboBox}
                  data={keywordDataByType?.LinkEnums ?? []}
                  isUseDefaultOnchange
                />
              </div>
            )}
          </div>
        </section>
        <hr className="cc-divider" />
        {isSecurityEnable && [
          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Can be selected by</label>
              </div>
            </div>
          </section>,
          <hr className="cc-divider" />,
        ]}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            {isModulesUsedEnable && (
              <div className="cc-field">
                <label className="cc-label">Used in</label>
                <p className="cc-label">
                  {sanitizeHtml(keywordDataByType?.ModulesUsed ?? "")}
                </p>
              </div>
            )}
          </div>
          <div className="cc-form-cols-3">
            <SystemFilter formRenderProps={formRenderProps}></SystemFilter>
          </div>
          <div className="cc-form-cols-3">
            {isExpiryMonthsEnable && (
              <div className="cc-field">
                <label className="cc-label">Expiry months</label>
                <KeywordField
                  name={nameOf("ExpiryMonths")}
                  component={CCNumericTextBox}
                  placeholder={"Expiry Months"}
                />
              </div>
            )}
          </div>
        </section>
      </FormElement>
    );
  }
);
