export const mockInProgress = [
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78593,
    DateRequestRegistered: new Date("28-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 41,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78706,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 65,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78708,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 67,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78685,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 45,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78689,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 48,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78705,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 64,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78690,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 49,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78702,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 61,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78536,
    DateRequestRegistered: new Date("27-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 34,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78695,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 54,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78719,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 78,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78693,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 52,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78701,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 60,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78713,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 72,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78686,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 46,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78595,
    DateRequestRegistered: new Date("28-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 42,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78688,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 47,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78656,
    DateRequestRegistered: new Date("3-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 43,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78696,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 55,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78698,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 57,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78724,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 83,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78691,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 50,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78718,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 77,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78716,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 75,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78721,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 80,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78683,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 44,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78699,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 58,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78712,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 71,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78717,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 76,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78694,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 53,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78711,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 70,
  },
  {
    ApplicationReference: "0x0042E04868448847950B1334A7F1A",
    Source: "Canberra Connect",
    MessageType: "REQ",
    CertificateRequested: "Conveyancing Certificate",
    CertificateNumber: 78692,
    DateRequestRegistered: new Date("4-Jul-17"),
    OrderStatus: "In progress",
    StreetName: "0x0042E04868448847950B1334A7F1A",
    Suburb: "0x0042E04868448847950B1334A7",
    Postcode: "04042E048",
    ApplicantName: "0x0042E04868448847950B1334A7F1A",
    ApplicantAddress: "0x0042E04868448847950B1334A7F1A",
    ID: 51,
  },
];
