import { Svc_Contact } from "@app/core/contacts/_id/model";
import { DD_CREATE_ACCOUNT } from "@app/products/direct-debit/accounts/components/dialogs/create-account/constant";
import {
  DirectDebitAccount,
  DirectDebitAccountLOVs,
  Svc_Contact_With_ID,
} from "@app/products/direct-debit/accounts/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IDirectDebitAccountFormStepProps extends DirectDebitAccount {
  _options?: {
    currentContact: Svc_Contact | undefined;
  };
}

const nameOf = nameOfFactory<IDirectDebitAccountFormStepProps>();
export const DDGeneralStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ formRenderProps, options }: IFormStepElement) => {
    const { pushNotificationPortal } = useNotificationPortalStore();

    const { valueGetter, onChange } = formRenderProps;

    const { dataForms } = useFlexibleFormStore();

    const ddAccountsLOVs = useMemo(() => {
      return dataForms?.DialogFormFirstLOVs as DirectDebitAccountLOVs;
    }, [dataForms?.DialogFormFirstLOVs]);

    const getValue = (nameOfField: keyof IDirectDebitAccountFormStepProps) =>
      valueGetter(nameOf(nameOfField));
    const onChangeField = (
      nameOfField: keyof IDirectDebitAccountFormStepProps,
      value: any
    ) => {
      onChange(nameOf(nameOfField as keyof IDirectDebitAccountFormStepProps), {
        value: value,
      });
    };
    const accountContact = getValue("_options")?.currentContact;
    const handleAuthorisationChange = (event: ComboBoxChangeEvent) => {
      onChangeField("Authorisation_Id", event.target.value?.Key);
      onChangeField("Authorisation_Number", event.target.value?.Value);
    };
    const getAddressFromContact = (contact?: Svc_Contact) => {
      return (
        contact?.PreferredAddress?.Formatted_SingleLine ??
        contact?.PostalAddress?.Formatted_SingleLine ??
        ""
      );
    };
    const handleChangeDDContact = (event: ComboBoxChangeEvent | null) => {
      const value = event?.value as Svc_Contact_With_ID | undefined;
      onChange(nameOf("_options"), {
        value: {
          ...getValue("_options"),
          currentContact: value ?? undefined,
        },
      });
      onChangeField(
        "Contact_ID",
        value?.Contact_Id ?? value?.Contact_ID ?? undefined
      );
      onChangeField(
        "Address",
        value ? getAddressFromContact(value as Svc_Contact) : ""
      );
      onChangeField("Name", value?.DisplayName ?? "");
      onChangeField("State", value?.PreferredAddress?.State ?? undefined);
      onChangeField(
        "Post_Code",
        value?.PreferredAddress?.Postcode ?? undefined
      );
    };

    const ddGroupsPickerHandler = (value: ComboBoxChangeEvent) => {
      onChangeField("Direct_Debit_Group_ID", value?.value?.Key);
      onChangeField("Direct_Debit_Group_Name", value?.value?.Name);
    };

    const handleSearchNameError = (error: any) => {
      pushNotificationPortal({
        title: "Pick contact errors",
        type: "error",
        description: error,
        autoClose: false,
        placeId: DD_CREATE_ACCOUNT,
      });
    };

    return (
      <section className="cc-field-group">
        {!options?.isFromAuthorisation && (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Authorisation number" isMandatory />
              <Field
                name={nameOf("Authorisation_Id")}
                validator={requiredValidator}
                nameDisplay="Authorisation_Number"
                textField={"Value"}
                dataItemKey={"Key"}
                onChange={handleAuthorisationChange}
                component={CCSearchComboBox}
                value={getDropdownValue(
                  getValue("Authorisation_Id"),
                  ddAccountsLOVs?.AuthorisationsList ?? [],
                  "Key"
                )}
                data={ddAccountsLOVs?.AuthorisationsList ?? []}
                disabled={options?.isDisableAuthorisation}
              />
            </div>
          </div>
        )}

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Name" isMandatory />
            <Field
              name={nameOf("Name")}
              nameDisplay="DisplayName"
              placeholder="Select name"
              value={{
                ...accountContact,
                Contact_ID:
                  accountContact?.Contact_Id ??
                  (accountContact as Svc_Contact_With_ID)?.Contact_ID,
              }}
              component={ContactPicker}
              onChange={handleChangeDDContact}
              displayValue={getValue("_options")?.currentContact?.DisplayName}
              validator={requiredValidator}
              onError={handleSearchNameError}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCValueField
              label={"Email"}
              value={getValue("_options")?.currentContact?.Email}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={"Phone number"}
              value={getValue("_options")?.currentContact?.WorkPhone}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCValueField
              label={"Mobile phone number"}
              value={getValue("_options")?.currentContact?.Mobile}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Group" isMandatory />
            <Field
              component={CCSearchComboBox}
              name={nameOf("Direct_Debit_Group_ID")}
              nameDisplay="DisplayName"
              textField={"Value"}
              dataItemKey={"Key"}
              value={getDropdownValue(
                getValue("Direct_Debit_Group_ID"),
                ddAccountsLOVs?.IncludeGroups ?? [],
                "Key"
              )}
              data={ddAccountsLOVs?.IncludeGroups ?? []}
              onChange={ddGroupsPickerHandler}
              validator={requiredValidator}
            />
          </div>
        </div>
      </section>
    );
  }
);
