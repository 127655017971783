import { renderOptionInputPickerKeyword } from "@app/core/components/common/config";
import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useDebounce } from "@common/hooks/useDebounce";
import { Keyword } from "@common/models/keyword";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export interface IPlusKeywordsDialogProps {
  onCloseDialog: () => void;
  onSubmit: (data: any) => void;
  productType: PRODUCT_TYPE_NUMBER;
  keywordType: KEYWORD_TYPE;
  optionsTitle?: string;
  selectableMode?: "single" | "multiple";
}

export const PlusKeywordsDialog = ({
  productType,
  onCloseDialog,
  onSubmit,
  keywordType,
  optionsTitle,
  selectableMode = "single",
}: IPlusKeywordsDialogProps) => {
  const options = renderOptionInputPickerKeyword(
    productType,
    keywordType,
    optionsTitle
  );

  const [searchKey, setSearchKey] = useState("");
  const [gridSelectedRows, setGridSelectedRows] = useState<Keyword[]>([]);
  const debouncedSearch = useDebounce(searchKey, 500);

  return (
    <CCDialog
      dialogWrapperClassName="cc-officer-picker-search"
      {...options.dialog}
      maxWidth="60%"
      maxHeight="80%"
      onClose={onCloseDialog}
      bodyElement={
        <div className="cc-search-result">
          <div className="cc-search-result-title">
            <CCLabel title="Search" />
            <Field
              name={"searchKey"}
              component={CCInput}
              placeholder="Search"
              onChange={(e: InputChangeEvent) => {
                setSearchKey(e.value);
              }}
            />
          </div>
          <div className="cc-search-result-body">
            <CCGrid
              {...options.grid}
              selectableMode={selectableMode}
              dataUrl={
                options.grid.dataUrl
                  ? debouncedSearch
                    ? getUrlSearchInputPicker(
                        debouncedSearch,
                        options.boxSearch.colSearch,
                        options.grid.dataUrl
                      )
                    : `${options.grid.dataUrl}?$count=true&`
                  : undefined
              }
              onSelectionChange={(dataItems) => {
                setGridSelectedRows(dataItems);
              }}
              selectedRows={gridSelectedRows}
            />
          </div>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            disabled={gridSelectedRows.length === 0}
            onClick={() => onSubmit(gridSelectedRows)}
          >
            Select
          </Button>
        </div>
      }
    />
  );
};
