import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderSharepointComponents } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { SHAREPOINTSTORAGEMETHOD } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsSPSubDivisionForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showFormat =
      parseInt(
        valueGetter(
          ECorporateSettingsField.SharePoint_Subdivisions_StorageMethod.toString()
        )
      ) !== SHAREPOINTSTORAGEMETHOD.PROPERTYNUMBER;
    const useSiteCollectionURL = valueGetter(
      ECorporateSettingsField.SharePoint_Subdivisions_UseSiteCollectionUrl.toString()
    );
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <CCSearchComboBoxGlobalSettings
            data={
              configData[
                ECorporateSettingsField.SharePoint_Subdivisions_StorageMethod
              ]
            }
            isEditing={isEditing}
          />

          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .SharePoint_Subdivisions_UseSiteCollectionUrl
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />

          {useSiteCollectionURL && (
            <InputGlobalSettings
              data={
                configData[
                  ECorporateSettingsField
                    .SharePoint_Subdivisions_SiteCollectionUrl
                ]
              }
              isEditing={isEditing}
            />
          )}
          {renderSharepointComponents(
            formRenderProps,
            isEditing,
            !useSiteCollectionURL,
            configData[
              ECorporateSettingsField.SharePoint_Subdivisions_DocumentLibrary
            ],
            configData[
              ECorporateSettingsField.SharePoint_Subdivisions_DocumentSet
            ]
          )}
          {showFormat && (
            <InputGlobalSettings
              data={
                configData[
                  ECorporateSettingsField
                    .SharePoint_Subdivisions_ParentContainerFormat
                ]
              }
              isEditing={isEditing}
            />
          )}
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.SharePoint_Subdivisions_TitleFormat
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .SharePoint_Subdivisions_DataSetCustomField
              ]
            }
            isEditing={isEditing}
          />
          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField.SharePoint_Subdivisions_CreateSubFolder
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
