import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { pprApplicationCategoryDisplay } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { CategoryPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const ViewName = (data: any) => {
  // TODO: Add interface later
  const valueGetter = data.formRenderProps.valueGetter;
  const onChange = data.formRenderProps.onChange;
  const disabled = !data.isEditing;

  const referralTriggerValueDisplayDetails: any[] =
    valueGetter(
      `option.ValueDisplayDetails_${
        data["TP_Application_OAs_AppCategoryCSV" + data.view]?.FieldName
      }`
    ) ?? [];

  const handleOnChangeAppCategory = (event: ICCInputPickerChangeEvent) => {
    const categories: any[] = event.value;
    onChange(
      data["TP_Application_OAs_AppCategoryCSV" + data.view]?.FieldName ?? "",
      {
        value: categories.map((item) => item.ID).join("^ "),
      }
    );
    onChange(
      `option.ValueDisplayDetails_${
        data["TP_Application_OAs_AppCategoryCSV" + data.view]?.FieldName
      }`,
      { value: categories }
    );
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        {data["TP_Application_OAs_ViewLabel" + data.view] && (
          <div className="cc-field">
            <label className="cc-label">
              {data["TP_Application_OAs_ViewLabel" + data.view]?.Title ?? ""}
            </label>
            <Field
              name={data["TP_Application_OAs_ViewLabel" + data.view]?.FieldName}
              component={CCInput}
              disabled={disabled}
            />
          </div>
        )}
        <div className="cc-field">
          <label className="cc-label">
            {data["TP_Application_OAs_AppCategoryCSV" + data.view]?.Title ?? ""}
          </label>
          <Field
            name={
              data["TP_Application_OAs_AppCategoryCSV" + data.view]?.FieldName
            }
            placeholder={
              data["TP_Application_OAs_AppCategoryCSV" + data.view]?.Title ?? ""
            }
            component={CategoryPicker}
            value={referralTriggerValueDisplayDetails}
            recordType={RECORDTYPE.TP_OtherApplication}
            onChange={handleOnChangeAppCategory}
            disabled={disabled}
            textProduce={(value: any) => {
              return pprApplicationCategoryDisplay(value);
            }}
          />
        </div>
      </div>

      <div className="cc-field-group">
        <label className="cc-label">{data.titleGroup}</label>
        <div className="cc-custom-sub-panel-bar cc-form-cols-3">
          {data["TP_NumberFormat_OAs_AppCategoryGRP" + data.view] && (
            <div className="cc-field">
              <label className="cc-label">
                {data["TP_NumberFormat_OAs_AppCategoryGRP" + data.view]
                  ?.Title ?? ""}
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={
                  data["TP_NumberFormat_OAs_AppCategoryGRP" + data.view]
                    ?.FieldName
                }
                component={CCSearchComboBox}
                data={
                  data["TP_NumberFormat_OAs_AppCategoryGRP" + data.view]
                    ?.LookupItems
                }
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                disabled={disabled}
                isUseDefaultOnchange
                validator={requiredValidator}
              />
            </div>
          )}
          {data["TP_NumberPrefix_OAs_AppCategoryGRP" + data.view] && (
            <div className="cc-field">
              <label className="cc-label">
                {data["TP_NumberPrefix_OAs_AppCategoryGRP" + data.view]
                  ?.Title ?? ""}
              </label>
              <Field
                name={
                  data["TP_NumberPrefix_OAs_AppCategoryGRP" + data.view]
                    ?.FieldName
                }
                component={CCInput}
                disabled={disabled}
              />
            </div>
          )}
          {data["TP_NumberSuffix_OAs_AppCategoryGRP" + data.view] && (
            <div className="cc-field">
              <label className="cc-label">
                {data["TP_NumberSuffix_OAs_AppCategoryGRP" + data.view]
                  ?.Title ?? ""}
              </label>
              <Field
                name={
                  data["TP_NumberSuffix_OAs_AppCategoryGRP" + data.view]
                    ?.FieldName
                }
                component={CCInput}
                disabled={disabled}
              />
            </div>
          )}
          {data["TP_NumberLength_OAs_AppCategoryGRP" + data.view] && (
            <div className="cc-field">
              <label className="cc-label">
                {data["TP_NumberLength_OAs_AppCategoryGRP" + data.view]
                  ?.Title ?? ""}
              </label>
              <Field
                name={
                  data["TP_NumberLength_OAs_AppCategoryGRP" + data.view]
                    ?.FieldName
                }
                component={CCInput}
                disabled={disabled}
              />
            </div>
          )}

          {data["TP_OAs_AppCategoryGRP" + data.view] && (
            <div className="cc-field">
              <label className="cc-label">
                {data["TP_OAs_AppCategoryGRP" + data.view]?.Title ?? ""}
              </label>
              <Field
                name={data["TP_OAs_AppCategoryGRP" + data.view]?.FieldName}
                component={CCNumericTextBox}
                disabled={disabled}
              />
            </div>
          )}

          {data[
            "TP_Set_DebtorNumber_As_OANumber_AppCategoryGRP" + data.view
          ] && (
            <div className="cc-field">
              <label className="cc-label">
                {data[
                  "TP_Set_DebtorNumber_As_OANumber_AppCategoryGRP" + data.view
                ]?.Title ?? ""}
              </label>
              <Field
                name={
                  data[
                    "TP_Set_DebtorNumber_As_OANumber_AppCategoryGRP" + data.view
                  ]?.FieldName
                }
                component={CCSwitch}
                disabled={disabled}
                checked={valueGetter(
                  data[
                    "TP_Set_DebtorNumber_As_OANumber_AppCategoryGRP" + data.view
                  ]?.FieldName
                )}
              />
            </div>
          )}

          {data["TP_DebtorNumber_Prefix_OA_AppCategoryGRP" + data.view] && (
            <div className="cc-field">
              <label className="cc-label">
                {data["TP_DebtorNumber_Prefix_OA_AppCategoryGRP" + data.view]
                  ?.Title ?? ""}
              </label>
              <Field
                name={
                  data["TP_DebtorNumber_Prefix_OA_AppCategoryGRP" + data.view]
                    ?.FieldName
                }
                component={CCInput}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
