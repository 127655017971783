import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataAssessment,
  postProcessCreateAssessment,
} from "@app/products/property/assessments/components/form-steps/new-assessment/api";
import { AssessmentDetailsFormStep } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/assessment-details/_index";
import { AssociationParcelsFormStep } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/_index";
import { DTO_CreateAssessment_Parcel } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/associations-parcel/model";
import { PhysicalAddressGridFormStep } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/physical-address-grid/_index";
import { SelectTitlesFormStep } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/select-titles/_index";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  Collection,
  DTO_Address,
  DTO_Assessment_LOVs,
  DTO_WorkflowDetail_CreateAssessment,
  DTO_Workflow_CreateAssessment,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { useNewAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/new-assessment/store";
import { getOfficerRegionId } from "@app/products/property/assessments/components/form-steps/new-assessment/util";
import { WorkflowGroupButtonsSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial";
import { IButtonSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import {
  getNotificationSpatialWorkflow,
  isApprovalSpatialGIS,
  isDisabledApproveButton,
  isHiddenRejectButton,
  isSingleSpatialGIS,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import {
  IListDialog,
  IProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket, ResponsePacket } from "@common/models/identityPacket";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue, getUUID } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { EBadgeDialogType, IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface INewAssessmentDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isSaveOnNextStep?: boolean;
  suffixTitle?: string;
  isRedirectManagePage?: boolean;
}

export const NewAssessmentDialog = observer(
  (props: INewAssessmentDialogProps) => {
    //Props
    const {
      onClose,
      dataFromActionList,
      prefixTitle,
      isSaveOnNextStep = false,
      suffixTitle,
      isRedirectManagePage = false,
    } = props;
    //Store
    const { currentFormTitle } = useCommonProductStore();
    const { isLLS, isActro, isGeneral } = CommunityProperty.getFlagOfStates();
    const { setCreateAssessmentLOVs, createAssessmentLOVs: assessmentLOVs } =
      useNewAssessmentDialogStore();
    const { buttonMapping, buttonFetch, setButtonMapping, setButtonFetch } =
      useWorkflowGroupButtonsSpatialStore();
    const { setAssessmentCollectionStepLOVs } =
      useAssessmentCollectionStepStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();

    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);

    //State
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [workflowInitDataFromNew, setWorkflowInitDataFromNew] =
      useState<DTO_Workflow_CreateAssessment>();
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Get labels
    const [assessmentLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessment,
    ]);

    const isFirstSave = useMemo(() => {
      const draftId =
        workflowInitDataFromNew?.WorkflowHeader?.WorkflowDraft
          ?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [workflowInitDataFromNew]);

    const initialValue = useMemo(() => {
      const officerInitData = officerUtilProcessing.processDataInit(
        workflowInitDataFromNew
      );
      let initSelectTitle: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.Details,
        ...officerInitData,
      };
      let clonePhysicalAddresses: DTO_Address[] = [];
      if (workflowInitDataFromNew?.WorkflowDetail?.PhysicalAddresses?.length) {
        clonePhysicalAddresses =
          workflowInitDataFromNew?.WorkflowDetail?.PhysicalAddresses.map(
            (item: DTO_Address) => ({
              ...item,
              RowId: getUUID(),
            })
          );
      }
      let initPhysicalAddress: any = [
        // ...defaultPhysicalAddress,
        ...(clonePhysicalAddresses ?? []),
      ];
      const creationDate =
        workflowInitDataFromNew?.WorkflowDetail?.AssessmentDetail
          ?.CreationDate ?? new Date();

      // Set more default value for Assessment details

      const officerRegionId = getOfficerRegionId(workflowInitDataFromNew);

      const regionName =
        getDropdownValue(
          officerRegionId + "",
          assessmentLOVs?.Assessment_Group ?? [],
          "Code"
        )?.Name || "";
      const statusName =
        getDropdownValue(
          (workflowInitDataFromNew?.WorkflowDetail?.AssessmentDetail?.Status ??
            "") + "",
          assessmentLOVs?.Assessment_Status ?? [],
          "Code"
        )?.Name || "";

      let initDetail: any = {
        IsCommonProperty: false,
        ...workflowInitDataFromNew?.WorkflowDetail?.AssessmentDetail,
        CreationDate: creationDate,
        Status_Name: statusName,
        Region_Name: regionName,
        Sector: officerRegionId,
      };

      let initSecondaryWorkflow: any = {};

      let initAssociation: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.Association,
      };
      const associateParcels =
        initAssociation?.AssociateParcels?.map(
          (item: DTO_CreateAssessment_Parcel) => {
            return {
              ...item,
              isNewParcel: true,
            };
          }
        ) ?? [];
      initAssociation.AssociateParcels = associateParcels;

      //TODO: remove if api don't return 0
      if (workflowInitDataFromNew?.WorkflowDetail?.Details?.LeaseType === 0) {
        initSelectTitle.LeaseType = null;
      }
      if (workflowInitDataFromNew) {
        //---step Secondary Workflow---
        initSecondaryWorkflow = secondaryWorkflowUtilProcessing.processDataInit(
          workflowInitDataFromNew
        );
      }

      return {
        [EKeysOfSteps.PhysicalAddress]: initPhysicalAddress,
        [EKeysOfSteps.AssessmentDetail]: initDetail,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
        [EKeysOfSteps.Details]: initSelectTitle,
        [EKeysOfSteps.SecondaryWorkflow]: initSecondaryWorkflow,
        [EKeysOfSteps.Collection]: collectionUtilProcessing.processDataInit<
          Collection[]
        >(workflowInitDataFromNew),
        [EKeysOfSteps.Association]: initAssociation,
      };
    }, [workflowInitDataFromNew, assessmentLOVs]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue.Details,
        component: SelectTitlesFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        isValid: false,
        options: {
          isToBeApprovalMode: isToBeApprovalMode,
          isReadOnly,
          isActro,
          workflowDraftId,
        },
      },
      {
        label: `${assessmentLabel} Details`,
        id: `cc-step-label-${ECustomColNameProperty.Assessment}`,
        initialValues: initialValue.AssessmentDetail,
        component: AssessmentDetailsFormStep,
        visible: isActro,
        key: EKeysOfSteps.AssessmentDetail,
        options: {
          isReadOnly,
          isActro,
          isLLS,
          isGeneral,
        },
      },
      {
        label: "Related",
        initialValues: initialValue.Association,
        component: AssociationParcelsFormStep,
        visible: true,
        key: EKeysOfSteps.Association,
        options: {
          isReadOnly,
          isActro,
          isLLS,
          setIsLoadingDialog,
        },
      },
      {
        label: "Physical Address",
        initialValues: initialValue.PhysicalAddresses,
        component: PhysicalAddressGridFormStep,
        visible: true,
        key: EKeysOfSteps.PhysicalAddress,
        options: {
          isReadOnly,
          isLLS,
          isActro,
          setIsLoadingDialog,
          assessmentLOVs,
          isFromNewAssessment: true,
        },
      },
      {
        label: `${assessmentLabel} Details`,
        id: `cc-step-label-${ECustomColNameProperty.Assessment}`,
        initialValues: initialValue.AssessmentDetail,
        component: AssessmentDetailsFormStep,
        visible: !isActro,
        key: EKeysOfSteps.AssessmentDetail,
        options: {
          isReadOnly,
          isActro,
          isLLS,
          isGeneral,
        },
      },
      {
        label: "Collections",
        initialValues: initialValue[EKeysOfSteps.Collection],
        component: CollectionFormStep,
        visible: true,
        key: EKeysOfSteps.Collection,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Create_Assessment,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];
    const handleSubmit = async (data: any, buttonId?: string) => {
      //reset notification before go next calling
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.resetNotifications();
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), false, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          handleParkProcess(processData(data));
          break;
        case EListSubmitButton.MappingSpatialWorkflow:
          handleMappingSpatialProcess(processData(data));
          break;
        case EListSubmitButton.FetchSpatialWorkflow:
          handleFetchSpatialProcess(processData(data));
          break;
        case EListSubmitButton.ViewMappingSpatialWorkflow:
          handleViewMappingSpatialProcess(processData(data));
          break;
      }
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The ${assessmentLabel.toLowerCase()} will be created based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessCreateAssessment,
        dataSendBack: payload,
        defaultSuccessMessage: `${assessmentLabel} approval sent back successfully`,
        defaultErrorMessage: `Send ${assessmentLabel.toLowerCase()} back failed`,
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessCreateAssessment,
        dataReallocate: payload,
        defaultSuccessMessage: `${assessmentLabel} approved successfully`,
        defaultErrorMessage: `Approve ${assessmentLabel.toLowerCase()} failed`,
      });
    };

    const processData = (data: any) => {
      let workflowDetail: any = {};
      let workflowHeader: any = { ...workflowInitDataFromNew?.WorkflowHeader };

      //Officer process data
      //field update: WD_Assessment_Group_Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(data, EKeysOfSteps.Details);
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;

      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep;
        if (
          key === EKeysOfSteps.PhysicalAddress ||
          key === EKeysOfSteps.Collection
        ) {
          dataStep = [...(value ?? [])];
        } else {
          dataStep = { ...value };
        }
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (dataStep?.BuildingUnitNumber === "") {
          dataStep.BuildingUnitNumber = null;
        }
        if (key === EKeysOfSteps.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key as keyof DTO_WorkflowDetail_CreateAssessment] =
            dataStep;
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleNextButton = async (data: any) => {
      const newData = processData(data);
      return await handleSaveAndNext(newData);
    };

    const titleHeader = useMemo(() => {
      const formId =
        workflowInitDataFromNew?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) || `Create ${assessmentLabel}`;

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowInitDataFromNew?.WorkflowHeader?.WorkflowApprovals ?? []
        )
      );
    }, [
      workflowInitDataFromNew?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id,
      workflowInitDataFromNew?.WorkflowHeader?.WorkflowApprovals,
      currentFormTitle,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
      assessmentLabel,
    ]);

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * Handle cancel process
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessCreateAssessment,
          dataCancel: data,
          defaultSuccessMessage: `The new ${assessmentLabel.toLowerCase()} application was cancelled successfully`,
          defaultErrorMessage: `Create ${assessmentLabel.toLowerCase()} cancel failed`,
        });
      } else {
        onClose();
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessCreateAssessment,
          dataReject: data,
          defaultSuccessMessage: `Create ${assessmentLabel.toLowerCase()} application was rejected successfully`,
          defaultErrorMessage: `Create ${assessmentLabel.toLowerCase()} reject failed`,
        });
      } else {
        onClose();
      }
    };

    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      return await getInitialDataAssessment(
        WorkflowTypes.Create_Assessment,
        id
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsAssessment, workflowData] = response;
          if (
            isSuccessResponse(lovsAssessment) &&
            isSuccessResponse(workflowData) &&
            lovsAssessment?.data &&
            workflowData?.data
          ) {
            //set Lovs Data Assessment
            setCreateAssessmentLOVs(
              lovsAssessment?.data?.dtoCreate_Assessment_LOVs
            );
            setAssessmentCollectionStepLOVs({
              Collection_Type:
                lovsAssessment?.data?.dtoCreate_Assessment_LOVs
                  ?.Collection_Type,
            });
            if (workflowData?.data) {
              setWorkflowInitDataFromNew(workflowData?.data);
              if (workflowData?.data?.WorkflowHeader) {
                //Get notification for Spatial
                const notificationSpatial = getNotificationSpatialWorkflow(
                  workflowData.data?.WorkflowHeader
                );
                if (!isNil(notificationSpatial)) {
                  if (notificationSpatial?.mapping) {
                    setButtonMapping((preState: IButtonSpatial) => ({
                      ...preState,
                      ...notificationSpatial?.mapping,
                    }));
                  }
                  if (notificationSpatial?.fetch) {
                    setButtonFetch((preState: IButtonSpatial) => ({
                      ...preState,
                      ...notificationSpatial?.fetch,
                    }));
                  }
                }
                setWorkflowDraftId(
                  workflowData.data?.WorkflowHeader?.WorkflowDraft
                    ?.Workflow_Draft_Id ||
                    workflowDraftId ||
                    0
                );
              }
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_Assessment_LOVs
              | DTO_Workflow_CreateAssessment
              | ResponsePacket
            > = lovsAssessment;
            if (!isSuccessResponse(workflowData)) {
              responseError = workflowData;
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_CreateAssessment>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }
      const response = await postProcessCreateAssessment(modeProcess, payload);

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
          //Show secondary workflow
          if (
            modeProcess === WorkflowProcessMode.Finish &&
            secondaryWorkflowSelectedList?.length
          ) {
            setListDialog(secondaryWorkflowSelectedList);
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (payload: DTO_Workflow_CreateAssessment) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_CreateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => setIsLoadingPark(false);

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = (payload: DTO_Workflow_CreateAssessment) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_CreateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          pushNotification({
            title:
              e?.Notification ??
              `New ${assessmentLabel.toLowerCase()} approved successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Approve new ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_CreateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              `New ${assessmentLabel.toLowerCase()} created successfully`,
            type: "success",
          };
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        defaultFailedMessage: `Create new ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    /**
     * handle save parcel
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_CreateAssessment,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false
    ): Promise<boolean> => {
      setIsLoadingOnNext(true);
      //Calling process Save at next button
      const response = await postProcessCreateAssessment(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage = `${assessmentLabel} saved successfully`;
      const defaultFailedMessage = `The create new ${assessmentLabel.toLowerCase()} process could not be saved`;

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID || 0);
          // get new data from draftId after first save
          if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
            setWorkflowInitDataFromNew({
              WorkflowDetail: response?.data?.ReturnData?.WorkflowDetail,
              WorkflowHeader: response?.data?.ReturnData?.WorkflowHeader,
            });
          }
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          getWorkflowData().then(() => {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: response?.data?.Notification ?? defaultSuccessMessage,
                type: "success",
              });
          });
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_CreateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessages ??
              `New ${assessmentLabel.toLowerCase()} was parked successfully.`,
            type: "success",
          });
        },
        defaultFailedMessage: `New ${assessmentLabel.toLowerCase()} could not be parked.`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle mapping button at Spatial
     * Call 2 apis (Process, Get)
     * @param payload
     */
    const handleMappingSpatialProcess = async (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      //set loading dialog
      setIsLoadingDialog(true);
      //set info mapping
      setButtonMapping((preState: IButtonSpatial) => ({
        ...preState,
        isLoading: true,
      }));
      let mappingInfo: any = {};
      //Calling api process
      const responsePost = await postProcessCreateAssessment(
        WorkflowProcessMode.Mapping,
        payload
      );
      if (isSuccessResponse(responsePost)) {
        const resSuccessPost =
          responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_CreateAssessment>;
        if (resSuccessPost?.IsSuccess) {
          //open new tab spatial
          window.open(responsePost?.data?.Gis_Mapping_Launch_Url, "_blank");
          mappingInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            mappingInfo.loadSuccess = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.SUCCESS,
            };
          }
          setWorkflowInitDataFromNew({
            WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
            WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
          });
        } else {
          mappingInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            mappingInfo.loadFail = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.ERROR,
            };
          }
          if (resSuccessPost?.ErrorMessage?.length) {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: resSuccessPost?.Notification?.length
                  ? resSuccessPost?.Notification
                  : "Mapping data failed.",
                description: resSuccessPost?.ErrorMessage,
                type: "error",
                autoClose: false,
              });
          }
        }
      } else {
        //TODO: fix type for temporary
        const responseFail = responsePost?.data as any as IdentityPacket;
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (responseFail?.Errors?.length) {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: "Mapping data failed.",
              description: head(responseFail?.Errors),
              type: "error",
              autoClose: false,
            });
        }
      }
      setButtonMapping((preState: IButtonSpatial) => ({
        ...preState,
        ...mappingInfo,
      }));
      setIsLoadingDialog(false);
    };

    const handleFetchSpatialProcess = async (
      payload: DTO_Workflow_CreateAssessment
    ) => {
      //set loading dialog
      setIsLoadingDialog(true);
      //set info fetch
      setButtonFetch((preState: IButtonSpatial) => ({
        ...preState,
        isLoading: true,
        loadFail: undefined,
        loadSuccess: undefined,
      }));
      let fetchInfo: any = {};
      const responsePost = await postProcessCreateAssessment(
        WorkflowProcessMode.Fetch,
        payload
      );
      if (isSuccessResponse(responsePost)) {
        const resSuccessPost =
          responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_CreateAssessment>;
        if (resSuccessPost?.IsSuccess) {
          fetchInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            fetchInfo.loadSuccess = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.SUCCESS,
            };
          }
          setWorkflowInitDataFromNew({
            WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
            WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
          });
        } else {
          fetchInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            fetchInfo.loadFail = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.ERROR,
            };
          }
          if (resSuccessPost?.ErrorMessage?.length) {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: resSuccessPost?.Notification?.length
                  ? resSuccessPost?.Notification
                  : "Fetch data failed.",
                description: resSuccessPost?.ErrorMessage,
                type: "error",
                autoClose: false,
              });
          }
        }
      } else {
        //TODO: fix type for temporary
        const resFailPost = responsePost?.data as any as IdentityPacket;
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resFailPost?.Errors?.length) {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: "Fetch data failed.",
              description: head(resFailPost?.Errors),
              type: "error",
              autoClose: false,
            });
        }
      }
      setButtonFetch((preState: IButtonSpatial) => ({
        ...preState,
        ...fetchInfo,
      }));
      setIsLoadingDialog(false);
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    const newBadge = useMemo(() => {
      let newStatus: undefined | IBadgeDialog[];
      if (statusBadge?.length) {
        newStatus = [...statusBadge];
      } else {
        newStatus = [];
      }
      // TODO will change when apply for all workflow, now just apply for create holding workflow
      if (
        isSingleSpatialGIS(workflowInitDataFromNew?.WorkflowHeader) ||
        isApprovalSpatialGIS(workflowInitDataFromNew?.WorkflowHeader)
      ) {
        if (
          buttonMapping?.loadSuccess &&
          buttonMapping?.loadSuccess?.message?.messageTitle
        ) {
          newStatus.push({
            content:
              buttonMapping?.loadSuccess?.message?.messageTitle ??
              "Mapping in progress",
          });
        } else if (
          buttonMapping?.loadFail &&
          buttonMapping?.loadFail?.message?.messageTitle
        ) {
          newStatus.push({
            content:
              buttonMapping?.loadFail?.message?.messageTitle ??
              "Mapping in failed",
            type: EBadgeDialogType.ERROR,
          });
        }
        if (
          buttonFetch?.loadSuccess &&
          buttonFetch?.loadSuccess?.message?.messageTitle
        )
          newStatus.push({
            content:
              buttonFetch?.loadSuccess?.message?.messageTitle ??
              "Fetch data successful",
            type: EBadgeDialogType.SUCCESS,
          });
        else if (
          buttonFetch?.loadFail &&
          buttonFetch?.loadFail?.message?.messageTitle
        )
          newStatus.push({
            content:
              buttonFetch?.loadFail?.message?.messageTitle ??
              "Fetch data failed",
            type: EBadgeDialogType.ERROR,
          });
      }
      return newStatus;
    }, [
      statusBadge,
      workflowInitDataFromNew?.WorkflowHeader,
      buttonMapping,
      buttonFetch,
    ]);

    const handleViewMappingSpatialProcess = (
      data: DTO_Workflow_CreateAssessment
    ) => {
      window.open(data?.WorkflowHeader?.WD_Gis_View_Launch_Url, "_blank");
    };

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              disabled={isLoadingApprove || isLoadingDialog || isLoadingOnNext}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              badge={newBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingOnNext || isLoadingPark || isLoadingApprove
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      workflowInitDataFromNew?.WorkflowHeader
                        ?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled ||
                              isDisabledApproveButton(
                                workflowInitDataFromNew?.WorkflowHeader
                              )
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          {isHiddenRejectButton(
                            workflowInitDataFromNew?.WorkflowHeader
                          ) ? null : (
                            <Button
                              themeColor="primary"
                              id={EListSubmitButton.Reject}
                              disabled={renderProps.nextButton.disabled}
                              className={"cc-dialog-button"}
                              onClick={renderProps.submitButton.onClick}
                            >
                              Reject
                            </Button>
                          )}
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  <div className="cc-dialog-footer-actions-left">
                    <WorkflowGroupButtonsSpatial
                      workflowHeader={workflowInitDataFromNew?.WorkflowHeader}
                      actionSubmit={renderProps.nextButton.onClick}
                      isApplySingleGIS
                    />
                  </div>
                </>
              }
            />
          );
        }}
      />
    );
  }
);
