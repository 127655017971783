export const mockPropertiesToReInspect = [
  {
    Locality: "Bayles",
    HouseNumber: 75,
    Street: "Backhouses",
    AssessmentNumber: 4051300200,
    Owners: "Murray, A D & S M",
    ToBeClearedBy: new Date("15-Jan-2022"),
    LastInspected: new Date("7-Jan-2022 15:31"),
    Clearance: "10 m break - all boundaries",
    OutcomeOfLastInspection: "Hazards identified",
    Period: "2021/2022",
    ParcelId: 29451,
    CaseId: 165903,
  },
  {
    Locality: "Bayles",
    HouseNumber: 115,
    Street: "Backhouses",
    AssessmentNumber: 4051300400,
    Owners: "Healey, B C & McBride, LJ",
    ToBeClearedBy: new Date("15-Jan-2022"),
    LastInspected: new Date("7-Jan-2022 15:30"),
    Clearance: "10 m break - all boundaries",
    OutcomeOfLastInspection: "Hazards identified",
    Period: "2021/2022",
    ParcelId: 567,
    CaseId: 165902,
  },
  {
    Locality: "Bunyip North",
    HouseNumber: 30,
    Street: "Butler",
    AssessmentNumber: 4146700100,
    Owners: "Damon, K R & Estate of Allan Gordon Damon",
    ToBeClearedBy: new Date("15-Jan-2022"),
    LastInspected: new Date("16-Dec-2021 16:51"),
    Clearance: "10 m break - all boundaries",
    OutcomeOfLastInspection: "Hazards identified",
    Period: "2021/2022",
    ParcelId: 4147,
    CaseId: 165899,
  },
  {
    Locality: "Catani",
    HouseNumber: 1085,
    Street: "Caldermeade",
    AssessmentNumber: 4149401500,
    Owners: "Camilleri, J",
    ToBeClearedBy: new Date("15-Jan-2022"),
    LastInspected: new Date("16-Dec-2021 16:45"),
    Clearance: "10 m break - all boundaries",
    OutcomeOfLastInspection: "Hazards identified",
    Period: "2021/2022",
    ParcelId: 28073,
    CaseId: 165898,
  },
  {
    Locality: "Battery Point",
    HouseNumber: 23,
    Street: "Albuera",
    AssessmentNumber: 2214889,
    Owners: "I S Middleton & J M Middleton",
    ToBeClearedBy: new Date("16-Jan-2022"),
    LastInspected: new Date("7-Jan-2022 17:04"),
    Clearance: "10m Fire Break",
    OutcomeOfLastInspection: "Owners to clear property",
    Period: "2020/2021",
    ParcelId: 231040,
    CaseId: 21004,
  },
];
