import { hmRoute } from "@app/products/hm/route";
import { spatialHMRouteCase } from "@app/products/hm/util";
import { IFlatCCMenuItem } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import { observer } from "mobx-react-lite";
import React from "react";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

export const HMRouter = observer((props: any) => {
  const { isProductActive } = useCommonProductStore();

  return (
    <ProductRouterSwitch>
      {hmRoute.children &&
        convertRouteDynamic(hmRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            const fullPath = `${props.match.url}/${route.path}`;
            const flatMenuData: IFlatCCMenuItem | undefined =
              isProductActive(fullPath);

            if (
              !flatMenuData &&
              route.path.search(":") === -1 &&
              !spatialHMRouteCase.isBathUpdate(route.path) // Special page for HM premises batch update
            ) {
              return null;
            }

            if (flatMenuData?.isUnnavigated && flatMenuData?.navigationPath) {
              const navigationComponent = () => {
                window.open(flatMenuData?.navigationPath, "_self");
                return null;
              };
              return (
                <PrivateRoute
                  exact
                  key={index}
                  path={fullPath}
                  component={navigationComponent}
                />
              );
            }

            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
});
