import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { PSARCommunicationButton } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/communication/_index";
import { PSARDeleteButton } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/delete/_index";
import { PSARMailMergeButton } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/mail-merge/_index";
import { PSARNewButton } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/new/_index";
import { PSARUpdateStatDaysButton } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/update-stat-days/_index";
import { TOWN_PLANNING_PPR_PSA_REFERRAL_CURRENT_BY_TL_LIST_VIEW_URL } from "@app/products/town-planning/ppr/psa-referrals/constant";
import { colPprPSAReferrals } from "@app/products/town-planning/ppr/psa-referrals/register/config";
import { PPRPSAReferral } from "@app/products/town-planning/ppr/psa-referrals/register/model";
import { PSAContactsTab } from "@app/products/town-planning/ppr/psa-referrals/_id/components/reference-sidebar/contact/_index";
import { PSARDetailTab } from "@app/products/town-planning/ppr/psa-referrals/_id/components/reference-sidebar/detail/_index";
import { PSARHistoryTab } from "@app/products/town-planning/ppr/psa-referrals/_id/components/reference-sidebar/history/_index";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkDetail, getBookmarkDisplayName } from "../util";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";
const nameOf = nameOfFactory<PPRPSAReferral>();
export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [
      <PSARNewButton />,
      <PSARMailMergeButton />,
      <PSARCommunicationButton />,
      <PSARUpdateStatDaysButton />,
      <PSARDeleteButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={TOWN_PLANNING_PSAR_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PSARDetailTab /> },
      { title: "Contacts", component: <PSAContactsTab /> },
      {
        title: "History",
        component: <PSARHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPprPSAReferrals}
      dataUrl={TOWN_PLANNING_PPR_PSA_REFERRAL_CURRENT_BY_TL_LIST_VIEW_URL}
      state={{
        sort: [
          { field: nameOf("TeamLeader"), dir: "desc" },
          { field: nameOf("VROffice"), dir: "asc" },
          { field: nameOf("ResponsibleAuthority"), dir: "asc" },
          { field: nameOf("Locality"), dir: "asc" },
          { field: nameOf("VRStreetName"), dir: "asc" },
          { field: nameOf("ID"), dir: "desc" },
        ],
        group: [
          { field: nameOf("VROffice"), dir: "asc" },
          { field: nameOf("TeamLeader"), dir: "asc" },
          { field: nameOf("Status"), dir: "asc" },
        ],
      }}
      primaryField={nameOf("ID")}
    />
  );
};
