import { VO_Assessment_Debt_Recovery } from "@app/products/property/assessments/debt-recovery/list/all/model";
import { ASSESSMENTS_DEBT_RECOVERY_ROUTE } from "@app/products/property/assessments/debt-recovery/[id]/constant";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment_Debt_Recovery>();

export const colListAll: IColumnFields[] = [
  {
    field: nameOf("Name_Address"),
    title: "Name and Address",
    width: 450,
    linkTo: (dataItem: VO_Assessment_Debt_Recovery) =>
      `${ASSESSMENTS_DEBT_RECOVERY_ROUTE}/${dataItem.Debt_Recovery_Id}`,
  },
  {
    field: nameOf("Debt_Recovery_Id"),
    title: "Debt Recovery ID",
    format: NUMBER_FORMAT.NUMBER2,
  },

  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment(s)",
  },
  {
    field: nameOf("Debt_Recovery_Type_Name"),
    title: "Type",
  },
  {
    field: nameOf("DRS_Name"),
    title: "Status",
  },
  {
    field: nameOf("DR_Date_From"),
    title: "Date From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DR_Date_To"),
    title: "Date To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DR_Initial_Balance"),
    title: "Initial Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DR_Initial_Interest_Balance"),
    title: "Initial Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InitialDebt",
    title: "Initial Debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("DR_Initial_Debt_w_Costs"),
    title: "Initial Debt Including Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DR_Total"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Assess_Balance_Outstanding"),
    title: "Assessment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    style: formatStyleUnRelevant,
  },
  {
    field: "UpToDueDateTime",
    title: "Up to Due Date Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "AsAt",
    title: "As At",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "AlertMessage",
    title: "Alert Message",
  },
  {
    field: "ShowAlertOnAssessment",
    title: "Show Alert on Assessment",
  },
  {
    field: nameOf("Officer_Surname_w_Given_Names"),
    title: "Officer",
  },
  {
    field: nameOf("DR_Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("DR_Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("DR_Modified_By"),
    title: "Changed By",
  },
  {
    field: nameOf("DR_Modified_On"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
