import {
  DTO_Secondary_Workflow,
  DTO_Workflow_Approval_Step,
} from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { workflowIsMandatoryFormat } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { IconEditCell } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/components/icon-edit-cell/_index";

const nameOfWorkflow = nameOfFactory<DTO_Secondary_Workflow>();
const nameOfApproval = nameOfFactory<DTO_Workflow_Approval_Step>();
export const colWorkflow: IColumnFields[] = [
  {
    field: nameOfWorkflow("Workflow_IsMandatory"),
    title: "Included?",
    format: workflowIsMandatoryFormat,
    editable: true,
    editCell: IconEditCell,
    disableEditField: "Is_Included",
  },
  {
    field: nameOfWorkflow("WorkflowType_Name"),
    title: "Workflow Name",
  },
];

export const colApproval: IColumnFields[] = [
  {
    field: nameOfApproval("ApprovalStatusName"),
    title: "Approval Status",
  },
  {
    field: nameOfApproval("WorkflowStepName"),
    title: "Approval Step",
  },
  {
    field: nameOfApproval("WorkflowDetails"),
    title: "Approval Details",
  },
  {
    field: nameOfApproval("FunctionalGroup"),
    title: "Officer/Group",
  },
  {
    field: nameOfApproval("WDA_Create_DateTime"),
    title: "Created Date",
    format: DATE_FORMAT.DATE,
  },
  // TODO: TBC
  // {
  //   field: nameOfApproval("AllocatedDate"),
  //   title: "Allocated Date",
  //   format: DATE_FORMAT.DATE,
  // },
  // {
  //   field: nameOfApproval("CompletedDate"),
  //   title: "Completed Date",
  //   format: DATE_FORMAT.DATE,
  // },
  // {
  //   field: nameOfApproval("WorkflowStatus"),
  //   title: "Workflow Status",
  // },
];
export const colApprovalNewStatus: IColumnFields[] = [
  {
    field: nameOfApproval("WorkflowStepName"),
    title: "Approval Step",
  },
  {
    field: nameOfApproval("WorkflowDetails"),
    title: "Approval Details",
  },
  {
    field: nameOfApproval("FunctionalGroup"),
    title: "Officer/Group",
  },
];
