import { ActionsAccordion } from "@app/core/actions/action-accordion/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import { Recall } from "@app/core/recall/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { useTabTableStore } from "@app/core/tab-table/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export interface IAccordionsRecallProps {
  isRecordDeleted?: boolean;
}

export const AccordionsRecall = observer(
  ({ isRecordDeleted = false }: IAccordionsRecallProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { setIsDisabled } = useTabTableStore();
    const recallObj = dataForms?.GeneralForm as Recall;
    const idUrl: string = useParams<{ id: string }>().id;
    const id: number = recallObj?.Recall_ID || parseInt(idUrl ?? "0");

    const listPanelBar: ITabProps[] = [
      {
        title: "Actions",
        tabType: TabTableType.Actions,
        component: (
          <ActionsAccordion id={id} recordType={RECORDTYPE.CORE_Recall} />
        ),
      },
      {
        title: "Comments",
        tabType: TabTableType.Comments,
        component: <Comments id={id} recordType={RECORDTYPE.CORE_Recall} />,
      },
      {
        title: "Documents",
        tabType: TabTableType.Documents,
        component: (
          <Documents
            id={id}
            recordType={RECORDTYPE.CORE_Recall}
            isUseSharepoint
            isAddToRecords
          />
        ),
      },
    ];

    useEffect(() => {
      setIsDisabled(isRecordDeleted);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecordDeleted]);

    return (
      <TabTable
        id={id}
        recordType={RECORDTYPE.CORE_Recall}
        initialPanels={listPanelBar}
      />
    );
  }
);
