import { CreateRegisterDialog } from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/_index";
import { loadSearchAccount } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/components/form-elements/transferring/api";
import { colTransfer } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/components/form-elements/transferring/config";
import { validatorTransferring } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/components/form-elements/transferring/util";
import {
  IRegisterMeterTransferred,
  SearchType,
  textFieldMapping,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/transfer/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import "./_index.scss";

export const REGISTER_TRANSFERRING_FORM_STEP = "Transferring";

export const TransferringStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorTransferring}
    />
  );
};
const nameOfGridData = nameOfFactory<IRegisterMeterTransferred>();

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [showCreateRegister, setShowCreateRegister] = useState<boolean>(false);

  const handleSearchAccount = (event: ComboBoxFilterChangeEvent) => {
    onChange(nameOf("SearchAccount"), { value: null });
    if (event.filter.value.length >= 1) {
      onChange(nameOf("_option.SearchAccount.Loading"), { value: true });
      loadSearchAccount(event.filter).then((data) => {
        onChange(nameOf("_option.SearchAccount.Data"), { value: data });
        onChange(nameOf("_option.SearchAccount.Loading"), { value: false });
      });
    } else {
      onChange(nameOf("_option.SearchAccount.Data"), { value: [] });
    }
  };
  const handleSearchAccountResult = (event: ComboBoxChangeEvent) => {
    if (!event.value) return;
    onChange(nameOf("SearchAccount"), {
      value: event.value,
    });
    onChange(nameOf("SR_AccountName"), {
      value: event.value?.Name,
    });
    onChange(nameOf("SR_PropertyAddress"), {
      value: event.value?.PropertyAddress,
    });
    onChange(nameOf("SR_Description"), {
      value: event.value?.Description,
    });
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <div className=" cc-form-cols-1">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Account number</label>
                <Field
                  name={nameOf("AccountNo")}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Description</label>
                <Field
                  name={nameOf("Description")}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Account name</label>
                <Field
                  name={nameOf("AccountName")}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Meters to be transferred</label>
              <CCGrid
                data={getFieldValue("MeterTransferred")}
                columnFields={colTransfer}
                primaryField={nameOfGridData("MeterId")}
              />
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  Account to be response for meters
                </label>
              </div>
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Account number
                  <CCTooltip
                    type="validator"
                    position="right"
                    content="You must have at least one record to go to the next step"
                  />
                </label>
                <Field
                  name={nameOf("SearchAccount")}
                  filterable
                  suggest
                  textField={textFieldMapping[SearchType.AccountNo]}
                  data={getFieldValue("_option.SearchAccount.Data") ?? []}
                  loading={
                    getFieldValue("_option.SearchAccount.Loading") ?? false
                  }
                  placeholder={"Account number"}
                  component={CCComboBox}
                  onFilterChange={handleSearchAccount}
                  onChange={handleSearchAccountResult}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label"></label>
                <Button
                  themeColor="primary"
                  className={"cc-new-account-button"}
                  onClick={() => {
                    setShowCreateRegister(true);
                  }}
                >
                  New Account
                </Button>
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Search result</label>
                <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-2">
                    <div className="cc-field">
                      <label className="cc-label">Account name</label>
                      <Field
                        name={nameOf("SR_AccountName")}
                        component={CCInput}
                        placeholder="Account name"
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Property address</label>
                      <Field
                        name={nameOf("SR_PropertyAddress")}
                        component={CCInput}
                        placeholder="Property address"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-2">
                    <div className="cc-field">
                      <label className="cc-label">Description</label>
                      <Field
                        name={nameOf("SR_Description")}
                        component={CCInput}
                        placeholder="Description"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Billing group</label>

                <Field
                  name={nameOf("BillingGroup")}
                  data={getFieldValue("_option.BillingGroup.Data") || []}
                  textField="Value"
                  dataItemKey="Key"
                  component={CCSearchComboBox}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Date of change</label>
                <Field
                  name={nameOf("DateOfChange")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  data={new Date()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCreateRegister && (
        <CreateRegisterDialog
          onSubmit={() => {
            setShowCreateRegister(false);
          }}
          onClose={() => {
            setShowCreateRegister(false);
          }}
        />
      )}
    </section>
  );
};
