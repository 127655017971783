import { ServiceStandardCategory } from "@app/products/crms/system-admin/categories/[id]/model";
import { useCRMSSytemAdminCategoryStore } from "@app/products/crms/system-admin/categories/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<ServiceStandardCategory>();

export const CRMSSystemAdminCategoryFormElement = observer(() => {
  const { isDisabled } = useCRMSSytemAdminCategoryStore();
  return (
    <FormElement>
      <div className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Name" isMandatory />
            <Field
              name={nameOf("Name")}
              component={CCInput}
              placeholder="Name"
              validator={requiredValidator}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
});
