import { loadViewConfiguresColumns } from "@app/products/property/api";
import { VO_Charge_Run_Assessment_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import { useChargeRunsStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/store";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { colAssessments } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/config";
import { DetailComponent } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/detail/_index";

const DEFAULT_ITEMS_PER_PAGE = 10;
const nameOf = nameOfFactory<VO_Charge_Run_Assessment_Summary>();

export const ChargeRunRebateAssessments = observer(() => {
  const { chargeRunsId } = useChargeRunsStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colsChargeRunAssessmentConfig, setColsChargeRunAssessmentConfig] =
    useState<IColumnFields[]>([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadChargeRunAssessmentViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Charge_Run_Assessment_Summary,
      colAssessments
    );
    if (Array.isArray(response)) {
      setColsChargeRunAssessmentConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadChargeRunAssessmentViewConfig();
  }, [chargeRunsId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadChargeRunAssessmentViewConfig();
        }}
      />
    );

  return (
    <CCGrid
      className="cc-charge-run-assessments-grid"
      dataUrl={`odata/property/internal/chargerunassessmentregister?key=${chargeRunsId}&$count=true&`}
      columnFields={colsChargeRunAssessmentConfig}
      primaryField={nameOf("Assessment_Id")}
      detail={DetailComponent}
      itemPerPage={DEFAULT_ITEMS_PER_PAGE}
    />
  );
});
