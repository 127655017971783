import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import {
  DTO_COOLeaseTransferBuyer,
  DTO_COOLeaseTransferException,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/model";

const nameOfBuyers = nameOfFactory<DTO_COOLeaseTransferBuyer>();
const nameOfExceptions = nameOfFactory<DTO_COOLeaseTransferException>();
export const colBuyers: IColumnFields[] = [
  {
    field: nameOfBuyers("ACT_Lease_Trf_Tenancy_Form_Code"),
    title: "Tenancy",
  },
  {
    field: nameOfBuyers("ALTB_Name"),
    title: "Name",
  },
  {
    field: nameOfBuyers("ALTB_Given_Names"),
    title: "Given Name",
  },
  {
    field: nameOfBuyers("ALTB_Estate_or_Interest"),
    title: "Estate or Interest",
  },
  {
    field: nameOfBuyers("ALTB_Portion_Transferred"),
    title: "Portion Transferred",
  },
  {
    field: nameOfBuyers("ACT_Lease_Transfer_Buyer_Id"),
    title: "Buyer Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colExceptions: IColumnFields[] = [
  {
    field: nameOfExceptions("ALTET_Name"),
    title: "Exception",
  },
  {
    field: nameOfExceptions("ALTE_Description"),
    title: "Description",
  },
  {
    field: nameOfExceptions("ACT_Lease_Trf_Exception_Type_Id"),
    title: "Type",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfExceptions("ALTET_Is_Error"),
    title: "Is An Error",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfExceptions("ALTET_Prevent_Update"),
    title: "Prevent Update",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfExceptions("ACT_Lease_Transfer_Exception_Id"),
    title: "Exception Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
