import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_DashboardViews } from "@common/pages/home/components/event-section/components/dashboard-view/model";

export const getDashboardViewResultSet = async (
  includeEmptyViews: boolean = false
): Promise<APIResponse<Svc_DashboardViews | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/dashboard/views?includeEmptyViews=${includeEmptyViews}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
