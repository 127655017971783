import {
  PreRenewalAnimalsDetails,
  Renewal_Method,
} from "@app/products/animals/renewals/components/button/batch-renew/model";
import { TextEditCustomCell } from "@app/products/property/components/grid/components/cc-text-edit-cell/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOf = nameOfFactory<PreRenewalAnimalsDetails>();

export const colPreRenewalAnimalsDetails: IColumnFields[] = [
  {
    field: nameOf("RegistrationNumber"),
    title: "Registration number ",
  },
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  {
    field: nameOf("AnimalName"),
    title: "Animal Name",
  },
  { field: nameOf("AnimalOwner"), title: "Animal Owner" },
  {
    field: nameOf("OSFees"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("EffectiveFrom"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("EffectiveTo"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NewTagNo"),
    title: "New Tag No",
    editable: true,
    editCell: TextEditCustomCell,
  },
  {
    field: nameOf("NextFee"),
    title: "Next Fee",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NextEffFrom"),
    title: "Next Effective From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NextEffTo"),
    title: "Next Effective To",
    format: DATE_FORMAT.DATE,
  },
];

export const generateColPreRenewalAnimalsDetails = (
  renewalMethod: Renewal_Method
) => {
  let newConfig: IColumnFields[] = cloneDeep(colPreRenewalAnimalsDetails);
  if (renewalMethod === Renewal_Method.IssueFeesUponRenewalNotice) {
  }
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("NextFee"):
        if (renewalMethod === Renewal_Method.IssueFeesUponRenewalNotice) {
          return false;
        }
        return true;
      default:
        return true;
    }
  });
};
