import { DTO_Certificate_RatesDepartment } from "@app/products/property/certificates/[id]/components/child-screens/rates-department/model";
import { useCertificateRateDepartmentStore } from "@app/products/property/certificates/[id]/components/child-screens/rates-department/store";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DTO_Certificate_RatesDepartment>();
export const CertificateRatesDepartment = observer(() => {
  const {
    data,
    lovsCertificate,
    loadRateDepartment,
    resetStore,
    isLoading,
    responseLoadError,
  } = useCertificateRateDepartmentStore();
  const { certificateId } = useCertificateStore();
  const udal = data?.UDAL ?? [];

  useEffectOnce(() => {
    if (certificateId) {
      loadRateDepartment(certificateId);
    }
    return () => resetStore();
  });
  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadRateDepartment(certificateId);
        }}
      />
    );

  return (
    <div className="cc-field-group">
      {udal?.map((item) => {
        return (
          <>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">{item?.UDA_Label ?? ""}</label>
                <CCSearchComboBox
                  name={`CCSearchComboBox-${item.UDA_Attribute_Id}`}
                  data={lovsCertificate?.[`${item.UDA_Attribute_Id}`] ?? []}
                  textField="Name"
                  dataItemKey="Code"
                  value={getDropdownValue(
                    item?.UDA_Code,
                    lovsCertificate?.[`${item.UDA_Attribute_Id}`] ?? [],
                    "Code"
                  )}
                  className="cc-form-control"
                  disabled
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">
                  {item?.UDA_Label ?? ""} details
                </label>
                <CCTextArea
                  className="cc-form-control"
                  placeholder={`${item?.UDA_Label ?? ""} details`}
                  name={`CCTextArea-${item.UDA_Attribute_Id}`}
                  rows={3}
                  value={item?.UDA_Text}
                  readOnly
                />
              </div>
            </div>
          </>
        );
      })}
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Payment date</label>
          <CCDatePicker
            className="cc-form-control"
            name={nameOf("PaymentDate")}
            format={DATE_FORMAT.DATE_CONTROL}
            value={data?.PaymentDate}
            disabled
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Completed</label>
          <CCSwitch
            name={nameOf("Completed")}
            checked={data?.Completed ?? false}
            disabled
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Comments</label>
          <CCTextArea
            className="cc-form-control"
            placeholder={"Comments"}
            name={nameOf("Comments")}
            rows={3}
            value={data?.Comments}
            readOnly
          />
        </div>
      </div>
    </div>
  );
});
