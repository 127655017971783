import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const inspectionPlannerRoute: ICCRoute = {
  path: "inspection-planner",
  name: "Inspection Planner",
  enum: AnimalsMenu.InspectionPlanner,
  children: [
    {
      path: "inspection-plans",
      name: "Inspection Plans",
      enum: AnimalsMenu.InspectionPlansByCreatedDate,
      component: require("./inspection-plans/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      enum: AnimalsMenu.InspectionPlansByArea,
      component: require("./by-area/_index").default,
    },
    {
      path: "by-priority",
      name: "By Priority",
      enum: AnimalsMenu.InspectionPlansByPriority,
      component: require("./by-priority/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: AnimalsMenu.InspectionPlansByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "over-due",
      name: "Over Due",
      enum: AnimalsMenu.InspectionPlansByOverDue,
      component: require("./over-due/_index").default,
    },
  ],
};
