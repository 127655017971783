import { IMeterReadingToBeProcessed } from "@app/products/property/meters/readings-to-be-processed/model";

export const mockMeterReadingToBeProcessed: IMeterReadingToBeProcessed[] = [
  {
    MeterNo: "Anh123",
    MeterReadingStatusName: "read",
    Reading: 10,
    ReadingDateTime: new Date("21-Jan-2022"),
    MeterId: 5876,
  },
];
