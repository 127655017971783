import { DIRECT_DEBIT_MODULE_ITEM_TYPES } from "@app/products/direct-debit/constant";
import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { getDDModuleItemTypeById } from "@app/products/direct-debit/system-admin/module-item-types/[id]/api";
import { postSaveDDModuleItemType } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/buttons/api";
import { checkIsInactiveDDModuleItemType } from "@app/products/direct-debit/system-admin/module-item-types/[id]/util";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const SaveButtonDDModuleItemType = observer(
  ({ disabled }: { disabled?: boolean }) => {
    //#region STORE ========/
    const {
      submitFormGetData,
      isLoadingForm,
      setIsLoadingForm,
      setInitialDataForms,
    } = useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const isNew = useIsNew();
    const history = useHistory();
    const { cancelToken } = useCancelRequest();
    //#endregion HOOK =====/

    const saveDDModuleItemTypeSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const directDebitModuleItemType: IntegrationModuleItemType =
              initialData;
            return postSaveDDModuleItemType(directDebitModuleItemType);
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket | undefined> =
              dataFromApi;
            if (isNew) {
              if (response?.data?.ID) {
                const urlRedirect =
                  DIRECT_DEBIT_MODULE_ITEM_TYPES + "/" + response.data.ID;
                pushNotificationPortal({
                  title: "Direct debit module item type saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              } else {
                pushNotificationPortal({
                  title: "Direct debit module item type could not be saved.",
                  type: "error",
                  autoClose: false,
                });
              }
              return false;
            }
          },
          extractParamForNextAPI: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            return response?.data?.ID;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Direct debit module item type could not be saved.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
        {
          fetch: ({ dataFromExtractParam }) => {
            const id: number = dataFromExtractParam;
            return getDDModuleItemTypeById(id, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IntegrationModuleItemType> =
              dataFromApi;
            const ddModuleItemTypeFormData = response?.data;
            //#region save data to store ========/
            setInitialDataForms({
              GeneralForm: ddModuleItemTypeFormData,
            });
            //#endregion save data to store =====/
            pushNotificationPortal({
              title: "Direct debit module item type saved successfully.",
              type: "success",
            });
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Direct debit module item type could not be saved.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const directDebitModuleItemType: IntegrationModuleItemType =
        await submitFormGetData("GeneralForm");

      if (directDebitModuleItemType) {
        setIsLoadingForm(true);
        await saveDDModuleItemTypeSlider.fetchApi({
          initialData: directDebitModuleItemType,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={
          disabled || isLoadingForm || checkIsInactiveDDModuleItemType()
        }
      />
    );
  }
);
