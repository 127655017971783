import { eventEmitter } from "@/App";
import { managedErrorMessage } from "@app/core/attachment/components/buttons/add-attachment/config";
import {
  IAttachment,
  IWebLink,
} from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  IAddAttachmentDialog,
  eUploadMode,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { saveDocument, saveDocumentAsWebLink } from "@app/core/documents/api";
import { Svc_Attachment } from "@app/core/documents/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { endsWith, isNil } from "lodash";
import React, { useState } from "react";

interface IAddAttachmentButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
  extDocType?: number;
  disabled?: boolean;
  mustRefreshCallback?: (
    mustRefreshNotification: IAppNotificationItemAddProps
  ) => void;
  blockDialogShowing?: () => boolean;
}

/**
 *
 * @param props {@link IAddAttachmentButtonProps}
 * @param [props.blockDialogShowing] A function that when visible, runs before showing the dialog, if it returns `true`, the dialog will not be shown.
 */
export const AddAttachmentButton = ({
  id,
  recordType,
  bubbleUps,
  extDocType,
  disabled = false,
  mustRefreshCallback: mustRefreshCallBack,
  blockDialogShowing,
}: IAddAttachmentButtonProps) => {
  const [isShowAttachmentDialog, setIsShowAttachmentDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const handleOnSubmit = async (data: IAddAttachmentDialog) => {
    if (id) {
      setIsLoading(true);
      switch (data?.UploadMode) {
        case eUploadMode.UPLOAD_FILE:
          if (!isNil(data?.File)) {
            Promise.all(
              data?.File?.filter((item) => isNil(item.validationErrors))?.map(
                async (item) => {
                  return {
                    FileEntry: await convertToFileStreamEntry(item),
                    Title: data.Title ?? item?.name,
                    Attachment_ID: 0,
                    Category_KWD: data?.Category?.Keyword_ID ?? null,
                    FileNumber: "",
                    TRIMUri: "",
                    DocumentClassification_ENUM:
                      data?.DocumentClassification?.Key,
                  } as IAttachment;
                }
              )
            ).then(async (attachments) => {
              const requestBody = {
                ParentID: id,
                ParentRecordType: recordType,
                AttachmentList: attachments,
                AddDateTimeToFileName: null,
                BubbleUps: bubbleUps,
              } as Svc_Attachment;
              const response = await saveDocument(requestBody);
              customLogger(
                "Core Attachment, add attachment, saveDocument"
              ).info(response?.data);
              setIsLoading(false);
              if (isSuccessIdentityPacket(response)) {
                clearErrorNotification();
                if (response?.data.mustRefresh && mustRefreshCallBack) {
                  mustRefreshCallBack({
                    title: "Attachment created successfully",
                    type: "success",
                  });
                } else {
                  pushNotification({
                    title: "Attachment created successfully",
                    type: "success",
                  });
                }
                eventEmitter.emit(CCGridEventType.RefreshOData);
                eventEmitter.emit(CCJournalEventType.RefreshData);
                eventEmitter.emit(TabTableEventType.RefreshData);
              } else {
                if (
                  response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT
                ) {
                  pushNotification({
                    autoClose: false,
                    title: "Exceeded the specified size limit",
                    type: "error",
                  });
                } else if (
                  endsWith(response.data?.Errors?.[0], managedErrorMessage)
                ) {
                  pushNotification({
                    autoClose: false,
                    title:
                      response.data?.ErrorStatus ?? "Create attachment failed",
                    description: response.data?.Errors ?? response.statusText,
                    type: "error",
                  });
                  eventEmitter.emit(CCGridEventType.RefreshOData);
                  eventEmitter.emit(CCJournalEventType.RefreshData);
                  eventEmitter.emit(TabTableEventType.RefreshData);
                } else {
                  pushNotification({
                    autoClose: false,
                    title:
                      response.data?.ErrorStatus ?? "Create attachment failed",
                    description: response.data?.Errors ?? response.statusText,
                    type: "error",
                  });
                }
              }
              setIsShowAttachmentDialog(false);
            });
          }
          break;
        case eUploadMode.UPLOAD_WEB_LINK:
          const requestBody = {
            WebLinkURI: data.WebLink,
            Tag: data?.Tag ?? "",
            WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
          } as IWebLink;
          const response = await saveDocumentAsWebLink(
            id,
            recordType,
            requestBody
          );
          customLogger(
            "Core Attachment, add attachment, saveDocumentAsWebLink"
          ).info(response?.data);
          setIsLoading(false);
          if (isSuccessResponse(response)) {
            if (
              response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
            ) {
              clearErrorNotification();
              pushNotification({
                title: "Attachment created successfully",
                type: "success",
              });
              eventEmitter.emit(CCGridEventType.RefreshOData);
              eventEmitter.emit(CCJournalEventType.RefreshData);
              eventEmitter.emit(TabTableEventType.RefreshData);
            } else {
              pushNotification({
                autoClose: false,
                title: "Attachment could not be saved",
                description: response.data?.Errors,
                type: "error",
              });
            }
          } else {
            pushNotification({
              autoClose: false,
              title: response.statusText,
              type: "error",
            });
          }
          setIsShowAttachmentDialog(false);
          break;
      }
    }
  };

  const openDialog = () => {
    if (
      typeof blockDialogShowing === "function" &&
      blockDialogShowing() === true
    ) {
      customLogger("Core Attachment, add attachment, openDialog").info(
        "Dialog is blocked from showing."
      );
      return;
    }
    setIsShowAttachmentDialog(true);
  };

  return (
    <>
      <CCNavButton
        title={"Attachment"}
        onClick={openDialog}
        disabled={disabled}
      />
      {isShowAttachmentDialog && (
        <AddAttachmentDialog
          onClose={() => setIsShowAttachmentDialog(false)}
          onSubmit={handleOnSubmit}
          isLoadingButton={isLoading}
          recordType={recordType}
          extDocType={extDocType}
        />
      )}
    </>
  );
};
