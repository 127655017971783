import { getViewConfigurations } from "@app/products/property/api";
import {
  DTO_Transaction_Item,
  ViewConfiguration,
} from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { getJournalItemDetail } from "@app/products/property/journals/[id]/components/child-screens/items/api";
import { isSuccessResponse } from "@common/apis/util";
import { cloneDeep } from "lodash";
import { colItemDetail } from "@app/products/property/journals/[id]/components/child-screens/items/components/details/config";
import { IColumnFields } from "@components/cc-grid/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseStatus } from "@common/constants/response-status";

interface IJournalItemsDetail extends GridDetailRowProps {
  nameForTransactionId?: string;
  nameForJournalNumber?: string;
}

const nameOf = nameOfFactory<DTO_Transaction_Item>();

export const JournalItemsDetail = ({
  dataItem,
  nameForTransactionId,
  nameForJournalNumber,
}: IJournalItemsDetail) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [columnFields, setColumnFields] =
    useState<IColumnFields[]>(colItemDetail);
  const [itemDetailData, setItemDetailData] = useState<DTO_Transaction_Item[]>(
    []
  );

  const loadJournalItemDetail = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const [responseConfiguration, responseItemDetailData] = await Promise.all([
      getViewConfigurations(ViewConfiguration.Journal_Transactions_Items),
      getJournalItemDetail(
        dataItem?.[`${nameForJournalNumber}`],
        dataItem?.[`${nameForTransactionId}`]
      ),
    ]);
    if (
      isSuccessResponse(responseConfiguration) &&
      isSuccessResponse(responseItemDetailData) &&
      responseConfiguration?.data &&
      responseItemDetailData?.data
    ) {
      let processColumns = cloneDeep(colItemDetail);
      processColumns = processColumns.map((column: IColumnFields) => {
        let newColumns = cloneDeep(column);
        if (newColumns.field === "Amount") {
          newColumns.calculateFooterCellValue = () =>
            responseItemDetailData?.data?.TransactionAllocations?.Totals
              ?.Amount ?? 0;
        }
        return newColumns;
      });
      setColumnFields(
        processDynamicColumns(
          processColumns,
          responseConfiguration.data.ColumnDefinitions?.Columns
        )
      );
      setItemDetailData(
        responseItemDetailData?.data?.TransactionAllocations?.TransactionItem
      );
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    if (!dataItem?.[`${nameForJournalNumber}`]) return;
    (async () => {
      await loadJournalItemDetail();
    })();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadJournalItemDetail();
        }}
      />
    );

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Transaction allocation</label>
            <CCGrid
              className="cc-contact-journals-children-grid"
              data={itemDetailData}
              columnFields={columnFields}
              primaryField={nameOf("ChargeBalancesId")}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
