import { VO_Parcel } from "@app/products/property/parcels/list/model";
import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Parcel>();
export const colDetailParcel: IColumnFields[] = [
  {
    field: nameOf("Reference"),
    title: "Parcel (S-S-B-U)",
    locked: true,
    linkTo: (dataItem) => `${PROPERTY_PARCEL_ROUTE}/${dataItem.Parcel_Id}`,
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Address",
    width: 300,
  },
  { field: nameOf("Locality_Name"), title: "Locality" },
  {
    field: nameOf("Location"),
    title: "Location",
  },
  {
    field: nameOf("Side_of_Street"),
    title: "Side of Street",
  },
  {
    field: nameOf("Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Estate_Name"),
    title: "Estate/Development",
  },
  {
    field: nameOf("Map_Number"),
    title: "Map Number",
  },
  {
    field: nameOf("Map_Reference"),
    title: "Map Reference",
  },
  {
    field: nameOf("Land_Area"),
    title: "Land Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Land_Category_Name"),
    title: "Land Category",
  },
  { field: nameOf("Owners_Names"), title: "Owners Name" },
  { field: nameOf("Land_Uses"), title: "Land Uses" },
  { field: nameOf("Zones"), title: "Zones" },
  {
    field: nameOf("Electoral_Division_Name"),
    title: "Electoral Division",
  },
  { field: nameOf("Parcel_Status_Name"), title: "Status" },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
