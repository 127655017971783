import { KeyValuePair } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getSystemFilters = async (): Promise<
  APIResponse<KeyValuePair<number, string>[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<KeyValuePair<number, string>[]>(
      `api/core/internal/getsystemfilters`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
