import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import React from "react";
import { colConcessionCardsHeld, colVerificationHistory } from "./config";

export const CONCESSION_CARDS_FORM_STEP = "ConcessionCards";

export const ConcessionCardsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const selectedConcessionCardHeld = getFieldValue(
    "SelectedConcessionCardHeld"
  );

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
    if (newSelected && field === "SelectedConcessionCardHeld") {
      onChange(nameOf("IsActive"), {
        value: newSelected.IsActive,
      });
      onChange(nameOf("DatePresented"), {
        value: newSelected.DatePresented || null,
      });
      onChange(nameOf("CardNumber"), {
        value: newSelected.CardNumber || null,
      });
    }
  };

  const handleConcessionCardHeldInfoChange = (field: string, value: any) => {
    onChange(nameOf(field), {
      value: value,
    });
    if (selectedConcessionCardHeld) {
      let newConcessionCardHeld = getFieldValue("ConcessionCardsHeld").map(
        (concessionCardHeld: any) => {
          if (concessionCardHeld.Id !== selectedConcessionCardHeld.Id)
            return concessionCardHeld;
          concessionCardHeld[field] = value;
          return concessionCardHeld;
        }
      );

      onChange(nameOf("ConcessionCardsHeld"), { value: newConcessionCardHeld });
    }
  };

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        {selectedConcessionCardHeld ? (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Card number</label>
              <Field
                name={nameOf("CardNumber")}
                component={CCInput}
                type="number"
                value={getFieldValue("CardNumber")}
                placeholder={"Card number"}
                onChange={(event: InputChangeEvent) => {
                  handleConcessionCardHeldInfoChange(
                    "CardNumber",
                    event.target.value
                  );
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Date presented</label>
              <Field
                name={nameOf("DatePresented")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                onChange={(event: DatePickerChangeEvent) => {
                  handleConcessionCardHeldInfoChange(
                    "DatePresented",
                    event.value
                  );
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is active</label>
              <Field
                name={nameOf("IsActive")}
                component={CCSwitch}
                checked={getFieldValue("IsActive")}
                onChange={(event: SwitchChangeEvent) => {
                  handleConcessionCardHeldInfoChange(
                    "IsActive",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Concession cards held</label>
            <CCGrid
              data={getFieldValue("ConcessionCardsHeld") || []}
              columnFields={colConcessionCardsHeld}
              primaryField="Id"
              selectableMode="single"
              selectedRows={
                getFieldValue("SelectedConcessionCardHeld")
                  ? [getFieldValue("SelectedConcessionCardHeld")]
                  : undefined
              }
              onSelectionChange={(dataItem) => {
                handleGridSelectionChange(
                  dataItem,
                  "SelectedConcessionCardHeld"
                );
              }}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Verification history for concession cards
            </label>
            <CCGrid
              data={getFieldValue("VotingEntitlementByElection") || []}
              columnFields={colVerificationHistory}
              primaryField="CardNumber"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
