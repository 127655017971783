import { getContactLovs } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/format-name-and-address/api";
import { GET_ENTITY_LOCALITY_REGISTER } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/format-name-and-address/constant";
import { DTO_Entity_Name_Address } from "@app/products/property/components/fields/search-name/model";
import { getEntityStreetLocality } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/api";
import {
  ComboboxSearchAPI,
  IEventOnChangeComboBox,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/_index";
import { VO_Entity_Locality } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/model";
import { DTO_Create_Entity_LOVs } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { default as React, ReactElement, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
interface IFormatNameAndAddressDialog {
  onClose: () => void;
  onSubmit: (data: DTO_Entity_Name_Address) => void;
  initialValues?: DTO_Entity_Name_Address;
  noticeGroupData: DTO_LOV_Number[];
}

export const FormatNameAndAddressDialog = observer(
  ({
    onClose,
    onSubmit,
    initialValues,
    noticeGroupData = [],
  }: IFormatNameAndAddressDialog) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] =
      useState<APIResponseError>();
    const [contactLOVs, setContactLOVs] = useState<DTO_Create_Entity_LOVs>();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const loadLovs = async () => {
      setIsLoading(true);
      const response = await getContactLovs();
      let errorResponse = undefined;
      if (isSuccessResponse(response)) {
        setContactLOVs(response?.data?.dtoEntity_LOVs);
      } else {
        const responseError = response as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error ?? "Load failed",
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };
    useEffectOnce(() => {
      loadLovs();
    });

    return (
      <Form
        onSubmitClick={(event: FormSubmitClickEvent) =>
          onSubmit(event.values as DTO_Entity_Name_Address)
        }
        initialValues={{
          ...initialValues,
          _option: {
            Locality: {
              Locality_Code: initialValues?.Locality,
            },
          },
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;
          const country = valueGetter("Country");
          const locality = valueGetter("Locality");
          const setFormattedNameAndAddressValue = () => {
            const name = valueGetter("Name");
            const attentionOf = valueGetter("Attention_Of");
            const careOf = valueGetter("Care_Of");
            const address = valueGetter("Address");
            const locality = valueGetter("Locality");
            const state = valueGetter("State");
            const postcode = valueGetter("Postcode");
            const formattedNameAndAddress = [
              name ? name + "\n" : "",
              attentionOf ? "Attn: " + attentionOf + "\n" : "",
              careOf ? "c/- " + careOf + "\n" : "",
              address ? address + "\n" : "",
              locality ? locality + " " : "",
              state ? state + " " : "",
              postcode ? postcode + " " : "",
            ].join("");
            onChange("Formatted_Name_Address", {
              value: formattedNameAndAddress,
            });
          };
          /**
           * handle Locality field
           * set data other field
           * calling api
           * @param event
           */
          const handleLocality = async (event: IEventOnChangeComboBox) => {
            onChange("Locality", {
              value: event?.value?.Locality_Code,
            });
            onChange("_option.Locality", {
              value: event?.value?.Locality_Code,
            });
            onChange("Locality_Data", {
              value: event?.data ?? [],
            });
            const postcodeId = event?.target?.value?.Postcode_Id;

            if (!isNil(postcodeId)) {
              onChange("Loading.Locality", { value: true });
              const response = await getEntityStreetLocality({ postcodeId });

              if (isSuccessResponse(response)) {
                const data = response.data?.dtoStreetAndLocality;
                onChange("State", { value: data?.State });
                onChange("Postcode", { value: data?.PostCode });
              } else {
                notificationRef?.current?.pushNotification({
                  title: response.error ?? "Load failed",
                  type: "error",
                  autoClose: false,
                });
              }
              onChange("Loading.Locality", { value: false });
            }
            setFormattedNameAndAddressValue();
          };

          return (
            <CCDialog
              maxWidth="45%"
              maxHeight="80%"
              titleHeader={"Format Name and Address"}
              onClose={onClose}
              bodyElement={
                <>
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        loadLovs();
                      }}
                    />
                  ) : (
                    <FormElement className="cc-form cc-formatted-dialog">
                      <section className="cc-field-group">
                        <CCLocalNotification ref={notificationRef} />
                        <div className="cc-form-cols-2">
                          <div className="cc-field cc-col-span-2">
                            <CCLabel title="Name(s)" />
                            <Field
                              name={"Name"}
                              component={CCTextArea}
                              rows={3}
                              placeholder={"Name(s)"}
                              onChange={(event: TextAreaChangeEvent) => {
                                onChange("Name", { value: event?.value });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>

                          <div className="cc-field">
                            <CCLabel title="Attention of" />
                            <Field
                              name={"Attention_Of"}
                              component={CCInput}
                              placeholder={"Attention of"}
                              onChange={(event: InputChangeEvent) => {
                                onChange("Attention_Of", {
                                  value: event?.value,
                                });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Care of" />
                            <Field
                              name={"Care_Of"}
                              component={CCInput}
                              placeholder={"Care of"}
                              onChange={(event: InputChangeEvent) => {
                                onChange("Care_Of", { value: event?.value });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>

                          <div className="cc-field cc-col-span-2">
                            <CCLabel title="Address" />
                            <Field
                              name={"Address"}
                              component={CCTextArea}
                              rows={3}
                              placeholder={"Address"}
                              onChange={(event: TextAreaChangeEvent) => {
                                onChange("Address", { value: event?.value });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>

                          <div className="cc-field">
                            {/* TODO: Update CCLabel and isLoading */}
                            <label className="cc-label">
                              Locality&nbsp;
                              {valueGetter("Loading.Locality") && (
                                <i className="cc-icon-loading fas fa-spinner fa-spin" />
                              )}
                            </label>
                            <Field
                              name={"_option.Locality"}
                              textField="Locality_Code"
                              dataItemKey="Locality_Code"
                              urlAPI={GET_ENTITY_LOCALITY_REGISTER}
                              component={(event) =>
                                ComboboxSearchAPI<VO_Entity_Locality>(event)
                              }
                              itemRender={ItemRenderLocality}
                              keySearch={"Locality_Code"}
                              data={valueGetter("Locality_Data")}
                              onChange={handleLocality}
                              disabled={country}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="State" />
                            <Field
                              name={"State"}
                              textField="Name"
                              dataItemKey="Code"
                              data={contactLOVs?.State ?? []}
                              component={CCSearchComboBox}
                              disabled={country}
                              value={getDropdownValue(
                                valueGetter("State"),
                                contactLOVs?.State ?? [],
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("State", {
                                  value: event?.value?.Code ?? null,
                                });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>

                          <div className="cc-field">
                            <CCLabel title="Country" />
                            <Field
                              name={"Country"}
                              textField="Name"
                              dataItemKey="Code"
                              data={contactLOVs?.Country ?? []}
                              component={CCSearchComboBox}
                              disabled={locality}
                              value={getDropdownValue(
                                valueGetter("Country"),
                                contactLOVs?.Country ?? [],
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("Locality", { value: "" });
                                onChange("State", { value: "" });
                                onChange("Postcode", { value: "" });
                                onChange("Country", {
                                  value: event.value?.Code ?? null,
                                });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Postcode" />
                            <Field
                              name={"Postcode"}
                              component={CCInput}
                              placeholder={"Postcode"}
                              readOnly={country}
                              onChange={(event: InputChangeEvent) => {
                                onChange("Postcode", { value: event?.value });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>

                          {/* TODO: Implement in the future */}
                          {/* <div className="cc-field">
                          <CCLabel title="DPID"/>
                          <Field
                            name={"DPID"}
                            component={CCInput}
                            placeholder={"DPID"}
                            readOnly
                          />
                        </div> */}
                          <div className="cc-field">
                            <CCLabel title="Notice" />
                            <Field
                              name={"Notice_Id"}
                              textField="Name"
                              dataItemKey="Code"
                              data={noticeGroupData ?? []}
                              component={CCSearchComboBox}
                              value={getDropdownValue(
                                valueGetter("Notice_Id"),
                                noticeGroupData ?? [],
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("Notice_Id", {
                                  value: event.value?.Code ?? null,
                                });
                                setFormattedNameAndAddressValue();
                              }}
                            />
                          </div>

                          <div className="cc-field cc-col-span-2">
                            <CCLabel title="Formatted name and address" />
                            <Field
                              name={"Formatted_Name_Address"}
                              component={CCTextArea}
                              rows={4}
                              placeholder={"Formatted name and address"}
                              readOnly
                            />
                          </div>
                        </div>
                      </section>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
const ItemRenderLocality = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = <>{dataItem?.Locality_Name}</>;
  return React.cloneElement(li, li.props, itemChildren);
};
