import {
  AssociationMeter,
  MasterSubEnum,
} from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<AssociationMeter>();
export const colMaterMeters: IColumnFields[] = [
  {
    field: nameOf("MasterSub"),
    title: "Master or Sub",
    filterByEnum: MasterSubEnum,
  },
  {
    field: nameOf("MeterNo"),
    title: "Meter Number",
  },
  { field: nameOf("Installed"), title: "Installed", format: DATE_FORMAT.DATE },
  {
    field: nameOf("Removed"),
    title: "Removed",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("AssociatedFrom"),
    title: "Associated From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("AssociatedTo"),
    title: "Associated To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LastReadOn"),
    title: "Last Read On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
