import { loadAttributesUDA } from "@app/products/property/assessments/[id]/components/child-screens/attributes-uda/api";
import { IAttributeData } from "@app/products/property/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentAttributesUDAStore {
  private _attributesUDA?: IAttributeData[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get attributesUDA() {
    return this._attributesUDA;
  }
  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setAttributesUDA = (attributesUDA?: IAttributeData[]) => {
    runInAction(() => {
      this._attributesUDA = attributesUDA;
    });
  };

  //Action

  loadAttributesUDA = async (assessmentId: number) => {
    this.setIsLoading(true);
    const newAttributes = await loadAttributesUDA(assessmentId);

    this.setAttributesUDA(
      newAttributes && newAttributes?.ItemData
        ? Object.keys(newAttributes?.ItemData).map((key, index) => ({
            Attribute: key,
            Value: newAttributes.ItemData[key as any],
            Id: index,
          }))
        : undefined
    );
    this.setIsLoading(false);
  };
}

const assessmentAttributesUDAStoreContext = createContext(
  new AssessmentAttributesUDAStore()
);
export const useAssessmentAttributesUDAStore = () => {
  return useContext(assessmentAttributesUDAStoreContext);
};
