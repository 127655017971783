import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colFirePreventionActivities } from "./config";
import { mockFirePreventionActivities } from "./mock";
const FirePreventionActivities = () => {
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={mockFirePreventionActivities || []}
              columnFields={colFirePreventionActivities}
              primaryField="ActivityId"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FirePreventionActivities;
