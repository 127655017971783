import {
  DebtRecoveryViews,
  DTO_Component_DebtRecovery,
} from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getDebtRecovery = async (
  assessmentId: number,
  view: DebtRecoveryViews.All
): Promise<DTO_Component_DebtRecovery | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_DebtRecovery>(
        `api/Property/internal/Assessment/${assessmentId}/debtrecovery`,
        {
          params: {
            view,
          },
        }
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
