import { eventEmitter } from "@/App";
import { COLLECTION_TYPE_REGISTER_LIST_ODATA } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/constant";
import { VO_CollectionType } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/model";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { colCollectionsAssociate } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/associate-collection/config";
import { NewCollection } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/new-collection/_index";
import { DTO_Collection } from "@app/products/property/components/child-screen/collections/model";
import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { useDebounce } from "@common/hooks/useDebounce";
import { getUUID, nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useRef, useState } from "react";

const COLLECTION_TYPE_GRID_ID = getUUID();
const nameOfCollection = nameOfFactory<VO_CollectionType>();
interface IAssociateCollectionProps {
  onClose: () => void;
  onSubmit: (event: VO_CollectionType) => void;
  isLoadingParentForm?: boolean;
  collections?: DTO_Collection[];
  isLoadingSave?: boolean;
  isSaveAndClose?: boolean;
}
export const AssociateCollection = ({
  onClose,
  onSubmit,
  isLoadingParentForm = false,
  collections,
  isLoadingSave = false,
  isSaveAndClose = true,
}: IAssociateCollectionProps) => {
  const { assessmentCollectionStepLOVs } = useAssessmentCollectionStepStore();
  const [urlODataSearch, setUrlODataSearch] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [showNewCollection, setShowNewCollection] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const formRef = useRef<any>(null);
  useEffect(() => {
    (async () => {
      if (debouncedSearch) {
        setIsSearching(true);
        setUrlODataSearch(
          getUrlSearchInputPicker(
            searchKey,
            nameOfCollection("CollectionTypeName"),
            COLLECTION_TYPE_REGISTER_LIST_ODATA
          )
        );
        setIsSearching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);
  const handleSubmit = (values: { [name: string]: any }) => {
    onSubmit(values as VO_CollectionType);
  };
  return (
    <Form
      initialValues={{ SelectedCollection: collections ?? [] }}
      onSubmit={handleSubmit}
      ref={formRef}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter, onSubmit, modified } = formRenderProps;
        const collectionSelected = valueGetter("SelectedCollection") ?? [];
        return (
          <CCDialog
            maxWidth="50%"
            height="auto"
            titleHeader={"Collections"}
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form cc-memo-dialog">
                <section className="cc-field-group">
                  <div className="cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-1">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-field">
                        <label className="cc-label">Search</label>
                        <Field
                          name={"searchKey"}
                          component={CCInput}
                          placeholder="Search"
                          onChange={(e: InputChangeEvent) => {
                            setSearchKey(e.value);
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Select the collections to be associated
                        </label>
                        <CCGrid
                          gridId={COLLECTION_TYPE_GRID_ID}
                          dataUrl={
                            searchKey
                              ? urlODataSearch
                              : COLLECTION_TYPE_REGISTER_LIST_ODATA
                          }
                          primaryField={nameOfCollection("Collection_Id")}
                          columnFields={colCollectionsAssociate}
                          selectedRows={collectionSelected}
                          itemPerPage={10}
                          isLoading={isSearching || isLoadingParentForm}
                          selectableMode="multiple"
                          onSelectionChange={(
                            dataItem: VO_CollectionType[]
                          ) => {
                            onChange("SelectedCollection", {
                              value: [...dataItem],
                            });
                          }}
                          state={{
                            sort: [
                              {
                                field: nameOfCollection("Collection_Id"),
                                dir: "desc",
                              },
                            ],
                            filter: {
                              filters: [
                                {
                                  field: nameOfCollection(
                                    "Collection_Show_in_List"
                                  ),
                                  value: true,
                                  operator: "eq",
                                },
                              ],
                              logic: "and",
                            },
                          }}
                          toolbar={
                            <div className="cc-grid-tools-bar">
                              <Button
                                type="button"
                                iconClass="fas fa-plus"
                                title="Add a new collection"
                                onClick={() => setShowNewCollection(true)}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {showNewCollection && (
                    <NewCollection
                      onClose={() => setShowNewCollection(false)}
                      onSubmit={(data: VO_CollectionType[]) => {
                        if (isSaveAndClose) {
                          onChange("SelectedCollection", {
                            value: data,
                          });
                          formRef?.current?.onSubmit();
                        } else {
                          onChange("SelectedCollection", {
                            value: [
                              ...data,
                              ...valueGetter("SelectedCollection"),
                            ],
                          });
                          eventEmitter.emit(CCGridEventType.RefreshOData, {
                            gridIds: [COLLECTION_TYPE_GRID_ID],
                          });
                        }

                        setShowNewCollection(false);
                      }}
                      collectionTypeListData={
                        assessmentCollectionStepLOVs?.Collection_Type ?? []
                      }
                      isShowCollectionInList
                    />
                  )}
                </section>
              </FormElement>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  className={"cc-dialog-button"}
                  onClick={onClose}
                  disabled={isLoadingSave}
                >
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  className={"cc-dialog-button"}
                  onClick={onSubmit}
                  iconClass={isLoadingSave ? "fas fa-spinner fa-spin" : ""}
                  disabled={
                    isSearching ||
                    isLoadingParentForm ||
                    isLoadingSave ||
                    !modified
                  }
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
