import { EAnimalSettingsCategory } from "@app/products/animals/system-admin/settings/_id/main/model";
import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const generalRouteStateAnimalsSettings: IRouteStateForm[] = [
  {
    name: EAnimalSettingsCategory.General_General,
    component: require("./forms/general/_index").default,
  },
  {
    name: EAnimalSettingsCategory.General_Registration,
    component: require("./forms/registration/_index").default,
  },
  {
    name: EAnimalSettingsCategory.General_Renewals,
    component: require("./forms/renewals/_index").default,
  },
  {
    name: EAnimalSettingsCategory.General_Views,
    component: require("./forms/views/_index").default,
  },
  {
    name: EAnimalSettingsCategory.General_InspectionPlanner,
    component: require("./forms/inspection-planner/_index").default,
  },
  {
    name: EAnimalSettingsCategory.General_MailMerge,
    component: require("./forms/mail-merge/_index").default,
  },
];
