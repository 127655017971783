/* eslint-disable no-useless-computed-key */
import { getDisplayTextWithDashes } from "@common/utils/common";

export const LLBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Local Laws ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      //don't have PermitHolder in list view mock data, require when integrate API
      selectedRow?.PermitHolder ?? "No Name",
      selectedRow?.Type,
    ]);
  },
};
