import { loadAssessmentAnnualStockList } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/api";
import { DTO_Assessment_AnnualReturnLandStock } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AnnualStockAssessmentStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _annualStock?: DTO_Assessment_AnnualReturnLandStock = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get annualStock() {
    return this._annualStock;
  }

  setAnnualStock = (annualStock?: DTO_Assessment_AnnualReturnLandStock) => {
    runInAction(() => {
      this._annualStock = annualStock;
    });
  };

  //Action

  loadAnnualStock = async (assessmentId: number) => {
    this.setIsLoading(true);
    let errorResponse;
    const annualStock = await loadAssessmentAnnualStockList(assessmentId);
    if (isSuccessResponse(annualStock) && annualStock?.data) {
      this.setAnnualStock(annualStock?.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._annualStock = undefined;
    });
  };
}

const annualStockAssessmentStoreContext = createContext(
  new AnnualStockAssessmentStore()
);

export const useAnnualStockAssessmentStore = () => {
  return useContext(annualStockAssessmentStoreContext);
};
