import { aesEncrypt } from "@common/utils/cryptography";
import axios from "axios";

interface IResetPassword {
  EncodedKey: string;
  CurrentPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}
export const resetPassword = async (obj: IResetPassword): Promise<any> => {
  try {
    const data = {
      EncodedKey: obj.EncodedKey,
      CurrentPassword: aesEncrypt(obj.CurrentPassword),
      NewPassword: aesEncrypt(obj.NewPassword),
      ConfirmPassword: aesEncrypt(obj.ConfirmPassword),
    };
    return await axios.put<any>(`api/authentication/reset-password`, data);
  } catch (error) {
    const errorResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
