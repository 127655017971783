export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Property - Supplementary Rates ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  const dynamicDetail = selectedRow.ID ? ` ${selectedRow.ID}` : "";
  return `Supplementary ${dynamicDetail} `;
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Supplementary Rates";
};
