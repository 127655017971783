import { VO_DeferredDuty_NoticeRuns } from "@app/products/property/deferred-duty/notice-runs/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { DEFERRED_DUTY_NOTICE_RUNS_ROUTE } from "./[id]/constant";

const nameOf = nameOfFactory<VO_DeferredDuty_NoticeRuns>();

export const colListNoticeRuns: IColumnFields[] = [
  {
    field: nameOf("DDS_Notice_Run_Id"),
    title: "Notice Run ID",
    locked: true,
    linkTo: (dataItem) => {
      return `${DEFERRED_DUTY_NOTICE_RUNS_ROUTE}/${dataItem.DDS_Notice_Run_Id}`;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("DDSNR_Name"),
    title: "Run Name",
  },
  {
    field: nameOf("DDSNR_Run_Date"),
    title: "Run Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DDSNR_Issue_Date"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DDSNR_Period_From_Date"),
    title: "Period Start",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DDSNR_Period_To_Date"),
    title: "Period End",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("No_of_Accounts"),
    title: "Number of Accounts",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Total_Interest_Raised"),
    title: "Total Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total_Other_Costs"),
    title: "Total Other Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total_Principal_Balance"),
    title: "Deferred Duty Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total_Payout_Amount"),
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DDSNR_Created_By"),
    title: "Created By",
  },
];
