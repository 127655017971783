import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import {
  EGenderAnimal,
  iconAnimalsColumnAlertConfig,
  iconAnimalsColumnDangerousConfig,
} from "@app/products/animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<AnimalsRegister>();
export const colAnimalsRegisterLostTags: IColumnFields[] = [
  {
    field: nameOf("RegistrationNumber"),
    locked: true,
    linkTo: (dataItem) => {
      return `${ANIMALS_ROUTE}/` + dataItem.ID;
    },
    title: "Registration Number",
  },
  ...iconAnimalsColumnAlertConfig,
  ...iconAnimalsColumnDangerousConfig,
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  { field: nameOf("AnimalOwner"), title: "Animal Owner" },
  {
    field: nameOf("AnimalOwnerContactNo"),
    title: "Contact Number",
  },
  {
    field: nameOf("SiteAddress"),
    title: "Registered Address",
  },
  { field: nameOf("Locality"), title: "Locality" },
  { field: nameOf("Type"), title: "Animal Type" },
  { field: nameOf("Breed"), title: "Breed" },
  { field: nameOf("AnimalName"), title: "Name" },
  { field: nameOf("Colour"), title: "Colour" },
  { field: nameOf("Gender"), title: "Gender", filterByEnum: EGenderAnimal },
  {
    field: nameOf("Age"),
    title: "Age",
  },
  {
    field: nameOf("MicrochipNo"),
    title: "Chip Number",
  },
  {
    field: nameOf("Sterilised"),
    title: "Sterilised",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: nameOf("StatusDueStatus"), title: "Status" },
  {
    field: nameOf("EffectiveTo"),
    title: "Due",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("ID"), title: "ID", style: formatStyleUnRelevant },
];
