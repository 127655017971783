import { ICCRoute } from "@common/constants/ICCRoute";

export const appealHearingRoute: ICCRoute = {
  path: "appeal-hearing",
  name: "Appeal Hearing",
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
