import { CoreAPIService } from "@common/apis/coreAPIService";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";
import { ActiveProduct, LoggedOnUser } from "@components/layout/model";

const saveUserTheme = async (theme: string) => {
  return await CoreAPIService.getClient().post(
    `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/dashboard/savetheme/${theme}`,
    {}
  );
};

const getActiveProducts = async (): Promise<IResponseData<ActiveProduct[]>> => {
  return await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/products`
  );
};

const getUserInfo = async (): Promise<IResponseData<LoggedOnUser>> => {
  return await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/getcurrentuser`
  );
};

export default {
  saveUserTheme,
  getActiveProducts,
  getUserInfo,
};
