import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useShowHideLogicPropertiesSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/hooks/useShowHideLogicPropertiesSettings";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export const GlobalSettingAddressDialog = observer(
  ({ formRenderProps }: IGlobalSettingsProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { configData } = useSettingGlobalStore();

    const data = useDataSetting(configData, [
      "CorporateSettings_PropertyAutoPopupDialogFromAddressDialog",
      "CorporateSettings_PropertyDialog_OnPropertySelectionReturnToAddressDialog",
      "CorporateSettings_AddressDialog_NearestCrossStreet",
      "CorporateSettings_PropertyDialog_DescriptionInAddressDialog",
      "Global_Contact_SplitAddressName",
      "CorporateSettings_EnableAutoCompleteSearchForAddress",
    ]);
    const {
      showPropertiesAutoPopup,
      showPropertySelectionReturnToPropertyDialog,
    } = useShowHideLogicPropertiesSettings(formRenderProps);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {showPropertiesAutoPopup && (
            <SwitchGlobalSettings
              data={
                data.CorporateSettings_PropertyAutoPopupDialogFromAddressDialog
              }
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          {showPropertySelectionReturnToPropertyDialog && (
            <SwitchGlobalSettings
              data={
                data.CorporateSettings_PropertyDialog_OnPropertySelectionReturnToAddressDialog
              }
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          <SwitchGlobalSettings
            data={data.CorporateSettings_AddressDialog_NearestCrossStreet}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={
              data.CorporateSettings_PropertyDialog_DescriptionInAddressDialog
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.Global_Contact_SplitAddressName}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={data.CorporateSettings_EnableAutoCompleteSearchForAddress}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
