import { DTO_AssessmentRebateEntitlementEntity } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { loadAssessmentRebateEntitiesDetail } from "./api";
import { colAssociatedEntities } from "./config";

const EntitledToRebateCardsDetails = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DTO_AssessmentRebateEntitlementEntity[]>([]);

  useEffectOnce(() => {
    setIsLoading(true);
    loadAssessmentRebateEntitiesDetail(dataItem.assessmentId, dataItem.Id).then(
      (response) => {
        if (
          !response?.AssociatedEntities ||
          response.AssociatedEntities.length === 0
        ) {
          setIsLoading(false);
          return;
        }
        setData(response.AssociatedEntities);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  });

  return (
    <>
      <label className="cc-label">Associated contacts</label>
      <CCGrid
        className="cc-rebates-concession-entitled-grid"
        data={data}
        isLoading={isLoading}
        columnFields={colAssociatedEntities}
        primaryField="Id"
      />
    </>
  );
};
export default EntitledToRebateCardsDetails;
