import { colSchemeAccountNotices } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/notices/config";
import { mockSchemeAccountNotices } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/notices/mock";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

const SchemeAccountNotices = () => {
  return (
    <div className="cc-scheme-account-notices">
      <CCGrid
        className="cc-scheme-account-notices"
        data={mockSchemeAccountNotices}
        columnFields={colSchemeAccountNotices}
        primaryField="NoticeNumber"
      />
    </div>
  );
};

export default SchemeAccountNotices;
