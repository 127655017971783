import { DDCreateAccountDialog } from "@app/products/direct-debit/accounts/components/dialogs/create-account";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import React, { useMemo, useState } from "react";

interface IDDAuthorisationAddAccountButtonProps {
  Authorisation_Id?: number;
  Authorisation_Number?: string;
  isDisabled?: boolean;
}

export const DDAuthorisationAddAccountButton = ({
  Authorisation_Id,
  Authorisation_Number,
  isDisabled = false,
}: IDDAuthorisationAddAccountButtonProps) => {
  const [isShowCreateAccountDialog, setIsShowCreateAccountDialog] =
    useState(false);

  const initData = useMemo(() => {
    if (!isNil(Authorisation_Id) && !isNil(Authorisation_Number)) {
      return {
        Authorisation_Id,
        Authorisation_Number,
      };
    }
    return null;
  }, [Authorisation_Id, Authorisation_Number]);

  return (
    <>
      <CCNavButton
        title="Account"
        onClick={() => setIsShowCreateAccountDialog(true)}
        disabled={isDisabled}
      />
      {isShowCreateAccountDialog && (
        <DDCreateAccountDialog
          onClose={() => setIsShowCreateAccountDialog(false)}
          initValues={initData}
        />
      )}
    </>
  );
};
