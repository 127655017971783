import { eventEmitter } from "@/App";
import { CORE_GET_ACTIONS_ODATA } from "@app/core/components/common/constant";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { useTabTableStore } from "@app/core/tab-table/store";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import {
  colPropertyActions,
  colWorkflows,
} from "@app/products/property/components/child-screen/actions/config";
import { ACTIONS_AND_WORKFLOWS_GRID_ID } from "@app/products/property/components/child-screen/actions/constant";
import {
  IPropertyActions,
  VO_Workflow_Draft_Related,
} from "@app/products/property/components/child-screen/actions/model";
import { ViewConfiguration, WorkflowTypes } from "@app/products/property/model";
import { ActionTypes } from "@app/products/town-planning/ppr/actions/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IPropertyActionsProps {
  id?: number;
  recordType: RECORDTYPE;
  componentNumber?: number;
}

const nameOf = nameOfFactory<IPropertyActions>();
const nameOfWorkflows = nameOfFactory<VO_Workflow_Draft_Related>();
export const PropertyActions = observer(
  ({ id, recordType, componentNumber }: IPropertyActionsProps) => {
    const [gridSelectedRows, setGridSelectedRows] = useState<
      IPropertyActions[]
    >([]);
    const [stateGrid, setStateGrid] = useState<State>({
      sort: [{ field: nameOf("Date"), dir: "desc" }],
    });
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState(false);
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { setListDialog, resetData } = usePropertyWorkflowStore();
    const { isDisabled } = useTabTableStore();
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const [columnWorkflows, setColumnWorkflows] =
      useState<IColumnFields[]>(colWorkflows);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const handleConfirmDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const parameterRecordType =
        gridSelectedRows[0].Type === ActionTypes.FurtherInfoRequest
          ? RECORDTYPE.CORE_FurtherInformationRequest
          : RECORDTYPE.CORE_Action;
      const response = await deleteRefUsageByIdAndRecordType(
        gridSelectedRows?.map((item: IPropertyActions) => item.ID),
        parameterRecordType,
        data?.Reason
      );
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        setShowConfirmDeleteDialog(false);
        clearErrorNotification();
        pushNotification({
          title: "Action deleted successfully",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Deleted action failed",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    const loadViewConfig = async () => {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const newColumnWorkflows = colWorkflows.map((column: IColumnFields) => {
        if (column.field === nameOfWorkflows("Workflow_Draft_Id")) {
          return {
            ...column,
            handleOnClick: (event: VO_Workflow_Draft_Related) => {
              setListDialog([
                {
                  type: event?.Workflow_Type_Enum as WorkflowTypes,
                  data: event,
                },
              ]);
            },
          };
        }
        return column;
      });
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.OData_RelatedWorkflows,
        newColumnWorkflows
      );
      if (Array.isArray(response)) {
        setColumnWorkflows(response);
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadViewConfig();
      return () => resetData();
    });

    return (
      <>
        {responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => {
              setResponseLoadError(undefined);
              loadViewConfig();
            }}
          />
        ) : (
          <div className="cc-property-actions">
            <label className="cc-label">Workflows</label>
            <CCGrid
              gridId={ACTIONS_AND_WORKFLOWS_GRID_ID}
              isLoading={isLoading}
              dataUrl={`/odata/property/internal/relatedworkflowdraftregister/GetRelatedWorkflowsByComponent(componentId=${id},componentNumber=${componentNumber})?$count=true&`}
              state={{
                sort: [
                  { field: nameOfWorkflows("Workflow_Draft_Id"), dir: "desc" },
                ],
              }}
              columnFields={columnWorkflows}
              primaryField={nameOfWorkflows("Workflow_Draft_Id")}
            />
          </div>
        )}
        <br />
        <div className="cc-property-actions">
          <label className="cc-label">Actions</label>
          <CCGrid
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  disabled={
                    isDisabled ? isDisabled : gridSelectedRows.length !== 1
                  }
                  onClick={() => {
                    setShowConfirmDeleteDialog(true);
                  }}
                />
              </div>
            }
            selectableMode="multiple"
            dataUrl={`${CORE_GET_ACTIONS_ODATA}(key=${id},recordType=${recordType})?$count=true&`}
            primaryField={nameOf("ID")}
            state={stateGrid}
            selectedRows={gridSelectedRows}
            onSelectionChange={(dataItem: IPropertyActions[]) => {
              if (dataItem) setGridSelectedRows([...dataItem]);
            }}
            columnFields={colPropertyActions}
            onDataStateChange={(state) => setStateGrid(state)}
          />
        </div>
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => {
              setShowConfirmDeleteDialog(false);
            }}
            contentDelete={gridSelectedRows[0].Type}
            handleSubmit={handleConfirmDelete}
            isDeleting={isDeleting}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
