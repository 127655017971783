import { DeleteButtonCommon } from "@app/core/delete/buttons/common/_index";
import { ActionDeleteAuthorise } from "@common/pages/actions/model";
import {
  checkAuthoriseDeteteActionButton,
  convertFormIdentifierToRecordType,
} from "@common/pages/actions/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IDeleteActionButtonProps {
  disabled?: boolean;
  refreshGridIds?: string[];
  gridId?: string;
  authorises?: ActionDeleteAuthorise;
}

export const DeleteActionButton = observer(
  ({
    disabled = false,
    refreshGridIds = [],
    gridId,
    authorises,
  }: IDeleteActionButtonProps) => {
    const { gridSelectedIds, gridSelectedRows } = useCCProductListViewStore();

    const recordType = useMemo(() => {
      if (gridSelectedRows.length !== 1) return undefined;
      const parameterRecordType = convertFormIdentifierToRecordType(
        gridSelectedRows[0]?.FormIdentifier_ENUM
      );
      return parameterRecordType;
    }, [gridSelectedRows]);

    const buttonDisable = useMemo(() => {
      if (isNil(recordType)) return true;
      let allowSecurity = checkAuthoriseDeteteActionButton(
        recordType,
        authorises
      );
      return disabled || !allowSecurity;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, authorises, recordType]);

    return (
      <DeleteButtonCommon
        recordType={recordType}
        disabled={buttonDisable}
        ids={gridSelectedIds}
        refreshGridIds={refreshGridIds}
        gridId={gridId}
      />
    );
  }
);
