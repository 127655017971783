import { complaintsRoute } from "@app/products/waste-water/complaints/route";
import { financeRoute } from "@app/products/waste-water/finance/route";
import { inspectionsRoute } from "@app/products/waste-water/inspections/route";
import { noticesRoute } from "@app/products/waste-water/notices/route";
import { scheduleRoute } from "@app/products/waste-water/schedule/route";
import { systemAdminRoute } from "@app/products/waste-water/system-admin/route";
import { systemsRoute } from "@app/products/waste-water/systems/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const wasteWaterRoute: ICCRoute = {
  path: "waste-water",
  name: "Waste Water",
  enum: eProductType.WasteWater,
  children: [
    systemsRoute,
    scheduleRoute,
    inspectionsRoute,
    complaintsRoute,
    noticesRoute,
    financeRoute,
    systemAdminRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
