import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const deletePICChargeRun = async (
  picChargeRunId: string
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/property/internal/picchargerun/${picChargeRunId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
