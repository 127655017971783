import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";

import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ReferenceSlideBar = observer(() => {
  const { isShowSlideBar, setIsShowSlideBar, isEmptyData } =
    useDetailParcelListViewStore();

  return (
    <>
      {isShowSlideBar && !isEmptyData ? (
        <div className="ncc-product-list-view-slide-bar">
          <div className="ncc-product-list-view-slide-bar-card">
            <ReferenceSideBar />
          </div>
          <div
            className="ncc-product-list-view-slide-bar-backdrop"
            onClick={() => {
              setIsShowSlideBar(false);
            }}
          />
        </div>
      ) : null}
    </>
  );
});
