import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { IRecordsTypes } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/model";
import { RecordsTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/_index";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsInfoXpert {
  formRenderProps: FormRenderProps;
}
const nameOfRecordType = nameOfFactory<IRecordsTypes>();
const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();
export const RecordsInfoXpert = observer(
  ({ formRenderProps }: IRecordsInfoXpert) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();
    const { valueGetter, onChange } = formRenderProps;

    const fieldInfoXpertUsername = getConfigDataField(
      ECorporateSettingsField.infoXpert_Username
    );
    const fieldInfoXpertPassword = getConfigDataField(
      ECorporateSettingsField.infoXpert_Password
    );
    const fieldInfoXpertRoot = getConfigDataField(
      ECorporateSettingsField.infoXpert_RootFolder
    );
    const fieldInfoXpertFolderURL = getConfigDataField(
      ECorporateSettingsField.infoXpert_URL
    );
    const fieldInfoXpertDocumentURL = getConfigDataField(
      ECorporateSettingsField.infoXpert_DocumentURL
    );
    const fieldInfoXpertServiceURL = getConfigDataField(
      ECorporateSettingsField.infoXpert_ServiceURL
    );
    const fieldInfoXpertAttachmentRecordType = getConfigDataField(
      ECorporateSettingsField.infoXpert_AttachmentRecordTypeName
    );
    const fieldInfoXpertSaveCommunication = getConfigDataField(
      ECorporateSettingsField.infoXpert_SaveCommunications
    );
    const fieldInfoXpertCommunicationRecordType = getConfigDataField(
      ECorporateSettingsField.infoXpert_CommunicationRecordType
    );
    const fieldDocumentCategoryInfoExpert = getConfigDataField(
      ECorporateSettingsField.infoXpert_CommunicationDefaultDocumentCategory
    );
    const fieldInfoXpertAttachmentTitleFormat = getConfigDataField(
      ECorporateSettingsField.infoXpert_ServiceURL
    );

    // custom field
    const attactmentRecordTypesValueDisplayDetails: IRecordsTypes[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.infoXpert_AttachmentRecordTypeName}`
      ) ?? [];
    const communicationRecordTypesValueDisplayDetails: IRecordsTypes[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.infoXpert_CommunicationRecordType}`
      ) ?? [];
    const categoryValueDisplayDetails: CoreKeyword[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.infoXpert_CommunicationDefaultDocumentCategory}`
      ) ?? [];

    const handleOnChangeAttactmentRecordTypes = (type: IRecordsTypes) => {
      onChange(fieldInfoXpertAttachmentRecordType?.FieldName ?? "", {
        value: type.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.infoXpert_AttachmentRecordTypeName}`,
        {
          value: [type],
        }
      );
    };
    const handleOnChangeCommunicationRecordTypes = (type: IRecordsTypes) => {
      onChange(fieldInfoXpertCommunicationRecordType?.FieldName ?? "", {
        value: type.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.infoXpert_CommunicationRecordType}`,
        {
          value: [type],
        }
      );
    };
    const handleOnChangeCategory = (coreKeyword: CoreKeyword) => {
      onChange(fieldDocumentCategoryInfoExpert?.FieldName ?? "", {
        value: coreKeyword.Keyword_ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.infoXpert_CommunicationDefaultDocumentCategory}`,
        {
          value: [coreKeyword],
        }
      );
    };

    return (
      <>
        {fieldInfoXpertUsername && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertUsername}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldInfoXpertPassword && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertPassword}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldInfoXpertRoot && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertRoot}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldInfoXpertFolderURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertFolderURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldInfoXpertDocumentURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertDocumentURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldInfoXpertServiceURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertServiceURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {/* InfoXpertAttachmentRecordType Keyword picker */}
        {fieldInfoXpertAttachmentRecordType && (
          <div className="cc-field">
            <CCLabel
              title={fieldInfoXpertAttachmentRecordType?.Title ?? ""}
              isMandatory={fieldInfoXpertAttachmentRecordType.IsMandatory}
            />
            <Field
              name={fieldInfoXpertAttachmentRecordType?.FieldName ?? ""}
              placeholder={fieldInfoXpertAttachmentRecordType?.Title ?? ""}
              value={attactmentRecordTypesValueDisplayDetails[0] ?? null}
              component={RecordsTypesPicker}
              recordsTypeListData={
                fieldInfoXpertAttachmentRecordType?.ValueDisplayDetails &&
                fieldInfoXpertAttachmentRecordType?.ValueDisplayDetails.length >
                  0
                  ? fieldInfoXpertAttachmentRecordType?.ValueDisplayDetails
                  : []
              }
              disabled={!isEditing}
              selectableMode="single"
              onPickTypes={handleOnChangeAttactmentRecordTypes}
              textField={nameOfRecordType("Name")}
              textProduce={(data: IRecordsTypes) => data.Name}
            />
          </div>
        )}

        {fieldInfoXpertSaveCommunication && (
          <div className="cc-form-cols-3">
            <SwitchGlobalSettings
              data={fieldInfoXpertSaveCommunication}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
        )}

        {fieldInfoXpertCommunicationRecordType &&
          valueGetter(fieldInfoXpertSaveCommunication?.FieldName ?? "") && (
            <div className="cc-field">
              <CCLabel
                title={fieldInfoXpertCommunicationRecordType?.Title ?? ""}
                isMandatory={fieldInfoXpertCommunicationRecordType.IsMandatory}
              />
              <Field
                name={fieldInfoXpertCommunicationRecordType?.FieldName ?? ""}
                placeholder={fieldInfoXpertCommunicationRecordType?.Title ?? ""}
                value={communicationRecordTypesValueDisplayDetails[0] ?? null}
                component={RecordsTypesPicker}
                recordsTypeListData={
                  fieldInfoXpertCommunicationRecordType?.ValueDisplayDetails &&
                  fieldInfoXpertCommunicationRecordType?.ValueDisplayDetails
                    .length > 0
                    ? fieldInfoXpertCommunicationRecordType?.ValueDisplayDetails
                    : []
                }
                disabled={!isEditing}
                selectableMode="single"
                onPickTypes={handleOnChangeCommunicationRecordTypes}
                textField={nameOfRecordType("Name")}
                textProduce={(data: IRecordsTypes) => data.Name}
              />
            </div>
          )}

        {fieldDocumentCategoryInfoExpert && (
          <div className="cc-field">
            <CCLabel
              title={fieldDocumentCategoryInfoExpert?.Title ?? ""}
              isMandatory={fieldDocumentCategoryInfoExpert.IsMandatory}
            />
            <Field
              name={fieldDocumentCategoryInfoExpert?.FieldName ?? ""}
              placeholder={fieldDocumentCategoryInfoExpert?.Title ?? ""}
              value={categoryValueDisplayDetails[0] ?? null}
              component={KeyWordsPicker}
              keyWordType={KEYWORD_TYPE.Core_DocumentCategory}
              productType={PRODUCT_TYPE_NUMBER.Core}
              onPickKeyWords={handleOnChangeCategory}
              isGetDetail={false}
              selectableMode={"single"}
              disabled={!isEditing}
              textField={nameOfCoreKeyword("Name")}
              textProduce={(data: CoreKeyword) => data.Name}
              validator={
                fieldDocumentCategoryInfoExpert.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )}

        {fieldInfoXpertAttachmentTitleFormat && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldInfoXpertAttachmentTitleFormat}
              isEditing={isEditing}
            />
          </div>
        )}
      </>
    );
  }
);
