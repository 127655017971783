import {
  DTO_DeferredDuty_Account_Transaction,
  DTO_Transaction_Grouping,
} from "@app/products/property/deferred-duty/model";
import { getDeferredDutyTransactions } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/api";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class DeferredDutyTransactionStore {
  private _deferredDutyTransaction?: DTO_Transaction_Grouping = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _listSelectedTransaction: DTO_DeferredDuty_Account_Transaction[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get deferredDutyTransaction() {
    return this._deferredDutyTransaction;
  }
  setDeferredDutyTransaction = (
    deferredDutyTransaction?: DTO_Transaction_Grouping
  ) => {
    runInAction(() => {
      this._deferredDutyTransaction = deferredDutyTransaction;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get listSelectedTransaction() {
    return this._listSelectedTransaction;
  }
  setListSelectedTransaction = (
    selectedTransaction: DTO_DeferredDuty_Account_Transaction[]
  ) => {
    runInAction(() => {
      this._listSelectedTransaction = selectedTransaction;
    });
  };

  get gridSelectedTransactionIds(): number[] {
    let listSelected: any = [...this._listSelectedTransaction];
    listSelected =
      listSelected?.map(
        (item: DTO_DeferredDuty_Account_Transaction) =>
          item["AccountTransactionId"]
      ) ?? [];
    return toJS(listSelected);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._deferredDutyTransaction = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._listSelectedTransaction = [];
    });
  };

  loadDeferredDutyTransaction = async (
    deferredDutyAccountId: number,
    showCancelled?: boolean
  ) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let newDeferredDutyTransaction = undefined;
    const response = await getDeferredDutyTransactions(
      deferredDutyAccountId,
      showCancelled
    );
    if (isSuccessResponse(response)) {
      newDeferredDutyTransaction = response.data?.DeferredDutyTransactions;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setDeferredDutyTransaction(newDeferredDutyTransaction);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
}

const deferredDutyTransactionStoreContext = createContext(
  new DeferredDutyTransactionStore()
);
export const useDeferredDutyTransactionStore = () => {
  return useContext(deferredDutyTransactionStoreContext);
};
