export const mockInspections = [
  {
    InspectionDate: new Date("7-Jan-2022 17:04"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("16-Jan-2022"),
    SpecificInstructions: "Clear Entire Property",
    Locality: "Battery Point",
    HouseNumber: "23",
    Street: "Albuera",
    StreetType: "Street",
    ParcelId: 231040,
    AssessmentNumber: "2214889",
    Owners: "I S Middleton & J M Middleton",
    Period: "2021/2022",
    InspectorUserId: "rhosking",
    CaseId: 210004,
    InspectionId: 210004,
  },
];
