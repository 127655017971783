import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colAssociations: IColumnFields[] = [
  {
    field: "Role",
    title: "Role",
  },
  {
    field: "Type",
    title: "Type",
  },
  {
    field: "ReferenceCards",
    title: "Reference Cards",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "Active",
    title: "Active?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
