import { eventEmitter } from "@/App";
import { ManagePageEventType } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/config";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { DTO_Workflow_ModifyEntity } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import {
  ResponseMessageWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IUpdateContactManagePageButton {
  isDisabled?: boolean;
}
export const UpdateContactManagePageButton = observer(
  ({ isDisabled = false }: IUpdateContactManagePageButton) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { pushNotification } = useCCAppNotificationStore();

    return (
      <>
        <CCNavButton
          title="Modify contact"
          disabled={isDisabled}
          onClick={() => {
            setListDialog([
              {
                type: WorkflowTypes.Modify_Entity,
                data: {},
                props: {
                  handleSubmitDialog: (
                    _data: DTO_Workflow_ModifyEntity,
                    res: ResponseMessageWorkflow<DTO_Workflow_ModifyEntity>
                  ) => {
                    const notification = {
                      title:
                        res?.Notification ??
                        "Modify contact was updated successfully.",
                      type: "success",
                    } as IAppNotificationItemAddProps;
                    eventEmitter.emit(ManagePageEventType.RefreshData, () =>
                      pushNotification(notification)
                    );
                  },
                  isShowNotificationFinish: false,
                },
              },
            ]);
          }}
        />
      </>
    );
  }
);
