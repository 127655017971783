import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { IStatusValues } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IBadgeDialog } from "@components/cc-dialog/model";
import { isNil } from "lodash";

export const getIsShowCancelWorkflowButton = (
  workflowStatusMode: IStatusValues,
  WorkflowStatus: number | undefined
) => {
  return (
    workflowStatusMode.isSaved ||
    workflowStatusMode.isParked ||
    workflowStatusMode.isIncomplete ||
    isNil(WorkflowStatus)
  );
};

export const getIsIncompleteMode = (workflowStatusMode: IStatusValues) => {
  return (
    workflowStatusMode.isSaved ||
    workflowStatusMode.isParked ||
    workflowStatusMode.isIncomplete
  );
};

export const getStatusBadge = (
  workflowStatusMode: IStatusValues,
  workflowDraftData?: VO_Workflow_Draft
) => {
  let statusBadge: IBadgeDialog[] | undefined;
  if (
    !isNil(workflowDraftData?.Workflow_Status_Name) &&
    (workflowStatusMode.isParked ||
      workflowStatusMode.isSaved ||
      workflowStatusMode.isIncomplete ||
      workflowStatusMode.isCancelled ||
      workflowStatusMode.isApproved)
  ) {
    statusBadge = [
      { content: workflowDraftData?.Workflow_Status_Name },
    ] as IBadgeDialog[];
  }
  return statusBadge;
};

export const getIsReadOnly = (workflowStatusMode: IStatusValues) => {
  return (
    workflowStatusMode.isApproved ||
    workflowStatusMode.isRejected ||
    workflowStatusMode.isCancelled
  );
};
export const getIsToBeApprovalMode = (workflowStatusMode: IStatusValues) => {
  return workflowStatusMode.isToBeApproved && workflowStatusMode.isCompleted;
};
