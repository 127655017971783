import { BuildingRegisterEnforcementFormElement } from "@app/products/building/registers/enforcements/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingRegistersEnforcementsStore } from "@app/products/building/registers/enforcements/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingRegisterEnforcementForm = observer(() => {
  const isNew = useIsNew();
  const {
    enforcements,
    createBuildingRegistersEnforcement,
    updateBuildingRegistersEnforcement,
  } = useBuildingRegistersEnforcementsStore();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createBuildingRegistersEnforcement(data);
    } else {
      updateBuildingRegistersEnforcement(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={enforcements}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegisterEnforcementFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
