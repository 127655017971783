export const ThemeType = {
  Default: "Ready",
  Ready: "Ready",
  Light: "Light",
  Dark: "Dark",
  Indigo: "Indigo",
};

/**
 * Use for the guidance tab in the right side bar - events - CRMS
 * src\app\products\crms\[id]\components\forms\components\reference-sidebar\guidance\util.ts
 */
export const scrollBarColorCRMSGuidanceTab = {
  [ThemeType.Dark]: {
    trackColor: "#444444", // $kendo-color-secondary color
    thumbColor: "rgba(255, 255, 255, 0.125)", // $border-color color
    thumbHoverColor: "#afafaf", // $disabled color
  },
  [ThemeType.Indigo]: {
    trackColor: "#465372", // $kendo-color-secondary color
    thumbColor: "hsla(0, 0%, 100%, 0.125)", // $border-color color
    thumbHoverColor: "#ebebeb", // $disabled color
  },
  [ThemeType.Light]: {
    trackColor: "#e4e7eb", // $kendo-color-secondary color
    thumbColor: "rgba(33, 37, 41, 0.125)", // $border-color color
    thumbHoverColor: "#757575", // $disabled color
  },
  [ThemeType.Default]: {
    trackColor: "#ededed", // $kendo-color-secondary color
    thumbColor: "rgba(33, 37, 41, 0.125)", // $border-color color
    thumbHoverColor: "#757575", // $disabled color
  },
};
