import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCSlideActionBarStore } from "@components/cc-slide-action-bar/store";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

export const ToggleSlideActionBarButton = observer(() => {
  const { isActive, setIsActive } = useCCSlideActionBarStore();

  return (
    <CCNavButton
      title={"Toggle Slide Action Bar"}
      iconClass={"fal fa-ellipsis-v"}
      isActive={isActive}
      className="cc-toggle-slide-action-bar-button"
      onClick={() => {
        setIsActive(!isActive);
      }}
    />
  );
});
