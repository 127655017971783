import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { CRMSServiceStandardForm } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/_index";
import {
  NewServiceStandardCloneParams,
  NewServiceStandardFromSubCategoryParams,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const NewCRMSServiceStandardComponent = observer(() => {
  const {
    isLoading,
    crmsServiceStandard,
    serviceStandardLovs,
    onSubmit,
    loadCRMSServiceStandard,
  } = useCRMSServiceStandardStore();

  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();

  const { state } = useLocation<{
    cloneNew?: NewServiceStandardCloneParams;
    dataFromSubCategory?: NewServiceStandardFromSubCategoryParams;
  }>();
  const newServiceStandardCloneState = state?.cloneNew;
  const dataFromSubCategory = state?.dataFromSubCategory;
  useEffectOnce(() => {
    if (newServiceStandardCloneState && newServiceStandardCloneState?.isClone) {
      loadCRMSServiceStandard(
        parseInt(id),
        isNew,
        newServiceStandardCloneState
      );
    } else if (dataFromSubCategory) {
      loadCRMSServiceStandard(
        parseInt(id),
        isNew,
        undefined,
        dataFromSubCategory
      );
    } else {
      loadCRMSServiceStandard(parseInt(id), isNew);
    }

    return () => {
      window.history.replaceState({}, "");
    };
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Service Standard"} />
      <FormTitle title={"New Service Standard"} />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Save"}
            onClick={onSubmit}
            name={ActionSubmitActions.Save}
          />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            {serviceStandardLovs && crmsServiceStandard && (
              <CRMSServiceStandardForm />
            )}
          </div>
        </div>
      </div>
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewCRMSServiceStandardComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.CustomerService },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_ServiceStandard,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewCRMSServiceStandard = renderWithPermissionsWrapper(renderProps);
