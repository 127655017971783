export const readingInstructionsData = [
  { Key: 1, Value: "amongst landscaping" },
  { Key: 2, Value: "Beware dog on premises, keep gates closed" },
  { Key: 3, Value: "inground meter" },
  { Key: 4, Value: "Lefthand boundary" },
  { Key: 5, Value: "Locked Gate" },
  { Key: 6, Value: "near centre of front boundary" },
  { Key: 7, Value: "Righthand boundary" },
  { Key: 8, Value: "vacant block" },
];

export const readingRouteData = [
  { Key: 1, Value: "Active Meters not in Route Airlie" },
  { Key: 2, Value: "Cannonvale 1" },
  { Key: 3, Value: "Cannonvale 2," },
  { Key: 4, Value: "Cannonvale 3" },
  { Key: 5, Value: "Cannonvale 4" },
  { Key: 6, Value: "Jubilee 1" },
  { Key: 7, Value: "Jubilee 2" },
  { Key: 8, Value: "Meters not being read" },
  { Key: 9, Value: "Mt Julian - Shute Harbour Rd" },
  { Key: 1, Value: "Proserpine 1" },
  { Key: 1, Value: "Proserpine 2" },
  { Key: 1, Value: "Proserpine 3" },
  { Key: 1, Value: "Proserpine 4" },
  { Key: 1, Value: "Proserpine 5" },
  { Key: 1, Value: "Shute Harbour" },
];
