import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent, SwitchProps } from "@progress/kendo-react-inputs";
import React from "react";

export interface ISwitchGlobalSettings extends SwitchProps {
  data: SettingField | undefined;
  isEditing: boolean;
  isFieldVisible?: boolean;
  formRenderProps: FormRenderProps | undefined;
  customTitle?: string;
  customChecked?: boolean;
  customOnChange?: (event: SwitchChangeEvent) => void;
}

export const SwitchGlobalSettings = ({
  data,
  isEditing,
  isFieldVisible = true,
  formRenderProps,
  disabled,
  customTitle,
  customChecked,
  customOnChange,
  ...others
}: ISwitchGlobalSettings) => {
  return data && isFieldVisible ? (
    <div className="cc-field">
      <label className="cc-label">
        {customTitle ?? data?.Title ?? ""}
        {data?.IsMandatory && <CCTooltip type="validator" position="right" />}
        {data?.HelpText && (
          <CCTooltip
            type="custom"
            content=" "
            position="auto"
            clickToOpen
            customTemplate={<>{sanitizeHtml(data.HelpText)}</>}
          >
            <i className="fa fa-info-circle ml-1 text-accent" />
          </CCTooltip>
        )}
      </label>
      {data?.Description && (
        <label className="cc-settings-description">
          {sanitizeHtml(data?.Description)}
        </label>
      )}
      <Field
        {...others}
        name={data?.FieldName}
        component={CCSwitch}
        disabled={disabled || !isEditing}
        checked={customChecked ?? formRenderProps?.valueGetter(data?.FieldName)}
        onChange={(event: SwitchChangeEvent) => {
          if (customOnChange) {
            customOnChange(event);
          } else {
            formRenderProps?.onChange(data?.FieldName, { value: event.value });
          }
        }}
      />
    </div>
  ) : null;
};
