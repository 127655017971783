import { useBuildingRegistersEnquiryStore } from "@app/products/building/registers/enquiries/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedBuildingRegistersEnquiry } from "./components/forms/existed/_index";
import { NewBuildingRegistersEnquiry } from "./components/forms/new/_index";

const ManageBuildingRegistersEnquiry = observer(() => {
  const isNew = useIsNew();

  const { id } = useParams<{ id: string }>();
  const { resetStore, loadBuildingRegistersEnquiry } =
    useBuildingRegistersEnquiryStore();

  useEffect(() => {
    loadBuildingRegistersEnquiry(+id);
  }, [id, loadBuildingRegistersEnquiry, resetStore]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewBuildingRegistersEnquiry />;
  }
  return <ExistedBuildingRegistersEnquiry />;
});

export default ManageBuildingRegistersEnquiry;
