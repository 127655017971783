import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCentreBasedCareAllByPreferences: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ActivationDate,
    title: "Activation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.AgeAtActivation,
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Reg_Status, title: "Status" },
  {
    field: CRSFieldMapping.GroupsSelected,
    title: "Groups Sel",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  { field: CRSFieldMapping.Weighting, title: "Weighting" },
  {
    field: CRSFieldMapping.RegistrationDate,
    title: "Registered",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.SecondYearEntrolment,
    title: "2nd Yr Enrol",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  { field: CRSFieldMapping.FirstPref, title: "Preference 1" },
  { field: CRSFieldMapping.SecondPref, title: "Preference 2" },
  { field: CRSFieldMapping.ThirdPref, title: "Preference 3" },
  { field: CRSFieldMapping.FourPref, title: "Preference 4" },
  { field: CRSFieldMapping.FifthPref, title: "Preference 5" },
  { field: CRSFieldMapping.SixthPref, title: "Preference 6" },
  { field: CRSFieldMapping.SeventhPref, title: "Preference 7" },
  { field: CRSFieldMapping.EighthPref, title: "Preference 8" },
  { field: CRSFieldMapping.NinethPref, title: "Preference 9" },
  { field: CRSFieldMapping.TenthPref, title: "Preference 10" },
  { field: CRSFieldMapping.EleventhPref, title: "Preference 11" },
  { field: CRSFieldMapping.TwelfthPref, title: "Preference 12" },
  { field: CRSFieldMapping.ThirteenthPref, title: "Preference 13" },
  { field: CRSFieldMapping.FourteenthPref, title: "Preference 14" },
  { field: CRSFieldMapping.FifteenthPref, title: "Preference 15" },
];
