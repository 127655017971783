import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { AssessmentDebtRecoveryAssociationTab } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/associations/_index";
import { AssessmentDebtRecoveryDetailTab } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/detail/_index";
import { CandidatesButton } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/buttons/candidates/_index";
import { CreateDebtRecoveryExemptionButton } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/buttons/create-debt-recovery-exemption/_index";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { NewDebtRecoveryButton } from "@app/products/property/components/action-bar/button/new-debt-recovery/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getUUID } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import {
  colCandidatesAssessment,
  colCandidatesBalancesByRating,
  colCandidatesList,
} from "./config";
import { mockCandidatesList } from "./mock";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

//@Todo remove when have API
const CustomFields = "2016/2017";
const assessmentsColumnHiddenCustomField = colCandidatesAssessment.filter(
  (column: IColumnFields) => column.field !== CustomFields
);

const assessmentGridId = getUUID();
export default function CandidatesListView() {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewDebtRecoveryButton />
        <CreateDebtRecoveryExemptionButton />
      </CCNavButton>,
      <CandidatesButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Debt_Recovery}
        recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton gridId={assessmentGridId} />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDebtRecoveryDetailTab /> },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab componentNumber={eComponent.Debt_Recovery} />
        ),
      },
      {
        title: "Related",
        component: <AssessmentDebtRecoveryAssociationTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
          />
        ),
      },
    ],
  });

  const getAssessmentsColumn = (data: any[]): any => {
    const isShowCustomFields = data?.some((item) =>
      Object.keys(item).includes(CustomFields)
    );
    if (isShowCustomFields) return colCandidatesAssessment;
    return assessmentsColumnHiddenCustomField;
  };

  return (
    <CCProductListView
      primaryField="ID"
      columnFields={colCandidatesList}
      data={mockCandidatesList}
      detail={({ dataItem }: GridDetailRowProps) => {
        return (
          <CustomPanelBar
            expandMode="multiple"
            listPanelBar={[
              {
                title: "Assessments",
                component: (
                  <CCGrid
                    gridId={assessmentGridId}
                    primaryField="Id"
                    columnFields={getAssessmentsColumn(
                      dataItem.DetailAssessments
                    )}
                    data={dataItem.DetailAssessments ?? []}
                  />
                ),
              },
              {
                title: "Balances by Rating Period and Due Date",
                component: (
                  <CCGrid
                    primaryField="No"
                    columnFields={colCandidatesBalancesByRating}
                    data={dataItem.BalancesByRatingPeriodAndDueDate ?? []}
                  />
                ),
              },
            ]}
          />
        );
      }}
    />
  );
}
