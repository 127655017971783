import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterSystemAdminConditions: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.SortIndex,
    title: "Sort",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: WastewaterFieldMapping.Title,
    title: "Title",
  },
  {
    field: WastewaterFieldMapping.Condition,
    title: "Condition",
  },
];
