import {
  Ancestor,
  FurtherInformationRequest,
} from "@app/core/further-info/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";

export const getFurtherInfo = async (
  id: number
): Promise<APIResponse<FurtherInformationRequest | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<FurtherInformationRequest>(
      `api/core/internal/firequest/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getParentSummary = async (
  parentId: number,
  recordType: RECORDTYPE
): Promise<APIResponse<Ancestor | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Ancestor>(
      `api/core/internal/core/${parentId}/parentsummary?recordType=${recordType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putFurtherInfo = async (
  id: number,
  recordType: RECORDTYPE,
  furtherInfo: FurtherInformationRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  if (!furtherInfo.FurtherInformationRequest_ID) {
    furtherInfo.OriginalDateDue = furtherInfo.DateDue;
  }
  try {
    return await CoreAPIService.getClient().put<IdentityPacket>(
      `api/core/internal/firequest?id=${id}&recordType=${recordType}`,
      furtherInfo
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
