import { IEditGISReferenceEditDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/edit-gis-reference/components/dialogs/edit/models";
import { nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface EditGISReferenceEditDialogProps {
  onClose: () => void;
  initialValues?: IEditGISReferenceEditDialog;
  onSubmit: (value: any) => void;
  defaultNotifications: IAppNotificationItem[];
}

export const EditGISReferenceEditDialog = observer(
  ({
    onClose,
    initialValues,
    defaultNotifications,
    onSubmit,
  }: EditGISReferenceEditDialogProps) => {
    const nameOf = nameOfFactory<IEditGISReferenceEditDialog>();
    return (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, onSubmit } = formRenderProps;

          return (
            <FormElement>
              <CCDialog
                maxWidth={"25%"}
                height="auto"
                onClose={onClose}
                titleHeader={"Edit GIS Reference"}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification
                      defaultNotifications={defaultNotifications}
                    />
                    <div className="cc-field-group">
                      <div className="cc-field">
                        <label className="cc-label">
                          GIS reference
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("GISReference")}
                          component={CCInput}
                          placeholder="GIS reference"
                          validator={validateRequired}
                        />
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!valid}
                      themeColor="primary"
                      onClick={onSubmit}
                    >
                      Edit
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
