import { AnimalRenewalViewType } from "@app/products/animals/model";
import { ODATA_ANIMALS_RENEWALS_VIEW } from "@app/products/animals/renewals/all-by-due-status/constant";
import { isNil } from "lodash";

export const AnimalRenewalsAllByDueStatusBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animals - Renewals";
  },

  getBookmarkListViewDetail() {
    return "Animals - Renewals - All By Due Status";
  },
};

export const generateAnimalsRenewalsOdata = (
  viewEnum?: AnimalRenewalViewType
) => {
  if (isNil(viewEnum)) {
    return `${ODATA_ANIMALS_RENEWALS_VIEW}?$count=true&`;
  }
  return `${ODATA_ANIMALS_RENEWALS_VIEW}(viewEnum=${viewEnum})?$count=true&`;
};
