import { history } from "@/AppRoutes";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { SETTING_LOCAL_GOVERNMENT_ROUTE } from "@common/pages/settings/lookups/local-government/constants";
import {
  ILocalGovernmentParentSection,
  LocalGovernment,
  LocalGovernmentSubmitActions,
} from "@common/pages/settings/lookups/local-government/model";
import {
  getLocalGovernmentById,
  postLocalGovernment,
} from "@common/pages/settings/lookups/local-government/[id]/api";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class LocalGovernmentStore {
  private _localGovernment?: LocalGovernment = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _parentSection?: ILocalGovernmentParentSection = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: ILocalGovernmentParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get localGovernment() {
    return toJS(this._localGovernment);
  }
  setLocalGovernment = (localGovernment?: any) => {
    runInAction(() => {
      this._localGovernment = localGovernment;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._localGovernment = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  get localGovernmentId() {
    return toJS(this.localGovernment?.Council_ID);
  }

  reloadLocalGovernment = () => {
    if (this.localGovernmentId)
      this.loadLocalGovernment(this.localGovernmentId);
  };

  loadLocalGovernment = async (
    localGovernmentID?: number,
    isNew?: boolean
  ): Promise<boolean> => {
    if (this.parentSection?.notification) {
      this.parentSection?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
      this.setParentSection({ ...this._parentSection, notification: [] });
    }
    if (isNew || !localGovernmentID) {
      return true;
    }
    let errorResponse = undefined;
    let newLocalGovernment: LocalGovernment | undefined;
    this.setIsLoading(true);
    const response = await getLocalGovernmentById(localGovernmentID);
    if (isSuccessResponse(response) && response.data) {
      newLocalGovernment = response.data;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setLocalGovernment(newLocalGovernment);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveLocalGovernment = async (
    localGovernmentInfo: LocalGovernment,
    action: LocalGovernmentSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postLocalGovernment(localGovernmentInfo);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        await this.runActions(action, response);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Save local goverment failed",
          type: "error",
          description: response.data?.Errors ?? response?.statusText,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? response?.statusText,
        type: "error",
      });
    }
  };

  runActions = async (
    action: LocalGovernmentSubmitActions,
    response?: APIResponse
  ) => {
    switch (action) {
      case LocalGovernmentSubmitActions.Save:
        await this.reloadLocalGovernment();
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Local government saved successfully",
          type: "success",
        });
        break;
      case LocalGovernmentSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(
          `${SETTING_LOCAL_GOVERNMENT_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              { title: "Local Government saved successfully", type: "success" },
            ],
          }
        );
        break;
      default:
        break;
    }
    this.setIsLoading(false);
  };
}

export const localGovernmentStore = new LocalGovernmentStore();
const LocalGovernmentStoreContext = createContext(localGovernmentStore);
export const useLocalGovernmentStore = () => {
  return useContext(LocalGovernmentStoreContext);
};
