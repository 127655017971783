import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import {
  NumericTextBoxBlurEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const SortIndexCell = observer(
  ({
    value,
    onChange,
    dataIndex,
    dataItem,
    field,
    format = DECIMAL_FORMAT.DECIMAL_2_DIGIT,
  }: ICustomEditCell) => {
    const { onUpdateQuestion } = useDynamicQuestionListsStore();
    const [indexValue, setIndexValue] = useState(value);
    const [isPressEnter, setIsPressEnter] = useState(false);

    const keydownEnterHandler = (e: any) => {
      if (e.key === "Enter") {
        setIsPressEnter(true);
      }
    };

    useEffect(() => {
      document
        .getElementById("cc-sort-index")
        ?.addEventListener("keydown", keydownEnterHandler);
      return () => {
        document
          .getElementById("cc-sort-index")
          ?.removeEventListener("keydown", keydownEnterHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (field && onUpdateQuestion && isPressEnter) {
        onUpdateQuestion(dataItem, field, indexValue);
        setIsPressEnter(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPressEnter]);

    return (
      <CCNumericTextBox
        id="cc-sort-index"
        value={value}
        format={format}
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (onChange) {
            let newValue = event.value ?? 0;
            setIndexValue(newValue);
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: newValue,
            });
          }
        }}
        onBlur={(event: NumericTextBoxBlurEvent) => {
          if (field && onUpdateQuestion && !isNull(event.target.value))
            onUpdateQuestion(dataItem, field, event.target.value);
        }}
      />
    );
  }
);
