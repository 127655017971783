import { getPropertyParcelEntities } from "@app/products/property/parcels/[id]/components/child-screens/entities/api";
import { DTO_Entity } from "@app/products/property/parcels/[id]/components/child-screens/entities/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ParcelEntitiesStore {
  private _parcelEntities?: DTO_Entity[] = [];
  private _isLoading: boolean = false;
  private _includePastNames: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get parcelEntities() {
    return this._parcelEntities;
  }

  setParcelEntities = (parcelEntities?: DTO_Entity[]) => {
    runInAction(() => {
      this._parcelEntities = parcelEntities;
    });
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get includePastNames() {
    return this._includePastNames;
  }

  setIncludePastNames = (includePastNames: boolean) => {
    runInAction(() => {
      this._includePastNames = includePastNames;
    });
  };

  //Action
  loadParcelEntities = async (
    parcelsId: number,
    includeInactive: boolean = false
  ) => {
    this.setIsLoading(true);
    const parcel = await getPropertyParcelEntities(parcelsId, includeInactive);
    this.setParcelEntities(parcel?.Entities ?? []);
    this.setIsLoading(false);
  };

  resetStore = () => {
    this.setIncludePastNames(false);
    this.setParcelEntities([]);
    this.setIsLoading(false);
  };
}

const parcelEntitiesStoreContext = createContext(new ParcelEntitiesStore());
export const useParcelEntitiesStore = () => {
  return useContext(parcelEntitiesStoreContext);
};
