import {
  IBalanceAdjustment,
  INewJournal,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/model";

export const mockNewJournal: INewJournal = {
  Number: 22377,
  Description: "Adjust Account Balances",
};

export const mockBalanceAdjustment: IBalanceAdjustment = {
  TransactionDate: new Date("11-Feb-2022 15:15"),
  ExistingBalance: 12,
};
