import { DTO_ChargeRun_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getChargeRunSummaryById = async (
  id: number
): Promise<APIResponse<DTO_ChargeRun_Summary | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChargeRun_Summary>(
      `api/property/internal/chargerun/${id}/summary`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
