import { PropertyPicFormElement } from "@app/products/property/pic/[id]/components/child-screens/general/components/form-element/_index";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const PropertyPicForm = observer(() => {
  const { pic } = usePicStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={pic}
        key={JSON.stringify(pic)}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyPicFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
