import { KeywordLite } from "@common/models/keyword";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<KeywordLite>();

export const colPprKeywords: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: KeywordLite) =>
      `${SETTINGS_LOOKUPS_KEYWORDS_ROUTE}/${dataItem.Keyword_ID}`,
  },
  {
    field: nameOf("KeywordType_Name"),
    title: "Type",
  },
];
