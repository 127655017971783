import { ICCRoute } from "@common/constants/ICCRoute";

export const adminRoute: ICCRoute = {
  path: "admin",
  name: "Admin",
  children: [
    {
      path: "master-facilities",
      name: "Master Facilities",
      component: require("./master-facilities/_index").default,
    },
    {
      path: "facilities",
      name: "Facilities",
      component: require("./facilities/_index").default,
    },
    {
      path: "event-types",
      name: "Event Types",
      component: require("./event-types/_index").default,
    },
    {
      path: "facility-features",
      name: "Facility Features",
      component: require("./facility-features/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
  ],
};
