import { loadAllAnimals } from "@app/products/animals/api";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { AnimalEAnimalsNewApplicationsBookmark } from "@app/products/animals/e-animals/new-applications/util";
import { AnimalBookmark } from "@app/products/animals/util";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import React from "react";
import { eAnimalsRoute } from "../route";
import { colAnimalseAnimalsNewApplications } from "./config";

export default () => {
  useCCListViewActionBar({
    title: eAnimalsRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={eAnimalsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        //TODO don't have exactly record type, temporary use Animals_Registration
        recordType={RECORDTYPE.Animals_Registration}
        detail={AnimalBookmark.getBookmarkDetail}
        displayName={AnimalBookmark.getBookmarkDisplayName}
        listViewDetail={
          AnimalEAnimalsNewApplicationsBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalEAnimalsNewApplicationsBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  return (
    <CCProductListView
      columnFields={colAnimalseAnimalsNewApplications}
      data={loadAllAnimals() as any}
      // dataUrl={AnimalsApiUrl.GET_VIEW_ANIMALS_EANIMALS_NEWAPPLICATIONS}
      primaryField="ID"
    />
  );
};
