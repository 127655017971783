import { IAddSubMeterData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/add-master-sub-meter/model";
import { AddMasterSubMeterDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/add-master-sub-meter/_index";
import { AssociationMeter } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const AddMasterSubMeterButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { addAssociationMeter, associationMeter } = useMeterInstallationStore();
  const { pushNotification } = useCCAppNotificationStore();
  const handleSubmit = (values: IAddSubMeterData) => {
    if (!values.MeterNo) return;
    setShowDialog(false);
    if (
      associationMeter.some(
        (meter) => meter.MeterNo === values.MeterNo && !meter.AssociatedTo
      )
    ) {
      pushNotification({
        title: `Meter ${values.MeterNo} is existing`,
        type: "warning",
      });
      return;
    }

    addAssociationMeter({
      ...values,
      Installed: new Date(),
      LastReadOn: new Date(),
      CreatedOn: new Date(),
      CreatedBy: "community_central_dev",
    } as AssociationMeter);
    pushNotification({
      title: `Add ${values.MasterSub?.toLowerCase()} successfully`,
      type: "success",
    });
  };
  return (
    <>
      <CCNavButton
        title={"Add"}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <AddMasterSubMeterDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
});
