import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  DTO_DeferredDuty_AccountLookup,
  DTO_DeferredDuty_AccountLookupObj,
} from "./model";

export const getSearchDeferredDutyAccount = async (
  searchData: DTO_DeferredDuty_AccountLookupObj
): Promise<APIResponse<DTO_DeferredDuty_AccountLookup>> => {
  try {
    return await CoreAPIService.getClient().post<DTO_DeferredDuty_AccountLookup>(
      `api/property/internal/deferredduty/account/lookup`,
      searchData
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
