import { RegionFormElement } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/child-screens/general/components/form-element/_index";
import { useRegionStore } from "@app/products/town-planning/ppr/system-admin/regions/[id]/store";
import { Region } from "@app/products/town-planning/ppr/[id]/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const RegionForm = observer(() => {
  const { region, saveRegion } = useRegionStore();
  const { pushNotification } = useCCAppNotificationStore();
  // Use for integrate API
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;

    if (!isValid) return;
    if (!isModified)
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const region = values as Region;
    saveRegion(region);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={region}
        key={JSON.stringify(region)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <RegionFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
