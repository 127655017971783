import { DTO_DebtRecovery_Candidates } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/candidates/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getIdentifyCandidates = async (): Promise<
  APIResponse<DTO_DebtRecovery_Candidates | undefined>
> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_DebtRecovery_Candidates>(
        `api/property/internal/debtrecovery/candidate/options`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
