import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_Profile_VerifyResult } from "@common/pages/profile/model";

export const getVerifyCredential = async (
  userID: number,
  userEmail: string
): Promise<APIResponse<Svc_Profile_VerifyResult | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/profile/verify-credential?ID=${userID}&userEmail=${userEmail}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
