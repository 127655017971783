import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import {
  formatBooleanValue,
  formatDateByKendo,
  formatIconValue,
  formatNumberByKendo,
  formatStyleCurrency,
} from "@common/utils/formatting";

export const getValueFieldOptions = (
  format: any,
  value: any
): { formatValue: string; style: React.CSSProperties } => {
  if (
    Object.values(DATE_FORMAT).includes(format) ||
    Object.values(DATETIME_FORMAT).includes(format)
  ) {
    const formatValue = formatDateByKendo(value, format);
    return {
      formatValue,
      style: {},
    };
  }

  if (Object.values(NUMBER_FORMAT).includes(format)) {
    const formatValue = formatNumberByKendo(value, format);
    return {
      formatValue,
      style: {},
    };
  }

  if (Object.values(CURRENCY_FORMAT).includes(format)) {
    const formatValue = formatNumberByKendo(value, format);
    const defaultStyle = formatStyleCurrency(value);
    return {
      formatValue,
      style: { ...defaultStyle },
    };
  }

  if (Object.values(PERCENTAGE_FORMAT).includes(format)) {
    const formatValue = formatNumberByKendo(value, format);

    return {
      formatValue,
      style: {},
    };
  }

  if (Object.values(ICON_FORMAT).includes(format)) {
    const formatValue = formatIconValue(value, format);
    return {
      formatValue,
      style: {},
    };
  }

  if (Object.values(BOOLEAN_FORMAT).includes(format)) {
    const formatValue = formatBooleanValue(value, format);
    return {
      formatValue,
      style: {},
    };
  }
  return {
    formatValue: value,
    style: {},
  };
};
