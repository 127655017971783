import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import {
  loadNewMeter,
  loadToCheckNewMeter,
} from "@app/products/property/meters/list/components/action-bar/dialogs/new-meter/api";
import { INewMeter } from "@app/products/property/meters/list/components/action-bar/dialogs/new-meter/model";
import { APIResponseError } from "@common/apis/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<INewMeter>();

interface INewMeterDialogProps {
  onClose: () => void;
}

export const NewMeterDialog = ({ onClose }: INewMeterDialogProps) => {
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [initialValues, setInitialValues] = useState<INewMeter | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const onResetRef = useRef<any>();

  const loadInitialValue = () => {
    setIsLoading(true);
    loadNewMeter().then((data) => {
      setInitialValues({
        ...data,
      });
      if (onResetRef.current) onResetRef.current();
      setResponseLoadError(
        !data
          ? {
              status: APIResponseStatus.NOT_FOUND,
            }
          : undefined
      );
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadInitialValue();
  });

  const createNewMeter = async (data: any) => {
    const result = await loadToCheckNewMeter();
    if (data.MeterNumber === result) {
      return true;
    } else {
      notificationRef.current?.pushNotification({
        title: "This meter number already existed",
        type: "warning",
      });
      return false;
    }
  };

  const handleSubmit = (data: any, event: any) => {
    if (event.target.name === "CreateAndExit") {
      createNewMeter(data).then((result) => {
        if (result) {
          onClose();
          history.push(`${PROPERTY_METERS_ROUTE}/9999`);
        }
      });
    } else {
      createNewMeter(data).then((result) => {
        if (result) {
          notificationRef.current?.pushNotification({
            title: "New meter created successfully",
            type: "success",
          });
          loadInitialValue();
        }
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onChange, onSubmit, valid, onFormReset } =
          formRenderProps;
        onResetRef.current = onFormReset;
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="700px"
              titleHeader={"New Meter"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  {isLoading ? <Loading isLoading={isLoading} /> : null}
                  {responseLoadError && !isLoading ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={loadInitialValue}
                    />
                  ) : null}
                  <CCLocalNotification ref={notificationRef} />
                  {initialValues ? (
                    <section className="cc-field-group">
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">
                            Meter number
                            <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("MeterNumber")}
                            component={CCInput}
                            validator={requiredValidator}
                            placeholder="Meter number"
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Make</label>
                          <Field
                            name={nameOf("Make")}
                            data={valueGetter(nameOf("MakeList"))}
                            component={CCSearchComboBox}
                            textField="Value"
                            dataItemKey="Key"
                            value={getDropdownValue(
                              valueGetter("Make"),
                              valueGetter(nameOf("MakeList")) ?? [],
                              "Key"
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange("Make", {
                                value: event.target.value?.Key ?? null,
                              });
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Type
                            <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("Type")}
                            data={valueGetter(nameOf("TypeList"))}
                            validator={requiredValidator}
                            component={CCSearchComboBox}
                            textField="Value"
                            dataItemKey="Key"
                            value={getDropdownValue(
                              valueGetter("Type"),
                              valueGetter(nameOf("TypeList")) ?? [],
                              "Key"
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange("Type", {
                                value: event.target.value?.Key ?? null,
                              });
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Purchase date
                            <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("PurchaseDate")}
                            format={DATE_FORMAT.DATE_CONTROL}
                            component={CCDatePicker}
                            validator={requiredValidator}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Number of dials
                            <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("NumberOfDials")}
                            component={CCNumericTextBox}
                            validator={requiredValidator}
                            placeholder="Number of dials"
                            min={1}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Meter size
                            <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("MeterSize")}
                            data={valueGetter(nameOf("MeterSizeList"))}
                            validator={requiredValidator}
                            component={CCSearchComboBox}
                            textField="Value"
                            dataItemKey="Key"
                            value={getDropdownValue(
                              valueGetter("MeterSize"),
                              valueGetter(nameOf("MeterSizeList")) ?? [],
                              "Key"
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange("MeterSize", {
                                value: event.target.value?.Key ?? null,
                              });
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Unit of measurement
                            <TooltipValidator />
                          </label>
                          <Field
                            name={nameOf("UnitOfMeasurement")}
                            data={valueGetter(nameOf("UnitOfMeasurementList"))}
                            validator={requiredValidator}
                            component={CCSearchComboBox}
                            textField="Value"
                            dataItemKey="Key"
                            value={getDropdownValue(
                              valueGetter(nameOf("UnitOfMeasurement")),
                              valueGetter(nameOf("UnitOfMeasurementList")) ??
                                [],
                              "Key"
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange(nameOf("UnitOfMeasurement"), {
                                value: event.target.value?.Key ?? null,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  ) : null}
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    name="CreateAndNew"
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!valid}
                  >
                    Create & New
                  </Button>
                  <Button
                    name="CreateAndExit"
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!valid || !initialValues}
                  >
                    Create & Exit
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
