import { PlansToComplyButton } from "@app/products/town-planning/ppr/[id]/components/buttons/plans-to-comply/_index";
import { AllocateToOfficerButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/allocate-to-officer/_index";
import { AmendApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-application/_index";
import { ApproveReportAndResponseButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/approve-report-and-response/_index";
import { CreateApplicationReportButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/create-application-report/_index";
import { CreateResponseLetterButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/create-response-letter/_index";
import { FiNotRequiredButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/fi-not-required/_index";
import { LodgeApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/lodge-application/_index";
import { NoAppealButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/no-appeal/_index";
import { OfficerAssessmentButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/officer-assessment/_index";
import { PermitCompletedButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/permit-completed/_index";
import { ReportAndResponseNotApprovedButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/report-and-response-not-approved/_index";
import { SendReptAndRespLetterForApprovalButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-rept-and-resp-letter-for-approval/_index";
import { WithDrawApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/withdraw-application/_index";
import { PPRForm } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/_index";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewPPR = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();

  const { isLoading, responseLoadError, onSubmit, loadPPR, ppr } =
    usePPRStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Application"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPPR(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title="New Application" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <AmendApplicationButton />
                <ApproveReportAndResponseButton />
                <LodgeApplicationButton />
                <OfficerAssessmentButton />
                <ReportAndResponseNotApprovedButton />
                <WithDrawApplicationButton />
                <SendReptAndRespLetterForApprovalButton />
                <FiNotRequiredButton />
                <NoAppealButton />
                <PlansToComplyButton isPPRWorkflow={true} />
                <AllocateToOfficerButton />
                <CreateApplicationReportButton />
                <CreateResponseLetterButton />
                <PermitCompletedButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={PPRSubmitActions.New}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">{ppr && <PPRForm />}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
