import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { CCEditor } from "@components/cc-editor/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { EditorChangeEvent } from "@progress/kendo-react-editor";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IExportRegisterSettingsFormProps = {
  formRenderProps: FormRenderProps;
};

export const ExportRegisterSettingsForm = observer(
  ({ formRenderProps }: IExportRegisterSettingsFormProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { configData = {} } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    //#region  fields
    const fieldExportRegisterEnabled =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_Enable.toString()
      ];
    const fieldExportRegisterMaximumRecords =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_MaxiumnRecords.toString()
      ];
    const fieldExportRegisterPublishDisclaimer =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_Flag_ShowWarning.toString()
      ];
    const fieldExportWarningText =
      configData[
        ECorporateSettingsField.TP_PublicRegister_Export_WarningText.toString()
      ];
    //#endregion

    //#region boolean value
    const isExportRegisterEnabled = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_Export_Enable.toString()
    );
    const isExportRegisterPublishDisclaimer = valueGetter(
      ECorporateSettingsField.TP_PublicRegister_Export_Flag_ShowWarning.toString()
    );
    //#endregion

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={fieldExportRegisterEnabled?.Title ?? ""} />
            <Field
              name={fieldExportRegisterEnabled?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isExportRegisterEnabled}
            />
          </div>
          {isExportRegisterEnabled && (
            <>
              <div className="cc-field">
                <CCLabel
                  title={fieldExportRegisterMaximumRecords?.Title ?? ""}
                  isMandatory={fieldExportRegisterMaximumRecords.IsMandatory}
                />
                <Field
                  name={fieldExportRegisterMaximumRecords?.FieldName}
                  component={CCNumericTextBox}
                  disabled={!isEditing}
                  validator={
                    fieldExportRegisterMaximumRecords?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldExportRegisterPublishDisclaimer?.Title ?? ""}
                />
                <Field
                  name={fieldExportRegisterPublishDisclaimer?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={isExportRegisterPublishDisclaimer}
                />
              </div>
              <div className="cc-field cc-col-span-3">
                <CCLabel
                  title={fieldExportWarningText?.Title ?? ""}
                  isMandatory={fieldExportWarningText.IsMandatory}
                />
                <Field
                  name={fieldExportWarningText?.FieldName}
                  component={CCEditor}
                  disabled={!isEditing}
                  validator={
                    fieldExportWarningText?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                  onChange={(event: EditorChangeEvent) => {
                    onChange(fieldExportWarningText.FieldName, {
                      value: event.html,
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="cc-form-cols-1"></div>
      </section>
    );
  }
);
