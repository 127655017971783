import { DTO_Parcel_Zones } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getParcelZones = async (
  parcelId: number
): Promise<APIResponse<DTO_Parcel_Zones | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Parcel_Zones>(
      `/api/property/internal/parcel/${parcelId}/zones`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;

    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
