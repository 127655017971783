export enum EKeysOfUpdateEntitySteps {
  Details = "Details",
  Person = "EntityDetails",
  PostalAddress = "PostalAddress",
  PhysicalAddress = "PhysicalAddress",
  Attributes = "Attributes",
  Associations = "Associated_Entities",
  ConcessionCards = "Concession_Cards",
  Voting = "Voting",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}
