import { BuildingRegisterEnforcementForm } from "@app/products/building/registers/enforcements/[id]/components/child-screens/general/_index";
import { useBuildingRegistersEnforcementsStore } from "@app/products/building/registers/enforcements/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import React from "react";

export const NewManageBuildingRegisterEnforcement = () => {
  const { isLoading } = useBuildingRegistersEnforcementsStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Enforcement"} />
      <FormTitle title={"New Building Enforcement"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Building officer assessment"} />
            <CCNavButton title={"Permit lapsed"} />
            <CCNavButton title={"Practitioners"} />
            <CCNavButton title={"Withdraw application"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Document"} />
            <CCNavButton title={"Fee"} />
            <CCNavButton title={"Inspection"} />
            <CCNavButton title={"Sample"} />
          </CCNavButton>,

          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
          <CCNavButton title={"More Options"} type="more">
            <CCNavButton title={"Same application"} />
          </CCNavButton>,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<BuildingRegisterEnforcementForm />} />
          </div>
        </div>
      </div>
    </>
  );
};
