import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `PPR - FI Request ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.ApplicationNumber,
    selectedRow.SiteAddress + ", " + selectedRow.Locality,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "PPR - FI Request";
};

export const getBookmarkListViewDetail = () => {
  return "PPR - FI Request - All";
};
