import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IContact } from "@common/pages/contacts/model";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

/* ===================== Recovery History  ===================== */
export const columnDebtRecoveryHistory: IColumnFields[] = [
  {
    field: "ID",
    title: "Debt Recovery Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "NameAndAddress", title: "Name and Address" },
  { field: "Assessments", title: "Assessment(s)" },
  { field: "Type", title: "Type" },
  { field: "Status", title: "Status" },
  {
    field: "DateFrom",
    title: "Date From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DateTo",
    title: "Date To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InitialBalance",
    title: "Initial Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InitialInterestBalance",
    title: "Initial Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InitialDebt",
    title: "Initial Debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InitialDebtIncludingCosts",
    title: "Initial Debt Including Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Total",
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "AssessmentBalance",
    title: "Assessment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "UpToDueDateTime",
    title: "Up To Due DateTime",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "AsAt",
    title: "As At",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ShowAlertOnAssessment",
    title: "Show Alert On Assessment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Officer",
    title: "Officer",
  },
  {
    field: "CreatedBy",
    title: "Created By",
  },
  {
    field: "CreatedOn",
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ChangedBy",
    title: "Changed By",
  },
  {
    field: "ChangedOn",
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
export const columnDebtRecoveryDetailHistory: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER1,
    style: formatStyleProminent,
  },
  {
    field: "AssessmentReference",
    title: "Assessment Reference",
    format: NUMBER_FORMAT.NUMBER1,
    style: formatStyleProminent,
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
    style: formatStyleProminent,
  },
  {
    field: "InitialCharges",
    title: "Initial Charges",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "InitialInterest",
    title: "Initial Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "TotalDebt",
    title: "Total Debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "DebtCostsBalance",
    title: "Debt+Costs Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "AssessmentBalance",
    title: "Assessment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "AssessmentId",
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
/* ===================== End Of Debt Recovery History  ===================== */
/* =====================  Property Associations  ===================== */
export const colAssociationsAssessment: IColumnFields[] = [
  {
    field: "Association",
    title: "Association",
  },
  {
    field: "BalanceOwing",
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("BalanceOwing", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    style: formatStyleProminent,
  },
  {
    field: "AssessmentReference",
    title: "Assessment Reference",
    style: formatStyleProminent,
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
    style: formatStyleProminent,
  },
  {
    field: "Ratepayer",
    title: "Ratepayer",
    style: formatStyleProminent,
  },
  {
    field: "LegalDescripttion",
    title: "Legal Description",
  },
  {
    field: "Balance",
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Balance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("BalanceOutstanding", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "BegingPostponed",
    title: "Beging Postponed",
  },
  {
    field: "Group",
    title: "Group",
  },
  {
    field: "ValuationGroup",
    title: "Valuation Group",
  },
  {
    field: "Type",
    title: "Type",
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "LandUser",
    title: "Land User",
  },
  {
    field: "AssessmentId",
    title: "Assessment Id",
  },
];

export const colAssociationsPacel: IColumnFields[] = [
  {
    field: "Association",
    title: "Association",
  },
  {
    field: "ParcelReference",
    title: "Parcel Reference",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "Locality",
    title: "Locality",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "CensusDistrict",
    title: "Census District",
  },
  {
    field: "ElectoralDivision",
    title: "Electoral Division",
  },
  {
    field: "StateCodeElectoralDivision",
    title: "State Code Electoral Division",
  },
  {
    field: "FederalElectoralDivision",
    title: "Federal Electoral Division",
  },
  {
    field: "LandArea",
    title: "Land Area",
  },
  {
    field: "Zones",
    title: "Zones",
  },
  {
    field: "LandUses",
    title: "Land Uses",
  },
  {
    field: "OwnersName",
    title: "Owners Name",
  },
  {
    field: "Status",
    title: "Status",
    format: NUMBER_FORMAT.NUMBER1,
    style: formatStyleUnRelevant,
  },
  {
    field: "ParcelId",
    title: "Parcel Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colAssociationsTitles: IColumnFields[] = [
  {
    field: "Association",
    title: "Association",
  },
  {
    field: "ReferenceNumber",
    title: "Reference Number",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Type",
    title: "Type",
  },
  {
    field: "Lot",
    title: "Lot",
  },
  {
    field: "PlanType",
    title: "Plan Type",
  },
  {
    field: "PlanNumber",
    title: "Plan Number",
  },
  {
    field: "Volume",
    title: "Volume",
  },
  {
    field: "Folio",
    title: "Folio",
  },
  {
    field: "County",
    title: "County",
  },
  {
    field: "Parish",
    title: "Parish",
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "LandArea",
    title: "Land Area",
  },
  {
    field: "IsAreaSurveyed",
    title: "Is Area Surveyed",
  },
  {
    field: "Coverage",
    title: "Coverage",
  },
  {
    field: "Owners",
    title: "Owners",
    format: NUMBER_FORMAT.NUMBER1,
    style: formatStyleUnRelevant,
  },
  {
    field: "Address",
    title: "Address",
    format: NUMBER_FORMAT.NUMBER1,
    style: formatStyleUnRelevant,
  },
  {
    field: "Locality",
    title: "Locality",
    format: NUMBER_FORMAT.NUMBER1,
    style: formatStyleUnRelevant,
  },
  {
    field: "TitleId",
    title: "Title Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
/* =====================  / Property Associations  ===================== */
/* =====================  Voting  ===================== */

export const colVotingNominateProperty: IColumnFields[] = [
  {
    field: "EffectiveDate",
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "AssessmentId",
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "Id",
    title: "Nominated Property Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colVotingEntitlementByElection: IColumnFields[] = [
  {
    field: "Election",
    title: "Election",
  },
  {
    field: "CouldVote",
    title: "Could Vote?",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: "Role",
    title: "Role",
  },
  {
    field: "AssessmentId",
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    style: formatStyleProminent,
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
    style: formatStyleProminent,
  },
];

const nameOfContact = nameOfFactory<IContact>();
export const colContacts: IColumnFields[] = [
  {
    field: nameOfContact("Type"),
    title: "Type",
    locked: true,
    linkTo: (dataItem) => `/contacts/manageregistration/${dataItem.ID}`,
  },
  {
    field: nameOfContact("DisplayName"),
    title: "Display Name",
  },
  {
    field: nameOfContact("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOfContact("Mobile"),
    title: "Mobile",
  },
  {
    field: nameOfContact("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfContact("Email"),
    title: "Email",
  },
];
