import { IInitialDisassociateMeter } from "@app/products/property/registers/[id]/components/dialogs/disassociate-meter/model";
import { FormValidatorType } from "@progress/kendo-react-form";
import { isBefore } from "date-fns";
import { isNull } from "lodash";

export const handleValidateDisassociateMeter: FormValidatorType = (
  data: IInitialDisassociateMeter
) => {
  const DateOfRemoval: Date = data.DateOfRemoval;

  let errorMessage = {};
  if (isNull(DateOfRemoval)) {
    errorMessage = {
      ...errorMessage,
      DateOfRemoval: " ",
    };
  }
  if (
    !isNull(DateOfRemoval) &&
    !isBefore(new Date(DateOfRemoval), new Date())
  ) {
    errorMessage = {
      ...errorMessage,
      DateOfRemoval: "Date of Removal must be equal or less than current date ",
    };
  }

  return errorMessage;
};
