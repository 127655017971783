import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  DTO_Certificate_LOVs,
  DTO_Workflow_CertificateReplace,
  RequestCertificateReplaceObj,
} from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/model";
import { APIResponse } from "@common/apis/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { ResponsePacket } from "@common/models/identityPacket";

export const postProcessReplaceCertificate = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_CertificateReplace
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CertificateReplace>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificatereplace/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialReplaceCertificate =
  | [
      APIResponse<DTO_Certificate_LOVs>,
      APIResponse<DTO_Workflow_CertificateReplace>
    ]
  | APIResponse<DTO_Certificate_LOVs | DTO_Workflow_CertificateReplace>
  | undefined;
export const getInitialReplaceCertificate = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  certificateId: number | undefined = 0
): Promise<IGetInitialReplaceCertificate> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `api/property/int/certificate/lovs?workflowType=${workflowType}`
      ),
      isFromActionList
        ? getWorkflowReplaceCertificate(workflowDraftId)
        : getNewWorkflowReplaceCertificate({ Certificate_Id: certificateId }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowReplaceCertificate = async (
  requestObj: RequestCertificateReplaceObj | object = {}
): Promise<APIResponse<DTO_Workflow_CertificateReplace>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificatereplace/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as APIResponse<ResponsePacket>;
    return {
      ...errorResponse,
      error: (errorResponse.data as ResponsePacket)?.Errors,
    };
  }
};

export const getWorkflowReplaceCertificate = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CertificateReplace>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `api/property/int/workflow/certificatereplace/${workflowDraftId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
