import { colTransactionType } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/components/form-elements/rebate-claim/config";
import { DTO_LOV_Transaction } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/model";
import { useCreateRebateClaimStore } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { addDays, subDays } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLOVTransaction = nameOfFactory<DTO_LOV_Transaction>();

const validator = (values: any) => {
  if (!values || !values?.TransactionTypes?.length) {
    return "Please include at least one transaction type.";
  }
  return undefined;
};

export const RebateClaimFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedTransactionTypes =
      getFieldValue("SelectedTransactionTypes") ?? [];

    //Use store
    const { createRebateClaimLOVs } = useCreateRebateClaimStore();
    /**
     * Handle grid selection change
     * @param dataItem
     * @param field
     */
    const handleGridSelectOnChange = (dataItem: any, field: string) => {
      if (dataItem) {
        onChange(nameOf(field), {
          value: dataItem,
        });
      }
    };

    /**
     * handle transactions type info change
     * @param isInclude
     */
    const handleTransactionsTypeInfoChange = (isInclude: boolean) => {
      if (!selectedTransactionTypes.length) return;

      //Get selected ID list
      const selectedTransactionTypeIds = selectedTransactionTypes.map(
        (transactionType: DTO_LOV_Transaction) => transactionType.Code
      );

      //Set new value of IsInclude for selected items
      let newSelectedTransactionTypes: DTO_LOV_Transaction[] = [];
      const newTransactionType = getFieldValue("TransactionTypesData").map(
        (transactionType: DTO_LOV_Transaction) => {
          if (selectedTransactionTypeIds.includes(transactionType.Code)) {
            if (transactionType.IsIncluded !== isInclude) {
              transactionType.IsIncluded = isInclude;
            }
            newSelectedTransactionTypes.push(transactionType);
          }
          return transactionType;
        }
      );
      onChange(nameOf("TransactionTypesData"), {
        value: newTransactionType,
      });
      onChange(nameOf("SelectedTransactionTypes"), {
        value: newSelectedTransactionTypes,
      });
      onChange(nameOf("TransactionTypes"), {
        value: newTransactionType
          ?.filter(
            (transactionType: DTO_LOV_Transaction) => transactionType.IsIncluded
          )
          .map((transactionType: DTO_LOV_Transaction) => transactionType.Code),
      });
    };

    const getMaxFromDate = () => {
      const toDate = getFieldValue("IncludeClaims_To");
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      if (toDate && toDate <= currentDate) {
        return subDays(getFieldValue("IncludeClaims_To"), 1);
      }
      return currentDate;
    };

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Rebate authority" isMandatory />
              <Field
                name={nameOf("Authority_Id")}
                component={CCSearchComboBox}
                data={createRebateClaimLOVs?.RebateAuthority ?? []}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                value={getDropdownValue(
                  "" + getFieldValue("Authority_Id"),
                  createRebateClaimLOVs?.RebateAuthority ?? [],
                  "Code"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("Authority_Id"), {
                    value: event.value?.Code ?? null,
                  });
                }}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Rating period" isMandatory />
              <Field
                name={nameOf("Rating_Period_Id")}
                component={CCSearchComboBox}
                data={createRebateClaimLOVs?.RatingPeriods ?? []}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                value={getDropdownValue(
                  "" + getFieldValue("Rating_Period_Id"),
                  createRebateClaimLOVs?.RatingPeriods ?? [],
                  "Code"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("Rating_Period_Id"), {
                    value: event.value?.Code ?? null,
                  });
                }}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          </div>
          <div className="cc-field">
            <CCLabel title="Include rebate transactions (not previously claimed) in the period" />
          </div>
          <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="From" isMandatory />
                <Field
                  name={nameOf("IncludeClaims_From")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  max={getMaxFromDate()}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="To" isMandatory />
                <Field
                  name={nameOf("IncludeClaims_To")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  min={
                    getFieldValue("IncludeClaims_From")
                      ? addDays(getFieldValue("IncludeClaims_From"), 1)
                      : undefined
                  }
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Transaction types to be included
                {!options?.isReadOnly && (
                  <CCTooltip
                    type="info"
                    position="right"
                    content="Please include at least one type"
                  />
                )}
              </label>
              <CCGrid
                data={getFieldValue("TransactionTypesData") || []}
                primaryField={nameOfLOVTransaction("Code")}
                columnFields={colTransactionType}
                selectableMode="multiple"
                selectedRows={
                  selectedTransactionTypes
                    ? [...selectedTransactionTypes]
                    : undefined
                }
                onSelectionChange={(dataItem: any) => {
                  handleGridSelectOnChange(
                    dataItem,
                    "SelectedTransactionTypes"
                  );
                }}
                readOnly={options?.isReadOnly}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      iconClass="fas fa-check text-success"
                      title="Include Transaction"
                      disabled={
                        !selectedTransactionTypes.length ||
                        selectedTransactionTypes.every(
                          (transactionType: DTO_LOV_Transaction) =>
                            transactionType.IsIncluded
                        )
                      }
                      onClick={() => {
                        handleTransactionsTypeInfoChange(true);
                      }}
                    />
                    <Button
                      iconClass="fas fa-times text-danger"
                      title="Exclude Transaction"
                      disabled={
                        !selectedTransactionTypes.length ||
                        selectedTransactionTypes.every(
                          (transactionType: DTO_LOV_Transaction) =>
                            !transactionType.IsIncluded
                        )
                      }
                      onClick={() => {
                        handleTransactionsTypeInfoChange(false);
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Name for the rebate claim" isMandatory />
              <Field
                name={nameOf("Name")}
                value={getFieldValue("Name")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
