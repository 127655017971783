import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDialog, ICCDialogProps } from "@components/cc-dialog/_index";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  InputChangeEvent,
  InputProps,
  TextAreaChangeEvent,
  TextAreaProps,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNull, isUndefined } from "lodash";
import React, {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import "./_index.scss";

export interface ICCInputPickerChangeEvent {
  value: any;
}
export interface ICCInputPickerProps
  extends Omit<
    InputProps & TextAreaProps,
    "validationMessage" | "value" | "onChange"
  > {
  validationMessage?: string | null;
  visited?: boolean;
  value: any;
  onChange?: (event: ICCInputPickerChangeEvent) => void;
  onInputChange?: (event: InputChangeEvent | TextAreaChangeEvent) => void;
  onError?: (value: any) => void;
  onLoadData?: () => void;
  textField?: string;
  textProduce?: (value: any) => string | undefined;

  valueField?: string;
  valueProduce?: (value: any) => any | undefined;

  dialog?: Omit<
    ICCDialogProps,
    "bodyElement" | "onClose" | "footerElement" | "width" | "height"
  >;
  customDialog?: (
    value: any,
    onClose: () => void,
    onSubmit: (value: any) => void
  ) => ReactElement;

  grid?: ICCGridProps;

  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  showClearButton?: boolean;
  onClickCancel?: () => void;
  description?: string;
  dialogWrapperClassName: string;
  isUseTextArea?: boolean;
  onlyDisableInput?: boolean;
}

export const CCInputPicker = (props: ICCInputPickerProps) => {
  const {
    validationMessage,
    visited,
    className,
    dialog = {
      maxWidth: "60%",
      height: "auto",
      titleHeader: "",
    },
    grid = {
      columnFields: [],
      primaryField: "ID",
    },
    value,
    textField,
    textProduce,
    valueField,
    valueProduce,
    onInputChange,
    onChange,
    readOnly = true,
    disabled,
    customDialog,
    onButtonClick,
    isLoading,
    onLoadData,
    showClearButton,
    onClickCancel,
    description = "",
    dialogWrapperClassName,
    isUseTextArea = false,
    onlyDisableInput = false,
    ...others
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [refreshCCTextArea, setRefreshCCTextArea] = useState(true);
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>(
    props.grid?.selectedRows ?? []
  );
  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  const handleOnChange = useCallback(
    (value: any) => {
      /* ====  Refresh CCTextArea  ==== */
      if (isUseTextArea) {
        setRefreshCCTextArea(false);
        setTimeout(() => setRefreshCCTextArea(true), 0);
      }
      /* ====  / Refresh CCTextArea  ==== */
      setShowDialog(false);
      if (!onChange) return;
      if (valueProduce) return onChange({ value: valueProduce(value) });
      if (valueField && !isArray(value) && valueField in value)
        return onChange({ value: value[valueField] });
      return onChange({ value });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, valueField, valueProduce]
  );

  const handleOnClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onButtonClick) return onButtonClick(event);
    setShowDialog(true);
    setGridSelectedRows(props.grid?.selectedRows ?? []);
    if (onLoadData) return onLoadData();
  };

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        } ${isUseTextArea ? "cc-case-use-textarea" : ""}`}
      >
        <div className="cc-wrap-input">
          {isHTML(inputValue) ? (
            <div className="cc-input-picker-html k-textbox">
              {sanitizeHtml(inputValue)}
            </div>
          ) : isUseTextArea ? (
            refreshCCTextArea && (
              <CCTextArea
                {...others}
                value={inputValue}
                onChange={onInputChange}
                className="cc-input-picker-field"
                readOnly={readOnly}
                disabled={disabled}
                rows={1}
                autoSize
              />
            )
          ) : (
            <CCInput
              {...others}
              value={inputValue}
              onChange={onInputChange}
              className="cc-input-picker-field"
              readOnly={readOnly}
              disabled={disabled}
            />
          )}
          {showClearButton && (
            <Button
              disabled={disabled}
              className="cc-input-picker-button cc-clear-button"
              icon="close"
              onClick={(event: any) => {
                event.preventDefault();
                handleOnChange(null);
              }}
            />
          )}
        </div>
        <Button
          disabled={onlyDisableInput ? false : disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickButton}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog &&
        (customDialog ? (
          customDialog(value, () => setShowDialog(false), handleOnChange)
        ) : (
          <CCDialog
            dialogWrapperClassName="cc-dialog-input-picker"
            {...dialog}
            height="auto"
            onClose={() => setShowDialog(false)}
            bodyElement={
              <>
                {description && (
                  <label className="cc-settings-description">
                    {sanitizeHtml(description)}
                  </label>
                )}
                <CCGrid
                  {...grid}
                  isLoading={isLoading}
                  selectableMode={grid.selectableMode ?? "single"}
                  selectedRows={gridSelectedRows}
                  onSelectionChange={(dataItems) => {
                    setGridSelectedRows(dataItems);
                  }}
                />
              </>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  onClick={() => {
                    setShowDialog(false);
                    onClickCancel && onClickCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={gridSelectedRows.length === 0}
                  onClick={() => {
                    handleOnChange(
                      grid.selectableMode === "multiple"
                        ? gridSelectedRows
                        : gridSelectedRows[0]
                    );
                  }}
                >
                  Select
                </Button>
              </div>
            }
          />
        ))}
    </>
  );
};
