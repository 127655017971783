import { DTO_Entity_LOVs } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import {
  DTO_Workflow_ModifyEntity,
  RequestObjFromSpatialModifyContact,
} from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetInitialDataModifyContact =
  | [APIResponse<DTO_Entity_LOVs>, APIResponse<DTO_Workflow_ModifyEntity>]
  | APIResponse<DTO_Entity_LOVs>
  | APIResponse<DTO_Workflow_ModifyEntity>
  | undefined;

export const getInitialDataModifyContact = async (
  workflowType: WorkflowTypes,
  id: number,
  isFromActionList: boolean = false,
  isUpdateFromChangeOfOwnership: boolean = false,
  requestObjSpatial: RequestObjFromSpatialModifyContact | object = {
    EntityId: id,
    Is_Update_From_ChangeOfOwnership: isUpdateFromChangeOfOwnership,
  }
): Promise<IGetInitialDataModifyContact> => {
  try {
    return (await Promise.all([
      CoreAPIService.getClient().get<DTO_Entity_LOVs>(
        `/api/property/internal/workflow/entity/lovs?workflowType=${workflowType}`
      ),
      isFromActionList
        ? getWorkflowModifyContact(id)
        : getNewWorkflowModifyContact(requestObjSpatial),
    ])) as IGetInitialDataModifyContact;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessModifyContact = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_ModifyEntity
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyEntity>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyentity/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowModifyContact = async (
  requestObjSpatial: RequestObjFromSpatialModifyContact | object = {}
): Promise<APIResponse<DTO_Workflow_ModifyEntity>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyentity/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowModifyContact = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyEntity>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifyentity/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
