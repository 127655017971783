import { isNil, lt } from "lodash";

export const handleValidateLotNumbers = (
  _value: number,
  valueGetter: (name: string) => number
): string => {
  if (isNil(valueGetter("Lot_To"))) {
    return "This field is required.";
  }

  if (
    valueGetter("Lot_From") &&
    valueGetter("Lot_To") &&
    lt(valueGetter("Lot_To"), valueGetter("Lot_From"))
  ) {
    return "The value is not valid.";
  }
  return "";
};
