import {
  getAddressMapShare,
  getCandidateAddress,
  getOriginalAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/api";
import { colAddressValidation } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/config";
import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { handleValidator } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/util";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
} from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "react-use";

interface IAddressValidationFormStepElement extends IFormStepElement {
  notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>;
  gridData?: MapshareSuggestion[];
}

const nameOfdAddressValidation = nameOfFactory<MapshareSuggestion>();

export const ADDRESS_VALIDATION_FORM_STEP = "Address_Validation";
export const AddressValidationStep = forwardRef(
  (props: IFormStepElement, ref) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [gridData, setGridData] = useState<MapshareSuggestion[]>();
    const { valueGetter, onChange } = props.formRenderProps;
    const prevMapShareMagicKey = valueGetter("_option.PrevMapShareMagicKey");
    const mapShareMagicKey = valueGetter("Address.Mapshare_Magickey");
    const address = valueGetter("Address");
    const propertyAssessment = valueGetter("Address.PropertyAssessment");
    const streetNo = getDisplayHouseNumber(address?.PropertyAssessment);
    const unitNo =
      (!isEmpty(getDisplayFloorNumber(address?.PropertyAssessment))
        ? `${getDisplayFloorNumber(address?.PropertyAssessment)} `
        : "") + getDisplayBuildingUnitNumber(address?.PropertyAssessment);

    useEffectOnce(() => {
      if (!address) return;
      if (props.setIsLoadingStep) props.setIsLoadingStep(true);
      onChange("Address.VMAS_Verified", { value: false });
      onChange("Address.StreetNo", { value: streetNo });
      onChange("Address.UnitNo", { value: unitNo });
      getAddressMapShare(address).then((response) => {
        if (!response.data) {
          notificationRef.current?.pushNotification({
            title: "Address mapshare load failed",
            type: "error",
          });
        }
        setGridData(response.data?.suggestions);
        if (props.setIsLoadingStep) props.setIsLoadingStep(false);
      });
    });

    useImperativeHandle(ref, () => ({
      async getConfirm() {
        if (!mapShareMagicKey) return true;
        if (isEqual(prevMapShareMagicKey, mapShareMagicKey)) {
          onChange("Address.VMAS_Verified", {
            value: true,
          });
          return true;
        }
        if (props.setIsLoadingStep) props.setIsLoadingStep(true);
        onChange("_option.PrevMapShareMagicKey", { value: mapShareMagicKey });
        const responseCandidateAddress = await getCandidateAddress(
          mapShareMagicKey
        );
        if (isSuccessResponse(responseCandidateAddress)) {
          const candidateAddress = responseCandidateAddress?.data;
          if (candidateAddress) {
            if (
              candidateAddress.candidates &&
              candidateAddress.candidates.length > 0
            ) {
              onChange("Address.PropertyAssessment.GIS_Latitude", {
                value: candidateAddress.candidates[0].location.x,
              });
              onChange("Address.PropertyAssessment.GIS_Longitude", {
                value: candidateAddress.candidates[0].location.y,
              });
              if (
                candidateAddress.candidates[0].attributes &&
                !isEmpty(candidateAddress.candidates[0].attributes.User_fld)
              ) {
                const responseOriginalAddress = await getOriginalAddress(
                  candidateAddress.candidates[0].attributes.User_fld
                );
                if (isSuccessResponse(responseOriginalAddress)) {
                  let newAddress = cloneDeep(address);
                  let newPropertyAssessment = cloneDeep(propertyAssessment);

                  //Clear unrelated fields
                  newPropertyAssessment = {
                    ...newPropertyAssessment,
                    PropertyName: "",
                    BuildingName: "",
                    AddressLocationDescriptor: "",
                    NearestCrossStreet: "",
                    UnitNumber1: null,
                    UnitSuffix1: "",
                    UnitNumber2: null,
                    UnitSuffix2: "",
                    UnitAbbreviation: "",
                    HouseNumber2: null,
                    HouseSuffix2: "",
                    FloorNumber1: null,
                    FloorSuffix1: "",
                    FloorNumber2: null,
                    FloorSuffix2: "",
                    FloorTypeAbbreviation: "",
                  };

                  newAddress = {
                    ...newAddress,
                    NearestCrossStreet: "",
                    PropertyName: "",
                    Location_Description: "",
                    PropertyAssessment: {
                      ...newAddress.PropertyAssessment,
                      ...newPropertyAssessment,
                    },
                    StreetNo: getDisplayHouseNumber(newPropertyAssessment),
                    UnitNo:
                      (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
                        ? `${getDisplayFloorNumber(newPropertyAssessment)} `
                        : "") +
                      getDisplayBuildingUnitNumber(newPropertyAssessment),
                  };

                  onChange("Address", { value: newAddress });

                  const originalAddress = responseOriginalAddress.data;
                  if (originalAddress) {
                    newAddress = {
                      ...newAddress,
                      UnitNo: originalAddress.UnitNo ?? "",
                      StreetNo: originalAddress.StreetNo ?? "",
                    };

                    newPropertyAssessment = {
                      ...newPropertyAssessment,
                      ...originalAddress.PropertyAssessment,
                      Address_PFI:
                        originalAddress.PropertyAssessment.Address_PFI ?? "",
                      HouseNumber1:
                        originalAddress.PropertyAssessment.HouseNumber1 ?? null,
                      HouseSuffix1:
                        originalAddress.PropertyAssessment.HouseSuffix1 ?? "",
                      Street_Name:
                        originalAddress.PropertyAssessment.Street_Name ?? "",
                      Street_Suffix:
                        originalAddress.PropertyAssessment.Street_Suffix ?? "",
                      Street_TypeAbbreviation:
                        originalAddress.PropertyAssessment
                          .Street_TypeAbbreviation ?? "",
                      Locality_Name:
                        originalAddress.PropertyAssessment.Locality_Name ?? "",
                      Locality_Postcode:
                        originalAddress.PropertyAssessment.Locality_Postcode ??
                        "",
                      UnitNumber1: originalAddress.UnitNo || null,
                    };

                    newAddress = {
                      ...newAddress,
                      Suburb: originalAddress.Suburb ?? "",
                      Postcode: originalAddress.Postcode ?? "",
                      AddressLine1: originalAddress.AddressLine1 ?? "",
                      VMAS_Verified: true,
                      State: originalAddress.State ?? "",
                      StreetNamePart_Name:
                        originalAddress.StreetNamePart_Name ?? "",
                      StreetName: originalAddress.StreetName ?? "",
                      PropertyAssessment: {
                        ...newAddress.PropertyAssessment,
                        ...newPropertyAssessment,
                      },
                      StreetNo: getDisplayHouseNumber(newPropertyAssessment),
                      UnitNo:
                        (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
                          ? `${getDisplayFloorNumber(newPropertyAssessment)} `
                          : "") +
                        getDisplayBuildingUnitNumber(newPropertyAssessment),
                    };

                    onChange("Address", { value: newAddress });

                    if (props?.options?.setOriginalFormData) {
                      props?.options?.setOriginalFormData(newAddress);
                    }
                  }
                } else {
                  if (props.setIsLoadingStep) props.setIsLoadingStep(false);
                  notificationRef.current?.pushNotification({
                    title: "Address validate failed",
                    type: "error",
                  });
                  return false;
                }
              }
            }
          }
          if (props.setIsLoadingStep) props.setIsLoadingStep(false);
          return true;
        } else {
          if (props.setIsLoadingStep) props.setIsLoadingStep(false);
          notificationRef.current?.pushNotification({
            title: "Address validate failed",
            type: "error",
          });
          return false;
        }
      },
    }));

    return (
      <>
        <CCLocalNotification ref={notificationRef} />
        <FieldArray
          name={props.nameOf()}
          {...props}
          component={AddressValidationFormStepElement}
          validator={handleValidator}
          notificationRef={notificationRef}
          gridData={gridData}
        />
      </>
    );
  }
);

const AddressValidationFormStepElement = ({
  formRenderProps,
  nameOf,
  setStepsVisible,
  gridData,
  setIsLoadingStep = () => {},
  isLoadingStep,
}: IAddressValidationFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const MagicKey = valueGetter("Address.Mapshare_Magickey");

  const selectedAddressValidation = useMemo(() => {
    if (gridData) {
      return gridData.filter(
        (address: MapshareSuggestion) => address.magicKey === MagicKey
      );
    }
  }, [gridData, MagicKey]);

  return (
    <>
      <CCGrid
        isLoading={isLoadingStep}
        columnFields={colAddressValidation}
        data={gridData ?? []}
        selectedRows={selectedAddressValidation}
        primaryField={nameOfdAddressValidation("magicKey")}
        selectableMode="single"
        onSelectionChange={(dataItem: MapshareSuggestion[]) =>
          onChange("Address.Mapshare_Magickey", {
            value: dataItem.length > 0 ? dataItem[0].magicKey : "",
          })
        }
      />
    </>
  );
};
