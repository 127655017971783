import { IRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/model";

export const mockRegisterTransactions: IRegisterTransactions[] = [
  {
    DateTime: new Date("31-Mar-2019 00:00"),
    TransType: "WSewC",
    TransactionAmount: 243.0,
    ProgressiveTotal: 425.9,
    Description: "Water Sewerage Charges",
    Quantity: 1,
    Notes: "Meter Id:5999 243.00 x 1 x (90/90)",
    JournalNumber: 15882,
    SessionNumber: 13863,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("5-Apr-2019 11:22"),
    TransactionId: 613566,
  },
  {
    DateTime: new Date("30-Jun-2019 00:00"),
    TransType: "WSC",
    TransactionAmount: 72.0,
    ProgressiveTotal: 497.9,
    Description: "Water Supply Charges",
    Quantity: 1,
    Notes: "Meter Id: 5999 72.00\\1.00 x (91\\91)",
    JournalNumber: 16554,
    SessionNumber: 14529,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Jul-2019 08:29"),
    TransactionId: 629140,
  },
  {
    DateTime: new Date("30-Jun-2019 00:00"),
    TransType: "WBC",
    TransactionAmount: 146.0,
    ProgressiveTotal: 643.9,
    Description: "Water Billing Charges",
    Quantity: 54,
    Notes: "Meter Id: 5999 Usage: 54kl",
    JournalNumber: 16554,
    SessionNumber: 14529,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Jul-2019 08:29"),
    TransactionId: 629141,
  },
  {
    DateTime: new Date("30-Jun-2019 00:00"),
    TransType: "WSewC",
    TransactionAmount: 243.0,
    ProgressiveTotal: 886.9,
    Description: "Water Sewerage Charges",
    Quantity: 1,
    Notes: "Meter Id:5999 243.00 x 1 x (91/91)",
    JournalNumber: 16554,
    SessionNumber: 14529,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Jul-2019 08:29"),
    TransactionId: 629142,
  },
  {
    DateTime: new Date("16-Aug-2019 00:00"),
    TransType: "Pmt",
    TransactionAmount: -425.9,
    ProgressiveTotal: 461.0,
    Reference: "Water Import",
    Description: "Agency Payment",
    ReceiptNumber: 176472,
    Notes: "W2229.0",
    JournalNumber: 17112,
    SessionNumber: 15075,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("16-Aug-2019 00:00"),
    TransactionId: 635494,
  },
  {
    DateTime: new Date("30-Aug-2019 00:00"),
    TransType: "Pmt",
    TransactionAmount: -461.0,
    ProgressiveTotal: 0.0,
    Reference: "Water Import",
    Description: "Agency Payment",
    ReceiptNumber: 177767,
    Notes: "W2229.0",
    JournalNumber: 17273,
    SessionNumber: 15234,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("30-Aug-2019 00:00"),
    TransactionId: 645966,
  },
  {
    DateTime: new Date("30-Sep-2019 00:00"),
    TransType: "WSC",
    TransactionAmount: 72.0,
    ProgressiveTotal: 72.0,
    Description: "Water Supply Charges",
    Quantity: 1,
    Notes: "Meter Id: 5999 72.00\\1.00 x (92\\92)",
    JournalNumber: 17258,
    SessionNumber: 15219,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Oct-2019 14:37"),
    TransactionId: 645542,
  },
  {
    DateTime: new Date("30-Sep-2019 00:00"),
    TransType: "WBC",
    TransactionAmount: 161.6,
    ProgressiveTotal: 233.6,
    Description: "Water Billing Charges",
    Quantity: 58,
    Notes: "Meter Id: 5999 Usage: 58kl",
    JournalNumber: 17258,
    SessionNumber: 15219,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Oct-2019 14:37"),
    TransactionId: 645543,
  },
  {
    DateTime: new Date("30-Sep-2019 00:00"),
    TransType: "WSewC",
    TransactionAmount: 243.0,
    ProgressiveTotal: 476.6,
    Description: "Water Sewerage Charges",
    Quantity: 1,
    Notes: "Meter Id:5999 243.00 x 1 x (92/92)",
    JournalNumber: 17258,
    SessionNumber: 15219,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Oct-2019 14:37"),
    TransactionId: 645544,
  },
  {
    DateTime: new Date("31-Dec-2019 00:00"),
    TransType: "WSC",
    TransactionAmount: 72.0,
    ProgressiveTotal: 548.6,
    Description: "Water Supply Charges",
    Quantity: 1,
    Notes: "Meter Id: 5999 72.00\\1.00 x (92\\92)",
    JournalNumber: 17948,
    SessionNumber: 15900,
    UserId: "CadenL",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("15-Jan-2020 09:39"),
    TransactionId: 662038,
  },
  {
    DateTime: new Date("31-Dec-2019 00:00"),
    TransType: "WBC",
    TransactionAmount: 122.6,
    ProgressiveTotal: 671.2,
    Description: "Water Billing Charges",
    Quantity: 48,
    Notes: "Meter Id: 5999 Usage: 48kl",
    JournalNumber: 17948,
    SessionNumber: 15900,
    UserId: "CadenL",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("15-Jan-2020 09:39"),
    TransactionId: 662039,
  },
  {
    DateTime: new Date("31-Dec-2019 00:00"),
    TransType: "WSewC",
    TransactionAmount: 243.0,
    ProgressiveTotal: 914.2,
    Description: "Water Sewerage Charges",
    Quantity: 1,
    Notes: "Meter Id:5999 243.00 x 1 x (92/92)",
    JournalNumber: 17948,
    SessionNumber: 15900,
    UserId: "CadenL",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("15-Jan-2020 09:39"),
    TransactionId: 662040,
  },
  {
    DateTime: new Date("31-Mar-2020 00:00"),
    TransType: "WSC",
    TransactionAmount: 72.0,
    ProgressiveTotal: 986.2,
    Description: "Water Supply Charges",
    Quantity: 1,
    Notes: "Meter Id: 5999 72.00\\1.00 x (91\\91)",
    JournalNumber: 18585,
    SessionNumber: 16530,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("9-Apr-2020 08:16"),
    TransactionId: 678566,
  },
  {
    DateTime: new Date("31-Mar-2020 00:00"),
    TransType: "WBC",
    TransactionAmount: 169.4,
    ProgressiveTotal: 1155.6,
    Description: "Water Billing Charges",
    Quantity: 60,
    Notes: "Meter Id: 5999 Usage: 60kl",
    JournalNumber: 18585,
    SessionNumber: 16530,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("9-Apr-2020 08:16"),
    TransactionId: 678567,
  },
  {
    DateTime: new Date("31-Mar-2020 00:00"),
    TransType: "WSewC",
    TransactionAmount: 243.0,
    ProgressiveTotal: 1398.6,
    Description: "Water Sewerage Charges",
    Quantity: 1,
    Notes: "Meter Id:5999 243.00 x 1 x (91/91)",
    JournalNumber: 18585,
    SessionNumber: 16530,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("9-Apr-2020 08:16"),
    TransactionId: 678568,
  },
  {
    DateTime: new Date("22-Apr-2020 00:00"),
    TransType: "Pmt",
    TransactionAmount: -476.6,
    ProgressiveTotal: 922.0,
    Reference: "Water Import",
    Description: "Agency Payment",
    ReceiptNumber: 192534,
    Notes: "W2229.0",
    JournalNumber: 18694,
    SessionNumber: 16634,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("22-Apr-2020 00:00"),
    TransactionId: 680439,
  },
  {
    DateTime: new Date("19-May-2020 00:00"),
    TransType: "Pmt",
    TransactionAmount: -437.6,
    ProgressiveTotal: 484.4,
    Reference: "Water Import",
    Description: "Agency Payment",
    ReceiptNumber: 195250,
    Notes: "W2229.0",
    JournalNumber: 18888,
    SessionNumber: 16822,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("19-May-2020 00:00"),
    TransactionId: 683502,
  },
  {
    DateTime: new Date("22-May-2020 00:00"),
    TransType: "Pmt",
    TransactionAmount: -484.4,
    ProgressiveTotal: 0.0,
    Reference: "Water Import",
    Description: "Agency Payment",
    ReceiptNumber: 196231,
    Notes: "W2229.0",
    JournalNumber: 18953,
    SessionNumber: 16882,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("22-Apr-2020 00:00"),
    TransactionId: 684935,
  },
  {
    DateTime: new Date("30-Jun-2020 00:00"),
    TransType: "WSC",
    TransactionAmount: 72.0,
    ProgressiveTotal: 72.0,
    Description: "Water Supply Charges",
    Quantity: 1,
    Notes: "Meter Id: 5999 72.00\\1.00 x (91\\91)",
    JournalNumber: 19238,
    SessionNumber: 17175,
    UserId: "JamesC",
    IsProposed: false,
    IsCancelled: false,
    CreatedOn: new Date("8-Jul-2020 17:58"),
    TransactionId: 695765,
  },
];
