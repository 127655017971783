import { ExistedECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/components/forms/existed/_index";
import { NewECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/components/forms/new/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import React from "react";

function ManagePropertyECertificateRequest() {
  const isNew = useIsNew();
  if (isNew) {
    return <NewECertificateRequest />;
  }

  return <ExistedECertificateRequest />;
}

export default ManagePropertyECertificateRequest;
