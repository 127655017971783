import { Registration } from "@app/products/animals/[id]/model";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";

export interface PreRenewalAnimalsDetails {
  ID: number;
  TagNo: string;
  RegistrationNumber: string;
  AnimalName: string;
  AnimalType?: number;
  AnimalOwner: string;
  OSFees?: number;
  EffectiveFrom?: Date;
  EffectiveTo?: Date;
  NewTagNo: string;
  NextFee: number;
  NextEffFrom?: Date;
  NextEffTo: string;
}

export interface AnimalsRenewalsBatchRenewRequest {
  RegistrationIDs: number[];
  DateFromDateTo: DateFromDateTo | null;
  UpdatePreRenewalAnimalsDetails: PreRenewalAnimalsDetails[];
  GenerateFee: boolean;
}

export interface QuickRegistrationDetailsRequest {
  RegistrationIDs: number[];
  Registration: Registration;
}

export interface QuickRegistrationDetailsResponse {
  LblRegNo: string;
  LblTag: string;
  LblFee: string;
  LblInvoiceNo: string;
}

//enum
export enum Renewal_Method {
  Unspecified = 0,
  IssueFeesUponRenewalNotice = 1,
  DoNotIssueFeesUponRenewalNotice = 2,
}
