import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { CreateDefermentCommonButton } from "@app/products/property/assessments/deferments/components/action-bar/buttons/create-deferment/common/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CreateDefermentListButtonAtCollections = observer(() => {
  const { gridSelectedRows, isEmptyData } = useDetailAssessmentListViewStore();

  const isDisabled = useMemo(() => {
    return !isEmptyData && gridSelectedRows.length !== 1;
  }, [gridSelectedRows, isEmptyData]);

  return (
    <CreateDefermentCommonButton
      isDisabled={isDisabled}
      recordID={gridSelectedRows?.[0]?.Assessment_Id}
    />
  );
});
