import { getDisplayTextWithDashes } from "@common/utils/common";
import { getLabel } from "@app/products/property/components/label/config";

export const getBookmarkDisplayName = (selectedRow: any) => {
  return `Property - ${getLabel.assessments()} ${
    selectedRow?.Assessment_Id ? `- ${selectedRow.Assessment_Id}` : ""
  }`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow?.Assessment_Number_Formatted,
    selectedRow?.Property_Name_Address_Locality,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Active`;
};
