import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { IAmsDashBoardWarrantyChart } from "@common/pages/home/model";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAMSWarrantyInsightProps {
  amsWarrantyInsightData: IAmsDashBoardWarrantyChart;
  queryDateTime: any;
}

const AMSWarrantyInsight = (warrantyData: IAMSWarrantyInsightProps) => {
  const history = useHistory();

  const AssetWarrantyChart = () => {
    const categories = [
      warrantyData.amsWarrantyInsightData.categorydescription,
    ]; // [2018, 2019, 2020];
    const series = [
      {
        name: "Expired < 2 Months",
        data: [warrantyData.amsWarrantyInsightData.expiredinlast2months],
      },
      {
        name: "Expire < 1 Month",
        data: [warrantyData.amsWarrantyInsightData.expiresin1month],
      },
      {
        name: "Expire 1 - 2 Months",
        data: [
          warrantyData.amsWarrantyInsightData.expiresbetweennext1and2months,
        ],
      },
      {
        name: "Expire 2 - 3 Months",
        data: [
          warrantyData.amsWarrantyInsightData.expiresbetweennext2and3months,
        ],
      },
    ];

    return (
      <React.Fragment>
        <Chart style={{ height: 250 }}>
          <ChartLegend position="top" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={categories} startAngle={45} />
          </ChartCategoryAxis>
          <ChartSeries>
            {series.map((item, idx) => (
              <ChartSeriesItem
                key={idx}
                type="column"
                tooltip={{ visible: true }}
                data={item.data}
                name={item.name}
              />
            ))}
          </ChartSeries>
        </Chart>
      </React.Fragment>
    );
  };

  return (
    <div
      className="cc-insight-group"
      onClick={(e) => {
        history.push(AmsRoute.ASSET_REGISTER_PATH);
      }}
    >
      <div className="cc-group-name">
        {getProductTitle(PRODUCT_TYPE.CommunityAsset)}
      </div>
      <h4>Asset Warranty Status by Category</h4>
      <div className="cc-date-time-description">
        as of {warrantyData.queryDateTime}
      </div>
      <br />
      <AssetWarrantyChart />
    </div>
  );
};

export default AMSWarrantyInsight;
