import logo from "@assets/img/logo.png";
import { isSuccessResponse } from "@common/apis/util";
import { forgotPassword } from "@common/pages/forgot-password/api";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export const ForgotPasswordPage = observer(() => {
  const globalStore = useGlobalStore();
  const siteKey = globalStore.allSetting.googleRecaptcha?.siteKey;
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [isRequestOk, setIsRequestOk] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const history = useHistory();

  /* ====  Captcha  ==== */
  const handleOnChange = (token: string | null) => {
    if (token) setIsCaptcha(true);
  };
  /* ====  / Captcha  ==== */

  /* ====  Form Submit  ==== */
  const handleCaptChaRequest = async (data: any) => {
    setIsLoading(true);
    if (isCaptcha) {
      setIsLoading(true);
      try {
        // Call API
        const response = await forgotPassword(data.email);
        if (isSuccessResponse(response)) {
          pushNotification({
            title: response.data,
            type: "success",
          });
          setIsRequestOk(true);
        } else {
          pushNotification({
            title: response.error,
            type: "error",
            autoClose: false,
          });
        }
      } catch (error) {
        pushNotification({
          title: "Error connecting to server",
          type: "error",
          autoClose: false,
        });
      }
      setIsLoading(false);
    }
  };
  /* ====  / Form Submit  ==== */

  /* ====  Method  ==== */
  const backLoginPage = () => {
    history.go(-1);
  };
  /* ====  / Method  ==== */

  /* ====  Validator  ==== */
  const validateEmail = (value: any) => {
    if (!value) {
      return "Require";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return "Email format is wrong";
    }
    return undefined;
  };
  /* ====  / Validator  ==== */

  return (
    <>
      <div className="cc-forgot-password-page">
        <div className="cc-forgot-password-card">
          <div className="cc-forgot-password-header">
            <img
              className="cc-forgot-password-logo"
              src={logo}
              alt="Ready Community"
            />
            <div className="cc-forgot-password-company-name">
              ready community
            </div>
          </div>
          <CCAppNotification />

          <Form
            onSubmit={handleCaptChaRequest}
            render={(fromRenderProps: FormRenderProps) => (
              <FormElement onSubmit={handleCaptChaRequest}>
                <fieldset className="k-form-fieldset cc-forgot-password-form-fieldset">
                  <div className="cc-field">
                    <label className="cc-forgot-password-form-label">
                      Email
                    </label>
                    <Field
                      validator={validateEmail}
                      component={CCInput}
                      type={"email"}
                      placeholder={"Email"}
                      name={"email"}
                    />
                  </div>

                  {siteKey && (
                    <div className="cc-recaptcha">
                      <ReCAPTCHA
                        sitekey={siteKey}
                        onChange={handleOnChange}
                        type="image"
                      />
                    </div>
                  )}

                  <div
                    style={{ display: !isRequestOk ? "flex" : "none" }}
                    className="cc-forgot-password-inline-group"
                  >
                    <Button
                      className="cc-forgot-password-back"
                      iconClass="fas fa-arrow-left"
                      onClick={backLoginPage}
                      type="button"
                    />
                    <Button
                      className="cc-forgot-password-button"
                      type="submit"
                      themeColor="primary"
                      disabled={
                        !fromRenderProps.valid ||
                        (!!siteKey && !isCaptcha) ||
                        isLoading
                      }
                    >
                      {isLoading ? (
                        <i className="fas fa-spinner fa-spin cc-forgot-password-button-icon" />
                      ) : null}
                      <span> REQUEST NEW PASSWORD</span>
                    </Button>
                  </div>

                  <div
                    style={{ display: isRequestOk ? "block" : "none" }}
                    className="cc-forgot-password-inline-group-ok"
                  >
                    <Button
                      className="cc-forgot-password-button-ok"
                      type="submit"
                      themeColor="primary"
                      onClick={backLoginPage}
                    >
                      <span> OK</span>
                    </Button>
                  </div>
                </fieldset>
              </FormElement>
            )}
          />
        </div>
      </div>
    </>
  );
});
