import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import { SettingButtonEdit } from "@components/setting/setting-button-edit/_index";
import React from "react";
import "./_index.scss";
interface ISettingTextInputProps {
  name: string;
  value: string | number;
  label: string;
  isMandatory?: boolean;
  useAutoComplete?: boolean;
  disabled?: boolean;
  onChange?: any;
  isMultiline?: boolean;
  maxLength?: number;
  description?: string;
  isEnabledEditButton?: boolean;
  handleOnEdit?: any;
  handleOnCancel?: any;
  formRenderProps?: any;
  validationMessage?: string;
  isLoading?: boolean;
}

export const SettingTextInput: React.FC<ISettingTextInputProps> = ({
  name,
  label,
  value,
  isMandatory,
  useAutoComplete,
  disabled,
  onChange,
  isMultiline,
  maxLength,
  description = "",
  isEnabledEditButton,
  handleOnEdit,
  formRenderProps,
  handleOnCancel,
  isLoading,
  ...others
}) => {
  return (
    <div className="cc-setting-text-input">
      <div className="cc-label-flex">
        <DescriptionLabel title={label} description={description} />
        {isMandatory && <CCTooltip type="validator" position="auto" />}
      </div>
      {isMultiline ? (
        <div>
          <CCTextArea
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled ?? false}
            autoComplete={useAutoComplete ? "on" : "off"}
            cols={184}
          />
        </div>
      ) : (
        <div className="cc-field-flex">
          <div className="cc-input">
            <CCInput
              name={name}
              value={!value ? "" : value}
              onChange={onChange}
              maxLength={maxLength}
              disabled={disabled ?? false}
              autoComplete={useAutoComplete ? "on" : "off"}
              {...others}
            />
          </div>

          {isEnabledEditButton ? (
            <SettingButtonEdit
              isEditing={!disabled}
              handleOnEdit={handleOnEdit}
              handleOnCancel={handleOnCancel}
              formRenderProps={formRenderProps}
              isLoading={isLoading}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
