import React, {ReactElement} from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./itemTypes";
import { statuses } from "./sample-data";

interface IProps {
    onDrop: (item: any, monitor: any, status: any) => void;
    theStatus: string
}
const DropWrapper : React.FC<IProps> = ({ onDrop, theStatus, children }) => {
    const [{ isOver }, drop] = useDrop({
        accept: ItemTypes.Card,
        canDrop: (item: any, monitor) => {
            const itemIndex = statuses.findIndex(si => si.status === item.status);
            const statusIndex = statuses.findIndex(si => si.status === theStatus);
            return [itemIndex + 1, itemIndex - 1, itemIndex].includes(statusIndex);
        },
        drop: (item, monitor) => {
            onDrop(item, monitor, theStatus);
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    });

    return (
        <div ref={drop} className={"drop-wrapper"}>
            {React.cloneElement(children as ReactElement<any>, { isOver })}
        </div>
    )
};

export default DropWrapper;