import { WastewaterUrl } from "@app/products/waste-water/config";
import {
  SystemTypeSelection,
  WasteWaterSummaryLOVs,
  WasteWaterSystemRegisterSummary,
} from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";
import { SiteUser } from "./model";

export type IGetWWById =
  | [
      APIResponse<WasteWaterSystemRegisterSummary>,
      APIResponse<WasteWaterSummaryLOVs>
    ]
  | APIResponse<WasteWaterSystemRegisterSummary>
  | undefined;

export const getWasteWaterInfo = async (id: string): Promise<IGetWWById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<WasteWaterSystemRegisterSummary>(
        `${
          globalStoreInstance.allSetting.baseAPIUrl
        }${WastewaterUrl.GET_WASTEWATER_SUMMARY.replace("{id}", id)}`
      ),
      CoreAPIService.getClient().get<WasteWaterSummaryLOVs>(
        `${globalStoreInstance.allSetting.baseAPIUrl}${WastewaterUrl.GET_WASTEWATER_SUMMARY_LOV}`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWasteWaterSummaryById = async (
  id: string
): Promise<APIResponse<WasteWaterSystemRegisterSummary | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<WasteWaterSystemRegisterSummary>(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${WastewaterUrl.GET_WASTEWATER_SUMMARY.replace("{id}", id)}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWasteWaterSummaryLov = async (): Promise<
  APIResponse<WasteWaterSummaryLOVs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<WasteWaterSummaryLOVs>(
      `${globalStoreInstance.allSetting.baseAPIUrl}${WastewaterUrl.GET_WASTEWATER_SUMMARY_LOV}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWasteWaterSystemType = async (): Promise<
  SystemTypeSelection[] | undefined
> => {
  try {
    const response: IResponseData = await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}${WastewaterUrl.GET_WASTEWATER_SYSTEM_TYPE}`
    );

    if (!response) {
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getWasteWaterLightWeightSiteUser = async (
  id: string
): Promise<SiteUser | undefined> => {
  try {
    const response: IResponseData = await CoreAPIService.getClient().get(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${WastewaterUrl.GET_CONTACTS_LIGHTWEIGHT_SITE_USER.replace("{id}", id)}`
    );

    if (!response) {
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const postWastewater = async (
  requestBody: WasteWaterSystemRegisterSummary
): Promise<APIResponse<WasteWaterSystemRegisterSummary>> => {
  try {
    return await CoreAPIService.getClient().post<WasteWaterSystemRegisterSummary>(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${`/api/ww/internal/wastewater`}`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putWastewater = async (
  requestBody: WasteWaterSystemRegisterSummary
): Promise<APIResponse<WasteWaterSystemRegisterSummary>> => {
  try {
    return await CoreAPIService.getClient().put<WasteWaterSystemRegisterSummary>(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${`/api/ww/internal/wastewater/{id}`.replace(
        "{id}",
        requestBody.System_ID.toString()
      )}`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
