import { mockFirePrevention } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/mock";
import { FirePreventionMemo } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/model";
import { sleep } from "@common/utils/common";

export const getFirePreventionMemos = async (
  caseId: number
): Promise<FirePreventionMemo[] | undefined> => {
  await sleep(1000);
  try {
    const response = mockFirePrevention;
    return response;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
