import { getParentSummary } from "@app/core/further-info/[id]/api";
import { Ancestor } from "@app/core/further-info/[id]/model";
import {
  BubbleUpType,
  Svc_BubbleUpIdentifier,
} from "@app/core/inspections/[id]/model";
import { getRefNoParent } from "@app/core/inspections/[id]/util";
import { getRecallById } from "@app/core/recall/[id]/api";
import {
  IGetRecallExtractParamForNextAPI,
  Recall,
} from "@app/core/recall/model";
import { APIResponse } from "@common/apis/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { mapEnum } from "@common/utils/common";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IParentBubbleUpDetails {
  parentId: number;
  parentRecordType: RECORDTYPE;
}

export const RecallDetailsTab = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const { cancelToken } = useCancelRequest();

  const [recall, setRecall] = useState<Recall | undefined>();
  const [ancestor, setAncestor] = useState<Ancestor | undefined>();
  const [parentBubbleUpDetails, setParentBubbleUpDetails] = useState<
    IParentBubbleUpDetails | undefined
  >();

  // Get recall data
  const manageRecallSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const id: number = initialData;
          return getRecallById(id, cancelToken());
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          const response: APIResponse<IIdentityPacket<Recall>> = dataFromApi;
          const recall = response?.data?.ReturnObj;
          return {
            recall: recall,
          } as IGetRecallExtractParamForNextAPI;
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const { recall } =
            dataFromExtractParam as IGetRecallExtractParamForNextAPI;
          let parentId = null;
          let parentRecordType = null;
          if (recall) {
            const parent = recall._BubbleUps?.find(
              (bubbleUp: Svc_BubbleUpIdentifier) =>
                mapEnum(bubbleUp?.BubbleUpType_ENUM, BubbleUpType) ===
                BubbleUpType.Parent
            );

            parentId = parent?.SourceIdentifier?._RecordSource_ID;
            parentRecordType = parent?.SourceIdentifier?._RecordSourceType_ENUM;
            setParentBubbleUpDetails({
              parentId: parentId,
              parentRecordType: parentRecordType,
            } as IParentBubbleUpDetails);
          }
          return getParentSummary(
            parentId ?? 0,
            parentRecordType ?? RECORDTYPE.SystemInitialise
          );
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          const { recall } =
            dataFromExtractParam as IGetRecallExtractParamForNextAPI;
          const response: APIResponse<Ancestor> = dataFromApi;
          setAncestor(response?.data);
          setRecall(recall);
        },
      },
    ],
  });

  // Get parent bubble up details
  const getParentLink = () => {
    return (
      <Link
        className="cc-detail-tab-link"
        to={{
          pathname: getPageLinkByRecordType(
            mapEnum(parentBubbleUpDetails?.parentRecordType, RECORDTYPE),
            parentBubbleUpDetails?.parentId ?? 0
          ),
        }}
      >
        {getRefNoParent(ancestor)}
      </Link>
    );
  };

  useEffect(() => {
    if (!isNil(lastSelectedId)) {
      manageRecallSlider.fetchApi({
        initialData: parseInt(lastSelectedId),
      });
    } else {
      setRecall(dataForms?.GeneralForm);
      setAncestor(dataForms?.GeneralForm?.CCFormOptions?.parentSummary);

      // Get parent bubble up details
      const parent = dataForms?.GeneralForm?._BubbleUps?.find(
        (bubbleUp: Svc_BubbleUpIdentifier) =>
          mapEnum(bubbleUp?.BubbleUpType_ENUM, BubbleUpType) ===
          BubbleUpType.Parent
      );
      setParentBubbleUpDetails({
        parentId: parent?.SourceIdentifier?._RecordSource_ID,
        parentRecordType: parent?.SourceIdentifier?._RecordSourceType_ENUM,
      } as IParentBubbleUpDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForms?.GeneralForm, lastSelectedId]);

  // Set loading
  if (manageRecallSlider?.isFetching) return <Loading isLoading />;

  // Set no data
  if (!recall) return <NoData />;

  // Set error
  if (manageRecallSlider.errorComponent)
    return manageRecallSlider.errorComponent;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Recall ID:"}
        value={recall?.Recall_ID?.toString()}
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title="Recall date:"
          value={formatDisplayValue(recall?.Date_Recall, DATE_FORMAT.DATE)}
        />
        <ProductReferenceRow title="Recall number:" value={recall?.Number} />
        <ProductReferenceRow
          title="Product name:"
          value={recall?.ProductName}
        />
        <ProductReferenceRow
          title="Product type:"
          value={recall?.ProductType}
        />
      </ProductReferenceBlock>
      <hr />
      <ProductReferenceBlock>
        <ProductReferenceRow title={"Parent:"} value={getParentLink()} />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
