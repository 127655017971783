import { PRODUCT_TYPE } from "@common/constants/productType";
import { ProductVisibility } from "@common/models/appRoutes";
import { useGlobalStore } from "@common/stores/global/store";
import { CCNotFoundPage } from "@components/cc-not-found-page/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { Route } from "react-router-dom";

export interface IProductRouteProps {
  productType: PRODUCT_TYPE;
  productPath: string;
  componentRouter: React.FC;
}
export const ProductRoute: React.FC<IProductRouteProps> = observer((props) => {
  const { productType, productPath, componentRouter } = props;

  const { isVisibleAndIsCommunityCentral } = useGlobalStore();
  const isVisibility = isVisibleAndIsCommunityCentral(productType);

  if (isVisibility === ProductVisibility.Visible) {
    return <Route path={productPath} component={componentRouter} />;
  } else if (isVisibility === ProductVisibility.Hidden) {
    return <Route path={productPath} component={() => <CCNotFoundPage />} />;
  }

  return null;
});
