import { loadViewConfiguresColumns } from "@app/products/property/api";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { AddDeferredDutyDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/_index";
import { DTO_DeferredDuty_Account } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/model";
import { colJournalDeferredDuty } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/config";
import { formDeferredDutyValidator } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/utils";
import { eAssessmentAllocation } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/assessment-journal/model";
import { allocationRadioGroup } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/assessment-journal/config";
import { DTO_Assessment } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { ViewConfiguration } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioButtonProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { useDeferredJournalStepStore } from "./store";

const nameOfLovs = nameOfFactory<DTO_LOV>();
const nameOfDeferred = nameOfFactory<DTO_DeferredDuty_Account>();
export const DeferredDutyJournalFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={
        !props?.options?.isReadOnly ? formDeferredDutyValidator : undefined
      }
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    setIsLoadingStep,
    localNotificationRef,
    options = {
      isReadOnly: false,
      workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { deferredJournalStepLOVs } = useDeferredJournalStepStore();
    const [showAddDeferredDuty, setShowAddDeferredDuty] = useState(false);
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [processAllocationData, setProcessAllocationData] =
      useState(allocationRadioGroup);

    const currentAccounts = getFieldValue("Accounts");
    const selectedAccounts = getFieldValue("AccountsSelected");
    const [processColumn, setProcessColumn] = useState(colJournalDeferredDuty);

    const handleAddAccounts = (accountList: DTO_DeferredDuty_Account[]) => {
      setShowAddDeferredDuty(false);
      const isExistedAccount = currentAccounts?.some(
        (account: DTO_DeferredDuty_Account) =>
          account.AccountId === accountList[0].AccountId
      );
      const isExistedAccountSelected = currentAccounts ?? [];
      if (!isExistedAccount) {
        onChange(nameOf("Accounts"), {
          value: [accountList?.[0], ...isExistedAccountSelected],
        });
        onChange(nameOf("AccountsSelected"), {
          value: [accountList?.[0]],
        });
      }
    };
    const handleDelete = () => {
      const newAccounts = currentAccounts?.filter(
        (account: DTO_DeferredDuty_Account) =>
          selectedAccounts[0].AccountId !== account.AccountId
      );
      onChange(nameOf("Accounts"), {
        value: newAccounts,
      });
      onChange(nameOf("AccountsSelected"), { value: [] });
    };

    const canSelect = () => {
      if (!selectedAccounts) return false;
      return selectedAccounts?.length > 0;
    };

    const handleGridSelectionChange = (dataItem: any, field: string) => {
      if (dataItem) {
        onChange(nameOf(field), {
          value: dataItem,
        });
      }
      const manualAllocateIndex = processAllocationData.findIndex(
        (item: RadioButtonProps) =>
          item.value === eAssessmentAllocation.CHARGES_BALANCES
      );
      let newProcessAllocationData = [...processAllocationData];
      if (manualAllocateIndex === -1) return;
      newProcessAllocationData.splice(manualAllocateIndex, 1, {
        ...newProcessAllocationData[manualAllocateIndex],
        disabled: dataItem?.length > 1,
      });

      setProcessAllocationData(newProcessAllocationData);
    };

    const loadConfigurationColumn = async () => {
      if (setIsLoadingStep) {
        setIsLoadingStep(true);
        const response = await loadViewConfiguresColumns(
          ViewConfiguration.DeferredDuty_AccountLookup,
          processColumn
        );
        if (Array.isArray(response)) {
          setProcessColumn(response);
        } else {
          localNotificationRef?.current?.pushNotification({
            type: "error",
            autoClose: false,
            title: response.error,
          });
        }
        setIsLoadingStep(false);
      }
    };
    useEffectOnce(() => {
      loadConfigurationColumn();
    });
    return (
      <>
        <section className="cc-field-group">
          <CCLabel title="Deferred duty" isMandatory={!options?.isReadOnly} />
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <CCGrid
                data={currentAccounts}
                columnFields={processColumn}
                primaryField={nameOfDeferred("AccountId")}
                selectableMode={"single"}
                readOnly={options?.isReadOnly}
                onSelectionChange={(dataItem: any) => {
                  if (options?.isReadOnly) return;
                  handleGridSelectionChange(dataItem, "AccountsSelected");
                }}
                selectedRows={selectedAccounts}
                onDataChange={(dataItem: DTO_Assessment[]) => {
                  onChange(nameOf("Accounts"), { value: dataItem });
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      className="cc-edit-field-button"
                      iconClass="fa fa-plus"
                      title="Associate Assessment(s)"
                      disabled={
                        options?.isReadOnly || isNil(options?.workflowDraftId)
                      }
                      onClick={() => {
                        setShowAddDeferredDuty(true);
                      }}
                    />
                    <Button
                      iconClass="fas fa-minus"
                      title="Un-associate Assessment(s)"
                      disabled={options?.isReadOnly || !canSelect()}
                      onClick={() => {
                        setIsShowConfirmDeletion(true);
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
          {errors?.Accounts?.length ? <Error>{errors?.Accounts}</Error> : null}
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <label className="cc-label">Transaction</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Type
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("TransactionTypeId")}
                  component={CCSearchComboBox}
                  data={deferredJournalStepLOVs?.DDTransactionTypes ?? []}
                  textField={nameOfLovs("Name")}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  dataItemKey={nameOfLovs("Code")}
                  isUseDefaultOnchange
                  disabled={options.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Amount
                  <CCTooltip type="validator" position="right" />
                  <CCTooltip content="Enter with sign. eg. Payments are negative" />
                </label>
                <Field
                  name={nameOf("DeferredDutyAmount")}
                  defaultValue={0}
                  component={CCCurrencyInput}
                  disabled={options.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Date</label>
                <Field
                  name={nameOf("TransactionDate")}
                  format={DATETIME_FORMAT.DATETIME_CONTROL}
                  component={CCDateTimePicker}
                  defaultValue={new Date()}
                  disabled={options.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Reference</label>
                <Field
                  name={nameOf("Reference")}
                  placeholder={"Reference"}
                  component={CCInput}
                  readOnly={options.isReadOnly}
                />
              </div>
              <div className="cc-col-span-2">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Description</label>
                    <Field
                      name={nameOf("Description")}
                      placeholder={"Description"}
                      component={CCInput}
                      readOnly={options.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Notes</label>
                    <Field
                      name={nameOf("Notes")}
                      placeholder={"Notes"}
                      component={CCTextArea}
                      rows={3}
                      readOnly={options.isReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={
              "Are you sure you wish to un-associate the selected deferred duty account from this journal?"
            }
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
        {showAddDeferredDuty && (
          <AddDeferredDutyDialog
            onClose={() => {
              setShowAddDeferredDuty(false);
            }}
            handleAddAccount={handleAddAccounts}
            defaultMessage="No account have been selected that are not already associated with the journal."
          />
        )}
      </>
    );
  }
);
