import { INVOICE_MANAGE_ROUTE } from "@app/core/invoice/[id]/constant";
import { Svc_Animals_Finance } from "@app/products/animals/finance/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_Finance>();
export const colAnimalsFinanceInvoicesSentToFinance: IColumnFields[] = [
  {
    field: nameOf("InvoiceNumber"),
    title: "Invoice Number",
    locked: true,
    linkTo: (dataItem: Svc_Animals_Finance) => {
      return `${INVOICE_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  {
    field: nameOf("DebtorNumber"),
    title: "Debtor Number",
  },
  {
    field: nameOf("InvoiceAmount"),
    title: "Invoice Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InvoiceDate"),
    title: "Invoice Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InvoicePostingDate"),
    title: "Posting Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InvoiceDueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InvoiceActiveDate"),
    title: "Active Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("FeeCount"),
    title: "Fee Count",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("InvoiceBalance"),
    title: "Invoice Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PaidInFull"),
    title: "Paid In Full",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("InvoiceDescription"),
    title: "Invoice Description",
  },
];
