import { RemoveMasterSubMeterDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/remove-master-sub-meter/_index";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";
export const RemoveMasterSubMeterButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedAssociationMeter, removeAssociationMeter } =
    useMeterInstallationStore();
  const { pushNotification } = useCCAppNotificationStore();
  const handleRemove = () => {
    setShowDialog(false);
    removeAssociationMeter();
    pushNotification({
      title: `${pluralize(
        "Meter",
        selectedAssociationMeter.length
      )} removed successfully`,
      type: "success",
    });
  };

  return (
    <>
      <CCNavButton
        title={"Remove"}
        disabled={!selectedAssociationMeter.length}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <RemoveMasterSubMeterDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={handleRemove}
        />
      )}
    </>
  );
});
