import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum EKeysOfMasterPropertyAdjustmentsSteps {
  General = "General",
  Valuations = "Valuations",
}

export const masterPropertyAdjustmentKeysOfSendSteps = [
  EKeysOfMasterPropertyAdjustmentsSteps.General,
  EKeysOfMasterPropertyAdjustmentsSteps.Valuations,
];

export interface RequestAdjustmentMasterPropertyObj {
  MasterPropertyId: number;
  SupplementaryId: number;
}

export interface DTO_Workflow_MasterPropertyAdjustment {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_MasterPropertyAdjustment;
}

export interface DTO_WorkflowDetail_MasterPropertyAdjustment {
  MasterPropertyId: number;
  SupplementaryId: number;
  General: DtoGeneral;
  RatingPeriod: DtoRatingPeriod;
}

export interface DtoGeneral {
  MasterPropertyId: number;
  AddressId: number;
  Address: string;
  Reason: string;
  EffectiveDate: Date | null;
  Assessment_Number: number | null;
}

export interface DtoRatingPeriod {
  ValuationforPreviousCalculationId: number | null;
  ValuationforNewCalculationId: number | null;
  CalculateAdjustment: boolean;
}
