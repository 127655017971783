import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAppliedOnAssessment } from "@app/products/property/assessments/[id]/components/child-screens/rebates/components/applied-on-assessment/config";
import { ViewConfiguration } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { IColumnFields } from "@components/cc-grid/model";
import { DTO_Assessment_Rebates } from "./model";

export const loadAssessmentRebates = async (
  assessmentId: number,
  showHistoricalEntitlements: boolean = false,
  loadChildItems: boolean = false
): Promise<APIResponse<DTO_Assessment_Rebates>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_Rebates>(
      `api/property/internal/assessment/${assessmentId}/rebates`,
      {
        params: {
          showHistoricalEntitlements,
          loadChildItems,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IAssessmentRebatesConfigAndDataResponse =
  | [IColumnFields[] | APIResponseError, APIResponse<DTO_Assessment_Rebates>]
  | IColumnFields
  | APIResponseError
  | APIResponse<DTO_Assessment_Rebates>;

export const getAssessmentRebatesConfigAndData = async (
  assessmentId: number,
  showHistoricalEntitlements: boolean = false,
  loadChildItems: boolean = false
): Promise<IAssessmentRebatesConfigAndDataResponse> => {
  try {
    return await Promise.all([
      //Load view config of Rebate applies grid
      loadViewConfiguresColumns(
        ViewConfiguration.Assessment_Rebates,
        colAppliedOnAssessment
      ),
      //Load data for all grids
      loadAssessmentRebates(
        assessmentId,
        showHistoricalEntitlements,
        loadChildItems
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
