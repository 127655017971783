import { CrmsPickUserGroupOrgDialog } from "@app/products/crms/components/dialogs/pick-users-groups/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface ICRMSPickUserGroupOrgButtonProps {
  onSubmit: (data: any) => void;
  isNotHavePanel?: boolean;
  title?: string;
  buttonToolTip?: string;
}
export const CrmsPickUserGroupOrgButton = ({
  onSubmit,
  isNotHavePanel,
  title,
  buttonToolTip = "Pick Users, Groups and Org Structures",
}: ICRMSPickUserGroupOrgButtonProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  return (
    <>
      <Button
        type="button"
        onClick={() => setIsOpenDialog(true)}
        title={buttonToolTip}
        iconClass="fas fa-plus"
      />
      {isOpenDialog && (
        <CrmsPickUserGroupOrgDialog
          onClose={() => setIsOpenDialog(false)}
          handleSubmit={(data) => {
            onSubmit(data);
            setIsOpenDialog(false);
          }}
          isNotHavePanel={isNotHavePanel}
          title={title}
        />
      )}
    </>
  );
};
