import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DateCustomCell } from "@app/products/property/components/grid/components/cc-date-range-limit/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import React from "react";

const nameOfGIS = nameOfFactory<DTO_GIS>();
const propsDateCustomCell = {
  listFieldName: {
    fromField: nameOfGIS("Effective_From"),
    toField: nameOfGIS("Effective_To"),
  },
};
export const colGISReference: IColumnFields[] = [
  {
    field: nameOfGIS("GISReference"),
    title: "GIS Reference",
  },
  {
    field: nameOfGIS("GIS_Type"),
    title: "Type",
  },
  {
    field: nameOfGIS("Effective_From"),
    title: "From Date",
    format: DATE_FORMAT.DATE,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCell} />
    ),
    editable: true,
    width: 200,
  },
  {
    field: nameOfGIS("Effective_To"),
    title: "To Date",
    format: DATE_FORMAT.DATE,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCell} />
    ),
    editable: true,
    width: 200,
  },
];
