import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const AnnualLandStockReturnsListWorkflowButtons = observer(() => {
  return (
    <CCNavButton
      title={"Workflow"}
      type="sub-workflow"
      className="cc-workflow-button"
    >
      <CCNavButton title="Process" />
    </CCNavButton>
  );
});
