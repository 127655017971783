import { IFinancialSummariesFormData } from "@app/products/property/assessments/financial-summaries/model";
import React from "react";
import ExistedPropertyFinancialSummaries from "./components/forms/existed/_index";
import { NewPropertyFinancialSummaries } from "./components/forms/new/_index";

export interface IManageFinancialSummaries {
  financialInfo?: IFinancialSummariesFormData | undefined;
  onClose: () => void;
  isNew?: boolean;
}

export const ManageFinancialSummaries = ({
  isNew = false,
  onClose,
  financialInfo,
}: IManageFinancialSummaries) => {
  return (
    <>
      {isNew ? (
        <NewPropertyFinancialSummaries onClose={onClose} />
      ) : (
        <ExistedPropertyFinancialSummaries
          onClose={onClose}
          financialInfo={financialInfo}
        />
      )}
    </>
  );
};
