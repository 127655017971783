import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFees4yoFees: IColumnFields[] = [
  {
    field: CRSFieldMapping.REGISTRATION_AppNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.CHILD_NAME, title: "Child Name" },
  {
    field: CRSFieldMapping.PARENT1_DEBTORNO,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  //Note
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Temp 1 2021",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Temp 2 2021",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Temp 3 2021",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Temp 4 2021",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Temp 1 2022",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  //
  {
    field: CRSFieldMapping.REGISTRATION_STATUS,
    title: "Status",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.CHILD_GENDER,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.CHILD_BIRTHDATE,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.CHILD_AGE,
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ALLOCATION_YEARATTENDING,
    title: "Year Att",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ALLOCATION_PLACEDAT,
    title: "Placed At",
  },
  { field: CRSFieldMapping.PARENT1_NAME, title: "Parent Name" },
  {
    field: CRSFieldMapping.PARENT1_CONTACTNO,
    title: "Phone",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
