import axios, { CancelToken, CancelTokenSource } from "axios";
import { MutableRefObject, useRef } from "react";
import { useEffectOnce } from "react-use";
export const isCancelRequest = axios.isCancel;

type TSourceRef = MutableRefObject<CancelTokenSource | undefined>;
interface IUseCancelRequest {
  cancelToken: () => CancelToken;
  cancelRequest: (message?: string) => void;
}
export const useCancelRequest = (): IUseCancelRequest => {
  const source: TSourceRef = useRef();

  const cancelToken = (): CancelToken => {
    source.current = axios.CancelToken.source();
    return source.current.token;
  };

  const cancelRequest = (message?: string): void => {
    if (source.current) source.current.cancel(message);
  };

  useEffectOnce(() => cancelRequest);

  return { cancelToken, cancelRequest };
};
