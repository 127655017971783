export const mockCandidatesList = [
  {
    ID: 2,
    FullNames:
      "GIUNIA, Gloanni\r\nand DAWKINS, Mark James\r\nand DAWKINS, Wayne Richard",
    Address: "NSW 2111",
    Assessments:
      "10 Batemans Rd, Almurta [678874]\r\n544 Batemans Rd, Almurta [67884]",
    BalanceOutstanding: 404.22,
    BalanceDue: 404.22,
    "2017/2018": 404.22,
    LastPaymentDate: "16-Mar-2021",
    LastPaymentAmount: -315,
    LastReminderNoticeRun:
      "2nd Instalment Notice - 2016/2017 Rates & Charges[400]",
    LastReminderIssueDate: "25-Oct-2020",
    NumberOfAssessments: 2,
    Assessment_Id: 100002,
    DetailAssessments: [
      {
        Include: false,
        AssessmentNumber: 678791,
        PropertyAddress: "700 Batemans Rd Almuna",
        BalanceOutstanding: 1010.9,
        BalanceDue: 1010.9,
        "2017/2018": 1010.9,
        LastPaymentDate: "6-Feb-2021",
        LastPaymentAmount: -1000.0,
        RebateEntitlements: "State Government Rebate",
        LastReminderNoticeRun:
          "4th Instalment Notice -16/17 Rates &. Charges as at 4 April 2017(416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 515,
      },
      {
        Include: false,
        AssessmentNumber: 678865,
        PropertyAddress: "1 Batemans Rd. Almurta",
        BalanceOutstanding: 767.69,
        BalanceDue: 767.69,
        "2017/2018": 767.69,
        LastPaymentDate: "6-Jan-2021",
        LastPaymentAmount: -580.74,
        RebateEntitlements: "State Government Rebate",
        LastReminderNoticeRun:
          "4th Instalment Notice -16/17 Rates &. Charges as at 4 April 2017(416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 587,
      },
      {
        Include: false,
        AssessmentNumber: 678871,
        PropertyAddress: "16 Batemans Rd. Alm.rta",
        BalanceOutstanding: 0.0,
        BalanceDue: 0.0,
        LastPaymentDate: "31-Aug-2020",
        LastPaymentAmount: -1775.14,
        LastReminderNoticeRun:
          "Overdue Rates and Charges as at 5 June 2013(249]",
        LastReminderIssueDate: "6-Jun-2017",
        Id: 595,
      },
      {
        Include: true,
        AssessmentNumber: 678874,
        PropertyAddress: "10 Batemans Rd. Alm.rta",
        BalanceOutstanding: 406.5,
        BalanceDue: 406.5,
        "2017/2018": 406.5,
        LastPaymentDate: "20-Feb-2021",
        LastPaymentAmount: -344,
        LastReminderNoticeRun:
          "4th Instalment Notice -16/17 Rates &. Charges as at 4 April 2017(416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 598,
      },
      {
        Include: false,
        AssessmentNumber: 678881,
        PropertyAddress: "84 Batemans Rd. Almurta",
        BalanceOutstanding: 0.0,
        BalanceDue: 0.0,
        LastPaymentDate: "4-May-2021",
        LastPaymentAmount: -490.97,
        LastReminderNoticeRun:
          "4th Instalment Notice -16/17 Rates &. Charges as at 4 April 2017(416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 605,
      },
      {
        Include: true,
        AssessmentNumber: 678884,
        PropertyAddress: "544 Batemans Rd Almurta",
        BalanceOutstanding: -2.28,
        BalanceDue: -2.28,
        "2017/2018": -2.28,
        LastPaymentDate: "16-Mar-2021",
        LastPaymentAmount: -315,
        LastReminderNoticeRun:
          "2nd Instalment Notice - 2016/2017 Rates & Charges(4'30]",
        LastReminderIssueDate: "25-Oct-2O20",
        Id: 608,
      },
      {
        Include: false,
        AssessmentNumber: 678887,
        PropertyAddress: "10A Batemans Rd. Almurta",
        BalanceOutstanding: 0.0,
        BalanceDue: 0.0,
        LastPaymentDate: "24-Apr-2021",
        LastPaymentAmount: -44449,
        LastReminderNoticeRun:
          "4th Instalment Notice -16/17 Rates &. Charges as at 4 April 2017(416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 611,
      },
      {
        Include: false,
        AssessmentNumber: 682529,
        PropertyAddress: "24 Bould St. Almurta",
        BalanceOutstanding: 0.0,
        BalanceDue: 0.0,
        LastPaymentDate: "21-Jul-2020",
        LastPaymentAmount: -1746.05,
        LastReminderNoticeRun:
          "4th Instalment Notice - 2015/2014 Rates & Charges[234]",
        LastReminderIssueDate: "17-Apr-2018",
        Id: 3.723,
      },
      {
        Include: false,
        AssessmentNumber: 681563,
        PropertyAddress: "11 Belgrave-Gembrook Dr, Bass",
        BalanceOutstanding: 0.0,
        BalanceDue: 0.0,
        LastPaymentDate: "23-Apr-2021",
        LastPaymentAmount: -281.0,
        LastReminderNoticeRun:
          "4th Instalment Notice -16/17 Rates &. Charges as at 4 April 2017(416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 4767,
      },
      {
        Include: false,
        AssessmentNumber: 3913826,
        PropertyAddress: "6 Masses' St",
        BalanceOutstanding: 0,
        BalanceDue: 0,
        Id: 5720,
      },
    ],
  },
  {
    ID: 3,
    FullNames:
      "LASKA, Karl Heinz\r\nand CINI, Alexander Anthony\r\nand CINI, Antonio Angelo",
    Address: "NSW 2122",
    Assessments:
      "20 Gladesville Rd, Archies Creek [680113]\r\n9 Gladesville Rd, Archies Creek [680116]",
    BalanceOutstanding: 736,
    BalanceDue: 736,
    "2017/2018": 736,
    LastPaymentDate: "2-Mar-2021",
    LastPaymentAmount: -736,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 2,
    Assessment_Id: 100003,
  },
  {
    ID: 4,
    FullNames: "CORLUKE, Marko\r\nand CORLUKE, Peter Marko",
    Address: "NSW 2251",
    Assessments: "370 Amanda St, Archies Creek [679172]",
    BalanceOutstanding: 279,
    BalanceDue: 279,
    "2017/2018": 279,
    LastPaymentDate: "27-Feb-2021",
    LastPaymentAmount: -279,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100004,
    DetailAssessments: [
      {
        Include: true,
        AssessmentNumber: 679172,
        PropertyAddress: "370 Amanda St, Archies Creek",
        BalanceOutstanding: 279,
        BalanceDue: 279,
        "2017/2018": 279,
        LastPaymentDate: "27-Feb-2021",
        LastPaymentAmount: -279,
        LastReminderNoticeRun:
          "4th Instalment Notice - 16/17 Rates & Charges as at 4 April 2017 [416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 894,
      },
    ],
  },
  {
    ID: 5,
    Address: "5 Kirrak St\r\nOFFICER, VIC 3809",
    Assessments: "8 Beatties St, Almurta [681110]",
    BalanceOutstanding: 2725.58,
    BalanceDue: 2725.58,
    "2017/2018": 2725.58,
    "2016/2017": 242.45,
    LastPaymentDate: "3-May-2021",
    LastPaymentAmount: -250,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100005,
    DetailAssessments: [
      {
        Include: true,
        AssessmentNumber: 681110,
        PropertyAddress: "8 Beatties St, Almurta",
        BalanceOutstanding: 2755.58,
        BalanceDue: 2755.58,
        "2017/2018": 2755.13,
        "2016/2017": 242.45,
        LastPaymentDate: "27-Feb-2021",
        LastPaymentAmount: -250,
        LastReminderNoticeRun:
          "4th Instalment Notice - 16/17 Rates & Charges as at 4 April 2017 [416]",
        LastReminderIssueDate: "4-Apr-2021",
        Id: 2667,
      },
    ],

    BalancesByRatingPeriodAndDueDate: [
      {
        RatingPeriod: "2016/2017",
        DueDate: "31-May-2020",
        Balance: 24245,
        Total: 24245,
        No: 1,
      },
      {
        RatingPeriod: "2017/2018",
        DueDate: "31-Aug-2023",
        Balance: 470.52,
        InterestBalance: 25.27,
        Total: 495.79,
        No: 2,
      },
      {
        RatingPeriod: "2017/2019",
        DueDate: "5-Sep-2020",
        Balance: 564.3,
        Total: 564,
        No: 3,
      },
      {
        RatingPeriod: "2017/2020",
        DueDate: "30-NOV-2020",
        Balance: 467,
        InterestBalance: 15.78,
        Total: 432.78,
        No: 4,
      },
      {
        RatingPeriod: "2017/2021",
        DueDate: "28-Feb-2021",
        Balance: 467,
        InterestBalance: 6.56,
        Total: 473.56,
        No: 5,
      },
      {
        RatingPeriod: "2017/2022",
        DueDate: "31-May-2021",
        Balance: 467,
        Total: 467,
        No: 6,
      },
    ],
  },
  {
    ID: 6,
    FullNames: "HOLMES, Drothy Amelia\r\nand HOLUB, Olga Alexandra",
    Address: "PO Box 118\r\nPAKENHAM, VIC 3811",
    Assessments: "10 Beasleys St, Archies Creek [681056]",
    BalanceOutstanding: 507.78,
    BalanceDue: 507.78,
    "2017/2018": 507.78,
    LastPaymentDate: "14-Mar-2021",
    LastPaymentAmount: -507.78,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100006,
  },
  {
    ID: 7,
    FullNames: "BYRNE, Amanda Suzanne\r\nand BYRNE, Darren",
    Address: "2/25 Jauncey Pde\r\nCROYDON, NSW 2132",
    Assessments: "193 Bendanelle Rd, Archies Creek [681555]",
    BalanceOutstanding: 412,
    BalanceDue: 412,
    "2017/2018": 412,
    LastPaymentDate: "23-Feb-2021",
    LastPaymentAmount: -412.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100007,
  },
  {
    ID: 8,
    FullNames: "El-HAWLI, Ziad\r\nand EL-HOULI, Abdulan",
    Address: "66a Quarry Rd\r\nPAKENHAM VIC 3810",
    Assessments: "155 Boundary Rd Cape Woolamai [682484]",
    BalanceOutstanding: 783,
    BalanceDue: 783,
    "2017/2018": 783,
    LastPaymentDate: "10-Mar-2021",
    LastPaymentAmount: -782.11,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100008,
  },
  {
    ID: 9,
    FullNames: "CARLAND,Trevor Edward",
    Address: "13 Redgum Ct\r\nEMERALD, VIC 3782",
    Assessments: "15 Banjo Paterson St, Archies Creek [680199]",
    BalanceOutstanding: 717,
    BalanceDue: 717,
    "2017/2018": 717,
    LastPaymentDate: "20-Feb-2021",
    LastPaymentAmount: -716.99,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100009,
  },
  {
    ID: 10,
    FullNames: "ENGLISH, Sharon Lee\r\nand ENGLISH, Violet Kathleen",
    Address: "9 The Boulevarde\r\nGEMBROOK, VIC 3783",
    Assessments: "3 Berry St, Almarta [681802]",
    BalanceOutstanding: 440,
    BalanceDue: 440,
    "2017/2018": 440,
    LastPaymentDate: "28-Feb-2021",
    LastPaymentAmount: -440.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100010,
  },
  {
    ID: 11,
    FullNames:
      "KINNIBURGH, Shirley Jeannette\r\nand KINSELA Annis Provincial A\r\nand HREB, Agata\r\nand HREORESEN, Melissa Karyn\r\nand HANNA, Kamal\r\nand LE MELEDO, Suzanne Elizabeth\r\nand CARPENTER, Alwyn Lloyd Price-Estat\r\nand SMITH, Amada\r\nand HUTCHISON, Karian Grace\r\nand HUTCHISON, Loreta",
    Address: "PAKENHAM, VIC 3810",
    Assessments:
      "9 Albert Rd, Archies Creek [679027]\r\n4870 Barnes St, Archies Creek [680414]\r\n343 Mannig Rd, Archies Creek [680838]\r\n110 Bourkes Creek St, Archies Creek [682599]\r\n12 Barr St, Almurta [680486]",
    BalanceOutstanding: 3551.91,
    BalanceDue: 3551.91,
    "2017/2018": 3551.91,
    LastPaymentDate: "2-Mar-2021",
    LastPaymentAmount: -538.81,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 5,
    Assessment_Id: 100011,
  },
  {
    ID: 12,
    FullNames: "APPLEBY, E\r\nand MELMOTH, Edgar",
    Address: "49 Glastonbury Dr\r\nMORNINGTON, VIC 3931",
    Assessments: "10 Berry St, Almurta [681791]",
    BalanceOutstanding: 441,
    BalanceDue: 441,
    "2017/2018": 441,
    LastPaymentDate: "28-Feb-2021",
    LastPaymentAmount: -441.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100012,
  },
  {
    ID: 13,
    FullNames: "FRANKLIN, Graeme Robins\r\nand HOMEWOOD, Graham C",
    Address: "AVONSLEIGH, VIC 3782",
    Assessments: "462-464 Black Sands Ave, Archies Creek [681935]",
    BalanceOutstanding: 596,
    BalanceDue: 596,
    "2017/2018": 596,
    LastPaymentDate: "25-Feb-2021",
    LastPaymentAmount: -596.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 1000013,
  },
  {
    ID: 14,
    FullNames: "FREEMAN, Harold Alexander (Poal)\r\nand FREEMAN, Jeffrey John",
    Address: "RMB 6143\r\nAVONSLEIGH, VIC 3782",
    Assessments: "115 Blackwood Rd, Archies Creek [682126]",
    BalanceOutstanding: 398,
    BalanceDue: 398,
    "2017/2018": 398,
    LastPaymentDate: "2-Dec-2021",
    LastPaymentAmount: -398.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100014,
  },
  {
    ID: 15,
    FullNames: "GATT, Jason\r\nand GATT, Joanne Louise",
    Address: "G P O Box 2933\r\nNAR NAR GOON, VIC 3812",
    Assessments: "16 Amplett St, Almurta [679333]",
    BalanceOutstanding: 409,
    BalanceDue: 409,
    "2017/2018": 409,
    LastPaymentDate: "28-Feb-2021",
    LastPaymentAmount: -409.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100015,
  },
  {
    ID: 16,
    FullNames: "GELICRISIO, Lily\r\nand GELL, Karen Mary",
    Address: "PO Box 247\r\nKOO WEE RUP, VIC 3981",
    Assessments: "27 Bimberi St, Archies Creek [681862]",
    BalanceOutstanding: 445,
    BalanceDue: 445,
    "2017/2018": 445,
    LastPaymentDate: "14-Feb-2021",
    LastPaymentAmount: -445.87,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100016,
  },
  {
    ID: 17,
    FullNames: "GIUNTA, Salvatore",
    Address: "230 Archies Creek Rd\r\nPAKENHAM, VIC 3810",
    Assessments: "37B The Point Rd, [682452]",
    BalanceOutstanding: 860,
    BalanceDue: 860,
    "2017/2018": 860,
    LastPaymentDate: "23-Feb-2021",
    LastPaymentAmount: -860.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100017,
  },
  {
    ID: 18,
    FullNames: "GOODERHAM, Keith\r\nand GOODGAME, Terri Ann",
    Address: "50 Princeton Ave\r\nPAKENHAM, VIC 3810",
    Assessments: "222 Baker Ave, Archies Creek [679955]",
    BalanceOutstanding: 487,
    BalanceDue: 487,
    "2017/2018": 487,
    LastPaymentDate: "6-Feb-2021",
    LastPaymentAmount: -487.73,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100018,
  },
  {
    ID: 19,
    FullNames: "FORBES, Melanie Jane",
    Address: "81 Hagelthom St\r\nPAKENHAM, VIC 3810",
    Assessments: "340 Amphlett St, Almurta [3429224]",
    BalanceOutstanding: 240,
    BalanceDue: 240,
    "2017/2018": 240,
    LastPaymentDate: "23-Feb-2021",
    LastPaymentAmount: -240.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100019,
  },
  {
    ID: 20,
    FullNames: "GREENHAM, Paul Thomas",
    Address: "3 Scanlon St\r\nAVONSLEIGH, VIC 3782",
    Assessments: "20 Gladesville Rd, Archies Creek [680005] ",
    BalanceOutstanding: 843.29,
    BalanceDue: 843.29,
    "2017/2018": 843.29,
    LastPaymentDate: "23-Dec-2021",
    LastPaymentAmount: -835.81,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100020,
  },
  {
    ID: 21,
    FullNames: "GRIFFIN, David Bernard\r\nand KATONA, Stephen Christopher",
    Address: "193 Victoria Dr\r\nNARRE WARREN, VIC 3805",
    Assessments: "15 Backwood Rd, Archies Creek [682157]",
    BalanceOutstanding: 378.27,
    BalanceDue: 378.27,
    "2017/2018": 378.27,
    LastPaymentDate: "1-May-2021",
    LastPaymentAmount: -435.0,
    LastReminderNoticeRun:
      "4th Instalment Notice - 16/17 Rates & Charges as at 4 Aprill 2017[416]",
    LastReminderIssueDate: "4-Apr-2021",
    NumberOfAssessments: 1,
    Assessment_Id: 100021,
  },
];
