import { CSLSystemAdminConditionsForm } from "@app/products/csl/system-admin/conditions/[id]/components/child-screens/general/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

interface IProps {
  setIsLoading: (status: boolean) => void;
}
export const NewManageCSLSystemAdminConditions = ({ setIsLoading }: IProps) => {
  return (
    <>
      <FormNavigation title="Conditions Reference" />
      <FormTitle title={"New Condition Reference"} />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Cancel"} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel
              component={
                <CSLSystemAdminConditionsForm
                  isNew
                  setIsLoading={setIsLoading}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
