import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export interface IReasonDialogProps {
  onCloseDialog: () => void;
  onSubmit: (data: any) => void;
  notifications?: IAppNotificationItemAddProps;
  textAreaLabel?: string;
  title?: string;
}

export interface ISafetyReasonForm {
  SafetyReason: string;
}

const nameOf = nameOfFactory<ISafetyReasonForm>();

export const SafetyReasonDialog = observer(
  ({ onCloseDialog, onSubmit, textAreaLabel, title }: IReasonDialogProps) => {
    const { notification, setNotification } = useCRMSEventStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const handleSubmit = async (event: FormSubmitClickEvent) => {
      onSubmit(event.values);
    };

    useEffect(() => {
      if (notification) {
        notificationRef.current?.pushNotification(notification, () =>
          setNotification(undefined)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    return (
      <Form
        onSubmitClick={(event: FormSubmitClickEvent) => handleSubmit(event)}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, modified, valid } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth="35%"
                height="auto"
                titleHeader={title ? `${title} Issue Reason` : "Issue Reason"}
                onClose={() => {
                  onCloseDialog();
                }}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel
                            title={textAreaLabel ? textAreaLabel : ""}
                            isMandatory
                          />
                          <Field
                            name={nameOf("SafetyReason")}
                            component={CCTextArea}
                            validator={requiredValidator}
                            rows={3}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onCloseDialog();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!modified || !valid}
                      themeColor="primary"
                      onClick={onSubmit}
                      className={"cc-dialog-button"}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
