import { CreateComplianceRequest } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";
import { IODataResponse } from "@common/models/odataResponse";
import { VO_Officer } from "@common/models/officer";
import {
  CompositeFilterDescriptor,
  toODataString,
} from "@progress/kendo-data-query";

export const postCreateComplianceRequest = async (
  compliance: CreateComplianceRequest
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/compliance`,
      compliance
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getOfficerSearch = async (
  filter: CompositeFilterDescriptor
): Promise<APIResponse<IODataResponse<VO_Officer> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/odata/property/internal/officerregister?$count=true&${toODataString({
        filter: filter,
      })}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
