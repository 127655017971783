import { eventEmitter } from "@/App";
import { postCreateComplianceRequest } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/api";
import { ResponsibleOfficerSearch } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/components/responsible-officer/_index";
import {
  ComplianceStatus,
  CreateComplianceRequest,
  dataComplianceType,
} from "@app/products/property/assessments/compliance/component/dialog/create-request-status/model";
import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { useResponsibleOfficerStore } from "@common/stores/responsible-officer/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";

interface ICreateRequestDialog {
  onClose: () => void;
  requestStatus: ComplianceStatus;
}
const nameOf = nameOfFactory<CreateComplianceRequest>();
export const CreateRequestDialog = observer(
  ({ onClose, requestStatus }: ICreateRequestDialog) => {
    const { gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    //store responsibleOfficer
    const {
      isLoading: isLoadingOfficer,
      responseLoadError,
      reloadResponsibleOfficer,
      responsibleOfficer,
    } = useResponsibleOfficerStore();

    //process initial Data form
    const initialValues = useMemo(() => {
      return {
        ResponsibleOfficerUserId: responsibleOfficer?.Id,
        ComplianceType: 0,
        _option: { ResponsibleOfficerUser: responsibleOfficer },
      };
    }, [responsibleOfficer]);

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const handleSubmit = async (event: any) => {
      const submitData = event.values;
      setIsLoading(true);
      const payload: CreateComplianceRequest = {
        AssessmentIds: gridSelectedIds,
        ComplianceStatus: requestStatus,
        ComplianceType: submitData?.ComplianceType.Code,
        ResponsibleOfficerUserId: submitData?.ResponsibleOfficerUserId,
      };

      const response = await postCreateComplianceRequest(payload);
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          onClose();
          pushNotification({
            title:
              response.data?.Notifications?.length > 0
                ? response.data?.Notifications?.[0]
                : `Created compliances successfully`,
            type: "success",
          });
          eventEmitter.emit(CCGridEventType.RefreshOData);
        } else {
          notificationRef.current?.pushNotification({
            autoClose: false,
            title: `Created compliances could not be completed`,
            type: "error",
            description: response.data?.Errors,
          });
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: `Created compliances could not be completed`,
          type: "error",
        });
      }
    };

    const returnTitle = useMemo(() => {
      switch (requestStatus) {
        case ComplianceStatus.No_Review_Required:
          return "No Review Required";
        case ComplianceStatus.Under_Review:
          return "Initiate Compliance Review";
        default:
          return "Create Compliance Request";
      }
    }, [requestStatus]);

    return (
      <div className="cc-change-view-dialog">
        <Form
          onSubmitClick={handleSubmit}
          initialValues={initialValues}
          key={JSON.stringify(initialValues)}
          render={(formRenderProps: FormRenderProps) => {
            const { onChange } = formRenderProps;
            return (
              <>
                <CCDialog
                  height="auto"
                  maxWidth="30%"
                  titleHeader={returnTitle}
                  onClose={onClose}
                  bodyElement={
                    <>
                      {isLoading || isLoadingOfficer ? (
                        <Loading isLoading />
                      ) : responseLoadError ? (
                        <CCLoadFailed
                          responseError={responseLoadError}
                          onReload={() => {
                            reloadResponsibleOfficer();
                          }}
                        />
                      ) : (
                        <FormElement>
                          <CCLocalNotification ref={notificationRef} />
                          <div className="cc-form">
                            <div className="cc-field-group">
                              <div className="cc-form-cols-1">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Type
                                    <CCTooltip type="validator" />
                                  </label>
                                  <Field
                                    name={nameOf("ComplianceType")}
                                    validator={requiredValidator}
                                    component={CCSearchComboBox}
                                    data={dataComplianceType}
                                    textField="Name"
                                    dataItemKey="Code"
                                    isUseDefaultOnchange
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Responsible officer
                                    <CCTooltip type="validator" />
                                  </label>
                                  <Field
                                    name={"_option.ResponsibleOfficerUser"}
                                    component={ResponsibleOfficerSearch}
                                    textField={"FullName"}
                                    validator={requiredValidator}
                                    onChange={(event: ComboBoxChangeEvent) => {
                                      onChange(
                                        "_option.ResponsibleOfficerUser",
                                        { value: event.value }
                                      );
                                      onChange("ResponsibleOfficerUserId", {
                                        value: event.value?.Id,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormElement>
                      )}
                    </>
                  }
                  footerElement={
                    <div className={"cc-dialog-footer-actions-right"}>
                      <Button className={"cc-dialog-button"} onClick={onClose}>
                        Cancel
                      </Button>

                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        disabled={!formRenderProps.valid || isLoading}
                        iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                        onClick={formRenderProps.onSubmit}
                      >
                        OK
                      </Button>
                    </div>
                  }
                />
              </>
            );
          }}
        />
      </div>
    );
  }
);
