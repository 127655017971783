import { loadFinancialSummary } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/financial-summary/api";
import { FinancialSummary } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/financial-summary/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { numberValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import Loading from "@components/loading/Loading";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const SchemesFinancialSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FinancialSummary | undefined>(undefined);
  const nameOf = nameOfFactory<FinancialSummary>();
  useEffectOnce(() => {
    setIsLoading(true);
    loadFinancialSummary().then((response: any) => {
      if (!response || response.length === 0) {
        setIsLoading(false);
        return;
      }

      setData(response);
      setIsLoading(false);
    });
  });

  if (isLoading) return <Loading isLoading />;
  return (
    <div className="cc-form">
      <Form
        initialValues={{ ...data }}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <section className="cc-field-group">
              <div className="cc-field">
                <label className="cc-label">Account's share of costs</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Estimated costs</label>
                      <Field
                        name={nameOf("EstimatedCosts")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Estimated percentage</label>
                      <Field
                        name={nameOf("EstimatedPercentage")}
                        min={0}
                        step={0.1}
                        validator={numberValidator}
                        component={NumericTextBox}
                        format={PERCENTAGE_FORMAT.PERCENTAGE}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Actual costs</label>
                      <Field
                        component={CCCurrencyInput}
                        name={nameOf("ActualCosts")}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Actual percentage</label>
                      <Field
                        component={NumericTextBox}
                        name={nameOf("ActualPercentage")}
                        format={PERCENTAGE_FORMAT.PERCENTAGE}
                        min={0}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Calculation</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Size</label>
                      <Field
                        component={CCInput}
                        name={nameOf("Size")}
                        placeholder={"Size"}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Coefficient</label>
                      <Field
                        component={CCInput}
                        name={nameOf("Coefficient")}
                        placeholder={"Coefficient"}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Number of units</label>
                      <Field
                        component={CCInput}
                        name={nameOf("NoofUnits")}
                        placeholder={"Number of units"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Amount outstanding</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Principal balance</label>
                      <Field
                        name={nameOf("PrincipalBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Interest balance</label>
                      <Field
                        name={nameOf("InterestBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Other balance</label>
                      <Field
                        name={nameOf("OtherBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Balance outstanding</label>
                      <Field
                        name={nameOf("BalanceOutstanding")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Withheld interest balance
                      </label>
                      <Field
                        name={nameOf("WithheldInterestBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Amount due</label>
                      <Field
                        name={nameOf("AmountDue")}
                        label={"Amount due"}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Payout amount</label>
                      <Field
                        name={nameOf("PayoutAmount")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Interest details</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Interest rate </label>
                      <Field
                        component={CCInput}
                        name={nameOf("InterestRate")}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Interest raised up to</label>
                      <Field
                        component={CCInput}
                        name={nameOf("InterestRaisedUpTo")}
                        placeholder={"Interest raised up to"}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Maximum interest amount
                      </label>
                      <Field
                        component={CCCurrencyInput}
                        name={nameOf("MaximumInterestAmount")}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Next interest date</label>
                      <Field
                        component={CCDatePicker}
                        name={nameOf("NextInterestDate")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Withhold until</label>
                      <Field
                        component={CCInput}
                        name={nameOf("WithholdUntil")}
                        placeholder={"Withhold until"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Scheme</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Scheme number</label>
                      <Field
                        component={CCInput}
                        name={nameOf("SchemeNumber")}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Scheme ID</label>
                      <Field
                        component={CCInput}
                        name={nameOf("SchemeId")}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Name</label>
                      <Field
                        component={CCInput}
                        name={nameOf("Name")}
                        readOnly
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Type</label>
                      <Field
                        component={CCInput}
                        name={nameOf("SchemesType")}
                        readOnly
                      />
                    </div>

                    <div className="cc-field">
                      <label className="cc-label">Initiated</label>
                      <Field
                        disabled
                        name={nameOf("Initiated")}
                        component={CCDatePicker}
                        format={DATE_FORMAT.DATE_CONTROL}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Estimated cost</label>
                      <Field
                        name={nameOf("EstimatedCost")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Declared</label>
                      <Field
                        component={CCDatePicker}
                        name={nameOf("Declared")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Subsidy</label>
                      <Field
                        component={CCDatePicker}
                        name={nameOf("Subsidy")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Commenced</label>
                      <Field
                        component={CCDatePicker}
                        name={nameOf("Commenced")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Cost to assessments</label>
                      <Field
                        name={nameOf("CostToAssessments")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Comment</label>
                      <Field
                        component={CCInput}
                        name={nameOf("Comment")}
                        placeholder={"Comment"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Instalment details</label>
                <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Type</label>
                      <Field
                        component={CCInput}
                        name={nameOf("Type")}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Instalment amount</label>
                      <Field
                        component={CCCurrencyInput}
                        name={nameOf("InstalmentAmount")}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Number of instalments</label>
                      <Field
                        component={CCInput}
                        name={nameOf("NumberOfInstalments")}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Next instalment date</label>
                      <Field
                        component={CCDatePicker}
                        name={nameOf("NextInstalmentDate")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Last instalment number</label>
                      <Field
                        component={CCInput}
                        name={nameOf("LastInstalmentNo")}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">
                        Take overpayments off principal balance
                      </label>
                      <Field
                        name={nameOf("TakeOverpaymentsOffPrincipalBalance")}
                        component={CCSwitch}
                        defaultChecked={true}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </FormElement>
        )}
      />
    </div>
  );
};
