import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import {
  ICommonFormProps,
  ISwitchCustomeFieldProps,
} from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { DropdownSelectGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown-select/_index";
import { DatePickerGlobalSetting } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/pick-date-input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();

  const eRenewalMethod = ECorporateSettingsField.Animals_RenewalMethod;
  const eEnableQuickEntryBatchRenew =
    ECorporateSettingsField.Animals_EnableQuickEntryBatchRenew;
  const eDefaultCurrentTagNoToNewTagNo =
    ECorporateSettingsField.Animals_DefaultCurrentTagNoToNewTagNo;

  const animalsRenewalMethodData = getConfigDataField(eRenewalMethod);

  const batchRenewMethodAndNewTagNo: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: eEnableQuickEntryBatchRenew,
    },
    {
      eSettingsField: eDefaultCurrentTagNoToNewTagNo,
      isVisible: valueGetter(`${eEnableQuickEntryBatchRenew}`),
    },
    {
      eSettingsField:
        ECorporateSettingsField.Animals_EnableBatchRenewRecieptDialogBox,
    },
    {
      eSettingsField: ECorporateSettingsField.Animals_CreateFeeOnRenewal,
    },
  ];

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <DatePickerGlobalSetting
          data={getConfigDataField(ECorporateSettingsField.Animals_RenewalDate)}
          isEditing={isEditing}
        />

        <DropdownSelectGlobalSettings
          data={animalsRenewalMethodData}
          isEditing={isEditing}
          onChange={(e: DropDownListChangeEvent) => {
            onChange(`${eRenewalMethod}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eRenewalMethod}`),
            animalsRenewalMethodData?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />

        {batchRenewMethodAndNewTagNo.map((item: ISwitchCustomeFieldProps) => {
          if (item?.isVisible === false) return null;
          return (
            <SwitchGlobalSettings
              data={getConfigDataField(item.eSettingsField)}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          );
        })}
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
