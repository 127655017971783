import { SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE } from "@common/pages/settings/security/corporate-authorisations/constant";
import { Svc_CorporateAuthorisations } from "@common/pages/settings/security/corporate-authorisations/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_CorporateAuthorisations>();
export const colCorporateAuthorisations: IColumnFields[] = [
  {
    title: "Name",
    field: nameOf("CorporateAuthorisationName"),
    locked: true,
    linkTo: (dataItem: any) =>
      `${SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE}/${dataItem.ID}`,
  },
  { title: "Description", field: nameOf("CorporateAuthorisationDescription") },
];
