import { RegisterMetersDetails } from "@app/products/property/registers/[id]/components/child-screens/meters/components/detail/_index";
import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";
import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const RegisterMeters = observer(() => {
  const {
    isLoading,
    loadMeters,
    gridColumns,
    metersData,
    setMetersSelected,
    resetStore,
  } = useRegisterMetersStore();

  const nameOf = nameOfFactory<IRegisterMeters>();

  useEffectOnce(() => {
    loadMeters();
    return () => {
      resetStore();
    };
  });
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              isLoading={isLoading}
              data={metersData}
              selectableMode="multiple"
              columnFields={gridColumns}
              primaryField={nameOf("Id")}
              detail={RegisterMetersDetails}
              onSelectionChange={(data) => setMetersSelected(data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
