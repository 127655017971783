export const mockWorkOrders = [
  {
    WorkOrderId: 9,
    AssignedContractor: "<To be Assigned>",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("27-Sep-2018"),
    Locality: "Lenah Valley",
    Street: "Alcides",
    HouseNumber: "25",
    Owners: "M J Zawadzki && Z Zawadzki",
    CreatedOn: new Date("27-Sep-2018 14:17"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    AssessmentNumber: 5478388,
    ParcelId: 112965,
    CaseId: 28,
  },
  {
    WorkOrderId: 8,
    AssignedContractor: "Mark Steven",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("19-Sep-2018"),
    Locality: "West Hobart",
    Street: "Stevens Farm",
    HouseNumber: "9",
    Owners: "S W Hennessy & C J Hennessy",
    CreatedOn: new Date("19-Sep-2018 13:24"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    AssessmentNumber: 3378113,
    ParcelId: 255050,
    CaseId: 13,
  },
  {
    WorkOrderId: 7,
    AssignedContractor: "Nick Paine",
    Clearance: "Remove All Undergrowth",
    ToBeClearedBy: new Date("25-Sep-2018"),
    Locality: "Sandy Bay",
    Street: "Churchill",
    HouseNumber: "254",
    Owners: "L Zheng",
    CreatedOn: new Date("10-Sep-2018 17:17"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    AssessmentNumber: 5605797,
    ParcelId: 171300,
    CaseId: 22,
  },
  {
    WorkOrderId: 6,
    AssignedContractor: "Nick Paine",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Locality: "Sandy Bay",
    Street: "Ada",
    HouseNumber: "6",
    Owners: "A Grillas",
    CreatedOn: new Date("4-Sep-2018 08:26"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    AssessmentNumber: 5682581,
    ParcelId: 206565,
    CaseId: 20,
  },
  {
    WorkOrderId: 5,
    AssignedContractor: "Mark Steven",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("5-Sep-2018"),
    DateCleared: "12-Sep-2018",
    NotifiedByContractorOn: new Date("10-Sep-2018"),
    Locality: "West Hobart",
    Street: "Stevens Farm",
    HouseNumber: "9",
    Owners: "S W Hennessy & C J Hennessy",
    SentOn: "12-Sep-2018 11:38",
    SentBy: "wisem",
    CreatedOn: new Date("31-Aug-2018 15:27"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    AssessmentNumber: 3378113,
    ParcelId: 255050,
    CaseId: 13,
  },
  {
    WorkOrderId: 4,
    AssignedContractor: "Nick Paine",
    Clearance: "30m Fire Break",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Locality: "Hobart",
    Street: "Bathurst",
    HouseNumber: "12 - 16",
    Owners: "University Of Tasmania",
    SentOn: "30-Aug-2018 10:54",
    SentBy: "kleineb",
    CreatedOn: new Date("30-Aug-2018 10:53"),
    CreatedBy: "kleineb",
    Period: "2018/2019",
    ParcelId: 194430,
    CaseId: 10,
  },
  {
    WorkOrderId: 3,
    AssignedContractor: "Ben Godfrey",
    Clearance: "Remove All Undergrowth",
    ToBeClearedBy: new Date("5-Sep-2018"),
    NotifiedByContractorOn: new Date("19-Sep-2018"),
    Locality: "New Town",
    Street: "Montagu",
    HouseNumber: "57",
    Owners: "M J Gray",
    SentOn: "12-Sep-2018 08:20",
    SentBy: "wisem",
    CreatedOn: new Date("30-Aug-2018 10:52"),
    CreatedBy: "watchorne",
    Period: "2018/2019",
    AssessmentNumber: 3007670,
    ParcelId: 128385,
    CaseId: 11,
  },
  {
    WorkOrderId: 2,
    AssignedContractor: "Ben Godfrey",
    Clearance: "10m Fire Break",
    ToBeClearedBy: new Date("20-Aug-2018"),
    Locality: "Glebe",
    Street: "Lillie",
    HouseNumber: "9",
    Owners: "D Reinbach & T Reinbach",
    SentOn: "30-Aug-2018 10:23",
    SentBy: "hoskingr",
    CreatedOn: new Date("30-Aug-2018 10:50"),
    CreatedBy: "hoskingr",
    Period: "2018/2019",
    AssessmentNumber: 1854438,
    ParcelId: 109140,
    CaseId: 5,
  },
  {
    WorkOrderId: 1,
    AssignedContractor: "Ben Godfrey",
    Clearance: "5m Fire Break",
    ToBeClearedBy: new Date("4-Aug-2018"),
    DateCleared: "11-Aug-2018",
    NotifiedByContractorOn: new Date("13-Aug-2018"),
    Locality: "Fern Tree",
    Street: "Grays",
    HouseNumber: "14",
    Owners: "D C Mackie",
    SentOn: "14-Aug-2018 14:14",
    SentBy: "wisem",
    CreatedOn: new Date("14-Aug-2018 14:14"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    AssessmentNumber: 5576360,
    ParcelId: 157810,
    CaseId: 4,
  },
];
