import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAreaResponsibility } from "@app/products/property/certificates/[id]/components/child-screens/general/components/area-of-responsibilites/config";
import { DTO_AreaOfResponsibility } from "@app/products/property/certificates/[id]/model";
import { ViewConfiguration } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<DTO_AreaOfResponsibility>();
interface ICertificateAreOfResponsibilities {
  data: DTO_AreaOfResponsibility[];
}
export const CertificateAreOfResponsibilities = ({
  data,
}: ICertificateAreOfResponsibilities) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [colsConfig, setColsConfig] = useState<IColumnFields[]>(
    colAreaResponsibility
  );

  const loadViewConfig = async () => {
    setIsLoading(true);

    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Certificate_AreaofResponsibility,
      colAreaResponsibility
    );
    if (Array.isArray(response)) {
      setColsConfig(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <CCGrid
      primaryField={nameOf("AreaOfResponsibility_Id")}
      columnFields={colsConfig}
      data={data ?? []}
    />
  );
};
