export const TownPlanningFieldMapping = {
  ApplicationNumber: "ApplicationNumber",
  SiteAddress: "SiteAddress",
  Locality: "Locality",
  Status: "Status",
  AppealDays: "AppealDays",
  Planner: "Planner",
  ReasonForPermit: "ReasonForPermit",
  AppType: "AppType",
  AssessmentNo: "AssessmentNo",
  LodgedDate: "LodgedDate",
  Postcode: "Postcode",
  StatusWithSubStatus: "StatusWithSubStatus",
  Decision: "Decision",
  DecisionDate: "DecisionDate",
  DecisionType: "DecisionType",
  FinalDecision: "FinalDecision",
  FinalDecisionDate: "FinalDecisionDate",
  FinalDecisionType: "FinalDecisionType",
  Applicant: "Applicant",
  ApplicantAddress: "ApplicantAddress",
  ApplicantContact: "ApplicantContact",
  Contact: "Contact",
  PreferredAddress: "PreferredAddress",
  PreferredContact: "PreferredContact",
  FileNo: "FileNo",
  RIN: "RIN",
  Ward: "Ward",
  Rating: "Rating",
  CostOfWorks: "CostOfWorks",
  StatDays: "StatDays",
  ContactPerson: "ContactPerson",
  LandDivision: "LandDivision",
  PlanningApplicationNumber: "PlanningApplicationNumber",
  SpearRef: "SpearRef",
  POSNo: "POSNo",
  ReferredDate: "ReferredDate",
  CertifiedDate: "CertifiedDate",
  RecertifiedDate: "RecertifiedDate",
  ComplianceDate: "ComplianceDate",
  TitlesOffice: "TitlesOffice",
  Lots: "Lots",
  CommercialLots: "CommercialLots",
  SpaceRequired: "SpaceRequired",
  Valuation: "Valuation",
  POSDue: "POSDue",
  Estate: "Estate",
  SpearReference: "SpearReference",
  ApplicantName: "ApplicantName",
  ApplicationStatus: "ApplicationStatus",
  ApplicationType: "ApplicationType",
  CouncilName: "CouncilName",
  FullAddress: "FullAddress",
  SubmittedDate: "SubmittedDate",
  CouncilReferenceNumber: "CouncilReferenceNumber",
  IsUpdated: "IsUpdated",
  ApplicationNo: "ApplicationNo",
  AppealNo: "AppealNo",
  Officer: "Officer",
  CouncilRepresentation: "CouncilRepresentation",
  DateLodged: "DateLodged",
  ApplicationOfficerDecision: "ApplicationOfficerDecision",
  ApplicationDecision: "ApplicationDecision",
  ApplicationDateDecided: "ApplicationDateDecided",
  DateAppealed: "DateAppealed",
  Grounds: "Grounds",
  HearingDate: "HearingDate",
  HearingType: "HearingType",
  TribunalMember: "TribunalMember",
  DecisionStatus: "DecisionStatus",
  KeyIssues: "KeyIssues",
  DecisionPolicyIssues: "DecisionPolicyIssues",
  CouncilPosition: "CouncilPosition",
  CouncilPositionDate: "CouncilPositionDate",
  HearingCost: "HearingCost",
  ReferenceNumber: "ReferenceNumber",
  Date_Lodged: "Date_Lodged",
  Register_Number: "Register_Number",
  Address: "Address",
  Type: "Type",
  Description: "Description",
  Days: "Days",
  Date_Due: "Date_Due",
  Date_Complete: "Date_Complete",
  LegalDescription: "LegalDescription",
  Outcome: "Outcome",
  CRMSEventReferenceNumber: "CRMSEventReferenceNumber",
  IPAddressUnitNo: "IPAddressUnitNo",
  IPAddressStreetNo: "IPAddressStreetNo",
  IPAddressStreetName: "IPAddressStreetName",
  IPAddressSuburbStatePostcode: "IPAddressSuburbStatePostcode",
  IPPostalAddress: "IPPostalAddress",
  IPName: "IPName",
  IPCompany: "IPCompany",
  IPAddress: "IPAddress",
  Attachment_Date: "Attachment_Date",
  Attachment_Title: "Attachment_Title",
  IPCategory: "IPCategory",
  IPType: "IPType",
  Category: "Category",
  Details: "Details",
  StatusOnly: "StatusOnly",
  DateRequested: "DateRequested",
  DateDue: "DateDue",
  DateInfoReceived: "DateInfoReceived",
  DateDecisionToAdvertise: "DateDecisionToAdvertise",
  DateAdvertisingStarts: "DateAdvertisingStarts",
  NumberOfSigns: "NumberOfSigns",
  NumberOfLetters: "NumberOfLetters",
  NumberOfPublications: "NumberOfPublications",
  MeetingDate: "MeetingDate",
  MeetingLocation: "MeetingLocation",
  MeetingType: "MeetingType",
  MeetingCouncillor: "MeetingCouncillor",
  MeetingOfficer: "MeetingOfficer",
  RefNo: "RefNo",
  DateTarget: "DateTarget",
  Source: "Source",
  Location: "Location",
  RequestedBy: "RequestedBy",
  ContactDetails: "ContactDetails",
  DueStatus: "DueStatus",
  DateRecorded: "DateRecorded",
  RecordedBy: "RecordedBy",
  OrgStructure: "OrgStructure",
  ActionOfficer: "ActionOfficer",
  Coordinator: "Coordinator",
  DateWork: "DateWork",
  FileNumber: "FileNumber",
  EnquiryRefNo: "EnquiryRefNo",
  Event_ReferenceNoAdditional: "Event_ReferenceNoAdditional",
  OnBehalfOf: "OnBehalfOf",
  Flag_ITSupport: "Flag_ITSupport",
  Flag_Safety: "Flag_Safety",
  Flag_Risk: "Flag_Risk",
  Flag_VIP: "Flag_VIP",
  CouncilAuthorisation: "CouncilAuthorisation",
  CouncilAuthorisationDate: "CouncilAuthorisationDate",
  ApplicantType: "ApplicantType",
  Gazzetted: "Gazzetted",
  Withdrawn: "Withdrawn",
  Contact2: "Contact2",
  LevelOfEnforcement: "LevelOfEnforcement",
  Number: "Number",
  Risk: "Risk",
  DateOffence: "DateOffence",
  DateServed: "DateServed",
  DateRevoked: "DateRevoked",
  ContactName: "ContactName",
  CreatedDate: "CreatedDate",
  FeeType: "FeeType",
  AmountIssued: "AmountIssued",
  AmountOutstanding: "AmountOutstanding",
  CostCode: "CostCode",
  AmountPaid: "AmountPaid",
  ReceiptNumber: "ReceiptNumber",
  ReceiptDate: "ReceiptDate",
  ReceiptedBy: "ReceiptedBy",
  Method: "Method",
  InvoiceNumber: "InvoiceNumber",
  DebtorNumber: "DebtorNumber",
  SecurePay_Reference: "SecurePay_Reference",
  MerchantReference: "MerchantReference",
  VicSmart: "VicSmart",
  OsBalance: "OsBalance",
  ePlanningCategory: "ePlanningCategory",
  Owner: "Owner",
  ePlanningLodgedBy: "ePlanningLodgedBy",
  Comments: "Comments",
  RecordedDate: "RecordedDate",
  Date_Recorded: "Date_Recorded",
  EPlanning_Categories: "EPlanning_Categories",
  Title: "Title",
  FileName: "FileName",
  ParentRefNo: "ParentRefNo",
  ParentDesc: "ParentDesc",
  Name: "Name",
  IsHidden: "IsHidden",
  IsSelectable: "IsSelectable",
  IsActive: "IsActive",
  HasOtherInputs: "HasOtherInputs",
  CostOfWorkv: "CostOfWorkv",
  SubdivisionLots: "SubdivisionLots",
  CategoryType: "CategoryType",
  ApplicationCategory_Name: "ApplicationCategory_Name",
  ApplicationCategory_Description: "ApplicationCategory_Description",
  TownPlanning: "TownPlanning",
  PSA: "PSA",
  OAs: "OAs",
  Code: "Code",
  TargetDays: "TargetDays",
  SortIndex: "SortIndex",
  ConditionCode: "ConditionCode",
  Condition: "Condition",
  Multiplier: "Multiplier",
  RateType: "RateType",
  Value: "Value",
  MinimumValue: "MinimumValue",
  Regulation: "Regulation",
  Class: "Class",
  ApplyTo_Name: "ApplyTo_Name",
  DeletedOn: "DeletedOn",
  DeletedBy: "DeletedBy",
  ComplaintNo: "ComplaintNo",
  DateReceived: "DateReceived",
  DateOccured: "DateOccured",
  SubCategory: "SubCategory",
  ComplaintStatus: "ComplaintStatus",
  DateClosed: "DateClosed",
  Group: "Group",
};

export enum TownPlanningRoute {
  TOWNPLANNING_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/townplanning/register",
  TOWNPLANING_DETAIL = "/townplanning/manageregistration",
}

export enum UITownPlanning {
  SiteAddress,
  Applicant,
  Builder,
  BuildingOfficerLodge,
  PreferredContact,
  AddRelated,
  PrivateCertifier,
  PrivateCertifierFromSurveyorsAddressBook,
  Planner,
  PreAppMeetingOfficer,
  EnforcementIssueOfficer,
  BuildingOfficer,
  AddComment,
  AddAdditonalAddress,
  AddAttachment,
  ApplicationCategory,
  FeeSchedule,
  Lodge,
  AllocateToPlanner,
  PlannerAssessment,
  PlannerAssessmentConfirm,
  RevisionReceived,
  RevisionAccepted,
  AppealComplete,
  FinalDecision,
  ViewBuildingDetails,
  ViewAssessmentDetails,
  Amend,
  BuildingDetails,
  EOT,
  AddStdCondtion,
  AllocateToBuildingOfficer,
  BuildingOfficerAssessment,
  IssueBuildingPermit,
  IssueBuildingCertificate,
  Issue,
  IssueNOD,
  ReadyForDecision,
  PickDescription,
  PickUseOfBuilding,
  AddMultiFees,
  FileNumber,
  AddAttachmentFromTRIM,
  PickZone,
  PickSLMZone,
  PickOverlay,
  PickPermitTrigger,
  AddCalculatedFee,
  PickBCA,
  PickInspectionsRequired,
  PermitReceived,
  ePlanning,
  ManagePortalLinks,
  ABS,
  SendPPARS,
  SendLevyReturn,
  PreviewPPARS,
  ApplicationDescription,
  PlansToComply,
  ApplicationNumber,
  StatusChange,
  LodgedDateChange,
  ExtendTargetDate,
  IPFromProperty,
  TRIMAdmin,
  AdvertisingNotRequired,
  FINotRequired,
  DecisionChange,
  AppealCompleteUndo,
  ReasonForPermit,
  PickBCAVersion,
  PickBuildingSpecificWork,
  ExecutiveSummary,
  AddPhoto,
  PickDebtorNo,
  PickSubmitters,
  PrescribeEvents,
  ApplicationNumberWithFileNo,
  ApplicationNumberWithoutFileNo,
  ConfirmIssue,
  ConfirmIssueNOD,
  ConfirmRefuse,
  ConfirmNotExist,
  TPSharePointDocumentLibrary,
  TPSharePointDocumentSet,
  Refer,
  Certify,
  Recertify,
  Compliance,
  Complete,
  Refuse,
  Withdraw,
  Expire,
  ReferAdd,
  AddMultiReferrals,
  PlanningAppNo,
  AddMeeting,
  AddInspection,
  AddIPFromFile,
  AddIPFromMap,
  DialogCIL,
  LinkSPEARApp,
  DialogLDChecklist,
  DialogMPL,
  DialogPickSubdivisionFeeTypes,
  DialogPortalBuildingFeeTypes,
  DialogPickPortalApplicationFee,
  DialogPickPortalEnquiryFee,
  PickRegulation,
  UnrelatedComplaints,
  PSAMinisterApproved,
  PickProposedFinalOutcome,
  CouncilDecision,
  PickApplicantAsCouncillor,
  MinisterAuthorisation,
  CouncilFinalDecision,
  Gazetted,
  PickPermitType,
  SkipWorkflow,
  Plumber,
  PickApplicationType,
  PlumbingDetails,
  IssuePlumbingCertificate,
  IssueNotifiableCertificate,
  PlumbingOfficerAssessment,
  CouncilConsideration,
  CouncilAuthorisation,
  Appealed,
  WithdrawnComments,
  Validated,
  Practitioners,
  Recommendation,
  ReadyForResponce,
  SendResponse,
  SubSharePointDocumentLibrary,
  SubSharePointDocumentSet,
  ResponsibleAuthority,
  PlanningRegionCoordinator,
  PlanningReferralTrigger,
  Rating,
  PickRegion,
  PickSuburb,
  TeamLeader,
  FurtherInformation,
  PPRResponseLetterDocument,
  PPRReportLetterDocument,
  SendApprovedResponseReportLetterToRAAndApplicant,
  ResponseReportLetterNotApproved,
  ApproveReport,
  ApprovePanelReport,
  PanelReportNotApproved,
  PanelHearingDecision,
  PlannerPrelimAssessment,
  PrincipleSupportAssessment,
  PrincipleSupportDecision,
  PickStateRoad,
  PPRPickSubdivision,
  PPRSendForApproval,
  SendLetterToRAAndApplicant,
  PlansToComplyApproveLetter,
  PlansToComplyRejectLetter,
  DialogConflictOfInterest,
  DialogPickBuildingWorks,
  AddReferral,
  DialogPickPortalObjector,
  DialogPickPortalSupporter,
  DialogPickPortalConditionalSupporter,
  DialogPickObjector,
  AddClause,
  Start_StatClock,
  Stop_StatClock,
  ApplicationPermitNumber,
  ApplicationWorksCommenceByDate,
  ApplicationWorksCompleteByDate,
  DialogPickSupporter,
  BatchSetBAMSRefNo,
  BatchStatusChange,
  PickStateLocalRoad,
  PermitNotRequiredComments,
  PSAApplicantType,
  PSARResponseLetterDocument,
  PSARReportLetterDocument,
  PSARProponentResponse,
  PSARPanelHearingReportLetterDocument,
  SendReportToPanelHearing,
  ApplicationType,
  BuildingInspectionComplianceType,
  PickCouncilDetails,
  ConfirmClearAllPublished,
  Payer,
  BillingAddress,
  RejectApplication,
  LodgeOfficer,
  AddWorkFlowAction,
  AllocateToDAOfficer,
  PickDAOutcome,
  AllocateToAHIMSOfficer,
  PickAHIMSOutcome,
  AllocateToEECOfficer,
  PickEECOutcome,
  AllocateToOEHOfficer,
  PickOEHOutcome,
  AllocateToLEPOfficer,
  PickLEPOutcome,
  AllocateToOGRFOfficer,
  PickOGRFOutcome,
  AllocateToTSOfficer,
  PickTSOutcome,
  PickLots,
  PickLGA,
  PickReferenceNo,
  PickDynamicQuestionList,
  UpdateQuestionList,
  PickExcludeContactRelTypes,
  ApplicationEdit,
  ApplicationTerminate,
  AddAttachmentandExternalDocumentType,
  Owner,
  AddIssueAttachmentandExternalDocumentType,
  AddRefuseAttachmentandExternalDocumentType,
  ApplicationClosure,
  SeniorOfficer,
  Delegate,
  InfoXpertFileNumber,
  PlannerAssessment_NSW,
  PlumbingAppNo,
  EnforcementNo,
  BuildingAppNo,
  CreateForestryOpreation,
  ChangeOwnership,
  UpdateAddressFromTabTable,
  PoolSpaRenew,
  PlanningPermitNo,
  PickIBRASubRegion,
  PickSLMRegion,
  Inactivate,
  UpdateApplicant,
  UpdatePreferredContact,
  PickBCASecondaryClass,
  Cancel,
  BatchUpdateDueDates,
  CreateApplication,
  PPRSendForApprovalComments,
  OfficerRecommendationComments,
  PlansToComplySendForApprovalNotes,
  BuildingPermitNo,
  PickDebtorNoAndSave,
  ReferralNotRequired,
  SendReportResponseForApproval,
  SendHearingSubmissionReportForApproval,
}
