import { RECORDTYPE } from "@common/constants/recordtype";
import { IAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IAccessControl>();
export const colSupperAdminSettings: IColumnFields[] = [
  { field: nameOf("EntityDisplayName"), title: "Name", width: "800" },
];

export const defaultSupperAdminSettingsStyleGrid = {
  maxHeight: 600,
  height: 600,
};

export interface AccessControlSuperAdmin {
  AccessControls: IAccessControl[];
  RecordSource: RecordSourceIdentifier;
}

export interface RecordSourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: RECORDTYPE;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
}

export const recordSourceDefault: RecordSourceIdentifier = {
  _RecordSource_ID: 0,
  _RecordSourceType_ENUM: RECORDTYPE.CORE_GlobalSettings,
  _RecordSource_ColumnName: "",
  _RecordSource_StringID: "",
  _RecordSource_FileNumber: "",
};
