import { SETTINGS_LOOKUPS_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/documents/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface NewDocumentButtonProps {
  disabled?: boolean;
}
export const NewDocumentButton = ({ disabled }: NewDocumentButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${SETTINGS_LOOKUPS_DOCUMENTS_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
