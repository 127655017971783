import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT } from "@common/constants/common-format";

export const colHMRecallsByMonth = [
  {
    field: HMFieldMapping.Date_Recall,
    title: "Date",
    locked: true,
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Number,
    title: "Number",
    width: 150,
  },
  {
    field: HMFieldMapping.ProductType,
    title: "Product Type",
    width: 150,
  },
  {
    field: HMFieldMapping.ProductName,
    title: "Product Name",
    width: 150,
  },
  {
    field: HMFieldMapping.ReferenceNumber,
    title: "Registration Number",

    width: 150,
  },
  {
    field: HMFieldMapping.TradingName,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
];
