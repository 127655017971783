import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { colBalanceTransfer } from "@app/products/property/supplementary-rates/[id]/components/child-screens/balance-transfers/config";
import { VO_Supplementary_BalanceTransfer } from "@app/products/property/supplementary-rates/[id]/components/child-screens/balance-transfers/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfBalanceTransfers =
  nameOfFactory<VO_Supplementary_BalanceTransfer>();
export const SupplementaryRatesBalanceTransfers = observer(() => {
  const { supplementaryRatesId } = useSupplementaryRatesStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedCols, setProcessedCols] =
    useState<IColumnFields[]>(colBalanceTransfer);

  const loadSupplementaryRatesBalanceTransfersConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    let response = await loadViewConfiguresColumns(
      ViewConfiguration.SupplementaryRates_BalanceTransfers,
      colBalanceTransfer
    );
    if (Array.isArray(response)) {
      setProcessedCols(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadSupplementaryRatesBalanceTransfersConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;

  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadSupplementaryRatesBalanceTransfersConfig();
      }}
    />
  ) : (
    <div className="cc-supplementary-rates-balance-transfers">
      <CCGrid
        dataUrl={`/odata/property/internal/supplementaryratesbalancetransferregister/${supplementaryRatesId}?$count=true&`}
        columnFields={processedCols}
        primaryField={nameOfBalanceTransfers("From_Assessment_Id")}
      />
    </div>
  );
});
