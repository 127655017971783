import { Application_Status_Int } from "@app/products/town-planning/ppr/constant";
import { StatusChangeDetails } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/model";
import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getPSARStatus = async (
  currentStatus?: Application_Status_Int
): Promise<APIResponse<IKeyValuePacket[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `api/tp/ppr/internal/application/psar/status`,
      {
        params: {
          currentStatus: currentStatus,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putPSARStatus = async (
  id: number,
  reqBody: StatusChangeDetails
): Promise<APIResponse<PPRApplicationIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `api/tp/ppr/internal/application/psar/${id}/changestatus`,
      reqBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
