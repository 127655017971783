import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getViewConfigurations } from "@app/products/property/api";
import { AssessmentDetailTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/_index";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { RebatesListDetailConcessionCards } from "@app/products/property/assessments/rebates/concession-cards/all/components/detail/_index";
import { RebateConcessionCardContactTab } from "@app/products/property/assessments/rebates/concession-cards/components/reference-sidebar/contact/_index";
import { colConcessionCards } from "@app/products/property/assessments/rebates/concession-cards/config";
import { GET_VIEW_REBATES_CONCESSION_CARDS_ACT_ENTITLEMENT } from "@app/products/property/assessments/rebates/concession-cards/constant";
import { VO_Assess_Rebate_Entitlement_Concession_Card } from "@app/products/property/assessments/rebates/concession-cards/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/rebates/concession-cards/only-with-active-rebate-entitlement/util";
import { ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE } from "@app/products/property/assessments/rebates/rebate-claims/[id]/constant";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Assess_Rebate_Entitlement_Concession_Card>();
const gridId = getUUID();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Assessment}
        recordType={RECORDTYPE.CommunityProperty_Concession_Card}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Concession_Card}
        keyField="ID"
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton gridId={gridId} />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <RebateConcessionCardContactTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            primaryField={nameOf("Assessment_Id")}
            recordType={RECORDTYPE.CommunityProperty_Assessment}
          />
        ),
      },
    ],
  });

  //State
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColumns, setProcessedColumns] =
    useState<IColumnFields[]>(colConcessionCards);

  const loadData = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.OData_Rebates_ConcessionCards).then(
      (response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedColumns([]);
            setIsLoading(false);
            return;
          }
          setProcessedColumns(
            processDynamicColumns(colConcessionCards, viewConfig)
          );
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffectOnce(() => {
    loadData();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      gridId={gridId}
      dataUrl={GET_VIEW_REBATES_CONCESSION_CARDS_ACT_ENTITLEMENT}
      columnFields={processedColumns}
      detail={RebatesListDetailConcessionCards}
      primaryField={nameOf("Assess_Rebate_Entitlement_Id")}
    />
  );
};
