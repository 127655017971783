import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_AppealHearingTabDetails } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/reference-sidebar/detail/model";

export const getAppealHearingDetailsTabById = async (
  id: number
): Promise<APIResponse<Svc_AppealHearingTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_AppealHearingTabDetails>(
      `api/core/internal/appeals/hearing/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
