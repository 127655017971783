import { Svc_Contact } from "@app/core/contacts/_id/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { manualDescriptionValidator } from "@app/products/direct-debit/accounts/[id]/components/general/form-element/util";
import {
  DirectDebitAccount,
  Svc_Contact_With_ID,
} from "@app/products/direct-debit/accounts/model";
import { DIRECT_DEBIT_AUTHORISATIONS } from "@app/products/direct-debit/constant";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredStringValidator,
  requiredValidator,
} from "@common/utils/field-validators";
import { getFullAddressSingleLine } from "@common/utils/formatting";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IDDAccountGeneralSectionProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

const nameOf = nameOfFactory<DirectDebitAccount>();

export const DDAccountGeneralSection = observer(
  ({ formRenderProps, isDisabled }: IDDAccountGeneralSectionProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { pushNotificationPortal } = useNotificationPortalStore();

    const { dataForms } = useFlexibleFormStore();

    const getValue = (nameOfField: keyof DirectDebitAccount) =>
      valueGetter(nameOf(nameOfField));

    const [contactPickerValue, setContactPickerValue] = useState<
      Svc_Contact_With_ID | undefined
    >(undefined);

    useEffect(() => {
      const contact = valueGetter("CCFormOptions")
        ?.contact as Svc_Contact_With_ID;
      setContactPickerValue({
        ...contact,
        Contact_ID: contact?.Contact_Id ?? contact?.Contact_ID,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter("CCFormOptions")?.contact]);

    const onChangeField = (
      nameOfField: keyof DirectDebitAccount,
      value: any
    ) => {
      onChange(nameOf(nameOfField as keyof DirectDebitAccount), {
        value: value,
      });
    };

    const ddGroupsPickerHandler = (value: ComboBoxChangeEvent) => {
      onChangeField("Direct_Debit_Group_ID", value?.value?.Key);
      onChangeField("Direct_Debit_Group_Name", value?.value?.Value);
    };

    const getAddressFromContact = (contact?: Svc_Contact) => {
      if (contact)
        return getFullAddressSingleLine(
          contact?.PreferredAddress ?? contact?.PostalAddress
        );
      return "";
    };

    const handleChangeDDContact = (event: ComboBoxChangeEvent | null) => {
      const value = event?.value;
      if (!value) {
        onChangeField("Contact_ID", null);
        onChangeField("Address", null);
        onChangeField("Name", null);
        onChangeField("State", null);
        onChangeField("Post_Code", null);
        onChangeField("Core_Contact_VIP", false);
        onChange("CCFormOptions", {
          value: {
            ...valueGetter("CCFormOptions"),
            contact: null,
          },
        });
        return;
      }
      onChangeField("Contact_ID", value?.Contact_Id ?? value?.Contact_ID);
      onChangeField("Address", getAddressFromContact(value as Svc_Contact));
      onChangeField("Name", value?.DisplayName);
      onChangeField("State", value?.PreferredAddress?.State);
      onChangeField("Post_Code", value?.PreferredAddress?.Postcode);
      onChangeField("Core_Contact_VIP", value?.VIP ?? false);
      onChange("CCFormOptions", {
        value: { ...valueGetter("CCFormOptions"), contact: value },
      });
    };
    const handleSearchNameError = (error: any) => {
      pushNotificationPortal({
        title: "Pick contact errors",
        type: "error",
        description: error,
        autoClose: false,
      });
    };

    useEffectOnce(() => {
      // This logic apply for case:
      // the account is already saved with a group, but the group is deleted
      // if the group is deleted, we need to clear the group value before editing
      const groupId = getValue("Direct_Debit_Group_ID");
      const index = dataForms.GeneralFormLovs?.IncludeGroups?.findIndex(
        (item: KeyValuePacket) => item.Key === groupId
      );
      if (!isNil(groupId) && index === -1) {
        onChangeField("Direct_Debit_Group_ID", null);
        onChangeField("Direct_Debit_Group_Name", "");
      }
    });

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <CCValueField
            label="Account number"
            value={getValue("Account_Number")}
          />
          <CCValueField
            label="Account reference"
            value={dataForms?.GeneralForm?.Account_Reference ?? ""}
          />
          <CCValueField
            label="Authorisation number"
            value={
              <div className="cc-link-field">
                <Link
                  to={
                    DIRECT_DEBIT_AUTHORISATIONS +
                    "/" +
                    getValue("Authorisation_Id")
                  }
                  rel="noopener noreferrer"
                  className="cc-grid-link-text"
                >
                  {getValue("Authorisation_Number")}
                </Link>
              </div>
            }
          />
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Manual description" />
            <Field
              name={nameOf("Manual_Description")}
              component={CCTextArea}
              placeholder="Manual description"
              rows={4}
              readOnly={isDisabled}
              validator={manualDescriptionValidator}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            <CCLabel title="Name" isMandatory />
            <Field
              name={nameOf("Name")}
              nameDisplay="DisplayName"
              placeholder="Select name"
              value={contactPickerValue}
              component={ContactPicker}
              onChange={handleChangeDDContact}
              displayValue={contactPickerValue?.DisplayName ?? ""}
              validator={requiredStringValidator}
              disabled={isDisabled}
              disabledButton={isDisabled}
              onError={handleSearchNameError}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={"Address"}
              value={getAddressFromContact(contactPickerValue)}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCValueField label={"Email"} value={contactPickerValue?.Email} />
          </div>
          <div className="cc-field">
            <CCValueField
              label={"Phone number"}
              value={contactPickerValue?.WorkPhone}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={"Mobile phone number"}
              value={contactPickerValue?.Mobile}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Group" isMandatory />
            <Field
              component={CCSearchComboBox}
              name={nameOf("Direct_Debit_Group_ID")}
              nameDisplay="DisplayName"
              textField={"Value"}
              dataItemKey={"Key"}
              value={getDropdownValue(
                getValue("Direct_Debit_Group_ID"),
                dataForms.GeneralFormLovs?.IncludeGroups ?? [],
                "Key"
              )}
              validator={requiredValidator}
              data={dataForms.GeneralFormLovs?.IncludeGroups ?? []}
              onChange={ddGroupsPickerHandler}
              disabled={isDisabled}
            />
          </div>
        </div>
      </section>
    );
  }
);
