import { getReAssign } from "@app/products/property/fire-prevention/components/dialogs/re-assign/api";
import { colReAssign } from "@app/products/property/fire-prevention/components/dialogs/re-assign/config";
import { IReAssign } from "@app/products/property/fire-prevention/components/dialogs/re-assign/model";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IReAssignDialogProps {
  onClose: () => void;
}
const nameOf = nameOfFactory<IReAssign>();

export const ReAssignDialog = ({ onClose }: IReAssignDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IReAssign[] | undefined>();
  const [dataSelected, setDataSelected] = useState<IReAssign | undefined>();
  const { gridSelectedIds, gridData, setGridData } =
    useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  useEffectOnce(() => {
    setIsLoading(true);
    getReAssign().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  });

  const handleSubmit = () => {
    const newGridData = gridData.map((item) => {
      if (gridSelectedIds.includes(item.WorkOrderId)) {
        item.AssignedContractor = dataSelected?.Contractor;
      }
      return { ...item };
    });
    setGridData(newGridData);
    pushNotification({
      title: "Contractor re-assigned successfully",
      type: "success",
    });
    onClose();
  };

  return (
    <Form
      onSubmitClick={(event: FormSubmitClickEvent) => {
        handleSubmit();
      }}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxHeight="50%"
              maxWidth="40%"
              titleHeader="Select Contractor to Clear Property"
              onClose={onClose}
              bodyElement={
                <CCGrid
                  isLoading={isLoading}
                  data={data}
                  columnFields={colReAssign}
                  primaryField={nameOf("ContractorId")}
                  selectableMode="single"
                  onSelectionChange={(data) => setDataSelected(data[0])}
                />
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!dataSelected}
                  >
                    Set as Contractor
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
