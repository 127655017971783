import { HomePageFunction } from "@common/pages/settings/homepage/homepage-shortcuts/model";
import { ProductListing } from "@common/pages/settings/homepage/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const HomepageShortcutsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Homepage";
  },
  getBookmarkListViewDetail() {
    return "Settings - Homepage - Homepage Shortcuts";
  },
};

export const HomepageShortcutsDetailsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Settings - Homepage - Homepage Shortcuts ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow?.FunctionHeading]);
  },
};

export const renderHomepageTitle = (
  homepageLovs?: ProductListing[],
  homepageShortcuts?: HomePageFunction
) => {
  const productType = homepageLovs?.find(
    (item: ProductListing) =>
      item.ProductType_ENUM === homepageShortcuts?.ProductType_ENUM
  );

  let title = productType?.ProductType_Name ?? "";
  if (homepageShortcuts?.FunctionHeading) {
    if (title) {
      title += " - " + homepageShortcuts?.FunctionHeading;
    } else {
      title = homepageShortcuts?.FunctionHeading;
    }
  }
  return title;
};
