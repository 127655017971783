export interface DTO_Component_Associations {
  IncludeInactive: boolean;
  IncludeSecondaryAssociations: boolean;
  AssociationsByComponent: DTO_ComponentAssociationItemGroup[];
}

export interface DTO_ComponentAssociationItemGroup {
  Group: string;
  Group_Enum: eComponent;
  Group_Value: number;
  Associations: IAPIAssociatedItem[];
}
//TODO: Need to move to the higher scope

export enum eComponent {
  Unknown = 0,
  Assessment = 1,
  Parcel = 2,
  Title = 3,
  Assessment_Transaction = 4,
  Certificate = 5,
  Register_Account = 6,
  Supplementary = 7,
  Meter = 8,
  Scheme_Account = 9,
  Certificate_Request = 10,
  Charge = 11,
  Charge_Balances = 12,
  Notice_Group = 13,
  Street_Locality = 14,
  GIS_Selection = 19,
  Certificates_Type = 20,
  Active_Certificates = 21,
  Active_Certificates_for_Responsible_Area = 22,
  Register = 23,
  Master_Property = 24,
  Scheme = 31,
  Meter_Reading_Route = 32,
  Meter_Reading_Device = 33,
  Utility = 34,
  Scheme_Account_Transaction = 35,
  Meter_Installation = 36,
  Register_Account_Transaction = 37,
  Land_Tax_Exemption = 38,
  ACT_Lease_Transfer = 39,
  Deferred_Duty_Account = 42,
  Deferred_Duty_Scheme = 45,
  Deferred_Duty_Transaction = 46,
  Deferred_Duty_Instalments = 47,
  Entity = 48,
  Contact_Name_and_Address = 49,
  Assessment_Levy_Id = 51,
  Tasks_Run_Id = 52,
  Tasks_Id = 53,
  Collection = 60,
  MenuItem = 61,
  Journal = 62,
  Advanced_Search = 63,
  Report = 64,
  Home = 66,
  Charge_Instalment = 67,
  Charge_Levy = 68,
  Application_Search_No_Results = 69,
  Session = 70,
  Associated_Name = 71,
  Lease = 72,
  Debt_Recovery = 73,
  Assessment_Rebate_Entitlement = 74,
  Arrangement = 75,
  Change_of_Ownership = 76,
  Supplementary_Assessment = 77,
  Arrangement_Letter = 78,
  Name_and_Address = 79,
  Debt_Recovery_Action = 80,
  Debt_Recovery_PaymentsReceived = 81,
  Debt_Recovery_Exemption = 82,
  Mail_Box = 83,
  Financial_Summary = 84,
  Rebate_Claim = 85,
  Concession_Card = 86,
  Valuation = 87,
  Address = 88,
  CommunityCentral_Town_Planning_Application = 89,
  CommunityCentral_Health_Manager_Premise = 90,
  CommunityCentral_Animal_Registration = 91,
  CommunityCentral_Building_Application = 92,
  CommunityCentral_Local_Laws_Permit = 93,
  CommunityCentral_Events_Management_System_Booking = 94,
  CommunityCentral_Waste_Water_Permit = 95,
  HazardId_Fire_Prevention = 96,
  HazardId_Noxious_Weed = 97,
  CommunityFinance_Customer = 98,
  Charge_Run = 99,
  Notice_Run = 100,
  CommunityCentral_DevelopmentApplication = 101,
  CommunityCentral_StreetProtection = 102,
  CommunityCentral_Other = 103,
  Compliance = 104,
  Communication_Template = 105,
  Workflow = 106,
  Subdivisions_Consolidations = 107,
  PIC = 108,
  PIC_Charge_Run = 109,
  CORE_Contact = 9999,
}

export interface IAPIAssociatedItem {
  Id: number;
  IdString: string;
}
