import { SwitchValueOptions } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useLocalLawsSettingStore } from "@app/products/local-laws/system-admin/settings/_category/store";
import { useLocalLawsSettingManageStore } from "@app/products/local-laws/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export interface ILocalLawsRenderSwitchProps {
  switchList: ISwitchCustomFieldProps[];
  optionsValue: SwitchValueOptions;
  formRenderProps: FormRenderProps;
}

export interface ISwitchCustomFieldProps {
  isEditing?: boolean;
  eSettingsField: ECorporateSettingsField;
  isVisible?: boolean;
}

export const LLRenderSwitch = observer((props: ILocalLawsRenderSwitchProps) => {
  const { getConfigDataField } = useLocalLawsSettingStore();
  const { isEditing } = useLocalLawsSettingManageStore();
  const { switchList, optionsValue, formRenderProps } = props;
  const { valueGetter, onChange } = formRenderProps;

  if (!switchList.length) return null;

  return (
    <>
      {switchList.map((item: ISwitchCustomFieldProps) => {
        if (item?.isVisible === false) return null;

        return (
          <SwitchGlobalSettings
            {...props}
            data={getConfigDataField(item.eSettingsField)}
            isEditing={
              !isNil(item?.isEditing) ? item.isEditing && isEditing : isEditing
            }
            formRenderProps={formRenderProps}
            customChecked={
              valueGetter(`${item.eSettingsField}`) === optionsValue.TRUE
            }
            customOnChange={(event: SwitchChangeEvent) => {
              onChange(`${item.eSettingsField}`, {
                value: event?.value ? optionsValue.TRUE : optionsValue.FALSE,
              });
            }}
          />
        );
      })}
    </>
  );
});
