import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterFinanceNotSentToFinance: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.ReferenceNumber,
    title: "Number",

    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: WastewaterFieldMapping.ContactName,
    title: "Name",
  },
  {
    field: WastewaterFieldMapping.CreatedDate,
    title: "Recorded",
  },
  {
    field: WastewaterFieldMapping.FeeType,
    title: "Type",
  },
  {
    field: WastewaterFieldMapping.AmountIssued,
    title: "Issued",
  },
  {
    field: WastewaterFieldMapping.AmountOutstanding,
    title: "Outstanding",
  },
  {
    field: WastewaterFieldMapping.CostCode,
    title: "Cost Code",
  },
  {
    field: WastewaterFieldMapping.ExportToFinanceDate,
    title: "Exported to Finance",
  },
];
