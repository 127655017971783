import {
  IAppeal,
  SOGDECISION,
  SOGDECISIONSTRING,
} from "@common/pages/appeal/_id/model";
import { isNil } from "lodash";

export const mappingSOGDecision: {
  [key: string]: SOGDECISION;
} = {
  [SOGDECISIONSTRING.SystemInitialise]: SOGDECISION.SystemInitialise,
  [SOGDECISIONSTRING.Submitted]: SOGDECISION.Submitted,
  [SOGDECISIONSTRING.NotSubmitted]: SOGDECISION.NotSubmitted,
};

export const processDataAppeal = (data?: IAppeal): IAppeal | undefined => {
  if (!data) return undefined;
  const newData: IAppeal = { ...data };

  if (!isNil(newData?.SOGDecision_ENUM)) {
    const ogDecision_ENUM = newData?.SOGDecision_ENUM;
    newData.SOGDecision_ENUM = mappingSOGDecision[ogDecision_ENUM];
  }
  return newData;
};
