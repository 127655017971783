import { isSuccessResponse } from "@common/apis/util";
import { getPropertyActivityDashboard } from "@common/pages/home/components/property/api";
import { DTO_Dashboard } from "@common/pages/home/components/property/model";

import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class PropertyDashboardStore {
  private _propertyDashboard?: DTO_Dashboard = undefined;
  private _isLoadingDashboardProperty: boolean = false;
  private _queryDateTime?: Date = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get queryDateTime() {
    return this._queryDateTime;
  }
  setQueryDateTime = (queryDateTime: Date) => {
    runInAction(() => {
      this._queryDateTime = queryDateTime;
    });
  };

  get isLoadingDashboardProperty() {
    return this._isLoadingDashboardProperty;
  }
  setIsLoadingDashboardProperty = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDashboardProperty = isLoading;
    });
  };

  get propertyDashboard() {
    return toJS(this._propertyDashboard);
  }
  setPropertyDashboard = (propertyDashboard?: DTO_Dashboard) => {
    runInAction(() => {
      this._propertyDashboard = propertyDashboard;
    });
  };
  //Action
  loadPropertyDashboard = async () => {
    this.setIsLoadingDashboardProperty(true);
    const response = await getPropertyActivityDashboard();
    this.setIsLoadingDashboardProperty(false);
    if (isSuccessResponse(response)) {
      this.setPropertyDashboard(response.data);
    }
  };
}

export const propertyDashboardStore = new PropertyDashboardStore();
const propertyDashboardStoreContext = createContext(propertyDashboardStore);
export const usePropertyDashboardStore = () => {
  return useContext(propertyDashboardStoreContext);
};
