import { ILocalGovernment } from "@common/pages/settings/lookups/local-government/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ILocalGovernment>();
export const colPickCouncil: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("CouncilAddress"),
    title: "Address",
  },
];
