import RollbackStatusDialog from "@app/products/property/journals/session-list/[id]/components/dialogs/rollback-session-dialog/_index";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { SESSION_STATUS_NAME_CAN_PROCESS } from "@app/products/property/journals/session-list/config";
import { FINANCESYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const RollbackSessionButton = observer(() => {
  const [showRollbackSessionDialog, setShowRollbackSessionDialog] =
    useState<boolean>(false);
  const { sessionList } = useSessionListStore();

  const { settings } = useCommonCoreStore();
  const financeSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FinanceSystem]
  );
  const isSAPSystem = useMemo(() => {
    return financeSystem === FINANCESYSTEM.SAP;
  }, [financeSystem]);

  return (
    <>
      <CCNavButton
        title={"Rollback session"}
        disabled={
          sessionList?.Session_Status ===
          SESSION_STATUS_NAME_CAN_PROCESS.ToBeUpdated
        }
        onClick={() => {
          setShowRollbackSessionDialog(true);
        }}
        invisible={isSAPSystem}
      />
      {showRollbackSessionDialog && (
        <RollbackStatusDialog
          onClosePopup={() => {
            setShowRollbackSessionDialog(false);
          }}
          journalInfo={sessionList}
          onOKButton={() => setShowRollbackSessionDialog(false)}
        />
      )}
    </>
  );
});
