import { useComplianceDocumentsStore } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/store";
import { DocumentIssuedDialog } from "@app/products/property/assessments/compliance/actions-and-letters/components/action-bar/dialog/populate-documents/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const DocumentIssuedButton = observer(() => {
  const { isOpenDocumentIssuedDialog, setIsOpenDocumentIssuedDialog } =
    useComplianceDocumentsStore();
  return (
    <>
      <CCNavButton
        title="Selection Criteria"
        onClick={() => {
          setIsOpenDocumentIssuedDialog(true);
        }}
      />
      {isOpenDocumentIssuedDialog && (
        <DocumentIssuedDialog
          onClose={() => {
            setIsOpenDocumentIssuedDialog(false);
          }}
        />
      )}
    </>
  );
});
