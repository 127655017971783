import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import {
  commentsProps,
  contactPersonProps,
  fileNumberProps,
  permitHolderProps,
  vehicleTypeProps,
} from "@app/products/parking-permits/util";
import { IParkingPermitsFormElementProps } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { populateValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Field } from "@progress/kendo-react-form";
import React from "react";

export const ParkingPermitsFormParking = ({
  formRenderProps,
  dataChoose,
  sameAddressData,
}: IParkingPermitsFormElementProps) => {
  const getValue = formRenderProps.valueGetter;
  const compareType = (dataCompare: any, target: string) => {
    return dataCompare?.PermitType === target;
  };

  return (
    <>
      <div className="border-bottom">
        {/* ROW 1 */}
        <div className="form-row pt-2 ">
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label ">Permit Holder</label>
            </div>
            <Field
              autoComplete="off"
              name={ParkingPermitsFieldMapping.PermitHolder}
              component={InputPicker}
              defaultValue={
                sameAddressData && sameAddressData.PermitHolder_DisplayName
              }
              {...permitHolderProps(formRenderProps)}
            />
          </div>
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Contact</label>
            </div>
            {populateValue(
              sameAddressData
                ? sameAddressData.PermitHolder_Email
                : getValue(ParkingPermitsFieldMapping.ContactPermitHolder)
            )}
          </div>
        </div>
        {/* ROW 2 */}
        <div className="form-row ">
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Contact Person&nbsp;</label>
              <div
                className="fa fa-chevron-circle-down label"
                onClick={() => {
                  formRenderProps.onChange(
                    ParkingPermitsFieldMapping.ContactPerson,
                    {
                      value: sameAddressData
                        ? sameAddressData.PermitHolder_DisplayName
                        : getValue(ParkingPermitsFieldMapping.PermitHolder),
                    }
                  );
                  formRenderProps.onChange(
                    ParkingPermitsFieldMapping.ContactNumPerson,
                    {
                      value: sameAddressData
                        ? sameAddressData.PermitHolder_Email
                        : getValue(
                            ParkingPermitsFieldMapping.ContactPermitHolder
                          ),
                    }
                  );
                }}
              />
            </div>
            <Field
              autoComplete="off"
              name={ParkingPermitsFieldMapping.ContactPerson}
              component={CCInput}
              {...contactPersonProps(formRenderProps)}
            />
          </div>
          <div className="form-group col-md-4">
            <label className="label">Contact</label>
            <label className="label-value">
              {populateValue(
                getValue(ParkingPermitsFieldMapping.ContactNumPerson)
              )}
            </label>
          </div>
        </div>
      </div>
      {compareType(dataChoose, "Blue Permit") && (
        <div className="border-bottom">
          {/* ROW 1 */}
          <div className="form-row  pt-2 ">
            <div className="form-group col-md-4">
              <div className="d-flex">
                <label className="label">Vehicle Type</label>
                <CCTooltip type="validator" position="right" />
              </div>
              <Field
                autoComplete="off"
                name={ParkingPermitsFieldMapping.VehicleType}
                component={CCInput}
                validator={requiredValidator}
                {...vehicleTypeProps(formRenderProps)}
              />
            </div>
          </div>
        </div>
      )}
      <div className="border-bottom">
        {/* ROW 1 */}
        <div className="form-row pt-2 ">
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Permit Address</label>
              &nbsp;
              <div
                className="fa fa-chevron-circle-down label"
                onClick={() => {
                  formRenderProps.onChange(
                    ParkingPermitsFieldMapping.PermitAddress,
                    {
                      value: getValue(
                        ParkingPermitsFieldMapping.AddressPermitHolder
                      ),
                    }
                  );
                }}
              />
            </div>
            <label className="label-value">
              {populateValue(
                sameAddressData
                  ? sameAddressData.StreetName
                  : getValue(ParkingPermitsFieldMapping.PermitAddress)
              )}
            </label>
          </div>
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Property Number</label>
            </div>
            <label className="label-value">
              {populateValue(
                getValue(ParkingPermitsFieldMapping.PropertyNumber)
              )}
            </label>
          </div>
        </div>

        {/* ROW 2 */}
        {/* @TODO here to update later once you know business */}
        {(sameAddressData || compareType(dataChoose, "Blue Permit")) && (
          <div className="form-row">
            <div className="form-group col-md-4">
              <div className="d-flex">
                <label className="label">Home Phone</label>
              </div>
              <label className="label-value">
                {populateValue(getValue(ParkingPermitsFieldMapping.HomePhone))}
              </label>
            </div>
            <div className="form-group col-md-4">
              <div className="d-flex">
                <label className="label">Business Phone</label>
              </div>
              <label className="label-value">
                {populateValue(
                  getValue(ParkingPermitsFieldMapping.BusinessPhone)
                )}
              </label>
            </div>
            <div className="form-group col-md-4">
              <div className="d-flex">
                <label className="label ">Mobile Phone</label>
              </div>
              <label className="label-value">
                {populateValue(
                  getValue(ParkingPermitsFieldMapping.MobilePhone)
                )}
              </label>
            </div>
          </div>
        )}

        {/* ROW 3 */}
        <div className="form-row ">
          <div className="form-group col-md-4">
            <label className="label">File Number</label>
            <Field
              autoComplete="off"
              name={ParkingPermitsFieldMapping.FileNumber}
              component={CCInput}
              {...fileNumberProps(formRenderProps)}
            />
          </div>
        </div>
      </div>
      {/* @TODO here to update later once you know business */}
      <div className="border-bottom">
        <div className="form-row pt-2">
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Issue Date</label>
              <CCTooltip type="validator" position="right" />
            </div>
            <Field
              name={ParkingPermitsFieldMapping.IssueDate}
              defaultValue={new Date()}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
        </div>
      </div>
      <div className="border-bottom">
        {/* ROW 1 */}
        <div className="form-row pt-2">
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Special Purpose</label>
              <CCTooltip type="validator" position="right" />
            </div>
            <label className="label-value">
              {populateValue(
                getValue(ParkingPermitsFieldMapping.SpecialPurpose)
              )}
            </label>
          </div>

          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Only Valid</label>
            </div>
            <label className="label-value">
              {populateValue(getValue(ParkingPermitsFieldMapping.OnlyValid))}
            </label>
          </div>
        </div>
      </div>

      <div className="border-bottom">
        {/* ROW 1 */}
        <div className="form-row pt-2">
          <div className="form-group col-md-4">
            <div className="d-flex">
              <label className="label">Comments</label>
            </div>
            <Field
              name={ParkingPermitsFieldMapping.Comments}
              component={CCInput}
              {...commentsProps(formRenderProps)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
