import { CCTooltip } from "@components/cc-tooltip/_index";
import React from "react";
import "./_index.scss";

export const PopulateCandidatesDialogHeaderNote = () => {
  return (
    <div>
      <label className="cc-label cc-tooltip-dialog-header">
        <div className="cc-tooltip-dialog-header-title">
          Compliance Candidate Selection Criteria
        </div>
        <CCTooltip
          position="bottom"
          className="cc-tooltip-information"
          content=" "
          customTemplate={
            <div>
              <p>Compliance Candidate Selection Criteria</p>
              <div>
                <p>
                  Identify assessments with a&nbsp;
                  <b>residential land tax exemption</b>
                  <br /> where the&nbsp;
                  <b>ratepayers address differs from the property address. </b>
                  <br />
                  Select <b>OK </b>to display the results.
                </p>
              </div>
            </div>
          }
          type="custom"
        >
          <div className="cc-icon-label-button fa fa-info-circle text-accent" />
        </CCTooltip>
      </label>
    </div>
  );
};
