export const mockNotifyContractors = [
  {
    WorkOrderId: 8,
    AssignedContractor: "Mark Steven",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("19-Sep-2018"),
    Locality: "West Hobart",
    HouseNumber: 9,
    Street: "Stevens Farm",
    Owners: "S W Hennessy & C J E Hennessy",
    AssessmentNumber: 3378113,
    CreatedOn: new Date("19-Sep-2018 13:24"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    ParcelId: 255050,
    CaseId: 13,
  },
  {
    WorkOrderId: 6,
    AssignedContractor: "Nick Paine",
    Clearance: "Clear Entire Property",
    ToBeClearedBy: new Date("5-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: 6,
    Street: "Ada",
    Owners: "A Grillas",
    AssessmentNumber: 5682581,
    CreatedOn: new Date("4-Sep-2018 8:26"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    ParcelId: 206565,
    CaseId: 20,
  },
  {
    WorkOrderId: 7,
    AssignedContractor: "Nick Paine",
    Clearance: "Remove All Undergrowth",
    ToBeClearedBy: new Date("25-Sep-2018"),
    Locality: "Sandy Bay",
    HouseNumber: 254,
    Street: "Churchill",
    Owners: "L Zheng",
    AssessmentNumber: 5605797,
    CreatedOn: new Date("10-Sep-2018 17:17"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    ParcelId: 171300,
    CaseId: 22,
  },
];
