import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const enableNearMap = valueGetter(
    ECorporateSettingsField.CorporateSettings_GISAllowNearMaps.toString()
  );
  const data = useDataSetting(configData, [
    "CorporateSettings_GISAllowNearMaps",
    "CorporateSettings_GISNearMaps_BaseURL",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={data.CorporateSettings_GISAllowNearMaps}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      </div>
      {enableNearMap && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={data.CorporateSettings_GISNearMaps_BaseURL}
            isEditing={isEditing}
          />
        </div>
      )}
    </section>
  );
});
