import { BuildingFormElement } from "@app/products/building/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingStore } from "@app/products/building/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingForm = observer(() => {
  const { setIsLoading, building, createBuilding, updateBuilding } =
    useBuildingStore();
  const isNew = useIsNew();
  const handleSubmit = (data: any) => {
    setIsLoading(true);
    if (isNew) {
      createBuilding(data);
    } else {
      updateBuilding(data);
    }
    setIsLoading(false);
  };
  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={building}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
