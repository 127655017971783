import { PPREnquiries } from "@app/products/town-planning/ppr/enquiries/current-register/model";
import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPREnquiries>();

export const colPPREnquiriesCurrentRegister: IColumnFields[] = [
  {
    field: nameOf("Register_Number"),
    title: "Number",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("ReferenceNumber"),
    title: "Application Number",
  },
  {
    field: nameOf("Date_Lodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("VROffice"),
    title: "DoT Region",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Date_Due"),
    title: "Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("Contact"),
    title: "Applicant",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Days"),
    title: "Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Date_Complete"),
    title: "Complete",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("OriginReferral"),
    title: "Origin Referral",
  },
  //dont remove this field, just hide it temporary
  // {
  //   field: nameOf("Category"),
  //   title: "Category",
  // },
  {
    field: nameOf("TeamLeader"),
    title: "Delegated Approver",
  },
  {
    field: nameOf("ResponsibleAuthority"),
    title: "Council",
  },
];
