import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialDataModifyDebtRecovery,
  postModifyDebtRecovery,
} from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/api";
import { DebtRecoveryFormStep } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/form-element/debt-recovery/_index";
import { DetailsFormStep } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/form-element/details/_index";
import {
  DTO_Modify_Debt_Recovery,
  DTO_Workflow_ModifyDebtRecovery,
  EKeysOfStepsModifyDebtRecovery,
  keysOfSendSteps,
} from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/model";
import { useModifyDebtRecoveryDialogStore } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/store";
import { ConfirmCloseButton } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/dialog/confirm-close-button/_index";
import { DTO_DebtRecovery_LOVs } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { sleep } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IModifyDebtRecoveryDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  assessmentId: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isShowSearchType?: boolean;
  isReadOnly?: boolean;
  isToBeApprovalMode?: boolean;
  isShowReasonRejection?: boolean;
  isShowReason?: boolean;
  isShowCancelWorkflowButton?: boolean;
  statusBadge?: IBadgeDialog[];
  isSaveOnNextStep?: boolean;
  isIncompleteMode?: boolean;
  isFromActionList?: boolean;
}

export const ModifyDebtRecoveryDialog = observer(
  ({
    onClose,
    statusBadge,
    assessmentId,
    dataFromActionList,
    prefixTitle,
    isReadOnly = false,
    isToBeApprovalMode = false,
    isSaveOnNextStep = false,
    isFromActionList = false,
    isShowCancelWorkflowButton = false,
    isIncompleteMode = false,
    suffixTitle,
  }: IModifyDebtRecoveryDialogProps) => {
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const {
      setIsLoadingOnDialog,
      setIsLoadingOnDialogCancel,
      setDebtRecoveryLOVs,
    } = useModifyDebtRecoveryDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const [isShowCloseDialog, setIsShowCloseDialog] = useState<boolean>(false);
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<any>();
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);
    const [modifyDebtRecoveryInitData, setModifyDebtRecoveryInitData] =
      useState<DTO_Workflow_ModifyDebtRecovery>();
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    const titleHeader = useMemo(() => {
      const formId =
        modifyDebtRecoveryInitData?.WorkflowHeader.WorkflowDraft.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify debt recovery";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, modifyDebtRecoveryInitData]);

    const initialValues = useMemo(() => {
      let initDebtRecovery: any = {};
      let initDetails: any = {};
      if (modifyDebtRecoveryInitData) {
        const workflowDebtRecovery =
          modifyDebtRecoveryInitData?.WorkflowDetail?.DebtRecovery;
        const workflowDetail =
          modifyDebtRecoveryInitData?.WorkflowDetail?.Details;

        // Debt Recovery Step
        if (workflowDebtRecovery) {
          initDebtRecovery = {
            ...initDebtRecovery,
            ...workflowDebtRecovery,
          };
        }
        //Details
        if (workflowDetail) {
          initDetails = {
            ...initDetails,
            ...workflowDetail,
          };
        }
      }
      return {
        [EKeysOfStepsModifyDebtRecovery.DebtRecovery]: initDebtRecovery,
        [EKeysOfStepsModifyDebtRecovery.Details]: initDetails,
        [EKeysOfStepsModifyDebtRecovery.Comments]: {},
        [EKeysOfStepsModifyDebtRecovery.Documents]: {},
      };
    }, [modifyDebtRecoveryInitData]);

    const steps: IStep[] = [
      {
        label: "Debt recovery",
        initialValues: initialValues.DebtRecovery,
        component: DebtRecoveryFormStep,
        visible: true,
        key: EKeysOfStepsModifyDebtRecovery.DebtRecovery,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Details",
        initialValues: initialValues.Details,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDebtRecovery.Details,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsModifyDebtRecovery.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Debt_Recovery,
        },
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues.Documents,
        visible: true,
        key: EKeysOfStepsModifyDebtRecovery.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          //Only use for modify workflow
          componentId: assessmentId,
        },
      },
    ];

    /**
     * Process data before sending to API
     * @param data
     */
    const processData: DTO_Workflow_ModifyDebtRecovery | any = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfStepsModifyDebtRecovery)) {
          return { [key]: value };
        }
      });

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          if (value && value._option) {
            delete value._option;
          }
          const dataStep = { ...value };
          workflowDetail[key as keyof DTO_Modify_Debt_Recovery] = dataStep;
        }
      }
      return {
        WorkflowDetail: workflowDetail,
      };
    };

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          setFinishConfirmDialogData(data);
          break;
        case EListSubmitButton.Cancel:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.ConfirmCloseYes:
        case EListSubmitButton.Close:
          await sendParkDebtRecovery(processData(data));
          break;
      }
    };

    //@TODO after integrate api
    // const sendSaveDebtRecovery = async (
    //   payload: DTO_Workflow_ModifyDebtRecovery,
    //   isCloseDialog: boolean = false
    // ) => {
    //   const response = await postModifyDebtRecovery(
    //     WorkflowProcessMode.Save,
    //     payload
    //   );
    //   setIsLoadingOnNext(false);
    //   const defaultSuccessMessage = "Debt recovery saved successfully";
    //   const defaultFailedMessage =
    //     "The debt recovery process could not be saved";
    //   if (isSuccessResponse(response)) {
    //     if (response?.data?.IsSuccess) {
    //       if (isCloseDialog) {
    //         onClose();
    //         pushNotification({
    //           title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
    //           type: "success",
    //         });
    //       }
    //       return true;
    //     } else {
    //       notificationFormStepRef?.current
    //         ?.getNotificationFormStep()
    //         ?.current?.pushNotification({
    //           title: response.data?.ErrorMessage ?? defaultFailedMessage,
    //           type: "error",
    //           autoClose: false,
    //         });
    //       return false;
    //     }
    //   } else {
    //     notificationFormStepRef?.current
    //       ?.getNotificationFormStep()
    //       ?.current?.pushNotification({
    //         title: response?.data?.ErrorMessage ?? defaultFailedMessage,
    //         type: "error",
    //         autoClose: false,
    //       });
    //     return false;
    //   }
    // };

    const sendApproveDebtRecovery = async (
      payload: DTO_Workflow_ModifyDebtRecovery
    ) => {
      const defaultSuccessMessage = "Debt recovery modified successfully";
      const defaultFailedMessage = "Modified debt recovery failed";
      const response = await postModifyDebtRecovery(
        WorkflowProcessMode.Approve,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingFinish(false);
      onClose();
    };

    const sendParkDebtRecovery = async (
      payload: DTO_Workflow_ModifyDebtRecovery
    ) => {
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);
      const defaultSuccessMessage = "Debt recovery parked successfully";
      const defaultFailedMessage = "Park debt recovery failed";
      const response = await postModifyDebtRecovery(
        WorkflowProcessMode.Park,
        payload
      );
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response?.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      setIsLoadingOnDialog(false);
      setIsLoadingPark(false);
      onClose();
    };

    const handleCancelButton = (data: any) => {
      if (isFromActionList) {
        setDataForCancelDialog({
          cancelAPI: async () => {
            await sleep(3000);
            return {
              status: 200,
              data: {
                IsSuccess: true,
              },
            };
          },
          dataCancel: data,
          defaultSuccessMessage:
            "The modify debt recovery was cancelled successfully.",
          defaultErrorMessage: "Modify debt recovery could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList) {
        setIsShowCloseDialog(true);
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    const handleConfirmNoRetain = async () => {
      setIsLoadingOnDialogCancel(true);
      // await abandonWorkflow().then(() => {
      //   setIsLoadingOnDialogCancel(false);
      //   onClose();
      // });
      onClose();
    };

    //@TODO after integrate api
    // const handleNextButton = async (data: any) => {
    //   setIsLoadingOnNext(true);
    //   return await sendSaveDebtRecovery(processData(data));
    // };

    const handleFinish = async (data: any) => {
      setIsLoadingOnNext(false); //@TODO remove after integrate api
      setIsLoadingFinish(true);
      await sendApproveDebtRecovery(processData(data));
    };

    const getWorkflowData = () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      getInitialDataModifyDebtRecovery(assessmentId).then((response) => {
        if (Array.isArray(response)) {
          const [lovsDebtRecoveryResponse, workflowDataResponse] = response;
          const lovsDebtRecoveryData = lovsDebtRecoveryResponse?.data;
          const workflowData = workflowDataResponse?.data;
          if (
            isSuccessResponse(response[0]) &&
            !isSuccessResponse(response[1]) &&
            lovsDebtRecoveryData &&
            workflowData
          ) {
            setWorkflowDraftId(
              workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
                0
            );
            setDebtRecoveryLOVs(lovsDebtRecoveryData);
            setModifyDebtRecoveryInitData({
              WorkflowHeader: workflowData?.WorkflowHeader,
              WorkflowDetail: workflowData?.WorkflowDetail,
            });
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_DebtRecovery_LOVs
              | DTO_Workflow_ModifyDebtRecovery
              | ResponsePacket
            > = response[0];
            if (!isSuccessResponse(response[1])) {
              responseError = response[1];
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          // saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              badge={statusBadge}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingFinish || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  {isShowCloseDialog && (
                    <ConfirmCloseButton
                      onClose={() => setIsShowCloseDialog(false)}
                      onConfirmYes={renderProps.submitButton.onClick}
                      onConfirmNo={handleConfirmNoRetain}
                    />
                  )}
                </>
              }
            />
          )}
        />
        {finishConfirmDialogData && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              "The debt recovery will be modified based on the information provided. Are you sure you want to submit?"
            }
            isLoadingYes={isLoadingFinish}
            onClosePopup={() => setFinishConfirmDialogData(undefined)}
            onAsyncConfirm={() => {
              return handleFinish(finishConfirmDialogData);
            }}
          />
        )}
      </>
    );
  }
);
