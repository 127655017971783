import { Attachment } from "@app/core/documents/model";
import { Contact_Relation } from "@app/core/inspections/[id]/model";
import { AnimalType } from "@app/products/animals/model";
import {
  DTOBaseClass_Standard,
  ElementControl,
} from "@app/products/crms/[id]/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { Gender } from "@common/constants/enumerations";
import { LinkType } from "@common/constants/linkType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { Address } from "@common/input-pickers/address/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface Registration extends DTOBaseClass_Standard {
  Registration_ID: number;
  AnimalType_ID?: number;
  AnimalType: AnimalType;
  Registration_Number: string;
  Animal_Number?: number;
  Status_ENUM: Registration_Status;
  Status_Name: string;
  Gender_ENUM?: Gender;
  Gender_Name: string;
  DueStatus: string;
  DebtorNumber: string;
  FileNumber: string;
  ReferenceNumber: string;
  AnimalName: string;
  MicrochipNumber: string;
  PensionNumber: string;
  TagNumber: string;
  BreedDisplay: string;
  ClubNumber: string;
  Flag_TransferFromOtherCouncil?: boolean;
  Flag_Microchipped?: boolean;
  Flag_Obedience?: boolean;
  Flag_Dangerous?: boolean;
  Flag_GuideDog?: boolean;
  Flag_Customs?: boolean;
  Flag_Farm?: boolean;
  Flag_Organisation?: boolean;
  Flag_Pensioner?: boolean;
  Flag_HealthCareCard?: boolean;
  Flag_Sterilised?: boolean;
  Flag_ClubMember?: boolean;
  Flag_Menacing?: boolean;
  Flag_RenewalNoticeSent?: boolean;
  Flag_RenewalFeeCreated?: boolean;
  Contact: Contact_Relation;
  Contact_RID?: number;
  Delegate: Contact_Relation;
  Delegate_RID?: number;
  Address: Address;
  Address_ID?: number;
  Breed_KWD?: number;
  Breed_Name: string;
  DeactivateReason_KWD?: number;
  DeactivateReason_Name: string;
  CrossBreed_KWD?: number;
  CrossBreed_Name: string;
  TagColour_KWD?: number;
  TagColour_Name: string;
  Colour_KWD?: number;
  Colour_Name: string;
  SterlisedProof_KWD?: number;
  SterlisedProof_Name: string;
  MicrochipProof_KWD?: number;
  MicrochipProof_Name: string;
  ObedienceProof_KWD?: number;
  ObedienceProof_Name: string;
  Date_DOB?: Date;
  Date_DOD?: Date;
  Age: string;
  TagHistory: string;
  TagHistoryCount: number;
  Date_Recorded?: Date;
  Date_Lodged?: Date;
  Date_EffectiveFrom?: Date;
  Date_EffectiveTo?: Date;
  Flag_IsWebRegistration?: boolean;
  Flag_IsWebRegistrationProcessed?: boolean;
  WebRegistration_Date?: Date;
  PortalFee_ReferenceNumber: string;
  Portal_FeeAmount?: number;
  Fee_PaidInPortal?: boolean;
  PhotoAttachment: Attachment;
  PhotoAttachment_ID?: number;
  IsPureBreed?: boolean;
  IsGuardDog?: boolean;
  IsDogHomeAdoption?: boolean;
  SecondaryColour_KWD?: number;
  SecondaryColour_Name: string;
  Flag_PenaltyFeeCreated?: boolean;
  Flag_QuickRegistration?: boolean;
  PoundRegister_ID?: number;
  IsHuntingDog?: boolean;
  IsGreyhound?: boolean;
  IsBreedingCat?: boolean;
  PureBreedProof_KWD?: number;
  PureBreedProof_Name: string;
  GuideOrAssistanceDogProof_KWD?: number;
  GuideOrAssistanceDogProof_Name: string;
  WorkingProof_KWD?: number;
  WorkingProof_Name: string;
  CustomsDogProof_KWD?: number;
  CustomsDogProof_Name: string;
  GuardDogProof_KWD?: number;
  GuardDogProof_Name: string;
  DogHomeAdoptionProof_KWD?: number;
  DogHomeAdoptionProof_Name: string;
  HuntingDogProof_KWD?: number;
  HuntingDogProof_Name: string;
  GreyhoundProof_KWD?: number;
  GreyhoundProof_Name: string;
  Date_PensionerExpiry?: Date;
  IsTransferred?: boolean;
  Flag_CertifiedAssistance?: boolean;
  Flag_DogExemption?: boolean;
  Flag_ExemptionApproved?: boolean;
  Assessment_Date?: Date;
  Inspection_Date?: Date;
  Approved_Date?: Date;
  ExemptionNo: string;
  Flag_RestrictedBreed?: boolean;
  HistoricReference: string;
  SaveTriggers: RegistrationUpdateTriggers[];
  _SaveTriggers: RegistrationUpdateTriggers[];
  BubbleUp: BubbleUpIdentifier[];
}

export interface Svc_Registration {
  Registration: Registration;
  InfringementOSBalance?: number;
  RBal?: number;
  OSFee?: number;
}

export enum RegistrationUpdateTriggers {
  UpdateContact,
  UpdateAddress,
  UpdateAnimalType,
  GenerateFeeForPortal,
  RegistrationFromPound,
  NewRegistration,
  UpdateDelegate,
}

export interface AnimalsLOVs {
  AnimalTypes: IKeyValuePacket[];
  Animals_DogBreeds: IKeyValuePacket[];
  Animals_CatBreeds: IKeyValuePacket[];
  Animals_OtherBreeds: IKeyValuePacket[];
  Animals_Colour: IKeyValuePacket[];
  Animals_Size: IKeyValuePacket[];
  Animals_Condition: IKeyValuePacket[];
  Animals_Disposition: IKeyValuePacket[];
  Animals_TagColour: IKeyValuePacket[];
  Animals_ProofOfObedience: IKeyValuePacket[];
  Animals_ProofOfSterilisation: IKeyValuePacket[];
  Animals_MicrochipProofExemption: IKeyValuePacket[];
  Animals_Gender: IKeyValuePacket[];
  Core_Judgement: IKeyValuePacket[];
}

// Handler
export interface RegistrationHandlerRequest {
  FormAction: Svc_FormAction_Registration;
  Registration: Registration;
  RegistrationArgs: any;
  IsFirstTimeLoad: boolean;
}

export interface RegistrationHandlerResponse {
  Registration: Registration;
  UIControl: RegistrationUIControl;
}

export interface RegistrationUIControl {
  LitContact: ElementControl;
  LitContactAddress: ElementControl;
  LitAddress: ElementControl;
  LitAddressDisplay: ElementControl;
  WfBtnLLPermit: ElementControl;
  MaxNoCheckWarning: ElementControl;
}

export enum Svc_FormAction_Registration {
  SystemInitialise,
  Form_PickAddress,
  Form_PickContact,
  Form_AnimalOwnerSameAsPropertyOwner,
  Form_CheckNoNumber,
}

export interface IParentAnimalsRegistrationSection {
  id?: number;
  recordType?: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
  data?: Registration;
  notification?: IAppNotificationItemAddProps[];
  isSameDetails?: boolean;
  poundId?: number;
  referenceNumber?: string;
}

export interface CoreLink extends DTOBaseClass_Standard {
  Core_Link_ID: number;
  Parent_ID: number;
  Parent_Type_ENUM: RECORDTYPE;
  Parent_Type_Name: string;
  Child_ID: number;
  Child_Type_ENUM: RECORDTYPE;
  Child_Type_Name: string;
  Child_RecordType_ENUM?: LinkType;
  Child_RecordType_Name: string;
  SortIndex?: number;
  AdditionalInformation: any; //XElement
  BubbleUps: BubbleUpIdentifier[];
}

// ENUM
export enum Registration_Status {
  Unspecified = 0,
  NewApplication = 1,
  Active = 2,
  Inactive = 3,
  LostTag = 4,
  Deceased = 5,
}

export enum ANI_NEXTACTION {
  SystemInitialise = 0,
  ISSUE = 1,
  LOSTTAG = 2,
  RENEW = 3,
  DECEASED = 4,
  REACTIVATE = 5,
  ISSUEPRORATA = 6,
  DEREGISTER = 7,
  TAGISSUED = 8,
  REISSUE = 9,
  PICKDEBTOR = 10,
}

export enum AnimalTypeSubmitActions {
  Save = "Save",
  New = "New",
  SameDetails = "SameDetails",
  IssueRegistration = "IssueRegistration",
  RegenerateFee = "RegenerateFee",
  NewTagIssue = "NewTagIssue",
  SaveAndBackPound = "SaveAndBackPound",
  IssueAndView = "IssueAndView",
}

// Workflow
export interface Svc_Registration_Workflow_ProvideReason {
  Registration: Registration;
  ProvideReason: ProvideReason;
}

export interface Svc_Registration_Workflow_DateFromDateTo {
  Registration: Registration;
  DateFromDateTo: DateFromDateTo;
}

export interface Svc_Registration_Workflow_IssueRegistration {
  Registration: Registration;
  AniNextAction: ANI_NEXTACTION;
}

export interface Svc_Registration_Workflow_Issue {
  OldRegistration: Registration;
  NewRegistration: Registration;
}
