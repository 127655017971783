import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_Session_Transactions } from "./model";

export const getSessionTransactionDetail = async (
  id: number,
  transactionId: number
): Promise<APIResponse<DTO_Session_Transactions | undefined>> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Session_Transactions>(
        `api/property/int/session/${id}/transactions/${transactionId}/items`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
