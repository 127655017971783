import { DTO_Entity_Associated_Entity } from "@app/products/property/contacts-central-names/list/model";
import { nameOfFactory } from "@common/utils/common";
import { includes, map } from "lodash";

const nameOfNames = nameOfFactory<DTO_Entity_Associated_Entity>();
export const handleValidator = (values: any) => {
  return includes(
    map(values?.Associated_Entities, nameOfNames("Role_Name")),
    undefined
  )
    ? "A contact must be assigned a role."
    : "";
};
