import { lstPPRApprovalDecisions } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/config";
import { IChangeStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/model";
import { Application_Status_Int } from "@app/products/town-planning/ppr/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

export interface IPPRUpdateStatusDialogProps {
  initialValues?: IChangeStatus;
  notifications?: IAppNotificationItem[];
  isLoading?: boolean;
  isUpdating?: boolean;
  onClose: () => void;
  onSubmit: (dataItem: IChangeStatus) => void;
}

const nameOf = nameOfFactory<IChangeStatus>();

export const PPRUpdateStatusDialog = ({
  initialValues,
  notifications,
  isLoading = false,
  isUpdating = false,
  onClose,
  onSubmit,
}: IPPRUpdateStatusDialogProps) => {
  const { settings } = useCommonProductStore();
  const pprEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_Application_PPR]
  );

  const handleOnChangeStatus = (
    event: ComboBoxChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    const { onChange, valueGetter } = formRenderProps;
    const newStatus = event.target.value?.Key;
    onChange(nameOf("CurrentStatus"), {
      value: newStatus,
    });
    switch (newStatus) {
      case Application_Status_Int.PermitIssued:
        if (pprEnabled) {
          onChange("_options.IsShowDecisionDate", {
            value: true,
          });
          onChange("_options.IsShowDecisionType", {
            value: true,
          });
        }
        break;
      case Application_Status_Int.Complete:
        if (valueGetter("_options.IsPlansToComply") && pprEnabled) {
          onChange("_options.IsShowDecisionDate", {
            value: true,
          });
          onChange("_options.IsShowDecisionType", {
            value: false,
          });
        }
        break;
      default:
        onChange("_options.IsShowDecisionDate", {
          value: false,
        });
        onChange("_options.IsShowDecisionType", {
          value: false,
        });
    }
  };

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const data = event.values as IChangeStatus;
    onSubmit(data);
  };

  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, valid, valueGetter } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="25%"
              height="auto"
              titleHeader={"Application Status"}
              onClose={onClose}
              disabled={isUpdating}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <>
                    {notifications?.length ? (
                      <div>
                        <CCLocalNotification
                          defaultNotifications={notifications}
                        />
                      </div>
                    ) : null}
                    <div className="cc-form">
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Status
                              <CCTooltip
                                type="validator"
                                content="Please select the new status"
                              />
                            </label>
                            <Field
                              name={nameOf("CurrentStatus")}
                              textField={"Value"}
                              dataItemKey={"Key"}
                              component={CCSearchComboBox}
                              data={valueGetter("_options.StatusList") ?? []}
                              value={getDropdownValue(
                                valueGetter(nameOf("CurrentStatus")),
                                valueGetter("_options.StatusList"),
                                "Key"
                              )}
                              onChange={(event: ComboBoxChangeEvent) =>
                                handleOnChangeStatus(event, formRenderProps)
                              }
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                        {valueGetter("_options.IsShowDecisionDate") && (
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">Decision date</label>
                              <Field
                                name={nameOf("DecisionDate")}
                                component={CCDatePicker}
                                format={DATE_FORMAT.DATE_CONTROL}
                              />
                            </div>
                          </div>
                        )}
                        {valueGetter("_options.IsShowDecisionType") && (
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Decision
                                <CCTooltip
                                  type="validator"
                                  content="Select the Decision"
                                />
                              </label>
                              <Field
                                name={nameOf("CurrentDecision")}
                                textField={"Value"}
                                dataItemKey={"Key"}
                                component={CCSearchComboBox}
                                data={lstPPRApprovalDecisions ?? []}
                                validator={requiredValidator}
                                isUseDefaultOnchange
                              />
                            </div>
                          </div>
                        )}
                      </section>
                    </div>
                  </>
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!valid}
                    themeColor="primary"
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                    iconClass={isUpdating ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
