import { ICCRoute } from "@common/constants/ICCRoute";
export const plansToComplyRoute: ICCRoute = {
  path: "plans-to-comply",
  name: "Plans to Comply",
  children: [
    {
      path: "current-register",
      name: "Current Register",
      component: require("./current-register/_index").default,
    },
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
  ],
};
