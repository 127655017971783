export const mockAssessment = [
  {
    AssessmentNumber: 3400000.5555,
    AssessmentReference: "MGW-28",
    PropertyAddress: ", ",
    AccountName: "C K SIDHU & K S GILL",
    Meters: [
      {
        Utility: "Water Usage",
        MeterNumber: "05W888617",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 4343,
      },
    ],
  },
  {
    AssessmentNumber: 3400000.6666,
    AssessmentReference: "NDD-19",
    PropertyAddress: "27 Aberdeen St, Hunters Hill",
    AccountName: "D Pfluger & B L Russell",
    Meters: [
      {
        Utility: "Water Usage",
        MeterNumber: "05W888617",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 4342,
      },
      {
        Utility: "Water Usage",
        MeterNumber: "05W888618",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 7347,
      },
    ],
  },
  {
    AssessmentNumber: 3400000.99999,
    AssessmentReference: "G3-145B",
    PropertyAddress: "Shop 1/52 Aberdeen St, Hunters Hill",
    AccountName: "D R & C P HAGGER",
    Meters: [
      {
        Utility: "Water Usage",
        MeterNumber: "05W888617",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 2222,
      },
      {
        Utility: "Water Usage",
        MeterNumber: "05W888618",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 72247,
      },
      {
        Utility: "Water Usage",
        MeterNumber: "05W888618",
        ScheduleRead: true,
        BillingGroup: "Standard Periodic Billing",
        MeterInstallationId: 7117,
      },
    ],
  },
];

export const reasonSpecialReadingData = [
  { Key: 1, Value: "Vacating Tenant" },
  { Key: 2, Value: "Item 1" },
  { Key: 3, Value: "Item 2" },
  { Key: 4, Value: "Item 3" },
  { Key: 5, Value: "Item 4" },
];
