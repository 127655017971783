import { isSuccessResponse } from "@common/apis/util";
import { putValidateAppeal } from "@common/pages/appeal/_id/api";
import { IAppeal } from "@common/pages/appeal/_id/model";
import { appealStore } from "@common/pages/appeal/_id/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AppealSendLetterToVcatApplicantButtonStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  handleSendLetterToVcatApplicant = async (appeal: IAppeal) => {
    this.setIsLoading(true);
    const response = await putValidateAppeal(appeal);
    await appealStore.reLoadAppeal();
    this.setIsLoading(false);
    if (isSuccessResponse(response)) {
      this.setIsShowDialog(true);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        description: response.data?.Errors,
        type: "error",
      });
    }
  };
}
export const appealSendLetterToVcatApplicantButtonStore =
  new AppealSendLetterToVcatApplicantButtonStore();
const appealSendLetterToVcatApplicantButtonContext = createContext(
  appealSendLetterToVcatApplicantButtonStore
);
export const useAppealSendLetterToVcatApplicantButtonStore = () => {
  return useContext(appealSendLetterToVcatApplicantButtonContext);
};
