import { DTO_Entity_AssociatedEntity } from "@app/products/property/contacts-central-names/[id]/components/child-screens/associated-contacts/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAssociatedContacts = async (
  contactId: string | number,
  isIncludePastNames: boolean = true
): Promise<APIResponse<DTO_Entity_AssociatedEntity>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/int/entity/${contactId}/associatedentities`,
      {
        params: {
          includepreviouscontacts: isIncludePastNames,
        },
      }
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
