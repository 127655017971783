export enum GIS {
  None,
  Exponare,
  Latitude,
  GoogleMaps,
  IntraMaps,
  Bing,
  Weave,
  Dekho,
  SpectrumSpatial,
  MapInfo,
  Geocortex,
  Pozi,
  ArcGIS,
}
