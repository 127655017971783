import { ENCRYPT_KEY, IV_TEXT_KEY } from "@common/configs/aesKey";
import CryptoJS from "crypto-js";
export const aesEncrypt = (data: string) => {
  const key = ENCRYPT_KEY;
  const iv = IV_TEXT_KEY;
  const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return cipher.toString();
};

export const aesDecrypt = (data: string) => {
  const key = ENCRYPT_KEY;
  const iv = IV_TEXT_KEY;
  const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return cipher.toString(CryptoJS.enc.Utf8);
};
