import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { ReactElement, createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

export interface IReferenceComponents {
  title?: string;
  component?: ReactElement;
  isVisible?: boolean;
}

class ReferenceSideBarStore {
  private _referenceComponents?: IReferenceComponents[];

  setReferenceComponents = (component?: IReferenceComponents[]) => {
    runInAction(() => {
      this._referenceComponents = component;
    });
  };

  resetDefault = () => {
    runInAction(() => {
      this._referenceComponents = [];
    });
  };

  constructor() {
    makeAutoObservable(this);
  }

  get referenceComponents() {
    return toJS(this._referenceComponents);
  }
}

export const referenceSideBarStore = new ReferenceSideBarStore();

const referenceSideBarStoreContext = createContext(referenceSideBarStore);

export const useReferenceSideBarStore = () => {
  return useContext(referenceSideBarStoreContext);
};
