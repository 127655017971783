import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

export const DeferredDutyAssociationsTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const deferredDutyId: string =
    lastSelectedRow && lastSelectedRow?.Schema_Acccount_ID
      ? lastSelectedRow?.Schema_Acccount_ID
      : params.id;

  return (
    <div className="cc-deferredduty-associations-tab">
      <PropertyAssociations
        isNoControl
        id={deferredDutyId}
        componentNumber={eComponent.Deferred_Duty_Account}
      />
    </div>
  );
});
