import { isSuccessResponse } from "@common/apis/util";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putApproveLetterAppeal } from "@common/pages/appeal/_id/components/buttons/workflow/approve-letter/api";
import { appealStore } from "@common/pages/appeal/_id/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ApproveLetterAppealButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  approveLetterAppealRequest = async (id?: number) => {
    if (id) {
      this.setIsLoading(true);
      const response = await putApproveLetterAppeal(id);
      this.setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          appNotificationStore.clearErrorNotification();
          appealStore.reLoadAppeal().then(() => {
            if (response.data?.Notifications?.length === 0) {
              appNotificationStore.pushNotification({
                type: "success",
                title: "Record saved successfully",
              });
            } else {
              appNotificationStore.pushNotification({
                autoClose: true,
                description: response.data?.Notifications,
                type: "warning",
              });
            }
          });
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Approve Letter Appeal failed",
            type: "error",
            description: response.data?.Errors,
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: response?.error ?? "Approve Letter Appeal failed",
          type: "error",
        });
      }
    }
  };
}
export const approveLetterAppealButtonStore =
  new ApproveLetterAppealButtonStore();
const approveLetterAppealButtonContext = createContext(
  approveLetterAppealButtonStore
);
export const useApproveLetterAppealButtonStore = () => {
  return useContext(approveLetterAppealButtonContext);
};
