import { scrollToView } from "@common/utils/animation";
import { ItemRenderProps } from "@progress/kendo-react-treeview";
import React, { useEffect, useRef } from "react";

const ItemDropdownTree = (props: ItemRenderProps) => {
  const scrollToViewRef = useRef<any>(null);

  useEffect(() => {
    if (props.item.selected) {
      scrollToView(scrollToViewRef, { block: "center", inline: "nearest" });
    }
  }, [props.item.selected]);

  return (
    <span ref={props.item.selected ? scrollToViewRef : undefined}>
      {props.item.value}
    </span>
  );
};

export default ItemDropdownTree;
