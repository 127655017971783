import { getMasterPropertySummaryById } from "@app/products/property/assessments/master-properties/[id]/api";
import { DTO_MasterProperty_Summary } from "@app/products/property/assessments/master-properties/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class MasterPropertyStore {
  private _masterProperty?: DTO_MasterProperty_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get masterProperty() {
    return toJS(this._masterProperty);
  }
  setMasterProperty = (masterProperty?: DTO_MasterProperty_Summary) => {
    runInAction(() => {
      this._masterProperty = masterProperty;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get notifications() {
    return this._notifications;
  }
  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };
  get masterPropertyId() {
    return this.masterProperty?.Master_Property_Id;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._masterProperty = undefined;
      this._isLoading = false;
      this._notifications = [];
      this._responseLoadError = undefined;
    });
  };
  loadMasterProperty = async (masterPropertyId: number) => {
    this.setIsLoading(true);
    const response = await getMasterPropertySummaryById(masterPropertyId);
    let errorResponse = undefined;
    let newMasterProperty = undefined;
    if (isSuccessResponse(response)) {
      newMasterProperty = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setMasterProperty(newMasterProperty);
    this.setIsLoading(false);
  };
}

const masterPropertyStoreContext = createContext(new MasterPropertyStore());
export const useMasterPropertyStore = () => {
  return useContext(masterPropertyStoreContext);
};
