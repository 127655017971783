import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { GridSearch } from "@components/cc-grid-search/_index";
import { ICCGridProps } from "@components/cc-grid/_index";
import { SortDescriptor, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import React, { useRef, useState } from "react";
import "./_index.scss";

interface IGridSelectionDialogEnhanceProps
  extends Omit<ICCGridProps, "primaryField"> {
  dialog: {
    titleHeader: string;
    height?: string | number;
    width?: string | number;
    flag_btnClearSelection?: boolean;
    maxWidth?: string | number;
    maxHeight?: string | number;
    isLoading?: boolean;
    isCloseDialogAfterSubmit?: boolean;
  };
  grid: {
    primaryField?: string;
    itemPerPage?: number;
  };
  search: {
    hasSearchField?: boolean;
    sortCol?: string;
    filterCol?: string;
  };
  onSubmit?: (
    e: any[],
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => void;
  setShowDialog: (isShow: boolean) => void;
  onCloseDialog: () => void;
}

export const GridSelectionEnhanceDialog = ({
  dialog,
  grid,
  search,
  columnFields,
  dataUrl,
  data,
  selectableMode = "single",
  state,
  selectedRows,
  itemPerPage,
  onSubmit,
  setShowDialog,
  onCloseDialog,
  ...others
}: IGridSelectionDialogEnhanceProps) => {
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [selectedItemCount, setSelectedItemCount] = useState<number>(0);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [stateData, setStateData] = useState<State>({
    ...state,
    sort: (search?.sortCol ? search.sortCol : search.filterCol)
      ? ([
          {
            field: search?.sortCol ? search.sortCol : search.filterCol,
            dir: "asc",
          },
        ] as SortDescriptor[])
      : undefined,
  });

  const handleSelectionChange = (dataItem: any) => {
    setSelectedData(dataItem);
  };

  return (
    <div className="cc-grid-selection-dialog">
      <CCDialog
        height={660}
        maxHeight="80%"
        maxWidth={dialog.maxWidth}
        titleHeader={dialog.titleHeader}
        onClose={onCloseDialog}
        {...others}
        bodyElement={
          <>
            <CCLocalNotification ref={notificationRef} />
            <GridSearch
              grid={{
                primaryField: grid.primaryField,
                itemPerPage: grid.itemPerPage,
              }}
              search={{
                hasSearchField: search.hasSearchField,
                filterCol: search.filterCol,
                sortCol: search.sortCol ? search.sortCol : search.filterCol,
              }}
              columnFields={columnFields}
              dataUrl={dataUrl}
              data={data}
              selectableMode={selectableMode}
              selectedRows={selectedRows}
              setSelectedItemCount={setSelectedItemCount}
              handleSelectionChange={handleSelectionChange}
              state={stateData}
              onDataStateChange={(state: State) => {
                setStateData(state);
              }}
            />
          </>
        }
        footerElement={
          <>
            <div className="cc-dialog-footer-actions-right">
              <Button className={"cc-dialog-button"} onClick={onCloseDialog}>
                Cancel
              </Button>
              {dialog.flag_btnClearSelection && (
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  Clear Selection
                </Button>
              )}
              <Button
                className="cc-dialog-button"
                themeColor="primary"
                iconClass={dialog.isLoading ? "fas fa-spinner fa-spin" : ""}
                disabled={
                  (selectedData?.length ?? selectedItemCount) === 0 ||
                  dialog.isLoading
                }
                onClick={() => {
                  dialog.isCloseDialogAfterSubmit && setShowDialog(false);
                  onSubmit && onSubmit(selectedData, notificationRef);
                }}
              >
                Select
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
};
