export enum ProcessExternalSource {
  ExecuteOO = "executeoo",
  Weave = "weave",
  Intramaps = "intramaps",
  Geocortex = "geocortex",
  Mapinfo = "mapinfo",
  Spectrumspatialanalyst = "spectrumspatialanalyst",
  Spectrumspatial = "spectrumspatial",
  Pozi = "pozi",
  Exponare = "exponare",
  Dekho = "dekho",
  Arcgis = "arcgis",
}

export enum ProcessExternalCookie {
  WeaveCookie = "WeaveCookie",
  IntraMapsCookie = "IntraMapsCookie",
  GeocortexCookie = "GeocortexCookie",
  MapInfoCookie = "MapInfoCookie",
  SpectrumSpatialCookie = "SpectrumSpatialCookie",
  PoziCookie = "PoziCookie",
  ExponareCookie = "ExponareCookie",
  DekhoCookie = "DekhoCookie",
  ArcGISCookie = "ArcGISCookie",
}
