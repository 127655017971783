import { PropertyFinancialSummariesForm } from "@app/products/property/assessments/financial-summaries/components/manage-dialog/components/general-form-dialog/_index";
import { IFinancialSummariesFormData } from "@app/products/property/assessments/financial-summaries/model";
import React from "react";

interface IProps {
  financialInfo: IFinancialSummariesFormData | undefined;
  onClose: () => void;
}

const ExistedPropertyFinancialSummaries = ({
  financialInfo,
  onClose,
}: IProps) => {
  return (
    <>
      {financialInfo && (
        <PropertyFinancialSummariesForm
          title={financialInfo.Name}
          onClose={onClose}
          formData={financialInfo}
        />
      )}
    </>
  );
};

export default ExistedPropertyFinancialSummaries;
