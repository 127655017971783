import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSApplicationsNoServices: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Status, title: "Status" },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  {
    field: CRSFieldMapping.ChildDateOfBirth,
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildAge,
    title: "Age",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: CRSFieldMapping.Parent1Name, title: "Parent Name" },
  { field: CRSFieldMapping.Parent1Relation, title: "Relationship" },
  { field: CRSFieldMapping.Phone, title: "Contact Information" },
  { field: CRSFieldMapping.PrefMethod, title: "Preference Method" },
  { field: CRSFieldMapping.FYORegistrationStatus, title: "4yo" },
  {
    field: CRSFieldMapping.FYOSecondYrRegStatus,
    title: "4yo 2nd Year",
  },
  {
    field: CRSFieldMapping.DebtorNo,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Parent2Name, title: "Parent2 Name" },
  {
    field: CRSFieldMapping.Parent2Relation,
    title: "Relationship2",
  },
];
