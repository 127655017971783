import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";
export interface INewLookupTableButtonProps {
  isDisabled?: boolean;
  isVisible?: boolean;
}

export const NewLookupTableButton = ({
  isDisabled = false,
  isVisible = true,
}: INewLookupTableButtonProps) => {
  const history = useHistory();
  if (!isVisible) return null;
  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`lookup-tables/new`);
      }}
      disabled={isDisabled}
    />
  );
};
