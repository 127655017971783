import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const eAnimalsRoute: ICCRoute = {
  path: "e-animal",
  name: "eAnimals",
  enum: AnimalsMenu.eAnimals,
  children: [
    {
      path: "new-applications",
      name: "New Applications",
      enum: AnimalsMenu.eAnimalsRegistrations,
      component: require("./new-applications/_index").default,
    },
  ],
};
