import {
  DocumentClassificationNumber,
  DOCUMENT_STATUS_NUNBER,
} from "@app/core/documents/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { isNil } from "lodash";

export const checkIsValidFile = (files?: UploadFileInfo[]) => {
  return (
    files?.length === 1 &&
    files?.some((item: UploadFileInfo) => isNil(item.validationErrors))
  );
};

export const changeKeyMapRadioGroupComponentStatus = (
  list: Array<IKeyValuePacket>
) => {
  return (
    list.map((item: IKeyValuePacket) => {
      return {
        label: item.Value,
        value: DOCUMENT_STATUS_NUNBER[item.Key],
      };
    }) ?? []
  );
};

export const changeKeyMapRadioGroupComponentClassification = (
  list: Array<IKeyValuePacket>
) => {
  return (
    list.map((item: IKeyValuePacket) => {
      return {
        label: item.Value,
        value: DocumentClassificationNumber[item.Key],
      };
    }) ?? []
  );
};
