import { useFirePreventionMemoStore } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/store";
import { AddMemoDialog } from "@app/products/property/fire-prevention/hazld-case/[id]/components/dialogs/add-memo/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const AddMemoButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { addMemo, loadMemo } = useFirePreventionMemoStore();
  const { id } = useParams<{ id: string }>();
  const { pushNotification } = useCCAppNotificationStore();
  const handleSubmit = (data: any) => {
    addMemo({
      ...data.values,
      Category: data.values.Category.Value,
      ChangedOn: new Date(),
    });
    pushNotification({
      title: "Add memo successfully",
      type: "success",
    });
    setIsShowDialog(false);
  };
  useEffect(() => {
    if (id) loadMemo(parseInt(id));
  }, [loadMemo, id]);
  return (
    <>
      <CCNavButton title="Memo" onClick={() => setIsShowDialog(true)} />

      {isShowDialog && (
        <AddMemoDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={(data: any) => {
            handleSubmit(data);
          }}
        />
      )}
    </>
  );
});
