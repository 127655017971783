import { DIGIT_AFTER_COMMA } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/components/form-elements/charges/constant";
import {
  DTO_AssessmentChargeBalances,
  DTO_ChargeInstallments,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { nameOfFactory } from "@common/utils/common";
import { cloneDeep, entries, forEach, get, groupBy, map } from "lodash";

const nameOfAssessmentChargeBalances =
  nameOfFactory<DTO_AssessmentChargeBalances>();

const CB_Balance = nameOfAssessmentChargeBalances("CB_Balance");
const CB_AmendBalanceBy = nameOfAssessmentChargeBalances("CB_AmendBalanceBy");
const CB_Interest = nameOfAssessmentChargeBalances("CB_Interest");
const CB_AmendInterestBy = nameOfAssessmentChargeBalances("CB_AmendInterestBy");
const Adjusted_Interest = nameOfAssessmentChargeBalances("Adjusted_Interest");
const Adjusted_Amount = nameOfAssessmentChargeBalances("Adjusted_Amount");

export const getSumWithInstalmentNumberData = (
  assessmentChargeBalances: DTO_AssessmentChargeBalances
) => {
  const newAssessmentChargeBalancesClone = cloneDeep(assessmentChargeBalances);
  const groupDataByInstalmentNumber = groupBy(
    newAssessmentChargeBalancesClone,
    nameOfAssessmentChargeBalances("Charge_Instalment_Id")
  );
  return map(
    entries(groupDataByInstalmentNumber),
    ([chargeInstalmentId, dataGroup]) => {
      let sums = {
        [CB_Balance]: 0,
        [CB_AmendBalanceBy]: 0,
        [CB_Interest]: 0,
        [CB_AmendInterestBy]: 0,
        [Adjusted_Amount]: 0,
        [Adjusted_Interest]: 0,
      };

      // Calculate cumulative sums for each assessment charge balance field within dataGroup
      forEach(dataGroup, (item) => {
        sums[CB_Balance] += get(item, CB_Balance, 0);
        sums[CB_AmendBalanceBy] += get(item, CB_AmendBalanceBy, 0);
        sums[CB_Interest] += get(item, CB_Interest, 0);
        sums[CB_AmendInterestBy] += get(item, CB_AmendInterestBy, 0);
        sums[Adjusted_Amount] += get(item, CB_AmendBalanceBy, 0);
        sums[Adjusted_Interest] += get(item, CB_AmendInterestBy, 0);
      });

      // Return the aggregated sums for each chargeInstalmentId along with the sums for different fields
      return {
        chargeInstalmentId,
        ...sums,
      };
    }
  );
};

export const getNewAssessmentChargeInstallments = (
  oldAssessmentChargeInstallments: DTO_ChargeInstallments[],
  sumWithInstalmentNumberData: any,
  fieldChange?: string
) => {
  return oldAssessmentChargeInstallments.map((item: DTO_ChargeInstallments) => {
    const newDataItem = sumWithInstalmentNumberData.find(
      (subItem: any) =>
        parseInt(subItem?.chargeInstalmentId) === item.Charge_Instalment_Id
    );

    if (newDataItem) {
      if (!fieldChange) {
        item.New_Balance = roundNumberWithDecimalPlaces(
          newDataItem[CB_Balance] + newDataItem[CB_AmendBalanceBy],
          DIGIT_AFTER_COMMA
        );
        item.New_Interest = roundNumberWithDecimalPlaces(
          newDataItem[CB_Interest] + newDataItem[CB_AmendInterestBy],
          DIGIT_AFTER_COMMA
        );
        item.Adjusted_Amount = roundNumberWithDecimalPlaces(
          newDataItem[Adjusted_Amount],
          DIGIT_AFTER_COMMA
        );
        item.Adjusted_Interest = roundNumberWithDecimalPlaces(
          newDataItem[Adjusted_Interest],
          DIGIT_AFTER_COMMA
        );
      }

      if (
        fieldChange === CB_AmendBalanceBy ||
        fieldChange === nameOfAssessmentChargeBalances("CB_AmendedBalance")
      ) {
        item.New_Balance = roundNumberWithDecimalPlaces(
          newDataItem[CB_Balance] + newDataItem[CB_AmendBalanceBy],
          DIGIT_AFTER_COMMA
        );
        item.Adjusted_Amount = roundNumberWithDecimalPlaces(
          newDataItem[Adjusted_Amount],
          DIGIT_AFTER_COMMA
        );
      }

      if (
        fieldChange === nameOfAssessmentChargeBalances("CB_AmendedInterest") ||
        fieldChange === CB_AmendInterestBy
      ) {
        item.New_Interest = roundNumberWithDecimalPlaces(
          newDataItem[CB_Interest] + newDataItem[CB_AmendInterestBy],
          DIGIT_AFTER_COMMA
        );
        item.Adjusted_Interest = roundNumberWithDecimalPlaces(
          newDataItem[Adjusted_Interest],
          DIGIT_AFTER_COMMA
        );
      }
    }
    return item;
  });
};

export const roundNumberWithDecimalPlaces = (
  value: number,
  decimalPlaces: number
) => {
  return parseFloat(value.toFixed(decimalPlaces));
};
