import { TextArea, TextAreaProps } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React from "react";

interface ICCTextAreaProps {
  validationMessage?: string | null;
  visited?: boolean;
  rows?: number;
}

export const CCTextArea = (props: ICCTextAreaProps & TextAreaProps) => {
  const { validationMessage, visited, rows, ...others } = props;

  return (
    <>
      <TextArea {...others} rows={rows} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
