import { useBuildingRegistersCouncilConsentStore } from "@app/products/building/registers/council-consent/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { BuildingRegisterCouncilConsentFormElement } from "./components/form-element/_index";

export const BuildingRegisterCouncilConsentForm = observer(() => {
  const {
    councilConsent,
    setIsLoading,
    createBuildingRegistersCouncilConsent,
    updateBuildingRegistersCouncilConsent,
  } = useBuildingRegistersCouncilConsentStore();
  const isNew = useIsNew();

  const handleSubmit = (data: any) => {
    setIsLoading(true);
    if (isNew) {
      createBuildingRegistersCouncilConsent(data);
    } else {
      updateBuildingRegistersCouncilConsent(data);
    }
    setIsLoading(false);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={councilConsent}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegisterCouncilConsentFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
