import { ExistedEnquiries } from "@app/products/town-planning/ppr/enquiries/_id/components/forms/existed/_index";
import { NewEnquiries } from "@app/products/town-planning/ppr/enquiries/_id/components/forms/new/_index";
import { IPPREnquiryParentSection } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePPREnquiry = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const pprEnquiryId = params.id;
  const location = useLocation();
  const { resetStore, loadPPREnquiry, setParentSection } = usePPREnquiryStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as IPPREnquiryParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    loadPPREnquiry(parseInt(pprEnquiryId), isNew);
  }, [pprEnquiryId, isNew, loadPPREnquiry]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  if (isNew) {
    return <NewEnquiries />;
  }
  return <ExistedEnquiries />;
};

export default observer(ManagePPREnquiry);
