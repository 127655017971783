import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormElement } from "@progress/kendo-react-form";
import React from "react";

export interface IDirectDebitGroupFormElementProps {
  isInactive?: boolean;
}

const nameOf = nameOfFactory<DirectDebitGroup>();

const descriptionValidator = (value: any) =>
  stringLengthValidator(value, 1, 50);

export const DirectDebitGroupFormElement = ({
  isInactive = false,
}: IDirectDebitGroupFormElementProps) => {
  return (
    <FormElement id="cc-form-manage-page-general">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Description" isMandatory />
            <Field
              component={CCInput}
              name={nameOf("Description")}
              validator={descriptionValidator}
              disabled={isInactive}
            />
          </div>
        </div>
      </section>
    </FormElement>
  );
};
