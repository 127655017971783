import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockBillingGroup: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "Monthly Billing",
  },
  {
    Key: 2,
    Value: "Standard Periodic Billing",
  },
];
