import React from "react";
interface IRatingStarProps {
  numberOfStar: number;
}
export const RatingStar = ({ numberOfStar }: IRatingStarProps) => {
  return (
    <div className="cc-rating-star-wrapper">
      {[...Array(numberOfStar)].map((e, i) => (
        <i className="fa fa-star cc-rating-star" />
      ))}
    </div>
  );
};
