import { ChatSidebar } from "@app/core/chat/ask-ai/reference-sidebar/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NoticeDetailTab } from "@app/core/notices/[id]/components/reference-sidebar/details/_index";
import { NoticeHistoryTab } from "@app/core/notices/[id]/components/reference-sidebar/history/_index";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { colByDate } from "@app/products/crms/notices/by-date/config";
import { CRMSNoticesByDateBookmark } from "@app/products/crms/notices/by-date/util";
import {
  CRMS_NOTICES_ROUTE,
  nameOfEventNoticesView,
} from "@app/products/crms/notices/constant";
import { noticesRoute } from "@app/products/crms/notices/route";
import {
  CRMSNoticesBookmark,
  crmsNoticesListViewURL,
} from "@app/products/crms/notices/util";
import { crmsRoute } from "@app/products/crms/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const gridId = getUUID();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Notice,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [<CrmsActionBarNavDropdown category={noticesRoute.path} />],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CORE_Notice}
        dataSetFilter={MAILMERGEDATASET.CORE_Notice} // Check later
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Notice}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_NOTICES_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Notice}
        detail={CRMSNoticesBookmark.getBookmarkDetail}
        displayName={CRMSNoticesBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSNoticesByDateBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={CRMSNoticesByDateBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <NoticeDetailTab /> },
      {
        title: "History",
        component: <NoticeHistoryTab />,
      },
      {
        title: "Ask AI",
        isVisible: useAskAIServiceStore().isShowAskAI,
        component: (
          <ChatSidebar productId={PRODUCT_TYPE_NUMBER.CustomerService} />
        ),
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={colByDate}
        primaryField={nameOfEventNoticesView("ID")}
        dataUrl={crmsNoticesListViewURL(
          FormAction.CUSTOMERSERVICE_MENU_VIEW_NOTICEBYDATE
        )}
      />
    </LoadingPermissionWrapper>
  );
});
