import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.NoticeRunId
    ? `- ${selectedRow.NoticeRunId}`
    : "";

  return `Property - Deferred Duty - Notice Runs ${dynamicDisplayName}`;
};

//@TODO Don't have Account and PropertyAddress data in grid mock data, recheck when integrated API
export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.NoticeRunId,
    selectedRow.RunName,
  ]);
};

export const getBookmarkListViewDetail = () => {
  return "Property - Deferred Duty - Notice Runs";
};
