import React, { ReactNode, useEffect, useRef } from "react";

const useTooltipWrapper = (
  wrapperRef: React.MutableRefObject<any>,
  childRef: React.MutableRefObject<any>
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        childRef.current && childRef.current.handleMouseOut(event);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, childRef]);
};

interface ITooltipWrapper {
  childRef: React.MutableRefObject<any>;
  children: ReactNode;
}

const TooltipWrapper = (props: ITooltipWrapper) => {
  const { childRef, children } = props;
  const wrapperRef = useRef(null);

  useTooltipWrapper(wrapperRef, childRef);
  return <div ref={wrapperRef}>{children}</div>;
};

export default TooltipWrapper;
