import { IFinancialSummariesFormData } from "@app/products/property/assessments/financial-summaries/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { ReactElement } from "react";

interface Props {
  formRenderProps: FormRenderProps;
}

export function PropertyFinancialSummariesFormElement({
  formRenderProps,
}: Props): ReactElement {
  const nameOf = nameOfFactory<IFinancialSummariesFormData>();
  let getValue = formRenderProps.valueGetter;
  const getDropdownValue_Date = (
    keyFilter: string,
    arr: any,
    dataItemKey: string = "Key"
  ): any => {
    if (keyFilter === undefined) {
      return { Key: "3", Value: "Current Rating Period" };
    }
    return (
      arr?.find((item: any) => {
        return item[dataItemKey] === keyFilter;
      }) ?? null
    );
  };
  const getDropdownValue_Summary = (
    keyFilter: string,
    arr: any,
    dataItemKey: string = "Key"
  ): any => {
    if (keyFilter === undefined) {
      return { Key: "Null", Value: "" };
    }
    return (
      arr?.find((item: any) => {
        return item[dataItemKey] === keyFilter;
      }) ?? null
    );
  };

  const transactionsSessionsList = [
    { Key: "1", Value: "All Transactions" },
    { Key: "2", Value: "Current Month" },
    { Key: "3", Value: "Current Rating Period" },
    { Key: "4", Value: "Last Month" },
    { Key: "5", Value: "Last Rating Period" },
    { Key: "6", Value: "Up to End of Last Month" },
    { Key: "7", Value: "Up to End of Last Rating Period" },
  ];
  const summarisedByList = [
    { Key: "DueDate", Value: "Due Date" },
    { Key: "FinancialArea", Value: "Financial Area" },
    { Key: "FinancialGroup", Value: "Financial Group" },
    { Key: "InstalmentPlan", Value: "Instalment Plan" },
    { Key: "IsDeferred", Value: "Is Deferred" },
    { Key: "IsPostponed", Value: "Is Postponed" },
    { Key: "Journal", Value: "Journal" },
    { Key: "Levy", Value: "Levy" },
    { Key: "RatingPeriod", Value: "Rating Period" },
    { Key: "Session", Value: "Session" },
    { Key: "Transaction Type", Value: "TransactionType" },
    { Key: "UpdateStatus", Value: "Update Status" },
    { Key: "TransactionRatingPeriod", Value: "Transaction Rating Period" },
    { Key: "GLDebtorAccount", Value: "GL Debtor Account" },
    { Key: "GLInterest Debtor Account", Value: "GL Interest Debtor Account" },
    { Key: "GLIncomeAccount", Value: "GL Income Account" },
    { Key: "GLBankAccount", Value: "GL Bank Account" },
    { Key: "GLInterestAccount", Value: "GL Interest Account" },
    { Key: "GLInterimBankAccount", Value: "GL Interim Bank Account" },
  ];

  const [summarisedBy, setSummarisedBy] = React.useState({
    firstValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_1")),
      summarisedByList
    ),
    secondValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_2")),
      summarisedByList
    ),
    thirdValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_3")),
      summarisedByList
    ),
    fourthValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_4")),
      summarisedByList
    ),
    fifthValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_5")),
      summarisedByList
    ),
    sixthValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_6")),
      summarisedByList
    ),
    seventhValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_7")),
      summarisedByList
    ),
    eighthValue: getDropdownValue_Summary(
      getValue(nameOf("SummarisedBy_8")),
      summarisedByList
    ),
  });

  const defaultValueSummarisedBy = { Key: "Null", Value: "" };
  const checkDefaultValuesNull = (item: any) => {
    return JSON.stringify(item) === JSON.stringify(defaultValueSummarisedBy);
  };
  const hasFirstValue =
    summarisedBy.firstValue && !checkDefaultValuesNull(summarisedBy.firstValue);
  const hasSecondValue =
    summarisedBy.secondValue &&
    !checkDefaultValuesNull(summarisedBy.secondValue);
  const hasThirdValue =
    summarisedBy.thirdValue && !checkDefaultValuesNull(summarisedBy.thirdValue);
  const hasFourthValue =
    summarisedBy.fourthValue &&
    !checkDefaultValuesNull(summarisedBy.fourthValue);
  const hasFifthValue =
    summarisedBy.fifthValue && !checkDefaultValuesNull(summarisedBy.fifthValue);
  const hasSixthValue =
    summarisedBy.sixthValue && !checkDefaultValuesNull(summarisedBy.sixthValue);
  const hasSeventhValue =
    summarisedBy.seventhValue &&
    !checkDefaultValuesNull(summarisedBy.seventhValue);

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-grid-control-left">
          <div className="cc-field">
            <label className="cc-label">Summarised by</label>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <Field
              name={nameOf("SummarisedBy_1")}
              dataItemKey="Key"
              textField="Value"
              data={summarisedByList}
              value={summarisedBy.firstValue}
              onChange={(event: ComboBoxChangeEvent) => {
                setSummarisedBy({
                  ...summarisedBy,
                  firstValue: event.target.value ?? null,
                });
                formRenderProps.onChange(nameOf("SummarisedBy_1"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
              component={CCSearchComboBox}
            />
          </div>
          {hasFirstValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_2"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.secondValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    secondValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_2"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}
          {hasSecondValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_3"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.thirdValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    thirdValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_3"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}

          {hasThirdValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_4"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.fourthValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    fourthValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_4"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}
          {hasFourthValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_5"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.fifthValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    fifthValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_5"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}

          {hasFifthValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_6"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.sixthValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    sixthValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_6"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}
          {hasSixthValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_7"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.seventhValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    seventhValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_7"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}
          {hasSeventhValue && (
            <div className="cc-field">
              <Field
                name={"SummarisedBy_8"}
                dataItemKey="Key"
                textField="Value"
                data={summarisedByList}
                value={summarisedBy.eighthValue}
                defaultItem={defaultValueSummarisedBy}
                onChange={(event: ComboBoxChangeEvent) => {
                  setSummarisedBy({
                    ...summarisedBy,
                    eighthValue: event.target.value ?? null,
                  });
                  formRenderProps.onChange(nameOf("SummarisedBy_8"), {
                    value: event.target.value.Key ?? null,
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
          )}
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-col-span-2">
            <div className="cc-field">
              <label className="cc-label">
                Name
                <CCTooltip
                  type="validator"
                  content={formRenderProps.errors.Name}
                />
              </label>
              <Field
                name={nameOf("Name")}
                component={CCInput}
                placeholder={"Name"}
                validator={requiredValidator}
              />
            </div>
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-grid-control-left">
          <div className="cc-field">
            <label className="cc-label">Include</label>
          </div>
        </div>
        <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <label className="cc-label">Transaction dates</label>
            <Field
              name={nameOf("TransactionDefaultPeriod")}
              dataItemKey="Key"
              textField="Value"
              data={transactionsSessionsList}
              value={getDropdownValue_Date(
                getValue(nameOf("TransactionDefaultPeriod")),
                transactionsSessionsList
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                formRenderProps.onChange(nameOf("TransactionDefaultPeriod"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
              component={CCSearchComboBox}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">From</label>
            <Field
              name={nameOf("TransactionFrom")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">To</label>
            <Field
              name={nameOf("TransactionTo")}
              defaultValue={new Date("20-Jun-2022")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
        </div>
        <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <label className="cc-label">Session update dates</label>
            <Field
              name={nameOf("SessionDefaultPeriod")}
              dataItemKey="Key"
              textField="Value"
              data={transactionsSessionsList}
              value={getDropdownValue_Date(
                getValue(nameOf("SessionDefaultPeriod")),
                transactionsSessionsList
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                formRenderProps.onChange(nameOf("SessionDefaultPeriod"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
              component={CCSearchComboBox}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">From</label>
            <Field
              name={nameOf("SessionFrom")}
              defaultValue={new Date("01-Jul-2021")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">To</label>
            <Field
              name={nameOf("SessionTo")}
              defaultValue={new Date("30-Jul-2022")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Transaction not yet updated to GL
            </label>
            <Field
              name={nameOf("IncludeAmountsYetToUpdate")}
              defaultChecked={getValue(nameOf("IncludeAmountsYetToUpdate"))}
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postponed charges</label>
            <Field
              name={nameOf("IncludePostponedCharges")}
              defaultChecked={getValue(nameOf("IncludePostponedCharges"))}
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Deferred arrears</label>
            <Field
              name={nameOf("IncludeDeferredArrears")}
              defaultChecked={
                getValue(nameOf("IncludeDeferredArrears")) ?? true
              }
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Proposed transactions</label>
            <Field
              name={nameOf("IncludeProposedAmounts")}
              defaultChecked={getValue(nameOf("IncludeProposedAmounts"))}
              component={CCSwitch}
            />
          </div>
        </div>
      </section>
    </>
  );
}
