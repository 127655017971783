import {
  NewRegistration,
  ParameterTypes,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/dialogs/test-fee/model";
import {
  RegistrationFeeRulesType,
  SVC_FeeCalculator_NonRegistrationFee_RuleType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { getNonRegistrationFeeRuleTypeFriendlyName } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";

export const feeRulesTypeData = [
  {
    label: "Registration fee",
    value: RegistrationFeeRulesType.RegistrationFeeRules,
  },
  {
    label: "Non registration fee",
    value: RegistrationFeeRulesType.NonRegistrationFeeRules,
  },
];

export const parametersData = [
  { label: ParameterTypes.Sterilized, value: false },
  { label: ParameterTypes.ClubMember, value: false },
  { label: ParameterTypes.Microchipped, value: false },
  { label: ParameterTypes.Obedience, value: false },
  { label: ParameterTypes.GuideDog, value: false },
  { label: ParameterTypes.Customs, value: false },
  { label: ParameterTypes.Working, value: false },
  { label: ParameterTypes.Organisation, value: false },
  { label: ParameterTypes.Menacing, value: false },
  { label: ParameterTypes.PureBred, value: false },
  { label: ParameterTypes.Greyhound, value: false },
  {
    label: ParameterTypes.DogsHomeAdoption,
    value: false,
  },
  { label: ParameterTypes.Pensioner, value: false },
  { label: ParameterTypes.HealthCareCard, value: false },
  { label: ParameterTypes.Dangerous, value: false },
  { label: ParameterTypes.NewTag, value: false },
  { label: ParameterTypes.ReplacementTag, value: false },
  { label: ParameterTypes.HuntingDog, value: false },
  { label: ParameterTypes.Transferred, value: false },
  {
    label: ParameterTypes.RestrictedBreed,
    value: false,
  },
];

export const newRegistrationData = [
  {
    label: NewRegistration.IsNewRegistration,
    value: false,
  },
  {
    label: NewRegistration.CertifiedAssistance,
    value: false,
  },
];

export const feeRuleTypeData: KeyValuePacket[] = [
  {
    Key: SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_NewTag,
    Value: getNonRegistrationFeeRuleTypeFriendlyName(
      SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_NewTag
    ),
  },
  {
    Key: SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_ReplacementTag,
    Value: getNonRegistrationFeeRuleTypeFriendlyName(
      SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_ReplacementTag
    ),
  },
  {
    Key: SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_TransferFee,
    Value: getNonRegistrationFeeRuleTypeFriendlyName(
      SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_TransferFee
    ),
  },
  {
    Key: SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_LifetimeTag,
    Value: getNonRegistrationFeeRuleTypeFriendlyName(
      SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_LifetimeTag
    ),
  },
  {
    Key: SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_ComplaintFee,
    Value: getNonRegistrationFeeRuleTypeFriendlyName(
      SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_ComplaintFee
    ),
  },
  {
    Key: SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_PenaltyFee,
    Value: getNonRegistrationFeeRuleTypeFriendlyName(
      SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_PenaltyFee
    ),
  },
];
