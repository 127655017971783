import {
  loadMeterAssociationByID,
  loadSearchAssessment,
} from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/api";
import { billingGroupData } from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/mock";
import {
  SearchType,
  textFieldMapping,
} from "@app/products/property/meters/[id]/components/form-steps/installation/components/form-elements/association/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
export const ASSOCIATION_LOCATION_FORM_STEP = "Association";
const searchTypeData = Object.values(SearchType);
export const AssociationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [isLoading, setIsLoading] = useState(false);
  const searchTypeValue = getFieldValue("SearchType");
  const meterId = getFieldValue("MeterId");
  const handleSearchAssessment = (event: ComboBoxFilterChangeEvent) => {
    let numLetters = 1;
    if (!searchTypeValue) return;
    if (searchTypeValue === SearchType.AssessmentNumber) numLetters = 4;
    onChange(nameOf("Assessment"), { value: null });
    if (event.filter.value.length >= numLetters) {
      onChange(nameOf("_option.Assessment.Loading"), { value: true });
      loadSearchAssessment(event.filter).then((data) => {
        onChange(nameOf("_option.Assessment.Data"), { value: data });
        onChange(nameOf("_option.Assessment.Loading"), { value: false });
      });
    } else {
      onChange(nameOf("_option.Assessment.Data"), { value: [] });
    }
  };
  useEffectOnce(() => {
    setIsLoading(true);
    loadMeterAssociationByID(meterId).then((data: any) => {
      if (!data) return;
      onChange(nameOf("MeterNumber"), { value: data?.MeterNumber });
      onChange(nameOf("MeterAssociationPropertyAddress"), {
        value: data?.MeterAssociationPropertyAddress,
      });
      onChange(nameOf("MeterAssociationAccountNames"), {
        value: data?.MeterAssociationAccountNames,
      });
      setIsLoading(false);
    });
  });

  const handleSearchAssessmentResult = (event: ComboBoxChangeEvent) => {
    if (!event.value) return;
    onChange(nameOf("Assessment"), { value: event.value });
    onChange(nameOf("AccountAttachedPropertyAddress"), {
      value: event.value?.AccountAttachedPropertyAddress ?? null,
    });
    onChange(nameOf("AccountAttachedAccountNames"), {
      value: event.value?.AccountAttachedAccountNames ?? null,
    });
  };
  if (isLoading) return <Loading isLoading />;
  return (
    <>
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Meter being associated</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Meter ID</label>
                  <Field
                    name={nameOf("MeterId")}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Meter number</label>
                  <Field
                    name={nameOf("MeterNumber")}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Property address</label>
                <Field
                  name={nameOf("MeterAssociationPropertyAddress")}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Account names</label>
                <Field
                  name={nameOf("MeterAssociationAccountNames")}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Date of attachment</label>
            <Field
              name={nameOf("DateOfAttachment")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Usage entitlement</label>
            <Field
              name={nameOf("UsageEntitlement")}
              min={1}
              component={NumericTextBox}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Billing group</label>
            <Field
              name={nameOf("BillingGroup")}
              data={billingGroupData}
              textField="Value"
              dataItemKey="Key"
              component={CCSearchComboBox}
              value={getDropdownValue(
                valueGetter("BillingGroup"),
                billingGroupData,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange("BillingGroup", {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">Account being attached</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Search by</label>
                  <Field
                    name={nameOf("SearchType")}
                    data={searchTypeData}
                    component={DropDownList}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Search assessment</label>
                  <Field
                    name={nameOf("Assessment")}
                    filterable
                    suggest
                    textField={textFieldMapping[searchTypeValue]}
                    data={getFieldValue("_option.Assessment.Data") ?? []}
                    loading={
                      getFieldValue("_option.Assessment.Loading") ?? false
                    }
                    placeholder={`Type ${searchTypeValue}`}
                    component={CCComboBox}
                    onFilterChange={handleSearchAssessment}
                    onChange={handleSearchAssessmentResult}
                    validator={requiredValidator}
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Property address</label>
                <Field
                  name={nameOf("AccountAttachedPropertyAddress")}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Account names</label>
                <Field
                  name={nameOf("AccountAttachedAccountNames")}
                  component={CCInput}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
