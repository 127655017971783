import { ICreateNoticeRun } from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/model";

export const mockCreateNoticeRun: ICreateNoticeRun = {
  NoticeRunName: "Paton Rd Nov 2017",
  ApplyAnOverridingDueDateToAmountsOwingInThisRun: null,
  IssueDate: "22-Dec-2021",
  DueDate: "8-Mar-2022",
  HaveNoTransactionsSinceLastNotice: null,
  AreInASchemeYetToBeDeclared: null,
  MinimumAmountDue: null,
  Start: "7-Nov-2017",
  End: "28-Jun-2018",
  HowAreTheNoticesToBeOrdered: "Scheme Account Number",
};

export const mockSelectSchemesToInclude = [
  {
    SelectSchemesToInclude: "Cardinia Drainage",
    ID: 1,
  },
  {
    SelectSchemesToInclude: "Como St Berrys Rd, Ferres Rd and P",
    ID: 2,
  },
  {
    SelectSchemesToInclude: "Cornish Road Special Charge Scheme",
    ID: 3,
  },
  {
    SelectSchemesToInclude: "DevonBelgrave Ave Special Charge",
    ID: 4,
  },
  {
    SelectSchemesToInclude: "Drainage",
    ID: 5,
  },
  {
    SelectSchemesToInclude: "George and Mary Street Bunyip Spec",
    ID: 6,
  },
  {
    SelectSchemesToInclude: "Guest Road Special Charge Scheme",
    ID: 7,
  },
  {
    SelectSchemesToInclude: "Henry Rd Special Charge Scheme ",
    ID: 8,
  },
  {
    SelectSchemesToInclude: "Island Road KWR Special Charge",
    ID: 9,
  },
  {
    SelectSchemesToInclude: "Jefferson Road Special Charge Scheme",
    ID: 10,
  },
  {
    SelectSchemesToInclude: "Majestic Dr Special Charge Scheme ",
    ID: 11,
  },
  {
    SelectSchemesToInclude: "McBride Road Special Charge Scheme",
    ID: 12,
  },
  {
    SelectSchemesToInclude:
      "Mill St Special Charge Scheme Miscellaneous/Conversion Schemes",
    ID: 13,
  },
  {
    SelectSchemesToInclude: "Moody Street Koo Wee Rup Special",
    ID: 14,
  },
  {
    SelectSchemesToInclude: "New Scheme",
    ID: 15,
  },
  {
    SelectSchemesToInclude: "Old Beaconsfield Road Special Charge",
    ID: 16,
  },
  {
    SelectSchemesToInclude: "Old Menzies Creek Road Special Charge",
    ID: 17,
  },
  {
    SelectSchemesToInclude: "Pakenham Industrial Estate Special",
    ID: 18,
  },
];

export const mockNoticesToBeOrdered = [
  { Code: "1", Name: "Name group, Name" },
  { Code: "2", Name: "Name" },
  { Code: "3", Name: "Scheme Account Number" },
  { Code: "4", Name: "Scheme Account ID" },
];
