import { getKeywordById } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/api";
import {
  colKeyWordsPicker,
  keyWordsPickerConfigMap,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/config";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { ICCGridProps } from "@components/cc-grid/_index";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React, { useState } from "react";

//@ Need enhance component later (load api, show error)
const nameOf = nameOfFactory<CoreKeyword>();

export interface IKeyWordsPicker extends ICCInputPickerProps {
  keyWordType: KEYWORD_TYPE;
  productType: PRODUCT_TYPE_NUMBER;
  onPickKeyWords: (value: any) => void;
  isGetDetail?: boolean;
  selectableMode?: "single" | "multiple" | "none";
  grid?: ICCGridProps;
}
export const KeyWordsPicker = (props: IKeyWordsPicker) => {
  const {
    keyWordType,
    productType,
    onPickKeyWords,
    onChange,
    onError,
    isGetDetail = true,
    selectableMode,
    textField,
    textProduce,
    valueField,
    valueProduce,
    grid,
    ...other
  } = props;
  const config = keyWordsPickerConfigMap[keyWordType];
  const [isLoading, setIsLoading] = useState(false);
  const [keywordListData, setKeywordListData] = useState<CoreKeyword[]>([]);

  const handleOnLoadData = () => {
    setIsLoading(true);

    getKeywords(keyWordType, productType).then((response) => {
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (response?.data) {
          setKeywordListData(response.data);
        } else {
          const error = response?.error ?? "Keyword list load failed";
          if (onError) onError(error);
        }
      }
    });
  };

  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: CoreKeyword = event.value;
    if (onPickKeyWords) onPickKeyWords(value);
    if (isGetDetail) {
      getKeywordById(value.Keyword_ID).then((response) => {
        if (isSuccessResponse(response) && response.data) {
          if (onChange) onChange({ value: response.data });
        } else {
          const error = response?.error ?? "Keyword is not found";
          if (onError) onError(error);
        }
      });
    }
  };

  return (
    <CCInputPicker
      {...other}
      isLoading={isLoading}
      dialog={{
        titleHeader: config?.title ?? "Keyword List",
        maxWidth: config?.width ?? "30%",
        minWidth: config?.minWidth,
      }}
      grid={{
        ...grid,
        columnFields: colKeyWordsPicker,
        primaryField: nameOf("Keyword_ID"),
        selectableMode: selectableMode ?? config?.selectableMode,
        data: keywordListData,
        isAutoHiddenPager: false,
      }}
      onLoadData={handleOnLoadData}
      onChange={handleOnChange}
      valueField={valueField ?? config?.valueField}
      valueProduce={valueProduce ?? config?.valueProduce}
      textField={textField ?? config?.textField}
      textProduce={textProduce ?? config?.textProduce}
    />
  );
};
