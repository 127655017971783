import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";

export interface ICRMSCommentDialog {
  onClosePopup: () => void;
  initData?: any;
  handleSubmit?: ((event: FormSubmitClickEvent) => void) | undefined;
  dialogTitle?: string;
  fieldTitle?: string;
  nameOfField?: string;
  placeholderString?: string;
}

export const CRMSCommentDialog = observer(
  ({
    onClosePopup,
    handleSubmit = () => {},
    initData = {},
    fieldTitle = "",
    dialogTitle = "",
    nameOfField = "",
    placeholderString,
  }: ICRMSCommentDialog) => {
    return (
      <Form
        initialValues={initData}
        key={JSON.stringify(initData)}
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, valid } = formRenderProps;

          return (
            <FormElement>
              <CCDialog
                maxWidth="35%"
                maxHeight="35%"
                onClose={() => onClosePopup()}
                titleHeader={dialogTitle}
                bodyElement={
                  <div className="cc-form">
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title={fieldTitle} isMandatory />
                          <Field
                            name={nameOfField}
                            placeholder={placeholderString ?? "Comment"}
                            rows={3}
                            component={CCTextArea}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <Fragment>
                    <div className="cc-dialog-footer-actions-right">
                      <div>
                        <Button
                          className="cc-dialog-button"
                          type="button"
                          onClick={() => {
                            onClosePopup();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="cc-dialog-button"
                          themeColor="primary"
                          type="submit"
                          onClick={onSubmit}
                          disabled={!valid}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Fragment>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
