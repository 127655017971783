import {
  VO_Candidate_LandRent,
  VO_Candidate_LandRent_Without_AnnualReview,
} from "@app/products/property/assessments/compliance/candidates-land-rent/model";
import { getLabel } from "@app/products/property/components/label/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { isBoolean, isNull, isString, isUndefined } from "lodash";

export const getBookmarkDisplayName = (
  selectedRow:
    | VO_Candidate_LandRent
    | VO_Candidate_LandRent_Without_AnnualReview
) => {
  const dynamicDisplayName = selectedRow.Assessment_Id
    ? `- ${selectedRow.Assessment_Id}`
    : "";
  return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (
  selectedRow:
    | VO_Candidate_LandRent
    | VO_Candidate_LandRent_Without_AnnualReview
) => {
  return getDisplayTextWithDashes([
    selectedRow.Assess_Reference,
    selectedRow.Property_Name_Address_Locality,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Compliance - Candidates Land Rent`;
};
export const removeUnusedProperty = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj)?.filter(([_, value]) => {
      if (isString(value) && (value as string).trim() === "") {
        return false;
      }
      if (isBoolean(value) && value === false) {
        return false;
      }
      return !isUndefined(value) && !isNull(value);
    })
  );
};
