import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { isFieldVisible } from "@app/products/crms/util";
import { CCValueField } from "@components/cc-value-field/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const WebRequest = observer(() => {
  const { uiControl } = useCRMSEventStore();

  return (
    <div>
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          {isFieldVisible(uiControl?.LblWebServiceSource.DisplayStatus) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServiceSource?.Value}
                label="Web service source"
              />
            </div>
          )}
          {isFieldVisible(
            uiControl?.LblWebServiceRequestType.DisplayStatus
          ) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServiceRequestType?.Value}
                label="Request type"
              />
            </div>
          )}
          {isFieldVisible(uiControl?.LblWebServicePassword.DisplayStatus) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServicePassword?.Value}
                label="Access code"
              />
            </div>
          )}
          {isFieldVisible(uiControl?.LblWebServiceCategory.DisplayStatus) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServiceCategory?.Value}
                label="Category"
              />
            </div>
          )}
          {isFieldVisible(
            uiControl?.LblWebServiceAfterHoursService.DisplayStatus
          ) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServiceAfterHoursService?.Value}
                label="After hours service"
              />
            </div>
          )}
          {isFieldVisible(uiControl?.LblWebServiceErrors.DisplayStatus) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServiceErrors?.Value}
                label="Web service errors"
              />
            </div>
          )}
          {isFieldVisible(
            uiControl?.LblWebServiceEventDescription.DisplayStatus
          ) && (
            <div className="cc-field">
              <CCValueField
                value={uiControl?.LblWebServiceEventDescription?.Value}
                label="Submitted request"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
