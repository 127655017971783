export const validatorAmalgamateAssessmentAssociation = (
  values: any
): string | undefined => {
  let primaryAssessment;

  // any = VO_Assessment_w_Detail + Is_Primary
  if (values?.Assessments?.length) {
    primaryAssessment = values?.Assessments?.find(
      (item: any) => item?.Is_Primary
    );
  }

  if (values?.Assessments?.length < 2 && !primaryAssessment?.Is_Primary) {
    return "AssessmentsLengthAndAssessmentPrimary";
  }

  if (values?.Assessments?.length < 2) {
    return "AssessmentsLength";
  }

  if (!primaryAssessment?.Is_Primary) {
    return "AssessmentPrimary";
  }

  return undefined;
};
