import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const processesRoute: ICCRoute = {
  path: "processes",
  name: "Processes",
  enum: eMenuAction.Process_Folder,
  children: [
    {
      path: "housekeeping",
      name: "Housekeeping",
      enum: eMenuAction.Process_Housekeeping,
      component: require("./housekeeping/_index").default,
    },
    {
      path: "reallocation-of-credit",
      name: "Reallocation of credit",
      enum: eMenuAction.Process_Reallocation_Of_Credit,
      component: require("./reallocation-of-credit/_index").default,
    },
    {
      path: "write-off-postponed-balances",
      name: "Write off postponed balances",
      enum: eMenuAction.Process_Write_Off_Postponed_Balances,
      component: require("./write-off-postponed-balances/_index").default,
    },
    {
      path: "write-off-small-balances",
      name: "Write off small balances",
      enum: eMenuAction.Process_Write_Off_Small_Balances,
      component: require("./write-off-small-balances/_index").default,
    },
  ],
};
