import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import { isSuccessResponse } from "@common/apis/util";
import { useActionBatchUpdateButtonStore } from "@common/pages/actions/components/action-bar/buttons/batch-update-action/store";
import { BatchUpdateActionDialog } from "@common/pages/actions/components/action-bar/dialog/batch-update-action/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce, useLocation } from "react-use";

interface IBatchUpdateActionButtonProps {
  isDisabled?: boolean;
  gridId?: string;
}
export const BatchUpdateActionButton = observer(
  ({ isDisabled = false, gridId }: IBatchUpdateActionButtonProps) => {
    const {
      isFirstCheckSuperAdmin,
      setIsFirstCheckSuperAdmin,
      isShowDialog,
      setIsShowDialog,
      isSuperAdmin,
      setIsSuperAdmin,
      resetStore,
      setPrevPathName,
      prevPathName,
    } = useActionBatchUpdateButtonStore();
    const { gridSelectedIds } = useCCProductListViewStore();
    const { currentUserInfo } = useGlobalStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { pathname } = useLocation();
    const { loader } = useSelectionListener(gridId);
    const [isLoadingGetAllRecords, setIsLoadingGetAllRecords] = useState(false);
    const [recordsSelected, setRecordsSelected] = useState<number[]>([]);

    const checkIsSuperAdmin = async (userID: number) => {
      const response = await postCheckIsSuperAdmin(userID);
      if (isSuccessResponse(response)) {
        setIsSuperAdmin(response.data ?? false);
      } else {
        pushNotificationPortal({
          title: "Load permission action for the batch update button failed.",
          type: "error",
          autoClose: false,
          description: response?.error,
        });
      }
    };

    const handleResetStore = () => {
      if (
        prevPathName &&
        prevPathName?.trim()?.length &&
        prevPathName !== pathname
      )
        resetStore();
    };

    useEffect(() => {
      handleResetStore();
      if (currentUserInfo?.UserPKID && isFirstCheckSuperAdmin) {
        setPrevPathName(pathname ?? "");
        checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
        setIsFirstCheckSuperAdmin(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserInfo?.UserPKID, isFirstCheckSuperAdmin]);

    useEffectOnce(() => {
      return () => {
        handleResetStore();
      };
    });

    const handleClickButton = async () => {
      let idsExport: number[] =
        gridSelectedIds?.length > 0 ? gridSelectedIds : [];
      if (loader.current.available) {
        setIsLoadingGetAllRecords(true);
        idsExport =
          (await loader.current.process({ $select: ["ID"], $flat: true }))
            ?.data ?? [];
        setIsLoadingGetAllRecords(false);
      }

      if (idsExport?.length) {
        setIsShowDialog(true);
        setRecordsSelected(idsExport);
      } else {
        pushNotificationPortal({
          title: `Select all records failed.`,
          type: "error",
          autoClose: false,
        });
      }
    };

    return isSuperAdmin ? (
      <>
        <CCNavButton
          title="Batch Update"
          onClick={handleClickButton}
          disabled={
            isDisabled || gridSelectedIds.length === 0 || isLoadingGetAllRecords
          }
          isLoading={isLoadingGetAllRecords}
        />

        {isShowDialog && (
          <BatchUpdateActionDialog
            onClose={() => setIsShowDialog(false)}
            actionsIds={recordsSelected}
          />
        )}
      </>
    ) : null;
  }
);
