import { RECORDTYPE } from "@common/constants/recordtype";

export interface ActionsResponse {
  CreatedBy: string;
  CreatedDate: Date;
  DueDate: Date;
  ID: number;
  Officer: string;
  ParentDescription: string;
  Status: string;
  Type: string;
}

export interface Svc_BubbleUpDetails {
  BubbleList: Svc_BubbleupIdentifier[];
  AncestorIsSameAsParent: boolean;
}
export interface Svc_BubbleupIdentifier {
  RecordID: number;
  RecordType_ENUM: RECORDTYPE;
  RecordTypeName: string;
  RecordNumber: string;
  BubbleupType_ENUM: BubbleupType_ENUM;
  LinkManagePage?: string;
}

export enum BubbleupType_ENUM {
  SystemInitialise = 0,
  Parent = 1,
  Ancestor = 2,
  Association = 3,
  Shared = 4,
}
