import {
  checkValidAddress,
  searchWastewaterAddress,
} from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/api";
import {
  colSearchAddress,
  searchAddressConfig,
} from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/config";
import { VMASResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/model";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { isEqual } from "lodash";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
interface IWastewaterSearchAddressProps extends ComboBoxProps {
  onChangeAddress?: (address: VMASResult | null) => void;
}
export const WastewaterSearchAddress = (
  props: IWastewaterSearchAddressProps
) => {
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<VMASResult[]>([]);
  const [value, setValue] = useState<string | null>(null);

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    if (searchText.length < searchAddressConfig.minCharacters) return;

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      setIsLoading(true);
      searchWastewaterAddress(event.filter.value).then((result) => {
        setData(result.data || []);
        setIsLoading(false);
      });
    }, searchAddressConfig.typeSpeed);
  };

  const handleChange = (event: ComboBoxChangeEvent) => {
    if (!event.value) {
      setValue(null);
      if (props.onChange) props.onChange({ ...event, value: null });
      return;
    }
    setIsLoading(true);
    setValue(null);
    checkValidAddress(
      event.value.Address_Identifier,
      event.value.StreetNo ?? ""
    ).then((result) => {
      if (result.data) {
        const displayValue = `${event.value.AddressLine} ${event.value.Suburb} ${event.value.State} ${event.value.Postcode}`;
        setValue(displayValue);
        if (props.onChange) props.onChange({ ...event, value: displayValue });
        if (props.onChangeAddress && result.data[0]) {
          result.data[0].Address_Identifier = displayValue;
          props.onChangeAddress(result.data[0]);
        }
      } else {
        if (props.onChange) props.onChange({ ...event, value: null });
        if (props.onChangeAddress) props.onChangeAddress(null);
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });

  useEffect(() => {
    if (!isEqual(props.value, value)) setValue(props.value);
    // eslint-disable-next-line
  }, [props.value]);

  return (
    <ComboBox
      {...props}
      filterable
      suggest
      data={data}
      loading={isLoading}
      onFilterChange={handleSearch}
      itemRender={ItemRender}
      value={value}
      header={
        <div className="cc-search-header">
          {colSearchAddress.map((col: IColumnFields) => (
            <div key={col.field} style={{ width: col.width }}>
              {col.title}
            </div>
          ))}
        </div>
      }
      onChange={handleChange}
      popupSettings={{ className: "cc-wastewater-search-address" }}
    />
  );
};
const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {colSearchAddress.map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {dataItem[col.field] ?? ""}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
