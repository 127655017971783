import { IActionsIncompleteDetailTab } from "@app/products/property/actions/draft-list/components/reference-sidebar/detail/model";

export const mockActionsIncompleteDetailTab: IActionsIncompleteDetailTab = {
  Component: "Holding",
  Workflow: "Create Holding",
  Region: "South West",
  WorkflowStatus: "Completed",
  UserId: "randallh",
  Created: new Date("4/12/2022"),
  Modified: new Date("4/12/2022"),
  ApprovalStatus: "Being Approved (LLS Officer)",
  FunctionalGroup: "Holding Approval",
};
