import {
  IReadingGridData,
  ISpecialReadingDialogData,
} from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";
import { mockSpecialReadingData } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/mock";
import { convertToReadingGridData } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/util";
import { IRegistersSummary } from "@app/products/property/registers/[id]/model";
import { sleep } from "@common/utils/common";

export const getRegisterSpecialReadingData = async (
  registerData: IRegistersSummary,
  meterSelected: IRegisterMeters[]
): Promise<ISpecialReadingDialogData> => {
  await sleep(500);
  let newReading: IReadingGridData[] = [];
  meterSelected.map((item, index) => {
    newReading.push({ ...convertToReadingGridData(item), MeterInstallationId: index + 1 });
    return newReading;
  });
  return {
    ...mockSpecialReadingData,
    AccountReference: registerData.AccountReference,
    Reading: newReading,
  };
};
