import {
  IMailMergeButtonProps,
  MailMergeButton,
} from "@app/core/new-mail-merge/buttons/components/buttons/_index";
import { FormAction } from "@app/products/crms/model";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IMailMergeButtonManagePageProps extends IMailMergeButtonProps {
  formAction?: FormAction;
}

export const MailMergeButtonManagePage = observer(
  (props: IMailMergeButtonManagePageProps) => {
    const { formAction = FormAction.CORE_TOOLBAR_ADD_DOCUMENT } = props;
    return <MailMergeButton {...props} formAction={formAction} />;
  }
);
