import { IChangeStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/model";
import { convertTypeApplicationStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/util";
import { PPRUpdateStatusDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/_index";
import {
  getStatus,
  putStatus,
} from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/api";
import { StatusChangeDetails } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PPRChangeStatusButton = observer(() => {
  const { ppr, pprId, setPPRWithLoading } = usePPRStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);
  const [initialValues, setInitialValues] = useState<IChangeStatus>();
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleOnClick = async () => {
    setIsLoading(true);
    const resStatus = await getStatus(
      convertTypeApplicationStatus(ppr?.Status_ENUM),
      ppr?.Flag_PlansToComply,
      false
    );
    setIsLoading(false);
    setShowUpdateStatusDialog(true);
    if (isSuccessResponse(resStatus)) {
      if (resStatus?.data) {
        setInitialValues({
          CurrentStatus: resStatus?.data[0]?.Key,
          _options: {
            StatusList: resStatus?.data ?? [],
            IsPlansToComply: ppr?.Flag_PlansToComply,
            IsShowDecisionDate: false,
            IsShowDecisionType: false,
          },
        });
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: resStatus?.statusText ?? "Status list load failed",
          type: "error",
        },
      ]);
    }
  };

  const handleOnSubmit = async (data: IChangeStatus) => {
    if (!pprId || !data?.CurrentStatus) return;
    setIsUpdating(true);
    const reqBody: StatusChangeDetails = {
      ApplicationIDs: [pprId],
      Status: data?.CurrentStatus,
      Decision: data?.CurrentDecision,
      DecisionDate: data?.DecisionDate,
    };
    const resUpdateStatus = await putStatus(pprId, reqBody);
    setIsUpdating(false);
    setShowUpdateStatusDialog(false);
    if (isSuccessIdentityPacket(resUpdateStatus)) {
      clearErrorNotification();
      setPPRWithLoading(resUpdateStatus?.data?.Application);
      pushNotification({
        type: "success",
        title: "Status changed successfully",
      });
    } else {
      pushNotification({
        title: "Change status failed",
        type: "error",
        autoClose: false,
        description: resUpdateStatus.data?.Errors ?? resUpdateStatus.statusText,
      });
    }
  };

  return (
    <>
      <CCNavButton title="Change status" onClick={handleOnClick} />

      {showUpdateStatusDialog && (
        <PPRUpdateStatusDialog
          isLoading={isLoading}
          isUpdating={isUpdating}
          notifications={notifications}
          initialValues={initialValues}
          onClose={() => setShowUpdateStatusDialog(false)}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  );
});
