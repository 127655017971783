import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { DTO_LOV_SupplementaryType } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

export const NEW_SUPPLEMENTARY_FORM_STEP = "CreateSupplementary";

export const NewSupplementaryFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ options, formRenderProps, nameOf }: IFormStepElement) => {
    const { createSupplementaryLOVs } = useNewSupplementaryDialogStore();

    //Get label
    const [assessmentLowercaseLabel, assessmentLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.AssessmentLowercase,
        ECustomColNameProperty.Assessment,
      ]);

    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const assessmentType = convertValueLOVToNumber<DTO_LOV>(
      createSupplementaryLOVs?.AssessmentType ?? [],
      "Code"
    );
    const currentAssessmentTypeItem = getDropdownValue(
      getFieldValue("BalanceTransferMethodId"),
      assessmentType,
      "Code"
    );
    const [isShowValuationTypeToBeUsed, setIsShowValuationTypeToBeUsed] =
      useState<boolean>(
        currentAssessmentTypeItem
          ? currentAssessmentTypeItem?.Name === "By Valuation"
          : false
      );
    const installmentType = convertValueLOVToNumber<DTO_LOV>(
      createSupplementaryLOVs?.InstallmentType ?? [],
      "Code"
    );
    const supplementaryType =
      convertValueLOVToNumber<DTO_LOV_SupplementaryType>(
        createSupplementaryLOVs?.SupplementaryType ?? [],
        "Code"
      );
    const valuationTypeTobeUsed = convertValueLOVToNumber<DTO_LOV>(
      createSupplementaryLOVs?.ValuationTypeTobeUsed ?? [],
      "Code"
    );
    const isInstalmentPlanHidden = getDropdownValue(
      getFieldValue("SupplementaryType"),
      supplementaryType,
      "Code"
    )?.IsInstalmentPlanHidden;

    const handleValidate = useCallback(
      (value: any) => {
        if (options?.readOnly || isInstalmentPlanHidden) return undefined;
        return requiredValidator(value);
      },
      [options?.readOnly, isInstalmentPlanHidden]
    );

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Name
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Name")}
                component={CCInput}
                placeholder={"Name"}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Type
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("SupplementaryType")}
                component={CCSearchComboBox}
                data={supplementaryType}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                disabled={options?.isReadOnly}
                value={getDropdownValue(
                  getFieldValue("SupplementaryType"),
                  supplementaryType,
                  "Code"
                )}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("SupplementaryType"), {
                    value: event?.value?.Code,
                  });
                  if (event?.value?.IsInstalmentPlanHidden) {
                    onChange(nameOf("InstalmentPlanId"), { value: undefined });
                    onChange(nameOf("UseExistingInstalmentPlan"), {
                      value: false,
                    });
                    onChange(nameOf("TransferRemainingBalance"), {
                      value: false,
                    });
                    onChange(nameOf("BalanceTransferMethodId"), {
                      value: undefined,
                    });
                    onChange(nameOf("BalanceTransferValuationType"), {
                      value: undefined,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Reference</label>
              <Field
                name={nameOf("Reference")}
                placeholder={"Reference"}
                component={CCInput}
                readOnly={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Date
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Date")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Notes</label>
              <Field
                name={nameOf("Notes")}
                rows={3}
                component={CCTextArea}
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
          {getFieldValue("SupplementaryType") && !isInstalmentPlanHidden ? (
            <>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">
                    Instalment plan
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    name={nameOf("InstalmentPlanId")}
                    component={CCSearchComboBox}
                    data={installmentType}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    isUseDefaultOnchange
                    validator={handleValidate}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  {/* TODO: Waiting API to return full label name */}
                  <label className="cc-label">
                    Override supplementary with existing{" "}
                    {assessmentLowercaseLabel} instalment plan (if present)
                  </label>
                  <Field
                    name={nameOf("UseExistingInstalmentPlan")}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                    checked={getFieldValue("UseExistingInstalmentPlan")}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  {/* TODO: Waiting API to return full label name */}
                  <label className="cc-label">
                    Transfer remaining balance from obsolete{" "}
                    {assessmentLowercaseLabel}s
                  </label>
                  <Field
                    name={nameOf("TransferRemainingBalance")}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                    checked={getFieldValue("TransferRemainingBalance")}
                    onChange={(event: SwitchChangeEvent) => {
                      onChange(nameOf("TransferRemainingBalance"), {
                        value: event?.value,
                      });
                      if (!event?.value) {
                        onChange(nameOf("BalanceTransferMethodId"), {
                          value: undefined,
                        });
                        onChange(nameOf("BalanceTransferValuationType"), {
                          value: undefined,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              {getFieldValue("TransferRemainingBalance") && (
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    {/* TODO: Waiting API to return full label name */}
                    <label className="cc-label">{`${assessmentLabel} by`}</label>
                    <Field
                      name={nameOf("BalanceTransferMethodId")}
                      component={CCSearchComboBox}
                      data={assessmentType}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      value={getDropdownValue(
                        getFieldValue("BalanceTransferMethodId"),
                        assessmentType,
                        "Code"
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange(nameOf("BalanceTransferMethodId"), {
                          value: event.target.value?.Code ?? null,
                        });
                        setIsShowValuationTypeToBeUsed(
                          event.target.value.Name === "By Valuation"
                        );
                        if (event.target.value.Name !== "By Valuation") {
                          onChange(nameOf("BalanceTransferValuationType"), {
                            value: undefined,
                          });
                        }
                      }}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  {isShowValuationTypeToBeUsed && (
                    <div className="cc-field">
                      <label className="cc-label">
                        Valuation type to be used
                      </label>
                      <Field
                        name={nameOf("BalanceTransferValuationType")}
                        component={CCSearchComboBox}
                        data={valuationTypeTobeUsed}
                        textField={nameOfLov("Name")}
                        dataItemKey={nameOfLov("Code")}
                        isUseDefaultOnchange
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : null}
        </section>
      </>
    );
  }
);
