import { columnDebtRecoveryDetailHistory } from "@common/pages/contacts/config";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";

const DebtRecoveryDetail: React.FC<GridDetailRowProps> = ({ dataItem }) => {
  return (
    <div className="cc-contact-debt-recovery cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Assessment associated with debt recovery
            </label>
            <CCGrid
              className="cc-contact-debt-recovery-history-grid"
              data={dataItem.AssessmentAssociated || []}
              columnFields={columnDebtRecoveryDetailHistory}
              primaryField="ID"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebtRecoveryDetail;
