import { history } from "@/AppRoutes";
import {
  getRegionById,
  postRegion,
} from "@app/products/town-planning/ppr/system-admin/regions/[id]/api";
import { RegionForm } from "@app/products/town-planning/ppr/system-admin/regions/[id]/config";
import { SYSTEM_ADMIN_REGION_ROUTE } from "@app/products/town-planning/ppr/system-admin/regions/[id]/constant";
import { Region } from "@app/products/town-planning/ppr/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { LOOKUP_REGION_ROUTE } from "@common/pages/settings/lookups/regions/constant";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class RegionStore {
  private _region?: Region = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get region() {
    return toJS(this._region);
  }
  setRegion = (region?: Region) => {
    runInAction(() => {
      this._region = region;
    });
  };

  loadRegion = async (regionId?: number, isNew?: boolean): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    if (isNew || !regionId) {
      this.setRegion(RegionForm as Region);
    } else {
      const regionRes = await getRegionById(regionId);
      let newRegion = undefined;
      if (isSuccessResponse(regionRes) && regionRes.data) {
        newRegion = regionRes.data;
        if (regionRes.data.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }

      this.setRegion(newRegion);
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._region = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isInactive = false;
    });
  };

  saveRegion = async (region: Region) => {
    this.setIsLoading(true);
    const response = await postRegion(region);
    this.setIsLoading(false);
    //we will push the history based on current route (lookup or system admin) and then remove "/new" from route
    const currentRoute =
      history.location.pathname.indexOf(LOOKUP_REGION_ROUTE) === -1
        ? SYSTEM_ADMIN_REGION_ROUTE
        : LOOKUP_REGION_ROUTE;
    if (isSuccessIdentityPacket(response)) {
      history.replace(`${currentRoute}/${response?.data?.ID}`, {
        notification: [
          {
            title: "Region saved successfully",
            type: "success",
          },
        ],
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Region save failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };
}

export const RegionStoreInstance = new RegionStore();
const RegionStoreContext = createContext(RegionStoreInstance);
export const useRegionStore = () => {
  return useContext(RegionStoreContext);
};
