import { apiCoreGetSiteUsers } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { ASPNET_MembershipUser } from "@common/models/user";
import { SettingUrl } from "@common/pages/settings/constant";
import {
  ChangePassword,
  NewSiteUser,
  SiteUser,
  SiteUserLovs,
} from "@common/pages/settings/security/site-users/_id/model";

export const getSiteUserById = async (
  pprEnquiryId: number
): Promise<APIResponse<NewSiteUser | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `${SettingUrl.GET_SETTING_SITE_USERS.replace(
        "{id}",
        pprEnquiryId.toString()
      )}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveSiteUser = async (
  siteuser: NewSiteUser
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      apiCoreGetSiteUsers(),
      siteuser
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putSaveSiteUser = async (
  siteuserID: number,
  siteuser: NewSiteUser
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put(
      `/api/core/internal/siteuser/${siteuserID}`,
      siteuser
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSiteUserLOV = async (): Promise<
  APIResponse<SiteUserLovs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      "api/core/internal/siteuserlovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getMembershipUserByUserName = async (
  userName?: string
): Promise<APIResponse<ASPNET_MembershipUser>> => {
  try {
    return await CoreAPIService.getClient().get<ASPNET_MembershipUser>(
      `api/core/internal/siteuser/get-membership-user-by-user-name?userName=${userName}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postChangeUserName = async (
  contactSiteUser: SiteUser
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      `${SettingUrl.SITE_USER_CHANGE_USERNAME}`,
      contactSiteUser
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putChangePassword = async (
  requestBody: ChangePassword
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().put(
      `${SettingUrl.SITE_USER_CHANGE_PASSWORD}`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const checkAvailability = async (
  userName: string
): Promise<APIResponse<any>> => {
  try {
    return CoreAPIService.getClient().get(
      `${SettingUrl.SETTING_GET_CONTACTS}`,
      {
        params: {
          userName,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
