import { LookupTables } from "@app/products/crms/system-admin/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<LookupTables>();

export const colLookupTables: IColumnFields[] = [
  {
    field: nameOf("Description"),
    title: "Lookup Value",
    linkTo: (dataItem: LookupTables) => `lookup-tables/${dataItem.ID}`,
  },
  {
    field: nameOf("LookupTableType_Name"),
    title: "Lookup Table",
  },
  {
    field: nameOf("SortIndex"),
    title: "Sort Index",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LookupTable_EnumeratedValue_Name"),
    title: "System Value",
  },
];
