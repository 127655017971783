import { VO_Assessment } from "@app/products/property/assessments/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { getLabel } from "@app/products/property/components/label/config";

export const PropertyAssessmentCollectionBookmark = {
  getBookmarkDisplayName(selectedRow: VO_Assessment) {
    const dynamicDisplayName = selectedRow.Assessment_Id
      ? `- ${selectedRow.Assessment_Id}`
      : "";
    return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: VO_Assessment) {
    return getDisplayTextWithDashes([
      selectedRow.Assessment_Number_Formatted,
      selectedRow.Property_Name_Address_Locality,
    ]);
  },
  getBookmarkListViewDisplayName() {
    return `Property - ${getLabel.assessments()}`;
  },
  getBookmarkListViewDetail() {
    return `Property - ${getLabel.assessments()} - Collections`;
  },
};
