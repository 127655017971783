import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  //Document display setup field
  const fieldRecordsEnableTitle = getConfigDataField(
    ECorporateSettingsField.Records_EnableTitleColumn
  );
  const fieldRecordsEnableFilename = getConfigDataField(
    ECorporateSettingsField.Records_EnableFileNameColumn
  );
  const fieldRecordsEnableCategory = getConfigDataField(
    ECorporateSettingsField.Records_EnableCategoryColumn
  );
  const fieldDisplayLinkedRecords = getConfigDataField(
    ECorporateSettingsField.Records_DisplayLinkedRecords
  );
  const fieldEnableFilenumber = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Records_EnableFileNumberColumn
  );
  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldRecordsEnableTitle && (
            <SwitchGlobalSettings
              data={fieldRecordsEnableTitle}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldRecordsEnableFilename && (
            <SwitchGlobalSettings
              data={fieldRecordsEnableFilename}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldRecordsEnableCategory && (
            <SwitchGlobalSettings
              data={fieldRecordsEnableCategory}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldDisplayLinkedRecords && (
            <SwitchGlobalSettings
              data={fieldDisplayLinkedRecords}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldEnableFilenumber && (
            <SwitchGlobalSettings
              data={fieldEnableFilenumber}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>
      </section>
    </>
  );
});
