import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { Log } from "@common/pages/settings/system-admin/system-logs/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Log>();
export const colSystemLogs: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    // linkTo: (dataItem: Svc_RefUsage_RecycleBin) =>
    //   getPageLinkByRecordType(dataItem.RecordType_ENUM, dataItem.Id),
  },
  {
    field: nameOf("System_Name"),
    title: "System Name",
  },
  {
    field: nameOf("Event1"),
    title: "Event1",
  },
  {
    field: nameOf("Event2"),
    title: "Event2",
  },
  {
    field: nameOf("Event3"),
    title: "Event3",
  },
  {
    field: nameOf("Message"),
    title: "Message",
  },
  {
    field: nameOf("StackTrace"),
    title: "Stack Trace",
  },
  {
    field: nameOf("Sys_CreatedDate"),
    title: "Created Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Sys_CreatedBy"),
    title: "Created By",
  },
];
