import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const deleteAppeals = async (
  ids: number[]
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/core/internal/appeals/delete`,
      {
        data: ids,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
