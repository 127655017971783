import {
  eFileUploadValidationErrors,
  IAttachmentFile,
} from "@app/core/documents/components/documents-input/model";
import { Attachment } from "@app/core/documents/model";
import { getUUID } from "@common/utils/common";

export const toAttachmentFiles = (
  attachments: Attachment[]
): IAttachmentFile[] =>
  attachments.map((attachment) => ({ ...attachment, _uid: getUUID() }));

export const getFileUploadValidationMessage = (validationError?: string) => {
  switch (validationError) {
    case eFileUploadValidationErrors.InvalidFiles:
      return "Invalid file(s). Please check file upload requirements.";
    case eFileUploadValidationErrors.InvalidFileExtension:
      return "File type not allowed.";
    case eFileUploadValidationErrors.InvalidMaxFileSize:
      return "File size too large.";
    case eFileUploadValidationErrors.InvalidMinFileSize:
      return "File size too small.";
    default:
      return "File upload invalid.";
  }
};
