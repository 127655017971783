import { getSearchAssessment } from "@app/products/property/components/action-bar/dialog/add-assessment/api";
import {
  getOptionRegex,
  getUrlApiSearchBy,
  searchConfig,
} from "@app/products/property/components/action-bar/dialog/add-assessment/config";
import {
  ButtonNameSubmit,
  IAddAssessmentDataForm,
  ISearchBy,
  VO_Assessment_Ratepayer,
  eSearchAssessmentBy,
} from "@app/products/property/components/action-bar/dialog/add-assessment/model";
import { useAddAssessmentDialogStore } from "@app/products/property/components/action-bar/dialog/add-assessment/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IAddAssessmentDataForm>();

interface IAddAssessmentDialogProps {
  dataUrl: string;
  isLoadingFinish?: boolean;
  notificationRef?: React.MutableRefObject<ICCLocalNotificationHandle | null>;
  handleFinish: (value: any) => void;
  onClose: () => void;
}

export const AddAssessmentDialog = observer(
  ({
    dataUrl,
    isLoadingFinish = false,
    notificationRef,
    handleFinish,
    onClose,
  }: IAddAssessmentDialogProps) => {
    //store
    const {
      isLoading,
      responseLoadError,
      reloadOptions,
      initialDataForm,
      listDataSearch,
      loadOption,
      isUseAssessRefAsPrimary,
    } = useAddAssessmentDialogStore();
    //Get labels
    const [ratepayerLabel, assessmentLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Ratepayer,
      ECustomColNameProperty.Assessment,
    ]);

    //state local
    const [filterValue, setFilterValue] = useState<string | null>(null);
    const refTimeOut = useRef<NodeJS.Timeout | null>(null);

    /**
     * Handle submit form
     * 2 types button => btn-finish and btn-adjust
     * @param values
     * @param event
     * @returns
     */
    const handleOnSubmit = (
      values: {
        [name: string]: any;
      },
      _event?: any
    ) => {
      const assessment = values?.Assessment;
      if (assessment) {
        handleFinish(assessment);
      }
    };

    /**
     * process data item render in Combobox
     * isUseAssessRefAsPrimary true => Assessment_Reference, otherwise => Assessment_Number
     * @param li
     * @param itemProps
     * @returns
     */
    const itemRender = (
      li: ReactElement<HTMLLIElement>,
      itemProps: ListItemProps
    ) => {
      const { dataItem } = itemProps;
      const itemChildren = (
        <span>
          {`${dataItem?.Assessment_Id ?? ""} - ${
            isUseAssessRefAsPrimary
              ? dataItem?.Assessment_Reference ?? ""
              : dataItem?.Assessment_Number ?? ""
          } - ${dataItem?.Ratepayer_Address ?? ""} - ${
            dataItem?.Ratepayer_Name ?? ""
          }`}
        </span>
      );
      return React.cloneElement(li, li.props, itemChildren);
    };

    useEffectOnce(() => {
      loadOption();
      //remove setTimeout
      return () => {
        if (refTimeOut.current) clearTimeout(refTimeOut.current);
      };
    });

    return (
      <Form
        onSubmit={(
          values: {
            [name: string]: any;
          },
          event?: React.SyntheticEvent<any>
        ) => handleOnSubmit(values, event)}
        initialValues={initialDataForm}
        key={JSON.stringify(initialDataForm)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit, onChange, valid, modified } =
            formRenderProps;

          //get current search value
          const searchByValue: ISearchBy | null = valueGetter(
            "_option.SearchBy.Value"
          );
          //get assessment value
          const assessmentValue: VO_Assessment_Ratepayer | null = valueGetter(
            nameOf("Assessment")
          );

          /**
           * handle Search Assessment
           * @param event
           * @returns
           */
          const handleSearchAssessment = (event: ComboBoxFilterChangeEvent) => {
            //get filter value
            const { filter } = event;
            const searchField = filter?.field as eSearchAssessmentBy;
            let filterValue = filter?.value;
            //reset lastIndex of regex
            getOptionRegex[searchField].lastIndex = 0;
            //flag check correct case
            const isCorrectFormat =
              getOptionRegex[searchField].test(filterValue);
            if (!isCorrectFormat) {
              //just only input number, replace words
              setFilterValue(filterValue.replace(/[^0-9]/gi, ""));
              return;
            }
            //check case decimal
            if (
              [
                eSearchAssessmentBy.ValuationNumber,
                eSearchAssessmentBy.AssessmentNumber,
              ].includes(searchField) &&
              filterValue.endsWith(".")
            )
              filterValue = filterValue + "0";
            setFilterValue(filterValue);
            onChange("_option.Assessment.Data", { value: [] });
            if (!searchByValue) return;
            //get search options
            const searchBy: eSearchAssessmentBy =
              searchField ?? eSearchAssessmentBy.AssessmentNumber;
            //clear previous timeout
            if (refTimeOut.current) clearTimeout(refTimeOut.current);
            refTimeOut.current = setTimeout(async function () {
              onChange("_option.Assessment.Loading", { value: true });
              //check length with search corresponding with options
              if (filterValue?.length >= searchConfig.numberLetterSearch) {
                //get url api
                let urlAPI = `${getUrlApiSearchBy[searchBy]}`.replace(
                  "{PROPERTY_ASSESSMENT_REGISTER_URL}",
                  dataUrl
                );
                //get search value
                if (searchField === eSearchAssessmentBy.AssessmentReference) {
                  urlAPI = urlAPI.replace(
                    "{valueAssessmentReference}",
                    `'${filterValue}'`
                  );
                } else if (searchField === eSearchAssessmentBy.HoldingName) {
                  urlAPI = urlAPI.replace(
                    "{valueAssessPropertyName}",
                    `${filterValue}`
                  );
                } else {
                  urlAPI = `${urlAPI}${filterValue}`;
                }
                //call api
                const response = await getSearchAssessment(urlAPI);
                if (isSuccessResponse(response)) {
                  onChange("_option.Assessment.Data", {
                    value: response?.data?.value,
                  });
                  onChange("_option.Assessment.Loading", { value: false });
                } else {
                  onChange("_option.Assessment.Data", { value: [] });
                  onChange("_option.Assessment.Loading", { value: false });
                }
              } else {
                onChange("_option.Assessment.Data", { value: [] });
                onChange("_option.Assessment.Loading", { value: false });
              }
            }, searchConfig.typeSpeed);
          };

          return (
            <CCDialog
              onClose={onClose}
              maxWidth="40%"
              height="auto"
              disabled={isLoadingFinish}
              titleHeader={`Add ${assessmentLabel}`}
              bodyElement={
                <>
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        reloadOptions();
                      }}
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">Search by</label>
                            <Field
                              name={"_option.SearchBy.Value"}
                              component={CCDropDownList}
                              textField="Value"
                              data={valueGetter("_option.SearchBy.Data")}
                              onChange={(event: DropDownListChangeEvent) => {
                                onChange("_option.SearchBy.Value", {
                                  value: event.target.value,
                                });
                                //reset data remaining fields
                                onChange(nameOf("Assessment"), { value: null });
                                setFilterValue(null);
                              }}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              {searchByValue?.Value}
                              <CCTooltip type="validator" />
                            </label>
                            <Field
                              name={nameOf("Assessment")}
                              component={CCComboBox}
                              clearButton={false}
                              filterable
                              suggest
                              placeholder={`Type ${
                                searchByValue?.Value?.toLocaleLowerCase() ?? ""
                              }`}
                              textField={
                                searchByValue?.Key ?? listDataSearch[0].Key
                              }
                              data={
                                valueGetter("_option.Assessment.Data") ?? []
                              }
                              loading={valueGetter(
                                "_option.Assessment.Loading"
                              )}
                              onFilterChange={handleSearchAssessment}
                              validator={requiredValidator}
                              itemRender={itemRender}
                              onChange={(e: ComboBoxChangeEvent) => {
                                let currentValue = e.value;
                                if (currentValue) {
                                  //process properties for suitable type
                                  currentValue = {
                                    ...e.value,
                                    Assessment_Id: e.value.Assessment_Id + "",
                                    Valuation_Number:
                                      e.value.Valuation_Number + "",
                                  };
                                }
                                onChange(nameOf("Assessment"), {
                                  value: currentValue,
                                });
                                setFilterValue(
                                  currentValue?.[
                                    searchByValue?.Key ?? listDataSearch[0].Key
                                  ] ?? ""
                                );
                              }}
                              filter={filterValue}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Address</label>
                            <Field
                              name={"Assessment.Property_Address"}
                              component={CCTextArea}
                              readOnly
                              placeholder="Address"
                              value={assessmentValue?.Ratepayer_Address ?? ""}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">{ratepayerLabel}</label>
                            <Field
                              name={"Assessment.Ratepayer"}
                              component={CCTextArea}
                              readOnly
                              placeholder={ratepayerLabel}
                              value={assessmentValue?.Ratepayer_Name ?? ""}
                            />
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    type="button"
                    name={ButtonNameSubmit.FINISH}
                    disabled={!modified || !valid}
                    onClick={onSubmit}
                    iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  >
                    Finish
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
