import { AddGISReferenceAddDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/add-gis-reference/components/dialogs/add";
import { IAddGISReferenceAddDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/add-gis-reference/components/dialogs/add/model";
import { IAddGISReferenceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/add-gis-reference/model";
import { colGISReference } from "@app/products/property/registers/[id]/config";
import { IGISReferenceGrid } from "@app/products/property/registers/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IAddGISReferenceDialogProps {
  onClose: () => void;
  onSubmit: (value: any) => void;
}

export const AddGISReferenceDialog = observer(
  ({ onClose, onSubmit }: IAddGISReferenceDialogProps) => {
    const [showAddGISReferenceDialog, setShowAddGISReferenceDialog] =
      useState<boolean>(false);

    const nameOf = nameOfFactory<IAddGISReferenceDialog>();

    const nameOfGISReferenceGrid = nameOfFactory<IGISReferenceGrid>();

    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const cleanRegEx = /\s+/g;

    const addGISReferenceGrid = (
      reference: string,
      formRenderProps: FormRenderProps
    ) => {
      const { valueGetter, onChange } = formRenderProps;
      let newGISReferenceGrid: IGISReferenceGrid[];
      if (valueGetter(nameOf("GISReferenceGrid"))) {
        newGISReferenceGrid = valueGetter(nameOf("GISReferenceGrid"));

        newGISReferenceGrid.push({
          GISReference: reference,
        });
      } else {
        newGISReferenceGrid = [
          {
            GISReference: reference,
          },
        ];
      }
      onChange(nameOf("GISReferenceGrid"), { value: newGISReferenceGrid });
    };

    const removeGISReferenceGrid = (
      selectedGISReferenceGrid: IGISReferenceGrid[],
      formRenderProps: FormRenderProps
    ) => {
      const { valueGetter, onChange } = formRenderProps;
      const newGISReference: IGISReferenceGrid[] = valueGetter(
        nameOf("GISReferenceGrid")
      );
      selectedGISReferenceGrid.forEach((reference: IGISReferenceGrid) => {
        let index: number = newGISReference.findIndex(
          (item: IGISReferenceGrid) =>
            item.GISReference === reference.GISReference
        );
        newGISReference.splice(index, 1);
      });
      onChange(nameOf("GISReferenceGrid"), { value: newGISReference });
    };

    const checkExistedGISReference = (
      reference: string,
      formRenderProps: FormRenderProps
    ) => {
      const { valueGetter } = formRenderProps;
      return valueGetter(nameOf("GISReferenceGrid"))?.some(
        (data: IGISReferenceGrid) => data.GISReference === reference
      )
        ? true
        : false;
    };

    const handleMinusOnclick = (
      event: any,
      formRenderProps: FormRenderProps
    ) => {
      event.preventDefault();
      const { valueGetter, onChange } = formRenderProps;
      if (
        valueGetter("_option.GISReferenceGrid.Selected") &&
        valueGetter("_option.GISReferenceGrid.Selected").length > 0
      ) {
        removeGISReferenceGrid(
          valueGetter("_option.GISReferenceGrid.Selected"),
          formRenderProps
        );
        onChange("_option.GISReferenceGrid.Selected", { value: [] });
      }
    };

    const handleAddGISReferences = (
      reference: string,
      formRenderProps: FormRenderProps
    ) => {
      setShowAddGISReferenceDialog(false);
      addGISReferenceGrid(reference, formRenderProps);
    };

    const handleOnSubmit = (
      data: IAddGISReferenceAddDialog,
      formRenderProps: FormRenderProps
    ) => {
      if (
        !checkExistedGISReference(
          data.GISReference!.replace(cleanRegEx, " ").trim(),
          formRenderProps
        )
      ) {
        handleAddGISReferences(data.GISReference!, formRenderProps);
      } else {
        setNotifications([
          {
            id: "0",
            title: "GIS reference already exists.",
            type: "warning",
          },
        ]);
      }
    };

    return (
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, valueGetter, onChange } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                width={"25%"}
                height="auto"
                onClose={onClose}
                titleHeader={"Add GIS Reference"}
                bodyElement={
                  <React.Fragment>
                    {showAddGISReferenceDialog && (
                      <AddGISReferenceAddDialog
                        onClose={() => {
                          setShowAddGISReferenceDialog(false);
                          setNotifications([]);
                        }}
                        onSubmit={(data: IAddGISReferenceAddDialog) => {
                          handleOnSubmit(data, formRenderProps);
                        }}
                        defaultNotifications={notifications}
                      />
                    )}

                    <div className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <label className="cc-label">
                            GIS references
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <CCGrid
                            toolbar={
                              <div className="cc-grid-tools-bar">
                                <Button
                                  type="button"
                                  iconClass="fas fa-plus"
                                  title="Add"
                                  onClick={(event: any) => {
                                    event.preventDefault();
                                    setShowAddGISReferenceDialog(true);
                                  }}
                                />
                                <Button
                                  type="button"
                                  iconClass="fas fa-minus"
                                  title="Remove"
                                  onClick={(event: any) => {
                                    event.preventDefault();
                                    handleMinusOnclick(event, formRenderProps);
                                  }}
                                />
                              </div>
                            }
                            className="cc-gis-reference-grid-data"
                            data={valueGetter(nameOf("GISReferenceGrid")) ?? []}
                            columnFields={colGISReference}
                            primaryField={nameOfGISReferenceGrid(
                              "GISReference"
                            )}
                            selectableMode="multiple"
                            onSelectionChange={(
                              dataItem: IGISReferenceGrid[]
                            ) => {
                              onChange("_option.GISReferenceGrid.Selected", {
                                value: dataItem,
                              });
                            }}
                            selectedRows={valueGetter(
                              "_option.GISReferenceGrid.Selected"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!(valueGetter("GISReferenceGrid")?.length > 0)}
                      themeColor="primary"
                      onClick={onSubmit}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
