import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, ReactElement, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class CCListViewActionBarStore {
  private _title: string = "";
  private _leftComponents?: ReactElement[] = [];
  private _centerComponents?: ReactElement[] = [];
  private _rightComponents?: ReactElement[] = [];
  private _searchComponent?: ReactElement = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get title() {
    return toJS(this._title);
  }
  setTitle = (title: string) => {
    runInAction(() => {
      this._title = title;
    });
  };

  get leftComponents() {
    return toJS(this._leftComponents);
  }
  setLeftComponents = (component?: ReactElement[]) => {
    runInAction(() => {
      this._leftComponents = component;
    });
  };

  get centerComponents() {
    return toJS(this._centerComponents);
  }

  setCenterComponents = (component?: ReactElement[]) => {
    runInAction(() => {
      this._centerComponents = component;
    });
  };

  get rightComponents() {
    return toJS(this._rightComponents);
  }
  setRightComponents = (component?: ReactElement[]) => {
    runInAction(() => {
      this._rightComponents = component;
    });
  };

  get searchComponent() {
    return toJS(this._searchComponent);
  }
  setSearchComponent = (component?: ReactElement) => {
    runInAction(() => {
      this._searchComponent = component;
    });
  };

  get showListViewActionBar() {
    return (
      this._title !== "" ||
      (this._leftComponents && this._leftComponents?.length > 0) ||
      (this._centerComponents && this._centerComponents?.length > 0) ||
      (this._rightComponents && this._rightComponents?.length > 0)
    );
  }

  //Action
  resetDefault = () => {
    runInAction(() => {
      this._title = "";
      this._centerComponents = [];
      this._rightComponents = [];
      this._leftComponents = [];
      this._searchComponent = undefined;
    });
  };
}

const actionBarStoreContext = createContext(new CCListViewActionBarStore());

export const useCCListViewActionBarStore = () => {
  return useContext(actionBarStoreContext);
};
