import { IColumnFields } from "@components/cc-grid/model";

import { IAttributeData } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
const nameOf = nameOfFactory<IAttributeData>();
export const colAttributes: IColumnFields[] = [
  {
    field: nameOf("Attribute"),
    title: "Attribute",
  },
  {
    field: nameOf("Value"),
    title: "Value",
    editable: true,
  },
];
