import { BubbleUpType } from "@app/core/inspections/[id]/model";
import {
  BubbleUpIdentifier,
  RecordSourceIdentifier,
} from "@app/products/waste-water/[id]/model";
import { mapEnum } from "@common/utils/common";

export const getSourceIdentifier = (
  bubbleUps: BubbleUpIdentifier[] | undefined,
  bubbleUpType: BubbleUpType
): RecordSourceIdentifier | undefined => {
  return bubbleUps?.find(
    (bubbleUp: BubbleUpIdentifier) =>
      mapEnum(bubbleUp.BubbleUpType_ENUM, BubbleUpType) === bubbleUpType
  )?.SourceIdentifier;
};
