import { WasteWaterFromAPI } from "@app/products/waste-water/[id]/components/reference-sidebar/contact/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";

export const loadWasteWaterById = async (
  id: string
): Promise<WasteWaterFromAPI | undefined> => {
  try {
    const response: IResponseData = await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/odata/core/internal/contacts/getcontacts(key=${id},%20recordType=1802)`
    );
    if (!response) {
      return undefined;
    }
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
