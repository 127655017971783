import { isBefore } from "date-fns";
import { isNull } from "lodash";

export const handleValidateDisassociateMeter: any = (data: any) => {
  const DateOfRemoval = data.DateOfRemoval;

  let errorMessage = {};
  if (isNull(DateOfRemoval)) {
    errorMessage = {
      ...errorMessage,
      DateOfRemoval: " ",
    };
  }
  if (
    !isNull(DateOfRemoval) &&
    !isBefore(new Date(DateOfRemoval), new Date())
  ) {
    errorMessage = {
      ...errorMessage,
      DateOfRemoval: "Date of Removal must be equal or less than current date ",
    };
  }

  return errorMessage;
};
