import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { AddTopicDialog } from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/components/form-element/components/dialogs/_index";
import {
  colOrgUnit,
  contactFields,
  managerFields,
  orgStructureFields,
  reviewerFields,
} from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { ICRMSKnowledgeBaseSummary } from "@app/products/crms/knowledge-base/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface ICRMSKnowledgeBaseProps {
  formRenderProps: FormRenderProps;
}

export const CRMSKnowledgeBaseFormElement = observer(
  ({ formRenderProps }: ICRMSKnowledgeBaseProps) => {
    const isNew = useIsNew();
    const nameOf = nameOfFactory<ICRMSKnowledgeBaseSummary>();
    const { valid, valueGetter, onChange, onSubmit, modified } =
      formRenderProps;
    const [isClearSelection, setIsClearSelection] = useState<boolean>(false);
    const [showTopicDialog, setShowTopicDialog] = useState<boolean>(false);
    const [showOrgUnitDialog, setShowOrgUnitDialog] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    const {
      Bold,
      Italic,
      Underline,
      Strikethrough,
      Subscript,
      Superscript,
      AlignLeft,
      AlignCenter,
      AlignRight,
      AlignJustify,
      Indent,
      Outdent,
      OrderedList,
      UnorderedList,
      Undo,
      Redo,
      Link,
      Unlink,
      InsertImage,
      ViewHtml,
      InsertTable,
      AddRowBefore,
      AddRowAfter,
      AddColumnBefore,
      AddColumnAfter,
      DeleteRow,
      DeleteColumn,
      DeleteTable,
      MergeCells,
      SplitCell,
    } = EditorTools;

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (modified) {
        onSubmit(event);
        return;
      }
      if (!valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    useEffectOnce(() => {
      if (valueGetter(nameOf("OrgStructure"))) {
        setIsClearSelection(true);
      }
    });

    // TODO handle
    const handleAddTopicPopup = (e: any) => {};
    const RemoveCommandCell = (props: any) => {
      const { dataItem } = props;
      return (
        <td className="k-command-cell">
          <button
            className="k-button k-grid-remove-command"
            onClick={() =>
              // confirm("Confirm deleting: " + dataItem.ProductName) &&
              props.remove(dataItem)
            }
          >
            Remove
          </button>
        </td>
      );
    };
    // TODO handle
    const handleAddOrgUnitPopup = (e: any) => {};
    const CommandCell = (props: any) => (
      <RemoveCommandCell {...props} remove={remove} />
    );
    const remove = (dataItem: any) => {
      // const data = deleteItem(dataItem);
      // this.setState({ data });
    };

    return (
      <FormElement onSubmit={onSubmit}>
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        {showTopicDialog && (
          <AddTopicDialog
            {...formRenderProps}
            onClosePopup={() => setShowTopicDialog(false)}
            onAddTopicPopup={(arg: any) => handleAddTopicPopup(arg)}
            titleHeader={"KB Page - Topic"}
          />
        )}
        {showOrgUnitDialog && (
          <GridSelectionDialog
            height="60%"
            titleHeader="PICK ORG UNIT(S)"
            dataUrl={CORE_INSPECTION_TYPES_ODATA}
            columnFields={colOrgUnit}
            setShowDialog={() => setShowOrgUnitDialog(false)}
            onCloseDialog={() => setShowOrgUnitDialog(false)}
            onSubmit={(arg: any) => handleAddOrgUnitPopup(arg)}
          />
        )}
        <CCGeneralPanel
          component={
            <section className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">
                    Page title
                    <CCTooltip
                      type="validator"
                      content={formRenderProps.errors.pagetitle}
                    />
                  </label>
                  <Field
                    name={nameOf("PageTitle")}
                    placeholder="Page title"
                    component={CCInput}
                    validator={requiredValidator}
                  />
                </div>
              </div>

              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Expiry date
                    <CCTooltip
                      type="validator"
                      content={formRenderProps.errors.expirydate}
                    />
                  </label>
                  <Field
                    name={nameOf("DateExpiryDate")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    validator={requiredValidator}
                    component={CCDatePicker}
                  />
                </div>
              </div>

              {/* ROW 3 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Org structure</label>
                  <Field
                    name={nameOf("OrgStructure")}
                    {...orgStructureFields(formRenderProps, isClearSelection)}
                    component={InputPicker}
                  />
                </div>
              </div>
            </section>
          }
        />
        <CustomPanelBar
          expandMode="multiple"
          listPanelBar={[
            {
              title: "Review",
              expanded: true,
              component: (
                <section className="cc-field-group">
                  {/* ROW 4 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Reviewer</label>
                      <Field
                        name={nameOf("Reviewer")}
                        {...reviewerFields(formRenderProps)}
                        component={InputPicker}
                      />
                    </div>
                    <CCValueField
                      label={"Last review date"}
                      value={new Date()}
                      format={DATE_FORMAT.DATE}
                    />
                    <div className="cc-field">
                      <label className="cc-label">Next review date</label>
                      <Field
                        name={"NextReviewDate"}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                      />
                    </div>
                  </div>
                </section>
              ),
            },
            {
              title: "Manager",
              expanded: true,
              component: (
                <section className="cc-field-group">
                  {/* ROW 5 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Manager
                        {valueGetter(nameOf("Reviewer")) && (
                          <Button
                            fillMode="flat"
                            themeColor="primary"
                            className="cc-icon-label-button"
                            onClick={(event) => {
                              event.preventDefault();
                              onChange(nameOf("Manager"), {
                                value: valueGetter(nameOf("Reviewer")),
                              });
                            }}
                            title={"Set Manager same as Reviewer."}
                            iconClass="fa fa-chevron-circle-right"
                          />
                        )}
                      </label>
                      <Field
                        name={nameOf("Manager")}
                        {...managerFields(formRenderProps)}
                        component={InputPicker}
                      />
                    </div>
                  </div>
                </section>
              ),
            },
            {
              title: "Main Contact",
              expanded: true,
              component: (
                <div className="cc-field-group">
                  {/* ROW 6 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <div className="cc-tool-tip-wrapper">
                        <label className="cc-label">
                          Contact
                          {valueGetter(nameOf("Reviewer")) && (
                            <Button
                              fillMode="flat"
                              themeColor="primary"
                              className="cc-icon-label-button"
                              onClick={(event) => {
                                event.preventDefault();
                                onChange(nameOf("Contact"), {
                                  value: valueGetter(nameOf("Reviewer")),
                                });
                              }}
                              title={"Set Main Contact same as Reviewer."}
                              iconClass="fa fa-chevron-circle-right"
                            />
                          )}
                          {valueGetter(nameOf("Manager")) && (
                            <Button
                              fillMode="flat"
                              className="cc-icon-label-button "
                              onClick={(event) => {
                                event.preventDefault();
                                onChange(nameOf("Contact"), {
                                  value: valueGetter(nameOf("Manager")),
                                });
                              }}
                              title={"Set Main Contact same as Manager."}
                              iconClass="fa fa-chevron-circle-right cc-label-field-success"
                            />
                          )}
                        </label>
                      </div>
                      <Field
                        name={nameOf("Contact")}
                        {...contactFields(formRenderProps)}
                        component={InputPicker}
                      />
                    </div>
                    <CCValueField
                      label={"Position"}
                      value={valueGetter("Position")}
                    />
                    <CCValueField
                      label={"Telephone"}
                      value={valueGetter("Telephone")}
                    />
                  </div>

                  {/* ROW 7 */}
                  <div className="cc-form-cols-3">
                    <CCValueField
                      label={"Mobile"}
                      value={valueGetter("Mobile")}
                    />
                    <CCValueField label={"Fax"} value={valueGetter("Fax")} />
                  </div>
                  <br />
                </div>
              ),
            },
            {
              title: "Options",
              expanded: true,
              component: (
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Released</label>
                      <Field
                        name={nameOf("IsReleased")}
                        checked={valueGetter(nameOf("IsReleased"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">External use</label>
                      <Field
                        name={nameOf("IsExternalUse")}
                        checked={valueGetter(nameOf("IsExternalUse"))}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Create event</label>
                      <Field
                        name={nameOf("IsCreateEvent")}
                        checked={valueGetter(nameOf("IsCreateEvent"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Phone enquiry</label>
                      <Field
                        name={nameOf("IsPhoneEnquiry")}
                        checked={valueGetter(nameOf("IsPhoneEnquiry"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Counter enquiry</label>
                      <Field
                        name={nameOf("IsCounterEnquiry")}
                        checked={valueGetter(nameOf("IsCounterEnquiry"))}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Enquiry only</label>
                      <Field
                        name={nameOf("IsEnquiryOnly")}
                        checked={valueGetter(nameOf("IsEnquiryOnly"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Transfer</label>
                      <Field
                        name={nameOf("IsTransfer")}
                        checked={valueGetter(nameOf("IsTransfer"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Live chat</label>
                      <Field
                        name={nameOf("IsLiveChat")}
                        checked={valueGetter(nameOf("IsLiveChat"))}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Twitter</label>
                      <Field
                        name={nameOf("IsTwitter")}
                        checked={valueGetter(nameOf("IsTwitter"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Email</label>
                      <Field
                        name={nameOf("IsEmail")}
                        checked={valueGetter(nameOf("IsEmail"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Facebook</label>
                      <Field
                        name={nameOf("IsFacebook")}
                        checked={valueGetter(nameOf("IsFacebook"))}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Mobile app</label>
                      <Field
                        name={nameOf("IsMobileApp")}
                        checked={valueGetter(nameOf("IsMobileApp"))}
                        component={CCSwitch}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Website</label>
                      <Field
                        name={nameOf("IsWebsite")}
                        checked={valueGetter(nameOf("IsWebsite"))}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                </section>
              ),
            },
            {
              title: "Advanced KB Search",
              expanded: true,
              component: (
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Reset hit counter</label>
                      <Field
                        name={nameOf("DateResetHitCounter")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                      />
                    </div>
                  </div>
                </section>
              ),
            },
            {
              title: "Topics",
              expanded: true,
              component: (
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    {isNew ? (
                      <div className="cc-col-span-2">
                        <CCValueField value={valueGetter("No Topics Added")} />
                      </div>
                    ) : (
                      <div className="cc-col-span-2">
                        <Grid
                          className="grid_action kb-grid-width"
                          sortable={true}
                          resizable
                        >
                          <Column
                            field="Name"
                            title="Name"
                            className="text-overflow-ellipsis"
                          />
                          <Column cell={CommandCell} />
                        </Grid>
                      </div>
                    )}
                    <div className="cc-col-span-1">
                      <Button
                        className="cc-full-width"
                        onClick={(event) => {
                          event.preventDefault();
                          setShowTopicDialog(true);
                        }}
                      >
                        Add Topic
                      </Button>
                    </div>
                  </div>
                </section>
              ),
            },
            {
              title: "Security",
              expanded: true,
              component: (
                <>
                  <section className="cc-field-group">
                    <div className="cc-form-cols-3">
                      <div className="cc-col-span-2">
                        <div className="cc-field">
                          <label className="cc-label">Readers</label>
                          <div className="cc-label-field">{"Everyone"}</div>
                        </div>
                      </div>
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <Button
                            className="cc-full-width"
                            onClick={(event) => {
                              event.preventDefault();
                              setShowOrgUnitDialog(true);
                            }}
                          >
                            Add Org Structure
                          </Button>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="field">
                            <Button
                              className="cc-full-width"
                              onClick={(event) => {
                                event.preventDefault();
                                setShowOrgUnitDialog(true);
                              }}
                            >
                              Add Groups
                            </Button>
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="field">
                            <Button
                              className="cc-full-width"
                              onClick={(event) => {
                                event.preventDefault();
                                setShowOrgUnitDialog(true);
                              }}
                            >
                              Add Users
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <hr className="cc-divider" />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-3">
                      <div className="cc-col-span-2">
                        <div className="cc-field">
                          <label className="cc-label">Writers</label>
                          <div className="cc-label-field">{"Everyone"}</div>
                        </div>
                      </div>
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <Button
                            className="cc-full-width"
                            onClick={(event) => {
                              event.preventDefault();
                              setShowOrgUnitDialog(true);
                            }}
                          >
                            Add Org Structure
                          </Button>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="field">
                            <Button
                              className="cc-full-width"
                              onClick={(event) => {
                                event.preventDefault();
                                setShowOrgUnitDialog(true);
                              }}
                            >
                              Add Groups
                            </Button>
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="field">
                            <Button
                              className="cc-full-width"
                              onClick={(event) => {
                                event.preventDefault();
                                setShowOrgUnitDialog(true);
                              }}
                            >
                              Add Users
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ),
            },
            {
              title: "Precis",
              expanded: true,
              component: (
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <Editor
                      tools={[
                        [Bold, Italic, Underline, Strikethrough],
                        [Subscript, Superscript],
                        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                        [Indent, Outdent],
                        [OrderedList, UnorderedList],
                        [Undo, Redo],
                        [Link, Unlink, InsertImage, ViewHtml],
                        [InsertTable],
                        [
                          AddRowBefore,
                          AddRowAfter,
                          AddColumnBefore,
                          AddColumnAfter,
                        ],
                        [DeleteRow, DeleteColumn, DeleteTable],
                        [MergeCells, SplitCell],
                      ]}
                      contentStyle={{ height: 300 }}
                    />
                  </div>
                </section>
              ),
            },
            {
              title: "Precis",
              component: (
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <Editor
                      tools={[
                        [Bold, Italic, Underline, Strikethrough],
                        [Subscript, Superscript],
                        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                        [Indent, Outdent],
                        [OrderedList, UnorderedList],
                        [Undo, Redo],
                        [Link, Unlink, InsertImage, ViewHtml],
                        [InsertTable],
                        [
                          AddRowBefore,
                          AddRowAfter,
                          AddColumnBefore,
                          AddColumnAfter,
                        ],
                        [DeleteRow, DeleteColumn, DeleteTable],
                        [MergeCells, SplitCell],
                      ]}
                      contentStyle={{ height: 300 }}
                    />
                  </div>
                </section>
              ),
            },
          ]}
        />
      </FormElement>
    );
  }
);
