import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";

export interface AnimalsTypeLOVs {
  FeeType: KeyValuePacket[];
  FeeCalculationMethod: KeyValuePacket[];
  FeeCalculationType: KeyValuePacket[];
  PenalityFeeType: KeyValuePacket[];
  RenewalPeriod: KeyValuePacket[];
}

export enum SVC_FeeCalculator_RegistrationFee_RuleType {
  Default = 0,
  Animal_Standard = 1,
  Animal_Sterilized = 2,
  Animal_Over10Years = 3,
  Animal_Under1Year = 4,
  Animal_ClubMember = 5,
  Animal_Microchipped = 6,
  Animal_Obedience = 7,
  Animal_GuideDog = 8,
  Animal_Customs = 9,
  Animal_Working = 10,
  Animal_Organisation = 11,
  Animal_PureBred = 12,
  Animal_Greyhound = 13,
  Animal_DogsHomeAdoption = 14,
  Animal_Pensioner = 15,
  Animal_Dangerous = 16,
  Animal_Menacing = 17,
  Animal_HuntingDog = 18,
  Animal_Transferred = 19,
  Animal_GuardDog = 20,
  Animal_Standard_Pensioner = 21,
  Animal_Sterilized_Pensioner = 22,
  Animal_CertifiedAssistance = 23,
  Animal_RestrictedBreed = 24,
}

export interface RegistrationFeeRuleList {
  RuleType_Enum: SVC_FeeCalculator_RegistrationFee_RuleType;
  SortOrder: number;
  RuleType_Name: string;
  Description: string;
  IsPriority: boolean;
}

export interface RegistrationFeeAdditionalChargeList {
  RuleType_Enum: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType;
  RuleType_Name: string;
  Description: string;
}

export interface NonRegistrationFeeRuleList {
  RuleType_Enum: SVC_FeeCalculator_NonRegistrationFee_RuleType;
  RuleType_Name: string;
  Description: string;
}

export enum SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType {
  Default,
  Animal_Government,
  Animal_Obedience,
  Animal_Pensioner,
  Animal_HealthCareCard,
  Working_Dog,
}

export enum SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType {
  Default,
  Add,
  Deduct,
  PercentAddition,
  PercentDeduction,
}

export enum SVC_FeeCalculator_NonRegistrationFee_RuleType {
  Default,
  Animal_NewTag,
  Animal_ReplacementTag,
  Animal_TransferFee,
  Animal_LifetimeTag,
  Animal_ComplaintFee,
  Animal_PenaltyFee,
}

export enum RegistrationFeeRulesType {
  Default = 0,
  RegistrationFeeRules = 1,
  RegistrationFeeAdditionalChargeRules = 2,
  NonRegistrationFeeRules = 3,
}

export interface RegistrationFeeRulesLOVs {
  RuleType: KeyValuePacket[];
  AdjustmentType1: KeyValuePacket[];
  AdjustmentType2: KeyValuePacket[];
  RVPStartMonth: KeyValuePacket[];
  RVPEndMonth: KeyValuePacket[];
}

export interface Svc_RegistrationFeeRulesType {
  RegistrationFeeRule: any;
  LOVs: RegistrationFeeRulesLOVs;
}
