export const ACCOUNT_DETAILS_STEP = "AccountDetailsStep";
export const PAYMENT_DETAILS_STEP = "PaymentDetailsStep";
export const ACCOUNT_GENERAL_STEP = "AccountGeneralsStep";
export const DD_CREATE_ACCOUNT = "DDCreateAccount";
export const ASSESSMENT_LOOKUP_API = "api/property/int/assessment/lookup";
export const FINANCE_CUSTOMER_LOOKUP_API =
  "api/receipting/internal/directdebitaccount/financecustomerlookup";
export const FINANCE_INVOICE_LOOKUP_API = (customerNo: string) => {
  return `api/receipting/internal/directdebitaccount/${customerNo}/financeinvoicelookup`;
};
export const REGISTER_LOOKUP_API = (registerId: string) => {
  return `api/property/int/register/${registerId}/accounts`;
};

export const ddCreateAccountFinanceInvoiceNumberAlias =
  "ddCreateAccountFinanceInvoiceNumber";
export const ddCreateAccountRegisterAccountIDAlias =
  "ddCreateAccountRegisterAccountID";
