import { APIResponse } from "@common/apis/model";
import { DTO_ChargeRun_PIC_TabDetails } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";

export const getPICChargeRunDetailTabId = async (
  id: number
): Promise<APIResponse<DTO_ChargeRun_PIC_TabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChargeRun_PIC_TabDetails>(
      `api/property/internal/picchargerun/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
