import { DTO_Cancellation_LOVs } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getWorkflowCancellationReasons = async (): Promise<
  APIResponse<DTO_Cancellation_LOVs>
> => {
  try {
    const response = await CoreAPIService.getClient().get(
      "/api/property/int/workflow/cancellationlovs"
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
