import { colContactAssociationTitle } from "@app/products/property/components/associations/components/title/contact-title/config";
import { DTO_AssociatedItem_Title } from "@app/products/property/components/associations/components/title/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IContactTitleProps {
  data: DTO_AssociatedItem_Title[];
}

const nameOf = nameOfFactory<DTO_AssociatedItem_Title>();
export const ContactAssociationTitle = ({ data }: IContactTitleProps) => {
  return (
    <CCGrid
      data={data}
      columnFields={colContactAssociationTitle}
      primaryField={nameOf("Id")}
    />
  );
};
