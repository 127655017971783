import { getECertificateRequestById } from "@app/products/property/certificates/e-certificate-requests/[id]/api";
import { IECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ECertificateRequestStore {
  private _eCertificateRequest?: IECertificateRequest = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get eCertificateRequest() {
    return this._eCertificateRequest;
  }
  setECertificateRequest = (eCertificateRequest?: IECertificateRequest) => {
    runInAction(() => {
      this._eCertificateRequest = eCertificateRequest;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get eCertificateRequestId() {
    return this._eCertificateRequest?.ID;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._eCertificateRequest = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadECertificateRequest = async (eCertificateRequestId: number) => {
    this.setIsLoading(true);
    const response = await getECertificateRequestById(eCertificateRequestId);
    let errorResponse = undefined;
    let newECertificateRequest = undefined;
    if (isSuccessResponse(response)) {
      newECertificateRequest = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setECertificateRequest(newECertificateRequest);
    this.setIsLoading(false);
  };
}

const eCertificateRequestStoreContext = createContext(
  new ECertificateRequestStore()
);
export const useECertificateRequestStore = () => {
  return useContext(eCertificateRequestStoreContext);
};
