import { KeyValuePair } from "@app/core/documents/model";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { BELL_CHARACTER } from "@common/constants/characters";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./index.scss";

interface ICustomTimePeriodFieldsProps {
  formRenderProps: FormRenderProps;
}

const CustomTimePeriodFields = ({
  formRenderProps,
}: ICustomTimePeriodFieldsProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "IntegratedReports_CustomNumberOfFields",
    "IntegratedReports_CustomisedFields",
  ]);

  //Get values
  const customisedFields: KeyValuePair<string, string>[] =
    valueGetter(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`
    ) ?? [];

  const numberOfFields = valueGetter(
    data.IntegratedReports_CustomNumberOfFields?.FieldName as string
  );

  //Local states
  const [rowCount, setRowCount] = useState<number>(numberOfFields);
  const [initCustomFieldsValues] =
    useState<KeyValuePair<string, string>[]>(customisedFields);

  //Handle events
  const processData = (
    valueList: KeyValuePair<string, string>[] | undefined
  ) => {
    let currentValue = "";
    if (valueList) {
      valueList.forEach(
        (item: KeyValuePair<string, string>) =>
          (currentValue +=
            item?.Key + BELL_CHARACTER + item?.Value + BELL_CHARACTER)
      );
    }
    return currentValue;
  };

  const handleChangeDescription = (index: number, event: InputChangeEvent) => {
    const clonedCustomisedFields = cloneDeep(customisedFields);
    if (!clonedCustomisedFields[index]) {
      clonedCustomisedFields[index] = {
        Key: "",
        Value: "",
      };
    }
    clonedCustomisedFields[index].Key = event.value;

    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`,
      {
        value: clonedCustomisedFields,
      }
    );
    onChange(`${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`, {
      value: processData(clonedCustomisedFields),
    });
  };

  const handleChangeNoOfDays = (
    index: number,
    event: NumericTextBoxChangeEvent
  ) => {
    const clonedCustomisedFields = cloneDeep(customisedFields);
    if (!clonedCustomisedFields[index]) {
      clonedCustomisedFields[index] = {
        Key: "",
        Value: "",
      };
    }

    clonedCustomisedFields[index].Value = event.value?.toString() || "";

    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`,
      {
        value: clonedCustomisedFields,
      }
    );
    onChange(`${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`, {
      value: processData(clonedCustomisedFields),
    });
  };

  const renderCustomisedFields = () => {
    const tempArray = Array.from({ length: rowCount }, (_, index) => index);
    return tempArray.map((index: number) => {
      return (
        <div className="cc-flex-container">
          <div className="cc-index-col">
            <CCLabel title={`${index + 1}`} />
          </div>
          <div className="cc-content-col cc-reports-setting-cols-3 ">
            <div className="cc-field cc-description-field">
              <Field
                name={`Description${index + 1}`}
                component={CCInput}
                disabled={!isEditing}
                defaultValue={initCustomFieldsValues[index]?.Key || ""}
                onChange={(event: InputChangeEvent) => {
                  handleChangeDescription(index, event);
                }}
              />
            </div>
            <div className="cc-field cc-no-of-days-field">
              <Field
                name={`NoOfDays${index + 1}`}
                component={CCNumericTextBox}
                min={0}
                format={NUMBER_FORMAT.NUMBER2}
                disabled={!isEditing}
                defaultValue={initCustomFieldsValues[index]?.Value || ""}
                onChange={(event: NumericTextBoxChangeEvent) => {
                  onChange(`NoOfDays${index + 1}`, {
                    value: event?.value && event.value >= 0 ? event?.value : 0,
                  });
                  handleChangeNoOfDays(index, event);
                }}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    data && (
      <section className="cc-field-group">
        <div className="cc-reports-setting-sub-panel-bar">
          <CCLabel
            title={data.IntegratedReports_CustomNumberOfFields?.Title ?? ""}
          />
        </div>
        <div className="cc-flex-container">
          <div className="cc-index-col-empty"></div>
          {data.IntegratedReports_CustomNumberOfFields && (
            <div className="cc-content-col cc-form-cols-3">
              <Field
                name={data.IntegratedReports_CustomNumberOfFields.FieldName}
                component={CCNumericTextBox}
                min={0}
                max={100}
                format={NUMBER_FORMAT.NUMBER2}
                disabled={!isEditing}
                onChange={(event: NumericTextBoxChangeEvent) => {
                  onChange(
                    `${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomNumberOfFields}`,
                    {
                      value: event.value,
                    }
                  );
                  const clonedCustomisedFields = cloneDeep(customisedFields);
                  clonedCustomisedFields?.splice(event.value ?? 0);
                  onChange(
                    `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`,
                    {
                      value: clonedCustomisedFields,
                    }
                  );
                  onChange(
                    `${CORPORATE_SETTINGS_FIELD.IntegratedReports_CustomisedFields}`,
                    {
                      value: processData(clonedCustomisedFields),
                    }
                  );
                }}
              />
              <div className="cc-field">
                <Button
                  className="cc-apply-button"
                  type="button"
                  disabled={!isEditing}
                  onClick={() => {
                    setRowCount(numberOfFields);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
        </div>
        {rowCount > 0 && (
          <>
            <div className="cc-flex-container">
              <div className="cc-index-col"></div>
              <div className="cc-content-col cc-reports-setting-cols-3">
                <div className="cc-field cc-description-field">
                  <CCLabel title="Description" />
                </div>
                <div className="cc-field cc-no-of-days-field">
                  <CCLabel title="Number of days" />
                </div>
              </div>
            </div>
            {renderCustomisedFields()}
          </>
        )}
      </section>
    )
  );
};

export default observer(CustomTimePeriodFields);
