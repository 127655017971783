import {
  apiCoreGetInvoiceItemColumnConfig,
  apiCorePickFeeType,
} from "@app/core/components/common/utils";
import { Fee } from "@app/core/invoice/[id]/model";
import {
  EViewConfiguration,
  Svc_ViewConfiguration,
} from "@app/core/invoice/invoice-item-accordion/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IIdentityPacket } from "@common/models/identityPacket";

export const getInvoiceItemColumnConfig = async (
  recordType: RECORDTYPE,
  viewEnum: EViewConfiguration
): Promise<APIResponse<IIdentityPacket<Svc_ViewConfiguration> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_ViewConfiguration>
    >(apiCoreGetInvoiceItemColumnConfig(recordType, viewEnum));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postPickFeeType = async (
  bubbleUp: BubbleUpIdentifier[],
  feeTypeId: number
): Promise<APIResponse<IIdentityPacket<Fee> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<Fee>>(
      apiCorePickFeeType(feeTypeId),
      bubbleUp
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
