import { useDebounce } from "@common/hooks/useDebounce";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import {
  FilterDescriptor,
  isCompositeFilterDescriptor,
  State,
} from "@progress/kendo-data-query";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import "./_index.scss";

interface IGridSearch extends Omit<ICCGridProps, "primaryField"> {
  grid: {
    primaryField?: string;
    itemPerPage?: number;
    isAutoHiddenPager?: boolean;
  };
  search: {
    debouncedSearch?: boolean;
    hasSearchField?: boolean;
    filterCol?: string;
    filterCols?: string[];
    sortCol?: string;
  };
  setSelectedItemCount?: (data: any) => void;
  handleSelectionChange: (data: any) => void;
}

export const GridSearch = ({
  grid,
  search = {
    debouncedSearch: false,
    hasSearchField: false,
    filterCol: undefined,
    filterCols: [],
    sortCol: undefined,
  },
  columnFields,
  dataUrl,
  data,
  selectableMode = "single",
  state = {},
  selectedRows,
  setSelectedItemCount,
  handleSelectionChange,
  onDataStateChange,
  ...props
}: IGridSearch) => {
  const [searchKey, setSearchKey] = useState("");
  const debouncedSearchKey = useDebounce(searchKey, 500);
  const searchValue =
    search.debouncedSearch === true ? debouncedSearchKey : searchKey;
  const isSearchValueNotBlank = searchValue.trim() !== "";

  const isDynamicSearch = (cols?: string[]): cols is string[] =>
    Array.isArray(cols) && cols.length > 1;
  const [searchCol, setSearchCol] = useState(() => {
    if (isDynamicSearch(search.filterCols)) {
      return (
        search.filterCols.find((item: any) => item === search.filterCol) ??
        search.filterCols[0]
      );
    }
    return search.filterCol;
  });
  const currentFilters = state?.filter?.filters ?? [];
  const combinedState = (function () {
    if (!search.sortCol) {
      return state;
    }
    const filtersFromGrid = currentFilters.filter(isCompositeFilterDescriptor);
    const newGridState: State = {
      ...state,
      filter: {
        logic: "and",
        filters: filtersFromGrid,
      },
    };
    if (isSearchValueNotBlank) {
      const searchFilter: FilterDescriptor = {
        field: searchCol,
        operator: "contains",
        value: searchValue,
      };
      newGridState.filter?.filters.unshift(searchFilter);
    }
    return newGridState;
  })();

  return (
    <div className="cc-grid-search">
      {search.hasSearchField ? (
        <div className="cc-grid-search-input">
          {isDynamicSearch(search.filterCols) ? (
            <div className="cc-grid-search-input-suboff">
              <CCLabel title="By column" />
              <CCDropDownList
                defaultValue={searchCol}
                value={searchCol}
                data={search.filterCols}
                onChange={(e) => setSearchCol(e.value)}
              />
            </div>
          ) : null}
          <div className="cc-grid-search-input-textbox">
            <CCLabel title="Search" />
            <Field
              name={"searchKey"}
              component={CCInput}
              placeholder="Search"
              onChange={(e: InputChangeEvent) => {
                setSearchKey(e.value);
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="cc-grid-search-list">
        <CCGrid
          columnFields={columnFields}
          selectableMode={selectableMode}
          data={data}
          dataUrl={dataUrl}
          primaryField={grid.primaryField || "ID"}
          onSelectionChange={handleSelectionChange}
          onTotalSelectedRowChange={setSelectedItemCount}
          state={combinedState}
          isAutoHiddenPager={grid.isAutoHiddenPager ?? false}
          selectedRows={selectedRows}
          itemPerPage={grid.itemPerPage ?? 10}
          onDataStateChange={onDataStateChange}
          {...props}
        />
      </div>
    </div>
  );
};
