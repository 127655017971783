import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { putDeleteSupplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/delete-suplementary/api";
import { DTO_DeleteSupplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/delete-suplementary/model";
import { PROPERTY_SUPPLEMENTARY_RATES_LIST } from "@app/products/property/supplementary-rates/list/all/constant";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface IDeleteSupplementaryButtonProps {
  isDisabled?: boolean;
}

export const DeleteSupplementaryButton = ({
  isDisabled,
}: IDeleteSupplementaryButtonProps) => {
  const history = useHistory();
  const { id: supplementaryId } = useParams<{ id: string }>();
  const { pushNotification } = useCCAppNotificationStore();
  const [showConfirmDeletionDialog, setShowConfirmDeletionDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteSupplementary = async (data: IDeleteConfirmation) => {
    if (supplementaryId) {
      setIsLoading(true);
      const payload = {
        ReasonForDeletion: data?.Reason,
      } as DTO_DeleteSupplementary;
      const response = await putDeleteSupplementary(supplementaryId, payload);
      setIsLoading(false);
      setShowConfirmDeletionDialog(false);

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        const notificationContent = {
          title:
            response.data?.SuccessMessage ??
            "Supplementary deleted successfully.",
          type: "success",
        };

        history.push(PROPERTY_SUPPLEMENTARY_RATES_LIST, {
          notification: notificationContent,
        });
      } else {
        pushNotification({
          title:
            response.data?.ErrorMessage ?? "Could not delete Supplementary.",
          type: "error",
          autoClose: false,
        });
      }
    }
  };

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Supplementary_Delete}
      >
        <CCNavButton
          disabled={isDisabled}
          title="Delete"
          onClick={() => {
            setShowConfirmDeletionDialog(true);
          }}
        />
      </PrivilegeWrapper>
      {showConfirmDeletionDialog && (
        <ConfirmDialog
          title="Confirm Deletion"
          message="Are you sure you want to delete this supplementary?"
          subMessage="If yes, enter the reason below:"
          isLoadingYes={isLoading}
          onClosePopup={() => setShowConfirmDeletionDialog(false)}
          onAsyncConfirm={handleDeleteSupplementary}
          isReasonRequired
          maxLength={250}
        />
      )}
    </>
  );
};
