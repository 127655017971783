import { isSuccessIdentityPacket } from "@common/apis/util";
import { ISecurityActionGridProps } from "@common/pages/settings/security/security-templates/model";
import { postSecurityActionsAllow } from "@common/pages/settings/security/security-templates/_id/components/buttons/allow-actions/api";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { Button } from "@progress/kendo-react-buttons";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

export const SecurityActionAllowButton = observer(
  ({ isGridToolBar = false }: ISecurityActionGridProps) => {
    const {
      securityTemplate,
      reloadSecurityTemplate,
      allowAndDenyListing,
      setAllowAndDenyListing,
      loadSecurityAction,
      hasItemDeny,
    } = useSecurityTemplateStore();
    const { clearErrorNotification, pushNotification } =
      useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
      setIsLoading(true);
      if (!isEmpty(allowAndDenyListing) && securityTemplate) {
        const response = await postSecurityActionsAllow(
          securityTemplate.SecurityTemplate_ID,
          allowAndDenyListing
        );
        setIsLoading(false);
        if (isSuccessIdentityPacket(response)) {
          loadSecurityAction(
            securityTemplate.SecurityTemplate_ID,
            securityTemplate.ProductType_ENUM
          );
          reloadSecurityTemplate().then(() => {
            clearErrorNotification();
            pushNotification({
              type: "success",
              title: `Allow security ${pluralize(
                "action",
                allowAndDenyListing.length
              )} successfully`,
            });
            setAllowAndDenyListing([]);
          });
        } else {
          clearErrorNotification();
          pushNotification({
            autoClose: false,
            type: "error",
            description: response.data?.Errors ?? response.statusText,
          });
        }
      }
    };

    return !isGridToolBar ? (
      <CCNavButton
        title={"Allow"}
        isLoading={isLoading}
        onClick={handleSubmit}
        disabled={!hasItemDeny || !allowAndDenyListing.length}
      />
    ) : (
      <Button
        type="button"
        iconClass={
          isLoading ? "fas fa-spinner fa-spin" : "fas fa-check text-success"
        }
        title="Allow"
        disabled={!hasItemDeny || !allowAndDenyListing.length}
        onClick={handleSubmit}
      />
    );
  }
);
