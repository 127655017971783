import { MapSpatialLinkTab } from "@app/products/property/components/reference-sidebar/map-with-spatial-link/_index";
import { generateGISViewUrl } from "@app/products/property/components/reference-sidebar/map-with-spatial-link/util";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { nameOfFactory } from "@common/utils/common";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { IMapTabProps } from "@components/cc-reference-sidebar/components/Map/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<VO_PIC>();
interface IPICMapTabProps extends IMapTabProps {}

export const PICMapTab = observer((props: IPICMapTabProps) => {
  const { lastSelectedRow, lastSelectedId } = useCCProductListViewStore();
  const { pic, picId } = usePicStore();

  const picGISViewUrl: string | undefined = useMemo(() => {
    if (lastSelectedRow && !isNil(lastSelectedId))
      return generateGISViewUrl(
        lastSelectedId,
        lastSelectedRow?.["GIS_View_Url"]
      );
    if (picId && pic?.GISViewUrl) {
      return generateGISViewUrl(picId, pic?.GISViewUrl);
    }
    return undefined;
  }, [pic, lastSelectedRow, picId, lastSelectedId]);

  return (
    <MapSpatialLinkTab
      {...props}
      spatialLink={picGISViewUrl}
      addressField={nameOf("PropertyAddress")}
    />
  );
});
