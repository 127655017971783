import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

const PropertyReturnQueryExtensionForSearching = () => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_ProcessExternal_NAR_Ids_WHERE_Clause",
    "CorporateSettings_ProcessExternal_Names_WHERE_Clause",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <InputGlobalSettings
          data={data.CorporateSettings_ProcessExternal_NAR_Ids_WHERE_Clause}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_ProcessExternal_Names_WHERE_Clause}
          isEditing={isEditing}
        />
      </div>
    </section>
  );
};

export default observer(PropertyReturnQueryExtensionForSearching);
