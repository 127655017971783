import { colPPRPlanToComply } from "@app/products/town-planning/ppr/[id]/components/child-screens/plans-to-comply/config";
import { IPPRPlansToComply } from "@app/products/town-planning/ppr/[id]/components/child-screens/plans-to-comply/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<IPPRPlansToComply>();
export const PPRPlanToComply = observer(() => {
  const { pprId } = usePPRStore();

  return (
    <CCGrid
      className="cc-ppr-plan-to-comply"
      dataUrl={`/odata/tp/ppr/internal/pprplanstocomply/Summary(id=${pprId})?$count=true&`}
      primaryField={nameOf("ID")}
      state={{
        sort: [{ field: nameOf("CreatedDate"), dir: "desc" }],
      }}
      columnFields={colPPRPlanToComply}
    />
  );
});
