import {
  IRouteStateForm,
  routeStateLocalLawsConfig,
} from "@app/products/local-laws/system-admin/settings/_id/main/routeState";
import { NoData } from "@components/no-data/NoData";
import { FormRenderProps } from "@progress/kendo-react-form";
import { filter } from "lodash";
import React, { memo } from "react";

export interface ILocalLawsComponentSettingFormProps {
  formRenderProps: FormRenderProps;
  dataSetting: any;
}

export const LocalLawsComponentSettingForm = memo(
  ({ formRenderProps, dataSetting }: ILocalLawsComponentSettingFormProps) => {
    const handleSwitchRouteLoadComponent = (
      dataSetting: any,
      routeStateLocalLawsConfig: IRouteStateForm[],
      formRenderProps: FormRenderProps
    ) => {
      let componentActive = <NoData vertical />;
      const settingActive = filter(routeStateLocalLawsConfig, {
        name: dataSetting?.Name,
      });

      if (settingActive.length) {
        const ComponentActive = settingActive[0]?.component;
        componentActive = (
          <ComponentActive
            formRenderProps={formRenderProps}
            dataSetting={dataSetting}
          />
        );
      }
      return componentActive;
    };

    return handleSwitchRouteLoadComponent(
      dataSetting,
      routeStateLocalLawsConfig,
      formRenderProps
    );
  }
);
