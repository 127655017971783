import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Assessment } from "@app/products/property/assessments/list/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment>();
export const colList: IColumnFields[] = [
  {
    field: nameOf("Assessment_Reference"),
    title: "Number",

    locked: true,
    linkTo: (dataItem: any) => {
      return `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`;
    },
  },
  {
    field: nameOf("Assessment_Number_Formatted"),
    title: "Number",

    locked: true,
    linkTo: (dataItem: any) => {
      return `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`;
    },
  },
  {
    field: nameOf("Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Property_Locality"),
    title: "Property Locality",
  },
  {
    field: nameOf("Property_Location_Descriptor"),
    title: "Property Location",
  },
  { field: nameOf("Legal_Description"), title: "Legal Description" },
  { field: nameOf("Improvements"), title: "Improvements" },
  {
    field: nameOf("Primary_Land_Use_Name"),
    title: "Primary Land Use",
  },
  { field: nameOf("Assessment_Type_Name"), title: "Type" },
  { field: nameOf("Assessment_Status_Name"), title: "Status" },
  { field: nameOf("Owners_Name"), title: "Owners Name" },
  { field: nameOf("Owners_State"), title: "Owners State" },
  { field: nameOf("Owners_Address"), title: "Owners Address" },
  { field: nameOf("Owners_Locality"), title: "Owners Locality" },
  {
    field: nameOf("Owners_Name_Address"),
    title: "Owners Name Address",
  },
  { field: nameOf("Owners_Postcode"), title: "Owners Postcode" },
  {
    field: nameOf("Assessment_Group"),
    title: "Region",
  },
  {
    field: nameOf("Differential_Category"),
    title: "RLP Board",
  },
  {
    field: nameOf("Owners_Notice_Group"),
    title: "Owner Notice Group",
  },
  {
    field: nameOf("Ratepayer_Notice_Group"),
    title: "Ratepayer Notice Group",
  },
  {
    field: nameOf("LGA_Name"),
    title: "LGA",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
