import {
  AnimalTypeSubmitActions,
  Registration_Status,
} from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AnimalSameDetailsButton = observer(() => {
  const { animalInfo, onSubmit } = useAnimalStore();

  const isVisible = useMemo(() => {
    return (
      animalInfo?.Status_ENUM === Registration_Status.Active ||
      animalInfo?.Status_ENUM === Registration_Status.Deceased ||
      animalInfo?.Status_ENUM === Registration_Status.Inactive ||
      animalInfo?.Status_ENUM === Registration_Status.LostTag
    );
  }, [animalInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Same details"}
      name={AnimalTypeSubmitActions.SameDetails}
      onClick={onSubmit}
    />
  ) : null;
});
