import { DirectDebitStatusCode } from "@app/products/direct-debit/system-admin/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getDirectDebitStatusCodeById = async (
  directDebitStatusCodeId: number,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitStatusCode> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitStatusCode>(
      `api/receipting/internal/directdebitstatus/${directDebitStatusCodeId}`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSaveDirectDebitStatusCode = async (
  directDebitStatusCode: DirectDebitStatusCode
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/receipting/internal/directdebitstatus",
      directDebitStatusCode
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
