import { DTO_DeferredDuty_Account_Transaction } from "@app/products/property/deferred-duty/[id]/components/forms/existed/components/form-steps/reverse-transaction/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import React from "react";
import { colTransactionsDeferredDuty } from "./config";

export const TransactionDeferredDutyFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOfTransaction = nameOfFactory<DTO_DeferredDuty_Account_Transaction>();
const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Transactions</label>
          <CCGrid
            className="cc-transactions-grid"
            data={getFieldValue("Transactions") || []}
            columnFields={colTransactionsDeferredDuty}
            primaryField={nameOfTransaction("AccountTransactionId")}
            readOnly
          />
        </div>
      </div>
    </section>
  );
};
