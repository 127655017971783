import { generateGetContactURL } from "@app/core/contacts/components/dialogs/pick-contact/util";
import { KeyValuePair } from "@app/core/documents/model";
import { CloseEventDialog } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/_index";
import { EventClose } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import { Svc_ContactDetails } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { CS_RequestorType } from "@app/products/crms/[id]/model";
import { AddCommentPhoneMessageDialog } from "@app/products/crms/phone-messages/[id]/components/forms/components/action-bar/dialogs/add-comment/_index";
import { CRMS_PHONE_MESSAGE_FLAGS } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/constant";
import { CompleteMessageRequest } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { MultiSelectSearch } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/multi-select-search/_index";
import { ForwardOnDialog } from "@app/products/crms/phone-messages/[id]/components/forms/components/dialogs/forward-on/_index";
import { colInternalContact } from "@app/products/crms/phone-messages/[id]/config";
import {
  PhoneMessage,
  PhoneMessageEventHandlerRequest,
  PhoneMessageStatus,
  Svc_PhoneMessageFormAction,
} from "@app/products/crms/phone-messages/[id]/model";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { ContactClassification } from "@common/constants/enumerations";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

export interface ICRMSPhoneMessageFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<PhoneMessage>();

export const CRMSPhoneMessageFormElement = observer(
  ({ formRenderProps }: ICRMSPhoneMessageFormElementProps) => {
    const {
      phoneMsgLovs,
      eventChangeHandler,
      uiControlExisted,
      setIsShowConvertToEventDialog,
      isShowConvertToEventDialog,
      convertToEventHandler,
      isLoadingExisted,
      isShowCloseEventDialog,
      setIsShowCloseEventDialog,
      crmsEventClose,
      completeMessageHandler,
      isLoadingCloseEvent,
      isShowForwardOnDialog,
      setIsShowForwardOnDialog,
      isShowCommentDialog,
      setIsShowCommentDialog,
    } = useCRMSPhoneMessageStore();
    const isNew = useIsNew();
    const { valueGetter, onChange } = formRenderProps;
    const phoneMsgFormObj: PhoneMessage = valueGetter("");
    const { pushNotification } = useCCAppNotificationStore();
    const isDisable =
      phoneMsgFormObj?.Status_ENUM === PhoneMessageStatus.Completed;
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    // handle on change
    const handleToChange = (event: MultiSelectChangeEvent) => {
      const params: PhoneMessageEventHandlerRequest = {
        PhoneMessageFormAction: Svc_PhoneMessageFormAction.Form_AddTo,
        PhoneMessage: phoneMsgFormObj,
        EventArgs: {
          ToRecipientsList: event.value ? refactorData(event.value) : [],
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change to recipient fail.");
    };

    const handleCcChange = (event: MultiSelectChangeEvent) => {
      const params: PhoneMessageEventHandlerRequest = {
        PhoneMessageFormAction: Svc_PhoneMessageFormAction.Form_AddCc,
        PhoneMessage: phoneMsgFormObj,
        EventArgs: {
          CcList: event.value ? refactorData(event.value) : [],
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change cc recipient fail.");
    };

    const handleBccChange = (event: MultiSelectChangeEvent) => {
      const params: PhoneMessageEventHandlerRequest = {
        PhoneMessageFormAction: Svc_PhoneMessageFormAction.Form_AddBcc,
        PhoneMessage: phoneMsgFormObj,
        EventArgs: {
          BccList: event.value ? refactorData(event.value) : [],
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change cc recipient fail.");
    };

    const refactorData = (data: Svc_ContactDetails[]) => {
      return data.map((item: Svc_ContactDetails) => ({
        Key: item.Id,
        Value: { Key: item.DisplayName, Value: item.Email },
      }));
    };

    const getIDs = (
      values: KeyValuePair<number, KeyValuePair<string, string>>[]
    ) => {
      let listID: (number | undefined)[] = [];
      if (values?.length > 0) {
        listID = values.map(
          (item: KeyValuePair<number, KeyValuePair<string, string>>) => item.Key
        );
      }
      return listID;
    };

    const handleMessageFlagChange = (
      event: SwitchChangeEvent,
      flagId: string,
      flagName: string
    ) => {
      let messageFlag_IDs: string[] = valueGetter(
        nameOf("MessageFlag_IDs")
      )?.split("|");

      let messageFlag_Names: string[] = valueGetter(
        nameOf("MessageFlag_Names")
      )?.split(",");

      onChange(`${CRMS_PHONE_MESSAGE_FLAGS}.MsgFlag_${flagId}`, {
        value: event.value,
      });

      if (event.value) {
        if (!messageFlag_IDs.includes(flagId)) {
          messageFlag_IDs.push(flagId);
          messageFlag_Names.push(flagName);
        }
      } else {
        messageFlag_IDs = messageFlag_IDs.filter(
          (item: string) => item !== flagId
        );
        messageFlag_Names = messageFlag_Names.filter(
          (item: string) => item !== flagName
        );
      }
      onChange(nameOf("MessageFlag_IDs"), {
        value: messageFlag_IDs.filter((item: string) => item).join("|"),
      });
      onChange(nameOf("MessageFlag_Names"), {
        value: messageFlag_Names.filter((item: string) => item).join(","),
      });
    };

    const handleConvertToEvent = () => {
      const phoneMsg = JSON.parse(JSON.stringify(phoneMsgFormObj));
      delete phoneMsg[CRMS_PHONE_MESSAGE_FLAGS];
      convertToEventHandler(phoneMsg);
    };

    const handleCompleteMessage = (data: EventClose) => {
      const phoneMsg = JSON.parse(JSON.stringify(phoneMsgFormObj));
      delete phoneMsg[CRMS_PHONE_MESSAGE_FLAGS];
      completeMessageHandler({
        CloseInfo: data,
        PhoneMessage: phoneMsg,
      } as CompleteMessageRequest);
    };

    return (
      <>
        {isShowConvertToEventDialog && (
          <ConfirmDialog
            onClosePopup={() => {
              setIsShowConvertToEventDialog(false);
            }}
            title={"Convert To Event"}
            onConfirmYes={handleConvertToEvent}
            subMessage={
              "This action will create an Event. Are you sure you want to continue?"
            }
            isLoadingYes={isLoadingExisted}
          />
        )}
        {isShowCloseEventDialog && crmsEventClose && !isLoadingExisted && (
          <CloseEventDialog
            crmsEventClose={crmsEventClose}
            onClosePopup={() => setIsShowCloseEventDialog(false)}
            onSubmit={handleCompleteMessage}
            isLoading={isLoadingCloseEvent}
            isPhoneMsg={true}
          />
        )}
        {isShowForwardOnDialog && (
          <ForwardOnDialog
            onClosePopup={() => {
              setIsShowForwardOnDialog(false);
            }}
            PhoneMessage={phoneMsgFormObj}
          />
        )}
        {isShowCommentDialog && (
          <AddCommentPhoneMessageDialog
            onClose={() => setIsShowCommentDialog(false)}
            phoneMessage={phoneMsgFormObj}
          />
        )}
        <FormElement>
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="From"
                  value={valueGetter(nameOf("Sender_SiteUser_DisplayName"))}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Date"
                  value={valueGetter("Sys_CreatedDate")}
                  format={DATETIME_FORMAT.DATETIME}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="To" isMandatory />
                <Field
                  name={nameOf("ToRecipientsList")}
                  dataUrl={`${generateGetContactURL({
                    classificationFilters: `'${ContactClassification.SiteUser}'`,
                    hideCouncillors: "true",
                  })}?$count=true`}
                  component={MultiSelectSearch}
                  columnFields={colInternalContact}
                  textField="Email"
                  dataItemKey="Id"
                  listID={getIDs(valueGetter(nameOf("ToRecipientsList")))}
                  filterField="DisplayName"
                  onError={pushNotification}
                  validator={requiredValidator}
                  formRenderProps={formRenderProps}
                  refactorData={refactorData}
                  onChangeHandler={handleToChange}
                  disabled={!isNew}
                />
              </div>

              <div className="cc-field">
                <label className="cc-label">Cc</label>
                <Field
                  name={nameOf("CcList")}
                  dataUrl={`${generateGetContactURL({
                    classificationFilters: `'${ContactClassification.SiteUser}'`,
                  })}?$count=true`}
                  component={MultiSelectSearch}
                  columnFields={colInternalContact}
                  textField="Email"
                  dataItemKey="Id"
                  listID={getIDs(valueGetter(nameOf("CcList")))}
                  filterField="DisplayName"
                  onError={pushNotification}
                  formRenderProps={formRenderProps}
                  refactorData={refactorData}
                  onChangeHandler={handleCcChange}
                  disabled={!isNew}
                />
              </div>

              <div className="cc-field">
                <label className="cc-label">Bcc</label>
                <Field
                  name={nameOf("BccList")}
                  dataUrl={`${generateGetContactURL({
                    classificationFilters: `'${ContactClassification.SiteUser}'`,
                  })}?$count=true`}
                  component={MultiSelectSearch}
                  columnFields={colInternalContact}
                  textField="Email"
                  dataItemKey="Id"
                  listID={getIDs(valueGetter(nameOf("BccList")))}
                  filterField="DisplayName"
                  onError={pushNotification}
                  formRenderProps={formRenderProps}
                  refactorData={refactorData}
                  onChangeHandler={handleBccChange}
                  disabled={!isNew}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="Contact"
                  className={
                    phoneMsgFormObj?.ContactType_ENUM ===
                      CS_RequestorType.ExternalPerson &&
                    !!phoneMsgFormObj?.Contact_External?.Contact?.ContactAlert
                      ? "cc-phone-msg-contact-alert"
                      : ""
                  }
                  value={uiControlExisted?.LblContact?.Value ?? ""}
                />
              </div>
            </div>
          </div>

          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Work phone</label>
                <Field
                  name={nameOf("WorkPhone")}
                  placeholder="Work phone"
                  readOnly={isDisable}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.WorkPhone}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Mobile</label>
                <Field
                  name={nameOf("Mobile")}
                  placeholder="Mobile"
                  readOnly={isDisable}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Mobile}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Home phone</label>
                <Field
                  name={nameOf("HomePhone")}
                  placeholder="Home phone"
                  readOnly={isDisable}
                  component={CCInputPhone}
                  validator={validatePhoneNumber}
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.HomePhone}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <Field
                  label="Email"
                  name={nameOf("Email")}
                  placeholder="Email"
                  readOnly={isDisable}
                  component={CCInputEmail}
                  validator={validateEmail}
                  onChangeForm={onChange}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Preferred method</label>
                <Field
                  name={nameOf("PreferredMethod_ENUM")}
                  textField="Value"
                  dataItemKey="Key"
                  data={phoneMsgLovs?.PreferredMethod}
                  component={CCSearchComboBox}
                  isUseDefaultOnchange
                  disabled={isDisable}
                />
              </div>
            </div>
          </div>

          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-form-cols-5">
              {phoneMsgLovs?.Flags &&
                phoneMsgLovs?.Flags.map((flag) => (
                  <div className="cc-field" key={flag.Key}>
                    <CCLabel title={flag.Value} />
                    <Field
                      name={`${CRMS_PHONE_MESSAGE_FLAGS}.MsgFlag_${flag.Key.toString()}`}
                      id={flag.Key.toString()}
                      ariaLabelledBy={flag.Value}
                      onChange={(event: SwitchChangeEvent) => {
                        handleMessageFlagChange(
                          event,
                          flag.Key.toString(),
                          flag.Value
                        );
                      }}
                      checked={valueGetter(
                        `${CRMS_PHONE_MESSAGE_FLAGS}.MsgFlag_${flag.Key.toString()}`
                      )}
                      component={CCSwitch}
                      disabled={isDisable}
                    />
                  </div>
                ))}
            </div>
          </div>

          <hr className="cc-divider" />

          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Message" isMandatory />
                <Field
                  name={nameOf("Message")}
                  placeholder="Message"
                  rows={8}
                  value={valueGetter("Message")}
                  component={CCTextArea}
                  validator={requiredValidator}
                  readOnly={isDisable}
                />
              </div>
            </div>
          </div>
          <hr className="cc-divider" />
          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-items-center cc-form-cols-1">
                <CCLabel
                  title={`Call back required within ${valueGetter(
                    nameOf("CallBackDays")
                  )} working days`}
                />
              </div>
            </div>
          </div>
        </FormElement>
      </>
    );
  }
);
