import useActionBarStore from "@common/hooks/useActionBarStore";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import "./_index.scss";

export const EventManagementActionBarCalendarFilter = () => {
  const [selectedCalendarFilter, setSelectedCalendarFilter] = useState([]);

  const { setCalendarFilters }: any = useActionBarStore();
  const calendarFilterData = [
    {
      key: "Meeting",
      title: "Daily Meeting",
    },
    {
      key: "Coffee",
      title: "Coffee Show",
    },
    {
      key: "English",
      title: "English Club Meeting",
    },
    {
      key: "Oncall",
      title: "Oncall Interview",
    },
    {
      key: "Offline",
      title: "Offline Interview",
    },
  ];

  const onCalenderFilterChange = (event: any) => {
    const cloneData: any = [...event.value];
    setSelectedCalendarFilter(cloneData);

    setCalendarFilters([...event.target.value]);
  };

  return (
    <div className="cc-action-bar-multi-select">
      <React.Fragment>
        <MultiSelect
          textField="title"
          placeholder="Filter by..."
          data={calendarFilterData}
          autoClose={false}
          allowCustom={true}
          dataItemKey="key"
          // className="d-none d-lg-block"
          value={selectedCalendarFilter}
          onChange={onCalenderFilterChange}
          tags={
            selectedCalendarFilter.length > 0
              ? [
                  {
                    text: `${selectedCalendarFilter.length} selected`,
                    data: [...calendarFilterData],
                  },
                ]
              : []
          }
        />
      </React.Fragment>
    </div>
  );
};
