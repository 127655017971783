import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { IModifyInstallation } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-installation/model";
import { colGISReference } from "@app/products/property/registers/[id]/config";
import { IGISReferenceGrid } from "@app/products/property/registers/[id]/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import useEffectOnce from "react-use/lib/useEffectOnce";

interface IEnterReadingsDialog {
  onClose: () => void;
  onModify: () => void;
}

const ModifyMeterInstallationDialog = observer(
  ({ onClose, onModify }: IEnterReadingsDialog) => {
    const { isLoading, installation, loadInstallation, setInstallation } =
      useMeterInstallationStore();
    const [modifyInstallationData, setModifyInstallationData] =
      useState<IModifyInstallation>();

    const nameOfGISReferenceGrid = nameOfFactory<IGISReferenceGrid>();

    useEffectOnce(() => {
      loadInstallation();
    });

    useEffect(() => {
      setModifyInstallationData({
        ...installation,
        _option: {
          RouteName: { Data: installation?.ReadingDetail?.ListRouteName },
          ReadingInstructions: {
            Data: installation?.ReadingDetail?.ListReadingInstructions,
          },
          Locality: {
            Data: installation?.ReadingDetail?.ListLocality,
          },
          Categories: {
            Data: installation?.BillingDetails?.ListCategories,
          },
        },
      });
    }, [installation]);

    const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
      const { values } = event;
      setInstallation(values);
      onModify();
    };

    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={modifyInstallationData}
        key={JSON.stringify(modifyInstallationData)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit, valid } = formRenderProps;
          return (
            <CCDialog
              titleHeader={`Modify Meter Installation`}
              onClose={onClose}
              maxHeight="auto"
              maxWidth="60%"
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <div className="cc-form">
                    <FormElement onSubmit={onSubmit} className="cc-field-group">
                      <div className="cc-field-group cc-col-span-3">
                        <div className="cc-reading-details cc-field-group">
                          <label className="cc-label">Reading details</label>
                          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Next scheduled reading date
                                </label>
                                <Field
                                  name={
                                    "ReadingDetail.NextScheduledReadingDate"
                                  }
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  component={CCDatePicker}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Reading frequency
                                </label>
                                <div className="cc-custom-input-group">
                                  <Field
                                    name={"ReadingDetail.ReadingFrequency"}
                                    placeholder={"Reading frequency"}
                                    min={0}
                                    component={CCNumericTextBox}
                                  />
                                  <div className="cc-input-group-postfix">
                                    month(s)
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cc-reading-details-route">
                              <label className="cc-label">Route</label>
                              <div className="cc-custom-sub-panel-bar cc-field-group">
                                <div className="cc-form-cols-3">
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Route name
                                    </label>
                                    <Field
                                      data={
                                        valueGetter("_option.RouteName.Data") ||
                                        []
                                      }
                                      name={"ReadingDetail.RouteName"}
                                      textField="Value"
                                      dataItemKey="Key"
                                      component={CCSearchComboBox}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Sequence within route
                                    </label>
                                    <Field
                                      name={"ReadingDetail.SequenceWithinRoute"}
                                      format={NUMBER_FORMAT.NUMBER2}
                                      placeholder={"Sequence within route"}
                                      component={CCNumericTextBox}
                                      min={0}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Assigned reading device
                                    </label>
                                    <Field
                                      name={
                                        "ReadingDetail.AssignedReadingDevice"
                                      }
                                      placeholder={"Assigned reading device"}
                                      component={CCInput}
                                      readOnly
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Device assigned to
                                    </label>
                                    <Field
                                      name={"ReadingDetail.DeviceAssignedTo"}
                                      component={CCInput}
                                      placeholder={"Device assigned to"}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cc-reading-details-property">
                              <label className="cc-label">Property</label>
                              <div className="cc-custom-sub-panel-bar cc-field-group">
                                <div className="cc-form-cols-3">
                                  <div className="cc-field cc-col-span-2">
                                    <label className="cc-label">
                                      Automatically determine property address
                                      from associated assessment
                                    </label>
                                    <Field
                                      name={"ReadingDetail.AutoProperty"}
                                      component={CCSwitch}
                                      defaultChecked={valueGetter(
                                        "ReadingDetail.AutoProperty"
                                      )}
                                    />
                                  </div>
                                </div>

                                <div className="cc-form-cols-3">
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Property name
                                    </label>
                                    <Field
                                      name={"ReadingDetail.PropertyName"}
                                      placeholder={"Property name"}
                                      component={CCInput}
                                      readOnly={valueGetter(
                                        "ReadingDetail.AutoProperty"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Property address
                                    </label>
                                    <Field
                                      name={"ReadingDetail.PropertyAddress"}
                                      placeholder={"Property address"}
                                      component={CCInput}
                                      readOnly={valueGetter(
                                        "ReadingDetail.AutoProperty"
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <label className="cc-label">Locality</label>
                                    <Field
                                      data={
                                        valueGetter("_option.Locality.Data") ||
                                        []
                                      }
                                      name={"ReadingDetail.Locality"}
                                      disabled={valueGetter(
                                        "ReadingDetail.AutoProperty"
                                      )}
                                      textField="Value"
                                      dataItemKey="Key"
                                      component={CCSearchComboBox}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cc-reading-details-location">
                              <div className=" cc-form-cols-3">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Meter location
                                  </label>
                                  <Field
                                    name={"ReadingDetail.MeterLocation"}
                                    placeholder={"Meter location"}
                                    component={CCInput}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    GIS references
                                  </label>
                                  <CCGrid
                                    className="cc-gis-reference-grid-data"
                                    columnFields={colGISReference}
                                    primaryField={nameOfGISReferenceGrid(
                                      "GISReference"
                                    )}
                                    data={[]}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Reading instructions
                                  </label>
                                  <Field
                                    data={
                                      valueGetter(
                                        "_option.ReadingInstructions.Data"
                                      ) || []
                                    }
                                    name={"ReadingDetail.ReadingInstructions"}
                                    textField="Value"
                                    dataItemKey="Key"
                                    component={CCSearchComboBox}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Keys are required
                                  </label>
                                  <Field
                                    name={"ReadingDetail.KeysAreRequired"}
                                    component={CCSwitch}
                                    defaultChecked={valueGetter(
                                      "ReadingDetail.KeysAreRequired"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="cc-reading-details-contact">
                              <div className="cc-form-cols-3">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Contact name
                                  </label>

                                  <Field
                                    name={"ReadingDetail.ContactName"}
                                    placeholder={"Contact name"}
                                    component={CCInput}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Contact phone
                                  </label>
                                  <Field
                                    name={"ReadingDetail.ContactPhone"}
                                    placeholder={"Contact phone"}
                                    component={CCInput}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Phone before reading
                                  </label>

                                  <Field
                                    name={"ReadingDetail.PhoneBeforeReading"}
                                    component={CCSwitch}
                                    defaultChecked={valueGetter(
                                      "ReadingDetail.PhoneBeforeReading"
                                    )}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Email before reading
                                  </label>
                                  <Field
                                    name={"ReadingDetail.EmailBeforeReading"}
                                    component={CCSwitch}
                                    defaultChecked={valueGetter(
                                      "ReadingDetail.EmailBeforeReading"
                                    )}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Email address
                                  </label>
                                  <Field
                                    name={"ReadingDetail.EmailAddress"}
                                    component={CCTextArea}
                                    rows={3}
                                    placeholder={"Email address"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cc-installation-remove-detail">
                          <label className="cc-label">
                            Installation/Remove details
                          </label>
                          <div className="cc-custom-sub-panel-bar cc-form-cols-3">
                            <div className="cc-field">
                              <label className="cc-label">
                                Installation date
                              </label>
                              <Field
                                name={"InstallationDetails.InstallationDate"}
                                format={DATE_FORMAT.DATE_CONTROL}
                                component={CCDatePicker}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">By</label>
                              <Field
                                name={"InstallationDetails.By"}
                                placeholder={"By"}
                                component={CCInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="cc-billing-details">
                          <label className="cc-label">Billing details</label>
                          <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">Category</label>
                              <Field
                                data={
                                  valueGetter("_option.Categories.Data") || []
                                }
                                name={"BillingDetails.Category"}
                                textField="Value"
                                dataItemKey="Key"
                                component={CCSearchComboBox}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </FormElement>
                  </div>
                )
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    type="button"
                    className={"cc-dialog-button"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    type={"submit"}
                    name="OK"
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={onSubmit}
                    disabled={!valid}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);

export default ModifyMeterInstallationDialog;
