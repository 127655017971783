import { PRODUCT_TYPE } from "@common/constants/productType";
import { sleep } from "@common/utils/common";

export interface ICCGridColumnAPI {
  ColumnName: string;
  DataColumn: string;
  IsHidden: boolean;
}
//Appendix B – API
export const getViewConfiguration = async (
  userId: number,
  productType: PRODUCT_TYPE,
  menuActionEnumeration_Id: number,
  userView_Id: number
): Promise<ICCGridColumnAPI[] | undefined> => {
  await sleep(1000);
  switch (userView_Id) {
    case 1:
      return [
        {
          ColumnName: "Assessment_Number_Formatted",
          DataColumn: "Number Custom",
          IsHidden: false,
        },
        {
          ColumnName: "Property_Address",
          DataColumn: "Property Address Custom 1",
          IsHidden: false,
        },
        {
          ColumnName: "Property_Locality",
          DataColumn: "Property Locality",
          IsHidden: false,
        },
        {
          ColumnName: "Property_Location_Descriptor",
          DataColumn: "Property Location",
          IsHidden: false,
        },
        {
          ColumnName: "Legal_Description",
          DataColumn: "Legal Description",
          IsHidden: false,
        },
        {
          ColumnName: "Improvements",
          DataColumn: "Improvements",
          IsHidden: false,
        },
        {
          ColumnName: "Land_Use",
          DataColumn: "Land Use Name",
          IsHidden: false,
        },
        {
          ColumnName: "Assessment_Type_Name",
          DataColumn: "Type",
          IsHidden: false,
        },
        {
          ColumnName: "Assessment_Status_Name",
          DataColumn: "Status",
          IsHidden: false,
        },
        {
          ColumnName: "Owners_Name",
          DataColumn: "Owners Name",
          IsHidden: false,
        },
        {
          ColumnName: "Owners_State",
          DataColumn: "Owners State",
          IsHidden: false,
        },
        {
          ColumnName: "Owners_Address",
          DataColumn: "Owners Address",
          IsHidden: false,
        },
        {
          ColumnName: "Owners_Locality",
          DataColumn: "Owners Locality",
          IsHidden: false,
        },
        {
          ColumnName: "Owners_Name_Address",
          DataColumn: "Owners Name Address",
          IsHidden: false,
        },
        {
          ColumnName: "Owners_Postcode",
          DataColumn: "Owners Postcode",
          IsHidden: false,
        },
        {
          ColumnName: "Assessment_Id",
          DataColumn: "Assessment ID",
          IsHidden: false,
        },
      ];
    case 2:
      return [
        {
          ColumnName: "Assessment_Number_Formatted",
          DataColumn: "Number Custom 2",
          IsHidden: false,
        },
        {
          ColumnName: "Property_Address",
          DataColumn: "Property Address Custom 2",
          IsHidden: false,
        },
        {
          ColumnName: "Property_Locality",
          DataColumn: "Property Locality",
          IsHidden: true,
        },
        {
          ColumnName: "Property_Location_Descriptor",
          DataColumn: "Property Location",
          IsHidden: true,
        },
        {
          ColumnName: "Legal_Description",
          DataColumn: "Legal Description",
          IsHidden: true,
        },
        {
          ColumnName: "Improvements",
          DataColumn: "Improvements",
          IsHidden: true,
        },
        {
          ColumnName: "Land_Use",
          DataColumn: "Land Use Name",
          IsHidden: true,
        },
        {
          ColumnName: "Assessment_Type_Name",
          DataColumn: "Type",
          IsHidden: true,
        },
        {
          ColumnName: "Assessment_Status_Name",
          DataColumn: "Status",
          IsHidden: true,
        },
        {
          ColumnName: "Owners_Name",
          DataColumn: "Owners Name",
          IsHidden: true,
        },
        {
          ColumnName: "Owners_State",
          DataColumn: "Owners State",
          IsHidden: true,
        },
        {
          ColumnName: "Owners_Address",
          DataColumn: "Owners Address",
          IsHidden: true,
        },
        {
          ColumnName: "Owners_Locality",
          DataColumn: "Owners Locality",
          IsHidden: true,
        },
        {
          ColumnName: "Owners_Name_Address",
          DataColumn: "Owners Name Address",
          IsHidden: true,
        },
        {
          ColumnName: "Owners_Postcode",
          DataColumn: "Owners Postcode",
          IsHidden: true,
        },
        {
          ColumnName: "Assessment_Id",
          DataColumn: "Assessment ID",
          IsHidden: false,
        },
      ];
  }
};
