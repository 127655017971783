import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const AssessmentGeneral2Form = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    const data = useDataSetting(configData, [
      "TP_UseESD",
      "TP_MandatoryESD",
      "TP_UseWSUD",
      "TP_MandatoryWSUD",
      "TP_Assessment_CouncilMeeting",
      "TP_Assessment_UseExtraDetails",
      "TP_Assessment_UseParkingDetails",
      "TP_Assessment_MasterPlans",
      "TP_Assessment_ParkingScheme",
      "TP_ShowOfficerDecision",
      "TP_DisablePPARSValLandUse",
      "TP_UseUtilities",
    ]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <SwitchSettings
            data={data.TP_UseESD}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_MandatoryESD}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            isFieldVisible={
              data.TP_UseESD &&
              formRenderProps?.valueGetter(data.TP_UseESD.FieldName)
            }
          />
        </div>
        <div className="cc-form-cols-2">
          <SwitchSettings
            data={data.TP_UseWSUD}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_MandatoryWSUD}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            isFieldVisible={
              data.TP_UseWSUD &&
              formRenderProps?.valueGetter(data.TP_UseWSUD.FieldName)
            }
          />
        </div>
        <div className="cc-form-cols-2">
          <SwitchSettings
            data={data.TP_Assessment_CouncilMeeting}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_UseExtraDetails}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_UseParkingDetails}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_MasterPlans}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_ParkingScheme}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_ShowOfficerDecision}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_DisablePPARSValLandUse}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_UseUtilities}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
