import { colAttributes } from "@common/pages/search/components/search-results/components/tooltip-search/config";
import { DTO_ApplicationSearch_ToolTipItem } from "@common/pages/search/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import "./_index.scss";
export const TooltipSearch = (dataTooltip: any) => {
  const nameOf = nameOfFactory<DTO_ApplicationSearch_ToolTipItem>();
  const data = dataTooltip.dataItem.Attributes;

  return (
    <CCGrid
      className="cc-tooltip-search-grid"
      style={{ width: 363, height: 260 }}
      itemPerPage={25}
      data={data}
      columnFields={colAttributes}
      primaryField={nameOf("Name")}
    />
  );
};
