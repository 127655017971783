import { CRMSKnowledgeBaseFormElement } from "@app/products/crms/knowledge-base/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { useCRMSKnowledgeBaseStore } from "@app/products/crms/knowledge-base/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

export const CRMSKnowledgeBaseForm = observer(() => {
  const isNew = useIsNew();
  const {
    crmsKnowledgeBase,
    updateCRMSKnowledgeBase,
    createCRMSKnowledgeBase,
  } = useCRMSKnowledgeBaseStore();

  const handleSubmit = async (data: any) => {
    if (isNew) {
      createCRMSKnowledgeBase(data);
    } else {
      updateCRMSKnowledgeBase(data);
    }
  };
  const crmsKBArticleFormValidator: any = (values: any): any => {
    if (Object.keys(values).length > 0) {
      return {};
    }

    return {};
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={crmsKnowledgeBase}
        validator={crmsKBArticleFormValidator}
        render={(formRenderProps: FormRenderProps) => (
          <CRMSKnowledgeBaseFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
