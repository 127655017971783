import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

interface ActionBarFilterIconProps {
  disabled?: boolean;
}

export const ActionBarFilterIcon = observer(({disabled} : ActionBarFilterIconProps) => {
  const { setIsShowFilterBar, isShowFilterBar, gridDataState, isEmptyData } =
    useCCProductListViewStore();

  const getFilterClassName = useMemo(() => {
    if (gridDataState.filter && gridDataState.filter.filters.length) {
      return "cc-applied-filter-button";
    }
    return "";
  }, [gridDataState.filter]);

  return (
    <CCNavButton
      iconClass="fal fa-filter"
      title="Show Filter"
      disabled={isEmptyData || disabled}
      isActive={isShowFilterBar}
      onClick={(event) => {
        event.preventDefault();
        setIsShowFilterBar(!isShowFilterBar);
      }}
      className={getFilterClassName}
    />
  );
});
