import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";

export const ActionStatusName = (type: ACTIONSTATUS) => {
  switch (type) {
    case ACTIONSTATUS.ALLOCATED:
      return "Allocated";
    case ACTIONSTATUS.COMPLETE:
      return "Complete";
    case ACTIONSTATUS.APPROVED:
      return "Approved";
    case ACTIONSTATUS.NOTAPPROVED:
      return "Not Approved";
    case ACTIONSTATUS.AWAITINGAPPROVAL:
      return "Awaiting Approval";
    case ACTIONSTATUS.NEW:
      return "New";
    case ACTIONSTATUS.DRAFT:
      return "Draft";
    case ACTIONSTATUS.SENT:
      return "Sent";
    case ACTIONSTATUS.READYTOBESENT:
      return "Ready to be sent";
    case ACTIONSTATUS.NOACTION:
      return "No action/action completed";
    case ACTIONSTATUS.PENDINGACTION:
      return "Pending Reply/Action";
    case ACTIONSTATUS.SEEKINGPARTIESAGREEMENTS:
      return "Seeking Parties Agreements";
    case ACTIONSTATUS.PRECONCILIATIONMEETINGS:
      return "Pre-Conciliation Meetings";
    case ACTIONSTATUS.INPROGRESS:
      return "In Progress";
    case ACTIONSTATUS.CLOSED:
      return "Closed";
    case ACTIONSTATUS.SystemInitialise:
      return "";

    case ACTIONSTATUS.AWAITINGSUBMISSION:
      return "Awaiting Submission";
    case ACTIONSTATUS.CONSIDERINGSUBMISSION:
      return "Considering Submission";
    case ACTIONSTATUS.FINAL:
      return "Final";

    case ACTIONSTATUS.NOTSTARTED:
      return "Not Started";
    case ACTIONSTATUS.ASSESSORAPPOINTED:
      return "Assessor appointed";
    case ACTIONSTATUS.ASSESSMENTUNDERWAY:
      return "Assessment underway";
    case ACTIONSTATUS.CONSIDERINGASSESORSREPORT:
      return "Considering assesor's report";
    case ACTIONSTATUS.PENDINGDISCUSSIONWITHWORKER:
      return "Pending discussion with worker";
    case ACTIONSTATUS.PENDINGFINALDECISIONCOMPLETED:
      return "Pending final decision";
    case ACTIONSTATUS.AWAITINGDELEGATEAPPROVAL:
      return "Awaiting Approval";
    default:
      return "";
  }
};
