import {
  getNoticeRunOutput,
  postNoticeRunOutput,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/notice-run-output/api";
import { listDefaultOptionNoticeGroup } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/notice-run-output/config";
import {
  DTO_LOV_NoticeRun,
  DTO_WorkflowDetail_NoticeRunExport,
  ENumLoading,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/notice-run-output/model";
import { checkFormatNoticeRunOutput } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/notice-run-output/util";
import {
  DTO_WorkflowHeader,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isArray, isNil } from "lodash";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface NoticeRunOutputDialogProps {
  onClose: () => void;
  actionAfterSuccessFinish: (event: any) => void;
}
const nameOf = nameOfFactory<DTO_WorkflowDetail_NoticeRunExport>();
export const OutputToFileDialog = ({
  onClose,
  actionAfterSuccessFinish,
}: NoticeRunOutputDialogProps) => {
  //state
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [noticeRunOutputInitialValue, setNoticeRunOutputInitialValue] =
    useState<DTO_WorkflowDetail_NoticeRunExport>();
  const [isLoading, setIsLoading] = useState<ENumLoading | undefined>();
  const [option, setOption] = useState<DTO_LOV_NoticeRun>();
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const { id } = useParams<{ id: string }>();
  const { lastSelectedId } = useCCProductListViewStore();
  const noticeRunId: string = id ?? lastSelectedId;
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const getInitialData = async () => {
    setIsLoading(ENumLoading.FirstLoading);
    const response = await getNoticeRunOutput(+noticeRunId);
    let errorResponse = undefined;
    if (isArray(response) && response[0]?.data && response[1]?.data) {
      const [lOVs, noticeRunData] = response;
      //set initial dat form
      setNoticeRunOutputInitialValue({
        ...noticeRunData?.data?.WorkflowDetail,
        Notice_Group_Id: null,
        FileName: noticeRunData.data?.WorkflowDetail?.NoticeName,
      });
      setWorkflowHeader(noticeRunData?.data?.WorkflowHeader);
      //filter data in list
      const defaultOption = lOVs.data?.NoticeGroups.filter((item) =>
        listDefaultOptionNoticeGroup.includes(item.Code)
      )?.sort((pre: DTO_LOV, cur: DTO_LOV) => {
        return pre?.Code === "" ? -1 : cur?.Code === "" ? 1 : 0;
      });
      let noticeGroupData: any = lOVs.data?.NoticeGroups?.filter((item) =>
        noticeRunData.data?.WorkflowDetail?.NoticeGroups?.includes(
          parseInt(item.Code)
        )
      );
      noticeGroupData = [...defaultOption, ...noticeGroupData];
      noticeGroupData.forEach((item: any) => {
        const codeValue = item?.Code;
        item.Code = codeValue?.length ? +codeValue : null;
      });
      setOption({
        NoticeGroups: noticeGroupData,
        NoticeOutputFormats: convertValueLOVToNumber(
          lOVs.data?.NoticeOutputFormats ?? [],
          "Code"
        ),
      });
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Notice run output to file load failed",
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(undefined);
  };
  //calling api the first time
  useEffectOnce(() => {
    getInitialData();
  });

  //handle submit form
  const handleSubmit = async (values: any) => {
    //workflow detail
    let workflowDetail: any = {
      Notice_Run_Id: noticeRunId,
      NoticeName: noticeRunOutputInitialValue?.NoticeName,
      Notice_Group_Id: !isNil(values?.Notice_Group_Id)
        ? values?.Notice_Group_Id
        : null,
      OutputFormatId: values?.OutputFormatId,
      FileName:
        values?.FileName +
        checkFormatNoticeRunOutput(values?._option?.OutputFormat),
    };
    //payload
    const payload = {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
    setIsLoading(ENumLoading.FinishLoading);
    const response = await postNoticeRunOutput(
      WorkflowProcessMode.Finish,
      payload
    );
    setIsLoading(undefined);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      onClose();
      actionAfterSuccessFinish(response?.data);
    } else {
      notificationRef.current?.pushNotification({
        title:
          response?.data.ErrorMessage ??
          `Notice run output to file submit failed`,
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={JSON.stringify(noticeRunOutputInitialValue)}
      initialValues={noticeRunOutputInitialValue}
      render={(formRenderProps: FormRenderProps) => {
        const { modified, valid, onSubmit, valueGetter, onChange } =
          formRenderProps;
        return (
          <CCDialog
            maxWidth="35%"
            height="auto"
            titleHeader={"Notice Run Output"}
            onClose={onClose}
            isLoading={isLoading === ENumLoading.FirstLoading}
            disabled={isLoading !== undefined}
            bodyElement={
              <div className="cc-form">
                <CCLocalNotification ref={notificationRef} />
                {responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      getInitialData();
                    }}
                  />
                ) : (
                  <FormElement className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Notice run
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("NoticeName")}
                          component={CCInput}
                          readOnly
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Notice group</label>
                        <Field
                          name={nameOf("Notice_Group_Id")}
                          component={CCSearchComboBox}
                          data={option?.NoticeGroups || []}
                          textField="Name"
                          dataItemKey="Code"
                          isUseDefaultOnchange
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Output format
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("OutputFormatId")}
                          component={CCSearchComboBox}
                          data={option?.NoticeOutputFormats || []}
                          textField="Name"
                          dataItemKey="Code"
                          validator={requiredValidator}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange("OutputFormatId", {
                              value: event.value?.Code,
                            });
                            onChange("_option.OutputFormat", {
                              value: event.value.Name,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          File name
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <div className="cc-custom-input-group">
                          <Field
                            name={nameOf("FileName")}
                            component={CCInput}
                            width="80px"
                            placeholder="File name"
                          />
                          <div className="cc-input-group-postfix">
                            {checkFormatNoticeRunOutput(
                              valueGetter("_option.OutputFormat")
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormElement>
                )}
              </div>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  iconClass={
                    isLoading === ENumLoading.FinishLoading
                      ? "fas fa-spinner fa-spin"
                      : ""
                  }
                  disabled={!modified || !valid}
                  themeColor="primary"
                  onClick={onSubmit}
                  className={"cc-dialog-button"}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
