import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_AdhocStep_LOVs } from "@app/products/property/components/action-bar/dialog/add-workflow/model";

export const getAdhocStepLovs = async (): Promise<
  APIResponse<DTO_AdhocStep_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/adhocsteplovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
