import { ExistedBuildingRegistersCouncilConsent } from "@app/products/building/registers/council-consent/[id]/components/forms/existed/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import React from "react";
import { NewBuildingRegistersCouncilConsent } from "./components/forms/new/_index";

const ManageBuildingRegistersCouncilConsent = () => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewBuildingRegistersCouncilConsent />;
  }

  return <ExistedBuildingRegistersCouncilConsent />;
};

export default ManageBuildingRegistersCouncilConsent;
