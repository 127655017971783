import { KeyValuePair } from "@app/core/documents/model";
import { MultiTenancyView } from "@app/core/location-register/[id]/model";
import {
  FSR_BUSINESSCLASSIFICATION,
  PCBACategories,
  PremisesType,
} from "@app/products/hm/model";
import { setupPremisesPickSiteAddress } from "@app/products/hm/premises/[id]/api";
import {
  getNameOfPremises,
  getNameOfPremisesContact,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { propertyOwnerOptions } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/contact-section/config";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  PremisesUpdateTriggers,
  SecondaryModeCheckField,
  Svc_FormAction_Premise,
} from "@app/products/hm/premises/[id]/model";
import {
  getFSSContactDisplay,
  getPrimaryContactDisplay,
  getProprietorContactDisplay,
} from "@app/products/hm/premises/[id]/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { getContact } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/api";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { AddressClassification } from "@common/constants/enumerations";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import {
  Address_BuildAddress,
  SetupAddress,
} from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { capitalizeFirstLetter } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import {
  ComboBoxChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { forEach, isFunction } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";

export interface IHMPremisesGeneralSectionProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}
export const GeneralSection2 = observer(
  ({ formRenderProps, isInactive = false }: IHMPremisesGeneralSectionProps) => {
    const { dataForms, setInitialDataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;

    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;
    const lovs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;

    const prevProprietorDisplayName = useRef<string>("");
    const prevPrimaryContactDisplayName = useRef<string>("");
    const prevAlternateAddressDisplayName = useRef<string>("");
    const prevFSPContactDisplayName = useRef<string>("");
    const prevPropertyOwnerDisplayName = useRef<string>("");

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();
    const [isLoadingOpenDialog, setIsLoadingOpenDialog] = useState(false);

    // #region GET SETTING VALUES
    const isShowMultiTenancy =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_MultiTenancy]
      ) ?? false;
    const isFastChoice =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_FastChoice]
      ) ?? false;
    const isShowScoresEndorse =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_ShowScoresEndorse]
      ) ?? false;
    const isUseSimpleAddressDialogHM =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_SimpleAddressDialog]
      ) ?? false;
    const alternateAddressLabelSetting = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AlternateAddressLabel]
    );
    const isContactSplitAddressName = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
    );
    const isShowProprietorAddress = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowProprietorAddress]
    );
    const isFSSRequired = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_FSSRequired]
    );
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    // #endregion GET SETTING VALUES

    // #region GET LABEL
    const alternateAddressLabel =
      capitalizeFirstLetter(alternateAddressLabelSetting) ??
      "Residential address";
    // #endregion GET LABEL

    const isVisibleFSSContact =
      valueGetter(getNameOfPremises("PremisesType"))?.Flag_FSP ||
      (!valueGetter(getNameOfPremises("PremisesType"))?.Flag_FSP &&
        valueGetter(getNameOfPremises("PremisesType"))?.Flag_EnableFSS);

    const getInitialPremisesHandlerRequest = (
      formAction: Svc_FormAction_Premise
    ) => {
      return {
        FormAction: formAction,
        Premises: premisesObj,
        Args: {},
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      } as PremiseHandlerRequest;
    };

    // #region Proprietor Field
    const proprietorContactDisplay = useMemo(() => {
      return getProprietorContactDisplay(
        valueGetter(getNameOfPremises("Proprietor"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("Proprietor"))?.Contact]);
    const handleChangeProprietor = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickProprietor
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          Proprietor: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select proprietor contact failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueProprietor = () => {
      if (uiControlStore) {
        prevProprietorDisplayName.current =
          uiControlStore?.LitProprietor?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietor: {
              ...uiControlStore?.LitProprietor,
              Value: premisesObj?.Proprietor?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValueProprietor = () => {
      if (
        uiControlStore &&
        isHTML(prevProprietorDisplayName?.current) &&
        valueGetter(getNameOfPremises("Proprietor"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietor: {
              ...uiControlStore?.LitProprietor,
              Value: prevProprietorDisplayName.current,
            },
          },
        });
      }
    };
    // #endregion Proprietor Field

    // #region Primary Contact Field
    const handleOnChangePrimaryContact = (
      event: ComboBoxChangeEvent | null
    ) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickPrimaryContact
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          PrimaryContact: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select primary contact failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValuePrimaryContact = () => {
      if (uiControlStore) {
        prevPrimaryContactDisplayName.current =
          uiControlStore?.LitContact?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitContact: {
              ...uiControlStore?.LitContact,
              Value: premisesObj?.PrimaryContact?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValuePrimaryContact = () => {
      if (
        uiControlStore &&
        isHTML(prevPrimaryContactDisplayName.current) &&
        valueGetter(getNameOfPremises("PrimaryContact"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitContact: {
              ...uiControlStore?.LitContact,
              Value: prevPrimaryContactDisplayName.current,
            },
          },
        });
      }
    };
    const primaryContactDisplay = useMemo(() => {
      return getPrimaryContactDisplay(
        valueGetter(getNameOfPremises("PrimaryContact"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("PrimaryContact"))?.Contact]);
    // #endregion Primary Contact Field

    // #region Residential Address Field
    const handleOpenAlternateAddressDialog = async (
      setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
    ) => {
      if (!premisesObj) return;
      setIsLoadingOpenDialog(true);
      const setupAddress: SetupAddress = {
        Address: valueGetter(getNameOfPremises("Proprietor"))?.Contact
          ?.AlternateAddress,
        ClassificationFilters: [
          AddressClassification.Generic,
          AddressClassification.POBox,
          AddressClassification.Consultant_Address,
          AddressClassification.International,
        ],
        IsHasFlag_ForceUse_StreetType: isContactSplitAddressName,
      };
      const response = await setupPremisesPickSiteAddress(setupAddress);
      setIsLoadingOpenDialog(false);
      if (isSuccessResponse(response) && response?.data) {
        setInitialBuildAddress(response?.data?.ReturnObj);

        if (setIsShowDialog && isFunction(setIsShowDialog))
          setIsShowDialog(true);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address failed.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };
    const handleChangeAlternateAddress = (
      buildAddress: Address_BuildAddress | null
    ) => {
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickProprietorAddress
        );
      if (buildAddress) {
        premisesHandlerRequest.Args = {
          Proprietor: {
            Contact: {
              AlternateAddress: buildAddress?.Address,
            },
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: `Select ${alternateAddressLabel} failed.`,
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueAlternateAddress = () => {
      if (uiControlStore) {
        prevAlternateAddressDisplayName.current =
          uiControlStore?.LitProprietorResidentialAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietorResidentialAddress: {
              ...uiControlStore?.LitProprietorResidentialAddress,
              Value:
                premisesObj?.Proprietor?.Contact?.AlternateAddress
                  ?.Formatted_SingleLine,
            },
          },
        });
      }
    };
    const restoreDisplayValueAlternateAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevAlternateAddressDisplayName.current) &&
        valueGetter(getNameOfPremises("Proprietor"))?.Contact?.AlternateAddress
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietorResidentialAddress: {
              ...uiControlStore?.LitProprietorResidentialAddress,
              Value: prevAlternateAddressDisplayName.current,
            },
          },
        });
      }
    };
    const isAlternateAddressDisabled =
      isInactive ||
      !valueGetter(getNameOfPremises("Proprietor")) ||
      !valueGetter(getNameOfPremises("Proprietor"))?.Contact;
    // #endregion Residential Address Field

    // #region FSS Contact Field
    const handleChangeFSSContact = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickFSPContact
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          FSPContact: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select FSS contact failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueFSSContact = () => {
      if (uiControlStore) {
        prevFSPContactDisplayName.current =
          uiControlStore?.LitFSPContact?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitFSPContact: {
              ...uiControlStore?.LitFSPContact,
              Value: premisesObj?.FSPContact?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValueFSSContact = () => {
      if (
        uiControlStore &&
        isHTML(prevFSPContactDisplayName?.current) &&
        valueGetter(getNameOfPremises("FSPContact"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitFSPContact: {
              ...uiControlStore?.LitFSPContact,
              Value: prevFSPContactDisplayName.current,
            },
          },
        });
      }
    };
    const fssContactDisplay = useMemo(() => {
      return getFSSContactDisplay(
        valueGetter(getNameOfPremises("FSPContact"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("FSPContact"))?.Contact]);
    // #endregion FSS Contact Field

    // #region Property Owner Field
    const handleOnChangeOwnerProperty = (data: MultiTenancyView) => {
      let premisesHandlerRequest: PremiseHandlerRequest = {
        FormAction: Svc_FormAction_Premise.Form_PickMultiTenancyRegister,
        Premises: premisesObj,
        Args: data?.ID ? [data?.ID] : [],
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      };

      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select property owner failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const propertyOwnerOrganisationDisplay = useMemo(() => {
      const contact = valueGetter(getNameOfPremises("MultiTenancyRegister"))
        ?.Contact?.Contact;
      if (
        contact?.Extension?.Organisation &&
        contact?.Extension?.Organisation !== contact?.DisplayName
      ) {
        return contact?.Extension?.Organisation;
      }
      return "";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("MultiTenancyRegister"))?.Contact?.Contact,
    ]);
    const restoreDisplayValuePropertyOwner = () => {
      if (
        uiControlStore &&
        isHTML(prevPropertyOwnerDisplayName?.current) &&
        valueGetter(getNameOfPremises("MultiTenancyRegister"))?.Contact?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitMultiTenancy: {
              ...uiControlStore?.LitMultiTenancy,
              Value: prevPropertyOwnerDisplayName.current,
            },
          },
        });
      }
    };
    const removeDisplayValuePropertyOwner = () => {
      if (uiControlStore) {
        prevPropertyOwnerDisplayName.current =
          uiControlStore?.LitMultiTenancy?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitMultiTenancy: {
              ...uiControlStore?.LitMultiTenancy,
              Value:
                premisesObj?.MultiTenancyRegister?.Contact?.Contact
                  ?.DisplayName,
            },
          },
        });
      }
    };
    // #endregion Property Owner Field

    // #region Secondary Mode
    const checkSecondaryModeLbtProprietor = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.LbtProprietor
    );
    // #endregion Secondary Mode

    const updateSaveTriggers = (triggers: PremisesUpdateTriggers) => {
      let saveTriggers: PremisesUpdateTriggers[] =
        valueGetter(getNameOfPremises("SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOfPremises("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    // #region UI
    const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();
    const premisesGeneralFormLovs =
      dataForms?.GeneralFormLovs as PremisesRegisterLovs;
    const nameOfPremisesType = nameOfFactory<PremisesType>();
    const isFlagFSPTrue = useMemo(
      () => {
        return (
          valueGetter(
            `${getNameOfPremises("PremisesType")}.${nameOfPremisesType(
              "Flag_FSP"
            )}`
          ) ?? false
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(`${getNameOfPremises("PremisesType")}.Flag_FSP`)]
    );
    const fsrBusinessClassENUM = valueGetter(
      `${getNameOfPremises("PremisesType")}.FSR_BusinessClass_ENUM`
    );

    const getValuePremises = (name: keyof Premises) =>
      valueGetter(getNameOfPremises(name));
    const premisesType = getValuePremises("PremisesType") as PremisesType;
    const [noOfUnitsLabel, setNoOfUnitsLabel] = useState("Number of units");
    const isShowDivNoUnits = useMemo(() => {
      let showDivNoUnits = false;
      if (premisesType?.Flag_CoolingTower) {
        setNoOfUnitsLabel("Number of towers");
        showDivNoUnits = true;
      } else if (premisesType?.Flag_SwimmingPool) {
        setNoOfUnitsLabel("Number of pools");
        showDivNoUnits = true;
      } else if (premisesType?.Flag_NoOfUnits) {
        showDivNoUnits = true;
      }
      return showDivNoUnits;
    }, [
      premisesType?.Flag_CoolingTower,
      premisesType?.Flag_NoOfUnits,
      premisesType?.Flag_SwimmingPool,
    ]);

    const [noOfEmployeesLabel, setNoOfEmployeesLabel] = useState(
      "Number of employees"
    );
    const isShowDivNoEmployees = useMemo(() => {
      let showDivNoEmployees = premisesType?.Flag_NoOfEmployees;
      if (premisesType?.Flag_CoolingTower) {
        setNoOfEmployeesLabel("Number of systems");
        showDivNoEmployees = true;
      }
      return showDivNoEmployees;
    }, [premisesType?.Flag_CoolingTower, premisesType?.Flag_NoOfEmployees]);

    const isShowDivPCBA = useMemo(() => {
      return premisesType?.Flag_PCBA;
    }, [premisesType?.Flag_PCBA]);

    const pcbaLabel = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_PCBAMenuLabel]
    );
    const nameOfKeyValuePair = nameOfFactory<KeyValuePair<string, string>>();

    const handleOnChangePCBACategories = (event: MultiSelectChangeEvent) => {
      const pcbaCategories =
        (event?.value as KeyValuePair<string, string>[]) ?? [];
      let pcbaCategoriesEnum: PCBACategories = PCBACategories.Unspecified;
      forEach(pcbaCategories, (pcbaCategory: KeyValuePair<string, string>) => {
        if (pcbaCategory) {
          pcbaCategoriesEnum =
            pcbaCategoriesEnum |
            PCBACategories[pcbaCategory.Key as keyof typeof PCBACategories];
        }
      });
      onChange(getNameOfPremises("PCAndBACategories"), {
        value: pcbaCategoriesEnum,
      });
      onChange(getNameOfPremises("_PCAndBACategories"), {
        value: event?.value,
      });
    };

    return (
      <>
        {isLoadingOpenDialog && <Loading isLoading isFullScreen />}
        <div className="cc-divider-space" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Proprietor" isMandatory />
              <Field
                uniqueKey="HMPremisesProprietorContactPicker"
                name={`${getNameOfPremises("Proprietor")}.Contact`}
                component={ContactPicker}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Pick proprietor contact failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                displayValue={uiControlStore?.LitProprietor?.Value ?? ""}
                placeholder="Select proprietor"
                onChange={handleChangeProprietor}
                validator={requiredValidator}
                removeDisplayValue={removeDisplayValueProprietor}
                restoreDisplayValue={restoreDisplayValueProprietor}
                disabled={isInactive || checkSecondaryModeLbtProprietor}
                disabledButton={isInactive || checkSecondaryModeLbtProprietor}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Details"
                value={
                  isHTML(proprietorContactDisplay)
                    ? sanitizeHtml(proprietorContactDisplay)
                    : proprietorContactDisplay
                }
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Proprietor address"
                value={
                  isHTML(uiControlStore?.LitProprietorAddress?.Value)
                    ? sanitizeHtml(uiControlStore?.LitProprietorAddress?.Value)
                    : uiControlStore?.LitProprietorAddress?.Value
                }
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Postal address"
                value={
                  isHTML(uiControlStore?.LitProprietorPostalAddress?.Value)
                    ? sanitizeHtml(
                        uiControlStore?.LitProprietorPostalAddress?.Value
                      )
                    : uiControlStore?.LitProprietorPostalAddress?.Value
                }
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel
                title="Primary contact"
                isMandatory
                customTooltip={
                  <CCTooltip
                    type="custom"
                    position="right"
                    content="Set primary contact same as proprietor."
                  >
                    <i
                      onClick={async () => {
                        const contactID = await getContact(
                          valueGetter(getNameOfPremisesContact("Contact_ID"))
                        );
                        handleOnChangePrimaryContact({
                          value: contactID?.data,
                        } as ComboBoxChangeEvent);
                      }}
                      className="fad fa-file-import text-accent cc-clone-icon"
                    />
                  </CCTooltip>
                }
              />

              <Field
                uniqueKey="HMPremisesPrimaryContactPicker"
                name={`${getNameOfPremises("PrimaryContact")}.Contact`}
                component={ContactPicker}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Pick primary contact failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                displayValue={uiControlStore?.LitContact?.Value ?? ""}
                placeholder="Select primary contact"
                onChange={handleOnChangePrimaryContact}
                validator={requiredValidator}
                removeDisplayValue={removeDisplayValuePrimaryContact}
                restoreDisplayValue={restoreDisplayValuePrimaryContact}
                disabled={isInactive}
                disabledButton={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Details"
                value={
                  isHTML(primaryContactDisplay)
                    ? sanitizeHtml(primaryContactDisplay)
                    : primaryContactDisplay
                }
              />
            </div>
          </div>

          {isVisibleFSSContact && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="FSS contact" />
                <Field
                  uniqueKey="HMPremisesFSSContactPicker"
                  name={`${getNameOfPremises("FSPContact")}.Contact`}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotificationPortal({
                      type: "error",
                      title: "Pick FSS contact errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  placeholder="Select FSS contact"
                  onChange={handleChangeFSSContact}
                  displayValue={uiControlStore?.LitFSPContact?.Value ?? ""}
                  removeDisplayValue={removeDisplayValueFSSContact}
                  restoreDisplayValue={restoreDisplayValueFSSContact}
                  disabled={isInactive}
                  disabledButton={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Details"
                  value={
                    isHTML(fssContactDisplay)
                      ? sanitizeHtml(fssContactDisplay)
                      : fssContactDisplay
                  }
                />
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            {isFastChoice && (
              <div className="cc-field">
                <CCLabel title="Fast choice" isMandatory />
                <Field
                  name={getNameOfPremises("Flag_FastChoice")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_FastChoice"))}
                  validator={requiredValidator}
                />
              </div>
            )}

            {valueGetter(getNameOfPremises("PremisesType"))?.Flag_EnableFSS &&
              isFSSRequired && (
                <div className="cc-field">
                  <CCLabel title="FSS required" />
                  <Field
                    name={getNameOfPremises("Flag_FSSRequired")}
                    disabled={isInactive}
                    component={CCSwitch}
                    checked={valueGetter(getNameOfPremises("Flag_FSSRequired"))}
                  />
                </div>
              )}
          </div>

          {isFlagFSPTrue && (
            <div className="cc-field cc-col-span-2">
              <CCLabel
                title="FSP template"
                isMandatory={
                  fsrBusinessClassENUM === FSR_BUSINESSCLASSIFICATION.CLASS_1 ||
                  fsrBusinessClassENUM === FSR_BUSINESSCLASSIFICATION.CLASS_2
                }
              />
              <Field
                name={getNameOfPremises("FSPTemplate_KWD")}
                component={CCSearchComboBox}
                data={premisesGeneralFormLovs?.FSPTemplate ?? []}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                isUseDefaultOnchange
                disabled={isInactive}
                validator={
                  fsrBusinessClassENUM === FSR_BUSINESSCLASSIFICATION.CLASS_1 ||
                  fsrBusinessClassENUM === FSR_BUSINESSCLASSIFICATION.CLASS_2
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}

          <div className="cc-form-cols-3">
            {isShowDivNoUnits && (
              <div className="cc-field">
                <CCLabel
                  title={noOfUnitsLabel}
                  isMandatory={premisesType?.Flag_CoolingTower ?? false}
                />
                <Field
                  name={getNameOfPremises("NoOfUnits")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                  validator={
                    premisesType?.Flag_CoolingTower
                      ? requiredValidator
                      : undefined
                  }
                  min={0}
                />
              </div>
            )}
            {isShowDivNoEmployees && (
              <div className="cc-field">
                <CCLabel
                  title={noOfEmployeesLabel}
                  isMandatory={premisesType?.Flag_CoolingTower ?? false}
                />
                <Field
                  name={getNameOfPremises("NoOfEmployees")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                  validator={
                    premisesType?.Flag_CoolingTower
                      ? requiredValidator
                      : undefined
                  }
                  min={0}
                />
              </div>
            )}
          </div>

          {isShowDivPCBA && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title={pcbaLabel ?? "PCBA"} />
                <Field
                  disabled={isInactive}
                  name={getNameOfPremises("_PCAndBACategories")}
                  textField={nameOfKeyValuePair("Value")}
                  dataItemKey={nameOfKeyValuePair("Key")}
                  data={lovs?.PCBA}
                  component={CCMultiSelectDropdown}
                  onChange={handleOnChangePCBACategories}
                />
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            {valueGetter(getNameOfPremises("PremisesType"))
              ?.Flag_DisplayProprietorAddress &&
              isShowProprietorAddress && (
                <>
                  <div className="cc-field">
                    <CCLabel title={alternateAddressLabel} />
                    <Field
                      uniqueKey="HMPremisesAlternateAddress"
                      name={`${getNameOfPremises(
                        "Proprietor"
                      )}.Contact.AlternateAddress`}
                      component={PropertyDetail}
                      isShowFullResultSearch
                      isHaveStreetLookup
                      formRenderProps={formRenderProps}
                      isSearchPropertyAddresses
                      initialData={initialBuildAddress}
                      placeholder="Select location"
                      onButtonClick={handleOpenAlternateAddressDialog}
                      onBeforeOpenDialog={handleOpenAlternateAddressDialog}
                      onChangeEventHandler={handleChangeAlternateAddress}
                      onSubmit={handleChangeAlternateAddress}
                      removeDisplayValue={removeDisplayValueAlternateAddress}
                      restoreDisplayValue={restoreDisplayValueAlternateAddress}
                      value={
                        uiControlStore?.LitProprietorResidentialAddress
                          ?.Value || ""
                      }
                      updateSaveTriggers={() => {
                        updateSaveTriggers(
                          PremisesUpdateTriggers.UpdateProprietor
                        );
                      }}
                      onError={(error: any) => {
                        pushNotificationPortal({
                          type: "error",
                          title: `Select ${alternateAddressLabel} failed.`,
                          description: error,
                          autoClose: false,
                        });
                      }}
                      forceIsUseSimpleAddressDialog={isUseSimpleAddressDialogHM}
                      disabled={isAlternateAddressDisabled}
                      disabledButton={isAlternateAddressDisabled}
                    />
                  </div>
                  {/* <div className="cc-field"> CHM-405 - Remove Assessment number
                    <CCValueField
                      label="Assessment number"
                      value={
                        valueGetter(getNameOfPremises("Proprietor"))?.Contact
                          ?.AlternateAddress?.AssessmentNo ?? ""
                      }
                    />
                  </div> */}
                </>
              )}
          </div>

          {/* <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCValueField
                label="Assessment number"
                value={
                  valueGetter(getNameOfPremises("SiteAddress"))?.AssessmentNo ??
                  ""
                }
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCValueField
                label="Assessment number"
                value={
                  valueGetter(getNameOfPremises("PostalAddress"))
                    ?.AssessmentNo ?? ""
                }
              />
            </div>
          </div> */}

          {isShowMultiTenancy && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Is multi tenancy?" />
                <Field
                  name={getNameOfPremises("Flag_MultiTenancy")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_MultiTenancy"))}
                />
              </div>
              {valueGetter(getNameOfPremises("Flag_MultiTenancy")) && (
                <>
                  <div className="cc-field">
                    <CCLabel title="Property owner" isMandatory />
                    <Field
                      uniqueKey="HMPremisesPropertyOwnerContactPicker"
                      component={InputPickerSearch}
                      name={getNameOfPremises("MultiTenancyRegister")}
                      disabled={isInactive}
                      value={uiControlStore?.LitMultiTenancy?.Value ?? ""}
                      options={propertyOwnerOptions}
                      onChange={handleOnChangeOwnerProperty}
                      removeDisplayValue={removeDisplayValuePropertyOwner}
                      restoreDisplayValue={restoreDisplayValuePropertyOwner}
                      validator={requiredValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Organisation"
                      value={
                        isHTML(propertyOwnerOrganisationDisplay)
                          ? sanitizeHtml(propertyOwnerOrganisationDisplay)
                          : propertyOwnerOrganisationDisplay
                      }
                    />
                  </div>
                </>
              )}

              <div className="cc-field">
                <CCLabel title="Is DoE?" />
                <Field
                  name={getNameOfPremises("Flag_DeptOfEducation")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(
                    getNameOfPremises("Flag_DeptOfEducation")
                  )}
                />
              </div>
            </div>
          )}

          {isShowScoresEndorse && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title="Scores on doors certificate issued"
                  isMandatory
                />
                <Field
                  name={getNameOfPremises("Flag_ScoresEndorse")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_ScoresEndorse"))}
                  validator={requiredValidator}
                />
              </div>
            </div>
          )}
        </section>
      </>
    );
    // #endregion UI
  }
);
