import { IRejectApplicationDialogData } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reject-application/model";
import { nameOfFactory } from "@common/utils/common";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React from "react";

interface IRejectApplicationDialogProps {
  onClose: () => void;
  onSubmit: (value: IRejectApplicationDialogData) => void;
  isSubmitLoading: boolean;
  notifications?: IAppNotificationItem[];
}

const nameOf = nameOfFactory<IRejectApplicationDialogData>();

export const RejectApplicationDialog = ({
  onClose,
  onSubmit,
  isSubmitLoading,
  notifications,
}: IRejectApplicationDialogProps) => {
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit(event.values as IRejectApplicationDialogData);
  };
  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="25%"
              titleHeader={"Reject Application"}
              onClose={onClose}
              disabled={isSubmitLoading}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification defaultNotifications={notifications} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Comments</label>
                        <Field
                          name={nameOf("Comment")}
                          component={CCTextArea}
                          rows={3}
                          placeholder="Comments"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!formRenderProps.valid}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isSubmitLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
