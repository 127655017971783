import { DTO_Parcel_Classification } from "@app/products/property/parcels/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getParcelClassification = async (parcelId: number) => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Parcel_Classification>(
        `/api/property/internal/parcel/${parcelId}/classification`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
