import { mockSingleBuildingEnforcement } from "@app/products/building/registers/enforcements/[id]/mock";
import { IBuildingRegisterEnforcement } from "@app/products/building/registers/enforcements/[id]/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getEnforcementById = async (
  id: number | string
): Promise<IBuildingRegisterEnforcement> => {
  await sleep(1000);
  const response = cloneDeep(mockSingleBuildingEnforcement);
  return response;
};
