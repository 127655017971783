import { colDeferredDutyCaculate } from "@app/products/property/deferred-duty/components/action-bar/dialogs/calculate-instalment/config";
import { mockCalculateInstalment } from "@app/products/property/deferred-duty/components/action-bar/dialogs/calculate-instalment/mock";
import { DTO_DeferredDuty_Account_Instalment } from "@app/products/property/deferred-duty/components/action-bar/dialogs/calculate-instalment/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory, sleep } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import "./_index.scss";

interface ICalculateInstalmentDialogProps {
  onClose: () => void;
}

const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Instalment>();
export const CalculateInstalmentDialog = ({
  onClose,
}: ICalculateInstalmentDialogProps) => {
  const [deferredDutyCaculateGrid, setDeferredDutyCaculate] = useState<
    DTO_DeferredDuty_Account_Instalment[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        const { onChange } = formRenderProps;
        const handleCalculate = async () => {
          //@TODO integrate adpi later
          setIsLoading(true);
          await sleep(2000);
          setDeferredDutyCaculate(mockCalculateInstalment);
          onChange("TotalNumberOfInstalments", {
            value: mockCalculateInstalment.length,
          });
          onChange("RemainingInstalments", {
            value: 54894,
          });
          notificationRef.current?.pushNotification({
            title: `Calculated successfully`,
            type: "success",
          });
          setIsLoading(false);
        };
        return (
          <CCDialog
            onClose={onClose}
            maxWidth="55%"
            maxHeight="55%"
            titleHeader="Calculate Instalment"
            bodyElement={
              <div className="cc-form cc-caculate-grid-dialog">
                <FormElement
                  onSubmit={formRenderProps.onSubmit}
                  className="cc-field-group cc-grid-dialog-content"
                >
                  <section className="cc-field-group cc-dialog-input">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <label className="cc-label">Next instalment date</label>
                        <Field
                          name={"NextInstalmentDate"}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Final instalment due by
                        </label>
                        <Field
                          name={"FinalInstalmentDueBy"}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Calculate or recalculate instalments
                        </label>
                        <div>
                          <Button
                            iconClass={
                              isLoading ? "fas fa-spinner fa-spin" : ""
                            }
                            type="button"
                            disabled={isLoading}
                            onClick={handleCalculate}
                          >
                            Calculate
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <label className="cc-label">
                          Total number of instalments
                        </label>
                        <Field
                          name={"TotalNumberOfInstalments"}
                          placeholder={"Total number of instalments"}
                          component={CCInput}
                          format={NUMBER_FORMAT.NUMBER2}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Remaining instalments
                        </label>
                        <Field
                          name={"RemainingInstalments"}
                          placeholder={"Remaining instalments"}
                          component={CCInput}
                          format={NUMBER_FORMAT.NUMBER2}
                          readOnly
                        />
                      </div>
                    </div>
                  </section>

                  <section className="cc-grid-list">
                    <CCGrid
                      data={deferredDutyCaculateGrid}
                      columnFields={colDeferredDutyCaculate}
                      primaryField={nameOf("Id")}
                      isLoading={isLoading}
                    />
                  </section>
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Close
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
