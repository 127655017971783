export const mockListAll = [
  {
    ID: 1,
    AssessmentNumber: 678678,
    Date: "9-May-2010 12:48",
    PeriodPaymentAmount: 328.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 1184.95,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Morris Mrs JC",
    PropertyAddress: "30 Aidens Cres, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 397,
  },
  {
    ID: 2,
    AssessmentNumber: 678678,
    Date: "9-May-2010 12:51",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 1184.95,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "9-May-2010 12:55",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest on Overdue instalments",
    ApplicantName: "Morris Mrs 3 C",
    PropertyAddress: "30 Aidens Cres, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 397,
  },
  {
    ID: 3,
    AssessmentNumber: 678537,
    Date: "18-Apr-2010 00:00",
    PeriodPaymentAmount: 1261.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2521.92,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Quinn Mr BD & Mrs K A",
    PropertyAddress: "18 Agnes St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 247,
  },
  {
    ID: 4,
    AssessmentNumber: 678537,
    Date: "16-May-2010 13:41",
    PeriodPaymentAmount: 761.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2521.92,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Quinn Mr B D & Mrs K A",
    PropertyAddress: "18 Agnes St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 247,
  },
  {
    ID: 5,
    AssessmentNumber: 678537,
    Date: "18-Apr-2010 00:00",
    PeriodPaymentAmount: 1000.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2521.92,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "16-May-2010 14:16",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Quinn Mr BD & Mrs K A",
    PropertyAddress: "18 Agnes St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 247,
  },
  {
    ID: 6,
    AssessmentNumber: 678682,
    Date: "4-Jul-2010 09:42",
    PeriodPaymentAmount: 300.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 3508.05,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "4-Jul-2010 09:51",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Ms J Gillies",
    PropertyAddress: "122-132 Aidens Cres, Archies Cree!",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 401,
  },
  {
    ID: 7,
    AssessmentNumber: 678413,
    Date: "5-Jul-2010 10:48",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 1089.87,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "5-Jul-2010 10:52",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Replaced",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Turner Mrs",
    PropertyAddress: "256 Aclare St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 134,
  },
  {
    ID: 8,
    AssessmentNumber: 678413,
    Date: "S-lul-2010 10:53",
    PeriodPaymentAmount: 178.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 1089.87,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "5-Jul-2010 10:56",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Tuer Mrs S",
    PropertyAddress: "256 Aclare St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 134,
  },
  {
    ID: 9,
    AssessmentNumber: 679292,
    Date: "22-Jul-2010 00:00",
    PeriodPaymentAmount: 855.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2566.09,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Anderson Mr S R.",
    PropertyAddress: "240 Collingwood St, Cape Woolamai",
    Comments:
      "Email recd 22 July 2006 requesting 3 equal monthly instalments of $500.00 to pay off the arrears only",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 968,
  },
  {
    ID: 10,
    AssessmentNumber: 679292,
    Date: "22-Jul-2010 00:00",
    PeriodPaymentAmount: 500.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2566.09,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Cancelled",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Anderson Mr S R.",
    PropertyAddress: "240 Collingwood St, Cape Woolamai",
    Comments:
      "Wants to pay 3 x $500.00 to pay off arrears commencing 1st August 2006",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 968,
  },
  {
    ID: 11,
    AssessmentNumber: 680346,
    Date: "24-Aug-2010 14:09",
    PeriodPaymentAmount: 464.5,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 4180.5,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mr G D Wright & Ms LR McPherson",
    PropertyAddress: "295 Barbara Rd, Cape Paterson",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2002,
  },
  {
    ID: 12,
    AssessmentNumber: 3028210,
    Date: "22-Jun-2011 11:12",
    PeriodPaymentAmount: 50.0,
    PaymentFrequency: "Weekly",
    InitDebtAmount: 765.92,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "26-Jun-2011 09:44",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest on Overdue instalments",
    ApplicantName: "Nguyen Miss Q",
    PropertyAddress: "103 Bower Rd, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 5130,
  },
  {
    ID: 13,
    AssessmentNumber: 678998,
    Date: "13-Jul-2011 12:08",
    PeriodPaymentAmount: 372.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 871.62,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "13-Jul-2011 12:13",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest on Overdue instalments",
    ApplicantName: "Mr V V CHANDRA",
    PropertyAddress: "237 Albert Rd, Archies Creek",
    Comments:
      "Adhoc arrangement for $500 one week after the intial part-payment and then the balance",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 717,
  },
  {
    ID: 14,
    AssessmentNumber: 678420,
    Date: "19-Jul-2011 16:41",
    PeriodPaymentAmount: 864.0,
    PaymentFrequency: "Custom,",
    InitDebtAmount: 2063.16,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "19-Jul-2011 16:45",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Nefusi Pty Utd",
    PropertyAddress: "U 1/10 Aclare St, Archies Creek",
    Comments: "Ad Hoc arrangement for the arrears amount only",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 141,
  },
  {
    ID: 15,
    AssessmentNumber: 681098,
    Date: "14-Aug-2011 14:40",
    PeriodPaymentAmount: 250.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 5167.56,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "4-Oct-2011 08:55",
    LastLetterType: "Cancellation of Arrangement 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Ms P Hurst & Ms T J Walsh",
    PropertyAddress: "Beasleys St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2654,
  },
  {
    ID: 16,
    AssessmentNumber: 682691,
    Date: "4-Mar-2012 09:36",
    PeriodPaymentAmount: 595.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 1190.0,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Paid Out",
    InterestStatus: "Never Raise Interest",
    ApplicantName: "Pamora Enterprises Pty Ltd",
    PropertyAddress: "24-6 Bower Rd, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 4083,
  },
  {
    ID: 17,
    AssessmentNumber: 679326,
    Date: "11-Mar-2012 13:56",
    PeriodPaymentAmount: 50.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 891.53,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "11-Mar-2012 13:59",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mr K J & Ms K K & Mr SJ  & Ms S Ra",
    PropertyAddress: "8 Amphlett St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 1002,
  },
  {
    ID: 18,
    AssessmentNumber: 680598,
    Date: "11-Mar-2012 14:27",
    PeriodPaymentAmount: 1134.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 4934.42,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mr J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    Comments: "$400 PER MONTH AS PER ARRANGMENT TO PAY FORM ON PROPERTY FILE.",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2197,
  },
  {
    ID: 19,
    AssessmentNumber: 680598,
    Date: "11-Mar-2012 14:28",
    PeriodPaymentAmount: 400.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 4934.42,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "11-Mar-2012 14:31",
    LastLetterType: "Arrangement Letter 2007/08",
    Status: "Replaced",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mr J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2197,
  },
  {
    ID: 20,
    AssessmentNumber: 680598,
    Date: "15-May-2012 10:19",
    PeriodPaymentAmount: 2087.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 4574.52,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mr J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2197,
  },
  {
    ID: 21,
    AssessmentNumber: 680598,
    Date: "15-May-2012 10:19",
    PeriodPaymentAmount: 400.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 4574.52,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "16-Jun-2013 10:26",
    LastLetterType: "Multiple Payments in Arrears 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest on Overdue instalments",
    ApplicantName: "Mr J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2197,
  },
  {
    ID: 22,
    AssessmentNumber: 680334,
    Date: "21-May-2012 15:08",
    PeriodPaymentAmount: 400.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 1451.13,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "24-Jun-2012 09:39",
    LastLetterType: "Single Payment in Arrears 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mr J Icao & Mrs E Tanquiamco-Icao",
    PropertyAddress: "21 Barbara Rd, Cape Paterson",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 1990,
  },
  {
    ID: 23,
    AssessmentNumber: 678413,
    Date: "3-Jun-2012 17:03",
    PeriodPaymentAmount: 103.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 1546.49,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mr S Turner",
    PropertyAddress: "256 Aclare St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 134,
  },
  {
    ID: 24,
    AssessmentNumber: 678413,
    Date: "3-Jun-2012 17:06",
    PeriodPaymentAmount: 100.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 1546.49,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "16-Jul-2012 10:45",
    LastLetterType: "Multiple Payments in Arrears 2007/08",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mr S Turner",
    PropertyAddress: "256 Aclare St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 134,
  },
  {
    ID: 25,
    AssessmentNumber: 681060,
    Date: "30-Sep-2012 12:26",
    PeriodPaymentAmount: 323.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2909.03,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2616,
  },
  {
    ID: 26,
    AssessmentNumber: 679810,
    Date: "22-Oct-2012 10:57",
    PeriodPaymentAmount: 438.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 3062.67,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Cancelled",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mr A & Mrs DM Kingston",
    PropertyAddress: "375 Atkins St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 1464,
  },
  {
    ID: 27,
    AssessmentNumber: 679810,
    Date: "22-Oct-2012 11:01",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 3062.67,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "2-Feb-2013 11:00",
    LastLetterType: "Single Payment in Arrears 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest on Overdue instalments",
    ApplicantName: "Mr A & Mrs DM Kingston",
    PropertyAddress: "375 Atkins St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 1464,
  },
  {
    ID: 28,
    AssessmentNumber: 682850,
    Date: "27-Nov-2012 14:57",
    PeriodPaymentAmount: 50.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2058.5,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "27-Nov-2012 14:59",
    LastLetterType: "Arrangement Letter 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mrs E E Whelan",
    PropertyAddress: "4 Bromby Ave, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 4227,
  },
  {
    ID: 29,
    AssessmentNumber: 681774,
    Date: "8-Dec-2012 16:36",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2313.72,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "8-Dec-2012 16:38",
    LastLetterType: "Arrangement Letter 2008/09",
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Miss C T Downing",
    PropertyAddress: "9 Berry St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3209,
  },
  {
    ID: 30,
    AssessmentNumber: 680959,
    Date: "15-Dec-2012 15:19",
    PeriodPaymentAmount: 749.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 3445.73,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir N & Mrs V L Easton",
    PropertyAddress: "2 Mars St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2533,
  },
  {
    ID: 31,
    AssessmentNumber: 678671,
    Date: "23-Dec-2012 13:57",
    PeriodPaymentAmount: 419.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 2195.47,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "23-Dec-2012 14:20",
    LastLetterType: "Arrangement Letter 2008/09",
    Status: "Replaced",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir E Papageorgiou",
    PropertyAddress: "181 Aidens Cres, Archies Creek",
    Comments: "Ad hoc arrangement as per information emailed from Legal Force.",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 388,
  },
  {
    ID: 32,
    AssessmentNumber: 678671,
    Date: "23-Dec-2012 14:21",
    PeriodPaymentAmount: 419.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 2195.47,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "23-Dec-2012 14:52",
    LastLetterType: "Arrangement Letter 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir E Papageorgiou",
    PropertyAddress: "181 Aidens Cres, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 388,
  },
  {
    ID: 33,
    AssessmentNumber: 682583,
    Date: "SeJan-2013 12:45",
    PeriodPaymentAmount: 482.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 2526.02,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "13-Jan-2013 09:32",
    LastLetterType: "Single Payment in Arrears 2008/09",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Miss G & Miss G & Miss S Lahoud",
    PropertyAddress: "20 Bourkes Creek St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3966,
  },
  {
    ID: 34,
    AssessmentNumber: 682583,
    Date: "14-Jan-2013 17:54",
    PeriodPaymentAmount: 482.0,
    PaymentFrequency: "Weekly",
    InitDebtAmount: 2541.36,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "14-Jan-2018 17:56",
    LastLetterType: "Arrangement Letter 2008/09",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Miss G & Miss G & Miss S Lahoud",
    PropertyAddress: "20 Bourkes Creek St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3966,
  },
  {
    ID: 35,
    AssessmentNumber: 682287,
    Date: "23-Jan-2013 09:29",
    PeriodPaymentAmount: 500.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 11232.73,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "12-May-2013 09:57",
    LastLetterType: "Multiple Payments in Arrears 2008/09",
    Status: "Cancelled",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mrs M V Clayton",
    PropertyAddress: "775-910 Bluebird St, Anderson",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3680,
  },
  {
    ID: 36,
    AssessmentNumber: 680959,
    Date: "2-Feb-2013 15:14",
    PeriodPaymentAmount: 329.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 2257.45,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir N & Mrs V L Easton,",
    PropertyAddress: "52 Mars St, Almurta",
    Comments:
      "Needs to change extension of time on the overdue amount from end of January 2009 till 16 February 2009. will then attempt to pay on the normal instalment plan. ie Feb & May",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2533,
  },
  {
    ID: 37,
    AssessmentNumber: 679546,
    Date: "12-Feb-2013 15:35",
    PeriodPaymentAmount: 100.0,
    PaymentFrequency: "Weekly",
    InitDebtAmount: 5102.9,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "11-May-2013 11:14",
    LastLetterType: "Multiple Payments in Arrears 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir J R & Mrs J Woodward",
    PropertyAddress: "5 Archer St, Archies Creek",
    Comments: "$100 per week atp signed 28/1/09. On file.",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 1212,
  },
  {
    ID: 38,
    AssessmentNumber: 681273,
    Date: "19-Feb-2013 14:27",
    PeriodPaymentAmount: 400.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2654.91,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "30-Apr-2013 13:09",
    LastLetterType: "Multiple Payments in Arrears 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mr D S Salter",
    PropertyAddress: "3 Beenak East St, Archies Creek",
    Comments:
      "Will pay $400 per month on 6th each month until July 2009, and then review once new rates have levied.",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2830,
  },
  {
    ID: 39,
    AssessmentNumber: 2972159,
    Date: "11-May-2013 14:38",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 824.09,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "16-Jun-2013 10:21",
    LastLetterType: "Single Payment in Arrears 2008/09",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir A & Mrs A italiani",
    PropertyAddress: "1 Bass Ave, Bass",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 5031,
  },
  {
    ID: 40,
    AssessmentNumber: 680582,
    Date: "11-May-2013 14:46",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 1894.71,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "6-Jan-2014 1647",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir A & Mrs A italiani",
    PropertyAddress: "147 Bass Ave, Bass",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 4719,
  },
  {
    ID: 41,
    AssessmentNumber: 682523,
    Date: "12-May-2013 15:17",
    PeriodPaymentAmount: 40.0,
    PaymentFrequency: "Weekly",
    InitDebtAmount: 998.1,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "12-May-2013 15:20",
    LastLetterType: "Arrangement Letter 2008/09",
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mis J E Hirsh",
    PropertyAddress: "1 Tiree Ave, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3911,
  },
  {
    ID: 42,
    AssessmentNumber: 681060,
    Date: "22-Jun-2013 13:13",
    PeriodPaymentAmount: 2905.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 3064.5,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Replaced",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mrs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2616,
  },
  {
    ID: 43,
    AssessmentNumber: 681060,
    Date: "22-Jun-2013 13:14",
    PeriodPaymentAmount: 160.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 3064.5,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "19-Aug-2013 14:31",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mirs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2616,
  },
  {
    ID: 44,
    AssessmentNumber: 681946,
    Date: "15-Jun-2013 11:17",
    PeriodPaymentAmount: 200.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 904.58,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "20-Jul-2013 15:17",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mir S J EDWARDS & Ms K CUMMING",
    PropertyAddress: "30 Buckleigh Lane, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3828,
  },
  {
    ID: 45,
    AssessmentNumber: 680716,
    Date: "16-Jun-2013 16:44",
    PeriodPaymentAmount: 50.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 839.36,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "28-Jul-2013 15:57",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mis G Curtis",
    PropertyAddress: "310 Luke St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2287,
  },
  {
    ID: 46,
    AssessmentNumber: 679326,
    Date: "5-Jul-2013 15:34",
    PeriodPaymentAmount: 25.0,
    PaymentFrequency: "Weekly",
    InitDebtAmount: 1673.95,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "16-Nov-2013 17:07",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mir K J & Ms KK & MrK S J & Ms S Rao",
    PropertyAddress: "8 Amphlett St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 1002,
  },
  {
    ID: 47,
    AssessmentNumber: 680598,
    Date: "26-Aug-2013 15:31",
    PeriodPaymentAmount: 500.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 5235.54,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "8-Jun-2014 17:33",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mir J C Panetta",
    PropertyAddress: "23 Bastin St, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2197,
  },
  {
    ID: 48,
    AssessmentNumber: 2990389,
    Date: "26-Aug-2013 17:15",
    PeriodPaymentAmount: 100.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2793.88,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "12-May-2014 09:51",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mir A G Philips",
    PropertyAddress: "124 Blackwood Rd, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 5000,
  },
  {
    ID: 49,
    AssessmentNumber: 682583,
    Date: "31-Aug-2013 10:38",
    PeriodPaymentAmount: 500.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 2793.7,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "19-Nov-2013 12:16",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Miss G & Miss G & Miss S Lahoud",
    PropertyAddress: "20 Bourkes Creek St, Almurta",
    Comments:
      "Arrangement to Pay form completed by Gamal Lahoud on 28 August 2009. $1,000 paid on that same date.",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3966,
  },
  {
    ID: 50,
    AssessmentNumber: 682120,
    Date: "13-Oct-2013 14:04",
    PeriodPaymentAmount: 50.0,
    PaymentFrequency: "Monthly",
    InitDebtAmount: 2176.15,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "14-Oct-2013 09:58",
    LastLetterType: "Arrangement Letter 2009/10",
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mis S C Catt",
    PropertyAddress: "71 Blackwood Rd, Archies Creek",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 3514,
  },
  {
    ID: 51,
    AssessmentNumber: 681060,
    Date: "3-Nov-2013 12:31",
    PeriodPaymentAmount: 100.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 4978.95,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "7-Jan-2015 10:36",
    LastLetterType: "Multiple Payments in Arrears 2009/10",
    Status: "Paid Out",
    InterestStatus: "Raise Interest based on Original Charge",
    ApplicantName: "Mirs A Von Plomgren",
    PropertyAddress: "1 Beasleys St, Archies Creek",
    Comments: "Instalment order from the court - $100 per fortnight",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2616,
  },
  {
    ID: 52,
    AssessmentNumber: 680716,
    Date: "3-Nov-2013 16:54",
    PeriodPaymentAmount: 50.0,
    PaymentFrequency: "Fortnightly",
    InitDebtAmount: 1720.47,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mrs G Curtis",
    PropertyAddress: "310 Luke St, Archies Creek",
    Comments:
      "restart arrangement, following discovery of payments were bpay to an old assessment number. corrections journalled october 2009",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2287,
  },
  {
    ID: 53,
    AssessmentNumber: 681157,
    Date: "6-Nov-2013 12:34",
    PeriodPaymentAmount: 500.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 1726.47,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    Status: "Paid Out",
    InterestStatus: "Determined by Arrangement Status",
    ApplicantName: "Mir P J Shorten & Mrs M P Crocker",
    PropertyAddress: "55 Beatties St, Almurta",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 2712,
  },
  {
    ID: 54,
    AssessmentNumber: 682825,
    Date: "30-Nov-2013 16:15",
    PeriodPaymentAmount: 510.0,
    PaymentFrequency: "Custom",
    InitDebtAmount: 3060.0,
    Balance: 0.0,
    OverdueAmount: 0.0,
    NoOfOverdueInstalments: 0,
    LastLetterProducedOn: "6-Nov-2013 12:37",
    LastLetterType: "Arrangement Letter 2009/10",
    Status: "Cancelled",
    InterestStatus: "Determined by-Arrangement Status",
    ApplicantName: "Me M Ziviani",
    PropertyAddress: "8 Rrodie St Anderson",
    AuthorisingOfficer: "Barbara HOGAN",
    AssessmentId: 4202,
  },
];
