import { DTO_Workflow_RaiseDeferredDutyInterest } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewRaiseDeferredDutyInterest = async (
  accountID?: number
): Promise<APIResponse<DTO_Workflow_RaiseDeferredDutyInterest>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutyraiseinterest/new`,
      accountID
        ? {
            DDAccountId: accountID,
          }
        : {}
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postRaiseDeferredDutyInterest = async (
  mode: WorkflowProcessMode,
  raiseDeferredDutyInterest: DTO_Workflow_RaiseDeferredDutyInterest | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_RaiseDeferredDutyInterest>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutyraiseinterest/process/${mode}`,
      raiseDeferredDutyInterest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getRaiseDeferredDutyInteresDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_RaiseDeferredDutyInterest>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/deferreddutyraiseinterest/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataDeferredDuty =
  | APIResponse<DTO_Workflow_RaiseDeferredDutyInterest>
  | undefined;

export const getInitialDataModifyDeferredDuty = async (
  accountId?: number,
  workflowDraftId?: number
): Promise<IGetInitialDataDeferredDuty> => {
  try {
    return !isNil(workflowDraftId)
      ? await getRaiseDeferredDutyInteresDraft(workflowDraftId)
      : await postNewRaiseDeferredDutyInterest(accountId ?? 0);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
