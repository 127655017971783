import { DTO_Workflow_JournalDDWriteOffBalance } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowWriteOffBalance = async (
  transactionId?: number,
  accountId?: number
): Promise<APIResponse<DTO_Workflow_JournalDDWriteOffBalance>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/deferreddutytransactionwriteoffbalance/new`,
      {
        TransactionIds: transactionId,
        AccountId: accountId,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewWriteOffBalance = async (
  mode: WorkflowProcessMode,
  deferredDuty: DTO_Workflow_JournalDDWriteOffBalance | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_JournalDDWriteOffBalance>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/deferreddutytransactionwriteoffbalance/process/${mode}`,
      deferredDuty
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWriteOffBalanceDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_JournalDDWriteOffBalance>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/int/workflow/deferreddutytransactionwriteoffbalance/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAssessment =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalDDWriteOffBalance>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalDDWriteOffBalance>
  | undefined;
export const getInitialDataWriteOffBalance = async (
  workflowDraftId: number | undefined,
  transactionId?: number,
  accountId?: number
): Promise<IGetInitialDataAssessment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWriteOffBalanceDraft(workflowDraftId)
        : postNewWorkflowWriteOffBalance(transactionId, accountId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
