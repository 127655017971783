import { IAssessmentMeters } from "@app/products/property/assessments/[id]/components/child-screens/meters/model";
import { useAssessmentMeterStore } from "@app/products/property/assessments/[id]/components/child-screens/meters/store";
import { loadAssessmentSpecialReadingData } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/api";
import { SelectedSecondaryWorkflow } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/transfer-meter/model";
import { TransferMeterDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/_index";
import { mockBillingGroup } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/mock";
import {
  IMeterTransferred,
  ITransferMeter,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/model";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { EnterSpecialReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/_index";
import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import ScheduleSpecialReadingsDialog from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/_index";
import { PROPERTY_METERS_SPECIAL_READING_LIST_ROUTE } from "@app/products/property/meters/special-reading-list/constant";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export const TransferButton = observer(() => {
  const [showEnterSpecialReadingDialog, setShowEnterSpecialReading] =
    useState<boolean>(false);
  const [
    showScheduleSpecialReadingsDialog,
    setShowScheduleSpecialReadingsDialog,
  ] = useState<boolean>(false);
  const [specialReadingData, setSpecialReadingData] = useState<
    ISpecialReadingDialogData | undefined
  >();
  const { assessment } = useAssessmentStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { meterSelected, meter } = useAssessmentMeterStore();
  const history = useHistory();
  const [transferringData, setTransferringData] = useState<ITransferMeter>();
  const { id } = useParams<{ id: string }>();
  const meterTransferred: IMeterTransferred[] | undefined = [];
  const defaultBilling = { Key: 1, Value: "Monthly Billing" };

  (meterSelected as IAssessmentMeters[])?.forEach((item) => {
    if (assessment) {
      meterTransferred.push({
        AssessmentNumber: assessment.Assessment.AssessmentNumber,
        PropertyAddress: assessment.Assessment.PropertyName_Address_Locality,
        MeterID: item.MeterId,
        MeterNumber: item.MeterNumber,
        Change: true,
      });
    }
  });
  const initialDataDialog = useMemo(() => {
    if (!id) {
      return {};
    } else {
      return {
        PropertyAddress: assessment?.Assessment?.PropertyName_Address_Locality,
        Assessment: {
          AssessmentNumber: assessment?.Assessment.AssessmentNumber,
          AssessmentId: id,
          _searchByAssessmentNumber: `${assessment?.Assessment.AssessmentNumber} - ${assessment?.Assessment?.PropertyName_Address_Locality}`,
          _searchByAssessmentId: `${id} - ${assessment?.Assessment?.PropertyName_Address_Locality}`,
        },
        Meters: meter,
      };
    }
  }, [id, assessment, meter]);
  const onClickTransfer = () => {
    if (assessment) {
      setTransferringData({
        AssessmentNumber: assessment.Assessment.AssessmentNumber,
        PropertyAddress: assessment.Assessment.PropertyName_Address_Locality,
        Ratepayer: assessment.Assessment.Ratepayer,
        DateOfChange: new Date(),
        MeterTransferred: meterTransferred,
        BillingGroup: defaultBilling,
        _option: {
          BillingGroup: {
            Data: mockBillingGroup,
          },
          SearchAssessment: {
            Data: [],
            Loading: false,
          },
        },
      });
    }
  };
  const handleOnSubmit = (data: any) => {
    setTransferringData(undefined);
    if (!data?.SecondaryWorkflow?.SelectedSecondaryWorkflow) {
      pushNotification({
        title: "Meter transferred successfully",
        type: "success",
      });
    }
    if (
      data?.SecondaryWorkflow?.SelectedSecondaryWorkflow ===
      SelectedSecondaryWorkflow.EnterSpecialReading
    ) {
      loadAssessmentSpecialReadingData(assessment).then(
        (response: ISpecialReadingDialogData) => {
          if (!response) return;
          setSpecialReadingData(response);
          setShowEnterSpecialReading(true);
        }
      );
    } else if (
      data?.SecondaryWorkflow?.SelectedSecondaryWorkflow ===
      SelectedSecondaryWorkflow.ScheduleASpecialReading
    ) {
      setShowScheduleSpecialReadingsDialog(true);
    }
  };
  return (
    <>
      <CCNavButton
        title="Transfer"
        disabled={meterSelected.length <= 0}
        onClick={onClickTransfer}
      />

      {transferringData && (
        <TransferMeterDialog
          transferringData={transferringData}
          onClose={() => {
            setTransferringData(undefined);
          }}
          onSubmit={(data) => handleOnSubmit(data)}
        />
      )}
      {showEnterSpecialReadingDialog && (
        <EnterSpecialReadingDialog
          onClose={() => {
            setShowEnterSpecialReading(false);
          }}
          onEnterAndExit={() => {
            setShowEnterSpecialReading(false);
            pushNotification({
              title: "Special reading entered successfully",
              type: "success",
            });
          }}
          specialReadingData={specialReadingData}
        />
      )}
      {showScheduleSpecialReadingsDialog && meter && (
        <ScheduleSpecialReadingsDialog
          onClose={() => setShowScheduleSpecialReadingsDialog(false)}
          onSubmit={() => {
            setShowScheduleSpecialReadingsDialog(false);
            history.push({
              pathname: `${PROPERTY_METERS_SPECIAL_READING_LIST_ROUTE}`,
              state: {
                notification: {
                  title: "Special readings scheduled successfully",
                  type: "success",
                },
              },
            });
          }}
          initialDataDialog={initialDataDialog}
        />
      )}
    </>
  );
});
