import { eventEmitter } from "@/App";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { ServiceStandardSubcategoriesAccordion } from "@app/products/crms/system-admin/categories/[id]/model";
import { useCRMSSytemAdminCategoryStore } from "@app/products/crms/system-admin/categories/[id]/store";
import { deleteSubCategory } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/api";
import { colSubCategoriesAccordion } from "@app/products/crms/system-admin/categories/config";
import { CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE } from "@app/products/crms/system-admin/categories/constant";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const nameOfServiceStandardSubCategory =
  nameOfFactory<ServiceStandardSubcategoriesAccordion>();

export const SubCategoryAccordion = observer(() => {
  const { isDisabled } = useCRMSSytemAdminCategoryStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { id } = useParams<{ id: string }>();

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [gridSelectedRows, setGridSelectedRows] = useState<
    ServiceStandardSubcategoriesAccordion[]
  >([]);

  const handleOnDelete = async () => {
    setIsDeleting(true);
    if (!gridSelectedRows[0]) return;
    const response = await deleteSubCategory(gridSelectedRows[0].ID);

    setIsDeleting(false);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      pushNotification({
        type: "success",
        title: "Subcategory deleted successfully",
      });

      eventEmitter.emit(CCGridEventType.RefreshOData);

      setGridSelectedRows([]);
    } else {
      pushNotification({
        type: "error",
        title: "Delete subcategory was failed.",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  const newCols = useMemo(() => {
    return colSubCategoriesAccordion.map((item) =>
      item.field === nameOfServiceStandardSubCategory("SubCategory")
        ? {
            ...item,
            linkTo: (dataItem: ServiceStandardSubcategoriesAccordion) => {
              return `${CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE}/${id}/subcategory/${dataItem.ID}`;
            },
          }
        : item
    );
  }, [id]);

  return (
    <>
      {isShowDialog && (
        <ConfirmDelete
          onClose={() => setIsShowDialog(false)}
          handleSubmit={handleOnDelete}
          isDeleting={isDeleting}
          message={"Are you sure you want to delete the subcategory?"}
          header={"Confirm Deletion"}
        />
      )}

      <CCGrid
        dataUrl={`odata/crms/internal/systemadmin/GetServiceStandardSubcategoryAccordion(serviceStandardCategoryId=${id})?$count=true&`}
        primaryField={nameOfServiceStandardSubCategory("ID")}
        columnFields={newCols}
        selectableMode="single"
        selectedRows={gridSelectedRows}
        onSelectionChange={(
          dataItem: ServiceStandardSubcategoriesAccordion[]
        ) => {
          setGridSelectedRows([...dataItem]);
        }}
        readOnly={isDisabled}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={gridSelectedRows.length === 0}
              onClick={() => {
                setIsShowDialog(true);
              }}
            />
          </div>
        }
      />
    </>
  );
});
