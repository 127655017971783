import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IOfficer>();

export const colOfficerPicker: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  { title: "Position", field: nameOf("Position") },
  { title: "Email", field: nameOf("Email") },
  { title: "Work Phone", field: nameOf("WorkPhone") },
  { title: "Mobile", field: nameOf("Mobile") },
];
