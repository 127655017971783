import { loadWasteWaterById } from "@app/products/waste-water/[id]/components/reference-sidebar/contact/api";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const WasteWaterContactTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ICommonContactItemRender[] | undefined>(
    undefined
  );
  const productListViewStore = useCCProductListViewStore();
  const { lastSelectedRow } = productListViewStore;
  const wasteWaterId: { id: string } = useParams();

  const id: string | number = lastSelectedRow?.ID || wasteWaterId.id;

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      loadWasteWaterById(id.toLocaleString.toString()).then((data) => {
        setIsLoading(false);
        if (!data) {
          setData([]);
          return;
        }
        const contactList: ICommonContactItemRender[] = [];
        const wasteWaterContact = data?.value;

        wasteWaterContact.forEach((item) => {
          contactList.push({
            ID: item?.ID,
            Type: item?.Type,
            DisplayName: item?.DisplayName,
            Email: item?.Email,
            PreferredMethod_ENUM: item?.PreferredMethod_ENUM,
            Mobile: item?.Mobile,
            WorkPhone: item?.WorkPhone,
          });
        });
        setData(contactList);
      });
    }
  }, [id]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return <ContactsSidebar data={(data as ICommonContactItemRender[]) ?? []} />;
});
