import { useBuildingRegistersStormwaterStore } from "@app/products/building/registers/stormwater/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { BuildingRegisterStormwaterFormElement } from "./components/form-element/_index";

export const BuildingRegisterStormwaterForm = observer((): ReactElement => {
  const isNew = useIsNew();
  const {
    buildingRegistersStormwater,
    createBuildingRegistersStormwater,
    updateBuildingRegistersStormwater,
  } = useBuildingRegistersStormwaterStore();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createBuildingRegistersStormwater(data);
    } else {
      updateBuildingRegistersStormwater(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={buildingRegistersStormwater}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegisterStormwaterFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
