import { odataGetEventsAccordion } from "@app/core/components/common/utils";
import { colCUSTRequestsAccordion } from "@app/products/crms/components/event-accordion/config";
import { Svc_CUSTRequestsAccordion } from "@app/products/crms/components/event-accordion/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IEventsAccordionProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<Svc_CUSTRequestsAccordion>();
export const EventsAccordion = ({ id, recordType }: IEventsAccordionProps) => {
  return (
    <CCGrid
      columnFields={colCUSTRequestsAccordion}
      primaryField={nameOf("ID")}
      dataUrl={
        id === undefined ? undefined : odataGetEventsAccordion(id, recordType)
      }
      state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
    />
  );
};
