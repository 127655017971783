export enum SearchType {
  Address = "Address",
  Name = "Name",
  LegalDescription = "Legal Description",
  ParcelId = "Parcel Id",
  ParcelReference = "Parcel Reference",
}
export enum SearchField {
  Address = "_searchByAddress",
  Name = "_searchByName",
  LegalDescription = "_searchByLegalDescription",
  ParcelId = "_searchByParcelId",
  ParcelReference = "_searchByParcelReference",
}

export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.Address]: SearchField.Address,
  [SearchType.Name]: SearchField.Name,
  [SearchType.LegalDescription]: SearchField.LegalDescription,
  [SearchType.ParcelId]: SearchField.ParcelId,
  [SearchType.ParcelReference]: SearchField.ParcelReference,
};

export interface IParcels {
  Address: string;
  Name: string;
  LegalDescription?: string;
  ParcelId?: number | null;
  ParcelReference: number | null;
}

export interface IParcelsStep {
  _option: {
    SearchParcel: {
      Data: IParcels[];
      Loading: boolean;
    };
  };
  SearchType: SearchType;
  Parcels: IParcels[];
}
