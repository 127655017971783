import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colByRefNo: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Description,
    title: "Description",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DateTarget,
    title: "Target",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Source,
    title: "Source",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Category,
    title: "Category",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Location,
    title: "Location",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.RequestedBy,
    title: "Contact",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ContactDetails,
    title: "Contact Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DueStatus,
    title: "Due Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DateRecorded,
    title: "Recorded",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.RecordedBy,
    title: "Recorded By",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.OrgStructure,
    title: "Org Structure",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ActionOfficer,
    title: "Action Officer",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Coordinator,
    title: "Coordinator",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DateWork,
    title: "Work Date",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.FileNumber,
    title: "File Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.EnquiryRefNo,
    title: "Assets Reference Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Event_ReferenceNoAdditional,
    title: "Additional Reference Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.OnBehalfOf,
    title: "On Behalf Of",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Flag_ITSupport,
    title: "IT Support",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Flag_Safety,
    title: "Safety",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Flag_Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Flag_VIP,
    title: "VIP",
    width: 150,
  },
];
