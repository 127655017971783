export const NoticeOfSaleStatus = {
  Imported: "Imported",
  InProgress: "In Progress",
  Suspended: "Suspended",
  Cancelled: "Cancelled",
  Completed: "Completed",
  AwaitingAuthorisation: "Awaiting Authorisation",
};

export const PROPERTY_NOTICE_OF_SALE_LIST_VIEW_URL = `/odata/property/internal/NoticeofsaleRegister?$count=true&`;
