import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary_Levy_Calculation>();
export const colLevyOldCharges: IColumnFields[] = [
  {
    field: nameOf("Levy_Category"),
    title: "Levy Category",
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("SAL_Old_Active_From_Date"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Percentage"),
    title: "Percentage of Levy",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("SLC_Old_Perc_of_Rating_Period"),
    title: "Percentage of Period",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Old_Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Old_Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Levy_Rate"),
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL_6_DIGIT,
  },
  {
    field: nameOf("SLC_Old_X_Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Old_RateByValue_Amount"),
    title: "Rate by Valn Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Diff_Increment_to_Minimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Diff_Reduction_to_Maximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Diff_Capping_Adjustment"),
    title: "Capping Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Old_X_Gross_Amount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Old_X_Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Old_X_Nett_Amount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Levy_Id"),
    title: "Assessment Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Supplementary_Assessment_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colLevyNewCharges: IColumnFields[] = [
  {
    field: nameOf("Levy_Category"),
    title: "Levy Category",
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("SAL_New_Active_From_Date"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Percentage"),
    title: "Percentage of Levy",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("SLC_New_Perc_of_Rating_Period"),
    title: "Percentage of Period",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("New_Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_New_Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Levy_Rate"),
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL_2_DIGIT,
  },
  {
    field: nameOf("SLC_New_X_Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_New_RateByValue_Amount"),
    title: "Rate by Valn Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Diff_Increment_to_Minimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Diff_Reduction_to_Maximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Diff_Capping_Adjustment"),
    title: "Capping Adjustment",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_New_X_Gross_Amount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_New_X_Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_New_X_Nett_Amount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Levy_Id"),
    title: "Assessment Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Supplementary_Assessment_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colLevyAdjustment: IColumnFields[] = [
  {
    field: nameOf("Levy_Category"),
    title: "Levy Category",
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("SLC_Supp_Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Supp_Gross_Amount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Supp_Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SLC_Supp_Nett_Amount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Levy_Id"),
    title: "Assessment Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Supplementary_Assessment_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
