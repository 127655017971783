import { HMPremiseRoute } from "@app/products/hm/model";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import { LineStyle } from "@progress/kendo-react-charts/dist/npm/field-types/line-style";
import React from "react";
import { useHistory } from "react-router-dom";

interface IHMComplaintsReceivedInsightProps {
  complaintsReceived: any;
  queryDateTime: any;
}

const HMComplaintsReceivedInsight = (
  complaintsReceivedData: IHMComplaintsReceivedInsightProps
) => {
  const history = useHistory();

  const ComplaintsReceivedChart = () => {
    return (
      <React.Fragment>
        <Chart style={{ height: 250 }}>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              title={{ text: "Date" }}
              categories={complaintsReceivedData.complaintsReceived.xData}
            />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="line"
              data={complaintsReceivedData.complaintsReceived.yData}
              style={"normal" as LineStyle}
              markers={{ visible: true }}
            />
          </ChartSeries>
        </Chart>
      </React.Fragment>
    );
  };

  return (
    <div
      className="cc-insight-group"
      onClick={(e) => {
        history.push(HMPremiseRoute.HM_PREMISES_REGISTER);
      }}
    >
      <div className="cc-group-name">
        {getProductTitle(PRODUCT_TYPE.HealthManager)}
      </div>
      <h4>Complaints Received</h4>
      <div className="cc-date-time-description">
        as of {complaintsReceivedData.queryDateTime}
      </div>
      <br />
      <ComplaintsReceivedChart />
    </div>
  );
};
export default HMComplaintsReceivedInsight;
