import { CCDatePickerEditCell } from "@app/products/property/components/grid/components/cc-date-picker-edit-cell/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCTooltip } from "@components/cc-tooltip/_index";
import React from "react";
import "./_index.scss";

export const DatePresentedEditCell = (props: ICustomEditCell) => {
  return (
    <div className="cc-date-edit-cell-group">
      <CCDatePickerEditCell
        className="cc-date-edit-cell"
        max={new Date()}
        {...props}
      />
      <CCTooltip content="Date presented is a past date." position="right" />
    </div>
  );
};
