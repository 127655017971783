import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ProcessPoolTabDetails } from "@common/pages/settings/system-admin/job-queue/model";

export const getJobQueueDetailsTabById = async (
  jobQueueId: number
): Promise<APIResponse<ProcessPoolTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ProcessPoolTabDetails>(
      `/api/core/internal/processpool/${jobQueueId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
