import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { colPprSystemAdminRegions } from "@app/products/town-planning/ppr/system-admin/regions/config";
import { TOWN_PLANNING_PPR_REGIONS_LIST_VIEW_URL } from "@app/products/town-planning/ppr/system-admin/regions/contants";
import { PPRRegion } from "@app/products/town-planning/ppr/system-admin/regions/model";
import { NewRegionButton } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/action-bar/buttons/new-region/_index";
import { RegionDetailTab } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/reference-sidebar/detail/_index";
import { RegionHistoryTab } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/reference-sidebar/history/_index";
import { SYSTEM_ADMIN_REGION_ROUTE } from "@app/products/town-planning/ppr/system-admin/regions/[id]/constant";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

const nameOf = nameOfFactory<PPRRegion>();

export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [<NewRegionButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={SYSTEM_ADMIN_REGION_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.CORE_Region}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <RegionDetailTab /> },
      {
        title: "History",
        component: <RegionHistoryTab />,
      },
    ],
  });

  return (
    <>
      <CCProductListView
        columnFields={colPprSystemAdminRegions}
        dataUrl={TOWN_PLANNING_PPR_REGIONS_LIST_VIEW_URL}
        state={{
          group: [{ field: nameOf("Region_Name"), dir: "asc" }],
          sort: [
            { field: nameOf("Region_Name"), dir: "asc" },
            { field: nameOf("Suburb"), dir: "asc" },
            { field: nameOf("PostCode"), dir: "asc" },
          ],
        }}
        primaryField={nameOf("Region_ID")}
      />
    </>
  );
};
