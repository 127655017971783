import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesCoolingTowers = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: { ID: string }) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.NoTowers,
    title: "Towers",
    width: 150,
  },
  {
    field: HMFieldMapping.NoSystems,
    title: "Systems",
    width: 150,
  },
  {
    field: HMFieldMapping.StreetNo,
    title: "Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Street,
    title: "Street",
    width: 150,
  },
  {
    field: HMFieldMapping.Suburb,
    title: "Suburb",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
  },
  {
    field: HMFieldMapping.FileNumber,
    title: "File Number",
    width: 150,
  },
  {
    field: HMFieldMapping.DebtorNo,
    title: "Debtor Number",
    width: 150,
  },
  {
    field: HMFieldMapping.AssessmentNo,
    title: "Assessment Number",
    width: 150,
  },
  {
    field: HMFieldMapping.LastInspection,
    title: "Last Inspection",
    width: 150,
  },
  {
    field: HMFieldMapping.Email,
    title: "Email",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress,
    title: "Postal Address",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress2,
    title: "Postal Address 2",
    width: 150,
  },
  {
    field: HMFieldMapping.Proprietor,
    title: "Proprietor",
    width: 150,
  },
  {
    field: HMFieldMapping.PrimaryContact,
    title: "Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.DateLodged,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.DateRegistrationExpires,
    title: "Due Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Fax,
    title: "Fax",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
