import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  CorporateAuth,
  ProductTypeWithCorpAuth,
} from "@common/pages/settings/security/corporate-authorisations/_id/model";

export const getCorporateAuthById = async (
  corporateAuthId: number
): Promise<APIResponse<CorporateAuth | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<CorporateAuth>(
      `api/core/internal/corporate-authorisation/${corporateAuthId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCorporateAuth = async (
  corporateAuth: CorporateAuth
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/corporate-authorisation`,
      corporateAuth
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCorporateAuthLOV = async (): Promise<
  APIResponse<ProductTypeWithCorpAuth[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      "api/core/internal/corporate-authorisation-lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
