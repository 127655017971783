import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";

export enum EKeysOfStepsNewPIC {
  Association = "Association",
  PICDetail = "PICDetails",
  Detail = "Details",
  Contact = "Contact",
  Collection = "SelectedCollection",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum ELabelNewPIC {
  Related = "Related",
  PICDetail = "PIC details",
  Detail = "Details",
  Contact = "Contacts",
  Collection = "Collections",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export const keysOfStepsNewPIC = [
  EKeysOfStepsNewPIC.Association,
  EKeysOfStepsNewPIC.PICDetail,
  EKeysOfStepsNewPIC.Contact,
  EKeysOfStepsNewPIC.Detail,
  EKeysOfStepsNewPIC.Collection,
  EKeysOfStepsNewPIC.Workflow,
];

export interface DTO_Workflow_PICCreate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_PIC_Create;
}

export interface DTO_WorkflowDetail_PIC_Create {
  PIC_Id?: number;
  Details: DTO_PIC_Detail;
  Association: DTO_PIC_CreateAssociation;
  PICDetails: DTO_PIC_CreateDetails;
  Contact: DTO_Contact;
  LOVs: DTO_PIC_CreateLOVs;
  SelectedCollection: Collection[];
  // TODO: Recheck when have API
  SelectedSecondaryWorkflow: number[];
}

export interface DTO_PIC_CreateLOVs {
  District: DTO_LOV[];
}

export interface RequestPICCreateObj {
  AssessmentIds: number[];
  TitleIds: number[];
}

export interface DTO_PIC_Detail {
  ApplicantName: string;
  ReasonId: number;
  Reference: string;
  PICStatus: number;
  PICStatusName: string;
}

export interface DTO_PIC_CreateAssociation {
  AssociationDate: Date;
  PrimaryHolding?: number;
  Holdings: DTO_Assessment[];
  Folios: DTO_Title[];
}

export interface DTO_PIC_CreateDetails {
  PICNumber: string;
  PICTypeId: number;
  PICLandUseId?: number;
  AgentLicenseNumber: string;
  RLPBoardId?: number;
  DistrictId?: number;
  Occupier: string;
  TradingNameId?: number;
  PrimaryHolding: string;
  TradingName?: string;
  PropertyName: string;
  PropertyAddress: string;
  PropertyLocality: string;
  GisReferences: DTO_GIS[];
  Swines: DTO_PIC_Swine[];
  DistrictName: string;
  RLPBoardName: string;
  PICLandUseName: string;
  PICTypeName: string;
  PICDescription: string;
  SecondaryLandUse: string;
  SecondaryLandUseId?: number;
  PrimaryLandUse: string;
  PrimaryLandUseId?: number;
  Status: string;
  LLSProperty_Ref_No: string;
  GISLandAreaUnitCode: string;
  GISLandArea?: number;
  Centroid_Lat?: number;
  Centroid_Lng?: number;
  Effective_To_Date?: Date;
  Effective_From_Date?: Date;
  GIS_GlobalID: string;
}

export interface DTO_PIC_Swine {
  PIC_Swine_Id?: number;
  Swine_Type_Id: number;
  Swine_Type_Name: string;
  Effective_From?: Date;
  Effective_To?: Date;
  PS_Is_Active: boolean;
  Id: string;
}
export interface DTO_PIC_LOVs {
  PICStatus: DTO_LOV_Number[];
  PICTypes: DTO_LOV_PICType[];
  PICLandUses: DTO_LOV_Number[];
  AssessmentGroups: DTO_LOV_Number[];
  RLPBoards: DTO_LOV_Number[];
  ReasonForCreate: DTO_LOV_Number[];
  Officers: DTO_LOV_Number[];
  SwineTypes: DTO_LOV_Number[];
  CollectionTypes: DTO_LOV[];
  Districts: DTO_LOV_Number[];
  GISType: DTO_LOV[];
}
export interface DTO_LOV_PICType {
  Code: number;
  Name: string;
  AgentType: boolean;
}

export interface DTO_Entity_Details {
  Entity_Id: number;
  Entity_Name_Address_Id: number;
  Role_Name: string;
  Percentage?: number;
  Name: string;
  Address: string;
  isnew: boolean;
}

export interface DTO_Contact {
  Contacts: DTO_Entity_Details[];
  Attention_Of: string;
  Care_Of: string;
  Name: string;
  Address: string;
  Locality: string;
  State: string;
  Country: string;
  Postcode: string;
  DPID?: number;
  Notice_Id?: number;
  Formatted_Name_Address: string;
}

export enum ELoadingModeWorkflow {
  ActionLoading = "ActionLoading",
}
