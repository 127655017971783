import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface ICreateJournalDialog {
  SelectionOfUsages?: number;
  SelectionOfUsagesList?: IKeyValuePacket[];
  BillingGroupsToInclude?: number;
  BillingGroupsToIncludeList?: IKeyValuePacket[];
  OnlyBillingGroups?: number;
  OnlyBillingGroupsList?: IKeyValuePacket[];
  SearchBy: string;
  SearchValue?: number | null;
  OldReference?: string;
  Owners?: string;
  PropertyAddress?: string;
  UtilitiesRaiseCharges?: number;
  UtilitiesRaiseChargesList?: IKeyValuePacket[];
  Date?: Date | string;
  Name?: string;
  Description?: string;
  InstalmentPlan?: string;
  InstalmentPlanList?: IKeyValuePacket[];
  ApplyRemainingRebateEntitlements?: boolean;
}

export enum SearchType {
  AssessmentNumber = "Assessment Number",
  AssessmentId = "Assessment Id",
}
