import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useLocalGovernmentStore } from "@common/pages/settings/lookups/local-government/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const LookupsLocalGovernmentTabHistory = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { localGovernment } = useLocalGovernmentStore();
  const localGovernmentId: number =
    localGovernment?.Council_ID ?? lastSelectedRow?.ID ?? 0;
  return (
    <Journal
      id={localGovernmentId}
      triggersReload={localGovernment}
      recordType={RECORDTYPE.CORE_Council}
    />
  );
});
