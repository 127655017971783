import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { isNil, trim } from "lodash";

export const DDAuthorisationsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Direct Debit - Authorisations";
  },

  getBookmarkRecordDisplayName() {
    return "Direct Debit - Authorisation";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Direct Debit - Authorisations - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(selectedRow: DirectDebitAuthorisation) {
    const dynamicDisplayName = selectedRow?.Authorisation_Id
      ? `- ${selectedRow.Authorisation_Id}`
      : "";
    return `Direct Debit - Authorisation ${dynamicDisplayName}`;
  },
};

export const stringLengthValidator = (
  value: any,
  minLength: number,
  maxLength: number
) => {
  if (minLength > 0 && (isNil(value) || trim(value)?.length === 0)) {
    return "This field is required.";
  }

  if (trim(value)?.length < minLength) {
    return `The minimum is ${minLength} characters.`;
  }

  if (trim(value)?.length > maxLength) {
    return `The maximum is ${maxLength} characters.`;
  }

  return "";
};
