import { DATE_FORMAT } from "@common/constants/common-format";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { formatDateByKendo } from "@common/utils/formatting";

export const PublicHolidaysBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },
  getBookmarkListViewDetail() {
    return "Settings - Lookups - Public Holidays";
  },
};

export const PublicHolidaysDetailsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Hol_Year
      ? `- ${selectedRow.Hol_Year}`
      : "";
    return `Settings - Lookups - Public Holidays ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      formatDateByKendo(new Date(selectedRow.Hol_Date), DATE_FORMAT.LONG_DATE),
    ]);
  },
};
