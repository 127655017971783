import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { CEP_ProductType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/cep/components/application-setting-checkbox/model";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
} from "@components/cc-input-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface ICEPSettingMultiChoiceProps {
  field: SettingField;
  isEditing: boolean;
  formRenderProps: FormRenderProps;
}

export const CEPSettingMultiChoice = (props: ICEPSettingMultiChoiceProps) => {
  const { field, isEditing, formRenderProps } = props;
  const { onChange, valueGetter } = formRenderProps;

  const data = field.LookupItems;
  const value = valueGetter(field.FieldName);

  const selectedRows = useMemo(
    () =>
      value
        ?.split(",")
        .map((id: string) => data?.find((item) => item.Id === id))
        .filter((item: any) => item),
    [value, data]
  );

  const valueDisplay = useMemo(
    () =>
      selectedRows
        ?.reduce((prev: any, curr: any) => `${prev}, ${curr?.Name}`, "")
        .slice(1)
        .trim(),
    [selectedRows]
  );

  const onChangeComboBoxValue = (event: ICCInputPickerChangeEvent) => {
    let currentValue = "";
    if (event.value) {
      event.value.forEach(
        (item: ILookupItem) => (currentValue += item.Id + ",")
      );
    }

    if (currentValue === "") {
      currentValue = CEP_ProductType.Unspecified;
    }

    onChange(field.FieldName, {
      value: currentValue,
    });
  };

  return (
    field && (
      <div className="cc-field">
        <CCLabel title={field.Title ?? ""} isMandatory={field.IsMandatory} />
        <Field
          name={field.FieldName}
          value={valueDisplay}
          component={CCInputPicker}
          disabled={!isEditing}
          dialog={{
            maxWidth: "35%",
            height: "auto",
            titleHeader: field.Title,
          }}
          grid={{
            columnFields: [
              {
                field: "Name",
                title: "Name",
              },
            ],
            selectableMode: "multiple",
            itemPerPage: 50,
            selectedRows,
            primaryField: "Id",
            data,
          }}
          showClearButton
          onChange={onChangeComboBoxValue}
          validator={field.IsMandatory ? requiredValidator : undefined}
        />
      </div>
    )
  );
};
