import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { nameStreetLocalityRoute } from "./name-street-locality/route";
import { sameNameRoute } from "./same-name/route";

export const potentialDuplicateEntitiesRoute: ICCRoute = {
  path: "potential-duplicate-contacts",
  name: "Potential Duplicate Contacts",
  enum: eMenuAction.Entities_PotentialDuplicates,
  children: [nameStreetLocalityRoute, sameNameRoute],
};
