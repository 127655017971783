import { history } from "@/AppRoutes";
import { ANIMALS_KENNEL_MANAGE_PAGE } from "@app/products/animals/kennels/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
export interface INewKennelButtonProps {
  isDisabled: boolean;
}

export const NewKennelButton = ({
  isDisabled = false,
}: INewKennelButtonProps) => {
  return (
    <CCNavButton
      title="New"
      onClick={() => history.push(`${ANIMALS_KENNEL_MANAGE_PAGE}/new`)}
      disabled={isDisabled}
    />
  );
};
