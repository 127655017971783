import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { AmalgamateAssessmentCommonButton } from "@app/products/property/assessments/components/action-bar/buttons/amalgamate-assessment/_index";
import { RequestAmalgamateAssessmentObj } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AmalgamateAssessmentWorkflowButtonAtCollections = observer(() => {
  const { gridSelectedRows, gridSelectedIds, isEmptyData } =
    useDetailAssessmentListViewStore();

  const requestAmalgamateObj: RequestAmalgamateAssessmentObj = useMemo(() => {
    if (gridSelectedRows?.length <= 0)
      return {
        Assessments: [
          {
            Assessment_Id: null,
            StatusId: null,
          },
        ],
        Assessment_Ids: [],
      };
    const assessmentList = gridSelectedRows?.map((item) => ({
      Assessment_Id: item?.Assessment_Id ?? 0,
      StatusId: item?.Assessment_Status ?? 0,
    }));

    return {
      Assessments: assessmentList,
      Assessment_Ids: gridSelectedIds,
    };
  }, [gridSelectedIds, gridSelectedRows]);

  const isDisabled = useMemo(() => {
    return isEmptyData || gridSelectedRows.length <= 0;
  }, [gridSelectedRows, isEmptyData]);

  return (
    <AmalgamateAssessmentCommonButton
      requestAmalgamateObj={requestAmalgamateObj}
      isDisabled={isDisabled}
    />
  );
});
