import { agentsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/agents/routeState";
import { assetsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/routeState";
import { cepRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/cep/routeState";
import { communicationRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/communication/routeState";
import { contactsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/contacts/routeState";
import { exchangeRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/exchange/routeState";
import { financeRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/routeState";
import { generalRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/routeState";
import { helperServiceRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/helper-services/routeState";
import { infringementsNoticesRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/infringements-notices/routeState";
import { inspectionsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/inspections/routeState";
import { investigationsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/investigations/routeState";
import { modulesRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/routeState";
import { narRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/nar/routeState";
import { paymentGatewayRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/payment-gateway/routeState";
import { printingRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/printing/routeState";
import { processExternalRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/process-external/routeState";
import { processorRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/processor/routeState";
import { propertiesRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/routeState";
import { recordsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/routeState";
import { reportsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/reports/routeState";
import { securitySettingsRouteStateGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/routeState";
import { loggingRouteStateGlobalSettings } from "./settings/logging/routeState";

export interface IRouteStateForm {
  name: string;
  component: any;
}

export const routeStateGlobalConfig: IRouteStateForm[] = [
  ...generalRouteStateGlobalSettings,
  ...recordsRouteStateGlobalSettings,
  ...printingRouteStateGlobalSettings,
  ...communicationRouteStateGlobalSettings,
  ...cepRouteStateGlobalSettings,
  ...modulesRouteStateGlobalSettings,
  ...contactsRouteStateGlobalSettings,
  ...propertiesRouteStateGlobalSettings,
  ...loggingRouteStateGlobalSettings,
  ...agentsRouteStateGlobalSettings,
  ...securitySettingsRouteStateGlobalSettings,
  ...investigationsRouteStateGlobalSettings,
  ...narRouteStateGlobalSettings,
  ...exchangeRouteStateGlobalSettings,
  ...assetsRouteStateGlobalSettings,
  ...processorRouteStateGlobalSettings,
  ...paymentGatewayRouteStateGlobalSettings,
  ...reportsRouteStateGlobalSettings,
  ...infringementsNoticesRouteStateGlobalSettings,
  ...financeRouteStateGlobalSettings,
  ...inspectionsRouteStateGlobalSettings,
  ...processExternalRouteStateGlobalSettings,
  ...helperServiceRouteStateGlobalSettings,
];
