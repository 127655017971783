import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const CancelChangeOfOwnershipButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();

  const isDisableCancelButton = useMemo(() => {
    if (gridSelectedRows) {
      return gridSelectedRows?.length !== 1;
    }
    return false;
  }, [gridSelectedRows]);

  return (
    <CCNavButton
      title="Cancel change of ownership"
      disabled={isDisableCancelButton}
    />
  );
});
