import { eventEmitter } from "@/App";
import { saveMailMergeDocument } from "@app/core/new-mail-merge/buttons/components/buttons/api";
import { MailMergeSelectDocument } from "@app/core/new-mail-merge/buttons/components/buttons/model";

import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromView } from "@app/core/new-mail-merge/dialogs/util";
import { FormAction } from "@app/products/crms/model";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";

import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IMailMergeButtonProps {
  count?: number;
  councilId?: number;
  isMultiple?: boolean;
  recordType: RECORDTYPE;
  productType: PRODUCT_TYPE_NUMBER;
  dataSetFilter: MAILMERGEDATASET;
  ancestorId?: number;
  ancestorType?: RECORDTYPE;
  selectedRecordIds?: number[];
  gridSelectedRows?: any[];
  isEmptyGridSelectedRow?: boolean;
  titleButton?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  initMailMergeData?: Partial<MailMerge_SelectMergeDocument>;
  formAction?: FormAction;
}

export const MailMergeButton = observer(
  ({
    productType,
    recordType,
    dataSetFilter,
    isMultiple = true,
    ancestorId,
    ancestorType,
    selectedRecordIds,
    isEmptyGridSelectedRow = true,
    gridSelectedRows = [],
    titleButton = "Mail merge",
    isVisible = true,
    isDisabled = false,
    initMailMergeData,
    formAction = FormAction.CORE_ALLOW_MAILMERGE,
  }: IMailMergeButtonProps) => {
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [showMailMergeDialog, setShowMergeDialog] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [initialMailMerge, setInitialMailMerge] =
      useState<MailMerge_SelectMergeDocument_Ext>();

    const handleOnClick = () => {
      const mailMerge = setUpMailMergeFromView();
      mailMerge.Dataset_Filters.push(dataSetFilter);
      setInitialMailMerge({
        ...mailMerge,
        ...initMailMergeData,
        _options: {
          SaveAsPDF: mailMerge.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
        },
      });
      setShowMergeDialog(true);
    };

    const handleOnSubmit = async (
      mailMerge: MailMerge_SelectMergeDocument_Ext
    ) => {
      let requestBody: MailMergeSelectDocument = {
        SelectedRecordIDs:
          selectedRecordIds ??
          gridSelectedRows
            .map((item: any) => item?.ID)
            .filter((id) => id !== null),
        SelectedMailMergeDocumentIDs: mailMerge.SelectedDocumentIDs,
        RecordType: recordType,
        AppendToRecordSource: mailMerge.AppendToRecordSource,
        BatchPrint: mailMerge.BatchPrint,
        Collate: mailMerge.Collate,
        SaveAsPdf: mailMerge?._options?.SaveAsPDF ?? false,
        Category: mailMerge.Category_KWD,
        AncestorType: ancestorId ?? null,
        Ancestor_ID: ancestorType ?? null,
        CategoryName: mailMerge.CategoryName ?? null,
      };
      setIsSubmitting(true);
      const response = await saveMailMergeDocument(
        productType,
        requestBody,
        formAction // formAction: use for security template
      );
      customLogger("Core Mail Merge, saveMailMergeDocument").info(
        response?.data
      );
      setIsSubmitting(false);
      setShowMergeDialog(false);
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          clearErrorNotification();
          //Refresh document accordion after updated successfully
          eventEmitter.emit(PropertyDocumentEventType.RefreshData);
          pushNotification({
            title: "Mail merge process has been queued to run on the serve",
            type: "success",
          });
          if (
            requestBody?.Collate === true &&
            response.data?.Data != null &&
            response.data?.Data.length > 0
          ) {
            DownloadFile(
              response.data.Data,
              response.data.ContentType,
              response.data.FileName
            );
          }
        } else {
          pushNotification({
            autoClose: false,
            title: "Mail merge process failed",
            description: response.data?.Errors,
            type: "error",
          });
        }
      } else {
        pushNotification({
          autoClose: false,
          title: response.statusText ?? "Mail merge process failed",
          type: "error",
        });
      }
    };
    if (!isVisible) return null;
    return (
      <>
        <CCNavButton
          title={titleButton}
          disabled={isEmptyGridSelectedRow || isDisabled}
          onClick={handleOnClick}
        />
        {showMailMergeDialog && (
          <MailMergeDialog
            isSubmitting={isSubmitting}
            initialMailMerge={initialMailMerge}
            onClose={() => {
              setShowMergeDialog(false);
            }}
            onSubmit={(mailMerge: MailMerge_SelectMergeDocument_Ext) =>
              handleOnSubmit(mailMerge)
            }
            productType={productType}
            recordType={recordType}
            isMultiple={isMultiple}
          />
        )}
      </>
    );
  }
);
