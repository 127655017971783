import { loadAssessmentSpecialReadingData } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/api";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { EnterSpecialReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const EnterSpecialReadingButton = observer(() => {
  const [showEnterSpecialReading, setShowEnterSpecialReading] =
    useState<boolean>(false);
  const { assessment } = useAssessmentStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [specialReadingData, setSpecialReadingData] = useState<
    ISpecialReadingDialogData | undefined
  >();
  const handleOnClick = () => {
    if (!assessment) return;
    loadAssessmentSpecialReadingData(assessment).then(
      (data: ISpecialReadingDialogData) => {
        if (!data) return;
        setSpecialReadingData(data);
        setShowEnterSpecialReading(true);
      }
    );
  };

  return (
    <>
      <CCNavButton title="Enter special reading" onClick={handleOnClick} />

      {showEnterSpecialReading && (
        <EnterSpecialReadingDialog
          onClose={() => {
            setShowEnterSpecialReading(false);
          }}
          onEnterAndExit={() => {
            setShowEnterSpecialReading(false);
            pushNotification({
              title: "Special reading entered successfully",
              type: "success",
            });
          }}
          specialReadingData={specialReadingData}
        />
      )}
    </>
  );
});
