export enum eLevySummaryTypes {
  Financial_Area = 0,
  Financial_Group = 1,
  Property_Locality = 2,
  Electoral_Division = 3,
  Assessment_Type = 4,
  Assessment_Status = 5,
  Region = 6,
}

// For fist level grid
export interface DTO_ChargeRun_LevySummary {
  LevySummary: DTO_LevySummary[];
}

export interface DTO_LevySummary {
  Assessment_Group: string;
  Levy_Amount?: number;
  Valuation?: number;
  AdjustmentForCapping?: number;
  ReductionToMaximum?: number;
  Group: string;
  PostponedAmount?: number;
  AttributableValue?: number;
  NettLevyAmount?: number;
  Rebates?: number;
  GrossLevyAmount?: number;
  IncrementsToMinimum?: number;
  RateByValuationAmount?: number;
  BaseAmount?: number;
  NumberOfLevies?: number;
  GroupId?: number;
  LevyName: string;
  DifferentialRatingCategory: string;
}
