import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colRecordWorkComplete: IColumnFields[] = [
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  {
    field: "WorkOrderId",
    title: "Work Order ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "HasBeenCompleted",
    title: "Has Been Completed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "NotifiedOn",
    title: "Notified On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "ClearedOn",
    title: "Cleared On",
    format: DATE_FORMAT.DATE,
  },
  { field: "Contractor", title: "Contractor" },
  {
    field: "ContractorNotifiedOn",
    title: "Contractor Notified On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  { field: "Owners", title: "Owners" },
  { field: "Clearance", title: "Clearance" },
  {
    field: "ToBeClearedBy",
    title: "To Be Cleared By",
    format: DATE_FORMAT.DATE,
  },
  { field: "Period", title: "Period" },
  { field: "Comment", title: "Comment" },

  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
