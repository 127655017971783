import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colRebateSummaryDetail } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/component/config";
import { VO_Charge_Run_Rebate_LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/component/model";
import { useChargeRunRebateLevySummaryStore } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/component/store";

const nameOfRebateLevySummary =
  nameOfFactory<VO_Charge_Run_Rebate_LevySummary>();
export const ChargeRunRebateSummaryDetail = observer((data: any) => {
  const {
    viewConfiguration,
    responseLoadError,
    isLoading,
    loadChargeRunRebateLevySummaryConfig,
    resetStore,
  } = useChargeRunRebateLevySummaryStore();

  useEffect(() => {
    loadChargeRunRebateLevySummaryConfig();
    return () => {
      resetStore();
    };
  }, [loadChargeRunRebateLevySummaryConfig, resetStore]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadChargeRunRebateLevySummaryConfig();
      }}
    />
  ) : (
    <div className="cc-charge-run-rebate-summary-detail">
      <CCGrid
        className="cc-charge-run-rebate-summary-detail-grid"
        dataUrl={`/odata/property/internal/chargerunrebatelevyregister/GetRebateLevySummary(key=${data?.dataItem?.Charge_Run_Id},rebateType=${data?.dataItem?.Rebate_Type_Id})?$count=true&`}
        columnFields={processDynamicColumns(
          colRebateSummaryDetail,
          viewConfiguration
        )}
        primaryField={nameOfRebateLevySummary("Rebate_Type_Id")}
      />
    </div>
  );
});
