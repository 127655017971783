import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { getDocumentById, saveDocument } from "@app/core/documents/api";
import { Svc_Attachment, eCheckType } from "@app/core/documents/model";
import {
  combineAttachments,
  putSortOrder,
} from "@app/core/view/components/dialogs/view-documents/api";
import { CombineAttachmentDialog } from "@app/core/view/components/dialogs/view-documents/components/dialogs/combine-attachment/_index";
import {
  MergeAttachment,
  Svc_CombineAttachment,
} from "@app/core/view/components/dialogs/view-documents/components/dialogs/combine-attachment/model";
import { UpdateAttachmentDialog } from "@app/core/view/components/dialogs/view-documents/components/dialogs/update-attachment/_index";
import { IUpdateAttachment } from "@app/core/view/components/dialogs/view-documents/components/dialogs/update-attachment/model";
import {
  colViewAttachments,
  nameOfViewAttachments,
} from "@app/core/view/components/dialogs/view-documents/config";
import {
  IViewAttachments,
  Svc_ChangeSort,
  eSortOrder,
} from "@app/core/view/components/dialogs/view-documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import fileDownload from "js-file-download";
import { isEmpty } from "lodash";
import React, { useMemo, useRef, useState } from "react";

export interface IViewDocumentsDialogProps {
  id?: number;
  recordType: RECORDTYPE;
  onClose: () => void;
}

export const ViewDocumentsDialog = ({
  id,
  recordType,
  onClose,
}: IViewDocumentsDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const [attachmentsSelected, setAttachmentsSelected] = useState<
    IViewAttachments[]
  >([]);

  const [showUpdateAttachmentDialog, setShowUpdateAttachmentDialog] =
    useState<boolean>(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false);
  const [showCombineAttachmentDialog, setShowCombineAttachmentDialog] =
    useState<boolean>(false);
  const [isSortingOrder, setIsSortingOrder] = useState<eSortOrder>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCombining, setIsCombining] = useState(false);

  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [updateAttachmentInfo, setUpdateAttachmentInfo] =
    useState<IUpdateAttachment>();

  const isDisabledButton =
    attachmentsSelected?.length !== 1 ||
    (!isEmpty(attachmentsSelected) &&
      attachmentsSelected[0].RecordType === eCheckType.WEB_LINK);

  const columnFields = useMemo(
    () =>
      colViewAttachments.map((col) => {
        if (col.field === nameOfViewAttachments("Title")) {
          col.handleOnClick = async (dataItem: IViewAttachments) => {
            if (dataItem?.RecordType === eCheckType.WEB_LINK) {
              const url = "http://" + dataItem?.Title;
              window.open(`${url}`, "_blank");
            } else {
              setIsLoading(true);
              setShowUpdateAttachmentDialog(true);
              const [resCategory, resAttachment] = await Promise.all([
                getKeywords(
                  KEYWORD_TYPE.Core_DocumentCategory,
                  PRODUCT_TYPE_NUMBER.Core
                ),
                getDocumentById(dataItem?.ID, true),
              ]);
              customLogger(
                "Core View, View documents, getKeywords - Core_DocumentCategory"
              ).info(resCategory?.data);
              customLogger("Core View, View documents, getDocumentById").info(
                resCategory?.data
              );
              setIsLoading(false);
              if (
                isSuccessResponse(resCategory) &&
                isSuccessResponse(resAttachment)
              ) {
                if (resCategory.data && resAttachment.data) {
                  setUpdateAttachmentInfo({
                    CurrentTitle: resAttachment?.data?.Title,
                    Category_KWD: resAttachment?.data?.Category_KWD,
                    _options: {
                      Categories: resCategory?.data ?? [],
                      Attachment: resAttachment?.data,
                    },
                  });
                }
              } else {
                setNotifications([
                  {
                    id: "1",
                    title: "Attachment load failed",
                    type: "warning",
                  },
                ]);
              }
            }
          };
        }
        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnUpdate = async (attachment: IUpdateAttachment) => {
    setIsUpdating(true);
    const requestBody = {
      ParentID: id,
      ParentRecordType: recordType,
      AttachmentList: [attachment?._options?.Attachment],
      AddDateTimeToFileName: null,
    } as Svc_Attachment;
    const response = await saveDocument(requestBody);
    customLogger("Core View documents, Update documents, saveDocument").info(
      response?.data
    );
    setIsUpdating(false);
    setShowUpdateAttachmentDialog(false);
    if (isSuccessResponse(response)) {
      notificationRef.current?.pushNotification({
        title: "Attachment updated successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: response.statusText ?? "Update attachment failed",
        type: "error",
      });
    }
  };

  const handleOnDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    const ids: number[] = attachmentsSelected?.map(
      (item: IViewAttachments) => item.ID
    );
    const response = await deleteRefUsageByIdAndRecordType(
      ids,
      RECORDTYPE.CORE_Attachment,
      data?.Reason
    );
    customLogger(
      "Core View documents, Delete documents, deleteRefUsageByIdAndRecordType"
    ).info(response?.data);
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      setAttachmentsSelected([]);
      setShowConfirmDeleteDialog(false);
      notificationRef.current?.pushNotification({
        title: "Attachment deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Delete attachment failed",
        type: "error",
        description: response.data?.Errors,
      });
    }
  };

  const handleOnChangeSortOrder = async (sortOder: eSortOrder) => {
    setIsSortingOrder(sortOder);

    const ids: number[] = attachmentsSelected?.map((item) => item?.ID) ?? [];
    const recordType: string =
      attachmentsSelected?.map((item) => item?.RecordType)?.[0] ?? "";
    const requestBody: Svc_ChangeSort = {
      IDs: ids,
      MoveForward: sortOder === eSortOrder.MOVE_UP ? true : false,
      RecordType:
        recordType === eCheckType.FILE ? eCheckType.FILE : eCheckType.WEB_LINK,
    };
    const response = await putSortOrder(requestBody);
    customLogger("Core View documents, putSortOrder").info(response?.data);
    setIsSortingOrder(eSortOrder.NONE);
    if (isSuccessResponse(response)) {
      notificationRef.current?.pushNotification({
        title: "Attachment changed the sort order successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title:
          response.statusText ??
          "The sort order of the attachment could not be changed",
        type: "error",
      });
    }
  };

  const handleOnCombineAttachments = async (combinedFileName: string) => {
    setIsCombining(true);

    const redBody = {
      ParentID: id,
      ParentRecordType: recordType,
      FileName: combinedFileName,
      MergeAttachments: attachmentsSelected?.map((item: IViewAttachments) => {
        return {
          DocumentID: item?.ID,
          FileNumber: item?.FileNumber,
          RecordType: parseInt(item?.RecordType),
        } as MergeAttachment;
      }),
    } as Svc_CombineAttachment;

    const response = await combineAttachments(redBody);
    customLogger("Core View documents, combineAttachments").info(
      response?.data
    );
    setIsCombining(false);
    setShowCombineAttachmentDialog(false);
    if (isSuccessResponse(response)) {
      notificationRef.current?.pushNotification({
        title: "Attachments combined successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: response.statusText ?? "Combine attachments failed",
        type: "error",
      });
    }
  };

  const handleOnDownload = () => {
    const documentInfo = !isEmpty(attachmentsSelected)
      ? attachmentsSelected[0]
      : null;
    if (documentInfo?.ID) {
      CoreAPIService.getClient()
        .get(`/api/core/internal/document/${documentInfo?.ID}`, {
          responseType: "blob",
        })
        .then((response) => {
          customLogger("Core View documents, download Document").info(
            response?.data
          );
          fileDownload(response.data, documentInfo?.FileName ?? "");
        });
    }
  };

  return (
    <CCDialog
      maxWidth="75%"
      height="auto"
      titleHeader={"View Attachments"}
      onClose={onClose}
      disabled={isLoading}
      bodyElement={
        <div className="cc-form">
          <>
            <CCLocalNotification ref={notificationRef} />
            <CCGrid
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    disabled={attachmentsSelected?.length !== 1}
                    title="Move Up"
                    onClick={() => handleOnChangeSortOrder(eSortOrder.MOVE_UP)}
                    iconClass={
                      isSortingOrder === eSortOrder.MOVE_UP
                        ? "fas fa-spinner fa-spin"
                        : "fas fa-chevron-up"
                    }
                  />
                  <Button
                    disabled={attachmentsSelected?.length !== 1}
                    title="Move Down"
                    onClick={() =>
                      handleOnChangeSortOrder(eSortOrder.MOVE_DOWN)
                    }
                    iconClass={
                      isSortingOrder === eSortOrder.MOVE_DOWN
                        ? "fas fa-spinner fa-spin"
                        : "fas fa-chevron-down"
                    }
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    title="Remove Record"
                    disabled={isDisabledButton}
                    onClick={() => setShowConfirmDeleteDialog(true)}
                  />
                </div>
              }
              isLoading={isLoading}
              selectableMode="multiple"
              columnFields={columnFields}
              primaryField={nameOfViewAttachments("ID")}
              dataUrl={`/odata/core/internal/documents/getdocumentsview(key=${id},recordType=${recordType})?$count=true&`}
              isAutoHiddenPager={false}
              itemPerPage={10}
              state={{
                sort: [
                  {
                    field: nameOfViewAttachments("SortOrder"),
                    dir: "asc",
                  },
                  {
                    field: nameOfViewAttachments("RecordedDate"),
                    dir: "desc",
                  },
                ],
              }}
              selectedRows={attachmentsSelected}
              onSelectionChange={(dataItem: IViewAttachments[]) => {
                if (dataItem) setAttachmentsSelected([...dataItem]);
              }}
            />
          </>
          {showUpdateAttachmentDialog && (
            <UpdateAttachmentDialog
              isLoading={isLoading}
              isUpdating={isUpdating}
              attachmentInfo={updateAttachmentInfo}
              notifications={notifications}
              onClose={() => {
                setShowUpdateAttachmentDialog(false);
              }}
              onSubmit={handleOnUpdate}
            />
          )}
          {showCombineAttachmentDialog && (
            <CombineAttachmentDialog
              isLoading={isLoading}
              isCombining={isCombining}
              attachmentInfo={attachmentsSelected}
              notifications={notifications}
              onClose={() => {
                setShowCombineAttachmentDialog(false);
              }}
              onSubmit={handleOnCombineAttachments}
            />
          )}
          {showConfirmDeleteDialog && (
            <ConfirmDelete
              onClose={() => setShowConfirmDeleteDialog(false)}
              handleSubmit={handleOnDelete}
              isDeleting={isDeleting}
              contentDelete={"Attachment"}
              header={"Confirm Deletion"}
            />
          )}
        </div>
      }
      footerElement={
        <div className={"cc-dialog-footer-actions-right"}>
          <Button className={"cc-dialog-button"} onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={handleOnDownload}
            disabled={isDisabledButton}
          >
            Download
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={() => {
              setShowCombineAttachmentDialog(true);
            }}
            disabled={attachmentsSelected?.length <= 1}
          >
            Combine
          </Button>
        </div>
      }
    />
  );
};
