import {
  IOccurrences,
  IReport,
} from "@app/products/property/meters/[id]/components/child-screens/leakage/model";
import { useMeterLeakageStore } from "@app/products/property/meters/[id]/components/child-screens/leakage/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { colReportLeakage } from "./config";

const nameOfOccurrence = nameOfFactory<IOccurrences>();
const nameOfReport = nameOfFactory<IReport>();

export const MeterLeakage = observer(() => {
  const {
    loadLeakage,
    isLoading,
    leakage,
    gridColumnsOccurrences,
    setSelectedLeakage,
  } = useMeterLeakageStore();
  useEffectOnce(() => {
    loadLeakage();
  });

  return (
    <div className="cc-property-meter-leakage">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Occurrences of leakage</label>
              <CCGrid
                isLoading={isLoading}
                className="cc-occurances-of-leakage-grid-data"
                data={leakage && leakage.Occurrences ? leakage.Occurrences : []}
                columnFields={gridColumnsOccurrences}
                primaryField={nameOfOccurrence("MeterLeakageId")}
                selectableMode="multiple"
                onSelectionChange={(e) => {
                  setSelectedLeakage(e);
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Reports of possible meter leakage
              </label>
              <CCGrid
                isLoading={isLoading}
                className="cc-meter-leakage-grid-data"
                data={leakage?.Report ?? []}
                columnFields={colReportLeakage}
                primaryField={nameOfReport("Id")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
