import { AssessmentPicker } from "@app/products/property/deferred-duty/components/action-bar/dialogs/create-deferred-duty/components/assessment-picker/_index";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { addYears } from "date-fns";
import React, { useEffect } from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  options = { isReadOnly: false, workflowDraftId: 0 },
  isLoadingStep,
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const optionAssessmentValueGetter = getFieldValue(
    "_option.AssessmentReference"
  );

  useEffect(() => {
    if (
      optionAssessmentValueGetter &&
      optionAssessmentValueGetter?.[0] &&
      optionAssessmentValueGetter !== ""
    ) {
      onChange(nameOf("AssessmentId"), {
        value: optionAssessmentValueGetter[0]?.Assessment_Id,
      });
      onChange(nameOf("PropertyAddress"), {
        value: optionAssessmentValueGetter[0]?.Property_Address,
      });
      onChange(nameOf("NameAndAddress"), {
        value: optionAssessmentValueGetter[0]?.Owners_Name_Address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionAssessmentValueGetter]);

  const handleSearchResult = (event: ComboBoxChangeEvent) => {
    onChange(nameOf("AssessmentReference"), {
      value: event.value?.Assessment_Reference,
    });
    onChange(nameOf("_option.AssessmentReference"), {
      value: [event.value],
    });
    if (!event?.value) {
      onChange(nameOf("AssessmentId"), {
        value: undefined,
      });
      onChange(nameOf("PropertyAddress"), {
        value: "",
      });
      onChange(nameOf("NameAndAddress"), {
        value: "",
      });
    }
  };

  const handleExecuteDateChange = (event: DatePickerChangeEvent) => {
    if (event?.value) {
      onChange(nameOf("ExecutionDate"), {
        value: event.value,
      });
      onChange(nameOf("PaymentCommencement"), {
        value: addYears(event.value, 5),
      });
      onChange(nameOf("PaymentCompletion"), {
        value: addYears(event.value, 10),
      });
    }
  };

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Account number</label>
            <Field
              name={nameOf("AccountNumber")}
              placeholder={"Account number"}
              min={0}
              spinners={false}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              onChange={(event: any) => {
                onChange(nameOf("AccountNumber"), {
                  value: event.value,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              component={CCTextArea}
              rows={4}
              placeholder={"Description"}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Assessment</label>
            <Field
              placeholder="Assessment"
              name={nameOf("AssessmentReference")}
              dataItemKey={"ID"}
              data={getFieldValue("_option.AssessmentReference")}
              component={AssessmentPicker}
              onChange={handleSearchResult}
              disabled={options?.isReadOnly}
              disabledTyping
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Assessment Id
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("AssessmentId")}
              component={CCInput}
              placeholder={"Assessment Id"}
              readOnly
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Property address</label>
            <Field
              name={nameOf("PropertyAddress")}
              component={CCTextArea}
              rows={4}
              placeholder={"Property address"}
              readOnly
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Name and address</label>
            <Field
              name={nameOf("NameAndAddress")}
              component={CCTextArea}
              rows={4}
              placeholder={"Name and address"}
              readOnly
            />
          </div>
        </div>
      </section>

      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Orginal duty assessed</label>
            <Field
              name={nameOf("OriginalDutyAssessed")}
              placeholder={"Orginal duty assessed"}
              min={0}
              spinners={false}
              component={CCNumericTextBox}
              format={CURRENCY_FORMAT.CURRENCY1}
              onChange={(event: any) => {
                onChange(nameOf("OriginalDutyAssessed"), {
                  value: event.value,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Duty deferred</label>
            <Field
              name={nameOf("DutyDeferred")}
              placeholder={"Duty deferred"}
              min={0}
              spinners={false}
              component={CCNumericTextBox}
              format={CURRENCY_FORMAT.CURRENCY1}
              onChange={(event: any) => {
                onChange(nameOf("DutyDeferred"), {
                  value: event.value,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Execution date</label>
            <Field
              name={nameOf("ExecutionDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              onChange={handleExecuteDateChange}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Deferment date</label>
            <Field
              name={nameOf("DefermentDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Payment commencement</label>
            <Field
              name={nameOf("PaymentCommencement")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Payment completion</label>
            <Field
              name={nameOf("PaymentCompletion")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled
            />
          </div>
        </div>
      </section>
    </>
  );
};
