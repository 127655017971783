import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IAssociateMeterDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export default function AssociateMeterDialog({
  onClosePopup,
  onConfirmYes,
}: IAssociateMeterDialog) {
  return (
    <ConfirmDialog
      title="Associate Meter"
      subMessage="Do you wish to Associate New Meter to Assessment?"
      height="auto"
      width="25%"
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
}
