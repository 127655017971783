import { GridSiteUser } from "@app/products/waste-water/model";
import { SystemTypeSelection } from "@app/products/waste-water/[id]/model";
import { nameOfFactory } from "@common/utils/common";

const nameOfSystemType = nameOfFactory<SystemTypeSelection>();
const nameOfSiteUser = nameOfFactory<GridSiteUser>();

export const colSystemType = [
  { title: "Manufacturer", field: nameOfSystemType("Manufacturer") },
  { title: "Name", field: nameOfSystemType("Name") },
  { title: "System Type", field: nameOfSystemType("SystemTypeClassification") },
  { title: "Size", field: nameOfSystemType("Size") },
  { title: "Description", field: nameOfSystemType("Description") },
];

export const colSiteUser = [
  { title: "Name", field: nameOfSiteUser("Name") },
  { title: "Position", field: nameOfSiteUser("Position") },
  { title: "Size", field: nameOfSiteUser("Email") },
  { title: "Phone", field: nameOfSiteUser("Phone") },
];
