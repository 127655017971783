import { getCommunicationSetup } from "@app/core/communication/dialogs/components/form-elememts/to/api";
import { AddRecipient } from "@app/core/communication/dialogs/components/form-elememts/to/components/add-recipient/_index";
import { ModifyRecipient } from "@app/core/communication/dialogs/components/form-elememts/to/components/modify-recipient/_index";
import { ConfigToStep } from "@app/core/communication/dialogs/components/form-elememts/to/config";
import { IToStep } from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { useToStepStore } from "@app/core/communication/dialogs/components/form-elememts/to/store";
import {
  findSourceIdentifierForKey,
  isPhonePreferMethod,
  recipientsAddRewrite,
  validatorToStep,
} from "@app/core/communication/dialogs/components/form-elememts/to/util";
import { ECommunication_Icon } from "@app/core/communication/dialogs/constant";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ContactMethodPreferred } from "@common/constants/enumerations";
import { APIResponseStatus } from "@common/constants/response-status";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce, useWindowSize } from "react-use";
import "./_index.scss";
export const COMMUNICATION_TO_STEP = "CommunicationTo";
const nameOfGridData = nameOfFactory<IToStep>();

export const CommunicationToStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorToStep}
    />
  );
};

const FormStepElement = observer(
  ({ nameOf, formRenderProps, options }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { height } = useWindowSize();
    const { setPreferred, preferred, resetStore } = useToStepStore();
    const [isLoading, setIsLoading] = useState(false);
    const [parentIdRecipients, setParentIdRecipients] = useState<number>(0);
    const [parentRecordType, setParentRecordType] = useState<string>();
    const colCommunicationTo = ConfigToStep();
    const usePreferred = getFieldValue("UsePreferred");
    const useEmail = getFieldValue("UseEmail");
    const useSMS = getFieldValue("UseSMS");
    const recipients = getFieldValue("recipients");
    const selectedToRows = getFieldValue("SelectedToRows") ?? [];
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();

    const loadData = useCallback(() => {
      if (!recipients) {
        setIsLoading(true);
        var queryIds = options.RecordId
          ? options.RecordId.toString()
          : options.RecordIds.join(", ");

        getCommunicationSetup(
          queryIds,
          options.ProductType,
          options.RecordType,
          options.RecipientTypes
        ).then((response: APIResponse) => {
          let errorResponse = undefined;
          if (isSuccessResponse(response)) {
            if (
              response.status !== APIResponseStatus.SUCCESS ||
              response.data === null
            ) {
              // @TODO: Show Toast and close dialog
              setIsLoading(false);
              return;
            }
            const data = response.data?.Communication;
            const defaultSenderEmailID = response.data?.DefaultSenderEmailID;
            const defaultCommunicationTemplate =
              response.data?.DefaultCommunicationTemplate;
            const fromEmailList = response.data?.FromList;
            setParentRecordType(
              data?.Recipients[0]?.Parent_RecordType ??
                findSourceIdentifierForKey(data, "_RecordSourceType_ENUM")
            );
            setParentIdRecipients(
              data?.Recipients[0]?.Parent_ID ??
                findSourceIdentifierForKey(data, "_RecordSource_ID")
            );

            if (!recipients) {
              onChange(nameOf("recipients"), {
                value: data?.Recipients,
              });
            }

            onChange("SendFrom", {
              value: fromEmailList?.find(
                (obj: IKeyValuePacket) => obj.Key === defaultSenderEmailID
              ),
            });
            onChange("_senderEmails", {
              value: fromEmailList,
            });

            onChange(nameOf("Communication"), {
              value: data,
            });

            onChange(nameOf("oSetting"), {
              value: {
                Dataset_Filters: [data?.Dataset_Filters[0]],
                MergeIdentifiers: [data?.SelectedRecords_Details[0]],
              },
            });

            onChange("_defaultCommunicationTemplate", {
              value: defaultCommunicationTemplate,
            });
            setIsLoading(false);
          } else {
            errorResponse = {
              status: response.status,
              error: response.error,
            };
          }
          setResponseLoadError(errorResponse);
        });
        return () => {
          setIsLoading(false);
        };
      }
      // eslint-disable-next-line
    }, []);

    useEffectOnce(() => {
      loadData();
      setSelectedRows(selectedToRows);
      setErrorsAndIconsFunc(recipients, usePreferred, useEmail, useSMS);
      return () => {
        resetStore();
      };
    });

    const onChangePreferred = (event: CheckboxChangeEvent) => {
      onChange(nameOf("UsePreferred"), { value: event.value });
      onChange(nameOf("UseEmail"), { value: false });
      onChange(nameOf("UseSMS"), { value: false });
      setErrorsAndIconsFunc(recipients, event.value, false, false);
      if (selectedToRows) {
        setCounters(selectedToRows, event.value, false, false);
      }
    };
    const onChangeEmail = (event: CheckboxChangeEvent) => {
      onChange(nameOf("UsePreferred"), { value: false });
      onChange(nameOf("UseEmail"), { value: event.value });
      setErrorsAndIconsFunc(recipients, false, event.value, useSMS);
      if (selectedToRows) {
        setCounters(selectedToRows, false, event.value, useSMS);
      }
    };
    const onChangeSMS = (event: CheckboxChangeEvent) => {
      onChange(nameOf("UsePreferred"), { value: false });
      onChange(nameOf("UseSMS"), { value: event.value });
      setErrorsAndIconsFunc(recipients, false, useEmail, event.value);
      if (selectedToRows) {
        setCounters(selectedToRows, false, useEmail, event.value);
      }
    };
    const onSelectionChange = (event: any) => {
      let recipients = event.map((item: any) => {
        item.SendToEmail = useEmail;
        item.SendToSMS = useSMS;
        return item;
      });
      setSelectedRows(event);
      let selectedToRows = event;
      onChange(nameOf("SelectedToRows"), { value: selectedToRows });

      setCounters(
        recipients,
        preferred.usePreferred,
        preferred.useEmail,
        preferred.useSMS
      );
    };

    const setErrorsAndIcons = (
      recipientData: any[],
      usePreferred: boolean,
      useEmail: boolean,
      useSMS: boolean
    ) => {
      if (!recipientData) {
        return;
      }
      recipientData?.map((item: any) => ({
        ...item,
        error: false,
        sendIcon: ECommunication_Icon.BellIcon,
        error2: false,
        sendIcon2: ECommunication_Icon.BellIcon,
      }));

      if (usePreferred) {
        recipientData.forEach((item: any) => {
          if (isPhonePreferMethod(item.PreferredMethod_Enum)) {
            item.sendIcon2 = ECommunication_Icon.SMSIcon;
            item.error2 = !item._Mobile;
          } else if (
            item.PreferredMethod_Enum === ContactMethodPreferred.Email &&
            item.Email
          ) {
            item.sendIcon = ECommunication_Icon.EmailIcon;
            item.error = !item.Email;
          } else {
            item.sendIcon = ECommunication_Icon.EmailIcon;
            item.error = true;
          }
        });
      } else if (useEmail) {
        recipientData.forEach((item: any) => {
          item.sendIcon = ECommunication_Icon.EmailIcon;
          item.error = !item.Email;
        });
      } else if (useSMS) {
        recipientData.forEach((item: any) => {
          item.sendIcon2 = ECommunication_Icon.SMSIcon;
          item.error2 = !item._Mobile;
        });
      }

      if (useEmail && useSMS) {
        recipientData.forEach((item: any) => {
          item.sendIcon = ECommunication_Icon.EmailIcon;
          item.sendIcon2 = ECommunication_Icon.SMSIcon;
          item.error = !item.Email;
          item.error2 = !item._Mobile;
        });
      }
      onChange(nameOf("recipients"), { value: recipientData ?? [] });
    };
    const setErrorsAndIconsFunc = (
      recipients: any[],
      usePreferred: boolean,
      useEmail: boolean,
      useSMS: boolean
    ) => {
      setPreferred({
        ...preferred,
        useEmail: useEmail,
        usePreferred: usePreferred,
        useSMS: useSMS,
      });
      setErrorsAndIcons(recipients, usePreferred, useEmail, useSMS);
    };
    const setCounters = (
      selectedRowData: any,
      usePreferred: boolean,
      useEmail: boolean,
      useSMS: boolean
    ) => {
      let emailRecipients: string[] = [];
      let emailCounter = 0;
      let smsCounter = 0;
      let errorCounter = 0;
      let warningCounter = 0;

      if (usePreferred) {
        selectedRowData.forEach((item: any) => {
          if (
            isPhonePreferMethod(item.PreferredMethod_Enum) &&
            item.PreferredMethod_Formatted
          ) {
            smsCounter++;
          } else if (
            item.PreferredMethod_Enum === ContactMethodPreferred.Email &&
            item.PreferredMethod_Formatted
          ) {
            emailCounter++;
            emailRecipients.push(item.PreferredMethod_Formatted);
          } else {
            errorCounter++;
          }
        });
      } else if (useEmail) {
        selectedRowData.forEach((item: any) => {
          if (item.Email) {
            emailCounter++;
            emailRecipients.push(item.Email);
          } else {
            errorCounter++;
          }
        });
      } else if (useSMS) {
        selectedRowData.forEach((item: any) => {
          if (item._Mobile) {
            smsCounter++;
          } else {
            errorCounter++;
          }
        });
      }

      if (useEmail && useSMS) {
        emailCounter = 0;
        emailRecipients = [];
        smsCounter = 0;
        errorCounter = 0;
        warningCounter = 0;

        selectedRowData.forEach((item: any) => {
          if (item.Email) {
            emailCounter++;
            emailRecipients.push(item.Email);
          }
          if (item._Mobile) {
            smsCounter++;
          }
          if (!item.Email && !item._Mobile) {
            errorCounter++;
          } else if (!item.Email || !item._Mobile) {
            warningCounter++;
          }
        });
      }

      onChange(nameOf("EmailCounter"), { value: emailCounter });
      onChange(nameOf("SmsCounter"), { value: smsCounter });
      onChange(nameOf("ErrorCounter"), { value: errorCounter });
      onChange(nameOf("WarningCounter"), { value: warningCounter });
    };

    // TODOS: refactor code
    const itemsOnHeight = useMemo(() => {
      if (height < 700) return 5;
      if (height < 800) return 7;
      return 10;
    }, [height]);

    const [isShowDialogAddRecipient, setIsShowDialogAddRecipient] =
      useState(false);
    const [isShowDialogModifyRecipient, setIsShowDialogModifyRecipient] =
      useState(false);

    const [dataModifyRecipient, setDataModifyRecipient] = useState();
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const handleAddRecipient = ({ recipientResult }: any) => {
      const addRecipientResult = recipientResult.map((item: any) => {
        return recipientsAddRewrite(item, parentIdRecipients, parentRecordType);
      });
      const recipientsNew = unionBy(
        recipients,
        addRecipientResult,
        "Contact_RID"
      );
      setErrorsAndIconsFunc(recipientsNew, usePreferred, useEmail, useSMS);
      setIsShowDialogAddRecipient(false);
    };

    const handleModifyRecipient = (recipientModify: any) => {
      const newRecipients = recipients.map((item: any) => {
        if (item.Contact_RID === recipientModify.Contact_RID) {
          recipientModify.error = !recipientModify?.Email;
          recipientModify.error2 = !recipientModify?._Mobile;
          item = recipientModify;
        }
        return item;
      });
      setErrorsAndIconsFunc(newRecipients, usePreferred, useEmail, useSMS);
      // Change emails, sms, warnings, errors counter
      const newRecipientsSelected = newRecipients.filter((item: any) =>
        selectedRows.some(
          (itemSelected) => item.Contact_RID === itemSelected.Contact_RID
        )
      );
      onChange(nameOf("SelectedToRows"), { value: newRecipientsSelected });
      setCounters(newRecipientsSelected, usePreferred, useEmail, useSMS);
      setIsShowDialogModifyRecipient(false);
    };

    const handleRemoveRecipient = (selectedToRows: any) => {
      let idsSelected = selectedRows.map((v: any) => {
        return v.Contact_RID;
      });

      const recipientNew = recipients.filter(
        (item: any) => !idsSelected.includes(item.Contact_RID)
      );
      onChange(nameOf("recipients"), {
        value: recipientNew,
      });
      onChange(nameOf("SelectedToRows"), { value: [] });
      setSelectedRows([]);
      setCounters([], usePreferred, useEmail, useSMS);
    };

    const renderNamesColumn = useMemo(() => {
      //Update handle function for FullName field
      return colCommunicationTo.map((col: IColumnFields) => {
        // Add handle OnClick action for field PropertyAddress
        if (col.field === nameOfGridData("Contact_FullName")) {
          col.handleOnClick = (recipients: any) => {
            setDataModifyRecipient(recipients);
            setIsShowDialogModifyRecipient(true);
          };
        }
        return col;
      });
    }, [colCommunicationTo]);

    return responseLoadError ? (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    ) : isLoading ? (
      <Loading isLoading />
    ) : (
      <>
        {isShowDialogAddRecipient && (
          <AddRecipient
            setIsShowDialogAddRecipient={setIsShowDialogAddRecipient}
            handleAddRecipient={handleAddRecipient}
          />
        )}

        {isShowDialogModifyRecipient && (
          <ModifyRecipient
            setIsShowDialogModifyRecipient={setIsShowDialogModifyRecipient}
            handleModifyRecipient={handleModifyRecipient}
            data={dataModifyRecipient}
          />
        )}

        <section className="cc-field-group">
          <div className="cc-manage-communication-body">
            <div className="cc-form">
              <div className="cc-form-cols-2 ">
                <div className="cc-checkbox-group">
                  <Field
                    name={nameOf("UsePreferred")}
                    component={Checkbox}
                    label={"Preferred"}
                    checked={usePreferred}
                    onChange={onChangePreferred}
                  />
                  <Field
                    name={nameOf("UseEmail")}
                    component={Checkbox}
                    label={"Email"}
                    checked={useEmail}
                    onChange={onChangeEmail}
                  />
                  <Field
                    name={nameOf("UseSMS")}
                    component={Checkbox}
                    label={"SMS"}
                    checked={useSMS}
                    onChange={onChangeSMS}
                  />
                </div>
                <div className=" cc-panel-header">
                  <div className="cc-panel-header-right">
                    <div className="cc-email-count">
                      <Chip
                        text={`${getFieldValue("EmailCounter")} Emails`}
                        value="chip"
                        themeColor="success"
                        fillMode="outline"
                        className="cc-email-count-chip"
                      />
                      <Chip
                        text={`${getFieldValue("SmsCounter")} SMS`}
                        value="chip"
                        themeColor="success"
                        fillMode="outline"
                        className="cc-email-count-chip"
                      />
                    </div>
                    <div className="cc-email-count">
                      <Chip
                        text={`${getFieldValue("WarningCounter")} Warnings`}
                        value="chip"
                        themeColor={
                          getFieldValue("WarningCounter") > 0
                            ? "warning"
                            : "base"
                        }
                        fillMode="outline"
                        className="cc-email-count-chip"
                      />
                      <Chip
                        text={`${getFieldValue("ErrorCounter")} Errors`}
                        value="chip"
                        themeColor={
                          getFieldValue("ErrorCounter") > 0 ? "error" : "base"
                        }
                        fillMode="outline"
                        className="cc-email-count-chip"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <CCGrid
                toolbar={
                  options?.RecordIds?.length === 1 || options?.RecordId ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        iconClass={ECommunication_Icon.PlusIcon}
                        type="button"
                        title="Add Recipient"
                        onClick={() => setIsShowDialogAddRecipient(true)}
                      />

                      <Button
                        type="button"
                        iconClass={ECommunication_Icon.MinusIcon}
                        title="Remove Recipient"
                        disabled={!selectedToRows || !selectedToRows[0]}
                        onClick={() => handleRemoveRecipient(selectedToRows)}
                      />
                    </div>
                  ) : null
                }
                columnFields={renderNamesColumn}
                selectedRows={selectedRows}
                primaryField={nameOfGridData("Contact_RID")}
                selectableMode="multiple"
                itemPerPage={itemsOnHeight}
                onSelectionChange={onSelectionChange}
                data={recipients ?? []}
                className="cc-grid-communication-to"
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
