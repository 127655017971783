import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { loadCRMSSystemSetting } from "@app/products/crms/system-admin/[id]/api";
import { CRMSSystemSettingForm } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/_index";
import { SettingPanelBar } from "@app/products/crms/system-admin/[id]/components/seting-panel-bar/_index";
import { CRMSSystemSettingFormData } from "@app/products/crms/system-admin/[id]/model";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { Toast } from "@components/toast";
import { default as React, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IProps {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  crmsSystemSettingInfo?: CRMSSystemSettingFormData | undefined;
}

export const ManageCRMSSystemSettings = ({
  isLoading,
  setIsLoading,
}: IProps) => {
  const history: any = useHistory();

  useEffectOnce(() => {
    const { state } = history.location;
    if (state?.status === "success") {
      Toast.displayToast({
        content: `🚀 Save ${CRMS_PRODUCT_NAME} Setting successfully !`,
        type: "success",
      });
      history.replace(window.location.pathname); // remove history states
    }
  });

  const [crmsSystemSettingInfo, setCrmsSystemSettingInfo] =
    useState<CRMSSystemSettingFormData>();

  useEffectOnce(() => {
    // setIsLoading(true);
    loadCRMSSystemSetting().then((data) => {
      if (!data) {
        return history.replace("/crms/all-events/after-hours-service");
      }
      setCrmsSystemSettingInfo(data);
      // setIsLoading(false);
    });
  });

  const menuItems: string[] = [
    "GENERAL",
    "EVENT",
    "NUMBERING",
    "SERVICE STANDARD",
    "ACKNOWLEDGEMENT",
    "ESCALATIONS",
    "SECURITY",
    "VIEWS",
    "PHONE MESSAGES",
    "MAIL MESSAGING",
    "ASSETS",
  ];

  const [activeItem, setActiveItem] = useState<string>(menuItems[0]);

  return (
    <>
      <FormNavigation title={`${CRMS_PRODUCT_NAME} Settings`} />

      <div id="manage-crmssystemsetting" className="container-fluid pl-4 pb-3">
        <FormTitle title={`Customer Service (${CRMS_PRODUCT_NAME}) Settings`} />
        <br />
        <SettingPanelBar
          menuItems={menuItems}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
        <div className="row pt-3 manage-crmssystemsetting-body">
          <div className="col-lg-10 col-sm-12">
            {crmsSystemSettingInfo && (
              <CRMSSystemSettingForm
                activeItem={activeItem}
                setIsLoading={setIsLoading}
                formData={crmsSystemSettingInfo}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
