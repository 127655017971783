import { IAssociate } from "@app/products/property/meters/[id]/components/action-bar/dialogs/associate/model";

export const mockAssociate: IAssociate = {
  MeterId: 50,
  MeterNumber: "00A113769",
  PropertyAddress: ", Proserpine",
  AccountNames: null,
  DateOfAttachment: new Date("24-Jan-2022 2:43"),
  UsageEntitlement: null,
  BillingGroup: "Monthly Billing",
  BillingGroupList: ["Monthly Billing", "Standard Periodic Billing"],
  AssessmentNumber: null,
  PropertyAddressAccountBeingAttached: null,
  AccountNamesAccountBeingAttached: null,
  SearchType: "Assessment Number",
};

export const mockAssessment = [
  {
    AssessmentNumber: 6789,
    PropertyAddress: "Wandella Avenue/14 Durham St",
    AccountName: "C K SIDHU & K S GILL",
    AssessmentId: 1,
  },
];
