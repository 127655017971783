import {
  IAccounts,
  IAccountsStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockAccountsStep: IAccountsStep = {
  Accounts: [
    {
      Register: "Cemetery",
      AccountReference: "MGW-28",
      PropertyAddress: ",",
      AccountId: 2010,
    },
  ],
};

export const dropdownRegisterData: IKeyValuePacket[] = [
  { Key: 1, Value: "Cemetery" },
  { Key: 2, Value: "Item1" },
];

export const mockRegisterAccountsAffected: IAccounts[] = [
  {
    Register: "Cemetery",
    AccountReference: "MGW-28",
    PropertyAddress: ",",
    AccountId: 2010,
  },
  {
    Register: "Cemetery",
    AccountReference: "MGW-27",
    PropertyAddress: ",",
    AccountId: 2011,
  },
  {
    Register: "Cemetery",
    AccountReference: "MGW-26",
    PropertyAddress: ",",
    AccountId: 2012,
  },
];
