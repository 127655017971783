import { apiCoreRegisteredAddress } from "@app/core/components/common/utils";
import { POUND_REGISTER_API } from "@app/products/animals/pound-register/[id]/constant";
import {
  PoundRegister,
  PoundRegisterHandlerRequest,
  PoundRegisterHandlerResponse,
  Svc_PoundRegister,
  Svc_PoundRegisterLOVs,
  Svc_PoundRegister_Workflow_ProvideReason,
} from "@app/products/animals/pound-register/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  Address_BuildAddress,
  SetupAddress,
} from "@common/input-pickers/address/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export type IGetAnimalPoundRegistrationById =
  | [
      APIResponse<IIdentityPacket<Svc_PoundRegister> | undefined>,
      APIResponse<Svc_PoundRegisterLOVs>
    ]
  | APIResponse<IIdentityPacket<Svc_PoundRegister> | undefined>
  | undefined;

export const getAnimalPoundRegisterInfo = async (
  id: number
): Promise<IGetAnimalPoundRegistrationById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<
        IIdentityPacket<Svc_PoundRegister> | undefined
      >(`${POUND_REGISTER_API}/${id}`),
      CoreAPIService.getClient().get<Svc_PoundRegisterLOVs>(
        `${POUND_REGISTER_API}/lovs`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAnimalPoundRegisterById = async (
  id: number
): Promise<APIResponse<IIdentityPacket<Svc_PoundRegister> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(`${POUND_REGISTER_API}/${id}`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postAnimalPoundRegister = async (
  poundRegister: PoundRegister
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `${POUND_REGISTER_API}`,
      poundRegister
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAnimalPoundRegisterLOVs = async (): Promise<
  APIResponse<IIdentityPacket<Svc_PoundRegisterLOVs> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_PoundRegisterLOVs> | undefined
    >(`${POUND_REGISTER_API}/lovs`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const poundRegisterHandler = async (
  handlerRequest: PoundRegisterHandlerRequest
): Promise<
  APIResponse<IIdentityPacket<PoundRegisterHandlerResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<PoundRegisterHandlerResponse>
    >(`${POUND_REGISTER_API}/form-handler`, handlerRequest);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupPoundRegisterPickSiteAddress = async (
  setupAddress: SetupAddress
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >(apiCoreRegisteredAddress(), setupAddress);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowLodgeRegistration = async (
  poundRegisterId: number
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `${POUND_REGISTER_API}/lodge-registration`,
      poundRegisterId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postWorkflowClosure = async (
  requestBody: Svc_PoundRegister_Workflow_ProvideReason
): Promise<APIResponse<IIdentityPacket<PoundRegister> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `${POUND_REGISTER_API}/closure`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
