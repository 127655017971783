import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { ProcessPoolTabDetails } from "@common/pages/settings/system-admin/job-queue/model";
import { getJobQueueDetailsTabById } from "@common/pages/settings/system-admin/job-queue/reference-sidebar/detail/api";

configure({ enforceActions: "always" });

class JobQueueDetailsTabStore {
  private _jobQueueDetailsTabData: ProcessPoolTabDetails | undefined =
    undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get jobQueueDetailsTabData() {
    return this._jobQueueDetailsTabData;
  }
  setJobQueueDetailsTabData = (
    jobQueueDetailsTabData: ProcessPoolTabDetails | undefined
  ) => {
    runInAction(() => {
      this._jobQueueDetailsTabData = jobQueueDetailsTabData;
    });
  };

  get responseLoadError() {
    return this._responseLoadError;
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  loadJobQueueDetailsTabById = async (jobQueueId: number) => {
    let errorResponse;
    this.setIsLoading(true);
    const certificate = await getJobQueueDetailsTabById(jobQueueId);
    if (isSuccessResponse(certificate)) {
      this.setJobQueueDetailsTabData(certificate?.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._jobQueueDetailsTabData = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
}

const jobQueueDetailsTabStoreContext = createContext(
  new JobQueueDetailsTabStore()
);
export const useJobQueueDetailsTabStore = () => {
  return useContext(jobQueueDetailsTabStoreContext);
};
