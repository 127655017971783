import { loadJournalUsages } from "@app/products/property/journals/[id]/components/child-screens/usages/api";
import { colUsages } from "@app/products/property/journals/[id]/components/child-screens/usages/config";
import { scrollToView } from "@common/utils/animation";
import { CCGrid } from "@components/cc-grid/_index";
import React, { RefObject, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

interface IJournalUsagesProps {
  usageRef?: RefObject<any>;
}

export const JournalUsages = ({ usageRef }: IJournalUsagesProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [journalUsagesData, setJournalUsagesData] = useState<any>();

  useEffectOnce(() => {
    setIsLoading(true);
    loadJournalUsages().then((data) => {
      setJournalUsagesData(data);
      setIsLoading(false);
      scrollToView(usageRef);
    });
  });

  useEffect(() => {
    scrollToView(usageRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageRef?.current]);

  return (
    <div className="cc-property-journal-usages" ref={usageRef}>
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                isLoading={isLoading}
                className="cc-journal-usages-grid-data"
                data={journalUsagesData}
                columnFields={colUsages}
                primaryField="Id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
