import { colPPRActionsWithTagCol } from "@app/products/town-planning/ppr/actions/config";
import { TOWN_PLANNING_PPR_ALL_ACTION_VIEW_URL } from "@app/products/town-planning/ppr/actions/contants";
import { IPPRActions } from "@app/products/town-planning/ppr/actions/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/town-planning/ppr/actions/util";
import { isSuccessResponse } from "@common/apis/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DeleteActionButton } from "@common/pages/actions/[id]/components/button/delete-action/_index";
import { NewActionButton } from "@common/pages/actions/[id]/components/button/new-action/_index";
import {
  ActionsContactsTabSwitch,
  ActionsDetailTabSwitch,
  ActionsHistoryTabSwitch,
} from "@common/pages/actions/[id]/components/reference-sidebar/details-switch/_index";
import { ActionListViewBookmarkIcon } from "@common/pages/actions/[id]/components/reference-sidebar/lisview-bookmark/_index";
import { allActionsRoute } from "@common/pages/actions/all-actions/route";
import { getActionDeleteAuthorise } from "@common/pages/actions/api";
import { CheckDueActionsButton } from "@common/pages/actions/components/action-bar/buttons/check-due-actions/_index";
import { ActionsActionBarNavDropdown } from "@common/pages/actions/components/action-bar/nav-dropdown/_index";
import { ActionDeleteAuthorise } from "@common/pages/actions/model";
import { actionsRoute } from "@common/pages/actions/route";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

const nameOf = nameOfFactory<IPPRActions>();

export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [authorises, setAuthorises] = useState<
    ActionDeleteAuthorise | undefined
  >();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.ACTIONS_DASHBOARD,
      1
    );
    await loadActionDeleteAuthorise();
    setIsLoading(false);
  };

  const loadActionDeleteAuthorise = async () => {
    const response = await getActionDeleteAuthorise();
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      setAuthorises(response.data.ReturnObj);
    } else {
      pushNotification({
        autoClose: false,
        title: "Load security action delete failed.",
        type: "error",
      });
    }
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: actionsRoute.name,
    leftComponents: [
      <ActionsActionBarNavDropdown category={allActionsRoute.path} />,
    ],
    centerComponents: [
      <NewActionButton />,
      <CheckDueActionsButton disabled={!hasPermission} />,
      <DeleteActionButton disabled={!hasPermission} authorises={authorises} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ActionListViewBookmarkIcon
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Action}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <ActionsDetailTabSwitch />,
      },
      { title: "Contacts", component: <ActionsContactsTabSwitch /> },
      {
        title: "History",
        component: <ActionsHistoryTabSwitch />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colPPRActionsWithTagCol}
      primaryField={nameOf("ID")}
      state={{
        sort: [{ field: nameOf("RecordedDate"), dir: "desc" }],
      }}
      dataUrl={TOWN_PLANNING_PPR_ALL_ACTION_VIEW_URL}
      disabled={!hasPermission}
    />
  );
});
