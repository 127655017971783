import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useHomePageShortcutsStore } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const HomepageShortcutsHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { homepageShortcuts } = useHomePageShortcutsStore();
  const { id } = useParams<{ id: string }>();
  const homepageShortcutId: number = id ? parseInt(id) : lastSelectedId;

  return (
    <Journal
      id={homepageShortcutId}
      triggersReload={homepageShortcuts}
      recordType={RECORDTYPE.Core_HomePageFunction}
    />
  );
});
