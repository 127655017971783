import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const tobaccoRoute: ICCRoute = {
  path: "tobacco",
  name: "Tobacco",
  enum: HealthManagerMenu.Tobacco,
  children: [
    {
      path: "register",
      name: "Register",
      enum: HealthManagerMenu.PremisesTobacco,
      component: require("./register/_index").default,
    },
    {
      path: "register-light",
      name: "Register",
      enum: HealthManagerMenu.PremisesTobaccoLight,
      //mock route: not implemented yet
      //component: require("./register-light/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: HealthManagerMenu.PremisesTobaccoByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      enum: HealthManagerMenu.PremisesTobaccoBySuburb,
      component: require("./by-suburb/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      enum: HealthManagerMenu.PremisesTobaccoByArea,
      component: require("./by-area/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: HealthManagerMenu.PremisesTobaccoByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "prelodgements",
      name: "Prelodgements",
      enum: HealthManagerMenu.PremisesTobaccoPrelodgements,
      component: require("./prelodgements/_index").default,
    },
    {
      path: "closures",
      name: "Closures",
      enum: HealthManagerMenu.PremisesTobaccoClosures,
      component: require("./closures/_index").default,
    },
    {
      path: "pending-transfers",
      name: "Pending Transfers",
      enum: HealthManagerMenu.PremisesTobaccoPendingTransfers,
      component: require("./pending-transfers/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("../premises/[id]/_index").default,
    },
  ],
};
