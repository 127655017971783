import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Journal } from "@app/core/journal/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadAllBuilding } from "@app/products/building/api";
import { BuildingActionBarNavDropdown } from "@app/products/building/components/action-bar/nav-dropdown/_index";

import { BuildingFieldMapping } from "@app/products/building/model";
import { NewPoolsOrSpasButton } from "@app/products/building/pools-or-spas/components/action-bar/buttons/new-pools-or-spas/_index";
import { colBuildingPoolsSpasRegister } from "@app/products/building/pools-or-spas/register/config";
import { BuildingPoolsOrSpasRegisterBookmark } from "@app/products/building/pools-or-spas/register/util";
import { poolsOrSpasRoute } from "@app/products/building/pools-or-spas/route";
import { BuildingPoolsOrSpasBookmark } from "@app/products/building/pools-or-spas/util";
import { PoolsOrSpasContactsTab } from "@app/products/building/pools-or-spas/[id]/components/reference-sidebar/contacts/_index";
import { PoolsOrSpasDetailTab } from "@app/products/building/pools-or-spas/[id]/components/reference-sidebar/detail/_index";
import { POOLS_OR_SPAS_MANAGE_ROUTE } from "@app/products/building/pools-or-spas/[id]/constant";
import { buildingRoute } from "@app/products/building/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: buildingRoute.name,

    leftComponents: [
      <BuildingActionBarNavDropdown category={poolsOrSpasRoute.path} />,
    ],
    centerComponents: [
      <NewPoolsOrSpasButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Building}
        dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_BuildingApplication}
        recordType={RECORDTYPE.TP_BuildingApplication}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Building}
        recordType={RECORDTYPE.TP_BuildingApplication}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={POOLS_OR_SPAS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Building}
        recordType={RECORDTYPE.BUILDING}
        detail={BuildingPoolsOrSpasBookmark.getBookmarkDetail}
        displayName={BuildingPoolsOrSpasBookmark.getBookmarkDisplayName}
        listViewDetail={
          BuildingPoolsOrSpasRegisterBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          BuildingPoolsOrSpasRegisterBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PoolsOrSpasDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <PoolsOrSpasContactsTab /> },
      {
        title: "History",
        component: <Journal id={0} recordType={RECORDTYPE.BUILDING} />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colBuildingPoolsSpasRegister}
      data={loadAllBuilding() || []}
      primaryField="ID"
      state={{
        sort: [{ field: BuildingFieldMapping.Register_Number, dir: "desc" }],
      }}
    />
  );
};
