import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderInfoExpertContainerComponent } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { INFOXPERTSTORAGEMETHOD } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsInfoExpertAmendmentsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showStorageMethod =
      parseInt(
        valueGetter(
          ECorporateSettingsField.infoXpert_AmendmentInheritApplicationFileNumber.toString()
        )
      ) === 0;

    const showNewTrim =
      parseInt(
        valueGetter(
          ECorporateSettingsField.infoXpert_AmendmentStorageMethod.toString()
        )
      ) === INFOXPERTSTORAGEMETHOD.CREATE;
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <CCSearchComboBoxGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .infoXpert_AmendmentInheritApplicationFileNumber
              ]
            }
            isEditing={isEditing}
          />
          {showStorageMethod && (
            <>
              <CCSearchComboBoxGlobalSettings
                data={
                  configData[
                    ECorporateSettingsField.infoXpert_AmendmentStorageMethod
                  ]
                }
                isEditing={isEditing}
              />
              {renderInfoExpertContainerComponent(
                formRenderProps,
                isEditing,
                configData[
                  ECorporateSettingsField.infoXpert_AmendmentPrimaryFolderID
                ],
                configData[
                  ECorporateSettingsField.infoXpert_AmendmentPrimaryFolderName
                ]
              )}
              {showNewTrim && (
                <>
                  <CCSearchComboBoxGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField.infoXpert_AmendmentIsSubFolder
                      ]
                    }
                    isEditing={isEditing}
                  />
                  <InputGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField.infoXpert_AmendmentTitleFormat
                      ]
                    }
                    isEditing={isEditing}
                  />
                </>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
);
