export const mockInfringements = [
  {
    InfringementId: 1,
    InfringementType: "Failure to comply with Fire Prevention Notice",
    AssessmentNumber: "5576260",
    PropertyAddress: "14 Grays Rd, Fern Tree",
    NameAndAddress: "D C Mackie 14 Grays RdFERN TREE TAS 7054",
    Clearance: "5m Fire Break",
    NoticeSentOn: new Date("30-Aug-2018"),
    ToBeClearedBy: new Date("25-Jul-2018"),
    CreatedOn: new Date("14-Aug-2018 14:10"),
    CreatedBy: "wisem",
    Period: "2018/2019",
    ParcelId: 157810,
    NoticeId: 4,
    CaseId: 4,
  },
];
