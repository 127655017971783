import {
  GE_GeneralSettingsCorporateSetting,
  GE_MailMergeCorporateSetting,
  IN_InspectionsSettingsCorporateSetting,
  NU_NumberSettingsCorporateSetting,
} from "@app/products/local-laws/system-admin/settings/_id/main/constant";
import { ELocalLawsSettingsCategory } from "@app/products/local-laws/system-admin/settings/_id/main/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const mappingCorporateSettingLocalLaws: {
  [key: string]: (ECorporateSettingsField | string)[];
} = {
  // #region General
  [ELocalLawsSettingsCategory.General_GeneralSettings]:
    GE_GeneralSettingsCorporateSetting,
  [ELocalLawsSettingsCategory.General_MailMerge]: GE_MailMergeCorporateSetting,
  // #endregion General

  // #region Numbering
  [ELocalLawsSettingsCategory.Numbering_NumberSettings]:
    NU_NumberSettingsCorporateSetting,

  // #endregion Numbering

  // #region Inspections
  [ELocalLawsSettingsCategory.Inspections_InspectionsSettings]:
    IN_InspectionsSettingsCorporateSetting,
  // #endregion Inspections

  // #region Records

  // #endregion Records
};
