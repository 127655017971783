import { AnimalsKennel } from "@app/products/animals/kennels/[id]/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const AnimalKennelsActiveBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animals - Kennels";
  },

  getBookmarkListViewDetail() {
    return "Animals - Kennels - Active";
  },
};
export const AnimalsKennelBookmark = {
  getBookmarkDisplayName(selectedRow: AnimalsKennel) {
    const dynamicDisplayName = selectedRow?.KennelNo
      ? `- ${selectedRow.KennelNo}`
      : "";
    return `Animals Kennels ${dynamicDisplayName}`;
  },

  getsBookmarkDetail(selectedRow: AnimalsKennel) {
    return getDisplayTextWithDashes([
      selectedRow?.Address ?? "",
      selectedRow?.Owner?.DisplayName ?? "",
    ]);
  },
};
