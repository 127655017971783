import { getDisplayTextWithDashes } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { FormRenderProps } from "@progress/kendo-react-form";
import { ParkingPermitsFieldMapping } from "./model";

export const contactPersonProps = (
  formReactRenderProps: FormRenderProps,
  disabled?: boolean
) => ({
  disabled: disabled,
  placeholder: "Contact Person",
  fieldName: ParkingPermitsFieldMapping.ContactPerson,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.ContactPerson, {
      value: event.value,
    });
  },
});

export const permitHolderProps = (
  formReactRenderProps: FormRenderProps,
  disabled?: boolean
) => ({
  placeholder: "Permit Holder",
  disabled: disabled,
  fieldName: ParkingPermitsFieldMapping.PermitHolder,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.PermitHolder, {
      value: event.value,
    });
  },
});

export const contactNumPersonProps = (
  formReactRenderProps: FormRenderProps
) => ({
  fieldName: ParkingPermitsFieldMapping.ContactNumPerson,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.ContactNumPerson, {
      value: event.value,
    });
  },
});

export const postalAddressProps = (
  formReactRenderProps: FormRenderProps,
  getValue: (props: any) => void | boolean,
  disabled?: boolean,
  disabledButton?: boolean
) => ({
  disabled: disabled,
  placeholder: "Postal Address",
  disabledButton: disabledButton,
  fieldName: ParkingPermitsFieldMapping.PostalAddress,
  value: !getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress)
    ? getValue(ParkingPermitsFieldMapping.PostalAddress)
    : getValue(ParkingPermitsFieldMapping.PermitAddress),
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.PostalAddress, {
      value: event.value,
    });
  },
});

export const isSamePermitAddressProps = (
  formReactRenderProps: FormRenderProps,
  getValue: (props: any) => void | boolean
) => ({
  fieldName: ParkingPermitsFieldMapping.IsSameAsPermitAddress,
  defaultChecked: true,
  onChange: (event: any) => {
    formReactRenderProps.onChange(
      ParkingPermitsFieldMapping.IsSameAsPermitAddress,
      {
        value: event.value,
      }
    );
    !getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress) &&
      formReactRenderProps.onChange(ParkingPermitsFieldMapping.PostalAddress, {
        value: getValue(ParkingPermitsFieldMapping.PermitAddress),
      });
  },
});

export const behalfOfProps = (
  formReactRenderProps: FormRenderProps,
  disabled: boolean,
  disabledButton: boolean
) => ({
  placeholder: "On behalf of",
  fieldName: ParkingPermitsFieldMapping.BehalfofProps,
  disabled: disabled,
  disabledButton: disabledButton,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.BehalfofProps, {
      value: event.value,
    });
  },
});
export const onBehalfOfProps = (formReactRenderProps: FormRenderProps) => ({
  fieldName: ParkingPermitsFieldMapping.OnBehalfOf,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.OnBehalfOf, {
      value: event.value,
    });
  },
});

export const fileNumberProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "File Number",
  fieldName: ParkingPermitsFieldMapping.FileNumber,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.FileNumber, {
      value: event.value,
    });
  },
});

export const commentsProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Comments",
  fieldName: ParkingPermitsFieldMapping.Comments,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.Comments, {
      value: event.value,
    });
  },
});

export const homePhoneProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Home Phone",
  fieldName: ParkingPermitsFieldMapping.HomePhone,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.HomePhone, {
      value: event.value,
    });
  },
});

export const businessPhoneProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Business Phone",
  fieldName: ParkingPermitsFieldMapping.BusinessPhone,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.BusinessPhone, {
      value: event.value,
    });
  },
});

export const mobilePhoneProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Mobile Phone",
  fieldName: ParkingPermitsFieldMapping.MobilePhone,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.MobilePhone, {
      value: event.value,
    });
  },
});

export const registrationNoProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Registration Number",
  fieldName: ParkingPermitsFieldMapping.RegNo,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.RegNo, {
      value: event.value,
    });
  },
});

export const makeModelProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Make/Model",
  fieldName: ParkingPermitsFieldMapping.Make_Model,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.Make_Model, {
      value: event.value,
    });
  },
});

export const specialPurposeProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Special Purpose",
  fieldName: ParkingPermitsFieldMapping.SpecialPurpose,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.SpecialPurpose, {
      value: event.value,
    });
  },
});

export const onlyValidProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Only Valid",
  fieldName: ParkingPermitsFieldMapping.OnlyValid,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.OnlyValid, {
      value: event.value,
    });
  },
});

export const vehicleTypeProps = (formReactRenderProps: FormRenderProps) => ({
  placeholder: "Vehicle Type",
  fieldName: ParkingPermitsFieldMapping.VehicleType,
  onChange: (event: any) => {
    formReactRenderProps.onChange(ParkingPermitsFieldMapping.VehicleType, {
      value: event.value,
    });
  },
});

export const tablePermitType: IColumnFields[] = [
  {
    title: "Permit Type",
    field: "PermitType",
  },
];

export const ParkingPermitsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Parking Permits ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow?.PermitHolder ?? "No Name",
      selectedRow?.PermitType,
    ]);
  },
};
