import { Attachment } from "@app/core/documents/model";
import { AddressBook } from "@app/products/animals/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Keyword, Keyword_Tags } from "@common/models/keyword";

export interface WasteWaterSummaryLOVs {
  WaterAuthority: IKeyValuePacket[];
  Status: IKeyValuePacket[];
  RiskLevel: IKeyValuePacket[];
  PermitType: IKeyValuePacket[];
}

export interface WasteWaterSystemRegisterSummary {
  System_ID: number;
  PermitNumber: string;
  Address: Address;
  Owner: Contact_Relation;
  Applicant: Contact_Relation;
  AllotmentSize: number | null;
  DateReceived: Date | null;
  GPSofTank: string;
  SystemType: SystemType;
  SystemType_ID: number;
  SecondarySystemSize: number | null;
  SecondarySystemType: SystemType;
  SecondarySystemType_ID: number | null;
  SystemSize: number | null;
  TertiarySystemType: SystemType;
  TertiarySystemType_ID: number | null;
  TertiarySystemSize: number | null;
  WaterAuthority: Keyword;
  WaterAuthority_KWD: number | null;
  Plumber: Contact_Relation;
  Contractor: Contact_Relation;
  Status: Keyword;
  Status_KWD: number;
  RiskLevel: Keyword;
  RiskLevel_KWD: number | null;
  TypeOfApplication: Keyword;
  TypeOfApplication_KWD: number | null;
  DebtorNo: string;
  FileNumber: string;
  Assessment_ApprovalPTIDate: Date | null;
  Assessment_ApprovalPTIOfficer: SiteUser;
  Assessment_ApprovalPTIOfficer_ID: number | null;
  Assessment_FinalAssessmentCTUOfficer: SiteUser;
  Assessment_FinalAssessmentCTUOfficer_ID: number | null;
  RespAuthApproved: boolean | null;
  Assessment_FinalAssessmentCTUDate: Date | null;
  OSFees: number;
  ComplianceCertificateNo: string;
  ApproveForBLDate: Date | null;
  BLicenceNumber: string;
  SentToDOHDate: Date | null;
  DOHApprovalDate: Date | null;
  DOHApprovalNumber: string;
  SerialNumber: string;
  LastInspection: Date | null;
  InspectionFrequency: number | null;
  NextInspection: Date | null;
  LastServiced: Date | null;
  LastDesludged: Date | null;
  LastSampled: Date | null;
  NextService: Date | null;
  NextDesludge: Date | null;
  NextSample: Date | null;
  Comments: string;
  Sys_TimeStamp: string;
}

export interface SystemType extends DTOBaseClass_Standard {
  SystemType_ID: number;
  Manufacturer: string;
  Name: string;
  Size: number | null;
  Material: string;
  EPANumber: string;
  Description: string;
  Conditions: string;
  ServiceFrequency: number | null;
  SampleFrequency: number | null;
  DesludgeFrequency: number | null;
  SystemTypeClassification_KWD: number | null;
  SystemTypeClassification_Name: string;
  Flag_Size_In_Litre: boolean | null;
  Numbering_Prefix: string;
  Numbering_Suffix: string;
}

export interface WasteWaterSummaryLite {
  ID: number;
  PermitNumber: string;
  Status: IKeyValuePacket;
  FileNumber: string;
  OSFees: number;
  SiteAddress: string;
  Contact: string;
  AllotmentSize: number | null;
  Received: Date | null;
}

export interface SystemTypeSelection {
  SystemType_ID: number;
  Name: string;
  Size: number | null;
  Manufacturer: string;
  Description: string;
  SystemTypeClassification: string;
}

export interface Address {
  Address_ID: number;
  AddressClassification_Name: string;
  ContactAddressType_KWD: number | null;
  ContactAddressType_Name: string;
  AddressClassification_ENUM: AddressClassification;
  PropertyName: string;
  UnitNo: string;
  StreetNo: string;
  StreetName: string;
  Suburb: string;
  State: string;
  Postcode: string;
  LGA: string;
  AreaCode: string;
  Region: string;
  Location_Description: string;
  Map_Reference: string;
  AssessmentNo: string;
  NearestCrossStreet: string;
  AddressBoxType_KWD: number | null;
  AddressBoxType_Name: string;
  PoBoxNo: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Formatted_SingleLine: string;
  AssessmentUpdated: boolean | null;
  Country: string;
  Country_KWD: number | null;
  PropertyType: string;
  PropertySubType: string;
  OwnerOccupier: boolean | null;
  RIN: string;
  ComplexAddress: boolean;
  PersonRole_KWD: number | null;
  PersonCategory_KWD: number | null;
  Flag_StreetName_HasParts: boolean;
  StreetNamePart_Name: string;
  StreetNamePart_Type: string;
  PropertyAssessment_ID: number | null;
  PropertyAssessment: PropertyAssessment;
  Flag_IsCouncilAddress: boolean;
  AddressValidationType_Name: string;
  PostalDelivery_Identifier: string;
  PostalDelivery_Barcode: string;
  VMAS_Verified: boolean | null;
  VMAS_Confidence: number | null;
  VMAS_GeoCode: string;
  VMAS_PosNorthSouth: number | null;
  VMAS_PosEastWest: number | null;
  Mapshare_Magickey: string;
  NSWAddressPredictiveID: string;
  PropertyAlert: string;
  Assessment_ID: number | null;
  FullAddress: string;
  FullAddress_HTML: string;
  FullAddress_Text: string;
  FullAddress_SingleLine: string;
  Formatted_AddressLine1: string;
  Formatted_AddressLine2: string;
  Formatted_AddressLine3: string;
  Formatted_AddressLine4: string;
  VERIFICATION_AUSPOST: string;
  VERIFICATION_VMAS2: string;
}

export interface PropertyAssessment {
  PropertyAssessment_ID: number;
  Flag_PrimaryAddress: boolean;
  PropertyName: string;
  UnitAbbreviation: string;
  UnitPrefix1: string;
  UnitNumber1: number | null;
  UnitSuffix1: string;
  UnitPrefix2: string;
  UnitNumber2: number | null;
  UnitSuffix2: string;
  FloorTypeAbbreviation: string;
  FloorPrefix1: string;
  FloorNumber1: number | null;
  FloorSuffix1: string;
  FloorPrefix2: string;
  FloorNumber2: number | null;
  FloorSuffix2: string;
  BuildingName: string;
  ComplexName: string;
  HousePrefix1: string;
  HouseNumber1: number | null;
  HouseSuffix1: string;
  HousePrefix2: string;
  HouseNumber2: number | null;
  HouseSuffix2: string;
  GIS_Lat_Lng: string;
  AddressLocationDescriptor: string;
  NearestCrossStreet: string;
  AddressMapNumber: string;
  AddressMapReference: string;
  Address_PFI: string;
  Address_PostalDeliveryID: string;
  Flag_RuralHouseNumber: boolean;
  Flag_OutsideProperty: boolean;
  GIS_Longitude: number | null;
  GIS_Latitude: number | null;
  Display__Building_Unit_Number: string;
  Display__Floor_Number: string;
  Display__House_Number: string;
  Locality_Name: string;
  Locality_Postcode: string;
  Locality_PFI: string;
  Locality_Code: string;
  Street_Name: string;
  Street_TypeAbbreviation: string;
  Street_Suffix: string;
  Street_Comments: string;
  Street_Road_PFI: string;
  Display__StreetName: string;
  SL_EvenStartRange: number | null;
  SL_EvenEndRange: number | null;
  SL_OddStartRange: number | null;
  SL_OddEndRange: number | null;
  SL_MapNumber: string;
  SL_MapReference: string;
  SL_Locality_PFI: string;
  PID_No: string;
  RIN: string;
  Ward: string;
  Area: string;
  Parish: string;
  CrownAllotment: string;
  Section: string;
  LotNo: string;
  PlanRef: string;
  LP_PS: string;
  Volume: string;
  Folio: string;
  County: string;
  Township: string;
  Zones: string;
  Overlay: string;
  Legal_Description: string;
  CertOfTitle: string;
  Block: string;
  Assessment_LandSize: number | null;
  Valuation_Number: string;
  Parcel_ID: number | null;
  Parcel_Reference: string;
  Owners_Entity_ID: number | null;
  Owners_Name: string;
  Owners_Attention_Of: string;
  Owners_Care_Of: string;
  Owners_Address: string;
  Owners_StreetNumber: string;
  Owners_Locality: string;
  Owners_State: string;
  Owners_Postcode: string;
  Owners_Country: string;
  Owners_Phone: string;
  Owners_Email: string;
  Owners_Mobile: string;
  Owners_Fax: string;
  Owners_ABN: string;
  Owners_ACN: string;
  Owners_ARBN: string;
  Owners_Display: string;
  RatePayers_Entity_ID: number | null;
  RatePayers_Name: string;
  RatePayers_Attention_Of: string;
  RatePayers_Care_Of: string;
  RatePayers_Address: string;
  RatePayers_Locality: string;
  RatePayers_State: string;
  RatePayers_Postcode: string;
  RatePayers_Country: string;
  RatePayers_Phone: string;
  RatePayers_Email: string;
  RatePayers_Mobile: string;
  RatePayers_Fax: string;
  OwnerNARIDs: number[];
  RatePayers_Display: string;
  Assessment_Display: string;
  Assessment_ID: number | null;
  Title_ID: number | null;
  ZoneIDs: number[];
  OverlayIDs: number[];

  //TODO: Currently added from client side to handle save temporary values used for InputPickerSearch
  //Need to confirm with onshore to save and get these fields
  Zone_Display?: CoreKeyword[];
  Overlay_Display?: CoreKeyword[];
}

export interface Contact_Relation {
  RID: number;
  ContactRelationshipType_ENUM: string;
  ContactRelationshipType_Name: string;
  Contact: Contact;
  DisplayName: string;
  BubbleUps: BubbleUpIdentifier[];
  _ExtensionType: string[];
  ExtensionType: string[];
  Extension?: string;
  PersonRole_KWD: number | null;
  PersonRole: Keyword;
  RelationshipComment: string;
  _KeywordTags: Keyword_Tags[];
  KeywordTags: Keyword_Tags[];
  SaveTriggers: string[];
}
export interface BubbleUpIdentifier {
  PKID: number;
  SourceIdentifier: RecordSourceIdentifier;
  BubbleUpType_ENUM: BubbleUpType;
  BubbleUpType_Name: string;
  Sys_CreatedDate: Date;
  Sys_DeactivationDate: Date | null;
  RecordSource_Description?: string;
}

export enum BubbleUpType {
  SystemInitialise = "SystemInitialise",
  Parent = "Parent",
  Ancestor = "Ancestor",
  Association = "Association",
  Shared = "Shared",
}

export interface RecordSourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
}

export interface Contact extends DTOBaseClass_Standard {
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: string;
  ContactClassification_Name: string;
  PreferredMethod_ENUM: ContactMethodPreferred;
  PreferredMethod_Name: string;
  PreferredMethod_Formatted: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  Fax: string;
  Mobile: string;
  HomePhone: string;
  WorkPhone: string;
  NARNumber: string;
  NARUpdated: boolean | null;
  Flag_SendSMSAlerts: boolean;
  Queued_Sys_DBRowState: string | null;
  Custom_Tags: string;
  Officer_Ticket_Counter: number;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  VIP: boolean | null;
  VIPTypeID: number | null;
  VIPType: string;
  VIPRelationshipOwnerID: number | null;
  VIPRelationshipOwner: string;
  ContactRoles_Count: number;
  PreferredAddress: Address;
  Organisation: string;
  Preferred_ContactAddressType_KWD: number | null;
  Preferred_ContactAddressType_Name: string;
  PostalAddress: Address;
  Postal_ContactAddressType_KWD: number | null;
  Postal_ContactAddressType_Name: string;
  AlternateAddress: Address;
  Alternate_ContactAddressType_KWD: number | null;
  Alternate_ContactAddressType_Name: string;
  AddressBook: AddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  DisplayName_Format1: string;
  DisplayName_Format2: string;
  DisplayName_WithSalutation: string;
  DisplayName_PlusPhoneNumbers: string;
  FullContactDetails: string;
  FullContactDetailsWithBR: string;
  DisplayName_CM: string;
  SynchDate: Date;
  ExtensionType: string[];
  Extension: Contact_Extension;
  Contact_CopiedFrom_PortalUser_MemberGUID: string | null;
  Contact_CopiedFrom_PortalUser_MemberUserName: string;
  ContactAlert: string;
  MergedContact_ID: number | null;
  Flag_Duplicate: boolean | null;
  IsAdministrativeAccount: boolean | null;
  ContactComments: string;
  Qualifications: string;
  SaveTriggers: string[];
  IsContactCopied: boolean;
  IDCardNumber: string;
  UserType_ENUM: string | null;
  UserType_Name: string;
  Photo_Attachment_ID: number | null;
  Photo_Attachment: Attachment;
  Sign_Attachment_ID: number | null;
  Sign_Attachment: Attachment;
  AuthorisedActs_KWDs: string;
  Qualification_KWD: number | null;
  Qualification_Name: string;
  Date_IoA: Date | null;
  Has_IoA_Location: boolean | null;
  Date_DeliveryLGIS: Date | null;
  Signed_IoA_Attachment_ID: number | null;
  Signed_IoA_Attachment: Attachment;
  Date_CardOrginalIssue: Date | null;
  Date_CardProduced: Date | null;
  Date_CardReceivedSigned: Date | null;
  Date_CardReIssue: Date | null;
  Date_CardReIssueReceivedSigned: Date | null;
  AQN: string;
  PortalStatus: string;
  ABNValid: boolean;
  ACNValid: boolean;
  GoldenRecord: boolean;
  ACO: boolean | null;
  BIOACTOfficer: boolean | null;
  LLSACTOfficer: boolean | null;
  ApprovedRoles: string;
}

export interface Contact_Extension {
  DateOfBirth: Date | null;
  VerificationQuestion: string;
  VerificationResponse: string;
  JobTitle: string;
  Organisation: string;
  ContactAttention: string;
  ShowContactAttention: boolean | null;
  FSSCertificate: string;
  FSSCertificateExpiryDate: Date | null;
  LicenceNo: string;
  FSSCertificateNo: string;
  CM_PlaceOfBirth: string;
  CM_Contactable: boolean | null;
  CM_Deceased: boolean | null;
  DebtorNo: string;
  CM_LastKnownAddress_Id: number | null;
  CM_DatePassedAway: Date | null;
  CM_LastKnownAddress: Address;
  Gender: string;
  AgeAtDeath: number | null;
  Language_ID: number | null;
  Language: Keyword;
  SecondLanguage: string;
  DisabilityType_KWD: number | null;
  DisabilityDetails: string;
  Lst_Disability_Category: number[];
  Lst_Disability_SubCategory: number[];
  TTY: string;
  Prisoner: boolean | null;
  CRNNumber: string;
  AboriginalStatus_KWD: number | null;
  LanguageAtHome: boolean | null;
  LanguageAtHome_KWD: number | null;
  Officer_Ticket_Counter: number | null;
}
export interface SiteUser extends Contact {
  MemberUserName: string;
  MemberGUID: string;
  Salutation: string;
  MiddleName: string;
  Staff_ID: string;
  LocationName: string;
  PositionName: string;
  PositionNumber: string;
  Manager: string;
  ManagerUserName: string;
  Department: string;
  HomePage_Name: string;
  DefaultActionPage_Name: string;
  PersonGroups: IKeyValuePacket[];
  Flag_Councillor: boolean | null;
  Flag_ExternalUser: boolean | null;
  Ward: string;
  Flag_CRMS_StandardForm: boolean | null;
  Flag_CRMS_Escalation_ActionOfficer_OutstandingNotifications: boolean | null;
  CRS_Centre_Access_Centre_ID: number | null;
  HealthArea_KWD: number | null;
  HealthArea_Name: string;
  PermitArea_KWD: number | null;
  PermitArea_Name: string;
  TRIMClientBinariesLoadPath: string;
  SSMSOrganisation_Name: string;
  IsOpenOfficeAdmin: boolean | null;
  SSMS_TeamLeaderID: number | null;
  SSMS_CouncilLeaderID: number | null;
  SSMSVICRoads_RegionID: number | null;
  SSMSCouncil_LGAID: number | null;
  OrgUnits: IKeyValuePacket[];
  SecurityOrgUnits: IKeyValuePacket[];
  FullName_Short: string;
  FullName_Salutation: string;
  FullName: string;
  Theme_LKP: number | null;
  Theme_Name: string;
  ACO: boolean | null;
  UserName: string;
  UserPassword: string;
  PasswordQuestion: string;
  PasswordAnswer: string;
  bResetPasswordOnFirstLogon: boolean;
  EnterpriseKey_Set: string;
  DocMgmt_Username: string;
  DocMgmt_Password: string;
  SiteUser_ActingOfficer_ID: number | null;
  SiteUser_ActingOfficer: SiteUser;
  Flag_OnLeave: boolean | null;
  OnLeave_StartDate: Date | null;
  OnLeave_EndDate: Date | null;
}

export enum AddressClassification {
  Generic = 0,
  LGA = 1,
  International = 2,
  POBox = 3,
  HM_Contact = 4,
  Premises = 5,
  DescriptiveAddress = 6,
  Property = 7,
  DFSV_Area_Generic = 100,
  DFSV_Area_POBox = 101,
  Line_Address = 102,
  Consultant_Address = 103,
  Scheme = 104,
  Lots = 105,
}

export enum FORMATTYPE_ADDRESS {
  SystemInitialise = 0,
  HTML = 1,
  VBSTRING = 2,
  SINGLELINE = 3,
  INLINE = 4,
  GOOGLEMAPS = 5,
}

export enum ContactMethodPreferred {
  None = "None",
  WorkPhone = "WorkPhone",
  HomePhone = "HomePhone",
  Email = "Email",
  Fax = "Fax",
  Mobile = "Mobile",
  Post = "Post",
  EmailAndPost = "EmailAndPost",
  TTY = "TTY",
}

export interface IWasteWater {
  ID?: number;
  PermitNumber?: string;
  Applicant?: string;
  Owner?: string;
  Address?: string;
  Suburb?: string;
  AssessmentNo?: number;
  Status?: string;
  TypeOfApplication?: string;
  FileNumber?: number;
  SystemName?: string;
  SystemType?: string;
  Received?: Date;
  PTI?: Date;
  CTU?: string;
  WaterAuthority?: string;
  Plumber?: string;
  Risk?: string;
  CertificateNo?: number;
  SerialNo?: number;
  Details?: string;
  ApplicantAddress?: string;
  ApplicantContact?: string;
  AllotmentSize?: number;
  GPS?: number;
  Manufacturer?: string;
  TankSize?: number;
  UnitPrimary?: string;
  PrimarySystemTypeClassification?: string;
  SecondarySystemType?: string;
  SecondaryManufacturer?: string;
  SecondaryTankSize?: number;
  UnitSecondary?: string;
  TertiarySystemType?: string;
  TertiaryManufacturer?: string;
  TertiaryTankSize?: number;
  UnitTertiary?: string;
  TertiarySystemTypeClassification?: string;
  Contractor?: string;
  RiskLevel?: string;
  PermitType?: string;
  ConstructionType?: string;
  DebtorNo?: number;
  FileNo?: number;
  Issued?: Date;
  IssuingOfficer?: string;
  RespAuthApproved?: boolean;
  ApprovalToUse?: Date;
  ApprovalOfficer?: string;
  OutstandingFees?: number;
  OSFees?: number;
  ComplainceCertNo?: number;
  ApprovalForBL?: Date;
  BLicenceNumber?: number;
  SentToDoH?: Date;
  DoHApproval?: string;
  DateDoHApproved?: Date;
  DoHApprovalNo?: string;
  SerialNumber?: number;
  LastInspection?: Date;
  Frequency?: number;
  NextInspection?: Date;
  LastService?: Date;
  LastDesludge?: Date;
  LastSample?: Date;
  NextService?: Date;
  NextDesludge?: Date;
  NextSample?: Date;
  Comments?: string;
  Size?: number;
  DateInspected?: Date;
  InspectionType?: string;
  Officer?: string;
  DateInspectedMonth?: string;
  DueStatus?: string;
  Material?: string;
  InspectionFrequency?: number;
  ReferenceNumber?: number;
  ContactName?: string;
  Compliance?: string;
  Reason?: string;
  RefNo?: number;
  DateTarget?: Date;
  Source?: string;
  Category?: string;
  Location?: string;
  RequestedBy?: string;
  ContactDetails?: string;
  DateRecorded?: Date;
  RecordedBy?: string;
  OrgStructure?: string;
  ActionOfficer?: string;
  Coordinator?: string;
  DateWork?: Date;
  EnquiryRefNo?: number;
  Event_ReferenceNoAdditional?: number;
  OnBehalfOf?: string;
  Flag_ITSupport?: boolean;
  Flag_Safety?: boolean;
  Flag_Risk?: boolean;
  Flag_VIP?: boolean;
  DateServed?: Date;
  Type?: string;
  DateDue?: Date;
  DateRevoked?: Date;
  Noticee?: string;
  CreatedDate?: Date;
  FeeType?: string;
  AmountIssued?: number;
  AmountOutstanding?: number;
  CostCode?: string;
  InvoiceNumber?: number;
  Name?: string;
  SystemTypeClassification?: string;
  SortIndex?: number;
  Title?: string;
  Condition?: string;
  Description?: string;
  Flag_AuthApproved?: string;
}
