import { IRegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/model";

export const mockMeters: IRegisterMeters[] = [
  {
    Id: 1,
    Utility: "Water",
    MeterNumber: "17B063013",
    ReadingRoute: "Kennedia Crescent",
    SequenceInRoute: 60,
    Category: "Water Residential",
    UsagePercentage: 100,
    LastReading: 1354,
    LastRead: new Date("30-Jun-2021"),
    DateAttached: new Date("30-Jun-2018"),
    NextReadDate: new Date("30-Sep-2021"),
    ReadingFrequency: 3,
    AverageDaily: 1.018,
    UnitOfMeasure: "Kilolitre",
    MeterLocation: "7b Kennedia Crescent",
    InstallationDate: new Date("8-Nov-2017"),
    MeterSize: 20,
    Status: "Active",
    MeterId: 5322,
    ScheduleRead: true,
    BillingGroup: "Standard Periodic Billing",
  },
  {
    Id: 2,
    Utility: "Water",
    MeterNumber: "90441",
    ReadingRoute: "Kennedia Crescent",
    SequenceInRoute: 60,
    Category: "Water Residential",
    UsageEntitlement: 100,
    LastReading: 1268,
    LastRead: new Date("8-Nov-2017"),
    DateRemoved: new Date("8-Nov-2017"),
    ReadingOnRemoval: 1268,
    NumberOfDials: 8,
    NextReadDate: new Date("30-Sep-2017"),
    ReadingFrequency: 3,
    AverageDaily: 0.4884,
    UnitOfMeasure: "Kilolitre",
    MeterLocation: "7b Kennedia Crescent",
    MeterSize: 20,
    Status: "Retired",
    MeterId: 912,
    ScheduleRead: true,
    BillingGroup: "Standard Periodic Billing",
  },
  {
    Id: 3,
    Utility: "Water",
    MeterNumber: "17B063013",
    ReadingRoute: "Kennedia Crescent",
    SequenceInRoute: 60,
    Category: "Water Residential",
    UsageEntitlement: 100,
    LastReading: 1354,
    LastRead: new Date("30-Jun-2021"),
    DateAttached: new Date("8-Nov-2017"),
    DateRemoved: new Date("30-Jun-2018"),
    ReadingOnRemoval: 263,
    NextReadDate: new Date("30-Sep-2021"),
    ReadingFrequency: 3,
    AverageDaily: 1.018,
    UnitOfMeasure: "Kilolitre",
    MeterLocation: "7b Kennedia Crescent",
    InstallationDate: new Date("8-Nov-2017"),
    MeterSize: 20,
    Status: "Active",
    MeterId: 5999,
    ScheduleRead: true,
    BillingGroup: "Standard Periodic Billing",
  },
];
