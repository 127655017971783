import {
  apiCoreFindDuplicateContact,
  apiMergeContacts,
  apiSetupMergeContacts,
} from "@app/core/components/common/utils";
import {
  Svc_MergeContacts,
  Svc_SetupMergeContacts,
} from "@app/core/contacts/merge-contact/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getFindDuplicateContact = async (): Promise<
  APIResponse<IdentityPacket | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<IdentityPacket>(
      apiCoreFindDuplicateContact()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSetupMergeContacts = async (
  contactIds: number[]
): Promise<
  APIResponse<IIdentityPacket<Svc_SetupMergeContacts> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Svc_SetupMergeContacts>
    >(apiSetupMergeContacts(), contactIds);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postMergeContacts = async (
  mergeContacts: Svc_MergeContacts
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiMergeContacts(),
      mergeContacts
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
