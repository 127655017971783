import ChangeInstalmentDialog from "@app/products/property/assessments/components/dialogs/change-instalments/_index";
import { CommunityProperty } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeInstalmentButton = observer(() => {
  const { isVic } = CommunityProperty.getFlagOfStates();
  const [showChangeInstalmentDialog, setShowChangeInstalmentDialog] =
    useState(false);

  return (
    <>
      <CCNavButton
        title="Change instalment"
        onClick={() => setShowChangeInstalmentDialog(true)}
        disabled // The requirement in TP-363: disabled it since the functionality is not implemented for now.
        invisible={!isVic}
      />

      {showChangeInstalmentDialog && (
        <ChangeInstalmentDialog
          onClose={() => {
            setShowChangeInstalmentDialog(false);
          }}
        />
      )}
    </>
  );
});
