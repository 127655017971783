import { InfoXpertContainerInputPicker } from "@app/core/infoExpert/container/_index";
import { SharePointContentTypesInputPicker } from "@app/core/sharepoint/content-types/_index";
import { SharePointDocumentLibraryInputPicker } from "@app/core/sharepoint/document-libraries/_index";
import { TrimClassificationInputPicker } from "@app/core/trim/trim-classification/_index";
import { TrimContainerInputPicker } from "@app/core/trim/trim-container/_index";
import { TrimRecordTypeInputPicker } from "@app/core/trim/trim-record-type/_index";
import {
  SettingField,
  SettingsDataType,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import {
  Svc_InfoExpertContainer,
  Svc_SharepointContentType,
  Svc_SharepointDocumentLibrary,
  Svc_TrimContainer,
} from "@common/models/corporateSetting";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export const renderTrimNewComponents = (
  formRenderProps: FormRenderProps,
  isEditing: boolean,
  configPrimaryContainerData: SettingField,
  configClassificationData: SettingField,
  configRecordTypeData: SettingField
) => {
  const { valueGetter, onChange } = formRenderProps;
  const selectedTrimContainerRows = valueGetter(
    `option.ValueDisplayDetails_${configPrimaryContainerData.FieldName}`
  );
  const selectedTrimClassificationRows = valueGetter(
    `option.ValueDisplayDetails_${configClassificationData.FieldName}`
  );
  const selectedTrimRecordTypeRows = valueGetter(
    `option.ValueDisplayDetails_${configRecordTypeData.FieldName}`
  );

  return (
    <>
      <div className="cc-field">
        <label className="cc-label">
          {configPrimaryContainerData.Title}
          {configPrimaryContainerData.IsMandatory && (
            <CCTooltip type="validator" position="right" />
          )}
        </label>
        <Field
          name={`option.ValueDisplayDetails_${configPrimaryContainerData.FieldName}`}
          component={TrimContainerInputPicker}
          disabled={!isEditing}
          validator={
            configPrimaryContainerData.IsMandatory
              ? requiredValidator
              : undefined
          }
          onChange={(value: Svc_TrimContainer[] | null) => {
            if (configPrimaryContainerData && value) {
              onChange(configPrimaryContainerData.FieldName, {
                value: value?.[0].ID,
              });
              onChange(
                `option.ValueDisplayDetails_${configPrimaryContainerData.FieldName}`,
                {
                  value,
                }
              );
            }
          }}
          showClearButton={false}
          selectedRows={selectedTrimContainerRows}
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">
          {configClassificationData.Title}
          {configClassificationData.IsMandatory && (
            <CCTooltip type="validator" position="right" />
          )}
        </label>
        <Field
          name={`option.ValueDisplayDetails_${configClassificationData.FieldName}`}
          component={TrimClassificationInputPicker}
          disabled={!isEditing}
          validator={
            configClassificationData.IsMandatory ? requiredValidator : undefined
          }
          onChange={(value: Svc_TrimContainer[] | null) => {
            if (configClassificationData && value) {
              onChange(configClassificationData.FieldName, {
                value: value?.[0].ID,
              });
              onChange(
                `option.ValueDisplayDetails_${configClassificationData.FieldName}`,
                {
                  value,
                }
              );
            }
          }}
          showClearButton={false}
          selectedRows={selectedTrimClassificationRows}
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">
          {configRecordTypeData.Title}
          {configRecordTypeData.IsMandatory && (
            <CCTooltip type="validator" position="right" />
          )}
        </label>
        <Field
          name={`option.ValueDisplayDetails_${configRecordTypeData.FieldName}`}
          component={TrimRecordTypeInputPicker}
          disabled={!isEditing}
          validator={
            configRecordTypeData.IsMandatory ? requiredValidator : undefined
          }
          onChange={(value: Svc_TrimContainer[] | null) => {
            if (configRecordTypeData && value) {
              onChange(configRecordTypeData.FieldName, {
                value: value?.[0].ID,
              });
              onChange(
                `option.ValueDisplayDetails_${configRecordTypeData.FieldName}`,
                {
                  value,
                }
              );
            }
          }}
          selectedRows={selectedTrimRecordTypeRows}
          showClearButton={false}
        />
      </div>
    </>
  );
};

export const renderInfoExpertContainerComponent = (
  formRenderProps: FormRenderProps,
  isEditing: boolean,
  configContainerIDData: SettingField,
  configContainerNameData: SettingField
) => {
  const { valueGetter, onChange } = formRenderProps;
  const selectedContainerRows = valueGetter(
    `option.ValueDisplayDetails_${configContainerNameData.FieldName}`
  );
  return (
    <>
      <div className="cc-field">
        <label className="cc-label">
          {configContainerNameData.Title}
          {configContainerNameData.IsMandatory && (
            <CCTooltip type="validator" position="right" />
          )}
        </label>
        <Field
          name={`option.ValueDisplayDetails_${configContainerNameData.FieldName}`}
          component={InfoXpertContainerInputPicker}
          disabled={!isEditing}
          validator={
            configContainerNameData.IsMandatory ? requiredValidator : undefined
          }
          onChange={(value: Svc_InfoExpertContainer[] | null) => {
            if (configContainerNameData) {
              onChange(configContainerIDData.FieldName, {
                value: value?.[0].ID,
              });
              onChange(configContainerNameData.FieldName, {
                value: value?.[0].Description,
              });
              onChange(
                `option.ValueDisplayDetails_${configContainerNameData.FieldName}`,
                {
                  value,
                }
              );
            }
          }}
          selectedRows={selectedContainerRows}
          showClearButton={true}
        />
      </div>
    </>
  );
};

export const renderSharepointComponents = (
  formRenderProps: FormRenderProps,
  isEditing: boolean,
  enableInputPicker: boolean,
  configDocumentLibraryData: SettingField,
  configContentTypeData: SettingField
) => {
  const { valueGetter, onChange } = formRenderProps;
  const selectedDocumentLibRows = valueGetter(
    `option.ValueDisplayDetails_${configDocumentLibraryData.FieldName}`
  );
  const selectedContentTypeRows = valueGetter(
    `option.ValueDisplayDetails_${configContentTypeData.FieldName}`
  );
  return (
    <>
      {enableInputPicker ? (
        <>
          {configDocumentLibraryData.DataType === SettingsDataType.Lookup && (
            <div className="cc-field">
              <label className="cc-label">
                {configDocumentLibraryData.Title}
                {configDocumentLibraryData.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
                {configDocumentLibraryData.HelpText && (
                  <CCTooltip
                    type="custom"
                    content=" "
                    position="auto"
                    clickToOpen
                    customTemplate={
                      <>{sanitizeHtml(configDocumentLibraryData.HelpText)}</>
                    }
                  >
                    <i className="fa fa-info-circle ml-1 text-accent" />
                  </CCTooltip>
                )}
              </label>
              {configDocumentLibraryData?.Description && (
                <label className="cc-settings-description">
                  {sanitizeHtml(configDocumentLibraryData?.Description)}
                </label>
              )}
              <Field
                name={`option.ValueDisplayDetails_${configDocumentLibraryData.FieldName}`}
                component={SharePointDocumentLibraryInputPicker}
                disabled={!isEditing}
                validator={
                  configDocumentLibraryData.IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: Svc_SharepointDocumentLibrary[] | null) => {
                  if (configDocumentLibraryData) {
                    onChange(configDocumentLibraryData.FieldName, {
                      value: value?.[0].ID,
                    });
                    onChange(
                      `option.ValueDisplayDetails_${configDocumentLibraryData.FieldName}`,
                      {
                        value,
                      }
                    );
                  }
                }}
                selectedRows={selectedDocumentLibRows}
                showClearButton={true}
              />
            </div>
          )}
          {configContentTypeData.DataType === SettingsDataType.Lookup && (
            <div className="cc-field">
              <label className="cc-label">
                {configContentTypeData.Title}
                {configContentTypeData.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${configContentTypeData.FieldName}`}
                component={SharePointContentTypesInputPicker}
                disabled={!isEditing}
                validator={
                  configContentTypeData.IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: Svc_SharepointContentType[] | null) => {
                  if (configContentTypeData) {
                    onChange(configContentTypeData.FieldName, {
                      value: value?.[0].ID,
                    });
                    onChange(
                      `option.ValueDisplayDetails_${configContentTypeData.FieldName}`,
                      {
                        value,
                      }
                    );
                  }
                }}
                selectedRows={selectedContentTypeRows}
                showClearButton
              />
            </div>
          )}
        </>
      ) : (
        <>
          <InputGlobalSettings
            data={configDocumentLibraryData}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={configContentTypeData}
            isEditing={isEditing}
          />
        </>
      )}
    </>
  );
};
