import { postSaveRecall } from "@app/core/recall/[id]/api";
import {
  RECALL_MANAGE_ROUTE,
  RECALL_SLIDER,
} from "@app/core/recall/[id]/constant";
import {
  changeRecallGeneralForm,
  getRecallGeneralForm,
  renderCoreRecallBubbleUps,
} from "@app/core/recall/[id]/util";
import { IParentRecallSection, Recall } from "@app/core/recall/model";
import { APIResponse } from "@common/apis/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

interface ISaveRecallButtonProps {
  isDisabled?: boolean;
}
export const SaveRecallButton = observer(
  ({ isDisabled = false }: ISaveRecallButtonProps) => {
    //#region STORE ========/
    const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
      useFlexibleFormStore();
    const isNew = useIsNew();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { cancelToken } = useCancelRequest();
    const history = useHistory();
    //#endregion HOOK =====/

    const parentState = getRecallGeneralForm("CCFormOptions")
      ?.parentState as IParentRecallSection;

    const saveRecallSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const recall: Recall = initialData;
            const bubbleUps =
              parentState?.bubbleUps ??
              renderCoreRecallBubbleUps(parentState.id, parentState.recordType);
            if (isNew) {
              recall._BubbleUps = bubbleUps;
            }
            return postSaveRecall(recall, cancelToken());
          },
          handleSuccess: async ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            if (isNew) {
              if (response?.data?.ID) {
                const urlRedirect =
                  RECALL_MANAGE_ROUTE + `/${response?.data?.ID}`;
                pushNotificationPortal({
                  title: "Recall saved successfully.",
                  type: "success",
                  route: urlRedirect,
                  callBack() {
                    history.replace(urlRedirect);
                  },
                });
              }
            } else {
              await fetchApiByAlias(RECALL_SLIDER);
              pushNotificationPortal({
                title: "Recall saved successfully.",
                type: "success",
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Save recall failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
            changeRecallGeneralForm({
              CCFormOptions: { forceModified: true },
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const recall: Recall = await submitFormGetData("GeneralForm");

      if (recall) {
        setIsLoadingForm(true);
        await saveRecallSlider.fetchApi({
          initialData: recall,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={isLoadingForm || isDisabled}
      />
    );
  }
);
