import { ICrmsUsersPicker } from "@app/products/crms/components/dialogs/pick-users-groups/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfUsers = nameOfFactory<ICrmsUsersPicker>();

export const colCrmsUsersPick: IColumnFields[] = [
  {
    field: nameOfUsers("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfUsers("Position"),
    title: "Position",
  },
  {
    field: nameOfUsers("Email"),
    title: "Email",
  },
  {
    field: nameOfUsers("WorkPhone"),
    title: "Work Phone",
  },
  {
    field: nameOfUsers("HomePhone"),
    title: "Home Phone",
  },
  {
    field: nameOfUsers("Mobile"),
    title: "Mobile",
  },
];
