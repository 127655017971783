import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const healthLicensingRoute: ICCRoute = {
  path: "health-licensing",
  name: "Health Licensing",
  enum: HealthManagerMenu.HealthLicensing,
  children: [
    {
      path: "register",
      name: "Register",
      enum: HealthManagerMenu.HealthLicensingRegister,
      // mock route: not implemented yet
      //component: require("./register/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: HealthManagerMenu.HealthLicensingByType,
      // mock route: not implemented yet
      //component: require("./by-type/_index").default,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      enum: HealthManagerMenu.HealthLicensingBySuburb,
      // mock route: not implemented yet
      //component: require("./by-suburb/_index").default,
    },
    {
      path: "prelodgements",
      name: "Prelodgements",
      enum: HealthManagerMenu.HealthLicensingPrelodgments,
      // mock route: not implemented yet
      //component: require("./prelodgements/_index").default,
    },
    {
      path: "closures",
      name: "Closures",
      enum: HealthManagerMenu.HealthLicensingClosures,
      // mock route: not implemented yet
      //component: require("./closures/_index").default,
    },
  ],
};
