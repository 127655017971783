import { NoticeRunFormElement } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/components/child-screens/general/components/form-element/_index";
import { useNoticeRunsStore } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const NoticeRunForm = observer(() => {
  const { noticeRuns } = useNoticeRunsStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={noticeRuns}
        key={JSON.stringify(noticeRuns)}
        render={(formRenderProps: FormRenderProps) => (
          <NoticeRunFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
