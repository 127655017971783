import { NUMBER_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IApplicationSettingTextProps {
  enumParam: ECorporateSettingsField;
}

export const GlobalSettingNumeric = (props: IApplicationSettingTextProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { enumParam } = props;

  if (!configData) return <></>;
  return (
    configData[enumParam.toString()] && (
      <div className="cc-field">
        <CCLabel
          title={configData[enumParam.toString()].Title ?? ""}
          isMandatory={configData[enumParam.toString()].IsMandatory}
        />
        <Field
          name={configData[enumParam.toString()].FieldName}
          component={CCNumericTextBox}
          format={NUMBER_FORMAT.NUMBER1}
          disabled={!isEditing}
          validator={
            configData[enumParam.toString()].IsMandatory
              ? requiredValidator
              : undefined
          }
        />
      </div>
    )
  );
};
