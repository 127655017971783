import { colPickEvent } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-event-closure/config";
import { CurrentLookupValues } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/pick-event-closure/model";
import { nameOfFactory } from "@common/utils/common";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";

interface ICPickEventClosureProps {
  onSubmit?: (data: any) => void;
  onClose?: (isShowDialog: boolean) => void;
  setShowDialog?: (isShowDialog: boolean) => void;
  dataUrl: string;
  isMultiple?: boolean;
  selectedRows?: any[];
}
const nameOf = nameOfFactory<CurrentLookupValues>();
export const CCPickEventClosure = ({
  onSubmit = () => {},
  onClose = () => {},
  setShowDialog = () => {},
  isMultiple = true,
  dataUrl,
  selectedRows,
}: ICPickEventClosureProps) => {
  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <GridSelectionDialog
            maxWidth="45%"
            height={"64%"}
            titleHeader={"Pick Event Closure Comment"}
            selectableMode={isMultiple ? "multiple" : "single"}
            dataUrl={dataUrl}
            //TODO: need to confirm
            // state={{
            //   filter: {
            //     filters: [
            //       {
            //         field: nameOf("ID"),
            //         operator: "eq",
            //         value: ,
            //       },
            //     ],
            //     logic: "and",
            //   },
            // }}
            columnFields={colPickEvent}
            primaryField={nameOf("ID")}
            {...formRenderProps}
            setShowDialog={setShowDialog}
            onCloseDialog={() => onClose(false)}
            onSubmit={(data: CurrentLookupValues[]) => {
              onSubmit(data[0]);
            }}
            selectedRows={selectedRows}
          />
        );
      }}
    />
  );
};
