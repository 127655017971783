import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { IAmendApplication } from "@app/products/town-planning/ppr/components/action-bar/dialogs/amend-application/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
const nameOf = nameOfFactory<IAmendApplication>();

interface IAmendApplicationDialog {
  onClose: () => void;
  onSubmit: (data: IAmendApplication) => void;
  isLoading?: boolean;
}

export const AmendApplicationDialog = observer(
  ({ onClose, onSubmit, isLoading }: IAmendApplicationDialog) => {
    const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
      onSubmit(event.values as IAmendApplication);
    };
    const { ppr } = usePPRStore();
    const initialValues = {
      PlansSubmitted: ppr?.RevisionDate || new Date(),
    };
    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={handleOnSubmitClick}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Enter the Date"}
                onClose={onClose}
                maxWidth={"25%"}
                height={"auto"}
                disabled={isLoading}
                bodyElement={
                  <div className="cc-form">
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Plans submitted
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("PlansSubmitted")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      disabled={!formRenderProps.valid}
                      themeColor="primary"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onClick={formRenderProps.onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
