import { loadViewConfiguresColumns } from "@app/products/property/api";
import { TitleDetail } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/property/title-detail/_index";
import {
  DTO_Assessment,
  DTO_PIC,
  DTO_Title,
  EKeysOfStepsCOONoS,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colAssessments, colPICs, colTitles } from "./config";

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const nameOfTitle = nameOfFactory<DTO_Title>();
const nameOfPIC = nameOfFactory<DTO_PIC>();
export const PropertyFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const selectedType = valueGetter(`${EKeysOfStepsCOONoS.Type}._option.Type`);
    const titles = getFieldValue("Titles") ?? [];
    const assessments = getFieldValue("Assessments") ?? [];
    const pics = getFieldValue("Pics") ?? [];

    //Use state
    const [processedTitleCol, setProcessedTitleCol] =
      useState<IColumnFields[]>(colTitles);
    const [processedAssessmentCol, setProcessedAssessmentCol] =
      useState<IColumnFields[]>(colAssessments);
    const [processedPICCol, setProcessedPICCol] =
      useState<IColumnFields[]>(colPICs);

    //Get labels
    const [assessmentsLabel, titlesLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessments,
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.Title_ID,
    ]);

    const loadViewConfiguration = async () => {
      setIsLoadingStep(true);
      const response = await Promise.all([
        loadViewConfiguresColumns(
          ViewConfiguration.ChangeOfOwnership_NoticeOfSale_Title,
          colTitles
        ),
        loadViewConfiguresColumns(
          ViewConfiguration.ChangeOfOwnership_NoticeOfSale_Assessment,
          colAssessments
        ),
        loadViewConfiguresColumns(
          ViewConfiguration.ChangeOfOwnership_NoticeOfSale_PIC,
          colPICs
        ),
      ]);
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        const [titleViewConfig, assessmentViewConfig, picViewConfig] = response;
        if (
          Array.isArray(titleViewConfig) &&
          Array.isArray(assessmentViewConfig) &&
          Array.isArray(picViewConfig)
        ) {
          setProcessedTitleCol(titleViewConfig);
          setProcessedAssessmentCol(assessmentViewConfig);
          setProcessedPICCol(picViewConfig);
        } else {
          let responseError = titleViewConfig as APIResponseError;
          if (!Array.isArray(assessmentViewConfig)) {
            responseError = assessmentViewConfig as APIResponseError;
          } else if (Array.isArray(assessmentViewConfig)) {
            responseError = picViewConfig as APIResponseError;
          }

          setLoadFailedStep({
            onReload: () => {
              loadViewConfiguration();
            },
            responseError: {
              status: responseError.status,
              error: responseError.error ?? "Load failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        setLoadFailedStep({
          onReload: () => {
            loadViewConfiguration();
          },
          responseError: {
            status: responseError.status,
            error: responseError.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadViewConfiguration();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title={`${titlesLabel} for which change is to apply`} />
            <CCGrid
              className="cc-title-change-of-ownership"
              data={titles}
              columnFields={processedTitleCol}
              detail={TitleDetail}
              primaryField={nameOfTitle("Title_Id")}
            />
          </div>
          {selectedType?.COOT_Display_Retain_AssociatedNames && (
            <div className="cc-field">
              <CCLabel title="Retain associated names" />
              <Field
                name={nameOf("RetainAssociatedNames")}
                component={CCSwitch}
                checked={getFieldValue("RetainAssociatedNames")}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
          <div className="cc-field">
            <CCLabel title={assessmentsLabel} />
            <CCGrid
              className="cc-assessment-change-of-ownership"
              data={assessments}
              columnFields={processedAssessmentCol}
              primaryField={nameOfAssessment("Assessment_Id")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="PICs" />
            <CCGrid
              className="cc-pic-change-of-ownership"
              data={pics}
              columnFields={processedPICCol}
              primaryField={nameOfPIC("PIC_Id")}
            />
          </div>
          <div className="cc-field">
            <div className="cc-label-control-between">
              <CCLabel title="Formatted owners name and address" />
              <div></div>
            </div>
            <Field
              name={nameOf("_option.FormattedNameAddress")}
              rows={4}
              readOnly
              component={CCTextArea}
            />
          </div>
        </div>
      </section>
    );
  }
);
