import { SaveConditionButton } from "@app/core/condition-reference/[id]/components/button/save";
import { ConditionReferenceForm } from "@app/core/condition-reference/[id]/components/general/_index";
import { ConditionReferenceHistoryTab } from "@app/core/condition-reference/[id]/components/slidebar/history/_index";
import { TitleConditionReferenceManagePage } from "@app/core/condition-reference/[id]/constant";
import { Svc_Form_ConditionReference } from "@app/core/condition-reference/[id]/model";
import { DBRowState } from "@app/products/crms/[id]/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { mapEnum } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
export const ExistManageConditionReference = observer(() => {
  const { id } = useParams<{ id: string }>();

  // #region STORE
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  // #endregion

  const [showSlideBar, setShowSlideBar] = useState(true);

  const formCondition = dataForms?.GeneralForm as Svc_Form_ConditionReference;
  const conditionReference = formCondition?.ConditionReference;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `${TitleConditionReferenceManagePage} - ${id ?? 0}`,
      Text: TitleConditionReferenceManagePage,
      LinkUrl: window.location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Condition,
      Record_ID: +id || 0,
    },
  ];

  const isInactive = useMemo(() => {
    return !!(
      mapEnum(conditionReference?.Sys_DBRowState, DBRowState) ===
        DBRowState.Inactive && conditionReference?.ConditionReference_ID
    );
  }, [
    conditionReference?.Sys_DBRowState,
    conditionReference?.ConditionReference_ID,
  ]);

  return (
    <>
      <FormTitle title={TitleConditionReferenceManagePage} />
      <CCManagePageActionBar
        leftActions={[<SaveConditionButton isDisabled={isInactive} />]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {formCondition && <ConditionReferenceForm />}
          </div>
        </div>

        {/* Handle later after Confirmation */}
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "History",
                  component: <ConditionReferenceHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
