import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { displayDotByLimit } from "@common/utils/formatting";

export const CRMSKnowledgeBaseBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `${CRMS_PRODUCT_NAME} - Knowledge Base ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return `${getDisplayTextWithDashes([
      selectedRow?.Description
        ? displayDotByLimit(selectedRow.Description, 15)
        : "Knowledge Base",
    ])}`;
  },
};
