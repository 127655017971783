import { IAssociateParcelLookup } from "@app/products/property/registers/[id]/components/dialogs/associate/model";
import { AssociateDialog } from "@app/products/property/registers/[id]/components/dialogs/associate/_index";
import { IRegistersSummary } from "@app/products/property/registers/[id]/model";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const AssociateButton = () => {
  const [showAssociateDialog, setShowAssociateDialog] = useState(false);
  const { register, setRegister } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();
  const setAssociatedParcel = (data: IAssociateParcelLookup) => {
    const newRegister: IRegistersSummary = { ...register };
    newRegister?.AssociatedParcels?.unshift(data.ParcelLookup[0]);
    setRegister(newRegister);
  };
  const handleSubmitAssociate = (data: any) => {
    setAssociatedParcel(data);
    setShowAssociateDialog(false);
    pushNotification({
      title: "Parcel associated successfully",
      type: "success",
    });
  };
  return (
    <React.Fragment>
      <CCNavButton
        title="Associate"
        onClick={() => {
          setShowAssociateDialog(true);
        }}
      />

      {showAssociateDialog && (
        <AssociateDialog
          onSubmit={(data) => {
            handleSubmitAssociate(data);
          }}
          onClose={() => {
            setShowAssociateDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
