import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colExceptions } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/config";
import {
  DTO_COOLeaseTransferException,
  ILeaseTransfersDetailProps,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/model";
import { useChangesOfOwnershipLeaseTransfersStore } from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/store";
import "../_index.scss";

const nameOfExceptions = nameOfFactory<DTO_COOLeaseTransferException>();
export const Exceptions = observer(
  ({ leaseTransfersId, assessmentId }: ILeaseTransfersDetailProps) => {
    const {
      responseLoadError,
      changesOfOwnershipLeaseTransferExceptions,
      isLoading,
      colExceptionsConfiguration,
      loadChangesOfOwnershipLeaseTransferExceptions,
      resetStore,
    } = useChangesOfOwnershipLeaseTransfersStore();

    useEffect(() => {
      assessmentId &&
        loadChangesOfOwnershipLeaseTransferExceptions(
          leaseTransfersId,
          assessmentId
        );
      return () => {
        resetStore();
      };
    }, [
      leaseTransfersId,
      assessmentId,
      resetStore,
      loadChangesOfOwnershipLeaseTransferExceptions,
    ]);

    return (
      <div className="cc-changes-of-ownership-lease-transfers-children-grid">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : responseLoadError ? (
          <div>
            <CCLoadFailed
              responseError={responseLoadError}
              onReload={() => {
                assessmentId &&
                  loadChangesOfOwnershipLeaseTransferExceptions(
                    leaseTransfersId,
                    assessmentId
                  );
              }}
            />
          </div>
        ) : (
          <CCGrid
            isLoading={isLoading}
            data={changesOfOwnershipLeaseTransferExceptions ?? []}
            columnFields={processDynamicColumns(
              colExceptions,
              colExceptionsConfiguration
            )}
            primaryField={nameOfExceptions("ACT_Lease_Transfer_Exception_Id")}
          />
        )}
      </div>
    );
  }
);
