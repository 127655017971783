import { eventEmitter } from "@/App";
import { ALERT_ACCORDION_GRID_ID } from "@app/core/contacts/components/accordions/alerts/constant";
import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import { postAlert } from "@app/core/contacts/components/dialogs/alert/api";
import { IAlerts } from "@app/core/contacts/components/dialogs/alert/model";
import { useAlertsDialogStore } from "@app/core/contacts/components/dialogs/alert/store";
import { AlertDialog } from "@app/core/contacts/components/dialogs/alert/_index";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IAlertsButtonProps {
  contactId?: number | null;
  isDisabled?: boolean;
  isModifyButton?: boolean;
  recordType: RECORDTYPE;
  onReload?: () => Promise<boolean | void>;
}

export const AlertsButton = observer(
  ({
    contactId,
    isDisabled,
    isModifyButton = false,
    recordType,
    onReload,
  }: IAlertsButtonProps) => {
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const [isShowAlertDialog, setIsShowAlertDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setAlertSelectedRows } = useAlertsAccordionStore();
    const { setNotification } = useAlertsDialogStore();

    const buttonTitle = useMemo(() => {
      return isModifyButton ? "Modify alert" : "New alert";
    }, [isModifyButton]);

    const handleSubmit = async (data: IAlerts) => {
      if (isNil(contactId)) return;
      setIsLoading(true);
      clearNotifications();
      const response = await postAlert(contactId, recordType, data);
      setIsLoading(false);

      if (isSuccessIdentityPacket(response)) {
        setAlertSelectedRows([]);
        setIsShowAlertDialog(false);
        if (onReload) {
          await onReload();
        }
        pushNotification({
          title: `${buttonTitle} was completed successfully.`,
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [ALERT_ACCORDION_GRID_ID],
        });
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        setNotification({
          autoClose: false,
          title: `${buttonTitle} could not be completed.`,
          description: response.data?.Errors ?? response.error,
          type: "error",
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={buttonTitle}
          disabled={isDisabled || isNil(contactId)}
          onClick={() => setIsShowAlertDialog(true)}
        />
        {isShowAlertDialog && (
          <AlertDialog
            onClose={() => setIsShowAlertDialog(false)}
            isLoadingSubmit={isLoading}
            handleSubmit={handleSubmit}
            isModifyAlert={isModifyButton}
          />
        )}
      </>
    );
  }
);
