import { mockRegistersList } from "@app/products/property/registers/list/mock";
import { ICurrentAccount } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/model";
import { sleep } from "@common/utils/common";

export const getCurrentAccount = async (
  accountId: number
): Promise<ICurrentAccount | undefined> => {
  await sleep(1000);
  const recordRegister = mockRegistersList.find(
    (item) => item.Id === accountId
  );
  return {
    ...recordRegister,
    TagNo: "D17-00568",
    Description: "(Standard fee/microchipped/desexed)",
    PropertyAddress: "14 Irrapatana Str, Rowby Dowmns",
    AccountId: accountId,
  };
};
