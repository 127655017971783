import { ICCRoute } from "@common/constants/ICCRoute";

export const communicationRoute: ICCRoute = {
  path: "communication",
  name: "Communication",
  children: [
    {
      path: "my-unprinted-docs",
      name: "My Unprinted Docs",
      component: require("./my-unprinted-docs/_index").default,
    },
    {
      path: "all-my-documents",
      name: "All My Documents",
      component: require("./all-my-documents/_index").default,
    },
    {
      path: "all-documents",
      name: "All Documents",
      component: require("./all-documents/_index").default,
    },
  ],
};
