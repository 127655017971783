import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import {
  Permit,
  Permit_Status,
  Svc_Permit,
  Svc_Permit_Workflow_DateFromDateTo,
} from "@app/products/local-laws/[id]/model";
import { postRenewPermit } from "@app/products/local-laws/components/toolbar/buttons/renew/api";
import { APIResponse } from "@common/apis/model";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import {
  DateFromDateTo,
  DateToFromDialogPlaceID,
} from "@common/dialog/date-to-from/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { addMonths, addYears } from "date-fns";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IRenewPermitButtonProps {
  isDisabled?: boolean;
}

export const RenewPermitButton = observer(
  ({ isDisabled }: IRenewPermitButtonProps) => {
    //#region STORE ========/
    const {
      dataForms,
      isLoadingForm,
      submitFormGetData,
      setMiddlewareSubmitFormOptions,
      updateFormFields,
    } = useFlexibleFormStore();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    //#region STATES ========/
    const [objEffectDateIssue, setObjEffectDateIssue] =
      useState<DateFromDateTo>();
    const [isShowWorkflowRenew, setIsShowWorkflowRenew] =
      useState<boolean>(false);
    //#endregion STATES =====/

    //#region DECLARE ========/
    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return (
        permitData?.Status_ENUM === Permit_Status.PermitIssued &&
        permitData?.PermitType?.Flag_Renewable
      );
    }, [dataForms]);
    //#endregion DECLARE =====/

    //#region METHOD ========/
    const objRenew = () => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      const objReason = new DateFromDateTo();
      objReason.Title_Text = "Renew Permit";
      objReason.DateLabelFrom_Text = "Effective from";
      objReason.DateLabelFrom_To = "Effective to";
      objReason.DateFrom = new Date();

      if (
        !isNil(permitData?.PermitType) &&
        permitData?.PermitType?.DefaultPeriodMonths
      ) {
        objReason.DateTo = addMonths(
          new Date(),
          permitData.PermitType.DefaultPeriodMonths
        );
      } else {
        objReason.DateTo = addYears(new Date(), 1);
      }

      setObjEffectDateIssue(objReason);
    };

    const isOpenIssueWfDialog = useMemo(() => {
      setObjEffectDateIssue(undefined);
      if (isShowWorkflowRenew) objRenew();
      return isShowWorkflowRenew;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowWorkflowRenew]);

    const handleClickButton = async () => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataForm = await submitFormGetData("GeneralForm");
      if (!isNil(dataForm?.Permit)) {
        setIsShowWorkflowRenew(true);
      }
    };

    const handleWorkflowRenew = async (
      dateFromDateTo: DateFromDateTo | null
    ) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const generalForm = await submitFormGetData("GeneralForm");
      let permit: Permit = generalForm?.Permit;

      if (permit) {
        permit.TypeOfDescription = permit.TypeOfDescription ?? "";
        permit.ReferenceNumber = permit.ReferenceNumber ?? "";
        permit.FileNumber = permit.FileNumber ?? "";
        permit.InsurancePolicyNumber = permit.InsurancePolicyNumber ?? "";
        permit.Description = permit.Description ?? "";

        const responseRequest = {
          Permit: permit,
          DateFromDateTo: dateFromDateTo,
        } as Svc_Permit_Workflow_DateFromDateTo;

        await renewPermitButtonSlider.fetchApi({
          initialData: responseRequest,
        });
      }
    };

    const handleOnCloseDialog = () => {
      setIsShowWorkflowRenew(false);
      setObjEffectDateIssue(undefined);
    };

    const renewPermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const renewPermitRequest: Svc_Permit_Workflow_DateFromDateTo =
              initialData;
            return postRenewPermit(renewPermitRequest, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IIdentityPacket<Permit>> = dataFromApi;
            const permitFormData = response?.data?.ReturnObj as Permit;
            if (response?.data?.ReturnObj) {
              //#region save data to store ========/
              updateFormFields("GeneralForm", {
                Permit: permitFormData,
              } as Svc_Permit);
              //#endregion save data to store =====/

              setIsShowWorkflowRenew(false);
              setObjEffectDateIssue(undefined);

              eventEmitter.emit(CCJournalEventType.RefreshData);
              eventEmitter.emit(TabTableEventType.RefreshData);

              pushNotificationPortal({
                title: "Permit renewed successfully.",
                type: "success",
              });
            } else {
              pushNotificationPortal({
                placeId: DateToFromDialogPlaceID,
                autoClose: false,
                title: "Renew permit failed.",
                type: "error",
                description: response.data?.Errors ?? response.statusText,
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              placeId: DateToFromDialogPlaceID,
              autoClose: false,
              title: "Renew permit failed.",
              type: "error",
              description: errorFromApi.error,
            });
          },
        },
      ],
    });
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Renew Permit"
          invisible={!isVisible}
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />

        {isOpenIssueWfDialog && (
          <DateToFromDialog
            onClose={handleOnCloseDialog}
            onSubmit={(value: DateFromDateTo) => handleWorkflowRenew(value)}
            isLoading={renewPermitButtonSlider.isFetching}
            data={objEffectDateIssue}
          />
        )}
      </>
    );
  }
);
