import { CCV_ASSET_REGISTER } from "@app/products/ams/assets/register/model";
import { LOOKUPNAME } from "@app/products/ams/constants/ams.lookups";
import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<CCV_ASSET_REGISTER>();

export const assetRegisterColumns: IColumnFields[] = [
  {
    field: nameOf("AssetNumber"),
    title: "Asset Number",
    locked: true,
    linkTo: (dataItem) => AmsRoute.ASSET_MANAGE_PATH + dataItem.Id,
  },
  {
    field: nameOf("AssetDescription"),
    title: "Description",
    dataType: "text",
  },
  {
    field: nameOf("CategoryName"),
    title: "Category",
    lookup: LOOKUPNAME.AssetCategory,
    dataType: "enumLookup",
  },
  {
    field: nameOf("AssetStatus"),
    title: "Status",
    lookup: LOOKUPNAME.AssetStatus,
    dataType: "enumLookup",
  },
  {
    field: nameOf("SerialNumber"),
    title: "Serial Number",
    dataType: "text",
  },
  {
    field: nameOf("EquipmentNumber"),
    title: "Equipment Number",
    dataType: "text",
  },

  {
    field: nameOf("WarrantyEndDate"),
    title: "Warranty End",
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
  {
    field: nameOf("DesignLife"),
    title: "Design Life",
    dataType: "numeric",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
