import { UITownPlanning } from "@app/products/town-planning/model";
import { putPSARValidateSave } from "@app/products/town-planning/ppr/psa-referrals/_id/api";
import { putSendHearingSubmissionReportForApproval } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-hearing-submission-report/api";
import { PSARStoreInstance } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { Application } from "@app/products/town-planning/ppr/[id]/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class SendHearingSubmissionReportButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  handleWorkflow = async (
    id: number,
    saveApplication: boolean,
    application: Application,
    workflow: UITownPlanning
  ) => {
    this.setIsLoading(true);
    const responseValidate = await putPSARValidateSave(
      workflow,
      saveApplication,
      id,
      application
    );
    if (isSuccessIdentityPacket(responseValidate)) {
      PSARStoreInstance.setPPRWithLoading(responseValidate.data?.Application);
      appNotificationStore.clearNotifications();
      const responseWorkflow = await putSendHearingSubmissionReportForApproval(
        id
      );
      if (
        isSuccessIdentityPacket(responseWorkflow) &&
        responseWorkflow.data?.Application
      ) {
        PSARStoreInstance.setPPRWithLoading(responseWorkflow.data?.Application);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Send hearing submission report successfully",
        });
        if (
          responseWorkflow.data?.Notifications &&
          responseWorkflow.data?.Notifications.length > 0
        ) {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Send approve notification",
            type: "warning",
            description: responseWorkflow.data?.Notifications,
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Send hearing submission report failed",
          type: "error",
          description:
            responseWorkflow.data?.Errors ?? responseWorkflow.statusText,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        description:
          responseValidate.data?.Errors ?? responseValidate.statusText,
        type: "error",
      });
    }

    this.setIsLoading(false);
  };
}
export const sendHearingSubmissionReportButtonStore =
  new SendHearingSubmissionReportButtonStore();
const sendHearingSubmissionReportButtonStoreContext = createContext(
  sendHearingSubmissionReportButtonStore
);
export const useSendHearingSubmissionReportStore = () => {
  return useContext(sendHearingSubmissionReportButtonStoreContext);
};
