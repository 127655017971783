import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const recordsRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: "Records_ManagementSettings",
    component: require("./forms/records-management-settings").default,
  },
  {
    name: "Records_DocumentDisplaySettings",
    component: require("./forms/record-document-display-settings").default,
  },
  {
    name: "Records_InheritAccessControlFromParentContainer",
    component: require("./forms/records-inherit-access-control").default,
  },
  {
    name: "Records_DocumentCreation",
    component: require("./forms/records-document-creation").default,
  },
  {
    name: "Records_Search",
    component: require("./forms/records-search").default,
  },
  {
    name: "Records_ParentContainer",
    component: require("./forms/records-parent-container").default,
  },
];
