import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const CreatePICButtonAtCollections = observer(() => {
  const { gridSelectedIds } = useDetailAssessmentListViewStore();
  return (
    <CreatePICButton
      isDisabled={gridSelectedIds?.length < 1}
      assessmentIds={gridSelectedIds}
    />
  );
});
