import { CCTooltip } from "@components/cc-tooltip/_index";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns/dist/npm/DropDownList/DropDownListProps";
import React from "react";

interface IProps {
  name: string;
  label: string;
  data: any;
  textField: string;
  dataItemKey: string;
  value: any;
  isMandatory?: boolean;
  useAutoComplete?: boolean;
  disabled?: boolean;
  onChange?: (arg0: DropDownListChangeEvent) => void;
  colWidth?: number;
}

export const AmsDropDownList: React.FC<IProps> = ({
  name,
  label,
  data,
  textField,
  dataItemKey,
  value,
  isMandatory,
  disabled,
  onChange,
}) => {
  return (
    <div className={`cc-ams-dropdown-list`}>
      <label className="cc-label">
        {label}
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </label>
      <DropDownList
        disabled={disabled}
        name={name}
        className="cc-form-control"
        data={data}
        textField={textField}
        dataItemKey={dataItemKey}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
