import { DTO_ChangeOfOwnershipProcessed } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { getParcelOwnership } from "@app/products/property/parcels/[id]/components/child-screens/ownership/api";
import { processChangeOfOwnershipData } from "@app/products/property/util";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ParcelOwnershipStore {
  private _parcelOwnership?: DTO_ChangeOfOwnershipProcessed[] = [];
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  setParcelOwnership = (parcelOwnership?: DTO_ChangeOfOwnershipProcessed[]) => {
    runInAction(() => {
      this._parcelOwnership = parcelOwnership;
    });
  };
  get parcelOwnership() {
    return this._parcelOwnership;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get isLoading() {
    return this._isLoading;
  }

  //Action
  loadParcelOwnership = async (parcelsId: number) => {
    this.setIsLoading(true);
    const ownership = await getParcelOwnership(parcelsId);
    if (ownership) {
      this.setParcelOwnership(processChangeOfOwnershipData(ownership));
    }
    this.setIsLoading(false);
  };

  resetStore = () => {
    this.setParcelOwnership([]);
    this.setIsLoading(false);
  };
}

const parcelOwnershipStoreContext = createContext(new ParcelOwnershipStore());
export const useParcelOwnershipStore = () => {
  return useContext(parcelOwnershipStoreContext);
};
