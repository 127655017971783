import { MasterProperty_Assessment } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/assessments/model";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<MasterProperty_Assessment>();

//All titles will be replaced by column name data from view configuration API
export const colMasterPropertyAssessment: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: DECIMAL_FORMAT.DECIMAL1,
    linkTo: (dataItem: MasterProperty_Assessment) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem?.Assessment_Id}`,
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
    linkTo: (dataItem: MasterProperty_Assessment) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem?.Assessment_Id}`,
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Assess_Legal_Description"),
    title: "Legal Description",
  },
  {
    field: nameOf("Unit_Entitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Unit_Entitlement_Percentage"),
    title: "Unit Entitlement Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Unit_Type"),
    title: "Unit Type",
  },
  {
    field: nameOf("Unit_Type_Percentage"),
    title: "Unit Type Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Assess_Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assess_Balance_Outstanding"),
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assess_Being_Postponed"),
    title: "Being Postponed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Assessment_Group_Name"),
    title: "Group",
  },
  {
    field: nameOf("Valuation_Group_Name"),
    title: "Valuation Group",
  },
  {
    field: nameOf("Assessment_Type"),
    title: "Type",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assessment_Status"),
    title: "Status",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Primary_Assess_Land_Use_Name"),
    title: "Land Use",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation 1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation 3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation 4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
