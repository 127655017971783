import { ExistedManageFees } from "@app/core/fees/[id]/components/forms/existed/_index";
import { NewManageFees } from "@app/core/fees/[id]/components/forms/new/_index";
import { IFeeParentSection } from "@app/core/fees/[id]/model";
import { useFeesStore } from "@app/core/fees/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useGlobalStore } from "@common/stores/global/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageFee = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{
    parent?: IFeeParentSection;
  }>();
  const { loadFinanceFees, checkIsSuperAdmin, setParents, resetStore } =
    useFeesStore();
  const { currentUserInfo } = useGlobalStore();

  useEffect(() => {
    if (state?.parent) {
      setParents(state?.parent);
    }
    if (currentUserInfo?.UserPKID) {
      checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
    }
    if (isNew) {
      loadFinanceFees(
        0,
        state?.parent?.id.toString(),
        state?.parent?.amount ?? ""
      );
    } else {
      loadFinanceFees(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, currentUserInfo?.UserPKID]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      window.history.replaceState({}, "");
    };
  });

  if (isNew) {
    return <NewManageFees />;
  }
  return <ExistedManageFees />;
});

export default ManageFee;
