import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(() => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const fieldObjectiveSearchWebService = getConfigDataField(
    ECorporateSettingsField.Objective_Search_WebService
  );
  const fieldObjectiveFetchSearchWebService = getConfigDataField(
    ECorporateSettingsField.Objective_FetchSearch_WebService
  );
  const fieldObjectiveDownloadWebService = getConfigDataField(
    ECorporateSettingsField.Objective_Download_WebService
  );
  const fieldObjectiveReadstreamWebService = getConfigDataField(
    ECorporateSettingsField.Objective_ReadStream_WebService
  );
  const fieldObjectiveDocumentRecordedDateFieldName = getConfigDataField(
    ECorporateSettingsField.Objective_FieldNameRecordedDate
  );
  const fieldObjectiveDocumentFileExtensionFieldName = getConfigDataField(
    ECorporateSettingsField.Objective_FieldNameFileExtension
  );

  return (
    <>
      <section className="cc-field-group">
        {fieldObjectiveSearchWebService && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveSearchWebService}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveFetchSearchWebService && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveFetchSearchWebService}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveDownloadWebService && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveDownloadWebService}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveReadstreamWebService && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveReadstreamWebService}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveDocumentRecordedDateFieldName && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveDocumentRecordedDateFieldName}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveDocumentFileExtensionFieldName && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveDocumentFileExtensionFieldName}
              isEditing={isEditing}
            />
          </div>
        )}
      </section>
    </>
  );
});
