import {
  WHICH_JOURNAL_FORM_STEP,
  WhichJournalFormStep,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import {
  NEW_JOURNAL_FORM_STEP,
  NewJournalFormStep,
} from "@app/products/property/journals/components/form-elements/new-journal/_index";
import {
  ACCOUNTS_FORM_STEP,
  AccountsFormStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/accounts/_index";
import {
  BALANCE_ADJUSTMENT_FORM_STEP,
  BalanceAdjustmentFormStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/components/form-elements/balance-adjustment/_index";
import {
  defaultNewJournal,
  defaultWhichJournal,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/config";
import {
  mockBalanceAdjustment,
  mockNewJournal,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/mock";
import { INewJournal } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/model";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface IAdjustAccountBalanceDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
export const AdjustAccountBalanceDialog = ({
  onClose,
  onSubmit,
  isIncompleteMode = false,
  isFromActionList = false,
}: IAdjustAccountBalanceDialogProps) => {
  const defaultNewJournalStep: INewJournal = {
    ...defaultNewJournal,
    ...mockNewJournal,
  };
  const steps: IStep[] = [
    {
      label: "Journal",
      initialValues: defaultWhichJournal,
      component: WhichJournalFormStep,
      visible: true,
      key: WHICH_JOURNAL_FORM_STEP,
    },
    {
      label: "New journal",
      initialValues: defaultNewJournalStep,
      component: NewJournalFormStep,
      visible: true,
      key: NEW_JOURNAL_FORM_STEP,
      options: {
        showWhichSupplementary: false,
        showStatusField: true,
      },
    },
    {
      label: "Accounts",
      component: AccountsFormStep,
      visible: true,
      key: ACCOUNTS_FORM_STEP,
    },
    {
      label: "Balance adjustment",
      initialValues: mockBalanceAdjustment,
      component: BalanceAdjustmentFormStep,
      visible: true,
      key: BALANCE_ADJUSTMENT_FORM_STEP,
    },
  ];

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={"Adjust Account Balance"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
