import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Input, MaskedTextBox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export const TabPeople = observer(
  ({ formRenderProps }: { formRenderProps: FormRenderProps }) => {
    const { onChange } = formRenderProps;
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    return (
      <div className="cc-form">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Owners</label>
              <Field
                placeholder={"Owners"}
                name={"PropertyAssessment.Owners_Display"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Attention of</label>
              <Field
                placeholder={"Attention of"}
                name={"PropertyAssessment.Owners_Attention_Of"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Care of</label>
              <Field
                placeholder={"Care of"}
                name={"PropertyAssessment.Owners_Care_Of"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Address</label>
              <Field
                placeholder={"Address"}
                name={"PropertyAssessment.Owners_Address"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Locality</label>
              <Field
                placeholder={"Locality"}
                name={"PropertyAssessment.Owners_Locality"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">State</label>
              <Field
                placeholder={"State"}
                name={"PropertyAssessment.Owners_State"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Field
                placeholder={"Postcode"}
                name={"PropertyAssessment.Owners_Postcode"}
                component={MaskedTextBox}
                mask={"0000"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Country</label>
              <Field
                placeholder={"Country"}
                name={"PropertyAssessment.Owners_Country"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Field
                placeholder={"Phone"}
                name={"PropertyAssessment.Owners_Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Private}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                placeholder={"Mobile"}
                name={"PropertyAssessment.Owners_Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            <div className="cc-field">
              <Field
                label="Email"
                placeholder={"Email"}
                name={"PropertyAssessment.Owners_Email"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={formRenderProps.onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Field
                placeholder={"Fax"}
                name={"PropertyAssessment.Owners_Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Fax}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Entity ID</label>
              <Field
                placeholder={"Entity ID"}
                name={"PropertyAssessment.Owners_Entity_ID"}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                spinners={false}
                min={0}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Ratepayers</label>
              <Field
                placeholder={"Ratepayers"}
                name={"PropertyAssessment.RatePayers_Display"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Attention of</label>
              <Field
                placeholder={"Attention of"}
                name={"PropertyAssessment.RatePayers_Attention_Of"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Care of</label>
              <Field
                placeholder={"Care of"}
                name={"PropertyAssessment.RatePayers_Care_Of"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Address</label>
              <Field
                placeholder={"Address"}
                name={"PropertyAssessment.RatePayers_Address"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Locality</label>
              <Field
                placeholder={"Locality"}
                name={"PropertyAssessment.RatePayers_Locality"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">State</label>
              <Field
                placeholder={"State"}
                name={"PropertyAssessment.RatePayers_State"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Field
                placeholder={"Postcode"}
                name={"PropertyAssessment.RatePayers_Postcode"}
                component={MaskedTextBox}
                mask={"0000"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Country</label>
              <Field
                placeholder={"Country"}
                name={"PropertyAssessment.RatePayers_Country"}
                component={Input}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Phone</label>
              <Field
                placeholder={"Phone"}
                name={"PropertyAssessment.RatePayers_Phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Private}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                placeholder={"Mobile"}
                name={"PropertyAssessment.RatePayers_Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
            <div className="cc-field">
              <Field
                label="Email"
                placeholder={"Email"}
                name={"PropertyAssessment.RatePayers_Email"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={formRenderProps.onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Fax</label>
              <Field
                placeholder={"Fax"}
                name={"PropertyAssessment.RatePayers_Fax"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Entity ID</label>
              <Field
                placeholder={"Entity ID"}
                name={"PropertyAssessment.RatePayers_Entity_ID"}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                spinners={false}
                min={0}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
);
