import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import {
  AddressBookSection,
  ContactExtension,
} from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface AddressLovs {
  Classifications: IKeyValuePacket[];
  ContactAddressTypes: IKeyValuePacket[];
  Countries: IKeyValuePacket[];
  LocationDescriptions: IKeyValuePacket[];
  StreetTypes: IKeyValuePacket[];
  States: string[];
  AddressBoxTypes: IKeyValuePacket[];
}

export interface Address_BuildAddress {
  Address: Address;
  BubbleUps: BubbleUpIdentifier[];
  ZoneList: IKeyValuePacket[];
  Zones_Display: string;
  OverlayList: IKeyValuePacket[];
  Overlay_Display: string;
  Filters: Address_Filters;
  Flag_ForceUse_StreetType: boolean | null;
  bAddressChanged: boolean;
  Module: string;
  Layer: string;
  AddressNominated: AddressNominated;
}

export interface Address_Filters {
  ClassificationFilters: AddressClassification[];
  bAllowPickAddressType: boolean;
}

export interface Address extends DTOBaseClass_Standard {
  Address_ID: number;
  AddressClassification_ENUM: AddressClassification;
  AddressClassification_Name: string;
  ContactAddressType_KWD: number | null;
  ContactAddressType_Name: string;
  PropertyName: string;
  UnitNo: string;
  StreetNo: string;
  StreetName: string;
  Suburb: string;
  State: string;
  Postcode: string;
  LGA: string;
  AreaCode: string;
  Region: string;
  Location_Description: string;
  Map_Reference: string;
  AssessmentNo: string;
  NearestCrossStreet: string;
  AddressBoxType_KWD: number | null;
  AddressBoxType_Name: string;
  PoBoxNo: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Formatted_SingleLine: string;
  AssessmentUpdated: boolean | null;
  Country: string;
  Country_KWD: number | null;
  PropertyType: string;
  PropertySubType: string;
  OwnerOccupier: boolean | null;
  RIN: string;
  ComplexAddress: boolean;
  PersonRole_KWD: number | null;
  PersonCategory_KWD: number | null;
  Flag_StreetName_HasParts: boolean;
  StreetNamePart_Name: string;
  StreetNamePart_Type: string;
  PropertyAssessment_ID: number | null;
  PropertyAssessment: PropertyAssessment;
  Flag_IsCouncilAddress: boolean;
  AddressValidationType_ENUM: AddressValidationType | null;
  AddressValidationType_Name: string;
  PostalDelivery_Identifier: string;
  PostalDelivery_Barcode: string;
  VMAS_Verified: boolean | null;
  VMAS_Confidence: number | null;
  VMAS_GeoCode: string;
  VMAS_PosNorthSouth: number | null;
  VMAS_PosEastWest: number | null;
  Mapshare_Magickey: string;
  NSWAddressPredictiveID: string;
  PropertyAlert: string;
  BubbleUps: BubbleUpIdentifier[];
  Assessment_ID: number | null;
  FullAddress: string;
  FullAddress_HTML: string;
  FullAddress_Text: string;
  FullAddress_SingleLine: string;
  Formatted_AddressLine1: string;
  Formatted_AddressLine2: string;
  Formatted_AddressLine3: string;
  Formatted_AddressLine4: string;
  VERIFICATION_AUSPOST: string;
  VERIFICATION_VMAS2: string;
}

export enum AddressClassification {
  Generic = 0,
  LGA = 1,
  International = 2,
  POBox = 3,
  HM_Contact = 4,
  Premises = 5,
  DescriptiveAddress = 6,
  Property = 7,
  DFSV_Area_Generic = 100,
  DFSV_Area_POBox = 101,
  Line_Address = 102,
  Consultant_Address = 103,
  Scheme = 104,
  Lots = 105,
}

export enum AddressNominated {
  FirstAddress = "FirstAddress",
  SecondAddress = "SecondAddress",
  OtherAddress = "OtherAddress",
}

export enum AddressValidationType {
  None = 0,
  VMAS2 = 1,
  DataTools = 2,
  DELWPMapShare = 3,
  NSWPOINT = 4,
}

export interface PropertyAssessment {
  PropertyAssessment_ID: number;
  Flag_PrimaryAddress: boolean;
  PropertyName: string;
  UnitAbbreviation: string;
  UnitPrefix1: string;
  UnitNumber1: number | null;
  UnitSuffix1: string;
  UnitPrefix2: string;
  UnitNumber2: number | null;
  UnitSuffix2: string;
  FloorTypeAbbreviation: string;
  FloorPrefix1: string;
  FloorNumber1: number | null;
  FloorSuffix1: string;
  FloorPrefix2: string;
  FloorNumber2: number | null;
  FloorSuffix2: string;
  BuildingName: string;
  ComplexName: string;
  HousePrefix1: string;
  HouseNumber1: number | null;
  HouseSuffix1: string;
  HousePrefix2: string;
  HouseNumber2: number | null;
  HouseSuffix2: string;
  GIS_Lat_Lng: string;
  AddressLocationDescriptor: string;
  NearestCrossStreet: string;
  AddressMapNumber: string;
  AddressMapReference: string;
  Address_PFI: string;
  Address_PostalDeliveryID: string;
  Flag_RuralHouseNumber: boolean;
  Flag_OutsideProperty: boolean;
  GIS_Longitude: number | null;
  GIS_Latitude: number | null;
  Display__Building_Unit_Number: string;
  Display__Floor_Number: string;
  Display__House_Number: string;
  Locality_Name: string;
  Locality_Postcode: string;
  Locality_PFI: string;
  Locality_Code: string;
  Street_Name: string;
  Street_TypeAbbreviation: string;
  Street_Suffix: string;
  Street_Comments: string;
  Street_Road_PFI: string;
  Display__StreetName: string;
  SL_EvenStartRange: number | null;
  SL_EvenEndRange: number | null;
  SL_OddStartRange: number | null;
  SL_OddEndRange: number | null;
  SL_MapNumber: string;
  SL_MapReference: string;
  SL_Locality_PFI: string;
  PID_No: string;
  RIN: string;
  Ward: string;
  Area: string;
  Parish: string;
  CrownAllotment: string;
  Section: string;
  LotNo: string;
  PlanRef: string;
  LP_PS: string;
  Volume: string;
  Folio: string;
  County: string;
  Township: string;
  Zones: string;
  Overlay: string;
  Legal_Description: string;
  CertOfTitle: string;
  Block: string;
  Assessment_LandSize: number | null;
  Valuation_Number: string;
  Parcel_ID: number | null;
  Parcel_Reference: string;
  Owners_Entity_ID: number | null;
  Owners_Name: string;
  Owners_Attention_Of: string;
  Owners_Care_Of: string;
  Owners_Address: string;
  Owners_StreetNumber: string;
  Owners_Locality: string;
  Owners_State: string;
  Owners_Postcode: string;
  Owners_Country: string;
  Owners_Phone: string;
  Owners_Email: string;
  Owners_Mobile: string;
  Owners_Fax: string;
  Owners_ABN: string;
  Owners_ACN: string;
  Owners_ARBN: string;
  Owners_Display: string;
  RatePayers_Entity_ID: number | null;
  RatePayers_Name: string;
  RatePayers_Attention_Of: string;
  RatePayers_Care_Of: string;
  RatePayers_Address: string;
  RatePayers_Locality: string;
  RatePayers_State: string;
  RatePayers_Postcode: string;
  RatePayers_Country: string;
  RatePayers_Phone: string;
  RatePayers_Email: string;
  RatePayers_Mobile: string;
  RatePayers_Fax: string;
  OwnerNARIDs: number[];
  RatePayers_Display: string;
  Assessment_Display: string;
  Assessment_ID: number | null;
  Title_ID: number | null;
}

export interface AddressBook {
  Sys_EnterpriseKey: string;
  Sys_TransactionDate: string;
  Sys_TimeStamp: string;
  AddressBook_ID: number;
  AddressBook_Name: string;
  AddressBookSection_ENUM: AddressBookSection;
  AddressBookSection_Name: string;
  ProductType_ENUM: PRODUCT_TYPE;
  ProductType_Name: string;
  IsSystemRecord: boolean;
  ExtensionType: ContactExtension[];
}

export interface SetupAddress {
  Address: Address;
  ClassificationFilters: AddressClassification[];
  IsHasFlag_ForceUse_StreetType: boolean;
}
