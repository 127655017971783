import { IFieldSelection } from "@app/core/communication/dialogs/components/form-elememts/message-template/model";
import { nameOfFactory } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import React from "react";
const nameOfGridData = nameOfFactory<IFieldSelection>();
interface IFieldSelectionComboBoxProps {
  editor: any;
  editorUtils: any;
  formRenderProps: any;
  nameOf: (name: string) => void;
}
export const FieldSelectionComboBox = ({
  editor,
  editorUtils,
  formRenderProps,
  nameOf,
}: IFieldSelectionComboBoxProps) => {
  const props = editor;
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const handleChange = (event: any) => {
    if (!event?.value?.code) {
      return;
    }
    if (props.view) {
      const { view } = props;
      const schema = view.state.schema;

      const nodeType = schema.nodes?.nonEditable;

      const node = nodeType?.createAndFill(
        { class: "shortcode" },
        schema.text(event.value.code)
      );
      editorUtils.insertNode(view, node);
      view.focus();
    }
  };
  const filterData = (filter: FilterDescriptor) => {
    const data = getFieldValue("FieldSelection").slice();
    return filterBy(data, filter);
  };
  const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
    onChange(nameOf("FieldSelectionFilter"), {
      value: filterData(event.filter),
    });
  };
  const nodeType = props?.view && props?.view.state.schema.nodes.text;
  const canInsert =
    props?.view && editorUtils.canInsert(props?.view.state, nodeType);

  return (
    <>
      <ComboBox
        filterable
        loading={!getFieldValue("FieldSelection")}
        data={getFieldValue("FieldSelectionFilter")}
        textField="text"
        dataItemKey={nameOfGridData("id")}
        onFilterChange={onFilterChange}
        disabled={!canInsert}
        onChange={handleChange}
        placeholder={`Field Selection`}
      />
    </>
  );
};
