import { getRecordTrimRecordType } from "@app/core/trim/trim-record-type/api";
import { getTrimRecordTypeInputPickerOption } from "@app/core/trim/trim-record-type/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { getSettings } from "@common/apis/setting";
import { isSuccessResponse } from "@common/apis/util";
import { Svc_AttachmentRecordType } from "@common/models/corporateSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface ITrimContainerProps {
  value: any;
  selectedRows?: any[] | undefined;
}

export const TrimRecordTypeInputPicker = (props: ITrimContainerProps) => {
  const { value, selectedRows, ...others } = props;
  const [recordLabel, setRecordLabel] = useState("Record Types");
  const nameOfTrimRecordType = nameOfFactory<Svc_AttachmentRecordType>();

  useEffectOnce(() => {
    getSettings([ECorporateSettingsField.CorporateSettings_RecordsLabel]).then(
      (response) => {
        if (response && isSuccessResponse(response)) {
          setRecordLabel(
            `${response.data?.[0].Value?.toString()} Record Types`
          );
        }
      }
    );
  });

  return (
    <InputPickerSearch
      nameDisplayMerge={nameOfTrimRecordType("Name")}
      options={getTrimRecordTypeInputPickerOption(
        {
          dialog: {
            titleHeader: recordLabel,
          },
        },
        {
          selectableMode: "single",
          selectedRows: selectedRows,
        }
      )}
      value={value}
      localSearchOptions={{
        enableLocalSearch: true,
        searchingPropKey: nameOfTrimRecordType("Name"),
      }}
      customGetData={async () => await getRecordTrimRecordType()}
      showClearButton={true}
      {...others}
    />
  );
};
