import { uniqBy } from "lodash";

export const isMultipleRecordsWithTheSameID = (
  items: any,
  key = "Id"
): boolean => {
  // initialize a Array of unique items
  const uniqByItems = uniqBy(items, key);

  return uniqByItems.length > 1;
};
