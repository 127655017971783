import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colNatureOfWork: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Name,
    title: "Name",
    width: 400,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Type,
    title: "Type",
    width: 400,
  },
  {
    field: TownPlanningFieldMapping.IsHidden,
    title: "Is Hidden",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.IsSelectable,
    title: "Is Selectable",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.IsActive,
    title: "Is Active",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.HasOtherInputs,
    title: "Has Other Inputs",
    width: 300,
  },
];
