import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colUsages: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "Utility",
    title: "Utility",
  },
  {
    field: "FromDate",
    title: "From Date",
    format: DATE_FORMAT.DATE,
  },

  {
    field: "ToDate",
    title: "To Date",
    format: DATE_FORMAT.DATE,
  },

  {
    field: "NumberOfDays",
    title: "Number of Days",
    format: NUMBER_FORMAT.NUMBER2,
  },

  {
    field: "MeterNumber",
    title: "Meter Number",
  },

  {
    field: "Percentage",
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },

  {
    field: "GrossUsage",
    title: "Gross Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },

  {
    field: "ChargeableUsage",
    title: "Chargeable Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DailyAverageUsage",
    title: "Daily Average Usage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: "TariffTotal",
    title: "Tariff Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Amount",
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    field: "Tariff",
    title: "Tariff",
  },
  {
    field: "Usage",
    title: "Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Unit",
    title: "Unit",
  },
  {
    field: "UsageTariffUnits",
    title: "Usage (Tariff Units)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Unit",
    title: "Unit",
  },
  {
    field: "Rate",
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: "TariffAmount",
    title: "Tariff Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "Levy",
    title: "Levy",
  },
  {
    field: "JournalNumber",
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "UsageId",
    title: "Usage ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
