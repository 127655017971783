import { VO_Land_Tax_Exemption } from "@app/products/property/assessments/compliance/land-tax-exemptions/model";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Land_Tax_Exemption>();
export const colLandTaxExemptions: IColumnFields[] = [
  {
    field: nameOf("Assessment"),
    title: "Assessment(S-S-B-U)",
    locked: true,
    linkTo: (dataItem) => {
      if (dataItem.AssessmentId)
        return {
          pathname: `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.AssessmentId}`,
          state: { isExpandedRating: true },
        };
      return undefined;
    },
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Assessment Property Address",
  },
  {
    field: nameOf("RatePayer"),
    title: "Ratepayer",
  },
  {
    field: nameOf("ExemptionExpiry"),
    title: "Exemption Expiry",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Reason"),
    title: "Reason",
  },
  {
    field: nameOf("Type_Id"),
    title: "Type ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Id"),
    title: "Land Tax Exemption ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("AssessmentId"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
