import { eventEmitter } from "@/App";
import { putRemoveCard } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/action-bar/buttons/remove-card/api";
import { VO_CentreLink_Request } from "@app/products/property/centrelink-customer-confirmation/view-request-file/model";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapperHideOnNoAccess } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
export const RemoveCardButton = observer(() => {
  const { gridSelectedRows, setGridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedItemIDs = useMemo(() => {
    return (
      gridSelectedRows?.map(
        (item: VO_CentreLink_Request) => item.CentreLink_Request_Id
      ) ?? []
    );
  }, [gridSelectedRows]);

  const handleRemoveCard = async () => {
    setIsLoading(true);
    const response = await putRemoveCard(selectedItemIDs);
    setIsLoading(false);
    setShowConfirmationDialog(false);

    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      eventEmitter.emit(CCGridEventType.RefreshOData);
      setGridSelectedRows([]);
      pushNotification({
        type: "success",
        title: response.data?.SuccessMessage ?? "Card/s removed successfully.",
      });
    } else {
      pushNotification({
        title: response.data?.ErrorMessage ?? "Could not remove card.",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <>
      <PrivilegeWrapperHideOnNoAccess
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Centrelink_Request_Card_Remove}
      >
        <CCNavButton
          title="Remove card"
          disabled={selectedItemIDs.length < 1}
          onClick={() => setShowConfirmationDialog(true)}
        />
      </PrivilegeWrapperHideOnNoAccess>

      {showConfirmationDialog && (
        <ConfirmDialog
          title="Confirmation"
          subMessage="Are you sure you want to remove the selected card/s?"
          onClosePopup={() => {
            setShowConfirmationDialog(false);
          }}
          onAsyncConfirm={handleRemoveCard}
          isLoadingYes={isLoading}
        />
      )}
    </>
  );
});
