import { PPRSettingDocument } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfDocument = nameOfFactory<PPRSettingDocument>();

export const colResponseReportLetter: IColumnFields[] = [
  { field: nameOfDocument("Name"), title: "Name" },
  { field: nameOfDocument("FileName"), title: "File Name" },
];
