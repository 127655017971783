import { DTO_Assessment_Names } from "@app/products/property/assessments/[id]/components/child-screens/contacts/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { loadPropertyAssessmentNames } from "./api";

configure({ enforceActions: "always" });
class AssessmentContactStore {
  private _contacts?: DTO_Assessment_Names = undefined;
  private _isLoading: boolean = false;
  private _isDetailLoading: boolean = false;
  private _isIncludePastNames: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get contacts() {
    return this._contacts;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isDetailLoading() {
    return this._isDetailLoading;
  }

  get isIncludePastNames() {
    return this._isIncludePastNames;
  }

  setContacts = (contacts?: DTO_Assessment_Names) => {
    runInAction(() => {
      this._contacts = contacts;
    });
  };

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  setIsDetailLoading = (isDetailLoading: boolean) => {
    runInAction(() => {
      this._isDetailLoading = isDetailLoading;
    });
  };

  setIsIncludePastNames = (isIncludePastNames: boolean) => {
    runInAction(() => {
      this._isIncludePastNames = isIncludePastNames;
    });
  };

  //Action

  resetStore = () => {
    runInAction(() => {
      this._contacts = undefined;
      this._isLoading = false;
      this._isDetailLoading = false;
      this._isIncludePastNames = false;
    });
  };

  loadContacts = async (assessmentId: number, isIncludePastNames?: boolean) => {
    if (!this.contacts) {
      this.setIsLoading(true);
    } else {
      this.setIsDetailLoading(true);
    }
    const contacts = await loadPropertyAssessmentNames(
      assessmentId,
      isIncludePastNames
    );
    this.setContacts(contacts);
    this.setIsLoading(false);
    this.setIsDetailLoading(false);
  };
}

const assessmentsContactsStoreContext = createContext(
  new AssessmentContactStore()
);

export const useAssessmentsContactsStore = () => {
  return useContext(assessmentsContactsStoreContext);
};
