import { IParcelLookup } from "@app/products/property/registers/[id]/components/dialogs/associate/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IParcelLookup>();
export const colAssociate: IColumnFields[] = [
  {
    field: nameOf("ParcelReference"),
    title: "Parcel Reference",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("SideOfStreet"),
    title: "Side of Street",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("MapReference"),
    title: "Map Reference",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOf("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOf("LandCategory"),
    title: "Land Category",
  },
  {
    field: nameOf("Owners"),
    title: "Owners",
  },
  {
    field: nameOf("Zoning"),
    title: "Zoning",
  },
  {
    field: nameOf("LandUses"),
    title: "Land Uses",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("ParcelId"),
    title: "Parcel ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
