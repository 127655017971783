import { history } from "@/AppRoutes";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

export const NewPermitReferralButton = () => {
  return (
    <CCNavButton
      title="New Permit Referral"
      onClick={() => history.push(`${TOWN_PLANNING_PPR_ROUTE}/new`)}
    />
  );
};
