import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderTrimNewComponents } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import {
  TRIMNUMBERINGMETHOD,
  TRIMSTORAGEMETHOD,
} from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const RecordsTrimSecondaryConsentForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter } = formRenderProps;
    const showStorageMethod =
      valueGetter(
        ECorporateSettingsField.TRIMAdmin_TP_SecConsentInheritApplicationFileNumber.toString()
      ) === false;
    const showNewTrim =
      parseInt(
        valueGetter(
          ECorporateSettingsField.TRIMAdmin_SCStorageMethod.toString()
        )
      ) === TRIMSTORAGEMETHOD.CREATE;
    const showTrimNumberPrefix =
      parseInt(
        valueGetter(ECorporateSettingsField.TRIMAdmin_SCNumberMethod.toString())
      ) === TRIMNUMBERINGMETHOD.SYSTEM;
    const enableSubFolderSystem = valueGetter(
      ECorporateSettingsField.TRIMAdmin_EnableSubFolderSystem.toString()
    );
    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .TRIMAdmin_TP_SecConsentInheritApplicationFileNumber
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            disabled={enableSubFolderSystem}
          />
          {showStorageMethod && (
            <>
              <CCSearchComboBoxGlobalSettings
                data={
                  configData[ECorporateSettingsField.TRIMAdmin_SCStorageMethod]
                }
                isEditing={isEditing}
              />
              {showNewTrim && (
                <>
                  {renderTrimNewComponents(
                    formRenderProps,
                    isEditing,
                    configData[
                      ECorporateSettingsField.TRIMAdmin_SCPrimaryContainerName
                    ],
                    configData[
                      ECorporateSettingsField.TRIMAdmin_SCClassification
                    ],
                    configData[
                      ECorporateSettingsField.TRIMAdmin_SCPrimaryRecordType
                    ]
                  )}
                  <InputGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField.TRIMAdmin_SCTitleFormat
                      ]
                    }
                    isEditing={isEditing}
                  />
                  <CCSearchComboBoxGlobalSettings
                    data={
                      configData[
                        ECorporateSettingsField.TRIMAdmin_SCNumberMethod
                      ]
                    }
                    isEditing={isEditing}
                  />
                  {showTrimNumberPrefix && (
                    <>
                      <InputGlobalSettings
                        data={
                          configData[
                            ECorporateSettingsField.TRIMAdmin_SCNumberPrefix
                          ]
                        }
                        isEditing={isEditing}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
    );
  }
);
