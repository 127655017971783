import { ISAPTransaction } from "@app/products/property/components/child-screen/sap-transactions/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ISAPTransaction>();
export const colSAPTransactions: IColumnFields[] = [
  {
    field: nameOf("CustomerID"),
    title: "Customer ID",
  },
  {
    field: nameOf("DocumentID"),
    title: "Document ID",
  },
  {
    field: nameOf("CustomerInformation"),
    title: "Customer Information",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("PostingDate"),
    title: "Posting Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NetDueDate"),
    title: "Net Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("BalanceCompanyAmount"),
    title: "Balance Company Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("OpenAmount"),
    title: "Open Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("ClearedAmount"),
    title: "Cleared Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SAPDocumentType"),
    title: "SAP Document Type",
  },
  {
    field: nameOf("LLSInvoiceType"),
    title: "LLS Invoice Type",
  },
  {
    field: nameOf("ExternalReferenceID"),
    title: "External Reference ID",
    style: formatStyleUnRelevant,
  },
];
