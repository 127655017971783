import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingSwitch } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-switch/_index";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData, getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

  const { valueGetter, onChange } = formRenderProps;

  if (!configData) return <></>;

  const isEnableUndertaking = valueGetter(
    ECorporateSettingsField.CorporateSettings_EnableNCItems_Undertaking.toString()
  );
  const isEnableProsecution = formRenderProps?.valueGetter(
    configData[
      ECorporateSettingsField.CorporateSettings_EnableNCItems_Prosecution.toString()
    ].FieldName
  );
  const isEnableNotice = formRenderProps?.valueGetter(
    configData[
      ECorporateSettingsField.CorporateSettings_EnableNCItems_Notice.toString()
    ].FieldName
  );
  const isEnableInspection = formRenderProps?.valueGetter(
    configData[
      ECorporateSettingsField.CorporateSettings_EnableNCItems_Inspection.toString()
    ].FieldName
  );
  const isEnableWarrant = formRenderProps?.valueGetter(
    configData[
      ECorporateSettingsField.CorporateSettings_EnableNCItems_SearchWarrant.toString()
    ].FieldName
  );
  const fieldNCItemLocation = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_NCItems_DefaultNCItemLocation
  );
  const fieldNCItemLocationDisplayValue = formRenderProps?.valueGetter(
    `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_NCItems_DefaultNCItemLocation}`
  )?.[0];
  const handleOnChangeNCItemLocation = (keyword: CoreKeyword) => {
    onChange(fieldNCItemLocation?.FieldName ?? "", {
      value: keyword?.Keyword_ID?.toString(),
    });
    onChange(
      `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_NCItems_DefaultNCItemLocation}`,
      { value: [keyword] }
    );
  };
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">{fieldNCItemLocation?.Title ?? ""}</label>
          <Field
            name={fieldNCItemLocation?.FieldName ?? ""}
            placeholder={fieldNCItemLocation?.Title ?? ""}
            value={fieldNCItemLocationDisplayValue ?? null}
            component={KeyWordsPicker}
            keyWordType={KEYWORD_TYPE.NCItemLocation}
            productType={PRODUCT_TYPE_NUMBER.TownPlanning}
            onPickKeyWords={handleOnChangeNCItemLocation}
            grid={{
              selectedRows: valueGetter(
                `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_NCItems_DefaultNCItemLocation}`
              ),
            }}
            textField={nameOfCoreKeyword("Name")}
            isGetDetail
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <label className="cc-label">
          <u>Undertaking</u>
        </label>
      </div>
      <div className="cc-form-cols-3">
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.CorporateSettings_EnableNCItems_Undertaking
          }
          formRenderProps={formRenderProps}
        />
        {isEnableUndertaking && (
          <>
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_ComplianceInstruction_Undertaking
              }
              formRenderProps={formRenderProps}
            />
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_MarkInheritedNCItems_Undertaking
              }
              formRenderProps={formRenderProps}
            />
          </>
        )}
      </div>
      <div className="cc-form-cols-1">
        <label className="cc-label">
          <u>Prosecution</u>
        </label>
      </div>
      <div className="cc-form-cols-3">
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.CorporateSettings_EnableNCItems_Prosecution
          }
          formRenderProps={formRenderProps}
        />
        {isEnableProsecution && (
          <>
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_ComplianceInstruction_Prosecution
              }
              formRenderProps={formRenderProps}
            />
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_MarkInheritedNCItems_Prosecution
              }
              formRenderProps={formRenderProps}
            />
          </>
        )}
      </div>
      <div className="cc-form-cols-1">
        <label className="cc-label">
          <u>Notice</u>
        </label>
      </div>
      <div className="cc-form-cols-3">
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.CorporateSettings_EnableNCItems_Notice
          }
          formRenderProps={formRenderProps}
        />
        {isEnableNotice && (
          <>
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_ComplianceInstruction_Notice
              }
              formRenderProps={formRenderProps}
            />
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_MarkInheritedNCItems_Notice
              }
              formRenderProps={formRenderProps}
            />
          </>
        )}
      </div>
      <div className="cc-form-cols-1">
        <label className="cc-label">
          <u>Inspection</u>
        </label>
      </div>
      <div className="cc-form-cols-3">
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.CorporateSettings_EnableNCItems_Inspection
          }
          formRenderProps={formRenderProps}
          isdisable
        />
        {isEnableInspection && (
          <>
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_ComplianceInstruction_Inspection
              }
              formRenderProps={formRenderProps}
            />
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_MarkInheritedNCItems_Inspection
              }
              formRenderProps={formRenderProps}
            />
          </>
        )}
      </div>
      <div className="cc-form-cols-1">
        <label className="cc-label">
          <u>Search Warrant</u>
        </label>
      </div>
      <div className="cc-form-cols-3">
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.CorporateSettings_EnableNCItems_SearchWarrant
          }
          formRenderProps={formRenderProps}
        />
        {isEnableWarrant && (
          <>
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_ComplianceInstruction_SearchWarrant
              }
              formRenderProps={formRenderProps}
            />
            <GlobalSettingSwitch
              enumParam={
                ECorporateSettingsField.CorporateSettings_MarkInheritedNCItems_SearchWarrant
              }
              formRenderProps={formRenderProps}
            />
          </>
        )}
      </div>
    </section>
  );
};
