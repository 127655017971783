import { VO_Supplementary_Levies } from "@app/products/property/supplementary-rates/[id]/components/child-screens/levies/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary_Levies>();

export const colRatesLevies: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Assessment_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("LevyCategory_Name"),
    title: "Levy Category",
  },
  {
    field: nameOf("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOf("Differential_Category"),
    title: "Differential Category",
  },
  {
    field: nameOf("Number_of_Minimums"),
    title: "Number of Minimums",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Levy_Quantity_Amount"),
    title: "Quantity/Amount",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Percentage"),
    title: "Percentage of Levy",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("AL_Base_Adjustment_Rate"),
    title: "Percentage of Base Amount",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("AL_RateByValue_Adjustment_Rate"),
    title: "Percentage of Rate by Valn Amount",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Old_Active_From_Date"),
    title: "Old Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Old_Active_To_Date"),
    title: "Old Active To Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Active_From_Date"),
    title: "New Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("New_Active_To_Date"),
    title: "New Active To Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Has_Changed"),
    title: "Levy Changed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Change_UserId"),
    title: "Last Change By",
  },
  {
    field: nameOf("Change_DateTime"),
    title: "Last Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
