import { eventEmitter } from "@/App";
import {
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromForm } from "@app/core/new-mail-merge/dialogs/util";
import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putResponseLetter } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/create-response-letter/api";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { MailMergeSelectDocument } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/create-application-report/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const CreateResponseLetterButton = observer(() => {
  const { ppr, pprId, setPPRWithLoading } = usePSARStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { settings } = useCommonProductStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMailMergeDialog, setShowMailMergeDialog] = useState(false);
  const [initialMailMerge, setInitialMailMerge] =
    useState<MailMerge_SelectMergeDocument_Ext>();

  const isVisible = useMemo(() => {
    if (ppr) {
      if (
        ppr.Status_ENUM === Application_Status.Referral ||
        ppr.Status_ENUM === Application_Status.Refused
      ) {
        if (isNil(ppr.PPRDetails.ResponseLetter_AttachmentID)) {
          return true;
        }
      } else if (ppr.Status_ENUM === Application_Status.PreliminaryAssessment) {
        if (
          !isNil(ppr.PPRDetails.Flag_IsReferralRequired) &&
          isNil(ppr.PPRDetails.ResponseLetter_AttachmentID)
        ) {
          return true;
        }
      }
    }

    return false;
  }, [ppr]);

  const handleCreateDocumentLetter = () => {
    const mailMerge = setUpMailMergeFromForm();
    mailMerge.Dataset_Filters.push(
      MAILMERGEDATASET.TOWNPLANNING_PSA_Applications
    );
    const currResponseLetterDocumentIDs =
      settings[ECorporateSettingsField.TP_PSARResponseLetterDocuments]?.Value;
    if (isEmpty(currResponseLetterDocumentIDs)) {
      pushNotification({
        title: "Response letter template not configured in system settings",
        type: "warning",
      });
      return;
    } else {
      const listCurrResponseLetterDocumentIDs = currResponseLetterDocumentIDs
        .split("|")
        .map((item: string) => +item);
      if (
        listCurrResponseLetterDocumentIDs &&
        listCurrResponseLetterDocumentIDs.length > 0
      ) {
        mailMerge.DocumentIDs = listCurrResponseLetterDocumentIDs;
      }
    }
    setInitialMailMerge({
      ...mailMerge,
      _options: {
        SaveAsPDF: mailMerge.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
      },
    });
    setShowMailMergeDialog(true);
  };

  const handleOnSubmit = async (
    mailMerge: MailMerge_SelectMergeDocument_Ext
  ) => {
    const requestBody: MailMergeSelectDocument = {
      SelectedMailMergeDocumentIDs: mailMerge?.DocumentIDs,
      RecordType: RECORDTYPE.TP_PSA_Application,
      AppendToRecordSource: mailMerge?.AppendToRecordSource,
      BatchPrint: mailMerge?.BatchPrint,
      SaveAsPdf: mailMerge?._options?.SaveAsPDF ?? false,
      Category: mailMerge?.Category_KWD,
    };

    if (pprId) {
      setIsSubmitting(true);
      const response = await putResponseLetter(pprId, requestBody);
      setIsSubmitting(false);
      setShowMailMergeDialog(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        clearNotifications();
        setPPRWithLoading(response.data.Application);
        pushNotification({
          title: "Response letter created successfully",
          type: "success",
        });
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        pushNotification({
          autoClose: false,
          title: "The create response letter workflow could not be completed.",
          description: response.data?.Errors ?? response.statusText,
          type: "error",
        });
      }
    }
    setShowMailMergeDialog(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Create response letter"
        onClick={handleCreateDocumentLetter}
      />
      {showMailMergeDialog && (
        <MailMergeDialog
          isSubmitting={isSubmitting}
          initialMailMerge={initialMailMerge}
          onClose={() => {
            setShowMailMergeDialog(false);
          }}
          onSubmit={(mailMerge: MailMerge_SelectMergeDocument_Ext) =>
            handleOnSubmit(mailMerge)
          }
          productType={PRODUCT_TYPE_NUMBER.TownPlanning}
          isMultiple
        />
      )}
    </>
  ) : null;
});
