import {
  IAccounts,
  SearchType,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAccounts>();

export const colAccounts: IColumnFields[] = [
  {
    field: nameOf("Register"),
    title: "Register",
  },
  {
    field: nameOf("AccountReference"),
    title: "Account Reference",
  },
  {
    field: nameOf("AccountDescription"),
    title: "Account Description",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("AccountId"),
    title: "Account ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const defaultAccounts = {
  SearchType: { Key: 1, Value: SearchType.AccountReference },
  _option: {
    Account: {
      Data: [],
      Loading: false,
    },
  },
};

export const searchTypeData: IKeyValuePacket[] = [
  { Key: 1, Value: SearchType.AccountReference },
  { Key: 2, Value: SearchType.AccountId },
];
