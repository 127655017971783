import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { booleanData } from "@components/cc-product-list-view/components/list-view-filter-bar/components/cc-boolean-filter/config";
import { ICCInputFilterProps } from "@components/cc-product-list-view/components/list-view-filter-bar/model";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React from "react";

export const CCBooleanFilter = (props: ICCInputFilterProps) => {
  const { filter, onFilterChange } = props;

  const onChange = (event: DropDownListChangeEvent) => {
    if (!event.target.value) {
      return;
    }
    onFilterChange.call(undefined, {
      nextFilter: { ...filter, value: event.target.value?.Key },
    });
  };

  return (
    <CCDropDownList
      onChange={onChange}
      value={filter.value}
      data={booleanData}
      isKeyValueDropdown
    />
  );
};
