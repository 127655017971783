import { CCDocumentsInput } from "@app/core/documents/components/documents-input/_index";
import { IAttachmentFile } from "@app/core/documents/components/documents-input/model";
import { Attachment } from "@app/core/documents/model";
import { DownloadFile } from "@common/constants/downloadfile";

import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { MAX_LENGTH_DOCUMENT_NAME } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/config";
import { fileUploadValidator } from "@common/pages/settings/lookups/documents/_id/util";
import { nameOfCCHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/model";
import { useLookupHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import {
  Field,
  FormElement,
  FormRenderProps
} from "@progress/kendo-react-form";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
interface ILookupHelpDocumentFormElementProps {
  formRenderProps: FormRenderProps;
}

export const LookupHelpDocumentFormElement = observer(
  ({ formRenderProps }: ILookupHelpDocumentFormElementProps) => {
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;
    const { isInactive } = useLookupHelpDocument();
    const filterTypesStr: string =
      settings[ECorporateSettingsField.CorporateSettings_FileExtension]?.Value;
    const handleOnDownload = (attachmentInfo?: Attachment) => {
      if (!isNil(attachmentInfo)) {
        DownloadFile(
          attachmentInfo?.FileEntry?.Base64FileAttachment ?? "",
          attachmentInfo?.FileEntry?.ContentType ?? "",
          attachmentInfo?.FileEntry?.FileName ?? ""
        );
      }
    };

    const allowedExtensions = useMemo(() => {
      if (!isEmpty(filterTypesStr)) {
        return filterTypesStr
          .toUpperCase()
          .split("|")
          .map((item) => item.replace(".", "").replace("*", ""));
      }
      return [];
    }, [filterTypesStr]);

    const handleUploadFile = (attachments: IAttachmentFile[]) => {
      if (!attachments || attachments.length === 0) {
        return;
      }
      onChange("_options.FileUpload", {
        value: attachments,
      });
      onChange(nameOfCCHelpDocument("FileEntry"), {
        value: {
          ...attachments[0]?.FileEntry,
          FileAttachment: attachments[0]?.FileEntry?.Base64FileAttachment,
        },
      });
    };

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel
                title="Document name"
                informationTooltip="Document name is a required field"
              />
              <Field
                name={nameOfCCHelpDocument("Title")}
                component={CCInput}
                validator={requiredValidator}
                maxLength={MAX_LENGTH_DOCUMENT_NAME}
                disabled={isInactive}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="File" />
              <Field
                name="_options.FileUpload"
                value={valueGetter("_options.FileUpload") ?? []}
                restrictions={{
                  allowedExtensions: allowedExtensions,
                }}
                mode="single"
                onChange={handleUploadFile}
                component={CCDocumentsInput}
                onClick={handleOnDownload}
                validator={fileUploadValidator}
                isUploadOnAdd={false}
                disabled={isInactive}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
