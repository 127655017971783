import { actionTaskScheduleData } from "@app/products/property/deferred-duty/components/action-bar/dialogs/raise-interest/form-elements/task-schedule/config";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInput } from "@components/cc-input/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";

export const RaiseDeferredDutyInterestTaskScheduleFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
export const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = { isReadOnly: false },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Task type</label>
            <Field
              name={nameOf("TaskType")}
              component={CCInput}
              placeholder={"Task type"}
              readOnly={true}
            />
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <label className="cc-label">Action</label>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <div className="cc-control-item">
              <Field
                name={nameOf("RunImmediately")}
                component={RadioGroup}
                data={actionTaskScheduleData}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
        {getFieldValue("RunImmediately") === false ? (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <Field
                name={nameOf("RunAt")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        ) : null}
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Field
              label="Email recipients"
              name={nameOf("EmailRecipients")}
              placeholder={"Email recipients"}
              rows={3}
              readOnly={options?.isReadOnly}
              component={CCInputEmail}
              validator={!options?.isReadOnly ? validateEmail : undefined}
              onChangeForm={onChange}
              isTextArea
            />
          </div>
        </div>
      </section>
    </>
  );
};
