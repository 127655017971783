import { FormatNameAndAddressDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/format-name-and-address/_index";
import { SearchNameContact } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/lease/components/search-name/_index";
import { ILeaseName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/lease/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { listSearchOptions } from "@app/products/property/components/fields/search-name/config";
import { NewContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/_index";
import { UpdateContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/_index";
import { nameOfLov } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { colNames, fieldName } from "./config";

const nameOfLeaseNames = nameOfFactory<ILeaseName>();
export const LeaseFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const { changeOfOwnershipLOVs } = useChangeOfOwnershipDialogStore();
  const [formatLessees, setFormatLessees] = useState<any>();
  const [showNewEntityDialog, setShowNewEntityDialog] = useState(false);
  const [newEntityData, setNewEntityData] = useState<any>();
  const [updateEntityData, setUpdateEntityData] = useState<any>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const namesValue = getFieldValue(fieldName.Names);
  const selectedName = getFieldValue(fieldName.NameSelected);
  const handleAddName = (values: any) => {
    if (values?.IsExist === false) {
      setNewEntityData(values);
      setShowNewEntityDialog(true);
      return;
    }
    let newNamesValue = [...namesValue];
    if (
      !newNamesValue.find(
        (name) => name[fieldName.PrimaryKey] === values[fieldName.PrimaryKey]
      )
    ) {
      newNamesValue.unshift({
        [fieldName.Name]: values[fieldName.Name],
        [fieldName.Address]: values[fieldName.Address],
        [fieldName.PrimaryKey]: values[fieldName.PrimaryKey],
      });
      onChange(nameOf(fieldName.Names), { value: newNamesValue });
    } else {
      notificationRef.current?.pushNotification({
        title: `${values.Name} already exists`,
        type: "warning",
      });
    }
    onChange(nameOf(fieldName.SearchName), { value: null });
  };

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
  };

  const handleRemoveName = (name: any) => {
    if (name) {
      let newNames = namesValue.filter(
        (item: any) => item[fieldName.PrimaryKey] !== name[fieldName.PrimaryKey]
      );
      if (newNames[0]) {
        newNames[0].selected = true;
        handleGridSelectionChange([newNames[0]], fieldName.NameSelected);
      } else {
        handleGridSelectionChange([], fieldName.NameSelected);
      }

      onChange(nameOf(fieldName.Names), { value: newNames });
    }
  };
  const handleNameInfoChange = (field: string, value: any) => {
    onChange(nameOf(field), {
      value: value,
    });

    if (selectedName) {
      let newNames = namesValue.map((name: any) => {
        if (name[fieldName.PrimaryKey] !== selectedName[fieldName.PrimaryKey])
          return name;
        name[field] = value;
        return name;
      });
      onChange(nameOf(fieldName.Names), { value: newNames });
    }
  };

  const handleChangeEntity = (values: any, isNew?: boolean) => {
    const person = values?.Person;
    const newName = {
      [fieldName.Name]:
        (person?.GivenNames ? ` ${person?.GivenNames}, ` : "") +
        (person?.Surname ? `${person?.Surname}` : ""),
      [fieldName.Address]:
        (person?.HouseNo ? person?.HouseNo : "") +
        (person?.StreetName ? ` ${person?.StreetName}` : "") +
        (person?.StreetType ? ` ${person?.StreetType}` : "") +
        (person?.AdditionalAddress ? ` ${person?.AdditionalAddress}` : "") +
        (person?.Locality ? ` ${person?.Locality}` : "") +
        (person?.State ? ` ${person?.State}` : "") +
        (person?.PostCode ? ` ${person?.PostCode}` : ""),
      [fieldName.PrimaryKey]: Math.random() * 100 + 100,
    };
    if (newName[fieldName.Name] === "") return;
    if (isNew) {
      handleAddName(newName);
    } else {
      handleNameInfoChange(fieldName.Name, newName[fieldName.Name]);
      handleNameInfoChange(fieldName.Address, newName[fieldName.Address]);
    }
  };

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group cc-change-of-ownership-type-step">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Lease type</label>
            <Field
              name={nameOf("LeaseType")}
              filterable
              suggest
              component={CCComboBox}
              placeholder="Lease type"
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Reference</label>
            <Field
              name={nameOf("Reference")}
              component={CCInput}
              placeholder="Reference"
              readOnly={options?.isReadOnly}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">Lease date from</label>
            <Field
              name={nameOf("LeaseDateFrom")}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Lease date to</label>
            <Field
              name={nameOf("LeaseDateTo")}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Conditions</label>
            <Field
              name={nameOf("Conditions")}
              component={CCTextArea}
              rows={3}
              placeholder="Conditions"
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <SearchNameContact
            fieldName={nameOf(fieldName.SearchName)}
            onChange={handleAddName}
            formRenderProps={formRenderProps}
            nameOf={nameOf}
            isDisabled={options?.isReadOnly}
          />

          <div className="cc-field">
            <label className="cc-label">Search options</label>
            <Field
              name={nameOf("SearchOptions")}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={listSearchOptions}
              component={CCMultiSelectDropdown}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Names</label>
            <CCGrid
              className="cc-owners-change-of-ownership"
              selectedRows={selectedName ? [selectedName] : undefined}
              columnFields={
                !options?.isReadOnly //Do nothing while in readOnly mode
                  ? colNames.map((col) => {
                      if (col.field === fieldName.Name) {
                        col.handleOnClick = (dataItem: any) => {
                          onChange(nameOf(fieldName.NameSelected), {
                            value: dataItem,
                          });
                          setUpdateEntityData(dataItem);
                        };
                      }
                      return col;
                    })
                  : colNames
              }
              readOnly={options?.isReadOnly}
              selectableMode="single"
              primaryField={nameOfLeaseNames("ID")}
              onSelectionChange={(dataItem: any) => {
                handleGridSelectionChange(dataItem, fieldName.NameSelected);
              }}
              data={getFieldValue(fieldName.Names) || []}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Lease from list"
                    disabled={!selectedName}
                    onClick={() => {
                      handleRemoveName(selectedName);
                    }}
                  />
                </div>
              }
            />
          </div>
          <div className="cc-field">
            <div className="cc-label-control-between">
              <label className="cc-label">Lessees</label>
              <Button
                fillMode="flat"
                themeColor="primary"
                className={"cc-edit-field-button"}
                onClick={() => {
                  setFormatLessees(true);
                }}
                title="Edit Lessees"
                iconClass="fa fa-edit"
                disabled={options?.isReadOnly}
              />
            </div>
            <Field
              name={nameOf("Lessees")}
              rows={4}
              component={CCTextArea}
              placeholder="Lessees"
              readOnly
            />
          </div>
        </div>
      </section>
      {formatLessees ? (
        <FormatNameAndAddressDialog
          noticeGroupData={changeOfOwnershipLOVs?.NoticeGroups ?? []}
          onClose={() => {
            setFormatLessees(undefined);
          }}
          onSubmit={() => {
            setFormatLessees(undefined);
          }}
        />
      ) : null}
      {showNewEntityDialog ? (
        <NewContactDialog
          isOpenedFromParentWorkflow
          isRedirectManagePage={false}
          currentEntity={{
            Surname: newEntityData[fieldName.Name].split(",")[0] || "",
          }}
          onClose={() => {
            setShowNewEntityDialog(false);
          }}
          handleSubmitDialog={(values: any) => {
            handleChangeEntity(values, true);
            setShowNewEntityDialog(false);
          }}
          isSaveOnNextStep
        />
      ) : null}
      {updateEntityData ? (
        <UpdateContactDialog
          entityId={updateEntityData?.ID}
          onClose={() => {
            setUpdateEntityData(undefined);
          }}
          handleSubmitDialog={(values: any) => {
            handleChangeEntity(values, false);
            setUpdateEntityData(undefined);
          }}
          isSaveOnNextStep
        />
      ) : null}
    </>
  );
};
