import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Notice_Run_Id
    ? `- ${selectedRow.Notice_Run_Id}`
    : "";

  return `Property - Charge and Notice Runs - Notice Runs ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Notice_Run_Id,
    selectedRow.NR_Name,
  ]);
};

export const getBookmarkListViewDetail = () => {
  return "Property - Charge And Notice Runs - Notice Runs - List";
};
