import { Asset } from "@app/products/crms/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IODataResponse } from "@common/models/odataResponse";
import { CancelTokenSource } from "axios";

export const getAssetList = async (
  searchText: string,
  requestController?: CancelTokenSource
): Promise<APIResponse<IODataResponse<Asset> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      `odata/crms/internal/events/GetAssetSiteSearch(searchText='${searchText}')`,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
