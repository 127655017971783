import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingText } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/global-text-input/_index";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={
            configData[ECorporateSettingsField.CorporateSettings_StaffOnLeave]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
      </div>
      <div className="cc-form-cols-3">
        <label className="cc-label">User Types</label>
      </div>
      <div className="cc-form-cols-3">
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_SiteUserL1}
        />
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_SiteUserL2}
        />
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_SiteUserL3}
        />
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_SiteUserL4}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_SiteUser_EnableDriverLicence
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_SiteUser_PasswordPolicy
            ]
          }
          isEditing={isEditing}
        />
      </div>
    </section>
  );
};
