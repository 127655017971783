import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { useModifyParcelDialogStore } from "@app/products/property/parcels/components/form-steps/modify-parcel/store";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isDisabled: false,
      isToBeApprovalMode: false,
    },
  }: IFormStepElement) => {
    const { modifyParcelLOVs } = useModifyParcelDialogStore();
    return (
      <>
        <section className="cc-field-group">
          {options?.isToBeApprovalMode && (
            <Field
              name={nameOf("Instructions")}
              rows={3}
              component={CCTextArea}
              placeholder="Workflow instructions"
              readOnly
            />
          )}
          <div className="cc-field">
            <OfficerAndOfficerRegion
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisabled={options?.isDisabled}
            />
          </div>
        </section>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Applicant name" isMandatory />
              <Field
                name={nameOf("ApplicantName")}
                component={CCInput}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Applicant name"
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reason for change" isMandatory />
              <Field
                name={nameOf("ReasonForCreation")}
                component={CCSearchComboBox}
                data={modifyParcelLOVs?.ReasonForChange ?? []}
                textField="Name"
                dataItemKey="Code"
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reference" isMandatory />
              <Field
                name={nameOf("Reference")}
                readOnly={options?.isReadOnly}
                component={CCInput}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                placeholder="Reference"
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Status" />
              <Field
                name={nameOf("Status")}
                component={CCSearchComboBox}
                data={convertValueLOVToNumber(
                  modifyParcelLOVs?.Parcel_Status ?? []
                )}
                textField="Name"
                dataItemKey="Code"
                disabled={options?.isReadOnly}
                isUseDefaultOnchange
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
