export const mockReadings = [
  {
    MeterNumber: "03W521424",
    ReadingDate: "27-Sep-2021 10:44",
    MeterReading: 1509,
    Usage: 127,
    MeterUnits: "kl",
    BaseUsage: 127,
    BaseUnits: "kl",
    DaysSinceLastRead: 478,
    AverageDailyUsage: 0.27,
    ReadingStatus: "Estimate",
    JournalNumber: 10378,
    EnterBy: "rhosking",
    EnteredOn: "27-Sep-2021 10:44",
    MeterReadingId: 102696,
    MeterId: 10,
  },
  {
    MeterNumber: "09B030178",
    ReadingDate: "8-Nov-2019 10:15",
    MeterReading: 23,
    Usage: 23,
    MeterUnits: "kl",
    BaseUsage: 73,
    BaseUnits: "kl",
    DaysSinceLastRead: 73,
    AverageDailyUsage: 0.32,
    Reader: "Meter Reader 1",
    ReadingStatus: "Read",
    JournalNumber: 10378,
    EnterBy: "rhosking",
    EnteredOn: "27-Sep-2021 10:47",
    MeterReadingId: 92636,
    MeterId: 5580,
  },
  {
    MeterNumber: "09B030178",
    ReadingDate: "6-Jun-2020 14:00",
    MeterReading: 44,
    Usage: 21,
    MeterUnits: "kl",
    BaseUsage: "",
    BaseUnits: "kl",
    DaysSinceLastRead: 211,
    AverageDailyUsage: 0.1,
    Reader: "Meter Reader 1",
    ReadingStatus: "Read",
    JournalNumber: 10378,
    EnterBy: "rhosking",
    EnteredOn: "27-Sep-2021 10:47",
    MeterReadingId: 102246,
    MeterId: 5580,
  },
];
