import { getCreateAssessmentLOVs } from "@app/products/property/assessments/components/form-steps/modify-assessment/api";
import { VO_CollectionType } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/model";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { postNewCollection } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/new-collection/api";
import { DTO_CollectionDialog } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/component/dialog/new-collection/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  requiredValidatorAndMaxLength,
} from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldValidatorType,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface INewCollectionProps {
  onClose: () => void;
  onSubmit: (data: VO_CollectionType[]) => void;
  collectionTypeListData?: DTO_LOV[];
  isShowCollectionInList?: boolean;
}

const requiredAndMaxLengthValidator = (
  value: FieldValidatorType | FieldValidatorType[] | undefined
) => {
  return requiredValidatorAndMaxLength(value, 100);
};

const nameOf = nameOfFactory<DTO_CollectionDialog>();
const nameOfCollectionType = nameOfFactory<DTO_LOV>();
export const NewCollection = observer(
  ({
    onClose,
    onSubmit,
    collectionTypeListData = undefined,
    isShowCollectionInList = false,
  }: INewCollectionProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { assessmentCollectionStepLOVs, setAssessmentCollectionStepLOVs } =
      useAssessmentCollectionStepStore();
    const [isLoadingLoadLOV, setIsLoadingLoadLOV] = useState(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const handleSubmit = async (values: { [name: string]: any }) => {
      const payload: DTO_CollectionDialog = {
        ...values,
        Collection_Id: 0,
        Collection_Type_Id:
          values?.CollectionTypeName && parseInt(values?.CollectionTypeName),
      };
      delete payload?.CollectionTypeName;
      setIsLoading(true);
      const response = await postNewCollection(payload);
      if (isSuccessResponse(response) && response.data) {
        onSubmit(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Add new collection failed",
          type: "error",
        });
      }
    };
    const loadCollectionLOVs = async () => {
      setIsLoadingLoadLOV(true);
      const response = await getCreateAssessmentLOVs();
      if (isSuccessResponse(response) && response?.data) {
        setAssessmentCollectionStepLOVs(
          response.data.dtoCreate_Assessment_LOVs
        );
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: response.error ?? "Load collections LOV failed",
          type: "error",
        });
      }
      setIsLoadingLoadLOV(false);
    };

    useEffectOnce(() => {
      if (collectionTypeListData && collectionTypeListData?.length > 0) return;
      loadCollectionLOVs();
    });
    const LOVofCollectionType = useMemo(() => {
      if (collectionTypeListData && collectionTypeListData?.length > 0) {
        return collectionTypeListData;
      }
      if (
        assessmentCollectionStepLOVs?.Collection_Type &&
        assessmentCollectionStepLOVs?.Collection_Type.length > 0
      ) {
        return assessmentCollectionStepLOVs?.Collection_Type;
      }
      return [];
    }, [collectionTypeListData, assessmentCollectionStepLOVs?.Collection_Type]);

    return (
      <Form
        initialValues={{ Collection_Show_in_List: isShowCollectionInList }}
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, valueGetter, onSubmit, modified, valid } =
            formRenderProps;
          const getFieldValue = (name: keyof DTO_CollectionDialog) =>
            valueGetter(nameOf(name));
          return (
            <CCDialog
              maxWidth="50%"
              height="auto"
              disabled={isLoading}
              titleHeader={"New Collection"}
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form cc-memo-dialog">
                  <section className="cc-field-group">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Collection type
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("CollectionTypeName")}
                          component={CCSearchComboBox}
                          data={LOVofCollectionType}
                          textField={nameOfCollectionType("Name")}
                          dataItemKey={nameOfCollectionType("Code")}
                          validator={requiredValidator}
                          isUseDefaultOnchange
                          isLoading={isLoadingLoadLOV}
                          disabled={isLoadingLoadLOV}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Name
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("Collection_Name")}
                          placeholder={"Name"}
                          validator={requiredAndMaxLengthValidator}
                          component={CCInput}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Notes</label>
                        <Field
                          name={nameOf("Collection_Notes")}
                          component={CCTextArea}
                          rows={3}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Show collection in lists
                        </label>
                        <CCSwitch
                          checked={getFieldValue("Collection_Show_in_List")}
                          name={nameOf("Collection_Show_in_List")}
                          onChange={(event: SwitchChangeEvent) => {
                            onChange(nameOf("Collection_Show_in_List"), {
                              value: event.value,
                            });
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    disabled={isLoading || !valid || !modified}
                    className={"cc-dialog-button"}
                    onClick={onSubmit}
                  >
                    Done
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
