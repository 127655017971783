import { putInfoNotReceived } from "@app/core/further-info/components/buttons/work-flow/info-not-received/api";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

export interface IInfoNotReceived {
  DateInfoNotReceived: Date;
  FI_NotReceivedComments: string | null;
}

export interface IInfoNotReceivedDialogProps {
  onClosePopup: () => void;
  onSubmit: (dataItem: IInfoNotReceived) => void;
}

export const InfoNotReceivedDialog = ({
  onClosePopup,
  onSubmit,
}: IInfoNotReceivedDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nameOf = nameOfFactory<IInfoNotReceived>();
  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const data: IInfoNotReceived = {
      DateInfoNotReceived: event.values.DateInfoNotReceived,
      FI_NotReceivedComments: event.values.FI_NotReceivedComments,
    };
    setIsLoading(true);
    const response = await putInfoNotReceived(+id, data);
    customLogger(
      "Core Further info, InfoNotReceivedDialog, putInfoNotReceived"
    ).info(response?.data);
    if (isSuccessResponse(response) && data.DateInfoNotReceived !== null) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        onSubmit(data);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Information not received failed",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: response.statusText ?? "Information not received failed",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <Form
      onSubmitClick={(event: FormSubmitClickEvent) => handleSubmit(event)}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, modified, valid } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="35%"
              height="auto"
              disabled={isLoading}
              titleHeader={"Information Not Received"}
              onClose={onClosePopup}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Date
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("DateInfoNotReceived")}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Comments</label>
                        <Field
                          placeholder={"Comments"}
                          name={nameOf("FI_NotReceivedComments")}
                          rows={4}
                          component={CCTextArea}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!modified || !valid}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
