import { AmsAssetRegister } from "@app/products/ams/assets/[id]/model";
import { useAssetManagerStore } from "@app/products/ams/assets/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<AmsAssetRegister>();
interface IProps {
  formRenderProps: FormRenderProps;
}
export const ManageAsset = observer(({ formRenderProps }: IProps) => {
  const isNew = useIsNew();
  const { assetLOVs } = useAssetManagerStore();

  const { pushNotification } = useCCAppNotificationStore();

  /* old code
  const modelCfgSet = getModelConfigSetForModelRef(modelRef);
  let modelDataSet = getDataForModelCfgSet(modelCfgSet);
  modelDataSet.push({
    modelReference: { modelName: "AssetRegister", context: "assetCategory" },
    records: [asset],
  }); */
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };
  return (
    <FormElement className="cc-property-assessment-form-body">
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Asset category
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("AssetCategoryId")}
              component={CCSearchComboBox}
              data={assetLOVs?.AssetCategory ?? []}
              textField="description"
              dataItemKey="id"
              disabled={!isNew}
              validator={requiredValidator}
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Asset number</label>
            <Field
              name={nameOf("AssetNumber")}
              component={CCInput}
              readOnly
              placeholder="Asset number"
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Asset description
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("AssetDescription")}
              component={CCInput}
              validator={requiredValidator}
              placeholder="Asset description"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Status <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("AssetStatusId")}
              component={CCSearchComboBox}
              validator={requiredValidator}
              data={assetLOVs?.AssetStatus ?? []}
              textField="description"
              dataItemKey="id"
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Equipment number</label>
            <Field
              name={nameOf("EquipmentNumber")}
              component={CCInput}
              placeholder="Equipment number"
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Warranty End Date</label>
            <Field component={CCDatePicker} name={nameOf("WarrantyEndDate")} />
          </div>

          <div className="cc-field">
            <label className="cc-label">Design life</label>
            <Field
              name={nameOf("DesignLife")}
              component={CCInput}
              placeholder="Design life"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Serial number</label>
            <Field
              name={nameOf("SerialNumber")}
              component={CCInput}
              placeholder="Serial number"
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Employee custodian</label>
            <Field
              name={nameOf("EmployeeCustodian")}
              component={CCInput}
              placeholder="Employee custodian"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Employee number</label>
            <Field
              name={nameOf("EmployeeNumber")}
              component={CCInput}
              placeholder="Employee number"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Location</label>
            <Field
              name={nameOf("LocationId")}
              component={CCSearchComboBox}
              data={assetLOVs?.location ?? []}
              textField="description"
              dataItemKey="id"
              isUseDefaultOnchange
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Department</label>
            <Field
              name={nameOf("DepartmentId")}
              component={CCSearchComboBox}
              data={assetLOVs?.department ?? []}
              textField="description"
              dataItemKey="id"
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Cost centre</label>
            <Field
              name={nameOf("CostCentreId")}
              component={CCSearchComboBox}
              data={assetLOVs?.costCentre ?? []}
              textField="description"
              dataItemKey="id"
              isUseDefaultOnchange
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">OS product</label>
            <Field
              name={nameOf("OsProductId")}
              component={CCSearchComboBox}
              data={assetLOVs?.osProduct ?? []}
              textField="description"
              dataItemKey="id"
              isUseDefaultOnchange
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
});
