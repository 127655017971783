import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { Journal } from "@app/core/journal/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { BuildingRegisterStormwaterForm } from "@app/products/building/registers/stormwater/[id]/components/child-screens/general/_index";
import { StormwaterContactsTab } from "@app/products/building/registers/stormwater/[id]/components/reference-sidebar/contacts/_index";
import { StormwaterDetailTab } from "@app/products/building/registers/stormwater/[id]/components/reference-sidebar/detail/_index";
import { BuildingStormwaterRecordState } from "@app/products/building/registers/stormwater/[id]/model";
import { useBuildingRegistersStormwaterStore } from "@app/products/building/registers/stormwater/[id]/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import Contacts from "@common/pages/contacts/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedBuildingRegisterStormwater = observer(() => {
  const { id } = useParams<{ id: string }>(); // TODO: The id variable is only use in case reloading.
  const {
    buildingRegistersStormwater,
    buildingRegistersStormwaterId,
    loadBuildingRegistersStormwater,
    responseLoadError,
    isLoading,
  } = useBuildingRegistersStormwaterStore();
  const { currentUserInfo } = useGlobalStore();

  const formTitle = useMemo(() => {
    const registerNumber = buildingRegistersStormwater?.ID;
    const contact = buildingRegistersStormwater?.Contact;
    // If these two are not falsy, return both, otherwise return one or default
    if (registerNumber && contact) {
      return `${registerNumber} - ${contact}`;
    }
    return String(registerNumber) || contact || "N/A";
  }, [buildingRegistersStormwater]);

  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Building - Registers - Stormwater - ${
        buildingRegistersStormwaterId ?? 0
      }`,
      Text:
        `${getDisplayTextWithDashes([
          buildingRegistersStormwater?.RegisterNumber,
          buildingRegistersStormwater?.Contact,
        ])}` || "N/A",
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Building,
      RecordType_ENUM: RECORDTYPE.BUILDING,
      Record_ID: buildingRegistersStormwaterId
        ? +buildingRegistersStormwaterId
        : 0,
    },
  ];

  const [buildingStormwaterRecordState] =
    useState<BuildingStormwaterRecordState>(
      new BuildingStormwaterRecordState()
    );
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <StormwaterDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts", component: <StormwaterContactsTab /> },
      {
        title: "History",
        component: (
          <Journal id={0} recordType={RECORDTYPE.TP_BuildingApplication} />
        ),
      },
    ],
  });
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Addresses",
      totalRecord: buildingStormwaterRecordState.addresses,
      component: (
        <SamplesAccordion
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Actions",
      totalRecord: buildingStormwaterRecordState.actions,
      component: (
        <Actions
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Comments",
      totalRecord: buildingStormwaterRecordState.comments,
      component: (
        <Comments
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Contacts",
      totalRecord: buildingStormwaterRecordState.contacts,
      component: (
        <Contacts
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Documents",
      totalRecord: buildingStormwaterRecordState.documents,
      component: (
        <Documents
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: buildingStormwaterRecordState.fees,
      component: (
        <Fees
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Inspections",
      totalRecord: buildingStormwaterRecordState.inspections,
      component: (
        <SamplesAccordion
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
    {
      title: "Related",
      totalRecord: buildingStormwaterRecordState.related,
      component: (
        <SamplesAccordion
          id={buildingRegistersStormwaterId}
          recordType={RECORDTYPE.BUILDING}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Stormwater" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadBuildingRegistersStormwater(+id);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={formTitle}
            badge={buildingRegistersStormwater?.Status}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              ></CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Additional address"} />
                <CCNavButton title={"Contact"} />
                <CCNavButton title={"Advertising"} />
                <CCNavButton title={"Appeal"} />
                <CCNavButton title={"Attachment"} />
                <CCNavButton title={"Comment"} />
                {/* TODO: enhance later */}
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Building}
                  recordType={RECORDTYPE.TP_BuildingApplication}
                  recordId={parseInt(id)}
                />
                <CCNavButton title={"Complaint"} />
                <CCNavButton title={"Appeal document"} />
                <CCNavButton title={"Fee"} />
                <CCNavButton title={"Further information request"} />
                <CCNavButton title={"Inspection"} />
                <CCNavButton title={"IP - ad hoc"} />
                <CCNavButton title={"IP - from file"} />
                <CCNavButton title={"IP - from map"} />
                <CCNavButton title={"IP - from properties"} />
                <CCNavButton title={"Notice"} />
                <CCNavButton title={"Prosecution"} />
                <CCNavButton title={"Referral"} />
              </CCNavButton>,

              <CCNavButton title={"View"} type="sub">
                <CCNavButton title={"Documents"} />
                <CCNavButton title={"Journal"} />
                <CCNavButton title={"Project"} />
              </CCNavButton>,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <div className="cc-manage-form-body">
                {buildingRegistersStormwater && (
                  <CCGeneralPanel
                    component={<BuildingRegisterStormwaterForm />}
                  />
                )}
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
