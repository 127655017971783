import { PPRPermitCompleted } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/model";
import { PermitCompletedDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putPermitCompleted } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/permit-completed/api";
import { permitCompletedValidatorListByFlagPPR } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/permit-completed/config";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PermitCompletedButton = observer(() => {
  const [showPermitCompletedDialog, setShowPermitCompletedDialog] =
    useState<boolean>(false);
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const { ppr, pprId, setPPRWithLoading } = usePPRStore();
  const [isLoading, setIsLoading] = useState(false);
  const isVisible = useMemo(() => {
    if (
      permitCompletedValidatorListByFlagPPR.some(
        (item) => item === ppr?.Status_ENUM
      )
    ) {
      return ppr?.Flag_IsPPR;
    }
    return ppr?.Status_ENUM === Application_Status.Lapsed;
  }, [ppr]);

  const handleOnSubmit = async (data: PPRPermitCompleted) => {
    const requestBody: PPRPermitCompleted = { ...data, _option: undefined };
    if (pprId) {
      setIsLoading(true);
      const response = await putPermitCompleted(pprId, requestBody);
      setIsLoading(false);
      setShowPermitCompletedDialog(false);
      if (isSuccessIdentityPacket(response)) {
        clearErrorNotification();
        setPPRWithLoading(response.data.Application);
        pushNotification({
          type: "success",
          title: "Permit completed successfully",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: "Complete permit failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Permit complete"
        onClick={() => setShowPermitCompletedDialog(true)}
      />

      {showPermitCompletedDialog && (
        <PermitCompletedDialog
          onClose={() => {
            setShowPermitCompletedDialog(false);
          }}
          onSubmit={(data: PPRPermitCompleted) => {
            handleOnSubmit(data);
          }}
          isLoading={isLoading}
        />
      )}
    </>
  ) : null;
});
