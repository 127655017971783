import { ElementDisplayStatus } from "@app/products/crms/[id]/model";
import { FormAction, ViewActionButton } from "@app/products/crms/model";
import { customLogger } from "@common/utils/logger";
import { find, isArray, some } from "lodash";

export const checkPermissionFormAction = (
  data: ViewActionButton[] | undefined,
  formAction: FormAction | FormAction[]
) => {
  if (!data) {
    customLogger("checkPermissionFormAction").error("data is undefined");
  }

  if (isArray(formAction)) {
    return !some(formAction, (action) => {
      const item = find(data, { FormAction: action });
      return !item || item.DisplayStatus !== ElementDisplayStatus.Visible;
    });
  } else {
    const item = find(data, { FormAction: formAction });
    return !!item && item.DisplayStatus === ElementDisplayStatus.Visible;
  }
};
