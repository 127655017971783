import {
  addAssociationMeterAPI,
  loadMeterInstallation,
  removeAssociationMeterAPI,
} from "@app/products/property/meters/[id]/components/child-screens/installation/api";
import {
  AssociationMeter,
  IInstallations,
} from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class MeterInstallationStore {
  private _installation?: IInstallations = undefined;
  private _isLoading: boolean = false;
  private _isModifyInstallation: boolean = false;
  private _isLoadingAssociation: boolean = false;
  private _associationMeter: AssociationMeter[] = [];
  private _selectedAssociationMeter: AssociationMeter[] = [];
  private _isLoadingAssessmentsAssociate: boolean = false;
  private _selectedAssessmentsAssociated?: any[] = undefined;
  private _meterId?: number = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get installation() {
    return this._installation;
  }
  get associationMeter() {
    return this._associationMeter;
  }
  get isLoading() {
    return this._isLoading;
  }
  get isModifyInstallation() {
    return this._isModifyInstallation;
  }
  get isLoadingAssociation() {
    return this._isLoadingAssociation;
  }

  get isLoadingAssessmentsAssociate() {
    return this._isLoadingAssessmentsAssociate;
  }

  get selectedAssessmentsAssociated() {
    return this._selectedAssessmentsAssociated;
  }
  get selectedAssociationMeter() {
    return this._selectedAssociationMeter;
  }

  get meterId() {
    return this._meterId;
  }

  setMeterId = (meterId: number) => {
    runInAction(() => {
      this._meterId = meterId;
    });
  };

  setInstallation = (installation?: IInstallations) => {
    runInAction(() => {
      this._installation = installation;
    });
  };
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setIsModifyInstallation = (isModifyInstallation: boolean) => {
    runInAction(() => {
      this._isModifyInstallation = isModifyInstallation;
    });
  };
  setIsLoadingAssociation = (isLoadingAssociation: boolean) => {
    runInAction(() => {
      this._isLoadingAssociation = isLoadingAssociation;
    });
  };
  setSelectedAssociationMeter = (
    selectedAssociationMeter: AssociationMeter[]
  ) => {
    runInAction(() => {
      this._selectedAssociationMeter = selectedAssociationMeter;
    });
  };
  setAssociationMeter = (associationMeter: AssociationMeter[]) => {
    runInAction(() => {
      this._associationMeter = associationMeter;
    });
  };

  setIsLoadingAssessmentsAssociate = (
    isLoadingAssessmentsAssociate: boolean
  ) => {
    runInAction(() => {
      this._isLoadingAssessmentsAssociate = isLoadingAssessmentsAssociate;
    });
  };
  setSelectedAssessmentsAssociated = (
    selectedAssessmentsAssociated?: any[]
  ) => {
    runInAction(() => {
      this._selectedAssessmentsAssociated = selectedAssessmentsAssociated;
    });
  };

  //Action
  loadInstallation = async () => {
    this.setIsLoading(true);
    const installation =
      this.isModifyInstallation && this.installation
        ? this.installation
        : await loadMeterInstallation();
    installation?.BillingDetails?.AssessmentsAssociated?.forEach(
      (item: any) => {
        item.MeterId = this.meterId;
      }
    );
    //TODO: Remove when integrate with api
    installation.AssociationMeter = this.associationMeter;

    this.setInstallation(installation);
    this.setAssociationMeter(installation.AssociationMeter ?? []);
    this.setIsLoading(false);
  };

  addAssociationMeter = async (meterAssociation: AssociationMeter) => {
    this.setIsLoadingAssociation(true);

    const newAssociationMeter = await addAssociationMeterAPI(
      this.associationMeter,
      meterAssociation
    );

    this.setAssociationMeter(newAssociationMeter);
    this.setIsLoadingAssociation(false);
  };

  removeAssociationMeter = async () => {
    if (!this.selectedAssociationMeter) return;
    this.setIsLoadingAssociation(true);

    const newAssociationMeter = await removeAssociationMeterAPI(
      this.associationMeter,
      this.selectedAssociationMeter
    );

    this.setAssociationMeter(newAssociationMeter);
    this.setSelectedAssociationMeter([]);
    this.setIsLoadingAssociation(false);
  };

  editMeterAssociation = async (data: any) => {
    this.setIsLoadingAssessmentsAssociate(true);
    // await
  };

  disassociateMeter = async () => {
    this.setIsLoadingAssessmentsAssociate(true);
    await loadMeterInstallation().then((data) => {
      this.setInstallation(data);
    });
    this.setIsLoadingAssessmentsAssociate(false);
  };
}
const meterInstallationStoreContext = createContext(
  new MeterInstallationStore()
);
export const useMeterInstallationStore = () => {
  return useContext(meterInstallationStoreContext);
};
