import { ICCRoute } from "@common/constants/ICCRoute";

export const priorityOfAccessRoute: ICCRoute = {
  path: "priority-of-access",
  name: "Priority Of Access",
  children: [
    {
      path: "questions",
      name: "Questions",
      component: require("./questions/_index").default,
    },
    {
      path: "groups",
      name: "Groups",
      component: require("./groups/_index").default,
    },
  ],
};
