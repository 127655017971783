import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAssessmentsToBeSplit } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/assessment-to-be-split/config";
import { DTO_Assessment } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/model";
import { ViewConfiguration } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const AssessmentToBeSplitFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const assessmentGridData = getFieldValue("Assessment")
      ? [getFieldValue("Assessment")]
      : [];

    const [processedColumns, setProcessedColumns] = useState<IColumnFields[]>(
      colAssessmentsToBeSplit
    );

    /**
     * Load view configuration
     */
    const loadViewConfig = async () => {
      setIsLoadingStep(true);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.SplitAssessment_Assessment,
        colAssessmentsToBeSplit
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        setProcessedColumns(response);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadViewConfig();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadViewConfig();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    return (
      <div className="cc-field">
        <CCGrid
          className="cc-slpit-assessment-assessment-to-be-split"
          data={assessmentGridData}
          columnFields={processedColumns}
          primaryField={nameOfAssessment("Assessment_Number")}
        />
      </div>
    );
  }
);
