import { colAssociationsAddress } from "@app/products/property/components/associations/components/address/config";
import { DTO_AssociatedItem_Address } from "@app/products/property/components/associations/components/address/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsAddressProps {
  associationsAddressInfo: DTO_AssociatedItem_Address[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Address>();

export const AssociationsAddress = ({
  associationsAddressInfo,
}: IAssociationsAddressProps) => {
  return (
    <CCGrid
      className="cc-address-grid"
      data={associationsAddressInfo || []}
      columnFields={colAssociationsAddress}
      primaryField={nameOf("Id")}
    />
  );
};
