import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const AnimalsContactsTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { animalInfo } = useAnimalStore();
  const { id } = useParams<{ id: string }>();
  const animalsId: number = id ? parseInt(id) : lastSelectedId;
  return (
    <ContactsTab
      id={animalsId}
      recordType={RECORDTYPE.Animals_Registration}
      triggerReload={animalInfo}
    />
  );
});
