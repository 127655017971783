import { CoreAPIService } from "@/common/apis/coreAPIService";
import {
  PPREnquiryIdentityPacket,
  Register,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const putLodgeEnquiries = async (
  id: number,
  dateLodged: Date
): Promise<APIResponse<PPREnquiryIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/${id}/lodge`,
      JSON.stringify(dateLodged)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putCreateAndLodgeEnquiries = async (
  register: Register,
  parentID?: number,
  parentRecordType?: RECORDTYPE
): Promise<APIResponse<PPREnquiryIdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().put<PPREnquiryIdentityPacket>(
      `/api/tp/ppr/internal/enquiry/createandlodge`,
      register,
      {
        params: {
          parentID,
          parentRecordType,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
