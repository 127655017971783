import { IBuildingRegister } from "@app/products/building/[id]/model";

export const mockSingleBuilding: IBuildingRegister = {
  ID: 1,
  Applicant: "Daniel M Green",
  Applicant_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
  Applicant_Contact: "danielgreen@hotmail.net",
  Builder: "Wendy D Zambrana",
  Builder_Address: "4166  Stoney Lonesome Road, Salt Lake City, Utah",
  Builder_Contact: "dalab@sontung-mtp.com",
  PreferredContact: "James K Baker",
  PreferredContact_PostalAddress:
    "3288  Dancing Dove Lane, Whitestone, New York",
  PreferredContact_Contact: "jamescameron@gmail.com",
  SiteAddress: "everylikebook.com",
  SiteAddress_Owner: "Andrew Thomas",
  SiteAddress_Details: "bookstore@everylikebook.com",
  PermitType: "Construction Equipment",
  NatureOfWork: ["Programming", "Computer"],
  UseOfBuilding: "Block A - VinHome",
  BCAClass: "Class 3",
  PrivateCertification: true,
  Surveyor: "Andrew Do",
  Surveyor_Number: "CE-130359",
  DevDescription:
    "We are looking for a Software Developer to build and implement functional programs. You will work with other Developers and Product Managers throughout the software development life cycle.",
  CostOfWorks: "5600",
  LandType: "Working Lands - Forests",
  LandOwnership: "Freehold land",
  BuildingOfficer: "Alex & John Company",
  CertRequired: "Complete all application process requirements",
  StageNo: "1262",
  PermitNo: "68553",
  CertificateNo: "884",
  ReferenceNo: "73303",
  FileNumber: 71189,
  OSFees: 80507,
  DebtorNumber: "DebtorNumber 21",
  CILRequired: true,
  InspectionsRequired: "Building Recall Information",
  TaswaterExempt: false,
  FeesPaid: "16673",
  Issued: "Issued 21",
  RefNo: "RefNo 21",
  AssessNo: "434718",
  PrivateCert: "PrivateCert 21",
  BCA: "BCA 21",
  Locality: "Locality 21",
  Owner: "Owner 21",
  Status: "In-Progress",
  Requires: "Requires 21",
  Lodged: "Lodged 21",
  AllInspComplete: "AllInspComplete 21",
  DueToCommence: "DueToCommence 21",
  Commenced: "Commenced 21",
  DueToComplete: "DueToComplete 21",
  Completed: "Completed 21",
  CertIssued: "CertIssued 21",
  FileNo: "FileNo 21",
  ValNo: "ValNo 21",
  LegalDescription: "LegalDescription 21",
  SurveyorNo: "SurveyorNo 21",
  StatDays: 582,
  Status_ENUM: 30560,
  CreatedDate: new Date("Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"),
  LodgedDate: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
  ClockStart: new Date("Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)"),
  LevyPayer: "Committee",
  LevyPayer_OwnerBuilder: "Alexander Ryan Park",
  LevyPayer_OwnerBuilderNo: "Alexander Ryan Park",
  InsuranceCompany: "Fidentinca",
};
