import { DTO_Entity_Attributes } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/model";
import { DTO_Entity_Summary } from "@app/products/property/contacts-central-names/[id]/model";
import { DTO_Entity_LOVs } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";

import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetContactGeneral =
  | [
      APIResponse<DTO_Entity_Summary>,
      APIResponse<DTO_Entity_Attributes>,
      APIResponse<DTO_Entity_LOVs>
    ]
  | APIResponse<DTO_Entity_Summary | DTO_Entity_Attributes | DTO_Entity_LOVs>;

export const getContactGeneral = async (
  contactId: number
): Promise<IGetContactGeneral> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Entity_Summary>(
        `/api/property/internal/entity/${contactId}/summary`
      ),
      CoreAPIService.getClient().get<DTO_Entity_Attributes>(
        `/api/property/int/entity/${contactId}/attributes`
      ),
      CoreAPIService.getClient().get<DTO_Entity_LOVs>(
        "/api/property/internal/workflow/entity/lovs"
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
