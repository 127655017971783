import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSCentresHBCCapacity: IColumnFields[] = [
  {
    field: CRSFieldMapping.Year,
    title: "Year",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.CentreName, title: "Centre" },
  { field: CRSFieldMapping.GroupName, title: "Group" },
  { field: CRSFieldMapping.WeekDays_Name, title: "Days" },
  { field: CRSFieldMapping.CentrePhone, title: "Centre Phone" },
  {
    field: CRSFieldMapping.PlacesAvailable,
    title: "Places Available",
  },
  { field: CRSFieldMapping.Safety, title: "Safety" },
  { field: CRSFieldMapping.Reserved, title: "Reserved" },
  { field: CRSFieldMapping.Offered, title: "Offered" },
  { field: CRSFieldMapping.PlacesFilled, title: "Places Filled" },
  { field: CRSFieldMapping.PlacesVacant, title: "Places Vacant" },
];
