import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

interface INewPPREnquiryButtonProps {
  title?: string;
}

export const NewPPREnquiryButton = ({
  title = "New",
}: INewPPREnquiryButtonProps) => {
  return (
    <CCNavButton
      title={title}
      linkTo={`${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/new`}
    />
  );
};
