import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ModifyGISReferenceTitleButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { titleId } = useTitleStore();
  return (
    <CCNavButton
      title="Modify GIS reference"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.ModifyGISReferenceTitle,
            data: {},
            props: {
              titleId,
            },
          },
        ]);
      }}
    />
  );
});
