import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { HearingCalendarDropdown } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/components/action-bar/calendar-dropdown/_index";
import { useHearingCalendarStore } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/store";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCCalendar } from "@components/cc-calendar/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce, useUpdateEffect } from "react-use";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";
import "./_index.scss";

export default observer(() => {
  const {
    hearingCalendar,
    loadHearingCalendar,
    isLoading,
    responseLoadError,
    loadHearingCalendarById,
    selectedSiteUser,
  } = useHearingCalendarStore();

  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />, <HearingCalendarDropdown />],
    // TODO: Implement Export Calendar button. Jisha will add task
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={TOWN_PLANNING_PSAR_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useEffectOnce(() => {
    loadHearingCalendar();
  });

  useUpdateEffect(() => {
    if (selectedSiteUser) {
      loadHearingCalendarById(selectedSiteUser.ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSiteUser]);

  return (
    <div className="cc-hearing-calendar-container">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadHearingCalendar()}
        />
      ) : (
        <CCCalendar
          detailUrl=""
          // @TODO Open PSAR manage, use when complete this Page
          // detailUrl="/town-planning/ppr/"
          data={hearingCalendar}
        />
      )}
    </div>
  );
});
