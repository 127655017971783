import { apiCoreSettingOptions } from "@app/core/components/common/utils";
import { DTO_Assessment_TabDetails } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/model";
import { SettingFolder } from "@app/products/property/system-admin/settings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IAssessmentDetailsTabById =
  | [APIResponse<DTO_Assessment_TabDetails>, APIResponse<SettingFolder>]
  | APIResponse<DTO_Assessment_TabDetails | SettingFolder>
  | undefined;
export const getAssessmentDetailsTabById = async (
  assessmentId: number
): Promise<IAssessmentDetailsTabById> => {
  try {
    return await Promise.all([
      await CoreAPIService.getClient().get<DTO_Assessment_TabDetails>(
        `/api/property/internal/assessment/${assessmentId}/tabdetails`
      ),
      CoreAPIService.getClient().get<SettingFolder>(apiCoreSettingOptions()),
    ]);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
