import { ICCRoute } from "@common/constants/ICCRoute";

export const homeBasedCareRoute: ICCRoute = {
  path: "home-based-care",
  name: "Home Based Care",
  children: [
    {
      path: "all-by-weighting",
      name: "All By Weighting",
      component: require("./all-by-weighting/_index").default,
    },
    {
      path: "all-by-status",
      name: "All By Status",
      component: require("./all-by-status/_index").default,
    },
    {
      path: "placements",
      name: "Placements",
      component: require("./placements/_index").default,
    },
    {
      path: "cancellations",
      name: "Cancellations",
      component: require("./cancellations/_index").default,
    },
  ],
};
