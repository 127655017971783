import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockStatus: IKeyValuePacket[] = [
  {
    Key: 1,
    Value: "Withdrawn By Applicant",
  },
  {
    Key: 2,
    Value: "Refused By Minister",
  },
  {
    Key: 3,
    Value: "Abandoned by Council",
  },
  {
    Key: 4,
    Value: "Minister Taken Over",
  },
];
