import {
  getMailMergeDocuments,
  getMailMergeDocumentTemplate,
} from "@app/core/communication/dialogs/components/form-elememts/template/api";
import { colMailMerge } from "@app/core/communication/dialogs/components/form-elememts/template/config";
import { IMailMergeGridData } from "@app/core/communication/dialogs/components/form-elememts/template/model";
import { COMMUNICATION_TO_STEP } from "@app/core/communication/dialogs/components/form-elememts/to/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IResponseData } from "@common/models/service";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffectOnce } from "react-use";
import "./_index.scss";
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const MAIL_MERGE_TEMPLATE_FORM_STEP = "Template";
const nameOfGridData = nameOfFactory<IMailMergeGridData>();
const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();
export const TemplateFormStep = observer((props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
});

const FormStepElement = observer(
  ({ formRenderProps, nameOf, options }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedMailMergeDocumentID = getFieldValue(
      "SelectedMailMergeDocumentID"
    );
    const [showPreview, setShowPreview] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const getMailMerge = async (id: number) => {
      setIsLoading(true);
      const response = await getMailMergeDocumentTemplate(
        id,
        options.ProductType
      );

      if (isSuccessResponse(response)) {
        onChange(nameOf("DocData"), { value: response.data });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    const newSelectionChange = (dataItem: IMailMergeGridData[]) => {
      if (dataItem.length > 0) {
        setShowPreview(true);
        // check the MailMergeDocument_ID of previous last item and the new MailMergeDocument_ID last item is different?.
        //To prevent re-render the same DocData
        if (
          dataItem[0].MailMergeDocument_ID !==
          (selectedMailMergeDocumentID ?? null)
        ) {
          getMailMerge(dataItem[0].MailMergeDocument_ID);
        }
        onChange(nameOf("SelectedMailMergeDocumentID"), {
          value: dataItem[0].MailMergeDocument_ID,
        });
      } else {
        setShowPreview(false);
        onChange(nameOf("DocData"), { value: null });
        onChange(nameOf("SelectedMailMergeDocumentID"), {
          value: undefined,
        });
      }
    };
    const setSelectedTemplate = (responseGetMailMergeTemplates: any) => {
      if (getFieldValue("SelectedTemplateMailMerge")) {
        const preSelectedMailMerge = (
          responseGetMailMergeTemplates.data.value ?? []
        ).find(
          (item: any) =>
            item?.MailMergeDocument_ID ===
            getFieldValue("SelectedTemplateMailMerge")?.MailMergeDocument_ID
        );
        if (preSelectedMailMerge) {
          onChange(nameOf("SelectedMailMergeDocument"), {
            value: [preSelectedMailMerge],
          });
          getMailMerge(preSelectedMailMerge.MailMergeDocument_ID);
        } else if (getFieldValue("SelectedTemplateMailMerge")) {
          onChange(nameOf("SelectedMailMergeDocument"), {
            value: [],
          });
          newSelectionChange([]);
          setShowPreview(false);
          onChange(nameOf("SelectedTemplateMailMerge"), {
            value: undefined,
          });
        }
      }
    };

    useEffectOnce(() => {
      getKeywords(
        KEYWORD_TYPE.Core_DocumentCategory,
        PRODUCT_TYPE_NUMBER.Core
      ).then((response: IResponseData) => {
        onChange(nameOf("CategoriesList"), { value: response.data });
      });

      const dataset_Filters = valueGetter(`${COMMUNICATION_TO_STEP}.oSetting`)
        ?.Dataset_Filters[0];
      const dataset_Filters_Number = MAILMERGEDATASET[dataset_Filters];
      if (options.ProductType && dataset_Filters_Number) {
        getMailMergeDocuments(dataset_Filters_Number, options.ProductType).then(
          (responseGetMailMergeTemplates: IResponseData) => {
            onChange(nameOf("MailMergeTemplateGridData"), {
              value: responseGetMailMergeTemplates.data.value,
            });
            setSelectedTemplate(responseGetMailMergeTemplates);
          }
        );
      }
    });

    const [numPages, setNumPages] = useState<number>(0);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
      setNumPages(numPages);
    };

    const showAllPage = () => {
      const pages = Array.from(
        { length: numPages },
        (_, index) => index + 1
      ).map((page) => <Page className="cc-pdf-pages" pageNumber={page} />);
      return pages;
    };

    return (
      <div className="cc-mail-merge-selection-container">
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Category</label>
              <Field
                name={nameOf("Categories")}
                component={CCSearchComboBox}
                className="cc-dropdown"
                loading={!getFieldValue("CategoriesList")}
                data={getFieldValue("CategoriesList") ?? []}
                textField={nameOfCoreKeyword("Name")}
                dataItemKey={nameOfCoreKeyword("Keyword_ID")}
              />
              <div className="cc-grid-container">
                <CCGrid
                  selectableMode="single"
                  onSelectionChange={newSelectionChange}
                  selectedRows={
                    selectedMailMergeDocumentID
                      ? [{ MailMergeDocument_ID: selectedMailMergeDocumentID }]
                      : []
                  }
                  columnFields={colMailMerge}
                  primaryField={nameOfGridData("MailMergeDocument_ID")}
                  data={getFieldValue("MailMergeTemplateGridData")}
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Preview</label>
              <Loading isLoading={isLoading} />
              {showPreview && !isLoading && (
                <>
                  {getFieldValue("DocData") && (
                    <Document
                      className="cc-preview"
                      file={`data:application/pdf;base64,${getFieldValue(
                        "DocData"
                      )}`}
                      renderMode="svg"
                      loading={""}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {numPages && showAllPage()}
                    </Document>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
