import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { StateRoadTabDetails } from "@common/pages/settings/lookups/state-roads/_id/components/reference-sidebar/detail/model";

export const getStateRoadTabDetailsById = async (
  id: number
): Promise<APIResponse<StateRoadTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<StateRoadTabDetails>(
      `api/core/internal/stateroad/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
