import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const kennelsRenewalsRoute: ICCRoute = {
  path: "kennels-renewals",
  name: "Kennels Renewals",
  enum: AnimalsMenu.KennelsRenewals,
  children: [
    {
      path: "requires-renewal-fees",
      name: "Requires Renewal Fees",
      enum: AnimalsMenu.KennelRenewalsReqFees,
      component: require("./requires-renewal-fees/_index").default,
    },
    {
      path: "requires-renewal-notice",
      name: "Requires Renewal Notice",
      enum: AnimalsMenu.KennelRenewalsReqNotice,
      component: require("./requires-renewal-notice/_index").default,
    },
    {
      path: "renewal-notice-sent",
      name: "Renewal Notice Sent",
      enum: AnimalsMenu.KennelRenewalsNoticeSent,
      component: require("./renewal-notice-sent/_index").default,
    },
  ],
};
