import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import Attachment from "./components/attachment/_index";
import { colInspections } from "./config";
import { mockInspections } from "./mock";

export const InspectionGrid = () => {
  return (
    <>
      <label className="cc-control-item cc-label">Inspections</label>
      <CCGrid
        data={mockInspections}
        columnFields={colInspections}
        primaryField="InspectionId"
        detail={Attachment}
      />
    </>
  );
};
