import { history } from "@/AppRoutes";
import {
  getBuildingRegistersPOPEById,
  postBuildingRegistersPOPE,
  putBuildingRegistersPOPE,
} from "@app/products/building/registers/pope/[id]/api";
import { POPE_MANAGE_ROUTE } from "@app/products/building/registers/pope/[id]/constant";
import { IBuildingRegistersPOPEFormData } from "@app/products/building/registers/pope/[id]/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class BuildingRegistersPOPEStore {
  private _buildingRegistersPOPE?: IBuildingRegistersPOPEFormData = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get buildingRegistersPOPE() {
    return toJS(this._buildingRegistersPOPE);
  }
  setBuildingRegistersPOPE = (
    buildingRegistersPOPE?: IBuildingRegistersPOPEFormData
  ) => {
    runInAction(() => {
      this._buildingRegistersPOPE = buildingRegistersPOPE;
    });
  };

  get buildingRegistersPOPEId() {
    return toJS(this.buildingRegistersPOPE?.ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._buildingRegistersPOPE = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadBuildingRegistersPOPE = async (buildingRegistersPOPEId: number) => {
    this.setIsLoading(true);
    const response = await getBuildingRegistersPOPEById(
      buildingRegistersPOPEId
    );
    let errorResponse = undefined;
    let newBuildingRegistersPOPE = undefined;
    if (isSuccessResponse(response)) {
      newBuildingRegistersPOPE = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setBuildingRegistersPOPE(newBuildingRegistersPOPE);
    this.setIsLoading(false);
  };

  createBuildingRegistersPOPE = async (buildingRegistersPOPE: IBuilding) => {
    this.setIsLoading(true);
    await postBuildingRegistersPOPE(buildingRegistersPOPE);
    appNotificationStore.pushNotification({
      title: "The building registers POPE was created successfully.",
      type: "success",
      onClose: () => {
        history.replace(
          `${POPE_MANAGE_ROUTE}/${buildingRegistersPOPE?.ID ?? 1}`
        );
      },
    });
    this.setIsLoading(false);
  };

  updateBuildingRegistersPOPE = async (buildingRegistersPOPE: IBuilding) => {
    this.setIsLoading(true);
    await putBuildingRegistersPOPE(buildingRegistersPOPE);
    appNotificationStore.pushNotification({
      title: "The building registers POPE was updated successfully.",
      type: "success",
    });
    this.setIsLoading(false);
  };
}

const buildingRegistersPOPEStoreContext = createContext(
  new BuildingRegistersPOPEStore()
);
export const useBuildingRegistersPOPEStore = () => {
  return useContext(buildingRegistersPOPEStoreContext);
};
