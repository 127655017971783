import { odataCoreGetInvoiceToFinance } from "@app/core/components/common/utils";
import { InvoiceDetailsTab } from "@app/core/invoice/[id]/components/reference-sidebar/details/_index";
import { InvoiceHistoryTab } from "@app/core/invoice/[id]/components/reference-sidebar/history/_index";
import { INVOICE_MANAGE_ROUTE } from "@app/core/invoice/[id]/constant";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import {
  InvoiceFinanceViewType,
  nameOfInvoiceToFinance,
} from "@app/products/local-laws/finance/model";
import { financeRoute } from "@app/products/local-laws/finance/route";
import { llFinanceBookmark } from "@app/products/local-laws/finance/util";
import { localLawsRoute } from "@app/products/local-laws/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCommonCoreStore } from "@common/stores/core/store";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  colLocalLawsFinanceInvoicesSentToFinance,
  generateInvoiceSentToFinanceColumnsBySetting,
} from "./config";

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { listViewDisplayURL, recordDisplayURL, listViewDisplayTitle } =
    llFinanceBookmark("Invoices Not Sent to Finance");

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={financeRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={INVOICE_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Invoice}
        detail={listViewDisplayURL}
        displayName={recordDisplayURL}
        listViewDetail={listViewDisplayTitle}
        listViewDisplayName={listViewDisplayURL}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InvoiceDetailsTab /> },
      {
        title: "History",
        component: <InvoiceHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      dataUrl={odataCoreGetInvoiceToFinance(
        PRODUCT_TYPE_NUMBER.LLPermits,
        InvoiceFinanceViewType.InvoiceSentToFinance
      )}
      columnFields={generateInvoiceSentToFinanceColumnsBySetting({
        settings,
        defaultConfig: colLocalLawsFinanceInvoicesSentToFinance,
      })}
      primaryField={nameOfInvoiceToFinance("ID")}
    />
  );
});
