import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_Property_EnableExternalPropertyAlerts",
    "CorporateSettings_ExternalPropertyConnectionString",
    "CorporateSettings_ExternalPropertyReturnQuery",
  ]);
  const returnExternalPropertyAlert = valueGetter(
    ECorporateSettingsField.CorporateSettings_Property_EnableExternalPropertyAlerts.toString()
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={data.CorporateSettings_Property_EnableExternalPropertyAlerts}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      </div>
      {returnExternalPropertyAlert && (
        <>
          {" "}
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={data.CorporateSettings_ExternalPropertyConnectionString}
              isEditing={isEditing}
            />
          </div>
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_ExternalPropertyReturnQuery}
              isEditing={isEditing}
              rows={10}
            />
          </div>
        </>
      )}
    </section>
  );
});
