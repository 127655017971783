import { history } from "@/AppRoutes";
import {
  getFurtherInfo,
  getParentSummary,
  putFurtherInfo,
} from "@app/core/further-info/[id]/api";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import {
  Ancestor,
  FURTHERINFOSOURCE,
  FurtherInfoSubmitActions,
  FurtherInformationRequest,
  IParentFISection,
  fiSubmitInWorkflowActions,
} from "@app/core/further-info/[id]/model";
import { approveLetterButtonStore } from "@app/core/further-info/components/buttons/more/approve-letter/store";
import { sendLetterToRAandApplicantButtonStore } from "@app/core/further-info/components/buttons/more/send-letter-to-RA-Applicant/store";
import { additionalInfoRequiredButtonStore } from "@app/core/further-info/components/buttons/work-flow/additional-info-required/store";
import { cancelButtonStore } from "@app/core/further-info/components/buttons/work-flow/cancel/store";
import { infoAdequateButtonStore } from "@app/core/further-info/components/buttons/work-flow/info-adequate/store";
import { infoInadequateButtonStore } from "@app/core/further-info/components/buttons/work-flow/info-inadequate/store";
import { infoNotReceivedStore } from "@app/core/further-info/components/buttons/work-flow/info-not-received/store";
import { infoReceivedStore } from "@app/core/further-info/components/buttons/work-flow/info-received/store";
import { reinstateButtonStore } from "@app/core/further-info/components/buttons/work-flow/reinstate/store";
import { rejectLetterButtonStore } from "@app/core/further-info/components/buttons/work-flow/reject-letter/store";
import { sendForApprovalButtonStore } from "@app/core/further-info/components/buttons/work-flow/send-for-approval/store";
import { requestFIButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/request-fi/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { BubbleUpType } from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE, mapRecordType } from "@common/constants/recordtype";
import { DBRowState } from "@common/models/baseClassStandard";
import { IdentityPacket } from "@common/models/identityPacket";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class FurtherInfoStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _furtherInfo?: FurtherInformationRequest = undefined;
  private _originalFI?: FurtherInformationRequest = undefined;
  private _furtherInfoId?: number = undefined;
  private _parent?: IParentFISection = undefined;
  private _ancestor?: Ancestor = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentFISection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get furtherInfo() {
    return toJS(this._furtherInfo);
  }
  setFurtherInfo = (furtherInfo?: FurtherInformationRequest) => {
    runInAction(() => {
      this._furtherInfo = furtherInfo;
    });
  };

  get originalFI() {
    return toJS(this._originalFI);
  }
  setOriginalFI = (originalFI?: FurtherInformationRequest) => {
    runInAction(() => {
      this._originalFI = originalFI;
    });
  };

  get furtherInfoId() {
    return toJS(this._furtherInfoId);
  }
  setFurtherInfoId = (furtherInfoId?: number) => {
    runInAction(() => {
      this._furtherInfoId = furtherInfoId;
    });
  };

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._furtherInfo = undefined;
      this._originalFI = undefined;
      this._furtherInfoId = undefined;
      this._parent = undefined;
      this._ancestor = undefined;
    });
  };

  //Return isReloadSuccess
  reLoadFurtherInfo = async (): Promise<boolean> => {
    if (this.furtherInfoId)
      return await this.loadFurtherInfo(this.furtherInfoId);
    return false;
  };

  loadParent = async (parent: IParentFISection, isNew: boolean) => {
    this.setParent(parent);
    const response = await getParentSummary(parent.id, parent.recordType);
    customLogger("Core Further info, getParentSummary").info(response?.data);
    if (isSuccessResponse(response) && response.data) {
      if (isNew) {
        let fiSource = null;
        switch (parent.recordType) {
          case RECORDTYPE.TP_Application:
            fiSource = FURTHERINFOSOURCE.Planning;
            break;
          case RECORDTYPE.TP_BuildingApplication:
            fiSource = FURTHERINFOSOURCE.Building;
            break;
          case RECORDTYPE.TP_LMRegister:
            fiSource = FURTHERINFOSOURCE.LM_Register;
            break;
          default:
            fiSource = null;
            break;
        }
        this.setFurtherInfo({
          ...parent.data,
          FurtherInformationRequest_ID: 0,
          FI_Source: fiSource,
        } as FurtherInformationRequest);
      }
      this.setAncestor(response.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load application data failed",
      });
      return false;
    }
  };

  loadFurtherInfo = async (
    furtherInfoId?: number,
    isNew?: boolean,
    parent?: IParentFISection
  ): Promise<boolean> => {
    if (isNew && parent) {
      this.setIsLoading(true);
      const response = await this.loadParent(parent, true);
      customLogger("Core Further info, loadFurtherInfo, getParentSummary").info(
        response
      );
      this.setIsLoading(false);
      return response;
    }
    if (furtherInfoId === undefined) return false;
    this.setIsLoading(true);
    let newFurtherInfo = undefined;
    let errorResponse = undefined;
    const response = await getFurtherInfo(furtherInfoId);
    if (isSuccessResponse(response)) {
      newFurtherInfo = response.data;
      //Check isDeleted
      if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
        this.setIsInactive(true);
        appNotificationStore.clearNotifications();
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Important note",
          type: "info",
          description:
            "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
        });
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    const sourceIdentifier = newFurtherInfo?._BubbleUps.find(
      (bubbleUp) => bubbleUp.BubbleUpType_ENUM === BubbleUpType.Parent
    )?.SourceIdentifier;

    if (sourceIdentifier) {
      const newParent = {
        id: sourceIdentifier._RecordSource_ID,
        recordType:
          mapRecordType[sourceIdentifier._RecordSourceType_ENUM] ??
          RECORDTYPE.TP_PPRApplication,
      };
      await this.loadParent(newParent, false);
    }

    //Load OriginalFI
    if (newFurtherInfo && newFurtherInfo.OrignalFI_ID) {
      const originalFIResponse = await getFurtherInfo(
        newFurtherInfo.OrignalFI_ID
      );
      customLogger("Core Further info, Load OriginalFI").info(
        originalFIResponse?.data
      );
      if (isSuccessResponse(originalFIResponse)) {
        this.setOriginalFI(originalFIResponse.data);
      } else {
        errorResponse = {
          status: originalFIResponse.status,
          error: originalFIResponse.error,
        };
      }
    }

    this.setFurtherInfo(newFurtherInfo);
    this.setFurtherInfoId(newFurtherInfo?.FurtherInformationRequest_ID);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);

    if (parent?.notification) {
      parent?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
    }
    return isUndefined(errorResponse);
  };

  saveFurtherInfo = async (
    furtherInfo: FurtherInformationRequest,
    action: FurtherInfoSubmitActions
  ) => {
    if (fiSubmitInWorkflowActions.includes(action)) {
      //Run actions submit in workflow
      this.setIsLoading(true);
      await this.runSubmitInWorkflowActions(furtherInfo, action);
      return;
    }

    //Save fiRequest and do another actions
    this.setIsLoading(true);
    if (!this.parent) return;
    const { id: parentId, recordType } = this.parent;
    const response = await putFurtherInfo(parentId, recordType, furtherInfo);
    customLogger("Core Further info, putFurtherInfo").info(response?.data);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        await this.reLoadFurtherInfo();
        await this.runActions(action, response.data);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Further information request could not be updated",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Further information request could not be updated",
        type: "error",
        description: response.data?.Errors,
      });
    }
    this.setIsLoading(false);
  };

  runSubmitInWorkflowActions = async (
    furtherInfo: FurtherInformationRequest,
    action: FurtherInfoSubmitActions
  ) => {
    switch (action) {
      case FurtherInfoSubmitActions.AdditionalInfoRequired:
        if (this.parent)
          additionalInfoRequiredButtonStore.additionalInfoRequired(
            this.parent,
            furtherInfo
          );
        break;
      default:
        break;
    }
  };

  runActions = async (
    action: FurtherInfoSubmitActions,
    data: IdentityPacket
  ) => {
    let parentActionRes = undefined;
    if (this.parent && this.parent.parentAction) {
      parentActionRes = false;
      switch (this.parent.parentAction) {
        case PPRSubmitActions.RequestFI:
          parentActionRes = await requestFIButtonStore.requestFI(
            this.parent.id,
            data
          );
          break;
        default:
          break;
      }
      if (parentActionRes) {
        this.setParent({ ...this.parent, parentAction: undefined });
      }
    }

    switch (action) {
      case FurtherInfoSubmitActions.Save:
        appNotificationStore.pushNotification({
          title: "Further information request updated successfully",
          type: "success",
        });
        break;
      case FurtherInfoSubmitActions.New:
        if (parentActionRes !== true) {
          history.replace(`${FURTHER_INFO_ROUTE}/${data.ID}`, {
            parent: {
              notification: [
                {
                  title: "Further information request created successfully",
                  type: "success",
                },
              ],
            } as IParentFISection,
          });
        }

        break;
      case FurtherInfoSubmitActions.InfoReceived:
        infoReceivedStore.setIsShowDialog(true);
        break;
      case FurtherInfoSubmitActions.InfoNotReceived:
        infoNotReceivedStore.setIsShowDialog(true);
        break;
      case FurtherInfoSubmitActions.Cancel:
        if (this.furtherInfoId)
          cancelButtonStore.cancelFIRequest(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.InfoAdequate:
        if (this.furtherInfoId)
          infoAdequateButtonStore.infoAdequate(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.InfoInadequate:
        if (this.furtherInfoId)
          infoInadequateButtonStore.infoInadequate(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.RejectLetter:
        if (this.furtherInfoId)
          rejectLetterButtonStore.rejectLetterFIRequest(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.Reinstate:
        if (this.furtherInfoId)
          reinstateButtonStore.reinstateFIRequest(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.SendForApproval:
        if (this.furtherInfoId)
          sendForApprovalButtonStore.validation(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.ApproveLetter:
        if (this.furtherInfoId)
          approveLetterButtonStore.approveLetterFIRequest(this.furtherInfoId);
        break;
      case FurtherInfoSubmitActions.SendLetterToRAandApplicant:
        if (this.furtherInfoId) {
          sendLetterToRAandApplicantButtonStore.validateFIRequest(
            this.furtherInfoId
          );
        }
        break;
      default:
        break;
    }
  };
}

export const furtherInfoStore = new FurtherInfoStore();
const FurtherInfoStoreContext = createContext(furtherInfoStore);
export const useFurtherInfoStore = () => {
  return useContext(FurtherInfoStoreContext);
};
