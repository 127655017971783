import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const documentsYetToBeIssuedRoute: ICCRoute = {
  path: "documents-yet-to-be-issued",
  name: "Documents yet to be issued",
  enum: eMenuAction.Compliance_ActionsAndLetters_DocumentsToBeIssued,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Compliance_ActionsAndLetters_DocumentsToBeIssued_All,
      component: require("./all/_index").default,
    },
  ],
};
