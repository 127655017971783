import { AusState } from "@common/constants/enumAusState";

export const state = [
  AusState.AUS_TERRITORY_ACT,
  AusState.AUS_STATE_NSW,
  AusState.AUS_TERRITORY_NT,
  AusState.AUS_STATE_QLD,
  AusState.AUS_STATE_SA,
  AusState.AUS_STATE_TAS,
  AusState.AUS_STATE_VIC,
  AusState.AUS_STATE_WA,
];
