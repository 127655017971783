import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import React from "react";
interface ILocalLawsActionBarNavDropdownProps {
  //TODO: Remove unused props
  category: string;
}

export const LocalLawsActionBarNavDropdown = ({
  category,
}: ILocalLawsActionBarNavDropdownProps) => {
  return <CCActionBarNavDropdownNew />;
};
