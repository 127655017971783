import { PropertyDetailDialog } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/_index";
import { Address } from "@app/products/waste-water/[id]/model";
import { Button } from "@progress/kendo-react-buttons";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React, { useState } from "react";
interface WastewaterAddressPickerChangeEvent {
  value?: Address;
}
interface IWastewaterAddressPickerProps {
  value?: Address;
  name?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  onChange?: (event: WastewaterAddressPickerChangeEvent) => void;
}

export const WastewaterAddressPicker = (
  props: IWastewaterAddressPickerProps &
    Omit<FieldRenderProps, "value" | "onChange">
) => {
  const {
    placeholder,
    disabled,
    className,
    visited,
    validationMessage,
    onChange,
    value,
    valid,
  } = props;

  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <>
      <div
        className={`cc-input-picker ${
          !valid ? "cc-input-picker-invalid" : ""
        } ${className ?? ""}`}
      >
        <div className="cc-input-picker-form-row">
          <Input
            className="cc-input-picker-field"
            placeholder={placeholder}
            value={value?.Formatted_SingleLine}
            readOnly
            valid={valid}
          />
          <div>
            <Button
              disabled={disabled}
              className="cc-input-picker-button"
              iconClass="fa fa-ellipsis-h"
              onClick={(event) => {
                event.preventDefault();
                setShowDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      {showDialog && (
        <PropertyDetailDialog
          initialValues={value}
          onClose={() => setShowDialog(false)}
          onSubmit={(value: Address) => {
            setShowDialog(false);
            if (onChange) onChange({ value });
          }}
        />
      )}
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
