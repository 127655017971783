import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

export const getModuleNameByID = (moduleId: number) => {
  switch (moduleId) {
    case PRODUCT_TYPE_NUMBER.HealthManager:
      return "HM";
    case PRODUCT_TYPE_NUMBER.WasteWater:
      return "WW";
    case PRODUCT_TYPE_NUMBER.CustomerService:
      return "CRMS";
    case PRODUCT_TYPE_NUMBER.DevApps:
      return "DevApp";
    case PRODUCT_TYPE_NUMBER.Building:
      return "Building";
    case PRODUCT_TYPE_NUMBER.TownPlanning:
      return "TP";
    case PRODUCT_TYPE_NUMBER.LLPermits:
      return "LLP";
    case PRODUCT_TYPE_NUMBER.Animals:
      return "Animals";
    default:
      return "";
  }
};

const timeForOneDay = 1000 * 60 * 60 * 24 * 1;
export const setCookie = (name: string, value: object, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * timeForOneDay);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + JSON.stringify(value) + ";" + expires;
};
