import { colAssociationsArrangement } from "@app/products/property/components/associations/components/arrangement/config";
import { DTO_AssociatedItem_Arrangement } from "@app/products/property/components/associations/components/arrangement/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsArrangementProps {
  associationsArrangementInfo: DTO_AssociatedItem_Arrangement[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Arrangement>();

export const AssociationsArrangement = ({
  associationsArrangementInfo,
}: IAssociationsArrangementProps) => {
  return (
    <CCGrid
      className="cc-arrangement-grid"
      data={associationsArrangementInfo || []}
      columnFields={colAssociationsArrangement}
      primaryField={nameOf("Id")}
    />
  );
};
