import { AnimalsApiUrl, EGenderAnimal } from "@app/products/animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { CRMSEventPicker } from "@app/products/animals/pound-register/[id]/model";
import { getEventsByRefNoOdataURL } from "@app/products/crms/util";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";

const nameOfEventPicker = nameOfFactory<CRMSEventPicker>();
export const crmsEventPickerOptions: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Event",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfEventPicker("RefNo"),
    colShowComboboxSearch: [
      { field: nameOfEventPicker("RefNo"), title: "Reference Number" },
    ],
  },
  grid: {
    columnFields: [
      { field: nameOfEventPicker("RefNo"), title: "Reference Number" },
      {
        field: nameOfEventPicker("ActionOfficer"),
        title: "Action Officer",
      },
      { field: nameOfEventPicker("Description"), title: "Description" },
      {
        field: nameOfEventPicker("Record"),
        title: "Record",
        format: DATE_FORMAT.DATE,
      },
      { field: nameOfEventPicker("Category"), title: "Category" },
      { field: nameOfEventPicker("Location"), title: "Location" },
      { field: nameOfEventPicker("Contact"), title: "Contact" },
      {
        field: nameOfEventPicker("Target"),
        title: "Target",
        format: DATE_FORMAT.DATE,
      },
    ],
    primaryField: nameOfEventPicker("ID"),
    dataUrl: getEventsByRefNoOdataURL(false, false),
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
  },
};

const nameOfAnimalsPicker = nameOfFactory<AnimalsRegister>();
export const animalsPickerOptions: IOptionInputPicker = {
  dialog: {
    titleHeader: "Animals",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfAnimalsPicker("RegistrationNumber"),
    colShowComboboxSearch: [
      {
        field: nameOfAnimalsPicker("RegistrationNumber"),
        title: "Registration number",
      },
    ],
  },
  grid: {
    columnFields: [
      {
        field: nameOfAnimalsPicker("RegistrationNumber"),
        locked: true,
        title: "Registration Number",
      },
      {
        field: nameOfAnimalsPicker("TagNo"),
        title: "Tag Number",
      },
      {
        field: nameOfAnimalsPicker("MicrochipNo"),
        title: "Chip Number",
      },
      { field: nameOfAnimalsPicker("AnimalOwner"), title: "Animal Owner" },
      {
        field: nameOfAnimalsPicker("SiteAddress"),
        title: "Registered Address",
      },
      { field: nameOfAnimalsPicker("Locality"), title: "Locality" },
      { field: nameOfAnimalsPicker("Type"), title: "Animal Type" },
      { field: nameOfAnimalsPicker("Breed"), title: "Breed" },
      { field: nameOfAnimalsPicker("AnimalName"), title: "Name" },
      { field: nameOfAnimalsPicker("Colour"), title: "Colour" },
      {
        field: nameOfAnimalsPicker("Gender"),
        title: "Gender",
        filterByEnum: EGenderAnimal,
      },
    ],
    primaryField: nameOfEventPicker("ID"),
    dataUrl: AnimalsApiUrl.GET_VIEW_ANIMALS_REGISTER_ACTIVE,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
  },
};
