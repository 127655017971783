import { getAutomaticAction } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/api";
import { colAutomaticAction } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/config";
import { ILightWeightActionType } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<ILightWeightActionType>();
interface IAutomaticActionDialogProps {
  onClose: () => void;
  onSubmit: (value: any) => void;
  selectableMode: "single" | "multiple";
  initialData: ILightWeightActionType[];
}

export const AutomaticActionDialog = ({
  onClose,
  onSubmit,
  selectableMode,
  initialData,
}: IAutomaticActionDialogProps) => {
  const selectedData = useRef<ILightWeightActionType[]>();
  const [gridData, setGridData] = useState<ILightWeightActionType[]>([]);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const loadAutomaticAction = async () => {
    setIsLoading(true);
    const response = await getAutomaticAction();
    if (isSuccessResponse(response)) {
      if (response.data) setGridData(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };
  const selectedActionType = useMemo(() => {
    if (gridData && initialData) {
      const defaultSelectedData = gridData.filter(
        (action: ILightWeightActionType) =>
          initialData.some(
            (initialAction: ILightWeightActionType) =>
              initialAction.ID === action.ID
          )
      );
      selectedData.current = defaultSelectedData;
      return defaultSelectedData;
    }
  }, [gridData, initialData]);

  useEffectOnce(() => {
    loadAutomaticAction();
  });

  return (
    <>
      <Form
        initialValues={{
          InheritFee: false,
        }}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <FormElement>
              <CCDialog
                height="auto"
                maxWidth="30%"
                titleHeader={"Add Automatic Action"}
                onClose={onClose}
                bodyElement={
                  <div className="cc-form">
                    <div className="cc-field-group">
                      {responseLoadError ? (
                        <CCLoadFailed
                          responseError={responseLoadError}
                          onReload={() => loadAutomaticAction()}
                        />
                      ) : (
                        <CCGrid
                          isLoading={isLoading}
                          columnFields={colAutomaticAction}
                          selectedRows={selectedActionType}
                          data={gridData || []}
                          primaryField={nameOf("ID")}
                          selectableMode={selectableMode}
                          onSelectionChange={(
                            value: ILightWeightActionType[]
                          ) => (selectedData.current = value)}
                        />
                      )}
                    </div>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>

                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={() => onSubmit(selectedData.current)}
                    >
                      Select
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    </>
  );
};
