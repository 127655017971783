import { listConfigNumberTypeAssessment } from "@app/products/property/components/dialogs/add-assessment-lookup/config";
import { DTO_AssessmentLookupObj } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { isNil } from "lodash";

export const isNumberModeAssessmentSearch = (value?: number): boolean => {
  if (isNil(value)) return false;
  return listConfigNumberTypeAssessment.includes(value);
};

export const generateAssessmentLookupOdataUrl = (
  searchData: DTO_AssessmentLookupObj
) => {
  const { LookupKey, LookupValue, Statuses } = searchData;
  return `odata/property/internal/assessmentlookupregister?lookupKey=${LookupKey}&lookupValue=${LookupValue}&status=${
    Statuses.length > 0 ? Statuses.toString() : "0,8" // default task 10644
  }&$count=true&`;
};
