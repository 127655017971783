import {
  DATE_PICKER_HEIGHT,
  DATE_PICKER_MARGIN,
  KEY_DOWN_ENTER,
} from "@components/cc-date-picker/config";
import { CCPopupControl } from "@components/cc-popup-control/_index";
import { DatePicker, DatePickerProps } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import { isDate, isNil, isString } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "./_index.scss";
interface ICCDatePickerProps {
  disabledDateInput?: boolean;
  validationMessage?: string | null;
  visited?: boolean;
}

export const CCDatePicker = (props: ICCDatePickerProps & DatePickerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { validationMessage, visited, value, disabledDateInput, ...others } =
    props;

  const dateValue = useMemo(() => {
    if (value && isString(value)) {
      return new Date(value);
    }
    if (isDate(value)) return value;
    return undefined;
  }, [value]);

  const renderPopup = useCallback(
    (props: any) => {
      return (
        <CCPopupControl
          popup={{ ...props, className: "cc-date-picker-popup" }}
          container={ref.current}
          controlHeight={DATE_PICKER_HEIGHT}
          controlMargin={DATE_PICKER_MARGIN}
        />
      );
    },
    [ref]
  );

  useEffect(() => {
    if (ref && ref.current) {
      const element = ref.current;
      const handleKeyDownEvent = (event: any) => {
        if (disabledDateInput) {
          event.stopPropagation();
          event.preventDefault();
        } else {
          if (event.key === KEY_DOWN_ENTER) {
            event.target?.blur();
          }
        }
      };
      element?.addEventListener("keydown", handleKeyDownEvent);
      return () => {
        element?.removeEventListener("keydown", handleKeyDownEvent);
      };
    }
  }, [disabledDateInput]);

  return (
    <div ref={ref} className="cc-date-picker">
      <DatePicker
        {...others}
        value={isNil(dateValue) ? null : dateValue}
        popup={renderPopup}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
