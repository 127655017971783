import { DTO_AssociatedItem_PICs } from "@app/products/property/components/associations/components/pics/model";
import { PROPERTY_PICS_ROUTE } from "@app/products/property/pic/list/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_PICs>();

export const colAssociationsPICS: IColumnFields[] = [
  {
    field: nameOf("PIC_Number"),
    title: "PIC Number",
    linkTo: (dataItem: DTO_AssociatedItem_PICs) =>
      `${PROPERTY_PICS_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Trading_Name"),
    title: "Trading Name",
  },
  {
    field: nameOf("Manager"),
    title: "PIC Manager",
  },
  {
    field: nameOf("PIC_Type_Name"),
    title: "PIC Type",
  },
  {
    field: nameOf("Quarantine_Status"),
    title: "Quarantine Status",
  },
  {
    field: nameOf("Land_Use"),
    title: "Land Use",
  },
  {
    field: nameOf("PIC_Status_Name"),
    title: "PIC Status",
  },
  {
    field: nameOf("Agent_License"),
    title: "Agent License",
  },
  {
    field: nameOf("Primary_Holding_Number"),
    title: "Primary Holding Number",
  },
  {
    field: nameOf("Primary_Holding_Address"),
    title: "Primary Holding Address",
  },
  {
    field: nameOf("Region"),
    title: "District",
  },
  {
    field: nameOf("RLP_Board"),
    title: "RLP Board",
  },
  {
    field: nameOf("Id"),
    title: "PIC ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
