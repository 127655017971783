import { PSARFormElement } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { Application } from "@app/products/town-planning/ppr/[id]/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const PSARInfoForm = observer(() => {
  const { ppr, savePSAR, setOnSubmit } = usePSARStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isValid, isModified } = event;
    const action = event.event?.currentTarget.name as PSARSubmitActions;
    if (!(action in PSARSubmitActions)) return;

    if (!isValid) return;
    if (
      !isModified &&
      [PSARSubmitActions.Save, PSARSubmitActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const pprInfo = values as Application;
    savePSAR(pprInfo, action);
  };
  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, ppr]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={ppr}
        key={JSON.stringify(ppr)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<PSARFormElement formRenderProps={formRenderProps} />}
            />
          );
        }}
      />
    </div>
  );
});
