import { Svc_HomepagePageUser } from "@common/pages/settings/homepage/model";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_HomepagePageUser>();
export const colManageHomepageShortcuts: IColumnFields[] = [
  {
    title: "Site Users",
    field: nameOf("DisplayName"),
    isGrow: true,
    linkTo: (dataItem: Svc_HomepagePageUser) => {
      return `${SITE_USER_ROUTE}/${dataItem.ID}`;
    },
  },
];
