import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import { SchemeContactsTab } from "@app/products/property/schemes/[id]/components/reference-sidebar/contact/_index";
import { SchemeDetailTab } from "@app/products/property/schemes/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import { NewSchemeButton } from "@app/products/property/schemes/components/action-bar/buttons/new-scheme/_index";
import { RaiseInterestButton } from "@app/products/property/schemes/components/action-bar/buttons/raise-interest/_index";
import { colSchemeList } from "@app/products/property/schemes/list/all/config";
import { mockSchemeList } from "@app/products/property/schemes/list/all/mock";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/schemes/list/util";
import { schemesRoute } from "@app/products/property/schemes/route";
import { getBookmarkListViewDisplayName } from "@app/products/property/schemes/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkListViewDetail } from "./util";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={schemesRoute.path} />,
    ],
    centerComponents: [
      <NewSchemeButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Scheme}
        recordType={RECORDTYPE.CommunityProperty_Scheme}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Scheme}
        keyField={"SchemeId"}
      />,
      <CCNavButton title={"Tools"} type="more">
        <RaiseInterestButton />
        <HousekeepingButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_SCHEMES_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SchemeDetailTab /> },
      { title: "Contacts", component: <SchemeContactsTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Scheme}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colSchemeList}
      data={mockSchemeList.All || []}
      primaryField="SchemeId"
    />
  );
};
