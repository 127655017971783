import { loadMemoData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/memo/api";
import { IMemoDialogData } from "@app/products/property/meters/[id]/components/action-bar/dialogs/memo/model";
import { IMeterMemo } from "@app/products/property/meters/[id]/components/child-screens/memos/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";

export const nameOf = nameOfFactory<IMemoDialogData>();
interface IAddMemoDialogProps {
  existingMemo?: IMeterMemo;
  onClose: () => void;
  onSubmit: (
    values: {
      [name: string]: any;
    },
    event?: React.SyntheticEvent<any>
  ) => void;
}
export const MemoDialog = ({
  onClose,
  onSubmit,
  existingMemo,
}: IAddMemoDialogProps) => {
  const [initialValues, setInitialValues] = useState<
    IMemoDialogData | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    loadMemoData().then((data) => {
      if (!existingMemo) {
        setInitialValues(data);
      } else {
        setInitialValues({ ...data, ...existingMemo });
      }

      setIsLoading(false);
    });
  }, [existingMemo]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const {
          valueGetter,
          valid,
          modified,
          onChange,
          onSubmit: handleSubmit,
        } = formRenderProps;
        return (
          <CCDialog
            titleHeader={existingMemo ? "Edit Memo" : "Add Memo"}
            onClose={onClose}
            maxWidth={"30%"}
            height={"auto"}
            bodyElement={
              isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormElement className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Category</label>
                        <Field
                          name={nameOf("Category")}
                          data={valueGetter("_option.Category.Data") || []}
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                          value={getDropdownValue(
                            valueGetter("Category"),
                            valueGetter("_option.Category.Data") ?? [],
                            "Key"
                          )}
                          onChange={(event: ComboBoxChangeEvent) => {
                            onChange("Category", {
                              value: event.target.value?.Key ?? null,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Memo</label>
                        <Field
                          validator={requiredValidator}
                          name={nameOf("Memo") || null}
                          component={CCTextArea}
                          rows={4}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              )
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={handleSubmit}
                  disabled={!valid || !modified}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
