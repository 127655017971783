import {
  BuildingRegistersEnquiryRecordsState,
  IBuildingRegistersEnquiryFormData,
} from "@app/products/building/registers/enquiries/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";

export const mockSingleBuildingRegistersEnquiry: IBuildingRegistersEnquiryFormData =
  {
    ID: 1,
    RegisterNumber: "8778",
    Contact: "Daniel M Green",
    Contact_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
    Contact_Contact: "danielgreen@hotmail.net",
    PreferredContact: "James K Baker",
    PreferredContact_Address:
      "4166  Stoney Lonesome Road, Salt Lake City, Utah",
    PreferredContact_Contact: "robert.hardwell@gmail.com",
    Address: "3288  Dancing Dove Lane, Whitestone, New York",
    Zone: "Arizona",
    Overlay: "Something like that ",
    Description:
      "I think that I am a responsible and honest boy/girl who wants to do things successfully. I am punctual towards my work and do it before time. I believe that mutual cooperation is a way to success and like to help people whenever they seek my help. I am an average student and like to read books and play chess.",
    Officer: "Andrew Thoma",
    EnquiryType: "1",
    AppNo: "15222",
    Lodged: "2020-08-14T18:57:20.898Z",
    Due: "2020-09-26T18:57:20.898Z",
    Complete: "2021-01-02T18:57:20.898Z",
    FileNumber: "623",
    OutstandingFees: 1565,
    DebtorNumber: 16622,
    Status: "Active",
    StatDays: 152,
    CreatedDate: "Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)",
    LodgedDate: "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)",
    ClockStart: "Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)",
  };

export const mockBuildingRegistersEnquiryRecordsState: BuildingRegistersEnquiryRecordsState =
  {
    actions: 0,
    comments: 0,
    contacts: 0,
    documents: 0,
    fees: 0,
    samples: 0,
  };

export const dropdownEnquiryData: IKeyValuePacket[] = [
  { Key: 1, Value: "Committee" },
  { Key: 2, Value: "Delegate" },
  { Key: 3, Value: "Minister" },
  { Key: 4, Value: "Senior Statutory Planner" },
  { Key: 5, Value: "Tribunal" },
  { Key: 6, Value: "DPC" },
  { Key: 7, Value: "Development Control Unit" },
  { Key: 8, Value: "Sydney Nth Planning Panel(JRPP)" },
  { Key: 9, Value: "Local Planning Panel" },
  { Key: 10, Value: "Land and Environment Court" },
];

export const parentPage: IParentSection[] = [
  { key: 1, label: "Register number", value: "20193" },
  { key: 2, label: "Type", value: "Building Enquiry" },
  {
    key: 3,
    label: "Description",
    value: "TEST",
  },
];
