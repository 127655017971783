import { Svc_Contact } from "@app/core/contacts/_id/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import { ContactClassification } from "@common/constants/enumerations";
import {
  ApplicationUpdateTriggers,
  LocalGovernment,
  Svc_Contact_Relation,
} from "@common/pages/settings/lookups/local-government/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressHTML } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface ILocalGovernmentFormElementProps {
  formRenderProps: FormRenderProps;
}
const nameOf = nameOfFactory<LocalGovernment>();

export const LocalGovernmentFormElement = observer(
  ({ formRenderProps }: ILocalGovernmentFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { valueGetter, onChange } = formRenderProps;

    //Get values
    const council: Svc_Contact_Relation = valueGetter(nameOf("CouncilDetails"));
    const councilContact: Svc_Contact = council?.Contact;

    const officer: Svc_Contact_Relation = valueGetter(nameOf("Officer"));
    const officerContact: Svc_Contact = officer?.Contact;

    const displayCouncilWebsite = useMemo(() => {
      return councilContact?.Website;
    }, [councilContact]);

    const displayAddress = useMemo(() => {
      return getFullAddressHTML(councilContact?.PreferredAddress);
    }, [councilContact]);

    const displayPostalAddress = useMemo(() => {
      return getFullAddressHTML(councilContact?.PostalAddress);
    }, [councilContact]);

    const displayOfficerEmailAddress = useMemo(() => {
      return officerContact?.Email;
    }, [officerContact]);

    const updateSaveTriggers = (triggers: ApplicationUpdateTriggers) => {
      let saveTriggers: ApplicationUpdateTriggers[] =
        valueGetter("_SaveTriggers") ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(nameOf("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const handleOnChangeCouncil = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      onChange("CouncilName", { value: value?.DisplayName ?? "" });
      const currentCouncil = valueGetter(nameOf("CouncilDetails"));
      onChange(nameOf("Council_RID"), { value: null });
      onChange(nameOf("CouncilDetails"), {
        value: {
          ContactRelationshipType_ENUM:
            currentCouncil?.ContactRelationshipType_ENUM ??
            ContactRelationshipType.Applicant,
          Contact: value,
        },
      });
      updateSaveTriggers(ApplicationUpdateTriggers.UpdateCouncilDetails);
    };

    const handleOnChangeOfficer = (event: ComboBoxChangeEvent) => {
      const value = event.value;
      const currentOfficer = valueGetter("Officer");
      onChange("Officer_RID", { value: null });
      onChange("Officer", {
        value: {
          ContactRelationshipType_ENUM:
            currentOfficer?.ContactRelationshipType_ENUM ??
            ContactRelationshipType.Applicant,
          Contact: value,
        },
      });
      updateSaveTriggers(ApplicationUpdateTriggers.UpdateCouncilOfficer);
    };

    return (
      <FormElement className="cc-local-goverment-form-elements">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Council
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name="CouncilDetails.Contact"
                component={ContactPicker}
                contactClassificationFilters={[
                  ContactClassification.IncorporatedBody,
                ]}
                validator={requiredValidator}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick Contact Errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder="Council"
                onChange={handleOnChangeCouncil}
              />
            </div>
            <CCValueField
              label="Council name"
              value={valueGetter(nameOf("CouncilName"))}
            />
          </div>
          <div className="cc-form-cols-3">
            <CCValueField label="Address" value={displayAddress} />
            <CCValueField label="Postal address" value={displayPostalAddress} />
            <CCValueField
              label="Council website"
              value={displayCouncilWebsite}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <Field
                name="Officer.Contact"
                component={ContactPicker}
                contactClassificationFilters={[
                  ContactClassification.Person,
                  ContactClassification.Company,
                  ContactClassification.Partnership,
                ]}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Pick Contact Errors",
                    description: error,
                    autoClose: false,
                  });
                }}
                placeholder="Officer"
                onChange={handleOnChangeOfficer}
              />
            </div>
            <CCValueField
              label="Email address"
              value={displayOfficerEmailAddress}
            />
          </div>
        </section>
      </FormElement>
    );
  }
);
