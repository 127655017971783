import { putChangeStatusDocument } from "@app/products/property/system-admin/document-template/list/component/buttons/deactivate-document/api";
import { useDocumentTemplateStore } from "@app/products/property/system-admin/document-template/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ActivateButton = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { isDeletedRecord, loadDocumentTemplate } = useDocumentTemplateStore();
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const handleActivate = async () => {
    setIsLoading(true);
    const response = await putChangeStatusDocument(parseInt(id), true);
    if (isSuccessResponse(response)) {
      await loadDocumentTemplate(parseInt(id));
      pushNotification({
        type: "success",
        title: "Document template activated successfully",
      });
    } else {
      pushNotification({
        type: "error",
        title: "Document template activate failed",
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      {isDeletedRecord && (
        <CCNavButton
          title="Active"
          onClick={handleActivate}
          isLoading={isLoading}
        />
      )}
    </>
  );
});
