import { useBuildingRegistersPOPEStore } from "@app/products/building/registers/pope/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedBuildingRegistersPOPE } from "./components/forms/existed/_index";
import { NewBuildingRegistersPOPE } from "./components/forms/new/_index";

const ManageBuildingRegistersPOPE = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadBuildingRegistersPOPE } =
    useBuildingRegistersPOPEStore();
  const isNew = useIsNew();

  useEffect(() => {
    if (isNew) return;
    loadBuildingRegistersPOPE(+id);
  }, [id, isNew, loadBuildingRegistersPOPE]);

  useEffectOnce(() => {
    return () => resetStore();
  });

  if (isNew) {
    return <NewBuildingRegistersPOPE />;
  }
  return <ExistedBuildingRegistersPOPE />;
});

export default ManageBuildingRegistersPOPE;
