import { InspectionTypeFee } from "@app/core/inspections/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const nameOfInspectionTypeFee = nameOfFactory<InspectionTypeFee>();
export const colAdditionalFees: IColumnFields[] = [
  {
    field: nameOfInspectionTypeFee("InspectionTypeFeeType_Name"),
    title: "Fee Type",
  },
  {
    field: nameOfInspectionTypeFee("InheritInspectionTypeFeeType"),
    title: "Inherit fee",
  },
  {
    field: nameOfInspectionTypeFee("InspectionTypeFee_Amount"),
    title: "Amount",
  },
];
