import { history } from "@/AppRoutes";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { setSessionRedirectURL } from "@common/pages/login/util";
import { deserialize } from "@common/utils/common";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "./auth-header";

let responseData: IResponseData = {
  data: null,
  headers: null,
  status: APIResponseStatus.BAD_REQUEST,
};
axios.defaults.transformResponse = (response) => {
  return deserialize(response);
};

axios.interceptors.response.use(
  (response: AxiosResponse): any => {
    responseData.data = response.data;
    responseData.headers = response.headers;
    responseData.status = response.status;
    return responseData;
  },
  (error) => {
    const response = error.response;
    if (response && response.status === APIResponseStatus.UN_AUTHORIZE) {
      setSessionRedirectURL();
      history.push("/login");
      localStorage.removeItem("userData");
    }

    let responseData = { data: [], status: 0, headers: [] };

    if (error.response) {
      responseData.data = error.response.data;
      responseData.headers = error.response.headers;
      responseData.status = error.response.status;
    }

    return responseData;
  }
);

export const BaseService = {
  requests: {
    get: (
      url: string,
      config?: AxiosRequestConfig | undefined
    ): Promise<IResponseData> =>
      axios.get(url, { ...config, headers: authHeader() }),
    post: (
      url: string,
      data: any,
      config?: AxiosRequestConfig | undefined
    ): Promise<IResponseData> =>
      axios.post(url, data, { ...config, headers: authHeader() }),
  },
};
