import { ChargeAndNoticeRunsDetailTab } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import { CreateChargeRunButton } from "@app/products/property/charge-and-notice-runs/charge-runs/components/buttons/create-charge-run/_index";
import { LastChargeRunButton } from "@app/products/property/charge-and-notice-runs/charge-runs/components/buttons/last-charge-run/_index";
import { DetailComponent } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/_index";
import { colListChargeRuns } from "@app/products/property/charge-and-notice-runs/charge-runs/config";
import { CHARGERUNS_LIST_VIEW_URL } from "@app/products/property/charge-and-notice-runs/charge-runs/constant";
import { VO_ChargeRun } from "@app/products/property/charge-and-notice-runs/charge-runs/model";
import {
  ChargeRunBookmark,
  ChargeRunListBookmark,
} from "@app/products/property/charge-and-notice-runs/charge-runs/util";
import { chargeAndNoticeRunsRoute } from "@app/products/property/charge-and-notice-runs/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_ChargeRun>();
export default () => {
  const { state } = useLocation<{
    notification?: IAppNotificationItemAddProps;
  }>();
  const { pushNotification } = useCCAppNotificationStore();
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={chargeAndNoticeRunsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CreateChargeRunButton />
      </CCNavButton>,
      <LastChargeRunButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_CHARGE_RUNS_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        detail={ChargeRunBookmark.getBookmarkDetail}
        displayName={ChargeRunBookmark.getBookmarkDisplayName}
        listViewDetail={ChargeRunListBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          ChargeRunListBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useEffectOnce(() => {
    if (state?.notification) {
      //This message use in src\app\products\property\charge-and-notice-runs\charge-runs\[id]\components\buttons\delete\_index.tsx
      pushNotification(state.notification);
      window.history.replaceState({}, "");
    }
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ChargeAndNoticeRunsDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Charge_Run}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      detail={DetailComponent}
      dataUrl={CHARGERUNS_LIST_VIEW_URL}
      columnFields={colListChargeRuns}
      primaryField={nameOf("Charge_Run_Id")}
      state={{ sort: [{ field: nameOf("Charge_Run_Id"), dir: "desc" }] }}
    />
  );
};
