import { colPPRAppealsAll } from "@app/products/town-planning/ppr/permit-appeals/all/config";
import { PPRAppeal } from "@app/products/town-planning/ppr/permit-appeals/all/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRAppeal>();

export const colPPRAppealsCurrentByStatus: IColumnFields[] =
  colPPRAppealsAll.filter(
    (item: IColumnFields) => item.field !== nameOf("AppealDays")
  );
