import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colRegister: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 250,
    dataType: "numeric",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.AppealDays,
    title: "Appeal Days",
    width: 250,
    dataType: "numeric",
  },
  {
    field: TownPlanningFieldMapping.Planner,
    title: "Planner",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.ReasonForPermit,
    title: "Reason for Permit",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.AppType,
    title: "Application Type",
    width: 250,
  },
];
