import { getDeferredDutyDetailsTabById } from "@app/products/property/deferred-duty/[id]/components/reference-sidebar/details/api";
import { DTO_DeferredDuty_Detail } from "@app/products/property/deferred-duty/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const DeferredDutyDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [deferredDutyInfo, setDeferredDutyInfo] = useState<
    DTO_DeferredDuty_Detail | undefined
  >(undefined);
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const deferredDutyId = lastSelectedRow?.Schema_Acccount_ID
    ? lastSelectedRow?.Schema_Acccount_ID
    : parseInt(params.id);

  const loadData = () => {
    if (deferredDutyId) {
      setIsLoading(true);
      getDeferredDutyDetailsTabById(deferredDutyId).then((response) => {
        let errorResponse = undefined;
        if (isSuccessResponse(response)) {
          if (response.data?.DeferredDutyDetail) {
            setDeferredDutyInfo(response.data?.DeferredDutyDetail);
          }
        } else {
          errorResponse = {
            status: response.status,
            error: response.error ?? "Load detail failed",
          };
        }
        setIsLoading(false);
        setResponseLoadError(errorResponse);
      });
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredDutyId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  }
  if (!deferredDutyInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Account number:"}
          value={deferredDutyInfo.AccountNumber}
        />
        <ProductReferenceRow
          title={"Account ID:"}
          value={deferredDutyInfo.AccountId}
        />
        <ProductReferenceRow
          title={"Notice number:"}
          value={deferredDutyInfo.NoticeNumber}
        />
        <ProductReferenceRow
          title={"Property:"}
          value={deferredDutyInfo.PropertyAddress}
        />
        <ProductReferenceRow title={"Name:"} value={deferredDutyInfo.Name} />
        <ProductReferenceRow
          title={"SSBU:"}
          value={deferredDutyInfo.AssessmentReference}
        />
        <ProductReferenceRow
          title={"Balance outstanding:"}
          value={formatDisplayValue(
            deferredDutyInfo.BalanceOutStanding,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
        {deferredDutyInfo.AlertMessage?.length > 0 && (
          <ProductReferenceRow
            badge
            value={deferredDutyInfo.AlertMessage}
            danger
          />
        )}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
