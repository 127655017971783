import { colGroups } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/config";
import { REPORT_SHARE_REPORT_GET_ORG_UNIT_IS_ADHOC_TRUE_URL } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/constant";
import { IGroup as IGroups } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/component/groups-grid/model";
import { nameOfFactory } from "@common/utils/common";
import { GridSearch } from "@components/cc-grid-search/_index";
import { ICCGridProps } from "@components/cc-grid/_index";
import { State } from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";

interface IGroupsGridProps extends ICCGridProps {
  handleSelectionChange: (e: IGroups[]) => void;
}
export const nameOfGroups = nameOfFactory<IGroups>();
export const GroupsGrid = (props: IGroupsGridProps) => {
  const { handleSelectionChange, state = {} } = props;
  const [stateData, setStateData] = useState<State>({
    ...state,
    sort: [{ field: nameOfGroups("Hierarchy"), dir: "asc" }],
  });

  useEffect(() => {
    setStateData({
      ...state,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.state]);

  return (
    <div className="cc-grid-selection-dialog">
      <GridSearch
        {...props}
        state={stateData}
        grid={{
          primaryField: nameOfGroups("ID"),
        }}
        search={{
          hasSearchField: true,
          filterCol: nameOfGroups("Hierarchy"),
          sortCol: nameOfGroups("Hierarchy"),
        }}
        columnFields={colGroups}
        selectableMode={"multiple"}
        dataUrl={REPORT_SHARE_REPORT_GET_ORG_UNIT_IS_ADHOC_TRUE_URL}
        handleSelectionChange={handleSelectionChange}
      />
    </div>
  );
};
