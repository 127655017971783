import { CSL_ROUTE } from "@app/products/csl/[id]/constant";

export const colCSLFeesAllFees = [
  {
    field: "ReferenceNumber",
    title: "Reference",
    locked: true,
    linkTo: (dataItem: any) => `${CSL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "ReferenceNumber",
    title: "Reference",
  },
  {
    field: "CreatedDate",
    title: "Recorded",
  },
  {
    field: "FeeType",
    title: "Type",
  },
  {
    field: "AmountIssued",
    title: "Issued",
  },
  {
    field: "AmountOutstanding",
    title: "Outstanding",
  },
  {
    field: "CostCode",
    title: "Cost Code",
  },
];
