import { IConfirmation } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/confirmation/model";
import { getWorkflowCancellationReasons } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/api";
import { DTO_Cancellation_LOVs } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/model";
import { ConfirmationDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirmation/_index";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
export interface IConfirmationDialogProps {
  onClose: () => void;
  onSubmitData: (data: IConfirmation) => void;
  description?: string;
  dataLOVsReason?: DTO_LOV[];
  isLoadingSendData?: boolean;
  notifications?: IAppNotificationItem[];
}
export const CancelDialog = (props: IConfirmationDialogProps) => {
  //State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancellationLOVs, setCancellationLOVs] =
    useState<DTO_Cancellation_LOVs>();
  const [responseLoadError, setResponseError] = useState<
    APIResponseError | undefined
  >();

  const loadCancelDataLOVs = async () => {
    setIsLoading(true);
    const response = await getWorkflowCancellationReasons();
    let errorResponse;
    if (isSuccessResponse(response)) {
      setCancellationLOVs(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setResponseError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadCancelDataLOVs();
  });

  return (
    <ConfirmationDialog
      {...props}
      titleHeader="Cancel Confirmation"
      subject="Are you sure you want to cancel this workflow?"
      customLabelReason="Cancelled Reason"
      dataLOVsReason={cancellationLOVs?.ReasonForCancellation ?? []}
      isLoading={isLoading}
      responseLoadError={responseLoadError}
      onReload={() => loadCancelDataLOVs()}
    />
  );
};
