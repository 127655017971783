import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const enableAskAI = async (): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      "/api/core/internal/help/enableAIChat"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data,
    };
  }
};

export const loadConversation = async (
  conversationId?: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      "/api/core/internal/help/conversation/" + conversationId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.message,
    };
  }
};

export const postAIMessage = async (
  lastMessage: string,
  productId: number,
  conversationId?: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<any>(
      "/api/core/internal/help/message",
      {
        ConversationId: conversationId,
        LastMessage: lastMessage,
        IgnoreContent: false,
        ProductID: productId,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.message,
    };
  }
};

export const getAIMessage = async (
  messageId: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      "/api/core/internal/help/message/" + messageId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.message,
    };
  }
};
