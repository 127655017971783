import { getLineage } from "@app/products/property/components/lineage/api";
import { DTO_Component_Lineage } from "@app/products/property/components/lineage/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class LineageStore {
  private _lineage?: DTO_Component_Lineage = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get lineage() {
    return toJS(this._lineage);
  }
  setLineage = (lineage?: DTO_Component_Lineage) => {
    runInAction(() => {
      this._lineage = lineage;
    });
  };

  //Action
  resetStore = () => {
    this.setLineage(undefined);
    this.setIsLoading(false);
  };
  loadLineage = async (apiUrl: string) => {
    this.setIsLoading(true);
    const lineage = await getLineage(apiUrl);
    this.setLineage(lineage);
    this.setIsLoading(false);
  };
}

const lineageStoreContext = createContext(new LineageStore());
export const useLineageStore = () => {
  return useContext(lineageStoreContext);
};
