import { DTO_RebateClaim_LOVs } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/form-steps/new-rebate-claim/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CreateRebateClaimStore {
  private _createRebateClaimLOVs?: DTO_RebateClaim_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get createRebateClaimLOVs() {
    return this._createRebateClaimLOVs;
  }
  setCreateRebateClaimLOVs = (createRebateClaimLOVs?: DTO_RebateClaim_LOVs) => {
    runInAction(() => {
      this._createRebateClaimLOVs = createRebateClaimLOVs;
    });
  };
}

const createRebateClaimStoreContext = createContext(
  new CreateRebateClaimStore()
);
export const useCreateRebateClaimStore = () => {
  return useContext(createRebateClaimStoreContext);
};
