import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { colAttachment } from "./config";
const Attachment = (props: GridDetailRowProps) => {
  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Attachments</label>
            <CCGrid
              className="cc-charges-grid"
              data={props.dataItem["Attachment"]}
              columnFields={colAttachment}
              primaryField="DocumentId"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Attachment;
