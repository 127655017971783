import { putCRMSSystemSetting } from "@app/products/crms/system-admin/[id]/api";
import { loadCrmsSetting } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/api";
import { CRMSSystemSettingFormElement } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { ICrmsSetting } from "@app/products/crms/system-admin/[id]/components/forms/components/child-screens/general/model";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IProps {
  setIsLoading: (status: boolean) => void;
  formData?: any;
  activeItem: string;
}

export const CRMSSystemSettingForm = ({
  setIsLoading,
  formData: initialValues,
  activeItem,
}: IProps) => {
  const [crmsSettingData, setCrmsSettingData] = useState<
    ICrmsSetting | undefined
  >(undefined);
  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    await putCRMSSystemSetting(data);
    setIsLoading(false);
  };
  useEffectOnce(() => {
    const data = loadCrmsSetting();
    setCrmsSettingData(data);
  });
  return (
    <>
      {crmsSettingData && (
        <Form
          onSubmit={handleSubmit}
          initialValues={crmsSettingData}
          render={(formRenderProps: FormRenderProps) => (
            <CRMSSystemSettingFormElement
              formRenderProps={formRenderProps}
              activeItem={activeItem}
            />
          )}
        />
      )}
    </>
  );
};
