import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colApplications: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.DeletedOn,
    title: "Deleted On",
    width: 250,
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.DeletedBy,
    title: "Deleted By",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 250,
    dataType: "numeric",
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.ReasonForPermit,
    title: "Reason for Permit",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Planner,
    title: "Planner",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.AppealDays,
    title: "Appeal Days",
    width: 250,
    dataType: "numeric",
  },
];
