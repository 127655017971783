import {
  getFromDate,
  getToDate,
} from "@app/products/ams/api/helpers/date-helper";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { isAfter } from "date-fns";

export const dateRangeValidator = (
  value: any,
  fromDateField: string,
  toDateField: string
) => {
  const fromDate = value?.[fromDateField]
    ? getFromDate(value[fromDateField])
    : null;
  const toDate = value?.[toDateField] ? getToDate(value[toDateField]) : null;

  let errorMessage = {};

  if (fromDate && toDate && isAfter(fromDate, toDate)) {
    errorMessage = {
      ...errorMessage,
      [fromDateField]: `Date input must be before ${formatDateByKendo(
        toDate,
        DATE_FORMAT.DATE_CONTROL
      )}`,
      [toDateField]: `Date input must be after ${formatDateByKendo(
        fromDate,
        DATE_FORMAT.DATE_CONTROL
      )}`,
    };
  }

  return errorMessage;
};
