import { getAssessmentTransfer } from "@app/products/property/assessments/[id]/components/child-screens/transfer/api";
import { processChangeOfOwnershipData } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { DTO_ChangeOfOwnershipProcessed } from "./model";
configure({ enforceActions: "always" });

class AssessmentTransferStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _transfer?: DTO_ChangeOfOwnershipProcessed[] = undefined;
  private _transferSelected?: DTO_ChangeOfOwnershipProcessed = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get transfer() {
    return this._transfer;
  }
  setTransfer = (transfer?: DTO_ChangeOfOwnershipProcessed[]) => {
    runInAction(() => {
      this._transfer = transfer;
    });
  };

  get transferSelected() {
    return this._transferSelected;
  }
  setTransferSelected = (transferSelected?: DTO_ChangeOfOwnershipProcessed) => {
    runInAction(() => {
      this._transferSelected = transferSelected;
    });
  };

  get transferSelectedID() {
    return this._transferSelected?.Id;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._transfer = undefined;
      this._isLoading = false;
      this._transferSelected = undefined;
      this._responseLoadError = undefined;
    });
  };

  loadTransfer = async (assessmentId: number) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    const response = await getAssessmentTransfer(assessmentId);
    if (isSuccessResponse(response)) {
      this.setTransfer(processChangeOfOwnershipData(response.data));
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setTransferSelected(undefined);
    this.setIsLoading(false);
    this.setResponseLoadError(errorResponse);
  };

  reLoadTransfer = async (assessmentId?: number) => {
    if (assessmentId) {
      await this.loadTransfer(assessmentId);
    }
  };
}
const assessmentTransferStoreContext = createContext(
  new AssessmentTransferStore()
);

export const useAssessmentTransferStore = () => {
  return useContext(assessmentTransferStoreContext);
};
