import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import {
  toBooleanType,
  toDateType,
  toNumberType,
  toStringType,
} from "@common/utils/dataType";
import { BooleanCell } from "@components/cc-grid/components/grid-cells/boolean/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { DateCell } from "@components/cc-grid/components/grid-cells/date/_index";
import { DateTimeCell } from "@components/cc-grid/components/grid-cells/datetime/_index";
import { IconCell } from "@components/cc-grid/components/grid-cells/icon/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { PercentageCell } from "@components/cc-grid/components/grid-cells/percentage/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridCellProps } from "@progress/kendo-react-grid";
import { isFunction, isObject } from "lodash";
import "./_index.scss";

interface IRenderCellProps {
  cell: GridCellProps;
  columnFields: IColumnFields[];
  primaryField: string;
  editField: string;
  editableMode?: "cell" | "row";
  disabledGrid: boolean;
  onEnterEditCell?: (dataItem: any, field: string) => void;
}
export const GridDynamicCell = ({
  cell,
  columnFields,
  primaryField,
  editField,
  editableMode,
  disabledGrid = false,
  onEnterEditCell,
}: IRenderCellProps) => {
  const columnField = columnFields.find(
    (item: any) => item.field === cell.field
  );
  const value = cell.dataItem[columnField!.field];

  let customFormat;
  if (isFunction(columnField?.format)) {
    customFormat = columnField?.format(cell.dataItem);
  } else if (isObject(columnField?.format)) {
    customFormat = columnField?.format;
  }

  const format: any = customFormat
    ? customFormat.customFormat
    : columnField?.format;

  const editCell = columnField?.editCell;
  const disableFieldName = columnField?.disableFieldName;
  const linkTo = !disabledGrid ? columnField?.linkTo : undefined;
  const href = !disabledGrid ? columnField?.href : undefined;
  const inEdit = cell.dataItem[editField];
  const handleOnClick = !disabledGrid ? columnField?.handleOnClick : undefined;
  const style: any =
    columnField?.style && typeof columnField?.style === "function"
      ? { ...cell.style, ...columnField?.style(value) }
      : { ...cell.style, ...columnField?.style };

  let isDisable: boolean = false;
  let disableEditField: string | undefined | ((dataItem: any) => boolean);
  switch (editableMode) {
    case "cell":
      disableEditField = columnField?.disableEditField;
      break;
    case "row":
      disableEditField = columnFields.find(
        (item: any) => item.disableEditField !== undefined
      )?.disableEditField;
      break;
    default:
      disableEditField = undefined;
      break;
  }

  if (disableFieldName) {
    isDisable =
      cell.dataItem[disableFieldName] === true ||
      cell.dataItem[disableFieldName] === undefined
        ? false
        : true;
  }

  const isDisableEdit: boolean = isFunction(disableEditField)
    ? disableEditField(cell.dataItem)
    : disableEditField && cell.dataItem[disableEditField];

  //@TODO: change logic, remove editableMode
  let editable: boolean =
    editableMode === undefined ||
    columnField?.field === "" ||
    isDisableEdit ||
    !columnField?.editable
      ? false
      : editableMode === "cell"
      ? Array.isArray(inEdit) && inEdit.includes(cell.field)
      : inEdit !== undefined;

  const formatChecker = customFormat ? customFormat.defaultFormat : format;

  if (Object.values(DATE_FORMAT).includes(formatChecker)) {
    return DateCell({
      ...cell,
      value: toDateType(value),
      style,
      handleOnClick,
      primaryField,
      linkTo,
      href,
      format,
      isDisable,
      editable,
      onEnterEditCell,
      editCell,
    });
  }

  if (Object.values(DATETIME_FORMAT).includes(formatChecker)) {
    return DateTimeCell({
      ...cell,
      value: toDateType(value),
      style,
      handleOnClick,
      primaryField,
      linkTo,
      href,
      format,
      isDisable,
      editable,
      onEnterEditCell,
      editCell,
    });
  }
  if (
    Object.values(NUMBER_FORMAT).includes(formatChecker) ||
    Object.values(DECIMAL_FORMAT).includes(formatChecker)
  ) {
    return NumberCell({
      ...cell,
      value: toNumberType(value) ?? undefined,
      style,
      handleOnClick,
      linkTo,
      href,
      format,
      isDisable,
      primaryField,
      editable,
      onEnterEditCell,
      editCell,
    });
  }

  if (Object.values(CURRENCY_FORMAT).includes(formatChecker)) {
    return CurrencyCell({
      ...cell,
      value: toNumberType(value) ?? undefined,
      style,
      handleOnClick,
      linkTo,
      href,
      format,
      // rowValue,
      primaryField,
      isDisable,
      editable,
      onEnterEditCell,
      editCell,
    });
  }

  if (Object.values(PERCENTAGE_FORMAT).includes(formatChecker)) {
    return PercentageCell({
      ...cell,
      value: toNumberType(value) ?? undefined,
      style,
      handleOnClick,
      linkTo,
      href,
      format,
      isDisable,
      primaryField,
      editable,
      onEnterEditCell,
      editCell,
    });
  }

  if (Object.values(ICON_FORMAT).includes(formatChecker)) {
    return IconCell({
      ...cell,
      value: toBooleanType(value),
      style,
      handleOnClick,
      primaryField,
      linkTo,
      href,
      format,
      isDisable,
      editable,
      editCell,
      onEnterEditCell,
    });
  }

  if (Object.values(BOOLEAN_FORMAT).includes(formatChecker)) {
    return BooleanCell({
      ...cell,
      value: toBooleanType(value),
      style,
      handleOnClick,
      linkTo,
      href,
      format,
      primaryField,
      isDisable,
      editable,
      onEnterEditCell,
      editCell,
    });
  }

  return TextCell({
    ...cell,
    value: toStringType(value),
    style,
    handleOnClick,
    linkTo,
    href,
    format,
    isDisable,
    primaryField,
    editable,
    onEnterEditCell,
    editCell,
  });
};
