import { IMeterLastReadingDetails } from "@app/products/property/meters/last-reading-details/model";

export const mockMeterLastReadingDetailsList: IMeterLastReadingDetails[] = [
  {
    MeterId: 3604,
    MeterNumber: "0000232",
    LastReadingDateTime: new Date("24 Jan 2022"),
    LastReading: 3580,
    LastReadingComments: "Reading on Installation",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("24 Jan 2022"),
    PreviousReading: 3580,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 0,
    DailyUsageInLastPeriod: 0,
    RouteNumber: 3000,
    ReadingSeqInRoute: 41500,
    Status: "Active",
    Location: "98 Shute Harbour Rd L/h Side",
    Category: "Whitsunday Water Usage (W1)",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("24 Jan 2022"),
  },
  {
    MeterId: 3617,
    MeterNumber: "125",
    LastReadingDateTime: new Date("21 Jan 2022"),
    LastReading: 0,
    LastReadingComments: "Meter Installation",
    LastReadingStatus: "New Meter",
    RouteNumber: 3000,
    ReadingSeqInRoute: 100,
    Status: "Active",
    Location: "front yard",
    Category: "No Charge Meter",
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("21 Jan 2022"),
  },
  {
    MeterId: 3596,
    MeterNumber: "0005819",
    LastReadingDateTime: new Date("21 Jan 2022"),
    LastReading: 2083,
    LastReadingComments: "Reading on Installation",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("21 Jan 2022"),
    PreviousReading: 2083,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 0,
    DailyUsageInLastPeriod: 0,
    RouteNumber: 3001,
    ReadingSeqInRoute: 12200,
    PropertyAddress: ", Cannonvale",
    Status: "Active",
    Location: "Rechecked by Council Plumbers 21/3 - 6406",
    Category: "Whitsunday Water Usage (W1)",
    Size: 28.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("21 Jan 2022"),
  },
  {
    MeterId: 3594,
    MeterNumber: "0005044",
    LastReadingDateTime: new Date("2.Jan 2022"),
    LastReading: 4589,
    LastReadingComments: "Reading on Installation",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("2.Jan 2022"),
    PreviousReading: 4589,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 0,
    DailyUsageInLastPeriod: 0,
    RouteNumber: 5000,
    ReadingSeqInRoute: 42900,
    PropertyAddress: ". Airlie Beach",
    Status: "Active",
    Location: "Boathaven Resort - end of Hermitage Dr - Right side",
    Category: "Whitsunday Water Usage (W1)",
    Size: 15.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("28 Jan 2022"),
  },
  {
    MeterId: 3615,
    MeterNumber: "05W888617",
    LastReadingDateTime: new Date("19 Jan 2022"),
    LastReading: 4900,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("06 Jun 2020"),
    PreviousReading: 4782,
    UsageInLastPeriod: 118,
    DaysInLastPeriod: 592,
    DailyUsageInLastPeriod: 0.1993,
    LastMeterReader: "Administrator",
    RouteNumber: 3001,
    ReadingSeqInRoute: 8700,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "171 Shute Harbour Rd - R.H Front Boundary",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 28.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("19 Jan 2816"),
  },
  {
    MeterId: 3602,
    MeterNumber: "Anh123",
    LastReadingDateTime: new Date("19 Jan 2022"),
    LastReading: 10,
    LastReadingComments: "Meter Installation",
    LastReadingStatus: "New Meter",
    RouteNumber: 4000,
    ReadingSeqInRoute: 34400,
    Status: "Active",
    Location: "Left Front Cornder",
    Category: "No Charge Meter",
    MeterType: "Mains",
    Size: 28.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("19 Jan 2022"),
  },
  {
    MeterId: 3582,
    MeterNumber: "Anh234",
    LastReadingDateTime: new Date("19 Jan 2022"),
    LastReading: 90,
    LastReadingComments: "Meter Installation",
    LastReadingStatus: "New Meter",
    RouteNumber: 4000,
    ReadingSeqInRoute: 34500,
    Status: "Active",
    Location: "Right Corner",
    Category: "No Charge Meter",
    MeterType: "Mains",
    Size: 15.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("19 Jan 2022"),
  },
  {
    MeterId: 3578,
    MeterNumber: "9945172",
    LastReadingDateTime: new Date("19 Jan 2022"),
    LastReading: 0,
    LastReadingComments: "Reading on Installation",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("19 Jan 2022"),
    PreviousReading: 0,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 0,
    DailyUsageInLastPeriod: 0,
    RouteNumber: 4000,
    ReadingSeqInRoute: 34500,
    Status: "Active",
    Location: "L79 25 Horizons Way Private Rd, Airlie",
    Category: "Proserpine Water Usage (P1)",
    Size: 28.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("19 Jan 2022"),
  },
  {
    MeterId: 3577,
    MeterNumber: "00HC00916C",
    LastReadingDateTime: new Date("14 Jan 2022"),
    LastReading: 37000,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("06 Jun 2020"),
    PreviousReading: 36961,
    UsageInLastPeriod: 39,
    DaysInLastPeriod: 587,
    DailyUsageInLastPeriod: 0.0664,
    RouteNumber: 3001,
    ReadingSeqInRoute: 12100,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Back right of Resort",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 80.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("15 Oct 2818"),
  },
  {
    MeterId: 3579,
    MeterNumber: "99009298",
    LastReadingDateTime: new Date("14 Jan 2022"),
    LastReading: 5000,
    LastReadingComments: "test",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("04 Jun 2020"),
    PreviousReading: 2678,
    UsageInLastPeriod: 2322,
    DaysInLastPeriod: 589,
    DailyUsageInLastPeriod: 3.9423,
    LastMeterReader: "Administrator",
    RouteNumber: 1002,
    ReadingSeqInRoute: 17900,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Category: "Proserpine Water Usage (P1)",
    Size: 28.008,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3581,
    MeterNumber: "M627283",
    LastReadingDateTime: new Date("14 Jan 2022"),
    LastReading: 4250,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("07 Jun 2020"),
    PreviousReading: 4198,
    UsageInLastPeriod: 52,
    DaysInLastPeriod: 586,
    DailyUsageInLastPeriod: 0.0887,
    LastMeterReader: "WMR1",
    RouteNumber: 4000,
    ReadingSeqInRoute: 33200,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "left boundary inside gate underground",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3600,
    MeterNumber: "03C035888",
    LastReadingDateTime: new Date("27 Sep 2021"),
    LastReading: 6700,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("04 Jun 2020"),
    PreviousReading: 6449,
    UsageInLastPeriod: 251,
    DaysInLastPeriod: 480,
    DailyUsageInLastPeriod: 0.5229,
    LastMeterReader: "WMR1",
    RouteNumber: 1003,
    ReadingSeqInRoute: 8600,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "1 Main St - rh side of block near green post office power box.",
    Category: "Proserpine Water Usage (P1)",
    MeterType: "Mains",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("11 Sep 2813"),
  },
  {
    MeterId: 3616,
    MeterNumber: "03W521424",
    LastReadingDateTime: new Date("27 Sep 2021"),
    LastReading: 1509,
    LastReadingStatus: "Estimate",
    PreviousReadingDateTime: new Date("06 Jun 2020"),
    PreviousReading: 1382,
    UsageInLastPeriod: 127,
    DaysInLastPeriod: 478,
    DailyUsageInLastPeriod: 0.2657,
    RouteNumber: 3002,
    ReadingSeqInRoute: 22700,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "5.Tropic Road - R/h Boundary",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("15 Sep 2813"),
  },
  {
    MeterId: 3613,
    MeterNumber: "07W003563",
    LastReadingDateTime: new Date("31 Jul 2020"),
    LastReading: 1050,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("06 Jun 2020"),
    PreviousReading: 1006,
    UsageInLastPeriod: 44,
    DaysInLastPeriod: 55,
    DailyUsageInLastPeriod: 0.8,
    LastMeterReader: "WMR1",
    RouteNumber: 3002,
    ReadingSeqInRoute: 36200,
    PropertyAddress: "76 Abell Road, Cannonvale",
    Status: "Active",
    Location: "Front Right - 76 Abell Road, Cannonvale",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("15 Feb 2817"),
  },
  {
    MeterId: 3614,
    MeterNumber: "07W003605",
    LastReadingDateTime: new Date("31 Jul 2020"),
    LastReading: 400,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("06 Jun 2020"),
    PreviousReading: 364,
    UsageInLastPeriod: 36,
    DaysInLastPeriod: 55,
    DailyUsageInLastPeriod: 0.6545,
    LastMeterReader: "WMR1",
    RouteNumber: 3002,
    ReadingSeqInRoute: 44700,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "16 Parker Road front right",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("29 May 2817"),
  },
  {
    MeterId: 3595,
    MeterNumber: "09HD09990C",
    LastReadingDateTime: new Date("29 Jun 2020"),
    LastReading: 334,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("19 Apr 2020"),
    PreviousReading: 0,
    UsageInLastPeriod: 334,
    DaysInLastPeriod: 71,
    DailyUsageInLastPeriod: 4.7042,
    RouteNumber: 4000,
    ReadingSeqInRoute: 15400,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "25 Horizons Way Private Road, Airlie - Ulysses L79",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 100.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("19 Apr 2828"),
  },
  {
    MeterId: 3608,
    MeterNumber: "08HB18029",
    LastReadingDateTime: new Date("23 Jun 2020"),
    LastReading: 834,
    LastReadingComments: "Plumbers read meter 23.06.10",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("02 Mar 2020"),
    PreviousReading: 833,
    UsageInLastPeriod: 1,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.0088,
    RouteNumber: 3002,
    ReadingSeqInRoute: 37400,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Parker Road - Meter at Park - Endeavour Crt Park",
    ReadingInstructions: "at park near transformer",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 50.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("13 Mar 2819"),
  },
  {
    MeterId: 3573,
    MeterNumber: "08D001413",
    LastReadingDateTime: new Date("23 Jun 2020"),
    LastReading: 14,
    LastReadingComments: "Plumbers checked meter 23.06.10",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("02 Mar 2020"),
    PreviousReading: 14,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.0,
    RouteNumber: 3002,
    ReadingSeqInRoute: 37500,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location:
      "Parkers Road - meter -turn right in st at roundabout- Cromandel Grove",
    ReadingInstructions: "to right of roundabout",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 32.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("13 Mar 2819"),
  },
  {
    MeterId: 3597,
    MeterNumber: "08D001414",
    LastReadingDateTime: new Date("23 Jun 2020"),
    LastReading: 10,
    LastReadingComments: "Plumbers confirmed reading 23.06.1.- 10",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("02 Mar 2020"),
    PreviousReading: 10,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.0,
    RouteNumber: 3002,
    ReadingSeqInRoute: 37600,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location:
      "Parkers Road - large lot near roundabout - Cromandel Grove on right side",
    ReadingInstructions: "to right of roundabout 2nd meter",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 32.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("13 Mar 2819"),
  },
  {
    MeterId: 3587,
    MeterNumber: "08HB18026",
    LastReadingDateTime: new Date("23 Jun 2020"),
    LastReading: 521,
    LastReadingComments: "Plumbers confirmed reading 23.06.1.- 521",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("02 Mar 2020"),
    PreviousReading: 504,
    UsageInLastPeriod: 17,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.1504,
    RouteNumber: 3002,
    ReadingSeqInRoute: 37300,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "Parkers Road - 50m past roundabout - at park near transformer",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 50.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("12 Mar 2819"),
  },
  {
    MeterId: 3599,
    MeterNumber: "08W109783",
    LastReadingDateTime: new Date("23 Jun 2020"),
    LastReading: 128,
    LastReadingComments: "Plumbers confirmed reading 23.06.10",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("02 Mar 2020"),
    PreviousReading: 124,
    UsageInLastPeriod: 4,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.0354,
    RouteNumber: 3002,
    ReadingSeqInRoute: 37700,
    Status: "Active",
    Location: "Parker Road - centre of first roundabout - garden meter",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("28 Jan 2819"),
  },
  {
    MeterId: 3592,
    MeterNumber: "07D013129",
    LastReadingDateTime: new Date("22 Jun 2020"),
    LastReading: 41,
    LastReadingComments: "Plumbers confirmed reading",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("01 Mar 2020"),
    PreviousReading: 0,
    UsageInLastPeriod: 41,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.3628,
    RouteNumber: 3003,
    ReadingSeqInRoute: 4600,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "5 Myer Lasky Drive, Cannonvale",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("11 Mar 2828"),
  },
  {
    MeterId: 3588,
    MeterNumber: "MX37844",
    LastReadingDateTime: new Date("22 Jun 2020"),
    LastReading: 6399,
    LastReadingComments: "Plumbers confirmed reading 22.06.1.- 6399",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("01 Mar 2020"),
    PreviousReading: 6396,
    UsageInLastPeriod: 3,
    DaysInLastPeriod: 113,
    DailyUsageInLastPeriod: 0.0265,
    RouteNumber: 1004,
    ReadingSeqInRoute: 1700,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location:
      "Between Club House & Dressing Sheds under metal lid - Less Stagg Oval",
    Category: "Proserpine Water Usage (P1)",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3591,
    MeterNumber: "97023184",
    LastReadingDateTime: new Date("22 Jun 2020"),
    LastReading: 1066,
    LastReadingComments: "Plumber read meter 22.06.1.- 1066",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("26 Feb 2020"),
    PreviousReading: 1015,
    UsageInLastPeriod: 51,
    DaysInLastPeriod: 117,
    DailyUsageInLastPeriod: 0.4359,
    RouteNumber: 1003,
    ReadingSeqInRoute: 11100,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "behind elders reality driveway underground",
    Category: "Proserpine Water Usage (P1)",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3590,
    MeterNumber: "R00006083",
    LastReadingDateTime: new Date("17 Jun 2020"),
    LastReading: 3456,
    LastReadingComments: "Estimated - meter replaced 09.06.10",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("01 Mar 2020"),
    PreviousReading: 3382,
    UsageInLastPeriod: 74,
    DaysInLastPeriod: 108,
    DailyUsageInLastPeriod: 0.6852,
    RouteNumber: 2000,
    ReadingSeqInRoute: 7000,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "27 Camm Rd - Mt Julian Shop",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("12 May 2811"),
  },
  {
    MeterId: 3589,
    MeterNumber: "10W011647",
    LastReadingDateTime: new Date("09 Jun 2020"),
    LastReading: 0,
    LastReadingComments: "Rending on Installation",
    LastReadingStatus: "Read",
    RouteNumber: 6000,
    ReadingSeqInRoute: 6400,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "right boundary directly behind power pole hidden under trees",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("19 Jun 2828"),
  },
  {
    MeterId: 3611,
    MeterNumber: "000246",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 167,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 167,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0,
    RouteNumber: 5001,
    ReadingSeqInRoute: 5900,
    Status: "Active",
    Location: "Note in Use - traffic island - Stormvogel Drive",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3586,
    MeterNumber: "09B004299",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 0,
    LastReadingStatus: "Rend",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 0,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0,
    RouteNumber: 5000,
    ReadingSeqInRoute: 42600,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Peppers Resort - 9a Hermitage Dr - Fire Meter",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Fire Hydrant",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("11 May 2819"),
  },
  {
    MeterId: 3585,
    MeterNumber: "99009294",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 4629,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 4619,
    UsageInLastPeriod: 10,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.1053,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 9500,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location:
      "287.Shute Harbour Road, Flametree - 50M L/h side up concrete d/way marked #18",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("18 Nov 2889"),
  },
  {
    MeterId: 3612,
    MeterNumber: "03W521383",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 1911,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 1859,
    UsageInLastPeriod: 52,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.5474,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 9400,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "2944 Shute Har Rd - Doll Museum",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("28 Jul 2813"),
  },
  {
    MeterId: 3593,
    MeterNumber: "MX25342",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 6461,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 6460,
    UsageInLastPeriod: 1,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0105,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 9300,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "Picnic/camping Area - approx 15m Shute Har Side of drive",
    Category: "Whitsunday Water Usage (W1)",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3619,
    MeterNumber: "030035900",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 5642,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 5639,
    UsageInLastPeriod: 3,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0316,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 9200,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location:
      "Top of bank behind guard rale at 2nd marker arrow from McInerney's house toward;",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("2 Jan 2014"),
  },
  {
    MeterId: 3618,
    MeterNumber: "R940006021",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 2401,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 2346,
    UsageInLastPeriod: 55,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.5789,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 9100,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "3264 Shute Harbour Road, Shute Harbour - l/h side of house",
    Category: "Whitsunday Water Usage (W1)",
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3576,
    MeterNumber: "03E012656",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 11446,
    LastReadingComments: "needs raising",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 11075,
    UsageInLastPeriod: 371,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 3.9053,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 9000,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "3344 Shute Harb Rd - Shute Harb.Motel - McInerney",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 40.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("14 Oct 2013"),
  },
  {
    MeterId: 3609,
    MeterNumber: "03W521382",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 31,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 31,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8900,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Shute Harb Rd - Wsc Opp. Shell Shutehaven",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 2.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("28 Jul 2013"),
  },
  {
    MeterId: 3610,
    MeterNumber: "03W521363",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 2817,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 2704,
    UsageInLastPeriod: 113,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 1.1895,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8800,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "Toilets Shute Harbours - Centre Garden",
    ReadingInstructions: "VACANT",
    Category: "Whitsunday Water Usage (W1)",
    Size: 2.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("05 May 2013"),
  },
  {
    MeterId: 3584,
    MeterNumber: "97006301",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 888,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 871,
    UsageInLastPeriod: 17,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.1789,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8700,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "boat ramp next to power pole - Ih side of ramp",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3601,
    MeterNumber: "04M062768",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 5220,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 5026,
    UsageInLastPeriod: 194,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 2.0421,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8600,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "South Pontoon - Jetty",
    ReadingInstructions: "NEW METERS",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3606,
    MeterNumber: "041 ID09475c",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 7835,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 7035,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8500,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "South Pontoon - Jetty",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 5.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3607,
    MeterNumber: "07X070556",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 1933,
    LastReadingComments: "replace lid on meter box",
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 1802,
    UsageInLastPeriod: 131,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 1.3789,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8400,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Shift Whitsunday- ctr of shops - r/h pit - in box front centre",
    ReadingInstructions: "NEW METERS",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("07 Jul 2018"),
  },
  {
    MeterId: 3620,
    MeterNumber: "07HB15178",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 1511,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 1382,
    UsageInLastPeriod: 129,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 1.3579,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 200,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "Shute Harbour - Fantasea Jetty",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 50.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("13 Nov 2017"),
  },
  {
    MeterId: 3605,
    MeterNumber: "98618580",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 10840,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 10682,
    UsageInLastPeriod: 158,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 1.6632,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8100,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Lloyd Roberts Jetty - Car park",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Bypass",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3583,
    MeterNumber: "98049061",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 3033,
    LastReadingStatus: "Rechecker",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 2758,
    UsageInLastPeriod: 275,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 2.8947,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7900,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "jetty meter - next to ticket office",
    Category: "Whitsunday Water Usage (W1)",
    Size: 2.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3621,
    MeterNumber: "MX33143",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 474,
    LastReadingStatus: "Rechecker",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 462,
    UsageInLastPeriod: 12,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.1263,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7800,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "SH little pit near ticket office",
    Category: "Whitsunday Water Usage (W1)",
    Size: 25.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3603,
    MeterNumber: "98672605",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 20379,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 20379,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 8000,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location:
      "Lloyd Roberts Jetty - Car park Opposite Store & Freight Building",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 5.0,
    UnitOfMeasure: "Kilolitre",
  },
  {
    MeterId: 3580,
    MeterNumber: "R01002358",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 10463,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 10358,
    UsageInLastPeriod: 105,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 1.1053,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7700,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Whit.Allover East.Pier Bwt Ticket Office/admin",
    Category: "Whitsunday Water Usage (W1)",
    Size: 32.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("13 Jun 2013"),
  },
  {
    MeterId: 3574,
    MeterNumber: "05W888684",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 5006,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 5006,
    UsageInLastPeriod: 0,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.0,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7600,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Lloyd Roberts Jetty - L Hand gardens near water.",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 20.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("18 Jan 2016"),
  },
  {
    MeterId: 3575,
    MeterNumber: "05W911159",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 6619,
    LastReadingStatus: "Read",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 6330,
    UsageInLastPeriod: 289,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 3.0421,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7300,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location:
      "Front of L226/HR887 - Cnr Whitsunday Drive -for sewerage pump stn near water rest",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 2.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("16 May 2016"),
  },
  {
    MeterId: 3598,
    MeterNumber: "03HB07968C",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 14774,
    LastReadingStatus: "Rechecket",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 13689,
    UsageInLastPeriod: 1085,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 11.4211,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7400,
    PropertyAddress: ". Proserpine",
    Status: "Active",
    Location: "Shutehaven Above Top Carpark Next Hydrant at water reservoir",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 5.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("15 Oct 2013"),
  },
  {
    MeterId: 3572,
    MeterNumber: "03A100765",
    LastReadingDateTime: new Date("08 Jun 2020"),
    LastReading: 666,
    LastReadingStatus: "Rechecker",
    PreviousReadingDateTime: new Date("05 Mar 2020"),
    PreviousReading: 628,
    UsageInLastPeriod: 38,
    DaysInLastPeriod: 95,
    DailyUsageInLastPeriod: 0.4,
    LastMeterReader: "WMR1",
    RouteNumber: 6000,
    ReadingSeqInRoute: 7500,
    PropertyAddress: ", Proserpine",
    Status: "Active",
    Location: "Shutehaven Above Top Carpark Next To Hydrant at reservoir",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Bypass",
    Size: 15.0,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("15 Oct 2013"),
  },
];
