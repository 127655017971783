import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { GeneralLedgerPostings } from "@app/products/property/journals/session-list/[id]/components/child-screens/general-ledger-postings/_index";
import { PropertyJournalForm } from "@app/products/property/journals/session-list/[id]/components/child-screens/general/_index";
import { SessionTransactions } from "@app/products/property/journals/session-list/[id]/components/child-screens/transactions/_index";
import { RegenerateSessionButton } from "@app/products/property/journals/session-list/[id]/components/forms/existed/components/action-bar/buttons/regenerate-session/_index";
import { JournalSectionDetailTab } from "@app/products/property/journals/session-list/[id]/components/reference-sidebar/detail/_index";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { CancelPropertySessionButton } from "@app/products/property/journals/session-list/components/action-bar/gl-update/components/buttons/cancel-property-session/_index";
import { UpdateToGeneralLedgerButton } from "@app/products/property/journals/session-list/components/action-bar/gl-update/components/buttons/update-to-general-ledger/_index";
import { eAccordion } from "@app/products/property/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { RollbackSessionButton } from "./components/action-bar/buttons/rollback-session/_index";

export const ExistedPropertyJournal = observer(() => {
  const { isLoading, sessionList, responseLoadError, loadSessionList } =
    useSessionListStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Property - Journal - Session List - ${
        sessionList?.SessionNumber ?? ""
      }`,
      Text: `Session ${sessionList?.SessionNumber ?? ""}`,
      LinkUrl: managePageUrl,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Journal,
      Record_ID: sessionList?.SessionNumber ?? 0,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Session_List_General_Ledger_Postings,
      title: "General Ledger Postings",
      component: <GeneralLedgerPostings />,
    },
    {
      accordionId: eAccordion.Session_List_Transactions,
      title: "Transactions",
      component: <SessionTransactions />,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Session List" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSessionList(+id)}
        />
      ) : (
        <>
          <FormTitle
            title={"Session " + sessionList?.SessionNumber}
            badge={sessionList?.Session_Status}
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CancelPropertySessionButton />
                <RegenerateSessionButton />
                <RollbackSessionButton />
                <UpdateToGeneralLedgerButton />
              </CCNavButton>,
              <CCNavButton title="Add" />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {sessionList && (
                  <CCGeneralPanel component={<PropertyJournalForm />} />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <JournalSectionDetailTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Journal}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
