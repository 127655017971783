import { getRegionDetailsTabById } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/reference-sidebar/detail/api";
import { RegionTabDetails } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const RegionDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [regionInfo, setRegionInfo] = useState<RegionTabDetails>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const regionID = params.id || lastSelectedRow.Region_ID;
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getRegionDetailsTabById(regionID);
    if (!isSuccessResponse(response) || !response.data) {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    } else {
      setRegionInfo(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!regionID) return;
    loadDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Region:"}
          value={regionInfo?.Region_Name}
        />
        <ProductReferenceRow title={"Suburb:"} value={regionInfo?.Suburb} />
        <ProductReferenceRow
          title={"Municipality:"}
          value={regionInfo?.Council_Name}
        />
        <ProductReferenceRow title={"State:"} value={regionInfo?.State} />
        <ProductReferenceRow
          title={"Coordinator:"}
          value={regionInfo?.Coordinator_DisplayName}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
