export const mockCategory = [
  {
    id: "1",
    Name: "Licence to Possess Regulation 25 Poisons",
    Classification: "Designer",
    Code: "https",
    FeeType: "National Configuration Officer",
    Poisons: false,
    PestControl: true,
    Renewable: true,
    MaxRenews: "0",
    SectionOfAct: "SectionOfAct 1",
    SectionOfAct2: "SectionOfAct2 1",
    SectionOfAct3: "SectionOfAct3 1",
    AuthorisedActivities: "AuthorisedActivities 1",
    AuthorisedActivities2: "AuthorisedActivities2 1",
    AuthorisedActivities3: "AuthorisedActivities3 1",
    Notes: "Notes 1",

    TrainingSkills: "TrainingSkills 1",
    PurposeOf: "PurposeOf 1",
    Storage: "Storage 1",
    MaxQuantity: "MaxQuantity 1",
    PersonsApprovedToAccess: "PersonsApprovedToAccess 1",
    Misc1: "Misc1 1",
    Misc2: "Misc2 1",
    Misc3: "43389",
    TheftLoss: true,
    Transport: false,
    VetDrugReport: true,
    PaymentJournal: false,
    CountryHealthNetwork: true,
    WholeSaleReport: true,
    EthicsCommitteeApproval: true,
  },
];
