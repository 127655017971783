import { getDeferredDutyNotices } from "@app/products/property/deferred-duty/[id]/components/child-screens/notices/api";
import { DTO_Notice } from "@app/products/property/deferred-duty/[id]/components/child-screens/notices/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class DeferredDutyNoticesStore {
  private _deferredDutyNotices?: DTO_Notice[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get deferredDutyNotices() {
    return this._deferredDutyNotices;
  }
  setDeferredDutyNotices = (notices?: DTO_Notice[]) => {
    runInAction(() => {
      this._deferredDutyNotices = notices;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._deferredDutyNotices = [];
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadDeferredDutyNotices = async (deferredDutyId: number) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let newDeferredDuty = undefined;
    const response = await getDeferredDutyNotices(deferredDutyId);
    if (isSuccessResponse(response)) {
      newDeferredDuty = response.data?.DeferredDutyNotices;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setDeferredDutyNotices(newDeferredDuty);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
}

const deferredDutyNoticeStoreContext = createContext(
  new DeferredDutyNoticesStore()
);
export const useDeferredDutyNoticesStore = () => {
  return useContext(deferredDutyNoticeStoreContext);
};
