import { ExistManageInvoice } from "@app/core/invoice/[id]/components/forms/existed/_index";
import { NewManageInvoice } from "@app/core/invoice/[id]/components/forms/new/_index";
import { IParentInvoiceSection } from "@app/core/invoice/[id]/model";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageInvoice = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadInvoiceById, resetStore, setParent } = useInvoiceStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { state } = useLocation<{
    parent?: IParentInvoiceSection;
  }>();

  useEffect(() => {
    if (state?.parent) {
      setParent(state?.parent);
    }

    if (isNew) {
      loadInvoiceById(0, isNew);
    } else {
      loadInvoiceById(parseInt(id), isNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, state]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
      window.history.replaceState({}, "");
    };
  });

  if (isNew) {
    return <NewManageInvoice />;
  }

  return <ExistManageInvoice />;
});

export default ManageInvoice;
