import { ExistManageAnimal } from "@app/products/animals/[id]/components/forms/existed/_index";
import { IParentAnimalsRegistrationSection } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useGlobalStore } from "@common/stores/global/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NewManageAnimal } from "./components/forms/new/_index";

export default observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { resetMenu } = useCCSubActionBarStore();
  const { loadAnimalsInfo, resetStore, setParent, checkIsSuperAdmin } =
    useAnimalStore();
  const { currentUserInfo } = useGlobalStore();

  const { state } = useLocation<{
    parent?: IParentAnimalsRegistrationSection;
  }>();

  useEffect(() => {
    if (currentUserInfo?.UserPKID)
      checkIsSuperAdmin(parseInt(currentUserInfo.UserPKID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo?.UserPKID]);

  useEffect(() => {
    if (state?.parent) {
      setParent(state?.parent);
      window.history.replaceState({}, "");
    }

    loadAnimalsInfo(parseInt(id), isNew);
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, loadAnimalsInfo]);

  if (isNew) {
    return <NewManageAnimal />;
  }
  return <ExistManageAnimal />;
});
