import { PPRApplicationIdentityPacket } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const putRejectLetterPlansToComply = async (
  id: number,
  infoInAdequate: boolean
): Promise<APIResponse<PPRApplicationIdentityPacket>> => {
  try {
    const response =
      await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
        `/api/tp/ppr/internal/planstocomply/${id}/reject?infoInAdequate=${infoInAdequate}`
      );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
