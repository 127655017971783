import { GoTrim } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/go-trim";
import { TrimFolderVolumeSearch } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/property-folder-volume-search";
import { TrimApi } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/trim-api";
import { TrimAttactmentDocumentType } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/trim-attachment-document-type";
import { TrimClickOnce } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/trim-click-one";
import { TrimGroupServer } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/trim-work-group-server";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsTrim {
  formRenderProps: FormRenderProps;
}
export const RecordsTrim = observer(({ formRenderProps }: IRecordsTrim) => {
  return (
    <>
      <TrimGroupServer formRenderProps={formRenderProps} />
      <TrimApi formRenderProps={formRenderProps} />
      <GoTrim formRenderProps={formRenderProps} />
      <TrimClickOnce formRenderProps={formRenderProps} />
      <TrimAttactmentDocumentType formRenderProps={formRenderProps} />
      <TrimFolderVolumeSearch formRenderProps={formRenderProps} />
    </>
  );
});
