import { CoreKeyword } from "@common/models/coreKeyword";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { UploadFileInfo } from "@progress/kendo-react-upload";

export interface IAddAttachmentDialog {
  UploadMode?: eUploadMode;
  File?: UploadFileInfo[];
  Title?: string;
  Category?: CoreKeyword;
  WebLink?: string;
  WebLinkCategory?: CoreKeyword;
  Tag?: string;
  DocumentClassification?: IKeyValuePacket;
  ExternalDocumentType?: IKeyValuePacket;
  ExternalDocument?: boolean;
}

export enum eUploadMode {
  UPLOAD_FILE = 1,
  UPLOAD_WEB_LINK = 2,
}
