import { CoreAPIService } from "@common/apis/coreAPIService";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Svc_MailMergeDocumentTemplate } from "@common/pages/settings/lookups/document-templates/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import fileDownload from "js-file-download";

const nameOf = nameOfFactory<Svc_MailMergeDocumentTemplate>();
export const colDocumentTemplate: IColumnFields[] = [
  {
    field: nameOf("DocumentTemplateName"),
    title: "Document Template Name",
    locked: true,
    handleOnClick: (dataItem: Svc_MailMergeDocumentTemplate) => {
      CoreAPIService.getClient()
        .get(`/api/core/internal/mailmerge/document-template`, {
          params: {
            fullPath: dataItem.DocumentFullPath,
          },
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, dataItem.DocumentTemplateName);
        });
    },
  },
  {
    field: nameOf("ProductName"),
    title: "Product",
  },
  {
    field: nameOf("DateModified"),
    title: "Date Modified",
    format: DATE_FORMAT.DATE,
  },
];
