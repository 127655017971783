import { DTO_AssociatedItem_Address } from "@app/products/property/components/associations/components/address/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Address>();

export const colAssociationsAddress: IColumnFields[] = [
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("AddressType"),
    title: "Address Type",
  },
  {
    field: nameOf("IsPrimary"),
    title: "Is Primary",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("LocationDescriptor"),
    title: "Location Descriptor",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
  },
  {
    field: nameOf("MapReference"),
    title: "Map Reference",
  },
  {
    field: nameOf("IsRuralHouseNumber"),
    title: "Is Rural House Number",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
