import {
  apiGetABNDetails,
  apiGetContactAddressBook,
  apiPickContactFromNAR,
} from "@app/core/components/common/utils";
import { AddressBook, Svc_Contact } from "@app/core/contacts/_id/model";
import {
  ABNChecker_Request,
  ABNMetadata,
  Svc_Contact_PickContactNAR_Request,
} from "@app/core/contacts/components/dialogs/contacts-from-nar/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { AddressBookSection } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IIdentityPacket } from "@common/models/identityPacket";

export const postPickContactFromNar = async (
  pickContactNARRequest: Svc_Contact_PickContactNAR_Request
): Promise<APIResponse<IIdentityPacket<Svc_Contact> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<Svc_Contact>>(
      apiPickContactFromNAR(),
      pickContactNARRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAddressBook = async (
  productType: PRODUCT_TYPE_NUMBER,
  addressBookSection: AddressBookSection
): Promise<APIResponse<AddressBook | null>> => {
  try {
    return await CoreAPIService.getClient().get<AddressBook>(
      apiGetContactAddressBook(),
      {
        params: {
          productType: productType,
          addresBookSection: addressBookSection,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postABNDetails = async (
  checkABNRequest: ABNChecker_Request
): Promise<APIResponse<ABNMetadata | null>> => {
  try {
    return await CoreAPIService.getClient().post<ABNMetadata>(
      apiGetABNDetails(),
      checkABNRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
