import { ILodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/model";
import { LodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/_index";
import { putPASRChangeLodgeDate } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/change-lodged-date/api";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PSARChangedLodgedDateButton = observer(() => {
  const { pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();

  const handleOnSubmit = async (data: ILodgeApplicationDialog) => {
    if (!pprId || !data?.DateLodged) return;
    setIsLoading(true);
    const response = await putPASRChangeLodgeDate(pprId, data?.DateLodged);
    setIsLoading(false);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      clearErrorNotification();
      setPPRWithLoading(response?.data?.Application);
      pushNotification({
        type: "success",
        title: "Lodged date changed successfully.",
      });
    } else {
      pushNotification({
        autoClose: false,
        title: "Change lodged date failed",
        type: "error",
        description: response.data?.Errors,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Change lodged date"
        isLoading={isLoading}
        onClick={() => {
          setIsShowDialog(true);
        }}
      />
      {isShowDialog && (
        <LodgeApplicationDialog
          isLoading={isLoading}
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleOnSubmit}
          initDate={null}
        />
      )}
    </>
  );
});
