import { CCTooltip } from "@components/cc-tooltip/_index";
import { Error } from "@progress/kendo-react-labels";
import React from "react";
import "./_index.scss";

interface ICCLabelProps {
  title: string;
  isMandatory?: boolean;
  informationTooltip?: string;
  customTooltip?: JSX.Element | null;
  errorMessage?: string;
  isLoading?: boolean;
}

export const CCLabel = ({ customTooltip = null, ...props }: ICCLabelProps) => {
  return (
    <span className={`${props.errorMessage ? "cc-label-container" : ""}`}>
      <label className="cc-label">
        {props.title}
        {props.isMandatory && <CCTooltip type="validator" position="right" />}
        {props.informationTooltip && (
          <CCTooltip
            type="info"
            position="right"
            content={props.informationTooltip}
          />
        )}
        {customTooltip}
        {props?.isLoading ? (
          <span className="cc-icon-loading-free">
            <i className="fas fa-spinner fa-spin" />
          </span>
        ) : null}
      </label>
      {props.errorMessage && <Error>{props.errorMessage}</Error>}
    </span>
  );
};
