import { SettingFolder } from "@app/products/property/system-admin/settings/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { GET_CATEGORY_SETTINGS } from "@common/configs/setting";
import { SettingUrl } from "@common/pages/settings/constant";

export const putSetting = async (requestBody: SettingFolder) => {
  try {
    const response = await CoreAPIService.getClient().put(
      `${SettingUrl.UPDATE_SETTING_MANAGE_FORM}`,
      requestBody
    );
    return response;
  } catch (error) {
    const errorResponse: any = error;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSetting = async (requestBody: SettingFolder) => {
  try {
    const response = await CoreAPIService.getClient().post(
      `${SettingUrl.UPDATE_SETTING_MANAGE_FORM}`,
      requestBody
    );
    return response;
  } catch (error) {
    const errorResponse: any = error;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const deleteSetting = async (requestBody: SettingFolder) => {
  const response = await CoreAPIService.getClient().delete(
    `${SettingUrl.UPDATE_SETTING_MANAGE_FORM}`,
    { data: requestBody }
  );
  return response;
};

export const cloneSetting = async (url: any) => {
  return CoreAPIService.getClient().get(`${GET_CATEGORY_SETTINGS}${url}`);
};

export const getCategorySettings = async (url: string) => {
  try {
    return await CoreAPIService.getClient().get(
      `${GET_CATEGORY_SETTINGS}${url}`
    );
  } catch (error) {
    const errorResponse: any = error;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
