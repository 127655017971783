import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colProperty: IColumnFields[] = [
  {
    field: "Locality",
    title: "Locality",
  },
  {
    field: "Street",
    title: "Street",
  },
  {
    field: "StreetType",
    title: "Street Type",
  },
  {
    field: "HouseNumber",
    title: "House Number",
  },
  {
    field: "PropertyName",
    title: "Property Name",
  },
  {
    field: "Owner",
    title: "Owner",
  },
  {
    field: "OwnerAddress",
    title: "Owner Address",
  },
  {
    field: "Ratepayer",
    title: "Ratepayer",
  },
  {
    field: "RatepayerAddress",
    title: "Ratepayer Address",
  },
  {
    field: "LandArea",
    title: "Land Area",
  },
  {
    field: "LegalDescription",
    title: "Legal Description",
  },
  {
    field: "Clearance",
    title: "Clearance",
  },
  {
    field: "Outcome",
    title: "Outcome",
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },

  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const comboboxSearchData = {
  _option: {
    HouseNumber: {
      Data: [],
      Loading: false,
    },
    Locality: {
      Data: [],
      Loading: false,
    },
    Street: {
      Data: [],
      Loading: false,
    },
    Owner: {
      Data: [],
      Loading: false,
    },
    OwnerAddress: {
      Data: [],
      Loading: false,
    },
    AssessmentNumber: {
      Data: [],
      Loading: false,
    },
    RatepayerAddress: {
      Data: [],
      Loading: false,
    },
    Ratepayer: {
      Data: [],
      Loading: false,
    },
    ParcelId: {
      Data: [],
      Loading: false,
    },
  },
};
