import { SchemeAccountForm } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/general/_index";
import { useSchemesAccountStore } from "@app/products/property/schemes/[id]/account/[id]/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewPropertySchemeAccount = observer(() => {
  const { isLoading } = useSchemesAccountStore();

  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="Scheme Account" />
      <FormTitle title="New Account" />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Allocate to planner"} />
            <CCNavButton title={"Failure"} />
            <CCNavButton title={"Revision received"} />
            <CCNavButton title={"Withdraw application"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <CCNavButton title={"Add actions"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<SchemeAccountForm />} />
          </div>
        </div>
      </div>
    </>
  );
});
