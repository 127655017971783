export enum CriteriaAdvancedSearch {
  ASSESSMENT = "Assessment",
  NAME = "Name",
  PARCEL = "Parcel",
  TITLE = "Title",
  ASSESSMENT_TRANSACTION = "Assessment Transaction",
  ARRANGEMENT = "Arrangement",
  CERTIFICATE = "Certificate",
  CHANGE_OF_OWNERSHIP = "Change Of Ownership",
  CHARGE = "Charge",
  DEBT_RECOVERY = "Debt Recovery",
  ENTITY = "Entity",
  LEVY = "Levy",
  MASTER_PROPERTY = "Master Property",
  REBATE_ENTITLEMENT = "Rebate Entitlements",
  SUPPLEMENTARY = "Supplementary",
}
