//filter= SDateTime gt 2014-06-23T00:00:00.000Z and SDateTime lt 2014-06-23T23:59:59.900Z
export function getFromDateString(date: Date): string {
  const newDate: Date = new Date(date);
  const outDate =
    newDate.getFullYear() +
    "-" +
    (1 + newDate.getMonth()) +
    "-" +
    newDate.getDate();
  return outDate;
}

export function getToDateString(date: Date): string {
  const newDate: Date = new Date(date);
  const outDate =
    newDate.getFullYear() +
    "-" +
    (1 + newDate.getMonth()) +
    "-" +
    (newDate.getDate() + 1);
  return outDate;
}

// function getFromDateTimeString(date: Date) : string {
//   const newDate : Date = new Date(date);
//   const outDate = newDate.getFullYear() + "-" + (1 + newDate.getMonth()) + "-" + (newDate.getDate() - 1) + "T00:00:00.000Z"
//   return outDate;
// }

// function getToDateTimeString(date: Date) : string {
// const newDate : Date = new Date(date);
// const outDate = newDate.getFullYear() + "-" + (1 + newDate.getMonth()) + "-" + newDate.getDate() + "T23:59:59.900Z"
// return outDate;
// }

//Set time to midnight
export function getFromDate(date: Date): Date {
  const newDate: Date = new Date(date);
  //Date is coming from a string. Actual day of month in Date type is zero based. (eg. 0 = 1st, 1 = 2nd). Subtract a day to get real date.
  newDate.setDate(newDate.getDate());
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

//Set time to 11.59.59PM
export function getToDate(date: Date): Date {
  const newDate: Date = new Date(date);
  //Date is coming from a string. Actual day of month in Date type is zero based. (eg. 0 = 1st, 1 = 2nd). Subtract a day to get real date.
  newDate.setDate(newDate.getDate());
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

export function getToDateReverse(date: Date): Date {
  const newDate: Date = new Date(date);
  //Date is coming from a string. Actual day of month in Date type is zero based. (eg. 0 = 1st, 1 = 2nd). Subtract a day to get real date.
  newDate.setDate(newDate.getDate() - 1);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

// function getSameDayOdataFilterString(fieldName: string, date: Date) : string {
//   //return fieldName + " gt " + getFromDateString(date) + " and " + fieldName + " lt " + getToDateString(date);
//   return getFromDateString(date) + " and " + fieldName + " lte " + getToDateString(date);
// }

// function getDateRangeOdateFilterString(fieldName: string, fromDate: Date, toDate: Date) : string {
//   if (fromDate && toDate)
//     return fieldName + " gte " + getFromDateString(fromDate) + " and " + fieldName + " lt " + getToDateString(toDate);
//   else
//     return "";
// }

export function newMinDate(): Date {
  return new Date(0);
}

export function newTodayDate(): Date {
  return new Date();
}

//Get date without time
export function simpleDate(date: Date): Date {
  let inDate: Date;
  if (Object.prototype.toString.call(date) === "[object Date]") {
    inDate = date;
  } else {
    //date might be passed in as a string...like...whaaaaat? So create a date to use the date functions.
    inDate = new Date(date);
  }
  //Note: Month is zero based! so 1/1/2020 = 1-Feb-2020
  let outDate = new Date(
    inDate.getFullYear(),
    inDate.getMonth(),
    inDate.getDate(),
    0,
    0,
    0,
    0
  );
  return outDate;
}

export function getFormattedDate(date?: Date | null): string {
  let inDate: Date;
  if (!date) return "";
  if (Object.prototype.toString.call(date) === "[object Date]") {
    inDate = date;
  } else {
    //date might be passed in as a string...like...whaaaaat? So create a date to use the date functions.
    inDate = new Date(date);
  }
  var year = inDate.getFullYear();

  var month = (1 + inDate.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = inDate.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return day + "/" + month + "/" + year;
}
