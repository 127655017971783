import { AnimalTypeForm } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/_index";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const NewAnimalTypeComponent = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { isLoading, responseLoadError, onSubmit, loadAnimalType, animalType } =
    useAnimalTypeStore();

  useEffectOnce(() => {
    loadAnimalType(parseInt(id), isNew);
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Animal Type" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAnimalType(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title={"New Animal Type"} />
          <CCManagePageActionBar
            leftActions={[
              // TODO: implement when having task for button
              // <CCNavButton
              //   title={"Workflow"}
              //   type="sub-workflow"
              //   className="cc-workflow-button"
              // >
              //   <CCNavButton title={"Publish to web"} />
              // </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {animalType && <AnimalTypeForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewAnimalTypeComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.Animals },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.Animals_Form_AnimalType,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewAnimalType = renderWithPermissionsWrapper(renderProps);
