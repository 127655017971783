import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { LocalGovernment } from "@common/pages/settings/lookups/local-government/model";

export const getLocalGovernmentById = async (
  localGovernmentId: number
): Promise<APIResponse<LocalGovernment | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<LocalGovernment>(
      `api/core/internal/local-government/${localGovernmentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postLocalGovernment = async (
  requestBody: LocalGovernment
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<LocalGovernment>(
      `api/core/internal/local-government`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
