import NewSupplementaryDialog from "@app/products/property/supplementary-rates/[id]/components/dialogs/new-supplementary/_index";
import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { ISupplementaryRatesFormData } from "@app/products/property/supplementary-rates/[id]/model";
import React from "react";
import { useHistory } from "react-router";

export const NewSupplementaryRates = () => {
  const history = useHistory();
  return (
    <NewSupplementaryDialog
      onClose={() => {
        history.goBack();
      }}
      onSubmit={(supplementary: ISupplementaryRatesFormData) => {
        history.replace(
          `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/` + 9999,
          supplementary
        );
      }}
    />
  );
};
