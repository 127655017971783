import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { getSupplementaryRatesSummaryById } from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/api";
import {
  colLevyAdjustments,
  colLevySummary,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/config";
import { DTO_Supplementary_Summary } from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SupplementaryRatesGeneralStore {
  private _supplementaryRates?: DTO_Supplementary_Summary = undefined;
  private _processedColLevySummary: IColumnFields[] = [];
  private _processedColLevyAdjustments: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get supplementaryRates() {
    return this._supplementaryRates;
  }
  setSupplementaryRates = (supplementaryRates?: DTO_Supplementary_Summary) => {
    runInAction(() => {
      this._supplementaryRates = supplementaryRates;
    });
  };

  get processedColLevySummary() {
    return this._processedColLevySummary;
  }
  setProcessedColLevySummary = (processedColLevySummary?: IColumnFields[]) => {
    runInAction(() => {
      this._processedColLevySummary = processedColLevySummary ?? [];
    });
  };

  get processedColLevyAdjustments() {
    return this._processedColLevyAdjustments;
  }
  setProcessedColLevyAdjustments = (
    processedColLevyAdjustments?: IColumnFields[]
  ) => {
    runInAction(() => {
      this._processedColLevyAdjustments = processedColLevyAdjustments ?? [];
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._supplementaryRates = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadSupplementaryRates = async (supplementaryRatesId: number) => {
    this.setIsLoading(true);

    const [
      responseSummary,
      responseLevySummaryConfig,
      responseLevyAdjustmentsConfig,
    ] = await Promise.all([
      getSupplementaryRatesSummaryById(supplementaryRatesId),
      loadViewConfiguresColumns(
        ViewConfiguration.Supplementary_LevySummary,
        colLevySummary
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.Supplementary_LevyCalculationSummary,
        colLevyAdjustments
      ),
    ]);

    let errorResponse = undefined;
    let newSupplementaryRates = undefined;
    let newColLevySummary = undefined;
    let newColLevyAdjustments = undefined;
    if (
      isSuccessResponse(responseSummary) &&
      Array.isArray(responseLevySummaryConfig) &&
      Array.isArray(responseLevyAdjustmentsConfig)
    ) {
      newSupplementaryRates = responseSummary.data;
      newColLevySummary = responseLevySummaryConfig;
      newColLevyAdjustments = responseLevyAdjustmentsConfig;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setSupplementaryRates(newSupplementaryRates);
    this.setProcessedColLevySummary(newColLevySummary);
    this.setProcessedColLevyAdjustments(newColLevyAdjustments);
    this.setIsLoading(false);
  };
}

const supplementaryRatesGeneralStoreContext = createContext(
  new SupplementaryRatesGeneralStore()
);
export const useSupplementaryRatesGeneralStore = () => {
  return useContext(supplementaryRatesGeneralStoreContext);
};
