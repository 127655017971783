import { ICCCheckboxGroupItem } from "@components/cc-checkbox-group/model";
import { isChecked } from "@components/cc-checkbox-group/util";
import { FieldProps } from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { isArray, map } from "lodash";
import React, { useMemo } from "react";

interface ICCCheckboxGroupProps {
  data: ICCCheckboxGroupItem[];
  disabled: boolean;
  onDataChange: (data: ICCCheckboxGroupItem[]) => void;
}

export const CCCheckboxGroup = ({
  value = null,
  disabled = false,
  className = "",
  data,
  onDataChange,
  ...others
}: ICCCheckboxGroupProps & FieldProps) => {
  const checkedValues = useMemo(() => {
    if (isArray(value)) return value;
    return [];
  }, [value]);

  const onChangeHandler = (event: CheckboxChangeEvent) => {
    const newCheckedItem = {
      value: event?.value,
      label: event?.target?.name ?? "",
    };

    const newCheckedValues = checkedValues.filter(
      (checkedItem: ICCCheckboxGroupItem) =>
        checkedItem.label !== newCheckedItem.label
    );
    if (newCheckedItem.value) {
      onDataChange([newCheckedItem, ...newCheckedValues]);
    } else {
      onDataChange([...newCheckedValues]);
    }
  };

  return (
    <div
      className={`cc-checkbox-group ${className}`}
      key={JSON.stringify(data)}
    >
      {map(data, (dataItem: ICCCheckboxGroupItem) => {
        return (
          <div className="cc-field" key={dataItem.label}>
            <Checkbox
              {...others}
              name={dataItem.label}
              label={dataItem.label}
              value={dataItem.value}
              checked={isChecked(checkedValues, dataItem)}
              disabled={disabled}
              onChange={onChangeHandler}
            />
          </div>
        );
      })}
    </div>
  );
};
