import { getParcelClassification } from "@app/products/property/parcels/[id]/components/child-screens/land-uses/api";
import { DTO_Parcel_Classification } from "@app/products/property/parcels/[id]/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ParcelClassificationStore {
  private _classification?: DTO_Parcel_Classification = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get classification() {
    return toJS(this._classification);
  }

  setClassification = (classification?: DTO_Parcel_Classification) => {
    runInAction(() => {
      this._classification = classification;
    });
  };

  //Action
  resetStore = () => {
    this.setClassification(undefined);
    this.setIsLoading(false);
  };

  loadParcelClassification = async (parcelId: number) => {
    this.setIsLoading(true);
    const newClassification = await getParcelClassification(parcelId);
    this.setClassification(newClassification);
    this.setIsLoading(false);
  };
}

const parcelClassificationStoreContext = createContext(
  new ParcelClassificationStore()
);
export const useParcelClassificationStore = () => {
  return useContext(parcelClassificationStoreContext);
};
