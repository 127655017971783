import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import { getStringValueSetting } from "@common/stores/products/util";
import { convertToTitleCase } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

export const generateHMPremisesAlertsColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  // Column Modifiers
  const columnDebtorNumberModifier = (column: IColumnFields) => {
    const debtorNumberLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );
    const title = debtorNumberLabel
      ? convertToTitleCase(debtorNumberLabel)
      : column.title;
    return { ...column, title };
  };

  const columnMapper = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("DebtorNo"):
        return columnDebtorNumberModifier(column);
      default:
        return column;
    }
  };

  return cloneDeep(defaultConfig).map(columnMapper);
};
