export enum PropertyRoute {
  PROPERTY_ASSESSMENTS = "/property/assessments",
  PROPERTY_ASSESSMENTS_LIST = "/property/assessments/list",
  PROPERTY_ASSESSMENTS_REGISTER = "/property/assessments/register",
  PROPERTY_ASSESSMENTS_ARRANGEMENTS = "/property/assessments/arrangements",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_EXEMPTIONS = "/property/assessments/debtrecoveryexemptions",
  PROPERTY_ASSESSMENTS_DEFERMENTS = "/property/assessments/deferments",
  PROPERTY_ASSESSMENTS_MAILBOXLIST = "/property/assessments/mailboxlist",
  PROPERTY_ASSESSMENTS_MAILBOXLIST_ALL = "mailboxlist/all",
  PROPERTY_ASSESSMENTS_MASTERPROPERTIES = "/property/assessments/masterproperties",
  PROPERTY_ASSESSMENTS_FINANCIALSUMMARIES = "/property/assessments/financial-summaries",
  PROPERTY_ASSESSMENTS_FOOTPRINTS = "/property/assessments/footprints",

  PROPERTY_ASSESSMENTS_PENSIONERSORREBATES = "/property/assessments/pensionersorrebates",
  PROPERTY_ASSESSMENTS_PENSIONERSORREBATES_LIST = "pensionersorrebates/listrebates",
  PROPERTY_ASSESSMENTS_PENSIONERSORREBATES_CLAIMS = "pensionersorrebates/rebatesclaims",
  PROPERTY_ASSESSMENTS_PENSIONERSORREBATES_CONCESSION_CARDS = "pensionersorrebates/concession-cards",
  PROPERTY_ASSESSMENTS_REBATES_CLAIMS = "/property/assessments/rebatesclaims",

  PROPERTY_ASSESSMENTS_DEBTRECOVERY = "/property/assessments/debt-recovery",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_CANDIDATES_VIEW = "debt-recovery/candidates",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_LIST_ALL = "debtrecovery/debtrecoverylist/all",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_PAYMENTSRECEIVED_ALL = "debtrecovery/payments/all",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_PAYMENTSRECEIVED_LAST = "debtrecovery/payments/last",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_ALLACTIONSANDLETTERS = "debtrecovery/actionsletters/allactionsandletters",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_ALLACTIONSANDLETTERSFORACTIVEDEBTRECOVERIES = "debtrecovery/actionsletters/allactionsandlettersforactivedebtrecoveries",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_LETTERSTHATHAVEBEENISSUED = "debtrecovery/actionsletters/lettersthathavebeenissued",
  PROPERTY_ASSESSMENTS_DEBTRECOVERY_LETTERSTOBEISSUEDFORACTIVEDEBTRECOVERIES = "debtrecovery/actionsletters/letterstobeissuedforactivedebtrecoveries",

  PROPERTY_ASSESSMENTS_FOOTPRINTS_ALL = "footprints/all",
  PROPERTY_ASSESSMENTS_FOOTPRINTS_FOOTPRINTSBYACCOUNTNUMBER = "footprints/footprintsbyaccountnumber",
  PROPERTY_ASSESSMENTS_FOOTPRINTS_LASTACCESSED = "footprints/lastaccessed",
  PROPERTY_ASSESSMENTS_FOOTPRINTS_MYFOOTPRINTS = "footprints/myfootprints",
  PROPERTY_ASSESSMENTS_FOOTPRINTS_MYFOOTPRINTSBYACCOUNTNUMBER = "footprints/myfootprintsbyaccountnumber",
  PROPERTY_ASSESSMENTS_FOOTPRINTS_MYLASTACCESSED = "footprints/mylastaccessed",

  PROPERTY_ASSESSMENTS_ARRANGEMENTS_LETTERS_PRODUCED_VIEW = "arrangements/letters-produced",
  PROPERTY_ASSESSMENTS_ARRANGEMENTS_LIST_ACTIVE_VIEW = "arrangements/list/active",
  PROPERTY_ASSESSMENTS_ARRANGEMENTS_LIST_ALL_VIEW = "arrangements/list/all",
  PROPERTY_ASSESSMENTS_ARRANGEMENTS_LIST_INSTALMENT_DUE_VIEW = "arrangements/list/instalmentdue",

  PROPERTY_PARCELS_REGISTER = "/property/parcels/register",
  PROPERTY_PARCELS_LOCALITYORSTREETORADDRESS = "/property/parcels/localityorstreetoraddress",
  PROPERTY_PARCELS_SEARCHES = "/property/parcels/searches",
  PROPERTY_PARCELS_FOOTPRINT = "/property/parcels/footprints",
  PROPERTY_PARCELS_LISTS = "/property/parcels/lists",

  PROPERTY_TITLES_REGISTER = "/property/titles/register",
  PROPERTY_TITLES_SEARCHES = "/property/titles/searches",
  PROPERTY_TITLES_FOOTPRINT = "/property/titles/footprints",
  PROPERTY_TITLES_LISTS = "/property/titles/lists",

  //001
  PROPERTY_SUPPLEMENTARYRATES_LIST = "/property/supplementaryrates/slist",
  PROPERTY_SUPPLEMENTARYRATES_VALUATIONSBYGROUP = "/property/supplementaryrates/valuationsbygroup",

  PROPERTY_SUPPLEMENTARYRATES_LIST_ALL = "slist/all",
  PROPERTY_SUPPLEMENTARYRATES_LIST_CREATEDBYME = "slist/createdbyme",
  PROPERTY_SUPPLEMENTARYRATES_LIST_GROUPBYRATINGPERIOD = "slist/groupbyratingperiod",

  PROPERTY_SCHEMES_REGISTER = "/property/schemes/register",
  PROPERTY_SCHEMES_PROCESSES = "/property/schemes/processes",
  PROPERTY_SCHEMES_NOTICERUNS = "/property/schemes/noticeruns",

  PROPERTY_METERS_REGISTER = "/property/meters/register",
  PROPERTY_METERS_PROCESSES = "/property/meters/processes",
  PROPERTY_METERS_METERREADINGDEVICES = "/property/meters/meterreadingdevices",
  PROPERTY_METERS_METERREADINGROUTES = "/property/meters/meterreadingroutes",

  PROPERTY_JOURNALS_LIST = "/property/journals/list",
  PROPERTY_JOURNALS_REGISTER = "/property/journals/register",
  PROPERTY_JOURNALS_MYJOURNAL = "/property/journals/myjournal",

  PROPERTY_CHANGESOFOWNERSHIP_FULLTRANSFEROFOWNERSHIP = "/property/changesofownership/fulltransferofownership",
  PROPERTY_CHANGESOFOWNERSHIP_PARTIALTRANSFEROFOWNERSHIP = "/property/changesofownership/partialtransferofownership",
  PROPERTY_CHANGESOFOWNERSHIP_CHANGEOFNAMEORADDRESS = "/property/changesofownership/changeofnameoraddress",
  PROPERTY_CHANGESOFOWNERSHIP_CHANGEOFASSOCIATEDNAMEORADDRESS = "/property/changesofownership/changeofassociatednameoraddress",

  PROPERTY_ENTITIES_ENTITYLIST = "/property/entities/entitylist",
  PROPERTY_ENTITIES_VOTERSLIST = "/property/entities/voterslist",
  PROPERTY_ENTITIES_POTENTIALDUPLICATEENTITIES = "/property/entities/potentialduplicateentities",

  PROPERTY_CERTIFICATES = "/property/certificates",
  PROPERTY_CERTIFICATES_LIST = "/property/certificates/list",
  PROPERTY_CERTIFICATES_BY_TYPE = "/property/certificates/bytype",
  PROPERTY_CERTIFICATES_ACTIVECERTIFICATESBYRESPAREA = "/property/certificates/activecertificatesbyresparea",
  PROPERTY_CERTIFICATES_ECERTIFICATEREQUESTS = "/property/certificates/ecertificaterequests",
  PROPERTY_CERTIFICATES_ECERTIFICATEREQUESTS_INERROR = "ecertificaterequests/inerror",
  PROPERTY_CERTIFICATES_ECERTIFICATEREQUESTS_INPROGRESS = "ecertificaterequests/inprogress",
  PROPERTY_CERTIFICATES_ECERTIFICATEREQUESTS_COMPLETED = "ecertificaterequests/completed",
  PROPERTY_CERTIFICATES_ECERTIFICATEREQUESTS_CANCELLED = "ecertificaterequests/cancelled",
  PROPERTY_CERTIFICATES_FOOTPRINTS = "/property/certificates/footprints",

  PROPERTY_CERTIFICATES_FOOTPRINTS_ALL = "footprints/all",
  PROPERTY_CERTIFICATES_FOOTPRINTS_FOOTPRINTSBYACCOUNTNUMBER = "footprints/footprintsbyaccountnumber",
  PROPERTY_CERTIFICATES_FOOTPRINTS_LASTACCESSED = "footprints/lastaccessed",
  PROPERTY_CERTIFICATES_FOOTPRINTS_MYFOOTPRINTS = "footprints/myfootprints",
  PROPERTY_CERTIFICATES_FOOTPRINTS_MYFOOTPRINTSBYACCOUNTNUMBER = "footprints/myfootprintsbyaccountnumber",
  PROPERTY_CERTIFICATES_FOOTPRINTS_MYLASTACCESSED = "footprints/mylastaccessed",

  PROPERTY_TASKS_TASKLIST = "/property/tasks/tasklist",
  PROPERTY_TASKS_TASKACTIONLETTERS = "/property/tasks/taskactionletters",

  PROPERTY_REGISTERS_DUMMY1 = "/property/registers/dummy1",
  PROPERTY_REGISTERS_DUMMY2 = "/property/registers/dummy2",
  PROPERTY_REGISTERS_DUMMY3 = "/property/registers/dummy3",
  PROPERTY_REGISTERS_DUMMY4 = "/property/registers/dummy4",

  PROPERTY_COLLECTIONS_DUMMY1 = "/property/collections/dummy1",
  PROPERTY_COLLECTIONS_DUMMY2 = "/property/collections/dummy2",
  PROPERTY_COLLECTIONS_DUMMY3 = "/property/collections/dummy3",
  PROPERTY_COLLECTIONS_DUMMY4 = "/property/collections/dummy4",

  PROPERTY_REPORTS_DUMMY1 = "/property/reports/dummy1",
  PROPERTY_REPORTS_DUMMY2 = "/property/reports/dummy2",
  PROPERTY_REPORTS_DUMMY3 = "/property/reports/dummy3",
  PROPERTY_REPORTS_DUMMY4 = "/property/reports/dummy4",

  PROPERTY_NOTICEGROUPSPROCESSES_DUMMY1 = "/property/noticegroupsprocesses/dummy1",
  PROPERTY_NOTICEGROUPSPROCESSES_DUMMY2 = "/property/noticegroupsprocesses/dummy2",
  PROPERTY_NOTICEGROUPSPROCESSES_DUMMY3 = "/property/noticegroupsprocesses/dummy3",
  PROPERTY_NOTICEGROUPSPROCESSES_DUMMY4 = "/property/noticegroupsprocesses/dummy4",

  PROPERTY_AUDITHISTORY_DUMMY1 = "/property/audithistory/dummy1",
  PROPERTY_AUDITHISTORY_DUMMY2 = "/property/audithistory/dummy2",
  PROPERTY_AUDITHISTORY_DUMMY3 = "/property/audithistory/dummy3",
  PROPERTY_AUDITHISTORY_DUMMY4 = "/property/audithistory/dummy4",

  PROPERTY_TASKSCHEDULELIST_DUMMY1 = "/property/taskschedulelist/dummy1",
  PROPERTY_TASKSCHEDULELIST_DUMMY2 = "/property/taskschedulelist/dummy2",
  PROPERTY_TASKSCHEDULELIST_DUMMY3 = "/property/taskschedulelist/dummy3",
  PROPERTY_TASKSCHEDULELIST_DUMMY4 = "/property/taskschedulelist/dummy4",

  PROPERTY_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/property/assessment",
  PROPERTY_ASSESSMENTS_DETAILS = "/settings/systemadmin/property",
}
