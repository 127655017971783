import { Invoice } from "@app/core/invoice/[id]/model";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { InvoiceFormElement } from "./components/invoice-form-element/_index";

export const InvoiceForm = observer(() => {
  const { invoice, saveInvoice, setOnSubmit, setIsShowInvoiceItemDialog } =
    useInvoiceStore();
  const { pushNotification } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = async (submitEvent: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = submitEvent;
    const actionSubmit = submitEvent.event?.currentTarget
      .name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    if (actionSubmit === ActionSubmitActions.AddInvoiceItem) {
      setIsShowInvoiceItemDialog(true);
    }

    if (actionSubmit === ActionSubmitActions.Save)
      saveInvoice(values as Invoice, isNew);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, invoice]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={invoice}
        key={JSON.stringify(invoice)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <InvoiceFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
