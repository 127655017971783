import { eventEmitter } from "@/App";
import { odataCoreGetSamples } from "@app/core/components/common/utils";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { colSamples } from "@app/core/samples/config";
import { Svc_SamplesAccordion } from "@app/core/samples/model";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ISamplesAccordionProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<Svc_SamplesAccordion>();
export const SamplesAccordion = observer(
  ({ id, recordType }: ISamplesAccordionProps) => {
    const [gridSelectedRows, setGridSelectedRows] = useState<
      Svc_SamplesAccordion[]
    >([]);
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { isDisabled } = useTabTableStore();

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );

    const handleConfirmDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const response = await deleteRefUsageByIdAndRecordType(
        gridSelectedRows?.map((item: Svc_SamplesAccordion) => item.ID),
        RECORDTYPE.CORE_Sample,
        data?.Reason
      );
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        setShowConfirmDeleteDialog(false);
        clearErrorNotification();
        setGridSelectedRows([]);
        pushNotification({
          title: "Sample deleted successfully.",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Sample delete failed.",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    return (
      <>
        <CCGrid
          columnFields={colSamples}
          primaryField={nameOf("ID")}
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  isDisabled
                    ? isDisabled
                    : gridSelectedRows.length !== 1 || isDeleting
                }
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
              />
            </div>
          }
          dataUrl={
            id === undefined ? undefined : odataCoreGetSamples(id, recordType)
          }
          selectableMode="multiple"
          onSelectionChange={(dataItem: Svc_SamplesAccordion[]) => {
            setGridSelectedRows(dataItem ?? []);
          }}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => {
              setShowConfirmDeleteDialog(false);
            }}
            contentDelete={"Sample"}
            handleSubmit={handleConfirmDelete}
            isDeleting={isDeleting}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
