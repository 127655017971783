import { AllocateToOfficerButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/allocate-to-officer/_index";
import { FiNotRequiredButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/fi-not-required/_index";
import { LodgeApplicationButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/lodge-application/_index";
import { WithDrawApplicationButton } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/withdraw-application/_index";
import { PSARInfoForm } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewPSARInfo = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { isLoading, responseLoadError, onSubmit, loadPPR } = usePSARStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"PSAR Info"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPPR(parseInt(id), isNew, true)}
        />
      ) : (
        <>
          <FormTitle title={"New Application"} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <LodgeApplicationButton />
                <AllocateToOfficerButton />
                <WithDrawApplicationButton />
                <FiNotRequiredButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={PSARSubmitActions.New}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <PSARInfoForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
