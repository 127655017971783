import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import {
  BubbleUpIdentifier,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionType } from "@common/models/actionType";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import {
  ACTIONSTATUS,
  ACTIONWORKFLOW,
  ASSESSORCONTACTTYPE,
  IMailMergeIdentifier,
} from "@common/pages/actions/[id]/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export type ActionParentActions = PPRSubmitActions.RequestAction;

export interface IParentActionSection {
  id: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
  data?: Action;
  parentAction?: ActionParentActions;
  notification?: IAppNotificationItemAddProps[];
  actionType?: IRespActionType;
}

export interface Action extends DTOBaseClass_Standard {
  Legacy_LID?: number | null;
  Action_ID: number;
  Description: string;
  PlainTextDescription: string;
  ClosureComment: string;
  ActionRequired: boolean;
  ActionType: ActionType;
  ActionType_ID: number;
  DateRecorded: Date;
  DateTarget?: Date;
  DateCompleted?: Date;
  DateSent?: Date;
  Date_AgendaViewing?: Date;
  Date_NoticeLettersSent?: Date;
  Councillors: string;
  RecordedBy?: SiteUser;
  ActionOfficer?: SiteUser;
  CompletedBy?: SiteUser;
  RecordedBy_ID: number;
  ActionOfficer_ID: number | null;
  CompletedBy_ID: number | null;
  Status_ENUM: ACTIONSTATUS;
  Approver: SiteUser;
  Approver_ID?: number | null;
  Delegate: SiteUser;
  Delegate_ID?: number | null;
  PanelRequested?: string | null;
  DirectionsHearing?: string | null;
  ExpertWitnessReportsDue?: string | null;
  PanelSubmissionPartADue?: string | null;
  PanelHearingFrom?: string | null;
  PanelHearingTo?: string | null;
  PanelVenue: string;
  OutcomeNotes: string;
  NoShowCause: string;
  Status_Name: string;
  TimeTakenHours: number;
  TimeTakenMinutes: number;
  ActionOutcome_KWD?: number | null;
  ActionOutcome: string;
  ExternalActionOfficer: Contact_Relation;
  MyParentContactRIDs: number[];
  ExternalActionOfficer_RID?: number | null;
  ReferralTrigger_Keyword: IKeyValuePacket[];
  ReferralTrigger_CSV: string;
  ReferralTrigger_Description: string;
  InfoAttached_Keyword: IKeyValuePacket[];
  InfoAttached_CSV: string;
  Policy_Keyword: IKeyValuePacket[];
  Policy_CSV: string;
  Workflow_ENUM?: ACTIONWORKFLOW | null;
  Workflow_Name: string;
  IssuedBy_Council_ID?: number | null;
  ActionBy_Council_ID?: number | null;
  FileNumber: string;
  ActionAgainst: Contact_Relation;
  ActionAgainst_RID?: number | null;
  Flag_IsImmediateAction?: boolean | null;
  ImmediateAction_KWD?: number | null;
  ImmediateAction_Details: string;
  Flag_IsShowCauseAction?: boolean | null;
  Flag_FamilyViolenceInformationSharingScheme?: boolean | null;
  Flag_ChildInformationSharingScheme?: boolean | null;
  ShowCause_ProposedAction_KWD?: number | null;
  CommissionerApproved?: boolean | null;
  DateNoticeSent?: string | null;
  SateSubmissionDue?: string | null;
  ShowCause_ProcessResult_KWD?: number | null;
  ShowCause_ActionResult_Notes: string;
  CommissionerApprovalDate?: string | null;
  SubmissionReceivedDate?: string | null;
  FinalDecisionMadeDate?: string | null;
  StartDate?: Date;
  SubmissionReceived?: boolean | null;
  FinalNoticeToWorker?: string | null;
  _BubbleUps: BubbleUpIdentifier[];
  BubbleUps: BubbleUpIdentifier[];
  IsBulk_AddAction: boolean;
  BulkCreationRecordSources: IMailMergeIdentifier[];
  SaveTriggers: ActionUpdateTriggers[];
  _SaveTriggers: ActionUpdateTriggers[];
  IsCommunication?: boolean | null;
  CommunicationActionType: string;
  ActionTypeTags_IDs: number[];
  ActionTypeTags_CSV: string;
  InformationSharing_Direction_KWD?: number | null;
  InformationSharing_Direction_Name: string;
  InformationSharing_Status_ENUM: ACTIONSTATUS;
  InformationSharing_Status_Name: string;
  InformationSharing_FromTo_KWD?: number | null;
  InformationSharing_FromTo_Name: string;
  InformationSharing_FromTo_Other: string;
  InformationSharing_Type_KWD?: number | null;
  InformationSharing_Type_Name: string;
  InformationSharing_ReferralDecisionBy_KWD?: number | null;
  InformationSharing_ReferralDecisionBy_Name: string;
  InformationSharing_ReasonForReferral_KWD?: number | null;
  InformationSharing_ReasonForReferral_Name: string;
  InformationSharing_Notes: string;
  Is_InformationSharing_VCAT?: boolean | null;
  InformationSharing_VCAT_ProceedingDate?: string | null;
  InformationSharing_VCAT_BoardRepresentative_RID?: number | null;
  InformationSharing_VCAT_BoardRepresentative: Contact_Relation;
  InformationSharing_VCAT_Determination_KWD?: number | null;
  Lst_InformationSharing_VCAT_Order_KWDs: number[];
  InformationSharing_VCAT_Determination_Name: string;
  InformationSharing_VCATOrder_Name: string;
  InformationSharing_VCAT_FineAmount?: number | null;
  InformationSharing_VCAT_DeterminationNotes: string;
  NextActionUrl: string;
  AssessmentAction_Type_KWD?: number | null;
  AssessmentAction_Type_Name: string;
  AssessmentAction_Worker: Contact_Relation;
  AssessmentAction_Worker_RID?: number | null;
  AssessmentAction_Status_ENUM: ACTIONSTATUS;
  AssessmentAction_Status_Name: string;
  AssessmentAction_Requestor: SiteUser;
  AssessmentAction_Requestor_ID?: number | null;
  AssessmentAction_RequestedBy_KWD?: number | null;
  AssessmentAction_RequestedBy_Name: string;
  AssessmentAction_Outcome_KWD?: number | null;
  AssessmentAction_Outcome_Name: string;
  AssessmentAction_AssessorContactType_ENUM: ASSESSORCONTACTTYPE;
  AssessmentAction_AssessorContactType_Name: string;
  AssessmentAction_Assessor: Contact_Relation;
  AssessmentAction_Assessor_RID?: number | null;
  AssessmentAction_Practitioner: Contact_Relation;
  AssessmentAction_Practitioner_RID?: number | null;
  AssessmentAction_Lst_Assessors: Contact_Relation[];
  AssessmentAction_Lst_AdditionalAssessors: number[];
  AssessmentAction_DateNoticeOfAssessmentSent?: string | null;
  AssessmentAction_DateReportProvidedToWorker?: string | null;
  AssessmentAction_DateOfDiscussion?: string | null;
  AssessmentAction_DateFinalDecision?: string | null;
  AssessmentAction_DateFinalDecisionProvidedToWorker?: string | null;
  AssessmentAction_DateFinalDecisionProvidedToParties?: string | null;
  AssessmentAction_Summary: string;
  AssessmentAction_Has_PrejudicialInformation?: boolean | null;
  AssessmentAction_Has_WrittenNotice?: boolean | null;
}

export enum ActionUpdateTriggers {
  UpdateAction,
  UpdateActionOfficer,
  UpdateCompletedBy,
  UpdateRecordedBy,
  UpdateExternalContact,
  UpdateParentContacts,
  CreateAutoActions,
  UpdateVCATBoardRepresentativeContact,
  UpdateActionAgainst,
  UpdateWorker,
  UpdateAdditionalAssessor,
  UpdateAssessmentWorker,
  UpdateAssessor,
  UpdateNominatedMedicalPractionerPsychologist,
}

export interface BuildAction {
  actionType_ID: number;
  actionOfficer_ID: number | null;
  actionOutcome_KWD: number | null;
  workflow_ENUM: ACTIONWORKFLOW | null;
  bubbleUps: BubbleUpIdentifier[];
}

export interface Meeting_Status {
  statusOnly: string;
  statusType: string;
  statusDate: string;
}

export interface ActionDeleteAuthorise {
  FormAction: boolean;
  FormNotice: boolean;
  FormActionPlan: boolean;
  FormVCATReview: boolean;
  FormAdvertising: boolean;
  FormProsecution: boolean;
  FormComplaint: boolean;
  FormFurtherInformationRequest: boolean;
}

export const dropdownStatusDataWithEnumeratedID = [
  { Key: 1, Value: "NEW" },
  { Key: 11, Value: "PENDINGACTION" },
  { Key: 10, Value: "NOACTION" },
];

export const dropdownStatusDataWithoutEnumeratedID = [
  { Key: 7, Value: "DRAFT" },
  { Key: 9, Value: "READYTOBESENT" },
  { Key: 8, Value: "SENT" },
];

export enum ActionSubmitActions {
  New = "New",
  Save = "Save",
  SendToActionOfficer = "SendToActionOfficer",
  ActionComplete = "ActionComplete",
  RescheduleAction = "RescheduleAction",
  ReturnToSender = "ReturnToSender",
  MeetingComplete = "MeetingComplete",
  CreateAndMeetingComplete = "CreateAndMeetingComplete",
  CancelMeeting = "CancelMeeting",
  CreateAndCancelMeeting = "CreateAndCancelMeeting",
  ReOpenMeeting = "ReOpenMeeting",
  SendForAction = "SendForAction",
  ChangeCategory = "ChangeCategory",
  CloseEvent = "CloseEvent",
  SpecialInterest = "SpecialInterest",
  AccessControl = "AccessControl",
  Details = "Details",
  CompleteMessage = "CompleteMessage",
  ChangeActionOfficer = "ChangeActionOfficer",
  ActionOfficerComment = "ActionOfficerComment",
  MergeContact = "MergeContact",
  MergeContactAndEdit = "MergeContactAndEdit",
  AddCheckListQuestion = "AddCheckListQuestion",
  AddInvoiceItem = "AddInvoiceItem",
  TestFees = "TestFees",
  EventEnterIntoPound = "EventEnterIntoPound",
}
