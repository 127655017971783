import { RatingStar } from "@app/products/hm/components/rating-star/_index";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadInspection } from "./api";
import { InspectionResponse } from "./model";

export const InspectionDetailsTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [inspectionInfo, setInspectionInfo] = useState<
    InspectionResponse | undefined
  >();
  const productListViewStore = useCCProductListViewStore();
  const { lastSelectedRow } = productListViewStore;
  const params: { id: string } = useParams();
  const inspectionId = params.id;
  useEffect(() => {
    if (lastSelectedRow?.ID || inspectionId) {
      const ID: any = lastSelectedRow?.ID || inspectionId;
      setIsLoading(true);
      loadInspection(ID).then((data) => {
        setInspectionInfo(data);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, inspectionId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!inspectionInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Registration Number:"}
        value={formatDisplayValue(inspectionInfo.RefNo, NUMBER_FORMAT.NUMBER2)}
        primary
      />
      <ProductReferenceBlock>
        {inspectionInfo.RatingStar ? (
          <ProductReferenceRow
            customValue={
              <RatingStar numberOfStar={inspectionInfo.RatingStar} />
            }
          />
        ) : null}
        <ProductReferenceRow
          title={"Date Inspected:"}
          value={inspectionInfo.DateInspected}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
