import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { SavePermitButton } from "@app/products/local-laws/[id]/components/buttons/save/_index";
import { PermitForm } from "@app/products/local-laws/[id]/components/general/_index";
import { LocalLawPermitFormTitle } from "@app/products/local-laws/[id]/constant";
import { Permit } from "@app/products/local-laws/[id]/model";
import { LodgePermitButton } from "@app/products/local-laws/components/toolbar/buttons/lodge-permit/_index";
import { RefusePermitButton } from "@app/products/local-laws/components/toolbar/buttons/refuse-permit/_index";
import { WithdrawPermitButton } from "@app/products/local-laws/components/toolbar/buttons/withdraw/_index";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const NewManagePermit = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const permitFromStore = dataForms?.GeneralForm?.Permit as Permit;
  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });

  const allowSavePermission = checkPermissions([
    FormAction.CORE_ALLOW_SAVE,
    FormAction.CORE_ALLOW_EDIT,
  ]);

  const MemoizedWorkflowPermitButton = useMemo(() => {
    return (
      <>
        <LodgePermitButton isDisabled={!allowSavePermission} />
        <RefusePermitButton isDisabled={!allowSavePermission} />
        <WithdrawPermitButton isDisabled={!allowSavePermission} />
      </>
    );
  }, [allowSavePermission]);

  return (
    <>
      <FormTitle title={`New ${LocalLawPermitFormTitle}`} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            {MemoizedWorkflowPermitButton}
          </CCNavButton>,
          <SavePermitButton />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {permitFromStore && <PermitForm />}
          </div>
        </div>
      </div>
    </>
  );
});
