import { IViewOnMapByGISButtonProps } from "@app/core/view-on-map/components/buttons/view-map-by-gis/_index";
import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IMapTabProps extends IViewOnMapByGISButtonProps {
  addressField?: string | string[];
  zoom?: number;
  onError?: (errorMessage: string) => void;
  objViewOnMapRequest?: Svc_ViewOnMap;
  isDisabled?: boolean;
  isInManagePage?: boolean;
  recordType?: RECORDTYPE;
}
export const AnimalMapTab = observer(
  ({
    zoom = 10,
    addressField = "Property_Name_Address_Locality",
    recordType,
    isInManagePage = undefined,
    isDisabled = false,
    objViewOnMapRequest,
    onError,
  }: IMapTabProps) => {
    const { animalInfo } = useAnimalStore();
    return (
      <MapTab
        address={animalInfo?.Address?.Formatted_SingleLine}
        zoom={zoom}
        recordType={recordType}
        isInManagePage={isInManagePage}
        addressField={addressField}
        isDisabled={isDisabled}
        objViewOnMapRequest={objViewOnMapRequest}
        onError={onError}
      />
    );
  }
);
