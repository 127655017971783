import { HMFieldMapping } from "@app/products/hm/model";

export const colHMFinanceNotSentToFinance = [
  {
    field: HMFieldMapping.ReferenceNumber,
    title: "Registration Number",
    locked: true,
    width: 150,
  },
  { field: HMFieldMapping.TradingName, title: "Name", width: 150 },
  { field: HMFieldMapping.CreatedDate, title: "Recorded", width: 150 },
  { field: HMFieldMapping.FeeType, title: "Type", width: 150 },
  { field: HMFieldMapping.AmountIssued, title: "Issued", width: 150 },
  { field: HMFieldMapping.AmountOutstanding, title: "Outstanding", width: 150 },
  { field: HMFieldMapping.CostCode, title: "Cost Code", width: 150 },
  { field: HMFieldMapping.InvoiceNumber, title: "Invoice Number", width: 150 },
  { field: HMFieldMapping.DebtorNumber, title: "Debtor Number", width: 150 },
];
