import { AddAssessmentRangeDialog } from "@app/products/property/assessments/components/dialogs/add-assessment-range-dialog/_index";
import { INVALID_WORKFLOW_DRAFT_ID } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { getViewConfigurations } from "@app/products/property/assessments/master-properties/api";

import { DTO_MP_Assessment } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/model";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colAssessment } from "./config";
import "./_index.scss";

export const AssociationsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfMPAssessmentGrid = nameOfFactory<DTO_MP_Assessment>();
const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    workflowDraftId: INVALID_WORKFLOW_DRAFT_ID,
  },
  isLoadingStep,
  setIsLoadingStep = () => {},
  loadFailedStep,
  setLoadFailedStep = () => {},
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  const selectedAssessments = getFieldValue("AssessmentSelected") ?? [];
  const currentAssessments = getFieldValue("Assessments") ?? [];

  const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
    useState<boolean>(false);
  const [showAddAssessmentRangeDialog, setShowAddAssessmentRangeDialog] =
    useState(false);
  const [processedAssessmentsCol, setProcessedAssessmentsCol] =
    useState<IColumnFields[]>(colAssessment);

  const loadData = () => {
    setIsLoadingStep(true);
    getViewConfigurations(ViewConfiguration.MasterProperty_Assessment).then(
      (response) => {
        setIsLoadingStep(false);
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setLoadFailedStep({
              onReload: () => {
                loadData();
              },
              responseError: {
                status: response.status,
                error: response.error ?? "Load failed",
              },
            });
            return;
          }
          setProcessedAssessmentsCol(
            processDynamicColumns(colAssessment, viewConfig)
          );
        } else {
          setLoadFailedStep({
            onReload: () => {
              loadData();
            },
            responseError: {
              status: response.status,
              error: response.error ?? "Load failed",
            },
          });
        }
      }
    );
  };

  useEffectOnce(() => {
    loadData();
  });

  const handleDelete = () => {
    const selectedIDs = selectedAssessments?.map(
      (assessment: DTO_MP_Assessment) => assessment.Assessment_Id
    );
    const newAssessments = currentAssessments?.filter(
      (assessment: DTO_MP_Assessment) =>
        !selectedIDs.includes(assessment.Assessment_Id)
    );
    onChange(nameOf("Assessments"), { value: newAssessments });
    onChange(nameOf("AssessmentSelected"), { value: [] });
  };

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    if (dataItem) {
      onChange(nameOf(field), {
        value: dataItem,
      });
    }
  };

  const handleAddAssessments = (assessmentList: DTO_MP_Assessment[]) => {
    setShowAddAssessmentRangeDialog(false);
    onChange(nameOf("Assessments"), { value: assessmentList });
  };

  const canSelect = () => {
    if (!selectedAssessments) return false;
    return selectedAssessments?.length > 0;
  };

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  if (loadFailedStep) {
    return (
      <CCLoadFailed
        onReload={loadFailedStep?.onReload}
        responseError={loadFailedStep?.responseError}
      />
    );
  }

  return (
    <>
      <section className="cc-field-group cc-new-master-property-associations-step">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Assessments in master property</label>
            <CCGrid
              data={getFieldValue("Assessments") || []}
              columnFields={processedAssessmentsCol}
              primaryField={nameOfMPAssessmentGrid("Assessment_Id")}
              editableMode="cell"
              readOnly={options?.isReadOnly}
              selectableMode="multiple"
              onSelectionChange={(dataItem: any) => {
                handleGridSelectionChange(dataItem, "AssessmentSelected");
              }}
              selectedRows={
                selectedAssessments ? [...selectedAssessments] : undefined
              }
              onDataChange={(dataItem: DTO_MP_Assessment[]) => {
                onChange(nameOf("Assessments"), { value: dataItem });
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    className="cc-edit-field-button"
                    iconClass="fa fa-plus"
                    title="Associate Assessment(s)"
                    disabled={!options?.workflowDraftId}
                    onClick={() => {
                      setShowAddAssessmentRangeDialog(true);
                    }}
                  />
                  <Button
                    iconClass="fas fa-minus"
                    title="Un-associate Assessment(s)"
                    disabled={!canSelect()}
                    onClick={() => {
                      setIsShowConfirmDeletion(true);
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>
      {isShowConfirmDeletion && (
        <ConfirmDialog
          title={"Confirm Deletion"}
          subMessage={
            "Are you sure you wish to un-associate the selected assessment(s) from this master property?"
          }
          onClosePopup={() => {
            setIsShowConfirmDeletion(false);
          }}
          onConfirmYes={handleDelete}
        />
      )}
      {showAddAssessmentRangeDialog && (
        <AddAssessmentRangeDialog
          onClose={() => {
            setShowAddAssessmentRangeDialog(false);
          }}
          handleAddAssessments={handleAddAssessments}
          currentAssessments={currentAssessments}
        />
      )}
    </>
  );
};
