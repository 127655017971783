import { getViewConfigurations } from "@app/products/property/api";
import { getAssessmentTransactionsViews } from "@app/products/property/assessments/[id]/components/child-screens/transactions/api";
import {
  ITransactionViewProps,
  mTransactionView,
  ViewConfiguration,
} from "@app/products/property/model";
import { getSupplementaryTransactionDetail } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/api";
import { colTransactionDetail } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/components/details/config";
import { colTransactions } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/config";
import {
  DTO_Supplementary_Transactions,
  VO_Supplementary_Transaction,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class SupplementaryRatesTransactionStore {
  private _transactionsDetail?: DTO_Supplementary_Transactions = undefined;
  private _isLoading: boolean = false;
  private _isLoadingGrid: boolean = false;
  private _isLoadingDetail: boolean = false;
  private _responseLoadErrorView?: APIResponseError = undefined;
  private _responseLoadErrorGrid?: APIResponseError = undefined;
  private _viewOption: ITransactionViewProps[] = [];
  private _transactionView?: ITransactionViewProps = undefined;
  private _showCancelledTransactions: boolean = false;
  private _columnFields: IColumnFields[] = colTransactions;
  private _columnFieldsDetail: IColumnFields[] = colTransactionDetail;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadErrorView() {
    return toJS(this._responseLoadErrorView);
  }
  setResponseLoadErrorView = (responseLoadErrorView?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadErrorView = responseLoadErrorView;
    });
  };

  get responseLoadErrorGrid() {
    return toJS(this._responseLoadErrorGrid);
  }
  setResponseLoadErrorGrid = (responseLoadErrorGrid?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadErrorGrid = responseLoadErrorGrid;
    });
  };

  get transactionsDetail() {
    return this._transactionsDetail;
  }
  setTransactionsDetail = (
    transactionDetail: DTO_Supplementary_Transactions
  ) => {
    runInAction(() => {
      this._transactionsDetail = transactionDetail;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetail() {
    return this._isLoadingDetail;
  }
  setIsLoadingDetail = (isLoadingDetail: boolean) => {
    runInAction(() => {
      this._isLoadingDetail = isLoadingDetail;
    });
  };

  get transactionView() {
    return this._transactionView;
  }
  setTransactionView = (transactionView: ITransactionViewProps) => {
    runInAction(() => {
      this._transactionView = transactionView;
    });
  };

  get isLoadingGrid() {
    return this._isLoadingGrid;
  }
  setIsLoadingGrid = (isLoadingGrid: boolean) => {
    runInAction(() => {
      this._isLoadingGrid = isLoadingGrid;
    });
  };

  get viewOption() {
    return this._viewOption;
  }
  setViewOption = (viewOption: ITransactionViewProps[]) => {
    runInAction(() => {
      this._viewOption = viewOption;
    });
  };

  get showCancelledTransactions() {
    return this._showCancelledTransactions;
  }
  setShowCancelledTransactions = (showCancelledTransactions: boolean) => {
    runInAction(() => {
      this._showCancelledTransactions = showCancelledTransactions;
    });
  };

  get columnFields() {
    return this._columnFields;
  }
  setColumnFields = (columnFields: IColumnFields[]) => {
    runInAction(() => {
      this._columnFields = columnFields;
    });
  };

  get columnFieldsDetail() {
    return this._columnFieldsDetail;
  }
  setColumnFieldsDetail = (_columnFieldsDetail: IColumnFields[]) => {
    runInAction(() => {
      this._columnFieldsDetail = _columnFieldsDetail;
    });
  };

  loadViews = async () => {
    this.setIsLoading(true);
    let errorResponse = undefined;

    const responseView = await getAssessmentTransactionsViews();

    if (isSuccessResponse(responseView) && responseView?.data) {
      let processView: any[] = responseView.data?.Views
        ? [...responseView.data.Views]
        : [];
      processView = processView.map((view: mTransactionView) => {
        return {
          ...view,
          TempId: `${view.Id} ${view.FinancialYear?.Year ?? ""}`,
        };
      });
      this.setViewOption(processView);
      this.setTransactionView(processView[0]);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadErrorView(errorResponse);
    this.setIsLoading(false);
  };

  loadTransactionConfigurationColumns = async () => {
    let errorResponse = undefined;

    this.setIsLoadingGrid(true);
    const response = await getViewConfigurations(
      ViewConfiguration.Supplementary_Transactions
    );
    if (isSuccessResponse(response) && response?.data) {
      let processColumns = cloneDeep(colTransactions);
      processColumns = processColumns.map((column: IColumnFields) => {
        let newColumns = cloneDeep(column);
        if (newColumns.field === "Transaction_Amount") {
          newColumns.calculateFooterCellValue = (
            props: GridFooterCellProps,
            gridData: VO_Supplementary_Transaction[]
          ) => {
            return gridData.reduce(
              (accumulator, currentValue: VO_Supplementary_Transaction) =>
                accumulator + currentValue.Transaction_Amount,
              0
            );
          };
        }
        return newColumns;
      });

      this.setColumnFields(
        processDynamicColumns(
          processColumns,
          response.data.ColumnDefinitions?.Columns
        )
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadErrorGrid(errorResponse);
    this.setIsLoadingGrid(false);
  };

  loadSupplementaryRatesTransactionDetail = async (
    id: number,
    transactionId: number
  ) => {
    let errorResponse = undefined;

    this.setIsLoadingDetail(true);
    const [responseConfiguration, responseTransactionDetailData] =
      await Promise.all([
        getViewConfigurations(
          ViewConfiguration.Supplementary_TransactionDetails
        ),
        getSupplementaryTransactionDetail(id, transactionId),
      ]);
    if (
      isSuccessResponse(responseConfiguration) &&
      isSuccessResponse(responseTransactionDetailData) &&
      responseConfiguration?.data &&
      responseTransactionDetailData?.data
    ) {
      let processColumns = cloneDeep(colTransactionDetail);
      processColumns = processColumns.map((column: IColumnFields) => {
        let newColumns = cloneDeep(column);
        if (newColumns.field === "Amount") {
          newColumns.calculateFooterCellValue = () =>
            responseTransactionDetailData?.data?.TransactionAllocations?.Totals
              ?.Amount ?? 0;
        }
        return newColumns;
      });
      this.setColumnFieldsDetail(
        processDynamicColumns(
          processColumns,
          responseConfiguration.data.ColumnDefinitions?.Columns
        )
      );
      this.setTransactionsDetail(responseTransactionDetailData?.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadErrorGrid(errorResponse);
    this.setIsLoadingDetail(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._transactionsDetail = undefined;
      this._isLoading = false;
      this._isLoadingGrid = false;
      this._isLoadingDetail = false;
      this._responseLoadErrorView = undefined;
      this._responseLoadErrorGrid = undefined;
      this._viewOption = [];
      this._transactionView = undefined;
      this._showCancelledTransactions = false;
      this._columnFields = [];
      this._columnFieldsDetail = [];
    });
  };
}

const supplementaryRatesTransactionStoreContext = createContext(
  new SupplementaryRatesTransactionStore()
);
export const useSupplementaryRatesTransactionStore = () => {
  return useContext(supplementaryRatesTransactionStoreContext);
};
