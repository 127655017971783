import { IGISReferenceGrid } from "@app/products/property/registers/[id]/model";

export const gisReferenceDataArrayToGrid = (gisReferenceArray: string[]) => {
  const gisReferenceGrid: IGISReferenceGrid[] = gisReferenceArray.map(
    (references) => {
      return { GISReference: references };
    }
  );

  return gisReferenceGrid;
};

export const gisReferenceDataGridToArray = (
  gisReferenceGrid: IGISReferenceGrid[]
) => {
  const gisReferenceArray: string[] = gisReferenceGrid.map(
    (references) => references.GISReference!
  );
  return gisReferenceArray;
};
