import { FurtherFormElement } from "@app/core/further-info/[id]/components/child-screens/general/components/form-element/_index";
import {
  FurtherInformationRequest,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const FurtherInfoForm = observer(() => {
  const { setOnSubmit, furtherInfo, saveFurtherInfo } = useFurtherInfoStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isValid, isModified } = event;

    const action = event.event?.currentTarget.name as FurtherInfoSubmitActions;
    if (!(action in FurtherInfoSubmitActions)) return;
    if (!isValid) return;

    if (
      !isModified &&
      [FurtherInfoSubmitActions.Save, FurtherInfoSubmitActions.New].includes(
        action
      )
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    saveFurtherInfo(values as FurtherInformationRequest, action);
  };
  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, furtherInfo]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={furtherInfo}
        key={JSON.stringify(furtherInfo)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <FurtherFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
