import { ECustomColNameProperty } from "@app/products/property/config";
import { DTO_Supplementary_W_Detail } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface ISupplementaryRatesDetailsProps {
  formRenderProps: FormRenderProps;
}
export const SupplementaryRatesDetailsFormElement = observer(
  ({ formRenderProps }: ISupplementaryRatesDetailsProps) => {
    const nameOf = nameOfFactory<DTO_Supplementary_W_Detail>();
    const getValue = formRenderProps.valueGetter;
    const { pushNotification } = useCCAppNotificationStore();

    //Get label
    const assessmentLowercaseLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.AssessmentLowercase
    );

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    return (
      <FormElement className="cc-property-supplementary-rates-form-elements">
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Supplementary type</label>
              <Field
                name={nameOf("Type")}
                placeholder={"Supplementary type"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Supplementary name</label>
              <Field
                name={nameOf("Name")}
                placeholder={"Supplementary name"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Supplementary reference</label>
              <Field
                name={nameOf("Reference")}
                placeholder={"Supplementary reference"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Supplementary date</label>
              <Field
                value={
                  getValue(nameOf("Date")) && new Date(getValue(nameOf("Date")))
                }
                name={nameOf("Date")}
                placeholder={"Supplementary date"}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Instalment plan</label>
              <Field
                name={nameOf("InstalmentPlan")}
                placeholder={"Instalment plan"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel
                // TODO: Waiting API to return lower case label
                title={`Use ${assessmentLowercaseLabel}s existing instalment plan`}
              />
              <Field
                name={nameOf("UseExistingInstalmentPlan")}
                component={CCSwitch}
                checked={getValue(nameOf("UseExistingInstalmentPlan"))}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Balance transfer method</label>
              <Field
                name={nameOf("BalanceTransferMethod")}
                placeholder={"Balance transfer method"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Balance transfer valuation type
              </label>
              <Field
                name={nameOf("BalanceTransferValuationType")}
                placeholder={"Balance transfer valuation type"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Created by</label>
              <Field
                name={nameOf("CreatedBy")}
                placeholder={"Created by"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Created on</label>
              <Field
                value={
                  getValue(nameOf("CreatedOn")) &&
                  new Date(getValue(nameOf("CreatedOn")))
                }
                name={nameOf("CreatedOn")}
                format={DATE_FORMAT.DATE_CONTROL}
                placeholder={"Created on"}
                component={CCDatePicker}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">VM2000 ID</label>
              <Field
                name={nameOf("VM2000_Id")}
                placeholder={"VM2000 ID"}
                component={CCNumericTextBox}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Enable levy calculations</label>
              <Field
                name={nameOf("EnableLevyCalculations")}
                component={CCSwitch}
                checked={getValue(nameOf("EnableLevyCalculations"))}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Notes</label>
              <Field
                name={nameOf("Notes")}
                placeholder={"Notes"}
                component={CCTextArea}
                rows={4}
                readOnly
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
