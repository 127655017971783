import {
  getFromDate,
  getToDate,
} from "@app/products/ams/api/helpers/date-helper";
import { EAssessmentError } from "@app/products/property/assessments/components/constant";
import { isAfter } from "date-fns";

export const ERROR_MESSAGE_FROM_TO_DATE = `"To Date" must be greater than or equal to "From Date"`;

export const assessmentGISValidator = (
  gisData: any = [],
  fromDateField: string,
  toDateField: string
): string | undefined => {
  const isError = gisData.some((item: any) => {
    const fromDate = item?.[fromDateField]
      ? getFromDate(item?.[fromDateField])
      : null;
    const toDate = item?.[toDateField] ? getToDate(item?.[toDateField]) : null;
    return fromDate && toDate && isAfter(fromDate, toDate);
  });

  if (isError) {
    return ERROR_MESSAGE_FROM_TO_DATE;
  }

  return undefined;
};

export const getErrorMessageParcel = (errors: string): string | undefined => {
  if (errors === EAssessmentError.ValidateFactorPercentage)
    return EAssessmentError.ValidateFactorPercentage;
  if (errors === EAssessmentError.ValidateExistParcel) {
    return EAssessmentError.ValidateExistParcel;
  }
  return undefined;
};
