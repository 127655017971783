import { DDCreateAuthorisationDialog } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

interface IDDCreateAuthorisationButtonProps {
  isDisabled?: boolean;
}
export const DDCreateAuthorisationButton = ({
  isDisabled = false,
}: IDDCreateAuthorisationButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  return (
    <>
      <CCNavButton
        title="New"
        onClick={() => setIsShowDialog(true)}
        disabled={isDisabled}
      />
      {isShowDialog && (
        <DDCreateAuthorisationDialog onClose={() => setIsShowDialog(false)} />
      )}
    </>
  );
};
