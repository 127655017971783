import { VO_Assessment_Subdivision_Title } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/details/model";
import { DTO_LOV_SuppType } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsSubdivideConsolidate {
  Details = "Details",
  Progeny = "Progeny",
  Supplementary = "Supplementary",
  NewSupplementary = "NewSupplementary",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsSubdivideConsolidate = [
  EKeysOfStepsSubdivideConsolidate.Details,
  EKeysOfStepsSubdivideConsolidate.Progeny,
  EKeysOfStepsSubdivideConsolidate.Supplementary,
  EKeysOfStepsSubdivideConsolidate.NewSupplementary,
];

export interface DTO_SubdivisionsConsolidations extends DTO_Request_Base {
  Assessment_Id: number;
}
export interface DTO_Workflow_SubdivisionsConsolidations {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_SubdivisionsConsolidations;
}

export interface DTO_WorkflowDetail_SubdivisionsConsolidations {
  SupplementaryMode: number;
  Assessment_Id: number;
  Supplementary_Id: number | null;
  Details: DTO_SubdivisionConsolidate_Details;
  Progeny: DTO_SubdivisionConsolidate_Progeny;
  NewSupplementary: DTO_Supplementary_Create;
}

export interface DTO_SubdivisionConsolidate_Details {
  Status_Id: number;
  Date: Date;
  Status: string;
  Titles: number[];
  Parcels: number[];

  // Parent lots grid data - Only show on UX, not send to API
  _option: {
    ParentTitleList: VO_Assessment_Subdivision_Title[];
  };
}

export interface DTO_SubdivisionConsolidate_Progeny {
  Progenies: DTO_Progeny[];
  AreaOfParentTitles: number;
  AreaOfProgenyTitles: number;
}

export interface DTO_SubdivisionConsolidate_Supplementary {
  SupplementaryType: number;
  SupplementaryType_Name: string;
}

export interface DTO_Supplementary_Create {
  SupplementaryId: number | null;
  SupplementaryType: number;
  InstalmentPlanId: number | null;
  Name: string;
  Reference: string;
  Date: Date;
  Notes: string;
  IsComplete: boolean | null;
  CreatedOn: Date | null;
  CreatedBy: string;
  VM2000_Id: number | null;
  TransferRemainingBalance: boolean;
  BalanceTransferMethodId: number;
  BalanceTransferValuationType: number | null;
  UseExistingInstalmentPlan: boolean;
}

export interface DTO_Progeny {
  House_Prefix_2: string;
  House_Number_2: number | null;
  House_Suffix_2: string;
  House_Number: string;
  Street_And_Locality: string;
  Side_Of_Street: string;
  Side_Of_Street_Abbreviation: string;
  Estate_Id: number | null;
  Estate: string;
  Estate_Stage_Number: string;
  GIS_Reference: string;
  Street_Locality_Id: number | null;
  Owner_Id: number | null;
  Owners: string;
  Title_ID: number | null;
  House_Suffix_1: string;
  House_Number_1: number | null;
  House_Prefix_1: string;
  Building_Unit_Number: string;
  Lot: string;
  Plan_Type: number | null;
  Plan_Type_Name: string;
  Plan_Number: string;
  Land_Area: number | null;
  Frontage: number | null;
  Legal_Description: string;
  Title_Status_Id: number | null;
  Parcel_Reference: string;
  Building_Unit_Abbreviation: string;
  Building_Unit_Prefix_1: string;
  Building_Unit_Number_1: number | null;
  Building_Unit_Suffix_1: string;
  Building_Unit_Prefix_2: string;
  Building_Unit_Number_2: number | null;
  Building_Unit_Suffix_2: string;
  Building_Unit_Name: string;
  Parcel_ID: number | null;
  RowId: string;

  //Dummy field to handle on UX
  Lot_From: number;
  Lot_To: number;
}

export interface DTO_SubdivisionsConsolidations_LOVs_Response {
  dtoSubdivisionsConsolidation_LOVs: DTO_SubdivisionsConsolidations_LOVs;
}

export interface DTO_SubdivisionsConsolidations_LOVs {
  SubdivisionsConsolidation_Status: DTO_LOV[];
  Supplementary_Type: DTO_LOV_SuppType[];
  Installment_Type: DTO_LOV[];
  Assessment_Type: DTO_LOV[];
  ValuationTypeTobeUsed: DTO_LOV[];
  Workflow_Memo_Category: DTO_LOV[];
}
