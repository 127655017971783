import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmUnVerifyReadingsDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export const ConfirmUnVerifyReadingsDialog = ({
  onClosePopup,
  onConfirmYes,
}: IConfirmUnVerifyReadingsDialog) => {
  return (
    <ConfirmDialog
      height={"auto"}
      width={"20%"}
      title={"Unverify Reading Confirmation"}
      message={""}
      subMessage={"Do you wish to Unverify Readings?"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
