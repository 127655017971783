import { HMFieldMapping } from "@app/products/hm/model";

export const colHMRecycleBinPremises = [
  {
    field: HMFieldMapping.DeletedOn,
    title: "Deleted On",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.DeletedBy,
    title: "Deleted By",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.Name,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.RiskClassification,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.RenewalMethod_Name,
    title: "Renewal",
    width: 150,
  },
  {
    field: HMFieldMapping.InspectionFrequency,
    title: "Insp Freq",
    width: 150,
  },
  {
    field: HMFieldMapping.Category,
    title: "Category",
    width: 150,
  },
  {
    field: HMFieldMapping.RegistrationFee_Amount,
    title: "Registration Fee",
    width: 150,
  },
  {
    field: HMFieldMapping.RenewalFee_Amount,
    title: "Renewal Fee",
    width: 150,
  },
  {
    field: HMFieldMapping.TransferFee_Amount,
    title: "Transfer Fee",
    width: 150,
  },
];
