import { DTO_Collection } from "@app/products/property/components/child-screen/collections/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfCollection = nameOfFactory<DTO_Collection>();
export const colCollectionsProperty: IColumnFields[] = [
  {
    field: nameOfCollection("CollectionName"),
    title: "Collection Name",
  },
  {
    field: nameOfCollection("CollectionTypeName"),
    title: "Collection Type",
  },
  {
    field: nameOfCollection("Collection_Notes"),
    title: "Collection Notes",
  },
  {
    field: nameOfCollection("LV_Name"),
    title: "List View",
  },
  {
    field: nameOfCollection("ComponentId"),
    title: "Component Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfCollection("Collection_Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfCollection("Collection_Created_By"),
    title: "Created By",
  },
  {
    field: nameOfCollection("CollectionId"),
    title: "Collection ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
