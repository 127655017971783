import {
  apiCoreGetRecall,
  apiCoreSaveRecall,
} from "@app/core/components/common/utils";
import { Recall } from "@app/core/recall/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getRecallById = async (
  recallId: number,
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<Recall>> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<Recall>>(
      apiCoreGetRecall(recallId),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSaveRecall = async (
  recall: Recall,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveRecall(),
      recall,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
