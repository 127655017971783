import { eventEmitter } from "@/App";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { putBatchCompleteEnquiry } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/api";
import { BatchComplete } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PPREnquiryBatchCompleteButton = observer(() => {
  const { gridSelectedIds } = useCCProductListViewStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);

  const objReason = useMemo(() => {
    const retVal = new ProvideReason();
    retVal.Flag_ShowKWD = false;
    retVal.Flag_ShowDate = true;
    retVal.DateOfAction = new Date();
    retVal.Flag_ShowReasonTextBox = true;
    retVal.Flag_TextIsMandatory = true;
    retVal.Flag_DateIsMandatory = true;
    retVal.Title_Text = "Batch Complete";
    retVal.ReasonLabel_Text = "Reason";
    retVal.DateLabel_Text = "Date";
    return retVal;
  }, []);

  const handleOnSubmit = async (
    value: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    if (isEmpty(gridSelectedIds) || !value.DateOfAction) return;
    setIsLoading(true);
    const batchCompleteDetail: BatchComplete = {
      RegisterIDs: gridSelectedIds,
      DateOfAction: value.DateOfAction,
      Reason: value.Reason,
    };
    const resUpdateStatus = await putBatchCompleteEnquiry(batchCompleteDetail);
    setIsLoading(false);
    setShowUpdateStatusDialog(false);
    if (isSuccessIdentityPacket(resUpdateStatus)) {
      clearErrorNotification();
      eventEmitter.emit(CCGridEventType.RefreshOData);
      pushNotification({
        type: "success",
        title: "Status changed successfully",
      });
    } else {
      notificationRef.current?.pushNotification({
        title: "Change status failed",
        type: "error",
        autoClose: false,
        description: resUpdateStatus.data?.Errors ?? resUpdateStatus.statusText,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Batch Complete"
        onClick={() => {
          setShowUpdateStatusDialog(true);
        }}
        disabled={isEmpty(gridSelectedIds)}
      />
      {showUpdateStatusDialog && (
        <ReasonDialog
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onClose={() => setShowUpdateStatusDialog(false)}
          onSubmit={handleOnSubmit}
          initialData={objReason}
        />
      )}
    </>
  );
});
