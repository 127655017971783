import {
  APIResponse,
  APIResponseError,
  THandleCatchResponse,
} from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { isCancelRequest } from "@common/hooks/useCancelRequest";
import { ResponsePacket } from "@common/models/identityPacket";
import {
  IdentityPacketErrorStatus,
  IdentityPacketErrorStatusNumber,
} from "@common/models/sysEnumerations";
import { AxiosRequestConfig } from "axios";
import { isNil } from "lodash";

export const handleCatchResponse = (error: any): THandleCatchResponse => {
  if (isNil(error)) {
    return {
      data: undefined,
      status: APIResponseStatus.NETWORK_ERROR,
      statusText: "NETWORK_ERROR",
      headers: undefined,
      config: {},
      error: "Not receiving response from the server",
    };
  }

  // Receiving response from the server
  const errorResponse: APIResponse = error;
  if (isCancelRequest(error?.errorCause)) {
    return {
      ...errorResponse,
      error: error.message,
      status: APIResponseStatus.CANCELLED,
    };
  }
  return {
    ...errorResponse,
    error: errorResponse.data?.Message ?? errorResponse.data?.Errors,
  };
};

export const isCancelResponse = (
  response?: APIResponse | APIResponseError
): boolean => {
  return !isNil(response) && response.status === APIResponseStatus.CANCELLED;
};

export const isSuccessResponse = (
  response?: APIResponse | APIResponseError
): boolean => {
  return !isNil(response) && response.status === APIResponseStatus.SUCCESS;
};

export const isNonAuthoritativeResponse = (
  response?: APIResponse | APIResponseError
): boolean => {
  return (
    !isNil(response) && response.status === APIResponseStatus.NON_AUTHORITATIVE
  );
};

export const isBadRequestResponse = (
  response?: APIResponse | APIResponseError
): boolean => {
  return !isNil(response) && response.status === APIResponseStatus.BAD_REQUEST;
};

export const isSuccessIdentityPacket = (
  response?: APIResponse<ResponsePacket | undefined>
): boolean => {
  return (
    !isNil(response) &&
    response.status === APIResponseStatus.SUCCESS &&
    (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success ||
      response.data?.ErrorStatus === IdentityPacketErrorStatusNumber.Success)
  );
};

export const dataTransformer: any = (data: AxiosRequestConfig) => {
  if (data instanceof FormData) {
    return data; // No transformation needed for FormData
  }
  if (data instanceof Date) {
    return toIsoStringTimeZone(data);
  }
  if (Array.isArray(data)) {
    return data.map((val) => dataTransformer(val));
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [key, dataTransformer(val)])
    );
  }

  return data;
};

export const toIsoStringTimeZone = (date: Date) => {
  let tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    "." +
    pad(date.getMilliseconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};
