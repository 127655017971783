import { colCategoryPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker-search/config";
import { searchLocationOnly } from "@app/products/town-planning/ppr/[id]/components/input-picker/trim-location-picker/api";
import { colTrimLocationPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/trim-location-picker/config";
import { ITrimLocation } from "@app/products/town-planning/ppr/[id]/components/input-picker/trim-location-picker/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { ICCInputPickerProps } from "@components/cc-input-picker/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNull, isUndefined } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./_index.scss";
const nameOf = nameOfFactory<ITrimLocation>();
export const TrimLocationPickerSearch = (props: ICCInputPickerProps) => {
  const {
    validationMessage,
    visited,
    className,
    dialog = {
      maxWidth: "60%",
      height: "auto",
      titleHeader: "Trim Locations",
    },
    grid = {
      columnFields: [],
      primaryField: "ID",
      selectableMode: "single",
    },
    value,
    textField,
    textProduce,
    valueField,
    valueProduce,
    onInputChange,
    onChange,
    disabled,
    customDialog,
    onButtonClick,
    isLoading,
    onLoadData,
    showClearButton,
    ...others
  } = props;

  const [searchKey, setSearchKey] = useState("");
  const [resultsSearch, setResultsSearch] = useState<ITrimLocation[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(searchKey, 500);

  const [showDialog, setShowDialog] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>(
    props.grid?.selectedRows ?? []
  );

  const inputValue = useMemo(() => {
    if (isUndefined(value) || isNull(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField, textProduce]);

  const handleOnChange = useCallback(
    (value: any) => {
      setShowDialog(false);
      if (!onChange) return;
      if (valueProduce) return onChange({ value: valueProduce(value) });

      if (valueField && !isArray(value) && valueField in value)
        return onChange({ value: value[valueField] });

      return onChange({ value });
    },
    [onChange, valueField, valueProduce]
  );

  const handleOnClickButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onButtonClick) return onButtonClick(event);
    setSearchKey("");
    setShowDialog(true);
    setGridSelectedRows(props.grid?.selectedRows ?? []);
    if (onLoadData) return onLoadData();
  };

  useEffect(() => {
    (async () => {
      if (debouncedSearch) {
        setIsSearching(true);
        const response = await searchLocationOnly(debouncedSearch);
        if (!isSuccessResponse(response)) {
          setNotifications([
            {
              id: "1",
              title: "Get location failed",
              type: "error",
            },
          ]);
        }
        setResultsSearch(response.data?.Listing || []);
        setIsSearching(false);
      }
    })();
  }, [debouncedSearch]);

  const ItemRender = (
    li: ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <div className="cc-search-item">
        {colCategoryPickerSearch.map((col: IColumnFields) => (
          <div key={col.field}>{sanitizeHtml(dataItem[col.field] ?? "")}</div>
        ))}
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    setSearchKey(searchText);
  };

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        {isHTML(inputValue) ? (
          <div className="cc-input-picker-html k-textbox">
            {sanitizeHtml(inputValue)}
          </div>
        ) : (
          <ComboBox
            disabled={disabled}
            required={!others.valid}
            filterable
            suggest
            data={resultsSearch}
            loading={isSearching}
            onFilterChange={handleSearch}
            itemRender={ItemRender}
            value={inputValue}
            onChange={(e: ComboBoxChangeEvent) => {
              e.target.value
                ? handleOnChange([e.target.value])
                : handleOnChange(null);
            }}
            popupSettings={{ className: "cc-location-picker-search" }}
          />
        )}
        {showClearButton && (
          <Button
            disabled={disabled}
            className="cc-input-picker-button cc-clear-button"
            icon="close"
            onClick={(event: any) => {
              event.preventDefault();
              handleOnChange(null);
            }}
          />
        )}
        <Button
          disabled={disabled}
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          onClick={handleOnClickButton}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {showDialog &&
        (customDialog ? (
          customDialog(value, () => setShowDialog(false), handleOnChange)
        ) : (
          <CCDialog
            {...dialog}
            height="auto"
            onClose={() => setShowDialog(false)}
            bodyElement={
              <div className="cc-field-group cc-add-recipient-dialog">
                <CCLocalNotification defaultNotifications={notifications} />
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Search</label>
                    <Field
                      name={"searchKey"}
                      component={CCInput}
                      placeholder="Search"
                      onChange={(e: InputChangeEvent) => {
                        setSearchKey(e.value);
                      }}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <CCGrid
                    {...grid}
                    columnFields={colTrimLocationPicker}
                    primaryField={grid.primaryField}
                    data={searchKey ? resultsSearch : undefined}
                    dataUrl={
                      searchKey ? undefined : "" //odata
                    }
                    selectableMode={grid.selectableMode}
                    isLoading={isSearching}
                    isAutoHiddenPager={false}
                    itemPerPage={10}
                    selectedRows={gridSelectedRows}
                    onSelectionChange={(dataItems) => {
                      setGridSelectedRows(dataItems);
                    }}
                    state={{
                      sort: [
                        {
                          field: nameOf("Name"),
                          dir: "asc",
                        },
                      ],
                    }}
                  />
                </div>
                <br />
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={gridSelectedRows.length === 0}
                  onClick={() => {
                    handleOnChange(
                      grid.selectableMode === "multiple"
                        ? gridSelectedRows
                        : gridSelectedRows[0]
                    );
                  }}
                >
                  Select
                </Button>
              </div>
            }
          />
        ))}
    </>
  );
};
