import { Field } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./EditableField.scss";

const EditableField = (props: any) => {
  const { name, component, ...others } = props;
  const getValue = props.formRenderProps.valueGetter;

  const [isEditing, setIsEditing] = useState(false);
  const [previousValue, setPreviousValue] = useState();

  useEffectOnce(() => {
    setPreviousValue(getValue(name));
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (props.handleSaveChange(name, getValue(name))) {
      setPreviousValue(getValue(name));
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    props.formRenderProps.onChange(name, {
      value: previousValue,
    });
  };

  return (
    <div className="flex-container editable-field">
      <Field
        autoComplete="off"
        {...others}
        name={name}
        // disabled={true}
        readOnly={!isEditing}
        className={isEditing ? "" : "disabled"}
        component={component}
      />

      <div className="input-group-append">
        <span className="input-group-text bg-component input-group-text-picker">
          {!isEditing && (
            <button onClick={handleEdit} type="button" className="link-button">
              <i className="fas fa-edit color-accent" />
            </button>
          )}
          {isEditing && (
            <>
              <button
                data-toggle="dropdown"
                type="button"
                className="link-button"
              >
                <i className={"fa fa-check color-success"}></i>
              </button>

              <div className="dropdown-menu">
                <a
                  onClick={handleSave}
                  className={
                    props.formRenderProps.errors[props.name]
                      ? "dropdown-item disabled"
                      : "dropdown-item"
                  }
                  href="!#"
                >
                  <i
                    className={
                      props.formRenderProps.errors[props.name]
                        ? "fa fa-check disabled"
                        : "fa fa-check color-success"
                    }
                  ></i>
                  <span className="ml-2">Save</span>
                </a>
                <div role="separator" className="dropdown-divider"></div>
                <a onClick={handleCancel} className="dropdown-item" href="!#">
                  <i className={"fa fa-times color-warning"}></i>
                  <span className="ml-2">Cancel</span>
                </a>
              </div>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditableField;
