import { useState } from "react";
import { useParams } from "react-router-dom";
import { DTO_DynamicQuestionnaireResponse } from "@app/products/property/certificates/[id]/components/child-screens/responses/model";
import Loading from "@components/loading/Loading";
import React from "react";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { APIResponseError } from "@common/apis/model";
import { getCertificateResponses } from "@app/products/property/certificates/[id]/components/child-screens/responses/api";
import { isSuccessResponse } from "@common/apis/util";
import { useEffectOnce } from "react-use";
import { CCLabel } from "@components/cc-label/_index";
import { ChecklistAnswerType } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { isEqual, map, split, toString } from "lodash";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { CCCheckboxGroup } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/responses/components/checkbox-group/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { NoData } from "@components/no-data/NoData";

export const CertificateResponses = () => {
  const { id } = useParams<{ id: string }>();
  const [responsesData, setResponsesData] = useState<
    DTO_DynamicQuestionnaireResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const loadResponsesData = async (id: number | string) => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    let response = await getCertificateResponses(5, id); // use componentNumber = 5 from API
    if (isSuccessResponse(response)) {
      setResponsesData(response?.data?.Responses);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  const renderAnswerField = (
    id: number | null,
    checklistAnswerType: ChecklistAnswerType | null,
    answerField: string,
    index: number
  ) => {
    const answers = split(answerField, "|");
    const valueField = responsesData?.[index]?.DQ_AnswerResponse;

    switch (checklistAnswerType) {
      case ChecklistAnswerType.CheckBox:
        return (
          <Field
            name={toString(id)}
            value={valueField}
            data={answers.map((answer) => ({ label: answer, value: false }))}
            component={CCCheckboxGroup}
            disabled
          />
        );
      case ChecklistAnswerType.RadioButton:
        return (
          <Field
            name={toString(id)}
            component={CCSwitch}
            disabled
            checked={isEqual(valueField, "Yes")}
          />
        );
      case ChecklistAnswerType.DatePicker:
        return (
          <Field
            name={toString(id)}
            component={CCDatePicker}
            disabled
            format={DATE_FORMAT.DATE_CONTROL}
            value={valueField}
          />
        );
      case ChecklistAnswerType.Label:
        return (
          <Field
            name={toString(id)}
            component={CCInput}
            readOnly
            value={valueField}
          />
        );
      case ChecklistAnswerType.TextBox:
        return (
          <Field
            name={toString(id)}
            component={CCTextArea}
            readOnly
            rows={3}
            value={valueField}
          />
        );
      default:
        return null;
    }
  };

  useEffectOnce(() => {
    loadResponsesData(id);
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (id) {
            loadResponsesData(id);
          }
        }}
      />
    );
  if (!responsesData) return <NoData vertical />;

  return (
    <Form
      render={() => (
        <FormElement>
          <div className="cc-field-group">
            {map(
              responsesData,
              (question: DTO_DynamicQuestionnaireResponse, index: number) => {
                return (
                  <div
                    className="cc-field"
                    key={question.DQ_ChecklistQuestion_Id}
                  >
                    <CCLabel title={question?.DQ_Question} />
                    {renderAnswerField(
                      question.DQ_ChecklistQuestion_Id,
                      question.DQ_ChecklistAnswerType,
                      question.DQ_AnswerField,
                      index
                    )}
                  </div>
                );
              }
            )}
            <br />
          </div>
        </FormElement>
      )}
    />
  );
};
