import { SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE } from "@common/pages/settings/lookups/action-types/_id/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface NewActionTypesButtonProps {
  disabled?: boolean;
}
export const NewActionTypesButton = ({
  disabled,
}: NewActionTypesButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
