export const actionTaskScheduleData = [
  {
    label: "Run immediately",
    value: true,
  },
  {
    label: "Run at",
    value: false,
  },
];
