import { history } from "@/AppRoutes";
import { getParentSummary } from "@app/core/further-info/[id]/api";
import { Ancestor } from "@app/core/further-info/[id]/model";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import {
  BubbleUpIdentifier,
  BubbleUpType,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE, mapRecordType } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { CoreKeyword } from "@common/models/coreKeyword";
import { IdentityPacket } from "@common/models/identityPacket";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { getActionById, postAction } from "@common/pages/actions/api";
import { cancelMeetingStore } from "@common/pages/actions/components/action-bar/buttons/cancel-meeting/store";
import { actionCompleteStore } from "@common/pages/actions/components/action-bar/buttons/complete-action/store";
import { meetingCompleteStore } from "@common/pages/actions/components/action-bar/buttons/meeting-complete/store";
import { reOpenMeetingStore } from "@common/pages/actions/components/action-bar/buttons/reopen-meeting/store";
import { returnToSenderButtonStore } from "@common/pages/actions/components/action-bar/buttons/return-to-sender/store";
import { sendToActionOfficerButtonStore } from "@common/pages/actions/components/action-bar/buttons/send-to-action-officer/store";
import {
  Action,
  ActionSubmitActions,
  IParentActionSection,
} from "@common/pages/actions/model";
import { globalStoreInstance } from "@common/stores/global/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ActionStore {
  private _action?: Action = undefined;
  private _isLoading: boolean = false;
  private _isLoadingAction: boolean = false;
  private _isLoadingParent: boolean = false;
  private _isLoadingDefaultOfficer: boolean = false;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parent?: IParentActionSection = undefined;
  private _ancestor?: Ancestor = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _pprActionOutcome?: CoreKeyword[] = [];
  private _assessmentActionOutcome?: CoreKeyword[] = [];
  private _actionDirection?: CoreKeyword[] = [];
  private _actionMeetingLocation?: CoreKeyword[] = [];
  private _isMeeting: boolean = false;
  private _isInactive: boolean = false;
  private _parentBubbleUp?: BubbleUpIdentifier[] = [];
  private _defaultOfficer?: SiteUser = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get action() {
    return this._action;
  }
  setAction = (action?: any) => {
    runInAction(() => {
      this._action = action;
    });
  };

  get actionId() {
    return this.action?.Action_ID;
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  //loading for submit
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  // loading for get action data (loadAction)
  get isLoadingAction() {
    return this._isLoadingAction;
  }
  setIsLoadingAction = (isLoadingAction: boolean) => {
    runInAction(() => {
      this._isLoadingAction = isLoadingAction;
    });
  };

  get isLoadingDefaultOfficer() {
    return this._isLoadingDefaultOfficer;
  }
  setIsLoadingDefaultOfficer = (isLoadingAction: boolean) => {
    runInAction(() => {
      this._isLoadingDefaultOfficer = isLoadingAction;
    });
  };

  get isLoadingParent() {
    return this._isLoadingParent;
  }
  setIsLoadingParent = (isLoadingParent: boolean) => {
    runInAction(() => {
      this._isLoadingParent = isLoadingParent;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentActionSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get defaultOfficer() {
    return this._defaultOfficer;
  }

  setDefaultOfficer = (defaultOfficer?: SiteUser) => {
    runInAction(() => {
      this._defaultOfficer = defaultOfficer;
    });
  };

  get parentBubbleUp() {
    return this._parentBubbleUp;
  }

  setParentBubbleUp = (parentBubbleUp: BubbleUpIdentifier[]) => {
    runInAction(() => {
      this._parentBubbleUp = parentBubbleUp;
    });
  };

  get pprActionOutcome() {
    return toJS(this._pprActionOutcome);
  }
  setPPRActionOutcome = (pprActionOutcome?: CoreKeyword[]) => {
    runInAction(() => {
      this._pprActionOutcome = pprActionOutcome;
    });
  };

  get assessmentActionOutcome() {
    return toJS(this._assessmentActionOutcome);
  }
  setAssessmentActionOutcome = (assessmentActionOutcome?: CoreKeyword[]) => {
    runInAction(() => {
      this._assessmentActionOutcome = assessmentActionOutcome;
    });
  };

  get actionDirection() {
    return toJS(this._actionDirection);
  }
  setActionDirection = (actionDirection?: CoreKeyword[]) => {
    runInAction(() => {
      this._actionDirection = actionDirection;
    });
  };

  get actionMeetingLocation() {
    return toJS(this._actionMeetingLocation);
  }
  setActionMeetingLocation = (actionMeetingLocation?: CoreKeyword[]) => {
    runInAction(() => {
      this._actionMeetingLocation = actionMeetingLocation;
    });
  };

  get isMeeting() {
    return toJS(this._isMeeting);
  }
  setIsMeeting = (isMeeting: boolean) => {
    runInAction(() => {
      this._isMeeting = isMeeting;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isLoadingParent = false;
      this._isLoadingAction = false;
      this._isLoadingDefaultOfficer = false;
      this._onSubmit = undefined;
      this._action = undefined;
      this._parent = undefined;
      this._ancestor = undefined;
      this._isMeeting = false;
      this._defaultOfficer = undefined;
    });
  };

  loadDefaultOfficer = async (currentUserid?: number): Promise<boolean> => {
    if (!currentUserid) {
      appNotificationStore.pushNotification({
        title: "Load default Officer fail.",
        type: "error",
        autoClose: false,
      });
      return false;
    }
    this.setIsLoadingDefaultOfficer(true);
    const response = await getSiteUser(currentUserid);
    this.setIsLoadingDefaultOfficer(false);
    if (isSuccessResponse(response) && response?.data) {
      this.setDefaultOfficer(response.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        title: response?.error ?? "Load default Officer fail.",
        type: "error",
        autoClose: false,
      });
      return false;
    }
  };

  // Not use in this scope will use if expand
  loadLOVsAction = async () => {
    const resActionOutcome = await getKeywords(
      KEYWORD_TYPE.Core_ActionOutcome,
      PRODUCT_TYPE_NUMBER.Core
    );
    if (isSuccessResponse(resActionOutcome) && resActionOutcome?.data) {
      this.setPPRActionOutcome(resActionOutcome.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: resActionOutcome?.error ?? "Action Outcome load failed",
        type: "error",
      });
    }

    const resAssessmentActionOutcome = await getKeywords(
      KEYWORD_TYPE.Core_AssessmentActionOutcome,
      PRODUCT_TYPE_NUMBER.Core
    );
    if (
      isSuccessResponse(resAssessmentActionOutcome) &&
      resAssessmentActionOutcome?.data
    ) {
      this.setAssessmentActionOutcome(resAssessmentActionOutcome.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          resAssessmentActionOutcome?.error ??
          "Assessment Action Outcome load failed",
        type: "error",
      });
    }

    const resActionDirection = await getKeywords(
      KEYWORD_TYPE.Core_InformationSharing_Direction,
      PRODUCT_TYPE_NUMBER.DWCMS
    );
    if (isSuccessResponse(resActionDirection) && resActionDirection?.data) {
      this.setActionDirection(resActionDirection.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: resActionDirection?.error ?? "Action Direction load failed",
        type: "error",
      });
    }

    const resActionMeetingLocation = await getKeywords(
      KEYWORD_TYPE.Core_ActionMeetingLocation,
      PRODUCT_TYPE_NUMBER.DWCMS
    );
    if (
      isSuccessResponse(resActionMeetingLocation) &&
      resActionMeetingLocation?.data
    ) {
      this.setActionMeetingLocation(resActionMeetingLocation.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          resActionMeetingLocation?.error ??
          "Action Meeting Location load failed",
        type: "error",
      });
    }
  };

  loadAction = async (recordId?: number): Promise<boolean> => {
    this.setIsLoadingAction(true);
    let errorResponse = undefined;
    let parentNotification: IAppNotificationItemAddProps[] = [];

    let newAction: Action | undefined;
    if (recordId) {
      const response = await getActionById(recordId);
      this.setIsLoadingAction(false);
      if (isSuccessResponse(response)) {
        newAction = response.data;
        this.setAction(newAction);
        if (this.parent?.notification) {
          parentNotification = this.parent.notification;
          this.setParent({ ...this.parent, notification: [] });
        }
        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
    }
    const sourceIdentifier = newAction?._BubbleUps.find(
      (bubbleUp) => bubbleUp.BubbleUpType_ENUM === BubbleUpType.Parent
    )?.SourceIdentifier;
    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType:
          mapRecordType[sourceIdentifier._RecordSourceType_ENUM] ??
          RECORDTYPE.TP_PPRApplication,
      };
      await this.loadParent(newParent, false);
    }

    parentNotification.forEach((notification: IAppNotificationItemAddProps) => {
      appNotificationStore.pushNotification(notification);
    });
    this.setResponseLoadError(errorResponse);
    this.setIsLoadingAction(false);
    return isUndefined(errorResponse);
  };

  loadParent = async (parent: IParentActionSection, isNew: boolean) => {
    parent.bubbleUps = this.parentBubbleUp;
    this.setParent(parent);
    if (parent.actionType && !parent.id && isNew) {
      this.setAction({
        ...parent.data,
        Action_ID: 0,
      } as Action);
      return true;
    }
    this.setIsLoadingParent(true);
    const response = await getParentSummary(parent.id, parent.recordType);
    this.setIsLoadingParent(false);
    if (isSuccessResponse(response) && response.data) {
      if (isNew) {
        this.setAction({
          ...parent.data,
          Action_ID: 0,
          BubbleUps: parent?.bubbleUps ?? [],
        } as Action);
      }
      this.setAncestor(response.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load parent action data failed",
      });
      return false;
    }
  };

  saveAction = async (actionInfo: Action, action: ActionSubmitActions) => {
    let parentIdResp = null;
    let recordTypeResp = null;
    const isNewAction = action === ActionSubmitActions.New;

    this.setIsLoading(true);
    if (!this.parent) {
      recordTypeResp = RECORDTYPE.CORE_Register;
    } else {
      const { id: parentId, recordType } = this.parent;
      parentIdResp = parentId;
      recordTypeResp = recordType;
    }

    const response = await postAction(
      actionInfo,
      parentIdResp,
      recordTypeResp,
      !isNewAction
    );

    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        if (this.actionId) await this.loadAction(this.actionId);
        await this.runActions(action, response.data);
      } else {
        this.setIsLoading(false);
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Action could not be saved.",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      this.setIsLoading(false);
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Action could not be saved.",
        type: "error",
        description: response.data?.Errors,
      });
    }
  };

  reLoadAction = async (): Promise<boolean> => {
    if (this.actionId) return await this.loadAction(this.actionId);
    return false;
  };

  runActions = (action: ActionSubmitActions, response?: IdentityPacket) => {
    switch (action) {
      case ActionSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Action saved successfully",
          type: "success",
        });
        break;
      case ActionSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${ACTIONS_ROUTE}/${response?.ID}`, {
          parent: {
            notification: [
              { title: "Record saved successfully", type: "success" },
            ],
          },
        });
        break;
      case ActionSubmitActions.SendToActionOfficer:
        if (this.action && this.parent) {
          sendToActionOfficerButtonStore.sendToActionOfficer(
            this.action,
            this.parent
          );
        } else if (this.action) {
          sendToActionOfficerButtonStore.sendToActionOfficer(this.action);
        }
        break;
      case ActionSubmitActions.ActionComplete:
        actionCompleteStore.loadActionCompleteDetails(
          globalStoreInstance.currentUserInfo
        );
        break;
      case ActionSubmitActions.ReturnToSender:
        if (this.actionId) {
          returnToSenderButtonStore.returnToSender(this.actionId);
        }
        break;
      case ActionSubmitActions.CreateAndMeetingComplete:
        appNotificationStore.clearErrorNotification();
        history.replace(`${ACTIONS_ROUTE}/${response?.ID}`);

        if (response?.ID) {
          meetingCompleteStore.meetingCompleteAction(response?.ID);
        }
        break;
      case ActionSubmitActions.MeetingComplete:
        if (this.actionId) {
          meetingCompleteStore.meetingCompleteAction(this.actionId);
        }
        break;
      case ActionSubmitActions.CreateAndCancelMeeting:
        appNotificationStore.clearErrorNotification();
        history.replace(`${ACTIONS_ROUTE}/${response?.ID}`);

        if (response?.ID) {
          cancelMeetingStore.cancelMeetingAction(response?.ID);
        }
        break;
      case ActionSubmitActions.CancelMeeting:
        if (this.actionId) {
          cancelMeetingStore.cancelMeetingAction(this.actionId);
        }
        break;
      case ActionSubmitActions.ReOpenMeeting:
        if (this.actionId) {
          reOpenMeetingStore.reOpenMeeting(this.actionId);
        }
        break;
      default:
        break;
    }
  };
}

export const actionStore = new ActionStore();
const ActionStoreContext = createContext(actionStore);
export const useActionStore = () => {
  return useContext(ActionStoreContext);
};
