import { ComboBox, ComboBoxProps } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import React from "react";

interface ICCComboBoxProps {
  validationMessage?: string | null;
  visited?: boolean;
  isFromDropdown?: boolean; // Add to distinguish change from dropdown
}

export const CCComboBox = (props: ICCComboBoxProps & ComboBoxProps) => {
  const { validationMessage, visited, ...others } = props;

  return (
    <>
      <ComboBox {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
