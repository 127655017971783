import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { useSettingConfirmExitStore } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/store";
import { putSetting } from "@app/products/property/system-admin/settings/api";
import { CCSettingPanel } from "@app/products/property/system-admin/settings/components/setting-panel/_index";
import { ICollapseState } from "@app/products/property/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ComponentSettingForm } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/existed/components/component-form/_index";
import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import {
  convertObjectToArray,
  processDataForm,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/util";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep, differenceWith, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import "./_index.scss";
export const ExistedPPRSettingManage = observer(() => {
  const { dataSetting } = useSettingPPRStore();
  const { setConfirmExit } = useSettingConfirmExitStore();
  const { isEditing, setIsEditing } = useSettingPPRManageStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [collapseState, setCollapseState] = useState<ICollapseState>({
    1: true,
  });

  const [originData, setOriginData] = useState<IData>();
  const [data, setData] = useState<IData>();
  const [isLoading, setIsLoading] = useState(false);

  const nameGroup = useMemo(() => {
    return dataSetting?.EditGroups[0].Name ?? "";
  }, [dataSetting]);

  useEffect(() => {
    const processData = processDataForm(dataSetting?.SettingFields);
    if (processData) {
      const cloneData = cloneDeep(processData);
      setData(cloneData);
      setOriginData(cloneData);
    }
    return () => {
      setIsEditing(false);
      setConfirmExit(false); //TODO: temporary fix -> re-render 2 times when unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSetting]);

  useEffect(() => {
    setConfirmExit(isEditing);
  }, [isEditing, setConfirmExit]);

  const handleCancel = () => {
    setIsEditing(false);
    setData(originData);
  };

  const handleEdit = () => {
    setIsEditing(true);
    setCollapseState({
      1: true,
    });
  };

  const handleSubmit = (values: { [name: string]: any }): void => {
    const cloneOriginData: IData = { ...originData };
    const cloneData: IData = { ...values };

    const newCloneOriginData = convertObjectToArray(cloneOriginData);
    const newCloneData = convertObjectToArray(cloneData);

    const compareDifferentChanged = differenceWith(
      newCloneData,
      newCloneOriginData,
      isEqual
    );

    if (compareDifferentChanged.length) {
      const getListNameFileChanged = compareDifferentChanged.map(
        (item) => item.key
      );
      const settingFields = cloneDeep(dataSetting.SettingFields);
      const resultSettingFields = settingFields.filter((item: SettingField) =>
        getListNameFileChanged.includes(item.FieldName)
      );
      resultSettingFields.forEach((field: SettingField) => {
        field.Value = values[field.FieldName];
        // fix the issue sanitize, this field contain HTML
        // which is block by backend sanitize rule
        delete field.Description;
      });

      const payLoads = {
        Name: dataSetting.Name,
        Title: dataSetting.Title,
        ItemId: dataSetting.ItemId,
        Mode: dataSetting.Mode,
        Description: dataSetting.Description,
        ProductType_Enum: dataSetting.ProductType_Enum,
        SettingCategoryName: dataSetting.SettingCategoryName,
        UseIsActive: dataSetting.UseIsActive,
        IsEditable: dataSetting.IsEditable,
        SettingFields: resultSettingFields,
        EditGroups: dataSetting.EditGroups,
        ParentFolders: dataSetting.ParentFolders,
      };

      if (!payLoads) {
        pushNotification({
          title: `Some thing went wrong`,
          autoClose: false,
          type: "error",
        });
        return;
      }
      setIsLoading(true);
      putSetting(payLoads)
        .then((response: IResponseData) => {
          if (response.status === APIResponseStatus.SUCCESS) {
            setData(values);
            setOriginData(values);
            setIsEditing(false);
            setIsLoading(false);
            pushNotification({
              title: `Updated successfully`,
              type: "success",
            });
            eventEmitter.emit(CCJournalEventType.RefreshData);
          } else if (response.status === APIResponseStatus.BAD_REQUEST) {
            const res = response.data;
            const messages = res.Messages ? res.Messages : [];
            const typeMessage =
              res.MessageType === "Flash Information"
                ? "info"
                : res.MessageType.toLowerCase();
            const isClose =
              res.MessageType === "Flash Information" ? true : false;
            pushNotification({
              title: res.HeaderMessage,
              autoClose: isClose,
              description: messages,
              type: typeMessage,
            });
            setIsLoading(false);
            setData(originData);
          } else {
            pushNotification({
              title: `Error occurred`,
              autoClose: false,
              type: "error",
            });
            setIsLoading(false);
            setData(originData);
          }
        })
        .catch(() => {
          pushNotification({
            title: `Error occurred`,
            autoClose: false,
            type: "error",
          });
          setIsLoading(false);
          setData(originData);
        });
    }
  };
  return (
    <div className="cc-exist-ppr-setting-manage-form cc-form">
      {data && (
        <Form
          onSubmit={handleSubmit}
          initialValues={data}
          key={JSON.stringify(data)}
          render={(formRenderProps: FormRenderProps) => {
            const { onFormReset, valid, modified, onSubmit } = formRenderProps;
            return (
              <FormElement>
                <CCSettingPanel
                  isInvalid={!valid && modified}
                  isExpanded={collapseState[1]}
                  handleDivButton={() =>
                    setCollapseState({
                      1: !collapseState[1],
                    })
                  }
                  isBoldCollapse
                  title={nameGroup}
                  classNameChildren="cc-manage-content-settings"
                  buttonGroupElement={
                    <>
                      {dataSetting?.IsEditable ? (
                        <div
                          className="cc-button-wrapper"
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                        >
                          <CCNavButton
                            className={`${
                              isEditing ? "cc-edit-hidden " : " "
                            } cc-manage-button`}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEdit();
                            }}
                            disabled={isLoading}
                            iconClass="fa fa-pencil"
                            title="Edit"
                            isLoading={isLoading}
                          />

                          {isEditing && (
                            <>
                              <CCNavButton
                                className={`cc-save-button${
                                  !valid || !modified
                                    ? " cc-button-disabled"
                                    : " cc-button-enabled"
                                }`}
                                disabled={!valid || !modified}
                                id="btn-save"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  onSubmit(e);
                                  onFormReset();
                                }}
                                iconClass="fa fa-check-circle"
                                title="Save"
                              />
                              <CCNavButton
                                className="cc-cancel-button"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  onFormReset();
                                  handleCancel();
                                }}
                                iconClass="fa fa-times-circle"
                                title="Cancel"
                                disabled={isLoading}
                              />
                            </>
                          )}
                        </div>
                      ) : null}
                    </>
                  }
                >
                  <>
                    {isLoading ? (
                      <Loading isLoading={isLoading} />
                    ) : (
                      <>
                        <CCAppNotification />
                        <div className="cc-ppr-component-setting">
                          <ComponentSettingForm
                            formRenderProps={formRenderProps}
                          />
                        </div>
                      </>
                    )}
                  </>
                </CCSettingPanel>
              </FormElement>
            );
          }}
        />
      )}
    </div>
  );
});
