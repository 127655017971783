import { history } from "@/AppRoutes";
import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { HMPremisesRegisterView } from "@app/products/hm/premises/model";
import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep, isEmpty, isNil } from "lodash";

export const colHMPremisesClosures = [
  {
    field: nameOfHMPremisesView("RegNo"),
    title: "Registration Number",
    locked: true,
    handleOnClick: (rowValue: HMPremisesRegisterView) => {
      // Confirmed with Zach and Rebecca in the catch-up meeting 03/10/2024
      // If the RegNo is empty or null, click on the blank cell to navigate
      if (isEmpty(rowValue?.RegNo) || isNil(rowValue?.RegNo)) {
        history.push(`${PREMISES_MANAGE_ROUTE}/${rowValue.ID}`);
      }
    },
    linkTo: (dataItem: HMPremisesRegisterView) => {
      return `${PREMISES_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  {
    field: nameOfHMPremisesView("TradingName"),
    title: "Trading Name",
  },
  ...iconColumnViewConfigAlert,
  {
    field: nameOfHMPremisesView("LocationDesc"),
    title: "Location Description",
  },
  {
    field: nameOfHMPremisesView("Date_Closed"),
    title: "Closed",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("AddressLine1"),
    title: "Address",
  },
  {
    field: nameOfHMPremisesView("Area"),
    title: "Area",
  },
  {
    field: nameOfHMPremisesView("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOfHMPremisesView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfHMPremisesView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfHMPremisesView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfHMPremisesView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfHMPremisesView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfHMPremisesView("Email"),
    title: "Email",
  },
  {
    field: nameOfHMPremisesView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: nameOfHMPremisesView("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfHMPremisesView("ClosureType"),
    title: "Closure Type",
  },
];

export const generatePremisesCloseColBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  const registrationNoLabel = getStringValueSetting(
    settings[ECorporateSettingsField.HealthManager_RegistrationNoLabel]
  );
  const isShowLocationDesc =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowLoactionDesc]
    ) ?? false;
  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    let isVisible = true;

    switch (col.field) {
      case nameOfHMPremisesView("LocationDesc"):
        return isShowLocationDesc;
      case nameOfHMPremisesView("RegNo"):
        if (registrationNoLabel) {
          col.title = registrationNoLabel;
        }
        break;
    }

    return isVisible;
  });
};
