import { ExistManageInspection } from "@app/core/inspections/[id]/components/forms/exist/_index";
import { NewManageInspection } from "@app/core/inspections/[id]/components/forms/new/_index";
import { IParentInspectionsSection } from "@app/core/inspections/[id]/model";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const ManageInspection = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadInspections, resetStore, setParent } = useInspectionsStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { state } = useLocation<{
    parent?: IParentInspectionsSection;
  }>();

  useEffect(() => {
    if (state?.parent) {
      setParent(state?.parent);
    }

    if (isNew) {
      loadInspections(0, isNew);
    } else {
      loadInspections(parseInt(id), isNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew, state]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
      window.history.replaceState({}, "");
    };
  });

  if (isNew) {
    return <NewManageInspection />;
  }

  return <ExistManageInspection />;
});

export default ManageInspection;
