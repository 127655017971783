import { mockParkingPermitContactTab } from "@app/products/parking-permits/mock";
import { colChargeRunJournals } from "@app/products/property/charge-and-notice-runs/charge-run-journals/config";
import { PROPERTY_CHARGE_RUN_JOURNALS_LISTS_VIEW_URL } from "@app/products/property/charge-and-notice-runs/charge-run-journals/constant";
import { chargeAndNoticeRunsRoute } from "@app/products/property/charge-and-notice-runs/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { JournalDetailTab } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/_index";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<VO_JournalList>();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={chargeAndNoticeRunsRoute.path} />,
    ],

    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={PROPERTY_CHARGE_RUNS_ROUTE}
      //   displayName={getBookmarkDisplayName}
      //   detail={getBookmarkDetail}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <JournalDetailTab /> },
      {
        title: "Contacts",
        component: (
          <ContactsSidebar
            data={mockParkingPermitContactTab as ICommonContactItemRender[]}
          />
        ),
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            //Hyperlink open Journal -> Use Journal
            recordType={RECORDTYPE.CommunityProperty_Journal}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      dataUrl={PROPERTY_CHARGE_RUN_JOURNALS_LISTS_VIEW_URL}
      columnFields={colChargeRunJournals}
      primaryField={nameOf("Journal_Number")}
      state={{ sort: [{ field: nameOf("Journal_Number"), dir: "desc" }] }}
    />
  );
};
