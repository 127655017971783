import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { WindowLog } from "@common/pages/settings/system-admin/windows-logs/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<WindowLog>();
export const colWindowsLogs: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
  },
  {
    field: nameOf("EntryType"),
    title: "Level",
  },
  {
    field: nameOf("TimeGenerated"),
    title: "Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Source"),
    title: "Source",
  },
  {
    field: nameOf("Message"),
    title: "Message",
  },
];
