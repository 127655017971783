import { PERCENTAGE_FORMAT } from "@common/constants/common-format";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import React from "react";

export const FactorCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  return (
    <CCPercentInput
      min={1}
      max={100}
      format={PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL}
      value={value}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value ?? 1,
          });
        }
      }}
    />
  );
};
