import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyComplianceGeneralFormElement } from "./components/form-element/_index";

export const PropertyComplianceGeneral = observer(() => {
  const { compliance } = useComplianceStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={compliance?.Compliance}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyComplianceGeneralFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
