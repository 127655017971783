import {
  DTO_LOV_Levy,
  DTO_LOV_RatingPeriod,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import { useNewChargeDetailsAddTransactionStepStore } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { cloneDeep, isNil, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLovs = nameOfFactory<DTO_LOV>();

export const NewChargeDetailsAddTransactionFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    //store
    const {
      setNewChargeDetailsStepLOVs,
      dataLOvsGroupByFinancialGroup,
      newChargeDetailsStepLOVs,
      originNewChargeDetailsStepLOVs,
    } = useNewChargeDetailsAddTransactionStepStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const financialGroupsTransaction = valueGetter(
      "AssessmentTransaction._option.Transaction_Type.Financial_Groups"
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Charge date</label>
            <Field
              name={nameOf("ChargeDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options.isReadOnly}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rating period" isMandatory />
            <Field
              name={nameOf("RatingPeriod")}
              component={CCSearchComboBox}
              data={newChargeDetailsStepLOVs?.RatingPeriods ?? []}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              disabled={options.isReadOnly}
              textField={nameOfLovs("Name")}
              dataItemKey={nameOfLovs("Code")}
              value={getDropdownValue(
                getFieldValue("RatingPeriod"),
                newChargeDetailsStepLOVs?.RatingPeriods ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                if (isNil(financialGroupsTransaction)) {
                  if (
                    isNil(getFieldValue("LevyId")) &&
                    isNil(event?.value) &&
                    originNewChargeDetailsStepLOVs
                  ) {
                    setNewChargeDetailsStepLOVs(originNewChargeDetailsStepLOVs);
                  } else {
                    const financialGroups = event?.value?.Financial_Groups;
                    const newLevy = cloneDeep(
                      dataLOvsGroupByFinancialGroup?.Levy
                    );
                    if (financialGroups?.length && newChargeDetailsStepLOVs) {
                      let listLevy: DTO_LOV_Levy[] = [];
                      financialGroups?.forEach((group: number) => {
                        let levyByGroup: DTO_LOV_Levy[] = [];
                        if (newLevy) {
                          levyByGroup = newLevy[group] ?? [];
                        }
                        listLevy = [...listLevy, ...levyByGroup];
                        listLevy = uniqBy(listLevy, "Code");
                      });
                      setNewChargeDetailsStepLOVs({
                        ...newChargeDetailsStepLOVs,
                        Levy: listLevy,
                      });
                    }
                  }
                }
                onChange(nameOf("RatingPeriod"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("_option.RatingPeriod"), {
                  value: event.value,
                });
              }}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Levy" isMandatory />
            <Field
              name={nameOf("LevyId")}
              component={CCSearchComboBox}
              data={newChargeDetailsStepLOVs?.Levy ?? []}
              textField={nameOfLovs("Name")}
              dataItemKey={nameOfLovs("Code")}
              disabled={options.isReadOnly}
              value={getDropdownValue(
                getFieldValue("LevyId"),
                newChargeDetailsStepLOVs?.Levy ?? [],
                "Code"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("LevyId"), {
                  value: event.value?.Code,
                });
                onChange(nameOf("_option.Levy"), {
                  value: event.value,
                });
                if (isNil(financialGroupsTransaction)) {
                  if (
                    isNil(getFieldValue("RatingPeriod")) &&
                    isNil(event?.value) &&
                    originNewChargeDetailsStepLOVs
                  ) {
                    setNewChargeDetailsStepLOVs(originNewChargeDetailsStepLOVs);
                  } else {
                    let listRatingPeriod: DTO_LOV_RatingPeriod[] = [];
                    const ratingPeriodByGroup = cloneDeep(
                      dataLOvsGroupByFinancialGroup?.RatingPeriods
                    );
                    if (
                      event?.value?.Financial_Group_Id &&
                      newChargeDetailsStepLOVs
                    ) {
                      if (ratingPeriodByGroup) {
                        listRatingPeriod =
                          ratingPeriodByGroup[event?.value?.Financial_Group_Id];
                      }
                      setNewChargeDetailsStepLOVs({
                        ...newChargeDetailsStepLOVs,
                        RatingPeriods: listRatingPeriod,
                      });
                    }
                  }
                }
              }}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Code</label>
            <Field
              name={nameOf("Code")}
              placeholder={"Code"}
              readOnly={options.isReadOnly}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Name" isMandatory />
            <Field
              name={nameOf("Name")}
              placeholder={"Name"}
              readOnly={options.isReadOnly}
              component={CCInput}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Instalment plan</label>
            <Field
              name={nameOf("InstalmentPlanId")}
              component={CCSearchComboBox}
              data={newChargeDetailsStepLOVs?.InstalmentPlans ?? []}
              textField={nameOfLovs("Name")}
              dataItemKey={nameOfLovs("Code")}
              isUseDefaultOnchange
              disabled={options.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Charge amount" />
            <Field
              name={nameOf("Amount")}
              component={CCCurrencyInput}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Apply any remaining rebate entitlements to charge
            </label>
            <Field
              name={nameOf("ApplyRemainingEntitlements")}
              component={CCSwitch}
              disabled={options.isReadOnly}
              checked={getFieldValue("ApplyRemainingEntitlements")}
            />
          </div>
          <div className="cc-col-span-2">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <Field
                name={nameOf("Description")}
                placeholder={"Description"}
                component={CCTextArea}
                rows={3}
                readOnly={options.isReadOnly}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
);
