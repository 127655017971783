import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colMemos: IColumnFields[] = [
  {
    field: "MemoDate",
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Category",
    title: "Category",
  },
  { field: "Memo", title: "Memo" },
  {
    field: "ChangedOn",
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ChangedBy",
    title: "Changed By",
  },
  {
    field: "MemoId",
    title: "Memo ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
