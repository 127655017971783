import { IFirePreventionAddMemo } from "@app/products/property/fire-prevention/hazld-case/[id]/components/dialogs/add-memo/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockAddMemo: IFirePreventionAddMemo = {
  Category: { Key: 1, Value: "General" },
  Memo: "",
  MemoId: 1,
  ChangedOn: new Date(),
  ChangedBy: "Derin Davis",
  MemoDate: new Date(),
};
export const mockDropdownMemoCategory: IKeyValuePacket[] = [
  { Key: 1, Value: "General" },
  { Key: 2, Value: "Item 1" },
  { Key: 3, Value: "Item 2" },
  { Key: 4, Value: "Item 3" },
];
