import { IInstallationDetails } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";
interface IInstallationRemoveDetailProps {
  data?: IInstallationDetails;
}
export const InstallationRemoveDetail = ({
  data,
}: IInstallationRemoveDetailProps) => {
  return (
    <div className="cc-installation-remove-detail">
      <label className="cc-label">Installation/Remove details</label>
      <div className="cc-custom-sub-panel-bar cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Installation date</label>
          <DatePicker
            className="cc-form-control"
            value={data?.InstallationDate}
            disabled
            format={DATE_FORMAT.DATE_CONTROL}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">By</label>
          <Input
            disabled
            className="cc-form-control"
            value={data?.By}
            readOnly
            placeholder={"By"}
          />
        </div>
      </div>
    </div>
  );
};
