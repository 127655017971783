import { FieldProps } from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { filter, includes, join, map, split } from "lodash";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface ICheckboxProps {
  data: Array<{ label: string; value: any }>;
  disabled: boolean;
  onDataChange: (data: string) => void;
}
//TODO: make common component
export const CCCheckboxGroup = ({
  value = null,
  disabled = false,
  className = "",
  visited = false,
  data,
  name,
  onDataChange,
  onChange,
  ...others
}: ICheckboxProps & FieldProps) => {
  const [options, setOptions] =
    useState<Array<{ label: string; value: any }>>(data);

  const answers = useMemo(() => {
    return split(value, "|");
  }, [value]);

  useEffectOnce(() => {
    setOptions(data ?? []);
  });

  const onChangeValue = (event: CheckboxChangeEvent) => {
    const newData = {
      value: event.value,
      label: event.target.name ?? "",
    };
    const updateOptionsLabel = map(options, (option) =>
      option.label === newData.label ? newData : option
    );
    setOptions(updateOptionsLabel);
    const getUpdatedOptionsLabels = join(
      map(filter(updateOptionsLabel, "value"), "label"),
      "|"
    );
    onDataChange(getUpdatedOptionsLabels);
  };

  return (
    <div className="cc-checkbox-group" key={JSON.stringify(options)}>
      {map(options, (dataItem: { label: string; value: any }) => {
        return (
          <div className="cc-field">
            <Checkbox
              {...others}
              name={dataItem.label}
              label={dataItem.label}
              value={dataItem.value}
              checked={includes(answers, dataItem.label)}
              disabled={disabled}
              onChange={onChangeValue}
            />
          </div>
        );
      })}
    </div>
  );
};
