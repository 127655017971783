import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { BUILDING_ROUTE } from "@app/products/building/constant";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { CRMS_PHONE_MESSAGE_ROUTE } from "@app/products/crms/phone-messages/[id]/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";

export const getLinkPageNavigate = (recordType: RECORDTYPE) => {
  switch (recordType) {
    case RECORDTYPE.CORE_Action:
      return `${ACTIONS_ROUTE}`;
    case RECORDTYPE.TP_PPRApplication:
      return `${TOWN_PLANNING_PPR_ROUTE}`;
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return `${CRMS_ROUTE}`;
    case RECORDTYPE.CUSTOMERSERVICE_PhoneMessage:
      return `${CRMS_PHONE_MESSAGE_ROUTE}`;
    case RECORDTYPE.Animals_Registration:
      return `${ANIMALS_ROUTE}`;
    case RECORDTYPE.BUILDING:
      return `${BUILDING_ROUTE}`;
    case RECORDTYPE.EMS_EventBooking:
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}`;
    case RECORDTYPE.HealthManager_Premises:
      return `${PREMISES_MANAGE_ROUTE}`;
    case RECORDTYPE.LLP_Permit:
      return `${LOCAL_LAWS_MANAGE_ROUTE}`;
    case RECORDTYPE.DisRes_Permit:
      return `${PARKING_PERMITS_ROUTE}`;
    case RECORDTYPE.TP_Application:
      return `${TOWN_PLANNING_ROUTE}`;
    case RECORDTYPE.WW_Product:
      return `${WASTE_WATER_ROUTE}`;
    default:
      return "";
  }
};

export const isHTML = (str: string) => {
  var htmlRegex = new RegExp(/<\/?[a-z][\s\S]*>/i);
  return htmlRegex.test(str);
};
