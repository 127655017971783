import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { getStatus } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/api";
import { StatusChangeDetails } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/model";
import { putBatchStatus } from "@app/products/town-planning/ppr/components/action-bar/buttons/update-status/api";

import { PPRUpdateStatusDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/_index";
import { IChangeStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/model";
import { Application_Status_Int } from "@app/products/town-planning/ppr/constant";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IPPRUpdateStatusButtonProps {
  isPlansToComply?: boolean;
}

export const PPRUpdateStatusButton = observer(
  ({ isPlansToComply = false }: IPPRUpdateStatusButtonProps) => {
    const { gridSelectedRows, gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);
    const [initialValues, setInitialValues] = useState<IChangeStatus>();
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );

    const handleOnClick = async () => {
      if (!gridSelectedRows || isEmpty(gridSelectedRows)) return;
      if (
        gridSelectedRows?.some(
          (item) => item?.RecordType_ENUM === RECORDTYPE.CORE_Register
        )
      ) {
        pushNotification({
          type: "warning",
          title:
            "To update the status of multiple applications simultaneously, the selected applications must be the same application type.",
        });
        return;
      }

      setIsLoading(true);
      setShowUpdateStatusDialog(true);
      const resStatus = await getStatus(
        Application_Status_Int.NewApplication,
        isPlansToComply,
        true
      );
      setIsLoading(false);

      if (isSuccessResponse(resStatus)) {
        if (resStatus?.data) {
          setInitialValues({
            CurrentStatus: resStatus?.data[0]?.Key,
            _options: {
              StatusList: resStatus?.data ?? [],
              IsPlansToComply: isPlansToComply,
              IsShowDecisionDate: false,
              IsShowDecisionType: false,
            },
          });
        }
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: resStatus?.statusText ?? "Status list load failed",
            type: "error",
          },
        ]);
      }
    };

    const handleOnSubmit = async (data: IChangeStatus) => {
      if (isEmpty(gridSelectedIds) || !data?.CurrentStatus) return;
      setIsUpdating(true);
      const reqBody: StatusChangeDetails = {
        ApplicationIDs: gridSelectedIds,
        IsBatchUpdate: true,
        Status: data?.CurrentStatus,
        Decision: data?.CurrentDecision,
        DecisionDate: data?.DecisionDate,
      };
      const resUpdateStatus = await putBatchStatus(reqBody);
      setIsUpdating(false);
      setShowUpdateStatusDialog(false);
      if (isSuccessIdentityPacket(resUpdateStatus)) {
        clearErrorNotification();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        if (isEmpty(resUpdateStatus?.data?.Notifications)) {
          pushNotification({
            type: "success",
            title: "Status changed successfully",
          });
        } else {
          pushNotification({
            type: "warning",
            description: resUpdateStatus?.data?.Notifications,
            autoClose: false,
          });
        }
      } else {
        pushNotification({
          title:
            "The status of the selected application(s) could not be updated",
          type: "error",
          autoClose: false,
          description:
            resUpdateStatus.data?.Errors ?? resUpdateStatus.statusText,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Update Status"
          onClick={handleOnClick}
          disabled={isEmpty(gridSelectedIds)}
        />

        {showUpdateStatusDialog && (
          <PPRUpdateStatusDialog
            isLoading={isLoading}
            isUpdating={isUpdating}
            notifications={notifications}
            initialValues={initialValues}
            onClose={() => setShowUpdateStatusDialog(false)}
            onSubmit={handleOnSubmit}
          />
        )}
      </>
    );
  }
);
