import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingApplicationsOpen: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ApplicationNumber,
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.PermitNumber,
    title: "Permit Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.Date_PermitIssued,
    title: "Issued",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.RefNo,
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.AssessmentNo,
    title: "Assess Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.IsPrivateCertifier,
    title: "Private Certifier",
  },
  {
    field: BuildingFieldMapping.Applicant,
    title: "Applicant",
  },
  {
    field: BuildingFieldMapping.Category,
    title: "Nature of Work",
  },
  {
    field: BuildingFieldMapping.BCA,
    title: "BCA",
  },
  {
    field: BuildingFieldMapping.UseOfBuilding,
    title: "Use of Building",
  },
  {
    field: BuildingFieldMapping.ReasonForPermit,
    title: "Dev Description",
  },
  {
    field: BuildingFieldMapping.SiteAddress,
    title: "Site Address",
  },
  {
    field: BuildingFieldMapping.Locality,
    title: "Locality",
  },
  {
    field: BuildingFieldMapping.Owner,
    title: "Owner",
  },
  {
    field: BuildingFieldMapping.Status,
    title: "Status",
  },
  {
    field: BuildingFieldMapping.ApplicantRequires,
    title: "Requires",
  },
  {
    field: BuildingFieldMapping.Date_Lodged,
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_AllInspComplete,
    title: "All Insp Complete",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_DueToCommence,
    title: "Due to Commence",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_Commenced,
    title: "Commenced",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_DueToComplete,
    title: "Due to Complete",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.Date_Completed,
    title: "Completed",
  },
  {
    field: BuildingFieldMapping.Date_CertificateIssued,
    title: "Certificate Issued",
  },
  {
    field: BuildingFieldMapping.FileNo,
    title: "File Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.ValuationNo,
    title: "Valuation Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: BuildingFieldMapping.LegalDescription,
    title: "Legal Description",
  },
  {
    field: BuildingFieldMapping.BuildingOfficer,
    title: "Building Officer",
  },
  {
    field: BuildingFieldMapping.Surveyor,
    title: "Surveyor",
  },
  {
    field: BuildingFieldMapping.SurveyorNumber,
    title: "Surveyor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
