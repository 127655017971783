import {
  DTO_Journal_LOVs,
  DTO_Workflow_JournalAuthorise,
  IGetInitialDataAuthoriseJournal,
} from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowAuthoriseJournal = async (
  journalId?: number
): Promise<APIResponse<DTO_Workflow_JournalAuthorise>> => {
  try {
    return await CoreAPIService.getClient().post(
      "api/property/internal/workflow/authorisejournal/new",
      { Journal_Number: journalId }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAuthoriseJournalByWorkflowDraftId = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAuthorise>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/authorisejournal/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessAuthoriseParcel = async (
  mode: WorkflowProcessMode,
  req: DTO_Workflow_JournalAuthorise
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_JournalAuthorise>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/authorisejournal/process/${mode}`,
      req
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInitialDataAuthoriseJournal = async (
  workflowDraftId: number | undefined,
  journalId?: number
): Promise<IGetInitialDataAuthoriseJournal> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        "/api/property/internal/journal/lovs"
      ),
      !isNil(workflowDraftId)
        ? getAuthoriseJournalByWorkflowDraftId(workflowDraftId)
        : postNewWorkflowAuthoriseJournal(journalId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
