import { VO_Charge_Run_RebateApplied } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebates-applied/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOfRebateApplied = nameOfFactory<VO_Charge_Run_RebateApplied>();
export const colChargeRunRebatesApplied: IColumnFields[] = [
  {
    field: nameOfRebateApplied("Assess_NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOfRebateApplied("RT_Name"),
    title: "Type",
  },
  {
    field: nameOfRebateApplied("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOfRebateApplied("Levy_Code"),
    title: "Levy Code",
  },
  {
    field: nameOfRebateApplied("ARE_Curtilage_Rate"),
    title: "Curtilage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfRebateApplied("LCR_Percentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfRebateApplied("LCR_Rebate_Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("LCR_Rebate_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateApplied("ARE_Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfRebateApplied("ARE_Loss_of_Eligibility_Date"),
    title: "Loss of Eligibility",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfRebateApplied("Assess_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOfRebateApplied("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOfRebateApplied("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOfRebateApplied("Assessment_Id"),
    title: "Assessment ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
