import { mockDOTPositionPSAR } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/assessment/mock";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import {
  formatDateByKendo,
  formatDisplayValue,
} from "@common/utils/formatting";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useState } from "react";

interface ITownPlanningFormProps {
  formRenderProps: FormRenderProps;
  isDisable?: boolean;
}
export const Assessment = ({
  formRenderProps,
  isDisable,
}: ITownPlanningFormProps) => {
  const [isLoading] = useState(false);
  const { valueGetter } = formRenderProps;
  const pprDetails = valueGetter("PPRDetails");
  const flagIsRefRequired: boolean = valueGetter(
    "PPRDetails.Flag_IsReferralRequired"
  );

  const displayReferal = () => {
    if (!isNil(pprDetails) && !isNil(flagIsRefRequired)) {
      const flagIsRefRequired = valueGetter(
        "PPRDetails.Flag_IsReferralRequired"
      ) as boolean;
      if (flagIsRefRequired) {
        return "Required";
      } else {
        return "Not Required";
      }
    }
    return "";
  };

  const displayAllocatedDate = () => {
    if (valueGetter("AllocatedToPlanner")) {
      const allocatedDate = new Date(valueGetter("AllocatedToPlanner") as Date);
      const planner = valueGetter("PPRDetails.Planner_In_Charge") || "";
      return `${formatDisplayValue(
        allocatedDate,
        DATE_FORMAT.DATE
      )} (${planner})`;
    }
    return "";
  };

  const displaySentForTLApprovalDate = () => {
    if (valueGetter("SendToCouncilForAuthorisation")) {
      const planner = valueGetter("PPRDetails.TeamLeader_In_Charge") || "";
      return `${formatDisplayValue(
        valueGetter("SendToCouncilForAuthorisation"),
        DATE_FORMAT.DATE
      )} (${planner})`;
    }
    return "";
  };

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">
            Submission due date <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={"PPRDetails.SubmissionDueDate"}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={requiredValidator}
            disabled={isDisable}
          />
        </div>
        <CCValueField
          label="Allocated to planner"
          value={displayAllocatedDate()}
        />
        <CCValueField label="Referral" value={displayReferal()} />

        <CCValueField
          label="Sent for Delegated approver approval"
          value={displaySentForTLApprovalDate()}
        />
        <CCValueField
          label="Delegated approver decision"
          format={DATE_FORMAT.DATE}
          value={valueGetter("CouncilAuthorisationDate")}
        />
        <div className="cc-field">
          <label className="cc-label">DTP position</label>
          <div className="cc-form-cols-2">
            <div className="cc-label-field">
              {valueGetter("AdoptAmendmentDate")
                ? formatDateByKendo(
                    valueGetter("AdoptAmendmentDate"),
                    DATE_FORMAT.DATE
                  )
                : "To be confirmed"}
            </div>
            <Field
              name={"PPRDetails.DOTPosition_ENUM"}
              textField="Value"
              dataItemKey="Code"
              data={mockDOTPositionPSAR}
              component={CCSearchComboBox}
              disabled={isDisable}
              isUseDefaultOnchange
            />
          </div>
        </div>
        <CCValueField
          label="Response sent to proponent"
          value={valueGetter("SendToCouncilForConsideration")}
          format={DATE_FORMAT.DATE}
        />
      </div>
    </section>
  );
};
