import { ICCRoute } from "@common/constants/ICCRoute";

export const currentRoute: ICCRoute = {
  path: "current",
  name: "Current",
  children: [
    {
      path: "my-applications",
      name: "My Applications",
      component: require("./my-applications/_index").default,
    },
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-team-leader",
      name: "By Delegated Approver",
      component: require("./by-team-leader/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      component: require("./by-due-status/_index").default,
    },
  ],
};
