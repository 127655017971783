import { isSuccessResponse } from "@common/apis/util";
import { putCancelMeetingAction } from "@common/pages/actions/components/action-bar/buttons/cancel-meeting/api";
import { actionStore } from "@common/pages/actions/[id]/store";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class CancelMeetingButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  cancelMeetingAction = async (id: number) => {
    this.setIsLoading(true);
    const response = await putCancelMeetingAction(id);
    if (isSuccessResponse(response)) {
      actionStore.reLoadAction().then(() => {
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          type: "success",
          title: "Cancel meeting successfully",
        });
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Cancel meeting failed",
        type: "error",
        description: response.data?.Errors,
      });
    }

    this.setIsLoading(false);
  };
}
export const cancelMeetingStore = new CancelMeetingButtonStore();
const cancelMeetingStoreContext = createContext(cancelMeetingStore);
export const useCancelMeetingButtonStore = () => {
  return useContext(cancelMeetingStoreContext);
};
