import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { PROPERTYAPISYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: IGlobalSettingsProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;
  const data = useDataSetting(configData, [
    "CorporateSettings_PropertyAPISystem",
    "CorporateSettings_PropertyAPIURL",
    "CorporateSettings_PropertyAPIReturnURL",
    "CorporateSettings_PropertyAPIUserName",
    "CorporateSettings_PropertyAPIPassword",
    "CorporateSettings_PropertyAPIKey",
  ]);

  const propertyAPISystem =
    +valueGetter(
      ECorporateSettingsField.CorporateSettings_PropertyAPISystem.toString()
    ) ?? 0;

  const isShowPropertyAPIKey =
    propertyAPISystem === PROPERTYAPISYSTEM.PortAdelaide;

  const isShowPropertyAPILogin =
    propertyAPISystem !== PROPERTYAPISYSTEM.PortAdelaide;

  const isShowAPIreturnURL =
    propertyAPISystem !== PROPERTYAPISYSTEM.PortPhillipDellBoomi;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_PropertyAPISystem}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertyAPIURL}
          isEditing={isEditing}
        />
        {isShowAPIreturnURL && (
          <InputGlobalSettings
            data={data.CorporateSettings_PropertyAPIReturnURL}
            isEditing={isEditing}
          />
        )}
        {isShowPropertyAPILogin && (
          <>
            <InputGlobalSettings
              data={data.CorporateSettings_PropertyAPIUserName}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_PropertyAPIPassword}
              isEditing={isEditing}
            />
          </>
        )}
        {isShowPropertyAPIKey && (
          <InputGlobalSettings
            data={data.CorporateSettings_PropertyAPIKey}
            isEditing={isEditing}
          />
        )}
        <InputGlobalSettings
          data={data.CorporateSettings_PropertyCustomSelectQuery_PropertyIDList}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_PropertyCustomReturnQuery_PropertyIDList}
          isEditing={isEditing}
        />
      </div>
    </section>
  );
});
