import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";

export interface DTO_NoticeRun_LOVs {
  Collections: DTO_LOV[];
  AssessmentStatuses: DTO_LOV[];
  DirectDebitSchemes: DTO_LOV[];
  NoticeOrderings: DTO_LOV_NoticeOrdering[];
  FinancialGroups: DTO_LOV[];
  FinancialAreas: DTO_LOV[];
  SupplementaryTypes: DTO_LOV[];
  RatingPeriodId: number | null;
  InstalmentPlanId: number | null;
  InstalmentPlans: DTO_LOV[];
  ChargeRun: DTO_LOV[];
  RatingPeriods: DTO_LOV[];
  AssessmentGroups: DTO_LOV[];
  NoticeOutputFormats: DTO_LOV[];
  NoticeGroups: DTO_LOV[];
  NoticeTypes: DTO_LOV_NoticeType[];
  AssessmentTypes: DTO_LOV[];
  JournalTypes: DTO_LOV[];
}

export interface DTO_LOV_NoticeType {
  Code: string;
  Name: string;
  ComponentNumber: number;
  TabAttributeVisibility: string;
  TabAssessmentVisibility: string;
  TabGroupsVisibility: string;
  TabCollectionVisibility: string;
  TabOptionsVisibility: string;
  TabJournalVisibility: string;
  TabSupplementaryVisibility: string;
  TabInstalmentsVisibility: string;
  ChargeRunVisibility: string;
  AssessmentExclusionsVisibility: string;
  SetTransactionDateToIssueDateVisibility: string;
}

export interface DTO_LOV_NoticeOrdering {
  Code: string;
  Name: string;
  ComponentNumber: number;
}

export interface DTO_LOV_NoticeRun {
  NoticeOutputFormats: DTO_LOV_Number[];
  NoticeGroups: DTO_LOV_Number[];
}

export interface DTO_WorkflowDetail_NoticeRunExport {
  Notice_Run_Id: number;
  NoticeName: string;
  Notice_Group_Id: number | null;
  OutputFormatId: number | null;
  FileName: string;
  NoticeGroups: number[];
}
export interface DTO_Workflow_NoticeRunExport {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_NoticeRunExport;
}

export enum ENumLoading {
  FirstLoading,
  FinishLoading,
}
