import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFeesOSFeesByDebtor: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_DebtorNo,
    title: "Debtor Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.Parent1_Name, title: "Parent1 Name" },
  //Note
  { field: CRSFieldMapping.Amnt_TotalOwing, title: "Balance" },
  { field: CRSFieldMapping.Amnt_Credit, title: "Credit" },
  { field: CRSFieldMapping.Amnt_CurrNotYetDue, title: "Current" },
  { field: CRSFieldMapping.Amnt_Overdue, title: "Due" },
  { field: CRSFieldMapping.Amnt_OverduePlus30, title: "Due +30d" },
  { field: CRSFieldMapping.Amnt_OverduePlus60, title: "Due +60d" },
  { field: CRSFieldMapping.Amnt_OverduePlus90, title: "Due +90d" },
  {
    field: CRSFieldMapping.Amnt_OverduePlus120,
    title: "Due +120d",
  },
  {
    field: CRSFieldMapping.Parent1_PreferredMthd,
    title: "Preference Method",
  },
  {
    field: CRSFieldMapping.Registration_SiblingName,
    title: "Children",
  },
  {
    field: CRSFieldMapping.Parent1_ContactNo,
    title: "Parent1 Phone Contact",
  },
  {
    field: CRSFieldMapping.Parent1_Address,
    title: "Parent1 Address",
  },
  { field: CRSFieldMapping.Parent1_Email, title: "Parent1 Email" },
];
