import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const existingInstalmentPlan: IKeyValuePacket[] = [
  {
    Value: "2018/2019 Instalments (Aug, Nov, Feb, May)",
    Key: 0,
  },
  {
    Value: "2018/2019 Instalments (Nov, Feb, May)",
    Key: 1,
  },
  {
    Value: "2018/2019 Instalments (Feb, May)",
    Key: 2,
  },
];
export const newInstalmentPlan: IKeyValuePacket[] = [];
