import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface ILookupActionTypeNotification {
  notification: IAppNotificationItemAddProps[];
}

export enum LookupActionTypeSubmitActions {
  Save = "Save",
  New = "New",
  Delete = "Delete",
}
