import { EModeCategoryDialog } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { Svc_FormAction } from "@app/products/crms/service-standards/[id]/model";

export const mappingTitleDialogCategory = {
  [EModeCategoryDialog.Category]: "New Category",
  [EModeCategoryDialog.Subcategory]: "New Subcategory",
  [EModeCategoryDialog.SubCategoryLevel3]: "New Subcategory Level 3",
  [EModeCategoryDialog.SubCategoryLevel4]: "New Subcategory Level 4",
};

export const mappingKeyCategory = {
  [EModeCategoryDialog.Category]: {
    key: "NewCategoryName",
    message: "Change category fail",
  },
  [EModeCategoryDialog.Subcategory]: {
    key: "NewSubCategoryName",
    message: "Change subcategory fail",
  },
  [EModeCategoryDialog.SubCategoryLevel3]: {
    key: "NewSubCategoryLevel3",
    message: "Change subcategory fail",
  },
  [EModeCategoryDialog.SubCategoryLevel4]: {
    key: "NewSubCategoryLevel4",
    message: "Change subcategory fail",
  },
};

export const mappingSerStandardCategory = {
  [EModeCategoryDialog.Category]: Svc_FormAction.CreateCategory,
  [EModeCategoryDialog.Subcategory]: Svc_FormAction.CreateSubCategory,
  [EModeCategoryDialog.SubCategoryLevel3]: Svc_FormAction.CreateSubCategoryL3,
  [EModeCategoryDialog.SubCategoryLevel4]: Svc_FormAction.CreateSubCategoryL4,
};
