import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";
import {
  BookmarkIcon,
  IBookmarkIconProps,
} from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/bookmark/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ListViewBookmarkIconParcelLocality = observer(
  (props: IBookmarkIconProps) => {
    const {
      gridSelectedIds,
      isEmptyGridSelectedRow,
      gridSelectedRows,
      gridTotalSelected,
    } = useDetailParcelListViewStore();

    const propsBookmarkIcon = {
      ...props,
      gridSelectedIds,
      isEmptyGridSelectedRow,
      gridSelectedRows,
      gridTotalSelected: gridTotalSelected,
    };
    return <BookmarkIcon {...propsBookmarkIcon} />;
  }
);
