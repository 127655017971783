import { TownPlanningApiUrl } from "@app/products/town-planning/config";
import { TownPlanningResponse } from "@app/products/town-planning/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { IResponseData } from "@common/models/service";
import { globalStoreInstance } from "@common/stores/global/store";

export const getTownPlanningDetailsTabById = async (
  id: string
): Promise<TownPlanningResponse | undefined> => {
  const response: IResponseData = await CoreAPIService.getClient().get(
    `${
      globalStoreInstance.allSetting.baseAPIUrl
    }${TownPlanningApiUrl.GET_TOWNPLANNING.replace("{id}", id)}`
  );

  if (!response.data) {
    return undefined;
  }

  return response.data as TownPlanningResponse;
};
