import { CRMS_KB_BASE_ROUTE } from "@app/products/crms/knowledge-base/[id]/constant";
import { CRMSFieldMapping } from "@app/products/crms/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colTopics: IColumnFields[] = [
  {
    field: CRMSFieldMapping.Tag,
    title: "Topic",
    width: 150,
    locked: true,
    linkTo: (dataItem) => `${CRMS_KB_BASE_ROUTE}/${dataItem.ID}`,
  },
];
