import { DTO_AssessmentCharge_Levy } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { CurrencyInputCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-charges/components/currency-input-cell/_index";

const nameOf = nameOfFactory<DTO_AssessmentCharge_Levy>();
export const colLevies: IColumnFields[] = [
  {
    field: nameOf("Levy"),
    title: "Levy",
  },
  {
    field: nameOf("Charge_Instalment_Id"),
    title: "Instalment",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Adjustment_Amount"),
    title: "Adjustment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
    editCell: CurrencyInputCell,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Adjustment_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("GrossAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("NettAmount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("NettAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Balance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Calculation"),
    title: "Calculation",
  },
  {
    field: nameOf("Charge_Balances_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const ADJUST_CHARGE_VALIDATOR_MESSAGE =
  "Please enter amount each charge and select a levy is to be adjusted by.";
