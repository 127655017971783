import {
  CRMS_INSPECTIONS_ROUTE,
  nameOfEventInspectionView,
} from "@app/products/crms/inspections/constant";
import { EventInspectionView } from "@app/products/crms/inspections/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colByDate: IColumnFields[] = [
  {
    field: nameOfEventInspectionView("ReferenceNumber"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem: EventInspectionView) =>
      `${CRMS_INSPECTIONS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOfEventInspectionView("DateInspected"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfEventInspectionView("InspectionType"),
    title: "Inspection Type",
  },
  {
    field: nameOfEventInspectionView("Reason"),
    title: "Reason",
  },
  {
    field: nameOfEventInspectionView("Compliance"),
    title: "Compliance",
  },
  {
    field: nameOfEventInspectionView("Category"),
    title: "Category",
  },
  {
    field: nameOfEventInspectionView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfEventInspectionView("Address"),
    title: "Address",
  },
  { field: nameOfEventInspectionView("Area"), title: "Area" },
];
