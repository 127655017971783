import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colRateBreakup: IColumnFields[] = [
  {
    field: "Date",
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Charge",
    title: "Charge",
  },
  {
    field: "Transaction",
    title: "Transaction",
  },
  {
    field: "ChargeId",
    title: "Charge ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
