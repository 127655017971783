import {
  IFirePreventionSummary,
  IProperty,
} from "@app/products/property/fire-prevention/hazld-case/[id]/model";

const mockProperty: IProperty[] = [
  {
    PropertyAddress:
      "23 Atouera St, Battery PointLot 1 STR 139638 Vol 139638 Fol 1",
    LegalDescription: "Lot 1 STR 139638 Vol 139638 Fol 1",
    LandArea: "457 m2",
    AssessNumber: 2214889,
    AssessStatus: "Active",
    ParcelId: 231040,
  },
];

export const mockFirePreventionSummary: IFirePreventionSummary = {
  OwnerS: "I S Middleton & J M Middleton\n348 Davey St\nSOUTH HOBART TAS 7004",
  RatepayerPostalAddress:
    "I S Middleton & J M Middleton\n348 Davey St\nSOUTH HOBART TAS 7004",
  Comments: null,
  Property: mockProperty,
};
