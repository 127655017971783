import { CCDialog } from "@components/cc-dialog/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
interface IImageGalleryProps {
  onClose: () => void;
  imageInfo: ReactImageGalleryItem[];
  isLoading?: boolean;
}
export const ImageGalleryDialog = ({
  onClose,
  imageInfo,
  isLoading = false,
}: IImageGalleryProps) => {
  return (
    <CCDialog
      titleHeader={"Image Gallery"}
      height={"auto%"}
      maxWidth={"40%"}
      onClose={onClose}
      bodyElement={
        isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <ImageGallery items={imageInfo} />
        )
      }
      // TODO: Discuss with Onshore about remove footer in the next CR
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};
