export enum AUSPOSTFORMAT {
  "SystemInitialise" = "0",
  "Stonnington" = "1",
  "Casey" = "2",
  "GlenEira" = "3",
  "CoPP" = "4",
  "None" = "5",
  "Internal" = "6",
  "Hobart" = "7",
  "Darebin" = "8",
  "ShireofMurray" = "9",
  "MOD09v02" = "10",
}

export enum ControlRenderMode {
  "Read" = "0",
  "Edit" = "1",
}

export enum IMPORT_METHOD_VALUE {
  "File" = "0",
  "Database" = "1",
}

export enum FINANCE_API_VALUE {
  "None" = "0",
  "Community Finance" = "1",
  "Port Phillip Dell Boomi" = "2",
  "Oracle" = "3",
}

export enum EXPORT_FORMAT_VALUE {
  Standard = "0",
  Format1 = "1",
  Format2 = "2",
  Custom = "3",
}

export enum FINANCE_METHOD_VALUE {
  None = "0",
  Localized = "1",
  Direct = "2",
}

export enum FINANCE_SYSTEM_VALUE {
  "None" = "0",
  "Collect" = "1",
  "Authority" = "2",
  "Pathway" = "3",
  "Proclaim" = "4",
  "FinanceOne - CRS" = "5",
  "CashLink" = "6",
  "Fujitsu2000" = "7",
  "Greentree" = "8",
  "FinanceOnePathway" = "9",
  "IBIS" = "10",
  "Computron" = "11",
  "MYOB" = "12",
  "FinanceOne" = "13",
  "Computron Debtors" = "14",
  "ComputronPathway" = "15",
  "Synergy Soft" = "16",
  "Community Finance" = "17",
  "TechOne CIAnyWhere" = "18",
  "TechnologyOne" = "19",
  "Oracle" = "20",
  "SAP" = "21",
}

export interface IDataLogicValue {
  isVisible: boolean;
  child: any[];
}
export interface IDataLogic {
  [key: string]: IDataLogicValue;
}
