import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";

export const handleProcessMasterProperty = (e: ComboBoxChangeEvent) => {
  let currentValue = e.value;
  if (currentValue) {
    //process properties for suitable type
    currentValue = {
      ...e.value,
      Id: e.value?.Id ? e.value.Id + "" : undefined,
      Property_Valuation_Number: e.value?.Property_Valuation_Number
        ? e.value.Property_Valuation_Number + ""
        : undefined,
      Property_Assessment_Number: e.value?.Property_Assessment_Number
        ? e.value.Property_Assessment_Number + ""
        : undefined,
    };
  }
  return currentValue;
};
