import { BatchComplete } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RecordCountPacket } from "@common/models/recordCountPacket";

export const putBatchCompleteEnquiry = async (
  batchComplete: BatchComplete
): Promise<APIResponse<RecordCountPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<RecordCountPacket>(
      `api/tp/ppr/internal/enquiry/batch-complete`,
      batchComplete
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
