import { apiCoreGetKeywordById } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Keyword } from "@common/models/keyword";
import { TreePacket } from "@common/models/treePacket";
import { IKeywordsDataByType } from "@common/pages/settings/lookups/keywords/_id/model";

export const getKeywordById = async (
  keywordId: number
): Promise<APIResponse<Keyword | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Keyword>(
      apiCoreGetKeywordById(keywordId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getKeywordTypeList = async (): Promise<
  APIResponse<TreePacket[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<TreePacket[]>(
      `api/core/internal/getkeywordtypelist`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getKeywordDataByType = async (
  keywordType: number
): Promise<APIResponse<IKeywordsDataByType | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IKeywordsDataByType>(
      `api/core/internal/getkeyworddatabytype/${keywordType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postKeyword = async (
  requestBody: Keyword
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<Keyword>(
      `api/core/internal/keyword`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
