import { getDebtRecoverySummaryById } from "@app/products/property/assessments/debt-recovery/[id]/api";
import {
  DTO_DebtRecovery,
  DTO_FunctionalityRestriction,
  DebtRecoveryAction,
} from "@app/products/property/assessments/debt-recovery/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class DebtRecoveryStore {
  private _debtRecovery?: DTO_DebtRecovery = undefined;
  private _debtRecoveryFunctionalities: DTO_FunctionalityRestriction[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _debtRecoveryActionSelectedRow?: DebtRecoveryAction | undefined =
    undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get debtRecovery() {
    return this._debtRecovery;
  }
  setDebtRecovery = (debtRecovery?: DTO_DebtRecovery) => {
    runInAction(() => {
      this._debtRecovery = debtRecovery;
    });
  };

  get debtRecoveryFunctionalities() {
    return toJS(this._debtRecoveryFunctionalities);
  }
  setDebtRecoveryFunctionalities = (
    debtRecoveryFunctionalities?: DTO_FunctionalityRestriction[]
  ) => {
    runInAction(() => {
      this._debtRecoveryFunctionalities = debtRecoveryFunctionalities ?? [];
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get debtRecoveryId() {
    return this.debtRecovery?.DebtRecoveryId;
  }

  get debtRecoveryActionSelectedRow() {
    return toJS(this._debtRecoveryActionSelectedRow);
  }
  setDebtRecoveryActionSelectedRow = (
    debtRecoveryActionSelectedRow?: DebtRecoveryAction
  ) => {
    runInAction(() => {
      this._debtRecoveryActionSelectedRow = debtRecoveryActionSelectedRow;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._debtRecovery = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._debtRecoveryActionSelectedRow = undefined;
    });
  };

  loadDebtRecovery = async (
    debtRecoveryId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getDebtRecoverySummaryById(debtRecoveryId);
    let errorResponse = undefined;
    let newDebtRecovery = undefined;
    if (isSuccessResponse(response)) {
      newDebtRecovery = response.data;
      this.setDebtRecoveryFunctionalities(
        newDebtRecovery?.DebtRecovery?.FunctionalityRestrictions
      );
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setDebtRecovery(newDebtRecovery?.DebtRecovery);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }

    //TODO: will check later and add alert message
    // if (
    //   newDebtRecovery &&
    //   newDebtRecovery?.AlertMessage &&
    //   newDebtRecovery?.ShowAlertOnAssessment === "yes"
    // ) {
    //   appNotificationStore.pushNotification({
    //     title: newDebtRecovery?.AlertMessage,
    //     type: "warning",
    //   });
    // }
    this.setIsLoading(false);
  };

  reLoadDebtRecovery = async () => {
    if (this.debtRecoveryId) await this.loadDebtRecovery(this.debtRecoveryId);
  };
}

const debtRecoveryStoreContext = createContext(new DebtRecoveryStore());
export const useDebtRecoveryStore = () => {
  return useContext(debtRecoveryStoreContext);
};
