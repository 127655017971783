import { state } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/child-screens/general/components/form-element/mock";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { Region } from "@app/products/town-planning/ppr/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { AusState } from "@common/constants/enumAusState";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useIsNew } from "@common/hooks/useIsNew";
import { ILocalGovernment } from "@common/pages/settings/lookups/local-government/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCKeyValueSearch } from "@components/cc-key-value-search/_index";
import { CCPickCouncil } from "@components/cc-pick-council/_index";
import { Suburb } from "@components/cc-pick-suburb/model";
import { CCPickSuburb } from "@components/cc-pick-suburb/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IApplicationCategoryFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Region>();

export const RegionFormElement = observer(
  ({ formRenderProps }: IApplicationCategoryFormElementProps) => {
    const isNew = useIsNew();
    const { onChange } = formRenderProps;
    const { pushNotification } = useCCAppNotificationStore();

    const handleOnPickOfficerForOfficer = (value: IOfficer) => {
      onChange(nameOf("Coordinator_ID"), { value: value.ID });
      getSiteUser(value.ID).then((response) => {
        if (isSuccessResponse(response)) {
          if (response.data) {
            onChange(nameOf("Coordinator"), {
              value: response.data,
            });
          }
        } else {
          pushNotification({
            title: "Officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      });
    };

    const [coordinatorEmail, setCoordinatorEmail] = useState<String>();

    const displayCoordinator = (value: any) => {
      setCoordinatorEmail(value.Email);
      return value.DisplayName;
    };

    useEffectOnce(() => {
      if (isNew) {
        onChange(nameOf("State"), {
          value: AusState.AUS_STATE_VIC.toString(),
        });
      }
    });
    return (
      <FormElement className="cc-application-category-form-elements">
        <input
          type="submit"
          id="btn-submit"
          hidden
          onClick={formRenderProps.onSubmit}
        ></input>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Region
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Region_Name")}
                component={CCKeyValueSearch}
                keywordType={KEYWORD_TYPE.Core_Region}
                productType={PRODUCT_TYPE_NUMBER.Core}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("Region_Name"), {
                    value: event.value?.Name,
                  });
                }}
                onReceiveData={
                  isNew
                    ? (data: any) => {
                        if (data?.length > 0)
                          onChange(nameOf("Region_Name"), {
                            value: data[0]?.Name,
                          });
                      }
                    : undefined
                }
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Suburb
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Suburb")}
                component={CCPickSuburb}
                onPickSuburb={(value: Suburb | null) => {
                  onChange(nameOf("Suburb"), { value: value?.Locality });
                }}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Municipality
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Council_Name")}
                component={CCPickCouncil}
                onPickCouncil={(value: ILocalGovernment | null) => {
                  onChange(nameOf("Council_Name"), { value: value?.Name });
                  onChange(nameOf("Council_ID"), { value: value?.ID });
                }}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Postcode
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={"PostCode"}
                component={CCInput}
                placeholder={"Postcode"}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                State
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("State")}
                data={state}
                component={CCSearchComboBox}
                validator={requiredValidator}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Coordinator
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={"Coordinator"}
                component={OfficerPicker}
                onPickOfficer={handleOnPickOfficerForOfficer}
                textProduce={displayCoordinator}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label={"Coordinator Email"}
                value={coordinatorEmail}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
