import { getViewConfigurations } from "@app/products/property/api";
import { AddZonesDialog } from "@app/products/property/components/dialogs/add-zones-dialog/_index";
import { postSearchZoneLookup } from "@app/products/property/components/dialogs/add-zones-dialog/api";
import { colSelectZones } from "@app/products/property/components/dialogs/add-zones-dialog/config";
import {
  DTO_Zone,
  VO_Zone,
} from "@app/products/property/components/dialogs/add-zones-dialog/model";
import { ViewConfiguration } from "@app/products/property/model";
import { EParcelLoadingType } from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import { processCombineData } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/associations/util";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ZonesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOfVOZoneGrid = nameOfFactory<VO_Zone>();
const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = { isReadOnly: false, setIsLoadingInStep: () => {} },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const currentZones: VO_Zone[] = getFieldValue("") ?? [];
    const listSelected: VO_Zone[] = getFieldValue("SelectedZone") ?? [];
    const [isShowConfirmDeletion, setIsShowConfirmDeletion] =
      useState<boolean>(false);
    const [isShowSelectZonesDialog, setIsShowSelectZonesDialog] =
      useState<boolean>(false);

    const loadingMode = getFieldValue("_option.Loading");
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [zoneColumns, setZoneColumns] =
      useState<IColumnFields[]>(colSelectZones);

    const handleDelete = () => {
      const selectedIDs = listSelected.map((item: VO_Zone) => item.Zone_Id);
      const newZones = currentZones.filter(
        (record: VO_Zone) => !selectedIDs.includes(record.Zone_Id)
      );
      onChange(nameOf(""), { value: newZones });
      onChange(nameOf("SelectedZone"), { value: [] });
    };

    const handleAddZone = async (data: VO_Zone[]) => {
      const listZoneId = data?.map((item: VO_Zone) => item.Zone_Id);
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Data,
      });
      options?.setIsLoadingInStep(true);
      const responseZoneLookup = await postSearchZoneLookup(listZoneId ?? []);
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      if (isSuccessResponse(responseZoneLookup) && responseZoneLookup?.data) {
        const responseZone = responseZoneLookup?.data?.Zones ?? [];
        const newData = responseZone?.map((item: DTO_Zone) => item);
        const previousZone = currentZones?.length ? [...currentZones] : [];
        const zoneData = processCombineData(previousZone, newData, "Zone_Id");
        onChange(nameOf(""), {
          value: zoneData,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Load zone failed`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingInStep(false);
    };

    const loadViewConfiguration = async () => {
      onChange(nameOf("_option.Loading"), {
        value: EParcelLoadingType.Grid,
      });
      options?.setIsLoadingInStep?.(true);
      const response = await getViewConfigurations(
        ViewConfiguration.ZoneLookup
      );
      onChange(nameOf("_option.Loading"), {
        value: undefined,
      });
      options?.setIsLoadingInStep?.(false);
      let errorResponse = undefined;
      if (isSuccessResponse(response) && response?.data) {
        setZoneColumns(
          processDynamicColumns(
            colSelectZones,
            response?.data?.ColumnDefinitions?.Columns
          )
        );
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Load view configuration failed",
        };
      }
      setResponseLoadError(errorResponse);
    };

    useEffectOnce(() => {
      (async () => {
        await loadViewConfiguration();
      })();
    });

    if (loadingMode === EParcelLoadingType.Grid) return <Loading isLoading />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfiguration();
          }}
        />
      );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              isLoading={loadingMode === EParcelLoadingType.Data}
              data={currentZones ?? []}
              columnFields={zoneColumns ?? []}
              primaryField={nameOfVOZoneGrid("Zone_Id")}
              readOnly={options?.isReadOnly}
              selectableMode="multiple"
              selectedRows={listSelected}
              onSelectionChange={(dataItem: VO_Zone[]) => {
                dataItem &&
                  onChange(nameOf("SelectedZone"), { value: dataItem });
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-plus"
                    title="Select Zones"
                    onClick={() => setIsShowSelectZonesDialog(true)}
                  />
                  <Button
                    disabled={!listSelected?.length}
                    className="cc-edit-field-button"
                    iconClass="fa fa-minus"
                    title="Delete"
                    onClick={() => {
                      setIsShowConfirmDeletion(true);
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
        {isShowSelectZonesDialog && (
          <AddZonesDialog
            onClose={() => {
              setIsShowSelectZonesDialog(false);
            }}
            handleSelectZone={(data: VO_Zone[]) => {
              handleAddZone(data);
              setIsShowSelectZonesDialog(false);
            }}
          />
        )}
        {isShowConfirmDeletion && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            subMessage={"Are you sure you want to delete the selected item(s)?"}
            onClosePopup={() => {
              setIsShowConfirmDeletion(false);
            }}
            onConfirmYes={handleDelete}
          />
        )}
      </section>
    );
  }
);
