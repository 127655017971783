import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { EnterSpecialReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/_index";
import { loadSpecialReadingData } from "@app/products/property/meters/special-reading-list/components/action-bar/buttons/enter-special-reading/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const EnterSpecialReadingButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const [formData, setFormData] = useState<
    ISpecialReadingDialogData | undefined
  >();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const { pushNotification } = useCCAppNotificationStore();

  return (
    <React.Fragment>
      <CCNavButton
        title="Enter Special Reading"
        onClick={() => {
          loadSpecialReadingData(gridSelectedRows[0]).then(
            (data: ISpecialReadingDialogData) => {
              if (!data) return;
              setFormData(data);
              setShowDialog(true);
            }
          );
        }}
        disabled={gridSelectedRows.length !== 1}
      />

      {showDialog && (
        <EnterSpecialReadingDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onEnterAndExit={() => {
            pushNotification({
              title: "Special reading entered successfully",
              type: "success",
            });
            setShowDialog(false);
          }}
          specialReadingData={formData!}
        />
      )}
    </React.Fragment>
  );
});
