import { getCoreRelated } from "@app/core/related/related-accordion/api";
import {
  ICoreRelated,
  ICoreRelatedParams,
} from "@app/core/related/related-accordion/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class RelatedAccordionStore {
  private _isLoading: boolean = false;
  private _relatedRecords: ICoreRelated[] = [];
  private _relatedParam?: ICoreRelatedParams = undefined;
  private _registerEnum?: number = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get relatedRecords() {
    return toJS(this._relatedRecords);
  }
  setRelatedRecords = (relatedRecords: ICoreRelated[]) => {
    runInAction(() => {
      this._relatedRecords = relatedRecords;
    });
  };

  get relatedParam() {
    return toJS(this._relatedParam);
  }

  setRelatedParam = (relatedParam: ICoreRelatedParams) => {
    runInAction(() => {
      this._relatedParam = relatedParam;
    });
  };

  get registerEnum() {
    return toJS(this._registerEnum);
  }

  setRegisterEnum = (registerEnum: number) => {
    runInAction(() => {
      this._registerEnum = registerEnum;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };
  loadRelatedRecords = async () => {
    if (this._relatedParam) {
      this.setIsLoading(true);
      const response = await getCoreRelated(this._relatedParam);
      if (isSuccessResponse(response)) {
        if (response.data) {
          appNotificationStore.clearErrorNotification();
          this.setRelatedRecords(response.data);
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: response.error,
            type: "error",
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: response.error,
          type: "error",
        });
      }
      this.setIsLoading(false);
    }
  };
}
export const relatedAccordionStore = new RelatedAccordionStore();
const relatedAccordionStoreContext = createContext(relatedAccordionStore);
export const useRelatedAccordionStore = () => {
  return useContext(relatedAccordionStoreContext);
};
