export const mockExcludeIfAlreadyUnderInvestigation = [
  {
    Row: "1",
    Address: "1310 Inverloch-Kongwak Rd, ALTONA NORTH",
    Assessment: "101-28-19",
    PropertyAddress: "10 Worthington St, Grantville",
    LandTaxExemptionId: "1110",
    AssessmentId: "100106",
    EntityId: "212873",
  },
  {
    Row: "2",
    Address: "1/60 Rose St, CAPE WOOLAMAI",
    Assessment: "101-34-17",
    PropertyAddress: "11 Union St, Grantville",
    LandTaxExemptionId: "1264",
    AssessmentId: "100293",
    EntityId: "111575",
  },
  {
    Row: "3",
    Address: "Acn 091 577 874,102 Ferguson St, UPWEY",
    Assessment: "101-49-9",
    PropertyAddress: "16 Scenic St, Grantville",
    LandTaxExemptionId: "1549",
    AssessmentId: "100760",
    EntityId: "219748",
  },
  {
    Row: "4",
    Address: "ALTONA MEADOWS",
    Assessment: "102-19-32",
    PropertyAddress: "Whitehead Ave, Pioneer Bay",
    LandTaxExemptionId: "1953",
    AssessmentId: "101360",
    EntityId: "88355",
  },
  {
    Row: "5",
    Address: "PO Box 113, ALTONA",
    Assessment: "103-26-19",
    PropertyAddress: "1 Thomson Ave, Queensferry",
    LandTaxExemptionId: "2704",
    AssessmentId: "102359",
    EntityId: "98572",
  },
  {
    Row: "6",
    Address: "14 Kingston St ALTONA",
    Assessment: "103-32-70",
    PropertyAddress: "3 Haven St Queensferry",
    LandTaxExemptionId: "2852",
    AssessmentId: "102563",
    EntityId: "150024",
  },
  {
    Row: "7",
    Address: "14 Kingston St, ALTONA",
    Assessment: "103-32-71",
    PropertyAddress: "5 Goldrush Cl, Queensferry",
    LandTaxExemptionId: "2853",
    AssessmentId: "102564",
    EntityId: "150024",
  },
  {
    Row: "8",
    Address: "457 Canterbury Rd, WONTHAGGI",
    Assessment: "103-66-25",
    PropertyAddress: "65 Nabilla St, Queensferry",
    LandTaxExemptionId: "3275",
    AssessmentId: "103155",
    EntityId: "40062",
  },
  {
    Row: "9",
    Address: "ALTONA MEADOWS",
    Assessment: "104-21-25",
    PropertyAddress: "19 Livio St, Almurta",
    LandTaxExemptionId: "3787",
    AssessmentId: "103853",
    EntityId: "88355",
  },
  {
    Row: "10",
    Address: "4/15 River St, SOUTH KINGSVILLE",
    Assessment: "105-58-5-1",
    PropertyAddress: "No 7 Drain St, Williamstown",
    LandTaxExemptionId: "5137",
    AssessmentId: "105763",
    EntityId: "136344",
  },
  {
    Row: "11",
    Address: "RMB 2175, NEWPORT",
    Assessment: "105-58-5-1",
    PropertyAddress: "No 7 Drain St, Williamstown",
    LandTaxExemptionId: "5137",
    AssessmentId: "105763",
    EntityId: "136345",
  },
  {
    Row: "12",
    Address: "1126 Bass Hwy, WILLIAMSTOWN",
    Assessment: "105-58-9-111",
    PropertyAddress: "8A Spring Ave, Williamstown",
    LandTaxExemptionId: "105006",
    AssessmentId: "246549",
    EntityId: "61299",
  },
  {
    Row: "13",
    Address: "PO Box 7098",
    Assessment: "106-40-11",
    PropertyAddress: "7 0X00F37FF54F0FFC4B81D56DBE3258019A010000009B8778AAD5Z",
    LandTaxExemptionId: "5370",
    AssessmentId: "106398",
    EntityId: "11375",
  },
  {
    Row: "14",
    Address: "MITCHAM",
    Assessment: "106-54-2-15",
    PropertyAddress: "36 0X00F37FF54F0FFC4B81D56DBE3258019A010000009B8778AADE",
    LandTaxExemptionId: "5395",
    AssessmentId: "106455",
    EntityId: "101469",
  },
  {
    Row: "15",
    Address: "MITCHAM",
    Assessment: "106-54-2-19",
    PropertyAddress: "6 0X00F37FF54F0FFC4B81D56DBE3258019A010000009B8778AAD5Z",
    LandTaxExemptionId: "5397",
    AssessmentId: "106459",
    EntityId: "101469",
  },
  {
    Row: "16",
    Address: "ALTONA MEADOWS",
    Assessment: "109-2-17",
    PropertyAddress: "19 BattungaStTemp",
    LandTaxExemptionId: "5459",
    AssessmentId: "106714",
    EntityId: "88355",
  },
  {
    Row: "17",
    Address: "9 Ascot St, INVERLOCH",
    Assessment: "109-8-6-25",
    PropertyAddress: "13 Newton Lane, Temp",
    LandTaxExemptionId: "5514",
    AssessmentId: "106799",
    EntityId: "146817",
  },
  {
    Row: "18",
    Address: "PO Box 4019, SEABROOK",
    Assessment: "109-14-4-82",
    PropertyAddress: "84B Grandview St, Temp",
    LandTaxExemptionId: "88078",
    AssessmentId: "218849",
    EntityId: "64263",
  },
  {
    Row: "19",
    Address: "RMB 5077, ALTONA MEADOWS",
    Assessment: "109-14-4-82",
    PropertyAddress: "84B Grandview St, Temp",
    LandTaxExemptionId: "88078",
    AssessmentId: "218849",
    EntityId: "64271",
  },
  {
    Row: "20",
    Address: "18 Barramundi Ave, NEWPORT",
    Assessment: "110-13-21",
    PropertyAddress: "7 Kennon St, Glen Forbes",
    LandTaxExemptionId: "5963",
    AssessmentId: "107409",
    EntityId: "91921",
  },
  {
    Row: "21",
    Address: "9 Ascot St, INVERLOCH",
    Assessment: "110-20-22-17",
    PropertyAddress: "9 Fraser Daley Cres, Glen Forbes",
    LandTaxExemptionId: "6113",
    AssessmentId: "107612",
    EntityId: "146817",
  },
  {
    Row: "22",
    Address: "211 WaverleyRd, KERNOT",
    Assessment: "110-32-35-8",
    PropertyAddress: "20-22 McKenzie St, Glen Forbes",
    LandTaxExemptionId: "6307",
    AssessmentId: "107909",
    EntityId: "146840",
  },
  {
    Row: "23",
    Address: "211 WaverleyRd, KERNOT",
    Assessment: "110-55-30",
    PropertyAddress: "Luke Cres, Glen Forbes",
    LandTaxExemptionId: "6551",
    AssessmentId: "108214",
    EntityId: "146840",
  },
  {
    Row: "24",
    Address: "883 Whitehorse Rd, ALTONA MEADOWS",
    Assessment: "124-38-26",
    PropertyAddress: "Dunstan St, Pound Creek",
    LandTaxExemptionId: "6781",
    AssessmentId: "108504",
    EntityId: "93849",
  },
  {
    Row: "25",
    Address: "883 Whitehorse Rd, ALTONA MEADOWS",
    Assessment: "124-45-31",
    PropertyAddress: "0X00F37FF54F0FFC4B81D56DBE3258019A010000005718E97EAD70.",
    LandTaxExemptionId: "6990",
    AssessmentId: "108782",
    EntityId: "93849",
  },
  {
    Row: "26",
    Address: "ALTONA MEADOWS",
    Assessment: "124-51-26",
    PropertyAddress: "34 Candle Bark St, Pound Creek",
    LandTaxExemptionId: "7130",
    AssessmentId: "108959",
    EntityId: "88355",
  },
  {
    Row: "27",
    Address: "Level 2, 28-32 Albert Rd, ALTONA NORTH",
    Assessment: "124-60-16-2",
    PropertyAddress: "Pavilion 3, Apt 1/126 Hatfield St, Pound Creek",
    LandTaxExemptionId: "71972",
    AssessmentId: "191258",
    EntityId: "213146",
  },
  {
    Row: "28",
    Address: "Level 2, 28-32 Albert Rd, WEST FOOTSCRAY",
    Assessment: "124-60-16-2",
    PropertyAddress: "Pavilion 3, Apt 1/126 Hatfield St, Pound Creek",
    LandTaxExemptionId: "71972",
    AssessmentId: "191258",
    EntityId: "220143",
  },
  {
    Row: "29",
    Address: "ALTONA MEADOWS",
    Assessment: "125-1-45",
    PropertyAddress:
      "63 Leggetts Ave, 0x00F37FF54F0FFC4B81 D56DBE3258019A0100000",
    LandTaxExemptionId: "7784",
    AssessmentId: "109788",
    EntityId: "88355",
  },
  {
    Row: "30",
    Address: "ALTONA MEADOWS",
    Assessment: "125-48-32",
    PropertyAddress:
      "50 Bennetts St, 0x00F37FF54F0FFC4B81 D56DBE3258019A010000001",
    LandTaxExemptionId: "8538",
    AssessmentId: "110721",
    EntityId: "88355",
  },
  {
    Row: "31",
    Address: "22-28 Hamilton St",
    Assessment: "125-61-18-16",
    PropertyAddress:
      "17 Wynen Hwy, 0x00F37FF54F0FFC4B81 D56DBE3258019A01000000",
    LandTaxExemptionId: "96352",
    AssessmentId: "232186",
    EntityId: "41994",
  },
  {
    Row: "32",
    Address: "21 Woodleast ALTONA MEADOWS",
    Assessment: "126-38-12",
    PropertyAddress: "23 Upton St, StHelier",
    LandTaxExemptionId: "9511",
    AssessmentId: "111934",
    EntityId: "81982",
  },
  {
    Row: "33",
    Address: "21 Woodleast ALTONA MEADOWS",
    Assessment: "126-39-6",
    PropertyAddress: "38 Angus St, StHelier",
    LandTaxExemptionId: "9527",
    AssessmentId: "111952",
    EntityId: "81982",
  },
  {
    Row: "34",
    Address: "457 Canterbury Rd, WONTHAGGI",
    Assessment: "212-47-3",
    PropertyAddress: "18 0X00F37FF54F0FFC4B81D56DBE3258019A0100000007C8131669Z",
    LandTaxExemptionId: "10160",
    AssessmentId: "112825",
    EntityId: "40062",
  },
  {
    Row: "35",
    Address: "1126 Bass Hwy, WILLIAMSTOWN",
    Assessment: "212-57-13-1",
    PropertyAddress:
      "3 Stanton St, 0x00F37FF54F0FFC4B81 D56DBE3258019A010000004A!",
    LandTaxExemptionId: "96745",
    AssessmentId: "232810",
    EntityId: "61299",
  },
  {
    Row: "36",
    Address: "41 Champion Rd, TECOMA",
    Assessment: "214-6-22-68",
    PropertyAddress: "Florence St, Cape Paterson",
    LandTaxExemptionId: "102129",
    AssessmentId: "241370",
    EntityId: "215258",
  },
  {
    Row: "37",
    Address: "Acn 081 863 363, 12 Waters Dr, MOUNT WAVE",
    Assessment: "214-6-22-68",
    PropertyAddress: "Florence St, Cape Paterson",
    LandTaxExemptionId: "102129",
    AssessmentId: "241370",
    EntityId: "215259",
  },
  {
    Row: "38",
    Address: "PC Box 580, LAVERTON",
    Assessment: "214-17-6-39",
    PropertyAddress: "Hillrise Ave, Cape Paterson",
    LandTaxExemptionId: "100830",
    AssessmentId: "239153",
    EntityId: "61061",
  },
  {
    Row: "39",
    Address: "151 Rathdowne St, WILLIAMSTOWN",
    Assessment: "216-12-2-10",
    PropertyAddress: "26 Fahey Cct Sunset Strip",
    LandTaxExemptionId: "92510",
    AssessmentId: "226200",
    EntityId: "85863",
  },
  {
    Row: "40",
    Address: "1515 Korumburra-Wonthaggi Rd, WERRIBEE",
    Assessment: "217-22-34-6",
    PropertyAddress: "23 Gordon St, Coronet Bay",
    LandTaxExemptionId: "65800",
    AssessmentId: "182320",
    EntityId: "21539",
  },
  {
    Row: "41",
    Address: "PO Box 46, ALTONA MEADOWS",
    Assessment: "217-22-34-6",
    PropertyAddress: "23 Gordon St, Coronet Bay",
    LandTaxExemptionId: "65800",
    AssessmentId: "182320",
    EntityId: "137602",
  },
  {
    Row: "42",
    Address: "Number 20, Second Avenue, 266671, WILLIAM!",
    Assessment: "217-46-1-29",
    PropertyAddress: "Elm Ave, Coronet Bay",
    LandTaxExemptionId: "102905",
    AssessmentId: "242704",
    EntityId: "272728",
  },
  {
    Row: "43",
    Address: "16 McDonald Rd, ESSENDON",
    Assessment: "218-50-31",
    PropertyAddress: "30 Mount Lyall St, Outtrim",
    LandTaxExemptionId: "12500",
    AssessmentId: "116156",
    EntityId: "70411",
  },
  {
    Row: "44",
    Address: "1515 Korumburra-Wonthaggi Rd, WERRIBEE",
    Assessment: "218-81-17",
    PropertyAddress: "1 Settlement St, Outtrim",
    LandTaxExemptionId: "12915",
    AssessmentId: "116672",
    EntityId: "21539",
  },
  {
    Row: "45",
    Address: "PO Box 46, ALTONA MEADOWS",
    Assessment: "218-81-17",
    PropertyAddress: "1 Settlement St, Outtrim",
    LandTaxExemptionId: "12915",
    AssessmentId: "116672",
    EntityId: "137602",
  },
  {
    Row: "46",
    Address: "2140 Korumburra-Wonthaggi Rd, INVERLOCH",
    Assessment: "220-16-16-23",
    PropertyAddress: "13 Richard St, Wonthaggi",
    LandTaxExemptionId: "95410",
    AssessmentId: "230848",
    EntityId: "184428",
  },
  {
    Row: "47",
    Address: "GPOBOX1670N, CERES",
    Assessment: "220-20-19-22",
    PropertyAddress: "23 Richard St, Wonthaggi",
    LandTaxExemptionId: "67818",
    AssessmentId: "185069",
    EntityId: "159798",
  },
  {
    Row: "48",
    Address: "2/38 Charles St, ALTONA NORTH",
    Assessment: "220-37-13",
    PropertyAddress: "40 Skehan St, Wonthaggi",
    LandTaxExemptionId: "13796",
    AssessmentId: "118551",
    EntityId: "168073",
  },
  {
    Row: "49",
    Address: "34 White Rd",
    Assessment: "220-88-5",
    PropertyAddress: "Ison Cres, Wonthaggi",
    LandTaxExemptionId: "14199",
    AssessmentId: "119041",
    EntityId: "2262",
  },
  {
    Row: "50",
    Address: "22 Wishart St, ALTONA NORTH",
    Assessment: "220-90-46",
    PropertyAddress: "10 Ison Cres, Wonthaggi",
    LandTaxExemptionId: "14303",
    AssessmentId: "119167",
    EntityId: "170721",
  },
];
