import { IFeeTypes } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";

import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { FeeType } from "@app/products/town-planning/ppr/[id]/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const PublicPortalPortalApplicationSettingForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();
    const { valueGetter, onChange } = formRenderProps;
    const nameOfFeeType = nameOfFactory<FeeType>();

    const fieldPortalDisableLogin = getConfigDataField(
      ECorporateSettingsField.EPlanning_EnableLogin
    );
    const fieldPortalDisableAccountCreation = getConfigDataField(
      ECorporateSettingsField.EPlanning_Flag_AllowNewAccountCreation
    );
    const fieldPortalDisableLodgement = getConfigDataField(
      ECorporateSettingsField.EPlanning_EnableApplicationLodgement
    );
    const fieldPortalDisableNewApplications = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Permit
    );
    const fieldPortalDisableAmendments = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Amendment
    );
    const fieldPortalDisableRevisions = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Revision
    );
    const fieldPortalDisableObjections = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Objection
    );
    const fieldPortalDisablePlansToComply = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_PlansToComply
    );
    const fieldPortalDisableFurtherInformation = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_FurtherInformation
    );
    const fieldPortalDisableOtherDocuments = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_OtherDocuments
    );
    const fieldPortalDisableEnquiries = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Enquiry
    );
    const fieldPortalDisableCertifications = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Certification
    );
    const fieldPortalDisablePayment = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Payment
    );
    const fieldPortalDisableView = getConfigDataField(
      ECorporateSettingsField.EPlanning_Disable_ViewApplication
    );
    const fieldPortalDisableEdit = getConfigDataField(
      ECorporateSettingsField.EPlanning_DisableApplication_Edit
    );
    const fieldPortalSCEnforcementIssue = getConfigDataField(
      ECorporateSettingsField.TP_SC_EnforcementIssue
    );
    const fieldPortalSCPreAppMeeting = getConfigDataField(
      ECorporateSettingsField.TP_SC_PreAppMeeting
    );
    const fieldPortalRestrictRevision = getConfigDataField(
      ECorporateSettingsField.EPlanning_RestrictRevision
    );
    const fieldPortalApplicationFee = getConfigDataField(
      ECorporateSettingsField.TP_PortalApplication_Fee
    );
    const fieldPortalEnquiryFee = getConfigDataField(
      ECorporateSettingsField.TP_PortalEnquiry_Fee
    );
    const fieldHideInheritedCategoriesFromPermit = getConfigDataField(
      ECorporateSettingsField.EPLAN_HideInheritedCategoriesFromPermitToAmendment
    );

    const feeTypesValueDisplayDetails: FeeType[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalApplication_Fee}`
      ) ?? [];
    const enquiryFeeTypesValueDisplayDetails: FeeType[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalEnquiry_Fee}`
      ) ?? [];

    const handleOnChangeFeeTypes = (feeType: IFeeTypes) => {
      onChange(fieldPortalApplicationFee?.FieldName ?? "", {
        value: feeType.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalApplication_Fee}`,
        {
          value: [feeType],
        }
      );
    };

    const handleOnChangeEnquiryFeeTypes = (feeType: IFeeTypes) => {
      onChange(fieldPortalEnquiryFee?.FieldName ?? "", {
        value: feeType.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_PortalEnquiry_Fee}`,
        {
          value: [feeType],
        }
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldPortalDisableLogin && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalDisableLogin?.Title ?? ""}
                isMandatory={fieldPortalDisableLogin.IsMandatory}
              />
              <Field
                name={fieldPortalDisableLogin?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(fieldPortalDisableLogin?.FieldName)}
              />
            </div>
          )}
          {fieldPortalDisableAccountCreation &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableLogin.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableAccountCreation?.Title ?? ""}
                  isMandatory={fieldPortalDisableAccountCreation.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableAccountCreation?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(
                    fieldPortalDisableAccountCreation?.FieldName
                  )}
                />
              </div>
            )}
          {fieldPortalDisableLodgement && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalDisableLodgement?.Title ?? ""}
                isMandatory={fieldPortalDisableLodgement.IsMandatory}
              />
              <Field
                name={fieldPortalDisableLodgement?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(fieldPortalDisableLodgement?.FieldName)}
              />
            </div>
          )}
          {fieldPortalDisableNewApplications &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableNewApplications?.Title ?? ""}
                  isMandatory={fieldPortalDisableNewApplications.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableNewApplications?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(
                    fieldPortalDisableNewApplications?.FieldName
                  )}
                />
              </div>
            )}
          {fieldPortalDisableAmendments &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableAmendments?.Title ?? ""}
                  isMandatory={fieldPortalDisableAmendments.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableAmendments?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(fieldPortalDisableAmendments?.FieldName)}
                />
              </div>
            )}
          {fieldPortalDisableRevisions &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableRevisions?.Title ?? ""}
                  isMandatory={fieldPortalDisableRevisions.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableRevisions?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(fieldPortalDisableRevisions?.FieldName)}
                />
              </div>
            )}
          {fieldPortalDisableObjections &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableObjections?.Title ?? ""}
                  isMandatory={fieldPortalDisableObjections.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableObjections?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(fieldPortalDisableObjections?.FieldName)}
                />
              </div>
            )}
          {fieldPortalDisablePlansToComply &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisablePlansToComply?.Title ?? ""}
                  isMandatory={fieldPortalDisablePlansToComply.IsMandatory}
                />
                <Field
                  name={fieldPortalDisablePlansToComply?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(
                    fieldPortalDisablePlansToComply?.FieldName
                  )}
                />
              </div>
            )}
          {fieldPortalDisableFurtherInformation &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableFurtherInformation?.Title ?? ""}
                  isMandatory={fieldPortalDisableFurtherInformation.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableFurtherInformation?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(
                    fieldPortalDisableFurtherInformation?.FieldName
                  )}
                />
              </div>
            )}
          {fieldPortalDisableOtherDocuments &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableOtherDocuments?.Title ?? ""}
                  isMandatory={fieldPortalDisableOtherDocuments.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableOtherDocuments?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(
                    fieldPortalDisableOtherDocuments?.FieldName
                  )}
                />
              </div>
            )}
          {fieldPortalDisableEnquiries &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableEnquiries?.Title ?? ""}
                  isMandatory={fieldPortalDisableEnquiries.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableEnquiries?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(fieldPortalDisableEnquiries?.FieldName)}
                />
              </div>
            )}
          {fieldPortalDisableCertifications &&
            !valueGetter(
              ECorporateSettingsField.EPlanning_EnableApplicationLodgement.toString()
            ) && (
              <div className="cc-field">
                <CCLabel
                  title={fieldPortalDisableCertifications?.Title ?? ""}
                  isMandatory={fieldPortalDisableCertifications.IsMandatory}
                />
                <Field
                  name={fieldPortalDisableCertifications?.FieldName}
                  component={CCSwitch}
                  disabled={!isEditing}
                  checked={valueGetter(
                    fieldPortalDisableCertifications?.FieldName
                  )}
                />
              </div>
            )}
          {fieldPortalDisablePayment && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalDisablePayment?.Title ?? ""}
                isMandatory={fieldPortalDisablePayment.IsMandatory}
              />
              <Field
                name={fieldPortalDisablePayment?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(fieldPortalDisablePayment?.FieldName)}
              />
            </div>
          )}
          {fieldPortalDisableView && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalDisableView?.Title ?? ""}
                isMandatory={fieldPortalDisableView.IsMandatory}
              />
              <Field
                name={fieldPortalDisableView?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(fieldPortalDisableView?.FieldName)}
              />
            </div>
          )}
          {fieldPortalDisableEdit && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalDisableEdit?.Title ?? ""}
                isMandatory={fieldPortalDisableEdit.IsMandatory}
              />
              <Field
                name={fieldPortalDisableEdit?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(fieldPortalDisableEdit?.FieldName)}
              />
            </div>
          )}
          {fieldPortalSCEnforcementIssue && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalSCEnforcementIssue?.Title ?? ""}
                isMandatory={fieldPortalSCEnforcementIssue.IsMandatory}
              />
              <Field
                name={fieldPortalSCEnforcementIssue?.FieldName}
                component={CCSwitch}
                disabled={true}
                checked={valueGetter(fieldPortalSCEnforcementIssue?.FieldName)}
              />
            </div>
          )}
          {fieldPortalSCPreAppMeeting && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalSCPreAppMeeting?.Title ?? ""}
                isMandatory={fieldPortalSCPreAppMeeting.IsMandatory}
              />
              <Field
                name={fieldPortalSCPreAppMeeting?.FieldName}
                component={CCSwitch}
                disabled={true}
                checked={valueGetter(fieldPortalSCPreAppMeeting?.FieldName)}
              />
            </div>
          )}
          {fieldPortalRestrictRevision && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalRestrictRevision?.Title ?? ""}
                isMandatory={fieldPortalRestrictRevision.IsMandatory}
              />
              <Field
                name={fieldPortalRestrictRevision?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(fieldPortalRestrictRevision?.FieldName)}
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-1">
          {fieldPortalApplicationFee && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalApplicationFee?.Title ?? ""}
                isMandatory={fieldPortalApplicationFee.IsMandatory}
              />
              <Field
                name={fieldPortalApplicationFee?.FieldName ?? ""}
                placeholder={fieldPortalApplicationFee?.Title ?? ""}
                value={feeTypesValueDisplayDetails[0]}
                component={FeeTypesPicker}
                isGetDetail={false}
                disabled={!isEditing}
                selectableMode="single"
                onPickFeeTypes={handleOnChangeFeeTypes}
                textField={nameOfFeeType("Name")}
                validator={
                  fieldPortalApplicationFee.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldPortalEnquiryFee && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalEnquiryFee?.Title ?? ""}
                isMandatory={fieldPortalEnquiryFee.IsMandatory}
              />
              <Field
                name={fieldPortalEnquiryFee?.FieldName ?? ""}
                placeholder={fieldPortalEnquiryFee?.Title ?? ""}
                value={enquiryFeeTypesValueDisplayDetails[0]}
                component={FeeTypesPicker}
                isGetDetail={false}
                disabled={!isEditing}
                selectableMode="single"
                onPickFeeTypes={handleOnChangeEnquiryFeeTypes}
                textField={nameOfFeeType("Name")}
                validator={
                  fieldPortalEnquiryFee.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldHideInheritedCategoriesFromPermit && (
            <div className="cc-field">
              <CCLabel
                title={fieldHideInheritedCategoriesFromPermit?.Title ?? ""}
                isMandatory={fieldHideInheritedCategoriesFromPermit.IsMandatory}
              />
              <Field
                name={fieldHideInheritedCategoriesFromPermit?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={valueGetter(
                  fieldHideInheritedCategoriesFromPermit?.FieldName
                )}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
