import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { ASSESSMENTS_DEBT_RECOVERY_ROUTE } from "@app/products/property/assessments/debt-recovery/[id]/constant";
import { colDebtRecoveryExemption } from "@app/products/property/assessments/debt-recovery/exemptions/config";
import { VO_DebtRecovery_Exemption } from "@app/products/property/assessments/debt-recovery/exemptions/model";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/debt-recovery/exemptions/util";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/assessments/debt-recovery/util";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { NoData } from "@components/no-data/NoData";
import React from "react";

const nameOf = nameOfFactory<VO_DebtRecovery_Exemption>();
const gridId = getUUID();
export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Debt_Recovery}
        recordType={RECORDTYPE.CommunityProperty_Debt_Recovery_Exemption}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Debt_Recovery_Exemption}
        keyField={"Debt_Recovery_Id"}
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton gridId={gridId} />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ASSESSMENTS_DEBT_RECOVERY_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [{ title: "Details", component: <NoData /> }],
  });
  return (
    <CCProductListView
      gridId={gridId}
      dataUrl={`odata/property/internal/debtrecoveryexemptionregister?&$count=true&`}
      columnFields={colDebtRecoveryExemption}
      primaryField={nameOf("Debt_Recovery_Exemption_Id")}
      state={{
        sort: [{ field: "Debt_Recovery_Exemption_Id", dir: "desc" }],
      }}
    />
  );
};
