import {
  BarrierStandardsColumns,
  BuildingUrl,
  OfficerColumns,
} from "@app/products/building/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export const officerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Officer",
  placeholder: "Officer",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Officer_Field",
  value: formRenderProps.valueGetter("Officer"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("Officer", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("Officer", {
        value: event.detail.Name,
      });
    }
  },
});

export const barrierStandardsFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Barrier Standards",
  placeholder: "Barrier Standards",
  tableTypes: BarrierStandardsColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "BarrierTypes_Field",
  value: formRenderProps.valueGetter("BarrierTypes"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("BarrierTypes", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("BarrierTypes", {
        value: event.detail.Name,
      });
      formRenderProps.onChange("BarrierTypesDesc", {
        value: event.detail.Position,
      });
    }
  },
});

export const regulationsFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Regulation",
  placeholder: "BCA or Regulations Applicable",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Regulation_Field",
  value: formRenderProps.valueGetter("Regulation"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("Regulation", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("Regulation", {
        value: event.detail.Name,
      });

      formRenderProps.onChange("RegulationsDesc", {
        value: event.detail.Position,
      });
    }
  },
});
