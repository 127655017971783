import { duplicateEventColumns } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/dialogs/duplication-checker-dialog/config";
import { renderByCategoryAndLocationOdataURL } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/recent-events-accordion/config";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { addDays } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";

const nameOf = nameOfFactory<EventView>();
interface IDuplicationCheckerDialogProps {
  duplicationEventValue: any;
  onCloseDialog: () => void;
  handleDuplicateEventSameIssue: (event: EventView) => void;
  handleGoToExistingEvent: (event: EventView) => void;
}
export const DuplicationCheckerDialog = observer(
  ({
    duplicationEventValue,
    onCloseDialog,
    handleDuplicateEventSameIssue,
    handleGoToExistingEvent,
  }: IDuplicationCheckerDialogProps) => {
    const { settings } = useCommonCoreStore();
    const [gridSelectedRows, setGridSelectedRows] = useState<EventView[]>([]);
    const recentEventsFilterDays = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Event_RecentEventsFilterDays
      ]
    );
    const isShowNewRequestSameIssueButton = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_Event_ShowNewRequestSameIssueButton
      ]
    );
    const recentEventsShowOpenEventsOnly = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_Event_RecentEventsShowOpenEventsOnly
      ]
    );

    const [stateGrid, setStateGrid] = useState<State>(() => {
      const recentEventsFilterDaysValue = !recentEventsFilterDays
        ? 7
        : recentEventsFilterDays;
      return {
        sort: [{ field: nameOf("ID"), dir: "desc" }],
        filter: {
          logic: "and",
          filters: [
            {
              field: nameOf("DateReceived"),
              operator: "gt",
              value: addDays(new Date(), -1 * recentEventsFilterDaysValue - 1),
            },
          ],
        },
      };
    });

    return (
      <CCDialog
        maxWidth="60%"
        height="auto"
        titleHeader={"Go To An Existing Event Or Pick Same Issue Event"}
        onClose={onCloseDialog}
        bodyElement={
          <div className="cc-form cc-duplicate-event-wrapper">
            <CCGrid
              style={{ height: "100%" }}
              selectableMode="single"
              isAutoHiddenPager={false}
              itemPerPage={10}
              dataUrl={renderByCategoryAndLocationOdataURL(
                0,
                duplicationEventValue?.ServiceStandard_Id,
                duplicationEventValue?.AssessmentNo,
                duplicationEventValue?.Formatted_AddressLine1,
                duplicationEventValue?.Suburb,
                recentEventsShowOpenEventsOnly
              )}
              primaryField={nameOf("ID")}
              selectedRows={gridSelectedRows}
              onSelectionChange={(dataItem: EventView[]) => {
                setGridSelectedRows([...dataItem]);
              }}
              onDataStateChange={setStateGrid}
              columnFields={duplicateEventColumns}
              state={stateGrid}
            />
          </div>
        }
        footerElement={
          <div className="cc-dialog-footer-actions-right">
            <Button className={"cc-dialog-button"} onClick={onCloseDialog}>
              Cancel
            </Button>
            <Button
              className={"cc-dialog-button"}
              onClick={() => handleGoToExistingEvent(gridSelectedRows[0])}
              disabled={gridSelectedRows?.length !== 1}
            >
              Go To Existing Event
            </Button>

            {isShowNewRequestSameIssueButton && (
              <Button
                className="cc-dialog-button"
                onClick={() =>
                  handleDuplicateEventSameIssue(gridSelectedRows[0])
                }
                disabled={gridSelectedRows?.length !== 1}
              >
                Same Issue
              </Button>
            )}

            <Button
              className="cc-dialog-button"
              themeColor="primary"
              onClick={onCloseDialog}
            >
              Continue With My Event
            </Button>
          </div>
        }
      />
    );
  }
);
