import {
  DocumentTemplateInitData,
  DocumentTemplate_Image,
  IKeyValuePacketBoolean,
} from "@app/products/property/system-admin/document-template/[id]/model";

export const AvailabilityData: IKeyValuePacketBoolean[] = [
  {
    Key: true,
    Value: "Active",
  },
  {
    Key: false,
    Value: "Inactive",
  },
];
export const RegionsData: IKeyValuePacketBoolean[] = [
  {
    Key: true,
    Value: "Yes",
  },
  {
    Key: false,
    Value: "No",
  },
];
export const defaultInitValue: DocumentTemplateInitData = {
  IsActive: true,
  UseRegions: false,
};
export const customMessage = {
  upload: {
    select: "",
    dropFilesHere: "",
  },
};
export const customMessageFile = {
  upload: {
    invalidFileExtension: "Only .doc or .docx is allowed",
  },
};
// allow the extension of the images to be uploaded
export const allowedExtensionsImage = [".jpeg", ".png", ".gif", ".svg", ".jpg"];
export const allowedExtensionsFile = [".doc", ".docx"];
export const defaultNewImage: DocumentTemplate_Image = {
  Width: 0,
  Height: 0,
  IsActive: true,
};
