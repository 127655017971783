import { CRMSViews } from "@app/products/crms/model";
import { categoryManageRoute } from "@app/products/crms/system-admin/categories/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_Administration,
  children: [
    {
      path: "categories",
      name: "Categories",
      enum: CRMSViews.Admin_Categories,
      component: require("./categories/_index").default,
      children: [categoryManageRoute],
    },
    {
      path: "location-register",
      name: "Location Register",
      enum: CRMSViews.Admin_LocationRegister,
      component: require("./location-register/_index").default,
    },
    {
      path: "lookup-tables",
      name: "Lookup Tables",
      enum: CRMSViews.Admin_LookupTables,
      component: require("./lookup-tables/_index").default,
    },
    {
      path: "rosters",
      name: "Rosters",
      enum: CRMSViews.Admin_Rosters,
      component: require("./rosters/_index").default,
      children: [
        {
          path: `:id(new|[0-9]+)`,
          component: require("./rosters/[id]/_index").default,
        },
      ],
    },
    {
      path: "settings",
      name: "Settings",
      enum: CRMSViews.Admin_SystemSettings,
      component: require("./settings/_index").default, // navigated to Radium already
    },
  ],
};
