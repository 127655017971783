export const mockHazldCases = [
  {
    Locality: "Battery Point",
    HouseNumber: "23",
    Street: "Albuera\r\n",
    AssessmentNumber: "2214889",
    Owners: "I S Middleton & J M Middleton",
    InspectionDate: new Date("7-Jan-2021 17:04"),
    Clearance: "10m Fire Break",
    Outcome: "Owners to clear property",
    ToBeClearedBy: new Date("16-Jan-2022"),
    Date: new Date("07-Jan-2022"),
    Period: "2021/2022",
    ParcelId: 231040,
    CaseId: 210004,
  },
];
