import { colGroupsOrgUser } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/accordions/special-interest/config";
import { options } from "@app/products/crms/service-standards/batch-update/constant";
import {
  ServiceStandardBatchUpdateHandlerRequest,
  ServiceStandardBatchUpdateMapObj,
  Svc_FormAction_ServiceStandardBatchUpdate,
} from "@app/products/crms/service-standards/batch-update/model";
import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { validatorManualEntriesEmail } from "@app/products/crms/util";
import { AccessControl } from "@common/models/accessControl";
import { PickUserGroupOrgButton } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/buttons/pick-user-group-org/_index";
import { IDataUserGroupOrgForm } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/model";
import { IAccessControl } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/security/forms/security-settings/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface ISpecialInterestProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

const nameOfSvcStandardBatchUpdateMapObj =
  nameOfFactory<ServiceStandardBatchUpdateMapObj>();
const nameOfAccessControl = nameOfFactory<AccessControl>();

export const SpecialInterest = observer(
  ({ formRenderProps, isDisabled }: ISpecialInterestProps) => {
    const { valueGetter, onChange } = formRenderProps;

    const {
      svcStandardBatchUpdateLovs,
      svcStandardBatchUpdateChangeHandler,
      setIsExpandedSpecialInterest,
    } = useSvcStandardBatchUpdateStore();

    const [listSelected, setListSelected] = useState<IAccessControl[]>([]);
    const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
      useState<boolean>(false);

    const SvcStandardBatchUpdateMapObj = valueGetter(
      nameOfSvcStandardBatchUpdateMapObj("SvcStandardBatchUpdate")
    );

    const specialInterest = valueGetter(
      getNameOfSvcStandardBatchUpdate("SpecialInterest")
    );

    const handleAddInterestEntities = (value: IDataUserGroupOrgForm) => {
      const groups = value?.Groups ?? [];
      const orgStructure = value?.OrgStructure ?? [];
      const users = value?.Users ?? [];
      const data = [...groups, ...orgStructure];

      let hierarchiesIds = data.map((item) => item.ID);
      const userIds = users.map((item) => item.ID);

      if (hierarchiesIds.length === 0 && userIds.length === 0) return;

      const params: ServiceStandardBatchUpdateHandlerRequest = {
        ServiceStandardBatchUpdateFormAction:
          Svc_FormAction_ServiceStandardBatchUpdate.PickSpecialInterest,
        ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
        EventArgs: {
          SiteUsers: userIds ?? [],
          Hierarchies: hierarchiesIds ?? [],
        },
        IsFirstTimeLoad: false,
      };
      svcStandardBatchUpdateChangeHandler(
        params,
        "Change interest entities failed."
      );
      setIsExpandedSpecialInterest(true);
    };

    const handleDeleteUser = () => {
      const ids = listSelected.map((item: IAccessControl) => item.EntityID);

      if (ids.length > 0) {
        const newList = specialInterest?.filter(
          (item: IAccessControl) => !ids.includes(item.EntityID)
        );

        onChange(`${getNameOfSvcStandardBatchUpdate("SpecialInterest")}`, {
          value: newList,
        });

        setListSelected([]);
        setIsShowConfirmDeleteDialog(false);
      }
    };

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update has special interest" />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "Update_HasSpecialInterest"
                )}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate("Update_HasSpecialInterest")
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(
              getNameOfSvcStandardBatchUpdate("Update_HasSpecialInterest")
            ) ? (
              <div className="cc-field">
                <CCLabel title="Has special interest" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("HasSpecialInterest")}
                  component={RadioGroup}
                  data={options}
                  layout={"horizontal"}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCLabel title="Internal Requestors:" />
          </div>

          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update email org manager" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailRequestorOrgManager"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailRequestorOrgManager"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailRequestorOrgManager"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Email org manager" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailRequestorOrgManager"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update org level" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailRequestorOrgManager_OrgLevel"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailRequestorOrgManager_OrgLevel"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailRequestorOrgManager_OrgLevel"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Org level" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailRequestorOrgManager_OrgLevel"
                    )}
                    dataItemKey="Key"
                    textField="Value"
                    data={
                      svcStandardBatchUpdateLovs?.SpecialInterestEmailRequestorOrgLevel ??
                      []
                    }
                    isUseDefaultOnchange
                    component={CCSearchComboBox}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCLabel title="Send email when:" />
          </div>

          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update created" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailWhenCreated"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailWhenCreated"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailWhenCreated"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Created" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailWhenCreated"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update recategorised" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailWhenRecategorised"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailWhenRecategorised"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailWhenRecategorised"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Recategorised" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailWhenRecategorised"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update rescheduled" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailWhenRescheduled"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailWhenRescheduled"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailWhenRescheduled"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Rescheduled" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailWhenRescheduled"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update overdue" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailWhenOverdue"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailWhenOverdue"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailWhenOverdue"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Overdue" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailWhenOverdue"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update closed" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailWhenClosed"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailWhenClosed"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailWhenClosed"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Closed" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailWhenClosed"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update send for action" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEmailWhenSentForAction"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_SpecialInterestEmailWhenSentForAction"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEmailWhenSentForAction"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Send for action" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "SpecialInterestEmailWhenSentForAction"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update manual entries" />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestManualEntries"
                )}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestManualEntries"
                  )
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>

            {valueGetter(
              getNameOfSvcStandardBatchUpdate(
                "Update_SpecialInterestManualEntries"
              )
            ) ? (
              <div className="cc-field">
                <CCLabel title="Manual entries" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "SpecialInterestManualEntries"
                  )}
                  component={CCTextArea}
                  rows={3}
                  disabled={isDisabled}
                  validator={validatorManualEntriesEmail}
                />
              </div>
            ) : null}
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update special interest entities" />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEntities"
                )}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate(
                    "Update_SpecialInterestEntities"
                  )
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
          </div>

          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SpecialInterestEntities"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Special interest entities" />
                  <CCGrid
                    columnFields={colGroupsOrgUser}
                    primaryField={nameOfAccessControl("EntityID")}
                    data={valueGetter(
                      getNameOfSvcStandardBatchUpdate("SpecialInterest")
                    )}
                    selectableMode={"multiple"}
                    selectedRows={listSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <PickUserGroupOrgButton
                          onSubmit={handleAddInterestEntities}
                          maxHeight="85%"
                        />
                        <Button
                          type="button"
                          iconClass="fas fa-minus"
                          title="Remove"
                          onClick={() => setIsShowConfirmDeleteDialog(true)}
                          disabled={listSelected.length === 0}
                        />
                      </div>
                    }
                    onSelectionChange={(dataItem: IAccessControl[]) => {
                      setListSelected(dataItem ?? []);
                    }}
                    itemPerPage={50}
                    isAutoHiddenPager={false}
                    readOnly={isDisabled}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {isShowConfirmDeleteDialog && (
            <ConfirmDialog
              title={"Confirm Deletion"}
              subMessage={`Are you sure you want to delete ${
                listSelected.length === 1 ? "this item" : "these items"
              }?`}
              onClosePopup={() => {
                setIsShowConfirmDeleteDialog(false);
              }}
              onConfirmYes={handleDeleteUser}
            />
          )}
        </section>
      </>
    );
  }
);
