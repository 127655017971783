import { HMFieldMapping } from "@app/products/hm/model";

export const colHMSystemAdminInspComments = [
  {
    field: HMFieldMapping.Comment,
    title: "Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Question_IDS,
    title: "Questions",
    width: 150,
  },
];
