import {
  apiCoreGetCondition,
  apiCoreGetConditionLOVs,
  apiCoreSaveCondition,
  apiCoreUpdateConditionSortIndex,
} from "@app/core/components/common/utils";
import {
  Condition,
  Svc_Condition,
  Svc_Condition_Lovs,
} from "@app/core/condition/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getConditionById = async (
  conditionId: number,
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<Svc_Condition>> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<Svc_Condition>>(
      apiCoreGetCondition(conditionId),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getConditionLovs = async (
  productType: PRODUCT_TYPE_NUMBER,
  recordType: RECORDTYPE,
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<Svc_Condition_Lovs>> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_Condition_Lovs>
    >(apiCoreGetConditionLOVs(), {
      params: { productType, recordType },
      cancelToken,
    });
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSaveCondition = async (
  condition: Condition,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveCondition(),
      condition,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postUpdateConditionSortIndex = async (
  conditionID: number,
  sortIndex: number,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreUpdateConditionSortIndex(conditionID, sortIndex),
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
