import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const renderTopSwitchListEnums = [
  ECorporateSettingsField.TP_SendFailureNotificationEmail,
  ECorporateSettingsField.TP_AutoCompleteMeetings,
  ECorporateSettingsField.TP_DisplayControlLine,
  ECorporateSettingsField.TP_UpdateStatDays,
  ECorporateSettingsField.TP_DisplayPropertyOwner,
  ECorporateSettingsField.TP_AllowAmendmentsOnAmendments,
  ECorporateSettingsField.TP_AmendmentInheritApplicant,
  ECorporateSettingsField.TP_InheritAssessmentDetailsOnAmendment,
  ECorporateSettingsField.TP_PlansToComplyInheritApplicant,
  ECorporateSettingsField.TP_SubdivisionInheritApplicant,
  ECorporateSettingsField.TP_ExtensionOfTimeInheritApplicant,
  ECorporateSettingsField.TP_SecondaryConsentInheritApplicant,
  ECorporateSettingsField.TP_ExtensionOfTimeInheritPlanner,
  ECorporateSettingsField.TP_SecondaryConsentInheritOfficer,
  ECorporateSettingsField.TP_ShowDocsTab,
  ECorporateSettingsField.TP_ShowIPContacts,
  ECorporateSettingsField.TP_SimpleCondtiionsTab,
  ECorporateSettingsField.TP_UseVicSmart,
];
export const renderMiddle1SwitchListEnums = [
  ECorporateSettingsField.TP_SecondaryConsentEnabled,
  ECorporateSettingsField.TP_PublicRegister_EnableExtensionOfTime,
  ECorporateSettingsField.TP_SOCInPlanningPermit,
  ECorporateSettingsField.TP_FeeSchedule,
  ECorporateSettingsField.TP_EnforcementIssue,
  ECorporateSettingsField.TP_SC_EnforcementIssue,
  ECorporateSettingsField.TP_PreAppMeeting,
  ECorporateSettingsField.TP_SC_PreAppMeeting,
];
export const renderMiddle2SwitchListEnums = [
  ECorporateSettingsField.TP_Application_UseFinalOutCome,
  ECorporateSettingsField.TP_AppellantInheritApplicant,
  ECorporateSettingsField.TP_Application_EnablePlanningEnquiryEscallation,
];

export const renderMiddle3SwitchListEnums = [
  ECorporateSettingsField.TP_PlanningEnquiry_EnableQuickClose,
  ECorporateSettingsField.TP_EnablePlansToComplyForAmendment,
  ECorporateSettingsField.TP_EnablePlanningNumberLabelOnSubdivision,
  ECorporateSettingsField.TP_Disable_Planner_Rating,
];
