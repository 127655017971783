import {
  ActionPersonAllocation,
  eCalculateCompletionTargetInDaysOrHours,
  ICRMSServiceStandardSummary,
} from "@app/products/crms/service-standards/[id]/model";

export const mockCRMSServiceStandardForm: ICRMSServiceStandardSummary = {
  Id: 9321,
  Category: "Bacon Advanced",
  SubCategory: "Intelligent",
  Precis: "multimedia",
  Description: "SMS Applications Nevada",
  OrgStructure: "solid state Gibraltar matrix",
  AutomaticAllocation: true,
  ActionPersonAllocation: ActionPersonAllocation.STAFF,
  ActionPerson: "multimedia",
  SendToCoordinatorPerson: true,
  SendToActionPerson: true,
  AllowAnonymous: true,
  AdditionalRefNo: true,
  AllowReCategories: true,
  AllowReallocateOfficer: true,
  HasNotificationsOrAlerts: true,
  HasTargetDays: true,
  HasSpecialInterest: true,
  AllowQuickClose: true,
  AssessmentNoIsMandatory: false,
  RequestorAddressIsMandatory: true,
  PromptForLocation: true,
  SetMeAsRequestor: true,
  AddAttachment: true,
  ShowMap: true,
  LockEventOnSendForAction: true,
  EnableComplainee: true,
  EmailRequestorOnSchedule: false,
  CRMS: false,
  KnowledgeBase: true,
  System: true,
  WWMS: true,
  ControlledSubstance: true,
  Animal: true,
  ITSupport: true,
  Health: true,
  LocalLaws: true,
  TownPlanning: true,
  Building: true,
  InternetChoice: true,
  EMS: true,
  ParkingPermits: true,
  Infringements: true,
  SimpleForm: true,
  Inspections: true,
  EnableAnimalsLookup: true,
  Notices: true,
  AutoActions: true,
  EnableBinsLookup: true,
  Assets: true,
  HardRubbish: false,
  Payroll: true,
  CollectionDate: true,
  EnableSchoolPatrols: true,
  EnableNuisanceAct: true,
  AfterHoursSendEmail: false,
  UseSystemDefault: true,
  //Asset Details
  SendToRAMM: true,
  AssetIsMandatory: false,
  ContractId: 10,
  FaultCode: "VDO",
  AssetType: 10016,
  Priority: 7,
  //Communication Settings
  SendToCoordinator: "No",
  SendToActionOfficer: "No",
  SendEmailToRequestorOnSendForAction: true,
  SendEmailToRequestorOnResendForAction: false,
  SendSmsToRequestorOnSendForAction: true,
  SendSmsToRequestorOnReSendForAction: true,
  SendEmailToOnBehalfOfOnSendForAction: false,
  SendEmailToOnBehalfOfOnReSendForAction: true,
  SendSMSToRequestorOnClosureForAction: "No",
  //Customer Service Validation & Resolution Responses
  TextCSValidation:
    "This description will enable the customer service validation to appear on the event entry screen. It will restrict the CS Officer from sending the request for action until they validate the option.",
  //Health Manager
  MobileChoice: true,
  //Mobile Details
  ShowHealthFieldsOnEventClosure: false,
  //Security
  IsConfidentiality: true,
  //Special Interest
  ManualEntries: "solid state Gibraltar matrix",
  EmailOrgManager: true,
  Created: true,
  ReCategorised: true,
  Rescheduled: true,
  Overdue: false,
  Closed: true,
  SentForAction: false,
  //Target Days
  CalculateCompletionTargetInDaysOrHours:
    eCalculateCompletionTargetInDaysOrHours.HOURS,
  Completion: 49,
  UrgentCompletion: 4,
  UseAcknowledgement: true,
  MayBeRescheduled: true,
  MaxNoOfDayToComplete: 100,
  NotifyCustomerIfRescheduled: true,
  FinalNotification: true,
  IncludeWeekendsOrPublicHolidaysInTargetCalculation: true,
  EnableInternalClosureComment: true,
};
