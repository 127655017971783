import { validateNotNegativeNumber } from "@common/utils/field-validators";
import { isNil } from "lodash";

const SQL_SERVER_INT_MAX = 2147483647;

const sqlServerMaxIntValidator = (value: any) => {
  if (value && value > SQL_SERVER_INT_MAX)
    return "The maximum value is " + SQL_SERVER_INT_MAX;
  return "";
};

export const positiveAndMaxIntValidator = (value: any) => {
  if (isNil(value)) return "";
  const positiveValidationResult = validateNotNegativeNumber(value);
  if (positiveValidationResult !== "") return positiveValidationResult;
  return sqlServerMaxIntValidator(value);
};
