import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";

export const productLogic = (recordType?: RECORDTYPE) => {
  switch (recordType) {
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      return PRODUCT_TYPE.CustomerService;
    case RECORDTYPE.DisRes_Permit:
      return PRODUCT_TYPE.DISRES;
    case RECORDTYPE.HealthManager_Premises:
      return PRODUCT_TYPE.HealthManager;
    case RECORDTYPE.WW_System:
      return PRODUCT_TYPE.WasteWater;
    case RECORDTYPE.WW_Installation:
    case RECORDTYPE.WW_Scheme:
    case RECORDTYPE.WW_Product:
      return PRODUCT_TYPE.WasteWaterSPI;
    case RECORDTYPE.CRS_Registration:
    case RECORDTYPE.CRS_FourYOKinder:
    case RECORDTYPE.CRS_HomeBasedCare:
    case RECORDTYPE.CRS_ThreeYOKinder:
    case RECORDTYPE.CRS_CentreBasedCare:
      return PRODUCT_TYPE.CRS;
    case RECORDTYPE.Animals_Registration:
    case RECORDTYPE.Animals_Kennel:
    case RECORDTYPE.Animals_Notices:
    case RECORDTYPE.Animals_PoundRegister:
      return PRODUCT_TYPE.Animals;
    case RECORDTYPE.CORE_Register_StreetProtection:
      return PRODUCT_TYPE.StreetProtection;
    case RECORDTYPE.LLP_Permit:
      return PRODUCT_TYPE.LLPermits;
    case RECORDTYPE.TP_Application:
    case RECORDTYPE.TP_Subdivision:
    case RECORDTYPE.TP_PSA_Application:
    case RECORDTYPE.TP_OtherApplication:
    case RECORDTYPE.TP_PPRApplication:
      return PRODUCT_TYPE.TownPlanning;
    case RECORDTYPE.TP_BuildingApplication:
      return PRODUCT_TYPE.Building;
    case RECORDTYPE.TP_DevAppsApplication:
      return PRODUCT_TYPE.DevApps;
    case RECORDTYPE.CSM_Licence:
      return PRODUCT_TYPE.CSM;
    case RECORDTYPE.NSP_Register:
      return PRODUCT_TYPE.NSPR;
    case RECORDTYPE.EMS_Facility:
    case RECORDTYPE.EMS_FacilityFeature:
    case RECORDTYPE.EMS_EventBooking:
      return PRODUCT_TYPE.EventManagementSystem;
    case RECORDTYPE.RAD_Register_Licence:
    case RECORDTYPE.RAD_Register_Place:
    case RECORDTYPE.RAD_Register_Source:
    case RECORDTYPE.RAD_Register_Accreditation:
      return PRODUCT_TYPE.Radiation;
    case RECORDTYPE.DWCMS_Application:
    case RECORDTYPE.DWCMS_Worker:
    case RECORDTYPE.DWCMS_Registration:
      return PRODUCT_TYPE.DWCMS;
    case RECORDTYPE.SRU_Application:
    case RECORDTYPE.SRU_Organisation:
    case RECORDTYPE.SRU_Registration:
    case RECORDTYPE.SRU_Facility:
    case RECORDTYPE.SRU_RegistrationAdministration:
    case RECORDTYPE.SRU_RegistrationStatement:
    case RECORDTYPE.SRU_Infringement:
    case RECORDTYPE.SRU_SuspendAdmission:
    case RECORDTYPE.SRU_RegistrationSanction:
      return PRODUCT_TYPE.StandardsAndRegulation;
    case RECORDTYPE.INFRINGEMENTS_InfringementTicket:
    case RECORDTYPE.INFRINGEMENTS_InfringementOffence:
    case RECORDTYPE.INFRINGEMENTS_InfringementCategory:
    case RECORDTYPE.INFRINGEMENTS_InfringementAnimals:
      return PRODUCT_TYPE.Infringements;
    case RECORDTYPE.TP_LMApplication:
      return PRODUCT_TYPE.LandManagement;
    case RECORDTYPE.CEM_Entry:
    case RECORDTYPE.CEM_Registration:
      return PRODUCT_TYPE.Cemeteries;
    default:
      return PRODUCT_TYPE.Core;
  }
};
