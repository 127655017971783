import { useActionStore } from "@common/pages/actions/[id]/store";
import { Action, ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import { ActionFormElement } from "./components/form-element/_index";
import { removeHTMLTags } from "./components/form-element/util";
import { sanitizeWithoutParse } from "@common/utils/sanitized-parser";

export const ActionForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { action, setIsLoading, saveAction, setOnSubmit } = useActionStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(actionSubmit in ActionSubmitActions)) return;
    if (!isValid) return;
    if (
      !isModified &&
      [ActionSubmitActions.Save, ActionSubmitActions.New].includes(actionSubmit)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const actionInfo = values as Action;
    actionInfo.Description = removeHTMLTags(sanitizeWithoutParse(actionInfo.Description));
    setIsLoading(true);
    await saveAction(actionInfo, actionSubmit);
    setIsLoading(false);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  const initialValues = useMemo(() => {
    let actionRequired = action?.ActionRequired;
    if (
      action?.ActionType?.Flag_IsMeeting ||
      action?.ActionType?.Flag_IsReferral ||
      action?.ActionType?.IsApproval
    ) {
      actionRequired = true;
    }

    return {
      ...action,
      ActionRequired: actionRequired,
    };
  }, [action]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <ActionFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
