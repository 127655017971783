import { buildingRoute } from "@app/products/building/route";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import React from "react";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

export function BuildingRouter(props: any) {
  return (
    <ProductRouterSwitch>
      {buildingRoute.children &&
        convertRouteDynamic(buildingRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element => {
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
}
