import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const kennelsRoute: ICCRoute = {
  path: "kennels",
  name: "Kennels",
  enum: AnimalsMenu.Kennels,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
    {
      path: "register",
      name: "Register",
      enum: AnimalsMenu.KennelByNumber,
      component: require("./register/_index").default,
    },
    {
      path: "active",
      name: "Active",
      enum: AnimalsMenu.KennelActive,
      component: require("./active/_index").default,
    },
    {
      path: "inactive",
      name: "Inactive",
      enum: AnimalsMenu.KennelInActive,
      component: require("./inactive/_index").default,
    },
    {
      path: "pending-approval",
      name: "Pending Approval",
      enum: AnimalsMenu.KennelPendingApproval,
      component: require("./pending-approval/_index").default,
    },
  ],
};
