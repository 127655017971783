import { listPICJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/constant";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import {
  DTO_Journal,
  JournalStatus,
} from "@app/products/property/journals/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { cloneDeep, isNil } from "lodash";

export const isHideBaseOnJournalType = (journalType: eJournalType) => {
  return listPICJournalType.includes(journalType);
};

/**
 * This modifies the parameter which is in form of
 * workflow details that include CreateJournal
 */
export const omitOfficerBasedOnStatus = <W extends OptionalCreateJournal>(
  workflowPayload: W
): void => {
  if (
    JournalStatus.Authorised !==
      workflowPayload.CreateJournal?.Journal_Status &&
    !isNil(workflowPayload.CreateJournal?.Officer_Id)
  ) {
    delete workflowPayload.CreateJournal?.Officer_Id;
  }
};

export function getWorkflowOverwrittenAtAuthorisingOfficer<
  W extends OptionalCreateJournal
>(workflowData: DTO_Workflow_JournalMatter<W>): DTO_Workflow_JournalMatter<W> {
  // Get CreateJournal.Officer_Id overwritten to match with current user
  // regarding task 13409
  const overwrittenData = cloneDeep(workflowData);
  if (
    overwrittenData &&
    overwrittenData.WorkflowDetail &&
    overwrittenData.WorkflowDetail.CreateJournal &&
    !isNil(overwrittenData?.WorkflowHeader?.WorkflowDraft?.WD_Officer_User_Id)
  ) {
    overwrittenData.WorkflowDetail.CreateJournal.Officer_Id =
      overwrittenData.WorkflowHeader.WorkflowDraft.WD_Officer_User_Id;
  }
  return overwrittenData;
}

type DTO_Workflow_JournalMatter<W> = {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: W;
};

interface OptionalCreateJournal {
  CreateJournal?: ModifiedCreateJournal;
}
interface ModifiedCreateJournal extends Omit<DTO_Journal, "Officer_Id"> {
  Officer_Id?: number;
}
