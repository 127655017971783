import { history } from "@/AppRoutes";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { Keyword } from "@common/models/keyword";
import { TreePacket } from "@common/models/treePacket";
import {
  getKeywordById,
  getKeywordDataByType,
  getKeywordTypeList,
  postKeyword,
} from "@common/pages/settings/lookups/keywords/_id/api";
import { SETTINGS_LOOKUPS_KEYWORDS_ROUTE } from "@common/pages/settings/lookups/keywords/_id/constant";
import {
  IKeywordsDataByType,
  ILookupKeywordsNotification,
  LookupKeywordSubmitActions,
} from "@common/pages/settings/lookups/keywords/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class KeywordsStore {
  private _keyword?: Keyword = undefined;
  private _keywordType?: TreePacket[] = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _isInactive: boolean = false;
  private _keywordsNotification?: ILookupKeywordsNotification = undefined;
  private _keywordDataByType?: IKeywordsDataByType = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get keywordsNotification() {
    return this._keywordsNotification;
  }
  setKeywordsNotification = (
    keywordsNotification: ILookupKeywordsNotification
  ) => {
    runInAction(() => {
      this._keywordsNotification = keywordsNotification;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get keyword() {
    return toJS(this._keyword);
  }
  setKeyword = (keyword?: Keyword) => {
    runInAction(() => {
      this._keyword = keyword;
    });
  };

  get keywordType() {
    return toJS(this._keywordType);
  }
  setKeywordType = (keywordType?: TreePacket[]) => {
    runInAction(() => {
      this._keywordType = keywordType;
    });
  };

  get keywordDataByType() {
    return toJS(this._keywordDataByType);
  }
  setKeywordDataByType = (keywordDataByType?: IKeywordsDataByType) => {
    runInAction(() => {
      this._keywordDataByType = keywordDataByType;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._keyword = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isInactive = false;
      this._keywordsNotification = undefined;
      this._onSubmit = undefined;
    });
  };

  get keywordId() {
    return toJS(this.keyword?.Keyword_ID);
  }

  reloadKeywords = async (): Promise<boolean> => {
    if (this.keywordId) {
      return await this.loadKeyword(this.keywordId);
    }
    return false;
  };

  loadKeywordDataByType = async (
    keywordType?: KEYWORD_TYPE
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    if (!keywordType) {
      this.setKeywordDataByType();
    } else {
      let newKeywordData = undefined;
      const keywordDataRes = await getKeywordDataByType(keywordType);
      if (isSuccessResponse(keywordDataRes) && keywordDataRes.data) {
        newKeywordData = keywordDataRes.data;
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setKeywordDataByType(newKeywordData);
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  loadKeyword = async (
    keywordID: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const resKeywordTypeList = await getKeywordTypeList();
    if (isSuccessResponse(resKeywordTypeList)) {
      if (resKeywordTypeList?.data) {
        this.setKeywordType(resKeywordTypeList?.data);
        this.loadKeywordDataByType(
          this._keyword?.KeywordType_ENUM as KEYWORD_TYPE
        );
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: resKeywordTypeList?.error ?? "Keyword type load failed",
        type: "warning",
      });
    }

    if (isNew) {
      this.setKeyword({
        KeywordType_ENUM: KEYWORD_TYPE.SystemInitialise,
      } as Keyword);
    } else {
      let newKeyword = undefined;
      this.setIsLoading(true);
      const response = await getKeywordById(keywordID);
      if (isSuccessResponse(response)) {
        newKeyword = response.data as Keyword;

        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setKeyword(newKeyword);
      if (this.keywordsNotification?.notification) {
        this.keywordsNotification?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setKeywordsNotification({
          ...this._keywordsNotification,
          notification: [],
        } as ILookupKeywordsNotification);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveKeyword = async (
    keywordInfo: Keyword,
    action: LookupKeywordSubmitActions
  ) => {
    this.setIsLoading(true);
    if (isNil(keywordInfo.Description)) keywordInfo.Description = "";
    const response = await postKeyword(keywordInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.keywordId) await this.loadKeyword(this.keywordId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save keyword failed",
        type: "error",
        description: response.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (action: LookupKeywordSubmitActions, response?: APIResponse) => {
    switch (action) {
      case LookupKeywordSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Keyword saved successfully",
          type: "success",
        });
        break;
      case LookupKeywordSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(
          `${SETTINGS_LOOKUPS_KEYWORDS_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              { title: "Keyword saved successfully", type: "success" },
            ],
          }
        );
        break;
      default:
        break;
    }
  };
}

export const keywordsStore = new KeywordsStore();
const KeywordsStoreContext = createContext(keywordsStore);
export const useKeywordsStore = () => {
  return useContext(KeywordsStoreContext);
};
