import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";
export const colOutbox: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.SpearReference,
    title: "SPEAR Reference",
    width: 750,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.CouncilReferenceNumber,
    title: "Council Reference Number",
    width: 750,
  },
  {
    field: TownPlanningFieldMapping.IsUpdated,
    title: "Is Updated",
    width: 750,
  },
];
