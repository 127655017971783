import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_DebtRecovery_Exemption } from "@app/products/property/assessments/debt-recovery/exemptions/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_DebtRecovery_Exemption>();
export const colDebtRecoveryExemption: IColumnFields[] = [
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: VO_DebtRecovery_Exemption) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
  },
  {
    field: nameOf("NA_Name"),
    title: "Name",
  },
  {
    field: nameOf("DRET_Name"),
    title: "Type",
  },
  {
    field: nameOf("DRE_Reason"),
    title: "Reason",
  },
  {
    field: nameOf("DRE_Active_From_Date"),
    title: "Date From",
    format: DATE_FORMAT.DATE_CONTROL,
  },
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment (10.3)",
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment (S-S-B-U)",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Assessment Property Address",
  },
  {
    field: nameOf("Assess_Legal_DescriptionX"),
    title: "Assessment Legal Description",
  },
  {
    field: nameOf("Debt_Recovery_Exemption_Id"),
    title: "Debt Recovery Exemption ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
