import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCNote } from "@components/cc-note/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { addDays, subDays } from "date-fns";
import React from "react";

export const RaiseDeferredDutyInterestDetailsFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  options = { isReadOnly: false },
}: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        {valueGetter(nameOf("DDAccountId")) ? (
          <div className="cc-field">
            <label className="cc-label">Deferred duty ID</label>
            <Field
              name={nameOf("DDAccountId")}
              component={CCNumericTextBox}
              spinners={false}
              format={NUMBER_FORMAT.NUMBER2}
              disabled
            />
          </div>
        ) : null}
        <div className="cc-field">
          <label className="cc-label">
            Previous interest to
            <CCTooltip
              type="info"
              position="right"
              content="Previous Interest To must be less than Calculate Interest To"
            />
          </label>
          <Field
            name={nameOf("PreviousInterestTo")}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            max={
              valueGetter(nameOf("CalculateInterestTo"))
                ? subDays(
                    new Date(valueGetter(nameOf("CalculateInterestTo"))),
                    1
                  )
                : undefined
            }
            disabled={options?.isReadOnly}
            disabledDateInput
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Calculate interest to
            <CCTooltip
              type="info"
              position="right"
              content="Calculate interest to must be greater than Previous Interest To"
            />
          </label>
          <Field
            name={nameOf("CalculateInterestTo")}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            min={
              valueGetter(nameOf("PreviousInterestTo"))
                ? addDays(valueGetter(nameOf("PreviousInterestTo")), 1)
                : undefined
            }
            disabled={options?.isReadOnly}
            disabledDateInput
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Market interest rate</label>
          <Field
            name={nameOf("MarketInterestRate")}
            min={0}
            max={100}
            step={0.1}
            component={CCPercentInput}
            disabled={options?.isReadOnly}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Premium interest rate</label>
          <Field
            name={nameOf("PremiumInterestRate")}
            min={0}
            max={100}
            step={0.1}
            component={CCPercentInput}
            disabled={options?.isReadOnly}
          />
        </div>
      </div>
      <br />
      <CCNote message="Raising Interest will create a journal with the interest for each Deferred Duty. If no Deferred Duty ID is shown it will run on all Deferred Duty records" />
    </section>
  );
};
