import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getPublicHolidayById } from "@common/pages/settings/lookups/public-holidays/_id/api";
import {
  LookupDatesDTO,
  SettingState,
} from "@common/pages/settings/lookups/public-holidays/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
export interface PublicHolidayTabDetailsProps {
  selectedRows?: any[];
}

export const PublicHolidayTabDetails = observer(
  ({ selectedRows }: PublicHolidayTabDetailsProps) => {
    const { lastSelectedId } = useCCProductListViewStore();
    const { settings } = useCommonCoreStore();
    const settingState: SettingState = useMemo(() => {
      return {
        isHaveACT: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_ACTHOLIDAYS]
        ),
        isHaveNSW: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_NSWHOLIDAYS]
        ),
        isHaveVIC: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_VICHOLIDAYS]
        ),
        isHaveSA: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_SAHOLIDAYS]
        ),
        isHaveTAS: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_TASHOLIDAYS]
        ),
        isHaveWA: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_WAHOLIDAYS]
        ),
        isHaveNT: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_NTHOLIDAYS]
        ),
        isHaveQLD: getBoolValueSetting(
          settings[ECorporateSettingsField.CorporateSettings_QLDHOLIDAYS]
        ),
      };
    }, [settings]);
    const [isLoading, setIsLoading] = useState(false);
    const [publicHoliDayTabDetailsInfo, setPublicHolidayTabDetailsInfo] =
      useState<LookupDatesDTO>();
    const [responseLoadError, setResponseLoadError] =
      useState<APIResponseError>();
    const publicHolidayID = lastSelectedId ?? selectedRows?.[0]?.ID;

    const loadDetailData = async () => {
      setIsLoading(true);
      setResponseLoadError(undefined);
      const response = await getPublicHolidayById(parseInt(publicHolidayID));
      if (isSuccessResponse(response)) {
        setPublicHolidayTabDetailsInfo(response.data);
      } else {
        setResponseLoadError({
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        });
      }
      setIsLoading(false);
    };

    useEffect(() => {
      if (!publicHolidayID) return;
      loadDetailData();
      // eslint-disable-next-line
    }, [publicHolidayID]);

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDetailData()}
        />
      );
    if (!publicHoliDayTabDetailsInfo) return <NoData />;

    return (
      <ProductReferenceBody>
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Date:"}
            value={formatDisplayValue(
              publicHoliDayTabDetailsInfo?.TheDate,
              DATE_FORMAT.DATE
            )}
          />
          <ProductReferenceRow
            title={"Day:"}
            value={publicHoliDayTabDetailsInfo?.DayOfWeek_ENUM}
          />
          {publicHoliDayTabDetailsInfo.ACT_Description &&
            settingState.isHaveACT && (
              <ProductReferenceRow
                title={"ACT holiday:"}
                value={publicHoliDayTabDetailsInfo?.ACT_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.NSW_Description &&
            settingState.isHaveNSW && (
              <ProductReferenceRow
                title={"NSW holiday:"}
                value={publicHoliDayTabDetailsInfo?.NSW_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.NT_Description &&
            settingState.isHaveNT && (
              <ProductReferenceRow
                title={"NT holiday:"}
                value={publicHoliDayTabDetailsInfo?.NT_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.QLD_Description &&
            settingState.isHaveQLD && (
              <ProductReferenceRow
                title={"QLD holiday:"}
                value={publicHoliDayTabDetailsInfo?.QLD_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.SA_Description &&
            settingState.isHaveSA && (
              <ProductReferenceRow
                title={"SA holiday:"}
                value={publicHoliDayTabDetailsInfo?.SA_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.TAS_Description &&
            settingState.isHaveTAS && (
              <ProductReferenceRow
                title={"TAS holiday:"}
                value={publicHoliDayTabDetailsInfo?.TAS_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.VIC_Description &&
            settingState.isHaveVIC && (
              <ProductReferenceRow
                title={"VIC holiday:"}
                value={publicHoliDayTabDetailsInfo?.VIC_Description}
              />
            )}
          {publicHoliDayTabDetailsInfo.WA_Description &&
            settingState.isHaveWA && (
              <ProductReferenceRow
                title={"WA holiday:"}
                value={publicHoliDayTabDetailsInfo?.WA_Description}
              />
            )}
        </ProductReferenceBlock>
      </ProductReferenceBody>
    );
  }
);
