import { CRMSSvcStandardUpdateFormElement } from "@app/products/crms/service-standards/batch-update/components/form/components/general/components/form-element/_index";
import {
  ServiceStandardBatchUpdateMapObj,
  StateSvcStandardUpdate,
} from "@app/products/crms/service-standards/batch-update/model";
import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

export const CRMSSvcStandardBatchUpdateForm = observer(() => {
  const { svcStandardBatchUpdate, setOnSubmit, saveSvcStandardbatchUpdate } =
    useSvcStandardBatchUpdateStore();

  const location = useLocation();
  const locationState = location?.state as StateSvcStandardUpdate;
  const gridSelectedIds: number[] = locationState?.gridSelectedIds;

  const submitRef = useRef<(event: SyntheticEvent<any>) => void | undefined>();

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const { isValid, values } = event;
    if (
      !isValid ||
      !gridSelectedIds ||
      (gridSelectedIds && gridSelectedIds.length < 1)
    )
      return;

    const { SvcStandardBatchUpdate: data } =
      values as ServiceStandardBatchUpdateMapObj;
    saveSvcStandardbatchUpdate(data);
  };

  const initialValues: ServiceStandardBatchUpdateMapObj = useMemo(() => {
    return {
      SvcStandardBatchUpdate: {
        ...svcStandardBatchUpdate,
        SelectedRows: gridSelectedIds,
        MobileIconForm: 0,
        CaseDetailsForm: 0,
      },
    } as ServiceStandardBatchUpdateMapObj;
  }, [svcStandardBatchUpdate, gridSelectedIds]);

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CRMSSvcStandardUpdateFormElement
              formRenderProps={formRenderProps}
            />
          );
        }}
      />
    </div>
  );
});
