import {
  Premises,
  Premises_Status,
} from "@app/products/hm/premises/[id]/model";

export const generateBadge = (premiseData: Premises) => {
  const listBadge = [];
  if (premiseData?.Status_ENUM) {
    listBadge.push(Premises_Status[premiseData.Status_ENUM]);
  }
  if (premiseData?.Alert) {
    listBadge.push("Alert");
  }
  return listBadge;
};
