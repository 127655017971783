import { getPPRSummaryById } from "@app/products/town-planning/ppr/[id]/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PPRViewOnMapTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string | undefined>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const pprId: number = lastSelectedRow?.ID ?? +params.id;

  useEffect(() => {
    setIsLoading(true);
    getPPRSummaryById(pprId).then((response) => {
      if (isSuccessResponse(response)) {
        setAddress(response.data?.SiteAddress?.Formatted_SingleLine);
      } else {
        setAddress(undefined);
      }
      setIsLoading(false);
    });
  }, [pprId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!address) return <NoData />;
  return <MapSTripTab address={address} />;
});
