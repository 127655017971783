import {
  apiCoreAlert,
  apiCoreGetAlert,
  apiCoreGetAlertLovs,
} from "@app/core/components/common/utils";
import { IAlert } from "@app/core/contacts/components/accordions/alerts/model";
import {
  IAlertLovs,
  IAlerts,
} from "@app/core/contacts/components/dialogs/alert/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";

export const postAlert = async (
  id: number,
  recordType: RECORDTYPE,
  alert: IAlerts
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreAlert(id, recordType),
      alert
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAlert = async (
  id: number
): Promise<APIResponse<IAlert | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IAlert>(apiCoreGetAlert(id));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getAlertLovs = async (): Promise<
  APIResponse<IAlertLovs[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<IAlertLovs[]>(
      apiCoreGetAlertLovs()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
