import { CCValueField } from "@components/cc-value-field/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export const TabSummary = ({
  formRenderProps,
}: {
  formRenderProps: FormRenderProps;
}) => {
  const { valueGetter } = formRenderProps;
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <CCValueField
            label="PID number"
            value={valueGetter("PropertyAssessment.PID_No")}
          />
          <CCValueField
            label="Assessment ID"
            value={valueGetter("PropertyAssessment.Assessment_ID")}
          />
          <CCValueField
            label="RIN"
            value={valueGetter("PropertyAssessment.RIN")}
          />
          <CCValueField
            label="Ward"
            value={valueGetter("PropertyAssessment.Ward")}
          />
          <CCValueField
            label="Address"
            value={valueGetter("FullAddress_SingleLine")}
          />
          <CCValueField
            label="Legal description"
            value={valueGetter("PropertyAssessment.Legal_Description")}
          />
          <CCValueField
            label="Zones"
            value={valueGetter("PropertyAssessment.Zones")}
          />
        </div>
      </div>
    </div>
  );
};
