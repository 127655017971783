import { ILovContact } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewPICContactStepStore {
  private _lov?: ILovContact = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get lovContact() {
    return toJS(this._lov);
  }
  setLovContact = (lov: ILovContact) => {
    runInAction(() => {
      this._lov = lov;
    });
  };
}

const newPICContactStepStoreContext = createContext(
  new NewPICContactStepStore()
);
export const useNewPICContactStepStoreContext = () => {
  return useContext(newPICContactStepStoreContext);
};
