import { ListConditionReferenceDialog } from "@app/core/condition/components/dialogs/list-condition-references/_index";
import { Svc_ConditionReference } from "@app/core/condition/components/dialogs/list-condition-references/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface IAddConditionButtonProps {
  onSubmit: (conditions: Svc_ConditionReference[]) => void;
}

export const AddConditionButton = ({ onSubmit }: IAddConditionButtonProps) => {
  const [isShowConditions, setIsShowConditions] = useState(false);
  const handleSubmit = (conditions: Svc_ConditionReference[]) => {
    if (typeof onSubmit === "function") {
      onSubmit(conditions);
      setIsShowConditions(false);
    }
  };

  return (
    <>
      {isShowConditions && (
        <ListConditionReferenceDialog
          productType={PRODUCT_TYPE_NUMBER.LLPermits}
          onClosePopup={() => {
            setIsShowConditions(false);
          }}
          onSubmit={handleSubmit}
        />
      )}
      <Button
        type="button"
        iconClass="fas fa-plus"
        title="Add conditions"
        onClick={() => {
          setIsShowConditions(true);
        }}
      />
    </>
  );
};
