import { assessmentColList } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/config";
import { colList as titleColList } from "@app/products/property/titles/list/config";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { FilterChangeEvent } from "@progress/kendo-react-data-tools";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useMemo } from "react";
import { CRITERIA_FORM_STEP } from "../criteria/_index";
import { SearchDetailFilter } from "./components/search-detail-filter/_index";

export const SEARCH_DETAILS_FORM_STEP = "SearchDetails";

export const SearchDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={SearchDetailsStepElement}
    />
  );
};

export const SearchDetailsStepElement = ({
  formRenderProps,
  nameOf,
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const criteriaFilter = valueGetter(`${CRITERIA_FORM_STEP}.Criteria`);
  const filterData = valueGetter(nameOf("Filter"));

  const getlistPanelBar = useMemo(() => {
    return criteriaFilter.map((criteria: any) => {
      if (criteria.Key === "Assessment" || criteria.Key === "Title") {
        return {
          title: criteria.Criteria,
          component: (
            <SearchDetailFilter
              initialFilter={filterData[criteria.Key]}
              columnFields={
                criteria.Key === "Assessment" ? assessmentColList : titleColList
              }
              onFilterDataChange={(filter: FilterChangeEvent) => {
                onChange(nameOf(`Filter`), {
                  value: { ...filterData, [criteria.Key]: filter },
                });
              }}
            />
          ),
        };
      }
      return { title: criteria.Criteria, component: <></> };
    });
  }, [criteriaFilter, filterData, nameOf, onChange]);
  return (
    <div>
      <CustomPanelBar listPanelBar={getlistPanelBar} expandMode="multiple" />
    </div>
  );
};
