import { ServiceStandard_LightWeight2 } from "@app/products/crms/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export interface EventBatchUpdate {
  SelectedRows: number[];
  Update_Coordinator: boolean;
  Coordinator_SiteUser_ID: number | null;
  Coordinator_DisplayName: string;
  Update_ActionOfficer: boolean;
  ActionOfficer_SiteUser_ID: number | null;
  ActionOfficer_DisplayName: string;
  Update_ServiceStandard: boolean;
  ServiceStandard_ID: number | null;
  ServiceStandard_Description: string;
  ServiceStandard: ServiceStandard_LightWeight2;
  Update_Type: boolean;
  Type_ID: number | null;
  Type_Display: string;
  Update_Source: boolean;
  Source_ID: number | null;
  Source_Display: string;
  Update_Comment: boolean;
  CommentTitle: string;
  CommentDescription: string;
}
export interface EventBatchUpdateLOV {
  Type: IKeyValuePacket[];
  Source: IKeyValuePacket[];
}

export interface EventBatchUpdateRequest {
  EventBatchUpdateActions: Svc_FormAction_EventBatchUpdate;
  EventBatchUpdate: EventBatchUpdate;
  EventArgs: any;
}

export enum Svc_FormAction_EventBatchUpdate {
  SystemInitialise,
  Coordinator,
  ActionOfficer,
  ServiceStandard,
}

export interface BatchUpdateEventLocationState {
  gridSelectedIds: number[];
}
