import { colSchemesAccountInstalments } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/instalments/config";
import { mockSchemesAccountInstalments } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/instalments/mock";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCGrid } from "@components/cc-grid/_index";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const SchemesAccountInstalments = () => {
  return (
    <div className="cc-schemes-account-instalments ">
      <div className="cc-form">
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field ">
              <label className="cc-label">Next instalment date</label>
              <DatePicker
                defaultValue={new Date("12/28/2021")}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={true}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Final instalment due by</label>
              <DatePicker
                defaultValue={new Date("2/29/2024")}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={true}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Total number of instalments</label>
              <NumericTextBox
                className="cc-form-control"
                value={28}
                disabled={true}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Remaining instalments</label>
              <NumericTextBox
                className="cc-form-control"
                value={28}
                disabled={true}
              />
            </div>
          </div>

          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                className="cc-instalments-grid-data"
                data={mockSchemesAccountInstalments || []}
                columnFields={colSchemesAccountInstalments}
                primaryField="Id"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
