import { postRegenerateSession } from "@app/products/property/journals/session-list/[id]/components/forms/existed/components/action-bar/buttons/regenerate-session/api";
import { isSuccessResponse } from "@common/apis/util";
import { FINANCESYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const RegenerateSessionButton = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const { settings } = useCommonCoreStore();

  const financeSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FinanceSystem]
  );
  const isSAPSystem = useMemo(() => {
    return financeSystem === FINANCESYSTEM.SAP;
  }, [financeSystem]);

  const handleSubmit = async () => {
    setIsLoadingDialog(true);
    const successMessage = "Regenerate session was successful";
    const errorMessage = "Regenerate session failed";
    const response = await postRegenerateSession();
    if (isSuccessResponse(response)) {
      pushNotification({
        title: successMessage,
        type: "success",
      });
    } else {
      pushNotification({
        title: errorMessage,
        type: "error",
        autoClose: false,
      });
    }
    setIsLoadingDialog(false);
    setIsShowDialog(false);
  };

  return (
    <>
      <CCNavButton
        title="Regenerate session"
        onClick={() => {
          setIsShowDialog(true);
        }}
        invisible={isSAPSystem}
      />
      {isShowDialog && (
        <ConfirmDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          title="Regenerate Session"
          subMessage="Are you sure you wish to regenerate the session?"
          btnNoTitle="Cancel"
          onAsyncConfirm={() => {
            handleSubmit();
          }}
          isLoadingYes={isLoadingDialog}
        />
      )}
    </>
  );
});
