import { DATE_FORMAT } from "@common/constants/common-format";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import React from "react";
interface IProps {
  name: string;
  value: Date;
  label: string;
  isMandatory?: boolean;
  disabled?: boolean;
  description?: string;
  onChange?: (arg0: DatePickerChangeEvent) => void;
}

export const AmsDateInput: React.FC<IProps> = ({
  name,
  label,
  value = null,
  isMandatory,
  disabled,
  onChange,
  description = "",
  ...others
}) => {
  return (
    <div className="cc-ams-date-input">
      <div className="cc-label">
        <DescriptionLabel title={label} description={description} />
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </div>
      <LocalizationProvider language="en-AU">
        <IntlProvider locale="en">
          <DatePicker
            name={name}
            format={DATE_FORMAT.DATE_CONTROL}
            value={value}
            disabled={disabled ?? false}
            onChange={onChange}
            {...others}
            className="cc-form-control"
          />
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
};
