import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colRegisters } from "./config";
import { mockRegisters } from "./mock";

const Registers = () => {
  return (
    <div className="cc-property-registers">
      <CCGrid
        className="cc-property-registers-grid"
        data={mockRegisters}
        columnFields={colRegisters}
        primaryField="Id"
      />
    </div>
  );
};

export default Registers;
