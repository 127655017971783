import { loadFinancialSummaryById } from "@app/products/property/assessments/financial-summaries/[id]/api";
import { IGridSummary } from "@app/products/property/assessments/financial-summaries/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class FinancialSummaryStore {
  private _financialSummary?: IGridSummary[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get financialSummary() {
    return this._financialSummary;
  }
  setFinancialSummary = (financialSummary?: IGridSummary[]) => {
    runInAction(() => {
      this._financialSummary = financialSummary;
    });
  };
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get notifications() {
    return this._notifications;
  }
  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._financialSummary = undefined;
      this._isLoading = false;
      this._notifications = [];
      this._responseLoadError = undefined;
    });
  };

  loadFinancialSummary = async (financialSummaryId: string) => {
    this.setIsLoading(true);
    const response = await loadFinancialSummaryById(financialSummaryId);
    let errorResponse = undefined;
    let newFinancialSummary = response;
    this.setResponseLoadError(errorResponse);
    this.setFinancialSummary(newFinancialSummary);
    this.setIsLoading(false);
  };
}

const financialSummaryStoreContext = createContext(new FinancialSummaryStore());
export const useFinancialSummaryStore = () => {
  return useContext(financialSummaryStoreContext);
};
