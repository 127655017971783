import { IBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/model";

export const mockSingleBuildingPoolsSpas: IBuildingPoolsSpas = {
  ID: 1,
  RegisterNumber: "8778",
  Contact: "Daniel M Green",
  Contact_Address: "4245  Paul Wayne Haggerty Road, Youngstown, Ohio",
  Contact_Contact: "danielgreen@hotmail.net",
  Description:
    "Find the latest breaking news and information on the top stories, weather, business, entertainment, politics, and more. For in-depth coverage",
  Address: "4166  Stoney Lonesome Road, Salt Lake City, Utah",
  Owner: "James K Baker",
  Details: "robert.hardwell@gmail.com",
  Type: "Arizona",
  Status: "Entered",
  Officer: "Andrew Thoma",
  RefNo: "66322",
  Lodged: new Date("Thur Feb 10 2019 14:09:46 GMT+0700 (Indochina Time)"),
  Complete: new Date("Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"),
  FileNumber: "623",
  BarrierTypes: "Stack",
  BarrierTypesDesc:
    "I think that I am a responsible and honest boy/girl who wants to do things successfully",
  Regulations: "Council",
  RegulationsDesc: "I think that I am a responsible and honest sonmething",
  RegisteredDate: new Date(
    "Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"
  ),
  LodgementCertificateBarrierNC: new Date(
    "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"
  ),
  WorksIssued: new Date("Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"),
  ComplianceRequired: new Date(
    "Thur Jan 05 2020 14:09:46 GMT+0700 (Indochina Time)"
  ),
  BuildingPermitNo: "7883322",
  AppNo: "15222",
  EnforcementNo: "161626",
  Due: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
  OutstandingFees: 1565,
  DebtorNumber: 16622,
  StatDays: 152,
  CreatedDate: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
  LodgedDate: new Date("Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"),
  ClockStart: new Date("Mon June 12 2020 14:09:46 GMT+0700 (Indochina Time)"),
  LastInspection: new Date(
    "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"
  ),
  LastInspectionCompliance: new Date(
    "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"
  ),
  LastInspectionDate: new Date(
    "Mon May 20 2020 14:09:46 GMT+0700 (Indochina Time)"
  ),
};
