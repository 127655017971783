import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { WorkOrderSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IWorkOrderIntegrationSettingsProps {
  formRenderProps: FormRenderProps;
}

const WorkOrderIntegrationSettings = ({
  formRenderProps,
}: IWorkOrderIntegrationSettingsProps) => {
  const { valueGetter } = formRenderProps;

  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "CorporateSettings_WorkOrderSystem",
    "CorporateSettings_WorkOrderSystem_Label",
    "CorporateSettings_WorkOrderReferenceURL",
    "CorporateSettings_WorkOrderUsername",
    "CorporateSettings_WorkOrderPassword",
    "CorporateSettings_WorkOrderAPIKey",
    "CorporateSettings_WorkOrderFileExtensions",
  ]);

  const workOrderSystem = +valueGetter(
    data.CorporateSettings_WorkOrderSystem?.FieldName as string
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={data.CorporateSettings_WorkOrderSystem}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_WorkOrderSystem_Label}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={data.CorporateSettings_WorkOrderReferenceURL}
          isEditing={isEditing}
        />
      </div>

      {workOrderSystem === WorkOrderSystem.CAMSS ? (
        <InputGlobalSettings
          data={data.CorporateSettings_WorkOrderAPIKey}
          isEditing={isEditing}
        />
      ) : (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={data.CorporateSettings_WorkOrderUsername}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={data.CorporateSettings_WorkOrderPassword}
            isEditing={isEditing}
          />
        </div>
      )}

      <InputGlobalSettings
        data={data.CorporateSettings_WorkOrderFileExtensions}
        isEditing={isEditing}
      />
    </section>
  );
};

export default observer(WorkOrderIntegrationSettings);
