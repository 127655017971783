import { DTO_Assessment } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { DTO_Journal } from "@app/products/property/journals/model";
import {
  DTO_Transaction,
  DTO_WorkflowHeader,
} from "@app/products/property/model";

export interface RequestTransferAssessmentTransactionObj {
  TransactionIds: number[];
}

export interface DTO_Workflow_JournalAssessmentTransferTransaction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalAssessmentTransferTransaction;
}

export interface DTO_WorkflowDetail_JournalAssessmentTransferTransaction {
  Journal_Number: number | null;
  JournalMode: number;
  CreateJournal: DTO_Journal;
  Transactions: DTO_Transaction[];
  Assessments: DTO_Assessment[];
  AssessmentTo: DTO_Assessment_To;
}
export interface DTO_Assessment_To {
  AssessmentId: number;
  LevyId: number | null;
  RatingPeriodId: number | null;
  TransactionDate: Date | null;
  ChargeDate: Date | null;
  IsOriginalTransactionDate: boolean;
}

export enum TransferTransactionKeysOfSteps {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  AssessmentTo = "AssessmentTo",
  Transactions = "Transactions",
  Comments = "Comments",
  Documents = "Documents",
}

export const transferTransactionKeysOfSendSteps = [
  TransferTransactionKeysOfSteps.NewJournal,
  TransferTransactionKeysOfSteps.AssessmentTo,
  TransferTransactionKeysOfSteps.Transactions,
];
