import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(() => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const fieldObjectiveDocumentChunkSize = getConfigDataField(
    ECorporateSettingsField.Objective_DocumentChunkSize
  );
  const fieldObjectiveNonChunkedWebService = getConfigDataField(
    ECorporateSettingsField.Objective_CreateDocument_WebService
  );
  const fieldObjectiveChunkedWebService = getConfigDataField(
    ECorporateSettingsField.Objective_CreateDocument_ChunkedStream_WebService
  );

  return (
    <>
      <section className="cc-field-group">
        {fieldObjectiveDocumentChunkSize && (
          <div className="cc-form-cols-1">
            <NumericTextboxGlobalSettings
              data={fieldObjectiveDocumentChunkSize}
              isEditing={isEditing}
              min={0}
            />
          </div>
        )}

        {fieldObjectiveNonChunkedWebService && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveNonChunkedWebService}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldObjectiveChunkedWebService && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldObjectiveChunkedWebService}
              isEditing={isEditing}
            />
          </div>
        )}
      </section>
    </>
  );
});
