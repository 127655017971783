import { VO_Supplementary_MasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SupplementaryRatesMasterPropertyStore {
  private _selectedMasterProperties: VO_Supplementary_MasterProperty[] = [];
  private _selectedMasterPropertyId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedMasterPropertyId() {
    return this._selectedMasterPropertyId;
  }

  setSelectedMasterPropertyId = (masterPropertyId: number) => {
    runInAction(() => {
      this._selectedMasterPropertyId = masterPropertyId;
    });
  };

  get selectedMasterProperties() {
    return toJS(this._selectedMasterProperties);
  }
  setSelectedMasterProperties = (
    selectedMasterProperties: VO_Supplementary_MasterProperty[]
  ) => {
    runInAction(() => {
      this._selectedMasterProperties = selectedMasterProperties;
      this.setSelectedMasterPropertyId(
        selectedMasterProperties[0]?.Master_Property_Id
      );
    });
  };

  //Action
  resetSupplementaryRatesMasterPropertyStore = () => {
    runInAction(() => {
      this._selectedMasterProperties = [];
    });
  };
}

const supplementaryRatesMasterPropertyStoreContext = createContext(
  new SupplementaryRatesMasterPropertyStore()
);
export const useSupplementaryRatesMasterPropertyStore = () => {
  return useContext(supplementaryRatesMasterPropertyStoreContext);
};
