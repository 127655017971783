import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedAppealHearing } from "@common/pages/appeal/_id/appeal-hearing/_id/components/forms/existed/_index";
import { NewAppealHearing } from "@common/pages/appeal/_id/appeal-hearing/_id/components/forms/new/_index";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageAppeal = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadAppealHearing, resetStore } = useAppealHearingStore();

  useEffect(() => {
    if (isNew) return;
    loadAppealHearing(+id);
    return () => {
      resetStore();
    };
  }, [isNew, loadAppealHearing, resetStore, id]);

  if (isNew) {
    return <NewAppealHearing />;
  }
  return <ExistedAppealHearing />;
};

export default observer(ManageAppeal);
