import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const dataLoggingModel: IKeyValuePacket[] = [
  {
    Key: 0,
    Value: "Text File Logging",
  },
  {
    Key: 1,
    Value: "Serilog",
  },
];
