import { CentreLinkCardsRequiringActions } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/centrelink-cards-requiring-actions/_index";
import { CentrelinkConcessionCards } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/centrelink-concession-cards/_index";
import { CentreLinkResponseBatchDetailsForm } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/details/_index";
import { ExternalConcessionCardsRequiringActions } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/external-concession-cards-requiring-actions/_index";
import { ExternalConcessionCards } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/external-concession-cards/_index";
import { Rejections } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/rejections/_index";
import { CentreLinkResponseBatchDetailTab } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/reference-sidebar/detail/_index";
import { useCentreLinkResponseBatchStore } from "@app/products/property/centrelink-customer-confirmation/view-responses/store";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { eAccordion } from "@app/products/property/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

interface IExistedCentreLinkResponseBatchProps {
  showSlideBar?: boolean;
}

const ExistedCentreLinkResponseBatch = observer(
  ({ showSlideBar = false }: IExistedCentreLinkResponseBatchProps) => {
    const {
      loadResponseBatchDetails,
      responseBatchDetails,
      isLoading,
      responseLoadError,
    } = useCentreLinkResponseBatchStore();

    const listPanelBar: IPropertyPanelBar[] = [
      {
        accordionId: eAccordion.Centrelink_Response_Centrelink_Concession_Cards,
        title: "CentreLink Concession Cards",
        component: <CentrelinkConcessionCards />,
      },
      {
        accordionId:
          eAccordion.Centrelink_Response_Centrelink_Cards_Requiring_Actions,
        title: "CentreLink Cards Requiring Actions",
        component: <CentreLinkCardsRequiringActions />,
      },
      {
        accordionId: eAccordion.Centrelink_Response_External_Concession_Cards,
        title: "External Concession Cards",
        component: <ExternalConcessionCards />,
      },
      {
        accordionId:
          eAccordion.Centrelink_Response_External_Concession_Cards_Requiring_Actions,
        title: "External Concession Cards Requiring Actions",
        component: <ExternalConcessionCardsRequiringActions />,
      },
      {
        accordionId: eAccordion.Centrelink_Response_Rejections,
        title: "Rejections",
        component: <Rejections />,
      },
    ];

    return (
      <>
        <br />
        <Loading isLoading={isLoading} isFullScreen />
        <FormNavigation title="CENTRELINK RESPONSE BATCH" />
        {responseLoadError ? (
          <CCLoadFailed
            responseError={responseLoadError}
            onReload={() => loadResponseBatchDetails()}
          />
        ) : (
          <>
            <FormTitle
              title={responseBatchDetails?.ResponseBatchId?.toString() ?? ""}
            />
            <div className="cc-manage-container">
              <div className="cc-form cc-manage-left">
                <CCAppNotification />
                <div className="cc-manage-form-body">
                  <CCGeneralPanel
                    title="Details"
                    isExpanded
                    isKeepMounted
                    component={<CentreLinkResponseBatchDetailsForm />}
                  />
                  <PropertyPanelBar initialPanels={listPanelBar} />
                </div>
              </div>
              {showSlideBar && (
                <div className="cc-manage-right">
                  <ReferenceSideBar
                    components={[
                      {
                        title: "Details",
                        component: <CentreLinkResponseBatchDetailTab />,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
);

export default ExistedCentreLinkResponseBatch;
