export enum SearchType {
  AssessmentNumber = "Assessment Number",
  AssessmentId = "Assessment Id",
}
export enum SearchField {
  AssessmentNumber = "_searchByAssessmentNumber",
  AssessmentId = "_searchByAssessmentId",
}

export const textFieldMapping: {
  [key: string]: string;
} = {
  [SearchType.AssessmentNumber]: SearchField.AssessmentNumber,
  [SearchType.AssessmentId]: SearchField.AssessmentId,
};

export interface IMeterScheduleSpecialReadings {
  AssessmentNumber: number;
  PropertyAddress: string;
  AccountName: string;
  AssessmentId: number;
  Meters: IMeterScheduleSpecialReadingsMeters[];
}

export interface IMeterScheduleSpecialReadingsMeters {
  Utility: string;
  MeterNumber: string;
  ScheduleRead: true;
  BillingGroup: string;
  MeterInstallationId: number;
}
