import { IAttributeData } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAttributeData>();
export const colAttributesUDA: IColumnFields[] = [
  {
    field: nameOf("Attribute"),
    title: "Attribute",
  },
  {
    field: nameOf("Value"),
    title: "Value",
  },
];
