import { VO_PIC } from "@app/products/property/pic/list/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: VO_PIC) => {
  return `Property - PIC ${
    selectedRow?.PIC_Id ? `- ${selectedRow.PIC_Id}` : ""
  }`;
};

export const getBookmarkDetail = (selectedRow: VO_PIC) => {
  return getDisplayTextWithDashes([
    selectedRow?.PIC_Number,
    selectedRow?.PropertyAddress,
    selectedRow?.PropertyLocality,
  ]);
};

export const getBookmarkListViewDetail = () => {
  return "Property - PIC - Active";
};
