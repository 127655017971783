export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Property - Debt Recovery ${dynamicDisplayName}`;
};

//@TODO Don't have CurrentRatepayer data in grid mock data, don't have DebtRecoveryId -> use ID instead it. Recheck when integrated API
export const getBookmarkDetail = (selectedRow: any) => {
  const dynamicDetail =
    (selectedRow.ID ? selectedRow.ID + " - " : "") +
    (selectedRow.CurrentRatepayer ?? "");
  return `${dynamicDetail.trim()}`;
};
