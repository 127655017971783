import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const crmsApplicationsRoute: ICCRoute = {
  path: "crms-applications",
  name: "Crms Applications",
  enum: LLPermitsMenu.CRMSPermits,
  children: [
    {
      path: "draft",
      name: "Draft",
      enum: LLPermitsMenu.CRMSDraftPermits,
      component: require("./draft/_index").default,
    },
  ],
};
