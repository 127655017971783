import { NewSearchButton } from "@app/products/property/advanced_search/components/action-bar/buttons/new-search/_index";
import { RefineButton } from "@app/products/property/advanced_search/components/action-bar/buttons/refine/_index";
import { advancedSearchRoute } from "@app/products/property/advanced_search/route";
import { ADVANCED_SEARCH_SEARCHES_ROUTE } from "@app/products/property/advanced_search/searches/constant";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/advanced_search/util";
import { SearchDetailTab } from "@app/products/property/advanced_search/[id]/components/reference-sidebar/detail/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { footprintsColList } from "./config";
import { mockSeacrchAdvandcedSearch } from "./mock";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={advancedSearchRoute.path} />,
    ],
    centerComponents: [<NewSearchButton />, <RefineButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ADVANCED_SEARCH_SEARCHES_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <SearchDetailTab />,
      },
    ],
  });
  return (
    <CCProductListView
      data={mockSeacrchAdvandcedSearch || []}
      columnFields={footprintsColList}
      primaryField={"Name"}
      state={{ group: [{ field: "Component", dir: "asc" }] }}
    />
  );
};
