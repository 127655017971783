import { SearchNameCombobox } from "@app/products/property/components/fields/search-name/_index";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import {
  handleSearchNameProcess,
  ISearchNameProcessParams,
} from "@app/products/property/components/fields/search-name/util";
import { SearchOptions } from "@app/products/property/components/fields/search-options/_index";
import { useContactRoleCellStore } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations-lls/components/contact-role-cell/store";
import { colNames } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations-lls/config";
import { handleValidator } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations-lls/util";
import {
  DTO_Role,
  DTO_Workflow_CreateContact,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { DTO_Entity_Associated_Entity } from "@app/products/property/contacts-central-names/list/model";
import { ResponseMessage } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { ContactLookahead_JSON } from "@common/models/contact";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import React, { useState } from "react";
import "./_index.scss";

const nameOfNames = nameOfFactory<DTO_Entity_Associated_Entity>();

export const AssociationsLLSFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? handleValidator : undefined}
    />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  localNotificationRef,
  options = {
    isReadOnly: false,
    contactLOVs: undefined,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const onChangeFieldValue = (name: string, value: any) =>
    onChange(nameOf(name), { value: value });
  const namesGridData: DTO_Entity_Associated_Entity[] =
    getFieldValue("Associated_Entities") ?? [];
  const selectedName = getFieldValue("_option.SelectedName");
  const [isGridLoading, setIsGridLoading] = useState<boolean>(false);
  const { setDropdownList } = useContactRoleCellStore();

  const handleLoadNameDetail = async (value: any) => {
    const newContact = {
      Entity_Name_Address_Id: value?.Entity_Name_Address_Id,
      Name: value?.Name ?? value?.DisplayName,
      Address: value?.Address ?? value?.ContactInfo,
      Entity_Id: +value?.Entity_Id,
      Id: getUUID(),
    } as DTO_Entity_Associated_Entity;
    let newNames: DTO_Entity_Associated_Entity[] = [];
    newNames = [...namesGridData, newContact];
    onChangeFieldValue("Associated_Entities", newNames);
  };

  const handleAddName = async (value: any) => {
    const dataProcess: ISearchNameProcessParams = {
      data: value,
      isCheckEntityId: !!value?.Entity_Name_Address_Id,
      //DTO data: ContactLookahead_JSON || DTO_Entity_Name_Address
      handleLoadNameDetail: async (data: any) => {
        await handleLoadNameDetail(data);
      },
      notificationAction: {
        canNotAddName: (response) => {
          localNotificationRef?.current?.pushNotification({
            title: response?.error ?? "Name could not be added",
            type: "error",
            autoClose: false,
          });
        },
      },
      setLoading: (isLoading: boolean) => {
        return setIsGridLoading(isLoading);
      },
    };

    await handleSearchNameProcess(dataProcess);
  };

  const handleGridSelectionChange = (
    field: string,
    dataItem: DTO_Entity_Associated_Entity[]
  ) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChangeFieldValue(field, newSelected);
  };

  const handleRemoveName = (name: DTO_Entity_Associated_Entity) => {
    if (name) {
      const newNames = namesGridData.filter(
        (item: DTO_Entity_Associated_Entity) => item.Id !== name.Id
      );
      onChangeFieldValue("Associated_Entities", newNames);
      handleGridSelectionChange("_option.SelectedName", []);
    }
  };

  const handleSubmitNewContactDialog = async (
    _payload: DTO_Workflow_CreateContact,
    responseMessage: ResponseMessage
  ) => {
    const entityId = responseMessage?.Component_ID;
    if (responseMessage?.IsSuccess && entityId) {
      setIsGridLoading(true);
      const nameDetailResponse = await loadNameDetail(entityId);
      setIsGridLoading(false);
      if (isSuccessResponse(nameDetailResponse)) {
        const nameDetail = nameDetailResponse?.data?.Entity_Name_Address;
        const newContact = {
          ...nameDetail,
          Entity_Id: entityId,
          Address: nameDetail.Full_Address,
        };
        handleAddName(newContact);
      } else {
        localNotificationRef?.current?.pushNotification({
          title: responseMessage?.ErrorMessage ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    } else {
      localNotificationRef?.current?.pushNotification({
        title: responseMessage?.ErrorMessage ?? "Name could not be added",
        type: "error",
        autoClose: false,
      });
    }
  };

  /**
   * Update role list based on selected name
   * @param selectedName
   * @logic do not allow to select the same role for the same entity, allow to reselect the role if the entity is removed
   * @returns list of available roles
   */
  const updateRoleListBasedOnSelectedName = (
    selectedName: DTO_Entity_Associated_Entity
  ) => {
    // Get all names with the same entity id
    const duplicateNames = namesGridData.filter(
      (entity: DTO_Entity_Associated_Entity) =>
        entity?.Id !== selectedName?.Id &&
        entity?.Entity_Id === selectedName?.Entity_Id
    );
    // Get all roles used by the same entity id
    const usedRoles = duplicateNames.map(
      (entity: DTO_Entity_Associated_Entity) => entity.Role_Name
    );

    // Update available roles
    const allRoles = options?.contactLOVs?.Roles ?? [];
    const availableRoles = allRoles?.filter(
      (role: DTO_Role) => !usedRoles.includes(role.Name)
    );
    setDropdownList(availableRoles);
  };

  return (
    <>
      <section className="cc-field-group cc-contact-associations-step">
        <div className="cc-form-cols-2">
          <SearchNameCombobox
            name={nameOf("SearchName")}
            onSelectionChange={(values: ContactLookahead_JSON) => {
              handleAddName(values);
            }}
            disabled={options?.isReadOnly}
            selectedSearchOptions={getFieldValue("SearchOptions")}
            onError={(error: any) => {
              localNotificationRef?.current?.pushNotification({
                type: "error",
                title: error ?? "Search name error",
                autoClose: false,
              });
            }}
            onSubmitNewContactDialog={handleSubmitNewContactDialog}
          />
          <SearchOptions
            formRenderProps={formRenderProps}
            nameOf={nameOf}
            isDisable={options?.isReadOnly}
          />
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel
              title="Names"
              informationTooltip="A contact must be assigned a role."
            />
            <CCGrid
              isLoading={isGridLoading}
              data={namesGridData}
              selectedRows={selectedName ? [selectedName] : undefined}
              columnFields={colNames}
              readOnly={options?.isReadOnly}
              selectableMode="single"
              onSelectionChange={(dataItem: DTO_Entity_Associated_Entity[]) => {
                updateRoleListBasedOnSelectedName(dataItem[0]);
                handleGridSelectionChange("_option.SelectedName", dataItem);
              }}
              onDataChange={(dataItem: any[]) => {
                onChangeFieldValue("Associated_Entities", dataItem);
              }}
              primaryField={nameOfNames("Id")}
              editableMode="cell"
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Entity from list"
                    disabled={!selectedName}
                    onClick={() => {
                      handleRemoveName(selectedName);
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>
    </>
  );
};
