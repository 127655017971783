import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmVerifyReadingsDialogProps {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export const ConfirmVerifyReadingsDialog = ({
  onClosePopup,
  onConfirmYes,
}: IConfirmVerifyReadingsDialogProps) => {
  return (
    <ConfirmDialog
      height={"auto"}
      width={"auto"}
      title={"Verify Reading Confirmation"}
      message={""}
      subMessage={"Do you wish to Verify the selected readings?"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
