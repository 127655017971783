import { loadMeterUserField } from "@app/products/property/meters/[id]/components/child-screens/user-field/api";
import { colMetersUserFields } from "@app/products/property/meters/[id]/components/child-screens/user-field/config";
import { IUserFieldData } from "@app/products/property/meters/[id]/components/child-screens/user-field/model";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const MetersUserFields = () => {
  const [data, setData] = useState<IUserFieldData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    setIsLoading(true);
    loadMeterUserField().then((response) => {
      if (response?.ItemData && response?.ItemData.length !== 0) {
        setData(
          Object.keys(response.ItemData).map((key: string, index: number) => {
            return {
              userField: key,
              value: response.ItemData[key as any],
              id: index,
            };
          })
        );
      }
      setIsLoading(false);
    });
  });

  return (
    <div className="cc-meter-user-field">
      <CCGrid
        isLoading={isLoading}
        className="cc-user-field-grid"
        data={data || []}
        columnFields={colMetersUserFields}
        primaryField="id"
        editableMode="row"
        itemPerPage={10}
      />
    </div>
  );
};
