import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { chargeRunJournalsRoute } from "@app/products/property/charge-and-notice-runs/charge-run-journals/route";
import { chargeRunsRoute } from "@app/products/property/charge-and-notice-runs/charge-runs/route";
import { listNoticeRunsRoute } from "@app/products/property/charge-and-notice-runs/notice-runs/route";
import { picChargeRunsRoute } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/route";

export const chargeAndNoticeRunsRoute: ICCRoute = {
  path: "charge-and-notice-runs",
  name: "Charge And Notice Runs",
  enum: eMenuAction.Charge_And_Notice_Runs_Folder,
  children: [
    chargeRunsRoute,
    listNoticeRunsRoute,
    chargeRunJournalsRoute,
    picChargeRunsRoute,
  ],
};
