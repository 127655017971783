import { VO_Assessment_RebateEntitlementEntity } from "@app/products/property/assessments/rebates/list/components/detail/model";
import { useRebatesDetailStore } from "@app/products/property/assessments/rebates/list/components/detail/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<VO_Assessment_RebateEntitlementEntity>();

export const RebatesListDetail = observer(
  ({ dataItem }: GridDetailRowProps) => {
    const {
      isLoading,
      responseLoadError,
      reLoadApiConfig,
      columnConfigRebatesDetail,
      loadViewConfigurationRebatesDetail,
      resetStore,
    } = useRebatesDetailStore();

    useEffectOnce(() => {
      loadViewConfigurationRebatesDetail();
      return () => resetStore();
    });

    //show Loading
    if (isLoading) return <Loading isLoading />;

    //Show load failed and reload
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            reLoadApiConfig();
          }}
        />
      );

    return (
      <div className="cc-rebates-list-detail">
        <div className="cc-grid-control-between">
          <label className="cc-label">Associated contacts</label>
        </div>
        <CCGrid
          className="cc-associated-entities-grid"
          dataUrl={`/odata/property/internal/rebateentitlemententitiesregister/GetRebateEntitlementEntities(assessmentRebateEntitlementId=${dataItem.Assess_Rebate_Entitlement_Id})?$count=true&`}
          columnFields={columnConfigRebatesDetail}
          primaryField={nameOf("Entity_Id")}
        />
      </div>
    );
  }
);
