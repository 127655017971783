export const mockSameName = [
  {
    Name: "ALLCOCK John Stuart",
    NoOfEntities: 2,
    Id: 0,
    IsExcluded: true,
    Detail: [
      {
        Name: "ALLCOCK John Stuart",
        Address: "176 Graham St, NAR NAR GOON NORTH, VIC 2110",
        Gender: "Female",
        EntityId: 693,
      },
      {
        Name: "ALLCOCK John Stuart",
        Address: "95 Gum Tree Rd, AVONSLEIGH, VIC 2111",
        Gender: "Female",
        EntityId: 692,
      },
    ],
  },
  {
    Name: "AQUILINA, Michael",
    NoOfEntities: 2,
    Id: 1,
    IsExcluded: false,
    Detail: [
      {
        Name: "AQUILINA, Michael",
        Address: "7 Romilly Ct, PAKENHAM, VIC 2110",
        Gender: "Female",
        EntityId: 1260,
      },
      {
        Name: "AQUILINA, Michael",
        Address: "7 Romilly Ct, VIC 2110",
        Gender: "Female",
        EntityId: 1261,
      },
    ],
  },
  {
    Name: "BALA, Mumin",
    NoOfEntities: 2,
    Id: 2,
    IsExcluded: true,
    Detail: [
      {
        Name: "BALA, Mumin",
        Address: "1 Marianne Way, EMERALD, VIC 2110",
        Gender: "Male",
        EntityId: 2109,
      },
      {
        Name: "BALA, Mumin",
        Address: "c/-A E Ashcroft, 56 Wood Rd, CASTLE HILL, NSW 2111",
        Gender: "Male",
        EntityId: 2108,
      },
    ],
  },
  {
    Name: "BALA Nevrije",
    NoOfEntities: 2,
    Id: 3,
    IsExcluded: false,
    Detail: [
      {
        Name: "BALA Nevrije",
        Address: "55 Billson St, PAKENHAM, VIC 2110",
        Gender: "Female",
        EntityId: 2110,
      },
      {
        Name: "BALA Nevrije",
        Address: "Bass Hwy, PAKENHAM, VIC 2111",
        Gender: "Female",
        EntityId: 2111,
      },
    ],
  },
  {
    Name: "BALLA Remzi",
    NoOfEntities: 2,
    Id: 4,
    IsExcluded: true,
    Detail: [
      {
        Name: "BALLA Remzi",
        Address: "66 Frank St, MARYKNOLL, VIC 2111 ",
        Gender: "Male",
        EntityId: 2187,
      },
      {
        Name: "BALLA Remzi",
        Address: "RSD Guyatts, Rd, NARRE WARREN SOUTH, VIC 2110",
        Gender: "Male",
        EntityId: 2188,
      },
    ],
  },
  {
    Name: "BASILONE, Giorgio",
    NoOfEntities: 3,
    Id: 5,
    IsExcluded: false,
    Detail: [
      {
        Name: "BASILONE, Giorgio",
        Address: "6 Botanic Dr, EMERALD, VIC 2110",
        Gender: "Female",
        EntityId: 9260,
      },
      {
        Name: "BASILONE, Giorgio",
        Address: "Suite 3, 140-142 Welsford St, PAKENHAM, VIC 2110",
        Gender: "Male",
        EntityId: 9259,
      },
      {
        Name: "BASILONE, Giorgio",
        Address: "Suite 3, 140-142 Welsford St, VERVALE, VIC 2110",
        Gender: "Male",
        EntityId: 9257,
      },
    ],
  },
  {
    Name: "BELANI, John",
    NoOfEntities: 2,
    Id: 6,
    IsExcluded: true,
  },
  {
    Name: "BELTEKY, Marie",
    NoOfEntities: 2,
    Id: 7,
    IsExcluded: false,
  },
  {
    Name: "BERNARDO, Antonio",
    NoOfEntities: 4,
    Id: 8,
    IsExcluded: false,
  },
  {
    Name: "BOGOEVSKA Rozika",
    NoOfEntities: 2,
    Id: 9,
    IsExcluded: false,
  },
  {
    Name: "BORG, Josephine",
    NoOfEntities: 3,
    Id: 10,
    IsExcluded: false,
  },
  {
    Name: "BRENNAN, Nick- Company Secretary",
    NoOfEntities: 2,
    Id: 11,
    IsExcluded: false,
  },
  {
    Name: "Brumar (Vic) Ply Ltd",
    NoOfEntities: 2,
    Id: 12,
    IsExcluded: false,
  },
  {
    Name: "BRUNTON, James",
    NoOfEntities: 2,
    Id: 13,
    IsExcluded: false,
  },
  {
    Name: "BRUZZANITI, John",
    NoOfEntities: 2,
    Id: 14,
    IsExcluded: false,
  },
  {
    Name: "Brymart Ply Ltd",
    NoOfEntities: 2,
    Id: 15,
    IsExcluded: false,
  },
  {
    Name: "BUCHANAN, Cathy",
    NoOfEntities: 2,
    Id: 16,
    IsExcluded: false,
  },
  {
    Name: "BUTTACAVOLL Peter Anthony",
    NoOfEntities: 2,
    Id: 17,
    IsExcluded: false,
  },
  {
    Name: "CAMILLERI Alfred George",
    NoOfEntities: 2,
    Id: 18,
    IsExcluded: false,
  },
  {
    Name: "CAMILLERI Charles",
    NoOfEntities: 4,
    Id: 19,
    IsExcluded: false,
  },
  {
    Name: "CAMILLERI Mary",
    NoOfEntities: 2,
    Id: 20,
    IsExcluded: false,
  },
  {
    Name: "CAMILLERI Paul",
    NoOfEntities: 3,
    Id: 21,
    IsExcluded: false,
  },
  {
    Name: "CANALS, Rita",
    NoOfEntities: 2,
    Id: 22,
    IsExcluded: false,
  },
  {
    Name: "CANFOTI, Nikola",
    NoOfEntities: 2,
    Id: 23,
    IsExcluded: false,
  },
  {
    Name: "CARULILE, Anthony Morton",
    NoOfEntities: 2,
    Id: 24,
    IsExcluded: false,
  },
  {
    Name: "CARUANA, Emmanuel",
    NoOfEntities: 2,
    Id: 25,
    IsExcluded: false,
  },
  {
    Name: "CASSAR Emmanuel",
    NoOfEntities: 4,
    Id: 26,
    IsExcluded: false,
  },
  {
    Name: "CASSAR John",
    NoOfEntities: "2",
    Id: 27,
    IsExcluded: false,
  },
  {
    Name: "CASSAR Joseph",
    NoOfEntities: "4",
    Id: 28,
    IsExcluded: false,
  },
  {
    Name: "CASSAR Josephine",
    NoOfEntities: "2",
    Id: 29,
    IsExcluded: false,
  },
  {
    Name: "CAUCHI, Joseph",
    NoOfEntities: "2",
    Id: 30,
    IsExcluded: false,
  },
  {
    Name: "CHAMI, Mahmoud",
    NoOfEntities: "2",
    Id: 31,
    IsExcluded: false,
  },
  {
    Name: "CHAN, Joseph Ping Kwong",
    NoOfEntities: "2",
    Id: 32,
    IsExcluded: false,
  },
  {
    Name: "CHARLESWORTH, Robert Edward",
    NoOfEntities: "2",
    Id: 33,
    IsExcluded: false,
  },
  {
    Name: "CHRISTOU, John",
    NoOfEntities: "2",
    Id: 34,
    IsExcluded: false,
  },
  {
    Name: "CINI, Concetta",
    NoOfEntities: "3",
    Id: 35,
    IsExcluded: false,
  },
  {
    Name: "CLARKE, Anthony James",
    NoOfEntities: "2",
    Id: 36,
    IsExcluded: false,
  },
  {
    Name: "CLARKE, Kaye Lynley",
    NoOfEntities: "2",
    Id: 37,
    IsExcluded: false,
  },
  {
    Name: "CLARKE, Sharon",
    NoOfEntities: "2",
    Id: 38,
    IsExcluded: false,
  },
  {
    Name: "CLE4RY, Sally Jane",
    NoOfEntities: "2",
    Id: 39,
    IsExcluded: false,
  },
  {
    Name: "COELN, Desiree Susanne Augusta",
    NoOfEntities: "2",
    Id: 40,
    IsExcluded: false,
  },
  {
    Name: "COMBEN, Lynette Ann",
    NoOfEntities: "2",
    Id: 41,
    IsExcluded: false,
  },
  {
    Name: "Common Equity Housing Limited",
    NoOfEntities: "2",
    Id: 42,
    IsExcluded: false,
  },
  {
    Name: "CONNORS, Terence Anthony",
    NoOfEntities: "2",
    Id: 43,
    IsExcluded: false,
  },
  {
    Name: "CONSTANTINOU, Maria",
    NoOfEntities: "3",
    Id: 44,
    IsExcluded: false,
  },
  {
    Name: "COZZELLA, Anna- Owner",
    NoOfEntities: "2",
    Id: 45,
    IsExcluded: false,
  },
  {
    Name: "CROFTS, June",
    NoOfEntities: "2",
    Id: 46,
    IsExcluded: false,
  },
  {
    Name: "CROFTS, William Leslie Joseph",
    NoOfEntities: "2",
    Id: 47,
    IsExcluded: false,
  },
  {
    Name: "CRONIN, John Francis",
    NoOfEntities: "2",
    Id: 48,
    IsExcluded: false,
  },
  {
    Name: "CROOKES, Peter Russell",
    NoOfEntities: "2",
    Id: 49,
    IsExcluded: false,
  },
  {
    Name: "CROSS, Darren John",
    NoOfEntities: "2",
    Id: 50,
    IsExcluded: false,
  },
  {
    Name: "CUCE, Salvatore",
    NoOfEntities: "2",
    Id: 51,
    IsExcluded: false,
  },
  {
    Name: "CVEINOVIC, Milisav",
    NoOfEntities: "3",
    Id: 52,
    IsExcluded: false,
  },
  {
    Name: "DALLEORE, Teresa",
    NoOfEntities: "2",
    Id: 53,
    IsExcluded: false,
  },
];
