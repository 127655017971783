import { AssetRegisterGridViewColumns, WorkOrderGridViewColumns } from './ams.field';
import { AmsRoute } from './ams.route';

// @TODO: Need to integate later
const MOCK_URL: string = '/odata/ams/internal/assetregisteritems?$count=true&';
export const AmsUrl = {
  // oData/api URLs

  
  //Dashboard
  GET_DASHBOARD_WARRANTY_CHART: '/api/ams/internal/dashboardwarrantychart',
  GET_DASHBOARD_ASSET_COUNT_TILES: '/api/ams/internal/dashboardactivities',
  GET_COMPUTER_ASSET_REPORT: '/api/AMS/Internal/LLSAssetComputerReport',
  GET_COMPUTER_WARRANTY_REPORT: '/api/AMS/Internal/LLSComputerWarrantyReport',


  //Lookups
  GET_AMS_LOOKUPS: '/api/ams/internal/lookups/',

  // Asset Register
  GET_VIEW_ASSET_REGISTER_ALL: '/odata/ams/internal/assetregisteritems?$count=true&',
  ASSET_REGISTER_ITEM: '/api/ams/internal/asset/', //Get, Put, Patch

  //Categories

  //Assessments

  //Work Orders
  GET_VIEW_WORK_ORDERS_ALL: '/odata/ams/internal/workorder?$count=true&',

  //Other
  GET_LOOKUPS_ALL: MOCK_URL,
  GET_SYSTEM_SETTINGS: MOCK_URL,
};

export const AmsListRoute = [
  {
    path: [AmsRoute.ASSET_REGISTER_PATH],
    initialColumnFields: AssetRegisterGridViewColumns,
    dataUrl: AmsUrl.GET_VIEW_ASSET_REGISTER_ALL,
    detailUrl: AmsRoute.ASSET_REGISTER_PATH,
    highLightColumns: '',
  },
  {
    path: [AmsRoute.WORK_ORDER_REGISTER_PATH],
    initialColumnFields: WorkOrderGridViewColumns,
    dataUrl: AmsUrl.GET_VIEW_WORK_ORDERS_ALL,
    detailUrl: AmsRoute.WORK_ORDER_REGISTER_PATH,
    highLightColumns: '',
  },
];
