import { DTO_AssessmentRebateEntitlementEntity } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_AssessmentRebateEntitlementEntity>();
export const colAssociatedEntities: IColumnFields[] = [
  {
    field: nameOf("EntitledEntitiesAsString"),
    title: "Entity",
  },
  {
    field: nameOf("IsEntitledToRebate"),
    format: BOOLEAN_FORMAT.BOOLEAN,
    title: "Entitled to Rebate",
  },
  {
    field: nameOf("ConcessionCardHeld"),
    title: "Concession Cards Held",
  },
  {
    field: nameOf("Id"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
