import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";
import "./_index.scss";

export interface IConfirmExit {
  onClose: () => void;
  onClick: () => void;
}
export const ConfirmExit = ({ onClose, onClick }: IConfirmExit) => {
  return (
    <div className="cc-exit-container">
      <Dialog title={"Please confirm"} onClose={onClose}>
        <p className="cc-exit-message">Are you sure you want to exit?</p>
        <p className="cc-exit-message">
          (If you choose "Yes" you will lose any changes make while editing!)
        </p>
        <DialogActionsBar>
          <div className="cc-dialog-footer-actions-right">
            <Button className="cc-dialog-button" onClick={onClose}>
              NO
            </Button>
            <Button
              themeColor="primary"
              className="cc-dialog-button"
              onClick={onClick}
            >
              YES
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};
