import { getPicSummaryById } from "@app/products/property/pic/[id]/api";
import {
  DTO_PIC_Summary,
  ILovPicSummary,
} from "@app/products/property/pic/[id]/model";
import { getContactAlertFromStringArray } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class PicStore {
  private _pic?: DTO_PIC_Summary = undefined;
  private _lov?: ILovPicSummary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get pic() {
    return toJS(this._pic);
  }
  setPic = (pic?: DTO_PIC_Summary) => {
    runInAction(() => {
      this._pic = pic;
    });
  };

  get lovPIC() {
    return toJS(this._lov);
  }
  setLovPIC = (lov?: ILovPicSummary) => {
    runInAction(() => {
      this._lov = lov;
    });
  };

  get picId() {
    return toJS(this.pic?.PIC_Id);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._pic = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  //reload PIC
  reloadPIC = (picId: number, notification?: IAppNotificationItemAddProps) => {
    if (isNil(picId)) {
      this.loadPic(picId, notification);
    }
  };

  loadPic = async (
    picId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getPicSummaryById(picId);
    let errorResponse = undefined;
    let newPic = undefined;
    if (Array.isArray(response)) {
      const [summary, lovPIC] = response;
      if (
        isSuccessResponse(summary) &&
        isSuccessResponse(lovPIC) &&
        lovPIC?.data &&
        summary?.data
      ) {
        const picManagerAddress = summary?.data?.ManagerAddress ?? "";
        const picManagerNameAndAddress =
          `${
            summary?.data?.ManagerName?.length
              ? `${summary?.data?.ManagerName}\n`
              : ""
          }` + picManagerAddress;

        newPic = {
          ...summary?.data,
          ManageNameAndAddress: picManagerNameAndAddress,
        };
        this.setLovPIC({ PICTypes: lovPIC?.data?.PICTypes });
        if (newPic?.Contact_Alerts && newPic?.Contact_Alerts?.length > 0) {
          const newNotifications = getContactAlertFromStringArray(
            newPic.Contact_Alerts
          );
          newNotifications.forEach((notification) => {
            appNotificationStore.pushNotification(notification);
          });
        }
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setPic(newPic);
    this.setIsLoading(false);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
  };
}

const picStoreContext = createContext(new PicStore());
export const usePicStore = () => {
  return useContext(picStoreContext);
};
