import { odataCoreGetFinanceTransaction } from "@app/core/components/common/utils";
import { colFinanceTransactionsAccordion } from "@app/core/transaction/finance-transaction-accordion/config";
import { FinanceTransactionsAccordion } from "@app/core/transaction/finance-transaction-accordion/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { isNil } from "lodash";
import React from "react";

interface IFinanceTransactionProps {
  id?: number | string;
  recordType: RECORDTYPE;
  debtNo?: string;
}

const nameOf = nameOfFactory<FinanceTransactionsAccordion>();
export const FinanceTransactions = ({
  id,
  recordType,
  debtNo = "",
}: IFinanceTransactionProps) => {
  return (
    <CCGrid
      columnFields={colFinanceTransactionsAccordion}
      primaryField={nameOf("ID")}
      dataUrl={
        isNil(id)
          ? undefined
          : odataCoreGetFinanceTransaction(id, recordType, debtNo)
      }
      state={{ sort: [{ field: nameOf("RecordedDate"), dir: "desc" }] }}
    />
  );
};
