import { ICCRoute } from "@common/constants/ICCRoute";

export const feesRoute: ICCRoute = {
  path: "fees",
  name: "Fees",
  children: [
    {
      path: "4yo-deposit",
      name: "4yo Deposit",
      component: require("./4yo-deposit/_index").default,
    },
    {
      path: "4yo-fees",
      name: "4yo Fees",
      component: require("./4yo-fees/_index").default,
    },
    {
      path: "4yo-invoices",
      name: "4yo Invoices",
      component: require("./4yo-invoices/_index").default,
    },
    {
      path: "4yo-zero-fees",
      name: "4yo Zero Fees",
      component: require("./4yo-zero-fees/_index").default,
    },
    {
      path: "placement-4yo-zero-fees",
      name: "Placement 4yo Zero Fees",
      component: require("./placement-4yo-zero-fees/_index").default,
    },
    {
      path: "term-1-4yo-zero-fees",
      name: "Term 1 4yo Zero Fees",
      component: require("./term-1-4yo-zero-fees/_index").default,
    },
    {
      path: "term-2-4yo-zero-fees",
      name: "Term 2 4yo Zero Fees",
      component: require("./term-2-4yo-zero-fees/_index").default,
    },
    {
      path: "term-3-4yo-zero-fees",
      name: "Term 3 4yo Zero Fees",
      component: require("./term-3-4yo-zero-fees/_index").default,
    },
    {
      path: "term-4-4yo-zero-fees",
      name: "Term 4 4yo Zero Fees",
      component: require("./term-4-4yo-zero-fees/_index").default,
    },
    {
      path: "3yo-fees",
      name: "3yo Fees",
      component: require("./3yo-fees/_index").default,
    },
    {
      path: "3yo-invoices",
      name: "3yo Invoices",
      component: require("./3yo-invoices/_index").default,
    },
    {
      path: "not-exported-fees",
      name: "Not Exported Fees",
      component: require("./not-exported-fees/_index").default,
    },
    {
      path: "exported-fees",
      name: "Exported Fees",
      component: require("./exported-fees/_index").default,
    },
    {
      path: "not-exported-debtors",
      name: "Not Exported Debtors",
      component: require("./not-exported-debtors/_index").default,
    },
    {
      path: "exported-debtors",
      name: "Exported Debtors",
      component: require("./exported-debtors/_index").default,
    },
    {
      path: "os-fees-by-debtor",
      name: "O/S Fees By Debtor",
      component: require("./os-fees-by-debtor/_index").default,
    },
    {
      path: "os-fees-by-registration",
      name: "O/S Fees By Registration",
      component: require("./os-fees-by-registration/_index").default,
    },
    {
      path: "not-exported-services",
      name: "Not Exported Services",
      component: require("./not-exported-services/_index").default,
    },
    {
      path: "exported-services",
      name: "Exported Services",
      component: require("./exported-services/_index").default,
    },

    {
      path: "payment-plans",
      name: "Payment Plans",
      component: require("./payment-plans/_index").default,
    },
    {
      path: "subsidy-cards",
      name: "Subsidy Cards",
      component: require("./subsidy-cards/_index").default,
    },
  ],
};
