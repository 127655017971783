import { ExistedDocumentTemplate } from "@app/products/property/system-admin/document-template/[id]/components/forms/existed/_index";
import { NewDocumentTemplate } from "@app/products/property/system-admin/document-template/[id]/components/forms/new/_index";
import { useDocumentTemplateStore } from "@app/products/property/system-admin/document-template/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const ManageDocumentTemplate = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { loadDocumentTemplate, resetStore } = useDocumentTemplateStore();
  const { clearNotifications } = useCCAppNotificationStore();

  useEffect(() => {
    if (isNew) {
      loadDocumentTemplate();
    }
    loadDocumentTemplate(+id);
    return () => {
      clearNotifications();
      resetStore();
    };
  }, [isNew, loadDocumentTemplate, resetStore, id, clearNotifications]);

  if (isNew) {
    return <NewDocumentTemplate />;
  }
  return <ExistedDocumentTemplate />;
};

export default observer(ManageDocumentTemplate);
