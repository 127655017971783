import { DTO_LOV } from "@common/models/odataResponse";

export const mockVehicleTypeData: DTO_LOV[] = [
  { Code: "1", Name: "Car" },
  { Code: "2", Name: "Dinghys" },
  { Code: "3", Name: "Truck" },
];
export const mockProofOfResidencyData = [{ Code: "1", Name: "Certificate BG" }];
export const mockPrintOnPermitData = [
  { Code: "1", Name: "Code" },
  { Code: "2", Name: "Name" },
];
export const mockRegistrationStateData = [
  { Code: "1", Name: "ACT" },
  { Code: "2", Name: "NSW" },
  { Code: "3", Name: "NT" },
  { Code: "4", Name: "QLD" },
  { Code: "5", Name: "SA" },
  { Code: "6", Name: "TAS" },
  { Code: "7", Name: "VIC" },
  { Code: "8", Name: "WA" },
];
export const mockColourData = [
  { Code: "1", Name: "Blue" },
  { Code: "2", Name: "Green" },
  { Code: "3", Name: "Pink" },
  { Code: "4", Name: "Red" },
  { Code: "5", Name: "White" },
];
