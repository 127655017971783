import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";

interface IConfirmDateDialog {
  confirmDialog?: any;
}

export const ConfirmDateDialog = observer(
  ({ confirmDialog }: IConfirmDateDialog) => {
    return (
      <ConfirmDialog
        title={"Confirm Date"}
        width={"25%"}
        height={"auto"}
        message={<></>}
        subMessage={
          <>
            <p>The leakage to date is outside the expected valid date range.</p>
            <p>Are you sure the leakage to date is correct?</p>
          </>
        }
        onClosePopup={() => {
          confirmDialog.actionCallback(false);
        }}
        onConfirmYes={() => {
          confirmDialog.actionCallback(true);
        }}
      />
    );
  }
);
