import { encryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { IMobileAndEmailPreview } from "@app/core/communication/dialogs/model";
import { sanitizeWithoutParse } from "@common/utils/sanitized-parser";
import React from "react";
import "./_index.scss";

export const EmailPreview = ({ EmailContent }: IMobileAndEmailPreview) => {
  const sanitizeEmailContent = sanitizeWithoutParse(
    encryptionHtmlForEditor(EmailContent ?? "")
  );
  return (
    <div className="cc-smartphone">
      <div className="cc-smartphone-screen">
        <iframe
          srcDoc={
            `<style>
            html {
              word-wrap: break-word !important;
              white-space: break-spaces !important;
            }
            ::-webkit-scrollbar {
              width: 4px;
              height: 8px;
            }
            ::-webkit-scrollbar-track {
              background: #F5F5F5;
            }
            ::-webkit-scrollbar-thumb {
              background: #4881B4;
              border-radius: 4px;
            }
            </style>` + sanitizeEmailContent
          }
          title="Iframe"
        ></iframe>
      </div>
    </div>
  );
};
