const mockListDebtRecoveryHistory = [
  {
    "ID": 2,
    "NameAndAddress": "J H Cummings(Estate) Ref:2301660-434-Jas 47 Pia Dr TRARALGON, VIC 3844",
    "Assessments": "68107. 17 Beasleys 5t. Arches Creek",
    "Status": "Recovery in Progress",
    "Type": "Debt Recovery / Legal Action",
    "DateFrom": "2017-06-05T17:00:00.000Z",
    "DateTo": "2017-09-13T17:00:00.000Z",
    "InitialBalance": 4818.62,
    "InitialInterestBalance": 366.64,
    "InitialDebt": 5155.26,
    "Balance": 0,
    "InitialDebtIncludingCosts": 5155.26,
    "Total": 0,
    "AssessmentBalance": 585,
    "UpToDueDateTime": "2017-09-13T17:00:00.000Z",
    "AsAt": "2017-09-13T17:00:00.000Z",
    "ShowAlertOnAssessment": true,
    "Officer": "Barbara HOGAN",
    "CreatedBy": "Barbara HOGAN",
    "CreatedOn": "2017-09-13T17:00:00.000Z",
    "ChangedBy": "Barbara HOGAN",
    "ChangedOn": "2017-09-13T17:00:00.000Z",
    "AssessmentAssociated": [
      {
        "AssessmentNumber": 681074,
        "AssessmentReference": 74982630,
        "PropertyAddress": "17 Beasleys St, Archies Creek",
        "InitialCharges": 4818.62,
        "InitialInterest": 336.64,
        "TotalDebt": 5155.26,
        "DebtCostsBalance": 0,
        "AssessmentBalance": 585,
        "AssessmentId": 2630
      }
    ]
  },
  {
    "ID": 5,
    "NameAndAddress": "J H Cummings(Estate) Ref:2301660-434-Jas 47 Pia Dr TRARALGON, VIC 3844",
    "Assessments": "68107. 17 Beasleys 5t. Arches Creek",
    "Status": "Recovery in Progress",
    "Type": "Debt Recovery / Legal Action",
    "DateFrom": "2017-08-07T17:00:00.000Z",
    "DateTo": "2017-09-13T17:00:00.000Z",
    "InitialBalance": 0,
    "InitialInterestBalance": 0,
    "InitialDebt": 0,
    "Balance": 0,
    "InitialDebtIncludingCosts": 0,
    "Total": 0,
    "AssessmentBalance": 585,
    "UpToDueDateTime": "2017-09-13T17:00:00.000Z",
    "AsAt": "2017-09-13T17:00:00.000Z",
    "ShowAlertOnAssessment": true,
    "Officer": "Barbara HOGAN",
    "CreatedBy": "Barbara HOGAN",
    "CreatedOn": "2017-09-13T17:00:00.000Z",
    "ChangedBy": "Barbara HOGAN",
    "ChangedOn": "2017-09-13T17:00:00.000Z",
    "AssessmentAssociated": [
      {
        "AssessmentNumber": 681074,
        "AssessmentReference": 74982630,
        "PropertyAddress": "17 Beasleys St, Archies Creek",
        "InitialCharges": 4818.62,
        "InitialInterest": 336.64,
        "TotalDebt": 5155.26,
        "DebtCostsBalance": 0,
        "AssessmentBalance": 585,
        "AssessmentId": 2630
      }
    ]
  }
]
export default mockListDebtRecoveryHistory;