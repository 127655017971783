export const mockMeterReadingRouteDetailsTab = {
  MeterReadingRouteNumber: 1000,
  RouteName: "Proserpine 1",
  AssignedReadingDevice: "PDA_ALL",
  AssignedDeviceHostName: "PDA_ALL",
  AssignedReader: "Meter Reading 1",
  ActiveMeters: 479,
  MetersWithStandardReadings: 0,
  MetersWithSpecialReadings: 0,
  ReadingsWaitingVerification: 22,
  StandardReadingRequired: 479,
  SpecialReadingRequired: 0,
  ReadingRouteId: 1,
};
