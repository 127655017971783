/* eslint-disable no-useless-computed-key */
import { getDisplayTextWithDashes } from "@common/utils/common";
import { getter } from "@progress/kendo-data-query";
import { cloneDeep } from "lodash";
import { AnimalResponse } from "./model";

export const convertAnimalResponseToAnimalForm = (
  animalResponse: AnimalResponse
) => {
  // @TODO: remove any later
  let animalForm: any = cloneDeep(animalResponse);
  animalForm.RegisteredAddress = "";
  if (animalResponse.Address?.Location_Description) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.Location_Description}\n`;
  }
  if (animalResponse.Address?.PropertyName) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.PropertyName}\n`;
  }
  if (animalResponse.Address?.UnitNo) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.UnitNo}`;
  }
  if (animalResponse.Address?.StreetNo) {
    if (animalResponse.Address?.UnitNo) {
      animalForm.RegisteredAddress += "/";
    }
    animalForm.RegisteredAddress += `${animalResponse.Address?.StreetNo} `;
  }
  if (animalResponse.Address?.StreetName) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.StreetName}`;
  }
  if (animalResponse.Address?.Suburb) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.Suburb} `;
  }
  if (animalResponse.Address?.State) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.State} `;
  }
  if (animalResponse.Address?.Postcode) {
    animalForm.RegisteredAddress += `${animalResponse.Address?.Postcode} `;
  }
  if (animalResponse.Address?.AssessmentNo) {
    animalForm.RegisteredAddress += `\nAssess # ${animalResponse.Address?.AssessmentNo}`;
  }
  if (animalResponse.Address?.PropertyAssessment?.Assessment_LandSize) {
    animalForm.RegisteredAddress += `\nLand Size ${animalResponse.Address?.PropertyAssessment?.Assessment_LandSize} m²`;
  }
  animalForm.PostalAddress = "";
  if (animalResponse.Contact?.Contact.PostalAddress?.StreetNo) {
    animalForm.PostalAddress += `${animalResponse.Contact?.Contact.PostalAddress?.StreetNo} `;
  }
  if (animalResponse.Contact?.Contact.PostalAddress?.StreetName) {
    animalForm.PostalAddress += `${animalResponse.Contact?.Contact.PostalAddress?.StreetName}`;
  }
  if (animalResponse.Contact?.Contact.PostalAddress?.Suburb) {
    animalForm.PostalAddress += `\n${animalResponse.Contact?.Contact.PostalAddress?.Suburb}`;
  }
  if (animalResponse.Contact?.Contact.PostalAddress?.State) {
    animalForm.PostalAddress += ` ${animalResponse.Contact?.Contact.PostalAddress?.State}`;
  }
  if (animalResponse.Contact?.Contact.PostalAddress?.Postcode) {
    animalForm.PostalAddress += ` ${animalResponse.Contact?.Contact.PostalAddress?.Postcode}`;
  }
  animalForm.FeeOS = 0;
  if (animalResponse.AnimalType?.Fee_StandardAmount) {
    animalForm.FeeOS = animalResponse.AnimalType?.Fee_StandardAmount;
  }
  if (animalResponse.Contact?.Contact?.DisplayName) {
    animalForm.AnimalOwner = `${animalResponse.Contact?.Contact?.DisplayName}`;
  }

  return animalForm;
};

const animalTypeGetter = getter("animalType");
const dobGetter = getter("dob");
const genderGetter = getter("gender");
const microchipNumberGetter = getter("microchipNumber");
const tagNoGetter = getter("tagNo");
const addressGetter = getter("registeredAddress");
const ownerGetter = getter("owner");
const pensionNumberGetter = getter("PensionNumber");

export const animalFormValidator: any = (values: any): any => {
  if (Object.keys(values).length > 0) {
    return {};
  }

  if (
    animalTypeGetter(values) ||
    dobGetter(values) ||
    genderGetter(values) ||
    microchipNumberGetter(values) ||
    tagNoGetter(values) ||
    addressGetter(values) ||
    ownerGetter(values) ||
    pensionNumberGetter(values)
  ) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Please fill atleast one of the following fields.",
    ["animalType"]: "You must select an existing Animal Type",
    ["dob"]: "You must select a date",
    ["gender"]: "You must select a gender",
    ["microchipNumber"]: "You must enter Microchip Number",
    ["tagNo"]: "You must enter Tag Number",
    ["registeredAddress"]: "You must enter Registered Address",
    ["owner"]: "You must enter Animal Owner",
  };
};

export const AnimalBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Animals ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow?.AnimalName ?? "",
      selectedRow?.Breed,
    ]);
  },
};
