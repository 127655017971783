import { BuildingUrl } from "@app/products/building/model";
import { IBuildingRegistersPOPEFormData } from "@app/products/building/registers/pope/[id]/model";
import { OfficerColumns } from "@common/pages/actions/config";
import { nameOfFactory } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";

const nameOf = nameOfFactory<IBuildingRegistersPOPEFormData>();

export const addressSurveyorFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Municipal Building Surveyor",
  placeholder: "Municipal Building Surveyor",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "AddressSurveyor_Field",
  value: formRenderProps.valueGetter(nameOf("MunicipalBuildingSurveyor")),
  onChange: (event: any) => {
    if (event.target === null) {
      formRenderProps.onChange(nameOf("MunicipalBuildingSurveyor"), {
        value: event.detail.Name,
      });

      formRenderProps.onChange(nameOf("Decision"), {
        value: event.detail.Name,
      });
    } else {
      formRenderProps.onChange(nameOf("MunicipalBuildingSurveyor"), {
        value: event.target.value,
      });
    }
  },
});
