import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const TaskSchedulingHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();

  const journalId = lastSelectedRow?.JobJournalRecordSourceId;

  return <Journal id={journalId} recordType={RECORDTYPE.CORE_Quartz} />;
});
