import { colAmountsOwingAtCurrentDate } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/balances/config";
import {
  mockAmountsOwingAtCurrentDate,
  mockAmountsOwingAtCurrentDateForm,
} from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/balances/mock";
import { IAmountsOwingAtCurrentDateForm } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/balances/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCNote } from "@components/cc-note/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import React, { ReactElement } from "react";
const nameOf = nameOfFactory<IAmountsOwingAtCurrentDateForm>();
export function Balances(): ReactElement {
  return (
    <div className="cc-form">
      <Form
        initialValues={{ ...mockAmountsOwingAtCurrentDateForm }}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            {/* <input hidden type="submit" id="btn-submit" onClick={validateForm} /> */}
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCValueField
                    name={nameOf("Overdue")}
                    label="Overdue"
                    value={formRenderProps.valueGetter(nameOf("Overdue"))}
                    format={CURRENCY_FORMAT.CURRENCY1}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    name={nameOf("Due")}
                    label="Due"
                    value={formRenderProps.valueGetter(nameOf("Due"))}
                    format={CURRENCY_FORMAT.CURRENCY1}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    name={nameOf("Pending")}
                    label="Pending"
                    value={formRenderProps.valueGetter(nameOf("Pending"))}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    name={nameOf("OverPayments")}
                    label="Over payments"
                    value={formRenderProps.valueGetter(nameOf("OverPayments"))}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    name={nameOf("BalanceOwing")}
                    label="Balance owing"
                    value={formRenderProps.valueGetter(nameOf("BalanceOwing"))}
                    format={CURRENCY_FORMAT.CURRENCY1}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    name={nameOf("PayoutAmount")}
                    label="Payout amount"
                    value={formRenderProps.valueGetter(nameOf("PayoutAmount"))}
                    format={CURRENCY_FORMAT.CURRENCY1}
                  />
                </div>
              </div>
              <section>
                <label className="cc-label">Assessment's share of costs</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Estimated</label>
                      <Field
                        name={nameOf("Estimated")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Actual</label>
                      <Field
                        name={nameOf("Actual")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <label className="cc-label">Balances</label>
                <div className="cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Principal balance</label>
                      <Field
                        name={nameOf("PrincipalBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Principal due</label>
                      <Field
                        name={nameOf("PrincipalDue")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Interest balance</label>
                      <Field
                        name={nameOf("InterestBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Other balance</label>
                      <Field
                        name={nameOf("OtherBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Amount owing</label>
                      <Field
                        name={nameOf("AmountOwing")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Balance</label>
                      <Field
                        name={nameOf("Balance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Withheld interest balance
                      </label>
                      <Field
                        name={nameOf("WithheldInterestBalance")}
                        component={CCCurrencyInput}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <Field
                    label="Show paid charges"
                    name={nameOf("ShowPaidCharges")}
                    component={Checkbox}
                  />
                </div>
                <div className="cc-field">
                  <CCGrid
                    data={mockAmountsOwingAtCurrentDate}
                    columnFields={colAmountsOwingAtCurrentDate}
                    primaryField="ID"
                  />
                </div>
              </div>
            </section>
            <CCNote
              message={`Scheme Account balances are based on ESTIMATED charges and is subject to change`}
            />
            <br />
          </FormElement>
        )}
      />
    </div>
  );
}
