import {
  DTO_DebtRecovery_LOVs,
  DTO_Workflow_CreateDebtRecovery,
} from "@app/products/property/components/action-bar/dialog/new-debt-recovery/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowDebtRecovery = async (
  assessmentId: number
): Promise<APIResponse<DTO_Workflow_CreateDebtRecovery>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/debtrecovery/new`,
      { AssessmentId: assessmentId }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewDebtRecovery = async (
  model: WorkflowProcessMode,
  newdebtRecovery: DTO_Workflow_CreateDebtRecovery | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CreateDebtRecovery>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createdebtrecovery/process/${model}`,
      newdebtRecovery
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDebtRecoveryDraft = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CreateDebtRecovery>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/debtRecovery/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataDebtRecovery =
  | [
      APIResponse<DTO_DebtRecovery_LOVs>,
      APIResponse<DTO_Workflow_CreateDebtRecovery>
    ]
  | APIResponse<DTO_DebtRecovery_LOVs | DTO_Workflow_CreateDebtRecovery>
  | undefined;

export const getInitialDataDebtRecovery = async (
  assessmentId: number,
  workflowDraftId: number | undefined
): Promise<IGetInitialDataDebtRecovery> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_DebtRecovery_LOVs>(
        `api/property/internal/workflow/debtrecovery/lovs`
      ),
      !isNil(workflowDraftId)
        ? getDebtRecoveryDraft(workflowDraftId)
        : postNewWorkflowDebtRecovery(assessmentId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
