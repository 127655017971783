import { DTO_Address } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";

export const validatorPhysicalAddress = (
  value: any,
  isLLS: boolean
): string | undefined => {
  if (!value?.Addresses?.length) {
    return `At least one address must be set as primary${
      isLLS ? " and property name is required" : ""
    }`;
  }
  const isContainPrimary = value?.Addresses?.some((item: DTO_Address) => {
    if (isLLS) return item?.IsAddressPrimary && item?.PropertyName;
    return item?.IsAddressPrimary;
  });
  if (!isContainPrimary) {
    return `At least one address must be set as primary${
      isLLS ? " and property name is required" : ""
    }`;
  }
  return undefined;
};
