import { IExecutionData } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/execution/model";
import { ENoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";

export const instalmentPlanData: Array<IExecutionData> = [
  { label: "No selection on instalment plans", value: "1" },
  {
    label: "Select instalment plans",
    value: "2",
  },
];

export enum EInstalmentPlanOption {
  NoSelection = "1",
  SelectInstalment = "2",
}

export const listTypeShowAssessmentPaying = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.NOTICES_FOR_JOURNALS_OF_ACCOUNT_TRANSACTIONS,
  ENoticeType.REGISTER_ACCOUNT_BALANCES
]