import { eBooleanType } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/model";

export const MAX_LENGTH_DOCUMENT_NAME = 60;

export const saveAsFormatData = [
  { Key: eBooleanType.FALSE, Value: "Microsoft Word (doc or docx)" },
  { Key: eBooleanType.TRUE, Value: "Portable Document Format (pdf)" },
];

export const makeActiveData = [
  { Key: eBooleanType.TRUE, Value: "Active" },
  { Key: eBooleanType.FALSE, Value: "Inactive" },
];
