import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import {
  EGenderAnimal,
  iconAnimalsColumnDangerousConfig,
} from "@app/products/animals/config";
import { Svc_Animals_Finance } from "@app/products/animals/finance/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_Finance>();
export const colAnimalsFinanceAllDebtorSummary: IColumnFields[] = [
  {
    field: nameOf("RegistrationNumber"),
    title: "Registration Number",
    locked: true,
    linkTo: (dataItem: Svc_Animals_Finance) => {
      return `${ANIMALS_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconAnimalsColumnDangerousConfig,
  {
    field: nameOf("DebtorNo"),
    title: "Debtor Number",
  },
  { field: nameOf("FileNumber"), title: "File Number" },
  {
    field: nameOf("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOf("AmountOS"),
    title: "Customer Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("AppBalance"),
    title: "App Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NARID"),
    title: "NAR ID",
  },
  { field: nameOf("CONTACTID"), title: "Contact ID" },
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  {
    field: nameOf("MicrochipNo"),
    title: "Chip Number",
  },
  {
    field: nameOf("AnimalOwner"),
    title: "Animal Owner",
  },
  {
    field: nameOf("SiteAddress"),
    title: "Registered Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("Type"),
    title: "Animal Type",
  },
  {
    field: nameOf("Breed"),
    title: "Breed",
  },
  {
    field: nameOf("AnimalName"),
    title: "Name",
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
    filterByEnum: EGenderAnimal,
  },
  {
    field: nameOf("StatusDueStatus"),
    title: "Status",
  },
];
