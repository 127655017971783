import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Charge_Run_Assessment_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Charge_Run_Assessment_Summary>();
export const colAssessments: IColumnFields[] = [
  {
    field: nameOf("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: VO_Charge_Run_Assessment_Summary) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
  },
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("NoofLevies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("BaseAmount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("RateByValueAmount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("IncrementtoMinimum"),
    title: "Increments to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("ReductiontoMaximum"),
    title: "Reduction to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Adjustment_For_Capping"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("GrossLevyAmount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Rebates"),
    title: "Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NettLevyAmount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Attributable_Value"),
    title: "Attributable Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
