import { history } from "@/AppRoutes";
import { TOWN_PLANNING_PPR_ENQUIRIES_BY_DUE_STATUS_ROUTE } from "@app/products/town-planning/ppr/enquiries/by-due-status/constant";
import { getProductTitle } from "@common/constants/product-control";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { useTownPlanningDashboardStore } from "@common/pages/home/components/townplanning/store";
import Loading from "@components/loading/Loading";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem
} from "@progress/kendo-react-charts";
import { observer } from "mobx-react-lite";
import React from "react";
export const EnquiriesByDueStatus = observer(() => {
  const {
    enquiriesByDueStatus,
    queryDateTimeTownPlanningDashboard,
    isLoadingDashboardTownPlanning,
  } = useTownPlanningDashboardStore();

  const dataList = enquiriesByDueStatus?.listOfData ?? [];

  const EnquiriesByDueStatusChartContainer = () => (
    <Chart
      style={{
        height: 300,
      }}
    >
      <ChartLegend position="top" orientation="horizontal" />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={enquiriesByDueStatus?.years ?? []}
          startAngle={45}
        />
      </ChartCategoryAxis>
      <ChartSeries>
        {dataList?.map((item, idx) => (
          <ChartSeriesItem
            key={idx}
            type="column"
            tooltip={{
              visible: true,
            }}
            data={item.data}
            name={item.name}
          />
        ))}
      </ChartSeries>
    </Chart>
  );

  return (
    <>
      {isLoadingDashboardTownPlanning ? (
        <Loading isLoading />
      ) : (
        <div
          className="cc-insight-group"
          onClick={() => {
            history.push(TOWN_PLANNING_PPR_ENQUIRIES_BY_DUE_STATUS_ROUTE);
          }}
        >
          <div className="cc-group-name">
            {getProductTitle(PRODUCT_TYPE.TownPlanning)}
          </div>
          <h4>Enquiries by Due Status</h4>
          <div className="cc-date-time-description">
            as of {queryDateTimeTownPlanningDashboard ?? ""}
          </div>
          <div className="cc-insight-group-container">
            <EnquiriesByDueStatusChartContainer />
          </div>
        </div>
      )}
    </>
  );
});
