import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { MailMerge_SelectMergeDocument_Ext } from "@app/core/new-mail-merge/dialogs/model";
import { CS_LookupTable } from "@app/products/crms/[id]/model";
import { getAcknowledgeMethodLovs } from "@app/products/crms/components/dialogs/acknowledge-events/api";
import { MailMergePicker } from "@app/products/crms/components/dialogs/acknowledge-events/components/mail-merge/_index";
import { EventBatchAcknowledge } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface IAcknowledgeEventsDialog {
  onClosePopup: () => void;
  initData?: EventBatchAcknowledge;
  handleSubmit?: ((event: FormSubmitClickEvent) => void) | undefined;
  isLoading?: boolean;
}

const nameOf = nameOfFactory<EventBatchAcknowledge>();
export const AcknowledgeEventsDialog = ({
  onClosePopup = () => {},
  handleSubmit = () => {},
  isLoading = false,
}: IAcknowledgeEventsDialog) => {
  const [acknowledgeMethodLovs, setAcknowledgeMethodLovs] = useState<
    IKeyValuePacket[]
  >([]);
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const [isFirstLoading, setIsFirstLoading] = useState(false);

  const acknowledgeMethodLovsCall = async () => {
    setResponseLoadError(undefined);
    setIsFirstLoading(true);
    const response = await getAcknowledgeMethodLovs(
      CS_LookupTable.CommunicationMethod
    );
    setIsFirstLoading(false);
    if (isSuccessResponse(response) && response.data) {
      setAcknowledgeMethodLovs(response.data);
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error,
      });
    }
  };

  useEffectOnce(() => {
    acknowledgeMethodLovsCall();
  });

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, onChange, valid } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="35%"
              maxHeight="45%"
              onClose={() => onClosePopup()}
              titleHeader="Event Batch Acknowledgement"
              bodyElement={
                isFirstLoading ? (
                  <Loading isLoading={isFirstLoading} />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => acknowledgeMethodLovsCall()}
                  />
                ) : (
                  <div className="cc-form">
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Mail merge template" />
                          <Field
                            name={"_option.MailMergeDocument_Name"}
                            component={MailMergePicker}
                            productType={PRODUCT_TYPE_NUMBER.CustomerService}
                            recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                            dataSetFilter={
                              MAILMERGEDATASET.CUSTOMERSERVICE_Event
                            }
                            isMultiple={false}
                            formRenderProps={formRenderProps}
                            onChangeForm={(
                              mailMerge: MailMerge_SelectMergeDocument_Ext
                            ) => {
                              onChange("_option.MailMergeDocument_Name", {
                                value:
                                  mailMerge?._options
                                    ?.SelectedMailMergeDocumentsTemplate?.[0]
                                    ?.FileName,
                              });
                              onChange(nameOf("MailMergeDocument_ID"), {
                                value:
                                  mailMerge?._options
                                    ?.SelectedMailMergeDocumentsTemplate?.[0]
                                    ?.MailMergeDocument_ID,
                              });
                            }}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Date acknowledged" isMandatory />
                          <Field
                            name={nameOf("DateAcknowledged")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            validator={requiredValidator}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Acknowledged method" isMandatory />
                          <Field
                            name={nameOf("AcknowledgedMethod_ID")}
                            component={CCDropDownList}
                            textField={"Value"}
                            dataItemKey={"Key"}
                            data={acknowledgeMethodLovs}
                            validator={requiredValidator}
                            disabled={isLoading}
                            isKeyValueDropdown
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              footerElement={
                <>
                  <div className="cc-dialog-footer-actions-right">
                    <div>
                      <Button
                        className="cc-dialog-button"
                        type="button"
                        onClick={() => {
                          onClosePopup();
                        }}
                        disabled={isLoading}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="cc-dialog-button"
                        themeColor="primary"
                        type="submit"
                        onClick={onSubmit}
                        disabled={isLoading || !valid}
                        iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
