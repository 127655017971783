import { VO_PIC } from "@app/products/property/pic/list/model";
import { nameOfFactory } from "@common/utils/common";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

export enum ePICActiveFilterValue {
  Active = "Active",
}
const nameOf = nameOfFactory<VO_PIC>();
export const activePICListFilterState: CompositeFilterDescriptor = {
  filters: [
    {
      field: nameOf("PIC_Status_Name"),
      operator: "eq",
      value: ePICActiveFilterValue.Active,
    },
  ],
  logic: "and",
};
