import { DTO_WorkflowHeader } from "@app/products/property/model";
import {
  URL_SEARCH_BY_MP_ASSESSMENT_NUMBER,
  URL_SEARCH_BY_MP_ID,
  URL_SEARCH_BY_MP_VALUATION_NUMBER_FORMATTED,
} from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/constants";
import {
  eSearchMasterPropertyBy,
  IAddMasterPropertyDataForm,
  ISearchBy,
} from "@app/products/property/supplementary-rates/[id]/components/dialogs/add-master-property/model";

export const searchByData: ISearchBy[] = [
  {
    Key: eSearchMasterPropertyBy.MasterPropertyNumber,
    Value: "Master property number",
  },
  {
    Key: eSearchMasterPropertyBy.MasterPropertyId,
    Value: "Master property ID",
  },
  {
    Key: eSearchMasterPropertyBy.MasterPropertyValuationNumber,
    Value: "Master property valuation number",
  },
];

export const searchConfig = {
  typeSpeed: 500,
};

export const defaultAddMasterProperty: IAddMasterPropertyDataForm = {
  _option: {
    SearchBy: {
      Data: searchByData,
      Value: searchByData[0],
    },
    MasterProperty: {
      Data: [],
      Loading: false,
    },
  },
};

export const getUrlApiMPSearchBy = {
  [eSearchMasterPropertyBy.MasterPropertyNumber]:
    URL_SEARCH_BY_MP_ASSESSMENT_NUMBER,
  [eSearchMasterPropertyBy.MasterPropertyId]: URL_SEARCH_BY_MP_ID,
  [eSearchMasterPropertyBy.MasterPropertyValuationNumber]:
    URL_SEARCH_BY_MP_VALUATION_NUMBER_FORMATTED,
};

export const defaultWorkflowHeader: DTO_WorkflowHeader = {
  WorkflowDraft: {
    Workflow_Draft_Id: 0,
    WD_Workflow_Status: 2,
  },
  WorkflowApprovals: [],
  AvailableSecondaryWorkflows: [],
};
