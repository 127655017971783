import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import { useLocalLawsSettingStore } from "@app/products/local-laws/system-admin/settings/_category/store";
import { LLRenderSwitch } from "@app/products/local-laws/system-admin/settings/_id/components/switch/_index";
import { eLLPermit } from "@app/products/local-laws/system-admin/settings/_id/main/constant";
import { ICommonFormProps } from "@app/products/local-laws/system-admin/settings/_id/main/model";
import { useLocalLawsSettingManageStore } from "@app/products/local-laws/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const { isEditing } = useLocalLawsSettingManageStore();
  const { getConfigDataField } = useLocalLawsSettingStore();

  const eNumberFormat = ECorporateSettingsField.LLP_NumberFormat;
  const eNumberPrefixSuffix = [
    ECorporateSettingsField.LLP_NumberPrefix,
    ECorporateSettingsField.LLP_NumberSuffix,
  ];
  const eNumberLength = ECorporateSettingsField.LLP_NumberLength;
  const eDebtorNumberPrefix = ECorporateSettingsField.LLP_DebtorNumberPrefix;
  const eDefaultDebtorNo = ECorporateSettingsField.LLP_DefaultDebtorNo;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <ComboBoxGlobalSettings
          data={getConfigDataField(eNumberFormat)}
          isEditing={isEditing}
          onChange={(e: ComboBoxChangeEvent) => {
            onChange(`${eNumberFormat}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eNumberFormat}`),
            getConfigDataField(eNumberFormat)?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />

        {eNumberPrefixSuffix.map((field: ECorporateSettingsField) => (
          <InputGlobalSettings
            data={getConfigDataField(field)}
            isEditing={isEditing}
          />
        ))}

        <NumericTextboxGlobalSettings
          data={getConfigDataField(eNumberLength)}
          isEditing={isEditing}
          min={0}
        />

        <NumericTextboxGlobalSettings
          data={getConfigDataField(eLLPermit)}
          isEditing={isEditing}
          min={0}
        />

        <LLRenderSwitch
          switchList={[
            {
              eSettingsField: eDefaultDebtorNo,
            },
          ]}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />

        <InputGlobalSettings
          data={getConfigDataField(eDebtorNumberPrefix)}
          isEditing={isEditing}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
