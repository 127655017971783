import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putProponentDecision } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/proponent-decision/api";
import { IProponentDecision } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/model";
import { PSARApproveDecisionDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/approve-decision/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ProponentDecisionButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.PSARProponentResponse.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.PermitIssued &&
      !isNull(ppr?.PPRDetails) &&
      !ppr?.PPRDetails?.ProponentDecisionType_ENUM
    );
  }, [ppr]);

  const handleSubmit = async (data: IProponentDecision) => {
    if (!pprId) return;
    setIsLoading(true);
    const response = await putProponentDecision(pprId, data);
    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      setIsShowDialog(false);
      setNotifications([]);
      appNotificationStore.clearNotifications();
      setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Application approved.",
      });
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Approved application failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Proponent decision"
        onClick={onSubmit}
        name={PSARSubmitActions.ProponentDecision}
      />

      {isShowDialog && (
        <PSARApproveDecisionDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          isSubmitLoading={isLoading}
          notifications={notifications}
        />
      )}
    </>
  ) : null;
});
