import { IAttachment } from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  IAddAttachmentDialog,
  eUploadMode,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { getDocumentById } from "@app/core/documents/api";
import { postAttachment } from "@app/core/documents/components/documents-input/api";
import { IAttachmentFile } from "@app/core/documents/components/documents-input/model";
import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { ICommonFormProps } from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import ImageDefault from "@assets/img/image-default.svg";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { NoData } from "@components/no-data/NoData";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;

  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] = useState(false);

  const eMailMergeElectronicSignature =
    ECorporateSettingsField.Animals_MailMerge_ElectronicSignature_ID;
  const fieldSignature = getConfigDataField(eMailMergeElectronicSignature);
  const fieldDisplayValue = valueGetter(
    `option.ValueDisplayDetails_${eMailMergeElectronicSignature}`
  )?.[0] as IAttachment;

  const handleSaveAttachmentPhoto = async (data: IAddAttachmentDialog) => {
    setIsOpenPopup(true);
    setIsLoadingUploadPhoto(true);
    if (fieldSignature) {
      switch (data?.UploadMode) {
        case eUploadMode.UPLOAD_FILE:
          if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
            const fileEntry = await convertToFileStreamEntry(data?.File[0]);
            let newAttachment = {
              Attachment_ID: 0,
              FileEntry: fileEntry,
              Title: data.Title ?? data?.File[0]?.name,
              Category_KWD: data?.Category?.Keyword_ID ?? null,
              FileNumber: "",
              TRIMUri: "",
              AddToRecords: false,
            } as IAttachmentFile;

            const response = await postAttachment(newAttachment);

            setIsLoadingUploadPhoto(false);
            if (isSuccessIdentityPacket(response)) {
              const responseDocument = await getDocumentById(
                response.data?.ID,
                true
              );
              if (isSuccessResponse(responseDocument)) {
                onChange(
                  `option.ValueDisplayDetails_${fieldSignature.FieldName}`,
                  {
                    value: [responseDocument.data],
                  }
                );
                onChange(fieldSignature.FieldName, {
                  value: responseDocument.data?.Attachment_ID,
                });

                setIsOpenPopup(false);
                clearNotifications();
                pushNotification({
                  title: "Photo uploaded successfully",
                  type: "success",
                });
              } else {
                pushNotification({
                  autoClose: false,
                  title: "Upload photo failed",
                  type: "error",
                });
              }
            } else {
              if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
                pushNotification({
                  autoClose: false,
                  title: "Exceeded the specified size limit",
                  type: "error",
                });
              } else {
                pushNotification({
                  autoClose: false,
                  title: "Upload photo failed",
                  description: response.data?.Errors ?? response.statusText,
                  type: "error",
                });
              }
            }
            setIsOpenPopup(false);
          }
          break;
      }
    }
    setIsLoadingUploadPhoto(false);
  };

  if (!fieldSignature) return <NoData vertical />;

  return (
    <section className="cc-field-group">
      {fieldSignature ? (
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">{fieldSignature?.Title ?? ""}</label>
            <div className="cc-image-upload">
              <div className="cc-image-upload-control">
                {fieldDisplayValue?.FileEntry?.FileAttachment
                  ? isEditing && (
                      <i
                        className="fa fa-times fa-2x"
                        onClick={() => {
                          formRenderProps.onChange(
                            `option.ValueDisplayDetails_${fieldSignature?.FieldName}`,
                            {
                              value: null,
                            }
                          );
                          formRenderProps.onChange(
                            fieldSignature?.FieldName ?? "",
                            {
                              value: null,
                            }
                          );
                        }}
                        title="Remove"
                      />
                    )
                  : isEditing && (
                      <i
                        className="fa fa-upload fa-2x"
                        onClick={() => {
                          setIsOpenPopup(true);
                        }}
                        title="Upload"
                      />
                    )}
              </div>
              <img
                src={
                  fieldDisplayValue
                    ? !fieldDisplayValue?.FileEntry?.FileAttachment?.toString().includes(
                        "blob:"
                      )
                      ? `data:${fieldDisplayValue?.FileEntry?.ContentType};base64,` +
                        fieldDisplayValue.FileEntry?.FileAttachment
                      : fieldDisplayValue.FileEntry?.FileAttachment
                    : ImageDefault
                }
                className="cc-image-preview"
                alt={fieldSignature?.Title ?? "Home page logo"}
              />
            </div>
            {isOpenPopup && (
              <AddAttachmentDialog
                onClose={() => setIsOpenPopup(false)}
                onSubmit={handleSaveAttachmentPhoto}
                isLoadingButton={isLoadingUploadPhoto}
                isDisableWebLink
                isAllowMultiple={false}
                allowedFileExtensions={"jpg|png|jpeg|bmp|gif"}
              />
            )}
          </div>
        </div>
      ) : null}

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
