import { pprRoute } from "@app/products/town-planning/ppr/route";
import { pprSettingEnums } from "@app/products/town-planning/ppr/[id]/config";
import { townPlanningRoute } from "@app/products/town-planning/route";
import { useCommonProductStore } from "@common/stores/products/store";
import { getUUID } from "@common/utils/common";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useEffectOnce } from "react-use";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

export const TownPlanningRouter = observer((props: any) => {
  const {
    isProductActive,
    isLoading,
    loadSettings,
    isLoadingSetting,
    setSettings,
    responseLoadSettingError,
    settings,
  } = useCommonProductStore();

  const loadPPRSettings = useCallback(() => {
    loadSettings(pprSettingEnums);
  }, [loadSettings]);

  useEffectOnce(() => {
    loadPPRSettings();
    return () => {
      setSettings({});
    };
  });

  if (isLoading || isLoadingSetting) return <Loading isLoading isFullScreen />;
  if (responseLoadSettingError || isEmpty(settings))
    return (
      <CCLoadFailed
        responseError={responseLoadSettingError}
        onReload={() => loadPPRSettings()}
      />
    );

  return (
    <ProductRouterSwitch>
      {townPlanningRoute.children &&
        convertRouteDynamic(townPlanningRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            const fullPath = `${props.match.url}/${route.path}`;
            if (!isProductActive(fullPath)) return null;

            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={fullPath}
                  component={route.component}
                />
              )
            );
          }
        )}
      {pprRoute.children &&
        convertRouteDynamic(pprRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            const fullPath = `/${pprRoute.path}/${route.path}`;
            if (!isProductActive(fullPath)) return null;

            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={getUUID() + index}
                  path={`/${pprRoute.path}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
});
