import { loadSearchAssessment } from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/api";
import {
  readingDeviceData,
  reasonSpecialReadingData,
} from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/mock";
import {
  IMeterScheduleSpecialReadingsMeters,
  SearchType,
  textFieldMapping,
} from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/model";
import { validatorDate } from "@app/products/property/meters/list/components/action-bar/dialogs/schedule-special-readings/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
import { colScheduleSpecialReadings } from "./config";

const nameOf = nameOfFactory<IMeterScheduleSpecialReadingsMeters>();
interface IScheduleSpecialReadingsDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  initialDataDialog?: any;
}

const ScheduleSpecialReadingsDialog = ({
  onClose,
  onSubmit,
  initialDataDialog,
}: IScheduleSpecialReadingsDialogProps) => {
  const searchTypeData = Object.values(SearchType);

  return (
    <Form
      onSubmitClick={onSubmit}
      initialValues={{
        SearchType: SearchType.AssessmentNumber,
        ReadingDevice: "PDA_ALL",
        Reason: "Change of Ownership",
        _option: {
          Assessment: {
            Data: [],
            Loading: false,
          },
        },
        WhenSpecialReadingDone: new Date(),
        ...initialDataDialog,
      }}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter } = formRenderProps;
        const searchTypeValue = valueGetter("SearchType");

        const handleSearchAssessment = (event: ComboBoxFilterChangeEvent) => {
          let numLetters = 1;
          if (!searchTypeValue) return;
          if (searchTypeValue === SearchType.AssessmentNumber) numLetters = 4;
          onChange("Assessment", { value: null });
          if (event.filter.value.length >= numLetters) {
            onChange("_option.Assessment.Loading", { value: true });
            loadSearchAssessment(event.filter).then((data) => {
              onChange("_option.Assessment.Data", { value: data });
              onChange("_option.Assessment.Loading", { value: false });
            });
          } else {
            onChange("_option.Assessment.Data", { value: [] });
          }
        };

        const handleSearchAssessmentResult = (event: ComboBoxChangeEvent) => {
          if (!event.value) return;

          onChange("Assessment", { value: event.value });
          onChange("AccountName", { value: event.value?.AccountName ?? null });
          onChange("PropertyAddress", {
            value: event.value?.PropertyAddress ?? null,
          });
          onChange("Meters", {
            value: event.value?.Meters ?? [],
          });
        };

        return (
          <CCDialog
            titleHeader={"Schedule Special Reading"}
            onClose={onClose}
            maxWidth="40%"
            height="auto"
            bodyElement={
              <div className="cc-form">
                <FormElement className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">
                        Account requiring special reading
                      </label>
                      <div className="cc-custom-sub-panel-bar cc-form-cols-1">
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">Search by</label>
                            <Field
                              name={"SearchType"}
                              data={searchTypeData}
                              component={DropDownList}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Search assessment
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={"Assessment"}
                              filterable
                              suggest
                              textField={textFieldMapping[searchTypeValue]}
                              data={
                                valueGetter("_option.Assessment.Data") ?? []
                              }
                              loading={
                                valueGetter("_option.Assessment.Loading") ??
                                false
                              }
                              placeholder={`Type ${searchTypeValue}`}
                              component={CCComboBox}
                              onFilterChange={handleSearchAssessment}
                              onChange={handleSearchAssessmentResult}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Property address</label>
                            <Field
                              readOnly
                              name={"PropertyAddress"}
                              placeholder={"Property address"}
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Account name</label>
                            <Field
                              readOnly
                              name={"AccountName"}
                              placeholder={"Account name"}
                              component={CCInput}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cc-form-cols-2">
                    <div className="cc-field">
                      <label className="cc-label">
                        Reason for the special reading
                      </label>
                      <Field
                        name={"Reason"}
                        data={reasonSpecialReadingData ?? []}
                        component={CCSearchComboBox}
                        textField="Value"
                        dataItemKey="Key"
                        value={getDropdownValue(
                          valueGetter("Reason"),
                          reasonSpecialReadingData,
                          "Key"
                        )}
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange("Reason", {
                            value: event.target.value?.Key ?? null,
                          });
                        }}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        When is the special reading to be done
                      </label>
                      <Field
                        name={"WhenSpecialReadingDone"}
                        component={CCDateTimePicker}
                        format={DATETIME_FORMAT.DATETIME_CONTROL}
                        min={new Date()}
                        validator={validatorDate}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Reading device the special reading is to be allocated to
                      </label>
                      <Field
                        name={"ReadingDevice"}
                        data={readingDeviceData ?? []}
                        component={CCSearchComboBox}
                        textField="Value"
                        dataItemKey="Key"
                        value={getDropdownValue(
                          valueGetter("ReadingDevice"),
                          readingDeviceData,
                          "Key"
                        )}
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange("ReadingDevice", {
                            value: event.target.value?.Key ?? null,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Meters to be read</label>
                      <CCGrid
                        data={valueGetter("Meters") ?? []}
                        columnFields={colScheduleSpecialReadings}
                        primaryField={nameOf("MeterInstallationId")}
                        editableMode="cell"
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Comments</label>
                      <Field name="Comments" rows={3} component={CCTextArea} />
                    </div>
                  </div>
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  disabled={!formRenderProps.valid}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};

export default ScheduleSpecialReadingsDialog;
