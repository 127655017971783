import {
  DTO_DeferredDuty_Account_Transaction,
  DTO_DeferredDuty_TransactionTotals,
} from "@app/products/property/deferred-duty/model";
import { colDeferredDutyTransaction } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/config";
import { useDeferredDutyTransactionStore } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/store";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

const nameOf = nameOfFactory<DTO_DeferredDuty_Account_Transaction>();
export const DeferredDutyTransaction = observer(() => {
  const [showCancelledTransactions, setShowCancelledTransactions] =
    useState<boolean>(true);
  const [deferredDutyTransactionCol, setDeferredDutyTransactionCol] = useState<
    IColumnFields[]
  >([]);
  const { deferredDutyAccountId } = useDeferredDutyStore();
  const {
    isLoading,
    deferredDutyTransaction,
    loadDeferredDutyTransaction,
    resetStore,
    responseLoadError,
    setListSelectedTransaction,
    listSelectedTransaction,
  } = useDeferredDutyTransactionStore();

  useEffect(() => {
    deferredDutyAccountId &&
      loadDeferredDutyTransaction(
        deferredDutyAccountId,
        showCancelledTransactions
      );
    return () => {
      resetStore();
    };
  }, [
    deferredDutyAccountId,
    loadDeferredDutyTransaction,
    resetStore,
    showCancelledTransactions,
  ]);

  useEffect(() => {
    const tempCol = cloneDeep(colDeferredDutyTransaction);
    if (deferredDutyTransaction?.Transactions?.length) {
      const totals: DTO_DeferredDuty_TransactionTotals =
        deferredDutyTransaction.Totals;
      tempCol.forEach((item: IColumnFields) => {
        if (Object.keys(totals).includes(item.field)) {
          const colField =
            item.field as keyof DTO_DeferredDuty_TransactionTotals;
          if (!isNil(totals?.[colField])) {
            item.footerCell = (
              <CurrencyCell value={totals?.[colField] as number} />
            );
          }
        }
      });
    }
    setDeferredDutyTransactionCol(tempCol);
  }, [deferredDutyTransaction]);

  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        deferredDutyAccountId &&
          loadDeferredDutyTransaction(
            deferredDutyAccountId,
            showCancelledTransactions
          );
      }}
    />
  ) : (
    <div className="cc-deferred-duty-transactions">
      <div className="cc-grid-control-left">
        <Checkbox
          className="cc-control-item"
          title="Show Cancelled Transactions"
          label="Show cancelled transactions"
          checked={showCancelledTransactions}
          onChange={(event: CheckboxChangeEvent) =>
            setShowCancelledTransactions(event.value)
          }
        />
      </div>

      <CCGrid
        isLoading={isLoading}
        data={deferredDutyTransaction?.Transactions ?? []}
        columnFields={deferredDutyTransactionCol}
        primaryField={nameOf("AccountTransactionId")}
        selectedRows={listSelectedTransaction}
        selectableMode="multiple"
        onSelectionChange={(data) => {
          setListSelectedTransaction(data);
        }}
        state={{
          sort: [
            { field: nameOf("TransactionDate"), dir: "desc" },
            { field: nameOf("AccountTransactionId"), dir: "desc" },
          ],
        }}
      />
    </div>
  );
});
