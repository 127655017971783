import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { mockDOTPositionPSAR } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/assessment/mock";
import {
  Application,
  PPRApplicationDetails,
} from "@app/products/town-planning/ppr/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";

interface ITownPlanningFormProps {
  formRenderProps: FormRenderProps;
  isDisable?: boolean;
}
const nameOf = nameOfFactory<Application>();
export const HearingDetail = ({
  formRenderProps,
  isDisable,
}: ITownPlanningFormProps) => {
  const [isLoading] = useState(false);
  const { valueGetter } = formRenderProps;

  const isVisibleDOTHearingSubmissionPosition = () => {
    const status = valueGetter(nameOf("Status_ENUM")) as Application_Status;
    const pprDetail = valueGetter(
      nameOf("PPRDetails")
    ) as PPRApplicationDetails;
    switch (status) {
      case Application_Status.ApplicationComplete:
        if (
          status === Application_Status.ApplicationComplete &&
          pprDetail?.PanelHearingDecisionDate
        ) {
          return true;
        }
        break;
      case Application_Status.PanelReportSentForApproval:
      case Application_Status.PanelReportApprovedByTeamLeader:
      case Application_Status.SentReportForPanelHearing:
        return true;
    }

    return false;
  };

  const displayDOTHearingSubmissionCreated = () => {
    const panelHearingReportCreatedDate = valueGetter(
      "PPRDetails.PanelHearingReportCreated"
    );
    if (panelHearingReportCreatedDate) {
      return `${formatDisplayValue(
        panelHearingReportCreatedDate,
        DATE_FORMAT.DATE
      )} (${
        valueGetter("PPRDetails.OfficerInCharge_DOTHearingSubmissionCreated") ??
        ""
      })`;
    }

    return "";
  };

  const displayDOTHearingSubmissionSent = () => {
    const panelReportSentToTLDate = valueGetter(
      "PPRDetails.PanelReportSentToTL"
    );
    if (panelReportSentToTLDate) {
      return `${formatDisplayValue(
        panelReportSentToTLDate,
        DATE_FORMAT.DATE
      )} (${
        valueGetter("PPRDetails.OfficerInCharge_DOTHearingSubmissionSent") ?? ""
      })`;
    }

    return "";
  };

  const displayDOTHearingSubmissionSentToTL = () => {
    const panelReportTLDecisionDate = valueGetter(
      "PPRDetails.PanelReportTLDecision"
    );
    if (panelReportTLDecisionDate) {
      return `${formatDisplayValue(
        panelReportTLDecisionDate,
        DATE_FORMAT.DATE
      )} (${
        valueGetter(
          "PPRDetails.OfficerInCharge_DOTHearingSubmissionSentToTL"
        ) ?? ""
      })`;
    }

    return "";
  };

  const displayDOTHearingSubmissionFinalised = () => {
    const panelReportTLDecisionDate = valueGetter(
      "PPRDetails.ReportSentToPanelHearing"
    );
    if (panelReportTLDecisionDate) {
      return `${formatDisplayValue(
        panelReportTLDecisionDate,
        DATE_FORMAT.DATE
      )}`;
    }

    return "";
  };

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Directions hearing</label>
          <Field
            name={"PPRDetails.DirectionsHearingDate"}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={isDisable}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">DTP attending</label>
          <Field
            name={"PPRDetails.Flag_DirectionsHearingDotToAttend"}
            component={CCSwitch}
            checked={valueGetter(
              "PPRDetails.Flag_DirectionsHearingDotToAttend"
            )}
            disabled={isDisable}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Request to be heard panel</label>
          <Field
            name={"PPRDetails.RequestToHeardPanel"}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={isDisable}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">DTP attending</label>
          <Field
            name={"PPRDetails.Flag_RequestHeardPanelDotToAttend"}
            component={CCSwitch}
            checked={valueGetter(
              "PPRDetails.Flag_RequestHeardPanelDotToAttend"
            )}
            disabled={isDisable}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">Public hearing: day 1</label>
          <Field
            name={"PPRDetails.PublicHearing"}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={isDisable}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">DTP attending</label>
          <Field
            name={"PPRDetails.Flag_PublicHearingDotToAttend"}
            component={CCSwitch}
            checked={valueGetter("PPRDetails.Flag_PublicHearingDotToAttend")}
            disabled={isDisable}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">DTP submission scheduled</label>
          <Field
            name={"PPRDetails.DOTSubmissionScheduled"}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={isDisable}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Planner hearing report due</label>
          <Field
            name={"PPRDetails.PlannerHearingReportDue"}
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            disabled={isDisable}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <CCValueField
          label="DTP submission created"
          value={displayDOTHearingSubmissionCreated()}
        />
        <CCValueField
          label="DTP submission sent"
          value={displayDOTHearingSubmissionSent()}
        />
        <CCValueField
          label="DTP submission sent to delegated approver"
          value={displayDOTHearingSubmissionSentToTL()}
        />
        {isVisibleDOTHearingSubmissionPosition() && (
          <div className="cc-field">
            <label className="cc-label">DTP hearing submission position</label>
            <Field
              name={"PPRDetails.DOTHearingSubmissionPosition_ENUM"}
              textField="Value"
              dataItemKey="Code"
              data={mockDOTPositionPSAR}
              component={CCSearchComboBox}
              isUseDefaultOnchange
              disabled={isDisable}
            />
          </div>
        )}
        <CCValueField
          label="DTP submission finalised"
          value={displayDOTHearingSubmissionFinalised()}
        />
      </div>
    </section>
  );
};
