import { postSaveDirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/[id]/components/buttons/save/api";
import { checkIsInactiveDirectDebitAuthorisations } from "@app/products/direct-debit/authorisations/[id]/util";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const SaveButtonDirectDebitAuthorisation = observer(
  ({ disabled }: { disabled?: boolean }) => {
    //#region STORE ========/
    const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
      useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion HOOK =====/

    const slider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const directDebitAuthorisation: DirectDebitAuthorisation =
              initialData;
            return postSaveDirectDebitAuthorisation(directDebitAuthorisation);
          },
          handleSuccess: async () => {
            await fetchApiByAlias("manageDirectDebitAuthorisationSlider");
            pushNotificationPortal({
              title: "Direct debit authorisations saved successfully.",
              type: "success",
            });
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "The authorisations could not be saved.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const directDebitAuthorisations: DirectDebitAuthorisation =
        await submitFormGetData("GeneralForm");

      if (directDebitAuthorisations) {
        setIsLoadingForm(true);
        await slider.fetchApi({
          initialData: directDebitAuthorisations,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={
          disabled ||
          isLoadingForm ||
          checkIsInactiveDirectDebitAuthorisations()
        }
      />
    );
  }
);
