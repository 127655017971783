import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  colClaimSummaryTotals,
  colRebateTransactionIncludeInClaim,
} from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/child-screens/general/components/form-element/config";
import { ViewConfiguration } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { IColumnFields } from "@components/cc-grid/model";
import { DTO_RebateClaim_Summary } from "./model";

export type IPropertyAssessmentNoticesAndConfigsColumn =
  | [
      IColumnFields[] | APIResponseError,
      IColumnFields[] | APIResponseError,
      APIResponse<DTO_RebateClaim_Summary | undefined>
    ]
  | IColumnFields
  | APIResponseError
  | APIResponse<DTO_RebateClaim_Summary>
  | undefined;

export const getRebateClaimSummaryByIdAndViewConfigs = async (
  rebateClaimId: number
): Promise<IPropertyAssessmentNoticesAndConfigsColumn> => {
  try {
    return await Promise.all([
      loadViewConfiguresColumns(
        ViewConfiguration.RebateClaim_Transactions,
        colRebateTransactionIncludeInClaim
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.RebateClaim_Summary,
        colClaimSummaryTotals
      ),
      CoreAPIService.getClient().get<DTO_RebateClaim_Summary>(
        `api/property/internal/rebate/${rebateClaimId}/summary`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
