import { history } from "@/AppRoutes";
import { appealedButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appealed/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { mapRecordType, RECORDTYPE } from "@common/constants/recordtype";
import { DBRowState } from "@common/models/baseClassStandard";
import { IdentityPacket } from "@common/models/identityPacket";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import {
  getAppeal,
  getAppealLovs,
  getParentSummary,
  putAppeal,
} from "@common/pages/appeal/_id/api";
import { APPEAL_HEARING_ROUTE } from "@common/pages/appeal/_id/appeal-hearing/_id/constant";
import { approveLetterAppealButtonStore } from "@common/pages/appeal/_id/components/buttons/workflow/approve-letter/store";
import { rejectLetterAppealButtonStore } from "@common/pages/appeal/_id/components/buttons/workflow/reject-letter/store";
import { appealSendForApprovalButtonStore } from "@common/pages/appeal/_id/components/buttons/workflow/send-for-approval/store";
import { appealSendLetterToVcatApplicantButtonStore } from "@common/pages/appeal/_id/components/buttons/workflow/send-letter-to-vcat-applicant/store";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import {
  Ancestor,
  AppealSubmitActions,
  appealSubmitWithValidateActions,
  IAppeal,
  IAppealLOVs,
  IDisplayDecision,
  IParentAppealSection,
} from "@common/pages/appeal/_id/model";
import { processDataAppeal } from "@common/pages/appeal/_id/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { isArray, isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AppealStore {
  private _appeal?: IAppeal = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];
  private _appealId?: number = undefined;
  private _parent?: any = undefined;
  private _ancestor?: Ancestor = undefined;
  private _parentDecision?: IDisplayDecision = undefined;
  private _lovs?: IAppealLOVs = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _isInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get appeal() {
    return this._appeal;
  }
  setAppeal = (appeal?: IAppeal | undefined) => {
    runInAction(() => {
      this._appeal = appeal;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get notifications() {
    return this._notifications;
  }
  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  get parent() {
    return toJS(this._parent);
  }
  setParent = (parent?: IParentAppealSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get parentDecision() {
    return this._parentDecision;
  }
  setParentDecision = (parentDecision?: IDisplayDecision) => {
    runInAction(() => {
      this._parentDecision = parentDecision;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get appealId() {
    return toJS(this._appealId);
  }
  setAppealId = (appealId?: number) => {
    runInAction(() => {
      this._appealId = appealId;
    });
  };

  get lovs() {
    return this._lovs;
  }
  setLovs = (lovs?: IAppealLOVs) => {
    runInAction(() => {
      this._lovs = lovs;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._appeal = undefined;
      this._isLoading = false;
      this._notifications = [];
      this._responseLoadError = undefined;
      this._appealId = undefined;
      this._parent = undefined;
      this._ancestor = undefined;
      this._onSubmit = undefined;
      this._lovs = undefined;
    });
  };

  reLoadAppeal = async (): Promise<boolean> => {
    if (this.appealId) return await this.loadAppeal(this.appealId);
    return false;
  };

  loadParent = async (parent: IParentAppealSection, isNew?: boolean) => {
    this.setParent(parent);
    let errorResponse = undefined;
    let newLovs = undefined;
    const lovResponse = await getAppealLovs();
    if (isSuccessResponse(lovResponse)) {
      newLovs = lovResponse.data;
    } else {
      errorResponse = {
        status: lovResponse.status,
        error: lovResponse.error,
      };
    }
    this.setLovs(newLovs);
    this.setResponseLoadError(errorResponse);
    const response = await getParentSummary(parent?.id, parent?.recordType);
    if (isArray(response)) {
      const [parentSummary, decision] = response;
      if (isNew) {
        this.setAppeal({
          ...parent?.data,
        } as IAppeal);
      }
      this.setAncestor(parentSummary.data);
      this.setParentDecision(decision.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load application data failed",
      });
      return false;
    }
  };

  loadAppeal = async (
    appealId: number,
    isNew: boolean = false,
    parent?: IParentAppealSection,
    notificationSaveAppeal: string[] = []
  ): Promise<boolean> => {
    if (isNew && parent) {
      this.setIsLoading(true);
      const response = await this.loadParent(parent, true);
      this.setIsLoading(false);
      return response;
    }
    if (appealId === undefined) return false;
    this.setIsLoading(true);
    let newAppeal = undefined;
    let errorResponse = undefined;
    const response = await getAppeal(appealId);
    if (isSuccessResponse(response)) {
      newAppeal = processDataAppeal(response.data);
      let titleNotifications = "Appeal created";
      if (!isNew) titleNotifications = "Appeal updated";
      if (notificationSaveAppeal.length) {
        appNotificationStore.pushNotification({
          title: titleNotifications,
          type: "warning",
          autoClose: false,
          description: notificationSaveAppeal,
        });
      }
      if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
        this.setIsInactive(true);
        appNotificationStore.clearNotifications();
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Important Note",
          type: "info",
          description:
            "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
        });
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    const sourceIdentifier = newAppeal?._BubbleUps.find(
      (bubbleUp: BubbleUpIdentifier) =>
        bubbleUp.BubbleUpType_ENUM === BubbleUpType.Parent
    )?.SourceIdentifier;
    if (sourceIdentifier) {
      const newParent = {
        id: sourceIdentifier._RecordSource_ID,
        recordType:
          mapRecordType[sourceIdentifier._RecordSourceType_ENUM] ??
          RECORDTYPE.TP_PPRApplication,
      };
      await this.loadParent(newParent, false);
    }

    this.setAppeal(newAppeal);
    this.setAppealId(newAppeal?.Appeal_ID);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };

  saveAppeal = async (
    appeal: IAppeal,
    action: AppealSubmitActions,
    isNew: boolean = false
  ) => {
    //Save appeal
    this.setIsLoading(true);
    if (appealSubmitWithValidateActions.includes(action)) {
      await this.validateSave(action, appeal);
    } else {
      const { recordType, id } = this.parent;
      appNotificationStore.clearNotifications();
      const response = await putAppeal(appeal, id, recordType);
      if (isSuccessResponse(response)) {
        if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
          if (response.data?.ID) {
            await this.loadAppeal(
              response.data?.ID,
              isNew,
              this.parent,
              response.data?.Notifications
            );
          }
          await this.runActions(action, response.data);
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Appeal could not be saved",
            type: "error",
            description: response.data?.Errors,
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Appeal could not be saved",
          type: "error",
          description: response.data?.Errors,
        });
      }
      this.setIsLoading(false);
    }
  };

  runActions = async (action: AppealSubmitActions, data: IdentityPacket) => {
    let parentActionRes = undefined;
    if (this.parent && this.parent.parentAction) {
      parentActionRes = false;
      switch (this.parent.parentAction) {
        case PPRSubmitActions.Appealed:
          parentActionRes = await appealedButtonStore.appealed(
            this.parent.id,
            data
          );
          break;
        default:
          break;
      }
      if (parentActionRes) {
        this.setParent({ ...this.parent, parentAction: undefined });
      }
    }

    switch (action) {
      case AppealSubmitActions.Save:
        appNotificationStore.pushNotification({
          title: "Appeal updated successfully",
          type: "success",
        });
        break;
      case AppealSubmitActions.New:
        if (parentActionRes !== true) {
          appNotificationStore.pushNotification({
            title: "Appeal created successfully",
            type: "success",
            onClose: () => {
              history.replace(`${APPEAL_ROUTE}/${data.ID}`);
            },
          });
        }
        break;
      case AppealSubmitActions.AdditionalHearing:
        history.push(`${window.location.pathname}${APPEAL_HEARING_ROUTE}/new`);
        break;
      case AppealSubmitActions.ApproveLetter:
        if (this.appealId) {
          approveLetterAppealButtonStore.approveLetterAppealRequest(
            this.appealId
          );
        }
        break;
      case AppealSubmitActions.RejectLetter:
        if (this.appealId)
          rejectLetterAppealButtonStore.rejectLetterAppealRequest(
            this.appealId
          );
        break;
      default:
        break;
    }
  };

  validateSave = async (action: AppealSubmitActions, appeal?: IAppeal) => {
    switch (action) {
      case AppealSubmitActions.AppealSendForApproval:
        if (appeal) {
          await appealSendForApprovalButtonStore.handleSendForApproval(appeal);
        }
        break;
      case AppealSubmitActions.SendLetterToVcatApplicant:
        if (appeal) {
          appealSendLetterToVcatApplicantButtonStore.handleSendLetterToVcatApplicant(
            appeal
          );
        }
        break;
      default:
        break;
    }
  };
}

export const appealStore = new AppealStore();
const appealStoreContext = createContext(appealStore);
export const useAppealStore = () => {
  return useContext(appealStoreContext);
};
