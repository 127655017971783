import { history } from "@/AppRoutes";
import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { IParentAnimalsRegistrationSection } from "@app/products/animals/[id]/model";
import { AnimalType } from "@app/products/animals/model";
import {
  getAnimalPoundRegisterById,
  getAnimalPoundRegisterInfo,
  postAnimalPoundRegister,
  postWorkflowClosure,
  postWorkflowLodgeRegistration,
  poundRegisterHandler,
} from "@app/products/animals/pound-register/[id]/api";
import { POUND_REGISTER_ROUTE } from "@app/products/animals/pound-register/[id]/constant";
import {
  IParentPoundRegisterSection,
  PoundRegister,
  PoundRegisterHandlerRequest,
  PoundRegisterSubmitActions,
  Svc_FormAction_PoundRegister,
  Svc_PoundRegisterLOVs,
} from "@app/products/animals/pound-register/[id]/model";
import { DBRowState } from "@app/products/crms/[id]/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil, toLower } from "lodash";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

class AnimalPoundRegisterStore {
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _isDeleted: boolean = false;
  private _isSuperAdmin: boolean = false;
  private _isFormModified: boolean = false;
  private _isLoadingSuperAdmin: boolean = false;
  private _ownerReadOnly: boolean = false;
  private _OSFee: number = 0;
  private _parent?: IParentPoundRegisterSection = undefined;
  private _poundRegisterInfo?: PoundRegister = undefined;
  private _poundRegisterLOVs?: Svc_PoundRegisterLOVs = undefined;
  private _poundRegisterBreedLOVs?: IKeyValuePacket[] = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _responseDetailLoadError?: APIResponseError = undefined;

  //For Workflow
  private _isLoadingWorkflow: boolean = false;
  private _isShowWorkflowClosure: boolean = false;

  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  //#region Store Property Set/Get
  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get responseDetailLoadError() {
    return toJS(this._responseDetailLoadError);
  }
  setResponseDetailLoadError = (responseDetailLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseDetailLoadError = responseDetailLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoading;
    });
  };

  get isLoadingSuperAdmin() {
    return this._isLoadingSuperAdmin;
  }
  setIsLoadingSuperAdmin = (isLoadingSuperAdmin: boolean) => {
    runInAction(() => {
      this._isLoadingSuperAdmin = isLoadingSuperAdmin;
    });
  };

  get isSuperAdmin() {
    return toJS(this._isSuperAdmin);
  }
  setIsSuperAdmin = (isSuperAdmin: boolean) => {
    runInAction(() => {
      this._isSuperAdmin = isSuperAdmin;
    });
  };

  get parent() {
    return toJS(this._parent);
  }
  setParent = (parent?: IParentPoundRegisterSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get ownerReadOnly() {
    return toJS(this._ownerReadOnly);
  }
  setOwnerReadOnly = (ownerReadOnly: boolean) => {
    runInAction(() => {
      this._ownerReadOnly = ownerReadOnly;
    });
  };

  get OSFee() {
    return this._OSFee;
  }
  setOSFee = (OSFee: number) => {
    runInAction(() => {
      this._OSFee = OSFee;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get isDeleted() {
    return this._isDeleted;
  }
  setIsDeleted = (isDeleted: boolean) => {
    runInAction(() => {
      this._isDeleted = isDeleted;
    });
  };

  get poundRegisterInfo() {
    return this._poundRegisterInfo;
  }
  setPoundRegisterInfo = (poundRegisterInfo?: PoundRegister) => {
    runInAction(() => {
      this._poundRegisterInfo = poundRegisterInfo;
    });
  };

  get poundRegisterLOVs() {
    return this._poundRegisterLOVs;
  }
  setPoundRegisterLOVs = (poundRegisterLOVs?: Svc_PoundRegisterLOVs) => {
    runInAction(() => {
      this._poundRegisterLOVs = poundRegisterLOVs;
    });
  };

  get poundRegisterBreedLOVs() {
    return this._poundRegisterBreedLOVs;
  }
  setPoundRegisterBreedLOVs = (_poundRegisterBreedLOVs?: IKeyValuePacket[]) => {
    runInAction(() => {
      this._poundRegisterBreedLOVs = _poundRegisterBreedLOVs;
    });
  };

  get poundRegisterId() {
    return toJS(this._poundRegisterInfo?.Register_ID);
  }
  //For Workflow
  get isLoadingWorkflow() {
    return this._isLoadingWorkflow;
  }
  setIsLoadingWorkflow = (isLoadingWorkflow: boolean) => {
    runInAction(() => {
      this._isLoadingWorkflow = isLoadingWorkflow;
    });
  };

  get isShowWorkflowClosure() {
    return this._isShowWorkflowClosure;
  }
  setIsShowWorkflowClosure = (isShowWorkflowClosure: boolean) => {
    runInAction(() => {
      this._isShowWorkflowClosure = isShowWorkflowClosure;
    });
  };
  //#endregion

  //#region Store Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isLoadingDetails = false;
      this._isSuperAdmin = false;
      this._isDeleted = false;
      this._isLoadingSuperAdmin = false;
      this._ownerReadOnly = false;
      this._OSFee = 0;
      this._parent = undefined;
      this._poundRegisterInfo = undefined;
      this._poundRegisterLOVs = undefined;
      this._poundRegisterBreedLOVs = undefined;
      this._responseLoadError = undefined;
      this._responseDetailLoadError = undefined;
      //Workflow
      this._isLoadingWorkflow = false;
      this._isShowWorkflowClosure = false;
    });
  };

  runActions = (
    action: PoundRegisterSubmitActions,
    response?: APIResponse,
    objPound?: PoundRegister
  ) => {
    switch (action) {
      case PoundRegisterSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Pound register saved successfully.",
          type: "success",
        });
        break;
      case PoundRegisterSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${POUND_REGISTER_ROUTE}/${response?.data?.ID}`);
        break;
      case PoundRegisterSubmitActions.AddRegister:
        appNotificationStore.clearErrorNotification();
        history.push(`${ANIMALS_ROUTE}/new`, {
          parent: {
            poundId: this.poundRegisterId,
          } as IParentAnimalsRegistrationSection,
        });
        break;
      case PoundRegisterSubmitActions.LodgeRegistration:
        this.handleWorkflowLodgeRegistration(objPound);
        break;
      default:
        break;
    }
  };
  //#endregion

  //#region Store Function
  checkIsSuperAdmin = async (userID: number) => {
    this.setIsLoadingSuperAdmin(true);
    const response = await postCheckIsSuperAdmin(userID);
    if (isSuccessResponse(response)) {
      this.setIsSuperAdmin(response.data ?? false);
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    this.setIsLoadingSuperAdmin(false);
  };

  loadPoundRegisterInfo = async (id: number, isNew?: boolean) => {
    appNotificationStore.clearNotifications();
    let errorResponse = undefined;
    this.setResponseLoadError(undefined);
    this.setIsLoading(true);
    this.setIsLoadingDetails(true);
    const response = await getAnimalPoundRegisterInfo(isNew ? 0 : id);
    if (Array.isArray(response)) {
      const [poundRegisterInfo, poundRegisterLOVs] = response;
      this.setPoundRegisterLOVs(poundRegisterLOVs.data);

      if (poundRegisterInfo?.data?.ReturnObj?.PoundRegister) {
        const returnObj = poundRegisterInfo.data.ReturnObj.PoundRegister;
        this.setPoundRegisterInfo(returnObj);
        await this.processFromParent(returnObj);
        this.firstLoadViewState();
        this.setOSFee(poundRegisterInfo.data.ReturnObj?.OSFee ?? 0);
      }

      if (!isNew && poundRegisterInfo?.data?.ReturnObj?.PoundRegister) {
        if (
          poundRegisterInfo?.data?.ReturnObj?.PoundRegister.Sys_DBRowState ===
          DBRowState.Inactive
        ) {
          this.setIsDeleted(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsDeleted(false);
        }
      }

      if (this.parent?.notification) {
        this.parent?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParent({ ...this.parent, notification: [] });
      }
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error,
      };
    }
    if (errorResponse) this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    this.setIsLoadingDetails(false);
  };

  reloadPoundRegister = async () => {
    if (this.poundRegisterId) {
      return await this.loadPoundRegisterInfo(this.poundRegisterId);
    }
    return false;
  };

  savePoundRegister = async (
    data: PoundRegister,
    action: PoundRegisterSubmitActions
  ) => {
    if (action === PoundRegisterSubmitActions.LodgeRegistration) {
      this.runActions(action, undefined, data);
    } else {
      if (action === PoundRegisterSubmitActions.Save) this.setIsLoading(true);
      const response = await postAnimalPoundRegister(data);
      this.setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        if (this.poundRegisterId) await this.reloadPoundRegister();
        this.runActions(action, response);
      } else {
        const responseError = response as APIResponse;
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Save pound register failed.",
          type: "error",
          description: responseError.data?.Errors ?? response?.statusText,
        });
      }
    }
  };

  loadDetails = async (id: number) => {
    this.setIsLoadingDetails(true);
    this.setResponseDetailLoadError(undefined);
    const response = await getAnimalPoundRegisterById(id);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setPoundRegisterInfo(response.data.ReturnObj.PoundRegister);
      this.setOSFee(response?.data?.ReturnObj?.OSFee ?? 0);
    } else {
      this.setResponseDetailLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };

  loadPoundRegisterHandle = async (
    params: PoundRegisterHandlerRequest,
    errorMsg: string
  ) => {
    if (params.FormAction === Svc_FormAction_PoundRegister.Form_PickAnimal)
      this.setIsLoading(true);
    const response = await poundRegisterHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      const poundRegister = response.data.ReturnObj.PoundRegister;
      if (params.FormAction === Svc_FormAction_PoundRegister.Form_PickAnimal)
        this.populateBreeds(
          poundRegister?.AnimalType_ID,
          poundRegister?.AnimalType
        );
      this.setPoundRegisterInfo(poundRegister);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
    this.setIsLoading(false);
  };

  handleWorkflowLodgeRegistration = async (objPound?: PoundRegister) => {
    if (isNil(objPound)) return;
    //Save form first
    this.setIsLoading(true);
    const responseSave = await postAnimalPoundRegister(objPound);
    if (isSuccessIdentityPacket(responseSave)) {
      if (this.poundRegisterId) {
        const responseLodgeRegistration = await postWorkflowLodgeRegistration(
          this.poundRegisterId
        );
        if (isSuccessIdentityPacket(responseLodgeRegistration)) {
          await this.reloadPoundRegister();
        } else {
          const responseError = responseLodgeRegistration as APIResponse;
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Lodge registration failed.",
            type: "error",
            description:
              responseError.data?.Errors ??
              responseLodgeRegistration?.statusText,
          });
        }
      }
    } else {
      const responseError = responseSave as APIResponse;
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save pound register failed.",
        type: "error",
        description: responseError.data?.Errors ?? responseSave?.statusText,
      });
    }
    this.setIsLoading(false);
  };

  handleWorkflowClosure = async (
    poundRegister: PoundRegister,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsLoadingWorkflow(true);
    const response = await postWorkflowClosure({
      PoundRegister: poundRegister,
      ProvideReason: provideReason,
    });
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setPoundRegisterInfo(response.data.ReturnObj);
      this.setIsShowWorkflowClosure(false);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Closed successfully.",
      });
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Close failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoadingWorkflow(false);
  };
  //#endregion

  //#region State Load
  firstLoadViewState = () => {
    if (!isNil(this.poundRegisterInfo)) {
      // Restricted breed logic
      if (!isNil(this.poundRegisterInfo.AnimalsRegistration_ID)) {
        this.setOwnerReadOnly(true);
      }
      this.populateBreeds(
        this.poundRegisterInfo?.AnimalType_ID,
        this.poundRegisterInfo?.AnimalType
      );
    }
  };

  populateBreeds = (animalTypeId?: number, animalType?: AnimalType) => {
    if (animalTypeId && animalType) {
      if (toLower(animalType?.Name).includes("cat")) {
        this.setPoundRegisterBreedLOVs(this.poundRegisterLOVs?.CatBreeds ?? []);
      } else if (toLower(animalType?.Name).includes("dog")) {
        this.setPoundRegisterBreedLOVs(this.poundRegisterLOVs?.DogBreeds ?? []);
      } else {
        this.setPoundRegisterBreedLOVs(
          this.poundRegisterLOVs?.OtherBreeds ?? []
        );
      }
    }
  };

  processFromParent = async (poundRegisterInfo: PoundRegister) => {
    if (this.parent?.newAnimalsId && !isNil(poundRegisterInfo)) {
      let params: PoundRegisterHandlerRequest = {
        FormAction: Svc_FormAction_PoundRegister.Form_PickAnimal,
        PoundRegister: poundRegisterInfo,
        PoundRegisterArgs: this.parent?.newAnimalsId ?? 0,
      };

      await this.loadPoundRegisterHandle(
        params,
        "Added animal registration failed."
      );
    }
    return poundRegisterInfo;
  };
  //#endregion
}

const animalPoundRegisterStoreContext = createContext(
  new AnimalPoundRegisterStore()
);
export const useAnimalPoundRegisterStore = () => {
  return useContext(animalPoundRegisterStoreContext);
};
