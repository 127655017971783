import { MailMerge_SelectMergeDocument } from "@app/core/new-mail-merge/dialogs/model";

export class UserCredentials {
  Contact_ID: number = 0;
  Domain?: string;
  UserName?: string;
  Password?: string;
  Title?: string;
  Header?: string;
  MailMergeSetup: MailMerge_SelectMergeDocument =
    new MailMerge_SelectMergeDocument();

  constructor() {
    this.Domain = "";
    this.UserName = "";
    this.Password = "";
  }
}
