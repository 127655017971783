export const mockWasteWater = [
  {
    ID: "1",
    PermitNumber: 40984,
    Applicant: "Rozella Ernser",
    Owner: "Raleigh Smitham",
    Address: "Apt. 363",
    Suburb: "Pennsylvania",
    AssessmentNo: 45753,
    Status: "Complied",
    TypeOfApplication: "Health",
    FileNumber: 66279,
    SystemName: "feed",
    SystemType: "hard drive",
    Received: new Date("2021-01-27T04:28:10.461Z"),
    PTI: new Date("2021-01-26T20:01:40.931Z"),
    CTU: "CTU 1",
    WaterAuthority: "WaterAuthority 1",
    Plumber: "Ferne Brown",
    Risk: "Risk Level 1",
    CertificateNo: 10484,
    SerialNo: "18301",
    Details: "Home Loan Account",
    ApplicantAddress: "77419 Travis Village",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: 482.0,
    GPS: 523.0,
    Manufacturer: "Parisian Group",
    TankSize: 974.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Fadel and Sons",
    SecondarySystemType: "Home",
    SecondaryManufacturer: "Jerde LLC",
    SecondaryTankSize: 51.2,
    UnitSecondary: "UnitSecondary 1",
    TertiarySystemType: "Garden",
    TertiaryManufacturer: "Frami Group",
    TertiaryTankSize: 44.0,
    UnitTertiary: "UnitTertiary 1",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 1",
    Contractor: "Devin_Bogisich",
    RiskLevel: "RiskLevel 1",
    PermitType: "PermitType 1",
    ConstructionType: "ConstructionType 1",
    DebtorNo: 12116,
    FileNo: 56896,
    Issued: new Date("2020-09-01T15:42:36.248Z"),
    IssuingOfficer: "Frida",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2021-01-19T08:55:18.640Z"),
    ApprovalOfficer: "Rupert Herzog",
    OutstandingFees: "923.00",
    OSFees: "897.00",
    ComplainceCertNo: 51887,
    ApprovalForBL: new Date("2020-09-15T16:43:53.683Z"),
    BLicenceNumber: 75955,
    SentToDoH: new Date("2020-07-22T08:29:16.175Z"),
    DoHApproval: "DoHApproval 1",
    DateDoHApproved: new Date("2020-12-25T04:03:33.997Z"),
    DoHApprovalNo: "DoHApprovalNo 1",
    SerialNumber: 41386,
    LastInspection: new Date("2020-03-18T17:51:28.817Z"),
    Frequency: 99273,
    NextInspection: new Date("2021-12-13T07:54:50.375Z"),
    LastService: new Date("2020-09-17T15:00:10.330Z"),
    LastDesludge: new Date("2020-09-10T05:20:39.585Z"),
    LastSample: new Date("2020-09-27T13:07:50.036Z"),
    NextService: new Date("2021-06-02T15:01:00.587Z"),
    NextDesludge: new Date("2021-07-08T19:07:52.199Z"),
    NextSample: new Date("2021-11-16T22:54:59.710Z"),
    Comments: "We need to override the digital JSON bandwidth!",
    Size: "11.00",
    DateInspected: new Date("2020-02-17T16:34:57.778Z"),
    InspectionType: "Orchestrator",
    Officer: "Jordi Herzog",
    DateInspectedMonth: "December",
    DueStatus: "open-source",
    Material: "Plastic",
    InspectionFrequency: 5741,
    ReferenceNumber: "16139",
    ContactName: "Wellington",
    Compliance: "firewall",
    Reason: "You can't copy the array without generating the mobile CSS alarm!",
    RefNo: "35174",
    Description:
      "Use the open-source JSON application, then you can generate the multi-byte interface!",
    DateTarget: new Date("2020-12-31T13:18:15.418Z"),
    Source: "Electronics",
    Category: "Sleek",
    Location: "Congo",
    RequestedBy: "Vena",
    ContactDetails: "Gislason",
    DateRecorded: new Date("2021-01-26T14:05:21.978Z"),
    RecordedBy: "Hyman Kirlin",
    OrgStructure: "exploit",
    ActionOfficer: "Rashawn Barton",
    Coordinator: "Jacklyn O'Kon",
    DateWork: new Date("2021-01-09T03:42:38.438Z"),
    EnquiryRefNo: "58475",
    Event_ReferenceNoAdditional: "33005",
    OnBehalfOf: "Branding",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-09-29T00:07:11.416Z"),
    Type: "capacitor",
    DateDue: new Date("2020-12-30T01:31:24.772Z"),
    DateRevoked: new Date("2021-01-26T19:04:08.667Z"),
    Noticee: "Maximillia",
    CreatedDate: "2020-04-08T13:26:24.007Z",
    FeeType: "Checking Account",
    AmountIssued: "176.00",
    AmountOutstanding: 69306,
    CostCode: "94666",
    InvoiceNumber: 46840,
    Name: "Romaguera - Trantow",
    SystemTypeClassification: "Concrete",
    SortIndex: 98383,
    Title: "Senior",
    Condition: "Division Singapore",
  },
  {
    ID: "2",
    PermitNumber: 16084,
    Applicant: "Anastacio Rempel",
    Owner: "Jody Quitzon",
    Address: "Apt. 142",
    Suburb: "Oklahoma",
    AssessmentNo: 66813,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 3507,
    SystemName: "card",
    SystemType: "transmitter",
    Received: new Date("2021-01-26T07:36:07.121Z"),
    PTI: new Date("2021-01-26T16:03:59.198Z"),
    CTU: "CTU 2",
    WaterAuthority: "WaterAuthority 2",
    Plumber: "Betsy Will DDS",
    Risk: "Risk Level 2",
    CertificateNo: 51166,
    SerialNo: "55039-4401",
    Details: "Vista",
    ApplicantAddress: "59091 Gerhold Run",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "7.00",
    GPS: "524.00",
    Manufacturer: "Wiza, Stiedemann and Grant",
    TankSize: 763.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Lehner - Beatty",
    SecondarySystemType: "Grocery",
    SecondaryManufacturer: "Hirthe, Mraz and Lakin",
    SecondaryTankSize: "175.00",
    UnitSecondary: "UnitSecondary 2",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Lockman and Sons",
    TertiaryTankSize: "916.00",
    UnitTertiary: "UnitTertiary 2",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 2",
    Contractor: "Hipolito4",
    RiskLevel: "RiskLevel 2",
    PermitType: "PermitType 2",
    ConstructionType: "ConstructionType 2",
    DebtorNo: 5812,
    FileNo: 95382,
    Issued: new Date("2020-01-31T13:07:09.049Z"),
    IssuingOfficer: "Russ",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-09-03T23:56:33.970Z"),
    ApprovalOfficer: "Beulah Heidenreich",
    OutstandingFees: "681.00",
    OSFees: "512.00",
    ComplainceCertNo: 99419,
    ApprovalForBL: new Date("2020-04-12T16:50:32.190Z"),
    BLicenceNumber: 78148,
    SentToDoH: new Date("2020-07-28T08:12:42.757Z"),
    DoHApproval: "DoHApproval 2",
    DateDoHApproved: new Date("2020-02-29T12:09:14.080Z"),
    DoHApprovalNo: "DoHApprovalNo 2",
    SerialNumber: 39779,
    LastInspection: new Date("2020-06-19T02:22:44.559Z"),
    Frequency: 98001,
    NextInspection: new Date("2021-06-01T06:23:43.798Z"),
    LastService: new Date("2020-12-10T08:25:35.743Z"),
    LastDesludge: new Date("2020-12-22T13:24:02.139Z"),
    LastSample: new Date("2020-09-23T05:09:11.349Z"),
    NextService: new Date("2021-02-22T00:04:48.012Z"),
    NextDesludge: new Date("2021-02-25T10:26:57.946Z"),
    NextSample: new Date("2021-11-18T14:34:35.207Z"),
    Comments:
      "Use the open-source SDD card, then you can override the neural driver!",
    Size: "388.00",
    DateInspected: new Date("2020-03-21T19:54:06.483Z"),
    InspectionType: "Developer",
    Officer: "Cordelia Hirthe",
    DateInspectedMonth: "April",
    DueStatus: "Books",
    Material: "Concrete",
    InspectionFrequency: 87786,
    ReferenceNumber: "16776",
    ContactName: "Albert",
    Compliance: "firewall",
    Reason:
      "quantifying the sensor won't do anything, we need to reboot the 1080p PNG application!",
    RefNo: "2336",
    Description:
      "The SMS microchip is down, synthesize the haptic application so we can bypass the AGP monitor!",
    DateTarget: new Date("2020-09-30T03:57:25.986Z"),
    Source: "Outdoors",
    Category: "Intelligent",
    Location: "Dominica",
    RequestedBy: "Quinten",
    ContactDetails: "Stokes",
    DateRecorded: new Date("2021-01-27T05:27:47.834Z"),
    RecordedBy: "Leo Ernser",
    OrgStructure: "Accountability",
    ActionOfficer: "Moses Bernhard",
    Coordinator: "Elvie Deckow",
    DateWork: new Date("2020-07-17T01:23:31.526Z"),
    EnquiryRefNo: "56191",
    Event_ReferenceNoAdditional: "99602",
    OnBehalfOf: "Group",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-08-15T07:06:23.501Z"),
    Type: "array",
    DateDue: new Date("2020-11-29T00:25:03.138Z"),
    DateRevoked: new Date("2021-01-26T15:05:14.638Z"),
    Noticee: "Aditya",
    CreatedDate: "2020-05-09T04:39:01.055Z",
    FeeType: "Checking Account",
    AmountIssued: "816.00",
    AmountOutstanding: 34647,
    CostCode: "05506",
    InvoiceNumber: 37452,
    Name: "Miller Group",
    SystemTypeClassification: "Cayman Islands",
    SortIndex: 58436,
    Title: "Forward",
    Condition: "Toys Circle",
  },
  {
    ID: "3",
    PermitNumber: 37432,
    Applicant: "Roberta Ledner",
    Owner: "Haleigh Altenwerth",
    Address: "Suite 668",
    Suburb: "North Carolina",
    AssessmentNo: 76525,
    Status: "Complied",
    TypeOfApplication: "Sports",
    FileNumber: 10312,
    SystemName: "program",
    SystemType: "array",
    Received: new Date("2021-01-26T16:42:37.786Z"),
    PTI: new Date("2021-01-27T03:07:16.496Z"),
    CTU: "CTU 3",
    WaterAuthority: "WaterAuthority 3",
    Plumber: "Breana Conroy",
    Risk: "Risk Level 3",
    CertificateNo: 309,
    SerialNo: "13324-3864",
    Details: "Kyat microchip",
    ApplicantAddress: "341 Kylie Isle",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "494.00",
    GPS: "859.00",
    Manufacturer: "Schaefer - Ferry",
    TankSize: 16.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Runolfsson - Ernser",
    SecondarySystemType: "Books",
    SecondaryManufacturer: "Mayer - Hoeger",
    SecondaryTankSize: "87.00",
    UnitSecondary: "UnitSecondary 3",
    TertiarySystemType: "Tools",
    TertiaryManufacturer: "Trantow LLC",
    TertiaryTankSize: "810.00",
    UnitTertiary: "UnitTertiary 3",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 3",
    Contractor: "Dino_Gislason96",
    RiskLevel: "RiskLevel 3",
    PermitType: "PermitType 3",
    ConstructionType: "ConstructionType 3",
    DebtorNo: 3934,
    FileNo: 50421,
    Issued: new Date("2020-07-13T10:48:16.384Z"),
    IssuingOfficer: "Keely",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-05-08T00:54:05.536Z"),
    ApprovalOfficer: "Mrs. Sabryna Mraz",
    OutstandingFees: "352.00",
    OSFees: "363.00",
    ComplainceCertNo: 41764,
    ApprovalForBL: new Date("2020-05-29T04:42:46.529Z"),
    BLicenceNumber: 61467,
    SentToDoH: new Date("2020-10-14T16:19:21.787Z"),
    DoHApproval: "DoHApproval 3",
    DateDoHApproved: new Date("2020-03-24T00:57:48.123Z"),
    DoHApprovalNo: "DoHApprovalNo 3",
    SerialNumber: 70955,
    LastInspection: new Date("2020-05-13T12:06:56.413Z"),
    Frequency: 86725,
    NextInspection: new Date("2022-01-14T12:56:22.753Z"),
    LastService: new Date("2020-04-28T06:50:11.177Z"),
    LastDesludge: new Date("2020-07-13T18:31:35.839Z"),
    LastSample: new Date("2020-09-16T22:49:20.903Z"),
    NextService: new Date("2021-12-04T12:26:36.168Z"),
    NextDesludge: new Date("2021-05-18T11:56:42.311Z"),
    NextSample: new Date("2021-11-19T05:45:11.600Z"),
    Comments:
      "The XML bandwidth is down, input the multi-byte feed so we can synthesize the IB circuit!",
    Size: "765.00",
    DateInspected: new Date("2020-05-09T14:00:20.621Z"),
    InspectionType: "Producer",
    Officer: "Lonzo Ward",
    DateInspectedMonth: "January",
    DueStatus: "synthesizing",
    Material: "Metal",
    InspectionFrequency: 32256,
    ReferenceNumber: "30081",
    ContactName: "May",
    Compliance: "bandwidth",
    Reason:
      "The IB capacitor is down, copy the haptic circuit so we can reboot the RAM sensor!",
    RefNo: "94632",
    Description:
      "The FTP bus is down, transmit the online array so we can parse the GB card!",
    DateTarget: new Date("2020-05-06T01:28:49.021Z"),
    Source: "Tools",
    Category: "Gorgeous",
    Location: "Sierra Leone",
    RequestedBy: "Candace",
    ContactDetails: "Kulas",
    DateRecorded: new Date("2021-01-26T07:45:31.399Z"),
    RecordedBy: "Paris Mueller",
    OrgStructure: "Indian Rupee Ngultrum",
    ActionOfficer: "Herbert Leuschke",
    Coordinator: "Columbus Jerde",
    DateWork: new Date("2020-08-07T04:48:55.667Z"),
    EnquiryRefNo: "6763",
    Event_ReferenceNoAdditional: "11290",
    OnBehalfOf: "Metrics",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-08-28T23:06:55.808Z"),
    Type: "application",
    DateDue: new Date("2020-05-05T22:19:56.108Z"),
    DateRevoked: new Date("2021-01-26T16:45:05.142Z"),
    Noticee: "Amy",
    CreatedDate: "2021-01-21T17:00:05.585Z",
    FeeType: "Money Market Account",
    AmountIssued: "206.00",
    AmountOutstanding: 88705,
    CostCode: "34637-3911",
    InvoiceNumber: 97255,
    Name: "Walker, Botsford and West",
    SystemTypeClassification: "Soft",
    SortIndex: 53789,
    Title: "Customer",
    Condition: "applications",
  },
  {
    ID: "4",
    PermitNumber: 95216,
    Applicant: "Erick Yost",
    Owner: "Sophie Bode",
    Address: "Apt. 424",
    Suburb: "Arkansas",
    AssessmentNo: 60156,
    Status: "Complied",
    TypeOfApplication: "Industrial",
    FileNumber: 78147,
    SystemName: "matrix",
    SystemType: "matrix",
    Received: new Date("2021-01-26T21:29:19.566Z"),
    PTI: new Date("2021-01-27T00:03:40.021Z"),
    CTU: "CTU 4",
    WaterAuthority: "WaterAuthority 4",
    Plumber: "Talia Bogisich",
    Risk: "Risk Level 4",
    CertificateNo: 99118,
    SerialNo: "27899",
    Details: "index",
    ApplicantAddress: "577 Fahey Stravenue",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "893.00",
    GPS: "900.00",
    Manufacturer: "Buckridge, Williamson and Zulauf",
    TankSize: 595.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hahn Inc",
    SecondarySystemType: "Movies",
    SecondaryManufacturer: "Streich LLC",
    SecondaryTankSize: "124.00",
    UnitSecondary: "UnitSecondary 4",
    TertiarySystemType: "Games",
    TertiaryManufacturer: "Koepp - Lowe",
    TertiaryTankSize: "479.00",
    UnitTertiary: "UnitTertiary 4",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 4",
    Contractor: "Cullen_Wehner",
    RiskLevel: "RiskLevel 4",
    PermitType: "PermitType 4",
    ConstructionType: "ConstructionType 4",
    DebtorNo: 25660,
    FileNo: 27485,
    Issued: new Date("2020-03-31T00:54:09.687Z"),
    IssuingOfficer: "Russ",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-07-07T06:04:10.071Z"),
    ApprovalOfficer: "May Leffler",
    OutstandingFees: "864.00",
    OSFees: "945.00",
    ComplainceCertNo: 53460,
    ApprovalForBL: new Date("2020-10-19T10:17:45.119Z"),
    BLicenceNumber: 47378,
    SentToDoH: new Date("2020-07-18T11:16:08.127Z"),
    DoHApproval: "DoHApproval 4",
    DateDoHApproved: new Date("2020-12-12T10:13:36.153Z"),
    DoHApprovalNo: "DoHApprovalNo 4",
    SerialNumber: 63980,
    LastInspection: new Date("2020-04-20T18:50:51.625Z"),
    Frequency: 84829,
    NextInspection: new Date("2021-06-18T15:40:58.405Z"),
    LastService: new Date("2020-10-07T02:03:33.239Z"),
    LastDesludge: new Date("2020-07-16T21:16:53.785Z"),
    LastSample: new Date("2020-09-07T21:53:43.692Z"),
    NextService: new Date("2021-03-18T07:57:15.377Z"),
    NextDesludge: new Date("2022-01-12T00:24:43.349Z"),
    NextSample: new Date("2021-09-04T01:42:06.819Z"),
    Comments:
      "You can't compress the application without connecting the bluetooth COM sensor!",
    Size: "681.00",
    DateInspected: new Date("2020-02-24T20:21:55.775Z"),
    InspectionType: "Architect",
    Officer: "Kenyon Mitchell",
    DateInspectedMonth: "September",
    DueStatus: "Cheese",
    Material: "Metal",
    InspectionFrequency: 95472,
    ReferenceNumber: "13165",
    ContactName: "Alexis",
    Compliance: "matrix",
    Reason:
      "The COM transmitter is down, transmit the haptic sensor so we can reboot the SMTP feed!",
    RefNo: "20587",
    Description:
      "The HTTP hard drive is down, bypass the cross-platform application so we can transmit the SCSI feed!",
    DateTarget: new Date("2020-08-25T05:47:46.515Z"),
    Source: "Books",
    Category: "Licensed",
    Location: "Cuba",
    RequestedBy: "Monica",
    ContactDetails: "Keeling",
    DateRecorded: new Date("2021-01-26T10:48:18.396Z"),
    RecordedBy: "Orval Lindgren",
    OrgStructure: "program",
    ActionOfficer: "Sylvan Stanton",
    Coordinator: "Herbert Bogisich",
    DateWork: new Date("2020-06-04T07:02:53.833Z"),
    EnquiryRefNo: "29115",
    Event_ReferenceNoAdditional: "91414",
    OnBehalfOf: "Implementation",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-05-04T02:34:02.117Z"),
    Type: "pixel",
    DateDue: new Date("2020-09-30T08:45:52.166Z"),
    DateRevoked: new Date("2021-01-26T15:05:24.611Z"),
    Noticee: "Alexandra",
    CreatedDate: "2020-08-02T00:07:35.838Z",
    FeeType: "Investment Account",
    AmountIssued: "432.00",
    AmountOutstanding: 87856,
    CostCode: "42655-7811",
    InvoiceNumber: 48254,
    Name: "Langosh, McCullough and Connelly",
    SystemTypeClassification: "yellow",
    SortIndex: 63368,
    Title: "Forward",
    Condition: "deliver seamless",
  },
  {
    ID: "5",
    PermitNumber: 72878,
    Applicant: "Elody Willms",
    Owner: "Tomas Labadie",
    Address: "Suite 985",
    Suburb: "Illinois",
    AssessmentNo: 96060,
    Status: "Complied",
    TypeOfApplication: "Movies",
    FileNumber: 99598,
    SystemName: "matrix",
    SystemType: "protocol",
    Received: new Date("2021-01-26T07:29:11.337Z"),
    PTI: new Date("2021-01-26T20:30:22.378Z"),
    CTU: "CTU 5",
    WaterAuthority: "WaterAuthority 5",
    Plumber: "Lorna Turcotte",
    Risk: "Risk Level 5",
    CertificateNo: 15971,
    SerialNo: "45266-6574",
    Details: "silver",
    ApplicantAddress: "825 Nader Stream",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "418.00",
    GPS: "701.00",
    Manufacturer: "Boyle - Cormier",
    TankSize: 355.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Wiegand Inc",
    SecondarySystemType: "Books",
    SecondaryManufacturer: "Goyette Inc",
    SecondaryTankSize: "605.00",
    UnitSecondary: "UnitSecondary 5",
    TertiarySystemType: "Grocery",
    TertiaryManufacturer: "Johnston - Hettinger",
    TertiaryTankSize: "410.00",
    UnitTertiary: "UnitTertiary 5",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 5",
    Contractor: "Lincoln91",
    RiskLevel: "RiskLevel 5",
    PermitType: "PermitType 5",
    ConstructionType: "ConstructionType 5",
    DebtorNo: 74677,
    FileNo: 85959,
    Issued: new Date("2020-11-13T03:07:02.875Z"),
    IssuingOfficer: "Alexandro",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-11-14T18:02:33.665Z"),
    ApprovalOfficer: "Elyssa Johnston",
    OutstandingFees: "152.00",
    OSFees: "591.00",
    ComplainceCertNo: 79141,
    ApprovalForBL: new Date("2020-04-09T17:59:40.721Z"),
    BLicenceNumber: 48031,
    SentToDoH: new Date("2020-04-02T15:15:50.625Z"),
    DoHApproval: "DoHApproval 5",
    DateDoHApproved: new Date("2020-02-11T19:39:57.134Z"),
    DoHApprovalNo: "DoHApprovalNo 5",
    SerialNumber: 64154,
    LastInspection: new Date("2020-03-09T21:45:53.689Z"),
    Frequency: 58133,
    NextInspection: new Date("2021-12-20T04:45:18.573Z"),
    LastService: new Date("2020-06-07T19:34:21.212Z"),
    LastDesludge: new Date("2020-08-29T08:37:50.312Z"),
    LastSample: new Date("2020-02-05T09:29:41.294Z"),
    NextService: new Date("2021-02-09T23:47:28.230Z"),
    NextDesludge: new Date("2021-08-27T12:41:20.147Z"),
    NextSample: new Date("2021-04-16T00:43:33.408Z"),
    Comments:
      "Use the solid state COM card, then you can synthesize the 1080p interface!",
    Size: "950.00",
    DateInspected: new Date("2020-02-26T11:20:27.604Z"),
    InspectionType: "Specialist",
    Officer: "Pearlie Hoeger",
    DateInspectedMonth: "November",
    DueStatus: "Communications",
    Material: "Granite",
    InspectionFrequency: 26764,
    ReferenceNumber: "86830",
    ContactName: "Jessie",
    Compliance: "panel",
    Reason:
      "The PCI firewall is down, synthesize the redundant application so we can input the TCP array!",
    RefNo: "62501",
    Description:
      "If we reboot the hard drive, we can get to the HDD system through the neural JSON driver!",
    DateTarget: new Date("2020-04-03T16:22:40.037Z"),
    Source: "Outdoors",
    Category: "Fantastic",
    Location: "Nauru",
    RequestedBy: "Tre",
    ContactDetails: "Grant",
    DateRecorded: new Date("2021-01-26T11:10:57.983Z"),
    RecordedBy: "Marshall Lang",
    OrgStructure: "Mountain",
    ActionOfficer: "Esmeralda Boyle DVM",
    Coordinator: "Margarette Zieme",
    DateWork: new Date("2020-02-04T09:06:15.828Z"),
    EnquiryRefNo: "78395",
    Event_ReferenceNoAdditional: "70922",
    OnBehalfOf: "Division",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-07-19T11:05:22.667Z"),
    Type: "bus",
    DateDue: new Date("2020-03-06T22:34:58.728Z"),
    DateRevoked: new Date("2021-01-27T01:28:35.292Z"),
    Noticee: "Bernadine",
    CreatedDate: "2020-05-21T11:48:31.631Z",
    FeeType: "Savings Account",
    AmountIssued: "85.00",
    AmountOutstanding: 39986,
    CostCode: "48110",
    InvoiceNumber: 1776,
    Name: "Kiehn - Grant",
    SystemTypeClassification: "emulation",
    SortIndex: 15694,
    Title: "Senior",
    Condition: "Intelligent Wooden",
  },
  {
    ID: "6",
    PermitNumber: 96316,
    Applicant: "Nellie Haag",
    Owner: "Ocie Labadie",
    Address: "Suite 334",
    Suburb: "South Dakota",
    AssessmentNo: 56036,
    Status: "Complied",
    TypeOfApplication: "Toys",
    FileNumber: 29862,
    SystemName: "matrix",
    SystemType: "transmitter",
    Received: new Date("2021-01-26T11:04:11.794Z"),
    PTI: new Date("2021-01-26T19:55:46.802Z"),
    CTU: "CTU 6",
    WaterAuthority: "WaterAuthority 6",
    Plumber: "Mr. Delphia Mosciski",
    Risk: "Risk Level 6",
    CertificateNo: 53716,
    SerialNo: "71771-9278",
    Details: "Soft Savings Account Rustic",
    ApplicantAddress: "294 Hodkiewicz Shore",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "418.00",
    GPS: "269.00",
    Manufacturer: "Kohler LLC",
    TankSize: 74.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Krajcik and Sons",
    SecondarySystemType: "Sports",
    SecondaryManufacturer: "Kuvalis Group",
    SecondaryTankSize: "242.00",
    UnitSecondary: "UnitSecondary 6",
    TertiarySystemType: "Beauty",
    TertiaryManufacturer: "Rodriguez - Little",
    TertiaryTankSize: "379.00",
    UnitTertiary: "UnitTertiary 6",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 6",
    Contractor: "Aiyana_Mayer",
    RiskLevel: "RiskLevel 6",
    PermitType: "PermitType 6",
    ConstructionType: "ConstructionType 6",
    DebtorNo: 81396,
    FileNo: 21106,
    Issued: new Date("2020-08-03T18:34:08.141Z"),
    IssuingOfficer: "Juana",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-08-18T04:13:58.980Z"),
    ApprovalOfficer: "Vergie Mills",
    OutstandingFees: "624.00",
    OSFees: "741.00",
    ComplainceCertNo: 4121,
    ApprovalForBL: new Date("2020-11-12T21:56:42.222Z"),
    BLicenceNumber: 28170,
    SentToDoH: new Date("2020-08-26T06:40:19.378Z"),
    DoHApproval: "DoHApproval 6",
    DateDoHApproved: new Date("2020-07-03T01:07:34.987Z"),
    DoHApprovalNo: "DoHApprovalNo 6",
    SerialNumber: 58436,
    LastInspection: new Date("2020-05-20T05:06:46.874Z"),
    Frequency: 27970,
    NextInspection: new Date("2021-02-26T22:26:04.814Z"),
    LastService: new Date("2020-07-28T10:52:57.565Z"),
    LastDesludge: new Date("2021-01-12T00:29:52.046Z"),
    LastSample: new Date("2020-08-21T23:57:49.625Z"),
    NextService: new Date("2022-01-11T06:37:32.789Z"),
    NextDesludge: new Date("2021-08-22T07:00:58.649Z"),
    NextSample: new Date("2022-01-16T08:37:30.253Z"),
    Comments:
      "Use the optical SAS feed, then you can override the open-source capacitor!",
    Size: "161.00",
    DateInspected: new Date("2020-09-20T00:58:49.160Z"),
    InspectionType: "Consultant",
    Officer: "Reginald Lueilwitz",
    DateInspectedMonth: "May",
    DueStatus: "UAE Dirham",
    Material: "Cotton",
    InspectionFrequency: 60353,
    ReferenceNumber: "2514",
    ContactName: "Kennedy",
    Compliance: "panel",
    Reason:
      "Try to bypass the XML bus, maybe it will quantify the back-end bus!",
    RefNo: "36332",
    Description:
      "The SAS monitor is down, navigate the haptic bus so we can generate the IB driver!",
    DateTarget: new Date("2020-10-23T11:04:56.868Z"),
    Source: "Kids",
    Category: "Unbranded",
    Location: "Ireland",
    RequestedBy: "Ayana",
    ContactDetails: "Gleichner",
    DateRecorded: new Date("2021-01-26T17:33:27.071Z"),
    RecordedBy: "Arnaldo Langworth",
    OrgStructure: "Home",
    ActionOfficer: "Dejuan Batz",
    Coordinator: "Deshawn Konopelski",
    DateWork: new Date("2020-06-26T14:04:11.601Z"),
    EnquiryRefNo: "37011",
    Event_ReferenceNoAdditional: "93875",
    OnBehalfOf: "Accounts",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-02-05T03:08:21.266Z"),
    Type: "matrix",
    DateDue: new Date("2020-04-12T05:28:44.468Z"),
    DateRevoked: new Date("2021-01-27T00:23:33.677Z"),
    Noticee: "Jennie",
    CreatedDate: "2020-11-10T03:20:48.152Z",
    FeeType: "Money Market Account",
    AmountIssued: "66.00",
    AmountOutstanding: 20752,
    CostCode: "64835-5640",
    InvoiceNumber: 74873,
    Name: "Crist - Ferry",
    SystemTypeClassification: "Pants",
    SortIndex: 21743,
    Title: "Product",
    Condition: "alliance index Steel",
  },
  {
    ID: "7",
    PermitNumber: 34231,
    Applicant: "Alexis Auer",
    Owner: "Fritz Bruen",
    Address: "Suite 481",
    Suburb: "Michigan",
    AssessmentNo: 82846,
    Status: "Complied",
    TypeOfApplication: "Tools",
    FileNumber: 75709,
    SystemName: "system",
    SystemType: "interface",
    Received: new Date("2021-01-26T22:09:55.387Z"),
    PTI: new Date("2021-01-26T16:29:59.404Z"),
    CTU: "CTU 7",
    WaterAuthority: "WaterAuthority 7",
    Plumber: "Jaeden Emmerich",
    Risk: "Risk Level 7",
    CertificateNo: 62326,
    SerialNo: "86703-2052",
    Details: "Borders Handcrafted",
    ApplicantAddress: "66666 Wilderman Court",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "483.00",
    GPS: "360.00",
    Manufacturer: "Sanford, Brakus and Greenfelder",
    TankSize: 92.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Heathcote - McClure",
    SecondarySystemType: "Music",
    SecondaryManufacturer: "Gulgowski - Hamill",
    SecondaryTankSize: "610.00",
    UnitSecondary: "UnitSecondary 7",
    TertiarySystemType: "Books",
    TertiaryManufacturer: "McClure - Morissette",
    TertiaryTankSize: "705.00",
    UnitTertiary: "UnitTertiary 7",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 7",
    Contractor: "Herminio.Veum53",
    RiskLevel: "RiskLevel 7",
    PermitType: "PermitType 7",
    ConstructionType: "ConstructionType 7",
    DebtorNo: 32618,
    FileNo: 28939,
    Issued: new Date("2021-01-13T08:00:44.486Z"),
    IssuingOfficer: "Raymundo",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-03-20T17:21:47.487Z"),
    ApprovalOfficer: "Ricky Kiehn",
    OutstandingFees: "124.00",
    OSFees: "178.00",
    ComplainceCertNo: 22685,
    ApprovalForBL: new Date("2020-02-08T16:33:42.097Z"),
    BLicenceNumber: 99101,
    SentToDoH: new Date("2020-06-11T17:05:56.671Z"),
    DoHApproval: "DoHApproval 7",
    DateDoHApproved: new Date("2020-07-21T11:13:54.613Z"),
    DoHApprovalNo: "DoHApprovalNo 7",
    SerialNumber: 54363,
    LastInspection: new Date("2020-05-04T10:04:34.317Z"),
    Frequency: 12309,
    NextInspection: new Date("2021-08-28T15:02:07.912Z"),
    LastService: new Date("2020-04-16T22:09:26.330Z"),
    LastDesludge: new Date("2020-12-10T08:54:27.534Z"),
    LastSample: new Date("2020-05-11T16:09:02.496Z"),
    NextService: new Date("2021-09-23T06:24:00.665Z"),
    NextDesludge: new Date("2021-06-29T14:41:01.511Z"),
    NextSample: new Date("2021-12-26T12:22:49.559Z"),
    Comments:
      "I'll override the haptic PNG circuit, that should transmitter the SMS matrix!",
    Size: "916.00",
    DateInspected: new Date("2020-11-13T07:49:25.431Z"),
    InspectionType: "Facilitator",
    Officer: "Rachael Christiansen",
    DateInspectedMonth: "August",
    DueStatus: "encoding",
    Material: "Wooden",
    InspectionFrequency: 9429,
    ReferenceNumber: "77253",
    ContactName: "Litzy",
    Compliance: "firewall",
    Reason:
      "You can't reboot the microchip without programming the online HDD sensor!",
    RefNo: "54765",
    Description:
      "You can't transmit the panel without synthesizing the bluetooth SSL card!",
    DateTarget: new Date("2020-09-19T23:59:51.588Z"),
    Source: "Sports",
    Category: "Ergonomic",
    Location: "Nicaragua",
    RequestedBy: "Weldon",
    ContactDetails: "Koss",
    DateRecorded: new Date("2021-01-26T08:50:03.404Z"),
    RecordedBy: "Emmitt Crona",
    OrgStructure: "New York",
    ActionOfficer: "Tyler Quitzon",
    Coordinator: "Minnie Leannon",
    DateWork: new Date("2020-05-10T19:28:14.035Z"),
    EnquiryRefNo: "49761",
    Event_ReferenceNoAdditional: "53893",
    OnBehalfOf: "Usability",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-03-04T19:40:12.357Z"),
    Type: "capacitor",
    DateDue: new Date("2020-08-11T21:06:36.344Z"),
    DateRevoked: new Date("2021-01-27T01:25:52.941Z"),
    Noticee: "Johann",
    CreatedDate: "2020-07-18T15:23:37.207Z",
    FeeType: "Investment Account",
    AmountIssued: "760.00",
    AmountOutstanding: 47103,
    CostCode: "27143-0454",
    InvoiceNumber: 48533,
    Name: "Gorczany and Sons",
    SystemTypeClassification: "Awesome",
    SortIndex: 69188,
    Title: "Legacy",
    Condition: "Seamless",
  },
  {
    ID: "8",
    PermitNumber: 27350,
    Applicant: "Tobin Bednar",
    Owner: "Lavern Crona III",
    Address: "Apt. 437",
    Suburb: "New Hampshire",
    AssessmentNo: 95302,
    Status: "Complied",
    TypeOfApplication: "Garden",
    FileNumber: 90963,
    SystemName: "transmitter",
    SystemType: "circuit",
    Received: new Date("2021-01-27T04:46:56.348Z"),
    PTI: new Date("2021-01-27T06:35:17.586Z"),
    CTU: "CTU 8",
    WaterAuthority: "WaterAuthority 8",
    Plumber: "Miss Marley Kertzmann",
    Risk: "Risk Level 8",
    CertificateNo: 35815,
    SerialNo: "74807-4721",
    Details: "payment Ball Shirt",
    ApplicantAddress: "09715 Glover Cove",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "717.00",
    GPS: "964.00",
    Manufacturer: "Kulas Group",
    TankSize: 125.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Becker, Orn and Bailey",
    SecondarySystemType: "Electronics",
    SecondaryManufacturer: "Sauer, Abbott and Heller",
    SecondaryTankSize: "472.00",
    UnitSecondary: "UnitSecondary 8",
    TertiarySystemType: "Industrial",
    TertiaryManufacturer: "Morar LLC",
    TertiaryTankSize: "177.00",
    UnitTertiary: "UnitTertiary 8",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 8",
    Contractor: "Hanna_Schuster",
    RiskLevel: "RiskLevel 8",
    PermitType: "PermitType 8",
    ConstructionType: "ConstructionType 8",
    DebtorNo: 47683,
    FileNo: 4211,
    Issued: new Date("2021-01-03T00:38:13.880Z"),
    IssuingOfficer: "Ruthe",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-12-27T16:32:32.474Z"),
    ApprovalOfficer: "Fernando Zemlak",
    OutstandingFees: "674.00",
    OSFees: "493.00",
    ComplainceCertNo: 78471,
    ApprovalForBL: new Date("2020-02-28T23:07:10.259Z"),
    BLicenceNumber: 6572,
    SentToDoH: new Date("2020-08-03T08:52:35.422Z"),
    DoHApproval: "DoHApproval 8",
    DateDoHApproved: new Date("2020-09-25T19:05:40.604Z"),
    DoHApprovalNo: "DoHApprovalNo 8",
    SerialNumber: 17078,
    LastInspection: new Date("2020-12-21T10:45:54.037Z"),
    Frequency: 15498,
    NextInspection: new Date("2021-12-12T09:23:21.582Z"),
    LastService: new Date("2020-09-25T17:08:12.177Z"),
    LastDesludge: new Date("2020-01-29T14:32:56.038Z"),
    LastSample: new Date("2020-03-23T17:24:29.354Z"),
    NextService: new Date("2021-12-09T12:18:32.997Z"),
    NextDesludge: new Date("2021-10-02T05:21:18.335Z"),
    NextSample: new Date("2021-03-11T14:29:13.108Z"),
    Comments:
      "I'll transmit the haptic JSON sensor, that should protocol the GB port!",
    Size: "329.00",
    DateInspected: new Date("2020-03-01T20:48:23.516Z"),
    InspectionType: "Supervisor",
    Officer: "Macey Schumm",
    DateInspectedMonth: "August",
    DueStatus: "Rubber",
    Material: "Metal",
    InspectionFrequency: 83763,
    ReferenceNumber: "20597",
    ContactName: "Concepcion",
    Compliance: "pixel",
    Reason:
      "Try to synthesize the GB bandwidth, maybe it will compress the multi-byte system!",
    RefNo: "50487",
    Description: "We need to parse the wireless FTP hard drive!",
    DateTarget: new Date("2021-01-05T17:22:18.940Z"),
    Source: "Grocery",
    Category: "Licensed",
    Location: "United States of America",
    RequestedBy: "Constance",
    ContactDetails: "Altenwerth",
    DateRecorded: new Date("2021-01-26T10:57:19.877Z"),
    RecordedBy: "Wilber Conroy",
    OrgStructure: "Distributed",
    ActionOfficer: "Asia Hayes",
    Coordinator: "Audie Reichert",
    DateWork: new Date("2020-06-12T10:35:56.820Z"),
    EnquiryRefNo: "49269",
    Event_ReferenceNoAdditional: "15071",
    OnBehalfOf: "Usability",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-05-24T14:53:16.421Z"),
    Type: "firewall",
    DateDue: new Date("2020-02-21T15:02:18.063Z"),
    DateRevoked: new Date("2021-01-26T08:05:35.904Z"),
    Noticee: "Jerald",
    CreatedDate: "2020-11-15T12:47:59.800Z",
    FeeType: "Credit Card Account",
    AmountIssued: "317.00",
    AmountOutstanding: 96041,
    CostCode: "05806",
    InvoiceNumber: 7630,
    Name: "Erdman Group",
    SystemTypeClassification: "Accounts",
    SortIndex: 61259,
    Title: "Future",
    Condition: "ivory Generic",
  },
  {
    ID: "9",
    PermitNumber: 53525,
    Applicant: "Dr. Laurie Cronin",
    Owner: "Chaim Corkery",
    Address: "Suite 047",
    Suburb: "Nevada",
    AssessmentNo: 52386,
    Status: "Complied",
    TypeOfApplication: "Health",
    FileNumber: 23238,
    SystemName: "bandwidth",
    SystemType: "alarm",
    Received: new Date("2021-01-26T14:29:49.793Z"),
    PTI: new Date("2021-01-26T21:40:51.625Z"),
    CTU: "CTU 9",
    WaterAuthority: "WaterAuthority 9",
    Plumber: "Alaina Green",
    Risk: "Risk Level 9",
    CertificateNo: 58894,
    SerialNo: "33035-9479",
    Details: "Public-key",
    ApplicantAddress: "670 Johathan Forks",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "652.00",
    GPS: "506.00",
    Manufacturer: "Mraz and Sons",
    TankSize: 767.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Erdman, Bogisich and Metz",
    SecondarySystemType: "Shoes",
    SecondaryManufacturer: "Effertz Group",
    SecondaryTankSize: "935.00",
    UnitSecondary: "UnitSecondary 9",
    TertiarySystemType: "Tools",
    TertiaryManufacturer: "Kassulke - Cronin",
    TertiaryTankSize: "751.00",
    UnitTertiary: "UnitTertiary 9",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 9",
    Contractor: "Merl15",
    RiskLevel: "RiskLevel 9",
    PermitType: "PermitType 9",
    ConstructionType: "ConstructionType 9",
    DebtorNo: 63150,
    FileNo: 34384,
    Issued: new Date("2021-01-24T07:32:37.894Z"),
    IssuingOfficer: "Ericka",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2021-01-20T15:54:00.612Z"),
    ApprovalOfficer: "Donnie Nader",
    OutstandingFees: "782.00",
    OSFees: "748.00",
    ComplainceCertNo: 58795,
    ApprovalForBL: new Date("2020-02-01T18:49:30.517Z"),
    BLicenceNumber: 24598,
    SentToDoH: new Date("2020-06-02T03:51:46.713Z"),
    DoHApproval: "DoHApproval 9",
    DateDoHApproved: new Date("2020-04-08T21:50:53.008Z"),
    DoHApprovalNo: "DoHApprovalNo 9",
    SerialNumber: 8992,
    LastInspection: new Date("2020-03-26T20:38:24.567Z"),
    Frequency: 57652,
    NextInspection: new Date("2021-05-02T13:21:26.042Z"),
    LastService: new Date("2020-02-14T08:02:55.056Z"),
    LastDesludge: new Date("2020-08-30T07:33:52.457Z"),
    LastSample: new Date("2020-12-16T09:06:42.406Z"),
    NextService: new Date("2021-09-07T14:20:43.582Z"),
    NextDesludge: new Date("2021-12-31T18:46:19.780Z"),
    NextSample: new Date("2021-07-06T10:08:04.481Z"),
    Comments:
      "Use the optical HTTP circuit, then you can transmit the haptic alarm!",
    Size: "145.00",
    DateInspected: new Date("2020-09-25T09:13:51.277Z"),
    InspectionType: "Administrator",
    Officer: "Miss Dorthy Gibson",
    DateInspectedMonth: "June",
    DueStatus: "Licensed",
    Material: "Fresh",
    InspectionFrequency: 51635,
    ReferenceNumber: "21149",
    ContactName: "Leonard",
    Compliance: "bandwidth",
    Reason: "We need to bypass the back-end THX microchip!",
    RefNo: "68737",
    Description:
      "You can't compress the hard drive without generating the bluetooth ADP monitor!",
    DateTarget: new Date("2020-02-05T04:22:09.098Z"),
    Source: "Kids",
    Category: "Incredible",
    Location: "Guatemala",
    RequestedBy: "Kole",
    ContactDetails: "Stehr",
    DateRecorded: new Date("2021-01-27T05:18:25.752Z"),
    RecordedBy: "Mrs. Beth Collins",
    OrgStructure: "Generic Plastic Gloves",
    ActionOfficer: "Brendon Johnson",
    Coordinator: "Silas Stoltenberg",
    DateWork: new Date("2021-01-05T22:35:17.668Z"),
    EnquiryRefNo: "48888",
    Event_ReferenceNoAdditional: "39278",
    OnBehalfOf: "Creative",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-09-01T12:44:11.695Z"),
    Type: "capacitor",
    DateDue: new Date("2020-09-06T22:21:18.314Z"),
    DateRevoked: new Date("2021-01-26T20:56:11.319Z"),
    Noticee: "Jackson",
    CreatedDate: "2020-04-18T05:39:39.395Z",
    FeeType: "Money Market Account",
    AmountIssued: "258.00",
    AmountOutstanding: 86901,
    CostCode: "25980",
    InvoiceNumber: 17150,
    Name: "Kozey - Roob",
    SystemTypeClassification: "Money Market Account",
    SortIndex: 90245,
    Title: "Future",
    Condition: "Table",
  },
  {
    ID: "10",
    PermitNumber: 65725,
    Applicant: "Rafaela Mills",
    Owner: "Zander Larson",
    Address: "Apt. 799",
    Suburb: "Alabama",
    AssessmentNo: 74100,
    Status: "Complied",
    TypeOfApplication: "Garden",
    FileNumber: 13384,
    SystemName: "bus",
    SystemType: "pixel",
    Received: new Date("2021-01-26T22:27:43.711Z"),
    PTI: new Date("2021-01-27T04:12:55.139Z"),
    CTU: "CTU 10",
    WaterAuthority: "WaterAuthority 10",
    Plumber: "Cali Crona",
    Risk: "Risk Level 10",
    CertificateNo: 22232,
    SerialNo: "82691-7870",
    Details: "Salad framework Ball",
    ApplicantAddress: "50435 Timmothy Drive",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "22.00",
    GPS: "719.00",
    Manufacturer: "Boyer - Pagac",
    TankSize: 88.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hills - Mayer",
    SecondarySystemType: "Books",
    SecondaryManufacturer: "Hammes, Conroy and Langosh",
    SecondaryTankSize: "980.00",
    UnitSecondary: "UnitSecondary 10",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Adams Inc",
    TertiaryTankSize: "442.00",
    UnitTertiary: "UnitTertiary 10",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 10",
    Contractor: "Janet.Kuvalis71",
    RiskLevel: "RiskLevel 10",
    PermitType: "PermitType 10",
    ConstructionType: "ConstructionType 10",
    DebtorNo: 48284,
    FileNo: 11435,
    Issued: new Date("2020-12-22T15:26:39.912Z"),
    IssuingOfficer: "Ariel",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-02-25T23:41:07.350Z"),
    ApprovalOfficer: "Jasen Kozey",
    OutstandingFees: "331.00",
    OSFees: "977.00",
    ComplainceCertNo: 38481,
    ApprovalForBL: new Date("2020-11-24T05:40:25.020Z"),
    BLicenceNumber: 9599,
    SentToDoH: new Date("2020-11-04T07:44:00.980Z"),
    DoHApproval: "DoHApproval 10",
    DateDoHApproved: new Date("2020-02-24T10:03:34.445Z"),
    DoHApprovalNo: "DoHApprovalNo 10",
    SerialNumber: 9363,
    LastInspection: new Date("2020-07-21T01:55:02.778Z"),
    Frequency: 88891,
    NextInspection: new Date("2021-09-14T18:38:08.077Z"),
    LastService: new Date("2020-07-18T04:04:29.189Z"),
    LastDesludge: new Date("2020-08-18T10:37:23.671Z"),
    LastSample: new Date("2020-04-01T17:18:54.915Z"),
    NextService: new Date("2021-11-19T17:50:47.290Z"),
    NextDesludge: new Date("2021-05-16T10:52:49.880Z"),
    NextSample: new Date("2021-07-10T09:33:53.766Z"),
    Comments:
      "navigating the system won't do anything, we need to override the haptic HTTP program!",
    Size: "236.00",
    DateInspected: new Date("2020-01-30T19:36:40.183Z"),
    InspectionType: "Architect",
    Officer: "Kristopher Lesch",
    DateInspectedMonth: "April",
    DueStatus: "5th generation",
    Material: "Granite",
    InspectionFrequency: 31453,
    ReferenceNumber: "21368",
    ContactName: "Kacey",
    Compliance: "hard drive",
    Reason:
      "Use the back-end CSS bandwidth, then you can calculate the auxiliary firewall!",
    RefNo: "13967",
    Description: "We need to generate the digital AGP firewall!",
    DateTarget: new Date("2020-04-10T06:08:36.213Z"),
    Source: "Sports",
    Category: "Tasty",
    Location: "French Guiana",
    RequestedBy: "Lexi",
    ContactDetails: "Zemlak",
    DateRecorded: new Date("2021-01-26T10:27:05.462Z"),
    RecordedBy: "Ross VonRueden",
    OrgStructure: "calculate",
    ActionOfficer: "Miss Ona Pfannerstill",
    Coordinator: "Mossie Kuhic",
    DateWork: new Date("2021-01-26T04:53:12.620Z"),
    EnquiryRefNo: "65706",
    Event_ReferenceNoAdditional: "30996",
    OnBehalfOf: "Brand",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-11-05T15:47:33.438Z"),
    Type: "panel",
    DateDue: new Date("2020-07-02T03:55:05.340Z"),
    DateRevoked: new Date("2021-01-26T13:10:14.639Z"),
    Noticee: "Harry",
    CreatedDate: "2020-12-25T03:13:11.306Z",
    FeeType: "Home Loan Account",
    AmountIssued: "930.00",
    AmountOutstanding: 18679,
    CostCode: "13941-9813",
    InvoiceNumber: 34827,
    Name: "DuBuque, Stracke and Towne",
    SystemTypeClassification: "turquoise",
    SortIndex: 39378,
    Title: "Corporate",
    Condition: "system-worthy",
  },
  {
    ID: "11",
    PermitNumber: 67940,
    Applicant: "Percival Dickens",
    Owner: "Ms. Shayna Zemlak",
    Address: "Suite 091",
    Suburb: "Kansas",
    AssessmentNo: 63515,
    Status: "Complied",
    TypeOfApplication: "Industrial",
    FileNumber: 47454,
    SystemName: "application",
    SystemType: "monitor",
    Received: new Date("2021-01-27T05:11:09.212Z"),
    PTI: new Date("2021-01-27T03:12:57.883Z"),
    CTU: "CTU 11",
    WaterAuthority: "WaterAuthority 11",
    Plumber: "Van Zulauf",
    Risk: "Risk Level 11",
    CertificateNo: 69650,
    SerialNo: "91051-0654",
    Details: "benchmark",
    ApplicantAddress: "492 Bechtelar Fields",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "524.00",
    GPS: "47.00",
    Manufacturer: "Hudson LLC",
    TankSize: 797.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Windler and Sons",
    SecondarySystemType: "Games",
    SecondaryManufacturer: "Anderson, Daugherty and Frami",
    SecondaryTankSize: "748.00",
    UnitSecondary: "UnitSecondary 11",
    TertiarySystemType: "Jewelery",
    TertiaryManufacturer: "Parisian LLC",
    TertiaryTankSize: "129.00",
    UnitTertiary: "UnitTertiary 11",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 11",
    Contractor: "Lester60",
    RiskLevel: "RiskLevel 11",
    PermitType: "PermitType 11",
    ConstructionType: "ConstructionType 11",
    DebtorNo: 6357,
    FileNo: 80826,
    Issued: new Date("2020-02-06T18:38:42.986Z"),
    IssuingOfficer: "Carson",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-06-09T11:54:00.923Z"),
    ApprovalOfficer: "Rosalyn Baumbach",
    OutstandingFees: "635.00",
    OSFees: "746.00",
    ComplainceCertNo: 25106,
    ApprovalForBL: new Date("2020-08-09T23:23:40.399Z"),
    BLicenceNumber: 77616,
    SentToDoH: new Date("2020-01-30T03:01:53.957Z"),
    DoHApproval: "DoHApproval 11",
    DateDoHApproved: new Date("2020-06-30T19:52:17.804Z"),
    DoHApprovalNo: "DoHApprovalNo 11",
    SerialNumber: 47123,
    LastInspection: new Date("2020-08-21T12:41:52.325Z"),
    Frequency: 31420,
    NextInspection: new Date("2021-11-11T14:08:02.596Z"),
    LastService: new Date("2020-01-31T03:52:42.310Z"),
    LastDesludge: new Date("2020-02-17T17:58:45.346Z"),
    LastSample: new Date("2020-11-06T15:08:05.188Z"),
    NextService: new Date("2021-12-24T08:49:00.315Z"),
    NextDesludge: new Date("2021-03-21T03:59:46.953Z"),
    NextSample: new Date("2021-07-04T08:19:31.425Z"),
    Comments:
      "I'll input the auxiliary RSS firewall, that should feed the EXE monitor!",
    Size: "562.00",
    DateInspected: new Date("2020-06-17T15:06:52.920Z"),
    InspectionType: "Manager",
    Officer: "Maida Lubowitz",
    DateInspectedMonth: "October",
    DueStatus: "Fork",
    Material: "Plastic",
    InspectionFrequency: 89847,
    ReferenceNumber: "57254",
    ContactName: "Toney",
    Compliance: "interface",
    Reason:
      "Try to compress the ADP card, maybe it will bypass the neural bandwidth!",
    RefNo: "38822",
    Description:
      "You can't input the alarm without compressing the primary HTTP interface!",
    DateTarget: new Date("2020-03-02T12:34:14.263Z"),
    Source: "Beauty",
    Category: "Licensed",
    Location: "Equatorial Guinea",
    RequestedBy: "Orval",
    ContactDetails: "Tillman",
    DateRecorded: new Date("2021-01-27T04:52:15.998Z"),
    RecordedBy: "Irma Macejkovic",
    OrgStructure: "Money Market Account",
    ActionOfficer: "Dominic Kertzmann",
    Coordinator: "Everette Bosco III",
    DateWork: new Date("2020-09-09T05:32:26.975Z"),
    EnquiryRefNo: "55423",
    Event_ReferenceNoAdditional: "83607",
    OnBehalfOf: "Research",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-04-09T08:34:59.751Z"),
    Type: "bus",
    DateDue: new Date("2020-05-12T10:26:49.958Z"),
    DateRevoked: new Date("2021-01-26T18:17:34.264Z"),
    Noticee: "Reta",
    CreatedDate: "2020-08-30T02:07:35.644Z",
    FeeType: "Money Market Account",
    AmountIssued: "564.00",
    AmountOutstanding: 34749,
    CostCode: "20953-2727",
    InvoiceNumber: 27624,
    Name: "Medhurst - Boyer",
    SystemTypeClassification: "Gibraltar Pound",
    SortIndex: 10596,
    Title: "Regional",
    Condition: "hack bluetooth",
  },
  {
    ID: "12",
    PermitNumber: 73779,
    Applicant: "Abby Jerde",
    Owner: "Ms. Vida Heathcote",
    Address: "Apt. 767",
    Suburb: "Alaska",
    AssessmentNo: 18042,
    Status: "Complied",
    TypeOfApplication: "Clothing",
    FileNumber: 95685,
    SystemName: "system",
    SystemType: "bandwidth",
    Received: new Date("2021-01-27T02:10:42.833Z"),
    PTI: new Date("2021-01-27T01:57:32.867Z"),
    CTU: "CTU 12",
    WaterAuthority: "WaterAuthority 12",
    Plumber: "Eldridge Bednar",
    Risk: "Risk Level 12",
    CertificateNo: 89709,
    SerialNo: "85903",
    Details: "Syrian Arab Republic optimize navigating",
    ApplicantAddress: "95440 Steuber Springs",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "607.00",
    GPS: "356.00",
    Manufacturer: "Stehr - Roberts",
    TankSize: 195.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Schroeder - Lebsack",
    SecondarySystemType: "Outdoors",
    SecondaryManufacturer: "Littel Group",
    SecondaryTankSize: "960.00",
    UnitSecondary: "UnitSecondary 12",
    TertiarySystemType: "Grocery",
    TertiaryManufacturer: "Botsford LLC",
    TertiaryTankSize: "121.00",
    UnitTertiary: "UnitTertiary 12",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 12",
    Contractor: "Dorris_Hirthe",
    RiskLevel: "RiskLevel 12",
    PermitType: "PermitType 12",
    ConstructionType: "ConstructionType 12",
    DebtorNo: 82758,
    FileNo: 63969,
    Issued: new Date("2020-05-20T01:27:15.535Z"),
    IssuingOfficer: "Royce",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-11-16T09:35:55.603Z"),
    ApprovalOfficer: "Kenton Gaylord",
    OutstandingFees: "746.00",
    OSFees: "408.00",
    ComplainceCertNo: 21654,
    ApprovalForBL: new Date("2020-06-11T15:42:40.134Z"),
    BLicenceNumber: 7159,
    SentToDoH: new Date("2020-10-17T03:00:29.740Z"),
    DoHApproval: "DoHApproval 12",
    DateDoHApproved: new Date("2020-11-28T10:25:57.040Z"),
    DoHApprovalNo: "DoHApprovalNo 12",
    SerialNumber: 42563,
    LastInspection: new Date("2020-06-03T09:23:54.433Z"),
    Frequency: 29271,
    NextInspection: new Date("2021-02-01T02:11:08.959Z"),
    LastService: new Date("2020-03-07T21:05:04.388Z"),
    LastDesludge: new Date("2020-11-19T17:22:29.062Z"),
    LastSample: new Date("2020-12-09T00:05:13.376Z"),
    NextService: new Date("2021-05-07T18:33:42.337Z"),
    NextDesludge: new Date("2021-03-18T13:56:32.746Z"),
    NextSample: new Date("2021-09-22T13:50:44.706Z"),
    Comments:
      "Use the auxiliary EXE interface, then you can parse the back-end microchip!",
    Size: "102.00",
    DateInspected: new Date("2020-08-25T21:58:09.825Z"),
    InspectionType: "Administrator",
    Officer: "Casper Stamm",
    DateInspectedMonth: "November",
    DueStatus: "Vermont",
    Material: "Plastic",
    InspectionFrequency: 95092,
    ReferenceNumber: "45481",
    ContactName: "Abigale",
    Compliance: "microchip",
    Reason:
      "Try to quantify the JSON transmitter, maybe it will hack the primary interface!",
    RefNo: "2931",
    Description:
      "I'll generate the 1080p SSL driver, that should matrix the EXE driver!",
    DateTarget: new Date("2020-03-30T08:29:22.500Z"),
    Source: "Grocery",
    Category: "Rustic",
    Location: "Burkina Faso",
    RequestedBy: "Mabelle",
    ContactDetails: "Bergstrom",
    DateRecorded: new Date("2021-01-27T01:55:16.608Z"),
    RecordedBy: "Sallie Rice I",
    OrgStructure: "B2C",
    ActionOfficer: "Andres Schimmel",
    Coordinator: "Raymond Schroeder",
    DateWork: new Date("2021-01-14T11:35:23.924Z"),
    EnquiryRefNo: "31887",
    Event_ReferenceNoAdditional: "1135",
    OnBehalfOf: "Metrics",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-03-29T18:58:28.684Z"),
    Type: "transmitter",
    DateDue: new Date("2021-01-07T04:38:50.536Z"),
    DateRevoked: new Date("2021-01-26T15:45:17.622Z"),
    Noticee: "Rowan",
    CreatedDate: "2020-03-08T20:21:24.819Z",
    FeeType: "Investment Account",
    AmountIssued: "195.00",
    AmountOutstanding: 39572,
    CostCode: "77130-2103",
    InvoiceNumber: 38710,
    Name: "Boyle, Hansen and Shanahan",
    SystemTypeClassification: "hack",
    SortIndex: 85932,
    Title: "Global",
    Condition: "Cambridgeshire Lebanon circuit",
  },
  {
    ID: "13",
    PermitNumber: 43573,
    Applicant: "Geo Feil",
    Owner: "Ola Hudson Sr.",
    Address: "Suite 121",
    Suburb: "Indiana",
    AssessmentNo: 36001,
    Status: "Complied",
    TypeOfApplication: "Games",
    FileNumber: 16044,
    SystemName: "circuit",
    SystemType: "feed",
    Received: new Date("2021-01-27T05:15:56.921Z"),
    PTI: new Date("2021-01-26T11:41:21.283Z"),
    CTU: "CTU 13",
    WaterAuthority: "WaterAuthority 13",
    Plumber: "Amanda Metz",
    Risk: "Risk Level 13",
    CertificateNo: 54045,
    SerialNo: "31844-8816",
    Details: "applications",
    ApplicantAddress: "035 Terrill Fork",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "85.00",
    GPS: "243.00",
    Manufacturer: "Streich and Sons",
    TankSize: 359.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Kshlerin, Torphy and Nienow",
    SecondarySystemType: "Toys",
    SecondaryManufacturer: "Wyman, Hahn and Hills",
    SecondaryTankSize: "352.00",
    UnitSecondary: "UnitSecondary 13",
    TertiarySystemType: "Beauty",
    TertiaryManufacturer: "Barton, Feest and Gleason",
    TertiaryTankSize: "803.00",
    UnitTertiary: "UnitTertiary 13",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 13",
    Contractor: "Amelia31",
    RiskLevel: "RiskLevel 13",
    PermitType: "PermitType 13",
    ConstructionType: "ConstructionType 13",
    DebtorNo: 71691,
    FileNo: 61112,
    Issued: new Date("2020-02-22T14:09:11.574Z"),
    IssuingOfficer: "Rudolph",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-12-14T08:08:59.441Z"),
    ApprovalOfficer: "Joseph Raynor",
    OutstandingFees: "286.00",
    OSFees: "156.00",
    ComplainceCertNo: 31647,
    ApprovalForBL: new Date("2020-08-30T07:01:50.016Z"),
    BLicenceNumber: 1547,
    SentToDoH: new Date("2020-12-20T17:25:38.144Z"),
    DoHApproval: "DoHApproval 13",
    DateDoHApproved: new Date("2020-11-06T04:54:40.735Z"),
    DoHApprovalNo: "DoHApprovalNo 13",
    SerialNumber: 36996,
    LastInspection: new Date("2020-09-10T15:00:08.385Z"),
    Frequency: 2264,
    NextInspection: new Date("2021-08-06T05:44:31.470Z"),
    LastService: new Date("2020-02-07T01:10:47.952Z"),
    LastDesludge: new Date("2020-12-27T12:00:54.979Z"),
    LastSample: new Date("2020-05-07T20:52:09.696Z"),
    NextService: new Date("2022-01-01T14:39:35.618Z"),
    NextDesludge: new Date("2021-10-23T13:21:52.075Z"),
    NextSample: new Date("2021-12-16T04:41:13.390Z"),
    Comments:
      "The TCP pixel is down, program the mobile firewall so we can transmit the ADP protocol!",
    Size: "818.00",
    DateInspected: new Date("2020-08-12T23:39:32.238Z"),
    InspectionType: "Administrator",
    Officer: "Johnpaul Hoppe V",
    DateInspectedMonth: "August",
    DueStatus: "generating",
    Material: "Rubber",
    InspectionFrequency: 60353,
    ReferenceNumber: "46085",
    ContactName: "Kenneth",
    Compliance: "pixel",
    Reason:
      "I'll hack the virtual SSL microchip, that should alarm the GB transmitter!",
    RefNo: "24479",
    Description:
      "Use the mobile EXE transmitter, then you can calculate the optical port!",
    DateTarget: new Date("2020-10-12T07:36:02.481Z"),
    Source: "Garden",
    Category: "Awesome",
    Location: "Mozambique",
    RequestedBy: "Greyson",
    ContactDetails: "Koepp",
    DateRecorded: new Date("2021-01-26T23:09:59.868Z"),
    RecordedBy: "Shyanne Lockman",
    OrgStructure: "grey",
    ActionOfficer: "Bessie Hilpert",
    Coordinator: "Mellie Simonis",
    DateWork: new Date("2020-11-03T19:43:03.346Z"),
    EnquiryRefNo: "72853",
    Event_ReferenceNoAdditional: "63100",
    OnBehalfOf: "Quality",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-03-31T12:35:33.973Z"),
    Type: "matrix",
    DateDue: new Date("2020-05-06T07:20:16.876Z"),
    DateRevoked: new Date("2021-01-26T13:41:38.352Z"),
    Noticee: "Hassie",
    CreatedDate: "2020-05-09T14:42:17.258Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "902.00",
    AmountOutstanding: 60863,
    CostCode: "25222",
    InvoiceNumber: 31465,
    Name: "Williamson and Sons",
    SystemTypeClassification: "service-desk",
    SortIndex: 76895,
    Title: "Corporate",
    Condition: "repurpose calculating",
  },
  {
    ID: "14",
    PermitNumber: 68531,
    Applicant: "Sydnie Krajcik",
    Owner: "Kayley Rutherford DDS",
    Address: "Apt. 604",
    Suburb: "Vermont",
    AssessmentNo: 48363,
    Status: "Complied",
    TypeOfApplication: "Garden",
    FileNumber: 76890,
    SystemName: "bus",
    SystemType: "panel",
    Received: new Date("2021-01-26T20:14:17.756Z"),
    PTI: new Date("2021-01-26T11:54:54.423Z"),
    CTU: "CTU 14",
    WaterAuthority: "WaterAuthority 14",
    Plumber: "Joaquin Romaguera",
    Risk: "Risk Level 14",
    CertificateNo: 2950,
    SerialNo: "66635",
    Details: "Health Analyst teal",
    ApplicantAddress: "84045 Deckow Roads",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "136.00",
    GPS: "304.00",
    Manufacturer: "Johnston - Kshlerin",
    TankSize: 761.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Mayer - Kautzer",
    SecondarySystemType: "Books",
    SecondaryManufacturer: "Funk, Flatley and Sipes",
    SecondaryTankSize: "643.00",
    UnitSecondary: "UnitSecondary 14",
    TertiarySystemType: "Outdoors",
    TertiaryManufacturer: "Powlowski, Hodkiewicz and Altenwerth",
    TertiaryTankSize: "773.00",
    UnitTertiary: "UnitTertiary 14",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 14",
    Contractor: "Colton.Hessel61",
    RiskLevel: "RiskLevel 14",
    PermitType: "PermitType 14",
    ConstructionType: "ConstructionType 14",
    DebtorNo: 14936,
    FileNo: 31325,
    Issued: new Date("2020-05-23T23:48:30.271Z"),
    IssuingOfficer: "Leonor",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2021-01-11T00:19:12.824Z"),
    ApprovalOfficer: "Jadon Treutel III",
    OutstandingFees: "384.00",
    OSFees: "838.00",
    ComplainceCertNo: 52329,
    ApprovalForBL: new Date("2020-05-08T18:28:01.123Z"),
    BLicenceNumber: 21942,
    SentToDoH: new Date("2020-03-07T16:50:19.471Z"),
    DoHApproval: "DoHApproval 14",
    DateDoHApproved: new Date("2020-05-18T14:39:56.403Z"),
    DoHApprovalNo: "DoHApprovalNo 14",
    SerialNumber: 18901,
    LastInspection: new Date("2020-03-18T20:16:10.350Z"),
    Frequency: 4518,
    NextInspection: new Date("2021-09-15T14:37:37.555Z"),
    LastService: new Date("2020-10-13T00:09:07.853Z"),
    LastDesludge: new Date("2020-11-13T21:27:08.700Z"),
    LastSample: new Date("2020-12-10T15:46:27.015Z"),
    NextService: new Date("2021-05-13T15:24:31.194Z"),
    NextDesludge: new Date("2021-04-02T22:56:16.900Z"),
    NextSample: new Date("2021-08-24T01:13:12.331Z"),
    Comments:
      "The SAS card is down, bypass the open-source sensor so we can transmit the ADP interface!",
    Size: "416.00",
    DateInspected: new Date("2020-06-28T22:00:09.391Z"),
    InspectionType: "Facilitator",
    Officer: "Reese Senger",
    DateInspectedMonth: "July",
    DueStatus: "Lead",
    Material: "Plastic",
    InspectionFrequency: 3262,
    ReferenceNumber: "30858",
    ContactName: "Onie",
    Compliance: "hard drive",
    Reason:
      "I'll navigate the primary TCP hard drive, that should firewall the SQL program!",
    RefNo: "58255",
    Description:
      "You can't index the protocol without navigating the mobile SMS circuit!",
    DateTarget: new Date("2020-08-06T18:37:16.723Z"),
    Source: "Outdoors",
    Category: "Unbranded",
    Location: "Guatemala",
    RequestedBy: "Verda",
    ContactDetails: "Gislason",
    DateRecorded: new Date("2021-01-27T02:44:39.406Z"),
    RecordedBy: "Uriel Maggio",
    OrgStructure: "connecting",
    ActionOfficer: "Arielle Adams",
    Coordinator: "Rodolfo Beatty",
    DateWork: new Date("2020-10-18T21:46:52.161Z"),
    EnquiryRefNo: "35704",
    Event_ReferenceNoAdditional: "32108",
    OnBehalfOf: "Quality",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-08-19T19:05:44.176Z"),
    Type: "interface",
    DateDue: new Date("2020-08-24T15:25:42.905Z"),
    DateRevoked: new Date("2021-01-26T18:01:05.369Z"),
    Noticee: "Keyon",
    CreatedDate: "2021-01-05T16:58:42.249Z",
    FeeType: "Auto Loan Account",
    AmountIssued: "952.00",
    AmountOutstanding: 57116,
    CostCode: "64986",
    InvoiceNumber: 49337,
    Name: "O'Conner, Yost and Paucek",
    SystemTypeClassification: "Metrics",
    SortIndex: 12213,
    Title: "Dynamic",
    Condition: "transform grey Corporate",
  },
  {
    ID: "15",
    PermitNumber: 21819,
    Applicant: "Marc Balistreri",
    Owner: "Lea Hirthe",
    Address: "Suite 917",
    Suburb: "Texas",
    AssessmentNo: 89158,
    Status: "Complied",
    TypeOfApplication: "Music",
    FileNumber: 88659,
    SystemName: "monitor",
    SystemType: "alarm",
    Received: new Date("2021-01-26T10:54:08.839Z"),
    PTI: new Date("2021-01-27T00:28:38.973Z"),
    CTU: "CTU 15",
    WaterAuthority: "WaterAuthority 15",
    Plumber: "Julie Hartmann",
    Risk: "Risk Level 15",
    CertificateNo: 45578,
    SerialNo: "98870",
    Details: "open-source e-markets Bulgarian Lev",
    ApplicantAddress: "5725 Emile Summit",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "463.00",
    GPS: "193.00",
    Manufacturer: "Treutel, Mayert and Jakubowski",
    TankSize: 237.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Bartell Group",
    SecondarySystemType: "Industrial",
    SecondaryManufacturer: "Mosciski Inc",
    SecondaryTankSize: "472.00",
    UnitSecondary: "UnitSecondary 15",
    TertiarySystemType: "Automotive",
    TertiaryManufacturer: "McKenzie - Welch",
    TertiaryTankSize: "382.00",
    UnitTertiary: "UnitTertiary 15",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 15",
    Contractor: "Aurore_Ullrich17",
    RiskLevel: "RiskLevel 15",
    PermitType: "PermitType 15",
    ConstructionType: "ConstructionType 15",
    DebtorNo: 54840,
    FileNo: 881,
    Issued: new Date("2020-09-25T03:12:18.120Z"),
    IssuingOfficer: "Gayle",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-04-17T18:44:04.028Z"),
    ApprovalOfficer: "Arnaldo Terry",
    OutstandingFees: "924.00",
    OSFees: "519.00",
    ComplainceCertNo: 98032,
    ApprovalForBL: new Date("2020-04-08T20:34:31.797Z"),
    BLicenceNumber: 79479,
    SentToDoH: new Date("2020-03-26T02:04:58.449Z"),
    DoHApproval: "DoHApproval 15",
    DateDoHApproved: new Date("2021-01-08T15:57:49.787Z"),
    DoHApprovalNo: "DoHApprovalNo 15",
    SerialNumber: 16896,
    LastInspection: new Date("2020-07-16T19:51:13.380Z"),
    Frequency: 30751,
    NextInspection: new Date("2021-04-29T01:01:18.857Z"),
    LastService: new Date("2020-10-06T17:23:47.285Z"),
    LastDesludge: new Date("2020-08-18T03:06:20.797Z"),
    LastSample: new Date("2020-09-18T05:45:31.396Z"),
    NextService: new Date("2021-10-03T01:44:20.390Z"),
    NextDesludge: new Date("2021-02-17T12:48:13.574Z"),
    NextSample: new Date("2021-04-22T19:27:54.590Z"),
    Comments:
      "You can't override the program without overriding the haptic SSL protocol!",
    Size: "302.00",
    DateInspected: new Date("2020-12-03T14:49:09.371Z"),
    InspectionType: "Director",
    Officer: "Darrell Mosciski V",
    DateInspectedMonth: "September",
    DueStatus: "Personal Loan Account",
    Material: "Concrete",
    InspectionFrequency: 14595,
    ReferenceNumber: "18432",
    ContactName: "Emie",
    Compliance: "protocol",
    Reason: "I'll quantify the primary XML bus, that should feed the RAM port!",
    RefNo: "30975",
    Description:
      "The SDD panel is down, input the haptic port so we can navigate the SMS application!",
    DateTarget: new Date("2020-05-03T15:58:30.355Z"),
    Source: "Kids",
    Category: "Sleek",
    Location: "San Marino",
    RequestedBy: "Misty",
    ContactDetails: "Koepp",
    DateRecorded: new Date("2021-01-26T22:30:42.108Z"),
    RecordedBy: "Bella Nicolas",
    OrgStructure: "Customer",
    ActionOfficer: "Maiya Kovacek",
    Coordinator: "Melisa Heathcote",
    DateWork: new Date("2020-03-05T23:52:44.616Z"),
    EnquiryRefNo: "95626",
    Event_ReferenceNoAdditional: "79012",
    OnBehalfOf: "Functionality",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-12-19T13:07:00.210Z"),
    Type: "application",
    DateDue: new Date("2020-04-10T20:51:03.297Z"),
    DateRevoked: new Date("2021-01-26T13:47:30.184Z"),
    Noticee: "Bernard",
    CreatedDate: "2020-03-08T13:46:40.174Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "191.00",
    AmountOutstanding: 67840,
    CostCode: "20177",
    InvoiceNumber: 2983,
    Name: "Schuppe, Powlowski and Dietrich",
    SystemTypeClassification: "CSS",
    SortIndex: 89004,
    Title: "Lead",
    Condition: "engineer cross-platform",
  },
  {
    ID: "16",
    PermitNumber: 94961,
    Applicant: "Theresia Rohan",
    Owner: "Lucious Kutch",
    Address: "Apt. 231",
    Suburb: "New Mexico",
    AssessmentNo: 8135,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 34527,
    SystemName: "application",
    SystemType: "microchip",
    Received: new Date("2021-01-27T00:59:34.652Z"),
    PTI: new Date("2021-01-26T19:43:10.784Z"),
    CTU: "CTU 16",
    WaterAuthority: "WaterAuthority 16",
    Plumber: "Mrs. Britney Quigley",
    Risk: "Risk Level 16",
    CertificateNo: 50064,
    SerialNo: "31751",
    Details: "Montenegro XSS",
    ApplicantAddress: "061 Stamm Locks",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "403.00",
    GPS: "207.00",
    Manufacturer: "Bins Inc",
    TankSize: 26.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Gislason, Gerlach and Littel",
    SecondarySystemType: "Health",
    SecondaryManufacturer: "Boyer, Gislason and Stokes",
    SecondaryTankSize: "510.00",
    UnitSecondary: "UnitSecondary 16",
    TertiarySystemType: "Shoes",
    TertiaryManufacturer: "Willms Inc",
    TertiaryTankSize: "752.00",
    UnitTertiary: "UnitTertiary 16",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 16",
    Contractor: "Marion_Bashirian37",
    RiskLevel: "RiskLevel 16",
    PermitType: "PermitType 16",
    ConstructionType: "ConstructionType 16",
    DebtorNo: 54499,
    FileNo: 69102,
    Issued: new Date("2020-11-30T21:01:45.515Z"),
    IssuingOfficer: "Jenifer",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-04-11T02:15:52.058Z"),
    ApprovalOfficer: "Drew Herman",
    OutstandingFees: "966.00",
    OSFees: "372.00",
    ComplainceCertNo: 59033,
    ApprovalForBL: new Date("2020-10-08T08:14:36.404Z"),
    BLicenceNumber: 46258,
    SentToDoH: new Date("2020-05-02T05:50:59.460Z"),
    DoHApproval: "DoHApproval 16",
    DateDoHApproved: new Date("2020-06-04T00:37:09.734Z"),
    DoHApprovalNo: "DoHApprovalNo 16",
    SerialNumber: 2121,
    LastInspection: new Date("2020-04-10T19:54:36.617Z"),
    Frequency: 94315,
    NextInspection: new Date("2021-03-23T03:05:51.461Z"),
    LastService: new Date("2020-07-17T14:38:58.544Z"),
    LastDesludge: new Date("2020-07-02T11:51:43.991Z"),
    LastSample: new Date("2020-06-01T12:12:32.143Z"),
    NextService: new Date("2021-05-01T02:53:38.467Z"),
    NextDesludge: new Date("2021-11-06T05:03:03.075Z"),
    NextSample: new Date("2021-08-11T10:20:30.561Z"),
    Comments:
      "You can't parse the system without quantifying the mobile HDD interface!",
    Size: "571.00",
    DateInspected: new Date("2020-03-22T07:58:22.504Z"),
    InspectionType: "Liaison",
    Officer: "Abelardo Stehr",
    DateInspectedMonth: "April",
    DueStatus: "action-items",
    Material: "Rubber",
    InspectionFrequency: 60392,
    ReferenceNumber: "37587",
    ContactName: "Fanny",
    Compliance: "monitor",
    Reason:
      "Use the bluetooth THX pixel, then you can parse the primary pixel!",
    RefNo: "33841",
    Description:
      "Use the auxiliary GB hard drive, then you can compress the cross-platform bandwidth!",
    DateTarget: new Date("2020-12-22T11:31:22.430Z"),
    Source: "Music",
    Category: "Rustic",
    Location: "Bolivia",
    RequestedBy: "Vesta",
    ContactDetails: "McDermott",
    DateRecorded: new Date("2021-01-26T20:07:16.763Z"),
    RecordedBy: "Krystel Morissette",
    OrgStructure: "strategize",
    ActionOfficer: "Lacy Ward",
    Coordinator: "Miss Gilberto O'Hara",
    DateWork: new Date("2020-05-04T07:38:57.208Z"),
    EnquiryRefNo: "44492",
    Event_ReferenceNoAdditional: "44686",
    OnBehalfOf: "Mobility",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-11-07T02:28:22.762Z"),
    Type: "firewall",
    DateDue: new Date("2020-11-06T15:38:17.919Z"),
    DateRevoked: new Date("2021-01-26T18:56:09.511Z"),
    Noticee: "Keenan",
    CreatedDate: "2020-08-20T09:40:52.290Z",
    FeeType: "Credit Card Account",
    AmountIssued: "822.00",
    AmountOutstanding: 2701,
    CostCode: "84497-6475",
    InvoiceNumber: 17960,
    Name: "Bernhard - Zboncak",
    SystemTypeClassification: "olive",
    SortIndex: 79757,
    Title: "Forward",
    Condition: "Dynamic European Monetary Unit (E.M.U.-6) cultivate",
  },
  {
    ID: "17",
    PermitNumber: 76845,
    Applicant: "Jadyn Lockman",
    Owner: "Mateo Friesen",
    Address: "Apt. 610",
    Suburb: "Alaska",
    AssessmentNo: 49466,
    Status: "Complied",
    TypeOfApplication: "Health",
    FileNumber: 7970,
    SystemName: "port",
    SystemType: "interface",
    Received: new Date("2021-01-26T08:26:35.318Z"),
    PTI: new Date("2021-01-27T04:02:08.011Z"),
    CTU: "CTU 17",
    WaterAuthority: "WaterAuthority 17",
    Plumber: "Micah MacGyver",
    Risk: "Risk Level 17",
    CertificateNo: 82632,
    SerialNo: "87676-4207",
    Details: "Computer Electronics",
    ApplicantAddress: "641 Bernier Hollow",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "584.00",
    GPS: "568.00",
    Manufacturer: "Harvey, Rolfson and Jacobi",
    TankSize: 689.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Konopelski - Schultz",
    SecondarySystemType: "Beauty",
    SecondaryManufacturer: "Romaguera - Schaefer",
    SecondaryTankSize: "560.00",
    UnitSecondary: "UnitSecondary 17",
    TertiarySystemType: "Health",
    TertiaryManufacturer: "Kuhlman LLC",
    TertiaryTankSize: "426.00",
    UnitTertiary: "UnitTertiary 17",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 17",
    Contractor: "Euna_Christiansen",
    RiskLevel: "RiskLevel 17",
    PermitType: "PermitType 17",
    ConstructionType: "ConstructionType 17",
    DebtorNo: 71921,
    FileNo: 86954,
    Issued: new Date("2020-04-12T16:34:06.538Z"),
    IssuingOfficer: "Selina",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-02-09T02:21:41.446Z"),
    ApprovalOfficer: "Ms. Janie Wiza",
    OutstandingFees: "199.00",
    OSFees: "927.00",
    ComplainceCertNo: 84505,
    ApprovalForBL: new Date("2020-09-30T06:43:03.722Z"),
    BLicenceNumber: 95572,
    SentToDoH: new Date("2020-08-04T03:34:05.644Z"),
    DoHApproval: "DoHApproval 17",
    DateDoHApproved: new Date("2020-05-06T21:49:38.719Z"),
    DoHApprovalNo: "DoHApprovalNo 17",
    SerialNumber: 66142,
    LastInspection: new Date("2020-05-30T13:10:09.009Z"),
    Frequency: 60130,
    NextInspection: new Date("2021-04-09T22:47:45.539Z"),
    LastService: new Date("2020-05-29T15:05:48.524Z"),
    LastDesludge: new Date("2020-07-01T18:30:43.333Z"),
    LastSample: new Date("2020-05-11T01:05:04.963Z"),
    NextService: new Date("2021-05-03T03:56:53.338Z"),
    NextDesludge: new Date("2021-09-15T17:16:24.429Z"),
    NextSample: new Date("2021-10-13T11:18:56.903Z"),
    Comments:
      "You can't input the system without bypassing the mobile HDD application!",
    Size: "236.00",
    DateInspected: new Date("2020-05-25T23:56:59.468Z"),
    InspectionType: "Officer",
    Officer: "Otho Ruecker",
    DateInspectedMonth: "December",
    DueStatus: "Reverse-engineered",
    Material: "Concrete",
    InspectionFrequency: 97269,
    ReferenceNumber: "69899",
    ContactName: "Estelle",
    Compliance: "circuit",
    Reason:
      "You can't transmit the monitor without navigating the auxiliary SAS hard drive!",
    RefNo: "93087",
    Description:
      "The XSS application is down, override the 1080p hard drive so we can navigate the RAM application!",
    DateTarget: new Date("2020-04-17T07:21:48.637Z"),
    Source: "Garden",
    Category: "Sleek",
    Location: "Norway",
    RequestedBy: "Annabelle",
    ContactDetails: "Reynolds",
    DateRecorded: new Date("2021-01-26T23:43:05.876Z"),
    RecordedBy: "Carley Lind",
    OrgStructure: "withdrawal",
    ActionOfficer: "Carmelo Tromp",
    Coordinator: "Charlotte Runolfsdottir MD",
    DateWork: new Date("2020-05-16T17:27:57.530Z"),
    EnquiryRefNo: "25821",
    Event_ReferenceNoAdditional: "34017",
    OnBehalfOf: "Paradigm",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2021-01-21T04:11:32.197Z"),
    Type: "array",
    DateDue: new Date("2020-11-24T12:53:05.827Z"),
    DateRevoked: new Date("2021-01-27T05:28:46.176Z"),
    Noticee: "Malachi",
    CreatedDate: "2020-07-29T01:30:22.287Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "582.00",
    AmountOutstanding: 65114,
    CostCode: "04696",
    InvoiceNumber: 20430,
    Name: "Rogahn Inc",
    SystemTypeClassification: "Salad",
    SortIndex: 44725,
    Title: "Customer",
    Condition: "azure engage Vatu",
  },
  {
    ID: "18",
    PermitNumber: 44542,
    Applicant: "Miss Rahul Blick",
    Owner: "Marion Gutmann",
    Address: "Apt. 444",
    Suburb: "Washington",
    AssessmentNo: 29703,
    Status: "Complied",
    TypeOfApplication: "Health",
    FileNumber: 45899,
    SystemName: "array",
    SystemType: "system",
    Received: new Date("2021-01-26T14:59:36.623Z"),
    PTI: new Date("2021-01-26T14:40:53.138Z"),
    CTU: "CTU 18",
    WaterAuthority: "WaterAuthority 18",
    Plumber: "Mathias Christiansen",
    Risk: "Risk Level 18",
    CertificateNo: 47082,
    SerialNo: "53071",
    Details: "Money Market Account National",
    ApplicantAddress: "9424 Robin Square",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "162.00",
    GPS: "998.00",
    Manufacturer: "Toy - Zboncak",
    TankSize: 537.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Roob, Bailey and Lubowitz",
    SecondarySystemType: "Kids",
    SecondaryManufacturer: "Pacocha, Effertz and Christiansen",
    SecondaryTankSize: "397.00",
    UnitSecondary: "UnitSecondary 18",
    TertiarySystemType: "Clothing",
    TertiaryManufacturer: "Berge - Bartoletti",
    TertiaryTankSize: "790.00",
    UnitTertiary: "UnitTertiary 18",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 18",
    Contractor: "Furman_Conn97",
    RiskLevel: "RiskLevel 18",
    PermitType: "PermitType 18",
    ConstructionType: "ConstructionType 18",
    DebtorNo: 75143,
    FileNo: 45117,
    Issued: new Date("2020-08-31T10:14:24.882Z"),
    IssuingOfficer: "Corbin",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-04-25T00:30:24.843Z"),
    ApprovalOfficer: "Jovani Casper",
    OutstandingFees: "305.00",
    OSFees: "357.00",
    ComplainceCertNo: 79206,
    ApprovalForBL: new Date("2020-10-07T10:40:13.394Z"),
    BLicenceNumber: 59365,
    SentToDoH: new Date("2020-11-16T00:16:13.758Z"),
    DoHApproval: "DoHApproval 18",
    DateDoHApproved: new Date("2020-05-16T07:42:24.942Z"),
    DoHApprovalNo: "DoHApprovalNo 18",
    SerialNumber: 42657,
    LastInspection: new Date("2021-01-11T18:23:28.019Z"),
    Frequency: 23349,
    NextInspection: new Date("2021-05-28T11:57:48.812Z"),
    LastService: new Date("2020-05-26T21:21:50.581Z"),
    LastDesludge: new Date("2020-02-11T08:46:18.572Z"),
    LastSample: new Date("2020-07-21T23:38:53.115Z"),
    NextService: new Date("2021-05-13T18:11:56.123Z"),
    NextDesludge: new Date("2022-01-26T11:23:16.094Z"),
    NextSample: new Date("2022-01-26T02:21:40.235Z"),
    Comments:
      "You can't bypass the matrix without indexing the auxiliary CSS monitor!",
    Size: "914.00",
    DateInspected: new Date("2020-12-02T00:02:33.192Z"),
    InspectionType: "Administrator",
    Officer: "Eli Harris",
    DateInspectedMonth: "May",
    DueStatus: "disintermediate",
    Material: "Rubber",
    InspectionFrequency: 31025,
    ReferenceNumber: "3351",
    ContactName: "Gillian",
    Compliance: "array",
    Reason:
      "If we calculate the driver, we can get to the HTTP capacitor through the multi-byte JSON monitor!",
    RefNo: "60585",
    Description: "We need to compress the neural HDD array!",
    DateTarget: new Date("2021-01-05T13:49:39.675Z"),
    Source: "Garden",
    Category: "Rustic",
    Location: "Tokelau",
    RequestedBy: "Joannie",
    ContactDetails: "Marquardt",
    DateRecorded: new Date("2021-01-26T23:22:01.172Z"),
    RecordedBy: "Helena Jenkins",
    OrgStructure: "optical",
    ActionOfficer: "Mr. Carol Rempel",
    Coordinator: "Jerome Ankunding",
    DateWork: new Date("2020-05-16T21:15:05.747Z"),
    EnquiryRefNo: "71293",
    Event_ReferenceNoAdditional: "61383",
    OnBehalfOf: "Creative",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-09-10T19:05:45.085Z"),
    Type: "transmitter",
    DateDue: new Date("2020-11-02T12:08:45.884Z"),
    DateRevoked: new Date("2021-01-26T12:13:43.364Z"),
    Noticee: "Gilberto",
    CreatedDate: "2020-02-03T12:19:00.576Z",
    FeeType: "Investment Account",
    AmountIssued: "843.00",
    AmountOutstanding: 87587,
    CostCode: "47126",
    InvoiceNumber: 74367,
    Name: "Pfeffer, Ankunding and Hermann",
    SystemTypeClassification: "frictionless",
    SortIndex: 85313,
    Title: "Future",
    Condition: "Specialist",
  },
  {
    ID: "19",
    PermitNumber: 89528,
    Applicant: "Ellsworth Cremin",
    Owner: "Dorthy Schuppe",
    Address: "Apt. 628",
    Suburb: "Kansas",
    AssessmentNo: 49279,
    Status: "Complied",
    TypeOfApplication: "Automotive",
    FileNumber: 45314,
    SystemName: "pixel",
    SystemType: "protocol",
    Received: new Date("2021-01-26T17:58:24.617Z"),
    PTI: new Date("2021-01-27T00:15:22.333Z"),
    CTU: "CTU 19",
    WaterAuthority: "WaterAuthority 19",
    Plumber: "Mr. Lynn Harber",
    Risk: "Risk Level 19",
    CertificateNo: 64636,
    SerialNo: "56875",
    Details: "Borders",
    ApplicantAddress: "46658 Dach Avenue",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "581.00",
    GPS: "929.00",
    Manufacturer: "O'Keefe - Nolan",
    TankSize: 125.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Gleason - Berge",
    SecondarySystemType: "Toys",
    SecondaryManufacturer: "Kerluke Inc",
    SecondaryTankSize: "685.00",
    UnitSecondary: "UnitSecondary 19",
    TertiarySystemType: "Beauty",
    TertiaryManufacturer: "Goyette, Bauch and Kub",
    TertiaryTankSize: "816.00",
    UnitTertiary: "UnitTertiary 19",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 19",
    Contractor: "Amie.Hickle9",
    RiskLevel: "RiskLevel 19",
    PermitType: "PermitType 19",
    ConstructionType: "ConstructionType 19",
    DebtorNo: 85935,
    FileNo: 68569,
    Issued: new Date("2020-03-23T00:49:11.969Z"),
    IssuingOfficer: "Dangelo",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-07-07T22:30:56.275Z"),
    ApprovalOfficer: "Harold Jacobs",
    OutstandingFees: "170.00",
    OSFees: "218.00",
    ComplainceCertNo: 98,
    ApprovalForBL: new Date("2020-08-10T17:59:59.737Z"),
    BLicenceNumber: 56527,
    SentToDoH: new Date("2020-07-04T08:14:49.661Z"),
    DoHApproval: "DoHApproval 19",
    DateDoHApproved: new Date("2020-06-09T05:38:05.522Z"),
    DoHApprovalNo: "DoHApprovalNo 19",
    SerialNumber: 90115,
    LastInspection: new Date("2020-03-13T20:21:47.987Z"),
    Frequency: 82610,
    NextInspection: new Date("2021-06-27T06:58:00.235Z"),
    LastService: new Date("2020-03-22T05:12:20.127Z"),
    LastDesludge: new Date("2020-07-11T15:26:13.950Z"),
    LastSample: new Date("2020-11-06T15:52:45.174Z"),
    NextService: new Date("2021-02-17T23:18:19.043Z"),
    NextDesludge: new Date("2021-06-23T02:27:18.905Z"),
    NextSample: new Date("2021-05-19T19:22:01.102Z"),
    Comments:
      "I'll synthesize the multi-byte JSON bandwidth, that should program the GB array!",
    Size: "870.00",
    DateInspected: new Date("2021-01-11T20:42:05.892Z"),
    InspectionType: "Engineer",
    Officer: "Ruben Lynch",
    DateInspectedMonth: "December",
    DueStatus: "program",
    Material: "Cotton",
    InspectionFrequency: 48864,
    ReferenceNumber: "5649",
    ContactName: "Itzel",
    Compliance: "array",
    Reason:
      "If we synthesize the monitor, we can get to the IB sensor through the auxiliary XML interface!",
    RefNo: "14374",
    Description:
      "overriding the bus won't do anything, we need to override the neural AGP card!",
    DateTarget: new Date("2020-10-17T06:09:42.830Z"),
    Source: "Sports",
    Category: "Rustic",
    Location: "Macao",
    RequestedBy: "Giovanni",
    ContactDetails: "Prohaska",
    DateRecorded: new Date("2021-01-26T15:21:41.431Z"),
    RecordedBy: "Mrs. Justine Wolff",
    OrgStructure: "Designer",
    ActionOfficer: "Jermey Weissnat",
    Coordinator: "Johnson Grady",
    DateWork: new Date("2020-12-22T01:42:48.610Z"),
    EnquiryRefNo: "22846",
    Event_ReferenceNoAdditional: "34664",
    OnBehalfOf: "Paradigm",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-10-03T14:00:41.994Z"),
    Type: "sensor",
    DateDue: new Date("2020-06-27T03:47:18.791Z"),
    DateRevoked: new Date("2021-01-27T05:14:21.047Z"),
    Noticee: "Camryn",
    CreatedDate: "2020-03-02T06:20:25.551Z",
    FeeType: "Home Loan Account",
    AmountIssued: "362.00",
    AmountOutstanding: 132,
    CostCode: "30376",
    InvoiceNumber: 91856,
    Name: "Prosacco and Sons",
    SystemTypeClassification: "Washington",
    SortIndex: 89400,
    Title: "Forward",
    Condition: "attitude",
  },
  {
    ID: "20",
    PermitNumber: 94359,
    Applicant: "Lisa Schultz",
    Owner: "Adella Shields",
    Address: "Suite 605",
    Suburb: "Ohio",
    AssessmentNo: 88734,
    Status: "Complied",
    TypeOfApplication: "Music",
    FileNumber: 67585,
    SystemName: "hard drive",
    SystemType: "alarm",
    Received: new Date("2021-01-26T09:18:28.603Z"),
    PTI: new Date("2021-01-26T15:29:10.615Z"),
    CTU: "CTU 20",
    WaterAuthority: "WaterAuthority 20",
    Plumber: "Ms. Braulio Jenkins",
    Risk: "Risk Level 20",
    CertificateNo: 90893,
    SerialNo: "65272",
    Details: "artificial intelligence multi-byte",
    ApplicantAddress: "82269 Littel Stream",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "161.00",
    GPS: "979.00",
    Manufacturer: "Sporer and Sons",
    TankSize: 575.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Schamberger, Wiza and Goodwin",
    SecondarySystemType: "Garden",
    SecondaryManufacturer: "Schmitt, Roob and Johnston",
    SecondaryTankSize: "294.00",
    UnitSecondary: "UnitSecondary 20",
    TertiarySystemType: "Outdoors",
    TertiaryManufacturer: "Boyer - Abshire",
    TertiaryTankSize: "792.00",
    UnitTertiary: "UnitTertiary 20",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 20",
    Contractor: "Laila_Harvey42",
    RiskLevel: "RiskLevel 20",
    PermitType: "PermitType 20",
    ConstructionType: "ConstructionType 20",
    DebtorNo: 16663,
    FileNo: 17818,
    Issued: new Date("2020-02-24T02:23:52.015Z"),
    IssuingOfficer: "Laverne",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-09-05T07:00:59.986Z"),
    ApprovalOfficer: "Mr. Marielle Gutkowski",
    OutstandingFees: "857.00",
    OSFees: "304.00",
    ComplainceCertNo: 80633,
    ApprovalForBL: new Date("2020-07-21T19:10:22.107Z"),
    BLicenceNumber: 99882,
    SentToDoH: new Date("2020-06-15T17:17:52.862Z"),
    DoHApproval: "DoHApproval 20",
    DateDoHApproved: new Date("2020-05-18T00:40:13.986Z"),
    DoHApprovalNo: "DoHApprovalNo 20",
    SerialNumber: 85870,
    LastInspection: new Date("2020-07-23T15:14:06.035Z"),
    Frequency: 41645,
    NextInspection: new Date("2021-07-09T12:12:52.134Z"),
    LastService: new Date("2021-01-11T14:30:31.395Z"),
    LastDesludge: new Date("2020-07-06T23:49:18.767Z"),
    LastSample: new Date("2020-09-14T01:49:31.607Z"),
    NextService: new Date("2021-11-16T01:46:54.758Z"),
    NextDesludge: new Date("2021-11-23T07:48:06.561Z"),
    NextSample: new Date("2021-03-06T13:58:05.025Z"),
    Comments:
      "I'll override the virtual PCI port, that should card the PNG transmitter!",
    Size: "213.00",
    DateInspected: new Date("2021-01-25T09:47:28.401Z"),
    InspectionType: "Developer",
    Officer: "Antonetta Huel",
    DateInspectedMonth: "June",
    DueStatus: "Avon",
    Material: "Soft",
    InspectionFrequency: 81948,
    ReferenceNumber: "12492",
    ContactName: "Emilia",
    Compliance: "monitor",
    Reason:
      "copying the application won't do anything, we need to copy the primary THX bus!",
    RefNo: "93571",
    Description:
      "If we override the sensor, we can get to the COM capacitor through the redundant THX panel!",
    DateTarget: new Date("2020-06-12T21:17:28.300Z"),
    Source: "Home",
    Category: "Unbranded",
    Location: "Slovenia",
    RequestedBy: "Chaya",
    ContactDetails: "Schinner",
    DateRecorded: new Date("2021-01-26T10:58:35.372Z"),
    RecordedBy: "Mrs. Mario Stokes",
    OrgStructure: "Hungary",
    ActionOfficer: "Talia Quigley",
    Coordinator: "Deborah Donnelly",
    DateWork: new Date("2020-04-29T05:06:32.280Z"),
    EnquiryRefNo: "86338",
    Event_ReferenceNoAdditional: "86329",
    OnBehalfOf: "Paradigm",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-09-30T23:12:16.573Z"),
    Type: "protocol",
    DateDue: new Date("2020-02-15T23:14:08.002Z"),
    DateRevoked: new Date("2021-01-27T03:56:56.108Z"),
    Noticee: "Mustafa",
    CreatedDate: "2020-12-11T20:32:32.388Z",
    FeeType: "Savings Account",
    AmountIssued: "126.00",
    AmountOutstanding: 94723,
    CostCode: "63337",
    InvoiceNumber: 52517,
    Name: "Schuster and Sons",
    SystemTypeClassification: "discrete",
    SortIndex: 43761,
    Title: "Legacy",
    Condition: "Personal Loan Account disintermediate Computer",
  },
  {
    ID: "21",
    PermitNumber: 92613,
    Applicant: "Johnathan Lueilwitz",
    Owner: "Cicero Kilback",
    Address: "Apt. 328",
    Suburb: "Missouri",
    AssessmentNo: 83058,
    Status: "Complied",
    TypeOfApplication: "Automotive",
    FileNumber: 28968,
    SystemName: "bus",
    SystemType: "firewall",
    Received: new Date("2021-01-27T04:47:55.165Z"),
    PTI: new Date("2021-01-26T22:32:31.254Z"),
    CTU: "CTU 21",
    WaterAuthority: "WaterAuthority 21",
    Plumber: "Kari Bergstrom Jr.",
    Risk: "Risk Level 21",
    CertificateNo: 2130,
    SerialNo: "12892-7081",
    Details: "withdrawal",
    ApplicantAddress: "89710 Jaylan Courts",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "445.00",
    GPS: "335.00",
    Manufacturer: "Herzog, Nikolaus and Bauch",
    TankSize: 348.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Mills - Feil",
    SecondarySystemType: "Books",
    SecondaryManufacturer: "Herzog, Fadel and Glover",
    SecondaryTankSize: "862.00",
    UnitSecondary: "UnitSecondary 21",
    TertiarySystemType: "Clothing",
    TertiaryManufacturer: "Smitham LLC",
    TertiaryTankSize: "357.00",
    UnitTertiary: "UnitTertiary 21",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 21",
    Contractor: "Nichole.Walker63",
    RiskLevel: "RiskLevel 21",
    PermitType: "PermitType 21",
    ConstructionType: "ConstructionType 21",
    DebtorNo: 34185,
    FileNo: 94524,
    Issued: new Date("2020-09-30T03:48:45.723Z"),
    IssuingOfficer: "Ressie",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-09-06T07:27:09.823Z"),
    ApprovalOfficer: "Alisa Osinski",
    OutstandingFees: "921.00",
    OSFees: "902.00",
    ComplainceCertNo: 63797,
    ApprovalForBL: new Date("2020-05-28T06:04:26.989Z"),
    BLicenceNumber: 44816,
    SentToDoH: new Date("2020-04-18T20:29:50.738Z"),
    DoHApproval: "DoHApproval 21",
    DateDoHApproved: new Date("2020-02-17T16:40:21.727Z"),
    DoHApprovalNo: "DoHApprovalNo 21",
    SerialNumber: 51189,
    LastInspection: new Date("2020-03-23T02:59:33.494Z"),
    Frequency: 97469,
    NextInspection: new Date("2021-04-13T13:22:11.690Z"),
    LastService: new Date("2020-09-23T14:46:44.597Z"),
    LastDesludge: new Date("2021-01-21T00:28:58.128Z"),
    LastSample: new Date("2020-08-22T23:31:35.906Z"),
    NextService: new Date("2021-07-31T05:41:40.646Z"),
    NextDesludge: new Date("2021-02-05T19:03:14.429Z"),
    NextSample: new Date("2021-02-11T22:31:37.337Z"),
    Comments:
      "You can't back up the interface without hacking the primary SAS bus!",
    Size: "734.00",
    DateInspected: new Date("2021-01-05T20:09:40.445Z"),
    InspectionType: "Analyst",
    Officer: "Cathrine Hermann",
    DateInspectedMonth: "February",
    DueStatus: "Engineer",
    Material: "Cotton",
    InspectionFrequency: 24313,
    ReferenceNumber: "51062",
    ContactName: "Antwon",
    Compliance: "microchip",
    Reason:
      "Try to back up the SQL array, maybe it will back up the digital panel!",
    RefNo: "17850",
    Description:
      "Use the cross-platform JBOD application, then you can navigate the open-source circuit!",
    DateTarget: new Date("2020-03-09T01:12:44.280Z"),
    Source: "Electronics",
    Category: "Fantastic",
    Location: "United States of America",
    RequestedBy: "Gillian",
    ContactDetails: "Purdy",
    DateRecorded: new Date("2021-01-27T02:33:21.786Z"),
    RecordedBy: "Miss Rosalind Howell",
    OrgStructure: "Licensed",
    ActionOfficer: "Josephine Rogahn",
    Coordinator: "Oceane Marvin",
    DateWork: new Date("2020-07-10T16:26:24.973Z"),
    EnquiryRefNo: "81219",
    Event_ReferenceNoAdditional: "61230",
    OnBehalfOf: "Solutions",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-06-19T04:47:02.392Z"),
    Type: "panel",
    DateDue: new Date("2020-07-01T07:26:03.050Z"),
    DateRevoked: new Date("2021-01-26T16:55:59.692Z"),
    Noticee: "Santos",
    CreatedDate: "2020-03-23T17:07:53.344Z",
    FeeType: "Investment Account",
    AmountIssued: "320.00",
    AmountOutstanding: 97344,
    CostCode: "67414-3503",
    InvoiceNumber: 39401,
    Name: "Wiegand Group",
    SystemTypeClassification: "open system",
    SortIndex: 86704,
    Title: "Lead",
    Condition: "invoice",
  },
  {
    ID: "22",
    PermitNumber: 85059,
    Applicant: "Maxine Muller",
    Owner: "Mateo Reilly",
    Address: "Suite 173",
    Suburb: "Missouri",
    AssessmentNo: 90830,
    Status: "Complied",
    TypeOfApplication: "Sports",
    FileNumber: 86811,
    SystemName: "transmitter",
    SystemType: "interface",
    Received: new Date("2021-01-26T10:09:31.161Z"),
    PTI: new Date("2021-01-27T04:12:48.517Z"),
    CTU: "CTU 22",
    WaterAuthority: "WaterAuthority 22",
    Plumber: "Kaitlin Mosciski",
    Risk: "Risk Level 22",
    CertificateNo: 15085,
    SerialNo: "54038-8845",
    Details: "Ohio Investment Account wireless",
    ApplicantAddress: "47847 Oberbrunner Villages",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "903.00",
    GPS: "547.00",
    Manufacturer: "Schneider Inc",
    TankSize: 13.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Beer, Emmerich and Ziemann",
    SecondarySystemType: "Movies",
    SecondaryManufacturer: "Renner and Sons",
    SecondaryTankSize: "233.00",
    UnitSecondary: "UnitSecondary 22",
    TertiarySystemType: "Health",
    TertiaryManufacturer: "Wuckert, Gibson and Legros",
    TertiaryTankSize: "214.00",
    UnitTertiary: "UnitTertiary 22",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 22",
    Contractor: "Kara0",
    RiskLevel: "RiskLevel 22",
    PermitType: "PermitType 22",
    ConstructionType: "ConstructionType 22",
    DebtorNo: 51687,
    FileNo: 39260,
    Issued: new Date("2020-02-02T13:49:21.796Z"),
    IssuingOfficer: "Minnie",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-10-04T00:07:47.564Z"),
    ApprovalOfficer: "Karley Fisher",
    OutstandingFees: "618.00",
    OSFees: "804.00",
    ComplainceCertNo: 67727,
    ApprovalForBL: new Date("2020-09-05T00:33:31.619Z"),
    BLicenceNumber: 48270,
    SentToDoH: new Date("2021-01-07T09:34:59.948Z"),
    DoHApproval: "DoHApproval 22",
    DateDoHApproved: new Date("2020-03-26T08:42:22.097Z"),
    DoHApprovalNo: "DoHApprovalNo 22",
    SerialNumber: 12003,
    LastInspection: new Date("2020-05-02T13:41:45.975Z"),
    Frequency: 69567,
    NextInspection: new Date("2021-01-28T20:07:41.408Z"),
    LastService: new Date("2020-09-02T12:25:20.918Z"),
    LastDesludge: new Date("2020-11-20T05:28:43.329Z"),
    LastSample: new Date("2020-12-30T00:48:26.999Z"),
    NextService: new Date("2021-11-28T22:22:09.689Z"),
    NextDesludge: new Date("2021-10-30T20:57:33.792Z"),
    NextSample: new Date("2021-05-18T11:21:47.226Z"),
    Comments:
      "Try to transmit the CSS program, maybe it will connect the cross-platform hard drive!",
    Size: "289.00",
    DateInspected: new Date("2020-02-18T17:09:38.953Z"),
    InspectionType: "Officer",
    Officer: "Audreanne Hammes",
    DateInspectedMonth: "August",
    DueStatus: "Chicken",
    Material: "Granite",
    InspectionFrequency: 24309,
    ReferenceNumber: "10220",
    ContactName: "Antonia",
    Compliance: "pixel",
    Reason:
      "The SAS system is down, calculate the primary microchip so we can transmit the XML hard drive!",
    RefNo: "392",
    Description:
      "You can't copy the microchip without overriding the bluetooth CSS capacitor!",
    DateTarget: new Date("2020-08-12T19:20:27.654Z"),
    Source: "Jewelery",
    Category: "Licensed",
    Location: "Heard Island and McDonald Islands",
    RequestedBy: "Jeramy",
    ContactDetails: "Deckow",
    DateRecorded: new Date("2021-01-26T21:40:41.600Z"),
    RecordedBy: "Tavares Barton",
    OrgStructure: "Personal Loan Account",
    ActionOfficer: "Mr. Myrl Stehr",
    Coordinator: "Ms. Eliseo Lang",
    DateWork: new Date("2020-09-29T13:45:31.368Z"),
    EnquiryRefNo: "91692",
    Event_ReferenceNoAdditional: "25600",
    OnBehalfOf: "Operations",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-10-31T23:55:40.309Z"),
    Type: "bandwidth",
    DateDue: new Date("2021-01-19T14:33:58.725Z"),
    DateRevoked: new Date("2021-01-26T18:35:49.340Z"),
    Noticee: "Nina",
    CreatedDate: "2020-11-23T11:04:02.985Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "613.00",
    AmountOutstanding: 93488,
    CostCode: "14890-6197",
    InvoiceNumber: 72146,
    Name: "Schulist and Sons",
    SystemTypeClassification: "Tasty",
    SortIndex: 67090,
    Title: "Corporate",
    Condition: "iterate Integrated PNG",
  },
  {
    ID: "23",
    PermitNumber: 3127,
    Applicant: "Tiana Bashirian",
    Owner: "Scarlett Adams",
    Address: "Apt. 689",
    Suburb: "Vermont",
    AssessmentNo: 91941,
    Status: "Complied",
    TypeOfApplication: "Home",
    FileNumber: 2936,
    SystemName: "program",
    SystemType: "application",
    Received: new Date("2021-01-26T23:16:25.430Z"),
    PTI: new Date("2021-01-26T11:41:56.408Z"),
    CTU: "CTU 23",
    WaterAuthority: "WaterAuthority 23",
    Plumber: "Emily Hansen",
    Risk: "Risk Level 23",
    CertificateNo: 17785,
    SerialNo: "98698",
    Details: "Lead connect Morocco",
    ApplicantAddress: "263 Schamberger Course",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "598.00",
    GPS: "548.00",
    Manufacturer: "Koelpin, Hessel and Hane",
    TankSize: 875.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Pfannerstill, Rippin and Collins",
    SecondarySystemType: "Electronics",
    SecondaryManufacturer: "Schmidt LLC",
    SecondaryTankSize: "463.00",
    UnitSecondary: "UnitSecondary 23",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Murray, Strosin and Glover",
    TertiaryTankSize: "613.00",
    UnitTertiary: "UnitTertiary 23",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 23",
    Contractor: "Jevon_Pagac71",
    RiskLevel: "RiskLevel 23",
    PermitType: "PermitType 23",
    ConstructionType: "ConstructionType 23",
    DebtorNo: 53148,
    FileNo: 34358,
    Issued: new Date("2021-01-26T06:34:06.254Z"),
    IssuingOfficer: "Oren",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-05-28T17:45:24.278Z"),
    ApprovalOfficer: "Janessa Kunde",
    OutstandingFees: "524.00",
    OSFees: "55.00",
    ComplainceCertNo: 11943,
    ApprovalForBL: new Date("2020-12-12T10:07:46.845Z"),
    BLicenceNumber: 12183,
    SentToDoH: new Date("2020-09-21T00:08:23.924Z"),
    DoHApproval: "DoHApproval 23",
    DateDoHApproved: new Date("2020-04-01T07:02:43.170Z"),
    DoHApprovalNo: "DoHApprovalNo 23",
    SerialNumber: 32897,
    LastInspection: new Date("2020-11-20T04:20:05.732Z"),
    Frequency: 74632,
    NextInspection: new Date("2021-08-09T14:21:46.043Z"),
    LastService: new Date("2020-02-16T15:17:10.039Z"),
    LastDesludge: new Date("2020-10-10T07:37:27.002Z"),
    LastSample: new Date("2020-08-03T08:25:53.756Z"),
    NextService: new Date("2022-01-17T19:09:54.578Z"),
    NextDesludge: new Date("2021-07-10T14:15:29.934Z"),
    NextSample: new Date("2021-02-12T08:53:03.373Z"),
    Comments: "We need to calculate the primary USB bus!",
    Size: "929.00",
    DateInspected: new Date("2020-03-24T09:07:24.561Z"),
    InspectionType: "Strategist",
    Officer: "Greta Reilly DDS",
    DateInspectedMonth: "September",
    DueStatus: "Bahamas",
    Material: "Metal",
    InspectionFrequency: 53986,
    ReferenceNumber: "41196",
    ContactName: "Janet",
    Compliance: "monitor",
    Reason:
      "Use the primary EXE panel, then you can override the virtual circuit!",
    RefNo: "18901",
    Description:
      "I'll synthesize the wireless SDD firewall, that should protocol the HDD transmitter!",
    DateTarget: new Date("2020-10-26T08:48:22.273Z"),
    Source: "Kids",
    Category: "Handmade",
    Location: "Japan",
    RequestedBy: "Francisca",
    ContactDetails: "Schowalter",
    DateRecorded: new Date("2021-01-26T14:12:39.656Z"),
    RecordedBy: "Johanna Lehner",
    OrgStructure: "Compatible",
    ActionOfficer: "Jerrod Bruen",
    Coordinator: "Tate Nader",
    DateWork: new Date("2020-04-09T10:05:57.133Z"),
    EnquiryRefNo: "97909",
    Event_ReferenceNoAdditional: "5287",
    OnBehalfOf: "Quality",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2021-01-01T00:09:37.928Z"),
    Type: "application",
    DateDue: new Date("2020-03-27T01:53:19.084Z"),
    DateRevoked: new Date("2021-01-26T09:46:02.889Z"),
    Noticee: "Ted",
    CreatedDate: "2020-07-17T12:23:54.900Z",
    FeeType: "Auto Loan Account",
    AmountIssued: "400.00",
    AmountOutstanding: 91616,
    CostCode: "98255-2262",
    InvoiceNumber: 89849,
    Name: "Cummerata, Bogisich and Daugherty",
    SystemTypeClassification: "zero defect",
    SortIndex: 83175,
    Title: "Central",
    Condition: "Incredible Wooden Gloves Dynamic Street",
  },
  {
    ID: "24",
    PermitNumber: 63115,
    Applicant: "Torey Kuvalis",
    Owner: "Mona Jakubowski DDS",
    Address: "Apt. 189",
    Suburb: "Oklahoma",
    AssessmentNo: 58836,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 50663,
    SystemName: "transmitter",
    SystemType: "feed",
    Received: new Date("2021-01-27T05:13:07.222Z"),
    PTI: new Date("2021-01-26T14:49:56.028Z"),
    CTU: "CTU 24",
    WaterAuthority: "WaterAuthority 24",
    Plumber: "Mylene Vandervort",
    Risk: "Risk Level 24",
    CertificateNo: 31547,
    SerialNo: "64034-2663",
    Details: "feed transform Intuitive",
    ApplicantAddress: "2173 Cassin Mill",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "537.00",
    GPS: "168.00",
    Manufacturer: "Will, Kuhn and Kiehn",
    TankSize: 791.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Nolan, Doyle and West",
    SecondarySystemType: "Toys",
    SecondaryManufacturer: "Bruen - Kuhlman",
    SecondaryTankSize: "467.00",
    UnitSecondary: "UnitSecondary 24",
    TertiarySystemType: "Beauty",
    TertiaryManufacturer: "Swift, Bogan and Welch",
    TertiaryTankSize: "918.00",
    UnitTertiary: "UnitTertiary 24",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 24",
    Contractor: "Theresia.Bosco0",
    RiskLevel: "RiskLevel 24",
    PermitType: "PermitType 24",
    ConstructionType: "ConstructionType 24",
    DebtorNo: 50696,
    FileNo: 4756,
    Issued: new Date("2021-01-02T05:28:19.492Z"),
    IssuingOfficer: "Favian",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-04-13T04:47:30.217Z"),
    ApprovalOfficer: "Cheyanne Waters",
    OutstandingFees: "415.00",
    OSFees: "92.00",
    ComplainceCertNo: 65192,
    ApprovalForBL: new Date("2020-09-05T12:44:04.453Z"),
    BLicenceNumber: 82803,
    SentToDoH: new Date("2020-05-06T15:37:01.252Z"),
    DoHApproval: "DoHApproval 24",
    DateDoHApproved: new Date("2020-10-13T21:59:09.679Z"),
    DoHApprovalNo: "DoHApprovalNo 24",
    SerialNumber: 76338,
    LastInspection: new Date("2020-02-16T06:47:26.951Z"),
    Frequency: 68277,
    NextInspection: new Date("2021-10-21T21:31:01.999Z"),
    LastService: new Date("2020-04-12T03:13:48.690Z"),
    LastDesludge: new Date("2020-03-18T05:53:23.037Z"),
    LastSample: new Date("2020-07-18T23:14:52.727Z"),
    NextService: new Date("2021-08-31T06:09:24.640Z"),
    NextDesludge: new Date("2021-04-16T11:20:23.728Z"),
    NextSample: new Date("2021-08-26T18:58:38.395Z"),
    Comments:
      "Try to quantify the JSON application, maybe it will connect the cross-platform port!",
    Size: "905.00",
    DateInspected: new Date("2020-09-23T23:02:07.604Z"),
    InspectionType: "Strategist",
    Officer: "Luz Wisoky",
    DateInspectedMonth: "January",
    DueStatus: "hack",
    Material: "Cotton",
    InspectionFrequency: 32645,
    ReferenceNumber: "92530",
    ContactName: "Micheal",
    Compliance: "bandwidth",
    Reason: "We need to override the redundant SSL sensor!",
    RefNo: "97409",
    Description:
      "You can't copy the microchip without navigating the bluetooth SCSI matrix!",
    DateTarget: new Date("2020-08-31T01:02:40.436Z"),
    Source: "Beauty",
    Category: "Handmade",
    Location: "Marshall Islands",
    RequestedBy: "Florence",
    ContactDetails: "Dicki",
    DateRecorded: new Date("2021-01-26T08:23:43.872Z"),
    RecordedBy: "Trudie Corkery",
    OrgStructure: "24/365",
    ActionOfficer: "Zena Conn",
    Coordinator: "Dr. Gillian Conn",
    DateWork: new Date("2020-09-14T15:36:30.481Z"),
    EnquiryRefNo: "16748",
    Event_ReferenceNoAdditional: "87363",
    OnBehalfOf: "Operations",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-09-03T21:47:06.119Z"),
    Type: "pixel",
    DateDue: new Date("2020-05-23T21:20:33.419Z"),
    DateRevoked: new Date("2021-01-26T11:27:52.091Z"),
    Noticee: "Irwin",
    CreatedDate: "2020-11-17T00:15:09.907Z",
    FeeType: "Credit Card Account",
    AmountIssued: "214.00",
    AmountOutstanding: 21207,
    CostCode: "83587",
    InvoiceNumber: 23684,
    Name: "Kuhn Inc",
    SystemTypeClassification: "ROI",
    SortIndex: 19689,
    Title: "Corporate",
    Condition: "Avon Radial parse",
  },
  {
    ID: "25",
    PermitNumber: 98693,
    Applicant: "Elenora Williamson",
    Owner: "Milo Jaskolski",
    Address: "Suite 958",
    Suburb: "Arizona",
    AssessmentNo: 27467,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 38068,
    SystemName: "protocol",
    SystemType: "driver",
    Received: new Date("2021-01-26T07:53:47.250Z"),
    PTI: new Date("2021-01-26T23:26:38.620Z"),
    CTU: "CTU 25",
    WaterAuthority: "WaterAuthority 25",
    Plumber: "Laury Ondricka",
    Risk: "Risk Level 25",
    CertificateNo: 44526,
    SerialNo: "11025-7246",
    Details: "Metrics",
    ApplicantAddress: "7103 Bennett Causeway",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "906.00",
    GPS: "922.00",
    Manufacturer: "Shields Inc",
    TankSize: 298.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Parisian, Howe and Okuneva",
    SecondarySystemType: "Games",
    SecondaryManufacturer: "Murazik LLC",
    SecondaryTankSize: "130.00",
    UnitSecondary: "UnitSecondary 25",
    TertiarySystemType: "Games",
    TertiaryManufacturer: "Bergnaum LLC",
    TertiaryTankSize: "357.00",
    UnitTertiary: "UnitTertiary 25",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 25",
    Contractor: "Makenna.Mayer18",
    RiskLevel: "RiskLevel 25",
    PermitType: "PermitType 25",
    ConstructionType: "ConstructionType 25",
    DebtorNo: 28667,
    FileNo: 98302,
    Issued: new Date("2020-08-01T04:28:33.073Z"),
    IssuingOfficer: "Ezequiel",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-11-08T20:23:32.892Z"),
    ApprovalOfficer: "Damian Jerde",
    OutstandingFees: "366.00",
    OSFees: "603.00",
    ComplainceCertNo: 69279,
    ApprovalForBL: new Date("2020-08-12T06:14:49.761Z"),
    BLicenceNumber: 31034,
    SentToDoH: new Date("2020-12-13T16:39:46.031Z"),
    DoHApproval: "DoHApproval 25",
    DateDoHApproved: new Date("2020-10-07T18:42:49.322Z"),
    DoHApprovalNo: "DoHApprovalNo 25",
    SerialNumber: 71889,
    LastInspection: new Date("2020-02-21T08:50:26.467Z"),
    Frequency: 75450,
    NextInspection: new Date("2021-04-16T15:32:22.665Z"),
    LastService: new Date("2020-06-30T11:57:57.519Z"),
    LastDesludge: new Date("2020-04-21T09:43:32.261Z"),
    LastSample: new Date("2020-04-29T12:18:22.055Z"),
    NextService: new Date("2021-12-13T07:25:38.987Z"),
    NextDesludge: new Date("2021-08-17T02:39:57.086Z"),
    NextSample: new Date("2021-10-30T07:42:25.443Z"),
    Comments:
      "Try to copy the SDD interface, maybe it will program the online protocol!",
    Size: "151.00",
    DateInspected: new Date("2020-10-13T12:34:12.945Z"),
    InspectionType: "Orchestrator",
    Officer: "Luis Kris",
    DateInspectedMonth: "March",
    DueStatus: "Buckinghamshire",
    Material: "Fresh",
    InspectionFrequency: 79121,
    ReferenceNumber: "16483",
    ContactName: "Malachi",
    Compliance: "microchip",
    Reason:
      "If we program the matrix, we can get to the EXE protocol through the auxiliary ADP panel!",
    RefNo: "25068",
    Description:
      "You can't index the application without connecting the back-end HDD application!",
    DateTarget: new Date("2020-06-03T10:16:00.764Z"),
    Source: "Shoes",
    Category: "Licensed",
    Location: "Austria",
    RequestedBy: "Karen",
    ContactDetails: "Luettgen",
    DateRecorded: new Date("2021-01-27T00:52:39.512Z"),
    RecordedBy: "Bernadine Okuneva",
    OrgStructure: "Zambia",
    ActionOfficer: "Sonny Cronin I",
    Coordinator: "Ulices Christiansen",
    DateWork: new Date("2020-12-14T15:37:43.125Z"),
    EnquiryRefNo: "49776",
    Event_ReferenceNoAdditional: "49206",
    OnBehalfOf: "Accountability",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-07-21T05:48:32.784Z"),
    Type: "protocol",
    DateDue: new Date("2020-03-18T00:54:06.326Z"),
    DateRevoked: new Date("2021-01-26T21:45:00.978Z"),
    Noticee: "Easton",
    CreatedDate: "2020-06-09T21:03:19.464Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "114.00",
    AmountOutstanding: 46057,
    CostCode: "63343",
    InvoiceNumber: 44478,
    Name: "Cartwright, Rowe and Blanda",
    SystemTypeClassification: "Unbranded Soft Sausages",
    SortIndex: 60278,
    Title: "Lead",
    Condition: "Soft",
  },
  {
    ID: "26",
    PermitNumber: 94484,
    Applicant: "Moses Watsica",
    Owner: "Godfrey Mueller",
    Address: "Apt. 251",
    Suburb: "Arizona",
    AssessmentNo: 98157,
    Status: "Complied",
    TypeOfApplication: "Computers",
    FileNumber: 57388,
    SystemName: "hard drive",
    SystemType: "application",
    Received: new Date("2021-01-26T07:03:20.151Z"),
    PTI: new Date("2021-01-26T15:31:27.104Z"),
    CTU: "CTU 26",
    WaterAuthority: "WaterAuthority 26",
    Plumber: "Patrick Stehr",
    Risk: "Risk Level 26",
    CertificateNo: 3237,
    SerialNo: "29063",
    Details: "parse Home Loan Account",
    ApplicantAddress: "065 Deondre Plains",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "984.00",
    GPS: "606.00",
    Manufacturer: "Rosenbaum and Sons",
    TankSize: 784.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Stoltenberg, Nolan and Gaylord",
    SecondarySystemType: "Toys",
    SecondaryManufacturer: "Parisian Group",
    SecondaryTankSize: "796.00",
    UnitSecondary: "UnitSecondary 26",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Torp - Willms",
    TertiaryTankSize: "865.00",
    UnitTertiary: "UnitTertiary 26",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 26",
    Contractor: "Ashleigh.Gleichner",
    RiskLevel: "RiskLevel 26",
    PermitType: "PermitType 26",
    ConstructionType: "ConstructionType 26",
    DebtorNo: 34546,
    FileNo: 79897,
    Issued: new Date("2020-03-15T15:00:07.984Z"),
    IssuingOfficer: "Trenton",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-10-14T01:51:11.826Z"),
    ApprovalOfficer: "Stefanie Mohr",
    OutstandingFees: "851.00",
    OSFees: "745.00",
    ComplainceCertNo: 84852,
    ApprovalForBL: new Date("2020-06-15T07:09:26.445Z"),
    BLicenceNumber: 45299,
    SentToDoH: new Date("2020-02-19T22:03:16.533Z"),
    DoHApproval: "DoHApproval 26",
    DateDoHApproved: new Date("2021-01-10T09:32:55.415Z"),
    DoHApprovalNo: "DoHApprovalNo 26",
    SerialNumber: 27287,
    LastInspection: new Date("2020-06-08T14:20:58.236Z"),
    Frequency: 75332,
    NextInspection: new Date("2021-02-23T20:52:55.258Z"),
    LastService: new Date("2020-11-19T01:23:19.178Z"),
    LastDesludge: new Date("2020-07-02T09:30:06.254Z"),
    LastSample: new Date("2020-07-03T01:00:31.822Z"),
    NextService: new Date("2021-08-21T06:01:00.398Z"),
    NextDesludge: new Date("2021-09-27T06:31:06.105Z"),
    NextSample: new Date("2021-06-08T10:43:33.058Z"),
    Comments:
      "Use the solid state EXE transmitter, then you can input the digital program!",
    Size: "889.00",
    DateInspected: new Date("2020-10-28T04:23:20.030Z"),
    InspectionType: "Planner",
    Officer: "Vivien Walter",
    DateInspectedMonth: "October",
    DueStatus: "Producer",
    Material: "Rubber",
    InspectionFrequency: 8713,
    ReferenceNumber: "97372",
    ContactName: "Kieran",
    Compliance: "card",
    Reason:
      "parsing the array won't do anything, we need to generate the multi-byte RAM bandwidth!",
    RefNo: "83812",
    Description: "We need to program the cross-platform JBOD system!",
    DateTarget: new Date("2020-05-28T20:57:09.575Z"),
    Source: "Grocery",
    Category: "Tasty",
    Location: "Guyana",
    RequestedBy: "Ivah",
    ContactDetails: "McLaughlin",
    DateRecorded: new Date("2021-01-26T20:58:03.022Z"),
    RecordedBy: "Gabrielle Kautzer",
    OrgStructure: "Sharable",
    ActionOfficer: "Mariano Pfeffer",
    Coordinator: "Chad Feil",
    DateWork: new Date("2020-10-28T22:51:42.498Z"),
    EnquiryRefNo: "35544",
    Event_ReferenceNoAdditional: "94333",
    OnBehalfOf: "Implementation",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-09-24T18:41:02.070Z"),
    Type: "bandwidth",
    DateDue: new Date("2020-04-01T15:40:59.757Z"),
    DateRevoked: new Date("2021-01-26T21:07:41.726Z"),
    Noticee: "Jessie",
    CreatedDate: "2020-06-13T01:29:25.180Z",
    FeeType: "Credit Card Account",
    AmountIssued: "880.00",
    AmountOutstanding: 95719,
    CostCode: "62135",
    InvoiceNumber: 74034,
    Name: "Strosin Inc",
    SystemTypeClassification: "SSL",
    SortIndex: 99506,
    Title: "Senior",
    Condition: "HTTP e-markets",
  },
  {
    ID: "27",
    PermitNumber: 51716,
    Applicant: "Miss Martina Huel",
    Owner: "Audra Greenholt",
    Address: "Apt. 015",
    Suburb: "New Jersey",
    AssessmentNo: 44103,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 66283,
    SystemName: "microchip",
    SystemType: "hard drive",
    Received: new Date("2021-01-26T23:47:09.867Z"),
    PTI: new Date("2021-01-26T21:02:00.929Z"),
    CTU: "CTU 27",
    WaterAuthority: "WaterAuthority 27",
    Plumber: "Billy Lemke",
    Risk: "Risk Level 27",
    CertificateNo: 53331,
    SerialNo: "26903-8970",
    Details: "Beauty application cultivate",
    ApplicantAddress: "542 Lynn Motorway",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "13.00",
    GPS: "242.00",
    Manufacturer: "Pfeffer - Cartwright",
    TankSize: 927.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Jakubowski, Gleichner and Strosin",
    SecondarySystemType: "Shoes",
    SecondaryManufacturer: "Beer, Jaskolski and Mills",
    SecondaryTankSize: "664.00",
    UnitSecondary: "UnitSecondary 27",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Frami, O'Reilly and Wilderman",
    TertiaryTankSize: "788.00",
    UnitTertiary: "UnitTertiary 27",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 27",
    Contractor: "Jerrold.Kuvalis44",
    RiskLevel: "RiskLevel 27",
    PermitType: "PermitType 27",
    ConstructionType: "ConstructionType 27",
    DebtorNo: 22439,
    FileNo: 58295,
    Issued: new Date("2020-04-30T01:39:06.707Z"),
    IssuingOfficer: "Connie",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-04-30T21:35:46.089Z"),
    ApprovalOfficer: "Edwin Gorczany",
    OutstandingFees: "956.00",
    OSFees: "584.00",
    ComplainceCertNo: 96978,
    ApprovalForBL: new Date("2020-09-16T00:33:10.099Z"),
    BLicenceNumber: 85148,
    SentToDoH: new Date("2020-03-31T18:28:04.405Z"),
    DoHApproval: "DoHApproval 27",
    DateDoHApproved: new Date("2020-07-24T13:38:37.571Z"),
    DoHApprovalNo: "DoHApprovalNo 27",
    SerialNumber: 56804,
    LastInspection: new Date("2020-03-08T11:03:57.886Z"),
    Frequency: 47212,
    NextInspection: new Date("2021-06-17T22:43:36.557Z"),
    LastService: new Date("2021-01-11T06:15:00.212Z"),
    LastDesludge: new Date("2020-08-30T14:08:51.435Z"),
    LastSample: new Date("2020-06-11T22:32:04.163Z"),
    NextService: new Date("2021-11-04T21:09:08.555Z"),
    NextDesludge: new Date("2021-12-03T17:44:21.796Z"),
    NextSample: new Date("2021-10-06T19:37:14.778Z"),
    Comments:
      "I'll index the online SAS panel, that should protocol the PCI bus!",
    Size: "254.00",
    DateInspected: new Date("2020-03-22T07:05:13.768Z"),
    InspectionType: "Producer",
    Officer: "Jocelyn Block",
    DateInspectedMonth: "November",
    DueStatus: "Meadow",
    Material: "Granite",
    InspectionFrequency: 69629,
    ReferenceNumber: "88956",
    ContactName: "Lupe",
    Compliance: "sensor",
    Reason:
      "I'll navigate the open-source TCP bus, that should pixel the PCI hard drive!",
    RefNo: "80873",
    Description:
      "You can't hack the system without compressing the redundant PNG microchip!",
    DateTarget: new Date("2020-05-08T01:35:55.431Z"),
    Source: "Movies",
    Category: "Fantastic",
    Location: "Congo",
    RequestedBy: "Amani",
    ContactDetails: "Bradtke",
    DateRecorded: new Date("2021-01-27T02:21:01.381Z"),
    RecordedBy: "Dena Harris",
    OrgStructure: "3rd generation",
    ActionOfficer: "Tracey Hackett",
    Coordinator: "Gardner Hoeger",
    DateWork: new Date("2020-09-28T05:55:43.265Z"),
    EnquiryRefNo: "65836",
    Event_ReferenceNoAdditional: "42663",
    OnBehalfOf: "Accountability",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-02-19T13:38:03.601Z"),
    Type: "hard drive",
    DateDue: new Date("2020-02-17T13:42:21.024Z"),
    DateRevoked: new Date("2021-01-26T13:17:48.802Z"),
    Noticee: "Reggie",
    CreatedDate: "2020-08-31T04:28:09.347Z",
    FeeType: "Money Market Account",
    AmountIssued: "40.00",
    AmountOutstanding: 33737,
    CostCode: "27841",
    InvoiceNumber: 86095,
    Name: "Bechtelar - Yundt",
    SystemTypeClassification: "hack",
    SortIndex: 59267,
    Title: "Future",
    Condition: "Bedfordshire connect Naira",
  },
  {
    ID: "28",
    PermitNumber: 30932,
    Applicant: "Rebeca Mayert",
    Owner: "Amber Kunze",
    Address: "Apt. 404",
    Suburb: "Texas",
    AssessmentNo: 98135,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 64728,
    SystemName: "panel",
    SystemType: "protocol",
    Received: new Date("2021-01-26T07:43:17.849Z"),
    PTI: new Date("2021-01-26T20:30:02.441Z"),
    CTU: "CTU 28",
    WaterAuthority: "WaterAuthority 28",
    Plumber: "Dusty Botsford Sr.",
    Risk: "Risk Level 28",
    CertificateNo: 48193,
    SerialNo: "95010-5606",
    Details: "SSL Optimized",
    ApplicantAddress: "441 Taurean Square",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "183.00",
    GPS: "749.00",
    Manufacturer: "Johnston, Glover and Frami",
    TankSize: 309.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Deckow Inc",
    SecondarySystemType: "Jewelery",
    SecondaryManufacturer: "Kutch Inc",
    SecondaryTankSize: "434.00",
    UnitSecondary: "UnitSecondary 28",
    TertiarySystemType: "Outdoors",
    TertiaryManufacturer: "Gerlach, Zulauf and MacGyver",
    TertiaryTankSize: "875.00",
    UnitTertiary: "UnitTertiary 28",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 28",
    Contractor: "Peggie_Stehr32",
    RiskLevel: "RiskLevel 28",
    PermitType: "PermitType 28",
    ConstructionType: "ConstructionType 28",
    DebtorNo: 98186,
    FileNo: 14606,
    Issued: new Date("2020-03-29T06:34:50.053Z"),
    IssuingOfficer: "Tess",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-08-11T14:23:40.245Z"),
    ApprovalOfficer: "Terry Champlin",
    OutstandingFees: "449.00",
    OSFees: "662.00",
    ComplainceCertNo: 24512,
    ApprovalForBL: new Date("2020-04-30T17:46:48.273Z"),
    BLicenceNumber: 35340,
    SentToDoH: new Date("2020-03-02T09:15:09.044Z"),
    DoHApproval: "DoHApproval 28",
    DateDoHApproved: new Date("2020-11-26T18:47:56.332Z"),
    DoHApprovalNo: "DoHApprovalNo 28",
    SerialNumber: 93301,
    LastInspection: new Date("2020-07-01T23:07:23.721Z"),
    Frequency: 13457,
    NextInspection: new Date("2021-02-25T16:49:56.038Z"),
    LastService: new Date("2020-04-16T02:33:58.945Z"),
    LastDesludge: new Date("2020-06-09T16:39:03.341Z"),
    LastSample: new Date("2020-11-19T03:40:08.618Z"),
    NextService: new Date("2021-07-04T00:38:07.362Z"),
    NextDesludge: new Date("2021-09-27T21:26:35.048Z"),
    NextSample: new Date("2021-01-31T18:11:22.500Z"),
    Comments:
      "I'll navigate the wireless SMTP port, that should microchip the SAS feed!",
    Size: "220.00",
    DateInspected: new Date("2020-06-25T02:44:54.192Z"),
    InspectionType: "Supervisor",
    Officer: "Markus Kuhic",
    DateInspectedMonth: "November",
    DueStatus: "redundant",
    Material: "Fresh",
    InspectionFrequency: 52750,
    ReferenceNumber: "61253",
    ContactName: "Isabelle",
    Compliance: "port",
    Reason:
      "Use the cross-platform SMS array, then you can program the redundant array!",
    RefNo: "42472",
    Description:
      "Try to index the EXE microchip, maybe it will calculate the neural firewall!",
    DateTarget: new Date("2020-10-01T05:01:49.335Z"),
    Source: "Books",
    Category: "Rustic",
    Location: "Bahamas",
    RequestedBy: "Karlie",
    ContactDetails: "Grady",
    DateRecorded: new Date("2021-01-26T19:10:57.645Z"),
    RecordedBy: "Kevon Rath",
    OrgStructure: "overriding",
    ActionOfficer: "Lucy Welch",
    Coordinator: "Mateo Waters",
    DateWork: new Date("2020-11-01T16:18:16.879Z"),
    EnquiryRefNo: "10587",
    Event_ReferenceNoAdditional: "81661",
    OnBehalfOf: "Optimization",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-11-26T02:45:31.556Z"),
    Type: "matrix",
    DateDue: new Date("2020-11-09T18:31:58.183Z"),
    DateRevoked: new Date("2021-01-26T22:02:44.279Z"),
    Noticee: "Therese",
    CreatedDate: "2020-02-06T22:43:14.368Z",
    FeeType: "Investment Account",
    AmountIssued: "547.00",
    AmountOutstanding: 76291,
    CostCode: "28964-0944",
    InvoiceNumber: 47039,
    Name: "Mills Group",
    SystemTypeClassification: "Rustic",
    SortIndex: 96737,
    Title: "Forward",
    Condition: "Plastic e-tailers",
  },
  {
    ID: "29",
    PermitNumber: 99583,
    Applicant: "Dr. Brian Kreiger",
    Owner: "Reilly Schoen",
    Address: "Suite 070",
    Suburb: "Maryland",
    AssessmentNo: 81137,
    Status: "Complied",
    TypeOfApplication: "Jewelery",
    FileNumber: 26503,
    SystemName: "monitor",
    SystemType: "system",
    Received: new Date("2021-01-26T07:52:24.588Z"),
    PTI: new Date("2021-01-26T06:42:23.252Z"),
    CTU: "CTU 29",
    WaterAuthority: "WaterAuthority 29",
    Plumber: "Vivienne McLaughlin",
    Risk: "Risk Level 29",
    CertificateNo: 35396,
    SerialNo: "14798",
    Details: "Generic Kids synthesize",
    ApplicantAddress: "82093 Jasper Lodge",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "375.00",
    GPS: "338.00",
    Manufacturer: "Hegmann - Renner",
    TankSize: 902.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Johnson, Larson and Mohr",
    SecondarySystemType: "Electronics",
    SecondaryManufacturer: "Bins, Dare and Nicolas",
    SecondaryTankSize: "169.00",
    UnitSecondary: "UnitSecondary 29",
    TertiarySystemType: "Beauty",
    TertiaryManufacturer: "Stark, Douglas and McKenzie",
    TertiaryTankSize: "662.00",
    UnitTertiary: "UnitTertiary 29",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 29",
    Contractor: "Magnus_Pollich",
    RiskLevel: "RiskLevel 29",
    PermitType: "PermitType 29",
    ConstructionType: "ConstructionType 29",
    DebtorNo: 22841,
    FileNo: 44344,
    Issued: new Date("2020-04-21T04:15:04.225Z"),
    IssuingOfficer: "Lyric",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-10-10T17:25:01.978Z"),
    ApprovalOfficer: "Brennon Klein",
    OutstandingFees: "724.00",
    OSFees: "31.00",
    ComplainceCertNo: 95871,
    ApprovalForBL: new Date("2020-08-03T12:50:49.548Z"),
    BLicenceNumber: 98783,
    SentToDoH: new Date("2020-10-25T15:23:24.060Z"),
    DoHApproval: "DoHApproval 29",
    DateDoHApproved: new Date("2020-08-20T09:54:54.945Z"),
    DoHApprovalNo: "DoHApprovalNo 29",
    SerialNumber: 84413,
    LastInspection: new Date("2020-03-15T12:25:58.487Z"),
    Frequency: 12860,
    NextInspection: new Date("2021-08-04T17:19:46.500Z"),
    LastService: new Date("2020-05-05T22:38:14.790Z"),
    LastDesludge: new Date("2020-07-24T08:48:31.758Z"),
    LastSample: new Date("2020-09-18T12:31:36.322Z"),
    NextService: new Date("2021-02-14T11:24:38.064Z"),
    NextDesludge: new Date("2021-07-25T14:51:08.056Z"),
    NextSample: new Date("2021-06-19T03:39:01.629Z"),
    Comments:
      "backing up the card won't do anything, we need to hack the mobile EXE interface!",
    Size: "482.00",
    DateInspected: new Date("2020-07-05T15:14:15.584Z"),
    InspectionType: "Engineer",
    Officer: "Nicholaus Daniel",
    DateInspectedMonth: "March",
    DueStatus: "reboot",
    Material: "Frozen",
    InspectionFrequency: 14483,
    ReferenceNumber: "32230",
    ContactName: "Ines",
    Compliance: "protocol",
    Reason:
      "Use the mobile RSS application, then you can connect the 1080p program!",
    RefNo: "85287",
    Description:
      "I'll input the neural SSL system, that should panel the ADP hard drive!",
    DateTarget: new Date("2020-11-12T20:05:28.637Z"),
    Source: "Baby",
    Category: "Generic",
    Location: "Latvia",
    RequestedBy: "Gust",
    ContactDetails: "Kirlin",
    DateRecorded: new Date("2021-01-26T09:11:38.004Z"),
    RecordedBy: "Pearl Bernhard Sr.",
    OrgStructure: "Cambridgeshire",
    ActionOfficer: "Axel Mayert",
    Coordinator: "Shakira Kihn",
    DateWork: new Date("2020-11-13T12:36:52.211Z"),
    EnquiryRefNo: "4883",
    Event_ReferenceNoAdditional: "34063",
    OnBehalfOf: "Data",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-02-02T19:20:21.485Z"),
    Type: "bandwidth",
    DateDue: new Date("2021-01-22T09:52:50.883Z"),
    DateRevoked: new Date("2021-01-26T20:08:17.877Z"),
    Noticee: "Silas",
    CreatedDate: "2020-09-28T15:54:54.866Z",
    FeeType: "Home Loan Account",
    AmountIssued: "394.00",
    AmountOutstanding: 48796,
    CostCode: "44787",
    InvoiceNumber: 51391,
    Name: "Reinger and Sons",
    SystemTypeClassification: "Uzbekistan Sum",
    SortIndex: 12061,
    Title: "Future",
    Condition: "Bacon Flats Cordoba Oro",
  },
  {
    ID: "30",
    PermitNumber: 61266,
    Applicant: "Clyde Satterfield",
    Owner: "Dedrick Rutherford",
    Address: "Suite 292",
    Suburb: "West Virginia",
    AssessmentNo: 93297,
    Status: "Complied",
    TypeOfApplication: "Tools",
    FileNumber: 39013,
    SystemName: "pixel",
    SystemType: "feed",
    Received: new Date("2021-01-26T11:12:42.845Z"),
    PTI: new Date("2021-01-26T09:18:41.917Z"),
    CTU: "CTU 30",
    WaterAuthority: "WaterAuthority 30",
    Plumber: "Audra Rowe",
    Risk: "Risk Level 30",
    CertificateNo: 10105,
    SerialNo: "98250-1866",
    Details: "auxiliary Checking Account synthesize",
    ApplicantAddress: "146 Monte Pass",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "842.00",
    GPS: "480.00",
    Manufacturer: "Stoltenberg LLC",
    TankSize: 890.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Greenholt and Sons",
    SecondarySystemType: "Home",
    SecondaryManufacturer: "Spinka - Rosenbaum",
    SecondaryTankSize: "983.00",
    UnitSecondary: "UnitSecondary 30",
    TertiarySystemType: "Garden",
    TertiaryManufacturer: "Collins Group",
    TertiaryTankSize: "700.00",
    UnitTertiary: "UnitTertiary 30",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 30",
    Contractor: "Carlotta.Kiehn",
    RiskLevel: "RiskLevel 30",
    PermitType: "PermitType 30",
    ConstructionType: "ConstructionType 30",
    DebtorNo: 29380,
    FileNo: 62767,
    Issued: new Date("2020-07-27T12:22:23.710Z"),
    IssuingOfficer: "Eda",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-05-25T19:30:33.702Z"),
    ApprovalOfficer: "Francis Klein",
    OutstandingFees: "71.00",
    OSFees: "3.00",
    ComplainceCertNo: 13173,
    ApprovalForBL: new Date("2020-10-16T03:21:26.026Z"),
    BLicenceNumber: 71397,
    SentToDoH: new Date("2020-10-24T01:46:10.239Z"),
    DoHApproval: "DoHApproval 30",
    DateDoHApproved: new Date("2020-02-09T09:28:38.602Z"),
    DoHApprovalNo: "DoHApprovalNo 30",
    SerialNumber: 19000,
    LastInspection: new Date("2020-06-17T04:38:50.865Z"),
    Frequency: 20432,
    NextInspection: new Date("2021-09-23T23:46:19.022Z"),
    LastService: new Date("2020-04-20T08:51:19.747Z"),
    LastDesludge: new Date("2020-07-30T08:24:32.323Z"),
    LastSample: new Date("2020-09-08T13:00:54.207Z"),
    NextService: new Date("2021-06-29T20:16:01.201Z"),
    NextDesludge: new Date("2021-06-23T22:56:08.237Z"),
    NextSample: new Date("2021-07-17T07:13:43.463Z"),
    Comments:
      "I'll program the neural PNG hard drive, that should system the SAS circuit!",
    Size: "745.00",
    DateInspected: new Date("2020-07-06T22:01:49.629Z"),
    InspectionType: "Designer",
    Officer: "Dana Upton",
    DateInspectedMonth: "March",
    DueStatus: "purple",
    Material: "Granite",
    InspectionFrequency: 27005,
    ReferenceNumber: "42083",
    ContactName: "Jakayla",
    Compliance: "circuit",
    Reason:
      "Try to copy the HTTP program, maybe it will index the auxiliary circuit!",
    RefNo: "26398",
    Description:
      "Try to synthesize the PNG firewall, maybe it will navigate the open-source circuit!",
    DateTarget: new Date("2020-06-17T02:01:00.037Z"),
    Source: "Grocery",
    Category: "Licensed",
    Location: "Honduras",
    RequestedBy: "Melyna",
    ContactDetails: "Simonis",
    DateRecorded: new Date("2021-01-26T18:04:46.372Z"),
    RecordedBy: "Franz Lindgren",
    OrgStructure: "Ergonomic Fresh Shirt",
    ActionOfficer: "Estefania Harvey",
    Coordinator: "Loy Hirthe",
    DateWork: new Date("2020-05-11T04:05:03.200Z"),
    EnquiryRefNo: "169",
    Event_ReferenceNoAdditional: "29121",
    OnBehalfOf: "Data",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-02-19T10:56:28.476Z"),
    Type: "monitor",
    DateDue: new Date("2020-11-09T21:45:43.558Z"),
    DateRevoked: new Date("2021-01-27T01:43:48.209Z"),
    Noticee: "Nick",
    CreatedDate: "2020-06-04T11:42:19.519Z",
    FeeType: "Money Market Account",
    AmountIssued: "251.00",
    AmountOutstanding: 86336,
    CostCode: "57604-6253",
    InvoiceNumber: 93464,
    Name: "Altenwerth Inc",
    SystemTypeClassification: "Multi-tiered",
    SortIndex: 95844,
    Title: "International",
    Condition: "payment",
  },
  {
    ID: "31",
    PermitNumber: 5050,
    Applicant: "Arely Swift",
    Owner: "Marguerite Beer",
    Address: "Suite 962",
    Suburb: "Hawaii",
    AssessmentNo: 68727,
    Status: "Complied",
    TypeOfApplication: "Games",
    FileNumber: 93213,
    SystemName: "alarm",
    SystemType: "interface",
    Received: new Date("2021-01-26T20:37:55.807Z"),
    PTI: new Date("2021-01-27T01:58:11.930Z"),
    CTU: "CTU 31",
    WaterAuthority: "WaterAuthority 31",
    Plumber: "Josh Hartmann",
    Risk: "Risk Level 31",
    CertificateNo: 69035,
    SerialNo: "69418-0118",
    Details: "Regional",
    ApplicantAddress: "41389 Leannon Island",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "770.00",
    GPS: "953.00",
    Manufacturer: "Rosenbaum - Hackett",
    TankSize: 469.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Pouros, Wisoky and Kilback",
    SecondarySystemType: "Industrial",
    SecondaryManufacturer: "Howell and Sons",
    SecondaryTankSize: "406.00",
    UnitSecondary: "UnitSecondary 31",
    TertiarySystemType: "Garden",
    TertiaryManufacturer: "Doyle, Champlin and Dietrich",
    TertiaryTankSize: "105.00",
    UnitTertiary: "UnitTertiary 31",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 31",
    Contractor: "Derek_Franecki",
    RiskLevel: "RiskLevel 31",
    PermitType: "PermitType 31",
    ConstructionType: "ConstructionType 31",
    DebtorNo: 73797,
    FileNo: 5167,
    Issued: new Date("2020-11-26T03:19:30.562Z"),
    IssuingOfficer: "Mason",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2021-01-08T11:23:19.148Z"),
    ApprovalOfficer: "Juana Conroy",
    OutstandingFees: "85.00",
    OSFees: "18.00",
    ComplainceCertNo: 41014,
    ApprovalForBL: new Date("2020-08-25T18:26:50.530Z"),
    BLicenceNumber: 54083,
    SentToDoH: new Date("2020-08-15T09:53:30.518Z"),
    DoHApproval: "DoHApproval 31",
    DateDoHApproved: new Date("2020-06-29T05:01:04.061Z"),
    DoHApprovalNo: "DoHApprovalNo 31",
    SerialNumber: 32957,
    LastInspection: new Date("2020-06-09T03:06:53.628Z"),
    Frequency: 9490,
    NextInspection: new Date("2021-08-03T15:17:13.808Z"),
    LastService: new Date("2020-06-30T10:46:56.911Z"),
    LastDesludge: new Date("2020-09-28T10:37:20.246Z"),
    LastSample: new Date("2020-02-06T03:06:25.774Z"),
    NextService: new Date("2021-11-24T02:44:14.971Z"),
    NextDesludge: new Date("2021-02-04T19:36:16.885Z"),
    NextSample: new Date("2021-02-19T16:58:24.508Z"),
    Comments:
      "Use the multi-byte AI panel, then you can back up the online pixel!",
    Size: "778.00",
    DateInspected: new Date("2020-11-26T08:22:43.213Z"),
    InspectionType: "Producer",
    Officer: "Britney Legros",
    DateInspectedMonth: "April",
    DueStatus: "European Unit of Account 9(E.U.A.-9)",
    Material: "Wooden",
    InspectionFrequency: 65252,
    ReferenceNumber: "78015",
    ContactName: "Juston",
    Compliance: "driver",
    Reason:
      "synthesizing the capacitor won't do anything, we need to program the solid state SAS matrix!",
    RefNo: "66569",
    Description:
      "You can't synthesize the protocol without compressing the cross-platform PNG circuit!",
    DateTarget: new Date("2020-06-30T23:09:57.699Z"),
    Source: "Tools",
    Category: "Unbranded",
    Location: "Afghanistan",
    RequestedBy: "Ambrose",
    ContactDetails: "Kulas",
    DateRecorded: new Date("2021-01-26T20:45:03.175Z"),
    RecordedBy: "Lillie Steuber Sr.",
    OrgStructure: "Steel",
    ActionOfficer: "Sean Hilpert",
    Coordinator: "Beverly Koch",
    DateWork: new Date("2020-06-15T15:03:07.827Z"),
    EnquiryRefNo: "3538",
    Event_ReferenceNoAdditional: "90757",
    OnBehalfOf: "Creative",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-07-21T13:50:57.082Z"),
    Type: "pixel",
    DateDue: new Date("2020-08-13T08:29:32.298Z"),
    DateRevoked: new Date("2021-01-26T14:32:33.642Z"),
    Noticee: "Bettye",
    CreatedDate: "2020-03-14T12:02:51.856Z",
    FeeType: "Checking Account",
    AmountIssued: "56.00",
    AmountOutstanding: 56117,
    CostCode: "08580",
    InvoiceNumber: 58538,
    Name: "Bechtelar Inc",
    SystemTypeClassification: "Georgia",
    SortIndex: 17077,
    Title: "National",
    Condition: "Qatar",
  },
  {
    ID: "32",
    PermitNumber: 8186,
    Applicant: "Martina Spinka",
    Owner: "Gabe Kris",
    Address: "Apt. 652",
    Suburb: "North Carolina",
    AssessmentNo: 50322,
    Status: "Complied",
    TypeOfApplication: "Music",
    FileNumber: 53988,
    SystemName: "sensor",
    SystemType: "panel",
    Received: new Date("2021-01-26T23:31:07.828Z"),
    PTI: new Date("2021-01-27T03:20:34.932Z"),
    CTU: "CTU 32",
    WaterAuthority: "WaterAuthority 32",
    Plumber: "Jordy Wolff",
    Risk: "Risk Level 32",
    CertificateNo: 11593,
    SerialNo: "45641",
    Details: "Programmable Bacon Innovative",
    ApplicantAddress: "91148 Gretchen Hill",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "23.00",
    GPS: "98.00",
    Manufacturer: "O'Kon, Armstrong and Murphy",
    TankSize: 36.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Sawayn and Sons",
    SecondarySystemType: "Jewelery",
    SecondaryManufacturer: "Rogahn, Rowe and Russel",
    SecondaryTankSize: "167.00",
    UnitSecondary: "UnitSecondary 32",
    TertiarySystemType: "Health",
    TertiaryManufacturer: "Morar - Fisher",
    TertiaryTankSize: "417.00",
    UnitTertiary: "UnitTertiary 32",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 32",
    Contractor: "Hugh.Hickle45",
    RiskLevel: "RiskLevel 32",
    PermitType: "PermitType 32",
    ConstructionType: "ConstructionType 32",
    DebtorNo: 23870,
    FileNo: 50965,
    Issued: new Date("2020-12-23T09:09:19.603Z"),
    IssuingOfficer: "Adrienne",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-11-21T00:52:47.769Z"),
    ApprovalOfficer: "Mrs. Darrin Lockman",
    OutstandingFees: "570.00",
    OSFees: "415.00",
    ComplainceCertNo: 94128,
    ApprovalForBL: new Date("2021-01-04T13:29:26.035Z"),
    BLicenceNumber: 73984,
    SentToDoH: new Date("2020-07-21T11:21:25.687Z"),
    DoHApproval: "DoHApproval 32",
    DateDoHApproved: new Date("2020-07-09T18:53:23.334Z"),
    DoHApprovalNo: "DoHApprovalNo 32",
    SerialNumber: 68396,
    LastInspection: new Date("2020-07-31T04:08:32.311Z"),
    Frequency: 48416,
    NextInspection: new Date("2021-05-16T15:22:24.514Z"),
    LastService: new Date("2021-01-05T07:05:07.390Z"),
    LastDesludge: new Date("2020-03-14T06:19:01.731Z"),
    LastSample: new Date("2020-09-01T12:43:05.145Z"),
    NextService: new Date("2021-05-13T21:34:56.506Z"),
    NextDesludge: new Date("2022-01-18T19:57:31.779Z"),
    NextSample: new Date("2021-04-23T20:59:53.859Z"),
    Comments:
      "I'll quantify the optical SCSI monitor, that should port the SSL transmitter!",
    Size: "404.00",
    DateInspected: new Date("2020-07-20T05:32:52.300Z"),
    InspectionType: "Architect",
    Officer: "Mrs. Rahsaan Bergstrom",
    DateInspectedMonth: "August",
    DueStatus: "HTTP",
    Material: "Soft",
    InspectionFrequency: 38019,
    ReferenceNumber: "42127",
    ContactName: "Dulce",
    Compliance: "system",
    Reason: "We need to navigate the virtual SDD circuit!",
    RefNo: "25175",
    Description:
      "The RAM transmitter is down, quantify the open-source application so we can navigate the XML transmitter!",
    DateTarget: new Date("2021-01-19T22:52:12.228Z"),
    Source: "Health",
    Category: "Refined",
    Location: "Marshall Islands",
    RequestedBy: "Citlalli",
    ContactDetails: "Ebert",
    DateRecorded: new Date("2021-01-26T15:28:44.884Z"),
    RecordedBy: "Layla Johns",
    OrgStructure: "Technician",
    ActionOfficer: "Angeline Johnson",
    Coordinator: "Renee Rau",
    DateWork: new Date("2020-03-31T08:15:57.050Z"),
    EnquiryRefNo: "28032",
    Event_ReferenceNoAdditional: "22276",
    OnBehalfOf: "Tactics",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-08-26T16:20:50.020Z"),
    Type: "system",
    DateDue: new Date("2020-02-16T10:37:27.036Z"),
    DateRevoked: new Date("2021-01-26T23:27:40.534Z"),
    Noticee: "Lilliana",
    CreatedDate: "2021-01-14T11:14:40.241Z",
    FeeType: "Home Loan Account",
    AmountIssued: "874.00",
    AmountOutstanding: 1024,
    CostCode: "56825-7707",
    InvoiceNumber: 42167,
    Name: "Breitenberg, Dickinson and Rohan",
    SystemTypeClassification: "Strategist",
    SortIndex: 38767,
    Title: "Product",
    Condition: "navigating Principal Quality-focused",
  },
  {
    ID: "33",
    PermitNumber: 90749,
    Applicant: "Hector Ratke",
    Owner: "Chelsie Hagenes",
    Address: "Suite 990",
    Suburb: "Texas",
    AssessmentNo: 16534,
    Status: "Complied",
    TypeOfApplication: "Health",
    FileNumber: 12951,
    SystemName: "microchip",
    SystemType: "microchip",
    Received: new Date("2021-01-26T18:58:35.528Z"),
    PTI: new Date("2021-01-26T14:35:44.671Z"),
    CTU: "CTU 33",
    WaterAuthority: "WaterAuthority 33",
    Plumber: "Leonora Toy Jr.",
    Risk: "Risk Level 33",
    CertificateNo: 54382,
    SerialNo: "25201-5533",
    Details: "microchip Steel optimize",
    ApplicantAddress: "33665 Miller Forges",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "760.00",
    GPS: "359.00",
    Manufacturer: "Raynor - Purdy",
    TankSize: 832.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Fahey, Zulauf and Runolfsson",
    SecondarySystemType: "Tools",
    SecondaryManufacturer: "Mann, Luettgen and Bahringer",
    SecondaryTankSize: "795.00",
    UnitSecondary: "UnitSecondary 33",
    TertiarySystemType: "Outdoors",
    TertiaryManufacturer: "Pollich - Wiza",
    TertiaryTankSize: "871.00",
    UnitTertiary: "UnitTertiary 33",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 33",
    Contractor: "Zola_Denesik82",
    RiskLevel: "RiskLevel 33",
    PermitType: "PermitType 33",
    ConstructionType: "ConstructionType 33",
    DebtorNo: 26071,
    FileNo: 9628,
    Issued: new Date("2020-03-16T02:50:32.182Z"),
    IssuingOfficer: "Stephanie",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-07-18T01:06:51.845Z"),
    ApprovalOfficer: "Ms. Pierce Walsh",
    OutstandingFees: "721.00",
    OSFees: "829.00",
    ComplainceCertNo: 50020,
    ApprovalForBL: new Date("2020-11-23T17:52:28.257Z"),
    BLicenceNumber: 42480,
    SentToDoH: new Date("2020-04-10T07:10:38.373Z"),
    DoHApproval: "DoHApproval 33",
    DateDoHApproved: new Date("2020-10-10T17:11:25.557Z"),
    DoHApprovalNo: "DoHApprovalNo 33",
    SerialNumber: 91233,
    LastInspection: new Date("2020-10-18T00:55:33.132Z"),
    Frequency: 77637,
    NextInspection: new Date("2022-01-04T21:50:07.532Z"),
    LastService: new Date("2020-09-01T03:27:04.210Z"),
    LastDesludge: new Date("2020-11-19T04:17:30.391Z"),
    LastSample: new Date("2020-02-22T21:40:24.489Z"),
    NextService: new Date("2021-05-19T20:03:51.263Z"),
    NextDesludge: new Date("2021-04-02T11:33:05.454Z"),
    NextSample: new Date("2021-06-17T20:42:39.073Z"),
    Comments:
      "Use the neural THX transmitter, then you can hack the wireless protocol!",
    Size: "892.00",
    DateInspected: new Date("2020-09-27T14:37:03.857Z"),
    InspectionType: "Engineer",
    Officer: "Dorcas Fadel",
    DateInspectedMonth: "January",
    DueStatus: "Avon",
    Material: "Rubber",
    InspectionFrequency: 51345,
    ReferenceNumber: "6196",
    ContactName: "Justen",
    Compliance: "hard drive",
    Reason:
      "Use the wireless CSS firewall, then you can connect the cross-platform circuit!",
    RefNo: "26764",
    Description: "We need to back up the digital GB protocol!",
    DateTarget: new Date("2020-02-12T01:38:22.877Z"),
    Source: "Kids",
    Category: "Fantastic",
    Location: "Sri Lanka",
    RequestedBy: "Elyssa",
    ContactDetails: "Vandervort",
    DateRecorded: new Date("2021-01-26T22:17:59.129Z"),
    RecordedBy: "Idella Pfannerstill",
    OrgStructure: "Licensed",
    ActionOfficer: "Dalton Von",
    Coordinator: "Jaydon Bartoletti",
    DateWork: new Date("2021-01-07T08:19:31.849Z"),
    EnquiryRefNo: "65197",
    Event_ReferenceNoAdditional: "93038",
    OnBehalfOf: "Response",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-08-26T15:09:25.439Z"),
    Type: "card",
    DateDue: new Date("2020-08-23T09:28:21.314Z"),
    DateRevoked: new Date("2021-01-26T20:05:38.990Z"),
    Noticee: "Caleigh",
    CreatedDate: "2020-04-14T15:44:17.890Z",
    FeeType: "Savings Account",
    AmountIssued: "777.00",
    AmountOutstanding: 96293,
    CostCode: "73005-3101",
    InvoiceNumber: 49174,
    Name: "Barton LLC",
    SystemTypeClassification: "Burgs",
    SortIndex: 76891,
    Title: "District",
    Condition: "PCI bottom-line pricing structure",
  },
  {
    ID: "34",
    PermitNumber: 26713,
    Applicant: "Rita Runolfsson",
    Owner: "Miss Josephine Mohr",
    Address: "Suite 651",
    Suburb: "Wisconsin",
    AssessmentNo: 19097,
    Status: "Complied",
    TypeOfApplication: "Outdoors",
    FileNumber: 57181,
    SystemName: "alarm",
    SystemType: "transmitter",
    Received: new Date("2021-01-27T05:08:26.754Z"),
    PTI: new Date("2021-01-27T02:31:35.634Z"),
    CTU: "CTU 34",
    WaterAuthority: "WaterAuthority 34",
    Plumber: "Clotilde VonRueden",
    Risk: "Risk Level 34",
    CertificateNo: 74900,
    SerialNo: "98268",
    Details: "Timor-Leste Sports",
    ApplicantAddress: "531 Shakira Circle",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "570.00",
    GPS: "300.00",
    Manufacturer: "Mohr, Doyle and Pagac",
    TankSize: 774.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hodkiewicz - Rau",
    SecondarySystemType: "Automotive",
    SecondaryManufacturer: "Herzog and Sons",
    SecondaryTankSize: "519.00",
    UnitSecondary: "UnitSecondary 34",
    TertiarySystemType: "Health",
    TertiaryManufacturer: "Cartwright - Hickle",
    TertiaryTankSize: "451.00",
    UnitTertiary: "UnitTertiary 34",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 34",
    Contractor: "Rosina_Kessler",
    RiskLevel: "RiskLevel 34",
    PermitType: "PermitType 34",
    ConstructionType: "ConstructionType 34",
    DebtorNo: 11147,
    FileNo: 56480,
    Issued: new Date("2020-08-22T16:41:55.857Z"),
    IssuingOfficer: "Augustine",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-07-13T21:18:58.711Z"),
    ApprovalOfficer: "Jaylan Collins",
    OutstandingFees: "84.00",
    OSFees: "451.00",
    ComplainceCertNo: 2474,
    ApprovalForBL: new Date("2020-07-01T21:31:49.155Z"),
    BLicenceNumber: 69544,
    SentToDoH: new Date("2020-09-04T19:33:03.867Z"),
    DoHApproval: "DoHApproval 34",
    DateDoHApproved: new Date("2021-01-19T09:56:23.214Z"),
    DoHApprovalNo: "DoHApprovalNo 34",
    SerialNumber: 33544,
    LastInspection: new Date("2021-01-27T03:59:41.551Z"),
    Frequency: 93539,
    NextInspection: new Date("2022-01-11T17:22:16.934Z"),
    LastService: new Date("2020-04-12T23:48:50.860Z"),
    LastDesludge: new Date("2020-08-17T04:46:02.172Z"),
    LastSample: new Date("2020-01-31T09:51:25.733Z"),
    NextService: new Date("2021-08-09T10:27:24.616Z"),
    NextDesludge: new Date("2021-08-05T09:07:08.751Z"),
    NextSample: new Date("2021-07-23T20:11:51.409Z"),
    Comments:
      "If we compress the application, we can get to the PNG interface through the bluetooth SMTP card!",
    Size: "5.00",
    DateInspected: new Date("2020-07-06T11:00:33.410Z"),
    InspectionType: "Architect",
    Officer: "Rosanna Turner PhD",
    DateInspectedMonth: "April",
    DueStatus: "efficient",
    Material: "Concrete",
    InspectionFrequency: 46340,
    ReferenceNumber: "4608",
    ContactName: "Osbaldo",
    Compliance: "panel",
    Reason:
      "If we input the port, we can get to the IB feed through the digital PNG program!",
    RefNo: "51855",
    Description:
      "Try to program the PNG capacitor, maybe it will parse the multi-byte microchip!",
    DateTarget: new Date("2020-08-07T09:47:45.358Z"),
    Source: "Health",
    Category: "Practical",
    Location: "Saint Helena",
    RequestedBy: "Jedidiah",
    ContactDetails: "Dickens",
    DateRecorded: new Date("2021-01-26T15:16:34.782Z"),
    RecordedBy: "Enoch Schumm",
    OrgStructure: "Cayman Islands",
    ActionOfficer: "Lela Upton",
    Coordinator: "Frederick Murray",
    DateWork: new Date("2020-12-31T08:57:02.102Z"),
    EnquiryRefNo: "13565",
    Event_ReferenceNoAdditional: "6729",
    OnBehalfOf: "Intranet",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-04-07T11:02:29.866Z"),
    Type: "transmitter",
    DateDue: new Date("2020-04-06T11:03:14.597Z"),
    DateRevoked: new Date("2021-01-26T23:36:41.927Z"),
    Noticee: "Salma",
    CreatedDate: "2020-12-09T20:40:32.923Z",
    FeeType: "Auto Loan Account",
    AmountIssued: "822.00",
    AmountOutstanding: 95962,
    CostCode: "64550-6335",
    InvoiceNumber: 88472,
    Name: "Schulist Inc",
    SystemTypeClassification: "Keyboard",
    SortIndex: 56401,
    Title: "Dynamic",
    Condition: "Sports Court program",
  },
  {
    ID: "35",
    PermitNumber: 26652,
    Applicant: "Marcelina Jones",
    Owner: "Mr. Christy Gibson",
    Address: "Suite 427",
    Suburb: "Oregon",
    AssessmentNo: 48616,
    Status: "Complied",
    TypeOfApplication: "Clothing",
    FileNumber: 78922,
    SystemName: "panel",
    SystemType: "card",
    Received: new Date("2021-01-27T01:02:19.476Z"),
    PTI: new Date("2021-01-27T01:45:46.425Z"),
    CTU: "CTU 35",
    WaterAuthority: "WaterAuthority 35",
    Plumber: "Elliott Gaylord",
    Risk: "Risk Level 35",
    CertificateNo: 83729,
    SerialNo: "41168-3800",
    Details: "Tactics",
    ApplicantAddress: "63403 Larkin Road",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "696.00",
    GPS: "577.00",
    Manufacturer: "Oberbrunner Group",
    TankSize: 766.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Greenfelder Group",
    SecondarySystemType: "Jewelery",
    SecondaryManufacturer: "Mraz, Kertzmann and Lind",
    SecondaryTankSize: "951.00",
    UnitSecondary: "UnitSecondary 35",
    TertiarySystemType: "Health",
    TertiaryManufacturer: "Koepp and Sons",
    TertiaryTankSize: "868.00",
    UnitTertiary: "UnitTertiary 35",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 35",
    Contractor: "Alexandra_Bailey28",
    RiskLevel: "RiskLevel 35",
    PermitType: "PermitType 35",
    ConstructionType: "ConstructionType 35",
    DebtorNo: 15854,
    FileNo: 11532,
    Issued: new Date("2020-09-22T11:07:10.512Z"),
    IssuingOfficer: "Greyson",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-05-27T03:59:46.698Z"),
    ApprovalOfficer: "Maxwell Haley",
    OutstandingFees: "700.00",
    OSFees: "10.00",
    ComplainceCertNo: 73180,
    ApprovalForBL: new Date("2020-08-30T03:58:07.120Z"),
    BLicenceNumber: 77345,
    SentToDoH: new Date("2020-08-27T20:10:43.136Z"),
    DoHApproval: "DoHApproval 35",
    DateDoHApproved: new Date("2020-03-24T05:19:32.106Z"),
    DoHApprovalNo: "DoHApprovalNo 35",
    SerialNumber: 62650,
    LastInspection: new Date("2020-07-01T08:20:22.239Z"),
    Frequency: 5416,
    NextInspection: new Date("2021-06-23T21:20:01.508Z"),
    LastService: new Date("2020-07-16T07:40:17.079Z"),
    LastDesludge: new Date("2020-09-08T20:14:29.986Z"),
    LastSample: new Date("2020-07-14T22:05:40.018Z"),
    NextService: new Date("2021-12-01T21:50:22.278Z"),
    NextDesludge: new Date("2022-01-18T05:30:23.860Z"),
    NextSample: new Date("2021-05-10T19:21:42.599Z"),
    Comments:
      "I'll compress the bluetooth SDD sensor, that should array the XSS protocol!",
    Size: "2.00",
    DateInspected: new Date("2020-08-30T11:51:44.157Z"),
    InspectionType: "Officer",
    Officer: "Nayeli Zboncak",
    DateInspectedMonth: "August",
    DueStatus: "Metal",
    Material: "Plastic",
    InspectionFrequency: 27484,
    ReferenceNumber: "97238",
    ContactName: "Maryjane",
    Compliance: "bus",
    Reason: "We need to calculate the cross-platform HDD capacitor!",
    RefNo: "60584",
    Description:
      "I'll hack the haptic SMS matrix, that should bandwidth the HTTP transmitter!",
    DateTarget: new Date("2020-03-05T15:13:26.075Z"),
    Source: "Music",
    Category: "Awesome",
    Location: "Montserrat",
    RequestedBy: "Gerson",
    ContactDetails: "Kiehn",
    DateRecorded: new Date("2021-01-27T00:44:14.568Z"),
    RecordedBy: "Rowan Mueller",
    OrgStructure: "Squares",
    ActionOfficer: "Mrs. Marilou Goodwin",
    Coordinator: "Miss Mayra Mills",
    DateWork: new Date("2020-07-27T02:20:15.689Z"),
    EnquiryRefNo: "81768",
    Event_ReferenceNoAdditional: "70950",
    OnBehalfOf: "Assurance",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-09-02T05:04:05.332Z"),
    Type: "pixel",
    DateDue: new Date("2020-06-02T19:59:06.904Z"),
    DateRevoked: new Date("2021-01-26T23:14:12.932Z"),
    Noticee: "Patience",
    CreatedDate: "2020-04-02T15:31:00.262Z",
    FeeType: "Credit Card Account",
    AmountIssued: "512.00",
    AmountOutstanding: 58180,
    CostCode: "57777",
    InvoiceNumber: 93906,
    Name: "Lesch and Sons",
    SystemTypeClassification: "South Carolina",
    SortIndex: 88448,
    Title: "Corporate",
    Condition: "Quality Executive logistical",
  },
  {
    ID: "36",
    PermitNumber: 2307,
    Applicant: "Eldred Wiegand",
    Owner: "Caroline Sipes",
    Address: "Apt. 458",
    Suburb: "Alaska",
    AssessmentNo: 37622,
    Status: "Complied",
    TypeOfApplication: "Kids",
    FileNumber: 65339,
    SystemName: "microchip",
    SystemType: "port",
    Received: new Date("2021-01-27T03:47:32.194Z"),
    PTI: new Date("2021-01-26T21:05:45.850Z"),
    CTU: "CTU 36",
    WaterAuthority: "WaterAuthority 36",
    Plumber: "Mrs. Ava Blanda",
    Risk: "Risk Level 36",
    CertificateNo: 41264,
    SerialNo: "96681-6398",
    Details: "iterate Product Metal",
    ApplicantAddress: "6167 Hayes Unions",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "133.00",
    GPS: "722.00",
    Manufacturer: "Runolfsdottir LLC",
    TankSize: 218.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Gerhold, Jast and Schuster",
    SecondarySystemType: "Games",
    SecondaryManufacturer: "Dare Inc",
    SecondaryTankSize: "187.00",
    UnitSecondary: "UnitSecondary 36",
    TertiarySystemType: "Automotive",
    TertiaryManufacturer: "Ward and Sons",
    TertiaryTankSize: "324.00",
    UnitTertiary: "UnitTertiary 36",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 36",
    Contractor: "Alana70",
    RiskLevel: "RiskLevel 36",
    PermitType: "PermitType 36",
    ConstructionType: "ConstructionType 36",
    DebtorNo: 26433,
    FileNo: 83939,
    Issued: new Date("2020-02-29T12:40:20.450Z"),
    IssuingOfficer: "Kevin",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-05-02T08:24:59.016Z"),
    ApprovalOfficer: "Sonya Green",
    OutstandingFees: "365.00",
    OSFees: "206.00",
    ComplainceCertNo: 332,
    ApprovalForBL: new Date("2020-07-09T01:42:23.489Z"),
    BLicenceNumber: 73474,
    SentToDoH: new Date("2021-01-14T19:45:09.299Z"),
    DoHApproval: "DoHApproval 36",
    DateDoHApproved: new Date("2020-09-03T10:05:44.447Z"),
    DoHApprovalNo: "DoHApprovalNo 36",
    SerialNumber: 1173,
    LastInspection: new Date("2020-07-20T04:06:35.083Z"),
    Frequency: 92775,
    NextInspection: new Date("2021-04-13T18:12:37.400Z"),
    LastService: new Date("2020-10-30T02:10:40.001Z"),
    LastDesludge: new Date("2020-01-28T15:39:43.728Z"),
    LastSample: new Date("2020-02-08T09:03:43.696Z"),
    NextService: new Date("2021-02-24T21:31:03.281Z"),
    NextDesludge: new Date("2021-10-10T23:56:42.998Z"),
    NextSample: new Date("2021-05-16T09:42:26.586Z"),
    Comments:
      "Try to hack the RAM capacitor, maybe it will hack the multi-byte microchip!",
    Size: "517.00",
    DateInspected: new Date("2020-03-16T23:51:49.468Z"),
    InspectionType: "Engineer",
    Officer: "Trystan Howe",
    DateInspectedMonth: "February",
    DueStatus: "Liaison",
    Material: "Steel",
    InspectionFrequency: 67822,
    ReferenceNumber: "64581",
    ContactName: "Erich",
    Compliance: "transmitter",
    Reason:
      "connecting the firewall won't do anything, we need to input the neural ADP bandwidth!",
    RefNo: "47730",
    Description:
      "indexing the bandwidth won't do anything, we need to index the neural SMS hard drive!",
    DateTarget: new Date("2020-06-13T17:56:14.623Z"),
    Source: "Industrial",
    Category: "Incredible",
    Location: "Zimbabwe",
    RequestedBy: "Crawford",
    ContactDetails: "Breitenberg",
    DateRecorded: new Date("2021-01-26T16:14:59.373Z"),
    RecordedBy: "Josefina Hansen",
    OrgStructure: "Investor",
    ActionOfficer: "Carleton Upton",
    Coordinator: "Gabe Purdy",
    DateWork: new Date("2020-08-02T08:44:24.340Z"),
    EnquiryRefNo: "47749",
    Event_ReferenceNoAdditional: "55742",
    OnBehalfOf: "Factors",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-07-01T09:35:33.987Z"),
    Type: "interface",
    DateDue: new Date("2020-12-24T13:10:58.539Z"),
    DateRevoked: new Date("2021-01-26T08:57:09.537Z"),
    Noticee: "Lavinia",
    CreatedDate: "2020-08-08T20:24:13.801Z",
    FeeType: "Credit Card Account",
    AmountIssued: "857.00",
    AmountOutstanding: 61950,
    CostCode: "34723-5442",
    InvoiceNumber: 79494,
    Name: "Johns Group",
    SystemTypeClassification: "Implementation",
    SortIndex: 42448,
    Title: "District",
    Condition: "Congolese Franc Pike",
  },
  {
    ID: "37",
    PermitNumber: 99100,
    Applicant: "Winfield Grimes",
    Owner: "Mrs. Erin Greenfelder",
    Address: "Apt. 752",
    Suburb: "Texas",
    AssessmentNo: 13519,
    Status: "Complied",
    TypeOfApplication: "Clothing",
    FileNumber: 10203,
    SystemName: "matrix",
    SystemType: "hard drive",
    Received: new Date("2021-01-26T23:34:55.966Z"),
    PTI: new Date("2021-01-26T06:54:46.845Z"),
    CTU: "CTU 37",
    WaterAuthority: "WaterAuthority 37",
    Plumber: "Kieran Gottlieb",
    Risk: "Risk Level 37",
    CertificateNo: 48171,
    SerialNo: "43586",
    Details: "calculate Shoes lime",
    ApplicantAddress: "931 MacGyver Island",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "304.00",
    GPS: "843.00",
    Manufacturer: "Trantow, Bednar and Mosciski",
    TankSize: 469.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hyatt, Beatty and Macejkovic",
    SecondarySystemType: "Sports",
    SecondaryManufacturer: "Mante Group",
    SecondaryTankSize: "269.00",
    UnitSecondary: "UnitSecondary 37",
    TertiarySystemType: "Baby",
    TertiaryManufacturer: "Hammes - Hilll",
    TertiaryTankSize: "906.00",
    UnitTertiary: "UnitTertiary 37",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 37",
    Contractor: "Elmira.Beahan",
    RiskLevel: "RiskLevel 37",
    PermitType: "PermitType 37",
    ConstructionType: "ConstructionType 37",
    DebtorNo: 67181,
    FileNo: 90248,
    Issued: new Date("2020-07-19T13:32:25.237Z"),
    IssuingOfficer: "Vivianne",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-09-28T02:00:50.872Z"),
    ApprovalOfficer: "Susana Smith",
    OutstandingFees: "699.00",
    OSFees: "93.00",
    ComplainceCertNo: 51786,
    ApprovalForBL: new Date("2020-02-01T19:47:33.662Z"),
    BLicenceNumber: 97356,
    SentToDoH: new Date("2020-04-27T00:13:56.624Z"),
    DoHApproval: "DoHApproval 37",
    DateDoHApproved: new Date("2020-08-17T02:34:58.419Z"),
    DoHApprovalNo: "DoHApprovalNo 37",
    SerialNumber: 18427,
    LastInspection: new Date("2020-12-18T18:14:48.434Z"),
    Frequency: 9465,
    NextInspection: new Date("2021-09-02T13:01:08.662Z"),
    LastService: new Date("2020-04-12T22:30:26.986Z"),
    LastDesludge: new Date("2020-06-12T23:03:40.865Z"),
    LastSample: new Date("2020-05-01T05:21:25.997Z"),
    NextService: new Date("2021-06-05T08:58:09.839Z"),
    NextDesludge: new Date("2021-12-03T15:03:47.916Z"),
    NextSample: new Date("2021-04-12T08:30:18.120Z"),
    Comments: "We need to copy the digital SMS matrix!",
    Size: "233.00",
    DateInspected: new Date("2020-07-06T08:51:53.776Z"),
    InspectionType: "Specialist",
    Officer: "Zoila Nikolaus",
    DateInspectedMonth: "January",
    DueStatus: "Lari",
    Material: "Wooden",
    InspectionFrequency: 62602,
    ReferenceNumber: "83429",
    ContactName: "Daryl",
    Compliance: "circuit",
    Reason:
      "If we navigate the bandwidth, we can get to the ADP pixel through the wireless AGP hard drive!",
    RefNo: "9607",
    Description:
      "Use the optical JSON circuit, then you can copy the bluetooth transmitter!",
    DateTarget: new Date("2020-11-19T05:40:50.707Z"),
    Source: "Automotive",
    Category: "Fantastic",
    Location: "Suriname",
    RequestedBy: "Amira",
    ContactDetails: "Bernier",
    DateRecorded: new Date("2021-01-26T14:14:27.672Z"),
    RecordedBy: "Leda Gibson",
    OrgStructure: "implement",
    ActionOfficer: "Marquise Senger",
    Coordinator: "Emie Spinka",
    DateWork: new Date("2020-12-14T01:05:19.958Z"),
    EnquiryRefNo: "31703",
    Event_ReferenceNoAdditional: "41845",
    OnBehalfOf: "Usability",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-08-12T18:30:03.776Z"),
    Type: "feed",
    DateDue: new Date("2020-08-12T08:41:41.831Z"),
    DateRevoked: new Date("2021-01-26T21:35:37.214Z"),
    Noticee: "Maymie",
    CreatedDate: "2020-05-26T05:33:24.065Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "445.00",
    AmountOutstanding: 61130,
    CostCode: "19927-0237",
    InvoiceNumber: 85625,
    Name: "Dickens - Rau",
    SystemTypeClassification: "redundant",
    SortIndex: 28896,
    Title: "Corporate",
    Condition: "capability",
  },
  {
    ID: "38",
    PermitNumber: 59465,
    Applicant: "Garry Pacocha",
    Owner: "Mariana Pouros Sr.",
    Address: "Suite 573",
    Suburb: "Georgia",
    AssessmentNo: 98089,
    Status: "Complied",
    TypeOfApplication: "Home",
    FileNumber: 68870,
    SystemName: "pixel",
    SystemType: "hard drive",
    Received: new Date("2021-01-26T15:33:13.445Z"),
    PTI: new Date("2021-01-27T05:08:16.527Z"),
    CTU: "CTU 38",
    WaterAuthority: "WaterAuthority 38",
    Plumber: "Chaim Strosin",
    Risk: "Risk Level 38",
    CertificateNo: 94861,
    SerialNo: "29802",
    Details: "Grenada",
    ApplicantAddress: "62829 Stark Views",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "376.00",
    GPS: "252.00",
    Manufacturer: "Zemlak, Abbott and Schmidt",
    TankSize: 299.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Prosacco Group",
    SecondarySystemType: "Books",
    SecondaryManufacturer: "Romaguera, Conroy and Koepp",
    SecondaryTankSize: "556.00",
    UnitSecondary: "UnitSecondary 38",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Prosacco Inc",
    TertiaryTankSize: "570.00",
    UnitTertiary: "UnitTertiary 38",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 38",
    Contractor: "Benedict.West",
    RiskLevel: "RiskLevel 38",
    PermitType: "PermitType 38",
    ConstructionType: "ConstructionType 38",
    DebtorNo: 14321,
    FileNo: 45496,
    Issued: new Date("2020-02-22T01:45:23.107Z"),
    IssuingOfficer: "Marshall",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-10-30T11:52:39.989Z"),
    ApprovalOfficer: "Michelle Greenfelder",
    OutstandingFees: "116.00",
    OSFees: "313.00",
    ComplainceCertNo: 36393,
    ApprovalForBL: new Date("2020-05-20T05:13:32.865Z"),
    BLicenceNumber: 38733,
    SentToDoH: new Date("2020-03-25T01:52:38.411Z"),
    DoHApproval: "DoHApproval 38",
    DateDoHApproved: new Date("2020-02-10T11:19:18.982Z"),
    DoHApprovalNo: "DoHApprovalNo 38",
    SerialNumber: 6545,
    LastInspection: new Date("2020-04-01T08:14:04.113Z"),
    Frequency: 62413,
    NextInspection: new Date("2021-09-24T03:30:44.777Z"),
    LastService: new Date("2020-04-24T18:33:26.319Z"),
    LastDesludge: new Date("2020-11-25T05:59:36.443Z"),
    LastSample: new Date("2020-06-02T21:01:37.876Z"),
    NextService: new Date("2022-01-26T15:46:08.811Z"),
    NextDesludge: new Date("2021-02-15T00:10:53.109Z"),
    NextSample: new Date("2021-12-06T01:51:18.206Z"),
    Comments: "We need to bypass the digital XSS port!",
    Size: "238.00",
    DateInspected: new Date("2020-11-16T06:58:40.584Z"),
    InspectionType: "Analyst",
    Officer: "Wiley Weimann",
    DateInspectedMonth: "December",
    DueStatus: "connect",
    Material: "Fresh",
    InspectionFrequency: 41436,
    ReferenceNumber: "67075",
    ContactName: "Piper",
    Compliance: "sensor",
    Reason:
      "Try to navigate the HDD pixel, maybe it will reboot the redundant card!",
    RefNo: "50835",
    Description:
      "I'll input the virtual XML firewall, that should panel the SAS capacitor!",
    DateTarget: new Date("2021-01-11T08:28:16.332Z"),
    Source: "Health",
    Category: "Awesome",
    Location: "Saint Pierre and Miquelon",
    RequestedBy: "Valentina",
    ContactDetails: "Stoltenberg",
    DateRecorded: new Date("2021-01-27T03:10:48.909Z"),
    RecordedBy: "Herman Stiedemann",
    OrgStructure: "support",
    ActionOfficer: "Trystan Schimmel MD",
    Coordinator: "Abelardo Jones",
    DateWork: new Date("2021-01-11T12:39:47.299Z"),
    EnquiryRefNo: "29035",
    Event_ReferenceNoAdditional: "22765",
    OnBehalfOf: "Quality",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2021-01-19T21:55:24.933Z"),
    Type: "application",
    DateDue: new Date("2020-10-25T21:17:59.606Z"),
    DateRevoked: new Date("2021-01-26T17:25:08.033Z"),
    Noticee: "Maxime",
    CreatedDate: "2020-08-13T00:07:14.330Z",
    FeeType: "Investment Account",
    AmountIssued: "726.00",
    AmountOutstanding: 28553,
    CostCode: "08431-6157",
    InvoiceNumber: 20124,
    Name: "Miller LLC",
    SystemTypeClassification: "digital",
    SortIndex: 56531,
    Title: "Chief",
    Condition: "Wooden",
  },
  {
    ID: "39",
    PermitNumber: 73118,
    Applicant: "Gisselle Stehr MD",
    Owner: "Hillard Krajcik",
    Address: "Apt. 300",
    Suburb: "Texas",
    AssessmentNo: 81872,
    Status: "Complied",
    TypeOfApplication: "Music",
    FileNumber: 85257,
    SystemName: "card",
    SystemType: "hard drive",
    Received: new Date("2021-01-26T22:19:41.502Z"),
    PTI: new Date("2021-01-27T00:11:59.542Z"),
    CTU: "CTU 39",
    WaterAuthority: "WaterAuthority 39",
    Plumber: "Frieda Bogan",
    Risk: "Risk Level 39",
    CertificateNo: 62273,
    SerialNo: "93932-0238",
    Details: "infomediaries",
    ApplicantAddress: "508 Lafayette Place",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "711.00",
    GPS: "946.00",
    Manufacturer: "Kohler - Harvey",
    TankSize: 835.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hane - Effertz",
    SecondarySystemType: "Movies",
    SecondaryManufacturer: "Bashirian Group",
    SecondaryTankSize: "479.00",
    UnitSecondary: "UnitSecondary 39",
    TertiarySystemType: "Automotive",
    TertiaryManufacturer: "Steuber, Keebler and Gerlach",
    TertiaryTankSize: "214.00",
    UnitTertiary: "UnitTertiary 39",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 39",
    Contractor: "Alexys.Nicolas38",
    RiskLevel: "RiskLevel 39",
    PermitType: "PermitType 39",
    ConstructionType: "ConstructionType 39",
    DebtorNo: 60469,
    FileNo: 982,
    Issued: new Date("2021-01-09T23:27:04.690Z"),
    IssuingOfficer: "Jamison",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-06-16T13:06:01.496Z"),
    ApprovalOfficer: "Verlie Terry",
    OutstandingFees: "39.00",
    OSFees: "11.00",
    ComplainceCertNo: 42552,
    ApprovalForBL: new Date("2020-08-13T22:05:39.429Z"),
    BLicenceNumber: 89009,
    SentToDoH: new Date("2020-07-24T07:00:01.925Z"),
    DoHApproval: "DoHApproval 39",
    DateDoHApproved: new Date("2020-10-18T17:54:39.667Z"),
    DoHApprovalNo: "DoHApprovalNo 39",
    SerialNumber: 85833,
    LastInspection: new Date("2021-01-07T21:19:08.565Z"),
    Frequency: 83701,
    NextInspection: new Date("2021-02-04T15:33:52.417Z"),
    LastService: new Date("2020-08-18T04:14:55.372Z"),
    LastDesludge: new Date("2020-10-24T02:07:22.301Z"),
    LastSample: new Date("2020-04-21T09:06:49.705Z"),
    NextService: new Date("2021-07-02T20:14:32.644Z"),
    NextDesludge: new Date("2021-10-30T17:10:09.326Z"),
    NextSample: new Date("2021-12-10T16:51:45.899Z"),
    Comments:
      "Use the solid state JSON port, then you can transmit the multi-byte pixel!",
    Size: "459.00",
    DateInspected: new Date("2020-06-21T10:52:54.645Z"),
    InspectionType: "Associate",
    Officer: "Josefina Beahan",
    DateInspectedMonth: "November",
    DueStatus: "plum",
    Material: "Steel",
    InspectionFrequency: 83315,
    ReferenceNumber: "65793",
    ContactName: "Cristopher",
    Compliance: "system",
    Reason:
      "Try to override the JSON capacitor, maybe it will bypass the digital program!",
    RefNo: "59327",
    Description:
      "If we copy the hard drive, we can get to the IB capacitor through the primary AI capacitor!",
    DateTarget: new Date("2020-04-02T20:27:50.084Z"),
    Source: "Jewelery",
    Category: "Awesome",
    Location: "Iceland",
    RequestedBy: "Brian",
    ContactDetails: "Kuhn",
    DateRecorded: new Date("2021-01-26T19:02:24.513Z"),
    RecordedBy: "Mr. Georgianna Thompson",
    OrgStructure: "Corner",
    ActionOfficer: "Adaline Reichert",
    Coordinator: "Leopold Batz",
    DateWork: new Date("2020-04-07T16:53:24.544Z"),
    EnquiryRefNo: "13476",
    Event_ReferenceNoAdditional: "42626",
    OnBehalfOf: "Integration",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-02-25T17:12:00.087Z"),
    Type: "matrix",
    DateDue: new Date("2020-11-28T09:27:07.772Z"),
    DateRevoked: new Date("2021-01-26T16:37:01.687Z"),
    Noticee: "Hardy",
    CreatedDate: "2020-02-22T21:44:37.616Z",
    FeeType: "Money Market Account",
    AmountIssued: "270.00",
    AmountOutstanding: 6659,
    CostCode: "09533-2480",
    InvoiceNumber: 7236,
    Name: "Jaskolski Group",
    SystemTypeClassification: "Arizona",
    SortIndex: 47701,
    Title: "Human",
    Condition: "Mongolia",
  },
  {
    ID: "40",
    PermitNumber: 72209,
    Applicant: "Terrell Wiza",
    Owner: "Tyrese Miller",
    Address: "Suite 808",
    Suburb: "Arizona",
    AssessmentNo: 31733,
    Status: "Complied",
    TypeOfApplication: "Home",
    FileNumber: 2045,
    SystemName: "protocol",
    SystemType: "bus",
    Received: new Date("2021-01-26T11:30:05.761Z"),
    PTI: new Date("2021-01-26T12:47:05.238Z"),
    CTU: "CTU 40",
    WaterAuthority: "WaterAuthority 40",
    Plumber: "Jaylon Brown",
    Risk: "Risk Level 40",
    CertificateNo: 95613,
    SerialNo: "61794-5654",
    Details: "indexing Ohio",
    ApplicantAddress: "3683 Braun Crest",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "542.00",
    GPS: "963.00",
    Manufacturer: "Dietrich Inc",
    TankSize: 622.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Upton - Braun",
    SecondarySystemType: "Kids",
    SecondaryManufacturer: "Rolfson - Boehm",
    SecondaryTankSize: "254.00",
    UnitSecondary: "UnitSecondary 40",
    TertiarySystemType: "Music",
    TertiaryManufacturer: "Schultz - Gibson",
    TertiaryTankSize: "482.00",
    UnitTertiary: "UnitTertiary 40",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 40",
    Contractor: "Willie.Cremin",
    RiskLevel: "RiskLevel 40",
    PermitType: "PermitType 40",
    ConstructionType: "ConstructionType 40",
    DebtorNo: 39058,
    FileNo: 60575,
    Issued: new Date("2020-02-19T23:27:30.079Z"),
    IssuingOfficer: "Sammie",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-08-17T18:07:10.493Z"),
    ApprovalOfficer: "Jamil Waters",
    OutstandingFees: "600.00",
    OSFees: "222.00",
    ComplainceCertNo: 24624,
    ApprovalForBL: new Date("2020-10-02T01:10:14.965Z"),
    BLicenceNumber: 57851,
    SentToDoH: new Date("2020-05-09T14:47:42.590Z"),
    DoHApproval: "DoHApproval 40",
    DateDoHApproved: new Date("2020-04-12T22:28:24.993Z"),
    DoHApprovalNo: "DoHApprovalNo 40",
    SerialNumber: 36892,
    LastInspection: new Date("2021-01-12T23:36:14.948Z"),
    Frequency: 93059,
    NextInspection: new Date("2021-08-20T19:13:30.367Z"),
    LastService: new Date("2020-06-12T10:23:59.548Z"),
    LastDesludge: new Date("2020-07-14T15:18:20.628Z"),
    LastSample: new Date("2020-07-15T01:05:50.925Z"),
    NextService: new Date("2021-11-05T10:03:21.230Z"),
    NextDesludge: new Date("2021-03-10T06:56:41.309Z"),
    NextSample: new Date("2021-12-06T01:36:49.335Z"),
    Comments:
      "I'll transmit the 1080p SAS monitor, that should bus the THX card!",
    Size: "240.00",
    DateInspected: new Date("2020-06-29T18:03:28.036Z"),
    InspectionType: "Associate",
    Officer: "Gloria Jast III",
    DateInspectedMonth: "November",
    DueStatus: "payment",
    Material: "Metal",
    InspectionFrequency: 31676,
    ReferenceNumber: "81852",
    ContactName: "Delbert",
    Compliance: "hard drive",
    Reason: "We need to synthesize the wireless GB pixel!",
    RefNo: "12226",
    Description:
      "I'll synthesize the cross-platform SMTP panel, that should program the PNG program!",
    DateTarget: new Date("2020-04-26T04:58:43.798Z"),
    Source: "Music",
    Category: "Ergonomic",
    Location: "Iran",
    RequestedBy: "Yessenia",
    ContactDetails: "Armstrong",
    DateRecorded: new Date("2021-01-26T21:13:01.671Z"),
    RecordedBy: "Lucie Armstrong",
    OrgStructure: "South Dakota",
    ActionOfficer: "Margot Rutherford IV",
    Coordinator: "Newton Wisozk",
    DateWork: new Date("2020-06-02T15:08:57.407Z"),
    EnquiryRefNo: "6169",
    Event_ReferenceNoAdditional: "40417",
    OnBehalfOf: "Configuration",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-10-22T14:13:05.508Z"),
    Type: "application",
    DateDue: new Date("2020-07-18T06:47:38.349Z"),
    DateRevoked: new Date("2021-01-26T16:38:13.134Z"),
    Noticee: "Jayne",
    CreatedDate: "2020-06-20T19:00:03.336Z",
    FeeType: "Money Market Account",
    AmountIssued: "546.00",
    AmountOutstanding: 67993,
    CostCode: "94646",
    InvoiceNumber: 64502,
    Name: "Pfannerstill, VonRueden and Zulauf",
    SystemTypeClassification: "Avon",
    SortIndex: 76534,
    Title: "Regional",
    Condition: "Cotton Practical Concrete Pants Refined Granite Fish",
  },
  {
    ID: "41",
    PermitNumber: 92976,
    Applicant: "Mr. Peyton Lockman",
    Owner: "Cornell Trantow III",
    Address: "Apt. 218",
    Suburb: "Ohio",
    AssessmentNo: 50476,
    Status: "Complied",
    TypeOfApplication: "Home",
    FileNumber: 72234,
    SystemName: "system",
    SystemType: "pixel",
    Received: new Date("2021-01-26T19:45:33.112Z"),
    PTI: new Date("2021-01-26T07:25:12.726Z"),
    CTU: "CTU 41",
    WaterAuthority: "WaterAuthority 41",
    Plumber: "Obie Ernser",
    Risk: "Risk Level 41",
    CertificateNo: 73891,
    SerialNo: "10132",
    Details: "action-items",
    ApplicantAddress: "722 Kellie Mission",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "635.00",
    GPS: "543.00",
    Manufacturer: "Yundt, Feil and Hilll",
    TankSize: 190.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Fritsch, Boyle and Herzog",
    SecondarySystemType: "Games",
    SecondaryManufacturer: "Gerlach, Padberg and Rodriguez",
    SecondaryTankSize: "675.00",
    UnitSecondary: "UnitSecondary 41",
    TertiarySystemType: "Industrial",
    TertiaryManufacturer: "Rosenbaum, Wiegand and O'Reilly",
    TertiaryTankSize: "333.00",
    UnitTertiary: "UnitTertiary 41",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 41",
    Contractor: "Mac_Bogan34",
    RiskLevel: "RiskLevel 41",
    PermitType: "PermitType 41",
    ConstructionType: "ConstructionType 41",
    DebtorNo: 47667,
    FileNo: 96221,
    Issued: new Date("2020-06-07T08:21:45.088Z"),
    IssuingOfficer: "Johnnie",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-05-25T22:33:36.585Z"),
    ApprovalOfficer: "Nicole Hickle",
    OutstandingFees: "585.00",
    OSFees: "920.00",
    ComplainceCertNo: 76421,
    ApprovalForBL: new Date("2020-10-13T19:32:58.975Z"),
    BLicenceNumber: 71095,
    SentToDoH: new Date("2020-09-17T13:09:01.951Z"),
    DoHApproval: "DoHApproval 41",
    DateDoHApproved: new Date("2020-05-08T18:40:42.617Z"),
    DoHApprovalNo: "DoHApprovalNo 41",
    SerialNumber: 1659,
    LastInspection: new Date("2020-08-04T19:00:56.206Z"),
    Frequency: 78234,
    NextInspection: new Date("2021-01-31T17:54:46.182Z"),
    LastService: new Date("2020-10-29T12:19:40.610Z"),
    LastDesludge: new Date("2020-10-29T23:24:39.408Z"),
    LastSample: new Date("2020-04-20T15:21:42.667Z"),
    NextService: new Date("2021-02-03T18:25:07.286Z"),
    NextDesludge: new Date("2021-04-23T16:33:33.367Z"),
    NextSample: new Date("2021-09-17T13:32:05.097Z"),
    Comments:
      "The SDD firewall is down, connect the optical transmitter so we can parse the THX matrix!",
    Size: "103.00",
    DateInspected: new Date("2020-10-13T01:07:32.948Z"),
    InspectionType: "Officer",
    Officer: "Alessandra D'Amore",
    DateInspectedMonth: "October",
    DueStatus: "Investor",
    Material: "Fresh",
    InspectionFrequency: 93851,
    ReferenceNumber: "63363",
    ContactName: "Eugene",
    Compliance: "panel",
    Reason:
      "quantifying the card won't do anything, we need to parse the 1080p IB panel!",
    RefNo: "80618",
    Description:
      "I'll generate the wireless JBOD hard drive, that should application the SMS hard drive!",
    DateTarget: new Date("2020-10-02T10:37:04.432Z"),
    Source: "Shoes",
    Category: "Gorgeous",
    Location: "Spain",
    RequestedBy: "Yolanda",
    ContactDetails: "Armstrong",
    DateRecorded: new Date("2021-01-26T11:53:24.260Z"),
    RecordedBy: "Mrs. Sydnie Dooley",
    OrgStructure: "contingency",
    ActionOfficer: "Vernie Rolfson V",
    Coordinator: "Carole Lebsack",
    DateWork: new Date("2020-08-31T17:48:52.300Z"),
    EnquiryRefNo: "13181",
    Event_ReferenceNoAdditional: "72192",
    OnBehalfOf: "Creative",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-09-26T23:51:55.195Z"),
    Type: "capacitor",
    DateDue: new Date("2021-01-20T00:33:31.243Z"),
    DateRevoked: new Date("2021-01-26T20:51:48.413Z"),
    Noticee: "Dominique",
    CreatedDate: "2020-11-01T10:10:00.673Z",
    FeeType: "Home Loan Account",
    AmountIssued: "708.00",
    AmountOutstanding: 43212,
    CostCode: "37174-0499",
    InvoiceNumber: 6660,
    Name: "Grady, Buckridge and Steuber",
    SystemTypeClassification: "action-items",
    SortIndex: 79643,
    Title: "Corporate",
    Condition: "heuristic Games non-volatile",
  },
  {
    ID: "42",
    PermitNumber: 23409,
    Applicant: "Morton Gibson",
    Owner: "Mr. Rebeka Purdy",
    Address: "Apt. 679",
    Suburb: "Delaware",
    AssessmentNo: 96464,
    Status: "Complied",
    TypeOfApplication: "Baby",
    FileNumber: 12842,
    SystemName: "hard drive",
    SystemType: "capacitor",
    Received: new Date("2021-01-26T22:52:49.065Z"),
    PTI: new Date("2021-01-26T13:26:07.072Z"),
    CTU: "CTU 42",
    WaterAuthority: "WaterAuthority 42",
    Plumber: "Carlie Reichel",
    Risk: "Risk Level 42",
    CertificateNo: 57176,
    SerialNo: "68882",
    Details: "leverage yellow",
    ApplicantAddress: "39107 Fadel Center",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "162.00",
    GPS: "476.00",
    Manufacturer: "Hermann, Greenfelder and Lockman",
    TankSize: 275.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hansen - Feest",
    SecondarySystemType: "Games",
    SecondaryManufacturer: "Bayer - Tillman",
    SecondaryTankSize: "538.00",
    UnitSecondary: "UnitSecondary 42",
    TertiarySystemType: "Home",
    TertiaryManufacturer: "Sporer - Gutmann",
    TertiaryTankSize: "312.00",
    UnitTertiary: "UnitTertiary 42",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 42",
    Contractor: "Hilda.Gibson8",
    RiskLevel: "RiskLevel 42",
    PermitType: "PermitType 42",
    ConstructionType: "ConstructionType 42",
    DebtorNo: 367,
    FileNo: 46705,
    Issued: new Date("2020-09-27T01:41:11.121Z"),
    IssuingOfficer: "Myah",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-07-23T13:08:16.358Z"),
    ApprovalOfficer: "Marilyne Nicolas",
    OutstandingFees: "518.00",
    OSFees: "574.00",
    ComplainceCertNo: 26717,
    ApprovalForBL: new Date("2020-12-11T20:06:08.934Z"),
    BLicenceNumber: 98980,
    SentToDoH: new Date("2020-11-02T02:24:18.868Z"),
    DoHApproval: "DoHApproval 42",
    DateDoHApproved: new Date("2020-09-04T10:16:52.558Z"),
    DoHApprovalNo: "DoHApprovalNo 42",
    SerialNumber: 84454,
    LastInspection: new Date("2020-03-05T08:41:49.030Z"),
    Frequency: 62136,
    NextInspection: new Date("2021-06-25T00:16:37.171Z"),
    LastService: new Date("2020-04-11T01:34:21.423Z"),
    LastDesludge: new Date("2020-03-16T07:07:12.274Z"),
    LastSample: new Date("2020-12-21T06:53:57.752Z"),
    NextService: new Date("2021-05-31T09:46:21.753Z"),
    NextDesludge: new Date("2021-12-12T23:00:17.930Z"),
    NextSample: new Date("2021-06-02T16:40:19.841Z"),
    Comments:
      "synthesizing the interface won't do anything, we need to quantify the bluetooth SMS driver!",
    Size: "314.00",
    DateInspected: new Date("2020-03-02T17:41:49.892Z"),
    InspectionType: "Representative",
    Officer: "Sabryna Wiza",
    DateInspectedMonth: "October",
    DueStatus: "system",
    Material: "Frozen",
    InspectionFrequency: 42916,
    ReferenceNumber: "77497",
    ContactName: "Frederique",
    Compliance: "feed",
    Reason:
      "I'll back up the neural AGP interface, that should protocol the EXE port!",
    RefNo: "4108",
    Description: "We need to quantify the open-source ADP card!",
    DateTarget: new Date("2020-10-28T10:48:47.133Z"),
    Source: "Kids",
    Category: "Small",
    Location: "Czech Republic",
    RequestedBy: "Foster",
    ContactDetails: "Sanford",
    DateRecorded: new Date("2021-01-26T09:52:24.876Z"),
    RecordedBy: "Leif Kulas",
    OrgStructure: "Fort",
    ActionOfficer: "Angelo Willms",
    Coordinator: "Dane Herzog",
    DateWork: new Date("2020-03-26T16:43:46.070Z"),
    EnquiryRefNo: "57070",
    Event_ReferenceNoAdditional: "90321",
    OnBehalfOf: "Optimization",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-11-17T03:33:02.311Z"),
    Type: "alarm",
    DateDue: new Date("2020-12-18T17:05:12.403Z"),
    DateRevoked: new Date("2021-01-27T05:01:11.773Z"),
    Noticee: "Hattie",
    CreatedDate: "2020-08-08T04:54:27.543Z",
    FeeType: "Investment Account",
    AmountIssued: "731.00",
    AmountOutstanding: 60953,
    CostCode: "75666-8324",
    InvoiceNumber: 8791,
    Name: "Davis - Abbott",
    SystemTypeClassification: "Solutions",
    SortIndex: 3975,
    Title: "National",
    Condition: "paradigms Administrator Books",
  },
  {
    ID: "43",
    PermitNumber: 20163,
    Applicant: "Ben Rau",
    Owner: "Elza Doyle III",
    Address: "Apt. 170",
    Suburb: "Texas",
    AssessmentNo: 31538,
    Status: "Complied",
    TypeOfApplication: "Books",
    FileNumber: 53752,
    SystemName: "driver",
    SystemType: "interface",
    Received: new Date("2021-01-26T16:12:43.845Z"),
    PTI: new Date("2021-01-26T13:06:57.911Z"),
    CTU: "CTU 43",
    WaterAuthority: "WaterAuthority 43",
    Plumber: "Daphney Kirlin",
    Risk: "Risk Level 43",
    CertificateNo: 20012,
    SerialNo: "89706",
    Details: "Practical Wooden Shoes",
    ApplicantAddress: "18180 Eldora Mission",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "845.00",
    GPS: "726.00",
    Manufacturer: "Larkin, Lebsack and Bruen",
    TankSize: 862.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Feeney, Heaney and Wisozk",
    SecondarySystemType: "Tools",
    SecondaryManufacturer: "Ziemann - Ebert",
    SecondaryTankSize: "858.00",
    UnitSecondary: "UnitSecondary 43",
    TertiarySystemType: "Music",
    TertiaryManufacturer: "Hegmann, Grady and Hegmann",
    TertiaryTankSize: "950.00",
    UnitTertiary: "UnitTertiary 43",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 43",
    Contractor: "Rae75",
    RiskLevel: "RiskLevel 43",
    PermitType: "PermitType 43",
    ConstructionType: "ConstructionType 43",
    DebtorNo: 54836,
    FileNo: 72637,
    Issued: new Date("2020-03-15T14:39:20.967Z"),
    IssuingOfficer: "Cordia",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-09-08T12:23:13.671Z"),
    ApprovalOfficer: "Miss Lambert O'Kon",
    OutstandingFees: "264.00",
    OSFees: "97.00",
    ComplainceCertNo: 69181,
    ApprovalForBL: new Date("2021-01-15T00:40:00.255Z"),
    BLicenceNumber: 90827,
    SentToDoH: new Date("2020-10-05T21:13:59.827Z"),
    DoHApproval: "DoHApproval 43",
    DateDoHApproved: new Date("2020-10-01T16:49:24.589Z"),
    DoHApprovalNo: "DoHApprovalNo 43",
    SerialNumber: 65051,
    LastInspection: new Date("2020-02-20T21:34:55.658Z"),
    Frequency: 30911,
    NextInspection: new Date("2021-11-20T08:29:29.008Z"),
    LastService: new Date("2020-11-14T18:47:51.780Z"),
    LastDesludge: new Date("2020-06-04T09:37:41.891Z"),
    LastSample: new Date("2020-04-07T12:29:23.576Z"),
    NextService: new Date("2021-11-15T05:59:27.701Z"),
    NextDesludge: new Date("2021-09-16T08:39:23.871Z"),
    NextSample: new Date("2021-03-03T08:39:46.603Z"),
    Comments:
      "I'll connect the wireless ADP firewall, that should application the XSS microchip!",
    Size: "211.00",
    DateInspected: new Date("2021-01-18T08:51:20.286Z"),
    InspectionType: "Analyst",
    Officer: "Mr. Tremayne Thompson",
    DateInspectedMonth: "June",
    DueStatus: "El Salvador Colon US Dollar",
    Material: "Cotton",
    InspectionFrequency: 48733,
    ReferenceNumber: "8479",
    ContactName: "Sheila",
    Compliance: "pixel",
    Reason: "We need to hack the haptic THX pixel!",
    RefNo: "41411",
    Description:
      "I'll copy the online CSS hard drive, that should interface the RAM bus!",
    DateTarget: new Date("2020-03-17T13:50:34.925Z"),
    Source: "Automotive",
    Category: "Rustic",
    Location: "Nepal",
    RequestedBy: "Bridgette",
    ContactDetails: "Schmeler",
    DateRecorded: new Date("2021-01-27T05:54:01.168Z"),
    RecordedBy: "Joesph Balistreri Sr.",
    OrgStructure: "data-warehouse",
    ActionOfficer: "Chad Ward",
    Coordinator: "Bryana Bartell",
    DateWork: new Date("2020-02-23T10:03:43.028Z"),
    EnquiryRefNo: "52679",
    Event_ReferenceNoAdditional: "75280",
    OnBehalfOf: "Solutions",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2020-02-11T03:35:40.389Z"),
    Type: "card",
    DateDue: new Date("2020-05-27T00:10:30.019Z"),
    DateRevoked: new Date("2021-01-26T11:20:45.754Z"),
    Noticee: "Abigail",
    CreatedDate: "2020-03-23T01:22:29.668Z",
    FeeType: "Checking Account",
    AmountIssued: "550.00",
    AmountOutstanding: 99389,
    CostCode: "88947-7906",
    InvoiceNumber: 6997,
    Name: "Wilkinson - Balistreri",
    SystemTypeClassification: "Iowa",
    SortIndex: 53748,
    Title: "Legacy",
    Condition: "District",
  },
  {
    ID: "44",
    PermitNumber: 73977,
    Applicant: "Margarette Cartwright",
    Owner: "Ms. Tyree Monahan",
    Address: "Suite 044",
    Suburb: "Missouri",
    AssessmentNo: 34575,
    Status: "Complied",
    TypeOfApplication: "Baby",
    FileNumber: 73909,
    SystemName: "sensor",
    SystemType: "sensor",
    Received: new Date("2021-01-26T10:39:57.914Z"),
    PTI: new Date("2021-01-26T11:13:00.752Z"),
    CTU: "CTU 44",
    WaterAuthority: "WaterAuthority 44",
    Plumber: "Darryl Prosacco",
    Risk: "Risk Level 44",
    CertificateNo: 22070,
    SerialNo: "95962-0862",
    Details: "Metal",
    ApplicantAddress: "221 White River",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "239.00",
    GPS: "369.00",
    Manufacturer: "Runte, Larkin and Conn",
    TankSize: 511.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Waelchi and Sons",
    SecondarySystemType: "Health",
    SecondaryManufacturer: "Schinner LLC",
    SecondaryTankSize: "496.00",
    UnitSecondary: "UnitSecondary 44",
    TertiarySystemType: "Shoes",
    TertiaryManufacturer: "Walsh - Stamm",
    TertiaryTankSize: "986.00",
    UnitTertiary: "UnitTertiary 44",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 44",
    Contractor: "Rhiannon93",
    RiskLevel: "RiskLevel 44",
    PermitType: "PermitType 44",
    ConstructionType: "ConstructionType 44",
    DebtorNo: 57349,
    FileNo: 69333,
    Issued: new Date("2020-05-03T21:24:28.676Z"),
    IssuingOfficer: "Teresa",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-02-06T04:17:06.021Z"),
    ApprovalOfficer: "Andreanne Shields",
    OutstandingFees: "142.00",
    OSFees: "636.00",
    ComplainceCertNo: 97302,
    ApprovalForBL: new Date("2020-05-22T23:06:52.676Z"),
    BLicenceNumber: 85063,
    SentToDoH: new Date("2020-12-26T10:34:27.781Z"),
    DoHApproval: "DoHApproval 44",
    DateDoHApproved: new Date("2020-05-09T18:21:05.962Z"),
    DoHApprovalNo: "DoHApprovalNo 44",
    SerialNumber: 62977,
    LastInspection: new Date("2021-01-20T12:42:36.540Z"),
    Frequency: 49815,
    NextInspection: new Date("2021-04-19T06:17:33.670Z"),
    LastService: new Date("2021-01-07T20:37:31.326Z"),
    LastDesludge: new Date("2020-09-02T20:16:30.894Z"),
    LastSample: new Date("2020-09-11T21:02:00.196Z"),
    NextService: new Date("2021-09-26T22:15:08.236Z"),
    NextDesludge: new Date("2021-08-31T09:56:05.043Z"),
    NextSample: new Date("2021-03-13T00:26:59.015Z"),
    Comments:
      "The HTTP capacitor is down, generate the solid state array so we can index the JSON monitor!",
    Size: "140.00",
    DateInspected: new Date("2020-11-11T06:16:00.980Z"),
    InspectionType: "Architect",
    Officer: "Alex Hand DDS",
    DateInspectedMonth: "January",
    DueStatus: "solid state",
    Material: "Metal",
    InspectionFrequency: 70832,
    ReferenceNumber: "81444",
    ContactName: "Maudie",
    Compliance: "bandwidth",
    Reason:
      "If we index the alarm, we can get to the PCI application through the primary XML pixel!",
    RefNo: "94777",
    Description:
      "You can't input the array without programming the cross-platform SDD program!",
    DateTarget: new Date("2020-03-26T20:26:05.916Z"),
    Source: "Home",
    Category: "Practical",
    Location: "Bouvet Island (Bouvetoya)",
    RequestedBy: "Beaulah",
    ContactDetails: "Pfannerstill",
    DateRecorded: new Date("2021-01-26T11:42:06.314Z"),
    RecordedBy: "Bradly Hyatt",
    OrgStructure: "Practical Steel Bike",
    ActionOfficer: "Jamie Grady II",
    Coordinator: "Jayda Pfeffer",
    DateWork: new Date("2020-05-19T01:00:15.085Z"),
    EnquiryRefNo: "44746",
    Event_ReferenceNoAdditional: "63960",
    OnBehalfOf: "Tactics",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2021-01-16T17:36:42.121Z"),
    Type: "matrix",
    DateDue: new Date("2020-02-19T08:59:09.668Z"),
    DateRevoked: new Date("2021-01-26T21:43:05.853Z"),
    Noticee: "Elissa",
    CreatedDate: "2020-11-10T14:19:33.029Z",
    FeeType: "Checking Account",
    AmountIssued: "556.00",
    AmountOutstanding: 18518,
    CostCode: "23532-3851",
    InvoiceNumber: 1862,
    Name: "Heller, Murazik and Johns",
    SystemTypeClassification: "Credit Card Account",
    SortIndex: 46763,
    Title: "Dynamic",
    Condition: "Refined Frozen Bike Practical functionalities",
  },
  {
    ID: "45",
    PermitNumber: 58849,
    Applicant: "Miss Zella Pacocha",
    Owner: "Orlando Ritchie DDS",
    Address: "Suite 072",
    Suburb: "Florida",
    AssessmentNo: 52157,
    Status: "Complied",
    TypeOfApplication: "Movies",
    FileNumber: 33312,
    SystemName: "port",
    SystemType: "bandwidth",
    Received: new Date("2021-01-26T11:26:10.723Z"),
    PTI: new Date("2021-01-26T17:13:07.495Z"),
    CTU: "CTU 45",
    WaterAuthority: "WaterAuthority 45",
    Plumber: "Brice Schmidt II",
    Risk: "Risk Level 45",
    CertificateNo: 6722,
    SerialNo: "12956-0451",
    Details: "Unbranded Soft Gloves CSS",
    ApplicantAddress: "7383 Kris Parkways",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "483.00",
    GPS: "234.00",
    Manufacturer: "Yundt - Kunze",
    TankSize: 852.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Dooley LLC",
    SecondarySystemType: "Baby",
    SecondaryManufacturer: "Simonis Group",
    SecondaryTankSize: "646.00",
    UnitSecondary: "UnitSecondary 45",
    TertiarySystemType: "Games",
    TertiaryManufacturer: "Schulist Inc",
    TertiaryTankSize: "306.00",
    UnitTertiary: "UnitTertiary 45",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 45",
    Contractor: "Weldon.Kshlerin",
    RiskLevel: "RiskLevel 45",
    PermitType: "PermitType 45",
    ConstructionType: "ConstructionType 45",
    DebtorNo: 16236,
    FileNo: 32475,
    Issued: new Date("2020-07-29T07:14:18.254Z"),
    IssuingOfficer: "Eveline",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-08-11T01:48:24.194Z"),
    ApprovalOfficer: "Iva Bradtke",
    OutstandingFees: "834.00",
    OSFees: "349.00",
    ComplainceCertNo: 34323,
    ApprovalForBL: new Date("2020-03-23T08:33:04.620Z"),
    BLicenceNumber: 43189,
    SentToDoH: new Date("2020-06-17T02:05:55.722Z"),
    DoHApproval: "DoHApproval 45",
    DateDoHApproved: new Date("2020-03-25T12:36:42.705Z"),
    DoHApprovalNo: "DoHApprovalNo 45",
    SerialNumber: 21160,
    LastInspection: new Date("2020-11-27T22:30:07.978Z"),
    Frequency: 83769,
    NextInspection: new Date("2021-08-18T07:11:11.870Z"),
    LastService: new Date("2020-06-22T14:24:37.801Z"),
    LastDesludge: new Date("2020-06-01T19:57:02.686Z"),
    LastSample: new Date("2020-11-06T16:18:30.119Z"),
    NextService: new Date("2021-02-13T02:13:07.733Z"),
    NextDesludge: new Date("2021-07-18T02:41:00.348Z"),
    NextSample: new Date("2021-08-05T05:33:31.577Z"),
    Comments:
      "If we reboot the port, we can get to the RAM system through the back-end TCP driver!",
    Size: "723.00",
    DateInspected: new Date("2020-01-28T11:07:38.648Z"),
    InspectionType: "Consultant",
    Officer: "Ottilie Jaskolski",
    DateInspectedMonth: "December",
    DueStatus: "Saudi Arabia",
    Material: "Concrete",
    InspectionFrequency: 84941,
    ReferenceNumber: "52165",
    ContactName: "Alvina",
    Compliance: "transmitter",
    Reason:
      "Try to hack the FTP firewall, maybe it will navigate the wireless microchip!",
    RefNo: "99757",
    Description:
      "Try to generate the RAM alarm, maybe it will override the mobile panel!",
    DateTarget: new Date("2020-06-24T01:04:44.666Z"),
    Source: "Games",
    Category: "Unbranded",
    Location: "Congo",
    RequestedBy: "Abdiel",
    ContactDetails: "Nienow",
    DateRecorded: new Date("2021-01-27T03:50:01.262Z"),
    RecordedBy: "Janet Tillman",
    OrgStructure: "Internal",
    ActionOfficer: "Rahsaan Koch",
    Coordinator: "Elvie Ledner",
    DateWork: new Date("2020-10-28T18:31:21.890Z"),
    EnquiryRefNo: "55329",
    Event_ReferenceNoAdditional: "1781",
    OnBehalfOf: "Quality",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-09-12T01:16:29.728Z"),
    Type: "feed",
    DateDue: new Date("2020-11-04T09:27:34.339Z"),
    DateRevoked: new Date("2021-01-27T04:28:02.380Z"),
    Noticee: "Kaley",
    CreatedDate: "2021-01-06T22:53:27.722Z",
    FeeType: "Money Market Account",
    AmountIssued: "12.00",
    AmountOutstanding: 81993,
    CostCode: "51899-5813",
    InvoiceNumber: 10166,
    Name: "Mayert, Dare and Bins",
    SystemTypeClassification: "communities",
    SortIndex: 67017,
    Title: "Corporate",
    Condition: "feed",
  },
  {
    ID: "46",
    PermitNumber: 89926,
    Applicant: "Kallie Beatty",
    Owner: "Roma Mann",
    Address: "Suite 287",
    Suburb: "South Carolina",
    AssessmentNo: 43626,
    Status: "Complied",
    TypeOfApplication: "Movies",
    FileNumber: 3794,
    SystemName: "circuit",
    SystemType: "sensor",
    Received: new Date("2021-01-27T06:00:33.079Z"),
    PTI: new Date("2021-01-26T11:14:19.416Z"),
    CTU: "CTU 46",
    WaterAuthority: "WaterAuthority 46",
    Plumber: "Curtis Lehner",
    Risk: "Risk Level 46",
    CertificateNo: 3807,
    SerialNo: "75816",
    Details: "heuristic software deposit",
    ApplicantAddress: "6655 Toy Way",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "898.00",
    GPS: "867.00",
    Manufacturer: "Hane and Sons",
    TankSize: 295.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Lueilwitz Group",
    SecondarySystemType: "Toys",
    SecondaryManufacturer: "Bauch Group",
    SecondaryTankSize: "313.00",
    UnitSecondary: "UnitSecondary 46",
    TertiarySystemType: "Sports",
    TertiaryManufacturer: "Rodriguez Inc",
    TertiaryTankSize: "695.00",
    UnitTertiary: "UnitTertiary 46",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 46",
    Contractor: "Raphaelle_Brown",
    RiskLevel: "RiskLevel 46",
    PermitType: "PermitType 46",
    ConstructionType: "ConstructionType 46",
    DebtorNo: 56277,
    FileNo: 93,
    Issued: new Date("2020-03-01T08:39:33.330Z"),
    IssuingOfficer: "Jaquan",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-04-15T17:29:26.495Z"),
    ApprovalOfficer: "Dr. Willard Waelchi",
    OutstandingFees: "68.00",
    OSFees: "119.00",
    ComplainceCertNo: 14058,
    ApprovalForBL: new Date("2020-09-14T23:35:21.135Z"),
    BLicenceNumber: 7357,
    SentToDoH: new Date("2020-04-15T19:06:17.056Z"),
    DoHApproval: "DoHApproval 46",
    DateDoHApproved: new Date("2020-07-12T18:42:34.942Z"),
    DoHApprovalNo: "DoHApprovalNo 46",
    SerialNumber: 75073,
    LastInspection: new Date("2020-06-26T06:34:31.809Z"),
    Frequency: 36103,
    NextInspection: new Date("2021-08-25T19:23:44.244Z"),
    LastService: new Date("2020-10-21T20:47:56.338Z"),
    LastDesludge: new Date("2020-06-18T22:10:09.784Z"),
    LastSample: new Date("2020-07-17T22:25:39.999Z"),
    NextService: new Date("2021-03-28T15:28:43.867Z"),
    NextDesludge: new Date("2021-12-03T10:34:00.671Z"),
    NextSample: new Date("2021-02-17T11:17:44.269Z"),
    Comments:
      "Try to index the SQL capacitor, maybe it will input the auxiliary bandwidth!",
    Size: "155.00",
    DateInspected: new Date("2020-04-04T21:22:18.662Z"),
    InspectionType: "Officer",
    Officer: "Madyson Doyle",
    DateInspectedMonth: "November",
    DueStatus: "Up-sized",
    Material: "Steel",
    InspectionFrequency: 53567,
    ReferenceNumber: "30281",
    ContactName: "Leanne",
    Compliance: "alarm",
    Reason:
      "I'll parse the digital COM protocol, that should port the HTTP program!",
    RefNo: "53595",
    Description:
      "The SCSI microchip is down, reboot the online microchip so we can index the EXE panel!",
    DateTarget: new Date("2020-07-31T20:38:59.083Z"),
    Source: "Games",
    Category: "Refined",
    Location: "Zambia",
    RequestedBy: "Mallie",
    ContactDetails: "Stark",
    DateRecorded: new Date("2021-01-26T08:09:06.291Z"),
    RecordedBy: "Emiliano Nikolaus",
    OrgStructure: "virtual",
    ActionOfficer: "Amalia Beer",
    Coordinator: "Darryl Mante",
    DateWork: new Date("2020-11-12T10:28:01.151Z"),
    EnquiryRefNo: "82895",
    Event_ReferenceNoAdditional: "50317",
    OnBehalfOf: "Data",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    DateServed: new Date("2020-12-14T18:43:26.645Z"),
    Type: "sensor",
    DateDue: new Date("2020-09-12T08:03:52.304Z"),
    DateRevoked: new Date("2021-01-27T04:56:57.945Z"),
    Noticee: "Lottie",
    CreatedDate: "2020-05-09T22:01:34.634Z",
    FeeType: "Credit Card Account",
    AmountIssued: "30.00",
    AmountOutstanding: 92661,
    CostCode: "29638-3887",
    InvoiceNumber: 26150,
    Name: "Farrell - Beer",
    SystemTypeClassification: "payment",
    SortIndex: 77769,
    Title: "Senior",
    Condition: "Granite Toys",
  },
  {
    ID: "47",
    PermitNumber: 40203,
    Applicant: "Jessica Hodkiewicz",
    Owner: "Randall Friesen",
    Address: "Suite 030",
    Suburb: "Nebraska",
    AssessmentNo: 4419,
    Status: "Complied",
    TypeOfApplication: "Baby",
    FileNumber: 30904,
    SystemName: "feed",
    SystemType: "interface",
    Received: new Date("2021-01-27T06:39:21.928Z"),
    PTI: new Date("2021-01-27T05:16:23.346Z"),
    CTU: "CTU 47",
    WaterAuthority: "WaterAuthority 47",
    Plumber: "Scarlett Bosco I",
    Risk: "Risk Level 47",
    CertificateNo: 49214,
    SerialNo: "14968",
    Details: "microchip Cape Verde Escudo",
    ApplicantAddress: "625 Courtney Plain",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "451.00",
    GPS: "311.00",
    Manufacturer: "Ziemann, Kling and Waters",
    TankSize: 960.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Hauck - Medhurst",
    SecondarySystemType: "Computers",
    SecondaryManufacturer: "Langworth, Walker and Rutherford",
    SecondaryTankSize: "230.00",
    UnitSecondary: "UnitSecondary 47",
    TertiarySystemType: "Outdoors",
    TertiaryManufacturer: "Balistreri - Hackett",
    TertiaryTankSize: "140.00",
    UnitTertiary: "UnitTertiary 47",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 47",
    Contractor: "Darrin.Miller68",
    RiskLevel: "RiskLevel 47",
    PermitType: "PermitType 47",
    ConstructionType: "ConstructionType 47",
    DebtorNo: 89950,
    FileNo: 26803,
    Issued: new Date("2020-07-15T14:36:10.128Z"),
    IssuingOfficer: "Hilario",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-09-13T20:54:08.894Z"),
    ApprovalOfficer: "Jakayla Lindgren",
    OutstandingFees: "528.00",
    OSFees: "948.00",
    ComplainceCertNo: 30526,
    ApprovalForBL: new Date("2020-05-27T03:38:17.213Z"),
    BLicenceNumber: 36254,
    SentToDoH: new Date("2021-01-20T09:31:34.746Z"),
    DoHApproval: "DoHApproval 47",
    DateDoHApproved: new Date("2020-04-27T07:36:40.214Z"),
    DoHApprovalNo: "DoHApprovalNo 47",
    SerialNumber: 40406,
    LastInspection: new Date("2020-03-09T09:04:36.435Z"),
    Frequency: 24394,
    NextInspection: new Date("2021-06-14T19:38:28.760Z"),
    LastService: new Date("2020-02-27T07:19:00.277Z"),
    LastDesludge: new Date("2020-03-25T12:26:39.627Z"),
    LastSample: new Date("2020-03-31T05:02:55.094Z"),
    NextService: new Date("2021-10-16T03:16:08.774Z"),
    NextDesludge: new Date("2021-08-05T13:31:09.693Z"),
    NextSample: new Date("2021-03-25T18:21:32.787Z"),
    Comments:
      "Try to override the SMS feed, maybe it will quantify the online panel!",
    Size: "604.00",
    DateInspected: new Date("2020-04-02T14:27:45.771Z"),
    InspectionType: "Agent",
    Officer: "Mac Heller",
    DateInspectedMonth: "August",
    DueStatus: "synthesize",
    Material: "Granite",
    InspectionFrequency: 60112,
    ReferenceNumber: "82041",
    ContactName: "Kelley",
    Compliance: "card",
    Reason:
      "I'll generate the digital XML microchip, that should program the AGP transmitter!",
    RefNo: "35049",
    Description:
      "If we input the interface, we can get to the SAS card through the optical SCSI monitor!",
    DateTarget: new Date("2020-05-24T18:53:42.292Z"),
    Source: "Tools",
    Category: "Awesome",
    Location: "Faroe Islands",
    RequestedBy: "Michael",
    ContactDetails: "Leuschke",
    DateRecorded: new Date("2021-01-26T19:46:21.405Z"),
    RecordedBy: "Hilbert Moore Jr.",
    OrgStructure: "Fantastic Concrete Computer",
    ActionOfficer: "Janie Goldner",
    Coordinator: "Jordan Auer",
    DateWork: new Date("2021-01-14T21:18:38.091Z"),
    EnquiryRefNo: "96372",
    Event_ReferenceNoAdditional: "67175",
    OnBehalfOf: "Identity",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    DateServed: new Date("2021-01-02T23:07:01.559Z"),
    Type: "application",
    DateDue: new Date("2020-12-08T02:59:34.877Z"),
    DateRevoked: new Date("2021-01-26T07:25:07.050Z"),
    Noticee: "Paxton",
    CreatedDate: "2020-12-29T12:56:52.091Z",
    FeeType: "Auto Loan Account",
    AmountIssued: "174.00",
    AmountOutstanding: 96531,
    CostCode: "15023-6220",
    InvoiceNumber: 62888,
    Name: "Heaney, Bogisich and Nicolas",
    SystemTypeClassification: "withdrawal",
    SortIndex: 6796,
    Title: "Senior",
    Condition: "protocol",
  },
  {
    ID: "48",
    PermitNumber: 16417,
    Applicant: "Dejon Harvey",
    Owner: "Caleigh Hand",
    Address: "Suite 166",
    Suburb: "Wisconsin",
    AssessmentNo: 2625,
    Status: "Complied",
    TypeOfApplication: "Electronics",
    FileNumber: 13645,
    SystemName: "bus",
    SystemType: "application",
    Received: new Date("2021-01-26T10:30:01.416Z"),
    PTI: new Date("2021-01-26T09:31:33.679Z"),
    CTU: "CTU 48",
    WaterAuthority: "WaterAuthority 48",
    Plumber: "Dr. Brendon Zulauf",
    Risk: "Risk Level 48",
    CertificateNo: 43855,
    SerialNo: "11693-3305",
    Details: "bypass",
    ApplicantAddress: "90099 Grimes Mills",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "431.00",
    GPS: "925.00",
    Manufacturer: "Cummerata Inc",
    TankSize: 471.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Fahey, Cummings and Becker",
    SecondarySystemType: "Electronics",
    SecondaryManufacturer: "Lueilwitz - Ruecker",
    SecondaryTankSize: "771.00",
    UnitSecondary: "UnitSecondary 48",
    TertiarySystemType: "Computers",
    TertiaryManufacturer: "Hickle LLC",
    TertiaryTankSize: "802.00",
    UnitTertiary: "UnitTertiary 48",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 48",
    Contractor: "Ashton_Romaguera",
    RiskLevel: "RiskLevel 48",
    PermitType: "PermitType 48",
    ConstructionType: "ConstructionType 48",
    DebtorNo: 95196,
    FileNo: 32149,
    Issued: new Date("2020-04-03T05:42:19.092Z"),
    IssuingOfficer: "Ernestina",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-11-03T02:38:33.165Z"),
    ApprovalOfficer: "Stephen West",
    OutstandingFees: "478.00",
    OSFees: "921.00",
    ComplainceCertNo: 80552,
    ApprovalForBL: new Date("2020-04-06T19:19:47.914Z"),
    BLicenceNumber: 5986,
    SentToDoH: new Date("2020-05-05T21:21:18.830Z"),
    DoHApproval: "DoHApproval 48",
    DateDoHApproved: new Date("2020-04-01T04:07:17.136Z"),
    DoHApprovalNo: "DoHApprovalNo 48",
    SerialNumber: 64131,
    LastInspection: new Date("2020-10-21T17:11:46.648Z"),
    Frequency: 57955,
    NextInspection: new Date("2021-12-02T05:44:56.476Z"),
    LastService: new Date("2020-12-14T19:24:38.354Z"),
    LastDesludge: new Date("2020-12-17T23:33:51.721Z"),
    LastSample: new Date("2020-10-06T22:06:03.234Z"),
    NextService: new Date("2021-03-25T03:35:14.428Z"),
    NextDesludge: new Date("2021-06-02T00:49:02.022Z"),
    NextSample: new Date("2021-11-01T19:30:02.857Z"),
    Comments: "We need to input the mobile USB microchip!",
    Size: "680.00",
    DateInspected: new Date("2020-03-25T13:09:35.496Z"),
    InspectionType: "Agent",
    Officer: "Jannie Farrell",
    DateInspectedMonth: "September",
    DueStatus: "fuchsia",
    Material: "Steel",
    InspectionFrequency: 19301,
    ReferenceNumber: "57511",
    ContactName: "Brook",
    Compliance: "capacitor",
    Reason:
      "If we back up the transmitter, we can get to the XML driver through the optical IB application!",
    RefNo: "25090",
    Description:
      "Use the back-end SSL hard drive, then you can copy the primary capacitor!",
    DateTarget: new Date("2020-08-29T01:18:02.679Z"),
    Source: "Clothing",
    Category: "Sleek",
    Location: "Saint Vincent and the Grenadines",
    RequestedBy: "Bennie",
    ContactDetails: "Lynch",
    DateRecorded: new Date("2021-01-26T18:29:51.435Z"),
    RecordedBy: "Dayton Borer",
    OrgStructure: "capacity",
    ActionOfficer: "Quincy Purdy",
    Coordinator: "Dewayne Lubowitz",
    DateWork: new Date("2020-03-06T20:12:18.527Z"),
    EnquiryRefNo: "97129",
    Event_ReferenceNoAdditional: "29388",
    OnBehalfOf: "Communications",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-03-28T05:42:47.870Z"),
    Type: "microchip",
    DateDue: new Date("2021-01-09T11:40:30.074Z"),
    DateRevoked: new Date("2021-01-27T02:43:52.016Z"),
    Noticee: "Thaddeus",
    CreatedDate: "2020-12-12T10:51:26.760Z",
    FeeType: "Checking Account",
    AmountIssued: "410.00",
    AmountOutstanding: 7694,
    CostCode: "48852-0481",
    InvoiceNumber: 81976,
    Name: "Wisozk - Shields",
    SystemTypeClassification: "Roads",
    SortIndex: 20002,
    Title: "Investor",
    Condition: "Home killer",
  },
  {
    ID: "49",
    PermitNumber: 77309,
    Applicant: "Penelope Greenfelder",
    Owner: "Tanya Collins",
    Address: "Suite 553",
    Suburb: "Maryland",
    AssessmentNo: 16635,
    Status: "Complied",
    TypeOfApplication: "Movies",
    FileNumber: 38252,
    SystemName: "bandwidth",
    SystemType: "system",
    Received: new Date("2021-01-26T12:28:18.140Z"),
    PTI: new Date("2021-01-27T06:18:04.424Z"),
    CTU: "CTU 49",
    WaterAuthority: "WaterAuthority 49",
    Plumber: "Hester Morissette",
    Risk: "Risk Level 49",
    CertificateNo: 97187,
    SerialNo: "16951-1109",
    Details: "De-engineered driver",
    ApplicantAddress: "360 Terry Coves",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "763.00",
    GPS: "414.00",
    Manufacturer: "Doyle LLC",
    TankSize: 521.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Mayert - Brown",
    SecondarySystemType: "Sports",
    SecondaryManufacturer: "Jakubowski - Harvey",
    SecondaryTankSize: "400.00",
    UnitSecondary: "UnitSecondary 49",
    TertiarySystemType: "Health",
    TertiaryManufacturer: "Frami - Ritchie",
    TertiaryTankSize: "574.00",
    UnitTertiary: "UnitTertiary 49",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 49",
    Contractor: "Nathen90",
    RiskLevel: "RiskLevel 49",
    PermitType: "PermitType 49",
    ConstructionType: "ConstructionType 49",
    DebtorNo: 26015,
    FileNo: 75666,
    Issued: new Date("2020-09-17T03:57:51.252Z"),
    IssuingOfficer: "Jessica",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-04-14T14:35:16.908Z"),
    ApprovalOfficer: "Brant Grant",
    OutstandingFees: "773.00",
    OSFees: "589.00",
    ComplainceCertNo: 69336,
    ApprovalForBL: new Date("2020-09-12T17:29:23.215Z"),
    BLicenceNumber: 81879,
    SentToDoH: new Date("2020-10-04T09:57:41.086Z"),
    DoHApproval: "DoHApproval 49",
    DateDoHApproved: new Date("2020-07-25T09:40:08.718Z"),
    DoHApprovalNo: "DoHApprovalNo 49",
    SerialNumber: 95130,
    LastInspection: new Date("2020-05-28T20:11:41.800Z"),
    Frequency: 91914,
    NextInspection: new Date("2021-09-14T09:39:07.915Z"),
    LastService: new Date("2020-05-31T03:55:00.924Z"),
    LastDesludge: new Date("2020-04-16T08:21:19.993Z"),
    LastSample: new Date("2020-02-24T07:02:22.113Z"),
    NextService: new Date("2021-08-31T10:06:04.361Z"),
    NextDesludge: new Date("2021-03-16T07:40:41.982Z"),
    NextSample: new Date("2021-07-05T19:24:21.614Z"),
    Comments:
      "If we back up the driver, we can get to the TCP matrix through the multi-byte USB array!",
    Size: "942.00",
    DateInspected: new Date("2020-03-19T05:41:57.719Z"),
    InspectionType: "Technician",
    Officer: "Syble Bauch",
    DateInspectedMonth: "September",
    DueStatus: "PCI",
    Material: "Concrete",
    InspectionFrequency: 76112,
    ReferenceNumber: "98719",
    ContactName: "Gus",
    Compliance: "alarm",
    Reason:
      "parsing the system won't do anything, we need to compress the open-source TCP bandwidth!",
    RefNo: "58305",
    Description:
      "Use the multi-byte JBOD pixel, then you can connect the wireless port!",
    DateTarget: new Date("2020-12-09T13:25:15.529Z"),
    Source: "Baby",
    Category: "Gorgeous",
    Location: "Lithuania",
    RequestedBy: "Casper",
    ContactDetails: "Rath",
    DateRecorded: new Date("2021-01-26T13:21:42.924Z"),
    RecordedBy: "Alexander Larkin",
    OrgStructure: "orchestration",
    ActionOfficer: "Madonna Reichert",
    Coordinator: "Eldora Stiedemann",
    DateWork: new Date("2020-12-01T23:18:43.948Z"),
    EnquiryRefNo: "25807",
    Event_ReferenceNoAdditional: "16298",
    OnBehalfOf: "Solutions",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-08-18T02:58:27.759Z"),
    Type: "protocol",
    DateDue: new Date("2020-12-23T17:00:04.476Z"),
    DateRevoked: new Date("2021-01-26T19:57:58.567Z"),
    Noticee: "Lea",
    CreatedDate: "2020-09-22T05:07:53.801Z",
    FeeType: "Auto Loan Account",
    AmountIssued: "537.00",
    AmountOutstanding: 29584,
    CostCode: "26619-0862",
    InvoiceNumber: 97042,
    Name: "Ryan LLC",
    SystemTypeClassification: "wireless",
    SortIndex: 10264,
    Title: "Global",
    Condition: "Fantastic Cotton Shirt hacking",
  },
  {
    ID: "3263507",
    PermitNumber: 64389,
    Applicant: "Bennett Stehr",
    Owner: "Gavin Crona",
    Address: "Apt. 035",
    Suburb: "Wyoming",
    AssessmentNo: 86991,
    Status: "Complied",
    TypeOfApplication: "Automotive",
    FileNumber: 28628,
    SystemName: "matrix",
    SystemType: "circuit",
    Received: new Date("2021-01-26T21:58:06.204Z"),
    PTI: new Date("2021-01-26T10:01:52.945Z"),
    CTU: "CTU 50",
    WaterAuthority: "WaterAuthority 50",
    Plumber: "Waino Runolfsson",
    Risk: "Risk Level 50",
    CertificateNo: 4970,
    SerialNo: "76267-3287",
    Details: "haptic backing up motivating",
    ApplicantAddress: "70979 McGlynn Shores",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "590.00",
    GPS: "361.00",
    Manufacturer: "Sanford - Harber",
    TankSize: 969.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Jaskolski LLC",
    SecondarySystemType: "Health",
    SecondaryManufacturer: "Borer Inc",
    SecondaryTankSize: "18.00",
    UnitSecondary: "UnitSecondary 50",
    TertiarySystemType: "Sports",
    TertiaryManufacturer: "Cassin, Schamberger and Torp",
    TertiaryTankSize: "834.00",
    UnitTertiary: "UnitTertiary 50",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 50",
    Contractor: "Joe90",
    RiskLevel: "RiskLevel 50",
    PermitType: "PermitType 50",
    ConstructionType: "ConstructionType 50",
    DebtorNo: 60990,
    FileNo: 72669,
    Issued: new Date("2020-11-19T05:31:38.105Z"),
    IssuingOfficer: "Dejon",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-12-30T12:35:37.839Z"),
    ApprovalOfficer: "Caterina McClure",
    OutstandingFees: "523.00",
    OSFees: "31.00",
    ComplainceCertNo: 34666,
    ApprovalForBL: new Date("2020-09-19T17:34:30.991Z"),
    BLicenceNumber: 76500,
    SentToDoH: new Date("2020-04-16T04:57:10.452Z"),
    DoHApproval: "DoHApproval 50",
    DateDoHApproved: new Date("2020-06-14T13:53:17.309Z"),
    DoHApprovalNo: "DoHApprovalNo 50",
    SerialNumber: 58104,
    LastInspection: new Date("2020-09-10T18:08:27.589Z"),
    Frequency: 46622,
    NextInspection: new Date("2021-11-25T01:30:13.917Z"),
    LastService: new Date("2020-07-04T16:49:30.216Z"),
    LastDesludge: new Date("2020-10-14T11:31:46.613Z"),
    LastSample: new Date("2020-03-23T10:27:33.541Z"),
    NextService: new Date("2021-09-13T04:40:24.282Z"),
    NextDesludge: new Date("2021-02-16T14:38:14.626Z"),
    NextSample: new Date("2021-10-28T20:52:27.948Z"),
    Comments: "We need to navigate the open-source RAM microchip!",
    Size: "881.00",
    DateInspected: new Date("2020-10-20T10:47:53.764Z"),
    InspectionType: "Executive",
    Officer: "Rachael Gibson",
    DateInspectedMonth: "August",
    DueStatus: "Berkshire",
    Material: "Plastic",
    InspectionFrequency: 13703,
    ReferenceNumber: "7726",
    ContactName: "Mossie",
    Compliance: "circuit",
    Reason:
      "The THX sensor is down, quantify the primary bus so we can quantify the AI transmitter!",
    RefNo: "86017",
    Description:
      "generating the card won't do anything, we need to calculate the neural SMS firewall!",
    DateTarget: new Date("2020-08-01T12:00:52.329Z"),
    Source: "Sports",
    Category: "Fantastic",
    Location: "Mali",
    RequestedBy: "Jamey",
    ContactDetails: "Brown",
    DateRecorded: new Date("2021-01-26T22:38:46.176Z"),
    RecordedBy: "Alf Pfeffer",
    OrgStructure: "Steel",
    ActionOfficer: "Horace Quitzon",
    Coordinator: "Avis Stiedemann",
    DateWork: new Date("2021-01-03T19:44:15.211Z"),
    EnquiryRefNo: "15165",
    Event_ReferenceNoAdditional: "21924",
    OnBehalfOf: "Interactions",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-04-26T01:00:09.491Z"),
    Type: "bandwidth",
    DateDue: new Date("2020-08-18T04:16:46.924Z"),
    DateRevoked: new Date("2021-01-27T00:36:35.406Z"),
    Noticee: "Dawson",
    CreatedDate: "2020-09-07T12:43:41.448Z",
    FeeType: "Credit Card Account",
    AmountIssued: "72.00",
    AmountOutstanding: 53361,
    CostCode: "12834",
    InvoiceNumber: 66596,
    Name: "Gleichner and Sons",
    SystemTypeClassification: "Tasty Rubber Soap",
    SortIndex: 25543,
    Title: "Corporate",
    Condition: "Coves array Architect",
  },
  {
    ID: "3311192",
    PermitNumber: 35278,
    Applicant: "Mara Kuphal",
    Owner: "Mr. Kaylie Metz",
    Address: "Suite 345",
    Suburb: "Arkansas",
    AssessmentNo: 11420,
    Status: "Complied",
    TypeOfApplication: "Grocery",
    FileNumber: 87142,
    SystemName: "card",
    SystemType: "matrix",
    Received: new Date("2021-01-26T09:07:32.218Z"),
    PTI: new Date("2021-01-26T16:21:25.246Z"),
    CTU: "CTU 51",
    WaterAuthority: "WaterAuthority 51",
    Plumber: "Audrey Sawayn",
    Risk: "Risk Level 51",
    CertificateNo: 75769,
    SerialNo: "41324-8547",
    Details: "overriding Future Pizza",
    ApplicantAddress: "620 Stiedemann Skyway",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "869.00",
    GPS: "586.00",
    Manufacturer: "Schulist - Turcotte",
    TankSize: 81.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Morar - Connelly",
    SecondarySystemType: "Industrial",
    SecondaryManufacturer: "Koelpin Inc",
    SecondaryTankSize: "971.00",
    UnitSecondary: "UnitSecondary 51",
    TertiarySystemType: "Shoes",
    TertiaryManufacturer: "Little, Bashirian and Grimes",
    TertiaryTankSize: "949.00",
    UnitTertiary: "UnitTertiary 51",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 51",
    Contractor: "Karianne45",
    RiskLevel: "RiskLevel 51",
    PermitType: "PermitType 51",
    ConstructionType: "ConstructionType 51",
    DebtorNo: 28356,
    FileNo: 17291,
    Issued: new Date("2020-02-19T10:51:46.470Z"),
    IssuingOfficer: "Emely",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-05-30T08:37:59.693Z"),
    ApprovalOfficer: "Beth Veum",
    OutstandingFees: "766.00",
    OSFees: "472.00",
    ComplainceCertNo: 3593,
    ApprovalForBL: new Date("2020-09-20T16:26:20.957Z"),
    BLicenceNumber: 97744,
    SentToDoH: new Date("2020-03-18T03:51:13.296Z"),
    DoHApproval: "DoHApproval 51",
    DateDoHApproved: new Date("2020-04-26T10:19:42.892Z"),
    DoHApprovalNo: "DoHApprovalNo 51",
    SerialNumber: 96097,
    LastInspection: new Date("2020-12-03T02:42:51.700Z"),
    Frequency: 46741,
    NextInspection: new Date("2021-10-28T08:13:59.475Z"),
    LastService: new Date("2020-08-18T02:44:15.247Z"),
    LastDesludge: new Date("2020-08-08T19:07:30.582Z"),
    LastSample: new Date("2020-03-20T10:00:31.171Z"),
    NextService: new Date("2022-01-10T07:40:40.220Z"),
    NextDesludge: new Date("2021-07-08T09:59:02.440Z"),
    NextSample: new Date("2021-06-01T19:27:53.280Z"),
    Comments:
      "Use the multi-byte SQL bus, then you can hack the primary system!",
    Size: "684.00",
    DateInspected: new Date("2020-09-13T11:00:29.535Z"),
    InspectionType: "Associate",
    Officer: "Grayce Hand",
    DateInspectedMonth: "June",
    DueStatus: "Ergonomic Plastic Cheese",
    Material: "Rubber",
    InspectionFrequency: 22372,
    ReferenceNumber: "25252",
    ContactName: "Taryn",
    Compliance: "matrix",
    Reason:
      "Try to parse the SMTP sensor, maybe it will connect the online monitor!",
    RefNo: "18851",
    Description:
      "transmitting the bandwidth won't do anything, we need to reboot the back-end SMTP pixel!",
    DateTarget: new Date("2020-03-29T13:15:34.665Z"),
    Source: "Industrial",
    Category: "Unbranded",
    Location: "Central African Republic",
    RequestedBy: "Catalina",
    ContactDetails: "Tromp",
    DateRecorded: new Date("2021-01-26T16:45:26.622Z"),
    RecordedBy: "Bernadette O'Kon",
    OrgStructure: "Re-engineered",
    ActionOfficer: "Vern Morissette",
    Coordinator: "Tony Wilkinson",
    DateWork: new Date("2020-10-11T09:59:31.693Z"),
    EnquiryRefNo: "95923",
    Event_ReferenceNoAdditional: "84264",
    OnBehalfOf: "Metrics",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-10-10T04:27:21.826Z"),
    Type: "microchip",
    DateDue: new Date("2020-03-11T00:04:38.118Z"),
    DateRevoked: new Date("2021-01-26T06:56:15.878Z"),
    Noticee: "Rebecca",
    CreatedDate: "2020-08-22T08:03:10.649Z",
    FeeType: "Credit Card Account",
    AmountIssued: "83.00",
    AmountOutstanding: 10384,
    CostCode: "82811",
    InvoiceNumber: 5904,
    Name: "Russel and Sons",
    SystemTypeClassification: "niches",
    SortIndex: 68140,
    Title: "Investor",
    Condition: "Cheese Beauty Corporate",
  },
  {
    ID: "3227870",
    PermitNumber: 72951,
    Applicant: "Rhea Pollich",
    Owner: "Leonora Schinner",
    Address: "Apt. 643",
    Suburb: "Rhode Island",
    AssessmentNo: 7885,
    Status: "Complied",
    TypeOfApplication: "Tools",
    FileNumber: 13532,
    SystemName: "alarm",
    SystemType: "port",
    Received: new Date("2021-01-26T22:25:46.549Z"),
    PTI: new Date("2021-01-26T21:38:22.879Z"),
    CTU: "CTU 52",
    WaterAuthority: "WaterAuthority 52",
    Plumber: "Miles Kihn",
    Risk: "Risk Level 52",
    CertificateNo: 81449,
    SerialNo: "25604-1659",
    Details: "payment Technician TCP",
    ApplicantAddress: "432 Wiza Tunnel",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "183.00",
    GPS: "348.00",
    Manufacturer: "Flatley and Sons",
    TankSize: 101.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Blick - Oberbrunner",
    SecondarySystemType: "Baby",
    SecondaryManufacturer: "Dare - Berge",
    SecondaryTankSize: "267.00",
    UnitSecondary: "UnitSecondary 52",
    TertiarySystemType: "Books",
    TertiaryManufacturer: "Carroll - Schaefer",
    TertiaryTankSize: "905.00",
    UnitTertiary: "UnitTertiary 52",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 52",
    Contractor: "Darrick_Wehner",
    RiskLevel: "RiskLevel 52",
    PermitType: "PermitType 52",
    ConstructionType: "ConstructionType 52",
    DebtorNo: 71640,
    FileNo: 13630,
    Issued: new Date("2020-05-29T09:54:29.381Z"),
    IssuingOfficer: "Calista",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-03-07T18:17:31.192Z"),
    ApprovalOfficer: "Efrain Bartoletti",
    OutstandingFees: "27.00",
    OSFees: "980.00",
    ComplainceCertNo: 58387,
    ApprovalForBL: new Date("2020-08-14T00:36:08.844Z"),
    BLicenceNumber: 58793,
    SentToDoH: new Date("2020-10-23T17:22:20.313Z"),
    DoHApproval: "DoHApproval 52",
    DateDoHApproved: new Date("2020-03-12T16:30:01.261Z"),
    DoHApprovalNo: "DoHApprovalNo 52",
    SerialNumber: 9939,
    LastInspection: new Date("2020-11-16T00:57:56.510Z"),
    Frequency: 38218,
    NextInspection: new Date("2021-05-24T04:39:03.360Z"),
    LastService: new Date("2020-03-17T10:06:03.974Z"),
    LastDesludge: new Date("2020-12-10T23:06:49.496Z"),
    LastSample: new Date("2020-08-14T11:15:50.701Z"),
    NextService: new Date("2021-04-05T22:40:50.197Z"),
    NextDesludge: new Date("2021-09-29T09:29:58.241Z"),
    NextSample: new Date("2021-10-12T19:59:41.829Z"),
    Comments:
      "The JSON microchip is down, parse the primary bus so we can compress the THX driver!",
    Size: "775.00",
    DateInspected: new Date("2020-09-16T21:22:49.367Z"),
    InspectionType: "Technician",
    Officer: "Jackie Casper I",
    DateInspectedMonth: "January",
    DueStatus: "Fresh",
    Material: "Cotton",
    InspectionFrequency: 96456,
    ReferenceNumber: "36988",
    ContactName: "Rubye",
    Compliance: "hard drive",
    Reason:
      "You can't transmit the array without overriding the back-end XML circuit!",
    RefNo: "88325",
    Description:
      "overriding the pixel won't do anything, we need to synthesize the digital IB system!",
    DateTarget: new Date("2020-10-15T10:50:39.357Z"),
    Source: "Tools",
    Category: "Refined",
    Location: "Iran",
    RequestedBy: "Glenda",
    ContactDetails: "Jacobson",
    DateRecorded: new Date("2021-01-26T14:23:34.362Z"),
    RecordedBy: "Russell Rosenbaum",
    OrgStructure: "Tuna",
    ActionOfficer: "Stephan Pfeffer",
    Coordinator: "Denis Eichmann",
    DateWork: new Date("2020-02-15T18:33:00.797Z"),
    EnquiryRefNo: "22656",
    Event_ReferenceNoAdditional: "98652",
    OnBehalfOf: "Applications",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-12-03T06:01:59.750Z"),
    Type: "application",
    DateDue: new Date("2020-06-02T12:39:45.452Z"),
    DateRevoked: new Date("2021-01-27T02:58:25.624Z"),
    Noticee: "Oda",
    CreatedDate: "2020-06-22T22:27:28.260Z",
    FeeType: "Personal Loan Account",
    AmountIssued: "528.00",
    AmountOutstanding: 17340,
    CostCode: "90885",
    InvoiceNumber: 21794,
    Name: "Gerlach and Sons",
    SystemTypeClassification: "Intranet",
    SortIndex: 62657,
    Title: "Central",
    Condition: "Station",
  },
  {
    ID: "53",
    PermitNumber: 3897,
    Applicant: "Frederic Kuvalis",
    Owner: "Howell Yost",
    Address: "Apt. 744",
    Suburb: "Idaho",
    AssessmentNo: 84534,
    Status: "Complied",
    TypeOfApplication: "Automotive",
    FileNumber: 33021,
    SystemName: "sensor",
    SystemType: "feed",
    Received: new Date("2021-01-26T21:07:31.301Z"),
    PTI: new Date("2021-01-27T05:04:07.788Z"),
    CTU: "CTU 53",
    WaterAuthority: "WaterAuthority 53",
    Plumber: "Jordi Fahey",
    Risk: "Risk Level 53",
    CertificateNo: 33577,
    SerialNo: "24534",
    Details: "Communications",
    ApplicantAddress: "90525 Hand Courts",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "848.00",
    GPS: "316.00",
    Manufacturer: "Waelchi - Spencer",
    TankSize: 724.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Okuneva, Wisoky and McLaughlin",
    SecondarySystemType: "Automotive",
    SecondaryManufacturer: "Stokes, Mayert and Koss",
    SecondaryTankSize: "816.00",
    UnitSecondary: "UnitSecondary 53",
    TertiarySystemType: "Movies",
    TertiaryManufacturer: "Nitzsche, Daugherty and Ferry",
    TertiaryTankSize: "801.00",
    UnitTertiary: "UnitTertiary 53",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 53",
    Contractor: "Chase.Schowalter90",
    RiskLevel: "RiskLevel 53",
    PermitType: "PermitType 53",
    ConstructionType: "ConstructionType 53",
    DebtorNo: 25410,
    FileNo: 60010,
    Issued: new Date("2020-04-18T16:53:41.772Z"),
    IssuingOfficer: "Linnea",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-03-31T19:45:50.195Z"),
    ApprovalOfficer: "Matilde Kutch",
    OutstandingFees: "401.00",
    OSFees: "728.00",
    ComplainceCertNo: 31879,
    ApprovalForBL: new Date("2020-12-03T01:29:21.834Z"),
    BLicenceNumber: 94699,
    SentToDoH: new Date("2020-06-07T18:41:55.586Z"),
    DoHApproval: "DoHApproval 53",
    DateDoHApproved: new Date("2020-04-08T12:57:12.877Z"),
    DoHApprovalNo: "DoHApprovalNo 53",
    SerialNumber: 49681,
    LastInspection: new Date("2020-03-24T17:03:12.181Z"),
    Frequency: 93932,
    NextInspection: new Date("2021-06-20T10:28:29.720Z"),
    LastService: new Date("2020-08-22T14:23:13.548Z"),
    LastDesludge: new Date("2020-10-24T03:11:25.441Z"),
    LastSample: new Date("2020-12-23T07:24:07.531Z"),
    NextService: new Date("2021-07-13T11:59:38.850Z"),
    NextDesludge: new Date("2021-06-01T10:44:36.096Z"),
    NextSample: new Date("2021-09-09T23:04:57.257Z"),
    Comments:
      "backing up the card won't do anything, we need to calculate the back-end RSS port!",
    Size: "581.00",
    DateInspected: new Date("2020-11-05T02:12:38.781Z"),
    InspectionType: "Designer",
    Officer: "June Lockman III",
    DateInspectedMonth: "July",
    DueStatus: "Bedfordshire",
    Material: "Soft",
    InspectionFrequency: 13784,
    ReferenceNumber: "51872",
    ContactName: "Caleigh",
    Compliance: "panel",
    Reason:
      "I'll back up the mobile THX interface, that should card the GB circuit!",
    RefNo: "99112",
    Description:
      "navigating the program won't do anything, we need to connect the back-end GB feed!",
    DateTarget: new Date("2020-02-15T20:19:57.244Z"),
    Source: "Baby",
    Category: "Rustic",
    Location: "Western Sahara",
    RequestedBy: "Melissa",
    ContactDetails: "Schaefer",
    DateRecorded: new Date("2021-01-26T15:19:54.340Z"),
    RecordedBy: "Caesar Brekke",
    OrgStructure: "Saint Barthelemy",
    ActionOfficer: "Pierre Marks",
    Coordinator: "Tod Schaden DDS",
    DateWork: new Date("2021-01-24T05:41:48.641Z"),
    EnquiryRefNo: "61754",
    Event_ReferenceNoAdditional: "72900",
    OnBehalfOf: "Markets",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-11-20T01:19:57.800Z"),
    Type: "alarm",
    DateDue: new Date("2020-04-28T01:03:49.674Z"),
    DateRevoked: new Date("2021-01-26T20:54:02.447Z"),
    Noticee: "Jonathon",
    CreatedDate: "2020-01-28T12:36:31.901Z",
    FeeType: "Savings Account",
    AmountIssued: "244.00",
    AmountOutstanding: 55502,
    CostCode: "33635",
    InvoiceNumber: 80327,
    Name: "White, Baumbach and Jacobi",
    SystemTypeClassification: "zero administration",
    SortIndex: 26070,
    Title: "Senior",
    Condition: "calculating",
  },
  {
    ID: "54",
    PermitNumber: 56289,
    Applicant: "Rachel Goldner",
    Owner: "Cindy Braun V",
    Address: "Suite 906",
    Suburb: "Maine",
    AssessmentNo: 67693,
    Status: "Complied",
    TypeOfApplication: "Industrial",
    FileNumber: 23375,
    SystemName: "bus",
    SystemType: "port",
    Received: new Date("2021-01-27T03:11:26.365Z"),
    PTI: new Date("2021-01-26T15:56:40.338Z"),
    CTU: "CTU 54",
    WaterAuthority: "WaterAuthority 54",
    Plumber: "Miss Ashtyn Morissette",
    Risk: "Risk Level 54",
    CertificateNo: 34004,
    SerialNo: "06089-4596",
    Details: "Avon",
    ApplicantAddress: "230 Reinger Avenue",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "32.00",
    GPS: "702.00",
    Manufacturer: "Braun, Stanton and Ward",
    TankSize: 190.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Emmerich, D'Amore and Hirthe",
    SecondarySystemType: "Games",
    SecondaryManufacturer: "Parisian LLC",
    SecondaryTankSize: "96.00",
    UnitSecondary: "UnitSecondary 54",
    TertiarySystemType: "Tools",
    TertiaryManufacturer: "Gislason, Marquardt and Wiegand",
    TertiaryTankSize: "265.00",
    UnitTertiary: "UnitTertiary 54",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 54",
    Contractor: "Mittie_Franecki",
    RiskLevel: "RiskLevel 54",
    PermitType: "PermitType 54",
    ConstructionType: "ConstructionType 54",
    DebtorNo: 74332,
    FileNo: 67559,
    Issued: new Date("2020-10-03T19:19:07.530Z"),
    IssuingOfficer: "Ozella",
    RespAuthApproved: true,
    ApprovalToUse: new Date("2020-11-02T21:11:05.976Z"),
    ApprovalOfficer: "Marielle Smitham",
    OutstandingFees: "325.00",
    OSFees: "713.00",
    ComplainceCertNo: 75403,
    ApprovalForBL: new Date("2021-01-26T14:27:09.880Z"),
    BLicenceNumber: 13336,
    SentToDoH: new Date("2020-09-09T23:07:49.380Z"),
    DoHApproval: "DoHApproval 54",
    DateDoHApproved: new Date("2021-01-05T12:43:23.475Z"),
    DoHApprovalNo: "DoHApprovalNo 54",
    SerialNumber: 6376,
    LastInspection: new Date("2020-03-10T22:52:53.480Z"),
    Frequency: 50122,
    NextInspection: new Date("2021-05-26T18:15:21.901Z"),
    LastService: new Date("2020-03-23T15:05:30.246Z"),
    LastDesludge: new Date("2020-03-10T14:44:14.124Z"),
    LastSample: new Date("2020-05-03T21:08:39.287Z"),
    NextService: new Date("2021-04-17T19:13:23.380Z"),
    NextDesludge: new Date("2021-04-26T02:13:55.958Z"),
    NextSample: new Date("2021-05-31T19:37:28.844Z"),
    Comments:
      "If we calculate the system, we can get to the JSON microchip through the digital AI interface!",
    Size: "79.00",
    DateInspected: new Date("2020-08-06T04:21:30.913Z"),
    InspectionType: "Strategist",
    Officer: "Lenora Schmitt V",
    DateInspectedMonth: "March",
    DueStatus: "exuding",
    Material: "Granite",
    InspectionFrequency: 56235,
    ReferenceNumber: "49559",
    ContactName: "Brooke",
    Compliance: "pixel",
    Reason: "Use the neural TCP bus, then you can synthesize the 1080p card!",
    RefNo: "74524",
    Description: "We need to hack the redundant JSON hard drive!",
    DateTarget: new Date("2020-10-30T05:48:13.959Z"),
    Source: "Toys",
    Category: "Tasty",
    Location: "Luxembourg",
    RequestedBy: "Conor",
    ContactDetails: "Graham",
    DateRecorded: new Date("2021-01-27T00:04:01.444Z"),
    RecordedBy: "Heloise Streich",
    OrgStructure: "Bolivar Fuerte",
    ActionOfficer: "Maxine Daniel",
    Coordinator: "Gillian Hettinger",
    DateWork: new Date("2020-03-27T16:39:25.076Z"),
    EnquiryRefNo: "65996",
    Event_ReferenceNoAdditional: "10511",
    OnBehalfOf: "Research",
    Flag_ITSupport: false,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: false,
    DateServed: new Date("2020-12-29T18:57:08.836Z"),
    Type: "bus",
    DateDue: new Date("2020-10-20T02:56:18.110Z"),
    DateRevoked: new Date("2021-01-26T22:43:48.487Z"),
    Noticee: "Bell",
    CreatedDate: "2021-01-01T00:28:09.521Z",
    FeeType: "Money Market Account",
    AmountIssued: "467.00",
    AmountOutstanding: 39704,
    CostCode: "64990-3142",
    InvoiceNumber: 98979,
    Name: "Wuckert - Daugherty",
    SystemTypeClassification: "Creative",
    SortIndex: 76599,
    Title: "Central",
    Condition: "Usability United States of America Beauty",
  },
  {
    ID: "55",
    PermitNumber: 7941,
    Applicant: "Fredrick O'Conner Jr.",
    Owner: "Kaylin Kertzmann",
    Address: "Suite 436",
    Suburb: "Rhode Island",
    AssessmentNo: 93832,
    Status: "Complied",
    TypeOfApplication: "Clothing",
    FileNumber: 41989,
    SystemName: "sensor",
    SystemType: "monitor",
    Received: new Date("2021-01-26T20:57:09.348Z"),
    PTI: new Date("2021-01-27T02:57:43.041Z"),
    CTU: "CTU 55",
    WaterAuthority: "WaterAuthority 55",
    Plumber: "Novella Padberg",
    Risk: "Risk Level 55",
    CertificateNo: 37771,
    SerialNo: "17069",
    Details: "generating",
    ApplicantAddress: "61704 Schmitt Ville",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "572.00",
    GPS: "435.00",
    Manufacturer: "Rodriguez, Rice and Kassulke",
    TankSize: 826.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Beatty and Sons",
    SecondarySystemType: "Automotive",
    SecondaryManufacturer: "Kilback and Sons",
    SecondaryTankSize: "37.00",
    UnitSecondary: "UnitSecondary 55",
    TertiarySystemType: "Outdoors",
    TertiaryManufacturer: "Corwin, Kutch and Spencer",
    TertiaryTankSize: "344.00",
    UnitTertiary: "UnitTertiary 55",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 55",
    Contractor: "Jessy.Cronin41",
    RiskLevel: "RiskLevel 55",
    PermitType: "PermitType 55",
    ConstructionType: "ConstructionType 55",
    DebtorNo: 45683,
    FileNo: 5243,
    Issued: new Date("2020-05-25T04:51:07.726Z"),
    IssuingOfficer: "Adonis",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2021-01-24T13:33:09.028Z"),
    ApprovalOfficer: "Alan McKenzie",
    OutstandingFees: "525.00",
    OSFees: "828.00",
    ComplainceCertNo: 28349,
    ApprovalForBL: new Date("2020-02-07T13:51:12.093Z"),
    BLicenceNumber: 39362,
    SentToDoH: new Date("2020-06-08T18:54:18.874Z"),
    DoHApproval: "DoHApproval 55",
    DateDoHApproved: new Date("2020-11-20T14:07:00.696Z"),
    DoHApprovalNo: "DoHApprovalNo 55",
    SerialNumber: 72161,
    LastInspection: new Date("2020-05-11T10:06:42.369Z"),
    Frequency: 14049,
    NextInspection: new Date("2021-09-15T15:03:12.307Z"),
    LastService: new Date("2020-12-06T16:32:58.541Z"),
    LastDesludge: new Date("2020-08-12T00:42:07.267Z"),
    LastSample: new Date("2021-01-17T04:23:40.768Z"),
    NextService: new Date("2021-12-30T17:05:01.963Z"),
    NextDesludge: new Date("2022-01-10T20:12:44.353Z"),
    NextSample: new Date("2021-02-12T12:20:28.995Z"),
    Comments:
      "compressing the card won't do anything, we need to quantify the bluetooth SCSI interface!",
    Size: "149.00",
    DateInspected: new Date("2020-08-05T13:58:13.196Z"),
    InspectionType: "Associate",
    Officer: "Thurman Leannon",
    DateInspectedMonth: "May",
    DueStatus: "lime",
    Material: "Cotton",
    InspectionFrequency: 72179,
    ReferenceNumber: "2081",
    ContactName: "Rick",
    Compliance: "application",
    Reason:
      "Try to connect the XML array, maybe it will transmit the redundant bandwidth!",
    RefNo: "8147",
    Description:
      "synthesizing the matrix won't do anything, we need to navigate the multi-byte JBOD sensor!",
    DateTarget: new Date("2020-11-27T08:26:22.706Z"),
    Source: "Baby",
    Category: "Rustic",
    Location: "Congo",
    RequestedBy: "Pauline",
    ContactDetails: "MacGyver",
    DateRecorded: new Date("2021-01-26T17:08:45.972Z"),
    RecordedBy: "Lillie Graham",
    OrgStructure: "drive",
    ActionOfficer: "Lisa Wyman DVM",
    Coordinator: "Mona Zulauf",
    DateWork: new Date("2020-02-17T14:49:40.754Z"),
    EnquiryRefNo: "88834",
    Event_ReferenceNoAdditional: "42350",
    OnBehalfOf: "Infrastructure",
    Flag_ITSupport: true,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-05-28T21:13:39.734Z"),
    Type: "driver",
    DateDue: new Date("2021-01-07T15:10:52.346Z"),
    DateRevoked: new Date("2021-01-27T01:24:39.030Z"),
    Noticee: "Emerson",
    CreatedDate: "2021-01-18T13:15:40.310Z",
    FeeType: "Money Market Account",
    AmountIssued: "437.00",
    AmountOutstanding: 94269,
    CostCode: "94659",
    InvoiceNumber: 52882,
    Name: "Cartwright - Cummerata",
    SystemTypeClassification: "Investment Account",
    SortIndex: 96004,
    Title: "Senior",
    Condition:
      "Bermudian Dollar (customarily known as Bermuda Dollar) Industrial Rial Omani",
  },
  {
    ID: "56",
    PermitNumber: 48910,
    Applicant: "Angeline Rogahn",
    Owner: "Priscilla Little",
    Address: "Apt. 620",
    Suburb: "Ohio",
    AssessmentNo: 49189,
    Status: "Complied",
    TypeOfApplication: "Shoes",
    FileNumber: 21951,
    SystemName: "program",
    SystemType: "microchip",
    Received: new Date("2021-01-26T15:43:20.715Z"),
    PTI: new Date("2021-01-27T06:07:42.352Z"),
    CTU: "CTU 56",
    WaterAuthority: "WaterAuthority 56",
    Plumber: "Shad Murphy",
    Risk: "Risk Level 56",
    CertificateNo: 11103,
    SerialNo: "00468-5197",
    Details: "responsive Cambridgeshire Principal",
    ApplicantAddress: "8631 Andres Village",
    ApplicantContact: "No Preferred Contact",
    AllotmentSize: "24.00",
    GPS: "699.00",
    Manufacturer: "Leannon - Buckridge",
    TankSize: 263.0,
    UnitPrimary: "litres",
    PrimarySystemTypeClassification: "Gusikowski - Grady",
    SecondarySystemType: "Music",
    SecondaryManufacturer: "Davis, Bins and Raynor",
    SecondaryTankSize: "354.00",
    UnitSecondary: "UnitSecondary 56",
    TertiarySystemType: "Books",
    TertiaryManufacturer: "Mertz Inc",
    TertiaryTankSize: "418.00",
    UnitTertiary: "UnitTertiary 56",
    TertiarySystemTypeClassification: "TertiarySystemTypeClassification 56",
    Contractor: "Claudine.Buckridge60",
    RiskLevel: "RiskLevel 56",
    PermitType: "PermitType 56",
    ConstructionType: "ConstructionType 56",
    DebtorNo: 5353,
    FileNo: 78979,
    Issued: new Date("2020-08-18T04:18:30.768Z"),
    IssuingOfficer: "Amely",
    RespAuthApproved: false,
    ApprovalToUse: new Date("2020-06-02T10:46:30.127Z"),
    ApprovalOfficer: "Jazmin McClure",
    OutstandingFees: "639.00",
    OSFees: "620.00",
    ComplainceCertNo: 50297,
    ApprovalForBL: new Date("2020-07-31T00:09:30.338Z"),
    BLicenceNumber: 56274,
    SentToDoH: new Date("2020-07-08T18:16:03.148Z"),
    DoHApproval: "DoHApproval 56",
    DateDoHApproved: new Date("2020-12-27T09:44:06.629Z"),
    DoHApprovalNo: "DoHApprovalNo 56",
    SerialNumber: 24177,
    LastInspection: new Date("2020-12-19T04:53:52.387Z"),
    Frequency: 56573,
    NextInspection: new Date("2021-05-23T14:20:25.038Z"),
    LastService: new Date("2020-03-29T15:51:31.418Z"),
    LastDesludge: new Date("2020-07-25T17:48:05.289Z"),
    LastSample: new Date("2020-11-20T17:35:13.564Z"),
    NextService: new Date("2022-01-23T17:20:26.484Z"),
    NextDesludge: new Date("2021-02-10T18:43:55.048Z"),
    NextSample: new Date("2021-11-15T17:44:33.158Z"),
    Comments: "We need to calculate the auxiliary SDD bandwidth!",
    Size: "782.00",
    DateInspected: new Date("2020-12-27T14:04:32.523Z"),
    InspectionType: "Specialist",
    Officer: "Althea Dietrich",
    DateInspectedMonth: "April",
    DueStatus: "overriding",
    Material: "Wooden",
    InspectionFrequency: 69365,
    ReferenceNumber: "72839",
    ContactName: "Dorian",
    Compliance: "microchip",
    Reason:
      "parsing the application won't do anything, we need to transmit the online SMS feed!",
    RefNo: "77404",
    Description:
      "I'll navigate the redundant SAS capacitor, that should application the FTP card!",
    DateTarget: new Date("2020-08-01T09:14:23.807Z"),
    Source: "Automotive",
    Category: "Generic",
    Location: "Bhutan",
    RequestedBy: "Ron",
    ContactDetails: "Hayes",
    DateRecorded: new Date("2021-01-27T01:12:03.685Z"),
    RecordedBy: "Judge Howell",
    OrgStructure: "multi-byte",
    ActionOfficer: "Delia Russel",
    Coordinator: "Alta Wiza",
    DateWork: new Date("2020-08-07T02:05:51.174Z"),
    EnquiryRefNo: "36697",
    Event_ReferenceNoAdditional: "65214",
    OnBehalfOf: "Creative",
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: false,
    Flag_VIP: true,
    DateServed: new Date("2020-02-18T00:56:21.366Z"),
    Type: "array",
    DateDue: new Date("2020-12-29T23:02:19.088Z"),
    DateRevoked: new Date("2021-01-26T22:57:36.914Z"),
    Noticee: "Talon",
    CreatedDate: "2020-11-22T07:49:42.589Z",
    FeeType: "Auto Loan Account",
    AmountIssued: "917.00",
    AmountOutstanding: 34499,
    CostCode: "68408",
    InvoiceNumber: 60701,
    Name: "McGlynn - Dickinson",
    SystemTypeClassification: "Chicken",
    SortIndex: 50629,
    Title: "District",
    Condition: "back-end Saint Vincent and the Grenadines calculating",
  },
];

export const mockWasteWaterContact = [
  {
    ID: "3173054",
    DisplayName: "Hamish Martin",
    WorkPhone: "653-198",
    Mobile: "196-260-8553",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
    Email: "hasmish@openoffice.com.au",
  },
  {
    ID: "3173058",
    Type: "Applicant",
    DisplayName: "Lena Stojanovski",
    WorkPhone: "(640) 254-957",
    Mobile: "1-540-062-1600",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
    Email: "lena@openoffice.com.au",
  },
];
