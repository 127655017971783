import { ImportNoticeOfSaleDialog } from "@app/products/property/changes-of-ownership/notice-of-sales/components/dialogs/import-notice-of-sale/components/form-elements/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ImportNoticeOfSaleButton = () => {
  const [showImportNoticeOfSaleDialog, setShowImportNoticeOfSaleDialog] =
    useState<boolean>(false);

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Notice_Of_Sale_Import}
      >
        <CCNavButton
          title="Import notice of sale"
          onClick={() => setShowImportNoticeOfSaleDialog(true)}
        />
      </PrivilegeWrapper>
      {showImportNoticeOfSaleDialog && (
        <ImportNoticeOfSaleDialog
          onClose={() => {
            setShowImportNoticeOfSaleDialog(false);
          }}
        />
      )}
    </>
  );
};
