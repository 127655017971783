export interface IViewAttachments {
  ID: number;
  RecordedDate: Date;
  RecordedBy: string;
  Title: string;
  FileName: string;
  FileNumber: string;
  Category: string;
  Tag: string;
  Status: string;
  Size: string;
  RecordType: string;
  WeblinkTitle: string;
  IsImage: string;
  OpenURL: string;
  NavigateURL: string;
  FileFolderNumber: string;
  SortOrder: string;
}

export interface Svc_ChangeSort {
  IDs: number[];
  RecordType: string;
  MoveForward: boolean;
}

export enum eSortOrder {
  NONE = "NONE",
  MOVE_UP = "MOVE_UP",
  MOVE_DOWN = "MOVE_DOWN",
}
