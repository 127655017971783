import {
  DTO_Supplementary_Assessment_Levy,
  DTO_Supplementary_Assessment_RatingPeriod,
} from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAssessmentByLevy =
  nameOfFactory<DTO_Supplementary_Assessment_Levy>();
const nameOfAssessmentByRatingPeriod =
  nameOfFactory<DTO_Supplementary_Assessment_RatingPeriod>();

export const colAssessmentByLevy: IColumnFields[] = [
  {
    field: nameOfAssessmentByLevy("LevyName"),
    title: "Levy Name",
  },
  {
    field: nameOfAssessmentByLevy("LevyCategory"),
    title: "Levy Category",
  },
  {
    field: nameOfAssessmentByLevy("PercentageofLevy"),
    title: "Percentage of Levy",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfAssessmentByLevy("QuantityAmount"),
    title: "Quantity Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("NumberofMinimums"),
    title: "Number of Minimums",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("BaseAmount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("RateByValuationAmount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("IncrementToMinimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("ReductionToMaximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("AdjustmentForCapping"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("TaxAmount"),
    title: "Tax Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("RebateAmount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("NettAmount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("TaxIncluded"),
    title: "Tax Included",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("PostponedValue"),
    title: "Postponed sValue",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByLevy("OldActiveFromDate"),
    title: "Old Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByLevy("NewActiveFromDate"),
    title: "New Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByLevy("HasLevyChanged"),
    title: "Has Levy Changed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];

export const colAssessmentByRatingPeriod: IColumnFields[] = [
  {
    field: nameOfAssessmentByRatingPeriod("RatingPeriodName"),
    title: "Rating Period Name",
  },
  {
    field: nameOfAssessmentByRatingPeriod("CalculateAdjustments"),
    title: "Calculate Adjustments",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAssessmentByRatingPeriod("NewDateofValuation"),
    title: "New Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByRatingPeriod("NewIssueDate"),
    title: "New Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByRatingPeriod("NewEffectiveDate"),
    title: "New Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByRatingPeriod("OldDateofValuation"),
    title: "Old Date of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByRatingPeriod("OldIssueDate"),
    title: "Old Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByRatingPeriod("OldEffectiveDate"),
    title: "Old Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentByRatingPeriod("BaseAmount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("RateByValuationAmount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("IncrementToMinimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("ReductionToMaximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("AdjustmentForCapping"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("TaxAmount"),
    title: "Tax Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("RebateAmount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("NettAmount"),
    title: "Nett Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("TaxIncluded"),
    title: "Tax Included",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("PostponedValue"),
    title: "Postponed Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("DiscountTotal1"),
    title: "Discount Total 1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("DiscountTotal2"),
    title: "Discount Total 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("CompliancePenalty"),
    title: "Compliance Penalty",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("ComplianceInterest"),
    title: "Compliance Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentByRatingPeriod("Id"),
    title: "ID",
    style: formatStyleUnRelevant,
  },
];
