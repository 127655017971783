import TooltipWrapper from "@components/cc-tooltip/components/_index";
import { Tooltip, TooltipPosition } from "@progress/kendo-react-tooltip";
import React from "react";
import "./_index.scss";

export interface ICCTooltipProps {
  type?: "info" | "validator" | "custom";
  content?: string;
  customTemplate?: React.ReactElement;
  position?: TooltipPosition;
  className?: string;
  openDelay?: number;
  children?: React.ReactElement;
  contentClassName?: string;
  clickToOpen?: boolean;
  classNameWrapper?: string;
}

export const CCTooltip = ({
  type = "info",
  children,
  content = "This field is required.",
  customTemplate,
  position = "top",
  className,
  classNameWrapper,
  openDelay,
  contentClassName = "cc-tooltip-html-content",
  clickToOpen = false,
}: ICCTooltipProps) => {
  const tooltip = React.useRef<any>(null);
  if (type === "info" || type === "validator")
    return (
      <Tooltip
        position={position}
        className={
          type === "validator"
            ? "cc-tooltip-validator"
            : "cc-tooltip-information"
        }
        openDelay={openDelay}
        content={(props) => {
          if (type === "validator") {
            return (
              <>
                {/*
                @TODO Change logic when apply error message in field
                <div className="cc-validator-result">Validation Result</div> */}
                {props.title}
              </>
            );
          } else if (type === "info") {
            return <>{props.title}</>;
          }
        }}
        anchorElement="target"
        showCallout={false}
      >
        {type === "validator" ? (
          <div
            className="cc-tooltip-validator-icon fas fa-star-of-life"
            title={content}
          />
        ) : (
          <div
            className="cc-icon-label-button fa fa-info-circle text-accent"
            title={content}
          />
        )}
      </Tooltip>
    );

  return (
    <>
      <Tooltip
        ref={tooltip}
        parentTitle={true}
        position={position}
        className={className}
        openDelay={openDelay}
        content={(props) => {
          return (
            <div
              className={`${contentClassName} ${
                clickToOpen ? "cc-tooltip-enabled-scroll" : ""
              }`}
            >
              {customTemplate}
              {props.title}
            </div>
          );
        }}
        anchorElement="target"
        showCallout={false}
      ></Tooltip>
      {clickToOpen ? (
        <TooltipWrapper childRef={tooltip}>
          <div
            onClick={(event) => {
              tooltip.current && tooltip.current.handleMouseOver(event);
            }}
            title={content}
          >
            {children}
          </div>
        </TooltipWrapper>
      ) : (
        <div
          onMouseOver={(event) =>
            tooltip.current && tooltip.current.handleMouseOver(event)
          }
          onMouseOut={(event) =>
            tooltip.current && tooltip.current.handleMouseOut(event)
          }
          title={content}
          className={classNameWrapper}
        >
          {children}
        </div>
      )}
    </>
  );
};
