import { NoticeForm } from "@app/core/notices/[id]/components/child-screens/general/_index";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewManageNotice = observer(() => {
  const isNew = useIsNew();
  const {
    isLoading,
    responseLoadError,
    notices,
    reloadNotices,
    onSubmit,
    isDisableSaveButton,
    isLoadingSuperAdmin,
  } = useNoticesStore();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Loading isLoading={isLoading || isLoadingSuperAdmin} isFullScreen />
      <FormNavigation title="Notice" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => reloadNotices(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormTitle title={"New Notice"} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={isDisableSaveButton}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {notices && <NoticeForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
