import { BuildingPoolsSpasForm } from "@app/products/building/pools-or-spas/[id]/components/child-screens/general/_index";
import { useBuildingPoolOrSpasStore } from "@app/products/building/pools-or-spas/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import React from "react";

export const NewBuildingPoolsSpas = () => {
  const { isLoading } = useBuildingPoolOrSpasStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <div className="cc-new-building-pools-spas">
        <FormNavigation title={"Pool/Spa"} />
        <FormTitle title={"New Building Pools/Spas"} />
        <CCManagePageActionBar
          leftActions={[
            <CCNavButton
              title={"Workflow"}
              type="sub-workflow"
              className="cc-workflow-button"
            ></CCNavButton>,
            <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
            <CCNavButton title={"Add"} type="sub">
              <AddActionButton />
              <CCNavButton title={"Action"} />
              <CCNavButton title={"Additional address"} />
              <CCNavButton title={"Contact"} />
              <CCNavButton title={"Advertising"} />
              <CCNavButton title={"Appeal"} />
              <CCNavButton title={"Attachment"} />
              <CCNavButton title={"Comment"} />
              <CCNavButton title={"Communication"} />
              <CCNavButton title={"Complaint"} />
              <CCNavButton title={"Document"} />
              <CCNavButton title={"Fee"} />
              <CCNavButton title={"Inspection"} />
              <CCNavButton title={"Notice"} />
              <CCNavButton title={"Prosecution"} />
            </CCNavButton>,

            <CCNavButton title={"View"} type="sub">
              <CCNavButton title={"Documents"} />
              <CCNavButton title={"Journal"} />
              <CCNavButton title={"Project"} />
            </CCNavButton>,
            <CCNavButton title={"Print"} />,
          ]}
        />

        <div className="cc-manage-container">
          <div className="cc-manage-left">
            <CCAppNotification />
            <div className="cc-manage-form-body">
              <CCGeneralPanel component={<BuildingPoolsSpasForm />} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
