import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const AnimalComplaintsBookmark = {
  getBookmarkDisplayName(selectedRow: EventView) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Animals ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: EventView) {
    return getDisplayTextWithDashes([
      selectedRow?.Description ? selectedRow?.Description : "",
    ]);
  },
};
