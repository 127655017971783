import { PPRContactsTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/contact/_index";
import { PPRDetailTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/_index";
import { PPRHistoryTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/history/_index";
import { PPRViewOnMapTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/view-on-map/_index";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { NewPPRApplicationButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/new-application/_index";
import { PPRActionBarNavDropdown } from "@app/products/town-planning/ppr/components/action-bar/nav-dropdown/_index";
import { colPprAllApplicationsByLocation } from "@app/products/town-planning/ppr/permit-referrals/all/by-location/config";
import { TOWN_PLANNING_PPR_REGISTER_ALL_LIST_VIEW_URL } from "@app/products/town-planning/ppr/permit-referrals/all/register/contant";
import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { PermitReferralCommunicationButton } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/communication/_index";
import { DeletePPRButton } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/delete/_index";
import { PermitReferralMailMergeButton } from "@app/products/town-planning/ppr/permit-referrals/components/action-bar/buttons/mail-merge/_index";
import { PSARUpdateStatDaysButton } from "@app/products/town-planning/ppr/psa-referrals/components/action-bar/button/update-stat-days/_index";
import { pprRoute } from "@app/products/town-planning/ppr/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { getBookmarkDetail, getBookmarkDisplayName } from "../util";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";

const nameOf = nameOfFactory<PPRApplication>();
export default () => {
  useCCListViewActionBar({
    title: pprRoute.name,
    leftComponents: [<PPRActionBarNavDropdown />],
    centerComponents: [
      <NewPPRApplicationButton title="New" />,
      <PermitReferralMailMergeButton />,
      <PermitReferralCommunicationButton />,
      <PSARUpdateStatDaysButton />,
      <DeletePPRButton />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={TOWN_PLANNING_PPR_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_PPRApplication}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PPRDetailTab /> },
      { title: "Map", component: <PPRViewOnMapTab /> },
      {
        title: "Contacts",
        component: <PPRContactsTab />,
      },
      {
        title: "History",
        component: <PPRHistoryTab />,
      },
    ],
  });
  return (
    <CCProductListView
      columnFields={colPprAllApplicationsByLocation}
      dataUrl={TOWN_PLANNING_PPR_REGISTER_ALL_LIST_VIEW_URL}
      state={{
        group: [
          { field: nameOf("DotOffice"), dir: "asc" },
          { field: nameOf("Locality"), dir: "asc" },
          { field: nameOf("DeclaredArterial"), dir: "asc" },
        ],
        sort: [
          { field: nameOf("DotOffice"), dir: "asc" },
          { field: nameOf("Locality"), dir: "asc" },
          { field: nameOf("DeclaredArterial"), dir: "asc" },
          { field: nameOf("ID"), dir: "desc" },
        ],
      }}
      primaryField={nameOf("ID")}
      itemPerPage={100}
      pageSizes={[5, 10, 20, 50, 100, 200, "All"]}
    />
  );
};
