import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const fieldTRIMContainerInheritParentAccessControl = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_ContainerInheritParentAccessControl
  );
  const fieldTRIMDocumentInheritParentAccessControl = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_DocumentInheritParentAccessControl
  );

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {fieldTRIMContainerInheritParentAccessControl && (
            <SwitchGlobalSettings
              data={fieldTRIMContainerInheritParentAccessControl}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldTRIMDocumentInheritParentAccessControl && (
            <SwitchGlobalSettings
              data={fieldTRIMDocumentInheritParentAccessControl}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>
      </section>
    </>
  );
});
