import { getTabTableCount } from "@app/core/tab-table/api";
import { TabTableEventType } from "@app/core/tab-table/constant";
import {
  DictionaryTabTableType,
  GetTabTableCountRequest,
  TabTableType,
} from "@app/core/tab-table/model";
import { useTabTableStore } from "@app/core/tab-table/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ICCPanelProps } from "@components/cc-panel/_index";
import {
  CCPanelsBar,
  ICCPanelsBarProps,
} from "@components/cc-panels-bar/_index";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "react-use";

export interface ITabProps extends ICCPanelProps {
  tabType?: TabTableType;
}
export interface ITabTableProps
  extends Omit<ICCPanelsBarProps, "initialPanels"> {
  initialPanels: ITabProps[];
  id?: number;
  recordType: RECORDTYPE;
  options?: Omit<GetTabTableCountRequest, "id" | "recordType" | "relatedTypes">;
  disabled?: boolean;
}
export const TabTable = ({
  initialPanels,
  id,
  recordType,
  options,
  disabled = false,
  ...others
}: ITabTableProps) => {
  const [totals, setTotals] = useState<DictionaryTabTableType>({});
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();
  const { resetStore, setIsDisabled } = useTabTableStore();
  const lastedParams = useRef<string>("");
  const relatedTypes = useMemo(
    () =>
      initialPanels
        .map((panel) => panel.tabType)
        .filter((item) => item !== undefined) as TabTableType[],
    [initialPanels]
  );

  const panels = useMemo(
    () =>
      initialPanels.map((panel) => {
        if (panel.tabType !== undefined) {
          const count = totals[TabTableType[panel.tabType]];
          return {
            ...panel,
            badge: count !== undefined ? count.toString() : undefined,
          } as ICCPanelProps;
        }
        return panel as ICCPanelProps;
      }),
    [initialPanels, totals]
  );

  const getTotals = useCallback(
    (id, recordType, relatedTypes, options) => {
      if (!id) return;
      getTabTableCount({
        id,
        recordType,
        relatedTypes,
        ...options,
      }).then((response) => {
        customLogger("Core Tab accordion, getTabTableCount").info(
          response?.data
        );
        if (isSuccessResponse(response)) {
          setTotals(response.data);
        } else {
          clearNotifications("warning", true);
          pushNotification({
            type: "warning",
            title: "Get accordions count failed",
          });
          setTotals({});
        }
      });
    },
    [clearNotifications, pushNotification]
  );

  const handleOnExpandedChange = useCallback(
    (expandedState: boolean[]) => {
      if (expandedState.some((isExpanded) => isExpanded))
        getTotals(id, recordType, relatedTypes, options);
    },
    [id, recordType, relatedTypes, options, getTotals]
  );

  useAddUniqueEventEmitter([
    {
      eventType: TabTableEventType.RefreshData,
      listener: () => {
        getTotals(id, recordType, relatedTypes, options);
      },
    },
  ]);

  useEffect(() => {
    const newParams = JSON.stringify({ id, recordType, relatedTypes, options });
    if (newParams !== lastedParams.current) {
      lastedParams.current = newParams;
      getTotals(id, recordType, relatedTypes, options);
    }
  }, [id, recordType, relatedTypes, options, getTotals]);

  useEffectOnce(() => {
    return () => resetStore();
  });

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled, setIsDisabled]);

  return (
    <CCPanelsBar
      {...others}
      initialPanels={panels}
      onChangeExpandedState={handleOnExpandedChange}
    />
  );
};
