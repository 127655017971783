import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { colAllocation } from "./config";

const RateBreakupDetail = (props: GridDetailRowProps) => {
  return (
    <CCGrid
      className="cc-rate-breakup-detail-grid"
      data={props?.dataItem?.Detail ?? []}
      columnFields={colAllocation}
      primaryField="ChargeId"
    />
  );
};

export default RateBreakupDetail;
