import { EErrorStandardAssociation } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/associations/model";
import { isNil } from "lodash";

export const validatorAmalgamatePICAssociation = (
  values: any
): string | undefined => {
  let message = "";
  if (
    isNil(values?._option?.PICs) ||
    values?._option?.PICs?.length < 2 ||
    isNil(values?.Selected_Primary_PIC_Id)
  )
    message += EErrorStandardAssociation.PIC + "_";
  if (
    isNil(values?._option?.Primary_Assessments) ||
    values?._option?.Primary_Assessments?.length <= 0 ||
    isNil(values?.Selected_Primary_Assessment_Id)
  )
    message += EErrorStandardAssociation.Holding;
  return message;
};
