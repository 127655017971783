import { DTO_Session_Journals } from "@app/products/property/journals/session-list/[id]/model";
import { SESSION_STATUS_NAME_CAN_PROCESS } from "@app/products/property/journals/session-list/config";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCValueField } from "@components/cc-value-field/_index";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { ReactElement } from "react";
import JournalsInPropertySession from "./journals-in-property-session/_index";

interface IPropertyJournalSessionListProps {
  formRenderProps: FormRenderProps;
}

export function PropertyJournalSessionList({
  formRenderProps,
}: IPropertyJournalSessionListProps): ReactElement {
  const nameOf = nameOfFactory<DTO_Session_Journals>();
  const getValue = formRenderProps.valueGetter;
  return (
    <FormElement className="cc-property-journal">
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCValueField
                  label="Created on"
                  value={getValue(nameOf("Session_Created_On"))}
                  format={DATETIME_FORMAT.DATETIME}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Created by"
                  value={getValue(nameOf("Session_Created_By_Name"))}
                />
              </div>
            </div>
          </div>
          {getValue(nameOf("Session_Status")) ===
            SESSION_STATUS_NAME_CAN_PROCESS.Updated && (
            <div className="cc-field">
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <CCValueField
                    label="Update on"
                    value={getValue(nameOf("Session_Updated_On"))}
                    format={DATETIME_FORMAT.DATETIME}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    label="Update by"
                    value={getValue(nameOf("Session_Updated_By"))}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <JournalsInPropertySession />
      </section>
    </FormElement>
  );
}
