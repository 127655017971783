import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { useDocumentIssuedStore } from "@app/products/property/components/grid/document-issued-grid/store";
import { nameOfLov } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { createDocument } from "./api";

interface ICreateDocumentCertificateDialogProps {
  title: string;
  componentNumber: eComponent;
  componentId: number | undefined;
  documentTypes: any[];
  onClose: () => void;
}
export const CreateDocumentDialog = observer(
  ({
    title,
    componentNumber,
    componentId,
    documentTypes,
    onClose,
  }: ICreateDocumentCertificateDialogProps) => {
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { reLoadDocumentsIssued, setDocumentIssueSelectedRow } =
      useDocumentIssuedStore();
    const { pushNotification } = useCCAppNotificationStore();

    const handleSubmit = async (event: any) => {
      setIsLoadingSubmit(true);
      const response = await createDocument(
        componentNumber,
        componentId,
        event.Type
      );
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        const successMess = {
          type: "success",
          title:
            response?.data?.Notification ??
            response?.data?.SuccessMessage ??
            "Create document was successful.",
        } as IAppNotificationItemAddProps;
        reLoadDocumentsIssued();
        //Refresh document accordion after created successfully
        eventEmitter.emit(PropertyDocumentEventType.RefreshData);
        // Refresh History
        eventEmitter.emit(CCJournalEventType.RefreshData);
        pushNotification(successMess);
        setDocumentIssueSelectedRow(undefined);
        onClose();
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title:
            response?.data?.ErrorMessage ??
            response?.error ??
            "Create document failed.",
          type: "error",
        });
      }
      setIsLoadingSubmit(false);
    };

    return (
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCDialog
              titleHeader={title}
              onClose={onClose}
              maxHeight="25%"
              maxWidth="30%"
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field">
                    <CCLabel title="Type" isMandatory />
                    <Field
                      name={"Type"}
                      component={CCSearchComboBox}
                      data={documentTypes || []}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      isUseDefaultOnchange
                      validator={requiredValidator}
                    />
                  </div>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    disabled={isLoadingSubmit || !formRenderProps?.valid}
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
