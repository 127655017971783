import { Premises } from "@app/products/hm/premises/[id]/model";

export class PremisesClosure {
  Date_Closed?: Date;
  ClosureType_KWD?: number;
  ClosureReason: string;
  ShowNotification?: boolean;
  SendNotification?: boolean;
  NotifyText: string;

  constructor() {
    this.ShowNotification = false;
    this.SendNotification = false;
    this.NotifyText = "";
    this.ClosureReason = "";
  }
}
export interface PremisesClosureWorkflowRequest {
  Premises: Premises;
  PremisesClosure: PremisesClosure;
}
