import {
  ServiceStandard,
  ServiceStandardMapObj,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

export interface CommunicationSettingsProps {
  formRenderProps: FormRenderProps;
}
export const CommunicationSettings = ({
  formRenderProps,
}: CommunicationSettingsProps) => {
  const { valueGetter } = formRenderProps;

  const { settings } = useCommonCoreStore();
  const { accessRight } = useCRMSServiceStandardStore();

  const isOnSendToActionSendEmailToRequestor = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Event_OnSendToActionSendEmailToRequestor
    ]
  );

  const isOnReSendToActionSendEmailToRequestor = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Event_OnReSendToActionSendEmailToRequestor
    ]
  );

  const isOnSendToActionSendSMSToRequestor = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Event_OnSendToActionSendSMSToRequestor
    ]
  );

  const isOnReSendToActionSendSMSToRequestor = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Event_OnReSendToActionSendSMSToRequestor
    ]
  );

  const isOnSendToActionSendEmailToOnBehalfOf = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Event_OnSendToActionSendEmailToOnBehalfOf
    ]
  );

  const isOnReSendToActionSendEmailToOnBehalfOf = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CUSTOMERSERVICE_Event_OnReSendToActionSendEmailToOnBehalfOf
    ]
  );

  const isOnClosureSendSMSToRequestor = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CUSTOMERSERVICE_Event_OnClosureSendSMSToRequestor
    ]
  );

  const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <CCValueField
          label="Send to coordinator"
          value={
            valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "SendToCoordinator"
              )}`
            )
              ? "Yes"
              : "No"
          }
        />
        <CCValueField
          label="Send to action officer"
          value={
            valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "SendToActionOfficer"
              )}`
            )
              ? "Yes"
              : "No"
          }
        />
      </div>

      <div className="cc-form-cols-2">
        <div className="cc-field">
          {isOnSendToActionSendEmailToRequestor ? (
            <>
              <CCLabel title="Send email to requestor on send for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SendToActionSendEmailToRequestor")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SendToActionSendEmailToRequestor"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send email to requestor on send for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SendToActionSendEmailToRequestor"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>

        <div className="cc-field">
          {isOnReSendToActionSendEmailToRequestor ? (
            <>
              <CCLabel title="Send email to requestor on re-send for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("ReSendToActionSendEmailToRequestor")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ReSendToActionSendEmailToRequestor"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send email to requestor on re-send for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ReSendToActionSendEmailToRequestor"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
      </div>

      <div className="cc-form-cols-2">
        <div className="cc-field">
          {isOnSendToActionSendSMSToRequestor ? (
            <>
              <CCLabel title="Send SMS to requestor on send for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SendToActionSendSMSToRequestor")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SendToActionSendSMSToRequestor"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send SMS to requestor on send for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SendToActionSendSMSToRequestor"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>

        <div className="cc-field">
          {isOnReSendToActionSendSMSToRequestor ? (
            <>
              <CCLabel title="Send SMS to requestor on re-send for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("ReSendToActionSendSMSToRequestor")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ReSendToActionSendSMSToRequestor"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send SMS to requestor on re-send for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ReSendToActionSendSMSToRequestor"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
      </div>

      <div className="cc-form-cols-2">
        <div className="cc-field">
          {isOnSendToActionSendEmailToOnBehalfOf ? (
            <>
              <CCLabel title="Send email to on behalf of on send for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("SendToActionSendEmailToOnBehalfOf")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SendToActionSendEmailToOnBehalfOf"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send email to on behalf of on send for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "SendToActionSendEmailToOnBehalfOf"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>

        <div className="cc-field">
          {isOnReSendToActionSendEmailToOnBehalfOf ? (
            <>
              <CCLabel title="Send email to on behalf of on re-send for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("ReSendToActionSendEmailToOnBehalfOf")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ReSendToActionSendEmailToOnBehalfOf"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send email to on behalf of on re-send for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ReSendToActionSendEmailToOnBehalfOf"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
      </div>

      <div className="cc-form-cols-2">
        <div className="cc-field">
          {isOnClosureSendSMSToRequestor ? (
            <>
              <CCLabel title="Send SMS to requestor on closure for action" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("ClosureSendSMSToRequestor")}`}
                checked={valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ClosureSendSMSToRequestor"
                  )}`
                )}
                component={CCSwitch}
              />
            </>
          ) : (
            <CCValueField
              label="Send SMS to requestor on closure for action"
              value={
                valueGetter(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "ClosureSendSMSToRequestor"
                  )}`
                )
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
      </div>
    </section>
  );
};
