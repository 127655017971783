import {
  loadMetersReading,
  reverseVoidReadings,
  unVerifyReadings,
  verifyReadings,
  voidReadings,
} from "@app/products/property/meters/[id]/components/child-screens/reading/api";
import { colReadings } from "@app/products/property/meters/[id]/components/child-screens/reading/config";
import { IMetersReading } from "@app/products/property/meters/[id]/components/child-screens/reading/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

export const nameOf = nameOfFactory<IMetersReading>();

class MeterReadingsStore {
  private _readings: IMetersReading[] = [];
  private _isLoading: boolean = false;
  private _selectedReadings?: IMetersReading[] = undefined;
  private _gridColumns: IColumnFields[] = colReadings;
  constructor() {
    makeAutoObservable(this);
  }

  get readings() {
    return this._readings;
  }
  get isLoading() {
    return this._isLoading;
  }

  get selectedReadings() {
    return this._selectedReadings;
  }
  get gridColumns() {
    return this._gridColumns;
  }

  setReadings = (readings: IMetersReading[]) => {
    runInAction(() => {
      this._readings = readings;
    });
  };
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setSelectedReadings = (selectedReadings?: IMetersReading[]) => {
    runInAction(() => {
      this._selectedReadings = selectedReadings;
    });
  };
  setGridColumns = (gridColumns: IColumnFields[]) => {
    runInAction(() => {
      this._gridColumns = gridColumns;
    });
  };

  //Action
  loadReadings = async () => {
    this.setIsLoading(true);
    const data = await loadMetersReading();
    this.setReadings(data);
    this.setIsLoading(false);
  };

  voidReadings = async () => {
    this.setIsLoading(true);
    if (!this.selectedReadings) return;
    const newReadings = await voidReadings(
      this.readings,
      this.selectedReadings
    );
    this.setReadings(newReadings);
    this.setSelectedReadings([]);
    this.setIsLoading(false);
  };

  verifyReadings = async () => {
    this.setIsLoading(true);
    if (!this.selectedReadings) return;
    const newReadings = await verifyReadings(
      this.readings,
      this.selectedReadings
    );
    this.setReadings(newReadings);
    this.setSelectedReadings([]);
    this.setIsLoading(false);
  };

  reverseVoid = async () => {
    this.setIsLoading(true);
    if (!this.selectedReadings) return;
    const newReadings = await reverseVoidReadings(
      this.readings,
      this.selectedReadings
    );
    this.setReadings(newReadings);
    this.setSelectedReadings([]);
    this.setIsLoading(false);
  };

  unVerifyReadings = async () => {
    this.setIsLoading(true);
    if (!this.selectedReadings) return;
    const newReadings = await unVerifyReadings(
      this.readings,
      this.selectedReadings
    );
    this.setReadings(newReadings);
    this.setSelectedReadings([]);
    this.setIsLoading(false);
  };
}

const meterReadingsStoreContext = createContext(new MeterReadingsStore());
export const useMeterReadingsStore = () => {
  return useContext(meterReadingsStoreContext);
};
