import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { ICustomEditCell } from "@components/cc-grid/model";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const NumberCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  return (
    <NumericTextBox
      value={value}
      format={DECIMAL_FORMAT.DECIMAL_2_DIGIT}
      min={0}
      onChange={(event) => {
        if (onChange) {
          let newValue = event.value;
          if (event.value === -0) {
            newValue = 0;
          }
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: newValue,
          });
        }
      }}
    />
  );
};
