import { IMailMergeGridData } from "@app/core/communication/dialogs/components/form-elememts/template/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IMailMergeGridData>();
export const colMailMerge: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("SaveFormat"),
    title: "Save Format",
  },
];
