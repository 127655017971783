import { searchResultDataMock } from "./[id]/mock";

export const loadSearchDataById = async (id: number): Promise<any> => {
  const AdvanceSearchData = JSON.parse(JSON.stringify(searchResultDataMock));
  return AdvanceSearchData;
};

export const loadAdvancedSearchIdByFilter = async (
  filterData: any
): Promise<any> => {
  return 1;
};
