import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { colDetail } from "@app/products/property/supplementary-rates/valuations-by-group/components/detail/config";
import { VO_ValuationAssessments } from "@app/products/property/supplementary-rates/valuations-by-group/components/detail/model";
import { ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP_DETAIL } from "@app/products/property/supplementary-rates/valuations-by-group/constant";
import { VO_Valuations_By_Group } from "@app/products/property/supplementary-rates/valuations-by-group/model";
import { APIResponseError } from "@common/apis/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { CCGrid } from "@components/cc-grid/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_ValuationAssessments>();
const nameOfValuationsByGroup = nameOfFactory<VO_Valuations_By_Group>();

const DetailComponent = ({ dataItem }: GridDetailRowProps) => {
  const [listConfigurationDetail, setListConfigurationDetail] = useState<
    IColumnFields[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const keys = Object.keys(dataItem);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_SupplementaryValuationAssessments,
      colDetail
    );
    if (Array.isArray(response)) {
      const processedResponse = response.map((col: IColumnFields) => {
        const totalKey = `Sum_${col.field}`;
        if (keys.includes(totalKey)) {
          return {
            ...col,
            footerCell: (
              <NumberCell
                style={formatStyleProminent}
                format={NUMBER_FORMAT.NUMBER2}
              />
            ),
            calculateFooterCellValue: () =>
              dataItem[totalKey as keyof typeof dataItem],
          } as IColumnFields;
        }
        return col;
      });
      setListConfigurationDetail(processedResponse);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-rebates-list-detail">
      <CCGrid
        className="cc-associated-entities-grid"
        dataUrl={ODATA_SUPPLEMENTARYS_RATE_VALUATION_BY_GROUP_DETAIL}
        columnFields={listConfigurationDetail}
        primaryField={nameOf("Assessment_Id")}
        state={{
          filter: {
            logic: "and",
            filters: [
              {
                field: nameOfValuationsByGroup("Valuation_Group_Name"),
                value: dataItem?.Valuation_Group_Name,
                operator: "eq",
              },
              {
                field: nameOfValuationsByGroup("Rating_Period_Id"),
                value: dataItem?.Rating_Period_Id,
                operator: "eq",
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default DetailComponent;
