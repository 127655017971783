import { getViewConfigurations } from "@app/products/property/api";
import { getChargeRunLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/api";
import {
  DTO_LevySummary,
  eLevySummaryTypes,
} from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { getViewConfigurationsByType } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/utils";
import { ICCViewColumn } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class ChargeRunLevySumaryGroupStore {
  private _chargeRunLevySumaryGroup: DTO_LevySummary[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _viewConfiguration: ICCViewColumn[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get chargeRunLevySumaryGroup() {
    return this._chargeRunLevySumaryGroup;
  }
  setChargeRunLevySumaryGroup = (
    chargeRunLevySumaryGroup: DTO_LevySummary[]
  ) => {
    runInAction(() => {
      this._chargeRunLevySumaryGroup = chargeRunLevySumaryGroup;
    });
  };

  get viewConfiguration() {
    return this._viewConfiguration;
  }
  setViewConfiguration = (viewConfiguration: ICCViewColumn[]) => {
    runInAction(() => {
      this._viewConfiguration = viewConfiguration;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  loadChargeRunLevySumaryGroup = async (
    id: number | undefined,
    levySumaryType: eLevySummaryTypes
  ) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const levySumaryGroupViewConfiguration: any =
      getViewConfigurationsByType(levySumaryType);
    const [responseConfiguration, responseData] = await Promise.all([
      getViewConfigurations(levySumaryGroupViewConfiguration),
      getChargeRunLevySummary(id, levySumaryType),
    ]);
    if (
      isSuccessResponse(responseConfiguration) &&
      isSuccessResponse(responseData) &&
      responseConfiguration?.data
    ) {
      this.setViewConfiguration(
        responseConfiguration.data.ColumnDefinitions?.Columns
      );
      this.setChargeRunLevySumaryGroup(responseData?.data?.LevySummary ?? []);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._chargeRunLevySumaryGroup = [];
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._viewConfiguration = [];
    });
  };
}

const chargeRunLevySumaryGroupStoreContext = createContext(
  new ChargeRunLevySumaryGroupStore()
);
export const useChargeRunLevySumaryGroupStore = () => {
  return useContext(chargeRunLevySumaryGroupStoreContext);
};
