import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { CreateDeferredDutyButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/create-deferred-duty/_index";
import { ModifyDeferredDutyButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/modify-deferred-duty/_index";
import { RaiseDeferredDutyInterestButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/raise-interest/_index";
import { colPropertyDeferredDutyAll } from "@app/products/property/deferred-duty/list/all/config";
import { PROPERTY_DEFERRED_DUTY_ALL_LIST_VIEW_URL } from "@app/products/property/deferred-duty/list/all/constant";
import { VO_DeferredDuty } from "@app/products/property/deferred-duty/list/all/model";
import { getBookmarkListViewDetail } from "@app/products/property/deferred-duty/list/all/util";
import { deferredDutyRoute } from "@app/products/property/deferred-duty/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDisplayName,
} from "@app/products/property/deferred-duty/util";
import { DeferredDutyAddToJournalButton } from "@app/products/property/deferred-duty/[id]/components/action-bar/button/add-to-journal/_index";
import { DeferredDutyAssociationsTab } from "@app/products/property/deferred-duty/[id]/components/reference-sidebar/associations/_index";
import { DeferredDutyDetailTab } from "@app/products/property/deferred-duty/[id]/components/reference-sidebar/details/_index";
import { PROPERTY_DEFERRED_DUTY_ROUTE } from "@app/products/property/deferred-duty/[id]/constant";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

const nameOf = nameOfFactory<VO_DeferredDuty>();

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={deferredDutyRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <CCNavButton title={"Deferred duty"} type="dropdown">
          <CreateDeferredDutyButton />
          <ModifyDeferredDutyButton />
        </CCNavButton>
        <CCNavButton title={"Journal "} type="dropdown">
          <DeferredDutyAddToJournalButton />
          {/* 5688 - Hide Adjust balances button - will be implementing in future*/}
          {/* <DeferredDutyAdjustBalancesButton /> */}
          <RaiseDeferredDutyInterestButton />
        </CCNavButton>
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Deferred_Duty}
        recordType={RECORDTYPE.CommunityProperty_Deferred_Duty_Account}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Deferred_Duty_Account}
        keyField={"Schema_Acccount_ID"}
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_DEFERRED_DUTY_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <DeferredDutyDetailTab />,
      },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab
            componentNumber={eComponent.Deferred_Duty_Account}
          />
        ),
      },
      {
        title: "Related",
        component: <DeferredDutyAssociationsTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colPropertyDeferredDutyAll}
      dataUrl={PROPERTY_DEFERRED_DUTY_ALL_LIST_VIEW_URL}
      primaryField={nameOf("Schema_Acccount_ID")}
    />
  );
};
