import { DTO_Deferment_LOVs } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import {
  DTO_Workflow_ModifyDeferment,
  ModifyDefermentRequestObjFromSpatial,
} from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewWorkflowModifyDeferment = async (
  requestObjFromSpatial: ModifyDefermentRequestObjFromSpatial | undefined
): Promise<APIResponse<DTO_Workflow_ModifyDeferment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifydeferment/new`,
      requestObjFromSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewModifyDeferment = async (
  mode: WorkflowProcessMode,
  payload: DTO_Workflow_ModifyDeferment | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyDeferment>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifydeferment/process/${mode}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getModifyDefermentDraft = async (
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_ModifyDeferment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/modifydeferment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataModifyDeferment =
  | [APIResponse<DTO_Deferment_LOVs>, APIResponse<DTO_Workflow_ModifyDeferment>]
  | APIResponse<DTO_Deferment_LOVs | DTO_Workflow_ModifyDeferment>
  | undefined;
export const getInitialDataModifyDeferment = async (
  workflowDraftId: number | undefined,
  requestObjFromSpatial: ModifyDefermentRequestObjFromSpatial | undefined
): Promise<IGetInitialDataModifyDeferment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Deferment_LOVs>(
        `api/property/internal/workflow/modifydeferment/lovs`
      ),
      !isNil(workflowDraftId)
        ? getModifyDefermentDraft(workflowDraftId)
        : postNewWorkflowModifyDeferment(requestObjFromSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
