import { getHMPremisesAddress } from "@app/products/hm/premises/[id]/components/reference-sidebar/map/api";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const HMPremisesTabMap = observer(() => {
  const { cancelToken } = useCancelRequest();
  const { pushNotificationPortal } = useNotificationPortalStore();
  const { lastSelectedId } = useCCProductListViewStore();

  const [address, setAddress] = useState<string>("");

  const getPremisesAddressSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const premisesID: number = initialData;
          return getHMPremisesAddress(premisesID, cancelToken());
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<string> = dataFromApi;
          setAddress(response?.data ?? "");
        },
        handleError: (error) => {
          const { errorFromApi } = error;
          pushNotificationPortal({
            title: "Get premises address failed.",
            type: "error",
            description: errorFromApi?.error ?? "",
            autoClose: false,
          });
        },
      },
    ],
  });

  useEffect(() => {
    getPremisesAddressSlider.fetchApi({
      initialData: parseInt(lastSelectedId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedId]);

  if (getPremisesAddressSlider.isFetching) return <Loading isLoading />;

  return (
    <MapTab
      zoom={18}
      recordType={RECORDTYPE.HealthManager_Premises}
      isInManagePage={false}
      address={address}
    />
  );
});
