import { Master_Property_Valuation } from "@app/products/property/assessments/master-properties/[id]/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Master_Property_Valuation>();
export const colValuations: IColumnFields[] = [
  {
    field: nameOf("VT_Name"),
    title: "Type",
  },
  {
    field: nameOf("Master_Valuation"),
    title: "Master Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Valuation"),
    title: "Assessments Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
