import { IDataPick } from "@common/pages/settings/system-admin/task-scheduling/components/pick-day-of-week/_index";
import { daysOfWeekData } from "@common/pages/settings/system-admin/task-scheduling/constant";
import {
  DayOfWeekType,
  ScheduleJobType,
  ScheduleJob_New,
  eCronFormat,
} from "@common/pages/settings/system-admin/task-scheduling/model";

/**
 * Check the array number is an adjacent number or not
 * to convert the day of the week to the cron format
 * ex: [1, 2, 3, 4, 5] => true
 * ex: [1, 2, 4, 5] => false
 * @param arr
 * @returns boolean
 */
export const isCheckAdjacentNumbers = (arr: number[]) => {
  let result = true;
  arr.forEach((item, index) => {
    if (index === arr.length - 1) return;
    if (item + 1 !== arr[index + 1]) {
      result = false;
    }
  });
  return result;
};

/**
 * @param data
 * @returns array: "seconds, minutes, hours, day of month, month, day of week year"
 * Note:
 * - This function does not cover the repeat, expire, and duration cases.
 * - Only used for the "Daily" and "Weekly" job types.
 * - Use the *,/-? format for the cron expression.
 */
export const convertCronFormat = (data: ScheduleJob_New) => {
  const { JobScheduleStartDateTime, DayOfWeek, JobType, RecurEvery } = data;

  let dayOfWeek = "?";
  let dayOfMonth = JobScheduleStartDateTime.getDate().toString();
  let month = (JobScheduleStartDateTime.getMonth() + 1).toString();

  switch (JobType) {
    case ScheduleJobType.Daily:
      month = "*";
      dayOfMonth = RecurEvery ? `*/${RecurEvery}` : "*";
      break;
    case ScheduleJobType.Weekly:
      if (DayOfWeek?.length) {
        dayOfMonth = "?";
        month = "*";
        dayOfWeek = "";
        const firstValue = covertNumberToStringDayOfWeek(DayOfWeek[0]);
        const endValue = covertNumberToStringDayOfWeek(
          DayOfWeek[DayOfWeek.length - 1]
        );
        if (DayOfWeek.length === 1) {
          dayOfWeek = firstValue;
        } else if (isCheckAdjacentNumbers(DayOfWeek)) {
          dayOfWeek = `${firstValue}-${endValue}`; // ex: 2-6: monday to friday
        } else {
          // ex: 2,4,6: monday, wednesday, friday
          DayOfWeek.forEach((item: DayOfWeekType, index: number) => {
            dayOfWeek += covertNumberToStringDayOfWeek(item);
            if (index !== DayOfWeek.length - 1) {
              dayOfWeek += ",";
            }
          });
        }
      }
      break;
  }

  return {
    [eCronFormat.Year]: "*",
    [eCronFormat.DayOfWeek]: dayOfWeek,
    [eCronFormat.Month]: month,
    [eCronFormat.DayOfMonth]: dayOfMonth,
    [eCronFormat.Hours]: JobScheduleStartDateTime.getHours().toString(),
    [eCronFormat.Minutes]: JobScheduleStartDateTime.getMinutes().toString(),
    [eCronFormat.Seconds]: "0",
  };
};

/**
 * @param result
 * @returns string: "seconds minutes hours day-of-month month day-of-week year"
 * @example "0 0 12 1 1 ? *" - At 12:00:00pm, on the 1st day, in January
 */
export const cronFormatString = (result: any) => {
  return `${result[eCronFormat.Seconds]} ${result[eCronFormat.Minutes]} ${
    result[eCronFormat.Hours]
  } ${result[eCronFormat.DayOfMonth]} ${result[eCronFormat.Month]} ${
    result[eCronFormat.DayOfWeek]
  } ${result[eCronFormat.Year]}`;
};

// Ex: 2 => MON
export const covertNumberToStringDayOfWeek = (dayOfWeek: DayOfWeekType) => {
  const data = daysOfWeekData.find(
    (item: IDataPick) => item.value === dayOfWeek
  );
  return data?.label ?? "";
};
