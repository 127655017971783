export const handleValidator = (
  value: any,
  valueGetter: (name: string) => any
) => {
  const isAddressValidationLoading = valueGetter(
    "_option.isAddressValidationLoading"
  );
  if (!isAddressValidationLoading) return "";
  return "Address validation is processing";
};
