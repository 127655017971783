export const CRMS_UNRESOLVED_EVENTS_BY_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEvents(flagITSupport=false)?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_ACKNOWLEDGEMENT_REQ_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEventsAcknowledgementRequired?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_RESENT_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEventsResent?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_MY_EVENT_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEventsMyEvents?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_DRAFTS_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEventsDrafts?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_CS_ADMIN_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEventsCSAdmin?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_RISK_VIEW_URL =
  "odata/crms/internal/events/GetUnresolvedEventsRisk?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_BY_ASSIGNED_TO_ORG =
  "odata/crms/internal/events/GetUnresolvedEventsByAssignedToOrgStructure?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_BY_SPECIAL_INTEREST_URL =
  "odata/crms/internal/events/GetUnresolvedEventsSpecialInterest?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_BY_ASSET_URL =
  "odata/crms/internal/events/GetUnresolvedEvents(flagITSupport=false,showOpenEventsOnly=true,isAsset=true)?$count=true&";

export const CRMS_UNRESOLVED_EVENTS_BY_DUE_STATUS =
  "odata/crms/internal/events/GetUnresolvedEventsByDueStatus?$count=true&";
