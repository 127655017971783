import { odataCoreGetInspection } from "@app/core/components/common/utils";
import { DeleteInspectionButton } from "@app/core/inspections/inspections-accordion/components/button/delete-inspection-button/_index";
import { colInspections } from "@app/core/inspections/inspections-accordion/config";
import { IInspection } from "@app/core/inspections/inspections-accordion/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";

interface IInspectionsProps {
  id?: number | string;
  recordType: RECORDTYPE;
  isDisabledDelete?: boolean;
  reloadAfterDeleted?: () => void;
}

const nameOf = nameOfFactory<IInspection>();
const Inspections: React.FC<IInspectionsProps> = ({
  id,
  recordType,
  isDisabledDelete: isDisabled = false,
  reloadAfterDeleted = () => {},
}) => {
  const [gridSelectedRows, setGridSelectedRows] = useState<IInspection[]>([]);
  return (
    <CCGrid
      selectableMode="multiple"
      columnFields={colInspections}
      primaryField={nameOf("ID")}
      dataUrl={
        id === undefined ? undefined : odataCoreGetInspection(id, recordType)
      }
      selectedRows={gridSelectedRows}
      onSelectionChange={(dataItem: IInspection[]) => {
        setGridSelectedRows([...dataItem]);
      }}
      state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
      toolbar={
        <div className="cc-grid-tools-bar">
          <DeleteInspectionButton
            recordType={RECORDTYPE.CORE_Inspection}
            gridSelectedRows={gridSelectedRows}
            setGridSelectedRows={setGridSelectedRows}
            disabled={isDisabled || gridSelectedRows.length !== 1}
            reloadAfterDeleted={reloadAfterDeleted}
          />
        </div>
      }
    />
  );
};

export default Inspections;
