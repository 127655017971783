export const mockSpecialReadingListDetailsTab = {
  SpecialReadType: "Change of Ownership",
  CreatedOn: new Date("27-Sep-2021 14:39"),
  CreatedBy: "rhosking",
  MeterSpecialReadId: 26,
  JournalNumber: 6080,
  DateRead: new Date("13-Nov-2017 11:33"),
  AssessmentNumber: 11500000.0001,
  PropertyAddress: "Nr Recreat.Cnt Sh.Har Rd/abel,",
};
