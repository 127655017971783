import { optionInputPickerClosureReason } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { IEnquiriesSendResponse } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/not-for-vr/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

export interface IEnquiriesSendResponseDialogProps {
  onClosePopup: () => void;
  onSubmit: (data: IEnquiriesSendResponse) => Promise<void>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  notifications?: IAppNotificationItemAddProps;
}

export const EnquiriesSendResponseDialog = observer(
  ({
    onClosePopup,
    onSubmit,
    isLoading,
    setIsLoading,
    notifications,
  }: IEnquiriesSendResponseDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const nameOf = nameOfFactory<IEnquiriesSendResponse>();
    const { pprEnquiry } = usePPREnquiryStore();

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      onSubmit(event.values as IEnquiriesSendResponse);
    };

    const [enquiryOutcome, setEnquiryOutcome] = useState<CoreKeyword[]>();

    const initialData = useMemo(() => {
      let newEnquiryOutcome = undefined;
      if (enquiryOutcome && pprEnquiry) {
        newEnquiryOutcome = enquiryOutcome.find(
          (keyword: CoreKeyword) =>
            keyword.Keyword_ID === pprEnquiry?.Outcome_KWD
        );
      }
      return {
        ClosureReason: pprEnquiry?.Comment2,
        DateResponded: pprEnquiry?.Date_Responded || new Date(),
        DateClosed: new Date(),
        EnquiryOutcome: newEnquiryOutcome,
      };
    }, [enquiryOutcome, pprEnquiry]);

    useEffect(() => {
      if (notifications) {
        notificationRef.current?.resetNotifications();
        notificationRef.current?.pushNotification(notifications);
      }
    }, [notifications]);

    useEffectOnce(() => {
      setIsLoading(true);
      getKeywords(
        KEYWORD_TYPE.Core_RegisterPlanningPermitReferralEnquiryOutcome,
        PRODUCT_TYPE_NUMBER.TownPlanning
      ).then((response) => {
        if (isSuccessResponse(response)) {
          if (response?.data) setEnquiryOutcome(response?.data);
        } else {
          notificationRef.current?.pushNotification({
            title: `Enquiry outcome categories load failed`,
            type: "warning",
          });
        }
      });
      setIsLoading(false);
    });

    return (
      <Form
        initialValues={initialData}
        onSubmitClick={(event: FormSubmitClickEvent) => handleSubmit(event)}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, modified, valid, onChange } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth="35%"
                height="auto"
                disabled={isLoading}
                titleHeader={"Send Response"}
                onClose={onClosePopup}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Closure reason
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={"ClosureReason"}
                            placeholder={"Closure reason"}
                            component={InputPickerSearch}
                            validator={requiredValidator}
                            onChange={(data: CoreKeyword) => {
                              onChange("ClosureReason", {
                                value: data?.Name,
                              });
                              onChange("Rating_KWD", {
                                value: data?.Keyword_ID,
                              });
                            }}
                            options={optionInputPickerClosureReason}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Enquiry outcome
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("EnquiryOutcome")}
                            component={CCSearchComboBox}
                            validator={requiredValidator}
                            data={enquiryOutcome || []}
                            textField={nameOfCoreKeyword("Name")}
                            dataItemKey={nameOfCoreKeyword("Keyword_ID")}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Date responded</label>
                          <Field
                            name={nameOf("DateResponded")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Date closed</label>
                          <Field
                            name={nameOf("DateClosed")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button
                      className={"cc-dialog-button"}
                      onClick={() => {
                        onClosePopup();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!modified || !valid}
                      themeColor="primary"
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onClick={onSubmit}
                      className={"cc-dialog-button"}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
