import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadDetailBookingById } from "./api";

export interface IEventManagementReferenceProps {
  debtorNo?: string;
  osFees?: number;
  status?: string;
  lodged?: Date;
}

export const EventManagementDetailsTab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState<
    IEventManagementReferenceProps | undefined
  >(undefined);
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const bookingId = params.id;

  useEffect(() => {
    if (bookingId || lastSelectedRow?.ID) {
      const id = bookingId ?? lastSelectedRow?.ID;
      setIsLoading(true);
      loadDetailBookingById(parseInt(id + "")).then((data) => {
        if (!data) {
          setBookingInfo(undefined);
          setIsLoading(false);
          return;
        }
        setBookingInfo(data);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, bookingId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Permit Number:"}
        value={bookingInfo?.debtorNo}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={bookingInfo?.status
            ?.toString()
            .replace(/([a-z](?=[A-Z]))/g, "$1 ")}
          success
        />
        <ProductReferenceRow
          title={"OS Fees:"}
          value={formatDisplayValue(
            bookingInfo?.osFees,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
        <ProductReferenceRow
          title={"Lodged:"}
          value={formatDisplayValue(bookingInfo?.lodged, DATE_FORMAT.DATE)}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
};
