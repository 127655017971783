import {
  getModelConfigSetForModelRef,
  getModelsForConfigSet,
} from "@app/products/ams/api/data/catalogues/catalogue-store";
import { useStores } from "@app/products/ams/api/helpers/use-stores";
import { IAssetCategory } from "@app/products/ams/model/assetCategory";
import {
  IFieldCfgScreenReference,
  IFieldConfiguration,
  IFieldDefModelReference,
  IModelData,
  IModelReference,
} from "@app/products/ams/model/catalogue";
import Loading from "@components/loading/Loading";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BoxCanvas } from "../components/BoxCanvas";
import EntityDataDisplay, {
  ILookupConfig,
} from "../components/EntityDataDisplay";
import { EntityFieldLayoutElement } from "../components/EntityFieldLayoutElement";
import PanelBarTitle from "../components/PanelBarTitle";
import { ItemTypes } from "../util/itemTypes";

const ManageAssetCategory = (props: any) => {
  let history = useHistory();
  const {
    dataStores: { assetCategoryStore },
  } = useStores();

  const [category, setCategory] = useState<IAssetCategory>({
    id: 0,
    name: "new",
    featureTables: [
      {
        name: "Default",
        columns: [
          { name: "AssetNumber", helpText: "Asset Number" },
          { name: "AssetDescription", helpText: "Asset Desc" },
          { name: "AssetCategory", helpText: "" },
          { name: "Status", helpText: "" },
        ],
      },
    ],
  });

  const modelRef: IModelReference = {
    modelName: "Roads",
    context: "assetCategory",
  };
  //const [colsInUse, setColsInUse] = useState<string[]>(["AssetNumber"]);
  const [mdlCfgSet, updateMdlCfgSet] = useState(
    getModelConfigSetForModelRef(modelRef)
  );
  const [mdlDef] = useState(getModelsForConfigSet(mdlCfgSet));

  const moveGrpContainer = (
    dragOrdinalPos: number,
    hoverOrdinalPos: number
  ) => {
    const dragGrpItem = mdlCfgSet.groupContainers.filter(
      (r) => r.ordinalPosition === dragOrdinalPos
    )[0];
    const hoverGrpItem = mdlCfgSet.groupContainers.filter(
      (r) => r.ordinalPosition === hoverOrdinalPos
    )[0];
    const newItems = mdlCfgSet.groupContainers.filter(
      (r) =>
        r.ordinalPosition !== dragOrdinalPos &&
        r.ordinalPosition !== hoverOrdinalPos
    );
    const concatted = newItems.concat([
      { ...dragGrpItem, ordinalPosition: hoverOrdinalPos },
      { ...hoverGrpItem, ordinalPosition: dragOrdinalPos },
    ]);
    updateMdlCfgSet({ ...mdlCfgSet, groupContainers: concatted });
  };

  const handleDrop = (item: any) => {
    const fdmr = item.fdmr as IFieldDefModelReference;

    if (!fdmr) {
      return;
    }

    //the drop will need more than the field property name passed in now
    let mdlCfgs = mdlCfgSet.modelConfigs.filter(
      (r) =>
        r.modelDefRef.context === fdmr.modelDefRef.context &&
        r.modelDefRef.modelName === fdmr.modelDefRef.modelName
    );
    let mdlCfg = mdlCfgs[0];
    let fields = mdlCfg.fields.filter(
      (r) => r.propertyName !== fdmr.fieldDef.propertyName
    );
    let grpContainer = mdlCfgSet.groupContainers[0]; //next we handle which container i drop into

    const maxOrdinal = Math.max.apply(
      Math,
      grpContainer.fields.map((r, i) => {
        return r.ordinalPosition;
      })
    );

    let fldScrRef: IFieldCfgScreenReference = {
      modelDefRef: fdmr.modelDefRef,
      propertyName: fdmr.fieldDef.propertyName,
      ordinalPosition: maxOrdinal + 10,
    };
    let fldCfg: IFieldConfiguration = {
      propertyName: fdmr.fieldDef.propertyName,
      isVisibile: true,
      isReadonly: fdmr.fieldDef.isReadonly,
      lookupName: "",
      formatString: "",
      placeHolder: "",
    };
    const allMdlCfgExcept = mdlCfgSet.modelConfigs.filter(
      (r) =>
        !(
          r.modelDefRef.context === fdmr.modelDefRef.context &&
          r.modelDefRef.modelName === fdmr.modelDefRef.modelName
        )
    );
    mdlCfg.fields = fields.concat(fldCfg);
    let grpFields = grpContainer.fields.filter(
      (r) =>
        !(
          r.modelDefRef.context === fldScrRef.modelDefRef.context &&
          r.modelDefRef.modelName === fldScrRef.modelDefRef.modelName &&
          r.propertyName === fldScrRef.propertyName
        )
    );
    grpContainer.fields = grpFields.concat(fldScrRef);
    let grpContainers = mdlCfgSet.groupContainers.filter(
      (r, i) => r.groupContainerName !== grpContainer.groupContainerName
    );

    updateMdlCfgSet({
      ...mdlCfgSet,
      modelConfigs: allMdlCfgExcept.concat(mdlCfg),
      groupContainers: grpContainers.concat(grpContainer),
    });
  };

  const configureLookup = (
    fldCfgSr: IFieldCfgScreenReference,
    record: any
  ): ILookupConfig => {
    const retVal: ILookupConfig = {
      data: [{ Key: 1, Value: "sample" }],
      textField: "Value",
      dataItemKey: "Key",
      value: null,
    };
    return retVal;
  };

  useEffect(() => {
    assetCategoryStore.loadCategories();
    if (!assetCategoryStore.selectedCategory) {
      assetCategoryStore.setSelectedCategory(
        assetCategoryStore.categories[0].name
      );
    }

    if (assetCategoryStore.selectedCategory) {
      setCategory(assetCategoryStore.selectedCategory);
    }
    //dont need a cleanup function for now
    /*     return () => {
      setIsLoading(false);
      clearAsset();
    }; */
  }, [assetCategoryStore, setCategory, assetCategoryStore.featureToggleCount]);

  const handleSubmit = () => {
    /*     if (asset.AssetId === 0) {
      createAsset(asset).then(() => {});
    } else {
      editAsset(asset).then(() => {
        // Mobx does not get reset but local state gets reset
        loadAsset(id);
      });
    } */
  };

  if (assetCategoryStore.loadingInitial) {
    return (
      <React.Fragment>
        <Loading isLoading={assetCategoryStore.loadingInitial} />
      </React.Fragment>
    );
  }

  var firstSet = true;

  let modelDataSet: IModelData[] = [];
  //let modelDataSet = getDataForModelCfgSet(modelCfgSet);
  //modelDataSet.push({ modelReference: {modelName: "AssetRegister", context: "assetCategory"}, records: [asset] });
  mdlCfgSet.modelConfigs.forEach((item, index) => {
    modelDataSet.push({ modelReference: item.modelDefRef, records: [{}] });
  });

  return (
    <Fragment>
      <h5
        className="pl-1 pt-3 pb-2 ccBackButton"
        onClick={() => {
          history.goBack();
        }}
      >
        <i className="fa fa-arrow-circle-left mr-2" />
        Manage Category
      </h5>
      <div className="container-fluid">
        <h2 className="pt-3">
          <span className="badge badge-pill badge-warning">
            {category.name}
          </span>
        </h2>
        <div className="row col-md-12 border-bottom border-top ">
          <div className="col-auto pt-2">
            <label className="label btn-link" onClick={handleSubmit}>
              Save Category
            </label>
          </div>

          <div className="col-auto pt-2">
            <label className="label text-muted">More Options</label>
          </div>
        </div>
        <br />

        <div className="row">
          <form className="col-md-4">
            <div className="col-auto border-right">
              <PanelBar>
                {mdlDef.map((md) => {
                  return (
                    <PanelBarItem
                      expanded={firstSet}
                      title={
                        <PanelBarTitle
                          title={md.modelReference.modelName}
                          counter={md.fields.length}
                        />
                      }
                    >
                      {md.fields.map((fcd) => {
                        firstSet = false;
                        const fdmr: IFieldDefModelReference = {
                          modelDefRef: md.modelReference,
                          fieldDef: fcd,
                        };
                        return <EntityFieldLayoutElement fdmr={fdmr} />;
                      })}
                    </PanelBarItem>
                  );
                })}
              </PanelBar>
            </div>
          </form>

          <div className="col-md-8">
            <BoxCanvas
              accept={ItemTypes.EntityFieldLayoutElement}
              handleDrop={handleDrop}
            >
              <EntityDataDisplay
                modelData={modelDataSet}
                modelConfigSet={mdlCfgSet}
                configureLookup={configureLookup}
                designerMode={true}
                moveItem={moveGrpContainer}
              />
            </BoxCanvas>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default observer(ManageAssetCategory);
