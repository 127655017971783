import { SendForApprovalDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/_index";
import { ISendForApproval } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { putAppealSendForApproval } from "@common/pages/appeal/_id/components/buttons/workflow/send-for-approval/api";
import { useAppealSendForApprovalStore } from "@common/pages/appeal/_id/components/buttons/workflow/send-for-approval/store";
import {
  APPEALSTATUS,
  AppealSubmitActions,
} from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

const appealList = [
  APPEALSTATUS.AwaitingAppeal,
  APPEALSTATUS.Lodged,
  APPEALSTATUS.AwaitingHearing,
  APPEALSTATUS.Completed,
];
export const AppealSendForApprovalButton = observer(() => {
  const { appeal, onSubmit, appealId, reLoadAppeal } = useAppealStore();
  const { isShowDialog, setIsShowDialog, isLoading } =
    useAppealSendForApprovalStore();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();

  const isVisible = useMemo(() => {
    return appealList.some((item) => item === appeal?.WorkFlowStatus_ENUM);
  }, [appeal]);

  const handleSubmit = async ({ SelectedDocumentIDs }: ISendForApproval) => {
    if (!appealId) return;
    setIsSubmitLoading(true);
    const response = await putAppealSendForApproval(
      appealId,
      SelectedDocumentIDs
    );
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        setIsShowDialog(false);
        clearErrorNotification();
        setNotifications([]);
        reLoadAppeal().then(() => {
          pushNotification({
            type: "success",
            title: "Record saved successfully",
          });
        });
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Record could not be saved",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: response.statusText ?? "Record could not be saved",
          type: "error",
        },
      ]);
    }
    setIsSubmitLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title={"Send for approval"}
        onClick={onSubmit}
        name={AppealSubmitActions.AppealSendForApproval}
        isLoading={isLoading}
        disabled={isLoading}
      />
      {isShowDialog && (
        <SendForApprovalDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          isSubmitLoading={isSubmitLoading}
          notifications={notifications}
          parentData={{
            Id: appealId,
            recordType: RECORDTYPE.CORE_Appeal,
          }}
        />
      )}
    </>
  ) : null;
});
