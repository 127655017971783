export const contactsRouteStateGlobalSettings = [
  {
    name: "Contacts_GeneralSettings",
    component: require("./forms/general-settings").default,
  },
  {
    name: "Contacts_ViewSettings",
    component: require("./forms/view-settings").default,
  },
  {
    name: "Contacts_DirectExport",
    component: require("./forms/direct-export").default,
  },
  {
    name: "Contacts_DirectImport",
    component: require("./forms/direct-import").default,
  },
  {
    name: "Contacts_EnableABNOrACNCheck",
    component: require("./forms/enable-abn-acn-check").default,
  },
];
