import React, { useEffect, useRef, useState } from "react";
import "./Loading.scss";
export interface IIsLoadingProps {
  isLoading: boolean;
  isFullScreen?: boolean;
  classWrap?: string;
}

export default ({
  isLoading = false,
  isFullScreen = false,
}: IIsLoadingProps) => {
  const loadingRef = useRef<HTMLDivElement>(null);
  const [gridWidth, setGridWidth] = useState<string>("auto");

  useEffect(() => {
    const gridElement = loadingRef?.current?.closest(".cc-grid");
    if (gridElement?.clientWidth) setGridWidth(gridElement?.clientWidth + "px");
  }, []);

  return isLoading ? (
    <div className={isFullScreen ? "" : "cc-overwrite-loading"}>
      <div
        ref={loadingRef}
        className="cc-loading"
        style={{ maxWidth: gridWidth }}
      >
        <div className="cc-loading-container">
          <svg
            className="cc-loader"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM2.65 12C2.65 17.1639 6.83614 21.35 12 21.35C17.1639 21.35 21.35 17.1639 21.35 12C21.35 6.83614 17.1639 2.65 12 2.65C6.83614 2.65 2.65 6.83614 2.65 12Z"
              fill="currentColor"
              className="cc-loader-circle"
            />
            <path
              d="M3.10074 16.9329C2.70224 17.1538 2.19704 17.011 2.00663 16.597C1.39861 15.2753 1.05767 13.8434 1.0067 12.3839C0.946118 10.649 1.29698 8.92448 2.03061 7.35121C2.76424 5.77794 3.8598 4.40063 5.22771 3.33189C6.37849 2.4328 7.69453 1.77357 9.09789 1.38973C9.53738 1.26952 9.97156 1.56468 10.0585 2.01195V2.01195C10.1454 2.45921 9.85192 2.88869 9.41406 3.01471C8.26587 3.34516 7.18908 3.89338 6.24356 4.63211C5.08083 5.54053 4.1496 6.71125 3.52602 8.04853C2.90244 9.38581 2.6042 10.8517 2.6557 12.3263C2.69757 13.5255 2.96975 14.7027 3.45466 15.7947C3.63958 16.2111 3.49925 16.712 3.10074 16.9329V16.9329Z"
              className="cc-loader-path"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
