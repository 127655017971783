import { DTO_Component_Lineage_DataApiTree } from "@app/products/property/components/lineage-accordion/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Component_Lineage_DataApiTree>();
export const lineageDefaultCols: IColumnFields[] = [
  {
    field: nameOf("Number"),
    title: "Number",
  },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
