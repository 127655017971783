import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import {
  IAlertLovs,
  IAlerts,
} from "@app/core/contacts/components/dialogs/alert/model";
import { useAlertsDialogStore } from "@app/core/contacts/components/dialogs/alert/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import { useEffectOnce } from "react-use";

interface IAddAlertDialogProps {
  onClose: () => void;
  handleSubmit: (data: IAlerts) => void;
  isModifyAlert?: boolean;
  isLoadingSubmit?: boolean;
}

const nameOf = nameOfFactory<IAlerts>();
const nameOfLovs = nameOfFactory<IAlertLovs>();
export const AlertDialog = observer(
  ({
    isModifyAlert = false,
    isLoadingSubmit,
    onClose,
    handleSubmit,
  }: IAddAlertDialogProps) => {
    const {
      isLoadingDialog,
      notification,
      setNotification,
      responseLoadError,
      resetStore,
      loadAlert,
      alertModify,
      alertLovs,
    } = useAlertsDialogStore();
    const { alertSelectedRows } = useAlertsAccordionStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const alertID = useMemo(() => {
      return isModifyAlert && alertSelectedRows?.[0]
        ? alertSelectedRows?.[0]?.ID
        : 0;
    }, [alertSelectedRows, isModifyAlert]);

    useEffectOnce(() => {
      loadAlert(alertID);
      return () => {
        resetStore();
      };
    });

    useEffect(() => {
      if (notification) {
        notificationRef.current?.pushNotification(notification, () =>
          setNotification(undefined)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    const handleSubmitForm = (data: FormSubmitClickEvent) => {
      const { isValid, isModified, values } = data;
      if (!isValid) return;
      if (!isModified) {
        return setNotification({
          title: "The form is not modified",
          type: "warning",
        });
      }
      handleSubmit(values as IAlerts);
    };

    const initialValues = useMemo(() => {
      if (isModifyAlert) {
        return { ...alertModify, IsActive: alertModify?.Active };
      }
      return {
        ID: 0,
        IsActive: true,
        Category_Id: alertLovs?.[0]?.Code ?? null,
        Category: alertLovs?.[0]?.Name ?? "",
      };
    }, [alertLovs, alertModify, isModifyAlert]);

    return (
      <Form
        onSubmitClick={handleSubmitForm}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, valueGetter, onSubmit, onChange } = formRenderProps;
          return (
            <CCDialog
              maxWidth="35%"
              height="auto"
              titleHeader={`${isModifyAlert ? "Modify " : "New "} Alert`}
              onClose={onClose}
              disabled={isLoadingSubmit}
              bodyElement={
                isLoadingDialog ? (
                  <Loading isLoading={isLoadingDialog} />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      loadAlert(alertID);
                    }}
                  />
                ) : (
                  <FormElement className="cc-form">
                    <section className="cc-field-group">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Category" />
                          <Field
                            name={nameOf("Category_Id")}
                            component={CCDropDownList}
                            data={alertLovs ?? []}
                            dataItemKey={nameOfLovs("Code")}
                            textField={nameOfLovs("Name")}
                            value={getDropdownValue(
                              "" + valueGetter(nameOf("Category_Id")),
                              alertLovs ?? [],
                              nameOfLovs("Code")
                            )}
                            onChange={(event: DropDownListChangeEvent) => {
                              onChange(nameOf("Category_Id"), {
                                value: event?.target?.value?.Code,
                              });
                              onChange(nameOf("Category"), {
                                value: event?.target?.value?.Name,
                              });
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Notes" isMandatory />
                          <Field
                            name={nameOf("Notes")}
                            component={CCTextArea}
                            validator={requiredValidator}
                            rows={3}
                          />
                        </div>
                        <div className="cc-field">
                          <CCLabel title="Active" />
                          <Field
                            name={nameOf("IsActive")}
                            component={CCSwitch}
                            checked={valueGetter(nameOf("IsActive")) ?? true}
                          />
                        </div>
                      </div>
                    </section>
                  </FormElement>
                )
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    disabled={isLoadingSubmit || isLoadingDialog || !valid}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
