import { Application_Decision } from "@app/products/town-planning/ppr/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Application_Decision_Number } from "./model";

export const getListPPRApprovalDecisions = (optCount: boolean) => {
  let ret: IKeyValuePacket[] = [
    {
      Key: Application_Decision_Number.OBJECTION,
      Value: GetFriendlyName(Application_Decision.OBJECTION),
    },
    {
      Key: Application_Decision_Number.NOOBJECTION,
      Value: GetFriendlyName(Application_Decision.NOOBJECTION),
    },
  ];

  if (optCount) {
    ret.splice(1, 0, {
      Key: Application_Decision_Number.NOOBJECTIONSUBJECTTOCONDITIONS,
      Value: GetFriendlyName(
        Application_Decision.NOOBJECTIONSUBJECTTOCONDITIONS
      ),
    });
  }
  return ret;
};

export const GetFriendlyName = (type: Application_Decision) => {
  switch (type) {
    case Application_Decision.Unspecified:
      return "Unspecified";
    case Application_Decision.RefusedByPlanning:
      return "Refused By Planning";
    case Application_Decision.PSASupported:
      return "Supported";
    case Application_Decision.PSANotSupported:
      return "Not Supported";
    case Application_Decision.PSAAdopted:
      return "Adopted";
    case Application_Decision.PSAAbandoned:
      return "Abandoned";
    case Application_Decision.PSADeferred:
      return "Deferred";
    case Application_Decision.PSAAdoptedWithChanges:
      return "Adopted with changes";

    case Application_Decision.Authorised:
      return "Authorised";
    case Application_Decision.AuthorisedWithCondition:
      return "Authorised with conditions";
    case Application_Decision.AuthorisationRefused:
      return "Authorisation refused";

    //PPR
    case Application_Decision.OBJECTION:
      return "Objection";
    case Application_Decision.NOOBJECTION:
      return "No Objection";
    case Application_Decision.NOOBJECTIONSUBJECTTOCONDITIONS:
      return "No Objection Subject To Conditions";
    case Application_Decision.VRRESPONDED:
      return "DoT Responded";

    //Assessement
    case Application_Decision.ApprovedSubjectToCondition:
      return "Approved subject to condition";
    case Application_Decision.ApprovedSubjectToStandardCondition:
      return "Approved subject to standard condition";

    //PSAR
    case Application_Decision.PanelReportRefused:
      return "Panel Report Refused";
    case Application_Decision.SentToPanelHearing:
      return "Sent To Panel Hearing";
    case Application_Decision.PSARSupportedWithChanges:
      return "Support With Changes";
    case Application_Decision.Gazetted:
      return "Gazetted";

    //Planning Permit Decision
    case Application_Decision.GrantPermit:
      return "Grant a Permit";
    case Application_Decision.GrantPermitWithConditions:
      return "Grant a Permit with Conditions";
    case Application_Decision.PermitRefused:
      return "Permit Refused";
    case Application_Decision.Withdrawn:
      return "Application Withdrawn";

    case Application_Decision.DeferredCommencement:
      return "Deferred Commencement";

    case Application_Decision.Cancelled:
      return "Application Cancelled";
  }

  return type.toString();
};
