import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();

export const colByStreet: IColumnFields[] = [
  {
    field: nameOf("Location_StreetName"),
    title: "Location Name",
  },
  {
    field: nameOf("Location_Suburb"),
    title: "Suburb",
  },
];
