import { IFeeTypesGrid } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/components/form-element/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
} from "@common/constants/common-format";
import { ActionType } from "@common/models/actionType";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfFeeTypes = nameOfFactory<IFeeTypesGrid>();
const nameOfActionTypes = nameOfFactory<ActionType>();

export const colFeeTypes: IColumnFields[] = [
  {
    field: nameOfFeeTypes("FeeType"),
    title: "Fee Type",
  },
  {
    field: nameOfFeeTypes("CalculatedFeeType"),
    title: "Calculated Fee Type",
  },
  {
    field: nameOfFeeTypes("Inherit"),
    title: "Inherit Fee",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfFeeTypes("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];

export const colActions: IColumnFields[] = [
  {
    field: nameOfActionTypes("Name"),
    title: "Name",
  },
];
