import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FormElement } from "@progress/kendo-react-form";
import React from "react";

const nameOf = nameOfFactory<DirectDebitSessionHeader>();
export const DirectDebitSessionFormElement = () => {
  return (
    <FormElement id="cc-form-manage-page-general">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Session number" />
            <Field
              component={CCInput}
              name={nameOf("Session_Number")}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Bank account" />
            <Field
              component={CCInput}
              name={nameOf("Bank_Account_Description")}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Transfer date" />
            <Field
              component={CCDatePicker}
              name={nameOf("Transfer_Date")}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Number of transfers" />
            <Field
              component={CCNumericTextBox}
              name={nameOf("No_Of_Transfers")}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Total amount" />
            <Field
              component={CCCurrencyInput}
              name={nameOf("Total_Amount")}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Number of rejections" />
            <Field
              component={CCNumericTextBox}
              name={nameOf("Rejected_Amount")}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rejected amount" />
            <Field
              component={CCCurrencyInput}
              name={nameOf("Rejected_Amount")}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Creation date" />
            <Field
              component={CCDatePicker}
              name={nameOf("Session_Creation_Date")}
              disabled
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Status" />
            <Field
              component={CCInput}
              name={nameOf("Status_Type_Name")}
              readOnly
            />
          </div>
        </div>
      </section>
    </FormElement>
  );
};
