import { NewAssessmentTransactionCommonButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/new-transaction/common/_index";
import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const NewAssessmentTransactionButtonAtCollections = observer(() => {
  const { gridSelectedRows } = useDetailAssessmentListViewStore();

  const listAssessmentID =
    useMemo(() => {
      return gridSelectedRows?.map((item) => item.Assessment_Id);
    }, [gridSelectedRows]) ?? [];

  return (
    <NewAssessmentTransactionCommonButton
      isDisabled={gridSelectedRows.length < 1}
      assessmentIDs={listAssessmentID}
    />
  );
});
