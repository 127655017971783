import { DTO_Journal_Transactions } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadAssessmentTransactionDetailTab = async (
  journalId: number,
  transactionId: number
): Promise<APIResponse<DTO_Journal_Transactions | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<
      DTO_Journal_Transactions | undefined
    >(
      `/api/property/internal/journal/${journalId}/transactions/${transactionId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
