import { getDisplayTextWithDashes } from "@common/utils/common";

export const LookupNoticeTypesBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },

  getBookmarkListViewDetail() {
    return "Settings - Lookups - Notice Types";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID
      ? `- ${selectedRow.ID}`
      : "";
    return `Settings - Notice Type ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ID + ", " + selectedRow.Name,
    ]);
  },
};
