import InterestDetails from "@app/products/property/assessments/[id]/components/child-screens/interest/components/detail/_index";
import { useAssessmentsInterestStore } from "@app/products/property/assessments/[id]/components/child-screens/interest/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";

export const Interest = observer(() => {
  const { assessmentId } = useAssessmentStore();
  const [isOnlyShowWhenInterestApplies, setIsOnlyShowWhenInterestApplies] =
    useState<boolean>(false);
  const [fromInterestDate, setFromInterestDate] = useState<string | null>(null);
  const [toDueDate, setToDueDate] = useState<string | null>(null);
  const {
    isLoading,
    isDetailLoading,
    colPendingInterestUpdate,
    colInterestUpdate,
    outStandingChargeInstalments,
    pendingInterests,
    resetStore,
    loadInterests,
  } = useAssessmentsInterestStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (!assessmentId) return;
    loadInterests(
      assessmentId,
      isOnlyShowWhenInterestApplies,
      fromInterestDate,
      toDueDate
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fromInterestDate,
    isOnlyShowWhenInterestApplies,
    toDueDate,
    assessmentId,
  ]);

  useEffect(() => {
    const pendingInterestsTotals: any = pendingInterests?.Totals;
    for (const totalItem in pendingInterestsTotals) {
      colPendingInterestUpdate.forEach((item) => {
        if (totalItem === item.field) {
          item.footerCell = (
            <CurrencyCell value={pendingInterestsTotals[totalItem]} />
          );
        }
      });
    }
  }, [colPendingInterestUpdate, pendingInterests]);

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Pending Interest",
      component: (
        <CCGrid
          isLoading={isDetailLoading}
          className="cc-interest-grid"
          data={pendingInterests?.PendingInterests as any[]}
          columnFields={colPendingInterestUpdate}
          primaryField="Id"
        />
      ),
    },
  ];

  const handleChangeFromInterestDate = (e: DatePickerChangeEvent) => {
    setFromInterestDate(e.value?.toISOString() as string);
  };

  const handleChangeToDueDate = (e: DatePickerChangeEvent) => {
    setToDueDate(e.value?.toISOString() as string);
  };

  const handleCheckBoxChange = (e: CheckboxChangeEvent) => {
    setIsOnlyShowWhenInterestApplies(e.value);
  };

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-property-panel-interest cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <div className="cc-grid-control-right">
              <Checkbox
                disabled={isDetailLoading}
                className="cc-control-item"
                title="Only show where interest applies"
                label="Only show where interest applies"
                checked={isOnlyShowWhenInterestApplies}
                onChange={(e: CheckboxChangeEvent) => {
                  handleCheckBoxChange(e);
                }}
              />
              <div className="cc-control-item">
                <Label className="cc-label-date">From Interest Date</Label>
                <Tooltip
                  className="tooltip-guide"
                  anchorElement="target"
                  position="top"
                  showCallout={false}
                >
                  <div
                    className="cc-icon-tooltip cc-icon-label-button fa fa-info-circle text-accent"
                    title={"Interest Date must be equal or less than Due Date"}
                  />
                </Tooltip>
                &nbsp;
                <CCDatePicker
                  disabled={isDetailLoading}
                  disabledDateInput={true}
                  format={DATE_FORMAT.DATE_CONTROL}
                  value={!fromInterestDate ? null : new Date(fromInterestDate)}
                  max={!toDueDate ? new Date() : new Date(toDueDate)}
                  onChange={(e: DatePickerChangeEvent) => {
                    handleChangeFromInterestDate(e);
                  }}
                />
              </div>
              <div className="cc-control-item">
                <Label className="cc-label-date">Up to Due Date</Label>
                <Tooltip
                  className="tooltip-guide"
                  anchorElement="target"
                  position="top"
                  showCallout={false}
                >
                  <div
                    className="cc-icon-tooltip cc-icon-label-button fa fa-info-circle text-accent"
                    title={
                      "Due Date must be equal or greater than Interest Date"
                    }
                  />
                </Tooltip>
                &nbsp;
                <CCDatePicker
                  disabled={isDetailLoading}
                  disabledDateInput={true}
                  format={DATE_FORMAT.DATE_CONTROL}
                  value={!toDueDate ? null : new Date(toDueDate)}
                  min={
                    !fromInterestDate ? undefined : new Date(fromInterestDate)
                  }
                  onChange={(e: DatePickerChangeEvent) => {
                    handleChangeToDueDate(e);
                  }}
                />
              </div>
            </div>
            <label className="cc-label">Outstanding charge instalments</label>
            <CCGrid
              isLoading={isDetailLoading}
              className="cc-interest-grid"
              data={outStandingChargeInstalments?.ChargeInstalments as any[]}
              columnFields={colInterestUpdate}
              primaryField="Id"
              detail={InterestDetails}
            />
          </div>
          <div className="cc-field">
            <div className="custom-sub-panel-bar">
              <CustomPanelBar listPanelBar={listPanelBar} sort={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
