import { eComponent } from "@app/products/property/components/associations/model";
import { colDocumentsIssued } from "@app/products/property/components/grid/document-issued-grid/config";
import { DTO_Document } from "@app/products/property/components/grid/document-issued-grid/model";
import { useDocumentIssuedStore } from "@app/products/property/components/grid/document-issued-grid/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
const nameOfDocument = nameOfFactory<DTO_Document>();

interface IDocumentsIssuedGridProps {
  componentNumber: eComponent;
  id?: number | string;
  documentTypeId?: number | string;
}

export const DocumentsIssuedGrid = observer(
  (props: IDocumentsIssuedGridProps) => {
    const { componentNumber, id, documentTypeId } = props;
    const {
      isLoading,
      documentsIssued,
      responseLoadError,
      loadDocumentsIssued,
      resetStore,
      documentIssueSelectedRow,
      setDocumentIssueSelectedRow,
    } = useDocumentIssuedStore();

    useEffect(() => {
      if (id) loadDocumentsIssued(componentNumber, id, documentTypeId);
      return () => {
        resetStore();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    if (responseLoadError && isLoading)
      return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            if (id) loadDocumentsIssued(componentNumber, id);
          }}
        />
      );
    return (
      <CCGrid
        className="cc-documents-issued-grid"
        data={documentsIssued}
        columnFields={colDocumentsIssued}
        primaryField={nameOfDocument("Component_Document_Id")}
        selectableMode="single"
        onSelectionChange={(dataItem: DTO_Document[]) => {
          setDocumentIssueSelectedRow(dataItem?.[0]);
        }}
        selectedRows={
          documentIssueSelectedRow ? [documentIssueSelectedRow] : []
        }
        isLoading={isLoading}
        state={{
          sort: [
            { field: nameOfDocument("Component_Document_Id"), dir: "desc" },
          ],
        }}
      />
    );
  }
);
