import {
  IPropertyContactTabCommonProps,
  PropertyContactTabCommon,
} from "@app/products/property/components/reference-sidebar/contact/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const PropertyContactTab = observer(
  ({ id, componentNumber }: IPropertyContactTabCommonProps) => {
    const { lastSelectedId } = useCCProductListViewStore();
    const recordId = id ?? lastSelectedId;
    return (
      <PropertyContactTabCommon
        id={recordId}
        componentNumber={componentNumber}
      />
    );
  }
);
