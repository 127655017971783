import { getViewConfigurations } from "@app/products/property/api";
import {
  ICCViewConfiguration,
  ViewConfiguration,
} from "@app/products/property/model";
import { DTO_PIC_Folio } from "@app/products/property/pic/[id]/components/child-screens/folios/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetFoliosResponse =
  | [APIResponse<DTO_PIC_Folio>, APIResponse<ICCViewConfiguration | undefined>]
  | APIResponse<DTO_PIC_Folio | ICCViewConfiguration>
  | undefined;

export const getFolios = async (picId: number): Promise<IGetFoliosResponse> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_PIC_Folio>(
        `/api/property/int/pic/${picId}/folios`
      ),
      getViewConfigurations(ViewConfiguration.PIC_Folios),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
