import { ConfirmReadingDialog } from "@app/products/property/meters/components/dialogs/confirm-reading/_index";
import {
  IEnterReadingDialogData,
  MAX_READING,
} from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/model";
import { IReadingGridData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { handleMeterReadingValidateForm } from "@app/products/property/meters/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { differenceInCalendarDays, subDays } from "date-fns";
import React, { useState } from "react";
import "../../../_index.scss";

interface IEnterReadingDialogProps {
  onClose: () => void;
  readingValue: IEnterReadingDialogData;
  onSubmit: (value: any) => void;
}

const nameOf = nameOfFactory<IEnterReadingDialogData>();

export const EnterReadingDialog = ({
  onClose,
  readingValue,
  onSubmit,
}: IEnterReadingDialogProps) => {
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const handleCurrentReadingChange = (
    formRenderProps: FormRenderProps,
    event: NumericTextBoxChangeEvent
  ) => {
    if (typeof event.value === "number") {
      const previousReading: number =
        formRenderProps.valueGetter("PreviousReading");
      const currentReading: number = event.value;

      if (previousReading) {
        formRenderProps.onChange("Usage", {
          value:
            currentReading >= previousReading
              ? currentReading - previousReading
              : MAX_READING - previousReading + currentReading,
        });
      }

      formRenderProps.onChange("CurrentReading", { value: event.value });
    }
  };

  const handleCurrentReadingDateChange = (
    formRenderProps: FormRenderProps,
    event: DateTimePickerChangeEvent
  ) => {
    if (event.value?.getTime()) {
      formRenderProps.onChange("NumberOfDays", {
        value: Math.max(
          differenceInCalendarDays(
            event.value,
            formRenderProps.valueGetter("PreviousReadingDate")
          ),
          0
        ),
      });
      formRenderProps.onChange("CurrentReadingDate", { value: event.value });
    }
  };

  const onSendData = (formRenderProps: FormRenderProps) => {
    const data: IReadingGridData = {
      Usage: formRenderProps.valueGetter("Usage"),
      CurrentReadingDate: formRenderProps.valueGetter("CurrentReadingDate"),
      CurrentReading: formRenderProps.valueGetter("CurrentReading"),
      Comment: formRenderProps.valueGetter("Comment"),
      MeterInstallationId: formRenderProps.valueGetter("MeterInstallationId"),
    };

    onSubmit(data);
  };

  const handleValidateForm: any = (
    event: React.SyntheticEvent<any>,
    formRenderProps: FormRenderProps,
    onEnterAndExit: (formRenderProps: FormRenderProps) => void
  ) => {
    if (!formRenderProps.valid) return;

    const currentReading: number =
      formRenderProps.valueGetter("CurrentReading");
    const previousReading: number =
      formRenderProps.valueGetter("PreviousReading");

    if (currentReading <= previousReading || currentReading > MAX_READING) {
      setShowWarningDialog(true);
    } else {
      formRenderProps.onSubmit(event);
      onEnterAndExit(formRenderProps);
    }
  };

  return (
    <Form
      initialValues={readingValue}
      validator={handleMeterReadingValidateForm}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="45%"
              height="auto"
              titleHeader={"Enter Reading"}
              onClose={onClose}
              bodyElement={
                <div className="cc-special-reading-dialog cc-form">
                  <div className="cc-field-group">
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Reading status</label>
                        <Field
                          name={nameOf("ReadingStatus")}
                          data={valueGetter("_option.ReadingStatus.Data") || []}
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Reading officer</label>
                        <Field
                          name={nameOf("ReadingOfficer")}
                          data={
                            valueGetter("_option.ReadingOfficer.Data") || []
                          }
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                        />
                      </div>
                    </div>

                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Previous reading date
                        </label>
                        <Field
                          name={nameOf("PreviousReadingDate")}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                          component={CCDateTimePicker}
                          disabled
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Previous reading</label>
                        <Field
                          name={nameOf("PreviousReading")}
                          disabled
                          component={CCNumericTextBox}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2 ">
                      <div className="cc-field ">
                        <label className="cc-label">
                          Current reading date
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={"CurrentReadingDate"}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                          min={
                            valueGetter("PreviousReadingDate")
                              ? subDays(valueGetter("PreviousReadingDate"), 1)
                              : undefined
                          }
                          onChange={(event: DateTimePickerChangeEvent) => {
                            handleCurrentReadingDateChange(
                              formRenderProps,
                              event
                            );
                          }}
                          component={CCDateTimePicker}
                          max={new Date()}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Current reading
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("CurrentReading")}
                          component={CCNumericTextBox}
                          onChange={(event: NumericTextBoxChangeEvent) => {
                            handleCurrentReadingChange(formRenderProps, event);
                          }}
                          min={0}
                        />
                      </div>
                    </div>

                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Number of days</label>
                        <Field
                          name={nameOf("NumberOfDays")}
                          component={CCNumericTextBox}
                          disabled
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Usage</label>
                        <div className="cc-custom-input-group">
                          <Field
                            disabled
                            name={nameOf("Usage")}
                            component={CCNumericTextBox}
                          />
                          <div className="cc-input-group-postfix">
                            {valueGetter("UnitOfMeasure")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Comment</label>
                        <Field
                          name={nameOf("Comment")}
                          component={CCTextArea}
                          rows={3}
                          placeholder={"Comment"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    name="EnterAndExit"
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    type="button"
                    onClick={(e) => {
                      handleValidateForm(e, formRenderProps, onSendData);
                    }}
                    disabled={!formRenderProps.valid}
                  >
                    Enter
                  </Button>
                </div>
              }
            />
            {showWarningDialog && (
              <ConfirmReadingDialog
                onClosePopup={() => {
                  setShowWarningDialog(false);
                }}
                onConfirmYes={() => {
                  setShowWarningDialog(false);
                  onSendData(formRenderProps);
                }}
              />
            )}
          </FormElement>
        );
      }}
    />
  );
};
