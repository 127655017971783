import { VO_Assessment_Collection } from "@app/products/property/assessments/collections/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment_Collection>();
export const colAssessCollection: IColumnFields[] = [
  {
    field: nameOf("Collection_Name"),
    title: "Collection Name",
    locked: true,
  },
  {
    field: nameOf("Collection_Type_Name"),
    title: "Collection Type",
  },
  {
    field: nameOf("Collection_Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Collection_Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("Collection_Created_On"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("LV_Name"),
    title: "List View Name",
  },
  {
    field: nameOf("Collection_Type"),
    title: "Collection Type",
  },
  {
    field: nameOf("Collection_Id"),
    title: "Collection Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
