import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { ExistedSecurityCorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/components/forms/existed/_index";
import { NewSecurityCorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/components/forms/new/_index";
import { ISecurityCorporateAuthNotification } from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { useCorporateAuthStore } from "@common/pages/settings/security/corporate-authorisations/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCorporateAuth = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const corporateAuthId = parseInt(params.id);
  const { resetStore, loadCorporateAuth, setCorporateAuthNotification } =
    useCorporateAuthStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setCorporateAuthNotification(
        location.state as ISecurityCorporateAuthNotification
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    if (isHasPermission) {
      loadCorporateAuth(corporateAuthId, isNew);
    }
  }, [isNew, loadCorporateAuth, corporateAuthId, isHasPermission]);

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.SECURITY_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      {isNew ? <NewSecurityCorporateAuth /> : <ExistedSecurityCorporateAuth />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageCorporateAuth);
