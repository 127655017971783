export interface IDisassociateMeter {
  ID?: number | null;
  MeterNumber?: string;
  PropertyAddress?: string;
  AccountNames?: string;
  RemoveOptions?: string;
  DateOfRemoval?: Date | null;
  LastReading?: number | null;
}

export enum RemoveOptions {
  RemoveFromProperty = "RemoveFromProperty",
  DeleteAssociation = "DeleteAssociation",
}

export interface IInitialDisassociateMeter {
  Id?: number;
  Utility?: string;
  MeterNumber?: string;
  ReadingRoute?: string;
  SequenceInRoute?: number;
  Category?: string;
  UsagePercentage?: number;
  LastReading?: number;
  LastRead?: number;
  DateAttached?: number;
  NextReadDate?: number;
  ReadingFrequency?: number;
  AverageDaily?: number;
  UnitOfMeasure?: string;
  MeterLocation?: string;
  InstallationDate?: number;
  MeterSize?: number;
  Status?: string;
  MeterId?: number;
  ScheduleRead?: boolean;
  BillingGroup?: string;
  selected?: boolean;
  expanded?: boolean;
  RemoveOptions?: string;
  DateOfRemoval: Date;
}
