import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { actionsLettersRoute } from "./actions-or-letters/route";
import { listRoute } from "./list/route";
import { paymentsRoute } from "./payments/route";

export const debtRecoveryRoute: ICCRoute = {
  path: "debt-recovery",
  name: "Debt Recovery",
  enum: eMenuAction.DebtRecovery_Folder,
  children: [
    listRoute,
    {
      path: "candidates",
      name: "Candidates",
      enum: eMenuAction.DebtRecovery_ListCandidates,
      component: require("./candidates/_index").default,
    },
    actionsLettersRoute,
    paymentsRoute,
    {
      path: "exemptions",
      name: "Exemptions",
      enum: eMenuAction.DebtRecovery_ListExemptions,
      component: require("./exemptions/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
