import { ApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { ActionType } from "@common/models/actionType";
import { cloneDeep, isArray } from "lodash";

export const convertToFormData = (initialData: ApplicationCategory) => {
  if (isArray(initialData.WorkFlowAutoActionTypes)) {
    const convertActionType = initialData.WorkFlowAutoActionTypes.map(
      (item: ActionType) => {
        return {
          ID: item.ActionType_ID,
          Name: item.Name,
          Description: item.Description,
          Flag_HasOutcome: item.Flag_HasOutcome,
          IsApproval: item.IsApproval,
          IsReferral: item.Flag_IsReferral,
          IsExternal: item.Flag_ExternalOfficer,
          TargetDays: item.TargetDays,
          RestrictActionOfficerSelection: item.RestrictActionOfficerSelection,
          DefaultActionOfficer: item.DefaultActionOfficer,
          CreatedDate: item.Sys_CreatedDate,
          CreatedBy: item.Sys_CreatedBy,
          SystemFilter_ENUMS: item.SystemFilter_ENUMS,
          Flag_UserSelection: item.Flag_UserSelection,
          Flag_RegulatoryAction: item.IsRegulatoryAction,
        };
      }
    );
    const newApplicationCategory: any = cloneDeep(initialData);
    newApplicationCategory.WorkFlowAutoActionTypes = convertActionType;
    return newApplicationCategory;
  }
};
