import {
  ChargeBalancesName,
  colAllocationToChargeBalances,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/allocations-to-charge-balances/config";
import { DTO_ChargeBalance } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React from "react";

export const AllocationToChargeBalancesAddTransactionFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfChargeBalances = nameOfFactory<DTO_ChargeBalance>();

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options,
}: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  //get value
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const chargeBalanceData = getFieldValue("ChargeBalance") ?? [];

  /**
   * handle onChange edit cell
   * @param dataRow
   */
  const onDataRowChange = (dataRow: DTO_ChargeBalance) => {
    let chargeBalanceIds = getFieldValue("_option.ChargeBalancesIDs") ?? [];
    const ids = new Set(chargeBalanceIds);
    if (
      !isNil(dataRow[ChargeBalancesName.AdjustInterest]) ||
      !isNil(dataRow[ChargeBalancesName.AdjustedAmount])
    ) {
      ids.add(dataRow.Charge_Balances_Id);
      onChange(nameOf("_option.ChargeBalancesIDs"), { value: Array.from(ids) });
    } else if (
      isNil(dataRow[ChargeBalancesName.AdjustInterest]) &&
      isNil(dataRow[ChargeBalancesName.AdjustedAmount])
    ) {
      const listRemoveChangeIds = chargeBalanceIds?.filter(
        (item: number) => item !== dataRow.Charge_Balances_Id
      );
      onChange(nameOf("_option.ChargeBalancesIDs"), {
        value: listRemoveChangeIds,
      });
    }
  };

  return (
    <section className="cc-form-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Allocation to charge balances</label>
          <CCGrid
            className="cc-allocation-to-charge-balances-grid"
            data={chargeBalanceData ?? []}
            columnFields={colAllocationToChargeBalances}
            primaryField={nameOfChargeBalances("Charge_Balances_Id")}
            editableMode={!options.isReadOnly ? "row" : undefined}
            readOnly={options.isReadOnly}
            onDataRowChange={onDataRowChange}
            onDataChange={(data) => {
              onChange(nameOf("ChargeBalance"), { value: data });
            }}
            itemPerPage={10}
          />
        </div>
      </div>
    </section>
  );
};
