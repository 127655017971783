import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";

export const postUpdatePermitRenewalNoticeSent = async (
  permitIDs: number[],
  isFlag: boolean
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/locallaw/internal/renewals/update-flag-renewal-notice-sent?flag=${isFlag}`,
      permitIDs
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
