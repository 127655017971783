import { EventManagementForm } from "@app/products/event-management/[id]/components/child-screens/general/_index";
import { useEventManagementStore } from "@app/products/event-management/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import React from "react";

export const NewManageEventManagement = () => {
  const { isLoading } = useEventManagementStore();
  return (
    <div className="cc-new-event-management">
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Event" />
      <FormTitle title={"New Event"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Confirm event"} />
            <CCNavButton title={"Decline event"} />
            <CCNavButton title={"Add to group booking"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Add comments"} />
            <CCNavButton title={"Add contacts"} />
            <CCNavButton title={"Add documents"} />
            <CCNavButton title={"Add fees"} />
            <CCNavButton title={"Add samples"} />
            <CCNavButton title={"Add inspection"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
          <CCNavButton title={"More Options"} type="more">
            <CCNavButton title={"View invoices"} />
          </CCNavButton>,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <CCGeneralPanel component={<EventManagementForm />} />
          </div>
        </div>
      </div>
    </div>
  );
};
