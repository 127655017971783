import { BuildingUrl, OfficerColumns } from "@app/products/building/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export const officerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Officer",
  readOnly: false,
  placeholder: "Officer",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  value: formRenderProps.valueGetter("Officer"),
  onChange: (event: any) => {
    formRenderProps.onChange("Officer", {
      value: event.target.value,
    });
  },
});
