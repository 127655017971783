import { history } from "@/AppRoutes";
import { SAVE_SETTINGS } from "@common/configs/setting";
import { EXPORT_FORMAT } from "@common/constants/export-format";
import {
  action,
  configure,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { createContext } from "react";
import { globalStoreInstance } from "../stores/global/store";
import { CoreAPIService } from "./coreAPIService";
configure({ enforceActions: "always" }); // Strict mode: runInAction
class ActionBarStoreService {
  isShowProperty = false;
  selectedDataRow = null;
  isShowFilterSidebar = false;
  isBookmarkToggle = false;
  isBookmark = false;
  exportFormat = "";
  isShowNotificationError = false;
  notificationMessage = "";
  mailmergeSelection = {};
  selectedCalendarFilter: string[] = [];

  setNotificationMessage = (value: string) => {
    runInAction(() => {
      this.notificationMessage = value;
    });
  };

  setMailmergeSelection = (value: {}) => {
    runInAction(() => {
      this.mailmergeSelection = value;
    });
  };

  setIsShowProperty = (value: boolean) => {
    runInAction(() => {
      this.isShowProperty = value;
    });
  };

  setIsShowNotificationError = (value: boolean) => {
    runInAction(() => {
      this.isShowNotificationError = value;
    });
  };

  setSelectedDataRow = (value: any) => {
    runInAction(() => {
      this.selectedDataRow = value;
    });
  };

  setIsShowFilterSidebar = (value: boolean) => {
    runInAction(() => {
      this.isShowFilterSidebar = value;
    });
  };

  setIsBookmarkToggle = (value: boolean) => {
    runInAction(() => {
      this.isBookmarkToggle = value;
    });
  };

  setIsBookmark = (value: boolean) => {
    runInAction(() => {
      this.isBookmark = value;
    });
  };

  setExportFormat = (type: EXPORT_FORMAT) => {
    runInAction(() => {
      this.exportFormat = type;
    });
  };

  togglePremisesRegisterSidebar = () => {
    runInAction(() => {
      this.isShowProperty = !this.isShowProperty;
    });
  };

  toggleFilterSidebar = () => {
    runInAction(() => {
      this.isShowFilterSidebar = !this.isShowFilterSidebar;
    });
  };

  setCalendarFilters = (filters: string[]) => {
    runInAction(() => {
      this.selectedCalendarFilter = filters;
    });
  };

  constructor() {
    makeObservable(this, {
      isShowProperty: observable,
      selectedDataRow: observable,
      isShowFilterSidebar: observable,
      isBookmarkToggle: observable,
      isBookmark: observable,
      exportFormat: observable,
      isShowNotificationError: observable,
      notificationMessage: observable,
      mailmergeSelection: observable,
      selectedCalendarFilter: observable,
      setNotificationMessage: action,
      setMailmergeSelection: action,
      setIsShowProperty: action,
      setIsShowNotificationError: action,
      setSelectedDataRow: action,
      setIsShowFilterSidebar: action,
      setIsBookmarkToggle: action,
      setIsBookmark: action,
      setExportFormat: action,
      togglePremisesRegisterSidebar: action,
      toggleFilterSidebar: action,
      setCalendarFilters: action,
    });
  }
}
export default createContext(new ActionBarStoreService());

export const SaveSettings = async (requestBody: any) => {
  const response = await CoreAPIService.getClient().put(
    `${globalStoreInstance.allSetting.baseAPIUrl}${SAVE_SETTINGS.replace(
      "{id}",
      requestBody.Registration_ID.toString()
    )}`,
    requestBody
  );
  if (!response) {
    return undefined;
  }

  history.replace(window.location.pathname, {
    status: "success",
  });
  history.go(0);
  return response.data;
};
