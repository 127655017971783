import { ECustomColNameProperty } from "@app/products/property/config";
import {
  ICCViewColumn,
  ICCViewConfiguration,
} from "@app/products/property/model";
import { getTitle } from "@app/products/property/titles/[id]/components/child-screens/associated-titles/api";
import { colAssociatedTitles } from "@app/products/property/titles/[id]/components/child-screens/associated-titles/config";
import {
  DTO_Title,
  DTO_Title_AssociatedTitle,
} from "@app/products/property/titles/[id]/components/child-screens/associated-titles/model";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

const nameOf = nameOfFactory<DTO_Title>();
export const AssociateTitle = observer(() => {
  const { titleId } = useTitleStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [titleData, setTitleData] = useState<DTO_Title[] | undefined>(
    undefined
  );
  const [processedTitleCol, setProcessedTitleCol] =
    useState<IColumnFields[]>(colAssociatedTitles);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const titleLowercaseLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.TitleLowercase
  );

  const loadData = (titleId: number) => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    getTitle(titleId || 0).then((response) => {
      if (Array.isArray(response)) {
        const [titleResponse, viewConfigResponse] = response;
        const titleResponseData = titleResponse?.data;
        const viewConfigResponseData = viewConfigResponse?.data;
        if (
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1]) &&
          titleResponseData &&
          viewConfigResponseData
        ) {
          const title: DTO_Title[] | undefined =
            titleResponseData.AssociatedTitles;
          const viewConfig: ICCViewColumn[] | undefined =
            viewConfigResponseData.ColumnDefinitions?.Columns;
          //Process dynamic columns
          setProcessedTitleCol(
            processDynamicColumns(colAssociatedTitles, viewConfig)
          );
          setTitleData(title);
        } else {
          let responseError: APIResponse<
            DTO_Title_AssociatedTitle | ICCViewConfiguration | undefined
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          setResponseLoadError({
            status: responseError.status,
            error: `Load ${titleLowercaseLabel} failed.`,
          });
        }
      } else {
        setResponseLoadError(response);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (titleId) {
      loadData(titleId);
    }
    // eslint-disable-next-line
  }, [titleId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (titleId) {
            loadData(titleId);
          }
        }}
      />
    );
  }

  return (
    <CCGrid
      data={titleData || []}
      columnFields={processedTitleCol}
      primaryField={nameOf("Id")}
    />
  );
});
