import { IAddAttachmentDialog } from "@app/core/attachment/components/dialogs/model";
import { checkIsValidFile } from "@app/core/attachment/components/dialogs/util";

import { CoreKeyword } from "@common/models/coreKeyword";
import { IntegratedReportImport } from "@common/pages/report/integrated-reports/component/buttons/export-xml/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import "./_index.scss";
export interface IImportReportDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isLoadingButton?: boolean;
  isErrorAPI?: string;
}

export const ImportReportDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoadingButton,
    isErrorAPI,
  }: IImportReportDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [filePreview, setFilePreview] = useState<any[]>([]);
    const [isError, setIsError] = useState(false);
    const nameOf = nameOfFactory<IAddAttachmentDialog>();
    useState<CoreKeyword[]>();
    const allowedExtensions = ["xml"];
    useEffect(() => {
      if (isErrorAPI) {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: isErrorAPI ?? "Importing report failed.",
          type: "error",
        });
      }
    }, [isErrorAPI]);
    return (
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, valueGetter } = formRenderProps;
          const isDisabled =
            !formRenderProps.valid ||
            !checkIsValidFile(valueGetter(nameOf("File"))) ||
            isError;
          const handleAddFile = async (event: UploadOnAddEvent) => {
            onChange(nameOf("File"), {
              value: [Object.assign(event.newState[0], {})],
            });
            if (
              !event.newState[0]?.validationErrors?.includes(
                "invalidFileExtension"
              )
            ) {
              setIsError(false);
              if (event?.newState[0].getRawFile) {
                let xmlProcessedData: any = [];
                let payload: IntegratedReportImport[] = [];
                const parser = new DOMParser();
                const xml = parser.parseFromString(
                  await event?.newState[0]?.getRawFile().text(),
                  "text/xml"
                );

                const newXml =
                  xml?.getElementsByTagName("Reports")?.[0]?.children;
                if (newXml) {
                  setIsError(false);
                  for (var i = 0; i < newXml.length; i++) {
                    let newElementXml: any = {};
                    let newElementPayload: IntegratedReportImport =
                      new IntegratedReportImport();
                    newXml?.[i].childNodes.forEach((element: any) => {
                      Object.keys(newElementPayload).forEach((key: string) => {
                        if (element.nodeName === key && element.textContent) {
                          switch (key) {
                            case "IzendaAdHocReportsID": {
                              newElementPayload["IzendaAdHocReportsID"] =
                                parseInt(element.textContent);
                              break;
                            }
                            case "ReportSourceID": {
                              newElementPayload["ReportSourceID"] = parseInt(
                                element.textContent
                              );
                              break;
                            }
                            case "IsDashboard": {
                              newElementPayload["IsDashboard"] =
                                element.textContent === "true";
                              break;
                            }
                            default: {
                              (newElementPayload[
                                key as keyof IntegratedReportImport
                              ] as string) = element.textContent;
                              break;
                            }
                          }
                        }
                      });
                      if (
                        element.nodeName === "Category" &&
                        element.textContent
                      ) {
                        newElementXml["Category"] = element.textContent;
                      }
                      if (
                        element.nodeName === "ReportName" &&
                        element.textContent
                      ) {
                        newElementXml["ReportName"] = element.textContent;
                      }
                    });
                    Object.keys(newElementPayload).forEach(
                      (key) =>
                        newElementPayload[
                          key as keyof IntegratedReportImport
                        ] === undefined &&
                        delete newElementPayload[
                          key as keyof IntegratedReportImport
                        ]
                    );
                    xmlProcessedData.push(newElementXml);
                    payload.push(newElementPayload);
                  }
                } else {
                  setIsError(true);
                  notificationRef.current?.pushNotification({
                    autoClose: false,
                    title: "Importing report failed. Invalid XML format",
                    type: "error",
                  });
                }

                setFilePreview(xmlProcessedData);
                onChange("FileData", {
                  value: payload,
                });
              }
            } else {
              setIsError(true);
              onChange("FileData", {
                value: undefined,
              });
            }
          };
          return (
            <CCDialog
              titleHeader={"Import Reports"}
              height={"auto"}
              maxWidth={"35%"}
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          File
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("File")}
                          component={CCUploadFile}
                          autoUpload={false}
                          batch={false}
                          multiple={false}
                          defaultFiles={[]}
                          withCredentials={false}
                          files={valueGetter(nameOf("File"))}
                          restrictions={{
                            allowedExtensions: allowedExtensions,
                            maxFileSize: 5000000,
                          }}
                          onAdd={handleAddFile}
                          onRemove={(event: UploadOnRemoveEvent) => {
                            onChange(nameOf("File"), {
                              value: event.newState,
                            });
                            setFilePreview([]);
                          }}
                          showActionButtons={false}
                          validator={requiredValidator}
                        />
                      </div>
                      {filePreview?.length > 0 && !isError && (
                        <>
                          <div className="cc-preview-report">Preview</div>
                          <div className="cc-preview-report-ol">
                            <ol className="cc-custom-import-report">
                              {filePreview.map((item, index) => (
                                <li key={index}>
                                  <span>{item?.Category}\</span>
                                  <span>{item?.ReportName}</span>
                                  <span> will be created.</span>
                                </li>
                              ))}
                            </ol>
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={isDisabled || isLoadingButton}
                    className={"cc-dialog-button"}
                    type={"submit"}
                    onClick={formRenderProps.onSubmit}
                    iconClass={isLoadingButton ? "fas fa-spinner fa-spin" : ""}
                  >
                    Import
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
