export interface IPromptObsolete {
  isObsolete: boolean;
}

export enum EHasCommunityGroup {
  Yes,
  No,
  Unknown,
}

export enum ePreferredContactMethod {
  None = 0,
  Work_Phone = 1,
  Home_Phone = 2,
  Mobile = 5,
  Email = 3,
  TTY = 8,
}
