import { useAssessmentRatingStore } from "@app/products/property/assessments/[id]/components/child-screens/rating/store";
import { Input } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export const RatingMasterProperty = observer(() => {
  const { assessment } = useAssessmentRatingStore();
  return (
    <div className="cc-field-group cc-master-property">
      <label className="cc-label">Master property</label>
      <div className="cc-custom-sub-panel-bar">
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Input
                className="cc-form-control"
                value={assessment?.MasterPropertyType}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Unit entitlement</label>
              <Input
                className="cc-form-control"
                value={assessment?.UnitEntitlementAsString}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
