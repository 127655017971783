import { odataGetRelatedRecordsWithRecordType } from "@app/core/components/common/utils";
import {
  IRelatedData,
  RelatedRecord,
  Related_Relationship,
} from "@app/core/related/related-dialog/model";
import { colAddRelated } from "@app/products/waste-water/[id]/components/dialog/related/config";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAddRelatedDialogProps {
  sourceID?: number;
  sourceRecordType: RECORDTYPE;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>;
}

const nameOf = nameOfFactory<IRelatedData>();
export const AddRelatedDialog = observer(
  ({
    sourceID,
    sourceRecordType,
    isLoading,
    onClose,
    onSubmit,
    notificationRef,
  }: IAddRelatedDialogProps) => {
    const handleSubmit = (event: FormSubmitClickEvent) => {
      if (sourceID) {
        const data: IRelatedData[] = event.values.listSelect as IRelatedData[];
        const relatedRecords: RelatedRecord[] = data.map(
          (record: IRelatedData) => {
            return {
              Related_Id: 0,
              Relationship_Int: Related_Relationship.Manual,
              Source_Record_ID: sourceID,
              Source_RecordType_Int: sourceRecordType,
              Related_Record_ID: record.Id,
              Related_RecordType_Int: record.RecordType_ENUM,
            };
          }
        );

        onSubmit(relatedRecords);
      }
    };

    return (
      <Form
        initialValues={{ listData: [], listSelect: [] }}
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange, valueGetter, onSubmit } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                height="auto"
                maxWidth="55%"
                titleHeader={"Pick Related Records"}
                onClose={onClose}
                disabled={isLoading}
                bodyElement={
                  <>
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <CCGrid
                              dataUrl={odataGetRelatedRecordsWithRecordType(
                                sourceRecordType
                              )}
                              selectableMode={"multiple"}
                              columnFields={colAddRelated}
                              primaryField={nameOf("Id")}
                              itemPerPage={10}
                              onSelectionChange={(data) => {
                                onChange("listSelect", {
                                  value: data,
                                });
                              }}
                              state={{
                                sort: [
                                  { field: nameOf("CreatedDate"), dir: "desc" },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>

                    <Button
                      className={"cc-dialog-button"}
                      onClick={onSubmit}
                      themeColor="primary"
                      type="submit"
                      disabled={valueGetter("listSelect").length === 0}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      Select
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
