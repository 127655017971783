import { DTO_Workflow_JournalAssessmentRaiseInterest } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export type IGetInitialDataJournalAssessmentRaiseInterest =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAssessmentRaiseInterest>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalAssessmentRaiseInterest>
  | undefined;

export const getInitialDataJournalAssessmentRaiseInterest = async (
  workflowDraftId: number | undefined,
  assessmentId?: number
): Promise<IGetInitialDataJournalAssessmentRaiseInterest> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        "/api/property/internal/journal/lovs"
      ),
      !isNil(workflowDraftId)
        ? getWorkflowJournalAssessmentRaiseInterest(workflowDraftId)
        : getNewWorkflowJournalAssessmentRaiseInterest(assessmentId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowJournalAssessmentRaiseInterest = async (
  assessmentId?: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentRaiseInterest>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/assessmentraiseinterest/new`,
      assessmentId ? { AssessmentId: assessmentId } : {}
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowJournalAssessmentRaiseInterest = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentRaiseInterest>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/assessmentraiseinterest/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessJournalAssessmentRaiseInterest = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_JournalAssessmentRaiseInterest | undefined
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_JournalAssessmentRaiseInterest>
  >
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/assessmentraiseinterest/process/${workflowType} `,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
