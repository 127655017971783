import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { OrgUnit } from "@components/cc-pick-org-units/model";

const nameOf = nameOfFactory<OrgUnit>();
export const colPickOrgUnits: IColumnFields[] = [
  {
    field: nameOf("Hierarchy"),
    title: "Hierarchy",
  },
  {
    field: nameOf("OrgLevel"),
    title: "Org Level",
  },
  {
    field: nameOf("OrgUnit_Name"),
    title: "Org Unit Name",
  },
  {
    field: nameOf("Manager"),
    title: "Manager",
  },
];
