import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

interface RequestQueueCreditReallocationObj {
  TaskName?: string;
  AssessmentsList?: string;
}

export const postReallocationOfCredits = async (
  requestBody?: RequestQueueCreditReallocationObj
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `api/property/internal/creditreallocation/queuetask`,
      { AssessmentsList: requestBody?.AssessmentsList ?? "" }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
