import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsSystemAdminPropertyRestrictions: IColumnFields[] =
  [
    {
      field: ParkingPermitsFieldMapping.FullAddress,
      title: "Property",
      locked: true,
      linkTo: (dataItem: any) => {
        return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
      },
      width: 450,
    },
    {
      field: ParkingPermitsFieldMapping.AssessmentNo,
      title: "Property ID Number",
      width: 450,
      format: NUMBER_FORMAT.NUMBER2,
    },
    { field: ParkingPermitsFieldMapping.Reason, title: "Reason", width: 450 },
    {
      field: ParkingPermitsFieldMapping.Sys_CreatedBy,
      title: "Created By",
      width: 450,
    },
  ];
