import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { CommunicationTemplateFormElement } from "@common/pages/settings/communication/template/_id/components/child-screens/general/components/form-element/_index";
import {
  CommunicationTemplate,
  CommunicationTemplateSubmitActions,
} from "@common/pages/settings/communication/template/_id/model";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const CommunicationTemplateForm = observer(() => {
  const { communicationTemplate, setOnSubmit, saveCommunicationTemplate } =
    useCommunicationTemplateStore();
  const { pushNotification } = useCCAppNotificationStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const action = event.event?.currentTarget
      .name as CommunicationTemplateSubmitActions;
    const { values, isModified, isValid } = event;
    if (!(action in CommunicationTemplateSubmitActions)) return;
    if (!isValid) return;
    if (!isModified && action === CommunicationTemplateSubmitActions.Save)
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    if (isNil(values.ShowInCommunicationDialog)) {
      values.ShowInCommunicationDialog = false;
    }
    values.EmailBody = decryptionHtmlForEditor(values?.EmailBody ?? "");
    saveCommunicationTemplate(values as CommunicationTemplate, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, communicationTemplate]);
  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={communicationTemplate}
        key={JSON.stringify(communicationTemplate)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <CommunicationTemplateFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
