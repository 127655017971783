import { mockInitialValuesParcelConstraints } from "@app/products/property/parcels/[id]/components/child-screens/constraints/components/dialogs/mock";
import { ParcelConstraintsEventType } from "@app/products/property/parcels/[id]/components/child-screens/constraints/constant";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useState } from "react";

export const ParcelConstraintsDialog = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  useAddUniqueEventEmitter([
    {
      eventType: ParcelConstraintsEventType.constraintsDetails,
      listener: (parcelConstraintsId: number) => {
        setIsShowDialog(true);
      },
    },
  ]);

  return isShowDialog ? (
    <Form
      initialValues={mockInitialValuesParcelConstraints}
      render={() => {
        return (
          <CCDialog
            height="auto"
            maxWidth="40%"
            titleHeader={"Constraints Details"}
            onClose={() => setIsShowDialog(false)}
            bodyElement={
              <FormElement>
                <div className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Name</label>
                        <Field
                          name="Name"
                          component={CCInput}
                          placeholder="Name"
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Code</label>
                        <Field
                          name="Code"
                          component={CCInput}
                          placeholder="Code"
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Header</label>
                        <Field
                          name="Header"
                          placeholder="Header"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Text</label>
                        <Field
                          name="Text"
                          placeholder="Text"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <br />
              </FormElement>
            }
          />
        );
      }}
    />
  ) : (
    <></>
  );
};
