import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { ICCValueFieldProps } from "@components/cc-value-field/_index";

export const colPaymentSchedule: IColumnFields[] = [
  {
    field: "InstalmentNumber",
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InstalmentAmount",
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "DatePaid",
    title: "Date Paid",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "AmountOwing",
    title: "Amounts Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];

export const colLettersSent: IColumnFields[] = [
  {
    field: "LetterType",
    title: "Type",
  },
  {
    field: "ProducedOn",
    title: "Produced On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ProducedBy",
    title: "Produced By",
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const detailFields: ICCValueFieldProps[] = [
  {
    name: "ArrangementNumber",
    label: "Arrangement number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    name: "ArrangementDate",
    label: "Arrangement date",
    format: DATE_FORMAT.DATE,
  },
  {
    name: "InitialDebt",
    label: "Initial debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    name: "Balance",
    label: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    name: "Overdue",
    label: "Overdue",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    name: "AuthorisingOfficer",
    label: "Authorising officer",
  },
  {
    name: "Applicant",
    label: "Applicant",
  },
  {
    name: "Status",
    label: "Status",
  },
  {
    name: "Interest",
    label: "Interest",
  },
];

export const colMemos: IColumnFields[] = [
  {
    field: "Memo",
    title: "Memo",
  },
  {
    field: "MemoDate",
    title: "Memo Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Category",
    title: "Category",
  },
  {
    field: "ChangedOn",
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "ChangedBy",
    title: "Changed By",
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
