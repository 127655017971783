import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import {
  ASSOCIATIONS_FORM_STEP,
  AssociationsFormStep,
} from "../components/form-elements/associations/_index";
import {
  ATTRIBUTES_FORM_STEP,
  AttributesFormStep,
} from "../components/form-elements/attributes/_index";
import {
  CONCESSION_CARDS_FORM_STEP,
  ConcessionCardsFormStep,
} from "../components/form-elements/concession-cards/_index";
import {
  PERSON_FORM_STEP,
  PersonFormStep,
} from "../components/form-elements/person/_index";
import {
  VOTING_FORM_STEP,
  VotingFormStep,
} from "../components/form-elements/voting/_index";
import {
  defaultAssociations,
  defaultConcessionCards,
  defaultPerson,
} from "./config";

interface IEntityDialogProps {
  onClose: () => void;
  onSubmit: (entity: any) => void;
  currentEntity?: any;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}

export const UpdateEntityDialog = ({
  onClose,
  onSubmit,
  currentEntity,
  isFromActionList = false,
  isIncompleteMode = false,
}: IEntityDialogProps) => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const [dataUpdate, setDataUpdate] = useState<any[]>(gridSelectedRows || []);

  useEffectOnce(() => {
    const listSelected = gridSelectedRows;
    setDataUpdate(listSelected);
  });

  const getInitSteps = () => {
    return dataUpdate?.map((item: any) => {
      return {
        ...item,
        Delivery: "Standard",
        Gender: item?.Gender === "Male" ? "M" : "F",
        Surname: item?.Name?.split(".")[0] ?? "",
        GivenName: item?.Name?.split(".")?.[1].trim() ?? "",
      };
    })?.[0];
  };

  const processedEntitiesItem = () => {
    if (currentEntity)
      return {
        ...currentEntity,
      };
    return {
      ...getInitSteps(),
    };
  };

  const steps: IStep[] = [
    {
      label: "Person",
      initialValues: { ...defaultPerson, ...processedEntitiesItem() },
      component: PersonFormStep,
      visible: true,
      key: PERSON_FORM_STEP,
    },
    {
      label: "Attributes",
      initialValues: processedEntitiesItem(),
      component: AttributesFormStep,
      visible: true,
      key: ATTRIBUTES_FORM_STEP,
    },
    {
      label: "Concession cards",
      initialValues: defaultConcessionCards,
      component: ConcessionCardsFormStep,
      visible: true,
      key: CONCESSION_CARDS_FORM_STEP,
    },
    {
      label: "Related",
      initialValues: defaultAssociations,
      component: AssociationsFormStep,
      visible: true,
      key: ASSOCIATIONS_FORM_STEP,
    },
    {
      label: "Voting",
      initialValues: [],
      component: VotingFormStep,
      visible: true,
      key: VOTING_FORM_STEP,
    },
  ];

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          maxHeight="90%"
          titleHeader={"Edit Entity"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
