import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsSystemAdminInspections: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.PermitType_Name,
    title: "Permit Type",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.PermitNumber,
    title: "Permit Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.PermitSubType,
    title: "Permit Code",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.PropertyType,
    title: "Property Type",
    width: 150,
  },
  { field: ParkingPermitsFieldMapping.Area, title: "Area", width: 150 },
  { field: ParkingPermitsFieldMapping.Org, title: "Staff Dept.", width: 150 },
  { field: ParkingPermitsFieldMapping.Suburb, title: "Suburb", width: 150 },
  { field: ParkingPermitsFieldMapping.Street, title: "Street", width: 150 },
  {
    field: ParkingPermitsFieldMapping.ExpiryDate,
    title: "Expiry Date",
    width: 150,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.RenewalStatus,
    title: "Renewal Status",
    width: 150,
  },
  {
    field: ParkingPermitsFieldMapping.LetterSent,
    title: "Letter Sent",
    width: 150,
  },
];
