import { getDownloadDocument } from "@app/products/property/system-admin/document-template/[id]/api";
import { ImageCardNew } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/components/image-card-new/_index";
import { ImageCardDocument } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/components/image-card/_index";
import {
  allowedExtensionsFile,
  AvailabilityData,
  customMessage,
  customMessageFile,
  defaultNewImage,
  RegionsData,
} from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/config";
import {
  DocumentTemplate,
  DocumentTemplate_Image,
} from "@app/products/property/system-admin/document-template/[id]/model";
import { useDocumentTemplateStore } from "@app/products/property/system-admin/document-template/[id]/store";
import { convertToFileStreamEntry } from "@app/products/property/system-admin/document-template/[id]/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import { UploadOnAddEvent } from "@progress/kendo-react-upload";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";
interface IDocumentTemplateFormElementProps {
  formRenderProps: FormRenderProps;
}
loadMessages(customMessage, "en-US");
loadMessages(customMessageFile, "au-AU");
export const DocumentTemplateFormElement = observer(
  ({ formRenderProps }: IDocumentTemplateFormElementProps) => {
    const { id } = useParams<{ id: string }>();
    const isNew = useIsNew();
    const { documentLovs, isDeletedRecord } = useDocumentTemplateStore();
    const [isError, setIsError] = useState(false);
    const nameOf = nameOfFactory<DocumentTemplate>();
    const { valueGetter, onSubmit, onChange } = formRenderProps;
    const handleRemoveImage = (index: number) => {
      const currentImages = valueGetter("DocumentTemplate_Images") ?? [];
      // set the IsActive of currentIndex by index to false if the currentIndex by index
      // exist the property DocumentTemplate_Image_ID
      const currentImage = currentImages[index];
      if (currentImage) {
        const currentImageId = currentImage?.DocumentTemplate_Image_ID;
        if (currentImageId) {
          currentImage.IsActive = false;
          onChange("DocumentTemplate_Images", { value: currentImages });
        } else {
          const newImages = [
            ...currentImages.slice(0, index),
            ...currentImages.slice(index + 1),
          ];
          onChange("DocumentTemplate_Images", { value: newImages });
        }
      }
    };
    const handleAddImage = () => {
      if (!isDeletedRecord) {
        const currentImages = valueGetter("DocumentTemplate_Images") ?? [];
        // push new default image into current images array and set it to form
        const newDefaultImage = { ...defaultNewImage };
        const newImages = [...currentImages, newDefaultImage];
        onChange("DocumentTemplate_Images", { value: newImages });
      }
    };
    const handleAddFile = async (e: UploadOnAddEvent) => {
      if (e.newState[0]?.validationErrors?.includes("invalidFileExtension")) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      if (!isDeletedRecord) {
        const file = e.newState?.[0];
        if (
          file &&
          file.extension &&
          allowedExtensionsFile.includes(file.extension.toLowerCase())
        ) {
          const newFile = await convertToFileStreamEntry(file);
          onChange("FileEntryNew", {
            value: {
              FileName: newFile?.FileName,
              FileSize: newFile?.FileSize,
              ContentType: newFile?.ContentType,
              FileAttachment: newFile?.FileAttachment,
            },
          });
        }
      }
    };

    const handleDownload = async () => {
      const response = await getDownloadDocument(parseInt(id));
      const newDecode = `data:${
        valueGetter("FileEntry.ContentType") ??
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      };base64,${response.data}`;
      var a = document.createElement("a");
      a.href = newDecode;
      a.download = valueGetter("FileEntry.FileName") ?? "document"; //file name
      a.click();
    };
    const handleDataset = (e: any) => {
      onChange(nameOf("Dataset_ENUM"), {
        value: e.target.value.Code,
      });
      onChange(nameOf("Dataset_Name"), {
        value: e.target.value.Name,
      });
      onChange(nameOf("Dataset_Int"), {
        value: e.target.value.Code,
      });
    };
    const handleFormat = (e: any) => {
      onChange(nameOf("SaveFormat_Name"), {
        value: e.target.value.Name,
      });
      onChange(nameOf("SaveFormat_ENUM"), {
        value: e.target.value.Code,
      });
      onChange(nameOf("SaveFormat_Int"), {
        value: e.target.value.Code,
      });
    };
    return (
      <FormElement>
        <input
          hidden
          onClick={isError ? () => null : onSubmit}
          id="btn-submit"
        />
        <div className="cc-form cc-document-form">
          <section className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Document name
                  <CCTooltip type="validator" position="right" />
                </label>
                <Field
                  name={nameOf("Name")}
                  component={CCInput}
                  disabled={isDeletedRecord}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Save as format</label>
                <Field
                  name={nameOf("SaveFormat_Int")}
                  component={CCDropDownList}
                  textField={"Name"}
                  dataItemKey={"Code"}
                  disabled={isDeletedRecord}
                  isKeyValueDropdown
                  defaultValue={
                    isNew ? documentLovs?.SaveFormats?.[0] ?? [] : undefined
                  }
                  data={documentLovs?.SaveFormats ?? []}
                  onChange={handleFormat}
                />
              </div>
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Get data from</label>
                <Field
                  name={nameOf("Dataset_Int")}
                  component={CCDropDownList}
                  textField={"Name"}
                  dataItemKey={"Code"}
                  disabled={isDeletedRecord}
                  isKeyValueDropdown
                  data={documentLovs?.Datasets ?? []}
                  onChange={handleDataset}
                />
              </div>
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Availability</label>
                <Field
                  name={nameOf("IsActive")}
                  textField={"Value"}
                  dataItemKey={"Key"}
                  component={CCDropDownList}
                  data={AvailabilityData}
                  disabled={isDeletedRecord}
                  isKeyValueDropdown
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Use mail merge regions</label>
                <Field
                  name={nameOf("UseRegions")}
                  textField={"Value"}
                  dataItemKey={"Key"}
                  component={CCDropDownList}
                  data={RegionsData}
                  disabled={isDeletedRecord}
                  isKeyValueDropdown
                />
              </div>
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  File
                  <CCTooltip type="validator" position="right" />
                  <CCTooltip
                    type="info"
                    position="right"
                    content="Only .doc or .docx extensions is allowed"
                  />
                </label>
                <LocalizationProvider language="au-AU">
                  <IntlProvider locale="au">
                    <Field
                      name={"FileEntryNew"}
                      component={CCUploadFile}
                      validator={isNew ? requiredValidator : undefined}
                      autoUpload={false}
                      batch={false}
                      multiple={false}
                      withCredentials={false}
                      showActionButtons={false}
                      disabled={isDeletedRecord}
                      onAdd={handleAddFile}
                      onRemove={() => {
                        onChange("FileEntryNew", {
                          value: undefined,
                        });
                        setIsError(false);
                      }}
                      restrictions={{
                        allowedExtensions: allowedExtensionsFile,
                      }}
                    />
                  </IntlProvider>
                </LocalizationProvider>
              </div>
            </div>
            {!isNew && (
              <div className="cc-form-cols-2">
                <div className=" cc-custom-download-document">
                  <Field
                    name={"FileEntry.FileName"}
                    component={CCInput}
                    readOnly
                    disabled={isDeletedRecord}
                  />
                  <Button
                    title="Download file"
                    type="button"
                    disabled={isDeletedRecord}
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                </div>
              </div>
            )}

            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Additional information</label>
                <Field
                  name={nameOf("AdditionalInformation")}
                  component={CCTextArea}
                  rows={3}
                  disabled={isDeletedRecord}
                />
              </div>
            </div>
            <section>
              <label className="cc-label">
                Image field settings
                <CCTooltip
                  type="info"
                  position="right"
                  content="Only jpeg, png, gif, svg and jpg extensions is allowed"
                />
              </label>
              <div className="cc-document-card-container">
                {valueGetter(nameOf("DocumentTemplate_Images"))?.reduce(
                  (
                    result: JSX.Element[],
                    image: DocumentTemplate_Image,
                    index: number
                  ) => {
                    if (image.IsActive) {
                      result.push(
                        <ImageCardDocument
                          formRenderProps={formRenderProps}
                          index={index}
                          key={index}
                          itemData={image}
                          handleRemoveImage={handleRemoveImage}
                          disabled={isDeletedRecord}
                        />
                      );
                    }
                    return result;
                  },
                  []
                )}
                {!isDeletedRecord && (
                  <ImageCardNew handleAddImage={handleAddImage} />
                )}
              </div>
            </section>
          </section>
        </div>
      </FormElement>
    );
  }
);
