import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import { isNil } from "lodash";
import React, { useMemo } from "react";
export const DateCustomCell: React.FC<ICustomEditCell> = (
  props: ICustomEditCell
) => {
  const { value, onChange, dataIndex, dataItem, field, extraConditions } =
    props;

  // Handle logic for min date
  const minDateProcessed = useMemo(() => {
    if (
      !isNil(extraConditions) &&
      field === extraConditions?.listFieldName?.toField &&
      !isNil(dataItem?.[extraConditions?.listFieldName?.fromField])
    )
      return new Date(dataItem?.[extraConditions?.listFieldName?.fromField]);

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem]);

  // Handle logic for max date
  const maxDateProcessed = useMemo(() => {
    if (
      !isNil(extraConditions) &&
      field === extraConditions?.listFieldName?.fromField &&
      !isNil(dataItem?.[extraConditions?.listFieldName?.toField])
    ) {
      return new Date(dataItem?.[extraConditions?.listFieldName?.toField]);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem]);

  return (
    <CCDatePicker
      value={value}
      min={minDateProcessed}
      max={maxDateProcessed}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value,
          });
        }
      }}
      format={DATE_FORMAT.DATE_CONTROL}
    />
  );
};
