import { DTO_Workflow_ModifyDebtRecovery } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/model";
import { DTO_DebtRecovery_LOVs } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postModifyDebtRecovery = async (
  model: WorkflowProcessMode,
  debtRecovery: DTO_Workflow_ModifyDebtRecovery | undefined
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyDebtRecovery>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      ``,
      //`../${model}`, @TODO waiting api
      debtRecovery
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDataModifyDebtRecovery = async (
  assessmentId: number
): Promise<APIResponse<DTO_Workflow_ModifyDebtRecovery>> => {
  try {
    return await CoreAPIService.getClient().post(`mocktest`, {
      AssessmentId: assessmentId,
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataDebtRecovery =
  | [
      APIResponse<DTO_DebtRecovery_LOVs>,
      APIResponse<DTO_Workflow_ModifyDebtRecovery>
    ]
  | APIResponse<
      DTO_DebtRecovery_LOVs | APIResponse<DTO_Workflow_ModifyDebtRecovery>
    >
  | undefined;

export const getInitialDataModifyDebtRecovery = async (
  assessmentId: number
): Promise<IGetInitialDataDebtRecovery> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_DebtRecovery_LOVs>(
        `api/property/internal/workflow/debtrecovery/lovs`
      ),
      getDataModifyDebtRecovery(assessmentId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
