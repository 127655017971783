import {
  ACCOUNTS_FORM_STEP,
  AccountsFormStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/_index";
import { defaultAccounts } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/config";
import { mockAccountsStep } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/accounts/mock";
import {
  ACCOUNT_NAMES_FORM_STEP,
  AccountNamesFormStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/_index";
import {
  TYPES_FORM_STEP,
  TypesFormStep,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/types/_index";
import { isShowParkButton } from "@app/products/property/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
interface IChangeOfNameAddrDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isFromActionList?: boolean;
  isIncompleteMode?: boolean;
}
export const ChangeOfNameAddrDialog = ({
  onClose,
  onSubmit,
  isIncompleteMode = false,
  isFromActionList = false,
}: IChangeOfNameAddrDialogProps) => {
  const defaultAccountStep = { ...defaultAccounts, ...mockAccountsStep };
  const steps: IStep[] = [
    {
      label: "Types",
      component: TypesFormStep,
      visible: true,
      key: TYPES_FORM_STEP,
    },
    {
      label: "Accounts",
      component: AccountsFormStep,
      visible: true,
      key: ACCOUNTS_FORM_STEP,
      initialValues: defaultAccountStep,
    },
    {
      label: "Names",
      component: AccountNamesFormStep,
      visible: true,
      key: ACCOUNT_NAMES_FORM_STEP,
    },
  ];
  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="45%"
          maxHeight="55%"
          titleHeader={"Change of Register Accounts Name/Address"}
          onClose={onClose}
          bodyElement={renderProps.children}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Park
                </Button>
              )}
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
