import { DTO_DeferredDuty_Account } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_DeferredDuty_Account>();

export const colJournalDeferredDuty: IColumnFields[] = [
  {
    field: nameOf("AccountNumber"),
    title: "Account Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("AccountName"),
    title: "Account Name",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("AssessmentId"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AccountId"),
    title: "Account ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
