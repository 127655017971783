import { DatePickerSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/date-picker/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { CEPSettingMultiChoice } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/cep/components/application-setting-checkbox/_index";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();

  const data = useDataSetting(configData, [
    "CorporateSettings_CEP_URL",
    "CorporateSettings_CEP_TenantId",
    "CorporateSettings_CEP_MessageQueueUrl",
    "CorporateSettings_CEP_MessageQueueUsername",
    "CorporateSettings_CEP_MessageQueuePassword",
    "CorporateSettings_Portal_EnabledCEPProductTypes",
    "CorporateSettings_CEP_LastUpdatedDate",
    "CorporateSettings_CEP_LastUpdatedBy",
  ]);

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {data.CorporateSettings_CEP_URL && (
            <InputGlobalSettings
              data={data.CorporateSettings_CEP_URL}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_CEP_TenantId && (
            <InputGlobalSettings
              data={data.CorporateSettings_CEP_TenantId}
              isEditing={isEditing}
            />
          )}
        </div>

        <div className="cc-form-cols-3">
          {data.CorporateSettings_CEP_MessageQueueUrl && (
            <InputGlobalSettings
              data={data.CorporateSettings_CEP_MessageQueueUrl}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_CEP_MessageQueueUsername && (
            <InputGlobalSettings
              data={data.CorporateSettings_CEP_MessageQueueUsername}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_CEP_MessageQueuePassword && (
            <InputGlobalSettings
              data={data.CorporateSettings_CEP_MessageQueuePassword}
              isEditing={isEditing}
            />
          )}
        </div>

        {data.CorporateSettings_Portal_EnabledCEPProductTypes && (
          <CEPSettingMultiChoice
            field={data.CorporateSettings_Portal_EnabledCEPProductTypes}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        )}

        <div className="cc-form-cols-3">
          {data.CorporateSettings_CEP_LastUpdatedDate && (
            <DatePickerSettings
              data={data.CorporateSettings_CEP_LastUpdatedDate}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
              readOnly
            />
          )}
          {data.CorporateSettings_CEP_LastUpdatedBy && (
            <InputGlobalSettings
              data={data.CorporateSettings_CEP_LastUpdatedBy}
              isEditing={isEditing}
            />
          )}
        </div>
      </section>
    </>
  );
});
