import { ITagsHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/model";

export const mockRegisterTagHistory: ITagsHistory = {
  TagsHistory: [
    {
      Row: 1,
      AnimalType: "Dog",
      CurrentTagNo: "D066944",
      Date: new Date("14 Jul 2021"),
      PreviousTagNo: "D066944",
      HistoryRAC: "D26 Microchipped & Desexed Dog",
      HistoryStatus: "Active",
      HistoryDescription: "Brahma James CLARE’S German Shepherd",
      HistoryUserId: "cgatt",
      AccountStatus: "Active",
      AccountDescription: "Brahma James CLARE’S German Shepherd",
      AccountPropertyAddress: "64 Ascot St South",
      AccountPropertyLocality: "Altona Meadows",
      AccountId: 51684,
    },
    {
      Row: 2,
      AnimalType: "Dog",
      CurrentTagNo: "D066944",
      Date: new Date("14 Jul 2021"),
      PreviousTagNo: "D066944",
      HistoryRAC: "D13 Registered with Approved Assoc",
      HistoryStatus: "Active",
      HistoryDescription: "Brahma James CLARE’S German Shepherd",
      HistoryUserId: "cgatt",
      AccountStatus: "Active",
      AccountDescription: "Brahma James CLARE’S German Shepherd",
      AccountPropertyAddress: "64 Ascot St South",
      AccountPropertyLocality: "Altona Meadows",
      AccountId: 51684,
    },
    {
      Row: 3,
      AnimalType: "Dog",
      CurrentTagNo: "D066944",
      Date: new Date("14 Jul 2021"),
      PreviousTagNo: "D066944",
      HistoryRAC: "D26 Microchipped & Desexed Dog",
      HistoryStatus: "Active",
      HistoryUserId: "cgatt",
      AccountStatus: "Active",
      AccountDescription: "Brahma James CLARE’S German Shepherd",
      AccountPropertyAddress: "64 Ascot St South",
      AccountPropertyLocality: "Altona Meadows",
      AccountId: 51684,
    },
    {
      Row: 4,
      AnimalType: "Dog",
      CurrentTagNo: "D066944",
      Date: new Date("14 Jul 2021"),
      PreviousTagNo: "D066944",
      HistoryRAC: "D26 Microchipped & Desexed Dog",
      HistoryStatus: "Active",
      HistoryDescription: "Brahma James CLARE'S Animal",
      HistoryUserId: "cgatt",
      AccountStatus: "Active",
      AccountDescription: "Brahma James CLARE’S German Shepherd",
      AccountPropertyAddress: "64 Ascot St South",
      AccountPropertyLocality: "Altona Meadows",
      AccountId: 51684,
    },
  ],
};
