import { CRSFormData } from "@app/products/crs/model";
import {
  mockCRSAddNeedsAssessed,
  mockCRSAllowCentreAccess,
  mockCRSChildGender,
  mockCRSCountryOfBirth,
  mockCRSCountryOfOrigin,
  mockCRSInterpreterRequired,
  mockCRSLanguage,
  mockCRSPaymentPlan,
  mockCRSProofOfBirthAttached,
  mockCRSRelationToChild,
  mockCRSResident,
  mockCRSSubsidyCard,
} from "@app/products/crs/[id]/components/child-screens/general/components/form-element/mock";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { FormRoute } from "@common/constants/menu";
import { useIsNew } from "@common/hooks/useIsNew";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";
import {
  createDefaultDropdownItem,
  nameOfFactory,
  populateValue,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { formatDateByKendo, formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
} from "@progress/kendo-react-inputs";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export interface ICRSFormElementProps {
  formRenderProps: FormRenderProps;
}

export const CRSFormElement = observer(
  ({ formRenderProps }: ICRSFormElementProps) => {
    const isNew = useIsNew();
    const history = useHistory();
    const nameOf = nameOfFactory<CRSFormData>();
    const { modified, valueGetter, onSubmit, onChange, valid } =
      formRenderProps;
    const { pushNotification } = useCCAppNotificationStore();
    const navigateToParentContactRelationship = () => {
      const parentPage: IParentSection[] = [
        {
          key: 1,
          label: "Child name",
          value: "Surinam Dollar",
        },
        { key: 2, label: "Lodgement date", value: "13 Feb 2020" },
        { key: 3, label: "Application status", value: "Lodged" },
      ];
      const isNull = !valueGetter(nameOf("Parent1Name"));
      if (isNull) {
        return history.push(
          `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${isNew ? "new" : "1300"}`,
          {
            flag_ContactFromNar: true,
            title: "Parent/Guardian 1",
          }
        );
      } else {
        return history.push(
          `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${"1300"}`,
          {
            parentPage,
            flag_ContactFromNar: false,
            title: "Parent/Guardian 1",
          }
        );
      }
    };
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (modified) {
        onSubmit(event);
        return;
      }
      if (!valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    return (
      <FormElement onSubmit={onSubmit} className="cc-crs-form-element">
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        {/* SECTION 1 */}
        <section className="cc-field-group">
          {/* ROW 1 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Child first name <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("Child_FirstName")}
                component={CCInput}
                placeholder={"Child first name"}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Child last name</label>
              <Field
                name={nameOf("Child_LastName")}
                component={CCInput}
                placeholder={"Child last name"}
              />
            </div>
            <CCValueField
              className="cc-col-span-2"
              label="Child display name"
              value={valueGetter(nameOf("ChildDisplayName"))}
            />
          </div>
          {/* ROW 2 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Date of birth</label>
              <Field
                name={nameOf("ChildDateOfBirth")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
            <CCValueField
              className="cc-col-span-2"
              label="Age today"
              value={valueGetter(nameOf("ChildAge"))}
            />
            <div className="cc-field">
              <label className="cc-label">Gender</label>
              <Field
                name={nameOf("ChildGender")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Gender")}
                data={mockCRSChildGender}
                value={mockCRSChildGender.find((item: IKeyValuePacket) => {
                  return item.Key === +valueGetter(nameOf("ChildGender"));
                })}
                onChange={(event: any) => {
                  onChange(nameOf("ChildGender"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
          </div>
          {/* ROW 3 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Child CRN</label>
              <Field
                name={nameOf("ChildCRN")}
                component={CCInput}
                placeholder={"Child CRN"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Proof of birth attached</label>
              <Field
                name={nameOf("ProofofBirthAttached")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem(
                  "Proof of Birth Attached"
                )}
                data={mockCRSProofOfBirthAttached}
                value={mockCRSProofOfBirthAttached.find(
                  (item: IKeyValuePacket) => {
                    return (
                      item.Key === +valueGetter(nameOf("ProofofBirthAttached"))
                    );
                  }
                )}
                onChange={(event: any) => {
                  onChange(nameOf("ProofofBirthAttached"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Lodgement date</label>
              <Field
                name={nameOf("LodgementDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
          </div>
          {/* ROW 4 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Subsidy card</label>
              <Field
                name={nameOf("Registration_CardType")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Subsidy Card")}
                data={mockCRSSubsidyCard}
                value={mockCRSSubsidyCard.find((item: IKeyValuePacket) => {
                  return (
                    item.Key === +valueGetter(nameOf("Registration_CardType"))
                  );
                })}
                onChange={(event: any) => {
                  onChange(nameOf("Registration_CardType"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
            {/* NOTE */}
            {valueGetter("Registration_CardType") === 2 && (
              <div className="cc-field">
                <label className="cc-label">Card expiry date</label>
                <Field
                  name={nameOf("Registration_CardExpiryMonth")}
                  component={CCInput}
                />
              </div>
            )}
          </div>
          {/* ROW 5 */}
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Add needs/disability</label>
              <Field
                name={nameOf("AddNeeds")}
                component={CCInput}
                placeholder={"Add Needs/Disability"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Add needs assessed</label>
              <Field
                name={nameOf("AddNeeds")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Add Needs Assessed")}
                data={mockCRSAddNeedsAssessed}
                value={mockCRSAddNeedsAssessed.find((item: IKeyValuePacket) => {
                  return item.Key === +valueGetter(nameOf("AddNeeds"));
                })}
                onChange={(event: any) => {
                  onChange(nameOf("AddNeeds"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
          </div>
          {/* ROW 6 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={nameOf("FileNumber")}
                component={CCInput}
                placeholder={"File number"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Country of birth</label>
              <Field
                name={nameOf("CountryofBirth")}
                textField="Value"
                dataItemKey="Key"
                data={mockCRSCountryOfBirth}
                value={mockCRSCountryOfBirth.find((item: IKeyValuePacket) => {
                  return item.Key === +valueGetter(nameOf("CountryofBirth"));
                })}
                component={CCComboBox}
                allowCustom={true}
                placeholder={"Country of birth"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Cultural background</label>
              <Field
                name={nameOf("CulturalBackground")}
                component={CCInput}
                placeholder={"Cultural background"}
              />
            </div>
          </div>
        </section>
        {/* PANELBAR */}

        <PanelBar expandMode={"multiple"} className="cc-crs-panel-bar">
          {/* SECTION 2 */}
          <PanelBarItem
            title={
              <div className="cc-collapse-header">
                <h6>
                  Parent / Guardian 1
                  {!isNew && (
                    <span> ({valueGetter(nameOf("Parent1Name"))})</span>
                  )}
                </h6>
                <hr />
              </div>
            }
          >
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Name</label>
                  <Field
                    onClick={navigateToParentContactRelationship}
                    name={nameOf("Parent1Name")}
                    component={InputPicker}
                    placeholder={"Name"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Relation to child</label>
                  <Field
                    name={nameOf("Parent1Relation")}
                    textField="Value"
                    dataItemKey="Key"
                    defaultItem={createDefaultDropdownItem("Relation to Child")}
                    data={mockCRSRelationToChild}
                    value={mockCRSRelationToChild.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key === +valueGetter(nameOf("Parent1Relation"))
                        );
                      }
                    )}
                    onChange={(event: any) => {
                      onChange(nameOf("Parent1Relation"), {
                        value: event.target.value.Key,
                      });
                    }}
                    component={DropDownList}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Country of origin</label>
                  <Field
                    name={nameOf("Parent1CountryofOrigin")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockCRSCountryOfOrigin}
                    value={mockCRSCountryOfOrigin.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key ===
                          +valueGetter(nameOf("Parent1CountryofOrigin"))
                        );
                      }
                    )}
                    component={CCComboBox}
                    allowCustom={true}
                    placeholder={"Country of origin"}
                  />
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Language</label>
                  <Field
                    name={nameOf("Parent1Language")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockCRSLanguage}
                    value={mockCRSLanguage.find((item: IKeyValuePacket) => {
                      return (
                        item.Key === +valueGetter(nameOf("Parent1Language"))
                      );
                    })}
                    component={CCComboBox}
                    allowCustom={true}
                    placeholder={"Language"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Interpreter required</label>
                  <Field
                    name={nameOf("Parent1InterpreterRequired")}
                    textField="Value"
                    dataItemKey="Key"
                    defaultItem={createDefaultDropdownItem(
                      "Interpreter Required"
                    )}
                    data={mockCRSInterpreterRequired}
                    value={mockCRSRelationToChild.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key ===
                          +valueGetter(nameOf("Parent1InterpreterRequired"))
                        );
                      }
                    )}
                    onChange={(event: any) => {
                      onChange(nameOf("Parent1InterpreterRequired"), {
                        value: event.target.value.Key,
                      });
                    }}
                    component={DropDownList}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Parent CRN</label>
                  <Field
                    name={nameOf("Parent1CRN")}
                    component={CCInput}
                    placeholder={"Parent CRN"}
                  />
                </div>
              </div>
              {/* ROW 3 */}
              <div className="cc-form-cols-3">
                <CCValueField
                  className="cc-col-span-2"
                  label="Date of birth"
                  value={valueGetter(nameOf("DateofBirth"))}
                  format={DATE_FORMAT.DATE}
                />
                <CCValueField
                  className="cc-col-span-2"
                  label="Contact ID"
                  value={valueGetter(nameOf("Parent1_ContactNo"))}
                />
              </div>
              {/* ROW 4 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Subsidy card</label>
                  <Field
                    name={nameOf("Parent1_Registration_CardType")}
                    textField="Value"
                    dataItemKey="Key"
                    defaultItem={createDefaultDropdownItem("Subsidy Card")}
                    data={mockCRSSubsidyCard}
                    value={mockCRSSubsidyCard.find((item: IKeyValuePacket) => {
                      return (
                        item.Key ===
                        +valueGetter(nameOf("Parent1_Registration_CardType"))
                      );
                    })}
                    onChange={(event: any) => {
                      onChange(nameOf("Parent1_Registration_CardType"), {
                        value: event.target.value.Key,
                      });
                    }}
                    component={DropDownList}
                  />
                </div>
                {/* NOTE */}
                {valueGetter("Parent1_Registration_CardType") === 2 && (
                  <div className="cc-field">
                    <label className="cc-label">Card expiry date</label>
                    <Field
                      name={nameOf("Registration_CardExpiryMonth")}
                      component={CCInput}
                    />
                  </div>
                )}
              </div>
              {/* ROW 5 */}
              <div className="cc-form-cols-3">
                <CCValueField
                  className="cc-col-span-2"
                  label="NAR number"
                  value={valueGetter(nameOf("PARENT1_NAMENUMBER"))}
                />
              </div>
              {/* ROW 6 */}
              <div className="cc-form-cols-3">
                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Proof of guardianship</label>
                  <Field
                    name={nameOf("Parent1ProofofGuardianship")}
                    component={CCInput}
                    placeholder={"Proof of guardianship"}
                  />
                </div>
              </div>
              {/* ROW 7 */}
              {!isNew && (
                <div className="cc-form-cols-3">
                  {valueGetter(nameOf("Parent1Phone")) && (
                    <CCValueField
                      className="cc-col-span-2"
                      label="Work phone"
                      value={valueGetter(nameOf("Parent1Phone"))}
                    />
                  )}
                  {valueGetter(nameOf("Parent1HomePhone")) && (
                    <CCValueField
                      className="cc-col-span-2"
                      label="Home phone"
                      value={valueGetter(nameOf("Parent1HomePhone"))}
                    />
                  )}
                  {valueGetter(nameOf("Mobile")) && (
                    <CCValueField
                      className="cc-col-span-2"
                      label="Mobile"
                      value={valueGetter(nameOf("Mobile"))}
                    />
                  )}
                </div>
              )}
              {/* ROW 8 */}
              {!isNew &&
              valueGetter(nameOf("ParentGuardian1_PreferredMethod")) ? (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Preferred method"
                    value={valueGetter(
                      nameOf("ParentGuardian1_PreferredMethod")
                    )}
                  />
                  <CCValueField
                    className="cc-col-span-2"
                    label="Email"
                    value={valueGetter(nameOf("Parent1_Email"))}
                  />
                </div>
              ) : (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Preferred method"
                    value={""}
                  />
                </div>
              )}
              {/* ROW 9 */}
              {!isNew && valueGetter(nameOf("Parent1Address")) && (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Home address"
                    value={valueGetter(nameOf("Parent1Address"))}
                  />
                </div>
              )}
              {/* ROW 10 */}
              {!isNew && valueGetter(nameOf("Parent1_PostalAddress")) && (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Postal address"
                    value={valueGetter(nameOf("Parent1_PostalAddress"))}
                  />
                </div>
              )}
            </section>
          </PanelBarItem>
          {/* SECTION 3 */}
          <PanelBarItem
            title={
              <div className="cc-collapse-header">
                <div className="cc-collapse-container">
                  <h6>
                    Parent / Guardian 2
                    {!isNew && (
                      <span> ({valueGetter(nameOf("Parent2Name"))})</span>
                    )}
                  </h6>
                  <Checkbox
                    disabled={true}
                    onChange={(event: CheckboxChangeEvent) => {}}
                    label="Copy address from parent / guardian 1"
                  />
                </div>

                <hr />
              </div>
            }
          >
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Name</label>
                  {/* 001 */}
                  <Field
                    onClick={navigateToParentContactRelationship}
                    name={nameOf("Parent2Name")}
                    validator={requiredValidator}
                    component={InputPicker}
                    placeholder={"Name"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Relation to child</label>
                  <Field
                    name={nameOf("Parent2Relation")}
                    textField="Value"
                    dataItemKey="Key"
                    defaultItem={createDefaultDropdownItem("Relation to Child")}
                    data={mockCRSRelationToChild}
                    value={mockCRSRelationToChild.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key === +valueGetter(nameOf("Parent2Relation"))
                        );
                      }
                    )}
                    onChange={(event: any) => {
                      onChange(nameOf("Parent2Relation"), {
                        value: event.target.value.Key,
                      });
                    }}
                    component={DropDownList}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Country of origin</label>
                  <Field
                    name={nameOf("Parent2CountryofOrigin")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockCRSCountryOfOrigin}
                    value={mockCRSCountryOfOrigin.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key ===
                          +valueGetter(nameOf("Parent2CountryofOrigin"))
                        );
                      }
                    )}
                    component={CCComboBox}
                    allowCustom={true}
                    placeholder={"Country of origin"}
                  />
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Language</label>
                  <Field
                    name={nameOf("Parent2Language")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockCRSLanguage}
                    value={mockCRSLanguage.find((item: IKeyValuePacket) => {
                      return (
                        item.Key === +valueGetter(nameOf("Parent2Language"))
                      );
                    })}
                    component={CCComboBox}
                    allowCustom={true}
                    placeholder={"Language"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Interpreter required</label>
                  <Field
                    name={nameOf("Parent2InterpreterRequired")}
                    textField="Value"
                    dataItemKey="Key"
                    defaultItem={createDefaultDropdownItem(
                      "Interpreter Required"
                    )}
                    data={mockCRSInterpreterRequired}
                    value={mockCRSRelationToChild.find(
                      (item: IKeyValuePacket) => {
                        return (
                          item.Key ===
                          +valueGetter(nameOf("Parent2InterpreterRequired"))
                        );
                      }
                    )}
                    onChange={(event: any) => {
                      onChange(nameOf("Parent2InterpreterRequired"), {
                        value: event.target.value.Key,
                      });
                    }}
                    component={DropDownList}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Parent CRN</label>
                  <Field
                    name={nameOf("Parent2CRN")}
                    component={CCInput}
                    placeholder={"Parent CRN"}
                  />
                </div>
              </div>
              {/* ROW 3 */}
              <div className="cc-form-cols-3">
                <CCValueField
                  className="cc-col-span-2"
                  label="Date of birth"
                  value={valueGetter(nameOf("DateofBirth"))}
                  format={DATE_FORMAT.DATE}
                />
                <CCValueField
                  className="cc-col-span-2"
                  label="Contact ID"
                  value={valueGetter(nameOf("Parent2_ContactNo"))}
                />
              </div>
              {/* ROW 4 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Subsidy card</label>
                  <Field
                    name={nameOf("Parent2_Registration_CardType")}
                    textField="Value"
                    dataItemKey="Key"
                    defaultItem={createDefaultDropdownItem("Subsidy Card")}
                    data={mockCRSSubsidyCard}
                    value={mockCRSSubsidyCard.find((item: IKeyValuePacket) => {
                      return (
                        item.Key ===
                        +valueGetter(nameOf("Parent2_Registration_CardType"))
                      );
                    })}
                    onChange={(event: any) => {
                      onChange(nameOf("Parent2_Registration_CardType"), {
                        value: event.target.value.Key,
                      });
                    }}
                    component={DropDownList}
                  />
                </div>
                {/* NOTE */}
                {valueGetter("Parent2_Registration_CardType") === 2 && (
                  <div className="cc-field">
                    <label className="cc-label">Card expiry date</label>
                    <Field
                      name={nameOf("Registration_CardExpiryMonth")}
                      component={CCInput}
                    />
                  </div>
                )}
              </div>
              {/* ROW 5 */}
              <div className="cc-form-cols-3">
                <CCValueField
                  className="cc-col-span-2"
                  label="NAR number"
                  value={valueGetter(nameOf("PARENT2_NAMENUMBER"))}
                />
              </div>
              {/* ROW 6 */}
              <div className="cc-form-cols-3">
                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Proof of guardianship</label>
                  <Field
                    name={nameOf("Parent2ProofofGuardianship")}
                    component={CCInput}
                    placeholder={"Proof of guardianship"}
                  />
                </div>
              </div>
              {/* ROW 7 */}
              {!isNew && (
                <div className="cc-form-cols-3">
                  {valueGetter(nameOf("Parent2Phone")) && (
                    <CCValueField
                      className="cc-col-span-2"
                      label="Work phone"
                      value={valueGetter(nameOf("Parent2Phone"))}
                    />
                  )}
                  {valueGetter(nameOf("Parent2HomePhone")) && (
                    <CCValueField
                      className="cc-col-span-2"
                      label="Home phone"
                      value={valueGetter(nameOf("Parent2HomePhone"))}
                    />
                  )}
                  {valueGetter(nameOf("Mobile")) && (
                    <CCValueField
                      className="cc-col-span-2"
                      label="Mobile"
                      value={valueGetter(nameOf("Mobile"))}
                    />
                  )}
                </div>
              )}
              {/* ROW 8 */}
              {!isNew &&
              valueGetter(nameOf("ParentGuardian1_PreferredMethod")) ? (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Preferred method"
                    value={valueGetter(
                      nameOf("ParentGuardian2_PreferredMethod")
                    )}
                  />
                  <CCValueField
                    className="cc-col-span-2"
                    label="Email"
                    value={valueGetter(nameOf("Parent1_Email"))}
                  />
                </div>
              ) : (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Preferred method"
                    value={""}
                  />
                </div>
              )}
              {/* ROW 9 */}
              {!isNew && valueGetter(nameOf("Parent1Address")) && (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Home address"
                    value={valueGetter(nameOf("Parent2Address"))}
                  />
                </div>
              )}
              {/* ROW 10 */}
              {!isNew && valueGetter(nameOf("Parent1_PostalAddress")) && (
                <div className="cc-form-cols-3">
                  <CCValueField
                    className="cc-col-span-2"
                    label="Postal address"
                    value={valueGetter(nameOf("Parent2_PostalAddress"))}
                  />
                </div>
              )}
            </section>
          </PanelBarItem>
          {/* SECTION 4 */}
          <PanelBarItem
            title={
              <div className="cc-collapse-header">
                <h6>Emergency Contacts</h6>
                <hr />
              </div>
            }
          >
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <Checkbox
                    name={nameOf("EC1")}
                    checked={valueGetter("EC1")}
                    onChange={(event: CheckboxChangeEvent) => {
                      onChange(nameOf("EC1"), {
                        value: event.value,
                      });
                    }}
                    label="Emergency contact 1"
                  />
                </div>
              </div>
              {valueGetter("EC1") && (
                <>
                  {/* ROW 2 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Contactable</label>
                      <Field
                        name={nameOf("EC1Contactable")}
                        defaultChecked={valueGetter("EC1Contactable")}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                  {/* ROW 3 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Name <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("EC1Name")}
                        component={CCInput}
                        placeholder={"Name"}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  {/* ROW 4 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Relation to child{" "}
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("EC1RelationtoChild")}
                        component={CCInput}
                        placeholder={"Relation to child"}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  {/* ROW 5 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Contact details{" "}
                        <CCTooltip type="validator" position="right" />
                      </label>

                      <Field
                        name={nameOf("EC1ContactDetails")}
                        rows={2}
                        component={CCTextArea}
                        validator={requiredValidator}
                        placeholder={"Contact details"}
                      />
                    </div>
                  </div>
                  {/* ROW 6 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Council notes</label>
                      <Field
                        name={nameOf("EC1CouncilNotes")}
                        rows={2}
                        component={CCTextArea}
                        placeholder={"Council notes"}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* ROW 7 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <Checkbox
                    name={nameOf("EC1")}
                    checked={valueGetter("EC2")}
                    onChange={(event: CheckboxChangeEvent) => {
                      onChange(nameOf("EC2"), {
                        value: event.value,
                      });
                    }}
                    label="Emergency contact 2"
                  />
                </div>
              </div>
              {valueGetter("EC2") && (
                <>
                  {/* ROW 2 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Contactable</label>
                      <Field
                        name={nameOf("EC2Contactable")}
                        defaultChecked={valueGetter("EC1Contactable")}
                        component={CCSwitch}
                      />
                    </div>
                  </div>
                  {/* ROW 3 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Name <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("EC2Name")}
                        component={CCInput}
                        placeholder={"Name"}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  {/* ROW 4 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Relation to child{" "}
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("EC2RelationtoChild")}
                        component={CCInput}
                        placeholder={"Relation to child"}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                  {/* ROW 5 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">
                        Contact details{" "}
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name={nameOf("EC2ContactDetails")}
                        rows={2}
                        component={CCTextArea}
                        validator={requiredValidator}
                        placeholder={"Contact details"}
                      />
                    </div>
                  </div>
                  {/* ROW 6 */}
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="cc-label">Council notes</label>
                      <Field
                        name={nameOf("EC2CouncilNotes")}
                        rows={2}
                        component={CCTextArea}
                        placeholder={"Council notes"}
                      />
                    </div>
                  </div>
                </>
              )}
            </section>
          </PanelBarItem>
          {/* SECTION 5 */}
          <PanelBarItem
            expanded={true}
            title={
              <div className="cc-collapse-header">
                <h6>Priority of Access</h6>
                <hr />
              </div>
            }
          >
            {/* 009 */}
            <div className="cc-table-responsive">
              <table className="cc-table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">CBC</th>
                    <th scope="col">HBC</th>
                    <th scope="col">3YO</th>
                    <th scope="col">4YO</th>
                  </tr>
                </thead>
                <thead className="cc-thead-dark">
                  <tr>
                    <th scope="col">Priority of Access</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {/* List Answer */}
                <tbody>
                  {/* ROW 1 */}
                  <tr>
                    <td>
                      <label className="cc-label">Aboriginal/TSI</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsAboriginalTSI")}
                        defaultChecked={valueGetter("IsAboriginalTSI")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsAboriginalTSI") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Aboriginal/TSI"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 2 */}
                  <tr>
                    <td>
                      <label className="cc-label">Interpreter</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsInterpreter")}
                        defaultChecked={valueGetter("IsInterpreter")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsInterpreter") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Interpreter"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 3 */}
                  <tr>
                    <td>
                      <label className="cc-label">Health care card</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsHealthCareCard")}
                        defaultChecked={valueGetter("IsHealthCareCard")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsHealthCareCard") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Health care card"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 4 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Child currently enrolled in municipality
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsChildCurrentlyEnrolledinMunicipality")}
                        defaultChecked={valueGetter(
                          "IsChildCurrentlyEnrolledinMunicipality"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter(
                        "IsChildCurrentlyEnrolledinMunicipality"
                      ) && (
                        <Input
                          className={"crs-input"}
                          placeholder={
                            "Child Currently Enrolled in Municipality"
                          }
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 5 */}
                  <tr>
                    <td>
                      <label className="cc-label">Are you a resident?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsResident")}
                        defaultChecked={valueGetter("IsResident")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsResident") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Resident"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 6 */}
                  <tr>
                    <td>
                      <label className="cc-label">Single parent?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsSingleParent")}
                        defaultChecked={valueGetter("IsSingleParent")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsSingleParent") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Single resident"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 7 */}
                  <tr>
                    <td>
                      <label className="cc-label">Respite for parent?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsRespiteforParent")}
                        defaultChecked={valueGetter("IsRespiteforParent")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsRespiteforParent") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Respite for parent"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 8 */}
                  <tr>
                    <td>
                      <label className="cc-label">Disability in family?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsDisabilityinfamily")}
                        defaultChecked={valueGetter("IsDisabilityinfamily")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsDisabilityinfamily") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Disability in family"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 9 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Special needs indicated?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsSpecialNeedsIndicated")}
                        defaultChecked={valueGetter("IsSpecialNeedsIndicated")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsSpecialNeedsIndicated") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Special needs indicated"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 10 */}
                  <tr>
                    <td>
                      <label className="cc-label">Special need assessed?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsSpecialNeedAssessed")}
                        defaultChecked={valueGetter("IsSpecialNeedAssessed")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsSpecialNeedAssessed") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Special need assessed"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 11 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Siblings currently enrolled in municipality?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf(
                          "IsSiblingscurrentlyenrolledinMunicipality"
                        )}
                        defaultChecked={valueGetter(
                          "IsSiblingscurrentlyenrolledinMunicipality"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter(
                        "IsSiblingscurrentlyenrolledinMunicipality"
                      ) && (
                        <Input
                          className={"crs-input"}
                          placeholder={
                            "Siblings currently enrolled in Municipality"
                          }
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 12 */}
                  <tr>
                    <td>
                      <label className="cc-label">At risk indicated?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsAtRiskIndicated")}
                        defaultChecked={valueGetter("IsAtRiskIndicated")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsAtRiskIndicated") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"At risk indicated"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 13 */}
                  <tr>
                    <td>
                      <label className="cc-label">At risk assessed?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsAtRiskAssessed")}
                        defaultChecked={valueGetter("IsAtRiskAssessed")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsAtRiskAssessed") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"At risk assessed"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 14 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Assessed as socially isolated?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsAssessedasSociallyIsolated")}
                        defaultChecked={valueGetter(
                          "IsAssessedasSociallyIsolated"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsAssessedasSociallyIsolated") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Assessed as socially isolated"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 15 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        High support needs indicated?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsHighSupportNeedsIndicated")}
                        defaultChecked={valueGetter(
                          "IsHighSupportNeedsIndicated"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsHighSupportNeedsIndicated") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"High support needs indicated"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 16 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        High support needs assessed?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsHighSupportNeedsAssessed")}
                        defaultChecked={valueGetter(
                          "IsHighSupportNeedsAssessed"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsHighSupportNeedsAssessed") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"High support needs assessed"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 17 */}
                  <tr>
                    <td>
                      <label className="cc-label">Referral agency?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsReferralAgency")}
                        defaultChecked={valueGetter("IsReferralAgency")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsReferralAgency") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Referral agency"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 18 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Cared by family member?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsCaredByFamilyMember")}
                        defaultChecked={valueGetter("IsCaredByFamilyMember")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsCaredByFamilyMember") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Cared by family member"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 19 */}
                  <tr>
                    <td>
                      <label className="cc-label">Multiple birth?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsMultipleBirth")}
                        defaultChecked={valueGetter("IsMultipleBirth")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsMultipleBirth") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Multiple birth"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 20 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Maternal & child health check?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsMaternalChildHealthCheck")}
                        defaultChecked={valueGetter(
                          "IsMaternalChildHealthCheck"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsMaternalChildHealthCheck") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Maternal & child health check"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 21 */}
                  <tr>
                    <td>
                      <label className="cc-label">Enrolment period</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsEnrolmentPeriod")}
                        defaultChecked={valueGetter("IsEnrolmentPeriod")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsEnrolmentPeriod") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Enrolment period"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                </tbody>
                <thead className="cc-thead-dark">
                  <tr>
                    <th scope="col">Working / Studying / Seeking Work</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {/* ROW 1 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Parent(s) working/studying?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsParentworkingstudying")}
                        defaultChecked={valueGetter("IsParentworkingstudying")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsParentworkingstudying") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Parent(s) working/studying"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 2 */}
                  <tr>
                    <td>
                      <label className="cc-label">
                        Working/Studying in municipality?
                      </label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsWorkingStudyinginmunicipality")}
                        defaultChecked={valueGetter(
                          "IsWorkingStudyinginmunicipality"
                        )}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsWorkingStudyinginmunicipality") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Working/Studying in municipality"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                  {/* ROW 3 */}
                  <tr>
                    <td>
                      <label className="cc-label">Parent seeking work?</label>
                    </td>
                    <td>
                      <Field
                        name={nameOf("IsParentseekingwork")}
                        defaultChecked={valueGetter("IsParentseekingwork")}
                        component={CCSwitch}
                      />
                    </td>
                    <td>
                      {valueGetter("IsParentseekingwork") && (
                        <Input
                          className={"crs-input"}
                          placeholder={"Parent seeking work"}
                        />
                      )}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredCBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegisteredHBC")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered3yo")))}
                    </td>
                    <td>
                      {populateValue(valueGetter(nameOf("FRegistered4yo")))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <Checkbox
                  name={nameOf("IsSkipPOAValidation")}
                  onChange={(event: CheckboxChangeEvent) => {}}
                  label="Skip POA validation"
                />
              </div>
            </div>
          </PanelBarItem>
          {/* SECTION 6 */}
          <PanelBarItem
            title={
              <div className="cc-collapse-header">
                <h6>Service Summary</h6>
                <hr />
              </div>
            }
          >
            <section className="cc-field-group">
              {/* SECTION 1 */}
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">CBC</label>
                  <div className="cc-label-field">
                    {valueGetter(nameOf("CBCRegistrationStatus"))
                      ? populateValue(
                          valueGetter(nameOf("CBCRegistrationStatus"))
                        )
                      : populateValue("No Registration")}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">3yo</label>
                  <div className="cc-label-field">
                    {valueGetter(nameOf("TYORegistrationStatus"))
                      ? populateValue(
                          valueGetter(nameOf("TYORegistrationStatus"))
                        )
                      : populateValue("No Registration")}
                  </div>
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">HBC</label>
                  <div className="cc-label-field">
                    {valueGetter(nameOf("HBCRegistrationStatus"))
                      ? populateValue(
                          valueGetter(nameOf("HBCRegistrationStatus"))
                        )
                      : populateValue("No Registration")}
                  </div>
                </div>
                <div className="cc-field">
                  <label className="cc-label">3yo</label>
                  <div className="cc-label-field">
                    {valueGetter(nameOf("FYORegistrationStatus"))
                      ? populateValue(
                          valueGetter(nameOf("FYORegistrationStatus"))
                        )
                      : populateValue("No Registration")}
                  </div>
                </div>
              </div>
            </section>
          </PanelBarItem>
        </PanelBar>
        {/* SECTION 2 */}
        <section className="cc-field-group">
          {/* ROW 1 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Resident</label>
              <Field
                name={nameOf("IsResident")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Resident")}
                data={mockCRSResident}
                value={mockCRSResident.find((item: IKeyValuePacket) => {
                  return item.Key === +valueGetter(nameOf("IsResident"));
                })}
                onChange={(event: any) => {
                  onChange(nameOf("IsResident"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
          </div>
          {/* ROW 2 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Additional information</label>
              <Field
                name={nameOf("AdditionalInformation")}
                component={CCInput}
                placeholder={"Additional information"}
              />
            </div>
          </div>
          {/* ROW 3 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Centre information</label>
              <Field
                name={nameOf("CentrePlaced")}
                component={CCInput}
                placeholder={"Centre information"}
              />
            </div>
          </div>
          {/* ROW 4 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Council attention</label>
              <Field
                name={nameOf("CouncilFees")}
                component={CCInput}
                placeholder={"Council attention"}
              />
            </div>
          </div>
          {/* ROW 5 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Allow centre access</label>
              <Field
                name={nameOf("Parent1Relation")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Allow Centre Access")}
                data={mockCRSAllowCentreAccess}
                value={mockCRSAllowCentreAccess.find(
                  (item: IKeyValuePacket) => {
                    return item.Key === +valueGetter(nameOf("Parent1Relation"));
                  }
                )}
                onChange={(event: any) => {
                  onChange(nameOf("Parent1Relation"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">External reference</label>
              <Field
                name={nameOf("ExternalRef")}
                component={CCInput}
                placeholder={"External reference"}
              />
            </div>
          </div>
          {/* ROW 6 */}
          {!isNew && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Last update sent</label>
                <div className="cc-label-field">
                  {populateValue(
                    formatDateByKendo(valueGetter(nameOf("Last_Payment_Date")))
                  )}
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Next update due</label>
                <div className="cc-label-field">
                  {populateValue(
                    formatDateByKendo(valueGetter(nameOf("Last_Payment_Date")))
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
        <hr className="cc-divider" />
        {/* SECTION 3 */}
        <section className="cc-field-group">
          {/* ROW 1 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Debtor number
                {!isNew && (
                  <CCTooltip content="Change Debtor Number" type="custom">
                    <div className="fa fa-chevron-circle-right cc-chevron-circle" />
                  </CCTooltip>
                )}
              </label>
              <div className="cc-label-field">
                {populateValue(valueGetter(nameOf("DebtorNo")))}
              </div>
            </div>
            <CCValueField
              label="OS fees"
              value={valueGetter(nameOf("Amnt_OverduePlus30"))}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatOSFees(valueGetter(nameOf("Amnt_OverduePlus30")))}
            />
          </div>
          {/* ROW 2 */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Payment plan</label>
              <Field
                name={nameOf("PaymentPlan")}
                textField="Value"
                dataItemKey="Key"
                defaultItem={createDefaultDropdownItem("Payment Plan")}
                data={mockCRSPaymentPlan}
                value={mockCRSPaymentPlan.find((item: IKeyValuePacket) => {
                  return item.Key === +valueGetter(nameOf("PaymentPlan"));
                })}
                onChange={(event: any) => {
                  onChange(nameOf("PaymentPlan"), {
                    value: event.target.value.Key,
                  });
                }}
                component={DropDownList}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
