import { DTO_NoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/components/action-bar/buttons/cancel-notice-of-sale/model";
import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const suspendNoticeOfSale = async (
  payload: DTO_NoticeOfSale
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/noticeofsale/suspend`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
