import { EGlobalSettingsCategory } from "@common/pages/settings/system-admin/global-settings/model";
import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const infringementsNoticesRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: EGlobalSettingsCategory.InfringementsNotices_GeneralSettings,
    component: require("./forms/general-settings").default,
  },
  {
    name: EGlobalSettingsCategory.InfringementsNotices_InfringementIntegrationSettings,
    component: require("./forms/infringement-integration-settings").default,
  },
  {
    name: EGlobalSettingsCategory.InfringementsNotices_NoticeExportSettings,
    component: require("./forms/notice-export-settings").default,
  },
];
