import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_Parcel_TabDetails } from "./model";

export const getParcelDetailsTabById = async (
  parcelId: number
): Promise<APIResponse<DTO_Parcel_TabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Parcel_TabDetails>(
      `/api/property/internal/parcel/${parcelId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
