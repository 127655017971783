import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";

export const postSaveDDModuleItemType = async (
  moduleItemType: IntegrationModuleItemType
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/receipting/internal/integrationmoduleitemtype",
      moduleItemType
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
