import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DatePickerProps } from "@progress/kendo-react-dateinputs";
import { isAfter } from "date-fns";
import React from "react";

interface ICCDatePickerEditCellProps
  extends ICustomEditCell,
    Pick<DatePickerProps, "max" | "className"> {}

export const CCDatePickerEditCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  max,
  className,
}: ICCDatePickerEditCellProps) => {
  const processValue = (value: Date | null) => {
    if (!max || !value) return value;
    const today = new Date();
    if (isAfter(value, today)) {
      return today;
    }
    return value;
  };

  return (
    <CCDatePicker
      className={className}
      value={value}
      format={DATE_FORMAT.DATE_CONTROL}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: processValue(event.value),
          });
        }
      }}
      max={max}
    />
  );
};
