import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getCommunicationQueueById } from "@common/pages/settings/communication/queue/_id/api";
import { CommunicationQueue } from "@common/pages/settings/communication/queue/_id/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CommunicationQueueStore {
  private _communicationQueue?: CommunicationQueue = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get communicationQueue() {
    return toJS(this._communicationQueue);
  }
  setCommunicationQueue = (communicationQueue?: CommunicationQueue) => {
    runInAction(() => {
      this._communicationQueue = communicationQueue;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._communicationQueue = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  get communicationQueueId() {
    return toJS(this.communicationQueue?.CommunicationQueue_ID);
  }

  loadCommunicationQueue = async (communicationQueueID: number) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    let newCommunicationQueue = undefined;
    const response = await getCommunicationQueueById(communicationQueueID);
    if (isSuccessResponse(response)) {
      newCommunicationQueue = response.data as CommunicationQueue;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setCommunicationQueue(newCommunicationQueue);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
}

export const communicationQueueStore = new CommunicationQueueStore();
const communicationQueueStoreContext = createContext(communicationQueueStore);
export const useCommunicationQueueStore = () => {
  return useContext(communicationQueueStoreContext);
};
