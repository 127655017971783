import { useTownPlanningStore } from "@app/products/town-planning/[id]/store";
import { IMapShareInfo } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import {
  getPropertiesAddressList,
  getPropertyAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/api";
import {
  getCandidateAddress,
  getOriginalAddress,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/api";
import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { PropertyDetailDialogEventType } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/constant";
import { searchAddress } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/api";
import {
  colSearchAddresses,
  colSearchPropertyAddresses,
  searchAddressesConfig,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/config";
import { Address } from "@app/products/waste-water/[id]/model";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { KeywordLite } from "@common/models/keyword";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import {
  getDisplayBuildingUnitNumber,
  getDisplayFloorNumber,
  getDisplayHouseNumber,
} from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isEmpty, isNil, isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  MouseEventHandler,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IPropertyPickerProps extends ComboBoxProps {
  visited?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  disabledButton?: boolean;
  onError?: (value: any) => void;
  isLoadDetail?: boolean;
  textField?: string;
  textProduce?: (value: any) => string | undefined;
  formRenderProps: FormRenderProps;
  notificationRef: any;
  setOriginalFormData: (value: Address) => void;
  isCRMS?: boolean;
  setIsLoadingValidate: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PropertyAddressPicker = observer((props: IPropertyPickerProps) => {
  const {
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onButtonClick,
    onError,
    isLoadDetail = true,
    textField = value?.text ?? "",
    textProduce,
    formRenderProps,
    notificationRef,
    setOriginalFormData,
    isCRMS,
    setIsLoadingValidate,
    ...others
  } = props;
  const [mapShareInfo, setMapShareInfo] = useState<IMapShareInfo>({
    mapShareSuggestionSelected: {
      text: "",
      magicKey: "",
      isCollection: false,
    },
    mapShareSearchResult: [],
  });

  const { mapShareSuggestionSelected, mapShareSearchResult } = mapShareInfo;

  const refTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<MapshareSuggestion[]>();
  const { valueGetter, onChange: onChangeForm } = formRenderProps;
  const address = valueGetter("Address");
  const propertyAssessment = valueGetter("Address.PropertyAssessment");

  const { settings } = useCommonProductStore();
  const { zoneKeywordList, overlayKeywordList } = useTownPlanningStore();

  const isStreetTypeEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
  );

  const isUseStreetType = useMemo(() => {
    if (
      !isStreetTypeEnabled &&
      !isNil(valueGetter("Address.Flag_ForceUse_StreetType"))
    ) {
      return valueGetter("Address.Flag_ForceUse_StreetType");
    } else {
      return isStreetTypeEnabled;
    }
    // eslint-disable-next-line
  }, [isStreetTypeEnabled, valueGetter]);

  const inputData = useMemo(() => {
    if (isNil(data)) {
      return mapShareSuggestionSelected?.text ? mapShareSearchResult : [];
    }
    return data;
  }, [mapShareSuggestionSelected, mapShareSearchResult, data]);

  const inputDisplayName = useMemo(() => {
    if (isUndefined(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value?.text ?? "";
  }, [value, textField, textProduce]);

  const setStreetType = async (address: Address) => {
    const streetTypeAbbreviation =
      address.PropertyAssessment.Street_TypeAbbreviation;
    const streetName = address.PropertyAssessment.Street_Name;

    const response = await getKeywords(
      KEYWORD_TYPE.Core_Address_StreetType,
      PRODUCT_TYPE_NUMBER.TownPlanning
    );
    if (!isSuccessResponse(response)) {
      notificationRef.current?.pushNotification({
        title: `Street type list load failed`,
        type: "warning",
      });
    } else {
      if (isUseStreetType && response && response.data) {
        const streetTypeIndex = response.data.findIndex(
          (item) =>
            item.Name.toLowerCase() === streetTypeAbbreviation?.toLowerCase()
        );
        if (!isEmpty(streetTypeAbbreviation) && streetTypeIndex !== -1) {
          onChangeForm("_option.StreetType", {
            value: response.data[streetTypeIndex],
          });
          address = {
            ...address,
            StreetNamePart_Type: response.data[streetTypeIndex]?.Name,
          };
        } else {
          onChangeForm("_option.StreetType", {
            value: undefined,
          });
          address = { ...address, StreetNamePart_Type: "" };
        }
      } else {
        if (!isEmpty(streetTypeAbbreviation)) {
          address = {
            ...address,
            PropertyAssessment: {
              ...address.PropertyAssessment,
              Street_Name: `${streetName}`,
            },
          };
        }
        onChangeForm("_option.StreetType", { value: "" });
      }
    }

    return address;
  };

  /* ====  SiteUserHandle-Search address (in Property Details dialog) ==== */
  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    if (searchText.length < searchAddressesConfig.minCharacters) return;

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      setIsLoading(true);
      if (isCRMS) {
        getPropertiesAddressList(searchText).then((response) => {
          if (isSuccessResponse(response)) {
            setData(response.data?.value ?? []);
          } else {
            if (onError) onError(response.error);
          }
          setIsLoading(false);
        });
      } else {
        searchAddress(searchText).then((response) => {
          if (isSuccessResponse(response)) {
            setData(response.data?.suggestions);
          } else {
            if (onError) onError(response.error);
          }
          setIsLoading(false);
        });
      }
    }, searchAddressesConfig.typeSpeed);
  };
  /* ====  / SiteUserHandle-Search address (in Property Details dialog) ==== */

  useAddUniqueEventEmitter([
    {
      eventType: PropertyDetailDialogEventType.updateAddress,
      listener: (dataValidate: any) => {
        handleAddValueToAddressForm(dataValidate);
      },
    },
  ]);

  /* ====  SiteUserHandle-Add values of selected search result to the form (in Property Details dialog) ==== */
  const handleAddValueToAddressForm = async (data: any) => {
    const newValue = data?.value ? data.value : data;
    let newAddress = { ...address };
    let newPropertyAssessment = { ...propertyAssessment };

    if (isCRMS) {
      getPropertyAddress(newValue?.ID?.toString() ?? "0").then((response) => {
        if (isSuccessResponse(response) && response.data) {
          newAddress = response.data;
          onChangeForm("Address", {
            value: newAddress,
          });
          onChangeForm(`Address.Flag_StreetName_HasParts`, {
            value: isUseStreetType,
          });
          setOriginalFormData(newAddress);
        } else {
          if (onError) onError(response.error);
        }
        setIsLoading(false);
        onChangeForm("IsSearchLoading", { value: false });
      });
    } else {
      //Clear unrelated fields
      newPropertyAssessment = {
        ...newPropertyAssessment,
        PropertyName: "",
        BuildingName: "",
        AddressLocationDescriptor: "",
        NearestCrossStreet: "",
        UnitNumber1: null,
        UnitSuffix1: "",
        UnitNumber2: null,
        UnitSuffix2: "",
        UnitAbbreviation: "",
        HouseNumber2: null,
        HouseSuffix2: "",
        FloorNumber1: null,
        FloorSuffix1: "",
        FloorNumber2: null,
        FloorSuffix2: "",
        FloorTypeAbbreviation: "",
      };

      newAddress = {
        ...newAddress,
        NearestCrossStreet: "",
        PropertyName: "",
        Location_Description: "",
        PropertyAssessment: {
          ...newAddress.PropertyAssessment,
          ...newPropertyAssessment,
        },
        StreetNo: getDisplayHouseNumber(newPropertyAssessment),
        UnitNo:
          (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
            ? `${getDisplayFloorNumber(newPropertyAssessment)} `
            : "") + getDisplayBuildingUnitNumber(newPropertyAssessment),
      };

      onChangeForm("_option.LocationDescriptor", {
        value: undefined,
      });

      //Get candidate address by magicKey
      const responseCandidateAddress = await getCandidateAddress(
        newValue?.magicKey
      );
      if (isSuccessResponse(responseCandidateAddress)) {
        setMapShareInfo({ ...mapShareInfo, mapShareSearchResult: data });
        const candidateAddress = responseCandidateAddress?.data;
        if (candidateAddress) {
          if (
            candidateAddress.candidates &&
            candidateAddress.candidates.length > 0
          ) {
            newPropertyAssessment = {
              ...newPropertyAssessment,
              GIS_Latitude: candidateAddress.candidates[0].location.x,
              GIS_Longitude: candidateAddress.candidates[0].location.y,
            };

            newAddress = {
              ...newAddress,
              PropertyAssessment: {
                ...newAddress.PropertyAssessment,
                ...newPropertyAssessment,
              },
            };

            if (
              candidateAddress.candidates[0].attributes &&
              !isEmpty(candidateAddress.candidates[0].attributes.User_fld)
            ) {
              const responseOriginalAddress = await getOriginalAddress(
                candidateAddress.candidates[0].attributes.User_fld
              );
              if (isSuccessResponse(responseOriginalAddress)) {
                const originalAddress = responseOriginalAddress.data;

                if (originalAddress) {
                  //Fill corresponding fields
                  newAddress = {
                    ...newAddress,
                    UnitNo: originalAddress.UnitNo ?? "",
                    StreetNo: originalAddress.StreetNo ?? "",
                  };

                  newPropertyAssessment = {
                    ...newPropertyAssessment,
                    ...originalAddress.PropertyAssessment,
                    Address_PFI:
                      originalAddress.PropertyAssessment.Address_PFI ?? "",
                    HouseNumber1:
                      originalAddress.PropertyAssessment.HouseNumber1 ?? null,
                    HouseSuffix1:
                      originalAddress.PropertyAssessment.HouseSuffix1 ?? "",
                    Street_Name:
                      originalAddress.PropertyAssessment.Street_Name ?? "",
                    Street_Suffix:
                      originalAddress.PropertyAssessment.Street_Suffix ?? "",
                    Street_TypeAbbreviation:
                      originalAddress.PropertyAssessment
                        .Street_TypeAbbreviation ?? "",
                    Locality_Name:
                      originalAddress.PropertyAssessment.Locality_Name ?? "",
                    Locality_Postcode:
                      originalAddress.PropertyAssessment.Locality_Postcode ??
                      "",
                    UnitNumber1: originalAddress.UnitNo || null,
                    Zones: originalAddress?.PropertyAssessment?.Zones ?? "",
                    Overlay: originalAddress?.PropertyAssessment?.Overlay ?? "",
                    ZoneIDs: originalAddress?.PropertyAssessment?.ZoneIDs ?? [],
                    OverlayIDs:
                      originalAddress?.PropertyAssessment?.OverlayIDs ?? [],
                  };
                  newAddress = {
                    ...newAddress,
                    Suburb: originalAddress.Suburb ?? "",
                    Postcode: originalAddress.Postcode ?? "",
                    AddressLine1: originalAddress.AddressLine1 ?? "",
                    VMAS_Verified: true,
                    State: originalAddress.State ?? "",
                    StreetNamePart_Name:
                      originalAddress.StreetNamePart_Name ?? "",
                    StreetName: originalAddress.StreetName ?? "",
                    PropertyAssessment: {
                      ...newAddress.PropertyAssessment,
                      ...newPropertyAssessment,
                    },
                    StreetNo: getDisplayHouseNumber(newPropertyAssessment),
                    UnitNo:
                      (!isEmpty(getDisplayFloorNumber(newPropertyAssessment))
                        ? `${getDisplayFloorNumber(newPropertyAssessment)} `
                        : "") +
                      getDisplayBuildingUnitNumber(newPropertyAssessment),
                  };
                  newAddress = await setStreetType(newAddress);

                  onChangeForm("Zone_Display", {
                    value: zoneKeywordList.filter((keyword: KeywordLite) =>
                      newPropertyAssessment.ZoneIDs.includes(keyword.Keyword_ID)
                    ),
                  });
                  onChangeForm("Overlay_Display", {
                    value: overlayKeywordList.filter((keyword: KeywordLite) =>
                      newPropertyAssessment.OverlayIDs.includes(
                        keyword.Keyword_ID
                      )
                    ),
                  });
                  onChangeForm("ZoneList", {
                    value: zoneKeywordList.filter((keyword: KeywordLite) =>
                      newPropertyAssessment.ZoneIDs.includes(keyword.Keyword_ID)
                    ),
                  });
                  onChangeForm("OverlayList", {
                    value: overlayKeywordList.filter((keyword: KeywordLite) =>
                      newPropertyAssessment.OverlayIDs.includes(
                        keyword.Keyword_ID
                      )
                    ),
                  });

                  onChangeForm("Address", {
                    value: newAddress,
                  });
                  onChangeForm(`Address.Flag_StreetName_HasParts`, {
                    value: isUseStreetType,
                  });
                  setOriginalFormData(newAddress);
                  setIsLoadingValidate(false);
                }
              } else {
                if (onError) onError(responseOriginalAddress.error);
              }
            }
          }
        }
      } else {
        if (onError) onError(responseCandidateAddress.error);
      }
      setIsLoading(false);
      onChangeForm("IsSearchLoading", { value: false });
    }
  };

  const handleOnChange = async (event: ComboBoxChangeEvent) => {
    const newValue = event.value;
    if (!onChange) return;
    onChange({
      ...event,
      value: {
        ...value,
        text: "",
      },
    });

    if (!newValue) {
      onChange({
        ...event,
        value: {
          ...value,
          text: "",
        },
      });
      onChangeForm("Address", { value: undefined });
      onChangeForm("_option.StreetType", { value: "" });

      return;
    }

    if (!isLoadDetail)
      return onChange({
        ...event,
        value: { ...newValue, magicKey: newValue.magicKey },
      });

    setIsLoading(true);
    onChangeForm("IsSearchLoading", { value: true });
    handleAddValueToAddressForm(event);
  };
  /* ====  / SiteUserHandle-Add values of selected search result to the form (in Property Details dialog) ==== */

  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });

  return (
    <>
      <div className={`${className ?? ""} cc-search-combobox-new`}>
        <ComboBox
          {...others}
          filterable
          suggest
          data={inputData}
          loading={isLoading}
          onFilterChange={handleSearch}
          itemRender={(
            li: ReactElement<HTMLLIElement>,
            itemProps: ListItemProps
          ) => ItemRender(li, itemProps, isCRMS)}
          value={inputDisplayName}
          header={
            <div className="cc-search-header">
              {(isCRMS ? colSearchPropertyAddresses : colSearchAddresses).map(
                (col: IColumnFields) => (
                  <div key={col.field} style={{ width: col.width }}>
                    {col.title}
                  </div>
                )
              )}
            </div>
          }
          onChange={handleOnChange}
          popupSettings={{ className: "cc-property-address-picker-search" }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
});

/* ====  SiteUserHandle-Render list of search results (in Property Details dialog) ==== */
const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  isCRMS?: boolean
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {(isCRMS ? colSearchPropertyAddresses : colSearchAddresses).map(
        (col: IColumnFields) => (
          <div key={col.field} style={{ width: col.width }}>
            {dataItem[col.field] ?? ""}
          </div>
        )
      )}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
/* ====  / SiteUserHandle-Render list of search results (in Property Details dialog) ==== */
