import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { isAfter, isBefore } from "date-fns";
import { isNull } from "lodash";

export const handleValidateForm: any = (data: any) => {
  const periodStart = data.PeriodStart;
  const periodEnd = data.PeriodEnd;
  const issueDate = data.IssueDate;

  let errorMessage = {};

  if (!periodStart || !periodEnd || !issueDate) {
    errorMessage = {
      VALIDATION_SUMMARY:
        "Issue Date, Period Start or Period End is incorrect.",
    };
  }

  if (
    !isNull(issueDate) &&
    isBefore(new Date(issueDate), new Date()) &&
    formatDateByKendo(new Date(issueDate), DATE_FORMAT.DATE_CONTROL) !==
      formatDateByKendo(new Date(), DATE_FORMAT.DATE_CONTROL)
  ) {
    errorMessage = {
      ...errorMessage,
      IssueDate: "Issue Date must be greater than or equal to Current Date",
    };
  }
  if (isNull(periodStart)) {
    errorMessage = {
      ...errorMessage,
      PeriodStart: " ",
    };
  }
  if (isNull(periodEnd)) {
    errorMessage = {
      ...errorMessage,
      PeriodEnd: " ",
    };
  }
  if (
    !isNull(periodStart) &&
    !isNull(periodEnd) &&
    !isAfter(new Date(periodEnd), new Date(periodStart))
  ) {
    errorMessage = {
      ...errorMessage,
      PeriodStart: "Period Start must be less than Period End",
      PeriodEnd: "Period End must be greater than Period Start",
    };
  }

  return errorMessage;
};
