import { getViewConfigurations } from "@app/products/property/api";
import {
  VO_Land_Rent,
  VO_Land_Rent_Action_Date,
} from "@app/products/property/assessments/[id]/components/child-screens/land-rent/model";
import {
  LAND_RENT_ACTION_DATE_ODATA,
  LAND_REST_ODATA,
} from "@app/products/property/assessments/[id]/components/child-screens/land-rent/util";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colAssessedLandRent, colLandRentActionDate } from "./config";

const nameOfLandRent = nameOfFactory<VO_Land_Rent>();
const nameOfLandRentActionDate = nameOfFactory<VO_Land_Rent_Action_Date>();

export const LandRent = observer(() => {
  const { assessmentId } = useAssessmentStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedLandRentCol, setProcessedLandRentCol] =
    useState<IColumnFields[]>(colAssessedLandRent);
  const [processedLandRentActionDateCol, setProcessedLandRentActionDateCol] =
    useState<IColumnFields[]>(colLandRentActionDate);

  const [landRentActionDateData, setLandRentActionDateData] = useState<string>(
    LAND_RENT_ACTION_DATE_ODATA(assessmentId)
  );

  const handleLoadConfigure = async (viewConfigEnum: ViewConfiguration) => {
    const response = await getViewConfigurations(viewConfigEnum);

    if (isSuccessResponse(response)) {
      const viewConfig: ICCViewColumn[] | undefined =
        response?.data?.ColumnDefinitions?.Columns;
      if (!viewConfig || viewConfig?.length === 0) {
        setResponseLoadError({
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Load configure column failed",
        });
      } else {
        switch (viewConfigEnum) {
          case ViewConfiguration.Assessment_LandRent:
            setProcessedLandRentCol(
              processDynamicColumns(colAssessedLandRent, viewConfig)
            );
            break;
          case ViewConfiguration.Assessment_LandRentActionDates:
            setProcessedLandRentActionDateCol(
              processDynamicColumns(colLandRentActionDate, viewConfig)
            );
            break;
        }
      }
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load configure column failed",
      });
    }
  };

  const loadConfiguration = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    await handleLoadConfigure(ViewConfiguration.Assessment_LandRent);
    await handleLoadConfigure(ViewConfiguration.Assessment_LandRentActionDates);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadConfiguration();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadConfiguration();
        }}
      />
    );
  if (isLoading) return <Loading isLoading />;

  return (
    <div className="cc-property-land-rent">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Assessed land rent</label>
              <CCGrid
                className="cc-arrangement-grid-data"
                dataUrl={LAND_REST_ODATA(assessmentId)}
                columnFields={processedLandRentCol}
                primaryField={nameOfLandRent("ALR_Id")}
                onSelectionChange={(elements: VO_Land_Rent[]) => {
                  setLandRentActionDateData(
                    LAND_RENT_ACTION_DATE_ODATA(
                      assessmentId,
                      elements?.[elements?.length - 1]?.Rating_Period_Id
                    )
                  );
                }}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Land rent action date</label>
              <CCGrid
                className="cc-arrangement-grid-data"
                dataUrl={landRentActionDateData}
                columnFields={processedLandRentActionDateCol}
                primaryField={nameOfLandRentActionDate("Id")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
