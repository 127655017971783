import { EventManagementFieldMapping } from "@app/products/event-management/constant";

export const EventTypeColumns = [
  {
    title: EventManagementFieldMapping.EventTypes,
    field: EventManagementFieldMapping.Name,
  },
  {
    title: EventManagementFieldMapping.Description,
    field: EventManagementFieldMapping.Description,
  },
];
