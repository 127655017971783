import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import {
  isApprovalSpatialGIS,
  isShowViewMappingButton,
  isSingleSpatialGIS,
  isSpatialGISMode,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
} from "@app/products/property/model";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useEffectOnce } from "react-use";

interface IWorkflowGroupButtonsSpatial {
  workflowHeader?: DTO_WorkflowHeader;
  actionSubmit: (event: any) => void;
  // TODO will change when apply for all workflow, now just apply for create holding workflow
  isApplySingleGIS?: boolean;
}
export const WorkflowGroupButtonsSpatial = observer(
  ({
    workflowHeader,
    actionSubmit,
    isApplySingleGIS = false,
  }: IWorkflowGroupButtonsSpatial) => {
    const { buttonFetch, buttonMapping, resetData } =
      useWorkflowGroupButtonsSpatialStore();

    const isShowGroupsButton = useMemo(() => {
      return isSpatialGISMode(workflowHeader);
    }, [workflowHeader]);

    const isShowSingleSpatialGIS = useMemo(() => {
      return isSingleSpatialGIS(workflowHeader);
    }, [workflowHeader]);

    const isShowSpatialGroupsButton = useMemo(() => {
      return isApprovalSpatialGIS(workflowHeader) || isShowSingleSpatialGIS;
    }, [workflowHeader, isShowSingleSpatialGIS]);

    useEffectOnce(() => {
      return () => resetData();
    });

    return (
      <>
        {/* 
          // TODO will change when apply for all workflow, now just apply for create holding workflow
       */}
        {isApplySingleGIS && isShowSpatialGroupsButton ? (
          <>
            <Button
              className={"cc-dialog-button"}
              iconClass={
                buttonMapping?.isLoading ? "fas fa-spinner fa-spin" : ""
              }
              disabled={buttonMapping?.isDisabled || buttonMapping?.isLoading}
              id={EListSubmitButton.MappingSpatialWorkflow}
              onClick={actionSubmit}
            >
              Mapping
            </Button>
            <Button
              iconClass={buttonFetch?.isLoading ? "fas fa-spinner fa-spin" : ""}
              className={"cc-dialog-button"}
              id={EListSubmitButton.FetchSpatialWorkflow}
              disabled={buttonFetch?.isDisabled || buttonFetch?.isLoading}
              onClick={actionSubmit}
            >
              Fetch data
            </Button>
          </>
        ) : isShowGroupsButton && !isNil(workflowHeader) ? (
          <>
            <Button
              className={"cc-dialog-button"}
              iconClass={
                buttonMapping?.isLoading ? "fas fa-spinner fa-spin" : ""
              }
              disabled={buttonMapping?.isDisabled || buttonMapping?.isLoading}
              id={EListSubmitButton.MappingSpatialWorkflow}
              onClick={actionSubmit}
            >
              Mapping
            </Button>
            <Button
              iconClass={buttonFetch?.isLoading ? "fas fa-spinner fa-spin" : ""}
              className={"cc-dialog-button"}
              id={EListSubmitButton.FetchSpatialWorkflow}
              disabled={buttonFetch?.isDisabled || buttonFetch?.isLoading}
              onClick={actionSubmit}
            >
              Fetch data
            </Button>
          </>
        ) : null}
        {/* Will show for user when GIS Single workflow for all mode */}
        {isApplySingleGIS && isShowViewMappingButton(workflowHeader) ? (
          <Button
            className={"cc-dialog-button"}
            id={EListSubmitButton.ViewMappingSpatialWorkflow}
            onClick={actionSubmit}
          >
            View Mapping
          </Button>
        ) : null}
      </>
    );
  }
);
