import { CommunicationQueue } from "@common/pages/settings/communication/queue/_id/model";
import { nameOfFactory } from "@common/utils/common";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCValueField } from "@components/cc-value-field/_index";
import { Editor, ProseMirror } from "@progress/kendo-react-editor";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface ISecurityOrgStructureFormElementProps {
  formRenderProps: FormRenderProps;
}
const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const nameOf = nameOfFactory<CommunicationQueue>();

export const CommunicationQueueFormElement = observer(
  ({ formRenderProps }: ISecurityOrgStructureFormElementProps) => {
    const { valueGetter } = formRenderProps;
    const editableRef = React.useRef<boolean>(true);
    const view = React.useRef<any>(null);
    const handleOnMount = (event: any) => {
      const state = event.viewProps.state;
      const plugins = [
        ...state.plugins,
        new Plugin({
          key: new PluginKey("readonly"),
          props: {
            editable: () => false,
          },
          filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
        }),
      ];
      view.current = new EditorView(
        {
          mount: event.dom,
        },
        {
          ...event.viewProps,
          state: EditorState.create({
            doc: state.doc,
            plugins,
          }),
        }
      );
      return view.current;
    };

    return (
      <FormElement className="cc-keyword-form-elements">
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCValueField
                name={nameOf("RecipientEmail")}
                label="To"
                value={valueGetter(nameOf("RecipientEmail"))}
              />
            </div>
            <div className="cc-field col-span-2">
              <CCValueField
                name={nameOf("SubjectContent")}
                label="Subject"
                value={sanitizeHtml(valueGetter(nameOf("SubjectContent")))}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Content</label>
              <Editor
                onMount={handleOnMount}
                defaultContent={valueGetter(nameOf("BodyContent"))}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
