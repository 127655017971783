import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  Svc_InfoExpertContainer,
  Svc_InfoExpertRecordType
} from "@common/models/corporateSetting";
import { IdentityPacket } from "@common/models/identityPacket";

export const getInfoXpertContainers = async (
  rootFolder: string,
  appendNoneOption: boolean
): Promise<
  APIResponse<IdentityPacket | Svc_InfoExpertContainer[] | any | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/settings/records/infoxpert/get-containers?rootFolder='${rootFolder}'&appendNoneOption=${appendNoneOption}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInfoXpertRecordTypes = async (): Promise<
  APIResponse<IdentityPacket | Svc_InfoExpertRecordType[] | any | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/settings/records/infoxpert/get-record-types`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
