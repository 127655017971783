import { getRegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/api";
import { IRegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class RegisterNamesStore {
  private _registerNames?: IRegisterNames = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get registerNames() {
    return toJS(this._registerNames);
  }
  setRegisterNames = (registerNames: IRegisterNames | undefined) => {
    runInAction(() => {
      this._registerNames = registerNames;
    });
  };

  //Action
  loadRegisterNames = async () => {
    this.setIsLoading(true);
    const data = await getRegisterNames();
    this.setRegisterNames(data);
    this.setIsLoading(false);
  };
}
const registerNamesStoreContext = createContext(new RegisterNamesStore());
export const useRegisterNamesStore = () => {
  return useContext(registerNamesStoreContext);
};
