import { EAnimalSettingsCategory } from "@app/products/animals/system-admin/settings/_id/main/model";
import { IRouteStateForm } from "@app/products/animals/system-admin/settings/_id/main/routeState";

export const poundRegisterRouteStateAnimalsSettings: IRouteStateForm[] = [
  {
    name: EAnimalSettingsCategory.PoundRegister_Detail,
    component: require("./forms/detail/_index").default,
  },
  {
    name: EAnimalSettingsCategory.PoundRegister_Numbering,
    component: require("./forms/numbering/_index").default,
  },
];
