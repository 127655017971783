export const ChargeRunBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Charge_Run_Id
      ? `- ${selectedRow.Charge_Run_Id}`
      : "";
    return `Property - Charge And Notice Run ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    const dynamicDetail = "Charge Run " + (selectedRow.Charge_Run_Id ?? "");
    return `${dynamicDetail}`;
  },
};

export const ChargeRunListBookmark = {
  getBookmarkListViewDisplayName() {
    return "Property - Charge And Notice Run";
  },

  getBookmarkListViewDetail() {
    return "Property - Charge And Notice Run - Charge Run";
  },
};
