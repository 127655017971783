import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingFinancePaidFees: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ReferenceNumber,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.ContactName,
    title: "Applicant",
  },
  {
    field: BuildingFieldMapping.CreatedDate,
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.FeeType,
    title: "Type",
  },
  {
    field: BuildingFieldMapping.AmountIssued,
    title: "Issued",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.AmountPaid,
    title: "Paid",
  },
  {
    field: BuildingFieldMapping.ReceiptNumber,
    title: "Receipt Number",
  },
  {
    field: BuildingFieldMapping.ReceiptDate,
    title: "Receipt Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: BuildingFieldMapping.ReceiptedBy,
    title: "Receipted By",
  },
  {
    field: BuildingFieldMapping.Method,
    title: "Method",
  },
  {
    field: BuildingFieldMapping.CostCode,
    title: "Cost Code",
  },
  {
    field: BuildingFieldMapping.InvoiceNumber,
    title: "Invoice Number",
  },
  {
    field: BuildingFieldMapping.DebtorNumber,
    title: "Debtor Number",
  },
  {
    field: BuildingFieldMapping.SecurePay_Reference,
    title: "Payment Reference",
  },
];
