import { odataCoreGetInvoices } from "@app/core/components/common/utils";
import { colInvoices } from "@app/core/invoice/invoice-accordion/config";
import { Svc_InvoiceAccordion } from "@app/core/invoice/invoice-accordion/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IInvoicesProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<Svc_InvoiceAccordion>();
export const InvoicesAccordion = ({ id, recordType }: IInvoicesProps) => {
  return (
    <CCGrid
      columnFields={colInvoices}
      primaryField={nameOf("ID")}
      dataUrl={
        id === undefined ? undefined : odataCoreGetInvoices(id, recordType)
      }
    />
  );
};
