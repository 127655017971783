import { mockRegistersList } from "@app/products/property/registers/list/mock";
import { mockRegisterSummary } from "@app/products/property/registers/[id]/mock";
import { IRegistersSummary } from "@app/products/property/registers/[id]/model";
import { sleep } from "@common/utils/common";

export const loadRegisterDetailsTabById = async (
  registerId: number
): Promise<IRegistersSummary | undefined> => {
  await sleep(1000);
  const recordRegister = mockRegistersList.find(
    (item) => item.Id === registerId
  );
  return {
    ...recordRegister,
    ...mockRegisterSummary,
    Id: registerId,
  };
};
