import {
  DTO_Workflow_Journal__AddAssessmentTransaction,
  RequestJournalAddAssessmentTransactionObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { DTO_Journal_Add_Transaction_LOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessNewAssessmentTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_Journal__AddAssessmentTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createassessmentjournal/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataJournal =
  | [
      APIResponse<DTO_Journal_Add_Transaction_LOVs>,
      APIResponse<DTO_Workflow_Journal__AddAssessmentTransaction>
    ]
  | APIResponse<
      | DTO_Journal_Add_Transaction_LOVs
      | DTO_Workflow_Journal__AddAssessmentTransaction
    >
  | undefined;
export const getInitialDataAssessmentTransaction = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  assessmentId: number[] | undefined
): Promise<IGetInitialDataJournal> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_Add_Transaction_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      isFromActionList
        ? getWorkflowCreateAssessmentTransaction(workflowDraftId)
        : getNewWorkflowNewAssessmentTransaction({
            Assessment_Ids: assessmentId,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowNewAssessmentTransaction = async (
  requestObj: RequestJournalAddAssessmentTransactionObj | object = {}
): Promise<APIResponse<DTO_Workflow_Journal__AddAssessmentTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/createassessmentjournal/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowCreateAssessmentTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_Journal__AddAssessmentTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/createassessmentjournal/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
