import { DTO_Notice } from "@app/products/property/deferred-duty/[id]/components/child-screens/notices/model";
import { useDeferredDutyNoticesStore } from "@app/products/property/deferred-duty/[id]/components/child-screens/notices/store";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colNotices } from "./config";
const nameOf = nameOfFactory<DTO_Notice>();
export const DeferredDutyNotices = observer(() => {
  //store
  const { deferredDutyAccountId } = useDeferredDutyStore();
  const {
    loadDeferredDutyNotices,
    resetStore,
    deferredDutyNotices,
    isLoading,
  } = useDeferredDutyNoticesStore();

  useEffect(() => {
    deferredDutyAccountId && loadDeferredDutyNotices(deferredDutyAccountId);
    return () => {
      resetStore();
    };
  }, [deferredDutyAccountId, loadDeferredDutyNotices, resetStore]);

  return (
    <div className="cc-deferred-duty-notices">
      <CCGrid
        className="cc-notices-grid"
        data={deferredDutyNotices ?? []}
        columnFields={colNotices}
        primaryField={nameOf("NoticeId")}
        isLoading={isLoading}
      />
    </div>
  );
});
