import {
  CSLRegisterForm,
  CSLRegisterRequest,
  CSLResponse,
} from "@app/products/csl/[id]/model";
import { formatDateByKendo } from "@common/utils/formatting";
import { cloneDeep } from "lodash";

export const convertCSLResponseToForm = (response: CSLResponse | undefined) => {
  if (!response) return undefined;

  let cslForm = new CSLRegisterForm();
  cslForm = cloneDeep(response) as CSLRegisterForm;

  cslForm.licenceCategory = `${response.LicenceCategory?.Name} (${response.LicenceCategory?.Code})`;

  cslForm.licensee = response.Applicant?.Contact.DisplayName || "";
  cslForm.address = response.LocalityUsedAddress?.AddressLine1 || "";
  if (
    response.LocalityUsedAddress?.StreetName &&
    response.LocalityUsedAddress.StreetName
  ) {
    cslForm.postalAddress = `${response.LocalityUsedAddress?.StreetNo} ${response.LocalityUsedAddress?.StreetName}`;
  }

  cslForm.tradingName = response.Supplier?.Contact.TradingName || "";

  if (
    response.Applicant?.Contact._HomePhone &&
    response.Applicant.Contact._Mobile
  ) {
    cslForm.phone = `(H) ${response.Applicant?.Contact._HomePhone}
                    (W) ${response.Applicant?.Contact._WorkPhone}`;
  }
  cslForm.mobile = response.Applicant?.Contact._Mobile || "";

  if (response.Applicant?.Contact.Email && response.Applicant.Contact.Website) {
    cslForm.emailOrWeb = `${response.Applicant?.Contact.Email}
                        ${response.Applicant?.Contact.Website}`;
  }

  if (response.Business?.Contact.DisplayName) {
    cslForm.contact = `${response.Business?.Contact.Salutation} ${response.Business?.Contact.DisplayName}`;
  }

  cslForm.contactPosition = ``;
  if (
    response.Business?.Contact._HomePhone &&
    response.Business.Contact._WorkPhone
  ) {
    cslForm.contactPhone = `${response.Business?.Contact._HomePhone}
                            ${response.Business?.Contact._WorkPhone}`;
  }

  cslForm.contactMobile = response.Business?.Contact._Mobile || "";
  cslForm.contactEmail = response.Business?.Contact.Email || "";

  if (
    response.LocalityUsedAddress?.StreetNo &&
    response.LocalityStoredAddress?.StreetName
  ) {
    cslForm.siteUsed = `${response.LocalityUsedAddress?.StreetNo} ${response.LocalityStoredAddress?.StreetName}`;
  }

  cslForm.assessingOfficer = response.AssessingOfficer_Name || "";
  cslForm.approvingOfficer = response.ApprovingOfficer_Name || "";
  cslForm.lodged = formatDateByKendo(response.DateLodged);
  cslForm.approved = formatDateByKendo(response.DateEthicsCommitteeApproval);
  cslForm.signedForm = formatDateByKendo(response.Sys_CreatedDate);
  cslForm.issued = formatDateByKendo(response.Sys_TransactionDate);
  cslForm.expires = formatDateByKendo(response.DateExpires);

  return cslForm;
};

export const convertCSLFormToRegisterRequest = (cslForm: CSLRegisterForm) => {
  let cslRequest = new CSLRegisterRequest();
  // cslRequest = cloneDeep(cslForm);
  cslRequest.Sys_TimeStamp = cslForm.Sys_TimeStamp;
  cslRequest.Licence_ID = cslForm.Licence_ID;
  cslRequest.LicenceCategory_ID = cslForm.LicenceCategory_ID;
  cslRequest.LicenceCategory = cslForm.LicenceCategory;
  cslRequest.Status_ENUM = cslForm.Status_ENUM;
  cslRequest.LicencePeriod_ENUM = cslForm.LicencePeriod_ENUM;
  cslRequest.LicenceHistory = cslForm.LicenceHistory;
  cslRequest.LicenceNumber = cslForm.LicenceNumber;
  cslRequest.FileNumber = cslForm.FileNumber;
  cslRequest.ReferenceNumber = cslForm.ReferenceNumber;
  cslRequest.DebtorNumber = cslForm.DebtorNumber;
  cslRequest.DrugsPossessed = cslForm.DrugsPossessed;
  cslRequest.StorageArrangements = cslForm.StorageArrangements;
  cslRequest.MaxQuantity = cslForm.MaxQuantity;
  cslRequest.PurposeOf = cslForm.PurposeOf;
  cslRequest.Dosage = cslForm.Dosage;
  cslRequest.IntendedToBeSoldTo = cslForm.IntendedToBeSoldTo;
  cslRequest.FumigationCourseGrading = cslForm.FumigationCourseGrading;
  cslRequest.LocalityUsed = cslForm.LocalityUsed;
  cslRequest.LocalityStored = cslForm.LocalityStored;
  cslRequest.OfficersLocations = cslForm.OfficersLocations;
  cslRequest.DamageCaused = cslForm.DamageCaused;
  cslRequest.AmountRequired = cslForm.AmountRequired;
  cslRequest.AccessPrecautions = cslForm.AccessPrecautions;
  cslRequest.PollutionPrecautions = cslForm.PollutionPrecautions;
  cslRequest.AgSafeAccredited = cslForm.AgSafeAccredited;
  cslRequest.Misc1 = cslForm.Misc1;
  cslRequest.Misc2 = cslForm.Misc2;
  cslRequest.Misc3 = cslForm.Misc3;
  cslRequest.ExemptedPersons = cslForm.ExemptedPersons;
  cslRequest.PersonsApprovedToOrder = cslForm.PersonsApprovedToOrder;
  cslRequest.PersonsApprovedToAccess = cslForm.PersonsApprovedToAccess;
  cslRequest.Patient = cslForm.Patient;
  cslRequest.EmployerFreeText = cslForm.EmployerFreeText;
  cslRequest.SupervisedBy = cslForm.SupervisedBy;
  cslRequest.AdditionalLocations = cslForm.AdditionalLocations;
  cslRequest.StorageLocation = cslForm.StorageLocation;
  cslRequest.Reg25Poisons = cslForm.Reg25Poisons;
  cslRequest.LicenceHistoryCount = cslForm.LicenceHistoryCount;
  cslRequest.TimesRenewed = cslForm.TimesRenewed;
  cslRequest.Flag_SignedDeclaration = cslForm.Flag_SignedDeclaration;
  cslRequest.Flag_WrittenConsent = cslForm.Flag_WrittenConsent;
  cslRequest.Flag_EthicsCommitteeApproval =
    cslForm.Flag_EthicsCommitteeApproval;
  cslRequest.Flag_CatBApproval = cslForm.Flag_CatBApproval;
  cslRequest.Flag_CatANotification = cslForm.Flag_CatANotification;
  cslRequest.Flag_Research = cslForm.Flag_Research;
  cslRequest.Flag_RenewalFeeCreated = cslForm.Flag_RenewalFeeCreated;
  cslRequest.Flag_RenewalNoticeSent = cslForm.Flag_RenewalNoticeSent;
  cslRequest.Flag_RequiresCertificate = cslForm.Flag_RequiresCertificate;
  cslRequest.Flag_FullTechnicianHolder = cslForm.Flag_FullTechnicianHolder;
  cslRequest.Flag_CertOfCompanyReg = cslForm.Flag_CertOfCompanyReg;
  cslRequest.Flag_CertOfTradingNameReg = cslForm.Flag_CertOfTradingNameReg;
  cslRequest.Flag_EmployerSignedDec = cslForm.Flag_EmployerSignedDec;
  cslRequest.Flag_SupervisorSignedDec = cslForm.Flag_SupervisorSignedDec;
  cslRequest.Flag_TrainingSkillsDec = cslForm.Flag_TrainingSkillsDec;
  cslRequest.Flag_PhotographMeetsReq = cslForm.Flag_PhotographMeetsReq;
  cslRequest.Flag_WitnessMeetsReq = cslForm.Flag_WitnessMeetsReq;
  cslRequest.Flag_WitnessSignedDec = cslForm.Flag_WitnessSignedDec;
  cslRequest.Flag_StatementOfAttainment = cslForm.Flag_StatementOfAttainment;
  cslRequest.Flag_OnJobCompetencyAssessment =
    cslForm.Flag_OnJobCompetencyAssessment;
  cslRequest.Flag_SpecifyMedicines = cslForm.Flag_SpecifyMedicines;
  cslRequest.Flag_TheftLossPolicyRequired =
    cslForm.Flag_TheftLossPolicyRequired;
  cslRequest.Flag_TransportCodeRequired = cslForm.Flag_TransportCodeRequired;
  cslRequest.Flag_VetDrugUseReportRequired =
    cslForm.Flag_VetDrugUseReportRequired;
  cslRequest.Flag_PaymentJournalRequired = cslForm.Flag_PaymentJournalRequired;
  cslRequest.Flag_CountryHealthNetwork = cslForm.Flag_CountryHealthNetwork;
  cslRequest.Flag_FumigationCourse = cslForm.Flag_FumigationCourse;
  cslRequest.Flag_BiosecuritySAApproval = cslForm.Flag_BiosecuritySAApproval;
  cslRequest.Flag_WholesaleReport = cslForm.Flag_WholesaleReport;
  cslRequest.Flag_KnownEmployer = cslForm.Flag_KnownEmployer;
  cslRequest.Flag_DoNotRenew = cslForm.Flag_DoNotRenew;
  cslRequest.Applicant = cslForm.Applicant;
  cslRequest.Applicant_RID = cslForm.Applicant_RID;
  cslRequest.Business = cslForm.Business;
  cslRequest.Business_RID = cslForm.Business_RID;
  cslRequest.Supplier = cslForm.Supplier;
  cslRequest.Supplier_RID = cslForm.Supplier_RID;
  cslRequest.AssessingOfficer_ID = cslForm.AssessingOfficer_ID;
  cslRequest.AssessingOfficer_Name = cslForm.AssessingOfficer_Name;
  cslRequest.ApprovingOfficer_ID = cslForm.ApprovingOfficer_ID;
  cslRequest.ApprovingOfficer_Name = cslForm.ApprovingOfficer_Name;
  cslRequest.DateLodged = cslForm.DateLodged;
  cslRequest.DateExpires = cslForm.DateExpires;
  cslRequest.DateEthicsCommitteeApproval = cslForm.DateEthicsCommitteeApproval;
  cslRequest.Date_VetDrugUseReportRequired =
    cslForm.Date_VetDrugUseReportRequired;
  cslRequest.ConditionTreated_Display = cslForm.ConditionTreated_Display;
  cslRequest.RegDrugPoison = cslForm.RegDrugPoison;
  cslRequest.RegDrugPoison_Namelist = cslForm.RegDrugPoison_Namelist;
  cslRequest.TypesofPoison = cslForm.TypesofPoison;
  cslRequest.PoisonSchedule = cslForm.PoisonSchedule;
  cslRequest.PoisonSchedule_Namelist = cslForm.PoisonSchedule_Namelist;
  cslRequest.PestType = cslForm.PestType;
  cslRequest.PestType_Namelist = cslForm.PestType_Namelist;
  cslRequest.BusinessType = cslForm.BusinessType;
  cslRequest.BusinessType_Namelist = cslForm.BusinessType_Namelist;
  cslRequest.LocalityUsedAddress = cslForm.LocalityUsedAddress;
  cslRequest.LocalityUsedAddress_ID = cslForm.LocalityUsedAddress_ID;
  cslRequest.LocalityStoredAddress = cslForm.LocalityStoredAddress;
  cslRequest.LocalityStoredAddress_ID = cslForm.LocalityStoredAddress_ID;
  cslRequest.Activity_IDs = cslForm.Activity_IDs;
  cslRequest.Activity_CSV = cslForm.Activity_CSV;
  cslRequest.ResearchProgramName = cslForm.ResearchProgramName;
  cslRequest.EndorsementSpecialistSpeciality_IDs =
    cslForm.EndorsementSpecialistSpeciality_IDs;
  cslRequest.EndorsementSpecialistSpeciality_CSV =
    cslForm.EndorsementSpecialistSpeciality_CSV;
  cslRequest.ApplicationDetails = cslForm.ApplicationDetails;
  cslRequest.Sys_EnterpriseKey = cslForm.Sys_EnterpriseKey;
  cslRequest.Sys_CreatedDate = cslForm.Sys_CreatedDate;
  cslRequest.Sys_CreatedBy_ID = cslForm.Sys_CreatedBy_ID;
  cslRequest.Sys_DBRowState = cslForm.Sys_DBRowState;
  cslRequest.Sys_TransactionDate = cslForm.Sys_TransactionDate;
  cslRequest.Sys_ModifiedDate = cslForm.Sys_ModifiedDate;
  cslRequest.Sys_ModifiedBy_ID = cslForm.Sys_ModifiedBy_ID;
  cslRequest.Sys_StatClockStatus_ENUM = cslForm.Sys_StatClockStatus_ENUM;
  cslRequest.EthicsCommitteeApproval = cslForm.EthicsCommitteeApproval;

  return cslRequest;
};
