import { colWriteOffSmallBalances } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/config";
import {
  DTO_Component_External_Process,
  DTO_Variable,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import { processPayloadData } from "@app/products/property/assessments/components/dialogs/write-off-small-balances/util";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  getWriteOffSmallBalancesVariables,
  postExecuteWriteOffSmallBalances,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/api";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { map } from "lodash";

interface IWriteOffSmallBalancesProps {
  onClose: () => void;
}

//#region 5617: Requirement: Hide the additional text boxes that are appearing when clicking in the value field
// Nicole will create a task in next Sprint to handle the specific case for Assessment and Journal

// interface IChildComponent {
//   isUseCCSearchComboBox?: boolean;
//   isUseCCInput?: boolean;
//   isUseCCCurrencyInput?: boolean;
//   isUseCheckbox?: boolean;
//   valueGetter?: (name: string) => any;
//   onChange: (
//     name: string,
//     options: {
//       value: any;
//     }
//   ) => void;
// }
//#endregion

const nameOf = nameOfFactory<DTO_Variable>();
export const WriteOffSmallBalancesDialog = observer(
  ({ onClose }: IWriteOffSmallBalancesProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowConfirmDialog, setIsShowConfirmDialog] =
      useState<boolean>(false);
    const [data, setData] = useState<DTO_Component_External_Process>();
    const [variablesData, setVariablesData] = useState<DTO_Variable[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

    //#region 5617: Same as the requirement above
    // const childComponent = ({
    //   isUseCCSearchComboBox,
    //   isUseCCInput,
    //   isUseCCCurrencyInput,
    //   isUseCheckbox,
    //   valueGetter,
    //   onChange,
    // }: IChildComponent) => {
    //   return (
    //     <div>
    //       <div className="cc-form-cols-5">
    //         <div className="cc-field cc-col-span-2">
    //           <label className="cc-label custom-label">
    //             {lastSelectedRow?.Variable_Prompt}
    //             {isUseCheckbox && (
    //               <>
    //                 &emsp;
    //                 <Field
    //                   component={Checkbox}
    //                   name="checkbox"
    //                   onChange={({ value }: CheckboxChangeEvent) => {
    //                     changeGridData(
    //                       variablesData,
    //                       lastSelectedRow,
    //                       +value,
    //                       setVariablesData
    //                     );
    //                     onChange("checkbox", { value });
    //                   }}
    //                 />
    //               </>
    //             )}
    //           </label>
    //         </div>
    //         <div className="cc-field cc-col-span-2">
    //           {isUseCCSearchComboBox && valueGetter && (
    //             <>
    //               <Field
    //                 name="dropdown"
    //                 component={CCSearchComboBox}
    //                 data={mockDropDownData}
    //                 textField={nameOfLov("Name")}
    //                 dataItemKey={nameOfLov("Code")}
    //                 value={getDropdownValue(
    //                   valueGetter("dropdown"),
    //                   mockDropDownData,
    //                   nameOfLov("Code")
    //                 )}
    //                 onChange={(event: ComboBoxChangeEvent) => {
    //                   onChange("dropdown", {
    //                     value: event.value?.Code ?? null,
    //                   });
    //                 }}
    //               />
    //               <br />
    //               <Field
    //                 name="textarea"
    //                 component={CCTextArea}
    //                 rows={3}
    //                 onChange={({ value }: TextAreaChangeEvent) => {
    //                   changeGridData(
    //                     variablesData,
    //                     lastSelectedRow,
    //                     value,
    //                     setVariablesData
    //                   );
    //                   onChange("textarea", { value });
    //                 }}
    //               />
    //             </>
    //           )}
    //           {isUseCCInput && (
    //             <Field
    //               name="input"
    //               component={
    //                 isUseCCCurrencyInput ? CCCurrencyInput : CCNumericTextBox
    //               }
    //               onChange={({ value }: InputChangeEvent) => {
    //                 changeGridData(
    //                   variablesData,
    //                   lastSelectedRow,
    //                   value,
    //                   setVariablesData
    //                 );
    //                 onChange("input", { value });
    //               }}
    //             />
    //           )}
    //         </div>
    //         <div className="cc-field">
    //           <Checkbox label="Save on default" />
    //         </div>
    //       </div>
    //     </div>
    //   );
    // };

    // const renderComponent = (
    //   valueGetter: (name: string) => any,
    //   onChange: (
    //     name: string,
    //     options: {
    //       value: any;
    //     }
    //   ) => void
    // ) => {
    //   if (!valueGetter("dropdown")) {
    //     onChange("dropdown", {
    //       value: mockDropDownData[0].Code ?? null,
    //     });
    //   }

    //   switch (lastSelectedRow?.Variable_Data_Type) {
    //     case eSqlDataType.VARCHAR:
    //       return childComponent({
    //         isUseCCSearchComboBox: true,
    //         valueGetter,
    //         onChange,
    //       });
    //     case eSqlDataType.MONEY:
    //       return childComponent({
    //         isUseCCInput: true,
    //         isUseCCCurrencyInput: true,
    //         onChange,
    //       });
    //     case eSqlDataType.BIT:
    //       return childComponent({ isUseCheckbox: true, onChange });
    //     case eSqlDataType.INT:
    //       return childComponent({ isUseCCInput: true, onChange });
    //     default:
    //       return null;
    //   }
    // };
    //#endregion

    const handleConfirmFinishProcess = async () => {
      setIsConfirmLoading(true);
      const response = await postExecuteWriteOffSmallBalances(
        processPayloadData(variablesData, data)
      );
      if (isSuccessResponse(response) && response.data.IsSuccess) {
        onClose();
        pushNotification({
          title:
            response.data.SuccessMessage ??
            "Write off small balances processed successfully",
          type: "success",
        });
      } else {
        setIsShowConfirmDialog(false);
        notificationRef.current?.pushNotification({
          title:
            response.data.ErrorMessage ??
            "Write off small balances processed failed",
          type: "error",
          autoClose: false,
        });
      }
      setIsConfirmLoading(false);
    };

    useEffectOnce(() => {
      (async () => {
        setIsLoading(true);
        const response = await getWriteOffSmallBalancesVariables();
        if (isSuccessResponse(response) && response.data) {
          setData(response.data);
          setVariablesData(response.data.Variables);
        } else {
          notificationRef.current?.pushNotification({
            title: "Load variables failed",
            type: "error",
            autoClose: false,
          });
        }
        setIsLoading(false);
      })();
    });

    return (
      <Form
        render={(_formRenderProps: FormRenderProps) => {
          return (
            <>
              <CCDialog
                isLoading={isLoading}
                disabled={isLoading}
                maxWidth="60%"
                height={"auto"}
                titleHeader={
                  "Set External Process Variable - Write off small balances"
                }
                onClose={onClose}
                bodyElement={
                  <FormElement className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-field-group">
                      <div className="cc-field">
                        <CCGrid
                          columnFields={colWriteOffSmallBalances}
                          data={variablesData}
                          primaryField={nameOf("Variable_Id")}
                          selectableMode="multiple"
                          itemPerPage={10}
                          editableMode="cell"
                          onDataChange={(dataItem: DTO_Variable[]) => {
                            const newVariablesData = map(
                              dataItem,
                              (item: DTO_Variable) => ({
                                ...item,
                                Variable_Value: item.Variable_Default,
                              })
                            );

                            setVariablesData(newVariablesData);
                          }}
                        />
                      </div>
                    </div>
                  </FormElement>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      onClick={() => setIsShowConfirmDialog(true)}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
              {isShowConfirmDialog && (
                <ConfirmDialog
                  title="Confirmation"
                  subMessage={"Are you sure you want to submit?"}
                  onClosePopup={() => setIsShowConfirmDialog(false)}
                  onAsyncConfirm={handleConfirmFinishProcess}
                  isLoadingYes={isConfirmLoading}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
