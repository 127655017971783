import { KEY_ENTER } from "@components/cc-input/contant";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  Input,
  InputProps,
  TextArea,
  TextAreaProps,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { handleFormatEmail } from "./util";

interface ICCInputEmailProps {
  label?: string;
  tooltip?: ReactElement;
  validationMessage?: string | null;
  visited?: boolean;
  required?: boolean;
  isTextArea?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value: string;
  name: string;
  onChangeForm: (
    name: string,
    options: {
      value: string;
    }
  ) => void;
}

export const CCInputEmail = (
  props: ICCInputEmailProps & InputProps & TextAreaProps
) => {
  const {
    validationMessage,
    visited,
    readOnly,
    disabled,
    value,
    name,
    onChangeForm,
    isTextArea,
    label,
    tooltip,
    ...others
  } = props;
  const [valueEmail, setValueEmail] = useState(value);
  const [isFirstInit, setIsFirstInit] = useState<boolean>(true);

  const initValue = useMemo(() => {
    return handleFormatEmail(valueEmail, isTextArea, readOnly, disabled);
  }, [disabled, isTextArea, readOnly, valueEmail]);

  useEffect(() => {
    if (isFirstInit) return;
    const result = handleFormatEmail(
      valueEmail,
      isTextArea,
      readOnly,
      disabled
    );
    onChangeForm(name, {
      value: result,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueEmail, isTextArea]);

  return (
    <>
      {isTextArea ? (
        <>
          <label className="cc-label">
            {label}
            {tooltip
              ? tooltip
              : !readOnly &&
                !disabled && (
                  <CCTooltip
                    type="info"
                    position="auto"
                    content="You can enter multiple email addresses and separate them by comma (,) or semicolon (;) or enter/return."
                  />
                )}
          </label>
          <TextArea
            {...others}
            readOnly={readOnly}
            disabled={disabled}
            value={
              isFirstInit
                ? initValue?.replace(/emailInvalid/g, "")
                : valueEmail?.replace(/emailInvalid/g, "")
            }
            onChange={(e) => {
              setIsFirstInit(false);
              setValueEmail(e.value);
            }}
          />
        </>
      ) : (
        <>
          <label className="cc-label">
            {label} {tooltip}
          </label>
          <Input
            {...others}
            readOnly={readOnly}
            disabled={disabled}
            value={
              isFirstInit
                ? initValue?.replace(/emailInvalid/g, "")
                : valueEmail?.replace(/emailInvalid/g, "")
            }
            onChange={(e) => {
              setIsFirstInit(false);
              setValueEmail(e.value?.trim());
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" || e.keyCode === KEY_ENTER)
                e.preventDefault();
              if (props.onKeyPress) props.onKeyPress(e);
            }}
          />
        </>
      )}
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
