import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { isBefore } from "date-fns";
import { isNull } from "lodash";

export const validatorDate = (data: any) => {
  if (
    !isNull(data) &&
    isBefore(new Date(data), new Date()) &&
    formatDateByKendo(new Date(data), DATE_FORMAT.DATE_CONTROL) !==
      formatDateByKendo(new Date(), DATE_FORMAT.DATE_CONTROL)
  ) {
    return "Must be equal or greater than current date";
  }
  return "";
};
