import { getLabel } from "@app/products/property/components/label/config";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.AssessmentId
    ? `- ${selectedRow.AssessmentId}`
    : "";
  return `Property - ${getLabel.assessments()} ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Assessment,
    selectedRow.PropertyAddress,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return `Property - ${getLabel.assessments()}`;
};

export const getBookmarkListViewDetail = () => {
  return `Property - ${getLabel.assessments()} - Compliance - Land Tax Exemptions`;
};
