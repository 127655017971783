import { getBuildingPoolsSpasById } from "@app/products/building/pools-or-spas/[id]/components/reference-sidebar/detail/api";
import { IBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

export const PoolsOrSpasDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [poolsOrSpasInfo, setPoolsOrSpasInfoInfo] = useState<
    IBuildingPoolsSpas | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const poolsOrSpasId: any = lastSelectedRow?.ID ?? params.id;
  useEffect(() => {
    setIsLoading(true);
    getBuildingPoolsSpasById(poolsOrSpasId).then((data) => {
      setPoolsOrSpasInfoInfo(data);
      setIsLoading(false);
    });
  }, [poolsOrSpasId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!poolsOrSpasInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem expanded={true} title={""}>
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Number:"}
              value={poolsOrSpasInfo.RegisterNumber}
            />
            <ProductReferenceRow
              title={"Status:"}
              value={poolsOrSpasInfo.Status}
              success
            />
            <ProductReferenceRow
              title={"Lodged Date:"}
              value={formatDisplayValue(
                poolsOrSpasInfo.LodgedDate,
                DATE_FORMAT.DATE
              )}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
