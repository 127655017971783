import {
  apiCoreRefusageViewOnMap,
  apiCoreRefusageViewOnMapInView,
} from "@app/core/components/common/utils";
import {
  Svc_ListView_ViewOnMap,
  Svc_ViewOnMap,
} from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postViewOnMap = async (
  objViewOnMapRequest: Svc_ViewOnMap,
  cancelToken?: CancelToken
): Promise<APIResponse<IIdentityPacket<string> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<string>>(
      apiCoreRefusageViewOnMap(),
      objViewOnMapRequest,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postViewOnMapInView = async (
  objViewOnMapRequest: Svc_ListView_ViewOnMap,
  cancelToken?: CancelToken
): Promise<APIResponse<IIdentityPacket<string> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<string>>(
      apiCoreRefusageViewOnMapInView(),
      objViewOnMapRequest,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
