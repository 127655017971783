import { IMeterReadingDevices } from "@app/products/property/meters/reading-devices/model";

export const mockMeterReadingDevices: IMeterReadingDevices[] = [
  {
    DeviceName: "PDA_ALL",
    DeviceHostName: "PDA_ALL",
    AssignedReader: "Meter Reader 1",
    ActiveMeters: 4598,
    StandardReadings: 133,
    SpecialReadings: 0,
    ReadingsWaitingVerification: 133,
    ReadingsVoided: 5,
    StandardReadingRequired: 4594,
    SpecialReadingRequired: 2,
    ReadingDeviceId: 34,
  },
  {
    DeviceName: "PDA1",
    DeviceHostName: "PDA1",
    AssignedReader: "Meter Reader 1",
    StandardReadings: 43,
    SpecialReadings: 0,
    ReadingsWaitingVerification: 43,
    ReadingsVoided: 2,
    ReadingDeviceId: 21,
  },
  {
    DeviceName: "PDA2",
    DeviceHostName: "PDA2",
    AssignedReader: "Meter Reader 2",
    StandardReadings: 57,
    SpecialReadings: 0,
    ReadingsWaitingVerification: 57,
    ReadingsVoided: 0,
    ReadingDeviceId: 22,
  },
  {
    DeviceName: "PDA3",
    DeviceHostName: "PDA3",
    AssignedReader: "Meter Reader 3",
    StandardReadings: 70,
    SpecialReadings: 0,
    ReadingsWaitingVerification: 70,
    ReadingsVoided: 0,
    ReadingDeviceId: 23,
  },
  {
    DeviceName: "PDA4",
    DeviceHostName: "PDA4",
    AssignedReader: "Meter Reader 4",
    StandardReadings: 38,
    SpecialReadings: 0,
    ReadingsWaitingVerification: 38,
    ReadingsVoided: 1,
    ReadingDeviceId: 24,
  },
  {
    DeviceName: "Unassigned to any reading device",
    StandardReadings: 145,
    SpecialReadings: 2,
    ReadingsWaitingVerification: 117,
    ReadingsVoided: 13,
  },
];
