import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IntegratedReportSecurity } from "@common/pages/report/integrated-reports/component/dialogs/share-report/model";

export const getShareReport = async (
  id: number
): Promise<APIResponse<IntegratedReportSecurity | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/core/internal/integratedreports/sharedto/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
