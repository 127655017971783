import { getAddressMapShare } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/api";
import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { colAddressValidation } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/address-validation/config";
import { Address } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement } from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

export type statusAddressValidation = "OK" | "CANCEL" | "SKIP";
export interface IOnAction {
  status: statusAddressValidation;
  dataValidate: MapshareSuggestion | undefined;
}
export interface IAddressValidationDialogProps {
  onClose: () => void;
  formData: Address | undefined;
  onAction: ({ status, dataValidate }: IOnAction) => void;
}
const nameOf = nameOfFactory<MapshareSuggestion>();

export const AddressValidationDialog = ({
  onClose,
  formData,
  onAction,
}: IAddressValidationDialogProps) => {
  const [gridData, setGridData] = useState<MapshareSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataValidate, setDataValidate] = useState<MapshareSuggestion>();

  useEffectOnce(() => {
    (async () => {
      if (!formData) return;
      setIsLoading(true);
      const response = await getAddressMapShare(formData);
      if (isSuccessResponse(response)) {
        response.data && setGridData(response?.data?.suggestions);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notificationRef.current?.pushNotification({
          title: "Address map share load failed",
          type: "error",
        });
      }
    })();
  });

  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const handleSubmit = (status: statusAddressValidation) => {
    onClose();
    onAction({ status, dataValidate });
  };

  return (
    <Form
      render={() => {
        return (
          <FormElement>
            <CCDialog
              isLoading={isLoading}
              disabled={isLoading}
              maxWidth="550px"
              height="auto"
              titleHeader="Address Validation"
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCGrid
                          selectableMode="single"
                          data={gridData}
                          primaryField={nameOf("text")}
                          columnFields={colAddressValidation}
                          onSelectionChange={(
                            dataItem: MapshareSuggestion[]
                          ) => {
                            setDataValidate(dataItem?.[0] ?? []);
                          }}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      handleSubmit("SKIP");
                    }}
                  >
                    Skip Selection
                  </Button>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      handleSubmit("CANCEL");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!dataValidate}
                    themeColor="primary"
                    onClick={() => {
                      handleSubmit("OK");
                    }}
                    className={"cc-dialog-button"}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
