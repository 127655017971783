import { NotificationType } from "@common/constants/notificationtype";
import { useGlobalStore } from "@common/stores/global/store";
import { observer } from "mobx-react-lite";
import { default as React } from "react";
import "./_index.scss";

const Notifications = () => {
  const { isNotify, notificationType, notificationsList } = useGlobalStore();

  if (!isNotify) return <></>;

  return (
    <div className="cc-notification-container">
      {notificationType === NotificationType.Success && (
        <div className="cc-message-container-success cc-notification">
          <div className="cc-message-container-icon">
            <i className="far fa-check"></i>
          </div>
          <div className="cc-message-container-Inner">
            {notificationsList.length > 0
              ? notificationsList[0].toString()
              : "Record successfully saved."}
          </div>
        </div>
      )}
      {notificationType === NotificationType.Warning && (
        <div className="cc-message-container-warning cc-notification">
          <div className="cc-message-container-icon">
            <i className="fas fa-pennant"></i>
          </div>
          <div className="cc-message-container-Inner">
            {notificationsList.length > 0
              ? notificationsList[0].toString()
              : null}
          </div>
        </div>
      )}
      {notificationType === NotificationType.Error && (
        <div className="cc-message-container-error cc-notification">
          <div className="cc-message-container-icon">
            <i className="fas fa-exclamation"></i>
          </div>
          <div className="cc-message-container-Inner">
            {notificationsList.length > 0
              ? notificationsList[0].toString()
              : null}
          </div>
        </div>
      )}

      {notificationType === NotificationType.Information && (
        <div className="cc-message-container-info cc-notification">
          <div className="cc-message-container-icon">
            <i className="fas fa-info"></i>
          </div>
          <div className="cc-message-container-Inner">
            {notificationsList.length > 0
              ? notificationsList[0].toString()
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(Notifications);
