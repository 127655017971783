import { DTO_Transaction } from "@app/products/property/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<DTO_Transaction>();

export const colTransactions: IColumnFields[] = [
  {
    field: nameOf("DateTime"),
    title: "Date/Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOf("Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
