import { KeyValuePair } from "@app/core/documents/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { OrgUnit } from "@common/pages/settings/security/org-structure/_id/model";

export const getOrgUnitById = async (
  orgUnitId: number
): Promise<APIResponse<OrgUnit | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<OrgUnit>(
      `api/core/internal/orgunit/${orgUnitId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getOrgStructureLevel = async (
  parentId?: number
): Promise<APIResponse<KeyValuePair<number, string>[]>> => {
  try {
    return await CoreAPIService.getClient().get<KeyValuePair<number, string>[]>(
      `/api/core/internal/orgunit/level`,
      {
        params: {
          parentId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

// export const getOrgStructureLevel = (parentOrgUnitId?: number) =>
//   [
//     {
//       Key: 1,
//       Value: "aaaaaa",
//     },
//     {
//       Key: 2,
//       Value: "bbbbbb",
//     },
//     {
//       Key: 3,
//       Value: "cccccc",
//     },
//     {
//       Key: 4,
//       Value: "dddddd",
//     },
//     {
//       Key: 5,
//       Value: "eeeeee",
//     },
//   ] as KeyValuePair<number, string>[];

export const postOrgUnit = async (
  requestBody: OrgUnit
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<OrgUnit>(
      `api/core/internal/orgunit`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
