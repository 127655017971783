import { furtherInfoStore } from "@app/core/further-info/[id]/store";
import { putInfoInadequate } from "@app/core/further-info/components/buttons/work-flow/info-inadequate/api";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { customLogger } from "@common/utils/logger";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class InfoInadequateButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  infoInadequate = async (id?: number) => {
    if (id === undefined) return;
    this.setIsLoading(true);
    const response = await putInfoInadequate(id);
    customLogger("Core Further info, infoInadequate").info(response?.data);
    if (isSuccessIdentityPacket(response)) {
      furtherInfoStore.reLoadFurtherInfo().then(() => {
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          type: "success",
          title: "Information inadequate successfully",
        });
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Information inadequate",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Information inadequate failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const infoInadequateButtonStore = new InfoInadequateButtonStore();
const infoInadequateButtonContext = createContext(infoInadequateButtonStore);
export const useInfoInadequateButtonStore = () => {
  return useContext(infoInadequateButtonContext);
};
