import { getRecordTrimContainer } from "@app/core/trim/trim-container/api";
import { getTrimContainerInputPickerOption } from "@app/core/trim/trim-container/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { getSettings } from "@common/apis/setting";
import { isSuccessResponse } from "@common/apis/util";
import { Svc_TrimContainer } from "@common/models/corporateSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface ITrimContainerProps {
  value: any;
  showNone?: boolean;
  electronicOnly?: boolean;
  selectedRows?: any[] | undefined;
}

export const TrimContainerInputPicker = (props: ITrimContainerProps) => {
  const {
    value,
    selectedRows,
    showNone = true,
    electronicOnly = false,
    ...others
  } = props;
  const [recordLabel, setRecordLabel] = useState("Containers");
  const nameOfTrimContainer = nameOfFactory<Svc_TrimContainer>();

  useEffectOnce(() => {
    getSettings([ECorporateSettingsField.CorporateSettings_RecordsLabel]).then(
      (response) => {
        if (response && isSuccessResponse(response)) {
          setRecordLabel(`${response.data?.[0].Value?.toString()} Containers`);
        }
      }
    );
  });

  return (
    <InputPickerSearch
      nameDisplayMerge={nameOfTrimContainer("Name")}
      options={getTrimContainerInputPickerOption(
        {
          dialog: {
            titleHeader: recordLabel,
          },
        },
        {
          selectableMode: "single",
          selectedRows: selectedRows,
        }
      )}
      value={value}
      customGetData={async (searchText: string) =>
        await getRecordTrimContainer(searchText, showNone, electronicOnly)
      }
      showClearButton={true}
      {...others}
    />
  );
};
