import { TransferDetail } from "@app/products/property/assessments/[id]/components/child-screens/transfer/components/detail/_index";
import { colTransfer } from "@app/products/property/assessments/[id]/components/child-screens/transfer/config";
import { DTO_ChangeOfOwnershipProcessed } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { useContactTransferStore } from "@app/products/property/contacts-central-names/[id]/components/child-screens/transfer/store";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";

const nameOf = nameOfFactory<DTO_ChangeOfOwnershipProcessed>();
export const ContactTransfer = observer(() => {
  const { contactId } = useContactsCentralNamesStore();
  const {
    isLoading,
    transfer,
    transferSelected,
    responseLoadError,
    setTransferSelected,
    loadTransfer,
    resetStore,
  } = useContactTransferStore();

  useEffect(() => {
    if (!contactId) return;
    loadTransfer(contactId);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const handleSelectedTransfer = useCallback(
    (dataItems: DTO_ChangeOfOwnershipProcessed[]) => {
      setTransferSelected(dataItems?.[0]);
    },
    [setTransferSelected]
  );
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (!contactId) return;
          loadTransfer(contactId);
        }}
      />
    );
  return (
    <CCGrid
      isLoading={isLoading}
      className="cc-contact-transfer-grid"
      data={transfer ?? []}
      columnFields={colTransfer}
      primaryField={nameOf("Id")}
      isLimitDetailWidth
      selectableMode="single"
      detail={TransferDetail}
      onSelectionChange={handleSelectedTransfer}
      selectedRows={transferSelected ? [{ ...transferSelected }] : []}
    />
  );
});
