export const mockListOfResponses = [
  {
    ResponseSent: "ACK • Certificate Roost Acknowledgement",
    ResponseSentDateTime: "10-Jul-2008 9:26",
    OrderStatus:
      "Certificate is expected to be delivered on or before: Jul 10 2008 9:27 AM",
    CouncilReference: 83,
    ExceptionDescription:
      '#1. ""Information Only"" Property match found. Matched On Property PFI',
    Id: 1045,
  },
  {
    ResponseSent: "CER • Despatch Response",
    ResponseSentDateTime: "10-Jul-2008 16:32",
    OrderStatus: "Certificate Produced",
    CouncilReference: 83,
    Id: 1053,
  },
];

export const mockCertificateRequest = [
  {
    Description: "Property match found",
    AdditionalInfo: "Matched On Property PFI",
    Messages: "Information",

    Id: 1,
  },
];
