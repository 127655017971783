import { VO_Rebate_Claim } from "@app/products/property/assessments/rebates/rebate-claims/model";
import { ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE } from "@app/products/property/assessments/rebates/rebate-claims/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Rebate_Claim>();
export const colRebatesClaims: IColumnFields[] = [
  {
    title: "Name",
    field: nameOf("RC_Name"),
    locked: true,
    linkTo: (dataItem) =>
      `${ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE}/${dataItem.Rebate_Claim_Id}`,
  },
  {
    title: "Number of Transactions",
    field: nameOf("No_of_Transactions"),
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    title: "Number of Assessments",
    field: nameOf("No_of_Assessments"),
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    title: "Total Rebate Additions",
    field: nameOf("Total_Rebate_Additions"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Total Rebate Reversals",
    field: nameOf("Total_Rebate_Reversals"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Total Rebates",
    field: nameOf("Total_Rebates"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    title: "Is Deleted",
    field: nameOf("RP_Is_Deleted"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Authority",
    field: nameOf("RA_Name"),
  },
  {
    title: "Rating Period",
    field: nameOf("RP_Name"),
  },
  {
    title: "From Date",
    field: nameOf("RC_From_Date"),
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    title: "To Date",
    field: nameOf("RC_To_Date"),
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    title: "Created By",
    field: nameOf("RC_Created_UserId"),
  },
  {
    title: "Created On",
    field: nameOf("RC_Created_DateTime"),
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    title: "ID",
    field: nameOf("Rebate_Claim_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Rebate_Authority_Id",
    field: nameOf("Rebate_Authority_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "Rating_Period_Id",
    field: nameOf("Rating_Period_Id"),
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    title: "RP_Code",
    field: nameOf("RP_Code"),
  },
  {
    title: "RP_From_Date",
    field: nameOf("RP_From_Date"),
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    title: "RP_To_Date",
    field: nameOf("RP_To_Date"),
    format: DATETIME_FORMAT.DATETIME,
  },
];
