import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IOptionsDefaultFinance } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/model";
import { getIndexField } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/util";
import { logic } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/logic";
import { IDataLogic } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export interface IOptionsFinanceSettings {
  item: any;
  options: IOptionsDefaultFinance;
  index: number;
}

export const optionsFinanceIntegrationSettings = (
  item: any,
  formRenderProps: FormRenderProps
): IOptionsFinanceSettings => {
  let options: IOptionsDefaultFinance = {
    showField: false,
    typeField: "",
    rowsField: 5,
    disabled: undefined,
  };
  let position = undefined;
  const enumFieldName = +item.FieldName;

  let groupShow: ECorporateSettingsField[] = [];

  /* ====  Logic  ==== */
  const groupShowLogic: ECorporateSettingsField[] = [];
  const dataLogic: IDataLogic = logic(formRenderProps).IntegrationSettingEnums;
  const dataLogicArr = Object.keys(dataLogic);
  dataLogicArr.forEach((item: string) => {
    if (dataLogic[item].isVisible) {
      groupShowLogic.push(...dataLogic[item].child);
    }
  });
  /* ====  / Logic  ==== */
  groupShow = groupShowLogic;

  /* ====  Defined type  ==== */
  let groupTextArea: number[] = [
    ECorporateSettingsField.CorporateSettings_FinanceCustomQuery, // Import Query *
    ECorporateSettingsField.CorporateSettings_UpToGlassFinanceTrasactionsQuery, // Transactions query *
    ECorporateSettingsField.CorporateSettings_UpToGlassFinanceTrasactionsCountQuery, // Transactions count query
    ECorporateSettingsField.CorporateSettings_UpToGlassFinanceOutstandingAmountQuery, // Outstanding amount and last payment date query *
    ECorporateSettingsField.CorporateSettings_UpToGlassFinanceFeeOutstandingAmountQuery, // Outstanding fee amount query
    ECorporateSettingsField.CorporateSettings_CashLinkReceiptInsertQuery, // Receipt Insert Query *
    ECorporateSettingsField.CorporateSettings_CashLinkReceiptItemInsertQuery, // Receipt Item Insert Query
    ECorporateSettingsField.CorporateSettings_CashLinkReceiptPaymentInsertQuery, // Receipt Payment Insert Query
    ECorporateSettingsField.CorporateSettings_FinanceDirectExportSQL, // Export Query
  ];

  let groupTable: number[] = [
    ECorporateSettingsField.CorporateSettings_CashLink_ProductwiseCodes, // Productwise Codes
  ];

  let groupTextOnly: number[] = [
    ECorporateSettingsField.CorporateSettings_FinanceLastBatchNo,
  ];

  let groupPasswordOnly: number[] = [
    ECorporateSettingsField.CorporateSettings_Finance_SAP_Password,
  ];

  let groupFinance: number[] = [
    ECorporateSettingsField.CorporateSettings_Finance_SAP_BaseUrl,
    ECorporateSettingsField.CorporateSettings_Finance_SAP_Username,
    ECorporateSettingsField.CorporateSettings_Finance_SAP_Password,
    ECorporateSettingsField.CorporateSettings_Finance_SAP_SourceInstitution,
    ECorporateSettingsField.CorporateSettings_Finance_SAP_SourceSystem,
    ECorporateSettingsField.CorporateSettings_Finance_SAP_UUID,
  ];
  /* ====  / Defined type  ==== */

  /* ====  Set options  ==== */
  if (groupShow.includes(enumFieldName)) {
    options.showField = true;
  }

  if (groupPasswordOnly.includes(enumFieldName)) {
    options.typeField = "password";
  }

  if (groupTextArea.includes(enumFieldName)) {
    options.typeField = "textArea";
  }

  if (groupTable.includes(enumFieldName)) {
    options.typeField = "table";
  }

  if (groupFinance.includes(enumFieldName)) {
    options.validate = {
      maxLength: 250,
      checkValidate: fieldFinanceGroupValidator,
    };
  }

  if (groupTextOnly.includes(enumFieldName)) {
    options.typeField = "textOnly";
  }

  const index = getIndexField(groupShow, position, enumFieldName);
  /* ====  / Set options  ==== */
  return {
    item,
    options,
    index,
  };
};

const MAX_LENGTH = 250;
const fieldFinanceGroupValidator = (
  value: string,
  valueGetter: (name: string) => any,
  fieldProps: {
    name: string;
  }
): string | undefined => {
  if (value && MAX_LENGTH < value?.length) {
    return `This field cannot exceed ${MAX_LENGTH}`;
  }
  return undefined;
};
