import { ECustomColNameProperty } from "@app/products/property/config";
import { eVisibility } from "@app/products/property/model";
import { isFieldVisible } from "@app/products/property/util";
import { Label } from "@common/stores/products/config";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field } from "@progress/kendo-react-form";
import { TextAreaProps } from "@progress/kendo-react-inputs";
import React from "react";

export interface IDataOwnerRatepayer {
  visibilityOwner?: eVisibility;
  ownerValue?: string;
  ratepayerValue?: string;
  ratepayerLabel?: string;
  options?: any;
}

export interface IOwnerAndRatepayer {
  className?: string;
  data: IDataOwnerRatepayer;
}
export const OwnerAndRatepayer = ({
  className,
  data,
  ...props
}: IOwnerAndRatepayer & TextAreaProps) => {
  //Get labels
  const ratepayerLabel =
    Label.CommunityProperty.getLabel(ECustomColNameProperty.Ratepayer) ?? "";

  const isShowOwner =
    isFieldVisible(data?.visibilityOwner) && !data.options.isLLS;
  const customClassName = isShowOwner
    ? className ?? "cc-form-cols-2 cc-col-span-2"
    : "cc-form-cols-1";

  const ownerField = isShowOwner ? (
    <div className="cc-field">
      <CCLabel title="Owner" />
      <Field
        {...props}
        name={"Owners"}
        placeholder="Owner"
        rows={4}
        value={data && data?.ownerValue}
        component={CCTextArea}
      />
    </div>
  ) : null;
  const ratePayerField = (
    <div className="cc-field">
      <CCLabel title={ratepayerLabel} />
      <Field
        {...props}
        name={"Ratepayer"}
        placeholder={ratepayerLabel}
        rows={4}
        value={data && data?.ratepayerValue}
        component={CCTextArea}
      />
    </div>
  );

  return ownerField || ratePayerField ? (
    <div className={customClassName}>
      {ownerField}
      {ratePayerField}
    </div>
  ) : null;
};
