import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsPermitsPaidNotIssued: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.PermitHolder_DisplayName,
    title: "Holder",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 250,
  },
  {
    field: ParkingPermitsFieldMapping.IssuedDate,
    title: "Issue Date",
    width: 250,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.ExpiryDate,
    title: "Expiry Date",
    width: 250,

    format: DATE_FORMAT.DATE,
  },
  {
    field: ParkingPermitsFieldMapping.PermitType_Name,
    title: "Permit Type",
    width: 250,
  },
  {
    field: ParkingPermitsFieldMapping.PermitNumber,
    title: "Permit Number",
    width: 250,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.FeesOutstanding,
    title: "Outstanding",
    width: 250,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.Status,
    title: "Status",
    width: 250,
  },
];
