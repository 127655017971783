import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";

export const colCSLRenewalsRequiresApproval = [
  {
    field: "LicenceNumber",
    title: "Licence Number",
    locked: true,
    linkTo: (dataItem: any) => `${CSL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "Applicant",
    title: "Licensee",
  },
  {
    field: "Business",
    title: "Contact",
  },
  {
    field: "Email",
    title: "Email",
  },
  {
    field: "Category",
    title: "Licence Category",
  },
  {
    field: "DateExpires",
    title: "Expires",

    format: DATE_FORMAT.DATE,
  },
  {
    field: "RenewalStatus",
    title: "Renewal Status",
  },
  {
    field: "AmountOS",
    title: "Outstanding",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DateIssued",
    title: "Issued",

    format: DATE_FORMAT.DATE,
  },
];
