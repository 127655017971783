import { ICCRoute } from "@common/constants/ICCRoute";

export const portalRoute: ICCRoute = {
  path: "portal",
  name: "Portal",
  children: [
    {
      path: "new-applications",
      name: "New Applications",
      component: require("./new-applications/_index").default,
    },
    {
      path: "waiting-for-payment",
      name: "Waiting for Payment",
      component: require("./waiting-for-payment/_index").default,
    },
    {
      path: "main-app-changes",
      name: "Main App Changes",
      component: require("./main-app-changes/_index").default,
    },
    {
      path: "cbc-registrations",
      name: "CBC Registrations",
      component: require("./cbc-registrations/_index").default,
    },
    {
      path: "hbc-registrations",
      name: "HBC Registrations",
      component: require("./hbc-registrations/_index").default,
    },
    {
      path: "3yo-registrations",
      name: "3yo Registrations",
      component: require("./3yo-registrations/_index").default,
    },
    {
      path: "4yo-registrations",
      name: "4yo Registrations",
      component: require("./4yo-registrations/_index").default,
    },
    {
      path: "cbc-reg-requests",
      name: "CBC Registration Requests",
      component: require("./cbc-reg-requests/_index").default,
    },
    {
      path: "hbc-reg-requests",
      name: "HBC Registration Requests",
      component: require("./hbc-reg-requests/_index").default,
    },
    {
      path: "3yo-reg-requests",
      name: "3yo Registration Requests",
      component: require("./3yo-reg-requests/_index").default,
    },
    {
      path: "4yo-reg-requests",
      name: "4yo Registration Requests",
      component: require("./4yo-reg-requests/_index").default,
    },
    {
      path: "cbc-offer-responses",
      name: "CBC Offer Responses",
      component: require("./cbc-offer-responses/_index").default,
    },
    {
      path: "hbc-offer-responses",
      name: "HBC Offer Responses",
      component: require("./hbc-offer-responses/_index").default,
    },

    {
      path: "3yo-offer-responses",
      name: "3yo Offer Responses",
      component: require("./3yo-offer-responses/_index").default,
    },

    {
      path: "4yo-offer-responses",
      name: "4yo Offer Responses",
      component: require("./4yo-offer-responses/_index").default,
    },
    {
      path: "profile-changes",
      name: "Profile Changes",
      component: require("./profile-changes/_index").default,
    },
    {
      path: "portal-payments",
      name: "Portal Payments",
      component: require("./portal-payments/_index").default,
    },
    {
      path: "portal-accounts",
      name: "Portal Accounts",
      component: require("./portal-accounts/_index").default,
    },
  ],
};
