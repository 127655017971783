import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React from "react";

export const CollectionDetailTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Collection ID:"}
            value={lastSelectedRow?.Collection_Id}
          />
          <ProductReferenceRow
            title={"Collection name:"}
            value={lastSelectedRow?.Collection_Name}
          />
          <ProductReferenceRow
            title={"Collection type:"}
            value={lastSelectedRow?.Collection_Type_Name}
          />
        </ProductReferenceBlock>
      </PanelBar>
    </ProductReferenceBody>
  );
});
