import { colAssociationsEntityNameAndAddress } from "@app/products/property/components/associations/components/entity-name-and-address/config";
import { DTO_AssociatedItem_Generic } from "@app/products/property/components/associations/components/entity-name-and-address/model";
import { CommunityProperty } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IAssociationsEntityNameAndAddressProps {
  associationsEntityNameAndAddressInfo:
    | DTO_AssociatedItem_Generic[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Generic>();

export const AssociationsEntityNameAndAddress = observer(
  ({
    associationsEntityNameAndAddressInfo,
  }: IAssociationsEntityNameAndAddressProps) => {
    const { isLLS } = CommunityProperty.getFlagOfStates();
    const newCols = useMemo(() => {
      if (isLLS) {
        //CP-3884: Hide AssociationType, Association, Component columns in LLS
        return colAssociationsEntityNameAndAddress.filter(
          (col: IColumnFields) =>
            col.field !== nameOf("AssociationType") &&
            col.field !== nameOf("Association") &&
            col.field !== nameOf("Component")
        );
      }
      return colAssociationsEntityNameAndAddress;
    }, [isLLS]);

    return (
      <CCGrid
        className="cc-associated-entity-name-and-address-grid"
        data={associationsEntityNameAndAddressInfo || []}
        columnFields={newCols}
        primaryField={nameOf("Id")}
      />
    );
  }
);
