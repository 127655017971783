import { DTO_PIC } from "@app/products/property/components/dialogs/add-pic-lookup/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

// TODO: Waiting api
/**
 *  1 - PIC
 *  2 - PIC Id
 */
export const picSearchBy: DTO_LOV_Number[] = [
  {
    Code: 1,
    Name: "PIC",
  },
  {
    Code: 2,
    Name: "PIC ID",
  },
];

export enum eOptionSearchPicLookup {
  PIC = 1,
  PicID = 2,
}

const nameOf = nameOfFactory<DTO_PIC>();
export const listConfigNumberTypePIC = [2];
export const colPicLookup: IColumnFields[] = [
  {
    field: nameOf("PIC_Number"),
    title: "PIC",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Trading_Name"),
    title: "Trading Name",
  },
  {
    field: nameOf("Pic_Manager"),
    title: "PIC Manager",
  },
  {
    field: nameOf("PIC_Type"),
    title: "PIC Type",
  },
  {
    field: nameOf("PIC_Status"),
    title: "PIC Status",
  },
  {
    field: nameOf("RLP_Board"),
    title: "RLP Board",
  },
  {
    field: nameOf("PIC_Id"),
    title: "PIC ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
