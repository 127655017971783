import { DTO_AmountOwing_StatusSummary } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/model";
import { useAssessmentAmountOwingStore } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/store";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatStyleProminent } from "@common/utils/formatting";
import { CCValueField } from "@components/cc-value-field/_index";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React from "react";

const ASSESSMENT_BALANCE_OWING = "Assessment Balance Owing";

export const AmountsOwingTotal = observer(() => {
  const { amountOwing } = useAssessmentAmountOwingStore();
  if (!amountOwing?.BalancesByStatus) return <NoData vertical />;
  return (
    <div className="cc-assessment-total">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <div className="cc-form-cols-3">
                {amountOwing.BalancesByStatus.map(
                  (item: DTO_AmountOwing_StatusSummary, index) =>
                    item?.Name !== ASSESSMENT_BALANCE_OWING ? (
                      <CCValueField
                        key={index}
                        label={item.Name}
                        value={item.TotalAmount}
                        format={CURRENCY_FORMAT.CURRENCY1}
                        style={formatStyleProminent}
                      />
                    ) : null
                )}
              </div>
            </div>
            <div className="cc-field">
              {amountOwing.BalancesByStatus.map(
                (item: DTO_AmountOwing_StatusSummary, index) =>
                  item?.Name === ASSESSMENT_BALANCE_OWING ? (
                    <CCValueField
                      key={index}
                      label={item.Name}
                      value={item.TotalAmount}
                      format={CURRENCY_FORMAT.CURRENCY1}
                      style={formatStyleProminent}
                    />
                  ) : null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
