import { ICRMSDocument } from "@app/products/crms/components/crms-documents/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ICRMSDocument>();
export const colCrmsDocument: IColumnFields[] = [
  {
    field: nameOf("RecordedDate"),
    title: "Recorded",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("Title"),
    title: "Title",
  },
  {
    field: nameOf("FileName"),
    title: "File Name",
  },
  {
    field: nameOf("Tag"),
    title: "Tag",
  },
  {
    field: nameOf("FileSize"),
    title: "File Size(Mb)",
  },
];
