import { IActionsCancelledDetailTab } from "@app/products/property/actions/cancelled-list/components/reference-sidebar/detail/model";

export const mockActionsCancelledDetailTab: IActionsCancelledDetailTab = {
  Component: "Holding",
  Workflow: "Create Holding",
  Region: "South West",
  WorkflowStatus: "Cancelled",
  UserId: "randallh",
  Created: new Date("4/12/2022"),
  Modified: new Date("4/12/2022"),
};
