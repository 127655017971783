import { DATE_FORMAT } from "@common/constants/common-format";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const ChangeOfOwnershipFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    return (
      <div className="cc-change-of-ownership">
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Change of ownership ID</label>
              <Field
                name={nameOf("Change_of_Ownership_Id")}
                readOnly
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Reference</label>
              <Field
                name={nameOf("COO_Reference")}
                component={CCInput}
                placeholder="Reference"
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Effective date</label>
              <Field
                name={nameOf("COO_Effective_Date")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Sale price</label>
              <Field
                name={nameOf("COO_Sale_Price")}
                component={CCCurrencyInput}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Contract date</label>
              <Field
                name={nameOf("COO_Contract_Date")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Transfer fee</label>
              <Field
                readOnly
                name={nameOf("COO_Transfer_Fee")}
                component={CCCurrencyInput}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Chattles</label>
              <Field
                name={nameOf("COO_Chattles")}
                component={CCCurrencyInput}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Notes</label>
              <Field
                rows={3}
                component={CCTextArea}
                name={nameOf("COO_Notes")}
                placeholder="Notes"
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Owner name and address</label>
              <Field
                component={CCTextArea}
                readOnly
                rows={3}
                name={nameOf("Owner_Name_Address")}
                placeholder="Owner name and address"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
);
