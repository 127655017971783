import {
  EWorkflow_Approval_Status_Name,
  EWorkflow_Status,
} from "@app/products/property/actions/model";
import { useNewAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/new-assessment/store";
import { AddWorkflowDialog } from "@app/products/property/components/action-bar/dialog/add-workflow/_index";
import {
  colApproval,
  colApprovalNewStatus,
  colWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/config";
import {
  WorkflowApprovalStatus,
  WorkflowStepApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import {
  DTO_Secondary_Workflow,
  DTO_Workflow_Approval_Step,
  EListSubmitButton,
} from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { filter, head, includes, isEqual, map } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { useEffectOnce } from "react-use";

const nameOfGridData = nameOfFactory<DTO_Secondary_Workflow>();
const nameOfGridDataApproval = nameOfFactory<DTO_Workflow_Approval_Step>();
export const WorkflowStepFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isFromActionList: false,
      isShowWorkflowApproval: true,
      dataFromActionList: {},
    },
  }: IFormStepElement) => {
    const { isLoadingOnDialog, setIsLoadingOnDialog } =
      useNewAssessmentDialogStore();
    const { valueGetter, onChange, onSubmit } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const selectedSecondaryWorkflow: number[] = getFieldValue(
      "SecondaryWorkflowsSelected"
    );
    const [workflowData, setWorkflowData] = useState<DTO_Secondary_Workflow[]>(
      getFieldValue("SecondaryWorkflow")
    );
    const [isShowAddWorkflowDialog, setIsShowAddWorkflowDialog] =
      useState<boolean>(false);
    const [isShowDeleteWorkflowDialog, setIsShowDeleteWorkflowDialog] =
      useState<boolean>(false);
    const [selectedWorkflows, setSelectedWorkflows] = useState<
      DTO_Workflow_Approval_Step[]
    >([]);
    const {
      Workflow_Status,
      Workflow_Approval_Status,
      Workflow_Approval_Status_Name,
    } = options?.dataFromActionList ?? {};

    const isInEditMode =
      isEqual(Workflow_Status, EWorkflow_Status.Completed) &&
      isEqual(
        Workflow_Approval_Status_Name,
        EWorkflow_Approval_Status_Name.To_Be_Approved
      );

    useEffectOnce(() => {
      selectedSecondaryWorkflow &&
        setWorkflowData(
          map(workflowData, (obj) => {
            return includes(selectedSecondaryWorkflow, obj.WorkflowType_Int)
              ? { ...obj, Workflow_IsMandatory: true }
              : obj;
          })
        );
    });

    const isSelectableSecondaryWorkflow: boolean = useMemo(() => {
      if (!options?.isFromActionList) return true;
      return !(
        isEqual(Workflow_Status, EWorkflow_Status.Completed) &&
        (isEqual(Workflow_Approval_Status, WorkflowApprovalStatus.Approved) ||
          isEqual(
            Workflow_Approval_Status,
            WorkflowApprovalStatus.ToBeApproved
          ))
      );
    }, [options, Workflow_Status, Workflow_Approval_Status]);

    const handleAddWorkflow = (values: any) => {
      setIsLoadingOnDialog(true);
      onChange(nameOf("AdhocStep"), {
        value: values,
      });
      onSubmit({
        ...values,
        currentTarget: { id: EListSubmitButton.SaveWorkflow },
      });
      setIsLoadingOnDialog(false);
      setIsShowAddWorkflowDialog(false);
    };

    const handleDeleteWorkflow = (values: any) => {
      setIsLoadingOnDialog(true);
      onChange(nameOf("DeletedAdhocStepId"), {
        value: head(selectedWorkflows)?.WorkflowDraftApprovalId,
      });
      onSubmit({
        ...values,
        currentTarget: { id: EListSubmitButton.SaveWorkflow },
      });
      setIsLoadingOnDialog(false);
      setIsShowDeleteWorkflowDialog(false);
    };

    return (
      <section className="cc-field-group">
        {getFieldValue("WD_Require_Approval") && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Approvals</label>
              <CCGrid
                toolbar={
                  isInEditMode ? (
                    <div className="cc-grid-tools-bar">
                      <Button
                        iconClass="fas fa-plus"
                        type="button"
                        title="Add"
                        onClick={() => setIsShowAddWorkflowDialog(true)}
                      />
                      <Button
                        iconClass="fas fa-minus"
                        title="Remove"
                        disabled={!selectedWorkflows.length}
                        onClick={() => {
                          setIsShowDeleteWorkflowDialog(true);
                        }}
                      />
                    </div>
                  ) : null
                }
                selectableMode={isInEditMode ? "single" : "none"}
                onSelectionChange={(dataItem: DTO_Workflow_Approval_Step[]) =>
                  setSelectedWorkflows(
                    filter(dataItem, {
                      ApprovalStatus: WorkflowStepApprovalStatus.Pending,
                    })
                  )
                }
                data={getFieldValue("WorkflowApprovals") || []}
                columnFields={
                  isSelectableSecondaryWorkflow
                    ? colApprovalNewStatus
                    : colApproval
                }
                state={{
                  sort: [
                    {
                      field: nameOfGridDataApproval("WorkflowStepSequence"),
                      dir: "asc",
                    },
                  ],
                }}
                primaryField={nameOfGridDataApproval("WorkflowDraftApprovalId")}
              />
            </div>
          </div>
        )}
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Additional workflows
              <CCTooltip
                type="custom"
                position="auto"
                content=" "
                customTemplate={
                  <div>
                    A padlock icon indicates the additional workflow is
                    mandatory. Click in the included column to include a new
                    additional workflow.
                    <br /> Additional workflows are automatically created after
                    the current workflow is approved.
                  </div>
                }
              >
                <i className="fa fa-info-circle ml-1 text-accent" />
              </CCTooltip>
            </label>
            <CCGrid
              data={workflowData}
              columnFields={colWorkflow}
              primaryField={nameOfGridData("WorkflowType_Int")}
              editableMode={"cell"}
              onDataChange={(dataItem: any[]) => {
                onChange(nameOf("SecondaryWorkflowsSelected"), {
                  value: map(
                    filter(dataItem, {
                      Workflow_IsMandatory: true,
                      Is_Included: false,
                    }),
                    nameOfGridData("WorkflowType_Int")
                  ),
                });
              }}
              readOnly={!isSelectableSecondaryWorkflow}
            />
          </div>
        </div>
        {isShowAddWorkflowDialog && (
          <AddWorkflowDialog
            onClose={() => setIsShowAddWorkflowDialog(false)}
            handleSubmit={handleAddWorkflow}
            isLoadingOnDialog={isLoadingOnDialog}
          />
        )}
        {isShowDeleteWorkflowDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={"Are you sure you want to delete?"}
            onClosePopup={() => setIsShowDeleteWorkflowDialog(false)}
            btnCancelVisible={false}
            onConfirmNo={() => setIsShowDeleteWorkflowDialog(false)}
            onAsyncConfirm={handleDeleteWorkflow}
            isLoadingYes={isLoadingOnDialog}
          />
        )}
      </section>
    );
  }
);
