import {
  colAssociationsAssessment,
  colAssociationsPacel,
  colAssociationsTitles,
} from "@common/pages/contacts/config";
import { CCGrid } from "@components/cc-grid/_index";
import { ICCPanelProps } from "@components/cc-panel/_index";
import { CCPanelsBar } from "@components/cc-panels-bar/_index";
import { Checkbox } from "@progress/kendo-react-inputs/dist/npm/checkbox/Checkbox";
import React from "react";
import mockListPropertyAssociations from "./mock";

const PropertyAssociations = () => {
  const listPanelBar: ICCPanelProps[] = [
    {
      title: "Assessment (1)",
      component: (
        <CCGrid
          className="cc-address-grid"
          data={mockListPropertyAssociations.Assessment}
          columnFields={colAssociationsAssessment}
          primaryField="Id"
        />
      ),
    },
    {
      title: "Parcel (1)",
      component: (
        <CCGrid
          className="cc-parcel-grid"
          data={mockListPropertyAssociations.Parcel}
          columnFields={colAssociationsPacel}
          primaryField="No"
        />
      ),
    },
    {
      title: "Title (1)",
      component: (
        <CCGrid
          className="cc-title-grid"
          data={mockListPropertyAssociations.Title}
          columnFields={colAssociationsTitles}
          primaryField="No"
        />
      ),
    },
  ];

  return (
    <div className="cc-property-panel-association">
      <div className="cc-grid-control-left">
        <div className="cc-control-item">Include:</div>
        <Checkbox
          className="cc-control-item"
          title="Inactive"
          label="Inactive"
        />
        <Checkbox
          className="cc-control-item"
          title="Secondary Associations"
          label="Secondary associations"
        />
      </div>
      <div className="cc-custom-sub-panel-bar">
        <CCPanelsBar initialPanels={listPanelBar} />
      </div>
    </div>
  );
};

export default PropertyAssociations;
