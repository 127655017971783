import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { AnimalsRegister } from "@app/products/animals/model";

import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

// use AnimalsRegister model instead of AnimalsKennel
const nameOf = nameOfFactory<AnimalsRegister>();

export const colRelatedAnimalsKennelAccordion: IColumnFields[] = [
  {
    field: nameOf("Type"),
    title: "Type",
    locked: true,
    linkTo: (dataItem: AnimalsRegister) => `${ANIMALS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  {
    field: nameOf("Breed"),
    title: "Work Phone",
  },
  {
    field: nameOf("AnimalName"),
    title: "Name",
  },
  {
    field: nameOf("Colour"),
    title: "Colour",
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
  },
  {
    field: nameOf("Age"),
    title: "Age",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
];
