import { LookupNoticeTypeForm } from "@common/pages/settings/lookups/notice-types/_id/component/child-screens/general/components/_index";
import { LookupNoticeTypeSubmitActions } from "@common/pages/settings/lookups/notice-types/_id/model";
import { useNoticeTypesStore } from "@common/pages/settings/lookups/notice-types/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewLookupNoticeType = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { noticeType, isLoading, responseLoadError, loadNoticeType, onSubmit } =
  useNoticeTypesStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Notice type"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadNoticeType(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New notice type" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={LookupNoticeTypeSubmitActions.New}
                onClick={onSubmit}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {noticeType && <LookupNoticeTypeForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
