import { getMailMergeDocuments } from "@app/core/communication/dialogs/components/form-elememts/template/api";
import { colMailMerge } from "@app/core/mail-merge/form-steps/components/template/config";
import { IMailMergeGridData } from "@app/core/mail-merge/form-steps/components/template/model";
import { templateValidator } from "@app/core/mail-merge/form-steps/components/template/util";
import { useMailMergeStore } from "@app/core/mail-merge/store";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { FieldArray } from "@progress/kendo-react-form";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const MAIL_MERGE_TEMPLATE_FORM_STEP = "Template";
const nameOfGridData = nameOfFactory<IMailMergeGridData>();
const nameOfCoreKeywork = nameOfFactory<CoreKeyword>();
export const TemplateFormStep = observer((props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={templateValidator}
    />
  );
});

const FormStepElement = observer(
  ({ formRenderProps, nameOf }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const [showPreview, setShowPreview] = useState(true);
    const {
      documentTemplateData,
      setDocumentTemplateData,
      loadMailMergeDocumentTemplate,
      isLoading,
    } = useMailMergeStore();

    const selectedMailMergeDocumentIDs = getFieldValue(
      "SelectedMailMergeDocumentIDs"
    );

    const handleOnChangeCategory = (event: ComboBoxChangeEvent) => {
      onChange(nameOf("SelectedCategory"), { value: event.value });
      onChange(nameOf("SelectedKeywordCategory"), { value: event.value });
    };

    const handleOnSelectionChange = (dataItem: any) => {
      let newSelectedMailMergeDocumentIDs: number[] = [];
      let newSelectedMailMergeDocument: any = [];
      dataItem.forEach((item: any) => {
        newSelectedMailMergeDocumentIDs.push(item.MailMergeDocument_ID);
        newSelectedMailMergeDocument.push(item);
      });
      onChange(nameOf("SelectedMailMergeDocumentIDs"), {
        value: newSelectedMailMergeDocumentIDs,
      });
      onChange(nameOf("SelectedMailMergeDocument"), {
        value: newSelectedMailMergeDocument,
      });
    };
    useEffectOnce(() => {
      getKeywords(
        KEYWORD_TYPE.Core_DocumentCategory,
        PRODUCT_TYPE_NUMBER.Core
      ).then((response) => {
        customLogger(
          "Core Mail Merge, Mail merge template, getKeywords - Core_DocumentCategory"
        ).info(response?.data);
        if (isSuccessResponse(response)) {
          if (response?.data)
            onChange(nameOf("CategoriesListData"), {
              value: response.data,
            });
        } else {
          notificationRef.current?.pushNotification({
            title: `Categories load failed`,
            type: "warning",
          });
        }
      });
      if (getFieldValue("ProductType") && getFieldValue("MailMergeDataset")) {
        getMailMergeDocuments(
          getFieldValue("MailMergeDataset"),
          getFieldValue("ProductType"),
          getFieldValue("DocumentIDs")
        ).then((response) => {
          customLogger(
            "Core Mail Merge, Mail merge template, getMailMergeDocuments"
          ).info(response?.data);
          if (isSuccessResponse(response)) {
            if (response?.data)
              onChange(nameOf("MailMergeTemplateGridData"), {
                value: response.data.value,
              });
          } else {
            notificationRef.current?.pushNotification({
              title: `Mail merge documents load failed`,
              type: "warning",
            });
          }
        });
      }
    });

    useEffect(() => {
      onChange(nameOf("DocData"), { value: null });
      if (
        !isEmpty(selectedMailMergeDocumentIDs) &&
        getFieldValue("ProductType")
      ) {
        setShowPreview(true);
        loadMailMergeDocumentTemplate(
          selectedMailMergeDocumentIDs,
          getFieldValue("ProductType")
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMailMergeDocumentIDs]);

    useEffect(() => {
      if (documentTemplateData) {
        onChange(nameOf("DocData"), { value: documentTemplateData });

        setDocumentTemplateData(null);
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentTemplateData, setDocumentTemplateData]);

    const [numPages, setNumPages] = useState<number>(0);

    const onDocumentLoadSuccess = ({ numPages }: any) => {
      setNumPages(numPages);
    };

    const showAllPage = () => {
      const pages = Array.from(
        { length: numPages },
        (_, index) => index + 1
      ).map((page) => <Page className="cc-pdf-pages" pageNumber={page} />);
      return pages;
    };

    return (
      <div className="cc-mail-merge-selection-container">
        <CCLocalNotification ref={notificationRef} />
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Category
                <CCTooltip type="validator" position="right" />
              </label>
              <CCSearchComboBox
                className="cc-dropdown"
                data={getFieldValue("CategoriesListData") ?? []}
                textField={nameOfCoreKeywork("Name")}
                dataItemKey={nameOfCoreKeywork("Keyword_ID")}
                onChange={handleOnChangeCategory}
                value={getFieldValue("SelectedCategory")}
              />
              <div className="cc-grid-container">
                <CCGrid
                  selectableMode="single"
                  onSelectionChange={(e) => handleOnSelectionChange(e)}
                  selectedRows={
                    getFieldValue("SelectedMailMergeDocument") ?? []
                  }
                  columnFields={colMailMerge}
                  primaryField={nameOfGridData("MailMergeDocument_ID")}
                  data={getFieldValue("MailMergeTemplateGridData") ?? []}
                />
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Preview</label>
              <Loading isLoading={isLoading} />
              {showPreview && !isLoading && (
                <>
                  {getFieldValue("DocData") && (
                    <Document
                      className="cc-preview"
                      file={`data:application/pdf;base64,${getFieldValue(
                        "DocData"
                      )}`}
                      renderMode="svg"
                      loading={""}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {numPages && showAllPage()}
                    </Document>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
