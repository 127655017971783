import { DTO_Charge } from "@app/products/property/assessments/[id]/components/child-screens/charges/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAdjustChargeBalancesButtonProps {
  chargeData: DTO_Charge[];
}

export const AdjustChargeBalancesButton = observer(
  ({ chargeData }: IAdjustChargeBalancesButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();

    return (
      <CCNavButton
        title="Adjust charge balances"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_Assessment_Adjust_ChargeBalances,
              data: {},
              props: { chargeData },
            },
          ]);
        }}
        disabled={chargeData?.length < 1}
      />
    );
  }
);
