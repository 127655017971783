import { UpdateEntityDialog } from "@app/products/property/assessments/rebates/rebate-entitlement-exceptions/components/action-bar/dialogs/edit-entity/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TYPE_NOTIFICATION } from "@components/cc-notification/components/cc-notification-item/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const UpdateEntityButton = observer(() => {
  const [showDialog, setShowDialog] = useState(false);
  const { gridSelectedRows, pushNotifications } = useCCProductListViewStore();
  const [itemsSelected, setItemsSelected] = useState<any[]>(gridSelectedRows);

  useEffect(() => {
    setItemsSelected(gridSelectedRows);
  }, [gridSelectedRows]);

  const getUpdatedItemId = () => {
    if (!itemsSelected || !itemsSelected?.[0]) return "";
    return itemsSelected[0]?.EntityId ?? "";
  };

  const isDisableUpdateButton = () => {
    if (itemsSelected) {
      if (itemsSelected?.[0] && itemsSelected[0]?.EntityId)
        return itemsSelected?.length !== 1;
      return true;
    }
    return false;
  };

  return (
    <>
      <CCNavButton
        title="Update Contact"
        disabled={isDisableUpdateButton()}
        onClick={() => setShowDialog(true)}
      />

      {showDialog && (
        <UpdateEntityDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={() => {
            setShowDialog(false);
            pushNotifications([
              {
                isAutoClose: true,
                primaryContent: `Updated Entity Id ${getUpdatedItemId()} successfully`,
                type: TYPE_NOTIFICATION.SUCCESS,
              },
            ]);
          }}
        />
      )}
    </>
  );
});
