import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { CommonFormFinance } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance";
import {
  IOptionsFinanceSettings,
  optionsFinanceIntegrationSettings,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/config";
import { sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const { valueGetter } = formRenderProps;
    const financeMethodValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_FinanceMethod.toString()
    );
    const financeSystemValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_FinanceSystem.toString()
    );

    const enableUpToGlassFinanceValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance.toString()
    );
    const directExportValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_FinanceDirectExport.toString()
    );
    const exportFormatValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_FinanceExportFormat.toString()
    );
    const financeApiValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_U2GFinanceAPISystem.toString()
    );
    const importMethodValue = valueGetter(
      ECorporateSettingsField.CorporateSettings_FinanceImportMethod.toString()
    );

    const dataSort = useMemo(() => {
      const data = dataSetting.SettingFields;
      const dataTransform: IOptionsFinanceSettings[] = data.map(
        (item: SettingField) => {
          let itemTransform = optionsFinanceIntegrationSettings(
            item,
            formRenderProps
          );
          return itemTransform;
        }
      );
      return sortBy(dataTransform, "index", "asc");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      financeMethodValue,
      financeSystemValue,
      enableUpToGlassFinanceValue,
      directExportValue,
      exportFormatValue,
      financeApiValue,
      importMethodValue,
    ]);

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1 cc-form-cols-finance">
            {dataSort.map((field: IOptionsFinanceSettings, index: number) => {
              return (
                field.options.showField && (
                  <CommonFormFinance
                    key={index}
                    item={field.item}
                    options={field.options}
                    formRenderProps={formRenderProps}
                  />
                )
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
