import { MailMergeFile } from "@common/pages/settings/mail-merge/model";
// example string: "attachment; filename=Attachment1MB.doc; size=1080320"
export const extractAttachmentInfo = (
  attachmentString: string
): MailMergeFile => {
  const regex = /attachment; filename="?([^";]+)"?; size=(\d+)/;
  const match = regex.exec(attachmentString);
  if (!match) {
    return {};
  }
  // Extract the file name and size from the matched groups
  const fileNameWithExtension = match[1];
  const size = parseInt(match[2]);

  // Split the file name to separate the extension
  const [fileName, extension] = fileNameWithExtension.split(".");
  return {
    fileName,
    extension,
    size,
  };
};
