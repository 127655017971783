import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { AddExternalGisItemsDialog } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/launch-external-gis/components/add-external-gis-items-dialog/_index";
import {
  colExternalGisMenuItems,
  processDataLaunchExternalGis,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/launch-external-gis/config";
import { IExternalGisMenuItems } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/modules/forms/launch-external-gis/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { clone } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export type ILaunchExternalGisProps = {
  formRenderProps: FormRenderProps;
};

const nameOf = nameOfFactory<IExternalGisMenuItems>();

const LaunchExternalGis = ({ formRenderProps }: ILaunchExternalGisProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<
    IExternalGisMenuItems[]
  >([]);
  const [isShowDialogAdd, setIsShowDialogAdd] = useState<boolean>(false);

  const settingValue = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Module_LaunchExternalGIS
  );
  const dataGrid: IExternalGisMenuItems[] =
    valueGetter(`option.ValueDisplayDetails_${settingValue?.FieldName}`) ?? [];

  const onAddExternalGisItems = (newData: IExternalGisMenuItems) => {
    let currentDataArray = clone(dataGrid);
    currentDataArray.push(newData);
    currentDataArray = currentDataArray.map((item, index) => ({
      ...item,
      ID: index + 1,
    }));

    //onChange Setting value
    onChange(
      `${ECorporateSettingsField.CorporateSettings_Module_LaunchExternalGIS}`,
      {
        value: processDataLaunchExternalGis(currentDataArray),
      }
    );

    onChange(`option.ValueDisplayDetails_${settingValue?.FieldName}`, {
      value: currentDataArray,
    });
  };

  const onDeleteExternalGisItems = () => {
    const currentDataArray = clone(dataGrid);
    let newData = currentDataArray.filter(
      (item) => !gridSelectedRows.find((x) => x.ID === item.ID)
    );
    newData = newData.map((item, index) => ({
      ...item,
      ID: index + 1,
    }));

    //onChange Setting value
    onChange(
      `${ECorporateSettingsField.CorporateSettings_Module_LaunchExternalGIS}`,
      {
        value: processDataLaunchExternalGis(newData),
      }
    );

    onChange(`option.ValueDisplayDetails_${settingValue?.FieldName}`, {
      value: newData,
    });

    setGridSelectedRows([]);
  };

  return (
    <>
      <section className="cc-field-group">
        <CCGrid
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                iconClass="fas fa-plus"
                type="button"
                title="Add"
                disabled={!isEditing}
                onClick={() => setIsShowDialogAdd(true)}
              />
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={!isEditing ? true : gridSelectedRows.length === 0}
                onClick={onDeleteExternalGisItems}
              />
            </div>
          }
          columnFields={colExternalGisMenuItems}
          state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
          primaryField={nameOf("ID")}
          data={dataGrid ?? []}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: IExternalGisMenuItems[]) => {
            if (dataItem) setGridSelectedRows([...dataItem]);
          }}
          selectableMode={isEditing ? "multiple" : "none"}
          isAutoHiddenPager={false}
          itemPerPage={10}
        />
      </section>
      {isShowDialogAdd && (
        <AddExternalGisItemsDialog
          onClose={() => setIsShowDialogAdd(false)}
          onAdd={onAddExternalGisItems}
        />
      )}
    </>
  );
};

export default observer(LaunchExternalGis);
