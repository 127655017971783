import {
  BuildingBCAClassColumns,
  BuildingBCAVersionColumns,
  BuildingNatureOfWorkColumns,
  BuildingOfficerColumns,
  BuildingPermitTypeColumns,
  BuildingUseOfBuildingColumn,
  InspectionsRequiredColumns,
} from "@app/products/building/model";
import { IBuilding } from "@app/products/building/[id]/model";
import { TownPlanningApiUrl } from "@app/products/town-planning/config";
import { nameOfFactory } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";

const nameOf = nameOfFactory<IBuilding>();
// INPUT PICKER - Permit Type
export const permitTypeFields = (formRenderProps: any) => ({
  titleHeader: "Pick Permit Type",
  placeholder: "Permit Type",
  tableTypes: BuildingPermitTypeColumns,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "PermitType_Picker_Field",
  value: formRenderProps.valueGetter(nameOf("PermitType")),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange(nameOf("PermitType"), {
        value: event.detail.Position,
      });
    }
  },
});

export const natureOfWorkFields = (formRenderProps: FormRenderProps) => ({
  isMultiSelect: true,
  titleHeader: "Pick Nature of Work(s)",
  placeholder: "Nature of Work",
  tableTypes: BuildingNatureOfWorkColumns,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "NatureOfWork",
  value: formRenderProps.valueGetter("NatureOfWork"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("NatureOfWork", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});

export const BCAVersionFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick BCA Version",
  placeholder: "BCA Version",
  tableTypes: BuildingBCAVersionColumns,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "BCAVersion_Picker_Field",
  value: formRenderProps.valueGetter(nameOf("BCAVersion")),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange(nameOf("BCAVersion"), {
        value: event.detail.Position,
      });
    }
  },
});
// INPUT PICKER - Use Of Building

export const useOfBuildingFields = (formRenderProps: FormRenderProps) => ({
  isMultiSelect: true,
  titleHeader: "Pick Use Of Building(s)",
  placeholder: "Use of Building",
  tableTypes: BuildingUseOfBuildingColumn,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "UseOfBuilding",
  value: formRenderProps.valueGetter("UseOfBuilding"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("UseOfBuilding", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});

// INPUT PICKER - BCA Class
export const BCAClassFields = (formRenderProps: FormRenderProps) => ({
  isMultiSelect: true,
  titleHeader: "Pick BCA Class(s)",
  placeholder: "BCA Class",
  tableTypes: BuildingBCAClassColumns,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "BCAClass",
  value: formRenderProps.valueGetter("BCAClass"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("BCAClass", {
        value: event.detail.map((cate: any) => cate.Role),
      });
    }
  },
});

// INPUT PICKER - Inspections Required
// INPUT PICKER - BCA Class
export const inspectionsRequiredFields = (
  formRenderProps: FormRenderProps
) => ({
  isMultiSelect: true,
  titleHeader: "Pick Inspections Required(s)",
  placeholder: "Inspections Required",
  tableTypes: InspectionsRequiredColumns,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "InspectionsRequired",
  value: formRenderProps.valueGetter("InspectionsRequired"),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange("InspectionsRequired", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});

// INPUT PICKER - Surveyor
export const buildingOfficerFields = (formRenderProps: any) => ({
  titleHeader: "Pick Building Officer",
  placeholder: "Building Officer",
  tableTypes: BuildingOfficerColumns,
  url: TownPlanningApiUrl.GET_PLANNERS_TOWNPLANNING,
  fieldName: "BuildingOfficer_Picker_Field",
  value: formRenderProps.valueGetter(nameOf("BuildingOfficer")),
  onChange: (event: any) => {
    if (event.target !== null) {
      formRenderProps.onChange("BuildingOfficer_Picker_Field", {
        value: event.target.value,
      });
    } else {
      formRenderProps.onChange(nameOf("BuildingOfficer"), {
        value: event.detail.Position,
      });
    }
  },
});
