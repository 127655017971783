export const mockRateBreakup = [
  {
    Date: new Date("1-Jul-2003"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 861,
  },
  {
    Date: new Date("1-Jul-2004"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 862,
    Detail: [
      {
        Amount: 200.0,
        Levy: "Fixed Charge",
      },
      {
        Amount: 730.83,
        Levy: "Rates - Residential",
      },
      {
        Amount: 18.0,
        Levy: "Torrens Catchment Levy",
      },
    ],
  },
  {
    Date: new Date("1-Jul-2005"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 863,
  },
  {
    Date: new Date("1-Jul-2006"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 864,
  },
  {
    Date: new Date("1-Jul-2007"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 865,
  },
  {
    Date: new Date("1-Jul-2008"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 866,
  },
  {
    Date: new Date("1-Jul-2009"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 867,
  },
  {
    Date: new Date("1-Jul-2010"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 868,
  },
  {
    Date: new Date("1-Jul-2011"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 869,
  },
  {
    Date: new Date("1-Jul-2012"),
    Transaction: "Rates",
    Charge: "Rts",
    ChargeId: 870,
  },
  {
    Date: new Date("26-Jul-2013"),
    Transaction: "Rate Run for 2013/2014, run on 26-Jul-2013 16:12",
    Charge: "Rts",
    ChargeId: 258392,
  },
  {
    Date: new Date("11-Jul-2014"),
    Transaction: "Rate Run for 2014/2015, run on 11-Jul-2014 14:35",
    Charge: "Rts",
    ChargeId: 294290,
  },
  {
    Date: new Date("7-Jul-2015"),
    Transaction: "Rate Run for 2015/2016, run on 07-Jul-2015 14:02",
    Charge: "Rts",
    ChargeId: 333214,
  },
  {
    Date: new Date("4-Jul-2016"),
    Transaction: "Rate Run for 2016/2017, run on 04-Jul-2016 11:05",
    Charge: "Rts",
    ChargeId: 369525,
  },
  {
    Date: new Date("4-Jul-2017"),
    Transaction: "Rate Run for 2017/2018, run on 04-Jul-2017 13:53",
    Charge: "Rts",
    ChargeId: 403193,
  },
  {
    Date: new Date("4-Jul-2018"),
    Transaction: "Rate Run for 2018/2019, run on 04-Jul-2018 10:34",
    Charge: "Rts",
    ChargeId: 435135,
    Detail: [
      {
        Amount: 52.73,
        Levy: "Adelaide & Mt Lofty Ranges NRM Levy",
      },
      {
        Amount: 634.0,
        Levy: "Fixed Charge",
      },
      {
        Amount: 1258.92,
        Levy: "Rates - Residential",
      },
    ],
  },
  {
    Date: new Date("2-Jul-2019"),
    Transaction: "Rate Run for 2019/2020, run on 02-Jul-2019 13:37",
    Charge: "Rts",
    ChargeId: 452989,
  },
  {
    Date: new Date("1-Jul-2020"),
    Transaction: "Rate Run for 2020/2021, run on 01-Jul-2020 12:19",
    Charge: "Rts",
    ChargeId: 529464,
  },
  {
    Date: new Date("2-Aug-2021"),
    Transaction: "Rate Run for 2021/2022, run on 02-Aug-2021 16:2",
    Charge: "Rts",
    ChargeId: 534384,
  },
  {
    Date: new Date("3-Sep-2021"),
    Transaction: "Penalty Run on 23 Sep 2021",
    Charge: "",
    ChargeId: 624692,
  },
];
