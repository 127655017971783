import { VMASResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchAddressConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

const nameOf = nameOfFactory<VMASResult>();

export const colSearchAddress: IColumnFields[] = [
  {
    field: nameOf("AddressLine"),
    title: "Address",
    width: 200,
  },
  {
    field: nameOf("Suburb"),
    title: "Suburb",
    width: 150,
  },
  {
    field: nameOf("Postcode"),
    title: "Postcode",
    width: 100,
  },
  {
    field: nameOf("State"),
    title: "State",
    width: 100,
  },
];
