import { useIsLoadingStatus } from "@common/hooks/useIsLoadingStatus";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import {
  Notification,
  NotificationEvent,
} from "@progress/kendo-react-notification";
import { isString } from "lodash";
import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { useUnmount } from "react-use";
import "./_index.scss";

export const CCAppNotificationItem = (props: IAppNotificationItem) => {
  const {
    title,
    description,
    type = "none",
    showIcon = true,
    autoClose = true,
    closable = true,
    className,
    delay = 3000,
    onClose,
    dir,
    style,
    onUnmount,
  } = props;
  const timeOutRef = useRef<NodeJS.Timeout | null>(null);
  const { isLoadingFullScreen } = useIsLoadingStatus();

  const handleOnClose = (event: NotificationEvent) => {
    if (onClose) onClose(props, event);
  };

  useEffect(() => {
    if (timeOutRef.current && isLoadingFullScreen) {
      clearTimeout(timeOutRef.current);
    }
    if (autoClose && !isLoadingFullScreen) {
      timeOutRef.current = setTimeout(() => {
        if (onClose) {
          onClose(props);
        }
      }, delay);
    }

    return () => {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
    };
  }, [autoClose, delay, isLoadingFullScreen, onClose, props]);

  useUnmount(() => {
    if (onUnmount) onUnmount(props);
  });

  const convertNodeToHtml = useCallback(
    (node?: ReactNode) => (isString(node) ? sanitizeHtml(node) : node),
    []
  );

  return (
    <Notification
      className={`cc-app-notification-item ${className ?? ""}`}
      type={{
        style: type,
        icon: showIcon,
      }}
      closable={closable}
      dir={dir}
      style={style}
      onClose={handleOnClose}
    >
      {title ? (
        <span className="cc-app-notification-item-title">
          {convertNodeToHtml(title)}
        </span>
      ) : null}
      {description && (
        <div className="cc-app-notification-item-descriptions">
          {Array.isArray(description) ? (
            description.map((desc, index) => (
              <span key={index}>{convertNodeToHtml(desc)}</span>
            ))
          ) : (
            <span>{convertNodeToHtml(description)}</span>
          )}
        </div>
      )}
    </Notification>
  );
};
