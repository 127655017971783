import { DefermentMethodFieldValue } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/create-deferment/model";
import { useModifyDefermentDialogStore } from "@app/products/property/assessments/deferments/components/action-bar/dialogs/modify-deferment/store";
import { nameOfLov } from "@app/products/property/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const ModifyDefermentDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const { modifyDefermentLOVs } = useModifyDefermentDialogStore();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const methodFieldValue = getFieldValue("MethodId");

    const renderField = () => {
      switch (methodFieldValue) {
        case DefermentMethodFieldValue.MANUALLY_JOURNALLED_AMOUNTS:
          return (
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">
                  Deferment of levies is to apply from
                </label>
                <Field
                  name={nameOf("IsApplyFrom")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  max={new Date(getFieldValue("IsApplyTo"))}
                  disabled
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">to</label>
                <Field
                  name={nameOf("IsApplyTo")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
          );
        case DefermentMethodFieldValue.ONE_OFF_AMOUNT:
        case DefermentMethodFieldValue.TASMANIAN_DEFERMENT:
          return (
            <>
              <div className="cc-field">
                <CCLabel title="Deferment date" />
                <Field
                  name={nameOf("DefermentDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled
                />
              </div>
              <div className="cc-field">
                <CCLabel title="One off amount" />
                <Field
                  name={nameOf("OneOffAmount")}
                  component={CCCurrencyInput}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                  disabled
                />
              </div>
            </>
          );
        default:
          return (
            <>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <CCLabel title="Deferment of levies is to apply from" />
                  <Field
                    name={nameOf("IsApplyFrom")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    max={new Date(getFieldValue("IsApplyTo"))}
                    disabled
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">to</label>
                  <Field
                    name={nameOf("IsApplyTo")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    min={new Date()}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
              <div className="cc-field">
                <CCLabel title="Percentage of deferable levies to defer" />
                <Field
                  name={nameOf("Percentage")}
                  placeholder={"Percentage of deferable levies to defer"}
                  component={CCNumericTextBox}
                  format={PERCENTAGE_FORMAT.PERCENTAGE}
                  disabled
                />
              </div>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Maximum Amount</label>
                  <Field
                    name={nameOf("MaximumAmount")}
                    component={CCCurrencyInput}
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Overall Maximum Amount</label>
                  <Field
                    name={nameOf("OverallMaximumAmount")}
                    component={CCCurrencyInput}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
            </>
          );
      }
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Assessment Id" />
            <Field
              name={nameOf("Assessment_Id")}
              component={CCInput}
              readOnly
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Type" />
            <Field
              name={nameOf("TypeId")}
              component={CCSearchComboBox}
              data={modifyDefermentLOVs?.Type ?? []}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              disabled
              value={getDropdownValue(
                valueGetter(nameOf("TypeId"))?.toString(),
                modifyDefermentLOVs?.Type ?? [],
                "Code"
              )}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Method" />
            <Field
              name={nameOf("MethodId")}
              component={CCSearchComboBox}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              data={modifyDefermentLOVs?.Method ?? []}
              disabled={options?.isReadOnly}
              value={getDropdownValue(
                valueGetter(nameOf("MethodId"))?.toString(),
                modifyDefermentLOVs?.Method ?? [],
                "Code"
              )}
            />
          </div>
        </div>
        {renderField()}
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Application Date</label>
            <Field
              name={nameOf("ApplicationDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Approval Date</label>
            <Field
              name={nameOf("ApprovalDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Notes</label>
            <Field
              name={nameOf("Notes")}
              placeholder={"Notes"}
              component={CCTextArea}
              rows={5}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
