import { ICCRoute } from "@common/constants/ICCRoute";
export const publicHolidaysRoute: ICCRoute = {
  path: "public-holidays",
  name: "Public Holidays",
  component: require("./_index").default,
  children: [
    {
      path: ":id([0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
