import { postComplianceRestrictionsById } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/buttons/modify-functionality-restrictions/api";
import { V_Compliance_Functionality } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/dialog/modify-functionality-restrictions/model";
import { FunctionalityRestrictionsDialog } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/components/dialog/modify-functionality-restrictions/_index";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IComplianceFunctionalityRestrictionsButton {
  complianceId: number;
}

export const ComplianceFunctionalityRestrictionsButton = observer(
  ({ complianceId }: IComplianceFunctionalityRestrictionsButton) => {
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { clearErrorNotification, pushNotification } =
      useCCAppNotificationStore();
    const { reLoadCompliance } = useComplianceStore();

    const handleEditComplianceFunctionalityRestrictions = async (
      dataItem: V_Compliance_Functionality[]
    ) => {
      setIsLoading(true);
      const response = await postComplianceRestrictionsById(
        complianceId,
        dataItem
      );
      setIsLoading(false);
      setIsShowDialog(false);
      clearErrorNotification();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        reLoadCompliance();
        pushNotification({
          type: "success",
          title:
            response?.data?.SuccessMessage ??
            "Function restrictions saved successfully",
        });
      } else {
        pushNotification({
          type: "error",
          title: response?.data?.ErrorMessage ?? "Function restrictions failed",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title={"Modify functionality restrictions"}
          onClick={() => setIsShowDialog(true)}
        />
        {isShowDialog && (
          <FunctionalityRestrictionsDialog
            title="Compliance Functionality"
            isLoading={isLoading}
            complianceId={complianceId}
            onClose={() => {
              setIsShowDialog(false);
            }}
            onSubmit={handleEditComplianceFunctionalityRestrictions}
          />
        )}
      </>
    );
  }
);
