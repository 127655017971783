import { getFirePreventionMemos } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/api";
import { FirePreventionMemo } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/model";
import { sleep } from "@common/utils/common";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class FirePreventionMemoStore {
  private _memos?: FirePreventionMemo[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get memos() {
    return this._memos;
  }
  setMemo = (memos?: FirePreventionMemo[]) => {
    runInAction(() => {
      this._memos = memos;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._memos = [];
      this._isLoading = false;
    });
  };

  loadMemo = async (caseId: number) => {
    this.setIsLoading(true);

    const memos = await getFirePreventionMemos(caseId);
    // use for mock data. prevent add duplicate getFirePreventionMemos when run addMemo()
    if (!this.memos) {
      this.setMemo(memos);
    }

    this.setIsLoading(false);
  };
  addMemo = async (newMemo: FirePreventionMemo) => {
    this.setIsLoading(true);
    await sleep(1000);
    let cloneMemo = this.memos ? [...this.memos] : [];
    cloneMemo.unshift({
      ...newMemo,
      MemoId: cloneMemo[0].MemoId + 1,
    });
    this.setMemo(cloneMemo);
    this.setIsLoading(false);
    return true;
  };
}

const firePreventionMemoStoreContext = createContext(
  new FirePreventionMemoStore()
);
export const useFirePreventionMemoStore = () => {
  return useContext(firePreventionMemoStoreContext);
};
