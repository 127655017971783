import { loadVoterDetail } from "@app/products/property/contacts-central-names/voters-list/components/detail/api";
import { voterDetailCols as voterDetailsCols } from "@app/products/property/contacts-central-names/voters-list/components/detail/config";
import { DTO_VoterDetails } from "@app/products/property/contacts-central-names/voters-list/components/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<DTO_VoterDetails>();
export const VoterDetails = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const [isLoading, setIsLoading] = useState<boolean>();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [voterDetailsData, setVoterDetailsData] = useState<DTO_VoterDetails[]>(
    []
  );

  const loadVoterDetailData = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadVoterDetail(dataItem.Voter_Id);
    setIsLoading(false);
    if (isSuccessResponse(response) && response.data) {
      const voterDetailsData = response.data.VoterDetails?.map(
        (item: DTO_VoterDetails) => ({
          ...item,
          RowId: getUUID(),
        })
      );
      setVoterDetailsData(voterDetailsData ?? []);
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error ?? "Load failed",
      });
    }
  };

  useEffectOnce(() => {
    loadVoterDetailData();
  });

  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadVoterDetailData();
        }}
      />
    );
  }

  return (
    <CCGrid
      isLoading={isLoading}
      data={voterDetailsData}
      columnFields={voterDetailsCols}
      primaryField={nameOf("RowId")}
    />
  );
};
