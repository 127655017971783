import { getContactsTab } from "@app/core/contacts/components/contact-tab/api";
import { ContactTabEventType } from "@app/core/contacts/components/contact-tab/config";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { customLogger } from "@common/utils/logger";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface IContactsTabProps {
  id?: number;
  recordType?: RECORDTYPE;
  triggerReload: any;
}

export const ContactsTab = observer(
  ({ id, recordType, triggerReload }: IContactsTabProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [data, setData] = useState<ICommonContactItemRender[] | undefined>();

    const handleOnLoadContact = async () => {
      if (!id || !recordType) return;
      setIsLoading(true);
      let newContactData: ICommonContactItemRender[] | undefined = [];
      let errorResponse = undefined;
      const response = await getContactsTab(id, recordType);
      customLogger("Core Contact, Contact tab, getContactsTab").info(
        response?.data
      );
      if (isSuccessResponse(response)) {
        newContactData = response.data?.value;
      } else {
        errorResponse = {
          status: response.status,
          error: response.error ?? response.data?.error?.message,
        };
      }
      setData(newContactData);
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    useEffect(() => {
      handleOnLoadContact();
      // eslint-disable-next-line
    }, [triggerReload, id, recordType]);

    useAddUniqueEventEmitter([
      {
        eventType: ContactTabEventType.RefreshData,
        listener: () => {
          handleOnLoadContact();
        },
      },
    ]);

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            handleOnLoadContact();
          }}
        />
      );
    return <ContactsSidebar data={data || []} />;
  }
);
