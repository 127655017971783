import { CORE_GET_VIEW_SITEUSER_ODATA } from "@app/core/components/common/constant";
import {
  colSettingSiteUsers,
  SettingFieldMapping,
} from "@common/pages/settings/config";

export const SETTING_CONTANTS = {
  AUTHENTICATION_TYPE: "None",
  ENTERPRISEKEY_SET: "E2D036C6-EBED-4A3C-BB84-E151C926FFBC",
};

export const STATUS_USER = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

export const CATEGORY_ATTACHMENT = [
  { Value: "Private Surveyor Documents", Id: 1 },
  { Value: "Additional information", Id: 2 },
  { Value: "Protection work", Id: 3 },
  { Value: "Determinations and modifications", Id: 4 },
  { Value: "Performance solutions", Id: 5 },
  { Value: "Private Surveyor Documents (2)", Id: 6 },
  { Value: "Domestic Building Work", Id: 7 },
  { Value: "Other documents", Id: 8 },
];

export const CRMS_FORM_TYPE = [
  { Text: "Standard", Value: true },
  { Text: "Simple", Value: false },
];

export const EVENT_SIMPLE_METHOD = [
  { Text: "Basic", Value: "Basic" },
  { Text: "Standard", Value: "Standard" },
];

export const LIST_SALUTATION = [
  { Value: "Dr", Id: 1 },
  { Value: "Miss", Id: 2 },
  { Value: "Mr", Id: 3 },
  { Value: "Mrs", Id: 4 },
  { Value: "Ms", Id: 5 },
];
export enum LOGIN_MODE {
  AZUREAD = "OpenIDConnect",
  FORM = "",
}
export enum DATA_TYPE {
  LABEL = "Label",
  LIST = "List",
  STRING = "String",
  BOOLEAN = "Boolean",
  LOOKUP = "Lookup",
  INT = "Int",
  DECIMAL = "Decimal",
  DATE = "Date",
  DATETIME = "DateTime",
  MONEY = "Money",
  PERCENT = "Percent",
}

export enum SettingRoute {
  SETTING_LOOKUPS_KEYWORDS = "/settings/lookups/keywords",
  SETTING_LOOKUPS_DOCUMENTS = "/settings/lookups/documents",
  SETTING_LOOKUPS_DOCUMENTTEMPLATES = "/settings/lookups/documenttemplates",
  SETTING_LOOKUPS_LOCALITIES = "/settings/lookups/localities",
  SETTING_LOOKUPS_ACTIONTYPES = "/settings/lookups/actiontypes",
  SETTING_LOOKUPS_FEETYPES = "/settings/lookups/feetypes",
  SETTING_LOOKUPS_INSPECTIONTYPES = "/settings/lookups/inspectiontypes",
  SETTING_LOOKUPS_SAMPLETYPES = "/settings/lookups/sampletypes",
  SETTING_LOOKUPS_COMPLAINTTYPES = "/settings/lookups/complainttypes",
  SETTING_LOOKUPS_NOTICETYPES = "/settings/lookups/noticetypes",
  SETTING_LOOKUPS_INTERVIEWTYPES = "/settings/lookups/interviewtypes",
  SETTING_LOOKUPS_PUBLICHOLIDAYS = "/settings/lookups/publicholidays",
  SETTING_LOOKUPS_PROPERTYALERTS = "/settings/lookups/propertyalerts",
  SETTING_LOOKUPS_LOCALGOVERNMENT = "/settings/lookups/localgovernment",
  SETTING_LOOKUPS_REGIONS = "/settings/lookups/regions",
  SETTING_LOOKUPS_WORKFLOWS = "/settings/lookups/workflows",
  SETTING_LOOKUPS_STATEROADS = "/settings/lookups/stateroads",
  SETTING_LOOKUPS_DYNAMICQUESTIONS = "/settings/lookups/dynamicquestions",
  SETTING_LOOKUPS_DYNAMICLISTS = "/settings/lookups/dynamiclists",
  SETTING_LOOKUPS_DIVISIONS = "/settings/lookups/divisions",
  SETTING_COMMUNICATION_QUEUE = "/settings/communication/queue",
  SETTING_COMMUNICATION_TEMPLATE = "/settings/communication/template",
  SETTING_SECURITY_CORPORATEAUTHORISATIONS = "/settings/security/corporateauthorisations",
  SETTING_SECURITY_ORGSTRUCTURE = "/settings/security/orgstructure",
  SETTING_SECURITY_SECURITYTEMPLATES = "/settings/security/securitytemplates",
  SETTING_SECURITY_SITEUSERS = "/settings/security/siteusers",
  SETTING_SECURITY_SITEUSERSBYORGSTRUCTURE = "/settings/security/siteusersbyorgstructure",
  SETTING_SECURITY_SITEUSERSBYADHOCGROUP = "/settings/security/siteusersbyadhocgroup",
  SETTING_SECURITY_SITEUSERSBYSECTEMPLATE = "/settings/security/siteusersbysectemplate",
  SETTING_SECURITY_ADSYNC = "/settings/security/adsync",
  SETTING_HOMEPAGE_HOMEPAGESHORTCUTS = "/settings/homepage/homepageshortcuts",
  SETTING_HOMEPAGE_HOMEPAGEVIEWS = "/settings/homepage/homepageviews",
  SETTING_HOMEPAGE_USERSBYSHORTCUTS = "/settings/homepage/usersbyshortcuts",
  SETTING_HOMEPAGE_USERSBYVIEWS = "/settings/homepage/usersbyviews",

  SETTING_SYSTEMADMIN_COMPLAINTSSETTINGS = "/settings/systemadmin/complaintssettings",
  SETTING_SYSTEMADMIN_SYSTEMLOGS = "/settings/systemadmin/systemlogs",
  SETTING_SYSTEMADMIN_WINDOWSLOGS = "/settings/systemadmin/windowslogs",
  SETTING_SYSTEMADMIN_RECYCLEBIN = "/settings/systemadmin/recyclebin",
  SETTING_SYSTEMADMIN_JOBQUEUE = "/settings/systemadmin/jobqueue",
  SETTING_SYSTEMADMIN_JOBQUEUEFAILURES = "/settings/systemadmin/jobqueuefailures",
  ROOT_SYSTEMADMIN = "/settings/system-admin/core/",
  ROOT_PRODUCT_SYSTEMADMIN = "/system-admin/settings/",
  SETTING_SYSTEMADMIN_PROPERTYSETTINGS = "/settings/systemadmin/property/assessment",
  //ASSET MANAGER
  SETTING_SYSTEMADMIN_ASM_MODULE = "/settings/systemadmin/assetmanager/modulesettings",
  SETTING_SYSTEMADMIN_ASM_CATALOGUETYPES = "/settings/systemadmin/assetmanager/cataloguetypes",
  SETTING_SYSTEMADMIN_ASM_LOOKUPS = "/settings/systemadmin/assetmanager/lookups",
}

export enum SettingFormRoute {
  SETTING_SECURITY_SITEUSERS_FORM = "/settings/security/site-users",
  SETTING_API_SITEUSERS = "/api/core/internal/siteuser",
}

export enum AdminSettingRoute {
  SETTINGS_HM_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/healthmanager",
  SETTINGS_WASTEWATER_SYSTEMADMIN_SETTINGS = "/settings/systemadmin/wastewater",
  SETTINGS_ANIMALS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/animals",
  SETTINGS_BUILDING_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/building",
  SETTINGS_COMPLAINTS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/complaints",
  SETTINGS_DEVAPPS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/devapps",
  SETTINGS_LLP_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/llpermits",
  SETTINGS_TRAININGMANAGER_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/trainingmanagers",
  SETTINGS_DISRES_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/disres",
  SETTINGS_CRS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/crs",
  SETTINGS_EMS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/ems",
  SETTINGS_RADIATION_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/radiation",
  SETTINGS_CSM_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/csm",
  SETTINGS_AMS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/AssetManager",
  SETTINGS_TOWNPLANNING_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/townplanning",
  SETTINGS_CRMS_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/customerservice",
  SETTING_SYSTEMADMIN_GLOBALSETTINGS = "/settings/systemadmin/core",
  SETTINGS_ACTTION_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/action",
  SETTINGS_EVENT_SYSTEMADMIN_SYSTEMSETTINGS = "/settings/systemadmin/eventmanagementsystem",
  SETTING_SYSTEMADMIN_PROPERTYSETTINGS = "/settings/systemadmin/property/assessment",
}

export enum CoreRoute {
  SETTING_SYSTEMADMIN_CORE_GLOBAL = "/settings/systemadmin/core/global",
  SETTING_SYSTEMADMIN_CORE_LOOKUPS = "/settings/systemadmin/core/lookups",
  SETTING_SYSTEMADMIN_CORE_HOMEPAGE = "/settings/systemadmin/core/homepage",
}

export const SettingUrl = {
  UPDATE_SETTING_SITE_USERS: `${SettingFormRoute.SETTING_SECURITY_SITEUSERS_FORM}/{id}`,
  GET_SETTING_SITE_USERS: `${SettingFormRoute.SETTING_API_SITEUSERS}/{id}`,
  CREATE_SITE_USERS: SettingFormRoute.SETTING_API_SITEUSERS,
  SETTING_GET_CONTACTS: `${SettingFormRoute.SETTING_API_SITEUSERS}/isusernameavaliable`,
  GET_VIEW_SETTING_SITE_USER: CORE_GET_VIEW_SITEUSER_ODATA,
  GET_ATTACHMENT_BY_ID: "/api/Document/{id}",
  UPDATE_SETTING_MANAGE_FORM: "/api/core/internal/settings",
  SITE_USER_CHANGE_PASSWORD: "/api/core/internal/siteuser/changepassword",
  SITE_USER_CHANGE_USERNAME: "/api/core/internal/siteuser/changeusername",
  SITE_USER_ADD_ATTACHMENT: "/api/core/internal/document/addattachment/",
  SITE_USER_DETAIL: SettingFormRoute.SETTING_SECURITY_SITEUSERS_FORM,
};
export const SettingListRoute = [
  {
    path: [SettingRoute.SETTING_SECURITY_SITEUSERS],
    initialColumnFields: colSettingSiteUsers,
    dataUrl: SettingUrl.GET_VIEW_SETTING_SITE_USER,
    highLightColumns: SettingFieldMapping.LoginName,
    detailUrl: SettingFormRoute.SETTING_SECURITY_SITEUSERS_FORM,
  },
  // {
  //   path: [AdminSettingRoute.SETTINGS_HM_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: HMUrl.GET_VIEW_HM_PREMISES_REGISTER,
  //   detailUrl: FormRoute.FORM_HM_PREMISE,
  //   highLightColumns: "TradingName",
  // },

  // {
  //   path: [AdminSettingRoute.SETTINGS_WASTEWATER_SYSTEMADMIN_SETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: WastewaterUrl.GET_VIEW_WASTEWATER_SYSTEMS_REGISTER,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: WastewaterFieldMapping.PermitNumber,
  // },

  // {
  //   path: [AdminSettingRoute.SETTINGS_ANIMALS_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: AnimalsUrl.GET_VIEW_ANIMALS_REGISTER_ACTIVE,
  //   highLightColumns: AnimalsFieldMapping.RegistrationNumber,
  //   detailUrl: FormRoute.FORM_ROUTE,
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_BUILDING_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: BuildingUrl.GET_VIEW_BUILDING_APPLICATIONS_REGISTER,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: BuildingFieldMapping.ApplicationNumber,
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_LLP_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: LocalLawsUrl.GET_VIEW_LOCALLAWS_PERMITS_REGISTER,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: LocalLawsFieldMapping.PermitNumber,
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_DISRES_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: ParkingPermitsUrl.GET_VIEW_PARKINGPERMITS_PERMITS_ALLPERMITS,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: ParkingPermitsFieldMapping.PermitType_Name,
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_CRS_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: CRSUrl.GET_VIEW_CRS_APPLICATIONS_LODGED,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: "Registration_ReferenceNo",
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_CSM_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: CSLUrl.GET_VIEW_CSL_LICENCES_ISSUED,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: "LicenceNumber",
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_AMS_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: AmsUrl.GET_VIEW_ASSET_REGISTER_ALL,
  //   detailUrl: AmsRoute.ASSET_MANAGE_DETAIL,
  //   highLightColumns: "AssetNumber",
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_TOWNPLANNING_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: TownPlanningUrl.GET_VIEW_TOWNPLANNING_ALLAPPLICATION_REGISTER,
  //   detailUrl: `${GeneralRoute.TOWNPLANNING_PATH}${FormRoute.FORM_ROUTE}`,
  //   highLightColumns: TownPlanningFieldMapping.ApplicationNumber,
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_CRMS_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: EventManagementUrl.GET_VIEW_EVENTMANAGEMENT_BOOKINGS_ALL,
  //   detailUrl: CrmsRoute.CRMS_DETAIL,
  //   highLightColumns: EventManagementFieldMapping.EventBooking_ReferenceNo,
  // },

  // {
  //   path: [AdminSettingRoute.SETTINGS_ACTTION_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: ActionsUrl.GET_VIEW_ACTIONS_MYACTIONS_BYRECORDEDDATE,
  //   detailUrl: ActionsRoute.ACTIONS_DETAIL,
  //   highLightColumns: ActionsFieldMapping.RefNo,
  // },
  // {
  //   path: [AdminSettingRoute.SETTINGS_EVENT_SYSTEMADMIN_SYSTEMSETTINGS],
  //   initialColumnFields: SettingList,
  //   dataUrl: EventManagementUrl.GET_VIEW_EVENTMANAGEMENT_BOOKINGS_ALL,
  //   detailUrl: FormRoute.FORM_ROUTE,
  //   highLightColumns: EventManagementFieldMapping.EventBooking_ReferenceNo,
  // },
];

export enum FormActionCheckPermission {
  CONTACTS = 102,
  COMMUNICATION_MENU = 6002,
  HOMEPAGE_MENU = 6007,
  SECURITY_MENU = 6003,
  SETTINGS_MENU = 6004,
  OUTSTANDING_MENU = 6006,
  RECORDS_MENU = 6011,
  GLOBAL_SETTINGS = 104,
  CORE_DASHBOARD_REPORTS = 101,
  ACTIONS_DASHBOARD = 103,
  CRMS_CLOSEDEVENT_REOPENEVENT = 17011,
}
