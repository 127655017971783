import { history } from "@/AppRoutes";
import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import {
  FurtherInformationRequest,
  IParentFISection,
} from "@app/core/further-info/[id]/model";
import { furtherInfoStore } from "@app/core/further-info/[id]/store";
import { putAdditionalInfoRequired } from "@app/core/further-info/components/buttons/work-flow/additional-info-required/api";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { customLogger } from "@common/utils/logger";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AdditionalInfoRequiredButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  additionalInfoRequired = async (
    fiRequestSection: IParentFISection,
    fiRequest: FurtherInformationRequest
  ) => {
    this.setIsLoading(true);
    const response = await putAdditionalInfoRequired(fiRequest);
    customLogger("Core Further info, putAdditionalInfoRequired").info(
      response?.data
    );
    furtherInfoStore.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      const newFIRequestID = response.data?.ID;
      if (fiRequest.FurtherInformationRequest_ID === newFIRequestID) {
        history.replace(`${FURTHER_INFO_ROUTE}/new`, {
          parent: {
            ...fiRequestSection,
            data: {
              DateRequested: fiRequest.DateRequested,
              DateDue: fiRequest.DateDue,
              NumberOfDaysGiven:
                (fiRequest.NumberOfDaysGiven ?? 0) +
                (fiRequest.ExtensionNumberOfDaysGiven ?? 0),
              Details: fiRequest.Details,
              Officer: fiRequest.Officer,
              Officer_ID: fiRequest.Officer_ID,
              OrignalFI_ID: fiRequest.FurtherInformationRequest_ID,
              FI_Source: fiRequest.FI_Source,
              OriginalDateDue: fiRequest.OriginalDateDue,
            },
            parentAction: undefined,
          } as IParentFISection,
        });
      } else {
        history.replace(`${FURTHER_INFO_ROUTE}/${newFIRequestID}`);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Additional information required failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const additionalInfoRequiredButtonStore =
  new AdditionalInfoRequiredButtonStore();
const additionalInfoRequiredButtonContext = createContext(
  additionalInfoRequiredButtonStore
);
export const useAdditionalInfoRequiredButtonStore = () => {
  return useContext(additionalInfoRequiredButtonContext);
};
