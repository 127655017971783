import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { useNewBookmarkStore } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useMemo } from "react";

interface IActionBarBookmarkIconProps {
  bookmarkList: IBookmarkItem[];
  disabled?: boolean;
  bookmarkOptions?: "page" | "record";
}

export const ActionBarBookmarkIcon = observer(
  ({
    bookmarkList,
    disabled = false,
    bookmarkOptions = "page",
  }: IActionBarBookmarkIconProps) => {
    const { saveBookmark, bookmarks, isLoading } = useNewBookmarkStore();

    const isBookmarkedAll = useMemo(() => {
      if (bookmarkList) {
        if (bookmarkList.length > 0) {
          return bookmarkList.every((item: IBookmarkItem) => {
            return bookmarks.some(
              (bookmark: IBookmarkItem) => bookmark.LinkUrl === item.LinkUrl
            );
          });
        }
      }
      return false;
    }, [bookmarkList, bookmarks]);

    const buttonTooltip = useMemo(() => {
      switch (bookmarkOptions) {
        case "page":
          if (isBookmarkedAll) {
            return "This page has been bookmarked";
          } else {
            return "Bookmark this page";
          }
        case "record":
          if (isBookmarkedAll) {
            return `${pluralize(
              "Record",
              bookmarkList.length
            )} has been bookmarked`;
          } else {
            return `Bookmark this ${pluralize("record", bookmarkList.length)}`;
          }
      }
    }, [bookmarkList.length, bookmarkOptions, isBookmarkedAll]);

    return (
      <CCNavButton
        iconClass="fal fa-bookmark"
        isLoading={isLoading}
        title={buttonTooltip}
        disabled={disabled}
        onClick={() => {
          saveBookmark(bookmarkList, isBookmarkedAll);
        }}
        isActive={isBookmarkedAll}
      />
    );
  }
);
