import { PremisesType } from "@app/products/hm/model";
import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { optionPremisesTypes } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/premises-type-section/config";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  SecondaryModeCheckField,
  Svc_FormAction_Premise,
  Svc_HMPremisesType,
} from "@app/products/hm/premises/[id]/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

export interface IHMPremisesTypeSectionProps {
  isInactive?: boolean;
  allowChangePremisesType?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOfPremisesType = nameOfFactory<PremisesType>();

export const PremisesTypeSection = observer(
  ({
    formRenderProps,
    isInactive = false,
    allowChangePremisesType = true,
  }: IHMPremisesTypeSectionProps) => {
    // #region STORES
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();

    // #endregion STORES

    // #region FORM PROPS
    const { valueGetter, onChange } = formRenderProps;
    const getValuePremises = (name: keyof Premises) =>
      valueGetter(getNameOfPremises(name));
    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const premisesType = getValuePremises("PremisesType") as PremisesType;
    const getValuePremisesType = (name: keyof PremisesType) =>
      valueGetter(`${getNameOfPremises("PremisesType")}.${name}`);
    const getNameOfPremisesType = (name: keyof PremisesType) => {
      return nameOfPremisesType(name);
    };
    // #endregion FORM PROPS

    // #region VARIABLES
    const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;
    const lovs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;
    // #endregion VARIABLES

    // #region GET SETTING VALUES
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    // #endregion GET SETTING VALUES

    //#region FIELD'S VALUE
    const riskValue = useMemo(() => {
      if (premisesType?.RiskClassification_ID) {
        return premisesType.RiskClassification?.Name;
      } else {
        return "";
      }
    }, [
      premisesType?.RiskClassification?.Name,
      premisesType?.RiskClassification_ID,
    ]);

    const premisesClassification = useMemo(() => {
      return premisesType?.PremisesClassification?.Name ?? "";
    }, [premisesType?.PremisesClassification?.Name]);
    //#endregion FIELD'S VALUE

    // #region Secondary Mode
    const checkSecondaryModeLbtPremisesType = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.LbtPremisesType
    );
    // #endregion Secondary Mode

    //#region HANDLE ON CHANGE
    const getInitialPremisesHandlerRequest = (
      formAction: Svc_FormAction_Premise
    ) => {
      return {
        FormAction: formAction,
        Premises: premisesObj,
        Args: {},
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      } as PremiseHandlerRequest;
    };

    const handleChangePremisesType = (premisesType: Svc_HMPremisesType) => {
      if (premisesType) {
        let premisesHandlerRequest: PremiseHandlerRequest =
          getInitialPremisesHandlerRequest(
            Svc_FormAction_Premise.Form_PickPremisesType
          );
        premisesHandlerRequest.Args = [premisesType.ID];

        const handlerInitialData: IHMHandlerEventInitialData = {
          premisesRegisterHandlerRequest: premisesHandlerRequest,
          errorMsg: `Select premises type failed.`,
        };

        fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOfPremises("PremisesType"), { value: null });
        onChange(getNameOfPremises("PremisesType_ID"), { value: 0 });
        onChange(getNameOfPremises("InspectionFrequency"), { value: null });
      }
    };
    //#endregion HANDLE ON CHANGE

    return (
      <>
        <div className="cc-divider-space" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Premises type" isMandatory />
              <Field
                name={getNameOfPremises("PremisesType")}
                nameDisplay={getNameOfPremisesType("Name")}
                placeholder="Select premises type"
                component={InputPickerSearch}
                valueMapGrid={getValuePremisesType("PremisesType_ID")}
                onChange={handleChangePremisesType}
                validator={requiredValidator}
                options={optionPremisesTypes}
                disabled={
                  isInactive ||
                  checkSecondaryModeLbtPremisesType ||
                  !allowChangePremisesType
                }
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Premises classification" />
              <CCValueField value={premisesClassification} />
            </div>
            <div className="cc-field">
              <CCLabel title="Risk" />
              <CCValueField value={riskValue} />
            </div>
          </div>
        </section>
      </>
    );
  }
);
