import { PrivateRoute } from "@/AppRoutes";
import { ProductRouterSwitch } from "@/ProductRouterSwitch";
import { propertyRoute } from "@app/products/property/route";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const PropertyRouter = observer((props: any) => {
  const { isProductActive, isLoading } = useCommonProductStore();

  if (isLoading) return <Loading isLoading isFullScreen />;
  return (
    <ProductRouterSwitch>
      {propertyRoute.children &&
        convertRouteDynamic(propertyRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            const fullPath = `${props.match.url}/${route.path}`;
            if (!isProductActive(fullPath)) {
              return null;
            }
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={fullPath}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
});
