import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import {
  DTO_Request_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsModifyPIC {
  PICID = "PIC_Id",
  Association = "Association",
  PICDetail = "PICDetails",
  Detail = "Details",
  Collection = "SelectedCollection",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum ELabelModifyPIC {
  Related = "Related",
  PICDetail = "PIC details",
  Detail = "Details",
  Collection = "Collections",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export const keysOfStepsModifyPIC = [
  EKeysOfStepsModifyPIC.PICID,
  EKeysOfStepsModifyPIC.Association,
  EKeysOfStepsModifyPIC.PICDetail,
  EKeysOfStepsModifyPIC.Detail,
  EKeysOfStepsModifyPIC.Collection,
];

export interface DTO_Workflow_PIC_Modify {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_PIC_Modify;
}

export interface DTO_WorkflowDetail_PIC_Modify {
  PIC_Id: number | null;
  Details: DTO_PIC_Detail;
  Association: DTO_PIC_ModifyAssociation;
  PICDetails: DTO_PIC_ModifyDetails;
  LOVs: DTO_PIC_ModifyLOVs;
  SelectedCollection: Collection[];
  // TODO: Recheck when have API
  SelectedSecondaryWorkflow: number[];
}

export interface DTO_PIC_ModifyLOVs {
  District: DTO_LOV[];
}

export interface DTO_PIC_Detail {
  ApplicantName: string;
  ReasonId: number;
  Reference: string;
  PICStatus: number;
}

export interface RequestPICModifyObj extends DTO_Request_Base {
  PIC_Id: number;
}

export interface DTO_PIC_ModifyAssociation {
  ApplicantName: string;
  ReasonId: number;
  Reference: string;
  PICStatus: number;
  AssociationDate: Date;
  PrimaryHolding: number | null;
  Holdings: DTO_Assessment[];
  Folios: DTO_Title[];
}

export interface DTO_PIC_ModifyDetails {
  PICNumber: string;
  PICTypeId: number;
  PICLandUseId: number | null;
  AgentLicenseNumber: string;
  RLPBoardId: number | null;
  DistrictId: number | null;
  Occupier: string;
  TradingNameId: number | null;
  PrimaryHolding: string;
  PropertyName: string;
  PropertyAddress: string;
  PropertyLocality: string;
  StateCode: string;
  Postcode: string;
  GisReferences: DTO_GIS[];
  Swines: DTO_PIC_Swine[];
  TradingName?: string;
}
