import {
  getAIMessage,
  loadConversation,
  postAIMessage,
} from "@app/core/chat/ask-ai/api";
import { MessageTemplate } from "@app/core/chat/ask-ai/components/message-template/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { processMessages } from "@app/core/chat/ask-ai/util";
import { isSuccessResponse } from "@common/apis/util";
import Loading from "@components/loading/Loading";
import {
  Chat,
  ChatMessageSendEvent,
  ChatMessageTemplateProps,
  Message,
  User,
} from "@progress/kendo-react-conversational-ui";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IChatSidebarProps {
  productId: number;
}
export const ChatSidebar = observer((props: IChatSidebarProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { getConversationId, getOrGenerateConversationId } =
    useAskAIServiceStore();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const chatRef = useRef<HTMLDivElement | null>(null);
  const [botTyping, setBotTyping] = React.useState(false); // Track if the bot is typing
  const productId = props.productId;
  const handleNewMessage = (event: ChatMessageSendEvent) => {
    const userMessage: Message = {
      author: event.message.author as User,
      text: event.message.text ?? "",
    };
    // Add the user message to the state
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Start the bot typing process
    handleBotResponse(userMessage);
  };
  const handleBotResponse = (userMessage: Message) => {
    setBotTyping(true); // Indicate bot is typing

    const botResponseMessage: Message = {
      text: "Bot is responding...", // Temporary text to indicate bot is typing
      author: { id: 0 },
    };

    // Add the temporary bot message to the state
    setMessages((prevMessages) => [...prevMessages, botResponseMessage]);
    const userMessageText = userMessage.text ?? "";
    postAIMessage(
      userMessageText,
      productId,
      getOrGenerateConversationId(productId)
    ).then((response: any) => {
      if (isSuccessResponse(response) && response.data?.MessageId) {
        getMessage(response.data.MessageId);
      }
    });
  };

  const getMessage = (messageId: string) => {
    setTimeout(() => {
      getAIMessage(messageId).then((response: any) => {
        if (
          isSuccessResponse(response) &&
          response.data?.StreamStatus === "Complete"
        ) {
          setBotTyping(false); // Stop bot typing
          const finalMessage = response.data.FinalResponse ?? "N/A";
          // Update messages after receiving the response
          setMessages((oldMessages) => {
            const newMessages = oldMessages.filter(
              (msg) => msg.text !== "Bot is responding..."
            ); // Remove temporary bot message
            newMessages.push({
              text: finalMessage,
              author: { id: 0 },
            });
            return newMessages;
          });
        } else {
          getMessage(messageId);
        }
      });
    }, 300); // Simulate a delay
  };

  useEffectOnce(() => {
    const conversationId = getConversationId(productId);
    if (conversationId != null) {
      setIsLoading(true);
      loadConversation(conversationId).then((response: any) => {
        if (isSuccessResponse(response) && response.data?.Messages) {
          const messageProcess = processMessages(response.data.Messages);
          setMessages([...messageProcess]);
        }
        setIsLoading(false);
      });
    }

    if (chatRef.current) {
      // Focus on the input field
      const input = chatRef.current.querySelector<HTMLInputElement>(".k-input");
      if (input) input.focus();
    }
  });

  // Auto scroll to bottom when new messages arrive
  useEffect(() => {
    if (chatRef.current) {
      const messagesContainer =
        chatRef.current.querySelector<HTMLDivElement>(".k-message-list");
      if (messagesContainer) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }
  }, [messages]);

  return (
    <div className="chat-body">
      {isLoading ? (
        <Loading isLoading /> // Display loading indicator while waiting for conversation
      ) : (
        <Chat
          messages={messages}
          onMessageSend={handleNewMessage}
          placeholder="Type your message..." // Simplified placeholder prop
          user={{ id: 1 }} // User object for chat
          messageTemplate={(props: ChatMessageTemplateProps) => {
            return <MessageTemplate {...props} isLoading={botTyping} />;
          }}
          width="100%" // Set width to 100% to fit the parent container
        />
      )}
    </div>
  );
});
