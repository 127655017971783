import {
  DTO_Compliance,
  DTO_Entity,
} from "@app/products/property/assessments/compliance/[id]/model";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { ResponsibleOfficerSearch } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/components/responsible-officer/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { DocumentsIssuedGrid } from "@app/products/property/components/grid/document-issued-grid/_index";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useIsEditable } from "@common/hooks/useIsEditable";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { colOwners } from "./config";

interface IComplianceSummaryProps {
  formRenderProps: FormRenderProps;
}
const nameOfOwner = nameOfFactory<DTO_Entity>();
const nameOf = nameOfFactory<DTO_Compliance>();
export const PropertyComplianceGeneralFormElement = observer(
  ({ formRenderProps }: IComplianceSummaryProps): ReactElement => {
    const isEditable = useIsEditable();
    const { complianceId, compliance, complianceLOV } = useComplianceStore();
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: keyof DTO_Compliance) =>
      valueGetter(nameOf(name));
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
    };

    return (
      <FormElement className="cc-compliance-form-body">
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Compliance ID</label>
              <Field
                readOnly
                name={nameOf("Id")}
                placeholder={"Compliance ID"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Active from</label>
              {!isEditable ? (
                <Field
                  disabled={!isEditable}
                  name={nameOf("ComplianceDateFrom")}
                  placeholder={"Active from"}
                  component={CCDateTimePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              ) : (
                <Field
                  name={nameOf("ComplianceDateFrom")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDateTimePicker}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">Active to</label>
              {!isEditable ? (
                <Field
                  disabled={!isEditable}
                  name={nameOf("ComplianceDateTo")}
                  placeholder={"Active to"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  value={
                    getFieldValue("ComplianceDateTo") &&
                    new Date(getFieldValue("ComplianceDateTo"))
                  }
                />
              ) : (
                <Field
                  name={nameOf("ComplianceDateTo")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Field
                disabled={!isEditable}
                name={nameOf("ComplianceType_Id")}
                placeholder={"Type"}
                textField="Name"
                dataItemKey="Code"
                isUseDefaultOnchange
                component={CCSearchComboBox}
                data={complianceLOV?.ComplianceTypes ?? []}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                disabled={!isEditable}
                name={nameOf("ComplianceStatus_Id")}
                placeholder={"Status"}
                textField="Name"
                dataItemKey="Code"
                isUseDefaultOnchange
                component={CCSearchComboBox}
                data={complianceLOV?.ComplianceStatuses ?? []}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Responsible</label>
              <Field
                name={nameOf("ResponsibleOfficer")}
                component={ResponsibleOfficerSearch}
                textField={"FullName"}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("ResponsibleOfficer"), {
                    value: event.value,
                  });
                  onChange(nameOf("Officer_Id"), {
                    value: event.value?.Id,
                  });
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Questionnaire return date</label>
              {!isEditable ? (
                <Field
                  disabled={!isEditable}
                  name={nameOf("ComplianceQuestionnaireReturnDate")}
                  placeholder={"Questionnaire return date"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  value={
                    getFieldValue("ComplianceQuestionnaireReturnDate") &&
                    new Date(getFieldValue("ComplianceQuestionnaireReturnDate"))
                  }
                />
              ) : (
                <Field
                  name={nameOf("ComplianceQuestionnaireReturnDate")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDateTimePicker}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">Rented from</label>
              <Field
                readOnly={!isEditable}
                name={nameOf("ComplianceRentedFrom")}
                placeholder={"Rented from"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Interest from</label>
              {!isEditable ? (
                <Field
                  disabled={!isEditable}
                  name={nameOf("ComplianceInterestFrom")}
                  placeholder={"Interest from"}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  value={
                    getFieldValue("ComplianceInterestFrom") &&
                    new Date(getFieldValue("ComplianceInterestFrom"))
                  }
                />
              ) : (
                <Field
                  name={nameOf("ComplianceInterestFrom")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                />
              )}
            </div>
            <div className="cc-field">
              <label className="cc-label">Created on</label>
              <Field
                name={nameOf("ComplianceCreatedOn")}
                disabled
                placeholder={"Created on"}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Created by</label>
              <Field
                readOnly
                name={nameOf("ComplianceCreatedBy")}
                placeholder={"Created by"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Penalty</label>
              <Field
                readOnly={!isEditable}
                name={nameOf("CompliancePenaltyPercentage")}
                placeholder={"Penalty"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Modified on</label>
              <Field
                name={nameOf("ComplianceModifiedOn")}
                disabled
                placeholder={"Modified on"}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Modified by</label>
              <Field
                readOnly
                name={nameOf("ComplianceModifiedBy")}
                placeholder={"Modified by"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Show alert on assessment</label>
              <Field
                // enable as required in task 8166
                name={nameOf("ComplianceShowAlertOnAssess")}
                checked={getFieldValue("ComplianceShowAlertOnAssess")}
                component={CCSwitch}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Ratepayers</label>
              <Field
                readOnly={!isEditable}
                name={nameOf("NameAddress")}
                rows={5}
                placeholder={"Ratepayers"}
                component={CCTextArea}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Owners</label>
              <CCGrid
                className="cc-owners-grid"
                data={compliance?.Entities ?? []}
                columnFields={colOwners}
                primaryField={nameOfOwner("EntityId")}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Search details</label>
              <Field
                readOnly={!isEditable}
                name={nameOf("SearchDetails")}
                rows={5}
                placeholder={"Search details"}
                component={CCTextArea}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Documents issued</label>
              <DocumentsIssuedGrid
                componentNumber={eComponent.Compliance}
                id={complianceId}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
