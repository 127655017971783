import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfStateRoad } from "@common/pages/settings/lookups/state-roads/_id/model";
import { useLookupStateRoadStore } from "@common/pages/settings/lookups/state-roads/_id/store";
import { useCommonCoreStore } from "@common/stores/core/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

interface ILookupStateRoadFormElementProps {
  formRenderProps: FormRenderProps;
}

export const LookupStateRoadFormElement = observer(
  ({ formRenderProps }: ILookupStateRoadFormElementProps) => {
    const { settings } = useCommonCoreStore();
    const { isInactive } = useLookupStateRoadStore();

    const defaultState: string =
      settings[
        ECorporateSettingsField.CorporateSettings_AddressDefaultState
      ]?.Value.toString();

    const StateRoadField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Road name
                <CCTooltip type="validator" position="right" />
              </label>
              <StateRoadField
                name={nameOfStateRoad("RoadName")}
                component={CCInput}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Road number</label>
              <StateRoadField
                name={nameOfStateRoad("RoadNumber")}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Road ID number</label>
              <StateRoadField
                name={nameOfStateRoad("RoadIDNo")}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Road class</label>
              <StateRoadField
                name={nameOfStateRoad("RoadClass")}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Road start number</label>
              <StateRoadField
                name={nameOfStateRoad("RoadStartNo")}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Road start name</label>
              <StateRoadField
                name={nameOfStateRoad("RoadStartName")}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Road start type <CCTooltip type="validator" position="right" />
              </label>
              <StateRoadField
                name={nameOfStateRoad("RoadStartType")}
                component={CCInput}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Road start locality
                <CCTooltip type="validator" position="right" />
              </label>
              <StateRoadField
                name={nameOfStateRoad("RoadStartLocality")}
                component={CCInput}
                validator={requiredValidator}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Road end number</label>
              <StateRoadField
                name={nameOfStateRoad("RoadEnd")}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Road end name</label>
              <StateRoadField
                name={nameOfStateRoad("RoadEndName")}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Road end type</label>
              <StateRoadField
                name={nameOfStateRoad("RoadEndType")}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Road end locality</label>
              <StateRoadField
                name={nameOfStateRoad("RoadEndLocality")}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">State</label>
              <StateRoadField
                name={nameOfStateRoad("RoadState")}
                component={CCInput}
                defaultValue={defaultState}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
