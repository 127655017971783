import {
  AnimalsRenewalsBatchRenewRequest,
  PreRenewalAnimalsDetails,
  QuickRegistrationDetailsRequest,
  QuickRegistrationDetailsResponse,
} from "@app/products/animals/renewals/components/button/batch-renew/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const postCalculateRenewalDates = async (
  animalRegistrationIds: number[]
): Promise<APIResponse<IIdentityPacket<DateFromDateTo> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/renewal/batch-renew/calculate-renewal-dates`,
      animalRegistrationIds
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postBatchRenew = async (
  objBatchRenewRequest: AnimalsRenewalsBatchRenewRequest,
  isAdvanceMethod: boolean
): Promise<APIResponse | undefined> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/renewal/batch-renew?isAdvanceMethod=${isAdvanceMethod}`,
      objBatchRenewRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getViewPreRenewalAnimalsDetails = async (
  animalRegisterIDs: number[]
): Promise<APIResponse<PreRenewalAnimalsDetails[]>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/renewal/batch-renew/pre-renewal-animals-details-view`,
      animalRegisterIDs
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getQuickRegistrationDetails = async (
  objQuickRegistrationDetailsRequest: QuickRegistrationDetailsRequest
): Promise<
  APIResponse<IIdentityPacket<QuickRegistrationDetailsResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/renewal/batch-renew/receipt-dialog-box`,
      objQuickRegistrationDetailsRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
