import { ISchemeAccountResponse } from "@app/products/property/schemes/[id]/account/[id]/model";

export const mockSchemeAccountDetail: ISchemeAccountResponse = {
  // AccountId: id,
  AccountStatus: "Active (Estimated costs)",
  AccountNumber: "32049.0008",
  Description: "",
  Comment: "Instalments $324",
  CBA: 7,
  BPay: 7,
  AustPost: 75,
  // EstimatedCosts: "",
  // EstimatedPercentage: "",
  // ActualCosts: "",
  // ActualPercentage: "",
  // Size: "",
  // Coefficient: "",
  // NoofUnits: "",
  Number: 5000000382,
  AssessmentId: 27172,
  //ParcelId: "",
  PropertyAddress: "59 Wheeler Rd, Maryknoll",
  // LegalDescription: "",
  NameAndAddress:
    "SPP No 2 (Inverloch) P/L Level 24 33 Alfred St SPOTSWOOD VIC 3015",
  // NoticeGroup: "",
  // HoldNoticesUntil: "",
  //NoticeMessage: "",
  SchemeNumber: 32049,
  SchemeId: 26,
  Initiated: "28-Jul-2017",
  EstimatedCost: 127709.18,
  // Declared: "",
  // Subsidy: "",
  //Commenced: "",
  EstimatedCostToAssessments: 127709.18,
  // AlertMessage: "",
  LastChangedOn: "28-Jul-2017 13:52",
  LastChangedBy: "HodgesR",
  Comments:
    "Construction of pavement, kerb & channel and associated drainage works",
};
