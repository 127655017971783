import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { JournalStatus } from "@app/products/property/journals/model";
import { SearchRegisterType } from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/model";

export const defaultWhichJournal = {
  JournalMode: JournalMode.CreateNew,
};

export const defaultNewJournal = {
  WhichSupplementary: SupplementaryMode.None,
  Status: JournalStatus.TobeAuthorised,
  Number: 20260,
  Description: "Transfer to another account",
};

export const defaultToAccount = {
  TransactionDate: new Date(),
  RegisterBy: { Key: 1, Value: "Dog" },
  RegisterSearchBy: { Key: 4, Value: "Reference (Tag)" },
  SearchRegisterType: SearchRegisterType.ReferenceTag,
  _option: {
    Assessment: {
      Data: [],
      Loading: false,
    },
  },
};
export const defaultTransaction = {
  Date: new Date(),
  Reference: "",
  Description: "Dog registrations 17-18",
  Amount: 34.5,
  Notes: "",
  Quantity: 1,
};
