import { getViewConfigurations } from "@app/products/property/api";
import { getComplianceAssessment } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/api";
import { colComplianceAssessment } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/config";
import {
  DTO_Compliance_Assessment,
  DTO_C_Assessment,
} from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/model";
import { ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ComplianceAssessmentStore {
  private _complianceAssessment?: DTO_Compliance_Assessment = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _viewConfiguration: IColumnFields[] = colComplianceAssessment;
  private _selectedAssessment?: DTO_C_Assessment = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get complianceAssessment() {
    return this._complianceAssessment;
  }
  setComplianceAssessment = (
    complianceAssessment?: DTO_Compliance_Assessment
  ) => {
    runInAction(() => {
      this._complianceAssessment = complianceAssessment;
    });
  };

  get viewConfiguration() {
    return this._viewConfiguration;
  }
  setViewConfiguration = (viewConfiguration: IColumnFields[]) => {
    runInAction(() => {
      this._viewConfiguration = viewConfiguration;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get selectedAssessment() {
    return toJS(this._selectedAssessment);
  }
  setSelectedAssessment = (selectedAssessment?: DTO_C_Assessment) => {
    runInAction(() => {
      this._selectedAssessment = selectedAssessment;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._complianceAssessment = undefined;
      this._isLoading = false;
      this._viewConfiguration = colComplianceAssessment;
      this._responseLoadError = undefined;
      this._selectedAssessment = undefined;
    });
  };

  loadComplianceAssessment = async (complianceId: number) => {
    this.setIsLoading(true);
    const [responseConfiguration, response] = await Promise.all([
      getViewConfigurations(ViewConfiguration.Compliance_Assessments),
      getComplianceAssessment(complianceId),
    ]);
    let newCompliance = undefined;
    let errorResponse = undefined;
    if (
      isSuccessResponse(responseConfiguration) &&
      responseConfiguration?.data &&
      isSuccessResponse(response) &&
      response?.data
    ) {
      newCompliance = response.data;
      this.setViewConfiguration(
        processDynamicColumns(
          colComplianceAssessment,
          responseConfiguration.data.ColumnDefinitions?.Columns
        )
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setComplianceAssessment(newCompliance);
    this.setIsLoading(false);
  };
}

const complianceAssessmnetStoreContext = createContext(
  new ComplianceAssessmentStore()
);
export const useComplianceAssessmentStore = () => {
  return useContext(complianceAssessmnetStoreContext);
};
