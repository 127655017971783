import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAssociationsPICS } from "@app/products/property/components/associations/components/pics/config";
import { DTO_AssociatedItem_PICs } from "@app/products/property/components/associations/components/pics/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAssociationsPICSProps {
  associationsPICsInfo: DTO_AssociatedItem_PICs[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_PICs>();
export const AssociationsPICS = ({
  associationsPICsInfo,
}: IAssociationsPICSProps) => {
  const [colsPIC, setColsPIC] = useState<IColumnFields[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Associations_PIC,
      colAssociationsPICS
    );
    if (Array.isArray(response)) {
      setColsPIC(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCGrid
      className="cc-title-grid"
      data={associationsPICsInfo || []}
      columnFields={colsPIC ?? []}
      primaryField={nameOf("Id")}
    />
  );
};
