import { AssociationMeter } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import React from "react";

const nameOf = nameOfFactory<AssociationMeter>();

const CustomDatePickerComponent = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  format,
}: ICustomEditCell) => {
  return (
    <DatePicker
      value={value}
      onChange={(event: DatePickerChangeEvent) => {
        if (onChange)
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value,
          });
      }}
      format={DATE_FORMAT.DATE_CONTROL}
      min={dataItem[nameOf("AssociatedFrom")]}
    />
  );
};

export const colRemoveMasterSubMeter: IColumnFields[] = [
  {
    field: nameOf("MeterNo"),
    title: "Meter Number",
    editable: false,
  },
  {
    field: nameOf("AssociatedTo"),
    title: "Associated To",
    editable: true,
    format: DATE_FORMAT.DATE,
    editCell: CustomDatePickerComponent,
  },
];
