import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { getPlanToComplyPPR } from "@app/products/town-planning/ppr/[id]/api";
import { usePlansToComplyButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/plans-to-comply/store";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import {
  Application_Decision,
  IPPRApplicationParentSection,
  PPRSubmitActions,
} from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

export interface IPlansToComplyButtonProps {
  isPPRWorkflow?: boolean;
}

export const PlansToComplyButton = observer(
  ({ isPPRWorkflow = false }: IPlansToComplyButtonProps) => {
    const { ppr, onSubmit } = usePPRStore();
    const history = useHistory();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const { isLoading, setIsPPRWorkflow } = usePlansToComplyButtonStore();
    const isVisible = useMemo(() => {
      if (!isPPRWorkflow) {
        if (ppr?.Flag_PlansToComply) return false;
        else return true;
      }
      if (
        ppr?.Status_ENUM === Application_Status.PermitIssued ||
        ppr?.Status_ENUM === Application_Status.AmendmentIssued ||
        ppr?.Status_ENUM === Application_Status.NoticeOfDecision ||
        ppr?.Status_ENUM === Application_Status.AppealComplete
      ) {
        return true;
      }
      if (
        ppr?.Status_ENUM === Application_Status.ApplicationComplete &&
        ppr?.FinalDecision_ENUM === Application_Decision.VRRESPONDED
      ) {
        return true;
      }
      if (
        ppr?.PlansToComplyReference ||
        ppr?.Flag_PlansToComply ||
        ppr?.AmendmentReference ||
        ppr?.Status_ENUM === Application_Status.Appealed
      ) {
        return false;
      }
      return false;
    }, [isPPRWorkflow, ppr]);
    const loadApplicationPlanToComply = async () => {
      if (ppr) {
        const response = await getPlanToComplyPPR(ppr);
        if (isSuccessResponse(response) && response?.data?.Application) {
          history.push(`${TOWN_PLANNING_PPR_ROUTE}/new`, {
            data: response?.data?.Application,
            isPlanToComply: true,
          } as IPPRApplicationParentSection);
        } else {
          clearErrorNotification();
          pushNotification({
            autoClose: false,
            description: response.data?.Errors ?? response.statusText,
            type: "error",
          });
        }
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          description: "Get data failed",
          type: "error",
        });
      }
    };
    const handleOnClickButton = () => {
      loadApplicationPlanToComply();
    };
    return isVisible ? (
      isLoading ? (
        <Loading isLoading={isLoading} isFullScreen />
      ) : (
        <CCNavButton
          title="Plans to comply"
          onClick={() => {
            if (!onSubmit) return;
            setIsPPRWorkflow(isPPRWorkflow);
            handleOnClickButton();
          }}
          isLoading={isLoading}
          name={PPRSubmitActions.PlansToComply}
        />
      )
    ) : null;
  }
);
