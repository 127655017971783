import React, { Fragment } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { KanbanItem } from "./models";

interface IProps {
  show: boolean;
  onClose: () => void;
  item: KanbanItem;
}
const Window: React.FC<IProps> = ({ show, onClose, item }) => {
  return (
    <Fragment>
      {show && (
        <Dialog onClose={onClose} className="modal">
          <div className={"close-btn-ctn"}>
            <h1 style={{ flex: "1 90%" }}>{item.title}</h1>
            <button className="close-btn" onClick={onClose}>
              X
            </button>
          </div>
          <div>
            <h2>Description</h2>
            <p>{item.content}</p>
            <h2>Status</h2>
            <p>
              {item.icon}{" "}
              {`${item.status.charAt(0).toUpperCase()}${item.status.slice(1)}`}
            </p>
          </div>
        </Dialog>
      )}
    </Fragment>
  );
};

export default Window;
