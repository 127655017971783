import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import { getCertificateDetailsTabById } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/api";
import { DTO_Certificate } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/model";
configure({ enforceActions: "always" });

class CertificateDetailsTabStore {
  private _certificateDetailsTabData: DTO_Certificate | undefined = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get certificateDetailsTabData() {
    return this._certificateDetailsTabData;
  }
  setCertificateDetailsTabData = (
    certificateDetailsTabData: DTO_Certificate | undefined
  ) => {
    runInAction(() => {
      this._certificateDetailsTabData = certificateDetailsTabData;
    });
  };

  get responseLoadError() {
    return this._responseLoadError;
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  loadCertificateDetailsTabById = async (certificateId: number) => {
    let errorResponse;
    this.setIsLoading(true);
    const certificate = await getCertificateDetailsTabById(certificateId);
    if (isSuccessResponse(certificate)) {
      this.setCertificateDetailsTabData(certificate?.data?.Certificate);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._certificateDetailsTabData = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
}

const certificateDetailsTabStoreContext = createContext(
  new CertificateDetailsTabStore()
);
export const useCertificateDetailsTabStore = () => {
  return useContext(certificateDetailsTabStoreContext);
};
