import { RatingAssessment } from "@app/products/property/assessments/[id]/components/child-screens/rating/components/assessment/_index";
import { CurrentLevies } from "@app/products/property/assessments/[id]/components/child-screens/rating/components/current-levies/_index";
import { CurrentValuation } from "@app/products/property/assessments/[id]/components/child-screens/rating/components/current-valuation/_index";
import { RatingMasterProperty } from "@app/products/property/assessments/[id]/components/child-screens/rating/components/master-property/_index";
import { useAssessmentRatingStore } from "@app/products/property/assessments/[id]/components/child-screens/rating/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { isFieldVisible } from "@app/products/property/util";
import { scrollToView } from "@common/utils/animation";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import { default as React, RefObject, useEffect } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
interface IRatingProps {
  ratingRef?: RefObject<any>;
}

export const Rating = observer(({ ratingRef }: IRatingProps) => {
  const { assessmentId } = useAssessmentStore();
  const {
    isIncludeHistorical,
    loadRating,
    rating,
    isLoading,
    setIsIncludeHistorical,
    assessment,
    resetStore,
  } = useAssessmentRatingStore();
  useEffect(() => {
    scrollToView(ratingRef);
    if (!assessmentId) return;
    loadRating(assessmentId, isIncludeHistorical);
    if (!assessmentId) return;
  }, [assessmentId, isIncludeHistorical, loadRating, ratingRef, resetStore]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (!isLoading && !rating) return <NoData vertical />;
  const handleIncludedHistorical = (event: CheckboxChangeEvent) => {
    setIsIncludeHistorical(event.value);
  };
  return (
    <div className="cc-property-assessment-rating" ref={ratingRef}>
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <Checkbox
            title="Include Historical"
            label="Include historical"
            className={"cc-checkbox"}
            disabled={isLoading}
            onChange={handleIncludedHistorical}
            checked={isIncludeHistorical}
          />
        </div>
        {isFieldVisible(assessment?.EnablePostponedLeviesVisibility) && (
          <div className="cc-grid-control-right">
            <Checkbox
              title="Enable Levies to be Postponed"
              label="Enable levies to be postponed"
              disabled
              className={"cc-checkbox-second"}
              checked={rating?.Assessment?.BeingPostponed ?? false}
            />
          </div>
        )}
      </div>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <CurrentLevies />
            <RatingAssessment />
            <CurrentValuation />
            {isFieldVisible(rating?.Assessment?.MasterPropertyVisibility) && (
              <RatingMasterProperty />
            )}
          </div>
        </div>
      )}
    </div>
  );
});
