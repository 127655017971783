import { useEffect } from "react";
import { useWindowSize } from "react-use";

const MIN_SCREEN_WIDTH = 375;
const MAX_SCREEN_WIDTH = 1024;

export function usePhoneOrTablet(callbackFunction: () => void) {
  const { width } = useWindowSize();
  useEffect(() => {
    if (
      width &&
      width >= MIN_SCREEN_WIDTH &&
      width < MAX_SCREEN_WIDTH &&
      typeof callbackFunction === "function"
    ) {
      callbackFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);
}
