import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { useAppealWithdrawnButtonStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-withdrawn/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AppealWithdrawnButton = observer(() => {
  const { isLoading } = useAppealWithdrawnButtonStore();
  const { ppr, onSubmit } = usePPRStore();
  const isVisible = useMemo(() => {
    return Application_Status.Appealed === ppr?.Status_ENUM;
  }, [ppr]);

  return isVisible ? (
    <CCNavButton
      isLoading={isLoading}
      disabled={isLoading}
      title="Appeal withdrawn"
      onClick={onSubmit}
      name={PPRSubmitActions.AppealWithdrawn}
    />
  ) : null;
});
