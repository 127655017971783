import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";
import { VO_Notice_Assessment_NoticeGroup } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";

export const getNoticeRunsNoticeGroups = async (
  notice_run_id: number | string,
  notice_group_id: number | string
): Promise<
  APIResponse<IODataResponse<VO_Notice_Assessment_NoticeGroup> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/odata/property/internal/noticerunassessmentnoticegroupregister/${notice_run_id}?$count=true&$filter=Notice_Group_Id eq ${notice_group_id}&$top=50&$skip=0`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
