import { mockSingleBuildingRegistersEnquiry } from "@app/products/building/registers/enquiries/[id]/mock";
import { IBuildingRegistersEnquiryFormData } from "@app/products/building/registers/enquiries/[id]/model";
import { IResponseData } from "@common/models/service";

export const loadBuildingRegistersEnquiryById = async (
  id?: string
): Promise<IBuildingRegistersEnquiryFormData> => {
  const response: IResponseData = JSON.parse(
    JSON.stringify(mockSingleBuildingRegistersEnquiry)
  );
  return response.data;
};
