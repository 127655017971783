import { eventEmitter } from "@/App";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessResponse } from "@common/apis/util";
import { postDeleteDynamicQuestionLists } from "@common/pages/settings/lookups/dynamic-lists/_id/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface DeleteDynamicQuestionButtonProps {
  disabled?: boolean;
}
export const DeleteDynamicQuestionButton = observer(
  ({ disabled }: DeleteDynamicQuestionButtonProps) => {
    const { gridSelectedRows, gridSelectedIds, clearSelectedItems } =
      useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleOnDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const response = await postDeleteDynamicQuestionLists(gridSelectedIds);
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        clearErrorNotification();
        setIsShowDialog(false);

        clearSelectedItems();
        pushNotification({
          title: `${pluralize(
            "Dynamic question lists",
            gridSelectedIds.length
          )} deleted successfully`,
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
      } else {
        pushNotification({
          title: `Delete ${pluralize(
            "Dynamic question lists",
            gridSelectedIds.length
          )} failed`,
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Delete"
          onClick={() => setIsShowDialog(true)}
          disabled={!gridSelectedRows?.length || disabled}
        />
        {isShowDialog && (
          <ConfirmDialog
            title={"Confirm Deletion"}
            isLoadingYes={isDeleting}
            subMessage={`Are you sure you want to delete ${
              gridSelectedIds.length === 1 ? "this item" : "these items"
            }?`}
            onClosePopup={() => {
              setIsShowDialog(false);
            }}
            onConfirmYes={handleOnDelete}
          />
        )}
      </>
    );
  }
);
