import { VO_ValuationAssessments } from "@app/products/property/supplementary-rates/valuations-by-group/components/detail/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_ValuationAssessments>();

export const colDetail: IColumnFields[] = [
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Rating_Period_Id"),
    title: "Rating Period Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("RatingPeriod_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Assessment_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Code_Valuation_1"),
    title: "Valuation Type",
  },
  {
    field: nameOf("Code_Valuation_2"),
    title: "Valuation Type",
  },
  {
    field: nameOf("Code_Valuation_3"),
    title: "Valuation Type",
  },
  {
    field: nameOf("Code_Valuation_4"),
    title: "Rateable UV",
  },
  {
    field: nameOf("Code_Valuation_5"),
    title: "Code_Valuation_5",
  },
  {
    field: nameOf("Code_Valuation_6"),
    title: "Code_Valuation_6",
  },
  {
    field: nameOf("Code_Valuation_7"),
    title: "Code_Valuation_7",
  },
  {
    field: nameOf("Code_Valuation_8"),
    title: "Code_Valuation_8",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Rateable UV",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_5"),
    title: "Valuation_5",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_6"),
    title: "Valuation_6",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_7"),
    title: "Valuation_7",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_8"),
    title: "Valuation_8",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOf("Assess_Valuation_Number"),
    title: "Assess Valuation Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AS_Is_Assessment_Editable"),
    title: "As Is Assessment Editable",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Valuation_Group_Id"),
    title: "Valuation Group Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Valuation_Group_Name"),
    title: "Valuation Group Name",
  },
];
