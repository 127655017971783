import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import {
  getAttachFiles,
  getDocumentsByIdAndRecordType,
} from "@app/core/communication/dialogs/components/form-elememts/attachment/api";
import { colAttachmentRecord } from "@app/core/communication/dialogs/components/form-elememts/attachment/config";
import {
  IAttachmentRecords,
  ICurrentAttachmentFromRecords,
} from "@app/core/communication/dialogs/components/form-elememts/attachment/model";
import { MESSAGE_TEMPLATE_STEP } from "@app/core/communication/dialogs/components/form-elememts/message-template/_index";
import { SVC_SelectedAttachment } from "@app/core/communication/dialogs/model";
import { Attachment, DOCUMENTSTATUS } from "@app/core/documents/model";
import { IResponseData } from "@common/models/service";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import Loading from "@components/loading/Loading";
import { FieldArray } from "@progress/kendo-react-form";
import {
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const ATTACHMENT_STEP = "Attachment";
const nameOfGridData = nameOfFactory<IAttachmentRecords>();
export const AttachmentsStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  nameOf,
  formRenderProps,
  options,
}: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffectOnce(() => {
    if (
      valueGetter(
        `${MESSAGE_TEMPLATE_STEP}.MessageTemplateDropdown.CommunicationTemplate_ID`
      )
    ) {
      setIsLoading(true);
      getAttachFiles(
        valueGetter(
          `${MESSAGE_TEMPLATE_STEP}.MessageTemplateDropdown.CommunicationTemplate_ID`
        )
      ).then((data: IResponseData) => {
        setIsLoading(false);
        if (data.data) {
          const defaultAttachment: any = [];
          const defaultAttachmentId: number[] = [];
          data.data.forEach(
            (item: ICurrentAttachmentFromRecords, index: number) => {
              defaultAttachment.push({
                name: item.Title ?? "",
                extension: item.Title.split(".").pop() ?? ".jpg",
                size: item.FileEntry.FileSize ?? 0,
                progress: 0,
                status: UploadFileStatus.Selected,
                uid: item.Attachment_ID ?? index,
              });
              defaultAttachmentId.push(item.Attachment_ID ?? index);
            }
          );
          // API has a temp bug: return duplicate of each item
          onChange(nameOf("AttachFromTemplate"), {
            value: defaultAttachment.filter(
              (value: any, index: number, self: any) =>
                index === self.findIndex((item: any) => item.uid === value.uid)
            ),
          });
          onChange(nameOf("ListOfTemplateAttachments"), {
            value: defaultAttachmentId.filter(
              (value: any, index: number, self: any) =>
                index === self.indexOf(value)
            ),
          });
        } else {
          onChange(nameOf("AttachFromTemplate"), { value: [] });
        }
      });
    }
    if (
      (options.RecordIds?.length === 1 || options.RecordIdAttachment) &&
      options.RecordTypeAttachment
    ) {
      getDocumentsByIdAndRecordType(
        options.RecordIdAttachment || options.RecordIds[0],
        options.RecordTypeAttachment,
        options.DocumentStatus
      ).then((response) => {
        onChange(nameOf("AttachmentFromCurrentRecord"), {
          value: response.data,
        });
      });
    }
  });
  const changeSelectedRecords = (value: IAttachmentRecords[]) => {
    onChange(nameOf("ListOfAttachmentsFromCurrent"), {
      value: value.map((attachment: IAttachmentRecords) => {
        return {
          AttachmentID: attachment.ID,
          FileNumber: attachment?.FileNumber,
          FileName: attachment?.FileName,
        };
      }),
    });
  };
  const changeAttachmentCount = (event: any) => {
    onChange(nameOf("AttachmentCount"), {
      value: event.newState.length,
    });
  };

  const handleAttachments = async (event: UploadOnAddEvent) => {
    changeAttachmentCount(event);
    let processFile = [];
    for (let index = 0; index < event.newState.length; index++) {
      const fileEntry = await convertToFileStreamEntry(event.newState[index]);
      const attachments = {
        FileEntry: fileEntry,
        Title: event.newState[index]?.name ?? "",
        Category_KWD: null,
        Attachment_ID: 0,
        FileNumber: "",
        TRIMUri: "",
        Status_ENUM: DOCUMENTSTATUS.Draft,
      } as Attachment;
      processFile.push(attachments);
    }
    onChange(nameOf("ListOfAttachments"), { value: processFile });
    onChange(nameOf("Attachment"), { value: event.newState });
  };

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            {"Attachment (" + (getFieldValue("AttachmentCount") ?? "0") + ")"}
          </label>
          <CCUploadFile
            autoUpload={false}
            batch={false}
            multiple
            files={getFieldValue("Attachment")}
            withCredentials={false}
            onAdd={handleAttachments}
            onRemove={handleAttachments}
            showActionButtons={false}
          />
        </div>
      </div>
      {(options.RecordIds?.length === 1 || options.RecordId) && (
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Attachment from current records</label>
            <CCGrid
              columnFields={colAttachmentRecord}
              selectedRows={(
                getFieldValue("ListOfAttachmentsFromCurrent") ?? []
              ).map((attachment: SVC_SelectedAttachment) => {
                return { ID: attachment.AttachmentID };
              })}
              primaryField={nameOfGridData("ID")}
              selectableMode="multiple"
              onSelectionChange={changeSelectedRecords}
              data={getFieldValue("AttachmentFromCurrentRecord") ?? []}
            />
          </div>
        </div>
      )}
      {(getFieldValue("AttachFromTemplate") ?? []).length > 0 && (
        <div className="cc-form-cols-1 cc-communication-template-attachment">
          <div className="cc-field">
            <label className="cc-label">
              Attachments from communication template
            </label>

            <CCUploadFile
              autoUpload={false}
              batch={false}
              defaultFiles={getFieldValue("AttachFromTemplate") ?? []}
              multiple
              files={getFieldValue("Attachment1")}
              withCredentials={false}
              showActionButtons={false}
              onAdd={(event: UploadOnAddEvent) => {
                onChange(nameOf("AttachFromTemplate"), {
                  value: event.newState,
                });
              }}
              onRemove={(event: UploadOnRemoveEvent) => {
                onChange(nameOf("AttachFromTemplate"), {
                  value: event.newState,
                });
                onChange(nameOf("ListOfTemplateAttachments"), {
                  value: event.newState.map((obj) => obj.uid),
                });
              }}
            />
          </div>
        </div>
      )}
    </section>
  );
};
