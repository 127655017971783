import { DTO_Entity } from "@app/products/property/titles/[id]/components/child-screens/owners/model";
import { useTitleOwnersStore } from "@app/products/property/titles/[id]/components/child-screens/owners/store";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
import { colFieldsTitleOwners } from "./config";

const TitleOwners = observer(() => {
  const {
    loadTitleOwners,
    titleOwners,
    isLoading,
    isIncludePastNames,
    setIncludePastNames,
    resetStore,
  } = useTitleOwnersStore();
  const { titleId } = useTitleStore();
  const nameOf = nameOfFactory<DTO_Entity>();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (!titleId) return;
    loadTitleOwners(titleId, isIncludePastNames);
  }, [titleId, isIncludePastNames, loadTitleOwners]);

  return (
    <div className="cc-title-owners">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">Associated contacts</label>
        </div>
        <div className="cc-grid-control-right">
          <Checkbox
            title="Include previous contacts"
            label="Include previous contacts"
            checked={isIncludePastNames}
            disabled={isLoading}
            onChange={(event: CheckboxChangeEvent) => {
              setIncludePastNames(event.value);
            }}
          />
        </div>
      </div>
      <CCGrid
        isLoading={isLoading}
        className="cc-ownership-transfer-grid"
        data={titleOwners ?? []}
        columnFields={colFieldsTitleOwners}
        primaryField={nameOf("Id")}
      />
    </div>
  );
});

export default TitleOwners;
