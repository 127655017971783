import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Entity_Id
    ? `- ${selectedRow.Entity_Id}`
    : "";
  return `Property - Contact ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Entity_Id,
    selectedRow.ENA_Name,
  ]);
};

export const getBookmarkListViewDetail = () => {
  return "Property - Contacts - List";
};
