import { DBRowStateWithoutArchive } from "@app/products/direct-debit/model";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const DBRowStateCustomCell = ({ dataItem }: GridCellProps) => {
  let value = "";
  const sysDBRowState =
    dataItem?.Sys_DBRowState_View_Filter ?? dataItem?.Sys_DBRowState;
  if (sysDBRowState !== null || sysDBRowState !== undefined)
    value = DBRowStateWithoutArchive[sysDBRowState];
  return <TextCell value={value || ""} />;
};
