import {
  ServiceStandard,
  ServiceStandardMapObj,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { ACCESSRIGHTS, RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

// Use this interface when implementing full-function
export interface IRecordsDetailsProps {
  formRenderProps: FormRenderProps;
}
export const RecordsDetails = observer(() => {
  const { settings } = useCommonCoreStore();
  const { accessRight } = useCRMSServiceStandardStore();
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );

  const isShowRecordsSharePoint = useMemo(
    () => recordsSystem === RECORDSSYSTEM.SharePoint,
    [recordsSystem]
  );

  const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        {isShowRecordsSharePoint && (
          <div className="cc-field">
            <CCLabel title="Records category" />
            <Field
              maxLength={50}
              readOnly={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Records_CategoryCode"
              )}`}
              component={CCInput}
            />
          </div>
        )}
      </div>
    </section>
  );
});
