import { ICategoryPropertyConfig } from "@app/products/property/system-admin/settings/_category/config";

export enum MODE_SETTING {
  SETTINGS_FOLDER = "SettingsFolder",
  SETTINGS_ITEM = "SettingsItem",
  NONE = "None",
}

export interface IStatePropertyCategory {
  stateData: ICategoryPropertyConfig;
  notificationContentSuccess: string;
}
