import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDeleteApplicationCategoryDialog {
  onClose: () => void;
  onSubmit: () => void;
}
export const DeleteApplicationCategoryDialog = observer(
  ({ onClose, onSubmit }: IDeleteApplicationCategoryDialog) => {
    return (
      <ConfirmDialog
        title="Confirm Delete"
        subMessage="Are you sure you want to delete the selected items?"
        onClosePopup={() => onClose()}
        onConfirmYes={onSubmit}
      />
    );
  }
);
