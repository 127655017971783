import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colInfringements } from "./config";
import { mockInfringements } from "./mock";

export const InfringementsGrid = () => {
  return (
    <>
      <label className="cc-control-item cc-label">Infringements</label>
      <CCGrid
        data={mockInfringements}
        columnFields={colInfringements}
        primaryField="ID"
      />
    </>
  );
};
