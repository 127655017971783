export const mockData = [
  {
    AssessmentNumber: 682488,
    PropertyAddress: "210 Boundary Rd, Cape Woolamai",
    LegalDescription: "Lot 14 SP3227",
    Owners: "A Smith",
    Reason: "Change of ownership",
    AssessmentId: 3876,
  },
];
