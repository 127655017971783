import { getRegisterMemos } from "@app/products/property/registers/[id]/components/child-screens/memos/api";
import { mMemo } from "@app/products/property/registers/[id]/components/child-screens/memos/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class RegisterMemoStore {
  private _memo?: mMemo[] = [];
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get memo() {
    return this._memo;
  }
  setMemo = (memo?: mMemo[]) => {
    runInAction(() => {
      this._memo = memo;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  loadMemo = async (registerId: number) => {
    this.setIsLoading(true);

    const memos = await getRegisterMemos();
    this.setMemo(memos?.Memos || []);

    this.setIsLoading(false);
  };
}

const registerMemoStoreContext = createContext(new RegisterMemoStore());
export const useRegisterMemoStore = () => {
  return useContext(registerMemoStoreContext);
};
