import {
  InspectionRegisterForm,
  PremiseRegisterForm,
} from "@app/products/hm/model";
import { nameOfFactory } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export interface IInspectionParentSectionProps {
  formRenderProps: FormRenderProps;
  premiseInfo?: PremiseRegisterForm;
}

export const InspectionParentSection = ({
  formRenderProps,
  premiseInfo,
}: IInspectionParentSectionProps) => {
  const getValue = formRenderProps.valueGetter;
  const nameOf = nameOfFactory<InspectionRegisterForm>();
  const tradingName =
    getValue(nameOf("TradingName")) ||
    `${premiseInfo?.TradingName} ${premiseInfo?.RegistrationNumber}`;
  const address =
    getValue(nameOf("Address")) ||
    `${premiseInfo?.SiteAddressStreetNo} ${premiseInfo?.SiteAddressStreetName} ${premiseInfo?.SiteAddressSuburb} ${premiseInfo?.SiteAddressState} ${premiseInfo?.SiteAddressPostcode}`;
  const type = getValue(nameOf("Type")) || `${premiseInfo?.PremisesTypeName}`;
  return (
    <>
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Trading name&nbsp;</label>
            <div className="cc-label-field">{tradingName}</div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Address</label>
            <div className="cc-label-value">{address}</div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Type</label>
            <div className="cc-label-value">{type}</div>
          </div>
        </div>
      </div>
      <hr className="cc-divider" />
    </>
  );
};
