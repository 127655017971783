import {
  DTO_DocumentType_LOVs,
  Svc_Attachment,
} from "@app/products/property/components/action-bar/button/add-attachment/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const saveAttachmentProperty = async (
  attachment: Svc_Attachment | any
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/attachment`,
      attachment
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCategories = async (
  recordType: RECORDTYPE,
  isMailMerge?: boolean
): Promise<APIResponse<DTO_DocumentType_LOVs | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_DocumentType_LOVs>(
      `/api/property/internal/attachment/document-type-lovs/${recordType}?isMailMerge=${
        isMailMerge ?? false
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCategoriesFormWorkflow = async (
  workflowType: WorkflowTypes,
  subType?: number
): Promise<APIResponse<DTO_DocumentType_LOVs | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_DocumentType_LOVs>(
      `api/property/internal/attachment/workflow-document-types?workflowType=${workflowType}&isMailMerge=false${
        subType ? "&workflowFunctionEnumValue=" + subType : ""
      }`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
