import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { AnimalsComponentSettingForm } from "@app/products/animals/system-admin/settings/_id/main/config";
import { mappingCorporateSettingAnimal } from "@app/products/animals/system-admin/settings/_id/main/util";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import { useSettingConfirmExitStore } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/store";
import { putSetting } from "@app/products/property/system-admin/settings/api";
import { CCSettingPanel } from "@app/products/property/system-admin/settings/components/setting-panel/_index";
import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import {
  DEFAULT_DYNAMIC_GRID,
  convertObjectToArray,
  processDataForm,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/util";
import {
  ICollapseState,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep, differenceWith, isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";

export const ExistedAnimalsSettingManage = observer(() => {
  const { dataSetting } = useAnimalsSettingStore();
  const { isEditing, isLoading, setIsEditing, setIsLoading } =
    useAnimalsSettingManageStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { setConfirmExit } = useSettingConfirmExitStore();

  const [data, setData] = useState<IData>();
  const [originData, setOriginData] = useState<IData>();
  const [collapseState, setCollapseState] = useState<ICollapseState>({
    1: true,
  });

  const handleSaveBasic = (values: { [name: string]: any }) => {
    const cloneOriginData: IData = { ...originData };
    const cloneData: IData = { ...values };

    const newCloneOriginData = convertObjectToArray(cloneOriginData);
    const newCloneData = convertObjectToArray(cloneData);

    const compareDifferentChanged = differenceWith(
      newCloneData,
      newCloneOriginData,
      isEqual
    );
    let differentGridDynamic = undefined;

    if (compareDifferentChanged.length) {
      let getListNameFileChanged = compareDifferentChanged.map(
        (item) => item.key
      );
      const settingFields = cloneDeep(dataSetting.SettingFields);
      //Process data dynamic grid
      if (getListNameFileChanged.includes(DEFAULT_DYNAMIC_GRID)) {
        const gridNewCloneData =
          newCloneData.find((item) => item.key === DEFAULT_DYNAMIC_GRID)
            ?.value ?? ([] as any);
        const gridNewCloneOriginData =
          newCloneOriginData.find((item) => item.key === DEFAULT_DYNAMIC_GRID)
            ?.value ?? ([] as any);
        differentGridDynamic = differenceWith(
          gridNewCloneData,
          gridNewCloneOriginData,
          isEqual
        );
        if (
          Array.isArray(differentGridDynamic) &&
          differentGridDynamic?.length
        ) {
          const fieldNameList = differentGridDynamic.map(
            (item) => item.FieldName
          );
          getListNameFileChanged = [
            ...getListNameFileChanged,
            ...fieldNameList,
          ];
        }
      }
      const resultSettingFields = settingFields.filter((item: SettingField) =>
        getListNameFileChanged.includes(item.FieldName)
      );
      resultSettingFields.forEach((field: SettingField) => {
        field.Value = values[field.FieldName];
        //Remove avoid security
        delete field.HelpText;
      });

      const payLoads = {
        Name: dataSetting.Name,
        Title: dataSetting.Title,
        ItemId: dataSetting.ItemId,
        Mode: dataSetting.Mode,
        Description: dataSetting.Description,
        ProductType_Enum: dataSetting.ProductType_Enum,
        SettingCategoryName: dataSetting.SettingCategoryName,
        UseIsActive: dataSetting.UseIsActive,
        IsEditable: dataSetting.IsEditable,
        SettingFields: resultSettingFields,
        EditGroups: dataSetting.EditGroups,
        ParentFolders: dataSetting.ParentFolders,
      };

      if (!payLoads) {
        pushNotification({
          title: `Some thing went wrong`,
          autoClose: false,
          type: "error",
        });
        return;
      }
      setIsLoading(true);
      putSetting(payLoads)
        .then((response: IResponseData) => {
          if (response.status === APIResponseStatus.SUCCESS) {
            setData(values);
            setOriginData(values);
            setIsEditing(false);
            setIsLoading(false);
            pushNotification({
              title: `Updated successfully`,
              type: "success",
            });
            eventEmitter.emit(CCJournalEventType.RefreshData);
          } else if (response.status === APIResponseStatus.BAD_REQUEST) {
            const res = response.data;
            const messages = res.Messages ? res.Messages : [];
            const typeMessage =
              res.MessageType === "Flash Information"
                ? "info"
                : res.MessageType.toLowerCase();
            const isClose =
              res.MessageType === "Flash Information" ? true : false;
            pushNotification({
              title: res.HeaderMessage,
              autoClose: isClose,
              description: messages,
              type: typeMessage,
            });
            setIsLoading(false);
            setData(originData);
          } else {
            pushNotification({
              title: `Error occurred`,
              autoClose: false,
              type: "error",
            });
            setIsLoading(false);
            setData(originData);
          }
        })
        .catch(() => {
          pushNotification({
            title: `Error occurred`,
            autoClose: false,
            type: "error",
          });
          setIsLoading(false);
          setData(originData);
        });
    }
  };

  const handleSubmit = async (values: {
    [name: string]: any;
  }): Promise<void> => {
    handleSaveBasic(values);
  };

  const nameGroup = useMemo(() => {
    return dataSetting?.EditGroups[0]?.Name ?? "";
  }, [dataSetting]);

  useEffect(() => {
    setConfirmExit(isEditing);
  }, [isEditing, setConfirmExit]);

  useEffect(() => {
    const getExistedSetting =
      mappingCorporateSettingAnimal[dataSetting?.Name] ?? []; // List field is manual integrate - dont show in dynamic grid
    const processData = processDataForm(
      dataSetting?.SettingFields,
      getExistedSetting
    );
    if (processData) {
      const cloneData = cloneDeep(processData);
      setData(cloneData);
      setOriginData(cloneData); // keep data in order to revert form data when cancel & use to compare when save
    }
    return () => {
      setIsEditing(false);
      setIsLoading(false);
    };
  }, [dataSetting, setIsEditing, setIsLoading]);

  return (
    <div className="cc-form cc-exist-animals-setting-manage-form">
      {data && (
        <Form
          onSubmit={handleSubmit}
          initialValues={data}
          key={JSON.stringify(data)}
          render={(formRenderProps: FormRenderProps) => {
            const { valid, modified, onFormReset, onSubmit } = formRenderProps;
            return (
              <FormElement>
                <CCSettingPanel
                  title={nameGroup}
                  isExpanded={collapseState[1]}
                  isBoldCollapse
                  classNameChildren="cc-manage-content-settings"
                  handleDivButton={() =>
                    setCollapseState({
                      1: !collapseState[1],
                    })
                  }
                  buttonGroupElement={
                    <>
                      {dataSetting?.IsEditable ? (
                        <div
                          className="cc-button-wrapper"
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                        >
                          <CCNavButton
                            className={`${
                              isEditing ? "cc-edit-hidden " : " "
                            } cc-manage-button`}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setIsEditing(true);
                              setCollapseState({
                                1: true,
                              });
                            }}
                            disabled={isLoading}
                            iconClass="fa fa-pencil"
                            title="Edit"
                            isLoading={isLoading}
                          />

                          {isEditing && (
                            <>
                              <CCNavButton
                                className={`cc-save-button${
                                  !valid || !modified
                                    ? " cc-button-disabled"
                                    : " cc-button-enabled"
                                }`}
                                disabled={!valid || !modified || isLoading}
                                id="btn-save"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  onSubmit(e);
                                  onFormReset();
                                }}
                                iconClass="fa fa-check-circle"
                                title="Save"
                                isLoading={isLoading}
                              />
                              <CCNavButton
                                className="cc-cancel-button"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  onFormReset();
                                  setIsEditing(false);
                                  setData(originData);
                                }}
                                iconClass="fa fa-times-circle"
                                title="Cancel"
                                disabled={isLoading}
                              />
                            </>
                          )}
                        </div>
                      ) : null}
                    </>
                  }
                >
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : (
                    <>
                      <CCAppNotification />
                      <div className="cc-animals-component-setting">
                        <AnimalsComponentSettingForm
                          formRenderProps={formRenderProps}
                          dataSetting={dataSetting}
                        />
                      </div>
                    </>
                  )}
                </CCSettingPanel>
              </FormElement>
            );
          }}
        />
      )}
    </div>
  );
});
