import { Journal } from "@app/core/journal/_index";
import { useAnimalsKennelStore } from "@app/products/animals/kennels/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const AnimalsKennelHistoryTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const { animalsKennel } = useAnimalsKennelStore();
  const { id } = useParams<{ id: string }>();
  const animalKennelId: number = id ? parseInt(id) : lastSelectedId;
  return (
    <Journal
      id={animalKennelId}
      triggersReload={animalsKennel}
      recordType={RECORDTYPE.Animals_Kennel}
    />
  );
});
