import { getColorStyleForCurrency } from "@components/reference-sidebar/util";
import React from "react";
import "./ProductReferenceHeading.scss";
interface IProductDetailHeading {
  title?: string;
  value?: string;
  customValue?: any;
  primary?: boolean;
  flexBetween?: boolean;
  format?: "currency";
  id?: string;
  style?:
    | ((value?: any) => React.CSSProperties | undefined)
    | React.CSSProperties;
}

export const ProductReferenceHeading = (props: IProductDetailHeading) => {
  let valueStyle: any =
    props?.style && typeof props.style === "function"
      ? props.style(props.value)
      : props.style;

  const getColorStyle = (type: string) => {
    //Add color based on value if it's currency
    if (props.format === "currency") {
      return getColorStyleForCurrency(props.value, type);
    }
    return "";
  };

  return (
    <>
      {!props.customValue ? (
        <div
          className={`cc-product-reference-heading ${
            props.primary ? "cc-heading-title-block" : "cc-category-block"
          }`}
        >
          <div
            className={`cc-heading-group ${
              props.flexBetween ? "cc-heading-group-between" : ""
            }`}
          >
            <span className="cc-title" id={props.id ?? ""}>
              {props.title}
            </span>
            <span
              className={`cc-value ${getColorStyle("cc-value-color")}`}
              style={valueStyle}
            >
              {props.value}
            </span>
          </div>
        </div>
      ) : (
        <>{props.customValue}</>
      )}
    </>
  );
};
