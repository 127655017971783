import {
  DTO_Workflow_JournalAssessmentReverseTransaction,
  RequestReverseAssessmentTransactionObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/reverse-transaction/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessReverseTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_JournalAssessmentReverseTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/reverseassessmenttransaction/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialReverseTransaction =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAssessmentReverseTransaction>
    ]
  | APIResponse<
      DTO_Journal_LOVs | DTO_Workflow_JournalAssessmentReverseTransaction
    >
  | undefined;
export const getInitialReverseTransaction = async (
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  transactionIds?: number[] | undefined
): Promise<IGetInitialReverseTransaction> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      isFromActionList
        ? getWorkflowReverseTransaction(workflowDraftId)
        : getNewWorkflowReverseTransaction({
            TransactionIds: transactionIds,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowReverseTransaction = async (
  requestObj: RequestReverseAssessmentTransactionObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalAssessmentReverseTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/reverseassessmenttransaction/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowReverseTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAssessmentReverseTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/reverseassessmenttransaction/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
