import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class RaiseDeferredDutyInterestDialogStore {
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _isLoadingApprove: boolean = false;
  private _deferredDutyLOVs?: any = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };
  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };
  get isLoadingApprove(): boolean {
    return this._isLoadingApprove;
  }
  setIsLoadingApprove = (isLoadingApprove: boolean) => {
    runInAction(() => {
      this._isLoadingApprove = isLoadingApprove;
    });
  };

  get deferredDutyLOVs() {
    return this._deferredDutyLOVs;
  }
  setDeferredDutyLOVs = (debtRecoveryPropertyLOVs?: any) => {
    runInAction(() => {
      this._deferredDutyLOVs = debtRecoveryPropertyLOVs;
    });
  };
}

const raiseDeferredDutyInterestDialogStoreContext = createContext(
  new RaiseDeferredDutyInterestDialogStore()
);
export const useRaiseDeferredDutyInterestDialogStore = () => {
  return useContext(raiseDeferredDutyInterestDialogStoreContext);
};
