import { DATETIME_FORMAT } from "@common/constants/common-format";
import { ICoreHelpDocument } from "@common/models/coreHelpDocuments";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTE } from "./_id/constant";

const nameOf = nameOfFactory<ICoreHelpDocument>()
export const colLookupsHelpDocuments: IColumnFields[] = [
  {
    field: nameOf("Title"),
    title: "Title",
    locked: true,
    linkTo: (dataItem: ICoreHelpDocument) =>
      `${SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTE}/${dataItem?.Attachment_ID}`,
  },
  {
    field: nameOf("FileName"),
    title: "Name",
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("RecordedDate"),
    title: "Uploaded On",
    format: DATETIME_FORMAT.DATETIME,
  },
];
