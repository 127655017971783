import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";

interface IApplicationSettingSwitchProps {
  isdisable?: boolean;
  enumParam: ECorporateSettingsField;
  formRenderProps?: FormRenderProps;
  handleOnChange?: (event: SwitchChangeEvent) => void;
}

export const GlobalSettingSwitch = (props: IApplicationSettingSwitchProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const {
    isdisable = false,
    enumParam,
    handleOnChange,
    formRenderProps,
  } = props;

  if (!configData) return <></>;
  return configData[enumParam.toString()] ? (
    <div className="cc-field">
      <CCLabel
        title={configData[enumParam.toString()].Title ?? ""}
        isMandatory={configData[enumParam.toString()].IsMandatory}
      />
      <Field
        name={configData[enumParam.toString()].FieldName}
        component={CCSwitch}
        disabled={!isEditing || isdisable}
        checked={formRenderProps?.valueGetter(
          configData[enumParam.toString()].FieldName
        )}
        onChange={
          handleOnChange
            ? handleOnChange
            : (e: SwitchChangeEvent) => {
                formRenderProps?.onChange(enumParam.toString(), {
                  value: e.value,
                });
              }
        }
        validator={
          configData[enumParam.toString()].IsMandatory
            ? requiredValidator
            : undefined
        }
      />
    </div>
  ) : null;
};
