import {
  BCAClassFields,
  BCAVersionFields,
  buildingOfficerFields,
  inspectionsRequiredFields,
  natureOfWorkFields,
  permitTypeFields,
  useOfBuildingFields,
} from "@app/products/building/[id]/components/child-screens/general/components/form-element/config";
import {
  mockDropdownCertRequiredeData,
  mockDropdownInsuranceCompanyData,
  mockDropdownLandOwnershipData,
  mockDropdownLandTypeData,
  mockDropdownLevyPayerData,
  mockParentPage,
} from "@app/products/building/[id]/components/child-screens/general/components/form-element/mock";
import { IBuilding } from "@app/products/building/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<IBuilding>();

export interface IBuildingFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingFormElement = observer(
  ({ formRenderProps }: IBuildingFormElementProps) => {
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;

      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const getValue = formRenderProps.valueGetter;

    return (
      <FormElement onSubmit={formRenderProps.onSubmit}>
        {showAddressPopup && (
          <RegisterAddressPopup
            onClosePopup={() => setShowAddressPopup(false)}
          />
        )}

        {/* Reference by a label in ActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Applicant
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`, {
                    mockParentPage,
                    recordType: "11000",
                  });
                }}
                autoComplete="off"
                name={nameOf("Applicant")}
                placeholder={"Applicant"}
                readOnly
                component={InputPicker}
                validator={validateRequired}
              />
            </div>
            <CCValueField
              label="Address"
              value={getValue(nameOf("Applicant_Address"))}
            />
            <CCValueField
              label="Contact"
              value={getValue(nameOf("Applicant_Contact"))}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Builder</label>
              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`, {
                    recordType: "11000",
                  });
                }}
                autoComplete="off"
                name={nameOf("Builder")}
                placeholder={"Builder"}
                readOnly
                component={InputPicker}
              />
            </div>
            <CCValueField
              label="Address"
              value={getValue(nameOf("Builder_Address"))}
            />
            <CCValueField
              label="Contact"
              value={getValue(nameOf("Builder_Contact"))}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Preferred contact</label>
              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`, {
                    recordType: "11000",
                  });
                }}
                autoComplete="off"
                name={nameOf("PreferredContact")}
                placeholder={"Preferred contact"}
                readOnly
                component={InputPicker}
              />
            </div>
            <CCValueField
              label="Postal address"
              value={getValue(nameOf("PreferredContact_PostalAddress"))}
            />
            <CCValueField
              label="Contact"
              value={getValue(nameOf("PreferredContact_Contact"))}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Levy payer</label>
              <Field
                name={nameOf("LevyPayer")}
                dataItemKey="Key"
                textField="Value"
                data={mockDropdownLevyPayerData}
                value={getDropdownValue(
                  getValue(nameOf("LevyPayer")),
                  mockDropdownLevyPayerData
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  formRenderProps.onChange(nameOf("LevyPayer"), {
                    value: event.target.value?.Key,
                  });
                  formRenderProps.onChange(nameOf("LevyPayer_OwnerBuilder"), {
                    value: getValue(nameOf("Builder_Address")),
                  });
                  formRenderProps.onChange(nameOf("LevyPayer_OwnerBuilderNo"), {
                    value: getValue(nameOf("Builder_Contact")),
                  });
                }}
                component={CCSearchComboBox}
              />
            </div>
            <CCValueField
              label="Owner/builder"
              value={getValue(nameOf("LevyPayer_OwnerBuilder"))}
            />
            <CCValueField
              label="Owner builder number"
              value={getValue(nameOf("LevyPayer_OwnerBuilderNo"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Site address
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                onClick={() => setShowAddressPopup(true)}
                name={nameOf("SiteAddress")}
                placeholder={"Site address"}
                readOnly
                validator={validateRequired}
                component={InputPicker}
              />
            </div>
            <CCValueField
              label="Owner"
              value={getValue(nameOf("SiteAddress_Owner"))}
            />
            <CCValueField
              label="Details"
              value={getValue(nameOf("SiteAddress_Details"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Permit type
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                component={InputPicker}
                {...permitTypeFields(formRenderProps)}
                name={nameOf("PermitType")}
                placeholder={"Permit type"}
                validator={validateRequired}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">BCA version</label>
              <Field
                component={InputPicker}
                {...BCAVersionFields(formRenderProps)}
                name={nameOf("BCAVersion")}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Nature of work</label>
              <Field
                component={InputPicker}
                {...natureOfWorkFields(formRenderProps)}
                name={nameOf("NatureOfWork")}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Use of building</label>
              <Field
                component={InputPicker}
                {...useOfBuildingFields(formRenderProps)}
                name={nameOf("UseOfBuilding")}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">BCA class</label>
              <Field
                component={InputPicker}
                {...BCAClassFields(formRenderProps)}
                name={nameOf("BCAClass")}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Private certification</label>
              <Field
                name={nameOf("PrivateCertification")}
                defaultChecked
                value={getValue(nameOf("PrivateCertification"))}
                component={CCSwitch}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Surveyor
                <CCTooltip type="validator" position="right" />
              </label>

              <Field
                onClick={() => {
                  history.push(`/managecontactrelationship/${"123"}`, {
                    recordType: "11000",
                  });
                }}
                autoComplete="off"
                name={nameOf("Surveyor")}
                placeholder={"Surveyor"}
                readOnly
                component={InputPicker}
                validator={validateRequired}
              />
            </div>
            <CCValueField
              label="Surveyor number"
              value={getValue(nameOf("Surveyor_Number"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-field">
            <label className="cc-label">Development description</label>
            <Field
              name={getValue(nameOf("DevDescription"))}
              placeholder="Development description"
              rows={8}
              value={getValue(nameOf("DevDescription"))}
              component={CCTextArea}
            />
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Cost of works
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={nameOf("CostOfWorks")}
                isMandatory
                component={CCCurrencyInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Land type</label>
              <Field
                name={nameOf("LandType")}
                dataItemKey="Key"
                textField="Value"
                data={mockDropdownLandTypeData}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Land ownership</label>
              <Field
                name={nameOf("LandOwnership")}
                dataItemKey="Key"
                textField="Value"
                data={mockDropdownLandOwnershipData}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Building officer</label>
              <Field
                component={InputPicker}
                {...buildingOfficerFields(formRenderProps)}
                name={nameOf("BuildingOfficer")}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Certificate required</label>
              <Field
                name={nameOf("CertRequired")}
                dataItemKey="Key"
                textField="Value"
                data={mockDropdownCertRequiredeData}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Stage number</label>
              <Field
                name={nameOf("StageNo")}
                placeholder={"Stage number"}
                component={CCInput}
              />
            </div>
            <CCValueField
              label="Permit number"
              value={getValue(nameOf("PermitNo"))}
            />

            <div className="cc-field">
              <label className="cc-label">Certificate number</label>
              <Field
                name={nameOf("CertificateNo")}
                placeholder={"Certificate number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Insurance company</label>
              <Field
                name={nameOf("InsuranceCompany")}
                dataItemKey="Key"
                textField="Value"
                data={mockDropdownInsuranceCompanyData}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Reference number</label>
              <Field
                name={nameOf("ReferenceNo")}
                placeholder={"Reference number"}
                component={CCInput}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={nameOf("FileNumber")}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              label="OS fees"
              value={getValue(nameOf("OSFees"))}
              style={formatOSFees(getValue(nameOf("OSFees")))}
              format={CURRENCY_FORMAT.CURRENCY1}
            />
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={nameOf("DebtorNumber")}
                placeholder={"Debtor number"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">CIL required</label>
              <Field
                name={nameOf("CILRequired")}
                defaultChecked={getValue(nameOf("CILRequired"))}
                component={CCSwitch}
              />
            </div>
            {getValue(nameOf("CILRequired")) && (
              <>
                <CCValueField
                  label="CIL fee type"
                  value={getValue(nameOf("CILFeeType"))}
                />
                <CCValueField
                  label="CIL fee"
                  value={getValue(nameOf("CILFee"))}
                />
              </>
            )}
          </div>
          {getValue(nameOf("CILRequired")) && (
            <CCValueField
              label="Receipt number"
              value={getValue(nameOf("ReceiptNumber"))}
            />
          )}
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Inspections required</label>
              <Field
                component={InputPicker}
                {...inspectionsRequiredFields(formRenderProps)}
                name={nameOf("InspectionsRequired")}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Taswater exempt</label>
              <Field
                name={nameOf("TaswaterExempt")}
                defaultChecked={getValue(nameOf("TaswaterExempt"))}
                component={CCSwitch}
              />
            </div>
            {!getValue(nameOf("TaswaterExempt")) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">
                    Certificate of certifiable number
                  </label>
                  <Field
                    name={"Cert.ofCertifiableNo"}
                    component={CCInput}
                    placeholder={"Certificate of certifiable number"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Taswater certificate date</label>
                  <Field
                    name={"TaswaterCertDate"}
                    component={CCInput}
                    placeholder={"Taswater certificate date"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Fees paid</label>
              <Field
                name={nameOf("FeesPaid")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
