import { isNil } from "lodash";

export const removeWrongDateFromAndTo = (
  arr: any[],
  fromField: string,
  toField: string
) => {
  const newArr =
    arr?.map((item: any) => {
      if (
        !isNil(item?.[fromField]) &&
        !isNil(item?.[toField]) &&
        item?.[fromField] > item?.[toField]
      ) {
        return {
          ...item,
          [fromField]: null,
          [toField]: null,
        };
      }
      return item;
    }) ?? [];
  return newArr;
};
