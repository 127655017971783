import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import {
  validateNotNegativeNumber,
  validateRequired,
} from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";
import { columnArrangementCalculatedSchedule } from "./config";
import { initialForm, listFrequency, mockNewArrangements } from "./mock";

interface INewArrangementDialog {
  onClose: () => void;
  assessmentInfo?: any;
}

export const NewArrangementDialog = (props: INewArrangementDialog) => {
  const timeout = useRef<any>();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<any | null>(null);
  useEffect(() => {
    if (props.assessmentInfo) {
      setInitialValues({
        ...initialForm,
        Assessment: {
          Assessment: props.assessmentInfo.Assess_NumberX
            ? props.assessmentInfo.Assess_NumberX +
              " ‑ " +
              props.assessmentInfo.Assess_Property_Address
            : "",
        },
        AssessmentNumber: props.assessmentInfo.Assess_NumberX || 0,
        Applicant: props.assessmentInfo.Owners_Name || "",
      });
    } else {
      setInitialValues(initialForm);
    }
  }, [props.assessmentInfo]);

  const filterData = (filter: FilterDescriptor) => {
    const data = mockNewArrangements.slice();
    return filterBy(data, filter);
  };
  const handleSearchAssessment = (
    event: ComboBoxFilterChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    const { onChange } = formRenderProps;
    if (event.filter.value.length > 3) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onChange("_option.Assessment.Data", {
          value: filterData(event.filter),
        });
        onChange("_option.Assessment.Loading", {
          value: false,
        });
      }, 1000);
      onChange("_option.Assessment.Loading", {
        value: true,
      });
    } else {
      onChange("_option.Assessment.Data", {
        value: [],
      });
    }
  };
  const handleDateChange = (
    event: DatePickerChangeEvent | DateTimePickerChangeEvent,
    fieldName: string
  ) => {
    if (event.value) {
      setInitialValues({
        ...initialValues,
        [fieldName]: event.value,
      });
    } else {
      setInitialValues({
        ...initialValues,
        [fieldName]: new Date(),
      });
    }
  };

  return (
    <CCDialog
      titleHeader={"Arrangement"}
      onClose={props.onClose}
      maxHeight="75%"
      bodyElement={
        <div className="cc-new-arrangement cc-form">
          <Form
            initialValues={initialValues}
            key={JSON.stringify(initialValues)}
            render={(formRenderProps: FormRenderProps) => {
              const { valueGetter, onChange } = formRenderProps;
              return (
                <FormElement className="cc-field-group">
                  <div className="cc-form-cols-2">
                    <div className="cc-form-cols-1">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Assessment</label>
                          <Field
                            name="Assessment"
                            filterable
                            validator={validateRequired}
                            textField="Assessment"
                            onFilterChange={(e: ComboBoxFilterChangeEvent) => {
                              handleSearchAssessment(e, formRenderProps);
                            }}
                            data={valueGetter("_option.Assessment.Data")}
                            loading={valueGetter("_option.Assessment.Loading")}
                            onChange={(event: ComboBoxChangeEvent) => {
                              setInitialValues(
                                event.value
                                  ? {
                                      ...initialValues,
                                      ...event.value,
                                      Assessment: event.value,
                                      PeriodicAmountStartOn: new Date(
                                        event.value.PeriodicAmountStartOn
                                      ),
                                      InitialPaymentOn: new Date(
                                        event.value.InitialPaymentOn
                                      ),
                                      ArrangementDate: new Date(
                                        event.value.ArrangementDate
                                      ),
                                    }
                                  : {
                                      ...initialForm,
                                    }
                              );
                            }}
                            placeholder="Enter assessment number"
                            component={CCComboBox}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Arrangement date</label>
                          <Field
                            name="ArrangementDate"
                            component={CCDateTimePicker}
                            validator={validateRequired}
                            onChange={(event: DateTimePickerChangeEvent) =>
                              handleDateChange(event, "ArrangementDate")
                            }
                            format={DATETIME_FORMAT.DATETIME_CONTROL}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Amount of initial debt
                          </label>
                          <Field
                            name="AmountOfInitialDebt"
                            disabled
                            component={CCCurrencyInput}
                            validator={validateNotNegativeNumber}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Applicant</label>
                          <Field name="Applicant" component={CCInput} />
                        </div>
                      </div>
                      <label className="cc-label">Arrangement terms</label>
                      <div className="cc-field-group cc-custom-sub-panel-bar">
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">Frequency</label>
                            <Field
                              name="Frequency"
                              component={CCSearchComboBox}
                              validator={validateRequired}
                              data={listFrequency}
                              textField="Name"
                              dataItemKey="Code"
                              value={getDropdownValue(
                                valueGetter("Frequency"),
                                listFrequency,
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange("Frequency", {
                                  value: event.value?.Code ?? null,
                                });
                              }}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Number of instalment
                            </label>
                            <Field
                              name="NoOfInstalment"
                              min={1}
                              validator={validateRequired}
                              component={NumericTextBox}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              Initial payment on
                            </label>
                            <Field
                              name="InitialPaymentOn"
                              component={CCDatePicker}
                              onChange={(event: DatePickerChangeEvent) =>
                                handleDateChange(event, "InitialPaymentOn")
                              }
                              validator={validateRequired}
                              format={DATE_FORMAT.DATE_CONTROL}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Initial payment of
                            </label>
                            <Field
                              name="InitialPaymentOf"
                              component={CCCurrencyInput}
                              min={0}
                              validator={validateNotNegativeNumber}
                            />
                          </div>
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Periodic amount start on
                              </label>
                              <Field
                                name="PeriodicAmountStartOn"
                                component={CCDatePicker}
                                validator={validateRequired}
                                format={DATE_FORMAT.DATE_CONTROL}
                                onChange={(event: DatePickerChangeEvent) =>
                                  handleDateChange(
                                    event,
                                    "PeriodicAmountStartOn"
                                  )
                                }
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Periodic amount start of
                              </label>
                              <Field
                                min={0}
                                validator={validateNotNegativeNumber}
                                name="PeriodicAmountStartOf"
                                component={CCCurrencyInput}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Comments</label>
                          <Field
                            name="Comments"
                            component={CCTextArea}
                            rows={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Calculated schedule</label>
                        <CCGrid
                          className="cc-new-arrangement-grid"
                          columnFields={columnArrangementCalculatedSchedule}
                          data={valueGetter("CalculatedSchedule") ?? []}
                          itemPerPage={10}
                          primaryField="InstalmentNumber"
                        />
                      </div>
                    </div>
                  </div>
                </FormElement>
              );
            }}
          />
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={() => {
              history.push(
                `${PROPERTY_ASSESSMENT_ROUTE}/` + initialValues.AssessmentNumber
              );
              props.onClose();
            }}
          >
            OK
          </Button>
        </div>
      }
    />
  );
};
