import { BuildingUrl } from "@app/products/building/model";
import { OfficerColumns } from "@app/products/waste-water/config";
import { FormRenderProps } from "@progress/kendo-react-form";

export const officerFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Officer",
  readOnly: true,
  placeholder: "Officer",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Officer_Field",
  value: formRenderProps.valueGetter("Officer"),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange("Officer", {
      value: event.detail.Position,
    });
  },
});

export const appNoFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Application Number",
  readOnly: true,
  placeholder: "Application Number",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "AppNo_Field",
  value: formRenderProps.valueGetter("AppNo"),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange("AppNo", {
      value: event.detail.ID,
    });
  },
});

export const regulationsFields = (formRenderProps: FormRenderProps) => ({
  isMultiSelect: true,
  titleHeader: "Pick Regulation",
  placeholder: "Regulations",
  tableTypes: OfficerColumns,
  url: BuildingUrl.GET_BUILDING_ISSUING_OFFICERS,
  fieldName: "Regulation_Field",
  value: formRenderProps.valueGetter("Regulation_Field")?.join(", "),
  onChange: (event: CustomEvent) => {
    if (event.target !== null) {
      formRenderProps.onChange("Regulation_Field", {
        value: event.detail?.value,
      });
    } else {
      formRenderProps.onChange("Regulation_Field", {
        value: event.detail.map((cate: any) => cate.Name),
      });
    }
  },
});
