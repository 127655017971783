export const propertiesRouteStateGlobalSettings = [
  {
    name: "Properties_GeneralPropertySettings",
    component: require("./forms/general-property-settings").default,
  },
  {
    name: "Properties_PropertyAPIIntegrationSettings",
    component: require("./forms/property-api-integration-settings").default,
  },
  {
    name: "Properties_PropertyIntegrationSettings",
    component: require("./forms/property-integration-settings").default,
  },
  {
    name: "Properties_CouncilPropertySyncAgentSettings",
    component: require("./forms/council-property-sync-agent-settings").default,
  },
  {
    name: "Properties_PropertyDataSyncSettings",
    component: require("./forms/property-data-sync-settings").default,
  },
  {
    name: "Properties_PropertyDialog",
    component: require("./forms/property-dialog").default,
  },
  {
    name: "Properties_StreetSelectionDialog",
    component: require("./forms/street-selection-dialog").default,
  },
  {
    name: "Properties_GISSettings",
    component: require("./forms/gis-settings").default,
  },
  {
    name: "Properties_NearMapSettings",
    component: require("./forms/near-map-settings").default,
  },
  {
    name: "Properties_AddressValidationSettings",
    component: require("./forms/address-validation-settings").default,
  },
  {
    name: "Properties_GoogleMapsSettings",
    component: require("./forms/google-maps-settings").default,
  },
  {
    name: "Properties_ExternalPropertyAlerts",
    component: require("./forms/external-property-alerts").default,
  },
];
