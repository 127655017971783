import { DecisionDetailsDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/decision-details/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const DecisionDetailsButton = observer(() => {
  const [showDecisionDetailsDialog, setShowDecisionDetailsDialog] =
    useState(false);
  const { ppr } = usePPRStore();
  const isVisible = useMemo(() => {
    return (
      ppr?.Status_ENUM === Application_Status.Complete && ppr.Flag_PlansToComply
    );
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title={"Decision Details"}
        onClick={() => {
          setShowDecisionDetailsDialog(true);
        }}
      />
      {showDecisionDetailsDialog && (
        <DecisionDetailsDialog
          onClosePopup={() => setShowDecisionDetailsDialog(false)}
        />
      )}
    </>
  ) : null;
});
