import { loadManualMeterReading } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/api";
import { FormManualReading } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/components/form/_index";
import { mockReadingStatus } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/mock";
import { IManualMeterReading } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/model";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IManualReadingsDialogProps {
  onClose: () => void;
  onSubmitManualReadings: () => void;
  meterInfo: IMeterFormGeneral | undefined;
}

export const ManualReadingsDialog = ({
  onClose,
  onSubmitManualReadings,
  meterInfo,
}: IManualReadingsDialogProps) => {
  const [meterInfoExtend, setMeterInfoExtend] = useState<IManualMeterReading>();
  const [isLoading, setIsLoading] = useState(false);

  const { metersSelected } = useRegisterMetersStore();
  const { register } = useRegisterStore();
  useEffectOnce(() => {
    setIsLoading(true);
    loadManualMeterReading().then((data) => {
      if (meterInfo?.MeterNumber && meterInfo?.Id) {
        setMeterInfoExtend({
          ...data,
          MeterNumber: meterInfo?.MeterNumber,
          Id: metersSelected[0].MeterId ?? 0,
          UnitOfMeasure: meterInfo.UnitOfMeasure ?? "",
          AccountName: register?.AccountName ?? "",
          PropertyAddress: register?.PropertyAddress ?? "",
          ReadingStatus: mockReadingStatus.find(
            (item) => item.Value === register?.Status
          ) || { Key: 3, Value: "Read" },
          PreviousReadingDate: metersSelected[0].LastRead,
          PreviousReading: meterInfo.LastReading ?? 0,
        });

        setIsLoading(false);
      }
    });
  });

  return (
    <FormManualReading
      isLoading={isLoading}
      meterInfoExtend={meterInfoExtend}
      onClose={onClose}
      onSubmit={onSubmitManualReadings}
      title={"Manual Meter Readings"}
    />
  );
};
