import { IAmendApplication } from "@app/products/town-planning/ppr/components/action-bar/dialogs/amend-application/model";
import { putAmendApplication } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-application/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AmendApplicationButtonStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  private _isShowAmendApplicationDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  get isShowAmendApplicationDialog() {
    return toJS(this._isShowAmendApplicationDialog);
  }
  setIsShowAmendApplicationDialog = (isShowAmendApplicationDialog: boolean) => {
    runInAction(() => {
      this._isShowAmendApplicationDialog = isShowAmendApplicationDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoading = false;
    });
  };

  handleSubmitAmend = async (data: IAmendApplication, pprId?: number) => {
    if (pprId) {
      this.setIsLoading(true);
      const response = await putAmendApplication(pprId, data?.PlansSubmitted);
      this.setIsShowAmendApplicationDialog(false);
      this.setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        appNotificationStore.clearNotifications();
        PPRStoreInstance.setPPRWithLoading(response.data.Application);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Record saved successfully",
        });
        if (
          response.data?.Notifications &&
          response.data?.Notifications.length > 0
        ) {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Amend application",
            type: "warning",
            description: response.data?.Notifications,
          });
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Amend application failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };
}
export const amendApplicationButtonStore = new AmendApplicationButtonStore();
const amendApplicationButtonStoreContext = createContext(
  amendApplicationButtonStore
);
export const useAmendApplicationButtonStore = () => {
  return useContext(amendApplicationButtonStoreContext);
};
