import { ContactDetailComponent } from "@app/products/property/assessments/[id]/components/child-screens/contacts/components/contact-detail/_index";
import { colContacts } from "@app/products/property/assessments/[id]/components/child-screens/contacts/config";
import {
  DTO_AssessmentEntity,
  eVisibility,
} from "@app/products/property/assessments/[id]/components/child-screens/contacts/model";
import { useAssessmentsContactsStore } from "@app/products/property/assessments/[id]/components/child-screens/contacts/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import {
  IOwnerAndRatepayer,
  OwnerAndRatepayer,
} from "@app/products/property/components/owner-ratepayer/_index";
import { isFieldVisible } from "@app/products/property/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CommunityProperty } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Form, FormElement } from "@progress/kendo-react-form";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<DTO_AssessmentEntity>();
export const Contacts = observer(() => {
  const {
    contacts,
    isLoading,
    isDetailLoading,
    isIncludePastNames,
    resetStore,
    loadContacts,
    setIsIncludePastNames,
  } = useAssessmentsContactsStore();

  const { assessmentId } = useAssessmentStore();
  const { isLLS } = CommunityProperty.getFlagOfStates();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (!assessmentId) return;
    loadContacts(assessmentId, isIncludePastNames);
  }, [assessmentId, loadContacts, isIncludePastNames, resetStore]);

  const handleOnChangeIncludePastNames = (event: CheckboxChangeEvent) => {
    setIsIncludePastNames(event.value);
  };

  const propsOwnerAndRatepayer: IOwnerAndRatepayer = {
    data: {
      visibilityOwner: contacts?.OwnersGroupBoxVisibility,
      ownerValue: contacts?.Owners?.NameAndAddress,
      ratepayerValue: contacts?.Ratepayer?.NameAndAddress,
      options: {
        isLLS,
      },
    },
  };

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!contacts) return <NoData vertical />;
  return (
    <Form
      render={() => (
        <FormElement className="cc-contacts cc-form">
          <section className="cc-field-group">
            <div className="cc-form-cols-2">
              <OwnerAndRatepayer {...propsOwnerAndRatepayer} readOnly />
              {isFieldVisible(contacts.DuplicateRatepayerVisibility) ? (
                <div className="cc-field">
                  <label className="cc-label">Duplicate ratepayer</label>
                  <CCTextArea
                    rows={3}
                    className="cc-form-control"
                    value={contacts.DuplicateRatepayer.NameAndAddress}
                  />
                </div>
              ) : null}
              {isFieldVisible(contacts.UtilityAgentVisibility) ? (
                <div className="cc-field">
                  <label className="cc-label">Utility agent</label>
                  <CCTextArea
                    rows={3}
                    className="cc-form-control"
                    value={contacts.UtilityAgent.NameAndAddress}
                  />
                </div>
              ) : null}
            </div>
            {isFieldVisible(contacts.LeaseVisibility) ? (
              <div className="cc-form-cols-2">
                {isFieldVisible(contacts.LeaseReferenceVisibility) ? (
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">Lease reference</label>
                    <CCTextArea
                      rows={3}
                      readOnly
                      className="cc-form-control"
                      value={contacts.Lease.Reference}
                    />
                  </div>
                ) : null}
                {isFieldVisible(contacts.LeaseTypeVisibility) ? (
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">Lease type</label>
                    <Input
                      readOnly
                      className="cc-form-control"
                      value={contacts.Lease.LeaseType}
                    />
                  </div>
                ) : null}
                {/* LeaseDate is a string that can't use isFieldVisible */}
                {contacts.Lease.LeaseDate === eVisibility.Visible ? (
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">Lease date</label>
                    <DatePicker
                      disabled
                      format={DATETIME_FORMAT.DATETIME_CONTROL}
                      className="cc-form-control"
                      value={new Date(contacts.Lease.LeaseDate)}
                    />
                  </div>
                ) : null}
                {isFieldVisible(contacts.LeaseMailboxVisibility) ? (
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">Lease mailbox</label>
                    <CCTextArea
                      rows={3}
                      readOnly
                      className="cc-form-control"
                      value={contacts.Lease.MailboxEmailAddress}
                    />
                  </div>
                ) : null}
                {isFieldVisible(contacts.LeaseConditionsVisibility) ? (
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">Lease conditions</label>
                    <CCTextArea
                      rows={3}
                      readOnly
                      className="cc-form-control"
                      value={contacts.Lease.Conditions}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <div className="cc-grid-control-between">
                  <div className="cc-grid-control-left">
                    <label className="cc-label">Details</label>
                  </div>
                  <div className="cc-grid-control-right">
                    <Checkbox
                      title="Include previous contacts"
                      label="Include previous contacts"
                      disabled={isDetailLoading}
                      checked={isIncludePastNames}
                      onChange={handleOnChangeIncludePastNames}
                    />
                  </div>
                </div>
                <CCGrid
                  isLoading={isDetailLoading}
                  className="cc-transactions-grid"
                  data={contacts?.Entities ?? []}
                  columnFields={colContacts}
                  primaryField={nameOf("Contact_Name_Address_Id")}
                  detail={ContactDetailComponent}
                  hideExpanderButton={(dataItem: DTO_AssessmentEntity) => {
                    if (dataItem?.AssociatedEntities?.length > 0) return false;
                    return true;
                  }}
                />
              </div>
            </div>
          </section>
        </FormElement>
      )}
    />
  );
});
