import { history } from "@/AppRoutes";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { colCorporateAuthorisations } from "@common/pages/settings/security/corporate-authorisations/config";
import {
  SECURITY_CORPORATE_AUTH_LIST_VIEW_URL,
  SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE,
} from "@common/pages/settings/security/corporate-authorisations/constant";
import { Svc_CorporateAuthorisations } from "@common/pages/settings/security/corporate-authorisations/model";
import { SecurityCorporateAuthBookmark } from "@common/pages/settings/security/corporate-authorisations/util";
import { CorpAuthTabDetails } from "@common/pages/settings/security/corporate-authorisations/_id/components/reference-sidebar/detail/_index";
import { CorpAuthHistoryTab } from "@common/pages/settings/security/corporate-authorisations/_id/components/reference-sidebar/history/_index";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_CorporateAuthorisations>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SECURITY_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE}/new`);
        }}
        disabled={!hasPermission}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_SECURITY_CORPORATE_AUTH_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_SiteUser_CorporateAuthorisation}
        detail={SecurityCorporateAuthBookmark.getBookmarkDetail}
        displayName={SecurityCorporateAuthBookmark.getBookmarkDisplayName}
        listViewDetail={SecurityCorporateAuthBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          SecurityCorporateAuthBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CorpAuthTabDetails /> },
      {
        title: "History",
        component: <CorpAuthHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colCorporateAuthorisations}
      primaryField={nameOf("ID")}
      dataUrl={SECURITY_CORPORATE_AUTH_LIST_VIEW_URL}
      state={{
        sort: [
          { field: nameOf("CorporateAuthorisationName"), dir: "asc" },
          { field: nameOf("ID"), dir: "asc" },
        ],
      }}
      disabled={!hasPermission}
    />
  );
};
