import { loadAssessmentTransactionDetailTab } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/components/reference-sidebar/detail/api";
import { DTO_Transaction } from "@app/products/property/journals/not-yet-in-journal/assessment-transactions/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DECIMAL_FORMAT,
} from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const JournalAssessmentTransactionsDetailTab = observer(() => {
  //store
  const { lastSelectedId } = useCCProductListViewStore();
  //state local
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [transactionInfo, setTransactionInfo] = useState<DTO_Transaction>();

  /**
   * Function calling api
   * 2 params (first: journalID always 0, second: transactionId)
   */
  const handleLoadTabDetail = async () => {
    if (lastSelectedId) {
      setIsLoading(true);
      let errorResponse = undefined;
      const response = await loadAssessmentTransactionDetailTab(
        0,
        lastSelectedId
      );
      if (isSuccessResponse(response) && response?.data?.TransactionGrouping) {
        if (response.data.TransactionGrouping?.Transactions?.length)
          setTransactionInfo(response.data.TransactionGrouping.Transactions[0]);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    }
  };

  //useEffect
  useEffect(() => {
    handleLoadTabDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  //show load failed
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          handleLoadTabDetail();
        }}
      />
    );
  if (!transactionInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Transaction ID:"}
              value={transactionInfo?.TransactionId + ""}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Assessment number:"}
              value={formatDisplayValue(
                transactionInfo?.AssessmentNumber,
                DECIMAL_FORMAT.DECIMAL1
              )}
            />
            <ProductReferenceRow
              title={"Transaction type:"}
              value={transactionInfo?.TransactionType ?? ""}
            />
            <ProductReferenceRow
              title={"Date/Time:"}
              value={formatDisplayValue(
                transactionInfo?.DateTime,
                DATETIME_FORMAT.DATETIME
              )}
            />
            <ProductReferenceRow
              title={"Amount:"}
              value={formatDisplayValue(
                transactionInfo?.Amount,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
