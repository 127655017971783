import { FINANCE_FEE_MANAGE_ROUTE } from "@app/core/fees/[id]/constant";
import { Svc_Animals_Finance } from "@app/products/animals/finance/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Animals_Finance>();

export const colAnimalsFinanceFeesAll: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    locked: true,
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: Svc_Animals_Finance) => {
      return `${FINANCE_FEE_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: nameOf("InvoiceNumber"),
    title: "Invoice Number",
  },
  {
    field: nameOf("DebtorNumber"),
    title: "Debtor Number",
  },
  { field: nameOf("CreatedDate"), title: "Recorded", format: DATE_FORMAT.DATE },
  {
    field: nameOf("AmountIssued"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { field: nameOf("CostCode"), title: "Cost Code" },
  { field: nameOf("FeeType"), title: "Type" },
  { field: nameOf("Type"), title: "App Type" },
  { field: nameOf("ContactName"), title: "Name" },
  { field: nameOf("ReferenceNumber"), title: "Tag Number" },
  {
    field: nameOf("AmountOutstanding"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
