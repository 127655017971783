import { colAccountsTransaction } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/accounts/config";
import { IAccountsTransaction } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/accounts/model";
import { validatorAccount } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/accounts/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { differenceBy } from "lodash";
import React, { useState } from "react";

export const ACCOUNTS_FORM_STEP = "Accounts";

const nameOfAccounts = nameOfFactory<IAccountsTransaction>();

export const AccountsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorAccount}
    />
  );
};

export const FormStepElement = ({
  formRenderProps,
  nameOf,
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const [selectedAccounts, setSelectedAccounts] = useState<
    IAccountsTransaction[]
  >([]);

  const handleRemoveAccount = () => {
    onChange(nameOf("Accounts"), {
      value: differenceBy(
        getFieldValue("Accounts"),
        selectedAccounts,
        "AccountId"
      ),
    });
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Accounts to receive new transaction
          </label>
          <CCGrid
            selectableMode={"multiple"}
            onSelectionChange={(dataItem: any) => {
              setSelectedAccounts(dataItem);
            }}
            data={getFieldValue("Accounts") ?? []}
            primaryField={nameOfAccounts("AccountId")}
            columnFields={colAccountsTransaction}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  iconClass="fas fa-minus"
                  title="Remove Account"
                  onClick={handleRemoveAccount}
                  disabled={!getFieldValue("Accounts").length}
                />
              </div>
            }
          />
        </div>
      </div>
    </section>
  );
};
