import { nameOfRecall } from "@app/core/recall/[id]/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IRecallSectionProps {
  isInactive: boolean;
}

export const RecallSection = observer(({ isInactive }: IRecallSectionProps) => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Recall date" isMandatory />
          <Field
            name={nameOfRecall("Date_Recall")}
            component={CCDatePicker}
            validator={requiredValidator}
            disabled={isInactive}
            format={DATE_FORMAT.DATE_CONTROL}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Product name" />
          <Field
            name={nameOfRecall("ProductName")}
            component={CCInput}
            placeholder="Product name"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Product type" />
          <Field
            name={nameOfRecall("ProductType")}
            component={CCInput}
            placeholder="Product type"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Recall number" />
          <Field
            name={nameOfRecall("Number")}
            component={CCInput}
            placeholder="Recall number"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Batch number" />
          <Field
            name={nameOfRecall("BatchNo")}
            component={CCInput}
            placeholder="Batch number"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Country of origin" />
          <Field
            name={nameOfRecall("CountryOfOrigin")}
            component={CCInput}
            placeholder="Country"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Marking date" />
          <Field
            name={nameOfRecall("DateMarking")}
            component={CCInput}
            placeholder="Marking date"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Product size" />
          <Field
            name={nameOfRecall("ProductSize")}
            component={CCInput}
            placeholder="Product size"
            disabled={isInactive}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Packaging" />
          <Field
            name={nameOfRecall("ProductPackaging")}
            component={CCInput}
            placeholder="Packaging"
            disabled={isInactive}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Description" />
          <Field
            name={nameOfRecall("Details")}
            component={CCTextArea}
            placeholder="Description"
            rows={8}
            disabled={isInactive}
          />
        </div>
      </div>
    </section>
  );
});
