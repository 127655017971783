export interface HMPremisesRegisterView {
  ID: number;
  TradingName: string;
  PremisesClassification: string;
  PremisesType: string;
  DateLodged: Date | null;
  DateRegistrationExpires: Date | null;
  StreetNo: string;
  Street: string;
  AddressLine1: string;
  Suburb: string;
  AssessmentNo: string;
  PostalAddress: string;
  PostalAddress2: string;
  RefNo: string;
  FileNumber: string;
  RegNo: string;
  DebtorNo: string;
  Officer: string;
  Proprietor: string;
  ProprietorABN: string;
  ProprietorACN: string;
  PrimaryContact: string;
  Area: string;
  Mobile: string;
  Phone: string;
  Email: string;
  Fax: string;
  Risk: string;
  FSP: string;
  NoEmployees: number | null;
  FSPContact: string;
  HasAlert: boolean;
  AlertText: string;
  LastInspection: Date | null;
  LastCompliance: string;
  AmountOS: number;
  HomeBusiness: boolean;
  Status_ENUM: number;
  Status: string;
  Flag_MultiTenancy: boolean;
  Flag_DeptOfEducation: boolean;
  PreviousCategory: string;
  LocationDesc: string;
  Jurisdiction: string;
}

export enum EnumPremisesColumns {
  TradingName = 0,
  RegNo = 1,
  AddressLine1 = 2,
  StreetNo = 3,
  Street = 4,
  Suburb = 5,
  Area = 6,
  Phone = 7,
  PremisesClassification = 8,
  PremisesType = 9,
  Risk = 10,
  Officer = 11,
  RefNo = 12,
  FileNumber = 13,
  DebtorNo = 14,
  AssessmentNo = 15,
  Email = 16,
  PostalAddress = 17,
  PostalAddress2 = 18,
  Proprietor = 19,
  ProprietorABN = 20,
  ProprietorACN = 21,
  NoEmployees = 22,
  PrimaryContact = 23,
  FSSContact = 24,
  DateLodged = 25,
  DateRegistrationExpires = 26,
  Fax = 27,
  FSPTemplate = 28,
  HomeBusiness = 29,
  Status = 30,
  StatusIcon = 31,
  Mobile = 32,
  LocationDesc = 33,
  Jurisdiction = 34,
}

export interface HMPremisesView extends PremisesView {
  PremisesCategory: string;
  AddressLine1: string;
  RenewalStatus: string;
  ProprietorABN: string;
  ProprietorACN: string;
  Flag_ViewInPortal: boolean | null;
  LastInspection: Date | null;
  LastCompliance: string;
  AmountOS: number | null;
  FSRPremisesClassification: string;
  FSRPremisesTypeName: string;
  Flag_MultiTenancy: boolean;
  Flag_DeptOfEducation: boolean;
  PreviousCategory: string;
  LocationDesc: string;
  Jurisdiction: string;

  //Close view
  ClosureType: string;
  Date_Closed: Date | null;

  //Use for filter
  Flag_Tobacco: boolean;
  Flag_TobaccoRetail: boolean;
  Flag_HealthLicensing?: boolean;

  // Use for Prescribed Accom
  NoOfShortTermSites: number | null;
  NoOfLongTermSites: number | null;
  NoOfUnits: number | null;
  NoOfOccupants: number | null;
  NoOfBeds: number | null;
  NoOfRooms: number | null;

  //Use for PCBA view
  PCBA_BodyPiercing: boolean;
  PCBA_BodyTreatments: boolean;
  PCBA_ColonicIrrigation: boolean;
  PCBA_CosmeticTattooing: boolean;
  PCBA_CuticleCutting: boolean;
  PCBA_Dermabrasion: boolean;
  PCBA_Accupuncture: boolean;
  PCBA_EarPiercing: boolean;
  PCBA_Electrolysis: boolean;
  PCBA_EyebrowEyelashTinting: boolean;
  PCBA_EyelashExtensions: boolean;
  PCBA_Facials: boolean;
  PCBA_FacialsMakeup: boolean;
  PCBA_Footscraping: boolean;
  PCBA_Footspa: boolean;
  PCBA_Hairdressing: boolean;
  PCBA_HairExtensions: boolean;
  PCBA_HennaTattoos: boolean;
  PCBA_LaserTreatment: boolean;
  PCBA_Makeup: boolean;
  PCBA_ManicurePedicure: boolean;
  PCBA_NailTreatment: boolean;
  PCBA_SprayTan: boolean;
  PCBA_Tattooing: boolean;
  PCBA_TemporaryMakeUp: boolean;
  PCBA_Threading: boolean;
  PCBA_Waxing: boolean;
  PCBA_Wigs: boolean;
  PCBA_Other: boolean;
  PCBA_PRP: boolean;
  PCBA_Microblading: boolean;
  ProprietorAddress: string;
  PCBA: string;
}

export interface PremisesView {
  ID: number;
  TradingName: string;
  PremisesClassification: string;
  PremisesType: string;
  DateLodged: Date | null;
  DateRegistrationExpires: Date | null;
  StreetNo: string;
  Street: string;
  Address: string;
  Suburb: string;
  PropertyName: string;
  AssessmentNo: string;
  PostalAddress: string;
  PostalAddress2: string;
  RefNo: string;
  FileNumber: string;
  RegNo: string;
  DebtorNo: string;
  Officer: string;
  Proprietor: string;
  PrimaryContact: string;
  FSPContact: string;
  Area: string;
  Phone: string;
  Email: string;
  Fax: string;
  Risk: string;
  FSP: string;
  CreatedDate: Date;
  CreatedBy: string;
  NoEmployees: number | null;
  DateConditionsDue: Date | null;
  Status: string;
  HasAlert: boolean;
  AlertText: string;
  RenewalApproved: boolean;
  Flag_Temporary: boolean;
  RenewalMethod_ENUM: number;
  Flag_SwimmingPool: boolean;
  Flag_FoodPremises: boolean;
  Status_ENUM: number | null;
}
