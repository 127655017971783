import { SAP } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SAP>();
export const colSAPCustomerNumber: IColumnFields[] = [
  {
    field: nameOf("SAP_Number"),
    title: "SAP ID",
  },
  {
    field: nameOf("Region_Name"),
    title: "Region",
  },
];
