import { InspectionType } from "@app/core/inspections/[id]/model";
import { SaveButtonInspectionTypes } from "@common/pages/settings/lookups/inspection-types/_id/components/buttons/save/_index";
import { InspectionTypeForm } from "@common/pages/settings/lookups/inspection-types/_id/components/general/_index";
import { TitleInspectionTypeManagePage } from "@common/pages/settings/lookups/inspection-types/_id/constant";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageInspectionTypeComponent = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const inspectionType = dataForms?.GeneralForm as InspectionType;

  return (
    <>
      <FormTitle title={`New ${TitleInspectionTypeManagePage}`} />
      <CCManagePageActionBar leftActions={[<SaveButtonInspectionTypes />]} />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {inspectionType && <InspectionTypeForm />}
          </div>
        </div>
      </div>
    </>
  );
});
