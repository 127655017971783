import { history } from "@/AppRoutes";
import {
  CSLSystemAdminConditionsFormData,
  ICSL,
} from "@app/products/csl/[id]/model";
import { Toast } from "@components/toast";
import { mockConditions } from "./mock";

//CSL System Admin Conditons
export const loadCSLSystemAdminConditonsById = async (
  id?: string
): Promise<ICSL> => {
  const cslCSLSystemAdminConditons: CSLSystemAdminConditionsFormData[] =
    mockConditions;

  return cslCSLSystemAdminConditons[0];
};
export const postCSLSystemAdminConditons = async (requestBody: ICSL) => {
  console.error("CSLSystemAdminConditons form data !", requestBody);

  mockApiCSLSystemAdminConditonsAlwaySuccess(requestBody);
};

export const putCSLSystemAdminConditons = async (requestBody: ICSL) => {
  mockApiCSLSystemAdminConditonsAlwaySuccess(requestBody);
  history.replace(window.location.pathname, {
    status: "success",
  });
  return "MockDataReturning";
};

// TODO:
// DELETE IT WHEN WE HAVE API IMPLEMENTATION
const mockApiCSLSystemAdminConditonsAlwaySuccess = (requestBody: ICSL) => {
  setTimeout(() => {
    history.replace(`/csl/managelicencecategories${requestBody?.id || 1}`, {
      status: "success",
    });

    Toast.displayToast({
      content: "🚀 Save Conditions Reference successfully !",
      type: "success",
    });
  }, 2000);
};
