import { KeyValuePair } from "@app/core/documents/model";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { BELL_CHARACTER } from "@common/constants/characters";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./index.scss";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter, onChange } = formRenderProps;
  const { configData } = useSettingGlobalStore();

  const data = useDataSetting(configData, [
    "CorporateSettings_Finance_CustomExportColumnCount",
    "CorporateSettings_CustomFieldSeparator",
    "CorporateSettings_Finance_CustomExportFields",
    "CorporateSettings_Finance_CustomExportGroupColumn",
  ]);

  //Get values
  const customisedFields: KeyValuePair<string, string>[] =
    valueGetter(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`
    ) ?? [];

  const numberOfFields = valueGetter(
    data.CorporateSettings_Finance_CustomExportColumnCount?.FieldName as string
  );
  const [rowCount, setRowCount] = useState<number>(numberOfFields);
  const [initCustomFieldsValues] =
    useState<KeyValuePair<string, string>[]>(customisedFields);

  //Handle events
  const processData = (
    valueList: KeyValuePair<string, string>[] | undefined
  ) => {
    let currentValue = "";
    if (valueList) {
      valueList.forEach(
        (item: KeyValuePair<string, string>) =>
          (currentValue +=
            item?.Key + BELL_CHARACTER + item?.Value + BELL_CHARACTER)
      );
    }
    return currentValue;
  };

  const handleChangeTitle = (index: number, event: InputChangeEvent) => {
    const clonedCustomisedFields = JSON.parse(JSON.stringify(customisedFields));

    if (!clonedCustomisedFields[index]) {
      clonedCustomisedFields[index] = {
        Key: "",
        Value: "",
      };
    }
    clonedCustomisedFields[index].Key = event.value?.toString() || "";
    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`,
      {
        value: clonedCustomisedFields,
      }
    );
    onChange(
      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`,
      {
        value: processData(clonedCustomisedFields),
      }
    );
  };

  const handleChangeDateFormat = (index: number, event: InputChangeEvent) => {
    const clonedCustomisedFields = JSON.parse(JSON.stringify(customisedFields));
    if (!clonedCustomisedFields[index]) {
      clonedCustomisedFields[index] = {
        Key: "",
        Value: "",
      };
    }
    clonedCustomisedFields[index].Value = event.value?.toString() || "";

    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`,
      {
        value: clonedCustomisedFields,
      }
    );
    onChange(
      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`,
      {
        value: processData(clonedCustomisedFields),
      }
    );
  };

  const renderCustomisedFields = () => {
    const tempArray = Array.from({ length: rowCount }, (_, index) => index);
    return tempArray.map((index: number) => {
      return (
        <tr>
          <td>{index + 1}</td>
          <td>
            <Field
              name={`Title${index + 1}`}
              component={CCInput}
              disabled={!isEditing}
              defaultValue={initCustomFieldsValues[index]?.Key || ""}
              onChange={(event: InputChangeEvent) => {
                handleChangeTitle(index, event);
              }}
            />
          </td>
          <td>
            <Field
              name={`Data Format${index + 1}`}
              component={CCInput}
              disabled={!isEditing}
              min={0}
              defaultValue={initCustomFieldsValues[index]?.Value || ""}
              onChange={(event: InputChangeEvent) => {
                handleChangeDateFormat(index, event);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return configData ? (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1 cc-form-cols-finance">
          {data.CorporateSettings_CustomFieldSeparator && (
            <InputGlobalSettings
              data={data.CorporateSettings_CustomFieldSeparator}
              isEditing={isEditing}
            />
          )}
          {configData["ddlFinanceCustomAvailableExportFields"] && (
            <CCSearchComboBoxGlobalSettings
              data={configData["ddlFinanceCustomAvailableExportFields"]}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_Finance_CustomExportGroupColumn && (
            <InputGlobalSettings
              data={data.CorporateSettings_Finance_CustomExportGroupColumn}
              isEditing={isEditing}
            />
          )}
          <div>
            <CCLabel
              title={
                data.CorporateSettings_Finance_CustomExportColumnCount?.Title ??
                ""
              }
            />
          </div>
          <div className="cc-flex-container">
            {data.CorporateSettings_Finance_CustomExportColumnCount && (
              <div className="cc-content-col cc-form-cols-3">
                <Field
                  name={
                    data.CorporateSettings_Finance_CustomExportColumnCount
                      .FieldName
                  }
                  component={CCNumericTextBox}
                  min={0}
                  max={100}
                  format={NUMBER_FORMAT.NUMBER2}
                  disabled={!isEditing}
                  onChange={(event: NumericTextBoxChangeEvent) => {
                    onChange(
                      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportColumnCount}`,
                      {
                        value: event.value,
                      }
                    );
                    const clonedCustomisedFields = cloneDeep(customisedFields);
                    clonedCustomisedFields?.splice(event.value ?? 0);
                    onChange(
                      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`,
                      {
                        value: clonedCustomisedFields,
                      }
                    );
                    onChange(
                      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportFields}`,
                      {
                        value: processData(clonedCustomisedFields),
                      }
                    );
                  }}
                />
                <div className="cc-field">
                  <Button
                    className="cc-apply-button"
                    type="button"
                    disabled={!isEditing}
                    onClick={() => {
                      setRowCount(numberOfFields);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )}
          </div>
          {rowCount > 0 && (
            <div className="cc-custom-export-fields-table">
              <table className="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <td></td>
                    <td>
                      <CCLabel title="Title" />
                      {data.CorporateSettings_Finance_CustomExportFields
                        ?.Description && (
                        <label className="cc-settings-description">
                          {sanitizeHtml(
                            data.CorporateSettings_Finance_CustomExportFields?.Description.split(
                              "|"
                            )[0]
                          )}
                        </label>
                      )}
                    </td>
                    <td>
                      <CCLabel title="Date format" />
                      {data.CorporateSettings_Finance_CustomExportFields
                        ?.Description && (
                        <label className="cc-settings-description">
                          {sanitizeHtml(
                            data.CorporateSettings_Finance_CustomExportFields?.Description.split(
                              "|"
                            )[1]
                          )}
                        </label>
                      )}
                    </td>
                  </tr>
                </thead>

                <tbody>{renderCustomisedFields()}</tbody>
              </table>
            </div>
          )}
        </div>
      </section>
    </div>
  ) : null;
});
