export const mockFinancialSummaries: any = {
  List: [
    {
      Name: "Financial Area",
      SummarisedBy: "Financial Area=>Is Postponed=>Journal=>Session",
      TransactionDefaultPeriod: "All Transactions",
      SessionDefaultPeriod: "Current Rating Period",
      IncludePostponedCharges: true,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: true,
      IncludeProposedAmounts: true,
      ID: 101,
    },
    {
      Name: "Financial Area",
      SummarisedBy: "Session=>Is Deferred=>Financial Group",
      TransactionDefaultPeriod: "Current Rating Period",
      SessionDefaultPeriod: "Current Rating Period",
      IncludePostponedCharges: true,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: true,
      IncludeProposedAmounts: true,
      ID: 102,
    },
    {
      Name: "Due Date=>Finarcial Area",
      SummarisedBy: "Due Date=>Financial Area",
      TransactionDefaultPeriod: "Current Ratirg Period",
      SessionDefaultPeriod: "Current Rating Period",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: false,
      ID: 201,
    },
    {
      Name: "Due Date=>Financial Area",
      SummarisedBy: "Due Date=>Financial Area",
      TransactionDefaultPeriod: "Current Rating Period",
      SessionDefaultPeriod: "Current Rating Period",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: false,
      ID: 204,
    },
    {
      Name: "Firancial Area=>Finarcial Area",
      SummarisedBy: "Due Date",
      TransactionDefaultPeriod: "All Transactions",
      SessionDefaultPeriod: "All Transactions",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: true,
      ID: 205,
    },
    {
      Name: "Financial Area=>Finarcial Group=>Instalment Plan",
      SummarisedBy: "Financial Area=>Finarcial Group=>Instalment Plan",
      TransactionDefaultPeriod: "Current Rating Period",
      SessionDefaultPeriod: "Current Rating Period",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: false,
      ID: 210,
    },
  ],
  ListDetail: [
    {
      Name: "Financial Area",
      SummarisedBy_1: "FinancialArea",
      SummarisedBy_2: "IsPostponed",
      SummarisedBy_3: "Journal",
      SummarisedBy_4: "Session",
      TransactionDefaultPeriod: "1",
      TransactionFrom: "01-Jul-2021",
      TransactionTo: "20-Jun-2022",
      SessionFrom: "01-Jul-2021",
      SessionTo: "30-Jun-2022",
      SessionDefaultPeriod: "3",
      IncludePostponedCharges: true,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: true,
      IncludeProposedAmounts: true,
      ID: 100,
      GridSummary: [
        {
          Name: "Adhac Charges",
          Trans: 1,
          Charges: 1,
          Amount: 555,
          Interest: 0,
          Total: 555,
        },
        {
          Name: "Maree recept test",
          Trans: 1,
          Charges: 1,
          Amount: 555,
          Interest: 0,
          Total: 555,
        },
        {
          Name: "Maree Supp",
          Trans: 2,
          Charges: 2,
          Amount: 770,
          Interest: 0,
          Total: 770,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3018",
          Trans: 2,
          Charges: 2,
          Amount: -20,
          Interest: 0,
          Total: -20,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3019",
          Trans: 1,
          Charges: 1,
          Amount: -750,
          Interest: 0,
          Total: -750,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3020",
          Trans: 1,
          Charges: 1,
          Amount: -555,
          Interest: 0,
          Total: -555,
        },
        {
          Name: "Special Comm Fac RES",
          Trans: 1,
          Charges: 1,
          Amount: 20.52,
          Interest: 0,
          Total: 20.52,
        },
        {
          Name: "testing 680213",
          Trans: 1,
          Charges: 1,
          Amount: 34.69,
          Interest: 0,
          Total: 34.39,
        },
      ],
    },
    {
      Name: "Financial Area",
      SummarisedBy_1: "Session",
      SummarisedBy_2: "IsDeferred",
      SummarisedBy_3: "FinancialGroup",
      TransactionDefaultPeriod: "3",
      SessionDefaultPeriod: "3",
      TransactionFrom: "01-Jul-2021",
      TransactionTo: "20-Jun-2022",
      SessionFrom: "01-Jul-2021",
      SessionTo: "30-Jun-2022",
      IncludePostponedCharges: true,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: true,
      IncludeProposedAmounts: true,
      ID: 102,
      GridSummary: [
        {
          Name: "Payments taken at Town Hall - Location Session 3018",
          Trans: 2,
          Charges: 2,
          Amount: -20,
          Interest: 0,
          Total: -20,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3019",
          Trans: 1,
          Charges: 1,
          Amount: -750,
          Interest: 0,
          Total: -750,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3020",
          Trans: 1,
          Charges: 1,
          Amount: -555,
          Interest: 0,
          Total: -555,
        },
        {
          Name: "Special Comm Fac RES",
          Trans: 1,
          Charges: 1,
          Amount: 20.52,
          Interest: 0,
          Total: 20.52,
        },
        {
          Name: "testing 680213",
          Trans: 1,
          Charges: 1,
          Amount: 34.69,
          Interest: 0,
          Total: 34.39,
        },
      ],
    },
    {
      Name: "Due Date=>Finarcial Area",
      SummarisedBy_1: "DueDate",
      SummarisedBy_2: "FinancialArea",
      TransactionFrom: "01-Jul-2021",
      TransactionTo: "20-Jun-2022",
      SessionFrom: "01-Jul-2021",
      SessionTo: "30-Jun-2022",
      TransactionDefaultPeriod: "3",
      SessionDefaultPeriod: "3",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: false,
      ID: 201,
      GridSummary: [],
    },
    {
      Name: "Due Date=>Financial Area",
      SummarisedBy_1: "DueDate",
      SummarisedBy_2: "FinancialArea",
      TransactionFrom: "01-Jul-2021",
      TransactionTo: "20-Jun-2022",
      SessionFrom: "01-Jul-2021",
      SessionTo: "30-Jun-2022",
      TransactionDefaultPeriod: "3",
      SessionDefaultPeriod: "3",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: false,
      ID: 204,
      GridSummary: [
        {
          Name: "Adhac Charges",
          Trans: 1,
          Charges: 1,
          Amount: 555,
          Interest: 0,
          Total: 555,
        },
        {
          Name: "Maree recept test",
          Trans: 1,
          Charges: 1,
          Amount: 555,
          Interest: 0,
          Total: 555,
        },
        {
          Name: "Maree Supp",
          Trans: 2,
          Charges: 2,
          Amount: 770,
          Interest: 0,
          Total: 770,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3018",
          Trans: 2,
          Charges: 2,
          Amount: -20,
          Interest: 0,
          Total: -20,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3019",
          Trans: 1,
          Charges: 1,
          Amount: -750,
          Interest: 0,
          Total: -750,
        },
      ],
    },
    {
      Name: "Firancial Area=>Finarcial Area",
      SummarisedBy_1: "DueDate",
      TransactionDefaultPeriod: "1",
      SessionDefaultPeriod: "1",
      TransactionFrom: "01-Jul-2021",
      TransactionTo: "20-Jun-2022",
      SessionFrom: "01-Jul-2021",
      SessionTo: "30-Jun-2022",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: true,
      ID: 205,
      GridSummary: [
        {
          Name: "Adhac Charges",
          Trans: 1,
          Charges: 1,
          Amount: 555,
          Interest: 0,
          Total: 555,
        },
        {
          Name: "Maree recept test",
          Trans: 1,
          Charges: 1,
          Amount: 555,
          Interest: 0,
          Total: 555,
        },
        {
          Name: "Maree Supp",
          Trans: 2,
          Charges: 2,
          Amount: 770,
          Interest: 0,
          Total: 770,
        },
        {
          Name: "Payments taken at Town Hall - Location Session 3018",
          Trans: 2,
          Charges: 2,
          Amount: -20,
          Interest: 0,
          Total: -20,
        },
      ],
    },
    {
      Name: "Financial Area=>Finarcial Group=>Instalment Plan",
      SummarisedBy_1: "FinancialArea",
      SummarisedBy_2: "FinancialGroup",
      SummarisedBy_3: "InstalmentPlan",
      TransactionDefaultPeriod: "3",
      SessionDefaultPeriod: "3",
      TransactionFrom: "01-Jul-2021",
      TransactionTo: "20-Jun-2022",
      SessionFrom: "01-Jul-2021",
      SessionTo: "30-Jun-2022",
      IncludePostponedCharges: false,
      IncludeDeferredArrears: true,
      IncludeAmountsYetToUpdate: false,
      IncludeProposedAmounts: false,
      ID: 210,
      GridSummary: [],
    },
  ],
};
