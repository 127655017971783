import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { checkIsActiveCrmsPhoneMessage } from "@components/layout/cc-header/components/buttons/phone/util";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewMessageButton = observer(() => {
  const { setIsShowNewPhoneMsgDialog } = useCRMSPhoneMessageStore();
  const { activeProductsMap } = useCommonProductStore();
  var isActiveCrmsPhoneMessage =
    checkIsActiveCrmsPhoneMessage(activeProductsMap);
  if (!isActiveCrmsPhoneMessage) return null;

  return (
    <CCNavButton
      title="New Message"
      onClick={() => {
        setIsShowNewPhoneMsgDialog({
          isShowDialog: true,
          isProductListView: false,
        });
      }}
    />
  );
});
