import { DTO_Address } from "@app/products/property/assessments/[id]/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAddress = nameOfFactory<DTO_Address>();

export const colAddress: IColumnFields[] = [
  {
    field: nameOfAddress("propertyAddress"),
    title: "Property Address",
  },
  { field: nameOfAddress("AddressTypeName"), title: "Address Type" },
  {
    field: nameOfAddress("isAddressPrimary"),
    title: "Is Primary",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOfAddress("PropertyName"), title: "Property Name" },
  { field: nameOfAddress("Locality"), title: "Locality" },
  { field: nameOfAddress("LocationDescriptor"), title: "Location Descriptor" },
  { field: nameOfAddress("MapNumber"), title: "Map Number" },
  { field: nameOfAddress("MapReference"), title: "Map Reference" },
  {
    field: nameOfAddress("AddressPFI"),
    title: "Address PFI",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAddress("isRuralHouseNumber"),
    title: "Is Rural House Number",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAddress("AddressId"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
