import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { ExistedSiteUser } from "@common/pages/settings/security/site-users/_id/components/forms/existed/_index";
import { NewSiteUser } from "@common/pages/settings/security/site-users/_id/components/forms/new/_index";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageSiteUsers = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const siteUserId = params.id;
  const { resetStore, loadSiteUser } = useSiteUserStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffect(() => {
    if (isHasPermission) loadSiteUser(parseInt(siteUserId), isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteUserId, isNew, isHasPermission]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.SECURITY_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      {isNew ? <NewSiteUser /> : <ExistedSiteUser />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageSiteUsers);
