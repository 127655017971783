import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const councillorEventsRoute: ICCRoute = {
  path: "councillor-events",
  name: "Councillor Events",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_CouncillorEvents,
  children: [
    {
      path: "my-events",
      name: "My Events",
      enum: CRMSViews.Councillor_UnresolvedMyEvents,
      component: require("./my-events/_index").default,
    },
    {
      path: "unresolved-by-councillor",
      name: "Unresolved By Councillor",
      enum: CRMSViews.Councillor_UnresolvedByCouncillor,
      component: require("./unresolved-by-councillor/_index").default,
    },
    {
      path: "all-by-councillor",
      name: "All By Councillor",
      enum: CRMSViews.Councillor_AllByCouncillor,
      component: require("./all-by-councillor/_index").default,
    },
    {
      path: "all-by-org-1",
      name: "All By Org 1",
      enum: CRMSViews.Councillor_AllByOrg1,
      component: require("./all-by-org-1/_index").default,
    },
    {
      path: "all-by-ward",
      name: "All By Ward",
      enum: CRMSViews.Councillor_AllByWard,
      component: require("./all-by-ward/_index").default,
    },
  ],
};
