import { VO_Journal_SessionList } from "@app/products/property/journals/session-list/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Journal_SessionList>();
export const colDataUpdateGeneralDialog: IColumnFields[] = [
  {
    field: nameOf("Session_Number"),
    title: "Session",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Session_Name"),
    title: "Name",
  },
];
