import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;

  const data = useDataSetting(configData, [
    "Global_PortalEmailOn",
    "Global_PortalEmailSMTPServer",
    "Global_PortalEmailSMTPPort",
    "Global_PortalEmailEnableSSL",
    "Global_PortalEmailSMTPAuthentication",
    "Global_PortalEmailSMTPUsername",
    "Global_PortalEmailSMTPPassword",
    "Global_PortalEmailFromAddress",
    "CorporateSettings_Portal_Show_TermsAndConditions",
    "CorporateSettings_Portal_Enquiry_SubjectPrefix",
    "CorporateSettings_Portal_Enquiry_Recipient",
    "CorporateSettings_Portal_GISConnectionString",
    "CorporateSettings_Portal_GISSearchString",
  ]);

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {data.Global_PortalEmailOn && (
            <SwitchGlobalSettings
              data={data.Global_PortalEmailOn}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
          {data.Global_PortalEmailSMTPServer &&
            valueGetter(data.Global_PortalEmailOn?.FieldName as string) && (
              <InputGlobalSettings
                data={data.Global_PortalEmailSMTPServer}
                isEditing={isEditing}
              />
            )}
          {data.Global_PortalEmailSMTPPort &&
            valueGetter(data.Global_PortalEmailOn?.FieldName as string) && (
              <InputGlobalSettings
                data={data.Global_PortalEmailSMTPPort}
                isEditing={isEditing}
              />
            )}
        </div>

        <div className="cc-form-cols-3">
          {data.Global_PortalEmailEnableSSL &&
            valueGetter(data.Global_PortalEmailOn?.FieldName as string) && (
              <SwitchGlobalSettings
                data={data.Global_PortalEmailEnableSSL}
                isEditing={isEditing}
                formRenderProps={formRenderProps}
              />
            )}
          {data.Global_PortalEmailSMTPAuthentication &&
            valueGetter(data.Global_PortalEmailOn?.FieldName as string) && (
              <SwitchGlobalSettings
                data={data.Global_PortalEmailSMTPAuthentication}
                isEditing={isEditing}
                formRenderProps={formRenderProps}
              />
            )}
          {data.CorporateSettings_Portal_Show_TermsAndConditions && (
            <SwitchGlobalSettings
              data={data.CorporateSettings_Portal_Show_TermsAndConditions}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>

        <div className="cc-form-cols-3">
          {data.Global_PortalEmailSMTPUsername &&
            valueGetter(
              data.Global_PortalEmailSMTPAuthentication?.FieldName as string
            ) && (
              <InputGlobalSettings
                data={data.Global_PortalEmailSMTPUsername}
                isEditing={isEditing}
              />
            )}
          {data.Global_PortalEmailSMTPPassword &&
            valueGetter(
              data.Global_PortalEmailSMTPAuthentication?.FieldName as string
            ) && (
              <InputGlobalSettings
                data={data.Global_PortalEmailSMTPPassword}
                isEditing={isEditing}
              />
            )}
          {data.Global_PortalEmailFromAddress &&
            valueGetter(data.Global_PortalEmailOn?.FieldName as string) && (
              <InputGlobalSettings
                data={data.Global_PortalEmailFromAddress}
                isEditing={isEditing}
              />
            )}
        </div>

        <div className="cc-form-cols-3">
          {data.CorporateSettings_Portal_Enquiry_SubjectPrefix && (
            <InputGlobalSettings
              data={data.CorporateSettings_Portal_Enquiry_SubjectPrefix}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_Portal_Enquiry_Recipient && (
            <InputGlobalSettings
              data={data.CorporateSettings_Portal_Enquiry_Recipient}
              isEditing={isEditing}
            />
          )}
          {data.CorporateSettings_Portal_GISConnectionString && (
            <InputGlobalSettings
              data={data.CorporateSettings_Portal_GISConnectionString}
              isEditing={isEditing}
            />
          )}
        </div>
        {data.CorporateSettings_Portal_GISSearchString && (
          <div className="cc-form-cols-1">
            <TextAreaGlobalSettings
              data={data.CorporateSettings_Portal_GISSearchString}
              isEditing={isEditing}
            />
          </div>
        )}
      </section>
    </>
  );
});
