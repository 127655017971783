import { apiCoreGetActiontypesByProductTypes } from "@app/core/components/common/utils";
import { ILightWeightActionType } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/automatic-action/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAutomaticAction = async (): Promise<
  APIResponse<ILightWeightActionType[] | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      apiCoreGetActiontypesByProductTypes()
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
