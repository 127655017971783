import { NotificationPortalContent } from "@components/cc-notification-portal/components/notification-portal-content/_index";
import { INotificationPortalContent } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { notificationPortalDefaultValue } from "@components/cc-notification-portal/constant";
import { Slide } from "@progress/kendo-react-animation";
import React from "react";

export const getCurrentRouteActive = (dataRoute: Record<string, any>) => {
  const currentRoute = window.location.pathname;
  /* ====  Handle route is Regex  ==== */
  const routeRegexMatchCurrentRoute = Object.keys(dataRoute).find(
    (item: string) => new RegExp(item).test(currentRoute)
  );
  /* ====  / Handle route is Regex  ==== */

  const _routeActive = routeRegexMatchCurrentRoute
    ? routeRegexMatchCurrentRoute
    : currentRoute;
  return _routeActive;
};

export const notificationsDomList = (
  notifications: INotificationPortalContent[]
) => {
  return (
    <div className="cc-app-notification-container cc-notification-portal-wrapper">
      <Slide direction={"down"}>
        {notifications
          ?.filter((notification) => notification.stateId === "")
          .map((notification) => (
            <NotificationPortalContent
              {...notificationPortalDefaultValue}
              key={notification.id}
              {...notification}
            />
          ))}
      </Slide>
    </div>
  );
};
