export const TOWN_PLANNING_PPR_PSA_REFERRAL_CURRENT_REGISTER_LIST_VIEW_URL =
  "/odata/tp/ppr/internal/pprpsareferral/Register()?$count=true&";
export const TOWN_PLANNING_PPR_PSA_REFERRAL_CURRENT_BY_OFFICER_LIST_VIEW_URL =
  "/odata/tp/ppr/internal/pprpsareferral/Register(openRecsOnly=true)?$count=true&";
export const TOWN_PLANNING_PPR_PSA_REFERRAL_CURRENT_BY_TL_LIST_VIEW_URL =
  "/odata/tp/ppr/internal/pprpsareferral/Register(openRecsOnly=true)?$count=true&";
export const TOWN_PLANNING_PPR_PSA_REFERRAL_MY_APPLICATION_LIST_VIEW_URL =
  "/odata/tp/ppr/internal/pprpsareferral/Register(openRecsOnly=true,myRecs=true)?$count=true&";
export const TOWN_PLANNING_PPR_PSA_REFERRAL_HEARING_CALENDAR_URL =
  "/odata/tp/ppr/internal/pprpsareferral/Register(officerID={officerID})?$count=true";
