import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AnimalsSettingManageStore {
  private _isEditing: boolean = false;
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEditing(): boolean {
    return toJS(this._isEditing);
  }
  setIsEditing = (isEditing: boolean) => {
    runInAction(() => {
      this._isEditing = isEditing;
    });
  };

  get isLoading(): boolean {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
}

export const animalsSettingManageStoreContext = createContext(
  new AnimalsSettingManageStore()
);
export const useAnimalsSettingManageStore = () => {
  return useContext(animalsSettingManageStoreContext);
};
