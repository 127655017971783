import { Svc_LLPermitType } from "@app/products/local-laws/system-admin/permit-types/model";

export const llSystemAdminPermitTypesBookmark = {
  listViewDisplayURL() {
    return "Local Laws - System Admin";
  },

  listViewDisplayTitle() {
    return "Local Laws - System Admin - Permit Types";
  },

  recordDisplayURL(selectedRow: Svc_LLPermitType) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Local Laws - Permit Types ${dynamicDisplayName}`;
  },
};
