import { FileStreamEntry } from "@app/products/property/system-admin/document-template/[id]/model";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { encode } from "base64-arraybuffer";

export const readFileAsDataURL = async (file: File) => {
  return await new Promise((resolve) => {
    let fileReader: any = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onloadend = async () => {
      if (fileReader.result) {
        resolve(encode(await fileReader.result));
      }
    };
  });
};

export const convertToFileStreamEntry = async (fileUpload: UploadFileInfo) => {
  let fileStreamEntry: FileStreamEntry = {};
  if (fileUpload?.getRawFile) {
    const fileRaw = fileUpload?.getRawFile();
    fileStreamEntry.FileName = fileUpload.name;
    fileStreamEntry.ContentType = fileRaw?.type;
    fileStreamEntry.FileSize = fileUpload.size;
    const fileArrayBuffer = await readFileAsDataURL(fileRaw);
    fileStreamEntry.FileAttachment = fileArrayBuffer as string;
    return fileStreamEntry;
  }
};
