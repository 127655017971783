import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const enquiriesRoute: ICCRoute = {
  path: "enquiries",
  name: "Enquiries",
  enum: HealthManagerMenu.Enquiries,
  children: [
    {
      path: "register-enquiries",
      name: "Register",
      enum: HealthManagerMenu.RegisterMultiTenancy,
      // mock route: not implemented yet
      //component: require("./register-enquiries/_index").default,
    },
  ],
};
