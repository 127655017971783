import { ChargeRunsTotal } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/child-screens/general/components/form-element/components/totals";
import { DTO_ChargeRun_PIC_Summary } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { Field, FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<DTO_ChargeRun_PIC_Summary>();
export const PropertyChargeAndNoticeRunsFormElement = observer(() => {
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Totals",
      component: <ChargeRunsTotal />,
    },
  ];

  //Get label
  const assessmentGroupsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.AssessmentGroups
  );

  return (
    <FormElement className="cc-property-charge-and-notice-runs-charge-runs">
      <section className="cc-field-group cc-custom-sub-panel-bar">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">ID</label>
            <Field
              readOnly
              name={nameOf("ChargeRunId")}
              placeholder={"ID"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Name</label>
            <Field
              readOnly
              name={nameOf("ChargeName")}
              placeholder={"Name"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Code</label>
            <Field
              readOnly
              name={nameOf("Code")}
              placeholder={"Code"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Date</label>
            <Field
              disabled
              name={nameOf("ChargeDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Type</label>
            <Field
              readOnly
              name={nameOf("ChargeRunType")}
              placeholder={"Type"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Instalment plan</label>
            <Field
              readOnly
              name={nameOf("InstalmentPlan")}
              placeholder={"Instalment plan"}
              component={CCInput}
            />
          </div>
        </div>
        <div>
          <label className="cc-label">Selection criteria</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">{assessmentGroupsLabel}</label>
                <Field
                  readOnly
                  name={nameOf("AssessmentGroupName")}
                  placeholder={assessmentGroupsLabel}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Custom where</label>
                <Field
                  readOnly
                  name={nameOf("SelectionCriteria")}
                  placeholder="Custom where"
                  rows={3}
                  component={CCTextArea}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              readOnly
              name={nameOf("Description")}
              placeholder="Description"
              rows={3}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Created on</label>
            <Field
              disabled
              name={nameOf("CreatedOn")}
              placeholder={"Created on"}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Created by</label>
            <Field
              readOnly
              name={nameOf("CreatedBy")}
              placeholder={"Created by"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Updated on</label>
            <Field
              disabled
              name={nameOf("UpdatedOn")}
              placeholder={"Updated on"}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Updated by</label>
            <Field
              readOnly
              name={nameOf("UpdatedBy")}
              placeholder={"Updated by"}
              component={CCInput}
            />
          </div>
        </div>

        <CustomPanelBar listPanelBar={listPanelBar} sort={false} />
      </section>
    </FormElement>
  );
});
