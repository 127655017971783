import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { HD_SCREEN_WIDTH } from "@common/constants/responsive";
import { editorDefaultToolsCustomEditor } from "@components/cc-custom-editor/config";
import { CCEditor } from "@components/cc-editor/_index";
import { EditorProps } from "@progress/kendo-react-editor";
import { TextArea } from "@progress/kendo-react-inputs";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import React, { useMemo, useState } from "react";
import { useWindowSize } from "react-use";

export interface ICCCustomEditorProps
  extends Omit<EditorProps, "onChange" | "value"> {
  disabled?: boolean;
  onChangeCustomEditor: (e: string) => void;
  value: string;
}
export const CCCustomEditor = ({
  disabled,
  value,
  tools,
  onChangeCustomEditor,
  ...others
}: ICCCustomEditorProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const handleSelectTab = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const { width } = useWindowSize();
  const defaultTools = useMemo(
    () =>
      width >= HD_SCREEN_WIDTH
        ? editorDefaultToolsCustomEditor.web
        : editorDefaultToolsCustomEditor.mobile,

    [width]
  );

  return (
    <TabStrip selected={selectedTab} onSelect={handleSelectTab}>
      <TabStripTab title="Design">
        <CCEditor
          onChange={(e) => {
            onChangeCustomEditor(e.html);
          }}
          contentStyle={{ height: 500 }}
          value={value}
          tools={tools ? tools : defaultTools}
          {...others}
        />
      </TabStripTab>
      <TabStripTab title="HTML">
        <TextArea
          style={{ width: "100%", height: "500px" }}
          value={decryptionHtmlForEditor(value)}
          onChange={(e) => {
            onChangeCustomEditor(e.value);
          }}
        />
      </TabStripTab>
    </TabStrip>
  );
};
