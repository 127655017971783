import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { AddToJournalButton } from "@app/products/property/journals/not-yet-in-journal/components/action-bar/button/add-to-journal/_index";
import { JournalDeferredDutyTransactionsDetailTab } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/components/reference-sidebar/detail";
import {
  PROPERTY_JOURNALS_DEFERRED_DUTY_TRANSACTIONS_ROUTE,
  PROPERTY_JOURNALS_DEFERRED_DUTY_TRANSACTIONS_URL,
} from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/constant";
import { VO_Journal_DDTransaction_NotIn_Journal } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/model";
import { useNotYetInJournalDeferredDutyTransactionsStore } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/store";
import { JournalDeferredDutyTransactionBookmark } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/util";
import { journalsRoute } from "@app/products/property/journals/route";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<VO_Journal_DDTransaction_NotIn_Journal>();
export default observer(() => {
  //store
  const {
    isLoading,
    responseLoadError,
    reLoadApiConfig,
    loadViewConfigurationNotYetInJournalDeferredDuty,
    columnConfigDeferredDuty,
  } = useNotYetInJournalDeferredDutyTransactionsStore();

  //using hook
  useEffectOnce(() => {
    loadViewConfigurationNotYetInJournalDeferredDuty();
  });

  //hook set action-bar
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton type="sub-workflow" title="Workflow">
        <AddToJournalButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        isDisabledRecordListView
        linkUrl={PROPERTY_JOURNALS_DEFERRED_DUTY_TRANSACTIONS_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        listViewDetail={
          JournalDeferredDutyTransactionBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          JournalDeferredDutyTransactionBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  //hook set reference slide-bar
  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <JournalDeferredDutyTransactionsDetailTab />,
      },
    ],
  });

  //show Loading
  if (isLoading) return <Loading isLoading isFullScreen />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig();
        }}
      />
    );
  return (
    <CCProductListView
      dataUrl={PROPERTY_JOURNALS_DEFERRED_DUTY_TRANSACTIONS_URL}
      columnFields={columnConfigDeferredDuty}
      primaryField={nameOf("Transaction_Id")}
    />
  );
});
