import { getSearchDeferredDutyAccount } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/api";
import { deferredDutySearchByList } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/config";
import {
    DDAccountStatus,
    DTO_DeferredDuty_Account,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/model";
import { colJournalDeferredDuty } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/config";
import { isSuccessResponse } from "@common/apis/util";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { useDebounce } from "@common/hooks/useDebounce";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import {
    CCLocalNotification,
    ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import {
    InputChangeEvent,
    SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";

interface IAddDeferredDutyDialogProps {
  onClose: () => void;
  handleAddAccount: (accountList: any[]) => void;
  defaultMessage?: string;
}
const nameOf = nameOfFactory<DTO_DeferredDuty_Account>();
export const AddDeferredDutyDialog = ({
  onClose,
  handleAddAccount,
}: IAddDeferredDutyDialogProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState<DTO_LOV_Number>(
    deferredDutySearchByList[5]
  );
  const [isSearching, setIsSearching] = useState(false);
  const [onlyActiveAccount, setOnlyActiveAccount] = useState(false);
  const [searchResult, setSearchResult] = useState<DTO_DeferredDuty_Account[]>(
    []
  );
  const [selectedDeferredDuty, setSelectedDeferredDuty] = useState<
    DTO_DeferredDuty_Account[]
  >([]);

  const debouncedSearch = useDebounce(searchValue, 500);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  useEffect(() => {
    (async () => {
      if (
        !isNil(debouncedSearch) &&
        debouncedSearch.length >= 3 &&
        searchBy &&
        !isNil(searchBy?.Code)
      ) {
        setIsSearching(true);
        const response = await getSearchDeferredDutyAccount({
          LookupKey: searchBy.Code,
          LookupValue: debouncedSearch,
          Statuses: onlyActiveAccount ? [DDAccountStatus.Active] : [],
        });
        setIsSearching(false);
        if (isSuccessResponse(response) && response.data) {
          setSearchResult(response.data.DeferredDutyAccounts);
        } else {
          notificationRef.current?.pushNotification({
            title: response.error ?? "Account lookup search failed",
            type: "error",
            autoClose: false,
          });
        }
      }
    })();
  }, [debouncedSearch, searchBy, onlyActiveAccount]);

  return (
    <Form
      onSubmitClick={() => handleAddAccount(selectedDeferredDuty)}
      initialValues={{
        DeferredDutySearchBy: deferredDutySearchByList[5],
        OnlyActiveAccounts: false,
      }}
      render={(formRenderProps: FormRenderProps) => (
        <CCDialog
          titleHeader="Deferred Duty Account Lookup"
          maxWidth="50%"
          height="auto"
          onClose={onClose}
          bodyElement={
            <FormElement className="cc-form cc-add-assessment-range-dialog">
              <section className="cc-field-group">
                <CCLocalNotification ref={notificationRef} />
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Search by" />
                    <Field
                      name={"DeferredDutySearchBy"}
                      placeholder={"Search by"}
                      component={CCDropDownList}
                      data={deferredDutySearchByList}
                      textField={"Name"}
                      dataItemKey={"Code"}
                      onChange={(event: DropDownListChangeEvent) => {
                        setSearchBy(event.value);
                        formRenderProps.onChange("DeferredDutySearchBy", {
                          value: event.value,
                        });
                      }}
                    />
                  </div>
                  <div className="cc-field cc-col-span-2">
                    <label className="cc-label">Search value</label>
                    <Field
                      name={"DeferredDutySearchValue"}
                      placeholder={"Search value"}
                      component={CCInput}
                      format={NUMBER_FORMAT.NUMBER2}
                      onChange={(event: InputChangeEvent) => {
                        setSearchValue(event.value);
                        formRenderProps.onChange("DeferredDutySearchValue", {
                          value: event.value,
                        });
                      }}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Only active accounts" />
                    <Field
                      name={"OnlyActiveAccounts"}
                      component={CCSwitch}
                      checked={onlyActiveAccount}
                      onChange={(event: SwitchChangeEvent) => {
                        setOnlyActiveAccount(event.value);
                        formRenderProps.onChange("OnlyActiveAccounts", {
                          value: event.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <CCGrid
                      columnFields={colJournalDeferredDuty}
                      primaryField={nameOf("AccountId")}
                      data={searchResult ?? []}
                      isLoading={isSearching}
                      selectableMode="single"
                      onSelectionChange={(
                        dataItem: DTO_DeferredDuty_Account[]
                      ) => {
                        setSelectedDeferredDuty(dataItem);
                      }}
                    />
                  </div>
                </div>
              </section>
            </FormElement>
          }
          footerElement={
            <div className="cc-dialog-footer-actions-right">
              <Button
                className="cc-dialog-button"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="cc-dialog-button"
                themeColor="primary"
                onClick={formRenderProps.onSubmit}
                type="submit"
                disabled={
                  !formRenderProps.valid ||
                  !formRenderProps.modified ||
                  selectedDeferredDuty?.length < 1
                }
              >
                OK
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
