import { useContactRoleCellStore } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations-lls/components/contact-role-cell/store";
import { DTO_Role } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
const nameOfRole = nameOfFactory<DTO_Role>();

export const ContactRoleCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const { dropdownList } = useContactRoleCellStore();
  return (
    <DropDownList
      textField={nameOfRole("Name")}
      dataItemKey={nameOfRole("Id")}
      data={dropdownList}
      value={getDropdownValue(value, dropdownList, nameOfRole("Name"))}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value?.Name,
          });
        }
      }}
    />
  );
};
