import {
  Application,
  Council,
  StateRoad,
} from "@app/products/town-planning/ppr/[id]/model";
import {
  Address,
  BubbleUpIdentifier,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { KeywordLite } from "@common/models/keyword";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface Register extends DTOBaseClass_Standard {
  Register_ID: number;
  Legacy_LID: string;
  Register_Number: string;
  RegisterType_ENUM: RegisterType;
  RegisterType_Name: string;
  Status_ENUM: RegisterStatus;
  Status_Name: string;
  Description: string;
  AdditionalInfo: string;
  Comment1: string;
  Comment2: string;
  ProductType_ENUM: PRODUCT_TYPE_NUMBER | null;
  ProductType_Name: string;
  IsBondDataNonEditable: boolean;
  IsBondExported: boolean | null;
  Contact: Contact_Relation;
  Contact_RID: number | null;
  Contact2: Contact_Relation;
  Contact2_RID: number | null;
  Officer: SiteUser;
  Officer_ID: number | null;
  BuildSurveyor_ID: number | null;
  BuildSurveyor: SiteUser;
  Address: Address;
  Address_ID: number | null;
  AdditionalAddresses: Address[];
  Type_KWD: number | null;
  Outcome_KWD: number | null;
  Source_KWD: number | null;
  Category_KWD: number | null;
  Regulations_KWD: number | null;
  BarrierTypes_KWD: number | null;
  Regulations_Description: string;
  BarrierTypes_Description: string;
  Building_UseOfBuilding_KeyWord: IKeyValuePacket[];
  Building_UseOfBuilding_Keyword_Display: string;
  Outcome_Description: string;
  ReferenceNumber: string;
  PlanForSubDivision: string;
  NatureOfProposedBuildingWorks: string;
  MinimumHeightAboveGroundLevel: string;
  Flag_CopyRequiredWithResponse: boolean | null;
  ReferenceNumber2: string;
  FileNumber: string;
  FrequencyOfInspection: number | null;
  InsurancePolicyNumber: string;
  BondReceiptNumber: string;
  BondAmount: number | null;
  DamageAmount: number | null;
  RefundAmount: number | null;
  CostOfWorks: number | null;
  CouncilConsentAssessment_OBJ?: CouncilConsentAssessment;
  DebtorNumber: string;
  CouncilConsentType_KWD: number | null;
  CouncilConsentPermitType_ID: number | null;
  CT_Make: string;
  CT_Model: string;
  CT_Serial: string;
  CT_Application: string;
  CT_Location: string;
  CT_FreqOfOperation: string;
  Flag_BiocideElimFitted: boolean | null;
  Flag_DriftElimFittted: boolean | null;
  Flag_DriftCover: boolean | null;
  Flag_DriftBelow: boolean | null;
  Flag_DriftRemove: boolean | null;
  Pool_TurnOver: string;
  Pool_Siting: string;
  Pool_DisinfectantDosing: string;
  Pool_WaterTreatment: string;
  Pool_Filtration: string;
  Pool_WaterDisposal: string;
  Pool_Flag_CyanuricAcid: boolean | null;
  Pool_Flag_SharedCirculation: boolean | null;
  Pool_Flag_Contracted: boolean | null;
  Pool_Volume: number | null;
  BuildingPermitNumber: string;
  Date_Construction: Date | null;
  LetterNumber: string;
  Date_LetterSent: Date | null;
  Date_CertificateOfCompliance: Date | null;
  Pool_IsWorksCarriedOut: boolean | null;
  Pool_WorksCarriedOut_Desc: string;
  Pool_RegisteredDate: Date | null;
  Pool_ReRegisteredDate: Date | null;
  RenewalFee_ID: number | null;
  Date_Renewed: Date | null;
  Date_Lodgement_CertificateOfBarrierNC: Date | null;
  Date_WorksIssued_AfterDateOfConstruction: Date | null;
  Date_Recorded: Date | null;
  Date_Lodged: Date | null;
  Date_Start: Date | null;
  Date_Due: Date | null;
  Date_Complete: Date | null;
  Date_BondReceived: Date | null;
  Date_BondReturned: Date | null;
  ProposedStartDate: Date | null;
  ProposedFinishDate: Date | null;
  Date_LastInspectionCalculated: Date | null;
  Date_NextInspectionCalculated: Date | null;
  Flag_RequiresCertificate: boolean | null;
  Flag_RenewalFeeCreated: boolean | null;
  Flag_RenewalNoticeSent: boolean | null;
  Flag_Anonymous: boolean | null;
  Flag_Justified: boolean | null;
  Date_Occurred: Date | null;
  Date_Notified: Date | null;
  Date_Notified2: Date | null;
  Date_Notified3: Date | null;
  Date_FirstInspection: Date | null;
  Date_LastInspection: Date | null;
  Date_ReminderSent: Date | null;
  Items_Keyword_CSV: string;
  Date_Expiry: Date | null;
  RiskAssess_HasBreachOccured: boolean | null;
  RiskAssess_LevelOfEnforcement_KWD: number | null;
  RiskAssess_LevelOfEnforcement_Name: string;
  RiskAssess_ImportanceRating_KWD: number | null;
  RiskAssess_ImportanceRating_Name: string;
  RiskAssess_CapacityToEscalate_KWD: number | null;
  RiskAssess_CapacityToEscalate_Name: string;
  RiskAssess_ScaleOfBreach_KWD: number | null;
  RiskAssess_ScaleOfBreach_Name: string;
  RiskAssess_UrgencyRating_KWD: number | null;
  RiskAssess_UrgencyRating_Name: string;
  RiskAssess_AssessDetails: string;
  EPlanning_ID: number | null;
  EPlanning_Username: string;
  EPlanning_DisplayName: string;
  EPlanning_Categories: string;
  EnquiryMethod_KWD: number | null;
  ResponsibleAuthority_ID: number | null;
  ResponsibleAuthority?: Council;
  VRStreetID: number | null;
  VRStreet?: StateRoad;
  VR_OfficeID: number | null;
  VROffice: string;
  TeamLeader_ID: number | null;
  TeamLeader?: SiteUser;
  Date_Responded: Date | null;
  OriginReferral_KWD: number | null;
  RejectionResponse_Notes: string;
  OfficerRecommendationNotes: string;
  Decision_Name: string;
  DecisionType_KWD: number | null;
  DecisionType_Name: string;
  FinalDecision_Name: string;
  FinalDecisionType_KWD: number | null;
  FinalDecisionType_Name: string;
  FinalDecisionDate: Date | null;
  EXT_Option_1: boolean | null;
  EXT_Option_2: boolean | null;
  EXT_Date_1: Date | null;
  EXT_Date_2: Date | null;
  EXT_Officer_1: string;
  EXT_Officer_2: string;
  EXT_Comment_1: string;
  EXT_Comment_2: string;
  ExistingUse: string;
  FormattedAuthorName: string;
  Items_Keyword: IKeyValuePacket[];
  Items_Text: string;
  BubbleUps: BubbleUpIdentifier[];
  SaveTriggers: RegisterUpdateTriggers[];
}
export interface ExtRegister extends Register {
  _options?: {
    ZoneList?: IKeyValuePacket[];
    OverlayList?: IKeyValuePacket[];
    CategoryName?: string;
  };
}
export interface CouncilConsentAssessment {
  AssessmentSource_Name: string;
  AssessmentSource_ENUM: AssessmentSource;
  Questions: CouncilConsentAssessmentQuestion[];
  ParentKWDs: number[];
  ParentKeywords: KeywordLite[];
  TargetDay: number;
}

export interface CouncilConsentAssessmentQuestion {
  RowNumber: number;
  ParentQuestionName: string;
  ParentQuestionDescription: string;
  ParentQuestionHTML: string;
  ParentQuestionSource_KWD: number;
  QuestionName: string;
  QuestionDescription: string;
  QuestionSource_KWD: number;
  QuestionSouceType_ENUM: CCQuestionSouceType;
  QuestionSourceType_Name: string;
  OptionsType_ENUM: CCOptionType;
  OptionsType_Name: string;
  Options_ENUM: CCOptions;
  Options_Name: string;
}

export enum RegisterType {
  SystemInitialise = 0,
  CouncilConsent = 1,
  Demolition = 2,
  EssentialSafety = 3,
  POPE = 4,
  PlansToComply = 5,
  PlanningEnquiry = 6,
  PlanningCertificate = 7,
  SecondaryConsent = 8,
  NonCompliance = 9,
  CommunityProperty = 10,
  Reserve = 11,
  CoolingTower = 12,
  SwimmingPool = 13,
  StreetProtection = 14,
  MultiTenancy = 15,
  Easement = 16,
  PoolAndSpaBuilding = 17,
  Stormwater = 18,
  SitingApproval = 19,
  BuildingEnquiry = 20,
  Enforcement = 21,
  Pretreatment = 22,
  PlanningExtensionOfTime = 23,
  BuildingCertificate = 24,
  LandManagementCertification = 25,
  LandManagementNotification = 26,
  LandManagementEnquiry = 27,
  CourtCase = 28,
  CEP_BuildingPermit = 29,
  CEP_CouncilConsentFlooding = 30,
  CEP_BuildingAdditionalDocument = 31,
  CEP_PrivateSurveyor = 32,
  HealthEnquiry = 33,
  Site = 34,
  TSRHolding = 35,
}

export enum RegisterStatus {
  SystemInitialise = "SystemInitialise", //0
  New = "New", //1
  Open = "Open", //2
  Closed = "Closed", //3
  PortalHoldingBay = "PortalHoldingBay", //4
  NA = "NA", //5
  Active = "Active", //6
  Decommissioned = "Decommissioned", //7
  Enforcement = "Enforcement", //8
  Existing = "Existing", //9
  Required = "Required", //10
  Lodged = "Lodged", //11
  Assessed = "Assessed", //12
  Inspected = "Inspected", //13
  ReadyForWarningNotice = "ReadyForWarningNotice", //14
  Awaiting_Assessment = "Awaiting_Assessment", //15
  Withdrawn = "Withdrawn", //16
  Appealed = "Appealed", //17
  ReadyForDecision = "ReadyForDecision", //18
  NotAppealed = "NotAppealed", //19
  FIRequest = "FIRequest", //20
  ReadyToAdvertise = "ReadyToAdvertise", //21
  Advertising = "Advertising", //22
  ReadyForAssessment = "ReadyForAssessment", //23
  Refused = "Refused", //24
  WarningNotice = "WarningNotice", //25
  ReadyForInfringement = "ReadyForInfringement", //26
  Infringement = "Infringement", //27
  ReadyForAppeal = "ReadyForAppeal", //28
  Prosecution = "Prosecution", //29
  ReadyForCompletion = "ReadyForCompletion", //30
  ReadyForProsecution = "ReadyForProsecution", //31
  ReceivedFromPortal = "ReceivedFromPortal", //32
  AllocatedToPlanner = "AllocatedToPlanner", //33
  Granted = "Granted", //34
  Lapsed = "Lapsed", //35
  AppealComplete = "AppealComplete", //36
  Entered = "Entered", //37
  AssessmentComplete = "AssessmentComplete", //38
  Complies = "Complies", //39
  NonComplies = "NonComplies", //40
  UnAuthorised = "UnAuthorised", //41
  AwaitingTLApproval = "AwaitingTLApproval", //42
  TLApproved = "TLApproved", //43
  TLRejected = "TLRejected", //44
  Proposed = "Proposed", //45
  Surrender = "Surrender", //46
  Amend = "Amend", //47
  SecondaryConsent_Lodged = "SecondaryConsent_Lodged", //101
  SecondaryConsent_PreliminaryAssessment = "SecondaryConsent_PreliminaryAssessment", //102
  SecondaryConsent_Issued = "SecondaryConsent_Issued", //103
  SecondaryConsent_Withdrawn = "SecondaryConsent_Withdrawn", //104
  AwaitingApproval = "AwaitingApproval", //201
  Approved = "Approved", //202
  Rejected = "Rejected", //203
  Completed = "Completed", //204
  NotForVR = "NotForVR", //205
  ReadyForAcknowledgment = "ReadyForAcknowledgment", //211
  SentForAcknowledgment = "SentForAcknowledgment", //212
  AcknowledgmentReceived = "AcknowledgmentReceived", //213
  ReadyForReview = "ReadyForReview", //214
  DelegateApproved = "DelegateApproved", //215
  DelegateReject = "DelegateReject", //216
  CertificationIssued = "CertificationIssued", //217
  NotificationIssued = "NotificationIssued", //218
  ReadyForAppraisal = "ReadyForAppraisal", //219
  AppraisalComplete = "AppraisalComplete", //220
  InformationPackReady = "InformationPackReady", //221
  InformationPackSent = "InformationPackSent", //222
  EOIReceived = "EOIReceived", //223
  OnHold = "OnHold", //224
  Transferred = "Transferred", //225
}

export enum RegisterUpdateTriggers {
  UpdateContact,
  UpdateContact2,
  UpdateAddress,
  UpdateBubbles,
  UpdateAdditionalAddresses,
  UpdateAssessmentPlanner,
  UpdateLots,
  UpdateAreas,
  UpdateDueDiligence,
  UpdateOfficer,
  UpdateTeamLeader,
}

export enum AssessmentSource {
  Unspecified = 0,
  CouncilConsent = 1,
}

export enum CCQuestionSouceType {
  Unspecified = 0,
  Core_Keyword = 1,
  Core_Question = 2,
}

export enum CCOptionType {
  Unspecified = 0,
  MailMerge = 1,
}

export enum CCOptions {
  Unspecified = 0,
  Yes = 1,
  No = 2,
  NA = 3,
}

export interface PPREnquiryIdentityPacket extends IdentityPacket {
  Register: Register;
}

export enum PPREnquirySubmitActions {
  ApproveResponse = "ApproveResponse",
  New = "New",
  Save = "Save",
  LodgeApplication = "LodgeApplication",
  CreateAndLodgeApplication = "CreateAndLodgeApplication",
  NotForVR = "NotForVR",
  CreateApplication = "CreateApplication",
  SendForApproval = "SendForApproval",
  RejectResponse = "RejectResponse",
  SendResponse = "SendResponse",
}

export const pprSubmitWithValidateActions: PPREnquirySubmitActions[] = [
  PPREnquirySubmitActions.LodgeApplication,
  PPREnquirySubmitActions.CreateAndLodgeApplication,
  PPREnquirySubmitActions.NotForVR,
  PPREnquirySubmitActions.SendForApproval,
  PPREnquirySubmitActions.RejectResponse,
  PPREnquirySubmitActions.SendResponse,
];

export enum INFOXPERTSTORAGEMETHOD {
  SELECT,
  CREATE,
}

export interface IPPREnquiryParentSection {
  notification?: IAppNotificationItemAddProps[];
  pprApplication?: Application;
  recordType?: RECORDTYPE;
  parentID?: number;
}

export enum RegisterStatus_Int {
  SystemInitialise = 0,
  New = 1,
  Open = 2,
  Closed = 3,
  PortalHoldingBay = 4,
  NA = 5,
  Active = 6,
  Decommissioned = 7,
  Enforcement = 8,
  Existing = 9,
  Required = 10,
  Lodged = 11,
  Assessed = 12,
  Inspected = 13,
  ReadyForWarningNotice = 14,
  Awaiting_Assessment = 15,
  Withdrawn = 16,
  Appealed = 17,
  ReadyForDecision = 18,
  NotAppealed = 19,
  FIRequest = 20,
  ReadyToAdvertise = 21,
  Advertising = 22,
  ReadyForAssessment = 23,
  Refused = 24,
  WarningNotice = 25,
  ReadyForInfringement = 26,
  Infringement = 27,
  ReadyForAppeal = 28,
  Prosecution = 29,
  ReadyForCompletion = 30,
  ReadyForProsecution = 31,
  ReceivedFromPortal = 32,
  AllocatedToPlanner = 33,
  Granted = 34,
  Lapsed = 35,
  AppealComplete = 36,
  Entered = 37,
  AssessmentComplete = 38,
  Complies = 39,
  NonComplies = 40,
  UnAuthorised = 41,
  AwaitingTLApproval = 42,
  TLApproved = 43,
  TLRejected = 44,
  Proposed = 45,
  Surrender = 46,
  Amend = 47,
  SecondaryConsent_Lodged = 101,
  SecondaryConsent_PreliminaryAssessment = 102,
  SecondaryConsent_Issued = 103,
  SecondaryConsent_Withdrawn = 104,
  AwaitingApproval = 201,
  Approved = 202,
  Rejected = 203,
  Completed = 204,
  NotForVR = 205,
  ReadyForAcknowledgment = 211,
  SentForAcknowledgment = 212,
  AcknowledgmentReceived = 213,
  ReadyForReview = 214,
  DelegateApproved = 215,
  DelegateReject = 216,
  CertificationIssued = 217,
  NotificationIssued = 218,
  ReadyForAppraisal = 219,
  AppraisalComplete = 220,
  InformationPackReady = 221,
  InformationPackSent = 222,
  EOIReceived = 223,
  OnHold = 224,
  Transferred = 225,
}
