import { AmalgamatePICCommonButton } from "@app/products/property/pic/list/components/action-bar/buttons/amalgamate-pic/components/buttons/amalgamate-pic-common/_index";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AmalgamatePICListButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const isEnable = useMemo(
    () => gridSelectedRows?.length > 0,
    [gridSelectedRows]
  );

  const listPicId = useMemo(
    () => gridSelectedRows?.map((item: VO_PIC) => item.PIC_Id) ?? [],
    [gridSelectedRows]
  );

  return (
    <AmalgamatePICCommonButton isDisabled={!isEnable} picIDs={listPicId} />
  );
});
