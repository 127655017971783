import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import React, { useState } from "react";
import { reinstateJobQueue } from "@common/pages/settings/system-admin/job-queue-failures/components/action-bar/buttons/reinstate/api";
import { observer } from "mobx-react-lite";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { eventEmitter } from "@/App";
import { CCGridEventType } from "@components/cc-grid/constant";

export const ReinstateJobQueueButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { gridSelectedIds } = useCCProductListViewStore();

  const handleClickButton = async () => {
    setIsLoading(true);
    const response = await reinstateJobQueue(gridSelectedIds);
    setIsLoading(false);
    clearNotifications();
    if (
      isSuccessResponse(response) &&
      response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
    ) {
      pushNotification({
        title:
          response.data?.Notifications?.length > 0
            ? response.data?.Notifications?.[0]
            : `Reinstate job queue was completed successfully`,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      pushNotification({
        autoClose: false,
        title: `Reinstate job queue could not be completed`,
        type: "error",
      });
    }
  };

  return (
    <CCNavButton
      title="Reinstate"
      onClick={handleClickButton}
      isLoading={isLoading}
      disabled={gridSelectedIds.length === 0}
    />
  );
});
