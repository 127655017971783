import { VO_Charge_Run_Assessment_Rebate_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfRebateSummaryGrid =
  nameOfFactory<VO_Charge_Run_Assessment_Rebate_Summary>();

export const colAssessmentRebateSummary: IColumnFields[] = [
  {
    field: nameOfRebateSummaryGrid("RT_Name"),
    title: "Rebate Type",
  },
  {
    field: nameOfRebateSummaryGrid("Total_Rebates"),
    title: "Total Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
