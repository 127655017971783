import { IFormValid } from "@components/cc-form-step/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export const handleUpdateFormValid = (
  formValid: IFormValid,
  setFormValid: (formValid: IFormValid) => void,
  formRenderProps: FormRenderProps,
  currentStepIndex: number
) => {
  const isStepChange = formValid.stepIndex !== currentStepIndex; //Step index changes
  //formValid of step changes
  const isStepValidChange =
    formValid.stepIndex === currentStepIndex &&
    formValid.isValid !== formRenderProps.valid;
  const canUpdateFormValid = isStepChange || isStepValidChange;
  if (!canUpdateFormValid) return;
  setFormValid({
    isValid: formRenderProps.valid,
    stepIndex: currentStepIndex,
  });
};
