import { mockReasonForTheSpecialReading } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/mock";
import {
  IReadingGridData,
  ISpecialReadingDialogData,
} from "@app/products/property/meters/components/dialogs/enter-special-reading/model";

export const mockReadingGridData: IReadingGridData[] = [
  {
    MeterNumber: "05W888617",
    PreviousReadingDate: new Date("6-Jun-2020 16:15"),
    PreviousReading: 4782,
    UnitOfMeasure: "Kilolitre",
    PercentShare: 20.0,
    NumberOfDials: 5,
    EstimatedUsage: 1751,
    WarningUsageThreshold: 3502,
    MeterInstallationId: 4342,
    Status: "Active",
  },
  {
    MeterNumber: "99002516",
    PreviousReadingDate: new Date("9-Jan-2016 00:00"),
    PreviousReading: 6081,
    UnitOfMeasure: "Kilolitre",
    NumberOfDials: 4,
    WarningUsageThreshold: 11210,
    MeterInstallationId: 3214,
    Status: "Retired",
    EstimatedUsage: 5605,
  },
];

export const mockSpecialReading: ISpecialReadingDialogData = {
  ReasonForTheSpecialReading: { Key: 3, Value: "New Meter" },
  _option: {
    ReasonForTheSpecialReading: {
      Data: mockReasonForTheSpecialReading,
    },
  },
  TaxIncluded: 0,
  Reading: mockReadingGridData,
};
