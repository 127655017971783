import { EComponentAreaResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/config";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAORModifyDebtRecoveryButtonProps {
  disabled?: boolean;
}
export const AORModifyDebtRecoveryButton = observer(
  ({ disabled }: IAORModifyDebtRecoveryButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    const { reLoadCertificate } = useCertificateStore();
    return (
      <CCNavButton
        title="Modify debt recovery"
        disabled={disabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.AreaOfResponsibility_Modify,
              data: {},
              props: {
                aorId: EComponentAreaResponsibility.DebtRecovery,
                actionAfterFinish: (
                  notificationContent: IAppNotificationItemAddProps
                ) => {
                  reLoadCertificate(notificationContent);
                },
                isRedirectManagePage: false,
              },
            },
          ]);
        }}
      />
    );
  }
);
