import {
  ICrmsDataUserGroupOrgForm,
  ICrmsUsersPicker,
} from "@app/products/crms/components/dialogs/pick-users-groups/model";
import { CrmsPickKeysOrganisationUnitButton } from "@app/products/crms/components/picker-button/pick-keys-organisation-unit/_index";
import { CrmsPickKeysServiceStandardButton } from "@app/products/crms/components/picker-button/pick-keys-service-standard/_index";
import { CrmsPickUserGroupOrgButton } from "@app/products/crms/components/picker-button/pick-users-groups/_index";
import {
  colRosterKeys,
  colRosterUsers,
} from "@app/products/crms/system-admin/rosters/[id]/components/forms/components/child-screens/general/components/form-element/config";
import {
  Roster,
  RosterHandlerRequest,
  RosterKey,
  RosterSiteUser,
  RosterUpdateTriggers,
  Svc_FormAction_Roster,
} from "@app/products/crms/system-admin/rosters/[id]/model";
import { useRosterStore } from "@app/products/crms/system-admin/rosters/[id]/store";
import { RosterType } from "@common/constants/enumerations";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

interface IApplicationCategoryFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Roster>();
const nameOfKeys = nameOfFactory<RosterKey>();
const nameOfUsers = nameOfFactory<RosterSiteUser>();

export const RosterFormElement = observer(
  ({ formRenderProps }: IApplicationCategoryFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { rosterTypes, isFormModified, rosterChangeHandler } =
      useRosterStore();

    const [isLoadingGridUsers, setIsLoadingGridUsers] =
      useState<boolean>(false);
    const [isLoadingGridKeys, setIsLoadingGridKeys] = useState<boolean>(false);
    const [selectedRowsUsers, setSelectedRowsUsers] = useState<any[]>([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<any[]>([]);

    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const rosterType = valueGetter(nameOf("RosterType_ENUM"));
    const rosterFormObj = valueGetter("");

    const updateSaveTriggers = (trigger: RosterUpdateTriggers) => {
      const saveTriggers = valueGetter(nameOf("_SaveTriggers")) ?? [];
      if (!saveTriggers.includes(trigger)) {
        saveTriggers.push(trigger);
        onChange(nameOf("_SaveTriggers"), { value: saveTriggers });
      }
    };

    const handleAddUsers = (data: ICrmsDataUserGroupOrgForm) => {
      setIsLoadingGridUsers(true);
      if (data) {
        if (data?.Users?.length) {
          //Get list User from Dialog
          const users = [...data.Users];
          const userIds = users.map((user: ICrmsUsersPicker) => user.Id);
          let params: RosterHandlerRequest = {
            RosterFormAction: Svc_FormAction_Roster.PickUsers,
            Roster: rosterFormObj,
            EventArgs: userIds,
            IsFirstTimeLoad: false,
          };
          rosterChangeHandler(params, "Add user failed.");
        }
      }
      setIsLoadingGridUsers(false);
    };

    const handleRemoveUsers = async () => {
      setIsLoadingGridUsers(true);
      let idsSelected = selectedRowsUsers.map((value: RosterSiteUser) => {
        return value.SiteUser_ID;
      });

      const newDataGrid = valueGetter(nameOf("RosterSiteUsers")).filter(
        (item: RosterSiteUser) => !idsSelected.includes(item.SiteUser_ID)
      );

      onChange(nameOf("RosterSiteUsers"), { value: newDataGrid });

      // Update save triggers
      updateSaveTriggers(RosterUpdateTriggers.UpdateSiteUsers);

      setSelectedRowsUsers([]);

      setIsLoadingGridUsers(false);
    };

    const handleAddKeys = async (data: any) => {
      setIsLoadingGridKeys(true);
      if (data) {
        if (data?.length) {
          const keyIds = data.map((item: any) => item.ID);
          let params: RosterHandlerRequest = {
            RosterFormAction: Svc_FormAction_Roster.PickKeys,
            Roster: rosterFormObj,
            EventArgs: keyIds,
            IsFirstTimeLoad: false,
          };
          rosterChangeHandler(params, "Add key failed.");
        }
      }
      setIsLoadingGridKeys(false);
    };

    const handleRemoveKeys = async () => {
      setIsLoadingGridKeys(true);
      let idsSelected = selectedRowsKeys.map((value: any) => {
        return value.Key;
      });

      const newDataGrid = valueGetter(nameOf("RosterKeys")).filter(
        (item: any) => !idsSelected.includes(item.Key)
      );

      onChange(nameOf("RosterKeys"), { value: newDataGrid });

      // Update save triggers
      updateSaveTriggers(RosterUpdateTriggers.UpdateKeys);

      setSelectedRowsKeys([]);

      setIsLoadingGridKeys(false);
    };

    const handleSelectionUserChange = (e: any[]) => {
      setSelectedRowsUsers(e);
    };

    const handleSelectionKeyChange = (e: any[]) => {
      setSelectedRowsKeys(e);
    };

    const handleRosterTypeChange = (event: DropDownListChangeEvent) => {
      onChange(nameOf("RosterType_ENUM"), { value: event?.value?.Key });
      onChange(nameOf("RosterKeys"), { value: [] });
      // Update save triggers
      updateSaveTriggers(RosterUpdateTriggers.UpdateKeys);
    };

    useEffect(() => {
      if (isFormModified) {
        onChange(nameOf("Roster_ID"), {
          value: valueGetter(nameOf("Roster_ID")),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormModified]);

    return (
      <FormElement className="cc-application-category-form-elements">
        <CCLocalNotification ref={notificationRef} />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Roster name" isMandatory />
              <Field
                name={nameOf("RosterName")}
                component={CCInput}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Roster type" isMandatory />
              <Field
                name={nameOf("RosterType_ENUM")}
                component={CCDropDownList}
                dataItemKey="Key"
                textField="Value"
                data={rosterTypes}
                onChange={handleRosterTypeChange}
                validator={requiredValidator}
                value={getDropdownValue(
                  valueGetter(nameOf("RosterType_ENUM")),
                  rosterTypes,
                  "Key"
                )}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Users" />
              <CCGrid
                data={valueGetter(nameOf("RosterSiteUsers")) ?? []}
                selectableMode={"multiple"}
                columnFields={colRosterUsers}
                isLoading={isLoadingGridUsers}
                selectedRows={selectedRowsUsers}
                primaryField={nameOfUsers("SiteUser_ID")}
                onSelectionChange={handleSelectionUserChange}
                state={{
                  sort: [
                    { field: nameOfUsers("SiteUser_DisplayName"), dir: "asc" },
                  ],
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <CrmsPickUserGroupOrgButton
                      onSubmit={(dataItem: ICrmsDataUserGroupOrgForm) => {
                        if (dataItem) {
                          handleAddUsers(dataItem);
                        }
                      }}
                      isNotHavePanel
                      title="Pick Contact"
                      buttonToolTip="Pick contact"
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={
                        valueGetter(nameOf("RosterSiteUsers")).length === 0 ||
                        selectedRowsUsers.length === 0
                      }
                      onClick={handleRemoveUsers}
                    />
                  </div>
                }
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Keys" />
              <CCGrid
                data={valueGetter(nameOf("RosterKeys")) ?? []}
                selectableMode={"multiple"}
                columnFields={colRosterKeys}
                isLoading={isLoadingGridKeys}
                selectedRows={selectedRowsKeys}
                primaryField={nameOfKeys("Key")}
                state={{
                  sort: [{ field: nameOfKeys("Key_DisplayName"), dir: "asc" }],
                }}
                onSelectionChange={handleSelectionKeyChange}
                readOnly={
                  rosterType !== RosterType.CRMS_ServiceStandard &&
                  rosterType !== RosterType.Core_Org
                }
                toolbar={
                  <div className="cc-grid-tools-bar">
                    {rosterType === RosterType.CRMS_ServiceStandard && (
                      <CrmsPickKeysServiceStandardButton
                        onSubmit={(dataItem: any) => {
                          if (dataItem) {
                            handleAddKeys(dataItem);
                          }
                        }}
                      />
                    )}
                    {rosterType === RosterType.Core_Org && (
                      <CrmsPickKeysOrganisationUnitButton
                        onSubmit={(dataItem: any) => {
                          if (dataItem) {
                            handleAddKeys(dataItem);
                          }
                        }}
                      />
                    )}
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      disabled={
                        valueGetter(nameOf("RosterKeys")).length === 0 ||
                        selectedRowsKeys.length === 0
                      }
                      onClick={handleRemoveKeys}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
