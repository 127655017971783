import { eventEmitter } from "@/App";
import { odataCoreGetContacts } from "@app/core/components/common/utils";
import { deleteContactRelationship } from "@app/core/contacts/components/contact-accordion/api";
import { colContactAccordion } from "@app/core/contacts/components/contact-accordion/config";
import { IContactODataItem } from "@app/core/contacts/components/contact-accordion/model";
import { ContactTabEventType } from "@app/core/contacts/components/contact-tab/config";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { isSuccessResponse } from "@common/apis/util";

import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { customLogger } from "@common/utils/logger";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, remove } from "lodash";
import React, { useState } from "react";

const nameOf = nameOfFactory<IContactODataItem>();
interface IContactAccordionProps {
  id?: number;
  recordType: RECORDTYPE;
}

export const ContactAccordion = ({
  id,
  recordType,
}: IContactAccordionProps) => {
  const { pushNotification } = useCCAppNotificationStore();
  const { isDisabled } = useTabTableStore();
  const { settings } = useCommonCoreStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<IContactODataItem[]>(
    []
  );
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const isEnableContactAlertColumn = getBoolValueSetting(
    settings[
      ECorporateSettingsField.Global_ContactManager_EnableContactAlertColumn
    ]
  );
  const isEnableContactHomePhone = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_ContactsTabTable_ShowHomePhone]
  );
  const arrExclude: any = [];
  if (!isEnableContactHomePhone) {
    arrExclude.push(nameOf("HomePhone"));
  }
  if (!isEnableContactAlertColumn) {
    arrExclude.push(nameOf("ContactAlert"));
  }
  const colContactAccordionOptional = remove(
    cloneDeep(colContactAccordion),
    (item) => {
      return !arrExclude.includes(item.field);
    }
  );

  const handleOnDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    setNotifications([]);
    const response = await deleteContactRelationship(
      gridSelectedRows[0].ID,
      data.Reason
    );
    customLogger(
      "Core contacts, Contact accordion, deleteContactRelationship"
    ).info(response?.data);
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        setIsShowDialog(false);
        pushNotification({
          type: "success",
          title: "Contact relationship deleted successfully",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(ContactTabEventType.RefreshData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        eventEmitter.emit(TabTableEventType.RefreshData);
        setGridSelectedRows([]);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Delete contact relationship failed",
            description: response.data?.Errors,
            type: "error",
          },
        ]);
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Delete contact relationship failed",
          description: response.error,
          type: "error",
        },
      ]);
    }
    setIsDeleting(false);
  };
  return (
    <>
      {isShowDialog && (
        <ConfirmDelete
          notifications={notifications}
          onClose={() => setIsShowDialog(false)}
          handleSubmit={handleOnDelete}
          isDeleting={isDeleting}
          contentDelete={"Contact Relation"}
          header={"Confirm Deletion"}
        />
      )}
      <CCGrid
        canSelectField={nameOf("IsAdditionalContactType")}
        className="cc-contact-accordion"
        selectableMode="single"
        dataUrl={odataCoreGetContacts(id, recordType)}
        primaryField={nameOf("ID")}
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: IContactODataItem[]) => {
          setGridSelectedRows([...dataItem]);
        }}
        state={{
          sort: [
            { field: nameOf("IsAdditional"), dir: "asc" },
            { field: nameOf("CreatedDate"), dir: "desc" },
          ],
        }}
        columnFields={colContactAccordionOptional}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={isDisabled ? isDisabled : !gridSelectedRows.length}
              onClick={() => setIsShowDialog(true)}
            />
          </div>
        }
      />
    </>
  );
};
