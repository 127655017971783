import {
  colResponseReportLetter,
  nameOfDocument,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/ppr/planning-permit-referrals/config";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

export interface IDocumentLetter {
  fieldInfo: SettingField;
  isEditing?: boolean;
  handleAddButton: () => void;
  handleDeleteButton: () => void;
  dataList: any[];
  listSelected: any[];
  onSelectionChange: (dataItem: any[]) => void;
}

export const DocumentLetter = (props: IDocumentLetter) => {
  const {
    fieldInfo,
    isEditing = false,
    handleAddButton,
    handleDeleteButton,
    dataList,
    listSelected,
    onSelectionChange,
  } = props;

  return (
    <div className="cc-field">
      <label className="cc-label">{fieldInfo?.Title}</label>
      <CCGrid
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fa fa-plus"
              title={`Add ${fieldInfo?.Title}`}
              onClick={handleAddButton}
              disabled={!isEditing}
            />
            <Button
              type="button"
              iconClass="fas fa-minus"
              title={`Remove ${fieldInfo?.Title}`}
              disabled={!(listSelected?.length === 1 && isEditing)}
              onClick={handleDeleteButton}
            />
          </div>
        }
        columnFields={colResponseReportLetter}
        primaryField={nameOfDocument("Document_ID")}
        data={dataList}
        selectableMode="multiple"
        selectedRows={listSelected}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};
