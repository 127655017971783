import { DTO_LOV } from "@common/models/odataResponse";

export interface IEditAddAddressLOVs {
  Address_Side_of_Street?: DTO_LOV[];
  Address_State?: DTO_LOV[];
  Address_FloorType?: DTO_LOV[];
  Address_UnitType?: DTO_LOV[];
  Address_Type?: DTO_LOV[];
  Country?: DTO_LOV[];
}

export interface INewAddressInitValues {
  Country: string;
  State: string;
}

export interface Predictive2Data {
  data: NSWPointData;
  requestTimestamp: number;
  requestDate: string;
}

export interface NSWPointData {
  dataset: string;
  geo: Geo;
  addressDetails: NSWPointAddressDetails;
  addressId: string;
  addressRecordType: string;
  properties: Properties;
  asgsMain: AsgsMain;
  commonwealthElectorate: CommonwealthElectorate;
  localGovernmentArea: LocalGovernmentArea;
  stateElectorate: StateElectorate[];
}

export interface Geo {
  geoDatumCode: string;
  geoFeature: string;
  geometry: Geometry;
}

export interface Geometry {
  coordinates: number[];
  type: string;
}

export interface AsgsMain {}

export interface CommonwealthElectorate {
  commElectoralName: string;
  commElectoralPid: string;
}

export interface LocalGovernmentArea {
  lgaName: string;
  lgaPid: string;
  lgaShortName: string;
}

export interface StateElectorate {
  stateElectoralPid: string;
  stateElectoralName: string;
  stateElectoralType: string;
  stateElectoralClassCode: string;
}

export interface NSWPointAddressDetails {
  streetNumber1: string;
  streetName: string;
  streetType: string;
  streetSuffix: string;
  localityName: string;
  stateTerritory: string;
  postcode: string;
  siteName: string;
  cadastralIdentifier: string;
  formattedAddress: string;
  streetTypeDescription: string;
  complexLevelNumber: string;
  complexLevelType: string;
}

export interface Properties {
  matchQualityPercentage: string;
  matchCode: string;
  matchCertainty: string;
  geoType: string;
}

export enum EStatusValidation {
  OK = "OK",
  SKIP = "SKIP",
  CANCEL = "CANCEL",
  ClOSE = "ClOSE",
}
