import { DTO_PendingInterest } from "@app/products/property/assessments/[id]/components/child-screens/interest/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_PendingInterest>();

export const colPendingInterestChild: IColumnFields[] = [
  {
    field: nameOf("EnableInterest"),
    title: "Enable Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ApplyInterest"),
    title: "Apply Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "CalculatedInterest",
    title: "Calculated Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestableBalance"),
    title: "Interestable Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestRate"),
    title: "Interest Rate",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("FromInterestDate"),
    title: "From Interest Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ToInterestDate"),
    title: "To Interest Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DaysInterest"),
    title: "Days Interest",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LevyName"),
    title: "Levy",
  },
  {
    field: nameOf("GraceDays"),
    title: "Grace Days",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IsRaiseInterestOnInterest"),
    title: "Interest on Interest",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("MinimumInterestRaised"),
    title: "Minimum Interest Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
