import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSKnowledgeBaseTopicsBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Knowledge Base`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Knowledge Base - Topics`;
  },
};
