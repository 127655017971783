import { ChatSidebar } from "@app/core/chat/ask-ai/reference-sidebar/_index";
import { useAskAIServiceStore } from "@app/core/chat/ask-ai/store";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Journal } from "@app/core/journal/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { CRMSKnowledgeBaseContactTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/contact/_index";
import { CRMSKnowledgeBaseDetailTab } from "@app/products/crms/knowledge-base/[id]/components/forms/components/reference-sidebar/details/_index";
import { CRMS_KB_BASE_ROUTE } from "@app/products/crms/knowledge-base/[id]/constant";
import { NewArticleButton } from "@app/products/crms/knowledge-base/components/action-bar/buttons/new/_index";
import { colDueForReview } from "@app/products/crms/knowledge-base/due-for-review/config";
import { CRMSKnowledgeBaseDueBookmark } from "@app/products/crms/knowledge-base/due-for-review/util";
import { knowledgeBaseRoute } from "@app/products/crms/knowledge-base/route";
import { CRMSKnowledgeBaseBookmark } from "@app/products/crms/knowledge-base/util";
import { crmsRoute } from "@app/products/crms/route";
import { loadAllCrms } from "@app/products/crms/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={knowledgeBaseRoute.path} />,
    ],
    centerComponents: [
      <NewArticleButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_KBAlert}
        dataSetFilter={MAILMERGEDATASET.CUSTOMERSERVICE_KBPage}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CUSTOMERSERVICE_KBAlert}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_KB_BASE_ROUTE}
        //TODO: Update enum later
        productType={PRODUCT_TYPE.CRMS_HardRubbish}
        recordType={RECORDTYPE.CUSTOMERSERVICE_KBAlert}
        detail={CRMSKnowledgeBaseBookmark.getBookmarkDetail}
        displayName={CRMSKnowledgeBaseBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSKnowledgeBaseDueBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={CRMSKnowledgeBaseDueBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CRMSKnowledgeBaseDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <CRMSKnowledgeBaseContactTab />,
      },
      {
        title: "History",
        component: (
          <Journal id={13347} recordType={RECORDTYPE.CUSTOMERSERVICE_KBAlert} />
        ),
      },
      {
        title: "Ask AI",
        isVisible: useAskAIServiceStore().isShowAskAI,
        component: (
          <ChatSidebar productId={PRODUCT_TYPE_NUMBER.CustomerService} />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colDueForReview}
      primaryField={"ID"}
      data={loadAllCrms() || []}
    />
  );
};
