import { CORE_GET_ACTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { colActionTypePicker } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/config";
import { Svc_ActionType } from "@common/pages/actions/[id]/model";
import { mapEnum, nameOfFactory } from "@common/utils/common";

const nameOf = nameOfFactory<Svc_ActionType>();
export const getOptionInputPickerActionType = (
  productType?: PRODUCT_TYPE,
  serviceStandardId?: number
): IOptionInputPicker => {
  let productTypeParam = "";
  if (productType) {
    productTypeParam = `productType=["${productType}"]`;
  }
  return {
    dialog: {
      titleHeader: "Pick Action Type",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOf("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOf("Name"),
        },
      ],
    },
    grid: {
      columnFields: colActionTypePicker,
      primaryField: "ID",
      dataUrl: serviceStandardId
        ? `odata/crms/internal/servicestandard/GetPickServiceStandardActionType(serviceStandardId=${serviceStandardId},productType=${mapEnum(
            productType,
            PRODUCT_TYPE_NUMBER
          )},isActive=true)`
        : `${CORE_GET_ACTION_TYPES_ODATA}(${productTypeParam}flagUserSelection=true,showRegulatory=true)`,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOf("Name"),
            dir: "asc",
          },
        ],
      },
    },
  };
};

export const convertBreakLineString = (html: string) => {
  return html?.replace(/^[^<>]*</, (m: string) => m.replace(/\r\n/g, "<br/>"));
};
export const removeHTMLTags = (str: string) => {
  let div = document.createElement("div");
  div.innerHTML = str;
  let text = div.textContent || div.innerText || "";
  return text;
};
