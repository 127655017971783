import { DTO_Notice } from "@app/products/property/assessments/[id]/components/child-screens/notices/model";
import { useAssessmentNoticesStore } from "@app/products/property/assessments/[id]/components/child-screens/notices/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<DTO_Notice>();
export const Notices = observer(() => {
  const {
    isLoading,
    loadConfigs,
    responseLoadError,
    reLoadApiConfig,
    columnAssessmentNotice,
    resetStore,
  } = useAssessmentNoticesStore();
  const { assessmentId } = useAssessmentStore();

  useEffectOnce(() => {
    loadConfigs();
    return () => resetStore();
  });

  //show Loading
  if (isLoading) return <Loading isLoading />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig();
        }}
      />
    );

  return (
    <div className="cc-property-notices">
      <CCGrid
        isLoading={isLoading}
        dataUrl={`/odata/property/internal/assessmentnoticesregister/${assessmentId}?$count=true&`}
        columnFields={columnAssessmentNotice}
        primaryField={nameOf("Notice_Id")}
      />
    </div>
  );
});
