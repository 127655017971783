import { ICCViewConfiguration } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getViewConfigurations = async (
  viewConfigurationId: number
): Promise<APIResponse<ICCViewConfiguration | undefined>> => {
  try {
    const response = await CoreAPIService.getClient().get<ICCViewConfiguration>(
      `/api/property/internal/common/viewconfiguration/${viewConfigurationId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
