import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { DynamicQuestionListsForm } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/_index";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewDynamicQuestionListsComponent = observer(() => {
  const { id } = useParams<{ id: string }>();

  const {
    dynamicQuestionLists,
    isLoading,
    responseLoadError,
    loadDynamicQuestionLists,
    onSubmit,
  } = useDynamicQuestionListsStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Dynamic List"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDynamicQuestionLists(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New Checklist Question" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={ActionSubmitActions.Save}
                onClick={onSubmit}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {dynamicQuestionLists && <DynamicQuestionListsForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewDynamicQuestionListsComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.CustomerService },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.Core_Form_ManageDynamicQuestionList,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewDynamicQuestionLists =
  renderWithPermissionsWrapper(renderProps);
