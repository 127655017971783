import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { HMNotInspectedView } from "@app/products/hm/insp-schedule/model";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";

const nameOfPremisesNotInspView = nameOfFactory<HMNotInspectedView>();
export const colHMInspScheduleNotInspected = [
  {
    field: nameOfPremisesNotInspView("TradingName"),
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: HMNotInspectedView) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconColumnViewConfigAlert,
  {
    field: nameOfPremisesNotInspView("RegNo"),
    title: "Registration Number",
  },
  {
    field: nameOfPremisesNotInspView("Address"),
    title: "Address",
  },
  {
    field: nameOfPremisesNotInspView("Area"),
    title: "Area",
  },
  {
    field: nameOfPremisesNotInspView("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOfPremisesNotInspView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfPremisesNotInspView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfPremisesNotInspView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfPremisesNotInspView("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesNotInspView("NextInspection"),
    title: "Next Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesNotInspView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfPremisesNotInspView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfPremisesNotInspView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfPremisesNotInspView("Email"),
    title: "Email",
  },
  {
    field: nameOfPremisesNotInspView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesNotInspView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesNotInspView("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
