import { SwitchValueOptions } from "@app/products/animals/system-admin/settings/_id/main/model";
import { routeStateAnimalsConfig } from "@app/products/animals/system-admin/settings/_id/main/routeState";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";
import { NoData } from "@components/no-data/NoData";
import { FormRenderProps } from "@progress/kendo-react-form";
import { filter } from "lodash";
import React, { memo } from "react";

export interface IAnimalsComponentFormProps {
  formRenderProps: FormRenderProps;
  dataSetting: any;
}

export const AnimalsComponentSettingForm = memo(
  ({ formRenderProps, dataSetting }: IAnimalsComponentFormProps) => {
    const handleSwitchRouteLoadComponent = (
      dataSetting: any,
      routeStateAnimalsConfig: IRouteStateForm[],
      formRenderProps: FormRenderProps
    ) => {
      let componentActive = <NoData vertical />;
      const settingActive = filter(routeStateAnimalsConfig, {
        name: dataSetting?.Name,
      });

      if (settingActive.length) {
        const ComponentActive = settingActive[0]?.component;
        componentActive = (
          <ComponentActive
            formRenderProps={formRenderProps}
            dataSetting={dataSetting}
          />
        );
      }
      return componentActive;
    };

    return handleSwitchRouteLoadComponent(
      dataSetting,
      routeStateAnimalsConfig,
      formRenderProps
    );
  }
);

export const generalRegistrationshowTabsConfig: ILookupItem[] = [
  {
    Id: "True",
    Name: "Documents",
  },
  {
    Id: "False",
    Name: "Records",
  },
];

export const switchOptionsValueNumberString: SwitchValueOptions = {
  TRUE: "1",
  FALSE: "0",
};

export const switchOptionsValueBooleanString: SwitchValueOptions = {
  TRUE: "True",
  FALSE: "False",
};
