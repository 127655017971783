import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButton } from "@app/core/mail-merge/buttons/_index";
import { bookingsRoute } from "@app/products/event-management/bookings/route";
import { EventManagementActionBarCalendarFilter } from "@app/products/event-management/components/action-bar/calendar-filter/_index";
import { EventManagementActionBarNavDropdown } from "@app/products/event-management/components/action-bar/nav-dropdown/_index";
import { NewEventManagementButton } from "@app/products/event-management/components/buttons/new-event-management/_index";
import { eventManagementRoute } from "@app/products/event-management/route";
import {
  displayDate,
  sampleCalendarData,
} from "@app/products/event-management/util";
import ActionBarStoreService from "@common/apis/actionbar.service";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { guid } from "@progress/kendo-react-common";
import { Popup } from "@progress/kendo-react-popup";
import {
  AgendaView,
  DayView,
  MonthView,
  Scheduler,
  SchedulerItem,
  SchedulerItemProps,
  WeekView,
} from "@progress/kendo-react-scheduler";
import { observer } from "mobx-react-lite";
import * as React from "react";
import "./_index.scss";

export const EventManagementCalendarForm = () => {
  useCCListViewActionBar({
    title: eventManagementRoute.name,
    leftComponents: [
      <EventManagementActionBarNavDropdown category={bookingsRoute.path} />,
      <EventManagementActionBarCalendarFilter />,
    ],
    centerComponents: [
      <NewEventManagementButton />,
      <MailMergeButton />,
      <CommunicationButtonListView />,
    ],
  });

  const { selectedCalendarFilter } = React.useContext(ActionBarStoreService);

  const filterColumn = [
    { text: "Daily Meeting", value: "Meeting", color: "#74b9ff" },
    { text: "Coffee Show", value: "Coffee", color: "#a29bfe" },
    { text: "English Club", value: "English", color: "#e17055" },
    { text: "Oncall Interview", value: "Oncall", color: "#00b894" },
    { text: "Offline Interview", value: "Offline", color: "#636e72" },
  ];

  const [data, setData] = React.useState(sampleCalendarData);

  React.useEffect(() => {
    if (selectedCalendarFilter.length === 0) {
      setData(sampleCalendarData);
    } else {
      const arrayFilters: any[] = selectedCalendarFilter.map(
        (item: any) => item.key
      );
      setData(
        sampleCalendarData.filter((o) => {
          return arrayFilters.includes(o.type);
        })
      );
    }
  }, [selectedCalendarFilter]);

  const handleDataChange = ({ created, updated, deleted }: any) => {
    setData((old) =>
      old
        .filter(
          (item) =>
            deleted.find((current: any) => current.id === item.id) === undefined
        )
        .map(
          (item) =>
            updated.find((current: any) => current.id === item.id) || item
        )
        .concat(
          created.map((item: any) => Object.assign({}, item, { id: guid() }))
        )
    );
  };

  const CustomSchedulerItem = (props: SchedulerItemProps) => {
    const ref = React.useRef<any>(null);
    const [show, setShow] = React.useState(false);

    const handleMouseOver = React.useCallback(
      (event) => {
        setShow(true);
        // Call the default `onFocus` handler
        if (props.onMouseOver) {
          props.onMouseOver(event);
        }
      },
      [props]
    );

    const handleMouseLeave = React.useCallback(
      (event) => {
        setShow(false);

        // Call the default `onFocus` handler
        if (props.onMouseOut) {
          props.onMouseOut(event);
        }
      },
      [props]
    );

    return (
      <React.Fragment>
        <SchedulerItem
          {...props}
          className={"scheduler-item"}
          ref={ref}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        />
        <Popup
          show={show}
          anchorAlign={{
            horizontal: "left",
            vertical: "bottom",
          }}
          popupAlign={{
            horizontal: "left",
            vertical: "top",
          }}
          id="scheduler-popup"
          anchor={ref.current && ref.current.element}
        >
          <div className="calendar-popup">
            <div>
              <h6>{props.title}</h6>
              <div>
                <b>Start:</b>{" "}
                {formatDateByKendo(props.start, DATETIME_FORMAT.DATETIME)}
              </div>
              <div>
                <b>End:</b>{" "}
                {formatDateByKendo(props.end, DATETIME_FORMAT.DATETIME)}
              </div>
              <hr />
              <div>
                <b>Description:</b> {props.description}
              </div>
            </div>
          </div>
        </Popup>
      </React.Fragment>
    );
  };
  return (
    <div className="cc-event-management-all-calendar">
      <Scheduler
        editable={false}
        data={data}
        defaultDate={displayDate}
        onDataChange={handleDataChange}
        className="cc-field"
        item={CustomSchedulerItem}
        resources={[
          {
            name: "Meeting Type",
            data: [...filterColumn],
            field: "type",
            valueField: "value",
            textField: "text",
            colorField: "color",
          },
        ]}
        onViewChange={(e: any) => {
          console.debug("Calendar onViewChange", e);
        }}
        defaultView="month"
      >
        <DayView />
        <WeekView />
        <MonthView />
        <AgendaView />
      </Scheduler>
    </div>
  );
};

export default observer(EventManagementCalendarForm);
