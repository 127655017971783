import { useCertificateDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/certificate-details/store";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { addDays } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
export const CertificateDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { certificateDetailStepLOVs } = useCertificateDetailStepStore();
    const requestSourceLovs = certificateDetailStepLOVs?.RequestSource ?? [];

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Description")}
              component={CCTextArea}
              placeholder="Description"
              readOnly={options?.isReadOnly}
              rows="3"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Comments</label>
            <Field
              name={nameOf("Comments")}
              component={CCTextArea}
              placeholder="Comments"
              readOnly={options?.isReadOnly}
              rows="3"
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Request source</label>
            <Field
              name={nameOf("RequestSourceId")}
              component={CCSearchComboBox}
              data={requestSourceLovs}
              textField="Name"
              dataItemKey="Code"
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Originated on</label>
            <Field
              name={nameOf("OriginatedOn")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              onChange={(event: DateTimePickerChangeEvent) => {
                if (event?.value) {
                  //calculate base on (ExpectedCompletionDays + OriginatedOn)
                  const expectedCompletionDays =
                    getFieldValue("ExpectedCompletionDays") ?? 0;
                  onChange(nameOf("_option.ExceptedCompletion"), {
                    value: addDays(event?.value, expectedCompletionDays),
                  });
                }
                onChange(nameOf("OriginatedOn"), {
                  value: event?.value,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Excepted completion</label>
            <Field
              name={nameOf("_option.ExceptedCompletion")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled //alway read only
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Date required</label>
            <Field
              name={nameOf("DateRequired")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Receipt number</label>
            <Field
              name={nameOf("ReceiptNumber")}
              component={CCNumericTextBox}
              readOnly={options?.isReadOnly}
              min={0}
              format={NUMBER_FORMAT.NUMBER2}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Receipt amount</label>
            <Field
              name={nameOf("ReceiptAmount")}
              component={CCCurrencyInput}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Is urgent</label>
            <Field
              name={nameOf("IsUrgent")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
              checked={getFieldValue("IsUrgent")}
            />
          </div>
        </div>
      </section>
    );
  }
);
