import {
  DTO_ConcessionCard,
  DTO_ConcessionCard_VerificationHistory,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/concession-cards/model";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOfConcessionCardGrid = nameOfFactory<DTO_ConcessionCard>();
export const colContactConcessionCardsHeld: IColumnFields[] = [
  { field: nameOfConcessionCardGrid("CC_Name"), title: "Concession Card" },
  { field: nameOfConcessionCardGrid("ECC_Card_Number"), title: "Card Number" },
  {
    field: nameOfConcessionCardGrid("ECC_Is_Active"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfConcessionCardGrid("ECC_Date_Presented"),
    title: "Date Presented",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfConcessionCardGrid("ECC_Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
];
const nameOfContactVerificationHistoryGrid =
  nameOfFactory<DTO_ConcessionCard_VerificationHistory>();
export const colContactVerificationHistory: IColumnFields[] = [
  {
    field: nameOfContactVerificationHistoryGrid("Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfContactVerificationHistoryGrid("Card_Number"),
    title: "Card Number",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Is_Deceased"),
    title: "Is Deceased?",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Has_Pension_Concession_Card"),
    title: "Has Pension Concession Card?",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Has_Health_Concession_Card"),
    title: "Has Health Concession Card?",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Has_Seniors_Health_Card"),
    title: "Has Seniors Health Card?",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Is_War_Widow"),
    title: "Is War Widow?",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Gold_Card"),
    title: "Gold Card",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Prisoner_Of_War"),
    title: "Prisoner of War",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Disability"),
    title: "Disability",
  },
  {
    field: nameOfContactVerificationHistoryGrid(
      "Special_Disability_Assessment"
    ),
    title: "Special Disability Assessment",
  },
  {
    field: nameOfContactVerificationHistoryGrid("FirstName"),
    title: "First Name",
  },
  {
    field: nameOfContactVerificationHistoryGrid("MiddleName"),
    title: "Middle Name",
  },
  { field: nameOfContactVerificationHistoryGrid("SurName"), title: "Surname" },
  {
    field: nameOfContactVerificationHistoryGrid("Date_Of_Birth"),
    title: "Date of Birth",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOfContactVerificationHistoryGrid("Address"), title: "Address" },
  {
    field: nameOfContactVerificationHistoryGrid("Postcode_Match"),
    title: "Did Postcode Match",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Match_Description"),
    title: "Match Description",
  },
  {
    field: nameOfContactVerificationHistoryGrid("Request_Date"),
    title: "Request Date",
    format: DATE_FORMAT.DATE,
  },
];
