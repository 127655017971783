import { ILookupItem } from "@app/products/property/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const PublicPortalLiveMergeTemplatesForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();

    const fieldEnableMergeReceipt = getConfigDataField(
      ECorporateSettingsField.TP_EPlanningReceiptMerge_Enable
    );
    const fieldPortalReceiptTemplate = getConfigDataField(
      ECorporateSettingsField.TP_EPlanningReceiptMerge_TemplateID
    );
    const fieldEnableMergeInvoice = getConfigDataField(
      ECorporateSettingsField.TP_EPlanningInvoiceMerge_Enable
    );
    const fieldPortalInvoiceTemplate = getConfigDataField(
      ECorporateSettingsField.TP_EPlanningInvoiceMerge_TemplateID
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {fieldEnableMergeReceipt && (
            <div className="cc-field">
              <CCLabel
                title={fieldEnableMergeReceipt?.Title ?? ""}
                isMandatory={fieldEnableMergeReceipt.IsMandatory}
              />
              <Field
                name={fieldEnableMergeReceipt?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldEnableMergeReceipt?.FieldName
                )}
              />
            </div>
          )}
          {fieldPortalReceiptTemplate && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalReceiptTemplate?.Title ?? ""}
                isMandatory={fieldPortalReceiptTemplate.IsMandatory}
              />
              <Field
                name={fieldPortalReceiptTemplate?.FieldName}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={!isEditing}
                data={fieldPortalReceiptTemplate?.LookupItems || []}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                validator={
                  fieldPortalReceiptTemplate.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldEnableMergeInvoice && (
            <div className="cc-field">
              <CCLabel
                title={fieldEnableMergeInvoice?.Title ?? ""}
                isMandatory={fieldEnableMergeInvoice.IsMandatory}
              />
              <Field
                name={fieldEnableMergeInvoice?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldEnableMergeInvoice?.FieldName
                )}
              />
            </div>
          )}
          {fieldPortalInvoiceTemplate && (
            <div className="cc-field">
              <CCLabel
                title={fieldPortalInvoiceTemplate?.Title ?? ""}
                isMandatory={fieldPortalInvoiceTemplate.IsMandatory}
              />
              <Field
                name={fieldPortalInvoiceTemplate?.FieldName}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={!isEditing}
                data={fieldPortalInvoiceTemplate?.LookupItems || []}
                textField={nameOfLookupItem("Name")}
                dataItemKey={nameOfLookupItem("Id")}
                validator={
                  fieldPortalInvoiceTemplate.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
