import { getViewConfigurations } from "@app/products/property/api";
import { colAssociationsDeferredDuty } from "@app/products/property/components/associations/components/deferred-duty/config";
import { DTO_AssociatedItem_DeferredDuty } from "@app/products/property/components/associations/components/deferred-duty/model";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface IAssociationsDeferredDutyProps {
  associationsDeferredDutyInfo: DTO_AssociatedItem_DeferredDuty[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_DeferredDuty>();
export const AssociationsDeferredDuty = ({
  associationsDeferredDutyInfo,
}: IAssociationsDeferredDutyProps) => {
  const [viewconfiguration, setViewconfiguration] = useState<ICCViewColumn[]>(
    []
  );
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await getViewConfigurations(
      ViewConfiguration.Associations_DeferredDuty
    );
    if (isSuccessResponse(response) && response?.data) {
      setViewconfiguration(response.data.ColumnDefinitions?.Columns);
    } else {
      const errorResponse = {
        status: response.status,
        error: response.error,
      };
      setResponseLoadError(errorResponse);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;

  return responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadViewConfig();
      }}
    />
  ) : (
    <CCGrid
      className="cc-deferred-duty-grid"
      data={associationsDeferredDutyInfo || []}
      columnFields={processDynamicColumns(
        colAssociationsDeferredDuty,
        viewconfiguration
      )}
      primaryField={nameOf("AccountID")}
    />
  );
};
