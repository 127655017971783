import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { DTO_Workflow_ModifyGIS } from "@app/products/property/parcels/[id]/components/forms/existed/components/dialogs/gis-reference/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";

export const postNewWorkflowParcelGISReference = async (
  parcelId: number
): Promise<APIResponse<DTO_Workflow_ModifyGIS>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifygis/new`,
      { ParcelId: parcelId }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowParcelGISReferenceByWorkflowDraftId = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyGIS>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/modifygis/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as APIResponse<ResponsePacket>;
    return {
      ...errorResponse,
      error: (errorResponse.data as ResponsePacket)?.Errors,
    };
  }
};

export const postWorkflowParcelGISReference = async (
  mode: WorkflowProcessMode,
  req: DTO_Workflow_ModifyGIS
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyGIS>>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/modifygis/process/${mode}`,
      req
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
