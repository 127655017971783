import { DeleteGISReferenceConfirmDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/delete-gis-reference/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { gisReferenceDataGridToArray } from "@app/products/property/registers/[id]/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";

export const DeleteGISReferenceButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    selectedGISReference,
    removeGISReference,
    isEmptySelectedGISReference,
  } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handleOnConfirmYes = () => {
    removeGISReference(gisReferenceDataGridToArray(selectedGISReference));
    pushNotification({
      title: "GIS reference deleted successfully.",
      type: "success",
    });
    setShowDialog(false);
  };

  return (
    <Fragment>
      <CCNavButton
        title={"Delete"}
        disabled={isEmptySelectedGISReference}
        onClick={() => setShowDialog(true)}
      />
      {showDialog && (
        <DeleteGISReferenceConfirmDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onConfirmYes={handleOnConfirmYes}
        />
      )}
    </Fragment>
  );
});
