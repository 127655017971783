import {
  configEnumNameFilter,
  getListsOfType,
  isIncludeListNull,
} from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-grid-column-menu-filter/config";
import { CustomFilterRender } from "@components/cc-grid/components/grid-column-menus/column-menu-filter/components/custom-grid-column-menu-filter/custom-filter-render";
import { IColumnFields } from "@components/cc-grid/model";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { GridColumnMenuProps } from "@progress/kendo-react-grid";
import {
  GridColumnMenuFilterProps,
  GridColumnMenuFilterStateProps,
} from "@progress/kendo-react-grid/dist/npm/columnMenu/GridColumnMenuFilter";
import { cloneDeep, isNil } from "lodash";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
export interface ICustomGridColumnMenuFilter
  extends GridColumnMenuFilterProps,
    GridColumnMenuProps,
    Omit<GridColumnMenuFilterStateProps, "expanded"> {
  columnConfig: IColumnFields;
}
export const CustomGridColumnMenuFilter = (props: any) => {
  const { column, filter, hideSecondFilter, onCloseMenu, onFilterChange } =
    props;
  const { filter: filterType } = column;
  const [isValid, setIsValid] = useState(false);
  const formRenderPropsRef = useRef<FormRenderProps>();
  const refButton = useRef<any>();
  useEffectOnce(() => {
    const queryForm = document.querySelector(
      ".kendo-grid-filter-menu-container > form"
    );
    const handler = (e: any) => {
      if (e.which === 13) {
        e.preventDefault();
        e.stopPropagation();
        if (refButton?.current?.props) {
          refButton?.current?.props?.onClick();
        }
        onCloseMenu();
      }
    };
    queryForm?.addEventListener("keydown", handler);
    return () => {
      queryForm?.removeEventListener("keydown", handler);
    };
  });

  const onFilterButtonChange = (value: any, event: any) => {
    const previousFirstFilter = value?.initialFilterFirst;
    const previousSecondFilter = value?.initialFilterSecond;
    const previousLogicBetween = value?.initialLogicBetweenFilter;
    const cloneValue = cloneDeep(value);
    let newFilterValue: any = { logic: "and", filters: [] };
    let submitValue: any = { logic: "and", filters: [] };
    let listFilters = [];
    const valueFirst =
      cloneValue[`${column?.field}_operator-1`] ?? previousFirstFilter?.value;
    const logicBetween = cloneValue["Between-Filter"] ?? previousLogicBetween;
    const valueSecond =
      cloneValue[`${column?.field}_operator-2`] ?? previousSecondFilter?.value;
    const valueOperatorFirst =
      cloneValue[`${column?.field}_operator-1_logic`] ??
      previousFirstFilter?.operator ??
      getListsOfType(filterType)?.[0]?.operator;
    const valueOperatorSecond =
      cloneValue[`${column?.field}_operator-2_logic`] ??
      previousSecondFilter?.operator ??
      getListsOfType(filterType)?.[0]?.operator;
    const isHideSecondFilter =
      (filterType === "date" && isIncludeListNull(valueOperatorFirst)) ||
      hideSecondFilter;
    if (filterType === "boolean" && typeof valueOperatorFirst === "boolean") {
      listFilters.push({
        field: `${column?.field}`,
        operator: "eq",
        value:
          cloneValue[`${column?.field}_operator-1_logic`] ??
          previousFirstFilter?.operator ??
          getListsOfType(filterType)?.[0]?.operator,
      });
    } else if (isIncludeListNull(valueOperatorFirst)) {
      listFilters.push({
        field: `${column?.field}`,
        operator: valueOperatorFirst,
        value: null,
      });
    } else if (isIncludeListNull(valueOperatorSecond)) {
      listFilters.push({
        field: `${column?.field}`,
        operator: valueOperatorSecond,
        value: null,
      });
    } else if (filterType !== "boolean") {
      listFilters.push({
        field: `${column?.field}`,
        operator: valueOperatorFirst,
        value: valueFirst,
      });
    }

    if (!isHideSecondFilter) {
      if (logicBetween)
        newFilterValue = {
          ...newFilterValue,
          logic: logicBetween,
        };
      //This case just use for type: Date => Enhance if using later
      if (valueSecond) {
        listFilters.push({
          field: `${column?.field}`,
          operator: valueOperatorSecond,
          value: valueSecond,
        });
      }
    }
    newFilterValue = { ...newFilterValue, filters: listFilters };
    const filterState = cloneDeep(filter) ?? [];
    const previousFilters = filterState?.filters ?? [];

    const indexCurrentField = previousFilters?.findIndex((item: any) => {
      return item?.filters?.[0]?.field.toString() === column.field.toString();
    });
    if (indexCurrentField !== -1) {
      if (isHideSecondFilter) {
        previousFilters.splice(indexCurrentField, 1, newFilterValue);
        submitValue = {
          ...submitValue,
          filters: previousFilters,
        };
      } else {
        previousFilters.splice(indexCurrentField, 1);
        const fieldValue = {
          logic: logicBetween ?? "and",
          filters: listFilters,
        };
        submitValue = {
          ...submitValue,
          filters: [...previousFilters, fieldValue],
        };
      }
    } else {
      const fieldValue = { logic: logicBetween ?? "and", filters: listFilters };
      submitValue = {
        ...submitValue,
        filters: [...previousFilters, fieldValue],
      };
    }
    onFilterChange(submitValue, event);
    onCloseMenu();
  };

  const handleClear = (event: any) => {
    const filterState = cloneDeep(filter) ?? [];
    const previousFilters = filterState?.filters;
    const indexCurrentField = previousFilters?.findIndex((item: any) => {
      return item?.filters?.[0]?.field.toString() === column.field.toString();
    });
    if (indexCurrentField !== -1) {
      previousFilters.splice(indexCurrentField, 1);
      onFilterChange(filterState, event);
    }
    onCloseMenu();
  };

  return (
    <div className="cc-custom-grid-column-menu-filter">
      <div className="k-columnmenu-item ">
        <span className="k-icon k-i-filter" />
        Filter
      </div>
      <div className="k-columnmenu-item-content">
        <div
          className="k-animation-container-relative"
          style={{ position: "relative", display: "block" }}
        >
          <div
            className="k-child-animation-container"
            style={{ transitionDelay: "0ms" }}
          >
            <div className="kendo-grid-filter-menu-container">
              <Form
                render={(formRenderProps: FormRenderProps) => {
                  const { valueGetter } = formRenderProps;
                  const propsCustomFilterRender = {
                    ...formRenderProps,
                    ...props,
                  };

                  //code checking valid form
                  let checkValid = false;

                  const initialFirst = valueGetter("initialFilterFirst");
                  const nameField = initialFirst?.name;
                  const initialSecond = valueGetter("initialFilterSecond");
                  const firstFilterValue = valueGetter(
                    `${nameField}_${configEnumNameFilter.Value_1}`
                  );
                  const firstFilterLogic = valueGetter(
                    `${nameField}_${configEnumNameFilter.Logic_1}`
                  );
                  const secondFilterValue = valueGetter(
                    `${nameField}_${configEnumNameFilter.Value_2}`
                  );
                  const secondFilterLogic = valueGetter(
                    `${nameField}_${configEnumNameFilter.Logic_2}`
                  );

                  const firstValue =
                    firstFilterValue ??
                    initialFirst?.value ??
                    firstFilterLogic?.value;
                  const firstLogic =
                    firstFilterLogic ??
                    initialFirst?.operator ??
                    firstFilterValue?.operator;
                  const secondValue =
                    secondFilterValue ??
                    initialSecond?.value ??
                    secondFilterLogic?.value;
                  const secondLogic =
                    secondFilterLogic ??
                    initialSecond?.operator ??
                    secondFilterValue?.operator;
                  checkValid =
                    (firstLogic &&
                      isIncludeListNull(firstLogic as string | undefined)) ||
                    (secondLogic &&
                      isIncludeListNull(secondLogic as string | undefined)) ||
                    filterType === "boolean" ||
                    (!isNil(firstValue) &&
                      ((filterType === "date" && firstValue instanceof Date) ||
                        firstLogic)) ||
                    (!isNil(secondValue) &&
                      ((filterType === "date" && secondValue instanceof Date) ||
                        secondLogic));
                  // if()
                  setIsValid(checkValid);

                  formRenderPropsRef.current = formRenderProps;
                  return (
                    <FormElement>
                      <div className="k-filter-menu">
                        <div className="">
                          <CustomFilterRender {...propsCustomFilterRender} />
                          <div className="cc-group-filter-button">
                            <Button
                              name="Clear"
                              className={"cc-filter-menu-button"}
                              type="button"
                              onClick={handleClear}
                            >
                              Clear
                            </Button>
                            <Button
                              name="Filter"
                              className={"cc-filter-menu-button"}
                              themeColor="primary"
                              id="submit-filter"
                              type="button"
                              ref={refButton}
                              disabled={!isValid}
                              onClick={(e) => {
                                const valueSubmit = valueGetter("");
                                onFilterButtonChange(valueSubmit, e);
                              }}
                            >
                              Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
