import { switchOptionsValueNumberString } from "@app/products/animals/system-admin/settings/_id/main/config";
import { LLRenderSwitch } from "@app/products/local-laws/system-admin/settings/_id/components/switch/_index";
import { ICommonFormProps } from "@app/products/local-laws/system-admin/settings/_id/main/model";
import { useLocalLawsSettingManageStore } from "@app/products/local-laws/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useLocalLawsSettingManageStore();

  const eEnableTemperatureRecord =
    ECorporateSettingsField.LLP_EnableTemperatureRecord;
  const eEnableFSPObserved = ECorporateSettingsField.LLP_EnableFSPObserved;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <LLRenderSwitch
          switchList={[
            {
              eSettingsField: eEnableTemperatureRecord,
            },
            {
              eSettingsField: eEnableFSPObserved,
            },
          ]}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
