import { INVOICE_MANAGE_ROUTE } from "@app/core/invoice/[id]/constant";
import { Svc_InvoiceAccordion } from "@app/core/invoice/invoice-accordion/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_InvoiceAccordion>();
export const colInvoices: IColumnFields[] = [
  {
    field: nameOf("InvoiceNumber"),
    title: "Invoice Number",
    locked: true,
    linkTo: (dataItem: Svc_InvoiceAccordion) => {
      return `${INVOICE_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  {
    field: nameOf("InvoiceDate"),
    title: "Invoice Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DueDate"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("ExpToFinance"),
    title: "Export To Finance",
  },
  {
    field: nameOf("PaidInfull"),
    title: "Paid In Full",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
