import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colRegisters: IColumnFields[] = [
  {
    field: "Row",
    title: "Row",
  },
  {
    field: "Register",
    title: "Register",
  },
  {
    field: "AccountReference",
    title: "Account Reference",
  },
  {
    field: "Description",
    title: "Description",
  },
  {
    field: "Date",
    title: "Date",
  },
  {
    field: "PropertyName",
    title: "Property Name",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "SupplementaryDate",
    title: "Property Locality",
  },
  {
    field: "Name",
    title: "Name",
  },
  {
    field: "Balance",
    title: "Balance",
  },
  {
    field: "Status",
    title: "Status",
  },
  {
    field: "CreatedOn",
    title: "Created On",
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Colour",
    title: "Colour",
  },
];
