import { loadAdvancedSearchIdByFilter } from "@app/products/property/advanced_search/api";
import { NewSearchDialog } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/_index";
import { PROPERTY_ASSESSMENT_ADVANCED_SEARCH_ROUTE } from "@app/products/property/advanced_search/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const NewSearchButton = () => {
  const history = useHistory();
  const [showNewSearchDialog, setShowNewSearchDialog] =
    useState<boolean>(false);
  return (
    <>
      <CCNavButton
        title="New Search"
        onClick={() => {
          setShowNewSearchDialog(true);
        }}
      />
      {showNewSearchDialog && (
        <NewSearchDialog
          onSubmit={(data) => {
            for (const [key] of Object.entries(data.SearchDetails.Filter)) {
              const removeUnusedFilterIndex = data.Criteria.Criteria.findIndex(
                (criteria: any) => criteria.Key === key
              );
              if (removeUnusedFilterIndex === -1) {
                delete data.SearchDetails.Filter[key];
              }
            }
            loadAdvancedSearchIdByFilter(data.SearchDetails.Filter).then(
              (data) => {
                history.push(
                  `${PROPERTY_ASSESSMENT_ADVANCED_SEARCH_ROUTE}/searches/${data}`
                );
              }
            );

            setShowNewSearchDialog(false);
          }}
          onClose={() => {
            setShowNewSearchDialog(false);
          }}
        />
      )}
    </>
  );
};
