import { SaveResultSearchDialog } from "@app/products/property/advanced_search/[id]/components/action-bar/dialogs/save-search/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const SaveSearchButton = () => {
  const [showNewSearchDialog, setShowNewSearchDialog] =
    useState<boolean>(false);

  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  return (
    <>
      <CCNavButton
        title="Save Search"
        onClick={() => {
          setShowNewSearchDialog(true);
        }}
      />
      {showNewSearchDialog && (
        <SaveResultSearchDialog
          onClose={() => {
            setShowNewSearchDialog(false);
          }}
          onSubmit={() => {
            clearNotifications();
            pushNotification({
              title: "Search result saved successfully",
              type: "success",
            });
            setShowNewSearchDialog(false);
          }}
        />
      )}
    </>
  );
};
