import { HMFieldMapping } from "@app/products/hm/model";

export const colHMSystemAdminPremisesTypes = [
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Name,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.RiskClassification,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.RenewalMethod_Name,
    title: "Renewal",
    width: 150,
  },
  {
    field: HMFieldMapping.InspectionFrequency,
    title: "Insp Freq",
    width: 150,
  },
  {
    field: HMFieldMapping.Category,
    title: "Category",
    width: 150,
  },
  {
    field: HMFieldMapping.RegistrationFee_Amount,
    title: "Registration Fee",
    width: 150,
  },
  {
    field: HMFieldMapping.RenewalFee_Amount,
    title: "Renewal Fee",
    width: 150,
  },
  {
    field: HMFieldMapping.TransferFee_Amount,
    title: "Transfer Fee",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_NoOfEmployees,
    title: "Employees",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_NoOfUnits,
    title: "Units",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_NoOfBeds,
    title: "Beds",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_TemporaryPremises,
    title: "Food Stall",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_TobaccoRetail,
    title: "Tobacco",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_FoodVehicle,
    title: "Food Vehicle",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesCount,
    title: "Number of Premises",
    width: 150,
  },
];
