import { defaultBuildAddress } from "@app/core/contacts/_id/components/child-screens/general/components/form-element/configs";
import {
  ContactClassification,
  ContactExtension,
  ContactLovs,
  ContactMethodPreferred,
  Svc_Contact,
} from "@app/core/contacts/_id/model";
import { useContactStore } from "@app/core/contacts/_id/store";
import { getSiteUser } from "@app/core/inspections/[id]/api";
import { optionOfficer } from "@app/core/inspections/[id]/components/child-screens/general/components/inspection-form-element/config";
import { Officers } from "@app/core/inspections/[id]/model";
import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { EHasCommunityGroup } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/model";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { AddressBookSection } from "@common/constants/enumerations";
import { AddressPicker } from "@common/input-pickers/address/_index";
import { ContactLookahead_JSON } from "@common/models/contact";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { eProductType } from "@common/stores/products/enum";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import {
  validateEmail,
  validateEmailRequired,
} from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import {
  validatePhoneNumber,
  validatePhoneNumberRequired,
} from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import "./_index.scss";

const nameOf = nameOfFactory<Svc_Contact>();
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();
interface IContactFormElementProps {
  formRenderProps: FormRenderProps;
  lovs?: ContactLovs;
  isNew?: boolean;
  excludePartnership?: boolean;
  contactClassificationFilters?: ContactClassification[];
  notificationRef?: React.MutableRefObject<ICCLocalNotificationHandle | null>;
}

export const ContactFormElement = observer(
  ({
    formRenderProps,
    lovs: lovsProp,
    isNew,
    contactClassificationFilters,
    excludePartnership,
    notificationRef,
  }: IContactFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { settings } = useCommonCoreStore();
    const { activeProductsMap } = useCommonProductStore();
    const { isInactive } = useContactStore();
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    //#region  Settings
    const isShowGenderPronoun = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_ContactManager_ShowGenderPronoun]
    );
    const oCorpSettingNAR = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_NARSystem]
    );
    const highlightAlert = getBoolValueSetting(
      settings[
        ECorporateSettingsField.Global_ContactManager_HighlightContactAlert
      ]
    );
    const enableVIP = getBoolValueSetting(
      settings[ECorporateSettingsField.EnableVIP]
    );
    const flagSendSMSAlerts = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_SMS_FlagSendSMSAlerts]
    );

    const allowFirstNameOnly = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_ContactManager_AllowFirstNameOnly]
    );
    const allowLastNameOnly = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_ContactManager_AllowLastNameOnly]
    );
    /* const hdnPersonCapitalizeFirst = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_Contact_CapitaliseFirst
      ]
    );
         const hdnPersonCapitalizeLast = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Contact_CapitaliseLast]
    );
    const hdnPersonUppercaseFirst = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Contact_UppercaseFirst]
    );
    const hdnPersonUppercaseLast = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_Contact_UppercaseLast]
    );
    const hdnPersonCapitalizeOnNew = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_Contact_CapitaliseOnNew
      ]
    );
    const hdnPersonAddSalutationToDisplayName = getBoolValueSetting(
      settings[
        ECorporateSettingsField.Global_ContactManager_AddSalutationToDisplayName
      ]
    ); */
    const preferredAddressLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_PreferredAddressLabel]
    );
    const alternateAddressLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AlternateAddressLabel]
    );
    /*     const contactAddressOrder = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_ContactAddressOrder]
    ); */
    const labelPreferredMethod = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_PreferredMethodLabel]
    );
    /*     const defaultPostal = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_DefaultPostalAddress]
    ); */
    /*     const ABNCheckerEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableABNChecker]
    ); */
    const flagForceUseStreetType = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
    );
    //#endregion
    //#region  Value getter
    const classification: ContactClassification =
      valueGetter(nameOf("ContactClassification_ENUM")) ??
      ContactClassification.Contact;

    const extensionTypes: ContactExtension[] = valueGetter(
      nameOf("ExtensionTypes")
    )?.map((type: any) => parseInt(ContactExtension[type]));

    const addressBookSectionEnum: AddressBookSection = valueGetter(
      nameOf("AddressBookSection_Int")
    );

    const methodPreferred: ContactMethodPreferred =
      valueGetter(nameOf("PreferredMethodOfContact_ENUM")) ??
      ContactMethodPreferred.None;

    const communityCentralSettings =
      activeProductsMap[eProductType.CommunityProperty]
        ?.Flag_CommunityCentral ?? false;
    const linkNarId = valueGetter(nameOf("NARNumber"))
      ? `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${valueGetter(
          nameOf("NARNumber")
        )}`
      : "";

    const isNarIdGivenEffect = useMemo(() => {
      return linkNarId && communityCentralSettings ? true : false;
    }, [linkNarId, communityCentralSettings]);

    //#endregion
    //#region Memos
    const contactType = useMemo(() => {
      let result = {
        isPerson: false,
        isCompany: false,
        isPartnership: false,
        isIncBody: false,
        isProprietor: false,
        isProprietorIndividual: false,
        isProprietorPartnership: false,
        isProprietorCharity: false,
        isProprietorBody: false,
        isProprietorTrading: false,
        isProprietorCompany: false,
        isProprietorContact: false,
      };
      if (classification === ContactClassification.Person)
        return { ...result, isPerson: true };
      if (
        classification === ContactClassification.Company ||
        classification === ContactClassification.Trust
      )
        return { ...result, isCompany: true };
      if (classification === ContactClassification.Partnership)
        return { ...result, isPartnership: true };
      if (classification === ContactClassification.IncorporatedBody)
        return { ...result, isIncBody: true };
      if (
        [
          ContactClassification.ProprietorBus_Individual,
          ContactClassification.ProprietorBus_Company,
          ContactClassification.ProprietorBus_Partnership,
          ContactClassification.ProprietorComm_NotForProfit,
          ContactClassification.ProprietorComm_BodyForCharity,
          ContactClassification.ProprietorComm_IndividualForCharity,
        ].includes(classification)
      )
        return { ...result, isProprietor: true };
      if (
        [
          ContactClassification.ProprietorBus_Individual,
          ContactClassification.ProprietorComm_IndividualForCharity,
        ].includes(classification)
      )
        return { ...result, isProprietorIndividual: true };
      if (classification === ContactClassification.ProprietorBus_Partnership)
        return { ...result, isProprietorPartnership: true };
      if (
        [
          ContactClassification.ProprietorComm_BodyForCharity,
          ContactClassification.ProprietorComm_IndividualForCharity,
        ].includes(classification)
      )
        return { ...result, isProprietorCharity: true };
      if (
        [
          ContactClassification.ProprietorComm_BodyForCharity,
          ContactClassification.ProprietorComm_NotForProfit,
        ].includes(classification)
      )
        return { ...result, isProprietorBody: true };

      if (
        [
          ContactClassification.ProprietorBus_Individual,
          ContactClassification.ProprietorBus_Company,
          ContactClassification.ProprietorBus_Partnership,
          ContactClassification.ProprietorComm_IndividualForCharity,
        ].includes(classification)
      )
        return { ...result, isProprietorTrading: true };

      if (classification === ContactClassification.ProprietorBus_Company)
        return { ...result, isProprietorCompany: true };
      if (
        [
          ContactClassification.ProprietorBus_Company,
          ContactClassification.ProprietorBus_Partnership,
          ContactClassification.ProprietorComm_NotForProfit,
          ContactClassification.ProprietorComm_BodyForCharity,
        ].includes(classification)
      )
        return { ...result, isProprietorContact: true };
      return result;
    }, [classification]);

    const isShowDisplayVerification = useMemo(() => {
      if (contactType.isPerson) return true;
      if (!extensionTypes) return false;
      if (extensionTypes.includes(ContactExtension.HasVerificationQuestion))
        return true;
      if (
        addressBookSectionEnum === AddressBookSection.CRS_Parents &&
        isNumber(oCorpSettingNAR) &&
        extensionTypes.includes(ContactExtension.HasDateOfBirth)
      )
        return true;
    }, [
      contactType.isPerson,
      extensionTypes,
      addressBookSectionEnum,
      oCorpSettingNAR,
    ]);

    const isShowDisplayAttention = useMemo(
      () =>
        isNumber(addressBookSectionEnum) &&
        extensionTypes &&
        extensionTypes.includes(ContactExtension.HasContactAttention),
      [extensionTypes, addressBookSectionEnum]
    );

    const isShowFSS = useMemo(
      () =>
        extensionTypes &&
        extensionTypes.includes(ContactExtension.HasFSSCertificate),
      [extensionTypes]
    );

    const hasVerificationQuestion = useMemo(
      () =>
        extensionTypes &&
        extensionTypes.includes(ContactExtension.HasVerificationQuestion),
      [extensionTypes]
    );
    const isRequiredWorkPhone =
      methodPreferred === ContactMethodPreferred.WorkPhone;
    const isRequiredMobilePhone =
      methodPreferred === ContactMethodPreferred.Mobile;
    const isRequiredHomePhone =
      methodPreferred === ContactMethodPreferred.HomePhone;
    const isRequiredFax = methodPreferred === ContactMethodPreferred.Fax;
    const isRequiredEmail =
      methodPreferred === ContactMethodPreferred.Email ||
      methodPreferred === ContactMethodPreferred.EmailAndPost;

    const lovs = useMemo(() => {
      if (!lovsProp) return undefined;
      let classification = lovsProp?.Classifications
        ? [...lovsProp.Classifications]
        : [];

      if (
        contactClassificationFilters &&
        contactClassificationFilters.length &&
        classification
      ) {
        classification = classification.filter((obj) =>
          contactClassificationFilters.includes(obj.Key)
        );
      }
      if (excludePartnership) {
        classification = classification.filter(
          (obj) => obj.Key !== ContactClassification.Partnership
        );
      }

      const newLovsProp = { ...lovsProp, Classifications: classification };

      return newLovsProp;
      // eslint-disable-next-line
    }, [contactClassificationFilters, excludePartnership]);
    //#endregion

    const ContactField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    const handleOnChangeWithDisplayName = (
      value: string | null,
      fieldName: keyof Svc_Contact
    ) => {
      onChange(fieldName, { value });
      if (classification !== ContactClassification.Person) {
        onChange(nameOf("DisplayName"), { value });
      } else {
        let salutation = valueGetter(nameOf("Person_Salutation")) ?? "";
        let firstName = valueGetter(nameOf("Person_FirstName")) ?? "";
        let lastName = valueGetter(nameOf("Person_LastName")) ?? "";
        switch (fieldName) {
          case "Person_Salutation":
            salutation = value ?? "";
            break;
          case "Person_FirstName":
            firstName = value ?? "";
            break;
          case "Person_LastName":
            lastName = value ?? "";
            break;
          default:
            break;
        }
        const valueConcat = `${salutation} ${firstName} ${lastName}`;
        onChange(nameOf("DisplayName"), {
          value: valueConcat.trim(),
        });
      }
    };

    const officerChangeHandler = async (officer: Officers) => {
      if (officer) {
        const response = await getSiteUser(officer?.ID);
        if (isSuccessResponse(response) && response?.data) {
          onChange(nameOf("VIPRelationshipOwner"), {
            value: response.data?.DisplayName,
          });
          onChange(nameOf("VIPRelationshipOwner_ID"), {
            value: response.data?.Contact_ID,
          });
        } else {
          notificationRef?.current?.pushNotification({
            title: "Officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      } else {
        onChange(nameOf("VIPRelationshipOwner"), {
          value: undefined,
        });
        onChange(nameOf("VIPRelationshipOwner_ID"), {
          value: undefined,
        });
      }
    };

    const isShowIdentify =
      valueGetter(nameOf("ContactClassification_ENUM")) ===
      ContactClassification.Person;

    return (
      <FormElement>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Address book</label>
              <ContactField
                name={nameOf("AddressBookSection_Int")}
                data={lovs?.AddressBooks}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Contact type</label>
              <ContactField
                name={nameOf("ContactClassification_ENUM")}
                data={lovs?.Classifications}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={!isNew || isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                {isNarIdGivenEffect ? (
                  <NavLink
                    to={linkNarId}
                    className="cc-grid-link-text"
                    onClick={(event) =>
                      linkNarId ? {} : event.preventDefault()
                    }
                  >
                    NAR ID
                  </NavLink>
                ) : (
                  "NAR ID"
                )}
              </label>
              <ContactField
                name={nameOf("NARNumber")}
                component={CCValueField}
              />
            </div>
          </div>
          {/*  Person */}
          {contactType.isPerson && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Salutation</label>
                <ContactField
                  name={nameOf("Person_Salutation")}
                  data={lovs?.PersonSalutations}
                  component={CCSearchComboBox}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  value={getDropdownValue(
                    valueGetter(nameOf("Person_Salutation")),
                    lovs?.PersonSalutations ?? [],
                    nameOfKeyValuePacket("Value")
                  )}
                  onChange={(event: ComboBoxChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value?.Value ?? null,
                      "Person_Salutation"
                    )
                  }
                  disabled={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  First name
                  {!allowLastNameOnly && <CCTooltip type="validator" />}
                </label>
                <ContactField
                  name={nameOf("Person_FirstName")}
                  placeholder={"First name"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                  validator={!allowLastNameOnly ? requiredValidator : undefined}
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value,
                      "Person_FirstName"
                    )
                  }
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Last name
                  {!allowFirstNameOnly && <CCTooltip type="validator" />}
                </label>
                <ContactField
                  name={nameOf("Person_LastName")}
                  placeholder={"Last name"}
                  component={CCInput}
                  validator={
                    !allowFirstNameOnly ? requiredValidator : undefined
                  }
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value,
                      "Person_LastName"
                    )
                  }
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              {isShowGenderPronoun && (
                <div className="cc-field">
                  <label className="cc-label">Preferred pronoun</label>
                  <ContactField
                    name={nameOf("Person_GenderPronoun")}
                    placeholder={"Preferred pronoun"}
                    component={CCComboBox}
                    data={lovs?.GenderPronouns?.map((obj) => obj.Value)}
                    disabled={isNarIdGivenEffect}
                  />
                </div>
              )}
              <div className="cc-field">
                <label className="cc-label">Organisation</label>
                <ContactField
                  name={nameOf("Extension_Organisation")}
                  placeholder={"Organisation"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Position</label>
                <ContactField
                  name={nameOf("Extension_JobTitle")}
                  placeholder={"Position"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ABN</label>
                <ContactField
                  name={nameOf("ABN")}
                  placeholder={"ABN"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
            </div>
          )}
          {/* Company */}
          {contactType.isCompany && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Company name <CCTooltip type="validator" />
                </label>
                <ContactField
                  name={nameOf("DisplayName")}
                  placeholder={"Company name"}
                  component={CCInput}
                  validator={requiredValidator}
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(event.value, "DisplayName")
                  }
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ACN</label>
                <ContactField
                  name={nameOf("ACN")}
                  placeholder={"ACN"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ABN</label>
                <ContactField
                  name={nameOf("ABN")}
                  placeholder={"ABN"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Trading name</label>
                <ContactField
                  name={nameOf("Company_TradingName")}
                  placeholder={"Trading name"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
            </div>
          )}
          {/*  Partnership */}
          {contactType.isPartnership && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Partnership name <CCTooltip type="validator" />
                </label>
                <ContactField
                  name={nameOf("Partnership_Name")}
                  placeholder={"Partnership name"}
                  component={CCInput}
                  validator={requiredValidator}
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value,
                      "Partnership_Name"
                    )
                  }
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ABN</label>
                <ContactField
                  name={nameOf("ABN")}
                  placeholder={"ABN"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Trading name</label>
                <ContactField
                  name={nameOf("Company_TradingName")}
                  placeholder={"Trading name"}
                  component={CCInput}
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value,
                      "Company_TradingName"
                    )
                  }
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ACN</label>
                <ContactField
                  name={nameOf("ACN")}
                  placeholder={"ACN"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
            </div>
          )}
          {/* IncBody */}
          {contactType.isIncBody && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">
                  Type <CCTooltip type="validator" />
                </label>
                <ContactField
                  name={nameOf("IncorporatedBodyType_KWD")}
                  data={lovs?.IncBodyTypes}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  validator={requiredValidator}
                  disabled={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ABN</label>
                <ContactField
                  name={nameOf("ABN")}
                  placeholder={"ABN"}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Name of body <CCTooltip type="validator" />
                </label>
                <ContactField
                  name={nameOf("IncorporatedBody_Name")}
                  placeholder={"Name of body"}
                  component={CCInput}
                  validator={requiredValidator}
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value,
                      "IncorporatedBody_Name"
                    )
                  }
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">ACN</label>
                <ContactField
                  name={nameOf("ACN")}
                  placeholder={"ACN"}
                  component={CCInput}
                  readOnly={isNarIdGivenEffect}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Trading name</label>
                <ContactField
                  name={nameOf("Company_TradingName")}
                  placeholder={"Trading name"}
                  component={CCInput}
                  onChange={(event: InputChangeEvent) =>
                    handleOnChangeWithDisplayName(
                      event.value,
                      "Company_TradingName"
                    )
                  }
                  readOnly={isNarIdGivenEffect}
                />
              </div>
            </div>
          )}
          {/* Proprietor */}
          {contactType.isProprietor && (
            <>
              {/* --Proprietor_Individual */}
              {contactType.isProprietorIndividual && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Salutation</label>
                    <ContactField
                      name={nameOf("Person_Salutation")}
                      data={lovs?.PersonSalutations}
                      component={CCSearchComboBox}
                      textField={nameOfKeyValuePacket("Value")}
                      dataItemKey={nameOfKeyValuePacket("Key")}
                      value={getDropdownValue(
                        valueGetter(nameOf("Person_Salutation")),
                        lovs?.PersonSalutations ?? [],
                        nameOfKeyValuePacket("Value")
                      )}
                      onChange={(event: ComboBoxChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value?.Value ?? null,
                          "Person_Salutation"
                        )
                      }
                      disabled={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Given name
                      {!allowLastNameOnly && <CCTooltip type="validator" />}
                    </label>
                    <ContactField
                      name={nameOf("Person_FirstName")}
                      placeholder={"Given name"}
                      component={CCInput}
                      validator={
                        !allowLastNameOnly ? requiredValidator : undefined
                      }
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Person_FirstName"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Last name
                      {!allowFirstNameOnly && <CCTooltip type="validator" />}
                    </label>
                    <ContactField
                      name={nameOf("Person_LastName")}
                      placeholder={"Last name"}
                      component={CCInput}
                      validator={
                        !allowFirstNameOnly ? requiredValidator : undefined
                      }
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Person_LastName"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
              {/* --Proprietor_Partnership */}
              {contactType.isProprietorPartnership && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Partnership name</label>
                    <ContactField
                      name={nameOf("Partnership_Name")}
                      placeholder={"Partnership name"}
                      component={CCInput}
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Partnership_Name"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
              {/* --Proprietor_Charity */}
              {contactType.isProprietorCharity && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Name of charity</label>
                    <ContactField
                      name={"nullName"}
                      placeholder={"Name of charity"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
              {/* --Proprietor_Body */}
              {contactType.isProprietorBody && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Name of body</label>
                    <ContactField
                      name={"nullName"}
                      placeholder={"Name of body"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">ABN</label>
                    <ContactField
                      name={nameOf("ABN")}
                      placeholder={"ABN"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
              {/* --Proprietor_Trading */}
              {contactType.isProprietorTrading && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Trading name</label>
                    <ContactField
                      name={nameOf("Company_TradingName")}
                      placeholder={"Trading name"}
                      component={CCInput}
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Company_TradingName"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">ABN</label>
                    <ContactField
                      name={nameOf("ABN")}
                      placeholder={"ABN"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
              {/* --Proprietor_Company */}
              {contactType.isProprietorCompany && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">
                      Company name
                      <CCTooltip type="validator" />
                    </label>
                    <ContactField
                      name={nameOf("Company_TradingName")}
                      placeholder={"Company name"}
                      component={CCInput}
                      validator={requiredValidator}
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Company_TradingName"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">ACN</label>
                    <ContactField
                      name={nameOf("ABN")}
                      placeholder={"ACN"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
              {/* --Proprietor_Contact */}
              {contactType.isProprietorContact && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Salutation</label>
                    <ContactField
                      name={nameOf("Person_Salutation")}
                      data={lovs?.PersonSalutations}
                      component={CCSearchComboBox}
                      textField={nameOfKeyValuePacket("Value")}
                      dataItemKey={nameOfKeyValuePacket("Key")}
                      value={getDropdownValue(
                        valueGetter(nameOf("Person_Salutation")),
                        lovs?.PersonSalutations ?? [],
                        nameOfKeyValuePacket("Value")
                      )}
                      onChange={(event: ComboBoxChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value?.Value ?? null,
                          "Person_Salutation"
                        )
                      }
                      disabled={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Given name
                      {!allowLastNameOnly && <CCTooltip type="validator" />}
                    </label>
                    <ContactField
                      name={nameOf("Person_FirstName")}
                      placeholder={"Given name"}
                      component={CCInput}
                      validator={
                        !allowLastNameOnly ? requiredValidator : undefined
                      }
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Person_FirstName"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Last name
                      {!allowFirstNameOnly && <CCTooltip type="validator" />}
                    </label>
                    <ContactField
                      name={nameOf("Person_LastName")}
                      placeholder={"Last name"}
                      component={CCInput}
                      validator={
                        !allowFirstNameOnly ? requiredValidator : undefined
                      }
                      onChange={(event: InputChangeEvent) =>
                        handleOnChangeWithDisplayName(
                          event.value,
                          "Person_LastName"
                        )
                      }
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {/* Display As */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Display as (name) <CCTooltip type="validator" />
              </label>
              <ContactField
                name={nameOf("DisplayName")}
                placeholder={"Display as (Name)"}
                component={CCInput}
                validator={requiredValidator}
                readOnly={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                {labelPreferredMethod ?? "Preferred method"}
              </label>
              <ContactField
                name={nameOf("PreferredMethodOfContact_ENUM")}
                data={lovs?.PreferredMethods ?? []}
                textField={nameOfKeyValuePacket("Value")}
                dataItemKey={nameOfKeyValuePacket("Key")}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={isNarIdGivenEffect}
              />
            </div>
          </div>

          {/* Additional contact blocks */}
          {contactType.isPartnership && (
            <div className="cc-form-cols-3">
              <PartnerField
                label={"Partner 1"}
                contactIdField={nameOf("Partnership1_Contact_Id")}
                displayField={nameOf("Partner1_Name")}
                relationshipIdFields={nameOf("Partnership1_Rid")}
                onChange={onChange}
                valueGetter={valueGetter}
                contactClassification={classification}
              />
              <PartnerField
                label={"Partner 2"}
                contactIdField={nameOf("Partnership2_Contact_Id")}
                displayField={nameOf("Partner2_Name")}
                relationshipIdFields={nameOf("Partnership2_Rid")}
                onChange={onChange}
                valueGetter={valueGetter}
                contactClassification={classification}
              />
              <PartnerField
                label={"Partner 3"}
                contactIdField={nameOf("Partnership3_Contact_Id")}
                displayField={nameOf("Partner3_Name")}
                relationshipIdFields={nameOf("Partnership3_Rid")}
                onChange={onChange}
                valueGetter={valueGetter}
                contactClassification={classification}
              />
              <PartnerField
                label={"Partner 4"}
                contactIdField={nameOf("Partnership4_Contact_Id")}
                displayField={nameOf("Partner4_Name")}
                relationshipIdFields={nameOf("Partnership4_Rid")}
                onChange={onChange}
                valueGetter={valueGetter}
                contactClassification={classification}
              />
            </div>
          )}
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Work phone
                {isRequiredWorkPhone && <CCTooltip type="validator" />}
              </label>
              <ContactField
                name={nameOf("WorkPhone")}
                placeholder={"Work phone"}
                component={CCInputPhone}
                validator={
                  isRequiredWorkPhone
                    ? validatePhoneNumberRequired
                    : validatePhoneNumber
                }
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.WorkPhone}
                readOnly={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Home phone
                {isRequiredHomePhone && <CCTooltip type="validator" />}
              </label>
              <ContactField
                name={nameOf("HomePhone")}
                placeholder={"Home phone"}
                component={CCInputPhone}
                validator={
                  isRequiredHomePhone
                    ? validatePhoneNumberRequired
                    : validatePhoneNumber
                }
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.HomePhone}
                readOnly={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Mobile
                {isRequiredMobilePhone && <CCTooltip type="validator" />}
              </label>
              <ContactField
                name={nameOf("Mobile")}
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={
                  isRequiredMobilePhone
                    ? validatePhoneNumberRequired
                    : validatePhoneNumber
                }
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
                readOnly={isNarIdGivenEffect}
              />
            </div>
            {flagSendSMSAlerts && (
              <div className="cc-field">
                <label
                  className={`cc-label ${
                    valueGetter(nameOf("Flag_SendSMSAlerts"))
                      ? "text-success"
                      : "text-error"
                  }`}
                >
                  {valueGetter(nameOf("Flag_SendSMSAlerts"))
                    ? "Send SMS alerts"
                    : "Do not send SMS alerts"}
                </label>
                <ContactField
                  name={nameOf("Flag_SendSMSAlerts")}
                  checked={valueGetter(nameOf("Flag_SendSMSAlerts"))}
                  component={CCSwitch}
                  disabled={isNarIdGivenEffect}
                />
              </div>
            )}
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <ContactField
                label="Email"
                isRequired={isRequiredEmail}
                name={nameOf("Email")}
                placeholder={"Email"}
                component={CCInputEmail}
                validator={
                  isRequiredEmail ? validateEmailRequired : validateEmail
                }
                onChangeForm={formRenderProps.onChange}
                readOnly={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Fax
                {isRequiredFax && <CCTooltip type="validator" />}
              </label>
              <ContactField
                name={nameOf("Fax")}
                placeholder={"Fax"}
                component={CCInputPhone}
                validator={
                  isRequiredFax
                    ? validatePhoneNumberRequired
                    : validatePhoneNumber
                }
                onChangeForm={onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Fax}
                readOnly={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Website</label>
              <ContactField
                name={nameOf("Website")}
                placeholder={"Website"}
                component={CCInput}
                readOnly={isNarIdGivenEffect}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        {/* DisplayVerification */}
        {isShowDisplayVerification && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                {hasVerificationQuestion && (
                  <div className="cc-field">
                    <label className="cc-label">Verification question</label>
                    <ContactField
                      name={nameOf("Extension_VerificationQuestion")}
                      placeholder={"Verification question"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                )}
                <div className="cc-field">
                  <label className="cc-label">Date of birth</label>
                  <ContactField
                    name={nameOf("Extension_DateOfBirth")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    disabled={isNarIdGivenEffect}
                  />
                </div>
                {hasVerificationQuestion && (
                  <div className="cc-field">
                    <label className="cc-label">Verification response</label>
                    <ContactField
                      name={nameOf("Extension_VerificationResponse")}
                      placeholder={"Verification response"}
                      component={CCInput}
                      readOnly={isNarIdGivenEffect}
                    />
                  </div>
                )}
                <div className="cc-field">
                  <label className="cc-label">Second language</label>
                  <ContactField
                    name={nameOf("Extension_SecondLanguage")}
                    placeholder={"Second language"}
                    component={CCInput}
                    readOnly={isNarIdGivenEffect}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}
        {isShowIdentify && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Identify as Aboriginal/Torres Strait Islander
                  </label>
                  <ContactField
                    name={"Aboriginal"}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    data={lovs?.Aboriginal ?? []}
                    component={CCSearchComboBox}
                    isUseDefaultOnchange
                    disabled={isNarIdGivenEffect}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    Identifies with a Community Group
                  </label>
                  <ContactField
                    name={"HasCommunityGroup"}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    data={lovs?.HasCommunityGroup ?? []}
                    component={CCSearchComboBox}
                    disabled={isNarIdGivenEffect}
                    value={getDropdownValue(
                      valueGetter("HasCommunityGroup"),
                      lovs?.HasCommunityGroup ?? [],
                      nameOfKeyValuePacket("Key")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      const selectedValue =
                        event.value?.[nameOfKeyValuePacket("Key")];
                      // Reset "Community group"
                      if (selectedValue !== EHasCommunityGroup.Yes) {
                        onChange("Community_Group_Ids", { value: [] });
                      }
                      onChange("HasCommunityGroup", {
                        value: selectedValue,
                      });
                    }}
                  />
                </div>
                {/* Only show when "Does the contact identify with a Community
            Group?"==="Yes"  */}
                {valueGetter("HasCommunityGroup") === EHasCommunityGroup.Yes ? (
                  <div className="cc-field">
                    <label className="cc-label">Community Group</label>
                    <ContactField
                      name={"Community_Group_Ids"}
                      textField={nameOfKeyValuePacket("Value")}
                      dataItemKey={nameOfKeyValuePacket("Key")}
                      data={lovs?.CommunityGroup ?? []}
                      component={CCMultiSelectDropdown}
                      disabled={isNarIdGivenEffect}
                      onChange={(event: MultiSelectChangeEvent) => {
                        let value = event?.value;
                        if (value?.length) {
                          value = value.map(
                            (item: IKeyValuePacket) => item.Key
                          );
                        }
                        onChange("Community_Group_Ids", {
                          value: value,
                        });
                      }}
                      value={getMultipleSelectValue(
                        valueGetter("Community_Group_Ids"),
                        lovs?.CommunityGroup ?? [],
                        nameOfKeyValuePacket("Key")
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}
        {/* DisplayAttention */}
        {isShowDisplayAttention && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Attention</label>
                  <ContactField
                    name={nameOf("Extension_Attention")}
                    placeholder={"Attention"}
                    component={CCInput}
                    readOnly={isNarIdGivenEffect}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}
        {/* ContactAlert */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div
              className={`cc-field ${
                highlightAlert ? "cc-highlight-alert" : ""
              }`}
            >
              <CCValueField
                label="Contact alert"
                value={valueGetter(nameOf("ContactAlert"))}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        {/* Registration No */}
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Registration number</label>
              <ContactField
                name={nameOf("Extension_PlumberLicNo")}
                placeholder={"Registration number"}
                component={CCInput}
                readOnly={isNarIdGivenEffect}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        {/* FSS */}
        {isShowFSS && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">FSS certified?</label>
                  <ContactField
                    name={nameOf("Extension_FSSCertificate")}
                    //@TODO: recheck value type
                    checked={valueGetter(nameOf("Extension_FSSCertificate"))}
                    component={CCSwitch}
                    disabled={isNarIdGivenEffect}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Certificate expiry date</label>
                  <ContactField
                    name={nameOf("Extension_FSSCertificateExpiryDate")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                    disabled={isNarIdGivenEffect}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">FSS certificate number</label>
                  <ContactField
                    name={nameOf("Extension_FSSCertificateNo")}
                    placeholder={"FSS certificate number"}
                    component={CCInput}
                    readOnly={isNarIdGivenEffect}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}
        {/* VIP */}
        {enableVIP && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">VIP</label>
                  <ContactField
                    name={nameOf("VIP")}
                    className="cc-vip-switch"
                    checked={valueGetter(nameOf("VIP"))}
                    component={CCSwitch}
                    disabled={isNarIdGivenEffect}
                  />
                </div>
                {valueGetter(nameOf("VIP")) && (
                  <>
                    <div className="cc-field">
                      <label className="cc-label">VIP type</label>
                      <ContactField
                        name={nameOf("VIPType_ID")}
                        data={lovs?.VIPTypes}
                        component={CCSearchComboBox}
                        textField={nameOfKeyValuePacket("Value")}
                        dataItemKey={nameOfKeyValuePacket("Key")}
                        isUseDefaultOnchange
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange(nameOf("VIPType_ID"), {
                            value: event.value,
                          });
                          onChange(nameOf("VIPType"), {
                            value: lovs?.VIPTypes?.find(
                              (obj) => obj.Key === event.value
                            )?.Value,
                          });
                        }}
                        disabled={isNarIdGivenEffect}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Relationship owner" />
                      <Field
                        component={InputPickerSearch}
                        name={nameOf("VIPRelationshipOwner")}
                        nameDisplay="DisplayName"
                        value={valueGetter(nameOf("VIPRelationshipOwner"))}
                        options={optionOfficer}
                        onChange={officerChangeHandler}
                        readOnly={isNarIdGivenEffect}
                      />
                    </div>
                  </>
                )}
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        )}
        {/* ContactComments */}
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Comments</label>
              <ContactField
                name={nameOf("ContactComments")}
                placeholder={"Comments"}
                component={CCTextArea}
                rows={3}
                disabled={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Qualifications</label>
              <ContactField
                name={nameOf("Qualifications")}
                placeholder={"Qualifications"}
                component={CCTextArea}
                rows={3}
                disabled={isNarIdGivenEffect}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        {/* Address */}
        {/* @TODO: Check render again */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                {preferredAddressLabel ?? "Preferred address"}
              </label>
              <ContactField
                name={nameOf("PreferredAddress")}
                component={AddressPicker}
                buildAddress={{
                  ...defaultBuildAddress,
                  flagForceUseStreetType,
                }}
                placeholder={preferredAddressLabel ?? "Preferred address"}
                onChangeDataSearch={(data: any) => {
                  onChange(nameOf("PreferredAddress"), {
                    value: data,
                  });
                }}
                disabled={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postal address</label>
              <ContactField
                name={nameOf("PostalAddress")}
                component={AddressPicker}
                buildAddress={defaultBuildAddress}
                placeholder={"Postal address"}
                onChangeDataSearch={(data: any) => {
                  onChange(nameOf("PostalAddress"), {
                    value: data,
                  });
                }}
                disabled={isNarIdGivenEffect}
              />
            </div>
            <div className="cc-field">
              <div className="cc-alternate-address">
                <label className="cc-label">
                  {alternateAddressLabel ?? "Alt address"}
                </label>
                {valueGetter(nameOf("PostalAddress")) && (
                  <Button
                    icon="paste"
                    title="Set Alternate Address same as Postal address"
                    className="cc-icon-label-button"
                    fillMode="flat"
                    onClick={(event: any) => {
                      event.preventDefault();
                      onChange(nameOf("AlternateAddress"), {
                        value: valueGetter(nameOf("PostalAddress")),
                      });
                    }}
                  />
                )}
              </div>
              <ContactField
                name={nameOf("AlternateAddress")}
                component={AddressPicker}
                buildAddress={defaultBuildAddress}
                placeholder={alternateAddressLabel ?? "Alternate address"}
                onChangeDataSearch={(data: any) => {
                  onChange(nameOf("AlternateAddress"), {
                    value: data,
                  });
                }}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        {/* Tags */}
        {/* @TODO: Check render again */}
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Tags</label>
              <ContactField
                name={nameOf("Custom_Tags")}
                placeholder={"Tags"}
                value={
                  valueGetter(nameOf("Custom_Tags"))
                    ? valueGetter(nameOf("Custom_Tags")).split(",")
                    : []
                }
                onChange={(event: MultiSelectChangeEvent) => {
                  onChange(nameOf("Custom_Tags"), {
                    value: event?.value.toString(),
                  });
                }}
                allowCustom
                component={MultiSelect}
                disabled={isNarIdGivenEffect}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);

interface IPartnerFieldProps
  extends Pick<FormRenderProps, "onChange" | "valueGetter"> {
  label: string;
  displayField: string;
  contactIdField: string;
  relationshipIdFields: string;
  contactClassification?: ContactClassification;
}
const PartnerField = observer(
  ({
    label,
    displayField,
    contactIdField,
    relationshipIdFields,
    onChange,
    valueGetter,
    contactClassification,
  }: IPartnerFieldProps) => {
    const { isInactive } = useContactStore();
    return (
      <div className="cc-field">
        <label className="cc-label">{label}</label>
        <Field
          name={contactIdField}
          component={ContactPicker}
          value={{
            DisplayName: valueGetter(displayField),
            Contact_ID: valueGetter(contactIdField),
          }}
          placeholder={label}
          isLoadDetail={false}
          contactClassification={contactClassification}
          onChange={(event: ComboBoxChangeEvent) => {
            const newContact: ContactLookahead_JSON | null = event.value;
            onChange(displayField, {
              value: newContact?.DisplayName,
            });
            onChange(contactIdField, {
              value: newContact?.ID,
            });
            onChange(relationshipIdFields, {
              value: null,
            });
          }}
          disabled={isInactive}
        />
      </div>
    );
  }
);
