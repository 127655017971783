import { addUniqueKey } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/util";
import { eChangeOfOwnershipType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Entity_Details,
  DTO_RebateEntitlementOwners,
  DTO_Rebates,
  DTO_Title,
  DTO_Workflow_ChangeOfOwnership,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { secondaryWorkflowUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { unionBy } from "lodash";

export const isDisabledByType = (
  typeToDisabled: eChangeOfOwnershipType,
  currentType: eChangeOfOwnershipType
) => {
  return currentType === typeToDisabled;
};

export const getCOOWorkflowData = (
  componentInvoked: number,
  componentInvokedId: number,
  changeOfOwnershipInitData?: DTO_Workflow_ChangeOfOwnership,
  changeOfOwnershipLOVs?: DTO_ChangeOfOwnership_LOVs
) => {
  let initType: any = { _option: { Type: undefined } };
  let initDetails: any = {};
  let initPICDetail: any = {};
  let initProperty: any = { _option: { AssessmentSelected: undefined } };
  let initNames: {
    Contacts: DTO_Entity_Details[];
    Name: string;
  } = {
    Contacts: [],
    Name: "",
  };
  let initRatePayer: {
    Contacts: DTO_Entity_Details[];
    Name: string;
  } = {
    Contacts: [],
    Name: "",
  };
  let initDuplicateRatePayer: {
    Contacts: DTO_Entity_Details[];
    Name: string;
  } = {
    Contacts: [],
    Name: "",
  };

  let initRebates: {
    Rebates: DTO_Rebates[];
    Owners: DTO_RebateEntitlementOwners[];
  } = {
    Rebates: [],
    Owners: [],
  };
  let initDocuments: {
    Document_Type_Id: number[];
  } = {
    Document_Type_Id: [],
  };
  let initAssociatedNamesDetails: DTO_Entity_Details[] = [];
  //---step Secondary Workflow---
  let initialWorkflow: any = {};
  let initNamesGridData: DTO_Entity_Details[] = [];

  if (changeOfOwnershipInitData) {
    const workflowDetail = changeOfOwnershipInitData?.WorkflowDetail;
    const typeSelectedRow = changeOfOwnershipLOVs?.ChangeOfOwnershipTypes?.find(
      (typeItem) =>
        typeItem?.Change_of_Ownership_Type === workflowDetail?.COODetails?.Type
    );
    initPICDetail = { ...workflowDetail?.PICDetails };
    initType = {
      ...initType,
      ...workflowDetail?.COODetails,
      _option: {
        Type: typeSelectedRow,
      },
    };

    initAssociatedNamesDetails = [
      ...initAssociatedNamesDetails,
      ...(workflowDetail?.AssociatedNamesDetails ?? []),
    ];

    initRatePayer = {
      ...initRatePayer,
      ...workflowDetail?.RatePayerDetails,
    };

    initDetails = {
      ...initDetails,
      ...workflowDetail?.COODetails,
      ComponentInvoked: componentInvoked,
      ComponentInvoked_Id: componentInvokedId,
    };

    initNames = {
      ...initNames,
      ...workflowDetail?.OwnerDetails,
    };
    //Add primary field from UX
    const titleData =
      workflowDetail?.PropertyDetails?.Titles?.map(
        (item: DTO_Title, index: number) => {
          return {
            ...item,
            Id: `exist_${index + 1}`,
          };
        }
      ) ?? [];
    initProperty = {
      ...initProperty,
      ...workflowDetail?.PropertyDetails,
      Titles: titleData,
    };
    initDuplicateRatePayer = {
      ...initDuplicateRatePayer,
      ...workflowDetail?.DuplicateRatePayerDetails,
    };

    initRebates = {
      ...initRebates,
      ...workflowDetail?.RebateEntitlements,
    };

    initDocuments = {
      ...initDocuments,
      ...workflowDetail?.Documents,
    };
    initNamesGridData = [
      ...initNames.Contacts,
      ...initRatePayer.Contacts,
      ...initDuplicateRatePayer.Contacts,
      ...initAssociatedNamesDetails,
    ];
    initNamesGridData = addUniqueKey(initNamesGridData);
    //Remove duplicate names
    initNamesGridData = unionBy(
      initNamesGridData,
      (name: DTO_Entity_Details) => `${name.Entity_Id}-${name.Role_Name}`
    );

    initialWorkflow = secondaryWorkflowUtilProcessing.processDataInit(
      changeOfOwnershipInitData
    );
  }

  return {
    [EKeysOfSteps.Type]: initType,
    [EKeysOfSteps.Details]: initDetails,
    [EKeysOfSteps.PICDetails]: initPICDetail,
    [EKeysOfSteps.Property]: initProperty,
    [EKeysOfSteps.Owners]: initNames,
    [EKeysOfSteps.RatePayers]: initRatePayer,
    [EKeysOfSteps.DuplicateRatePayers]: initDuplicateRatePayer,
    [EKeysOfSteps.Rebates]: initRebates,
    [EKeysOfSteps.Voting]: [],
    [EKeysOfSteps.Comments]: {},
    [EKeysOfSteps.Documents]: initDocuments,
    [EKeysOfSteps.SecondaryWorkflow]: initialWorkflow,
    [EKeysOfSteps.AssociatedNames]: initAssociatedNamesDetails,
    [EKeysOfSteps.NamesGridData]: initNamesGridData,
    Lease: {}, //TODO: Implement in the future outside ACTRO/LLS => fix for next button
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
};
