import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colNewEnquiries: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Date_Recorded,
    title: "Recorded",
    width: 200,
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Register_Number,
    title: "Number",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.OsBalance,
    title: "O/S Payment",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Address,
    title: "Address",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Contact,
    title: "Contact",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.EPlanning_Categories,
    title: "Type",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Description,
    title: "Description",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Date_Due,
    title: "Due",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Officer,
    title: "Officer",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.ReferenceNumber,
    title: "Reference Number",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Date_Complete,
    title: "Complete",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.LegalDescription,
    title: "Legal Description",
    width: 200,
  },
];
