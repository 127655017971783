import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement } from "@progress/kendo-react-form";
import React, { useMemo } from "react";

export interface IUpdateStatusDialogProps {
  onClosePopup: () => void;
}

export const DecisionDetailsDialog = ({
  onClosePopup,
}: IUpdateStatusDialogProps) => {
  const { ppr } = usePPRStore();

  const decisionDetailsList = useMemo(() => {
    if (!ppr) return [];
    return [
      { Title: "Decision", Value: ppr.Decision_ENUM },
      {
        Title: "Decision Date",
        Value: formatDisplayValue(ppr.DecisionDate, DATE_FORMAT.DATE),
      },
      {
        Title: "Lodged",
        Value: formatDisplayValue(ppr.LodgedDate, DATE_FORMAT.DATE),
      },
      {
        Title: "Letter Sent for Delegated Approver Approval",
        Value:
          formatDisplayValue(
            ppr.PPRDetails.DateSentForTeamLeaderApproval,
            DATE_FORMAT.DATE
          ) || "N/A",
      },
      {
        Title: "Letter Approved Material Adequate",
        Value:
          formatDisplayValue(
            ppr.PPRDetails.DateApprovedMaterialAdequate,
            DATE_FORMAT.DATE
          ) || "N/A",
      },
      {
        Title: "Letter Approved Material InAdequate",
        Value:
          formatDisplayValue(
            ppr.PPRDetails.DateApprovedMaterialInadequate,
            DATE_FORMAT.DATE
          ) || "N/A",
      },
      {
        Title: "Letter Disapproved Material Adequate",
        Value:
          formatDisplayValue(
            ppr.PPRDetails.DateDisApprovedMaterialAdequate,
            DATE_FORMAT.DATE
          ) || "N/A",
      },
      {
        Title: "Letter Disapproved Material InAdequate",
        Value:
          formatDisplayValue(
            ppr.PPRDetails.DateDisApprovedMaterialInadequate,
            DATE_FORMAT.DATE
          ) || "N/A",
      },
      {
        Title: "Letter Sent to Responsible Authority",
        Value:
          formatDisplayValue(
            ppr.PPRDetails.DateSentToRAandApplicant,
            DATE_FORMAT.DATE
          ) || "N/A",
      },
    ];
  }, [ppr]);

  return (
    <Form
      render={() => {
        return (
          <FormElement>
            <CCDialog
              maxWidth="35%"
              height="auto"
              titleHeader={"Decision Details"}
              onClose={onClosePopup}
              bodyElement={
                <div className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-2">
                      {decisionDetailsList.map((item) => (
                        <CCValueField label={item.Title} value={item.Value} />
                      ))}
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    className={"cc-dialog-button"}
                    onClick={() => {
                      onClosePopup();
                    }}
                  >
                    Exit
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
