export const options = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const FlagHardRubbishAllocationManualOptions = [
  {
    label: "User",
    value: true,
  },
  {
    label: "System",
    value: false,
  },
];

export const flagHardRubbishCollectionItemLookupOptions = [
  {
    label: "Lookup",
    value: true,
  },
  {
    label: "Custom",
    value: false,
  },
];

export const targetDaysOrHourOptions = [
  {
    label: "Days",
    value: true,
  },
  {
    label: "Hours",
    value: false,
  },
];
