export const mockRebateEntitlementExceptions = [
  {
    Row: "1",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    ENA_Name: "VIKSTROM, Anton Gote",
    ENA_Address:
      "c/- abc\r\nAlan\r\nd\r\nTestabc\r\n3 Adderley Pl\r\nChange is from Advice of Sale - A4115 -\r\n27/11/07\r\nALDERLEY, QLD   4051",
    LastRebateClamId: "10",
    EntityId: "8054",
    AssessmentId: "206",
    RebateEntitlementId: "6507",
  },
  {
    Row: "2",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "Stare Government Rebate - Emergency Service Levy Pensioner",
    ENA_Name: "ABRAHALL, William Jame 222",
    ENA_Address:
      "c/- abc\r\nAlan\r\nd\r\nTestabc\r\n3 Adderley Pl\r\nChange is from Advice of Sale - A4115 -\r\n27/11/07\r\nALDERLEY, QLD   4051",
    LastRebateClamId: "14",
    EntityId: "8054",
    AssessmentId: "206",
    RebateEntitlementId: "6507",
  },
  {
    Row: "3",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "Stare Government Rebate - General Rates Pensioner",
    LastRebateClamId: "9",
    AssessmentId: "206",
    RebateEntitlementId: "6508",
  },
  {
    Row: "4",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "Stare Government Rebate - General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "206",
    RebateEntitlementId: "6508",
  },
  {
    Row: "5",
    AssessmentNumber: "215",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "Stae Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "215",
    RebateEntitlementId: "7346",
  },
  {
    Row: "6",
    AssessmentNumber: "215",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "215",
    RebateEntitlementId: "7347",
  },
  {
    Row: "7",
    AssessmentNumber: "401",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "Stare Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "22",
    AssessmentId: "401",
    RebateEntitlementId: "7272",
  },
  {
    Row: "8",
    AssessmentNumber: "401",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "21",
    AssessmentId: "401",
    RebateEntitlementId: "7273",
  },
  {
    Row: "9",
    AssessmentNumber: "421",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "421",
    RebateEntitlementId: "1748",
  },
  {
    Row: "10",
    AssessmentNumber: "421",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "421",
    RebateEntitlementId: "3267",
  },
  {
    Row: "11",
    AssessmentNumber: "498",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "498",
    RebateEntitlementId: "48",
  },
  {
    Row: "12",
    AssessmentNumber: "498",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "26",
    AssessmentId: "498",
    RebateEntitlementId: "46161",
  },
  {
    Row: "13",
    AssessmentNumber: "528",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "26",
    AssessmentId: "528",
    RebateEntitlementId: "7280",
  },
  {
    Row: "14",
    AssessmentNumber: "528",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "25",
    AssessmentId: "528",
    RebateEntitlementId: "7281",
  },
  {
    Row: "15",
    AssessmentNumber: "576",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "576",
    RebateEntitlementId: "2807",
  },
  {
    Row: "16",
    AssessmentNumber: "576",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "576",
    RebateEntitlementId: "3021",
  },
  {
    Row: "17",
    AssessmentNumber: "693",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClamId: "13",
    AssessmentId: "693",
    RebateEntitlementId: "70",
  },
  {
    Row: "18",
    AssessmentNumber: "693",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    LastRebateClamId: "14",
    AssessmentId: "693",
    RebateEntitlementId: "4865",
  },
  {
    Row: "19",
    AssessmentNumber: "802",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "802",
    EntityId: "9216",
    RebateEntitlementId: "2993",
  },
  {
    Row: "20",
    AssessmentNumber: "911",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "911",
    EntityId: "9249",
    RebateEntitlementId: "6897",
  },
  {
    Row: "21",
    AssessmentNumber: "911",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "911",
    EntityId: "9249",
    RebateEntitlementId: "6898",
  },
  {
    Row: "22",
    AssessmentNumber: "1332",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate Emergency Service Levy Senior",
    LastRebateClamId: "14",
    AssessmentId: "1332",
    RebateEntitlementId: "5862",
  },
  {
    Row: "23",
    AssessmentNumber: "1332",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate General Rates Senior",
    LastRebateClamId: "13",
    AssessmentId: "1332",
    RebateEntitlementId: "5863",
  },
  {
    Row: "24",
    AssessmentNumber: "1604",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "1604",
    RebateEntitlementId: "1849",
  },
  {
    Row: "25",
    AssessmentNumber: "1604",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "1604",
    RebateEntitlementId: "3534",
  },
  {
    Row: "26",
    AssessmentNumber: "1612",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "1612",
    RebateEntitlementId: "123",
  },
  {
    Row: "27",
    AssessmentNumber: "1612",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "1612",
    RebateEntitlementId: "2998",
  },
  {
    Row: "28",
    AssessmentNumber: "1726",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "1726",
    RebateEntitlementId: "1863",
  },
  {
    Row: "29",
    AssessmentNumber: "1726",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "14",
    AssessmentId: "1726",
    RebateEntitlementId: "4546",
  },
  {
    Row: "30",
    AssessmentNumber: "1755",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "15",
    AssessmentId: "1755",
    RebateEntitlementId: "130",
  },
  {
    Row: "31",
    AssessmentNumber: "1755",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "16",
    AssessmentId: "17E5",
    RebateEntitlementId: "3183",
  },
  {
    Row: "32",
    AssessmentNumber: "2097",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    LastRebateClamId: "24",
    AssessmentId: "2097",
    RebateEntitlementId: "6633",
  },
  {
    Row: "33",
    AssessmentNumber: "2097",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClamId: '23"',
    AssessmentId: "2097",
    RebateEntitlementId: "6634",
  },
  {
    Row: "34",
    AssessmentNumber: "2738",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClamId: "15",
    AssessmentId: "2738",
    RebateEntitlementId: "2622",
  },
  {
    Row: "35",
    AssessmentNumber: "2738",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClamId: "17",
    AssessmentId: "2738",
    RebateEntitlementId: "2622",
  },
  {
    Row: "38",
    AssessmentNumber: "2738",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    LastRebateClamId: "16",
    AssessmentId: "2738",
    RebateEntitlementId: "4952",
  },
  {
    Row: "37",
    AssessmentNumber: "3129",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "10",
    AssessmentId: "3129",
    RebateEntitlementId: "3206",
  },
  {
    Row: "38",
    AssessmentNumber: "3136",
    ExceptionType: "No rebate entitlement for Rebate Transaction Amount",
    LastRebateClamId: "12",
    AssessmentId: "3138",
  },
  {
    Row: "39",
    AssessmentNumber: "3136",
    ExceptionType: "No rebate entitlement for Rebate Transaction Amount",
    LastRebateClamId: "11",
    AssessmentId: "3138",
  },
  {
    Row: "40",
    AssessmentNumber: "3182",
    ExceptionType: "No rebate entitlement for Rebate Transaction Amount",
    LastRebateClamId: "12",
    AssessmentId: "3182",
  },
  {
    Row: "41",
    AssessmentNumber: "3339",
    ExceptionType: "No rebate entitlement for Rebate Transaction Amount",
    LastRebateClamId: "11",
    AssessmentId: "3339",
  },
  {
    Row: "42",
    AssessmentNumber: "3360",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "15",
    AssessmentId: "3380",
    RebateEntitlementId: "2074",
  },
  {
    Row: "43",
    AssessmentNumber: "3360",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "Stale Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "16",
    AssessmentId: "3380",
    RebateEntitlementId: "4764",
  },
  {
    Row: "44",
    AssessmentNumber: "4041",
    ExceptionType: "Rebate Given wh?e charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClamId: "13",
    AssessmentId: "4041",
    RebateEntitlementId: "2156",
  },
  {
    Row: "45",
    AssessmentNumber: "4041",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClamId: "26",
    AssessmentId: "4041",
    RebateEntitlementId: "4077",
  },
  {
    Row: "46",
    AssessmentNumber: "4181",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "Side Government Rebate - Emergency Service Levy Senior",
    LastRebateClamId: "24",
    AssessmentId: "4181",
    EntityId: "10715",
    RebateEntitlementId: "7376",
  },
  {
    Row: "47",
    AssessmentNumber: "4181",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClamId: "23",
    AssessmentId: "4181",
    EntityId: "10715",
    RebateEntitlementId: "7377",
  },
];
