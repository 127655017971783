import { CS_CaseDetailType } from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import {
  ServiceStandard,
  ServiceStandardMapObj,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

interface CaseDetailsProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

export const CaseDetailsSection = observer(
  ({ formRenderProps, isDisabled = false }: CaseDetailsProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { serviceStandardLovs } = useCRMSServiceStandardStore();

    var caseDetailEnumValue = valueGetter(
      `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
        "CaseDetails_Form_ENUM"
      )}`
    );

    useEffectOnce(() => {
      if (isNil(caseDetailEnumValue)) {
        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "CaseDetails_Form_ENUM"
          )}`,
          { value: CS_CaseDetailType.Default }
        );
      }
    });

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Case details form" isMandatory />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "CaseDetails_Form_ENUM"
              )}`}
              textField={nameOfKeyValuePacket("Value")}
              dataItemKey={nameOfKeyValuePacket("Key")}
              data={serviceStandardLovs?.CaseDetails ?? []}
              component={CCSearchComboBox}
              validator={requiredValidator}
              value={getDropdownValue(
                caseDetailEnumValue,
                serviceStandardLovs?.CaseDetails ?? [],
                nameOfKeyValuePacket("Key")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(
                  `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                    "CaseDetails_Form_ENUM"
                  )}`,
                  {
                    value: event.value?.[nameOfKeyValuePacket("Key")],
                  }
                );
                if (
                  event.value?.[nameOfKeyValuePacket("Key")] ===
                  CS_CaseDetailType.Default
                ) {
                  onChange(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "CaseDetails_Label"
                    )}`,
                    {
                      value: "",
                    }
                  );
                }
              }}
            />
          </div>
          {!isNil(caseDetailEnumValue) &&
            caseDetailEnumValue !== CS_CaseDetailType.Default && (
              <div className="cc-field">
                <CCLabel
                  title="Additional ref no label"
                  isMandatory={
                    caseDetailEnumValue !== CS_CaseDetailType.Default
                  }
                />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CaseDetails_Label")}`}
                  component={CCInput}
                  validator={
                    caseDetailEnumValue !== CS_CaseDetailType.Default
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            )}
        </div>
      </section>
    );
  }
);
