import { HD_SCREEN_WIDTH } from "@common/constants/responsive";
import { TLinkTo } from "@components/cc-grid/model";
import { Slide } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { ThemeType } from "@styles/constant";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffectOnce, useWindowSize } from "react-use";
import { useCCSubActionBarStore } from "../cc-sub-action-bar/store";
import { getMenuNameByUrlKey, getUrlKey } from "../cc-sub-action-bar/util";
import "./_index.scss";

export interface ICCNavButtonProps {
  htmlFor?: string;
  isActive?: boolean;
  type?: "single" | "sub" | "more" | "dropdown" | "sub-workflow";
  iconClass?: string;
  disabled?: boolean;
  invisible?: boolean;
  className?: string;
  onClick?: (event: any) => void;
  title: string;
  children?: ReactElement | ReactElement[];
  ref?: any;
  isLoading?: boolean;
  name?: string;
  id?: string;
  externalLinkTo?: string;
  linkTo?: TLinkTo;
  isDefaultActive?: boolean;
}

/**
 * @param props Of type {@link ICCNavButtonProps}
 * @param props.isDefaultActive When set to true, the button (of type "sub" or "sub-workflow") will be active if there's no button take the higher priority to show (by pinned / clicked-on / also set props.isDefaultActive=true and having later order in the group).
 */
export const CCNavButton = observer(
  ({
    htmlFor,
    type = "single",
    iconClass,
    name,
    disabled,
    invisible,
    className,
    onClick,
    title,
    children,
    ref,
    isLoading,
    id,
    isActive: propIsActive,
    externalLinkTo,
    linkTo,
    isDefaultActive = false,
  }: ICCNavButtonProps) => {
    const [isActive, setIsActive] = useState<boolean | undefined>(false);
    const { menuName, setMenu, resetMenu, setIsPin, setIsWorkflow } =
      useCCSubActionBarStore();
    const { pathname } = useLocation();
    const { width } = useWindowSize();
    const isDesktop = useMemo(() => {
      return width >= HD_SCREEN_WIDTH;
    }, [width]);

    const currentTheme = localStorage.getItem("OOTheme");

    useEffectOnce(() => {
      if ((type !== "sub" && type !== "sub-workflow") || !children) return;

      const urlKey = getUrlKey(pathname);
      const menuName: string | undefined = getMenuNameByUrlKey(urlKey);
      if (!menuName) {
        resetMenu();
        return;
      }
      if (menuName !== title) return;
      setIsActive(true);
      setMenu(title, children);
      setIsPin(true);
      setIsWorkflow(type === "sub-workflow");
    });

    useEffect(() => {
      if (
        ((menuName && menuName === title) || isDefaultActive) &&
        (type === "sub" || type === "sub-workflow")
      ) {
        setMenu(menuName ?? title, children);
        setIsWorkflow(type === "sub-workflow");
      }
    }, [
      menuName,
      children,
      title,
      type,
      isDefaultActive,
      setMenu,
      setIsWorkflow,
    ]);

    useEffect(() => {
      setIsActive(propIsActive);
    }, [propIsActive]);

    useEffect(() => {
      if (type !== "sub" && type !== "sub-workflow") return;
      setIsActive(menuName === title && !isNil(children));
    }, [menuName, type, title, children]);

    const handleOnClick = useCallback(
      (event: any) => {
        event.preventDefault();

        if (type === "more" && children) {
          setIsActive(!isActive);
          return;
        }

        if (type === "dropdown" && !isDesktop && children) {
          setIsActive(!isActive);
          return;
        }

        if (type === "sub" || type === "sub-workflow") {
          if (!isActive && children) {
            setMenu(title, children);
          } else {
            resetMenu();
          }

          setIsWorkflow(type === "sub-workflow");
          return;
        }

        if (onClick) onClick(event);
      },
      [
        onClick,
        type,
        isActive,
        setMenu,
        resetMenu,
        title,
        children,
        isDesktop,
        setIsWorkflow,
      ]
    );

    const buttonClassName = useMemo(() => {
      const classNameType = iconClass ? "cc-nav-icon-button" : "cc-nav-button";
      let defaultClassName = classNameType;
      if (disabled || isLoading)
        defaultClassName += ` ${classNameType}-disabled`;
      if (isActive) defaultClassName += ` ${classNameType}-active`;
      if (className) defaultClassName += " " + className;

      return defaultClassName;
    }, [iconClass, disabled, isLoading, isActive, className]);

    const renderButton = () => {
      if (invisible) return;
      if (htmlFor) {
        return (
          <label
            key={title}
            ref={ref}
            htmlFor={htmlFor}
            className={"k-button " + buttonClassName}
          >
            <i className={isLoading ? `fas fa-spinner fa-spin` : ""} />
            {title}
          </label>
        );
      } else {
        if (type === "more" && (isActive || !children)) return null; //check children to not render button If there aren't any options available
        if (linkTo)
          return (
            <Link
              className={`k-button cc-nav-button cc-nav-link ${
                disabled && " cc-nav-button-disabled "
              }`}
              to={linkTo}
            >
              {iconClass ? undefined : title}
            </Link>
          );
        return (
          <Button
            key={title}
            ref={ref}
            title={title}
            fillMode={iconClass ? "flat" : "solid"}
            disabled={disabled}
            iconClass={isLoading ? "fas fa-spinner fa-spin" : iconClass}
            onClick={handleOnClick}
            className={buttonClassName}
            name={name}
            id={id}
          >
            {iconClass ? undefined : (
              <span className="cc-nav-title" id={id ?? ""}>
                {title}
              </span>
            )}
            {(type === "sub" ||
              type === "sub-workflow" ||
              type === "dropdown") &&
            children ? (
              <span className="k-icon k-i-arrow-60-down cc-nav-arrow-icon"></span>
            ) : null}
          </Button>
        );
      }
    };

    const renderChildren = () => {
      // if (!children) return null;
      switch (type) {
        case "more":
          return (
            //@TODO: Slide animation css with direction right and down. Remove Slide kendo after that
            <div className={`${isActive ? "cc-nav-more-slide" : ""}`}>
              <div
                className={`cc-nav-more-menu ${isActive ? "" : "cc-hidden"}`}
              >
                <div className="cc-nav-more-children">{children}</div>
                <Button
                  key={"cc-close-button"}
                  fillMode="flat"
                  className="cc-close-button"
                  iconClass={"fal fa-times"}
                  onClick={() => {
                    setIsActive(false);
                  }}
                />
              </div>
            </div>
          );
        case "sub":
        case "sub-workflow":
          return (
            <Slide direction="down" className="cc-nav-sub-menu-animation">
              {children && isActive ? (
                <div className="cc-nav-sub-menu">{children}</div>
              ) : null}
            </Slide>
          );

        case "dropdown":
          return (
            <div
              className={`cc-nav-dropdown-menu ${
                isActive ? "cc-nav-dropdown-menu-active" : ""
              }`}
            >
              {children}
            </div>
          );
        default:
          return null;
      }
    };
    if (externalLinkTo)
      return (
        <a
          href={externalLinkTo}
          target="_blank"
          rel="noopener noreferrer"
          className={`
            cc-nav-button cc-nav-link
              ${disabled && " cc-nav-button-disabled "}
              ${className && ` ${className}`}
            `}
        >
          {iconClass ? <i className={iconClass}></i> : title}
        </a>
      );
    return (
      <>
        <div className={`cc-nav-button-container cc-nav-type-${type}`}>
          {type === "more" && currentTheme === ThemeType.Default && (
            <div className="cc-separates"></div>
          )}
          {renderButton()}
          {renderChildren()}
        </div>
      </>
    );
  }
);
