import { Svc_Registration_FeeOutstanding } from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/animal-registration-details/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Registration_FeeOutstanding>();
export const colRegistrationFeeOutstanding: IColumnFields[] = [
  {
    field: nameOf("FeeType"),
    title: "Fee type",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Amount"),
    title: "Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
