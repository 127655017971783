import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { IAllowedFileTypes } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/document-settings/model";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { AllowFileTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/allow-file-type-picker/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
const nameOfCoreKeyword = nameOfFactory<IAllowedFileTypes>();
export const PublicPortalDocumentSettingForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();
    const { onChange, valueGetter } = formRenderProps;

    const fieldMaximumUploadSize = getConfigDataField(
      ECorporateSettingsField.EPlanning_Document_MaxUploadSize
    );
    const fieldChangeFilenameOnUpload = getConfigDataField(
      ECorporateSettingsField.EPlanning_Document_Flag_ChangeFilename
    );
    const fieldAllowedFileTypes = getConfigDataField(
      ECorporateSettingsField.EPlanning_Document_FileTypesAllowed
    );

    const allowFileValueDisplayDetails: IAllowedFileTypes[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.EPlanning_Document_FileTypesAllowed}`
      ) ?? [];

    const handleSubmitAllowFileType = (data: IAllowedFileTypes[]) => {
      const dataSubmit: string[] = [];
      onChange(fieldAllowedFileTypes?.FieldName ?? "", {
        value: data,
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.EPlanning_Document_FileTypesAllowed}`,
        {
          value: data,
        }
      );
      data.forEach((item: IAllowedFileTypes) => {
        if (item.Name !== "") {
          dataSubmit.push(item.Name);
        } else {
          dataSubmit.push(item.Id);
        }
      });
      onChange(fieldAllowedFileTypes?.FieldName ?? "", {
        value: dataSubmit.toString(),
      });
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          {fieldMaximumUploadSize && (
            <div className="cc-field">
              <CCLabel
                title={fieldMaximumUploadSize?.Title ?? ""}
                isMandatory={fieldMaximumUploadSize.IsMandatory}
              />
              <Field
                name={fieldMaximumUploadSize?.FieldName}
                component={CCInput}
                disabled={!isEditing}
                validator={
                  fieldMaximumUploadSize.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {fieldChangeFilenameOnUpload && (
            <div className="cc-field">
              <CCLabel
                title={fieldChangeFilenameOnUpload?.Title ?? ""}
                isMandatory={fieldChangeFilenameOnUpload.IsMandatory}
              />
              <Field
                name={fieldChangeFilenameOnUpload?.FieldName}
                component={CCSwitch}
                disabled={!isEditing}
                checked={formRenderProps?.valueGetter(
                  fieldChangeFilenameOnUpload?.FieldName
                )}
              />
            </div>
          )}
          {fieldAllowedFileTypes && (
            <div className="cc-field">
              <CCLabel
                title={fieldAllowedFileTypes?.Title ?? ""}
                isMandatory={fieldAllowedFileTypes.IsMandatory}
              />
              <Field
                name={`option.ValueDisplayDetails_${ECorporateSettingsField.EPlanning_Document_FileTypesAllowed}`}
                placeholder={fieldAllowedFileTypes?.Title ?? ""}
                value={allowFileValueDisplayDetails}
                component={AllowFileTypesPicker}
                onPickKeyWords={handleSubmitAllowFileType}
                selectableMode={"multiple"}
                listData={fieldAllowedFileTypes?.LookupItems ?? []}
                selectedRows={allowFileValueDisplayDetails ?? []}
                disabled={!isEditing}
                textField={nameOfCoreKeyword("Name")}
                textProduce={(data: IAllowedFileTypes[]) =>
                  data
                    .reduce((pre, cur) => pre + ", " + cur.Id, "")
                    .slice(1)
                    .trim()
                }
                validator={
                  fieldAllowedFileTypes.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
