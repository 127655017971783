import { IDataForSendBackDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ConfirmSendBackDialogStore {
  private _dataForSendBackDialog?: IDataForSendBackDialog = undefined;
  private _isLoadingSendBack: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get dataForSendBackDialog(): IDataForSendBackDialog | undefined {
    return this._dataForSendBackDialog;
  }
  setDataForSendBackDialog = (
    dataForSendBackDialog?: IDataForSendBackDialog
  ) => {
    runInAction(() => {
      this._dataForSendBackDialog = dataForSendBackDialog;
    });
  };

  get isLoadingSendBack(): boolean {
    return this._isLoadingSendBack;
  }
  setIsLoadingSendBack = (isLoadingSendBack: boolean) => {
    runInAction(() => {
      this._isLoadingSendBack = isLoadingSendBack;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoadingSendBack = false;
      this._dataForSendBackDialog = undefined;
    });
  };
}

const confirmSendBackDialogStoreContext = createContext(
  new ConfirmSendBackDialogStore()
);
export const useConfirmSendBackDialogStore = () => {
  return useContext(confirmSendBackDialogStoreContext);
};
