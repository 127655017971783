import { SearchAssessmentField } from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/components/search-assessment/_index";
import { mockCreateJournalDialog } from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/mock";
import {
  ICreateJournalDialog,
  SearchType,
} from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/model";
import { comboBoxSearchAssessmentData } from "@app/products/property/meters/special-reading-list/components/dialogs/create-journal/components/search-assessment/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<ICreateJournalDialog>();
interface ICreateNoticeRunDialog {
  onClose: () => void;
  onSubmit: (event: any) => void;
}
export interface IDataGridDialog {
  SessionNumber: number;
  Name: string;
}

const searchTypeData = Object.values(SearchType);

export const CreateJournalDialog = observer(
  ({ onClose, onSubmit }: ICreateNoticeRunDialog) => {
    return (
      <>
        <Form
          initialValues={{
            ...mockCreateJournalDialog,
            ...comboBoxSearchAssessmentData,
          }}
          render={(formRenderProps: FormRenderProps) => {
            const { valueGetter, onChange } = formRenderProps;
            const BillingGroupsInclude = valueGetter(
              nameOf("BillingGroupsToInclude")
            );
            const BillingGroupsIncludeList = valueGetter(
              nameOf("BillingGroupsToIncludeList")
            );
            const SelectionOfUsages = valueGetter(nameOf("SelectionOfUsages"));
            const SelectionOfUsagesList = valueGetter("SelectionOfUsagesList");
            const searchBy = valueGetter("SearchBy");

            const handleOnSearchAssessment = (value: any) => {
              if (value?.Owners && value?.PropertyAddress) {
                onChange(nameOf("Owners"), { value: value.Owners });
                onChange(nameOf("PropertyAddress"), {
                  value: value.PropertyAddress,
                });
              }
            };

            return (
              <FormElement>
                <CCDialog
                  height="auto"
                  maxWidth="40%"
                  titleHeader={"Create Journal with Usages"}
                  onClose={onClose}
                  bodyElement={
                    <>
                      <div className="cc-form cc-create-journal-dialog">
                        <section className="cc-field-group">
                          <section>
                            <label className="cc-label">
                              Selection of usages
                            </label>
                            <div className="cc-custom-sub-panel-bar">
                              <div className="cc-form-cols-2">
                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">Usages</label>
                                  <Field
                                    name={nameOf("SelectionOfUsages")}
                                    data={SelectionOfUsagesList}
                                    component={CCSearchComboBox}
                                    textField="Value"
                                    dataItemKey="Key"
                                    value={getDropdownValue(
                                      SelectionOfUsages,
                                      SelectionOfUsagesList,
                                      "Key"
                                    )}
                                    onChange={(event: ComboBoxChangeEvent) => {
                                      onChange(nameOf("SelectionOfUsages"), {
                                        value: event.target.value?.Key ?? null,
                                      });
                                    }}
                                  />
                                </div>
                                {SelectionOfUsages ===
                                SelectionOfUsagesList[0]?.Value ? (
                                  <>
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Billing groups to include
                                      </label>
                                      <Field
                                        name={nameOf("BillingGroupsToInclude")}
                                        data={BillingGroupsIncludeList}
                                        component={CCSearchComboBox}
                                        textField="Value"
                                        dataItemKey="Key"
                                        value={getDropdownValue(
                                          BillingGroupsInclude,
                                          BillingGroupsIncludeList,
                                          "Key"
                                        )}
                                        onChange={(
                                          event: ComboBoxChangeEvent
                                        ) => {
                                          onChange(
                                            nameOf("BillingGroupsToInclude"),
                                            {
                                              value:
                                                event.target.value?.Key ?? null,
                                            }
                                          );
                                        }}
                                      />
                                    </div>
                                    {BillingGroupsInclude ===
                                      BillingGroupsIncludeList[1]?.Value && (
                                      <div className="cc-field">
                                        <label className="cc-label">
                                          Billing groups
                                        </label>
                                        <Field
                                          name={nameOf("OnlyBillingGroups")}
                                          data={valueGetter(
                                            nameOf("OnlyBillingGroupsList")
                                          )}
                                          component={CCSearchComboBox}
                                          textField="Value"
                                          dataItemKey="Key"
                                          value={getDropdownValue(
                                            valueGetter(
                                              nameOf("OnlyBillingGroups")
                                            ),
                                            valueGetter(
                                              nameOf("OnlyBillingGroupsList")
                                            ) ?? [],
                                            "Key"
                                          )}
                                          onChange={(
                                            event: ComboBoxChangeEvent
                                          ) => {
                                            onChange(
                                              nameOf("OnlyBillingGroups"),
                                              {
                                                value:
                                                  event.target.value?.Key ??
                                                  null,
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Utilities for which to raise charges
                                      </label>
                                      <Field
                                        name={nameOf("UtilitiesRaiseCharges")}
                                        data={valueGetter(
                                          nameOf("UtilitiesRaiseChargesList")
                                        )}
                                        component={CCSearchComboBox}
                                        textField="Value"
                                        dataItemKey="Key"
                                        value={getDropdownValue(
                                          valueGetter(
                                            nameOf("UtilitiesRaiseCharges")
                                          ),
                                          valueGetter(
                                            nameOf("UtilitiesRaiseChargesList")
                                          ) ?? [],
                                          "Key"
                                        )}
                                        onChange={(
                                          event: ComboBoxChangeEvent
                                        ) => {
                                          onChange(
                                            nameOf("UtilitiesRaiseCharges"),
                                            {
                                              value:
                                                event.target.value?.Key ?? null,
                                            }
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : SelectionOfUsages ===
                                  SelectionOfUsagesList[1] ? (
                                  <div className="cc-field">
                                    <label className="cc-label">
                                      Utilities for which to raise charges
                                    </label>
                                    <Field
                                      name={nameOf("UtilitiesRaiseCharges")}
                                      data={valueGetter(
                                        nameOf("UtilitiesRaiseChargesList")
                                      )}
                                      component={CCSearchComboBox}
                                      textField="Value"
                                      dataItemKey="Key"
                                      value={getDropdownValue(
                                        valueGetter(
                                          nameOf("UtilitiesRaiseCharges")
                                        ),
                                        valueGetter(
                                          nameOf("UtilitiesRaiseChargesList")
                                        ) ?? [],
                                        "Key"
                                      )}
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        onChange(
                                          nameOf("UtilitiesRaiseCharges"),
                                          {
                                            value:
                                              event.target.value?.Key ?? null,
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Search type
                                      </label>
                                      <Field
                                        name={nameOf("SearchBy")}
                                        data={searchTypeData}
                                        component={DropDownList}
                                      />
                                    </div>
                                    <div className="cc-field">
                                      <SearchAssessmentField
                                        label="Search assessment"
                                        fieldName={searchBy}
                                        formRenderProps={formRenderProps}
                                        onChange={(values: any) => {
                                          handleOnSearchAssessment(values);
                                        }}
                                      />
                                    </div>
                                    <div className="cc-field">
                                      <label className="cc-label">Owners</label>
                                      <Field
                                        name={nameOf("Owners")}
                                        value={valueGetter(nameOf("Owners"))}
                                        component={CCInput}
                                        readOnly
                                      />
                                    </div>
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Property address
                                      </label>
                                      <Field
                                        name={nameOf("PropertyAddress")}
                                        value={valueGetter(
                                          nameOf("PropertyAddress")
                                        )}
                                        component={CCInput}
                                        readOnly
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </section>
                          <section>
                            <label className="cc-label">Charge details</label>
                            <div className="cc-custom-sub-panel-bar">
                              <div className="cc-form-cols-2">
                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">Date</label>
                                  <Field
                                    name={nameOf("Date")}
                                    component={CCDatePicker}
                                    format={DATE_FORMAT.DATE_CONTROL}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">Name</label>
                                  <Field
                                    name={nameOf("Name")}
                                    component={CCInput}
                                    value={valueGetter(nameOf("Name"))}
                                  />
                                </div>
                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Description
                                  </label>
                                  <Field
                                    name={nameOf("Description")}
                                    component={CCTextArea}
                                    rows={3}
                                    placeholder="Description"
                                    value={valueGetter(nameOf("Description"))}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Instalment plan
                                  </label>
                                  <Field
                                    name={nameOf("InstalmentPlan")}
                                    data={valueGetter(
                                      nameOf("InstalmentPlanList")
                                    )}
                                    component={CCSearchComboBox}
                                    textField="Value"
                                    dataItemKey="Key"
                                    value={getDropdownValue(
                                      valueGetter(nameOf("InstalmentPlan")),
                                      valueGetter(
                                        nameOf("InstalmentPlanList")
                                      ) ?? [],
                                      "Key"
                                    )}
                                    onChange={(event: ComboBoxChangeEvent) => {
                                      onChange(nameOf("InstalmentPlan"), {
                                        value: event.target.value?.Key ?? null,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Apply any remaining rebate entitlements to
                                    charge
                                  </label>
                                  <Field
                                    name={nameOf(
                                      "ApplyRemainingRebateEntitlements"
                                    )}
                                    component={CCSwitch}
                                    checked={valueGetter(
                                      nameOf("ApplyRemainingRebateEntitlements")
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>
                      </div>
                    </>
                  }
                  footerElement={
                    <div className={"cc-dialog-footer-actions-right"}>
                      <Button className={"cc-dialog-button"} onClick={onClose}>
                        Cancel
                      </Button>

                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={onSubmit}
                      >
                        OK
                      </Button>
                    </div>
                  }
                />
              </FormElement>
            );
          }}
        />
      </>
    );
  }
);
