import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import {
  ContactForm,
  IContactFormProps,
} from "@common/pages/contacts/contact-form/_index";
import { ContactRegisterForm } from "@common/pages/contacts/manage-contact/model";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
export const NewManageContact = ({
  setIsLoading,
  contactInfo,
}: IContactFormProps) => {
  return (
    <div className="cc-new-contact">
      <FormNavigation title={"Contact"} />
      <FormTitle title="New Contact" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Issue registration"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Attachment"} />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Communication"} />
            <CCNavButton title={"Complaint"} />
            <CCNavButton title={"Document"} />
            <CCNavButton title={"Fee"} />
            <CCNavButton title={"Document"} />
            <CCNavButton title={"Fee"} />
            <CCNavButton title={"Inspection"} />
            <CCNavButton title={"Notice"} />
            <CCNavButton title={"Prosecution"} />
            <CCNavButton title={"Related"} />
          </CCNavButton>,

          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <div className="cc-manage-form-body">
            <CCGeneralPanel
              component={
                <ContactForm
                  // Override default value for Microchip switch box
                  contactInfo={
                    {
                      ...contactInfo,
                    } as ContactRegisterForm
                  }
                  setIsLoading={setIsLoading}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
