import { eventEmitter } from "@/App";
import { SendBackDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/send-back/_index";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowProcessMode } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ConfirmSendBackDialog = observer(() => {
  const {
    isLoadingSendBack,
    dataForSendBackDialog,
    setDataForSendBackDialog,
    setIsLoadingSendBack,
    resetStore,
  } = useConfirmSendBackDialogStore();
  const { removeDialog, listDialog } = usePropertyWorkflowStore();
  const { clearSelectedItems } = useCCProductListViewStore();

  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleSendBackWorkflow = async (value: any) => {
    if (!dataForSendBackDialog) return;

    setNotifications([]);
    setIsLoadingSendBack(true);
    appNotificationStore.clearErrorNotification();

    const {
      sendBackCallback,
      dataSendBack,
      defaultSuccessMessage,
      defaultErrorMessage,
    } = dataForSendBackDialog;
    const sendBackData = {
      ...dataSendBack,
      WorkflowHeader: {
        ...dataSendBack?.WorkflowHeader,
        WorkflowDraft: {
          ...dataSendBack?.WorkflowHeader?.WorkflowDraft,
          WD_SendBack_Reason_Id: value?.Reason,
          WD_SendBack_Additional_Comments: value?.Comments,
        },
      },
    };
    const response = await sendBackCallback(
      WorkflowProcessMode.SendBack,
      sendBackData
    );

    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      appNotificationStore.pushNotification({
        title: response.data?.Notification ?? defaultSuccessMessage,
        type: "success",
      });

      setDataForSendBackDialog(undefined);

      if (listDialog?.[0]?.type) {
        removeDialog(listDialog[0].type);
      }
      if (listDialog?.[0]?.typeList) {
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: response?.data?.ErrorMessage ?? defaultErrorMessage,
          type: "error",
        },
      ]);
    }

    setIsLoadingSendBack(false);
    setDataForSendBackDialog(undefined);

    if (listDialog?.[0]?.type) {
      removeDialog(listDialog[0].type);
    }

    if (listDialog?.[0]?.typeList) {
      eventEmitter.emit(CCGridEventType.RefreshOData);
    }
  };

  useEffectOnce(() => {
    return () => resetStore();
  });

  return (
    <>
      {dataForSendBackDialog && (
        <SendBackDialog
          onSubmitData={handleSendBackWorkflow}
          onClose={() => {
            setNotifications([]);
            setDataForSendBackDialog(undefined);
          }}
          isLoadingSendData={isLoadingSendBack}
          notifications={notifications}
        />
      )}
    </>
  );
});
