import { loadAddressValidationSearch } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/api";
import {
  colAddressValidationSearch,
  searchAddressValidationConfig,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/config";
import { NSWPointSuggestion } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/model";
import "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/_index.scss";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isUndefined } from "lodash";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddressValidationSearchProps extends ComboBoxProps {
  visited?: boolean;
  onError?: (value: any) => void;
  textProduce?: (value: any) => string | undefined;
  isLoadingParent?: boolean;
  textField?: string;
}

export const AddressValidationSearch = (
  props: IAddressValidationSearchProps
) => {
  const {
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onError,
    textProduce,
    isLoadingParent,
    textField = value?.address ?? "",
    ...others
  } = props;
  const [data, setData] = useState<NSWPointSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);

  const inputDisplayName = useMemo(() => {
    if (isUndefined(value)) return "";
    if (textProduce) return textProduce(value);
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value?.text ?? "";
  }, [value, textField, textProduce]);

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = event.filter.value;
    if (searchText.length < searchAddressValidationConfig.minCharacters) {
      setData([]);
      return;
    }

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      setIsLoading(true);
      loadAddressValidationSearch(searchText).then((response) => {
        if (isSuccessResponse(response)) {
          setData(response.data?.suggestions ?? []);
        } else {
          if (onError) onError(response.error);
        }
        setIsLoading(false);
      });
    }, searchAddressValidationConfig.typeSpeed);
  };

  useEffectOnce(() => {
    return () => {
      if (refTimeOut.current) clearTimeout(refTimeOut.current);
    };
  });

  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (!onChange) return;
    const newValue = event.value as NSWPointSuggestion | null;
    if (!newValue) return onChange({ ...event, value: null });
    return onChange({
      ...event,
      value: newValue,
    });
  };

  return (
    <div className={`${className ?? ""} cc-search-combobox-new`}>
      <ComboBox
        {...others}
        filterable
        suggest
        data={data}
        itemRender={ItemRender}
        loading={isLoading || isLoadingParent}
        onFilterChange={handleSearch}
        onChange={handleOnChange}
        value={inputDisplayName}
        header={
          <div className="cc-search-header">
            {colAddressValidationSearch.map((col: IColumnFields) => (
              <div key={col.field} style={{ width: col.width }}>
                {col.title}
              </div>
            ))}
          </div>
        }
        popupSettings={{ className: "cc-property-address-picker-search" }}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {colAddressValidationSearch.map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {dataItem[col.field] ?? ""}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
