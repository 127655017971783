export class IntegratedReportImport {
  Name?: string;
  Xml?: string;
  CreatedDate?: string | null;
  ModifiedDate?: string | null;
  TenantID?: string;
  Form?: string;
  IzendaAdHocReportsID?: number;
  ReportSourceID?: number;
  Thumbnail?: string;
  Category?: string;
  ReportName?: string;
  IsDashboard?: boolean;
}
