import { ExportFormat } from "@components/cc-grid/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface IActionBarExportIconProps {
  disabled?: boolean;
  isVisible?: boolean;
}

export const ActionBarExportIcon = observer(
  ({ disabled, isVisible = true }: IActionBarExportIconProps) => {
    const { setGridExportFormat, isEmptyGridSelectedRow } =
      useCCProductListViewStore();

    if (!isVisible) return null;
    return (
      <CCNavButton
        iconClass="fal fa-download"
        title="Export to Excel"
        disabled={isEmptyGridSelectedRow || disabled}
        onClick={() => {
          setGridExportFormat(ExportFormat.EXCEL);
        }}
      />
    );
  }
);
