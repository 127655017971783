import { DTO_Component_Lineage } from "@app/products/property/components/lineage/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getLineage = async (
  apiUrl: string
): Promise<DTO_Component_Lineage | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_Lineage>(apiUrl);
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
