import { actionTaskScheduleData } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/raise-assessment-interest/components/form-elements/task-schedule/config";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInput } from "@components/cc-input/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";

export const TaskScheduleFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
export const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Task type</label>
          <Field
            name={nameOf("TaskType")}
            component={CCInput}
            placeholder={"Task type"}
            readOnly
          />
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <label className="cc-label">Action</label>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <div className="cc-control-item">
              <Field
                name={nameOf("RunImmediately")}
                component={RadioGroup}
                data={actionTaskScheduleData}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        </div>
        {getFieldValue("RunImmediately") === false ? (
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <Field
                name={nameOf("RunAt")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled={options?.isReadOnly}
              />
            </div>
          </div>
        ) : null}
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <Field
              label="Email recipients"
              name={nameOf("EmailRecipients")}
              placeholder={"Email recipients"}
              readOnly={options?.isReadOnly}
              component={CCInputEmail}
              validator={!options?.isReadOnly ? validateEmail : undefined}
              onChangeForm={formRenderProps.onChange}
              isTextArea
              rows={3}
            />
          </div>
        </div>
      </section>
    </>
  );
};
