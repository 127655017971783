import {
  addressFields,
  officerFields,
} from "@app/products/building/registers/certificates/[id]/components/child-screens/general/components/form-element/config";
import {
  dropdownCertificateTypeData,
  parentPage,
} from "@app/products/building/registers/certificates/[id]/components/child-screens/general/components/form-element/mock";
import { IBuildingRegistersCertificatesFormData } from "@app/products/building/registers/certificates/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory, populateValue } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
import { useHistory } from "react-router-dom";

const nameOf = nameOfFactory<IBuildingRegistersCertificatesFormData>();
interface IBuildingRegistersCertificatesFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegistersCertificatesFormElement = ({
  formRenderProps,
}: IBuildingRegistersCertificatesFormElementProps) => {
  const history = useHistory();
  const { pushNotification } = useCCAppNotificationStore();

  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }

    if (!formRenderProps.valid) return;

    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  const getValue = formRenderProps.valueGetter;

  return (
    <FormElement>
      {/* Reference by a label in WastewaterActionBar.tsx */}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />

      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Contact
              <CCTooltip
                type="validator"
                position="right"
                content={formRenderProps.errors.Contact}
              />
            </label>

            <Field
              onClick={() => {
                history.push(`/managecontactrelationship/${"123"}`, {
                  parentPage,
                  recordType: "11000",
                });
              }}
              autoComplete="off"
              name={"Contact"}
              placeholder={"Contact"}
              component={InputPicker}
              validator={validateRequired}
            />
          </div>
          <CCValueField
            label="Address"
            value={populateValue(getValue(nameOf("Contact_Address")))}
          />
          <CCValueField
            label="Contact"
            value={populateValue(getValue(nameOf("Contact_Contact")))}
          />
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Site address
              <CCTooltip
                type="validator"
                position="right"
                content={formRenderProps.errors.Address}
              />
            </label>
            <Field
              component={InputPicker}
              {...addressFields(formRenderProps)}
              name={nameOf("Address")}
              validator={validateRequired}
            />
          </div>
          <CCValueField
            label="Owner"
            value={populateValue(getValue(nameOf("Owner")))}
          />
          <CCValueField
            label="Details"
            value={populateValue(getValue(nameOf("Details")))}
          />
        </div>
      </div>
      <hr className="cc-divider" />
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Certificate date</label>
            <Field
              name={nameOf("CertificateDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Certificate number</label>
            <Field
              name={nameOf("Certificate")}
              placeholder={"Certificate number"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Certificate type</label>
            <Field
              name={nameOf("CertificateType")}
              dataItemKey="Key"
              textField="Value"
              data={dropdownCertificateTypeData}
              isUseDefaultOnchange
              component={CCSearchComboBox}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Issued
              <CCTooltip
                type="validator"
                position="right"
                content={formRenderProps.errors.Address}
              />
            </label>
            <Field
              component={InputPicker}
              {...officerFields(formRenderProps)}
              validator={validateRequired}
              name={nameOf("Officer")}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Issued date</label>
            <Field
              name={nameOf("Issue")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">File number</label>
            <Field
              name={nameOf("FileNumber")}
              placeholder={"File number"}
              component={CCInput}
            />
          </div>
        </div>
      </div>
    </FormElement>
  );
};
