import { CandidatesDialog } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/dialogs/candidates/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const CandidatesButton = () => {
  const [showCandidatesDialog, setShowCandidatesDialog] =
    useState<boolean>(false);

  return (
    <>
      <CCNavButton
        title="Candidates"
        onClick={() => setShowCandidatesDialog(true)}
      />

      {showCandidatesDialog && (
        <CandidatesDialog
          onClose={() => {
            setShowCandidatesDialog(false);
          }}
        />
      )}
    </>
  );
};
