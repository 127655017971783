import { history } from "@/AppRoutes";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { PUBLIC_HOLIDAYS_ROUTE } from "@common/pages/settings/lookups/public-holidays/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export interface IYearDropDownProps {
  defaultItem?: IKeyValuePacket;
}

export const YearDropDown = ({
  defaultItem = { Key: 0, Value: "Year" },
}: IYearDropDownProps) => {
  const [dataYear, setDataYear] = useState<IKeyValuePacket[]>([]);
  const renderData = () => {
    const dateNow = new Date();
    const yearNow = dateNow.getFullYear();
    const data: IKeyValuePacket[] = [];
    for (let year = yearNow; year < yearNow + 10; year++) {
      data.push({ Key: year, Value: year.toString() });
    }
    setDataYear(data);
  };
  useEffectOnce(() => {
    renderData();
  });
  return (
    <div className="cc-year-dropdown-container cc-drop-down-list-wrap">
      <CCDropDownList
        data={dataYear}
        className="cc-year-dropdown cc-navbar-dropdown-tree"
        defaultValue={defaultItem}
        popupSettings={{ className: "cc-year-dropdown-popup" }}
        dataItemKey={nameOfKeyValuePacket("Key")}
        textField={nameOfKeyValuePacket("Value")}
        onChange={(event: DropDownListChangeEvent) => {
          if (event.value !== 0)
            history.push(`${PUBLIC_HOLIDAYS_ROUTE}/${event.value}`);
        }}
        isKeyValueDropdown
      ></CCDropDownList>
    </div>
  );
};
