import { KeyValuePair } from "@app/core/documents/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export interface IKeywordsDataByType {
  ModulesUsed: string;
  ParentKeyword: KeyValuePair<number, string>;
  FSRCode: KeyValuePair<number, string>;
  LinkEnums: KeyValuePair<number, string>;
}

export interface ILookupKeywordsNotification {
  notification: IAppNotificationItemAddProps[];
}

export enum LookupKeywordSubmitActions {
  Save = "Save",
  New = "New",
  Delete = "Delete",
}
