import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter } = formRenderProps;

  const data = useDataSetting(configData, [
    "CorporateSettings_EnablePrinting",
    "CorporateSettings_DefaultPrinterName",
    "CorporateSettings_PrintingAgentPath",
    "CorporateSettings_PrintingAgentFileContainer",
  ]);

  return (
    <>
      <section className="cc-field-group">
        {data.CorporateSettings_EnablePrinting && (
          <div className="cc-form-cols-1">
            <SwitchGlobalSettings
              data={data.CorporateSettings_EnablePrinting}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
        )}

        {valueGetter(
          data.CorporateSettings_EnablePrinting?.FieldName as string
        ) && (
          <>
            <div className="cc-form-cols-3">
              {data.CorporateSettings_DefaultPrinterName && (
                <CCSearchComboBoxGlobalSettings
                  data={data.CorporateSettings_DefaultPrinterName}
                  isEditing={isEditing}
                />
              )}
              {data.CorporateSettings_PrintingAgentPath && (
                <InputGlobalSettings
                  data={data.CorporateSettings_PrintingAgentPath}
                  isEditing={isEditing}
                />
              )}
              {data.CorporateSettings_PrintingAgentFileContainer && (
                <InputGlobalSettings
                  data={data.CorporateSettings_PrintingAgentFileContainer}
                  isEditing={isEditing}
                />
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
});
