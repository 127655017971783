import { IAddGISReferenceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/add-gis-reference/model";
import { AddGISReferenceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/add-gis-reference/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { gisReferenceDataGridToArray } from "@app/products/property/registers/[id]/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";

export const AddGISReferenceButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { addGISReference } = useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handelOnSubmit = (data: IAddGISReferenceDialog) => {
    addGISReference(gisReferenceDataGridToArray(data.GISReferenceGrid));
    setShowDialog(false);
    pushNotification({
      title: "GIS reference added successfully.",
      type: "success",
    });
  };

  return (
    <Fragment>
      <CCNavButton title={"Add"} onClick={() => setShowDialog(true)} />
      {showDialog && (
        <AddGISReferenceDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={(data: IAddGISReferenceDialog) => {
            handelOnSubmit(data);
          }}
        />
      )}
    </Fragment>
  );
});
