import { getData } from "@app/products/crms/phone-messages/[id]/api";
import { searchAddressesConfig } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/property-address-picker/config";
import { isSuccessResponse } from "@common/apis/util";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ListItemProps,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import React, { ReactElement, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IMultiSelectSearchProps extends MultiSelectProps {
  visited?: boolean;
  onError: (value: any) => void;
  textField?: string;
  dataItemKey?: string;
  name: string;
  columnFields: IColumnFields[];
  formRenderProps: FormRenderProps;
  dataUrl: string;
  filterField?: string;
  refactorData?: (data: any[]) => any[];
  onChangeHandler?: (event: MultiSelectChangeEvent) => void;
  listID?: number[];
  setIsGetDataLoading?: (isLoadingGetContact: boolean) => void;
}

export const MultiSelectSearch = (props: IMultiSelectSearchProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(undefined);
  const [value, setValue] = useState([]);
  const refTimeOut = useRef<NodeJS.Timeout | null>(null);

  const {
    visited,
    validationMessage,
    textField,
    onError,
    name,
    columnFields,
    dataUrl,
    dataItemKey,
    filterField,
    formRenderProps,
    refactorData,
    onChangeHandler,
    listID,
    disabled,
    setIsGetDataLoading,
  } = props;

  const { onChange: onChangeForm } = formRenderProps;

  const getContacts = async (filterSearch?: string, isFirstLoad?: boolean) => {
    setIsLoading(true);
    setIsGetDataLoading?.(true);
    const response = await getData(dataUrl, filterSearch);
    setIsLoading(false);
    setIsGetDataLoading?.(false);
    if (isSuccessResponse(response) && response.data) {
      if (!isFirstLoad) {
        setData(response.data.value);
      } else {
        setValue(response.data.value);
      }
    } else {
      onError({
        autoClose: false,
        title: "Get data fail.",
        type: "error",
        description: response.error,
      });
    }
  };

  // handle event
  const handleSearch = (event: MultiSelectFilterChangeEvent) => {
    const searchText = event.filter.value;
    setData([]);

    if (searchText.length < searchAddressesConfig.minCharacters) return;

    if (refTimeOut.current) clearTimeout(refTimeOut.current);
    refTimeOut.current = setTimeout(() => {
      if (filterField) {
        getContacts(`&$filter=contains(${filterField},'${searchText}')`, false);
      } else {
        getContacts(searchText, false);
      }
    }, searchAddressesConfig.typeSpeed);
  };

  const handleOnChange = (event: MultiSelectChangeEvent) => {
    if (event.value) {
      if (onChangeHandler) onChangeHandler(event);
      setValue(event.value);
      onChangeForm(name, {
        value: refactorData ? refactorData(event.value) : event.value,
      });
    }
  };

  useEffectOnce(() => {
    if (listID && listID?.length > 0) {
      let filterSearch = listID
        .map((item: number) => `${dataItemKey} eq ${item}`)
        .join(" or ");
      getContacts(`&$filter=${filterSearch}`, true);
    }
  });

  return (
    <>
      <MultiSelect
        filterable
        data={data}
        loading={isLoading}
        onFilterChange={handleSearch}
        itemRender={(
          li: ReactElement<HTMLLIElement>,
          itemProps: ListItemProps
        ) => ItemRender(li, itemProps, columnFields)}
        value={value}
        header={
          <div className="cc-search-header">
            {columnFields.map((col: IColumnFields) => (
              <div key={col.field}>{col.title}</div>
            ))}
          </div>
        }
        onChange={handleOnChange}
        popupSettings={{ className: "cc-multi-select-search" }}
        textField={textField}
        dataItemKey={dataItemKey}
        disabled={disabled}
      />

      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
  columnFields: IColumnFields[]
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {columnFields.map((col: IColumnFields) => (
        <div key={col.field}>{dataItem?.[col.field] ?? ""}</div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
