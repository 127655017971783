import { isSuccessResponse } from "@common/apis/util";
import { getAppealHearingDetailsTabById } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/reference-sidebar/detail/api";
import { Svc_AppealHearingTabDetails } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/reference-sidebar/detail/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AppealHearingDetailTabStore {
  private _appealHearingDetailTab?: Svc_AppealHearingTabDetails = undefined;
  private _isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get appealHearingDetailTab() {
    return toJS(this._appealHearingDetailTab);
  }
  setAppealHearingDetailTab = (
    appealHearingDetailTab?: Svc_AppealHearingTabDetails | undefined
  ) => {
    runInAction(() => {
      this._appealHearingDetailTab = appealHearingDetailTab;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._appealHearingDetailTab = undefined;
      this._isLoading = false;
    });
  };

  loadAppealHearingDetailTab = async (appealHearingId: number) => {
    this.setIsLoading(true);
    const response = await getAppealHearingDetailsTabById(appealHearingId);

    if (isSuccessResponse(response)) {
      this.setAppealHearingDetailTab(response?.data);
    } else {
      //TODO:Enhance
      //console.log("Detail load failed");
    }

    this.setIsLoading(false);
  };
}
const AppealHearingDetailTabStoreContext = createContext(
  new AppealHearingDetailTabStore()
);
export const useAppealHearingDetailTabStore = () => {
  return useContext(AppealHearingDetailTabStoreContext);
};
