import React, { Fragment } from "react";

export const MapInfoFragment: React.FC<{ title?: string }> = () => {
  return (
    <Fragment>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.5634518942315!2d145.15338741585134!3d-37.91726534774697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad63b99238c8a05%3A0xf79911f79ad48f5b!2sOpen%20Office!5e0!3m2!1sen!2s!4v1597254890309!5m2!1sen!2s"
        //src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6675.561560729553!2d145.04462725749897!3d-37.84986326037516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf79911f79ad48f5b!2sOpen%20Office!5e0!3m2!1sen!2sus!4v1604529586596!5m2!1sen!2sus" 
        width="100%"
        height="500"
        frameBorder="0"
        style={{ border: "0" }}
        allowFullScreen={false}
        aria-hidden="false"
        tabIndex={0}
        title="map"
      />
    </Fragment>
  );
};

MapInfoFragment.defaultProps = {
  title: "Map View",
};

export default MapInfoFragment;
