import { mockECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/mock";
import { IECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getECertificateRequestById = async (
  id: number | string
): Promise<IECertificateRequest | undefined> => {
  await sleep(1000);
  const response = cloneDeep(mockECertificateRequest);
  return response;
};
