import { history } from "@/AppRoutes";
import { DOCUMENT_TEMPLATE_ROUTE } from "@app/products/property/system-admin/document-template/constant";
import {
  postDocumentTemplate,
  putDocumentTemplate,
} from "@app/products/property/system-admin/document-template/[id]/api";
import { DocumentTemplateFormElement } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/components/form-element/_index";
import { defaultInitValue } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/config";
import { useDocumentTemplateStore } from "@app/products/property/system-admin/document-template/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const DocumentTemplateForm = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const {
    documentTemplate,
    setIsLoadingSave,
    loadDocumentTemplate,
    documentLovs,
  } = useDocumentTemplateStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handleSubmit = async (event: any) => {
    const { values, isValid, isModified } = event;
    if (!isValid) return;

    if (!isModified)
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    const newValues = cloneDeep(values);
    if (newValues.DocumentTemplate_Images?.length > 0) {
      newValues.DocumentTemplate_Images = newValues.DocumentTemplate_Images.map(
        (image: any) => {
          delete image.AttachmentPhoto;
          return {
            ...image,
          };
        }
      );
    }
    if (newValues.FileEntryNew) {
      newValues.FileEntry = { ...newValues.FileEntryNew };
      delete newValues.FileEntryNew;
    }
    if (isNew) {
      setIsLoadingSave(true);

      const response = await postDocumentTemplate(newValues);
      setIsLoadingSave(false);
      if (isSuccessResponse(response)) {
        history.push(`${DOCUMENT_TEMPLATE_ROUTE}/${response.data?.ID}`);
        pushNotification({
          title:
            response.data?.SuccessMessage ??
            `Document template created successfully`,
          type: "success",
        });
      } else {
        pushNotification({
          title:
            response.data?.ErrorMessage ?? `Document template created failed`,
          type: "error",
        });
      }
    } else {
      setIsLoadingSave(true);
      const response = await putDocumentTemplate(newValues);
      setIsLoadingSave(false);
      if (isSuccessResponse(response)) {
        await loadDocumentTemplate(+id);
        pushNotification({
          title:
            response.data?.SuccessMessage ??
            `Document template updated successfully`,
          type: "success",
        });
      } else {
        pushNotification({
          title:
            response.data?.ErrorMessage ?? `Document template updated failed`,
          type: "error",
        });
      }
    }
  };
  const initValues = useMemo(() => {
    return isNew
      ? {
          ...defaultInitValue,
          DocumentTemplate_ID: 0,
          Dataset_Int: documentLovs?.Datasets?.[0]?.Code,
          Dataset_ENUM: documentLovs?.Datasets?.[0]?.Name,
          Dataset_Name: documentLovs?.Datasets?.[0]?.Name,
          SaveFormat_Int: documentLovs?.SaveFormats?.[0]?.Code,
          SaveFormat_ENUM: documentLovs?.SaveFormats?.[0]?.Name,
          SaveFormat_Name: documentLovs?.SaveFormats?.[0]?.Name,
        }
      : documentTemplate;
  }, [isNew, documentTemplate, documentLovs]);
  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initValues}
        key={JSON.stringify(initValues)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              component={
                <DocumentTemplateFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
