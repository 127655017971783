import { Attachment, KeyValuePair } from "@app/core/documents/model";
import {
  FileStreamEntry,
  MAILMERGEDATASET,
  MAILMERGEDOCUMENTUSAGE,
  MAILMERGESAVEFORMAT,
} from "@app/core/new-mail-merge/dialogs/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { eBooleanType } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/model";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

// TODO: Move all Document's model to this place
export const nameOfCCDocument = nameOfFactory<CCDocument>();

export interface CCDocument extends DTOBaseClass_Standard {
  Document_ID: number;
  Name: string;
  Document_Usage_ENUM: MAILMERGEDOCUMENTUSAGE;
  Document_Usage_ENUM_Int: number;
  Document_Usage_Name: string;
  Dataset_ENUM: MAILMERGEDATASET;
  Dataset_ENUM_Int: number;
  Dataset_Name: string;
  SaveFormat_ENUM: MAILMERGESAVEFORMAT;
  SaveFormat_ENUM_Int: number;
  SaveFormat_Name: string;
  SaveFormat: string;
  WhenLoaded: Date;
  AdditionalInformation: string;
  FileEntry: FileStreamEntry | null;
  UseRegions: boolean;
  AllowLiveMerge: boolean;
  Council_ID: number | null;
  EmailSubject: string;
  EmailContent: string;
  SMSContent: string;
  ImageFieldSettings: string;
  Category_KWD: number | null;
}

export interface CCDocument_Ext extends CCDocument {
  _options?: {
    Usages?: KeyValuePair<number, string>[];
    MailMergeDataset?: KeyValuePair<number, string>[];
    SaveAsFormat?: eBooleanType;
    Availability?: eBooleanType;
    FileUpload?: Attachment[];
    IsDisabledUseRegion?: boolean;
    IsDisabledSaveAsFormat?: boolean;
    IsDisabledMailMergeDataset?: boolean;
    IsDisabledAllowLiveMailMerge?: boolean;
  };
}

export interface ILookupDocumentParentSection {
  notification?: IAppNotificationItemAddProps[];
  parentDocument?: CCDocument_Ext;
}

export enum LookupDocumentActions {
  New = "New",
  Save = "Save",
}
