import { DTO_Title } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfTitles = nameOfFactory<DTO_Title>();

export const colAssociatedTitles: IColumnFields[] = [
  {
    field: nameOfTitles("Association_Type"),
    title: "Association Type",
  },
  {
    field: nameOfTitles("ReferenceNumber"),
    title: "Lot Reference",
  },
  {
    field: nameOfTitles("LegalDescription"),
    title: "Lot Legal Description",
  },
  {
    field: nameOfTitles("Lot"),
    title: "Lot",
  },
  {
    field: nameOfTitles("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfTitles("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOfTitles("Section"),
    title: "Section",
  },
  {
    field: nameOfTitles("County"),
    title: "County",
  },
  {
    field: nameOfTitles("Parish"),
    title: "Parish",
  },
  // hide for now
  // {
  //   field: nameOfTitles("Owners"),
  //   title: "Owners",
  // },
  {
    field: nameOfTitles("Active_From"),
    title: "Active From Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitles("Active_To"),
    title: "Active To Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfTitles("TitleId"),
    title: "Lot Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
