import { getChargeRunDetailTabId } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/reference-sidebar/detail/api";
import { DTO_ChargeRun_TabDetails } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/reference-sidebar/detail/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ChargeAndNoticeRunsDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chargeRunInfo, setChargeRunInfo] = useState<
    DTO_ChargeRun_TabDetails | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const { isLLS } = CommunityProperty.getFlagOfStates();

  const params: { id: string } = useParams();
  const chargeRunId: number =
    lastSelectedRow?.ChargeRunId ?? parseInt(params.id);

  //Get label
  const numberOfAssessmentsLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.NumberOfAssessments
  );

  useEffect(() => {
    if (chargeRunId) {
      setIsLoading(true);
      getChargeRunDetailTabId(chargeRunId).then((response) => {
        setChargeRunInfo(response?.data);
        setIsLoading(false);
      });
    }
  }, [chargeRunId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!chargeRunInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={numberOfAssessmentsLabel}
              value={(chargeRunInfo?.NumberOfAssessments ?? 0).toString()}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Number of levies:"}
                value={chargeRunInfo?.NumberOfLevies ?? ""}
              />
              <ProductReferenceRow
                title={"Gross levy amount:"}
                value={formatDisplayValue(
                  chargeRunInfo?.TotalGrossLevyAmount,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Rebates:"}
                value={formatDisplayValue(
                  chargeRunInfo?.TotalRebateAmount,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              {!isLLS && (
                <ProductReferenceRow
                  title={"Roundings:"}
                  value={formatDisplayValue(
                    chargeRunInfo?.TotalRoundingAmount,
                    CURRENCY_FORMAT.CURRENCY1
                  )}
                  format="currency"
                />
              )}
              <ProductReferenceRow
                title={"Nett levy amount:"}
                value={formatDisplayValue(
                  chargeRunInfo?.TotalNettLevyAmount,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Discount amount:"}
                value={formatDisplayValue(
                  chargeRunInfo?.TotalDiscountAmount,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
