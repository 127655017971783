import { NewAssessmentTransactionButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/new-transaction/_index";
import { RaiseChargeButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/raise-charge/_index";
import { AmalgamateAssessmentWorkflowButton } from "@app/products/property/assessments/components/action-bar/buttons/amalgamate-assessment/components/worklfow/_index";
import { AssociateAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/associate-assessment/_index";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { DisAssociateAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/disassociate-assessment/_index";
import { ModifyAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/modify-assessment/_index";
import { NewAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/new-assessment/_index";
import { RaiseAssessmentInterestButton } from "@app/products/property/assessments/components/action-bar/buttons/raise-assessment-interest/_index";
import { ReactivateAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/reactivate-assessment/_index";
import { WriteOffSmallBalancesButton } from "@app/products/property/assessments/components/action-bar/buttons/write-off-small-balances/_index";
import { CreateDefermentListButton } from "@app/products/property/assessments/deferments/components/action-bar/buttons/create-deferment/_index";
import { AddToSupplementaryButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/add-to-supplementary/_index";
import { NewDebtRecoveryButton } from "@app/products/property/components/action-bar/button/new-debt-recovery/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessmentListWorkflowButtons = observer(() => {
  const { gridSelectedRows, gridSelectedIds } = useCCProductListViewStore();
  const { isLLS, isGeneral } = CommunityProperty.getFlagOfStates();
  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  return (
    <CCNavButton
      title={"Workflow"}
      type="sub-workflow"
      className="cc-workflow-button"
    >
      <CCNavButton
        title={assessmentLabel ?? ""}
        type="dropdown"
        id={`cc-nav-${ECustomColNameProperty.Assessment}`}
      >
        <AssociateAssessmentButton />
        <DisAssociateAssessmentButton />
        <AmalgamateAssessmentWorkflowButton />
        <ModifyAssessmentButton />
        <NewAssessmentButton />
        <ReactivateAssessmentButton />
      </CCNavButton>
      <CCNavButton title={"Supplementary"} type="dropdown">
        <AddToSupplementaryButton
          componentNumber={eComponent.Assessment}
          statusId={gridSelectedRows[0]?.Assessment?.StatusId}
        />
        {/* TODO: Hide menu until API ready (later sprint) */}
        {/* <BulkLevyUpdateButton /> */}
      </CCNavButton>
      <NewDebtRecoveryButton />
      <CCNavButton
        title={"Deferment"}
        type="dropdown"
        invisible={isLLS || isGeneral}
      >
        <CreateDefermentListButton />
      </CCNavButton>
      <ChangeOfOwnerShipButton componentNumber={eComponent.Assessment} />
      <CCNavButton title="Journal" type="dropdown">
        <NewAssessmentTransactionButton />
        <RaiseChargeButton />
        <RaiseAssessmentInterestButton
          isDisabled={gridSelectedRows?.length !== 1}
        />
        <WriteOffSmallBalancesButton />
      </CCNavButton>
      {isLLS ? (
        <CCNavButton title={"PIC"} type="dropdown">
          <CreatePICButton
            isDisabled={gridSelectedIds?.length < 1}
            assessmentIds={gridSelectedIds}
          />
        </CCNavButton>
      ) : (
        <></>
      )}
    </CCNavButton>
  );
});
