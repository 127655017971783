import { optionInputPickerReasonForPermit } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { IApplicationDescription } from "@app/products/town-planning/ppr/[id]/components/input-picker/application-description/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCInputPicker,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isString } from "lodash";
import React, { useRef } from "react";

export interface IApplicationDescriptionProps extends ICCInputPickerProps {}
export const ApplicationDescription = (props: IApplicationDescriptionProps) => {
  const nameOf = nameOfFactory<IApplicationDescription>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  return (
    <CCInputPicker
      {...props}
      textProduce={(value: IApplicationDescription) => value.ReasonForPermit}
      customDialog={(value: IApplicationDescription, onClose, onSubmit) => (
        <Form
          initialValues={value}
          key={JSON.stringify(value)}
          onSubmit={onSubmit}
          render={(formRenderProps: FormRenderProps) => {
            const { valid, onSubmit, valueGetter, modified, onChange } =
              formRenderProps;
            return (
              <CCDialog
                titleHeader={"Application Description"}
                onClose={onClose}
                maxWidth="60%"
                height={"auto"}
                bodyElement={
                  <div className="cc-form">
                    <FormElement className="cc-field-group">
                      <CCLocalNotification ref={notificationRef} />
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">
                            Reason for permit
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            allowCustom
                            name={nameOf("ReasonForPermit")}
                            placeholder="Reason for permit"
                            component={InputPickerSearch}
                            validator={requiredValidator}
                            onChange={(data: any) => {
                              onChange(nameOf("ReasonForPermit"), {
                                value:
                                  isString(data) && data.trim().length
                                    ? sanitizeHtml(data)
                                    : data?.Name,
                              });
                            }}
                            options={optionInputPickerReasonForPermit}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">Secondary consent</label>
                          <Field
                            name={nameOf("Flag_IsSecondaryConsent")}
                            component={CCSwitch}
                            checked={valueGetter(
                              nameOf("Flag_IsSecondaryConsent")
                            )}
                          />
                        </div>
                      </div>

                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">Long description</label>
                          <Field
                            name={nameOf("LongDescription")}
                            placeholder={"Long description"}
                            component={CCTextArea}
                            rows={3}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Existing use</label>
                          <Field
                            name={nameOf("EPlanning_ExistingConditions")}
                            placeholder={"Existing use"}
                            component={CCTextArea}
                            rows={3}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Applicant proposal</label>
                          <Field
                            name={nameOf("EPlanning_ProposalDescription")}
                            placeholder={"Applicant proposal"}
                            component={CCTextArea}
                            rows={3}
                          />
                        </div>
                      </div>
                    </FormElement>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      onClick={onSubmit}
                      type={"submit"}
                      disabled={!valid || !modified}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            );
          }}
        />
      )}
    />
  );
};
