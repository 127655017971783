import { ICCRoute } from "@common/constants/ICCRoute";

export const contactDeletedRoute: ICCRoute = {
  path: "deleted",
  name: "Deleted",
  children: [
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
    },
  ],
};
