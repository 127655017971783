import { IKeyValuePacket } from "@common/models/keyValuePacket";

//#region External DTO
export class ContactResponse {
  _SaveTriggers: string[] = [];
  Sys_TimeStamp: string = "";
  Contact_ID: string = "";
  DisplayName: string = "";
  FirstName: string = "";
  LastName: string = "";
  ContactClassification_Name: string = "";
  PreferredMethod_Name: string = "";
  WorkPhone: string = "";
  HomePhone: string = "";
  Fax: string = "";
  Mobile: string = "";
  NARNumber: string = "";
  NARUpdated: string = "";
  Flag_SendSMSAlerts: boolean = false;
  Custom_Tags: string = "";
  Email: string = "";
  Website: string = "";
  ABN: string = "";
  ACN: string = "";
  VIP?: boolean = false;
  VIPTypeID?: string = "";
  VIPType: string = "";
  VIPRelationshipOwnerID?: number = 0;
  VIPRelationshipOwner: string = "";
  ContactRoles_Count: number = 0;
  PreferredAddress: string = "";
  Organisation: string = "";
  Preferred_ContactAddressType_KWD?: number = 0;
  Preferred_ContactAddressType_Name: string = "";
  PostalAddress?: Address;
  Postal_ContactAddressType_KWD?: number = 0;
  Postal_ContactAddressType_Name: string = "";
  // AlternateAddress: Address = "";
}
export interface Address {
  Sys_TimeStamp: string;
  Address_ID: number;
  AddressClassification_ENUM: string;
  ContactAddressType_KWD: number;
  ContactAddressType_Name: string;
  PropertyName: string;
  UnitNo: string;
  StreetNo: string;
  StreetName: string;
  Country: string;
  Postcode: string;
  State: string;
  Suburb: string;
}

export class ContactRegisterRequest {}

export class ContactRegisterForm {
  DisplayName: string = "";
  FirstName: string = "";
  LastName: string = "";
  ContactClassification_Name: string = "";
  PreferredMethod_Name: string = "";
  WorkPhone: string = "";
  HomePhone: string = "";
  Fax: string = "";
  Mobile: string = "";
  NARNumber: string = "";
  NARUpdated: string = "";
  Flag_SendSMSAlerts: boolean = false;
  Custom_Tags: string = "";
  Email: string = "";
  Website: string = "";
  ABN: string = "";
  ACN: string = "";
  VIP?: boolean = false;
  VIPTypeID?: string = "";
  VIPType: string = "";
  VIPRelationshipOwnerID?: number = 0;
  VIPRelationshipOwner: string = "";
  ContactRoles_Count: number = 0;
  PreferredAddress: string = "";
  Organisation: string = "";
  Preferred_ContactAddressType_KWD?: number = 0;
  Preferred_ContactAddressType_Name: string = "";
  PostalAddress: string = "";
  Postal_ContactAddressType_KWD?: number = 0;
  Postal_ContactAddressType_Name: string = "";
  EntityId?: number = 0; //Mock
  ApplicationNumber?: number = 0; //Mock
  Gender?: string = "male"; //Mock
  DateOfBirth?: Date; //Mock
  DateOfDeath?: Date; //Mock
  PrivateAddress?: string = ""; //Mock
  WorkAddress?: string = ""; //Mock
  MoreAddress?: string = ""; //Mock
  Distinction?: string = ""; //Mock
  Occupation?: string = ""; //Mock
  LastChanged?: Date; //Mock
  ChangedBy?: string = ""; //Mock
  IsObsolete?: boolean = false; //Mock
  Notes?: string = ""; //Mock
  PersonNumber?: number = 0; //Mock
  SilentEnrolment?: boolean = false; //Mock
  VoterRegistration?: Date; //Mock
  UpdateDate?: Date; //Mock
}

export interface ContactLOVs {
  ContactTypes: IKeyValuePacket[];
  Contacts_DogBreeds: IKeyValuePacket[];
  Contacts_CatBreeds: IKeyValuePacket[];
  Contacts_OtherBreeds: IKeyValuePacket[];
  Contacts_Colour: IKeyValuePacket[];
  Contacts_Size: IKeyValuePacket[];
  Contacts_Condition: IKeyValuePacket[];
  Contacts_Disposition: IKeyValuePacket[];
  Contacts_TagColour: IKeyValuePacket[];
  Contacts_ProofOfObedience: IKeyValuePacket[];
  Contacts_ProofOfSterilisation: IKeyValuePacket[];
  Contacts_MicrochipProofExemption: IKeyValuePacket[];

  Core_Judgement: IKeyValuePacket[];
}

export interface IContactData {
  FirstName: string;
  LastName: string;
  DisplayName: string;
  Email: string;
  HomePhone: string;
  Mobile: string;
}

export interface IContactRelationShipData {
  ContactClassification_ENUM: string;
  Salutation: string;
  FirstName: string;
  LastName: string;
  DisplayName: string;
  Website: string;
  Email: string;
  _HomePhone: string;
  _Mobile: string;
  _WorkPhone: string;
  _Fax: string;
  Organisation: string;
  ABN: string;
  PreferredMethod_ENUM: string;
  ContactAlert: string;
  Extension: {
    DateOfBirth: string;
    FSSCertificate: string;
    FSSCertificateNo: string;
    FSSCertificateExpiryDate: string;
    LicenceNo: string;
    SecondLanguage: string;
    JobTitle: string;
  };
}

export interface IPosition {
  position: [];
}
