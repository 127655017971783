import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { DatePickerSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/date-picker/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const AssessmentExtendedSettingsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { isEditing } = useSettingPPRManageStore();
    const { configData } = useSettingPPRStore();

    const data = useDataSetting(configData, [
      "TP_Assessment_Sensitive",
      "TP_Assessment_CulturalHeritage",
      "TP_Assessment_Demolition",
      "TP_Assessment_BuildingHeight",
      "TP_Assessment_BuildingHeight_Numeric",
      "TP_Assessment_InspectionDetails",
      "TP_Assessment_NoOfNewLots",
      "TP_Assessment_IncreaseOfDwellings",
      "TP_Assessment_DistanceFromWaterway",
      "TP_Enable_CurrentProposedLand",
      "TP_Enable_PermitTriggers",
      "TP_Assessment_Enable_DCP",
      "TP_View_DCP_StartDate",
    ]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <SwitchSettings
            data={data.TP_Assessment_Sensitive}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_CulturalHeritage}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-2">
          <SwitchSettings
            data={data.TP_Assessment_Demolition}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_BuildingHeight}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-2">
          <SwitchSettings
            data={data.TP_Assessment_InspectionDetails}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_NoOfNewLots}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_IncreaseOfDwellings}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_DistanceFromWaterway}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Enable_CurrentProposedLand}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Enable_PermitTriggers}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchSettings
            data={data.TP_Assessment_Enable_DCP}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <DatePickerSettings
            data={data.TP_View_DCP_StartDate}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            isFieldVisible={
              data.TP_Assessment_Enable_DCP &&
              formRenderProps?.valueGetter(
                data.TP_Assessment_Enable_DCP.FieldName
              )
            }
          />
        </div>
      </section>
    );
  }
);
