import { history } from "@/AppRoutes";
import { ViewActionButton } from "@app/products/crms/model";
import {
  ChecklistQuestion,
  DynamicQuestionListDetail,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DynamicQuestionList } from "@common/models/dynamicQuestion";
import { ActionSubmitActions } from "@common/pages/actions/model";
import {
  getCheckListQuestionLovs,
  getDeleteCheckListQuestion,
  getDynamicQuestionListsLovs,
  postCheckListQuestionById,
  postDynamicQuestionListsByID,
  postSaveCheckListQuestion,
  postSaveDynamicQuestionLists,
  postUpdateCheckListQuestion,
} from "@common/pages/settings/lookups/dynamic-lists/_id/api";
import { COMMENT_FORM_STEP } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/dialog/dynamic-question-dialog/comment/_index";
import { QUESTION_FORM_STEP } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/components/dialog/dynamic-question-dialog/question/_index";
import { convertToObject } from "@common/pages/settings/lookups/dynamic-lists/_id/util";
import {
  ANSWER_FIELD_TEMP_NAME,
  COMMENT_LABEL_TEMP_NAME,
  DYNAMIC_LISTS_ROUTE,
} from "@common/pages/settings/lookups/dynamic-lists/constant";
import {
  Svc_CheckListQuestionLovs,
  Svc_DynamicQuestionLovs,
} from "@common/pages/settings/lookups/dynamic-lists/model";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { SyntheticEvent, createContext, useContext } from "react";

configure({ enforceActions: "always" });
class DynamicQuestionListsStore {
  private _isLoading: boolean = false;
  private _isInactive: boolean = false;
  private _isLoadingCheckListQuestionDialog: boolean = false;
  private _isFirstLoadingCheckListQuestionDialog: boolean = false;
  private _isShowCheckListQuestionDialog: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _responseLoadErrorCheckList?: APIResponseError = undefined;
  private _dynamicQuestionLists?: DynamicQuestionList = undefined;
  private _dynamicQuestionListsLovs?: Svc_DynamicQuestionLovs = undefined;
  private _checkListQuestion?: ChecklistQuestion = undefined;
  private _checkListQuestionLovs?: Svc_CheckListQuestionLovs = undefined;
  private _checkListQuestionId?: number = undefined;
  private _isAddCheckListQuestion?: boolean = false;
  private _onSubmit?: (event: SyntheticEvent<any>) => void = undefined;
  private _onUpdateQuestion?: (
    dataRow: DynamicQuestionListDetail,
    fieldChange: string,
    valueChange?: number
  ) => void = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _securityViewActionButton?: ViewActionButton[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingCheckListQuestionDialog() {
    return this._isLoadingCheckListQuestionDialog;
  }
  setIsLoadingCheckListQuestionDialog = (
    isLoadingCheckListQuestionDialog: boolean
  ) => {
    runInAction(() => {
      this._isLoadingCheckListQuestionDialog = isLoadingCheckListQuestionDialog;
    });
  };

  get isFirstLoadingCheckListQuestionDialog() {
    return this._isFirstLoadingCheckListQuestionDialog;
  }
  setIsFirstLoadingCheckListQuestionDialog = (
    isFirstLoadingCheckListQuestionDialog: boolean
  ) => {
    runInAction(() => {
      this._isFirstLoadingCheckListQuestionDialog =
        isFirstLoadingCheckListQuestionDialog;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get responseLoadErrorCheckList() {
    return toJS(this._responseLoadErrorCheckList);
  }
  setResponseLoadErrorCheckList = (
    responseLoadErrorCheckList?: APIResponseError
  ) => {
    runInAction(() => {
      this._responseLoadErrorCheckList = responseLoadErrorCheckList;
    });
  };

  get dynamicQuestionListsLovs() {
    return this._dynamicQuestionListsLovs;
  }
  setDynamicQuestionListsLovs = (
    dynamicQuestionListsLovs?: Svc_DynamicQuestionLovs
  ) => {
    runInAction(() => {
      this._dynamicQuestionListsLovs = dynamicQuestionListsLovs;
    });
  };

  get checkListQuestionLovs() {
    return this._checkListQuestionLovs;
  }
  setCheckListQuestionLovs = (
    checkListQuestionLovs?: Svc_CheckListQuestionLovs
  ) => {
    runInAction(() => {
      this._checkListQuestionLovs = checkListQuestionLovs;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get dynamicQuestionListsId() {
    return this._dynamicQuestionLists?.DynamicQuestionList_ID;
  }

  get dynamicQuestionLists() {
    return this._dynamicQuestionLists;
  }
  setDynamicQuestionLists = (dynamicQuestionLists?: DynamicQuestionList) => {
    runInAction(() => {
      this._dynamicQuestionLists = dynamicQuestionLists;
    });
  };

  get checkListQuestion() {
    return this._checkListQuestion;
  }
  setCheckListQuestion = (checkListQuestion?: ChecklistQuestion) => {
    runInAction(() => {
      this._checkListQuestion = checkListQuestion;
    });
  };

  get checkListQuestionId() {
    return this._checkListQuestionId;
  }
  setCheckListQuestionId = (checkListQuestionId?: number) => {
    runInAction(() => {
      this._checkListQuestionId = checkListQuestionId;
    });
  };

  get isShowCheckListQuestionDialog() {
    return this._isShowCheckListQuestionDialog;
  }
  setIsShowCheckListQuestionDialog = (
    isShowCheckListQuestionDialog: boolean
  ) => {
    runInAction(() => {
      this._isShowCheckListQuestionDialog = isShowCheckListQuestionDialog;
    });
  };

  get isAddCheckListQuestion() {
    return this._isAddCheckListQuestion;
  }
  setIsAddCheckListQuestion = (isAddCheckListQuestion: boolean) => {
    runInAction(() => {
      this._isAddCheckListQuestion = isAddCheckListQuestion;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get onUpdateQuestion() {
    return this._onUpdateQuestion;
  }
  setOnUpdateQuestion = (
    onUpdateQuestion: (
      dataRow: DynamicQuestionListDetail,
      fieldChange: string,
      valueChange?: number
    ) => void
  ) => {
    runInAction(() => {
      this._onUpdateQuestion = onUpdateQuestion;
    });
  };

  get securityViewActionButton() {
    return this._securityViewActionButton;
  }
  setSecurityViewActionButton = (
    securityViewActionButton?: ViewActionButton[]
  ) => {
    runInAction(() => {
      this._securityViewActionButton = securityViewActionButton;
    });
  };

  //Action
  resetStore = (isResetCheckListQuestionDialog?: boolean) => {
    runInAction(() => {
      if (isResetCheckListQuestionDialog) {
        this._checkListQuestion = undefined;
        this._checkListQuestionLovs = undefined;
        this._isLoadingCheckListQuestionDialog = false;
        this._isFirstLoadingCheckListQuestionDialog = false;
        this._responseLoadErrorCheckList = undefined;
        this._checkListQuestionId = undefined;
        this._isShowCheckListQuestionDialog = false;
        this._isAddCheckListQuestion = false;
      } else {
        this._isLoading = false;
        this._responseLoadError = undefined;
        this._dynamicQuestionLists = undefined;
        this._dynamicQuestionListsLovs = undefined;
        this._onSubmit = undefined;
        this._onUpdateQuestion = undefined;
        this._notification = undefined;
        this._isInactive = false;
      }
    });
  };

  reLoadDynamicQuestionLists = async (isNew: boolean) => {
    if (this.dynamicQuestionListsId) {
      await this.loadDynamicQuestionLists(this.dynamicQuestionListsId, isNew);
    }
  };

  loadDynamicQuestionLists = async (id: number, isNew: boolean) => {
    const dynamicQuestionListId = isNew ? 0 : id;

    this.setIsLoading(true);
    const [response, responseLovs] = await Promise.all([
      postDynamicQuestionListsByID(dynamicQuestionListId),
      getDynamicQuestionListsLovs(),
    ]);
    this.setIsLoading(false);

    if (
      isSuccessResponse(response) &&
      response?.data &&
      isSuccessResponse(responseLovs) &&
      responseLovs?.data?.ReturnObj
    ) {
      let newDynamicQuestionLists = cloneDeep(response.data);

      // reassign value for the "Checklist type" field for trigger required validator
      newDynamicQuestionLists = {
        ...newDynamicQuestionLists,
        ChecklistType_ENUM:
          response.data.ChecklistType_ENUM === 0
            ? null
            : response.data.ChecklistType_ENUM,
      };

      this.setDynamicQuestionLists(newDynamicQuestionLists);
      this.setDynamicQuestionListsLovs(responseLovs.data.ReturnObj);

      // set inactive to disable elements
      if (!!!newDynamicQuestionLists.Sys_DBRowState) {
        this.setIsInactive(true);
        appNotificationStore.pushNotification({
          autoClose: false,
          title:
            "Important Note: You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          type: "warning",
        });
      }

      // Push notification
      if (this.notification) {
        appNotificationStore.pushNotification(this.notification);
        this.setNotification(undefined);
      }

      if (this.isAddCheckListQuestion) {
        this.setCheckListQuestionId(0);
        this.setIsShowCheckListQuestionDialog(true);
      }
    } else {
      this.setResponseLoadError({
        status: response?.status ?? responseLovs.status,
        error: response?.error ?? responseLovs.error,
      });
    }
  };

  loadCheckListQuestion = async (
    checkListQuestionId: number,
    dynamicQuestionId: number
  ) => {
    this.setIsFirstLoadingCheckListQuestionDialog(true);
    const [response, responseLovs] = await Promise.all([
      postCheckListQuestionById(checkListQuestionId),
      getCheckListQuestionLovs(dynamicQuestionId, checkListQuestionId),
    ]);
    this.setIsFirstLoadingCheckListQuestionDialog(false);

    if (
      isSuccessResponse(response) &&
      response?.data &&
      isSuccessResponse(responseLovs) &&
      responseLovs?.data?.ReturnObj
    ) {
      let checkListQuestion = cloneDeep(response.data);
      let answerFieldObj = {};
      let commentLabelObj = {};

      // Split AnswerField to object properties
      if (checkListQuestion.AnswerField) {
        answerFieldObj = convertToObject(
          checkListQuestion.AnswerField,
          QUESTION_FORM_STEP,
          ANSWER_FIELD_TEMP_NAME
        );
      }

      // Split CommentLabels to object properties
      if (checkListQuestion.CommentLabels) {
        commentLabelObj = convertToObject(
          checkListQuestion.CommentLabels,
          COMMENT_FORM_STEP,
          COMMENT_LABEL_TEMP_NAME
        );
      }

      this.setCheckListQuestion({
        ...checkListQuestion,
        ...answerFieldObj,
        ...commentLabelObj,
        ChecklistAnswerType_ENUM:
          checkListQuestion?.ChecklistAnswerType_ENUM === 0
            ? null
            : checkListQuestion?.ChecklistAnswerType_ENUM,
      });
      this.setCheckListQuestionLovs(responseLovs.data.ReturnObj);
    } else {
      this.setResponseLoadErrorCheckList({
        status: response?.status ?? responseLovs.status,
        error: response?.error ?? responseLovs.error,
      });
    }
  };

  deleteCheckListQuestion = async (
    dynamicQuestionDetailId: number,
    checkListQuestionId: number,
    isNew: boolean
  ) => {
    this.setIsLoading(true);
    const response = await getDeleteCheckListQuestion(
      dynamicQuestionDetailId,
      checkListQuestionId
    );
    this.setIsLoading(false);

    if (isSuccessResponse(response)) {
      this.setNotification({
        type: "success",
        title: "Record successfully deleted.",
      });
      this.reLoadDynamicQuestionLists(isNew);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Record could not be deleted",
        type: "error",
        description: response?.error ?? response?.data?.Errors,
      });
    }
  };

  updateCheckListQuestion = async (
    dynamicQuestionListDetail: DynamicQuestionListDetail,
    isNew: boolean
  ) => {
    this.setIsLoading(true);
    const response = await postUpdateCheckListQuestion(
      dynamicQuestionListDetail
    );
    this.setIsLoading(false);

    if (isSuccessResponse(response)) {
      this.setNotification({
        type: "success",
        title: "Record successfully saved.",
      });
      this.reLoadDynamicQuestionLists(isNew);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Record could not be updated",
        type: "error",
        description: response?.error ?? response?.data?.Errors,
      });
    }
  };

  submitAction = async (
    dynamicQuestionLists: DynamicQuestionList,
    action: ActionSubmitActions,
    isNew: boolean,
    isModified?: boolean
  ) => {
    // Just call API save when form is modified.
    if (action === ActionSubmitActions.AddCheckListQuestion) {
      if (!isModified) {
        this.setIsShowCheckListQuestionDialog(true);
        return;
      } else {
        this.setIsAddCheckListQuestion(true);
      }
    }

    if (
      action === ActionSubmitActions.Save ||
      action === ActionSubmitActions.AddCheckListQuestion
    ) {
      this.setIsLoading(true);
      const response = await postSaveDynamicQuestionLists(dynamicQuestionLists);
      this.setIsLoading(false);

      if (isSuccessResponse(response) && response?.data) {
        if (isNew) {
          history.replace(
            DYNAMIC_LISTS_ROUTE + "/" + response?.data?.ID.toString()
          );
        } else {
          this.reLoadDynamicQuestionLists(isNew);
        }
        this.setNotification({
          type: "success",
          title: "Record successfully saved.",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Record could not be saved",
          type: "error",
          description: response?.error ?? response?.data?.Errors,
        });
      }
    }
  };

  saveCheckListQuestion = async (
    checkListQuestion: ChecklistQuestion,
    isNew: boolean,
    notification?: React.RefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsLoadingCheckListQuestionDialog(true);
    const response = await postSaveCheckListQuestion(checkListQuestion);
    this.setIsLoadingCheckListQuestionDialog(false);

    if (isSuccessResponse(response)) {
      this.reLoadDynamicQuestionLists(isNew);
      this.setIsShowCheckListQuestionDialog(false);
      this.setNotification({
        type: "success",
        title: "Record successfully saved.",
      });
    } else {
      notification?.current?.pushNotification({
        autoClose: false,
        title: "Record could not be saved",
        type: "error",
        description: response?.error ?? response?.data?.Errors,
      });
    }
  };
}

const dynamicQuestionListsContext = createContext(
  new DynamicQuestionListsStore()
);
export const useDynamicQuestionListsStore = () => {
  return useContext(dynamicQuestionListsContext);
};
