import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const ApplicationEplanningForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();

    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_EPlanning_EmailApplicantOnLodgement
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_EPlanning_EmailOAApplicantOnLodgement
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_EPlanning_RestrictPublishPublicOnly
            }
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
