import {
  DTO_Detail,
  DTO_Entity_Attributes,
  DTO_Entity_NominatedProperty,
  DTO_Entity_PhysicalAddress,
  DTO_Entity_PostalAddress,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { DTO_Entity_Associated_Entity } from "@app/products/property/contacts-central-names/list/model";
import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";

//new
export enum EKeysOfUpdateContactSteps {
  Details = "Details",
  Person = "EntityDetails",
  PhysicalAddress = "PhysicalAddress",
  PostalAddress = "PostalAddress",
  Attributes = "Attributes",
  Associations = "Associated_Entities",
  ConcessionCards = "Concession_Cards",
  Voting = "VotingDetails",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendUpdateContactSteps = [
  EKeysOfUpdateContactSteps.Details,
  EKeysOfUpdateContactSteps.Person,
  EKeysOfUpdateContactSteps.PhysicalAddress,
  EKeysOfUpdateContactSteps.PostalAddress,
  EKeysOfUpdateContactSteps.Attributes,
  EKeysOfUpdateContactSteps.Associations,
  EKeysOfUpdateContactSteps.ConcessionCards,
  EKeysOfUpdateContactSteps.Voting,
];

export interface DTO_Workflow_ModifyEntity {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ModifyEntity;
}

export interface RequestObjFromSpatialModifyContact {
  EntityId: number;
  Is_Update_From_ChangeOfOwnership: boolean;
}

export interface DTO_WorkflowDetail_ModifyEntity
  extends DTO_WorkflowDetail_Base {
  Details: DTO_Detail;
  EntityDetails: DTO_Entity_Detail;
  PostalAddress: DTO_Entity_PostalAddress;
  PhysicalAddress: DTO_Entity_PhysicalAddress;
  Attributes: DTO_ModifyEntity_Attributes;
  Concession_Cards: DTO_Entity_Concession_Card[];
  Associated_Entities: DTO_Entity_Associated_Entity[];
  VotingDetails: DTO_Entity_Voting;
}

export interface DTO_Entity_Detail {
  Id: number;
  Change_Of_Ownership_Id?: number;
  Type: number;
  Title: string;
  GivenName: string;
  Surname: string;
  Distinction: string;
  Category_Id?: number;
  Organisation_Name: string;
  ACN: string;
  ABN: string;
  AttentionOf: string;
  CareOf: string;
  Job_Description: string;
  Organisation: string;
  ApplicantName: string;
  ReasonForCreation: string;
  Changed_On?: Date;
  Changed_By: string;
  Is_Update_From_ChangeOfOwnership: boolean;
  Body_Type: number;
}
export interface SAP {
  Entity_SAP_Id: number;
  Entity_Id: number;
  Entity_Region_Id: number;
  SAP_Number: string;
  Is_Active: boolean;
  Region_Name: string;
}

export interface DTO_Entity_Concession_Card {
  Id: number;
  Concession_Card: string;
  Card_Number: string;
  Is_Active: boolean;
  Presented_Date?: Date;
  Confirmation_Date?: Date;
  Card_Number_InputMask?: string;
}

export interface RequestObjFromSpatial {
  EntityId: number;
}

export interface DTO_Entity_Voting {
  Nominated_Properties: DTO_Entity_NominatedProperty[];
  Voting_Entitlements: DTO_Entity_VotingEntitlement[];
}

export interface DTO_Entity_VotingEntitlement {
  Entity_Id: number;
  Election: string;
  Can_Vote: boolean;
  Role_Name: string;
  Assessment_Id?: number;
  Assess_Number?: number;
  Property_Name_Address_Locality: string;
}
export interface DTO_ModifyEntity_Attributes extends DTO_Entity_Attributes {
  Is_Active?: boolean;
}
