import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingMultiChoice } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-checkbox/_index";
import { ApplicationSettingSearchComboBox } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-search-combobox/_index";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const ApplicationRegistersForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    if (!configData) return <></>;
    const fieldRegisterColumns =
      configData[ECorporateSettingsField.TP_RegisterColumns.toString()];
    const isShowExcludeClosed =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_ShowPlanningEnqInRegister.toString()
      ) ?? false;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_RegisterView}
          />
          <ApplicationSettingMultiChoice
            field={fieldRegisterColumns}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-3">
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ShowSCInRegister}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ShowPlanningEnqInRegister}
            formRenderProps={formRenderProps}
          />
          {isShowExcludeClosed && (
            <ApplicationSettingSwitch
              enumParam={ECorporateSettingsField.TP_ExcludeClosedPE}
              formRenderProps={formRenderProps}
            />
          )}
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_ShowPlanningCertifcateInRegister
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_ShowPlanningCertificationInRegister
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ShowNonComplianceInRegister}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ShowEOTInRegister}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_CombineAddesssesInRegister}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
