import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colNewApplications: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.CreatedDate,
    title: "Lodged",
    width: 200,
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.VicSmart,
    title: "VicSmart",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.OsBalance,
    title: "O/S Payment",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Applicant,
    title: "Applicant",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.ePlanningCategory,
    title: "Category",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Owner,
    title: "Owner",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.ePlanningLodgedBy,
    title: "Lodged By",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Comments,
    title: "Comments",
    width: 200,
  },
];
