import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsTrimGroupServer {
  formRenderProps: FormRenderProps;
}
export const TrimGroupServer = observer(
  ({ formRenderProps }: IRecordsTrimGroupServer) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    const { valueGetter } = formRenderProps;

    const fieldUseHPECMAPI = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_UseServiceAPI
    );
    const fieldTRIMWorkgroupServerName = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_WorkgroupServerName
    );
    const fieldTRIMWorkgroupServerPort = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_WorkgroupServerPort
    );
    const fieldTRIMDatabaseId = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_DatabaseID
    );
    const fieldTRIMDatasetName = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_DatasetName
    );
    const fieldTRIMTempDir = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_TrimWebServerWorkPath
    );

    const isShowTrimGroupServer = !valueGetter(
      fieldUseHPECMAPI?.FieldName ?? ""
    );

    return isShowTrimGroupServer ? (
      <>
        {fieldTRIMWorkgroupServerName && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMWorkgroupServerName}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldTRIMWorkgroupServerPort && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMWorkgroupServerPort}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldTRIMDatabaseId && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMDatabaseId}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldTRIMDatasetName && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMDatasetName}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldTRIMTempDir && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMTempDir}
              isEditing={isEditing}
            />
          </div>
        )}
      </>
    ) : null;
  }
);
