import { history } from "@/AppRoutes";
import {
  putCreateAndLodgeEnquiries,
  putLodgeEnquiries,
} from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/lodge-application/api";
import { LodgeDialog } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/lodge-application/_index";
import { ILodge } from "@app/products/town-planning/ppr/enquiries/_id/components/dialogs/lodge-application/model";
import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import {
  PPREnquiryIdentityPacket,
  PPREnquirySubmitActions,
  Register,
  RegisterStatus,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { APIResponse } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { UITriggerRegister } from "@common/constants/uiTrigger";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useIsNew } from "@common/hooks/useIsNew";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import {
  appNotificationStore,
  useCCAppNotificationStore,
} from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

const lodgeValidatorList = [
  RegisterStatus.New,
  RegisterStatus.PortalHoldingBay,
];

export const EnquiriesLodgeApplicationButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [newEnquiry, setNewEnquiry] = useState<Register>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    onSubmit,
    pprEnquiry,
    pprEnquiryId,
    setPPREnquiryWithLoading,
    parentSection,
  } = usePPREnquiryStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const isNew = useIsNew();

  useAddUniqueEventEmitter([
    {
      eventType: UITriggerRegister.Lodge.toString(),
      listener: (pprEnquiryInfo: Register) => {
        setNewEnquiry(pprEnquiryInfo);
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    return lodgeValidatorList.some((item) => item === pprEnquiry?.Status_ENUM);
  }, [pprEnquiry]);

  const handleLodge = async (data: ILodge) => {
    setIsLoading(true);
    let response: APIResponse<PPREnquiryIdentityPacket> | undefined = undefined;
    if (isNew && newEnquiry) {
      const newPPREnquiry = cloneDeep(newEnquiry);
      newPPREnquiry.Date_Lodged = data.DateLodged;
      response = await putCreateAndLodgeEnquiries(
        newPPREnquiry,
        parentSection?.parentID,
        parentSection?.recordType
      );
    } else if (pprEnquiryId) {
      response = await putLodgeEnquiries(pprEnquiryId, data.DateLodged);
    }

    if (isSuccessIdentityPacket(response)) {
      setIsShowDialog(false);
      clearNotifications();
      setPPREnquiryWithLoading(response?.data?.Register);
      if (isNew) {
        const notifications: IAppNotificationItemAddProps[] = [
          {
            type: "success",
            title: "Enquiry lodged successfully",
          },
        ];
        if (
          response?.data?.Notifications &&
          response?.data?.Notifications.length > 0
        ) {
          notifications.push({
            autoClose: false,
            title: "Lodge enquiry",
            type: "warning",
            description: response?.data?.Notifications,
          });
        }
        history.replace(
          `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${response?.data?.ID}`,
          {
            notification: notifications,
          }
        );
      } else {
        setPPREnquiryWithLoading(response?.data.Register);
        pushNotification({
          type: "success",
          title: "Application lodged successfully",
        });
        if (
          response?.data?.Notifications &&
          response?.data?.Notifications.length > 0
        ) {
          pushNotification({
            autoClose: false,
            title: "Lodge enquiry",
            type: "warning",
            description: response?.data?.Notifications,
          });
        }
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Lodge enquiry failed",
        type: "error",
        description: response?.data?.Errors ?? response?.statusText,
      });
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Lodge application"
        onClick={onSubmit}
        name={
          isNew
            ? PPREnquirySubmitActions.CreateAndLodgeApplication
            : PPREnquirySubmitActions.LodgeApplication
        }
      />

      {isShowDialog && (
        <LodgeDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleLodge}
          isLoading={isLoading}
        />
      )}
    </>
  ) : null;
});
