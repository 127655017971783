import { getReferral } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/api";
import { colReferral } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/config";
import {
  DataTable,
  ISendReferral,
} from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/model";
import { referralValidation } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export interface IReferralDialogProps {
  onClose: () => void;
  onSubmit: (value: ISendReferral) => void;
  isSubmitLoading?: boolean;
  notifications?: IAppNotificationItem[];
}

const nameOfGrid = nameOfFactory<DataTable>();
const nameOf = nameOfFactory<ISendReferral>();

export const ReferralDialog = ({
  onClose,
  onSubmit,
  isSubmitLoading = false,
  notifications,
}: IReferralDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [referralData, setReferralData] = useState<DataTable[]>([]);
  const [errorResponse, setErrorResponse] = useState<APIResponseError>();
  const [searchKey, setSearchKey] = useState("");
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    onSubmit({
      ActionTypeIDs: event.values?.ActionTypeIDs.map(
        (item: DataTable) => item.ID
      ),
    } as ISendReferral);
  };

  const loadReferral = async () => {
    setIsLoading(true);
    const response = await getReferral();
    let errorResponse = undefined;
    if (isSuccessResponse(response) && response.data) {
      setReferralData(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setErrorResponse(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadReferral();
  });

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      validator={referralValidation}
      render={({ onChange, modified, valid, onSubmit }: FormRenderProps) => (
        <div className="cc-grid-selection-dialog">
          <CCDialog
            maxWidth="70%"
            height="70%"
            titleHeader={"Pick Referral Type"}
            onClose={onClose}
            disabled={isLoading}
            bodyElement={
              <div className="cc-grid-search">
                {errorResponse ? (
                  <CCLoadFailed
                    responseError={errorResponse}
                    onReload={() => loadReferral()}
                  />
                ) : (
                  <>
                    <CCLocalNotification defaultNotifications={notifications} />
                    <div className="cc-grid-search-input">
                      <label className="cc-label">Search</label>
                      <Field
                        name={"searchKey"}
                        component={CCInput}
                        placeholder="Search"
                        onChange={(e: InputChangeEvent) => {
                          setSearchKey(e.value);
                        }}
                      />
                    </div>
                    <div className="cc-grid-search-list">
                      <CCGrid
                        isLoading={isLoading}
                        selectableMode="multiple"
                        columnFields={colReferral}
                        primaryField={nameOfGrid("ID")}
                        data={referralData}
                        isAutoHiddenPager={false}
                        onSelectionChange={(selectedRows: DataTable[]) => {
                          onChange(nameOf("ActionTypeIDs"), {
                            value: selectedRows,
                          });
                        }}
                        itemPerPage={10}
                        state={{
                          filter: {
                            filters: [
                              {
                                logic: "or",
                                filters: [
                                  {
                                    field: "Name",
                                    operator: "contains",
                                    value: searchKey,
                                  },
                                ],
                              },
                            ],
                            logic: "or",
                          },
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  className={"cc-dialog-button"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!modified || !valid}
                  iconClass={isSubmitLoading ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  className={"cc-dialog-button"}
                  onClick={onSubmit}
                >
                  Select
                </Button>
              </div>
            }
          />
        </div>
      )}
    />
  );
};
