import { history } from "@/AppRoutes";
import { CSL_ROUTE } from "@app/products/csl/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

export const NewCSLButton = () => {
  return (
    <>
      <CCNavButton
        title="New CSL"
        onClick={() => history.push(`${CSL_ROUTE}/new`)}
      />
    </>
  );
};
