import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IPropertyActionBarNavDropdownProps {
  category: string;
}

export const PropertyActionBarNavDropdown = observer(
  ({ category }: IPropertyActionBarNavDropdownProps) => {
    return <CCActionBarNavDropdownNew />;
  }
);
