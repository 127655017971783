import { loadDeferredDutyTransactionDetailTab } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/components/reference-sidebar/detail/api";
import { DTO_DeferredDuty_TransactionTabDetails } from "@app/products/property/journals/not-yet-in-journal/deferred-duty-transactions/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const JournalDeferredDutyTransactionsDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [deferredDutyTransactionInfo, setDeferredDutyTransactionInfo] =
    useState<DTO_DeferredDuty_TransactionTabDetails | undefined>();
  const { lastSelectedId } = useCCProductListViewStore();
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  /**
   * Function calling api
   */
  const handleLoadTabDetail = async () => {
    if (lastSelectedId) {
      setIsLoading(true);
      let errorResponse = undefined;
      const response = await loadDeferredDutyTransactionDetailTab(
        lastSelectedId
      );
      if (isSuccessResponse(response) && response?.data) {
        setDeferredDutyTransactionInfo(response.data);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    }
  };

  //useEffect
  useEffect(() => {
    handleLoadTabDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  //show load failed
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          handleLoadTabDetail();
        }}
      />
    );
  if (!deferredDutyTransactionInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Transaction ID:"}
              value={deferredDutyTransactionInfo?.AccountTransactionId + ""}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Account number:"}
              value={deferredDutyTransactionInfo?.AccountNumber}
            />
            <ProductReferenceRow
              title={"Transaction type:"}
              value={deferredDutyTransactionInfo?.TransactionType}
            />
            <ProductReferenceRow
              title={"Date/Time:"}
              value={formatDisplayValue(
                deferredDutyTransactionInfo?.TransactionDate,
                DATETIME_FORMAT.DATETIME
              )}
            />
            <ProductReferenceRow
              title={"Amount:"}
              value={formatDisplayValue(
                deferredDutyTransactionInfo?.TransactionAmount,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
