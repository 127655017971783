export const mockContacts = [
  {
    ID: "3173054",
    Type: "Owner",
    DisplayName: "Hamish Martin",
    WorkPhone: "653-198",
    Mobile: "196-260-8553",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
    Email: "hasmish@openoffice.com.au",
  },
  {
    ID: 3173058,
    Type: "Applicant",
    DisplayName: "Lena Stojanovski",
    WorkPhone: "(640) 254-957",
    Mobile: "1-540-062-1600",
    PostalAddress: "360 Derrimut Street, HOPPERS CROSSING VIC 3029",
    PreferredMethod_ENUM: "0",
    PreferredMethod_Name: "None",
    Email: "lena@openoffice.com.au",
  },
];
