import { APIResponseStatus } from "@common/constants/response-status";
import { CONFIG_DEFAULT_IDLE } from "@common/hooks/useIdle";
import { IResponseData } from "@common/models/service";
import { ISettings } from "@common/models/settings";
import { aesDecrypt } from "@common/utils/cryptography";
import { BaseService } from "./base.service";

const getSettings = async (): Promise<ISettings> => {
  const response: IResponseData = await BaseService.requests.get(
    "/api/settings"
  );
  if (
    response.status !== APIResponseStatus.SUCCESS ||
    !response.data ||
    !response.data.clientSetting ||
    response.data.length === 0
  ) {
    return {
      systemSettingUrl: "",
      apiKey: "",
      baseAPIUrl: "",
      oDataUrl: "",
      activityDetector: CONFIG_DEFAULT_IDLE,
    };
  }
  return JSON.parse(aesDecrypt(response.data.clientSetting));
};

const createCookie = async (body: any): Promise<any> => {
  const response: IResponseData = await BaseService.requests.post(
    "/api/authentication/createcookie",
    body
  );
  if (
    response.status !== APIResponseStatus.SUCCESS ||
    !response.data ||
    response.data.length === 0
  ) {
    return console.error("CREATE COOKIE FAILS", {
      "baseService.request": response,
    });
  }
  return "";
};

export default {
  getSettings,
  createCookie,
};
