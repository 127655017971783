import { NewTransactionDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/new-transaction/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const NewTransactionButton = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const [showNewTransaction, setShowNewTransaction] = useState<boolean>(false);

  return (
    <>
      <CCNavButton
        title="New transaction"
        onClick={() => setShowNewTransaction(true)}
      />

      {showNewTransaction && (
        <NewTransactionDialog
          onSubmit={() => {
            setShowNewTransaction(false);
            pushNotification({
              title: "New transaction created successfully",
              type: "success",
            });
          }}
          onClose={() => {
            setShowNewTransaction(false);
          }}
        />
      )}
    </>
  );
});
