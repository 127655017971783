import { getSuburbById } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address/api";
import { WastewaterSearchLocality } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/_index";
import { getKeywords } from "@common/apis/coreKeyword";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCKeyValueSearch } from "@components/cc-key-value-search/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { isEmpty, isNil } from "lodash";
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useEffectOnce } from "react-use";

export const ADDRESS_FORM_STEP = "Address";
export const AddressStep = forwardRef((props: IFormStepElement, ref) => {
  const { valueGetter, onChange } = props.formRenderProps;
  useImperativeHandle(ref, () => ({
    async getConfirm() {
      if (valueGetter("Address")) {
        onChange("Address.StreetNamePart_Name", {
          value: valueGetter("Address.PropertyAssessment.Street_Name"),
        });
      }

      return true;
    },
  }));
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
});

const FormStepElement = ({
  formRenderProps,
  nameOf,
  setStepsVisible,
}: IFormStepElement) => {
  const { settings } = useCommonProductStore();
  const { valueGetter, onChange } = formRenderProps;
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const streetTypeAbbreviation = valueGetter(
    "Address.PropertyAssessment.Street_TypeAbbreviation"
  );
  const locationDescription = valueGetter("Address.Location_Description");
  const streetName = valueGetter("Address.PropertyAssessment.Street_Name");
  // const streetSuffix = valueGetter("Address.PropertyAssessment.Street_Suffix");
  const address = valueGetter("Address");

  const checkComplexAddress = valueGetter("_option.checkComplexAddress");

  const isStreetTypeEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
  );

  const isShowBuildingName = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CorporateSettings_PropertyDialog_BuildingName
    ]
  );
  const isShowNearestCrossStreet = getBoolValueSetting(
    settings[
      ECorporateSettingsField
        .CorporateSettings_PropertyDialog_NearestCrossStreet
    ]
  );

  const isUseStreetType = useMemo(() => {
    if (address) {
      if (
        !isStreetTypeEnabled &&
        !isNil(valueGetter("Address.Flag_ForceUse_StreetType"))
      ) {
        onChange("Address.Flag_StreetName_HasParts", {
          value: valueGetter("Address.Flag_ForceUse_StreetType"),
        });

        return valueGetter("Address.Flag_ForceUse_StreetType");
      } else {
        onChange("Address.Flag_StreetName_HasParts", {
          value: isStreetTypeEnabled,
        });

        return isStreetTypeEnabled;
      }
    }
    // eslint-disable-next-line
  }, [isStreetTypeEnabled, valueGetter]);

  // TODO: Re-check this logic later
  // useEffect(() => {
  //   let value: string = streetName;
  //   if (streetTypeAbbreviation && streetTypeAbbreviation !== "") {
  //     if (value?.length > 0) {
  //       value += " ";
  //     }
  //     value += streetTypeAbbreviation;
  //   }

  //   if (streetSuffix && streetSuffix !== "") {
  //     if (value?.length > 0) {
  //       value += " ";
  //     }
  //     value += streetSuffix;
  //   }
  //   if (address) {
  //     onChange("Address.StreetName", {
  //       value: value,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [streetName, streetTypeAbbreviation, streetSuffix]);

  const handleOnChangeStreetType = (event: ComboBoxChangeEvent) => {
    const coreKeyword: CoreKeyword = event.value;
    if (isUseStreetType && coreKeyword) {
      onChange("Address.PropertyAssessment.Street_TypeAbbreviation", {
        value: coreKeyword?.Name,
      });
      onChange("Address.StreetNamePart_Type", {
        value: coreKeyword?.Name,
      });
    } else {
      onChange("Address.PropertyAssessment.Street_TypeAbbreviation", {
        value: "",
      });
      onChange("Address.StreetNamePart_Type", {
        value: "",
      });
    }
    onChange("_option.StreetType", {
      value: coreKeyword?.Name,
    });
  };

  const handelOnChangeLocality = async (event: ComboBoxChangeEvent) => {
    if (event.value) {
      onChange("Address.PropertyAssessment.Locality_Name", {
        value: event.value.Locality,
      });
      onChange("Address.Suburb", {
        value: event.value.Locality,
      });
      if (event.value?.ID) {
        const response = await getSuburbById(event.value.ID);
        if (isSuccessResponse(response)) {
          onChange("Address.State", { value: response.data?.State });
          onChange("Address.PropertyAssessment.Locality_Postcode", {
            value: response.data?.PostCode,
          });
          onChange("Address.Postcode", {
            value: response.data?.PostCode,
          });
        } else {
          notificationRef.current?.pushNotification({
            title: `Suburb API load failed`,
            type: "warning",
          });
        }
      }
    } else {
      onChange("Address.PropertyAssessment.Locality_Name", { value: "" });
      onChange("Address.Suburb", {
        value: "",
      });
    }
  };

  useEffectOnce(() => {
    getKeywords(
      KEYWORD_TYPE.Core_Address_StreetType,
      PRODUCT_TYPE_NUMBER.TownPlanning
    ).then((response) => {
      if (!isSuccessResponse(response)) {
        notificationRef.current?.pushNotification({
          title: `Street type list load failed`,
          type: "warning",
        });
      } else {
        if (isUseStreetType && response && response.data) {
          const streetTypeIndex = response.data.findIndex(
            (item) =>
              item.Name.toLowerCase() === streetTypeAbbreviation?.toLowerCase()
          );

          if (
            !isEmpty(
              valueGetter("Address.PropertyAssessment.Street_TypeAbbreviation")
            ) &&
            streetTypeIndex !== -1
          ) {
            onChange("_option.StreetType", {
              value: response.data[streetTypeIndex],
            });

            onChange("Address.StreetNamePart_Type", {
              value: response.data[streetTypeIndex]?.Name,
            });
          }
        } else {
          if (!isEmpty(streetTypeAbbreviation)) {
            onChange("Address.PropertyAssessment.Street_Name", {
              value: `${streetName}`,
            });
          }
          onChange("StreetType", { value: "" });
        }
      }
    });

    getKeywords(
      KEYWORD_TYPE.Core_LocationDescription,
      PRODUCT_TYPE_NUMBER.TownPlanning
    ).then((response) => {
      if (!isSuccessResponse(response)) {
        notificationRef.current?.pushNotification({
          title: `Location description list load failed`,
          type: "warning",
        });
      } else {
        if (response && response.data) {
          const locationDescriptionIndex = response.data.findIndex(
            (item) => item.Name === locationDescription
          );
          if (locationDescriptionIndex !== -1) {
            onChange("_option.LocationDescriptor", {
              value: response.data[locationDescriptionIndex],
            });
          }
        }
      }
    });
  });

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Property name</label>
            <Field
              name={"Address.PropertyAssessment.PropertyName"}
              component={CCInput}
              placeholder={"Property name"}
              onChange={(event: any) => {
                onChange("Address.PropertyAssessment.PropertyName", {
                  value: event.value,
                });
                onChange("Address.PropertyName", {
                  value: event.value,
                });
              }}
            />
          </div>
          {isShowBuildingName && (
            <div className="cc-field">
              <label className="cc-label">Building name</label>
              <Field
                name={"Address.PropertyAssessment.BuildingName"}
                component={CCInput}
                placeholder={"Building name"}
              />
            </div>
          )}
          <div className="cc-field">
            <label className="cc-label">Location description</label>
            <Field
              name={"_option.LocationDescriptor"}
              component={CCKeyValueSearch}
              placeholder={"Location description"}
              keywordType={KEYWORD_TYPE.Core_LocationDescription}
              productType={PRODUCT_TYPE_NUMBER.TownPlanning}
              value={locationDescription}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(
                  "Address.PropertyAssessment.AddressLocationDescriptor",
                  {
                    value: event.value?.Name,
                  }
                );
                onChange("Address.Location_Description", {
                  value: event.value?.Name,
                });
                onChange("_option.LocationDescriptor", {
                  value: event.value?.Name,
                });
              }}
            />
          </div>
          {isShowNearestCrossStreet && (
            <div className="cc-field">
              <label className="cc-label">Nearest cross street</label>
              <Field
                name={"Address.PropertyAssessment.NearestCrossStreet"}
                component={CCInput}
                placeholder={"Nearest cross street"}
                onChange={(event: any) => {
                  onChange("Address.PropertyAssessment.NearestCrossStreet", {
                    value: event.value,
                  });
                  onChange("Address.NearestCrossStreet", {
                    value: event.value,
                  });
                }}
              />
            </div>
          )}
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Complex address</label>
            <Field
              name="_option.checkComplexAddress"
              checked={checkComplexAddress}
              onChange={(event: SwitchChangeEvent) => {
                onChange("_option.checkComplexAddress", { value: event.value });
                onChange("Address.ComplexAddress", { value: event.value });
              }}
              component={CCSwitch}
            />
          </div>
        </div>
        {checkComplexAddress ? (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Unit number start</label>
                <div className="cc-input-group-cols-3">
                  <Field
                    className="cc-col-span-2"
                    name={"Address.PropertyAssessment.UnitNumber1"}
                    placeholder={"Unit number start"}
                    min={0}
                    component={CCNumericTextBox}
                  />
                  <Field
                    name={"Address.PropertyAssessment.UnitSuffix1"}
                    placeholder={"Suffix"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Unit number end</label>
                <div className="cc-input-group-cols-3">
                  <Field
                    className="cc-col-span-2"
                    name={"Address.PropertyAssessment.UnitNumber2"}
                    placeholder={"Unit number end"}
                    component={CCNumericTextBox}
                    min={0}
                  />
                  <Field
                    name={"Address.PropertyAssessment.UnitSuffix2"}
                    placeholder={"Suffix"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Unit abbreviation</label>
                <Field
                  name={"Address.PropertyAssessment.UnitAbbreviation"}
                  component={CCInput}
                  placeholder={"Unit abbreviation"}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">House number start</label>
                <div className="cc-input-group-cols-3">
                  <Field
                    className="cc-col-span-2"
                    name={"Address.PropertyAssessment.HouseNumber1"}
                    placeholder={"House number start"}
                    component={CCNumericTextBox}
                    min={0}
                  />
                  <Field
                    name={"Address.PropertyAssessment.HouseSuffix1"}
                    placeholder={"Suffix"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">House number end</label>
                <div className="cc-input-group-cols-3">
                  <Field
                    className="cc-col-span-2"
                    name={"Address.PropertyAssessment.HouseNumber2"}
                    placeholder={"House number end"}
                    component={CCNumericTextBox}
                    min={0}
                  />
                  <Field
                    name={"Address.PropertyAssessment.HouseSuffix2"}
                    placeholder={"Suffix"}
                    component={CCInput}
                  />
                </div>
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Floor number start</label>
                <div className="cc-input-group-cols-3">
                  <Field
                    className="cc-col-span-2"
                    name={"Address.PropertyAssessment.FloorNumber1"}
                    placeholder={"Floor number start"}
                    component={CCNumericTextBox}
                    min={0}
                  />
                  <Field
                    name={"Address.PropertyAssessment.FloorSuffix1"}
                    placeholder={"Suffix"}
                    component={CCInput}
                  />
                </div>
              </div>

              <div className="cc-field">
                <label className="cc-label">Floor number end</label>
                <div className="cc-input-group-cols-3">
                  <Field
                    className="cc-col-span-2"
                    name={"Address.PropertyAssessment.FloorNumber2"}
                    placeholder={"Floor number end"}
                    component={CCNumericTextBox}
                    min={0}
                  />
                  <Field
                    name={"Address.PropertyAssessment.FloorSuffix2"}
                    placeholder={"Suffix"}
                    component={CCInput}
                  />
                </div>
              </div>
              <div className="cc-field">
                <label className="cc-label">Floor abbreviation</label>
                <Field
                  name={"Address.PropertyAssessment.FloorTypeAbbreviation"}
                  component={CCInput}
                  placeholder="Floor abbreviation"
                />
              </div>
            </div>
          </>
        ) : (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Unit number</label>
              <Field
                name={"Address.PropertyAssessment.UnitNumber1"}
                placeholder={"Unit number"}
                component={CCNumericTextBox}
                min={0}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">House number</label>
              <Field
                name={"Address.PropertyAssessment.HouseNumber1"}
                placeholder={"House number"}
                component={CCNumericTextBox}
                min={0}
              />
            </div>
          </div>
        )}
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {/* @TODO Missing Street_Name_AutoComplete ComBoBox, Add feature later */}
          <div className="cc-field">
            <label className="cc-label">Street name</label>
            <Field
              name={"Address.PropertyAssessment.Street_Name"}
              placeholder={"Street name"}
              component={CCInput}
              onChange={(event: any) => {
                onChange("Address.PropertyAssessment.Street_Name", {
                  value: event.value,
                });
                onChange("Address.StreetName", {
                  value: event.value,
                });
                onChange("Address.StreetNamePart_Name", { value: event.value });
              }}
            />
          </div>
          {isStreetTypeEnabled && (
            <>
              <div className="cc-field">
                <label className="cc-label">Street type</label>
                <Field
                  name={"_option.StreetType"}
                  component={CCKeyValueSearch}
                  placeholder={"Street type"}
                  keywordType={KEYWORD_TYPE.Core_Address_StreetType}
                  productType={PRODUCT_TYPE.TownPlanning}
                  onChange={handleOnChangeStreetType}
                />
              </div>
              {checkComplexAddress && (
                <div className="cc-field">
                  <label className="cc-label">Street suffix</label>
                  <Field
                    name={"Address.PropertyAssessment.Street_Suffix"}
                    component={CCInput}
                    placeholder={"Street suffix"}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Locality name</label>
            <Field
              name={"Address.PropertyAssessment.Locality_Name"}
              component={WastewaterSearchLocality}
              placeholder={"Locality name"}
              onChange={handelOnChangeLocality}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postcode</label>
            <Field
              name={"Address.PropertyAssessment.Locality_Postcode"}
              placeholder={"Postcode"}
              component={CCInput}
              onChange={(event: any) => {
                onChange("Address.PropertyAssessment.Locality_Postcode", {
                  value: event.value,
                });
                onChange("Address.Postcode", { value: event.value });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality PFI</label>
            <Field
              name={"Address.PropertyAssessment.Locality_PFI"}
              placeholder={"Locality PFI"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality code</label>
            <Field
              name={"Address.PropertyAssessment.Locality_Code"}
              placeholder={"Locality code"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">State</label>
            <Field
              name={"Address.State"}
              placeholder={"State"}
              component={CCInput}
            />
          </div>
        </div>
      </section>
    </>
  );
};
