import { eventEmitter } from "@/App";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { ACTIONS_AND_WORKFLOWS_GRID_ID } from "@app/products/property/components/child-screen/actions/constant";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ConfirmFinishDialog = observer(() => {
  //State
  const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);
  //Store
  const { setDataForFinishDialog, dataForFinishDialog, resetStore } =
    useConfirmFinishDialogStore();

  /**
   * handle confirmation finish dialog
   */
  const handleConfirmFinish = async () => {
    if (!dataForFinishDialog) return;
    setIsLoadingFinish(true);
    await dataForFinishDialog.finishCallback();
    eventEmitter.emit(CCGridEventType.RefreshOData, {
      gridIds: [ACTIONS_AND_WORKFLOWS_GRID_ID],
    });
    setIsLoadingFinish(false);
    setDataForFinishDialog(undefined);
  };

  useEffectOnce(() => {
    return () => resetStore();
  });

  return (
    <>
      {dataForFinishDialog && (
        <ConfirmDialog
          title="Confirmation"
          subMessage={
            dataForFinishDialog?.confirmMessage ??
            "Are you sure you want to submit?"
          }
          isLoadingYes={isLoadingFinish}
          onAsyncConfirm={handleConfirmFinish}
          onClosePopup={() => setDataForFinishDialog(undefined)}
        />
      )}
    </>
  );
});
