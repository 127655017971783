import { colRemoveMasterSubMeter } from "@app/products/property/meters/[id]/components/action-bar/dialogs/remove-master-sub-meter/config";
import { removeAssociationMeterValid } from "@app/products/property/meters/[id]/components/action-bar/dialogs/remove-master-sub-meter/util";
import { AssociationMeter } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { nameOfFactory } from "@common/utils/common";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOf = nameOfFactory<AssociationMeter>();
export interface IRemoveMasterSubMeterDialogProps {
  onClose: () => void;
  onSubmit: () => void;
}
export const RemoveMasterSubMeterDialog = observer(
  ({ onSubmit, onClose }: IRemoveMasterSubMeterDialogProps) => {
    const { selectedAssociationMeter, setSelectedAssociationMeter } =
      useMeterInstallationStore();

    const validNotifications = useMemo((): IAppNotificationItem[] => {
      return removeAssociationMeterValid(selectedAssociationMeter);
    }, [selectedAssociationMeter]);

    return (
      <CCDialog
        titleHeader={"Remove Master/Sub Meter"}
        onClose={onClose}
        maxWidth={"25%"}
        height={"auto"}
        bodyElement={
          <div className="cc-form">
            <section className="cc-field">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCLocalNotification
                    defaultNotifications={validNotifications}
                  />
                </div>
                <div className="cc-field">
                  <CCGrid
                    columnFields={colRemoveMasterSubMeter}
                    data={selectedAssociationMeter ?? []}
                    editableMode="cell"
                    onDataChange={(data) => {
                      setSelectedAssociationMeter(data);
                    }}
                    primaryField={nameOf("Id")}
                  />
                </div>
              </div>
            </section>
          </div>
        }
        footerElement={
          <div className="cc-dialog-footer-actions-right">
            <Button className="cc-dialog-button" onClick={onClose}>
              NO
            </Button>
            <Button
              className="cc-dialog-button"
              disabled={validNotifications.length > 0}
              themeColor="primary"
              onClick={onSubmit}
            >
              YES
            </Button>
          </div>
        }
      />
    );
  }
);
