import { SchemeAccountFormElement } from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/general/components/form-element/_index";
import { useSchemesAccountStore } from "@app/products/property/schemes/[id]/account/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const SchemeAccountForm = observer(() => {
  const { schemesAccount } = useSchemesAccountStore();
  return (
    <div className="cc-form">
      <Form
        // onSubmit={handleSubmit}
        initialValues={schemesAccount}
        render={(formRenderProps: FormRenderProps) => (
          <SchemeAccountFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
