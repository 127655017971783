import { ECustomColNameProperty } from "@app/products/property/config";
import { getTitleDetailsTabById } from "@app/products/property/titles/[id]/components/reference-sidebar/detail/api";
import { DTO_Title_W_Detail } from "@app/products/property/titles/[id]/model";
import { Label } from "@common/stores/products/config";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const TitleDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [titleInfo, setTitleInfo] = useState<DTO_Title_W_Detail | undefined>();
  const { lastSelectedRow } = useCCProductListViewStore();

  //Get labels
  const [titleIDLabel, titleNumberLabel, titleTypeLabel] =
    Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Title_ID,
      ECustomColNameProperty.Title_Number,
      ECustomColNameProperty.Title_Type,
    ]);

  const params: { id: string } = useParams();
  const titleId =
    lastSelectedRow && lastSelectedRow?.Title_Id
      ? lastSelectedRow?.Title_Id
      : params.id;

  useEffect(() => {
    if (titleId) {
      setIsLoading(true);
      getTitleDetailsTabById(parseInt(titleId)).then((data) => {
        if (data?.titleDetail) {
          setTitleInfo(data.titleDetail);
        }
        setIsLoading(false);
      });
    }
  }, [titleId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!titleInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Legal Description:"}
              value={titleInfo?.LegalDescription}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={`${titleIDLabel}:`}
                value={titleInfo?.Id + ""}
              />
              <ProductReferenceRow
                title={`${titleNumberLabel}:`}
                value={titleInfo?.ReferenceNumber ?? ""}
              />
              <ProductReferenceRow
                title={`${titleTypeLabel}:`}
                value={titleInfo?.Type}
              />
              <ProductReferenceRow title={"Lot:"} value={titleInfo?.Lot} />
              <ProductReferenceRow
                title={"Plan Type:"}
                value={titleInfo?.PlanType}
              />
              <ProductReferenceRow
                title={"Plan Number:"}
                value={titleInfo?.PlanNumber}
              />
              <ProductReferenceRow
                title={"Status:"}
                success
                value={titleInfo?.Status}
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
