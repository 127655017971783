import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DynamicQuestionList_Light } from "@common/models/dynamicQuestion";

// TODO: Need change
export const getDynamicQuestionByChecklistType = async (
  checklistType: number
): Promise<APIResponse<DynamicQuestionList_Light[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DynamicQuestionList_Light[]>(
      `api/core/internal/dynamicquestions/checklistbytype/${checklistType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
