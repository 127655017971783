import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import { SettingButtonEdit } from "@components/setting/setting-button-edit/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";
interface ISettingComboboxListProps {
  name: string;
  label: string;
  data: any;
  textField: string;
  dataItemKey: string;
  value: any;
  isMandatory?: boolean;
  useAutoComplete?: boolean;
  disabled?: boolean;
  onChange?: (event: ComboBoxChangeEvent) => void;
  description?: string;
  isEnabledEditButton?: boolean;
  handleOnEdit?: any;
  handleOnCancel?: any;
  formRenderProps?: FormRenderProps;
  isLoading?: boolean;
}

export const SettingComboboxList: React.FC<ISettingComboboxListProps> = ({
  name,
  label,
  data,
  textField,
  dataItemKey,
  value,
  isMandatory,
  disabled,
  onChange,
  description = "",
  isEnabledEditButton,
  handleOnEdit = () => {},
  handleOnCancel = () => {},
  formRenderProps = undefined,
  isLoading,
  ...others
}) => {
  return (
    <div className="cc-setting-dropdown-list">
      <div className="cc-label-flex">
        <DescriptionLabel title={label} description={description} />
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </div>
      <div className={isEnabledEditButton ? "cc-field-flex" : ""}>
        <div className="cc-dropdown-list">
          <CCSearchComboBox
            disabled={disabled}
            name={name}
            data={data}
            textField={textField}
            dataItemKey={dataItemKey}
            value={value}
            className="cc-form-control"
            onChange={onChange}
            {...others}
          />
        </div>
        {isEnabledEditButton ? (
          <SettingButtonEdit
            isEditing={!disabled}
            handleOnEdit={handleOnEdit}
            handleOnCancel={handleOnCancel}
            formRenderProps={formRenderProps}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};
