import { checkIsValidFile } from "@app/core/attachment/components/dialogs/util";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import React from "react";

export const IMPORT_FILE_FORM_STEP = "ImportFile";
const ACCEPT_FORMAT = [".txt"];
const MAX_FILE_SIZE = 2097152;

const fileValidator = (data: any) => {
  if (!checkIsValidFile(data)) {
    return "File size too large.";
  }
  return requiredValidator(data);
};

export const ImportFileFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { onChange } = formRenderProps;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Upload file
            <CCTooltip type="validator" position="right" />
            <CCTooltip
              type="custom"
              position="auto"
              content=" "
              customTemplate={
                <div>
                  &#8226; Allowed file type(s): .txt
                  <br />
                  &#8226; Maximum upload file size: 2 MB
                </div>
              }
            >
              <i className="fa fa-info-circle ml-1 text-accent" />
            </CCTooltip>
          </label>
          <Field
            name={nameOf("File")}
            component={CCUploadFile}
            batch={false}
            multiple={false}
            autoUpload={false}
            accept={ACCEPT_FORMAT.join(",")}
            withCredentials={false}
            restrictions={{
              allowedExtensions: ACCEPT_FORMAT,
              maxFileSize: MAX_FILE_SIZE,
            }}
            onAdd={(event: UploadOnAddEvent) => {
              onChange(nameOf("File"), {
                value: event.newState,
              });
            }}
            onRemove={(event: UploadOnRemoveEvent) => {
              onChange(nameOf("File"), {
                value: event.newState,
              });
            }}
            showActionButtons={false}
            validator={fileValidator}
          />
        </div>
      </div>
    </section>
  );
};
