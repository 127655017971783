import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { stringLengthValidator } from "@app/products/direct-debit/authorisations/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface IDDAuthorisationGeneralSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DirectDebitAuthorisation>();

// Validators
const authorisedByNameValidator = (value: any) =>
  stringLengthValidator(value, 1, 100);

export const DDAuthorisationGeneralSection = ({
  formRenderProps,
}: IDDAuthorisationGeneralSectionProps) => {
  const { valueGetter } = formRenderProps;
  const getValue = (nameOfField: keyof DirectDebitAuthorisation) =>
    valueGetter(nameOf(nameOfField));
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Authorisation number" />
          <Field
            name={nameOf("Authorisation_Number")}
            placeholder="Authorisation number"
            readOnly={true}
            component={CCInput}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Authorised by name" isMandatory />
          <Field
            name={nameOf("Authorised_By_Name")}
            placeholder="Authorised by name"
            component={CCInput}
            readOnly={true}
            validator={authorisedByNameValidator}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Authorised date" isMandatory />
          <Field
            name={nameOf("Authorised_Date")}
            placeholder="Authorised date"
            component={CCDatePicker}
            format={DATE_FORMAT.DATE_CONTROL}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCValueField label="Email" value={getValue("EMail")} />
        </div>
        <div className="cc-field">
          <CCValueField label="Phone number" value={getValue("Phone_No")} />
        </div>
        <div className="cc-field">
          <CCValueField
            label="Mobile phone number"
            value={getValue("Mobile_Phone_No")}
          />
        </div>
      </div>
    </section>
  );
};
