import {
  getDocumentTemplate,
  getDocumentTemplateLOVS,
} from "@app/products/property/system-admin/document-template/[id]/api";
import {
  DocumentTemplate,
  DocumentTemplateLOVS,
} from "@app/products/property/system-admin/document-template/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class DocumentTemplateStore {
  private _documentTemplate?: DocumentTemplate = undefined;
  private _isLoading: boolean = false;
  private _isLoadingSave: boolean = false;
  private _isDeletedRecord: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _documentLovs?: DocumentTemplateLOVS = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get documentTemplate() {
    return toJS(this._documentTemplate);
  }
  setDocumentTemplate = (documentTemplate?: DocumentTemplate | undefined) => {
    runInAction(() => {
      this._documentTemplate = documentTemplate;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get isLoadingSave() {
    return this._isLoadingSave;
  }
  setIsLoadingSave = (isLoadingSave: boolean) => {
    runInAction(() => {
      this._isLoadingSave = isLoadingSave;
    });
  };

  get isDeletedRecord() {
    return this._isDeletedRecord;
  }
  setIsDeletedRecord = (isDeletedRecord: boolean) => {
    runInAction(() => {
      this._isDeletedRecord = isDeletedRecord;
    });
  };

  get documentLovs() {
    return toJS(this._documentLovs);
  }
  setDocumentTemplateLovs = (documentLovs?: DocumentTemplateLOVS) => {
    runInAction(() => {
      this._documentLovs = documentLovs;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._documentTemplate = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._documentLovs = undefined;
    });
  };
  loadDocumentTemplate = async (documentId?: number): Promise<boolean> => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    const responseLOVS = await getDocumentTemplateLOVS();
    if (isSuccessResponse(responseLOVS)) {
      this.setDocumentTemplateLovs(responseLOVS.data);
    } else {
      errorResponse = {
        status: responseLOVS.status,
        error: responseLOVS.error,
      };
    }
    if (documentId) {
      const response = await getDocumentTemplate(documentId);
      if (isSuccessResponse(response)) {
        if (!response.data?.IsActive) {
          this.setIsDeletedRecord(true);
          appNotificationStore.pushNotification({
            type: "info",
            autoClose: false,
            title:
              "Important note: you are viewing an inactive record. You are not allowed to perform any workflow functions or make changes to this record",
          });
        } else {
          appNotificationStore.clearNotifications();
          this.isDeletedRecord && this.setIsDeletedRecord(false);
        }
        this.setDocumentTemplate(response.data);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };
}

const documentTemplateStoreContext = createContext(new DocumentTemplateStore());
export const useDocumentTemplateStore = () => {
  return useContext(documentTemplateStoreContext);
};
