import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Parcel } from "./model";

const nameOf = nameOfFactory<VO_Parcel>();

export const colList: IColumnFields[] = [
  {
    field: nameOf("Legal_Description"),
    title: "Legal Description",
    locked: true,
    linkTo: (dataItem) => `${PROPERTY_PARCEL_ROUTE}/${dataItem.Parcel_Id}`,
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
    width: 300,
  },
  {
    field: nameOf("Reference"),
    title: "Parcel Reference",
  },

  { field: nameOf("Locality_Name"), title: "Locality" },
  {
    field: nameOf("Census_District"),
    title: "Census District",
  },
  {
    field: nameOf("Electoral_Division_Name"),
    title: "Electoral Division",
  },
  {
    field: nameOf("State_Electoral_Division"),
    title: "State Code Electoral Division",
  },
  {
    field: nameOf("Federal_Electoral_Division"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOf("Land_Area"),
    title: "Land Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  { field: nameOf("Zones"), title: "Zones" },
  { field: nameOf("Land_Uses"), title: "Land Uses" },
  { field: nameOf("Owners_Names"), title: "Owners Name" },
  { field: nameOf("Parcel_Status_Name"), title: "Status" },
  {
    field: nameOf("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
