import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";

interface IMoveCommentsToInstructionsDialog {
  onClose: () => void;
}
export const MoveCommentsToInstructionsDialog = observer(
  ({ onClose }: IMoveCommentsToInstructionsDialog) => {
    const { pushNotification } = useCCAppNotificationStore();

    const handleConfirmYes = () => {
      onClose();
      pushNotification({
        title:
          "Move reading comments to meter reading instructions successfully",
        type: "success",
      });
    };

    return (
      <ConfirmDialog
        title="Move Meter Reading Comments to Meter Reading Instructions Confirmation"
        subMessage="Do you wish to move reading comments to the Meter Reading Instructions?"
        onClosePopup={() => onClose()}
        onConfirmYes={handleConfirmYes}
      />
    );
  }
);
