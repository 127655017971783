import { PPRAppeal } from "@app/products/town-planning/ppr/permit-appeals/all/model";
import { ICalendarData } from "@components/cc-calendar/model";
import { setHours } from "date-fns";
import { isSameDay } from "date-fns/esm";

//TODO enhance later just fix temporary
export const processAppealHearingCalendarData = (
  rawData: PPRAppeal[]
): ICalendarData[] => {
  return rawData.map((data: PPRAppeal) => {
    const checkIsSameDay = isSameDay(
      data?.HearingDate as Date,
      data?.HearingDateEnd as Date
    );
    const newData: ICalendarData = {
      id: data?.ID,
      title: data?.CalendarSubject,
      start: new Date(data?.HearingDate as Date),
      end: checkIsSameDay
        ? setHours(new Date(data?.HearingDateEnd as Date), 23)
        : new Date(data?.HearingDateEnd as Date),
    };
    return newData;
  });
};
