import { validatorDateDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/components/util";
import { useNewSwineBrandDialogStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/swine-brands/store";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IAddSwineBrandDialogProps {
  onClose: () => void;
  onSubmit: (value: DTO_PIC_Swine) => void;
  defaultNotifications?: IAppNotificationItem[];
}
const nameOf = nameOfFactory<DTO_PIC_Swine>();
export const SwineBrandDialog = observer(
  ({ onClose, onSubmit, defaultNotifications }: IAddSwineBrandDialogProps) => {
    const { lov } = useNewSwineBrandDialogStoreContext();
    const handleSubmit = (form: any) => {
      onSubmit(form?.values);
    };
    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={{
          Swine_Type_Id: 1, // for Swine
          Swine_Type_Name: lov?.SwineTypes?.find((item) => item.Code === 1)
            ?.Name,
        }}
        validator={validatorDateDialog}
        render={(formRenderProps: FormRenderProps) => {
          const { valid, onSubmit, onChange, valueGetter } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth={"25%"}
                height="auto"
                onClose={onClose}
                titleHeader={"Add Swine Brand"}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification
                      defaultNotifications={defaultNotifications}
                    />
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Swine type
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("Swine_Type_Id")}
                            component={CCSearchComboBox}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange(nameOf("Swine_Type_Id"), {
                                value: event?.value?.Code,
                              });
                              onChange(nameOf("Swine_Type_Name"), {
                                value: event?.value?.Name,
                              });
                            }}
                            value={getDropdownValue(
                              valueGetter(nameOf("Swine_Type_Id")),
                              lov?.SwineTypes ?? [],
                              "Code"
                            )}
                            data={lov?.SwineTypes ?? []}
                            textField="Name"
                            dataItemKey="Code"
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">From date</label>
                          <Field
                            name={nameOf("Effective_From")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            onChange={(event: DatePickerChangeEvent) => {
                              let fromDateValue = event.target?.value;
                              onChange(nameOf("Effective_From"), {
                                value: fromDateValue,
                              });
                              onChange("Editing", {
                                value: "Effective_From",
                              });
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">To date</label>
                          <Field
                            name={nameOf("Effective_To")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            onChange={(event: DatePickerChangeEvent) => {
                              let toDateValue = event.target?.value;
                              onChange(nameOf("Effective_To"), {
                                value: toDateValue,
                              });
                              onChange("Editing", {
                                value: "Effective_To",
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!valid}
                      themeColor="primary"
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
