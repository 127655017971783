import { history } from "@/AppRoutes";
import { putAppealed } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appealed/api";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AppealedButtonStore {
  constructor() {
    makeAutoObservable(this);
  }
  //Action
  appealed = async (applicationId: number, data: IdentityPacket) => {
    const response = await putAppealed(applicationId);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.pushNotification({
        title: "Application appealed successfully",
        type: "success",
        onClose: () => {
          history.replace(`${APPEAL_ROUTE}/${data.ID}`);
        },
      });
      return true;
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Appeal application failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
      return false;
    }
  };
}
export const appealedButtonStore = new AppealedButtonStore();
const appealedButtonContext = createContext(appealedButtonStore);
export const useAppealedButtonStore = () => {
  return useContext(appealedButtonContext);
};
