import { getMasterPropertyAssessments } from "@app/products/property/assessments/components/dialogs/add-assessment-range-dialog/api";
import { DTO_MasterProperty_AssessmentRange } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/components/form-elements/associations/model";
import { DTO_MP_Assessment } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/model";
import { isSuccessResponse } from "@common/apis/util";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { orderBy } from "lodash";
import React, { useRef, useState } from "react";

interface IAddAssessmentRangeDialogProps {
  onClose: () => void;
  handleAddAssessments: (assessmentList: DTO_MP_Assessment[]) => void;
  currentAssessments: DTO_MP_Assessment[];
  defaultMessage?: string;
}
const nameOf = nameOfFactory<DTO_MasterProperty_AssessmentRange>();

export const AddAssessmentRangeDialog = ({
  onClose,
  handleAddAssessments,
  currentAssessments,
  defaultMessage = "No assessments have been selected that are not already associated with the master property.",
}: IAddAssessmentRangeDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const filterAssessmentsCanBeAdd = (assessmentList: DTO_MP_Assessment[]) => {
    const currentAssessmentNumbers = currentAssessments.map(
      (currentAssessment: DTO_MP_Assessment) => currentAssessment.Assess_Number
    );
    return assessmentList.filter((assessmentListItem: DTO_MP_Assessment) => {
      const existedInAssessment = currentAssessmentNumbers.includes(
        assessmentListItem.Assess_Number
      );
      return !existedInAssessment;
    });
  };

  const handleSubmit = (data: DTO_MasterProperty_AssessmentRange) => {
    setIsLoading(true);
    getMasterPropertyAssessments(data).then((response) => {
      if (isSuccessResponse(response)) {
        const assessmentList = response?.data?.Assessments;
        if (assessmentList && assessmentList.length > 0) {
          let newAssessmentList: DTO_MP_Assessment[] = [];
          if (!currentAssessments) {
            newAssessmentList = [...assessmentList];
          } else {
            //Check duplicate
            const assessmentsCanBeAdd =
              filterAssessmentsCanBeAdd(assessmentList);
            if (assessmentsCanBeAdd && assessmentsCanBeAdd?.length === 0) {
              notificationRef.current?.pushNotification({
                title: defaultMessage,
                type: "error",
              });
              setIsLoading(false);
              return;
            }
            //Process assessments
            newAssessmentList = [...currentAssessments, ...assessmentsCanBeAdd];
          }

          //Add assessments (sort by ascending order of Assess_Number) to grid
          handleAddAssessments(orderBy(newAssessmentList, "Assess_Number"));
        } else {
          notificationRef.current?.pushNotification({
            title: defaultMessage,
            type: "error",
            autoClose: false,
          });
        }
      } else {
        notificationRef.current?.pushNotification({
          title: response?.error ?? defaultMessage,
          type: "error",
        });
      }
      setIsLoading(false);
    });
  };

  return (
    <Form
      onSubmitClick={({ values }) => {
        handleSubmit(values as DTO_MasterProperty_AssessmentRange);
      }}
      render={(formRenderProps: FormRenderProps) => (
        <CCDialog
          titleHeader="Associate Assessments"
          maxWidth="30%"
          height="auto"
          onClose={onClose}
          disabled={isLoading}
          bodyElement={
            <FormElement className="cc-form cc-add-assessment-range-dialog">
              <section className="cc-field-group">
                <CCLocalNotification ref={notificationRef} />
                <div className="cc-form-cols-2">
                  <div className="cc-field cc-cols-span-1">
                    <CCLabel title="Add assessment number from" isMandatory />
                    <Field
                      name={nameOf("AsessmentNumberFrom")}
                      placeholder={"Assessment number from"}
                      component={CCNumericTextBox}
                      min={0}
                      validator={requiredValidator}
                      format={NUMBER_FORMAT.NUMBER2}
                    />
                  </div>
                  <div className="cc-field cc-cols-span-1">
                    <label className="cc-label">To</label>
                    <Field
                      name={nameOf("AsessmentNumberTo")}
                      placeholder={"Assessment number to"}
                      component={CCNumericTextBox}
                      min={
                        formRenderProps.valueGetter("AsessmentNumberFrom") ?? 0
                      }
                      format={NUMBER_FORMAT.NUMBER2}
                    />
                  </div>
                </div>
              </section>
            </FormElement>
          }
          footerElement={
            <div className="cc-dialog-footer-actions-right">
              <Button
                className="cc-dialog-button"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                className="cc-dialog-button"
                themeColor="primary"
                onClick={formRenderProps.onSubmit}
                type="submit"
                disabled={!formRenderProps.valid || !formRenderProps.modified}
                iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
              >
                OK
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
