import {
  ACCEPT_FORMAT,
  colComments,
  colProperty,
  MAX_FILE_SIZE,
} from "@app/products/property/fire-prevention/components/dialogs/new-inspection/config";
import { mockNewInspectionData } from "@app/products/property/fire-prevention/components/dialogs/new-inspection/mock";
import {
  IPropertyData,
  IPropertyGridData,
  OUTCOME_TYPE,
} from "@app/products/property/fire-prevention/components/dialogs/new-inspection/model";
import SelectPropertyDialog from "@app/products/property/fire-prevention/components/dialogs/select-property/_index";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT, DATETIME_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isUndefined } from "lodash";
import React, { useRef, useState } from "react";
import "./_index.scss";

interface INewInspectionDialog {
  onEnterAndNew: () => void;
  onEnterAndDone: () => void;
  onClose: () => void;
}

const NewInspectionDialog = ({
  onEnterAndNew,
  onEnterAndDone,
  onClose,
}: INewInspectionDialog) => {
  const [showSelectPropertyDialog, setShowSelectPropertyDialog] =
    useState(false);
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const handleValidateForm: any = (
    event: React.SyntheticEvent<any>,
    formRenderProps: FormRenderProps,
    onEnter: () => void
  ) => {
    if (
      formRenderProps.modified &&
      formRenderProps.valueGetter("Property")?.length >= 1
    ) {
      formRenderProps.onSubmit(event);
      onEnter();
      return;
    }

    if (!formRenderProps.valid) return;

    if (
      formRenderProps.valueGetter("Property")?.length === 0 ||
      isUndefined(formRenderProps.valueGetter("Property"))
    ) {
      notificationRef.current?.pushNotification({
        title: "Property should be selected",
        type: "warning",
      });
    } else {
      notificationRef.current?.pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    }
  };

  const onChangeSelectProperty = (
    formRenderProps: FormRenderProps,
    value: IPropertyData
  ) => {
    const newValue: IPropertyGridData = formRenderProps
      .valueGetter("Property")
      .slice()
      .concat(value);
    formRenderProps.onChange("Property", { value: newValue });
  };

  return (
    <div className="cc-new-inspection cc-form">
      <Form
        onSubmit={(data: any) => {}}
        initialValues={mockNewInspectionData}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;
          const outcomeValue = valueGetter("Outcome");
          const isDisableClearanceBy =
            isUndefined(outcomeValue) ||
            outcomeValue === OUTCOME_TYPE.NO_HAZARD;

          return (
            <CCDialog
              titleHeader={"New Inspection"}
              onClose={onClose}
              maxHeight="80%"
              maxWidth="45%"
              bodyElement={
                <FormElement className="cc-form">
                  <div className="cc-field-group">
                    <CCLocalNotification ref={notificationRef} />
                    {showSelectPropertyDialog && (
                      <SelectPropertyDialog
                        onClose={() => setShowSelectPropertyDialog(false)}
                        onSubmit={(e) => {
                          onChangeSelectProperty(formRenderProps, e);
                          setShowSelectPropertyDialog(false);
                        }}
                        listPropertyExistedData={valueGetter("Property")}
                      />
                    )}

                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Property</label>
                        <CCGrid
                          toolbar={
                            <div className="cc-grid-tools-bar">
                              <Button
                                type="button"
                                iconClass="fas fa-plus"
                                title="Select"
                                onClick={(event: any) => {
                                  event.preventDefault();
                                  setShowSelectPropertyDialog(true);
                                }}
                              />
                            </div>
                          }
                          className="cc-property-grid-data"
                          data={valueGetter("Property") || []}
                          columnFields={colProperty}
                          primaryField="Id"
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Comments in previous cases
                        </label>
                        <CCGrid
                          className="cc-comments-grid-data"
                          data={valueGetter("CommentsInPreviousCases") || []}
                          columnFields={colComments}
                          primaryField="Id"
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Comments for this case
                        </label>
                        <Field
                          name="CommentsForThisCase"
                          component={CCTextArea}
                          placeholder="Comments for this case"
                          rows={3}
                        />
                      </div>
                    </div>
                    <label className="cc-label">Inspection details</label>
                    <div className="cc-custom-sub-panel-bar">
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Case ID</label>
                          <Field name="CaseId" component={CCInput} disabled />
                        </div>
                      </div>

                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Date</label>
                          <Field
                            name="Date"
                            component={CCDateTimePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            defaultValue={new Date()}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Last inspection</label>
                          <Field
                            name="LastInspection"
                            component={CCDateTimePicker}
                            format={DATETIME_FORMAT.DATETIME_CONTROL}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Outcome</label>
                          <Field
                            name="Outcome"
                            component={CCSearchComboBox}
                            data={valueGetter("OutcomeValues")}
                            textField={nameOfLov("Name")}
                            dataItemKey={nameOfLov("Code")}
                            value={getDropdownValue(
                              valueGetter("Outcome"),
                              valueGetter("OutcomeValues"),
                              nameOfLov("Code")
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange("Outcome", {
                                value: event.target.value?.Code ?? null,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {!isDisableClearanceBy && (
                        <React.Fragment>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">Clearance</label>
                              <Field
                                name="Clearance"
                                component={CCSearchComboBox}
                                data={valueGetter("ClearanceValues")}
                                textField={nameOfLov("Name")}
                                dataItemKey={nameOfLov("Code")}
                                value={getDropdownValue(
                                  valueGetter("Clearance"),
                                  valueGetter("ClearanceValues"),
                                  nameOfLov("Code")
                                )}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  onChange("Clearance", {
                                    value: event.target.value?.Code ?? null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">By</label>
                              <Field
                                name="ClearanceBy"
                                component={CCDatePicker}
                                format={DATE_FORMAT.DATE_CONTROL}
                                defaultValue={new Date()}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Additional notes</label>
                          <Field
                            name="AdditionalNotes"
                            component={CCTextArea}
                            placeholder="Additional notes"
                            rows={3}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Specific instructions
                          </label>
                          <Field
                            name="SpecificInstructions"
                            component={CCTextArea}
                            textField="searchText"
                            placeholder="Specific instructions"
                            rows={3}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">
                            Copy instructions from previous inspection
                          </label>
                          <Field
                            name="CopyInstructionsFromPreviousInspection"
                            component={CCSwitch}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Add attachment</label>
                          <Field name="AddAttachment" component={CCSwitch} />
                        </div>
                      </div>
                      <div>
                        {formRenderProps.valueGetter("AddAttachment") && (
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <CCUploadFile
                                batch={false}
                                multiple={true}
                                accept={ACCEPT_FORMAT.join(",")}
                                withCredentials={false}
                                defaultFiles={[]}
                                restrictions={{
                                  maxFileSize: MAX_FILE_SIZE,
                                  allowedExtensions: ACCEPT_FORMAT,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    type="button"
                    className={"cc-dialog-button"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="button"
                    name="EnterAndNew"
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={(e) => {
                      handleValidateForm(e, formRenderProps, onEnterAndNew);
                    }}
                  >
                    Enter & New
                  </Button>

                  <Button
                    type="button"
                    name="EnterAndDone"
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={(e) => {
                      handleValidateForm(e, formRenderProps, onEnterAndDone);
                    }}
                    disabled={!formRenderProps.valid}
                  >
                    Enter & Done
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    </div>
  );
};

export default NewInspectionDialog;
