import { DTO_DynamicColumns } from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/model";
import {
  typeDate,
  typeDateTime,
  typeNumber,
  typeText,
} from "@app/products/property/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const getDataType = (value: number) => {
  if (Object.values(typeNumber).includes(value)) {
    return NUMBER_FORMAT.NUMBER2;
  }
  if (Object.values(typeDate).includes(value)) {
    return DATE_FORMAT.DATE;
  }
  if (Object.values(typeDateTime).includes(value)) {
    return DATETIME_FORMAT.DATETIME;
  }
  if (Object.values(typeText).includes(value)) {
    return null;
  }
};

export const addDynamicColumnsWithStandardColumns = (
  dynamicCols: any,
  standardColumns: IColumnFields[]
) => {
  const totalLengthColumns = dynamicCols.length + standardColumns.length;
  dynamicCols.forEach((item: any) => {
    if (item.position === totalLengthColumns) {
      delete item.position;
      item.style = formatStyleUnRelevant;
      standardColumns.splice(totalLengthColumns, 0, item);
    } else {
      const index = item.position;
      delete item.position;
      standardColumns.splice(index - 1, 0, item);
    }
  });
  return standardColumns;
};

export const processDynamicColumns = (dynamicCols: DTO_DynamicColumns[]) => {
  return dynamicCols.map((item: DTO_DynamicColumns) => {
    const type = getDataType(item.DataType_ENUM);
    if (type === null) {
      return {
        field: item.FieldName,
        title: item.DisplayName,
        position: item.ColumnIndex,
      };
    }
    return {
      field: item.FieldName,
      title: item.DisplayName,
      position: item.ColumnIndex,
      format: type,
    };
  });
};
