import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  DTO_Journal_Transactions,
  DTO_Transaction,
  DTO_Transaction_LOVs,
} from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/model";
import { ResponseMessage } from "@app/products/property/model";

export const getDataJournalTransaction = async (
  journalId: number,
  transactionId: number
): Promise<APIResponse<DTO_Journal_Transactions | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Journal_Transactions>(
      `api/property/internal/journal/${journalId}/transactions/${transactionId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getJournalTransactionLOVs = async (
  transactionId: number
): Promise<APIResponse<DTO_Transaction_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Transaction_LOVs>(
      `api/property/internal/journal/lovs?transactionId=${transactionId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postTransaction = async (
  journalId: number,
  transactionId: number,
  transaction: DTO_Transaction
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `/api/property/internal/journal/${journalId}/transactions/${transactionId}/save`,
      transaction
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
