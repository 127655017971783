import {
  IAssessmentGroup,
  IAssessmentModel,
  IAssessmentQuestion,
} from "@app/products/ams/model/store";
import { action, configure, makeObservable, observable } from "mobx";
import {
  EditingQuestion,
  IEditingQuestion,
} from "../inspections-assessments-models/store";

configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class InspectionAssessmentUi {
  editingInspectionTemplate: IAssessmentModel | null = null;
  editingQuestions: IEditingQuestion[] = [];

  constructor() {
    makeObservable(this, {
      editingInspectionTemplate: observable,
      editingQuestions: observable,
      setEditingTemplate: action,
      toggleEditingQuestion: action,
    });
  }

  indexOf(edQuestion: IEditingQuestion) {
    return this.editingQuestions.findIndex(
      (o) =>
        o.groupName === edQuestion.groupName &&
        o.questionNo === edQuestion.questionNo
    );
  }

  isInDesignMode(edQuestion: IEditingQuestion): boolean {
    const indexOf = this.indexOf(edQuestion);
    return indexOf !== -1;
  }

  isAlternateQuestion(group: IAssessmentGroup, question: IAssessmentQuestion) {
    const indexOf = group.sortedQuestions.indexOf(question);
    let response = false;
    if (indexOf >= 0) {
      response = Math.abs(indexOf % 2) === 1; //if its odd numbered, its the alternate row.
    }

    return response;
  }

  setEditingTemplate(item: IAssessmentModel) {
    this.editingInspectionTemplate = item;
    this.editingQuestions = [];
  }

  toggleEditingQuestion(edQuestion: IEditingQuestion) {
    const indexOf = this.indexOf(edQuestion);
    if (indexOf !== -1) {
      this.editingQuestions.splice(indexOf, 1);
    } else {
      this.editingQuestions.push(new EditingQuestion(edQuestion));
    }
  }
}
