import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface ICaseDetailsProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

const nameOfIKeyValuePacket = nameOfFactory<IKeyValuePacket>();

export const CaseDetails = observer(
  ({ formRenderProps, isDisabled }: ICaseDetailsProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { svcStandardBatchUpdateLovs } = useSvcStandardBatchUpdateStore();

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update case details form" />
            <Field
              name={getNameOfSvcStandardBatchUpdate("Update_CaseDetailsForm")}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_CaseDetailsForm")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_CaseDetailsForm")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Case details form" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("CaseDetailsForm")}
                component={CCSearchComboBox}
                data={svcStandardBatchUpdateLovs?.CaseDetailType ?? []}
                textField={nameOfIKeyValuePacket("Value")}
                dataItemKey={nameOfIKeyValuePacket("Key")}
                value={getDropdownValue(
                  valueGetter(
                    getNameOfSvcStandardBatchUpdate("CaseDetailsForm")
                  ),
                  svcStandardBatchUpdateLovs?.CaseDetailType ?? [],
                  "Key"
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(getNameOfSvcStandardBatchUpdate("CaseDetailsForm"), {
                    value: event.target.value?.Key ?? null,
                  });
                }}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update button label" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_CaseDetailsButtonLabel"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_CaseDetailsButtonLabel")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_CaseDetailsButtonLabel")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Button label" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("CaseDetailsButtonLabel")}
                component={CCInput}
                disabled={isDisabled}
                placeholder="Button label"
              />
            </div>
          ) : null}
        </div>
      </section>
    );
  }
);
