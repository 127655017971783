import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingDropdown } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-dropdown/_index";
import { GlobalSettingSwitch } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-switch/_index";
import { GlobalSettingText } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/global-text-input/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <GlobalSettingText
          enumParam={ECorporateSettingsField.CorporateSettings_AdvertisingLabel}
        />
        <GlobalSettingText
          enumParam={
            ECorporateSettingsField.CorporateSettings_AdvertisingDueLabel
          }
        />
        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.Advertising_EnableSimpleAdvertising
          }
          formRenderProps={formRenderProps}
        />
        <GlobalSettingSwitch
          enumParam={ECorporateSettingsField.Advertising_EnableAdvertisingHints}
          formRenderProps={formRenderProps}
        />
        <GlobalSettingDropdown
          enumParam={
            ECorporateSettingsField.Advertising_AdvertisingDueCalculator
          }
        />
      </div>
    </section>
  );
};
