import { CORE_GET_ACTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { colOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/config";
import { IOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

const nameOfOrgUnit = nameOfFactory<IOrgUnit>();

export interface IPickOrgStructurePickerProps extends ICCInputPickerProps {
  onPickOrgStructure: (value: IOrgUnit | null, name: string) => void;
  adhocGroup: boolean | null;
  allowClear: boolean;
}
export const PickOrgStructurePicker = (props: IPickOrgStructurePickerProps) => {
  const {
    onChange,
    onError,
    onPickOrgStructure,
    adhocGroup,
    allowClear,
    name,
    ...others
  } = props;

  const handleOnChangeOfficer = (event: ICCInputPickerChangeEvent) => {
    const value: IOrgUnit | null = event.value;
    if (onPickOrgStructure) onPickOrgStructure(value, name ?? "");
  };

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "Pick Org Units",
        maxWidth: "75%",
      }}
      grid={{
        columnFields: colOrgUnit,
        primaryField: nameOfOrgUnit("ID"),
        selectableMode: "single",
        dataUrl: `${CORE_GET_ACTION_TYPES_ODATA}(isAdhoc=${adhocGroup}, orgLevel=null)?$count=true&`,
        isAutoHiddenPager: false,
        state: { sort: [{ field: nameOfOrgUnit("ID"), dir: "asc" }] },
        itemPerPage: 10,
      }}
      showClearButton={allowClear}
      onChange={handleOnChangeOfficer}
    />
  );
};
