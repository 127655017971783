import { ExistManageBuildingRegisterEnforcement } from "@app/products/building/registers/enforcements/[id]/components/forms/existed/_index";
import { NewManageBuildingRegisterEnforcement } from "@app/products/building/registers/enforcements/[id]/components/forms/new/_index";
import { useIsNew } from "@common/hooks/useIsNew";
import React from "react";

const ManageBuildingRegisterEnforcement = () => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewManageBuildingRegisterEnforcement />;
  }
  return <ExistManageBuildingRegisterEnforcement />;
};

export default ManageBuildingRegisterEnforcement;
