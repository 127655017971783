import { ModifyMeterDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/modify-meter/_index";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ModifyMeterButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { meter } = useMeterStore();

  return (
    <React.Fragment>
      <CCNavButton
        title={"Modify meter"}
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <ModifyMeterDialog
          meterInfo={meter}
          onClose={() => {
            setShowDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
});
