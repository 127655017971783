import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colAllFees: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ReferenceNumber,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ContactName,
    title: "Applicant Name",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.CreatedDate,
    title: "Recorded",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Type,
    title: "Type",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.FeeType,
    title: "Fee Type",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.AmountIssued,
    title: "Issued",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.AmountOutstanding,
    title: "Outstanding",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.CostCode,
    title: "Cost Code",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.MerchantReference,
    title: "Receipt Number",
    width: 250,
  },
];
