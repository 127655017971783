import { Application_Status } from "@app/products/town-planning/ppr/constant";

export const reptAndRespLetterForApprovalValidatorList: Application_Status[] = [
  Application_Status.AssessedByPlanner,
  Application_Status.PreliminaryAssessment,
  Application_Status.Validated,
  Application_Status.FurtherInfo,
  Application_Status.FurtherInfoReceived,
  Application_Status.FurtherInfoWaitingForApproval,
  Application_Status.FurtherInfoApproved,
  Application_Status.ReadyToSendForApproval,
  Application_Status.ReadyToSendForApproval,
  Application_Status.FurtherInfoReceived,
];
