import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const mockContacts: ICommonContactItemRender[] = [
  {
    ID: "3173054",
    Type: "Owner",
    DisplayName: "Hamish Martin",
    WorkPhone: "653-198",
    Mobile: "196-260-8553",
    PreferredMethod_ENUM: "0",
    Email: "hasmish@openoffice.com.au",
  },
  {
    ID: "3173058",
    Type: "Applicant",
    DisplayName: "Lena Stojanovski",
    WorkPhone: "(640) 254-957",
    Mobile: "1-540-062-1600",
    PreferredMethod_ENUM: "0",
    Email: "lena@openoffice.com.au",
  },
];
