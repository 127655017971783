import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  getNominatedProperties,
  getVotingEntitlements,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/voting/api";
import {
  nominatePropertyCol,
  votingEntitlementCol,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/voting/config";
import {
  DTO_NominatedProperty,
  DTO_VotingEntitlement_By_Election,
} from "@app/products/property/contacts-central-names/[id]/components/child-screens/voting/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
const nameOfNominatedPropertyGrid = nameOfFactory<DTO_NominatedProperty>();
const nameOfVotingEntitlementGrid =
  nameOfFactory<DTO_VotingEntitlement_By_Election>();

export const Voting = observer(() => {
  const { contactId } = useContactsCentralNamesStore();

  const [processedColNominateProperty, setProcessedColNominateProperty] =
    useState<IColumnFields[]>(nominatePropertyCol);
  const [processedColVotingEntitlement, setProcessedColVotingEntitlement] =
    useState<IColumnFields[]>(votingEntitlementCol);
  const [processedDataNominateProperty, setProcessedDataNominateProperty] =
    useState<DTO_NominatedProperty[]>([]);
  const [processedDataVotingEntitlement, setProcessedDataVotingEntitlement] =
    useState<DTO_VotingEntitlement_By_Election[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadViewConfigAndData = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await Promise.all([
      loadViewConfiguresColumns(
        ViewConfiguration.Entity_Nominated_Properties,
        nominatePropertyCol
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.Entity_Voting_Entitlements,
        votingEntitlementCol
      ),
      getNominatedProperties(contactId ?? 0),
      getVotingEntitlements(contactId ?? 0),
    ]);
    setIsLoading(false);
    if (Array.isArray(response)) {
      const [
        responseNominatedPropertiesConfig,
        responseVotingEntitlementsConfig,
        responseNominatedPropertiesData,
        responseVotingEntitlementsData,
      ] = response;
      const isAllAPICallsSuccess =
        Array.isArray(responseNominatedPropertiesConfig) &&
        Array.isArray(responseVotingEntitlementsConfig) &&
        isSuccessResponse(responseNominatedPropertiesData) &&
        isSuccessResponse(responseVotingEntitlementsData);

      if (isAllAPICallsSuccess) {
        setProcessedColNominateProperty(responseNominatedPropertiesConfig);
        setProcessedColVotingEntitlement(responseVotingEntitlementsConfig);
        setProcessedDataNominateProperty(
          responseNominatedPropertiesData.data?.Entity_Nominated_Properties ??
            []
        );
        setProcessedDataVotingEntitlement(
          responseVotingEntitlementsData.data?.Entity_VotingEntitlements_By_Election?.map(
            (item: DTO_VotingEntitlement_By_Election) => ({
              ...item,
              RowId: getUUID(),
            })
          ) ?? []
        );
      } else {
        let responseError =
          responseNominatedPropertiesConfig as APIResponseError;
        if (!Array.isArray(responseVotingEntitlementsConfig)) {
          responseError = responseVotingEntitlementsConfig as APIResponseError;
        } else if (!isSuccessResponse(responseNominatedPropertiesData)) {
          responseError = responseNominatedPropertiesData as APIResponseError;
        } else if (!isSuccessResponse(responseVotingEntitlementsData)) {
          responseError = responseVotingEntitlementsData as APIResponseError;
        }
        setResponseLoadError({
          status: responseError.status,
          error: responseError.error ?? "Load failed",
        });
      }
    } else {
      const responseError = response as APIResponse;
      setResponseLoadError({
        status: responseError.status,
        error: responseError.error ?? "Load failed",
      });
    }
  };

  useEffect(() => {
    loadViewConfigAndData();
    // eslint-disable-next-line
  }, [contactId]);

  if (isLoading) return <Loading isLoading />;

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfigAndData();
        }}
      />
    );

  return (
    <div className="cc-field-group">
      <div className="cc-field">
        <CCLabel title="Nominate property" />
        <CCGrid
          data={processedDataNominateProperty}
          columnFields={processedColNominateProperty}
          primaryField={nameOfNominatedPropertyGrid("Nominated_Property_Id")}
        />
      </div>
      <div className="cc-field">
        <CCLabel title="Voting entitlement by election" />
        <CCGrid
          data={processedDataVotingEntitlement}
          columnFields={processedColVotingEntitlement}
          primaryField={nameOfVotingEntitlementGrid("RowId")}
        />
      </div>
    </div>
  );
});
