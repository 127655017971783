import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_CommunicationQueueTabDetails } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/model";

export const getCommunicationQueueTabDetailsById = async (
  communicationQueueId: number
): Promise<APIResponse<Svc_CommunicationQueueTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_CommunicationQueueTabDetails>(
      `/api/core/internal/communicationqueue/${communicationQueueId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
