export const mockDataLevyCategory = [
  { Code: 1, Name: "Business" },
  { Code: 2, Name: "Comm. Facilities/Asset Infra." },
  { Code: 3, Name: "Environmental Levy" },
  { Code: 4, Name: "Garbage 1" },
  { Code: 5, Name: "Garbage 2" },
  { Code: 6, Name: "Garbage 3" },
  { Code: 7, Name: "Local Loan 1" },
  { Code: 8, Name: "Local Loan 2" },
  { Code: 9, Name: "Operational" },
  { Code: 10, Name: "Other Infrastructure" },
  { Code: 11, Name: "Residential" },
  { Code: 12, Name: "Road Infrastructure" },
  { Code: 13, Name: "State Fire Emergency Services Levy" },
];

export const mockDataLevy = [
  { Code: 1, Name: "Com. Add. 120l Waste" },
  { Code: 2, Name: "Com. Add. 240l Waste" },
  { Code: 3, Name: "Com. Extra Green-waste service 240L" },
  { Code: 4, Name: "Com. Extra Recycling Service" },
  { Code: 5, Name: "Com. Waste Charge (SBS only)" },
  { Code: 6, Name: "Com. Waste Charge 120l & SBS" },
  { Code: 7, Name: "Com. Waste Charge 240l & SBS " },
  { Code: 8, Name: "Dom. Availability Charge" },
  { Code: 9, Name: "Dom. Extra Green-waste service 240L" },
  { Code: 10, Name: "Dom. Extra Recycling Service" },
  { Code: 11, Name: "Dom. Waste 120l" },
  { Code: 12, Name: "Dom. Waste 120l 2 Shared" },
  { Code: 13, Name: "Dom. Waste 240l" },
  { Code: 14, Name: "Dom. Waste 240l 2 Shared" },
  { Code: 15, Name: "Dom. Waste 240l 3 Shared" },
  { Code: 16, Name: "Dom. Waste 80l" },
];
