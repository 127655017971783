import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IKeyValuePacket>();
export const colAgentSettings: IColumnFields[] = [
  { field: nameOf("Value"), title: "Name", width: "800" },
];

export const defaultStyleGrid = {
  maxHeight: 600,
  height: 600,
};
