import { FIRE_PREVENTION_ROUTE } from "@app/products/property/fire-prevention/hazld-case/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colNotices: IColumnFields[] = [
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${FIRE_PREVENTION_ROUTE}/` + dataItem.CaseId;
    },
    locked: true,
  },
  {
    field: "NoticeId",
    title: "Notice ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "NoticeType", title: "Notice Type" },
  { field: "SentOn", title: "Sent On", format: DATETIME_FORMAT.DATETIME },
  { field: "SentBy", title: "Sent By" },
  { field: "MailDate", title: "Mail Date", format: DATE_FORMAT.DATE },
  { field: "NoticeReference", title: "Notice Reference" },
  { field: "Owners", title: "Owners" },
  { field: "Clearance", title: "Clearance" },
  { field: "Outcome", title: "Outcome" },
  {
    field: "ToBeClearedBy",
    title: "To Be Cleared By",
    format: DATE_FORMAT.DATE,
  },
  { field: "SpecificInstructions", title: "Specific Instructions" },
  { field: "Locality", title: "Locality" },
  {
    field: "HouseNumber",
    title: "House Number",
  },
  { field: "Street", title: "Street" },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "InspectionDate",
    title: "Inspection Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  { field: "Period", title: "Period" },
  { field: "InspectorUserId", title: "Inspector User ID" },
  {
    field: "ParcelId",
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
