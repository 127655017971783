import { AnimalType } from "@app/products/animals/model";
import {
  RegistrationFeeRulesType,
  SVC_FeeCalculator_NonRegistrationFee_RuleType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType,
  SVC_FeeCalculator_RegistrationFee_RuleType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { ICCCheckboxGroupItem } from "@components/cc-checkbox-group/model";

export interface SVC_AnimalFeeParams {
  Sterilized: boolean;
  Age: number;
  ClubMember: boolean;
  Microchipped: boolean;
  Obedience: boolean;
  GuideDog: boolean;
  Customs: boolean;
  Working: boolean;
  Organisation: boolean;
  PureBred: boolean;
  Greyhound: boolean;
  DogsHomeAdoption: boolean;
  Pensioner: boolean;
  HealthCareCard: boolean;
  Dangerous: boolean;
  Menacing: boolean;
  NewTag: boolean;
  ReplacementTag: boolean;
  LifeTimeTag: boolean;
  CalculationDate: Date;
  HuntingDog: boolean;
  Transferred: boolean;
  IsNewRegistration: boolean;
  GuardDog: boolean;
  CertifiedAssistance: boolean;
  RestrictedBreed: boolean;
}

export interface Svc_CalculateRegistrationFee {
  FeeParams: SVC_AnimalFeeParams;
  ObjAnimalType: AnimalType;
  AnimalDOB: Date | null;
  CalculationDate: Date | null;
}

export interface SVC_AnimalRegistrationFeeAdditionalChargeRule {
  AdditionalChargeRuleType_ENUM: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType;
  AdditionalChargeRuleType_Name: string;
  Amount: number;
  AdjustmentType_ENUM: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType;
  AdjustmentType_Name: string;
}

export interface SVC_AnimalFeeComponent {
  Net: number;
  GST: number;
  Gross: number;
}

export interface SVC_AnimalFeeDetails {
  AppliedRegistrationFeeRule_ENUM: SVC_FeeCalculator_RegistrationFee_RuleType | null;
  AppliedRegistrationFeeRule_Name: string;
  SelectedRegistrationFeeAdjustmentRules: SVC_AnimalRegistrationFeeAdditionalChargeRule[];
  RegistrationFee_BaseAmount: number;
  RegistrationFee_AfterAdjustment1_Amount: number;
  RegistrationFee_AfterAdjustment2_Amount: number;
  RegistrationFee_AfterAdjustments_BaseFees: number;
  NetAmount: number;
  TotalAmount: number;
  GSTComponent: number;
  Fees: SVC_AnimalFeeComponent[];
  CalculationSteps: string[];
  _CalculationSteps: string[];
}

export enum SVC_FeeCalculator_ResultStatus {
  Default,
  Success,
  HasError,
  NoMatchingFee,
}

export interface SVC_AnimalFeeResult {
  NetAmount: number;
  TotalAmount: number;
  GSTComponent: number;
  FeeType_Name: string;
  FeeType_ID: number;
  FeeType_Code: string;
  FeeDetails: SVC_AnimalFeeDetails;
  Fees: SVC_AnimalFeeComponent[];
  Status_ENUM: SVC_FeeCalculator_ResultStatus;
  Status_Name: string;
  ErrorMessage: string;
}

export interface TestFees {
  FeeRulesType:
    | RegistrationFeeRulesType.RegistrationFeeRules
    | RegistrationFeeRulesType.NonRegistrationFeeRules;
  Parameters: ICCCheckboxGroupItem[];
  NewRegistration: ICCCheckboxGroupItem[];
  AnimalDOB: Date | null;
  CalculationDate: Date | null;
  NonRegistrationFeeRuleType: SVC_FeeCalculator_NonRegistrationFee_RuleType;
  CalculationSteps: string[];
  FeeType_Name: string;
  TotalAmount: number | null;
  Status_ENUM: SVC_FeeCalculator_ResultStatus;
}

export enum ParameterTypes {
  Sterilized = "Sterilized",
  ClubMember = "Club member",
  Microchipped = "Microchipped",
  Obedience = "Obedience",
  GuideDog = "Guide dog",
  Customs = "Customs",
  Working = "Working (farm)",
  Organisation = "Organisation",
  Menacing = "Menacing (guard)",
  PureBred = "Pure bred",
  Greyhound = "Greyhound",
  DogsHomeAdoption = "Dogs home adoption",
  Pensioner = "Pensioner",
  HealthCareCard = "Health care card",
  Dangerous = "Dangerous",
  NewTag = "New tag",
  ReplacementTag = "Replacement tag",
  HuntingDog = "Hunting dog",
  Transferred = "Transferred",
  RestrictedBreed = "Restricted breed",
}

export enum NewRegistration {
  IsNewRegistration = "Is new registration",
  CertifiedAssistance = "Certified assistance",
}

export interface TestFeesMapObj {
  TestFees: TestFees;
}
