import {
  DTO_DebtRecoveryExemption,
  DTO_DebtRecovery_Ext,
  DebtRecoveryViews,
  IMappingDebtRecoveryView,
} from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import { ASSESSMENTS_DEBT_RECOVERY_ROUTE } from "@app/products/property/assessments/debt-recovery/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_DebtRecovery_Ext>();
const nameOfDebtRecoveryExemption = nameOfFactory<DTO_DebtRecoveryExemption>();
export const colDebtRecovery: IColumnFields[] = [
  {
    field: nameOf("Debt_RecoveryId"),
    title: "Debt Recovery ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: DTO_DebtRecovery_Ext) =>
      `${ASSESSMENTS_DEBT_RECOVERY_ROUTE}/${dataItem.Debt_RecoveryId}`,
  },
  { field: nameOf("NewNameAndAddress"), title: "Name and Address" },
  { field: nameOf("Assessments"), title: "Assessment(s)" },
  { field: nameOf("Type"), title: "Type" },
  { field: nameOf("Status"), title: "Status" },
  {
    field: nameOf("DateFrom"),
    title: "Date From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateTo"),
    title: "Date To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InitialBalance"),
    title: "Initial Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialInterestBalance"),
    title: "Initial Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InterestBalance"),
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CostsIncurred"),
    title: "Costs Incurred",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialDebt"),
    title: "Initial Debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialDebtIncludingCosts"),
    title: "Initial Debt Including Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total"),
    title: "Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("AssessmentBalance"),
    title: "Assessment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("UpToDueDateTime"),
    title: "Up to Due Date Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("AsAt"),
    title: "As At",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("AlertMessage"),
    title: "Alert Message",
  },
  {
    field: nameOf("ShowAlertOnAssessment"),
    title: "Show Alert on Assessment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
];

export const colDebtRecoveryExemptions: IColumnFields[] = [
  {
    field: nameOfDebtRecoveryExemption("NameAndAddressName"),
    title: "Name",
  },
  {
    field: nameOfDebtRecoveryExemption("Type"),
    title: "Type",
  },
  {
    field: nameOfDebtRecoveryExemption("Reason"),
    title: "Reason",
  },
  {
    field: nameOfDebtRecoveryExemption("ActiveFrom"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfDebtRecoveryExemption("ActiveTo"),
    title: "Active To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfDebtRecoveryExemption("AssessmentNumberFormatted"),
    title: "Assessment Number Formatted",
  },
  {
    field: nameOfDebtRecoveryExemption("Reference"),
    title: "Reference",
  },
  {
    field: nameOfDebtRecoveryExemption("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOfDebtRecoveryExemption("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfDebtRecoveryExemption("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfDebtRecoveryExemption("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOfDebtRecoveryExemption("CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfDebtRecoveryExemption("ModifiedBy"),
    title: "Modified By",
  },
  {
    field: nameOfDebtRecoveryExemption("ModifiedOn"),
    title: "Modified On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfDebtRecoveryExemption("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfDebtRecoveryExemption("DebtRecovery_ExemptionId"),
    title: "Debt Recovery Exemption ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const mappingDebtRecoveryView: IMappingDebtRecoveryView = {
  All: DebtRecoveryViews.All,
  Ratepayer: DebtRecoveryViews.Ratepayer,
  Assessment: DebtRecoveryViews.Assessment,
};
