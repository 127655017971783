import { ENTITY_STREET_LOCALITY_API } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/constant";
import { DTO_Entity_AddressDetail } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

interface IGetEntityStreetLocalityProps {
  locality?: string | null;
  streetName?: string;
  postcodeId?: number;
}
export const getEntityStreetLocality = async ({
  locality,
  streetName,
  postcodeId,
}: IGetEntityStreetLocalityProps): Promise<
  APIResponse<DTO_Entity_AddressDetail | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Entity_AddressDetail>(
      ENTITY_STREET_LOCALITY_API,
      {
        params: {
          locality,
          streetName,
          Postcode_Id: postcodeId,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
