import { SEPARATOR_CHARACTER } from "@common/constants/characters";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IOptionsTable } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/components/table/model";
import {
  convertToDataOrigin,
  getLabelTable,
  transformDataFieldsMappingTable,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface ITableFields {
  optionsTable: IOptionsTable;
  formRenderProps: FormRenderProps;
}

export const TableFields = observer(
  ({ optionsTable, formRenderProps }: ITableFields) => {
    const { dataSetting } = useSettingGlobalStore();
    const dataResponse = dataSetting?.SettingFields;

    const enumList: string[] = useMemo(() => {
      return [
        optionsTable.fieldNameActiveProductList,
        optionsTable.fieldNameTable,
      ];
    }, [optionsTable]);

    const data = useMemo(() => {
      const data: any = {};
      enumList.forEach((fieldName: any) => {
        data[fieldName] = filter(dataResponse, {
          FieldName: fieldName,
        })[0];
      });
      return data;
    }, [enumList, dataResponse]);

    const { valueGetter, onChange } = formRenderProps;
    const { isEditing } = useSettingGlobalManageStore();

    const dataEnableProducts =
      data[optionsTable.fieldNameActiveProductList].ValueDisplayDetails;

    const labelTable = data[optionsTable.fieldNameTable].Title;
    const dataFields = valueGetter(optionsTable.fieldNameTable);
    const dataTable = transformDataFieldsMappingTable(
      dataFields,
      dataEnableProducts
    );
    const listTitle = valueGetter(optionsTable.fieldNameListTitleTable).split(
      SEPARATOR_CHARACTER
    );

    return (
      <div className="cc-journal-fields-mapping">
        <label className="cc-label">{labelTable}</label>
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              {listTitle.map((label: string) => (
                <th>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTable &&
              dataTable?.map((item: string[], i: number) =>
                item?.[0] ? (
                  <tr>
                    <td>
                      <b>{getLabelTable(item?.[0], dataEnableProducts)}</b>
                    </td>
                    <td>
                      <Field
                        name={`${item?.[0]}_1`}
                        component={CCNumericTextBox}
                        disabled={!isEditing}
                        onChange={(e: any) => {
                          dataTable[i][1] = e.value;
                          onChange(optionsTable.fieldNameTable, {
                            value: convertToDataOrigin(dataTable),
                          });
                          onChange(`${item?.[0]}_1`, {
                            value: e.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Field
                        name={`${item?.[0]}_2`}
                        component={CCNumericTextBox}
                        disabled={!isEditing}
                        onChange={(e: any) => {
                          dataTable[i][2] = e.value;
                          onChange(optionsTable.fieldNameTable, {
                            value: convertToDataOrigin(dataTable),
                          });
                          onChange(`${item?.[0]}_2`, {
                            value: e.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                ) : null
              )}
          </tbody>
        </table>
      </div>
    );
  }
);
