import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { VO_Officer } from "@common/models/officer";
import { globalStoreInstance } from "@common/stores/global/store";
import { getOfficerSearch } from "@common/stores/responsible-officer/api";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { isArray } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction
class ResponsibleOfficerStore {
  private _responseLoadError?: APIResponseError = undefined;
  private _isLoading: boolean = false;
  private _responsibleOfficer?: VO_Officer = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responsibleOfficer() {
    return toJS(this._responsibleOfficer);
  }
  setResponsibleOfficer = (responsibleOfficer?: VO_Officer) => {
    runInAction(() => {
      this._responsibleOfficer = responsibleOfficer;
    });
  };

  getDataOfficer = async (valueSearch: string | undefined) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    const filterParam: CompositeFilterDescriptor = {
      logic: "and",
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "FullName",
              operator: "contains",
              value: valueSearch,
            },
          ],
        },
      ],
    };

    const response = await getOfficerSearch(filterParam);
    this.setIsLoading(false);
    if (
      isSuccessResponse(response) &&
      response?.data?.value &&
      isArray(response?.data?.value)
    ) {
      this.setResponsibleOfficer(response.data.value[0] || null);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
  };

  reloadResponsibleOfficer = async () => {
    const currentUserName: string =
      globalStoreInstance.currentUserInfo?.userDisplayName ?? "";
    await this.getDataOfficer(currentUserName);
  };

  resetStore = () => {
    this._responseLoadError = undefined;
    this._responsibleOfficer = undefined;
  };
}
export const responsibleOfficerInstance = new ResponsibleOfficerStore();
const responsibleOfficerStoreContext = createContext(
  responsibleOfficerInstance
);
export const useResponsibleOfficerStore = () => {
  return useContext(responsibleOfficerStoreContext);
};
