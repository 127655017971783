import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderTopSwitchApplicationWorkflowListEnums } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_workflow/model";
import { ApplicationSettingSearchComboBox } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-search-combobox/_index";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { ApplicationSettingText } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-text-input/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { IActionType } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-action-type-referral/model";
import { PickActionTypeReferralPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-action-type-referral/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const ApplicationWorkflowForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const isShowManagerInternalReferral =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_EnableManagerApproval.toString()
      ) ?? false;
    const isShowConflictAdviseMessage =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_EnableConflictofinterestDialogBox.toString()
      ) ?? false;
    const isShowRefWorkflowPosition =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_EnableReferral_On_Workflow_Bar.toString()
      ) ?? false;

    const actionTypeReferralDisplayValue =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_ManagerInternalReferral}`
      )[0] ?? [];

    if (!configData) return <></>;

    const handleOnChangeActionTypeReferral = (selectedValue: any) => {
      formRenderProps?.onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_ManagerInternalReferral}`,
        {
          value: [selectedValue],
        }
      );
      formRenderProps?.onChange(
        configData[
          ECorporateSettingsField.TP_ManagerInternalReferral.toString()
        ].FieldName ?? "",
        {
          value: selectedValue?.ID,
        }
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_DisableWorkflowForOSFees}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_IssuePermitWithOSfees}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_Application_LockEditingOnCompletedApplication
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_RestrictAmendments}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_EnableManagerApproval}
            formRenderProps={formRenderProps}
          />
          {isShowManagerInternalReferral &&
            configData[ECorporateSettingsField.TP_ManagerInternalReferral] && (
              <div className="cc-field">
                <CCLabel
                  title={
                    configData[
                      ECorporateSettingsField.TP_ManagerInternalReferral
                    ].Title
                  }
                  isMandatory={
                    configData[
                      ECorporateSettingsField.TP_ManagerInternalReferral
                    ].IsMandatory
                  }
                />
                <Field
                  name={
                    configData[
                      ECorporateSettingsField.TP_ManagerInternalReferral
                    ].FieldName
                  }
                  component={PickActionTypeReferralPicker}
                  value={actionTypeReferralDisplayValue}
                  textProduce={(value: IActionType) => value.Name}
                  allowClear={true}
                  selectMode="single"
                  disabled={!isEditing}
                  onPickActionTypeReferral={handleOnChangeActionTypeReferral}
                  validator={
                    configData[
                      ECorporateSettingsField.TP_ManagerInternalReferral
                    ].IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            )}
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_EnableConflictofinterestDialogBox
            }
            formRenderProps={formRenderProps}
          />

          {isShowConflictAdviseMessage && (
            <ApplicationSettingText
              enumParam={ECorporateSettingsField.TP_ConflictAdvise}
            />
          )}
          {renderTopSwitchApplicationWorkflowListEnums.map(
            (enumParam: ECorporateSettingsField) => {
              return (
                <ApplicationSettingSwitch
                  enumParam={enumParam}
                  formRenderProps={formRenderProps}
                  key={enumParam}
                />
              );
            }
          )}
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_EnableReferral_On_Workflow_Bar
            }
            formRenderProps={formRenderProps}
          />
          {isShowRefWorkflowPosition && (
            <ApplicationSettingSearchComboBox
              enumParam={ECorporateSettingsField.TP_ReferralWorkflow_Position}
            />
          )}
        </div>
      </section>
    );
  }
);
