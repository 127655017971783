import { mockSingleBuildingDemolition } from "@app/products/building/registers/demolitions/[id]/mock";
import { IBuildingDemolitionFormData } from "@app/products/building/registers/demolitions/[id]/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getBuildingDemolitionById = async (
  id: number | string
): Promise<IBuildingDemolitionFormData> => {
  await sleep(1000);
  const response = cloneDeep(mockSingleBuildingDemolition);
  return response;
};
