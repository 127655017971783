import { useIsNew } from "@common/hooks/useIsNew";
import React from "react";
import { ExistedBuildingRegisterDemolition } from "./components/forms/existed/_index";
import { NewBuildingRegisterDemolition } from "./components/forms/new/_index";

const ManageBuildingRegisterDemolition = () => {
  const isNew = useIsNew();

  if (isNew) {
    return <NewBuildingRegisterDemolition />;
  }

  return <ExistedBuildingRegisterDemolition />;
};

export default ManageBuildingRegisterDemolition;
