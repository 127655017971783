import EnterReadingsDialog from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const EnterReadingsButton = observer(() => {
  const [showEnterReadingsDialog, setShowEnterReadingsDialog] = useState(false);

  return (
    <React.Fragment>
      <CCNavButton
        title="Enter readings"
        onClick={() => {
          setShowEnterReadingsDialog(true);
        }}
      />

      {showEnterReadingsDialog && (
        <EnterReadingsDialog
          onEnter={() => {
            setShowEnterReadingsDialog(false);
            setTimeout(() => {
              setShowEnterReadingsDialog(true);
            }, 500);
          }}
          onEnterAndExit={() => {
            setShowEnterReadingsDialog(false);
          }}
          onClose={() => {
            setShowEnterReadingsDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
});
