import { mockEventManagementsAll } from "@app/products/event-management/mock";
import { IResponseData } from "@common/models/service";
import { Toast } from "@components/toast";

export const getEventManagementRecords = async (
  id: number,
  recordType: number
) => {
  try {
    const response: IResponseData = JSON.parse(
      JSON.stringify(mockEventManagementsAll)
    );

    return response.data;
  } catch (error) {
    Toast.displayToast({ content: "🚀 Load Event Fail!", type: "error" });
    console.error("Load Event Fail!", error);
  }
};
