import { colParcel } from "@app/products/property/registers/list/components/action-bar/form-steps/create-register/components/parcels/config";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isEqual } from "lodash";
import React, { useRef } from "react";
import { loadSearchParcel } from "./api";
import { IParcels, SearchType, textFieldMapping } from "./model";

const searchTypeData = Object.values(SearchType);
const validator = (values: any) => {
  if (!values["Parcels"] || !values["Parcels"].length)
    return "Please complete form!";
  return undefined;
};
const nameOfParcel = nameOfFactory<IParcels>();
export const CREATE_REGISTER_PARCEL_FORM_STEP = "Parcels";
export const ParcelFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validator}
    />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const searchTypeValue = getFieldValue("SearchType");
  const selectedParcel = getFieldValue("ParcelsSelected");
  const currentParcel = getFieldValue("Parcels");
  const handleSearchParcel = (event: ComboBoxFilterChangeEvent) => {
    let numLetters = 1;

    if (event.filter.value.length >= numLetters) {
      onChange(nameOf("_option.SearchParcel.Loading"), { value: true });
      loadSearchParcel(event.filter).then((data) => {
        onChange(nameOf("_option.SearchParcel.Data"), { value: data });
        onChange(nameOf("_option.SearchParcel.Loading"), { value: false });
      });
    } else {
      onChange(nameOf("_option.SearchParcel.Data"), { value: [] });
    }
  };
  const handleSearchParcelResult = (event: ComboBoxChangeEvent) => {
    onChange(nameOf("SearchParcel"), { value: null });
    if (!event.value) return;

    let newParcel = [...getFieldValue("Parcels")];

    if (newParcel) {
      if (checkExistedRegister(event.value.ParcelId, newParcel)) {
        notificationRef.current?.pushNotification({
          title: "Register parcels has already existed",
          type: "warning",
        });
        return;
      }
      newParcel.unshift({
        Address: event.value.Address,
        Name: event.value.Name,
        LegalDescription: event.value.LegalDescription,
        ParcelId: event.value.ParcelId,
        ParcelReference: event.value.ParcelReference,
      });
      onChange(nameOf("Parcels"), { value: newParcel });
    }
  };

  const checkExistedRegister = (ParcelId: number, ListParcels: any[]) => {
    return ListParcels.some((item) =>
      isEqual(parseInt(item?.ParcelId), ParcelId)
    );
  };

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    let newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
  };

  const handleRemoveParcel = (parcel: any) => {
    if (parcel) {
      let newParcel = currentParcel.filter(
        (item: any) => item.ParcelId !== parcel.ParcelId
      );
      if (newParcel[0]) {
        newParcel[0].selected = true;
        handleGridSelectionChange([newParcel[0]], "ParcelsSelected");
      } else {
        handleGridSelectionChange([], "ParcelsSelected");
      }
      onChange(nameOf("Parcels"), { value: newParcel });
    }
  };

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Search by</label>
            <Field
              name={nameOf("SearchType")}
              data={searchTypeData}
              component={DropDownList}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Search value</label>
            <Field
              name={nameOf("SearchParcel")}
              filterable
              textField={textFieldMapping[searchTypeValue]}
              suggest
              onFilterChange={handleSearchParcel}
              data={getFieldValue("_option.SearchParcel.Data")}
              loading={getFieldValue("_option.SearchParcel.Loading")}
              placeholder={`Type ${searchTypeValue}`}
              component={CCComboBox}
              onChange={handleSearchParcelResult}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label"></label>
            <CCGrid
              className="cc-register-create-register"
              data={getFieldValue("Parcels") || []}
              selectedRows={selectedParcel ? [selectedParcel] : []}
              columnFields={colParcel}
              primaryField={nameOfParcel("ParcelId")}
              selectableMode="single"
              isLimitDetailWidth
              onSelectionChange={(dataItem) => {
                handleGridSelectionChange(dataItem, "ParcelsSelected");
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Parcel"
                    disabled={!selectedParcel}
                    onClick={() => handleRemoveParcel(selectedParcel)}
                  />
                </div>
              }
            />
          </div>
        </div>
      </section>
    </>
  );
};
