import { DTO_Entity_Associated_Entity } from "@app/products/property/contacts-central-names/list/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Entity_Associated_Entity>();
export const colNames: IColumnFields[] = [
  { field: nameOf("Name"), title: "Name" },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    href: (dataItem: DTO_Entity_Associated_Entity) => ({
      href: `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
      target: "_blank",
    }),
  },
  { field: nameOf("Address"), title: "Address" },
  {
    field: nameOf("Entity_Name_Address_Id"),
    title: "Contact Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
