import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { NoData } from "@components/no-data/NoData";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  //store
  const { getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();

  //props
  const { valueGetter } = formRenderProps;

  const fieldSSLOffLoad = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_Security_SSLOffLoad
  );

  if (!fieldSSLOffLoad) return <NoData vertical />;
  return (
    <div className="cc-sll-settings">
      <div className="cc-form-cols-3">
        {fieldSSLOffLoad && (
          <div className="cc-field">
            <CCLabel
              title={fieldSSLOffLoad?.Title ?? ""}
              isMandatory={fieldSSLOffLoad?.IsMandatory}
            />
            <Field
              name={fieldSSLOffLoad?.FieldName}
              checked={valueGetter(fieldSSLOffLoad?.FieldName)}
              component={CCSwitch}
              disabled={!isEditing}
            />
          </div>
        )}
      </div>
    </div>
  );
});
