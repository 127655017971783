import { PROPERTY_ENTITY_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colRebateEntitlementExceptions: IColumnFields[] = [
  {
    field: "ENA_Name",
    title: "Contact Name",
    locked: true,
    linkTo: (dataItem) => ({
      pathname: `${PROPERTY_ENTITY_ROUTE}/` + dataItem?.EntityId,
      state: { isExpandedRebate: true },
    }),
  },
  { field: "ENA_Address", title: "Contact Address" },
  {
    field: "RebateType",
    title: "Rebate Type",
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "ExceptionMessage", title: "Exception Message" },
  { field: "ExceptionType", title: "Exception Type" },
  {
    field: "LastRebateClaimId",
    title: "Last Rebate Claim ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "EntityId",
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "RebateEntitlementId",
    title: "Rebate Entitlement ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
