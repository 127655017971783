import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IGoTrim {
  formRenderProps: FormRenderProps;
}
export const GoTrim = observer(({ formRenderProps }: IGoTrim) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const { valueGetter } = formRenderProps;

  const fieldEnableGoTRIM = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_EnableTRIMWebLink
  );
  const fieldGoTrimUrl = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_TRIMWebLinkUrl
  );

  return (
    <>
      {fieldEnableGoTRIM && (
        <div className="cc-form-cols-1">
          <SwitchGlobalSettings
            data={fieldEnableGoTRIM}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}

      {fieldGoTrimUrl && valueGetter(fieldEnableGoTRIM?.FieldName ?? "") && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings data={fieldGoTrimUrl} isEditing={isEditing} />
        </div>
      )}
    </>
  );
});
