import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import { loadNominatedProperty } from "./api";
import { colNominatedProperty } from "./config";

interface ISelectNominatePropertyDialog {
  onClose: () => void;
  onSubmit: (dataItem: any) => void;
}

export const SelectNominatePropertyDialog = (
  props: ISelectNominatePropertyDialog
) => {
  const [selectedNominateProperty, setSelectedNominateProperty] = useState<
    any[]
  >([]);
  const [loadingNominateProperty, setLoadingNominateProperty] =
    useState<boolean>(false);
  const [dataNominateProperty, setDataNominateProperty] = useState<any[]>([]);

  const handleLoadNominateProperty = useCallback(() => {
    setLoadingNominateProperty(true);
    loadNominatedProperty().then((data: any) => {
      setTimeout(() => {
        setLoadingNominateProperty(false);
        setDataNominateProperty(data);
      }, 500);
    });
  }, []);

  useEffectOnce(() => {
    handleLoadNominateProperty();
  });

  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={"Select Nominate Property"}
            onClose={props.onClose}
            maxWidth="50%"
            maxHeight="50%"
            bodyElement={
              <div className="cc-form">
                <FormElement
                  onSubmit={formRenderProps.onSubmit}
                  className="cc-field-group"
                >
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel
                        title="The following are the properties the voter is entitled
                        to vote on behalf"
                      />
                      <CCGrid
                        isLoading={loadingNominateProperty}
                        data={dataNominateProperty}
                        columnFields={colNominatedProperty}
                        primaryField="AssessmentId"
                        selectableMode="single"
                        selectedRows={selectedNominateProperty}
                        onSelectionChange={(dataItem: any) => {
                          setSelectedNominateProperty([...dataItem]);
                        }}
                      />
                    </div>
                  </div>
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={() => {
                    props.onSubmit(selectedNominateProperty);
                  }}
                  type={"submit"}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
