import { getWasteWaterSummaryLite } from "@app/products/waste-water/[id]/components/reference-sidebar/details/api";
import { WasteWaterSummaryLite } from "@app/products/waste-water/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo, splitCamelCase } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const WasteWaterDetailsTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState(false);
  const [wasteWaterDetailInfo, setWasteWaterDetailInfo] = useState<
    WasteWaterSummaryLite | undefined
  >();
  const params: { id: string } = useParams();
  const wasteWaterId = lastSelectedRow?.ID || params.id;

  useEffect(() => {
    setIsLoading(true);
    if (wasteWaterId) {
      getWasteWaterSummaryLite(wasteWaterId).then(
        (response: WasteWaterSummaryLite | undefined) => {
          setWasteWaterDetailInfo(response);
          setIsLoading(false);
        }
      );
    }
  }, [wasteWaterId]);

  if (isLoading) return <Loading isLoading />;
  if (!wasteWaterDetailInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Permit Number:"}
        value={wasteWaterDetailInfo?.PermitNumber}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Status:"}
          value={splitCamelCase(wasteWaterDetailInfo?.Status.Value || "")}
          success
        />
        <ProductReferenceRow
          title={"File Number:"}
          value={wasteWaterDetailInfo?.FileNumber}
        />
        <ProductReferenceRow
          title={"Site Address:"}
          value={wasteWaterDetailInfo?.SiteAddress}
        />
        <ProductReferenceRow
          title={"Contact:"}
          value={wasteWaterDetailInfo?.Contact}
        />
        <ProductReferenceRow
          title={"Allotment Size:"}
          value={wasteWaterDetailInfo?.AllotmentSize}
        />
        <ProductReferenceRow
          title={"Received:"}
          value={formatDateByKendo(
            wasteWaterDetailInfo?.Received,
            DATE_FORMAT.DATE
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
