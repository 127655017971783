import { isSuccessResponse } from "@common/apis/util";
import { postSharePointVerify } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/components/button/share-point-test/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
interface IRecordsSharePointVerify {
  isEditing: boolean;
}
export const SharePointVerifyButton = observer(
  ({ isEditing }: IRecordsSharePointVerify) => {
    const [isLoading, setIsLoading] = useState(false);
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();

    const handleSubmit = async () => {
      setIsLoading(true);
      const response = await postSharePointVerify();
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        clearNotifications();
        pushNotification({
          title: `Verify successflly`,
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          description: response.data?.Errors,
          type: "error",
        });
      }
    };

    return (
      <>
        <Button
          disabled={isLoading || !isEditing}
          onClick={handleSubmit}
          className={"cc-dialog-button"}
          type={"button"}
          iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
        >
          Test connection
        </Button>
      </>
    );
  }
);
