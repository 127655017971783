import { nameOfLov } from "@app/products/property/model";
import { loadSelectSchemesToInclude } from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/api";
import { colSelectSchemesToInclude } from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/config";
import {
  mockCreateNoticeRun,
  mockNoticesToBeOrdered,
} from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/mock";
import {
  ICreateNoticeRun,
  ISelectSchemesToInclude,
} from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/model";
import {
  handleValidateForm,
  requiredValidatorDatePickerField,
} from "@app/products/property/schemes/notice-runs/components/action-bar/dialogs/create-notice-run/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import Loading from "@components/loading/Loading";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
const nameOf = nameOfFactory<ICreateNoticeRun>();

interface ICreateNoticeRunDialog {
  onClose: () => void;
  onSubmit?: () => void;
}
export interface IDataGridDialog {
  SessionNumber: number;
  Name: string;
}

export const CreateNoticeRunDialog = ({
  onClose,
  onSubmit,
}: ICreateNoticeRunDialog) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ISelectSchemesToInclude[] | undefined>(
    undefined
  );
  useEffect(() => {
    setIsLoading(true);
    loadSelectSchemesToInclude().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <Form
      initialValues={mockCreateNoticeRun}
      validator={handleValidateForm}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <div className="cc-create-notice-run">
            <CCDialog
              height="auto"
              maxWidth="50%"
              titleHeader={"New Scheme Notice Run"}
              onClose={onClose}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <div className="cc-form">
                    <section className="cc-field-group">
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">Notice run name</label>
                          <Field
                            name={nameOf("NoticeRunName")}
                            component={CCInput}
                            placeholder="Notice run name"
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Issue date</label>
                          <Field
                            name={nameOf("IssueDate")}
                            format={DATE_FORMAT.DATE_CONTROL}
                            component={CCDatePicker}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Due date</label>
                          <Field
                            name={nameOf("DueDate")}
                            format={DATE_FORMAT.DATE_CONTROL}
                            component={CCDatePicker}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Apply an overriding due date to amounts owing in
                            this run
                          </label>
                          <Field
                            name={nameOf(
                              "ApplyAnOverridingDueDateToAmountsOwingInThisRun"
                            )}
                            component={CCSwitch}
                          />
                        </div>
                      </div>
                      <section>
                        <label className="cc-label">Selection criteria</label>
                        <div className="cc-custom-sub-panel-bar">
                          <section className="cc-field-group">
                            <div className="cc-form-cols-3">
                              <div className="cc-field cc-col-span-3">
                                <CCGrid
                                  data={data}
                                  columnFields={colSelectSchemesToInclude}
                                  primaryField="ID"
                                  selectableMode="multiple"
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Reporting period start
                                  <TooltipValidator
                                    content={
                                      "Period Start must be less than Period End"
                                    }
                                  />
                                </label>
                                <Field
                                  name={nameOf("Start")}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  component={CCDatePicker}
                                  validator={requiredValidatorDatePickerField}
                                  max={
                                    new Date(formRenderProps.valueGetter("End"))
                                  }
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Reporting period end
                                  <TooltipValidator
                                    content={
                                      "Period End must be greater than Period Start"
                                    }
                                  />
                                </label>
                                <Field
                                  name={nameOf("End")}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  component={CCDatePicker}
                                  validator={requiredValidatorDatePickerField}
                                  min={
                                    new Date(
                                      formRenderProps.valueGetter("Start")
                                    )
                                  }
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">
                                  Minimum amount due
                                </label>
                                <Field
                                  name={nameOf("MinimumAmountDue")}
                                  component={CCCurrencyInput}
                                />
                              </div>
                            </div>
                            <section>
                              <label className="cc-label">
                                Include scheme accounts which:
                              </label>
                              <div className="cc-custom-sub-panel-bar">
                                <section className="cc-field-group">
                                  <div className="cc-form-cols-3">
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Have no transactions since last notice
                                      </label>
                                      <Field
                                        name={nameOf(
                                          "HaveNoTransactionsSinceLastNotice"
                                        )}
                                        component={CCSwitch}
                                      />
                                    </div>
                                    <div className="cc-field">
                                      <label className="cc-label">
                                        Are in a scheme yet to be declared
                                      </label>
                                      <Field
                                        name={nameOf(
                                          "AreInASchemeYetToBeDeclared"
                                        )}
                                        component={CCSwitch}
                                      />
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </section>
                          </section>
                        </div>
                      </section>
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">
                            How are the notices to be ordered?
                          </label>
                          <Field
                            name={nameOf("HowAreTheNoticesToBeOrdered")}
                            component={CCSearchComboBox}
                            textField={nameOfLov("Name")}
                            dataItemKey={nameOfLov("Code")}
                            data={mockNoticesToBeOrdered}
                            value={getDropdownValue(
                              formRenderProps.valueGetter(
                                nameOf("HowAreTheNoticesToBeOrdered")
                              ),
                              mockNoticesToBeOrdered,
                              nameOfLov("Code")
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              formRenderProps.onChange(
                                nameOf("HowAreTheNoticesToBeOrdered"),
                                {
                                  value: event.target.value?.Code ?? null,
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    disabled={!formRenderProps.valid}
                    themeColor="primary"
                    onClick={onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </div>
        </FormElement>
      )}
    />
  );
};
