import { history } from "@/AppRoutes";
import {
  getInspection,
  getInspectionLOVs,
  postInspection,
  putInspection,
} from "@app/products/hm/api";
import { getPositions } from "@app/products/hm/inspections/[id]/components/child-screens/general/api";
import { INSPECTIONS_MANAGE_ROUTE } from "@app/products/hm/inspections/[id]/constant";
import {
  IInspectionLovs,
  InspectionRegisterForm,
} from "@app/products/hm/model";
import { convertInspectionResponseToForm } from "@app/products/hm/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class HMInspectionsStore {
  private _inspections?: InspectionRegisterForm = undefined;
  private _referenceData?: any = {
    inspection: {},
    parent: {},
  };
  private _position?: any = undefined;
  private _inspectionsLOVs?: IInspectionLovs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get inspections() {
    return toJS(this._inspections);
  }
  setInspections = (inspections?: InspectionRegisterForm) => {
    runInAction(() => {
      this._inspections = inspections;
    });
  };

  get position() {
    return toJS(this._position);
  }
  setPosition = (position?: any) => {
    runInAction(() => {
      this._position = position;
    });
  };

  get referenceData() {
    return toJS(this._referenceData);
  }
  setReferenceData = (referenceData?: any) => {
    runInAction(() => {
      this._referenceData = referenceData;
    });
  };

  get inspectionsLOVs() {
    return toJS(this._inspectionsLOVs);
  }
  setInspectionsLOVs = (inspectionsLOVs?: IInspectionLovs) => {
    runInAction(() => {
      this._inspectionsLOVs = inspectionsLOVs;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get premisesId() {
    return toJS(this._inspections?.ID)?.toString();
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._inspections = undefined;
      this._isLoading = false;
      this._inspectionsLOVs = undefined;
      this._responseLoadError = undefined;
    });
  };

  //@TODO Recheck when inspections API was fixed
  // loadInspectionsInfo = async (
  //   id: string,
  //   isNew: boolean,
  //   inspectionType: string
  // ) => {
  //   let errorResponse = undefined;
  //   this.setIsLoading(true);

  //   if (isNew) {
  //     const response = await getInpectionLOVs(id, inspectionType);
  //     if (isSuccessResponse(response)) {
  //       this.setInspectionsLOVs(response.data);
  //     } else {
  //       errorResponse = {
  //         status: response.status,
  //         error: response.error,
  //       };
  //     }
  //   } else {
  //     const response = await getInspection(id);
  //     if (Array.isArray(response)) {
  //       const [InspectionsInfo, InspectionsLOVs] = response;

  //       if (InspectionsInfo.data) {
  //         const newInspections: InspectionRegisterForm =
  //           convertInspectionResponseToForm(InspectionsInfo.data);
  //         this.setInspections(newInspections);
  //       }

  //       this.setInspectionsLOVs(InspectionsLOVs.data);
  //     } else {
  //       const responseError = response as APIResponse;
  //       errorResponse = {
  //         status: responseError.status,
  //         error: responseError.error,
  //       };
  //     }
  //   }

  //   this.setResponseLoadError(errorResponse);
  //   this.setIsLoading(false);
  // };

  loadInspections = async (premiseId: string, parentPremiseId?: string) => {
    this.setIsLoading(true);
    const responseSummary = await getInspection(premiseId);
    let newInspections = undefined;
    let newReferences = undefined;
    let errorResponse = undefined;
    let newInspectionsLOVs = undefined;
    if (isSuccessResponse(responseSummary)) {
      newInspections = convertInspectionResponseToForm(responseSummary.data);
      newReferences = {
        inspection: {
          InspectionLabel:
            responseSummary.data.InspectionType +
            " - " +
            responseSummary.data.Compliance,
          RefNo: responseSummary.data.ReferenceNumber,
          Rating: responseSummary.data.AssessmentRating,
          DateInspected: responseSummary.data.DateInspected,
        },
        parent: responseSummary.data.parent,
      };
      const premiseId = parentPremiseId ?? newInspections?.parent.Premises_ID;
      const inspectionTypeId = newInspections?.InspectionType_ID;
      if (premiseId && inspectionTypeId) {
        const responseLOVs = await getInspectionLOVs(
          premiseId,
          inspectionTypeId
        );
        if (isSuccessResponse(responseLOVs)) {
          newInspectionsLOVs = responseLOVs.data;
        } else {
          errorResponse = {
            status: responseLOVs.status,
            error: responseLOVs.error,
          };
        }
      }
    } else {
      errorResponse = {
        status: responseSummary.status,
        error: responseSummary.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setInspections(newInspections);
    this.setInspectionsLOVs(newInspectionsLOVs);
    this.setReferenceData(newReferences);
    this.setIsLoading(false);
  };

  loadInspectionsLOVs = async (id: string, inspectionType: string) => {
    this.setIsLoading(true);
    const responseLOVs = await getInspectionLOVs(id, inspectionType);
    let newInspectionsLOVs = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(responseLOVs)) {
      newInspectionsLOVs = responseLOVs.data;
    } else {
      errorResponse = {
        status: responseLOVs.status,
        error: responseLOVs.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setInspectionsLOVs(newInspectionsLOVs);
    this.setIsLoading(false);
  };

  loadPosition = async (productType: number) => {
    this.setIsLoading(true);
    const newPosition = await getPositions(productType);
    this.setPosition(newPosition);
    this.setIsLoading(false);
  };

  createInspections = async (data: any) => {
    this.setIsLoading(true);
    const response = await postInspection(
      convertInspectionResponseToForm(data)
    );
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Inspections created successfully",
        type: "success",
        onClose: () => {
          history.push(`${INSPECTIONS_MANAGE_ROUTE}/${response.data.ID ?? 1}`);
        },
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Inspections create failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };

  updateInspections = async (data: any) => {
    this.setIsLoading(true);
    const response = await putInspection(
      data.ID,
      convertInspectionResponseToForm(data)
    );
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Inspections updated successfully",
        type: "success",
        onClose: () => {
          history.push(`${INSPECTIONS_MANAGE_ROUTE}/${response.data.ID ?? 1}`);
        },
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Inspections update failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };
}

const InspectionsStoreContext = createContext(new HMInspectionsStore());
export const useInspectionsStore = () => {
  return useContext(InspectionsStoreContext);
};
