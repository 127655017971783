import {
  behalfOfProps,
  commentsProps,
  isSamePermitAddressProps,
  onBehalfOfProps,
  postalAddressProps,
} from "@app/products/parking-permits/util";
import { ParkingPermitsFormDefault } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/components/form-default/_index";
import { parentPage } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/mock";
import { IParkingPermitsForm } from "@app/products/parking-permits/[id]/model";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";
export interface IParkingPermitsFormElementProps {
  formRenderProps: FormRenderProps;
  dataChoose?: string;
  typePermit?: string;
  sameAddressData?: any;
}

const nameOf = nameOfFactory<IParkingPermitsForm>();

export const ParkingPermitsFormElement = ({
  formRenderProps,
}: IParkingPermitsFormElementProps) => {
  const { valueGetter, modified, onSubmit, onChange, valid } = formRenderProps;
  const { pushNotification } = useCCAppNotificationStore();
  //#region Init
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const isNew = useIsNew();
  const history = useHistory();
  //#endregion

  //#region Function
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (modified) {
      onSubmit(event);
      return;
    }

    if (!valid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  const navigateToContactRelationship = () => {
    history.push(`/managecontactrelationship/${"1300"}`, {
      parentPage,
      recordType: "210",
    });
  };
  //#endregion

  return (
    <>
      {showAddressPopup && (
        <RegisterAddressPopup onClosePopup={() => setShowAddressPopup(false)} />
      )}

      <FormElement
        onSubmit={onSubmit}
        className="cc-parking-permits-form-element"
      >
        {/* Reference by a label in ActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        {/* Form Edit */}
        {isNew ? (
          <ParkingPermitsFormDefault formRenderProps={formRenderProps} />
        ) : (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label ">
                    Permit Holder
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    onClick={navigateToContactRelationship}
                    autoComplete="off"
                    name={nameOf("PermitHolder")}
                    component={InputPicker}
                    validator={requiredValidator}
                  />
                </div>
                <CCValueField
                  name={nameOf("ContactPermitHolder")}
                  label="Contact"
                  value={valueGetter(nameOf("ContactPermitHolder"))}
                />
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Contact person&nbsp;
                    <Button
                      fillMode="flat"
                      className="cc-parking-permits-owner-button"
                      iconClass="fa fa-chevron-circle-down"
                      onClick={(e) => {
                        e.preventDefault();
                        onChange(nameOf("ContactNumPerson"), {
                          value: valueGetter(nameOf("ContactPermitHolder")),
                        });
                        onChange(nameOf("ContactPerson"), {
                          value: valueGetter(nameOf("PermitHolder")),
                        });
                      }}
                    />
                  </label>
                  <Field
                    autoComplete="off"
                    name={nameOf("ContactPerson")}
                    component={CCInput}
                    readOnly
                  />
                </div>
                <CCValueField
                  name={nameOf("ContactNumPerson")}
                  label="Contact"
                  value={valueGetter(nameOf("ContactNumPerson"))}
                />
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Permit address
                    <CCTooltip type="validator" position="right" />
                    <Button
                      fillMode="flat"
                      className="cc-parking-permits-owner-button"
                      iconClass="fa fa-chevron-circle-down"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </label>
                  <Field
                    onClick={() => setShowAddressPopup(true)}
                    name={nameOf("PermitAddress")}
                    validator={requiredValidator}
                    component={InputPicker}
                  />
                </div>
                <CCValueField
                  name={nameOf("PropertyNumber")}
                  label="Property number"
                  value={valueGetter(nameOf("PropertyNumber"))}
                />
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">File number</label>
                  <Field
                    autoComplete="off"
                    name={nameOf("FileNumber")}
                    component={CCInput}
                    readOnly
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    <Field
                      autoComplete="off"
                      name={nameOf("IsSameAsPermitAddress")}
                      className="cc-label"
                      component={Checkbox}
                      {...isSamePermitAddressProps(
                        formRenderProps,
                        valueGetter
                      )}
                    />
                    <label className="cc-label">Same as permit address</label>
                  </label>
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    <Field
                      autoComplete="off"
                      name={"IsPropertyAddress"}
                      className="cc-label"
                      component={Checkbox}
                    />
                    <label className="cc-label">Property address?</label>
                  </label>
                </div>
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    <Field
                      autoComplete="off"
                      name={nameOf("OnBehalfOf")}
                      className="cc-label"
                      component={Checkbox}
                      {...onBehalfOfProps(formRenderProps)}
                    />
                    <label className="cc-label">On behalf of?</label>
                  </label>
                  <Field
                    onClick={navigateToContactRelationship}
                    autoComplete="off"
                    name={"Behalfof"}
                    component={InputPicker}
                    {...behalfOfProps(
                      formRenderProps,
                      !valueGetter(nameOf("OnBehalfOf")),
                      !valueGetter(nameOf("OnBehalfOf"))
                    )}
                  />
                </div>
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Postal address</label>
                  <Field
                    onClick={() => setShowAddressPopup(true)}
                    autoComplete="off"
                    name={nameOf("PostalAddress")}
                    component={InputPicker}
                    {...postalAddressProps(
                      formRenderProps,
                      valueGetter,
                      valueGetter(nameOf("IsSameAsPermitAddress")),
                      valueGetter(nameOf("IsSameAsPermitAddress"))
                    )}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Issue date
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    name={nameOf("IssueDate")}
                    defaultValue={new Date()}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Issue time</label>
                  <Field
                    value={
                      valueGetter(nameOf("IssueTime"))
                        ? new Date(valueGetter(nameOf("IssueTime")))
                        : new Date()
                    }
                    name={nameOf("IssueTime")}
                    component={TimePicker}
                  />
                </div>
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Expiry date</label>
                  <Field
                    name={nameOf("ExpiryDate")}
                    defaultValue={new Date()}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Expiry time</label>
                  <Field
                    value={
                      valueGetter(nameOf("ExpiryTime"))
                        ? new Date(valueGetter(nameOf("ExpiryTime")))
                        : new Date()
                    }
                    name={nameOf("ExpiryTime")}
                    component={TimePicker}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Debtor number</label>
                  <Field name={nameOf("DebtorNumber")} component={CCInput} />
                </div>
                <div className="cc-field">
                  <label className="cc-label">OS fees</label>
                  <Field name={nameOf("OSFees")} component={CCCurrencyInput} />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Comments</label>
                  <Field
                    name={nameOf("Comments")}
                    component={CCInput}
                    {...commentsProps(formRenderProps)}
                  />
                </div>
              </div>
            </section>
          </>
        )}
      </FormElement>
    </>
  );
};
