import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingBAMSPESentToBAMS: IColumnFields[] = [
  {
    field: BuildingFieldMapping.Date_Sent,
    title: "Event Date",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.ApplicationNumber,
    title: "Number",
  },
  {
    field: BuildingFieldMapping.PermitNo,
    title: "Permit Number",
  },
  {
    field: BuildingFieldMapping.BAMS_PrescribeEvent_LodgedDateTime,
    title: "BAMS Sent Date",
  },
  {
    field: BuildingFieldMapping.PrescribeEvent_KWD_Name,
    title: "Prescribe Event",
  },
  {
    field: BuildingFieldMapping.Code,
    title: "Code",
  },
  {
    field: BuildingFieldMapping.SiteAddress,
    title: "Site Address",
  },
  {
    field: BuildingFieldMapping.Surveyor_RegistrationNo,
    title: "Surveyor Registration Number",
  },
  {
    field: BuildingFieldMapping.Building_StageNumber,
    title: "Building Stage Number",
  },
  {
    field: BuildingFieldMapping.CertificateNo,
    title: "Certificate Number",
  },
  {
    field: BuildingFieldMapping.PersonDisplayName,
    title: "Involved Person",
  },
  {
    field: BuildingFieldMapping.ProjectID_Name,
    title: "Project ID/Name",
  },
];
