import { RegistrationFeeRule } from "@app/products/animals/model";
import { nameOfFactory } from "@common/utils/common";
const nameOfRegistrationFeeRule = nameOfFactory<RegistrationFeeRule>();

export const validDateValidator = (
  dayLabel: string,
  day: number,
  month: number,
  year?: number
) => {
  if (!day) return "This field is required.";
  if (!month) return "";
  const date = new Date();
  const defineYear = year ? year : date.getFullYear();

  // Check for specific edge cases like February 30th or February 29th in non-leap years
  if (day > new Date(defineYear, month, 0).getDate()) {
    return `Invalid ${dayLabel}`;
  }

  // Check if the date is a valid date and it's not "Invalid Date"
  const checkDate = new Date(`${month}/${day}/${defineYear}`);

  if (
    isNaN(checkDate.getTime()) ||
    Object.prototype.toString.call(checkDate) !== "[object Date]"
  ) {
    return `Invalid ${dayLabel}`;
  }

  return "";
};

export const startDayValidator = (
  value: any,
  valueGetter: (name: string) => any
) => {
  const startDay = value;
  const startMonth = valueGetter(nameOfRegistrationFeeRule("RVPStartMon"));
  return validDateValidator("Start day", startDay, startMonth);
};

export const endDayValidator = (
  value: any,
  valueGetter: (name: string) => any
) => {
  const endDay = value;
  const endMonth = valueGetter(nameOfRegistrationFeeRule("RVPEndMon"));
  return validDateValidator("End day", endDay, endMonth);
};
