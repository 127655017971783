import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import {
  BookmarkIcon,
  IBookmarkIconProps,
} from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/bookmark/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ListViewBookmarkIconAssessmentCollections = observer(
  (props: IBookmarkIconProps) => {
    const {
      gridSelectedIds,
      isEmptyGridSelectedRow,
      gridSelectedRows,
      gridTotalSelected,
    } = useDetailAssessmentListViewStore();

    const propsBookmarkIcon = {
      ...props,
      gridSelectedIds,
      isEmptyGridSelectedRow,
      gridSelectedRows,
      gridTotalSelected,
    };
    return <BookmarkIcon {...propsBookmarkIcon} />;
  }
);
