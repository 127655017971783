import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React from "react";

export const CentreLinkDetailSidebar = () => {
  return (
    <>
      <ProductReferenceBody>
        <PanelBar expandMode={"single"}>
          <PanelBarItem
            expanded={true}
            title={
              <ProductReferenceHeading
                title={"Information"}
                value={""}
                primary
              />
            }
          >
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Entities with invalid card numbers:"}
                value={"19382"}
              />
              <ProductReferenceRow
                title={"Entities with duplicate card numbers:"}
                value={"6"}
              />
            </ProductReferenceBlock>
          </PanelBarItem>
        </PanelBar>
      </ProductReferenceBody>
    </>
  );
};
