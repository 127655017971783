import { mockParcelLookup } from "@app/products/property/registers/[id]/components/dialogs/associate/mock";
import { IParcelLookup } from "@app/products/property/registers/[id]/components/dialogs/associate/model";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadParcelLookup = async (filter: FilterDescriptor) => {
  const parcelList = mockParcelLookup.map((parcel: IParcelLookup) => {
    return {
      ...parcel,
      SearchParcelId: parcel.ParcelId + "",
      SearchParcelReference: parcel.ParcelId + " - " + parcel.ParcelReference,
      SearchAddress: parcel.ParcelId + " - " + parcel.Address,
      SearchLegalDescription: parcel.ParcelId + " - " + parcel.LegalDescription,
      SearchName: parcel.ParcelId + " - " + parcel.Name,
    };
  });
  const data = parcelList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
