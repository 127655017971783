import { IDocuments } from "@app/core/documents/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IDocuments>();

export const colDocuments: IColumnFields[] = [
  {
    field: nameOf("DynamicTitle"),
    title: "Title",
    locked: true,
  },
  {
    field: nameOf("Title"),
    title: "Title",
    locked: true,
  },
  {
    field: nameOf("RecordedDate"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("FileName"),
    title: "File Name",
  },
  {
    field: nameOf("DocumentID"),
    title: "File Number",
  },
  {
    field: nameOf("Tag"),
    title: "Tag",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Size"),
    title: "File Size (MB)",
  },
];
