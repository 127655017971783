import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const auditHistoryRoute: ICCRoute = {
  path: "audit-history",
  name: "Audit History",
  enum: eMenuAction.Audit_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Audit_Folder_List,
      component: require("./list/_index").default,
    },
  ],
};
