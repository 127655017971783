import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementAdminMasterFacilities: IColumnFields[] = [
  {
    field: EventManagementFieldMapping.Facility_Name,
    title: "Facility",
    width: 300,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.Facility_Status,
    title: "Status",
    width: 300,
  },
];
