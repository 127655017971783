import { ManageFinancialSummaries } from "@app/products/property/assessments/financial-summaries/components/manage-dialog/_index";
import { mockFinancialSummaries } from "@app/products/property/assessments/financial-summaries/mock";
import { IFinancialSummariesFormData } from "@app/products/property/assessments/financial-summaries/model";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
interface IEditFinancialSummaryButton {
  financialId?: string;
}
export const EditFinancialSummaryButton = observer(
  ({ financialId }: IEditFinancialSummaryButton) => {
    const { gridSelectedRows, isEmptyData } = useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [showFinancialSummaryDialog, setShowFinancialSummaryDialog] =
      useState(false);
    const [financialInfo, setFinancialInfo] = useState<
      IFinancialSummariesFormData | undefined
    >();

    const loadDetailsFinancialSummary = (id: number) => {
      return mockFinancialSummaries.ListDetail.find(
        (financial: any) => financial.ID === +id
      );
    };

    const handleOnClick = () => {
      setShowFinancialSummaryDialog(true);
      const financialInfoData = loadDetailsFinancialSummary(
        financialId ?? gridSelectedRows[0]?.Id
      );
      if (financialInfoData) {
        setFinancialInfo(financialInfoData);
      } else {
        clearErrorNotification();
        setShowFinancialSummaryDialog(false);
        pushNotification({
          autoClose: false,
          title: "Load details financial summary failed.",
          type: "error",
        });
      }
    };

    const isDisabled = useMemo(() => {
      return (
        (!isEmptyData && gridSelectedRows.length !== 1 && !financialId) ||
        (!financialId && isEmptyData)
      );
    }, [gridSelectedRows.length, financialId, isEmptyData]);

    return (
      <>
        <PrivilegeWrapper
          dialogType={ePermissionPrivilegeTypeCheck.Dialog}
          privilegesEnum={EPrivilegesWorkflow.Financial_Summary_Modify}
        >
          <CCNavButton
            title="Modify financial summary"
            disabled={isDisabled}
            onClick={handleOnClick}
          />
        </PrivilegeWrapper>
        {showFinancialSummaryDialog && (
          <ManageFinancialSummaries
            onClose={() => {
              setShowFinancialSummaryDialog(false);
            }}
            financialInfo={financialInfo}
          />
        )}
      </>
    );
  }
);
