import { VO_CentreLink_External_Response } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/external-concession-cards/model";
import { VO_Centrelink_Rejection } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/rejections/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Centrelink_Rejection>();
export const colRejections: IColumnFields[] = [
  {
    field: nameOf("CR_CentreLink_Reference_Number"),
    title: "CentreLink Reference Number",
  },
  {
    field: nameOf("ENA_Name"),
    title: "Name",
    linkTo: (dataItem: VO_CentreLink_External_Response) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
  },
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Entity_Email_Address"),
    title: "Email",
  },
  {
    field: nameOf("Entity_Phone_Number_Mobile"),
    title: "Mobile",
  },
];
