import { loadMeterSpecialReadingListDetailTabById } from "@app/products/property/meters/special-reading-list/[id]/components/reference-sidebar/detail/api";
import { IReferenceSideBarDetails } from "@app/products/property/meters/special-reading-list/[id]/components/reference-sidebar/detail/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const MeterSpecialReadingListDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [meterSpecialReadingListInfo, setMeterSpecialReadingListInfo] =
    useState<IReferenceSideBarDetails>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const meterSpecialReadId = params.id || lastSelectedRow.MeterSpecialReadId;
  useEffect(() => {
    setIsLoading(true);
    if (!meterSpecialReadId) {
      setIsLoading(false);
      return;
    }
    loadMeterSpecialReadingListDetailTabById(meterSpecialReadId).then(
      (data) => {
        if (!data) return;
        setMeterSpecialReadingListInfo(data);
        setIsLoading(false);
      }
    );
  }, [meterSpecialReadId]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Meter Special Read ID:"}
          value={meterSpecialReadingListInfo?.MeterSpecialReadId}
        />
        <ProductReferenceRow
          title={"Special Read Type:"}
          value={meterSpecialReadingListInfo?.SpecialReadType}
        />
        <ProductReferenceRow
          title={"Date Read:"}
          value={formatDisplayValue(
            meterSpecialReadingListInfo?.DateRead,
            DATETIME_FORMAT.DATETIME
          )}
        />
        <ProductReferenceRow
          title={"Created On:"}
          value={formatDisplayValue(
            meterSpecialReadingListInfo?.CreatedOn,
            DATETIME_FORMAT.DATETIME
          )}
        />
        <ProductReferenceRow
          title={"Created By:"}
          value={meterSpecialReadingListInfo?.CreatedBy}
        />
        <ProductReferenceRow
          title={"Journal Number:"}
          value={meterSpecialReadingListInfo?.JournalNumber}
        />
        <ProductReferenceRow
          title={"Assessment Number:"}
          value={meterSpecialReadingListInfo?.AssessmentNumber}
        />
        <ProductReferenceRow
          title={"Property Address:"}
          value={meterSpecialReadingListInfo?.PropertyAddress}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
