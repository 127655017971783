import { DTO_PIC_Transaction } from "@app/products/property/pic/[id]/components/child-screens/transactions/model";
import { usePicTransactionsStore } from "@app/products/property/pic/[id]/components/child-screens/transactions/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const nameOf = nameOfFactory<DTO_PIC_Transaction>();

export const PicTransaction = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    loadTransactions,
    resetStore,
    isLoading,
    responseLoadError,
    transactionCols,
    transactions,
  } = usePicTransactionsStore();

  useEffect(() => {
    loadTransactions(+id);
    return () => {
      resetStore();
    };
  }, [id, loadTransactions, resetStore]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (id) {
            loadTransactions(+id);
          }
        }}
      />
    );
  }
  if (!transactions) return <NoData />;

  return (
    <div className="cc-pic-transactions cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={transactions ?? []}
              columnFields={transactionCols}
              primaryField={nameOf("PICTransactionId")}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
