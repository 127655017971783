export const enum EPropertyStepGridType {
  Assessment = "Assessments",
  Title = "Titles",
}

export const propertyFormStepGridValidator = (
  gridType: EPropertyStepGridType,
  values: any,
  gridLabel: string
) => {
  const gridData = values?.[gridType] ?? [];
  if (!values || !gridData.length)
    return `You must add at least one ${gridLabel} to proceed.`;
  return "";
};
