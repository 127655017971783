import { Registration_Status } from "@app/products/animals/[id]/model";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import {
  LIMIT_DATE_RENEW,
  TIME_PER_DATE,
} from "@app/products/animals/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const AnimalRenewButton = observer(() => {
  const { animalInfo, isDeleted, setIsShowWorkflowRenew } = useAnimalStore();

  const isVisible = useMemo(() => {
    return animalInfo?.Status_ENUM === Registration_Status.Active;
  }, [animalInfo]);

  const isDisable = useMemo(() => {
    let isDisable = false;

    if (!isNil(animalInfo?.Date_EffectiveTo)) {
      let daysToRenewal = Math.round(
        (new Date(animalInfo.Date_EffectiveTo).getTime() -
          new Date().getTime()) /
          TIME_PER_DATE
      );
      isDisable = daysToRenewal > LIMIT_DATE_RENEW;
    }
    return isDisable;
  }, [animalInfo]);

  return isVisible ? (
    <CCNavButton
      title="Renew"
      disabled={isDisable || isDeleted}
      onClick={() => setIsShowWorkflowRenew(true)}
    />
  ) : null;
});
