import { mockRegisterMemos } from "@app/products/property/registers/[id]/components/child-screens/memos/mock";
import { DTO_Register_Memos } from "@app/products/property/registers/[id]/components/child-screens/memos/model";
import { sleep } from "@common/utils/common";

export const getRegisterMemos = async (): Promise<
  DTO_Register_Memos | undefined
> => {
  await sleep(1000);
  return mockRegisterMemos;
};
