import { DTO_AssociatedItem_AssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/model";
import { colAssociationsAssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/standard-assessment-rebate/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsAssessmentRebateEntitlementProps {
  associationsAssessmentRebateEntitlementInfo:
    | DTO_AssociatedItem_AssessmentRebateEntitlement[]
    | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_AssessmentRebateEntitlement>();
export const AssociationsAssessmentRebateEntitlement = ({
  associationsAssessmentRebateEntitlementInfo,
}: IAssociationsAssessmentRebateEntitlementProps) => {
  return (
    <CCGrid
      className="cc-assessment-grid"
      data={associationsAssessmentRebateEntitlementInfo || []}
      columnFields={colAssociationsAssessmentRebateEntitlement}
      primaryField={nameOf("Id")}
    />
  );
};
