import { IColumnFields } from "@components/cc-grid/model";

export const InspectionTableType: IColumnFields[] = [
  { field: "Name", title: "Name" },
  { field: "Group", title: "Group" },
  { field: "Description", title: "Description" },
  { field: "FeeAmount", title: "Fee Amount" },
  { field: "Classification", title: "Classification" },
];

export const OfficerType: IColumnFields[] = [
  { field: "Name", title: "Name" },
  { field: "Position", title: "Position" },
  { field: "Email", title: "Email" },
  { field: "WorkPhone", title: "Phone" },
  { field: "Mobile", title: "Mobile" },
];
