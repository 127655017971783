import {
  ServiceStandard,
  ServiceStandardMapObj,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { isFieldVisible } from "@app/products/crms/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

export interface CustomerServiceValidationProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

export const CustomerServiceValidation = observer(
  ({ formRenderProps }: CustomerServiceValidationProps) => {
    const { valueGetter } = formRenderProps;
    const { serviceStandardLovs, uiControl, accessRight } =
      useCRMSServiceStandardStore();
    const isDisabled = useMemo(
      () => accessRight === ACCESSRIGHTS.CanRead,
      [accessRight]
    );

    const isVisibleResponses = isFieldVisible(
      uiControl?.DivResponses?.DisplayStatus
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            <CCLabel title="CS validation" />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "CustomerServiceValidation"
              )}`}
              component={CCInput}
              placeholder={"CS validation"}
            />
          </div>

          <div className="cc-col-span-1">
            <CCValueField
              value={
                "This description will enable the customer service validation to appear on the event entry screen.  It will restrict the CS Officer from sending the request for action until they validate the option"
              }
              className={"cc-text-info cc-padding-text-info"}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Use system defaults</label>
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "ResolutionResponses_UseDefaults"
              )}`}
              checked={valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "ResolutionResponses_UseDefaults"
                )}`
              )}
              component={CCSwitch}
            />
          </div>
          {!valueGetter(
            `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "ResolutionResponses_UseDefaults"
            )}`
          ) && (
            <div className="cc-field">
              <CCLabel title="Comment" />
              <Field
                disabled={isDisabled}
                name={`${nameOfServiceStandardMapObj(
                  "ServiceStandard"
                )}.${nameOf("ResolutionResponses")}`}
                textField={"Name"}
                dataItemKey={"LookupTable_Id"}
                data={serviceStandardLovs?.ResolutionResponses ?? []}
                component={CCMultiSelectDropdown}
              />
            </div>
          )}
        </div>

        {isVisibleResponses && (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel
                  title={uiControl?.FlResponse1Caption?.Value ?? "Response 1"}
                />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Response1")}`}
                  placeholder={
                    uiControl?.FlResponse1Caption?.Value ?? "Response 1"
                  }
                  rows={6}
                  component={CCTextArea}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel
                  title={uiControl?.FlResponse2Caption?.Value ?? "Response 2"}
                />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Response2")}`}
                  placeholder={
                    uiControl?.FlResponse2Caption?.Value ?? "Response 2"
                  }
                  rows={6}
                  component={CCTextArea}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel
                  title={uiControl?.FlResponse3Caption?.Value ?? "Response 3"}
                />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Response3")}`}
                  placeholder={
                    uiControl?.FlResponse3Caption?.Value ?? "Response 3"
                  }
                  rows={6}
                  component={CCTextArea}
                />
              </div>
            </div>
          </>
        )}
      </section>
    );
  }
);
