import { ManualReadingDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/_index";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
export const ManualReadingButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { meter } = useMeterStore();
  const { pushNotification } = useCCAppNotificationStore();

  return (
    <>
      <CCNavButton
        title="Manual reading"
        onClick={() => {
          setIsShowDialog(true);
        }}
      />

      {isShowDialog && (
        <ManualReadingDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          meterInfo={meter}
          onSubmit={(data: any) => {
            setIsShowDialog(false);
            pushNotification({
              title: `Manual reading successfully`,
              type: "success",
            });
          }}
        />
      )}
    </>
  );
});
