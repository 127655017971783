export const mockSchemeList = {
  All: [
    {
      SchemeNumber: 32021,
      SchemeName: "Pakenham Industrial Estate Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 48,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      InterestRate: 0,
      DefaultNumberOfInstalments: 1,
      SchemeInitiatedOn: new Date("20-Nov-2001"),
      SchemeDeclaredOn: new Date("3-Dec-2008"),
      SchemeCommencedOn: new Date("10-Apr-2003"),
      ActualSubsidy: 0,
      EstimatedCost: 1633334,
      EstimatedSubsidy: 0,
      EstimatedCostToAssessments: 1633334,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 1,
    },
    {
      SchemeNumber: 32022,
      SchemeName: "Majestic Dr Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 47,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      DefaultNumberOfInstalments: 1,
      SchemeInitiatedOn: new Date("21-Jul-2004"),
      SchemeDeclaredOn: new Date("3-Dec-2008 "),
      SchemeCommencedOn: new Date("24-Feb-2005"),
      ActualCost: 388580,
      ActualSubsidy: 0,
      ActualCostToAssessments: 388580,
      EstimatedCost: 446615.29,
      EstimatedSubsidy: 0,
      EstimatedCostToAssessments: 446615.29,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 2,
    },
    {
      SchemeNumber: 32023,
      SchemeName: "Devon\\Belgrave Ave Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 69,
      PrincipalBalance: 16282.88,
      InterestBalance: 34573.2,
      BalanceOutstanding: 50856.08,
      AmountDue: 50856.08,
      NextInterestDate: new Date("12-Feb-2022"),
      DefaultNumberOfInstalments: 1,
      SchemeInitiatedOn: new Date("20-Jul-2005"),
      SchemeDeclaredOn: new Date("3-Dec-2008"),
      SchemeCommencedOn: new Date("31-Mar-2005"),
      ActualSubsidy: 0,
      EstimatedCost: 354663.56,
      EstimatedSubsidy: 0,
      EstimatedCostToAssessments: 354663.56,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 3,
    },
    {
      SchemeNumber: 32030,
      SchemeName: "Henry Rd Special Charge Scheme Sewerage",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 9,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      InterestRate: 0,
      DefaultNumberOfInstalments: 20,
      SchemeInitiatedOn: new Date("31-Oct-2002"),
      SchemeDeclaredOn: new Date("3-Dec-2008 "),
      SchemeCommencedOn: new Date("31-Oct-2002"),
      ActualCost: 1423252,
      ActualSubsidy: 0,
      ActualCostToAssessments: 1423252.0,
      EstimatedCost: 1132735,
      EstimatedSubsidy: 0,
      EstimatedCostToAssessments: 1132735,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 4,
    },
    {
      SchemeNumber: 32031,
      SchemeName: "Westlands Rd Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 158,
      PrincipalBalance: 56493.98,
      InterestBalance: 19602.56,
      BalanceOutstanding: 76096.54,
      AmountDue: 76096.54,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("22-Oct-2008"),
      SchemeDeclaredOn: new Date("18-Sep-2007 "),
      SchemeCommencedOn: new Date("3-Dec-2008 "),
      ActualCost: 1265398.06,
      ActualCostToAssessments: 1265398.06,
      EstimatedCost: 1197992.53,
      EstimatedCostToAssessments: 1197992.53,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 5,
    },
    {
      SchemeNumber: 32032,
      SchemeName: "Miscellaneous/Conversion Schemes",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 24,
      PrincipalBalance: 35315,
      BalanceOutstanding: 35315,
      AmountDue: 35315,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("23-Oct-2008"),
      SchemeDeclaredOn: new Date("3-Dec-2008"),
      SchemeCommencedOn: new Date("3-Dec-2008"),
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      Comment:
        "Miscellaneous scheme accounts not included in conversion - August",
      SchemeId: 6,
    },
    {
      SchemeNumber: 32033,
      SchemeName: "Jefferson Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 34,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("14-Apr-2009"),
      EstimatedCost: 407386.72,
      EstimatedCostToAssessments: 407386.72,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 8,
    },
    {
      SchemeNumber: 32034,
      SchemeName: "Old Beaconsfield Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 34,
      PrincipalBalance: 5744.19,
      InterestBalance: 1809.4,
      BalanceOutstanding: 7553.59,
      AmountDue: 7553.59,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("14-Apr-2009"),
      EstimatedCost: 342550,
      EstimatedCostToAssessments: 342550,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 9,
    },
    {
      SchemeNumber: 32035,
      SchemeName: "Old Menzies Creek Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 16,
      PrincipalBalance: 55942.45,
      BalanceOutstanding: 55942.45,
      AmountDue: 55942.45,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("24-Nov-2009"),
      EstimatedCost: 209250,
      EstimatedCostToAssessments: 209250,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      Comment: "Old Menzies Creek Road Special Charge Scheme",
      SchemeId: 11,
    },
    {
      SchemeNumber: 32036,
      SchemeName: "Wattletree Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 74,
      PrincipalBalance: 35778.26,
      InterestBalance: 13539.73,
      BalanceOutstanding: 49317.99,
      AmountDue: 49317.99,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("24-Nov-2009"),
      ActualCost: 276209.23,
      ActualCostToAssessments: 276209.23,
      EstimatedCost: 389445,
      EstimatedCostToAssessments: 389445,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 12,
    },
    {
      SchemeNumber: 32037,
      SchemeName: "Guest Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 37,
      PrincipalBalance: 3815.51,
      InterestBalance: 804.93,
      BalanceOutstanding: 4620.44,
      AmountDue: 4620.44,
      NextInstalmentDate: new Date("20-Oct-2013"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("25-Nov-2009"),
      EstimatedCost: 98030.02,
      EstimatedCostToAssessments: 98030.02,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 13,
    },
    {
      SchemeNumber: 32038,
      SchemeName: "Cornish Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 8,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("15-Jun-2010"),
      SchemeDeclaredOn: new Date("15-Jun-2010"),
      ActualCost: 13469.22,
      ActualCostToAssessments: 13469.22,
      EstimatedCost: 16737.56,
      EstimatedCostToAssessments: 16737.56,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 14,
    },
    {
      SchemeNumber: 32039,
      SchemeName: "Ryan Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 67,
      PrincipalBalance: 6929.05,
      InterestBalance: 479.79,
      BalanceOutstanding: 7408.84,
      AmountDue: 7408.84,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("25-Aug-2010"),
      SchemeDeclaredOn: new Date("16-Nov-2010"),
      EstimatedCost: 191100,
      EstimatedCostToAssessments: 191100,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 15,
    },
    {
      SchemeNumber: 32040,
      SchemeName:
        "Como St Berrys Rd, Ferres Rd and Part of Madigan Way, Emerald",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 48,
      PrincipalBalance: 46541.95,
      InterestBalance: 3051.29,
      BalanceOutstanding: 49593.24,
      AmountDue: 49593.24,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("27-Jan-2012"),
      SchemeDeclaredOn: new Date("19-Apr-2011"),
      ActualCost: 417627.1,
      ActualCostToAssessments: 417627.1,
      EstimatedCost: 485500,
      EstimatedCostToAssessments: 485500,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      Comment:
        "Construction of pavement, kerb & chanel and associated drainage",
      SchemeId: 16,
    },
    {
      SchemeNumber: 32042,
      SchemeName: "Paton Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 5,
      PrincipalBalance: 357.29,
      BalanceOutstanding: 357.29,
      AmountDue: 357.29,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("1-Feb-2012"),
      SchemeDeclaredOn: new Date("15-Mar-2011"),
      EstimatedCost: 51498.2,
      EstimatedCostToAssessments: 51498.2,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 18,
    },
    {
      SchemeNumber: 32043,
      SchemeName: "George and Mary Street Bunyip Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 31,
      PrincipalBalance: 99921.22,
      InterestBalance: 10143.53,
      BalanceOutstanding: 110064.75,
      AmountDue: 110064.75,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("28-Jun-2012"),
      SchemeDeclaredOn: new Date("17-May-2011 "),
      SchemeCommencedOn: new Date("28-Jun-2012"),
      EstimatedCost: 229000,
      EstimatedCostToAssessments: 229000,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 20,
    },
    {
      SchemeNumber: 32044,
      SchemeName: "Mill St Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 17,
      PrincipalBalance: 61099,
      InterestBalance: 4256.02,
      BalanceOutstanding: 65355.02,
      AmountDue: 65355.02,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("28-Mar-2014"),
      SchemeDeclaredOn: new Date("1-Jul-2013 "),
      SchemeCommencedOn: new Date("1-Jul-2013"),
      ActualCost: 184819.54,
      ActualCostToAssessments: 184819.54,
      EstimatedCost: 189400,
      EstimatedCostToAssessments: 189400,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 21,
    },
    {
      SchemeNumber: 32045,
      SchemeName: "Moody Street Koo Wee Rup Special Charge Schemel 00",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 3,
      PrincipalBalance: 68850.8,
      BalanceOutstanding: 68850.8,
      AmountDue: 68850.8,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("31-Jul-2016"),
      EstimatedCost: 100633,
      EstimatedCostToAssessments: 100633,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 22,
    },
    {
      SchemeNumber: 32046,
      SchemeName: "McBride Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 16,
      PrincipalBalance: 181230.39,
      BalanceOutstanding: 181230.39,
      AmountDue: 181230.39,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("31-Jul-2016"),
      SchemeCommencedOn: new Date("12-Mar-2016"),
      EstimatedCost: 265492.05,
      EstimatedCostToAssessments: 265492.05,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 23,
    },
    {
      SchemeNumber: 32047,
      SchemeName: "Island Road KWR Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 28,
      PrincipalBalance: 91114.93,
      BalanceOutstanding: 91114.93,
      AmountDue: 91114.93,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("10-Dec-2016"),
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 24,
    },
    {
      SchemeNumber: 32048,
      SchemeName: "Wadsley Avenue Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 16,
      PrincipalBalance: 65244.77,
      BalanceOutstanding: 65244.77,
      AmountDue: 65244.77,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("10-Dec-2016"),
      EstimatedCost: 133000,
      EstimatedCostToAssessments: 133000,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 25,
    },
    {
      SchemeNumber: 32049,
      SchemeName: "Wheeler Road Maryknoll Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 8,
      PrincipalBalance: 117024.88,
      BalanceOutstanding: 117024.88,
      AmountDue: 109713.82,
      InterestRate: 2.83,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("28-Jul-2017"),
      EstimatedCost: 127709.18,
      EstimatedCostToAssessments: 127709.18,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 26,
    },
    {
      SchemeNumber: 32050,
      SchemeName: "Peet Street Industrial Area Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 41,
      PrincipalBalance: 10298823.93,
      BalanceOutstanding: 10298823.93,
      AmountDue: 10298823.93,
      DefaultNumberOfInstalments: 16,
      SchemeInitiatedOn: new Date("14-Aug-2018"),
      SchemeDeclaredOn: new Date("15-May-2018"),
      EstimatedCost: 10298823.95,
      EstimatedCostToAssessments: 10298823.95,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 27,
    },
    {
      SchemeNumber: 32051,
      SchemeName: "New Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 0,
      DefaultNumberOfInstalments: 16,
      SchemeInitiatedOn: new Date("16-Dec-2021"),
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 28,
    },
    {
      SchemeNumber: 32052,
      SchemeName: "Drainage",
      SchemeType: "Drainage Scheme",
      NumberOfAccounts: 47,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      InterestRate: 10,
      NextInterestDate: new Date("14-Feb-2022"),
      NextInstalmentDate: new Date("15-Jan-2022"),
      DefaultNumberOfInstalments: 16,
      SchemeInitiatedOn: new Date("16-Dec-2021"),
      SchemeDeclaredOn: new Date("6-Dec-2021"),
      SchemeCommencedOn: new Date("16-Dec-2021"),
      EstimatedCost: 30000,
      EstimatedCostToAssessments: 30000,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 29,
    },
  ],
  IncludeInActive: [
    {
      SchemeNumber: 32021,
      SchemeName: "Pakenham Industrial Estate Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 48,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      InterestRate: 0,
      DefaultNumberOfInstalments: 1,
      SchemeInitiatedOn: new Date("20-Nov-2001"),
      SchemeDeclaredOn: new Date("3-Dec-2008 "),
      SchemeCommencedOn: new Date("10-Apr-2003"),
      ActualSubsidy: 0,
      EstimatedCost: 1633334,
      EstimatedSubsidy: 0,
      EstimatedCostToAssessments: 1633334,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 1,
    },
    {
      SchemeNumber: 32023,
      SchemeName: "Devon\\Belgrave Ave Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 69,
      PrincipalBalance: 16282.88,
      InterestBalance: 34573.2,
      BalanceOutstanding: 50856.08,
      AmountDue: 50856.08,
      NextInterestDate: new Date("12-Feb-2022"),
      DefaultNumberOfInstalments: 1,
      SchemeInitiatedOn: new Date("20-Jul-2005"),
      SchemeDeclaredOn: new Date("3-Dec-2008 "),
      SchemeCommencedOn: new Date("31-Mar-2005"),
      ActualSubsidy: 0,
      EstimatedCost: 354663.56,
      EstimatedSubsidy: 0,
      EstimatedCostToAssessments: 354663.56,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 3,
    },
    {
      SchemeNumber: 32031,
      SchemeName: "Westlands Rd Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 158,
      PrincipalBalance: 56493.98,
      InterestBalance: 19602.56,
      BalanceOutstanding: 76096.54,
      AmountDue: 76096.54,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("22-Oct-2008"),
      SchemeDeclaredOn: new Date("18-Sep-2007 "),
      SchemeCommencedOn: new Date("3-Dec-2008 "),
      ActualCost: 1265398.06,
      ActualCostToAssessments: 1265398.06,
      EstimatedCost: 1197992.53,
      EstimatedCostToAssessments: 1197992.53,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 5,
    },
    {
      SchemeNumber: 32032,
      SchemeName: "Miscellaneous/Conversion Schemes",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 24,
      PrincipalBalance: 35315,
      BalanceOutstanding: 35315,
      AmountDue: 35315,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("23-Oct-2008"),
      SchemeDeclaredOn: new Date("3-Dec-2008 "),
      SchemeCommencedOn: new Date("3-Dec-2008"),
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      Comment:
        "Miscellaneous scheme accounts not included in conversion - August",
      SchemeId: 6,
    },
    {
      SchemeNumber: 32033,
      SchemeName: "Jefferson Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 34,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("14-Apr-2009"),
      EstimatedCost: 407386.72,
      EstimatedCostToAssessments: 407386.72,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 8,
    },
    {
      SchemeNumber: 32034,
      SchemeName: "Old Beaconsfield Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 34,
      PrincipalBalance: 5744.19,
      InterestBalance: 1809.4,
      BalanceOutstanding: 7553.59,
      AmountDue: 7553.59,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("14-Apr-2009"),
      EstimatedCost: 342550,
      EstimatedCostToAssessments: 342550,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 9,
    },
    {
      SchemeNumber: 32035,
      SchemeName: "Old Menzies Creek Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 16,
      PrincipalBalance: 55942.45,
      BalanceOutstanding: 55942.45,
      AmountDue: 55942.45,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("24-Nov-2009"),
      EstimatedCost: 209250,
      EstimatedCostToAssessments: 209250,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      Comment: "Old Menzies Creek Road Special Charge Scheme",
      SchemeId: 11,
    },
    {
      SchemeNumber: 32036,
      SchemeName: "Wattletree Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 74,
      PrincipalBalance: 35778.26,
      InterestBalance: 13539.73,
      BalanceOutstanding: 49317.99,
      AmountDue: 49317.99,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("24-Nov-2009"),
      ActualCost: 276209.23,
      ActualCostToAssessments: 276209.23,
      EstimatedCost: 389445,
      EstimatedCostToAssessments: 389445,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 12,
    },
    {
      SchemeNumber: 32037,
      SchemeName: "Guest Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 37,
      PrincipalBalance: 3815.51,
      InterestBalance: 804.93,
      BalanceOutstanding: 4620.44,
      AmountDue: 4620.44,
      NextInstalmentDate: new Date("20-Oct-2013"),
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("25-Nov-2009"),
      EstimatedCost: 98030.02,
      EstimatedCostToAssessments: 98030.02,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 13,
    },
    {
      SchemeNumber: 32039,
      SchemeName: "Ryan Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 67,
      PrincipalBalance: 6929.05,
      InterestBalance: 479.79,
      BalanceOutstanding: 7408.84,
      AmountDue: 7408.84,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("25-Aug-2010"),
      SchemeDeclaredOn: new Date("16-Nov-2010"),
      EstimatedCost: 191100,
      EstimatedCostToAssessments: 191100,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 15,
    },
    {
      SchemeNumber: 32040,
      SchemeName:
        "Como St Berrys Rd, Ferres Rd and Part of Madigan Way, Emerald",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 48,
      PrincipalBalance: 46541.95,
      InterestBalance: 3051.29,
      BalanceOutstanding: 49593.24,
      AmountDue: 49593.24,
      NextInstalmentDate: new Date("20-Jul-2014"),
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("27-Jan-2012"),
      SchemeDeclaredOn: new Date("19-Apr-2011"),
      ActualCost: 417627.1,
      ActualCostToAssessments: 417627.1,
      EstimatedCost: 485500,
      EstimatedCostToAssessments: 485500,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      Comment:
        "Construction of pavement, kerb & chanel and associated drainage",
      SchemeId: 16,
    },
    {
      SchemeNumber: 32042,
      SchemeName: "Paton Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 5,
      PrincipalBalance: 357.29,
      BalanceOutstanding: 357.29,
      AmountDue: 357.29,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("1-Feb-2012"),
      SchemeDeclaredOn: new Date("15-Mar-2011"),
      EstimatedCost: 51498.2,
      EstimatedCostToAssessments: 51498.2,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 18,
    },
    {
      SchemeNumber: 32043,
      SchemeName: "George and Mary Street Bunyip Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 31,
      PrincipalBalance: 99921.22,
      InterestBalance: 10143.53,
      BalanceOutstanding: 110064.75,
      AmountDue: 110064.75,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("28-Jun-2012"),
      SchemeDeclaredOn: new Date("17-May-2011 "),
      SchemeCommencedOn: new Date("28-Jun-2012"),
      EstimatedCost: 229000,
      EstimatedCostToAssessments: 229000,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 20,
    },
    {
      SchemeNumber: 32044,
      SchemeName: "Mill St Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 17,
      PrincipalBalance: 61099,
      InterestBalance: 4256.02,
      BalanceOutstanding: 65355.02,
      AmountDue: 65355.02,
      DefaultNumberOfInstalments: 0,
      SchemeInitiatedOn: new Date("28-Mar-2014"),
      SchemeDeclaredOn: new Date("1-Jul-2013 "),
      SchemeCommencedOn: new Date("1-Jul-2013"),
      ActualCost: 184819.54,
      ActualCostToAssessments: 184819.54,
      EstimatedCost: 189400,
      EstimatedCostToAssessments: 189400,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 21,
    },
    {
      SchemeNumber: 32045,
      SchemeName: "Moody Street Koo Wee Rup Special Charge Schemel 00",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 3,
      PrincipalBalance: 68850.8,
      BalanceOutstanding: 68850.8,
      AmountDue: 68850.8,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("31-Jul-2016"),
      EstimatedCost: 100633,
      EstimatedCostToAssessments: 100633,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 22,
    },
    {
      SchemeNumber: 32046,
      SchemeName: "McBride Road Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 16,
      PrincipalBalance: 181230.39,
      BalanceOutstanding: 181230.39,
      AmountDue: 181230.39,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("31-Jul-2016"),
      SchemeCommencedOn: new Date("12-Mar-2016"),
      EstimatedCost: 265492.05,
      EstimatedCostToAssessments: 265492.05,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 23,
    },
    {
      SchemeNumber: 32047,
      SchemeName: "Island Road KWR Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 28,
      PrincipalBalance: 91114.93,
      BalanceOutstanding: 91114.93,
      AmountDue: 91114.93,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("10-Dec-2016"),
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 24,
    },
    {
      SchemeNumber: 32048,
      SchemeName: "Wadsley Avenue Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 16,
      PrincipalBalance: 65244.77,
      BalanceOutstanding: 65244.77,
      AmountDue: 65244.77,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("10-Dec-2016"),
      EstimatedCost: 133000,
      EstimatedCostToAssessments: 133000,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 25,
    },
    {
      SchemeNumber: 32049,
      SchemeName: "Wheeler Road Maryknoll Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 8,
      PrincipalBalance: 117024.88,
      BalanceOutstanding: 117024.88,
      AmountDue: 109713.82,
      InterestRate: 2.83,
      DefaultNumberOfInstalments: 28,
      SchemeInitiatedOn: new Date("28-Jul-2017"),
      EstimatedCost: 127709.18,
      EstimatedCostToAssessments: 127709.18,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 26,
    },
    {
      SchemeNumber: 32050,
      SchemeName: "Peet Street Industrial Area Special Charge Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 41,
      PrincipalBalance: 10298823.93,
      BalanceOutstanding: 10298823.93,
      AmountDue: 10298823.93,
      DefaultNumberOfInstalments: 16,
      SchemeInitiatedOn: new Date("14-Aug-2018"),
      SchemeDeclaredOn: new Date("15-May-2018"),
      EstimatedCost: 10298823.95,
      EstimatedCostToAssessments: 10298823.95,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 27,
    },
    {
      SchemeNumber: 32051,
      SchemeName: "New Scheme",
      SchemeType: "Private Streets Scheme",
      NumberOfAccounts: 0,
      DefaultNumberOfInstalments: 16,
      SchemeInitiatedOn: new Date("16-Dec-2021"),
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 28,
    },
    {
      SchemeNumber: 32052,
      SchemeName: "Drainage",
      SchemeType: "Drainage Scheme",
      NumberOfAccounts: 47,
      PrincipalBalance: 0,
      BalanceOutstanding: 0,
      AmountDue: 0,
      InterestRate: 10,
      NextInterestDate: new Date("14-Feb-2022"),
      NextInstalmentDate: new Date("15-Jan-2022"),
      DefaultNumberOfInstalments: 16,
      SchemeInitiatedOn: new Date("16-Dec-2021"),
      SchemeDeclaredOn: new Date("6-Dec-2021 "),
      SchemeCommencedOn: new Date("16-Dec-2021"),
      EstimatedCost: 30000,
      EstimatedCostToAssessments: 30000,
      RaiseInterestOnInterest: false,
      InterestRaisingFrequencyInMonths: 3,
      InstalmentFrequencyInMonths: 3,
      DayToPayInstalment: 30,
      SchemeId: 29,
    },
  ],
};
