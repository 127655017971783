import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { FINANCEMETHOD } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";

export const isVisibleFinanceTransaction = (settings: SettingsMap) => {
  const financeMethod =
    getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
    ) ?? FINANCEMETHOD.None;

  const isEnableUpToGlassFinance = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
  );

  const isShowFinanceTransactionAccordion =
    isEnableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct;

  return isShowFinanceTransactionAccordion;
};
