import { LocalityResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const searchWastewaterLocality = async (
  searchText: string
): Promise<APIResponse<LocalityResult[]>> => {
  try {
    return await CoreAPIService.getClient().get<LocalityResult[]>(
      `/api/core/internal/locality`,
      {
        params: {
          valueSearch: searchText,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
