import { parseNumber } from "@telerik/kendo-intl";

export const getColorStyleForCurrency = (
  value: string | undefined,
  type: string
) => {
  if (parseNumber(value ?? "") > 0) {
    return type + "-accent";
  } else if (parseNumber(value ?? "") < 0) {
    return type + "-error";
  } else {
    return type + "-disabled";
  }
};
