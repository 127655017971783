import { SITE_USER_ROUTE } from "@common/pages/profile/constant";
import { Svc_HomepagePageUser } from "@common/pages/settings/homepage/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_HomepagePageUser>();

export const colsSideUsers: IColumnFields[] = [
  {
    title: "Site Users",
    field: nameOf("DisplayName"),
    linkTo: (dataItem: Svc_HomepagePageUser) => {
      return `${SITE_USER_ROUTE}/${dataItem.ID}`;
    },
    isGrow: true,
  },
];
