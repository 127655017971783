import { apiCoreLookupValidate } from "@app/core/components/common/utils";
import { NSWPointServiceSuggestions } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/new-address/validation-adress-search/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadAddressValidationSearch = async (
  streetName: string
): Promise<APIResponse<NSWPointServiceSuggestions | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreLookupValidate(streetName)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
