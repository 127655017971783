import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { deferredDutyListRoute } from "./list/route";
import { noticeRunsListRoute } from "./notice-runs/route";

export const deferredDutyRoute: ICCRoute = {
  path: "deferred-duty",
  name: "Deferred Duty",
  enum: eMenuAction.DeferredDuty_Folder,
  children: [
    deferredDutyListRoute,
    noticeRunsListRoute,
    { path: ":id(new|[0-9]+)", component: require("./[id]/_index").default },
  ],
};
