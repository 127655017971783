import { CertificateAreOfResponsibilities } from "@app/products/property/certificates/[id]/components/child-screens/general/components/area-of-responsibilites/_index";
import { DTO_Certificate } from "@app/products/property/certificates/[id]/model";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { DocumentsIssuedGrid } from "@app/products/property/components/grid/document-issued-grid/_index";
import { nameOfLov } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CommunityProperty } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";

interface Props {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DTO_Certificate>();
export const PropertyCertificateFormElement = observer(
  ({ formRenderProps }: Props): ReactElement => {
    const { certificateLov, areaOfResponsibility, certificateId } =
      useCertificateStore();
    const { valueGetter, onChange } = formRenderProps;
    const { isLLS } = CommunityProperty.getFlagOfStates();

    return (
      <FormElement className="cc-property-certificate-form-elements">
        <section className="cc-field-group">
          <label className="cc-label">Applicant</label>
          <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
            <div className="cc-field">
              <label className="cc-label">Reference</label>
              <Field
                name={nameOf("Cert_Applicant_Reference")}
                component={CCInput}
                readOnly
                placeholder="Reference"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Applicant name</label>
              <Field
                readOnly
                name={nameOf("Cert_Applicant_Name")}
                component={CCInput}
                placeholder="Applicant name"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Address</label>
              <Field
                name={nameOf("Cert_Applicant_Address")}
                placeholder="Address"
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Locality</label>
              <Field
                name={nameOf("Cert_Applicant_Locality")}
                component={CCInput}
                readOnly
                placeholder="Locality"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">State</label>
              <Field
                name={nameOf("Cert_Applicant_State")}
                component={CCInput}
                readOnly
                placeholder="State"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Postcode</label>
              <Field
                name={nameOf("Cert_Applicant_Postcode")}
                placeholder="Postcode"
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <Field
                label="Email address"
                name={nameOf("Cert_Applicant_Email_Address")}
                placeholder="Email address"
                readOnly
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={onChange}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Country</label>
              <Field
                name={nameOf("Cert_Applicant_Country_Name")}
                placeholder="Country"
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Applicant SAP ID</label>
              <Field
                name={nameOf("Cert_Applicant_SAP_Number")}
                placeholder="Applicant SAP ID"
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Proposed settlement date" />
              <Field
                name={nameOf("Proposed_Settlement_Date")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Purchaser name" />
              <Field
                name={nameOf("Purchaser_Names")}
                placeholder="Purchaser name"
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Purchaser address" />
              <Field
                name={nameOf("Purchaser_Addresses")}
                placeholder="Purchaser address"
                component={CCInput}
                readOnly
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <Field
                name={nameOf("Cert_Description")}
                row={3}
                component={CCTextArea}
                readOnly
                placeholder="Description"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Comments</label>
              <Field
                name={nameOf("Cert_Comment")}
                row={3}
                component={CCTextArea}
                readOnly
                placeholder="Comments"
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Request source</label>
              <Field
                name={nameOf("Certificate_Request_Source_Id")}
                component={CCSearchComboBox}
                data={certificateLov?.RequestSource ?? []}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3 ">
            <div className="cc-field">
              <label className="cc-label">Originated on</label>
              <Field
                name={nameOf("Cert_Originated_DateTime")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Originated by</label>
              <Field
                name={nameOf("Cert_Originated_By")}
                component={CCInput}
                placeholder="Originated by"
                readOnly
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Expected completion</label>
              <Field
                name={"Expected_Completion"} //just calculate in UX
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Date required</label>
              <Field
                name={nameOf("Cert_Required_By_DateTime")}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is urgent</label>
              <Field
                name={nameOf("Cert_Is_Urgent")}
                component={CCSwitch}
                checked={valueGetter(nameOf("Cert_Is_Urgent"))}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3 ">
            <div className="cc-field">
              <label className="cc-label">Issued on</label>
              <Field
                name={nameOf("Cert_Issued_DateTime")}
                disabled
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Issued by</label>
              <Field
                name={nameOf("Cert_Issued_By")}
                readOnly
                placeholder="Issued by"
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Elapsed days</label>
              <div className="cc-custom-input-group">
                <Field
                  disabled
                  name={nameOf("Elapsed_Days")}
                  component={NumericTextBox}
                  min={0}
                />
                <div className="cc-input-group-postfix">days</div>
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Receipt number</label>
              <Field
                name={nameOf("Cert_Receipt_Number")}
                readOnly
                component={CCInput}
                placeholder="Receipt number"
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Receipt amount</label>
              <Field
                name={nameOf("Cert_Receipt_Amount")}
                disabled
                component={CCCurrencyInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Certificate ID</label>
              <Field
                name={nameOf("CertificateId")}
                placeholder="Certificate ID"
                readOnly
                component={CCInput}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            {!isLLS && (
              <div className="cc-field">
                <label className="cc-label">Area of responsibilities</label>
                <CertificateAreOfResponsibilities
                  data={areaOfResponsibility ?? []}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Documents issued</label>
              <DocumentsIssuedGrid
                componentNumber={eComponent.Certificate}
                id={certificateId}
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
