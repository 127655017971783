import { ExportSchemeNoticeDialog } from "@app/products/property/schemes/notice-runs/[id]/components/forms/existed/components/action-bar/dialogs/export-scheme-notice/_index";
import { NoticeRunDetail } from "@app/products/property/schemes/notice-runs/[id]/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { encodeBase64, saveAs } from "@progress/kendo-file-saver";
import React, { useState } from "react";

interface IExportSchemeNoticeButtonProps {
  disabled?: boolean;
  noticeRunInfo: NoticeRunDetail | undefined;
}
export const ExportSchemeNoticeButton = ({
  disabled,
  noticeRunInfo,
}: IExportSchemeNoticeButtonProps) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <>
      <CCNavButton
        disabled={disabled}
        title="Export Scheme Notices"
        onClick={() => setShowDialog(true)}
      />
      {showDialog && (
        <ExportSchemeNoticeDialog
          NoticeRunInfo={noticeRunInfo}
          onSubmit={(fileName: string) => {
            const dataURI = "data:text/xml;base64," + encodeBase64("XML FIle");
            saveAs(dataURI, fileName);
            setShowDialog(false);
          }}
          onClose={() => {
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};
