import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useReOPenMeetingButtonStore } from "@common/pages/actions/components/action-bar/buttons/reopen-meeting/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IReOpenMeetingButtonProps {
  disabled?: boolean;
}

export const ReOpenMeetingButton = observer(
  ({ disabled }: IReOpenMeetingButtonProps) => {
    const { action, onSubmit } = useActionStore();
    const { isLoading } = useReOPenMeetingButtonStore();

    const isVisible = useMemo(() => {
      return (
        action?.ActionType &&
        !(
          action?.ActionType.IsRegulatoryAction &&
          action?.ActionType.IsRegulatoryAction === true
        ) &&
        !(
          action?.ActionType.IsAssessment &&
          action?.ActionType.IsAssessment === true
        ) &&
        (action?.Status_ENUM === ACTIONSTATUS.APPROVED ||
          action?.Status_ENUM === ACTIONSTATUS.COMPLETE) &&
        action?.ActionType.Flag_IsMeeting
      );
    }, [action]);

    return isVisible ? (
      <CCNavButton
        title="Reopen meeting"
        isLoading={isLoading}
        onClick={onSubmit}
        disabled={disabled}
        name={ActionSubmitActions.ReOpenMeeting}
      />
    ) : null;
  }
);
