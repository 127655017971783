import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "global-settings",
      name: "Global Settings",
      component: require("./global-settings/_category/_index").default,
    },
    //23-03-2023 Hide portal-setting for all products
    // portalSettingsRoute,
    {
      path: "complaints-settings",
      name: "Complaints Settings",
      component: require("./complaints-settings/_index").default,
    },
    {
      path: "system-logs",
      name: "System Logs",
      component: require("./system-logs/_index").default,
    },
    {
      path: "windows-logs",
      name: "Windows Logs",
      component: require("./windows-logs/_index").default,
    },
    {
      path: "recycle-bin",
      name: "Recycle Bin",
      component: require("./recycle-bin/_index").default,
    },
    {
      path: "job-queue",
      name: "Job Queue",
      component: require("./job-queue/_index").default,
    },
    {
      path: "job-queue-failures",
      name: "Job Queue - Failures",
      component: require("./job-queue-failures/_index").default,
    },
    {
      path: "task-scheduling",
      name: "Task Scheduling",
      component: require("./task-scheduling/_index").default,
    },
  ],
};
