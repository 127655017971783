import { TownPlanningApiUrl } from "@app/products/town-planning/config";
import {
  TownPlanningRegisterRequest,
  TownPlanningResponse,
} from "@app/products/town-planning/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { globalStoreInstance } from "@common/stores/global/store";

export const getTownPlanningSummaryById = async (
  id: string
): Promise<APIResponse<TownPlanningResponse | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${TownPlanningApiUrl.GET_TOWNPLANNING.replace("{id}", id)}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postTownPlanning = async (
  requestBody: TownPlanningRegisterRequest
): Promise<APIResponse<TownPlanningResponse | undefined>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `${globalStoreInstance.allSetting.baseAPIUrl}${TownPlanningApiUrl.CREATE_TOWNPLANNING}`,
      requestBody
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putTownPlanning = async (
  requestBody: TownPlanningRegisterRequest
): Promise<APIResponse<TownPlanningResponse | undefined>> => {
  try {
    const response = await CoreAPIService.getClient().put(
      `${
        globalStoreInstance.allSetting.baseAPIUrl
      }${TownPlanningApiUrl.UPDATE_TOWNPLANNING.replace(
        "{id}",
        String(requestBody.Application_ID)
      )}`,
      requestBody
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
