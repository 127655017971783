import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { EJournalDefaultType } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const DeferredDutyAddToJournalButton = observer(() => {
  const { gridSelectedRows, isEmptyData, lastSelectedId } =
    useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const { setListDialog } = usePropertyWorkflowStore();
  const { deferredDuty } = useDeferredDutyStore();
  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
  }, [gridSelectedRows.length, id, isEmptyData]);

  return (
    <CCNavButton
      title={"Add to journal"}
      disabled={isDisabled}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_Create,
            data: {},
            props: {
              journalTypeDefault: EJournalDefaultType.AddJournal,
              isFromDeferredDuty: true,
              accountId: deferredDuty?.AccountId || lastSelectedId,
            },
          },
        ]);
      }}
    />
  );
});
