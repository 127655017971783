import { eventEmitter } from "@/App";
import { deleteCategory } from "@app/products/crms/system-admin/categories/components/action-bar/buttons/delete-category/api";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IDeleteCategoryButtonProps {
  disabled?: boolean;
}

export const DeleteCategoryButton = observer(
  ({ disabled }: IDeleteCategoryButtonProps) => {
    const { gridSelectedIds, clearSelectedItems } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
      setIsLoading(true);
      const response = await deleteCategory(gridSelectedIds);
      setIsLoading(false);

      eventEmitter.emit(CCGridEventType.RefreshOData);
      if (isSuccessIdentityPacket(response)) {
        const data = response?.data;
        pushNotification({
          type: "success",
          title:
            data?.Notifications && data.Notifications?.length > 0
              ? data.Notifications
              : "Category deleted successfully",
        });
      } else {
        pushNotification({
          autoClose: false,
          type: "error",
          title:
            response.data?.Errors ??
            response.error ??
            "Category deleted was failed.",
        });
      }
      clearSelectedItems();
    };

    return (
      <CCNavButton
        title="Delete"
        onClick={handleDelete}
        disabled={disabled || gridSelectedIds?.length === 0}
        isLoading={isLoading}
      />
    );
  }
);
