import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import React from "react";

interface IPropertyActionBarNavDropdownProps {
  category: string;
  disabled?: boolean;
}

export const SettingsActionBarNavDropdown = ({
  category,
  disabled,
}: IPropertyActionBarNavDropdownProps) => {
  return <CCActionBarNavDropdownNew disabled={disabled} />;
};
