import { getUUID } from "@common/utils/common";
import {
  ODataGreedyLoaderResponse,
  SelectTypeEnum,
  SelectionLoaderEvent,
} from "./model";

export const CCGridEventType = {
  RefreshOData: getUUID(),
  ODataGreedyLoaderChanged: "odata-greedy-loader-changed",
};

// [Grid] Pager: Use to show text "items/page" in the pager-info
export const WIDTH_TO_SHOW_LABEL_ITEM_PER_PAGE = 570;

export const MARGIN_LEFT_MESSAGE_NO_RECORD = "-15px";

export enum EGroupHeaderProps {
  AGGREGATES = "aggregates",
  GROUP_ID = "groupId",
}

export const defaultSelectionLoaderEvent: SelectionLoaderEvent = {
  available: false,
  peek: {
    type: SelectTypeEnum.PAGE,
    count: 0,
  },
  process: async function dummy(): Promise<ODataGreedyLoaderResponse> {
    return {
      successful: false,
      data: [],
      error: "No fetching happened.",
    };
  },
};
