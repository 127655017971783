import { ICCRoute } from "@common/constants/ICCRoute";

export const nonComplianceRoute: ICCRoute = {
  path: "non-compliance",
  name: "Non Compliance",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "notices-by-date",
      name: "Notices By Date",
      component: require("./notices-by-date/_index").default,
    },
    {
      path: "notices-by-due-status",
      name: "Notices By Due Status",
      component: require("./notices-by-due-status/_index").default,
    },
  ],
};
