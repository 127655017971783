import { ICCRoute } from "@common/constants/ICCRoute";

export const otherRoute: ICCRoute = {
  path: "other",
  name: "Other",
  children: [
    {
      path: "interested-parties",
      name: "Interested Parties",
      component: require("./interested-parties/_index").default,
    },
    {
      path: "fi-request",
      name: "FI Request",
      component: require("./fi-request/_index").default,
    },
    {
      path: "advertising",
      name: "Advertising",
      component: require("./advertising/_index").default,
    },
    {
      path: "meetings",
      name: "Meetings",
      component: require("./meetings/_index").default,
    },
    {
      path: "interested-parties-all",
      name: "Interested Parties All",
      component: require("./interested-parties-all/_index").default,
    },
  ],
};
