import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import {
  convertToDataOrigin,
  getLabel,
  transformDataFieldsMapping,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./index.scss";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const data = dataSetting.SettingFields;
    const { valueGetter, onChange } = formRenderProps;
    const dataEnableProducts = data[3].ValueDisplayDetails;
    const listLabel = data[1].Value.split("|");

    const dataFields = transformDataFieldsMapping(
      valueGetter(
        ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_JornalFieldMapping.toString()
      ),
      dataEnableProducts
    );

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                {data[0].Title}
                {data[0]?.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={data[0].FieldName}
                component={CCInput}
                placeholder={data[0].Title}
                disabled={!isEditing}
              />
            </div>
          </div>

          <div className="cc-journal-fields-mapping">
            <label className="cc-label">{data[2].Title}</label>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  {listLabel.map((label: string) => (
                    <th>{label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataFields &&
                  dataFields?.map(
                    (item: string[], i: number) =>
                      item?.[0] && (
                        <tr>
                          <td>
                            <b>{getLabel(item?.[0], dataEnableProducts)}</b>
                          </td>
                          <td>
                            <Field
                              name={`${item?.[0]}_1`}
                              component={CCInput}
                              disabled={!isEditing}
                              onChange={(e: any) => {
                                dataFields[i][1] = e.value;
                                onChange(
                                  ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_JornalFieldMapping.toString(),
                                  {
                                    value: convertToDataOrigin(dataFields),
                                  }
                                );
                                onChange(`${item?.[0]}_1`, {
                                  value: e.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${item?.[0]}_2`}
                              component={CCInput}
                              disabled={!isEditing}
                              onChange={(e: any) => {
                                dataFields[i][2] = e.value;
                                onChange(
                                  ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_JornalFieldMapping.toString(),
                                  {
                                    value: convertToDataOrigin(dataFields),
                                  }
                                );
                                onChange(`${item?.[0]}_2`, {
                                  value: e.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              name={`${item?.[0]}_3`}
                              component={CCNumericTextBox}
                              disabled={!isEditing}
                              onChange={(e: any) => {
                                dataFields[i][3] = e.value;
                                onChange(
                                  ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_JornalFieldMapping.toString(),
                                  {
                                    value: convertToDataOrigin(dataFields),
                                  }
                                );
                                onChange(`${item?.[0]}_3`, {
                                  value: e.value,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
);
