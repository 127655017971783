import { history } from "@/AppRoutes";
import { colHMPremisesAlert } from "@app/products/hm/config";
import { HMPremisesView } from "@app/products/hm/premises/model";
import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { isEmpty, isNil } from "lodash";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesPCBA = [
  {
    field: nameOfHMPremisesView("RegNo"),
    title: "Registration Number",
    locked: true,
    handleOnClick: (rowValue: HMPremisesView) => {
      // Confirmed with Zach and Rebecca in the catch-up meeting 03/10/2024
      // If the RegNo is empty or null, click on the blank cell to navigate
      if (isEmpty(rowValue?.RegNo) || isNil(rowValue?.RegNo)) {
        history.push(`${PREMISES_MANAGE_ROUTE}/${rowValue.ID}`);
      }
    },
    linkTo: (dataItem: HMPremisesView) => {
      return `${PREMISES_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  ...colHMPremisesAlert,
  {
    field: nameOfHMPremisesView("TradingName"),
    title: "Trading Name",
  },
  {
    field: nameOfHMPremisesView("Address"),
    title: "Address",
  },
  {
    field: nameOfHMPremisesView("Area"),
    title: "Area",
  },
  {
    field: nameOfHMPremisesView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfHMPremisesView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfHMPremisesView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfHMPremisesView("PCBA_BodyPiercing"),
    title: "Body Piercing",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_BodyTreatments"),
    title: "Body Treatments",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_ColonicIrrigation"),
    title: "Colonic Irrigation",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_CosmeticTattooing"),
    title: "Cosmetic Tattooing",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_CuticleCutting"),
    title: "Cuticle Cutting",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Dermabrasion"),
    title: "Dermabrasion",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Accupuncture"),
    title: "Dry Needling/Accupuncture",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_EarPiercing"),
    title: "Ear Piercing",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Electrolysis"),
    title: "Electrolysis",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_EyebrowEyelashTinting"),
    title: "Eyebrow/Eyelash Tinting",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_EyelashExtensions"),
    title: "Eyelash Extensions",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Facials"),
    title: "Facials",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_FacialsMakeup"),
    title: "Facials/Makeup",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Footscraping"),
    title: "Footscraping",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Footspa"),
    title: "Footspa",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Hairdressing"),
    title: "Hairdressing",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_HairExtensions"),
    title: "Hair Extensions",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_HennaTattoos"),
    title: "Henna Tattoos",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_LaserTreatment"),
    title: "Laser Treatment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Makeup"),
    title: "Makeup",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_ManicurePedicure"),
    title: "Manicure/Pedicure",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_NailTreatment"),
    title: "Nail Treatment",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_SprayTan"),
    title: "Spray Tan",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Tattooing"),
    title: "Tattooing",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_TemporaryMakeUp"),
    title: "Temporary Make Up",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Threading"),
    title: "Threading",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Waxing"),
    title: "Waxing",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Wigs"),
    title: "Wigs",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Other"),
    title: "Other",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_PRP"),
    title: "PRP",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("PCBA_Microblading"),
    title: "Microblading",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfHMPremisesView("RefNo"),
    title: "Reference Number",
  },
  {
    field: nameOfHMPremisesView("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfHMPremisesView("DebtorNo"),
    title: "Debtor Number",
  },
  {
    field: nameOfHMPremisesView("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfHMPremisesView("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("Email"),
    title: "Email",
  },
  {
    field: nameOfHMPremisesView("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfHMPremisesView("PostalAddress2"),
    title: "Postal Address 2",
  },
  {
    field: nameOfHMPremisesView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfHMPremisesView("NoEmployees"),
    title: "Employees",
  },
  {
    field: nameOfHMPremisesView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfHMPremisesView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("Fax"),
    title: "Fax",
  },
  {
    field: nameOfHMPremisesView("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfHMPremisesView("ProprietorAddress"),
    title: "Proprietor Address",
  },
];
