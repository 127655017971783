import { ApplicationCategory } from "@app/products/town-planning/[id]/model";
import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import {
  IPPROfficerAllocation,
  PPROfficerAllocation,
} from "@app/products/town-planning/ppr/components/action-bar/dialogs/allocate-to-officer/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { SiteUser } from "@app/products/waste-water/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

interface IAllocateToOfficerDialogProps {
  onClose: () => void;
  onSubmit: (data: PPROfficerAllocation) => void;
  isLoading: boolean;
  initialData: IPPROfficerAllocation;
  notification?: IAppNotificationItemAddProps;
}

export const AllocateToOfficerDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoading,
    initialData,
    notification,
  }: IAllocateToOfficerDialogProps) => {
    const nameOf = nameOfFactory<IPPROfficerAllocation>();
    const { ppr } = usePSARStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      const data: any = { ...event.values };
      data.ApplicationCategories = data?.ApplicationCategories?.map(
        (itemCategory: ApplicationCategory) =>
          itemCategory.ApplicationCategory_ID
      );
      onSubmit(data as PPROfficerAllocation);
    };

    useEffect(() => {
      if (!notification) {
        notificationRef.current?.resetNotifications();
      } else {
        notificationRef.current?.pushNotification(notification);
      }
    }, [notification]);

    return (
      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialData}
        render={(formRenderProps: FormRenderProps) => {
          const { onChange } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                titleHeader={"Officer Assessment"}
                onClose={onClose}
                maxWidth={"45%"}
                height={"auto"}
                disabled={isLoading}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Applicant proposal
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("ReasonForPermit")}
                            component={CCTextArea}
                            rows={3}
                            validator={requiredValidator}
                            onInputChange={(event: InputChangeEvent) => {
                              onChange("ReasonForPermit", {
                                value: event.target.value,
                              });
                            }}
                            readOnly={false}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Planner
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={"Planner"}
                            component={InputPickerSearch}
                            onChange={(data: SiteUser) => {
                              if (
                                ppr?.PPRDetails.TeamLeader_ID &&
                                ppr?.PPRDetails.TeamLeader_ID ===
                                  data?.Contact_ID
                              ) {
                                notificationRef.current?.pushNotification({
                                  title:
                                    "Officer cannot be the delegated approver",
                                  type: "warning",
                                });
                              } else {
                                onChange("Planner_ID", {
                                  value: data?.Contact_ID ?? null,
                                });
                                onChange("Planner", {
                                  value: data?.DisplayName ?? null,
                                });
                              }
                            }}
                            placeholder={"Planner"}
                            options={optionInputPickerOfficer}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Comments</label>
                          <Field
                            name={nameOf("AllocationComments")}
                            component={CCTextArea}
                            rows={3}
                            placeholder={"Comments"}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button className="cc-dialog-button" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={!formRenderProps.valid}
                      onClick={formRenderProps.onSubmit}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
