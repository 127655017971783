import { ILovNewSwineBrand } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/swine-brands/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewSwineBrandDialogStore {
  private _lov?: ILovNewSwineBrand = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get lov() {
    return toJS(this._lov);
  }
  setLov = (lov: ILovNewSwineBrand) => {
    runInAction(() => {
      this._lov = lov;
    });
  };
}

const newSwineBrandDialogStoreContext = createContext(
  new NewSwineBrandDialogStore()
);
export const useNewSwineBrandDialogStoreContext = () => {
  return useContext(newSwineBrandDialogStoreContext);
};
