import { PPRPSAReferral } from "@app/products/town-planning/ppr/psa-referrals/register/model";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { ICalendarData } from "@components/cc-calendar/model";
import { setHours } from "date-fns";

//TODO enhance later just fix temporary
export const processHearingCalendarData = (
  rawData: PPRPSAReferral[]
): ICalendarData[] => {
  return rawData.map((data: PPRPSAReferral) => {
    const newData: ICalendarData = {
      id: data?.ID,
      title: data?.CalendarSubject,
      start: new Date(data?.DirectionsHearingDate as Date),
      end: setHours(new Date(data?.HearingDateEnd as Date), 23),
    };
    return newData;
  });
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `PPR - PSA Referrals ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.ApplicationNumber,
    selectedRow.SiteAddress + ", " + selectedRow.Locality,
  ]);
};
