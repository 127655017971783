import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ReplaceCertificateButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { reLoadCertificate } = useCertificateStore();

  return (
    <CCNavButton
      title="Replace Certificate"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Certificate_Replace,
            data: {},
            props: {
              reloadPage: (notification: IAppNotificationItemAddProps) => {
                reLoadCertificate(notification);
              },
              isRedirectManagePage: false,
            },
          },
        ]);
      }}
    />
  );
});
