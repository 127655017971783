import {
  DTO_Certificate_LOVs,
  DTO_Workflow_CertificateModify,
  RequestCertificateModifyObj,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessModifyCertificate = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_CertificateModify
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_CertificateModify>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificatemodify/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialModifyCertificate =
  | [
      APIResponse<DTO_Certificate_LOVs>,
      APIResponse<DTO_Workflow_CertificateModify>
    ]
  | APIResponse<DTO_Certificate_LOVs | DTO_Workflow_CertificateModify>
  | undefined;
export const getInitialModifyCertificate = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined = 0,
  isFromActionList: boolean = false,
  certificateId: number | undefined = 0
): Promise<IGetInitialModifyCertificate> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Certificate_LOVs>(
        `api/property/int/certificate/lovs?workflowType=${workflowType}`
      ),
      isFromActionList
        ? getWorkflowModifyCertificate(workflowDraftId)
        : getNewWorkflowModifyCertificate({ CertificateId: certificateId }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowModifyCertificate = async (
  requestObj: RequestCertificateModifyObj | object = {}
): Promise<APIResponse<DTO_Workflow_CertificateModify>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/int/workflow/certificatemodify/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowModifyCertificate = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_CertificateModify>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/certificatemodify/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
