import { DTO_Journal_TabDetails } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getJournalDetailById = async (
  journalId: number | string
): Promise<DTO_Journal_TabDetails | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Journal_TabDetails>(
        `/api/property/internal/journal/${journalId}/tabdetails`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
