import { mockStatus } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/withdraw-reason/mock";
import { IPSARWithdraw } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/withdraw-reason/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface IWithdrawCommentDialogProps {
  onClose: () => void;
  onSubmit: (data: IPSARWithdraw) => void;
  notifications?: IAppNotificationItem[];
  isLoading?: boolean;
}

const nameOf = nameOfFactory<IPSARWithdraw>();

export const WithdrawReasonDialog = observer(
  ({
    onClose,
    onSubmit,
    isLoading,
    notifications,
  }: IWithdrawCommentDialogProps) => {
    const { pprId } = usePSARStore();
    const initialData: IPSARWithdraw = {
      ApplicationID: pprId,
      Comment: "",
      ReasonKWD: undefined,
    };
    const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
      onSubmit(event.values as IPSARWithdraw);
    };
    return (
      <Form
        onSubmitClick={handleOnSubmitClick}
        initialValues={initialData}
        render={(formRenderProps: FormRenderProps) => {
          const { valid } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                height="auto"
                maxWidth="25%"
                titleHeader={"Withdrawn Reason"}
                onClose={onClose}
                disabled={isLoading}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification defaultNotifications={notifications} />
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Status
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("ReasonKWD")}
                            textField="Value"
                            dataItemKey="Key"
                            component={CCSearchComboBox}
                            data={mockStatus}
                            validator={requiredValidator}
                            isUseDefaultOnchange
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Details</label>
                          <Field
                            name={nameOf("Comment")}
                            component={CCTextArea}
                            rows={5}
                            placeholder="Details"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      disabled={!valid || isLoading}
                      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                      onClick={formRenderProps.onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
