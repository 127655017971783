import { ICON_FORMAT } from "@common/constants/common-format";
import {
  SiteUser_CorporateAuth_Link,
  Svc_PickSiteUser,
} from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { Svc_SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfSecurityTemplate = nameOfFactory<Svc_SecurityTemplate>();
const nameOfSiteUserCorporateAuth =
  nameOfFactory<SiteUser_CorporateAuth_Link>();
const nameOfPickSiteUser = nameOfFactory<Svc_PickSiteUser>();

export const colSecurityTemplates: IColumnFields[] = [
  {
    title: "Name",
    field: nameOfSecurityTemplate("TemplateName"),
    isGrow: true,
  },
];

export const colSiteUsers: IColumnFields[] = [
  {
    title: "Name",
    field: nameOfSiteUserCorporateAuth("SiteUser_DisplayName"),
    locked: true,
    isGrow: true,
  },
  {
    title: "Send Notification",
    field: nameOfSiteUserCorporateAuth("SendNotification"),
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
];

export const colPickSiteUsers: IColumnFields[] = [
  {
    title: "Name",
    field: nameOfPickSiteUser("Name"),
    locked: true,
  },
  {
    title: "Position",
    field: nameOfPickSiteUser("Position"),
  },
  {
    title: "Email",
    field: nameOfPickSiteUser("Email"),
  },
  {
    title: "Phone",
    field: nameOfPickSiteUser("Phone"),
  },
];
