import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { DTO_SendBack_LOVs } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/model";

export const getWorkflowSendBackReasons = async (): Promise<
  APIResponse<DTO_SendBack_LOVs | undefined>
> => {
  try {
    const response = await CoreAPIService.getClient().get(
      "api/property/int/workflow/sendbacklovs"
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
