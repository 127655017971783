import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import {
  DTO_Workflow_ModifyTitle,
  RequestInputObj,
} from "@app/products/property/titles/list/components/action-bar/form-steps/modify-title/model";
import { DTO_Title_LOVs } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessModifyTitle = async (
  workflowType: WorkflowProcessMode,
  titleData: DTO_Workflow_ModifyTitle
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyTitle>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifytitle/process/${workflowType}`,
      titleData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowModifyTitle = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyTitle>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifytitle/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataTitle =
  | [APIResponse<DTO_Title_LOVs>, APIResponse<DTO_Workflow_ModifyTitle>]
  | APIResponse<DTO_Title_LOVs | DTO_Workflow_ModifyTitle>
  | undefined;
export const getInitialDataTitle = async (
  requestObjFromSpatial: RequestInputObj,
  workflowDraftId: number | undefined
): Promise<IGetInitialDataTitle> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Title_LOVs>(
        `api/property/internal/workflow/title/lovs`
      ),
      !isNil(workflowDraftId)
        ? getWorkflowModifyTitle(workflowDraftId)
        : postNewWorkflowModifyTitle(requestObjFromSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewWorkflowModifyTitle = async (
  requestObjSpatial: RequestInputObj | object = {}
): Promise<APIResponse<DTO_Workflow_ModifyTitle>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifytitle/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
