import { VO_Assessment } from "@app/products/property/assessments/list/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Assessment>();

//TODO mappers are waiting
export const colAssessmentPicker: IColumnFields[] = [
  {
    field: nameOf("Assessment_Number"),
    title: "Number",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Owners_Name_Address"),
    title: "Owners Name Address",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
