import { IComment } from "@app/products/hm/components/action-bar/buttons/add-comment/model";
import {
  IGetHMPremiseById,
  InspectionRegisterForm,
  PremiseRegisterForm,
} from "@app/products/hm/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { globalStoreInstance } from "@common/stores/global/store";
import { appNotificationStore } from "@components/cc-app-notification/store";

export const getHMPremiseInfo = async (
  id: string
): Promise<IGetHMPremiseById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get(
        `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/premise/${id}`
      ),
      CoreAPIService.getClient().get(
        `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/premiselovs`
      ),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPremise = async (id: string): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/premise/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPremiseLOVs = async (): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/premiselovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPremiseRecords = async (
  id: string,
  recordType: number
): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/relatedtotal/${id}/${recordType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postPremise = async (
  premise: PremiseRegisterForm
): Promise<APIResponse | undefined> => {
  try {
    return await CoreAPIService.getClient().post(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/premise/0`,
      premise
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putPremise = async (
  premise: PremiseRegisterForm
): Promise<APIResponse | undefined> => {
  try {
    return await CoreAPIService.getClient().put(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/HM/Internal/premise/${premise.Premises_ID}`,
      premise
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postComment = async (comment: IComment) => {
  try {
    const response = await CoreAPIService.getClient().post(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/comment/${comment.ID}`,
      comment
    );
    if (!isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Comment added failed",
        type: "error",
      });
    } else {
      appNotificationStore.pushNotification({
        title: "Comment added successfully",
        type: "success",
      });
    }
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    appNotificationStore.pushNotification({
      autoClose: false,
      title: errorResponse.data?.Message ?? "Comment added failed",
      type: "error",
    });
  }
};

//@TODO Recheck when inspections API was fixed
// export const getHMInspectionsInfo = async (
//   id: string,
//   inspectionType: string
// ): Promise<IGetHMInspectionById> => {
//   try {
//     return await Promise.all([
//       CoreAPIService.getClient().get(
//         `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/inspection/${id}`
//       ),
//       CoreAPIService.getClient().get(
//         `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/inspectionlovs/${id}/${inspectionType}`
//       ),
//     ]);
//   } catch (error) {
//     const errorResponse: APIResponse = error as any;
//     return {
//       ...errorResponse,
//       error: errorResponse.data?.Message,
//     };
//   }
// };

export const getInspection = async (id: string): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/inspection/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInspectionLOVs = async (
  id: string,
  inspectionType: string
): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/inspectionlovs/${id}/${inspectionType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postInspection = async (
  data: InspectionRegisterForm
): Promise<APIResponse> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/inspection/`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putInspection = async (
  id: number,
  data: InspectionRegisterForm
): Promise<APIResponse> => {
  try {
    const response = await CoreAPIService.getClient().put(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/inspection/${id}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

// MOCK:TODO
