import {
  MAIL_MERGE_OPTIONS_FORM_STEP,
  OptionsFormStep,
} from "@app/core/mail-merge/form-steps/components/options/_index";
import {
  MAIL_MERGE_TEMPLATE_FORM_STEP,
  TemplateFormStep,
} from "@app/core/mail-merge/form-steps/components/template/_index";
import {
  MAIL_MERGE_WORKFLOW_FORM_STEP,
  WorkflowFormStep,
} from "@app/core/mail-merge/form-steps/components/workflow/_index";
import { defaultOptions } from "@app/core/mail-merge/form-steps/config";
import { IMailMergeSelection } from "@app/core/mail-merge/form-steps/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCFormStep, ICCFormStepRender } from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import React, { useMemo } from "react";

interface IMailMergeDialog {
  isLoading?: boolean;
  showCollateOption?: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  mailMergeSelection: IMailMergeSelection;
  productType?: PRODUCT_TYPE_NUMBER;
}
export const MailMergeDialog = ({
  showCollateOption = true,
  isLoading,
  onClose,
  onSubmit,
  mailMergeSelection,
  productType,
}: IMailMergeDialog) => {
  const steps: IStep[] = [
    {
      label: "Template",
      initialValues: {
        ...mailMergeSelection,
        ProductType: productType,
      },
      component: TemplateFormStep,
      visible: true,
      key: MAIL_MERGE_TEMPLATE_FORM_STEP,
    },
    {
      label: "Options",
      initialValues: defaultOptions,
      options: { isShowCollateOption: showCollateOption },
      component: OptionsFormStep,
      visible: true,
      key: MAIL_MERGE_OPTIONS_FORM_STEP,
    },
    {
      label: "Workflow",
      component: WorkflowFormStep,
      visible: true,
      key: MAIL_MERGE_WORKFLOW_FORM_STEP,
    },
  ];
  const titleHeader = useMemo(() => {
    if (mailMergeSelection.SelectedRecordIDs?.length) {
      return `Mail Merge (${mailMergeSelection.SelectedRecordIDs?.length} Premises Selected)`;
    } else return `Mail Merge Documents`;
  }, [mailMergeSelection.SelectedRecordIDs]);

  return (
    <CCFormStep
      onSubmit={onSubmit}
      initialSteps={steps}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="80%"
          maxHeight="85%"
          titleHeader={titleHeader}
          onClose={onClose}
          disabled={isLoading}
          bodyElement={
            <div className="cc-mail-merge-stepper">{renderProps.children}</div>
          }
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              <Button className={"cc-dialog-button"} onClick={onClose}>
                Cancel
              </Button>
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              <Button
                themeColor="primary"
                id="cc-next-step-button"
                disabled={renderProps.nextButton.disabled}
                className={"cc-dialog-button"}
                iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                onClick={renderProps.nextButton.onClick}
              >
                {renderProps.nextButton.label}
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
