import { ICCRoute } from "@common/constants/ICCRoute";

export const applicationsRoute: ICCRoute = {
  path: "applications",
  name: "Applications",
  children: [
    {
      path: "lodged",
      name: "Lodged",
      component: require("./lodged/_index").default,
    },
    {
      path: "draft",
      name: "Draft",
      component: require("./draft/_index").default,
    },
    {
      path: "archived",
      name: "Archived",
      component: require("./archived/_index").default,
    },
    {
      path: "all-applications",
      name: "All Applications",
      component: require("./all-applications/_index").default,
    },
    {
      path: "submitted",
      name: "Submitted",
      component: require("./submitted/_index").default,
    },
    {
      path: "no-services",
      name: "No Services",
      component: require("./no-services/_index").default,
    },
    {
      path: "search",
      name: "Search",
      component: require("./search/_index").default,
    },
    {
      path: "reports",
      name: "Reports",
      component: require("./reports/_index").default,
    },
    {
      path: "classic-reports",
      name: "Classic Reports",
      component: require("./classic-reports/_index").default,
    },
  ],
};
