import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Svc_GetView_CommunicationQueueList } from "@common/pages/settings/communication/queue/model";
import { SETTINGS_COMMUNICATION_QUEUE_ROUTE } from "@common/pages/settings/communication/queue/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_GetView_CommunicationQueueList>();

export const colCommunicationQueue: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem: Svc_GetView_CommunicationQueueList) =>
      `${SETTINGS_COMMUNICATION_QUEUE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("ParentDescription"),
    title: "Description",
  },
  {
    field: nameOf("Officer_DisplayName"),
    title: "Send By",
  },
  {
    field: nameOf("DateQueued"),
    title: "Date Queued",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CommunicationType"),
    title: "Communication Type",
  },
  {
    field: nameOf("RecipientEmail"),
    title: "To",
  },
  {
    field: nameOf("Subject"),
    title: "Subject",
  },
  {
    field: nameOf("IsSent"),
    title: "Sent",
  },
];

export const orgStructureSettingEnum = [
  ECorporateSettingsField.CorporateSettings_OrgStructureLevels,
  ECorporateSettingsField.CorporateSettings_LabelOrg1,
  ECorporateSettingsField.CorporateSettings_LabelOrg2,
  ECorporateSettingsField.CorporateSettings_LabelOrg3,
  ECorporateSettingsField.CorporateSettings_LabelOrg4,
  ECorporateSettingsField.CorporateSettings_LabelOrg5,
];
