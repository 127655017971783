import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import {
  optionInputPickerOverlay,
  optionInputPickerZone,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/detail/config";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CoreKeyword } from "@common/models/coreKeyword";
import { getStringDisplayFromArrayObject } from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isArray } from "lodash";
import React, { useRef } from "react";

export const DETAIL_FORM_STEP = "Detail";
export const DetailStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  setStepsVisible,
}: IFormStepElement) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { onChange } = formRenderProps;
  const MAX_LENGTH_ASSESSMENT_ID = 10;

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Assess number</label>
            <Field
              name={"Address.PropertyAssessment.PID_No"}
              component={CCInput}
              placeholder={"Assess number"}
              onChange={(event: any) => {
                onChange("Address.PropertyAssessment.PID_No", {
                  value: event.value,
                });
                onChange("Address.AssessmentNo", { value: event.value });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Assessment ID</label>
            <Field
              name={"Address.PropertyAssessment.Assessment_ID"}
              component={CCNumericTextBox}
              placeholder={"Assessment ID"}
              maxLength={MAX_LENGTH_ASSESSMENT_ID}
              format={NUMBER_FORMAT.NUMBER2}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">RIN</label>
            <Field
              name={"Address.PropertyAssessment.RIN"}
              component={CCInput}
              placeholder={"RIN"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Ward</label>
            <Field
              name={"Address.PropertyAssessment.Ward"}
              component={CCInput}
              placeholder={"Ward"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Area</label>
            <Field
              name={"Address.PropertyAssessment.Area"}
              component={CCInput}
              placeholder={"Area"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Parish</label>
            <Field
              name={"Address.PropertyAssessment.Parish"}
              component={CCInput}
              placeholder={"Parish"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Crown allotment</label>
            <Field
              name={"Address.PropertyAssessment.CrownAllotment"}
              component={CCInput}
              placeholder={"Crown allotment"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Section</label>
            <Field
              name={"Address.PropertyAssessment.Section"}
              component={CCInput}
              placeholder={"Section"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Planning reference number</label>
            <Field
              name={"Address.PropertyAssessment.PlanRef"}
              component={CCInput}
              placeholder={"Planning reference number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">LP/PS</label>
            <Field
              name={"Address.PropertyAssessment.LP_PS"}
              component={CCInput}
              placeholder={"LP/PS"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Volume</label>
            <Field
              name={"Address.PropertyAssessment.Volume"}
              component={CCInput}
              placeholder={"Volume"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Folio</label>
            <Field
              name={"Address.PropertyAssessment.Folio"}
              component={CCInput}
              placeholder={"Folio"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">County</label>
            <Field
              name={"Address.PropertyAssessment.County"}
              component={CCInput}
              placeholder={"County"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Township</label>
            <Field
              name={"Address.PropertyAssessment.Township"}
              component={CCInput}
              placeholder={"Township"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Valuation number</label>
            <Field
              name={"Address.PropertyAssessment.Valuation_Number"}
              component={CCInput}
              placeholder={"Valuation number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Certificate of title</label>
            <Field
              name={"Address.PropertyAssessment.CertOfTitle"}
              component={CCInput}
              placeholder={"Certificate of title"}
            />
          </div>

          <div className="cc-field cc-col-span-2">
            <label className="cc-label">Parcel reference</label>
            <Field
              name={"Address.PropertyAssessment.Parcel_Reference"}
              component={CCInput}
              placeholder={"Parcel reference"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Parcel ID</label>
            <Field
              name={"Address.PropertyAssessment.Parcel_ID"}
              component={CCNumericTextBox}
              placeholder={"Parcel ID"}
              format={NUMBER_FORMAT.NUMBER2}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Land size</label>
            <Field
              name={"Address.PropertyAssessment.Assessment_LandSize"}
              component={CCNumericTextBox}
              placeholder={"Land size"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Block</label>
            <Field
              name={"Address.PropertyAssessment.Block"}
              component={CCInput}
              placeholder={"Block"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Lot</label>
            <Field
              name={"Address.PropertyAssessment.LotNo"}
              component={CCInput}
              placeholder={"Lot"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Zone</label>
            <Field
              name={"Zone_Display"}
              placeholder={"Zone"}
              component={InputPickerSearch}
              nameDisplayMerge="Name"
              onChange={(value: CoreKeyword[]) => {
                if (!value) {
                  onChange("ZoneList", { value: [] });
                  onChange("Zone_Display", {
                    value: "",
                  });
                  onChange("Address.PropertyAssessment.Zones", {
                    value: "",
                  });
                } else {
                  let newZone = undefined;
                  if (!isArray(value)) {
                    newZone = [value];
                  } else {
                    newZone = [...value];
                  }
                  onChange("ZoneList", { value: newZone });
                  onChange("Zone_Display", { value: newZone });
                  onChange("Address.PropertyAssessment.Zones", {
                    value: getStringDisplayFromArrayObject(newZone, "Name"),
                  });
                }
              }}
              options={optionInputPickerZone}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Overlay</label>
            <Field
              name={"Overlay_Display"}
              placeholder={"Overlay"}
              nameDisplayMerge="Name"
              component={InputPickerSearch}
              onChange={(value: CoreKeyword[]) => {
                if (!value) {
                  onChange("OverlayList", { value: [] });
                  onChange("Overlay_Display", {
                    value: "",
                  });
                  onChange("Address.PropertyAssessment.Overlay", {
                    value: "",
                  });
                } else {
                  let newOverlay = undefined;
                  if (!isArray(value)) {
                    newOverlay = [value];
                  } else {
                    newOverlay = [...value];
                  }
                  onChange("OverlayList", { value: newOverlay });
                  onChange("Overlay_Display", { value: newOverlay });
                  onChange("Address.PropertyAssessment.Overlay", {
                    value: getStringDisplayFromArrayObject(newOverlay, "Name"),
                  });
                }
              }}
              options={optionInputPickerOverlay}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field ">
            <label className="cc-label">Legal description</label>
            <Field
              name={"Address.PropertyAssessment.Legal_Description"}
              component={CCInput}
              placeholder={"Legal description"}
            />
          </div>
        </div>
      </section>
    </>
  );
};
