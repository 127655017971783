import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { isEqual } from "lodash";
import React from "react";

export const NumericTextBoxCustomCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  format = CURRENCY_FORMAT.CURRENCY1,
}: ICustomEditCell) => {
  return (
    <CCNumericTextBox
      value={value}
      format={format}
      min={isEqual(format, NUMBER_FORMAT.NUMBER2) ? 0 : undefined}
      onChange={(event: NumericTextBoxChangeEvent) => {
        if (onChange) {
          let newValue = event.value ?? 0;
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: newValue,
          });
        }
      }}
    />
  );
};
