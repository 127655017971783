export const mockAssessment = [
  {
    AssessmentNumber: 300000,
    AccountAttachedPropertyAddress: ", ",
    AccountAttachedAccountNames: "Common Property-Body Corporate",
    AssessmentId: 12345,
  },
  {
    AssessmentNumber: 300001,
    AccountAttachedPropertyAddress: ", ",
    AccountAttachedAccountNames: "Common Property-Body Corporate",
    AssessmentId: 123456,
  },
];

export const mockMeterAssociation = {
  MeterId: 5879,
  MeterNumber: 125,
  MeterAssociationPropertyAddress: ",",
  MeterAssociationAccountNames: "",
};

export const billingGroupData = [
  { Key: 1, Value: "Monthly Billing" },
  { Key: 2, Value: "Standard Periodic Billing" },
];
