import { apiCoreGetHelpDocument, apiCorePostHelpDocument } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";
import { ICCHelpDocument } from "@common/pages/settings/lookups/help-documents/_id/model";

export const getHelpDocumentById = async (
  attachmentId: number
): Promise<APIResponse<ICCHelpDocument | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ICCHelpDocument>(
      apiCoreGetHelpDocument(attachmentId)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postHelpDocument = async (
  requestBody: ICCHelpDocument
): Promise<APIResponse<ResponsePacket>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      apiCorePostHelpDocument(),
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

