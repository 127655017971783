import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfSteps {
  Details = "MasterProperty",
  Associations = "Assessments",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Details,
  EKeysOfSteps.Associations,
];

export interface DTO_MasterProperty_LOVs {
  ReasonForChange: DTO_LOV[];
  PropertyType: DTO_LOV[];
  Locality: DTO_LOV[];
  Workflow_Memo_Category: DTO_LOV[];
}

export interface DTO_Workflow_MasterProperty_Edit {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_MasterProperty_Edit;
}

export interface DTO_WorkflowDetail_MasterProperty_Edit {
  Master_Property_Id: number | null;
  MasterProperty: DTO_MasterProperty;
  Assessments: DTO_MP_Assessment[];
  ReasonForChange: number | null;
  SelectedSecondaryWorkflow: number[];
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}

export interface DTO_MasterProperty {
  Master_Property_Id: number | null;
  MP_Assess_Number: number | null;
  MP_Valuation_Number: number | null;
  MP_Property_Name: string;
  Master_Property_Type_Id: number;
  MP_Property_Address: string;
  Locality_Id: number | null;
  MP_Description: string;
  MP_Effective_Date: string | null;
  MP_Letter_Date: string | null;
}

export interface RequestMasterPropertyObj {
  MasterPropertyId: number | null;
  IsAssessmentsActiveOnly: boolean | null;
}

export interface DTO_MasterProperty_Assessment {
  Master_Property_Id: number | null;
  Assessments: DTO_MP_Assessment[];
}

export interface DTO_MP_Assessment {
  Assessment_Id: number;
  Assess_Number: number | null;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement: number | null;
  Unit_Entitlement_Percentage: number | null;
  Unit_Type: string;
  Unit_Type_Percentage: number | null;
  Assess_Balance: number;
  Assess_Balance_Outstanding: number | null;
  Assess_Being_Postponed: boolean;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Assessment_Status: number;
  Primary_Assess_Land_Use_Name: string;
  Valuation_1: number | null;
  Valuation_2: number | null;
  Valuation_3: number | null;
  Valuation_4: number | null;
  Valuation_5: number | null;
  Valuation_6: number | null;
  Valuation_7: number | null;
  Valuation_8: number | null;
  Valuation_9: number | null;
  Valuation_10: number | null;
  Valuation_11: number | null;
  Valuation_12: number | null;
  Valuation_13: number | null;
  Valuation_14: number | null;
  Valuation_15: number | null;
  Valuation_16: number | null;
  Valuation_17: number | null;
  Valuation_18: number | null;
  Valuation_19: number | null;
  Valuation_20: number | null;
}

export interface RequestMasterPropertyObj {
  MasterPropertyId: number | null;
  IsAssessmentsActiveOnly: boolean | null;
}
