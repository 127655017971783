export class BuildingStormwaterResponse {
  ID?: number = 0;
  RegisterNumber?: number = 0;
  Status?: string = "";
  Contact?: string = "";
  ContactAddress?: string = "";
  ContactContact?: string = "";
  Address?: string = "";
  Owners?: string = "";
  Details?: string = "";
  Officer?: string = "";
  AppNo?: string = "";
  RefNumber?: string = "";
  FileNumber?: string = "";
  Lodged?: Date;
  Description?: string = "";
  DecisionType?: string = "";
  CreatedDate?: Date;
  DecisionDate?: Date;
  OSFees?: number = 0;
  DebtorNumber?: string = "";
  PlannerAssessment?: string;
}
export class BuildingStormwaterRequest extends BuildingStormwaterResponse {}

export class BuildingStormwaterFormData extends BuildingStormwaterResponse {}

export class BuildingStormwaterRecordState {
  addresses: number = 0;
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  related: number = 0;
}
