export const mockTownPlanning = [
  {
    ID: 3251,
    ApplicationNumber: 20996,
    SiteAddress: "495 Julianne Dale",
    Locality: "Saint Martin",
    Status: "1080p",
    AppealDays: "Thu Jul 01 2021 06:38:11 GMT+0700 (Indochina Time)",
    Planner:
      "I'll back up the primary SSL driver, that should sensor the CSS program!",
    ReasonForPermit:
      "connecting the driver won't do anything, we need to override the primary FTP interface!",
    AppType: "TCP",
    AssessmentNo: 22644,
    LodgedDate: "Fri Jul 31 2020 04:19:38 GMT+0700 (Indochina Time)",
    Postcode: 48451,
    StatusWithSubStatus:
      "You can't transmit the protocol without bypassing the neural PNG application!",
    Decision:
      "Try to bypass the SQL capacitor, maybe it will generate the online monitor!",
    DecisionDate: "Sat Dec 05 2020 19:14:01 GMT+0700 (Indochina Time)",
    DecisionType: "quantifying",
    FinalDecision:
      "If we hack the capacitor, we can get to the SDD feed through the redundant TCP matrix!",
    FinalDecisionDate: "Wed Jul 01 2020 04:56:28 GMT+0700 (Indochina Time)",
    FinalDecisionType: "indexing",
    Applicant: "Leuschke",
    ApplicantAddress: "086 Brad Corners",
    ApplicantContact: "Hartmann",
    Contact: "Halvorson",
    PreferredAddress: "89415 Beier Run",
    PreferredContact: "947.892.3897 x3997",
    FileNo: 54701,
    RIN: "XML",
    Ward: "generating",
    Rating: 91084,
    CostOfWorks: 59725,
    StatDays: "Sun Dec 06 2020 03:04:41 GMT+0700 (Indochina Time)",
    ContactPerson: "Renner",
    LandDivision: true,
    PlanningApplicationNumber: 5602,
    SpearRef: "COM",
    POSNo: 85179,
    ReferredDate: "Sun Nov 22 2020 19:40:35 GMT+0700 (Indochina Time)",
    CertifiedDate: "Wed Nov 18 2020 00:19:21 GMT+0700 (Indochina Time)",
    RecertifiedDate: "Wed Nov 25 2020 09:09:11 GMT+0700 (Indochina Time)",
    ComplianceDate: "Fri Aug 28 2020 15:50:12 GMT+0700 (Indochina Time)",
    TitlesOffice: "Thu Jul 30 2020 22:41:04 GMT+0700 (Indochina Time)",
    Lots: "dolor",
    CommercialLots:
      "Praesentium ipsa quia quaerat sunt exercitationem illo assumenda recusandae distinctio.",
    SpaceRequired: "Id est incidunt.",
    Valuation: "Quidem excepturi quas officiis.",
    POSDue: "Tue Apr 06 2021 00:31:48 GMT+0700 (Indochina Time)",
    Estate: "deleniti",
    SpearReference: "cum",
    ApplicantName: "Weimann",
    ApplicationStatus: "Ex corrupti vel.",
    ApplicationType: "et",
    CouncilName: "distinctio",
    FullAddress: "8124 Leann Mountain",
    SubmittedDate: "Sun Jan 10 2021 14:58:33 GMT+0700 (Indochina Time)",
    CouncilReferenceNumber: 76110,
    IsUpdated: false,
    ApplicationNo: 89432,
    AppealNo: 28177,
    Officer: "Douglas",
    CouncilRepresentation: "cupiditate",
    DateLodged: "Wed Oct 28 2020 15:23:08 GMT+0700 (Indochina Time)",
    ApplicationOfficerDecision: "Similique laudantium iusto eos facilis at ad.",
    ApplicationDecision: "aperiam",
    ApplicationDateDecided:
      "Thu Mar 19 2020 05:38:17 GMT+0700 (Indochina Time)",
    DateAppealed: "Sun Jul 05 2020 19:04:08 GMT+0700 (Indochina Time)",
    Grounds: "nam",
    HearingDate: "Wed Nov 10 2021 17:54:13 GMT+0700 (Indochina Time)",
    HearingType: "et",
    TribunalMember: "Halvorson",
    DecisionStatus:
      "Sit aliquid deserunt voluptatem delectus voluptatum asperiores expedita quisquam non.",
    KeyIssues: 68645,
    DecisionPolicyIssues: "Rerum voluptates nihil assumenda nam quasi cum.",
    CouncilPosition: "cumque",
    CouncilPositionDate: "Sun Oct 17 2021 14:21:20 GMT+0700 (Indochina Time)",
    HearingCost: 2257,
    ReferenceNumber: 73635,
    Date_Lodged: "Thu Dec 03 2020 12:59:54 GMT+0700 (Indochina Time)",
    Register_Number: 51492,
    Address: "5025 Waelchi Turnpike",
    Type: "non",
    Description:
      "I'll override the 1080p HTTP pixel, that should matrix the SQL matrix!",
    Days: "Tue Dec 29 2020 00:30:53 GMT+0700 (Indochina Time)",
    Date_Due: "Wed Jan 27 2021 04:33:49 GMT+0700 (Indochina Time)",
    Date_Complete: "Fri Apr 03 2020 02:18:41 GMT+0700 (Indochina Time)",
    LegalDescription:
      "Try to bypass the HTTP protocol, maybe it will transmit the wireless array!",
    Outcome: "sit",
    CRMSEventReferenceNumber: 16315,
    IPAddressUnitNo: "24035-4629",
    IPAddressStreetNo: "69050-7683",
    IPAddressStreetName: "Jose Land",
    IPAddressSuburbStatePostcode: "38286",
    IPPostalAddress: "qui",
    IPName: "Dickens",
    IPCompany: "Torphy - Bradtke",
    IPAddress: "copy_schemas_solutions.lzh",
    Attachment_Date: "Mon Nov 30 2020 23:55:23 GMT+0700 (Indochina Time)",
    Attachment_Title: "architecto",
    IPCategory: "mobile",
    IPType: "iure",
    Category: "multi-byte",
    Details: "Nihil sed aut.",
    StatusOnly: "repellat",
    DateRequested: "Sat Apr 04 2020 12:19:18 GMT+0700 (Indochina Time)",
    DateDue: "Sat Aug 07 2021 06:06:03 GMT+0700 (Indochina Time)",
    DateInfoReceived: "Mon Dec 07 2020 08:06:35 GMT+0700 (Indochina Time)",
    DateDecisionToAdvertise:
      "Sat May 30 2020 15:00:16 GMT+0700 (Indochina Time)",
    DateAdvertisingStarts: "Wed Sep 02 2020 20:37:39 GMT+0700 (Indochina Time)",
    NumberOfSigns: 86395,
    NumberOfLetters: 99256,
    NumberOfPublications: 2717,
    MeetingDate: "Tue Oct 26 2021 22:38:31 GMT+0700 (Indochina Time)",
    MeetingLocation: "4433 Batz Roads",
    MeetingType: "at",
    MeetingCouncillor: "Becker",
    MeetingOfficer: "ullam",
    RefNo: 16137,
    DateTarget: "Thu Apr 29 2021 00:15:43 GMT+0700 (Indochina Time)",
    Source: "perspiciatis",
    Location: "821 Farrell Divide",
    RequestedBy: "Koelpin",
    ContactDetails: "Dignissimos placeat expedita quasi velit.",
    DueStatus: "A laborum veritatis.",
    DateRecorded: "Fri Feb 28 2020 00:15:59 GMT+0700 (Indochina Time)",
    RecordedBy: "Rodriguez",
    OrgStructure: "facere",
    ActionOfficer: "quos",
    Coordinator: "Herman",
    DateWork: "Sat Dec 26 2020 09:19:13 GMT+0700 (Indochina Time)",
    FileNumber: 67700,
    EnquiryRefNo: 63375,
    Event_ReferenceNoAdditional: "Nisi ut quis rerum dolorem ab recusandae.",
    OnBehalfOf: "Laboriosam nam qui quis repudiandae quia dolor.",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: true,
    CouncilAuthorisation: "Auer",
    CouncilAuthorisationDate:
      "Thu Sep 24 2020 18:56:15 GMT+0700 (Indochina Time)",
    ApplicantType: "rerum",
    Gazzetted: "Wed Sep 09 2020 01:50:32 GMT+0700 (Indochina Time)",
    Withdrawn: "Sat Jun 13 2020 13:13:21 GMT+0700 (Indochina Time)",
    Contact2: "554.040.1923 x79916",
    LevelOfEnforcement: "voluptate",
    Number: 76781,
    Risk: "doloribus",
    DateOffence: "Sun Mar 08 2020 14:57:58 GMT+0700 (Indochina Time)",
    DateServed: "Sat Nov 28 2020 02:13:06 GMT+0700 (Indochina Time)",
    DateRevoked: "Tue Oct 13 2020 14:11:01 GMT+0700 (Indochina Time)",
    ContactName: "Bosco",
    CreatedDate: "Wed Jan 29 2020 22:09:27 GMT+0700 (Indochina Time)",
    FeeType: "quaerat",
    AmountIssued: 87595,
    AmountOutstanding: 64286,
    CostCode: 68722,
    AmountPaid: 2787,
    ReceiptNumber: 74446,
    ReceiptDate: "Fri May 08 2020 18:40:32 GMT+0700 (Indochina Time)",
    ReceiptedBy: "cum",
    Method: "corrupti",
    InvoiceNumber: 16843,
    DebtorNumber: 57858,
    SecurePay_Reference: "Ut sint qui adipisci culpa possimus excepturi.",
    MerchantReference:
      "Temporibus voluptate autem accusamus quis autem sed illo.",
    VicSmart: "ab",
    OsBalance: "658.00",
    ePlanningCategory: "fugit",
    Owner: "Larkin",
    ePlanningLodgedBy: "voluptates",
    Comments:
      "Try to copy the SMTP monitor, maybe it will hack the virtual array!",
    RecordedDate: "Sun Mar 15 2020 05:31:51 GMT+0700 (Indochina Time)",
    Date_Recorded: "Wed Nov 04 2020 04:12:30 GMT+0700 (Indochina Time)",
    EPlanning_Categories:
      "I'll quantify the multi-byte JBOD port, that should application the PNG feed!",
    Title:
      "I'll copy the primary GB array, that should port the RSS application!",
    FileName: "quia",
    ParentRefNo: 65227,
    ParentDesc:
      "Aut nesciunt hic non quibusdam consequatur necessitatibus autem.",
    Name: "Watsica",
    IsHidden: true,
    IsSelectable: true,
    IsActive: true,
    HasOtherInputs: false,
    CostOfWorkv: 17298,
    SubdivisionLots: 13224,
    CategoryType: "officia",
    ApplicationCategory_Name: "harum",
    ApplicationCategory_Description:
      "Reiciendis quidem ut et et quo qui minus consequuntur.",
    TownPlanning: false,
    PSA: false,
    OAs: false,
    Code: 77477,
    TargetDays: "Thu Nov 18 2021 11:24:28 GMT+0700 (Indochina Time)",
    SortIndex: 93831,
    ConditionCode: 30104,
    Condition: "facilis",
    Multiplier: "eius",
    RateType: "iure",
    Value: 98103,
    MinimumValue: 21802,
    Regulation: "cumque",
    Class: "sint",
    ApplyTo_Name: "Bergnaum",
    DeletedOn: "Sun Aug 23 2020 02:02:42 GMT+0700 (Indochina Time)",
    DeletedBy: "Lakin",
    ComplaintNo: 3499,
    DateReceived: "Sat Oct 24 2020 04:30:11 GMT+0700 (Indochina Time)",
    DateOccured: "Sat Oct 17 2020 17:49:22 GMT+0700 (Indochina Time)",
    SubCategory: "multi-byte",
    ComplaintStatus:
      "Try to synthesize the XSS panel, maybe it will copy the primary system!",
    DateClosed: "Mon Apr 20 2020 16:16:54 GMT+0700 (Indochina Time)",
    Group:
      "If we hack the bus, we can get to the TCP microchip through the online JSON hard drive!",
  },
  {
    ID: 7659,
    ApplicationNumber: 87781,
    SiteAddress: "85117 Auer Ridge",
    Locality: "Seychelles",
    Status: "auxiliary",
    AppealDays: "Fri Dec 17 2021 05:56:33 GMT+0700 (Indochina Time)",
    Planner:
      "Use the 1080p SSL card, then you can compress the 1080p bandwidth!",
    ReasonForPermit:
      "You can't quantify the interface without backing up the solid state COM monitor!",
    AppType: "PNG",
    AssessmentNo: 24428,
    LodgedDate: "Mon Jul 13 2020 18:56:13 GMT+0700 (Indochina Time)",
    Postcode: 98927,
    StatusWithSubStatus:
      "compressing the port won't do anything, we need to back up the optical TCP firewall!",
    Decision:
      "Try to parse the JBOD monitor, maybe it will copy the neural capacitor!",
    DecisionDate: "Thu Jul 16 2020 19:01:32 GMT+0700 (Indochina Time)",
    DecisionType: "synthesizing",
    FinalDecision:
      "I'll input the 1080p GB protocol, that should monitor the SDD application!",
    FinalDecisionDate: "Sat Aug 22 2020 13:17:03 GMT+0700 (Indochina Time)",
    FinalDecisionType: "calculating",
    Applicant: "Spencer",
    ApplicantAddress: "9110 Halvorson Lane",
    ApplicantContact: "Fay",
    Contact: "Friesen",
    PreferredAddress: "704 Porter Spring",
    PreferredContact: "1-623-933-7793",
    FileNo: 29197,
    RIN: "AI",
    Ward: "synthesizing",
    Rating: 23808,
    CostOfWorks: 67623,
    StatDays: "Tue Oct 20 2020 09:55:32 GMT+0700 (Indochina Time)",
    ContactPerson: "Strosin",
    LandDivision: false,
    PlanningApplicationNumber: 29009,
    SpearRef: "AGP",
    POSNo: 45637,
    ReferredDate: "Fri Feb 14 2020 21:39:09 GMT+0700 (Indochina Time)",
    CertifiedDate: "Mon May 11 2020 21:07:45 GMT+0700 (Indochina Time)",
    RecertifiedDate: "Sat Oct 17 2020 20:28:41 GMT+0700 (Indochina Time)",
    ComplianceDate: "Mon Nov 16 2020 14:40:17 GMT+0700 (Indochina Time)",
    TitlesOffice: "Fri Dec 04 2020 09:33:20 GMT+0700 (Indochina Time)",
    Lots: "est",
    CommercialLots: "Dolorem dolores in similique molestiae.",
    SpaceRequired: "In est recusandae aspernatur sed molestias enim ea quos.",
    Valuation: "Commodi consequatur doloribus velit dicta.",
    POSDue: "Thu Mar 04 2021 10:05:36 GMT+0700 (Indochina Time)",
    Estate: "ea",
    SpearReference: "doloremque",
    ApplicantName: "Corwin",
    ApplicationStatus: "Provident officiis voluptas sit omnis aliquid.",
    ApplicationType: "quae",
    CouncilName: "dolor",
    FullAddress: "211 Murray Turnpike",
    SubmittedDate: "Sat Dec 26 2020 13:06:07 GMT+0700 (Indochina Time)",
    CouncilReferenceNumber: 59223,
    IsUpdated: false,
    ApplicationNo: 8766,
    AppealNo: 38256,
    Officer: "Schiller",
    CouncilRepresentation: "et",
    DateLodged: "Thu Jul 23 2020 19:21:45 GMT+0700 (Indochina Time)",
    ApplicationOfficerDecision:
      "Vitae placeat doloremque est soluta ducimus esse saepe.",
    ApplicationDecision: "aut",
    ApplicationDateDecided:
      "Sun Nov 15 2020 14:22:35 GMT+0700 (Indochina Time)",
    DateAppealed: "Fri Jan 15 2021 04:06:56 GMT+0700 (Indochina Time)",
    Grounds: "veritatis",
    HearingDate: "Thu Jun 10 2021 00:25:07 GMT+0700 (Indochina Time)",
    HearingType: "saepe",
    TribunalMember: "Shanahan",
    DecisionStatus: "Recusandae ut dolor voluptate quasi rerum nisi ut.",
    KeyIssues: 38617,
    DecisionPolicyIssues:
      "Culpa non voluptas saepe distinctio est molestiae inventore.",
    CouncilPosition: "est",
    CouncilPositionDate: "Fri Apr 02 2021 14:55:58 GMT+0700 (Indochina Time)",
    HearingCost: 1132,
    ReferenceNumber: 74489,
    Date_Lodged: "Sat Sep 05 2020 02:37:51 GMT+0700 (Indochina Time)",
    Register_Number: 98301,
    Address: "4680 Charlotte Land",
    Type: "ut",
    Description: "We need to back up the wireless SDD interface!",
    Days: "Mon Jan 04 2021 13:40:38 GMT+0700 (Indochina Time)",
    Date_Due: "Tue Apr 06 2021 19:09:16 GMT+0700 (Indochina Time)",
    Date_Complete: "Sun Jan 10 2021 07:13:26 GMT+0700 (Indochina Time)",
    LegalDescription:
      "Use the haptic SMS array, then you can calculate the back-end capacitor!",
    Outcome: "libero",
    CRMSEventReferenceNumber: 70205,
    IPAddressUnitNo: "93818-6861",
    IPAddressStreetNo: "02561-6698",
    IPAddressStreetName: "Ruecker Ford",
    IPAddressSuburbStatePostcode: "21902-1265",
    IPPostalAddress: "at",
    IPName: "Gleason",
    IPCompany: "King - Walsh",
    IPAddress: "hybrid.swf",
    Attachment_Date: "Wed Jun 03 2020 02:44:00 GMT+0700 (Indochina Time)",
    Attachment_Title: "sed",
    IPCategory: "solid state",
    IPType: "eos",
    Category: "auxiliary",
    Details: "Enim distinctio dolor et et alias recusandae optio.",
    StatusOnly: "beatae",
    DateRequested: "Thu May 28 2020 10:19:03 GMT+0700 (Indochina Time)",
    DateDue: "Wed Oct 27 2021 10:49:50 GMT+0700 (Indochina Time)",
    DateInfoReceived: "Sat Feb 08 2020 21:15:47 GMT+0700 (Indochina Time)",
    DateDecisionToAdvertise:
      "Thu May 07 2020 12:13:38 GMT+0700 (Indochina Time)",
    DateAdvertisingStarts: "Thu Oct 22 2020 19:11:28 GMT+0700 (Indochina Time)",
    NumberOfSigns: 70210,
    NumberOfLetters: 1092,
    NumberOfPublications: 92058,
    MeetingDate: "Thu Jul 29 2021 09:18:33 GMT+0700 (Indochina Time)",
    MeetingLocation: "2760 Wiza Overpass",
    MeetingType: "repellat",
    MeetingCouncillor: "Sanford",
    MeetingOfficer: "unde",
    RefNo: 15937,
    DateTarget: "Wed Feb 10 2021 22:11:14 GMT+0700 (Indochina Time)",
    Source: "molestias",
    Location: "2427 Kunde Grove",
    RequestedBy: "Dicki",
    ContactDetails: "Ut itaque voluptate consequatur ipsa sit.",
    DueStatus:
      "Exercitationem quo odit iure impedit et architecto est quos voluptatem.",
    DateRecorded: "Sun Jan 17 2021 04:31:27 GMT+0700 (Indochina Time)",
    RecordedBy: "Abernathy",
    OrgStructure: "commodi",
    ActionOfficer: "error",
    Coordinator: "Prohaska",
    DateWork: "Wed Jul 08 2020 20:11:35 GMT+0700 (Indochina Time)",
    FileNumber: 91029,
    EnquiryRefNo: 32210,
    Event_ReferenceNoAdditional: "Earum quod at molestias quibusdam.",
    OnBehalfOf: "Est fugit assumenda omnis quae et molestias.",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: false,
    Flag_VIP: false,
    CouncilAuthorisation: "Gutmann",
    CouncilAuthorisationDate:
      "Wed Oct 28 2020 18:04:05 GMT+0700 (Indochina Time)",
    ApplicantType: "quis",
    Gazzetted: "Mon Jun 29 2020 04:59:55 GMT+0700 (Indochina Time)",
    Withdrawn: "Sun Aug 16 2020 13:16:44 GMT+0700 (Indochina Time)",
    Contact2: "535.949.7893 x6732",
    LevelOfEnforcement: "corporis",
    Number: 83021,
    Risk: "et",
    DateOffence: "Sat Feb 15 2020 19:45:05 GMT+0700 (Indochina Time)",
    DateServed: "Mon Nov 23 2020 04:42:44 GMT+0700 (Indochina Time)",
    DateRevoked: "Sun Dec 20 2020 22:49:49 GMT+0700 (Indochina Time)",
    ContactName: "Gibson",
    CreatedDate: "Sat Aug 29 2020 22:00:09 GMT+0700 (Indochina Time)",
    FeeType: "quia",
    AmountIssued: 96570,
    AmountOutstanding: 89496,
    CostCode: 74662,
    AmountPaid: 19347,
    ReceiptNumber: 10434,
    ReceiptDate: "Thu Sep 17 2020 20:02:55 GMT+0700 (Indochina Time)",
    ReceiptedBy: "eius",
    Method: "autem",
    InvoiceNumber: 96149,
    DebtorNumber: 46256,
    SecurePay_Reference: "Voluptas aperiam quidem.",
    MerchantReference: "Enim sunt excepturi totam velit exercitationem.",
    VicSmart: "fuga",
    OsBalance: "539.00",
    ePlanningCategory: "blanditiis",
    Owner: "Dooley",
    ePlanningLodgedBy: "quis",
    Comments:
      "If we calculate the sensor, we can get to the RSS microchip through the bluetooth RAM card!",
    RecordedDate: "Fri Jun 12 2020 08:07:15 GMT+0700 (Indochina Time)",
    Date_Recorded: "Sat Feb 22 2020 09:33:09 GMT+0700 (Indochina Time)",
    EPlanning_Categories:
      "Try to parse the HTTP transmitter, maybe it will override the primary bandwidth!",
    Title:
      "If we override the port, we can get to the EXE system through the mobile HTTP protocol!",
    FileName: "nemo",
    ParentRefNo: 16857,
    ParentDesc: "Est quo dignissimos accusantium non at non fugiat.",
    Name: "Mills",
    IsHidden: false,
    IsSelectable: true,
    IsActive: true,
    HasOtherInputs: false,
    CostOfWorkv: 79352,
    SubdivisionLots: 11697,
    CategoryType: "tempora",
    ApplicationCategory_Name: "laudantium",
    ApplicationCategory_Description:
      "Iure ducimus quasi repellat quia totam animi dolorem.",
    TownPlanning: true,
    PSA: true,
    OAs: false,
    Code: 41814,
    TargetDays: "Mon Jul 12 2021 13:32:19 GMT+0700 (Indochina Time)",
    SortIndex: 33947,
    ConditionCode: 6432,
    Condition: "cupiditate",
    Multiplier: "aut",
    RateType: "numquam",
    Value: 63049,
    MinimumValue: 12513,
    Regulation: "ab",
    Class: "modi",
    ApplyTo_Name: "Osinski",
    DeletedOn: "Tue Oct 13 2020 15:02:49 GMT+0700 (Indochina Time)",
    DeletedBy: "Herzog",
    ComplaintNo: 95403,
    DateReceived: "Fri Apr 10 2020 12:01:23 GMT+0700 (Indochina Time)",
    DateOccured: "Tue Feb 18 2020 12:06:10 GMT+0700 (Indochina Time)",
    SubCategory: "neural",
    ComplaintStatus:
      "If we compress the capacitor, we can get to the HDD feed through the optical CSS driver!",
    DateClosed: "Sat Feb 15 2020 10:55:41 GMT+0700 (Indochina Time)",
    Group: "We need to program the virtual RAM capacitor!",
  },
  {
    ID: 82915,
    ApplicationNumber: 97942,
    SiteAddress: "0312 Ritchie Inlet",
    Locality: "Bermuda",
    Status: "online",
    AppealDays: "Mon Jul 05 2021 05:09:51 GMT+0700 (Indochina Time)",
    Planner:
      "Use the 1080p EXE pixel, then you can index the neural microchip!",
    ReasonForPermit:
      "If we program the transmitter, we can get to the SAS monitor through the online SMTP monitor!",
    AppType: "SSL",
    AssessmentNo: 16107,
    LodgedDate: "Tue Feb 04 2020 05:56:46 GMT+0700 (Indochina Time)",
    Postcode: 16790,
    StatusWithSubStatus: "We need to synthesize the optical XML capacitor!",
    Decision:
      "generating the capacitor won't do anything, we need to synthesize the back-end SDD program!",
    DecisionDate: "Wed May 27 2020 15:47:13 GMT+0700 (Indochina Time)",
    DecisionType: "synthesizing",
    FinalDecision:
      "Use the neural HTTP bus, then you can parse the back-end capacitor!",
    FinalDecisionDate: "Fri Aug 21 2020 22:29:49 GMT+0700 (Indochina Time)",
    FinalDecisionType: "transmitting",
    Applicant: "Larson",
    ApplicantAddress: "1145 Naomi Ranch",
    ApplicantContact: "Trantow",
    Contact: "Greenfelder",
    PreferredAddress: "290 Adrianna Row",
    PreferredContact: "703-795-1349 x391",
    FileNo: 3877,
    RIN: "RAM",
    Ward: "copying",
    Rating: 86580,
    CostOfWorks: 98353,
    StatDays: "Fri Nov 06 2020 14:11:16 GMT+0700 (Indochina Time)",
    ContactPerson: "Davis",
    LandDivision: false,
    PlanningApplicationNumber: 51472,
    SpearRef: "HTTP",
    POSNo: 15058,
    ReferredDate: "Sun Mar 29 2020 04:06:36 GMT+0700 (Indochina Time)",
    CertifiedDate: "Mon Dec 28 2020 00:25:37 GMT+0700 (Indochina Time)",
    RecertifiedDate: "Sat Mar 21 2020 13:09:02 GMT+0700 (Indochina Time)",
    ComplianceDate: "Thu Feb 20 2020 23:23:41 GMT+0700 (Indochina Time)",
    TitlesOffice: "Tue May 26 2020 19:00:19 GMT+0700 (Indochina Time)",
    Lots: "qui",
    CommercialLots: "Expedita cum error maiores dicta voluptatum.",
    SpaceRequired: "Voluptate fuga quia fuga.",
    Valuation: "Nihil repellendus sed exercitationem aut natus.",
    POSDue: "Thu Apr 15 2021 04:43:59 GMT+0700 (Indochina Time)",
    Estate: "ab",
    SpearReference: "vitae",
    ApplicantName: "Heaney",
    ApplicationStatus: "In consequuntur distinctio ipsa dicta.",
    ApplicationType: "totam",
    CouncilName: "unde",
    FullAddress: "2285 Beatty Court",
    SubmittedDate: "Sun Sep 06 2020 21:43:15 GMT+0700 (Indochina Time)",
    CouncilReferenceNumber: 16143,
    IsUpdated: true,
    ApplicationNo: 86990,
    AppealNo: 81502,
    Officer: "Koss",
    CouncilRepresentation: "et",
    DateLodged: "Mon Dec 21 2020 16:37:04 GMT+0700 (Indochina Time)",
    ApplicationOfficerDecision:
      "Quaerat deserunt non voluptatem et blanditiis ut tenetur quam.",
    ApplicationDecision: "maiores",
    ApplicationDateDecided:
      "Mon Sep 14 2020 23:08:54 GMT+0700 (Indochina Time)",
    DateAppealed: "Mon Sep 14 2020 02:29:02 GMT+0700 (Indochina Time)",
    Grounds: "omnis",
    HearingDate: "Sun Feb 14 2021 06:19:16 GMT+0700 (Indochina Time)",
    HearingType: "vitae",
    TribunalMember: "McDermott",
    DecisionStatus: "Minima enim et officiis.",
    KeyIssues: 53699,
    DecisionPolicyIssues: "Asperiores modi est pariatur tenetur ab.",
    CouncilPosition: "quidem",
    CouncilPositionDate: "Mon Mar 15 2021 19:54:53 GMT+0700 (Indochina Time)",
    HearingCost: 64452,
    ReferenceNumber: 95951,
    Date_Lodged: "Fri Jul 31 2020 04:36:13 GMT+0700 (Indochina Time)",
    Register_Number: 33546,
    Address: "5010 Orrin Cliffs",
    Type: "magni",
    Description: "We need to hack the auxiliary JSON feed!",
    Days: "Thu May 21 2020 12:13:28 GMT+0700 (Indochina Time)",
    Date_Due: "Wed Jan 05 2022 23:01:26 GMT+0700 (Indochina Time)",
    Date_Complete: "Sun Oct 04 2020 22:27:39 GMT+0700 (Indochina Time)",
    LegalDescription:
      "The SDD matrix is down, calculate the wireless array so we can connect the JSON alarm!",
    Outcome: "esse",
    CRMSEventReferenceNumber: 80539,
    IPAddressUnitNo: "11999",
    IPAddressStreetNo: "50085",
    IPAddressStreetName: "Volkman Lights",
    IPAddressSuburbStatePostcode: "62076",
    IPPostalAddress: "reiciendis",
    IPName: "Veum",
    IPCompany: "Feest - Heathcote",
    IPAddress: "center_sql_avon.xyz",
    Attachment_Date: "Sun Apr 05 2020 18:29:46 GMT+0700 (Indochina Time)",
    Attachment_Title: "quis",
    IPCategory: "cross-platform",
    IPType: "est",
    Category: "optical",
    Details: "Excepturi delectus illo porro ut sint aut qui.",
    StatusOnly: "blanditiis",
    DateRequested: "Thu Jan 30 2020 02:58:05 GMT+0700 (Indochina Time)",
    DateDue: "Sun Mar 28 2021 21:10:28 GMT+0700 (Indochina Time)",
    DateInfoReceived: "Thu May 28 2020 09:20:10 GMT+0700 (Indochina Time)",
    DateDecisionToAdvertise:
      "Mon Feb 24 2020 03:14:54 GMT+0700 (Indochina Time)",
    DateAdvertisingStarts: "Mon Jan 04 2021 01:30:52 GMT+0700 (Indochina Time)",
    NumberOfSigns: 93542,
    NumberOfLetters: 81340,
    NumberOfPublications: 62081,
    MeetingDate: "Sun Jan 09 2022 22:28:14 GMT+0700 (Indochina Time)",
    MeetingLocation: "40085 Noemie Villages",
    MeetingType: "ea",
    MeetingCouncillor: "Fritsch",
    MeetingOfficer: "quia",
    RefNo: 75537,
    DateTarget: "Thu Apr 29 2021 13:50:00 GMT+0700 (Indochina Time)",
    Source: "quidem",
    Location: "0853 Norbert Expressway",
    RequestedBy: "Dickinson",
    ContactDetails: "Velit quod officiis vitae hic repellat.",
    DueStatus: "A et ex.",
    DateRecorded: "Thu Mar 19 2020 16:38:29 GMT+0700 (Indochina Time)",
    RecordedBy: "Blick",
    OrgStructure: "consequatur",
    ActionOfficer: "esse",
    Coordinator: "McGlynn",
    DateWork: "Sun Mar 08 2020 22:21:06 GMT+0700 (Indochina Time)",
    FileNumber: 57607,
    EnquiryRefNo: 71587,
    Event_ReferenceNoAdditional:
      "Quasi autem molestias officiis id corrupti placeat id non.",
    OnBehalfOf: "Voluptas repellat optio.",
    Flag_ITSupport: false,
    Flag_Safety: false,
    Flag_Risk: true,
    Flag_VIP: false,
    CouncilAuthorisation: "Rowe",
    CouncilAuthorisationDate:
      "Wed Oct 14 2020 19:51:24 GMT+0700 (Indochina Time)",
    ApplicantType: "reiciendis",
    Gazzetted: "Mon Mar 02 2020 09:21:34 GMT+0700 (Indochina Time)",
    Withdrawn: "Tue Dec 22 2020 12:52:47 GMT+0700 (Indochina Time)",
    Contact2: "377-517-8531",
    LevelOfEnforcement: "itaque",
    Number: 5666,
    Risk: "et",
    DateOffence: "Wed Nov 11 2020 22:27:09 GMT+0700 (Indochina Time)",
    DateServed: "Fri Jul 10 2020 08:29:18 GMT+0700 (Indochina Time)",
    DateRevoked: "Sat Sep 05 2020 17:40:40 GMT+0700 (Indochina Time)",
    ContactName: "Gottlieb",
    CreatedDate: "Sat May 30 2020 23:55:14 GMT+0700 (Indochina Time)",
    FeeType: "tempora",
    AmountIssued: 84913,
    AmountOutstanding: 14295,
    CostCode: 34568,
    AmountPaid: 81177,
    ReceiptNumber: 20292,
    ReceiptDate: "Mon May 18 2020 23:36:45 GMT+0700 (Indochina Time)",
    ReceiptedBy: "nulla",
    Method: "debitis",
    InvoiceNumber: 35461,
    DebtorNumber: 85697,
    SecurePay_Reference:
      "Occaecati officia aut repudiandae consequuntur voluptas.",
    MerchantReference: "Consectetur quidem aliquid sint ut.",
    VicSmart: "perferendis",
    OsBalance: "959.00",
    ePlanningCategory: "eos",
    Owner: "Moen",
    ePlanningLodgedBy: "quidem",
    Comments:
      "I'll override the cross-platform SSL capacitor, that should circuit the CSS panel!",
    RecordedDate: "Fri Apr 10 2020 10:35:01 GMT+0700 (Indochina Time)",
    Date_Recorded: "Mon Apr 13 2020 10:29:47 GMT+0700 (Indochina Time)",
    EPlanning_Categories:
      "Use the digital GB monitor, then you can compress the solid state port!",
    Title:
      "If we hack the bandwidth, we can get to the USB circuit through the cross-platform USB driver!",
    FileName: "architecto",
    ParentRefNo: 75170,
    ParentDesc: "Rerum eum dignissimos.",
    Name: "Walter",
    IsHidden: true,
    IsSelectable: false,
    IsActive: true,
    HasOtherInputs: false,
    CostOfWorkv: 50344,
    SubdivisionLots: 80867,
    CategoryType: "adipisci",
    ApplicationCategory_Name: "et",
    ApplicationCategory_Description:
      "Exercitationem fugit nihil qui ipsa adipisci adipisci et.",
    TownPlanning: true,
    PSA: false,
    OAs: true,
    Code: 37683,
    TargetDays: "Thu Sep 30 2021 04:58:45 GMT+0700 (Indochina Time)",
    SortIndex: 31224,
    ConditionCode: 65925,
    Condition: "autem",
    Multiplier: "velit",
    RateType: "quasi",
    Value: 97831,
    MinimumValue: 70909,
    Regulation: "praesentium",
    Class: "et",
    ApplyTo_Name: "Cremin",
    DeletedOn: "Sun Aug 02 2020 15:33:02 GMT+0700 (Indochina Time)",
    DeletedBy: "DuBuque",
    ComplaintNo: 23003,
    DateReceived: "Sat Nov 07 2020 06:19:42 GMT+0700 (Indochina Time)",
    DateOccured: "Fri Oct 09 2020 19:16:31 GMT+0700 (Indochina Time)",
    SubCategory: "wireless",
    ComplaintStatus:
      "Try to navigate the RAM alarm, maybe it will compress the wireless application!",
    DateClosed: "Tue Jun 23 2020 11:40:55 GMT+0700 (Indochina Time)",
    Group:
      "I'll parse the neural RSS bandwidth, that should sensor the CSS sensor!",
  },
];

export const mockInputPicker = [
  {
    ID: "1",
    CategoryName: "Refined Concrete Mouse",
    TargetDays: 24737,
    TownPlanning: true,
    Certification: true,
    Regulation: "Baby",
    Class: "Class 1",
    Description:
      "Try to program the SDD capacitor, maybe it will input the online transmitter!",
    ApplyTo: "Auto Loan Account",
  },
  {
    ID: "2",
    CategoryName: "Intelligent Metal Car",
    TargetDays: 23367,
    TownPlanning: true,
    Certification: false,
    Regulation: "Shoes",
    Class: "Class 2",
    Description:
      "The HTTP pixel is down, override the haptic bus so we can calculate the IB application!",
    ApplyTo: "matrix",
  },
  {
    ID: "3",
    CategoryName: "Small Cotton Chair",
    TargetDays: 62348,
    TownPlanning: false,
    Certification: false,
    Regulation: "Beauty",
    Class: "Class 3",
    Description: "We need to hack the multi-byte JBOD bus!",
    ApplyTo: "Grocery",
  },
  {
    ID: "4",
    CategoryName: "Ergonomic Concrete Fish",
    TargetDays: 76493,
    TownPlanning: false,
    Certification: true,
    Regulation: "Industrial",
    Class: "Class 4",
    Description:
      "If we calculate the hard drive, we can get to the PCI alarm through the back-end PNG matrix!",
    ApplyTo: "Legacy",
  },
  {
    ID: "5",
    CategoryName: "Refined Soft Cheese",
    TargetDays: 7712,
    TownPlanning: true,
    Certification: false,
    Regulation: "Shoes",
    Class: "Class 5",
    Description:
      "I'll parse the digital GB matrix, that should program the CSS application!",
    ApplyTo: "Zimbabwe",
  },
  {
    ID: "6",
    CategoryName: "Practical Soft Chips",
    TargetDays: 98134,
    TownPlanning: true,
    Certification: true,
    Regulation: "Baby",
    Class: "Class 6",
    Description: "We need to hack the neural SMTP firewall!",
    ApplyTo: "sky blue",
  },
  {
    ID: "7",
    CategoryName: "Practical Fresh Fish",
    TargetDays: 60453,
    TownPlanning: false,
    Certification: false,
    Regulation: "Baby",
    Class: "Class 7",
    Description:
      "If we parse the sensor, we can get to the XML bus through the mobile EXE alarm!",
    ApplyTo: "Vietnam",
  },
  {
    ID: "8",
    CategoryName: "Intelligent Steel Tuna",
    TargetDays: 17776,
    TownPlanning: false,
    Certification: true,
    Regulation: "Toys",
    Class: "Class 8",
    Description:
      "programming the circuit won't do anything, we need to calculate the online IB feed!",
    ApplyTo: "Administrator",
  },
  {
    ID: "9",
    CategoryName: "Licensed Cotton Salad",
    TargetDays: 73809,
    TownPlanning: false,
    Certification: true,
    Regulation: "Toys",
    Class: "Class 9",
    Description:
      "You can't input the alarm without generating the primary COM microchip!",
    ApplyTo: "Centralized",
  },
  {
    ID: "10",
    CategoryName: "Rustic Granite Mouse",
    TargetDays: 34200,
    TownPlanning: true,
    Certification: true,
    Regulation: "Music",
    Class: "Class 10",
    Description:
      "Use the mobile SAS sensor, then you can parse the mobile matrix!",
    ApplyTo: "Engineer",
  },
  {
    ID: "11",
    CategoryName: "Fantastic Concrete Chair",
    TargetDays: 53034,
    TownPlanning: true,
    Certification: true,
    Regulation: "Jewelery",
    Class: "Class 11",
    Description:
      "bypassing the port won't do anything, we need to bypass the haptic AI sensor!",
    ApplyTo: "revolutionize",
  },
  {
    ID: "12",
    CategoryName: "Licensed Soft Salad",
    TargetDays: 9674,
    TownPlanning: false,
    Certification: false,
    Regulation: "Electronics",
    Class: "Class 12",
    Description:
      "The USB protocol is down, reboot the auxiliary protocol so we can copy the ADP hard drive!",
    ApplyTo: "turn-key",
  },
  {
    ID: "13",
    CategoryName: "Small Concrete Hat",
    TargetDays: 41085,
    TownPlanning: true,
    Certification: true,
    Regulation: "Games",
    Class: "Class 13",
    Description:
      "Try to synthesize the HTTP capacitor, maybe it will index the optical card!",
    ApplyTo: "Cambridgeshire",
  },
  {
    ID: "14",
    CategoryName: "Incredible Granite Table",
    TargetDays: 21225,
    TownPlanning: false,
    Certification: true,
    Regulation: "Baby",
    Class: "Class 14",
    Description:
      "Try to bypass the XSS matrix, maybe it will hack the optical matrix!",
    ApplyTo: "synthesizing",
  },
  {
    ID: "15",
    CategoryName: "Gorgeous Concrete Chicken",
    TargetDays: 71339,
    TownPlanning: true,
    Certification: true,
    Regulation: "Home",
    Class: "Class 15",
    Description:
      "I'll program the neural GB circuit, that should card the GB matrix!",
    ApplyTo: "plug-and-play",
  },
  {
    ID: "16",
    CategoryName: "Unbranded Frozen Shoes",
    TargetDays: 70015,
    TownPlanning: true,
    Certification: false,
    Regulation: "Electronics",
    Class: "Class 16",
    Description:
      "Use the online FTP capacitor, then you can compress the 1080p system!",
    ApplyTo: "Officer",
  },
  {
    ID: "17",
    CategoryName: "Intelligent Concrete Shirt",
    TargetDays: 602,
    TownPlanning: false,
    Certification: false,
    Regulation: "Home",
    Class: "Class 17",
    Description:
      "Use the multi-byte TCP protocol, then you can navigate the digital system!",
    ApplyTo: "parsing",
  },
  {
    ID: "18",
    CategoryName: "Fantastic Granite Car",
    TargetDays: 38593,
    TownPlanning: false,
    Certification: false,
    Regulation: "Electronics",
    Class: "Class 18",
    Description:
      "Use the neural RSS program, then you can back up the neural array!",
    ApplyTo: "partnerships",
  },
  {
    ID: "19",
    CategoryName: "Tasty Metal Salad",
    TargetDays: 1017,
    TownPlanning: true,
    Certification: false,
    Regulation: "Games",
    Class: "Class 19",
    Description:
      "The HTTP port is down, copy the haptic matrix so we can generate the PCI transmitter!",
    ApplyTo: "RAM",
  },
  {
    ID: "20",
    CategoryName: "Gorgeous Soft Tuna",
    TargetDays: 4261,
    TownPlanning: false,
    Certification: false,
    Regulation: "Garden",
    Class: "Class 20",
    Description:
      "Use the 1080p SSL sensor, then you can index the open-source port!",
    ApplyTo: "Branding",
  },
  {
    ID: "21",
    CategoryName: "Awesome Granite Soap",
    TargetDays: 56232,
    TownPlanning: false,
    Certification: true,
    Regulation: "Beauty",
    Class: "Class 21",
    Description:
      "synthesizing the bus won't do anything, we need to quantify the cross-platform ADP feed!",
    ApplyTo: "well-modulated",
  },
  {
    ID: "22",
    CategoryName: "Intelligent Concrete Car",
    TargetDays: 99210,
    TownPlanning: false,
    Certification: false,
    Regulation: "Electronics",
    Class: "Class 22",
    Description:
      "You can't compress the card without navigating the 1080p THX circuit!",
    ApplyTo: "Taka",
  },
  {
    ID: "23",
    CategoryName: "Handmade Steel Bacon",
    TargetDays: 9206,
    TownPlanning: true,
    Certification: true,
    Regulation: "Books",
    Class: "Class 23",
    Description:
      "The CSS system is down, calculate the back-end array so we can generate the PNG firewall!",
    ApplyTo: "overriding",
  },
  {
    ID: "24",
    CategoryName: "Unbranded Steel Computer",
    TargetDays: 24031,
    TownPlanning: true,
    Certification: false,
    Regulation: "Kids",
    Class: "Class 24",
    Description:
      "Try to override the HDD hard drive, maybe it will hack the wireless alarm!",
    ApplyTo: "programming",
  },
];
