import { ICCRoute } from "@common/constants/ICCRoute";

export const feesRoute: ICCRoute = {
  path: "fees",
  name: "Fees",
  children: [
    {
      path: "os-fees",
      name: "OS Fees",
      component: require("./os-fees/_index").default,
    },
    {
      path: "all-fees",
      name: "All Fees",
      component: require("./all-fees/_index").default,
    },
  ],
};
