import { API_GET_APPEALS } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { globalStoreInstance } from "@common/stores/global/store";
import { configure } from "mobx";
configure({ enforceActions: "always" });

export const getAppealsByApplicationNumber = async (
  applicationNo: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}${API_GET_APPEALS}?$filter=ApplicationNo eq '${applicationNo}'`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
