import { DTO_Workflow_Supplementary_Associate } from "@app/products/property/components/action-bar/dialog/add-assessment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getSearchAssessment = async (
  url: string
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(`${url}`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postAddAssessmentSupplementary = async (
  newSupplementary: DTO_Workflow_Supplementary_Associate | undefined,
  mode: WorkflowProcessMode
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_Supplementary_Associate>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/supplementary/associateassessment/process/${mode}`,
      newSupplementary
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
