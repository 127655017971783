import { IReAssign } from "@app/products/property/fire-prevention/components/dialogs/re-assign/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IReAssign>();

export const colReAssign: IColumnFields[] = [
  {
    field: nameOf("Contractor"),
    title: "Contractor",
  },
  {
    field: nameOf("PreferredWortRegion"),
    title: "Preferred Wort Region",
  },
  {
    field: nameOf("NumberOfOustandingWorks"),
    title: "Number of Oustanding Works",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NumberOfCompletedWorks"),
    title: "Number of Completed Works",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LastAssignedWork"),
    title: "Last Assigned Work",
  },
  {
    field: nameOf("LastCompletedWork"),
    title: "Last Completed Work",
  },
  {
    field: nameOf("ContractorId"),
    title: "Contractor ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
