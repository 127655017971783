import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export interface INewPhoneMessageButtonProps {
  isDisable?: boolean;
}

export const NewPhoneMessageButton = observer(
  ({ isDisable = false }: INewPhoneMessageButtonProps) => {
    const { setIsShowNewPhoneMsgDialog } = useCRMSPhoneMessageStore();

    return (
      <CCNavButton
        title="New"
        disabled={isDisable}
        onClick={() => {
          setIsShowNewPhoneMsgDialog({
            isShowDialog: true,
            isProductListView: true,
          });
        }}
      />
    );
  }
);
