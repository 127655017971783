import { nameOfFactory } from "@common/utils/common";
const nameOf = nameOfFactory<IAllowedFileTypes>();
export interface IAllowedFileTypes {
  Id: string;
  Name: string;
}

export const colAllowFilePicker: any[] = [
  { title: "File Type", field: nameOf("Id") },
];
