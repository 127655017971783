import { NUMBER_FORMAT } from "@common/constants/common-format";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const TabMap = ({
  formRenderProps,
}: {
  formRenderProps: FormRenderProps;
}) => {
  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">GIS (latitude, longitude)</label>
            <Field
              name={"PropertyAssessment.GIS_Lat_Lng"}
              component={Input}
              placeholder={"GIS (latitude, longitude)"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map number</label>
            <Field
              name={"PropertyAssessment.AddressMapNumber"}
              component={Input}
              placeholder={"Map number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map reference</label>
            <Field
              name={"PropertyAssessment.AddressMapReference"}
              component={Input}
              placeholder={"Map reference"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">PFI</label>
            <Field
              name={"PropertyAssessment.Address_PFI"}
              component={Input}
              placeholder={"PFI"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postal delivery</label>
            <Field
              name={"PropertyAssessment.Address_PostalDeliveryID"}
              component={Input}
              placeholder={"Postal delivery"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">GIS (latitude)</label>
            <Field
              name={"PropertyAssessment.GIS_Latitude"}
              component={Input}
              placeholder={"GIS (latitude)"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">GIS (longitude)</label>
            <Field
              name={"PropertyAssessment.GIS_Longitude"}
              component={Input}
              placeholder={"GIS (longitude)"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street map reference</label>
            <Field
              name={"PropertyAssessment.SL_MapReference"}
              component={Input}
              placeholder={"Street map reference"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street map number</label>
            <Field
              name={"PropertyAssessment.SL_MapNumber"}
              component={Input}
              placeholder={"Street map number"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street PFI</label>
            <Field
              name={"PropertyAssessment.Street_Road_PFI"}
              component={Input}
              placeholder={"Street PFI"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street even start</label>
            <Field
              name={"PropertyAssessment.SL_EvenStartRange"}
              component={NumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              spinners={false}
              placeholder={"Street even start"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street even end</label>
            <Field
              name={"PropertyAssessment.SL_EvenEndRange"}
              component={NumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              spinners={false}
              placeholder={"Street even end"}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street odd start</label>
            <Field
              name={"PropertyAssessment.SL_OddStartRange"}
              component={NumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              spinners={false}
              placeholder={"Street odd start"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street odd end</label>
            <Field
              name={"PropertyAssessment.SL_OddEndRange"}
              component={NumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              spinners={false}
              placeholder={"Street odd end"}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
