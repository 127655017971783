import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { useRefreshData } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/useRefreshData";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { SUPP_MASTER_PROPERTIES_ACCORDION_GRID_ID } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/_index";
import { useSupplementaryRatesMasterPropertyStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/store";
import {
  getMasterPropertyAdjustmentWorkflow,
  postProcessMasterPropertyAdjustmentWorkflow,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/api";
import { GeneralFormStep } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/general/_index";
import { ValuationsFormStep } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/valuations/_index";
import {
  DTO_Workflow_MasterPropertyAdjustment,
  EKeysOfMasterPropertyAdjustmentsSteps,
  masterPropertyAdjustmentKeysOfSendSteps,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IMasterPropertyAdjustmentsDialogProps {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  supplementaryId: number;
  prefixTitle?: string;
  suffixTitle?: string;
}

export const MasterPropertyAdjustmentsDialog = observer(
  ({
    onClose,
    dataFromActionList,
    isSaveOnNextStep,
    supplementaryId,
    prefixTitle,
    suffixTitle,
  }: IMasterPropertyAdjustmentsDialogProps) => {
    //#region HOOKS -------------------------------------------->
    //Use ref
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Use store
    const { pushNotification } = useCCAppNotificationStore();
    const { currentFormTitle } = useCommonProductStore();
    const { selectedMasterPropertyId: masterPropertyId } =
      useSupplementaryRatesMasterPropertyStore();
    const { setSupplementaryRatesId } = useSupplementaryRatesStore();

    // Use state
    const [workflowName, setWorkflowName] = useState<string>(
      "Master Property Adjustment"
    );
    const [initialData, setInitialData] =
      useState<DTO_Workflow_MasterPropertyAdjustment>();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });

    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const { refreshPage } = useRefreshData(workflowHeader);

    const { isFromActionList, isReadOnly, statusBadge } =
      usePropertyWorkflow(dataFromActionList);

    //Use memo
    const isFirstSave = useMemo(() => {
      const draftId =
        initialData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
      return isNil(draftId) || draftId === 0;
    }, [initialData]);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const assessmentNumber =
        initialData?.WorkflowDetail?.General?.Assessment_Number;
      const address = initialData?.WorkflowDetail?.General?.Address;
      let additionalInfo = "";
      // Assessment Number and Address are required when creating an assessment,
      // so they should not be 'undefined' unless the Get Workflow API is still processing.
      if (assessmentNumber && address) {
        additionalInfo = ` - ${assessmentNumber}, ${address}`;
      }
      const formTitle =
        currentFormTitle(formId ?? 0) ?? "Master Property Adjustment";
      const title = workflowName + additionalInfo;
      setWorkflowName(formTitle);
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line
    }, [workflowHeader, prefixTitle, suffixTitle]);

    const initialValues = useMemo(() => {
      let initGeneral = {};
      if (initialData) {
        const workflowDetail = initialData?.WorkflowDetail;
        //General
        if (workflowDetail?.General) {
          initGeneral = {
            ...initGeneral,
            ...workflowDetail.General,
          };
        }
      }

      return {
        [EKeysOfMasterPropertyAdjustmentsSteps.General]: initGeneral,
        [EKeysOfMasterPropertyAdjustmentsSteps.Valuations]: {},
      };
    }, [initialData]);

    const steps: IStep[] = [
      {
        label: "General",
        component: GeneralFormStep,
        initialValues: initialValues.General,
        visible: true,
        key: EKeysOfMasterPropertyAdjustmentsSteps.General,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Valuations",
        component: ValuationsFormStep,
        initialValues: initialValues.Valuations,
        visible: true,
        key: EKeysOfMasterPropertyAdjustmentsSteps.Valuations,
        options: {
          isReadOnly,
        },
      },
    ];

    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      //Get workflow data
      const workflowDataResponse = await getMasterPropertyAdjustmentWorkflow(
        {
          MasterPropertyId: masterPropertyId,
          SupplementaryId: supplementaryId,
        },
        workflowDraftId
      );
      notificationFormStepRef?.current?.setLoadingFormStep(false);
      const workflowData = workflowDataResponse?.data;
      if (isSuccessResponse(workflowDataResponse) && workflowData) {
        setSupplementaryRatesId(workflowData.WorkflowDetail?.SupplementaryId);
        //Set initial workflow data
        setInitialData(workflowData);
        //Set workflow header and workflow draft ID
        if (workflowData?.WorkflowHeader) {
          setWorkflowHeader(workflowData.WorkflowHeader);
          setWorkflowDraftId(
            workflowData.WorkflowHeader.WorkflowDraft?.Workflow_Draft_Id ?? 0
          );
        }
      } else {
        const responseError = workflowDataResponse as APIResponse<
          DTO_Workflow_MasterPropertyAdjustment | ResponsePacket
        >;
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed.",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion <--------------------------------------------

    //#region COMMON FUNCTIONS -------------------------------------------->
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Save:
          await sendSaveMasterPropertyAdjustments(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          await handleFinishProcess(processData(data));
          break;
        case EListSubmitButton.Park:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * Process data before sending to API
     * @param data
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (
          masterPropertyAdjustmentKeysOfSendSteps.includes(
            key as EKeysOfMasterPropertyAdjustmentsSteps
          )
        ) {
          return { [key]: value };
        }
      });

      const { MasterPropertyId, SupplementaryId } =
        initialData?.WorkflowDetail ?? {};

      workflowDetail = {
        ...initialData?.WorkflowDetail,
        MasterPropertyId,
        SupplementaryId,
      };

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          workflowDetail[key] = {
            ...value,
          };
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling API with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_MasterPropertyAdjustment>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessMasterPropertyAdjustmentWorkflow(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };
    //#endregion <--------------------------------------------

    //#region SAVE and NEXT -------------------------------------------->
    /**
     * Handle Next button
     * @param data
     * @param filterSteps
     * @param keyStep
     */
    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      return await sendSaveMasterPropertyAdjustments(processData(data));
    };

    /**
     * Send save master property adjustments process (call API with 'Save' mode)
     * @param payload
     * @param isCloseDialog
     */
    const sendSaveMasterPropertyAdjustments = async (
      payload: DTO_Workflow_MasterPropertyAdjustment,
      isCloseDialog: boolean = false
    ) => {
      const response = await postProcessMasterPropertyAdjustmentWorkflow(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = `${workflowName} was saved successfully.`;
      const defaultFailedMessage = `${workflowName} could not be saved.`;
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };
    //#endregion <--------------------------------------------

    //#region PARK -------------------------------------------->
    /**
     * Handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_MasterPropertyAdjustment
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_MasterPropertyAdjustment> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            if (!isFromActionList) {
              //Reload master property status in Master Properties accordion
              eventEmitter.emit(CCGridEventType.RefreshOData, {
                gridIds: [SUPP_MASTER_PROPERTIES_ACCORDION_GRID_ID],
              });
            }
            pushNotification({
              title:
                e?.Notification ?? `${workflowName} was parked successfully.`,
              type: "success",
            });
          },
          defaultFailedMessage: `${workflowName} could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region FINISH -------------------------------------------->
    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_MasterPropertyAdjustment
    ) => {
      setIsLoadingOnNext(true);

      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_MasterPropertyAdjustment> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            refreshPage(() =>
              pushNotification({
                title:
                  e?.Notification ??
                  `${workflowName} was completed successfully.`,
                type: "success",
              })
            );
          },
          defaultFailedMessage: `${workflowName} could not be completed.`,
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {
        setIsLoadingOnNext(false);
      };
      //calling API process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    //#endregion <--------------------------------------------

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={
            isSaveOnNextStep && !isReadOnly ? handleNextButton : undefined
          }
          renderForm={(renderProps: ICCFormStepRender) => {
            return (
              <CCDialog
                maxWidth="60%"
                titleHeader={titleHeader}
                onClose={onClose}
                badge={statusBadge}
                bodyElement={renderProps.children}
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    {!isReadOnly && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext && !renderProps.isLastStep
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                }
              />
            );
          }}
        />
      </>
    );
  }
);
