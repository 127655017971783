import { NewAssetButton } from "@app/products/hm/components/action-bar/buttons/new-asset/_index";

import { NewEventButton } from "@app/products/crms/components/action-bar/buttons/new-event/_index";
import { NewMessageButton } from "@app/products/hm/components/action-bar/buttons/new-message/_index";
import { NewWordOrderButton } from "@app/products/hm/components/action-bar/buttons/new-word-order/_index";
import { NewReportsButton } from "@app/products/hm/components/action-bar/buttons/reports/_index";
import { NewEnquiryButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/dashboard/new-enquiry/_index";
import { NewPermitReferralButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/dashboard/new-permit-referral/_index";
import { NewPSAReferralButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/dashboard/new-psa-referral/_index";
import AuthService from "@common/apis/auth.service";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { Activity } from "@common/pages/home/components/activity/_index";
import { useAnimalsDashboardStore } from "@common/pages/home/components/animals/store";
import { useCrmsDashboardStore } from "@common/pages/home/components/crms/store";
import { EventsSection } from "@common/pages/home/components/event-section/_index";
import { Insights } from "@common/pages/home/components/insights/_index";
import { usePropertyDashboardStore } from "@common/pages/home/components/property/store";
import { useTownPlanningDashboardStore } from "@common/pages/home/components/townplanning/store";
import { IDashboardModel } from "@common/pages/home/model";
import { useGlobalStore } from "@common/stores/global/store";
import { TOWN_PLANNING_CONFIGURATION_MODE } from "@common/stores/products/town-planning/enum";
import { useCommonTownPlanningStore } from "@common/stores/products/town-planning/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import "hammerjs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";
import { getDashboardData } from "./api";
import Greeting from "./components/greeting/_index";

export const HomePage = observer(() => {
  const [displayName, setDisplayName] = useState("");
  const { currentUserInfo, productsActive, isVisibleAndIsCommunityCentral } =
    useGlobalStore();
  const { townPlanningMode } = useCommonTownPlanningStore();
  const { loadTownPlanningDashboard } = useTownPlanningDashboardStore();
  const { loadPropertyDashboard, setQueryDateTime } =
    usePropertyDashboardStore();
  const { clearNotifications } = useCCAppNotificationStore();
  const { loadCrmsEventDashboard } = useCrmsDashboardStore();
  const { loadAnimalsDashboard } = useAnimalsDashboardStore();

  const [dashboardData, setDashBoardData] = useState<IDashboardModel>({
    activity: {
      inspectionThisMonth: 0,
      inspectionDueThisWeek: 0,
      newRegistrations: 0,
      inspectionFinished: 0,
    },
    insights: {
      premiseByType: {},
      complaintsReceived: {},
    },
    queryDateTime: "",
  });

  // const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const isVisibleAsset =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CommunityAsset) === "visible";
  const isVisibleCRMS =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CustomerService) === "visible";
  const isVisibleTownPlanning =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.TownPlanning) === "visible";
  const isVisibleCommunityProperty =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CommunityProperty) ===
    "visible";
  const isVisibleAnimals =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.Animals) === "visible";

  useEffect(() => {
    // setIsLoading(true);
    if (AuthService.getCurrentUser()) {
      getDashboardData()
        .then((response: IResponseData) => {
          if (response.status === APIResponseStatus.BAD_REQUEST) {
            if (response.data === "Key not supplied X-API-KEY") {
              //Show error "Header Key X-API-KEY not supplied"
            } else {
              //Show normal Bad Request error
            }
          }
          if (response.status === APIResponseStatus.UN_AUTHORIZE) {
            if (response.data === "Invalid X-API-KEY") {
              //REACT_APP_API_KEY set in .env does not match the api key in the database (SELECT Value FROM Core_CorporateSettings Where CorporateSettingsField_ENUM = 999)
              //Show error message for invalid api key.
            } else {
              //Show Normal unauthorised message
            }
          }
          if (response.status === APIResponseStatus.SUCCESS) {
            // Update user display name
            let userData = AuthService.getCurrentUser();
            userData.userDisplayname =
              response.data.DisplayUsername &&
              response.data.DisplayUsername !== "CommunityCentralAgent"
                ? response.data.DisplayUsername
                : currentUserInfo?.userDisplayName;
            userData.userID = response.data.User_LID;
            localStorage.setItem("userData", JSON.stringify(userData));

            // setIsLoading(false);
            setDisplayName(
              response.data.DisplayUsername &&
                response.data.DisplayUsername !== "CommunityCentralAgent"
                ? response.data.DisplayUsername
                : currentUserInfo?.userDisplayName
            );
            setDashBoardData({
              activity: {
                inspectionThisMonth: response.data.InspectionThisMonth,
                inspectionDueThisWeek: response.data.InspectionDueThisWeek,
                newRegistrations: response.data.NewRegistrations,
                inspectionFinished: response.data.InspectionFinished,
              },
              insights: {
                premiseByType: response.data.PremiseByType,
                complaintsReceived: response.data.ComplaintsReceived,
              },
              queryDateTime: response.data.QueryDateTime,
            });
            setQueryDateTime(response.data.QueryDateTime);
          }

          // setIsLoading(false);
        })
        .catch((error: any) => {
          console.error(
            "Error while loading dashboard data (getDashboardData): " + error
          );
          // setIsLoading(false);
        });

      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    isVisibleCommunityProperty && loadPropertyDashboard();
  }, [loadPropertyDashboard, isVisibleCommunityProperty]);

  useEffect(() => {
    isVisibleTownPlanning &&
      townPlanningMode === TOWN_PLANNING_CONFIGURATION_MODE.PPR &&
      loadTownPlanningDashboard();
  }, [isVisibleTownPlanning, townPlanningMode, loadTownPlanningDashboard]);

  useEffect(() => {
    isVisibleCRMS && loadCrmsEventDashboard();
  }, [isVisibleCRMS, loadCrmsEventDashboard]);

  useEffect(() => {
    isVisibleAnimals && loadAnimalsDashboard();
  }, [isVisibleAnimals, loadAnimalsDashboard]);

  const pprButtons = useMemo(() => {
    if (
      isVisibleTownPlanning &&
      townPlanningMode === TOWN_PLANNING_CONFIGURATION_MODE.PPR
    )
      return [
        <NewEnquiryButton />,
        <NewPermitReferralButton />,
        <NewPSAReferralButton />,
      ];
    return [];
  }, [townPlanningMode, isVisibleTownPlanning]);

  const crmsButtons = useMemo(() => {
    if (isVisibleCRMS) return [<NewEventButton />];
    return [];
  }, [isVisibleCRMS]);

  const crmsMoreButtons = useMemo(() => {
    if (isVisibleCRMS) return [<NewMessageButton />];
    return [];
  }, [isVisibleCRMS]);

  const assetButtons = useMemo(() => {
    if (isVisibleAsset) return [<NewAssetButton />, <NewWordOrderButton />];
    return [];
  }, [isVisibleAsset]);

  useCCListViewActionBar({
    title: "Dashboard",
    centerComponents: [
      ...pprButtons,
      ...crmsButtons,
      ...assetButtons,
      ...[
        <NewReportsButton />,
        <CCNavButton title="More Options" type="more">
          {crmsMoreButtons.map((item: JSX.Element) => item)}
        </CCNavButton>,
      ],
    ],
  });
  useEffectOnce(() => {
    clearNotifications();
    return () => {
      clearNotifications();
    };
  });

  return productsActive ? (
    <div className="cc-homepage">
      <CCAppNotification />
      {/* <Loading isLoading={isLoading} /> */}
      <Greeting displayName={displayName} />
      <Activity activityData={dashboardData.activity} />
      <Insights
        queryDateTime={dashboardData.queryDateTime}
        insights={dashboardData.insights}
      />
      <EventsSection />
    </div>
  ) : (
    <Loading isLoading isFullScreen />
  );
});
