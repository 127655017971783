const data = [{
    id: 1,
    icon: "⭕️",
    status: "open",
    title: "Park Bins overflow",
    content: "Bins at Central Park need emptying"
}, {
    id: 2,
    icon: "⭕️",
    status: "open",
    title: "Sewer main burst",
    content: "Second St Sewer main has burst and overflowing"
}, {
    id: 3,
    icon: "🔆️",
    status: "in progress",
    title: "Lost Dog",
    content: "Lost dog roaming the streets."
}, {
    id: 4,
    icon: "📝",
    status: "in review",
    title: "Kanban Board sample",
    content: "demonstrating the board"
}, {
    id: 5,
    icon: "✅",
    status: "done",
    title: "Footpath repair",
    content: "Footpath on corner of Second and Third street is cracked"
}];

const statuses = [{
    status: "open",
    icon: "⭕️",
    color: "#EB5A46"
}, {
    status: "in progress",
    icon: "🔆️",
    color: "#00C2E0"
}, {
    status: "in review",
    icon: "📝",
    color: "#C377E0"
}, {
    status: "done",
    icon: "✅",
    color: "#3981DE"
}];


export { data, statuses };