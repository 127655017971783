import { isSuccessResponse } from "@common/apis/util";
import { putAppealsHearings } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/api";
import { colAdditionalHearing } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/config";
import { IAdditionalHearing } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/model";
import { useAdditionalHearingStore } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/store";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

const nameOf = nameOfFactory<IAdditionalHearing>();
export const AdditionalHearing = observer(() => {
  const {
    isLoading,
    additionalHearing,
    setAdditionalHearing,
    loadAdditionalHearing,
    additionalHearingSelected,
    setAdditionalHearingSelected,
  } = useAdditionalHearingStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { appealId, isInactive } = useAppealStore();
  const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] =
    useState<boolean>(false);

  useEffect(() => {
    loadAdditionalHearing(appealId);
  }, [appealId, loadAdditionalHearing]);

  const handleDelete = async () => {
    const selectedList = additionalHearingSelected
      ? [...additionalHearingSelected]
      : [];
    const getListIds = selectedList.map((item: IAdditionalHearing) => item?.ID);
    if (!getListIds) return;
    await putAppealsHearings(getListIds).then((res) => {
      if (isSuccessResponse(res)) {
        if (res?.data?.length === 0) {
          setAdditionalHearingSelected([]);
          //TODO: Using emit reload Grid data
          const newGridData =
            additionalHearing?.filter(
              (item: IAdditionalHearing) =>
                !additionalHearingSelected?.some(
                  (selectedItem: IAdditionalHearing) =>
                    item?.ID === selectedItem?.ID
                )
            ) ?? [];
          setAdditionalHearing(newGridData);
          pushNotification({
            title: "Additional hearing deleted successfully",
            type: "success",
          });
        } else {
          pushNotification({
            title: "Additional hearing delete unsuccessfully",
            type: "error",
          });
        }
      } else {
        pushNotification({
          title: "Additional Hearing delete unsuccessfully",
          type: "error",
        });
      }
    });
  };

  return (
    <>
      <CCGrid
        isLoading={isLoading}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={
                isInactive ? isInactive : !additionalHearingSelected?.length
              }
              onClick={() => {
                setIsShowConfirmDeleteDialog(true);
              }}
            />
          </div>
        }
        selectableMode="single"
        className="cc-additional-hearing"
        data={additionalHearing ?? []}
        selectedRows={additionalHearingSelected ?? []}
        columnFields={colAdditionalHearing}
        primaryField={nameOf("ID")}
        onSelectionChange={(dataItem: any) => {
          setAdditionalHearingSelected(
            (dataItem as IAdditionalHearing[]) ?? []
          );
        }}
      />
      {isShowConfirmDeleteDialog && (
        <ConfirmDialog
          title="Confirm Delete"
          subMessage="Are you sure you want to remove this record?"
          onClosePopup={() => {
            setIsShowConfirmDeleteDialog(false);
          }}
          onConfirmYes={handleDelete}
        />
      )}
    </>
  );
});
