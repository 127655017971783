import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import {
  columnViewConfigLocation,
  iconColumnViewConfigAlert,
  iconColumnViewConfigCallReturn,
  iconColumnViewConfigHazard,
  iconColumnViewConfigUrgent,
} from "@app/products/crms/config";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<EventView>();

export const colResent: IColumnFields[] = [
  {
    field: nameOf("RefNo"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem: EventView) => {
      if (dataItem.IsHideInfo) {
        return undefined;
      }
      return `${CRMS_ROUTE}/${dataItem.ID}`;
    },
  },
  ...iconColumnViewConfigAlert,
  ...iconColumnViewConfigUrgent,
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DateTarget"),
    title: "Target",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ResentCount"),
    title: "Resent Count",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Source"),
    title: "Source",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  ...iconColumnViewConfigHazard,
  columnViewConfigLocation,
  {
    field: nameOf("Location_AssessmentNo"),
    title: "Assessment Number",
  },
  ...iconColumnViewConfigCallReturn,
  {
    field: nameOf("RequestedBy"),
    title: "Contact",
  },
  {
    field: nameOf("ContactDetails"),
    title: "Contact Number",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
  {
    field: nameOf("DateRecorded"),
    title: "Recorded",

    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("OrgStructure"),
    title: "Org Structure",
  },
  {
    field: nameOf("ActionOfficer"),
    title: "Action Officer",
  },
  {
    field: nameOf("Coordinator"),
    title: "Coordinator",
  },
  // Still to be reviewed - taskID: 3269
  // {
  //   field: nameOf("DateWork"),
  //   title: "Work Date",
  //   format: DATE_FORMAT.DATE,
  // },
  {
    field: nameOf("FileNumber"),
    title: "File Number",
  },
  // Still to be reviewed - taskID: 3269
  // {
  //   field: nameOf("EnquiryRefNo"),
  //   title: "Assets Reference Number",
  // },
  // {
  //   field: nameOf("Event_ReferenceNoAdditional"),
  //   title: "Additional Reference Number",
  // },
  // Dont receive any requirement from Onshore for this field
  // {
  //   field: nameOf("Flag_ITSupport"),
  //   title: "IT Support",
  // },
];
