import { mockRegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/mock";
import { IRegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/model";
import { sleep } from "@common/utils/common";

export const getRegisterNames = async (): Promise<
  IRegisterNames | undefined
> => {
  try {
    await sleep(300);
    return mockRegisterNames;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
