import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const inspectionsRoute: ICCRoute = {
  path: "inspections",
  name: "Inspections",
  enum: LLPermitsMenu.Inspections,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: LLPermitsMenu.InspectionsByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: LLPermitsMenu.InspectionsByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: LLPermitsMenu.InspectionsByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      enum: LLPermitsMenu.InspectionsByMonth,
      component: require("./by-month/_index").default,
    },
  ],
};
