import { ICCGridDefaultProps, IGridConfig } from "./model";

export enum ExportFormat {
  CSV = "csv",
  EXCEL = "excel",
  NONE = "",
}

export const CCGridCancelMessage = "CCGridCancelMessage";

export const gridConfig: IGridConfig = {
  paging: {
    buttonCount: 3,
    info: true,
    pageSizes: [5, 10, 20, 50, 100, 200],
    previousNext: true,
  },
  filterOperators: {
    text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
    numeric: [
      { text: "grid.filterEqOperator", operator: "eq" },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterGtOperator", operator: "gt" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterLtOperator", operator: "lt" },
    ],
    date: [
      { text: "grid.filterAfterOperator", operator: "gt" },
      { text: "grid.filterBeforeOperator", operator: "lt" },
    ],
    boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
  },
  fontSetting: {
    font: "Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "inherit",
    width: undefined,
  },
  size: {
    grid: {
      paddingX: 10,
      detailPaddingX: 52,
    },
    columns: {
      minWidth: 100,
      maxWidth: 500,
      paddingX: 30,
      minResizable: 30,
    },
    components: {
      dateCellWidth: 50,
      dateTimeCellWidth: 135,
      iconBooleanCellWidth: 50,
      iconShareCellWidth: 50,
      checkBoxWidth: 55,
      filterButtonWidth: 60,
    },
  },
};

export const gridDefaultProps: ICCGridDefaultProps = {
  data: [],
  state: {
    skip: 0,
    filter: { logic: "and", filters: [] },
    sort: [],
    group: [],
  },
  onDataStateChange: () => {},
  dataUrl: undefined,
  fileExportName: undefined,
  exportFormat: ExportFormat.NONE,
  onExported: undefined,
  columnFields: [],
  primaryField: "Id",
  className: undefined,
  style: {
    maxHeight: 500,
    height: "auto",
  },
  itemPerPage: 5,
  pagerType: "numeric",
  isAutoHiddenPager: true,
  detail: undefined,
  isLimitDetailWidth: false,
  disableRowField: "isDisabledRow",
  canSelectField: "canSelect",
  canNotSelectField: "canNotSelect",
  selectedField: "selected",
  expandField: "expanded",
  groupField: "groupId",
  editField: "inEdit",
  expandMode: "single",
  selectableMode: undefined,
  onSelectionChange: () => {},
  editableMode: undefined,
  onDataChange: () => {},
  toolbar: undefined,
  groupDragMode: "auto",
  columnMenu: undefined,
  pageSizes: gridConfig.paging.pageSizes,
};

export const CHANGE_PAGE_SPEED = 500;
