import { ICCRoute } from "@common/constants/ICCRoute";

export const spearRoute: ICCRoute = {
  path: "spear",
  name: "SPEAR",
  children: [
    {
      path: "inbox",
      name: "Inbox",
      component: require("./inbox/_index").default,
    },
    {
      path: "outbox",
      name: "Outbox",
      component: require("./outbox/_index").default,
    },

    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
  ],
};
