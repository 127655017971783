import { ExistedRegion } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/forms/existed/_index";
import { NewRegion } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/forms/new/_index";
import { useRegionStore } from "@app/products/town-planning/ppr/system-admin/regions/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { LOOKUP_REGION_ROUTE } from "@common/pages/settings/lookups/regions/constant";
import { includes } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageRegion = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const regionID = parseInt(params.id) || undefined;
  const { resetStore, loadRegion } = useRegionStore();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);
  /**
   * Reason for this check:
   * This page is used in PPR system admin and core lookup regions.
   * In core lookup regions, we have the logic to check permission.
   * In PPR system admin regions, we don't have check permission to view the region.
   */
  const isUseInLookupRegionLocation = includes(
    location?.pathname ?? "",
    LOOKUP_REGION_ROUTE
  );

  useEffect(() => {
    if (location.state) {
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    if (!isUseInLookupRegionLocation || isHasPermission) {
      loadRegion(regionID, isNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    regionID,
    isNew,
    loadRegion,
    isUseInLookupRegionLocation,
    isHasPermission,
  ]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isUseInLookupRegionLocation) {
    return (
      <CheckPermissionSettingWrapper setIsHasPermission={setIsHasPermission}>
        {isNew ? <NewRegion /> : <ExistedRegion />}
      </CheckPermissionSettingWrapper>
    );
  }
  if (isNew) {
    return <NewRegion />;
  }
  return <ExistedRegion />;
};

export default observer(ManageRegion);
