import { DTO_LOV } from "@common/models/odataResponse";

export interface IReInspectionFormData {
  Property?: any[];
  CommentsInPreviousCases?: any[];
  CommentsForThisCases?: string;
  //InspectionDetail
  CaseId: number | string;
  Date?: Date | string;
  LastInspection?: Date | string;
  OutcomeValues?: DTO_LOV[];
  ClearanceValues?: DTO_LOV[];
  AdditionNotes?: string;
  SpecificInstructions?: string;
  CopyInstructionsFromPreviousInspection?: boolean;
  AddAttachment?: boolean;
  _searchByCaseId?: number | string;
}

export enum OUTCOME_TYPE {
  CLEARED_NO_HAZARD = "Cleared, No Hazard",
  NO_HAZARD = "No Hazard",
}
