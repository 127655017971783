import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { renderList } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/records/records-trimcustomfields/constant";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const RecordsTrimCustomFieldsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    if (!configData) return <></>;

    return (
      <section className="cc-field-group">
        <div className="custom-form-cols-2">
          {renderList.map((enumParam: ECorporateSettingsField) => {
            return (
              <>
                {configData[enumParam] && (
                  <CCSearchComboBoxGlobalSettings
                    data={configData[enumParam]}
                    isEditing={isEditing}
                  />
                )}
              </>
            );
          })}
        </div>
      </section>
    );
  }
);
