import { DeleteChargeRunCommonButton } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/buttons/delete/components/_index";
import { deletePICChargeRun } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/buttons/delete/api";
import { PROPERTY_PIC_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/constant";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface IDeletePICChargeRunProps {
  disabled?: boolean;
}

export const DeletePICChargeRunButton = observer(
  ({ disabled }: IDeletePICChargeRunProps) => {
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const { id } = useParams<{ id: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDeletePICChargeRun = async () => {
      if (!isNil(id)) {
        setIsLoading(true);
        const response = await deletePICChargeRun(id);
        setIsLoading(false);
        if (response?.data?.IsSuccess) {
          const notificationContent: IAppNotificationItemAddProps = {
            type: "success",
            title:
              response?.data?.SuccessMessage ??
              "PIC charge run deleted successfully.",
          };
          history.push(PROPERTY_PIC_CHARGE_RUNS_ROUTE, {
            notification: notificationContent,
          });
        } else {
          pushNotification({
            type: "error",
            title:
              response?.data?.ErrorMessage ??
              "PIC charge run could not be deleted.",
            autoClose: false,
          });
        }
      }
    };

    return (
      <DeleteChargeRunCommonButton
        handleDelete={handleDeletePICChargeRun}
        isLoading={isLoading}
        disabled={disabled}
        message={`Are you sure you want to delete the PIC Charge Run?`}
      />
    );
  }
);
