import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putFiNotRequired } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/fi-not-required/api";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const FiNotRequiredButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.ReferralNotRequired.toString(),
      listener: () => {
        fiNotRequired();
      },
    },
  ]);

  const fiNotRequired = async () => {
    if (!pprId) return;
    const response = await putFiNotRequired(pprId);

    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      setPPRWithLoading(response.data.Application);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        title: "Application saved successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "FI not required failed",
        description: response.data?.Errors ?? response.statusText,
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const isVisible = useMemo(() => {
    return (
      ppr?.PPRDetails?.Flag_IsReferralRequired == null &&
      ppr?.Status_ENUM === Application_Status.PreliminaryAssessment
    );
  }, [ppr]);

  return isVisible ? (
    <CCNavButton
      title="Referral not required"
      disabled={isLoading}
      isLoading={isLoading}
      onClick={onSubmit}
      name={PSARSubmitActions.FiNotRequired}
    />
  ) : null;
});
