import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { VO_Supplementary } from "@app/products/property/supplementary-rates/list/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary>();
export const colList: IColumnFields[] = [
  {
    field: nameOf("Supplementary_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: VO_Supplementary) => {
      return `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/${dataItem.Supplementary_Id}`;
    },
    locked: true,
  },
  {
    field: nameOf("Supp_Name"),
    title: "Name",
    width: 300,
  },
  {
    field: nameOf("Supp_Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
    width: 150,
  },
  {
    field: nameOf("Supp_Reference"),
    title: "Reference",
    width: 300,
  },
  {
    field: nameOf("Supp_Notes"),
    title: "Notes",
    width: 400,
  },
  {
    field: nameOf("Supp_Is_Complete"),
    title: "Is Complete",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ST_Name"),
    title: "Type",
    width: 300,
  },
  {
    field: nameOf("IP_Name"),
    title: "Instalment Plan",
    width: 300,
  },
  {
    field: nameOf("Supp_Use_Existing_Instal_Plan"),
    title: "Use Existing Instalment Plan",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Supp_Enable_Levy_Calcs"),
    title: "Enable Levy Calculations",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Def_Bal_Trf_Valuation_Type"),
    title: "Balance Transfer Method",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Supp_Creation_DateTime"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
    width: 200,
  },
  {
    field: nameOf("Supp_Creation_UserId"),
    title: "Created By",
  },
];
