import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.SchemeId
    ? `- ${selectedRow.SchemeId}`
    : "";
  return `Property - Schemes ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.SchemeNumber,
    selectedRow.SchemeName,
  ]);
};
