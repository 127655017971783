import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import React from "react";

interface ICrmsActionBarNavDropdownProps {
  category: string;
}

export const CrmsActionBarNavDropdown = ({
  category,
}: ICrmsActionBarNavDropdownProps) => {
  return <CCActionBarNavDropdownNew />;
};
