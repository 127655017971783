import { getNewSupplementaryLOVs } from "@app/products/property/supplementary-rates/[id]/components/dialogs/new-supplementary/api";
import { DTO_Create_Supplementary_LOVs } from "@app/products/property/supplementary-rates/[id]/components/dialogs/new-supplementary/model";
import { ISupplementaryRatesFormData } from "@app/products/property/supplementary-rates/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

interface INewSupplementaryDialog {
  onClose: () => void;
  onSubmit: (supplementary: any) => void;
}

export const NewSupplementaryDialog = (props: INewSupplementaryDialog) => {
  const [showConfirmNoInstalmentPlan, setShowConfirmNoInstalmentPlan] =
    useState(false);
  const [newSupplementaryRates, setNewSupplementaryRates] = useState<
    ISupplementaryRatesFormData | undefined
  >();
  const [supLOV, setSupLOV] = useState<DTO_Create_Supplementary_LOVs>();
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [isShowValuationTypeToBeUsed, setIsShowValuationTypeToBeUsed] =
    useState<boolean>(false);
  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const { values } = event;

    if (values?.InstalmentPlan) {
      props.onSubmit({
        ID: 9999,
        Date: values?.Date,
        Name: values?.Name,
        Reference: values?.Reference,
        Notes: values?.Notes,
        IsComplete: false,
        Type: values?.Type,
        InstalmentPlan: values?.InstalmentPlan,
        UseExistingInstalmentPlan: values?.UseExistingInstalmentPlan,
        CreatedOn: new Date().toDateString(),
      });
    } else {
      setNewSupplementaryRates({
        ID: 9999,
        Date: values?.Date ? values?.Date : new Date().toDateString(),
        Name: values?.Name,
        Reference: values?.Reference,
        Notes: values?.Notes,
        IsComplete: false,
        Type: values?.Type,
        InstalmentPlan: values?.InstalmentPlan,
        UseExistingInstalmentPlan: values?.UseExistingInstalmentPlan,
        CreatedOn: new Date().toDateString(),
      });
      setShowConfirmNoInstalmentPlan(true);
    }
  };

  const loadLOV = async () => {
    setIsLoading(true);
    const response = await getNewSupplementaryLOVs();
    let errorResponse = undefined;
    if (isSuccessResponse(response)) {
      setSupLOV(response?.data?.dtoCreate_Supplementary_LOVs);
    } else {
      errorResponse = {
        status: response?.status,
        error: response?.error,
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadLOV();
  });

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        const getValue = formRenderProps.valueGetter;
        return (
          <CCDialog
            titleHeader={"New Supplementary"}
            onClose={props.onClose}
            maxHeight="70%"
            maxWidth="40%"
            bodyElement={
              <>
                <div className="cc-form">
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => loadLOV()}
                    />
                  ) : (
                    <FormElement className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Name
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name="Name"
                            component={CCInput}
                            validator={requiredValidator}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Type</label>
                          <Field
                            name={"Type"}
                            component={CCSearchComboBox}
                            data={supLOV?.SupplementaryType ?? []}
                            textField="Name"
                            dataItemKey="Code"
                            isUseDefaultOnchange
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Reference</label>
                          <Field name="Reference" component={CCInput} />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Date</label>
                          <Field
                            name="Date"
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                            defaultValue={new Date()}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Notes</label>
                          <CCTextArea
                            rows={3}
                            className="cc-form-control"
                            autoComplete="off"
                            name="Notes"
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">Instalment plan</label>
                          <Field
                            name="InstalmentPlan"
                            component={CCSearchComboBox}
                            data={supLOV?.InstallmentType ?? []}
                            textField="Name"
                            dataItemKey="Code"
                            isUseDefaultOnchange
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Override with assessments existing active instalment
                            plan (If present)
                          </label>
                          <Field
                            name={"UseExistingInstalmentPlan"}
                            component={CCSwitch}
                            value={getValue("UseExistingInstalmentPlan")}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Is any remaining balance on obsolete assessments to
                            be transferred to replacement assessments?
                          </label>
                          <Field
                            name={"IsReplacementAssessments"}
                            component={CCSwitch}
                            value={getValue("IsReplacementAssessments")}
                          />
                        </div>
                      </div>
                      {getValue("IsReplacementAssessments") && (
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">Assessment by</label>
                            <Field
                              name="AssessmentBy"
                              component={CCSearchComboBox}
                              data={supLOV?.AssessmentType ?? []}
                              textField="Name"
                              dataItemKey="Code"
                              value={getDropdownValue(
                                getValue("AssessmentBy"),
                                supLOV?.AssessmentType ?? [],
                                "Code"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                formRenderProps.onChange("AssessmentBy", {
                                  value: event.target.value?.Code ?? null,
                                });
                                setIsShowValuationTypeToBeUsed(
                                  event.target.value?.Name === "By Valuation"
                                );
                              }}
                            />
                          </div>
                          {isShowValuationTypeToBeUsed && (
                            <div className="cc-field">
                              <label className="cc-label">
                                Valuation type to be used
                              </label>
                              <Field
                                name="ValuationType"
                                component={CCSearchComboBox}
                                data={supLOV?.ValuationTypeTobeUsed ?? []}
                                textField="Name"
                                dataItemKey="Code"
                                isUseDefaultOnchange
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </FormElement>
                  )}
                </div>
                {showConfirmNoInstalmentPlan && (
                  <ConfirmDialog
                    onClosePopup={() => {
                      setShowConfirmNoInstalmentPlan(false);
                    }}
                    width="30%"
                    height="auto"
                    title={"Confirm No Instalment Plan"}
                    message={
                      "Are you sure you wish to proceed with no Instalment Plan?"
                    }
                    subMessage={
                      "No Instalment Plan has been selected for the Supplementary. It is recommended to select an Instalment Plan be used to determine the due dates of charges/credits. With credits the Instalment Plan determines which instalment the credit is to be applied to. With no Instalment Plan charges/credits will be due on the date of the charge."
                    }
                    onConfirmYes={() => {
                      props.onSubmit(newSupplementaryRates);
                    }}
                  />
                )}
              </>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  type={"submit"}
                  disabled={!formRenderProps.valid}
                  onClick={formRenderProps.onSubmit}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};

export default NewSupplementaryDialog;
