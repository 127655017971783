import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import {
  ICategoryPropertyConfig,
  getCurrentStateCategoryData,
} from "@app/products/property/system-admin/settings/_category/config";
import { CCSettingListEventType } from "@app/products/property/system-admin/settings/_category/constant";
import {
  IStatePropertyCategory,
  MODE_SETTING,
} from "@app/products/property/system-admin/settings/_category/model";
import { useSettingPropertyStore } from "@app/products/property/system-admin/settings/_category/store";
import SettingManage from "@app/products/property/system-admin/settings/_id/_index";
import { PropertyActionBarSettingClone } from "@app/products/property/system-admin/settings/components/action-bar/buttons/clone/_index";
import { PropertyActionBarSettingDelete } from "@app/products/property/system-admin/settings/components/action-bar/buttons/delete/_index";
import { PropertyActionBarSettingNew } from "@app/products/property/system-admin/settings/components/action-bar/buttons/new/_index";
import Breadcrumb from "@app/products/property/system-admin/settings/components/breadcrumb/_index";
import { ICategoryNameSetting } from "@app/products/property/system-admin/settings/model";
import { settingsRoute } from "@app/products/property/system-admin/settings/route";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { GlobalSettingHistoryTab } from "@common/pages/settings/system-admin/global-settings/_id/sidebar/history/_index";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { EListType } from "@components/cc-product-list-view/components/hooks/useStoreStateListView";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./_index.scss";

export default observer(() => {
  const referenceSideBarStore = useReferenceSideBarStore();
  const location = useLocation();
  const history = useHistory();
  const state: any = location.state;

  const params: { category: string } = useParams();
  const categoryState = getCurrentStateCategoryData(state, params);
  const { clearNotifications } = useCCAppNotificationStore();
  const actionBarStore = useCCListViewActionBarStore();
  const { setReferenceComponents } = referenceSideBarStore;
  const {
    sortDescriptor,
    loadDataSetting,
    responseLoadError,
    dataSetting,
    modeSetting,
    currentBreadcrumb,
    isLoading,
    columnFields,
    primaryKeyTable,
    setModeSetting,
    isNew,
  } = useSettingPropertyStore();
  const { listCategoryPropertySettingVisible } = useCommonProductStore();

  const categoryConfigs: ICategoryPropertyConfig[] = useMemo(() => {
    return (
      listCategoryPropertySettingVisible?.map(
        ({ CategoryName, ProductType }: ICategoryNameSetting) => ({
          category: CategoryName.toLowerCase(),
          requestUrl: `/${ProductType}/${CategoryName}`,
          product: ProductType,
        })
      ) ?? []
    );
  }, [listCategoryPropertySettingVisible]);

  const title = "Property";
  const actionBarLeft = [
    <PropertyActionBarNavDropdown category={settingsRoute.path} />,
  ];
  const actionBarRight = [
    <ActionBarExportIcon />,
    <ActionBarFilterIcon />,
    <ActionBarInfoIcon />,
  ];
  const actionBarCenter = [
    <PropertyActionBarSettingNew />,
    <PropertyActionBarSettingClone />,
    <PropertyActionBarSettingDelete />,
  ];

  useEffect(() => {
    actionBarStore.setTitle(title);
    actionBarStore.setLeftComponents(actionBarLeft);
    actionBarStore.setCenterComponents(actionBarCenter);
    actionBarStore.setRightComponents(actionBarRight);
    setReferenceComponents([
      {
        title: "History",
        component: <GlobalSettingHistoryTab />,
      },
    ]);
    return () => {
      actionBarStore.resetDefault();
      referenceSideBarStore.resetDefault();
      clearNotifications();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, history.location.state]);

  useAddUniqueEventEmitter([
    {
      eventType: CCSettingListEventType.RefreshOData,
      listener: (event: IStatePropertyCategory) => {
        const notification = event?.notificationContentSuccess;
        const stateData = event?.stateData;
        loadCategory(stateData, notification);
      },
    },
  ]);

  const loadCategory = (
    configCategory?: ICategoryPropertyConfig,
    notification?: string
  ) => {
    if (configCategory) {
      const requestUrl = configCategory?.requestUrl;
      loadDataSetting(requestUrl, notification);
    } else {
      setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
    }
  };

  useEffect(() => {
    const newConfig = processCategoryConfig(categoryState);
    loadCategory(newConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, history.location.state]);

  const processCategoryConfig = (
    categoryData: string | ICategoryPropertyConfig
  ) => {
    let categoryConfig: ICategoryPropertyConfig | undefined = undefined;
    if (typeof categoryData === "object" && categoryData?.requestUrl) {
      return categoryData;
    }
    let category = "";
    if (typeof categoryData === "object" && categoryData?.category) {
      category = categoryData?.category;
    } else if (typeof categoryData === "string") {
      category = categoryData;
    }

    if (category === "") history.push("/");
    categoryConfig = categoryConfigs?.find(
      (item: ICategoryPropertyConfig) =>
        categoryData === item.category?.toLocaleLowerCase()
    );

    if (categoryConfig === undefined) {
      history.push("/");
    }
    return categoryConfig;
  };

  const isNewForm = useMemo(() => {
    if (modeSetting === MODE_SETTING.SETTINGS_ITEM) {
      if (isNew || dataSetting?.ItemId === "-1") return true;
    }
    return false;
  }, [isNew, dataSetting, modeSetting]);

  if (isLoading) return <Loading isLoading isFullScreen />;
  return (
    <div className="cc-system-admin-setting">
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            const newConfig = processCategoryConfig(categoryState);
            loadCategory(newConfig);
          }}
        />
      ) : (
        <>
          <Breadcrumb
            breadcrumb={currentBreadcrumb}
            isSettingFolder={modeSetting === MODE_SETTING.SETTINGS_FOLDER}
          />
          {modeSetting === MODE_SETTING.SETTINGS_FOLDER ? (
            <CCProductListView
              columnFields={columnFields}
              data={dataSetting}
              typeList={EListType.Setting}
              primaryField={primaryKeyTable}
              state={{ sort: sortDescriptor }}
            />
          ) : modeSetting === MODE_SETTING.SETTINGS_ITEM ? (
            <>
              {dataSetting && (
                <SettingManage isNew={isNewForm} data={dataSetting} />
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
});
