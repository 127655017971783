import { useSupplementaryRatesDetailsStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/store";
import Loading from "@components/loading/Loading";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { SupplementaryRatesDetailsFormElement } from "./components/form-element/_index";

export const SupplementaryRatesDetails = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    loadSupplementaryRatesDetails,
    isLoading,
    resetStore,
    supplementaryRatesDetails,
  } = useSupplementaryRatesDetailsStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });
  useEffect(() => {
    if (id) loadSupplementaryRatesDetails(+id);
  }, [loadSupplementaryRatesDetails, id]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <div className="cc-form">
      <Form
        initialValues={supplementaryRatesDetails?.SupplementaryDetail}
        key={JSON.stringify(supplementaryRatesDetails?.SupplementaryDetail)}
        render={(formRenderProps: FormRenderProps) => (
          <SupplementaryRatesDetailsFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
