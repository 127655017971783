import { getViewConfigurations } from "@app/products/property/api";
import {
  colFoliosAssessment,
  colHoldingAmalgamate,
} from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/associations/config";
import { EDialogStepAssociationAmalgamate } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/associations/model";
import { validatorAmalgamateAssessmentAssociation } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/associations/util";
import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { fnt_Assessment_LookupResult } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  ViewConfiguration,
} from "@app/products/property/model";
import { VO_Title } from "@app/products/property/titles/list/model";
import { processDynamicColumns } from "@app/products/property/util";
import { isSuccessResponse } from "@common/apis/util";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { difference, differenceBy, remove, union } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export const AssociationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={
        props.options?.readOnly
          ? undefined
          : validatorAmalgamateAssessmentAssociation
      }
    />
  );
};

const nameOfTitle = nameOfFactory<VO_Title>();
const nameOfAssessment = nameOfFactory<VO_Assessment_w_Detail>();

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    options = {
      isReadOnly: false,
      processAssessment: {
        isDifferentType: false,
        listIDs: [],
        setIsDifferentType: () => {},
      },
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors, onSubmit } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const assessments = getFieldValue("Assessments");
    const titles = getFieldValue("Titles");
    const selectedAssessment =
      getFieldValue("_option.AssessmentSelected") ?? [];
    const currentAssessmentIds = getFieldValue("Assessment_Ids") ?? [];

    // State
    const [isShowDialog, setIsShowDialog] =
      useState<EDialogStepAssociationAmalgamate>();
    const [columnsAssessment, setColumnsAssessment] =
      useState<IColumnFields[]>(colHoldingAmalgamate);

    //Get labels
    const [
      assessmentsLabel,
      assessmentLabel,
      assessmentLowercaseLabel,
      titlesLabel,
      titleLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.Assessments,
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.Title,
    ]);

    if (
      options?.processAssessment?.isDifferentType &&
      differenceBy(currentAssessmentIds, options?.processAssessment?.listIDs)
        ?.length
    ) {
      onChange(nameOf("Assessment_Ids"), {
        value: options?.processAssessment?.listIDs ?? [],
      });
      options?.processAssessment?.setIsDifferentType(false);
    }

    const loadViewConfig = async () => {
      setIsLoadingStep(true);
      const response = await getViewConfigurations(
        ViewConfiguration.Assessment_List
      );
      setIsLoadingStep(false);
      if (isSuccessResponse(response) && response?.data) {
        const newColumnHolding = processDynamicColumns(
          colHoldingAmalgamate,
          response.data?.ColumnDefinitions?.Columns
        );
        // Handle add new column for view config
        newColumnHolding.splice(1, 0, {
          field: "Is_Primary",
          title: `Is Primary ${assessmentLabel}`,
          format: BOOLEAN_FORMAT.BOOLEAN,
        });
        setColumnsAssessment(newColumnHolding);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadViewConfig();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    /**
     * Submit and Reload form
     */
    const submitAndReloadForm = async () => {
      onSubmit({
        currentTarget: {
          id: EListSubmitButton.SaveWorkflow,
          actions: { isShowNotification: false, reloadForm: true },
        },
      } as any);
    };

    /**
     * handle add item (Holding)
     * @param data
     * @param field
     */
    const handleAddAssessment = async (data: fnt_Assessment_LookupResult[]) => {
      const assessmentIds = data?.map((item) => item.Assessment_Id) ?? [];
      const assessmentListId = getFieldValue("Assessment_Ids") ?? [];
      const differentListID = difference(assessmentIds, assessmentListId);
      if (differentListID) {
        const concatListAssessment = union(assessmentListId, assessmentIds);
        onChange(nameOf("Assessment_Ids"), {
          value: concatListAssessment,
        });
        await submitAndReloadForm();
      }
    };

    /**
     * handle delete item
     * @param field
     */
    const handleDeleteAssessment = async () => {
      // get selected assessment
      const selectedAssess: VO_Assessment_w_Detail = selectedAssessment?.[0];
      const assessmentListId = getFieldValue("Assessment_Ids") ?? [];
      const assessId = selectedAssess?.Assessment_Id;
      remove(assessmentListId, (id: number) => id === assessId);
      onChange(nameOf("Assessment_Ids"), { value: assessmentListId });
      await submitAndReloadForm();
    };

    const handleSetPrimaryAssessment = async (id: number) => {
      if (!id) return;
      const newAssessment = assessments?.map(
        (item: VO_Assessment_w_Detail) => ({
          ...item,
          Is_Primary: id === item.Assessment_Id,
        })
      );

      onChange(nameOf("Primary_Assessment_Id"), {
        value: id,
      });

      onChange(nameOf("Assessments"), {
        value: newAssessment ?? [],
      });
      onChange(nameOf("_option.AssessmentSelected"), {
        value: [],
      });

      await submitAndReloadForm();
    };

    useEffectOnce(() => {
      (async () => await loadViewConfig())();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          responseError={loadFailedStep?.responseError}
          onReload={loadFailedStep?.onReload}
        />
      );
    }

    return (
      <section className="cc-field-group cc-associations-step">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Amalgamation date" />
            <Field
              name={nameOf("AssociationDate")}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              {assessmentsLabel}
              <CCTooltip type="validator" position="right" />
              {errors?.[nameOf("")] ? (
                errors[nameOf("")] ===
                "AssessmentsLengthAndAssessmentPrimary" ? (
                  <Error>
                    More than one {assessmentLowercaseLabel} should be
                    amalgamated and it is mandatory to set the primary{" "}
                    {assessmentLowercaseLabel}
                  </Error>
                ) : errors[nameOf("")] === "AssessmentsLength" ? (
                  <Error>
                    More than one {assessmentLowercaseLabel} should be
                    amalgamated
                  </Error>
                ) : (
                  <Error>
                    Mandatory to set the primary {assessmentLowercaseLabel}
                  </Error>
                )
              ) : null}
            </label>

            <CCGrid
              data={assessments ?? []}
              selectableMode="single"
              columnFields={columnsAssessment ?? []}
              primaryField={nameOfAssessment("Assessment_Id")}
              selectedRows={selectedAssessment ?? []}
              onSelectionChange={(dataItems) => {
                onChange(nameOf("_option.AssessmentSelected"), {
                  value: dataItems,
                });
              }}
              readOnly={options?.isReadOnly}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    type="button"
                    disabled={
                      options?.isReadOnly ||
                      assessments.length <= 0 ||
                      (selectedAssessment?.length === 1 &&
                        selectedAssessment?.[0]?.Is_Primary) ||
                      !selectedAssessment?.length
                    }
                    themeColor="primary"
                    onClick={() =>
                      handleSetPrimaryAssessment(
                        selectedAssessment?.[0]?.Assessment_Id
                      )
                    }
                  >
                    Set as primary
                  </Button>
                  <Button
                    iconClass="fas fa-plus"
                    type="button"
                    onClick={() => {
                      setIsShowDialog(
                        EDialogStepAssociationAmalgamate.NewAssessment
                      );
                    }}
                    disabled={options?.isReadOnly}
                  />
                  <Button
                    type="button"
                    iconClass="fas fa-minus"
                    disabled={
                      options?.isReadOnly ||
                      assessments.length <= 0 ||
                      (selectedAssessment?.length === 1 &&
                        selectedAssessment?.[0]?.Is_Primary) ||
                      !selectedAssessment?.length
                    }
                    onClick={() => {
                      setIsShowDialog(
                        EDialogStepAssociationAmalgamate.DeleteAsessment
                      );
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title={titlesLabel} />
            <CCGrid
              data={titles ?? []}
              columnFields={colFoliosAssessment ?? []}
              primaryField={nameOfTitle("Title_Id")}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        {isShowDialog === EDialogStepAssociationAmalgamate.NewAssessment && (
          <AddAssessmentLookupDialog
            onClose={() => {
              setIsShowDialog(undefined);
            }}
            handleAddAssessment={async (data: any) => {
              setIsShowDialog(undefined);
              handleAddAssessment(data);
            }}
          />
        )}
        {isShowDialog === EDialogStepAssociationAmalgamate.DeleteAsessment && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={`Any associated ${titleLabel} will also be removed.`}
            onClosePopup={() => setIsShowDialog(undefined)}
            onConfirmYes={handleDeleteAssessment}
          />
        )}
      </section>
    );
  }
);
