import { IPaymentOption } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPaymentOption>();
export const colPaymentOptions: IColumnFields[] = [
  {
    field: nameOf("PaymentOption"),
    title: "Payment Option",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Amount2"),
    title: "Amount 2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Amount3"),
    title: "Amount 3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Amount4"),
    title: "Amount 4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CostOfOption"),
    title: "Cost of Option",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
