import { PROPERTY_NOTICE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/constant";
import { loadLastNoticeRun } from "@app/products/property/charge-and-notice-runs/notice-runs/components/buttons/last-charge-run/api";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const LastNoticeRunButton = observer(() => {
  const history = useHistory();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickButton = async () => {
    setIsLoading(true);
    const response = await loadLastNoticeRun();
    setIsLoading(false);
    const lastNoticeRunId = response?.data?.NoticeRunId;
    if (isSuccessResponse(response) && lastNoticeRunId) {
      history.push(`${PROPERTY_NOTICE_RUNS_ROUTE}/${lastNoticeRunId}`);
    } else {
      clearNotifications();
      pushNotification({
        title: response?.error ?? "Load last notice run failed",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <CCNavButton
      className={"cc-dialog-button"}
      title="Last Notice Run"
      onClick={handleClickButton}
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
});
