import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colApplicationCategories: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationCategory_Name,
    title: "Name",
    width: 250,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ApplicationCategory_Description,
    title: "Description",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.TownPlanning,
    title: "Town Planning",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.LandDivision,
    title: "Certification",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.PSA,
    title: "PSA",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.OAs,
    title: "OA's",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Code,
    title: "Code",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.TargetDays,
    title: "Target Days",
    width: 200,
    format: DATE_FORMAT.DATE,
  },
];
