import { colBillingDetails } from "@app/products/property/meters/[id]/components/child-screens/installation/components/billing-details/config";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { CCGrid } from "@components/cc-grid/_index";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

export const InstallationBillingDetails = observer(() => {
  const {
    setSelectedAssessmentsAssociated,
    isLoadingAssessmentsAssociate,
    installation,
  } = useMeterInstallationStore();

  return (
    <div className="cc-billing-details">
      <label className="cc-label">Billing details</label>
      <div className="cc-custom-sub-panel-bar cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Category</label>
          <DropDownList
            className="cc-form-control"
            data={installation?.BillingDetails?.ListCategories}
            value={installation?.BillingDetails?.Category}
            disabled
            textField="Value"
            dataItemKey="Key"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Assessments/Account responsible for usage
          </label>
          <CCGrid
            isLoading={isLoadingAssessmentsAssociate}
            className="cc-grid-assessment-account"
            columnFields={colBillingDetails}
            data={installation?.BillingDetails?.AssessmentsAssociated || []}
            primaryField="AssessmentId"
            selectableMode="single"
            onSelectionChange={(dataItem: any[]) => {
              setSelectedAssessmentsAssociated(
                dataItem.length === 0 ? undefined : dataItem
              );
            }}
          />
        </div>
      </div>
    </div>
  );
});
