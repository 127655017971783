import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ChargeViews } from "@app/products/property/assessments/[id]/components/child-screens/charges/model";
import { ViewConfiguration } from "@app/products/property/model";
import { VO_Supplementary_Levy_Calculation } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/model";
import {
  colLevyAdjustment,
  colLevyNewCharges,
  colLevyOldCharges,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/levy-calculation/config";
import { DTO_Supplementary_Assessment_Adjustment } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/levy-calculation/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { IODataResponse } from "@common/models/odataResponse";
import { IColumnFields } from "@components/cc-grid/model";

export const getODataLevyCalculation = async (
  supplementaryAssessmentId: number,
  chargeViewType: ChargeViews
): Promise<
  APIResponse<IODataResponse<VO_Supplementary_Levy_Calculation> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/odata/property/internal/supplementaryratesassessmentlevychargesregister/${supplementaryAssessmentId}?type=${chargeViewType}&period=0&$count=true&`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getLevyCalculationTotalAmount = async (
  supplementaryAssessmentId: number
): Promise<
  APIResponse<DTO_Supplementary_Assessment_Adjustment> | undefined
> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/supplementary/levycalculation/totalamount/${supplementaryAssessmentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type ILevyCalculationResponse =
  | [
      IColumnFields[] | APIResponseError,
      IColumnFields[] | APIResponseError,
      IColumnFields[] | APIResponseError,
      APIResponse<
        IODataResponse<VO_Supplementary_Levy_Calculation> | undefined
      >,
      APIResponse<
        IODataResponse<VO_Supplementary_Levy_Calculation> | undefined
      >,
      APIResponse<
        IODataResponse<VO_Supplementary_Levy_Calculation> | undefined
      >,
      APIResponse<DTO_Supplementary_Assessment_Adjustment> | undefined
    ]
  | IColumnFields
  | APIResponseError
  | APIResponse<IODataResponse<VO_Supplementary_Levy_Calculation>>
  | APIResponse<DTO_Supplementary_Assessment_Adjustment>
  | undefined;

export const getLevyCalculationGridData = async (
  supplementaryAssessmentId: number
): Promise<ILevyCalculationResponse> => {
  try {
    return await Promise.all([
      loadViewConfiguresColumns(
        ViewConfiguration.AssessmentAdjustment_OldCharges,
        colLevyOldCharges
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.AssessmentAdjustment_NewCharges,
        colLevyNewCharges
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.AssessmentAdjustment_Adjustments,
        colLevyAdjustment
      ),
      getODataLevyCalculation(
        supplementaryAssessmentId,
        ChargeViews.OldCharges
      ),
      getODataLevyCalculation(
        supplementaryAssessmentId,
        ChargeViews.NewCharges
      ),
      getODataLevyCalculation(
        supplementaryAssessmentId,
        ChargeViews.Adjustments
      ),
      getLevyCalculationTotalAmount(supplementaryAssessmentId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
