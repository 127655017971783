import { DTO_LOV_Number } from "@common/models/odataResponse";

export interface IFilterSearch {
  searchValue: string;
  searchBy: DTO_LOV_Number;
}

export interface DTO_Title_Lookup {
  Titles: DTO_Title[];
}

export interface DTO_TitleLookupObj {
  LookupKey: number;
  LookupValue: string;
  Statuses: number[];
}
export interface DTO_Title {
  PIC: string;
  PIC_ID: string;
  LLS_Property_ID?: number;
  CadId?: number;
  StatusId?: number;
  AssociationFactorPercentage?: number;
  Active_To?: Date;
  PIC_Title_Id?: number;
  Title_Is_Part_of_Lot: boolean;
  LinkedAssessments: DTO_Title_LinkedAssessment[];
  GISReference: string;
  Section: string;
  Parish: string;
  County: string;
  Active_From?: Date;
  GIS_GlobalID: string;
  Is_Entitle?: boolean;
  Frontage?: number;
  Id: number;
  Association: string;
  Page: string;
  ExcludedArea?: number;
  Township: string;
  FurtherDescription: string;
  isAddedToGridAfterSpatial: boolean;
  CrownStatus: string;
  Portion: string;
  Block: string;
  CrownAllotment: string;
  Tenure: string;
  Child_Title_Id?: number;
  Association_Type: string;
  Subdivision: string;
  AssociatedPICAreaSet?: number;
  AssociatedPICArea?: number;
  AssociatedPICAreaTotal?: number;
  TitleId: number;
  LegalDescription: string;
  ReferenceNumber?: number;
  PlanType: string;
  Lot: string;
  Volume: string;
  PlanNumber: string;
  Status: string;
  DivisionDate?: Date;
  LandArea?: number;
  Coverage: string;
  NameAddress: string;
  IsAreaSurveyed: boolean;
  Folio: string;
  Title_Part_Lot_Number: number;
  PIC_Part_Lot_Number: number;

  // @TODO confirm later
  RowId: string;
  // #15686 - Calculation the % remaining => Calculation the Maximum base on the  % remaining
  // => the Maximum = AssociatedPICAreaRemaining + AssociatedPICAreaSetFixed
  AssociatedPICAreaRemaining?: number;
  isNewPIC: boolean;
}

export interface DTO_Title_LinkedAssessment {
  Assessment_Id: number;
  LinkedTitles: number[];
}

export interface fnt_Title_LookupResult {
  Title_Status_Name: string;
  Title_Folio: string;
  Title_Volume: string;
  Parish_Name: string;
  Title_Section: string;
  Title_Division_Date?: Date;
  Title_Is_Area_Surveyed?: boolean;
  Title_Land_Area?: number;
  Title_Frontage?: number;
  County_Name: string;
  Title_Lot: string;
  Title_Plan_Number: string;
  Plan_Type_Name: string;
  Title_Legal_Description: string;
  Title_Reference_Number?: number;
  Coverage_Name: string;
  Title_Id: number;
  Name_Address: string;
  Total_PIC_Association?: number;
}

export const folioGridLookupLoading = "Folio";
