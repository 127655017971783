import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colMasterPropertyAttributes } from "./config";
import { mockMasterPropertyAttributes } from "./mock";

export const MasterPropertyAttributes = () => {
  const [data, setData] = useState<any>(null);

  useEffectOnce(() => {
    setData(mockMasterPropertyAttributes);
  });

  return (
    <>
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">User defined attributes</label>
        </div>
      </div>
      <CCGrid
        className="cc-attributes-uda-grid"
        data={data ?? []}
        columnFields={colMasterPropertyAttributes}
        primaryField="ID"
      />
    </>
  );
};
