import {
  getRegisterSummaryById,
  postGISReference,
  putGISReference,
  removeGISReferenceAPI,
} from "@app/products/property/registers/[id]/api";
import {
  IAssociationParcel,
  IGISReferenceGrid,
  IRegistersSummary,
} from "@app/products/property/registers/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class RegisterStore {
  private _register?: IRegistersSummary = undefined;
  private _isLoading: boolean = false;
  private _selectedAssociateParcel: IAssociationParcel[] = [];
  private _responseLoadError?: APIResponseError = undefined;
  private _selectedGISReference: IGISReferenceGrid[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get register() {
    return toJS(this._register);
  }

  setRegister = (register?: IRegistersSummary) => {
    runInAction(() => {
      this._register = register;
    });
  };

  get selectedAssociateParcel() {
    return toJS(this._selectedAssociateParcel);
  }

  setSelectedAssociateParcel = (
    selectedAssociateParcel: IAssociationParcel[]
  ) => {
    runInAction(() => {
      this._selectedAssociateParcel = selectedAssociateParcel;
    });
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get registerId() {
    return toJS(this.register?.Id);
  }

  get selectedGISReference() {
    return this._selectedGISReference;
  }

  get isEmptySelectedGISReference() {
    return this.selectedGISReference.length === 0;
  }

  setSelectedGISReference = (selectedGISReference: IGISReferenceGrid[]) => {
    runInAction(() => {
      this._selectedGISReference = selectedGISReference;
    });
  };

  //Action

  get isEmptySelectedAssociateParcel(): boolean {
    return toJS(this._selectedAssociateParcel).length === 0;
  }
  //Action

  resetStore = () => {
    runInAction(() => {
      this._register = undefined;
      this._isLoading = false;
      this._selectedAssociateParcel = [];
      this._responseLoadError = undefined;
    });
  };

  loadRegister = async (registerId: number) => {
    this.setIsLoading(true);
    const response = await getRegisterSummaryById(registerId);
    let errorResponse = undefined;
    let newRegister = undefined;
    if (isSuccessResponse(response)) {
      newRegister = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setRegister(newRegister);
    this.setIsLoading(false);
  };

  addGISReference = async (GISReference: string[]) => {
    this.setIsLoading(true);
    const newRegister = await postGISReference(GISReference, this.register);
    this.setRegister(newRegister);
    this.setIsLoading(false);
  };

  editGISReference = async (
    oldGISReference: string[],
    newGISReference: string[]
  ) => {
    if (this.register?.GISReference) {
      const newRegister = await putGISReference(
        oldGISReference,
        newGISReference,
        this._register!
      );
      this.setRegister(newRegister);
      this.setSelectedGISReference([]);
    }
  };

  removeGISReference = async (selectedGISReference: string[]) => {
    if (this.register?.GISReference) {
      const newRegister = await removeGISReferenceAPI(
        selectedGISReference,
        this._register!
      );
      this.setRegister(newRegister);
      this.setSelectedGISReference([]);
    }
  };
}

const registerStoreContext = createContext(new RegisterStore());
export const useRegisterStore = () => {
  return useContext(registerStoreContext);
};
