export const narRouteStateGlobalSettings = [
  {
    name: "NAR_NameAndAddressIntegrationSettings",
    component: require("./forms/name-and-address-integration-settings").default,
  },
  {
    name: "NAR_LocationContactsSearch",
    component: require("./forms/location-contacts-search").default,
  },
  {
    name: "NAR_APIIntegrationSettings",
    component: require("./forms/api-integration-settings").default,
  },
];
