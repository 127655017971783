import { BuildingRegisterCouncilConsentForm } from "@app/products/building/registers/council-consent/[id]/components/child-screens/general/_index";
import { useBuildingRegistersCouncilConsentStore } from "@app/products/building/registers/council-consent/[id]/store";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import React from "react";

export const NewBuildingRegistersCouncilConsent = () => {
  const { isLoading } = useBuildingRegistersCouncilConsentStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Council Consent"} />
      <FormTitle title={"New Council Consent"} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          ></CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Additional address"} />
            <CCNavButton title={"Contact"} />
            <CCNavButton title={"Advertising"} />
            <CCNavButton title={"Appeal"} />
            <CCNavButton title={"Attachment"} />
            <CCNavButton title={"Comment"} />
            <CCNavButton title={"Communication"} />
            <CCNavButton title={"Complaint"} />
            <CCNavButton title={"Appeal document"} />
            <CCNavButton title={"Fee"} />
            <CCNavButton title={"Further information request"} />
            <CCNavButton title={"Inspection"} />
            <CCNavButton title={"IP - ad hoc"} />
            <CCNavButton title={"IP - from file"} />
            <CCNavButton title={"IP - from map"} />
            <CCNavButton title={"IP - from properties"} />
            <CCNavButton title={"Notice"} />
            <CCNavButton title={"Prosecution"} />
            <CCNavButton title={"Referral"} />
          </CCNavButton>,

          <CCNavButton title={"View"} type="sub">
            <CCNavButton title={"Documents"} />
            <CCNavButton title={"Journal"} />
            <CCNavButton title={"Project"} />
          </CCNavButton>,
          <CCNavButton title={"Print"} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <BuildingRegisterCouncilConsentForm />
          </div>
        </div>
      </div>
    </>
  );
};
