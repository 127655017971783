import { ContactLookahead_JSON } from "@common/models/contact";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchContactsConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

const nameOf = nameOfFactory<ContactLookahead_JSON>();

export const colSearchContacts: IColumnFields[] = [
  {
    field: nameOf("DisplayName"),
    title: "Name",
    width: 200,
  },
  {
    field: nameOf("Phone"),
    title: "Phone",
    width: 150,
  },
  {
    field: nameOf("ContactInfo"),
    title: "Address",
    width: 250,
  },
  {
    field: nameOf("Email"),
    title: "Email",
    width: 200,
  },
];
