import { INoticeRunAssessmentLov } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunAssessmentStepStore {
  private _assessmentLOVs?: INoticeRunAssessmentLov = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get assessmentLOVs() {
    return toJS(this._assessmentLOVs);
  }
  setAssessmentLOVs = (assessmentLOVs?: INoticeRunAssessmentLov) => {
    runInAction(() => {
      this._assessmentLOVs = assessmentLOVs;
    });
  };
}

const noticeRunAssessmentStepStoreContext = createContext(
  new NoticeRunAssessmentStepStore()
);
export const useNoticeRunAssessmentStepStore = () => {
  return useContext(noticeRunAssessmentStepStoreContext);
};
