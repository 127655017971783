import { loadAssessmentCertificate } from "@app/products/property/assessments/[id]/components/child-screens/certificates/api";
import { DTO_Component_Certificates } from "@app/products/property/assessments/[id]/components/child-screens/certificates/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentCertificateStore {
  private _certificate?: DTO_Component_Certificates = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get certificate() {
    return this._certificate;
  }
  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setCertificate = (certificate?: DTO_Component_Certificates) => {
    runInAction(() => {
      this._certificate = certificate;
    });
  };

  //Action

  loadCertificate = async (assessmentId: number) => {
    this.setIsLoading(true);
    const certificate = await loadAssessmentCertificate(assessmentId);
    this.setCertificate(certificate);
    this.setIsLoading(false);
  };
}

const assessmentCertificateStoreContext = createContext(
  new AssessmentCertificateStore()
);
export const useAssessmentCertificateStore = () => {
  return useContext(assessmentCertificateStoreContext);
};
