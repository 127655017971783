import {
  action,
  configure,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import React, { createContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class InspScheduleCalendarStore {
  selectedBuildingCalendarFilter: string = "everyone";

  setBuildingCalendarFilter = (filter: any) => {
    runInAction(() => {
      this.selectedBuildingCalendarFilter = filter.key;
    });
  };

  constructor() {
    makeObservable(this, {
      selectedBuildingCalendarFilter: observable,
      setBuildingCalendarFilter: action,
    });
  }
}

const inspScheduleCalendarStoreContext = createContext(
  new InspScheduleCalendarStore()
);

export const useInspScheduleCalendarStore = () => {
  const context = React.useContext(inspScheduleCalendarStoreContext);
  if (context === undefined) {
    throw new Error(
      "useInspScheduleCalendarStore in not within New InspScheduleCalendarStore"
    );
  }
  return context;
};
