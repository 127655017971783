import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const nameStreetLocalityRoute: ICCRoute = {
  path: "name-street-locality",
  name: "Name, Street, Locality",
  enum: eMenuAction.Entities_PotentialDuplicates_NameStreetLocality,
  children: [
    {
      path: "all",
      name: "All",
      enum: eMenuAction.Entities_PotentialDuplicates_NameStreetLocality_All,
      component: require("./all/_index").default,
    },
    {
      path: "active",
      name: "Active",
      enum: eMenuAction.Entities_PotentialDuplicates_NameStreetLocality_Active,
      component: require("./active/_index").default,
    },
  ],
};
