import React from 'react';

interface IProps {}

export const MailMessagesTab = (props: IProps) => {
  return (
    <div className={'pt-4'}>
      <b>This is MailMessages Tab !</b>
    </div>
  );
};
