import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CommunicationTemplateHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { communicationTemplate, communicationTemplateId } =
    useCommunicationTemplateStore();
  const communicationTemplateID =
    communicationTemplateId ?? lastSelectedRow?.ID;
  return (
    <Journal
      id={communicationTemplateID}
      triggersReload={communicationTemplate}
      recordType={RECORDTYPE.CORE_CommunicationTemplate}
    />
  );
});
