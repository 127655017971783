import { CCDatePickerEditCell } from "@app/products/property/components/grid/components/cc-date-picker-edit-cell/_index";
import { DTO_Entity_NominatedProperty } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { DTO_Entity_VotingEntitlement } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfNominatedProperty = nameOfFactory<DTO_Entity_NominatedProperty>();
const nameOfVotingEntitlement = nameOfFactory<DTO_Entity_VotingEntitlement>();
export const colNominateProperty: IColumnFields[] = [
  {
    field: nameOfNominatedProperty("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
    minWidth: 180,
    editCell: CCDatePickerEditCell,
    editable: true,
  },
  {
    field: nameOfNominatedProperty("Finish_Date"),
    title: "Finish Date",
    format: DATE_FORMAT.DATE,
    minWidth: 180,
    editCell: CCDatePickerEditCell,
    editable: true,
  },
  {
    field: nameOfNominatedProperty("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfNominatedProperty("Assessment_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfNominatedProperty("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfNominatedProperty("Nominated_Id"),
    title: "Nominated Property ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
export const colVotingEntitlementByElection: IColumnFields[] = [
  { field: nameOfVotingEntitlement("Election"), title: "Election" },
  {
    field: nameOfVotingEntitlement("Can_Vote"),
    title: "Could Vote?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOfVotingEntitlement("Role_Name"), title: "Role" },
  {
    field: nameOfVotingEntitlement("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfVotingEntitlement("Assess_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfVotingEntitlement("Property_Name_Address_Locality"),
    title: "Property Address",
  },
];
