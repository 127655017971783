import { DTO_LOV } from "@common/models/odataResponse";

export const searchNameConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

export const ID_FOR_ITEM_NOT_FOUND = -1;
export const listSearchOptions: DTO_LOV[] = [
  { Code: "1", Name: "Starts with" },
  { Code: "2", Name: "Contains" },
  { Code: "3", Name: "Ends with" },
  { Code: "4", Name: "Exact match" },
];
