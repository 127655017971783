import { history } from "@/AppRoutes";
import { IPPREnquiryParentSection } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { CredentialType } from "@common/constants/enumerations";
import { APIResponseStatus } from "@common/constants/response-status";
import { IdentityPacket } from "@common/models/identityPacket";
import { ASPNET_MembershipUser } from "@common/models/user";
import {
  getMembershipUserByUserName,
  getSiteUserById,
  getSiteUserLOV,
  postSaveSiteUser,
  putSaveSiteUser,
} from "@common/pages/settings/security/site-users/_id/api";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
import {
  NewSiteUser,
  SiteUserLovs,
  SiteUserSubmitActions,
} from "@common/pages/settings/security/site-users/_id/model";
import {
  convertDefaultActionPageEnum,
  convertProductListViewEnum,
} from "@common/pages/settings/security/site-users/util";
import { defaultThemeLKPID } from "@common/pages/settings/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class siteUserStore {
  private _siteUser?: NewSiteUser = undefined;
  private _siteUserLovs?: SiteUserLovs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _parentSection?: IPPREnquiryParentSection = undefined;
  private _isInactive: boolean = false;
  private _membership?: ASPNET_MembershipUser;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get siteUser() {
    return toJS(this._siteUser);
  }
  setSiteUser = (siteUser?: any) => {
    runInAction(() => {
      this._siteUser = siteUser;
    });
  };

  get siteUserLovs() {
    return toJS(this._siteUserLovs);
  }
  setSiteUserLovs = (siteUserLovs?: SiteUserLovs) => {
    runInAction(() => {
      this._siteUserLovs = siteUserLovs;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPPREnquiryParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get siteUserId() {
    return toJS(this.siteUser?.Contact_SiteUser?.Contact_ID);
  }

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get membership() {
    return this._membership;
  }

  setMembership = (membership?: ASPNET_MembershipUser) => {
    runInAction(() => {
      this._membership = membership;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._siteUser = undefined;
      this._siteUserLovs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._parentSection = undefined;
      this._membership = undefined;
    });
  };

  reloadSiteUser = async (): Promise<boolean> => {
    if (this.siteUserId) {
      return await this.loadSiteUser(this.siteUserId);
    }
    return false;
  };

  loadSiteUser = async (
    siteUserId?: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    let newSiteUser = new NewSiteUser();
    if (newSiteUser.siteUserCredential)
      newSiteUser.siteUserCredential.CredentialType_ENUM =
        CredentialType.Exchange;
    this.setIsLoading(true);
    // Load LOVs data
    const lovResponse = await getSiteUserLOV();
    if (isSuccessResponse(lovResponse) && lovResponse.data) {
      lovResponse.data.DefaultPage = convertProductListViewEnum(
        lovResponse.data?.DefaultPage
      );
      lovResponse.data.DefaultActionPages = convertDefaultActionPageEnum(
        lovResponse.data?.DefaultActionPages
      );
      this.setSiteUserLovs(lovResponse.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    if (!isNew && siteUserId) {
      const response = await getSiteUserById(siteUserId);
      if (isSuccessResponse(response) && response?.data) {
        newSiteUser = { ...newSiteUser, ...response.data };
        if (!newSiteUser._option) return false;
        newSiteUser._option.Contact_SiteUser.Sys_DBRowState =
          response?.data?.Contact_SiteUser?.Sys_DBRowState === "Active"
            ? true
            : false;
        const responseMembership = await getMembershipUserByUserName(
          response.data?.Contact_SiteUser?.MemberUserName
        );
        this.setMembership(responseMembership?.data);
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
    }

    this.setSiteUser({
      ...newSiteUser,
      Contact_SiteUser: {
        ...newSiteUser.Contact_SiteUser,
        Theme_LKP:
          newSiteUser.Contact_SiteUser?.Theme_LKP ??
          defaultThemeLKPID(this.siteUserLovs?.Theme),
      },
    });
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveSiteUser = async (
    siteUserInfo: NewSiteUser,
    action: SiteUserSubmitActions,
    isNew: boolean
  ) => {
    siteUserInfo._option = undefined;
    this.setIsLoading(true);

    siteUserInfo.UserName = siteUserInfo.Contact_SiteUser?.MemberUserName;
    const response = await (isNew
      ? postSaveSiteUser(siteUserInfo)
      : putSaveSiteUser(this.siteUserId as number, siteUserInfo));
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearNotifications();
      if (this.siteUserId) await this.loadSiteUser(this.siteUserId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save site user failed",
        type: "error",
        description: response?.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (
    action: SiteUserSubmitActions,
    response?: APIResponse<IdentityPacket | undefined>
  ) => {
    switch (action) {
      case SiteUserSubmitActions.Save:
        appNotificationStore.pushNotification({
          title: "Site user saved successfully",
          type: "success",
        });
        break;
      case SiteUserSubmitActions.New:
        if (isSuccessResponse(response)) {
          history.replace(`${SITE_USER_ROUTE}/${response?.data?.ID}`, {
            notification: [
              {
                title: "Site user saved successfully",
                type: "success",
              },
            ],
          });
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Save site user failed",
            type: "error",
            description: response?.data?.Errors ?? response?.statusText,
          });
        }
        break;
    }
  };
}

export const siteUserInstance = new siteUserStore();
const siteUserContext = createContext(siteUserInstance);
export const useSiteUserStore = () => {
  return useContext(siteUserContext);
};
