import {
  ServiceStandardSubCategory,
  ServiceStandardSystemAdminLovs,
} from "@app/products/crms/system-admin/categories/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket, ResponsePacket } from "@common/models/identityPacket";

export const getCrmsSystemAdminLovs = async (): Promise<
  APIResponse<ServiceStandardSystemAdminLovs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<ServiceStandardSystemAdminLovs>(
      "api/crms/internal/system-admin/category/lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const loadSubCategoryById = async (
  id: number
): Promise<APIResponse<ServiceStandardSubCategory | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ServiceStandardSubCategory>(
      `api/crms/internal/system-admin/subcategory/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveSubCategory = async (
  crmsSubcategory: ServiceStandardSubCategory
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/crms/internal/system-admin/subcategory",
      crmsSubcategory
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const deleteSubCategory = async (
  subCategoryId: number
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      "api/crms/internal/system-admin/subcategory/delete",
      subCategoryId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const deleteServiceStandard = async (
  serviceStandardId: number
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      "api/crms/internal/system-admin/subcategory/service-standard/delete",
      serviceStandardId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
