import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import {
  EPrivilegesWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { postProcessSupplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/complete-incomplete/api";
import { defaultWorkflowHeaderCompleteSupplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/complete-incomplete/config";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

interface ICompleteIncompleteButtonProps {
  isSupplementaryComplete?: boolean;
  isCompleteButton?: boolean;
}

export const CompleteIncompleteButton = observer(
  ({
    isSupplementaryComplete = false,
    isCompleteButton = true,
  }: ICompleteIncompleteButtonProps) => {
    const { id: supplementaryId } = useParams<{ id: string }>();
    const { pushNotification } = useCCAppNotificationStore();
    const { reLoadSupplementaryRates } = useSupplementaryRatesStore();
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdateSupplementaryStatus = async () => {
      if (supplementaryId) {
        setIsLoading(true);
        const response = await postProcessSupplementary(
          WorkflowProcessMode.Finish,
          {
            WorkflowHeader: defaultWorkflowHeaderCompleteSupplementary,
            WorkflowDetail: {
              SupplementaryId: parseInt(supplementaryId),
            },
            WorkflowTypes: isCompleteButton
              ? WorkflowTypes.Complete_Supplementary
              : WorkflowTypes.Incomplete_Supplementary,
          }
        );
        setIsLoading(false);
        setShowDialog(false);
        const defaultSuccessMessage = isCompleteButton
          ? "Complete supplementary successfully"
          : "Supplementary status was changed to incomplete";
        const defaultFailedMessage = isCompleteButton
          ? "Complete supplementary failed"
          : "Supplementary status could not be changed to incomplete";
        if (isSuccessResponse(response) && response?.data?.IsSuccess) {
          pushNotification({
            title: defaultSuccessMessage,
            type: "success",
          });
          reLoadSupplementaryRates(); //Refresh supplementary rate info
          eventEmitter.emit(CCJournalEventType.RefreshData); //Refresh history tab
        } else {
          pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        }
      }
    };

    return (
      <>
        <PrivilegeWrapper
          dialogType={ePermissionPrivilegeTypeCheck.Dialog}
          privilegesEnum={
            isCompleteButton
              ? EPrivilegesWorkflow.Complete_Supplementary
              : EPrivilegesWorkflow.Supplementary_Incomplete
          }
          shouldApplyWrapper={!isCompleteButton}
        >
          <CCNavButton
            disabled={isCompleteButton === isSupplementaryComplete}
            title={isCompleteButton ? "Complete" : "Incomplete"}
            onClick={() => {
              setShowDialog(true);
            }}
          />
        </PrivilegeWrapper>
        {showDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={`Are you sure you want to ${
              isCompleteButton
                ? "complete"
                : "change the status to incomplete for"
            } this supplementary?`}
            onClosePopup={() => setShowDialog(false)}
            onAsyncConfirm={handleUpdateSupplementaryStatus}
            isLoadingYes={isLoading}
          />
        )}
      </>
    );
  }
);
