import { colLocalLawsPermitsBy } from "@app/products/local-laws/permits/config";
import { Svc_LLPermit } from "@app/products/local-laws/permits/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_LLPermit>();
export const colLocalLawsPermitsInsuranceStatus: IColumnFields[] = [
  ...colLocalLawsPermitsBy,
  {
    field: nameOf("InsurancePolicyNumber"),
    title: "Policy Number",
  },
  {
    field: nameOf("InsuranceCompany"),
    title: "Insurance Company",
  },
  {
    field: nameOf("InsuranceDateExpires"),
    title: "Insurance Expires",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InsuranceDueStatus"),
    title: "Insurance Due Status",
  },
];
