import { useMeterLeakageStore } from "@app/products/property/meters/[id]/components/child-screens/leakage/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";

interface IConfirmDeleteLeakageDialogProps {
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfirmDeleteLeakageDialog = observer(
  ({ setShowDialog }: IConfirmDeleteLeakageDialogProps) => {
    const { selectedLeakage, removeLeakage } = useMeterLeakageStore();
    const { pushNotification } = useCCAppNotificationStore();
    return (
      <ConfirmDialog
        title={"Confirm Removal"}
        message={<></>}
        subMessage={<p>Do you wish to remove selected leakage?</p>}
        width={350}
        height={"auto"}
        onClosePopup={() => {
          setShowDialog(false);
        }}
        onConfirmYes={() => {
          setShowDialog(false);
          removeLeakage(selectedLeakage);
          pushNotification({
            title: `Meter leakage removed successfully`,
            type: "success",
          });
        }}
      />
    );
  }
);
