import { getResponseBatchDetails } from "@app/products/property/centrelink-customer-confirmation/view-responses/api";
import { DTO_CentrelinkResponseBatchDetail } from "@app/products/property/centrelink-customer-confirmation/view-responses/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class CentreLinkResponseBatchStore {
  private _responseBatchDetails?: DTO_CentrelinkResponseBatchDetail = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseBatchDetails() {
    return this._responseBatchDetails;
  }
  setResponseBatchDetails = (
    responseBatchDetails?: DTO_CentrelinkResponseBatchDetail
  ) => {
    runInAction(() => {
      this._responseBatchDetails = responseBatchDetails;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetCentreLinkResponseBatchStore = () => {
    runInAction(() => {
      this._responseBatchDetails = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadResponseBatchDetails = async () => {
    this.setResponseLoadError(undefined);
    this.setIsLoading(true);
    const response = await getResponseBatchDetails();
    this.setIsLoading(false);
    if (isSuccessResponse(response)) {
      this.setResponseBatchDetails(response.data);
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response.error,
      });
    }
  };

  reLoadResponseBatchDetails = async (): Promise<void> => {
    await this.loadResponseBatchDetails();
  };
}
const centreLinkResponseBatchStoreContext = createContext(
  new CentreLinkResponseBatchStore()
);
export const useCentreLinkResponseBatchStore = () => {
  return useContext(centreLinkResponseBatchStoreContext);
};
