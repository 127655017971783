export enum CEP_ProductType {
  Unspecified = "Unspecified",
  CRMS = "CRMS",
  Infringements = "Infringements",
  Animals = "Animals",
  eBuilding = "eBuilding",
  RAD = "RAD",
  DWRS = "DWRS",
  ePlanning = "ePlanning",
  eParkingPermit = "eParkingPermit",
}

export enum CEP_ProductTypeNumber {
  Unspecified = 0,
  CRMS = 1,
  Infringements = 2,
  Animals = 4,
  eBuilding = 8,
  RAD = 16,
  DWRS = 32,
  ePlanning = 64,
  eParkingPermit = 128,
}
