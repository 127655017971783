import { history } from "@/AppRoutes";
import { UITownPlanning } from "@app/products/town-planning/model";
import { ILodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/model";
import { LodgeApplicationDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/lodge-application/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import {
  putCreateLodgeApplication,
  putLodgeApplication,
} from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/lodge-application/api";
import { TOWN_PLANNING_PSAR_ROUTE } from "@app/products/town-planning/ppr/psa-referrals/_id/constant";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import {
  Application,
  PPRApplicationIdentityPacket,
} from "@app/products/town-planning/ppr/[id]/model";
import { APIResponse } from "@common/apis/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  IAppNotificationItem,
  IAppNotificationItemAddProps,
} from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const LodgeApplicationButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { ppr, onSubmit, setPPRWithLoading, pprId } = usePSARStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [newApplication, setNewApplication] = useState<Application>();
  const isNew = useIsNew();

  const isVisible = useMemo(() => {
    return Application_Status.NewApplication === ppr?.Status_ENUM;
  }, [ppr]);

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.Lodge.toString(),
      listener: (pprEnquiryInfo: Application) => {
        setNewApplication(pprEnquiryInfo);
        setIsShowDialog(true);
      },
    },
  ]);

  const handleSubmit = async (data: ILodgeApplicationDialog) => {
    setIsLoading(true);
    let response: APIResponse<PPRApplicationIdentityPacket> | undefined =
      undefined;

    if (isNew && newApplication) {
      const newPPRApplication = cloneDeep(newApplication);
      newPPRApplication.LodgedDate = data.DateLodged;
      response = await putCreateLodgeApplication(newPPRApplication);
    } else if (pprId) {
      response = await putLodgeApplication(pprId, data.DateLodged);
    }

    if (isSuccessIdentityPacket(response) && response?.data?.Application) {
      setIsShowDialog(false);
      clearNotifications();
      setPPRWithLoading(response.data.Application);
      if (isNew) {
        const notifications: IAppNotificationItemAddProps[] = [
          {
            type: "success",
            title: "Enquiry lodged successfully",
          },
        ];
        if (
          response?.data?.Notifications &&
          response?.data?.Notifications.length > 0
        ) {
          notifications.push({
            autoClose: false,
            title: "Lodge Application",
            type: "warning",
            description: response?.data?.Notifications,
          });
        }
        history.replace(`${TOWN_PLANNING_PSAR_ROUTE}/${response?.data?.ID}`, {
          notification: notifications,
        });
      } else {
        pushNotification({
          type: "success",
          title: "Application lodged successfully",
        });
        if (
          response.data?.Notifications &&
          response.data?.Notifications.length > 0
        ) {
          pushNotification({
            autoClose: false,
            title: "Logde",
            type: "warning",
            description: response.data?.Notifications,
          });
        }
      }
    } else {
      setNotifications([
        {
          id: "1",
          title: "The application could not be lodged.",
          type: "error",
          autoClose: false,
          description: response?.data?.Errors ?? response?.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };

  return isVisible ? (
    <>
      <CCNavButton
        title="Lodge application"
        onClick={onSubmit}
        isLoading={isLoading}
        name={
          isNew
            ? PSARSubmitActions.CreateAndLodgeApplication
            : PSARSubmitActions.LodgeApplication
        }
      />
      {isShowDialog && (
        <LodgeApplicationDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          notifications={notifications}
          isLoading={isLoading}
        />
      )}
    </>
  ) : null;
});
