import { PopulateCandidatesDialogHeaderNote } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/populate-candidates/components/header-note/_index";
import { IPopulateCandidateDialog } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/populate-candidates/model";
import { useComplianceCandidateStore } from "@app/products/property/assessments/compliance/candidates/store";
import { nameOfFactory } from "@common/utils/common";
import { formatAssessmentNumberInMasked } from "@common/utils/formatting";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import "./_index.scss";

const nameOf = nameOfFactory<IPopulateCandidateDialog>();

interface IPopulateCandidatesDialog {
  onClose: () => void;
}

export const PopulateCandidatesDialog = observer(
  ({ onClose }: IPopulateCandidatesDialog) => {
    const { populateCandidateData, loadCandidate } =
      useComplianceCandidateStore();

    const initialValues = useMemo(() => {
      return populateCandidateData ? cloneDeep(populateCandidateData) : {};
    }, [populateCandidateData]);

    const handleSubmit = (values: IPopulateCandidateDialog) => {
      loadCandidate(
        values?.assessmentNumberFrom,
        values?.assessmentNumberTo,
        values?.activeCompliance ?? false,
        values?.isChangeOfOwnership ?? false,
        values?.isNACareOf ?? false,
        values?.isPOBox ?? false,
        values?.isCommonProperty ?? false
      );
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={(event: FormSubmitClickEvent) =>
          handleSubmit(event.values)
        }
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange } = formRenderProps;
          const handleOnChange = (event: any, assessmentNameField: string) => {
            const rawDataOnlyNumber =
              event.target.rawValue?.replace(/\D/g, "") ?? "";
            const newValue = formatAssessmentNumberInMasked(rawDataOnlyNumber);
            if (valueGetter(assessmentNameField) !== newValue) {
              onChange(assessmentNameField, {
                value: newValue,
              });
            }
          };
          return (
            <CCDialog
              titleHeader={<PopulateCandidatesDialogHeaderNote />}
              onClose={onClose}
              height="60%"
              maxWidth="40%"
              bodyElement={
                <div className="cc-populate-candidates-dialog cc-form">
                  <FormElement className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <section className="cc-field-group cc-populate-candidates-assessments">
                        <label className="cc-label">Assessments</label>
                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">From</label>
                              <Field
                                name={nameOf("assessmentNumberFrom")}
                                component={CCMaskedTextBox}
                                placeholder={"123-456-7890-123"}
                                mask="000-000-0000-000"
                                onChange={(event: any) =>
                                  handleOnChange(
                                    event,
                                    nameOf("assessmentNumberFrom")
                                  )
                                }
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">To</label>
                              <Field
                                name={nameOf("assessmentNumberTo")}
                                component={CCMaskedTextBox}
                                placeholder={"123-456-7890-123"}
                                mask="000-000-0000-000"
                                onChange={(event: any) =>
                                  handleOnChange(
                                    event,
                                    nameOf("assessmentNumberTo")
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="cc-field-group ">
                        <label className="cc-label">
                          Exclude land tax exemptions if
                        </label>

                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                Already under investigation (active compliance)
                              </label>
                              <Field
                                name={nameOf("activeCompliance")}
                                component={CCSwitch}
                                defaultChecked={initialValues.activeCompliance}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                No change of ownership after last compliance
                              </label>
                              <Field
                                name={nameOf("isChangeOfOwnership")}
                                component={CCSwitch}
                                defaultChecked={
                                  initialValues.isChangeOfOwnership
                                }
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Ratepayer has a 'care of'
                              </label>
                              <Field
                                name={nameOf("isNACareOf")}
                                component={CCSwitch}
                                defaultChecked={initialValues.isNACareOf}
                              />
                            </div>

                            <div className="cc-field">
                              <label className="cc-label">
                                Addressed to a post office box
                              </label>
                              <Field
                                name={nameOf("isPOBox")}
                                component={CCSwitch}
                                defaultChecked={initialValues.isPOBox}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Common property
                              </label>
                              <Field
                                name={nameOf("isCommonProperty")}
                                component={CCSwitch}
                                defaultChecked={initialValues.isCommonProperty}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={
                      !formRenderProps.modified || !formRenderProps.valid
                    }
                    onClick={formRenderProps.onSubmit}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
