import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const pprSettingEnums = [
  ECorporateSettingsField.CorporateSettings_RecordsSystem,
  ECorporateSettingsField.TP_DisplayPropertyZoneOverlay,
  ECorporateSettingsField.CorporateSettings_PropertySystem,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_OwnerBusinessNumbers,
  ECorporateSettingsField.Global_Contact_SplitAddressName,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_OwnerStreetNumber,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_NearestCrossStreet,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_BuildingName,
  ECorporateSettingsField.TP_DisplayControlLine,
  ECorporateSettingsField.TP_StatBasedOnValidation,
  ECorporateSettingsField.TP_StatTargetDaysEnabled,
  ECorporateSettingsField.TP_ApplicationBannerShowAppealDays,
  ECorporateSettingsField.TP_StatDaysFTDDays,
  ECorporateSettingsField.CorporateSettings_AddressValidation,
  ECorporateSettingsField.CorporateSettings_PropertyAutoPopupDialog,
  ECorporateSettingsField.TRIMAdmin_TownPlanningStorageMethod,
  ECorporateSettingsField.CorporateSettings_RecordsSetFileNoWithPropertyNo,
  ECorporateSettingsField.Records_PropertyNoMask,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_OwnerStreetNumber,
  ECorporateSettingsField.TP_ApplicationValidation,
  ECorporateSettingsField.TP_Application_PPR,
  ECorporateSettingsField.TP_Application_PSA,
  ECorporateSettingsField.TP_Application_OAs,
  ECorporateSettingsField.TP_EnableReadyforDecision_Approval_Workflow,
  ECorporateSettingsField.TP_PPRReportLetterDocuments,
  ECorporateSettingsField.TP_PPRResponseLetterDocuments,
  ECorporateSettingsField.TP_DisplayRegister, //Show hide Mail Merge & Communication
  ECorporateSettingsField.TP_UseVicSmart,
  ECorporateSettingsField.CorporateSettings_Action_ShowDetailsForMeetings,
  ECorporateSettingsField.TP_PlansToComplyInheritApplicant,
  ECorporateSettingsField.TP_AmendmentInheritApplicant,
  ECorporateSettingsField.TP_InheritAssessmentDetailsOnAmendment,
  ECorporateSettingsField.FileitAdmin_PPR_AmendmentInheritApplicationFileNumber,
  ECorporateSettingsField.FileitAdmin_PPR_PlansToComplyInheritApplicationFileNumber,
  ECorporateSettingsField.TRIMAdmin_EnquiryStorageMethod,
  ECorporateSettingsField.infoXpert_EnquiryStorageMethod,
  ECorporateSettingsField.CorporateSettings_UseImageGallery,
  ECorporateSettingsField.CorporateSettings_FileExtension,
  ECorporateSettingsField.Global_EnableAttachmentSizeRestriction,
  ECorporateSettingsField.Global_AttachmentSizeRestrictionSize,
  ECorporateSettingsField.Global_EnableFileAttachmentMultiple,
  ECorporateSettingsField.TP_PSARReportLetterDocuments,
  ECorporateSettingsField.TP_PSARResponseLetterDocuments,
  ECorporateSettingsField.TP_PSA_LabelFor_ReadytoSendtocouncilForAuth,
  ECorporateSettingsField.TP_PSARPanelHearingReportLetterDocuments,
  ECorporateSettingsField.TP_PPRLabelForTL,
  ECorporateSettingsField.TP_PPRVCATTemplateURL,
];

export const pprApplicationFrom = {
  Status_ENUM: Application_Status.NewApplication,
  Sys_CreatedDate: new Date(),
  Date_Created: new Date(),
  Flag_EnforcementIssue: false,
  Flag_PreApplicationMeeting: false,
  Flag_PrivateSurveyor: false,
  Flag_PlanningPrivateSurveyor: false,
  Flag_LandDivision: false,
  Flag_PlansToComply: false,
  Flag_IsPSA: false,
  Flag_IsOA: false,
  BuildingDetails: {
    Building_StageNumber: "0",
    Building_TotalFloorArea: 0,
    Building_BCA: [],
    ReportingAuthorities: [],
    Building_BCAVersion: [],
    Building_SpecificWork: [],
    Building_BuildingWorks_Display: [],
    Building_UseOfBuildings: [],
  },
  AssessmentPlanner: { AssessmentPlanner_Images: [] },
  StatDays: {
    StatDays: 0,
    GrossDays: 0,
    AppealDays: 0,
    TargetDays: 0,
    StartDateDescription: "",
    EndDateDescription: "",
    StartDate: null,
    TargetDate: null,
  },
  PPRDetails: {},
  AdditionalAddresses: [],
  BuildingWorks: {
    Nature: {
      IsPNF: false,
      IsLandManagement: false,
      WorkFlowAutoActionTypes: [],
      ApplicationCategoryFees: [],
      ApplicationAssessment: [],
    },
    Use: {},
    Class: {},
  },
  ApplicationCategories: [],
  PrescribeEvents: { Sent_Counter: 0 },
  IsAmendment: false,
  Flag_IsStateGovtApp: false,
};

export const pprValidateSaveActions = {
  ApproveReportAndResponse: "ApproveReportAndResponse",
  ReportAndResponseNotApproved: "ReportAndResponseNotApproved",
  SendForTLApproval: "SendForTLApproval",
  ResendForTLApproval: "ResendForTLApproval",
};
