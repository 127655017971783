import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { AddressBookSection } from "@common/constants/enumerations";
import { Address } from "@common/input-pickers/address/model";
import { Contact } from "@common/models/contact";

export interface Svc_MergeContacts_SubContact {
  CurrAction: string;
  SubContactID: number;
  DisplayName: string;
}

export enum ContactClassification {
  SystemInitialise = 0,
  Contact = 1,
  SiteUser = 2,
  Person = 3,
  Company = 4,
  Partnership = 5,
  IncorporatedBody = 6,
  PortalUser = 7,
  PrivateInspector = 8,
  CRS_Parent = 10,
  CRS_Child = 11,
  ProprietorBus_Individual = 15,
  ProprietorBus_Company = 16,
  ProprietorBus_Partnership = 17,
  ProprietorComm_NotForProfit = 18,
  ProprietorComm_IndividualForCharity = 19,
  ProprietorComm_BodyForCharity = 20,
  VCATBoardRepresentative = 21,
  GroupCoordinator = 22,
}

export interface Svc_MergeContacts {
  SelectedContactIDs: number[];
  MasterContactID: string;
  MasterDisplayName: string;
  MasterWorkPhone: string;
  MasterHomePhone: string;
  MasterMobile: string;
  MasterEmail: string;
  MasterPostalAddress?: number;
  MasterStreetAddress?: number;
  MasterContactType: ContactClassification;
  MasterAddressBook?: AddressBookSection;
  SubContacts: Svc_MergeContacts_SubContact[];
}

export interface MergeContactsLovs {
  WorkPhone: string[];
  HomePhone: string[];
  Mobile: string[];
  Email: string[];
  AddressBook: KeyValuePacket[];
  ContactType: KeyValuePacket[];
  PostalAddress: Address[];
  PreferredAddress: Address[];
}

export interface Svc_SetupMergeContacts {
  Contacts: Contact[];
  MergeContactsLovs: MergeContactsLovs;
}

export enum ContactAction {
  Merge = "Merge",
  Delete = "Delete",
  NoAction = "NoAction",
}
