import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { ICCInputFilterProps } from "@components/cc-product-list-view/components/list-view-filter-bar/model";
import { isCurrencyFilter } from "@components/cc-product-list-view/components/list-view-filter-bar/util";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";

export const CCNumericFilter = (props: ICCInputFilterProps) => {
  const { filter, onFilterChange, item } = props;

  const onChange = (event: NumericTextBoxChangeEvent) => {
    onFilterChange.call(undefined, {
      nextFilter: { ...filter, value: event.value ?? null },
    });
  };

  if (isCurrencyFilter(item.format)) {
    return (
      <CCCurrencyInput
        name={item.field}
        value={filter.value}
        onChange={onChange}
      />
    );
  }
  return (
    <CCNumericTextBox
      format={item.format}
      value={filter.value}
      onChange={onChange}
    />
  );
};
