import { FirePreventionMemo } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/model";

export const mockFirePrevention: FirePreventionMemo[] = [
  {
    Memo: "GROB to be sent to:Ian and Kerrie BirchPO Box 225 Pinijarra Western",
    MemoDate: new Date("26 Aug 2019"),
    Category: "General",
    ChangedOn: new Date("26 Aug 2019 11:32"),
    ChangedBy: "PADTS",
    MemoId: 1160,
  },
];
