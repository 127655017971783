import { IMeterTransferred } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-meter/components/form-elements/transferring/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IMeterTransferred>();
export const colTransfer: IColumnFields[] = [
  {
    field: nameOf("MeterNumber"),
    title: "Meter Number",
  },
  {
    field: nameOf("Change"),
    title: "Change",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("MeterID"),
    title: "Meter ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
