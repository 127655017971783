import { apiCore } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getDocumentsOptionStatusLOV = async (): Promise<
  APIResponse<IKeyValuePacket[]>
> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `/api/core/internal/documents/statuslov`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getDocumentContentDownload = async (
  id?: number
): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(apiCore(id), {
      responseType: "blob",
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
