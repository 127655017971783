import { isSuccessResponse } from "@common/apis/util";
import { getExportXml } from "@common/pages/report/integrated-reports/component/buttons/export-xml/api";
import { convertArrJSONToXML } from "@common/pages/report/integrated-reports/component/buttons/export-xml/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ExportXmlButton = observer(() => {
  const { isEmptyGridSelectedRow, gridSelectedIds } =
    useCCProductListViewStore();
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();

  const exportXml = async () => {
    setIsLoading(true);
    const response = await getExportXml(gridSelectedIds);
    if (isSuccessResponse(response)) {
      setIsLoading(false);
      pushNotification({
        type: "success",
        title: "Export xml successfully",
      });
      const excelContent = convertArrJSONToXML(
        response?.data?.IntegratedReportImports
      );
      const newDecode =
        "data:Application/octet-stream," + encodeURIComponent(excelContent);
      var a = document.createElement("a");
      a.href = newDecode;
      a.download = "Export.xml";
      a.click();
    } else {
      setIsLoading(false);
      pushNotification({
        autoClose: false,
        title: "Error xml",
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        isLoading={isLoading}
        title="Export XML"
        onClick={exportXml}
        disabled={isEmptyGridSelectedRow || isLoading}
      />
    </>
  );
});
