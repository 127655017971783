import { mockInspections } from "@app/products/property/fire-prevention/historical/inspections/mock";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadSearchCaseId = async (filter: FilterDescriptor) => {
  const inspections = new Set(mockInspections.map((item) => item.CaseId));

  const data = Array.from(inspections).slice();
  await sleep(1000);
  return filterBy(data, filter);
};
