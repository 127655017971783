import {
  postCSLSystemAdminConditons,
  putCSLSystemAdminConditons,
} from "@app/products/csl/system-admin/conditions/[id]/api";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
import { CSLSystemAdminConditionsFormElement } from "./components/form-element/_index";

export interface ICSLSystemAdminConditionsFormProps {
  isNew: boolean;
  setIsLoading: (status: boolean) => void;
  formData?: any;
}
export const CSLSystemAdminConditionsForm = ({
  isNew,
  setIsLoading,
  formData: initialValues,
}: ICSLSystemAdminConditionsFormProps) => {
  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    if (isNew) {
      await postCSLSystemAdminConditons(data);
    } else {
      await putCSLSystemAdminConditons(data);
    }
    setIsLoading(false);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formRenderProps: FormRenderProps) => (
          <CSLSystemAdminConditionsFormElement
            isNew={isNew}
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
};
