import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { listFieldsConfig } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/psa/field-settings/config";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IFieldSettingsFormProps = {
  formRenderProps: FormRenderProps;
};
export const FieldSettingsForm = observer(
  ({ formRenderProps }: IFieldSettingsFormProps) => {
    const { valueGetter } = formRenderProps;
    const { isEditing } = useSettingPPRManageStore();
    const { getConfigDataField } = useSettingPPRStore();
    const configField = listFieldsConfig.map((field) =>
      getConfigDataField(field)
    );
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          {configField &&
            configField.map(
              (item) =>
                item && (
                  <div className="cc-field">
                    <CCLabel
                      title={item?.Title ?? ""}
                      isMandatory={item.IsMandatory}
                    />
                    <Field
                      name={item?.FieldName}
                      component={CCSwitch}
                      disabled={!isEditing}
                      checked={valueGetter(item?.FieldName)}
                    />
                  </div>
                )
            )}
        </div>
      </section>
    );
  }
);
