import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export const nameOfStateRoad = nameOfFactory<StateRoad>();

export interface StateRoad extends DTOBaseClass_Standard {
  StateRoad_ID: number;
  RoadName: string;
  RoadNumber: string;
  RoadIDNo: string;
  RoadClass: string;
  RoadStartNo: string;
  RoadStartName: string;
  RoadStartType: string;
  RoadStartLocality: string;
  RoadEnd: string;
  RoadEndName: string;
  RoadEndType: string;
  RoadEndLocality: string;
  RoadState: string;
}

export interface ILookupStateRoadParentSection {
  notification?: IAppNotificationItemAddProps[];
}

export enum LookupStateRoadActions {
  New = "New",
  Save = "Save",
}
