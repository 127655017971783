import { EventManagementActionBarNavDropdown } from "@app/products/event-management//components/action-bar/nav-dropdown/_index";
import { adminRoute } from "@app/products/event-management/admin/route";
import { eventManagementRoute } from "@app/products/event-management/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { NoData } from "@components/no-data/NoData";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: eventManagementRoute.name,
    leftComponents: [
      <EventManagementActionBarNavDropdown category={adminRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });

  return (
    <div className="cc-system-admin-setting">
      <NoData />
    </div>
  );
};
