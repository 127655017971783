import { ExistedPropertySchemeAccount } from "@app/products/property/schemes/[id]/account/[id]/components/forms/existed/_index";
import { NewPropertySchemeAccount } from "@app/products/property/schemes/[id]/account/[id]/components/forms/new/_index";
import { useSchemesAccountStore } from "@app/products/property/schemes/[id]/account/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default function ManagePropertySchemeAccount() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const accountId = params.id;
  const { loadSchemesAccount, resetStore } = useSchemesAccountStore();

  useEffect(() => {
    if (isNew) return;
    loadSchemesAccount(+accountId);
  }, [isNew, accountId, loadSchemesAccount]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewPropertySchemeAccount />;
  }

  return <ExistedPropertySchemeAccount />;
}
