export const mockNameStreetLocality = [
  {
    NoOfEntities: 2,
    Id: 0,
    IsExcluded: false,
    Detail: [
      {
        Address: "59 Smith Beach Rd, OFFICER, VIC 2110",
        Gender: "Female",
        EntityId: 3032,
      },
      {
        Address: "59 Smith Beach Rd, OFFICER, VIC 2111",
        Gender: "Female",
        EntityId: 3032,
      },
    ],
  },
  {
    NoOfEntities: 2,
    Id: 1,
    IsExcluded: true,
    Detail: [
      {
        Address: "14 The Mount Dr, OFFICER, VIC 2110",
        Gender: "Female",
        EntityId: 4908,
      },
      {
        Address: "OFFICER, VIC 2110",
        Gender: "Female",
        EntityId: 1995,
      },
    ],
  },
  {
    NoOfEntities: 4,
    Id: 2,
    IsExcluded: false,
    Detail: [
      {
        Address: "14 The Mount Dr, PAKENHAM, VIC 2110",
        Gender: "Female",
        EntityId: 344,
      },
      {
        Address: "14 The Mount Dr, PAKENHAM, VIC 2110",
        Gender: "Female",
        EntityId: 6623,
      },
      {
        Address: "14 The Mount Dr, PAKENHAM, VIC 2111",
        Gender: "Female",
        EntityId: 5201,
      },
      {
        Address: "PAKENHAM, VIC 2111",
        Gender: "Male",
        EntityId: 792,
      },
    ],
  },
  {
    NoOfEntities: 2,
    Id: 3,
    IsExcluded: false,
  },
  {
    NoOfEntities: 2,
    Id: 4,
    IsExcluded: false,
  },
  {
    Name: "BORG, Agnes",
    NoOfEntities: 4,
    Id: 5,
    IsExcluded: false,
    Detail: [
      {
        Name: "BORG, Agnes",
        Address: "43-49 Balcombe St, PAKENHAM, VIC 2110",
        Gender: "Female",
        EntityId: 4221,
      },
      {
        Name: "BORG, Andrew",
        Address: "43-49 Balcombe St, PAKENHAM, VIC 2110",
        Gender: "Male",
        EntityId: 4222,
      },
      {
        Name: "BORG, Angela",
        Address: "43-49 Balcombe St, PAKENHAM, VIC 2111",
        Gender: "Male",
        EntityId: 4223,
      },
      {
        Name: "BORG, Ann",
        Address: "43-49 Balcombe St, PAKENHAM, VIC 2111",
        Gender: "Male",
        EntityId: 4224,
      },
    ],
  },
  {
    Name: "BROWNLEE, Marianne Frances",
    NoOfEntities: 2,
    Id: 6,
    IsExcluded: true,
    Detail: [
      {
        Name: "BROWNLEE, Marianne Frances",
        Address: "33 Grandview Gr, BEACONSFIELD, VIC 2110",
        EntityId: 5334,
      },
      {
        Name: "BROWNLEE, Marianne Francis",
        Address: "33 Grandview Gr, BEACONSFIELD, VIC 2110",
        EntityId: 5335,
      },
    ],
  },
  {
    Name: "BROVVNLIE, Margaret Irene",
    NoOfEntities: 2,
    Id: 7,
    IsExcluded: false,
    Detail: [
      {
        Name: "BROVVNLIE, Margaret Irene",
        Address: "3 Vosges St, NARRE WARREN, VIC 2110",
        EntityId: 5336,
      },
      {
        Name: "BROVVNLIE, Mary McCulloch",
        Address: "3 Vosges St, NARRE WARREN, VIC 2110",
        EntityId: 5337,
      },
    ],
  },
  {
    Name: "CAMPBELL. John Colin",
    NoOfEntities: 2,
    Id: 8,
    IsExcluded: false,
    Detail: [
      {
        Name: "CAMPBELL. John Colin",
        Address: "14 Longstaff St, BEACONSFIELD UPPER, VIC 2110",
        Gender: "Female",
        EntityId: 6488,
      },
      {
        Name: "CAMPBELL. John V",
        Address: "14 Longstaff St, BEACONSFIELD UPPER, VIC 2110",
        EntityId: 6489,
      },
    ],
  },
  {
    Name: "CASSABA Mark Anthony",
    NoOfEntities: 2,
    Id: 9,
    IsExcluded: true,
  },
  {
    Name: "CAVE, J",
    NoOfEntities: 2,
    Id: 10,
    IsExcluded: true,
  },
  {
    Name: "CHILDS, Sharon Leanne",
    NoOfEntities: 2,
    Id: 11,
    IsExcluded: false,
  },
  {
    Name: "CHIRIANO, Nick",
    NoOfEntities: 2,
    Id: 12,
    IsExcluded: false,
  },
  {
    Name: "Christina Const Nom Ply Ltd",
    NoOfEntities: 2,
    Id: 13,
    IsExcluded: false,
  },
  {
    Name: "CILIA, Mark Manuel",
    NoOfEntities: 2,
    Id: 14,
    IsExcluded: false,
  },
  {
    Name: "CLARK. Alexis Gregor",
    NoOfEntities: 2,
    Id: 15,
    IsExcluded: false,
  },
  {
    Name: "CLARKE, Anthony James",
    NoOfEntities: 2,
    Id: 16,
    IsExcluded: true,
  },
  {
    Name: "CLARKE, Ethel Theresa",
    NoOfEntities: 2,
    Id: 17,
    IsExcluded: false,
  },
  {
    Name: "COLLINS, John",
    NoOfEntities: 2,
    Id: 18,
    IsExcluded: false,
  },
  {
    Name: "Connor John Crawford",
    NoOfEntities: 2,
    Id: 19,
    IsExcluded: true,
  },
  {
    Name: "CONNORS, Terence Anthony",
    NoOfEntities: 2,
    Id: 20,
    IsExcluded: false,
  },
  {
    Name: "CROSS, Darren John",
    NoOfEntities: 2,
    Id: 21,
    IsExcluded: false,
  },
  {
    Name: "DALLEORE, Teresa",
    NoOfEntities: 2,
    Id: 22,
    IsExcluded: false,
  },
  {
    Name: "DAVIES, Cheryl Jean",
    NoOfEntities: 2,
    Id: 23,
    IsExcluded: false,
  },
  {
    Name: "DAY, Dawn Margaret",
    NoOfEntities: 2,
    Id: 24,
    IsExcluded: false,
  },
  {
    Name: "DEAIR Cedric",
    NoOfEntities: 2,
    Id: 25,
    IsExcluded: false,
  },
  {
    Name: "DELLER, Denise",
    NoOfEntities: 2,
    Id: 26,
    IsExcluded: false,
  },
  {
    Name: "DELLER Gavin Ross",
    NoOfEntities: 2,
    Id: 27,
    IsExcluded: false,
  },
  {
    Name: "Department Of Human Services",
    NoOfEntities: 2,
    Id: 28,
    IsExcluded: false,
  },
];
