import { ExistedLocationRegister } from "@app/core/location-register/[id]/components/forms/existed/_index";
import { NewLocationRegister } from "@app/core/location-register/[id]/components/forms/new/_index";
import { useLocationRegisterStore } from "@app/core/location-register/[id]/store";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManageLocationRegister = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();

  const { loadLocationRegister, resetStore, setParentSection } =
    useLocationRegisterStore();

  const location = useLocation();
  const notificationState = location.state as IPSARApplicationParentSection;

  useEffect(() => {
    if (notificationState && notificationState?.notification) {
      setParentSection(notificationState);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState]);

  useEffect(() => {
    loadLocationRegister(parseInt(id), isNew);
    return () => {
      resetStore();
    };
  }, [id, isNew, loadLocationRegister, resetStore]);

  if (isNew) {
    return <NewLocationRegister />;
  }

  return <ExistedLocationRegister />;
});

export default ManageLocationRegister;
