import { getRefreshCacheProperty } from "@app/products/property/system-admin/settings/_id/components/action-bar/components/action-bar/buttons/refresh-cache/api";
import { isSuccessResponse } from "@common/apis/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";

export const PropertySettingRefreshCacheButton = () => {
  const [isShowConfirmRefresh, setIsShowConfirmRefresh] =
    useState<boolean>(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState<boolean>(false);
  const { pushNotificationPortal } = useNotificationPortalStore();

  const handleOnClick = () => {
    setIsShowConfirmRefresh(true);
  };

  const handleRefresh = async () => {
    // Logic to refresh the cache
    setIsShowConfirmRefresh(false);
    setIsLoadingRefresh(true);
    const response = await getRefreshCacheProperty();
    if (isSuccessResponse(response)) {
      // Success
      pushNotificationPortal({
        placeId: TypePlaceId.SettingMangePage,
        title: "Cache has been refreshed successfully.",
        type: "success",
      });
    } else {
      // Error
      pushNotificationPortal({
        placeId: TypePlaceId.SettingMangePage,
        title: "Cache could not be refreshed.",
        type: "error",
        description: response.error ?? response.data?.Errors,
        autoClose: false,
      });
    }
    setIsLoadingRefresh(false);
  };

  if (isLoadingRefresh) return <Loading isLoading isFullScreen />;

  return (
    <>
      <CCNavButton title="Refresh cache" onClick={handleOnClick} />
      {isShowConfirmRefresh && (
        <ConfirmDialog
          title="Refresh Cache Confirmation"
          subMessage="Are you sure you wish to refresh the cache?"
          onClosePopup={() => setIsShowConfirmRefresh(false)}
          onAsyncConfirm={handleRefresh}
        />
      )}
    </>
  );
};
