import { DTO_ParcelAddress_Extend } from "@app/products/property/parcels/[id]/model";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAddress = nameOfFactory<DTO_ParcelAddress_Extend>();

export const colAddressForParcel: IColumnFields[] = [
  {
    field: nameOfAddress("PropertyName_Address_Locality"),
    title: "Property Address",
  },
  { field: nameOfAddress("AddressType"), title: "Address Type" },
  {
    field: nameOfAddress("IsPrimaryAddress"),
    title: "Is Primary",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOfAddress("PropertyName"), title: "Property Name" },
  { field: nameOfAddress("Locality"), title: "Locality" },
  { field: nameOfAddress("LocationDescriptor"), title: "Location Descriptor" },
  { field: nameOfAddress("MapNumber"), title: "Map Number" },
  { field: nameOfAddress("MapReference"), title: "Map Reference" },
  {
    field: nameOfAddress("Address_PFI"),
    title: "Address PFI",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAddress("IsRuralHouseNumber"),
    title: "Is Rural House Number",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAddress("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
