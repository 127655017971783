import { IAssociationParcel } from "@app/products/property/registers/[id]/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<IAssociationParcel>();
export const colRegisterSummaryAssociatedParcels: IColumnFields[] = [
  {
    field: nameOf("ParcelReference"),
    title: "Parcel Reference",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("MapNumber"),
    title: "Map Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Zoning"),
    title: "Zoning",
  },
  {
    field: nameOf("LandUses"),
    title: "Land Uses",
  },
  {
    field: nameOf("Owners"),
    title: "Owners",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("ParcelId"),
    title: "Parcel ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
