import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<DTO_Journal_Summary>();
export const colCreateSession: IColumnFields[] = [
  {
    field: nameOf("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Jnl_DateTime"),
    title: "Date & Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Jnl_Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Jnl_Description"),
    title: "Description",
  },
  {
    field: nameOf("Jnl_No_of_Transactions"),
    title: "Number of Transactions",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Journal_Total"),
    title: "Total Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("totalAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Jnl_Debit_Total"),
    title: "Total Debits",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("totalDebits", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Jnl_Credit_Total"),
    title: "Total Credits",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("totalCredits", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("Journal_Type_Name"),
    title: "Journal Type",
  },
];
