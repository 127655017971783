import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { useJournalSessionDetailsStore } from "@app/products/property/journals/session-list/[id]/components/reference-sidebar/detail/store";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import SessionDialog from "@app/products/property/journals/session-list/components/dialogs/session-dialog/_index";
import { postCancelSession } from "@app/products/property/journals/session-list/components/dialogs/session-dialog/api";
import {
  Cancel_Session_Dialog_Mode,
  ISessionGridDialog,
} from "@app/products/property/journals/session-list/components/dialogs/session-dialog/model";
import { SESSION_STATUS_NAME_CAN_PROCESS } from "@app/products/property/journals/session-list/config";
import { isSuccessResponse } from "@common/apis/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

export const CancelPropertySessionButton = observer(() => {
  const { sessionList, reloadSessionList } = useSessionListStore();
  const { gridSelectedRows, clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { reLoadJournalSessionDetails } = useJournalSessionDetailsStore();

  const [itemToBeUpdated, setItemToBeUpdated] = useState<ISessionGridDialog>();
  const [cancelSessionDialogMode, setCancelSessionDialogMode] =
    useState<Cancel_Session_Dialog_Mode>(Cancel_Session_Dialog_Mode.Hidden);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  //ref local notification
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  useEffect(() => {
    if (sessionList) {
      if (
        sessionList.Session_Status ===
        SESSION_STATUS_NAME_CAN_PROCESS.ToBeUpdated
      ) {
        setItemToBeUpdated({
          Session_Number: sessionList.SessionNumber ?? 0,
          Session_Name: sessionList.Session_Name ?? "",
        });
      }
    } else {
      if (gridSelectedRows.length > 0) {
        if (
          gridSelectedRows.find(
            (item) =>
              item?.Session_Status_Name !==
              SESSION_STATUS_NAME_CAN_PROCESS.ToBeUpdated
          )
        ) {
          setItemToBeUpdated(undefined);
        } else {
          setItemToBeUpdated(gridSelectedRows[0]);
        }
      } else {
        setItemToBeUpdated(undefined);
      }
    }
  }, [gridSelectedRows, sessionList]);

  const handleCancelSession = async () => {
    if (!itemToBeUpdated) return;

    setIsSubmitting(true);
    const response = await postCancelSession(itemToBeUpdated.Session_Number);
    setIsSubmitting(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      setCancelSessionDialogMode(Cancel_Session_Dialog_Mode.Hidden);
      //Refresh list view
      if (gridSelectedRows && gridSelectedRows.length > 0) {
        eventEmitter.emit(CCGridEventType.RefreshOData);
        clearSelectedItems();
      }
      //Refresh history tab
      eventEmitter.emit(CCJournalEventType.RefreshData);
      //Refresh detail tab
      reLoadJournalSessionDetails();
      //Refresh manage page
      reloadSessionList().then(() => {
        pushNotification({
          title: "Session was cancelled successfully",
          type: "success",
        });
      });
    } else {
      notificationRef.current?.pushNotification({
        title: response?.data?.ErrorMessage ?? "Session could not be cancelled",
        type: "error",
        autoClose: false,
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Cancel session"
        onClick={() => {
          gridSelectedRows.length > 1
            ? setCancelSessionDialogMode(
                Cancel_Session_Dialog_Mode.NotificationDialog
              )
            : setCancelSessionDialogMode(
                Cancel_Session_Dialog_Mode.ActionDialog
              );
        }}
        disabled={isNil(itemToBeUpdated)}
      />

      {cancelSessionDialogMode ===
        Cancel_Session_Dialog_Mode.NotificationDialog && (
        <ConfirmDialog
          onClosePopup={() => {
            setCancelSessionDialogMode(Cancel_Session_Dialog_Mode.Hidden);
          }}
          title="Cancel Session"
          subMessage="Only one session can be closed at a time."
          btnNoVisible={false}
          btnYesTitle="OK"
          onConfirmYes={() => {
            clearSelectedItems();
          }}
        />
      )}

      {cancelSessionDialogMode === Cancel_Session_Dialog_Mode.ActionDialog &&
        itemToBeUpdated && (
          <SessionDialog
            onClosePopup={() =>
              setCancelSessionDialogMode(Cancel_Session_Dialog_Mode.Hidden)
            }
            onOkPopup={handleCancelSession}
            listItem={[itemToBeUpdated]}
            title="Cancel Confirmation"
            msg="The selected session will be cancelled:"
            isSubmitting={isSubmitting}
            notificationRef={notificationRef}
          />
        )}
    </>
  );
});
