import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";
import "./_index.scss";

export const MAIL_MERGE_OPTIONS_FORM_STEP = "Options";

export const OptionsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options,
}: IFormStepElement) => {
  const { valueGetter } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  return (
    <div className="cc-mail-merge-selection-container">
      <div className="cc-field-group">
        <div className="cc-form-cols-4 cc-switch-options-step">
          <div className="cc-field">
            <label className="cc-label">Attach to each record</label>
            <Field
              name={nameOf("AppendToRecordSource")}
              checked={getFieldValue("AppendToRecordSource")}
              component={CCSwitch}
            />
          </div>
          {options.isShowCollateOption && (
            <div className="cc-field">
              <label className="cc-label">Open after merge</label>
              <Field
                name={nameOf("Collate")}
                checked={getFieldValue("Collate")}
                component={CCSwitch}
              />
            </div>
          )}
          <div className="cc-field">
            <label className="cc-label">Provide copy for printing</label>
            <Field
              name={nameOf("BatchPrint")}
              checked={getFieldValue("BatchPrint")}
              component={CCSwitch}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Save as PDF</label>
            <Field
              name={nameOf("SaveAsPdf")}
              checked={getFieldValue("SaveAsPdf")}
              component={CCSwitch}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
