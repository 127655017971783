import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface IReactivateAssessmentCommonButtonProps {
  assessmentID?: number;
  isDisabled?: boolean;
  statusID?: number; //It means id of Assessment_Status
}

export const ReactivateAssessmentCommonButton = observer(
  ({
    assessmentID,
    isDisabled,
    statusID,
  }: IReactivateAssessmentCommonButtonProps) => {
    const idManagePage = useParams<{ id: string }>();
    const { setListDialog } = usePropertyWorkflowStore();

    //Get label
    const reactivateAssessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Reactivate_Assessment
    );

    const id = assessmentID ?? +idManagePage?.id;

    return (
      <CCNavButton
        title={reactivateAssessmentLabel}
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Reactivate_Assessment,
              data: {},
              props: {
                assessmentID: +id ?? 0,
                statusID: statusID ?? 0,
              },
            },
          ]);
        }}
      />
    );
  }
);
