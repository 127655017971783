import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { systemAdminRoute } from "@app/products/animals/system-admin/route";
import { defaultAnimalsCategoryConfig } from "@app/products/animals/system-admin/settings/_category/config";
import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import AnimalsSettingManage from "@app/products/animals/system-admin/settings/_id/_index";
import Breadcrumb from "@app/products/property/system-admin/settings/components/breadcrumb/_index";
import { MODE_SETTING } from "@app/products/town-planning/ppr/system-admin/settings/_category/model";
import { ICategoryConfig } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { GlobalSettingHistoryTab } from "@common/pages/settings/system-admin/global-settings/_id/sidebar/history/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { EListType } from "@components/cc-product-list-view/components/hooks/useStoreStateListView";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default observer(() => {
  const {
    isLoading,
    currentBreadcrumb,
    responseLoadError,
    modeSetting,
    columnFields,
    dataSetting,
    primaryKeyTable,
    setModeSetting,
    loadDataSetting,
  } = useAnimalsSettingStore();
  const actionBarStore = useCCListViewActionBarStore();
  const referenceSideBarStore = useReferenceSideBarStore();

  const { state } = useLocation<any>();
  const history = useHistory();

  const title = "settings";
  const actionBarLeft = [
    <AnimalActionBarNavDropdown category={systemAdminRoute.path} />,
  ];
  const actionBarRight = [
    <ActionBarExportIcon />,
    <ActionBarFilterIcon />,
    <ActionBarInfoIcon />,
  ];

  useEffect(() => {
    actionBarStore.setTitle(title);
    actionBarStore.setLeftComponents(actionBarLeft);
    actionBarStore.setRightComponents(actionBarRight);
    referenceSideBarStore.setReferenceComponents([
      {
        title: "History",
        component: <GlobalSettingHistoryTab />,
      },
    ]);

    return () => {
      actionBarStore.resetDefault();
      referenceSideBarStore.resetDefault();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, history.location.state]);

  const loadData = () => {
    const newConfig = processCategoryConfig();
    if (newConfig && newConfig.endPoint !== "") {
      let requestUrl =
        state && state.requestUrl ? state.requestUrl : newConfig.endPoint;
      loadDataSetting(requestUrl);
    } else {
      setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
    }
  };

  const processCategoryConfig = () => {
    let categoryConfig: ICategoryConfig | undefined = undefined;
    const categoryState = state?.category;
    if (!isNil(categoryState)) {
      categoryConfig = {
        name: state?.category,
        endPoint: state?.requestUrl,
        product: state?.product,
      };
    } else {
      categoryConfig = {
        name: defaultAnimalsCategoryConfig.name,
        endPoint: defaultAnimalsCategoryConfig.endPoint,
        product: defaultAnimalsCategoryConfig.product,
      };
    }
    return categoryConfig;
  };

  if (isLoading) return <Loading isLoading isFullScreen />;

  return (
    <div className="cc-system-admin-setting">
      {responseLoadError ? (
        <CCLoadFailed responseError={responseLoadError} onReload={loadData} />
      ) : (
        <>
          <Breadcrumb
            breadcrumb={currentBreadcrumb}
            isSettingFolder={modeSetting === MODE_SETTING.SETTINGS_FOLDER}
          />
          {modeSetting === MODE_SETTING.SETTINGS_FOLDER ? (
            <CCProductListView
              columnFields={columnFields ?? []}
              data={dataSetting ?? []}
              primaryField={primaryKeyTable}
              typeList={EListType.Setting}
            />
          ) : modeSetting === MODE_SETTING.SETTINGS_ITEM ? (
            <> {dataSetting && <AnimalsSettingManage />}</>
          ) : null}
        </>
      )}
    </div>
  );
});
