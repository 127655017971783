import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colNoticeByDueStatus: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Number,
    title: "Number",
    width: 200,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ApplicantName,
    title: "Respondant",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Type,
    title: "Type",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Officer,
    title: "Officer",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.Risk,
    title: "Risk",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.DateOffence,
    title: "Offence",
    width: 200,
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
  {
    field: TownPlanningFieldMapping.DateServed,
    title: "Served",
    width: 200,
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
  {
    field: TownPlanningFieldMapping.DateDue,
    title: "Compliance Due",
    width: 200,
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
  {
    field: TownPlanningFieldMapping.DateRevoked,
    title: "Complied / Revoked",
    width: 200,
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
];
