import {
  Application,
  Application_Decision_Int,
} from "@app/products/town-planning/ppr/[id]/model";
import { getApplicationDecisions } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/application-decision/api";
import { ApplicationDecisionDetails } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/application-decision/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<ApplicationDecisionDetails>();

interface IApplicationDecisionDialogProps {
  onClose: () => void;
  onSubmit: (data: ApplicationDecisionDetails) => void;
  isLoading: boolean;
  notifications?: IAppNotificationItem[];
  application?: Application;
}

export const ApplicationDecisionDialog = observer(
  ({
    onClose,
    onSubmit,
    notifications,
    isLoading,
    application,
  }: IApplicationDecisionDialogProps) => {
    const [decisions, setDecisions] = useState<IKeyValuePacket[]>([]);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [isGettingData, setIsGettingData] = useState(false);

    const initialValues = useMemo<ApplicationDecisionDetails>(() => {
      return {
        DecisionDate: application?.DecisionDate ?? new Date(),
        DotNotified:
          application?.PPRDetails?.DOTNotifiedOfFinalDecision ?? null,
        Decision_ENUM: application?.Decision_ENUM
          ? Application_Decision_Int[application.Decision_ENUM]
          : null,
      };
    }, [application]);

    const loadDecisions = () => {
      setIsGettingData(true);
      getApplicationDecisions().then((response) => {
        let errorResponse = undefined;
        let newDecision: IKeyValuePacket[] = [];
        if (isSuccessResponse(response)) {
          newDecision = response.data;
        } else {
          errorResponse = {
            status: response.status,
            error: response.error,
          };
        }
        setDecisions(newDecision);
        setResponseLoadError(errorResponse);
        setIsGettingData(false);
      });
    };

    useEffectOnce(() => {
      loadDecisions();
    });

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={(event) =>
          onSubmit(event.values as ApplicationDecisionDetails)
        }
        render={({
          onSubmit: onFormSubmit,
          valid,
          valueGetter,
          onChange,
        }: FormRenderProps) => (
          <FormElement>
            <CCDialog
              titleHeader={"Approve Application"}
              onClose={onClose}
              maxWidth={"30%"}
              height={"auto"}
              disabled={isLoading}
              bodyElement={
                isGettingData ? (
                  <Loading isLoading />
                ) : responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={loadDecisions}
                  />
                ) : (
                  <div className="cc-form">
                    <CCLocalNotification defaultNotifications={notifications} />
                    <section className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <label className="cc-label">
                            Decision
                            <CCTooltip type="validator" position="right" />
                          </label>
                          <Field
                            name={nameOf("Decision_ENUM")}
                            data={decisions}
                            validator={requiredValidator}
                            textField="Value"
                            dataItemKey="Key"
                            component={CCSearchComboBox}
                            value={getDropdownValue(
                              valueGetter(nameOf("Decision_ENUM")),
                              decisions ?? [],
                              "Key"
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange(nameOf("Decision_ENUM"), {
                                value: event.target.value?.Key ?? null,
                              });
                            }}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Decision date</label>
                          <Field
                            name={nameOf("DecisionDate")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            DoT notified of final decision
                          </label>
                          <Field
                            name={nameOf("DotNotified")}
                            component={CCDatePicker}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                )
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    disabled={!valid || !!responseLoadError}
                    onClick={onFormSubmit}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        )}
      />
    );
  }
);
