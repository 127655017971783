import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewWastewaterButton = () => {
  const history = useHistory();
  return (
    <CCNavButton
      title="New Waste Water"
      onClick={() => {
        history.push(WASTE_WATER_ROUTE + "/new");
      }}
    />
  );
};
