import { ExistedContact } from "@app/core/contacts/_id/components/forms/existed/_index";
import { NewContact } from "@app/core/contacts/_id/components/forms/new/_index";
import { IContactParentSection } from "@app/core/contacts/_id/model";
import { useContactStore } from "@app/core/contacts/_id/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageContact = () => {
  const isNew = useIsNew();
  const { id: contactId } = useParams<{
    id?: string;
  }>();
  const { resetStore, loadContact } = useContactStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { state } = useLocation<IContactParentSection>();

  useEffect(() => {
    loadContact(parseInt(contactId ?? "0"), state, isNew);
    window.history.replaceState({}, "");
  }, [contactId, isNew, loadContact, state]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line
  });

  if (isNew) {
    return <NewContact />;
  }
  return <ExistedContact />;
};

export default observer(ManageContact);
