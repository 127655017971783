import { RegisterSummaryAssociatedParcels } from "@app/products/property/registers/[id]/components/child-screens/general/components/form-element/components/associated-parcels/_index";
import { RegisterSummaryAttribute } from "@app/products/property/registers/[id]/components/child-screens/general/components/form-element/components/attribute/_index";
import { colGISReference } from "@app/products/property/registers/[id]/config";
import {
  IGISReferenceGrid,
  IRegistersSummary,
} from "@app/products/property/registers/[id]/model";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { gisReferenceDataArrayToGrid } from "@app/products/property/registers/[id]/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Toast } from "@components/toast";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  formRenderProps: FormRenderProps;
}

export const PropertyRegisterFormElement = observer(
  ({ formRenderProps }: Props) => {
    const { register, setSelectedGISReference, selectedGISReference } =
      useRegisterStore();
    const nameOf = nameOfFactory<IRegistersSummary>();
    const nameOfGISReferenceGrid = nameOfFactory<IGISReferenceGrid>();

    const getValue = formRenderProps.valueGetter;

    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;

      Toast.displayToast({
        content: "The form is not modified",
        type: "warning",
      });
    };

    return (
      <FormElement className="cc-property-register-form-elements">
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Date & time</label>
              <Field
                name={nameOf("Date")}
                placeholder={"Date & time"}
                component={CCDateTimePicker}
                disabled
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Category</label>
              <Field
                name={nameOf("Category")}
                placeholder={"Category"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                name={nameOf("Status")}
                placeholder={"Status"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Account name</label>
              <Field
                name={nameOf("AccountName")}
                placeholder={"Account name"}
                component={CCTextArea}
                rows={3}
                readOnly
              />
            </div>
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Description</label>
              <Field
                name={nameOf("Description")}
                placeholder={"Description"}
                component={CCTextArea}
                rows={3}
                readOnly
              />
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <label className="cc-label">Property</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Property name</label>
                    <Field
                      name={nameOf("PropertyName")}
                      placeholder={"Property name"}
                      component={CCInput}
                      readOnly
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Locality</label>
                    <Field
                      name={nameOf("PropertyLocality")}
                      placeholder={"Locality"}
                      component={CCInput}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="cc-field cc-col-span-2">
                <label className="cc-label">Address</label>
                <Field
                  name={nameOf("PropertyAddress")}
                  placeholder={"Property address"}
                  component={CCTextArea}
                  rows={4}
                  readOnly
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">GIS references</label>

              <CCGrid
                className="cc-gis-reference-grid-data"
                data={
                  register?.GISReference
                    ? gisReferenceDataArrayToGrid(register?.GISReference)
                    : []
                }
                columnFields={colGISReference}
                primaryField={nameOfGISReferenceGrid("GISReference")}
                selectableMode="multiple"
                onSelectionChange={(dataItem) => {
                  if (dataItem) {
                    setSelectedGISReference(dataItem);
                  }
                }}
                selectedRows={selectedGISReference}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Created on</label>
              <Field
                name={nameOf("CreatedOn")}
                placeholder={"Created on"}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Created by</label>
              <Field
                name={nameOf("CreatedBy")}
                placeholder={"Created by"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
        </section>
        <br />
        <section className="cc-field-group">
          <Field
            name={nameOf("Attributes")}
            component={RegisterSummaryAttribute}
            data={getValue("Attributes")}
          />
        </section>
        <br />
        <section className="cc-field-group">
          <label className="cc-label">Associated parcels</label>
          <Field
            name={nameOf("AssociatedParcels")}
            component={RegisterSummaryAssociatedParcels}
            data={getValue("AssociatedParcels")}
          />
        </section>
      </FormElement>
    );
  }
);
