import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();

  const data = useDataSetting(configData, [
    "NCItem_CriticalFactor",
    "NCItem_MajorFactor",
    "NCItem_StartingScore",
    "NCItem_CompliedScore",
    "NCItem_MinorScore",
    "NCItem_CriticalScore",
  ]);

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <NumericTextboxGlobalSettings
            data={data.NCItem_CriticalFactor}
            isEditing={isEditing}
            format={NUMBER_FORMAT.NUMBER2}
          />
          <NumericTextboxGlobalSettings
            data={data.NCItem_MajorFactor}
            format={NUMBER_FORMAT.NUMBER2}
            isEditing={isEditing}
          />
          <NumericTextboxGlobalSettings
            data={data.NCItem_StartingScore}
            format={NUMBER_FORMAT.NUMBER2}
            isEditing={isEditing}
          />
          <NumericTextboxGlobalSettings
            data={data.NCItem_CompliedScore}
            format={NUMBER_FORMAT.NUMBER2}
            isEditing={isEditing}
          />
          <NumericTextboxGlobalSettings
            data={data.NCItem_MinorScore}
            format={NUMBER_FORMAT.NUMBER2}
            isEditing={isEditing}
          />
          <NumericTextboxGlobalSettings
            data={data.NCItem_CriticalScore}
            format={NUMBER_FORMAT.NUMBER2}
            isEditing={isEditing}
          />
        </div>
      </section>
    </>
  );
});
