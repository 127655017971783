import { useBuildingRegistersDemolitionsStore } from "@app/products/building/registers/demolitions/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { BuildingRegisterDemolitionFormElement } from "./components/form-element/_index";

export const BuildingRegisterDemolitionForm = observer(() => {
  const {
    setIsLoading,
    demolitions,
    createBuildingDemolition,
    updateBuildingDemolition,
  } = useBuildingRegistersDemolitionsStore();
  const isNew = useIsNew();
  const handleSubmit = (data: any) => {
    setIsLoading(true);
    if (isNew) {
      createBuildingDemolition(data);
    } else {
      updateBuildingDemolition(data);
    }
    setIsLoading(false);
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={demolitions}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegisterDemolitionFormElement
            formRenderProps={formRenderProps}
          />
        )}
      />
    </div>
  );
});
