import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colParkingPermitsFinanceOSFees: IColumnFields[] = [
  {
    field: ParkingPermitsFieldMapping.ReferenceNumber,
    title: "Permit Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PARKING_PERMITS_ROUTE}/${dataItem.ID}`;
    },
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: ParkingPermitsFieldMapping.PermitHolder, title: "Name", width: 200 },
  {
    field: ParkingPermitsFieldMapping.CreatedDate,
    title: "Recorded",
    width: 200,

    format: DATE_FORMAT.DATE,
  },
  { field: ParkingPermitsFieldMapping.FeeType, title: "Type", width: 200 },
  {
    field: ParkingPermitsFieldMapping.AmountIssued,
    title: "Issued",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.AmountOutstanding,
    title: "Outstanding",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.CostCode,
    title: "Cost Code",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: ParkingPermitsFieldMapping.SecurePay_Reference,
    title: "Payment Reference",
    width: 200,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
