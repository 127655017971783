import { BuildingFieldMapping } from "@app/products/building/model";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colBuildingSystemAdminNatureOfWork: IColumnFields[] = [
  {
    field: BuildingFieldMapping.ApplicationCategory_Name,
    title: "Name",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${BUILDING_MANAGE_ROUTE}/${dataItem.ID}`;
    },
  },
  {
    field: BuildingFieldMapping.ApplicationCategory_Description,
    title: "Description",
  },
  {
    field: BuildingFieldMapping.Code,
    title: "Code",
  },
  {
    field: BuildingFieldMapping.SortIndex,
    title: "Sort",
  },
  {
    field: BuildingFieldMapping.IsPublishToWeb,
    title: "Publish to Web",
  },
  {
    field: BuildingFieldMapping.PublishName,
    title: "Web Name",
  },
  {
    field: BuildingFieldMapping.PublishDescription,
    title: "Web Description",
  },
];
