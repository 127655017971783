import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export type IStatDaysSettingsFormProps = {
  formRenderProps: FormRenderProps;
};

export const StatDaysSettingsForm = observer(
  ({ formRenderProps }: IStatDaysSettingsFormProps) => {
    const { valueGetter } = formRenderProps;
    const { configData = {} } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const fieldBusinessDaysOnly =
      configData[
        ECorporateSettingsField.TP_StatDaysBusinessDaysOnly.toString()
      ];
    const fieldIncludeReferrals =
      configData[
        ECorporateSettingsField.TP_StatDaysIncludeReferrals.toString()
      ];
    const fieldIncludeAdvertising =
      configData[
        ECorporateSettingsField.TP_StatDaysIncludeAdvertising.toString()
      ];
    const fieldFIDays =
      configData[ECorporateSettingsField.TP_StatDaysFIDays.toString()];
    const fieldFIDaysWithoutAdvertising =
      configData[
        ECorporateSettingsField.TP_FIDaysForWithoutAdvertising.toString()
      ];
    const fieldFIDaysHeritageSensitive =
      configData[
        ECorporateSettingsField.TP_FIDaysForHeritageSensitive.toString()
      ];
    const fieldAdvertDays =
      configData[ECorporateSettingsField.TP_StatDaysAdvertDays.toString()];
    const fieldFTDDays =
      configData[ECorporateSettingsField.TP_StatDaysFTDDays.toString()];
    const fieldNODDays =
      configData[ECorporateSettingsField.TP_NODDays.toString()];
    const fieldNoAppeal =
      configData[ECorporateSettingsField.TP_NoAppealDays.toString()];
    const fieldHasTargetDays =
      configData[ECorporateSettingsField.TP_StatTargetDaysEnabled.toString()];
    const fieldDefaultTargetDays =
      configData[ECorporateSettingsField.TP_StatTargetDaysDefault.toString()];
    const fieldEnableAppealDays =
      configData[
        ECorporateSettingsField.TP_ApplicationBannerShowAppealDays.toString()
      ];
    const fieldInternalRatingWorkflow =
      configData[ECorporateSettingsField.TP_RatingWorkflow.toString()];
    const fieldP1 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_Keyword_P1.toString()
      ];
    const fieldTargetDaysP1 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_TargetDays_P1.toString()
      ];
    const fieldP2 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_Keyword_P2.toString()
      ];
    const fieldTargetDaysP2 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_TargetDays_P2.toString()
      ];
    const fieldP3 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_Keyword_P3.toString()
      ];
    const fieldTargetDaysP3 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_TargetDays_P3.toString()
      ];
    const fieldP4 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_Keyword_P4.toString()
      ];
    const fieldTargetDaysP4 =
      configData[
        ECorporateSettingsField.TP_RatingWorkflow_TargetDays_P4.toString()
      ];
    const fieldInformation = configData["Information"];

    //Value Getter
    const isStatDaysBusinessDaysOnly = valueGetter(
      ECorporateSettingsField.TP_StatDaysBusinessDaysOnly.toString()
    );
    const isStatDaysIncludeReferrals = valueGetter(
      ECorporateSettingsField.TP_StatDaysIncludeReferrals.toString()
    );
    const isStatDaysIncludeAdvertising = valueGetter(
      ECorporateSettingsField.TP_StatDaysIncludeAdvertising.toString()
    );
    const isStatTargetDaysEnabled = valueGetter(
      ECorporateSettingsField.TP_StatTargetDaysEnabled.toString()
    );
    const isEnableAppealDays = valueGetter(
      ECorporateSettingsField.TP_ApplicationBannerShowAppealDays.toString()
    );
    const isInternalRatingWorkflow = valueGetter(
      ECorporateSettingsField.TP_RatingWorkflow.toString()
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={fieldBusinessDaysOnly?.Title ?? ""} />
            <Field
              name={fieldBusinessDaysOnly?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isStatDaysBusinessDaysOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={fieldIncludeReferrals?.Title ?? ""} />
            <Field
              name={fieldIncludeReferrals?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isStatDaysIncludeReferrals}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={fieldIncludeAdvertising?.Title ?? ""} />
            <Field
              name={fieldIncludeAdvertising?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isStatDaysIncludeAdvertising}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={fieldFIDays?.Title ?? ""}
              isMandatory={fieldFIDays.IsMandatory}
            />
            <Field
              name={fieldFIDays?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldFIDays?.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldFIDaysWithoutAdvertising?.Title ?? ""}
              isMandatory={fieldFIDaysWithoutAdvertising.IsMandatory}
            />
            <Field
              name={fieldFIDaysWithoutAdvertising?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldFIDaysWithoutAdvertising?.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldFIDaysHeritageSensitive?.Title ?? ""}
              isMandatory={fieldFIDaysHeritageSensitive.IsMandatory}
            />
            <Field
              name={fieldFIDaysHeritageSensitive?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldFIDaysHeritageSensitive?.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={fieldAdvertDays?.Title ?? ""}
              isMandatory={fieldAdvertDays.IsMandatory}
            />
            <Field
              name={fieldAdvertDays?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldAdvertDays?.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldFTDDays?.Title ?? ""}
              isMandatory={fieldFTDDays.IsMandatory}
            />
            <Field
              name={fieldFTDDays?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldFTDDays?.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldNODDays?.Title ?? ""}
              isMandatory={fieldNODDays.IsMandatory}
            />
            <Field
              name={fieldNODDays?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldNODDays?.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={fieldNoAppeal?.Title ?? ""}
              isMandatory={fieldNoAppeal.IsMandatory}
            />
            <Field
              name={fieldNoAppeal?.FieldName}
              component={CCNumericTextBox}
              disabled={!isEditing}
              validator={
                fieldNoAppeal?.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel title={fieldHasTargetDays?.Title ?? ""} />
            <Field
              name={fieldHasTargetDays?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isStatTargetDaysEnabled}
            />
          </div>
          {isStatTargetDaysEnabled && (
            <div className="cc-field">
              <CCLabel
                title={fieldDefaultTargetDays?.Title ?? ""}
                isMandatory={fieldDefaultTargetDays.IsMandatory}
              />
              <Field
                name={fieldDefaultTargetDays?.FieldName}
                component={CCNumericTextBox}
                disabled={!isEditing}
                validator={
                  fieldDefaultTargetDays?.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={fieldEnableAppealDays?.Title ?? ""} />
            <Field
              name={fieldEnableAppealDays?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isEnableAppealDays}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={fieldInternalRatingWorkflow?.Title ?? ""} />
            <Field
              name={fieldInternalRatingWorkflow?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isInternalRatingWorkflow}
            />
          </div>
        </div>
        {isInternalRatingWorkflow && (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title={fieldP1?.Title ?? ""} />
                <Field
                  name={fieldP1?.FieldName}
                  component={CCSearchComboBox}
                  data={fieldP1?.LookupItems}
                  textField={nameOfLookupItem("Name")}
                  dataItemKey={nameOfLookupItem("Id")}
                  disabled={!isEditing}
                  isUseDefaultOnchange
                  validator={
                    fieldP1.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldTargetDaysP1?.Title ?? ""}
                  isMandatory={fieldTargetDaysP1.IsMandatory}
                />
                <Field
                  name={fieldTargetDaysP1?.FieldName}
                  component={CCNumericTextBox}
                  disabled={!isEditing}
                  validator={
                    fieldTargetDaysP1?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title={fieldP2?.Title ?? ""} />
                <Field
                  name={fieldP2?.FieldName}
                  component={CCSearchComboBox}
                  data={fieldP2?.LookupItems}
                  textField={nameOfLookupItem("Name")}
                  dataItemKey={nameOfLookupItem("Id")}
                  disabled={!isEditing}
                  isUseDefaultOnchange
                  validator={
                    fieldP2.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldTargetDaysP2?.Title ?? ""}
                  isMandatory={fieldTargetDaysP2.IsMandatory}
                />
                <Field
                  name={fieldTargetDaysP2?.FieldName}
                  component={CCNumericTextBox}
                  disabled={!isEditing}
                  validator={
                    fieldTargetDaysP2?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title={fieldP3?.Title ?? ""} />
                <Field
                  name={fieldP3?.FieldName}
                  component={CCSearchComboBox}
                  data={fieldP3?.LookupItems}
                  textField={nameOfLookupItem("Name")}
                  dataItemKey={nameOfLookupItem("Id")}
                  disabled={!isEditing}
                  isUseDefaultOnchange
                  validator={
                    fieldP3.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldTargetDaysP3?.Title ?? ""}
                  isMandatory={fieldTargetDaysP3.IsMandatory}
                />
                <Field
                  name={fieldTargetDaysP3?.FieldName}
                  component={CCNumericTextBox}
                  disabled={!isEditing}
                  validator={
                    fieldTargetDaysP3?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title={fieldP4?.Title ?? ""} />
                <Field
                  name={fieldP4?.FieldName}
                  component={CCSearchComboBox}
                  data={fieldP4?.LookupItems}
                  textField={nameOfLookupItem("Name")}
                  dataItemKey={nameOfLookupItem("Id")}
                  disabled={!isEditing}
                  isUseDefaultOnchange
                  validator={
                    fieldP4.IsMandatory ? requiredValidator : undefined
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title={fieldTargetDaysP4?.Title ?? ""}
                  isMandatory={fieldTargetDaysP4.IsMandatory}
                />
                <Field
                  name={fieldTargetDaysP4?.FieldName}
                  component={CCNumericTextBox}
                  disabled={!isEditing}
                  validator={
                    fieldTargetDaysP4?.IsMandatory
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className="cc-form-cols-1">
          <CCValueField
            className={"cc-stat-day-information"}
            label={"Information"}
            value={sanitizeHtml(fieldInformation?.Title ?? "")}
          />
        </div>
      </section>
    );
  }
);
