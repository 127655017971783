import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import {
  EPrivilegesWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const ModifySupplementaryButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { id } = useParams<{ id: string }>();
  const { supplementaryAssessments } = useSupplementaryRatesStore();

  return (
    <PrivilegeWrapper
      dialogType={ePermissionPrivilegeTypeCheck.Workflow}
      privilegesEnum={EPrivilegesWorkflow.Supplementary_Modify}
    >
      <CCNavButton
        title="Modify"
        disabled={supplementaryAssessments?.SupplementaryDetail?.IsComplete}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Supplementary_Modify,
              data: {},
              props: {
                supplementaryId: +id,
              },
            },
          ]);
        }}
      />
    </PrivilegeWrapper>
  );
});
