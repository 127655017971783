import { loadMetersUsage } from "@app/products/property/meters/[id]/components/child-screens/usage/api";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colUsage } from "./config";

export const MetersUsage = () => {
  const [gridData, setGridData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    setIsLoading(true);
    loadMetersUsage().then((data) => {
      setGridData(data);
      setIsLoading(false);
    });
  });

  return (
    <div className="cc-property-meters-usage">
      <CCGrid
        isLoading={isLoading}
        className="cc-property-meters-usage-grid"
        data={gridData}
        columnFields={colUsage}
        primaryField="Year"
        state={{ sort: [{ field: "Year", dir: "desc" }] }}
      />
    </div>
  );
};
