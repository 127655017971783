import { IPremisesBatchUpdate } from "@app/products/hm/components/batch-update/model";
import { NewManagePremisesBatchUpdate } from "@app/products/hm/components/batch-update/new/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

const PremisesBatchUpdate = () => {
  const history = useHistory();

  //#region STORE ========/
  const { setInitialDataForms } = useFlexibleFormStore();
  const { settings } = useCommonCoreStore();
  //#endregion STORE =====/

  const { clearNotifications } = useNotificationPortalStore();

  useEffectOnce(() => {
    return () => {
      clearNotifications();
    };
  });

  const hmLabel = getStringValueSetting(
    settings[ECorporateSettingsField.HealthManager_Common_ProductName]
  );

  useEffect(() => {
    setInitialDataForms({
      GeneralForm: {
        IsSelectedAll: false,
        Change_Area: false,
        Change_FSP_Template: false,
        Change_Officer: false,
        Change_Premises_Type: false,
        Close_Premises: false,
        ClosureReason: "",

        SelectedRows: [],
        Keyword_ID: null,
        FSPTemplate_KWD: null,
        Officer_ID: null,
        PremisesType_ID: null,
        ClosureType_KWD: null,
        Date_Closed: new Date(),
      } as IPremisesBatchUpdate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <FormNavigation title={hmLabel ?? "HEALTH MANAGER"} />
      <NewManagePremisesBatchUpdate />
    </>
  );
};

export default PremisesBatchUpdate;
