import React from "react";
import "./_index.scss";

export const NoteEntityMerge = () => {
  return (
    <div className="cc-entity-merge-note">
      <p>
        Merge - this will merge the data with the master contact where the data
        is not present on the master record.
      </p>
      <p>
        Delete - this will remove the contact from the system and be replaced
        with the selected master contact.
      </p>
      <p>
        No Action - No Action will be taken and the contact will be left as is.
      </p>
    </div>
  );
};
