import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { postSendTestEmail } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/general-mail-settings/api";
import { onSubmitFormGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const [isTestingSendingEmail, setIsTestingSendingEmail] = useState(false);
  const { pushNotification } = useCCAppNotificationStore();
  const { valueGetter } = formRenderProps;
  const emailOn = valueGetter(
    ECorporateSettingsField.CorporateSettings_EmailOn.toString()
  );
  const fieldSMTPServer = valueGetter(
    ECorporateSettingsField.Global_EmailSMTPServer.toString()
  ) as string;
  const fieldFromAddress = valueGetter(
    ECorporateSettingsField.CorporateSettings_EmailFromAddress.toString()
  ) as string;
  const fieldProtocolType = valueGetter(
    ECorporateSettingsField.Global_AuthenticationType.toString()
  ) as string;
  const isDisableTestSendingEmail = useMemo((): boolean => {
    return isTestingSendingEmail
      ? true
      : !isEditing ||
          (fieldSMTPServer.length <= 0 && fieldFromAddress.length <= 0);
  }, [fieldSMTPServer, fieldFromAddress, isEditing, isTestingSendingEmail]);

  const handleOnTestingSendingEmail = async (event: any) => {
    setIsTestingSendingEmail(true);
    await onSubmitFormGlobalSettings(event);
    const response = await postSendTestEmail();
    if (isSuccessResponse(response)) {
      pushNotification({
        title: "Test email sent",
        type: "success",
        description: response.data?.Notifications,
        autoClose: false,
      });
    } else {
      pushNotification({
        autoClose: false,
        title: "Send test email failed",
        description: response.data?.Errors,
        type: "error",
      });
    }
    setIsTestingSendingEmail(false);
  };
  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={configData[ECorporateSettingsField.CorporateSettings_EmailOn]}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        {emailOn && fieldProtocolType === "0" && (
          <>
            <InputGlobalSettings
              data={configData[ECorporateSettingsField.Global_EmailSMTPServer]}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={configData[ECorporateSettingsField.Global_EmailPort]}
              isEditing={isEditing}
            />
          </>
        )}
      </div>
      {fieldProtocolType === "0" && (
        <div className="cc-form-cols-3">
          <SwitchGlobalSettings
            data={configData[ECorporateSettingsField.Global_EmailEnableSSL]}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <SwitchGlobalSettings
            data={
              configData[
                ECorporateSettingsField.Global_Email_EnableSMTPAuthentication
              ]
            }
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}
      <div className="cc-form-cols-3">
        <InputGlobalSettings
          data={configData[ECorporateSettingsField.Global_EmailSMTPUsername]}
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={configData[ECorporateSettingsField.Global_EmailSMTPPassword]}
          isEditing={isEditing}
          type={"password"}
        />
      </div>
      <div className="cc-form-cols-3">
        <CCSearchComboBoxGlobalSettings
          data={configData[ECorporateSettingsField.Global_AuthenticationType]}
          isEditing={isEditing}
        />
      </div>
      {fieldProtocolType === "1" && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .Global_MailMessage_MicrosoftGraphTenantId
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .Global_MailMessage_MicrosoftGraphAuthenticationURL
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .Global_MailMessage_MicrosoftGraphClientId
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .Global_MailMessage_MicrosoftGraphClientSecret
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.Global_MailMessage_MicrosoftGraphScopes
              ]
            }
            isEditing={isEditing}
          />
        </div>
      )}
      {emailOn && (
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.CorporateSettings_EmailFromAddress
              ]
            }
            isEditing={isEditing}
          />
          <div className="cc-field">
            <label className="cc-label">&nbsp;</label>
            <Button
              type="button"
              disabled={isDisableTestSendingEmail}
              onClick={handleOnTestingSendingEmail}
              className="cc-dialog-button"
              iconClass={isTestingSendingEmail ? "fas fa-spinner fa-spin" : ""}
            >
              Test Email
            </Button>
          </div>
        </div>
      )}
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_Email_SaveAsAction
            ]
          }
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      </div>
    </section>
  );
});
