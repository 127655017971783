import ActionBarStoreService from "@common/apis/actionbar.service";
import React from "react";

/**
 * @deprecated Use useCCListViewActionBar instead
 */
const useActionBarStore = () => {
  const context = React.useContext(ActionBarStoreService);
  if (context === undefined) {
    throw new Error("useActionBarStore in not within ActionBarStoreService");
  }
  return context;
};

export default useActionBarStore;
