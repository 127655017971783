import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const complianceNoticesRoute: ICCRoute = {
  path: "compliance-notices",
  name: "Compliance Notices",
  enum: LLPermitsMenu.Notices,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: LLPermitsMenu.NoticesByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      enum: LLPermitsMenu.NoticesByDueStatus,
      component: require("./by-due-status/_index").default,
    },
  ],
};
