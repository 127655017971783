import { ICCRoute } from "@common/constants/ICCRoute";

export const recycleBinRoute: ICCRoute = {
  path: "recycle-bin",
  name: "Recycle Bin",
  children: [
    {
      path: "applications",
      name: "Applications",
      component: require("./applications/_index").default,
    },
  ],
};
