import { getDisplayTextWithDashes } from "@common/utils/common";

export const LookupKeywordBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },

  getBookmarkListViewDetail() {
    return "Settings - Lookups - Keywords";
  },

  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.Keyword_ID
      ? `- ${selectedRow.Keyword_ID}`
      : "";
    return `Settings - Keywords ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.Keyword_ID + ", " + selectedRow.Name,
    ]);
  },
};
