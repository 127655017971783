import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { Svc_RefUsage_RecycleBin } from "@common/pages/settings/system-admin/recycle-bin/model";

export const postRecycleBinRestore = async (
  records: Svc_RefUsage_RecycleBin[]
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/core/internal/refusage/recycle-bin/restore`,
      records
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
