import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const postEditAction = async (): Promise<APIResponse<any>> => {
  try {
    const response = {
      data: {},
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };

    await sleep(1500);
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;

    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
