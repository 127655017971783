import { DTO_Journal } from "@app/products/property/journals/model";
import { postAddToJournal } from "@app/products/property/journals/not-yet-in-journal/components/action-bar/dialog/add-to-journal/api";
import { RequestJournalTransaction } from "@app/products/property/journals/not-yet-in-journal/components/action-bar/dialog/add-to-journal/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

interface IAddToJournalDialogProps {
  onClose: () => void;
  transactions: RequestJournalTransaction[];
}
const nameOf = nameOfFactory<DTO_Journal>();

export const AddToJournalDialog = observer(
  ({ onClose, transactions }: IAddToJournalDialogProps) => {
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (data: DTO_Journal) => {
      setIsLoading(true);
      const defaultSuccessMessage = "Journal created successfully";
      const defaultErrorMessage = "Add to Journal failed";
      const requestJournalObj = {
        Journal: data,
        Transactions: transactions,
      };
      postAddToJournal(requestJournalObj).then((response) => {
        if (isSuccessResponse(response) && response?.data?.ID) {
          history.push(`/property/journals/${response?.data?.ID}`);
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          notificationRef.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultErrorMessage,
            type: "error",
            autoClose: false,
          });
        }
        setIsLoading(false);
      });
    };
    return (
      <Form
        onSubmitClick={({ values }) => {
          handleSubmit(values as DTO_Journal);
        }}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCDialog
              maxWidth="40%"
              height="auto"
              titleHeader="Create Journal with selected Independent Transactions"
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form cc-add-to-journal-dialog">
                  <section className="cc-field-group">
                    <CCLocalNotification ref={notificationRef} />
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Journal description
                          <CCTooltip type="validator" position="right" />
                        </label>
                        <Field
                          name={nameOf("Jnl_Description")}
                          placeholder={"Journal description"}
                          component={CCInput}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Notes</label>
                        <Field
                          name={nameOf("Jnl_Notes")}
                          component={CCTextArea}
                          placeholder="Notes"
                          rows={3}
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps?.valid}
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                  >
                    Finish
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
