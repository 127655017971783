import {
  TownPlanningRegisterForm,
  TownPlanningRegisterRequest,
  TownPlanningResponse,
} from "@app/products/town-planning/[id]/model";
import { cloneDeep } from "lodash";

export const convertTownPlanningResponseToForm = (
  response: TownPlanningResponse
) => {
  let townPlanningForm = new TownPlanningRegisterForm();
  townPlanningForm = cloneDeep(response) as TownPlanningRegisterForm;

  townPlanningForm.ApplicantName = response?.Applicant?.Contact?.DisplayName;
  townPlanningForm.ApplicantAddress =
    response?.Applicant?.Contact?.PreferredAddress?.StreetName || "";
  townPlanningForm.ApplicantContact =
    response?.Applicant?.Contact?._Mobile || "";

  townPlanningForm.PreferredName =
    response?.PreferredContact?.Contact?.DisplayName || "";
  townPlanningForm.PreferredAddress =
    response?.PreferredContact?.Contact?.PreferredAddress?.StreetName || "";
  townPlanningForm.PreferredContactNumber =
    response?.PreferredContact?.Contact?._Mobile;

  townPlanningForm.SiteAddressOwner =
    response?.SiteAddress?.PropertyAssessment?.Owners_Address;
  townPlanningForm.Zone = response?.SiteAddress?.PropertyAssessment?.Zones;
  townPlanningForm.Overlay = response?.SiteAddress?.PropertyAssessment?.Overlay;

  townPlanningForm.OwnerName =
    response?.SiteAddress?.PropertyAssessment?.Owners_Address;
  townPlanningForm.Details = response?.SiteAddress?.Legal_Description;
  townPlanningForm.Subdivision = response?.Subdivision;

  townPlanningForm.Ward = response?.SiteAddress?.PropertyAssessment?.Ward;
  townPlanningForm.Rin = response?.SiteAddress?.PropertyAssessment?.RIN;
  townPlanningForm.LandSize =
    response?.SiteAddress?.PropertyAssessment?.Assessment_LandSize;

  townPlanningForm.LotNo = response?.SiteAddress?.PropertyAssessment?.LotNo;

  return townPlanningForm;
};

export const convertTownPlanningFormToRegisterRequest = (
  townPlanningForm: TownPlanningRegisterForm
): TownPlanningRegisterRequest => {
  let townPlanningRequest: TownPlanningRegisterRequest =
    new TownPlanningRegisterRequest();
  townPlanningRequest = cloneDeep(townPlanningForm);

  townPlanningRequest.Application_ID = townPlanningForm.Application_ID;
  townPlanningRequest.RefNo = townPlanningForm.RefNo;
  townPlanningRequest.FileNo = townPlanningForm.FileNo;
  townPlanningRequest.ApplicationNumber = townPlanningForm.ApplicationNumber;
  townPlanningRequest.ApplicationYear = townPlanningForm.ApplicationYear;
  townPlanningRequest.ApplicationNumberDisplay =
    townPlanningForm.ApplicationNumberDisplay;
  townPlanningRequest.IsAmendment = townPlanningForm.IsAmendment;
  townPlanningRequest.PrivateSurveyorRegID =
    townPlanningForm.PrivateSurveyorRegID;
  townPlanningRequest.PlanningPrivateSurveyorRegID =
    townPlanningForm.PlanningPrivateSurveyorRegID;
  townPlanningRequest.Date_Created = townPlanningForm.Date_Created;
  townPlanningRequest.LodgedDate = townPlanningForm.LodgedDate;
  townPlanningRequest.DecisionDate = townPlanningForm.DecisionDate;
  townPlanningRequest.FinalDecisionDate = townPlanningForm.FinalDecisionDate;
  townPlanningRequest.Rating_KWD = townPlanningForm.Rating_KWD;
  townPlanningRequest.Rating_Name = townPlanningForm.Rating_Name;
  townPlanningRequest.ApplicationCategories =
    townPlanningForm.ApplicationCategories;
  townPlanningRequest.FeeSchedules = townPlanningForm.FeeSchedules;
  townPlanningRequest.ApplicationCategories_Namelist =
    townPlanningForm.ApplicationCategories_Namelist;
  townPlanningRequest.FeeSchedules_Namelist =
    townPlanningForm.FeeSchedules_Namelist;
  townPlanningRequest.ApplicationDescription =
    townPlanningForm.ApplicationDescription;
  townPlanningRequest.ApplicationDescription_Namelist =
    townPlanningForm.ApplicationDescription_Namelist;
  townPlanningRequest.StatDays = townPlanningForm.StatDays;
  townPlanningRequest.CostOfWorks = townPlanningForm.CostOfWorks;
  townPlanningRequest.Flag_LandDivision = townPlanningForm.Flag_LandDivision;
  townPlanningRequest.Flag_IsTownPlanning =
    townPlanningForm.Flag_IsTownPlanning;
  townPlanningRequest.Flag_IsBuilding = townPlanningForm.Flag_IsBuilding;
  townPlanningRequest.Flag_IsDevApps = townPlanningForm.Flag_IsDevApps;
  townPlanningRequest.Flag_PreApplicationMeeting =
    townPlanningForm.Flag_PreApplicationMeeting;
  townPlanningRequest.Flag_EnforcementIssue =
    townPlanningForm.Flag_EnforcementIssue;
  townPlanningRequest.Flag_PrivateSurveyor =
    townPlanningForm.Flag_PrivateSurveyor;
  townPlanningRequest.Flag_PlansToComply = townPlanningForm.Flag_PlansToComply;
  townPlanningRequest.Flag_PlanningPrivateSurveyor =
    townPlanningForm.Flag_PlanningPrivateSurveyor;
  townPlanningRequest.Flag_EOT = townPlanningForm.Flag_EOT;
  townPlanningRequest.Flag_LodgementFeeRequired =
    townPlanningForm.Flag_LodgementFeeRequired;
  townPlanningRequest.RecordType = townPlanningForm.RecordType;
  townPlanningRequest.ApplicationTypeFlags =
    townPlanningForm.ApplicationTypeFlags;
  townPlanningRequest.Status_ENUM = townPlanningForm.Status_ENUM;
  townPlanningRequest.Decision_ENUM = townPlanningForm.Decision_ENUM;
  townPlanningRequest.DecisionType_ENUM = townPlanningForm.DecisionType_ENUM;
  townPlanningRequest.FinalDecision_ENUM = townPlanningForm.FinalDecision_ENUM;
  townPlanningRequest.FinalDecisionType_ENUM =
    townPlanningForm.FinalDecisionType_ENUM;
  townPlanningRequest.Applicant_RID = townPlanningForm.Applicant_RID;
  townPlanningRequest.Applicant = townPlanningForm.Applicant;
  townPlanningRequest.PreferredContact_RID =
    townPlanningForm.PreferredContact_RID;
  townPlanningRequest.PreferredContact = townPlanningForm.PreferredContact;
  townPlanningRequest.SiteAddress_ID = townPlanningForm.SiteAddress_ID;
  townPlanningRequest.SiteAddress = townPlanningForm.SiteAddress;
  townPlanningRequest.AdditionalAddresses =
    townPlanningForm.AdditionalAddresses;
  townPlanningRequest.ReasonForPermit = townPlanningForm.ReasonForPermit;
  townPlanningRequest.DateReferral = townPlanningForm.DateReferral;
  townPlanningRequest.PrivateCertifier = townPlanningForm.PrivateCertifier;
  townPlanningRequest.DebtorNumber = townPlanningForm.DebtorNumber;
  townPlanningRequest.BuildingDetails_ID = townPlanningForm.BuildingDetails_ID;
  townPlanningRequest.BuildingDetails = townPlanningForm.BuildingDetails;
  townPlanningRequest.AssessmentPlanner_ID =
    townPlanningForm.AssessmentPlanner_ID;
  townPlanningRequest.AssessmentPlanner = townPlanningForm.AssessmentPlanner;
  townPlanningRequest.Flag_EPlanning_Update =
    townPlanningForm.Flag_EPlanning_Update;
  townPlanningRequest.Flag_IsPSA = townPlanningForm.Flag_IsPSA;
  townPlanningRequest.Flag_IsOA = townPlanningForm.Flag_IsOA;
  townPlanningRequest.PrescribeEvents = townPlanningForm.PrescribeEvents;
  townPlanningRequest.PPRDetails = townPlanningForm.PPRDetails;
  townPlanningRequest.Flag_IsPPR = townPlanningForm.Flag_IsPPR;
  townPlanningRequest.Flag_IsStateGovtApp =
    townPlanningForm.Flag_IsStateGovtApp;
  townPlanningRequest.Sys_EnterpriseKey = townPlanningForm.Sys_EnterpriseKey;
  townPlanningRequest.Sys_CreatedDate = townPlanningForm.Sys_CreatedDate;
  townPlanningRequest.Sys_CreatedBy = townPlanningForm.Sys_CreatedBy;
  townPlanningRequest.Sys_CreatedBy_ID = townPlanningForm.Sys_CreatedBy_ID;
  townPlanningRequest.Sys_DBRowState = townPlanningForm.Sys_DBRowState;
  townPlanningRequest.Sys_TransactionDate =
    townPlanningForm.Sys_TransactionDate;
  townPlanningRequest.Sys_ModifiedDate = townPlanningForm.Sys_ModifiedDate;
  townPlanningRequest.Sys_ModifiedBy = townPlanningForm.Sys_ModifiedBy;
  townPlanningRequest.Sys_ModifiedBy_ID = townPlanningForm.Sys_ModifiedBy_ID;
  townPlanningRequest.Sys_ReferenceNumber =
    townPlanningForm.Sys_ReferenceNumber;
  townPlanningRequest.Sys_StatClockStatus_ENUM =
    townPlanningForm.Sys_StatClockStatus_ENUM;

  return townPlanningRequest;
};
