import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const premisesRoute: ICCRoute = {
  path: "premises",
  name: "Premises",
  enum: HealthManagerMenu.Premises,
  children: [
    {
      path: "register",
      name: "Register",
      enum: HealthManagerMenu.PremisesRegister,
      component: require("./register/_index").default,
    },
    {
      path: "register-eha",
      name: "Register",
      enum: HealthManagerMenu.PremisesRegisterEHA,
      // mock route: not implemented yet
      //component: require("./register-eha/_index").default,
    },
    {
      path: "register-light",
      name: "Register",
      enum: HealthManagerMenu.PremisesRegisterLight,
      // mock route: not implemented yet
      //component: require("./register-light/_index").default,
    },
    {
      path: "register-water-authority",
      name: "Register",
      enum: HealthManagerMenu.PremisesRegisterWaterAuthority,
      // mock route: not implemented yet
      //component: require("./register-water-authority/_index").default,
    },
    {
      path: "pre-treatments",
      name: "Pre-Treatments",
      enum: HealthManagerMenu.PreTreatmentRegister,
      // mock route: not implemented yet
      //component: require("./pre-treatments/_index").default,
    },
    {
      path: "by-pre-treatments",
      name: "By Pre-Treatments",
      enum: HealthManagerMenu.PremisesByPreTreatment,
      // mock route: not implemented yet
      //component: require("./by-pre-treatments/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: HealthManagerMenu.PremisesByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-suburb",
      name: "By Suburb",
      enum: HealthManagerMenu.PremisesBySuburb,
      component: require("./by-suburb/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      enum: HealthManagerMenu.PremisesByArea,
      component: require("./by-area/_index").default,
    },
    {
      path: "by-jurisdiction",
      name: "By Jurisdiction",
      enum: HealthManagerMenu.PremisesByJurisdiction,
      // mock route: not implemented yet
      //component: require("./by-jurisdiction/_index").default,
    },
    {
      path: "by-risk",
      name: "By Risk",
      enum: HealthManagerMenu.PremisesByRisk,
      component: require("./by-risk/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: HealthManagerMenu.PremisesByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-fsp",
      name: "By FSP",
      enum: HealthManagerMenu.PremisesByFSP,
      component: require("./by-fsp/_index").default,
    },
    {
      path: "by-property",
      name: "By Property",
      enum: HealthManagerMenu.PremisesByProperty,
      component: require("./by-property/_index").default,
    },
    {
      path: "by-star-rating",
      name: "By Star Rating",
      enum: HealthManagerMenu.PremisesByGrading,
      component: require("./by-star-rating/_index").default,
    },
    {
      path: "by-category",
      name: "By Category",
      enum: HealthManagerMenu.PremisesByCategory,
      component: require("./by-category/_index").default,
    },
    {
      path: "by-proprietor",
      name: "By Proprietor",
      enum: HealthManagerMenu.PremisesByProprietor,
      component: require("./by-proprietor/_index").default,
    },
    {
      path: "pcba",
      name: "PCBA",
      enum: HealthManagerMenu.PremisesPCBA,
      component: require("./pcba/_index").default,
    },
    {
      path: "prescribed-accom",
      name: "Prescribed Accom",
      enum: HealthManagerMenu.PremisesPrescribedAccom,
      component: require("./prescribed-accom/_index").default,
    },
    {
      path: "pools-spas",
      name: "Pools / Spas",
      enum: HealthManagerMenu.PremisesPools,
      component: require("./pools-spas/_index").default,
    },
    {
      path: "cooling-towers",
      name: "Cooling Towers",
      enum: HealthManagerMenu.PremisesCoolingTowers,
      component: require("./cooling-towers/_index").default,
    },
    {
      path: "drinking-water",
      name: "Drinking Water",
      enum: HealthManagerMenu.DrinkingWater,
      component: require("./drinking-water/_index").default,
    },
    {
      path: "fast-choices",
      name: "Fast Choices",
      enum: HealthManagerMenu.PremisesFastChoices,
      component: require("./fast-choices/_index").default,
    },
    {
      path: "food-stalls",
      name: "Food Stalls",
      enum: HealthManagerMenu.PremisesFoodStalls,
      component: require("./food-stalls/_index").default,
    },
    {
      path: "food-vehicles",
      name: "Food Vehicles",
      enum: HealthManagerMenu.PremisesFoodVehicles,
      component: require("./food-vehicles/_index").default,
    },
    {
      path: "food-high-risk",
      name: "High Risk Foods",
      enum: HealthManagerMenu.PremisesFoodHighRisk,
      // mock route: not implemented yet
      //component: require("./food-high-risk/_index").default,
    },
    {
      path: "fbn-unprocessed",
      name: "FBN UnProcessed",
      enum: HealthManagerMenu.PremisesFBN_UnProcessed,
      // mock route: not implemented yet
      //component: require("./fbn-unprocessed/_index").default,
    },
    {
      path: "fbn-processed",
      name: "FBN Processed",
      enum: HealthManagerMenu.PremisesFBN_Processed,
      // mock route: not implemented yet
      //component: require("./fbn-processed/_index").default,
    },
    {
      path: "fbn-registered",
      name: "FBN Registered",
      enum: HealthManagerMenu.PremisesFBN_Registered,
      // mock route: not implemented yet
      //component: require("./fbn-registered/_index").default,
    },
    {
      path: "os-fees",
      name: "OS Fees",
      enum: HealthManagerMenu.PremisesOSFees,
      component: require("./os-fees/_index").default,
    },
    {
      path: "non-renewable",
      name: "Non Renewable",
      enum: HealthManagerMenu.PremisesNonRenewable,
      component: require("./non-renewable/_index").default,
    },
    {
      path: "streatrader",
      name: "Streatrader",
      enum: HealthManagerMenu.PremisesStreatrader,
      component: require("./streatrader/_index").default,
    },
    {
      path: "prelodgements",
      name: "Prelodgements",
      enum: HealthManagerMenu.PremisesPrelodgments,
      component: require("./prelodgements/_index").default,
    },
    {
      path: "closures",
      name: "Closures",
      enum: HealthManagerMenu.PremisesClosures,
      component: require("./closures/_index").default,
    },
    {
      path: "pending-transfers",
      name: "Pending Transfers",
      enum: HealthManagerMenu.PremisesPendingTransfers,
      component: require("./pending-transfers/_index").default,
    },
    {
      path: "due-conditions",
      name: "Due Conditions",
      enum: HealthManagerMenu.PremisesDueConditions,
      component: require("./due-conditions/_index").default,
    },
    {
      path: "comp-conditions",
      name: "Comp. Conditions",
      enum: HealthManagerMenu.PremisesCompliedConditions,
      component: require("./comp-conditions/_index").default,
    },
    {
      path: "alerts",
      name: "Alerts",
      enum: HealthManagerMenu.PremisesAlerts,
      component: require("./alerts/_index").default,
    },
    {
      path: "register-portal-status",
      name: "Portal Status",
      enum: HealthManagerMenu.PremisesRegisterPortalStatus,
      // mock route: not implemented yet
      //component: require("./register-portal-status/_index").default,
    },
    {
      path: "scores-on-doors",
      name: "Scores on Doors",
      enum: HealthManagerMenu.PremisesScoresEndorse,
      // mock route: not implemented yet
      //component: require("./scores-on-doors/_index").default,
    },
  ],
};
