import { TransferDetail } from "@app/products/property/assessments/[id]/components/child-screens/transfer/components/detail/_index";
import { DTO_ChangeOfOwnershipProcessed } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { colTransfer } from "@app/products/property/titles/[id]/components/child-screens/transfer/config";
import { useTitleTransferStore } from "@app/products/property/titles/[id]/components/child-screens/transfer/store";
import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";

const nameOf = nameOfFactory<DTO_ChangeOfOwnershipProcessed>();
export const TitleTransfer = observer(() => {
  const { titleId } = useTitleStore();
  const {
    isLoading,
    transfer,
    transferSelected,
    responseLoadError,
    setTransferSelected,
    loadTransfer,
    resetStore,
  } = useTitleTransferStore();

  useEffect(() => {
    if (!titleId) return;
    loadTransfer(titleId);

    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleId]);

  const handleSelectedTransfer = useCallback(
    (dataItems: DTO_ChangeOfOwnershipProcessed[]) => {
      setTransferSelected(dataItems?.[0]);
    },
    [setTransferSelected]
  );
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          if (!titleId) return;
          loadTransfer(titleId);
        }}
      />
    );
  return (
    <CCGrid
      isLoading={isLoading}
      className="cc-title-transfer-grid"
      data={transfer ?? []}
      columnFields={colTransfer}
      primaryField={nameOf("Id")}
      isLimitDetailWidth
      selectableMode="single"
      detail={TransferDetail}
      onSelectionChange={handleSelectedTransfer}
      selectedRows={transferSelected ? [{ ...transferSelected }] : []}
    />
  );
});
