import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { convertToTitleCase } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

export const generateHMPremisesPrelodgementsColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  // Column Modifiers
  const columnDebtorNumberModifier = (column: IColumnFields) => {
    const debtorNumberLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );
    const title = debtorNumberLabel
      ? convertToTitleCase(debtorNumberLabel)
      : column.title;
    return { ...column, title };
  };

  const columnAssessmentNumberModifier = (column: IColumnFields) => {
    const assessmentNumberLabel = getStringValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_PropertiesAssessmentNoLabel
      ]
    );
    const title = assessmentNumberLabel
      ? convertToTitleCase(assessmentNumberLabel)
      : column.title;
    return { ...column, title };
  };

  const columnMapper = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("DebtorNo"):
        return columnDebtorNumberModifier(column);
      case nameOfHMPremisesView("AssessmentNo"):
        return columnAssessmentNumberModifier(column);
      default:
        return column;
    }
  };

  // Column Filters
  const columnFilterLocationDescription = (_column: IColumnFields) => {
    return getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowLoactionDesc]
    );
  };

  const columnFilter = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("LocationDesc"):
        return columnFilterLocationDescription(column);
      default:
        return true;
    }
  };

  return cloneDeep(defaultConfig).filter(columnFilter).map(columnMapper);
};
