import {
  BalanceTypesEnum,
  DTO_DebtRecovery_Balance,
  DTO_FunctionalityRestriction,
  DTO_Owner,
  DebtRecoveryAction,
} from "@app/products/property/assessments/debt-recovery/[id]/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleCurrency,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOfFunctionalityRestrictions =
  nameOfFactory<DTO_FunctionalityRestriction>();
const nameOfOwner = nameOfFactory<DTO_Owner>();
const nameOfBalance = nameOfFactory<DTO_DebtRecovery_Balance>();
export const colFunctionalityRestrictions: IColumnFields[] = [
  {
    field: nameOfFunctionalityRestrictions("FunctionalityName"),
    title: "Functionality",
  },
  {
    field: nameOfFunctionalityRestrictions("IsRestricted"),
    title: "Restrict?",
    cell: (props: GridCellProps) => {
      const { dataItem } = props;
      return (
        <TextCell
          value={
            dataItem?.RestrictedFrom || dataItem?.RestrictedTo ? "Yes" : "No"
          }
        />
      );
    },
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfFunctionalityRestrictions("RestrictedFrom"),
    title: "From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfFunctionalityRestrictions("RestrictedTo"),
    title: "To",
    format: DATE_FORMAT.DATE,
  },
];

export const colOwner: IColumnFields[] = [
  {
    field: nameOfOwner("Name"),
    title: "Name",
    linkTo: (dataItem: DTO_Owner) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.EntityId}`,
  },
  {
    field: nameOfOwner("Address"),
    title: "Current Address",
  },
  {
    field: nameOfOwner("EntityId"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colBalances: IColumnFields[] = [
  {
    field: nameOfBalance("Type"),
    title: "Type",
    filterByEnum: BalanceTypesEnum,
  },
  {
    field: nameOfBalance("InitialDept"),
    title: "Initial Dept",
    format: CURRENCY_FORMAT.CURRENCY1,
    style: formatStyleCurrency,
  },
  {
    field: nameOfBalance("DebtBalances"),
    title: "Debt Balances",
    format: CURRENCY_FORMAT.CURRENCY1,
    style: formatStyleCurrency,
  },
];

const nameOf = nameOfFactory<DebtRecoveryAction>();
export const colActions: IColumnFields[] = [
  {
    field: nameOf("ActionsDateAndTime"),
    title: "Actions Date & Time",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DocumentIssuedOn"),
    title: "Document Issued On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DocumentIssuedBy"),
    title: "Document Issued By",
  },
  {
    field: nameOf("ModifiedOn"),
    title: "Modified On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ModifiedBy"),
    title: "Modified By",
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CreatedBy"),
    title: "Created By",
  },
  {
    field: nameOf("DebtRecoveryActionId"),
    title: "Document ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
