import { eventEmitter } from "@/App";
import { saveComment } from "@app/core/comments/api";
import { AddCommentDialog } from "@app/core/comments/components/dialogs/add-comment/_index";
import { useCommentDialogStore } from "@app/core/comments/components/dialogs/add-comment/store";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { Comments } from "@app/core/comments/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { EPrivilegesWorkflow } from "@app/products/property/model";

import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

export interface ICommentButtonProps {
  id?: number | string;
  recordType: RECORDTYPE;
  isUseDropDownForTitle?: boolean;
  isDisabled?: boolean;
  bubbleUps?: BubbleUpIdentifier[];
  shouldApplyWrapper?: boolean; //Should apply privilege wrapper: currently just for Property comment button
}

export const AddCommentButton = observer(
  ({
    id,
    recordType = RECORDTYPE.CommunityProperty_Assessment,
    isUseDropDownForTitle = false,
    isDisabled = false,
    bubbleUps,
    shouldApplyWrapper = false,
  }: ICommentButtonProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { isShowCommentDialog, setIsShowCommentDialog } =
      useCommentDialogStore();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const handleOnSubmit = async (data: Comments) => {
      setIsLoadingSubmit(true);
      const requestBody: Comments = {
        ID: 0,
        Comment: data.Comment ?? "",
        Title: data.Title,
        BubbleUps: bubbleUps ?? [
          {
            PKID: 0,
            SourceIdentifier: {
              _RecordSource_ID: id ? +id : 0,
              _RecordSourceType_ENUM: recordType.toString(),
              _RecordSource_ColumnName: "",
              _RecordSource_StringID: "",
            },
            BubbleUpType_ENUM: BubbleUpType.Ancestor,
            BubbleUpType_Name: BubbleUpType.Ancestor.toString(),
            Sys_CreatedDate: new Date(),
            Sys_DeactivationDate: null,
          },
          {
            PKID: 0,
            SourceIdentifier: {
              _RecordSource_ID: id ? +id : 0,
              _RecordSourceType_ENUM: recordType.toString(),
              _RecordSource_ColumnName: "",
              _RecordSource_StringID: "",
            },
            BubbleUpType_ENUM: BubbleUpType.Parent,
            BubbleUpType_Name: BubbleUpType.Parent.toString(),
            Sys_CreatedDate: new Date(),
            Sys_DeactivationDate: null,
          },
        ],
        workFlowDraftID: null,
      };
      const defaultSuccessMessage = "Comment saved successfully";
      const defaultErrorMessage = "Save comment failed";

      const response = await saveComment(requestBody as any, recordType);
      if (
        isSuccessResponse(response) &&
        response?.data?.ErrorStatus === IdentityPacketErrorStatus.Success
      ) {
        pushNotification({
          title: response?.data?.Notifications?.[0] ?? defaultSuccessMessage,
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData, {
          gridIds: [COMMENT_ACCORDION_GRID_ID],
        });
        if (!isUseDropDownForTitle) {
          //PPR: refresh tab count and history
          eventEmitter.emit(CCJournalEventType.RefreshData);
          eventEmitter.emit(TabTableEventType.RefreshData);
        }
        setIsShowCommentDialog(false);
      } else {
        notificationRef.current?.pushNotification({
          title: response?.data?.Errors ?? defaultErrorMessage,
          type: "error",
          autoClose: false,
        });
      }
      setIsLoadingSubmit(false);
    };

    return (
      <>
        <PrivilegeWrapper
          dialogType={ePermissionPrivilegeTypeCheck.Dialog}
          privilegesEnum={EPrivilegesWorkflow.Comment_Add}
          shouldApplyWrapper={shouldApplyWrapper}
        >
          <CCNavButton
            title="Comment"
            onClick={() => setIsShowCommentDialog(true)}
            disabled={isDisabled}
          />
        </PrivilegeWrapper>
        {isShowCommentDialog && (
          <AddCommentDialog
            onClose={() => setIsShowCommentDialog(false)}
            onSubmit={handleOnSubmit}
            recordType={recordType}
            notificationRef={notificationRef}
            isLoadingSubmit={isLoadingSubmit}
            isUseDropDownForTitle={isUseDropDownForTitle}
          />
        )}
      </>
    );
  }
);
