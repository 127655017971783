import { mockInitialValuesParcelZones } from "@app/products/property/parcels/[id]/components/child-screens/zones/components/dialogs/mock";
import { ParcelZonesEventType } from "@app/products/property/parcels/[id]/components/child-screens/zones/constant";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import React, { useState } from "react";

export const ParcelZoneDialog = () => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  useAddUniqueEventEmitter([
    {
      eventType: ParcelZonesEventType.zoneDetails,
      listener: (parcelZoneId: number) => {
        setIsShowDialog(true);
      },
    },
  ]);

  return isShowDialog ? (
    <Form
      initialValues={mockInitialValuesParcelZones}
      render={() => {
        return (
          <CCDialog
            height="auto"
            maxWidth="40%"
            titleHeader={"Zone Details"}
            onClose={() => setIsShowDialog(false)}
            bodyElement={
              <FormElement>
                <div className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Scheme Name</label>
                        <Field
                          name="SchemeName"
                          placeholder="Scheme Name"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Zone Scheme Heading</label>
                        <Field
                          name="ZoneSchemeHeading"
                          placeholder="Zone Scheme Heading"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Zone Scheme Text</label>
                        <Field
                          name="ZoneSchemeText"
                          placeholder="Zone Scheme Text"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Zone Name</label>
                        <Field
                          name="ZoneName"
                          component={CCInput}
                          placeholder="Zone Name"
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Zone Code</label>
                        <Field
                          name="ZoneCode"
                          component={CCInput}
                          placeholder="Zone Code"
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Header</label>
                        <Field
                          name="Header"
                          placeholder="Header"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Objectives</label>
                        <Field
                          name="Objectives"
                          placeholder="Objectives"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Without Consent</label>
                        <Field
                          name="WithoutConsent"
                          placeholder="Without Consent"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>

                      <div className="cc-field">
                        <label className="cc-label">With Consent</label>
                        <Field
                          name="WithConsent"
                          placeholder="With Consent"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>

                      <div className="cc-field">
                        <label className="cc-label">With Concurrence</label>
                        <Field
                          name="WithConcurrence"
                          placeholder="With Concurrence"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Prohibited</label>
                        <Field
                          name="Prohibited"
                          placeholder="Prohibited"
                          rows={3}
                          component={CCTextArea}
                          readOnly
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <br />
              </FormElement>
            }
          />
        );
      }}
    />
  ) : (
    <></>
  );
};
