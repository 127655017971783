import { colAssociationsDebtRecovery } from "@app/products/property/components/associations/components/debt-recovery/config";
import { DTO_AssociatedItem_DebtRecovery } from "@app/products/property/components/associations/components/debt-recovery/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsDebtRecoveryProps {
  associationsDebtRecoveryInfo: DTO_AssociatedItem_DebtRecovery[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_DebtRecovery>();

export const AssociationsDebtRecovery = ({
  associationsDebtRecoveryInfo,
}: IAssociationsDebtRecoveryProps) => {
  return (
    <CCGrid
      className="cc-debt-recovery-grid"
      data={associationsDebtRecoveryInfo || []}
      columnFields={colAssociationsDebtRecovery}
      primaryField={nameOf("Id")}
    />
  );
};
