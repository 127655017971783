import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import {
  createSupplementaryFromValuation,
  getCreateSupplementaryFromImportValuationLOVs,
} from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/create-supplementary/api";
import { DTO_Supplementary } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/create-supplementary/model";
import { DTO_Supplementary_Import } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface ICreateSuppWithImportValDialogProps {
  onClose: () => void;
}

const nameOf = nameOfFactory<DTO_Supplementary>();
export const CreateSuppWithImportValDialog = observer(
  ({ onClose }: ICreateSuppWithImportValDialogProps) => {
    const history = useHistory();
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const { pushNotification } = useCCAppNotificationStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [createSuppFromImportValLOVs, setCreateSuppFromImportValLOVs] =
      useState<DTO_Supplementary_Import>();
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<DTO_Supplementary>();

    const loadLOVs = async () => {
      setIsLoading(true);
      const response = await getCreateSupplementaryFromImportValuationLOVs();
      let errorResponse = undefined;
      if (isSuccessResponse(response)) {
        setCreateSuppFromImportValLOVs(response?.data);
      } else {
        errorResponse = {
          status: response?.status,
          error: response?.error ?? "Load failed",
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    useEffectOnce(() => {
      loadLOVs();
    });

    const handleFinish = async (data: DTO_Supplementary) => {
      setIsSubmitting(true);
      const response = await createSupplementaryFromValuation(data);
      setIsSubmitting(false);
      setFinishConfirmDialogData(undefined);
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        history.push(
          `${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/${response?.data?.Component_ID}`
        );
        pushNotification({
          title:
            "Created supplementary successfully sent to job queue. You will be notified once the job has been completed.",
          type: "success",
        });
      } else {
        notificationRef.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? "Create supplementary failed.",
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <Form
        onSubmit={(data) => {
          setFinishConfirmDialogData(data as DTO_Supplementary);
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, modified, valid } = formRenderProps;
          return (
            <>
              <CCDialog
                titleHeader="Create Supplementary with Import Valuations"
                onClose={onClose}
                maxWidth="55%"
                maxHeight="70%"
                bodyElement={
                  //Loading
                  isLoading ? (
                    <Loading isLoading />
                  ) : //Load other data failed
                  responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        loadLOVs();
                      }}
                    />
                  ) : (
                    <div className="cc-import-valuation cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <FormElement>
                        <>
                          <section className="cc-field-group">
                            <div className="cc-field">
                              <CCLabel title="Supplementary type" isMandatory />
                              <Field
                                name={nameOf("Type")}
                                data={
                                  createSuppFromImportValLOVs?.SupplementaryTypes ??
                                  []
                                }
                                textField="Name"
                                dataItemKey="Code"
                                component={CCSearchComboBox}
                                isUseDefaultOnchange
                                validator={requiredValidator}
                              />
                              <div className="cc-custom-sub-panel-bar">
                                <br />
                                VM2000 supplementary to import
                              </div>
                            </div>
                          </section>
                          <hr className="cc-divider" />
                          <section className="cc-field-group">
                            <CCLabel title="Supplementary details" />
                            <div className="cc-custom-sub-panel-bar">
                              <div className="cc-form-cols-1">
                                <div className="cc-field">
                                  <CCLabel title="Name" isMandatory />
                                  <Field
                                    name={nameOf("Name")}
                                    placeholder="Name"
                                    component={CCInput}
                                    validator={requiredValidator}
                                  />
                                </div>
                                <div className="cc-field">
                                  <CCLabel title="Reference" />
                                  <Field
                                    name={nameOf("Reference")}
                                    placeholder="Reference"
                                    component={CCInput}
                                  />
                                </div>
                                <div className="cc-field">
                                  <CCLabel
                                    title="Supplementary date"
                                    isMandatory
                                  />
                                  <Field
                                    name={nameOf("Date")}
                                    format={DATE_FORMAT.DATE_CONTROL}
                                    component={CCDatePicker}
                                    validator={requiredValidator}
                                  />
                                </div>
                                <div className="cc-field">
                                  <CCLabel title="Notes or additional comments regarding the supplementary" />
                                  <Field
                                    placeholder="Notes or additional comments regarding the supplementary"
                                    name={nameOf("Notes")}
                                    rows={3}
                                    component={CCTextArea}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                        </>
                      </FormElement>
                    </div>
                  )
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={!modified || !valid}
                      onClick={onSubmit}
                    >
                      Finish
                    </Button>
                  </div>
                }
              />
              {finishConfirmDialogData && (
                <ConfirmDialog
                  title="Confirmation"
                  maxHeight="70%"
                  subMessage="Are you sure you want to submit?"
                  onClosePopup={() => setFinishConfirmDialogData(undefined)}
                  isLoadingYes={isSubmitting}
                  onAsyncConfirm={() => {
                    return handleFinish(finishConfirmDialogData);
                  }}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
