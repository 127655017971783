import { ParcelConstraintsDialog } from "@app/products/property/parcels/[id]/components/child-screens/constraints/components/dialogs";
import { colParcelConstraints } from "@app/products/property/parcels/[id]/components/child-screens/constraints/config";
import { DTO_Constraint } from "@app/products/property/parcels/[id]/components/child-screens/constraints/model";
import { useParcelsConstraintsStore } from "@app/products/property/parcels/[id]/components/child-screens/constraints/store";
import { useParcelsStore } from "@app/products/property/parcels/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export const ParcelConstraints = observer(() => {
  const { isLoading, constraints, loadConstraints, resetStore } =
    useParcelsConstraintsStore();
  const nameOf = nameOfFactory<DTO_Constraint>();
  const { parcelId } = useParcelsStore();
  useEffect(() => {
    if (parcelId) loadConstraints(parcelId);
    return () => {
      resetStore();
    };
  }, [loadConstraints, parcelId, resetStore]);

  return (
    <div className="cc-parcel-constraints">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Parcel constraints</label>
              <CCGrid
                isLoading={isLoading}
                className="cc-parcel-constraints-grid"
                data={constraints?.Constraints ?? []}
                columnFields={colParcelConstraints}
                primaryField={nameOf("ParcelConstraintId")}
              />
            </div>
          </div>
        </div>
      </div>
      <ParcelConstraintsDialog />
    </div>
  );
});
