export const editorSubjectStyle = `
body {
  padding: 4px;
  padding-left: 8px;
  padding-top: 6px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 0.875rem
}`;
