import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Change_of_Ownership } from "@app/products/property/changes-of-ownership/list/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Change_of_Ownership>();
export const colList: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "Change of Ownership ID",
    locked: true,
    linkTo: (dataItem) => {
      if (dataItem.Assessment_Id)
        return {
          pathname: `${PROPERTY_ASSESSMENT_ROUTE}/` + dataItem.Assessment_Id,
          state: { isExpandedTransfer: true },
        };
      return undefined;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Change_of_Ownership_Type_Name"),
    title: "Change Type",
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Effective_Date"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Assessment_Number_Formatted"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Sale_Price"),
    title: "Sale Price",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Vendors"),
    title: "Vendors",
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("Change_of_Ownership_Status_Name"),
    title: "Status",
  },
  {
    field: nameOf("Changed_By"),
    title: "Change User ID",
  },
  {
    field: nameOf("Changed_On"),
    title: "Change Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
