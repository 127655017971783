import { history } from "@/AppRoutes";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { getNoticeTypeById, getNoticeTypeLovs, postNoticeType } from "@common/pages/settings/lookups/notice-types/_id/api";
import { IParentNoticeTypeSection, LookupNoticeTypeSubmitActions, NoticeType, NoticesTypeLovs } from "@common/pages/settings/lookups/notice-types/_id/model";
import { SETTINGS_LOOKUPS_NOTICETYPES_ROUTE } from "@common/pages/settings/lookups/notice-types/constant";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class NoticeTypesStore {
  private _noticeType?: NoticeType = undefined;
  private _noticeTypeLovs?: NoticesTypeLovs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _isInactive: boolean = false;
  private _parent?: IParentNoticeTypeSection = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get noticeType() {
    return toJS(this._noticeType);
  }
  setNoticeType = (noticeType?: NoticeType) => {
    runInAction(() => {
      this._noticeType = noticeType;
    });
  };

  get noticeTypeLovs() {
    return toJS(this._noticeTypeLovs);
  }
  setNoticeTypeLovs = (noticeTypeLovs?: NoticesTypeLovs) => {
    runInAction(() => {
      this._noticeTypeLovs = noticeTypeLovs;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentNoticeTypeSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._noticeType = undefined;
      this._noticeTypeLovs = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isInactive = false;
      this._parent = undefined;
      this._onSubmit = undefined;
    });
  };

  get noticeTypeId() {
    return toJS(this.noticeType?.NoticeType_ID);
  }

  loadLOVsAction = async () => {
    const response = await getNoticeTypeLovs();
    if (isSuccessResponse(response) && response?.data?.ReturnObj) {
      this.setNoticeTypeLovs(response?.data?.ReturnObj);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.data?.Errors ?? "Notice type lovs load failed",
        type: "error",
      });
    }
  };

  reloadNoticeTypes = async (): Promise<boolean> => {
    if (this.noticeTypeId) {
      return await this.loadNoticeType(this.noticeTypeId);
    }
    return false;
  };

  loadNoticeType = async (
    noticeTypeID: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    this.loadLOVsAction();
    const id = !isNew ? noticeTypeID : 0;
    const response = await getNoticeTypeById(id);
    if(isSuccessResponse(response) && response?.data?.ReturnObj) {
      this.setNoticeType(response.data.ReturnObj);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: response.data?.Errors ?? "Load notice type failed",
      };
    }
    if (!isNew && response?.data?.ReturnObj) {
      // Push notification
      if (this.parent?.notification) {
        this.parent?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setParent({ ...this.parent, notification: [] });
      }
      if (response.data.ReturnObj?.Sys_DBRowState === DBRowState.Inactive) {
        this.setIsInactive(true);
        appNotificationStore.clearNotifications();
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Important Note",
          type: "info",
          description:
            "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
        });
      } else {
        this.setIsInactive(false);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveNoticeType = async (
    noticeTypeInfo: NoticeType,
    action: LookupNoticeTypeSubmitActions
  ) => {
    this.setIsLoading(true);
    if (isNil(noticeTypeInfo.Description)) noticeTypeInfo.Description = "";
    const response = await postNoticeType(noticeTypeInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.noticeTypeId) await this.loadNoticeType(this.noticeTypeId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save noticeType failed",
        type: "error",
        description: response.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (action: LookupNoticeTypeSubmitActions, response?: APIResponse) => {
    switch (action) {
      case LookupNoticeTypeSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Notice type saved successfully",
          type: "success",
        });
        break;
      case LookupNoticeTypeSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(
          `${SETTINGS_LOOKUPS_NOTICETYPES_ROUTE}/${response?.data?.ID}`, {
            parent: {
            notification: [
              { title: "Notice type saved successfully", type: "success" },
            ],
          }
      });
        break;
      default:
        break;
    }
  };
}

export const noticeTypesStore = new NoticeTypesStore();
const NoticeTypesStoreContext = createContext(noticeTypesStore);
export const useNoticeTypesStore = () => {
  return useContext(NoticeTypesStoreContext);
};
