import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";

const MOCK_URL = "";
export const TownPlanningApiUrl = {
  // TownPlanning CRUD API
  CREATE_TOWNPLANNING: "/api/tp/Internal/tpapplication",
  GET_TOWNPLANNING: "/api/tp/Internal/tpapplication/{id}",
  UPDATE_TOWNPLANNING: "/api/tp/Internal/tpapplication/{id}",
  DELETE_TOWNPLANNING: "/api/tp/Internal/tpapplication/{id}",

  // @TODO TownPlanning Form OData
  GET_PLANNERS_TOWNPLANNING: CORE_OFFICERS_ODATA,
  GET_FINAL_OUTCOME_PROPOSE_TOWNPLANING: CORE_OFFICERS_ODATA,

  // TownPlanning View OData
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_REGISTER:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationRegisterAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_REGISTERSEARCH:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationRegisterAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_OPEN:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationRegisterOpen?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_MYAPPLICATIONS:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationMyApplications?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_BYSTATUS:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsByStatusAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_BYLOCATION:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsByLocalityAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_BYTYPE:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsByTypeAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_BYRATING:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsByRatingAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_BYWARD:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsByWardAll?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_CURRENTBYOFFICER:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsByPlanner?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_VICSMART:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsVicSmart?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_NOAPPEAL:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsNoAppeal?$count=true&",
  GET_VIEW_TOWNPLANNING_ALLAPPLICATION_REGISTERSTATEGOVT:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationsStateGovt?$count=true&",
  GET_VIEW_TOWNPLANNING_AMENDMENTS_REGISTER:
    "/odata/tp/internal/tpapplication/GetViewTPApplicationAmendments?$count=true&",
  GET_VIEW_TOWNPLANNING_PLANTOCOMPLY_REGISTER:
    "/odata/tp/internal/tpapplication/GetViewTPPlansToComply?$count=true&",
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_REGISTER:
    "/odata/tp/internal/tpapplication/GetViewTPCertifications?$count=true&",
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_CERTANDNOCOMPLIANCE:
    "/odata/tp/internal/tpapplication/GetViewTPCertificationsNoCompliance?$count=true&",
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_COMPLIANCE:
    "/odata/tp/internal/tpapplication/GetViewTPCertificationsCompliance?$count=true&",
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_REFUSED:
    "/odata/tp/internal/tpapplication/GetViewTPCertificationsRefused?$count=true&",
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_WITHDRAWN:
    "/odata/tp/internal/tpapplication/GetViewTPCertificationsWithdrawn?$count=true&",
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_NOTCERTIFIED:
    "/odata/tp/internal/tpapplication/GetViewTPCertificationsNotCertified?$count=true&",

  // Mock TownPlanning View OData
  GET_VIEW_TOWNPLANNING_AMENDMENTS_BYSTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_PLANTOCOMPLY_BYSTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_BYSTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_CERTIFICATIONS_BYESTATE: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SPEAR_INBOX: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SPEAR_OUTBOX: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SPEAR_REGISTER: MOCK_URL,
  GET_VIEW_TOWNPLANNING_APPEALS_ALL: MOCK_URL,
  GET_VIEW_TOWNPLANNING_APPEALS_CURRENTBYSTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_APPEALS_CURRENTBYMONTH: MOCK_URL,
  GET_VIEW_TOWNPLANNING_REGISTERS_PLANNINGENQUIRIES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_REGISTERS_PLANNINGCERTIFICATES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_REGISTERS_SECONDARYCONSENT: MOCK_URL,
  GET_VIEW_TOWNPLANNING_REGISTERS_EXTENSIONOFTIME: MOCK_URL,
  GET_VIEW_TOWNPLANNING_CRMSAPPLICATIONS_DRAFT: MOCK_URL,
  GET_VIEW_TOWNPLANNING_OTHER_INTERESTEDPARTIES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_OTHER_FIREQUESTS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_OTHER_ADVERTISING: MOCK_URL,
  GET_VIEW_TOWNPLANNING_OTHER_MEETINGS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_OTHER_INTERESTEDPARTIESALL: MOCK_URL,
  GET_VIEW_TOWNPLANNING_COMPLAINTS_BYREFNO: MOCK_URL,
  GET_VIEW_TOWNPLANNING_COMPLAINTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_TOWNPLANNING_COMPLAINTS_BYSTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_PSA_REGISTER: MOCK_URL,
  GET_VIEW_TOWNPLANNING_PSA_DEFERRED: MOCK_URL,
  GET_VIEW_TOWNPLANNING_PSA_EXHIBITION: MOCK_URL,
  GET_VIEW_TOWNPLANNING_PSA_INTERESTEDPARTIES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_PSA_INTERESTEDPARTIESALL: MOCK_URL,
  GET_VIEW_TOWNPLANNING_NONCOMPLIANCE_REGISTER: MOCK_URL,
  GET_VIEW_TOWNPLANNING_NONCOMPLIANCE_BYOFFICER: MOCK_URL,
  GET_VIEW_TOWNPLANNING_NONCOMPLIANCE_BYSTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_NONCOMPLIANCE_NOTICEBYDATE: MOCK_URL,
  GET_VIEW_TOWNPLANNING_NONCOMPLIANCE_NOTICEBYDUESTATUS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_FINANCE_OSFEES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_FINANCE_FEESPAID: MOCK_URL,
  GET_VIEW_TOWNPLANNING_FINANCE_FEESALL: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NEWAPPLICATIONS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NEWSUBMISSIONS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NEWENQUIRIES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NEWSECONDARYCONSENT: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NEWEXTENSIONOFTIME: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NEWDOCSFI: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_AUDITHISTORY: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_APPLICATIONCATEGORIES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_EPLANNINGDEVELOPMENTS_NATUREOFWORK: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SYSTEMADMIN_APPLICATIONCATEGORIES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SYSTEMADMIN_CONDITIONS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SYSTEMADMIN_CLAUSES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SYSTEMADMIN_CALCULATEDFEES: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SYSTEMADMIN_FEESCHEDULE: MOCK_URL,
  GET_VIEW_TOWNPLANNING_SYSTEMADMIN_REPORTS: MOCK_URL,
  GET_VIEW_TOWNPLANNING_RECYCLEBIN_APPLICATIONS: MOCK_URL,
};
