import { IDataForCancelDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ConfirmCancelDialogStore {
  private _dataForCancelDialog?: IDataForCancelDialog = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get dataForCancelDialog(): IDataForCancelDialog | undefined {
    return this._dataForCancelDialog;
  }
  setDataForCancelDialog = (dataForCancelDialog?: IDataForCancelDialog) => {
    runInAction(() => {
      this._dataForCancelDialog = dataForCancelDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._dataForCancelDialog = undefined;
    });
  };
}

const confirmCancelDialogStoreContext = createContext(
  new ConfirmCancelDialogStore()
);
export const useConfirmCancelDialogStore = () => {
  return useContext(confirmCancelDialogStoreContext);
};
