import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
} from "@components/cc-input-picker/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React, { useMemo } from "react";

interface ISelectionSettingsProps {
  field: SettingField;
  isEditing: boolean;
  isMultiple: boolean;
  formRenderProps: FormRenderProps;
  separator?: string;
  itemKey?: string;
}

export const SelectionGlobalSettings = (props: ISelectionSettingsProps) => {
  const {
    field,
    isEditing,
    isMultiple,
    formRenderProps,
    separator = "|",
    itemKey = "Id",
  } = props;
  const { onChange, valueGetter } = formRenderProps;

  const data = field?.LookupItems;
  const value = valueGetter(field.FieldName);

  const selectedRows = useMemo(
    () =>
      value
        .split(separator)
        .map((id: string) => data?.find((item) => item[itemKey] === id))
        .filter((item: any) => item),
    [value, data, separator, itemKey]
  );

  const valueDisplay = useMemo(
    () =>
      selectedRows
        .reduce((prev: any, curr: any) => `${prev}, ${curr?.Name}`, "")
        .slice(1)
        .trim(),
    [selectedRows]
  );

  const onChangeComboBoxValue = (event: ICCInputPickerChangeEvent) => {
    const valueList = !isMultiple && event.value ? [event.value] : event.value;
    let currentValue = separator;
    if (valueList) {
      valueList.forEach(
        (item: ILookupItem) => (currentValue += item.Id + separator)
      );
    }

    onChange(field.FieldName, {
      value: currentValue,
    });
  };

  const convertToTitleCase = (text: string) => {
    return text
      .split(" ")
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  };

  return (
    field && (
      <div className="cc-field">
        <label className="cc-label">
          {field.Title ?? ""}
          {field.IsMandatory && <CCTooltip type="validator" position="right" />}
          {field.HelpText && (
            <CCTooltip
              type="custom"
              content=" "
              position="auto"
              clickToOpen
              customTemplate={<>{sanitizeHtml(field.HelpText)}</>}
            >
              <i className="fa fa-info-circle ml-1 text-accent" />
            </CCTooltip>
          )}
        </label>
        {field.Description && (
          <label className="cc-settings-description">
            {sanitizeHtml(field.Description)}
          </label>
        )}
        <Field
          name={field.FieldName}
          value={valueDisplay}
          component={CCInputPicker}
          disabled={!isEditing}
          dialog={{
            maxWidth: "35%",
            height: "auto",
            titleHeader: convertToTitleCase(field.Title),
          }}
          grid={{
            columnFields: [
              {
                field: "Name",
                title: "Name",
              },
            ],
            selectableMode: isMultiple ? "multiple" : "single",
            itemPerPage: 50,
            selectedRows,
            primaryField: "Id",
            data,
          }}
          showClearButton
          onChange={onChangeComboBoxValue}
          validator={field.IsMandatory ? requiredValidator : undefined}
        />
      </div>
    )
  );
};
