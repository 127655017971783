import { VMASResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/model";
import { WastewaterSearchAddress } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/address/_index";
import { LocalityResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/model";
import { WastewaterSearchLocality } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/_index";
import { Address } from "@app/products/waste-water/[id]/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export const TabAddress = ({
  formRenderProps,
}: {
  formRenderProps: FormRenderProps;
}) => {
  const [checkComplexAddress, setCheckComplexAddress] = useState(false);
  const { onChange, valueGetter } = formRenderProps;

  const handleOnChangeAddress = (address: VMASResult) => {
    let currentAddress: Address = valueGetter("");

    currentAddress.AddressLine1 = address.AddressLine;
    currentAddress.PropertyName = address.PropertyName;
    currentAddress.UnitNo = address.UnitNo;
    currentAddress.StreetNo = address.StreetNo;
    currentAddress.StreetName = address.StreetName;
    currentAddress.Suburb = address.Suburb;
    currentAddress.State = address.State;
    currentAddress.Postcode = address.Postcode;

    currentAddress.VMAS_Confidence = address.Confidence
      ? +address.Confidence
      : NaN;
    currentAddress.VMAS_GeoCode = address.GeoCode;
    currentAddress.VMAS_PosNorthSouth = address.PosNorthSouth
      ? +address.PosNorthSouth
      : NaN;
    currentAddress.VMAS_PosEastWest = address.PosEastWest
      ? +address.PosEastWest
      : NaN;
    currentAddress.FullAddress_SingleLine = address.Address_Identifier;
    currentAddress.PostalDelivery_Identifier =
      address.PostalDelivery_Identifier;
    currentAddress.PostalDelivery_Barcode = address.PostalDelivery_Barcode;
    currentAddress.Formatted_SingleLine = address.Address_Identifier;
    onChange("", { value: currentAddress });
  };
  return (
    <div className="cc-form">
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Enter an address</label>
            <Field
              name={"_option.EnterAnAddress"}
              component={WastewaterSearchAddress}
              placeholder={"Enter an address"}
              onChangeAddress={handleOnChangeAddress}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Property name</label>
            <Field
              name={"PropertyAssessment.PropertyName"}
              component={Input}
              placeholder={"Property name"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Location description</label>
            <Field
              name={"PropertyAssessment.AddressLocationDescriptor"}
              component={Input}
              placeholder={"Location description"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Complex address</label>
            <CCSwitch
              checked={checkComplexAddress}
              onChange={(event) => setCheckComplexAddress(event.value)}
            />
          </div>
        </div>

        {checkComplexAddress ? (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Unit number start</label>
                <Field
                  name={"PropertyAssessment.UnitNumber1"}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"Unit number start"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Unit number end</label>
                <Field
                  name={"PropertyAssessment.UnitNumber2"}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"Unit number end"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Unit abbreviation</label>
                <Field
                  name={"PropertyAssessment.UnitAbbreviation"}
                  component={Input}
                  placeholder={"Unit abbreviation"}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">House number start</label>
                <Field
                  name={"PropertyAssessment.HouseNumber1"}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"House number start"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">House number end</label>
                <Field
                  name={"PropertyAssessment.HouseNumber2"}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"House number end"}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Floor number start</label>
                <Field
                  name={"PropertyAssessment.FloorNumber1"}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"Floor number start"}
                />
              </div>

              <div className="cc-field">
                <label className="cc-label">Floor number end</label>

                <Field
                  name={"PropertyAssessment.FloorNumber2"}
                  component={CCNumericTextBox}
                  spinners={false}
                  format={NUMBER_FORMAT.NUMBER2}
                  placeholder={"Floor number end"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Floor abbreviation</label>
                <Field
                  name={"PropertyAssessment.FloorTypeAbbreviation"}
                  component={Input}
                  placeholder={"Floor abbreviation"}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Unit number</label>
              <Field
                name={"PropertyAssessment.UnitNumber1"}
                component={CCNumericTextBox}
                spinners={false}
                format={NUMBER_FORMAT.NUMBER2}
                placeholder={"Unit number"}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">House number</label>
              <Field
                name={"PropertyAssessment.HouseNumber1"}
                spinners={false}
                format={NUMBER_FORMAT.NUMBER2}
                component={CCNumericTextBox}
                placeholder={"House number"}
              />
            </div>
          </div>
        )}
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Street name</label>
            <Field
              name={"PropertyAssessment.Street_Name"}
              component={Input}
              placeholder={"Street name"}
            />
          </div>
        </div>
      </section>
      <hr className="cc-divider" />
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Locality name</label>
            <Field
              name={"PropertyAssessment.Locality_Name"}
              component={WastewaterSearchLocality}
              placeholder={"Locality name"}
              onChangeLocality={(locality: LocalityResult | null) => {
                onChange("PropertyAssessment.Locality_Postcode", {
                  value: locality?.PostCode ?? null,
                });
                onChange("State", { value: locality?.State ?? null });
              }}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postcode</label>
            <Field
              name={"PropertyAssessment.Locality_Postcode"}
              component={Input}
              placeholder={"Postcode"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality PFI</label>
            <Field
              name={"PropertyAssessment.Locality_PFI"}
              component={Input}
              placeholder={"Locality PFI"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Locality code</label>
            <Field
              name={"PropertyAssessment.Locality_Code"}
              component={Input}
              placeholder={"Locality code"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">State</label>
            <Field name={"State"} component={Input} placeholder={"State"} />
          </div>
        </div>
      </section>
    </div>
  );
};
