import { useAssessmentTransactionStore } from "@app/products/property/assessments/[id]/components/child-screens/transactions/store";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ECustomColNameProperty } from "@app/products/property/config";
import { WorkflowTypes } from "@app/products/property/model";
import { Label } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const TransferTransactionButton = observer(() => {
  const { gridSelectedRows } = useAssessmentTransactionStore();
  const { setListDialog } = usePropertyWorkflowStore();

  //Get label
  const transferToAnotherAssessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Transfer_To_Another_Assessment
  );

  return (
    <>
      <CCNavButton
        title={transferToAnotherAssessmentLabel}
        disabled={gridSelectedRows?.length !== 1}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_Assessment_Transafer_Transaction,
              data: {},
            },
          ]);
        }}
      />
    </>
  );
});
