export enum COMMENT_DIALOG_MODE {
  Hide = "Hide",
  Edit = "Edit",
  New = "New",
}

export interface DTO_Workflow_Memo {
  Workflow_Memo_Id: number;
  WM_Workflow_Draft_Id: number;
  WM_Workflow_Memo_Category_Id: number;
  WM_Create_DateTime?: Date;
  WM_Modify_DateTime?: Date;
  WM_Change_User_Id?: number;
  WM_Memo: string;
  WM_Seq: number | null;
}
