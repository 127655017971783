import { NoticeOfSaleStatus } from "@app/products/property/changes-of-ownership/notice-of-sales/constant";
import { VO_NoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/model";
import { WorkflowIdCell } from "@app/products/property/components/cells/workflow-id-cell/_index";
import { WorkflowTypes } from "@app/products/property/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<VO_NoticeOfSale>();

export const noticeOfSaleFilterState: CompositeFilterDescriptor = {
  filters: [
    {
      field: nameOf("Status"),
      operator: "neq",
      value: NoticeOfSaleStatus.Cancelled,
    },
    {
      field: nameOf("Status"),
      operator: "neq",
      value: NoticeOfSaleStatus.Completed,
    },
  ],
  logic: "and",
};

export const noticeOfSalesColumns: IColumnFields[] = [
  {
    field: nameOf("File_Name"),
    title: "File Name",
  },
  {
    field: nameOf("Extract_Date"),
    title: "Extract Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Workflow_Id"),
    title: "Workflow ID",
    format: NUMBER_FORMAT.NUMBER2,
    cell: (props: GridCellProps) => (
      <WorkflowIdCell
        props={props}
        workflowType={WorkflowTypes.Change_Of_Ownership_NoticeOfSale}
      />
    ),
  },
  {
    field: nameOf("Title_System"),
    title: "Title System",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Book_Number"),
    title: "Book Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Page_Number"),
    title: "Page Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Dealing_Number"),
    title: "Dealing Number",
  },
  {
    field: nameOf("Dealing_Type"),
    title: "Dealing Type",
  },
  {
    field: nameOf("Purchase_Price"),
    title: "Purchase Price",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Acquisition_Date"),
    title: "Acquisition Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Contract_Date"),
    title: "Contract Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Settlement_Date"),
    title: "Settlement Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Area"),
    title: "Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Transfer_Type"),
    title: "Transfer Type",
  },
  {
    field: nameOf("Nature_Of_Property"),
    title: "Nature of Property",
  },
  {
    field: nameOf("Agent_Reference_Number"),
    title: "Agent Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Agent_Name"),
    title: "Agent Name",
  },
  {
    field: nameOf("Agent_Address"),
    title: "Agent Address",
  },
  {
    field: nameOf("Agent_Postcode"),
    title: "Agent Postcode",
  },
  {
    field: nameOf("Agent_Phone_Number"),
    title: "Agent Phone Number",
  },
  {
    field: nameOf("Share_Percentage_Acquired"),
    title: "Share Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOf("Property_Type"),
    title: "Property Type",
  },
  {
    field: nameOf("Comment"),
    title: "Comment",
  },
  {
    field: nameOf("File_Id"),
    title: "File ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
