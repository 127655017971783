import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { VoidReadingsButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/void-readings/_index";
import { MeterContactsTab } from "@app/products/property/meters/[id]/components/reference-sidebar/contact/_index";
import { MeterDetailTab } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/_index";
import { VerifyReadingButton } from "@app/products/property/meters/last-reading-details/components/action-bar/buttons/_index";
import { colMeterLastReadingDetailsList } from "@app/products/property/meters/last-reading-details/config";
import { mockMeterLastReadingDetailsList } from "@app/products/property/meters/last-reading-details/mock";
import { IMeterLastReadingDetails } from "@app/products/property/meters/last-reading-details/model";
import { metersRoute } from "@app/products/property/meters/route";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  const nameOf = nameOfFactory<IMeterLastReadingDetails>();

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],
    centerComponents: [<VoidReadingsButton />, <VerifyReadingButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={PROPERTY_METERS_ROUTE}
      //   displayName={getBookmarkDisplayName}
      //   detail={getBookmarkDetail}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <MeterDetailTab />,
      },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Related",
      },
      {
        title: "Contacts",
        component: <MeterContactsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab recordType={RECORDTYPE.CommunityProperty_Meter} />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      data={mockMeterLastReadingDetailsList}
      columnFields={colMeterLastReadingDetailsList}
      primaryField={nameOf("MeterId")}
    />
  );
};
