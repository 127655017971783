import { IListLocalLaws } from "@app/products/local-laws/model";

// @TODO: Need to integrate later
const MOCK_URL = "";
export const LocalLawsUrl = {
  // LocalLaws CRUD API
  CREATE_LOCALLAWS: "",
  GET_LOCALLAWS: "",
  UPDATE_LOCALLAWS: "",
  DELETE_LOCALLAWS: "",

  // LocalLaws Form OData
  GET_FORM_LOCALLAWS: "",

  // LocalLaws View OData

  // Mock LocalLaws View OData
  GET_VIEW_LOCALLAWS_PERMITS_REGISTER: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_BYTYPE: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_BYSTATUS: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_BYLOCATION: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_BYOFFICER: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_BYAREA: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_NEWAPPLICATIONS: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_LODGED: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_REFUSED: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_EXPIRED: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_DEACTIVATED: MOCK_URL,
  GET_VIEW_LOCALLAWS_PERMITS_INSURANCESTATUS: MOCK_URL,
  GET_VIEW_LOCALLAWS_RENEWALS_ALLBYDUESTATUS: MOCK_URL,
  GET_VIEW_LOCALLAWS_RENEWALS_REQUIRESRENEWALFEE: MOCK_URL,
  GET_VIEW_LOCALLAWS_RENEWALS_REQUIRESRENEWALNOTICE: MOCK_URL,
  GET_VIEW_LOCALLAWS_RENEWALS_REQUIRESRENEWAL: MOCK_URL,
  GET_VIEW_LOCALLAWS_INSPECTIONS_BYDATE: MOCK_URL,
  GET_VIEW_LOCALLAWS_INSPECTIONS_BYTYPE: MOCK_URL,
  GET_VIEW_LOCALLAWS_INSPECTIONS_BYOFFICER: MOCK_URL,
  GET_VIEW_LOCALLAWS_INSPECTIONS_BYMONTH: MOCK_URL,
  GET_VIEW_LOCALLAWS_COMPLAINTS_BYREFNO: MOCK_URL,
  GET_VIEW_LOCALLAWS_COMPLAINTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_LOCALLAWS_COMPLAINTS_BYSTATUS: MOCK_URL,
  GET_VIEW_LOCALLAWS_COMPLIANCENOTICES_BYDATE: MOCK_URL,
  GET_VIEW_LOCALLAWS_COMPLIANCENOTICES_BYDUESTATUS: MOCK_URL,
  GET_VIEW_LOCALLAWS_CRMSAPPLICATIONS_DRAFT: MOCK_URL,
  GET_VIEW_LOCALLAWS_FINANCE_OSFEES: MOCK_URL,
  GET_VIEW_LOCALLAWS_FINANCE_PAIDFEES: MOCK_URL,
  GET_VIEW_LOCALLAWS_FINANCE_FEESALL: MOCK_URL,
  GET_VIEW_LOCALLAWS_FINANCE_NOTSENTTOFINANCE: MOCK_URL,
  GET_VIEW_LOCALLAWS_FINANCE_SENTTOFINANCE: MOCK_URL,
  GET_VIEW_LOCALLAWS_SYSTEMADMIN_PERMITTYPES: MOCK_URL,
  GET_VIEW_LOCALLAWS_SYSTEMADMIN_CONDITIONS: MOCK_URL,
};

export const mockLocalLawsAll: IListLocalLaws[] = [
  {
    ID: 1,
    PermitNumber: 53906,
    Applicant: 54655,
    SiteAddress: "Suite 832",
    Locality: "Montana",
    Type: "Producer",
    StatusDueStatus: "Officer",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Jonathan Fahey",
    EffectiveFrom: "Fri Jan 31 2020 17:17:02 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 19:44:14 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Eda Armstrong",
    Area: "Oregon",
    DateInspected: "Fri Mar 13 2020 04:50:19 GMT+0700 (Indochina Time)",
    ReferenceNumber: 29250,
    ContactName: "Darius Bernhard",
    InspectionType: "Officer",
    Compliance:
      "I'll bypass the virtual IB transmitter, that should bus the SAS system!",
    Reason: "We need to program the neural SAS hard drive!",
    InsurancePolicyNumber: 72759,
    InsuranceCompany: "Bradtke Group",
    AmountOS: 814.0,
    DateRecorded: "Mon Jun 29 2020 05:45:04 GMT+0700 (Indochina Time)",
    RecordedBy: "Kelsi Kulas",
    OrgStructure: "Schaefer, Mills and Rice",
    ActionOfficer: "Hannah Upton",
    Coordinator: "Bogisich and Sons",
    DateWork: "Wednesday",
    FileNumber: "salad_optical_vertical.spf",
    EnquiryRefNo: 35442,
    Event_ReferenceNoAdditional: 2109,
    OnBehalfOf: 3812,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Wed Jun 03 2020 03:35:59 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 03:46:19 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 20:45:57 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 67554,
    CreatedDate: "Mon Jun 01 2020 19:51:28 GMT+0700 (Indochina Time)",
    FeeType: "Orchestrator",
    AmountIssued: 298.0,
    AmountOutstanding: 597.0,
    CostCode: "1MT9C7QLIC3A09GGFV6HLE2BLYKFPL8ZJ",
    InvoiceNumber: 39634,
    DebtorNumber: 91295,
    Name: "Rosella",
    Description:
      "Vero autem est nihil natus. Quis ea repellendus consectetur omnis sequi. Consequatur explicabo quam mollitia libero id ea quae quibusdam quasi. Consequatur doloremque qui officiis quis amet. Id sunt tempore doloribus consectetur natus temporibus aut.\n \rQuia qui hic expedita et neque dolore quidem hic delectus. Eaque velit beatae sit natus voluptate ut voluptatem assumenda harum. Eum minima minus ut.\n \rFacere dolorum maiores et et. Voluptatem enim et quos. Aut nulla non nesciunt nihil consequuntur officia. Nisi quo architecto perspiciatis est enim odio voluptate et perferendis. Assumenda est repudiandae unde placeat veniam et provident.",
    SortIndex: 20804,
    Title: "fundamental_sri_lanka_rupee_global.uvs",
  },
  {
    ID: 8539,
    PermitNumber: 76159,
    Applicant: 78565,
    SiteAddress: "Apt. 082",
    Locality: "Delaware",
    Type: "Specialist",
    StatusDueStatus: "Coordinator",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Lacy Dooley",
    EffectiveFrom: "Sun Mar 29 2020 08:44:24 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 00:41:35 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Vita Nikolaus",
    Area: "New Mexico",
    DateInspected: "Sun Sep 13 2020 03:59:19 GMT+0700 (Indochina Time)",
    ReferenceNumber: 54260,
    ContactName: "Christiana Schaden",
    InspectionType: "Orchestrator",
    Compliance:
      "I'll copy the haptic JBOD capacitor, that should monitor the AI hard drive!",
    Reason:
      "The SSL capacitor is down, connect the back-end sensor so we can synthesize the FTP pixel!",
    InsurancePolicyNumber: 31364,
    InsuranceCompany: "Gleason Group",
    AmountOS: 47.0,
    DateRecorded: "Fri Feb 28 2020 01:19:10 GMT+0700 (Indochina Time)",
    RecordedBy: "Kyra Schultz",
    OrgStructure: "Trantow, Dibbert and Rippin",
    ActionOfficer: "Arjun Nader",
    Coordinator: "Renner, Pacocha and Gottlieb",
    DateWork: "Monday",
    FileNumber: "hat_table_interface.mseq",
    EnquiryRefNo: 19298,
    Event_ReferenceNoAdditional: 18880,
    OnBehalfOf: 89010,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Fri Dec 11 2020 18:19:52 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 07:57:33 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 15:10:01 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 31141,
    CreatedDate: "Mon Oct 19 2020 09:18:08 GMT+0700 (Indochina Time)",
    FeeType: "Officer",
    AmountIssued: 398.0,
    AmountOutstanding: 140.0,
    CostCode: "32SPBD7Q5V0UMEZBE7NAEUBPYQB",
    InvoiceNumber: 5418,
    DebtorNumber: 27077,
    Name: "Alexandria",
    Description:
      "Natus est sed dolorum quisquam quibusdam temporibus sunt aut. Ut vero est eum. Magnam et pariatur animi nesciunt quos ipsa. Odit veniam qui rem aliquid. Praesentium autem ratione temporibus perspiciatis earum.",
    SortIndex: 60508,
    Title: "turn_key.jng",
  },
  {
    ID: 81900,
    PermitNumber: 3967,
    Applicant: 36499,
    SiteAddress: "Apt. 712",
    Locality: "Kansas",
    Type: "Associate",
    StatusDueStatus: "Engineer",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Conor Moen",
    EffectiveFrom: "Sat Jul 04 2020 23:54:13 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 21:47:23 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Tate Kuhn",
    Area: "New Jersey",
    DateInspected: "Wed Nov 25 2020 11:28:24 GMT+0700 (Indochina Time)",
    ReferenceNumber: 2450,
    ContactName: "America Feeney",
    InspectionType: "Representative",
    Compliance:
      "Use the digital SCSI sensor, then you can hack the bluetooth card!",
    Reason:
      "The SCSI system is down, reboot the redundant capacitor so we can generate the SAS pixel!",
    InsurancePolicyNumber: 9475,
    InsuranceCompany: "Stark - Lakin",
    AmountOS: 24.0,
    DateRecorded: "Tue Aug 25 2020 07:04:57 GMT+0700 (Indochina Time)",
    RecordedBy: "Stuart Bernier",
    OrgStructure: "Stokes, Rice and Howe",
    ActionOfficer: "Hershel Fadel",
    Coordinator: "Harber, Purdy and Jacobs",
    DateWork: "Saturday",
    FileNumber: "generating.mfm",
    EnquiryRefNo: 2856,
    Event_ReferenceNoAdditional: 26026,
    OnBehalfOf: 56381,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Tue Jan 21 2020 06:26:56 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 07:45:04 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 19:34:33 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 15508,
    CreatedDate: "Sat Aug 29 2020 13:24:11 GMT+0700 (Indochina Time)",
    FeeType: "Assistant",
    AmountIssued: 987.0,
    AmountOutstanding: 155.0,
    CostCode: "3LRC48GESFZ7O31I626PJMZWGCCUODYHK",
    InvoiceNumber: 51305,
    DebtorNumber: 4227,
    Name: "Baylee",
    Description:
      "Totam ut sit omnis ea nisi. Beatae qui molestias voluptate amet. Ullam illum repudiandae dolorem consequatur et est perferendis architecto modi. Aut maxime facere sunt est maxime ipsam dignissimos vel alias. Dolorem optio fugiat.\n \rModi in non. Est provident quas architecto perferendis quis sint aspernatur. Id aut possimus. Qui sed voluptatem saepe totam alias alias dolore repellat. Quibusdam voluptatum voluptas est porro sed.\n \rEst similique placeat rerum. Sit dolores enim voluptates quos temporibus voluptatem atque ducimus. Neque ratione est velit rerum sequi sit voluptatum dolores. Aut dolorum quia aut voluptatem. Laudantium dolores aut molestiae tempore qui laudantium perspiciatis. Neque ipsa beatae ipsa eveniet soluta rerum.",
    SortIndex: 9345,
    Title: "frozen.sit",
  },
  {
    ID: 37570,
    PermitNumber: 53923,
    Applicant: 1798,
    SiteAddress: "Suite 649",
    Locality: "Mississippi",
    Type: "Orchestrator",
    StatusDueStatus: "Associate",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Theresa D'Amore",
    EffectiveFrom: "Sat May 16 2020 22:55:25 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 05:58:49 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Otha Hermann",
    Area: "Montana",
    DateInspected: "Sun Apr 26 2020 11:57:00 GMT+0700 (Indochina Time)",
    ReferenceNumber: 24630,
    ContactName: "Jennings Hodkiewicz",
    InspectionType: "Director",
    Compliance:
      "Use the optical AGP microchip, then you can copy the 1080p driver!",
    Reason:
      "copying the array won't do anything, we need to bypass the open-source TCP panel!",
    InsurancePolicyNumber: 62172,
    InsuranceCompany: "Rippin and Sons",
    AmountOS: 308.0,
    DateRecorded: "Fri Jun 19 2020 23:50:15 GMT+0700 (Indochina Time)",
    RecordedBy: "Pierce MacGyver",
    OrgStructure: "Beatty - Strosin",
    ActionOfficer: "Bryce Kuphal",
    Coordinator: "Lakin, Hirthe and Fisher",
    DateWork: "Saturday",
    FileNumber: "credit_card_account_withdrawal_baby.oa3",
    EnquiryRefNo: 59373,
    Event_ReferenceNoAdditional: 58589,
    OnBehalfOf: 23158,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sat Feb 22 2020 16:33:38 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 04:49:05 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 10:03:18 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 32384,
    CreatedDate: "Sat Apr 04 2020 01:51:17 GMT+0700 (Indochina Time)",
    FeeType: "Developer",
    AmountIssued: 579.0,
    AmountOutstanding: 362.0,
    CostCode: "11UV9AFJKWSNP43U80447F0RAJS9HB",
    InvoiceNumber: 62105,
    DebtorNumber: 40354,
    Name: "Juliet",
    Description:
      "Earum soluta praesentium molestiae rerum. Assumenda minima velit magnam. Iusto modi eos architecto iusto officiis voluptas sit vero ipsum. Quae dolore quod. Doloribus consequatur nihil qui eum pariatur similique.",
    SortIndex: 9208,
    Title: "silver_tasty_fresh_chicken_24/365.p7c",
  },
  {
    ID: 42777,
    PermitNumber: 81821,
    Applicant: 62200,
    SiteAddress: "Suite 894",
    Locality: "Connecticut",
    Type: "Agent",
    StatusDueStatus: "Designer",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Rickey Prohaska",
    EffectiveFrom: "Thu Nov 26 2020 12:57:41 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 19:33:30 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Kristin Jenkins",
    Area: "Indiana",
    DateInspected: "Wed Apr 29 2020 22:43:32 GMT+0700 (Indochina Time)",
    ReferenceNumber: 52031,
    ContactName: "Landen Buckridge",
    InspectionType: "Executive",
    Compliance:
      "Try to parse the JSON bandwidth, maybe it will back up the optical hard drive!",
    Reason:
      "The USB hard drive is down, input the bluetooth array so we can override the SCSI hard drive!",
    InsurancePolicyNumber: 87938,
    InsuranceCompany: "Cummerata - Fritsch",
    AmountOS: 701.0,
    DateRecorded: "Thu Jun 04 2020 17:58:53 GMT+0700 (Indochina Time)",
    RecordedBy: "Albertha Hessel",
    OrgStructure: "Kshlerin Inc",
    ActionOfficer: "Arnold Wiegand",
    Coordinator: "Schaefer - Armstrong",
    DateWork: "Thursday",
    FileNumber: "thx_shoes.ppd",
    EnquiryRefNo: 83013,
    Event_ReferenceNoAdditional: 80493,
    OnBehalfOf: 18287,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Mon Nov 09 2020 08:21:09 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 09:34:42 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 22:08:19 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 5825,
    CreatedDate: "Tue Jun 16 2020 13:01:13 GMT+0700 (Indochina Time)",
    FeeType: "Associate",
    AmountIssued: 531.0,
    AmountOutstanding: 583.0,
    CostCode: "37RMFFWOP6QBG4CM69Z66MVYJH7Z6",
    InvoiceNumber: 31412,
    DebtorNumber: 63728,
    Name: "Zelda",
    Description:
      "Praesentium perferendis architecto explicabo et sit sequi id. Molestiae et laboriosam distinctio laboriosam quibusdam perferendis dignissimos est qui. Reprehenderit dolor autem id perspiciatis magni dolorem in.",
    SortIndex: 94298,
    Title: "compress_integrate_motivating.g2w",
  },
  {
    ID: 80916,
    PermitNumber: 54127,
    Applicant: 43389,
    SiteAddress: "Suite 089",
    Locality: "Idaho",
    Type: "Orchestrator",
    StatusDueStatus: "Engineer",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Kelly Hilpert",
    EffectiveFrom: "Fri May 29 2020 07:52:45 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 11:21:35 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Tyreek Kihn",
    Area: "Tennessee",
    DateInspected: "Fri Jan 31 2020 13:39:30 GMT+0700 (Indochina Time)",
    ReferenceNumber: 19973,
    ContactName: "Jamir Mosciski",
    InspectionType: "Coordinator",
    Compliance:
      "If we bypass the matrix, we can get to the RAM interface through the digital SSL bandwidth!",
    Reason:
      "You can't hack the program without hacking the cross-platform AGP card!",
    InsurancePolicyNumber: 90419,
    InsuranceCompany: "Mraz - McGlynn",
    AmountOS: 472.0,
    DateRecorded: "Mon Sep 14 2020 12:50:42 GMT+0700 (Indochina Time)",
    RecordedBy: "Joanie Cummerata",
    OrgStructure: "Maggio Inc",
    ActionOfficer: "Juanita Connelly",
    Coordinator: "Christiansen - Braun",
    DateWork: "Sunday",
    FileNumber: "licensed_soft_gloves_full_range_backing_up.ppd",
    EnquiryRefNo: 33781,
    Event_ReferenceNoAdditional: 13982,
    OnBehalfOf: 27058,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sun May 10 2020 04:21:57 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 02:43:52 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 19:51:00 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 49332,
    CreatedDate: "Fri Jul 10 2020 21:44:51 GMT+0700 (Indochina Time)",
    FeeType: "Assistant",
    AmountIssued: 39.0,
    AmountOutstanding: 141.0,
    CostCode: "1GF3A6QFE8H98KTIRH38LQBK0E6",
    InvoiceNumber: 66219,
    DebtorNumber: 39742,
    Name: "Maiya",
    Description:
      "Quia eum excepturi.\nEos sit dolorem.\nRecusandae et est sit amet exercitationem enim molestiae.\nEsse necessitatibus et omnis.",
    SortIndex: 20169,
    Title: "override_unbranded_metal_chips_invoice.jnlp",
  },
  {
    ID: 56940,
    PermitNumber: 26843,
    Applicant: 14547,
    SiteAddress: "Suite 904",
    Locality: "Minnesota",
    Type: "Planner",
    StatusDueStatus: "Analyst",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Creola Nicolas",
    EffectiveFrom: "Sun Jul 05 2020 23:22:36 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 20:00:19 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Rod Kub",
    Area: "North Carolina",
    DateInspected: "Sat Nov 07 2020 02:10:32 GMT+0700 (Indochina Time)",
    ReferenceNumber: 71098,
    ContactName: "Aileen Blick",
    InspectionType: "Designer",
    Compliance: "We need to override the haptic GB alarm!",
    Reason:
      "You can't connect the program without indexing the back-end SAS port!",
    InsurancePolicyNumber: 50597,
    InsuranceCompany: "Osinski Inc",
    AmountOS: 936.0,
    DateRecorded: "Thu Jun 18 2020 13:15:53 GMT+0700 (Indochina Time)",
    RecordedBy: "Jaquelin Boyle",
    OrgStructure: "Cummings Group",
    ActionOfficer: "Beulah Batz",
    Coordinator: "Tremblay - Roob",
    DateWork: "Sunday",
    FileNumber: "borders_jewelery.cxx",
    EnquiryRefNo: 11257,
    Event_ReferenceNoAdditional: 71850,
    OnBehalfOf: 63794,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Mon Jan 13 2020 22:55:51 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 07:29:35 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 12:29:08 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 86765,
    CreatedDate: "Wed Nov 25 2020 19:03:49 GMT+0700 (Indochina Time)",
    FeeType: "Producer",
    AmountIssued: 0.0,
    AmountOutstanding: 522.0,
    CostCode: "3NBZ7LLYS4WQ1898S5X3OLG0KFNR",
    InvoiceNumber: 57273,
    DebtorNumber: 48328,
    Name: "Omer",
    Description:
      "Omnis fugit recusandae. Maxime ratione aut voluptatem excepturi. Exercitationem provident repellendus cumque sit.",
    SortIndex: 47229,
    Title: "payment.pnm",
  },
  {
    ID: 92336,
    PermitNumber: 99679,
    Applicant: 32203,
    SiteAddress: "Suite 169",
    Locality: "Georgia",
    Type: "Facilitator",
    StatusDueStatus: "Orchestrator",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Frankie Mohr",
    EffectiveFrom: "Thu Jun 18 2020 22:33:37 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 15:42:24 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Abbigail Bosco",
    Area: "Tennessee",
    DateInspected: "Mon Jan 06 2020 18:58:43 GMT+0700 (Indochina Time)",
    ReferenceNumber: 29610,
    ContactName: "Asia Runolfsdottir",
    InspectionType: "Consultant",
    Compliance:
      "You can't program the pixel without generating the virtual JBOD panel!",
    Reason:
      "Use the digital CSS circuit, then you can hack the neural microchip!",
    InsurancePolicyNumber: 3559,
    InsuranceCompany: "O'Reilly, Dietrich and Nitzsche",
    AmountOS: 97.0,
    DateRecorded: "Sat Apr 04 2020 18:48:55 GMT+0700 (Indochina Time)",
    RecordedBy: "Samara Bechtelar",
    OrgStructure: "Hilpert Inc",
    ActionOfficer: "Cyrus Gaylord",
    Coordinator: "DuBuque - Morissette",
    DateWork: "Sunday",
    FileNumber: "transmitting_phased.chat",
    EnquiryRefNo: 15918,
    Event_ReferenceNoAdditional: 68188,
    OnBehalfOf: 31176,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Mon Oct 05 2020 02:27:18 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 04:18:22 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 14:21:51 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 25248,
    CreatedDate: "Sun Mar 22 2020 16:29:39 GMT+0700 (Indochina Time)",
    FeeType: "Analyst",
    AmountIssued: 528.0,
    AmountOutstanding: 118.0,
    CostCode: "1X8IHRNWDBHQTONAIRFNO8KDEJLTL8D9H4",
    InvoiceNumber: 39195,
    DebtorNumber: 6396,
    Name: "Cornelius",
    Description:
      "Inventore praesentium tempora. Officiis est non libero. Nihil beatae quam numquam nulla id repellat est.\n \rQui doloribus et. Veniam omnis commodi doloribus enim assumenda consequatur. Consequatur inventore nemo et aut voluptates maxime est. Voluptatem dolor eligendi eos minima adipisci neque et unde. Est aliquid quo.\n \rFacilis nihil placeat eligendi. Ea quas quasi ut dolores accusamus maxime. Iure sed deserunt molestiae. Quia pariatur dolorem magnam. Eos quisquam aliquid cumque. Ut quo consequatur explicabo.",
    SortIndex: 27742,
    Title: "producer_well_bedfordshire.pfm",
  },
  {
    ID: 12212,
    PermitNumber: 96006,
    Applicant: 19347,
    SiteAddress: "Suite 278",
    Locality: "North Carolina",
    Type: "Liason",
    StatusDueStatus: "Liason",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Josefa Pacocha",
    EffectiveFrom: "Tue May 12 2020 00:57:43 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 12:44:05 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Mohammed Cartwright",
    Area: "Washington",
    DateInspected: "Thu Sep 03 2020 13:00:40 GMT+0700 (Indochina Time)",
    ReferenceNumber: 58715,
    ContactName: "Haskell Graham",
    InspectionType: "Facilitator",
    Compliance:
      "Try to back up the GB microchip, maybe it will synthesize the back-end array!",
    Reason:
      "I'll reboot the multi-byte FTP sensor, that should feed the XML microchip!",
    InsurancePolicyNumber: 56449,
    InsuranceCompany: "Blick - Pfannerstill",
    AmountOS: 72.0,
    DateRecorded: "Wed Sep 23 2020 05:49:36 GMT+0700 (Indochina Time)",
    RecordedBy: "Israel Botsford",
    OrgStructure: "Jones Inc",
    ActionOfficer: "Rollin Bahringer",
    Coordinator: "Bernier - Romaguera",
    DateWork: "Sunday",
    FileNumber: "electronics_index_calculate.sxg",
    EnquiryRefNo: 66202,
    Event_ReferenceNoAdditional: 12854,
    OnBehalfOf: 80203,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Tue Nov 24 2020 08:15:34 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 07:11:06 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 19:44:45 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 2710,
    CreatedDate: "Sun Nov 15 2020 05:23:48 GMT+0700 (Indochina Time)",
    FeeType: "Technician",
    AmountIssued: 49.0,
    AmountOutstanding: 691.0,
    CostCode: "1VYJINAJ2GD1SDHUC9CDUP5IAOMUJ",
    InvoiceNumber: 86114,
    DebtorNumber: 40288,
    Name: "Priscilla",
    Description:
      "Necessitatibus perferendis iusto aut est quos alias qui enim.\nDoloribus non nulla ullam deleniti minus eos incidunt recusandae.",
    SortIndex: 95308,
    Title: "operations_shoals.cco",
  },
  {
    ID: 75314,
    PermitNumber: 81887,
    Applicant: 88628,
    SiteAddress: "Apt. 346",
    Locality: "Texas",
    Type: "Associate",
    StatusDueStatus: "Consultant",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Warren Strosin",
    EffectiveFrom: "Thu May 21 2020 00:44:49 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 01:50:39 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Vallie Gottlieb",
    Area: "Oklahoma",
    DateInspected: "Mon Aug 17 2020 21:19:48 GMT+0700 (Indochina Time)",
    ReferenceNumber: 72706,
    ContactName: "Phyllis Fahey",
    InspectionType: "Technician",
    Compliance:
      "I'll input the virtual COM pixel, that should driver the PCI array!",
    Reason:
      "Try to reboot the FTP capacitor, maybe it will hack the primary interface!",
    InsurancePolicyNumber: 49792,
    InsuranceCompany: "Hintz - Kling",
    AmountOS: 386.0,
    DateRecorded: "Wed Nov 11 2020 11:15:17 GMT+0700 (Indochina Time)",
    RecordedBy: "Clotilde Oberbrunner",
    OrgStructure: "Cassin, McLaughlin and Wunsch",
    ActionOfficer: "Hoyt Ankunding",
    Coordinator: "Langosh LLC",
    DateWork: "Saturday",
    FileNumber: "senior_borders.pskcxml",
    EnquiryRefNo: 24641,
    Event_ReferenceNoAdditional: 66329,
    OnBehalfOf: 80400,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sat Dec 05 2020 01:58:59 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 22:05:58 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 12:54:15 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 15056,
    CreatedDate: "Sun Sep 27 2020 23:30:25 GMT+0700 (Indochina Time)",
    FeeType: "Analyst",
    AmountIssued: 490.0,
    AmountOutstanding: 693.0,
    CostCode: "3XA2QOYMYGKY8POMWEUWSA9Z283E9O",
    InvoiceNumber: 79142,
    DebtorNumber: 94637,
    Name: "Rosa",
    Description: "sit aperiam ullam",
    SortIndex: 30568,
    Title: "vietnam_orchestrator_pa'anga.mp4",
  },
  {
    ID: 97684,
    PermitNumber: 76848,
    Applicant: 35627,
    SiteAddress: "Suite 421",
    Locality: "South Carolina",
    Type: "Orchestrator",
    StatusDueStatus: "Strategist",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Dolores Oberbrunner",
    EffectiveFrom: "Tue Feb 04 2020 00:38:49 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 22:49:24 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Casimir Emard",
    Area: "Indiana",
    DateInspected: "Sun Dec 13 2020 04:26:36 GMT+0700 (Indochina Time)",
    ReferenceNumber: 17708,
    ContactName: "Jarrell Terry",
    InspectionType: "Consultant",
    Compliance:
      "You can't generate the capacitor without connecting the open-source THX matrix!",
    Reason:
      "If we synthesize the panel, we can get to the HTTP sensor through the online CSS capacitor!",
    InsurancePolicyNumber: 75484,
    InsuranceCompany: "Smith - Effertz",
    AmountOS: 79.0,
    DateRecorded: "Mon Sep 28 2020 21:46:49 GMT+0700 (Indochina Time)",
    RecordedBy: "Raven Schroeder",
    OrgStructure: "Fadel - Kautzer",
    ActionOfficer: "Adrian Spinka",
    Coordinator: "Wintheiser - Hyatt",
    DateWork: "Tuesday",
    FileNumber: "capacity.pskcxml",
    EnquiryRefNo: 70968,
    Event_ReferenceNoAdditional: 19726,
    OnBehalfOf: 58385,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sun Nov 01 2020 08:05:50 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 01:01:55 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 00:38:30 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 39166,
    CreatedDate: "Tue Mar 03 2020 12:35:50 GMT+0700 (Indochina Time)",
    FeeType: "Facilitator",
    AmountIssued: 182.0,
    AmountOutstanding: 666.0,
    CostCode: "1B3WM5QNRGWZIJYPP8WQDYEBHLT9E37",
    InvoiceNumber: 15337,
    DebtorNumber: 43041,
    Name: "Nolan",
    Description: "quia",
    SortIndex: 90199,
    Title: "national_concrete_massachusetts.mpe",
  },
  {
    ID: 48830,
    PermitNumber: 97250,
    Applicant: 75229,
    SiteAddress: "Suite 927",
    Locality: "Florida",
    Type: "Developer",
    StatusDueStatus: "Administrator",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Luna Lesch",
    EffectiveFrom: "Sat Dec 28 2019 10:35:29 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 01:54:16 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Georgianna Gaylord",
    Area: "Wisconsin",
    DateInspected: "Thu Nov 12 2020 22:03:57 GMT+0700 (Indochina Time)",
    ReferenceNumber: 77763,
    ContactName: "Noble Streich",
    InspectionType: "Supervisor",
    Compliance:
      "Try to calculate the PCI alarm, maybe it will synthesize the redundant card!",
    Reason:
      "Try to transmit the RAM feed, maybe it will transmit the haptic capacitor!",
    InsurancePolicyNumber: 39088,
    InsuranceCompany: "Cormier, McClure and Lesch",
    AmountOS: 832.0,
    DateRecorded: "Mon Jul 20 2020 22:20:47 GMT+0700 (Indochina Time)",
    RecordedBy: "Modesta Hirthe",
    OrgStructure: "Ernser - Grady",
    ActionOfficer: "Patricia Heidenreich",
    Coordinator: "Mayer - Leffler",
    DateWork: "Thursday",
    FileNumber: "maryland_coves_cambridgeshire.fh4",
    EnquiryRefNo: 41275,
    Event_ReferenceNoAdditional: 53338,
    OnBehalfOf: 50586,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Mon Jun 01 2020 22:18:02 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 14:52:39 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 13:16:52 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 96462,
    CreatedDate: "Wed Mar 25 2020 23:16:05 GMT+0700 (Indochina Time)",
    FeeType: "Developer",
    AmountIssued: 127.0,
    AmountOutstanding: 659.0,
    CostCode: "1PNVRD287OLHUWU5F0Z2VM1KGN2HQ980",
    InvoiceNumber: 36813,
    DebtorNumber: 48606,
    Name: "Zena",
    Description:
      "Labore unde quaerat nostrum. Voluptatibus doloremque et ut et et non. Voluptatem excepturi perferendis aut cupiditate. Molestiae omnis voluptates voluptatem in pariatur. Officia et ut.",
    SortIndex: 29493,
    Title: "turn_key.wmlsc",
  },
  {
    ID: 90739,
    PermitNumber: 67080,
    Applicant: 40518,
    SiteAddress: "Apt. 764",
    Locality: "Minnesota",
    Type: "Representative",
    StatusDueStatus: "Architect",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Melisa Prosacco",
    EffectiveFrom: "Sun Dec 29 2019 07:56:12 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 19:51:25 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Agnes Wuckert",
    Area: "Washington",
    DateInspected: "Tue Dec 01 2020 10:56:59 GMT+0700 (Indochina Time)",
    ReferenceNumber: 92106,
    ContactName: "Nathanael Labadie",
    InspectionType: "Architect",
    Compliance:
      "You can't compress the monitor without bypassing the bluetooth XML driver!",
    Reason:
      "You can't compress the program without bypassing the redundant EXE array!",
    InsurancePolicyNumber: 82204,
    InsuranceCompany: "D'Amore LLC",
    AmountOS: 977.0,
    DateRecorded: "Sat Oct 03 2020 10:51:30 GMT+0700 (Indochina Time)",
    RecordedBy: "Tessie Mraz",
    OrgStructure: "Torphy, Rice and Erdman",
    ActionOfficer: "Adriana Farrell",
    Coordinator: "Champlin and Sons",
    DateWork: "Wednesday",
    FileNumber: "norfolk_island.aep",
    EnquiryRefNo: 79699,
    Event_ReferenceNoAdditional: 76175,
    OnBehalfOf: 7127,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Fri May 29 2020 11:01:34 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 10:06:59 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 18:58:33 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 62190,
    CreatedDate: "Mon Sep 28 2020 16:15:32 GMT+0700 (Indochina Time)",
    FeeType: "Agent",
    AmountIssued: 2.0,
    AmountOutstanding: 789.0,
    CostCode: "3NL20XBRR8VAOH8MTMBHU1FPCW40EPH",
    InvoiceNumber: 11216,
    DebtorNumber: 12377,
    Name: "Susie",
    Description: "earum fugiat odit",
    SortIndex: 79696,
    Title: "capacitor_enhance.vsw",
  },
  {
    ID: 76349,
    PermitNumber: 55775,
    Applicant: 96523,
    SiteAddress: "Suite 542",
    Locality: "Maryland",
    Type: "Specialist",
    StatusDueStatus: "Consultant",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Rowena Boehm",
    EffectiveFrom: "Mon Jul 06 2020 17:47:20 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 22:38:35 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Willow Feest",
    Area: "Wisconsin",
    DateInspected: "Thu Sep 03 2020 19:22:11 GMT+0700 (Indochina Time)",
    ReferenceNumber: 41372,
    ContactName: "Duane Daugherty",
    InspectionType: "Technician",
    Compliance:
      "If we transmit the panel, we can get to the USB sensor through the bluetooth TCP circuit!",
    Reason:
      "Try to reboot the SCSI panel, maybe it will input the mobile program!",
    InsurancePolicyNumber: 49799,
    InsuranceCompany: "Morar - Witting",
    AmountOS: 191.0,
    DateRecorded: "Wed Jan 29 2020 12:30:10 GMT+0700 (Indochina Time)",
    RecordedBy: "Cecile Parisian",
    OrgStructure: "Denesik, MacGyver and Morissette",
    ActionOfficer: "Anne Thompson",
    Coordinator: "Harris and Sons",
    DateWork: "Monday",
    FileNumber: "enhance.azs",
    EnquiryRefNo: 67269,
    Event_ReferenceNoAdditional: 22757,
    OnBehalfOf: 97446,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Tue Oct 20 2020 11:14:46 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 06:47:26 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 15:14:33 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 72918,
    CreatedDate: "Thu Nov 05 2020 09:29:09 GMT+0700 (Indochina Time)",
    FeeType: "Facilitator",
    AmountIssued: 51.0,
    AmountOutstanding: 167.0,
    CostCode: "1TK1X5VR17JGLJQ3QUNS8I7CVJHK",
    InvoiceNumber: 84611,
    DebtorNumber: 634,
    Name: "Jedidiah",
    Description: "Laudantium sunt eos minima quia autem.",
    SortIndex: 62832,
    Title: "website_ethiopia.xfdf",
  },
  {
    ID: 66941,
    PermitNumber: 93744,
    Applicant: 48522,
    SiteAddress: "Suite 022",
    Locality: "Texas",
    Type: "Planner",
    StatusDueStatus: "Specialist",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Russel Marvin",
    EffectiveFrom: "Fri Dec 18 2020 15:02:48 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 21:05:50 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Daren Dickens",
    Area: "New York",
    DateInspected: "Sat Jun 20 2020 01:01:19 GMT+0700 (Indochina Time)",
    ReferenceNumber: 51578,
    ContactName: "Susan Thompson",
    InspectionType: "Officer",
    Compliance:
      "You can't program the bandwidth without indexing the virtual FTP card!",
    Reason:
      "I'll index the redundant SAS port, that should protocol the HTTP bus!",
    InsurancePolicyNumber: 46503,
    InsuranceCompany: "Daugherty, Gutkowski and Abbott",
    AmountOS: 889.0,
    DateRecorded: "Sun May 17 2020 11:13:07 GMT+0700 (Indochina Time)",
    RecordedBy: "Russ Adams",
    OrgStructure: "Jacobi, Kohler and Barton",
    ActionOfficer: "Joel Nicolas",
    Coordinator: "Koss LLC",
    DateWork: "Friday",
    FileNumber: "multi_lateral_auto_loan_account_users.svc",
    EnquiryRefNo: 95081,
    Event_ReferenceNoAdditional: 51510,
    OnBehalfOf: 18141,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Tue Sep 29 2020 16:35:01 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 04:31:31 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 10:56:22 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 32608,
    CreatedDate: "Fri Apr 24 2020 05:53:58 GMT+0700 (Indochina Time)",
    FeeType: "Analyst",
    AmountIssued: 379.0,
    AmountOutstanding: 224.0,
    CostCode: "191VOFCRWEBR8BN5VJINEWAGOC4",
    InvoiceNumber: 22122,
    DebtorNumber: 17368,
    Name: "Lazaro",
    Description: "consequatur voluptatibus eum",
    SortIndex: 28226,
    Title: "monetize_models_white.lzh",
  },
  {
    ID: 9798,
    PermitNumber: 10090,
    Applicant: 97688,
    SiteAddress: "Apt. 802",
    Locality: "Alabama",
    Type: "Analyst",
    StatusDueStatus: "Consultant",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Kiana Green",
    EffectiveFrom: "Thu Oct 08 2020 11:51:30 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 05:22:02 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Izaiah Bernier",
    Area: "Kentucky",
    DateInspected: "Fri Jan 03 2020 16:52:59 GMT+0700 (Indochina Time)",
    ReferenceNumber: 85864,
    ContactName: "Jovanny Glover",
    InspectionType: "Representative",
    Compliance:
      "I'll generate the optical FTP bandwidth, that should capacitor the GB circuit!",
    Reason: "We need to index the optical AGP hard drive!",
    InsurancePolicyNumber: 66622,
    InsuranceCompany: "Denesik - Weimann",
    AmountOS: 330.0,
    DateRecorded: "Sat Jun 13 2020 22:21:59 GMT+0700 (Indochina Time)",
    RecordedBy: "Fredy Wiza",
    OrgStructure: "Morar, Rohan and Ferry",
    ActionOfficer: "Royce Bradtke",
    Coordinator: "Turcotte Inc",
    DateWork: "Friday",
    FileNumber: "best_of_breed_incentivize.p7s",
    EnquiryRefNo: 86875,
    Event_ReferenceNoAdditional: 34604,
    OnBehalfOf: 47015,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Thu Oct 01 2020 15:43:46 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 07:57:28 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 08:46:14 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 77606,
    CreatedDate: "Tue Jun 16 2020 21:36:22 GMT+0700 (Indochina Time)",
    FeeType: "Strategist",
    AmountIssued: 581.0,
    AmountOutstanding: 503.0,
    CostCode: "16T8RGPSQUVBVQE8X1XQ24L7B0G0K",
    InvoiceNumber: 94932,
    DebtorNumber: 74997,
    Name: "Audreanne",
    Description:
      "Quis est amet.\nFuga molestiae deleniti velit nulla deserunt magni explicabo.\nUllam neque doloremque quo dolores veniam et dolorem et.",
    SortIndex: 54342,
    Title: "workforce_1080p_intelligent_frozen_cheese.qbo",
  },
  {
    ID: 98443,
    PermitNumber: 30405,
    Applicant: 13839,
    SiteAddress: "Apt. 936",
    Locality: "North Dakota",
    Type: "Officer",
    StatusDueStatus: "Strategist",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Christophe Fay",
    EffectiveFrom: "Wed Jun 24 2020 06:44:07 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 18:19:46 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Helmer Walter",
    Area: "Louisiana",
    DateInspected: "Mon Nov 30 2020 16:04:51 GMT+0700 (Indochina Time)",
    ReferenceNumber: 39093,
    ContactName: "Florencio Borer",
    InspectionType: "Representative",
    Compliance:
      "The HTTP circuit is down, compress the multi-byte system so we can parse the HDD system!",
    Reason:
      "The RAM application is down, compress the primary port so we can reboot the SMS pixel!",
    InsurancePolicyNumber: 59648,
    InsuranceCompany: "Kilback Group",
    AmountOS: 95.0,
    DateRecorded: "Wed May 06 2020 22:46:44 GMT+0700 (Indochina Time)",
    RecordedBy: "Lillian Lakin",
    OrgStructure: "Borer - Jones",
    ActionOfficer: "Jordane Harber",
    Coordinator: "Nienow, Brakus and VonRueden",
    DateWork: "Friday",
    FileNumber: "small_cambridgeshire_georgia.uvv",
    EnquiryRefNo: 19736,
    Event_ReferenceNoAdditional: 2587,
    OnBehalfOf: 45831,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Wed Aug 19 2020 00:23:36 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 08:56:01 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 05:06:40 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 71850,
    CreatedDate: "Wed Apr 08 2020 08:21:02 GMT+0700 (Indochina Time)",
    FeeType: "Coordinator",
    AmountIssued: 108.0,
    AmountOutstanding: 946.0,
    CostCode: "30WHEEDAV8378878FHHDFQGA6XOSA5",
    InvoiceNumber: 21015,
    DebtorNumber: 53556,
    Name: "Ashlee",
    Description:
      "Ut quia illum et magnam adipisci. Cupiditate quae beatae nihil molestias repellat officiis amet itaque. Quia est dicta adipisci reprehenderit sint. Occaecati voluptas voluptatem quo maxime et aspernatur. Voluptas qui voluptate. Nulla qui provident consequatur.",
    SortIndex: 97583,
    Title: "home_instruction_set.cla",
  },
  {
    ID: 27933,
    PermitNumber: 33575,
    Applicant: 9792,
    SiteAddress: "Suite 515",
    Locality: "Utah",
    Type: "Facilitator",
    StatusDueStatus: "Coordinator",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Mireille Sawayn",
    EffectiveFrom: "Thu Sep 17 2020 02:58:03 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 13:00:56 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Asa Klein",
    Area: "Arkansas",
    DateInspected: "Fri Mar 06 2020 11:50:28 GMT+0700 (Indochina Time)",
    ReferenceNumber: 84445,
    ContactName: "Imani Collier",
    InspectionType: "Liason",
    Compliance:
      "The HTTP application is down, back up the auxiliary program so we can connect the TCP hard drive!",
    Reason:
      "I'll bypass the cross-platform PCI port, that should matrix the RSS array!",
    InsurancePolicyNumber: 70193,
    InsuranceCompany: "Davis - Kling",
    AmountOS: 995.0,
    DateRecorded: "Mon Jul 13 2020 04:39:13 GMT+0700 (Indochina Time)",
    RecordedBy: "Newell Hermann",
    OrgStructure: "Jerde - Stanton",
    ActionOfficer: "Zachariah Kohler",
    Coordinator: "Bode - Sawayn",
    DateWork: "Thursday",
    FileNumber: "virtual.scss",
    EnquiryRefNo: 18334,
    Event_ReferenceNoAdditional: 94249,
    OnBehalfOf: 42970,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Wed Jan 22 2020 03:20:14 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 11:00:41 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 10:35:13 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 83432,
    CreatedDate: "Sun Jul 05 2020 05:13:14 GMT+0700 (Indochina Time)",
    FeeType: "Architect",
    AmountIssued: 973.0,
    AmountOutstanding: 284.0,
    CostCode: "1LCTNGN9CE966PS5HPQJ2IKBQWA0CGSZ",
    InvoiceNumber: 83761,
    DebtorNumber: 41031,
    Name: "Randall",
    Description:
      "Velit neque quo magnam deleniti ipsa magni ad hic. Recusandae officia illum quas nihil. Est modi in. Eveniet non qui est ut et aut. Ea quos incidunt voluptatem est autem a. Molestias unde voluptate eum corrupti rem delectus.",
    SortIndex: 90215,
    Title: "vista.wpd",
  },
  {
    ID: 47782,
    PermitNumber: 92509,
    Applicant: 42735,
    SiteAddress: "Suite 247",
    Locality: "Georgia",
    Type: "Manager",
    StatusDueStatus: "Manager",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Solon Hintz",
    EffectiveFrom: "Thu Jul 09 2020 23:02:17 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 06:42:50 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Elise Parker",
    Area: "Missouri",
    DateInspected: "Wed May 27 2020 03:12:05 GMT+0700 (Indochina Time)",
    ReferenceNumber: 76236,
    ContactName: "Kailee Rempel",
    InspectionType: "Specialist",
    Compliance:
      "generating the system won't do anything, we need to calculate the haptic EXE sensor!",
    Reason:
      "connecting the microchip won't do anything, we need to bypass the optical XML hard drive!",
    InsurancePolicyNumber: 98116,
    InsuranceCompany: "Barton, Gutkowski and Goodwin",
    AmountOS: 989.0,
    DateRecorded: "Sat Feb 22 2020 08:10:08 GMT+0700 (Indochina Time)",
    RecordedBy: "Carlos McGlynn",
    OrgStructure: "Swift, Denesik and Kshlerin",
    ActionOfficer: "Macey Stehr",
    Coordinator: "Weimann Group",
    DateWork: "Friday",
    FileNumber: "electronics_data_pricing_structure.crt",
    EnquiryRefNo: 36036,
    Event_ReferenceNoAdditional: 10585,
    OnBehalfOf: 70505,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Fri Jul 24 2020 21:25:23 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 18:25:06 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 06:45:36 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 91156,
    CreatedDate: "Sun May 10 2020 06:54:12 GMT+0700 (Indochina Time)",
    FeeType: "Orchestrator",
    AmountIssued: 931.0,
    AmountOutstanding: 157.0,
    CostCode: "163PNLJZ2I4FB4XX8M8BIS2E6GQGZHPI",
    InvoiceNumber: 49744,
    DebtorNumber: 79943,
    Name: "Adrain",
    Description: "distinctio",
    SortIndex: 49058,
    Title: "dynamic.wpd",
  },
  {
    ID: 92304,
    PermitNumber: 67968,
    Applicant: 99849,
    SiteAddress: "Suite 177",
    Locality: "Maryland",
    Type: "Developer",
    StatusDueStatus: "Technician",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Brennon Gleason",
    EffectiveFrom: "Wed Jun 03 2020 18:32:11 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 22:25:59 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Eleazar Batz",
    Area: "Arizona",
    DateInspected: "Tue Nov 17 2020 11:04:31 GMT+0700 (Indochina Time)",
    ReferenceNumber: 32592,
    ContactName: "Keira Daniel",
    InspectionType: "Director",
    Compliance: "We need to navigate the auxiliary FTP system!",
    Reason:
      "I'll compress the virtual RSS array, that should panel the TCP bus!",
    InsurancePolicyNumber: 30485,
    InsuranceCompany: "Doyle - Olson",
    AmountOS: 825.0,
    DateRecorded: "Thu Feb 06 2020 06:05:16 GMT+0700 (Indochina Time)",
    RecordedBy: "Solon Emard",
    OrgStructure: "Lemke, Hudson and Gottlieb",
    ActionOfficer: "Kiley Padberg",
    Coordinator: "Runolfsson - Effertz",
    DateWork: "Thursday",
    FileNumber: "district_wireless.listafp",
    EnquiryRefNo: 25133,
    Event_ReferenceNoAdditional: 25935,
    OnBehalfOf: 9124,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sat Dec 05 2020 11:04:14 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 22:39:11 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 19:54:55 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 85228,
    CreatedDate: "Sun Sep 06 2020 11:16:39 GMT+0700 (Indochina Time)",
    FeeType: "Manager",
    AmountIssued: 328.0,
    AmountOutstanding: 398.0,
    CostCode: "1XOM737BGQI99K35EB6FRBFW761HA",
    InvoiceNumber: 97460,
    DebtorNumber: 74707,
    Name: "Sydni",
    Description:
      "Rerum necessitatibus laborum consectetur expedita voluptatibus optio iusto sequi alias.",
    SortIndex: 47773,
    Title: "parsing_egypt.fhc",
  },
  {
    ID: 69007,
    PermitNumber: 60591,
    Applicant: 74827,
    SiteAddress: "Suite 204",
    Locality: "Illinois",
    Type: "Designer",
    StatusDueStatus: "Director",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Micheal Stanton",
    EffectiveFrom: "Fri Nov 20 2020 13:51:47 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 17:15:46 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Danyka Harvey",
    Area: "Florida",
    DateInspected: "Tue May 19 2020 19:28:50 GMT+0700 (Indochina Time)",
    ReferenceNumber: 16686,
    ContactName: "Zula Leannon",
    InspectionType: "Technician",
    Compliance:
      "You can't program the interface without compressing the cross-platform JSON monitor!",
    Reason:
      "If we reboot the firewall, we can get to the SQL port through the auxiliary TCP array!",
    InsurancePolicyNumber: 38526,
    InsuranceCompany: "Daniel, Mohr and Gerlach",
    AmountOS: 621.0,
    DateRecorded: "Tue May 12 2020 14:16:26 GMT+0700 (Indochina Time)",
    RecordedBy: "Alanna Morissette",
    OrgStructure: "Russel, Keeling and Kub",
    ActionOfficer: "Vernon Hamill",
    Coordinator: "Schaden, Dickinson and Boyle",
    DateWork: "Saturday",
    FileNumber: "shoes_sports.lzh",
    EnquiryRefNo: 85939,
    Event_ReferenceNoAdditional: 76672,
    OnBehalfOf: 24165,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Mon Aug 03 2020 23:30:09 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 05:44:05 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 21:54:52 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 43339,
    CreatedDate: "Sat Sep 19 2020 11:45:11 GMT+0700 (Indochina Time)",
    FeeType: "Director",
    AmountIssued: 899.0,
    AmountOutstanding: 845.0,
    CostCode: "3T13MS4MM5Y3OV41H1WKBEOER6Z638H45Q",
    InvoiceNumber: 5276,
    DebtorNumber: 61886,
    Name: "Elody",
    Description:
      "Quaerat doloremque non suscipit vel accusamus maxime sint deleniti. Optio sapiente eos delectus deserunt. Ratione illum quas similique. Repellendus sint est impedit cum ea tenetur praesentium. Ut consectetur suscipit commodi blanditiis. Magni ut corrupti dicta ut nostrum officia.",
    SortIndex: 84903,
    Title: "bedfordshire_pci_bluetooth.jade",
  },
  {
    ID: 14437,
    PermitNumber: 98954,
    Applicant: 53256,
    SiteAddress: "Suite 875",
    Locality: "Idaho",
    Type: "Agent",
    StatusDueStatus: "Analyst",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Roxane Schoen",
    EffectiveFrom: "Fri Apr 10 2020 11:05:25 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 15:12:41 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Phoebe Brakus",
    Area: "Nevada",
    DateInspected: "Sun Jan 12 2020 12:06:58 GMT+0700 (Indochina Time)",
    ReferenceNumber: 98855,
    ContactName: "Kathryne Green",
    InspectionType: "Designer",
    Compliance:
      "I'll connect the redundant JBOD sensor, that should feed the THX monitor!",
    Reason: "We need to copy the bluetooth SQL bus!",
    InsurancePolicyNumber: 22950,
    InsuranceCompany: "Zieme Group",
    AmountOS: 841.0,
    DateRecorded: "Sun Apr 19 2020 13:37:36 GMT+0700 (Indochina Time)",
    RecordedBy: "Samanta Ernser",
    OrgStructure: "Turcotte - Wunsch",
    ActionOfficer: "Margarett Schulist",
    Coordinator: "Smith LLC",
    DateWork: "Sunday",
    FileNumber: "olive_cutting_edge.xpr",
    EnquiryRefNo: 84610,
    Event_ReferenceNoAdditional: 20988,
    OnBehalfOf: 20402,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Thu Aug 27 2020 19:41:24 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 08:40:29 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 21:12:29 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 91363,
    CreatedDate: "Sun Jul 19 2020 08:34:13 GMT+0700 (Indochina Time)",
    FeeType: "Architect",
    AmountIssued: 141.0,
    AmountOutstanding: 143.0,
    CostCode: "1Y0T34I9ZTYA9QT1HT7ITIL785QTWE6R9",
    InvoiceNumber: 73282,
    DebtorNumber: 45846,
    Name: "Lessie",
    Description:
      "Et illo fugit et. Est voluptatum molestiae recusandae voluptatem sunt accusantium earum et. Numquam soluta illo in eaque eius ullam dolorem suscipit.",
    SortIndex: 92901,
    Title: "operative_gb.wax",
  },
  {
    ID: 66206,
    PermitNumber: 10891,
    Applicant: 869,
    SiteAddress: "Apt. 507",
    Locality: "Georgia",
    Type: "Planner",
    StatusDueStatus: "Associate",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Winston Abshire",
    EffectiveFrom: "Thu Aug 06 2020 03:46:07 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 22:59:09 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Elvera Lemke",
    Area: "West Virginia",
    DateInspected: "Mon Nov 16 2020 06:11:52 GMT+0700 (Indochina Time)",
    ReferenceNumber: 49946,
    ContactName: "Laurence Heidenreich",
    InspectionType: "Producer",
    Compliance:
      "You can't back up the hard drive without generating the optical THX firewall!",
    Reason:
      "Use the open-source SCSI alarm, then you can connect the open-source monitor!",
    InsurancePolicyNumber: 88537,
    InsuranceCompany: "Okuneva, Orn and Kulas",
    AmountOS: 958.0,
    DateRecorded: "Sun Jan 19 2020 04:15:17 GMT+0700 (Indochina Time)",
    RecordedBy: "Verna Kuphal",
    OrgStructure: "Gleason, Kirlin and Ruecker",
    ActionOfficer: "Elijah Orn",
    Coordinator: "Okuneva, Koelpin and Lebsack",
    DateWork: "Saturday",
    FileNumber: "copying_bedfordshire.fcs",
    EnquiryRefNo: 38800,
    Event_ReferenceNoAdditional: 94181,
    OnBehalfOf: 72086,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Fri May 15 2020 11:47:58 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 13:50:48 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 11:05:09 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 2057,
    CreatedDate: "Wed Aug 12 2020 12:23:16 GMT+0700 (Indochina Time)",
    FeeType: "Technician",
    AmountIssued: 456.0,
    AmountOutstanding: 314.0,
    CostCode: "3F7WNWUYNO4DI6V3XR1NYZW7AYQ5Y",
    InvoiceNumber: 58599,
    DebtorNumber: 42664,
    Name: "Quinton",
    Description:
      "Id modi tempore sed vitae omnis est.\nLabore voluptatem non in debitis.\nFacilis illo voluptatem alias sed quasi.",
    SortIndex: 35809,
    Title: "licensed_toys_uic_franc.z3",
  },
  {
    ID: 1025,
    PermitNumber: 28833,
    Applicant: 53790,
    SiteAddress: "Suite 762",
    Locality: "Vermont",
    Type: "Technician",
    StatusDueStatus: "Consultant",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Lonnie Bins",
    EffectiveFrom: "Tue Feb 25 2020 02:14:41 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 03:22:55 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Adalberto Schuster",
    Area: "Oregon",
    DateInspected: "Sat Oct 17 2020 12:06:05 GMT+0700 (Indochina Time)",
    ReferenceNumber: 73668,
    ContactName: "Turner Considine",
    InspectionType: "Orchestrator",
    Compliance:
      "Try to quantify the SQL panel, maybe it will navigate the wireless monitor!",
    Reason:
      "parsing the alarm won't do anything, we need to navigate the digital HDD circuit!",
    InsurancePolicyNumber: 98194,
    InsuranceCompany: "Nader Group",
    AmountOS: 397.0,
    DateRecorded: "Wed Aug 05 2020 22:11:28 GMT+0700 (Indochina Time)",
    RecordedBy: "Vesta Smitham",
    OrgStructure: "Wunsch, Koch and Feeney",
    ActionOfficer: "Dallas Dare",
    Coordinator: "Kling, Gerlach and Auer",
    DateWork: "Friday",
    FileNumber: "cheese_ergonomic_redefine.sxc",
    EnquiryRefNo: 35576,
    Event_ReferenceNoAdditional: 8140,
    OnBehalfOf: 28284,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Thu Aug 27 2020 16:57:24 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 16:31:37 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 23:20:47 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 52395,
    CreatedDate: "Fri Aug 28 2020 13:33:55 GMT+0700 (Indochina Time)",
    FeeType: "Director",
    AmountIssued: 83.0,
    AmountOutstanding: 237.0,
    CostCode: "1O7Z3QH931BEB740PXO1A37XQY82E48EAJ",
    InvoiceNumber: 79406,
    DebtorNumber: 89679,
    Name: "Nico",
    Description: "excepturi saepe consequuntur",
    SortIndex: 53667,
    Title: "alarm_orange_capacitor.jsonld",
  },
  {
    ID: 51986,
    PermitNumber: 44548,
    Applicant: 86173,
    SiteAddress: "Apt. 909",
    Locality: "Kentucky",
    Type: "Strategist",
    StatusDueStatus: "Facilitator",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Silas Larson",
    EffectiveFrom: "Sat Sep 12 2020 10:09:03 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 05:37:30 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Berniece O'Kon",
    Area: "Indiana",
    DateInspected: "Mon Mar 23 2020 16:26:52 GMT+0700 (Indochina Time)",
    ReferenceNumber: 49544,
    ContactName: "Lulu Bernier",
    InspectionType: "Liason",
    Compliance:
      "The FTP driver is down, index the digital sensor so we can hack the PCI interface!",
    Reason:
      "Use the wireless ADP hard drive, then you can parse the virtual bus!",
    InsurancePolicyNumber: 17233,
    InsuranceCompany: "Kutch - Kessler",
    AmountOS: 812.0,
    DateRecorded: "Tue Oct 27 2020 05:41:25 GMT+0700 (Indochina Time)",
    RecordedBy: "Reid Jacobs",
    OrgStructure: "Stiedemann - Bins",
    ActionOfficer: "Alvera Parisian",
    Coordinator: "Kling - Jones",
    DateWork: "Friday",
    FileNumber: "invoice_united_states_of_america_money_market_account.curl",
    EnquiryRefNo: 18225,
    Event_ReferenceNoAdditional: 58516,
    OnBehalfOf: 18080,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Wed Feb 19 2020 20:44:29 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 01:17:29 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 08:56:48 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 46592,
    CreatedDate: "Tue Sep 01 2020 00:15:36 GMT+0700 (Indochina Time)",
    FeeType: "Consultant",
    AmountIssued: 502.0,
    AmountOutstanding: 89.0,
    CostCode: "1VPXCXO3KA1136F6VBHK6X6UIF7",
    InvoiceNumber: 1318,
    DebtorNumber: 23569,
    Name: "Chaya",
    Description:
      "Aut facilis dolorem accusantium corporis voluptatum fuga assumenda pariatur. Id modi nobis ea voluptatem dolorem deserunt vitae voluptatem. Esse sed corrupti at. Recusandae iure omnis natus.",
    SortIndex: 87883,
    Title: "incredible_metal_gloves_music_withdrawal.xlsm",
  },
  {
    ID: 32305,
    PermitNumber: 50129,
    Applicant: 72570,
    SiteAddress: "Suite 224",
    Locality: "Idaho",
    Type: "Engineer",
    StatusDueStatus: "Analyst",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Treva Champlin",
    EffectiveFrom: "Fri Sep 11 2020 05:20:18 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 00:15:18 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Kiana Rogahn",
    Area: "Delaware",
    DateInspected: "Wed Sep 23 2020 06:13:33 GMT+0700 (Indochina Time)",
    ReferenceNumber: 91597,
    ContactName: "Cicero Witting",
    InspectionType: "Agent",
    Compliance:
      "You can't copy the matrix without indexing the haptic CSS pixel!",
    Reason:
      "I'll index the primary HTTP sensor, that should bandwidth the SAS sensor!",
    InsurancePolicyNumber: 91803,
    InsuranceCompany: "Jaskolski LLC",
    AmountOS: 264.0,
    DateRecorded: "Wed Apr 15 2020 14:41:22 GMT+0700 (Indochina Time)",
    RecordedBy: "Catalina Reilly",
    OrgStructure: "Emard and Sons",
    ActionOfficer: "Brian Mann",
    Coordinator: "Gerhold Group",
    DateWork: "Saturday",
    FileNumber:
      "mexican_peso_mexican_unidad_de_inversion_(udi)_scsi_administrator.rp9",
    EnquiryRefNo: 75332,
    Event_ReferenceNoAdditional: 63995,
    OnBehalfOf: 37212,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Thu Jul 23 2020 04:57:21 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 07:23:47 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 21:25:58 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 42326,
    CreatedDate: "Wed Jan 22 2020 04:06:59 GMT+0700 (Indochina Time)",
    FeeType: "Administrator",
    AmountIssued: 323.0,
    AmountOutstanding: 642.0,
    CostCode: "1WUNYPKFRKAEPAA8Z6FFZBM12SF8FC",
    InvoiceNumber: 99999,
    DebtorNumber: 97518,
    Name: "Alec",
    Description:
      "Quos at non. Vel facere unde quaerat accusantium nesciunt. Aut velit sint ut odit.",
    SortIndex: 13310,
    Title: "faroe_islands_unbranded_alaska.uvz",
  },
  {
    ID: 73646,
    PermitNumber: 1392,
    Applicant: 85903,
    SiteAddress: "Apt. 561",
    Locality: "Oregon",
    Type: "Strategist",
    StatusDueStatus: "Analyst",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Andrew Zulauf",
    EffectiveFrom: "Wed Aug 19 2020 03:41:31 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 04:58:54 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Clay Cole",
    Area: "Wyoming",
    DateInspected: "Tue Mar 17 2020 20:05:37 GMT+0700 (Indochina Time)",
    ReferenceNumber: 73187,
    ContactName: "Adam White",
    InspectionType: "Designer",
    Compliance:
      "The SMS protocol is down, generate the solid state firewall so we can connect the PNG pixel!",
    Reason:
      "You can't quantify the feed without navigating the redundant GB system!",
    InsurancePolicyNumber: 45043,
    InsuranceCompany: "Cormier, Hayes and Johns",
    AmountOS: 748.0,
    DateRecorded: "Wed Oct 07 2020 07:43:29 GMT+0700 (Indochina Time)",
    RecordedBy: "Alejandra Lind",
    OrgStructure: "Roberts - Ondricka",
    ActionOfficer: "Theodora Schuster",
    Coordinator: "Fadel - Christiansen",
    DateWork: "Monday",
    FileNumber: "inverse_backing_up_practical.mar",
    EnquiryRefNo: 84271,
    Event_ReferenceNoAdditional: 33763,
    OnBehalfOf: 81887,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Fri May 01 2020 16:42:27 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 13:14:18 GMT+0700 (Indochina Time)",
    DateServed: "Thu Dec 24 2020 19:31:19 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 92877,
    CreatedDate: "Thu Dec 24 2020 07:54:51 GMT+0700 (Indochina Time)",
    FeeType: "Associate",
    AmountIssued: 635.0,
    AmountOutstanding: 945.0,
    CostCode: "1PRC4QB52H0VON2NTXCFIP4B61I",
    InvoiceNumber: 42204,
    DebtorNumber: 14914,
    Name: "Bryon",
    Description:
      "Labore esse explicabo et ut at praesentium omnis. Nemo fugit sunt. Sed aut qui laborum. Autem quos quia veniam et quidem libero. Consequatur sed molestiae ut voluptatum quo.\n \rAut est ut nulla. Perferendis sint fuga atque unde. Qui et ipsa a ut delectus at nesciunt laboriosam. Veritatis natus quia nesciunt.\n \rCumque iure impedit expedita optio error non. At sit dicta illum et corrupti qui eaque eum repudiandae. Ipsa sed earum modi. Ut neque blanditiis sapiente cumque magnam aliquid esse non. Dolorum in quis est iusto sit et veniam est eum.",
    SortIndex: 83893,
    Title: "alarm_sleek.xsd",
  },
  {
    ID: 52324,
    PermitNumber: 30796,
    Applicant: 60894,
    SiteAddress: "Apt. 730",
    Locality: "New Hampshire",
    Type: "Orchestrator",
    StatusDueStatus: "Designer",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Tristian Douglas",
    EffectiveFrom: "Wed Dec 02 2020 15:54:41 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 16:38:41 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Gwen Heaney",
    Area: "Maryland",
    DateInspected: "Sat Oct 03 2020 01:53:40 GMT+0700 (Indochina Time)",
    ReferenceNumber: 83852,
    ContactName: "Damian Jast",
    InspectionType: "Representative",
    Compliance:
      "The XML protocol is down, override the solid state port so we can navigate the ADP sensor!",
    Reason:
      "I'll connect the mobile AI matrix, that should matrix the USB pixel!",
    InsurancePolicyNumber: 96902,
    InsuranceCompany: "Prosacco and Sons",
    AmountOS: 344.0,
    DateRecorded: "Wed May 20 2020 21:51:49 GMT+0700 (Indochina Time)",
    RecordedBy: "Tyreek Gerlach",
    OrgStructure: "Pollich and Sons",
    ActionOfficer: "Chris Dach",
    Coordinator: "Huel - Koss",
    DateWork: "Friday",
    FileNumber: "indigo_http_lebanese_pound.gmx",
    EnquiryRefNo: 98724,
    Event_ReferenceNoAdditional: 10185,
    OnBehalfOf: 41987,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sat Jul 11 2020 09:42:26 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 19:21:05 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 07:48:26 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 36097,
    CreatedDate: "Sun Oct 25 2020 00:30:01 GMT+0700 (Indochina Time)",
    FeeType: "Supervisor",
    AmountIssued: 883.0,
    AmountOutstanding: 997.0,
    CostCode: "1ZVYNIKDVWWEOQEIL3S08QQOYFM442R",
    InvoiceNumber: 23675,
    DebtorNumber: 5925,
    Name: "Travis",
    Description: "Reiciendis voluptas corporis distinctio maiores.",
    SortIndex: 30716,
    Title: "payment.gif",
  },
  {
    ID: 31083,
    PermitNumber: 19080,
    Applicant: 4313,
    SiteAddress: "Suite 081",
    Locality: "Kentucky",
    Type: "Officer",
    StatusDueStatus: "Manager",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Fatima Cummings",
    EffectiveFrom: "Sun Mar 01 2020 20:06:24 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 05:43:52 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Salma Lakin",
    Area: "Nevada",
    DateInspected: "Tue Jan 21 2020 02:56:40 GMT+0700 (Indochina Time)",
    ReferenceNumber: 94116,
    ContactName: "Easton O'Keefe",
    InspectionType: "Supervisor",
    Compliance:
      "You can't compress the system without hacking the wireless SMTP port!",
    Reason: "Use the mobile JSON card, then you can back up the 1080p sensor!",
    InsurancePolicyNumber: 51630,
    InsuranceCompany: "O'Conner Inc",
    AmountOS: 97.0,
    DateRecorded: "Thu Jun 11 2020 13:55:27 GMT+0700 (Indochina Time)",
    RecordedBy: "Forest MacGyver",
    OrgStructure: "Rippin - Lindgren",
    ActionOfficer: "Elizabeth Strosin",
    Coordinator: "Kihn, Oberbrunner and Kutch",
    DateWork: "Tuesday",
    FileNumber: "savings_account_specialist.atx",
    EnquiryRefNo: 48168,
    Event_ReferenceNoAdditional: 75667,
    OnBehalfOf: 54050,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sun Apr 05 2020 23:19:52 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 22:49:46 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 09:32:51 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 59323,
    CreatedDate: "Wed Dec 09 2020 00:59:58 GMT+0700 (Indochina Time)",
    FeeType: "Facilitator",
    AmountIssued: 513.0,
    AmountOutstanding: 619.0,
    CostCode: "36R8C2NENNWWHVGE1PKB8AD9M4M5X78YY",
    InvoiceNumber: 88755,
    DebtorNumber: 30626,
    Name: "Brennan",
    Description:
      "Illo eveniet quia fugiat velit eum deleniti sunt veniam. Odit sit sed. Id maiores mollitia doloribus dolor et sunt dolorem. Non rem repellat. Enim inventore enim.\n \rUnde non ipsa et aut labore. Sed provident dolores distinctio qui totam dolorem et quae. Tempora consequuntur molestiae quisquam quam natus nulla sed. Illo voluptas impedit quos cupiditate delectus et nostrum sunt ducimus. Sapiente aliquam autem. Consequatur eaque magnam rerum.\n \rEt aut aut nihil fugiat ut sapiente reiciendis. Aut non delectus accusamus est velit quasi ut placeat. Quia eaque sint. Et ipsam molestiae. Est eius dolores veritatis suscipit. Consequatur deleniti maxime quia asperiores quia natus iste et nihil.",
    SortIndex: 63649,
    Title: "incredible_oklahoma_withdrawal.pfa",
  },
  {
    ID: 41233,
    PermitNumber: 94053,
    Applicant: 82480,
    SiteAddress: "Suite 581",
    Locality: "Nebraska",
    Type: "Liason",
    StatusDueStatus: "Executive",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Eino Prosacco",
    EffectiveFrom: "Thu May 21 2020 18:03:27 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 18:55:59 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Joshua Eichmann",
    Area: "Indiana",
    DateInspected: "Mon Jan 06 2020 00:26:54 GMT+0700 (Indochina Time)",
    ReferenceNumber: 87133,
    ContactName: "Oliver Hoeger",
    InspectionType: "Producer",
    Compliance:
      "You can't compress the alarm without programming the 1080p USB pixel!",
    Reason:
      "The PCI protocol is down, override the open-source protocol so we can index the COM capacitor!",
    InsurancePolicyNumber: 91578,
    InsuranceCompany: "Jones, Witting and Metz",
    AmountOS: 119.0,
    DateRecorded: "Sun Feb 16 2020 04:40:09 GMT+0700 (Indochina Time)",
    RecordedBy: "Amanda Wuckert",
    OrgStructure: "Roob - Kemmer",
    ActionOfficer: "Iliana Kertzmann",
    Coordinator: "Smith LLC",
    DateWork: "Sunday",
    FileNumber: "savings_account_optimize_indiana.uvp",
    EnquiryRefNo: 13388,
    Event_ReferenceNoAdditional: 6465,
    OnBehalfOf: 68593,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sat Apr 04 2020 08:26:42 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 05:30:23 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 03:30:22 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 93004,
    CreatedDate: "Sat Dec 12 2020 12:04:17 GMT+0700 (Indochina Time)",
    FeeType: "Assistant",
    AmountIssued: 816.0,
    AmountOutstanding: 289.0,
    CostCode: "3293ZZOVM5NMIK4E4VCD5TMCE1B97OM5UB",
    InvoiceNumber: 89805,
    DebtorNumber: 31093,
    Name: "Jaylen",
    Description:
      "Est optio velit maiores fuga vel autem dolores est sit. Voluptates quia beatae. Necessitatibus eos soluta recusandae magnam tenetur. Laboriosam aut neque sit pariatur. Amet saepe non nam officia qui.\n \rMagnam error suscipit omnis sint consequatur quos nulla ullam cupiditate. Aliquam rerum excepturi doloremque mollitia non nulla. Voluptatem cum et sapiente sapiente et magnam et autem ex. Qui autem id dignissimos nihil vero molestias ipsam vel corrupti.\n \rOccaecati nulla nostrum vel aliquid et deleniti quod id dignissimos. Voluptatem et quidem nihil accusantium id optio repellat dolorum ab. Reiciendis laborum deleniti qui qui voluptas deleniti nemo cupiditate qui.",
    SortIndex: 87602,
    Title: "groupware_michigan_buckinghamshire.skd",
  },
  {
    ID: 71209,
    PermitNumber: 36133,
    Applicant: 46141,
    SiteAddress: "Apt. 535",
    Locality: "Kentucky",
    Type: "Facilitator",
    StatusDueStatus: "Strategist",
    InsuranceDueStatus: "Overdue By 3 Months",
    Officer: "Dewayne Cole",
    EffectiveFrom: "Wed Mar 18 2020 16:00:45 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 05:09:16 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Lemuel Russel",
    Area: "North Dakota",
    DateInspected: "Wed Aug 12 2020 14:53:42 GMT+0700 (Indochina Time)",
    ReferenceNumber: 32890,
    ContactName: "Woodrow Hartmann",
    InspectionType: "Consultant",
    Compliance:
      "You can't index the program without synthesizing the auxiliary ADP transmitter!",
    Reason:
      "The SDD array is down, connect the haptic protocol so we can copy the JSON capacitor!",
    InsurancePolicyNumber: 69878,
    InsuranceCompany: "Bogan - Altenwerth",
    AmountOS: 470.0,
    DateRecorded: "Sat Jan 04 2020 13:16:04 GMT+0700 (Indochina Time)",
    RecordedBy: "Lester Kiehn",
    OrgStructure: "Price LLC",
    ActionOfficer: "Rosina Kreiger",
    Coordinator: "Reilly, Monahan and Crona",
    DateWork: "Tuesday",
    FileNumber: "intelligent_rapids.tiff",
    EnquiryRefNo: 89224,
    Event_ReferenceNoAdditional: 76043,
    OnBehalfOf: 4554,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Mon Jun 15 2020 10:44:56 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 19:43:29 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 06:15:18 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 23883,
    CreatedDate: "Sun Jan 05 2020 00:50:43 GMT+0700 (Indochina Time)",
    FeeType: "Manager",
    AmountIssued: 205.0,
    AmountOutstanding: 409.0,
    CostCode: "14N0R4GBY7U3KWYWH2CWVXQM8Q8F",
    InvoiceNumber: 82139,
    DebtorNumber: 95428,
    Name: "Clara",
    Description:
      "Dignissimos explicabo optio ut dolor totam minima officia minima dolore. Est qui dicta ad molestiae et vitae suscipit recusandae eum. Harum ut eveniet voluptas beatae unde nobis et assumenda rem. Quas incidunt velit.\n \rNemo non ipsam in rerum rem quia nam eveniet. Non voluptatibus sit velit rerum. Enim nesciunt sed voluptatem qui impedit autem odit tenetur eius. Aliquam laudantium voluptates.\n \rSint deserunt facere sed laudantium blanditiis voluptate vitae at ut. Corrupti ad deserunt. Expedita magnam repudiandae doloribus et accusantium officiis alias nam et.",
    SortIndex: 98570,
    Title: "thx_liberia_guinea_bissau.fcs",
  },
  {
    ID: 34327,
    PermitNumber: 62306,
    Applicant: 24345,
    SiteAddress: "Apt. 282",
    Locality: "California",
    Type: "Orchestrator",
    StatusDueStatus: "Facilitator",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "Immanuel Kuhic",
    EffectiveFrom: "Wed Sep 16 2020 06:40:59 GMT+0700 (Indochina Time)",
    EffectiveTo: "Fri Dec 25 2020 02:08:22 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Colleen Wiegand",
    Area: "North Carolina",
    DateInspected: "Sat Nov 14 2020 07:32:45 GMT+0700 (Indochina Time)",
    ReferenceNumber: 42242,
    ContactName: "Trinity Lowe",
    InspectionType: "Consultant",
    Compliance:
      "I'll index the neural AGP transmitter, that should panel the SSL alarm!",
    Reason: "We need to back up the online RSS microchip!",
    InsurancePolicyNumber: 70426,
    InsuranceCompany: "Lind and Sons",
    AmountOS: 703.0,
    DateRecorded: "Sun Aug 16 2020 18:47:13 GMT+0700 (Indochina Time)",
    RecordedBy: "Yasmeen Heaney",
    OrgStructure: "Miller - Howell",
    ActionOfficer: "Payton Rolfson",
    Coordinator: "Goodwin Group",
    DateWork: "Sunday",
    FileNumber: "shoes_customer.umj",
    EnquiryRefNo: 33872,
    Event_ReferenceNoAdditional: 21428,
    OnBehalfOf: 55649,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Sat May 30 2020 16:08:30 GMT+0700 (Indochina Time)",
    DateRevoked: "Fri Dec 25 2020 03:21:23 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 08:13:59 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 64211,
    CreatedDate: "Fri Jan 31 2020 13:05:55 GMT+0700 (Indochina Time)",
    FeeType: "Strategist",
    AmountIssued: 583.0,
    AmountOutstanding: 590.0,
    CostCode: "3N3TA5RLKZT6AM9OX8WOHZP60SUR25",
    InvoiceNumber: 99831,
    DebtorNumber: 72159,
    Name: "Sydnie",
    Description: "et",
    SortIndex: 7097,
    Title: "outdoors_south_carolina_pixel.air",
  },
  {
    ID: 33744,
    PermitNumber: 86402,
    Applicant: 45441,
    SiteAddress: "Suite 164",
    Locality: "Alaska",
    Type: "Agent",
    StatusDueStatus: "Manager",
    InsuranceDueStatus: "Overdue By 2 Months",
    Officer: "George Schneider",
    EffectiveFrom: "Wed May 13 2020 14:22:43 GMT+0700 (Indochina Time)",
    EffectiveTo: "Thu Dec 24 2020 21:00:56 GMT+0700 (Indochina Time)",
    UsePermitEventName: "Zoila Howe",
    Area: "Idaho",
    DateInspected: "Sun Jun 21 2020 03:49:51 GMT+0700 (Indochina Time)",
    ReferenceNumber: 1440,
    ContactName: "Laurianne Jacobson",
    InspectionType: "Liason",
    Compliance:
      "You can't generate the hard drive without bypassing the primary USB array!",
    Reason:
      "I'll transmit the cross-platform EXE monitor, that should capacitor the SSL alarm!",
    InsurancePolicyNumber: 62882,
    InsuranceCompany: "Mosciski - Hoeger",
    AmountOS: 201.0,
    DateRecorded: "Tue Jan 07 2020 09:53:02 GMT+0700 (Indochina Time)",
    RecordedBy: "Kaitlin Cummerata",
    OrgStructure: "Funk, Reinger and McKenzie",
    ActionOfficer: "Karianne Lebsack",
    Coordinator: "Funk - Mante",
    DateWork: "Thursday",
    FileNumber: "object_oriented_dynamic_response.bz",
    EnquiryRefNo: 24849,
    Event_ReferenceNoAdditional: 26939,
    OnBehalfOf: 98861,
    Flag_ITSupport: true,
    Flag_Safety: true,
    Flag_Risk: true,
    Flag_VIP: true,
    DateDue: "Tue Mar 03 2020 17:41:29 GMT+0700 (Indochina Time)",
    DateRevoked: "Thu Dec 24 2020 23:44:28 GMT+0700 (Indochina Time)",
    DateServed: "Fri Dec 25 2020 00:35:35 GMT+0700 (Indochina Time)",
    CRMSEventReferenceNumber: 30482,
    CreatedDate: "Fri Sep 25 2020 00:48:37 GMT+0700 (Indochina Time)",
    FeeType: "Assistant",
    AmountIssued: 929.0,
    AmountOutstanding: 721.0,
    CostCode: "11UFS3E70NV522VQO68XIW6SRDL1ZD8H",
    InvoiceNumber: 32760,
    DebtorNumber: 35240,
    Name: "Roosevelt",
    Description: "Eos error exercitationem nihil beatae et hic illo illo sit.",
    SortIndex: 47390,
    Title: "computer.xvml",
  },
];

export const mockLocalLawsByID = {
  ID: 1,
  PermitNo: "C16622",
  PermitType: "Business-focused",
  AreaOfCouncil: "",
  PermitHolder: "Greyson Turcotte",
  Address: "71529 Bogan Inlet",
  PostalAddress: "Apt. 429",
  WorkPhone: "(147) 255-6449 x9951",
  HomePhone: "303.861.8356 x5924",
  PreferredContact: "Auto Loan Account",
  Mobile: "1-482-226-6264",
  Email: "macy.lind37@hotmail.com",
  SiteAddress: "fae.org",
  Owner: "Moen - Greenholt",
  Details: "Details 1",
  Description: "Avon Chicken New York",
  DateEffectiveFrom: "Wed Jan 13 2021 15:53:15",
  DateEffectiveTo: "Wed Jan 13 2021 15:53:15",
  Officer: "Ricky West V",
  Chairs: 4,
  Status: "Status 1",
  Tables: 4,
  InsuranceCompany: "Durgan - Gibson",
  PolicyNumber: 25206,
  PolicyIssueDate: "Wed Jan 13 2021 15:53:15",
  PolicyExpiresDate: "Wed Jan 13 2021 15:53:15",
  DebtorNumber: 64276,
  FileNumber: 14590,
  OSFees: 60008,
  Status_ENUM: "Active",
};
