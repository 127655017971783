import { getNoticeRunsById } from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/api";
import {
  DTO_NoticeOutputHistory,
  DTO_NoticeRunTotal,
  DTO_NoticeRun_Summary,
  listFieldTextArea,
} from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class NoticeRunsStore {
  private _noticeRuns?: DTO_NoticeRun_Summary = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get noticeRuns() {
    return toJS(this._noticeRuns);
  }
  setNoticeRuns = (noticeRuns?: DTO_NoticeRun_Summary) => {
    runInAction(() => {
      this._noticeRuns = noticeRuns;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get noticeRunsId() {
    return toJS(this.noticeRuns?.NoticeRunId);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._noticeRuns = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadNoticeRuns = async (
    noticeRunsId: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    const response = await getNoticeRunsById(noticeRunsId);
    let errorResponse = undefined;
    let newNoticeRuns: any = undefined;
    if (isSuccessResponse(response)) {
      newNoticeRuns = response.data;
      const noticeRunData: any = response.data;
      listFieldTextArea.forEach((item) => {
        const dataItem = noticeRunData?.[item];
        newNoticeRuns[`${item}Data`] = dataItem?.length
          ? dataItem.join("\r\n")
          : "";
      });
      if (newNoticeRuns?.NoticeRunTotal?.length) {
        newNoticeRuns?.NoticeRunTotal.forEach(
          (item: DTO_NoticeRunTotal, idx: number) => {
            item.ID = idx + 1;
          }
        );
      }
      let outputHistory = noticeRunData?.OutputHistory ?? [];
      outputHistory = outputHistory?.map(
        (item: DTO_NoticeOutputHistory, index: number) => ({
          ...item,
          ID: index + 1,
        })
      );
      newNoticeRuns.OutputHistory = outputHistory;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setNoticeRuns(newNoticeRuns);
    this.setIsLoading(false);
    if (notification) {
      appNotificationStore.pushNotification(notification);
    }
  };
}

const noticeRunsStoreContext = createContext(new NoticeRunsStore());
export const useNoticeRunsStore = () => {
  return useContext(noticeRunsStoreContext);
};
