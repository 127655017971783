import { DTO_Notice } from "@app/products/property/assessments/[id]/components/child-screens/notices/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Notice>();
export const colAssessmentNotices: IColumnFields[] = [
  {
    field: nameOf("Notice_Run_Id"),
    title: "Notice Run ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NR_Issue_Date"),
    title: "Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NR_Name"),
    title: "Notice Run",
  },
  {
    field: nameOf("NA_Arrears_Balance"),
    title: "Arrears Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Arrears_Interest_Balance"),
    title: "Arrears Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Current_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Total_Balance"),
    title: "Total Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Charges_Raised"),
    title: "Charges Raised",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Rebate_Amount"),
    title: "Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Amount_Due"),
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Discount_Amount_1"),
    title: "Discount Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NA_Discounted_Amount_Due_1"),
    title: "Discounted Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("IP_Name"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("DT_Name"),
    title: "Discount Type",
  },
  {
    field: nameOf("IP_Discount_Due_Date_1"),
    title: "Discount Due Date",
    format: DATE_FORMAT.DATE,
  },
  { field: nameOf("RP_Name"), title: "Rating Period" },
  {
    field: nameOf("Charge_Run_Id"),
    title: "Charge Run ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Notice_Name"),
    title: "Notice Addressee",
  },
  {
    field: nameOf("Notice_Type_Name"),
    title: "Notice Type",
  },
  {
    field: nameOf("PayingByDirectDebit"),
    title: "Paying By Direct Debit",
  },
  {
    field: nameOf("NR_Created_By_UserId"),
    title: "Notice Created By",
  },
  {
    field: nameOf("NR_Created_On_DateTime"),
    title: "Notice Created On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Notice_Id"),
    title: "Notice ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
