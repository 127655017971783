import { colRegisterSummaryAssociatedParcels } from "@app/products/property/registers/[id]/components/child-screens/general/components/form-element/components/associated-parcels/config";
import { IAssociationParcel } from "@app/products/property/registers/[id]/model";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IRegisterSummaryAssociatedParcelsProps {
  data: IAssociationParcel[];
}
const nameOf = nameOfFactory<IAssociationParcel>();
export const RegisterSummaryAssociatedParcels = ({
  data,
}: IRegisterSummaryAssociatedParcelsProps) => {
  const { setSelectedAssociateParcel, selectedAssociateParcel } =
    useRegisterStore();
  return (
    <div className="cc-register-associated-parcels">
      <CCGrid
        className="cc-register-associated-parcels-grid"
        data={data || []}
        columnFields={colRegisterSummaryAssociatedParcels}
        primaryField={nameOf("ParcelId")}
        selectableMode="multiple"
        onSelectionChange={(data: IAssociationParcel[]) =>
          setSelectedAssociateParcel(data)
        }
        selectedRows={selectedAssociateParcel}
      />
    </div>
  );
};
