import { VO_Comments } from "@app/core/comments/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Comments>();
export const colCommentWorkflow: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Title"),
    title: "Title",
  },
  {
    field: nameOf("Comment"),
    title: "Description",
    minWidth: 400,
    isGrow: true,
  },
];
