import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { JournalStatus } from "@app/products/property/journals/model";

export const dataWhichSupplementary = [
  {
    label: "None",
    value: SupplementaryMode.None,
  },
  { label: "New supplementary", value: SupplementaryMode.CreateNew },
  {
    label: "Existing supplementary",
    value: SupplementaryMode.SelectFromList,
  },
  {
    label: "Existing supplementary created by me",
    value: SupplementaryMode.SelectFromMyList,
  },
];

export const dataJournalStatus = [
  {
    label: "Yet to be authorised, proposed transactions",
    value: JournalStatus.TobeAuthorised,
  },
  {
    label: "Incomplete, proposed transactions",
    value: JournalStatus.Incomplete,
  },
  {
    label: "Authorised, active transactions",
    value: JournalStatus.Authorised,
  },
];
