import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { notYetInJournalRoute } from "./not-yet-in-journal/route";
import { sessionListRoute } from "./session-list/route";

export const journalsRoute: ICCRoute = {
  path: "journals",
  name: "Journals",
  enum: eMenuAction.Journals_Folder,
  children: [
    {
      path: "list",
      name: "List",
      enum: eMenuAction.Journals_SearchList,
      component: require("./list/_index").default,
    },
    {
      path: "my-journal",
      name: "My Journal",
      enum: eMenuAction.Journals_MyJournal,
      component: require("./my-journal/_index").default,
    },
    {
      path: "authorised",
      name: "Authorised",
      enum: eMenuAction.Journals_Authorise,
      component: require("./authorised/_index").default,
    },
    {
      path: "to-be-authorised",
      name: "To Be Authorised",
      enum: eMenuAction.Journals_TobeAuthorise,
      component: require("./to-be-authorised/_index").default,
    },
    notYetInJournalRoute,
    sessionListRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
