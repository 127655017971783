import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const generateNoticesAPI = async (
  noticeRunId: number
): Promise<APIResponse> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/int/noticerun/generate-notices?noticeRunId=${noticeRunId}`,
      {}
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
