import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRS4yoKinderAllByStatus: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",

    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.YearAttending,
    title: "Year Attending",

    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Status, title: "Status" },
  { field: CRSFieldMapping.ChildName, title: "Child Name" },
  { field: CRSFieldMapping.PrefMethod, title: "Preference Method" },
  { field: CRSFieldMapping.FirstPref, title: "First Preference" },
  { field: CRSFieldMapping.Offered, title: "Centre Offered" },
  {
    field: CRSFieldMapping.Placed,
    title: "Centre Placed",

    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  // { field: CRSFieldMapping.DaysReq, title: 'Days Req', },
  // { field: CRSFieldMapping.DaysAlloc, title: 'Days Alloc', },
  {
    field: CRSFieldMapping.RegistrationDate,
    title: "Registered",

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ChildDateOfBirth,
    title: "Date of Birth",

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.Deferred,
    title: "Deferred",

    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.SecondYearEntrolment,
    title: "2nd Yr Enrol",

    format: ICON_FORMAT.ICON_BOOLEAN,
  },
  {
    field: CRSFieldMapping.ChildGender,
    title: "Gender",

    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.LodgementDate,
    title: "Lodged",

    format: DATE_FORMAT.DATE,
  },
  { field: CRSFieldMapping.ParentName, title: "Parent Name" },
  { field: CRSFieldMapping.Relationship, title: "Relationship" },
  {
    field: CRSFieldMapping.Phone,
    title: "Phone",

    format: NUMBER_FORMAT.NUMBER2,
  },
];
