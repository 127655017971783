export enum AddressValidationType {
  None,
  VMAS2,
  DataTools,
  DELWPMapShare,
  NSWPOINT,
}

export interface MapshareSuggestion {
  text: string;
  magicKey: string;
  isCollection: boolean;
}

export interface DELWPMapshareServiceSuggestions {
  suggestions: MapshareSuggestion[];
}

export interface DELWPMapshareServiceCandidateAddress {
  spatialReference: MapshareCandidateSpatialReference;
  candidates: MapshareCandidate[];
}

export interface MapshareCandidateSpatialReference {
  wkid: number | null;
  latestWkid: number | null;
}

export interface MapshareCandidate {
  address: string;
  location: MapshareLocation;
  score: number | null;
  attributes: MapshareCandidateAttributes;
  extent: MapshareExtent;
}

export interface MapshareLocation {
  x: number;
  y: number;
}

export interface MapshareCandidateAttributes {
  User_fld: string;
}

export interface MapshareExtent {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface DELWPMapshareServicePropertyGeodetic {
  displayFieldName: string;
  fieldAliases: MapshareFieldAliases;
  geometryType: string;
  featureSpatialReference: MapshareFeatureSpatialReference;
  fields: MapshareField[];
  features: MapshareFeature[];
}

export interface MapshareFieldAliases {
  OBJECTID: string;
  ADD_PFI: string;
  PROP_LGA_CODE: string;
  PROP_PROPNUM: string;
  PROP_PROPERTY_TYPE: string;
  PROP_MULTI_ASSESSMENT: string;
  PROP_STATUS: string;
  ADD_EZI_ADDRESS: string;
  ADD_IS_PRIMARY: string;
  ADD_DISTANCE_RELATED_FLAG: string;
  ADD_HOUSE_NUMBER_1: string;
  ADD_HOUSE_SUFFIX_1: string;
  ADD_ROAD_NAME: string;
  ADD_ROAD_TYPE: string;
  ADD_ROAD_SUFFIX: string;
  ADD_LOCALITY_NAME: string;
  ADD_STATE: string;
  ADD_POSTCODE: string;
  ADD_NUM_ROAD_ADDRESS: string;
  PROP_PFI: string;
  PROPV_PFI: string;
  PROPV_BASE_PFI: string;
  SHAPE_LENGTH: string;
  SHAPE_AREA: string;
}
export interface MapshareFeatureSpatialReference {
  wkid: number | null;
  latestWkid: number | null;
}

export interface MapshareField {
  name: string;
  type: string;
  alias: string;
  length: number | null;
}

export interface MapshareFeature {
  attributes: MapshareFeatureAttributes;
  geometry: MapshareGeometry;
}
export interface MapshareFeatureAttributes {
  OBJECTID: number | null;
  ADD_PFI: string;
  PROP_LGA_CODE: string;
  PROP_PROPNUM: string;
  PROP_PROPERTY_TYPE: string;
  PROP_MULTI_ASSESSMENT: string;
  PROP_STATUS: string;
  ADD_EZI_ADDRESS: string;
  ADD_IS_PRIMARY: string;
  ADD_DISTANCE_RELATED_FLAG: string;
  ADD_HOUSE_NUMBER_1: number | null;
  ADD_HOUSE_SUFFIX_1: string;
  ADD_ROAD_NAME: string;
  ADD_ROAD_TYPE: string;
  ADD_ROAD_SUFFIX: any;
  ADD_LOCALITY_NAME: string;
  ADD_STATE: string;
  ADD_POSTCODE: string;
  ADD_NUM_ROAD_ADDRESS: string;
  PROP_PFI: string;
  PROPV_PFI: string;
  PROPV_BASE_PFI: any;
  SHAPE_LENGTH: number;
  SHAPE_AREA: number;
}

export interface MapshareGeometry {
  rings: number[][][];
}
