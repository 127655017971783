import { DTO_AssociatedItem_Arrangement } from "@app/products/property/components/associations/components/arrangement/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_AssociatedItem_Arrangement>();

export const colAssociationsArrangement: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Number"),
    title: "Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Date"),
    title: "Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PeriodPaymentAmount"),
    title: "Period Payment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PaymentFrequency"),
    title: "Payment Frequency",
  },
  {
    field: nameOf("InitialDebtAmount"),
    title: "Init Debt Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("OverdueAmount"),
    title: "Overdue Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("NumberOfOverdueInstalments"),
    title: "Number of Overdue Instalments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NextInstalmentDue"),
    title: "Next Instalment Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NextDueInstalment"),
    title: "Next Due Instalment",
  },
  {
    field: nameOf("LastLetterProducedOn"),
    title: "Last Letter Produced On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("LastLetterType"),
    title: "Last Letter Type",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("InterestStatus"),
    title: "Interest Status",
  },
  {
    field: nameOf("ApplicantName"),
    title: "Applicant Name",
  },
  {
    field: nameOf("PropertyName_Address_Locality"),
    title: "Property Name Address Locality",
  },
  {
    field: nameOf("Comments"),
    title: "Comments",
  },
  {
    field: nameOf("DebtRecovery"),
    title: "Debt Recovery",
  },
  {
    field: nameOf("AuthorisingOfficer"),
    title: "Authorising Officer",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
