import { crmsRoute } from "@app/products/crms/route";
import { spatialCRMSRouteCase } from "@app/products/crms/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IFlatCCMenuItem } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useEffectOnce } from "react-use";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

// TODO: temp settings
const crmsSettingEnums = [
  ECorporateSettingsField.CorporateSettings_PropertyDialog_OwnerStreetNumber,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_NearestCrossStreet,
  ECorporateSettingsField.CorporateSettings_PropertyDialog_BuildingName,
  ECorporateSettingsField.CorporateSettings_AddressValidation,
  ECorporateSettingsField.TP_UseSimpleAddressDialog,
  ECorporateSettingsField.CorporateSettings_PropertyCustomSelectQuery_Street,
];

export const CrmsRouter = observer((props: any) => {
  const { setSettings, loadSettings, isLoading, isProductActive } =
    useCommonProductStore();

  const loadCRMSSettings = useCallback(() => {
    loadSettings(crmsSettingEnums);
  }, [loadSettings]);

  useEffectOnce(() => {
    loadCRMSSettings();
    return () => {
      setSettings({});
    };
  });

  if (isLoading) return <Loading isLoading isFullScreen />;
  return (
    <ProductRouterSwitch>
      {crmsRoute.children &&
        convertRouteDynamic(crmsRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            const fullPath = `${props.match.url}/${route.path}`;
            const flatMenuData: IFlatCCMenuItem | undefined =
              isProductActive(fullPath);
            if (
              !flatMenuData &&
              route.path.search(":") === -1 &&
              !spatialCRMSRouteCase.isBathUpdate(route.path) // Special page for event/service standard batch update
            ) {
              return null;
            }

            if (flatMenuData?.isUnnavigated && flatMenuData?.navigationPath) {
              const navigationComponent = () => {
                window.open(flatMenuData?.navigationPath, "_self");
                return null;
              };
              return (
                <PrivateRoute
                  exact
                  key={index}
                  path={fullPath}
                  component={navigationComponent}
                />
              );
            }
            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={fullPath}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
});
