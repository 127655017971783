import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ApplicationCategoriesTabDetails } from "./model";

export const getApplicationCategoriesDetailsTabById = async (
  applicationCategoryId: number
): Promise<APIResponse<ApplicationCategoriesTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ApplicationCategoriesTabDetails>(
      `/api/tp/internal/applicationcategory/${applicationCategoryId}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
