import { Fee, Invoice } from "@app/core/invoice/[id]/model";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import { returnAmount } from "@app/core/invoice/[id]/util";
import { postPickFeeType } from "@app/core/invoice/invoice-item-accordion/api";
import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { FeeType } from "@app/products/town-planning/ppr/[id]/model";
import { IFeeTypes } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { isSuccessResponse } from "@common/apis/util";
import { FINANCEEXPORTOVERRIDEREASON } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

export interface IInvoiceItemDialogProps {
  onClose: () => void;
  productType: PRODUCT_TYPE_NUMBER;
  invoice: Invoice;
  isNew: boolean;
}
const nameOf = nameOfFactory<Fee>();
const nameOfFeeType = nameOfFactory<FeeType>();
export const InvoiceItemDialog = observer(
  ({ onClose, productType, invoice, isNew }: IInvoiceItemDialogProps) => {
    const { saveInvoice, isLoadingSaveInvoiceItem, setIsExpandedInvoiceItems } =
      useInvoiceStore();
    const [isRequiredFeeDescription, setIsRequiredFeeDescription] =
      useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const isDisable = isLoadingSaveInvoiceItem || isLoading;

    const handleSaveFeeItem = (event: FormSubmitClickEvent) => {
      const invoiceItem: Fee = cloneDeep(event.values.InvoiceItem) as Fee;
      const newInvoice = cloneDeep(invoice);
      setIsExpandedInvoiceItems(true);
      if (newInvoice.Invoice_ID === 0)
        newInvoice.Description = invoiceItem.FeeType.Name;
      invoiceItem.ExportOverrideReason_ENUM =
        FINANCEEXPORTOVERRIDEREASON.PENDING;
      invoiceItem.DebtorNumber = newInvoice.DebtorNumber;
      invoiceItem.Flag_CreditNote =
        invoiceItem._Custom_Flag_CreditNote ?? false;
      invoiceItem.ServiceDate = newInvoice.InvoiceDate;
      invoiceItem.InvoicedDate = newInvoice.InvoiceDate;
      delete invoiceItem._Custom_Flag_CreditNote;
      saveInvoice(
        { ...newInvoice, InvoiceItem: invoiceItem } as Invoice,
        isNew,
        true,
        notificationRef
      );
    };

    return (
      <Form
        initialValues={{ InvoiceItem: {} }}
        onSubmitClick={handleSaveFeeItem}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, valid, onSubmit, onChange } = formRenderProps;

          const feeTypeValue = valueGetter(`InvoiceItem.${nameOf("FeeType")}`);
          const isShowCreditNote = valueGetter(
            `InvoiceItem.${nameOf("Flag_CreditNote")}`
          );

          const creditNoteOnChangeHandler = (event: SwitchChangeEvent) => {
            if (event?.value) {
              setIsRequiredFeeDescription(true);
            } else {
              setIsRequiredFeeDescription(false);
            }
            onChange(`InvoiceItem.${nameOf("_Custom_Flag_CreditNote")}`, {
              value: event?.value,
            });
            // Change sign of amount to negative or positive
            const amount = valueGetter(`InvoiceItem.${nameOf("Amount")}`);
            onChange(`InvoiceItem.${nameOf("Amount")}`, {
              value: returnAmount(amount, event?.value),
            });
          };

          const handleOnChangeFeeTypes = async (data: IFeeTypes) => {
            setIsLoading(true);
            const response = await postPickFeeType(
              invoice?._BubbleUps,
              data?.ID
            );
            setIsLoading(false);
            if (isSuccessResponse(response) && response.data) {
              onChange("InvoiceItem", {
                value: {
                  ...response.data.ReturnObj,
                  _Custom_Flag_CreditNote: false,
                },
              });
            } else {
              notificationRef.current?.pushNotification({
                type: "error",
                autoClose: false,
                title: response?.error ?? "Select fee type failed",
              });
            }
          };

          return (
            <CCDialog
              maxWidth="40%"
              height={"auto"}
              titleHeader="Quick - Add Invoice Item"
              onClose={onClose}
              bodyElement={
                <FormElement className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <div className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Fee type
                          <CCTooltip type="validator" position="right" />
                          {isLoading && (
                            <span className="cc-icon-loading-free">
                              <i className="fas fa-spinner fa-spin" />
                            </span>
                          )}
                        </label>
                        <Field
                          name={`InvoiceItem.${nameOf("FeeType")}`}
                          component={FeeTypesPicker}
                          isGetDetail={false}
                          selectableMode="single"
                          onPickFeeTypes={handleOnChangeFeeTypes}
                          textField={nameOfFeeType("Name")}
                          validator={requiredValidator}
                          productType={productType}
                          value={feeTypeValue}
                          textProduce={(data: any) =>
                            `${data.Name}${data.Code ? ` - ${data.Code}` : ""}`
                          }
                          disabled={isDisable}
                        />
                      </div>
                      <div className="cc-field">
                        <CCLabel title="Amount" />
                        <Field
                          name={`InvoiceItem.${nameOf("Amount")}`}
                          component={CCCurrencyInput}
                          disabled={isDisable}
                        />
                      </div>
                      {isShowCreditNote && (
                        <div className="cc-field">
                          <CCLabel title="Credit note" />
                          <Field
                            name={`InvoiceItem.${nameOf(
                              "_Custom_Flag_CreditNote"
                            )}`}
                            component={CCSwitch}
                            checked={valueGetter(
                              `InvoiceItem.${nameOf("_Custom_Flag_CreditNote")}`
                            )}
                            onChange={creditNoteOnChangeHandler}
                            disabled={isDisable}
                          />
                        </div>
                      )}
                      <div className="cc-field">
                        <CCLabel
                          title="Description"
                          isMandatory={isRequiredFeeDescription}
                        />
                        <Field
                          name={`InvoiceItem.${nameOf("Description")}`}
                          component={CCTextArea}
                          rows={4}
                          validator={
                            isRequiredFeeDescription
                              ? requiredValidator
                              : undefined
                          }
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                  </div>
                </FormElement>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    onClick={onClose}
                    disabled={isDisable}
                  >
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={!valid || isDisable}
                    onClick={onSubmit}
                    className="cc-dialog-button"
                    iconClass={
                      isLoadingSaveInvoiceItem ? "fas fa-spinner fa-spin" : ""
                    }
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
