import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { BuildingActionBarNavDropdown } from "@app/products/building/components/action-bar/nav-dropdown/_index";
import { BuildingPoolsOrSpasDecommissionedBookmark } from "@app/products/building/pools-or-spas/decommissioned/util";
import { poolsOrSpasRoute } from "@app/products/building/pools-or-spas/route";
import { buildingRoute } from "@app/products/building/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { NoData } from "@components/no-data/NoData";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: buildingRoute.name,

    leftComponents: [
      <BuildingActionBarNavDropdown category={poolsOrSpasRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={BUILDING_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Building}
        recordType={RECORDTYPE.BUILDING}
        listViewDetail={
          BuildingPoolsOrSpasDecommissionedBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          BuildingPoolsOrSpasDecommissionedBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  return <NoData />;
};
