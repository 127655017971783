import { colRebateCalcForSelectedEntitlements } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/calculations/components/detail/config";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import "./_index.scss";

export const RebateCalcForSelectedEntitlements = (
  _props: GridDetailRowProps
) => {
  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">
            Rebate calculations for selected entitlements by rating period
          </label>
          <CCGrid
            className="cc-notice-of-sale-list-children-grid"
            data={[]}
            columnFields={colRebateCalcForSelectedEntitlements}
            primaryField="ID"
            readOnly
          />
        </div>
      </div>
    </div>
  );
};
