export const addOptionAPI = (
  api: any,
  dataObj: { [key: string]: string },
  loadingObj?: { [key: string]: string }
) => {
  const apiKeyArr = Object.keys(api);
  const dataKeyArr = Object.keys(dataObj);
  const loadingKeyArr = loadingObj ? Object.keys(loadingObj) : [];
  const objFilter: any = {};
  const _option: { [key: string]: any } = {};
  apiKeyArr.forEach((item) => {
    if (dataKeyArr.includes(item) || loadingKeyArr?.includes(item))
      _option[dataObj[item]] = {};

    if (dataKeyArr.includes(item)) {
      _option[dataObj[item]]["Data"] = api[item];
    } else if (loadingKeyArr?.includes(item)) {
      _option[dataObj[item]]["Loading"] = api[item];
    } else {
      objFilter[item] = api[item];
    }
  });
  return { ...objFilter, _option };
};
