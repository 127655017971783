import { ICCRoute } from "@common/constants/ICCRoute";

export const applicationsRoute: ICCRoute = {
  path: "applications",
  name: "Applications",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "open",
      name: "Open",
      component: require("./open/_index").default,
    },
    {
      path: "overdue",
      name: "Overdue",
      component: require("./overdue/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-locality",
      name: "By Locality",
      component: require("./by-locality/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-nature-of-work",
      name: "By Nature of Work",
      component: require("./by-nature-of-work/_index").default,
    },
    {
      path: "by-permit-type",
      name: "By Permit Type",
      component: require("./by-permit-type/_index").default,
    },
    {
      path: "by-permit-issued",
      name: "By Permit Issued",
      component: require("./by-permit-issued/_index").default,
    },
    {
      path: "building-assessment",
      name: "Building Assessment",
      component: require("./building-assessment/_index").default,
    },
    {
      path: "private-certifications",
      name: "Private Certifications",
      component: require("./private-certifications/_index").default,
    },
    {
      path: "council-applications",
      name: "Council Applications",
      component: require("./council-applications/_index").default,
    },
    {
      path: "plumber-applications",
      name: "Plumber Applications",
      component: require("./plumber-applications/_index").default,
    },
    {
      path: "private-and-council",
      name: "Private and Council",
      component: require("./private-and-council/_index").default,
    },
    {
      path: "street-protection",
      name: "Street Protection",
      component: require("./street-protection/_index").default,
    },
    {
      path: "due-to-lapse",
      name: "Due To Lapse",
      component: require("./due-to-lapse/_index").default,
    },
    {
      path: "cil",
      name: "CIL",
      component: require("./cil/_index").default,
    },
    {
      path: "cil-pending-payment",
      name: "CIL - Pending Payment",
      component: require("./cil-pending-payment/_index").default,
    },
  ],
};
