import { useWithdrawApplicationStore } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/withdraw-application/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { WithdrawCommentDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/withdraw-comment/_index";
import WithdrawConfirmDialog from "@app/products/town-planning/ppr/components/action-bar/dialogs/withdraw-confirm/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const withdrawValidatorList = [
  Application_Status.AllocatedToPlanner,
  Application_Status.AssessedByPlanner,
  Application_Status.PreliminaryAssessment,
  Application_Status.FurtherInfo,
  Application_Status.FurtherInfoReceived,
  Application_Status.FurtherInfoWaitingForApproval,
  Application_Status.FurtherInfoApproved,
  Application_Status.ReadyToSendForApproval,
  Application_Status.ReadyForDecision,
  Application_Status.ApprovedByTeamLeader,
  Application_Status.PlannerAssessment,
  Application_Status.PermitIssued,
  Application_Status.AmendmentIssued,
  Application_Status.NoticeOfDecision,
  Application_Status.PermitRefused,
  Application_Status.AmendmentRefused,
  Application_Status.Appealed,
];
export const WithDrawApplicationButton = observer(() => {
  const {
    isShowDialog,
    setIsShowDialog,
    isLoading,
    handleSubmitWithdraw,
    setIsShowWithDrawDialog,
    isShowWithDrawDialog,
  } = useWithdrawApplicationStore();
  const { ppr, onSubmit, pprId } = usePPRStore();

  const isVisible = useMemo(() => {
    return withdrawValidatorList.some((item) => {
      if (
        Application_Status.Lodged === ppr?.Status_ENUM &&
        !ppr?.Flag_PlansToComply
      )
        return true;
      return item === ppr?.Status_ENUM;
    });
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Withdraw application"
        onClick={onSubmit}
        isLoading={isLoading}
        disabled={isLoading}
        name={PPRSubmitActions.WithdrawnApplication}
      />

      {isShowDialog && (
        <WithdrawConfirmDialog
          onClosePopup={() => {
            setIsShowDialog(false);
          }}
          onConfirmYes={() => {
            setIsShowDialog(false);
            setIsShowWithDrawDialog(true);
          }}
        />
      )}

      {isShowWithDrawDialog && (
        <WithdrawCommentDialog
          onClose={() => {
            setIsShowWithDrawDialog(false);
          }}
          isLoading={isLoading}
          onSubmit={(data) => {
            handleSubmitWithdraw(data, pprId);
          }}
        />
      )}
    </>
  ) : null;
});
