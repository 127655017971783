import "@app/products/animals/components/forms/components/form-element/_index.scss";
import {
  DirectDebitBankAccount,
  DirectDebitStatusCode,
} from "@app/products/direct-debit/system-admin/model";
import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { default as React, useCallback } from "react";

export interface IDirectDebitStatusCodeFormElementProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DirectDebitStatusCode>();
const nameOfBankAccount = nameOfFactory<DirectDebitBankAccount>();
const statusCodeValidator = (value: string) =>
  stringLengthValidator(value, 1, 20);

const statusDescriptionValidator = (value: string) =>
  stringLengthValidator(value, 0, 50);

export const DirectDebitStatusCodeFormElement = ({
  isInactive = false,
  formRenderProps,
}: IDirectDebitStatusCodeFormElementProps) => {
  const { valueGetter, onChange } = formRenderProps;

  const { dataForms } = useFlexibleFormStore();

  const getValue = (nameOfField: keyof DirectDebitStatusCode) =>
    valueGetter(nameOf(nameOfField));

  const onChangeField = (nameOfField: string, value: any) => {
    onChange(nameOf(nameOfField as keyof DirectDebitStatusCode), {
      value: value,
    });
  };

  const bankAccountId = getValue("Direct_Debit_Bank_Account_Id");

  const ddBankAccountsPickerHandler = (value: ComboBoxChangeEvent) => {
    onChangeField(
      "Direct_Debit_Bank_Account_Id",
      value?.value?.Direct_Debit_Bank_Account_Id
    );
    onChangeField(
      "Direct_Debit_Bank_Acc_Name",
      value?.value?.Direct_Debit_Bank_Acc_Name
    );
    onChangeField("Direct_Debit_Bank_Description", value?.value?.Description);
  };
  const bankAccountValidator = useCallback(
    (value: any) => {
      if (
        !getDropdownValue(
          getValue("Direct_Debit_Bank_Account_Id"),
          dataForms?.GeneralFormLovs?.bankAccountList ?? [],
          nameOf("Direct_Debit_Bank_Account_Id")
        )
      ) {
        return "This field is required.";
      }

      return requiredValidator(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bankAccountId, dataForms?.GeneralFormLovs?.bankAccountList]
  );
  return (
    <FormElement id="cc-form-manage-page-general">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Bank account" isMandatory />
            <Field
              component={CCComboBox}
              name={nameOf("Direct_Debit_Bank_Account_Id")}
              textField={nameOfBankAccount("Description")}
              dataItemKey={nameOfBankAccount("Direct_Debit_Bank_Account_Id")}
              onChange={ddBankAccountsPickerHandler}
              value={getDropdownValue(
                getValue("Direct_Debit_Bank_Account_Id"),
                dataForms?.GeneralFormLovs?.bankAccountList ?? [],
                nameOf("Direct_Debit_Bank_Account_Id")
              )}
              data={dataForms?.GeneralFormLovs?.bankAccountList ?? []}
              disabled={isInactive}
              validator={bankAccountValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Status code" isMandatory />
            <Field
              component={CCInput}
              name={nameOf("Status_Code")}
              disabled={isInactive}
              validator={statusCodeValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Status description" />
            <Field
              component={CCInput}
              name={nameOf("Status_Description")}
              disabled={isInactive}
              validator={statusDescriptionValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Is rejection" />
            <Field
              component={CCSwitch}
              name={nameOf("Is_Rejected")}
              disabled={isInactive}
              checked={getValue("Is_Rejected")}
            />
          </div>
        </div>
      </section>
    </FormElement>
  );
};
