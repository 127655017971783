import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "bulk-archive",
      name: "Bulk Archive",
      component: require("./bulk-archive/_index").default,
    },
    {
      path: "characteristics",
      name: "Characteristics",
      component: require("./characteristics/_index").default,
    },
    {
      path: "documents",
      name: "Documents",
      component: require("./documents/_index").default,
    },
    {
      path: "fee-setup",
      name: "Fee Setup",
      component: require("./fee-setup/_index").default,
    },
    {
      path: "hbc-compat-score",
      name: "HBC Compat. Score",
      component: require("./hbc-compat-score/_index").default,
    },
    {
      path: "keywords",
      name: "Keywords",
      component: require("./keywords/_index").default,
    },
    {
      path: "legacy-registrations",
      name: "Legacy Registrations",
      component: require("./legacy-registrations/_index").default,
    },
    {
      path: "localities",
      name: "Localities",
      component: require("./localities/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
  ],
};
