import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { Attachment, DOCUMENTSTATUS } from "@app/core/documents/model";
import {
  RecallUploadFileInfo,
  Svc_BatchRecallUpdateRequest,
} from "@app/core/recall/model";
import { CCLabel } from "@components/cc-label/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Chip } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { UploadOnAddEvent } from "@progress/kendo-react-upload";
import React, { useRef, useState } from "react";
import { renderRecallAttachmentItem } from "./util";

export const RecallAttachmentField = (props: any) => {
  const {
    disabled,
    files,
    attachmentRemoval,
    removalWarning,
    updateFileUI,
    updateFileData,
    updateAttachmentRemoval,
  } = props;
  const [showConfirmRemoveAttachment, setShowConfirmRemoveAttachment] =
    useState(false);
  const [skipRemovalWarning, setSkipRemovalWarning] = useState(
    !removalWarning?.show
  );
  const attachmentRemovalEvent = useRef<UploadOnAddEvent>();

  const handleUpdateAttachments = async (event: UploadOnAddEvent) => {
    let processFile = [];
    for (let index = 0; index < event.newState.length; index++) {
      const file = event.newState[index] as RecallUploadFileInfo;
      if (file.parentMapping) {
        continue;
      }
      const fileEntry = await convertToFileStreamEntry(file);
      const attachments = {
        FileEntry: fileEntry,
        Title:
          typeof file.name === "string" && file.name
            ? file.name
            : file.originalFileName ?? "",
        Category_KWD: null,
        Attachment_ID: 0,
        FileNumber: "",
        TRIMUri: "",
        Status_ENUM: DOCUMENTSTATUS.Draft,
      } as Attachment;
      processFile.push(attachments);
    }
    updateFileData(processFile);
    const uiAttachments: RecallUploadFileInfo[] = renderRecallAttachmentItem(
      event.newState,
      ({ text }) => (
        <span>
          {text}{" "}
          <Chip
            size={"small"}
            rounded={"full"}
            themeColor={"success"}
            children="new"
          />
        </span>
      )
    );
    updateFileUI(uiAttachments);
  };

  const handleAddAttachments = handleUpdateAttachments;

  const removeAttachments = (event: UploadOnAddEvent) => {
    const removedFiles = event.affectedFiles as RecallUploadFileInfo[];
    const removalMap: Svc_BatchRecallUpdateRequest["AttachmentRemoval"] =
      Object.assign({}, attachmentRemoval);
    removedFiles.forEach((file) => {
      if (!file.parentMapping) {
        return;
      }
      for (const recallId in file.parentMapping) {
        const attachmentId = file.parentMapping[recallId];
        if (removalMap && !removalMap[recallId]) {
          removalMap[recallId] = [];
        }
        removalMap?.[recallId].push(attachmentId);
      }
    });
    updateAttachmentRemoval(removalMap);
    handleUpdateAttachments(event);
  };

  const handleRemoveAttachments = (event: UploadOnAddEvent) => {
    const removedFiles = event.affectedFiles as RecallUploadFileInfo[];
    if (
      removedFiles?.some((file) => file.parentMapping) &&
      removalWarning?.show
    ) {
      attachmentRemovalEvent.current = event;
      setShowConfirmRemoveAttachment(true);
      return;
    }
    removeAttachments(event);
  };

  return (
    <>
      <CCLabel
        title={"Attachment (" + (files?.length ?? 0) + ")"}
        informationTooltip="Attachments added are supportive of the recall ONLY and will not be included in communication or mail merge."
      />
      <CCUploadFile
        autoUpload={false}
        batch={false}
        multiple
        files={files}
        withCredentials={false}
        onAdd={handleAddAttachments}
        onRemove={handleRemoveAttachments}
        showActionButtons={false}
        disabled={disabled}
      />
      {showConfirmRemoveAttachment ? (
        <ConfirmDialog
          title="Confirmation"
          message="Remove this attachment"
          subMessage={
            <>
              Attachment will be deleted permanently on saving recall. Are you
              sure you want to proceed?
              <br />
              <div className="mt-3">
                <small>
                  <Checkbox
                    title="Removal confirmation"
                    label="Don't show this message again for current batch recall."
                    checked={skipRemovalWarning}
                    onChange={(event) => setSkipRemovalWarning(event.value)}
                  />
                </small>
              </div>
            </>
          }
          btnYesTitle="Ok"
          btnNoTitle="Cancel"
          onClosePopup={() => {
            setSkipRemovalWarning(false);
            setShowConfirmRemoveAttachment(false);
          }}
          onConfirmYes={() => {
            if (attachmentRemovalEvent.current) {
              removeAttachments(attachmentRemovalEvent.current);
            }
            setShowConfirmRemoveAttachment(false);
            attachmentRemovalEvent.current = undefined;
            if (skipRemovalWarning) {
              removalWarning?.skip();
            }
          }}
        />
      ) : null}
    </>
  );
};
