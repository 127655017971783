import {
  IAssetCategory,
  IFeatureTableDefinition,
} from "@app/products/ams/model/assetCategory";
import { action, configure, makeObservable, observable } from "mobx";

configure({ enforceActions: "always" }); // Strict mode: runInAction

export default class AssetCategoryStore {
  loadingInitial = true;
  categories: IAssetCategory[] = [];
  featureTables: Map<string, IFeatureTableDefinition> = new Map();
  selectedCategory: IAssetCategory | null = null;
  featureToggleCount: number = 0;

  constructor() {
    makeObservable(this, {
      loadingInitial: observable,
      categories: observable,
      featureTables: observable,
      selectedCategory: observable,
      featureToggleCount: observable,
      loadCategories: action,
      loadFeatureTables: action,
      setSelectedCategory: action,
      toggleFeatureTableUse: action,
    });
  }

  loadCategories() {
    this.loadFeatureTables(); //make sure the features are loaded too

    if (this.categories.length === 0) {
      const initCategories = [
        {
          id: 1,
          name: "Roads",
          featureTables: [
            {
              name: "Default",
              columns: [
                { name: "AssetNumber", helpText: "Asset Number" },
                { name: "AssetDescription", helpText: "Asset Desc" },
                { name: "AssetCategory", helpText: "" },
                { name: "Status", helpText: "" },
              ],
            },
            {
              name: "Measurements",
              columns: [
                { name: "Length", helpText: "section length" },
                { name: "Width", helpText: "section width" },
              ],
            },
          ],
        },
      ];

      this.categories = initCategories;
      this.loadingInitial = false;
    }
  }

  loadFeatureTables() {
    if (this.featureTables.size === 0) {
      const initFeatureTables = [
        {
          //fields from the Default set cannot be opted out of
          name: "Default",
          tableName: "AssetRegisterItem",
          columns: [
            {
              name: "AssetNumber",
              dataType: "string",
              lookupName: "",
              label: "Number",
            },
            {
              name: "AssetDescription",
              dataType: "string",
              lookupName: "",
              label: "Description",
            },
            {
              name: "AssetCategory",
              dataType: "string",
              lookupName: "AssetCategory",
              label: "Category",
            },
            {
              name: "Status",
              dataType: "string",
              lookupName: "Asset Status",
              label: "Status",
            },
          ],
        },
        {
          name: "Lifecycle",
          tableName: "AssetRegisterItem",
          columns: [
            {
              name: "DesignLife",
              dataType: "number",
              lookupName: "",
              label: "Design life",
            },
            {
              name: "RemainingLife",
              dataType: "number",
              lookupName: "",
              label: "Remaining life",
            },
            {
              name: "Purchase Date",
              dataType: "datetime",
              lookupName: "",
              label: "Purchase date",
            },
            {
              name: "Install Date",
              dataType: "datetime",
              lookupName: "",
              label: "Install date",
            },
            {
              name: "Warranty Expiry Date",
              dataType: "datetime",
              lookupName: "",
              label: "Warranty date",
            },
            {
              name: "CurrentValue",
              dataType: "currency",
              lookupName: "",
              label: "Current $",
            },
            {
              name: "ReplacementCost",
              dataType: "currency",
              lookupName: "",
              label: "Replacement cost $",
            },
          ],
        },
        {
          name: "Measurements",
          tableName: "Measurements",
          columns: [
            {
              name: "Length",
              dataType: "number",
              lookupName: "",
              label: "Length",
            }, //how to say need uom?
            {
              name: "Width",
              dataType: "number",
              lookupName: "",
              label: "Width",
            },
            {
              name: "Radius",
              dataType: "number",
              lookupName: "",
              label: "Radius",
            },
            {
              name: "Diameter",
              dataType: "number",
              lookupName: "",
              label: "Diameter",
            },
            { name: "Area", dataType: "number", lookupName: "", label: "Area" },
            {
              name: "Volume",
              dataType: "number",
              lookupName: "",
              label: "Volume",
            },
          ],
        },
        {
          name: "Location",
          tableName: "Location",
          columns: [
            {
              name: "Latitude",
              dataType: "number",
              lookupName: "",
              label: "Lat.",
            },
            {
              name: "Longitude",
              dataType: "number",
              lookupName: "",
              label: "Lng.",
            },
            {
              name: "Zone",
              dataType: "number",
              lookupName: "Zone",
              label: "Zone",
            },
            {
              name: "Address1",
              dataType: "string",
              lookupName: "",
              label: "Address line 1",
            },
            {
              name: "Address2",
              dataType: "string",
              lookupName: "",
              label: "Address line 2",
            },
            {
              name: "Suburb",
              dataType: "string",
              lookupName: "",
              label: "Suburb",
            },
            {
              name: "State",
              dataType: "string",
              lookupName: "",
              label: "State",
            },
            {
              name: "Country",
              dataType: "string",
              lookupName: "",
              label: "Country",
            },
            {
              name: "Facility",
              dataType: "string",
              lookupName: "Facility",
              label: "Facility",
            },
            {
              name: "Building",
              dataType: "string",
              lookupName: "Building",
              label: "Building",
            },
            {
              name: "Room",
              dataType: "string",
              lookupName: "Room",
              label: "Room",
            },
          ],
        },
      ];
      initFeatureTables.forEach((ft: IFeatureTableDefinition) => {
        this.featureTables.set(ft.name, ft);
      });
    }
  }

  setSelectedCategory(categoryName: string) {
    let category = this.categories.find((itm) => itm.name === categoryName);
    if (category) {
      this.selectedCategory = category;
    }
  }

  toggleFeatureTableUse(
    categoryName: string,
    featureTableName: string,
    featureColumnName: string
  ) {
    let category = this.categories.find((itm) => itm.name === categoryName);
    let ftu = category?.featureTables.find(
      (itm) => itm.name === featureTableName
    );
    let currentInUse = ftu ? true : false;

    if (category && !currentInUse) {
      ftu = { name: featureTableName, columns: [] };
      category.featureTables = category.featureTables.concat(ftu);
    }

    let fcu = ftu?.columns.find((itm) => itm.name === featureColumnName);
    currentInUse = fcu ? true : false;
    if (ftu) {
      if (!currentInUse) {
        fcu = { name: featureColumnName, helpText: "" };
        ftu.columns = ftu.columns.concat(fcu);
      } else {
        ftu.columns = ftu.columns.filter(
          (itm) => itm.name !== featureColumnName
        );
      }
    }

    this.featureToggleCount = this.featureToggleCount + 1;
  }
}
