import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { postReinstateScheduling } from "@common/pages/settings/system-admin/task-scheduling/api";
import {
  EJobsState,
  IQuartzJobKey,
} from "@common/pages/settings/system-admin/task-scheduling/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IReinstateJobsButtonProps {
  isDisabled?: boolean;
}

export const ReinstateJobsButton = observer(
  ({ isDisabled = false }: IReinstateJobsButtonProps) => {
    const { gridSelectedRows, clearSelectedItems } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();

    const [isShowConfirmDialog, setIsConfirmDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isButtonDisabled = useMemo(() => {
      return (
        isDisabled ||
        gridSelectedRows?.length !== 1 ||
        gridSelectedRows[0].JobState !== EJobsState.Failed
      );
    }, [isDisabled, gridSelectedRows]);

    const handleReinstate = async () => {
      if (!gridSelectedRows?.length) return;

      setIsLoading(true);
      const dataReinstate: IQuartzJobKey = {
        Jobkey: gridSelectedRows[0].JobName,
        Group: gridSelectedRows[0].GroupName,
      };
      const response = await postReinstateScheduling(dataReinstate ?? []);
      setIsLoading(false);
      setIsConfirmDialog(false);
      if (isSuccessResponse(response)) {
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          autoClose: true,
          title: "The job was reinstated successfully.",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: response.data?.Errors ?? "Reinstate the job was failed.",
          type: "error",
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Reinstate"
          onClick={() => {
            setIsConfirmDialog(true);
          }}
          disabled={isButtonDisabled}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            onClosePopup={() => setIsConfirmDialog(false)}
            title={"Confirm Reinstate"}
            message={`Are you sure you want to reinstate this failed job?`}
            onAsyncConfirm={handleReinstate}
            isLoadingYes={isLoading}
          />
        )}
      </>
    );
  }
);
