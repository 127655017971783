import Actions from "@app/core/actions/_index";
import { Comments } from "@app/core/comments/_index";
import { Documents } from "@app/core/documents/_index";
import Fees from "@app/core/fees/_index";
import { SamplesAccordion } from "@app/core/samples/_index";
import { EventManagementForm } from "@app/products/event-management/[id]/components/child-screens/general/_index";
import { getEventManagementRecords } from "@app/products/event-management/[id]/components/forms/existed/api";
import { IEventManagementRecordsState } from "@app/products/event-management/[id]/components/forms/existed/model";
import { EventManagementContactsTab } from "@app/products/event-management/[id]/components/reference-sidebar/contacts/_index";
import { EventManagementDetailsTab } from "@app/products/event-management/[id]/components/reference-sidebar/details/_index";
import { EventManagementHistoryTab } from "@app/products/event-management/[id]/components/reference-sidebar/history/_index";
import { useEventManagementStore } from "@app/products/event-management/[id]/store";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useState } from "react";
import { useEffectOnce } from "react-use";

export const ExistManageEventManagement = observer(() => {
  const [eventManagementRecords, setEventManagementRecords] = useState<
    IEventManagementRecordsState | undefined
  >(undefined);
  const {
    eventManagement,
    isLoading,
    responseLoadError,
    loadEventManagement,
    eventManagementId,
  } = useEventManagementStore();

  useEffectOnce(() => {
    if (!eventManagementId) return;
    getEventManagementRecords(
      eventManagementId,
      RECORDTYPE.EMS_EventBooking
    ).then((res) => {
      setEventManagementRecords(res);
    });
  });
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const getTitle = () => {
    const DebtorNo = eventManagement?.DebtorNo;
    const MasterFacility = eventManagement?.MasterFacility ? "Big Venue 1" : "";

    if (DebtorNo && MasterFacility) {
      return `${DebtorNo} - ${MasterFacility}`;
    }

    return DebtorNo || MasterFacility || "N/A";
  };
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getDisplayTextWithDashes([
        eventManagement?.DebtorNo,
        eventManagement?.MasterFacility || "Big Venue 1",
      ]),
      LinkUrl: managePageUrl,
      LinkText: `Event Management - ${eventManagementId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.EventManagementSystem,
      RecordType_ENUM: RECORDTYPE.EMS_EventBooking, // TODO: Change RECORDTYPE
      Record_ID: eventManagementId ?? 0,
    },
  ];

  const listPanelBar: IListPanelBar[] = [
    {
      title: "Actions",
      totalRecord: eventManagementRecords?.actions,
      component: (
        <Actions
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Comments",
      totalRecord: eventManagementRecords?.comments,
      component: (
        <Comments
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Documents",
      totalRecord: eventManagementRecords?.documents,
      component: (
        <Documents
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Fees",
      totalRecord: eventManagementRecords?.fees,
      component: (
        <Fees id={eventManagementId} recordType={RECORDTYPE.EMS_EventBooking} />
      ),
    },
    {
      title: "Events",
      totalRecord: eventManagementRecords?.samples,
      component: (
        <SamplesAccordion
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Booking Dates",
      totalRecord: eventManagementRecords?.bookingDates,
      component: (
        <SamplesAccordion
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Features",
      totalRecord: eventManagementRecords?.features,
      component: (
        <SamplesAccordion
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Booking Estimates",
      totalRecord: eventManagementRecords?.bookingEstimates,
      component: (
        <SamplesAccordion
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Bonds",
      totalRecord: eventManagementRecords?.bonds,
      component: (
        <SamplesAccordion
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
    {
      title: "Contacts",
      totalRecord: eventManagementRecords?.contacts,
      component: (
        <SamplesAccordion
          id={eventManagementId}
          recordType={RECORDTYPE.EMS_EventBooking}
        />
      ),
    },
  ];

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <EventManagementDetailsTab />,
      },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <EventManagementContactsTab />,
      },
      {
        title: "History",
        component: <EventManagementHistoryTab />,
      },
    ],
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Event" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() =>
            eventManagementId ? loadEventManagement(eventManagementId) : {}
          }
        />
      ) : (
        <>
          <FormTitle title={getTitle()} badge={eventManagement?.Status_ENUM} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Confirm event"} />
                <CCNavButton title={"Decline event"} />
                <CCNavButton title={"Add to group booking"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Add comments"} />
                <CCNavButton title={"Add contacts"} />
                <CCNavButton title={"Add documents"} />
                <CCNavButton title={"Add fees"} />
                <CCNavButton title={"Add samples"} />
                <CCNavButton title={"Add inspection"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"View invoices"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <CCGeneralPanel component={<EventManagementForm />} />
                <CustomPanelBar listPanelBar={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
