import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { DTO_LOV } from "@common/models/odataResponse";

configure({ enforceActions: "always" });
class NewCertificateDetailStepStore {
  private _lovDetail?: DTO_LOV[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get lovDetail() {
    return toJS(this._lovDetail);
  }
  setLovDetail = (lov: DTO_LOV[]) => {
    runInAction(() => {
      this._lovDetail = lov;
    });
  };
}

const newCertificateDetailStepStoreContext = createContext(
  new NewCertificateDetailStepStore()
);
export const useNewCertificateDetailStepStoreContext = () => {
  return useContext(newCertificateDetailStepStoreContext);
};
