import { CoreAPIService } from "@common/apis/coreAPIService";

export const getRefreshCacheProperty = async () => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/common/clearcache`
    );
  } catch (error) {
    const errorResponse: any = error;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
