import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_Assessment_Names } from "./model";

export const loadPropertyAssessmentNames = async (
  assessmentId: number,
  isIncludePastNames: boolean = true
): Promise<DTO_Assessment_Names | undefined> => {
  try {
    const response = await CoreAPIService.getClient().get<DTO_Assessment_Names>(
      `/api/property/internal/assessment/${assessmentId}/names`,
      {
        params: {
          LoadPastNames: isIncludePastNames,
        },
      }
    );

    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
