import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const TRANSACTION_FORM_STEP = "Transactions";
export const TransactionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ nameOf }: IFormStepElement) => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Date/Time</label>
          <Field
            name={nameOf("Date")}
            component={CCDatePicker}
            format={DATETIME_FORMAT.DATETIME_CONTROL}
            disabled
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Reference</label>
          <Field
            name={nameOf("Reference")}
            component={CCInput}
            placeholder="Reference"
            readOnly
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Description</label>
          <Field
            name={nameOf("Description")}
            component={CCInput}
            placeholder="Description"
            readOnly
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Amount</label>
          <Field
            name={nameOf("Amount")}
            component={CCCurrencyInput}
            placeholder="Amount"
            disabled
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Quantity</label>
          <Field
            name={nameOf("Quantity")}
            component={CCInput}
            placeholder="Quantity"
            readOnly
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Notes</label>
          <Field
            name={nameOf("Notes")}
            placeholder="Notes"
            readOnly
            rows={3}
            component={CCTextArea}
          />
        </div>
      </div>
    </section>
  );
};
