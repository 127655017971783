import { mockRegistersList } from "@app/products/property/registers/list/mock";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
import { IRegistersSummary } from "./model";

export const getRegisterSummaryById = async (
  registerId: number
): Promise<APIResponse<IRegistersSummary | undefined>> => {
  await sleep(1000);
  const recordRegister = mockRegistersList.find(
    (item) => item.Id === registerId
  );

  if (recordRegister) {
    return {
      data: recordRegister as IRegistersSummary,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};

export const postGISReference = async (
  GISReference: string[],
  register?: IRegistersSummary
): Promise<IRegistersSummary> => {
  await sleep(1000);
  let newGISReference: string[] = [];
  if (register?.GISReference) {
    newGISReference = [
      ...Array.from(new Set(register.GISReference.concat(GISReference))),
    ];
  } else {
    newGISReference = [...Array.from(new Set(GISReference))];
  }
  const newRegister: IRegistersSummary | undefined = {
    ...register,
    GISReference: newGISReference,
  };
  return newRegister;
};

export const putGISReference = async (
  oldGISReference: string[],
  newGISReference: string[],
  registers: IRegistersSummary
): Promise<IRegistersSummary> => {
  const newRegister = registers;
  let index: number = 0;
  oldGISReference.forEach((reference: string) => {
    newRegister.GISReference![newRegister.GISReference!.indexOf(reference)] =
      newGISReference[index];
    index++;
  });
  return newRegister;
};

export const removeGISReferenceAPI = async (
  selectedGISReference: string[],
  registers: IRegistersSummary
): Promise<IRegistersSummary> => {
  const newRegister = registers;
  selectedGISReference.forEach((references: string) => {
    let index: number = newRegister.GISReference!.findIndex(
      (item: string) => item === references
    );
    newRegister.GISReference!.splice(index, 1);
  });
  return newRegister;
};
