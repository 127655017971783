import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const inspectionsRoute: ICCRoute = {
  path: "inspections",
  name: "Inspections",
  enum: HealthManagerMenu.Inspections,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: HealthManagerMenu.InspectionsByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: HealthManagerMenu.InspectionsByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: HealthManagerMenu.InspectionsByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      enum: HealthManagerMenu.InspectionsByMonth,
      component: require("./by-month/_index").default,
    },
    {
      path: "by-area",
      name: "By Area",
      enum: HealthManagerMenu.InspectionsByArea,
      component: require("./by-area/_index").default,
    },
    {
      path: "supervisor",
      name: "Supervisor",
      enum: HealthManagerMenu.InspectionsSupervisor,
      // mock route: not implemented yet
      //component: require("./supervisor/_index").default,
    },
    {
      path: "assessments",
      name: "Assessments",
      enum: HealthManagerMenu.InspectionsAssessmentsByDate,
      component: require("./assessments/_index").default,
    },
    {
      path: "audits",
      name: "Audits",
      enum: HealthManagerMenu.InspectionsAuditsByDate,
      component: require("./audits/_index").default,
    },
    {
      path: "os-nc-items",
      name: "OS NC Items",
      enum: HealthManagerMenu.InspectionsOSNCItems,
      component: require("./os-nc-items/_index").default,
    },
    {
      path: "supervisor",
      name: "Supervisor",
      enum: HealthManagerMenu.InspectionsSupervisor,
      component: require("./supervisor/_index").default,
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
