import { IAttributeData } from "@app/products/property/model";
import { getPICAttributes } from "@app/products/property/pic/[id]/components/child-screens/attributes/api";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class PicAttributesStore {
  private _attributes?: IAttributeData[] = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get attributes() {
    return this._attributes;
  }
  setAttributes = (attributes?: IAttributeData[]) => {
    runInAction(() => {
      this._attributes = attributes;
    });
  };

  //Action

  loadAttributes = async (picsId: number) => {
    this.setIsLoading(true);
    const newAttributes = await getPICAttributes(picsId);

    this.setAttributes(
      newAttributes && newAttributes?.ItemData
        ? Object.keys(newAttributes?.ItemData).map((key, index) => ({
            Attribute: key,
            Value: newAttributes.ItemData[key as any],
            Id: index,
          }))
        : undefined
    );
    this.setIsLoading(false);
  };

  resetStore = () => {
    this.setAttributes(undefined);
    this.setIsLoading(false);
  };
}

const picAttributesStoreContext = createContext(new PicAttributesStore());
export const usePicAttributesStore = () => {
  return useContext(picAttributesStoreContext);
};
