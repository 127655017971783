import { INewOwners } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/components/form-elements/names/model";
import {
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { PercentageCell } from "@components/cc-grid/components/grid-cells/percentage/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOfNewOwner = nameOfFactory<INewOwners>();
export const newOwnerCols: IColumnFields[] = [
  { field: nameOfNewOwner("Name"), title: "Name" },
  {
    field: nameOfNewOwner("Role_Name"),
    title: "Role",
  },
  {
    field: nameOfNewOwner("Percentage"),
    title: "Percentage",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
    calculateFooterCellValue: (_props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfNewOwner("Percentage"), gridData),
    footerCell: <PercentageCell className="text-success" />,
  },
  { field: nameOfNewOwner("Address"), title: "Address" },
  {
    field: nameOfNewOwner("Entity_Name_Address_Id"),
    title: "Contact Name/Address ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
