import {
  apiCoreGetFeeType,
  apiCoreGetFeeTypeLovs,
  apiCoreSaveFeeType,
} from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import {
  FeeType,
  TApiFeeType,
} from "@common/pages/settings/lookups/fee-types/model";
import { CancelToken } from "axios";

export const getFeeTypeById = async (
  FeeTypeId: number,
  cancelToken: CancelToken
): Promise<APIResponse<TApiFeeType["Value"]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<TApiFeeType["Value"]>(
      apiCoreGetFeeType(FeeTypeId),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getFeeTypeLOVs = async (
  cancelToken: CancelToken
): Promise<APIResponse<TApiFeeType["Lovs"]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<TApiFeeType["Lovs"]>(
      apiCoreGetFeeTypeLovs(),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSaveFeeTypes = async (
  feeType: FeeType
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveFeeType(),
      feeType
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
