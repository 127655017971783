import { odataCoreGetAttachmentRecordsViews } from "@app/core/components/common/utils";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { RecordsViewsHistoryTab } from "@common/pages/settings/records/components/reference-sidebar/history/_index";
import { colRecords } from "@common/pages/settings/records/documents/config";
import { DocumentsViewsBookmark } from "@common/pages/settings/records/documents/util";
import { Svc_RecordsView } from "@common/pages/settings/records/model";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_RecordsView>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.RECORDS_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Attachment}
        listViewDetail={DocumentsViewsBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          DocumentsViewsBookmark.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <RecordsViewsHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colRecords}
      primaryField={nameOf("ID")}
      dataUrl={odataCoreGetAttachmentRecordsViews(false)}
      state={{ sort: [{ field: nameOf("CreatedDate"), dir: "desc" }] }}
      disabled={!hasPermission}
    />
  );
};
