import { eventEmitter } from "@/App";
import { postCancelJournal } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/cancel-journal/api";
import { useJournalStore } from "@app/products/property/journals/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ICancelJournalButtonProps {
  disabled?: boolean;
}

export const CancelJournalButton = observer(
  ({ disabled = false }: ICancelJournalButtonProps) => {
    const [isShowConfirmDialog, setIsShowConfirmDilog] =
      useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();
    const { reLoadJournal, journalId } = useJournalStore();
    const { lastSelectedId } = useCCProductListViewStore();
    const handleCancelJournal = async () => {
      setIsLoading(true);
      const cancelId = journalId ?? lastSelectedId;
      const response = await postCancelJournal(cancelId);
      setIsLoading(false);
      setIsShowConfirmDilog(false);
      if (response?.data?.IsSuccess) {
        reLoadJournal().then(() => {
          pushNotification({
            type: "success",
            title:
              response?.data?.SuccessMessage ??
              "Journal cancelled successfully",
          });
          eventEmitter.emit(CCGridEventType.RefreshOData);
        });
      } else {
        pushNotification({
          type: "error",
          title: response?.data?.ErrorMessage ?? "Cancel journal failed",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Cancel"
          disabled={disabled}
          onClick={() => setIsShowConfirmDilog(true)}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage={"Are you sure you wish to cancel this journal?"}
            isLoadingYes={isLoading}
            onClosePopup={() => setIsShowConfirmDilog(false)}
            onAsyncConfirm={() => {
              return handleCancelJournal();
            }}
          />
        )}
      </>
    );
  }
);
