import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import {
  listTypeHideFinancialAreas,
  listTypeHideLevyBalances,
  listTypeHideUtilityAgent,
  listTypeShowAssessmentBalance,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/config";
import { useNoticeRunOptionStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/options/store";
import { isShowWithNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/utils";
import { nameOfLov } from "@app/products/property/model";
import { isFieldVisible } from "@app/products/property/util";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import {
  ComboBoxChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";

export const OptionsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    //store
    const { optionLOVs } = useNoticeRunOptionStepStore();
    const { dataSelectedNoticeType } = useNoticeRunNoticeTypeStepStore();
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const isShowIncludeIfNotInChargeRun = useMemo(() => {
      return isFieldVisible(dataSelectedNoticeType?.ChargeRunVisibility);
    }, [dataSelectedNoticeType?.ChargeRunVisibility]);

    const financialAreaLov = useMemo(() => {
      return optionLOVs?.FinancialAreas ?? [];
    }, [optionLOVs?.FinancialAreas]);

    const noticeOrderLov = useMemo(() => {
      return optionLOVs?.NoticeOrdering ?? [];
    }, [optionLOVs?.NoticeOrdering]);

    const documentTemplateLov = useMemo(() => {
      return optionLOVs?.DocumentTemplates ?? [];
    }, [optionLOVs?.DocumentTemplates]);

    const isShowHeader = !(
      isShowWithNoticeType(listTypeHideLevyBalances, valueGetter) &&
      isShowWithNoticeType(listTypeHideUtilityAgent, valueGetter) &&
      !isShowWithNoticeType(listTypeShowAssessmentBalance, valueGetter) &&
      isShowWithNoticeType(listTypeHideFinancialAreas, valueGetter)
    );

    return (
      <div className="cc-form">
        <CCLocalNotification ref={notificationRef} />
        <div className="cc-field-group">
          <div className="cc-field">
            {isShowHeader && (
              <label className="cc-label">Balances to include</label>
            )}
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-1">
                {!isShowWithNoticeType(
                  listTypeHideLevyBalances,
                  valueGetter
                ) && (
                  <div className="cc-form-cols-2">
                    <div className="cc-field">
                      <label className="cc-label">
                        Include levy balances on notice
                      </label>
                      <Field
                        name={nameOf("IncludeBalances")}
                        checked={getFieldValue("IncludeBalances")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  </div>
                )}
                <div className="cc-form-cols-1">
                  {!isShowWithNoticeType(
                    listTypeHideUtilityAgent,
                    valueGetter
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label">Use utility agent</label>
                      <Field
                        name={nameOf("UseUtilityAgent")}
                        checked={getFieldValue("UseUtilityAgent")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  )}
                  {isShowIncludeIfNotInChargeRun &&
                  isShowWithNoticeType(
                    listTypeShowAssessmentBalance,
                    valueGetter
                  ) ? (
                    <div className="cc-field">
                      <label className="cc-label">
                        Include assessments with balances but is not in the
                        charge run
                      </label>
                      <Field
                        name={nameOf("IncludeIfNotInChargeRun")}
                        checked={getFieldValue("IncludeIfNotInChargeRun")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  ) : null}
                </div>
                {!isShowWithNoticeType(
                  listTypeHideFinancialAreas,
                  valueGetter
                ) && (
                  <div className="cc-field">
                    <label className="cc-label">Financial areas</label>
                    <Field
                      name={nameOf("FinancialAreas")}
                      component={CCMultiSelectDropdown}
                      data={financialAreaLov ?? []}
                      onChange={(event: MultiSelectChangeEvent) => {
                        let value = event?.value;
                        if (value?.length) {
                          value = value.map(
                            (item: DTO_LOV_Number) => item.Code
                          );
                        }
                        onChange(nameOf("FinancialAreas"), {
                          value: value,
                        });
                      }}
                      value={getMultipleSelectValue(
                        getFieldValue("FinancialAreas"),
                        financialAreaLov ?? [],
                        "Code"
                      )}
                      textField="Name"
                      dataItemKey="Code"
                      disabled={options?.isReadOnly}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Notice</label>
            <div className="cc-custom-sub-panel-bar cc-form-cols-1">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Notice order</label>
                  <Field
                    name={nameOf("NoticeOrdering_Id")}
                    component={CCSearchComboBox}
                    data={noticeOrderLov ?? []}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    isUseDefaultOnchange
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Document template" />
                  <Field
                    name={nameOf("DocumentTemplateId")}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={documentTemplateLov}
                    value={getDropdownValue(
                      valueGetter(nameOf("DocumentTemplateId")),
                      documentTemplateLov,
                      nameOfLov("Code")
                    )}
                    component={CCSearchComboBox}
                    onChange={(event: ComboBoxChangeEvent) => {
                      onChange(nameOf("DocumentTemplateId"), {
                        value: event.target.value?.Code ?? null,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
