import { Location } from "history";

// Key for storing the history stack in sessionStorage
const HISTORY_STACK_KEY = "historyStack";

/**
 * Retrieves the history stack from sessionStorage.
 * @param {Location[]} [defaultValue=[]] - The default value to return if no stack is found in sessionStorage.
 * @returns {Location[]} The history stack from sessionStorage or the provided default.
 */
const getHistoryStackFromSession = (
  defaultValue: Location[] = []
): Location[] => {
  const storedStack = sessionStorage.getItem(HISTORY_STACK_KEY);
  return storedStack ? JSON.parse(storedStack) : defaultValue;
};

/**
 * Stores the given history stack in sessionStorage.
 * @param {Location[]} historyStack - The history stack to store in sessionStorage.
 */
const setHistoryStackInSession = (historyStack: Location[]): void => {
  sessionStorage.setItem(HISTORY_STACK_KEY, JSON.stringify(historyStack));
};

// Helper object to manage sessionStorage for history stack
export const HistoryStackHelper = {
  getHistoryStackFromSession,
  setHistoryStackInSession,
};
