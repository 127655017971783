import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { ServiceStandardSubCategory } from "@app/products/crms/system-admin/categories/[id]/model";
import { useCRMSSytemAdminSubCategoryStore } from "@app/products/crms/system-admin/categories/[id]/subcategory/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<ServiceStandardSubCategory>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();

export interface CRMSSystemAdminSubCategoryFormElementProps {
  formRenderProps: FormRenderProps;
}

export const CRMSSystemAdminSubCategoryFormElement = observer(
  ({ formRenderProps }: CRMSSystemAdminSubCategoryFormElementProps) => {
    const { crmsSystemAdminLovs, isDisabled } =
      useCRMSSytemAdminSubCategoryStore();

    return (
      <>
        <FormElement>
          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Category" isMandatory />
                <Field
                  name={nameOf("ServiceStandardCategory_Id")}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  data={crmsSystemAdminLovs?.ServiceStandardCategories ?? []}
                  component={CCSearchComboBox}
                  disabled
                />
              </div>
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Subcategory name" isMandatory />
                <Field
                  name={nameOf("Name")}
                  component={CCInput}
                  placeholder="Subcategory name"
                  validator={requiredValidator}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </FormElement>
      </>
    );
  }
);
