import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { RecordsContentPlus } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/content-plus";
import { RecordsECM } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/ecm";
import { RecordsECMCMIS } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/ecm-cmis";
import { RecordsFileit } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/fileit";
import { RecordsInfoXpert } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/infoXpert";
import { RecordsInternalFile } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/internal-file";
import { RecordsObjective } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/object";
import { RecordsSharePoint } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/share-point";
import { RecordsTrim } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim";
import { RecordsSystemEnum } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const { valueGetter } = formRenderProps;

  //Common field
  const fieldRecordsSystem = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_RecordsSystem
  );
  const fieldRecordLabel = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_RecordsLabel
  );
  const fieldAPIFileIntegrationDetails = getConfigDataField(
    ECorporateSettingsField.Records_ECMCMISAPIFileIntegration
  );
  const fieldRecordsSaveInRecordsSystemOnly = getConfigDataField(
    ECorporateSettingsField.Records_SaveDocumentOnlyInRecordsSystem
  );
  const fieldRecordsTabTableAvailable = getConfigDataField(
    ECorporateSettingsField.Records_TabTableAvailable
  );
  const fieldRecordsEnableLazyLoading = getConfigDataField(
    ECorporateSettingsField.Records_EnableLazyLoading
  );
  const fieldRecordsSetFileNoWithPropertyNo = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_RecordsSetFileNoWithPropertyNo
  );
  const fieldRecordsSetFileNoWithAppRef = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_RecordsSetFileNoWithAppRef
  );
  const fieldFileNoMask = getConfigDataField(
    ECorporateSettingsField.Records_PropertyNoMask
  );

  // check sys recordType
  const isRecordsSysNone =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") === RecordsSystemEnum.None;
  const isRecordsSysTRIM =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") === RecordsSystemEnum.TRIM;
  const isRecordsSysInfoXpert =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") ===
    RecordsSystemEnum.infoXpert;
  const isRecordsSysECM =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") === RecordsSystemEnum.ECM;
  const isRecordsSysContentPlus =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") ===
    RecordsSystemEnum.ContentPlus;
  const isRecordsSysObjective =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") ===
    RecordsSystemEnum.Objective;
  const isRecordsSysCMIS =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") === RecordsSystemEnum.CMIS;
  const isRecordsSysSharePoint =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") ===
    RecordsSystemEnum.SharePoint;
  const isRecordsSysInternalFile =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") ===
    RecordsSystemEnum.InternalFile;
  const isRecordsSysFileit =
    valueGetter(fieldRecordsSystem?.FieldName ?? "") ===
    RecordsSystemEnum.Fileit;

  const isShowEnableLazyLoading = () => {
    if (
      (isRecordsSysTRIM || isRecordsSysInfoXpert || isRecordsSysSharePoint) &&
      valueGetter(fieldRecordsTabTableAvailable?.FieldName ?? "")
    )
      return true;
    return false;
  };

  const isShowFilemask = () => {
    if (
      isRecordsSysSharePoint ||
      isRecordsSysInternalFile ||
      isRecordsSysFileit
    )
      return false;
    return true;
  };

  return (
    <>
      <section className="cc-field-group">
        {fieldRecordsSystem && fieldRecordsSystem?.FieldName && (
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={fieldRecordsSystem}
              isEditing={isEditing}
            />
          </div>
        )}

        {!isRecordsSysNone && (
          <>
            {fieldRecordLabel && isRecordsSysTRIM && (
              <div className="cc-form-cols-1">
                <InputGlobalSettings
                  data={fieldRecordLabel}
                  isEditing={isEditing}
                />
              </div>
            )}

            <div className="cc-form-cols-3">
              {fieldAPIFileIntegrationDetails && isRecordsSysCMIS && (
                <SwitchGlobalSettings
                  data={fieldAPIFileIntegrationDetails}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              )}
              {fieldRecordsSaveInRecordsSystemOnly &&
                !isRecordsSysInternalFile && (
                  <SwitchGlobalSettings
                    data={fieldRecordsSaveInRecordsSystemOnly}
                    disabled={valueGetter(
                      fieldAPIFileIntegrationDetails?.FieldName ?? ""
                    )}
                    customChecked={
                      valueGetter(
                        fieldAPIFileIntegrationDetails?.FieldName ?? ""
                      )
                        ? false
                        : valueGetter(
                            fieldRecordsSaveInRecordsSystemOnly?.FieldName
                          )
                    }
                    isEditing={isEditing}
                    formRenderProps={formRenderProps}
                  />
                )}
              {fieldRecordsTabTableAvailable && !isRecordsSysInternalFile && (
                <SwitchGlobalSettings
                  data={fieldRecordsTabTableAvailable}
                  disabled={valueGetter(
                    fieldAPIFileIntegrationDetails?.FieldName ?? ""
                  )}
                  customChecked={
                    valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "")
                      ? true
                      : valueGetter(fieldRecordsTabTableAvailable?.FieldName)
                  }
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              )}

              {fieldRecordsEnableLazyLoading && isShowEnableLazyLoading() && (
                <SwitchGlobalSettings
                  data={fieldRecordsEnableLazyLoading}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              )}
              {fieldRecordsSetFileNoWithPropertyNo && isShowFilemask() && (
                <SwitchGlobalSettings
                  data={fieldRecordsSetFileNoWithPropertyNo}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              )}
              {fieldRecordsSetFileNoWithAppRef && isShowFilemask() && (
                <SwitchGlobalSettings
                  data={fieldRecordsSetFileNoWithAppRef}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              )}
            </div>

            {fieldFileNoMask && isShowFilemask() && (
              <div className="cc-form-cols-1">
                <InputGlobalSettings
                  data={fieldFileNoMask}
                  isEditing={isEditing}
                />
              </div>
            )}
          </>
        )}

        {isRecordsSysTRIM && <RecordsTrim formRenderProps={formRenderProps} />}
        {isRecordsSysInfoXpert && (
          <RecordsInfoXpert formRenderProps={formRenderProps} />
        )}
        {isRecordsSysContentPlus && <RecordsContentPlus />}
        {isRecordsSysECM && <RecordsECM />}
        {isRecordsSysCMIS && (
          <RecordsECMCMIS formRenderProps={formRenderProps} />
        )}
        {isRecordsSysObjective && (
          <RecordsObjective formRenderProps={formRenderProps} />
        )}
        {isRecordsSysSharePoint && (
          <RecordsSharePoint formRenderProps={formRenderProps} />
        )}
        {isRecordsSysInternalFile && (
          <RecordsInternalFile formRenderProps={formRenderProps} />
        )}
        {isRecordsSysFileit && (
          <RecordsFileit formRenderProps={formRenderProps} />
        )}
      </section>
    </>
  );
});
