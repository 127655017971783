import { getInfoXpertRecordTypes } from "@app/core/infoExpert/container/api";
import { TrimRecordTypeInputPicker } from "@app/core/trim/trim-record-type/_index";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { getInfoExpertRecordTypeInputPickerOption } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";

import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import {
  Svc_AttachmentRecordType,
  Svc_InfoExpertContainer,
  Svc_InfoExpertRecordType,
} from "@common/models/corporateSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
const nameOfInfoXpertRecordType = nameOfFactory<Svc_InfoExpertContainer>();
export const RecordsAttachmentTitleFormatForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { valueGetter, onChange } = formRenderProps;

    if (!configData) return <></>;

    const selectedTrimRecordTypeRows = valueGetter(
      `option.ValueDisplayDetails_${
        configData[ECorporateSettingsField.TP_TRIMRecords_AttachmentRecordType]
          ?.FieldName
      }`
    );
    const selectedInfoExpertRecordTypeRows = valueGetter(
      `option.ValueDisplayDetails_${
        configData[
          ECorporateSettingsField.TP_infoXpertRecords_AttachmentRecordType
        ]?.FieldName
      }`
    );
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .CorporateSettings_PropertiesAssessmentNumberColumnName
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_TP_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.IntegrationCMS_TP_AmendmentDescription
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.IntegrationCMS_SubDiv_Description
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.IntegrationCMS_Enquiry_Description
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_NC_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_SC_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_EOT_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_SC_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField
                  .IntegrationCMS_TP_PlansToComplyDescription
              ]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_PSA_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={
              configData[ECorporateSettingsField.IntegrationCMS_OA_Description]
            }
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={configData[ECorporateSettingsField.ecm_TP_Classification]}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={configData[ECorporateSettingsField.ecm_TP_Subject]}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={configData[ECorporateSettingsField.ecm_TP_Customer]}
            isEditing={isEditing}
          />
          <InputGlobalSettings
            data={configData[ECorporateSettingsField.ecmCMIS_TP_PropertyIndex]}
            isEditing={isEditing}
          />
          {configData[
            ECorporateSettingsField.TP_TRIMRecords_AttachmentRecordType
          ] && (
            <div className="cc-field">
              <label className="cc-label">
                {
                  configData[
                    ECorporateSettingsField.TP_TRIMRecords_AttachmentRecordType
                  ].Title
                }
                {configData[
                  ECorporateSettingsField.TP_TRIMRecords_AttachmentRecordType
                ].IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${
                  configData[
                    ECorporateSettingsField.TP_TRIMRecords_AttachmentRecordType
                  ].FieldName
                }`}
                component={TrimRecordTypeInputPicker}
                disabled={!isEditing}
                validator={
                  configData[
                    ECorporateSettingsField.TP_TRIMRecords_AttachmentRecordType
                  ].IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: Svc_AttachmentRecordType[] | null) => {
                  if (
                    configData[
                      ECorporateSettingsField
                        .TP_TRIMRecords_AttachmentRecordType
                    ]
                  ) {
                    onChange(
                      configData[
                        ECorporateSettingsField
                          .TP_TRIMRecords_AttachmentRecordType
                      ].FieldName,
                      {
                        value: value?.[0].ID,
                      }
                    );
                    onChange(
                      `option.ValueDisplayDetails_${
                        configData[
                          ECorporateSettingsField
                            .TP_TRIMRecords_AttachmentRecordType
                        ].FieldName
                      }`,
                      {
                        value,
                      }
                    );
                  }
                }}
                selectedRows={selectedTrimRecordTypeRows}
              />
            </div>
          )}
          {configData[
            ECorporateSettingsField.TP_infoXpertRecords_AttachmentRecordType
          ] && (
            <div className="cc-field">
              <label className="cc-label">
                {
                  configData[
                    ECorporateSettingsField
                      .TP_infoXpertRecords_AttachmentRecordType
                  ].Title
                }
                {configData[
                  ECorporateSettingsField
                    .TP_infoXpertRecords_AttachmentRecordType
                ].IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
              </label>
              <Field
                name={`option.ValueDisplayDetails_${
                  configData[
                    ECorporateSettingsField
                      .TP_infoXpertRecords_AttachmentRecordType
                  ].FieldName
                }`}
                component={InputPickerSearch}
                disabled={!isEditing}
                validator={
                  configData[
                    ECorporateSettingsField
                      .TP_infoXpertRecords_AttachmentRecordType
                  ].IsMandatory
                    ? requiredValidator
                    : undefined
                }
                onChange={(value: Svc_InfoExpertRecordType[] | null) => {
                  if (
                    configData[
                      ECorporateSettingsField
                        .TP_infoXpertRecords_AttachmentRecordType
                    ]
                  ) {
                    onChange(
                      configData[
                        ECorporateSettingsField
                          .TP_infoXpertRecords_AttachmentRecordType
                      ].FieldName,
                      {
                        value: value?.[0].ID,
                      }
                    );
                    onChange(
                      `option.ValueDisplayDetails_${
                        configData[
                          ECorporateSettingsField
                            .TP_infoXpertRecords_AttachmentRecordType
                        ].FieldName
                      }`,
                      {
                        value,
                      }
                    );
                  }
                }}
                nameDisplayMerge={nameOfInfoXpertRecordType("Name")}
                options={getInfoExpertRecordTypeInputPickerOption(undefined, {
                  selectableMode: "single",
                  selectedRows: selectedInfoExpertRecordTypeRows,
                })}
                localSearchOptions={{
                  enableLocalSearch: true,
                  searchingPropKey: nameOfInfoXpertRecordType("Name"),
                }}
                customGetData={async () => await getInfoXpertRecordTypes()}
                showClearButton={true}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
