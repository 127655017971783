import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { colCertificateRequest, colListOfResponses } from "./config";
import { mockCertificateRequest, mockListOfResponses } from "./mock";

const RequestResponse = () => {
  return (
    <div className="cc-e-certificate-request-response">
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                List of responses sent to the requestor
              </label>
              <CCGrid
                className="cc-list-of-responses-grid-data"
                data={mockListOfResponses}
                columnFields={colListOfResponses}
                primaryField="InstalmentNumber"
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Messages related to certificate request processing
              </label>
              <CCGrid
                className="cc-certificate-request-grid-data"
                data={mockCertificateRequest}
                columnFields={colCertificateRequest}
                primaryField="Id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestResponse;
