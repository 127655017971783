import { CORE_MAILMERGE_DOCUMENTS_ODATA } from "@app/core/components/common/constant";
import { apiCoreDocumentById } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { isEmpty } from "lodash";
import { configure } from "mobx";

configure({ enforceActions: "always" });

export const getMailMergeDocumentTemplate = async (
  id: number,
  productType: PRODUCT_TYPE
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(apiCoreDocumentById(id), {
      params: {
        ProductType: productType,
      },
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getMailMergeDocuments = async (
  key: string,
  productType: PRODUCT_TYPE,
  documentIds?: number[]
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(
      CORE_MAILMERGE_DOCUMENTS_ODATA,
      {
        params: !isEmpty(documentIds)
          ? {
              key: key,
              productType: productType,
              documentIds: documentIds?.toString(),
            }
          : {
              key: key,
              productType: productType,
            },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
