import { getDataJournalTransaction } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/api";
import { DTO_Transaction } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ModifyTransactionStore {
  private _transaction?: DTO_Transaction = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _assessRebateEntitlementsLOVs?: DTO_LOV[] = undefined;
  private _isRebateTypeTransactionLOVs: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get journalTransaction() {
    return this._transaction;
  }
  setJournalTransaction = (transaction?: DTO_Transaction | undefined) => {
    runInAction(() => {
      this._transaction = transaction;
    });
  };

  get assessRebateEntitlementsLOVs() {
    return this._assessRebateEntitlementsLOVs;
  }
  setAssessRebateEntitlementsLOVs = (
    _assessRebateEntitlementsLOVs?: DTO_LOV[] | undefined
  ) => {
    runInAction(() => {
      this._assessRebateEntitlementsLOVs = _assessRebateEntitlementsLOVs;
    });
  };

  get isRebateTypeTransactionLOVs() {
    return this._isRebateTypeTransactionLOVs;
  }
  setIsRebateTypeTransactionLOVs = (_isRebateTypeTransactionLOVs: boolean) => {
    runInAction(() => {
      this._isRebateTypeTransactionLOVs = _isRebateTypeTransactionLOVs;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._transaction = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  loadJournalTransaction = async (journalId: number, transactionId: number) => {
    this.setIsLoading(true);
    const response = await getDataJournalTransaction(journalId, transactionId);
    let errorResponse, newJournalTransaction;
    if (isSuccessResponse(response)) {
      newJournalTransaction = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setJournalTransaction(
      newJournalTransaction?.TransactionGrouping?.Transactions?.[0]
    );
    this.setIsLoading(false);
  };
}

const journalStoreContext = createContext(new ModifyTransactionStore());
export const useModifyTransactionStore = () => {
  return useContext(journalStoreContext);
};
