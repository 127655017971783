import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Component_Contacts } from "@app/products/property/components/reference-sidebar/contact/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getContact = async (
  id: number | string | undefined,
  componentNumber: eComponent
): Promise<APIResponse<DTO_Component_Contacts | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/tab/contact/${componentNumber}/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
