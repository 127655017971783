import { IAlert } from "@app/core/contacts/components/accordions/alerts/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class AlertsAccordionStore {
  private _alertSelectedRows?: IAlert[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get alertSelectedRows() {
    return this._alertSelectedRows;
  }
  setAlertSelectedRows = (alertSelectedRows: IAlert[]) => {
    runInAction(() => {
      this._alertSelectedRows = alertSelectedRows;
    });
  };

  resetStore = () => {
    this._alertSelectedRows = [];
  };
}

export const alertsAccordionStore = new AlertsAccordionStore();
const alertsAccordionStoreContext = createContext(alertsAccordionStore);
export const useAlertsAccordionStore = () => {
  return useContext(alertsAccordionStoreContext);
};
