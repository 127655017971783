import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import {
  formatDisplayValue,
  formatNumberByKendo,
} from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const AnimalTypeDetailsTab = observer(() => {
  const { lastSelectedId } = useCCProductListViewStore();
  const {
    loadAnimalTypeDetails,
    isLoadingDetails,
    animalTypeDetails,
    responseLoadError,
  } = useAnimalTypeStore();

  const params: { id: string } = useParams();
  const animalTypeID = params.id ?? lastSelectedId;
  useEffect(() => {
    if (animalTypeID) {
      loadAnimalTypeDetails(parseInt(animalTypeID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animalTypeID]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          animalTypeID && loadAnimalTypeDetails(+animalTypeID);
        }}
      />
    );
  }
  if (!animalTypeDetails) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Animal type ID:"}
        value={animalTypeDetails.AnimalType_ID?.toString()}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow title={"Name:"} value={animalTypeDetails.Name} />
        <ProductReferenceRow
          title={"Max number:"}
          value={formatNumberByKendo(
            animalTypeDetails.MaxNoOfAnimals,
            NUMBER_FORMAT.NUMBER2
          )}
        />
        <ProductReferenceRow
          title={"Max number(large):"}
          value={formatNumberByKendo(
            animalTypeDetails.MaxNoOfAnimals_Large,
            NUMBER_FORMAT.NUMBER2
          )}
        />
        <ProductReferenceRow
          title={"Minimum amount:"}
          value={formatDisplayValue(
            animalTypeDetails.Fee_MinAmount,
            CURRENCY_FORMAT.CURRENCY1
          )}
        />
        <ProductReferenceRow
          title={"Health care card discount:"}
          value={formatDisplayValue(
            animalTypeDetails.Fee_HealthCareCardDiscount,
            PERCENTAGE_FORMAT.PERCENTAGE
          )}
        />
        <ProductReferenceRow
          title={"Transfer fee:"}
          value={formatDisplayValue(
            animalTypeDetails.Fee_TransferAmount,
            CURRENCY_FORMAT.CURRENCY1
          )}
        />
        <ProductReferenceRow
          title={"Replacement tag fee:"}
          value={formatDisplayValue(
            animalTypeDetails.Fee_ReplacementTagAmount,
            CURRENCY_FORMAT.CURRENCY1
          )}
        />
        <ProductReferenceRow
          title={"Recorded on:"}
          value={formatDisplayValue(
            animalTypeDetails.Sys_CreatedDate,
            DATE_FORMAT.DATE
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
