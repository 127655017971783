import { Region } from "@app/products/town-planning/ppr/[id]/model";

export const RegionForm: Partial<Region> = {
  Region_ID: 0,
  Region_Name: "",
  Suburb: "",
  Council_ID: 0,
  Council_Name: "",
  PostCode: "",
  State: "",
  State_Code: "",
  Coordinator_ID: null,
};
