import { history } from "@/AppRoutes";
import { getAnimalById } from "@app/products/animals/[id]/api";
import { Svc_Registration } from "@app/products/animals/[id]/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  checkIsSuperAdmin,
  getPermitFormById,
  getPermitFormLOVs,
  postPermitFormHandler,
} from "@app/products/local-laws/[id]/api";
import { ExistManagePermit } from "@app/products/local-laws/[id]/existed/_index";
import {
  ApiPermitTransferData,
  IHandlerEventInitialData,
  IParentPermitSection,
  Permit,
  PermitRegisterHandlerRequest,
  Svc_FormAction_PermitRegister,
  Svc_Permit,
  TApiIsSuperAdminResponse,
  TApiLoadPermitByIdResponse,
  TApiLoadPermitLovsResponse,
  TApiPermitHandlerResponse,
  TApiPermitResponse,
} from "@app/products/local-laws/[id]/model";
import { NewManagePermit } from "@app/products/local-laws/[id]/new/_index";
import {
  getAlerts,
  processPushNotificationPortal,
} from "@app/products/local-laws/[id]/util";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { useRouteDataStore } from "@common/stores/route/store";
import {
  CCCheckPermissionWrapper,
  TPermissionCheck,
} from "@components/cc-check-permission-wrapper/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { INotificationPortalContentExcludeId } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { size } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePermit = () => {
  //#region STORE ========/
  const { currentUserInfo } = useGlobalStore();
  const { resetMenu } = useCCSubActionBarStore();
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
    notificationsOtherPage,
  } = useNotificationPortalStore();
  const { isLoadingFor } = useRouteDataStore();
  const {
    setInitialDataForms,
    isLoadingForm,
    dataForms,
    syncFormWithStore,
    setMiddlewareSubmitFormOptions,
  } = useFlexibleFormStore();
  const { state } = useLocation<{
    parent?: IParentPermitSection;
  }>();

  const permitFromStore = dataForms?.GeneralForm as Svc_Permit;
  useEffectOnce(() => {
    return () => {
      clearNotifications();
    };
  });
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region STATE ========/
  const [hasPermission, setHasPermission] = React.useState<boolean>(false);
  //#endregion STATE =====/

  //#region Variable ========/
  const titleManagePage = "Permit";
  const { id } = useParams<{ id: string }>();
  const permitFormId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  const currentUserID = currentUserInfo?.UserPKID;
  //#endregion Variable =====/

  const managePermitSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => [
          getPermitFormById(+permitFormId, cancelToken()),
          getPermitFormLOVs(cancelToken()),
          checkIsSuperAdmin(currentUserID ? +currentUserID : 0, cancelToken()),
        ],
        extractParamForNextAPI: ({ dataFromApi }) => {
          const response: TApiPermitResponse = dataFromApi;
          const [_permitResponse] = response;
          const notifications = _permitResponse?.data?.Notifications;

          if (isNew && state?.parent?.isSameDetails && state?.parent?.data) {
            let parentData = state.parent.data as Permit;
            let processPermit = _permitResponse?.data?.ReturnObj.Permit;

            processPermit.Contact = parentData?.Contact;
            processPermit.Contact.RID = parentData?.Contact?.RID ?? 0;

            processPermit.Address = parentData?.Address;
            processPermit.Address.Address_ID =
              parentData?.Address?.Address_ID ?? 0;
            processPermit.DebtorNumber = parentData?.DebtorNumber;

            if (state?.parent) {
              window.history.replaceState({}, "");
            }
          }

          return {
            notifications: notifications,
            permitFormData: _permitResponse?.data?.ReturnObj,
          } as ApiPermitTransferData;
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: TApiPermitResponse = dataFromApi;
          const [_permitResponse, _permitLOVsResponse, _isSuperAdminResponse]: [
            TApiLoadPermitByIdResponse,
            TApiLoadPermitLovsResponse,
            TApiIsSuperAdminResponse
          ] = response;
          const _permitFormData = _permitResponse.data?.ReturnObj;
          const _permitLOVs = _permitLOVsResponse?.data?.ReturnObj;
          const _isSuperAdmin = _isSuperAdminResponse?.data;

          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: _permitFormData,
            GeneralFormLovs: _permitLOVs,
            IsSuperAdmin: _isSuperAdmin,
            ParentSection: state?.parent as IParentPermitSection,
          });
          //#endregion save data to store =====/
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: errorFromApi.error,
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
      {
        fetch: () => {
          return getAnimalById(state?.parent?.animalID!);
        },
        handleSuccess: () => {
          if (state?.parent) {
            window.history.replaceState({}, "");
          }
        },
        extractParamForNextAPI: ({ dataFromApi, dataFromExtractParam }) => {
          const { permitFormData }: ApiPermitTransferData =
            dataFromExtractParam;
          const response: APIResponse<IIdentityPacket<Svc_Registration>> =
            dataFromApi;

          const objAnimalRegistration = response.data?.ReturnObj.Registration;
          permitFormData.Permit.Contact = objAnimalRegistration.Contact;
          permitFormData.Permit.Contact.RID =
            objAnimalRegistration.Contact?.RID ?? 0;
          permitFormData.Permit.DebtorNumber =
            objAnimalRegistration.DebtorNumber;
          permitFormData.Permit.Address = objAnimalRegistration.Address;
          permitFormData.Permit.Address.Address_ID =
            objAnimalRegistration.Address?.Address_ID ?? 0;

          return dataFromExtractParam as ApiPermitTransferData;
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: errorFromApi.error,
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const { permitFormData }: ApiPermitTransferData =
            dataFromExtractParam;
          const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
            FormAction: Svc_FormAction_PermitRegister.SystemInitialise,
            Permit: permitFormData.Permit,
            PermitRegisterArgs: null,
            IsFirstTimeLoad: true,
          };

          return postPermitFormHandler(
            permitRegisterHandlerRequest,
            cancelToken()
          );
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          listenNotificationPortal();
          const response: TApiPermitHandlerResponse = dataFromApi;
          const { notifications, permitFormData } = dataFromExtractParam;
          const _permitObj = response.data?.ReturnObj.Permit;
          const _uiControl = response.data?.ReturnObj.UIControl;

          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: { ...permitFormData, Permit: _permitObj },
            GeneralUIControl: _uiControl,
          });
          //#endregion save data to store =====/

          // Push alert
          if (size(notificationsOtherPage) === 0) {
            // there is no notification from other page => clear all notification
            clearNotifications();
          }
          const alerts = getAlerts(_permitObj);
          processPushNotificationPortal(
            alerts,
            (alert: INotificationPortalContentExcludeId) =>
              pushNotificationPortal(alert)
          );

          // Push notification
          processPushNotificationPortal(
            notifications,
            (notification: INotificationPortalContentExcludeId) =>
              pushNotificationPortal({ type: "info", title: notification })
          );
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: errorFromApi.error,
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
    ],
  });

  const permitHandlerSlider = useFlexibleFetchData({
    beforeFetch: () => {
      syncFormWithStore("GeneralForm");
    },
    alias: "permitHandlerSlider",
    slides: [
      {
        fetch: ({ initialData }) => {
          const handlerInitData: IHandlerEventInitialData = initialData;
          return postPermitFormHandler(
            handlerInitData?.permitRegisterHandlerRequest,
            cancelToken()
          );
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: TApiPermitHandlerResponse = dataFromApi;
          const _permitObj = response.data?.ReturnObj.Permit;
          const _uiControl = response.data?.ReturnObj.UIControl;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: { ...permitFromStore, Permit: _permitObj },
            GeneralUIControl: _uiControl,
          });
          setMiddlewareSubmitFormOptions({ skipCheckModified: true });
          //#endregion save data to store =====/
        },
        handleError: ({ errorFromApi, initialData }) => {
          pushNotificationPortal({
            title: initialData?.errorMsg,
            type: "error",
            description: errorFromApi.error,
            autoClose: false,
          });
        },
      },
    ],
  });

  const checkPermissionForPermit: TPermissionCheck = {
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
    formAction: FormAction.CORE_ALLOW_NEW,
  };

  useEffect(() => {
    if (!isNew || (isNew && hasPermission)) {
      if (state?.parent?.animalID) {
        managePermitSlider.fetchApi();
      } else {
        managePermitSlider.fetchApi({ skipSlide: 2 });
      }
    }
    return () => {
      resetMenu();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasPermission]);

  return (
    <>
      <Loading
        isLoading={
          isLoadingForm ||
          !managePermitSlider.isDone ||
          permitHandlerSlider.isFetching ||
          isLoadingFor(EWatchStatusLoading.IsLoadingPermission)
        }
        isFullScreen
      />
      <FormNavigation title={titleManagePage} />
      {managePermitSlider.errorComponent ? (
        managePermitSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrapper
          permission={checkPermissionForPermit}
          onChangeHasPermission={() => setHasPermission(true)}
        >
          <NewManagePermit />
        </CCCheckPermissionWrapper>
      ) : (
        <ExistManagePermit />
      )}
    </>
  );
};

export default observer(ManagePermit);
