import { RequestObjAddressStreet } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Address } from "@common/input-pickers/address/model";
import { CancelTokenSource } from "axios";

export const getPropertiesAddressList = async (
  searchText: string,
  requestController?: CancelTokenSource
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      `odata/core/internal/addresses/GetPropertiesAddressList(searchText='${searchText}')`,
      {
        cancelToken: requestController?.token,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPropertyAddress = async (
  addressId: string
): Promise<APIResponse<Address | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Address>(
      `api/core/internal/property-address/${addressId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSelectStreet = async (
  listStreetId: RequestObjAddressStreet
): Promise<APIResponse<Address | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<Address>(
      "/api/core/internal/get-address-street",
      listStreetId
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
