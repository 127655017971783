import { useModifyMasterPropertyDialogStore } from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/store";
import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

export const UnitTypeCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const { masterPropertyLOVs } = useModifyMasterPropertyDialogStore();
  return (
    <DropDownList
      textField="Name"
      dataItemKey="Code"
      data={masterPropertyLOVs?.PropertyType ?? []}
      value={getDropdownValue(
        value,
        masterPropertyLOVs?.PropertyType ?? [],
        "Name"
      )}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value.Name,
          });
        }
      }}
    />
  );
};
