import { Svc_Permit_Batch_DateFromDateTo } from "@app/products/local-laws/[id]/model";
import { postBatchRenewPermit } from "@app/products/local-laws/components/toolbar/buttons/batch-renew/api";
import { APIResponse } from "@common/apis/model";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import {
  DateFromDateTo,
  DateToFromDialogPlaceID,
} from "@common/dialog/date-to-from/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { IdentityPacket } from "@common/models/identityPacket";
import { IdentityPacketErrorStatusNumber } from "@common/models/sysEnumerations";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { SelectTypeEnum } from "@components/cc-grid/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IBatchRenewPermitButtonProps {
  isDisabled?: boolean;
  gridId?: string;
}

export const BatchRenewPermitButton = observer(
  ({ isDisabled = false, gridId }: IBatchRenewPermitButtonProps) => {
    //#region STORE ========/
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { gridSelectedIds } = useCCProductListViewStore();
    const { loader } = useSelectionListener(gridId);
    //#endregion STORE =====/

    //#region STATES ========/
    const [objEffectDateIssue, setObjEffectDateIssue] =
      useState<DateFromDateTo>();
    const [isShowBatchRenew, setIsShowBatchRenew] = useState<boolean>(false);
    //#endregion STATES =====/

    //#region METHOD ========/
    const objBatchRenew = () => {
      const objReason = new DateFromDateTo();
      objReason.Title_Text = "Issue Renewal";
      objReason.DateLabelFrom_Text = "Registration from";
      objReason.DateLabelFrom_To = "Registration to";
      objReason.DateFrom = new Date(new Date().getFullYear(), 0, 1);
      objReason.DateTo = new Date(new Date().getFullYear(), 11, 31);

      setObjEffectDateIssue(objReason);
    };

    const isOpenBatchRenewDialog = useMemo(() => {
      setObjEffectDateIssue(undefined);
      if (isShowBatchRenew) objBatchRenew();
      return isShowBatchRenew;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowBatchRenew]);

    const handleBatchRenew = async (dateFromDateTo: DateFromDateTo | null) => {
      let rowIds = gridSelectedIds;
      if (loader.current.peek.type === SelectTypeEnum.ALL) {
        rowIds = (
          await loader.current.process({ $select: ["ID"], $flat: true })
        ).data;
      }

      const responseRequest = {
        SelectedIds: rowIds,
        DateFromDateTo: dateFromDateTo,
      } as Svc_Permit_Batch_DateFromDateTo;

      await batchRenewPermitButtonSlider.fetchApi({
        initialData: responseRequest,
      });
    };

    const handleOnCloseDialog = () => {
      setIsShowBatchRenew(false);
      setObjEffectDateIssue(undefined);
    };

    const batchRenewPermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const batchRenewPermitRequest: Svc_Permit_Batch_DateFromDateTo =
              initialData;
            return postBatchRenewPermit(batchRenewPermitRequest, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IdentityPacket> = dataFromApi;
            if (
              response?.data?.ErrorStatus ===
              IdentityPacketErrorStatusNumber.Notifications
            ) {
              setIsShowBatchRenew(false);
              setObjEffectDateIssue(undefined);

              pushNotificationPortal({
                title: response.data?.Notifications[0],
                type: "info",
              });
            } else {
              pushNotificationPortal({
                placeId: DateToFromDialogPlaceID,
                autoClose: false,
                title: "Renew batch permit failed.",
                type: "error",
                description: response.data?.Errors ?? response.statusText,
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              placeId: DateToFromDialogPlaceID,
              autoClose: false,
              title: "Renew batch permit failed.",
              type: "error",
              description: errorFromApi.error,
            });
          },
        },
      ],
    });
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Batch renew"
          onClick={() => setIsShowBatchRenew(true)}
          disabled={isDisabled || gridSelectedIds?.length === 0}
        />

        {isOpenBatchRenewDialog && (
          <DateToFromDialog
            onClose={handleOnCloseDialog}
            onSubmit={(value: DateFromDateTo) => handleBatchRenew(value)}
            isLoading={batchRenewPermitButtonSlider.isFetching}
            data={objEffectDateIssue}
          />
        )}
      </>
    );
  }
);
