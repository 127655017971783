import { FeeTypeFormElement } from "@common/pages/settings/lookups/fee-types/_id/components/general/components/_index";
import { FeeType } from "@common/pages/settings/lookups/fee-types/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useFlexibleFormState } from "@common/stores/flexible-form/useFlexibleFormState";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const FeeTypeForm = observer(() => {
  //#region STORE
  const { middlewareSubmitForm } = useFlexibleFormStore();
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion STORE

  //#region HOOK
  const { setFormRef, initialDataForm } = useFlexibleFormState();
  // #endregion

  //#region INITIAL_VALUES
  const feeType = initialDataForm as FeeType;
  //#endregion INITIAL_VALUES

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotModified, whenNotValid } = middlewareSubmitForm(event);
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });

    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
  };

  return (
    <div className="cc-form">
      <Form
        ref={setFormRef}
        onSubmitClick={handleOnSubmit}
        initialValues={feeType}
        key={JSON.stringify(feeType)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <FeeTypeFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
