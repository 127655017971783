import { ContactManager_View_ActiveContact } from "@app/core/contacts/active/_filter/model";
import { SiteUserView } from "@common/pages/settings/security/site-users/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfRecipientSiteUser = nameOfFactory<SiteUserView>();
export const colRecipientSiteUser: IColumnFields[] = [
  {
    field: nameOfRecipientSiteUser("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfRecipientSiteUser("UserName"),
    title: "Login Name",
  },
  {
    field: nameOfRecipientSiteUser("Email"),
    title: "Email",
  },
  {
    field: nameOfRecipientSiteUser("Mobile"), // Update API
    title: "Mobile",
  },
];

const nameOfRecipientContact =
  nameOfFactory<ContactManager_View_ActiveContact>();
export const colRecipientContact: IColumnFields[] = [
  {
    field: nameOfRecipientContact("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfRecipientContact("StreetAddress"), // Recheck
    title: "Address",
  },
  {
    field: nameOfRecipientContact("Email"),
    title: "Email",
  },
  {
    field: nameOfRecipientContact("Mobile"),
    title: "Mobile",
  },
];

export const colRecipientResult: IColumnFields[] = [
  {
    field: nameOfRecipientContact("DisplayName"),
    title: "Name",
  },
  {
    field: nameOfRecipientContact("Email"),
    title: "Email",
  },
  {
    field: nameOfRecipientContact("Mobile"),
    title: "Mobile",
  },
];

export const dataSearchByRecipientCommunication = [
  {
    Key: 1,
    Value: "Site User",
  },
  {
    Key: 2,
    Value: "Contact",
  },
];

export const optionsSearchField = [
  {
    label: "All",
    value: 1,
  },
  {
    label: "Name only",
    value: 2,
  },
];

export const configGrid = { minHeight: "255px", maxHeight: "255px" };
