import { putFiNotRequired } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/fi-not-required/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class FINotRequiredButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  fiNotRequired = async (applicationId: number) => {
    this.setIsLoading(true);
    const response = await putFiNotRequired(applicationId);
    if (isSuccessIdentityPacket(response)) {
      appNotificationStore.clearErrorNotification();
      PPRStoreInstance.setPPRWithLoading(response.data?.Application);
      appNotificationStore.pushNotification({
        title: "Record saved successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "FI not required failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsLoading(false);
  };
}
export const fiNotRequiredButtonStore = new FINotRequiredButtonStore();
const fiNotRequiredButtonStoreContext = createContext(fiNotRequiredButtonStore);
export const useFINotRequiredButtonStore = () => {
  return useContext(fiNotRequiredButtonStoreContext);
};
