import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsAssessmentModifyChargeDetail {
  Details = "Details",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsAssessmentModifyChargeDetail = [
  EKeysOfStepsAssessmentModifyChargeDetail.Details,
];

export interface DTO_Workflow_AssessmentModifyChargeDetail {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_AssessmentModifyChargeDetail;
}

export interface DTO_WorkflowDetail_AssessmentModifyChargeDetail {
  ChargesDetails: DTO_Charge_Detail;
}

// Recheck dto after have api
export interface DTO_Charge_Detail {
  Id: number;
  Date: Date | null;
  Name: string;
  Code: string;
  Description: string;
  RatingPeriodId: number;
  InstallmentPlanTypeId: number | null;
}

export interface DTO_Charge_LOVs {
  dtoCreate_charge_LOVs: DTO_AssessmentModifyChargeDetail_LOVs;
}

export interface DTO_AssessmentModifyChargeDetail_LOVs {
  RatingPeriod: DTO_LOV[];
  InstalmentPlan: DTO_LOV[];
}

export interface ModifChargeDetailsRequestObjFromSpatial {
  ChargeId: number;
}
