import { PERMIT_TYPES_URL } from "@app/products/local-laws/system-admin/permit-types/constant";
import { Svc_LLPermitType } from "@app/products/local-laws/system-admin/permit-types/model";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { nameOfFactory } from "@common/utils/common";

const nameOfPermitType = nameOfFactory<Svc_LLPermitType>();

export const optionPermitType: IOptionInputPicker = {
  dialog: {
    titleHeader: "Permit Types",
    maxWidth: "50%",
  },
  boxSearch: {
    colSearch: nameOfPermitType("Name"),
    colShowComboboxSearch: [
      {
        field: nameOfPermitType("Name"),
        title: "Name",
        isGrow: true,
      },
    ],
  },
  grid: {
    columnFields: [{ field: nameOfPermitType("Name"), title: "Name" }],
    primaryField: nameOfPermitType("ID"),
    dataUrl: PERMIT_TYPES_URL,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfPermitType("Name"),
          dir: "asc",
        },
      ],
    },
  },
};
