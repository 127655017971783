import { SVC_FeeCalculator_RegistrationFee_MatchMethod } from "@app/products/animals/model";

export const getRegistrationFeeCalculationMethodFriendlyName = (
  registrationFeeCalculationMethodEnum: SVC_FeeCalculator_RegistrationFee_MatchMethod
) => {
  const registrationFeeCalculationMethod: Record<
    SVC_FeeCalculator_RegistrationFee_MatchMethod,
    string
  > = {
    [SVC_FeeCalculator_RegistrationFee_MatchMethod.FirstMatch]: "First Match",
    [SVC_FeeCalculator_RegistrationFee_MatchMethod.LowestPrice]: "Lowest Price",
    [SVC_FeeCalculator_RegistrationFee_MatchMethod.HighestPrice]:
      "Highest Price",
    [SVC_FeeCalculator_RegistrationFee_MatchMethod.Default]: "",
  };
  return (
    registrationFeeCalculationMethod[registrationFeeCalculationMethodEnum] || ""
  );
};
