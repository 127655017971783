import { odataCoreGetDocuments } from "@app/core/components/common/utils";
import { colCrmsServiceStandard } from "@app/products/crms/components/crms-service-standards/config";
import { ICRMSServiceStandard } from "@app/products/crms/components/crms-service-standards/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface ICRMSServiceStandardProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<ICRMSServiceStandard>();
const CRMSServiceStandard: React.FC<ICRMSServiceStandardProps> = ({
  id,
  recordType,
}) => {
  return (
    <div>
      <CCGrid
        columnFields={colCrmsServiceStandard}
        primaryField={nameOf("ID")}
        dataUrl={
          id === undefined ? undefined : odataCoreGetDocuments(id, recordType)
        }
      />
    </div>
  );
};

export default CRMSServiceStandard;
