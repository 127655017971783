import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colWastewaterComplaintsByStatus: IColumnFields[] = [
  {
    field: WastewaterFieldMapping.RefNo,
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${WASTE_WATER_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: WastewaterFieldMapping.Description,
    title: "Description",
  },
  {
    field: WastewaterFieldMapping.DateTarget,
    title: "Target",
    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.Source,
    title: "Source",
  },
  {
    field: WastewaterFieldMapping.Category,
    title: "Category",
  },
  {
    field: WastewaterFieldMapping.Location,
    title: "Location",
  },
  {
    field: WastewaterFieldMapping.RequestedBy,
    title: "Contact",
  },
  {
    field: WastewaterFieldMapping.ContactDetails,
    title: "Contact Number",
  },
  {
    field: WastewaterFieldMapping.Status,
    title: "Status",
  },
  {
    field: WastewaterFieldMapping.DueStatus,
    title: "Due Status",
  },
  {
    field: WastewaterFieldMapping.DateRecorded,
    title: "Recorded",
    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.RecordedBy,
    title: "Recorded By",
  },
  {
    field: WastewaterFieldMapping.OrgStructure,
    title: "Org Structure",
  },
  {
    field: WastewaterFieldMapping.ActionOfficer,
    title: "Action Officer",
  },
  {
    field: WastewaterFieldMapping.Coordinator,
    title: "Coordinator",
  },
  {
    field: WastewaterFieldMapping.DateWork,
    title: "Work Date",

    format: DATE_FORMAT.DATE,
  },
  {
    field: WastewaterFieldMapping.FileNumber,
    title: "File Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: WastewaterFieldMapping.EnquiryRefNo,
    title: "Assets Reference Number",
  },
  {
    field: WastewaterFieldMapping.Event_ReferenceNoAdditional,
    title: "Additional Reference Number",
  },
  {
    field: WastewaterFieldMapping.OnBehalfOf,
    title: "On Behalf Of",
  },
  {
    field: WastewaterFieldMapping.Flag_ITSupport,
    title: "IT Support",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: WastewaterFieldMapping.Flag_Safety,
    title: "Safety",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: WastewaterFieldMapping.Flag_Risk,
    title: "Risk",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: WastewaterFieldMapping.Flag_VIP,
    title: "VIP",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
