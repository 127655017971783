import { DTO_Entity } from "@app/products/property/components/fields/search-name/model";
import {
  colDuplicateOrganisation,
  colDuplicateContact,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/dialog/duplicate-contact/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep } from "lodash";
import pluralize from "pluralize";
import React, { useMemo } from "react";
import { EntityType } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";

const nameOfGrid = nameOfFactory<DTO_Entity>();

interface IDuplicateContactDialogProps {
  contacts?: DTO_Entity[];
  onClose: () => void;
  onUpdateContact: (entity: DTO_Entity) => void;
  entityType?: number;
}

export const DuplicateContactDialog = ({
  contacts = [],
  onClose,
  onUpdateContact,
  entityType,
}: IDuplicateContactDialogProps) => {
  const columnFields = useMemo(() => {
    const isOrganisationSearch =
      entityType !== EntityType.Person &&
      entityType !== EntityType.DeceasedEstate;
    let newColumns = cloneDeep(
      isOrganisationSearch ? colDuplicateOrganisation : colDuplicateContact
    );
    newColumns[0].handleOnClick = (dataItem) => {
      onUpdateContact(dataItem);
    };
    return newColumns;
  }, [onUpdateContact, entityType]);

  return (
    <CCDialog
      maxWidth="50%"
      height="auto"
      titleHeader={`Possible Duplicate ${pluralize(
        "Contact",
        contacts.length
      )}`}
      onClose={onClose}
      bodyElement={
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <CCGrid
              data={contacts ?? []}
              columnFields={columnFields}
              primaryField={nameOfGrid("Entity_Id")}
            />
          </div>
        </section>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      }
    />
  );
};
