import { apiCoreSearch } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_ApplicationSearch, IResponseProps, ISearchFilter } from "./model";

export const getSearchHomePage = async (
  data: ISearchFilter
): Promise<IResponseProps | undefined> => {
  try {
    const response = await CoreAPIService.getClient().post<IResponseProps>(
      apiCoreSearch(),
      data
    );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const getSearchProperty = async (
  valueSearch: string
): Promise<DTO_ApplicationSearch | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_ApplicationSearch>(
        `/api/property/internal/search`,
        {
          params: {
            SearchText: valueSearch,
          },
        }
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
