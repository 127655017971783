import { INCItem } from "@app/core/nc-items/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<INCItem>();
export const colNCItems: IColumnFields[] = [
  {
    field: nameOf("Location"),
    title: "Location",
  },
  {
    field: nameOf("Summary"),
    title: "Summary",
  },
];
