import { PPRRegion } from "@app/products/town-planning/ppr/system-admin/regions/model";
import { SYSTEM_ADMIN_REGION_ROUTE } from "@app/products/town-planning/ppr/system-admin/regions/[id]/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRRegion>();
export const colPprSystemAdminRegions: IColumnFields[] = [
  {
    field: nameOf("Region_Name"),
    title: "Region",
    locked: true,
    linkTo: (dataItem: PPRRegion) =>
      `${SYSTEM_ADMIN_REGION_ROUTE}/${dataItem.Region_ID}`,
  },
  {
    field: nameOf("Council_Name"),
    title: "Municipality",
  },
  {
    field: nameOf("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOf("Coordinator_DisplayName"),
    title: "Coordinator",
  },
  {
    field: nameOf("State"),
    title: "State",
  },
  {
    field: nameOf("PostCode"),
    title: "Postcode",
  },
];
