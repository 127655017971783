import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IResequenceMeterDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export default function ResequenceMeterDialog({
  onClosePopup,
  onConfirmYes,
}: IResequenceMeterDialog) {
  return (
    <ConfirmDialog
      title={"Re-sequence Meter"}
      subMessage={
        "Do you wish to allocate new reading sequence numbers in steps of 100 for all routes?"
      }
      message={""}
      height={"auto"}
      width={"25%"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
}
