import { DTO_Create_Supplementary_LOVs } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewSupplementaryDialogStore {
  private _createSupplementaryLOVs?: DTO_Create_Supplementary_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get createSupplementaryLOVs() {
    return this._createSupplementaryLOVs;
  }
  setCreateSupplementaryLOVs = (
    createSupplementaryLOVs?: DTO_Create_Supplementary_LOVs
  ) => {
    runInAction(() => {
      this._createSupplementaryLOVs = createSupplementaryLOVs;
    });
  };
}

const newSupplementaryDialogStoreContext = createContext(
  new NewSupplementaryDialogStore()
);
export const useNewSupplementaryDialogStore = () => {
  return useContext(newSupplementaryDialogStoreContext);
};
