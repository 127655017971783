import { getDisplayTextWithDashes } from "@common/utils/common";

export const dynamicListsBookmark = {
  getBookmarkListViewDisplayName() {
    return "Settings - Lookups";
  },
  getBookmarkListViewDetail() {
    return "Settings - Lookups - Dynamic Lists";
  },
};

export const dynamicListsDetailsBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Settings - Lookups - Dynamic Lists ${dynamicDisplayName}`;
  },
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([selectedRow.Question, selectedRow.ID]);
  },
};
