import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
export const colMeterTransactions: IColumnFields[] = [
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "DateTime",
    title: "Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "TransType",
    title: "Transactions Type",
  },
  {
    field: "TransactionAmount",
    title: "Transaction Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("TransactionAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "IsCancelled",
    title: "Is Cancelled?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Reference",
    title: "Reference",
  },
  {
    field: "Description",
    title: "Description",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: "Notes",
    title: "Notes",
  },
  {
    field: "JournalNumber",
    title: "Journal Number",
  },
  {
    field: "SessionNumber",
    title: "Session Number",
  },
  {
    field: "IsProposed",
    title: "Is Proposed?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "CreatedOn",
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "Source",
    title: "Source",
  },
  {
    field: "UserId",
    title: "User ID",
  },
  {
    field: "TransactionId",
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
