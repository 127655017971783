import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { useSendForApprovalPSARButtonStore } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/send-rept-and-resp-letter-for-approval/store";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const SendReptAndRespLetterForApprovalButton = observer(() => {
  const { ppr, onSubmit } = usePSARStore();
  const { isLoading } = useSendForApprovalPSARButtonStore();

  const isVisible = useMemo(() => {
    if (ppr) {
      if (
        ppr.Status_ENUM === Application_Status.Referral ||
        ppr.Status_ENUM === Application_Status.Refused
      ) {
        return true;
      } else if (
        ppr.Status_ENUM === Application_Status.PreliminaryAssessment &&
        !isNil(ppr.PPRDetails.Flag_IsReferralRequired)
      ) {
        return true;
      }
    }

    return false;
  }, [ppr]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Send Report and Response Letters for Approval"
        onClick={onSubmit}
        disabled={isLoading}
        isLoading={isLoading}
        name={PSARSubmitActions.SendReptAndRespLetterForApproval}
      />
    </>
  ) : null;
});
