import { AmsLLSAssetComputerReport } from "@app/products/ams/assets/reports/computer-asset-report/model";
import { LOOKUPNAME } from "@app/products/ams/constants/ams.lookups";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<AmsLLSAssetComputerReport>();
export const amsLLSAssetComputerReportColumns: IColumnFields[] = [
  {
    field: nameOf("Asset_Number"),
    title: "Asset Number",
  },
  {
    field: nameOf("Asset_Description"),
    title: "Description",
  },
  {
    field: nameOf("Status"),
    title: "Status",
    lookup: LOOKUPNAME.AssetStatus,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Serial_Number"),
    title: "Serial Number",
  },
  {
    field: nameOf("Equipment_Number"),
    title: "Equipment Number",
  },
  {
    field: nameOf("Warranty_End_Date"),
    title: "Warranty End",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Design_Life"),
    title: "Design Life",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Employee_Custodian"),
    title: "Employee Custodian",
  },
  {
    field: nameOf("Employee_Number"),
    title: "Employee Number",
  },
  {
    field: nameOf("Department"),
    title: "Department",
    lookup: LOOKUPNAME.department,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Cost_Centre"),
    title: "Cost Center",
    lookup: LOOKUPNAME.costCentre,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Office_Location"),
    title: "Location",
    lookup: LOOKUPNAME.location,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Os_Product"),
    title: "Os Product",
    lookup: LOOKUPNAME.osProduct,
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("Comments"), title: "Comment" },
];
