import {
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IAppeal } from "@common/pages/appeal/_id/model";

export enum SOGDECISION {
  SystemInitialise = 0,
  Submitted = 1,
  NotSubmitted = 2,
}

export interface AppealHearing extends DTOBaseClass_Standard {
  AppealHearing_ID?: number;
  Appeal_ID?: number;
  Appeal?: IAppeal;
  HearingType_KWD?: number | null;
  HearingDuration?: string;
  HearingDate?: string | null;
  HearingRepresentationOfficer?: SiteUser;
  HearingRepresentationOfficer_ID?: number | null;
  HearingWitness?: Contact_Relation;
  HearingWitness_RID?: number | null;
  CouncilRepresentation?: Contact_Relation;
  CouncilRepresentation_RID?: number | null;
  Status?: string;
  SaveTriggers?: AppealHearingUpdateTriggers[];
  _option?: {
    Grounds: {
      Data: IKeyValuePacket[];
    };
    HearingDuration: {
      Data: IKeyValuePacket[];
    };
    HearingType_KWD: {
      Data: IKeyValuePacket[];
    };
    CcDuration: {
      Data: IKeyValuePacket[];
    };
    SogDecision: {
      Data: IKeyValuePacket[];
    };
    VcatDecision: {
      Data: IKeyValuePacket[];
    };
  };
}

export enum AppealHearingUpdateTriggers {
  UpdateHearingRepresentationOfficer,
  UpdateHearingWitness,
  UpdateCouncilRepresentation,
}

export interface AppealHearingLOVs {
  HearingType?: IKeyValuePacket[];
  HearingDuration?: IKeyValuePacket[];
}

export interface AppealHearingLOVsNew {
  HearingType?: IKeyValuePacket[];
  HearingDuration?: string[];
}

export enum AppealHearingSubmitActions {
  Save = "Save",
  New = "New",
}
