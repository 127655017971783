import { FormRenderProps } from "@progress/kendo-react-form";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export enum ELocalLawsSettingsCategory {
  // #region General
  General_GeneralSettings = "General_GeneralSettings",
  General_Communication = "General_Communication",
  General_MailMerge = "General_MailMerge",
  // #endregion General

  // #region Numbering
  Numbering_NumberSettings = "Numbering_NumberSettings",
  // #endregion Numbering

  // #region Inspections
  Inspections_InspectionsSettings = "Inspections_InspectionsSettings",
  // #endregion Inspections

  // #region Records
  Records_AttachmentTitleFormat = "Records_AttachmentTitleFormat",
  Records_RecordsSettings = "Records_RecordsSettings",
  Records_UseContainerPartForSearch = "Records_UseContainerPartForSearch",
  Records_SharePointSettings = "Records_SharePointSettings",
  // #endregion Records
}
