import { RenderSend } from "@app/core/communication/dialogs/components/cells/send/_index";
import { IToStep } from "@app/core/communication/dialogs/components/form-elememts/to/model";
import { useToStepStore } from "@app/core/communication/dialogs/components/form-elememts/to/store";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IToStep>();
export const ConfigToStep = () => {
  const { preferred } = useToStepStore();
  const colCommunicationTo: IColumnFields[] = [
    {
      field: nameOf("Send"),
      title: "Send",
      cell: RenderSend,
      width: preferred.useSMS && preferred.useEmail ? "90px" : "70px",
    },
    {
      field: nameOf("Contact_FullName"),
      title: "Full Name",
    },
    {
      field: nameOf("ContactRelationshipType_Name"),
      title: "Contact Relationship Type",
    },
    {
      field: nameOf("Email"),
      title: "Email",
    },
    {
      field: nameOf("_Mobile"),
      title: "Mobile",
    },
    // Waiting for confirm, will enhance later
    // {
    //   field: nameOf("PreferredMethod_Formatted"),
    //   title: "Preferred Method Formatted",
    // },
    {
      field: nameOf("PreferredMethod_Name"),
      title: "Preferred",
    },
  ];
  return colCommunicationTo;
};
