import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { VO_Compliance_Entities_With_Multiple_Exemption } from "@app/products/property/assessments/compliance/entities-with-multiple-exemptions/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Compliance_Entities_With_Multiple_Exemption>();
export const colComplianceEntitiesAll: IColumnFields[] = [
  {
    field: nameOf("Assess_Reference"),
    title: "Assessment(S-S-B-U)",
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
  },
  {
    field: nameOf("ENA_Name"),
    title: "Contact Name",
  },
  {
    field: nameOf("ENA_Address_Locality"),
    title: "Address",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("Compliance_Id"),
    title: "Compliance ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Land_Tax_Exemption_Id"),
    title: "Land Tax Exemption ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
