import { IDataForFinishDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ConfirmFinishDialogStore {
  private _dataForFinishDialog?: IDataForFinishDialog = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get dataForFinishDialog(): IDataForFinishDialog | undefined {
    return this._dataForFinishDialog;
  }
  setDataForFinishDialog = (dataForFinishDialog?: IDataForFinishDialog) => {
    runInAction(() => {
      this._dataForFinishDialog = dataForFinishDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._dataForFinishDialog = undefined;
    });
  };
}

const confirmFinishDialogStoreContext = createContext(
  new ConfirmFinishDialogStore()
);
export const useConfirmFinishDialogStore = () => {
  return useContext(confirmFinishDialogStoreContext);
};
