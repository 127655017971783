import { EClientSequenceKey } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";

export const IntegrationSettingEnums = {
  defaultShow: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceMethod, // Finance Method
      ECorporateSettingsField.CorporateSettings_FinanceSystem, // Finance System
    ],
  },

  divFinanceExportFormat: {
    isVisible: false,
    child: [ECorporateSettingsField.CorporateSettings_FinanceExportFormat],
  },

  divFinanceConnectionString: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceConnectionString, // Finance Connection String *
      ECorporateSettingsField.CorporateSettings_FinanceQuery_CommandTimeOut, // Command Timeout (seconds)
    ],
  },

  divFinanceDirectExport: {
    isVisible: false,
    child: [ECorporateSettingsField.CorporateSettings_FinanceDirectExport],
  },

  divFinanceDirectExportConnectionString: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceDirectExportConnectionString, // Finance Direct Export Connection String
    ],
  },

  divFinanceDirectExportSQL: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceDirectExportSQL, // Export Query
    ],
  },

  divFinanceAutoReceiptFees: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceAutoReceiptFees, // Auto Receipt Fees on Export
    ],
  },

  divFinanceExportFolder: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceExportDir, // Export Folder (UNC path)
    ],
  },

  defaultShow1: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceExportSuccessNotification, // Export Email Result If Successful
    ],
  },

  divFinanceExportEmailAddress: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceNotificationEmailAddress, // Export Notification Email
    ],
  },

  divFinanceConnectionMethod: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_PropertiesConnectionMethod, // Connection Method
    ],
  },

  divFinanceImportMethod: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceImportMethod, // Import Method
    ],
  },

  divFinanceReferenceMethod: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceLookupMethod, // Reference Method
    ],
  },

  divTechOneExportFields: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceExportTechOneFileHeaderField1, // Header Field1
      ECorporateSettingsField.CorporateSettings_FinanceExportTechOneFileHeaderField2, // Header Field2
    ],
  },

  divCustomQuery: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceCustomQuery, // Import Query *
    ],
  },

  divImportFolder: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceImportDir, // Import Folder
    ],
  },

  divProcessedImportFileFolder: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceProcessedImportFileDir, // Processed Import File Folder
    ],
  },

  divImportFileName: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FeeImportFileName, // Import Receipts File Name
    ],
  },

  divBalanceSheetFileName: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_BalanceSheetFileName, // Balance sheet File Name
    ],
  },

  defaultShow2: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceImportNotification, // Import Email Result on
    ],
  },

  divImportFileFormat: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceImportFileFormat, // Import File Format
    ],
  },

  divFinanceImportSQL: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_CashlinkViewName, // View Name *
      ECorporateSettingsField.CorporateSettings_CashlinkFeeIDColumn, // ID Column Name *
      ECorporateSettingsField.CorporateSettings_CashlinkAmountPaidColumn, // Amount Paid Column Name *
      ECorporateSettingsField.CorporateSettings_CashlinkReceiptNoColumn, // Receipt No Column Name *
      ECorporateSettingsField.CorporateSettings_CashlinkDatePaidColumn, // Date Paid Column Name *
      ECorporateSettingsField.CorporateSettings_CashlinkPaidByColumn, // Teller Column Name *
    ],
  },

  divFinanceImportEmailAddress: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceImportNotificationEmailAddress, // Import Notification Email
    ],
  },

  divFinanceBatchNo: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceLastBatchNo, // Last Batch No
    ],
  },

  divFinanceCollectNewExport: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_Finance_Collect_NewFormat, // Use New Collect Export Format
    ],
  },

  divFinanceTransactionTypes: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_InvoiceTransType, // Invoice Transaction Type *
      ECorporateSettingsField.CorporateSettings_CreditTransType, // CREDIT Transaction Type *
      ECorporateSettingsField.CorporateSettings_FinanceDebtType, // Debt Type
      ECorporateSettingsField.CorporateSettings_FinanceOverrideAccPer, // Override Account %
    ],
  },

  divCashlinkReceipting: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_CashLinkReceiptInsertQuery, // Receipt Insert Query *
      ECorporateSettingsField.CorporateSettings_CashLinkReceiptItemInsertQuery, // Receipt Item Insert Query
      ECorporateSettingsField.CorporateSettings_CashLinkReceiptPaymentInsertQuery, // Receipt Payment Insert Query
      ECorporateSettingsField.CorporateSettings_CashLink_ProductwiseCodes, // Productwise Codes
    ],
  },

  divEnableUpToGlassFinance: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance, // Enable Up to the Glass Finance
    ],
  },

  divUseCommunityFinanceAPI: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_U2GFinanceAPISystem, // Finance API
    ],
  },

  divUpToGlassFinanceSettings: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceTransactionUrl, // Finance Transaction URL
      ECorporateSettingsField.CorporateSettings_U2GUseParameterisedQuery, // Use Parameterised Query
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceTrasactionsQuery, // Transactions query *
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceTrasactionsCountQuery, // Transactions count query
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceOutstandingAmountQuery, // Outstanding amount and last payment date query *
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceFeeOutstandingAmountQuery, // Outstanding fee amount query
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceRefreshPaidFeesByDays, // Refresh Days Count (Paid Fee)
      ECorporateSettingsField.CorporateSettings_UpToGlassFinanceStaleTime, // Stale Time
      ECorporateSettingsField.CorporateSettings_CalculateAgedDebtors, // Calculate Aged Debtors
    ],
  },

  divFinanceAPISettings: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_BaseURL, // Base URL
      ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_UserName, // Username
      ECorporateSettingsField.CorporateSettings_U2GCommunityFinanceAPI_Password, // Password
    ],
  },

  divOracleAdditionalSettings: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_U2GFinance_OracleTokenUrl, // Token URL
      ECorporateSettingsField.CorporateSettings_U2GFinance_OracleClientID, // Client ID
      ECorporateSettingsField.CorporateSettings_U2GFinance_OracleClientSecret, // Client Secret
      ECorporateSettingsField.CorporateSettings_U2GFinance_OracleScope, // Scope
    ],
  },

  divPreFinanceSummaryPaymentInformationDetails: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_PreFinanceSummaryPaymentInformation, // Enable Pre-Finance Summary Payment Informaion
    ],
  },

  divDellBoomiAccountBalanceSettings: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_U2GFinanceAPIAccountBalanceURL, // Account Balance URL
    ],
  },

  divReceiptNumberPrefix: {
    isVisible: false,
    child: [],
  },
  divReceiptNumberSuffix: {
    isVisible: false,
    child: [],
  },

  dvQuote: {
    isVisible: false,
    child: [],
  },

  IntegrationSettingEnums: {
    isVisible: false,
    child: [],
  },

  divCustomeFeeFieldsforExport_Oracle: {
    isVisible: false,
    child: [],
  },

  divCommunityFinanceAPISettings: {
    isVisible: false,
    child: [],
  },

  dvF1TransactionType: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_DebtorType, // Debtor Transaction Type
      ECorporateSettingsField.CorporateSettings_FinanceSalesTerritory, // AR Category
    ],
  },

  divIDPrefix: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceIdPrefix, // ID Prefix
    ],
  },

  divLastImportedDate: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_ExternalPaymentLastImportDate, // Last Payment Import
    ],
  },

  divCustomeFeeFieldsforExport: {
    isVisible: false,
    child: [],
  },

  // divReceiptLastNumber: {
  //   isVisible: false,
  //   child: [
  //     EClientSequenceKey.Core_Receipt, // Last Number Used
  //   ],
  // },

  ddlFInanceReferenceMethod: {
    isVisible: false,
    child: [ECorporateSettingsField.CorporateSettings_FinanceLookupMethod],
  },

  /* ==========  General Finance Settings  ========== */
  defaultShowGeneralFinanceSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FeesExportedToFinanceReadOnly, // Fees Exported to Finance Read-Only
      ECorporateSettingsField.CorporateSettings_AllowPaymentEdits, // Allow Editing of Payments
      ECorporateSettingsField.CorporateSettings_PaymentDays, // Default Days for Payment Due
      ECorporateSettingsField.CorporateSettings_LedgeCodeMandatory, // Make Ledgercode Mandatory
      ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice, // Enable MultiLine Invoice
      ECorporateSettingsField.CorporateSettings_EnableGST, // Enable GST
      ECorporateSettingsField.CorporateSettings_OSFeeLabel, // OS Fee Label
    ],
  },
  /* ==========  / General Finance Settings  ========== */

  /* ====  Bpay Finance Settings  ==== */
  defaultShowBpayFinanceSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceRefNoMethod, // Calculate Reference Numbers by
      ECorporateSettingsField.CorporateSettings_AusPostFormat, // Aus Post Format
      ECorporateSettingsField.CorporateSettings_RefNoLength_BPAY, // BPAY Ref No Length
      ECorporateSettingsField.CorporateSettings_BPAYBillerCode, // BPAY Biller Code
      ECorporateSettingsField.CorporateSettings_BPAYSuffixCategoryCode, // BPAY Suffix Category Code
      ECorporateSettingsField.CorporateSettings_BPayRefNoTruncation, // BPAY Truncate Ref No
      ECorporateSettingsField.CorporateSettings_RefNoLength_BILLPAY, // BillPay Ref No Length
      ECorporateSettingsField.CorporateSettings_RefNo_APOConstant, // APO Barcode Constant
      ECorporateSettingsField.CorporateSettings_RefNoLength_Barcode, // APO Barcode Ref No Length
      ECorporateSettingsField.CorporateSettings_RefNoLength_Keyline, // Keying Line Ref No Length
    ],
  },

  trKeyLineCode: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_KeyingLineCode, // Keying Code
    ],
  },

  trKeyLineTID: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_KeyingTID, // TID
    ],
  },

  defaultShowBpayFinanceSettings1: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_RefNoLength_OCR, // OCR Ref No Length
      ECorporateSettingsField.CorporateSettings_FinanceiCRNGenerationMethod, // iCRN Generation Method
      ECorporateSettingsField.CorporateSettings_BPAYClientRef, // BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_Animals, // Animals BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_Building, // Building BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_CEM, // Cemeteries BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_CRMS, // CRMS BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_EventBooking, // Event Booking BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_HM, // Health Manager BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_Infringements, // Infringements BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_LL, // LocalLaws BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_TP, // Town Planning BPAY Client Ref
      ECorporateSettingsField.CorporateSettings_BPAYClientRef_WW, // Wastewater BPAY Client Ref
    ],
  },
  /* ====  / Bpay Finance Settings  ==== */

  /* ====  Debtor Settings  ==== */
  defaultShowDebtorSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber, // Auto Allocate Debtor Numbers
    ],
  },

  divDebtorNumberLength: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceDebtorNumberLength, // Debtor Number Length
    ],
  },

  divDebtorNumberLastNumber: {
    isVisible: true,
    child: [
      "SYSENUM_" + EClientSequenceKey.Core_DebtorNumber, // Last Number Used
    ],
  },

  defaultShowDebtorSettings1: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceExportDebtors, // Export Debtors
    ],
  },

  divExportDebtorFolder: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceExportDir, // Export Folder (UNC path)
    ],
  },
  divExportDebtorFormat: {
    isVisible: false,
    child: [
      "ExportDebtorFormat", // Export Debtor Format
    ],
  },
  divExportDebtorFieldSeparator: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_ExportDebtorFileSeparator, // Export Debtor Field Separator
    ],
  },
  divOnlyExportDebtorsWithFees: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceOnlyExportDebtorWithFees, // Only Export Debtors With Fees
    ],
  },

  divCheckDebtorNumber: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory, // Debtor Number Mandatory
    ],
  },
  defaultShowDebtorSettings2: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection, // Enable User Selection
    ],
  },

  divDebtorUserSelection: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_RestrictDebtorToModule, // Restrict Debtor to Module
      ECorporateSettingsField.CorporateSettings_RestrictDebtorToContact, // Restrict Debtor to Contact
    ],
  },

  defaultShowDebtorSettings3: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_DebtorNumberLabel, // Debtor Number Label
    ],
  },
  /* ====  / Debtor Settings  ==== */

  /* ====  Invoice Number Settings  ==== */
  defaultShowInvoiceNumberSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceAllocateInvoiceNumber, // Auto Allocate Invoice Numbers
      ECorporateSettingsField.CorporateSettings_FinanceFeeIDInvoiceNumber, // Use Fee ID For Invoice Number
    ],
  },
  divInvoiceNumberLength: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceInvoiceNumberLength, // Invoice Number Length
      ECorporateSettingsField.CorporateSettings_FinanceInvoiceNumberPrefix, // Prefix
      ECorporateSettingsField.CorporateSettings_FinanceInvoiceNumberSuffix, // Suffix
    ],
  },
  divInvoiceNumberPrefix: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceInvoiceNumberPrefix, // Prefix
    ],
  },
  divInvoiceNumberSuffix: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceInvoiceNumberSuffix, // Suffix
    ],
  },
  divInvoiceLastNumber: {
    isVisible: true,
    child: [
      "SYSENUM_" + EClientSequenceKey.Core_Invoice, // Last Number Used
    ],
  },
  /* ====  / Invoice Number Settings  ==== */

  /* ====  Receipt Number Settings  ==== */
  defaultShowReceiptNumberSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceAllocateReceiptNumber, // Auto Allocate Receipt Numbers
    ],
  },

  divReceiptNumberLength: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceReceiptNumberLength, // Receipt Number Length
      ECorporateSettingsField.CorporateSettings_FinanceReceiptNumberPrefix, // Prefix
      ECorporateSettingsField.CorporateSettings_FinanceReceiptNumberSuffix, // Suffix
    ],
  },

  divReceiptLastNumber: {
    isVisible: false,
    child: [
      EClientSequenceKey.Core_Receipt, // Last Number Used
    ],
  },
  /* ====  / Receipt Number Settings  ==== */

  /* ====  Future Fee Settings  ==== */
  defaultShowFutureFeeSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_EnableFutureFees, // Enable Future Fees
      ECorporateSettingsField.CorporateSettings_ShowFeeDescINFeeTab, // Show Description in Fee Tab
    ],
  },
  /* ====  / Future Fee Settings  ==== */

  /* ====  Finance staging update settings  ==== */
  defaultShowFinanceStagingUpdateSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_UpdateFinanceStaging, // Update Finance Staging
    ],
  },
  divUpdateFinance: {
    isVisible: false,
    child: [
      ECorporateSettingsField.CorporateSettings_FinanceStagingConnectionString, // Connection String
      ECorporateSettingsField.CorporateSettings_UpdateFinanceStagingQuerySQL, // Update Query
    ],
  },
  /* ====  / Finance staging update settings  ==== */

  /* ====  Payment Method Settings  ==== */
  defaultShowPaymentMethodMandatory: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_IsPaymentMethodMandatory, // Payment Method Mandatory
    ],
  },
  /* ====  / Payment Method Settings  ==== */

  /* ====  Bond Integration Lookup settings  ==== */
  defaultShowBondIntegrationLookupSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_BondLookup, // Lookup Bond Type
    ],
  },
  divBondLookUp: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_BondConnectionString, // Bond Connection String
      ECorporateSettingsField.CorporateSettings_BondLookUpQuery, // Bond Query
    ],
  },
  /* ====  / Bond Integration Lookup settings  ==== */

  /* ====  Subsidies settings  ==== */
  defaultShowSubsidiesSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_Enable_Subsidies, // Enable Subsidies
    ],
  },
  divSubsidySettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_Subsidies_Label, // Label For Subsidies
    ],
  },
  /* ====  / Subsidies settings  ==== */
  /** === SAP Finance System */
  divSAPFinanceSystemSettings: {
    isVisible: true,
    child: [
      ECorporateSettingsField.CorporateSettings_Finance_SAP_BaseUrl,
      ECorporateSettingsField.CorporateSettings_Finance_SAP_Username,
      ECorporateSettingsField.CorporateSettings_Finance_SAP_Password,
      ECorporateSettingsField.CorporateSettings_Finance_SAP_SourceInstitution,
      ECorporateSettingsField.CorporateSettings_Finance_SAP_SourceSystem,
      ECorporateSettingsField.CorporateSettings_Finance_SAP_UUID,
    ],
  },
};
