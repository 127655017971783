import { PRODUCT_TYPE } from "@common/constants/productType";
import { PAGE_TYPE, divPropPageType } from "@common/hooks/usePageType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { CCGrid, ICCGridProps } from "@components/cc-grid/_index";
import { ColumnMenuListView } from "@components/cc-grid/components/grid-column-menus/column-menu-list-view/_index";
import { gridConfig, gridDefaultProps } from "@components/cc-grid/config";
import {
  getGroupedListWithAggregate,
  getSortedListAfterGroup,
  notYetAggregation,
} from "@components/cc-grid/util";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { CCNotification } from "@components/cc-notification/_index";
import { getViewConfiguration } from "@components/cc-product-list-view/api";
import {
  EListType,
  useStoreStateListView,
} from "@components/cc-product-list-view/components/hooks/useStoreStateListView";
import { ListViewFilterBar } from "@components/cc-product-list-view/components/list-view-filter-bar/_index";
import {
  PRODUCT_LIST_VIEW_SELECTION_SPEED,
  PRODUCT_LIST_VIEW_TAKE,
} from "@components/cc-product-list-view/config";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { GroupDescriptor, State } from "@progress/kendo-data-query";
import { isNil, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce, useEffectOnce } from "react-use";
import "./_index.scss";

export interface ICCProductListViewProps extends ICCGridProps {
  isClearStoreState?: boolean;
  disabled?: boolean;
  typeList?: EListType;
}

export const CCProductListView = observer((props: ICCProductListViewProps) => {
  const {
    notifications,
    setNotifications,
    isShowFilterBar,
    isShowSlideBar,
    gridData,
    gridColumns,
    gridDataState,
    gridSelectedRows,
    lastSelectedRow,
    gridExportFormat,
    setGridExportFormat,
    setGridData,
    setGridColumns,
    setGridDataState,
    setGridSelectedRows,
    setGridSelectedIds,
    setIsShowSlideBar,
    resetStore,
    clearSelectedItems,
    filterBarState,
    setDisableRowField,
    setCanSelectField,
    setFilterBarState,
    setGridTotalSelected,
    setPrimaryField,
    isEmptyData,
    setItemPerPage,
  } = useCCProductListViewStore();
  const { settings } = useCommonCoreStore();
  const {
    data,
    state,
    columnFields,
    selectableMode = "multiple",
    itemPerPage,
    dataUrl,
    selectedRows,
    disableRowField,
    canSelectField,
    primaryField,
    isClearStoreState = false, // change to is clear
    pageSizes,
    disabled,
    onDataStateChange,
    typeList = EListType.Normal,
  } = props;

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRowsState, setSelectedRowsState] = useState<any[]>(
    selectedRows ?? []
  );
  const [totalSelectedRows, setTotalSelectedRows] = useState<number>(
    selectedRows?.length ?? 0
  );
  const menuActionEnumId = params.get("menuActionEnumId");
  const userViewId = params.get("userViewId");

  /**
   * Config item per page of 2 type
   * FlatGridPageSize
   * GroupedGridPageSize
   */
  const defaultConfigItemPage = useMemo(() => {
    let itemPerPageCurrent = itemPerPage;
    let pageSizeData = pageSizes ? [...pageSizes] : undefined;
    if (isNil(itemPerPageCurrent)) {
      const flagItemPage: number = getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_FlatGridPageSize]
      );
      const groupItemPage: number = getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_GroupedGridPageSize]
      );
      state?.group
        ? (itemPerPageCurrent = groupItemPage)
        : (itemPerPageCurrent = flagItemPage);
      pageSizeData = [...gridConfig.paging.pageSizes];
      pageSizeData.push(itemPerPageCurrent);
      pageSizeData = uniq(pageSizeData);
      pageSizeData.sort((a, b) => a - b);
    }
    setItemPerPage(itemPerPageCurrent);
    return {
      itemPerPage: itemPerPageCurrent,
      pageSize: pageSizeData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, pageSizes, settings, state]);

  useDebounce(
    () => {
      setGridSelectedRows(selectedRowsState);
      setGridSelectedIds(selectedRowsState.map((item) => item[primaryField]));
    },
    PRODUCT_LIST_VIEW_SELECTION_SPEED,
    [selectedRowsState]
  );
  useDebounce(
    () => {
      setGridTotalSelected(totalSelectedRows);
    },
    PRODUCT_LIST_VIEW_SELECTION_SPEED,
    [totalSelectedRows]
  );

  useEffect(() => {
    setPrimaryField(primaryField);
  }, [primaryField, setPrimaryField]);

  useEffect(() => {
    setCanSelectField(canSelectField);
  }, [canSelectField, setCanSelectField]);

  useEffect(() => {
    setDisableRowField(disableRowField);
  }, [disableRowField, setDisableRowField]);

  const { isKeepStoreState } = useStoreStateListView(
    typeList,
    resetStore,
    isClearStoreState
  );

  useEffect(() => {
    if (!dataUrl) {
      setGridData(data);
    }
    setIsLoading(false);
  }, [data, setGridData, dataUrl]);

  useEffect(() => {
    if (selectedRows) {
      setGridSelectedRows(selectedRows);
      setGridSelectedIds(selectedRows.map((item) => item[primaryField]));
    }
  }, [primaryField, selectedRows, setGridSelectedIds, setGridSelectedRows]);

  useEffect(
    () => {
      //Check current grid is stored in localStorage or not
      //Get default state from props if have default state and grid is not stored
      if (state && !isKeepStoreState) {
        const { sort, filter, group, take, skip } = state;
        let newDataState = {
          sort: sort !== undefined ? sort : gridDefaultProps.state?.sort,
          filter:
            filter !== undefined ? filter : gridDefaultProps.state?.filter,
          group: group !== undefined ? group : gridDefaultProps.state?.group,
          skip: skip !== undefined ? skip : gridDefaultProps.state?.skip,
          take:
            take !== undefined
              ? take
              : defaultConfigItemPage.itemPerPage ||
                gridDefaultProps.state?.take,
        };

        //itemPerPage is set to -1 if default selected option is "All"
        //In this case, the $take query option is removed in order to get all records
        if (newDataState.take === -1) delete newDataState.take;

        const newGroupList: Array<GroupDescriptor> = newDataState?.group
          ? [...newDataState.group]
          : [];
        if (newGroupList && notYetAggregation(newGroupList)) {
          newDataState.group = getGroupedListWithAggregate(
            primaryField,
            newDataState.group ?? []
          );
        }
        if (group) {
          newDataState.sort = getSortedListAfterGroup(
            newGroupList,
            newDataState.sort ?? []
          );
        }
        setGridDataState(newDataState);
      }
    },
    // eslint-disable-next-line
    [
      state,
      setGridDataState,
      defaultConfigItemPage,
      primaryField,
      isKeepStoreState,
    ]
  );

  useEffect(() => {
    setGridColumns(columnFields);
  }, [columnFields, setGridColumns]);

  const handleOnDataChange = useCallback(
    (data) => {
      setGridData(data);
    },
    [setGridData]
  );
  const handleOnDataStateChange = useCallback(
    (state: State) => {
      setGridDataState(state);
      if (onDataStateChange) onDataStateChange(state);
    },
    [setGridDataState, onDataStateChange]
  );
  const handleOnSelectionChange = useCallback(
    (dataItem: any[]) => {
      if (!isShowSlideBar) {
        setGridSelectedRows(dataItem);
        setGridSelectedIds(dataItem.map((item) => item[primaryField]));
      } else {
        //Use Debounce
        setSelectedRowsState(dataItem);
      }
    },
    [primaryField, setGridSelectedIds, setGridSelectedRows, isShowSlideBar]
  );

  const handleOnChangeTotalSelect = useCallback(
    (totalSelectedRows: number) => {
      if (!isShowSlideBar) {
        setGridTotalSelected(totalSelectedRows);
      } else {
        //Use Debounce
        setTotalSelectedRows(totalSelectedRows);
      }
    },
    [setGridTotalSelected, isShowSlideBar]
  );
  /*   const handleOnChangeColumnField = useCallback(
    (newColumns: IColumnFields[]) => {
      setGridColumns(newColumns);
    },
    [setGridColumns]
  ); */

  useEffect(() => {
    if (menuActionEnumId !== null && userViewId !== null) {
      setIsLoading(true);
      getViewConfiguration(
        0,
        PRODUCT_TYPE.CommunityProperty,
        +menuActionEnumId,
        +userViewId
      ).then((configs) => {
        if (configs) {
          setGridColumns(
            configs
              .filter((conf) => !conf.IsHidden)
              .map((config) => {
                const find = columnFields.find(
                  (col) => col.field === config.ColumnName
                );
                if (find) {
                  return {
                    ...find,
                    title: config.DataColumn,
                  };
                }
                return {
                  field: config.ColumnName,
                  title: config.DataColumn,
                };
              })
          );
        } else {
          setGridColumns(columnFields);
        }
        setIsLoading(false);
      });
    } else {
      if (!isKeepStoreState) {
        setGridColumns(columnFields);
      }
    }
  }, [
    menuActionEnumId,
    userViewId,
    columnFields,
    setGridColumns,
    isKeepStoreState,
  ]);

  const handleChangeFilter = useCallback((filter) => {
    setGridDataState((previousState: State) => {
      return { ...previousState, filter: filter };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    return () => {
      clearSelectedItems();
    };
  });

  return (
    <>
      {notifications && notifications.length ? (
        <div className="ncc-product-list-view-notification">
          <CCNotification
            initialNotifications={notifications}
            onSetNotifications={setNotifications}
          />
        </div>
      ) : null}
      <CCAppNotification />
      <YieldNotificationPortal placeId={TypePlaceId.Main} />
      <div
        {...divPropPageType(PAGE_TYPE.ProductListView)}
        className="ncc-product-list-view-body"
        id="cc-product-list-view-body"
      >
        {isShowFilterBar ? (
          <div className="ncc-product-list-view-filter">
            <ListViewFilterBar
              columns={gridColumns}
              filter={filterBarState}
              onChangeFilter={handleChangeFilter}
              setFilterBarState={setFilterBarState}
            />
          </div>
        ) : null}
        {!isLoading ? (
          <CCGrid
            {...props}
            exportFormat={gridExportFormat}
            onExported={setGridExportFormat}
            itemPerPage={
              defaultConfigItemPage?.itemPerPage || PRODUCT_LIST_VIEW_TAKE
            }
            pageSizes={defaultConfigItemPage?.pageSize}
            selectableMode={selectableMode}
            isAutoHiddenPager={false}
            style={{ height: "100%" }}
            groupDragMode={"fixed"}
            pagerType={"input"}
            className="ncc-product-list-view-grid"
            data={gridData}
            state={gridDataState}
            columnFields={gridColumns}
            selectedRows={gridSelectedRows}
            onDataChange={handleOnDataChange}
            onDataStateChange={handleOnDataStateChange}
            onSelectionChange={handleOnSelectionChange}
            columnMenu={ColumnMenuListView}
            onTotalSelectedRowChange={handleOnChangeTotalSelect}
            readOnly={disabled} //Recheck later
            disabledGrid={disabled}
            // onColumnFieldsChange={handleOnChangeColumnField}
          />
        ) : (
          <Loading isLoading={isLoading} isFullScreen />
        )}

        {isShowSlideBar && lastSelectedRow && !isEmptyData ? (
          <div className="ncc-product-list-view-slide-bar">
            <div className="ncc-product-list-view-slide-bar-card">
              <ReferenceSideBar />
            </div>
            <div
              className="ncc-product-list-view-slide-bar-backdrop"
              onClick={() => {
                setIsShowSlideBar(false);
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
});
