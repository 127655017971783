import { VO_Parcel } from "@app/products/property/parcels/list/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Parcel>();

export const colListLocalityStreetAddress: IColumnFields[] = [
  {
    field: nameOf("Full_Street_Name"),
    title: "Full Street Name",
  },
  {
    field: nameOf("Locality_Name"),
    title: "Locality Name",
  },
  {
    field: nameOf("Property_Name_Address_Locality"),
    title: "Property Name Address Locality",
  },
];
