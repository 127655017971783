import { generalRouteStateLocalLawsSettings } from "@app/products/local-laws/system-admin/settings/_id/main/settings/general/routeState";
import { inspectionsRouteStateLocalLawsSettings } from "@app/products/local-laws/system-admin/settings/_id/main/settings/inspections/routeState";
import { numberingRouteStateLocalLawsSettings } from "@app/products/local-laws/system-admin/settings/_id/main/settings/numbering/routeState";

export interface IRouteStateForm {
  name: string;
  component: any;
}

export const routeStateLocalLawsConfig: IRouteStateForm[] = [
  ...numberingRouteStateLocalLawsSettings,
  ...inspectionsRouteStateLocalLawsSettings,
  ...generalRouteStateLocalLawsSettings,
];
