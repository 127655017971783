import { TOWN_PLANNING_PPR_APPEALS_HEARING_CALENDER_LIST_VIEW_API_URL } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/contants";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getAppealHearingCalendarByOfficerId = async (
  officerId?: number
): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      TOWN_PLANNING_PPR_APPEALS_HEARING_CALENDER_LIST_VIEW_API_URL.replace(
        "{officerID}",
        (officerId || 0).toString()
      )
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse?.data?.Message,
    };
  }
};
