import { PPRRefAuthRegister } from "@app/products/town-planning/ppr/permit-referrals/all/ref-auth-register/model";
import { handlePermitReferralsAllLinkTo } from "@app/products/town-planning/ppr/permit-referrals/all/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<PPRRefAuthRegister>();
export const colPprAllApplicationsRefAuthRegister: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Permit Application Number",
    locked: true,
    linkTo: handlePermitReferralsAllLinkTo,
  },
  {
    field: nameOf("PlanningScheme"),
    title: "Planning Scheme",
  },
  {
    field: nameOf("ResponsibleAuthority"),
    title: "Responsible Authority",
  },
  {
    field: nameOf("CouncilRefNo"),
    title: "Reference Number",
  },
  { field: nameOf("LodgedDate"), title: "Received", format: DATE_FORMAT.DATE },
  {
    field: nameOf("SiteAddress"),
    title: "Address of the Land Affected",
  },
  {
    field: nameOf("ReasonForPermit"),
    title: "Proposed Used Development",
  },
  {
    field: nameOf("FinalDecisionDate"),
    title: "Decision",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("SectionPEAct"),
    title: "Section P & Act",
  },
  {
    field: nameOf("VicRoadDecision"),
    title: "Decision of the Head Transport for Victoria",
  },
  { field: nameOf("FileNo"), title: "File Number" },
];
