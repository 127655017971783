import { DIRECT_DEBIT_MODULE_ITEM_TYPES } from "@app/products/direct-debit/constant";
import {
  DBRowStateWithoutArchive,
  IntegrationModuleTypeFilter,
} from "@app/products/direct-debit/model";
import { DBRowStateCustomCell } from "@app/products/direct-debit/system-admin/components/db-row-state-custom-cell/_index";
import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IntegrationModuleItemType>();

export const colModuleItemTypes: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: IntegrationModuleItemType) => {
      return (
        `${DIRECT_DEBIT_MODULE_ITEM_TYPES}/` +
        dataItem.Integration_Module_Item_Type_ID
      );
    },
  },
  {
    field: nameOf("Integration_Module_Type_ENUM"),
    title: "Module Type",
    filterByEnum: IntegrationModuleTypeFilter,
    width: 180,
  },
  {
    field: nameOf("Register_ID"),
    title: "Register ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Item_Type_ID"),
    title: "Item Type ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sys_DBRowState_View_Filter"),
    title: "Status",
    filterByEnum: DBRowStateWithoutArchive,
    cell: DBRowStateCustomCell,
  },
  {
    field: nameOf("Integration_Module_Item_Type_ID"),
    title: "Module Item Type ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
