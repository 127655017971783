import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const ReportsSettings = ({
  formRenderProps,
}: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "IntegratedReports_ViewerDefaultPreviewResults",
    "IntegratedReports_DesignerDefaultPreviewResults",
    "IntegratedReports_EnableLargerExportLimit",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <NumericTextboxGlobalSettings
          data={data.IntegratedReports_ViewerDefaultPreviewResults}
          isEditing={isEditing}
          min={0}
          format={NUMBER_FORMAT.NUMBER2}
        />
        <NumericTextboxGlobalSettings
          data={data.IntegratedReports_DesignerDefaultPreviewResults}
          isEditing={isEditing}
          min={0}
          format={NUMBER_FORMAT.NUMBER2}
        />
        <SwitchGlobalSettings
          data={data.IntegratedReports_EnableLargerExportLimit}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      </div>
    </section>
  );
};

export default observer(ReportsSettings);
