import { Register } from "@app/products/town-planning/ppr/enquiries/_id/model";
import {
  getDecisionName,
  pprApplicationCategoryDisplay,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/util";
import { pprApplicationFrom } from "@app/products/town-planning/ppr/[id]/config";
import {
  Application,
  ContactRelationshipType,
} from "@app/products/town-planning/ppr/[id]/model";
import { Contact } from "@app/products/waste-water/[id]/model";
import { APPEALSTATUS } from "@common/pages/appeal/_id/model";
import { isEmpty, isNil } from "lodash";

export const convertApplicationToPPRForm = (application?: Application) => {
  if (application) {
    application.Decision_Name = getDecisionName(application.Decision_ENUM);
  }
  return {
    ...application,
    ApplicationCategories_Display: pprApplicationCategoryDisplay(
      application?.ApplicationCategories
    ),
  } as Application;
};

export const processDataParentSentToAppeal = (data?: Application) => {
  if (!data) return undefined;
  const parentAppel: any = {};

  const application: Application = data;
  const contact: Contact = data.Applicant?.Contact;
  if (!isNil(application?.Applicant) && !isNil(contact)) {
    let appellantContactDetails = "";
    let appellantContactDetails2 = "";
    parentAppel.Appellant_Details = data.Applicant.Contact?.DisplayName;

    if (!isEmpty(contact.Mobile)) appellantContactDetails2 += contact.Mobile;
    if (!isEmpty(contact.HomePhone))
      appellantContactDetails2 += "," + contact.HomePhone;
    if (!isEmpty(contact.WorkPhone))
      appellantContactDetails2 += "," + contact.WorkPhone;
    if (!isEmpty(contact.Email)) appellantContactDetails = contact.Email;
    else parentAppel.AppellantContact_Details = "";
    parentAppel.AppellantContact_Details = appellantContactDetails;
    parentAppel.AppellantContact_Details2 = appellantContactDetails2;

    if (application.Flag_IsPPR) {
      parentAppel.WorkFlowStatus_ENUM = APPEALSTATUS.AwaitingAppeal;
    }
    return parentAppel;
  }
};

export const convertRegisterEnquiryToPPRForm = (register: Register) => {
  let result = {
    ...pprApplicationFrom,
    Applicant: {
      ContactRelationshipType_ENUM: ContactRelationshipType.Applicant,
      Contact: register?.Contact?.Contact,
      RID: 0,
      Applicant_RID: null,
    },
    SiteAddress: {
      ...register?.Address,
      Address_ID: 0,
    },
    Flag_IsPPR: true,
    PPRDetails: {
      VRStreet: register?.VRStreet,
      VRStreetID: register?.VRStreetID,
      ResponsibleAuthority_ID: register?.ResponsibleAuthority_ID,
      ResponsibleAuthority: register?.ResponsibleAuthority,
      VROfficeName: register?.VROffice,
      VR_OfficeID: register?.VR_OfficeID,
    },
  };
  if (!isNil(register?.Contact2)) {
    return {
      ...result,
      PreferredContact: {
        ContactRelationshipType_ENUM: ContactRelationshipType?.PrimaryContact,
        Contact: register?.Contact2?.Contact,
        RID: 0,
        PreferredContact_RID: null,
      },
    };
  } else {
    return result;
  }
};
