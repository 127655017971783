import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { HMPremisesInspectionScheduleView } from "@app/products/hm/insp-schedule/model";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

const nameOfPremisesInspScheduleView =
  nameOfFactory<HMPremisesInspectionScheduleView>();
export const colHMInspScheduleMySchedule: IColumnFields[] = [
  {
    field: nameOfPremisesInspScheduleView("TradingName"),
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: HMPremisesInspectionScheduleView) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconColumnViewConfigAlert,
  {
    field: nameOfPremisesInspScheduleView("RegNo"),
    title: "Registration Number",
  },
  {
    field: nameOfPremisesInspScheduleView("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("NextInspection"),
    title: "Next Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("StreetNo"),
    title: "Number",
  },
  {
    field: nameOfPremisesInspScheduleView("Street"),
    title: "Street",
  },
  {
    field: nameOfPremisesInspScheduleView("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOfPremisesInspScheduleView("Area"),
    title: "Area",
  },
  {
    field: nameOfPremisesInspScheduleView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfPremisesInspScheduleView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfPremisesInspScheduleView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfPremisesInspScheduleView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfPremisesInspScheduleView("RefNo"),
    title: "Reference Number",
  },
  {
    field: nameOfPremisesInspScheduleView("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfPremisesInspScheduleView("DebtorNo"),
    title: "Debtor Number",
  },
  {
    field: nameOfPremisesInspScheduleView("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfPremisesInspScheduleView("Email"),
    title: "Email",
  },
  {
    field: nameOfPremisesInspScheduleView("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfPremisesInspScheduleView("PostalAddress2"),
    title: "Postal Address 2",
  },
  {
    field: nameOfPremisesInspScheduleView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfPremisesInspScheduleView("NoEmployees"),
    title: "Emp",
  },
  {
    field: nameOfPremisesInspScheduleView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfPremisesInspScheduleView("FSPContact"),
    title: "FSS Contact",
  },
  {
    field: nameOfPremisesInspScheduleView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfPremisesInspScheduleView("Fax"),
    title: "Fax",
  },
  {
    field: nameOfPremisesInspScheduleView("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfPremisesInspScheduleView("NextInspectionMonth"),
    title: "Next Inspection Month",
    format: DATE_FORMAT.MONTH_YEAR,
  },
];

export const generateColumnsInspScheduleBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;
  const debtorNumberLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
  );

  let columnsClone: IColumnFields[] = cloneDeep(defaultConfig);

  return columnsClone.filter((col) => {
    switch (col.field) {
      case nameOfPremisesInspScheduleView("DebtorNo"):
        if (debtorNumberLabel) {
          col.title = debtorNumberLabel;
        }
        return true;
      default:
        return true;
    }
  });
};
