import { APIResponse } from "@common/apis/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { DTO_Title_Format_Legal_Description } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/form-elements/title-details/model";

export const formatLegalDescription = async (
  payload: DTO_Title_Format_Legal_Description
): Promise<APIResponse<string>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/title/formatlegaldescription`,
      payload
    );

    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;

    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
