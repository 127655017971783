import { ICCRoute } from "@common/constants/ICCRoute";

export const allocationsRoute: ICCRoute = {
  path: "allocations",
  name: "Allocations",
  children: [
    {
      path: "allocations",
      name: "Allocations",
      component: require("./allocations/_index").default,
    },
    {
      path: "rounds-by-year",
      name: "Rounds By Year",
      component: require("./rounds-by-year/_index").default,
    },
    {
      path: "adjustments-by-year",
      name: "Adjustments By Year",
      component: require("./adjustments-by-year/_index").default,
    },
    {
      path: "rounds-by-service",
      name: "Rounds By Service",
      component: require("./rounds-by-service/_index").default,
    },
    {
      path: "adjustments-by-service",
      name: "Adjustments By Service",
      component: require("./adjustments-by-service/_index").default,
    },
  ],
};
