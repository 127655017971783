import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementAdminFacilityFeatures: IColumnFields[] = [
  { field: EventManagementFieldMapping.FeatureName, title: "Name", width: 450 },
  {
    field: EventManagementFieldMapping.DataType,
    title: "Data Type",
    width: 450,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.FeatureNameFacility,
    title: "Name on Facility Page",
    width: 450,
  },
  {
    field: EventManagementFieldMapping.FeatureNameEventBooking,
    title: "Name on Booking Page",
    width: 400,
  },
];
