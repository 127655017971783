import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import React from "react";

export const PPRCommunicationButton = () => {
  return (
    <CommunicationButtonListView
      productType={PRODUCT_TYPE_NUMBER.TownPlanning}
      recordType={RECORDTYPE.TP_PPRApplication}
    />
  );
};
