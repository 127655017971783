import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadAllWastewater } from "@app/products/waste-water/api";
import { NewWastewaterButton } from "@app/products/waste-water/components/action-bar/buttons/new-waste-water/_index";
import { WasteWaterActionBarNavDropdown } from "@app/products/waste-water/components/action-bar/nav-dropdown/_index";
import { WastewaterResponse } from "@app/products/waste-water/model";
import { wasteWaterRoute } from "@app/products/waste-water/route";
import { scheduleRoute } from "@app/products/waste-water/schedule/route";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { getRandomHex } from "@common/utils/common";
import { CCCalendar } from "@components/cc-calendar/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import React from "react";
import { useEffectOnce } from "react-use";

export default () => {
  const { resetStore } = useCCProductListViewStore();
  useCCListViewActionBar({
    title: wasteWaterRoute.name,
    leftComponents: [
      <WasteWaterActionBarNavDropdown category={scheduleRoute.path} />,
    ],
    centerComponents: [
      <NewWastewaterButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.WasteWater}
        dataSetFilter={MAILMERGEDATASET.WasteWater_Scheme}
        recordType={RECORDTYPE.WW_Scheme}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.WasteWater}
        recordType={RECORDTYPE.WW_Scheme}
      />,
    ],
  });

  const rawData = loadAllWastewater() as WastewaterResponse[];
  const itemFilter = rawData.map((item) => ({
    text: `Applied by ${item.Applicant}`,
    value: item.Applicant,
    color: getRandomHex(),
  }));

  //TODO: will remove resetStore when we fixed CCProductListView
  useEffectOnce(() => resetStore());

  return (
    <CCCalendar
      data={rawData.map((data: WastewaterResponse) => {
        let tempDate = new Date();
        tempDate = new Date(data.NextService);
        tempDate.setHours(0, 0, 0, 0); // Start of Day
        const startDate = tempDate.toString();

        tempDate.setHours(23, 59, 59, 999); // End of Day
        const endDate = tempDate.toString();

        return {
          id: data.ID,
          description: data.Applicant,
          title: `Permit Number ${data.PermitNumber} - ${
            data.Applicant || "N/A"
          }`,
          start: new Date(startDate),
          end: new Date(endDate),
          type: data.Applicant,
        };
      })}
      filterColumn={itemFilter}
      detailUrl={WASTE_WATER_ROUTE + "/"}
    />
  );
};
