import { DTO_DebtRecovery_DeferredDuty } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const getDebtRecoveryDeferredDuty = async (
  debtRecoveryId: number
): Promise<DTO_DebtRecovery_DeferredDuty | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_DebtRecovery_DeferredDuty>(
        `api/property/internal/debtrecovery/${debtRecoveryId}/defferedduty`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    return undefined;
  }
};
