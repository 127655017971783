import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const AssessmentPrelimAssessmentForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();

    const data = useDataSetting(configData, [
      "TP_Assessment_PrelimAssess",
      "TP_Assessment_Form_PrelimAssessment_Label",
    ]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <SwitchSettings
            data={data.TP_Assessment_PrelimAssess}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
          <InputSettings
            data={data.TP_Assessment_Form_PrelimAssessment_Label}
            isEditing={isEditing}
          />
        </div>
      </section>
    );
  }
);
