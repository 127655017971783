import { HealthManagerMenu } from "@app/products/hm/premises/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const financeRoute: ICCRoute = {
  path: "finance",
  name: "Finance",
  enum: HealthManagerMenu.Finance,
  children: [
    {
      path: "all-debtor-summary",
      name: "All Debtor Summary",
      enum: HealthManagerMenu.DebtorSummaryAll,
      //mock route: not implemented yet
      //component: require("./all-debtor-summary/_index").default,
    },
    {
      path: "os-debtor-summary",
      name: "OS Debtor Summary",
      enum: HealthManagerMenu.DebtorSummaryOutstanding,
      //mock route: not implemented yet
      //component: require("./os-debtor-summary/_index").default,
    },
    {
      path: "os-fees",
      name: "OS Fees",
      enum: HealthManagerMenu.FeesOutstanding,
      component: require("./os-fees/_index").default,
    },
    {
      path: "paid-fees",
      name: "Paid Fees",
      enum: HealthManagerMenu.FeesPaid,
      component: require("./paid-fees/_index").default,
    },
    {
      path: "not-send-to-finance",
      name: "Not Send To Finance",
      enum: HealthManagerMenu.FeesNotSentToFinance,
      component: require("./not-send-to-finance/_index").default,
    },
    {
      path: "send-to-finance",
      name: "Send To Finance",
      enum: HealthManagerMenu.FeesSentToFinance,
      component: require("./send-to-finance/_index").default,
    },
    {
      path: "exported-debtors",
      name: "Exported Debtors",
      enum: HealthManagerMenu.ExportedDebtors,
      //mock route: not implemented yet
      //component: require("./exported-debtors/_index").default,
    },
    {
      path: "not-exported-debtors",
      name: "Not Exported Debtors",
      enum: HealthManagerMenu.NotExportedDebtors,
      //mock route: not implemented yet
      //component: require("./not-exported-debtors/_index").default,
    },
    {
      path: "invoices-not-sent-to-finance",
      name: "Invoices Not Sent to Finance",
      enum: HealthManagerMenu.InvoicesNotSentToFinance,
      //mock route: not implemented yet
      //component: require("./invoices-not-sent-to-finance/_index").default,
    },
    {
      path: "invoices-sent-to-finance",
      name: "Invoices Sent to Finance",
      enum: HealthManagerMenu.InvoicesSentToFinance,
      //mock route: not implemented yet
      //component: require("./invoices-sent-to-finance/_index").default,
    },
    {
      path: "fees-all",
      name: "Fees All",
      enum: HealthManagerMenu.FeesAll,
      component: require("./fees-all/_index").default,
    },
  ],
};
