import {
  eSchemesInterestRateStatus,
  ISchemeFormGeneral,
} from "@app/products/property/schemes/[id]/model";

export const mockScheme: ISchemeFormGeneral = {
  SchemeNumber: "32050",
  SchemeId: 27,
  IsActive: true,
  SuppressProcessing: false,
  ShowOnAssessmentAmountOwingForm: true,
  RaiseInterestOnLastDayOnMonth: true,
  IncludeInAssessmentAmountOwingBalances: true,
  Name: "Peet Street Industrial Area Special Charge Scheme",
  SchemeType: "Private Streets Scheme",
  Comments: undefined,
  DateCommenced: undefined,
  DateInitiated: new Date("14-Aug-2018"),
  DateDeclared: new Date("15-May-2018"),
  Costs: [
    {
      Costs: "Cost",
      Estimated: 10298823.95,
    },
    {
      Costs: "Subsidy",
    },
    {
      Costs: "Cost to Assessments",
      Estimated: 10298823.95,
      isDisableEdit: true,
    },
  ],
  InstalmentType:
    "Fixed Instalment of varying Principal & Actual Interest Raised",
  RaiseInterestEvery: 3,
  InstalmentFrequency: 3,
  NumberOfInstalments: 16,
  DaysToPayInstalment: 30,
  InterestAndInstalmentDetailsGL: [
    {
      GLAccountType: "Principal Debtor Account",
      GLAccReference: "1000005015",
    },
    {
      GLAccountType: "Interest Debtor Account",
      GLAccReference: "1000005015",
    },
    {
      GLAccountType: "Principal Income Account",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Interest Income Account",
      GLAccReference: "1001751025",
    },
    {
      GLAccountType: "Other Income Account",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Other Debtor Account",
      GLAccReference: "1000005015",
    },
    {
      GLAccountType: "Bank",
      GLAccReference: "1000005001",
    },
    {
      GLAccountType: "Discount",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Principal Write Off",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Interest Write Off",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Other Write Off",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Interim Bank Account",
    },
  ],
  SchemesInterestRate: [
    {
      EffectiveFrom: new Date("07-Jan-2021"),
      InterestRate: 3.2,
      PenaltyRate: 0.9,
      Status: eSchemesInterestRateStatus.IN_USE,
      Id: "1",
      IsDisabledRow: true,
    },
    {
      EffectiveFrom: new Date("09-Jan-2021"),
      InterestRate: 5.7,
      PenaltyRate: 0.5,
      Status: eSchemesInterestRateStatus.IN_USE,
      Id: "2",
      IsDisabledRow: true,
    },
    {
      EffectiveFrom: new Date("10-Jan-2021"),
      InterestRate: 8.5,
      PenaltyRate: 1.5,
      Status: eSchemesInterestRateStatus.PROPOSED,
      Id: "3",
    },
    {
      EffectiveFrom: new Date("12-Jan-2021"),
      InterestRate: 6.5,
      PenaltyRate: 0.7,
      Status: eSchemesInterestRateStatus.PROPOSED,
      Id: "4",
    },
    {
      EffectiveFrom: new Date("14-Jan-2021"),
      InterestRate: 6.5,
      PenaltyRate: 0.7,
      Status: eSchemesInterestRateStatus.PROPOSED,
      Id: "5",
    },
  ],
};

export const mockSchemeFormGeneral: ISchemeFormGeneral = {
  SchemeId: 27,
  SchemeNumber: "32052",
  IsActive: true,
  SuppressProcessing: true,
  Costs: [
    {
      Costs: "Cost",
    },
    {
      Costs: "Subsidy",
    },
    {
      Costs: "Cost to Assessments",
      isDisableEdit: true,
    },
  ],
  InterestAndInstalmentDetailsGL: [
    {
      GLAccountType: "Principal Debtor Account",
      GLAccReference: "1000005015",
    },
    {
      GLAccountType: "Interest Debtor Account",
      GLAccReference: "1000005015",
    },
    {
      GLAccountType: "Principal Income Account",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Interest Income Account",
      GLAccReference: "1001751025",
    },
    {
      GLAccountType: "Other Income Account",
      GLAccReference: "1000005186",
    },
    {
      GLAccountType: "Other Debtor Account",
      GLAccReference: "1000005015",
    },
  ],
};
