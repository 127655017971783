import { LocationRegisterFormElement } from "@app/core/location-register/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { Register } from "@app/core/location-register/[id]/model";
import { useLocationRegisterStore } from "@app/core/location-register/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import { default as React, useEffect, useMemo, useRef } from "react";

export const LocationRegisterForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { locationRegister, setOnSubmit, saveLocationRegister } =
    useLocationRegisterStore();
  const isNew = useIsNew();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (data: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = data;
    const register = values.LocationRegister;

    const actionSubmit = data.event?.currentTarget.name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (!isModified && [ActionSubmitActions.Save].includes(actionSubmit))
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    if (isNil(register.Contact?.Contact)) {
      delete register.Contact;
      saveLocationRegister(
        { ...register, Contact_RID: null } as Register,
        isNew
      );
    } else {
      saveLocationRegister(register as Register, isNew);
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, locationRegister]);

  const initialValues = useMemo(() => {
    return {
      LocationRegister: locationRegister,
    };
  }, [locationRegister]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <LocationRegisterFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
