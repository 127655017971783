import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import { WorkflowTypes } from "@app/products/property/model";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class PropertyWorkflowStore {
  private _listDialog?: IListDialog[] = [];
  private _isLoadingReject: boolean = false;
  private _isLoadingSendBack: boolean = false;
  private _isLoadingAllocate: boolean = false;
  private _isLoadingReallocate: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get listDialog(): IListDialog[] {
    return toJS(this._listDialog) ?? [];
  }
  setListDialog = (listDialog: IListDialog[]) => {
    runInAction(() => {
      this._listDialog = listDialog;
    });
  };

  get isLoadingReject(): boolean {
    return this._isLoadingReject;
  }
  setIsLoadingReject = (isLoadingReject: boolean) => {
    runInAction(() => {
      this._isLoadingReject = isLoadingReject;
    });
  };

  get isLoadingSendBack(): boolean {
    return this._isLoadingSendBack;
  }
  setIsLoadingSendBack = (isLoadingSendBack: boolean) => {
    runInAction(() => {
      this._isLoadingSendBack = isLoadingSendBack;
    });
  };

  get isLoadingAllocate(): boolean {
    return this._isLoadingAllocate;
  }
  setIsLoadingAllocate = (isLoadingAllocate: boolean) => {
    runInAction(() => {
      this._isLoadingAllocate = isLoadingAllocate;
    });
  };

  get isLoadingReallocate(): boolean {
    return this._isLoadingReallocate;
  }
  setIsLoadingReallocate = (isLoadingReallocate: boolean) => {
    runInAction(() => {
      this._isLoadingReallocate = isLoadingReallocate;
    });
  };

  removeDialog = (enumDialog: WorkflowTypes) => {
    runInAction(() => {
      const cloneList: IListDialog[] = cloneDeep(this._listDialog) ?? [];
      const idxCurrentDialog = cloneList.findIndex(
        (dialogInfo: IListDialog) => {
          if (dialogInfo.type) {
            return dialogInfo.type === enumDialog;
          }
          return -1;
        }
      );
      if (idxCurrentDialog !== -1) cloneList.splice(idxCurrentDialog, 1);

      this._listDialog = cloneList;
    });
  };

  //Action
  resetData = () => {
    this.setListDialog([]);
  };
}

const propertyWorkflowStoreContext = createContext(new PropertyWorkflowStore());
export const usePropertyWorkflowStore = () => {
  return useContext(propertyWorkflowStoreContext);
};
