import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LocalGovernmentSubmitActions } from "@common/pages/settings/lookups/local-government/model";
import { useLocalGovernmentStore } from "@common/pages/settings/lookups/local-government/store";
import { LocalGovernmentForm } from "@common/pages/settings/lookups/local-government/[id]/components/child-screens/general/_index";
import { LookupsLocalGovernmentTabDetails } from "@common/pages/settings/lookups/local-government/[id]/components/reference-sidebar/detail/_index";
import { LookupsLocalGovernmentTabHistory } from "@common/pages/settings/lookups/local-government/[id]/components/reference-sidebar/history/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";

import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./_index.scss";

export const ExistedLocalGovernment = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    localGovernment,
    isLoading,
    responseLoadError,
    loadLocalGovernment,
    localGovernmentId,
    onSubmit,
  } = useLocalGovernmentStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        localGovernment?.Council_ID,
        localGovernment?.CouncilDetails?.DisplayName,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Lookups - Local Government - ${
        localGovernment?.Council_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Council,
      Record_ID: localGovernmentId ?? 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Local Government"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadLocalGovernment(parseInt(id))}
        />
      ) : (
        <div className="cc-local-government-form">
          <FormTitle title={localGovernment?.CouncilName || ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={LocalGovernmentSubmitActions.Save}
                onClick={onSubmit}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {localGovernment && <LocalGovernmentForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <LookupsLocalGovernmentTabDetails />,
                    },
                    {
                      title: "History",
                      component: <LookupsLocalGovernmentTabHistory />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
});
