import { DTO_AssessmentRebateEntitlementEntity } from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import {
  DTO_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAssociatedParcel = nameOfFactory<DTO_Parcel>();
export const colAssociatedParcels: IColumnFields[] = [
  {
    field: nameOfAssociatedParcel("ParcelReference"),
    title: "Parcel (S-S-B-U)",
  },
  {
    field: nameOfAssociatedParcel("PropertyAddress"),
    title: "Address",
  },
  {
    field: nameOfAssociatedParcel("Locality"),
    title: "Locality",
  },
  {
    field: nameOfAssociatedParcel("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfAssociatedParcel("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOfAssociatedParcel("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOfAssociatedParcel("StateCodeElectoralDivision"),
    title: "State Electoral Division",
  },
  {
    field: nameOfAssociatedParcel("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  {
    field: nameOfAssociatedParcel("LandArea"),
    title: "Land Area",
  },
  {
    field: nameOfAssociatedParcel("Zones"),
    title: "Zones",
  },
  {
    field: nameOfAssociatedParcel("LandUse"),
    title: "Land Use",
  },
  {
    field: nameOfAssociatedParcel("OwnerName"),
    title: "Owners",
  },
  {
    field: nameOfAssociatedParcel("Status"),
    title: "Status",
  },
  {
    field: nameOfAssociatedParcel("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfAssociatedTitle = nameOfFactory<DTO_Title>();
export const colAssociatedLots: IColumnFields[] = [
  {
    field: nameOfAssociatedTitle("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfAssociatedTitle("Status"),
    title: "Status",
  },
  {
    field: nameOfAssociatedTitle("Lot"),
    title: "Lot",
  },
  {
    field: nameOfAssociatedTitle("AssociationFactorPercentage"),
    title: "Factor %",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssociatedTitle("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOfAssociatedTitle("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOfAssociatedTitle("Section"),
    title: "Section",
  },
  {
    field: nameOfAssociatedTitle("NameAddress"),
    title: "Name Address",
  },
  {
    field: nameOfAssociatedTitle("Volume"),
    title: "Volume",
  },
  {
    field: nameOfAssociatedTitle("Folio"),
    title: "Folio",
  },
  {
    field: nameOfAssociatedTitle("County"),
    title: "County",
  },
  {
    field: nameOfAssociatedTitle("Parish"),
    title: "Parish",
  },
  {
    field: nameOfAssociatedTitle("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssociatedTitle("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfAssociatedTitle("LandArea"),
    title: "Land Area (m2)",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssociatedTitle("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAssociatedTitle("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOfAssociatedTitle("TitleId"),
    title: "Lot ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfAssociatedEntity =
  nameOfFactory<DTO_AssessmentRebateEntitlementEntity>();
export const colAssociatedEntities: IColumnFields[] = [
  {
    field: nameOfAssociatedEntity("EntitledEntitiesAsString"),
    title: "Contact",
  },
  {
    field: nameOfAssociatedEntity("IsEntitledToRebate"),
    title: "Entitled to Rebate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfAssociatedEntity("ConcessionCardHeld"),
    title: "Concession Cards Held",
  },
  {
    field: nameOfAssociatedEntity("Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
