export enum IdentityPacketErrorStatus {
  Unknown = "Unknown", // 0
  Success = "Success", // 1
  HasErrors = "HasErrors", // 2
  HasMoreErrors = "HasMoreErrors", // 3
  FailOnFirst = "FailOnFirst", // 4
  ConcurrencyViolation = "ConcurrencyViolation", // 5
  Notifications = "Notifications", // 6
}

export enum IdentityPacketErrorStatusNumber {
  Unknown,
  Success,
  HasErrors,
  HasMoreErrors,
  FailOnFirst,
  ConcurrencyViolation,
  Notifications,
}
