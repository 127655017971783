import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { useLocalGovernmentStore } from "@common/pages/settings/lookups/local-government/store";
import { getLocalGovernmentTabDetailsById } from "@common/pages/settings/lookups/local-government/[id]/components/reference-sidebar/detail/api";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const LookupsLocalGovernmentTabDetails = observer(() => {
  const { localGovernment } = useLocalGovernmentStore();
  const [isLoading, setIsLoading] = useState(false);
  const [lookupTabDetailsInfo, setLookupTabDetailsInfo] = useState<any>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const lookupId: any =
    lastSelectedRow && lastSelectedRow?.ID ? lastSelectedRow?.ID : params.id;

  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getLocalGovernmentTabDetailsById(lookupId);
    if (!isSuccessResponse(response) || !response.data) {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    } else {
      setLookupTabDetailsInfo(response.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!lookupId) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [localGovernment, lookupId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Council_ID:"}
          value={lookupTabDetailsInfo?.Council_ID}
        />
        <ProductReferenceRow
          title={"Council name:"}
          value={lookupTabDetailsInfo?.CouncilName}
        />
        {lookupTabDetailsInfo?.CouncilDetails ? (
          <ProductReferenceRow
            title={"Council details:"}
            value={lookupTabDetailsInfo?.CouncilDetails}
          />
        ) : null}
        {lookupTabDetailsInfo?.Officer ? (
          <ProductReferenceRow
            title={"Officer:"}
            value={lookupTabDetailsInfo?.Officer}
          />
        ) : null}
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDisplayValue(
            lookupTabDetailsInfo?.CreatedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Created by:"}
          value={lookupTabDetailsInfo?.CreatedBy ?? ""}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
