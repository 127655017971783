import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_Component_Document } from "@app/products/property/components/grid/document-issued-grid/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

/*
  if the "current" parameter is true
  then the API will return the current (latest) document
  otherwise, it will return the full list of documents 
 */
export const getDocumentIssued = async (
  componentNumber: eComponent,
  componentId: number | string,
  documentTypeId?: number | string,
  current = false
): Promise<APIResponse<DTO_Component_Document | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_Document>(
      `/api/property/int/document/${componentNumber}/${componentId}/load/${
        documentTypeId ?? 0
      }?current=${current}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
