import { ServiceStandardView } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/model";
import { colCategory } from "@app/products/crms/components/dialogs/category-sub-category/config";
import { NewSearchKeyDialog } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/dialogs/new-search-key/_index";
import { renderOptionPickServiceStandard } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { IShowDialogSearchKey } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/model";
import {
  ServiceStandard,
  ServiceStandardHandlerRequest,
  ServiceStandardMapObj,
  Svc_FormAction,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { columnFieldsCustom } from "@app/products/crms/service-standards/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboxSearchLov } from "@components/cc-combox-search-lov/_index";
import { CCEditor } from "@components/cc-editor/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { EditorChangeEvent } from "@progress/kendo-react-editor";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

export interface InternetInformationDetailsProps {
  formRenderProps: FormRenderProps;
}

export const InternetInformationDetails = observer(
  ({ formRenderProps }: InternetInformationDetailsProps) => {
    const [showDialogSearchKey, setShowDialogSearchKey] = useState<
      IShowDialogSearchKey | undefined
    >();
    const { valueGetter, onChange } = formRenderProps;

    const {
      serviceStandardChangeHandler,
      uiControl,
      serviceStandardDataFilter,
      setIsExpandInternetInformationDetails,
      accessRight,
    } = useCRMSServiceStandardStore();

    const { settings } = useCommonCoreStore();

    const serviceStandardCategoryLevels = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandardCategoryLevels
      ]
    );

    const subCategoryLevel3Label = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel3Caption
      ]
    );

    const subCategoryLevel4Label = getStringValueSetting(
      settings[
        ECorporateSettingsField
          .CUSTOMERSERVICE_ServiceStandardSubCategoryLevel4Caption
      ]
    );

    const serviceStandardFormObj = valueGetter(
      nameOfServiceStandardMapObj("ServiceStandard")
    );

    const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

    const handleChangeParentServiceStandard = (data: ServiceStandardView) => {
      if (!data) {
        onChange(
          `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
            "InternetParentServiceStandard_Id"
          )}`,

          { value: null }
        );
      }
      const params: ServiceStandardHandlerRequest = {
        ServiceStandardFormAction:
          Svc_FormAction.PickParentServiceStandardforTRIBE,
        ServiceStandard: serviceStandardFormObj,
        EventArgs: data ? [data.ID] : [],
        IsFirstTimeLoad: false,
      };

      serviceStandardChangeHandler(
        params,
        "Change parent service standard fail"
      );
      setIsExpandInternetInformationDetails(true);
    };

    const handleChangeSearchKey = (data: IKeyValuePacket) => {
      onChange(
        `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
          "CommunityEngagementSearchKey"
        )}`,
        { value: data?.Key }
      );
    };

    const handleSubmitSearchKey = (name: string) => {
      const params: ServiceStandardHandlerRequest = {
        ServiceStandardFormAction: Svc_FormAction.CreateSearchKey,
        ServiceStandard: serviceStandardFormObj,
        EventArgs: {
          CommunityEngagementSearchKey: 0,
          CategoryInternetSearchKey: name,
        },
        IsFirstTimeLoad: false,
      };
      serviceStandardChangeHandler(params, "Change search key fail");
      setIsExpandInternetInformationDetails(true);
    };

    return (
      <>
        {showDialogSearchKey && (
          <NewSearchKeyDialog
            titleDialog="New Search Key"
            onClose={() => setShowDialogSearchKey(undefined)}
            onSubmit={(name: string) => {
              handleSubmitSearchKey(name);
            }}
            data={showDialogSearchKey?.data}
          />
        )}
        <section className="cc-field-group">
          <CCLabel title="Internet" />
          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Enable upload control" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebEnableUploadControl")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebEnableUploadControl"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Assessment no is mandatory"
                  value={
                    valueGetter(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("Flag_AssessmentNo")}`
                    )
                      ? "Yes"
                      : "No"
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel title="House no is mandatory" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebHouseNumberMandatory")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebHouseNumberMandatory"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Email is mandatory" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebEmailMandatory")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebEmailMandatory"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Home phone is mandatory" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebHomePhoneMandatory")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebHomePhoneMandatory"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Work phone is mandatory" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebWorkPhoneMandatory")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebWorkPhoneMandatory"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Lookup council location" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebLookupCouncilLocation")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebLookupCouncilLocation"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Show councillor selection" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_WebShowCouncillor")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_WebShowCouncillor"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Sort order" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategorySortIndex")}`}
                  component={CCNumericTextBox}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Search key" />
                <Field
                  component={CCComboxSearchLov}
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CommunityEngagementSearchKey")}`}
                  dataLov={
                    uiControl?.DdlCommunityEngagementSearchKey?.Dataset ?? []
                  }
                  textField={"Value"}
                  dataItemKey={"Key"}
                  onChange={handleChangeSearchKey}
                  valueField={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "CommunityEngagementSearchKey"
                    )}`
                  )}
                  isShowNewFooter
                  onChangeNewButton={(searchKey: string) =>
                    setShowDialogSearchKey({
                      data: searchKey,
                    })
                  }
                />
              </div>
              <div className="cc-field cc-col-span-2">
                <CCValueField
                  value={
                    "Search Key enables the ability to group internet categories together.If you want two categories to be group together , make them both identical.The web request then needs to pass the search key through the URL for these categories to viewed by the resident. Eg.http://CouncilWebSite/Request.aspx?rid=graffiti_removal"
                  }
                  className="cc-text-info cc-padding-text-info"
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Web ID key" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategoryInternetFriendlyName")}`}
                  component={CCInput}
                />
              </div>
              <div className="cc-field cc-col-span-2">
                <CCValueField
                  value={
                    "Unique entry to match the internet category to this Service Standard."
                  }
                  className="cc-text-info cc-padding-text-info"
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Information only" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("Flag_InformationOnly")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "Flag_InformationOnly"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field cc-col-span-2">
                <CCValueField
                  value={
                    "Enable information only when you configure the web to have a multi level structure. When set to Yes the system will not create a request but open next level of category."
                  }
                  className="cc-text-info cc-padding-text-info"
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Parent key" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("InternetParentServiceStandard_Id")}`}
                  nameDisplay="Name"
                  placeholder="Select service standard"
                  component={InputPickerSearch}
                  valueMapGrid={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "InternetParentServiceStandard_Id"
                    )}`
                  )}
                  onChange={handleChangeParentServiceStandard}
                  options={renderOptionPickServiceStandard(
                    columnFieldsCustom(
                      serviceStandardCategoryLevels,
                      colCategory,
                      subCategoryLevel3Label,
                      subCategoryLevel4Label
                    ),
                    serviceStandardCategoryLevels,
                    serviceStandardDataFilter
                  )}
                  value={uiControl?.LblParentKeyServiceStandard?.Value ?? ""}
                />
              </div>
              <div className="cc-field cc-col-span-2">
                <CCValueField
                  value={
                    "Parent service standard.  Leave blank if this is the top level."
                  }
                  className="cc-text-info cc-padding-text-info"
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Web title" isMandatory />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("InternetPageTitle")}`}
                  component={CCInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Web subtitle" isMandatory />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("InternetPageSubTitle")}`}
                  component={CCInput}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Auto popup instructions" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("NeedAutoPopupInstructions")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "NeedAutoPopupInstructions"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Web instructions" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategoryWebInstructions")}`}
                  contentStyle={{ height: 150 }}
                  component={CCEditor}
                  value={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "CategoryWebInstructions"
                    )}`
                  )}
                  onChange={(event: EditorChangeEvent) => {
                    onChange(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("CategoryWebInstructions")}`,
                      { value: event.html }
                    );
                  }}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Web tags" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("InternetTags")}`}
                  component={CCInput}
                />
                <CCValueField
                  value="Please separate all tags by a comma. System tags available are [My House] , [My Family] , [My Business] "
                  className="cc-text-info"
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Confirmation content" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("ConfirmationContent")}`}
                  contentStyle={{ height: 150 }}
                  component={CCEditor}
                  value={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "ConfirmationContent"
                    )}`
                  )}
                  onChange={(event: EditorChangeEvent) => {
                    onChange(
                      `${nameOfServiceStandardMapObj(
                        "ServiceStandard"
                      )}.${nameOf("ConfirmationContent")}`,
                      { value: event.html }
                    );
                  }}
                />
              </div>
            </div>
            <div className="cc-form-col-3">
              <div className="cc-field">
                <CCLabel title="Only available to owners" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("IsAvailableOnlyToOwners")}`}
                  checked={valueGetter(
                    `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                      "IsAvailableOnlyToOwners"
                    )}`
                  )}
                  component={CCSwitch}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Issue location label" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("IssueLocationLabel")}`}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Description text option 1" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategoryDescriptionTextOption1")}`}
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Description text option 2" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategoryDescriptionTextOption2")}`}
                  component={CCInput}
                />
              </div>
            </div>
            <div className="cc-form-col-1">
              <div className="cc-field">
                <CCLabel title="Description checkboxes" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategoryDescriptionCheckboxes")}`}
                  component={CCTextArea}
                  rows={4}
                />
              </div>
            </div>
            <div className="cc-form-col-1">
              <div className="cc-field">
                <CCLabel title="Upload control additional text" />
                <Field
                  disabled={isDisabled}
                  name={`${nameOfServiceStandardMapObj(
                    "ServiceStandard"
                  )}.${nameOf("CategoryUploadControlAdditionalText")}`}
                  component={CCTextArea}
                  rows={4}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
);
