export const mockRebateEntitlementExceptions = [
  {
    Row: "4",
    AssessmentNumber: "49",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Pensioner",
    ENA_Name: "VIKSTROM, Anton Gote",
    ENA_Address: "1/57 Mount Stuart Rd\r\nALTONA, VIC   3018",
    AssessmentId: "49",
    EntityId: "4",
    RebateEntitlementId: "4",
  },
  {
    Row: "5",
    AssessmentNumber: "50",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    ENA_Name: "ABRAHALL, William Jame 222",
    ENA_Address:
      "32 Forrest St\r\nChange is from Advice of Sale - A4115 -\r\n27/11/07\r\nBLACK HILLS, TAS   7140",
    AssessmentId: "49",
    EntityId: "5",
    RebateEntitlementId: "3422",
  },
  {
    Row: "6",
    AssessmentNumber: "99",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate - General Rates Pensioner",
    ENA_Name: "ABRAHAM, Johathan Benjamin 12345",
    ENA_Address: "34 Circe Cir\r\nGROVE, TAS   7109",
    AssessmentId: "99",
    EntityId: "6",
    RebateEntitlementId: "1708",
  },
  {
    Row: "7",
    AssessmentNumber: "99",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    ENA_Name: "ABRAHAM, Teresa Mavis 1234",
    ENA_Address:
      "37a Pearse St\r\nChange is from Advice of Sale - A4115 -\r\n27/11/07\r\nNEW NORFOLK, TAS   7140",
    AssessmentId: "99",
    EntityId: "7",
    RebateEntitlementId: "4148",
  },
  {
    Row: "8",
    AssessmentNumber: "199",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    ENA_Name: "O'CONNOR, Katherine",
    ENA_Address: "3 Cobby St\r\nWILLIAMSTOWN NORTH, VIC   3016",
    LastRebateClaimId: "4",
    AssessmentId: "199",
    EntityId: "8",
    RebateEntitlementId: "5612",
  },
  {
    Row: "3",
    AssessmentNumber: "199",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClaimId: "3",
    AssessmentId: "199",
    EntityId: "9032",
    RebateEntitlementId: "5613",
  },
  {
    Row: "1",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "5",
    AssessmentId: "206",
    RebateEntitlementId: "13",
  },
  {
    Row: "2",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "6",
    AssessmentId: "206",
    RebateEntitlementId: "4763",
  },
  {
    Row: "9",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "10",
    AssessmentId: "206",
    RebateEntitlementId: "6507",
  },
  {
    Row: "lộ",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Serves Levy Pensioner",
    LastRebateClaimId: "14",
    AssessmentId: "206",
    RebateEntitlementId: "6507",
  },
  {
    Row: "11",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "9",
    AssessmentId: "206",
    RebateEntitlementId: "6508",
  },
  {
    Row: "12",
    AssessmentNumber: "206",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "13",
    AssessmentId: "206",
    RebateEntitlementId: "6508",
  },
  {
    Row: "13",
    AssessmentNumber: "215",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "14",
    AssessmentId: "215",
    RebateEntitlementId: "7346",
  },
  {
    Row: "14",
    AssessmentNumber: "215",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "13",
    AssessmentId: "215",
    RebateEntitlementId: "7347",
  },
  {
    Row: "15",
    AssessmentNumber: "215",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    AssessmentId: "215",
    EntityId: "2118",
    RebateEntitlementId: "5586",
  },
  {
    Row: "16",
    AssessmentNumber: "215",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Senior",
    AssessmentId: "215",
    EntityId: "2118",
    RebateEntitlementId: "5587",
  },
  {
    Row: "17",
    AssessmentNumber: "215",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    AssessmentId: "215",
    EntityId: "2118",
    RebateEntitlementId: "6631",
  },
  {
    Row: "18",
    AssessmentNumber: "215",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Senior",
    AssessmentId: "215",
    EntityId: "2118",
    RebateEntitlementId: "6632",
  },
  {
    Row: "19",
    AssessmentNumber: "215",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    AssessmentId: "215",
    EntityId: "2119",
    RebateEntitlementId: "5586",
  },
  {
    Row: "20",
    AssessmentNumber: "215",
    ExceptionType: "Rebate Type does not match Concession Cards held",
    RebateType: "State Government Rebate - General Rates Senior",
    AssessmentId: "215",
    EntityId: "2119",
    RebateEntitlementId: "5587",
  },
  {
    Row: "21",
    AssessmentNumber: "234",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "8",
    AssessmentId: "234",
    EntityId: "9172",
    RebateEntitlementId: "5596",
  },
  {
    Row: "22",
    AssessmentNumber: "234",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "7",
    AssessmentId: "234",
    EntityId: "9172",
    RebateEntitlementId: "5597",
  },
  {
    Row: "23",
    AssessmentNumber: "363",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    LastRebateClaimId: "8",
    AssessmentId: "363",
    EntityId: "10098",
    RebateEntitlementId: "5662",
  },
  {
    Row: "24",
    AssessmentNumber: "363",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Saner",
    LastRebateClaimId: "7",
    AssessmentId: "363",
    EntityId: "10098",
    RebateEntitlementId: "5663",
  },
  {
    Row: "25",
    AssessmentNumber: "385",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "8",
    AssessmentId: "335",
    EntityId: "11902",
    RebateEntitlementId: "5970",
  },
  {
    Row: "26",
    AssessmentNumber: "385",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "7",
    AssessmentId: "386",
    EntityId: "11902",
    RebateEntitlementId: "5971",
  },
  {
    Row: "27",
    AssessmentNumber: "398",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "8",
    AssessmentId: "398",
    EntityId: "1749",
    RebateEntitlementId: "5942",
  },
  {
    Row: "28",
    AssessmentNumber: "398",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "7",
    AssessmentId: "398",
    EntityId: "1749",
    RebateEntitlementId: "5943",
  },
  {
    Row: "29",
    AssessmentNumber: "401",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "22",
    AssessmentId: "401",
    RebateEntitlementId: "7272",
  },
  {
    Row: "30",
    AssessmentNumber: "401",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "21",
    AssessmentId: "401",
    RebateEntitlementId: "7273",
  },
  {
    Row: "31",
    AssessmentNumber: "421",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "13",
    AssessmentId: "421",
    RebateEntitlementId: "1748",
  },
  {
    Row: "32",
    AssessmentNumber: "421",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "14",
    AssessmentId: "421",
    RebateEntitlementId: "3267",
  },
  {
    Row: "33",
    AssessmentNumber: "458",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Pensioner",
    AssessmentId: "458",
    EntityId: "10455",
    RebateEntitlementId: "1754",
  },
  {
    Row: "34",
    AssessmentNumber: "458",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    AssessmentId: "458",
    EntityId: "10455",
    RebateEntitlementId: "3113",
  },
  {
    Row: "35",
    AssessmentNumber: "484",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    LastRebateClaimId: "12",
    AssessmentId: "484",
    EntityId: "12493",
    RebateEntitlementId: "5696",
  },
  {
    Row: "36",
    AssessmentNumber: "484",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rates Senior",
    LastRebateClaimId: "7",
    AssessmentId: "484",
    EntityId: "12493",
    RebateEntitlementId: "5697",
  },
  {
    Row: "37",
    AssessmentNumber: "498",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "13",
    AssessmentId: "498",
    RebateEntitlementId: "48",
  },
  {
    Row: "38",
    AssessmentNumber: "498",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "26",
    AssessmentId: "498",
    RebateEntitlementId: "4616",
  },
  {
    Row: "39",
    AssessmentNumber: "528",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "26",
    AssessmentId: "528",
    RebateEntitlementId: "7280",
  },
  {
    Row: "40",
    AssessmentNumber: "528",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rates Pensioner",
    LastRebateClaimId: "25",
    AssessmentId: "528",
    RebateEntitlementId: "7281",
  },
  {
    Row: "41",
    AssessmentNumber: "566",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Senior",
    AssessmentId: "566",
    EntityId: "5222",
    RebateEntitlementId: "4864",
  },
  {
    Row: "42",
    AssessmentNumber: "576",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - General Rales Pensioner",
    LastRebateClaimId: "13",
    AssessmentId: "576",
    RebateEntitlementId: "2807",
  },
  {
    Row: "43",
    AssessmentNumber: "576",
    ExceptionType: "Rebate Given while charges has Balance Owing > $0.09",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    LastRebateClaimId: "14",
    AssessmentId: "576",
    RebateEntitlementId: "3021",
  },
  {
    Row: "44",
    AssessmentNumber: "662",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "Stale Government Rebate - Emergency Service Levy Senior",
    AssessmentId: "662",
    EntityId: "10100",
    RebateEntitlementId: "5514",
  },
  {
    Row: "45",
    AssessmentNumber: "662",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rales Senior",
    AssessmentId: "662",
    EntityId: "10100",
    RebateEntitlementId: "5515",
  },
  {
    Row: "46",
    AssessmentNumber: "666",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - Emergency Service Levy Pensioner",
    AssessmentId: "666",
    EntityId: "8602",
    RebateEntitlementId: "5540",
  },
  {
    Row: "47",
    AssessmentNumber: "666",
    ExceptionType: "Pensioners with no Concession Cards",
    RebateType: "State Government Rebate - General Rales Pensioner",
    AssessmentId: "666",
    EntityId: "8602",
    RebateEntitlementId: "5541",
  },
];
