import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsECMCMIS {
  formRenderProps: FormRenderProps;
}

export const RecordsECMCMIS = observer(
  ({ formRenderProps }: IRecordsECMCMIS) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    const { valueGetter } = formRenderProps;

    const fieldAPIFileIntegrationDetails = getConfigDataField(
      ECorporateSettingsField.Records_ECMCMISAPIFileIntegration
    );
    const fieldEcmCMISSaveCommunications = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_SaveCommunications
    );
    const fieldECMCMISWorkingDirectory = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_WorkingDirectory
    );
    const fieldECMCMISHost = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_HostURL
    );
    const fieldECMCMISUsername = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_Username
    );
    const fieldECMCMISPassword = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_Password
    );
    const fieldECMCMISRepositoryID = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_RepositoryID
    );
    const fieldECMCMISDocLaunchURL = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_DocLaunchURL
    );
    const fieldECMCMISConnectionString = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_ConnectionString
    );
    const fieldECMCMISHostEnvironment = getConfigDataField(
      ECorporateSettingsField.ecmCMIS_HostEnvironment
    );

    return (
      <>
        {fieldEcmCMISSaveCommunications &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-3">
              <SwitchGlobalSettings
                data={fieldEcmCMISSaveCommunications}
                isEditing={isEditing}
                formRenderProps={formRenderProps}
              />
            </div>
          )}

        {fieldECMCMISWorkingDirectory &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldECMCMISWorkingDirectory}
                isEditing={isEditing}
              />
            </div>
          )}

        {fieldECMCMISHost &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldECMCMISHost}
                isEditing={isEditing}
              />
            </div>
          )}

        {fieldECMCMISUsername &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldECMCMISUsername}
                isEditing={isEditing}
              />
            </div>
          )}

        {fieldECMCMISPassword &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldECMCMISPassword}
                isEditing={isEditing}
              />
            </div>
          )}

        {fieldECMCMISRepositoryID &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldECMCMISRepositoryID}
                isEditing={isEditing}
              />
            </div>
          )}

        {fieldECMCMISDocLaunchURL && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldECMCMISDocLaunchURL}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldECMCMISConnectionString &&
          !valueGetter(fieldAPIFileIntegrationDetails?.FieldName ?? "") && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldECMCMISConnectionString}
                isEditing={isEditing}
              />
            </div>
          )}

        {fieldECMCMISHostEnvironment && (
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={fieldECMCMISHostEnvironment}
              isEditing={isEditing}
            />
          </div>
        )}
      </>
    );
  }
);
