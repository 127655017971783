import { mockCRS } from "@app/products/crs/mock";
import { CRSFormData } from "@app/products/crs/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getCRSSummaryById = async (
  id?: string
): Promise<APIResponse<CRSFormData | undefined>> => {
  await sleep(1000);
  const records: any[] = JSON.parse(JSON.stringify(mockCRS));
  const dataResponse = records.find((record) => String(record.ID) === id);
  if (dataResponse) {
    return {
      data: dataResponse as CRSFormData,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};

export const postCRS = async (requestBody: CRSFormData) => {
  await sleep(2000);
};

export const putCRS = async (requestBody: CRSFormData) => {
  await sleep(2000);
};
