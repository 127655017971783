import { comboBoxSearchData } from "@app/products/property/schemes/[id]/components/dialogs/new-scheme-account/config";
import {
  mockACollectionOfAssessment,
  mockActiveAssessmentsInStreet,
  mockAsAnAccountOfScheme,
  mockAssessment,
  mockNewSchemeDialog,
} from "@app/products/property/schemes/[id]/components/dialogs/new-scheme-account/mock";
import { AddAssessmentType } from "@app/products/property/schemes/[id]/components/dialogs/new-scheme-account/model";
import { notEmptyMaskInputValidator } from "@app/products/property/schemes/[id]/components/dialogs/new-scheme-account/util";
import { ISchemeForm } from "@app/products/property/schemes/[id]/model";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  Input,
  MaskedTextBox,
  RadioButton,
} from "@progress/kendo-react-inputs";
import React, { useRef } from "react";
interface INewSchemeAccountDialogProps {
  schemeInfo: ISchemeForm;
  onClose: () => void;
  onSubmit: (
    values: {
      [name: string]: any;
    },
    event?: React.SyntheticEvent<any>
  ) => void;
}
export const NewSchemeAccountDialog = ({
  onClose,
  onSubmit,
  schemeInfo,
}: INewSchemeAccountDialogProps) => {
  //const { SchemeNumber } = schemeInfo;
  const SchemeNumber = schemeInfo.General?.SchemeNumber ?? "0";
  const timeout = useRef<any>();
  const filterData = (filter: FilterDescriptor) => {
    const data = mockAssessment.slice();
    return filterBy(data, filter);
  };
  const handleSearchAssessment = (
    event: ComboBoxFilterChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    const { onChange } = formRenderProps;
    if (event.filter.value.length > 3) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        onChange("_option.AssessmentNumber.Data", {
          value: filterData(event.filter),
        });
        onChange("_option.AssessmentNumber.Loading", {
          value: false,
        });
      }, 1000);
      onChange("_option.AssessmentNumber.Loading", {
        value: true,
      });
    } else {
      onChange("_option.AssessmentNumber.Data", {
        value: [],
      });
    }
  };

  const accountNumberMask = `${SchemeNumber?.replaceAll(`0`, `\\0`).replaceAll(
    `9`,
    `\\9`
  )}.000000`;

  return (
    <Form
      initialValues={{
        ...mockNewSchemeDialog,
        AccountNumber: `${SchemeNumber}.000100`,
        ...comboBoxSearchData,
      }}
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;
        return (
          <CCDialog
            maxWidth="35%"
            maxHeight="65%"
            titleHeader={"New Scheme Account(s)"}
            onClose={onClose}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <label className="cc-label">Add</label>
                  <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-1 ">
                      <div className="cc-field">
                        <div>
                          <Field
                            name="AddAssessmentType"
                            component={RadioButton}
                            label="Assessment number"
                            checked={
                              valueGetter("AddAssessmentType") ===
                              AddAssessmentType.AssessmentNumber
                            }
                            value={AddAssessmentType.AssessmentNumber}
                          />
                        </div>
                      </div>

                      <div className="cc-field">
                        <Field
                          name="AssessmentNumber"
                          component={CCComboBox}
                          disabled={
                            valueGetter("AddAssessmentType") !==
                            AddAssessmentType.AssessmentNumber
                          }
                          filterable
                          suggest
                          textField="Assessment"
                          onFilterChange={(
                            event: ComboBoxFilterChangeEvent
                          ) => {
                            handleSearchAssessment(event, formRenderProps);
                          }}
                          data={valueGetter("_option.AssessmentNumber.Data")}
                          loading={valueGetter(
                            "_option.AssessmentNumber.Loading"
                          )}
                          placeholder="Type assessment number"
                          validator={
                            valueGetter("AddAssessmentType") ===
                            AddAssessmentType.AssessmentNumber
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </div>
                      {valueGetter("AssessmentNumber") ? (
                        <div className="cc-field">
                          <Input
                            value={
                              valueGetter("AssessmentNumber").FormattedAddress
                            }
                            disabled
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="cc-form-cols-1 ">
                      <div className="cc-field">
                        <div>
                          <Field
                            name="AddAssessmentType"
                            component={RadioButton}
                            checked={
                              valueGetter("AddAssessmentType") ===
                              AddAssessmentType.ACollectionOfAssessment
                            }
                            value={AddAssessmentType.ACollectionOfAssessment}
                            label="A collection of assessment"
                          />
                        </div>
                      </div>
                      <div className="cc-field">
                        <Field
                          name="ACollectionOfAssessment"
                          data={mockACollectionOfAssessment}
                          suggest
                          textField="Text"
                          component={CCComboBox}
                          placeholder="A collection of assessment"
                          disabled={
                            valueGetter("AddAssessmentType") !==
                            AddAssessmentType.ACollectionOfAssessment
                          }
                          validator={
                            valueGetter("AddAssessmentType") ===
                            AddAssessmentType.ACollectionOfAssessment
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1 ">
                      <div className="cc-field">
                        <div>
                          <Field
                            name="AddAssessmentType"
                            component={RadioButton}
                            checked={
                              valueGetter("AddAssessmentType") ===
                              AddAssessmentType.ActiveAssessmentsInStreet
                            }
                            value={AddAssessmentType.ActiveAssessmentsInStreet}
                            label="Active assessments in street"
                          />
                        </div>
                      </div>
                      <div className="cc-field">
                        <Field
                          name="ActiveAssessmentsInStreet"
                          placeholder="Active assessments in street"
                          component={CCComboBox}
                          suggest
                          data={mockActiveAssessmentsInStreet}
                          textField="Text"
                          disabled={
                            valueGetter("AddAssessmentType") !==
                            AddAssessmentType.ActiveAssessmentsInStreet
                          }
                          validator={
                            valueGetter("AddAssessmentType") ===
                            AddAssessmentType.ActiveAssessmentsInStreet
                              ? requiredValidator
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <br />
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">
                        As an account of scheme
                      </label>
                      <Field
                        name="AsAnAccountOfScheme"
                        component={CCComboBox}
                        suggest={true}
                        data={mockAsAnAccountOfScheme}
                        textField="Text"
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                </section>
                <br />
                <section className="cc-field-group">
                  <label className="cc-label">
                    Account number to be allocated
                  </label>
                  <div className="cc-form-cols-2 cc-custom-sub-panel-bar">
                    <div className="cc-field">
                      <label className="cc-label">Account number</label>

                      <Field
                        name="AccountNumber"
                        component={MaskedTextBox}
                        mask={accountNumberMask}
                        defaultValue="32052.000100"
                        validator={notEmptyMaskInputValidator}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">Increment by</label>
                      <Field
                        name="IncrementBy"
                        component={MaskedTextBox}
                        mask="000000"
                        defaultValue="000100"
                        validator={notEmptyMaskInputValidator}
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  themeColor="primary"
                  disabled={!formRenderProps.valid}
                  className={"cc-dialog-button"}
                  onClick={formRenderProps.onSubmit}
                >
                  Create Scheme Account(s)
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
