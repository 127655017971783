import { IButtonSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
export const defaultButtonSpatial = {
  isDisabled: false,
  isLoading: false,
  isHidden: false,
  loadSuccess: undefined,
  loadFail: undefined,
};
class WorkflowGroupButtonsSpatialStore {
  private _buttonMapping: IButtonSpatial = {
    ...defaultButtonSpatial,
  };
  private _buttonFetch: IButtonSpatial = {
    ...defaultButtonSpatial,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get buttonMapping(): IButtonSpatial {
    return toJS(this._buttonMapping);
  }
  setButtonMapping = (
    infoMapping:
      | IButtonSpatial
      | ((infoMapping: IButtonSpatial) => IButtonSpatial)
  ) => {
    let newInfo = {} as IButtonSpatial;
    runInAction(() => {
      if (typeof infoMapping === "function") {
        newInfo = infoMapping({ ...this._buttonMapping });
      } else {
        newInfo = { ...infoMapping };
      }
      this._buttonMapping = newInfo;
    });
  };

  get buttonFetch(): IButtonSpatial {
    return toJS(this._buttonFetch);
  }
  setButtonFetch = (
    infoFetch: IButtonSpatial | ((infoFetch: IButtonSpatial) => IButtonSpatial)
  ) => {
    let newInfo = {} as IButtonSpatial;
    runInAction(() => {
      if (typeof infoFetch === "function") {
        newInfo = infoFetch({ ...this._buttonFetch });
      } else {
        newInfo = { ...infoFetch };
      }
      this._buttonFetch = newInfo;
    });
  };

  //Action
  resetData = () => {
    this._buttonMapping = { ...defaultButtonSpatial };
    this._buttonFetch = {
      ...defaultButtonSpatial,
    };
  };
}

const workflowGroupButtonsSpatialStoreContext = createContext(
  new WorkflowGroupButtonsSpatialStore()
);
export const useWorkflowGroupButtonsSpatialStore = () => {
  return useContext(workflowGroupButtonsSpatialStoreContext);
};
