import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
  NumericTextBoxProps,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import React from "react";

export interface ICCNumericTextBoxProps {
  visited?: boolean;
}

export const CCNumericTextBox: React.FC<
  NumericTextBoxProps & ICCNumericTextBoxProps
> = ({ onChange, validationMessage, visited, ...others }) => {
  return (
    <>
      <NumericTextBox
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (onChange)
            onChange({ ...event, value: event.value === -0 ? 0 : event.value });
        }}
        {...others}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
