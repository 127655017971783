import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const UpdateNoticeOfSaleButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedRows } = useCCProductListViewStore();
  return (
    <CCNavButton
      title="Update notice of sale"
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Change_Of_Ownership_NoticeOfSale,
            data: {},
            props: {
              noticeOfSaleId: gridSelectedRows[0].NoticeofSale_Id,
              statusId: gridSelectedRows[0].Status_Id,
            },
          },
        ]);
      }}
      disabled={gridSelectedRows.length !== 1}
    />
  );
});
