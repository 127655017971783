import { getViewConfigurations } from "@app/products/property/api";
import { DTO_Certificate_AreaBalances } from "@app/products/property/certificates/[id]/components/child-screens/balances/model";
import {
  ICCViewConfiguration,
  ViewConfiguration,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type IGetCertificateBalanceViews =
  | [
      APIResponse<ICCViewConfiguration | undefined>,
      APIResponse<ICCViewConfiguration | undefined>
    ]
  | APIResponse<ICCViewConfiguration>
  | undefined;
export const getCertificateBalanceViews =
  async (): Promise<IGetCertificateBalanceViews> => {
    try {
      return await Promise.all([
        getViewConfigurations(ViewConfiguration.Certificate_AreaofBlances),
        getViewConfigurations(ViewConfiguration.Certificate_Rebates),
      ]);
    } catch (error) {
      const errorResponse: APIResponse = error as any;
      return {
        ...errorResponse,
        error: errorResponse.data?.Message,
      };
    }
  };

export const getCertificateBalance = async (
  certificateId: number
): Promise<APIResponse<DTO_Certificate_AreaBalances>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Certificate_AreaBalances>(
      `/api/property/int/certificate/${certificateId}/areaofbalance`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
