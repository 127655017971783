import { filter, find, first } from "lodash";
import { OOSys_Theme_LKP } from "@common/pages/settings/security/site-users/_id/model";
import {
  DEFAULT_THEME_NAME,
  centralThemeCondition,
  readyTheme,
} from "@common/pages/profile/constant";

export const defaultThemeLKPID = (themeData: OOSys_Theme_LKP[] | undefined) => {
  const centralActiveThemes = filter(themeData, centralThemeCondition) ?? [];
  const defaultTheme = (find(
    centralActiveThemes,
    (theme: OOSys_Theme_LKP) =>
      theme.Theme_Name === DEFAULT_THEME_NAME ||
      (theme.Theme_Name === readyTheme.Theme_Name &&
        theme.Theme_DisplayName === readyTheme.Theme_DisplayName)
  ) ?? first(centralActiveThemes)) as OOSys_Theme_LKP;

  return defaultTheme?.Theme_LKP_ID;
};
