import { PickActionTypeDialog } from "@app/products/crms/components/dialogs/pick-action-type/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

export interface ActionPlusButtonProps {
  onClick: (data: IRespActionType[]) => void;
}

export const ActionPlusButton = ({ onClick }: ActionPlusButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleAddAction = (data: IRespActionType[]) => {
    setIsShowDialog(false);
    onClick(data);
  };

  return (
    <>
      <Button
        type="button"
        iconClass="fas fa-plus"
        title="Add"
        onClick={() => setIsShowDialog(true)}
      />

      {isShowDialog && (
        <PickActionTypeDialog
          onCloseDialog={() => setIsShowDialog(false)}
          onSubmit={handleAddAction}
          productType={PRODUCT_TYPE.CustomerService}
        />
      )}
    </>
  );
};
