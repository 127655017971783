import { DTO_Component_ChangeOfOwnership } from "@app/products/property/assessments/[id]/components/child-screens/transfer/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getContactTransfer = async (
  contactId: number
): Promise<APIResponse<DTO_Component_ChangeOfOwnership | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_ChangeOfOwnership>(
      `/api/property/internal/Contact/${contactId}/changeofownership`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
