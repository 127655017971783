import { KeyValuePair } from "@app/core/documents/model";
import { EventClose } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import { CompleteMessageRequest } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/model";
import {
  ForwardOnRequest,
  PhoneMessage,
  PhoneMessageEventHandlerRequest,
  PhoneMessageEventHandlerResponse,
  PhoneMessageLOVs,
  SendToActionOfficerRequest,
} from "@app/products/crms/phone-messages/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getCRMSPhoneMessageSummaryById = async (
  id: number
): Promise<APIResponse<PhoneMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<PhoneMessage>(
      `/api/crms/internal/phone-message/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPhoneMsgEventNumber = async (): Promise<
  APIResponse<KeyValuePair<number, string> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<KeyValuePair<number, string>>(
      "/api/crms/internal/phone-message/event-number"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getPhoneMsgLovs = async (): Promise<
  APIResponse<PhoneMessageLOVs | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<PhoneMessageLOVs>(
      "/api/crms/internal/phone-message-lovs"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getData = async (
  dataUrl: string,
  filterSearch?: string
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      filterSearch ? `${dataUrl}${filterSearch}` : dataUrl
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const eventHandler = async (
  params: PhoneMessageEventHandlerRequest
): Promise<
  APIResponse<IIdentityPacket<PhoneMessageEventHandlerResponse> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<PhoneMessageEventHandlerResponse>
    >(`/api/crms/internal/phone-message/form-event-handler`, params);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const sendPhoneMessage = async (
  phoneMessage: PhoneMessage
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/phone-message/send`,
      phoneMessage
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const savePhoneMessage = async (
  phoneMessage: PhoneMessage
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/phone-message`,
      phoneMessage
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const convertToEvent = async (
  phoneMessage: PhoneMessage
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/phone-message/convert-to-event`,
      phoneMessage
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupCompleteMessage = async (
  phoneMessage: PhoneMessage
): Promise<APIResponse<IIdentityPacket<EventClose> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IIdentityPacket<EventClose>>(
      `/api/crms/internal/phone-message/setup-complete`,
      phoneMessage
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCompleteMessage = async (
  completeMessageRequest: CompleteMessageRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/phone-message/complete-message`,
      completeMessageRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postForwardOn = async (
  forwardOnRequest: ForwardOnRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/phone-message/forward-on`,
      forwardOnRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSentToActionOfficer = async (
  sendToActionOfficerRequest: SendToActionOfficerRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/phone-message/send-to-action-officer`,
      sendToActionOfficerRequest
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
