import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAssessmentLevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/levies-summary/config";
import { RebateSummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/components/rebate-summary/_index";
import { VO_Charge_Run_Assessment_Levy_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { usePageType } from "@common/hooks/usePageType";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOfLevySummaryGrid =
  nameOfFactory<VO_Charge_Run_Assessment_Levy_Summary>();
export const LeviesSummary = (data: any) => {
  const { isManagePage } = usePageType();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnViewConfig, setColumnViewConfig] = useState<IColumnFields[]>(
    colAssessmentLevySummary
  );
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadAssessmentLevySummaryViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Charge_Run_Assessment_LevySummary,
      colAssessmentLevySummary
    );
    if (Array.isArray(response)) {
      setColumnViewConfig(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadAssessmentLevySummaryViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadAssessmentLevySummaryViewConfig();
        }}
      />
    );

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          {isManagePage && <label className="cc-label">Levy summary</label>}
          <CCGrid
            className="cc-charge-run-levies-summary-gird"
            dataUrl={`odata/property/internal/chargerunassessmentlevyregister/GetLevySummary(chargeRunId=${
              data?.dataItem?.Charge_Run_Id ?? data?.data?.Charge_Run_Id
            },assessmentId=${
              data?.dataItem?.Assessment_Id ?? data?.data?.Assessment_Id
            },faid=11)?$count=true&`}
            columnFields={columnViewConfig}
            primaryField={nameOfLevySummaryGrid("Levy_Id")}
            detail={isManagePage ? RebateSummary : undefined}
          />
        </div>
      </div>
    </div>
  );
};
