import { CCNote } from "@components/cc-note/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IConfirmVoidReadingsDialog {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export const ConfirmVoidReadingsDialog = ({
  onClosePopup,
  onConfirmYes,
}: IConfirmVoidReadingsDialog) => {
  return (
    <ConfirmDialog
      width={"24%"}
      height={"auto"}
      title={"Confirm"}
      message={"Are you sure you wish to void the selected readings?"}
      subMessage={
        <CCNote
          message={`If the selected readings had resulted in any charges then these charges would need to be adjusted manually`}
        />
      }
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
