import { IPPRAppealCompleted } from "@app/products/town-planning/ppr/components/action-bar/dialogs/appeal-complete-application/model";
import { putAppealComplete } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-complete-application/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AppealCompleteButtonStore {
  private _isLoading: boolean = false;
  private _isShowDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isShowDialog() {
    return toJS(this._isShowDialog);
  }
  setIsShowDialog = (isShowDialog: boolean) => {
    runInAction(() => {
      this._isShowDialog = isShowDialog;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isShowDialog = false;
      this._isLoading = false;
    });
  };

  handleAppealComplete = async (data: IPPRAppealCompleted, pprId?: number) => {
    if (pprId) {
      this.setIsLoading(true);
      const response = await putAppealComplete(pprId, data);
      this.setIsShowDialog(false);
      this.setIsLoading(false);
      if (isSuccessResponse(response)) {
        appNotificationStore.clearErrorNotification();
        PPRStoreInstance.setPPRWithLoading(response.data.Application);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Appeal complete successfully",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Appeal complete failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };
}
export const appealCompleteButtonStore = new AppealCompleteButtonStore();
const appealCompleteButtonStoreContext = createContext(
  appealCompleteButtonStore
);
export const useAppealCompleteButtonStore = () => {
  return useContext(appealCompleteButtonStoreContext);
};
