import { useFeesStore } from "@app/core/fees/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { generateParentValueField } from "@common/utils/common";
import { CCValueField } from "@components/cc-value-field/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IFinanceFeeParentSectionProps {
  recordType: RECORDTYPE | undefined;
}

export const FinanceFeeParentSection = observer(
  ({ recordType }: IFinanceFeeParentSectionProps) => {
    const { ancestor } = useFeesStore();
    if (!ancestor) return null;

    return (
      <>
        {ancestor.lblParent1 !== "" ? (
          <>
            <section className="cc-field-group">
              {ancestor.AncestorIsSameAsParent ? null : (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCValueField
                      label={ancestor.lblAncestor1}
                      value={generateParentValueField(
                        ancestor.litAncestor1,
                        recordType
                      )}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label={ancestor.lblAncestor2}
                      value={generateParentValueField(
                        ancestor.litAncestor2,
                        recordType
                      )}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label={ancestor.lblAncestor3}
                      value={generateParentValueField(
                        ancestor.litAncestor3,
                        recordType
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCValueField
                    label={ancestor.lblParent1}
                    value={generateParentValueField(
                      ancestor.litParent1,
                      recordType
                    )}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    label={ancestor.lblParent2}
                    value={generateParentValueField(
                      ancestor.litParent2,
                      recordType
                    )}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    label={ancestor.lblParent3}
                    value={generateParentValueField(
                      ancestor.litParent3,
                      recordType
                    )}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
          </>
        ) : null}
      </>
    );
  }
);
