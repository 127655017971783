import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IAmendApplicationConfirmDialogProps {
  onClosePopup: () => void;
  onConfirmYes: () => void;
}
export const AmendApplicationConfirmDialog = ({
  onClosePopup,
  onConfirmYes,
}: IAmendApplicationConfirmDialogProps) => {
  return (
    <ConfirmDialog
      title={"Amend Application"}
      message={
        "Are you sure this request relates to an application, not a permit?"
      }
      height={"auto"}
      maxWidth={"25%"}
      onClosePopup={onClosePopup}
      onConfirmYes={onConfirmYes}
    />
  );
};
