import { checkIsValidFile } from "@app/core/attachment/components/dialogs/util";
import {
  ImportNOSFileRestrictions,
  fileFormatData,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/dialogs/import-notice-of-sale/components/form-elements/import-file/config";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCLabel } from "@components/cc-label/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const fileValidator = (data: any) => {
  if (!checkIsValidFile(data)) {
    return "File size too large.";
  }
  return requiredValidator(data);
};

export const ImportFileFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({ formRenderProps, nameOf }: IFormStepElement) => {
    const { onChange, valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const { settings } = useCommonCoreStore();
    const isEnableAttachmentSizeRestriction = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_EnableAttachmentSizeRestriction]
    );
    const attachmentSizeRestrictionSize = getNumberValueSetting(
      settings[ECorporateSettingsField.Global_AttachmentSizeRestrictionSize]
    );

    const allowedExtensions = [getFieldValue("FileFormat").Value];

    const maxFileSize = useMemo(() => {
      if (isEnableAttachmentSizeRestriction && attachmentSizeRestrictionSize) {
        return attachmentSizeRestrictionSize;
      }
      return ImportNOSFileRestrictions.DEFAULT_MAX_FILE_SIZE;
    }, [attachmentSizeRestrictionSize, isEnableAttachmentSizeRestriction]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="File format" />
            <Field
              name={nameOf("FileFormat")}
              component={CCDropDownList}
              textField={"Value"}
              dataItemKey={"Key"}
              data={fileFormatData}
              onChange={(event: DropDownListChangeEvent) => {
                onChange(nameOf("FileFormat"), {
                  value: event.value,
                });
                onChange(nameOf("File"), {
                  value: [],
                });
              }}
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel
            title="Upload file"
            isMandatory
            informationTooltip={`Maximum upload file size: ${
              maxFileSize / (1024 * 1024)
            } MB`}
          />
          <Field
            name={nameOf("File")}
            component={CCUploadFile}
            batch={false}
            multiple={false}
            autoUpload={false}
            accept={allowedExtensions}
            withCredentials={false}
            restrictions={{
              allowedExtensions,
              maxFileSize,
            }}
            onAdd={(event: UploadOnAddEvent) => {
              onChange(nameOf("File"), {
                value: event.newState,
              });
            }}
            onRemove={(event: UploadOnRemoveEvent) => {
              onChange(nameOf("File"), {
                value: event.newState,
              });
            }}
            showActionButtons={false}
            validator={fileValidator}
            files={valueGetter(nameOf("File"))}
          />
        </div>
      </section>
    );
  }
);
