import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";

interface MoveOrgStructureButtonProps {
  disabled?: boolean;
}
export const MoveOrgStructureButton = ({
  disabled,
}: MoveOrgStructureButtonProps) => {
  return <CCNavButton title="Move Org Unit" disabled={disabled} />;
};
