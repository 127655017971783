import { IECertificateRequest } from "@app/products/property/certificates/e-certificate-requests/[id]/model";

export const mockECertificateRequest: IECertificateRequest = {
  ID: 62,
  AgentReference: "57838626-010-8",
  Source: "LANDATA",
  MessageType: "STA - Request Status Enquire",
  CertificateNumber: "No Certificate record valid",
  ExceptionDescription: "",
  OrderStatus: "In Error",
  CertificateName: "Land Information Certificate",
  AdditionalReference: "",
  Name: "Bruce L Oldham C./- LANDATA",
  Address: "Level 14, 570 Bourke Street",
  EmailAddress: "certificates@landata.vic.gov.au",
  Phone: "8636 2456",
  Fax: "",
  DX: "",
  Country: "Australia",
  DateRequestRegistered: new Date("01-Jul-2006 10:20"),
  SettlementDate: new Date("29-Jun-2006"),
  TimeStamp: new Date("31-May-2006 09:35"),
  PreferredDeliveryMethod: "Email",
  CertificateCode: "24 - Land Information Certificate",
  PropertyPFI: "",
  ParcelPFI: "",
  ParcelId: "",
  Municipality: "HOBSONS BAY CITY COUNCIL",
  Extra: "",
  Parish: "Cut-paw-paw",
  StreetLoc: "47",
  StreetName: "WOODS STREET",
  Suburb: "NEWPORT",
  Postcode: "3105",
  UnitType: "",
  UnitNumber1: "",
  UnitNumber2: "",
  FloorType: "",
  FloorNumber: "",
  StreetNumber1: "47",
  StreetNumber2: "",
  PropertyStreetName: "WOODS",
  StreetType: "STREET",
  StreetSuffix: "",
  PropertySuburb: "NEWPORT",
  PropertyPostcode: "3105",
  PropertyName1: "",
  PropertyName2: "",
  LocationDescription: "",
};
