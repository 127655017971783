import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const inspectionsRoute: ICCRoute = {
  path: "inspections",
  name: "Inspections",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_Inspection,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: CRMSViews.Inspections_ByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-type",
      name: "By Type",
      enum: CRMSViews.Inspections_ByType,
      component: require("./by-type/_index").default,
    },
    {
      path: "by-officer",
      name: "By Officer",
      enum: CRMSViews.Inspections_ByOfficer,
      component: require("./by-officer/_index").default,
    },
    {
      path: "by-month",
      name: "By Month",
      enum: CRMSViews.Inspections_ByMonth,
      component: require("./by-month/_index").default,
    },
  ],
};
