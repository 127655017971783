export const mockVotingList = {
  NominateProperty: [
    {
      EffectiveDate: "28-Aug-2020",
      AssessmentId: 35147,
      AssessmentNumber: 5000008373,
      PropertyAddress: "3 Quirk Rd",
      Id: 2,
    },
  ],
  EntitlementByElection: [
    {
      Election: "Cardinia Shire Election Oct 2020 - 07/09/2020",
      CouldVote: true,
      Role: "Aus Citz, Applicant Voter",
      AssessmentId: 35147,
      AssessmentNumber: 5000008373,
      PropertyAddress: "3 Quirk Rd",
      Id: 1,
    },
  ],
};
