import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

interface IContactDeleteProps {
  disabled?: boolean;
}

export const ContactDeleteButton = observer(
  ({ disabled }: IContactDeleteProps) => {
    const { gridSelectedRows, setGridSelectedRows } =
      useCCProductListViewStore();
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState<boolean>(false);
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const handleOnSubmit = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const ids: number[] =
        gridSelectedRows
          ?.map((item) => item?.Id)
          ?.filter((id) => id !== null) ?? [];
      const response = await deleteRefUsageByIdAndRecordType(
        ids,
        RECORDTYPE.CORE_Contact,
        data?.Reason
      );
      setIsDeleting(false);
      setShowConfirmDeleteDialog(false);
      if (isSuccessResponse(response)) {
        setGridSelectedRows([]);
        clearNotifications();
        pushNotification({
          title: `${pluralize("Contact", ids.length)} deleted successfully`,
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: `Delete ${pluralize("contact", ids.length)} failed`,
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    return (
      <>
        <CCNavButton
          title="Delete"
          disabled={!gridSelectedRows?.length || disabled}
          isLoading={isDeleting}
          onClick={() => {
            setShowConfirmDeleteDialog(true);
          }}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => setShowConfirmDeleteDialog(false)}
            handleSubmit={handleOnSubmit}
            isDeleting={isDeleting}
            contentDelete={"Contact"}
            header={"Confirm Deletion"}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
