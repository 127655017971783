import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockCRSChildGender: IKeyValuePacket[] = [
  { Key: 1, Value: "Female" },
  { Key: 2, Value: "Male" },
];

export const mockCRSProofOfBirthAttached: IKeyValuePacket[] = [
  { Key: 2, Value: "No" },
  { Key: 1, Value: "Yes" },
];

export const mockCRSAddNeedsAssessed: IKeyValuePacket[] = [
  { Key: 2, Value: "No" },
  { Key: 1, Value: "Yes" },
];

export const mockCRSResident: IKeyValuePacket[] = [
  { Key: 2, Value: "No" },
  { Key: 1, Value: "Yes" },
];

export const mockCRSAllowCentreAccess: IKeyValuePacket[] = [
  { Key: 2, Value: "No" },
  { Key: 1, Value: "Yes" },
];

export const mockCRSPaymentPlan: IKeyValuePacket[] = [
  { Key: 2, Value: "No" },
  { Key: 1, Value: "Yes" },
];

export const mockCRSCountryOfBirth: IKeyValuePacket[] = [
  { Key: 1, Value: "Australia" },
];

export const mockCRSRelationToChild: IKeyValuePacket[] = [
  { Key: 1, Value: "Mother" },
];

export const mockCRSCountryOfOrigin: IKeyValuePacket[] = [
  { Key: 1, Value: "Australia" },
];

export const mockCRSLanguage: IKeyValuePacket[] = [
  { Key: 1, Value: "Chinese" },
  { Key: 1, Value: "Dutch" },
  { Key: 1, Value: "English" },
  { Key: 1, Value: "Maltese" },
];

export const mockCRSInterpreterRequired: IKeyValuePacket[] = [
  { Key: 1, Value: "No" },
  { Key: 2, Value: "Yes" },
];

export const mockCRSSubsidyCard: IKeyValuePacket[] = [
  { Key: 2, Value: "Health Care Card" },
  { Key: 1, Value: "None" },
];
