import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { useJournalSessionDetailsStore } from "@app/products/property/journals/session-list/[id]/components/reference-sidebar/detail/store";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import SessionDialog from "@app/products/property/journals/session-list/components/dialogs/session-dialog/_index";
import { putUpdateSession } from "@app/products/property/journals/session-list/components/dialogs/session-dialog/api";
import { ISessionGridDialog } from "@app/products/property/journals/session-list/components/dialogs/session-dialog/model";
import { SESSION_STATUS_NAME_CAN_PROCESS } from "@app/products/property/journals/session-list/config";
import { VO_Journal_SessionList } from "@app/products/property/journals/session-list/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { map } from "lodash";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useEffect, useRef, useState } from "react";
const nameOfSession = nameOfFactory<VO_Journal_SessionList>();
export const UpdateToGeneralLedgerButton = observer(() => {
  const [showUpdateToGeneralLedgerDialog, setShowUpdateToGeneralLedgerDialog] =
    useState(false);
  const { gridSelectedRows, clearSelectedItems } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { sessionList, reloadSessionList } = useSessionListStore();
  const { reLoadJournalSessionDetails } = useJournalSessionDetailsStore();
  const [itemsUpdated, setItemsUpdated] = useState<ISessionGridDialog[]>([]);
  const [itemsToBeUpdated, setItemsToBeUpdated] = useState<
    ISessionGridDialog[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  //ref local notification
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  const processList = (status: string) => {
    return gridSelectedRows.filter(
      (item: VO_Journal_SessionList) => item.Session_Status_Name === status
    );
  };

  useEffect(() => {
    //Manage page
    if (sessionList) {
      if (
        sessionList.Session_Status ===
        SESSION_STATUS_NAME_CAN_PROCESS.ToBeUpdated
      ) {
        setItemsToBeUpdated([
          {
            Session_Number: sessionList.SessionNumber,
            Session_Name: sessionList.Session_Name,
          },
        ]);
      } else {
        setItemsToBeUpdated([]);
      }
      setItemsUpdated([]);
      return;
    }
    //List view
    setItemsUpdated(processList(SESSION_STATUS_NAME_CAN_PROCESS.Updated));
    setItemsToBeUpdated(
      processList(SESSION_STATUS_NAME_CAN_PROCESS.ToBeUpdated)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSelectedRows, sessionList]);

  const handleUpdate = () => {
    setShowUpdateToGeneralLedgerDialog(true);
  };
  const handleUpdateSession = async () => {
    if (!itemsToBeUpdated) return;
    setIsSubmitting(true);
    const sessionNumbers = map(
      itemsToBeUpdated,
      nameOfSession("Session_Number")
    );
    const response = await putUpdateSession(sessionNumbers);
    setIsSubmitting(false);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      setShowUpdateToGeneralLedgerDialog(false);
      pushNotification({
        title:
          response?.data?.SuccessMessage ??
          `Session(s) was updated successfully.`,
        type: "success",
      });
      //Refresh list view
      if (gridSelectedRows && gridSelectedRows.length > 0) {
        eventEmitter.emit(CCGridEventType.RefreshOData);
        clearSelectedItems();
      }
      //Refresh history tab
      eventEmitter.emit(CCJournalEventType.RefreshData);
      //Refresh detail tab
      reLoadJournalSessionDetails();
      //Refresh manage page
      reloadSessionList();
    } else {
      notificationRef.current?.pushNotification({
        title:
          response?.data?.ErrorMessage ?? "Session(s) could not be updated.",
        type: "error",
        autoClose: false,
      });
    }
  };
  return (
    <>
      <CCNavButton
        title="Update"
        onClick={handleUpdate}
        disabled={itemsToBeUpdated?.length === 0}
      />
      {showUpdateToGeneralLedgerDialog && itemsToBeUpdated && (
        <SessionDialog
          onClosePopup={() => setShowUpdateToGeneralLedgerDialog(false)}
          onOkPopup={() => {
            handleUpdateSession();
          }}
          listItem={itemsToBeUpdated}
          title="Update Confirmation"
          msg={`The selected ${pluralize(
            "session",
            itemsToBeUpdated.length
          )} will be updated:`}
          warning={
            itemsUpdated?.length !== 0
              ? `${pluralize("Session", itemsUpdated.length)} ${pluralize(
                  "have",
                  itemsUpdated.length
                )} been excluded.`
              : ""
          }
          isSubmitting={isSubmitting}
          notificationRef={notificationRef}
        />
      )}
    </>
  );
});
