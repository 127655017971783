import { IAssociateMeter } from "@app/products/property/registers/[id]/components/dialogs/associate-meter/model";
import { MeterSearchBy } from "@components/cc-search-meter/config";

export const mockAssociateMeter: IAssociateMeter = {
  AccountReference: "MGW-28",
  AccountId: 2010,
  PropertyAddress: "27 Aberdeen St, Archies Creek",
  AccountNames: "L J GILES",
  DateOfAttachment: new Date("24-Jan-2022 2:43"),
  UsageEntitlement: null,
  BillingGroup: { Key: 1, Value: "Monthly Billing" },
  MeterSearchResult: null,
  _option: {
    BillingGroup: {
      Data: [
        { Key: 1, Value: "Monthly Billing" },
        { Key: 2, Value: "Standard Periodic Billing" },
      ],
    },
    MeterSearchBy: {
      Data: MeterSearchBy,
      Value: MeterSearchBy[0],
    },
  },
};
