import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_AssociatedItem_Assessment>();

export const colNominatedProperty: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("PropertyName_Address_Locality"), title: "Property Address" },
  {
    field: nameOf("Association"),
    title: "Role",
  },
];
