import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { PickOrgStructurePicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/_index";
import { IOrgUnit } from "@app/products/town-planning/ppr/[id]/components/input-picker/pick-org-structure/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import {
  renderMiddle1SwitchListEnums,
  renderMiddle2SwitchListEnums,
  renderMiddle3SwitchListEnums,
  renderTopSwitchListEnums,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/application_general/model";
import { ApplicationSettingMultiChoice } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-checkbox/_index";
import { ApplicationSettingSearchComboBox } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-search-combobox/_index";
import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { ApplicationSettingText } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-text-input/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export const ApplicationGeneralForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData, getConfigDataField } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const nameOfLookupItem = nameOfFactory<ILookupItem>();
    const isVisibleMetroFeeLimit =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_UseMetro.toString()
      ) ?? false;
    const isShowConditionStages =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_ShowConditionStages.toString()
      ) ?? false;
    const isShowCovenantDisclaimerCaption =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_CovenantDisclaimerEnable.toString()
      ) ?? false;
    const isShowValidationInApplicationBlock =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_ApplicationValidation.toString()
      ) ?? false;
    const isShowListAssessmentAuthority =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_Enable_AssessmentAuthority.toString()
      ) ?? false;
    const fieldObjector = getConfigDataField(
      ECorporateSettingsField.TP_Objectors
    );
    const objectorValueDisplay =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_Objectors}`
      ) ?? [];

    const fieldSupporterType = getConfigDataField(
      ECorporateSettingsField.TP_Supporters
    );

    const supporterTypeValueDisplay =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_Supporters}`
      ) ?? [];

    const orgStructureDisplayValue =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_OfficerOrgStructure}`
      )[0] ?? "";

    if (!configData) return <></>;
    const fieldConditionType =
      configData[ECorporateSettingsField.TP_ConditionTypes.toString()];

    const handleOnChangeOrgStructure = (selectedValue: IOrgUnit | null) => {
      formRenderProps?.onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_OfficerOrgStructure}`,
        {
          value: [selectedValue?.Hierarchy],
        }
      );
      formRenderProps?.onChange(
        configData[ECorporateSettingsField.TP_OfficerOrgStructure.toString()]
          .FieldName ?? "",
        {
          value: selectedValue?.ID,
        }
      );
    };

    const commonCurrencyComponent = (enumParam: ECorporateSettingsField) => {
      return (
        configData[enumParam.toString()] && (
          <div className="cc-field">
            <CCLabel
              title={configData[enumParam.toString()].Title ?? ""}
              isMandatory={configData[enumParam.toString()].IsMandatory}
            />
            <Field
              name={configData[enumParam.toString()].FieldName}
              component={CCCurrencyInput}
              disabled={!isEditing}
              validator={
                configData[enumParam.toString()].IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )
      );
    };

    const fieldAssessmentAuthorityValueDisplay =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_AssessmentAuthorityCustomList}`
      ) ?? [];

    const handleOnChangeMultiSelect = (
      event: MultiSelectChangeEvent,
      enumParam: ECorporateSettingsField
    ) => {
      const selectedValues = event.target.value as ILookupItem[];
      formRenderProps?.onChange(`option.ValueDisplayDetails_${enumParam}`, {
        value: selectedValues,
      });
      formRenderProps?.onChange(
        configData[enumParam.toString()].FieldName ?? "",
        {
          value: selectedValues
            .map((item: ILookupItem) => item.Name)
            .toString(),
        }
      );
    };
    const commonMultiSelectComponent = (
      enumParam: ECorporateSettingsField,
      displayValue: any
    ) => {
      return (
        configData[enumParam.toString()] && (
          <div className="cc-field">
            <CCLabel
              title={configData[enumParam.toString()].Title ?? ""}
              isMandatory={configData[enumParam.toString()].IsMandatory}
            />
            <Field
              name={configData[enumParam.toString()].FieldName}
              component={CCMultiSelectDropdown}
              data={configData[enumParam.toString()].LookupItems}
              textField={nameOfLookupItem("Name")}
              dataItemKey={nameOfLookupItem("Id")}
              value={displayValue}
              disabled={!isEditing}
              onChange={(event: MultiSelectChangeEvent) =>
                handleOnChangeMultiSelect(event, enumParam)
              }
              validator={
                configData[enumParam.toString()].IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )
      );
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_DefaultIPRole}
          />
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_DefaultIPCategory}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_SendAllocationEmail}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_SendRequesterEmailWhenAllocatePlanner
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_AllocateToPlannerCCEmail}
          />
          {renderTopSwitchListEnums.map(
            (enumParam: ECorporateSettingsField) => {
              return (
                <ApplicationSettingSwitch
                  enumParam={enumParam}
                  formRenderProps={formRenderProps}
                />
              );
            }
          )}

          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_UseMetro}
            formRenderProps={formRenderProps}
          />
          {isVisibleMetroFeeLimit &&
            commonCurrencyComponent(ECorporateSettingsField.TP_MetroFeeLimit)}
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_DefaultTab}
          />
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_DefaultDefaultConditionGroup}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ShowConditionStages}
            formRenderProps={formRenderProps}
          />
          {isShowConditionStages && (
            <ApplicationSettingSearchComboBox
              enumParam={ECorporateSettingsField.TP_Default_ConditionStage}
            />
          )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ShowDPCDecisionType}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_SendEmailOnApplicationsDue}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_IssueNODObjectorCheck}
            formRenderProps={formRenderProps}
          />
          {configData[ECorporateSettingsField.TP_Objectors] && (
            <div className="cc-field">
              <label className="cc-label">
                {fieldObjector?.Title ?? ""}
                {fieldObjector?.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
                {fieldObjector?.HelpText && (
                  <CCTooltip
                    type="custom"
                    content=" "
                    position="auto"
                    clickToOpen
                    customTemplate={<>{sanitizeHtml(fieldObjector.HelpText)}</>}
                  >
                    <i className="fa fa-info-circle ml-1 text-accent" />
                  </CCTooltip>
                )}
              </label>
              <Field
                name={fieldObjector?.FieldName ?? ""}
                placeholder={fieldObjector?.Title ?? ""}
                value={objectorValueDisplay ?? null}
                component={KeyWordsPicker}
                keyWordType={KEYWORD_TYPE.Core_ContactRole}
                productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                onPickKeyWords={(keywords: CoreKeyword[]) => {
                  formRenderProps?.onChange(fieldObjector?.FieldName ?? "", {
                    value: keywords
                      .map((keyword: CoreKeyword) => keyword.Keyword_ID)
                      .toString(),
                  });
                  formRenderProps?.onChange(
                    `option.ValueDisplayDetails_${ECorporateSettingsField.TP_Objectors}`,
                    { value: keywords }
                  );
                }}
                isGetDetail={false}
                disabled={!isEditing}
                validator={
                  fieldObjector?.IsMandatory ? requiredValidator : undefined
                }
              />
            </div>
          )}
          {configData[ECorporateSettingsField.TP_Supporters] && (
            <div className="cc-field">
              <label className="cc-label">
                {fieldSupporterType?.Title ?? ""}
                {fieldSupporterType?.IsMandatory && (
                  <CCTooltip type="validator" position="right" />
                )}
                {fieldSupporterType?.HelpText && (
                  <CCTooltip
                    type="custom"
                    content=" "
                    position="auto"
                    clickToOpen
                    customTemplate={
                      <>{sanitizeHtml(fieldSupporterType.HelpText)}</>
                    }
                  >
                    <i className="fa fa-info-circle ml-1 text-accent" />
                  </CCTooltip>
                )}
              </label>
              <Field
                name={fieldSupporterType?.FieldName ?? ""}
                placeholder={fieldSupporterType?.Title ?? ""}
                value={supporterTypeValueDisplay ?? null}
                component={KeyWordsPicker}
                keyWordType={KEYWORD_TYPE.Core_ContactRole}
                productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                onPickKeyWords={(keywords: CoreKeyword[]) => {
                  formRenderProps?.onChange(
                    fieldSupporterType?.FieldName ?? "",
                    {
                      value: keywords
                        .map((keyword: CoreKeyword) => keyword.Keyword_ID)
                        .toString(),
                    }
                  );
                  formRenderProps?.onChange(
                    `option.ValueDisplayDetails_${ECorporateSettingsField.TP_Supporters}`,
                    { value: keywords }
                  );
                }}
                isGetDetail={false}
                disabled={!isEditing}
                validator={
                  fieldSupporterType?.IsMandatory
                    ? requiredValidator
                    : undefined
                }
              />
            </div>
          )}
          {renderMiddle1SwitchListEnums.map(
            (enumParam: ECorporateSettingsField) => {
              return (
                <ApplicationSettingSwitch
                  enumParam={enumParam}
                  formRenderProps={formRenderProps}
                />
              );
            }
          )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_CovenantDisclaimerEnable}
            formRenderProps={formRenderProps}
          />
          {isShowCovenantDisclaimerCaption && (
            <ApplicationSettingText
              enumParam={ECorporateSettingsField.TP_CovenantDisclaimerQuestion}
            />
          )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_ApplicationValidation}
            formRenderProps={formRenderProps}
          />
          {isShowValidationInApplicationBlock && (
            <div className="cc-form-cols-2">
              <ApplicationSettingSwitch
                enumParam={
                  ECorporateSettingsField.TP_ReferralMandatoryInValidation
                }
                formRenderProps={formRenderProps}
              />
              <ApplicationSettingSwitch
                enumParam={ECorporateSettingsField.TP_StatBasedOnValidation}
                formRenderProps={formRenderProps}
              />
            </div>
          )}
          {configData[ECorporateSettingsField.TP_OfficerOrgStructure] && (
            <div className="cc-field">
              <CCLabel title={"Officer Org Structure"} isMandatory={false} />
              <Field
                name={
                  configData[ECorporateSettingsField.TP_OfficerOrgStructure]
                    .FieldName
                }
                component={PickOrgStructurePicker}
                value={orgStructureDisplayValue}
                allowClear={true}
                adhocGroup={false}
                disabled={!isEditing}
                onPickOrgStructure={handleOnChangeOrgStructure}
              />
            </div>
          )}
          {renderMiddle2SwitchListEnums.map(
            (enumParam: ECorporateSettingsField) => {
              return (
                <ApplicationSettingSwitch
                  enumParam={enumParam}
                  formRenderProps={formRenderProps}
                />
              );
            }
          )}
        </div>
        <div className="cc-form-cols-1">
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_DisplayPropertyZoneOverlay}
            formRenderProps={formRenderProps}
          />
        </div>
        <div className="cc-form-cols-3">
          {renderMiddle3SwitchListEnums.map(
            (enumParam: ECorporateSettingsField) => {
              return (
                <ApplicationSettingSwitch
                  enumParam={enumParam}
                  formRenderProps={formRenderProps}
                />
              );
            }
          )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_AssessmentAuthority}
            formRenderProps={formRenderProps}
          />
          {isShowListAssessmentAuthority &&
            commonMultiSelectComponent(
              ECorporateSettingsField.TP_AssessmentAuthorityCustomList,
              fieldAssessmentAuthorityValueDisplay
            )}
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_PlansToComply}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_PortalEnableClearDocumentsPublished
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_FeesPaid}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_ComplaintsSource}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_UseComplyDevelopmentCertificate
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={
              ECorporateSettingsField.TP_Enable_DeferredCommencement_OfficerDecision
            }
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Use_FeeCalculator}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_UserSubStatus}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSearchComboBox
            enumParam={ECorporateSettingsField.TP_Banner_DisplayOption}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_NCIPInStdView}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_CostOfWorks}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Menu_Enable_Certification}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_Enable_ApplicationType}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingMultiChoice
            field={fieldConditionType}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
