export const mockNames = [
  {
    ID: 3,
    Name: "Akers-Jones, B A",
    State: "Vic",
    Address: "Fassifern\r\n50 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Akers-Jones, B A\r\nFassifern\r\n50 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 4,
    Name: "Ridgway, N",
    State: "Vic",
    Address: "60 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Ridgway, N\r\n60 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 5,
    Name: "Prowse, J L & M A",
    State: "Vic",
    Address: "Rosemont\r\n11 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Prowse, J L & M A\r\nRosemont\r\n11 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 6,
    Name: "Haussegger, E A & M A",
    State: "Vic",
    Address: "Mandalay\r\n25 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Haussegger, E A & M A\r\nMandalay\r\n25 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 7,
    Name: "Litkowski, A M & K L",
    State: "Vic",
    Address: "31 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Litkowski, A M & K L\r\n31 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 8,
    Name: "Cockerell, G L",
    State: "Vic",
    Address: "57 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Cockerell, G L\r\n57 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 9,
    Name: "Konecny, A R & J A",
    State: "Vic",
    Address: "71 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Konecny, A R & J A\r\n71 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 10,
    Name: "Fullfield P/L",
    State: "Vic",
    Address: "405 Alma Rd",
    Locality: "Caulfield North",
    NameAndAddress:
      "Fullfield P/L\r\n405 Alma Rd\r\nCAULFIELD NORTH, VIC   3161",
    Postcode: "3161",
  },
  {
    ID: 11,
    Name: "Pitt, P W",
    State: "Vic",
    Address: "120b A'Beckett Rd",
    Locality: "Beaconsfield Upper, Vic, 3808",
    NameAndAddress:
      "Pitt, P W\r\n120b A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC, 3808, VIC   380\r\n8",
    Postcode: "3808",
  },
  {
    ID: 13,
    Name: "Mylius, V",
    State: "Vic",
    Address: "120 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Mylius, V\r\n120 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 14,
    Name: "Elsner, D M",
    State: "Vic",
    Address: "110 A'Beckett Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Elsner, D M\r\n110 A'Beckett Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 15,
    Name: "Peet Cardinia Lakes Syndicate Ltd",
    State: "Vic",
    Address: "Level 2  470 St Kilda Rd",
    Locality: "Melbourne",
    NameAndAddress:
      "Peet Cardinia Lakes Syndicate Ltd\r\nLevel 2  470 St Kilda Rd\r\nMELBOURNE, VIC   3000",
    Postcode: "3000",
  },
  {
    ID: 16,
    Name: "Ronview Nominees P/L & Ronview P/L",
    State: "Vic",
    Address: "PO Box 138",
    Locality: "Pakenham",
    NameAndAddress:
      "Ronview Nominees P/L & Ronview P/L\r\nPO Box 138\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 17,
    Name: "Azemi, N N & Y",
    State: "Vic",
    Address: "16 Francis St",
    Locality: "Dandenong",
    NameAndAddress: "Azemi, N N & Y\r\n16 Francis St\r\nDANDENONG, VIC   3175",
    Postcode: "3175",
  },
  {
    ID: 19,
    Name: "Johnson, S V & G R & Estate of George Edward Johnson",
    State: "Vic",
    Address: "123a Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress:
      "Johnson, S V & G R \r\n& Estate of George Edward Johnson\r\n123a Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 20,
    Name: "5k Developments P/L",
    State: "Vic",
    Address: "PO Box 282",
    Locality: "Kangaroo Ground",
    NameAndAddress:
      "5k Developments P/L\r\nPO Box 282\r\nKANGAROO GROUND, VIC   3097",
    Postcode: "3097",
  },
  {
    ID: 21,
    Name: "Pater, D W & S P",
    State: "Vic",
    Address: "40 Taylor Rd",
    Locality: "Mount Burnett",
    NameAndAddress:
      "Pater, D W & S P\r\n40 Taylor Rd\r\nMOUNT BURNETT, VIC   3781",
    Postcode: "3781",
  },
  {
    ID: 22,
    Name: "Stirling Trading P/L",
    State: "Vic",
    Address: "1/53 Sinclair Rd",
    Locality: "Dandenong",
    NameAndAddress:
      "Stirling Trading P/L\r\n1/53 Sinclair Rd\r\nDANDENONG, VIC   3175",
    Postcode: "3175",
  },
  {
    ID: 23,
    Name: "Pater, D W & S P",
    State: "Vic",
    Address: "25-31 Victoria Rd",
    Locality: "Narre Warren",
    NameAndAddress:
      "Pater, D W & S P\r\n25-31 Victoria Rd\r\nNARRE WARREN, VIC   3805",
    Postcode: "3805",
  },
  {
    ID: 26,
    Name: "Menzel, J L",
    State: "Vic",
    Address: "151 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Menzel, J L\r\n151 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 27,
    Name: "South East Water",
    State: "Vic",
    Address: "Locked Bag 1",
    Locality: "Heatherton",
    NameAndAddress:
      "South East Water\r\nLocked Bag 1\r\nHEATHERTON, VIC   3202",
    Postcode: "3202",
  },
  {
    ID: 28,
    Name: "Jamnicant P/L ",
    State: "Vic",
    Address: "444-454 Belgrave-Hallam Rd",
    Locality: "Narre Warren North",
    NameAndAddress:
      "Jamnicant P/L\r\n444-454 Belgrave-Hallam Rd\r\nNARRE WARREN NORTH, VIC   3804",
    Postcode: "3804",
  },
  {
    ID: 29,
    Name: "Drazic, I",
    State: "Vic",
    Address: "157 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Drazic, I\r\n157 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 30,
    Name: "Allister, S D & D E",
    State: "Vic",
    Address: "159 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress:
      "Allister, S D & D E\r\n159 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 31,
    Name: "Ploog, L R",
    State: "Vic",
    Address: "161 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Ploog, L R\r\n161 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 32,
    Name: "Leach, G M",
    State: "NT",
    Address: "PO Box 3575",
    Locality: "Palmerston",
    NameAndAddress: "Leach, G M\r\nPO Box 3575\r\nPALMERSTON, NT   0831",
    Postcode: "0831",
  },
  {
    ID: 38,
    Name: "Schroder, L E & M D",
    State: "Vic",
    Address: "165 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress:
      "Schroder, L E & M D\r\n165 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 39,
    Name: "Martin, C M",
    State: "Vic",
    Address: "167 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Martin, C M\r\n167 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 40,
    Name: "Curtis, C G & May, K E",
    State: "Vic",
    Address: "169 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress:
      "Curtis, C G & May, K E\r\n169 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 41,
    Name: "Walsh, N C & S J",
    State: "Vic",
    Address: "1 Breyleigh Dr",
    Locality: "Pakenham",
    NameAndAddress:
      "Walsh, N C & S J\r\n1 Breyleigh Dr\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 42,
    Name: "Chalmers, T K E & B I",
    State: "Vic",
    Address: "173 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress:
      "Chalmers, T K E & B I\r\n173 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 43,
    Name: "Deeley, A & H",
    State: "Vic",
    Address: "218 McNabb St",
    Locality: "Berwick",
    NameAndAddress: "Deeley, A & H\r\n218 McNabb St\r\nBERWICK, VIC   3806",
    Postcode: "3806",
  },
  {
    ID: 44,
    Name: "Brucam P/L",
    State: "Vic",
    Address: "701/89 High St",
    Locality: "Kew",
    NameAndAddress: "Brucam P/L\r\n701/89 High St\r\nKEW, VIC   3101",
    Postcode: "3101",
  },
  {
    ID: 45,
    Name: "Mepstead, B M",
    State: "Vic",
    Address: "Tibooburra\r\n235 Leppitt Rd",
    Locality: "Beaconsfield Upper",
    NameAndAddress:
      "Mepstead, B M\r\nTibooburra\r\n235 Leppitt Rd\r\nBEACONSFIELD UPPER, VIC   3808",
    Postcode: "3808",
  },
  {
    ID: 46,
    Name: "Milner, W G & G K",
    State: "Vic",
    Address: "189 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Milner, W G & G K\r\n189 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 47,
    Name: "Zinner, M A & J M",
    State: "Vic",
    Address: "191-197 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress:
      "Zinner, M A & J M\r\n191-197 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 48,
    Name: "Reeves, D R & S C",
    State: "Vic",
    Address: "199 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Reeves, D R & S C\r\n199 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 49,
    Name: "Daye, J L",
    State: "Vic",
    Address: "30 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Daye, J L\r\n30 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 50,
    Name: "Barnes, J M",
    State: "Vic",
    Address: "32 Ahern Rd",
    Locality: "Pakenham",
    NameAndAddress: "Barnes, J M\r\n32 Ahern Rd\r\nPAKENHAM, VIC   3810",
    Postcode: "3810",
  },
  {
    ID: 51,
    Name: "Kannan, K & K",
    State: "Vic",
    Address: "3 Gillian Rd",
    Locality: "Mount Waverley",
    NameAndAddress:
      "Kannan, K & K\r\n3 Gillian Rd\r\nMOUNT WAVERLEY, VIC   3149",
    Postcode: "3149",
  },
  {
    ID: 52,
    Name: "Craig McLaughlin",
    State: "Vic",
    Address: "Mockup Address",
    Locality: "Mockup",
    NameAndAddress: "Craig McLaughlin\r\nMockup Address",
    Postcode: "3806",
  },
  {
    ID: 53,
    Name: "Derin Davis",
    State: "Vic",
    Address: "Mockup Address",
    Locality: "Mockup",
    NameAndAddress: "Derin Davis\r\nMockup Address",
    Postcode: "3806",
  },
  {
    ID: 54,
    Name: "Randall Hosking",
    State: "Vic",
    Address: "Mockup Address",
    Locality: "Mockup",
    NameAndAddress: "Randall Hosking\r\nMockup Address",
    Postcode: "3806",
  },
  {
    ID: 55,
    Name: "Daniel Ocean",
    State: "Vic",
    Address: "Mockup Address",
    Locality: "Mockup",
    NameAndAddress: "Daniel Ocean\r\nMockup Address",
    Postcode: "3806",
  },
  {
    ID: 56,
    Name: "Vijay Ranjit",
    State: "Vic",
    Address: "Mockup Address",
    Locality: "Mockup",
    NameAndAddress: "Vijay Ranjit\r\nMockup Address",
    Postcode: "3806",
  },
  {
    ID: 57,
    Name: "Chris Henry",
    State: "Vic",
    Address: "Mockup Address",
    Locality: "Mockup",
    NameAndAddress: "Chris Henry\r\nMockup Address",
    Postcode: "3806",
  },
];
