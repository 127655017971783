export const mockInspections = [
  {
    InspectionDate: "7-Jan-2022 17:04",
    Clearance: "10m File Break",
    Outcome: "Owners to clear property",
    ClearBy: "16-Jan-2022",
    SpecificInstructions: null,
    Inspector: "rhosking",
    DocsExist: null,
    InspectionId: 210004,
    AdditionalNotes: null,
    Attachment: [],
  },
  {
    InspectionDate: "14-Aug-2018 14:09",
    Clearance: "5m File Break",
    Outcome: "Owners to clear property",
    ClearBy: "4-Aug-2018",
    SpecificInstructions: null,
    Inspector: "rhosking",
    DocsExist: null,
    InspectionId: 5,
    AdditionalNotes: null,
    Attachment: [],
  },
];
