import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AppealHearingDetailTab } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/reference-sidebar/detail/_index";
import { AppealHearingForm } from "@common/pages/appeal/_id/appeal-hearing/_id/components/child-screens/general/_index";
import { AppealHearingSubmitActions } from "@common/pages/appeal/_id/appeal-hearing/_id/model";
import { useAppealHearingStore } from "@common/pages/appeal/_id/appeal-hearing/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedAppealHearing = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    onSubmit,
    responseLoadError,
    loadAppealHearing,
    appealHearingId,
    appealHearing,
  } = useAppealHearingStore();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        appealHearing?.AppealHearing_ID,
        appealHearing?.HearingRepresentationOfficer?.DisplayName,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Appeal - Hearing - ${appealHearingId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: +id ?? 0,
    },
  ];
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Appeal Hearing"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadAppealHearing(+id);
          }}
        />
      ) : (
        <>
          <FormTitle title="Appeal Hearing" />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={AppealHearingSubmitActions.Save}
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <AppealHearingForm />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <AppealHearingDetailTab /> },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
