import { mockMarkAsComplete } from "@app/products/property/fire-prevention/current/record-work-complete/components/dialogs/mark-as-complete/mock";
import { IMarkAsComplete } from "@app/products/property/fire-prevention/current/record-work-complete/components/dialogs/mark-as-complete/model";
import { validatorDayField } from "@app/products/property/fire-prevention/current/record-work-complete/components/dialogs/mark-as-complete/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { addDays } from "date-fns";
import React from "react";

const nameOf = nameOfFactory<IMarkAsComplete>();

interface IMarkAsCompleteDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
}
export const MarkAsCompleteDialog = ({
  onClose,
  onSubmit,
}: IMarkAsCompleteDialogProps) => {
  return (
    <Form
      onSubmitClick={(event: FormSubmitClickEvent) => {
        onSubmit(event.values);
      }}
      initialValues={mockMarkAsComplete}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onChange, onSubmit, valid } = formRenderProps;
        let actualComment = valueGetter(nameOf("ActualComment"));
        let setActualComment = (actualCommentValue: string) =>
          onChange(nameOf("ActualComment"), {
            value: actualCommentValue,
          });
        const handleSendDataToActualComment = (
          defaultCommentDataOld: string[],
          defaultCommentDataNew: string[]
        ) => {
          if (
            defaultCommentDataOld &&
            defaultCommentDataNew &&
            defaultCommentDataOld.length < defaultCommentDataNew.length
          ) {
            if (actualComment) {
              const actualCommentData = actualComment.split("\n");
              const defaultCommentDataAdd = defaultCommentDataNew.filter(
                (item) => !actualCommentData.includes(item)
              );
              setActualComment(
                [...actualCommentData, ...defaultCommentDataAdd].join("\n")
              );
            } else {
              setActualComment(defaultCommentDataNew.join("\n"));
            }
          } else {
            if (defaultCommentDataOld === null) {
              setActualComment(defaultCommentDataNew.join("\n"));
            }
          }
        };
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="30%"
              titleHeader="Contract Completion Date"
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <label className="cc-label">
                          Notification date <TooltipValidator />
                        </label>
                        <Field
                          name={nameOf("NotificationDate")}
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                          validator={requiredValidator}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Date cleared <TooltipValidator />
                        </label>
                        <Field
                          name={nameOf("DateCleared")}
                          format={DATE_FORMAT.DATE_CONTROL}
                          component={CCDatePicker}
                          max={addDays(
                            valueGetter(nameOf("NotificationDate")),
                            1
                          )}
                          onChange={(event: DatePickerChangeEvent) => {
                            onChange(nameOf("DateCleared"), {
                              value: event.target.value,
                            });
                          }}
                          validator={validatorDayField}
                        />
                      </div>
                      <div className="cc-field cc-col-span-3">
                        <label className="cc-label">Default comment</label>
                        <Field
                          name={nameOf("DefaultComment")}
                          component={CCMultiSelectDropdown}
                          defaultValue={valueGetter(nameOf("DefaultComment"))}
                          data={valueGetter(nameOf("DefaultCommentData"))}
                          placeholder="Default comment"
                          onChange={(e: MultiSelectChangeEvent) => {
                            handleSendDataToActualComment(
                              valueGetter(nameOf("DefaultComment")),
                              e.value
                            );
                            onChange("DefaultComment", {
                              value: e.value,
                            });
                          }}
                        />
                      </div>
                      <div className="cc-field cc-col-span-3">
                        <label className="cc-label">Actual comment</label>
                        <Field
                          name={nameOf("ActualComment")}
                          component={CCTextArea}
                          rows={3}
                          value={actualComment}
                          placeholder="Actual comment"
                          onChange={(e: TextAreaChangeEvent) => {
                            setActualComment(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!valid}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
