import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { colRebateSummaryDetail } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/rebate-summary-detail/config";
import { VO_Charge_Run_Rebate_LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/components/detail/components/rebate-summary-detail/model";

const nameOf = nameOfFactory<VO_Charge_Run_Rebate_LevySummary>();
export const RebateSummaryDetail = ({ dataItem }: GridDetailRowProps) => {
  return (
    <CCGrid
      dataUrl={`/odata/property/internal/chargerunrebatelevyregister/GetRebateLevySummary(key=${dataItem?.Charge_Run_Id},rebateType=${dataItem?.Rebate_Type_Id})?$count=true&`}
      columnFields={colRebateSummaryDetail}
      primaryField={nameOf("Levy_Id")}
    />
  );
};
