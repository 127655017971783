import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const CCDropDownCell = (props: GridCellProps) => {
  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const fieldList = "List" + field;
  const data = dataItem[fieldList];
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  let defaultRendering = dataValue;
  if (!dataItem.inEdit || dataItem.inEdit !== field) {
    defaultRendering = (
      <td>{!dataValue || dataValue === null ? "" : dataValue.toString()}</td>
    );
  } else {
    defaultRendering = (
      <td>
        <DropDownList
          onChange={handleChange}
          value={data.find((c: any) => c.value === dataValue)}
          data={data}
          textField="value"
        />
      </td>
    );
  }
  return props.render
    ? props.render.call(undefined, defaultRendering, props)
    : defaultRendering;
};
