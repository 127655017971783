export enum ComplianceType {
  Address_Mismatch = 0,
  Review_Land_Tax_Exemption = 1,
  Land_Rent_Address_Mismatch = 2,
  Land_Rent_Annual_Review_Submission_Form_Missing = 3,
  Review_Land_Holder_Duty_Liability = 100,
}

export enum ComplianceStatus {
  Under_Review = 0,
  Completed_Is_In_Compliance = 10,
  Completed_Not_In_Compliance = 11,
  No_Review_Required = 90,
  Cancelled = 99,
  Allocated = 100,
  Unallocated = 101,
}
export interface CreateComplianceRequest {
  AssessmentIds: number[];
  ComplianceType: number;
  ComplianceStatus: number;
  ResponsibleOfficerUserId?: string;
}

export const dataComplianceType = [
  { Name: "Address Mismatch", Code: ComplianceType.Address_Mismatch },
  {
    Name: "Review Land Tax Exemption",
    Code: ComplianceType.Review_Land_Tax_Exemption,
  },
  {
    Name: "Land Rent Address Mismatch",
    Code: ComplianceType.Land_Rent_Address_Mismatch,
  },
  {
    Name: "Land Rent Annual Review Submission Form Missing",
    Code: ComplianceType.Land_Rent_Annual_Review_Submission_Form_Missing,
  },
  {
    Name: "Review Land Holder Duty Liability",
    Code: ComplianceType.Review_Land_Holder_Duty_Liability,
  },
];
export const dataComplianceStatus = [
  { Name: "Under Review", Code: ComplianceStatus.Under_Review },
  {
    Name: "Completed Is In Compliance",
    Code: ComplianceStatus.Completed_Is_In_Compliance,
  },
  {
    Name: "Completed Not In Compliance",
    Code: ComplianceStatus.Completed_Not_In_Compliance,
  },
  { Name: "No Review Required", Code: ComplianceStatus.No_Review_Required },
  { Name: "Cancelled", Code: ComplianceStatus.Cancelled },
  { Name: "Allocated", Code: ComplianceStatus.Allocated },
  { Name: "Unallocated", Code: ComplianceStatus.Unallocated },
];
