import { postPremisesBatchUpdate } from "@app/products/hm/components/batch-update/api";
import { IPremisesBatchUpdate } from "@app/products/hm/components/batch-update/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

interface ISaveConditionButtonProps {
  isDisabled?: boolean;
}
export const SavePremisesBatchUpdate = observer(
  ({ isDisabled = false }: ISaveConditionButtonProps) => {
    //#region STORE ========/
    const { submitFormGetData, isLoadingForm, setIsLoadingForm } =
      useFlexibleFormStore();
    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { cancelToken } = useCancelRequest();
    const history = useHistory();
    //#endregion HOOK =====/

    const saveConditionSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const premisesBatchUpdate: IPremisesBatchUpdate = initialData;
            return postPremisesBatchUpdate(premisesBatchUpdate, cancelToken());
          },
          handleSuccess: () => {
            history.goBack();
          },
          handleError: ({ errorFromApi }) => {
            pushNotificationPortal({
              title: "Save premises batch update failed.",
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleClickButton = async () => {
      const formPremisesBatchUpdate: IPremisesBatchUpdate =
        await submitFormGetData("GeneralForm");

      if (formPremisesBatchUpdate) {
        setIsLoadingForm(true);
        await saveConditionSlider.fetchApi({
          initialData: formPremisesBatchUpdate,
        });
        setIsLoadingForm(false);
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={isLoadingForm || isDisabled}
      />
    );
  }
);
