import { RateNoticeAmountsOwing } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/_index";
import { useAssessmentAmountOwingStore } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { CommunityProperty } from "@common/stores/products/config";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { AssessmentAmountsOwing } from "./components/panels/assessment/_index";
import { DetailsAmountsOwing } from "./components/panels/details/_index";
import { AmountsOwingTotal } from "./components/total/_index";

export const AmountOwing = observer(() => {
  const { isLoading, amountOwing, loadAmountOwing, resetStore } =
    useAssessmentAmountOwingStore();
  const { assessmentId } = useAssessmentStore();
  const { isGeneral } = CommunityProperty.getFlagOfStates();
  useEffectOnce(() => {
    if (!assessmentId) return;
    loadAmountOwing(assessmentId);
    return () => resetStore();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!amountOwing) return <NoData vertical />;

  const theFirstListPanelBar: Array<IListPanelBar> = [
    {
      title: "Total",
      expanded: true,
      component: <AmountsOwingTotal />,
    },
  ];
  const theSecondListPanelBar: Array<IListPanelBar> = [
    {
      title: "Assessment Amounts Owing and Current Period Rates",
      component: <AssessmentAmountsOwing />,
    },
    {
      title: "Details",
      component: <DetailsAmountsOwing />,
    },
    {
      title: "Rate Notice",
      component: <RateNoticeAmountsOwing />,
      isVisible: !isGeneral,
    },
  ];

  return (
    <div className="cc-property-assessment-amount-owing">
      <div className="cc-custom-sub-panel-bar">
        <CustomPanelBar listPanelBar={theFirstListPanelBar} sort={false} />
        <CustomPanelBar listPanelBar={theSecondListPanelBar} sort={false} />
      </div>
    </div>
  );
});
