import { useApproveResponseButtonStore } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/approve-response/store";
import {
  PPREnquirySubmitActions,
  RegisterStatus,
} from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const EnquiriesApproveResponseButton = observer(() => {
  const { onSubmit, pprEnquiry } = usePPREnquiryStore();
  const { isLoading } = useApproveResponseButtonStore();
  const isVisible = useMemo(() => {
    return RegisterStatus.AwaitingApproval === pprEnquiry?.Status_ENUM;
  }, [pprEnquiry]);

  return isVisible ? (
    <>
      <CCNavButton
        title={"Approve response"}
        onClick={onSubmit}
        isLoading={isLoading}
        name={PPREnquirySubmitActions.ApproveResponse}
      />
    </>
  ) : null;
});
