import { AccordionRelated } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/accordion-related/_index";
import { ContactTitle } from "@app/core/contacts/merge-contact/merge-contact-dialog/components/contact-title/_index";
import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { CCPanel } from "@components/cc-panel/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { MasterContactForm } from "./components/form/_index";
import "./_index.scss";

export const MasterContact = observer(() => {
  const { masterContact } = useMergeContactStore();
  const [isExpanded, setIsExpanded] = useState(true);
  const title = useMemo(() => {
    return (
      <ContactTitle
        title={`${masterContact?.ContactClassification_Name ?? ""} - ${
          masterContact?.DisplayName ? masterContact?.DisplayName + " -" : ""
        } Used ${
          masterContact?.ContactRoles_Count ?? 0
        } times (Master Contact)`}
        isGolden={masterContact?.GoldenRecord}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterContact]);

  return (
    <div className="cc-contact-panel">
      <CCPanel
        key={masterContact?.Contact_ID}
        component={<MasterContactForm />}
        onToggle={() => {
          setIsExpanded(!isExpanded);
        }}
        title={title}
        isExpanded={isExpanded}
        isKeepMounted
      />
      <AccordionRelated idContact={masterContact?.Contact_ID} />
    </div>
  );
});
