import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ThemeType } from "@styles/constant";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";

export const PPRVCATTemplatesButton = observer(() => {
  const { settings } = useCommonProductStore();
  const vcatTemplateURL: string =
    settings[ECorporateSettingsField.TP_PPRVCATTemplateURL]?.Value ?? "";
  const currentTheme = localStorage.getItem("OOTheme");

  return (
    <CCNavButton
      className={currentTheme === ThemeType.Default ? "cc-vcat-templates" : ""}
      title="VCAT Templates"
      externalLinkTo={`${vcatTemplateURL}`}
    />
  );
});
