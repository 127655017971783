import { DTO_Assessment } from "@app/products/property/assessments/[id]/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum EKeysOfStepsModifyDebtRecovery {
  DebtRecovery = "DebtRecovery",
  Details = "Details",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendSteps = [
  EKeysOfStepsModifyDebtRecovery.DebtRecovery,
  EKeysOfStepsModifyDebtRecovery.Details,
  EKeysOfStepsModifyDebtRecovery.Comments,
  EKeysOfStepsModifyDebtRecovery.Documents,
];

export interface DTO_Workflow_ModifyDebtRecovery {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_Modify_Debt_Recovery;
}

export interface DTO_Modify_Debt_Recovery {
  DebtRecovery: DTO_Modify_Debt_Recovery_Step;
  Details: DTO_Modify_Details_Step;
}

export interface DTO_Modify_Debt_Recovery_Step {
  AppliacationName: string;
  ReasonForChange: number | null;
  AssessmentList: DTO_Assessment[];
}

export interface DTO_Modify_Details_Step {
  DebtRecoveryID: number;
  Status: string;
  ActiveFrom: string | null;
  ActiveTo: string | null;
  DebtRecoveryTypeId: number;
  ResponsibleOfficer: string;
  SolicitorReference: string;
}

export interface DTO_Assessments_DebtRecovery {
  AssessmentNumber: number;
  PropertyAddress: string | null;
  Ratepayer: string | null;
  UnitType: string | null;
  Assessment_ID: number;
}
