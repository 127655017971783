import { CRMSFieldMapping } from "@app/products/crms/model";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCollectionLookup: IColumnFields[] = [
  {
    field: CRMSFieldMapping.NumCollections,
    title: "Num Collections",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${CRMS_ROUTE}/${dataItem.ID}`,
  },
  {
    field: CRMSFieldMapping.CollectionMonthName,
    title: "Month",
    width: 150,
  },
  {
    field: CRMSFieldMapping.CollectionDay,
    title: "Collection Day",
    width: 150,
  },
  {
    field: CRMSFieldMapping.MaxCollections,
    title: "Max Collections",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRMSFieldMapping.Description,
    title: "Description",
    width: 150,
  },
  {
    field: CRMSFieldMapping.AreaCode,
    title: "Area Code",
    width: 150,
  },
  {
    field: CRMSFieldMapping.CollectionDate,
    title: "Next Collection Date",
    width: 150,
    dataType: "date",
    format: DATE_FORMAT.DATE,
  },
];
