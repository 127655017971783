import { history } from "@/AppRoutes";
import {
  getHMPremiseInfo,
  getPremise,
  getPremiseLOVs,
  postPremise,
  putPremise,
} from "@app/products/hm/api";
import { PremiseLOVs, PremiseRegisterForm } from "@app/products/hm/model";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { convertPremiseResponseToForm } from "@app/products/hm/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class HMPremisesStore {
  private _premises?: PremiseRegisterForm = undefined;
  private _premisesLOVs?: PremiseLOVs = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get premises() {
    return toJS(this._premises);
  }
  setPremises = (premises?: PremiseRegisterForm) => {
    runInAction(() => {
      this._premises = premises;
    });
  };

  get premisesLOVs() {
    return toJS(this._premisesLOVs);
  }
  setPremisesLOVs = (premiseLOVs?: PremiseLOVs) => {
    runInAction(() => {
      this._premisesLOVs = premiseLOVs;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get premisesId() {
    return toJS(this._premises?.Premises_ID)?.toString();
  }

  //Action

  resetStore = () => {
    runInAction(() => {
      this._premises = undefined;
      this._isLoading = false;
      this._premisesLOVs = undefined;
      this._responseLoadError = undefined;
    });
  };

  loadPremisesInfo = async (Id: string, isNew: boolean) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    if (isNew) {
      const response = await getPremiseLOVs();
      if (isSuccessResponse(response)) {
        this.setPremisesLOVs(response.data);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
    } else {
      const response = await getHMPremiseInfo(Id);
      if (Array.isArray(response)) {
        const [PremisesInfo, PremisesLOVs] = response;

        if (PremisesInfo.data) {
          const newPremise: PremiseRegisterForm = convertPremiseResponseToForm(
            PremisesInfo.data
          );
          this.setPremises(newPremise);
        }

        this.setPremisesLOVs(PremisesLOVs.data);
      } else {
        const responseError = response as APIResponse;
        errorResponse = {
          status: responseError.status,
          error: responseError.error,
        };
      }
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  loadPremises = async (premiseId: string) => {
    this.setIsLoading(true);
    const responseSummary = await getPremise(premiseId);
    let newPremise = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(responseSummary)) {
      newPremise = convertPremiseResponseToForm(responseSummary.data);
    } else {
      errorResponse = {
        status: responseSummary.status,
        error: responseSummary.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setPremises(newPremise);
    this.setIsLoading(false);
  };

  loadPremisesLOVs = async () => {
    this.setIsLoading(true);
    const responseLOVs = await getPremiseLOVs();
    let newPremiseLOVs = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(responseLOVs)) {
      newPremiseLOVs = responseLOVs.data;
    } else {
      errorResponse = {
        status: responseLOVs.status,
        error: responseLOVs.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setPremisesLOVs(newPremiseLOVs);
    this.setIsLoading(false);
  };

  createPremises = async (data: PremiseRegisterForm) => {
    this.setIsLoading(true);
    const response = await postPremise(data);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Premises created successfully",
        type: "success",
        onClose: () => {
          history.push(`${PREMISES_MANAGE_ROUTE}/${response?.data.ID ?? 1}`);
        },
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Premises create failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };

  updatePremises = async (data: PremiseRegisterForm) => {
    this.setIsLoading(true);
    const response = await putPremise(data);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Premises updated successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Premises update failed",
        type: "error",
      });
    }
    this.setIsLoading(false);
  };
}

const premisesStoreContext = createContext(new HMPremisesStore());
export const usePremisesStore = () => {
  return useContext(premisesStoreContext);
};
