import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LookupKeywordSubmitActions } from "@common/pages/settings/lookups/keywords/_id/model";
import { LookupNoticeTypeForm } from "@common/pages/settings/lookups/notice-types/_id/component/child-screens/general/components/_index";
import { LookupNoticeTypesTabDetails } from "@common/pages/settings/lookups/notice-types/_id/component/reference-sidebar/detail/_index";
import { LookupNoticeTypesHistoryTab } from "@common/pages/settings/lookups/notice-types/_id/component/reference-sidebar/history/_index";
import { useNoticeTypesStore } from "@common/pages/settings/lookups/notice-types/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedLookupNoticeType = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    noticeType,
    isLoading,
    responseLoadError,
    loadNoticeType,
    noticeTypeId,
    isInactive,
    onSubmit,
  } = useNoticeTypesStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([noticeType?.NoticeType_ID, noticeType?.Name])}`,
      LinkUrl: managePageUrl,
      LinkText: `Lookups - Keywords - ${noticeType?.NoticeType_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Keyword,
      Record_ID: noticeTypeId ?? 0,
    },
  ];

  const NoticeTypeNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Notice type"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadNoticeType(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={noticeType?.Name || ""} />
          <CCManagePageActionBar
            leftActions={[
              <NoticeTypeNavButton
                title={"Save"}
                name={LookupKeywordSubmitActions.Save}
                onClick={onSubmit}
              />,
            ]}
            rightActions={[
              <NoticeTypeNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {noticeType && <LookupNoticeTypeForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <LookupNoticeTypesTabDetails />,
                    },
                    {
                      title: "History",
                      component: <LookupNoticeTypesHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
