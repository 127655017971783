import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import {
  colAgentSettings,
  defaultStyleGrid,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/agents/forms/agent-settings/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { NoData } from "@components/no-data/NoData";
import { observer } from "mobx-react-lite";
import React from "react";
const nameOf = nameOfFactory<IKeyValuePacket>();
export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter, onChange } = formRenderProps;
  const fieldAgentsSettings = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_AgentsUsed
  );
  const agentSettingsValueDisplayDetails =
    valueGetter(
      `option.ValueDisplayDetails_${fieldAgentsSettings?.FieldName}`
    ) ?? [];
  if (!fieldAgentsSettings) return <NoData />;
  return (
    <div className="cc-agent-settings-form">
      {isEditing ? (
        <CCGrid
          columnFields={colAgentSettings}
          primaryField={nameOf("Key")}
          data={fieldAgentsSettings?.LookupItems ?? []}
          selectableMode="multiple"
          selectedRows={agentSettingsValueDisplayDetails}
          onSelectionChange={(dataItem: IKeyValuePacket[]) => {
            if (isEditing) {
              let listSelected: IKeyValuePacket[] = [];
              if (dataItem) listSelected = dataItem;
              onChange(
                `option.ValueDisplayDetails_${fieldAgentsSettings?.FieldName}`,
                {
                  value: listSelected,
                }
              );
              const newSelected = [...listSelected];
              onChange(`${fieldAgentsSettings?.FieldName}`, {
                value: newSelected?.map((item) => item.Key).join("|"),
              });
            }
          }}
          style={defaultStyleGrid}
          itemPerPage={50}
          isAutoHiddenPager={false}
        />
      ) : (
        <CCGrid
          columnFields={colAgentSettings}
          primaryField={nameOf("Key")}
          itemPerPage={50}
          data={agentSettingsValueDisplayDetails}
          isAutoHiddenPager={false}
          style={defaultStyleGrid}
        />
      )}
    </div>
  );
});
