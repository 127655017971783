import { ResponseMessage } from "@app/products/property/model";
import {
  DocumentTemplate,
  DocumentTemplateLOVS,
} from "@app/products/property/system-admin/document-template/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getDocumentTemplate = async (
  id: number
): Promise<APIResponse<DocumentTemplate | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DocumentTemplate>(
      `/api/property/internal/documentTemplate/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const postDocumentTemplate = async (
  documentTemplate: DocumentTemplate
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/documentTemplate`,
      documentTemplate
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const putDocumentTemplate = async (
  documentTemplate: DocumentTemplate
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().put(
      `/api/property/internal/documentTemplate`,
      documentTemplate
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getDownloadDocument = async (
  id: number
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/documenttemplate/download/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getDocumentTemplateLOVS = async (): Promise<
  APIResponse<DocumentTemplateLOVS | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<DocumentTemplateLOVS>(
      `/api/property/internal/documenttemplate/documentTemplatelov`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
