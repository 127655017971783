import { ResponsibleOfficerSearch } from "@app/products/property/assessments/compliance/component/dialog/create-request-status/components/responsible-officer/_index";
import { useModifyDebtRecoveryDialogStore } from "@app/products/property/components/action-bar/dialog/modify-debt-recovery/store";
import { mockFinancialGroup } from "@app/products/property/components/action-bar/dialog/new-debt-recovery/components/form-elements/detail/mock";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import {
  ComboBoxChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    nameOf,
    formRenderProps,
    options = { isReadOnly: false, workflowDraftId: 0 },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { debtRecoveryLOVs } = useModifyDebtRecoveryDialogStore();

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Debt recovery ID</label>
            <Field
              name={nameOf("DebtRecoveryID")}
              component={CCInput}
              placeholder={"Debt recovery ID"}
              readOnly
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Status</label>
            <Field
              name={nameOf("Status")}
              component={CCSearchComboBox}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              disabled={options?.isReadOnly}
              data={
                debtRecoveryLOVs?.dtoDebtRecovery_LOVs?.DebtRecoveryStatus ?? []
              }
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Active from</label>
            <Field
              name={nameOf("ActiveFrom")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Active to</label>
            <Field
              name={nameOf("ActiveTo")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Type</label>
            <Field
              name={nameOf("DebtRecoveryTypeId")}
              component={CCSearchComboBox}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              disabled={options?.isReadOnly}
              data={
                debtRecoveryLOVs?.dtoDebtRecovery_LOVs?.DebtRecoveryType ?? []
              }
              isUseDefaultOnchange
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Responsible</label>
            <Field
              name={nameOf("ResponsibleOfficer")}
              component={ResponsibleOfficerSearch}
              textField={"FullName"}
              value={getFieldValue("CurrentResponsibleOfficerUser")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("CurrentResponsibleOfficerUser"), {
                  value: event.value,
                });
                onChange(nameOf("ResponsibleOfficer"), {
                  value: event.value?.FullName,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Solicitor reference</label>
            <Field
              name={nameOf("SolicitorReference")}
              component={CCInput}
              placeholder={"Solicitor reference"}
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Financial group</label>
            <Field
              name={nameOf("FinancialGroup")}
              component={CCMultiSelectDropdown}
              //TODO: Add data from api
              data={mockFinancialGroup}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              placeholder="Financial group"
              onChange={(event: MultiSelectChangeEvent) => {
                onChange(nameOf("FinancialGroup"), {
                  value: event.value,
                });
              }}
              disabled={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
