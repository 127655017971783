import {
  existingInstalmentPlan,
  newInstalmentPlan,
} from "@app/products/property/assessments/components/dialogs/change-instalments/config";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface IChangeInstalmentDialogProps {
  onClose: () => void;
}
const ChangeInstalmentDialog = (props: IChangeInstalmentDialogProps) => {
  return (
    <CCDialog
      maxWidth="35%"
      height={"auto"}
      titleHeader={"Change Instalment Plan to Full Amount"}
      onClose={props.onClose}
      bodyElement={
        <div className="cc-form">
          <div className="cc-field-group">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Existing instalment plan</label>
                <CCSearchComboBox
                  className="cc-form-control"
                  data={existingInstalmentPlan}
                  defaultValue={existingInstalmentPlan[0]}
                  textField="Value"
                  dataItemKey="Key"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">New instalment plan</label>
                <CCSearchComboBox
                  className="cc-form-control"
                  data={newInstalmentPlan}
                  textField="Value"
                  dataItemKey="Key"
                />
              </div>
            </div>
          </div>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            onClick={props.onClose}
          >
            OK
          </Button>
        </div>
      }
    />
  );
};
export default ChangeInstalmentDialog;
