import { FurtherInfoForm } from "@app/core/further-info/[id]/components/child-screens/general/_index";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions,
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { getFurtherStatusFriendlyName } from "@app/core/further-info/[id]/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewFurtherInfo = observer(() => {
  const {
    isLoading,
    responseLoadError,
    onSubmit,
    loadFurtherInfo,
    furtherInfoId,
    furtherInfo,
  } = useFurtherInfoStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title={`Further Information Request ${
          isNumber(furtherInfo?.OrignalFI_ID) ? " - Extension" : ""
        }`}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadFurtherInfo(furtherInfoId, true);
          }}
        />
      ) : (
        <>
          <FormTitle
            title={"New"}
            badge={getFurtherStatusFriendlyName(
              FURTHERINFOSTATUS.InfoRequestedDraft
            )}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={FurtherInfoSubmitActions.New}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <FurtherInfoForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
