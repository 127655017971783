import { BuildingParentSection } from "@app/products/building/components/forms/building-parent-section/_index";
import { BuildingFieldMapping } from "@app/products/building/model";
import { officerFields } from "@app/products/building/registers/stormwater/[id]/components/child-screens/general/components/form-element/config";
import { mockStatusDropdownData } from "@app/products/building/registers/stormwater/[id]/mock";
import { BuildingStormwaterFormData } from "@app/products/building/registers/stormwater/[id]/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IBuildingRegisterStormwaterFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegisterStormwaterFormElement = observer(
  ({ formRenderProps }: IBuildingRegisterStormwaterFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();

    // Check apply field CC
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const nameOf = nameOfFactory<BuildingStormwaterFormData>();
    const getValue = formRenderProps.valueGetter;

    return (
      <FormElement>
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <BuildingParentSection formRenderProps={formRenderProps} />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                dataItemKey="Key"
                textField="Value"
                name={nameOf("Status")}
                data={mockStatusDropdownData}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <Field
                component={InputPicker}
                {...officerFields(formRenderProps)}
                name={BuildingFieldMapping.Officer}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reference number</label>
              <Field name={nameOf("RefNumber")} component={CCInput} />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <CCValueField
              name={nameOf("Lodged")}
              label="Lodged"
              value={getValue(nameOf("Lodged"))}
              format={DATE_FORMAT.DATE}
              tooltip={
                <CCTooltip
                  type="validator"
                  position="right"
                  // content={formRenderProps.errors.Contact} // TODO: pass error, ask again the behavior later
                />
              }
            />

            <CCValueField
              name={nameOf("DecisionDate")}
              label="Decision date"
              value={getValue(nameOf("DecisionDate"))}
              format={DATE_FORMAT.DATE}
            />

            <CCValueField
              name={nameOf("FileNumber")}
              label="File number"
              value={getValue(nameOf("FileNumber"))}
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              name={nameOf("OSFees")}
              label="OS fees"
              value={getValue(nameOf("OSFees"))}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatOSFees(getValue(nameOf("OSFees")))}
            />

            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field name={nameOf("DebtorNumber")} component={CCInput} />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
