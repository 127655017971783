import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { options } from "@app/products/crms/service-standards/batch-update/constant";

import { useSvcStandardBatchUpdateStore } from "@app/products/crms/service-standards/batch-update/store";
import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { isFieldVisible } from "@app/products/crms/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export interface ISystemInterfaceProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

// Show it when implementing in the service standard manage page
// const nameOfSvcStandardBatchUpdateMapObj =
//   nameOfFactory<ServiceStandardBatchUpdateMapObj>();

export const SystemInterface = observer(
  ({ formRenderProps, isDisabled = false }: ISystemInterfaceProps) => {
    const { valueGetter } = formRenderProps;

    const { uiControl } = useSvcStandardBatchUpdateStore();
    const { settings } = useCommonCoreStore();

    const isUpdateHasAlerts = valueGetter(
      getNameOfSvcStandardBatchUpdate("Update_HasAlerts")
    );
    const isDivWWMS_CSM = isFieldVisible(uiControl?.DivWWMS_CSM?.DisplayStatus);
    const isDivWasteWater = isFieldVisible(
      uiControl?.DivWasteWater?.DisplayStatus
    );
    const isDivControlledSubstance = isFieldVisible(
      uiControl?.DivControlledSubstance?.DisplayStatus
    );
    const cxLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
    );
    // Show it when implementing in the service standard manage page
    // const SvcStandardBatchUpdateMapObj = valueGetter(
    //   nameOfSvcStandardBatchUpdateMapObj("SvcStandardBatchUpdate")
    // );
    // const isHasAlert = valueGetter(
    //   getNameOfSvcStandardBatchUpdate("HasAlerts")
    // );
    // const isAlertsEnableEscalationAlerts = valueGetter(
    //   getNameOfSvcStandardBatchUpdate("Alerts_EnableEscalationAlerts")
    // );
    // const alertsEscalation1OfficerDisplayName = valueGetter(
    //   `${getNameOfSvcStandardBatchUpdate(
    //     "Alerts_Escalation1_Officer"
    //   )}.DisplayName`
    // );
    // const alertsEscalation2OfficerDisplayName = valueGetter(
    //   `${getNameOfSvcStandardBatchUpdate(
    //     "Alerts_Escalation2_Officer"
    //   )}.DisplayName`
    // );

    // const addSaveTriggers = (trigger: ServiceStandardUpdateTriggers) => {
    //   const saveTriggers =
    //     valueGetter(getNameOfSvcStandardBatchUpdate("SaveTriggers")) ?? [];
    //   if (!saveTriggers.includes(trigger)) {
    //     saveTriggers.push(trigger);
    //     onChange(getNameOfSvcStandardBatchUpdate("SaveTriggers"), {
    //       value: saveTriggers,
    //     });
    //   }
    // };

    // const handleChange1stActionOfficer = (values: any) => {
    //   onChange(
    //     getNameOfSvcStandardBatchUpdate(
    //       "Alerts_Escalation1_Officer_SiteUser_ID"
    //     ),
    //     {
    //       value: values?.Id ?? null,
    //     }
    //   );

    //   if (!values) {
    //     addSaveTriggers(ServiceStandardUpdateTriggers.ClearEscalationOfficer1);
    //     onChange(
    //       getNameOfSvcStandardBatchUpdate("Alerts_Escalation1_Officer"),
    //       { value: null }
    //     );
    //     onChange(getNameOfSvcStandardBatchUpdate("Alerts_Escalation1_Days"), {
    //       value: null,
    //     });
    //   } else {
    //     addSaveTriggers(ServiceStandardUpdateTriggers.UpdateEscalationOfficer1);
    //     const params: ServiceStandardBatchUpdateHandlerRequest = {
    //       ServiceStandardBatchUpdateFormAction:
    //         Svc_FormAction_ServiceStandardBatchUpdate.PickEscalationOfficer1,
    //       ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
    //       EventArgs: {},
    //       IsFirstTimeLoad: false,
    //     };
    //     svcStandardBatchUpdateChangeHandler(
    //       params,
    //       "Change 1st action officer failed."
    //     );
    //   }
    //   setIsExpandedSystemInterface(true);
    // };

    // const handleChange2ndActionOfficer = (values: any) => {
    //   onChange(
    //     getNameOfSvcStandardBatchUpdate(
    //       "Alerts_Escalation2_Officer_SiteUser_ID"
    //     ),
    //     {
    //       value: values?.Id ?? null,
    //     }
    //   );
    //   if (!values) {
    //     addSaveTriggers(ServiceStandardUpdateTriggers.UpdateEscalationOfficer2);
    //     onChange(
    //       getNameOfSvcStandardBatchUpdate("Alerts_Escalation2_Officer"),
    //       { value: null }
    //     );
    //     onChange(getNameOfSvcStandardBatchUpdate("Alerts_Escalation2_Days"), {
    //       value: null,
    //     });
    //   } else {
    //     const params: ServiceStandardBatchUpdateHandlerRequest = {
    //       ServiceStandardBatchUpdateFormAction:
    //         Svc_FormAction_ServiceStandardBatchUpdate.PickEscalationOfficer2,
    //       ServiceStandardBatchUpdate: SvcStandardBatchUpdateMapObj,
    //       EventArgs: {},
    //       IsFirstTimeLoad: false,
    //     };
    //     svcStandardBatchUpdateChangeHandler(
    //       params,
    //       "Change 2nd action officer failed."
    //     );
    //   }
    //   setIsExpandedSystemInterface(true);
    // };

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update has alerts" />
              <Field
                name={getNameOfSvcStandardBatchUpdate("Update_HasAlerts")}
                checked={isUpdateHasAlerts}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>

            {isUpdateHasAlerts ? (
              <div className="cc-field">
                <CCLabel title="Has alerts" isMandatory />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("HasAlerts")}
                  component={RadioGroup}
                  data={options}
                  layout={"horizontal"}
                  validator={requiredValidator}
                  disabled={isDisabled}
                />
              </div>
            ) : null}
          </div>

          {/* Show it when implementing in the service standard manage page */}
          {/* {isUpdateHasAlerts && isHasAlert ? (
            <>
              <div className="cc-custom-sub-panel-bar">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Enable alerts" />
                    <Field
                      name={getNameOfSvcStandardBatchUpdate(
                        "Alerts_EnableEscalationAlerts"
                      )}
                      checked={isAlertsEnableEscalationAlerts ?? false}
                      component={CCSwitch}
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="cc-col-span-2">
                    <CCValueField
                      value={
                        "Alerts selected staff when events for this category become overdue by the defined days"
                      }
                      className={"cc-text-info cc-padding-text-info"}
                    />
                  </div>
                </div>
              </div>
              {isAlertsEnableEscalationAlerts ? (
                <>
                  <div className="cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="1st alert officer" isMandatory />
                        <Field
                          name={getNameOfSvcStandardBatchUpdate(
                            "Alerts_Escalation1_Officer_SiteUser_ID"
                          )}
                          nameDisplay="DisplayName"
                          placeholder="Select 1st alert officer"
                          component={InputPickerSearch}
                          onChange={handleChange1stActionOfficer}
                          options={renderOptionActionOfficer(
                            `${ODATA_GET_CONTACTS}(classificationFilters='2',hideCouncillors=true)`
                          )}
                          value={alertsEscalation1OfficerDisplayName}
                          validator={requiredValidator}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="cc-field">
                        <CCLabel
                          title="1st alert no. of days"
                          isMandatory={!!alertsEscalation1OfficerDisplayName}
                        />
                        <Field
                          name={getNameOfSvcStandardBatchUpdate(
                            "Alerts_Escalation1_Days"
                          )}
                          component={CCNumericTextBox}
                          validator={(value: any) => {
                            return !!alertsEscalation1OfficerDisplayName
                              ? requiredValidator(value)
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cc-custom-sub-panel-bar">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="2nd alert office" />
                        <Field
                          name={getNameOfSvcStandardBatchUpdate(
                            "Alerts_Escalation2_Officer_SiteUser_ID"
                          )}
                          nameDisplay="DisplayName"
                          placeholder="Select 2nd alert officer"
                          component={InputPickerSearch}
                          onChange={handleChange2ndActionOfficer}
                          options={renderOptionActionOfficer(
                            `${ODATA_GET_CONTACTS}(classificationFilters='2',hideCouncillors=true)`
                          )}
                          value={alertsEscalation2OfficerDisplayName}
                          disabled={isDisabled}
                        />
                      </div>

                      <div className="cc-field">
                        <CCLabel
                          title="2nd alert no. of days"
                          isMandatory={!!alertsEscalation2OfficerDisplayName}
                        />
                        <Field
                          name={getNameOfSvcStandardBatchUpdate(
                            "Alerts_Escalation2_Days"
                          )}
                          component={CCNumericTextBox}
                          validator={(value: any) => {
                            return !!alertsEscalation2OfficerDisplayName
                              ? requiredValidator(value)
                              : "";
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null} */}

          {/* <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Update enable event overdue alerts" />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "Update_EventOverdueAlerts"
                )}
                checked={valueGetter(
                  getNameOfSvcStandardBatchUpdate("Update_EventOverdueAlerts")
                )}
                component={CCSwitch}
                disabled={isDisabled}
              />
            </div>
            {valueGetter(
              getNameOfSvcStandardBatchUpdate("Update_EventOverdueAlerts")
            ) ? (
              <div className="cc-field">
                <CCLabel title="Enable event overdue alerts" isMandatory />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("EventOverdueAlerts")}
                  component={RadioGroup}
                  data={options}
                  layout={"horizontal"}
                  disabled={isDisabled}
                  validator={requiredValidator}
                />
              </div>
            ) : null}
          </div> */}
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCLabel title="System interface" />
          </div>

          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title={`Update ${
                    uiControl?.FlDisplayAsCRMSChoice?.Value ??
                    `available to ${CRMS_PRODUCT_NAME}`
                  }`}
                />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_DisplayAsCRMSChoice"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_DisplayAsCRMSChoice"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_DisplayAsCRMSChoice")
              ) ? (
                <div className="cc-field">
                  <CCLabel
                    title={
                      uiControl?.FlDisplayAsCRMSChoice?.Value ??
                      `Available to ${cxLabel ?? CRMS_PRODUCT_NAME}`
                    }
                    isMandatory
                  />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "DisplayAsCRMSChoice"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update knowledge base" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_KnowledgeBase")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_KnowledgeBase")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_KnowledgeBase")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Knowledge base" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("KnowledgeBase")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    validator={requiredValidator}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update internet choice" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_DisplayAsInternetChoice"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_DisplayAsInternetChoice"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_DisplayAsInternetChoice"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Internet choice" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "DisplayAsInternetChoice"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    validator={requiredValidator}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update system" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_System")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_System")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(getNameOfSvcStandardBatchUpdate("Update_System")) ? (
                <div className="cc-field">
                  <CCLabel title="System" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("System")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            {isDivWWMS_CSM && (
              <>
                {isDivWasteWater && (
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update WWMS" />
                      <Field
                        name={getNameOfSvcStandardBatchUpdate(
                          "Update_WasteWater"
                        )}
                        checked={valueGetter(
                          getNameOfSvcStandardBatchUpdate("Update_WasteWater")
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>
                    {valueGetter(
                      getNameOfSvcStandardBatchUpdate("Update_WasteWater")
                    ) ? (
                      <div className="cc-field">
                        <CCLabel title="WWMS" isMandatory />
                        <Field
                          name={getNameOfSvcStandardBatchUpdate("WasteWater")}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          disabled={isDisabled}
                          validator={requiredValidator}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
                {isDivControlledSubstance && (
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Update controlled substance" />
                      <Field
                        name={getNameOfSvcStandardBatchUpdate(
                          "Update_ControlledSubstance"
                        )}
                        checked={valueGetter(
                          getNameOfSvcStandardBatchUpdate(
                            "Update_ControlledSubstance"
                          )
                        )}
                        component={CCSwitch}
                        disabled={isDisabled}
                      />
                    </div>
                    {valueGetter(
                      getNameOfSvcStandardBatchUpdate(
                        "Update_ControlledSubstance"
                      )
                    ) ? (
                      <div className="cc-field">
                        <CCLabel title="Controlled substance" isMandatory />
                        <Field
                          name={getNameOfSvcStandardBatchUpdate(
                            "ControlledSubstance"
                          )}
                          component={RadioGroup}
                          data={options}
                          layout={"horizontal"}
                          disabled={isDisabled}
                          validator={requiredValidator}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update IT support" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_ITSupport")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_ITSupport")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_ITSupport")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="IT support" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("ITSupport")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update animal" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Animal")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Animal")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(getNameOfSvcStandardBatchUpdate("Update_Animal")) ? (
                <div className="cc-field">
                  <CCLabel title="Animal" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Animal")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update health" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Health")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Health")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(getNameOfSvcStandardBatchUpdate("Update_Health")) ? (
                <div className="cc-field">
                  <CCLabel title="Health" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Health")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update local laws" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_LocalLaws")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_LocalLaws")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_LocalLaws")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Local laws" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("LocalLaws")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update town planning" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_TownPlanning")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_TownPlanning")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_TownPlanning")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Town planning" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("TownPlanning")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update building" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Building")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Building")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Building")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Building" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Building")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update kennel" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Kennel")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Kennel")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(getNameOfSvcStandardBatchUpdate("Update_Kennel")) ? (
                <div className="cc-field">
                  <CCLabel title="Kennel" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Kennel")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCLabel title="Additional interface" />
          </div>

          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update simple form" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_SimpleForm")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_SimpleForm")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_SimpleForm")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Simple form" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("SimpleForm")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update inspections" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Inspections")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Inspections")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Inspections")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Inspections" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Inspections")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update auto actions" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_AutoActions")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_AutoActions")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_AutoActions")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Auto actions" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("AutoActions")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update assets" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Asset")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Asset")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(getNameOfSvcStandardBatchUpdate("Update_Asset")) ? (
                <div className="cc-field">
                  <CCLabel title="Assets" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Asset")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update hard rubbish" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_Flag_HardRubbish"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Flag_HardRubbish")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Flag_HardRubbish")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Hard rubbish" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Flag_HardRubbish")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update notices" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Notices")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Notices")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Notices")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Notices" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Notices")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update enable bins lookup" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_EnableBinsLookup"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_EnableBinsLookup")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_EnableBinsLookup")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Enable bins lookup" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("EnableBinsLookup")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update payroll" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate("Update_Payroll")}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_Payroll")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Payroll")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Payroll" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("Payroll")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update collection date" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_CollectionDate"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_CollectionDate")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_CollectionDate")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Collection date" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("CollectionDate")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update enable school patrols" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_EnableSchoolPatrols"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_EnableSchoolPatrols"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_EnableSchoolPatrols")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Enable school patrols" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "EnableSchoolPatrols"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update enable nuisance act" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_EnableNuisanceAct"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate("Update_EnableNuisanceAct")
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_EnableNuisanceAct")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Enable nuisance act" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate("EnableNuisanceAct")}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update enable animals lookup" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_EnableAnimalsLookUp"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_EnableAnimalsLookUp"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_EnableAnimalsLookUp")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Enable animals lookup" isMandatory />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "EnableAnimalsLookUp"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                    validator={requiredValidator}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </section>

        {/* Show it when implementing in the service standard manage page */}
        {/* <hr className="cc-divider" /> */}

        {/* <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCLabel title="Hard rubbish" />
          </div>

          <div className="cc-field-group cc-custom-sub-panel-bar">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update allocation method" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_Flag_HardRubbishAllocationManual"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_Flag_HardRubbishAllocationManual"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_Flag_HardRubbishAllocationManual"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Allocation method" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Flag_HardRubbishAllocationManual"
                    )}
                    component={RadioGroup}
                    data={FlagHardRubbishAllocationManualOptions}
                    layout={"horizontal"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update schedule event when sent" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_Flag_HardRubbishScheduleEventOnSent"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_Flag_HardRubbishScheduleEventOnSent"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_Flag_HardRubbishScheduleEventOnSent"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Schedule event when sent" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Flag_HardRubbishScheduleEventOnSent"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update show in home page picker" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_Flag_HardRubbishPicker"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_Flag_HardRubbishPicker"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_Flag_HardRubbishPicker")
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Show in home page picker" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Flag_HardRubbishPicker"
                    )}
                    component={RadioGroup}
                    data={options}
                    layout={"horizontal"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update collection item" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_Flag_HardRubbishCollectionItemLookup"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_Flag_HardRubbishCollectionItemLookup"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_Flag_HardRubbishCollectionItemLookup"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Collection item" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Flag_HardRubbishCollectionItemLookup"
                    )}
                    component={RadioGroup}
                    data={flagHardRubbishCollectionItemLookupOptions}
                    layout={"horizontal"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update collection item name" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_CollectionItemUserDefinedValue"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_CollectionItemUserDefinedValue"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_HardRubbish_CollectionItemUserDefinedValue"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Collection item name" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "HardRubbish_CollectionItemUserDefinedValue"
                    )}
                    component={CCInput}
                    placeholder={"Collection item name"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update message prompt" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_MessagePrompt"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_MessagePrompt"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_HardRubbish_MessagePrompt"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Message prompt" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "HardRubbish_MessagePrompt"
                    )}
                    component={CCTextArea}
                    rows={3}
                    placeholder={"Message prompt"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Update message items options" />
                <Field
                  name={getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_MessageItemsOptions"
                  )}
                  checked={valueGetter(
                    getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_MessageItemsOptions"
                    )
                  )}
                  component={CCSwitch}
                  disabled={isDisabled}
                />
              </div>
              {valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_HardRubbish_MessageItemsOptions"
                )
              ) ? (
                <div className="cc-field">
                  <CCLabel title="Message items options" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "HardRubbish_MessageItemsOptions"
                    )}
                    component={CCTextArea}
                    rows={3}
                    placeholder={"Message items options"}
                    disabled={isDisabled}
                  />
                </div>
              ) : null}
            </div>

            <div className="cc-form-cols-3">
              <CCLabel title="System allocation settings:" />
            </div>

            <div className="cc-field-group cc-custom-sub-panel-bar">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Update business days" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_AllocationBusinessDays"
                    )}
                    checked={valueGetter(
                      getNameOfSvcStandardBatchUpdate(
                        "Update_HardRubbish_AllocationBusinessDays"
                      )
                    )}
                    component={CCSwitch}
                    disabled={isDisabled}
                  />
                </div>
                {valueGetter(
                  getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_AllocationBusinessDays"
                  )
                ) ? (
                  <div className="cc-field">
                    <CCLabel title="Business days" />
                    <Field
                      name={getNameOfSvcStandardBatchUpdate(
                        "HardRubbish_AllocationBusinessDays"
                      )}
                      component={CCNumericTextBox}
                      min={0}
                      placeholder={"Business days"}
                      disabled={isDisabled}
                    />
                  </div>
                ) : null}
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Update collections per year" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_CollectionsPerYear"
                    )}
                    checked={valueGetter(
                      getNameOfSvcStandardBatchUpdate(
                        "Update_HardRubbish_CollectionsPerYear"
                      )
                    )}
                    component={CCSwitch}
                    disabled={isDisabled}
                  />
                </div>
                {valueGetter(
                  getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_CollectionsPerYear"
                  )
                ) ? (
                  <div className="cc-field">
                    <CCLabel title="Collections per year" />
                    <Field
                      name={getNameOfSvcStandardBatchUpdate(
                        "HardRubbish_CollectionsPerYear"
                      )}
                      component={CCNumericTextBox}
                      min={0}
                      placeholder={"Collections per year"}
                      disabled={isDisabled}
                    />
                  </div>
                ) : null}
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Update restrictions per month" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_RestrictionsPerMonth"
                    )}
                    checked={valueGetter(
                      getNameOfSvcStandardBatchUpdate(
                        "Update_HardRubbish_RestrictionsPerMonth"
                      )
                    )}
                    component={CCSwitch}
                    disabled={isDisabled}
                  />
                </div>
                {valueGetter(
                  getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_RestrictionsPerMonth"
                  )
                ) ? (
                  <div className="cc-field">
                    <CCLabel title="Restrictions per month" />
                    <Field
                      name={getNameOfSvcStandardBatchUpdate(
                        "HardRubbish_RestrictionsPerMonth"
                      )}
                      component={CCNumericTextBox}
                      min={0}
                      placeholder={"Restrictions per month"}
                      disabled={isDisabled}
                    />
                  </div>
                ) : null}
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Update restriction alert" />
                  <Field
                    name={getNameOfSvcStandardBatchUpdate(
                      "Update_HardRubbish_RestrictionsAlert"
                    )}
                    checked={valueGetter(
                      getNameOfSvcStandardBatchUpdate(
                        "Update_HardRubbish_RestrictionsAlert"
                      )
                    )}
                    component={CCSwitch}
                    disabled={isDisabled}
                  />
                </div>
                {valueGetter(
                  getNameOfSvcStandardBatchUpdate(
                    "Update_HardRubbish_RestrictionsAlert"
                  )
                ) ? (
                  <div className="cc-field">
                    <CCLabel title="Restriction alert" />
                    <Field
                      name={getNameOfSvcStandardBatchUpdate(
                        "HardRubbish_RestrictionsAlert"
                      )}
                      component={CCInput}
                      placeholder={"Restriction alert"}
                      disabled={isDisabled}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section> */}
      </>
    );
  }
);
