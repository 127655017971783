import { nameOfFactory } from "@common/utils/common";
import { colPickOrgUnits } from "@components/cc-pick-org-units/config";
import { OrgUnit } from "@components/cc-pick-org-units/model";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface ICCPickOrgUnitsProps {
  onSubmit?: (data: any) => void;
  onClose?: (isShowDialog: boolean) => void;
  setShowDialog?: (isShowDialog: boolean) => void;
  dataUrl: string;
  isMultiple?: boolean;
  selectedRows?: any[];
}
const nameOf = nameOfFactory<OrgUnit>();
export const CCPickOrgUnits = ({
  onSubmit = () => {},
  onClose = () => {},
  setShowDialog = () => {},
  isMultiple = true,
  dataUrl,
  selectedRows,
}: ICCPickOrgUnitsProps) => {
  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <GridSelectionDialog
            maxWidth="55%"
            titleHeader={"Pick Org Unit(s)"}
            selectableMode={isMultiple ? "multiple" : "single"}
            dataUrl={dataUrl}
            columnFields={colPickOrgUnits}
            primaryField={nameOf("ID")}
            {...formRenderProps}
            setShowDialog={setShowDialog}
            onCloseDialog={() => onClose(false)}
            onSubmit={(data: OrgUnit[]) => onSubmit(data)}
            selectedRows={selectedRows}
          />
        );
      }}
    />
  );
};
