import { useComplianceCandidateStore } from "@app/products/property/assessments/compliance/candidates/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const PopulateCandidatesButton = observer(() => {
  const { setIsOpenPopulateDialog } = useComplianceCandidateStore();
  return (
    <>
      <CCNavButton
        title="Populate Candidates"
        onClick={() => {
          setIsOpenPopulateDialog(true);
        }}
      />
    </>
  );
});
