import { DTO_Create_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssociateAssessmentDialogStore {
  private _isLoadingOnDialog: boolean = false;
  private _isLoadingOnDialogCancel: boolean = false;
  private _associateAssessmentLOVs?: DTO_Create_Assessment_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoadingOnDialogCancel(): boolean {
    return this._isLoadingOnDialogCancel;
  }
  setIsLoadingOnDialogCancel = (isLoadingOnDialogCancel: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialogCancel = isLoadingOnDialogCancel;
    });
  };
  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get associateAssessmentLOVs() {
    return this._associateAssessmentLOVs;
  }
  setAssociateAssessmentLOVs = (
    associateAssessmentLOVs?: DTO_Create_Assessment_LOVs
  ) => {
    runInAction(() => {
      this._associateAssessmentLOVs = associateAssessmentLOVs;
    });
  };
}

const associateAssessmentDialogStoreContext = createContext(
  new AssociateAssessmentDialogStore()
);
export const useAssociateAssessmentDialogStore = () => {
  return useContext(associateAssessmentDialogStoreContext);
};
