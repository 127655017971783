import { loadViewConfiguresColumns } from "@app/products/property/api";
import {
  colLRSOwnersGroupAndOwnersNames,
  colLRSPreviousOwners,
  colLRSStreetAddress,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/components/lot-details-children/config";
import {
  VO_NoticeOfSale_Title_OwnerDetail,
  VO_NoticeOfSale_Title_PreviousOwnerDetail,
  VO_NoticeOfSale_Title_Street_Address,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/detail/components/lot-details/components/lot-details-children/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOfLRSStreetAddress =
  nameOfFactory<VO_NoticeOfSale_Title_Street_Address>();
const nameOfLRSOwnersGroupAndOwnersNames =
  nameOfFactory<VO_NoticeOfSale_Title_OwnerDetail>();
const nameOfLRSPreviousOwners =
  nameOfFactory<VO_NoticeOfSale_Title_PreviousOwnerDetail>();
export const LotDetailsChildren = (props: GridDetailRowProps) => {
  const { dataItem } = props;

  //Use state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedColLRSStreetAddress, setProcessedColLRSStreetAddress] =
    useState<IColumnFields[]>([]);
  const [
    processedColLRSOwnersGroupAndOwnersNames,
    setProcessedColLRSOwnersGroupAndOwnersNames,
  ] = useState<IColumnFields[]>([]);
  const [processedColLRSPreviousOwners, setProcessedColLRSPreviousOwners] =
    useState<IColumnFields[]>([]);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await Promise.all([
      loadViewConfiguresColumns(
        ViewConfiguration.OData_NoticeOfSale_Titles_StreetAddress,
        colLRSStreetAddress
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.OData_NoticeOfSale_Titles_OwnerDetails,
        colLRSOwnersGroupAndOwnersNames
      ),
      loadViewConfiguresColumns(
        ViewConfiguration.OData_NoticeOfSale_Titles_PreviousOwnerDetails,
        colLRSPreviousOwners
      ),
    ]);
    setIsLoading(false);
    if (Array.isArray(response)) {
      const [
        responseLRSStreetAddress,
        responseLRSOwnersGroupAndOwnersNames,
        responseLRSPreviousOwners,
      ] = response;
      if (
        Array.isArray(responseLRSStreetAddress) &&
        Array.isArray(responseLRSOwnersGroupAndOwnersNames) &&
        Array.isArray(responseLRSPreviousOwners)
      ) {
        setProcessedColLRSStreetAddress(responseLRSStreetAddress);
        setProcessedColLRSOwnersGroupAndOwnersNames(
          responseLRSOwnersGroupAndOwnersNames
        );
        setProcessedColLRSPreviousOwners(responseLRSPreviousOwners);
      } else {
        let responseError = responseLRSStreetAddress as APIResponseError;
        switch (true) {
          case !Array.isArray(responseLRSOwnersGroupAndOwnersNames):
            responseError =
              responseLRSOwnersGroupAndOwnersNames as APIResponseError;
            break;
          case !Array.isArray(responseLRSPreviousOwners):
            responseError = responseLRSPreviousOwners as APIResponseError;
            break;
        }
        setResponseLoadError({
          status: responseError.status,
          error: responseError.error ?? "Load failed",
        });
      }
    } else {
      const responseError = response as APIResponse;
      setResponseLoadError({
        status: responseError.status,
        error: responseError.error ?? "Load failed",
      });
    }
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-field">
          <CCLabel title="LRS street address" />
          <CCGrid
            className="cc-lot-details-children-grid"
            dataUrl={`odata/property/internal/noticeofsaletitlesstreetaddressregister?lrs_Title_Id=${dataItem.LRS_Title_Id}&$count=true&`}
            columnFields={processedColLRSStreetAddress}
            primaryField={nameOfLRSStreetAddress("LRS_NoS_Street_Address_Id")}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="LRS owners group and LRS owners names" />
          <CCGrid
            className="cc-lot-details-children-grid"
            dataUrl={`odata/property/internal/noticeofsaletitlesownerdetailsregister?lrs_Title_Id=${dataItem.LRS_Title_Id}&$count=true&`}
            columnFields={processedColLRSOwnersGroupAndOwnersNames}
            primaryField={nameOfLRSOwnersGroupAndOwnersNames(
              "LRS_NoS_Owners_Name_Id"
            )}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="LRS previous owners" />
          <CCGrid
            className="cc-lot-details-children-grid"
            dataUrl={`odata/property/internal/noticeofsaletitlespreviousownerdetailsregister?lrs_Title_Id=${dataItem.LRS_Title_Id}&$count=true&`}
            columnFields={processedColLRSPreviousOwners}
            primaryField={nameOfLRSPreviousOwners("LRS_NoS_Previous_Owner_Id")}
          />
        </div>
      </div>
    </div>
  );
};
