import {
  ResponseMessage,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { DTO_Workflow_Supplementary_Disassociate } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/assessment/remove/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const disassociateAssessmentSupplementary = async (
  payload: DTO_Workflow_Supplementary_Disassociate
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `api/property/internal/workflow/supplementary/disassociateassessment/process/${WorkflowProcessMode.Finish}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
