import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { PROPERTY_NOTICE_GROUPS_ROUTE } from "@app/products/property/notice-groups/constant";
import { VO_NoticeGroups_Assessment } from "@app/products/property/notice-groups/model";

const nameOf = nameOfFactory<VO_NoticeGroups_Assessment>();
export const colList: IColumnFields[] = [
  {
    field: nameOf("Assess_Reference"),
    title: "Number",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PROPERTY_NOTICE_GROUPS_ROUTE}/${dataItem.Assessment_Id}`;
    },
  },
  {
    field: nameOf("Assess_Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOf("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Assess_Property_Locality"),
    title: "Property Locality",
  },
  {
    field: nameOf("Assess_Property_Location_Desc"),
    title: "Property Location",
  },
  {
    field: nameOf("Ratepayer_Name"),
    title: "Ratepayer",
  },
  { field: nameOf("Legal_Description"), title: "Legal Description" },
  { field: nameOf("Assess_Improvements"), title: "Assess Improvements" },
  { field: nameOf("Primary_Assess_Land_Use_Name"), title: "Primary Land Use" },
  { field: nameOf("Assessment_Type_Name"), title: "Assessment Type" },
  { field: nameOf("Assessment_Status_Name"), title: "Assessment Status" },
  {
    field: nameOf("Assessment_Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
