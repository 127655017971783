import { ADVANCED_SEARCH_SEARCHES_ROUTE } from "@app/products/property/advanced_search/searches/constant";
import { IResultData } from "@app/products/property/advanced_search/[id]/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IResultData>();
export const searchResultColList: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumber"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${ADVANCED_SEARCH_SEARCHES_ROUTE}/${dataItem.AssessmentNumber}`,
  },
  { field: nameOf("PropertyName"), title: "Property Name" },
  { field: nameOf("PropertyAddress"), title: "Property Address" },
  {
    field: nameOf("PropertyLocality"),
    title: "Property Locality",
    width: 150,
  },
  {
    field: nameOf("PropertyLocation"),
    title: "Property Location",
  },
  {
    field: nameOf("Ratepayer"),
    title: "Ratepayer",
  },
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOf("AssessmentType"),
    title: "Assessment Type",
    width: 250,
  },
  {
    field: nameOf("AssessmentStatus"),
    title: "Assessment Status",
    width: 250,
  },
];
