import { mockRateNotice } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/mock";
import { IRateNotice } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/components/panels/rate-notice/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getRateNoticeAmountOwing = async (
  id: number
): Promise<IRateNotice> => {
  const responseData = cloneDeep(mockRateNotice);
  await sleep(1000);
  return responseData;
};
