import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  children: [
    {
      path: "nature-of-work",
      name: "Nature Of Work",
      component: require("./nature-of-work/_index").default,
    },
    {
      path: "conditions",
      name: "Conditions",
      component: require("./conditions/_index").default,
    },
    {
      path: "calculated-fees",
      name: "Calculated Fees",
      component: require("./calculated-fees/_index").default,
    },
    {
      path: "permit-types",
      name: "Permit Types",
      component: require("./permit-types/_index").default,
    },
    {
      path: "nc-items",
      name: "NC Items",
      component: require("./nc-items/_index").default,
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_index").default,
    },
  ],
};
