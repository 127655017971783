import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colCRSPortalNewApplications: IColumnFields[] = [
  {
    field: CRSFieldMapping.DateLodged_DATETIME,
    title: "Lodged Date",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },

    format: DATE_FORMAT.DATE,
  },
  {
    field: CRSFieldMapping.ApplicationType,
    title: "Application Type",
  },
  {
    field: CRSFieldMapping.PrimaryCaregiver_DisplayName,
    title: "Parent / Guardian",
  },
  {
    field: CRSFieldMapping.Child_DisplayName,
    title: "Child Name",
  },
  {
    field: CRSFieldMapping.Portal_Application_REFID,
    title: "Portal Application ID",
  },
  {
    field: CRSFieldMapping.Portal_ReferenceNo,
    title: "Portal Reference Number",
  },
];
