import { EditMeterAssociationDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/edit-meter-association/_index";
import { useMeterInstallationStore } from "@app/products/property/meters/[id]/components/child-screens/installation/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const EditMeterAssociationButton = observer(() => {
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);
  const { installation, selectedAssessmentsAssociated, setInstallation } =
    useMeterInstallationStore();
  const { pushNotification } = useCCAppNotificationStore();

  return (
    <>
      <CCNavButton
        title="Edit association"
        disabled={selectedAssessmentsAssociated ? false : true}
        onClick={() => setIsShowEditDialog(true)}
      />

      {isShowEditDialog &&
        selectedAssessmentsAssociated &&
        selectedAssessmentsAssociated[0] && (
          <EditMeterAssociationDialog
            initialForm={selectedAssessmentsAssociated[0]}
            onClose={() => {
              setIsShowEditDialog(false);
            }}
            onSave={(e) => {
              setIsShowEditDialog(false);
              const assessmentsAssociated =
                installation?.BillingDetails?.AssessmentsAssociated;
              if (assessmentsAssociated && installation) {
                const data = [...assessmentsAssociated];
                const newData = data.map((item: any) => {
                  if (item.AssessmentId === e.AssessmentId) {
                    return e;
                  }
                  return item;
                });
                const newAssessmentsAssociated = {
                  BillingDetails: {
                    ...installation.BillingDetails,
                    AssessmentsAssociated: newData,
                  },
                };
                setInstallation({
                  ...installation,
                  ...newAssessmentsAssociated,
                });
              }
              pushNotification({
                title: `Meter association with property updated successfully`,
                type: "success",
              });
            }}
          />
        )}
    </>
  );
});
