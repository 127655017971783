import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const SecurityOrgStructureHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { orgUnit } = useOrgStructureStore();
  const orgUnitId: number = orgUnit?.OrgUnit_ID ?? lastSelectedRow?.ID;
  return (
    <Journal
      id={orgUnitId}
      triggersReload={orgUnit}
      recordType={RECORDTYPE.CORE_OrgUnit}
    />
  );
});
