import { putAppealWithdrawn } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-withdrawn/api";
import { PPRStoreInstance } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class AppealWithdrawnButtonStore {
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
    });
  };

  handleSubmitAppealWithdrawn = async (pprId?: number) => {
    if (pprId) {
      this.setIsLoading(true);
      const response = await putAppealWithdrawn(pprId);
      this.setIsLoading(false);
      if (isSuccessResponse(response)) {
        appNotificationStore.clearErrorNotification();
        PPRStoreInstance.setPPRWithLoading(response.data.Application);
        appNotificationStore.pushNotification({
          type: "success",
          title: "Record saved successfully",
        });
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Withdraw appeal failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    }
  };
}
export const appealWithdrawnButtonStore = new AppealWithdrawnButtonStore();
const appealWithdrawnButtonStoreContext = createContext(
  appealWithdrawnButtonStore
);
export const useAppealWithdrawnButtonStore = () => {
  return useContext(appealWithdrawnButtonStoreContext);
};
