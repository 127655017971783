import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  getJobSchedulingLovs,
  getScheduledJobData,
} from "@common/pages/settings/system-admin/task-scheduling/api";
import { JobsSchedulingElement } from "@common/pages/settings/system-admin/task-scheduling/components/dialogs/new-or-update/components/form-element/_index";
import {
  ScheduleJobType,
  ScheduleJob_New,
  SchedulingJob_LOVs,
} from "@common/pages/settings/system-admin/task-scheduling/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface IJobsSchedulingDialogProps {
  onClosePopup: () => void;
  onSubmit: (data: any) => void;
  isNew?: boolean;
  isLoading: boolean;
  notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>;
}

export const JobsSchedulingDialog = ({
  isNew,
  isLoading: isLoadingSubmit,
  notificationRef,
  onClosePopup,
  onSubmit,
}: IJobsSchedulingDialogProps) => {
  const { gridSelectedRows } = useCCProductListViewStore();

  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [jobsScheduling, setJobsScheduling] = useState<
    ScheduleJob_New | undefined
  >();
  const [lovs, setLovs] = useState<SchedulingJob_LOVs | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const getLovs = async () => {
    setIsLoading(true);
    const response = await getJobSchedulingLovs();
    setIsLoading(false);
    if (isSuccessResponse(response) && response?.data) {
      setLovs(response.data);
    } else {
      setResponseLoadError({
        status: response.status,
        error: response.error,
      });
    }
  };

  const getJobSchedulingData = async () => {
    setIsLoading(true);
    const [response, responseLovs] = await Promise.all([
      getScheduledJobData(
        gridSelectedRows[0]?.JobName,
        gridSelectedRows[0]?.GroupName
      ),
      getJobSchedulingLovs(),
    ]);
    setIsLoading(false);

    if (
      isSuccessResponse(response) &&
      response?.data &&
      isSuccessResponse(responseLovs) &&
      responseLovs?.data
    ) {
      setJobsScheduling(response.data as ScheduleJob_New);
      setLovs(responseLovs.data);
    } else {
      setResponseLoadError({
        status: response.status ?? responseLovs.status,
        error: response.error ?? responseLovs.error,
      });
    }
  };

  const handleSubmit = async (event: FormSubmitClickEvent) => {
    const { values } = event;
    onSubmit(values);
  };

  useEffectOnce(() => {
    if (isNew) {
      getLovs();
      const initialValues = {
        IsEnable: true,
        JobScheduleStartDateTime: new Date(),
        JobType: ScheduleJobType.Daily,
        RecurEvery: 1,
        IsRepeatJob: false,
        IsExpire: false,
        JobId: 0,
        JobDescription: "",
      } as ScheduleJob_New;
      setJobsScheduling(initialValues);
    } else {
      getJobSchedulingData();
    }
  });

  return (
    <Form
      initialValues={jobsScheduling}
      key={JSON.stringify(jobsScheduling)}
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, modified, valid } = formRenderProps;
        return (
          <CCDialog
            maxWidth="45%"
            maxHeight={"85%"}
            height={680}
            titleHeader={`${isNew ? "New" : "Edit"} Task Scheduling`}
            onClose={onClosePopup}
            isLoading={isLoading}
            disabled={isLoadingSubmit}
            bodyElement={
              <>
                {responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={isNew ? getLovs : getJobSchedulingData}
                  />
                ) : (
                  <FormElement className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    <JobsSchedulingElement
                      formRenderProps={formRenderProps}
                      lovs={lovs}
                    />
                  </FormElement>
                )}
              </>
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button
                  type="button"
                  className={"cc-dialog-button"}
                  onClick={() => {
                    onClosePopup();
                  }}
                  disabled={isLoadingSubmit}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!modified || !valid || isLoading || isLoadingSubmit}
                  themeColor="primary"
                  onClick={onSubmit}
                  className={"cc-dialog-button"}
                  iconClass={isLoadingSubmit ? "fas fa-spinner fa-spin" : ""}
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
