import { DTO_Journal_Summary } from "@app/products/property/journals/session-list/[id]/model";
import { useSessionListStore } from "@app/products/property/journals/session-list/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<DTO_Journal_Summary>();

const JournalsInPropertySession = observer(() => {
  const { sessionList, sessionListJournalCols } = useSessionListStore();

  return (
    <div className="cc-form-cols-1">
      <div className="cc-field">
        <label className="cc-label">Journals in property session</label>
        <CCGrid
          data={sessionList?.JournalList ?? []}
          columnFields={sessionListJournalCols}
          primaryField={nameOf("Journal_Number")}
        />
      </div>
    </div>
  );
});
export default JournalsInPropertySession;
