import { townPlanningRoute } from "@app/products/town-planning/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface TownPlanningActionBarNavDropdownProps {
  category: string;
}

export const TownPlanningActionBarNavDropdown = ({
  category,
}: TownPlanningActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={townPlanningRoute.path}
      route={townPlanningRoute}
    />
  );
};
