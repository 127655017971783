import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const renewalsRoute: ICCRoute = {
  path: "renewals",
  name: "Renewals",
  enum: AnimalsMenu.Renewals,
  children: [
    {
      path: "all-by-due-status",
      name: "All By Due Status",
      enum: AnimalsMenu.AnimalsRenewal,
      component: require("./all-by-due-status/_index").default,
    },
    {
      path: "requires-renewal-fee",
      name: "Requires Renewal Fee",
      enum: AnimalsMenu.RenewalsReqFee,
      component: require("./requires-renewal-fee/_index").default,
    },
    {
      path: "requires-renewal-notice",
      name: "Requires Renewal Notice",
      enum: AnimalsMenu.RenewalsReqNotice,
      component: require("./requires-renewal-notice/_index").default,
    },
    {
      path: "notice-sent",
      name: "Notice Sent",
      enum: AnimalsMenu.RenewalsReqRenewal,
      component: require("./notice-sent/_index").default,
    },
  ],
};
