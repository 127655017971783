import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { MeterContactsTab } from "@app/products/property/meters/[id]/components/reference-sidebar/contact/_index";
import { MeterDetailTab } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/_index";
import { ProcessReadingsButton } from "@app/products/property/meters/readings-to-be-processed/components/action-bar/buttons/process-readings/_index";
import { colMeterReadingToBeProcessed } from "@app/products/property/meters/readings-to-be-processed/config";
import { mockMeterReadingToBeProcessed } from "@app/products/property/meters/readings-to-be-processed/mock";
import { metersRoute } from "@app/products/property/meters/route";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],
    centerComponents: [<ProcessReadingsButton />],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={PROPERTY_METERS_ROUTE}
      //   displayName={getBookmarkDisplayName}
      //   detail={getBookmarkDetail}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <MeterDetailTab />,
      },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Related",
      },
      {
        title: "Contacts",
        component: <MeterContactsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab recordType={RECORDTYPE.CommunityProperty_Meter} />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      data={mockMeterReadingToBeProcessed}
      columnFields={colMeterReadingToBeProcessed}
      primaryField={"MeterId"}
    />
  );
};
