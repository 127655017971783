import { isSuccessResponse } from "@common/apis/util";
import { runJobsScheduling } from "@common/pages/settings/system-admin/task-scheduling/api";
import { ScheduleJob_PrimaryKey } from "@common/pages/settings/system-admin/task-scheduling/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IRunJobsSchedulingButtonProps {
  isDisabled?: boolean;
}

export const RunJobsSchedulingButton = observer(
  ({ isDisabled }: IRunJobsSchedulingButtonProps) => {
    const { gridSelectedRows } = useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleRun = async () => {
      if (gridSelectedRows?.[0]) {
        setIsLoading(true);
        const primaryKey: ScheduleJob_PrimaryKey = {
          Jobkey: gridSelectedRows?.[0].JobName,
          Group: gridSelectedRows?.[0].GroupName,
        };
        const response = await runJobsScheduling(primaryKey);
        setIsLoading(false);
        if (isSuccessResponse(response)) {
          pushNotification({
            title: "The schedule job(s) ran.",
            type: "success",
          });
        }
      }
    };

    return (
      <CCNavButton
        title="Run"
        isLoading={isLoading}
        disabled={isDisabled || isLoading || gridSelectedRows?.length !== 1}
        onClick={handleRun}
      />
    );
  }
);
