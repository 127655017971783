export enum Offline_MobileHealth {
  Default = 0,
  Propriator = 1,
  FSS = 2,
}

export enum DataType {
  SystemInitialise = 0,
  BigInt = 1,
  Binary = 2,
  Bit = 3,
  Char = 4,
  Date = 5,
  DateTime = 6,
  DateTime2 = 7,
  DateTimeOffset = 8,
  Decimal = 9,
  Float = 10,
  Image = 11,
  Int = 12,
  Money = 13,
  NChar = 14,
  NText = 15,
  NVarchar = 16,
  Real = 17,
  SmallDateTime = 18,
  SmallInt = 19,
  SmallMoney = 20,
  Structured = 21,
  Text = 22,
  Time = 23,
  TimeStamp = 24,
  TinyInt = 25,
  Udt = 26,
  UniqueIdentifier = 27,
  VarBinary = 28,
  VarChar = 29,
  Variant = 30,
  Xml = 31,
}

export enum ConditionOperation {
  SystemInitialise = 0,
  Equals = 1,
  Contains = 2,
  GreaterThan = 3,
  GreaterThanOrEqualsTo = 4,
  LessThan = 5,
  LessThanOrEqualsTo = 6,
}

export enum DateInterval {
  SystemInitialise = 0,
  Year = 1,
  Month = 2,
  Week = 3,
  Day = 4,
  Hour = 5,
  Minute = 6,
  Second = 7,
}

export enum PaymentPlanInterval {
  Weekly = 1,
  Fortnightly = 2,
  Monthly = 3,
}

export enum PaymentPlanType {
  Installment_Amount = 1,
  Installment_Number = 2,
  ExtensionOfTime = 3,
}

export enum ACTIONWORKFLOW {
  SystemInitialise = 0,
  LAMP_BranchManagerApproval = 1,
  LAMP_LicenceCoordinatorApproval = 2,
  LAMP_DelegateApproval = 3,
  LAMP_NotificationApproval = 4,
  LAMP_ApplicationProcessDelegateApproval = 5,
  PNF_AutoCreatedWorkFlowAction = 6,
  LM_AutoCreatedWorkFlowAction = 7,
}

export enum ACTIONSTATUS {
  SystemInitialise = 0,
  NEW = 1,
  ALLOCATED = 2,
  COMPLETE = 3,
  APPROVED = 4,
  NOTAPPROVED = 5,
  AWAITINGAPPROVAL = 6,
  DRAFT = 7,
  SENT = 8,
  READYTOBESENT = 9,
  NOACTION = 10,
  PENDINGACTION = 11,
  SEEKINGPARTIESAGREEMENTS = 12,
  PRECONCILIATIONMEETINGS = 13,
  INPROGRESS = 14,
  CLOSED = 15,
  AWAITINGSUBMISSION = 16,
  CONSIDERINGSUBMISSION = 17,
  FINAL = 18,
  NOTSTARTED = 19,
  ASSESSORAPPOINTED = 20,
  ASSESSMENTUNDERWAY = 21,
  CONSIDERINGASSESORSREPORT = 22,
  PENDINGDISCUSSIONWITHWORKER = 23,
  PENDINGFINALDECISIONCOMPLETED = 24,
  AWAITINGDELEGATEAPPROVAL = 25,
}

export enum ADVERTISINGSTATUS {
  SystemInitialise = 0,
  Required = 1,
  Complete = 2,
  Cancelled = 3,
  Prepared = 4,
  Sent = 5,
}

export enum FURTHERINFOSTATUS {
  SystemInitialise = 0,
  InfoRequested = 1,
  InfoReceived = 2,
  Cancelled = 3,
  ExtensionRequested = 4,
  ExtensionGranted = 5,
  Archived = 6,
  ExtensionDeclined = 7,
  InfoReceivedAwaitingAssessment = 8,
  Lapsed = 9,
  InfoWaitingForApproval = 10,
  InfoApproved = 11,
  InfoRejected = 12,
  InfoRequestedDraft = 13,
  InfoNotReceived = 14,
  InfoRequestedNew = 15,
}

export enum APPEALSTATUS {
  SystemInitialise = 0,
  AwaitingAppeal = 1,
  Lodged = 2,
  AwaitingHearing = 3,
  AwaitingApproval = 4,
  TeamLeaderApproved = 5,
  TeamLeaderRejected = 6,
  Completed = 7,
  AwaitingOrder = 8,
}

export enum DOCUMENTSTATUS {
  SystemInitialise = 0,
  Draft = 1,
  Final = 2,
}

export enum ContactRoles {
  SystemInitialise = 0,
  Applicant = 1,
  TeamLeader = 2,
  Planner = 3,
  ResponsibleAuthority = 4,
}

export enum APPEALDECISION {
  SystemInitialise = 0,
  Upheld = 1,
}

export enum SOGDECISION {
  SystemInitialise = 0,
  Submitted = 1,
  NotSubmitted = 2,
}

export enum ChecklistType {
  SystemInitialise = 0,
  NonComplianceChecklist = 1,
  TrainingManagerChecklist = 2,
  ActionTypeChecklist = 3,
  PNFChecklist = 4,
  ePlanningChecklist = 5,
  LandManagementChecklist = 6,
  LLPAOChecklist = 7,
  LLPBIOChecklist = 8,
  PermitsChecklist = 9,
  PropertyCertificateQuestionnaire = 10,
  InspectionTypeChecklist = 11,
}

export enum ChecklistAnswerType {
  Default = 0,
  RadioButton = 1,
  TextBox = 2,
  CheckBox = 3,
  Label = 4,
  DatePicker = 5,
}

export enum ChecklistResult {
  SystemInitialise = 0,
  Yes = 1,
  No = 2,
  NA = 3,
}

export enum SRS_NEXTACTION {
  SystemInitialise = 0,
  ISSUEDOCUMENT = 1,
}

export enum CONTACT_NEXTACTION {
  SystemInitialise = 0,
  PICKANOTHER = 1,
}

export enum LAMP_NEXTACTION {
  SystemInitialise = 0,
  CREATEAUTHORISATION = 1,
  UPDATEAUTHORISATION = 2,
  APPROVAL_BRANCHMANAGER = 3,
  APPROVAL_COORDINATOR = 4,
  APPROVAL_REQUESTFI = 5,
  APPROVAL_PUBLICNOTIFICATION = 6,
}

export enum NC_NEXTACTION {
  SystemInitialise = 0,
  INSPECT = 1,
  LODGE = 2,
  ASSESS = 3,
  CLOSE = 4,
  WARNINGNOTICE = 5,
  INFRINGEMENT = 6,
  APPEAL = 7,
  PROSECUTION = 8,
  ALLOCATETOPLANNER = 9,
}

export enum TP_NEXTACTION {
  SystemInitialise = 0,
  REQUESTFI = 1,
  ADVERTISING = 2,
  APPEALED = 3,
  LODGED = 4,
  ALLOCATED = 5,
  ASSESSED = 6,
  REVISIONACCEPTED = 7,
  AMENDMENT = 8,
  ALLOCATEDTOBUILDINGOFFICER = 9,
  ASSESSEDBYBUILDINGOFFICER = 10,
  ISSUEBUILDINGPERMIT = 11,
  ISSUEBUILDINGCERTIFICATE = 12,
  ISSUE = 13,
  BUILDINGCERTIFICATERECEIVED = 14,
  PERMITRECEIVED = 15,
  PLANSTOCOMPLY = 16,
  FIEXTENSION = 17,
  APPEALCOMPLETE = 18,
  REVISIONRECEIVED = 19,
  SECONDARYCONSENT = 20,
  MEETING = 21,
  REFER = 30,
  CERTIFY = 31,
  COMPLIANCE = 32,
  COMPLETE = 33,
  RECERTIFY = 34,
  REFUSE = 35,
  WITHDRAW = 36,
  EXPIRE = 37,
  REFERNA = 38,
  PERMITNOTREQUIRED = 39,
  ISSUECERTIFICATEOFCOMPLETION = 40,
  Exhibition = 41,
  READYFORDECISION = 42,
  VIEWASSESSMENT = 43,
  NOAPPEAL = 44,
  EOT = 45,
  REFERRAL = 46,
  RECOMMENDATION = 47,
  SENDRESPONSE = 48,
  ALLOCATETOPLANNER = 49,
  PSPERMITAUTOACTIONS = 50,
  PSCERTIFICATEAUTOACTIONS = 51,
  CREATENEW = 52,
  CREATEDUPLICATE = 53,
  SEARCHAPPLICATION = 54,
  UPDATEEXTERNAL = 55,
  TRANSFER = 56,
  NAVIGATETOPARENT = 57,
  UPDATEASSESSMENT = 58,
  COMPLETEASSESSMENT = 59,
  CANCELLED = 60,
  CREATEAPPLICAION = 61,
}

export enum PROJECT_NEXTACTION {
  SystemInitialise = 0,
  LODGE = 1,
  COMPLETE = 2,
}

export enum HM_NEXTACTION {
  SystemInitialise = 0,
  REGISTER = 1,
  CLOSE = 2,
  START_TRANSFER = 3,
  TRANSFER = 4,
  RENEW = 5,
  REOPEN = 6,
  PICKDEBTOR = 7,
}

export enum RAD_LICENCE_NEXTACTION {
  SystemInitialise = 0,
  LODGE = 1,
  DOWNLOADMAILMERGEDOC = 2,
  REGISTER_LICENCE = 3,
}

export enum RAD_SOURCE_NEXTACTION {
  SystemInitialise = 0,
  REGISTER = 1,
  CLOSE = 2,
  RENEW = 3,
  DOWNLOADMAILMERGEDOC = 4,
  LodgeAnniversaryOption = 5,
}

export enum LLP_NEXTACTION {
  SystemInitialise = 0,
  ISSUEPERMIT = 1,
  REFUSEPERMIT = 2,
  RENEWPERMIT = 3,
  DEACTIVATEPERMIT = 4,
  REACTIVATEPERMIT = 5,
  ISSUEPRORATAPERMIT = 6,
  LODGEPERMIT = 7,
  WITHDRAWPERMIT = 8,
  PICKDEBTOR = 9,
  APPROVEPERMIT = 10,
  CANCELPERMIT = 11,
  APPROVEPERMITAO = 12,
  APPROVEPERMITBIO = 13,
  PERMITONHOLD = 14,
  RESUMEPERMIT = 15,
  REJECTPERMIT = 16,
  GENERATEINVOICE = 17,
  REVOKEPERMIT = 18,
  APPROVEPERMITAOBIO = 19,
  VALIDATEPERMIT = 20,
  AMENDPERMIT = 21,
  REJECTPERMITAO = 22,
  REJECTPERMITBIO = 23,
  REJECTPERMIT_AO_BIO = 24,
  VERIFYPAYMENT = 25,
}

export enum ANI_NEXTACTION {
  SystemInitialise = 0,
  ISSUE = 1,
  LOSTTAG = 2,
  RENEW = 3,
  DECEASED = 4,
  REACTIVATE = 5,
  ISSUEPRORATA = 6,
  DEREGISTER = 7,
  TAGISSUED = 8,
  REISSUE = 9,
  PICKDEBTOR = 10,
}

export enum EMS_NEXTACTION {
  SystemInitialise = 0,
  LaunchCalendar = 1,
}

export enum FLEET_NEXTACTION {
  SystemInitialise = 0,
  LaunchCalendar = 1,
}

export enum DWCMS_APPLICATION_NEXTACTION {
  SystemInitialise = 0,
  REVIEW = 1,
  ASSESS = 2,
  APPROVE = 3,
  REGISTER = 4,
}

export enum ASSESSMENT_RESULT {
  SystemInitialise = 0,
  Exceeds = 1,
  Meets = 2,
  MeetsWithIntervention = 3,
  DoesNotMeet = 4,
  NA = 5,
}

export enum TCR_CATEGORY {
  SystemInitialise = 0,
  HealthcareandSupport = 1,
  Medication = 2,
  Standards = 3,
  FabricandMaintenance = 4,
  ResidentInformationandRights = 5,
  Complaints = 6,
  Money = 7,
  Staffing = 8,
  Records = 9,
}

export enum REGISTRATIONSTATUS {
  UNSAVED = 0,
  WITHDRAW = 1,
  REGISTER = 2,
  CLOSED = 3,
  DEREGISTERED = 4,
  PENDING = 5,
  DRAFT = 6,
  PLACEMENT = 7,
  OFFERED = 8,
  ACCEPTED = 9,
  DEFERRED = 10,
  ARCHIVED = 11,
  REJECTED = 12,
  CURRENT = 13,
  TRANSFERED = 14,
  DUEFORRENEWAL = 15,
  ONHOLD = 16,
  SUSPENDED = 17,
  CANCELLED = 18,
  EXPIRED = 19,
}

export enum SampleClassification {
  Unclassified = 0,
  FOOD = 1,
  STANDARD = 2,
}

export enum SampleOptions {
  Unclassified = 0,
  ProductType = 1,
  ProductName = 2,
  QuantityPurchased = 3,
  PackagingType = 4,
  Manufacturer = 5,
  ReferredCouncil = 6,
  BatchNo = 7,
  UseByDate = 8,
  Group = 9,
  Temperature = 10,
}

export enum InspectionClassification {
  Unclassified = 0,
  STANDARD_INSPECTION = 1,
  AFSA_INSPECTION = 2,
  NONCOMPLIANCE_INSPECTION = 3,
  TOBACCO_INSPECTION = 4,
  TCR_INSPECTION = 5,
  FSP_AUDIT = 10,
  ASSESSMENT = 11,
  WASTE_SERVICE = 12,
  WASTE_DESLUDGE = 13,
  FIVESTAR_FOODASSESSMENT = 14,
  FIVESTAR_HEALTHASSESSMENT = 15,
  FOODHYGIENEINSPECTION = 16,
  ACCOMASSESSMENT = 17,
  FIVESTAR_FOODSAFETYRATING = 18,
  POOL_ASSESSMENT = 19,
  TRADEWASTE_CHECKLIST = 20,
  FOODPREMISES_CHECKLIST = 21,
  FOODPREMISES_STONNINGTON = 22,
  FOODPREMISES_GLENEIRA = 23,
  ACCOMASSESSMENTV2 = 25,
  SOUTHAUSTRALIA_FOODSAFETYRATINGSCHEME = 26,
  FOODPREMISES_CHECKLIST_BANYULE = 27,
  FOODPREMISES_ASSESSMENT_ACTH = 28,
  FOODPREMISES_GLENEIRA_FOLLOWUP_RISKASSESSMENT = 29,
  INFECTION_CONTROL = 30,
  FOODPREMISES_SURFCOAST = 31,
  FOODPREMISES_INSPECTION_ASSESSMENT_WILLOUGHBY = 32,
  TEMPORARY_FOOD_STALL_SAFETY_ASSESSMENT = 33,
  HEALTHPREMISES_ASSESSMENT_WILLOUGHBY = 34,
  NONCOMPLIANCE_CHECKLIST_2DAYNOTICE = 35,
  NONCOMPLIANCE_CHECKLIST_WARRANTS = 36,
  FOODPREMISES_CHECKLIST_BAYSIDE = 37,
  LIQUOR_LICENCED_PREMISES_CHECKLIST_GOLDCOAST = 38,
  TOBACCO_RETAILER_INSPECTION_CHECKLIST_GOLDCOAST = 39,
  ANIMALS_NONCOMPLIANCE = 40,
  TOBACCO_INSPECTION_ASSESSMENT_DAREBIN = 41,
  PUBLICHEALTHWELLBEING_INSPECTION_ASSESSMENT_DAREBIN = 42,
  PRESCRIBEDACCOMODATION_INSPECTION_ASSESSMENT_DAREBIN = 43,
  EARTHWORKRETAININGWALLSTRUCTURALSUPPORT_INSPECTION_ASSESSMENT_RYDE = 44,
  FENCE_INSPECTION_ASSESSMENT_RYDE = 45,
  PRIVACYSCREENS_INSPECTION_ASSESSMENT_RYDE = 46,
  FOODPREMISES_INSPECTION_ASSESSMENT_NSW = 47,
  SOUTHAUSTRALIA_FOODAUDIT = 48,
  GENERAL_INSPECTION = 49,
  TEMPORARY_FOOD_STALL_INSPECTION_ACTH = 50,
  PNF_INSPECTION_ASSESSMENT = 51,
  PNF_TRAININGDAYVISIT_ASSESSMENT = 52,
  PNF_PREAPPROVEDFIELDVISIT_ASSESSMENT = 53,
  PNF_TOWNHALLVISIT_ASSESSMENT = 54,
  PNF_OGRFVISIT_ASSESSMENT = 55,
  PNF_PREHARVESTVISIT_ASSESSMENT = 56,
  PNF_POSTHARVESTVISIT_ASSESSMENT = 57,
  TEMPORARY_FITOUT_INSPECTION_ACTH = 58,
  TOBACCO_OUTDOOR_DINING_DRINKING_ASSESSMENT_MV = 59,
  TOBACCO_RETAILER_COMPLIANCE_ASSESSMENT_MV = 60,
  FIELD_DATA_SHEET_LLS = 61,
  INS_PASTURE_EXPANSION_FIELD_DATA_SHEET_LLS = 62,
  HEALTHHYGIENEASSESSMENT_WHITEHORSE = 63,
  FOODPREMISES_CHECKLIST_BAYSIDE_V2 = 64,
  SOUTHAUSTRALIA_FOODSAFETYRATINGSCHEME_V3 = 65,
  WHITEHORSE_FIVESTAR_FOODASSESSMENT_V2 = 66,
}

export enum NoticeClassification {
  Unclassified = 0,
  Notice = 1,
  Infringement = 2,
  Caution = 3,
  Reprimand = 4,
}

export enum NoticeRiskRanking {
  NotApplicable = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Extreme = 4,
}

export enum NoticeNumberingMethod {
  None = 0,
  UniqueNumber = 1,
  InheritParentNoWithUniqueSuffix = 2,
}

export enum NC_ITEMSORTOPTIONS {
  SystemInitialise = 0,
  Default = 1,
  AFSAQuestions = 2,
  AssessmentQuestions = 3,
}

export enum NC_RISK {
  SystemInitialise = 0,
  Major = 1,
  Minor = 2,
  Critical = 3,
  Observation = 4,
}

export enum NC_STATUS {
  SystemInitialise = 0,
  Closed = 1,
  Open = 2,
  Superseded = 3,
}

export enum YESNONA {
  SystemInitialise = 0,
  Yes = 1,
  No = 2,
  NA = 3,
}

export enum YESNODONTKNOW {
  No = 0,
  Yes = 1,
  DontKnow = 2,
}

export enum DOCUMENTTYPE {
  SystemInitialise = 0,
  MAILMERGE = 2,
  OTHER = 3,
}

export enum PERSONTYPE {
  BOTH = 0,
  INTERNAL = 1,
  EXTERNAL = 2,
  CONTACT = 3,
}

export enum PERSONTYPESEARCH {
  ALL = 0,
  INTERNAL = 1,
  EXTERNAL = 2,
  CONTACT = 3,
  ADDRESSBOOK = 4,
  INTERNAL_CONTACT = 5,
  EXTERNAL_CONTACT = 6,
}

export enum CONTACTTYPE {
  NONE = 0,
  WORKPHONE = 1,
  HOMEPHONE = 2,
  EMAIL = 3,
  FAX = 4,
  MOBILE = 5,
  POST = 6,
}

export enum ASSESSORCONTACTTYPE {
  SystemInitialise = 0,
  PUBLICCONTACT = 1,
  DISABILITYWORKER = 2,
}

export enum Gender {
  SystemInitialise = 0,
  Male = 1,
  Female = 2,
  Unknown = 3,
  Other = 4,
}

export enum Related_Relationship {
  SystemInitialise = 0,
  Manual = 1,
  EmployerEmployee = 2,
  Supervisor = 4,
  MultiTenancy = 5,
  Licence = 6,
  NonCompliance = 7,
  PlanningEnquiry = 8,
  BuildingEnquiry = 9,
  BuildingEnforcement = 10,
  HealthEnquiry = 11,
}

export enum AFSARESULT {
  SystemInitialise = 0,
  COMPLIED = 1,
  NONCOMPLIED = 2,
  NOTAPPLICABLE = 3,
  SERIOUSNONCOMPLIED = 4,
}

export enum AFSAADJUSTMENT {
  SystemInitialise = 0,
  INCREASE = 1,
  DECREASE = 2,
  RETAIN = 3,
  MANUAL = 4,
}

export enum ASSESSMENTADJUSTMENT {
  SystemInitialise = 0,
  INCREASE = 1,
  DECREASE = 2,
  RETAIN = 3,
  MANUAL = 4,
}

export enum ASSESSMENTRESULT {
  RESULT_0 = 0,
  RESULT_1 = 1,
  RESULT_2 = 2,
  RESULT_3 = 3,
  RESULT_4 = 4,
  RESULT_5 = 5,
  COMPLIED = 6,
  NONCOMPLIED = 7,
  NOTAPPLICABLE = 8,
  YES = 9,
  NO = 10,
  NOTOBSERVED = 11,
  OBSERVATION = 12,
  SATISFACTORY = 20,
  MINOR = 21,
  MAJOR = 22,
  CRITICAL = 23,
  Y = 24,
  N = 25,
  LEVEL_1 = 30,
  LEVEL_2 = 31,
  LEVEL_3 = 32,
  LEVEL_4 = 33,
  LEVEL_5 = 34,
  CONFIDENCE_1 = 40,
  CONFIDENCE_2 = 41,
  CONFIDENCE_3 = 42,
  CONFIDENCE_4 = 43,
  RESULT_8 = 50,
  SERIOUSNONCOMPLIED = 51,
  HEIGHT = 52,
  DEPTH = 53,
  MEASURE = 54,
  CALCULATION = 55,
  DISTANCE = 56,
  NOTE = 57,
  ADDPHOTO = 58,
  LENGTH = 59,
  NUMBER_0 = 1000,
  NUMBER_1 = 1001,
  NUMBER_2 = 1002,
  NUMBER_3 = 1003,
  NUMBER_4 = 1004,
  NUMBER_5 = 1005,
  NUMBER_6 = 1006,
  NUMBER_7 = 1007,
  NUMBER_8 = 1008,
  NUMBER_9 = 1009,
  NUMBER_10 = 1010,
  NUMBER_11 = 1011,
  NUMBER_12 = 1012,
  NUMBER_13 = 1013,
  NUMBER_14 = 1014,
  NUMBER_15 = 1015,
  NUMBER_16 = 1016,
  NUMBER_17 = 1017,
  NUMBER_18 = 1018,
  NUMBER_19 = 1019,
  NUMBER_20 = 1020,
  NUMBER_21 = 1021,
  NUMBER_22 = 1022,
  NUMBER_23 = 1023,
  NUMBER_24 = 1024,
  NUMBER_25 = 1025,
  NUMBER_26 = 1026,
  NUMBER_27 = 1027,
  NUMBER_28 = 1028,
  NUMBER_29 = 1029,
  NUMBER_30 = 1030,
  NUMBER_31 = 1031,
  NUMBER_32 = 1032,
  NUMBER_33 = 1033,
  NUMBER_34 = 1034,
  NUMBER_35 = 1035,
  NUMBER_36 = 1036,
  NUMBER_37 = 1037,
  NUMBER_38 = 1038,
  NUMBER_39 = 1039,
  NUMBER_40 = 1040,
  NUMBER_41 = 1041,
  NUMBER_42 = 1042,
  NUMBER_43 = 1043,
  NUMBER_44 = 1044,
  NUMBER_45 = 1045,
  NUMBER_46 = 1046,
  NUMBER_47 = 1047,
  NUMBER_48 = 1048,
  NUMBER_49 = 1049,
  NUMBER_50 = 1050,
  NUMBER_51 = 1051,
  NUMBER_52 = 1052,
  NUMBER_53 = 1053,
  NUMBER_54 = 1054,
  NUMBER_55 = 1055,
  NUMBER_56 = 1056,
  NUMBER_57 = 1057,
  NUMBER_58 = 1058,
  NUMBER_59 = 1059,
  NUMBER_60 = 1060,
  NUMBER_61 = 1061,
  NUMBER_62 = 1062,
  NUMBER_63 = 1063,
  NUMBER_64 = 1064,
  NUMBER_65 = 1065,
  NUMBER_66 = 1066,
  NUMBER_67 = 1067,
  NUMBER_68 = 1068,
  NUMBER_69 = 1069,
  NUMBER_70 = 1070,
  NUMBER_71 = 1071,
  NUMBER_72 = 1072,
  NUMBER_73 = 1073,
  NUMBER_74 = 1074,
  NUMBER_75 = 1075,
  NUMBER_76 = 1076,
  NUMBER_77 = 1077,
  NUMBER_78 = 1078,
  NUMBER_79 = 1079,
  NUMBER_80 = 1080,
  NUMBER_81 = 1081,
  NUMBER_82 = 1082,
  NUMBER_83 = 1083,
  NUMBER_84 = 1084,
  NUMBER_85 = 1085,
  NUMBER_86 = 1086,
  NUMBER_87 = 1087,
  NUMBER_88 = 1088,
  NUMBER_89 = 1089,
  NUMBER_90 = 1090,
  NUMBER_91 = 1091,
  NUMBER_92 = 1092,
  NUMBER_93 = 1093,
  NUMBER_94 = 1094,
  NUMBER_95 = 1095,
  NUMBER_96 = 1096,
  NUMBER_97 = 1097,
  NUMBER_98 = 1098,
  NUMBER_99 = 1099,
  NUMBER_100 = 1100,
  NUMBER_101 = 1101,
  NUMBER_102 = 1102,
  NUMBER_103 = 1103,
  NUMBER_104 = 1104,
  NUMBER_105 = 1105,
  NUMBER_106 = 1106,
  NUMBER_107 = 1107,
  NUMBER_108 = 1108,
  NUMBER_109 = 1109,
  NUMBER_110 = 1110,
  NUMBER_111 = 1111,
  NUMBER_112 = 1112,
  NUMBER_113 = 1113,
  NUMBER_114 = 1114,
  NUMBER_115 = 1115,
  NUMBER_116 = 1116,
  NUMBER_117 = 1117,
  NUMBER_118 = 1118,
  NUMBER_119 = 1119,
  NUMBER_120 = 1120,
  NUMBER_121 = 1121,
  NUMBER_122 = 1122,
  NUMBER_123 = 1123,
  NUMBER_124 = 1124,
  NUMBER_125 = 1125,
  NUMBER_126 = 1126,
  NUMBER_127 = 1127,
  NUMBER_128 = 1128,
  NUMBER_129 = 1129,
  NUMBER_130 = 1130,
  NUMBER_131 = 1131,
  NUMBER_132 = 1132,
  NUMBER_133 = 1133,
  NUMBER_134 = 1134,
  NUMBER_135 = 1135,
  NUMBER_136 = 1136,
  NUMBER_137 = 1137,
  NUMBER_138 = 1138,
  NUMBER_139 = 1139,
  NUMBER_140 = 1140,
  NUMBER_141 = 1141,
  NUMBER_142 = 1142,
  NUMBER_143 = 1143,
  NUMBER_144 = 1144,
  NUMBER_145 = 1145,
  NUMBER_146 = 1146,
  NUMBER_147 = 1147,
  NUMBER_148 = 1148,
  NUMBER_149 = 1149,
  NUMBER_150 = 1150,
  NUMBER_151 = 1151,
  NUMBER_152 = 1152,
  NUMBER_153 = 1153,
  NUMBER_154 = 1154,
  NUMBER_155 = 1155,
  NUMBER_156 = 1156,
  NUMBER_157 = 1157,
  NUMBER_158 = 1158,
  NUMBER_159 = 1159,
  NUMBER_160 = 1160,
  NUMBER_161 = 1161,
  NUMBER_162 = 1162,
  NUMBER_163 = 1163,
  NUMBER_164 = 1164,
  NUMBER_165 = 1165,
  NUMBER_166 = 1166,
  NUMBER_167 = 1167,
  NUMBER_168 = 1168,
  NUMBER_169 = 1169,
  NUMBER_170 = 1170,
  NUMBER_171 = 1171,
  NUMBER_172 = 1172,
  NUMBER_173 = 1173,
  NUMBER_174 = 1174,
  NUMBER_175 = 1175,
  NUMBER_176 = 1176,
  NUMBER_177 = 1177,
  NUMBER_178 = 1178,
  NUMBER_179 = 1179,
  NUMBER_180 = 1180,
  NUMBER_181 = 1181,
  NUMBER_182 = 1182,
  NUMBER_183 = 1183,
  NUMBER_184 = 1184,
  NUMBER_185 = 1185,
  NUMBER_186 = 1186,
  NUMBER_187 = 1187,
  NUMBER_188 = 1188,
  NUMBER_189 = 1189,
  NUMBER_190 = 1190,
  NUMBER_191 = 1191,
  NUMBER_192 = 1192,
  NUMBER_193 = 1193,
  NUMBER_194 = 1194,
  NUMBER_195 = 1195,
  NUMBER_196 = 1196,
  NUMBER_197 = 1197,
  NUMBER_198 = 1198,
  NUMBER_199 = 1199,
  NUMBER_200 = 1200,
  TO_BE_VERIFIED = 1201,
  TO_BE_PRODUCED = 1202,
  DOSA = 1203,
  OUTDOOR_AREAS_FOR_SMOKING_ONLY = 1204,
  OTHERS = 1205,
  SMOKE_FREE_VENUE = 1206,
  UNSATISFACTORY = 1207,
  CRITICALLYUNSATISFACTORY = 1208,
}

export enum ASSESSMENTEVIDENCE {
  SystemInitialise = 0,
  OBSERVATION = 1,
  INTERVIEW = 2,
  RECORD = 3,
  NA = 4,
  OBSERVATION_INTERVIEW = 5,
  OBSERVATION_RECORD = 6,
  INTERVIEW_RECORD = 7,
  OBSERVATION_INTERVIEW_RECORD = 8,
  RECORD_OBERVATION_DEMONSTRATION = 9,
  DEMONSTRATION = 10,
  DEMONSTRATION_RECORD = 11,
  DEMONSTRATION_OBSERVATION = 12,
  COMPLIED = 13,
  NOTCOMPLIED = 14,
  OBSERVED = 15,
  IMMEDIATE_SOLUTION = 16,
}

export enum TCR_ASSESSMENT_RESULT {
  SystemInitialise = 0,
  Exceeds = 1,
  Meets = 2,
  MeetsWithIntervention = 3,
  DoesNotMeet = 4,
}

export enum TCR_ASSESSMENT_EVIDENCE {
  SystemInitialise = 0,
  NA = 1,
}

export enum ASSESSMENTLIKELIHOOD {
  SystemInitialise = 0,
  CERTAIN = 1,
  LIKELY = 2,
  UNLIKELY = 3,
}

export enum ASSESSMENTCONSEQUENCE {
  SystemInitialise = 0,
  SERIOUS = 1,
  MAJOR = 2,
  MINOR = 3,
}

export enum ASSESSMENTRISK {
  SystemInitialise = 0,
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
  CRITICAL = 4,
  MAJOR = 5,
  MINOR = 6,
}

export enum INSPECTIONDEDUCTIONRESULT {
  NONE = 0,
  JUSTIFIED = 1,
  NOTJUSTIFIED = 2,
}

export enum EnforcementOptions {
  EnforcementPlan = 0,
  ComplianceNotice = 1,
  Infringement = 2,
  SuspendAdmissions = 3,
  AppointAdmin = 5,
  RegistrationStatement = 6,
  Undertaking = 7,
  Censure = 8,
  Sanction = 9,
  SearchWarrant = 10,
  Prosecution = 11,
}

export enum DEDUCTIONTYPE {
  SystemInitialise = 0,
  NONE = 1,
  CRITICALNONCOMPLIANCE = 2,
  FOODPOISONING = 3,
}

export enum HOMEPAGE {
  SystemInitialise = 0,
  CONTACTMANAGER = 1,
  CORPORATEADMINISTATION = 2,
  HEALTHMANAGER = 3,
  WASTEWATER = 4,
  CRS = 5,
  SRS = 6,
}

export enum AGENTS {
  SystemInitialise = 0,
  CORE_ADSync = 1,
  CORE_FinanceImport = 2,
  CORE_ActionNotifications = 3,
  CORE_ActionEscalations = 4,
  CORE_NCItemsNotifications = 5,
  CORE_NCItemsEscalations = 6,
  CORE_FinanceExport = 7,
  CORE_CouncilPropertyAddressSync = 8,
  CORE_NARSync = 9,
  CORE_PropertySync = 10,
  CORE_ComplaintsSync = 11,
  CORE_FinanceStagingUpdate = 12,
  CORE_InspectionsNotifications = 13,
  CORE_ContactExport = 14,
  CORE_InspectionPlannerNotifications = 15,
  CORE_ICALCalendarSubscription = 16,
  CORE_ProcessPool = 17,
  CORE_MailMessaging = 18,
  CORE_InfringementsSync = 19,
  CORE_BatchUpdateProcess = 20,
  CORE_HouseKeeping = 21,
  CORE_StatusUpdate = 22,
  HM_DueConditionsNotifications = 100,
  HM_DueConditionsEscalations = 101,
  HM_InspectionScheduleNotifications = 102,
  HM_InspectionScheduleEscalations = 103,
  HM_ExpireTemporaryPremises = 104,
  HM_SecondaryMode = 105,
  TP_StatDays = 301,
  TP_UpdateApplications = 302,
  Building_OverdueApplicationsNotifications = 303,
  TP_SPEAR_Sync = 304,
  Building_CreateCILPermits = 305,
  RAD_Notifications = 501,
  RAD_Updates = 502,
  RAD_GLBatchProcess = 503,
  RAD_ComplianceTestAlert = 504,
  TP_PSA_Referral_Notifications = 505,
  SRU_Service = 601,
  SSMS_ActionNotifications = 701,
  DWCMS_Notifications = 801,
  DWCMS_DownloadExDoct = 802,
  DWCMS_Updates = 803,
  LLP_UpdatePermits = 804,
  Infringe_EndOfDayProcessor = 901,
}

export enum AGENT_GREENTREE {
  SystemInitialise = 0,
  FinanceExportDebtors = 1,
  FinanceExportTransactions = 2,
  FinanceImportDebtors = 3,
  FinanceImportInventory = 4,
  FinanceImportTransactions = 5,
}

export enum BPAYREFNOMETHOD {
  SystemInitialise = 0,
  InvoiceNumber = 1,
  DebtorNumber = 2,
}

export enum iCRNGENERATIONMETHOD {
  SystemInitialise = 0,
  AmountOnly = 1,
  DateOnly = 2,
  DateAndAmount = 3,
}

export enum AUSPOSTFORMAT {
  SystemInitialise = 0,
  Stonnington = 1,
  Casey = 2,
  GlenEira = 3,
  CoPP = 4,
  None = 5,
  Internal = 6,
  Hobart = 7,
  Darebin = 8,
  ShireofMurray = 9,
  MOD09v02 = 10,
}

export enum PHONENUMBERFORMATS {
  SystemInitialise = 0,
  Default = 1,
  Format1 = 2,
}

export enum BPAYREFNOTRUNCATION {
  SystemInitialise = 0,
  None = 1,
  Right = 2,
  Left = 3,
}

export enum REFNOTRUNCATION {
  SystemInitialise = 0,
  None = 1,
  Right = 2,
  Left = 3,
}

export enum SMSContent {
  Body2SubjectBody = 0,
  Subject2SubjectBody = 1,
  SubjectAndBody = 2,
  Subject2SubjectBodyEmpty = 3,
  Body2BodySubjectEmpty = 4,
}

export enum JAVASCRIPT_Months {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

export enum SYSTEM_Month {
  Default = 0,
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export enum NUMBERINGMETHOD {
  DatabaseSequence,
  ManualEntry,
  CustomFormat,
}

export enum ISSUENUMBER {
  Creation,
  Registration,
}

export enum NUMBERINGFORMAT {
  YYYYNN,
  NNYYYY,
  YYYYSNN,
  NNSYYYY,
  YYNN,
  NNYY,
  YYSNN,
  YYSPREFIXNN,
  NNSYY,
  NNNN,
  YYDNN,
  YYYYDNN,
  YYYYNNSSTAGE,
  SURVEYSYYFNNNNSSTAGE,
  NNDYY,
  NNDYYYY,
  Manual,
  YYYYPNN,
  APPLICATIONNODISPLAYSCSEQ,
  YYYYFNN,
  YYFNN,
  DIVRTYYYYNN,
  FINYEARZONECODENN,
}

export enum INFRINGEMENTEXTERNALSYSTEM {
  None = 0,
  PinForce = 1,
}

export enum INFORMATIONSHARING {
  Referral = 1,
  Other = 2,
}

export enum RelationshipToComplainee {
  Default = 0,
  Employer = 1,
  Colleague = 2,
}

export enum NDISStatus {
  Default = 0,
  Deferred = 1,
}

export enum RECORDSSYSTEM {
  None,
  TRIMContext,
  infoXpert,
  ECM,
  BluePointContentPlus,
  Objective,
  ECMCMIS,
  SharePoint,
  InternalFileSystem,
  Fileit,
}

export enum SHAREPOINTCREDENTIALS {
  Default = 0,
  DefaultNetwork = 1,
  Network = 2,
  Online = 3,
  Proxy = 4,
  None = 5,
  OAuthTokenPassthrough = 6,
  OAuthClientCredentialToken = 7,
}

export enum OBJECTIVEAUTHENTICATION {
  None,
  Basic,
  Negotiate,
}

export enum OBJECTIVECREDENTIALS {
  None,
  SiteUserRecordsDetails,
  DefaultCredentials,
}

export enum PROPERTYSYSTEM {
  None,
  Lynx,
  PropertyGov,
  Authority,
  Pathway,
  Proclaim,
  Custom,
}

export enum PROPERTYAPISYSTEM {
  None,
  Community,
  PortPhillipDellBoomi,
  PortAdelaide,
}

export enum INFRINGEMENTSYSTEM {
  None,
  Infringe,
  Custom,
}

export enum GIS {
  None,
  Exponare,
  Latitude,
  GoogleMaps,
  IntraMaps,
  Bing,
  Weave,
  Dekho,
  SpectrumSpatial,
  MapInfo,
  Geocortex,
  Pozi,
  ArcGIS,
}

export enum GISReferenceKey {
  GISKey,
  AssessmentNumber,
}

export enum FINANCEMETHOD {
  None = 0,
  Localized = 1,
  Direct = 2,
}

export enum FINANCENOTIFICATIONS {
  NoEmail = 0,
  FailureOnly = 1,
  SuccessAndFailure = 2,
}

export enum FINANCESYSTEM {
  None = 0,
  Collect = 1,
  Authority = 2,
  Pathway = 3,
  Proclaim = 4,
  FinanceOneCRS = 5,
  CashLink = 6,
  Fujitsu2000 = 7,
  Greentree = 8,
  FinanceOnePathway = 9,
  IBIS = 10,
  Computron = 11,
  MYOB = 12,
  FinanceOne = 13,
  ComputronDebtors = 14,
  ComputronPathway = 15,
  SynergySoft = 16,
  CommunityFinance = 17,
  TechOneCIAnyWhere = 18,
  TechnologyOne = 19,
  Oracle = 20,
  SAP = 21,
}

export enum FINANCEAPISYSTEM {
  None = 0,
  CommunityFinance = 1,
  PortPhillipDellBoomi = 2,
  Oracle = 3,
}

export enum FINANCELOOKUPMETHOD {
  FeeID,
  DebtorNumber,
  DebtorNumberBalance,
  InvoiceNumber,
}

export enum FINANCEEXPORTFORMAT {
  Standard,
  Format1,
  Format2,
  Custom,
}

export enum SUMMARYMODEL {
  None = 0,
  Reconciliation = 1,
  SummaryDebtor = 2,
  SummaryFee = 3,
  SummaryTransactionCount = 4,
}

export enum FINANCECOLLECTSYSTEMMODULE {
  None = 0,
  Animals = 1,
  DISRES = 2,
  Building = 3,
  CRS = 4,
  DevApps = 5,
  HealthManager = 6,
  LLPermits = 7,
  TownPlanning = 8,
  StreetProtection = 9,
  WasteWater = 10,
  EventManagementSystem = 11,
}

export enum ADDRESSORDER {
  PreferredPostalAlternative,
  PostalPreferredAlternative,
}

export enum ASSETSSYSTEM {
  None = 0,
  Custom = 1,
  Confirm = 2,
  RAMM = 3,
  AssetMaster = 4,
  Assetic = 5,
  Reflect = 6,
  Conquest = 7,
}

export enum WORKORDERSYSTEM {
  None = 0,
  CAMSS = 1,
  CommunityAssets = 2,
}

export enum NARSYSTEM {
  None,
  PropertyGovEntities,
  PNR,
  Custom,
  CustomDynamic,
}

export enum NARAPISYSTEM {
  None,
  Community,
  PortPhillipDellBoomi,
  PortAdelaide,
}

export enum CONTACTDIRECTEXPORT {
  None,
  MDM,
  Custom,
  SQL,
  Oracle,
}

export enum NOTICEEXPORT {
  None,
  TechOne,
}

export enum CRMSYSTEM {
  None,
  Custom,
  Merit,
}

export enum CONNECTIONMETHOD {
  SQL,
  ODBC,
  OLE,
}

export enum SMSDELIVERYMETHOD {
  EMAILGATEWAY,
  API,
}

export enum SMSPROVIDER {
  TELSTRA,
}

export enum SMSREQUESTTYPE {
  GET = 1,
  POST = 2,
}

export enum COMPLAINTSMENU {
  Complaints,
  CRMS,
}

export enum PROPERTYRETURNASSESSMENTKEY {
  None = 0,
  ASSESSMENTNUMBER = 1,
  ASSESSMENTID = 2,
  TITLEID = 3,
  CUSTOMKEY = 4,
}

export enum IMPORTMETHOD {
  File,
  Database,
}

export enum PROCLAIMQUERYMETHOD {
  Standard,
  Custom,
}

export enum TRIMSTORAGEMETHOD {
  SELECT,
  CREATE,
  NONE,
  PROPERTYNUMBER,
}

export enum INFOXPERTSTORAGEMETHOD {
  SELECT,
  CREATE,
}

export enum SHAREPOINTSTORAGEMETHOD {
  SELECT,
  CREATE,
  PROPERTYNUMBER,
}

export enum TRIMNUMBERINGMETHOD {
  TRIM,
  SYSTEM,
}

export enum TRIMSYSTEMNUMBERINGMETHOD {
  ApplicationNoYear,
  ApplicationNumberDisplay,
  LandholdingNumber,
  CertificationNumber,
  NotificationNumber,
  EnquiryNumber,
}

export enum TRIMSEARCH {
  DEFAULT = 10000000,
}

export enum AddressValidationType {
  None = 0,
  VMAS2 = 1,
  DataTools = 2,
  DELWPMapShare = 3,
  NSWPOINT = 4,
}

export enum PERSONFUNCTIONTYPE {
  SystemInitialise = 0,
  RECORDEDBY = 1,
  PROPRIETOR = 2,
  ACTIONOFFICER = 3,
  CONTACT = 4,
  INFECTIONCONTROLOFFICER = 7,
  SUPERVISOR = 12,
  ASSESSEDBY = 17,
  COORDINATOR = 18,
  MANAGER = 22,
  PRIMARYCONTACT = 32,
  AUDITOR = 38,
  FSSCONTACT = 41,
  INCHARGECONTACT = 42,
  POOL_OPERATOR = 51,
  POOL_CONTRACTOR = 52,
  PROSECUTION_DEFENDANT = 53,
  PROSECUTION_DEFENDANTLEGALREP = 54,
  PROSECUTION_INFORMANT = 55,
  PROSECUTION_INFORMANTLEGALREP = 56,
  PROPERTY_OWNER = 57,
  WASTEWATER_OWNER = 3000,
  WASTEWATER_PLUMBER = 3001,
  WASTEWATER_APPLICANT = 3002,
  WASTEWATER_CONTRACTOR = 3003,
  HEALTH_ADMINOFFICER = 4000,
  HEALTH_APPLICANT = 4001,
  HEALTH_REGIONMANAGER = 4002,
  HEALTH_SECRETARY = 4003,
  HEALTH_CONTRACTOR = 4004,
  HEALTH_EVENTCOORDINATOR = 4005,
  HEALTH_SECONDARYFSS = 4006,
  HEALTH_MANAGEMENTCOMPANY = 4007,
  HEALTH_PRESIDENT = 4009,
  HEALTH_PREVIOUSFSS = 4010,
  HEALTH_PREVIOUSPROPRIETOR = 4011,
  HEALTH_RECALLCONTACT = 4012,
}

export enum FORMATTYPE_ADDRESS {
  SystemInitialise = 0,
  HTML = 1,
  VBSTRING = 2,
  SINGLELINE = 3,
  INLINE = 4,
  GOOGLEMAPS = 5,
}

export enum FORMATTYPE_DATE {
  SystemInitialise = 0,
  DATEONLY = 1,
  DATEANDTIME = 2,
  TIMEONLY = 3,
  DATEMONTHONLY = 4,
  DDMMYYYY = 5,
  DateForJournals = 6,
  YYYYHMMHDD = 7,
  DDMMMYYYY = 8,
  DD_MMM_YYYY = 9,
  FULL_DATEANDTIME_DDMMMYYYY_HHMMSSTT = 10,
  FULL_DATEANDTIME = 11,
  YYYYMMDD = 12,
  FULLDATE_WITH_DAYS = 13,
  FULLDATEANDTIME_WITH_DAYS = 14,
}

export enum HEALTHMANAGER_SYSTEMSETTINGSFIELD {
  SystemSettings_Prosecutions,
  SystemSettings_VicFin,
  SystemSettings_DefaultState,
  SystemSettings_DefaultLanguage,
  SystemSettings_DefaultPreferedMethod,
  SystemSettings_PageSize,
  SystemSettings_RenewalDate,
  SystemSettings_FeeOption,
  SystemSettings_PDA_AFSAInspectionTypeId,
}

export enum FoodInspectionStandard {
  AFSA = 0,
  FPAR = 1,
}

export enum CUSTOMERSERVICE_REQUESTSTATUS {
  SystemInitialise = 0,
  DRAFT = 1,
  PENDING = 2,
  OPEN = 3,
  ONHOLD = 4,
  CLOSED = 5,
}

export enum CUSTOMERSERVICE_CONFIGURATIONMODE {
  SystemInitialise = 0,
  LOCAL_GOV = 1,
  STATE_GOV = 2,
}

export enum TOWNPLANNING_CONFIGURATIONMODE {
  SystemInitialise = 0,
  LOCAL_GOV = 1,
  PPR = 2,
  PNF = 3,
}

export enum HEALTHMANAGER_CONFIGURATIONMODE {
  SystemInitialise = 0,
  LOCAL_GOV = 1,
  STATE_GOV = 2,
  WATER_AUTHORITY = 3,
}

export enum PAYMENTTYPE {
  NONE = 0,
  CASH = 1,
  CHEQUE = 2,
  EFT = 3,
  CREDITCARD = 4,
  WRITEOFF = 5,
  CREDITNOTE = 6,
  FINANCE = 7,
  BANKDEPOSIT = 8,
  DISCOUNT = 9,
  AWAITINGPAYMENT = 10,
  PAYMENTGATEWAY = 11,
  BPAY = 12,
  MONEYORDER = 13,
  ONLINE = 14,
  NOTREQUIRED = 15,
  JOURNAL = 16,
}

export enum PAYMENTOPTIONS {
  NONE = 0,
  CASH = 1 << 1,
  CHEQUE = 1 << 2,
  EFT = 1 << 3,
  CREDITCARD = 1 << 4,
  WRITEOFF = 1 << 5,
  CREDITNOTE = 1 << 6,
  FINANCE = 1 << 7,
  BANKDEPOSIT = 1 << 8,
  DISCOUNT = 1 << 9,
  AWAITINGPAYMENT = 1 << 10,
  PAYMENTGATEWAY = 1 << 11,
  BPAY = 1 << 12,
  MONEYORDER = 1 << 13,
  ONLINE = 1 << 14,
  NOTREQUIRED = 1 << 15,
  JOURNAL = 1 << 16,
}

export enum ProRataPeriod {
  None,
  Quarterly,
  Biannually,
  Monthly,
}

export enum ComplaintStatus {
  New = 0,
  Open = 1,
  Closed = 2,
  OpenExtended = 3,
}

export enum ExternalIntegrationSystem {
  Infringement_Pinforce = 1,
}

export enum ExternalIntegrationStatus {
  UNPROCESSED = 0,
  SUCCESS = 1,
  FAILURE = 2,
}

export enum ApprovalStatus {
  Unspecified = 0,
  Approved = 1,
  Declined = 2,
  Waiting = 3,
}

export enum SSMS_Organisation {
  VICRoads = 1,
  Council = 2,
  Installer = 3,
}

export enum ProjectStatus {
  SystemInitialise = 0,
  New = 1,
  Lodged = 2,
  Closed = 3,
}

export enum CCQuestionSouceType {
  Unspecified = 0,
  Core_Keyword = 1,
  Core_Question = 2,
}

export enum CCOptionType {
  Unspecified = 0,
  MailMerge = 1,
}

export enum CCOptions {
  Unspecified = 0,
  Yes = 1,
  No = 2,
  NA = 3,
}

export enum AssessmentSource {
  Unspecified = 0,
  CouncilConsent = 1,
}

export enum TobaccoVisitType {
  Complaint_Test_Purchase,
  Education,
  Education_Following_Complaint,
  Random_Test_Purchase,
  Repeat_Test_Purchase,
  Compliance_Outdoor_Location,
}

export enum RECORDSLISTTYPE {
  TRIM_Classification,
  TRIM_Keyword,
  TRIM_Container,
  TRIM_RecordType,
  TRIM_None,
  TRIM_Record,
}

export enum RECORDSCUSTOMFIELDS_COMPLAINTS {
  None = 0,
  ComplaintNumber = 1,
  Category = 2,
  SubCategory = 3,
  RespondentFullAddress = 4,
  RespondentAddressLine1 = 5,
  RespondentSuburb = 6,
  RespondentPostcode = 7,
}

export enum CORPORATEAUTHORISATIONTYPE {
  CM_ACCOUNTSPAYABLEOFFICER = 1001,
  CM_ACCOUNTSRECEIVABLEOFFICER = 1002,
  CM_BOOKINGSOFFICER = 1003,
  CM_BURIALADMINISTRATIONOFFICER = 1004,
  CM_BURIALATTENDANT = 1005,
  CM_CREMATIONADMINISTRATIONOFFICER = 1006,
  CM_CREMATORIUMATTENDANT = 1007,
  CM_ENDOFTENUREOFFICER = 1008,
  CM_GENERALADMINISTRATIONOFFICER = 1009,
  CM_GRAVEDIGGER = 1010,
  CM_MEMORIALCONSULTANT = 1011,
  CM_HERITAGEOFFICER = 1012,
  CM_FUNERALDIRECTOR = 1013,
  CRS_MATERNALCONSULTANT = 2001,
  CRS_CBCOFFICER = 2002,
  CRS_HBCOFFICER = 2003,
  CRS_TYOKINDEROFFICER = 2004,
  CRS_FYOKINDEROFFICER = 2005,
  CRS_FINANCEOFFICER = 2006,
  CRS_ADMINISTRATOR = 2007,
  MCH_DutyNurse = 2501,
  MCH_SpecificNurse = 2502,
  CRMS_Councillor = 2601,
  CRMS_Admin = 2602,
  CRMS_User = 2603,
  VICRoads_Admin = 2701,
  VICRoads_TeamLeader = 2702,
  VICRoads_Engineer = 2703,
  Council_Admin = 2704,
  Council_Leader = 2705,
  Council_Engineer = 2706,
  Installer_Admin = 2707,
  Installer = 2708,
  OpenOffice_Admin = 2709,
}

export enum ProductRole {
  SSMS_VICRoadsEngineer = 1,
  SSMS_VICRoadsTeamLeader = 2,
  SSMS_VICRoadsAdmin = 4,
  SSMS_InstallerAdmin = 8,
  SSMS_CouncilEngineer = 16,
  SSMS_CouncilLeader = 32,
  SSMS_CouncilAdmin = 64,
  SSMS_Installer = 128,
  SSMS_OperationalAdmin = 256,
}

export enum ContactClassification {
  SystemInitialise = 0,
  Contact = 1,
  SiteUser = 2,
  Person = 3,
  Company = 4,
  Partnership = 5,
  IncorporatedBody = 6,
  PortalUser = 7,
  PrivateInspector = 8,
  CRS_Parent = 10,
  CRS_Child = 11,
  ProprietorBus_Individual = 15,
  ProprietorBus_Company = 16,
  ProprietorBus_Partnership = 17,
  ProprietorComm_NotForProfit = 18,
  ProprietorComm_IndividualForCharity = 19,
  ProprietorComm_BodyForCharity = 20,
  VCATBoardRepresentative = 21,
}

export enum ContactExtension {
  SystemInitialise = 0,
  HasJobTitle = 1,
  HasOrganisation = 2,
  HasVerificationQuestion = 4,
  HasVerificationResponse = 8,
  HasDateOfBirth = 16,
  HasPortalUserDetails = 32,
  HasLicenceNo = 64,
  HasFSSCertificate = 128,
  HasDebtorNo = 256,
  HasCMPlaceOfBirth = 512,
  HasCMContactable = 1024,
  HasCMDeceased = 2048,
  HasCMLastKnownAddress = 4096,
  HasCMDatePassedAway = 8192,
  HasGender = 16384,
  HasContactAttention = 32768,
  HasAgeAtDeath = 65536,
  HasLanguage = 131072,
  HasSecondLanguage = 262144,
  HasDWCMSAdditionalDetails = 524288,
}

export enum SelectContactWizard_Mode {
  PickContact,
  PickContactWithCreate,
  EditContact,
  PickContactOnly,
  PickContactOnlyWithCreate,
}

export enum ContactRelationshipWizard_Mode {
  PickContact,
  PickContactWithCreate,
  EditContact,
  PickContactOnly,
  PickContactOnlyWithCreate,
}

export enum ContactRelationshipType {
  SystemInitialise = 0,
  AdditionalContact = 1,
  Officer = 2,
  RecordedBy = 3,
  AssessedBy = 4,
  Previous_AdditionalContact = 5,
  PrivateCertifier = 198,
  Builder = 199,
  PrimaryContact = 200,
  InChargeContact = 202,
  AdditionalContact_Supervisor = 203,
  AdditionalContact_Manager = 204,
  AdditionalContact_RegionManager = 205,
  AdditionalContact_Secretary = 206,
  AdditionalContact_EventCoordinator = 207,
  AdditionalContact_ManagementCompany = 208,
  AdditionalContact_President = 209,
  AdditionalContact_PreviousPrimaryContact = 210,
  Other = 211,
  AdditionalContact_Director = 212,
  AdditionalContact_PublicOfficer = 213,
  Applicant = 224,
  Owner = 226,
  Plumber = 230,
  Contractor = 231,
  Operator = 232,
  AdditionalContact_Auditor = 233,
  Complainant = 234,
  Prosecution_Defendant = 235,
  Prosecution_DefendantLegalRep = 236,
  Prosecution_Informant = 237,
  Prosecution_InformantLegalRep = 238,
  Respondant = 239,
  Partner = 240,
  LicenceHolder = 241,
  AdditonalContact_AuthorisedContact = 242,
  AdditonalContact_NotificationRecipient = 243,
  AdditonalContact_Client = 244,
  AdditonalContact_ClientContact = 245,
  Appellant = 246,
  AppellantRepresentation = 247,
  Witness = 248,
  AdditionalContact_PropertyOwner = 249,
  AdditionalContact_Landlord = 250,
  AdditionalContact_EstateAgent = 251,
  AdditionalContact_InterestedParty = 252,
  AdditionalContact_Conveyancer = 253,
  AdditionalContact_Tenant = 254,
  AdditionalContact_Surveyor = 255,
  AdditionalContact_Builder = 256,
  AdditionalContact_Solicitor = 257,
  AdditionalContact_Draftsman = 258,
  AdditionalContact_Practitioner = 259,
  AdditionalContact_Architect = 260,
  AdditionalContact_Accountant = 261,
  AdditionalContact_Engineer = 262,
  Appointment_AppointmentWith = 263,
  AdditionalContact_PrivateCertifier = 264,
  AdditionalContact_AdditionalApplicant = 265,
  AdditionalContact_PreviousApplicant = 266,
  AdditionalContact_PreferredContact = 267,
  AdditionalContact_ReferralAuthority = 268,
  ReferralAuthority = 269,
  EnforcementAgency = 270,
  Manufacturer = 271,
  ReferredCouncil = 272,
  Laboratory = 273,
  AdditionalContact_BusinessOwner = 274,
  LGA = 275,
  Distributor = 276,
  Patient = 277,
  Supplier = 278,
  Pharmacist = 279,
  Business = 280,
  BusinessOwner = 281,
  AdditionalContact_Witness = 282,
  CouncilRepresentation = 283,
  ApplicantRepresentation = 284,
  EmergencyContact = 285,
  EmergencyContact2 = 286,
  AdditionalContact_PreviousPharmacist = 287,
  AdditionalContact_PreviousLicenceHolder = 288,
  AdditionalContact_PropertyManager = 289,
  Installer = 290,
  TeamLeader = 291,
  CEO = 292,
  LevyPayer = 293,
  AdditionalContact_NominatedLevyPayer = 294,
  Interviewee = 295,
  Supervisor = 296,
  AdditionalContact_PreviousSupervisor = 297,
  Researcher = 298,
  AdditionalContact_PreviousResearcher = 299,
  CRS_ParentGuardian1 = 300,
  CRS_ParentGuardian2 = 301,
  CRS_Child = 302,
  CRS_Contact1 = 303,
  CRS_Contact2 = 304,
  CRS_ManagementContact1 = 305,
  CRS_ManagementContact2 = 306,
  CRS_President = 307,
  CRS_Secretary = 308,
  CRS_Treasurer = 309,
  CRS_PublicOfficer = 310,
  CRS_EmergencyFirstContact = 311,
  CRS_EmergencySecondContact = 312,
  CRS_Carer = 313,
  CRS_FamilyFriend = 314,
  CRS_RegistrationContact = 315,
  CRS_Relative = 316,
  CRS_Centre_Cleaner = 317,
  CRS_Centre_Janitor = 318,
  CRS_Centre_Security = 319,
  CRS_Centre_CentreContact = 320,
  CRS_AdditionalContactParentGuardian = 321,
  CRS_AdditionalEmergencyContact = 322,
  Consultant = 323,
  VIP = 324,
  AdditionalContact_SubmitterObjecting = 325,
  AdditionalContact_SubmitterSupporting = 326,
  AdditionalContact_SubmitterSupportingWithChanges = 327,
  AdditionalContact_SubmitterWithdrawn = 328,
  AdditionalContact_SystemOwner = 329,
  AdditionalContact_SystemManager = 330,
  AdditionalContact_BuildingOwner = 331,
  AdditionalContact_BuildingManager = 332,
  AlternativeContact = 333,
  AdditionalContact_PreviousAlternativeContact = 334,
  AdditionalContact_PreviousOwner = 335,
  CouncilConsent_Surveyor = 336,
  ApplicantManager = 337,
  SRU_AdditionalFundedContact = 338,
  SRU_AdditionalEnforcementContact = 339,
  SRU_IRBPrimaryContact = 340,
  AdditionalContact_CertifyingAuthority = 341,
  AdditionalContact_PreviousPartner = 342,
  AdditionalContact_AfterHoursContact = 343,
  CEM_AdditionalRegisterationContact = 344,
  InvestigationSubject = 345,
  InvestigationServiceProvider = 346,
  VCATBoardRepresentative = 347,
  ActionAgainst = 348,
  AdditionalContact_Payee = 349,
  Manager = 350,
  AdditionalContact_PreviousManager = 351,
  PSC = 352,
  AdditionalContact_PreviousDirector = 353,
  Register_PreferredContact = 354,
  AdditionalContact_PrivateInspector = 355,
  AdditionalAssessor = 356,
  AsessmentWorker = 357,
  Assessor = 358,
  NominatedPractionerPsychologist = 359,
  Leasee = 360,
  Subcontractor = 361,
  AdditionalContact_Designer = 362,
  WASTEWATER_OWNER = 600,
  HM_Proprietor = 700,
  HM_Proprietor2 = 701,
  HM_Proprietor3 = 702,
  HM_Proprietor4 = 703,
  HM_AdditionalContact_PreviousProprietor = 710,
  HM_AdditionalContact_FSSSecondary = 712,
  HM_AdditionalContact_FSSPrevious = 713,
  HM_AdditionalContact_InfectionControlOfficer = 714,
  HM_AdditionalContact_PropertyOwner = 715,
  HM_AdditionalContact_RecallContact = 716,
  HM_Inspection_PersonContacted = 717,
  HM_AdditionalContact_Supervisor = 718,
  HM_AdditionalContact_InChargeContact = 719,
  HM_AdditionalContact_AlternateContact = 720,
  HM_FSSContact = 721,
  HM_AdditionalContact_FSSContactPrevious = 722,
  HM_Inspection_TobaccoBuyer = 723,
  EPLAN_Applicant = 1000,
  EPLAN_PreferredContact = 1001,
  EPLAN_Objector = 1002,
  CUSTOMERSERVICE_RequestedBy = 2001,
  CUSTOMERSERVICE_OnBehalfOf = 2002,
  CUSTOMERSERVICE_CEO = 2003,
  CUSTOMERSERVICE_ActionOfficer = 2004,
  CUSTOMERSERVICE_Coordinator = 2005,
  CUSTOMERSERVICE_OwnerAnimalAttack = 2006,
  CUSTOMERSERVICE_ColdChain_BreachReportedBy = 2010,
  CUSTOMERSERVICE_Councillor = 2011,
  Complainee = 2012,
  PreviousComplainee = 2013,
  Provider = 2014,
  PreviousProvider = 2015,
  AdditionalWorker = 2016,
  AdditionalProvider = 2017,
  RequestorProvider = 2018,
  PreviousRequestorProvider = 2019,
  MCH_ResponsiblePerson = 3001,
  MCH_PrimaryContact = 3002,
  MCH_CentreContact1 = 3003,
  MCH_CentreContact2 = 3004,
  PoundRegister_ImpoundedByExternal = 3101,
  AdditionalContact_PreviousImpoundedByExternal = 3102,
  Animals_Delegate = 3103,
  Animals_AdditionalContact_KennelOwner = 3104,
  DisRes_PermitHolder = 4001,
  DisRes_PermitAddressCareOf = 4002,
  DisRes_PermitHolderContactPerson = 4003,
  DisRes_PostalAddressCareOf = 4004,
  AMS_AppointmentWith = 6001,
  EMS_RequestedBy = 6501,
  EMS_Debtor = 6502,
  EMS_Organiser = 6503,
  EMS_Trainer = 6504,
  EMS_AdditionalContact = 6505,
  EMS_PreviousRequestedBy = 6506,
  TM_CourseProvider = 7001,
  TM_CourseTrainer = 7002,
  TM_Attendees = 7003,
  TM_WaitingList = 7004,
  TM_TrainingRequest = 7005,
  RAD_Applicant = 7501,
  RAD_Owner = 7502,
  RAD_Occupier = 7503,
  RAD_AuthorisedContact = 7504,
  SSMS_CouncilEngineer = 8001,
  SSMS_CouncilLeader = 8002,
  SSMS_TrafficEngineer = 8003,
  SSMS_TeamLeader = 8004,
  SSMS_InspectionRequestedBy = 8005,
  SSMS_InspectedBy = 8006,
  SSMS_NOCReceivedBy = 8007,
  SSMS_MOCCreatedBy = 8008,
  SSMS_MOCSentBy = 8009,
  SSMS_MOCCancelledBy = 8010,
  SSMS_InstalledBy = 8011,
  LLP_Supervisor = 8501,
  LLP_PreferredContact = 8502,
  LLP_AdditionalContact1 = 8503,
  LLP_AdditionalContact2 = 8504,
  SRU_SeizureContact = 9001,
  SRU_Administrator = 9002,
  SRU_ReportableTransaction_Contact = 9003,
  SRU_IncidentContact = 9004,
  SRU_FacilityPSC = 9005,
  SRU_AdditionalContact_PreviousFacilityPSC = 9006,
  SRU_FacilityLandlord = 9007,
  SRU_AdditionalContact_PreviousFacilityLandlord = 9008,
  SRU_LegalPersonalRepresentative = 9009,
  SRU_ComplaintsNominee = 9010,
  SRU_AdditionalContact_PreviousComplaintsNominee = 9011,
  SRU_AdditionalContact_PreviousLegalPersonalRepresentative = 9012,
  SRU_Previous_Complainant = 9013,
  DWCMS_Applicant = 9501,
  DWCMS_ServiceProvider = 9502,
  DWCMS_EmploymentProvider = 9503,
  DWCMS_PreviousApplicant = 9504,
  DWCMS_RegistrationApplicant = 9505,
  TP_ApplicationPayer = 10001,
  PNF_Owners = 10002,
  PNF_Contractor = 10003,
  PNF_Logger = 10004,
  PNF_Sawmill = 10005,
  PNF_LGACouncil = 10006,
  PNF_Consultant = 10007,
  PNF_PropertyManager = 10008,
  PNF_HolderOfTimberRights = 10011,
  PNF_PowerOfAttorney = 10012,
  PNF_Other = 10013,
  PNF_Deceased = 10014,
  PNF_PriorOwner = 10015,
  LM_PrimaryOrganisation = 10016,
  LM_ProjectSponsor = 10017,
  LM_EventManager = 10018,
  LM_ProjectManager = 10019,
  LM_RegionalServicesOfficer = 10020,
  LM_ProjectMember = 10021,
  LM_LandManager = 10022,
  CEM_FuneralDirector = 10501,
  CEM_NextofKin = 10502,
  Infringement_Offender = 10051,
  FLEET_RequestedBy = 11001,
  FLEET_Debtor = 11002,
  FLEET_Organiser = 11003,
  FLEET_Trainer = 11004,
  FLEET_AdditionalContact = 11005,
  FLEET_PreviousRequestedBy = 11006,
  CSM_AuthorisedContact = 11051,
  CSM_Shareholder = 11052,
  CSM_Trustbeneficiary = 11053,
  CSM_Owner = 11054,
  CSM_AdditionalContact_Researcher = 11055,
  CSM_AdditionalContact_Dispensary_Contact = 11056,
}

export enum ContactRelationshipUpdateTriggers {
  UpdateContact,
  UpdateRelation,
  ConflictOverwriteChanges,
  UpdateGenericContact,
  UpdateBubble,
}

export enum ContactRelationExtension {
  SystemInitialise = 0,
  HasRole = 1,
  HasComment = 2,
}

export enum ContactMethodPreferred {
  None = 0,
  WorkPhone = 1,
  HomePhone = 2,
  Email = 3,
  Fax = 4,
  Mobile = 5,
  Post = 6,
  EmailAndPost = 7,
  TTY = 8,
}

export enum ContactMethodSet {
  Preferred = 0,
  Verbal = 1,
  Written = 2,
  VerbalAndWritten = 3,
}

export enum CredentialType {
  Unknown = 0,
  Exchange = 1,
}

export enum PortalUserRole {
  Guest = 0,
  InternalOfficer = 1,
  ExternalOfficer = 2,
  MemberOfPublic = 4,
  Admin = 32,
  CanImpersonate = 64,
  OpenOfficeOfficer = 128,
}

export enum AddressNominated {
  FirstAddress,
  SecondAddress,
  OtherAddress,
}

export enum SiteUserType {
  Default = 0,
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
}

export enum SiteUserExternalRefType {
  Default = 0,
  Staff_ID = 1,
  OO_ID = 2,
  AURION_Asset = 3,
  LoginName = 4,
  Email = 5,
}

export enum ExternalSystemReferance {
  Default = 0,
  ConfirmAssetSystem,
  RAMMAssetSystem = 2,
}

export enum AddressBookSection {
  Global = 0,
  Proprietor = 1,
  PortalProprietor = 2,
  Plumber = 3,
  Auditor = 4,
  Builders = 5,
  PrivateSurveyors = 6,
  ReferalAuthorities = 7,
  LocalGovernment = 8,
  TPB_Occupiers = 9,
  Engineers = 10,
  Architects = 11,
  Draftsman = 12,
  CSL_Global = 20,
  CRS_Parents = 61,
  CRS_Global = 62,
  Councillors = 71,
  MCH_PrimaryContact = 81,
  MCH_Global = 82,
  DisRes_Staff = 120,
  TrainingManager_CourseProvider = 121,
  NSPR_Global = 130,
  PoliceOfficers = 131,
  Installers = 132,
  VCATBoardRepresentative = 133,
  FuneralDirector = 134,
}

export enum Contact_AddressBookFlag {
  GenericContact = 0,
  GenericCopy = 1,
  AddressBookCopy = 2,
  AddressBookReference = 3,
}

export enum UI_ContactLink_Classification {
  Restricted,
  Filtered,
  Generic,
}

export enum UI_ContactRelationship_Mode {
  DisplayRelationship,
  DisplayHistorical,
  RelationshipEdit_StatutoryContact,
  RelationshipEdit_AdditionalContact,
}

export enum UI_ContactRelationship_Workflow {
  Lookahead,
  SelectContact_Copy,
  CreateContact,
  SelectContact_Link,
  AdditionalContactRelationship,
  ExistingContact,
  ExistingContact_ViewREL_ViewCON,
  ExistingContact_EditREL_ViewCON,
  ExistingContact_ViewREL_EditCON,
  ExistingContact_EditREL_EditCON,
}

export enum UI_Contact_Workflow {
  NewContact,
  ExistingContact,
}

export enum AddressClassification {
  Generic = 0,
  LGA = 1,
  International = 2,
  POBox = 3,
  HM_Contact = 4,
  Premises = 5,
  DescriptiveAddress = 6,
  Property = 7,
  DFSV_Area_Generic = 100,
  DFSV_Area_POBox = 101,
  Line_Address = 102,
  Consultant_Address = 103,
  Scheme = 104,
  Lots = 105,
}

export enum DeadlineUnit {
  Days = 0,
  Hours = 1,
  Weeks = 2,
}

export enum DefaultAffectSchedule {
  Yes = 0,
  No = 1,
  Blank = 2,
  YesLocked = 3,
  NoLocked = 4,
}

export enum MessageType {
  Success = 0,
  Warning = 1,
  Information = 2,
  Error = 3,
  FlashInformation = 4,
}

export enum JournalPOI {
  SystemInitialise = 0,
  ContactNameChange = 1500,
  ContactAddressChange = 1501,
  ContactPhoneNumberChanged = 1502,
  ContactVerificationChanged = 1503,
  ContactJobDetailsChanged = 1504,
  ObjectiveFolderWebLinkAdded = 1505,
  CRS_HBCCentreReferred = 1550,
  Core_FC = 1600,
  Core_LFC = 1601,
  Core_FCAddress = 1602,
  Core_StatClockStarted = 1603,
  Core_StatClockStopped = 1604,
  Core_EmailAdded = 1605,
  Core_CommentAdded = 1606,
  Core_ActionAdded = 1607,
  Core_DocumentAdded = 1608,
  Core_WebLinkAdded = 1609,
  Core_CommunicationSent = 1610,
  Core_CommunicationFailed = 1611,
  Core_ActionCreated = 1612,
  Core_ActionModified = 1613,
  Streatrader_RecordAccessed = 1900,
  Streatrader_NonParticipatingEdit = 1901,
  Streatrader_CouncilAdminOverride = 1902,
  CustomerService_EmailAdded = 3001,
  CustomerService_CommentAdded = 3002,
  CustomerService_ActionAdded = 3003,
  CustomerService_DocumentAdded = 3004,
  CustomerService_EventClosed = 3005,
  CustomerService_EventScheduled = 3006,
  CustomerService_EventReopened = 3007,
  CustomerService_WebLinkAdded = 3008,
  CustomerService_Categorised = 3009,
  CustomerService_NewRequest = 3010,
  CustomerService_LocationAddressModified = 3011,
  CustomerService_ReferredToActionOfficer = 3012,
  CustomerService_ReferredToCoordinator = 3013,
  CustomerService_EmailToSpecialInterest = 3014,
  CustomerService_RequestorReceipt = 3015,
  CustomerService_RequestorFinalNotification = 3016,
  CustomerService_RequestorRescheduledNotification = 3017,
  CustomerService_ReferredToRiskIssueOfficer = 3018,
  CustomerService_SafetyReason = 3019,
  CustomerService_ReferredToRecordsOfficer = 3020,
  CustomerService_EventPrinted = 3021,
  CustomerService_KB_SentForApproval = 3022,
  CustomerService_NotifiedAfterHoursEmail = 3023,
  CustomerService_NotifiedCouncillor = 3024,
  CustomerService_HardRubbish_AdminOverride = 3025,
  CustomerService_SafetyRemovedReason = 3026,
  CustomerService_Reassigned = 3027,
  CustomerService_NotifyExternalRequetor = 3028,
  CustomerService_NotifyOnBehalfOf = 3029,
  CustomerService_EmailToResponsibleOfficer = 3030,
  CustomerService_SMSRequestorOnClosure = 3031,
  TrainingManager_IssueForApproval = 4001,
  TrainingManager_ApprovedByApprover1 = 4002,
  TrainingManager_DeclinedByApprover1_NotifiedRequestor = 4003,
  TrainingManager_DeclinedByApprover1_NotifiedAdministrator = 4004,
  TrainingManager_ApprovedByApprover2 = 4005,
  TrainingManager_DeclinedByApprover2_NotifiedRequestor = 4006,
  TrainingManager_DeclinedByApprover2_NotifiedAdministrator = 4007,
  TrainingManager_AllocationConfirmed_NotifiedRequestor = 4008,
  TrainingManager_AllocationConfirmed_NotifiedAdministrator = 4009,
  TrainingManager_AllocationConfirmed_NotifiedOrgManager = 4010,
  TrainingManager_AllocationDeclined_NotifiedRequestor = 4011,
  TrainingManager_AllocationConfirmationRequired_NotifiedRequestor = 4012,
  TrainingManager_SubmitRequest = 4100,
  Portal_LinkedRecordsChanged = 5001,
  TP_Portal_Download = 5002,
  TP_Portal_SentEmail = 5003,
  TP_ePlanningDocFI = 6001,
  TP_ePlanningObjections = 6002,
  TP_ePlanningApplication = 6003,
  TP_ePlanningSC = 6004,
  TP_ePlanningEOT = 6005,
  TP_ePlanningAmendment = 6006,
  TP_ePlanningPlansToComply = 6007,
  TP_ePlanningCertification = 6008,
  TP_ePlanningRevision = 6009,
  TP_NSW_ePlanningLodgement = 6010,
  HM_AdministrationFeeCreated = 7001,
  HM_PortalUpdatedPremises = 7002,
  HM_PortalRenewedPremises = 7003,
  HM_PortalFlaggedReview = 7004,
  HM_PortalRecordedPayment = 7005,
  SRU_Application_NewRegistration_ReviewerSubmit_NotifiedOfficer = 8001,
  SRU_Application_NewRegistration_OfficerRecommendForApproval_NotifiedManager = 8002,
  SRU_Application_NewRegistration_OfficerRecommendForRefusal_NotifiedManager = 8003,
  SRU_Application_NewRegistration_ManagerEndorseApproval_NotifiedDelegate = 8004,
  SRU_Application_NewRegistration_ManagerEndorseRefusal_NotifiedDelegate = 8005,
  SRU_Application_NewRegistration_ManagerSendBack_NotifiedOfficer = 8006,
  SRU_Application_NewRegistration_DelegateSendBack_NotifiedOfficer = 8007,
  SRU_Application_NewRegistration_OfficerSentBackRecommendForApproval_NotifiedManager = 8008,
  SRU_Application_NewRegistration_OfficerSentBackRecommendForRefusal_NotifiedManager = 8009,
  SRU_Application_NewRegistration_DelegateProposeRefusal_NotifiedOfficer = 8010,
  SRU_Application_NewRegistration_DelegateProposeRefusal_NotifiedApplicant = 8011,
  SRU_Application_NewRegistration_ApplicantResponded_NotifiedPanelMember = 8012,
  SRU_Application_NewRegistration_PanelRecommendForApproval_NotifiedDelegate = 8013,
  SRU_Application_NewRegistration_PanelRecommendForRefusal_NotifiedDelegate = 8014,
  SRU_Application_NewRegistration_DelegateApproved_NotifiedOfficer = 8015,
  SRU_Application_NewRegistration_DelegateRefused_NotifiedOfficer = 8016,
  SRU_Application_NewRegistration_DelegateApproved_NotifiedApplicant = 8017,
  SRU_Application_NewRegistration_DelegateRefused_NotifiedApplicant = 8018,
  SRU_Application_NewRegistration_Cancelled_NotifiedOfficer = 8019,
  SRU_Application_ChangeOfDetails_ReviewerSubmit_NotifiedOfficer = 8020,
  SRU_Application_ChangeOfDetails_OfficerApproved_NotifiedApplicant = 8021,
  SRU_Application_ChangeOfDetails_OfficerRecommendForApproval_NotifiedManager = 8022,
  SRU_Application_ChangeOfDetails_ManagerEndorseForApproval_NotifiedDelegate = 8023,
  SRU_Application_ChangeOfDetails_ManagerSendBack_NotifiedOfficer = 8024,
  SRU_Application_ChangeOfDetails_DelegateSendBack_NotifiedOfficer = 8025,
  SRU_Application_ChangeOfDetails_OfficerSentBackRecommendForApproval_NotifiedManager = 8026,
  SRU_Application_ChangeOfDetails_DelegateApproved_NotifiedOfficer = 8027,
  SRU_Application_ChangeOfDetails_DelegateApproved_NotifiedApplicant = 8028,
  SRU_Application_ChangeOfDetails_OfficerCancelled_NotifiedApplicant = 8029,
  SRU_Issue_InitialNotification_ToOrganisation = 8033,
  SRU_Issue_OfficerRecommendForApproval_NotifiedManager = 8034,
  SRU_Issue_OfficerRecommendForRefusal_NotifiedManager = 8035,
  SRU_Issue_ManagerSendBack_NotifiedOfficer = 8036,
  SRU_Issue_Renotification_ToOrganisation = 8037,
  SRU_Issue_ManagerEndorseApproval_NotifiedOfficer = 8038,
  SRU_Issue_ManagerEndorseApproval_NotifiedDelegate = 8039,
  SRU_Issue_DelegateApproved_NotifiedOfficer = 8040,
  SRU_Issue_DelegateSendBack_NotifiedOfficer = 8041,
  SRU_Issue_CompletedNotification_ToOrganisation = 8042,
  SRU_Issue_OfficerSentBackRecommendForApproval_NotifyManager = 8043,
  SRU_Organisation_NotificationOfPlannedDate_ToFundedDivisions = 8044,
  SRU_Issue_ReviewerSubmitIssue_NotifyOfficer = 8045,
  SRU_Application_NewCondition_OfficerRecommendForApproval_NotifiedManager = 8050,
  SRU_Application_NewCondition_ManagerEndorseApproval_NotifiedDelegate = 8051,
  SRU_Application_NewCondition_ManagerSendBack_NotifiedOfficer = 8052,
  SRU_Application_NewCondition_DelegateSendBack_NotifiedOfficer = 8053,
  SRU_Application_NewCondition_OfficerSentBackRecommendForApproval_NotifiedManager = 8054,
  SRU_Application_NewCondition_DelegateProposeApproval_NotifiedOfficer = 8055,
  SRU_Application_NewCondition_DelegateProposeApproval_NotifiedOrganisation = 8056,
  SRU_Application_NewCondition_OrganisationResponseNotReceived_NotifiedDelegate = 8057,
  SRU_Application_NewCondition_OrganisationResponseSatisfactory_NotifiedDelegate = 8058,
  SRU_Application_NewCondition_OrganisationResponseUnsatisfactory_NotifiedDelegate = 8059,
  SRU_Application_NewCondition_DelegateApproved_NotifiedOfficer = 8060,
  SRU_Application_NewCondition_DelegateCancelled_NotifiedOfficer = 8061,
  SRU_Application_NewCondition_DelegateApproved_NotifiedOrganisation = 8062,
  SRU_Application_NewCondition_DelegateRefused_NotifiedOrganisation = 8063,
  SRU_Application_NewCondition_Cancelled_NotifiedOfficer = 8064,
  SRU_Application_RegistrationRevocation_OfficerRecommendForApproval_NotifiedManager = 8070,
  SRU_Application_RegistrationRevocation_ManagerEndorseApproval_NotifiedDelegate = 8071,
  SRU_Application_RegistrationRevocation_ManagerSendBack_NotifiedOfficer = 8072,
  SRU_Application_RegistrationRevocation_DelegateSendBack_NotifiedOfficer = 8073,
  SRU_Application_RegistrationRevocation_OfficerSentBackRecommendForApproval_NotifiedManager = 8074,
  SRU_Application_RegistrationRevocation_DelegateProposeApproval_NotifiedOfficer = 8075,
  SRU_Application_RegistrationRevocation_DelegateProposeApproval_NotifiedOrganisation = 8076,
  SRU_Application_RegistrationRevocation_OrganisationResponseNotReceived_NotifiedDelegate = 8077,
  SRU_Application_RegistrationRevocation_OrganisationResponseSatisfactory_NotifiedDelegate = 8078,
  SRU_Application_RegistrationRevocation_OrganisationResponseUnsatisfactory_NotifiedDelegate = 8079,
  SRU_Application_RegistrationRevocation_DelegateApproved_NotifiedOfficer = 8080,
  SRU_Application_RegistrationRevocation_DelegateCancelled_NotifiedOfficer = 8081,
  SRU_Application_RegistrationRevocation_DelegateApproved_NotifiedOrganisation = 8082,
  SRU_Application_RegistrationRevocation_DelegateRefused_NotifiedOrganisation = 8083,
  SRU_Application_RegistrationRevocation_Cancelled_NotifiedOfficer = 8084,
  SRU_Organisation_CertificationSuspended_NotifyDelegate = 8091,
  SRU_Application_EnforcementPlan_OfficerRecommendForApproval_NotifiedManager = 8092,
  SRU_Application_EnforcementPlan_ManagerEndorseApproval_NotifiedDelegate = 8093,
  SRU_Application_EnforcementPlan_ManagerSendBack_NotifiedOfficer = 8094,
  SRU_Application_EnforcementPlan_DelegateSendBack_NotifiedOfficer = 8095,
  SRU_Application_EnforcementPlan_OfficerSentBackRecommendForApproval_NotifiedManager = 8096,
  SRU_Application_EnforcementPlan_DelegateProposeApproval_NotifiedOfficer = 8097,
  SRU_Application_EnforcementPlan_DelegateProposeApproval_NotifiedOrganisation = 8098,
  SRU_Application_EnforcementPlan_OrganisationResponseNotReceived_NotifiedDelegate = 8099,
  SRU_Application_EnforcementPlan_OrganisationResponseSatisfactory_NotifiedDelegate = 8100,
  SRU_Application_EnforcementPlan_OrganisationResponseUnsatisfactory_NotifiedDelegate = 8101,
  SRU_Application_EnforcementPlan_DelegateApproved_NotifiedOfficer = 8102,
  SRU_Application_EnforcementPlan_DelegateCancelled_NotifiedOfficer = 8103,
  SRU_Application_EnforcementPlan_DelegateApproved_NotifiedOrganisation = 8104,
  SRU_Application_EnforcementPlan_DelegateRefused_NotifiedOrganisation = 8105,
  SRU_Application_EnforcementPlan_Cancelled_NotifiedOfficer = 8106,
  SRU_Application_ChangeOfDetails_SRS_ReviewerSubmit_NotifiedOfficer = 8107,
  SRU_Application_ChangeOfDetails_SRS_OfficerApproved_NotifiedApplicant = 8108,
  SRU_Application_ChangeOfDetails_SRS_OfficerRecommendForApproval_NotifiedManager = 8109,
  SRU_Application_ChangeOfDetails_SRS_ManagerEndorseForApproval_NotifiedDelegate = 8110,
  SRU_Application_ChangeOfDetails_SRS_ManagerSendBack_NotifiedOfficer = 8111,
  SRU_Application_ChangeOfDetails_SRS_DelegateSendBack_NotifiedOfficer = 8112,
  SRU_Application_ChangeOfDetails_SRS_OfficerSentBackRecommendForApproval_NotifiedManager = 8113,
  SRU_Application_ChangeOfDetails_SRS_DelegateApproved_NotifiedOfficer = 8114,
  SRU_Application_ChangeOfDetails_SRS_DelegateApproved_NotifiedApplicant = 8115,
  SRU_Application_ChangeOfDetails_SRS_OfficerCancelled_NotifiedApplicant = 8116,
  SRU_Application_ChangeOfDetails_SRS_DelegateRefused_NotifiedOfficer = 8117,
  SRU_Application_ChangeOfDetails_SRS_DelegateRefused_NotifiedApplicant = 8118,
  SRU_Application_ChangeOfDetails_SRS_ManagerRefused_NotifiedOfficer = 8119,
  SRU_Application_ChangeOfDetails_SRS_ManagerRefused_NotifiedApplicant = 8120,
  SRU_Application_ReportableIncident_OfficerRecommendForApproval_NotifiedManager = 8130,
  SRU_Application_ReportableIncident_ManagerEndorseForApproval_NotifiedDelegate = 8131,
  SRU_Application_ReportableIncident_ManagerSendBack_NotifiedOfficer = 8132,
  SRU_Application_ReportableIncident_DelegateSendBack_NotifiedOfficer = 8133,
  SRU_Application_ReportableIncident_OfficerSentBackRecommendForApproval_NotifiedManager = 8134,
  SRU_Application_ReportableIncident_DelegateApproved_NotifiedOfficer = 8135,
  DWCMS_Application_NewRegistration_DueDateExtended = 8501,
  DWCMS_Application_NewRegistration_Withdrawn = 8502,
  DWCMS_Application_NewRegistration_FIReceived = 8503,
  DWCMS_Application_NewRegistration_FIRequested = 8504,
  DWCMS_Application_PublishedToWeb = 8505,
  DWCMS_Application_NewRegistration_DueDateNoChange = 8506,
  DWCMS_Application_Withdrawn_Rollback = 8507,
  DWCMS__Application_NewRegistration_RegistrationIndicatorChange = 8508,
}

export enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

export enum DeadlineTypesOfInterest {
  SystemInitialise = 0,
  ASAP = 230,
  BySpecifiedDate = 231,
  OnSpecifiedDate = 232,
  Default = 233,
}

export enum ReportSpecification {
  SystemInitialise = 0,
  CORE = 1,
  PRODUCT = 2,
}

export enum DefaultTab {
  Unspecified = 0,
  Actions = 1,
  Inspections = 2,
  Contacts = 3,
  Documents = 4,
  Comments = 5,
  Fees = 6,
  Related = 7,
}

export enum DefaultTab_Events {
  Unspecified = 0,
  Actions = 1,
  Inspections = 2,
  Contacts = 3,
  Documents = 4,
  Comments = 5,
  Fees = 6,
  Related = 7,
  EMSRepeatBookings = 8,
  EMSEventFacilities = 9,
  EMSEstimates = 10,
  FLEETRepeatBookings = 11,
  FLEETEventVehicles = 12,
  FLEETEstimates = 13,
}

export enum DefaultTab_TPCertification {
  Unspecified = 0,
  Actions = 1,
  Inspections = 2,
  Contacts = 3,
  Documents = 4,
  Comments = 5,
  Fees = 6,
  Related = 7,
  Conditions = 8,
}

export enum MerchantPaymentMethod {
  SystemInitialise = 0,
  CreditCardPayment = 1,
  DirectPayment = 2,
  BPAY = 3,
}

export enum FieldOption {
  Hidden = 0,
  Optional = 1,
  Mandatory = 2,
  DoNotDisplay = 3,
  DisplayOnly = 4,
}

export enum CreditCardType {
  SystemInitialise = 0,
  JCB = 1 << 0,
  Amex = 1 << 1,
  DinersClub = 1 << 2,
  Bankcard = 1 << 3,
  MasterCard = 1 << 4,
  Visa = 1 << 5,
  PayPal = 1 << 6,
}

export enum CheckDigitType {
  None = 0,
  Mod10 = 1,
  Mod10V5 = 2,
  Mod10V10 = 3,
  Mod97 = 4,
  ISBN10 = 5,
  ISBN13 = 6,
  EAN = 7,
  SEDOL = 8,
  IMO = 9,
  ISIN = 10,
  GTIN = 11,
  ISTC = 12,
  LRC = 13,
  Pathway = 14,
  SumOfDigits = 15,
  Mod97Weighted1 = 16,
}

export enum Trace_ENUM {
  Event = 1,
  Page = 2,
  IsPostBack = 3,
  IsAsync = 4,
  ExecuteDateTime = 5,
  ContentLength = 6,
  ThreadID = 7,
  HTTPRequestID = 8,
  AuthorUsername = 9,
  StartTime_EndTime_Diff = 10,
}

export enum StatClockStatus {
  NeverUsed = 0,
  ClockStarted = 1,
  ClockStopped = 2,
}

export enum ObjectType {
  NSPRPrevAddress = 1,
  NSPRPrevRegistration = 2,
  HMActivityReport = 3,
}

export enum FrequencyType {
  Days = 1,
  Months = 2,
}

export enum FURTHERINFOSOURCE {
  Planning = 1,
  Building = 2,
  LM_Register = 3,
}

export enum ServiceType {
  Default = 0,
  CORE_FakeService = 1,
  CM_ChapelHire = 101,
  CM_Cremation = 102,
  CM_Burial = 103,
  CM_Exhumation = 104,
  CM_Monument = 105,
  CM_LicenceBurial = 106,
  CM_LicenceCremationMemorial = 107,
  CM_MemorialRecovery = 108,
  CM_Administration = 109,
  CM_FAYS = 110,
  CM_Courier = 111,
  CRS_TermFeeWyndham = 201,
  CRS_TermFeeKnox = 202,
  CRS_TermFeeCasey = 203,
  LAMP_ApplicationFee = 301,
  LAMP_AssessmentFee = 302,
  LAMP_AdvertisingFee = 303,
  LAMP_FMFFee = 304,
  LAMP_EMFFee = 305,
  LAMP_REFFee = 306,
  LAMP_WaterReuseFee = 307,
  LAMP_WasteLevyFee = 308,
  LAMP_OtherFee = 309,
  LAMP_TransferFee = 310,
  LAMP_RenewalFee = 311,
  LAMP_AuthorisationFee = 312,
  LAMP_AnnualAuthorisationFee = 313,
  LAMP_EarthworkRegisterFee = 314,
  LAMP_DredgingRegisterFee = 315,
  LAMP_LateLodgementFee = 316,
  LAMP_LatePaymentFee = 317,
  LAMP_AdhocFee = 318,
}

export enum ServiceFee {
  Default = 0,
  CORE_FakeFee = 1,
  CM_ChapelHire_MawsonChapel = 101,
  CM_ChapelHire_FloreyChapel = 102,
  CM_ChapelHire_HeysenChapel = 103,
  CM_ChapelHire_BurialLounge = 104,
  CM_ChapelHire_PremiumLargeService = 105,
  CM_ChapelHire_SaturdaySurcharge = 106,
  CM_ChapelHire_SundaySurcharge = 107,
  CM_ChapelHire_NoBurialCremationSurcharge = 108,
  CM_ChapelHire_OverallocatedTime = 109,
  CM_Cremation_Adult = 201,
  CM_Cremation_ChildUnder12 = 202,
  CM_Cremation_InfantUnder1 = 203,
  CM_Cremation_RetainedOrgans = 204,
  CM_Cremation_WitnessOfCharging = 205,
  CM_Cremation_WitnessOfChargingNoChapelService = 206,
  CM_Cremation_SaturdaySurcharge = 207,
  CM_Cremation_SundaySurcharge = 208,
  CM_Cremation_TurnaroundTime4Hours = 209,
  CM_Cremation_TurnaroundTime24Hours = 210,
  CM_Cremation_GranulateOutsideSourceCremRem = 211,
  CM_Cremation_CrematedRemainsHandling = 212,
  CM_Cremation_CrematedRemainsIntoGrave = 213,
  CM_Cremation_CrematedRemainsIntoVault = 214,
  CM_Cremation_CrematedRemainsIntoMemorial = 215,
  CM_Cremation_OutsideSourceToUnmarkedLocation = 216,
  CM_Cremation_TransferCremRemFromExistingPosition = 217,
  CM_Cremation_WitnessOfInterment = 218,
  CM_Cremation_ChairsForWitnessOfInterment = 219,
  CM_Cremation_CrematedRemainsIntoWall = 220,
  CM_Burial_StandardSingleDepth = 301,
  CM_Burial_StandardDoubleDepth = 302,
  CM_Burial_StandardTripleDepth = 303,
  CM_Burial_StandardChildUnder12 = 304,
  CM_Burial_Infant = 305,
  CM_Burial_LargeSingleDepth = 306,
  CM_Burial_LargeDoubleDepth = 307,
  CM_Burial_LargeTripleDepth = 308,
  CM_Burial_Perinatal = 309,
  CM_Burial_PerinatalSecondTwin = 310,
  CM_Burial_VaultOpening = 311,
  CM_Burial_LiftAndDeepen = 312,
  CM_Burial_OssuaryBox = 313,
  CM_Burial_ReturnToSite = 314,
  CM_Burial_SaturdaySurcharge = 315,
  CM_Burial_SundaySurcharge = 316,
  CM_Burial_Canopy = 317,
  CM_Burial_ChairsForEachAfter12 = 318,
  CM_Burial_HandFilledGrave = 319,
  CM_Burial_SandFilledGrave = 320,
  CM_Burial_PortableSound = 321,
  CM_Burial_FirstIntermentIntoVault = 322,
  CM_Burial_InfantSecondTwin = 323,
  CM_Burial_CrematedRemainsIntoCoffin = 324,
  CM_Exhumation_FirstSet = 401,
  CM_Exhumation_SecondSet = 402,
  CM_Exhumation_ThirdSet = 403,
  CM_Monument_PlaqueOnHeadstone = 501,
  CM_Monument_AlterationsToMonumentHeadstone = 502,
  CM_Monument_AlterationsToMonumentPlaque = 503,
  CM_Monument_ChildrensMonument = 504,
  CM_Monument_SingleMonument = 505,
  CM_Monument_DoubleMonument = 506,
  CM_Monument_TripleMonument = 507,
  CM_Monument_QuadrupleMonument = 508,
  CM_BurialLicence_SiteSelectionCharge = 601,
  CM_BurialLicence_SingleVault = 602,
  CM_BurialLicence_DoubleVault = 603,
  CM_MemorialRecovery_RecoveryOfPlaque = 701,
  CM_MemorialRecovery_RecoveryOfCremRemFromWall = 702,
  CM_MemorialRecovery_RecoveryOfCremRemFromGrave = 703,
  CM_MemorialRecovery_RemoveHeadstone = 704,
  CM_Administration_AdminFee = 901,
  CM_Administration_PhotographOfSite = 902,
  CM_Administration_SearchFee = 903,
  CM_FAYS_CremationAdult = 1001,
  CM_FAYS_ChapelMawson = 1002,
  CM_FAYS_ChapelFlorey = 1003,
  CM_FAYS_ChapelHeysen = 1004,
  CM_FAYS_LicenceCremationMemorial = 1005,
  CM_FAYS_MonumentBronzePlaque = 1006,
  CM_FAYS_AdminFee = 1007,
  CM_FAYS_BurialSecondDepth = 1008,
  CM_FAYS_LicenceBurial = 1009,
  CM_FAYS_BurialSecondIntermentAtLevel1 = 1010,
  CM_Courier_Zone1 = 1101,
  CM_Courier_Zone2 = 1102,
  CM_Courier_Zone3 = 1103,
  CM_Courier_Zone4 = 1104,
  CM_Courier_Zone5 = 1105,
  CRS_TermFeeW_NormalTerm1075 = 2001,
  CRS_TermFeeW_SiblingTerm1075 = 2002,
  CRS_TermFeeW_ParentHCHTerm1075 = 2003,
  CRS_TermFeeW_ChildCCHTerm1075 = 2004,
  CRS_TermFeeW_NormalTerm12 = 2005,
  CRS_TermFeeW_SiblingTerm12 = 2006,
  CRS_TermFeeW_ParentHCHTerm12 = 2007,
  CRS_TermFeeW_ChildCCHTerm12 = 2008,
  CRS_TermFeeW_NormalTerm15 = 2009,
  CRS_TermFeeW_SiblingTerm15 = 2010,
  CRS_TermFeeW_ParentHCHTerm15 = 2011,
  CRS_TermFeeW_ChildCCHTerm15 = 2012,
  CRS_TermFeeW_LateCollectionFee = 2013,
  CRS_TermFeeW_SiblingSCHTerm15 = 2014,
  CRS_TermFeeW_SiblingSCHTerm12 = 2015,
  CRS_TermFeeW_SiblingSCHTerm1075 = 2016,
  CRS_TermFeeKnox_FullFee = 2017,
  CRS_TermFeeKnox_SiblingFee = 2018,
  CRS_TermFeeKnox_ParentSubsidyCardFee = 2019,
  CRS_TermFeeKnox_ChildSubsidyCardFee = 2020,
  CRS_TermFeeKnox_SiblingSubsidyCardFee = 2021,
  CRS_TermFeeKnox_BothChildSubsidyCardFee = 2022,
  CRS_TermFeeKnox_ConfirmPlaceFullFee = 2023,
  CRS_TermFeeKnox_ConfirmPlaceSiblingFee = 2024,
  CRS_TermFeeKnox_ConfirmPlaceParentSubsidyCardFee = 2025,
  CRS_TermFeeKnox_ConfirmPlaceChildSubsidyCardFee = 2026,
  CRS_TermFeeKnox_ConfirmPlaceSiblingSubsidyCardFee = 2027,
  CRS_TermFeeKnox_ConfirmPlaceBothChildSubsidyCardFee = 2028,
  CRS_TermFeeKnox_WorkingBeeLevyFullFee = 2029,
  CRS_TermFeeKnox_WorkingBeeLevySiblingFee = 2030,
  CRS_TermFeeKnox_WorkingBeeLevyParentSubsidyCardFee = 2031,
  CRS_TermFeeKnox_WorkingBeeLevyChildSubsidyCardFee = 2032,
  CRS_TermFeeKnox_WorkingBeeLevySiblingSubsidyCardFee = 2033,
  CRS_TermFeeKnox_WorkingBeeLevyBothChildSubsidyCardFee = 2034,
  CRS_TermFeeCasey_FullFee = 2035,
  CRS_TermFeeCasey_SiblingFee = 2036,
  CRS_TermFeeCasey_ParentSubsidyCardFee = 2037,
  CRS_TermFeeCasey_ChildSubsidyCardFee = 2038,
  CRS_TermFeeCasey_SiblingSubsidyCardFee = 2039,
  CRS_TermFeeCasey_BothChildSubsidyCardFee = 2040,
  CRS_TermFeeCasey_TwinDiscountFullFee = 2041,
  CRS_TermFeeCasey_TwinDiscountSiblingFee = 2042,
  CRS_TermFeeCasey_TwinDiscountParentSubsidyCardFee = 2043,
  CRS_TermFeeCasey_TwinDiscountChildSubsidyCardFee = 2044,
  CRS_TermFeeCasey_TwinDiscountSiblingSubsidyCardFee = 2045,
  CRS_TermFeeCasey_TwinDiscountBothChildSubsidyCardFee = 2046,
  CRS_TermFeeW_NormalTerm3yo3Hr = 2047,
  CRS_TermFeeW_NormalTerm3yo4Hr = 2048,
  CRS_TermFeeW_NormalTerm3yo5Hr = 2049,
  CRS_TermFeeW_NormalTerm3yo6Hr = 2050,
  CRS_TermFeeCasey_FullFee_3yo = 2051,
  CRS_TermFeeCasey_SiblingFee_3yo = 2052,
  CRS_TermFeeCasey_ParentSubsidyCardFee_3yo = 2053,
  CRS_TermFeeCasey_ChildSubsidyCardFee_3yo = 2054,
  CRS_TermFeeCasey_SiblingSubsidyCardFee_3yo = 2055,
  CRS_TermFeeCasey_BothChildSubsidyCardFee_3yo = 2056,
  CRS_TermFeeCasey_TwinDiscountFullFee_3yo = 2057,
  CRS_TermFeeCasey_TwinDiscountSiblingFee_3yo = 2058,
  CRS_TermFeeCasey_TwinDiscountParentSubsidyCardFee_3yo = 2059,
  CRS_TermFeeCasey_TwinDiscountChildSubsidyCardFee_3yo = 2060,
  CRS_TermFeeCasey_TwinDiscountSiblingSubsidyCardFee_3yo = 2061,
  CRS_TermFeeCasey_TwinDiscountBothChildSubsidyCardFee_3yo = 2062,
  CRS_TermFeeKnox_FullFee_3yo = 2063,
  CRS_TermFeeKnox_SiblingFee_3yo = 2064,
  CRS_TermFeeKnox_ParentSubsidyCardFee_3yo = 2065,
  CRS_TermFeeKnox_ChildSubsidyCardFee_3yo = 2066,
  CRS_TermFeeKnox_SiblingSubsidyCardFee_3yo = 2067,
  CRS_TermFeeKnox_BothChildSubsidyCardFee_3yo = 2068,
  CRS_TermFeeKnox_ConfirmPlaceFullFee_3yo = 2069,
  CRS_TermFeeKnox_ConfirmPlaceSiblingFee_3yo = 2070,
  CRS_TermFeeKnox_ConfirmPlaceParentSubsidyCardFee_3yo = 2071,
  CRS_TermFeeKnox_ConfirmPlaceChildSubsidyCardFee_3yo = 2072,
  CRS_TermFeeKnox_ConfirmPlaceSiblingSubsidyCardFee_3yo = 2073,
  CRS_TermFeeKnox_ConfirmPlaceBothChildSubsidyCardFee_3yo = 2074,
  CRS_TermFeeKnox_WorkingBeeLevyFullFee_3yo = 2075,
  CRS_TermFeeKnox_WorkingBeeLevySiblingFee_3yo = 2076,
  CRS_TermFeeKnox_WorkingBeeLevyParentSubsidyCardFee_3yo = 2077,
  CRS_TermFeeKnox_WorkingBeeLevyChildSubsidyCardFee_3yo = 2078,
  CRS_TermFeeKnox_WorkingBeeLevySiblingSubsidyCardFee_3yo = 2079,
  CRS_TermFeeKnox_WorkingBeeLevyBothChildSubsidyCardFee_3yo = 2080,
  CRS_TermFeeW_SiblingTerm3yo3Hr = 2081,
  CRS_TermFeeW_SiblingTerm3yo4Hr = 2082,
  CRS_TermFeeW_SiblingTerm3yo5Hr = 2083,
  CRS_TermFeeW_SiblingTerm3yo6Hr = 2084,
  CRS_TermFeeW_ParentHCHTerm3yo3Hr = 2085,
  CRS_TermFeeW_ParentHCHTerm3yo4Hr = 2086,
  CRS_TermFeeW_ParentHCHTerm3yo5Hr = 2087,
  CRS_TermFeeW_ParentHCHTerm3yo6Hr = 2088,
  CRS_TermFeeW_ChildCCHTerm3yo3Hr = 2089,
  CRS_TermFeeW_ChildCCHTerm3yo4Hr = 2090,
  CRS_TermFeeW_ChildCCHTerm3yo5Hr = 2091,
  CRS_TermFeeW_ChildCCHTerm3yo6Hr = 2092,
  CRS_TermFeeW_SiblingSCHTerm3yo3Hr = 2093,
  CRS_TermFeeW_SiblingSCHTerm3yo4Hr = 2094,
  CRS_TermFeeW_SiblingSCHTerm3yo5Hr = 2095,
  CRS_TermFeeW_SiblingSCHTerm3yo6Hr = 2096,
  CRS_TermFeeW_LateCollectionFee3yo = 2097,
  CRS_TermFeeW_MultiBirth1stChild = 2098,
  CRS_TermFeeW_MultiBirth2ndChild = 2099,
  LAMP_ApplicationFee = 2101,
  LAMP_AuthorisationFee = 2102,
  LAMP_PublicNotificationFee = 2103,
  LAMP_FMFFee = 2104,
  LAMP_EMFFee = 2105,
  LAMP_REFFee = 2106,
  LAMP_WaterReuseFee = 2107,
  LAMP_OtherFee = 2109,
  LAMP_TransferFee = 2110,
  LAMP_RenewalFee = 2111,
  LAMP_AssessmentFee_EMF = 2112,
  LAMP_AssessmentFee_Dredging = 2113,
  LAMP_AssessmentFee_EarthWorks = 2114,
  LAMP_AssessmentFee_WasteTranportCatA = 2115,
  LAMP_AssessmentFee_WasteTranportCatB = 2116,
  LAMP_AuthorisationFee_WasteTranport = 2123,
  LAMP_ApplicationFee_WorksApproval = 2124,
  LAMP_AuthorisationFee_WorksApproval = 2125,
  LAMP_ExemptionFee = 2209,
  LAMP_EmergencyFee = 2210,
  LAMP_WastePopulation = 2220,
  LAMP_WastePopulation_SolidCountryLevy = 2221,
  LAMP_WastePopulation_SolidCountry_5percent = 2222,
  LAMP_WastePopulation_SolidCountry_ZeroWaste = 2223,
  LAMP_WasteReturn = 2224,
  LAMP_WasteReturn_LiquidContribution = 2225,
  LAMP_WasteReturn_Liquid_5percent = 2226,
  LAMP_WasteReturn_SolidMetroContribution = 2227,
  LAMP_WasteReturn_SolidMetro_5percent = 2228,
  LAMP_WasteReturn_SolidMetro_ZeroWaste = 2229,
  LAMP_WasteReturn_SolidCountryLevy = 2230,
  LAMP_WasteReturn_SolidCountry_5percent = 2231,
  LAMP_WasteReturn_SolidCountry_ZeroWaste = 2232,
  LAMP_WasteLodgementPenalty = 2233,
  LAMP_EnvironmentalAuthorisations = 2235,
  LAMP_LateLodgementFee = 2240,
  LAMP_LatePaymentFee = 2241,
  LAMP_VolSurvey = 2250,
  LAMP_AnnualAuthorisationFee = 2251,
  LAMP_AdhocFee = 2255,
}

export enum FeeRule {
  Simple = 0,
  Range = 1,
  Exact = 2,
  AdHoc = 3,
}

export enum UPDATEFINANCESTAGINGSTATUS {
  Check = 1,
  Update = 2,
  Cancel = 3,
}

export enum FINANCEEXPORTOVERRIDEREASON {
  PENDING = 1,
}

export enum UPDATEFINANCESTAGINGRESULTSTATUS {
  SUCCESS = 1,
  FAILURE = 2,
}

export enum COLOURTYPE {
  Red = 1,
  Yellow = 2,
  Green = 3,
}

export enum DIRECTION {
  In = 1,
  Out = 2,
}

export enum EmailType {
  Default = 0,
  CM_ScheduleAppointmentToClient = 1,
  CM_RescheduleAppointmentToClient = 2,
  CM_CancelAppointmentToClient = 3,
  CM_ScheduleAppointmentToStaff = 4,
  CM_RescheduleAppointmentToStaff = 5,
  CM_CancelAppointmentToStaff = 6,
  CM_CreateDebtorNotificationToARO = 51,
  CM_RefundDebtorOnVoidedLicenceNotificationToARO = 52,
  CM_PlaceExpiredSticker1ToStaff = 101,
  CM_RemoveExpiredSticker1ToStaff = 102,
  CM_PlaceExpiredSticker2ToStaff = 103,
  CM_PlaceCancelledStickerToStaff = 151,
  CM_PlaceHeritageAssessmentSticker = 152,
  CM_RequestPhotographyToStaff = 201,
  CM_RequestHeritageAssessmentToStaff = 202,
  CM_DisposeCrematedRemainsAndMonumentsToStaff = 251,
  CM_DisposeMonumentsToStaff = 252,
  CM_BurialIntermentNotificationToStaff = 301,
  CM_CremationOrderNotificationToStaff = 302,
  CM_CrematedRemainsRemovalRetrieveCrematedRemains = 351,
  CM_BookingReceipt = 401,
  CM_BookingConfirmation = 402,
  CM_NotifyStaffBookingFullyPaid = 403,
  CM_ExportTransaction = 451,
  CM_NotifyStaffLaybyConsultationFullyPaid = 452,
  CM_BIONotificationToStaff = 501,
  CM_NotifyStaffMWOFullyPaid = 551,
  CM_CRIO_IntermentScheduled = 661,
  CUSTOMERSERVICE_Event_SendForAction_Request = 1001,
  CUSTOMERSERVICE_Event_SendForAction_Incident = 1002,
  CUSTOMERSERVICE_Event_FinalNotificationText = 1003,
  CUSTOMERSERVICE_Event_EventClosure_NotifyActionOfficer_Request = 1004,
  CUSTOMERSERVICE_Event_EventClosure_NotifyActionOfficer_Incident = 1005,
  CUSTOMERSERVICE_Event_EventClosure_NotifyCoordinator_Request = 1006,
  CUSTOMERSERVICE_Event_EventClosure_NotifyCoordinator_Incident = 1007,
  CUSTOMERSERVICE_Event_EventRescheduled_NotifyRequestor = 1008,
  CUSTOMERSERVICE_Event_SendForAction_NotifySpecialInterest = 1009,
  CUSTOMERSERVICE_Event_EventClosure_NotifySpecialInterest = 1010,
  CUSTOMERSERVICE_Event_EventReschedule_NotifySpecialInterest = 1011,
  CUSTOMERSERVICE_Event_EventCreated_NotifySpecialInterest = 1012,
  CUSTOMERSERVICE_Event_EventRecategorised_NotifySpecialInterest = 1013,
  CUSTOMERSERVICE_Event_EventReopened_NotifyActionOfficer = 1014,
  CUSTOMERSERVICE_Event_EventReopened_NotifyCoordinator = 1015,
  CUSTOMERSERVICE_Event_EventReopened_NotifySpecialInterest = 1016,
  CUSTOMERSERVICE_ColdChain_BreachImport_NotifiedParties = 1051,
}

export enum EMAILKEY {
  Default = 0,
  MOC = 1,
  INSPECTION = 2,
}

export enum ACCESSRIGHTS {
  Default = 0,
  CanRead = 1,
  CanWrite = 2,
}

export enum ACCESSCONTROLENTITYTYPE {
  Default = 0,
  OrgUnit = 1,
  SiteUser = 2,
}

export enum ACCESSCONTROLRECORDTYPE {
  Default = 0,
  CustomerService_Event = 1,
  Action = 2,
  Comment = 3,
  Contact = 4,
  CustomerService_ServiceStandard = 5,
  CustomerService_ServiceStandardGroups = 6,
  CustomerService_KnowledgeBase = 7,
  CustomerService_EventDefaultOnNew = 8,
  ActionDefaultOnNew = 9,
  CommentDefaultOnNew = 10,
  ContactDefaultOnNew = 11,
  CustomerService_ServiceStandardDefaultOnNew = 12,
  CustomerService_KnowledgeBaseDefaultOnNew = 13,
  IntegratedReportDefaultOnNew = 14,
  CustomerService_SpecialInterest = 15,
  SuperAdmin = 16,
  CustomerService_ColdChain_ImportNotifiedParties = 17,
  RecipientCouncillor = 18,
  RecipientCEO = 19,
  RecipientDirector = 20,
  Keyword = 21,
  CRMS_SuperAdmin = 22,
  Core_IntegratedReport = 23,
  EMS_Facility = 90,
  EMS_SuperAdmin = 91,
  EscalationAOOutstandingRequestsEligibleStaff = 100,
  EscalationAOOverdueRequestsEligibleStaff = 101,
  EscalationCOOutstandingRequestsEligibleStaff = 102,
  EscalationCOOverdueRequestsEligibleStaff = 103,
  EscalationAOOutstandingEligibleStaff_Ack = 104,
  EscalationAOOverdueEligibleStaff_Ack = 105,
  TM_SuperAdmin = 201,
  TM_TrainingRequest = 202,
  TM_DevelopmentPlan = 203,
  TM_TrainingEvalution = 204,
  TM_CourseOutline_DefaultOnNewRequest = 205,
  TM_SkillAndCompliance = 206,
  TM_Approver1Group = 207,
  TM_Approver1Manager = 208,
  TM_Approver2Group = 209,
  TM_Approver2Manager = 210,
  TM_InternalApprover2Group = 211,
  TM_InternalApprover2Manager = 212,
  TM_InternalApprover1Group = 213,
  TM_InternalApprover1Manager = 214,
  FLEET_Vehicle = 301,
  FLEET_SuperAdmin = 302,
}

export enum ACCESSCONTROLSOURCE {
  Default = 0,
  ManualSelection = 1,
  ServiceStandardSelection = 2,
  ActionOfficerSelection = 3,
  CompletedBySelection = 4,
  DefaultOnNew = 5,
  SpecialInterest = 6,
  ReportedTo = 7,
  ReportedBy = 8,
  ContactSelection = 9,
  ReviewerSelection = 10,
  ManagerSelection = 11,
  RecipientCouncillor = 12,
  RecipientCEO = 13,
  RecipientDirector = 14,
  RequestorOrgManager = 15,
  CourseOutlineSelection = 100,
}

export enum AppointmentStatus {
  Default = 0,
  Draft = 1,
  Scheduled = 2,
  Cancelled = 3,
  Complete = 4,
}

export enum AppointmentCompleteAttendance {
  Default = 0,
  Attended = 1,
  NoShow = 2,
}

export enum DocumentTabs {
  Default = 0,
  DocumentsOnly = 1,
  RecordsOnly = 2,
  DocumentsAndRecords = 3,
}

export enum EscalationRowStatus {
  Default = 0,
  Normal = 1,
  Highlight = 2,
  Overdue = 3,
}

export enum PreferredContactMethodSet {
  All = 0,
  Written = 1,
  Verbal = 2,
  WrittenAndVerbal = 3,
}

export enum ContentDisplaySetting {
  DontDisplay = 0,
  SpecificTextOnly = 1,
  DisplayDefaultIfEmpty = 2,
}

export enum ContentSourceType {
  CodeOnly = 0,
  CorporateSetting = 1,
  CMSEntry = 2,
  FileSystem = 3,
}

export enum DisplayComplexityOption {
  Basic = 0,
  Advanced = 1,
  ReadOnly = 3,
}

export enum PageDisplay_ENUM {
  HiddenAlways = 0,
  ShownAlways = 1,
  HiddenPreferred = 2,
  ShownPreferred = 3,
}

export enum ApplicationActions_BITFIELD {
  Initilise = 0,
  View = 1 << 0,
  Add = 1 << 1,
  Amend = 1 << 2,
  Delete = 1 << 3,
  Renew = 1 << 4,
  Transfer = 1 << 5,
  Pay = 1 << 6,
  Confirm = 1 << 7,
  Select = 1 << 8,
}

export enum RosterType {
  Default = 0,
  Core_Area = 1,
  Core_Ward = 2,
  Core_Org = 3,
  CRMS_ServiceStandard = 101,
  CRMS_Category = 102,
}

export enum ActivityType {
  Default = 0,
  Login = 1,
  NewUser = 2,
  ChangePassword = 3,
  CRSPortal_RegisterChild = 1001,
  CRSPortal_UpdateChildDetails = 1002,
  CRSPortal_UpdateProfile = 1003,
  CRSPortal_CentreGroupSelection = 1004,
  Health24x7_RenewPremises = 1101,
}

export enum AccountOperationRequests {
  Default = 0,
  SendEmail = 1 << 0,
  RelinkAccount = 1 << 1,
}

export enum AccountOperationResult {
  Default = 0,
  Success_AccountCreated = 1,
  Success_AccountExists = 2,
  Success_AccountRestored = 3,
  Success_AccountDisabled = 4,
  Success_AccountReset = 5,
  Success_AccountUpdated = 6,
  Success_PortalLinkDoesNotExist = 7,
  Success_AccountReLinked = 8,
  Failure_AccountMissing = 11,
  Failure_DuplicateEmail = 12,
  Failure_DuplicateUsername = 13,
  Failure_AccountLocked = 14,
  Failure_AccountDisabled = 15,
  Failure_UnknownError = 16,
  Failure_UnauthorisedAccess = 17,
  Failure_PortalLinkCreation = 18,
  Failure_ServiceFault = 19,
  Failure_ResponseNULL = 20,
  Failure_DuplicateContact = 21,
  Failure_PortalLinkCorrupted = 22,
  Failure_DuplicateEmailPortal = 23,
  Failure_DuplicateMemberGuidPortal = 24,
  Failure_DuplicatePortalLink = 25,
  Failure_DifferentPortalLink = 26,
  PartialSuccess_AccountCreated_EmailFailed = 30,
  SuccessProfileCreated = 40,
  SuccessProfileUpdated = 41,
  SuccessProfileRemoved = 42,
  FailureProfileNotCreated = 45,
  FailureProfileNotUpdated = 46,
  FailureProfileNotRemoved = 47,
}

export enum StaticMapResolution {
  Pixels_256By256 = 0,
  Pixels_512By512 = 2,
  Pixels_512By256 = 3,
}

export enum EXPORTCUSTOMFIELDS {
  DebtorNumber = 1,
  DebtorName = 2,
  ReferenceNo = 3,
  FeeDateCreated = 4,
  FeeCode = 5,
  FeeDescription = 6,
  Amount = 7,
  Description = 8,
  FeeID = 9,
  TransactionDate = 10,
  DebtorLastName = 11,
  DebtorFirstName = 12,
  DebtorAddress_Full = 13,
  FeeTypeCategory = 14,
  FeeTypeName = 15,
  NumberOfUnits = 16,
  FeeDateCreated_DDMMYYYY = 17,
  TransactionDate_DDMMYYYY = 18,
  PreviousYear = 19,
  CurrentYear = 20,
  NextYear = 21,
  InvoiceNumber = 22,
  ServiceDate_DDMMMYYYY = 23,
  BPayReference = 24,
  CheckDigit = 25,
  Accounting_Flexfield_Segment_1 = 26,
  Accounting_Flexfield_Segment_2 = 27,
  Accounting_Flexfield_Segment_3 = 28,
  Accounting_Flexfield_Segment_4 = 29,
  Accounting_Flexfield_Segment_5 = 30,
  Transaction_Line_Amount = 31,
  Unit_Selling_Price = 32,
  TransactionDate_YYYYMMDD = 33,
  FeeDateCreated_YYYYMMDD = 34,
  ServiceDate_YYYYMMDD = 35,
}

export enum EXPORTCUSTOMFIELDS_HM {
  HM_TradingName = 1,
  HM_RegNo = 2,
  HM_PremisesType = 3,
}

export enum PREFERRED_DISPLAYADDRESS {
  Default = 0,
  PreferredAddress = 1,
  PostalAddress = 2,
  AlternateAddress = 3,
}

export enum BusinessessNumberTypes {
  ABN,
  ACN,
  ARBN,
}

export enum INSPECTIONADDITIONALDETAILS {
  Unclassified = 0,
  CONTACT_NAME = 1,
  INTERVIEWED_PERSON = 2,
  ABN = 3,
  PROPRIETOR_NAME = 201,
  PROPRIETOR_POSTALADDRESS = 202,
  PROPRIETOR_EMAIL = 203,
  PROPRIETOR_MOBILE = 204,
  TRADING_NAME = 205,
  FSS_NAME = 206,
  FSS_CERTIFICATENO = 207,
  FSS_EXPIRYDATE = 208,
  PREMISES_TYPE = 209,
  FOODHANDLERS_KWD = 210,
  FASTCHOICE = 211,
  SCORESENDORSE = 212,
  PROPRIETOR_PHONE = 213,
  CONTACT_PHONE = 214,
}

export enum DEBTOREXPORTED {
  Unclassified = 0,
  NEW = 1,
  CHANGED = 2,
  EXPORTED = 3,
}

export enum InterviewClassification {
  Unclassified = 0,
  Phone = 1,
  Video = 2,
}

export enum ConditionType {
  Unclassified = 0,
  Condition = 1,
  Restriction = 2,
}

export enum ConditionCategory {
  Unclassified = 0,
  SRU_Disability = 1,
  SRU_CYF = 2,
  SRU_CYFAndDisability = 3,
  SRU_SRS = 4,
}

export enum Offline_BackendFunction {
  HM_Inspection_MultipleReports = 1,
  Building_Inspection_MultipleReports = 201,
}

export enum Division {
  Unclassified = 0,
  North = 1,
  South = 2,
  East = 3,
  West = 4,
  Central = 5,
}

export enum TECHONE_ENTITY_CLASSIFICATION {
  Property = 1,
  Owner = 2,
  Proprietor = 3,
}

export enum DocumentClassification {
  Unclassified = 0,
  TP_PSAR_ExhibitionDocs = 1,
  TP_PSAR_HearingDocs = 2,
  TP_PSAR_WorkingDocs = 3,
}

export enum ExternalSystemDocumentType {
  Unclassified = 0,
  IssuePermit = 1,
  RefusePemit = 2,
}

export enum WebLinkCategory {
  Unclassified = 0,
  SetAsideAreaMap = 1,
  SetAsideAreaObligation = 2,
}

export enum HMOnline_PremisesPriority {
  PendingInspection = 1,
  Compliant = 2,
  Major = 3,
  Critical = 4,
}

export enum Relationship_ProhibitionOrderSource {
  Unspecified = 0,
  Matter = 1,
  Investigation = 2,
  InformationSharing = 3,
}

export enum InvestigationStatus {
  Unclassified = 0,
  New = 1,
  Closed = 2,
  Planning = 3,
  Conducting = 4,
  MakingFindingsOfFact = 5,
  PendingApproval = 6,
  CreatingReport = 7,
  MonitoringRecommendations = 8,
  OutcomeAndClosure = 9,
  ClosureApproved = 10,
  Awaiting = 11,
  Completed = 12,
  FinalOutcomesandRecommendations = 13,
  ApprovedforClosure = 14,
  MonitoringCompliance = 15,
  Recommendations = 16,
  MonitoringComplete = 17,
}

export enum UndertakingType {
  Unclassified = 0,
  DWCMS = 1,
  HSR = 2,
}

export enum Undertaking_ClosureReason_Classification {
  Unclassified = 0,
  Revoked = 1,
  Updated = 2,
  Active = 3,
  SupersededUpdated = 4,
}

export enum Inspection_Location {
  Unclassified = 0,
  Onsite = 1,
  Desktop = 2,
}

export enum DisciplinaryAction_Type {
  Unclassified = 0,
  ProfessionalRegistration = 1,
  Other = 2,
}

export enum DisciplinaryAction_Kind {
  Unclassified = 0,
  Suspended = 1,
  Cancelled = 2,
  Other = 3,
}

export enum DisciplinaryAction_OrderType {
  Unclassified = 0,
  Prohibited = 1,
  Banned = 2,
  Other = 3,
}

export enum Source_Type {
  Unclassified = 0,
  Portal = 1,
  Registration = 2,
}

export enum Pathway {
  Unspecified = 0,
  Normal = 1,
  Alternate = 2,
  Qualification = 3,
  Training = 4,
  Experience = 5,
}

export enum Investigation_RelatedEntitiesNotified {
  Default = 0,
  Other = 1,
}

export enum IssueSource {
  Complaint = 1,
  Investigation = 2,
  Notification = 3,
  Enquiry = 4,
}

export enum PrimaryPlaceOfPracticeSource {
  Unspecified = 0,
  Registration = 1,
  Renewal = 2,
  Manual = 3,
}

export enum StreetType {
  AL = 1,
  AMB = 2,
  APPR = 3,
  ARC = 4,
  ART = 5,
  AV = 6,
  BAY = 7,
  BEND = 8,
  BRAE = 9,
  BRK = 10,
  BVD = 11,
  BWK = 12,
  BWL = 13,
  BYP = 14,
  CCL = 15,
  CCS = 16,
  CCT = 17,
  CHA = 18,
  CL = 19,
  CNR = 20,
  COM = 21,
  CON = 22,
  CR = 23,
  CROS = 24,
  CRSE = 25,
  CRST = 26,
  CRY = 27,
  CT = 28,
  CV = 29,
  DALE = 30,
  DELL = 31,
  DENE = 32,
  DIV = 33,
  DOM = 34,
  DR = 35,
  EAST = 36,
  EDG = 37,
  ENT = 38,
  ESP = 39,
  EXTN = 40,
  FLTS = 41,
  FORD = 42,
  FWY = 43,
  GATE = 44,
  GDN = 45,
  GLA = 46,
  GLN = 47,
  GLY = 48,
  GRA = 49,
  GRN = 50,
  GV = 51,
  GWY = 52,
  HILL = 53,
  HLW = 54,
  HTH = 55,
  HTS = 56,
  HUB = 57,
  HWY = 58,
  ID = 59,
  JCT = 60,
  LA = 61,
  LNK = 62,
  LOOP = 63,
  LWR = 64,
  LWY = 65,
  MALL = 66,
  MEW = 67,
  MWS = 68,
  NOOK = 69,
  NTH = 70,
  OUT = 71,
  PATH = 72,
  PD = 73,
  PKT = 74,
  PKW = 75,
  PL = 76,
  PLZ = 77,
  PRM = 78,
  PS = 79,
  PSG = 80,
  PT = 81,
  PUR = 82,
  PWAY = 83,
  QD = 84,
  QU = 85,
  RCH = 86,
  RD = 87,
  RDG = 88,
  RES = 89,
  REST = 90,
  RET = 91,
  RIDE = 92,
  RISE = 93,
  RND = 94,
  ROW = 95,
  RSG = 96,
  RTN = 97,
  RUN = 98,
  SLO = 99,
  SQ = 100,
  ST = 101,
  STH = 102,
  STP = 103,
  STPS = 104,
  SUB = 105,
  TCE = 106,
  THRU = 107,
  TOR = 108,
  TRK = 109,
  TRL = 110,
  TURN = 111,
  TWY = 112,
  UPR = 113,
  VLY = 114,
  VST = 115,
  VW = 116,
  WAY = 117,
  WD = 118,
  WEST = 119,
  WK = 120,
  WKWY = 121,
  WTRS = 122,
  WRY = 123,
  WYD = 124,
  PDE = 125,
}

export enum EducationType {
  E = 0,
  SP = 1,
  LTSP = 2,
  T = 3,
}

export enum AreaType {
  Treatment = 0,
  SetAside = 1,
}

export enum HostEnvironment {
  SystemInitialise = 0,
  OnPremises = 1,
  SaaS = 2,
}

export enum AuthorNameFormatType {
  Unspecified = 0,
  FirstNameLastName = 1,
  LastNameCommaSpaceFirstName = 2,
  LastNameCommaFirstName = 3,
}

export enum SettingFolderMode {
  SettingsFolder = 1,
  SettingsItem,
  ListField,
  SelectItems,
  AddDeleteItems,
}

export enum SettingActionType {
  Read = 0,
  Insert,
  Update,
  Delete,
}

export enum LoggingModel {
  TextFile = 0,
  Serilog = 1,
}

export enum SerilogSinkType {
  Database = 0,
  Seq = 1,
}

export enum PublishTo {
  Unspecified = 0,
  MSServiceBus = 1,
}

export enum Communication_AdditionalRecipientType {
  Unspecified = 0,
  PPRTeamLeader = 1,
  PPRResponsibleAuthority = 2,
  VCAT = 3,
  OnBehalfOf = 4,
  ActionOfficer = 5,
  Coordinator = 6,
  Requestor = 7,
  Councillor = 8,
}

export enum TypeSubmitActions {
  Save = "Save",
  New = "New",
  SameDetails = "SameDetails",
  IssueRegistration = "IssueRegistration",
  Renew = "Renew",
  Deregister = "Deregister",
  Reactivate = "Reactivate",
}

export enum IssueRegistrationFee {
  Unspecified = 0,
  Creation = 1,
  Registration = 2,
}
