import { IAssignContractor } from "@app/products/property/fire-prevention/components/dialogs/assign/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IAssignContractor>();

export const colAssignContractor: IColumnFields[] = [
  { field: nameOf("Contractor"), title: "Contractor" },
  { field: nameOf("PreferredWorkRegion"), title: "Preferred Work Region" },
  {
    field: nameOf("NumberOfOutstandingWorks"),
    title: "Number of Outstanding Works",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("NumberOfCompletedWorks"),
    title: "Number of Completed Works",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LastAssignedWork"),
    title: "Last Assigned Work",
  },
  {
    field: nameOf("LastCompletedWork"),
    title: "Last Completed Work",
  },
  {
    field: nameOf("ContractorId"),
    title: "Contractor ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
