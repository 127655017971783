export const mockTransaction = [
  {
    DateTime: "28-Jul-2017 14:20",
    TransType: "Est",
    Reference: "Wheeler Rd Estimated Cost",
    Description: "Estimated Cost",
    TransactionAmount: 10684.3,
    ProgressiveTotal: 10684.3,
    PrincipalAmount: 10684.3,
    CurrentAmountDue: 10684.3,
    PrincipalBalance: 10684.3,
    InterestBalance: 0.0,
    TotalBalanceOwing: 10684.3,
    CurrentBalanceOwing: 10684.3,
    JournalNumber: "27287",
    SessionNumber: "22676",
    NoticeIssueDate: "29-Jul-2017",
    NoticeDueDate: "13-Oct-2017",
    UserId: "HodgesR",
    IsProposed: false,
    IsCancelled: false,
    SuppressOnNotice: false,
    CreatedOn: "28-Jul-2017 14:21",
    AccountTransactionId: 7996,
  },
  {
    DateTime: "5-Sep-2018 11:34",
    TransType: "Pmt",
    Description: "Payment",
    ReceiptNumber: "2202859",
    TransactionAmount: -10684.3,
    ProgressiveTotal: 0.0,
    PrincipalAmount: -10684.3,
    CurrentAmountDue: -10684.3,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    TotalBalanceOwing: 0.0,
    CurrentBalanceOwing: 0.0,
    JournalNumber: "30528",
    SessionNumber: "25524",
    UserId: "AnastasiA",
    IsProposed: false,
    IsCancelled: false,
    SuppressOnNotice: false,
    CreatedOn: "5-Sep-2018 11:34",
    AccountTransactionId: 8325,
  },
];

export const mockTransactionNo = [
  {
    DateTime: "28-Jul-2017 14:20",
    TransType: "Est",
    Reference: "Wheeler Rd Estimated Cost",
    Description: "Estimated Cost",
    TransactionAmount: 10684.3,
    ProgressiveTotal: 10684.3,
    PrincipalAmount: 10684.3,
    CurrentAmountDue: 10684.3,
    PrincipalBalance: 10684.3,
    InterestBalance: 0.0,
    TotalBalanceOwing: 10684.3,
    CurrentBalanceOwing: 10684.3,
    JournalNumber: "27287",
    SessionNumber: "22676",
    NoticeIssueDate: "29-Jul-2017",
    NoticeDueDate: "13-Oct-2017",
    UserId: "HodgesR",
    IsProposed: false,
    IsCancelled: false,
    SuppressOnNotice: false,
    CreatedOn: "28-Jul-2017 14:21",
    AccountTransactionId: 7996,
  },
  {
    DateTime: "5-Sep-2018 11:34",
    TransType: "Pmt",
    Description: "Payment",
    ReceiptNumber: "2202859",
    TransactionAmount: -10684.3,
    ProgressiveTotal: 0.0,
    PrincipalAmount: -10684.3,
    CurrentAmountDue: -10684.3,
    PrincipalBalance: 0.0,
    InterestBalance: 0.0,
    TotalBalanceOwing: 0.0,
    CurrentBalanceOwing: 0.0,
    JournalNumber: "30528",
    SessionNumber: "25524",
    UserId: "AnastasiA",
    IsProposed: false,
    IsCancelled: false,
    SuppressOnNotice: false,
    CreatedOn: "5-Sep-2018 11:34",
    AccountTransactionId: 8325,
  },
];
