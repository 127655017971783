import { DTO_Journal } from "@app/products/property/journals/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";

export interface DTO_Workflow_JournalAssessmentAdjustCharge {
  WorkflowDetail: DTO_WorkflowDetail_JournalAssessmentAdjustCharge;
  WorkflowHeader: DTO_WorkflowHeader;
}

export interface DTO_WorkflowDetail_JournalAssessmentAdjustCharge {
  Journal_Number: number | null;
  Assessment_Id: number;
  JournalMode: number;
  SupplementaryMode: number;
  CreateSupplementary: DTO_Supplementary_Create;
  CreateJournal: DTO_Journal;
  AssessmentCharges: DTO_AssessmentCharge[];
  AssessmentChargesTotals: DTO_AssessmentChargesTotals;
  TransactionDetails: DTO_AssessmentTransaction;
  SelectedSecondaryWorkflow: number[];
}

export interface DTO_AssessmentChargesTotals {
  GrossAmount: number | null;
  RebatesGiven: number | null;
  NettAmount: number | null;
  Balance: number | null;
}

export interface DTO_Supplementary_Create {
  SupplementaryId: number | null;
  SupplementaryType: number;
  InstalmentPlanId: number | null;
  Name: string;
  Reference: string;
  Date: Date;
  Notes: string;
  IsComplete: boolean | null;
  CreatedOn: Date | null;
  CreatedBy: string;
  VM2000_Id: number | null;
  TransferRemainingBalance: boolean;
  BalanceTransferMethodId: number;
  BalanceTransferValuationType: number | null;
  UseExistingInstalmentPlan: boolean;
}

export interface DTO_AssessmentCharge {
  Adjustment_Amount: number | null;
  ChargeDate: Date | null;
  GrossAmount: number | null;
  RebatesGiven: number | null;
  NettAmount: number;
  Balance: number | null;
  Charge_Interest_Balance: number | null;
  Name: string;
  Code: string;
  Description: string;
  RatingPeriod: string;
  Charge_Id: number;
  ChargeLevies: DTO_AssessmentCharge_Levies;
}

export interface DTO_AssessmentCharge_Levies {
  Levies: DTO_AssessmentCharge_Levy[];
  Totals: DTO_Totals;
}

export interface DTO_Totals {
  GrossAmount: number | null;
  NettAmount: number | null;
  Balance: number | null;
}

export interface DTO_AssessmentCharge_Levy {
  ReductionToMaximumAmount: number | null;
  IncrementToMinimumAmount: number | null;
  RateByValuationAmount: number | null;
  RebateAmount: number | null;
  PercentageOfRatingPeriod: number | null;
  BaseAmount: number | null;
  Levy: string;
  Levy_Id: number;
  Calculation: string;
  Interest: number | null;
  Balance: number | null;
  NettAmount: number | null;
  GrossAmount: number | null;
  Adjustment_Amount: number | null;
  CI_Instalment_Number: number | null;
  Charge_Instalment_Id: number | null;
  Charge_Balances_Id: number;
  AdjustmentForCappingAmount: number | null;
  TaxAmount: number | null;
}

export interface DTO_AssessmentTransaction {
  Journal_Number: number;
  Transaction_Type: number;
  Amount: number;
  TransactionDateTime: Date;
  TransactionReference: string;
  TransactionDescription: string;
  TransactionNotes: string;
}

export enum EKeysOfStepsAdjustCharge {
  WhichJournal = "WhichJournal",
  NewJournal = "CreateJournal",
  NewSupplementary = "CreateSupplementary",
  AdjustCharges = "AssessmentCharges",
  Documents = "Documents",
  Comments = "Comments",
  TransactionDetails = "TransactionDetails",
}
