import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React from "react";

interface IMoveCommentsToLocationDialog {
  onClose: () => void;
}
export const MoveCommentsToLocationDialog = observer(
  ({ onClose }: IMoveCommentsToLocationDialog) => {
    const { pushNotification } = useCCAppNotificationStore();

    const handleConfirmYes = () => {
      onClose();
      pushNotification({
        title: "Move reading comments to meter location successfully",
        type: "success",
      });
    };

    return (
      <ConfirmDialog
        title="Move Meter Reading Comments to Meter Location Confirmation"
        subMessage="Do you wish to move reading comments to the Meter Location?"
        onClosePopup={() => onClose()}
        onConfirmYes={handleConfirmYes}
      />
    );
  }
);
