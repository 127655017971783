import { eventEmitter } from "@/App";
import { importNoticeOfSale } from "@app/products/property/changes-of-ownership/notice-of-sales/components/dialogs/import-notice-of-sale/components/form-elements/api";
import { ImportFileFormStep } from "@app/products/property/changes-of-ownership/notice-of-sales/components/dialogs/import-notice-of-sale/components/form-elements/import-file/_index";
import {
  IMPORT_FILE_FORM_STEP,
  fileFormatData,
} from "@app/products/property/changes-of-ownership/notice-of-sales/components/dialogs/import-notice-of-sale/components/form-elements/import-file/config";
import { EListSubmitButton } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IImportNoticeOfSaleDialogProps {
  onClose: () => void;
}

export const ImportNoticeOfSaleDialog = observer(
  ({ onClose }: IImportNoticeOfSaleDialogProps) => {
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const { pushNotification } = useCCAppNotificationStore();
    const [isShowConfirmDialog, setIsShowConfirmDialog] = useState<boolean>();
    const [formData, setFormData] = useState<FormData | null>(null);
    const [isLoadingConfirmYes, setIsLoadingConfirmYes] = useState(false);

    const steps: IStep[] = [
      {
        label: "Import files",
        component: ImportFileFormStep,
        initialValues: {
          FileFormat: fileFormatData[0],
        },
        visible: true,
        key: IMPORT_FILE_FORM_STEP,
      },
    ];

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          const file = data?.ImportFile?.File[0]?.getRawFile();
          const fileFormat = data?.ImportFile?.FileFormat?.Value;
          if (file && fileFormat) {
            const newFormData = new FormData();
            newFormData.append("file", file);
            newFormData.append("fileFormat", fileFormat);
            newFormData.append("fileName", file.name);
            setFormData(newFormData);
          }
          setIsShowConfirmDialog(true);
          break;
        default:
          break;
      }
    };

    const handleFinish = async () => {
      if (!formData) return;
      setIsLoadingConfirmYes(true);
      const response = await importNoticeOfSale(formData);
      setIsLoadingConfirmYes(false);
      setIsShowConfirmDialog(false);
      if (isSuccessResponse(response) && response.data?.IsSuccess) {
        onClose();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title:
            response?.data?.SuccessMessage ??
            "Import notice of sale was completed successfully.",
          type: "success",
        });
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title:
              response?.error ??
              response?.data?.ErrorMessage ??
              "Import notice of sale could not be completed.",
            type: "error",
            autoClose: false,
          });
      }
    };

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          initialSteps={steps}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              maxHeight="70%"
              titleHeader="Import Notice of Sale"
              onClose={onClose}
              bodyElement={renderProps.children}
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={renderProps.nextButton.disabled}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {renderProps.nextButton.label}
                  </Button>
                </div>
              }
            />
          )}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            title="Confirmation"
            subMessage="Are you sure you want to submit?"
            onClosePopup={() => setIsShowConfirmDialog(false)}
            onAsyncConfirm={() => {
              return handleFinish();
            }}
            isLoadingYes={isLoadingConfirmYes}
          />
        )}
      </>
    );
  }
);
