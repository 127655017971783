import { loadChangeOfOwnershipContact } from "@app/products/property/changes-of-ownership/list/components/reference-sidebar/contact/api";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import React, { useEffect, useState } from "react";

export const ChangeOfOwnershipContactTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ICommonContactItemRender[] | undefined>(
    undefined
  );
  useEffect(() => {
    setIsLoading(true);
    loadChangeOfOwnershipContact().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!data) return <NoData />;
  return <ContactsSidebar data={data || []} />;
};
