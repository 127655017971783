import React, { Fragment } from "react";

interface IProps {
  pillheading?: string;
  descriptiveHeading: string;
}
export const PageTitleHeader: React.FC<IProps> = ({
  pillheading,
  descriptiveHeading,
}) => {
  return (
    <Fragment>
      <h2>
        {pillheading && <span className="badge badge-pill badge-warning">{pillheading}</span>}
        &nbsp;{descriptiveHeading}&nbsp;&nbsp;&nbsp;
      </h2>
    </Fragment>
  );
};
