import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { CreateJournalDialog } from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const CreateJournalButton = observer(() => {
  const [showCreateJournalDialog, setshowCreateJournalDialog] = useState(false);
  const history = useHistory();
  return (
    <>
      <CCNavButton
        title="Create Journal"
        onClick={() => setshowCreateJournalDialog(true)}
      />

      {showCreateJournalDialog && (
        <CreateJournalDialog
          onClose={() => {
            setshowCreateJournalDialog(false);
          }}
          onSubmit={() => {
            setshowCreateJournalDialog(false);

            history.push(`${PROPERTY_JOURNALS_ROUTE}/20245`, {
              isExpandedUsage: true,
            });
          }}
        />
      )}
    </>
  );
});
