import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsAmalgamatePIC {
  Detail = "Details",
  Association = "Associations",
  PICDetail = "PICDetails",
  Collection = "SelectedCollection",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export enum ELabelAmalgamatePIC {
  Detail = "Details",
  Association = "Related",
  PICDetail = "PIC detail",
  Collection = "Collections",
  Comment = "Comments",
  Document = "Documents",
  Workflow = "Workflow",
}

export const keysOfStepsAmalgamatePIC = [
  EKeysOfStepsAmalgamatePIC.Association,
  EKeysOfStepsAmalgamatePIC.Detail,
  EKeysOfStepsAmalgamatePIC.PICDetail,
  EKeysOfStepsAmalgamatePIC.Collection,
];

export interface DTO_Workflow_PIC_Amalgamate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_PIC_Amalgamate;
}

export interface DTO_WorkflowDetail_PIC_Amalgamate {
  Details: DTO_PIC_AmalgamateDetails;
  Associations: DTO_PIC_AmalgamateAssociations;
  PICDetails: DTO_PIC_AmalgamatePICDetails;
  LOVs: DTO_PIC_AmalgamateLOVs;
  SelectedCollection: Collection[];
}
export interface RequestPICAmalgamateObj {
  PIC_Ids: number[];
}

export interface DTO_PIC_AmalgamateDetails {
  Reference: string;
  ApplicantName: string;
  ReasonId?: number;
}

export interface DTO_PIC_AmalgamateAssociations {
  AssociationDate: Date;
  PIC_Ids: number[];
  PICs: VO_PIC[];
  Selected_Primary_PIC_Id?: number;
  Primary_Assessments: VO_Assessment_w_Detail[];
  Default_Primary_Assessment_Id?: number;
  Selected_Primary_Assessment_Id?: number;
}

export interface DTO_PIC_AmalgamatePICDetails {
  Trading_Names: string[];
  Selected_Trading_Name: string;
  Default_Trading_Name: string;
  Districts: { [key: number]: string };
  Selected_District_Id: number | null;
  Default_District_Id: number | null;
  Selected_Quarantine_Status: string;
  Default_Quarantine_Status: string;
  GIS_GlobalID: string;
  Effective_From_Date: Date | null;
  Effective_To_Date: Date | null;
  LongitudeLatitude: string;
  GISLandArea: number | null;
  GISLandAreaUnitCode: string;
  LLSProperty_Ref_No: string;
  SpatialReceivedLots: DTO_Title[];
  OriginalLotGis: string[];
  Default_RLP_Board_Id: number | null;
  Selected_RLP_Board_Id: number | null;
  RLP_Boards: { [key: number]: string };
  Default_Agent_License_Number: string;
  PIC_Numbers: string[];
  Selected_PIC_Number: string;
  Default_PIC_Number: string;
  PIC_Manager_Ids: { [key: number]: string };
  Selected_PIC_Manager_Id: number | null;
  Default_PIC_Manager_Id: number | null;
  PIC_Types: { [key: number]: string };
  GisReferences: DTO_GIS[];
  Swines: DTO_PIC_Swine[];
  Selected_PIC_Type_Id: number | null;
  Default_PIC_Type_Id: number | null;
  Default_PIC_Type_Is_Agent: boolean | null;
  PIC_Land_Uses: { [key: number]: string };
  Selected_PIC_Land_Use_Id: number | null;
  Default_PIC_Land_Use_Id: number | null;
  Agent_License_Numbers: string[];
  Selected_Agent_License_Number: string;
  Selected_PIC_Type_Name: string;
}

export interface DTO_PIC_AmalgamateLOVs {
  ReasonForCreation: DTO_LOV[];
  GISType: DTO_LOV[];
  SwineType: DTO_LOV[];
}
