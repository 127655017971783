import { mockBuildingCalendar } from "@app/products/building/insp-schedule/calendar/mock";

const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate: any) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};

export const displayDate = new Date(Date.UTC(currentYear, 11, 25));

export const sampleCalendarData = mockBuildingCalendar.map((dataItem) => ({
  id: dataItem.TaskID,
  start: parseAdjust(dataItem.Start),
  startTimezone: dataItem.StartTimezone,
  end: parseAdjust(dataItem.End),
  endTimezone: dataItem.EndTimeZone,
  isAllDay: dataItem.isAllDay,
  title: dataItem.Title,
  description: dataItem.Description,
  recurrenceRule: dataItem.RecurrenceRule,
  recurrenceId: dataItem.RecurrenceID,
  recurrenceExceptions: dataItem.RecurrenceException,
  name: dataItem.name,
  roomId: dataItem.RoomID,
  ownerID: dataItem.OwnerID,
  personId: dataItem.OwnerID,
}));
