import { InspectionsView } from "@app/products/animals/inspections/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { formatDateByKendo } from "@common/utils/formatting";

export const LocalLawInspectionsBookmark = {
  getBookmarkDisplayName(selectedRow: InspectionsView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Local Laws - Inspections ${dynamicDisplayName}`;
  },

  getBookmarkDetail(selectedRow: InspectionsView) {
    return getDisplayTextWithDashes([
      selectedRow?.InspectionType ?? "",
      formatDateByKendo(selectedRow?.DateInspected, DATE_FORMAT.DATE) ?? "",
    ]);
  },
};
