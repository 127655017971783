import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { EVENT_MANAGEMENT_MANAGE_ROUTE } from "@app/products/event-management/[id]/constant";
import { IColumnFields } from "@components/cc-grid/model";

export const colEventManagementAdminFacilities: IColumnFields[] = [
  {
    field: EventManagementFieldMapping.Facility_Name,
    title: "Facility",
    width: 300,
    locked: true,
    linkTo: (dataItem) => {
      return `${EVENT_MANAGEMENT_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  {
    field: EventManagementFieldMapping.MasterFacility_Name,
    title: "Master Facility",
    width: 300,
  },
  {
    field: EventManagementFieldMapping.Facility_Status,
    title: "Status",
    width: 300,
  },
  {
    field: EventManagementFieldMapping.Capacity,
    title: "Capacity",
    width: 250,
  },
  { field: EventManagementFieldMapping.Alcohol, title: "Alcohol", width: 200 },
  {
    field: EventManagementFieldMapping.Catering,
    title: "Catering",
    width: 200,
  },
  {
    field: EventManagementFieldMapping.AudioVisual,
    title: "Audio Visual",
    width: 200,
  },
  {
    field: EventManagementFieldMapping.ChildFriendly,
    title: "Child Friendly",
    width: 200,
  },
];
