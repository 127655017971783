import {
  VO_Land_Rent,
  VO_Land_Rent_Action_Date,
} from "@app/products/property/assessments/[id]/components/child-screens/land-rent/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfLandRent = nameOfFactory<VO_Land_Rent>();
const nameOfLandRentActionDate = nameOfFactory<VO_Land_Rent_Action_Date>();
export const colAssessedLandRent: IColumnFields[] = [
  {
    field: nameOfLandRent("Rating_Period_Name"),
    title: "Period",
  },
  {
    field: nameOfLandRent("Rating_Period_Start"),
    title: "Period Start",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLandRent("Rating_Period_End"),
    title: "Period End",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLandRent("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOfLandRent("Land_Rent_Calculated"),
    title: "Land Rent Calculated",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLandRent("Notional_Amount"),
    title: "Notional Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLandRent("ALR_Id"),
    title: "ALR ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLandRent("Change_Date"),
    title: "Charge Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfLandRent("Change_User"),
    title: "Charge User",
  },
];

export const colLandRentActionDate: IColumnFields[] = [
  {
    field: nameOfLandRentActionDate("Rating_Period_Name"),
    title: "Period",
  },
  {
    field: nameOfLandRentActionDate("Rating_Period_Start"),
    title: "Period Start",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLandRentActionDate("Rating_Period_End"),
    title: "Period End",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLandRentActionDate("Action_Type"),
    title: "Action Type",
  },
  {
    field: nameOfLandRentActionDate("Actioned_On"),
    title: "Actioned On",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfLandRentActionDate("Action_Valuation"),
    title: "Action Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLandRentActionDate("Id"),
    title: "Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLandRentActionDate("Change_Date"),
    title: "Charge Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfLandRentActionDate("Change_User"),
    title: "Charge User",
  },
];
