import { getBuildingRegisterEasementById } from "@app/products/building/registers/easements/[id]/components/reference-sidebar/detail/api";
import { IBuildingRegisterEasementFormData } from "@app/products/building/registers/easements/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

export const EasementDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [easementInfo, setEasementInfo] = useState<
    IBuildingRegisterEasementFormData | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();

  const easementId: number | string = lastSelectedRow?.ID ?? params.id;
  useEffect(() => {
    setIsLoading(true);
    getBuildingRegisterEasementById(easementId).then(
      (data: IBuildingRegisterEasementFormData) => {
        setEasementInfo(data);
        setIsLoading(false);
      }
    );
  }, [easementId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!easementInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded
          title={
            <ProductReferenceHeading
              title={"Register Number:"}
              value={easementInfo?.RegisterNo?.toString()}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Status:"}
              value={easementInfo?.Type}
              success
            />
            <ProductReferenceRow
              title={"Lodged Date:"}
              value={formatDisplayValue(easementInfo?.Lodged, DATE_FORMAT.DATE)}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
