import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colReports: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Name,
    title: "Name",
    width: 900,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Description,
    title: "Description",
    width: 900,
  },
];
