import { centrelinkCustomerConfirmationRoute } from "@app/products/property/centrelink-customer-confirmation/route";
import ExistedCentreLinkResponseBatch from "@app/products/property/centrelink-customer-confirmation/view-responses/components/_index";
import { ImportRejectionButton } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/buttons/import-rejection/_index";
import { ImportResponseButton } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/buttons/import-response/_index";
import { PROPERTY_CENTRELINK_RESPONSE_BATCH_ROUTE } from "@app/products/property/centrelink-customer-confirmation/view-responses/constant";
import { useCentreLinkResponseBatchStore } from "@app/products/property/centrelink-customer-confirmation/view-responses/store";
import { getBookmarkDisplayName } from "@app/products/property/centrelink-customer-confirmation/view-responses/util";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const ManageCentreLinkResponseBatch = observer(() => {
  const { loadResponseBatchDetails, resetCentreLinkResponseBatchStore } =
    useCentreLinkResponseBatchStore();
  const { resetMenu } = useCCSubActionBarStore();

  const [showSlideBar, setShowSlideBar] = useState(true);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown
        category={centrelinkCustomerConfirmationRoute.path}
      />,
    ],
    centerComponents: [
      <CCNavButton
        title="Workflow"
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <ImportResponseButton />
        <ImportRejectionButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <CCNavButton
        title={"Slide Bar"}
        iconClass="fal fa-info-circle"
        isActive={showSlideBar}
        onClick={() => {
          setShowSlideBar(!showSlideBar);
        }}
      />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_CENTRELINK_RESPONSE_BATCH_ROUTE}
        displayName={() => {
          return "Property - Centrelink Customer Confirmation - Import Responses";
        }}
        listViewDisplayName={getBookmarkDisplayName}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_CentrelinkRequestBatch}
      />,
    ],
  });

  useEffectOnce(() => {
    loadResponseBatchDetails();
    return () => {
      resetMenu();
      resetCentreLinkResponseBatchStore();
    };
  });

  return <ExistedCentreLinkResponseBatch showSlideBar={showSlideBar} />;
});

export default ManageCentreLinkResponseBatch;
