import { globalStoreInstance } from "@common/stores/global/store";
import figlet from "figlet";
// @ts-ignore
//Disable before run test===================
import standard from "figlet/importable-fonts/Standard";
//===========================================

/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */

const getLine = (length: number) => {
  let ret = "";
  for (let i = 0; i <= length * 2 + 2; i++) {
    ret += "-";
  }
  ret += "\n";
  return ret;
};

const getHeading = (headingText: string) => {
  let ret = "| ";
  for (const ch of headingText) {
    ret = ret + ch + " ";
  }
  ret += "| \n";
  return ret;
};
const getBlock = (txt: string) => {
  const textLen = txt.length;
  let ret = "";
  ret += getLine(textLen);
  ret += getHeading(txt);
  ret += getLine(textLen);
  return ret;
};

export const BannerText = () => {
  let bannerText = "";
  const projectName = globalStoreInstance.allSetting.projectName
    ? globalStoreInstance.allSetting.projectName
    : "Ready Community";
  const projectVersion = globalStoreInstance.allSetting.version
    ? globalStoreInstance.allSetting.version
    : "beta";
  // Disable before run test===================
  figlet.parseFont("Standard", standard);
  //===========================================
  figlet.text(
    projectName,
    // Disable before run test===================
    {
      font: "Standard",
    },
    //===========================================
    function (err: any, data: any) {
      bannerText += data + "\n";
    }
  );
  bannerText += getBlock("Version: " + projectVersion);
  console.log(bannerText);
};
