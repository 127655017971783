import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { FormAction } from "@app/products/crms/model";

export const CRMSNoticesBookmark = {
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `${CRMS_PRODUCT_NAME} - Notices ${dynamicDisplayName}`;
  },
  getBookmarkDetail() {
    return `${CRMS_PRODUCT_NAME}  Notices`;
  },
};

export const crmsNoticesListViewURL = (formAction: FormAction) => {
  return `odata/crms/internal/events/GetEventNotices(formAction=${formAction})?$count=true&`;
};
