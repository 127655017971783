import {
  loadAssessmentArrangementInfo,
  loadAssessmentArrangementList,
} from "@app/products/property/assessments/[id]/components/child-screens/arrangement/api";
import {
  DTO_ArrangementListView,
  DTO_Assessment_Arrangements,
} from "@app/products/property/assessments/[id]/components/child-screens/arrangement/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AssessmentArrangementStore {
  private _arrangement?: DTO_Assessment_Arrangements = undefined;
  private _views: DTO_ArrangementListView[] = [];
  private _view?: DTO_ArrangementListView = undefined;
  private _isLoading: boolean = false;
  private _isDetailLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  get isDetailLoading() {
    return this._isDetailLoading;
  }
  get arrangement() {
    return this._arrangement;
  }
  get views() {
    return this._views;
  }
  get view() {
    return this._view;
  }

  setArrangement = (arrangement?: DTO_Assessment_Arrangements) => {
    runInAction(() => {
      this._arrangement = arrangement;
    });
  };
  setViews = (views: DTO_ArrangementListView[]) => {
    runInAction(() => {
      this._views = views;
    });
  };
  setView = (view?: DTO_ArrangementListView) => {
    runInAction(() => {
      this._view = view;
    });
  };
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  setIsDetailLoading = (isDetailLoading: boolean) => {
    runInAction(() => {
      this._isDetailLoading = isDetailLoading;
    });
  };

  //Action

  loadViews = async (assessmentId: number) => {
    this.setIsLoading(true);
    const newViews = await loadAssessmentArrangementList(assessmentId);
    this.setViews(newViews ? newViews.ArrangementListView : []);
    this.setView(this.views[0]);
    this.setIsLoading(false);
  };

  loadArrangement = async (assessmentId: number, arrangementId: number) => {
    this.setIsDetailLoading(true);
    const newArrangement = await loadAssessmentArrangementInfo(
      assessmentId,
      arrangementId
    );
    this.setArrangement(newArrangement);
    this.setIsDetailLoading(false);
  };
}

const assessmentArrangementStoreContext = createContext(
  new AssessmentArrangementStore()
);
export const useAssessmentArrangementStore = () => {
  return useContext(assessmentArrangementStoreContext);
};
