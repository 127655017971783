import {
  CommunicationButton,
  ICommunicationButton,
} from "@app/core/communication/buttons-list-view/components/buttons/_index";
import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const CommunicationButtonParcelLocality = observer(
  (props: ICommunicationButton) => {
    const { gridSelectedRows } = useDetailParcelListViewStore();

    return (
      <CommunicationButton {...props} gridSelectedRows={gridSelectedRows} />
    );
  }
);
