//TODO: convert to a store

import {
  IFieldCfgScreenReference,
  //IModelConfiguration,
  IFieldConfiguration,
  IFieldDefinition,
  IGroupContainerLayout,
  IModelConfigurationSet,
  IModelData,
  IModelDefinition,
  IModelReference,
  IRowFields,
} from "@app/products/ams/model/catalogue";
import modelCfgDataAssetCategory from "../asset-categories/assetcategory-configuration.json"; //definition of model
import modelDefDataAssetCategory from "../asset-categories/assetcategory-definition.json"; //definition of model
import modelRecDataFeatures from "../asset-register/assetFeatures-data.json"; //feature records of data
import modelRecDataCatalogue from "./catalogue-data.json"; //records of data
import modelDefDataCatalogue from "./catalogue-definition.json"; //definition of model

const modelRecDataCol = modelRecDataCatalogue as IModelData[];
const featureModelRecDataCol = modelRecDataFeatures as IModelData[];
const modelDefDataCol = (modelDefDataCatalogue as IModelDefinition[]).concat(
  modelDefDataAssetCategory as IModelDefinition[]
);
const modelCfgDatacol = modelCfgDataAssetCategory as IModelConfigurationSet[];

//super basic mock
export function getDataForModel(modelRef: IModelReference): IModelData {
  const retVal = { modelReference: modelRef, records: [] };
  //todo: this wont find asset data at the moment, but i can just make the asset page create the correct 'wrapper' object?
  const item = modelRecDataCol.find(
    (r: IModelData) =>
      r.modelReference.modelName === modelRef.modelName &&
      r.modelReference.context === modelRef.context
  );
  return item ?? retVal;
}

export function getDataForModelCfgSet(
  modelCfgSet: IModelConfigurationSet
): IModelData[] {
  let retVal: IModelData[] = [];

  modelCfgSet.modelConfigs.forEach((item) => {
    let modelDataSet: IModelData[] = [];
    switch (item.modelDefRef.context) {
      case "catalogue":
        modelDataSet = modelRecDataCol;
        break;
      case "assetCategory":
        modelDataSet = featureModelRecDataCol;
        break;
    }

    let rec = modelDataSet.find(
      (x) =>
        x.modelReference.context === item.modelDefRef.context &&
        x.modelReference.modelName === item.modelDefRef.modelName
    );
    if (rec) {
      retVal.push(rec);
    }
  });

  return retVal;
}

export function getModelsByContext(context: string): IModelDefinition[] {
  const retVal = {
    modelReference: { modelName: "", context: context },
    fields: [],
  };

  const items = modelDefDataCol.filter(
    (r, i) => r.modelReference.context === context
  );
  return items ?? retVal;
}

export function getModelForReference(
  modelRef: IModelReference
): IModelDefinition {
  const retVal = { modelReference: modelRef, fields: [] };
  const item = modelDefDataCol.find(
    (r: IModelDefinition) =>
      r.modelReference.modelName === modelRef.modelName &&
      r.modelReference.context === modelRef.context
  );
  return item ?? retVal;
}

export function getModelsForConfigSet(
  modelCfgSet: IModelConfigurationSet
): IModelDefinition[] {
  let retVal: IModelDefinition[] = [];
  modelCfgSet.modelConfigs.forEach((cfg) => {
    retVal.push(getModelForReference(cfg.modelDefRef));
  });
  return retVal;
}

export function getModelConfigSetForModelRef(
  modelRef: IModelReference
): IModelConfigurationSet {
  let retVal = {
    modelConfigRef: modelRef,
    modelConfigs: [{ modelDefRef: modelRef, fields: [] }],
    groupContainers: [],
  };
  const item = modelCfgDatacol.find(
    (r) =>
      r.modelConfigRef.context === modelRef.context &&
      r.modelConfigRef.modelName === modelRef.modelName
  );
  return item ?? retVal;
}

export function getIRowFieldsForGroupContainer(
  grp: IGroupContainerLayout
): IRowFields[] {
  let retVal: IRowFields[] = [];
  let rowNum = 1;

  //const chunkSize = designerMode ? 3 : 4;
  const chunkSize = 3;
  for (var i = 0; i < grp.fields.length; i += chunkSize) {
    retVal.push({
      rowNum: rowNum++,
      fields: grp.fields.slice(i, i + chunkSize),
    });
  }
  return retVal;
}

export function getElementsForScreenReference(
  modelCfgSet: IModelConfigurationSet,
  modelDefs: IModelDefinition[],
  modelDatas: IModelData[],
  fldCfgSr: IFieldCfgScreenReference
): [IFieldConfiguration, IFieldDefinition, IModelData] {
  const modelCfg = modelCfgSet.modelConfigs.find(
    (x) =>
      x.modelDefRef.context === fldCfgSr.modelDefRef.context &&
      x.modelDefRef.modelName === fldCfgSr.modelDefRef.modelName
  );
  const modelDef = modelDefs.find(
    (y) =>
      y.modelReference.context === modelCfg?.modelDefRef.context &&
      y.modelReference.modelName === modelCfg.modelDefRef.modelName
  );
  const modelData = modelDatas.find(
    (d) =>
      d.modelReference.context === modelDef?.modelReference.context &&
      d.modelReference.modelName === modelDef.modelReference.modelName
  );
  const fldCfg = modelCfg!.fields.find(
    (r) => r.propertyName === fldCfgSr.propertyName
  );
  const fldDef = modelDef!.fields.find(
    (s) => s.propertyName === fldCfgSr.propertyName
  );
  return [fldCfg!, fldDef!, modelData!];
}
