import { SITING_APPROVALS_MANAGE_ROUTE } from "@app/products/building/registers/siting-approvals/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewSitingApprovalButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Siting Approval"
      onClick={() => {
        history.push(`${SITING_APPROVALS_MANAGE_ROUTE}/new`);
      }}
    />
  );
};
