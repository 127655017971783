import { AssociationMeter } from "@app/products/property/meters/[id]/components/child-screens/installation/model";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { isBefore } from "date-fns";

export const removeAssociationMeterValid = (
  associationMeter: AssociationMeter[]
): IAppNotificationItem[] => {
  for (let i = 0; i < associationMeter.length; i++) {
    const meter = associationMeter[i];
    if (!meter.AssociatedTo || !meter.AssociatedFrom) {
      return [
        {
          id: "0",
          title: `Please enter "Associated To" at meter ${meter.MeterNo}`,
          type: "warning",
          autoClose: false,
        },
      ];
    }

    meter.AssociatedTo.setHours(0, 0, 0, 0);
    meter.AssociatedFrom.setHours(0, 0, 0, 0);
    if (isBefore(meter.AssociatedTo, meter.AssociatedFrom)) {
      return [
        {
          id: "0",
          title: `Meter ${meter.MeterNo} - "Associated To" must be equal or greater than "Associated From"`,
          type: "warning",
          autoClose: false,
        },
      ];
    }
  }
  return [];
};
