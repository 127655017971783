import { getViewConfigurations } from "@app/products/property/api";
import {
  colTitleLookup,
  eOptionSearchTitleLookup,
} from "@app/products/property/components/dialogs/add-title-lookup/config";
import {
  IFilterSearch,
  fnt_Title_LookupResult,
} from "@app/products/property/components/dialogs/add-title-lookup/model";
import {
  generateTitleLookupOdataUrl,
  isNumberModeTitleSearch,
} from "@app/products/property/components/dialogs/add-title-lookup/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { processDynamicColumns } from "@app/products/property/util";

import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useDebounce } from "@common/hooks/useDebounce";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAddTitleLookupDialogProps {
  onClose: () => void;
  handleAddTitle: (data: fnt_Title_LookupResult[]) => void;
  isLoadingFinish?: boolean;
  statusParamSearch?: number[];
}
const nameOf = nameOfFactory<fnt_Title_LookupResult>();
export const AddTitleLookupDialog = observer(
  ({
    onClose,
    handleAddTitle,
    isLoadingFinish = false,
    statusParamSearch = [0],
  }: IAddTitleLookupDialogProps) => {
    //Get labels
    const [titleIDLabel, titleLabel, titleLookupLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Title_ID,
        ECustomColNameProperty.Title,
        ECustomColNameProperty.Title_Lookup,
      ]);

    //process list option
    const optionListTitle = useMemo(() => {
      const titleSearchBy: DTO_LOV_Number[] = [
        {
          Code: eOptionSearchTitleLookup.LegalDescription,
          Name: "Legal Description",
        },
        {
          Code: eOptionSearchTitleLookup.Owner,
          Name: "Owner",
        },
        {
          Code: eOptionSearchTitleLookup.Volume_Folio,
          Name: "Volume/Folio",
        },
        {
          Code: eOptionSearchTitleLookup.TitleId,
          Name: `${titleIDLabel}`,
        },
        {
          Code: eOptionSearchTitleLookup.TitleReference,
          Name: `${titleLabel} Reference Number`,
        },
      ];
      return titleSearchBy;
    }, [titleIDLabel, titleLabel]);

    const [filter, setFilter] = useState<IFilterSearch>({
      searchValue: "",
      searchBy: optionListTitle?.[0],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >(undefined);
    const [processedColumns, setProcessedColumns] =
      useState<IColumnFields[]>(colTitleLookup);
    const [selectedTitle, setSelectedTitle] = useState<
      fnt_Title_LookupResult[]
    >([]);
    const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

    const debouncedSearch = useDebounce(filter?.searchValue ?? "", 500);

    useEffect(() => {
      (async () => {
        if (
          !isNil(debouncedSearch) &&
          filter &&
          filter?.searchValue?.length >= 1 &&
          !isNil(filter?.searchBy?.Code)
        ) {
          setSelectedTitle([]); //Clear selected rows
          setDataUrl(
            generateTitleLookupOdataUrl({
              LookupKey: filter?.searchBy.Code,
              LookupValue: debouncedSearch,
              Statuses: statusParamSearch,
            })
          );
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, filter?.searchBy?.Code]);

    const loadConfigurationView = () => {
      setIsLoading(true);
      getViewConfigurations(ViewConfiguration.TitleLookup).then((response) => {
        if (isSuccessResponse(response)) {
          const viewConfig: ICCViewColumn[] | undefined =
            response?.data?.ColumnDefinitions?.Columns;
          if (!viewConfig || viewConfig?.length === 0) {
            setProcessedColumns([]);
            setIsLoading(false);
            return;
          }
          setProcessedColumns(
            processDynamicColumns(colTitleLookup, viewConfig)
          );
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error ?? "Load failed",
          });
        }
        setIsLoading(false);
      });
    };

    useEffectOnce(() => {
      loadConfigurationView();
    });

    return (
      <Form
        onSubmitClick={() => handleAddTitle(selectedTitle)}
        initialValues={{
          TitleSearchBy: optionListTitle?.[0],
        }}
        render={(formRenderProps: FormRenderProps) => (
          <CCDialog
            titleHeader={titleLookupLabel}
            maxWidth="50%"
            height="auto"
            onClose={onClose}
            disabled={isLoading || isLoadingFinish}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Search by" />
                      <Field
                        name={"TitleSearchBy"}
                        placeholder={"Search by"}
                        component={CCDropDownList}
                        data={optionListTitle}
                        textField={"Name"}
                        dataItemKey={"Code"}
                        onChange={(event: DropDownListChangeEvent) => {
                          setFilter((pre: IFilterSearch) => {
                            return {
                              searchValue: "",
                              searchBy: event?.value,
                            };
                          });
                          formRenderProps.onChange("TitleSearchBy", {
                            value: event.value,
                          });
                          formRenderProps.onChange("TitleSearchValue", {
                            value: "",
                          });
                        }}
                      />
                    </div>
                    <div className="cc-field cc-col-span-2">
                      <label className="cc-label">
                        Search value
                        <CCTooltip
                          type="info"
                          content={`${titleIDLabel} is a number`}
                          position="auto"
                        />
                      </label>
                      <Field
                        name={"TitleSearchValue"}
                        placeholder={"Search value"}
                        component={CCInput}
                        type={
                          isNumberModeTitleSearch(filter?.searchBy?.Code)
                            ? "number"
                            : "string"
                        }
                        onChange={(event: InputChangeEvent) => {
                          setFilter((pre: IFilterSearch) => {
                            return { ...pre, searchValue: event?.value };
                          });
                          formRenderProps.onChange("TitleSearchValue", {
                            value: event.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    {isLoading ? (
                      <Loading isLoading />
                    ) : responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => {
                          loadConfigurationView();
                        }}
                      />
                    ) : (
                      <div className="cc-field">
                        <CCGrid
                          columnFields={processedColumns ?? []}
                          primaryField={nameOf("Title_Id")}
                          dataUrl={dataUrl}
                          selectedRows={selectedTitle}
                          selectableMode="multiple"
                          onSelectionChange={(dataItem: any[]) => {
                            setSelectedTitle(dataItem);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  iconClass={isLoadingFinish ? "fas fa-spinner fa-spin" : ""}
                  themeColor="primary"
                  onClick={formRenderProps.onSubmit}
                  type="submit"
                  disabled={
                    !formRenderProps.valid ||
                    !formRenderProps.modified ||
                    selectedTitle?.length === 0
                  }
                >
                  OK
                </Button>
              </div>
            }
          />
        )}
      />
    );
  }
);
