import { ChargeRunsTotal } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/general/components/form-element/components/totals";
import { DTO_ChargeRun_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/model";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { ReactElement } from "react";

interface IPropertyChargeAndNoticeRunsFormElementProps {
  formRenderProps: FormRenderProps;
  options?: any;
}

export function PropertyChargeAndNoticeRunsFormElement({
  options = { assessmentGroupLabel: "Assessment groups" },
}: IPropertyChargeAndNoticeRunsFormElementProps): ReactElement {
  const nameOf = nameOfFactory<DTO_ChargeRun_Summary>();
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Totals",
      component: <ChargeRunsTotal />,
    },
  ];

  return (
    <FormElement className="cc-property-charge-and-notice-runs-charge-runs">
      <section className="cc-field-group cc-custom-sub-panel-bar">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Id" />
            <Field
              readOnly
              name={nameOf("ChargeRunId")}
              placeholder={"Id"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Name" />
            <Field
              readOnly
              name={nameOf("ChargeName")}
              placeholder={"Name"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Code" />
            <Field
              readOnly
              name={nameOf("Code")}
              placeholder={"Code"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Date" />
            <Field
              disabled
              name={nameOf("ChargeDate")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Type" />
            <Field
              readOnly
              name={nameOf("ChargeRunType")}
              placeholder={"Type"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Instalment plan" />
            <Field
              readOnly
              name={nameOf("InstalmentPlan")}
              placeholder={"Instalment plan"}
              component={CCInput}
            />
          </div>
        </div>
        <div>
          <label className="cc-label">Selection criteria</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel
                  title={options?.assessmentGroupLabel ?? "Assessment groups"}
                />
                <Field
                  readOnly
                  name={nameOf("AssessmentGroupName")}
                  placeholder={
                    options?.assessmentGroupLabel ?? "Assessment groups"
                  }
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Rating period" />
                <Field
                  readOnly
                  name={nameOf("RatingPeriod")}
                  placeholder="Rating period"
                  component={CCInput}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Custom where" />
                <Field
                  readOnly
                  name={nameOf("SelectionCriteria")}
                  placeholder="Custom where"
                  rows={3}
                  component={CCTextArea}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Description" />
            <Field
              readOnly
              name={nameOf("Description")}
              placeholder="Description"
              rows={3}
              component={CCTextArea}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Created on" />
            <Field
              disabled
              name={nameOf("CreatedOn")}
              placeholder={"Created on"}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Created by" />
            <Field
              readOnly
              name={nameOf("CreatedBy")}
              placeholder={"Created by"}
              component={CCInput}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Updated on" />
            <Field
              disabled
              name={nameOf("UpdatedOn")}
              placeholder={"Updated on"}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Updated by" />
            <Field
              readOnly
              name={nameOf("UpdatedBy")}
              placeholder={"Updated by"}
              component={CCInput}
            />
          </div>
        </div>
        <CustomPanelBar listPanelBar={listPanelBar} sort={false} />
      </section>
    </FormElement>
  );
}
