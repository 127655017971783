import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postAddAssessmentCompliance = async (
  complianceId: number,
  assessmentId: number
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/compliance/${complianceId}/assessment/${assessmentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const deleteAssessmentCompliance = async (
  complianceId: number,
  assessmentId: number
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().delete(
      `api/property/internal/compliance/${complianceId}/assessment/${assessmentId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
