import { CC_SETTINGS_SEQCONTROL } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { EClientSequenceKey } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface IRecordsInternalFile {
  formRenderProps: FormRenderProps;
}

export const RecordsInternalFile = observer(
  ({ formRenderProps }: IRecordsInternalFile) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    //Common field
    const fieldNumberFormat = getConfigDataField(
      ECorporateSettingsField.InternalFileSystem_NumberFormat
    );
    const fieldNumberPrefix = getConfigDataField(
      ECorporateSettingsField.InternalFileSystem_NumberPrefix
    );
    const fieldNumberSuffix = getConfigDataField(
      ECorporateSettingsField.InternalFileSystem_NumberSuffix
    );
    const fieldNumberLength = getConfigDataField(
      ECorporateSettingsField.InternalFileSystem_NumberLength
    );
    const fieldNumberLastUsed = getConfigDataField(
      `${CC_SETTINGS_SEQCONTROL}${EClientSequenceKey.Core_InternalFileSystem}`
    );

    return (
      <>
        {fieldNumberFormat && (
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={fieldNumberFormat}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldNumberPrefix && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldNumberPrefix}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldNumberSuffix && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldNumberSuffix}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldNumberLength && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldNumberLength}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldNumberLastUsed && (
          <div className="cc-form-cols-3">
            <NumericTextboxGlobalSettings
              data={fieldNumberLastUsed}
              isEditing={isEditing}
              min={0}
            />
          </div>
        )}
      </>
    );
  }
);
