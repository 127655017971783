import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class RaiseInterestWorkflowStore {
  private _isUseAssessRefAsPrimary: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isUseAssessRefAsPrimary() {
    return this._isUseAssessRefAsPrimary;
  }
  setIsUseAssessRefAsPrimary = (isAssessmentRef: boolean) => {
    runInAction(() => {
      this._isUseAssessRefAsPrimary = isAssessmentRef;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this.setIsUseAssessRefAsPrimary(false);
    });
  };
}

const raiseInterestWorkflowStoreContext = createContext(
  new RaiseInterestWorkflowStore()
);
export const useRaiseInterestWorkflowStore = () => {
  return useContext(raiseInterestWorkflowStoreContext);
};
