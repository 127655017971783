import { getUrlSearchInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/config";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { useDebounce } from "@common/hooks/useDebounce";
import { getOptionInputPickerActionType } from "@common/pages/actions/[id]/components/child-screens/general/components/form-element/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export interface PickActionTypeDialogProps {
  onCloseDialog: () => void;
  onSubmit: (data: any) => void;
  productType: PRODUCT_TYPE;
  serviceStandardId?: number;
  selectedActionTypes?: any[];
}

export const PickActionTypeDialog = ({
  productType,
  onCloseDialog,
  onSubmit,
  serviceStandardId,
  selectedActionTypes,
}: PickActionTypeDialogProps) => {
  const options = getOptionInputPickerActionType(
    productType,
    serviceStandardId
  );

  const [searchKey, setSearchKey] = useState("");
  const [gridSelectedRows, setGridSelectedRows] = useState<any[]>(
    selectedActionTypes ?? []
  );
  const debouncedSearch = useDebounce(searchKey, 500);

  return (
    <CCDialog
      dialogWrapperClassName="cc-officer-picker-search"
      {...options.dialog}
      maxWidth="60%"
      maxHeight="80%"
      onClose={onCloseDialog}
      bodyElement={
        <div className="cc-search-result">
          <div className="cc-search-result-title">
            <CCLabel title="Search" />
            <Field
              name={"searchKey"}
              component={CCInput}
              placeholder="Search"
              onChange={(e: InputChangeEvent) => {
                setSearchKey(e.value);
              }}
            />
          </div>
          <div className="cc-search-result-body">
            <CCGrid
              {...options.grid}
              selectableMode={"multiple"}
              dataUrl={
                options.grid.dataUrl
                  ? debouncedSearch
                    ? getUrlSearchInputPicker(
                        debouncedSearch,
                        options.boxSearch.colSearch,
                        options.grid.dataUrl
                      )
                    : `${options.grid.dataUrl}?$count=true&`
                  : undefined
              }
              onSelectionChange={(dataItems) => {
                setGridSelectedRows(dataItems);
              }}
              selectedRows={gridSelectedRows}
            />
          </div>
        </div>
      }
      footerElement={
        <div className="cc-dialog-footer-actions-right">
          <Button className="cc-dialog-button" onClick={onCloseDialog}>
            Cancel
          </Button>
          <Button
            className="cc-dialog-button"
            themeColor="primary"
            disabled={gridSelectedRows.length === 0}
            onClick={() => onSubmit(gridSelectedRows)}
          >
            {serviceStandardId ? "Send for action" : "Select"}
          </Button>
        </div>
      }
    />
  );
};
