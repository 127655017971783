//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";

export const colHMFSRPremisesTypes = [
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Name,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.RiskClassification,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Flag_FSR,
    title: "FSR Send",
    width: 150,
  },
  {
    field: HMFieldMapping.FSR_BusinessClass_Name,
    title: "Business Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.FSR_BusinessClass_ENUM,
    title: "ID",
    width: 150,
  },
  {
    field: HMFieldMapping.FSR_PremisesType_Name,
    title: "Premises Type",
    width: 150,
  },
  {
    field: HMFieldMapping.FSR_PremisesType_ENUM,
    title: "ID",
    width: 150,
  },
];
