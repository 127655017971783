import { history } from "@/AppRoutes";
import {
  getApplicationCategorySummaryById,
  postApplicationCategorySummary,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/api";
import { ApplicationCategoriesFrom } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/config";
import { SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/constant";
import {
  ApplicationCategory,
  ApplicationCategoryForm,
  IPPRApplicationCategoryParentSection,
} from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";
import { convertToFormData } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { DBRowState } from "@common/models/baseClassStandard";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isArray } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ApplicationCategoriesStore {
  private _applicationCategories?: ApplicationCategoryForm = undefined;
  private _applicationCategoriesLOVs?: any = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _isInactive: boolean = false;
  private _parentSection?: IPPRApplicationCategoryParentSection = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parentSection() {
    return this._parentSection;
  }
  setParentSection = (parentSection: IPPRApplicationCategoryParentSection) => {
    runInAction(() => {
      this._parentSection = parentSection;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get applicationCategories() {
    return toJS(this._applicationCategories);
  }
  setApplicationCategories = (applicationCategories?: any) => {
    runInAction(() => {
      this._applicationCategories = applicationCategories;
    });
  };

  get applicationCategoriesLOVs() {
    return toJS(this._applicationCategoriesLOVs);
  }
  setApplicationCategoriesLOVs = (applicationCategoriesLOVs?: any) => {
    runInAction(() => {
      this._applicationCategoriesLOVs = applicationCategoriesLOVs;
    });
  };

  loadApplicationCategories = async (
    applicationCategoriesId?: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    if (isNew || !applicationCategoriesId) {
      this.setApplicationCategories(ApplicationCategoriesFrom);
    } else {
      const applicationCategoryRes = await getApplicationCategorySummaryById(
        applicationCategoriesId
      );
      if (this.parentSection?.notification) {
        this.parentSection?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
      }
      let newApplicationCategory = undefined;
      if (
        isSuccessResponse(applicationCategoryRes) &&
        applicationCategoryRes.data
      ) {
        newApplicationCategory = convertToFormData(applicationCategoryRes.data);
        if (
          applicationCategoryRes.data.Sys_DBRowState === DBRowState.Inactive
        ) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }

      this.setApplicationCategories(newApplicationCategory);
    }

    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  get applicationCategoriesId() {
    return toJS(this.applicationCategories?.ApplicationCategory_ID);
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._applicationCategories = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isInactive = false;
      this._parentSection = undefined;
    });
  };

  saveApplicationCategories = async (pprInfo: ApplicationCategory) => {
    this.setIsLoading(true);
    if (isArray(pprInfo.WorkFlowAutoActionTypes))
      pprInfo.WorkFlowAutoActionTypeIDs = pprInfo.WorkFlowAutoActionTypes.map(
        (autoAction: any) => parseInt(autoAction.ID)
      );
    pprInfo.WorkFlowAutoActionTypes = [];
    const response = await postApplicationCategorySummary(pprInfo);
    this.setIsLoading(false);

    if (isSuccessIdentityPacket(response)) {
      history.replace(
        `${SYSTEM_ADMIN_APPLICATION_CATEGORIES_ROUTE}/${response?.data?.ID}`,
        {
          notification: [
            {
              title: "Application saved successfully",
              type: "success",
            },
          ],
        } as IPPRApplicationCategoryParentSection
      );
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "PPR save failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };
}

export const ApplicationCategoriesStoreInstance =
  new ApplicationCategoriesStore();
const ApplicationCategoriesStoreContext = createContext(
  ApplicationCategoriesStoreInstance
);
export const useApplicationCategoriesStore = () => {
  return useContext(ApplicationCategoriesStoreContext);
};
