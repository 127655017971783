import { DTO_Component_GIS_Reference } from "@app/products/property/components/gis-accordion/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getGISAccordion = async (
  apiUrl: string
): Promise<APIResponse<DTO_Component_GIS_Reference | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_GIS_Reference>(
      apiUrl
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
