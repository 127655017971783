import { IRegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { NumberCell } from "@components/cc-grid/components/grid-cells/number/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<IRegisterTransactions>();

export const colRegisterTransactions: IColumnFields[] = [
  {
    field: nameOf("DateTime"),
    title: "Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("TransType"),
    title: "Transactions Type",
  },
  {
    field: nameOf("TransactionAmount"),
    title: "Transaction Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("TransactionAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("ProgressiveTotal"),
    title: "Progressive Total",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Reference"),
    title: "Reference",
  },
  {
    field: nameOf("Description"),
    title: "Description",
    footerCell: <TextCell value="Overall Total" />,
  },
  {
    field: nameOf("Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER2,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Quantity", gridData),
    footerCell: <NumberCell style={formatStyleProminent} />,
  },
  {
    field: nameOf("ReceiptNumber"),
    title: "Receipt Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Notes"),
    title: "Notes",
  },
  {
    field: nameOf("JournalNumber"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("SessionNumber"),
    title: "Session Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("UserId"),
    title: "User ID",
  },
  {
    field: nameOf("IsProposed"),
    title: "Is Proposed?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsCancelled"),
    title: "Is Cancelled?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("TransactionId"),
    title: "Transaction ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
