import { nameOfLov } from "@app/products/property/model";
import {
  colCost,
  colInterestAndInstalmentDetailsGL,
  colInterestRateAndPenaltyRate,
  mockInstalmentType,
  mockSchemeType,
} from "@app/products/property/schemes/[id]/components/child-screens/general/config";
import {
  ISchemeFormGeneral,
  ISchemesInterestRate,
} from "@app/products/property/schemes/[id]/model";
import {
  DATE_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { useIsEditable } from "@common/hooks/useIsEditable";
import { useIsNew } from "@common/hooks/useIsNew";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray, FormRenderProps } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import React, { ReactElement, useMemo } from "react";

interface Props {
  formRenderProps: FormRenderProps;
}
const KEY_NAME = "General";

export const GeneralFormElement = (props: Props) => {
  return <FieldArray name={KEY_NAME} {...props} component={SchemeGeneral} />;
};

const SchemeGeneral = ({ formRenderProps }: Props): ReactElement => {
  const isNew = useIsNew();
  const isEditable = useIsEditable();

  const canEdit = useMemo(() => {
    if (!isNew && !isEditable) return false;

    return isNew || isEditable;
  }, [isNew, isEditable]);

  const getNameOf = (name?: keyof ISchemeFormGeneral) => {
    if (name) {
      return `${KEY_NAME}.${name}`;
    }
    return KEY_NAME;
  };

  const nameOf = nameOfFactory<ISchemesInterestRate>();

  const { valueGetter, onChange } = formRenderProps;
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
  };

  return (
    <div className="cc-property-scheme-general">
      {/* Reference by a label in WastewaterActionBar.tsx */}
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      <section className="cc-field-group">
        <div className="cc-form-cols-4">
          <div className="cc-field">
            <label className="cc-label">Scheme number</label>
            <Field
              name={getNameOf("SchemeNumber")}
              placeholder="Scheme number"
              component={CCInput}
              disabled={!canEdit}
            />
          </div>
          {/* readOnly */}
          <div className="cc-field">
            <label className="cc-label">Scheme ID</label>
            <Field
              name={getNameOf("SchemeId")}
              placeholder="Scheme ID"
              component={CCInput}
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Is active</label>
            <Field
              name={getNameOf("IsActive")}
              checked={valueGetter(getNameOf("IsActive"))}
              component={CCSwitch}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Suppress processing</label>
            <Field
              name={getNameOf("SuppressProcessing")}
              checked={valueGetter(getNameOf("SuppressProcessing"))}
              component={CCSwitch}
              disabled={!canEdit}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Name</label>
            <Field
              name={getNameOf("Name")}
              placeholder="Name"
              component={CCInput}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Scheme type</label>
            <Field
              placeholder="Scheme type"
              name={getNameOf("SchemeType")}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              component={CCSearchComboBox}
              data={mockSchemeType}
              disabled={!canEdit}
              value={getDropdownValue(
                valueGetter(getNameOf("SchemeType")),
                mockSchemeType,
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(getNameOf("SchemeType"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Comments</label>
            <Field
              placeholder="Comments"
              name={getNameOf("Comments")}
              component={CCTextArea}
              rows={3}
              disabled={!canEdit}
            />
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <label className="cc-label">Dates</label>

        <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <label className="cc-label">Commenced</label>
            <Field
              name={getNameOf("DateCommenced")}
              component={CCDatePicker}
              format={DATE_FORMAT.DATE_CONTROL}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Initiated</label>
            <Field
              name={getNameOf("DateInitiated")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Declared</label>
            <Field
              name={getNameOf("DateDeclared")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={!canEdit}
            />
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        {!canEdit ? (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                data={valueGetter(getNameOf("Costs"))}
                columnFields={colCost}
                primaryField="Costs"
              />
            </div>
          </div>
        ) : (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCGrid
                data={valueGetter(getNameOf("Costs"))}
                columnFields={colCost}
                primaryField="Costs"
                editableMode="cell"
                onDataChange={(dataItem: any) => {
                  onChange(getNameOf("Costs"), { value: dataItem });
                }}
              />
            </div>
          </div>
        )}
      </section>
      <br />
      <section className="cc-field-group">
        <label className="cc-label">Interest and instalment details</label>
        <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <label className="cc-label">Instalment type</label>
            <Field
              placeholder="Instalment type"
              name={getNameOf("InstalmentType")}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              component={CCSearchComboBox}
              disabled={!canEdit}
              data={mockInstalmentType}
              value={getDropdownValue(
                valueGetter(getNameOf("InstalmentType")),
                mockInstalmentType,
                nameOfLov("Code")
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(getNameOf("InstalmentType"), {
                  value: event.target.value?.Code ?? null,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-4 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <label className="cc-label">
              Show on assessment amount owing form
            </label>
            <Field
              name={getNameOf("ShowOnAssessmentAmountOwingForm")}
              checked={valueGetter(
                getNameOf("ShowOnAssessmentAmountOwingForm")
              )}
              component={CCSwitch}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Include in assessment amount owing balances
            </label>
            <Field
              name={getNameOf("IncludeInAssessmentAmountOwingBalances")}
              checked={valueGetter(
                getNameOf("IncludeInAssessmentAmountOwingBalances")
              )}
              component={CCSwitch}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              Raise interest on last day on month
            </label>
            <Field
              name={getNameOf("RaiseInterestOnLastDayOnMonth")}
              checked={valueGetter(getNameOf("RaiseInterestOnLastDayOnMonth"))}
              component={CCSwitch}
              disabled={!canEdit}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Using penalty interest</label>
            <Field
              name={getNameOf("UsingPenaltyInterest")}
              checked={valueGetter(getNameOf("UsingPenaltyInterest"))}
              component={CCSwitch}
              disabled={!canEdit}
            />
          </div>
        </div>
        <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <CCGrid
              data={valueGetter(getNameOf("SchemesInterestRate"))}
              columnFields={colInterestRateAndPenaltyRate}
              primaryField="Id"
              disableRowField={nameOf("IsDisabledRow")}
            />
          </div>
        </div>
        <section className="cc-field-group cc-custom-sub-panel-bar">
          <label className="cc-label">Interest raising</label>
          <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
            <div className="cc-field">
              <label className="cc-label">Raise interest every</label>
              <div className="cc-custom-input-group">
                <Field
                  name={getNameOf("RaiseInterestEvery")}
                  placeholder="Raise interest every"
                  component={NumericTextBox}
                  disabled={!canEdit}
                  min={0}
                />
                <div className="cc-input-group-postfix">month(s)</div>
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Interest to be next raised on</label>
              <Field
                name={getNameOf("InterestToBeNextRaisedOn")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={!canEdit}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Interest was last raised on</label>
              <Field
                name={getNameOf("InterestWasLastRaisedOn")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled
              />
            </div>
          </div>
        </section>
        <section className="cc-field-group cc-custom-sub-panel-bar">
          <label className="cc-label">Instalment parameters</label>
          <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
            <div className="cc-field">
              <label className="cc-label">Instalment frequency</label>
              <div className="cc-custom-input-group">
                <Field
                  name={getNameOf("InstalmentFrequency")}
                  placeholder="Instalment frequency"
                  component={NumericTextBox}
                  disabled={!canEdit}
                  min={0}
                />
                <div className="cc-input-group-postfix">month(s)</div>
              </div>
            </div>
            <div className="cc-field">
              <label className="cc-label">Payment commencement</label>
              <Field
                name={getNameOf("PaymentCommencement")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={!canEdit}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Next instalment date</label>
              <Field
                name={getNameOf("NextInstalmentDate")}
                format={DATE_FORMAT.DATE_CONTROL}
                component={CCDatePicker}
                disabled={!canEdit}
              />
            </div>
          </div>
          <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
            <div className="cc-field">
              <label className="cc-label">Number of instalments</label>
              <Field
                name={getNameOf("NumberOfInstalments")}
                placeholder="Number of instalments"
                component={NumericTextBox}
                disabled={!canEdit}
                min={0}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Days to pay instalment</label>
              <Field
                name={getNameOf("DaysToPayInstalment")}
                placeholder="Days to pay instalment"
                component={NumericTextBox}
                disabled={!canEdit}
                min={0}
              />
            </div>
          </div>
        </section>
        <section className="cc-field-group cc-custom-sub-panel-bar">
          <label className="cc-label">Discount on payout</label>
          <div className="cc-form-cols-3 cc-custom-sub-panel-bar">
            <div className="cc-field">
              <label className="cc-label">Percentage</label>
              <Field
                name={getNameOf("DiscountOnPayout")}
                component={NumericTextBox}
                placeholder="Percentage"
                format={PERCENTAGE_FORMAT.PERCENTAGE}
                disabled={!canEdit}
                min={0}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">If paid by</label>
              <Field
                name={getNameOf("DiscountOnPayoutIfPaidBy")}
                component={CCInput}
                placeholder="If paid by"
                disabled={!canEdit}
              />
            </div>
          </div>
        </section>
        <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
          <div className="cc-field">
            <CCGrid
              data={valueGetter(getNameOf("InterestAndInstalmentDetailsGL"))}
              columnFields={colInterestAndInstalmentDetailsGL}
              primaryField={"GLAccountType"}
              itemPerPage={6}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
