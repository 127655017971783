// import { DTO_Certificate_Detail_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/certificate-details/model";
import { DTO_Rates_Department_Details_LOVs } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class RateDepartmentDetailStepStore {
  private _rateDepartmentDetailStepLOVs?: DTO_Rates_Department_Details_LOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get rateDepartmentDetailStepLOVs() {
    return toJS(this._rateDepartmentDetailStepLOVs);
  }
  setRateDepartmentDetailStepLOVs = (
    data?: DTO_Rates_Department_Details_LOVs
  ) => {
    runInAction(() => {
      this._rateDepartmentDetailStepLOVs = data;
    });
  };
}

const rateDepartmentDetailStepStoreContext = createContext(
  new RateDepartmentDetailStepStore()
);
export const useRateDepartmentDetailStepStore = () => {
  return useContext(rateDepartmentDetailStepStoreContext);
};
