import { mockGeneralLedgerAccounts } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/mock";
import { GeneralLedgerAccountsResponse } from "@app/products/property/schemes/[id]/components/forms/existed/components/action-bar/buttons/edit-gl-account/model";
import { sleep } from "@common/utils/common";

export const loadGeneralLedgerAccountsBySchemeId = async (
  id: string
): Promise<GeneralLedgerAccountsResponse> => {
  await sleep(1000);
  return {
    SchemeId: id,
    GeneralLedgerAccounts: mockGeneralLedgerAccounts,
  };
};
