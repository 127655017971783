import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const noticesRoute: ICCRoute = {
  path: "notices",
  name: "Notices",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_Notice,
  children: [
    {
      path: "by-date",
      name: "By Date",
      enum: CRMSViews.Notices_ByDate,
      component: require("./by-date/_index").default,
    },
    {
      path: "by-due-status",
      name: "By Due Status",
      enum: CRMSViews.Notices_ByDueStatus,
      component: require("./by-due-status/_index").default,
    },
  ],
};
