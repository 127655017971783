import { DTO_Assessment_ChargeBalance } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadAssessmentChargeBalanceDetail = async (
  assessmentId: number
): Promise<APIResponse<DTO_Assessment_ChargeBalance | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_ChargeBalance>(
      `/api/property/internal/assessment/${assessmentId}/chargebalance`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
