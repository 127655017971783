import { loadAssociateMeter } from "@app/products/property/registers/[id]/components/dialogs/associate-meter/api";
import { IAssociateMeter } from "@app/products/property/registers/[id]/components/dialogs/associate-meter/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSearchMeter } from "@components/cc-search-meter";
import { MeterSearchBy } from "@components/cc-search-meter/config";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IAssociateMeter>();

export interface IAssociateMeterDialog {
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export const AssociateMeterDialog = observer(
  ({ onClose, onSubmit }: IAssociateMeterDialog) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
    const [initialValues, setInitialValues] = useState<IAssociateMeter>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const params: { id: string } = useParams();
    const registerId = params.id;

    useEffectOnce(() => {
      setIsLoading(true);
      loadAssociateMeter(+registerId).then((data) => {
        if (data) {
          setInitialValues(data);
        } else {
          notificationRef.current?.pushNotification({
            title: `An API error occurred.`,
            type: "error",
          });
        }
        setIsLoading(false);
      });
    });

    const handleSubmit = async (data: any) => {
      onSubmit(data);
    };

    return (
      <Form
        onSubmit={(data) => {
          data._option = undefined;
          handleSubmit(data);
        }}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onSubmit, modified } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth="40%"
                height="auto"
                titleHeader="Meter Account Association"
                onClose={onClose}
                bodyElement={
                  <div className="cc-form">
                    <CCLocalNotification ref={notificationRef} />
                    {isLoading ? (
                      <Loading isLoading={isLoading} />
                    ) : (
                      <section className="cc-field-group">
                        <section>
                          <label className="cc-label">
                            Account being associated
                          </label>
                          <div className="cc-custom-sub-panel-bar">
                            <section className="cc-field-group">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">
                                    Account reference
                                  </label>
                                  <Field
                                    name={nameOf("AccountReference")}
                                    component={CCInput}
                                    placeholder="Account reference"
                                    readOnly
                                  />
                                </div>

                                <div className="cc-field">
                                  <label className="cc-label">Account ID</label>
                                  <Field
                                    name={nameOf("AccountId")}
                                    component={CCInput}
                                    placeholder="Account ID"
                                    readOnly
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Property address
                                  </label>
                                  <Field
                                    name={nameOf("PropertyAddress")}
                                    component={CCInput}
                                    placeholder="Property address"
                                    readOnly
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Account names
                                  </label>
                                  <Field
                                    name={nameOf("AccountNames")}
                                    component={CCInput}
                                    placeholder="Account names"
                                    readOnly
                                  />
                                </div>
                              </div>
                            </section>
                          </div>
                        </section>

                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">
                              Date of attachment
                            </label>
                            <Field
                              name={nameOf("DateOfAttachment")}
                              format={DATETIME_FORMAT.DATETIME_CONTROL}
                              component={CCDateTimePicker}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Usage entitlement
                            </label>
                            <Field
                              name={nameOf("UsageEntitlement")}
                              component={CCNumericTextBox}
                              min={1}
                              placeholder="Usage entitlement"
                            />
                          </div>

                          <div className="cc-field">
                            <label className="cc-label">Billing group</label>
                            <Field
                              name={nameOf("BillingGroup")}
                              data={valueGetter("_option.BillingGroup.Data")}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                        </div>

                        <section>
                          <label className="cc-label">
                            Meter being attached
                          </label>
                          <div className="cc-custom-sub-panel-bar">
                            <section className="cc-field-group">
                              <div className="cc-form-cols-2">
                                <div className="cc-field">
                                  <label className="cc-label">Search by</label>
                                  <Field
                                    name={"_option.MeterSearchBy.Value"}
                                    data={valueGetter(
                                      "_option.MeterSearchBy.Data"
                                    )}
                                    component={DropDownList}
                                    textField="Value"
                                    dataItemKey="Key"
                                  />
                                </div>

                                <div className="cc-field">
                                  <label className="cc-label">
                                    Search meter
                                  </label>
                                  <Field
                                    name={nameOf("MeterSearchResult")}
                                    component={CCSearchMeter}
                                    searchBy={
                                      valueGetter(
                                        "_option.MeterSearchBy.Value"
                                      ) || MeterSearchBy[0]
                                    }
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Property address
                                  </label>
                                  <Field
                                    name={"MeterSearchResult.PropertyAddress"}
                                    component={CCInput}
                                    placeholder="Property address"
                                  />
                                </div>

                                <div className="cc-field cc-col-span-2">
                                  <label className="cc-label">
                                    Account names
                                  </label>
                                  <Field
                                    name={"MeterSearchResult.AccountName"}
                                    component={CCInput}
                                    placeholder="Account names"
                                  />
                                </div>
                              </div>
                            </section>
                          </div>
                        </section>
                      </section>
                    )}
                  </div>
                }
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    <Button className={"cc-dialog-button"} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={onSubmit}
                      disabled={!modified}
                    >
                      OK
                    </Button>
                  </div>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
