import { loadModifyRegister } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/modify-register/api";
import { mockModifyRegister } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/modify-register/mock";
import {
  IModifyRegister,
  IModifyRegisterData,
} from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/dialogs/modify-register/model";
import { APIResponseError } from "@common/apis/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { APIResponseStatus } from "@common/constants/response-status";
import { nameOfFactory } from "@common/utils/common";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<IModifyRegister>();

interface IModifyRegisterDialogProps {
  onClose: () => void;
  onSubmit: (data: IModifyRegister) => void;
}

export const ModifyRegisterDialog = ({
  onClose,
  onSubmit,
}: IModifyRegisterDialogProps) => {
  const [initialValues, setInitialValues] = useState<IModifyRegisterData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadInitialValue = () => {
    setIsLoading(true);
    setInitialValues(undefined);
    loadModifyRegister().then((data) => {
      setInitialValues(data);
      setResponseLoadError(
        !data
          ? {
              status: APIResponseStatus.NOT_FOUND,
            }
          : undefined
      );
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadInitialValue();
  });

  return (
    <Form
      onSubmit={(data) => {
        data._option = undefined;
        onSubmit(data);
      }}
      initialValues={mockModifyRegister}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter, onSubmit, modified } = formRenderProps;
        const isAutoUpdatePropertyAddress = valueGetter(
          nameOf("AutomaticallyUpdatePropertyAddressFromParcels")
        );
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="40%"
              titleHeader={"Modify Register"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  {isLoading ? <Loading isLoading={isLoading} /> : null}
                  {responseLoadError && !isLoading ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={loadInitialValue}
                    />
                  ) : null}

                  {initialValues ? (
                    <section className="cc-field-group">
                      <div className="cc-form-cols-3">
                        <div className="cc-field cc-col-span-1">
                          <label className="cc-label">Date & time</label>
                          <Field
                            name={nameOf("DateTime")}
                            format={DATETIME_FORMAT.DATETIME_CONTROL}
                            component={CCDateTimePicker}
                          />
                        </div>
                      </div>
                      <div className="cc-form-cols-3">
                        <div className="cc-field">
                          <label className="cc-label">Account reference</label>
                          <Field
                            name={nameOf("AccountReference")}
                            component={CCInput}
                            placeholder="Account reference"
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Category</label>
                          <Field
                            name={nameOf("Category")}
                            data={valueGetter("_option.Category.Data") || []}
                            textField="Value"
                            dataItemKey="Key"
                            component={CCSearchComboBox}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Status</label>
                          <Field
                            name={nameOf("Status")}
                            data={valueGetter("_option.Status.Data") || []}
                            textField="Value"
                            dataItemKey="Key"
                            component={CCSearchComboBox}
                          />
                        </div>
                        <div className="cc-field cc-col-span-3">
                          <label className="cc-label">Description</label>
                          <Field
                            name={nameOf("Description")}
                            component={CCTextArea}
                            placeholder="Description"
                            rows={3}
                          />
                        </div>
                      </div>
                      <section>
                        <label className="cc-label">Property</label>
                        <div className="cc-custom-sub-panel-bar">
                          <section className="cc-field-group">
                            <div className="cc-form-cols-3">
                              <div className="cc-field">
                                <label className="cc-label">
                                  Automatically update property address from
                                  parcels
                                </label>
                                <Field
                                  name={nameOf(
                                    "AutomaticallyUpdatePropertyAddressFromParcels"
                                  )}
                                  component={CCSwitch}
                                  defaultChecked={isAutoUpdatePropertyAddress}
                                />
                              </div>
                              <div className="cc-field cc-col-span-2">
                                <label className="cc-label">
                                  Property name
                                </label>
                                <Field
                                  name={nameOf("PropertyName")}
                                  component={CCInput}
                                  placeholder="Property name"
                                  disabled={isAutoUpdatePropertyAddress}
                                />
                              </div>
                              <div className="cc-field cc-col-span-3">
                                <label className="cc-label">Address</label>
                                <Field
                                  name={nameOf("Address")}
                                  component={CCTextArea}
                                  placeholder="Address"
                                  rows={3}
                                  disabled={isAutoUpdatePropertyAddress}
                                />
                              </div>
                              <div className="cc-field cc-col-span-2">
                                <label className="cc-label">Locality</label>
                                <Field
                                  name={nameOf("Locality")}
                                  data={
                                    valueGetter("_option.Locality.Data") || []
                                  }
                                  textField="Value"
                                  dataItemKey="Key"
                                  disabled={isAutoUpdatePropertyAddress}
                                  component={CCSearchComboBox}
                                />
                              </div>
                              <div className="cc-field">
                                <label className="cc-label">Postcode</label>
                                <Field
                                  name={nameOf("Postcode")}
                                  component={CCInput}
                                  placeholder="Postcode"
                                  disabled={isAutoUpdatePropertyAddress}
                                />
                              </div>
                            </div>
                          </section>
                        </div>
                      </section>
                    </section>
                  ) : null}
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className="cc-dialog-button"
                    themeColor="primary"
                    onClick={onSubmit}
                    disabled={!modified || !initialValues}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
