import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewSchemeButton = () => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New Scheme"
      onClick={() => {
        history.push(`${PROPERTY_SCHEMES_ROUTE}/new`);
      }}
    />
  );
};
