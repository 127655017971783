import { RegionForm } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/child-screens/general/_index";
import { useRegionStore } from "@app/products/town-planning/ppr/system-admin/regions/[id]/store";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewRegion = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { region, loadRegion, isLoading, responseLoadError } = useRegionStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Region"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadRegion(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New Region" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                htmlFor="btn-submit"
                name={PPRSubmitActions.Save}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {region && <RegionForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
