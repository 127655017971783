export const meterInstallation = [
  {
    MeterNo: "C013225",
    Installation: [{ Code: 1, Name: ",Proserpine" }],
  },
  {
    MeterNo: "C013226",
    Installation: [
      { Code: 1, Name: ",Proserpine" },
      { Code: 2, Name: "Item mock 1" },
    ],
  },
  {
    MeterNo: "C013227",
    Installation: [
      { Code: 1, Name: ",Proserpine" },
      { Code: 2, Name: "Item mock 1" },
      { Code: 3, Name: "Item mock 2" },
    ],
  },
  {
    MeterNo: "C013228",
    Installation: [],
  },
];
