import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colDraft: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 250,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.CRMSEventReferenceNumber,
    title: "CRMS Event Number",
    width: 250,
    dataType: "text",
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 300,
    dataType: "text",
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 250,
    dataType: "text",
  },
  {
    field: TownPlanningFieldMapping.Postcode,
    title: "Postcode",
    width: 250,
    dataType: "numeric",
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 250,
    dataType: "text",
  },
  {
    field: TownPlanningFieldMapping.Applicant,
    title: "Applicant",
    width: 250,
    dataType: "text",
  },
  {
    field: TownPlanningFieldMapping.ApplicantAddress,
    title: "Address",
    width: 250,
    dataType: "text",
  },
];
