import { CCActionBarNavDropdownNew } from "@components/cc-list-view-action-bar/components/nav-dropdown-new/_index";
import React from "react";

interface IHMActionBarNavDropdownProps {
  category: string;
}

export const HMActionBarNavDropdown = ({
  // keep this props
  // because it still uses for another lists - have not implemented yet
  category,
}: IHMActionBarNavDropdownProps) => {
  return <CCActionBarNavDropdownNew />;
};
