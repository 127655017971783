import { TransactionsDetail } from "@app/products/property/assessments/[id]/components/child-screens/transactions/components/detail/_index";
import { useAssessmentTransactionStore } from "@app/products/property/assessments/[id]/components/child-screens/transactions/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { DTO_Transaction } from "@app/products/property/model";
import { nameOfFactory } from "@common/utils/common";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const nameOf = nameOfFactory<DTO_Transaction>();
export const Transactions = observer(() => {
  const { assessmentId } = useAssessmentStore();
  const {
    isLoading,
    isGridLoading,
    gridData,
    columnFields,
    gridDataState,
    views,
    view,
    showCancelled,
    setView,
    setShowCancelled,
    setGridSelectedRows,
    loadViews,
    loadTransactions,
    resetStore,
    responseLoadError,
  } = useAssessmentTransactionStore();

  useEffectOnce(() => {
    loadViews();
    return () => resetStore();
  });

  useEffect(() => {
    loadTransactionData();
    // eslint-disable-next-line
  }, [showCancelled, view, assessmentId, loadTransactions]);

  const loadTransactionData = () => {
    if (!view || assessmentId === undefined) return;
    const year = view.FinancialYear ? view.FinancialYear.Year : undefined;
    loadTransactions(assessmentId, year, view.Id, showCancelled);
  };

  if (isLoading) return <Loading isLoading />;
  return (
    <div className="cc-assessment-transactions">
      <div className="cc-grid-control-left">
        <label className="cc-control-item cc-label">View</label>
        <CCDropDownList
          className="cc-control-item cc-transactions-view"
          data={views ?? []}
          textField="Name"
          value={view}
          dataItemKey="Key"
          disabled={isGridLoading}
          onChange={(event: DropDownListChangeEvent) => {
            setView(event.target.value);
          }}
        />
        <Checkbox
          className="cc-control-item"
          title="Show Cancelled Transactions"
          label="Show cancelled transactions"
          disabled={isGridLoading}
          checked={showCancelled}
          onChange={(event: CheckboxChangeEvent) => {
            setShowCancelled(event.value);
          }}
        />
      </div>
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadTransactionData()}
        />
      ) : (
        <CCGrid
          isLoading={isGridLoading}
          state={gridDataState}
          className="cc-transactions-grid"
          data={gridData ?? []}
          columnFields={columnFields}
          primaryField={nameOf("TransactionId")}
          detail={TransactionsDetail}
          selectableMode="multiple"
          onSelectionChange={(dataItem: DTO_Transaction[]) => {
            if (dataItem) setGridSelectedRows(dataItem);
          }}
        />
      )}
    </div>
  );
});
