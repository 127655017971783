import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsAssessmentLandRentAction {
  Details = "Details",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsAssessmentLandRentAction = [
  EKeysOfStepsAssessmentLandRentAction.Details,
];

export interface DTO_Workflow_AssessmentLandRentAction {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_AssessmentLandRentAction;
}

export interface DTO_WorkflowDetail_AssessmentLandRentAction {
  Details: DTO_AssessmentLandRentAction;
}

// Recheck dto after have api
export interface DTO_AssessmentLandRentAction {
  Assessment_Id: number;
  Rating_Period?: number;
  Period_Start_Date?: Date | null;
  Period_End_Date?: Date | null;
  Land_Rent_Action_Type?: number;
  Actioned_Date?: Date | null;
  Valuation?: number;
}

export interface DTO_AssessmentLandRentAction_LOVs {
  RatingPeriod: DTO_LOV[];
  LandRentActionType: DTO_LOV[];
}
