import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { generateEmbeddedReportToken, getPowerBIReport } from "@common/pages/report/powerbi/list/api";
import { isUndefined } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class PowerBIReportStore {
  private _report?: any = undefined;
  private _tokenEmbed: string = "";
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get report() {
    return this._report;
  }
  setReport = (report?: any) => {
    runInAction(() => {
      this._report = report;
    });
  };

  get tokenEmbed() {
    return this._tokenEmbed;
  }
  setTokenEmbed = (tokenEmbed: string) => {
    runInAction(() => {
      this._tokenEmbed = tokenEmbed;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }

  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._report = undefined;
      this._tokenEmbed = "";
      this._responseLoadError = undefined;
    });
  };

  loadEmbedToken = async (
    idReportEmbed: string,
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const response = await generateEmbeddedReportToken(
      idReportEmbed
    );
    if (isSuccessResponse(response) && response?.data) {
      this.setTokenEmbed(response.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Get embed token failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };

  reloadReportWithoutState = async (reportId: string): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const response = await getPowerBIReport(reportId);
    if (isSuccessResponse(response) && response?.data) {
      this.setReport(response.data);
      this.loadEmbedToken(reportId);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Get report failed",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return isUndefined(errorResponse);
  };
}

export const powerBIReportStore = new PowerBIReportStore();
const PowerBIReportStoreContext = createContext(powerBIReportStore);
export const usePowerBIReportStore = () => {
  return useContext(PowerBIReportStoreContext);
};
