import {
  DTO_Workflow_JournalAddDDTransaction,
  RequestJournalAddDDTransactionObj,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/model";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessAddDeferredDutyTransaction = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_JournalAddDDTransaction
): Promise<APIResponse<any>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutytransactionadd/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialAddDeferredDutyTransaction =
  | [
      APIResponse<DTO_Journal_LOVs>,
      APIResponse<DTO_Workflow_JournalAddDDTransaction>
    ]
  | APIResponse<DTO_Journal_LOVs | DTO_Workflow_JournalAddDDTransaction>
  | undefined;
export const getInitialDataAddDeferredDutyTransaction = async (
  workflowDraftId: number | undefined,
  journalNumber: number = 0,
  isNewJournal: boolean = false
): Promise<IGetInitialAddDeferredDutyTransaction> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Journal_LOVs>(
        `api/property/internal/journal/lovs`
      ),
      !isNewJournal && !isNil(workflowDraftId)
        ? getWorkflowAddDeferredDutyTransaction(workflowDraftId)
        : getNewWorkflowAddDeferredDutyTransaction({
            JournalNumber: journalNumber,
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowAddDeferredDutyTransaction = async (
  requestObj: RequestJournalAddDDTransactionObj | object = {}
): Promise<APIResponse<DTO_Workflow_JournalAddDDTransaction>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/workflow/deferreddutytransactionadd/new`,
      requestObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowAddDeferredDutyTransaction = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_JournalAddDDTransaction>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/workflow/deferreddutytransactionadd/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
