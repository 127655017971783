import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { isSuccessResponse } from "@common/apis/util";
import { NARSYSTEM } from "@common/constants/enumerations";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { getSyncContactsFromNAR } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/nar/forms/name-and-address-integration-settings/api";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

const NameAndAddressIntegrationSettingsForm = ({
  formRenderProps,
}: ICommonFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { valueGetter } = formRenderProps;
  const [isLoadingSyncButton, setIsLoadingSyncButton] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const data = useDataSetting(configData, [
    "CorporateSettings_NARAPI",
    "CorporateSettings_NARSystem",
    "CorporateSettings_NARConnectionMethod",
    "NARConnectionString",
    "CorporateSettings_NAR_AutoPopup",
    "NAR_DetailSearchEnabled",
    "CorporateSettings_NAR_Custom_DOBFilter",
    "CorporateSettings_NAR_Custom_NARNumberFilter",
    "CorporateSettings_NAR_Custom_NARNumberAlphanumeric",
    "NARSQLQuery",
    "CorporateSettings_NAR_Custom_ListContacts_WHERE_Clause",
    "CorporateSettings_NAR_Custom_ListContacts_ORDERBY_Clause",
    "CorporateSettings_NAR_Custom_IDSearch_WHERE_Clause",
    "CorporateSettings_NARList_Search_WHERE_Clause",
    "CorporateSettings_NAR_Custom_Sync_Query",
    "CorporateSettings_NARCustomDynamic_SelectionQuery",
    "CorporateSettings_NARCustomDynamic_ReturnQuery",
  ]);

  const narSystem = valueGetter(
    data.CorporateSettings_NARSystem?.FieldName as string
  );
  const narAPI = valueGetter(
    data.CorporateSettings_NARAPI?.FieldName as string
  );
  const detailSearchEnabled = valueGetter(
    data.NAR_DetailSearchEnabled?.FieldName as string
  );

  const handleSyncClick = async (e: any) => {
    e.preventDefault();
    setIsLoadingSyncButton(true);
    const response = await getSyncContactsFromNAR();
    if (isSuccessResponse(response)) {
      pushNotification({
        type: "success",
        title: "Sync NAR contacts",
        description: `${response.data.SuccessCount} contacts synchronised with NAR`,
      });
    } else {
      pushNotification({
        type: "error",
        title: "Sync NAR contacts failed",
        autoClose: false,
        description: response.data?.Errors ?? response.error,
      });
    }
    setIsShowDialog(false);
    setIsLoadingSyncButton(false);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={data.CorporateSettings_NARAPI}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        {!narAPI && (
          <>
            <CCSearchComboBoxGlobalSettings
              data={data.CorporateSettings_NARSystem}
              isEditing={isEditing}
            />
            {(narSystem === NARSYSTEM.Custom.toString() ||
              narSystem === NARSYSTEM.CustomDynamic.toString()) && (
              <CCSearchComboBoxGlobalSettings
                data={data.CorporateSettings_NARConnectionMethod}
                isEditing={isEditing}
                disabled={narSystem === NARSYSTEM.CustomDynamic.toString()}
              />
            )}

            {narSystem !== NARSYSTEM.None.toString() && (
              <>
                <SwitchGlobalSettings
                  data={data.CorporateSettings_NAR_AutoPopup}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
                {narSystem === NARSYSTEM.PNR.toString() && (
                  <SwitchGlobalSettings
                    data={data.NAR_DetailSearchEnabled}
                    isEditing={isEditing}
                    formRenderProps={formRenderProps}
                  />
                )}
                {((narSystem === NARSYSTEM.PNR.toString() &&
                  detailSearchEnabled) ||
                  narSystem === NARSYSTEM.Custom.toString()) && (
                  <SwitchGlobalSettings
                    data={data.CorporateSettings_NAR_Custom_DOBFilter}
                    isEditing={isEditing}
                    formRenderProps={formRenderProps}
                  />
                )}
                {(narSystem === NARSYSTEM.PNR.toString() ||
                  narSystem === NARSYSTEM.Custom.toString() ||
                  narSystem === NARSYSTEM.CustomDynamic.toString()) && (
                  <>
                    <SwitchGlobalSettings
                      data={data.CorporateSettings_NAR_Custom_NARNumberFilter}
                      isEditing={isEditing}
                      formRenderProps={formRenderProps}
                      disabled={
                        narSystem === NARSYSTEM.CustomDynamic.toString()
                      }
                    />
                    <SwitchGlobalSettings
                      data={
                        data.CorporateSettings_NAR_Custom_NARNumberAlphanumeric
                      }
                      isEditing={isEditing}
                      formRenderProps={formRenderProps}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      {!narAPI && (
        <>
          {narSystem !== NARSYSTEM.None.toString() && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={data.NARConnectionString}
                isEditing={isEditing}
              />
            </div>
          )}

          {narSystem === NARSYSTEM.Custom.toString() && (
            <>
              <div className="cc-form-cols-1">
                <TextAreaGlobalSettings
                  data={data.NARSQLQuery}
                  isEditing={isEditing}
                  rows={10}
                />
                <TextAreaGlobalSettings
                  data={
                    data.CorporateSettings_NAR_Custom_ListContacts_WHERE_Clause
                  }
                  isEditing={isEditing}
                  rows={10}
                />
                <TextAreaGlobalSettings
                  data={
                    data.CorporateSettings_NAR_Custom_ListContacts_ORDERBY_Clause
                  }
                  isEditing={isEditing}
                  rows={2}
                />
                <TextAreaGlobalSettings
                  data={data.CorporateSettings_NAR_Custom_IDSearch_WHERE_Clause}
                  isEditing={isEditing}
                  rows={2}
                />
                <TextAreaGlobalSettings
                  data={data.CorporateSettings_NARList_Search_WHERE_Clause}
                  isEditing={isEditing}
                  rows={2}
                />
                <TextAreaGlobalSettings
                  data={data.CorporateSettings_NAR_Custom_Sync_Query}
                  isEditing={isEditing}
                />
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <div>
                    <Button
                      type="button"
                      disabled={!isEditing}
                      onClick={() => setIsShowDialog(true)}
                    >
                      {isLoadingSyncButton ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : null}
                      Sync
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
          {narSystem === NARSYSTEM.CustomDynamic.toString() && (
            <>
              <div className="cc-form-cols-1">
                <TextAreaGlobalSettings
                  data={data.CorporateSettings_NARCustomDynamic_SelectionQuery}
                  isEditing={isEditing}
                  rows={10}
                />
                <TextAreaGlobalSettings
                  data={data.CorporateSettings_NARCustomDynamic_ReturnQuery}
                  isEditing={isEditing}
                  rows={10}
                />
              </div>
            </>
          )}
          {isShowDialog && (
            <ConfirmDialog
              title="Confirm Sync"
              message={
                "This will start sync specified fields of NAR, continue?"
              }
              onClosePopup={() => {
                setIsShowDialog(false);
              }}
              onAsyncConfirm={(e) => {
                handleSyncClick(e);
              }}
            />
          )}
        </>
      )}
    </section>
  );
};

export default observer(NameAndAddressIntegrationSettingsForm);
