import { DateCell } from "@app/products/property/assessments/components/custom-cell/date-cell";
import { V_Debt_Recovery_Functionality } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/dialog/modify-functionality-restrictions/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<V_Debt_Recovery_Functionality>();
export const colDebtRecoveryModifyFunctionalityRestrictions: IColumnFields[] = [
  {
    field: nameOf("Functionality_Name"),
    title: "Functionality",
  },
  {
    field: nameOf("DRF_Restrict_From"),
    title: "From",
    editable: true,
    editCell: DateCell,
    format: DATE_FORMAT.DATE,
    width: 150,
  },
  {
    field: nameOf("DRF_Restrict_To"),
    title: "To",
    editable: true,
    editCell: DateCell,
    format: DATE_FORMAT.DATE,
    width: 150,
  },
];
