import { MemoDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/memo/_index";
import { IMeterMemo } from "@app/products/property/meters/[id]/components/child-screens/memos/model";
import { useMeterMemoStore } from "@app/products/property/meters/[id]/components/child-screens/memos/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

export const AddMemoButton = observer(() => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { addMemos } = useMeterMemoStore();
  const { pushNotification } = useCCAppNotificationStore();
  const handleAddMemo = useCallback(
    (data) => {
      addMemos(data as IMeterMemo);
      setShowDialog(false);
      pushNotification({
        title: `Memo added successfully`,
        type: "success",
      });
    },
    [addMemos, pushNotification]
  );
  return (
    <>
      <CCNavButton title="Memo" onClick={() => setShowDialog(true)} />
      {showDialog && (
        <MemoDialog
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={handleAddMemo}
        />
      )}
    </>
  );
});
