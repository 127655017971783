import { ImportValuationsDialog } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations/_index";
import { CommunityProperty } from "@common/stores/products/config";

import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";

export const ImportValuationsButton = observer(() => {
  const [isShowImportValuationsDialog, setIsShowImportValuationsDialog] =
    useState<boolean>(false);
  const { isGeneral, isActro } = CommunityProperty.getFlagOfStates();

  const renderDialog = useCallback(() => {
    if (!isShowImportValuationsDialog) return null;
    return (
      <ImportValuationsDialog
        onClose={() => {
          setIsShowImportValuationsDialog(false);
        }}
      />
    );
  }, [isShowImportValuationsDialog]);

  return isActro || isGeneral ? (
    <>
      <CCNavButton
        title="Import valuations"
        onClick={() => setIsShowImportValuationsDialog(true)}
      />
      {renderDialog()}
    </>
  ) : null;
});
