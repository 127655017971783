import { ConfirmReadingDialog } from "@app/products/property/meters/components/dialogs/confirm-reading/_index";
import { loadSearch } from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/api";
import {
  mockReadingOfficerData,
  mockReadingStatusData,
} from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/mock";
import {
  BUTTONS,
  MAX_READING,
  METER_STATUS,
  OPTIONS_SEARCH,
  textFieldMapping,
} from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/model";
import { handleMeterReadingValidateForm } from "@app/products/property/meters/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import { Button } from "@progress/kendo-react-buttons";
import { DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import {
  differenceInCalendarDays,
  intervalToDuration,
  subDays,
} from "date-fns";
import React, { useRef, useState } from "react";
interface IEnterReadingsDialog {
  onEnter: () => void;
  onEnterAndExit: () => void;
  onClose: () => void;
}

const searchByData = Object.values(OPTIONS_SEARCH);

const EnterReadingsDialog = ({
  onEnter,
  onEnterAndExit,
  onClose,
}: IEnterReadingsDialog) => {
  const [initialValues, setInitialValues] = useState<any>({
    SearchBy: OPTIONS_SEARCH.METER_NUMBER,
    CurrentReadingDate: new Date(),
  });
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [isEnter, setIsEnter] = useState(false);

  const handleOnSubmitClick = (values: any, event: any) => {
    if (event.target.name === BUTTONS.ENTER_AND_EXIT) {
      if (
        values?.CurrentReading <= values?.PreviousReading ||
        values?.CurrentReading > MAX_READING
      ) {
        setShowWarningDialog(true);
      } else {
        onEnterAndExit();
      }
    } else if (event.target.name === BUTTONS.ENTER) {
      setIsEnter(true);
      if (
        values?.CurrentReading <= values?.PreviousReading ||
        values?.CurrentReading > MAX_READING
      ) {
        setShowWarningDialog(true);
      } else {
        onEnter();
      }
    }
  };

  return (
    <Form
      onSubmit={(values: any, event: any) => handleOnSubmitClick(values, event)}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      validator={handleMeterReadingValidateForm}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter } = formRenderProps;
        const getFieldValue = valueGetter;
        const searchByValue = getFieldValue("SearchBy");

        const handleSearch = (event: ComboBoxFilterChangeEvent) => {
          onChange("Meter", { value: null });
          if (event.filter.value.length >= 1) {
            onChange("_option.Meter.Loading", { value: true });
            loadSearch(event.filter).then((data) => {
              onChange("_option.Meter.Data", { value: data });
              onChange("_option.Meter.Loading", { value: false });
            });
          } else {
            onChange("_option.Meter.Data", { value: [] });
          }
        };

        const handleSearchResult = (event: ComboBoxChangeEvent) => {
          if (!event.value) return;
          if (event.value?.Status !== METER_STATUS.ACTIVE) {
            notificationRef.current?.pushNotification({
              title: `Meter has status ${event.value?.Status}`,
              type: "warning",
            });
          } else {
            onChange("Meter", { value: event.value });
            onChange("ReadingStatus", { value: event.value?.ReadingStatus });
            onChange("PropertyAddress", {
              value: event.value?.PropertyAddress,
            });
            onChange("PreviousReading", {
              value: event.value?.PreviousReading,
            });
            onChange("PreviousReadingDate", {
              value: event.value?.PreviousReadingDate,
            });
            onChange("CurrentReadingDate", {
              value: getFieldValue("CurrentReadingDate") ?? new Date(),
            });
            onChange("CurrentReading", {
              value: getFieldValue("CurrentReading") ?? null,
            });
            if (getFieldValue("CurrentReadingDate")) {
              onChange("NumberOfDays", {
                value: intervalToDuration({
                  start: getFieldValue("PreviousReadingDate"),
                  end: getFieldValue("CurrentReadingDate"),
                }).days,
              });
            }
            if (getFieldValue("CurrentReading")) {
              onChange("Usage", {
                value:
                  getFieldValue("CurrentReading") >=
                  getFieldValue("PreviousReading")
                    ? getFieldValue("CurrentReading") -
                      getFieldValue("PreviousReading")
                    : MAX_READING -
                      getFieldValue("PreviousReading") +
                      getFieldValue("CurrentReading"),
              });
            }
          }
        };

        const handleOnChangeCurrentReadingDate = (
          event: DateTimePickerChangeEvent
        ) => {
          onChange("CurrentReadingDate", {
            value: event.target.value,
          });
          if (
            getFieldValue("PreviousReadingDate") &&
            getFieldValue("CurrentReadingDate")
          ) {
            onChange("NumberOfDays", {
              value: Math.max(
                differenceInCalendarDays(
                  valueGetter("CurrentReadingDate"),
                  valueGetter("PreviousReadingDate")
                ),
                0
              ),
            });
          }
        };

        const handleOnChangeCurrentReading = (
          event: NumericTextBoxChangeEvent
        ) => {
          onChange("CurrentReading", {
            value: event.target.value,
          });

          const previousReading = getFieldValue("PreviousReading");
          const currentReading = getFieldValue("CurrentReading");

          if (previousReading && typeof currentReading === "number") {
            onChange("Usage", {
              value:
                currentReading >= previousReading
                  ? currentReading - previousReading
                  : MAX_READING - previousReading + currentReading,
            });
          }
        };

        return (
          <>
            <CCDialog
              titleHeader={"Manual Meter Reading"}
              onClose={onClose}
              maxHeight="70%"
              maxWidth="45%"
              bodyElement={
                <div className="cc-form">
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <CCLocalNotification ref={notificationRef} />
                    <section>
                      <label className="cc-label">Search meter</label>
                      <div className="cc-custom-sub-panel-bar cc-estate">
                        <section className="cc-field-group">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">Search by</label>
                              <Field
                                name={"SearchBy"}
                                component={DropDownList}
                                data={searchByData}
                                onChange={(event: DropDownListChangeEvent) => {
                                  setInitialValues({
                                    SearchBy: event.target.value,
                                    CurrentReadingDate: new Date(),
                                  });
                                }}
                              />
                            </div>
                            <div className="cc-field">
                              <label className="cc-label">
                                Search value <TooltipValidator />
                              </label>
                              <Field
                                name={"Meter"}
                                filterable
                                suggest
                                textField={textFieldMapping[searchByValue]}
                                onFilterChange={handleSearch}
                                data={getFieldValue("_option.Meter.Data") ?? []}
                                loading={
                                  getFieldValue("_option.Meter.Loading") ??
                                  false
                                }
                                placeholder={searchByValue}
                                component={CCComboBox}
                                onChange={handleSearchResult}
                                validator={requiredValidator}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    </section>

                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Property address</label>
                        <Field
                          disabled
                          name="PropertyAddress"
                          component={CCInput}
                          placeholder={"Property address"}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Account names</label>
                        <Field
                          disabled
                          name="AccountNames"
                          component={CCInput}
                          placeholder={"Account names"}
                        />
                      </div>
                    </div>

                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Reading status</label>
                        <Field
                          name="ReadingStatus"
                          data={mockReadingStatusData ?? []}
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Reading officer</label>
                        <Field
                          name="ReadingOfficer"
                          data={mockReadingOfficerData ?? []}
                          textField="Value"
                          dataItemKey="Key"
                          component={CCSearchComboBox}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Previous reading date
                        </label>
                        <Field
                          disabled
                          name="PreviousReadingDate"
                          component={CCDateTimePicker}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Previous reading</label>
                        <Field
                          disabled
                          name="PreviousReading"
                          component={CCNumericTextBox}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">
                          Current reading date <TooltipValidator />
                        </label>
                        <Field
                          name="CurrentReadingDate"
                          component={CCDateTimePicker}
                          format={DATETIME_FORMAT.DATETIME_CONTROL}
                          min={
                            getFieldValue("PreviousReadingDate")
                              ? subDays(
                                  getFieldValue("PreviousReadingDate"),
                                  -1
                                )
                              : undefined
                          }
                          max={new Date()}
                          onChange={handleOnChangeCurrentReadingDate}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Current reading <TooltipValidator />
                        </label>
                        <Field
                          name="CurrentReading"
                          component={CCNumericTextBox}
                          onChange={handleOnChangeCurrentReading}
                          min={0}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Number of days</label>
                        <Field
                          disabled
                          name={"NumberOfDays"}
                          placeholder={"Number of days"}
                          component={CCNumericTextBox}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Usage</label>
                        <div className="cc-custom-input-group">
                          <Field
                            disabled
                            name={"Usage"}
                            placeholder={"Usage"}
                            component={CCInput}
                          />
                          <div className="cc-input-group-postfix">
                            Kilolitre
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Comment</label>
                        <Field
                          name="Comment"
                          component={CCTextArea}
                          rows={3}
                          placeholder={"Comment"}
                        />
                      </div>
                    </div>
                  </FormElement>
                </div>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    type="button"
                    className={"cc-dialog-button"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    type={"submit"}
                    name="Enter"
                    themeColor="primary"
                    className={"cc-dialog-button"}
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps.valid}
                  >
                    Enter
                  </Button>

                  <Button
                    type={"submit"}
                    name="EnterAndExit"
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps.valid}
                  >
                    Enter & Exit
                  </Button>
                </div>
              }
            />
            {showWarningDialog && (
              <ConfirmReadingDialog
                onClosePopup={() => {
                  setShowWarningDialog(false);
                }}
                onConfirmYes={() => {
                  setShowWarningDialog(false);
                  isEnter ? onEnter() : onEnterAndExit();
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default EnterReadingsDialog;
