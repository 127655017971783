import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AnyRecord } from "dns";

export const searchCategoryNameOnly = async (
  searchText: string,
  recordType: RECORDTYPE
): Promise<APIResponse<any | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<AnyRecord | undefined>(
      `/odata/tp/internal/tpapplicationcategory/GetApplicationcategory(recordType=${recordType})?$count=true&$top=50&$skip=0&$filter=contains(ApplicationCategory_Name,'${encodeURIComponent(
        searchText
      )}')`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
