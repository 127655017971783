import { MailMerge_SelectMergeDocument } from "@app/core/new-mail-merge/dialogs/model";
import { nameOfLov } from "@app/products/property/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import React, { useCallback, useMemo } from "react";

const nameOf = nameOfFactory<MailMerge_SelectMergeDocument>();
const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

interface IDocumentCategoryFieldProps {
  options: any;
  valueGetter: (path: string) => any;
  isRequiredCategory: boolean;
  onChange: any;
}
export const DocumentCategoryField = (props: IDocumentCategoryFieldProps) => {
  const { options, valueGetter, isRequiredCategory, onChange } = props;
  const productType = options.productType ?? PRODUCT_TYPE_NUMBER.Core;
  const isCommunityProperty =
    productType === PRODUCT_TYPE_NUMBER.CommunityProperty;
  const categoryData = valueGetter("_options.Categories") ?? [];
  const categoryKWD = valueGetter(nameOf("Category_KWD"));

  //Data item key
  const dataItemKey = useMemo(
    () =>
      isCommunityProperty ? nameOfLov("Code") : nameOfCoreKeyword("Keyword_ID"),
    [isCommunityProperty]
  );

  //Handle on change
  const handleOnChange = useCallback(
    (event: ComboBoxChangeEvent) => {
      onChange(nameOfCoreKeyword("Name"), {
        value: event.value?.Name,
      });
      onChange(nameOf("CategoryName"), {
        value: event.value?.Name,
      });
      onChange(nameOf("Category_KWD"), {
        value: !isCommunityProperty
          ? event.value?.Keyword_ID
          : event.value?.Code,
      });
    },
    [isCommunityProperty, onChange]
  );

  return (
    <div className="cc-field">
      <CCLabel title="Category" isMandatory={isRequiredCategory} />
      <Field
        className="cc-dropdown"
        name={nameOf("Category_KWD")}
        component={CCSearchComboBox}
        data={categoryData}
        textField={nameOfCoreKeyword("Name")}
        dataItemKey={dataItemKey}
        value={getDropdownValue(categoryKWD, categoryData, dataItemKey)}
        onChange={handleOnChange}
        validator={isRequiredCategory ? requiredValidator : undefined}
      />
    </div>
  );
};
