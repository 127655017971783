import { HMFieldMapping } from "@app/products/hm/model";
export const colHMSystemAdminRisks = [
  {
    field: HMFieldMapping.Name,
    title: "Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.LowerLimit,
    title: "Lower Limit",
    width: 150,
  },
  {
    field: HMFieldMapping.UpperLimit,
    title: "Upper Limit",
    width: 150,
  },
  {
    field: HMFieldMapping.Description,
    title: "Description",
    width: 150,
  },
];
