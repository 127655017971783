import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const masterPropertiesRoute: ICCRoute = {
  path: "master-properties",
  name: "Master Properties",
  enum: eMenuAction.MasterProperties_List,
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
