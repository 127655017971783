import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export interface IInitDataResolutionResponse {
  EventDescription?: string;
}

export interface IResolutionResponsesDialog {
  onClosePopup: () => void;
  initData?: IInitDataResolutionResponse;
  handleSubmit?: ((event: FormSubmitClickEvent) => void) | undefined;
}

const nameOf = nameOfFactory<IInitDataResolutionResponse>();
export const ResolutionResponsesDialog = observer(
  ({
    onClosePopup = () => {},
    initData = { EventDescription: "" },
    handleSubmit = () => {},
  }: IResolutionResponsesDialog) => {
    const { crmsEvent } = useCRMSEventStore();
    const { settings } = useCommonCoreStore();
    const response1Label = getStringValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_Response1Caption
      ]
    );
    const response2Label = getStringValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_Response2Caption
      ]
    );
    const response3Label = getStringValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_Response3Caption
      ]
    );

    return (
      <Form
        onSubmitClick={handleSubmit}
        key={JSON.stringify(initData)}
        initialValues={initData}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, valueGetter } = formRenderProps;
          return (
            <FormElement>
              <CCDialog
                maxWidth="40%"
                maxHeight="45%"
                onClose={() => onClosePopup()}
                titleHeader="Event"
                bodyElement={
                  <div className="cc-form">
                    <div className="cc-field-group">
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCLabel title="Event description" />
                          <Field
                            name={nameOf("EventDescription")}
                            placeholder="Event description"
                            rows={3}
                            value={valueGetter(`EventDescription`)}
                            component={CCTextArea}
                          />
                        </div>
                        {crmsEvent?.ServiceStandard?.Response1 ? (
                          <div className="cc-field">
                            <CCValueField
                              label={`${response1Label ?? "Response1"}`}
                              name={"Response1"}
                              value={crmsEvent?.ServiceStandard?.Response1}
                            />
                          </div>
                        ) : null}
                        {crmsEvent?.ServiceStandard?.Response2 ? (
                          <div className="cc-field">
                            <CCValueField
                              label={`${response2Label ?? "Response2"}`}
                              name={"Response2"}
                              value={crmsEvent?.ServiceStandard?.Response2}
                            />
                          </div>
                        ) : null}
                        {crmsEvent?.ServiceStandard?.Response3 ? (
                          <div className="cc-field">
                            <CCValueField
                              label={`${response3Label ?? "Response3"}`}
                              name={"Response3"}
                              value={crmsEvent?.ServiceStandard?.Response3}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                }
                footerElement={
                  <>
                    <div className="cc-dialog-footer-actions-right">
                      <div>
                        <Button
                          className="cc-dialog-button"
                          type="button"
                          onClick={() => {
                            onClosePopup();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="cc-dialog-button"
                          themeColor="primary"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </>
                }
              />
            </FormElement>
          );
        }}
      />
    );
  }
);
