import { IPPRPlansToComply } from "@app/products/town-planning/ppr/[id]/components/child-screens/plans-to-comply/model";
import { TOWN_PLANNING_PPR_ROUTE } from "@app/products/town-planning/ppr/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPPRPlansToComply>();

export const colPPRPlanToComply: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Reference Number",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_PPR_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("LodgedDate"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Decision"),
    title: "Decision",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Planner"),
    title: "Officer",
  },
  {
    field: nameOf("FinalDecisionDate"),
    title: "Date Completed",
    format: DATE_FORMAT.DATE,
  },
];
