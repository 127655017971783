import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";

export const mockMeterStatus = [
  { Key: 1, Value: "Being Tested" },
  { Key: 2, Value: "Faulty" },
  { Key: 3, Value: "Not in Service" },
  { Key: 4, Value: "Retired" },
];

export const mockListReplacementMeter = [
  "0000232",
  "0005831",
  "0005855",
  "0006044",
  "0006059",
  "0007230",
  "0008144",
  "00A0057191",
  "00A105659",
  "00A312709",
  "029823",
  "03W521478",
  "03W521582",
  "03W595573",
  "05W888574",
  "05X048068",
  "06W046600",
  "06W046641",
  "07E003748",
  "07E126182",
];

export const mockReplacementMeter: IMeterFormGeneral[] = [
  {
    Id: 1,
    PropertyAddress: "5 Jones Road, Cannonvale",
    MeterNumber: "0000232",
    Category: "Whitsunday Water Usage (W1)",
    Make: "",
    Type: "Mains",
    Condition: "",
    Status: "Active",
    InstalledOn: new Date("22-Dec-2010"),
    NumberOfDials: 4,
    MeterSize: 25,
    AverageDaily: 0.7587,
    UnitOfMeasure: "kl",
    EstimatedUsageSinceLast: "423kl",
    RouteName: "Cannonvale 2",
    SequenceWithinRoute: "211500",
    AssignedReadingDevice: "PDA_ALL",
    DeviceAssignedTo: "Meter Reader 1",
    MeterLocation: "",
    ReadingInstructions: "",
    SpecialInstructions: "",
    PhoneBeforeReading: false,
    KeysAreRequired: false,
    ContactName: "",
    ContactPhone: "",
    EmailBeforeReading: false,
    EmailAddress: "",
    NextScheduledReadingDate: new Date("28-Oct-2020"),
    Reading: 4,
    ReadingDate: new Date("2-Jun-2020 7:24"),
    LastReading: 2480,
    Usage: 73,
    LRDStatus: "",
    Reader: "Meter Reader 1",
    Comment: "",
    NumberOfDays: 96,
    LRDAverageDaily: 0.7604,
    AssessmentsAccountsAssociated: [
      {
        Type: "Assessment",
        AssessmentNumber: "32160450",
        BillingGroup: "Standard Periodic Billing",
        UsageEntitlement: 100,
        UsagePercentage: 100,
        PropertyAddress: ",",
        Improvements: "DWELLING",
        PrimaryLandUse: "20-Single Unit Dwelling",
        AssessmentType: "Normal Assessment",
        Status: "Active",
        AssessmentId: 854,
      },
    ],
  },
  {
    Id: 2,
    PropertyAddress: "5 Jones Road, Cannonvale",
    MeterNumber: "0005831",
    Category: "Whitsunday Water Usage (W1)",
    Make: "",
    Type: "Mains",
    Condition: "",
    Status: "Active",
    InstalledOn: new Date("22-Dec-2010"),
    NumberOfDials: 4,
    MeterSize: 35,
    AverageDaily: 0.7587,
    UnitOfMeasure: "kl",
    EstimatedUsageSinceLast: "423kl",
    RouteName: "Cannonvale 2",
    SequenceWithinRoute: "211500",
    AssignedReadingDevice: "PDA_ALL",
    DeviceAssignedTo: "Meter Reader 2",
    MeterLocation: "",
    ReadingInstructions: "",
    SpecialInstructions: "",
    PhoneBeforeReading: false,
    KeysAreRequired: false,
    ContactName: "",
    ContactPhone: "",
    EmailBeforeReading: false,
    EmailAddress: "",
    NextScheduledReadingDate: new Date("28-Oct-2020"),
    Reading: 4,
    ReadingDate: new Date("2-Jun-2020 7:24"),
    LastReading: 2480,
    Usage: 73,
    LRDStatus: "",
    Reader: "Meter Reader 1",
    Comment: "",
    NumberOfDays: 96,
    LRDAverageDaily: 0.7604,
    AssessmentsAccountsAssociated: [
      {
        Type: "Assessment",
        AssessmentNumber: "32160450",
        BillingGroup: "Standard Periodic Billing",
        UsageEntitlement: 100,
        UsagePercentage: 100,
        PropertyAddress: ",",
        Improvements: "DWELLING",
        PrimaryLandUse: "20-Single Unit Dwelling",
        AssessmentType: "Normal Assessment",
        Status: "Active",
        AssessmentId: 854,
      },
    ],
  },
];
