import { getPicDetailsTabById } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/api";
import { PICTabDetailsEventType } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/config";
import { DTO_PIC_TabDetails } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PropertyPicDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [picInfo, setPicInfo] = useState<DTO_PIC_TabDetails | undefined>();
  const { lastSelectedId } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const picId: number = !isNil(params.id) ? +params.id : lastSelectedId;
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const handleLoadTabDetail = async (picId: number) => {
    setIsLoading(true);
    let errorResponse = undefined;
    const response = await getPicDetailsTabById(picId);
    if (isSuccessResponse(response) && response?.data) {
      setPicInfo(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(false);
  };

  useAddUniqueEventEmitter([
    {
      eventType: PICTabDetailsEventType.RefreshData,
      listener: (id: number) => {
        handleLoadTabDetail(id);
      },
    },
  ]);

  //useEffect
  useEffect(() => {
    if (picId) handleLoadTabDetail(picId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  //show load failed
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          handleLoadTabDetail(picId);
        }}
      />
    );
  if (!picInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"PIC Number:"}
              value={picInfo?.PICNumber}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"PIC Id:"}
              value={picInfo?.PIC_Id + ""}
            />
            <ProductReferenceRow
              title={"Primary Holding:"}
              value={picInfo?.PrimaryHoldingNumber}
            />
            <ProductReferenceRow title={"Status:"} value={picInfo?.PICStatus} />
            <ProductReferenceRow
              title={"District:"}
              value={picInfo?.District}
            />
            <ProductReferenceRow
              title={"RLP Board:"}
              value={picInfo?.RLPBoard}
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
