import {
  DTO_Workflow_ModifyAssessment,
  RequestObjFromSpatial,
} from "@app/products/property/assessments/components/form-steps/modify-assessment/model";
import { DTO_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessModifyAssessment = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_ModifyAssessment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ModifyAssessment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyassessment/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewWorkflowModifyAssessment = async (
  requestObjSpatial: RequestObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_ModifyAssessment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifyassessment/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getCreateAssessmentLOVs = async (): Promise<
  APIResponse<DTO_Assessment_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createassessment/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
export const getWorkflowModifyAssessment = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_ModifyAssessment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifyassessment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAssessment =
  | [
      APIResponse<DTO_Assessment_LOVs>,
      APIResponse<DTO_Workflow_ModifyAssessment>
    ]
  | APIResponse<DTO_Assessment_LOVs | DTO_Workflow_ModifyAssessment>
  | undefined;
export const getInitialDataAssessment = async (
  workflowType: WorkflowTypes,
  requestObjFromSpatial: RequestObjFromSpatial,
  workflowDraftId: number | undefined,
  isNewAssessment: boolean = false
): Promise<IGetInitialDataAssessment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Assessment_LOVs>(
        `/api/property/internal/workflow/createassessment/lovs?workflowType=${workflowType}`
      ),
      !isNewAssessment && !isNil(workflowDraftId)
        ? getWorkflowModifyAssessment(workflowDraftId)
        : postNewWorkflowModifyAssessment(requestObjFromSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
