import { mockTransactionType } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/charge/mock";
import { validatorCharge } from "@app/products/property/registers/[id]/components/dialogs/form-steps/raise-charge/components/form-elements/charge/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const CHARGE_FORM_STEP = "Charge";
export const ChargeFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorCharge}
    />
  );
};

const FormStepElement = ({ nameOf }: IFormStepElement) => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Transaction date</label>
          <Field
            name={nameOf("TransactionDate")}
            component={CCDateTimePicker}
            format={DATETIME_FORMAT.DATETIME_CONTROL}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Transaction type
            <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={nameOf("TransactionType")}
            placeholder="Transaction type"
            data={mockTransactionType}
            component={CCComboBox}
            textField="Value"
            dataItemKey="Key"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Reference</label>
          <Field
            name={nameOf("Reference")}
            component={CCInput}
            placeholder="Reference"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Description</label>
          <Field
            name={nameOf("Description")}
            placeholder="Description"
            rows={3}
            component={CCTextArea}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Notes</label>
          <Field
            name={nameOf("Notes")}
            placeholder="Notes"
            rows={3}
            component={CCTextArea}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">
            Amount <CCTooltip type="validator" position="right" />
          </label>
          <Field name={nameOf("Amount")} component={CCCurrencyInput} />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Quantity <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={nameOf("Quantity")}
            component={CCNumericTextBox}
            min={0}
          />
        </div>
      </div>
    </section>
  );
};
