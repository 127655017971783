import {
  action,
  computed,
  configure,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import React, { createContext, ReactElement } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class CCSubActionBarStore {
  private _menuName: string | undefined = undefined;
  private _children: ReactElement | ReactElement[] | undefined = undefined;
  private _isPin: boolean = false;
  private _isWorkflow: boolean = false;

  setMenu = (
    menuName: string | undefined,
    children: ReactElement | ReactElement[] | undefined
  ) => {
    runInAction(() => {
      this._menuName = menuName;
      this._children = children;
    });
  };

  resetMenu = () => {
    runInAction(() => {
      this._menuName = undefined;
      this._children = undefined;
    });
  };

  setIsPin = (status: boolean) => {
    runInAction(() => {
      this._isPin = status;
    });
  };

  setIsWorkflow = (isWorkflow: boolean) => {
    runInAction(() => {
      this._isWorkflow = isWorkflow;
    });
  };

  constructor() {
    makeObservable<
      CCSubActionBarStore,
      "_menuName" | "_children" | "_isPin" | "_isWorkflow"
    >(this, {
      _menuName: observable,
      _children: observable,
      _isPin: observable,
      _isWorkflow: observable,
      setMenu: action,
      resetMenu: action,
      setIsPin: action,
      menuName: computed,
      children: computed,
      isPin: computed,
      isWorkflow: computed,
    });
  }

  get menuName() {
    return toJS(this._menuName);
  }
  get children() {
    return toJS(this._children);
  }
  get isPin() {
    return toJS(this._isPin);
  }
  get isWorkflow() {
    return toJS(this._isWorkflow);
  }
}

const formStepStoreContext = createContext(new CCSubActionBarStore());

export const useCCSubActionBarStore = () => {
  const context = React.useContext(formStepStoreContext);
  if (context === undefined) {
    throw new Error("useCCSubActionBarStore in not within CCSubActionBarStore");
  }
  return context;
};
