import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IApplicationSettingDropdownProps {
  enumParam: ECorporateSettingsField;
  isManualRequired?: boolean;
}
const nameOfLookupItem = nameOfFactory<ILookupItem>();

export const GlobalSettingDropdown = (
  props: IApplicationSettingDropdownProps
) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { enumParam, isManualRequired = false } = props;

  if (!configData) return <></>;
  const configDataValue = configData[enumParam.toString()];
  const isMandatory = configDataValue.IsMandatory || isManualRequired;

  return configDataValue ? (
    <div className="cc-field">
      <CCLabel title={configDataValue.Title ?? ""} isMandatory={isMandatory} />
      <Field
        name={configDataValue.FieldName}
        component={CCSearchComboBox}
        data={configDataValue.LookupItems}
        textField={nameOfLookupItem("Name")}
        dataItemKey={nameOfLookupItem("Id")}
        disabled={!isEditing}
        isUseDefaultOnchange
        validator={isMandatory ? requiredValidator : undefined}
      />
    </div>
  ) : null;
};
