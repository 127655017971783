import { mockEnterReading } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/mock";
import { IEnterReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/components/dialogs/enter-reading/model";
import { sleep } from "@common/utils/common";

export const loadEnterReading = async (
  readingGridData: IEnterReadingDialogData
): Promise<IEnterReadingDialogData> => {
  await sleep(500);
  return {
    ...readingGridData,
    ...mockEnterReading,
  };
};
