import { eventEmitter } from "@/App";
import { odataCoreGetViewCurrentSiteUsersByHomepage } from "@app/core/components/common/utils";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { colManageHomepageShortcuts } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/config";
import { useHomePageShortcutsStore } from "@common/pages/settings/homepage/homepage-shortcuts/[id]/store";
import { postHomePageRemoveUser } from "@common/pages/settings/homepage/homepage-shortcuts/api";
import { HomePageFunction } from "@common/pages/settings/homepage/homepage-shortcuts/model";
import {
  HomepageType,
  Svc_HomepagePageUser,
  Svc_HomepagePickUserRequest,
} from "@common/pages/settings/homepage/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const nameOf = nameOfFactory<HomePageFunction>();
const nameOfHomepagePageUser = nameOfFactory<Svc_HomepagePageUser>();

export const HomepageShortcutsFormElement = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { pushNotification } = useCCAppNotificationStore();
  const { settings } = useCommonCoreStore();
  const { homepageShortcutsLovs, setIsLoading } = useHomePageShortcutsStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<
    Svc_HomepagePageUser[]
  >([]);

  const defaultItemPerPage: number = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FlatGridPageSize]
  );

  const handleSideUserDelete = async () => {
    setIsLoading(true);
    const ids: number[] = gridSelectedRows?.map(
      (item: Svc_HomepagePageUser) => item.ID
    );
    const params: Svc_HomepagePickUserRequest = {
      HomepageIDs: [parseInt(id)],
      SiteUserIDs: ids,
      HomepageType: HomepageType.HomepageShortcut,
    };
    const response = await postHomePageRemoveUser(params);
    setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      pushNotification({
        title: "Remove user successfully.",
        description: response.data?.Errors,
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
    } else {
      pushNotification({
        autoClose: false,
        title: "Remove user failed.",
        type: "error",
        description: response.data?.Errors ?? response.error,
      });
    }
  };

  return (
    <FormElement>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Product type" />
            <Field
              name={nameOf("ProductType_ENUM")}
              dataItemKey="ProductType_ENUM"
              textField="ProductType_Name"
              data={homepageShortcutsLovs ?? []}
              isUseDefaultOnchange
              component={CCSearchComboBox}
              disabled={true}
              validator={requiredValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Function" isMandatory />
            <Field
              name={nameOf("FunctionHeading")}
              component={CCInput}
              placeholder={"Function heading"}
              validator={requiredValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Description" isMandatory />
            <Field
              name={nameOf("FunctionDescription")}
              component={CCInput}
              placeholder={"Function description"}
              validator={requiredValidator}
            />
          </div>
        </div>

        <div className="cc-form-cols-1">
          <CCGrid
            selectableMode="multiple"
            columnFields={colManageHomepageShortcuts}
            primaryField={nameOfHomepagePageUser("ID")}
            dataUrl={odataCoreGetViewCurrentSiteUsersByHomepage(parseInt(id))}
            itemPerPage={defaultItemPerPage}
            onSelectionChange={(dataItem: Svc_HomepagePageUser[]) => {
              setGridSelectedRows([...dataItem]);
            }}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  type="button"
                  iconClass="fas fa-minus"
                  title="Remove"
                  disabled={gridSelectedRows.length === 0}
                  onClick={handleSideUserDelete}
                />
              </div>
            }
            state={{
              sort: [
                { field: nameOfHomepagePageUser("DisplayName"), dir: "asc" },
              ],
            }}
          />
        </div>
      </section>
    </FormElement>
  );
});
