import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";

export const saveRestrictedBreed = async (
  ids: number[],
  isDelete: boolean = false
): Promise<APIResponse<ResponsePacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponsePacket>(
      `api/animals/internal/pick-restricted-breed/save?isDelete=${isDelete}`,
      ids
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
