import { UITownPlanning } from "@app/products/town-planning/model";
import { ISendForApproval } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/model";
import { SendForApprovalDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/send-for-approval/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putSendForTLApproval } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-for-tl-approval/api";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ResendForTLApprovalButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePPRStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.PPRSendForApproval.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    if (
      (ppr?.Status_ENUM === Application_Status.ReadyToReSendToTeamLeader ||
        ppr?.Status_ENUM === Application_Status.Complete) &&
      ppr?.Flag_PlansToComply
    ) {
      return true;
    }

    return false;
  }, [ppr]);

  const handleSubmit = async (data: ISendForApproval) => {
    if (!pprId || !data.SelectedDocumentIDs) return;
    setIsLoading(true);
    const response = await putSendForTLApproval(
      pprId,
      data.SelectedDocumentIDs,
      ""
    );
    if (isSuccessIdentityPacket(response)) {
      setIsShowDialog(false);
      setNotifications([]);
      appNotificationStore.clearNotifications();
      setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Resend to delegated approver successfully",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Resend for approval",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Resend to delegated approver failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isVisible ? (
        <CCNavButton
          title="Resend for delegated approver approval"
          onClick={onSubmit}
          isLoading={isLoading}
          name={PPRSubmitActions.ResendForTLApproval}
        />
      ) : null}

      {isShowDialog && (
        <SendForApprovalDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          isSubmitLoading={isLoading}
          notifications={notifications}
          parentData={{
            Id: pprId,
            recordType: RECORDTYPE.TP_PPRApplication,
          }}
        />
      )}
    </>
  );
});
