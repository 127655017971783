import { AnimalsSettingManageFormActionBar } from "@app/products/animals/system-admin/settings/_id/components/action-bar/_index";
import { ExistedAnimalsSettingManage } from "@app/products/animals/system-admin/settings/_id/main/_index";
import { usePhoneOrTablet } from "@common/hooks/usePhoneOrTablet";
import { GlobalSettingHistoryTab } from "@common/pages/settings/system-admin/global-settings/_id/sidebar/history/_index";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const AnimalsSettingManage = observer(() => {
  const actionBarStore = useCCListViewActionBarStore();
  const referenceSideBarStore = useReferenceSideBarStore();
  const history = useHistory();

  const [isShowSlideBar, setIsShowSlideBar] = useState(true);
  usePhoneOrTablet(() => setIsShowSlideBar(false));
  actionBarStore.resetDefault();

  const handleShowSlideBar = () => {
    setIsShowSlideBar(!isShowSlideBar);
  };

  useEffect(() => {
    referenceSideBarStore.setReferenceComponents([
      {
        title: "History",
        component: <GlobalSettingHistoryTab />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  return (
    <>
      <AnimalsSettingManageFormActionBar
        isShowSlideBar={isShowSlideBar}
        onClick={handleShowSlideBar}
      />
      <div className="cc-manage-container">
        <>
          <div className="cc-manage-left">
            <div className="cc-manage-form-body">
              <ExistedAnimalsSettingManage />
            </div>
          </div>
          {isShowSlideBar && (
            <div className="cc-manage-right">
              <ReferenceSideBar />
            </div>
          )}
        </>
      </div>
    </>
  );
});

export default AnimalsSettingManage;
