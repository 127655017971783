import { DTO_Create_Title_LOVs } from "@app/products/property/titles/list/components/action-bar/form-steps/new-title/model";
import { APIResponseError } from "@common/apis/model";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

class CreateTitleStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _lovCreateTitle?: DTO_Create_Title_LOVs = undefined;
  private _isLoadingOnDialog: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get responseError() {
    return toJS(this._responseLoadError);
  }

  setResponseError = (responseError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseError;
    });
  };

  get isLoadingOnDialog(): boolean {
    return this._isLoadingOnDialog;
  }
  setIsLoadingOnDialog = (isLoadingOnDialog: boolean) => {
    runInAction(() => {
      this._isLoadingOnDialog = isLoadingOnDialog;
    });
  };

  get lovsCreateTitle() {
    return this._lovCreateTitle;
  }

  setLOVsCreateTitle = (titleLovs?: DTO_Create_Title_LOVs) => {
    runInAction(() => {
      this._lovCreateTitle = titleLovs;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._lovCreateTitle = undefined;
    });
  };
}

const createTitleContext = createContext(new CreateTitleStore());

export const useCreateTitleStore = () => {
  return useContext(createTitleContext);
};
