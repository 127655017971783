import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedAppeal } from "@common/pages/appeal/_id/components/forms/existed/_index";
import { NewAppeal } from "@common/pages/appeal/_id/components/forms/new/_index";
import { IParentAppealSection } from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageAppeal = () => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadAppeal } = useAppealStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { clearNotifications } = useCCAppNotificationStore();
  const { state } = useLocation<{ parent: IParentAppealSection } | undefined>();

  useEffect(() => {
    if (!id) return;
    loadAppeal(+id, isNew, state?.parent);

    // eslint-disable-next-line
  }, [isNew, id, state]);

  useEffectOnce(() => {
    return () => {
      clearNotifications();
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line
  });
  if (isNew) {
    return <NewAppeal />;
  }
  return <ExistedAppeal />;
};

export default observer(ManageAppeal);
