import { loadSearchDataById } from "@app/products/property/advanced_search/api";
import { NewAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/new-assessment/_index";
import { propertyRoute } from "@app/products/property/route";
import { nameOfFactory } from "@common/utils/common";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChangeViewButton } from "./components/action-bar/buttons/change-view/_index";
import { RefineButton } from "./components/action-bar/buttons/refine/_index";
import { SaveSearchButton } from "./components/action-bar/buttons/save-search/_index";
import { SearchDetailTab } from "./components/reference-sidebar/detail/_index";
import { searchResultColList } from "./config";
import { IResultData, ISearchResultData } from "./model";
const nameOf = nameOfFactory<IResultData>();
export default () => {
  const [searchResultData, setSearchResultData] = useState<ISearchResultData>();
  const params: { id: string } = useParams();

  useCCListViewActionBar({
    title: propertyRoute.name,
    centerComponents: [
      <ChangeViewButton />,
      <SaveSearchButton />,
      <RefineButton />,
      //@TODO: Integrate later
      <NewAssessmentButton isInAdvanceSearch />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <SearchDetailTab />,
      },
    ],
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }
    loadSearchDataById(parseInt(params.id)).then((data: any) => {
      setSearchResultData(data);
    });
  }, [params.id]);

  return (
    <CCProductListView
      data={searchResultData?.ResultData || []}
      columnFields={searchResultColList}
      primaryField={nameOf("AssessmentNumber")}
    />
  );
};
