import { loadMeterContacts } from "@app/products/property/meters/[id]/components/reference-sidebar/contact/api";
import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";
import { ContactsSidebar } from "@common/pages/contacts/contacts-sidebar/_index";
import Loading from "@components/loading/Loading";
import React, { useEffect, useState } from "react";

export const MeterContactsTab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ICommonContactItemRender[] | undefined>(
    undefined
  );
  useEffect(() => {
    setIsLoading(true);
    loadMeterContacts().then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return <ContactsSidebar data={data || []} />;
};
