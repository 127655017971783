import { DTO_DebtRecoveryAssessment } from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import { nameOfFactory } from "@common/utils/common";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colDebtRecoveryDetail } from "./config";
const nameOf = nameOfFactory<DTO_DebtRecoveryAssessment>();
export const AssessmentDebtRecoveryDetail = (props: GridDetailRowProps) => {
  const [debtRecoveryDetail, setDebtRecoveryDetail] = useState<
    DTO_DebtRecoveryAssessment[]
  >([]);
  const [columnDebtRecoveryDetail, setColumnDebtRecoveryDetail] = useState<
    IColumnFields[]
  >(colDebtRecoveryDetail);
  useEffectOnce(() => {
    const dataItem = props.dataItem;
    setDebtRecoveryDetail(
      dataItem.DebtRecoveryAssessments.DebtRecoveryAssessments
    );

    const debtRecoveryDetailTotalsTotals: any =
      dataItem.DebtRecoveryAssessments?.Totals;
    columnDebtRecoveryDetail.forEach((item: IColumnFields) => {
      if (Object.keys(debtRecoveryDetailTotalsTotals).includes(item.field)) {
        item.footerCell = (
          <CurrencyCell value={debtRecoveryDetailTotalsTotals[item.field]} />
        );
      }
    });
    setColumnDebtRecoveryDetail(columnDebtRecoveryDetail);
  });
  return (
    <div className="cc-assessment-debt-recovery cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Assessment associated with debt recovery
            </label>
            <CCGrid
              data={debtRecoveryDetail}
              columnFields={colDebtRecoveryDetail}
              primaryField={nameOf("Assessment_Id")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
