import { DTO_LOV_Number } from "@common/models/odataResponse";
export interface DTO_Assessment {
  PIC_Assessment_Id?: number;
  Valuation_1?: number;
  Valuation_2?: number;
  Valuation_3?: number;
  Valuation_4?: number;
  Valuation_5?: number;
  Valuation_6?: number;
  Valuation_7?: number;
  Valuation_8?: number;
  Valuation_9?: number;
  Valuation_10?: number;
  Valuation_11?: number;
  Valuation_12?: number;
  Valuation_13?: number;
  Valuation_14?: number;
  Valuation_15?: number;
  Valuation_16?: number;
  Valuation_17?: number;
  Valuation_18?: number;
  Is_Primary_Assessment?: boolean;
  LinkedTitles: DTO_Assessment_LinkedTitle[];
  AS_Is_Assessment_Editable: boolean;
  Primary_Assess_Land_Use_Name: string;
  Assessment_Id: number;
  Assess_Number?: number;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement?: number;
  Unit_Entitlement_Percentage?: number;
  Unit_Type: string;
  Unit_Type_Percentage?: number;
  Assess_Balance: number;
  Assess_Balance_Outstanding?: number;
  Assess_Being_Postponed: boolean;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Assessment_Type_Name: string;
  Assessment_Status: number;
  Assessment_Status_Name: string;
  Valuation_19?: number;
  Valuation_20?: number;
  Active_From?: Date;
  Active_To?: Date;
  RLP_Board_Name: string;
  RLP_Board_Id: number;
  Assess_Property_Name: string;
  AssessmentParcelId?: number;
}

export interface DTO_Assessment_LinkedTitle {
  Title_Id: number;
  LinkedAssessments: number[];
}
export interface DTO_AssessmentLookupObj {
  LookupKey: number;
  LookupValue: string;
  Statuses: number[];
}

export interface DTO_Assessment_Lookup {
  Assessments: DTO_Assessment[];
}

export interface IFilterSearch {
  searchValue: string;
  searchBy: DTO_LOV_Number;
}

export interface fnt_Assessment_LookupResult {
  Assessment_Id: number | null;
  Assess_Number: number | null;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Assessment_Type_Name: string;
  Assessment_Status: number | null;
  Assessment_Status_Name: string;
  Title_Id: number | null;
}

export const eLoadingAssessment = 1;
