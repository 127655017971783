import {
  DTO_Workflow_Rebate_UpdateLossOfEligibilityDate,
  RebateLossOfEligibilityObj,
} from "@app/products/property/assessments/[id]/components/dialogs/rebates/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";
import { isNil } from "lodash";

export const getWorkflowUpdateLossOfEligibilityDate = async (
  requestObj: RebateLossOfEligibilityObj | object = {},
  workflowDraftId: number | undefined
): Promise<APIResponse<DTO_Workflow_Rebate_UpdateLossOfEligibilityDate>> => {
  try {
    if (isNil(workflowDraftId)) {
      return await CoreAPIService.getClient().post(
        `/api/property/internal/workflow/rebate/lossofeligibility/new`,
        requestObj
      );
    } else {
      return await CoreAPIService.getClient().get(
        `/api/property/internal/workflow/rebate/lossofeligibility/${workflowDraftId}`
      );
    }
  } catch (error) {
    const errorResponse: APIResponse = error as APIResponse<ResponsePacket>;
    return {
      ...errorResponse,
      error: (errorResponse.data as ResponsePacket)?.Errors,
    };
  }
};

export const postProcessUpdateLossOfEligibilityDate = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_Rebate_UpdateLossOfEligibilityDate
): Promise<
  APIResponse<
    ResponseMessageWorkflow<DTO_Workflow_Rebate_UpdateLossOfEligibilityDate>
  >
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/rebate/lossofeligibility/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
