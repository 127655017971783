import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import {
  ActionsResponse,
  Svc_BubbleUpDetails,
} from "@common/pages/actions/[id]/components/reference-sidebar/details/model";

export const getDetailTab = async (
  id?: string
): Promise<APIResponse<ActionsResponse | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/core/internal/actions/${id}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getActionBubbleupDetails = async (
  id?: string
): Promise<APIResponse<Svc_BubbleUpDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/core/internal/actions/${id}/bubbleup`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetActionDetailTabResponse =
  | [
      APIResponse<ActionsResponse | undefined>,
      APIResponse<Svc_BubbleUpDetails | undefined>
    ]
  | APIResponse<ActionsResponse | Svc_BubbleUpDetails>
  | undefined;

export const getActionDetailTab = async (
  id?: string
): Promise<IGetActionDetailTabResponse> => {
  try {
    return await Promise.all([getDetailTab(id), getActionBubbleupDetails(id)]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
