import { PROPERTY_PIC_ROUTE } from "@app/products/property/pic/[id]/constant";
import { VO_PIC } from "@app/products/property/pic/list/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_PIC>();

export const colListPic: IColumnFields[] = [
  {
    field: nameOf("PIC_Number"),
    title: "PIC",
    locked: true,
    linkTo: (dataItem: any) => {
      return `${PROPERTY_PIC_ROUTE}/${dataItem.PIC_Id}`;
    },
  },
  {
    field: nameOf("Primary_Holding_Number"),
    title: "Primary Holding Number",
  },
  { field: nameOf("Region"), title: "Region" },
  {
    field: nameOf("PropertyAddress"),
    title: "Property Address",
  },
  {
    field: nameOf("PropertyLocality"),
    title: "Property Locality",
  },
  { field: nameOf("Manager"), title: "PIC Manager" },
  {
    field: nameOf("Trading_Name"),
    title: "Trading Name",
  },
  { field: nameOf("RLP_Board"), title: "RLP Board" },
  { field: nameOf("PIC_Status_Name"), title: "PIC Status" },
  {
    field: nameOf("PIC_Type_Name"),
    title: "PIC Type",
  },
  { field: nameOf("Agent_License_Number"), title: "Agent License Number" },
  {
    field: nameOf("PIC_Land_Use_Name"),
    title: "Land Use Name",
  },
  {
    field: nameOf("PropertyName"),
    title: "Property Name",
  },
  {
    field: nameOf("PIC_Quarantine_Status_On"),
    title: "Quarantine Status",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("PIC_Id"),
    title: "PIC ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
