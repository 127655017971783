import { DTO_AssociatedItem_Title } from "@app/products/property/components/associations/components/title/model";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_Title>();

export const colAssociationsTitle: IColumnFields[] = [
  {
    field: nameOf("LegalDescription"),
    title: "Legal Description",
    linkTo: (dataItem: DTO_AssociatedItem_Title) =>
      `${PROPERTY_TITLES_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("ReferenceNumber"),
    title: "Reference Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Lot"),
    title: "Lot",
  },
  {
    field: nameOf("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOf("PlanNumber"),
    title: "Plan Number",
  },
  {
    field: nameOf("Volume"),
    title: "Volume",
  },
  {
    field: nameOf("Folio"),
    title: "Folio",
  },
  {
    field: nameOf("County"),
    title: "County",
  },
  {
    field: nameOf("Parish"),
    title: "Parish",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("IsAreaSurveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Coverage"),
    title: "Coverage",
  },
  {
    field: nameOf("OwnersName"),
    title: "Name",
  },
  {
    field: nameOf("OwnersAddress"),
    title: "Address",
  },
  {
    field: nameOf("OwnersLocality"),
    title: "Locality",
  },
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("Tenure"),
    title: "Tenure",
  },
  {
    field: nameOf("CrownAllotment"),
    title: "Crown Allotment",
  },
  {
    field: nameOf("Section"),
    title: "Section",
  },
  {
    field: nameOf("Block"),
    title: "Block",
  },
  {
    field: nameOf("Portion"),
    title: "Portion",
  },
  {
    field: nameOf("Subdivision"),
    title: "Subdivision",
  },
  {
    field: nameOf("CrownStatus"),
    title: "Crown Status",
  },
  {
    field: nameOf("FurtherDescription"),
    title: "Further Description",
  },
  {
    field: nameOf("Page"),
    title: "Page",
  },
  {
    field: nameOf("Township"),
    title: "Township",
  },
  {
    field: nameOf("DivisionDate"),
    title: "Division Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Frontage"),
    title: "Frontage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("LandAreaAsString"),
    title: "Land Area",
  },
  {
    field: nameOf("ExcludedAreaAsString"),
    title: "Excluded Area",
  },
  {
    field: "Id",
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
