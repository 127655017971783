import { ICON_FORMAT } from "@common/constants/common-format";
import { SecurityAction } from "@common/pages/settings/security/security-templates/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<SecurityAction>();

export const colSecurityActionGrid: IColumnFields[] = [
  {
    field: nameOf("FormIdentifier_Name"),
    title: "Form",
    width: 400,
  },
  {
    field: nameOf("FormAction_Name"),
    title: "Action",
    width: 300,
  },
  {
    field: nameOf("bAllowAction"),
    title: "Allow",
    format: ICON_FORMAT.ICON_BOOLEAN,
  },
];
