import { COMMUNICATION_API_ROUTE } from "@app/core/communication/dialogs/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { globalStoreInstance } from "@common/stores/global/store";

export const getCommunicationTemplates = async (filter: string) => {
  return await CoreAPIService.getClient().get(
    `${globalStoreInstance.allSetting.baseAPIUrl}${COMMUNICATION_API_ROUTE}/templates/${filter}`
  );
};
// api of this func is still on Radium PR
export const getFieldSelection = async (oSetting: any) => {
  return await CoreAPIService.getClient().post(
    `${globalStoreInstance.allSetting.baseAPIUrl}${COMMUNICATION_API_ROUTE}/getfieldselection`,
    oSetting
  );
};
