import { AnimalViewType, AnimalsRegister } from "@app/products/animals/model";
import { AlertIcon, IconCell } from "@app/products/crms/config";
import { calculatorDynamicWidthIconColumn } from "@app/products/crms/util";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { PROPERTYSYSTEM, RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { GridCellProps } from "@progress/kendo-react-grid";
import { cloneDeep } from "lodash";
import React from "react";

const MOCK_URL = "";
export const AnimalsApiUrl = {
  // // Animals CRUD API
  // CREATE_ANIMALS: '',
  UPDATE_ANIMALS: "",
  DELETE_ANIMALS: "",

  GET_ANIMAL_LOVS: "/api/animals/internal/animals-lovs",
  GET_ANIMAL: "/api/animals/internal/registration/{id}",
  SAVE_ANIMAL: "/api/animals/internal/registration",
  // Animals Form OData
  GET_FORM_ANIMALS: "",
  // Animals View OData
  GET_VIEW_ANIMALS_REGISTER_ACTIVE: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.Active})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_DOGSBYBREED: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.DogsByBreed})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_CATSBYBREED: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.CatsByBreed})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_OTHERBYBREED: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.OtherByBreed})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_ANIMALSBYTYPE: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.AnimalsByType})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_DECEASED: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.Deceased})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_INACTIVE: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.Inactive})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_LOSTTAGS: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.LostTags})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_LIFETIME: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.LifeTime})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_REGISTER: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.Register})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_QUICKREGISTER: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.QuickRegister})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_DOGREGISTER: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.DogRegister})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_CATREGISTER: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.CatRegister})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_CATBREEDERS: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.CatBreeders})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_DANGEROUSDOGS: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.DangerousDogs})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_DOGEXEMPTIONS: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.DogExemptions})?$count=true&`,
  GET_VIEW_ANIMALS_REGISTER_ALL: `/odata/animals/internal/animalsregister/GetAnimalsView(viewEnum=${AnimalViewType.All})?$count=true&`,
  // Mock Animals View OData
  GET_VIEW_ANIMALS_RENEWALS_ALLBYDUESTATUS: MOCK_URL,
  GET_VIEW_ANIMALS_RENEWALS_REQUIRESRENEWALFEE: MOCK_URL,
  GET_VIEW_ANIMALS_RENEWALS_REQUIRESRENEWALNOTICE: MOCK_URL,
  GET_VIEW_ANIMALS_RENEWALS_NOTICESENT: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONPLANNER_INSPECTIONPLANS: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONPLANNER_BYAREA: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONPLANNER_BYPRIORITY: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONPLANNER_BYOFFICER: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONPLANNER_OVERDUE: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONS_BYDATE: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONS_BYTYPE: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONS_BYOFFICER: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONS_BYMONTH: MOCK_URL,
  GET_VIEW_ANIMALS_INSPECTIONS_BYAREA: MOCK_URL,
  GET_VIEW_ANIMALS_COMPLAINTS_BYREFNO: MOCK_URL,
  GET_VIEW_ANIMALS_COMPLAINTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_ANIMALS_COMPLAINTS_BYSTATUS: MOCK_URL,
  GET_VIEW_ANIMALS_NOTICES_BYDATE: MOCK_URL,
  GET_VIEW_ANIMALS_NOTICES_BYDUESTATUS: MOCK_URL,
  GET_VIEW_ANIMALS_FINANCE_OSFEES: MOCK_URL,
  GET_VIEW_ANIMALS_FINANCE_FEESPAID: MOCK_URL,
  GET_VIEW_ANIMALS_FINANCE_FEESALL: MOCK_URL,
  GET_VIEW_ANIMALS_EANIMALS_NEWAPPLICATIONS: MOCK_URL,
  GET_VIEW_ANIMALS_POUNDREGISTER_BYNUMBER: MOCK_URL,
  GET_VIEW_ANIMALS_POUNDREGISTER_BYSTATUS: MOCK_URL,
  GET_VIEW_ANIMALS_KENNELSRENEWALS_REQUIRESRENEWALFEES: MOCK_URL,
  GET_VIEW_ANIMALS_SYSTEMADMIN_ANIMALTYPES: MOCK_URL,
};

export interface IAddAdditionalAddressButton {
  listType?: AnimalViewType;
  defaultConfig: IColumnFields[];
  hideFields?: any[];
  settings?: SettingsMap;
}

const nameOf = nameOfFactory<AnimalsRegister>();

export const generateAnimalsViewBySetting = ({
  settings,
  defaultConfig = [],
  listType,
}: IAddAdditionalAddressButton) => {
  if (!settings) return defaultConfig;
  const isAnimalsShowOSFees = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_ShowOSFees]
  );
  const isAnimalsShowAge = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_ShowAge]
  );
  const isAnimalsDisplayAnimalType = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_DisplayAnimalType]
  );
  const isAnimalsDisplayAnimalPureBreed = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_Display_Purebreed]
  );
  const isAnimalsDisplayAnimalHuntingDog = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_Display_Huntingdog]
  );
  const isAnimalsDisplayAnimalGreyhound = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_Display_Greyhound]
  );
  const isAnimalsDisplayAnimalGuardDog = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_Display_GuardDog]
  );
  const isAnimalsDisplayAnimalCertifiedAssistance = getBoolValueSetting(
    settings[ECorporateSettingsField.Animals_Display_CertifiedAssistance]
  );
  const isAnimalsDisplayAnimalDebtorNo = getBoolValueSetting(
    settings[
      ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber
    ]
  );
  const propertySystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
  );
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("AmountOS"):
        return isAnimalsShowOSFees;
      case nameOf("Age"):
        return isAnimalsShowAge;
      case nameOf("Type"):
        return isAnimalsDisplayAnimalType;
      case nameOf("PureBreed"):
        return isAnimalsDisplayAnimalPureBreed;
      case nameOf("HuntingDog"):
        return isAnimalsDisplayAnimalHuntingDog;
      case nameOf("Greyhound"):
        return isAnimalsDisplayAnimalGreyhound;
      case nameOf("GuardDog"):
        return isAnimalsDisplayAnimalGuardDog;
      case nameOf("CertifiedAssistance"):
        return isAnimalsDisplayAnimalCertifiedAssistance;
      case nameOf("DebtorNo"):
        if (
          isAnimalsDisplayAnimalDebtorNo &&
          listType === AnimalViewType.Register
        ) {
          return true;
        }
        return false;
      case nameOf("FileNo"):
        if (recordsSystem === RECORDSSYSTEM.None) {
          return false;
        }
        return true;
      case nameOf("AssessmentNo"):
        if (propertySystem === PROPERTYSYSTEM.None) {
          return false;
        }
        return true;
      default:
        return true;
    }
  });
};

export enum EGenderAnimal {
  "Male" = "Male",
  "Female" = "Female",
  "Unknown" = "Unknown",
}

const DangerousIcon = ({ dataItem }: { dataItem: AnimalsRegister }) => {
  if (!dataItem.Dangerous) return null;
  return (
    <i
      title="Dangerous"
      className="far fa-dog"
      style={{
        color: "var(--warning)",
      }}
    />
  );
};
export const iconAnimalsColumnDangerousConfig: IColumnFields[] = [
  {
    field: nameOf("Dangerous"),
    title: "Dangerous",
    format: BOOLEAN_FORMAT.BOOLEAN,
    cell: (props: GridCellProps) => {
      return (
        <IconCell props={props}>
          <DangerousIcon dataItem={props.dataItem} />
        </IconCell>
      );
    },
    iconHeaderCell: <i title="Dangerous" className="far fa-dog" />,
    dynamicStyle: (state: State) => {
      return {
        // Get dynamic width column
        width: calculatorDynamicWidthIconColumn(55, state, "Dangerous"),
      };
    },
  },
];

export const iconAnimalsColumnAlertConfig: IColumnFields[] = [
  {
    field: nameOf("HasAlert"),
    title: "Alert",
    format: BOOLEAN_FORMAT.BOOLEAN,
    cell: (props: GridCellProps) => {
      return (
        <IconCell props={props}>
          <AlertIcon dataItem={props.dataItem} />
        </IconCell>
      );
    },
    iconHeaderCell: <i title="Alert" className="fas fa-bell-exclamation" />,
    dynamicStyle: (state: State) => {
      return {
        // Get dynamic width column
        width: calculatorDynamicWidthIconColumn(55, state, "HasAlert"),
      };
    },
  },
];
