import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { colPPRAppeals } from "@app/products/town-planning/ppr/[id]/components/child-screens/appeals/config";
import { Svc_AppealTabViewDetails } from "@app/products/town-planning/ppr/[id]/components/child-screens/appeals/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<Svc_AppealTabViewDetails>();

interface IAppealsProps {
  id: number | undefined;
  recordType: RECORDTYPE;
}

export const Appeals = observer(({ id, recordType }: IAppealsProps) => {
  const { pushNotification } = useCCAppNotificationStore();
  const { isDisabled } = useTabTableStore();
  const [selectedRecords, setSelectedRecords] = useState<
    Svc_AppealTabViewDetails[]
  >([]);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const handleDelete = async (data: IDeleteConfirmation) => {
    setIsLoading(true);
    const response = await deleteRefUsageByIdAndRecordType(
      selectedRecords.map((record: Svc_AppealTabViewDetails) => record.ID),
      RECORDTYPE.CORE_Appeal,
      data.Reason
    );
    if (isSuccessResponse(response)) {
      setSelectedRecords([]);
      setIsShowDialog(false);
      pushNotification({
        title: "Appeal deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Delete appeal failed",
          type: "error",
          description: response.data?.Errors,
        },
      ]);
    }
    setIsLoading(false);
  };
  return (
    <div className="cc-ppr-appeals">
      <CCGrid
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={isDisabled ? isDisabled : selectedRecords.length !== 1}
              onClick={() => setIsShowDialog(true)}
            />
          </div>
        }
        selectableMode="multiple"
        dataUrl={`/odata/tp/internal/pprappeal/Summary(recordID=${id},recordType=${recordType})?$count=true&`}
        primaryField={nameOf("ID")}
        columnFields={colPPRAppeals}
        onSelectionChange={(dataItem: Svc_AppealTabViewDetails[]) => {
          setSelectedRecords(dataItem);
        }}
        state={{
          sort: [{ field: nameOf("ID"), dir: "desc" }],
        }}
      />
      {isShowDialog && (
        <ConfirmDelete
          onClose={() => {
            setIsShowDialog(false);
          }}
          handleSubmit={handleDelete}
          isDeleting={isLoading}
          notifications={notifications}
        />
      )}
    </div>
  );
});
