import { mockPropertyData } from "@app/products/property/fire-prevention/components/dialogs/select-property/mock";
import {
  IFilterSearchResult,
  IPropertyGridData,
} from "@app/products/property/fire-prevention/components/dialogs/select-property/model";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { identity, isEmpty, pickBy } from "lodash";

export const loadSearch = async (filter: FilterDescriptor, searchType: any) => {
  let dataList;

  dataList = mockPropertyData.map((item: any) => item[searchType]);
  const data = new Set(filterBy(dataList, filter).slice());
  await sleep(1000);
  return Array.from(data);
};

export const loadSearchResult = async (filter: IFilterSearchResult) => {
  const {
    owner,
    street,
    locality,
    houseNumber,
    parcelId,
    ratepayer,
    ratepayerAddress,
    assessmentNumber,
    ownerAddress,
  } = filter;
  let listProperty: IPropertyGridData = [];

  const propertyData = [...mockPropertyData];
  const listFilter = {
    Owner: owner,
    Street: street,
    Locality: locality,
    HouseNumber: houseNumber,
    ParcelId: parcelId,
    Ratepayer: ratepayer,
    RatepayerAddress: ratepayerAddress,
    AssessmentNumber: assessmentNumber,
    OwnerAddress: ownerAddress,
  };
  const processFilter = pickBy(listFilter, identity);

  listProperty = multiFilter(propertyData, processFilter);
  return listProperty;
};

const multiFilter = (arr: any, filters: any) => {
  if (isEmpty(filters) || isEmpty(arr)) return [];

  const filterKeys = Object.keys(filters);
  return arr.filter((item: any) => {
    // flipped around, and item[key] forced to a string
    return filterKeys.every(
      (key) => !!~String(item[key]).indexOf(filters[key])
    );
  });
};
//@TODO: will be removed after integrating API
