import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface SiteUserHistoryTabProps {
  id?: number;
}

export const SiteUserHistoryTab = observer(
  ({ id }: SiteUserHistoryTabProps) => {
    const { lastSelectedRow } = useCCProductListViewStore();
    const { siteUser, siteUserId } = useSiteUserStore();
    const siteUserID: number = id ?? siteUserId ?? lastSelectedRow?.ID ?? 0;
    return (
      <Journal
        id={siteUserID}
        triggersReload={siteUser}
        recordType={RECORDTYPE.CORE_Contact}
      />
    );
  }
);
