import { PROPERTY_PARCEL_ROUTE } from "@app/products/property/parcels/[id]/constant";
import { DTO_GISReferences } from "@app/products/property/titles/[id]/components/child-screens/general/model";
import {
  DTO_Parcel,
  DTO_TitleAssociation,
} from "@app/products/property/titles/[id]/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAssociatedParcels = nameOfFactory<DTO_Parcel>();
export const colAssociatedParcels: IColumnFields[] = [
  {
    field: nameOfAssociatedParcels("LegalDescription"),
    title: "Legal Description",
    linkTo: (dataItem: DTO_Parcel) =>
      `${PROPERTY_PARCEL_ROUTE}/${dataItem?.Parcel_Id}`,
  },
  { field: nameOfAssociatedParcels("Reference"), title: "Parcel Reference" },
  {
    field: nameOfAssociatedParcels("PropertyName_Address_Locality"),
    title: "Property Address",
  },
  { field: nameOfAssociatedParcels("Locality"), title: "Locality" },
  {
    field: nameOfAssociatedParcels("CensusDistrict"),
    title: "Census District",
  },
  {
    field: nameOfAssociatedParcels("ElectoralDivision"),
    title: "Electoral Division",
  },
  {
    field: nameOfAssociatedParcels("StateElectoralDivision"),
    title: "State Code Electoral Division",
  },
  {
    field: nameOfAssociatedParcels("FederalElectoralDivision"),
    title: "Federal Electoral Division",
  },
  { field: nameOfAssociatedParcels("LandAreaFormatted"), title: "Land Area" },
  { field: nameOfAssociatedParcels("ZonesAsString"), title: "Zones" },
  { field: nameOfAssociatedParcels("LandUsesAsString"), title: "Land Uses" },
  { field: nameOfAssociatedParcels("OwnersNames"), title: "Owners Name" },
  { field: nameOfAssociatedParcels("Status"), title: "Status" },
  {
    field: nameOfAssociatedParcels("Parcel_Id"),
    title: "Parcel ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfAssociatedTitles = nameOfFactory<DTO_TitleAssociation>();
export const colAssociatedTitles: IColumnFields[] = [
  { field: nameOfAssociatedTitles("Relationship"), title: "Relationship" },
  { field: nameOfAssociatedTitles("Title"), title: "Title" },
  {
    field: nameOfAssociatedTitles("EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssociatedTitles("titleId"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

const nameOfGISReferences = nameOfFactory<DTO_GISReferences>();
export const colGISReferences: IColumnFields[] = [
  { field: nameOfGISReferences("GISReference"), title: "GIS Reference" },
];
