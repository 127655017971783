import { MoveCommentsToInstructionsDialog } from "@app/products/property/meters/list/components/action-bar/dialogs/move-comments-to-instructions/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const MoveCommentsToInstructionsButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { isEmptyGridSelectedRow } = useCCProductListViewStore();

  return (
    <>
      <CCNavButton
        title="Move comments to instructions"
        onClick={() => setIsShowDialog(true)}
        disabled={isEmptyGridSelectedRow}
      />
      {isShowDialog && (
        <MoveCommentsToInstructionsDialog
          onClose={() => setIsShowDialog(false)}
        />
      )}
    </>
  );
});
