import { CC_SETTINGS_SEQCONTROL } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import {
  INumberingChildComponentProps,
  NumberingChildComponent,
} from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/numbering/numbering-child-component/_index";
import { EClientSequenceKey } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const NumberingApplicationForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();

    const numberingApplicationProps = useMemo(() => {
      if (configData) {
        const fieldNumberFormat =
          configData[ECorporateSettingsField.TP_NumberFormat.toString()];
        const fieldPrefix =
          configData[ECorporateSettingsField.TP_NumberPrefix.toString()];
        const fieldSuffix =
          configData[ECorporateSettingsField.TP_NumberSuffix.toString()];
        const fieldLength =
          configData[ECorporateSettingsField.TP_NumberLength.toString()];
        const fieldAmendmentSeparator =
          configData[ECorporateSettingsField.TP_AmendmentSeparator.toString()];
        const fieldLastNumberUsed =
          configData[
            `${CC_SETTINGS_SEQCONTROL}${EClientSequenceKey.TP_Application}`
          ];
        const fieldSetDebtorNumber =
          configData[
            ECorporateSettingsField.TP_Set_DebtorNumber_As_ApplicationNumber.toString()
          ];
        const fieldDebtorNumberPrefix =
          configData[
            ECorporateSettingsField.TP_DebtorNumber_Prefix_Application.toString()
          ];

        const propsData: INumberingChildComponentProps = {
          fieldNumberFormat,
          fieldPrefix,
          fieldSuffix,
          fieldLength,
          fieldAmendmentSeparator,
          fieldLastNumberUsed,
          fieldSetDebtorNumber,
          fieldDebtorNumberPrefix,
          formRenderProps,
        };

        return propsData;
      }
      return undefined;
    }, [configData, formRenderProps]);

    return <NumberingChildComponent {...numberingApplicationProps} />;
  }
);
