import { StandardDocuments } from "@app/products/crms/service-standards/[id]/model";
import { BOOLEAN_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { SETTINGS_LOOKUPS_DOCUMENTS_ROUTE } from "@common/pages/settings/lookups/documents/contant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<StandardDocuments>();
export const colCrmsStandardDocument: IColumnFields[] = [
  {
    field: nameOf("RecordedDate"),
    title: "Recorded",
    format: DATE_FORMAT.DATE,
    href: (dataItem: StandardDocuments) => ({
      href: `/${SETTINGS_LOOKUPS_DOCUMENTS_ROUTE}/${dataItem.DocumentID}`,
    }),
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("Title"),
    title: "Name",
  },
  {
    field: nameOf("FileName"),
    title: "File Name",
  },
  {
    field: nameOf("ServiceDirection"),
    title: "SD",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
