import { IConfirmation } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/confirmation/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
export interface IConfirmationDialogProps {
  onClose: () => void;
  onSubmitData: (data: IConfirmation) => void;
  description?: string;
  subject?: string;
  customLabelReason?: string;
  dataLOVsReason?: DTO_LOV[];
  isLoadingSendData?: boolean;
  titleHeader?: string;
  isLoading?: boolean;
  responseLoadError?: any;
  onReload?: () => void;
  notifications?: IAppNotificationItem[];
}
const nameOf = nameOfFactory<IConfirmation>();
export const ConfirmationDialog = observer(
  ({
    onSubmitData,
    onClose,
    description,
    titleHeader = "",
    subject,
    customLabelReason = "Reason",
    dataLOVsReason = [],
    isLoadingSendData = false,
    responseLoadError,
    isLoading = false,
    onReload = () => {},
    notifications,
  }: IConfirmationDialogProps) => {
    return (
      <Form
        onSubmit={onSubmitData}
        render={(formRenderProps: FormRenderProps) => {
          const { onSubmit, valid } = formRenderProps;
          return (
            <CCDialog
              maxWidth="40%"
              height="auto"
              titleHeader={titleHeader}
              onClose={onClose}
              disabled={isLoadingSendData}
              closeIcon={!isLoadingSendData}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <>
                    {responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => onReload()}
                      />
                    ) : (
                      <div className="cc-form">
                        {notifications?.length ? (
                          <CCLocalNotification
                            defaultNotifications={notifications}
                          />
                        ) : null}
                        <FormElement className="cc-field-group">
                          {subject && <h5 className="cc-label">{subject}</h5>}
                          {description && (
                            <p className="cc-label">{description}</p>
                          )}
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <label className="cc-label">
                                {customLabelReason}
                                <CCTooltip type="validator" position="right" />
                              </label>
                              <Field
                                name={nameOf("Reason")}
                                placeholder="Reason"
                                textField="Name"
                                dataItemKey="Code"
                                data={dataLOVsReason}
                                isUseDefaultOnchange
                                component={CCSearchComboBox}
                                validator={requiredValidator}
                              />
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">Comments</label>
                              <Field
                                name={nameOf("Comments")}
                                rows={3}
                                component={CCTextArea}
                                placeholder="Comments"
                              />
                            </div>
                          </div>
                        </FormElement>
                      </div>
                    )}
                  </>
                )
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    onClick={onClose}
                    disabled={isLoading || isLoadingSendData}
                  >
                    NO
                  </Button>
                  <Button
                    themeColor="primary"
                    className="cc-dialog-button"
                    iconClass={
                      isLoadingSendData ? "fas fa-spinner fa-spin" : ""
                    }
                    onClick={onSubmit}
                    disabled={!valid || isLoadingSendData || isLoading}
                  >
                    YES
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
