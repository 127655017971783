import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import RateBreakupDetail from "./components/detail/_index";
import { colRateBreakup } from "./config";
import { mockRateBreakup } from "./mock";
const RateBreakup = () => {
  return (
    <div className="cc-rate-breakup">
      <CCGrid
        className="cc-rate-breakup-grid"
        data={mockRateBreakup}
        columnFields={colRateBreakup}
        primaryField="ChargeId"
        detail={RateBreakupDetail}
      />
    </div>
  );
};

export default RateBreakup;
