import { ILovReactivateDetail } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/components/form-element/details/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ReactivatePICDetailStepStore {
  private _lovDetail?: ILovReactivateDetail = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get lovDetail() {
    return toJS(this._lovDetail);
  }
  setLovDetail = (lov: ILovReactivateDetail) => {
    runInAction(() => {
      this._lovDetail = lov;
    });
  };
}

const reactivatePICDetailStepStoreContext = createContext(
  new ReactivatePICDetailStepStore()
);
export const useReactivePICDetailStepStoreContext = () => {
  return useContext(reactivatePICDetailStepStoreContext);
};
