import { LocationRegisterForm } from "@app/core/location-register/[id]/components/forms/components/child-screens/general/_index";
import { useLocationRegisterStore } from "@app/core/location-register/[id]/store";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewLocationRegisterComponent = observer(() => {
  const isNew = useIsNew();

  const {
    isLoading,
    responseLoadError,
    locationRegister,
    loadLocationRegister,
    onSubmit,
  } = useLocationRegisterStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Location Register" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadLocationRegister(0, isNew);
          }}
        />
      ) : (
        <>
          <FormTitle title={"New Location Register"} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {locationRegister && <LocationRegisterForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewLocationRegisterComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.Core },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.Core_Form_ManageLocationRegister,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewLocationRegister = renderWithPermissionsWrapper(renderProps);
