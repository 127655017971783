import { EGlobalSettingsCategory } from "@common/pages/settings/system-admin/global-settings/model";
import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const cepRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: EGlobalSettingsCategory.CEPCommunityEngagementPlatform,
    component: require("./forms/community-engagement-platform").default,
  },
  {
    name: EGlobalSettingsCategory.CEPPortalMailSettings,
    component: require("./forms/portal-mail-settings").default,
  },
];
