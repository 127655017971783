export interface DTO_Component_DebtRecovery {
  AssessmentId: number | null;
  EntityId: number | null;
  DebtRecoveryView: DebtRecoveryViews;
  DebtRecoveries: DTO_DebtRecovery[];
  DebtRecoveryExemptions: DTO_DebtRecoveryExemption[];
}

export enum DebtRecoveryViews {
  All = 0,
  Ratepayer = 1,
  Assessment = 2,
}

export interface DTO_DebtRecovery {
  DebtRecoveryAssessments: DTO_DebtRecoveryAssessments;
  ChangedBy: string;
  ChangedOn: string | null;
  CreatedBy: string;
  CreatedOn: string | null;
  Officer: string;
  ShowAlertOnAssessment: boolean;
  AlertMessage: string;
  SolicitorMessage: string;
  AsAt: string | null;
  UpToDueDateTime: string | null;
  AssessmentBalance: number;
  Total: number;
  InitialDebtIncludingCosts: number;
  LastAction: string;
  InterestBalance: number | null;
  Balance: number;
  InitialDebt: number;
  InitialInterestBalance: number | null;
  InitialBalance: number;
  DateTo: string | null;
  DateFrom: string | null;
  CostsIncurred: number | null;
  Status: string;
  Type: string;
  Assessments: string;
  NameAndAddress: DTO_NameAndAddress;
  Debt_RecoveryId: number;
}

export interface DTO_DebtRecoveryAssessments {
  DebtRecoveryAssessments: DTO_DebtRecoveryAssessment[];
  Totals: DTO_DebtRecoveryAssessmentTotals;
}

export interface DTO_DebtRecoveryAssessmentTotals {
  InitialCharges: number | null;
  InitialInterest: number | null;
  CostsIncurred: number | null;
  TotalDebt: number | null;
  DebtCostBalance: number | null;
  AssessmentBalance: number | null;
}

export interface DTO_NameAndAddress {
  NameAndAddress: string;
  NameAddress_Id: number;
}

export interface DTO_DebtRecoveryAssessment {
  AssessmentNumberFormatted: string;
  AssessmentReference: string;
  PropertyName_Address_Locality: string;
  CurrentOwner: string;
  InitialCharges: number | null;
  InitialInterest: number | null;
  CostsIncurred: number | null;
  TotalDebt: number | null;
  DebtRecoveryAssessmentId: number;
  DebtCostBalance: number | null;
  AssessmentBalance: number | null;
  Assessment_Id: number;
}

export interface DTO_DebtRecoveryExemption {
  LegalDescription: string;
  PropertyAddress: string;
  PropertyName: string;
  ModifiedOn: string | null;
  ModifiedBy: string;
  CreatedOn: string | null;
  CreatedBy: string;
  Reference: string;
  ActiveTo: string | null;
  IsActive: boolean;
  Description: string;
  Type: string;
  Reason: string;
  NameAndAddressName: string;
  Assessment_Id: number;
  DebtRecovery_ExemptionId: number;
  ActiveFrom: string | null;
  AssessmentNumberFormatted: string;
}

export interface IMappingDebtRecoveryView {
  [key: string]: number;
}

export interface DTO_DebtRecovery_Ext extends DTO_DebtRecovery {
  NewNameAndAddress?: string;
  NewNameAddress_Id?: number;
}

export interface IDebtRecoveryOptions {
  label: string;
  value: "All" | "Ratepayer" | "Assessment";
}
