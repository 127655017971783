import { ENoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";

// CCOI means Charge on instalment
export const listTypeShowCCOI = [ENoticeType.FINAL_NOTICES];

// Number change of ownership after && No notice issued after && No interest exemption
// will show if the type as listTypeShowHoldingFields
export const listTypeShowHoldingFields = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.REMINDER_NOTICES,
];

// NAA mean No active arrangement
export const listTypeShowNAA = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ANNUAL_INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ARREARS_NOTICES,
  ENoticeType.REMINDER_NOTICES,
];

// No outstanding charge in discount period
export const listTypeShowOutstandingCharge = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ANNUAL_INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ARREARS_NOTICES,
  ENoticeType.REGISTER_ACCOUNT_BALANCES,
  ENoticeType.NOTICES_FOR_JOURNALS_OF_ACCOUNT_TRANSACTIONS,
  ENoticeType.REMINDER_NOTICES,
];

// Rebate Entitlements including No selection on rebate entitlement && Is entitled to Rebate && Is not entitled to Rebate
export const listTypeShowRebateEntitlements = [
  ENoticeType.AMOUNTS_DUE,
  ENoticeType.FINAL_NOTICES,
  ENoticeType.INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ANNUAL_INSTALMENT_NOTICES,
  ENoticeType.LAND_RENT_ARREARS_NOTICES,
  ENoticeType.REMINDER_NOTICES,
];
