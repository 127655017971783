export const mockGlobalSetting = {
  Organisation: "PTN Global",
  State: "Ariona",
  SystemLabel: "PTN corp",
  CorporateEmail: "super.admin@ptnglobalcorp.com",
  SMTPServer: "smtp.ptnglobal.com",
  SMTPServerPort: "3306",
  EnableSSLforSMTP: true,
  SMTPAuthentication: true,
  FromAddress: "Cantho",
  Host: "smtp.microsoft.com",
  Port: "12312",
  Username: "andrewrobert",
  Password: "0909495979",
  Domain: "azure.com",
  SiteBaseURL: "",
  ApplicationBasePath: "",
};

export const mockCrmsSetting = {
  GeneralCEO: "Alexander REO",
  FlagPromptToSave: true,
  FlagEventShowMap: false,
  ActionOfficerLabel: "Super PIA",
  CoordinatorLabel: "Martin garrix",
  SendToActionOfficerLabel: "Hardwell",
  FlagPrintingIncludeMap: true,
  FlagPrintingIncludeLatestInfo: true,
  FlagPrintingIncludeComments: false,
  FlagPrintingIncludeActions: false,
  FlagPrintingIncludeContacts: false,
};
