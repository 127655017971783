import { useIsNew } from "@common/hooks/useIsNew";
import { ExistManageActions } from "@common/pages/actions/[id]/components/forms/existed/_index";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { IParentActionSection } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NewAction } from "./components/forms/new/_index";

const ManageAction = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const { currentUserInfo } = useGlobalStore();
  const actionId = params.id;
  const { state } = useLocation<{
    parent?: IParentActionSection;
  }>();
  const { resetMenu } = useCCSubActionBarStore();
  const { loadAction, resetStore, setParent, loadParent, loadDefaultOfficer } =
    useActionStore();

  useEffect(() => {
    if (currentUserInfo) {
      if (state?.parent) {
        setParent(state?.parent);
        if (isNew) {
          loadParent(state.parent, isNew);
        }
        window.history.replaceState({}, "");
      }
      if (isNew) {
        loadDefaultOfficer(
          currentUserInfo?.UserPKID
            ? parseInt(currentUserInfo.UserPKID)
            : undefined
        );
      }
      loadAction(parseInt(actionId));
    }
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line
  }, [actionId, isNew, loadAction, state, currentUserInfo]);

  if (isNew) {
    return <NewAction />;
  }

  return <ExistManageActions />;
});

export default ManageAction;
