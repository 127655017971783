import {
  InspectionType,
  InspectionTypeFee,
} from "@app/core/inspections/[id]/model";
import { AdditionalFeesDialog } from "@common/pages/settings/lookups/inspection-types/_id/components/additional-fees-dialog/_index";
import {
  colAdditionalFees,
  nameOfInspectionTypeFee,
} from "@common/pages/settings/lookups/inspection-types/_id/components/additional-fees/config";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";

const nameOf = nameOfFactory<InspectionType>();

export interface IAdditionalFeesProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}
export const AdditionalFees = ({
  formRenderProps,
  isDisabled = false,
}: IAdditionalFeesProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [gridSelectedRows, setGridSelectedRows] = useState<InspectionTypeFee[]>(
    []
  );

  const gridData = valueGetter(nameOf("InspectionTypeFees"));

  const handleOnSubmitDialog = (data: InspectionTypeFee) => {
    const inspectionTypeFees = valueGetter(nameOf("InspectionTypeFees")) ?? [];
    onChange(nameOf("InspectionTypeFees"), {
      value: [
        ...inspectionTypeFees,
        { ...data, Index: inspectionTypeFees.length + 1 } as InspectionTypeFee,
      ],
    });
  };

  const handleRemoveRow = () => {
    const removeIds = gridSelectedRows?.map(
      (item: InspectionTypeFee) => item.Index
    );

    const inspectionTypeFees = valueGetter(nameOf("InspectionTypeFees")) ?? [];
    const newInspectionTypeFees = inspectionTypeFees?.filter(
      (item: InspectionTypeFee) => !removeIds.includes(item.Index)
    );
    onChange(nameOf("InspectionTypeFees"), {
      value: newInspectionTypeFees,
    });
    setGridSelectedRows([]);
  };

  return (
    <>
      <CCLabel title="Additional fees" />
      <CCGrid
        readOnly={isDisabled}
        selectableMode="multiple"
        columnFields={colAdditionalFees}
        primaryField={nameOfInspectionTypeFee("Index")}
        data={gridData ?? []}
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: InspectionTypeFee[]) => {
          setGridSelectedRows([...dataItem]);
        }}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-plus"
              title="Add fee type"
              disabled={isDisabled}
              onClick={() => setIsShowDialog(true)}
            />
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={isDisabled || gridSelectedRows?.length === 0}
              onClick={handleRemoveRow}
            />
          </div>
        }
      />
      {isShowDialog ? (
        <AdditionalFeesDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleOnSubmitDialog}
        />
      ) : null}
    </>
  );
};
