import { options } from "@app/products/crms/service-standards/batch-update/constant";
import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";

export interface ICommunicationsProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

export const Communications = ({
  formRenderProps,
  isDisabled,
}: ICommunicationsProps) => {
  const { valueGetter } = formRenderProps;

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update after hours after hours send email" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_AfterHoursAfterHoursSendEmail"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_AfterHoursAfterHoursSendEmail"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_AfterHoursAfterHoursSendEmail"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel title="After hours after hours send email" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "AfterHoursAfterHoursSendEmail"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update issue location types use system defaults" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_IssueLocationTypesUseSystemDefaults"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_IssueLocationTypesUseSystemDefaults"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_IssueLocationTypesUseSystemDefaults"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Issue location types use system defaults"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "IssueLocationTypesUseSystemDefaults"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>
      </section>

      <hr className="cc-divider" />

      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update send email to requestor on send for action" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_SendEmailToRequestorOnSendForAction"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SendEmailToRequestorOnSendForAction"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_SendEmailToRequestorOnSendForAction"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Send email to requestor on send for action"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "SendEmailToRequestorOnSendForAction"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update send email to requestor on re-send for action" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_SendEmailToRequestorOnReSendForAction"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SendEmailToRequestorOnReSendForAction"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_SendEmailToRequestorOnReSendForAction"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Send email to requestor on re-send for action"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "SendEmailToRequestorOnReSendForAction"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update send SMS to requestor on send for action" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_SendSMSToRequestorOnSendForAction"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SendSMSToRequestorOnSendForAction"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_SendSMSToRequestorOnSendForAction"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Send SMS to requestor on send for action"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "SendSMSToRequestorOnSendForAction"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update send SMS to requestor on re-send for action" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_SendSMSToRequestorOnReSendForAction"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SendSMSToRequestorOnReSendForAction"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_SendSMSToRequestorOnReSendForAction"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Send SMS to requestor on re-send for action"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "SendSMSToRequestorOnReSendForAction"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update send email to onBehalf of on send for action" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_SendEmailToOnBehalfOfOnSendForAction"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SendEmailToOnBehalfOfOnSendForAction"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_SendEmailToOnBehalfOfOnSendForAction"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Send email to onBehalf of on send for action"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "SendEmailToOnBehalfOfOnSendForAction"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update send email to onBehalf of on re-send for action" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_SendEmailToOnBehalfOfOnReSendForAction"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_SendEmailToOnBehalfOfOnReSendForAction"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_SendEmailToOnBehalfOfOnReSendForAction"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Send email to onBehalf of on re-send for action"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "SendEmailToOnBehalfOfOnReSendForAction"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};
