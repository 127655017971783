import { ExistedAnimalType } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/existed/_index";
import { NewAnimalType } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/new/_index";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { IPSARApplicationParentSection } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUnmount } from "react-use";

const ManageAnimalType = observer(() => {
  const isNew = useIsNew();
  const { resetStore, setParentSection } = useAnimalTypeStore();
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();
  const notificationState = location.state as IPSARApplicationParentSection;

  useEffect(() => {
    if (notificationState && notificationState?.notification) {
      setParentSection(notificationState);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState]);

  useUnmount(() => {
    resetStore();
    resetMenu();
  });

  if (isNew) {
    return <NewAnimalType />;
  }
  return <ExistedAnimalType />;
});

export default ManageAnimalType;
