import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useActionCompleteButtonStore } from "@common/pages/actions/components/action-bar/buttons/complete-action/store";
import { ActionCompleteDialog } from "@common/pages/actions/components/action-bar/dialog/complete-action/_index";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

interface IActionCompleteButtonProps {
  isDisabled?: boolean;
}
export const ActionCompleteButton = observer(
  ({ isDisabled = false }: IActionCompleteButtonProps) => {
    const { action, onSubmit, reLoadAction } = useActionStore();
    const { isShowDialog, setIsShowDialog } = useActionCompleteButtonStore();
    const { clearErrorNotification, pushNotification } =
      useCCAppNotificationStore();
    const history = useHistory();

    const isVisible = useMemo(() => {
      return (
        action?.Status_ENUM === ACTIONSTATUS.ALLOCATED && action?.ActionRequired
      );
    }, [action]);

    const handleCompleteAction = () => {
      setIsShowDialog(false);
      clearErrorNotification();
      if (history.action !== "POP") {
        history.goBack();
      } else {
        reLoadAction().then(() => {
          pushNotification({
            type: "success",
            title: "Action completed successfully",
          });
        });
      }
    };

    return isVisible ? (
      <>
        <CCNavButton
          title="Action complete"
          onClick={onSubmit}
          name={ActionSubmitActions.ActionComplete}
          disabled={isDisabled}
        />
        {isShowDialog && (
          <ActionCompleteDialog
            onClosePopup={() => setIsShowDialog(false)}
            onSubmit={handleCompleteAction}
          />
        )}
      </>
    ) : null;
  }
);
