import { APIResponse } from "@common/apis/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import {
  DTO_Component_External_Process,
  DTO_Variable,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import { ResponseMessage } from "@app/products/property/model";

export const getWriteOffSmallBalancesVariables = async (
  component_number: number = 1,
  external_process_id: number = 10
): Promise<APIResponse<DTO_Component_External_Process>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Component_External_Process>(
      `/api/property/int/externalprocess/variables/${component_number}/${external_process_id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postExecuteWriteOffSmallBalances = async (
  payload: Partial<DTO_Variable>[],
  component_number: number = 1,
  external_process_id: number = 10
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `api/property/int/externalprocess/execute/${component_number}/${external_process_id}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
