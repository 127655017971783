import { TOWN_PLANNING_PPR_PSA_REFERRAL_HEARING_CALENDAR_URL } from "@app/products/town-planning/ppr/psa-referrals/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getOdataHearingCalendarByOfficerId = async (
  officerId?: number
): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      TOWN_PLANNING_PPR_PSA_REFERRAL_HEARING_CALENDAR_URL.replace(
        "{officerID}",
        (officerId || 0).toString()
      )
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse?.data?.Message,
    };
  }
};

export const getPickSiteUserList = async (): Promise<APIResponse> => {
  try {
    return await CoreAPIService.getClient().get(
      "/odata/core/internal/officers"
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse?.data?.Message,
    };
  }
};
