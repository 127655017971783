import {
  IMailMergeButtonProps,
  MailMergeButton,
} from "@app/core/new-mail-merge/buttons/components/buttons/_index";
import { useDetailParcelListViewStore } from "@app/products/property/parcels/locality-street-address/components/detail-parcel/components/grid-detail-parcel/store";

import { observer } from "mobx-react-lite";
import React from "react";

export const MailMergeButtonParcelLocality = observer(
  (props: IMailMergeButtonProps) => {
    const { isEmptyGridSelectedRow, gridSelectedRows, gridSelectedIds } =
      useDetailParcelListViewStore();

    return (
      <MailMergeButton
        {...props}
        isEmptyGridSelectedRow={isEmptyGridSelectedRow}
        gridSelectedRows={gridSelectedRows}
        selectedRecordIds={gridSelectedIds}
        titleButton="Mail Merge"
      />
    );
  }
);
