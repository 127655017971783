import { SETTINGS_LOOKUPS_NOTICETYPES_ROUTE } from "@common/pages/settings/lookups/notice-types/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface NewNoticeTypesButtonProps {
  disabled?: boolean;
}
export const NewNoticeTypesButton = ({
  disabled,
}: NewNoticeTypesButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${SETTINGS_LOOKUPS_NOTICETYPES_ROUTE}/new`);
      }}
      disabled={disabled}
    />
  );
};
