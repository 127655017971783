export const dataDeliveryFirst = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Special",
    value: "Special",
  },
  {
    label: "International",
    value: "International",
  },
];

export const dataDeliverySecond = [
  ...dataDeliveryFirst,
  {
    label: "DX Mail",
    value: "DXMail",
  },
];

export enum EDeliveryOptions {
  STANDARD = "Standard",
  SPECIAL = "Special",
  INTERNATIONAL = "International",
  DXMail = "DXMail",
}

export enum EAddressStepperMode {
  Physical = "Physical",
  Postal = "Postal",
}

// Follow by Lovs return from API
export const optionPerson: number[] = [1, 2]; // Person (1) = Deceased Estate (2)
export const optionOrganisation: number[] = [0, 3]; // Organisation (0) = Trust (3)
export const optionIncorporatedBody: number[] = [4]; // Incorporated Body  = (4)
export const optionPartnership: number[] = [5]; // Partnership = 5
