import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSExportsActions: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_ReferenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.Child_FirstName,
    title: "Child First Name",
  },
  {
    field: CRSFieldMapping.Child_LastName,
    title: "Child Last Name",
  },
  { field: CRSFieldMapping.RecordedDate, title: "Recorded" },
  { field: CRSFieldMapping.ActionType, title: "Type" },
  { field: CRSFieldMapping.ActionOfficer, title: "Action Officer" },
  {
    field: CRSFieldMapping.ActionDescription,
    title: "Description",
  },
  { field: CRSFieldMapping.DateTarget, title: "Due" },
  { field: CRSFieldMapping.Status_Name, title: "Status" },
];
