import { useNewMasterPropertyDialogStore } from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/store";
import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

export const UnitTypeCell = observer(
  ({ value, onChange, dataIndex, dataItem, field }: ICustomEditCell) => {
    const { masterPropertyLOVs } = useNewMasterPropertyDialogStore();

    return (
      <DropDownList
        textField="Name"
        dataItemKey="Code"
        data={masterPropertyLOVs?.PropertyType ?? []}
        value={getDropdownValue(
          value,
          masterPropertyLOVs?.PropertyType ?? [],
          "Name"
        )}
        onChange={(event) => {
          if (onChange)
            onChange({
              field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: event.value.Name,
            });
        }}
      />
    );
  }
);
