import { BuildingPoolsSpasFormElement } from "@app/products/building/pools-or-spas/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingPoolOrSpasStore } from "@app/products/building/pools-or-spas/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingPoolsSpasForm = observer(() => {
  const isNew = useIsNew();
  const { poolsOrSpas, createBuildingPoolsSpas, updateBuildingPoolsSpas } =
    useBuildingPoolOrSpasStore();
  const handleSubmit = (data: any) => {
    if (isNew) {
      createBuildingPoolsSpas(data);
    } else {
      updateBuildingPoolsSpas(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={poolsOrSpas}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingPoolsSpasFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
