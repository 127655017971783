import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewPremiseButton = ({
  disabled = false,
}: NewPremisesButtonProps) => {
  const history = useHistory();
  const { settings } = useCommonCoreStore();

  const enableSecondaryMode = getBoolValueSetting(
    settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
  );

  return !enableSecondaryMode ? (
    <CCNavButton
      title="New"
      disabled={disabled}
      onClick={() => {
        history.push(`${PREMISES_MANAGE_ROUTE}/new`);
      }}
    />
  ) : null;
};

type NewPremisesButtonProps = {
  disabled?: boolean;
};
