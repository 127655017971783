import { HMFieldMapping } from "@app/products/hm/model";

export const colHMGovtReportsVendingMachines = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Suburb,
    title: "Suburb",
    width: 150,
  },
  {
    field: HMFieldMapping.DateInspected,
    title: "Date Visited",
    width: 150,
  },
  {
    field: HMFieldMapping.Contacted,
    title: "Contact Person",
    width: 150,
  },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
];
