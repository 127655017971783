import { IMetersUsage } from "@app/products/property/meters/[id]/components/child-screens/usage/model";

export const mockMetersUsage: IMetersUsage[] = [
  {
    Year: 2011,
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 9.396,
    Aug: 9.7092,
    Sep: 9.396,
    Oct: 9.7092,
    Nov: 9.396,
    Dec: 9.7092,
    AnnualUsage: 57.3156,
  },
  {
    Year: 2012,
    Jan: 9.7092,
    Feb: 9.0828,
    Mar: 9.7092,
    Apr: 9.396,
    May: 9.7092,
    Jun: 10.017,
    Jul: 28.9602,
    Aug: 28.9602,
    Sep: 28.026,
    Oct: 28.9602,
    Nov: 28.026,
    Dec: 28.9602,
    AnnualUsage: 229.5162,
  },
  {
    Year: 2013,
    Jan: 28.9602,
    Feb: 26.1576,
    Mar: 28.9602,
    Apr: 28.026,
    May: 28.9602,
    Jun: 28.1243,
    Jul: 32.0075,
    Aug: 32.0075,
    Sep: 30.975,
    Oct: 32.1917,
    Nov: 36.501,
    Dec: 37.7177,
    AnnualUsage: 370.5889,
  },
  {
    Year: 2014,
    Jan: 37.7177,
    Feb: 33.621,
    Mar: 23.8855,
    Apr: 23.115,
    May: 23.8855,
    Jun: 23.3282,
    Jul: 30.4947,
    Aug: 30.4947,
    Sep: 29.511,
    Oct: 30.5851,
    Nov: 32.223,
    Dec: 33.2971,
    AnnualUsage: 352.1589,
  },
  {
    Year: 2015,
    Jan: 33.2971,
    Feb: 30.0748,
    Mar: 26.5736,
    Apr: 20.358,
    May: 21.0366,
    Jun: 20.358,
    Jul: 15.8742,
    Aug: 15.1094,
    Sep: 14.622,
    Oct: 15.1094,
    Nov: 31.173,
    Dec: 32.2121,
    AnnualUsage: 275.7982,
  },
  {
    Year: 2016,
    Jan: 32.2121,
    Feb: 30.1339,
    Mar: 19.1801,
    Apr: 14.883,
    May: 15.3791,
    Jun: 14.883,
    Jul: 17.2069,
    Aug: 18.3613,
    Sep: 17.769,
    Oct: 18.3613,
    Nov: 19.3145,
    Dec: 22.7168,
    AnnualUsage: 240.401,
  },
  {
    Year: 2017,
    Jan: 22.7168,
    Feb: 20.5184,
    Mar: 22.7284,
    Apr: 22.332,
    May: 23.0764,
    Jun: 21.5002,
    Jul: 10.1835,
    Aug: 10.1835,
    Sep: 9.855,
    Oct: 10.1835,
    Nov: 12.861,
    Dec: 15.3605,
    AnnualUsage: 201.4992,
  },
  {
    Year: 2018,
    Jan: 15.3605,
    Feb: 13.874,
    Mar: 16.3377,
    Apr: 15.912,
    May: 16.4424,
    Jun: 16.1728,
    Jul: 18.4636,
    Aug: 18.4636,
    Sep: 17.868,
    Oct: 18.4636,
    Nov: 20.1738,
    Dec: 21.8674,
    AnnualUsage: 209.3994,
  },
  {
    Year: 2019,
    Jan: 21.8674,
    Feb: 19.7512,
    Mar: 62.4694,
    Apr: 61.764,
    May: 63.8228,
    Jun: 58.9342,
    Jul: 19.9609,
    Aug: 19.9609,
    Sep: 19.317,
    Oct: 19.9609,
    Nov: 22.9464,
    Dec: 24.8527,
    AnnualUsage: 415.6078,
  },
  {
    Year: 2020,
    Jan: 24.8527,
    Feb: 23.2493,
    Mar: 23.655,
    Apr: 22.812,
    May: 23.5724,
    Jun: 45.624,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
    AnnualUsage: 122.7038,
  },
];
