import { history } from "@/AppRoutes";
import { getEventIdByReferenceNumber } from "@app/products/crms/[id]/api";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { IEventsParentSection } from "@app/products/crms/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  ProcessExternalCookie,
  ProcessExternalSource,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/process-external/forms/components/process-external-action/model";
import { setCookie } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/process-external/forms/components/process-external-action/util";
import { sleep } from "@common/utils/common";
import { CCResult } from "@components/cc-result/_index";
import Loading from "@components/loading/Loading";
import { isEmpty, isNil } from "lodash";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface IProcessExternalActionProps {
  source?: string;
  // External Interface For modules
  module?: string;
  referenceNo?: string;
  assessmentNumber?: string;
  idType?: string;
  // Mapping Interface
  mapInterfaceId?: string;
  elements?: string;
}

export const processExternalAction = async ({
  source = "",
  module,
  referenceNo,
  assessmentNumber,
  idType,
  mapInterfaceId,
  elements,
}: IProcessExternalActionProps) => {
  if (isEmpty(source)) return;
  if (source.toLowerCase() !== ProcessExternalSource.ExecuteOO) {
    const cookieValue = {
      element: elements,
      id: mapInterfaceId,
    };
    await sleep(500);
    switch (source.toLowerCase()) {
      case ProcessExternalSource.Weave:
        setCookie(ProcessExternalCookie.WeaveCookie, cookieValue, 1);
        break;
      case ProcessExternalSource.Intramaps:
        if (mapInterfaceId === "mapkey") {
          // Not implement Request.QueryString["id"].ToString() == "mapkey"
          // Radium Request URL Drawing but not work.
        }
        setCookie(ProcessExternalCookie.IntraMapsCookie, cookieValue, 1);
        break;
      case ProcessExternalSource.Geocortex:
        setCookie(ProcessExternalCookie.GeocortexCookie, cookieValue, 1);
        break;
      case ProcessExternalSource.Mapinfo:
        setCookie(ProcessExternalCookie.MapInfoCookie, cookieValue, 1);
        break;
      case ProcessExternalSource.Spectrumspatialanalyst:
      case ProcessExternalSource.Spectrumspatial:
        if (mapInterfaceId === "mapkey") {
          // Empty logic in Radium now
        } else {
          setCookie(
            ProcessExternalCookie.SpectrumSpatialCookie,
            cookieValue,
            1
          );
        }
        break;
      case ProcessExternalSource.Pozi:
        if (mapInterfaceId === "mapkey") {
          // Empty logic in Radium now
        } else {
          setCookie(ProcessExternalCookie.PoziCookie, cookieValue, 1);
        }
        break;
      case ProcessExternalSource.Exponare:
        if (mapInterfaceId === "mapkey") {
          // Empty logic in Radium now
        } else {
          setCookie(ProcessExternalCookie.ExponareCookie, cookieValue, 1);
        }
        break;
      case ProcessExternalSource.Dekho:
        setCookie(ProcessExternalCookie.DekhoCookie, cookieValue, 1);
        break;
      case ProcessExternalSource.Arcgis:
        if (mapInterfaceId === "mapkey") {
          // Empty logic in Radium now
        } else {
          setCookie(ProcessExternalCookie.ArcGISCookie, cookieValue, 1);
        }
        break;
    }
  } else {
    // CCS-232 ExecuteOO: Scope handle for AssessmentNo & referenceNo
    let requestAssessmentNumber = assessmentNumber;
    if (!isNil(idType) && idType.toLowerCase() === "mapkey") {
      // Call api Properties__GetAssessmentNoFromMapKey
      // idType is not in scope now
      let assessmentNoFromMapKey = "";
      requestAssessmentNumber = assessmentNoFromMapKey;
    }
    if (!isNil(module) && !isEmpty(module)) {
      switch (module.toUpperCase()) {
        case "CRMS":
          if (isEmpty(referenceNo)) {
            if (!isEmpty(requestAssessmentNumber)) {
              //launch the new CRMS form with local state assessno
              // moduleRedirectUrl.Append("&assessno=" + AssessmentNo);
              history.push(`${CRMS_ROUTE}/new`, {
                assessno: requestAssessmentNumber,
              } as IEventsParentSection);
            }
          } else {
            if (referenceNo) {
              const resp = await getEventIdByReferenceNumber(referenceNo);
              if (isSuccessResponse(resp) && resp.data) {
                history.push(`${CRMS_ROUTE}/${resp.data}`);
              } else {
                history.push(`${CRMS_ROUTE}/new`);
              }
            }
          }
          break;
      }
      // Need handle for other Product module in other task
    }
  }
};

export const ManageProcessExternalAction = () => {
  const params = new URLSearchParams(window.location.search);
  const [isLoadingExternalAction, setIsLoadingExternalAction] =
    useState<boolean>(true);

  const handleExternalAction = async (
    reqProcessAction: IProcessExternalActionProps
  ) => {
    setIsLoadingExternalAction(true);
    await processExternalAction(reqProcessAction);
    setIsLoadingExternalAction(false);
  };

  useEffectOnce(() => {
    const sourceParam = params.get("source");
    const moduleParam = params.get("Module");
    const referenceNoParam = params.get("refno");
    const assessmentNoParam = params.get("id");
    const mapInterfaceIdParam = params.get("id");
    const elementsParam = params.get("element");

    if (sourceParam?.toLowerCase() === ProcessExternalSource.ExecuteOO) {
      let reqProcessActionForModule = {
        source: ProcessExternalSource.ExecuteOO,
        module: moduleParam,
        assessmentNumber: assessmentNoParam,
        referenceNo: referenceNoParam,
      } as IProcessExternalActionProps;
      handleExternalAction(reqProcessActionForModule);
    } else {
      let reqProcessActionForMapping = {
        source: sourceParam,
        mapInterfaceId: mapInterfaceIdParam,
        elements: elementsParam,
      } as IProcessExternalActionProps;
      handleExternalAction(reqProcessActionForMapping);
    }
  });

  if (isLoadingExternalAction)
    return <Loading isLoading={isLoadingExternalAction} isFullScreen />;
  return (
    <CCResult
      status={"info"}
      title={"Please Close This Window"}
      className="cc-not-found-page"
    />
  );
};
