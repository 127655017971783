import { DIRECT_DEBIT_GROUPS } from "@app/products/direct-debit/constant";
import { DBRowStateWithoutArchive } from "@app/products/direct-debit/model";
import { DBRowStateCustomCell } from "@app/products/direct-debit/system-admin/components/db-row-state-custom-cell/_index";
import { DirectDebitGroup } from "@app/products/direct-debit/system-admin/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DirectDebitGroup>();

export const colDirectDebitGroups: IColumnFields[] = [
  {
    field: nameOf("Description"),
    title: "Description",
    locked: true,
    // isGrow: true,
    linkTo: (dataItem: DirectDebitGroup) => {
      return DIRECT_DEBIT_GROUPS + "/" + dataItem.Direct_Debit_Group_ID;
    },
  },
  {
    field: nameOf("Sys_DBRowState"),
    title: "Status",
    filterByEnum: DBRowStateWithoutArchive,
    cell: DBRowStateCustomCell,
  },
  {
    field: nameOf("Direct_Debit_Group_ID"),
    title: "Group ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
