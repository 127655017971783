import { RESULTS_FORM_STEP } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/components/form-elements/results/_index";
import { isNil } from "lodash";

export const validatorNameField = (
  value: any,
  valueGetter: (name: string) => any
): string | undefined => {
  const valueResultStep = valueGetter(RESULTS_FORM_STEP);
  const valueCurrentList = valueResultStep["ListView"];

  if ((value && value.trim().length > 0) || isNil(valueCurrentList)) {
    return undefined;
  }

  return "Default’s value will be used if you don’t fill out";
};
