import { optionInputPickerApplicationCategory } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker-search/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import {
  optionInputPickerOverlay,
  optionInputPickerZone,
} from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/simple/components/form-elements/detail/config";
import { ApplicationUpdateTriggers } from "@app/products/town-planning/ppr/[id]/model";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isArray } from "lodash";
import React, { useRef } from "react";

export const ASSESSMENT_FORM_STEP = "Assessment";

export const AssessmentFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { settings } = useCommonProductStore();
  const useVicSmart = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_UseVicSmart]
  );

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              Application category
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={"_option.ApplicationCategories"}
              nameDisplayMerge="ApplicationCategory_Name"
              placeholder="Application categories"
              component={InputPickerSearch}
              validator={requiredValidator}
              onChange={(data: PPRApplicationCategory[]) => {
                onChange("_option.ApplicationCategories", {
                  value: data,
                });
                onChange("SaveTriggers", {
                  value: [ApplicationUpdateTriggers.UpdateApplicationCategory],
                });
                onChange("ApplicationCategories", {
                  value: data?.map(
                    (itemCategory: PPRApplicationCategory) => itemCategory.ID
                  ),
                });
              }}
              options={optionInputPickerApplicationCategory}
            />
          </div>

          <div className="cc-field">
            <label className="cc-label">
              Zones <CCTooltip type="validator" position="right" />
            </label>

            <Field
              name={"_option.ZonesDisplay"}
              placeholder={"Zones"}
              component={InputPickerSearch}
              validator={requiredValidator}
              onChange={(value: CoreKeyword[]) => {
                if (!value) {
                  onChange("_option.Zones", { value: [] });
                  onChange("_option.ZonesDisplay", {
                    value: "",
                  });
                  onChange("Zones", {
                    value: [],
                  });
                } else {
                  let newZone = undefined;
                  if (!isArray(value)) {
                    newZone = [value];
                  } else {
                    newZone = [...value];
                  }

                  onChange("_option.Zones", { value: newZone });
                  onChange("_option.ZonesDisplay", {
                    value: newZone
                      .reduce((pre, cur) => pre + ", " + cur.Name, "")
                      .slice(1)
                      .trim(),
                  });
                  onChange("Zones", {
                    value: newZone
                      ? newZone.map((item: CoreKeyword) => item.Keyword_ID)
                      : [],
                  });
                }
              }}
              onError={(error: any) => {
                notificationRef.current?.pushNotification({
                  title: error,
                  type: "error",
                });
              }}
              options={optionInputPickerZone}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Overlays</label>
            <Field
              name={"_option.OverlaysDisplay"}
              placeholder={"Overlays"}
              component={InputPickerSearch}
              onChange={(value: CoreKeyword[]) => {
                if (!value) {
                  onChange("_option.Overlays", {
                    value: value,
                  });
                  onChange("_option.OverlaysDisplay", {
                    value: "",
                  });
                  onChange("Overlays", {
                    value: [],
                  });
                } else {
                  let newOverlay = undefined;
                  if (!isArray(value)) {
                    newOverlay = [value];
                  } else {
                    newOverlay = [...value];
                  }
                  onChange("_option.Overlays", {
                    value: newOverlay,
                  });
                  onChange("_option.OverlaysDisplay", {
                    value: newOverlay
                      .reduce((pre, cur) => pre + ", " + cur.Name, "")
                      .slice(1)
                      .trim(),
                  });
                  onChange("Overlays", {
                    value: newOverlay
                      ? newOverlay.map((item: CoreKeyword) => item.Keyword_ID)
                      : [],
                  });
                }
              }}
              options={optionInputPickerOverlay}
            />
          </div>
          {useVicSmart && (
            <div className="cc-field">
              <label className="cc-label">Vicsmart</label>
              <Field
                name={"AssessmentPlanner.AssessmentPlanner_Flag_VicSmart"}
                component={CCSwitch}
                checked={valueGetter(
                  "AssessmentPlanner.AssessmentPlanner_Flag_VicSmart"
                )}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};
