import { DTO_DeferredDuty_Account } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-deferred-duty-account-transaction/components/form-elements/deferred-duty/components/add-deferred-duty-account/model";
import {
  DTO_AllocationChargeBalance,
  DTO_ChargeBalance,
  DTO_DDTransaction,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/model";
import { DTO_Journal } from "@app/products/property/journals/model";
import {
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_Process_JournalPIC } from "@app/products/property/pic/list/components/action-bar/form-steps/new-transaction/model";
import { DTO_Supplementary_Create } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";

export interface DTO_Workflow_JournalCreate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalCreate;
}

export interface DTO_WorkflowDetail_JournalCreate
  extends DTO_WorkflowDetail_Base {
  CreateJournal: DTO_Journal;
  JournalMode: number;
  SupplementaryMode: number;
  CreateSupplementary: DTO_Supplementary_Create;
  AssessmentTransaction: DTO_AssessmentTransaction;
  Assessments: DTO_Assessment[];
  NewCharge: DTO_AssessmentTransactionCharge;
  AllocationChargeBalance: DTO_AllocationChargeBalance;
  AccountTransaction: DTO_DDTransaction;
  Accounts: DTO_DeferredDuty_Account[];
  PIC: DTO_Process_JournalPIC[];
}
export interface RequestJournalCreateObj {
  Journal_Type: number | null;
  Journal_Number: number | null;
  DD_AccountId: number | null;
  PICIds: number[];
}
export interface DTO_Journal_LOVs {
  AssessmentType: DTO_LOV_Number[];
  JournalTypes: DTO_LOV_Transaction[];
  TransactionTypes: DTO_LOV_Transaction[];
  CurrentRatingPeriodId: number;
  RatingPeriods: DTO_LOV_RatingPeriod[];
  InstalmentPlans: DTO_LOV_Number[];
  PaymentAllocations: DTO_LOV_Number[];
  Levy: DTO_LOV_Levy[];
  RebateType: DTO_LOV_Number[];
  Financial_Groups: Financial_Group[];
  ValuationTypeTobeUsed: DTO_LOV[];
  IsRebateTypeTransaction: boolean;
  AssessRebateEntitlements: DTO_LOV_Number[];
  SupplementaryType: DTO_LOV_SuppType[];
  Officer: DTO_LOV_Number[];
  DDTransactionTypes: DTO_LOV[];
  ChargeTransactionClassifications: number[];
  PICTransactionTypes: DTO_LOV_Number[];
}

export interface DTO_LOV_SuppType {
  Code: string;
  Name: string;
  IsInstalmentPlanHidden: boolean;
}

export interface DTO_LOV_Transaction {
  Code: string;
  Name: string;
  Classifications: string;
  Financial_Groups: number[];
}
export type DTO_LOV_TransactionConverted = Omit<DTO_LOV_Transaction, "Code"> & {
  Code: number;
};
export interface DTO_LOV_RatingPeriod {
  Code: string;
  Name: string;
  Financial_Groups: number[];
}
export type DTO_LOV_RatingPeriodConverted = Omit<
  DTO_LOV_RatingPeriod,
  "Code"
> & { Code: number };

export interface Financial_Group {
  Rating_Period_Type_Id: number;
  Financial_Group_Id: number;
}

export interface DTO_LOV_SuppType {
  Code: string;
  Name: string;
  IsInstalmentPlanHidden: boolean;
}

export interface DTO_LOV_Journal {
  Code: string;
  Name: string;
  Classifications?: string;
}
export type DTO_LOV_JournalConverted = Omit<DTO_LOV_Journal, "Code"> & {
  Code: number;
};

export interface DTO_LOV_Levy {
  Code: number;
  Name: string;
  Financial_Group_Id: number | null;
  Transaction_Type_Id: number;
}

export interface DTO_LOV_SuppType {
  Code: string;
  Name: string;
  IsInstalmentPlanHidden: boolean;
}
export interface Financial_Group {
  Rating_Period_Type_Id: number;
  Financial_Group_Id: number;
}

export interface DTO_AssessmentTransaction {
  Journal_Number: number;
  Transaction_Type: number;
  Amount: number;
  TransactionDateTime: Date;
  TransactionReference: string;
  TransactionDescription: string;
  TransactionNotes: string;
  AllocationMode: number;
  PaymentAllocationNo: number | null;
  Instalment_Plan_Id: number | null;
  AssessmentRebateEntitlementId: number | null;
}

export interface DTO_AssessmentTransactionCharge {
  ChargeDate: Date | null;
  TransactionDate: Date | null;
  RatingPeriod: number | null;
  LevyId: number;
  Code: string;
  Name: string;
  Description: string;
  Amount: number;
  TransactionType: number;
  InstalmentPlanId: number | null;
  ApplyRemainingEntitlements: boolean;
  ShowOnlyChargeTransactionType: boolean;
}
export interface DTO_ManualChargeBalance {
  Charge_Balances_Id: number;
  BalanceAmount: number;
  Interest: number;
}
export interface AssessmentTransaction {
  Journal_Number: number;
  Transaction_Type: number;
  Amount: number;
  TransactionDateTime: Date;
  TransactionReference: string;
  TransactionDescription: string;
  TransactionNotes: string;
}

export interface DTO_Assessment {
  Valuation_2: number | null;
  Valuation_3: number | null;
  Valuation_4: number | null;
  Valuation_5: number | null;
  Valuation_6: number | null;
  Valuation_7: number | null;
  Valuation_8: number | null;
  Valuation_9: number | null;
  Valuation_10: number | null;
  Valuation_11: number | null;
  Valuation_12: number | null;
  Valuation_13: number | null;
  Valuation_14: number | null;
  Valuation_15: number | null;
  Valuation_16: number | null;
  Valuation_17: number | null;
  Valuation_18: number | null;
  Valuation_1: number | null;
  AS_Is_Assessment_Editable: boolean;
  Primary_Assess_Land_Use_Name: string;
  Assessment_Status_Name: string;
  Assessment_Id: number;
  Assess_Number: number | null;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement: number | null;
  Unit_Entitlement_Percentage: number | null;
  Valuation_19: number | null;
  Unit_Type: string;
  Assess_Balance: number;
  Assess_Balance_Outstanding: number | null;
  Assess_Being_Postponed: boolean;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Assessment_Type_Name: string;
  Assessment_Status: number;
  Unit_Type_Percentage: number | null;
  Valuation_20: number | null;
}
export interface DTO_Assessment_ChargeBalance {
  AssessmentId: number;
  ChargeBalances: DTO_ChargeBalance[];
}

export enum TransactionAllocationMode {
  NewCharge,
  Manual,
  Sequence,
}

export enum JournalKeysOfSteps {
  NewJournal = "CreateJournal",
  NewSupplementary = "CreateSupplementary",
  AssessmentTransaction = "AssessmentTransaction",
  NewChargeDetail = "NewCharge",
  AllocationChargeBalances = "AllocationChargeBalance",
  Comments = "Comments",
  Documents = "Documents",
  Workflow = "SecondaryWorkflow",
  AccountTransaction = "AccountTransaction",
  Accounts = "Accounts",
  PIC = "PIC",
}

export const journalKeysOfSendSteps = [
  JournalKeysOfSteps.NewJournal,
  JournalKeysOfSteps.NewSupplementary,
  JournalKeysOfSteps.AssessmentTransaction,
  JournalKeysOfSteps.NewChargeDetail,
  JournalKeysOfSteps.AccountTransaction,
  JournalKeysOfSteps.AllocationChargeBalances,
  JournalKeysOfSteps.Workflow,
  JournalKeysOfSteps.PIC,
];
export enum EJournalDefaultType {
  CreateJournal = 0,
  AddJournal = 76,
}
