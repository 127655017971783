import { BuildingUnitCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/building-unit-cell/_index";
import { EstateCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/estate-cell/_index";
import { NumberInputCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/number-input-cell/_index";
import { OwnerCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/owner-cell/_index";
import { PlanTypeCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/plan-type-cell/_index";
import { StreetLocalityCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/street-locality-cell/_index";
import { StreerSideCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/street-side-cell/_index";
import { DTO_Progeny } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Progeny>();
export const colAssessmentProgeny: IColumnFields[] = [
  {
    field: nameOf("Lot"),
    title: "Lot",
    editable: true,
  },
  {
    field: nameOf("Plan_Type_Name"),
    title: "Plan Type",
    editable: true,
    editCell: PlanTypeCell,
  },
  {
    field: nameOf("Plan_Number"),
    title: "Plan Number",
    editable: true,
  },
  {
    field: nameOf("Land_Area"),
    title: "Land Area (m²)",
    editable: true,
    format: DECIMAL_FORMAT.DECIMAL1,
    editCell: NumberInputCell,
  },
  {
    field: nameOf("Frontage"),
    title: "Frontage (m)",
    editable: true,
    format: DECIMAL_FORMAT.DECIMAL1,
    editCell: NumberInputCell,
  },
  {
    field: nameOf("Parcel_Reference"),
    title: "Parcel Reference",
    editable: true,
  },
  {
    field: nameOf("Building_Unit_Name"),
    title: "Building Unit",
    editable: true,
    editCell: BuildingUnitCell,
  },
  {
    field: nameOf("Building_Unit_Number"),
    title: "Building Unit Number",
    editable: true,
  },
  {
    field: nameOf("House_Number"),
    title: "House Number",
    editable: true,
  },
  {
    field: nameOf("Street_And_Locality"),
    title: "Street & Locality",
    editable: true,
    editCell: StreetLocalityCell,
  },
  {
    field: nameOf("Side_Of_Street"),
    title: "Side of Street",
    editable: true,
    editCell: StreerSideCell,
  },
  {
    field: nameOf("Estate"),
    title: "Estate",
    editable: true,
    editCell: EstateCell,
  },
  {
    field: nameOf("Estate_Stage_Number"),
    title: "Estate Stage Number",
    editable: true,
  },
  {
    field: nameOf("GIS_Reference"),
    title: "GIS References",
    editable: true,
  },
  {
    field: nameOf("Owners"),
    title: "Owners",
    editable: true,
    editCell: OwnerCell,
  },
];
