export enum EModifyCertificateKeysOfSteps {
  ApplicantDetails = "Applicant",
  CertificateDetails = "CertificateDetails",
  RateDepartmentDetails = "RateDetail",
  Names = "Names",
  Questionnaire = "Questionnaire",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfStepsModifyCertificate = [
  EModifyCertificateKeysOfSteps.ApplicantDetails,
  EModifyCertificateKeysOfSteps.CertificateDetails,
  EModifyCertificateKeysOfSteps.RateDepartmentDetails,
  EModifyCertificateKeysOfSteps.Names,
  EModifyCertificateKeysOfSteps.Questionnaire,
];
