/**
 * Replaces all occurrences of a target string with a replacement string in a source string, ignoring case.
 *
 * @param source - The source string.
 * @param target - The target string to be replaced.
 * @param replacement - The replacement string.
 * @returns The modified string with all occurrences of the target string replaced.
 */
export const replaceIgnoreCase = (
  source: string,
  target: string,
  replacement: string
) => {
  // Use a regular expression for case-insensitive global search ('gi')
  const regex = new RegExp(target, "gi");
  return source.replace(regex, replacement);
};

/**
 * Returns the index within the source string of the last occurrence of the target string, ignoring case.
 *
 * @param source - The source string to search within.
 * @param target - The target string to search for.
 * @returns The index of the last occurrence of the target string within the source string, ignoring case.
 */
export const lastIndexOfIgnoreCase = (source: string, target: string) => {
  return source.toLowerCase().lastIndexOf(target.toLowerCase());
};
