import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const systemAdminRoute: ICCRoute = {
  path: "system-admin",
  name: "System Admin",
  enum: AnimalsMenu.Administration,
  children: [
    {
      path: "animal-types",
      name: "Animal Types",
      enum: AnimalsMenu.PermitTypes,
      component: require("./animal-types/_index").default,
      children: [
        {
          path: ":id(new|[0-9]+)",
          component: require("./animal-types/[id]/_index").default,
        },
      ],
    },
    {
      path: "settings",
      name: "Settings",
      component: require("./settings/_category/_index").default,
      enum: AnimalsMenu.Settings,
    },
  ],
};
