import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface ActionBarInfoIconProps {
  disabled?: boolean;
}
export const ActionBarInfoIcon = observer(({disabled} : ActionBarInfoIconProps) => {
  const { setIsShowSlideBar, isShowSlideBar, isEmptyData } =
    useCCProductListViewStore();

  return (
    <CCNavButton
      iconClass="fal fa-info-circle"
      title="Show Information"
      disabled={isEmptyData || disabled}
      onClick={() => {
        setIsShowSlideBar(!isShowSlideBar);
      }}
      isActive={isShowSlideBar && !isEmptyData}
    />
  );
});
