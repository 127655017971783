import { DTO_Assessment_AnnualReturnLandStock } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const loadAssessmentAnnualStockList = async (
  assessmentId: number
): Promise<APIResponse<DTO_Assessment_AnnualReturnLandStock | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Assessment_AnnualReturnLandStock>(
      `api/property/internal/assessment/${assessmentId}/annualstockreturn`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
