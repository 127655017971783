import { FormAction } from "@app/products/crms/model";

export const crmsUnresolvedEventListViewURL = (formAction: FormAction) => {
  return `odata/crms/internal/events/GetUnresolvedEvents(formAction=${formAction},flagITSupport=false)?$count=true&`;
};

export const crmsUnresolvedEventUnreadMailViewURL = (
  formAction: FormAction
) => {
  return `odata/crms/internal/events/GetUnresolvedEvents(formAction=${formAction},flagITSupport=false,showOpenEventsOnly=true)?$count=true&`;
};

export const crmsUnresolvedEventAfterHoursServiceViewURL = (
  formAction: FormAction
) => {
  return `odata/crms/internal/events/GetUnresolvedEvents(formAction=${formAction},flagITSupport=false,showOpenEventsOnly=true,isAfterHoursService=true)?$count=true&`;
};
