import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import {
  DTO_Variable,
  eSqlDataType,
} from "@app/products/property/assessments/components/dialogs/write-off-small-balances/model";
import React from "react";
import { TextInputCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/custom-cells/text-input-cell/_index";
import { NumberInputCell } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/custom-cell/number-input-cell/_index";

const nameOf = nameOfFactory<DTO_Variable>();
export const colWriteOffSmallBalances: IColumnFields[] = [
  {
    field: nameOf("Variable_Prompt"),
    title: "Prompt",
  },
  {
    field: nameOf("Variable_Default"),
    title: "Value",
    editable: true,
    editCell: (props: ICustomEditCell) => {
      switch (props.dataItem.Variable_Data_Type) {
        case eSqlDataType.MONEY:
          return <NumberInputCell {...props} />;
        case eSqlDataType.BIT:
          return <NumberInputCell {...props} max={1} />;
        case eSqlDataType.INT:
          return <NumberInputCell {...props} format={NUMBER_FORMAT.NUMBER2} />;
        default:
          return <TextInputCell {...props} />;
      }
    },
    format: (props: any) => {
      switch (props?.Variable_Data_Type) {
        case eSqlDataType.MONEY:
          return {
            customFormat: CURRENCY_FORMAT.CURRENCY1,
            defaultFormat: CURRENCY_FORMAT.CURRENCY1,
          };
        case eSqlDataType.BIT:
        case eSqlDataType.INT:
          return {
            customFormat: NUMBER_FORMAT.NUMBER2,
            defaultFormat: NUMBER_FORMAT.NUMBER2,
          };
        default:
          return {}; // Empty object represents the string format
      }
    },
    width: 150,
    filterable: false,
  },
  { field: nameOf("Variable_Name"), title: "Variable Name" },
  {
    field: nameOf("Variable_Data_Type"),
    title: "SQL Data Type",
  },
  {
    field: nameOf("Variable_Id"),
    title: "Variable ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
