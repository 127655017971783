import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { IColumnFields } from "@components/cc-grid/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { colList } from "@app/products/property/notice-groups/config";
configure({ enforceActions: "always" });

class NoticeGroupsStore {
  private _columnNoticeGroups: IColumnFields[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get columnNoticeGroups() {
    return this._columnNoticeGroups;
  }
  setColumnNoticeGroups = (columnConfig: IColumnFields[]) => {
    runInAction(() => {
      this._columnNoticeGroups = columnConfig;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  loadConfigs = async () => {
    this.setIsLoading(true);
    this.setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.NoticeGroups_List,
      colList
    );
    if (Array.isArray(response)) {
      this.setColumnNoticeGroups(response);
    } else {
      this.setResponseLoadError(response);
    }
    this.setIsLoading(false);
  };

  //restore
  resetStore = () => {
    runInAction(() => {
      this.setColumnNoticeGroups([]);
      this.setIsLoading(false);
      this.setResponseLoadError();
    });
  };

  //reload
  reLoadApiConfig = () => {
    this.loadConfigs();
  };
}

const noticeGroupsStoreContext = createContext(new NoticeGroupsStore());
export const useNoticeGroupsStore = () => {
  return useContext(noticeGroupsStoreContext);
};
