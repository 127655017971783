import { CommunicationQueueFormElement } from "@common/pages/settings/communication/queue/_id/components/child-screens/general/components/form-element/_index";
import { useCommunicationQueueStore } from "@common/pages/settings/communication/queue/_id/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const CommunicationQueueForm = observer(() => {
  const { communicationQueue } = useCommunicationQueueStore();

  return (
    <div className="cc-form">
      <Form
        initialValues={communicationQueue}
        key={JSON.stringify(communicationQueue)}
        render={(formRenderProps: FormRenderProps) => {
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <CommunicationQueueFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
