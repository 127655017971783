import { getApplicationCategoryOdataURL } from "@app/products/town-planning/ppr/psa-referrals/_id/components/category-picker/utils";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import { colCategoryPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/category-picker/config";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

export interface ICategoryPickerOfficerAssessmentProps
  extends ICCInputPickerProps {}

export const CategoryPickerOfficerAssessment = (
  props: ICategoryPickerOfficerAssessmentProps
) => {
  const nameOf = nameOfFactory<PPRApplicationCategory>();
  const { onError, ...others } = props;

  return (
    <CCInputPicker
      {...others}
      dialog={{
        titleHeader: "Pick Categories",
        maxWidth: "40%",
      }}
      grid={{
        columnFields: colCategoryPicker,
        primaryField: nameOf("ID"),
        selectableMode: "multiple",
        dataUrl: getApplicationCategoryOdataURL(RECORDTYPE.TP_Application),
        isAutoHiddenPager: false,
        state: {
          sort: [{ field: nameOf("ApplicationCategory_Name"), dir: "asc" }],
        },
      }}
    />
  );
};
