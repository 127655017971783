import { ExistedFurtherInfo } from "@app/core/further-info/[id]/components/forms/existed/_index";
import { NewFurtherInfo } from "@app/core/further-info/[id]/components/forms/new/_index";
import { IParentFISection } from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManageFurtherInfo = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const furtherInfoId = params.id;
  const { resetStore, loadFurtherInfo } = useFurtherInfoStore();
  const { resetMenu } = useCCSubActionBarStore();
  const { state } = useLocation<{ parent: IParentFISection } | undefined>();

  useEffect(() => {
    loadFurtherInfo(
      furtherInfoId ? +furtherInfoId : undefined,
      isNew,
      state?.parent
    );
    window.history.replaceState({}, "");
    // eslint-disable-next-line
  }, [furtherInfoId, isNew, state]);

  useEffect(() => {
    return () => {
      resetStore();
      resetMenu();
    };
    // eslint-disable-next-line
  }, []);

  if (isNew) {
    return <NewFurtherInfo />;
  }
  return <ExistedFurtherInfo />;
};

export default observer(ManageFurtherInfo);
