import { loadParcelCertificate } from "@app/products/property/parcels/[id]/components/child-screens/certificates/api";
import { DTO_Component_Certificates } from "@app/products/property/parcels/[id]/components/child-screens/certificates/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ParcelCertificateStore {
  private _certificate?: DTO_Component_Certificates = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get certificate() {
    return this._certificate;
  }
  setCertificate = (certificate?: DTO_Component_Certificates) => {
    runInAction(() => {
      this._certificate = certificate;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  //Action
  loadParcelCertificate = async (parcelId: number) => {
    this.setIsLoading(true);
    const certificate = await loadParcelCertificate(parcelId);
    this.setCertificate(certificate);
    this.setIsLoading(false);
  };

  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._certificate = undefined;
    });
  };
}

const parcelCertificateStoreContext = createContext(
  new ParcelCertificateStore()
);
export const useParcelCertificateStore = () => {
  return useContext(parcelCertificateStoreContext);
};
