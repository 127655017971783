import { AlertIcon, IconCell } from "@app/products/crms/config";
import { calculatorDynamicWidthIconColumn } from "@app/products/crms/util";
import { DTO_AssessmentEntity } from "@app/products/property/assessments/[id]/components/child-screens/contacts/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
const nameOf = nameOfFactory<DTO_AssessmentEntity>();
export const colContacts: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: DTO_AssessmentEntity) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem?.Id}`,
  },
  {
    field: nameOf("HasAlert"),
    title: "Alert",
    format: BOOLEAN_FORMAT.BOOLEAN,
    cell: (props: GridCellProps) => (
      <IconCell props={props}>
        <AlertIcon dataItem={props.dataItem} />
      </IconCell>
    ),
    iconHeaderCell: <i title="Alert" className="fas fa-bell-exclamation" />,
    dynamicStyle: (state: State) => ({
      width: calculatorDynamicWidthIconColumn(55, state, "HasAlert"), // Get dynamic width column
    }),
  },
  {
    field: nameOf("Role"),
    title: "Role",
  },
  {
    field: nameOf("Address_Formatted"),
    title: "Address",
  },
  {
    field: nameOf("Gender"),
    title: "Gender",
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ChangeOfOwnership_Reference"),
    title: "Change Reference",
  },
  {
    field: nameOf("ChangeOfOwnership_EffectiveDate"),
    title: "Effective Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChangeOfOwnership_Id"),
    title: "Change of Ownership ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Contact_Name_Address_Id"),
    title: "ID",
    style: formatStyleUnRelevant,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
