import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import {
  EPrivilegesWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IRaiseInterestCommonButtonProps {
  isShowAssessmentInfo?: boolean;
  buttonName?: string;
  isDisabled?: boolean;
  assessmentID?: number;
}
export const RaiseAssessmentInterestCommonButton = ({
  isShowAssessmentInfo = false,
  buttonName = "Raise interest",
  isDisabled = false,
  assessmentID,
}: IRaiseInterestCommonButtonProps) => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { id } = useParams<{ id: string }>();

  const assessmentId = useMemo(() => {
    return id ?? assessmentID;
  }, [assessmentID, id]);

  return (
    <PrivilegeWrapper
      dialogType={ePermissionPrivilegeTypeCheck.Workflow}
      privilegesEnum={EPrivilegesWorkflow.Journal_Raise_Penalty_Interest}
    >
      <CCNavButton
        title={buttonName}
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_Assessment_Raise_Interest,
              data: {},
              props: { isShowAssessmentInfo, assessmentId },
            },
          ]);
        }}
      />
    </PrivilegeWrapper>
  );
};
