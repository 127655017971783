import { mockSchemeList } from "@app/products/property/schemes/list/all/mock";
import {
  mockScheme,
  mockSchemeFormGeneral,
} from "@app/products/property/schemes/[id]/mock";
import { ISchemeForm } from "@app/products/property/schemes/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";

export const getSchemeDetailById = async (
  id: number
): Promise<APIResponse<ISchemeForm | undefined>> => {
  await sleep(1000);
  const dataResponse = mockSchemeList.All.find(
    (item: any) => item?.SchemeId === id
  );
  if (dataResponse) {
    return {
      data: { General: { ...mockScheme, SchemeId: id }, Accounts: [] },
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};

export const getNewSchemeDetailById = async (
  id: number
): Promise<APIResponse<ISchemeForm | undefined>> => {
  await sleep(1000);
  return {
    data: {
      General: { ...mockSchemeFormGeneral },
      Accounts: [],
    },
    status: APIResponseStatus.SUCCESS,
    statusText: "Ok",
    headers: {},
    config: {},
  };
};
