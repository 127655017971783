import {
  DATETIME_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colReadings: IColumnFields[] = [
  {
    field: "MeterNumber",
    title: "Meter Number",
  },
  {
    field: "ReadingDate",
    title: "Reading Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "MeterReading",
    title: "Meter Reading",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Usage",
    title: "Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "MeterUnits",
    title: "Meter Units",
  },
  {
    field: "BaseUsage",
    title: "Base Usage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "BaseUnits",
    title: "Base Units",
  },
  {
    field: "DaysSinceLastRead",
    title: "Days Since Last Read",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "AverageDailyUsage",
    title: "Average Daily Usage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: "Reader",
    title: "Reader",
  },
  {
    field: "ReadingStatus",
    title: "Reading Status",
  },
  {
    field: "JournalNumber",
    title: "Journal Number",
  },
  {
    field: "EnterBy",
    title: "Enter By",
  },
  {
    field: "EnteredOn",
    title: "Entered On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "MeterReadingId",
    title: "Meter Reading ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "MeterId",
    title: "Meter ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
