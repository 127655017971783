import { useRejectLetterButtonStore } from "@app/core/further-info/components/buttons/work-flow/reject-letter/store";
import {
  FURTHERINFOSTATUS,
  FurtherInfoSubmitActions
} from "@app/core/further-info/[id]/model";
import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const RejectLetterButton = observer(() => {
  const { isLoading } = useRejectLetterButtonStore();
  const { furtherInfo, onSubmit } = useFurtherInfoStore();

  const isVisible = useMemo(
    () => furtherInfo?.Status_ENUM === FURTHERINFOSTATUS.InfoWaitingForApproval,
    [furtherInfo]
  );

  return isVisible ? (
    <CCNavButton
      title={"Reject letter"}
      isLoading={isLoading}
      disabled={isLoading}
      onClick={onSubmit}
      name={FurtherInfoSubmitActions.RejectLetter}
    />
  ) : null;
});
