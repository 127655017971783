import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { LeaseTransfersSuspendDialog } from "@app/products/property/changes-of-ownership/lease-transfers/components/dialogs/suspend/_index";

export const LeaseTransfersSuspendButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const [showSuspendDialog, setSuspendDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="Suspend"
        disabled={gridSelectedRows?.length === 0}
        onClick={() => setSuspendDialog(true)}
      />

      {showSuspendDialog && (
        <LeaseTransfersSuspendDialog
          onClose={() => {
            setSuspendDialog(false);
          }}
        />
      )}
    </>
  );
});
