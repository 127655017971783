import { useStores } from "@app/products/ams/api/helpers/use-stores";
import PanelBarTitle from "@app/products/ams/components/PanelBarTitle";
import { ItemTypes } from "@app/products/ams/util/itemTypes";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { useObserver } from "mobx-react-lite";
import React, { Fragment } from "react";
import GroupLayoutElement from "./GroupLayoutElement";
import NewItemLayoutElement from "./NewItemLayoutElement";
import QuestionLayoutElement from "./QuestionLayoutElement";

const InspectionPanelElements = () => {
  const {
    dataStores: { inspectionAssessmentStore },
  } = useStores();
  return useObserver(() => {
    return (
      <Fragment>
        <PanelBar expandMode="multiple">
          <PanelBarItem title={<PanelBarTitle title="New Elements" />}>
            <NewItemLayoutElement
              title="Group"
              itemType={ItemTypes.PanelNewItemLayoutElement}
            />
            <NewItemLayoutElement
              title="Question"
              itemType={ItemTypes.PanelNewItemLayoutElement}
            />
          </PanelBarItem>

          {inspectionAssessmentStore.assessmentTemplates.map((mdl) => {
            return (
              <PanelBarItem
                title={
                  <PanelBarTitle
                    title={mdl.assessmentHeading}
                    counter={mdl.assessmentGroups.length}
                  />
                }
              >
                {mdl.sortedGroups.map((grp) => {
                  return (
                    <PanelBarItem
                      headerClassName="p-0"
                      title={
                        <GroupLayoutElement
                          parentModel={mdl.assessmentHeading}
                          title={grp.groupHeading}
                          counter={grp.questions.length}
                        />
                      }
                    >
                      {grp.sortedQuestions.map((q) => {
                        return (
                          <QuestionLayoutElement
                            parentModelName={mdl.assessmentHeading}
                            parentGroupName={grp.groupHeading}
                            question={q}
                          />
                        );
                      })}
                    </PanelBarItem>
                  );
                })}
              </PanelBarItem>
            );
          })}
        </PanelBar>
      </Fragment>
    );
  });
};

export default InspectionPanelElements;
