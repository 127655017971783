import { apiCoreGetLightweightSiteUser } from "@app/core/components/common/utils";
import { SiteUser } from "@app/products/waste-water/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getSiteUser = async (
  id: number
): Promise<APIResponse<SiteUser | undefined>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiCoreGetLightweightSiteUser(id)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
