import { sleep } from "@common/utils/common";
import { mockMeterData } from "@components/cc-search-meter/mock";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const getMeterData = async (
  filter: FilterDescriptor,
  apiUrl: string
) => {
  const result = mockMeterData.map((meter: any) => {
    return {
      ...meter,
      MeterId: meter.MeterId + "",
      MeterNumber: meter.MeterNumber + "",
    };
  });
  const data = result.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
