import { IRegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/model";

export const mockRegisterNames: IRegisterNames = {
  RegisterNamesGrid: [
    {
      Role: "Deceased",
      Name: "HOLUB, Paul",
      Address: "PO Box 327, Beaconsfield Upper, VIC 6208",
      Gender: "M",
      DateOfBirth: new Date("11-Sep-1946"),
      IsActive: true,
      EntityNameAddrId: 15111,
    },
  ],
  RegisterNamesFields: {
    Name: "HOLUB, Paul",
    Gender: "M",
    Occupation: null,
    DateOfBirth: new Date("11-Sep-1946"),
    EmailAddress: null,
    Notes: null,
    Private: null,
    Business: null,
    Mobile: null,
    Fax: null,
    Address: "PO Box 327, Beaconsfield Upper, VIC 6208",
    AlternateAddress: null,
  },
};
