import AdditionalDetails from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/additional-details/_index";
import { ECertificateRequestForm } from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/general/components/_index";
import RequestResponse from "@app/products/property/certificates/e-certificate-requests/[id]/components/child-screens/request-response/_index";
import { ECertificateRequestDetailTab } from "@app/products/property/certificates/e-certificate-requests/[id]/components/reference-sidebar/detail/_index";
import { useECertificateRequestStore } from "@app/products/property/certificates/e-certificate-requests/[id]/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { eAccordion } from "@app/products/property/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedECertificateRequest = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const {
    eCertificateRequest,
    isLoading,
    loadECertificateRequest,
    resetStore,
    responseLoadError,
  } = useECertificateRequestStore();
  const { id } = useParams<{ id: string }>();
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;

  useEffectOnce(() => {
    loadECertificateRequest(parseInt(id));
    return () => {
      resetStore();
    };
  });

  const formTitle = useMemo(() => {
    return `${eCertificateRequest?.AgentReference} - ${eCertificateRequest?.Name}`;
  }, [eCertificateRequest]);

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: formTitle,
      LinkUrl: managePageUrl,
      LinkText: `Property - Certificate - E Certificate Requests - ${parseInt(
        id
      )}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Certificate,
      Record_ID: parseInt(id),
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.E_Certificate_Request_Additional_Details,
      title: "Additional Details",
      component: <AdditionalDetails />,
    },
    {
      accordionId: eAccordion.E_Certificate_Request_Request_Response,
      title: "Request Response",
      component: <RequestResponse />,
    },
    {
      accordionId: eAccordion.E_Certificate_Request_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Certificate_Request}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="eCERTIFICATE REQUEST" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadECertificateRequest(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={formTitle}
            badge={eCertificateRequest?.OrderStatus || ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Allocate to planner"} />
                <CCNavButton title={"Failure"} />
                <CCNavButton title={"Revision received"} />
                <CCNavButton title={"Withdraw application"} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <AddActionButton />
                <CCNavButton title={"Add comments"} />
                <CCNavButton title={"Add contacts"} />
                <CCNavButton title={"Add documents"} />
                <CCNavButton title={"Add fees"} />
                <CCNavButton title={"Add samples"} />
                <CCNavButton title={"Add inspection"} />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,

              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"ePlanning - development"} />
                <CCNavButton title={"Portal links"} />
                <CCNavButton title={"Certification"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {eCertificateRequest && (
                  <CCGeneralPanel component={<ECertificateRequestForm />} />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <ECertificateRequestDetailTab />,
                    },
                    {
                      title: "Map",
                      component: <MapTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Certificate}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
