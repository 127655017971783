import { WorkflowTypes } from "@app/products/property/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { useDeferredDutyTransactionStore } from "@app/products/property/deferred-duty/[id]/components/child-screens/transaction/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const WriteOffBalancesButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedTransactionIds } = useDeferredDutyTransactionStore();

  return (
    <CCNavButton
      title="Write off balances"
      disabled={gridSelectedTransactionIds?.length !== 1}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_DD_Writeoff_Balance,
            data: {},
          },
        ]);
      }}
    />
  );
});
