import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import { PARKING_PERMITS_ROUTE } from "@app/products/parking-permits/[id]/constant";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router";

export const DisabledSameAddrButton = observer(() => {
  const { gridSelectedRows, lastSelectedRow } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const history = useHistory();
  const dataNewForm = lastSelectedRow && {
    PermitHolder_DisplayName:
      lastSelectedRow[ParkingPermitsFieldMapping.PermitHolder_DisplayName],
    PermitHolder_Email:
      lastSelectedRow[ParkingPermitsFieldMapping.PermitHolder_Email],
    ContactPermitHolder:
      lastSelectedRow[ParkingPermitsFieldMapping.ContactPermitHolder],
    StreetName: lastSelectedRow[ParkingPermitsFieldMapping.StreetName],
  };

  const handleOnClick = () => {
    if (gridSelectedRows.length === 0) {
      pushNotification({
        title: "Please select atleast one record",
        type: "warning",
      });
    } else if (gridSelectedRows.length === 1) {
      const urlHistoryDisable: string = `${PARKING_PERMITS_ROUTE}/new-disabled`;
      history.push(urlHistoryDisable, {
        sameAddressData: dataNewForm,
      });
    } else {
      pushNotification({
        title: "Please select a single record",
        type: "warning",
      });
    }
  };

  return (
    <CCNavButton
      title="Disabled-Same Addr."
      onClick={() => {
        handleOnClick();
      }}
    />
  );
});
