import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { PropertyRegisterFormElement } from "./components/form-element/_index";

export const PropertyRegisterForm = observer(() => {
  const { register } = useRegisterStore();
  return (
    <div className="cc-form">
      <Form
        // onSubmit={handleSubmit}
        initialValues={register}
        key={JSON.stringify(register)}
        render={(formRenderProps: FormRenderProps) => (
          <PropertyRegisterFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
