import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import {
  FurtherInformationRequest,
  IParentFISection,
} from "@app/core/further-info/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddFIRequestButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  data?: FurtherInformationRequest;
}

export const AddFIRequestButton = ({
  id,
  recordType,
  data,
}: IAddFIRequestButtonProps) => {
  const history = useHistory();
  return (
    <CCNavButton
      title={"Further information request"}
      onClick={() => {
        history.push(`${FURTHER_INFO_ROUTE}/new`, {
          parent: {
            id: id,
            recordType: recordType,
            data: data,
          } as IParentFISection,
        });
      }}
    />
  );
};
