import { saveMailMergeDocument } from "@app/core/mail-merge/buttons/api";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { MailMergeDialog } from "@app/core/mail-merge/form-steps/_index";
import { IMailMergeSelection } from "@app/core/mail-merge/form-steps/model";
import { useMailMergeStore } from "@app/core/mail-merge/store";
import { DownloadFile } from "@common/constants/downloadfile";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IMailMergeButtonProps {
  productType?: PRODUCT_TYPE_NUMBER;
  mailMergeDataSet?: MAILMERGEDATASET;
  recordType?: RECORDTYPE;
}

export const MailMergeButton = observer((props: IMailMergeButtonProps) => {
  const { productType, mailMergeDataSet, recordType } = props;
  const { gridSelectedRows, isEmptyGridSelectedRow } =
    useCCProductListViewStore();
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const [isShowMailMergeSelection, setIsShowMailMergeSelection] =
    useState(false);

  const { setMailMergeSelection, mailMergeSelection }: any =
    useMailMergeStore();

  const handleMailMergeButton = () => {
    if (gridSelectedRows.length >= 1) {
      clearNotifications();
      setMailMergeSelection({
        SelectedRecordIDs: gridSelectedRows
          .map((item: any) => item.ID)
          .filter((id) => id !== null),
        MailMergeDataset: mailMergeDataSet, //MAILMERGEDATASET.HM_PREMISES,
        RecordType: recordType, //RECORDTYPE.HM_PREMISES,  // need to verify the naming
      });
      setIsShowMailMergeSelection(true);
    }
  };

  const handleSubmit = (value: any) => {
    const mailMergeSelectionData: IMailMergeSelection = {
      SelectedRecordIDs: value.Template.SelectedRecordIDs,
      SelectedMailMergeDocumentIDs: value.Template.SelectedMailMergeDocumentIDs,
      RecordType: value.Template.RecordType,
      AppendToRecordSource: value.Options.AppendToRecordSource,
      BatchPrint: value.Options.BatchPrint,
      Collate: value.Options.Collate,
      Category: value.Template.SelectedCategory.Keyword_ID,
      SaveAsPdf: value.Options.SaveAsPdf,
    };

    saveMailMergeDocument(mailMergeSelectionData, productType)
      .then((response: any) => {
        customLogger(
          "Core Mail Merge, MailMergeButton, saveMailMergeDocument"
        ).info(response?.data);
        if (
          response.data &&
          response.data.ErrorStatus === IdentityPacketErrorStatus.Success
        ) {
          pushNotification({
            type: "success",
            title: "Mail merge process has been queued to run on the serve",
          });

          setIsShowMailMergeSelection(false);
          //Download base64 formatted data
          if (
            mailMergeSelectionData?.Collate === true &&
            response.data.Data != null &&
            response.data.Data.length > 0
          ) {
            DownloadFile(
              response.data.Data,
              response.data.ContentType,
              response.data.FileName
            );
          }
        } else {
          pushNotification({
            type: "error",
            title: "Error occurred",
            autoClose: false,
          });
        }
      })
      .catch(() => {
        pushNotification({
          type: "error",
          title: "Error occurred",
          autoClose: false,
        });
      });
    setIsShowMailMergeSelection(false);
  };

  return (
    <>
      <CCNavButton
        title="Mail Merge"
        disabled={isEmptyGridSelectedRow}
        onClick={() => {
          handleMailMergeButton();
        }}
      />
      {isShowMailMergeSelection && (
        <MailMergeDialog
          mailMergeSelection={{ ...mailMergeSelection }}
          productType={productType}
          onClose={() => {
            setIsShowMailMergeSelection(false);
          }}
          onSubmit={(data) => {
            handleSubmit(data);
          }}
        />
      )}
    </>
  );
});
