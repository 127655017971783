import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { VO_Certificate } from "@app/products/property/certificates/list/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Certificate>();
export const colList: IColumnFields[] = [
  {
    field: nameOf("Cert_Number"),
    title: "Certificate Number",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_CERTIFICATE_ROUTE}/${dataItem.Certificate_Id}`,
  },
  {
    field: nameOf("Certificate_Type_Name"),
    title: "Type",
  },

  {
    field: nameOf("Cert_Originated_DateTime"),
    title: "Date/Time Originated",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Cert_Applicant_Name"),
    title: "Applicant",
  },
  {
    field: nameOf("Cert_Applicant_Reference"),
    title: "Applicant Reference",
  },
  {
    field: nameOf("Cert_Description"),
    title: "Description",
  },
  {
    field: nameOf("Cert_Balance_Owing"),
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Cert_Issued_DateTime"),
    title: "Issued On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Cert_Issued_By"),
    title: "Issued By",
  },
  {
    field: nameOf("Certificate_Status_Name"),
    title: "Certificate Status",
  },
  {
    field: nameOf("Certificate_Id"),
    title: "Certificate Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
