import { IPremisesBatchUpdate } from "@app/products/hm/components/batch-update/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postPremisesBatchUpdate = async (
  premisesBatchUpdate: IPremisesBatchUpdate,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/hm/internal/premises-batch-update",
      premisesBatchUpdate,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
