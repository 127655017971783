import { CSL_SYSTEM_ADMIN_CATEGORIES_ROUTE } from "@app/products/csl/system-admin/categories/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";

export const colCSLSystemAdminCategories = [
  {
    field: "Name",
    title: "Name",
    linkTo: (dataItem: any) =>
      `${CSL_SYSTEM_ADMIN_CATEGORIES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: "Code",
    title: "Licence Code",
  },
  {
    field: "Flag_PestControl",
    title: "Pest Control",
  },
  {
    field: "Type",
    title: "Classification",
  },
  {
    field: "CurrentFee",
    title: "Current Fee",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "FutureFee",
    title: "Future Fee",

    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "FutureFeeDate",
    title: "Cut Off Date",
    format: DATE_FORMAT.DATE,
  },
];
