import { DirectDebitStatusCode } from "@app/products/direct-debit/system-admin/model";
import { SaveButtonDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/buttons/save/_index";
import { DirectDebitStatusCodeForm } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/general/_index";
import { TitleDirectDebitStatusCodeManagePage } from "@app/products/direct-debit/system-admin/status-codes/constant";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageDirectDebitStatusCode = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const directDebitStatusCode = dataForms?.GeneralForm as DirectDebitStatusCode;

  return (
    <>
      <FormTitle title={`New ${TitleDirectDebitStatusCodeManagePage}`} />
      <CCManagePageActionBar
        leftActions={[<SaveButtonDirectDebitStatusCode />]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitStatusCode && <DirectDebitStatusCodeForm />}
          </div>
        </div>
      </div>
    </>
  );
});
