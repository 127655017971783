import { odataCoreGetContacts } from "@app/core/components/common/utils";
import { RECORDTYPE } from "@common/constants/recordtype";
import { colContacts } from "@common/pages/contacts/config";
import { IContact } from "@common/pages/contacts/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IContactsProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOfContact = nameOfFactory<IContact>();
const Contacts: React.FC<IContactsProps> = ({ id, recordType }) => {
  return (
    <div>
      <CCGrid
        columnFields={colContacts}
        primaryField={nameOfContact("ID")}
        dataUrl={
          id === undefined ? undefined : odataCoreGetContacts(id, recordType)
        }
      />
    </div>
  );
};

export default Contacts;
