import { colAssociationsCompliance } from "@app/products/property/components/associations/components/compliance/config";
import { DTO_AssociatedItem_Compliance } from "@app/products/property/components/associations/components/compliance/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";

interface IAssociationsComplianceProps {
  associationsComplianceInfo: DTO_AssociatedItem_Compliance[] | undefined;
}
const nameOf = nameOfFactory<DTO_AssociatedItem_Compliance>();

export const AssociationsCompliance = ({
  associationsComplianceInfo,
}: IAssociationsComplianceProps) => {
  return (
    <CCGrid
      data={associationsComplianceInfo || []}
      columnFields={colAssociationsCompliance}
      primaryField={nameOf("Id")}
    />
  );
};
