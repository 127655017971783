import { Registration } from "@app/products/animals/[id]/model";
import { Svc_Registration_FeeOutstanding } from "@app/products/animals/components/forms/components/form-element/components/workflow-elements/animal-registration-details/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket } from "@common/models/identityPacket";

export const getRegistrationFeeOutstandingList = async (
  registrationId?: number
): Promise<APIResponse<Svc_Registration_FeeOutstanding[]>> => {
  try {
    return await CoreAPIService.getClient().get<
      Svc_Registration_FeeOutstanding[]
    >(`api/animals/internal/registration/fee-outstanding/${registrationId}`);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const calculationRegistrationFeeOutstanding = async (
  registration?: Registration
): Promise<
  APIResponse<IIdentityPacket<Svc_Registration_FeeOutstanding[]> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/animals/internal/registration/calculate-fee-discount`,
      registration
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
