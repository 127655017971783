import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Svc_ActionTypeTabDetails } from "@common/pages/settings/lookups/action-types/_id/components/reference-sidebar/detail/model";

export const getActionTypeTabDetailsById = async (
  actionTypeID: number
): Promise<APIResponse<Svc_ActionTypeTabDetails | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Svc_ActionTypeTabDetails>(
      `/api/core/internal/actions/actiontypes/${actionTypeID}/tabdetails`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
