import { history } from "@/AppRoutes";
import { KeyValuePair } from "@app/core/documents/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import {
  getOrgStructureLevel,
  getOrgUnitById,
  postOrgUnit,
} from "@common/pages/settings/security/org-structure/_id/api";
import { SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE } from "@common/pages/settings/security/org-structure/_id/constant";
import {
  ISecurityOrgStructureNotification,
  OrgStructureLevel,
  OrgUnit,
  SecurityOrgStructureSubmitActions,
} from "@common/pages/settings/security/org-structure/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class OrgStructureStore {
  private _orgUnit?: OrgUnit = undefined;
  private _level1?: KeyValuePair<number, string>[] = undefined;
  private _level2?: KeyValuePair<number, string>[] = undefined;
  private _level3?: KeyValuePair<number, string>[] = undefined;
  private _level4?: KeyValuePair<number, string>[] = undefined;
  private _level5?: KeyValuePair<number, string>[] = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _orgStructureNotification?: ISecurityOrgStructureNotification =
    undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get orgStructureNotification() {
    return this._orgStructureNotification;
  }
  setOrgStructureNotification = (
    orgStructureNotification: ISecurityOrgStructureNotification
  ) => {
    runInAction(() => {
      this._orgStructureNotification = orgStructureNotification;
    });
  };

  get orgUnit() {
    return toJS(this._orgUnit);
  }
  setOrgUnit = (orgUnit?: OrgUnit) => {
    runInAction(() => {
      this._orgUnit = orgUnit;
    });
  };

  getLevel(level: OrgStructureLevel) {
    let orgUnitLevel: KeyValuePair<number, string>[] | undefined;
    switch (level) {
      case OrgStructureLevel.Level1:
        orgUnitLevel = toJS(this._level1);
        break;
      case OrgStructureLevel.Level2:
        orgUnitLevel = toJS(this._level2);
        break;
      case OrgStructureLevel.Level3:
        orgUnitLevel = toJS(this._level3);
        break;
      case OrgStructureLevel.Level4:
        orgUnitLevel = toJS(this._level4);
        break;
      case OrgStructureLevel.Level5:
        orgUnitLevel = toJS(this._level5);
        break;
    }
    return orgUnitLevel;
  }

  setLevel = (
    level: OrgStructureLevel,
    data?: KeyValuePair<number, string>[]
  ) => {
    switch (level) {
      case OrgStructureLevel.Level1:
        runInAction(() => {
          this._level1 = data;
        });
        break;
      case OrgStructureLevel.Level2:
        runInAction(() => {
          this._level2 = data;
        });
        break;
      case OrgStructureLevel.Level3:
        runInAction(() => {
          this._level3 = data;
        });
        break;
      case OrgStructureLevel.Level4:
        runInAction(() => {
          this._level4 = data;
        });
        break;
      case OrgStructureLevel.Level5:
        runInAction(() => {
          this._level5 = data;
        });
        break;
    }
  };

  get level1() {
    return toJS(this._level1);
  }
  setLevel1 = (level1?: KeyValuePair<number, string>[]) => {
    runInAction(() => {
      this._level1 = level1;
    });
  };

  get level2() {
    return toJS(this._level2);
  }
  setLevel2 = (level2?: KeyValuePair<number, string>[]) => {
    runInAction(() => {
      this._level2 = level2;
    });
  };

  get level3() {
    return toJS(this._level3);
  }
  setLevel3 = (level3?: KeyValuePair<number, string>[]) => {
    runInAction(() => {
      this._level3 = level3;
    });
  };

  get level4() {
    return toJS(this._level4);
  }
  setLevel4 = (level4?: KeyValuePair<number, string>[]) => {
    runInAction(() => {
      this._level4 = level4;
    });
  };

  get level5() {
    return toJS(this._level5);
  }
  setLevel5 = (level5?: KeyValuePair<number, string>[]) => {
    runInAction(() => {
      this._level5 = level5;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._orgUnit = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._orgStructureNotification = undefined;
      this._onSubmit = undefined;
    });
  };

  get orgUnitId() {
    return toJS(this.orgUnit?.OrgUnit_ID);
  }

  reloadOrgUnit = async (): Promise<boolean> => {
    if (this.orgUnitId) {
      return await this.loadOrgUnit(this.orgUnitId);
    }
    return false;
  };

  loadLevel = async (level: OrgStructureLevel, parentOrgUnitId?: number) => {
    this.setIsLoading(true);
    // const resLevel = getOrgStructureLevel(parentOrgUnitId);
    // this.setLevel(level, resLevel);
    const resLevel = await getOrgStructureLevel(parentOrgUnitId);
    if (isSuccessResponse(resLevel)) {
      if (resLevel?.data) {
        this.setLevel(level, resLevel?.data);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          resLevel?.error ?? `Level ${OrgStructureLevel[level]} load failed`,
        type: "warning",
      });
    }
    this.setIsLoading(false);
  };

  loadOrgUnit = async (
    orgUnitID: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    this.loadLevel(OrgStructureLevel.Level1);
    // const resLevel1 = await getOrgStructureLevel();
    // if (isSuccessResponse(resLevel1)) {
    //   if (resLevel1?.data) {
    //     this.setLevel1(resLevel1?.data);
    //   }
    // } else {
    //   appNotificationStore.pushNotification({
    //     autoClose: false,
    //     title: resLevel1?.error ?? "Level 1 load failed",
    //     type: "warning",
    //   });
    // }

    if (isNew) {
      this.setOrgUnit({} as OrgUnit);
    } else {
      let newOrgUnit = undefined;
      const response = await getOrgUnitById(orgUnitID);
      if (isSuccessResponse(response)) {
        newOrgUnit = response.data as OrgUnit;
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setOrgUnit(newOrgUnit);
      if (this.orgStructureNotification?.notification) {
        this.orgStructureNotification?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setOrgStructureNotification({
          ...this._orgStructureNotification,
          notification: [],
        } as ISecurityOrgStructureNotification);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveOrgUnit = async (
    orgUnitInfo: OrgUnit,
    action: SecurityOrgStructureSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postOrgUnit(orgUnitInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.orgUnitId) await this.loadOrgUnit(this.orgUnitId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save org structure failed",
        type: "error",
        description: response.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (
    action: SecurityOrgStructureSubmitActions,
    response?: APIResponse
  ) => {
    switch (action) {
      case SecurityOrgStructureSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Org structure saved successfully",
          type: "success",
        });
        break;
      case SecurityOrgStructureSubmitActions.CreateTopLevel:
      case SecurityOrgStructureSubmitActions.CreateAdhocGroup:
      case SecurityOrgStructureSubmitActions.AddThisOrgUnit:
        appNotificationStore.clearErrorNotification();
        history.replace(
          `${SETTINGS_SECURITY_ORG_STRUCTURE_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              { title: "Org structure saved successfully", type: "success" },
            ],
          }
        );
        break;
      default:
        break;
    }
  };
}

export const orgStructureStore = new OrgStructureStore();
const OrgStructureStoreContext = createContext(orgStructureStore);
export const useOrgStructureStore = () => {
  return useContext(OrgStructureStoreContext);
};
