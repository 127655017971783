import { LLPermitsMenu } from "@app/products/local-laws/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const permitTypesRoute: ICCRoute = {
  path: "permit-types",
  name: "Permit Types",
  enum: LLPermitsMenu.PermitTypes,
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
