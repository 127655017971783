import { DirectDebitSessionLine } from "@app/products/direct-debit/sessions/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getDDessionLineBySessionNumber = async (
  sessionID: number,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitSessionLine[]> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitSessionLine[]>(
      `api/receipting/internal/directdebitsession/${sessionID}/transfers`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
