import { ExistedDeferredDutyRequest } from "@app/products/property/deferred-duty/[id]/components/forms/existed/_index";
import NewDeferredDuty from "@app/products/property/deferred-duty/[id]/components/forms/new/_index";
import { useDeferredDutyStore } from "@app/products/property/deferred-duty/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

function ManagePropertyDeferredDuty() {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { clearNotifications } = useCCAppNotificationStore();
  const deferredDutyId = parseInt(id);
  const { resetMenu } = useCCSubActionBarStore();
  const { loadDeferredDuty, resetStore, isLoading } = useDeferredDutyStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
      clearNotifications();
    };
  });
  useEffect(() => {
    if (isNew) return;
    if (deferredDutyId) {
      loadDeferredDuty(deferredDutyId);
    }
  }, [deferredDutyId, isNew, loadDeferredDuty]);

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  if (isNew) {
    return <NewDeferredDuty />;
  }
  return <ExistedDeferredDutyRequest />;
}

export default observer(ManagePropertyDeferredDuty);
