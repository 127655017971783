import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import React from "react";

interface IAssessmentHouseKeepingDialogProps {
  isLoadingYes: boolean;
  onClosePopup: () => void;
  onAsyncConfirm: () => void;
}
export default function AssessmentHouseKeepingDialog({
  isLoadingYes,
  onClosePopup,
  onAsyncConfirm,
}: IAssessmentHouseKeepingDialogProps) {
  return (
    <ConfirmDialog
      title="Housekeeping"
      message="Do you wish to run the housekeeping tool?"
      subMessage="The tool will run in the background, and a notification will be displayed upon completion."
      onClosePopup={onClosePopup}
      onAsyncConfirm={onAsyncConfirm}
      isLoadingYes={isLoadingYes}
    />
  );
}
