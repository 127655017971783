import { SettingField } from "@app/products/property/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import {
  ILookupItems,
  PROVIDER_VALUE,
  SettingFieldMoreOptions,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/payment-gateway/forms/payment-gateway-integration-settings/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(
  ({ dataSetting, formRenderProps }: IGlobalComponentFormProps) => {
    const { isEditing } = useSettingGlobalManageStore();
    const data = dataSetting.SettingFields;
    const { valueGetter, onChange } = formRenderProps;

    const changeTypeValue = (value: string, lookupItems: ILookupItems[]) =>
      filter(lookupItems, { Id: value })[0];

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            {data.map((item: SettingField & SettingFieldMoreOptions) => {
              item.show = false;
              item.hiddenField = false;
              const enumFieldName = +item.FieldName;
              // Provider:
              switch (
                valueGetter(
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_Provider.toString()
                )
              ) {
                case PROVIDER_VALUE.SecurePay:
                  item.show = [
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix, // Receipt Prefix
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableCCPayment, // Enable Credit Card Payments
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableDirectPayment, // Enable Direct Payments
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeoutSeconds, // Timeout Seconds
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeZone, // Time Zone
                  ].includes(enumFieldName);
                  if (
                    valueGetter(
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableCCPayment.toString()
                    )
                  ) {
                    item.show = [
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix, // Receipt Prefix
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableCCPayment, // Enable Credit Card Payments
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableDirectPayment, // Enable Direct Payments
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeoutSeconds, // Timeout Seconds
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeZone, // Time Zone

                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID, // Merchant ID
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCPassword, // Merchant Password
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCURL, // Credit Card URL
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCCurrency, // Currency for Credit Card Payments
                    ].includes(enumFieldName);
                  }
                  if (
                    valueGetter(
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableDirectPayment.toString()
                    )
                  ) {
                    item.show = [
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix, // Receipt Prefix
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableCCPayment, // Enable Credit Card Payments
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableDirectPayment, // Enable Direct Payments
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeoutSeconds, // Timeout Seconds
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeZone, // Time Zone

                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID, // Merchant ID
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCPassword, // Merchant Password
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCURL, // Credit Card URL
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_CCCurrency, // Currency for Credit Card Payments

                      ECorporateSettingsField.CorporateSettings_PaymentGateway_DirectMerchantID, // Direct Payment Merchant ID
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_DirectMerchantPassword, // Direct Payment Merchant ID
                      ECorporateSettingsField.CorporateSettings_PaymentGateway_DirectPaymentURL, // Direct Payment URL
                    ].includes(enumFieldName);
                  }

                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID ===
                    enumFieldName
                  )
                    item.Title = "Merchant ID";
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCPassword ===
                    enumFieldName
                  )
                    item.Title = "Merchant password";
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCURL ===
                    enumFieldName
                  )
                    item.Title = "Credit card URL";
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCCurrency ===
                    enumFieldName
                  )
                    item.Title = "Currency for credit card payments";
                  break;
                case PROVIDER_VALUE.BPOINT:
                  item.show = [
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID, // User Name
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCUserName, // API User Name
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCPassword, // API Password | Merchant Password
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix, // Receipt Prefix
                  ].includes(enumFieldName);
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID ===
                    enumFieldName
                  )
                    item.Title = "User name";
                  break;
                case PROVIDER_VALUE.AdvamDirectPost:
                  item.show = [
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID, // Advam Account | User Name | Merchant ID
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCUserName, // API User Name
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCPassword, // API Password | Merchant Password
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCURL, // Primary URL | Supported Card Types | Credit Card URL
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_SecondaryCCURL, // Secondary URL
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix, // Receipt Prefix
                  ].includes(enumFieldName);
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID ===
                    enumFieldName
                  )
                    item.Title = "Advam account";
                  break;
                case PROVIDER_VALUE.AdvamIFrame:
                  item.show = [
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID, // Advam Account | User Name | Merchant ID
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCUserName, // API User Name
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCPassword, // API Password | Merchant Password
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCURL, // Primary URL | Supported Card Types | Credit Card URL
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_SecondaryCCURL, // Secondary URL
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CardTypes, // Supported Card Types
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix, // Receipt Prefix
                  ].includes(enumFieldName);
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_ReceiptPrefix ===
                    enumFieldName
                  )
                    item.hiddenField = true;
                  if (
                    ECorporateSettingsField.CorporateSettings_PaymentGateway_CCMerchantID ===
                    enumFieldName
                  )
                    item.Title = "Advam account";
                  break;
              }
              if (
                [
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_Provider, // Provider Dropdown
                  ECorporateSettingsField.CorporateSettings_Portal_PendingPaymentExipryDays, // Pending Payment Expiry Days
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_1_Type, // CRN 1 Type
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_1_DisplayText, // CRN 1 Display Text
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_1_Prefix, // CRN 1 Prefix
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_1_Postfix, // CRN 1 Postfix
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_2_Type, // CRN 2 Type
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_2_DisplayText, // CRN 2 Display Text
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_3_Type, // CRN 3 Type
                  ECorporateSettingsField.CorporateSettings_PaymentGateway_CRN_3_DisplayText, // CRN 3 Display Text
                ].includes(enumFieldName)
              ) {
                item.show = true;
              }
              let options = null;
              if (
                ECorporateSettingsField.CorporateSettings_Portal_PendingPaymentExipryDays ===
                enumFieldName
              ) {
                options = {
                  min: 2,
                  max: 14,
                };
              }
              if (
                ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeoutSeconds ===
                enumFieldName
              ) {
                options = {
                  min: 60,
                  max: 300,
                };
              }
              return (
                item.show && (
                  <>
                    {item.DataType === "Lookup" && (
                      <div className="cc-field">
                        <label className="cc-label">{item.Title}</label>
                        <Field
                          name={item.FieldName}
                          component={CCSearchComboBox}
                          data={item?.LookupItems}
                          value={changeTypeValue(
                            valueGetter(item.FieldName),
                            item.LookupItems
                          )}
                          textField="Name"
                          dataItemKey="Id"
                          disabled={!isEditing}
                          onChange={(e: ComboBoxChangeEvent) => {
                            onChange(item?.FieldName, {
                              value: e.value?.Id ?? null,
                            });
                          }}
                        />
                      </div>
                    )}
                    {item?.DataType === "Boolean" && (
                      <div className="cc-field">
                        <label className="cc-label">{item?.Title}</label>
                        <Field
                          name={item?.FieldName}
                          component={CCSwitch}
                          disabled={
                            ECorporateSettingsField.CorporateSettings_PaymentGateway_EnableDirectPayment ===
                            +item.FieldName
                              ? true
                              : !isEditing
                          }
                          checked={valueGetter(item?.FieldName)}
                        />
                      </div>
                    )}
                    {item.DataType === "String" && (
                      <>
                        <div className="cc-field">
                          <label className="cc-label">{item.Title}</label>
                          <>
                            {[
                              ECorporateSettingsField.CorporateSettings_PaymentGateway_TimeoutSeconds,
                              ECorporateSettingsField.CorporateSettings_Portal_PendingPaymentExipryDays,
                            ].includes(+item.FieldName) ? (
                              <>
                                <Field
                                  name={item.FieldName}
                                  component={CCNumericTextBox}
                                  placeholder={item.Title}
                                  disabled={!isEditing}
                                  {...options}
                                />
                              </>
                            ) : (
                              <Field
                                name={item.FieldName}
                                component={CCInput}
                                placeholder={item.Title}
                                disabled={!isEditing}
                              />
                            )}
                          </>
                        </div>
                        {item.hiddenField && <CCValueField />}
                      </>
                    )}
                  </>
                )
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
