import React from "react";
import { useDrag } from "react-dnd";

export interface INewItemDragItem {
  type: string;
  title: string;
}

interface IProps {
  title: string;
  itemType: string;
}

const NewItemLayoutElement: React.FC<IProps> = ({ title, itemType }) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: itemType,
      title: title,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      className="form-row col-auto vw-100 border p-1"
      ref={drag}
      style={{ opacity: isDragging ? "0.5" : "1" }}
    >
      <div className="col-9">
        <label className="label">{title}</label>
      </div>
    </div>
  );
};

export default NewItemLayoutElement;
