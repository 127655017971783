import { MeterFormElement } from "@app/products/property/meters/[id]/components/child-screens/general/components/form-element/_index";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";

interface Props {
  isNew: boolean;
}

export const MeterForm = observer(({ isNew }: Props): ReactElement => {
  const { meter } = useMeterStore();

  return (
    <div className="cc-form">
      <Form
        initialValues={meter}
        render={(formRenderProps: FormRenderProps) => (
          <MeterFormElement isNew={isNew} formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
