import { IntegrationModuleItemType } from "@app/products/direct-debit/system-admin/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const getDDModuleItemTypeById = async (
  directDebitModuleItemTypeId: number,
  cancelToken: CancelToken
): Promise<APIResponse<IntegrationModuleItemType> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<IntegrationModuleItemType>(
      `api/receipting/internal/integrationmoduleitemtype/${directDebitModuleItemTypeId}`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
