import { eventEmitter } from "@/App";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { ContactCentralNameDetailTabEventType } from "@app/products/property/contacts-central-names/[id]/components/reference-sidebar/detail/util";
import { WorkflowTypes } from "@app/products/property/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const UpdateContactButton = observer(() => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { gridSelectedRows } = useCCProductListViewStore();

  const isDisableUpdateButton = useMemo(() => {
    return (
      gridSelectedRows?.length !== 1 ||
      gridSelectedRows?.[0]?.Entity_Is_Obsolete
    );
  }, [gridSelectedRows]);

  return (
    <>
      <CCNavButton
        title="Modify contact"
        disabled={isDisableUpdateButton}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Modify_Entity,
              data: {},
              props: {
                handleSubmitDialog: () => {
                  eventEmitter.emit(CCGridEventType.RefreshOData);
                  eventEmitter.emit(
                    ContactCentralNameDetailTabEventType.RefreshData
                  );
                },
              },
            },
          ]);
        }}
      />
    </>
  );
});
