import { RebateClaimFormElement } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/child-screens/general/components/form-element/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const RebateClaimForm = observer(() => {
  return (
    <div className="cc-form">
      <Form
        render={(formRenderProps: FormRenderProps) => (
          <RebateClaimFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
