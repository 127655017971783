import ProfileFormElement from "@common/pages/profile/components/form/components/_index";
import { useProfileStore } from "@common/pages/profile/store";
import { NewSiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { produceSiteUserSaveTrigger } from "@common/pages/settings/security/site-users/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { find } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const ProfileForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { siteUser, setOnSubmit, setOnChange, saveSiteUser, siteUserLovs } =
    useProfileStore();

  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const onChangeRef = useRef<
    (
      name: string,
      options: {
        value: any;
      }
    ) => void
  >();

  const handleOnSubmit = async (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    if (!isValid) return;
    if (!isModified)
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    if (!values.Contact_SiteUser?.Flag_OnLeave) {
      values.Contact_SiteUser.SiteUser_ActingOfficer_ID = null;
      values.Contact_SiteUser.SiteUser_ActingOfficer = null;
      values.Contact_SiteUser.OnLeave_StartDate = null;
      values.Contact_SiteUser.OnLeave_EndDate = null;
    }

    const pprInfo = produceSiteUserSaveTrigger(values as NewSiteUser, siteUser);
    await saveSiteUser(pprInfo);
    const themeIdChanged = values.Contact_SiteUser.Theme_LKP;
    const currentTheme = localStorage.getItem("OOTheme");
    const themeChanged = find(siteUserLovs?.Theme, {
      Theme_LKP_ID: themeIdChanged,
    })?.Theme_DisplayName;
    if (currentTheme !== themeChanged) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, siteUser]);

  useEffect(() => {
    if (onChangeRef.current) setOnChange(onChangeRef.current);
    //eslint-disable-next-line
  }, [onChangeRef.current, siteUser]);

  // Check if data is existed in Update Mode
  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={siteUser}
        key={JSON.stringify(siteUser)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          onChangeRef.current = formRenderProps.onChange;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <ProfileFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
