import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { SETTING_CORE_INSPECTIONTYPE } from "@common/pages/settings/lookups/inspection-types/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_InspectionType>();

export const inspectionTypeConfig: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: Svc_InspectionType) => {
      return `${SETTING_CORE_INSPECTIONTYPE}/${dataItem.ID}`;
    },
  },
  {
    field: nameOf("Classification"),
    title: "Classification",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("FeeAmount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
