import { apiCoreSaveInspectionType } from "@app/core/components/common/utils";
import { InspectionType } from "@app/core/inspections/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";

export const postSaveInspectionTypes = async (
  inspectionType: InspectionType
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveInspectionType(),
      inspectionType
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
