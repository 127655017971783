import { EGlobalSettingsCategory } from "@common/pages/settings/system-admin/global-settings/model";
import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const financeRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: EGlobalSettingsCategory.Finance_GeneralFinanceSettings,
    component: require("./forms/general-finance-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_FinanceIntegrationSettings,
    component: require("./forms/finance-integration-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_CommunityFinanceAPISettings,
    component: require("./forms/community-finance-api-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_CustomExportFields,
    component: require("./forms/custom-export-fields").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_OracleCustomExportFields,
    component: require("./forms/oracle-custom-export-fields").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_BPAYSettings,
    component: require("./forms/bpay-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_DebtorSettings,
    component: require("./forms/debtor-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_InvoiceNumberSettings,
    component: require("./forms/invoice-number-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_ReceiptNumberSettings,
    component: require("./forms/receipt-number-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_FutureFeeSettings,
    component: require("./forms/future-fee-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_FinanceStagingUpdateSettings,
    component: require("./forms/finance-staging-update-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_PaymentMethodSettings,
    component: require("./forms/payment-method-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_BondIntegrationLookupSettings,
    component: require("./forms/bond-integration-lookup-settings").default,
  },
  {
    name: EGlobalSettingsCategory.Finance_SubsidiesSettings,
    component: require("./forms/subsidies-settings").default,
  },
];
