import { isSuccessIdentityPacket } from "@common/apis/util";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { putResendToActionOfficer } from "@common/pages/actions/components/action-bar/buttons/resend-to-action-officer/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IResendToActionOfficerButtonProps {
  disabled?: boolean;
}

export const ResendToActionOfficerButton = observer(
  ({ disabled }: IResendToActionOfficerButtonProps) => {
    const { action, reLoadAction } = useActionStore();
    const history = useHistory();
    const { clearErrorNotification, pushNotification } =
      useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { id } = useParams<{ id: string }>();
    const [title, setTitle] = useState("Resend to action officer");

    const handleSubmit = async () => {
      setIsLoading(true);
      const response = await putResendToActionOfficer(parseInt(id));
      setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        reLoadAction().then(() => {
          clearErrorNotification();
          if (history.action !== "POP") {
            history.goBack();
          } else {
            reLoadAction().then(() => {
              pushNotification({
                type: "success",
                title: "Resend to action officer successfully",
              });
            });
          }
        });
      } else {
        clearErrorNotification();
        pushNotification({
          autoClose: false,
          title: "Resend to action officer failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const isVisible = useMemo(() => {
      if (
        action?.Status_ENUM === ACTIONSTATUS.ALLOCATED &&
        action?.ActionRequired &&
        !(
          action?.ActionType &&
          action?.ActionType.Flag_IsReferral &&
          action?.DateSent !== undefined
        )
      )
        return true;
      return false;
    }, [action]);

    useEffectOnce(() => {
      if (
        action?.Status_ENUM === ACTIONSTATUS.ALLOCATED &&
        action?.ActionRequired &&
        action?.DateSent === undefined
      )
        setTitle("Send to action officer");

      if (
        action?.ActionType &&
        action?.ActionType.Flag_IsReferral &&
        action?.DateSent === undefined
      )
        setTitle("Mark as sent");
    });

    return isVisible ? (
      <CCNavButton
        title={title}
        isLoading={isLoading}
        onClick={handleSubmit}
        disabled={disabled}
      />
    ) : null;
  }
);
