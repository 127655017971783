import { useCertificateDetailsTabStore } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import MapView from "@components/map/MapView";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const CertificateMapTab = observer(() => {
  const {
    isLoading,
    certificateDetailsTabData,
    loadCertificateDetailsTabById,
    resetStore,
  } = useCertificateDetailsTabStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const certificateId: string | number = useMemo(() => {
    return id ?? lastSelectedId;
  }, [lastSelectedId, id]);

  useEffect(() => {
    certificateId && loadCertificateDetailsTabById(+certificateId);
    return () => resetStore();
  }, [certificateId, loadCertificateDetailsTabById, resetStore]);

  const addressData = useMemo(() => {
    return certificateDetailsTabData?.Cert_On_Parcel
      ? certificateDetailsTabData?.Parcel_Property_Addresses
      : certificateDetailsTabData?.Assess_Property_Addresses;
  }, [certificateDetailsTabData]);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-ref-map-container">
      <MapView width="100%" height="100%" address={addressData} />
    </div>
  );
});
