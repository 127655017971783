import { JournalStatus } from "@app/products/property/journals/model";
import { ResponseMessage } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postCancelJournal = async (
  id?: number
): Promise<APIResponse<ResponseMessage | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<ResponseMessage>(
      `api/property/internal/journal/${id}/status/${JournalStatus.Cancelled}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
