import { PickUserGroupOrg } from "@common/pages/report/integrated-reports/component/dialogs/share-report/component/dialog/pick-user-group-org/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface IPickUserGroupOrgButtonProps {
  maxHeight?: string;
  onSubmit: (data: any) => void;
}
export const PickUserGroupOrgButton = ({
  maxHeight,
  onSubmit,
}: IPickUserGroupOrgButtonProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  return (
    <>
      <Button
        type="button"
        onClick={() => setIsOpenDialog(true)}
        title={"Select Users, Groups and Org Structures"}
        iconClass="fas fa-plus"
      />
      {isOpenDialog && (
        <PickUserGroupOrg
          maxHeight={maxHeight}
          onClose={() => setIsOpenDialog(false)}
          handleSubmit={(data) => {
            onSubmit(data);
            setIsOpenDialog(false);
          }}
        />
      )}
    </>
  );
};
