import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { CertificateDetailTab } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/_index";
import { CertificateActiveByRespAreaBookmark } from "@app/products/property/certificates/active-by-resp-area/util";
import { CERTIFICATE_ROUTE } from "@app/products/property/certificates/list/constant";
import { certificateRoute } from "@app/products/property/certificates/route";
import { MockCertificateBookmark } from "@app/products/property/certificates/util";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colActiveByRespArea } from "./config";
import { mockActiveByRespArea } from "./mock";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={certificateRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Certificate}
        recordType={RECORDTYPE.CommunityProperty_Certificate}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CERTIFICATE_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Certificate}
        //TODO: Update bookmark after integrate api for list view
        detail={MockCertificateBookmark.getBookmarkDetail}
        displayName={MockCertificateBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CertificateActiveByRespAreaBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={
          CertificateActiveByRespAreaBookmark.getBookmarkListViewDetail
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <CertificateDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      { title: "Contacts" },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Certificate}
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      data={mockActiveByRespArea}
      columnFields={colActiveByRespArea}
      primaryField={"ID"}
      state={{ sort: [{ field: "CertificateNumber", dir: "desc" }] }}
    />
  );
};
