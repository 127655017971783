import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { DBRowState } from "@common/models/baseClassStandard";
import {
  CCFormConfigDTO,
  CCLabelNamesDTO,
  CCMenuDTO,
  CCOrganisations,
} from "@common/stores/products/model";

export interface Svc_HomepagePageUser {
  HomePageFunction: string;
  HomePageView: string;
  ID: number;
  UserName: string;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  SecurityTemplates: string;
  EnterpriseRole_ENUM: number | null;
  EnterpriseRole_Name: string;
  IsActive: boolean | null;
  IsLocked: boolean | null;
  OnLeave: boolean | null;
  PrimaryKey: string;
}

export interface Svc_HomepagePickUserRequest {
  HomepageIDs: number[];
  SiteUserIDs: number[];
  HomepageType: HomepageType;
}

export enum HomepageType {
  HomepageShortcut = 0,
  HomepageView = 1,
}

export interface CCPrivilege {
  Id: number;
  Name: string;
}

export interface CCPrivileges {
  Privileges: CCPrivilege[];
}

export interface ProductListing {
  Sys_ModifiedBy: string;
  Sys_ModifiedBy_ID: number;
  Sys_ModifiedDate: Date;
  Sys_DBRowState: DBRowState;
  Sys_EnterpriseKey: string;
  Sys_TimeStamp: string;
  Sys_TransactionDate: Date;
  ClientListing_ID: number;
  ProductType_ENUM: ProductType;
  ProductType_Int: number;
  CustomProductName: string;
  ProductType_Name: string;
  Theme_LKP: number;
  LicenceKey: string;
  Menu_Width: number | null;
  Logo: string;
  FileSize: number;
  Filename: string;
  ContentType: string;
  Menu_Sort_Order: number | null;
  Product_Label: string;
  Flag_CommunityCentral: boolean | null;
  Product_Acronym: string;
  Menu: CCMenuDTO;
  LabelNames: CCLabelNamesDTO;
  WorkflowTypes: CCWorkflowTypes;
  FormConfig: CCFormConfigDTO;
  Organisations: CCOrganisations;
  Privileges: CCPrivileges;
}

export interface CCWorkflowTypes {
  WorkflowTypes: CCWorkflowType[];
}

export interface CCWorkflowType {
  WorkflowType_ENUM: string;
  WorkflowType_Name: string;
  WorkflowType_Int: number;
}
