import { getHMInspScheduleCalendarByOfficer } from "@app/products/hm/insp-schedule/calendar/api";
import { processHMInspScheduleCalendarData } from "@app/products/hm/insp-schedule/calendar/util";
import { getPickSiteUserList } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/api";
import { defaultCalendarDropDownData } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/config";
import { IPickSiteUser } from "@app/products/town-planning/ppr/psa-referrals/hearing-calendar/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { ICalendarData } from "@components/cc-calendar/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class HMInspectionScheduleCalendarStore {
  private _calendarData?: ICalendarData[] = undefined;
  private _siteUser?: IPickSiteUser[] = undefined;
  private _selectedSiteUser?: IPickSiteUser = defaultCalendarDropDownData;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get calendarData() {
    return toJS(this._calendarData);
  }
  setAppealHearingCalendar = (calendarData?: ICalendarData[]) => {
    runInAction(() => {
      this._calendarData = calendarData;
    });
  };

  get siteUser() {
    return toJS(this._siteUser);
  }
  setSiteUser = (siteUser?: IPickSiteUser[]) => {
    runInAction(() => {
      this._siteUser = siteUser;
    });
  };

  get selectedSiteUser() {
    return toJS(this._selectedSiteUser);
  }
  setSelectedSiteUser = (selectedSiteUser?: IPickSiteUser) => {
    runInAction(() => {
      this._selectedSiteUser = selectedSiteUser;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._calendarData = [];
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._siteUser = undefined;
    });
  };

  loadCalendar = async () => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const [calendarRes, pickSiteUserListRes] = await Promise.all([
      getHMInspScheduleCalendarByOfficer(),
      getPickSiteUserList(),
    ]);

    let newAppealHearingCalendar = undefined;
    let newPickSiteUserList = undefined;
    if (
      isSuccessResponse(calendarRes) &&
      calendarRes?.data &&
      isSuccessResponse(pickSiteUserListRes) &&
      pickSiteUserListRes.data?.value
    ) {
      newAppealHearingCalendar = processHMInspScheduleCalendarData(
        calendarRes?.data?.ReturnObj ?? []
      );
      newPickSiteUserList = pickSiteUserListRes.data.value;
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    this.setAppealHearingCalendar(newAppealHearingCalendar);
    this.setSiteUser(newPickSiteUserList);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  loadCalendarByOfficer = async (officerName?: string) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const calendarRes = await getHMInspScheduleCalendarByOfficer(officerName);
    let newCalendarRes = undefined;
    if (isSuccessResponse(calendarRes) && calendarRes?.data) {
      newCalendarRes = processHMInspScheduleCalendarData(
        calendarRes?.data?.ReturnObj ?? []
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    this.setAppealHearingCalendar(newCalendarRes);
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };
}

export const hmInspectionScheduleCalendarStoreInstance =
  new HMInspectionScheduleCalendarStore();
const hmInspectionScheduleCalendarStoreContext = createContext(
  hmInspectionScheduleCalendarStoreInstance
);
export const useHMInspectionScheduleCalendarStore = () => {
  return useContext(hmInspectionScheduleCalendarStoreContext);
};
