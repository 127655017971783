import { EConfigNameFieldsModifyAreaOfResponsibility } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/components/element-steps/_step-details/config";
import { useModifyAreaOfResponsibilityStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-area-of-responsibility/store";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";
export const StepDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      udaId: "0",
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { stepDetailStepLOVs } = useModifyAreaOfResponsibilityStore();
    const configField = stepDetailStepLOVs?.UDAL;
    const fieldLovs = stepDetailStepLOVs?.UDALOvs;

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">{configField?.UDA_Label ?? ""}</label>
            <Field
              name={nameOf(
                `_option.${EConfigNameFieldsModifyAreaOfResponsibility.OptionField}`
              )}
              component={CCSearchComboBox}
              data={fieldLovs?.[options?.udaId] ?? []}
              textField="Name"
              dataItemKey="Code"
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">
              {configField?.UDA_Label ?? ""} details
            </label>
            <Field
              name={nameOf(
                `_option.${EConfigNameFieldsModifyAreaOfResponsibility.TextField}`
              )}
              component={CCTextArea}
              disabled={options?.isReadOnly}
              rows={4}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Completed</label>
            <Field
              name={nameOf("Completed")}
              component={CCSwitch}
              disabled={options?.isReadOnly}
              checked={getFieldValue("Completed")}
              onChange={(event: SwitchChangeEvent) => {
                onChange(nameOf("Completed"), { value: event?.value });
                if (!event?.value) {
                  onChange(nameOf("Completion_DateTime"), { value: undefined });
                  onChange(nameOf("Completion_UserId"), { value: undefined });
                }
              }}
            />
          </div>
          {getFieldValue("Completed") ? (
            <>
              <div className="cc-field">
                <label className="cc-label">Completed on</label>
                <Field
                  name={nameOf("Completion_DateTime")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Completed by</label>
                <Field
                  name={nameOf("Completion_UserId")}
                  component={CCInput}
                  disabled={options?.isReadOnly}
                  placeholder="Completed by"
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Comments</label>
            <Field
              name={nameOf("AOR_Comments")}
              component={CCTextArea}
              disabled={options?.isReadOnly}
              placeholder="Comments"
              rows={4}
            />
          </div>
        </div>
      </section>
    );
  }
);
