import { colSchemes } from "@app/products/property/schemes/components/dialogs/raise-interest/config";
import { mockSchemes } from "@app/products/property/schemes/components/dialogs/raise-interest/mock";
import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

interface IRaiseInterestDialogProps {
  onClose: () => void;
  onSubmit: () => void;
}

const initialValues = {
  CalculateInterestTo: new Date(),
};

export const RaiseInterestDialog = (props: IRaiseInterestDialogProps) => {
  return (
    <Form
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={"Selected Scheme's Interest"}
            onClose={props.onClose}
            maxWidth="33%"
            maxHeight="55%"
            bodyElement={
              <div className="cc-form">
                <FormElement
                  onSubmit={formRenderProps.onSubmit}
                  className="cc-field-group"
                >
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Calculate interest to
                        </label>
                        <Field
                          name={"CalculateInterestTo"}
                          component={CCDatePicker}
                          format={DATE_FORMAT.DATE_CONTROL}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Schemes</label>
                        <CCGrid
                          data={mockSchemes || []}
                          columnFields={colSchemes}
                          primaryField="ID"
                          selectableMode="multiple"
                        />
                      </div>
                    </div>
                  </section>
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={() => {
                    props.onSubmit();
                  }}
                  type={"submit"}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
