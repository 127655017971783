import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { IPPRFurtherInfo } from "@app/products/town-planning/ppr/[id]/components/child-screens/further-info/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPPRFurtherInfo>();

export const colPPRFurtherInfo: IColumnFields[] = [
  {
    field: nameOf("CreatedDate"),
    title: "Date",
    format: DATE_FORMAT.DATE,
    locked: true,
    linkTo: (dataItem) => `${FURTHER_INFO_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("DateDue"),
    title: "Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
];
