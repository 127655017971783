import { BuildingRegistersPOPEFormElement } from "@app/products/building/registers/pope/[id]/components/child-screens/general/components/form-element/_index";
import { useBuildingRegistersPOPEStore } from "@app/products/building/registers/pope/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const BuildingRegistersPOPEForm = observer(() => {
  const isNew = useIsNew();
  const {
    buildingRegistersPOPE,
    createBuildingRegistersPOPE,
    updateBuildingRegistersPOPE,
  } = useBuildingRegistersPOPEStore();

  const handleSubmit = (data: any) => {
    if (isNew) {
      createBuildingRegistersPOPE(data);
    } else {
      updateBuildingRegistersPOPE(data);
    }
  };

  return (
    <div className="cc-form">
      <Form
        onSubmit={handleSubmit}
        initialValues={buildingRegistersPOPE}
        render={(formRenderProps: FormRenderProps) => (
          <BuildingRegistersPOPEFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
