import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";

export const CRMSCouncillorEventsAllByWardBookmark = {
  getBookmarkListViewDisplayName() {
    return `${CRMS_PRODUCT_NAME} - Councillor Events`;
  },
  getBookmarkListViewDetail() {
    return `${CRMS_PRODUCT_NAME} - Councillor Events - All By Ward`;
  },
};
