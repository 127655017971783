import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { Journal } from "@app/core/journal/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { TPAllApplicationsOpenBookmark } from "@app/products/town-planning/all-applications/open/util";
import { allApplicationsRoute } from "@app/products/town-planning/all-applications/route";
import { NewTownPlanningButton } from "@app/products/town-planning/components/action-bar/buttons/new-town-planning/_index";
import { TownPlanningActionBarNavDropdown } from "@app/products/town-planning/components/action-bar/nav-dropdown/_index";
import { TownPlanningApiUrl } from "@app/products/town-planning/config";
import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { townPlanningRoute } from "@app/products/town-planning/route";
import { TownPlanningBookmark } from "@app/products/town-planning/utils";
import { TownPlanningContactsTab } from "@app/products/town-planning/[id]/components/reference-sidebar/contact/_index";
import { TownPlanningDetailsTab } from "@app/products/town-planning/[id]/components/reference-sidebar/details/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { colOpen } from "./config";

export default () => {
  useCCListViewActionBar({
    title: townPlanningRoute.name,
    leftComponents: [
      <TownPlanningActionBarNavDropdown category={allApplicationsRoute.path} />,
    ],
    centerComponents: [
      <NewTownPlanningButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.TownPlanning}
        recordType={
          RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application
        }
        dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_Application}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.TownPlanning}
        recordType={
          RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application
        }
        keyField={"ID"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={TOWN_PLANNING_ROUTE.MANAGE_ROUTE}
        productType={PRODUCT_TYPE.TownPlanning}
        recordType={RECORDTYPE.TP_Application}
        detail={TownPlanningBookmark.getBookmarkDetail}
        displayName={TownPlanningBookmark.getBookmarkDisplayName}
        listViewDetail={TPAllApplicationsOpenBookmark.getBookmarkListViewDetail}
        listViewDisplayName={
          TPAllApplicationsOpenBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <TownPlanningDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <TownPlanningContactsTab />,
      },
      {
        title: "History",
        component: (
          <Journal
            id={13347}
            recordType={
              RECORDTYPE.CommunityProperty_CommunityCentral_Town_Planning_Application
            }
          />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colOpen}
      primaryField={"ID"}
      dataUrl={TownPlanningApiUrl.GET_VIEW_TOWNPLANNING_ALLAPPLICATION_OPEN}
      state={{
        sort: [
          { field: TownPlanningFieldMapping.ApplicationNumber, dir: "desc" },
        ],
      }}
    />
  );
};
