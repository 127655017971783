import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { NewAnimalButton } from "@app/products/animals/components/action-bar/buttons/new-animal/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { AnimalsContactsTab } from "@app/products/animals/components/reference-sidebar/contacts/_index";
import { AnimalDetailsTab } from "@app/products/animals/components/reference-sidebar/details/_index";
import { AnimalsHistoryTab } from "@app/products/animals/components/reference-sidebar/history/_index";
import {
  AnimalRenewalViewType,
  AnimalsRegister
} from "@app/products/animals/model";
import { generateColAnimalsRenewals } from "@app/products/animals/renewals/all-by-due-status/config";
import { generateAnimalsRenewalsOdata } from "@app/products/animals/renewals/all-by-due-status/util";
import { AnimalIssueRenewalNoticeMarkAsSentButton } from "@app/products/animals/renewals/requires-renewal-notice/components/buttons/issue-renewal-notice-mark-as-sent/_index";
import { AnimalIssueRenewalNoticeButton } from "@app/products/animals/renewals/requires-renewal-notice/components/buttons/issue-renewal-notice/_index";
import { AnimalMarkAsSentButton } from "@app/products/animals/renewals/requires-renewal-notice/components/buttons/mark-as-sent/_index";
import { AnimalResetRenewalFeeFlagButton } from "@app/products/animals/renewals/requires-renewal-notice/components/buttons/reset-renewal-fee-flag/_index";
import { AnimalRenewalsRequiresRenewalNoticeBookmark } from "@app/products/animals/renewals/requires-renewal-notice/util";
import { animalRoute } from "@app/products/animals/route";
import { AnimalBookmark } from "@app/products/animals/util";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { renewalsRoute } from "../route";
import { colAnimalsRenewalsRequiresRenewalNotice } from "./config";

const nameOf = nameOfFactory<AnimalsRegister>();

export default () => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Registration,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={renewalsRoute.path} />,
    ],
    centerComponents: [
      <NewAnimalButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <CCNavButton title="Issue Renewal Notice" type="sub">
        <AnimalIssueRenewalNoticeButton
          productType={PRODUCT_TYPE_NUMBER.Animals}
          dataSetFilter={MAILMERGEDATASET.Animals_Registration}
          recordType={RECORDTYPE.Animals_Registration}
          titleButton="Issue Renewal Notice"
        />
        <AnimalIssueRenewalNoticeMarkAsSentButton
          productType={PRODUCT_TYPE_NUMBER.Animals}
          dataSetFilter={MAILMERGEDATASET.Animals_Registration}
          recordType={RECORDTYPE.Animals_Registration}
        />
        <AnimalMarkAsSentButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Registration}
        recordType={RECORDTYPE.Animals_Registration}
      />,
      <AnimalResetRenewalFeeFlagButton />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        recordType={RECORDTYPE.Animals_Registration}
        keyField={nameOf("ID")}
      />,
      <DeleteButton
        recordType={RECORDTYPE.Animals_Registration}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.Animals_Registration}
        detail={AnimalBookmark.getBookmarkDetail}
        displayName={AnimalBookmark.getBookmarkDisplayName}
        listViewDetail={
          AnimalRenewalsRequiresRenewalNoticeBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalRenewalsRequiresRenewalNoticeBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AnimalDetailsTab /> },
      { title: "Contacts", component: <AnimalsContactsTab /> },
      {
        title: "History",
        component: <AnimalsHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={generateColAnimalsRenewals(
          colAnimalsRenewalsRequiresRenewalNotice,
          settings
        )}
        dataUrl={generateAnimalsRenewalsOdata(
          AnimalRenewalViewType.RequiresRenewalNotice
        )}
        primaryField={nameOf("ID")}
        state={{
          sort: [{ field: nameOf("TagNo"), dir: "desc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
};
