export class IBuildingEssentialSafetyResponse {
  ID?: number = 0;
  RegisterNumber?: number = 0;
  Status?: string = "Pending";
  Contact?: string = "";
  ContactAddress?: string = "";
  ContactContact?: string = "";

  Address?: string = "";
  Owners?: string = "";
  Details?: string = "";
  Description?: string = "";
  Officer?: string = "";
  BCA?: number = 0;
  InspectionFrequency?: number = 0;
  LastInspection?: string = "";
  NextInspection?: string = "";
  Items?: string = "";
  Lodged?: string = "";
  DecisionType?: string = "";
  CreatedDate?: string = "";
  DecisionDate?: string = "";
  OSFees?: number = 0;
  DebtorNumber?: string = "";
  AppNo?: string;
  RefNumber?: number;
  FileNumber?: number;
  PlannerAssessment?: string;
}

export class IBuildingEssentialSafetyRequest extends IBuildingEssentialSafetyResponse {}

export class IBuildingEssentialSafetyFormData extends IBuildingEssentialSafetyResponse {}

export class IBuildingEssentialSafetyRecordState {
  addresses: number = 0;
  actions: number = 0;
  comments: number = 0;
  contacts: number = 0;
  documents: number = 0;
  fees: number = 0;
  inspections: number = 0;
  related: number = 0;
}
