import { DirectDebitSessionLovs } from "@app/products/direct-debit/sessions/components/dialogs/create-session/model";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getDirectDebitSessionLovs = async (
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitSessionLovs | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitSessionLovs>(
      "api/receipting/internal/directdebitsession-lovs",
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const saveDirectDebitSession = async (
  data: DirectDebitSessionHeader,
  cancelToken: CancelToken
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/receipting/internal/directdebitsession/createsession`,
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
