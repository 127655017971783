import { useAssessmentComplianceAccordionStore } from "@app/products/property/assessments/[id]/components/child-screens/compliance/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
import {
  colAssessmentComplianceAccordion,
  colLandTaxExemptions,
  optionComplianceFor,
} from "./config";
import {
  DTO_Assessment_Compliance_LandTaxExemption,
  DTO_Compliance,
} from "./model";

interface IAssessmentComplianceAccordion {
  assessmentId: number;
}

const nameOfAssessmentComplianceAccordion = nameOfFactory<DTO_Compliance>();
const nameOfAssessmentLandTaxExemptionAccordion =
  nameOfFactory<DTO_Assessment_Compliance_LandTaxExemption>();

export const Compliance = observer(
  ({ assessmentId }: IAssessmentComplianceAccordion) => {
    const {
      resetStore,
      isRatePayer,
      setIsRatePayer,
      loadAssessmentCompliance,
      assessmentCompliance,
      isLoading,
      responseLoadError,
    } = useAssessmentComplianceAccordionStore();

    useEffectOnce(() => {
      return () => {
        resetStore();
      };
    });

    useEffect(() => {
      loadAssessmentCompliance(assessmentId);
    }, [isRatePayer, assessmentId, loadAssessmentCompliance]);

    return (
      <div className="cc-property-compliance-checks cc-form">
        <Form
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className="cc-field-group">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Compliance history for</label>
                    <Field
                      name={"ComplianceHistoryFor"}
                      component={RadioGroup}
                      data={optionComplianceFor}
                      layout={"horizontal"}
                      value={isRatePayer}
                      onChange={(event: RadioGroupChangeEvent) => {
                        setIsRatePayer(event.value);
                      }}
                    />
                  </div>
                </div>
                {isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <>
                    {responseLoadError ? (
                      <CCLoadFailed
                        responseError={responseLoadError}
                        onReload={() => loadAssessmentCompliance(assessmentId)}
                      />
                    ) : (
                      <div className="cc-form-cols-1">
                        <div className="cc-field">
                          <CCGrid
                            data={assessmentCompliance?.Compliances ?? []}
                            columnFields={colAssessmentComplianceAccordion}
                            primaryField={nameOfAssessmentComplianceAccordion(
                              "ComplianceId"
                            )}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">
                            Land tax exemptions
                          </label>
                          <CCGrid
                            data={assessmentCompliance?.LandTaxExemptions ?? []}
                            columnFields={colLandTaxExemptions}
                            primaryField={nameOfAssessmentLandTaxExemptionAccordion(
                              "Land_Tax_Exemption_Id"
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </FormElement>
          )}
        />
      </div>
    );
  }
);
