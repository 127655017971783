import { ResponseMessage } from "@app/products/property/model";
import { Valuation } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/dialogs/new-valuation/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const createValuation = async (
  supplementaryId: number,
  assessmentId: number,
  valuation: Valuation
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/assessessmentvaluation/${supplementaryId}/${assessmentId}`,
      valuation
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
