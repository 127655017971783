export enum EGlobalSettingsCategory {
  //General
  General = "General",
  General_Details = "General_Details",
  General_MailSettings = "General_MailSettings",
  General_ProxySettings = "General_ProxySettings",
  General_General_Settings = "General_General_Settings",
  General_AddDocumentOptions = "General_AddDocumentOptions",
  General_Conditions = "General_Conditions",
  General_PhoneNumberFormatValidation = "General_PhoneNumberFormatValidation",
  General_OrgStructureSettings = "General_OrgStructureSettings",
  General_SiteUserSettings = "General_SiteUserSettings",
  General_PublicHolidays = "General_PublicHolidays",
  General_BusinessHours = "General_BusinessHours",
  General_MenuGroups = "General_MenuGroups",
  General_MailMerge = "General_MailMerge",
  General_JobQueue = "General_JobQueue",
  General_Actions = "General_Actions",
  General_Advertising = "General_Advertising",
  General_Keywords = "General_Keywords",
  General_NCItemSettings = "General_NCItemSettings",
  General_QualificationSettings = "General_QualificationSettings",

  //Contacts
  Contacts = "Contacts",

  //Finance
  Finance = "Finance",
  Finance_GeneralFinanceSettings = "Finance_GeneralFinanceSettings",
  Finance_FinanceIntegrationSettings = "Finance_FinanceIntegrationSettings",
  Finance_CommunityFinanceAPISettings = "Finance_CommunityFinanceAPISettings",
  Finance_CustomExportFields = "Finance_CustomExportFields",
  Finance_OracleCustomExportFields = "Finance_OracleCustomExportFields",
  Finance_BPAYSettings = "Finance_BPAYSettings",
  Finance_DebtorSettings = "Finance_DebtorSettings",
  Finance_InvoiceNumberSettings = "Finance_InvoiceNumberSettings",
  Finance_ReceiptNumberSettings = "Finance_ReceiptNumberSettings",
  Finance_FutureFeeSettings = "Finance_FutureFeeSettings",
  Finance_FinanceStagingUpdateSettings = "Finance_FinanceStagingUpdateSettings",
  Finance_PaymentMethodSettings = "Finance_PaymentMethodSettings",
  Finance_BondIntegrationLookupSettings = "Finance_BondIntegrationLookupSettings",
  Finance_SubsidiesSettings = "Finance_SubsidiesSettings",

  //Properties
  Properties = "Properties",

  //Records
  Records = "Records",

  //Communication
  Communication = "Communication",
  CommunicationSettings = "CommunicationSettings",
  SmsAndFaxSettings = "SmsAndFaxSettings",

  //NAR
  NAR = "NAR",

  // Infringements/Notices
  InfringementsNotices = "InfringementsNotices",
  InfringementsNotices_GeneralSettings = "InfringementsNotices_GeneralSettings",
  InfringementsNotices_InfringementIntegrationSettings = "InfringementsNotices_InfringementIntegrationSettings",
  InfringementsNotices_NoticeExportSettings = "InfringementsNotices_NoticeExportSettings",

  //Inspections
  Inspections = "Inspections",
  Inspections_InspectionSettings = "Inspections_InspectionSettings",
  Inspections_InspectionExportSettings = "Inspections_InspectionExportSettings",
  Inspections_NonComplyingScores = "Inspections_NonComplyingScores",

  //Investigations
  Investigations = "Investigations",
  Investigations_InvestigationNumbering = "Investigations_InvestigationNumbering",

  //Exchange
  Exchange = "Exchange",
  Exchange_ExchangeIntegrationSettings = "Exchange_ExchangeIntegrationSettings",

  //Payment Gateway
  PaymentGateway = "PaymentGateway",
  PaymentGateway_PaymentGatewayIntegrationSettings = "PaymentGateway_PaymentGatewayIntegrationSettings",

  //CEP
  CEP = "CEP",
  CEPCommunityEngagementPlatform = "CEP_CommunityEngagementPlatform",
  CEPPortalMailSettings = "CEP_PortalMailSettings",

  //Modules
  Modules = "Modules",
  ModulesA = "ModulesA",
  ModulesB = "ModulesB",

  //Printing
  Printing = "Printing",
  PrintingSettings = "Printing_Settings",

  //Assets
  Assets = "Assets",

  //Security
  Security = "Security",

  //Agents
  Agents = "Agents",
  Agents_AgentSettings = "Agents_AgentSettings",

  //Processor
  Processor = "Processor",

  //Reports
  Reports = "Reports",

  //Process External
  ProcessExternal = "ProcessExternal",

  //Logging
  Logging = "Logging",
}
