import { ISupplementary_Associate_Assessment } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/model";
import {
  SupplementaryMode,
  VO_Supplementary,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { DECIMAL_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Supplementary>();

export const colSupplementary: IColumnFields[] = [
  {
    field: nameOf("Supplementary_Id"),
    title: "Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("Supp_Name"), title: "Name" },
  { field: nameOf("ST_Name"), title: "Type" },
];

export const dataWhichSupplementary = [
  { label: "New supplementary", value: SupplementaryMode.CreateNew },
  {
    label: "Existing supplementary created by me",
    value: SupplementaryMode.SelectFromMyList,
  },
  {
    label: "Existing supplementary",
    value: SupplementaryMode.SelectFromList,
  },
];

export const getSupplementaryOdata = (
  isMyList: boolean,
  isCompletedSupplementary: boolean
) => {
  return `/odata/property/internal/supplementaryratesregister?mylist=${isMyList}&$filter=Supp_Is_Complete eq ${isCompletedSupplementary}&$count=true&`;
};

export const nameOfAssessment =
  nameOfFactory<ISupplementary_Associate_Assessment>();
export const colAssessment: IColumnFields[] = [
  {
    field: nameOfAssessment("Assessment_Number"),
    title: "Assessment Number",
  },
  {
    field: nameOfAssessment("Assessment_Reference"),
    title: "Assessment Reference",
  },
  {
    field: nameOfAssessment("Property_Address"),
    title: "Address",
  },
  {
    field: nameOfAssessment("Assessment_Group"),
    title: "Assessment Group",
  },
  {
    field: nameOfAssessment("Ratepayer"),
    title: "Ratepayer",
  },
  {
    field: nameOfAssessment("Assessment_Number_Decimal"),
    title: "Assessment Number Decimal",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfAssessment("Assessment_Valuation"),
    title: "Assessment Valuation",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfAssessment("Assessment_Valuation_Decimal"),
    title: "Assessment Valuation Decimal",
  },
  {
    field: nameOfAssessment("Assessment_Id"),
    title: "Id",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
