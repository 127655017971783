import { AddToSupplementaryButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/add-to-supplementary/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const AssessmentAddToSupplementaryButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  return (
    <AddToSupplementaryButton
      componentNumber={eComponent.Assessment}
      statusId={gridSelectedRows[0]?.Assessment?.StatusId}
    />
  );
});
