import { PremisesRegisterWorkflowInitialResponse } from "@app/products/hm/premises/[id]/components/buttons/register-premises/model";
import { PremisesRegisterWorkflowRequest } from "@app/products/hm/premises/[id]/components/dialogs/register-premises/model";
import { Premises } from "@app/products/hm/premises/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postHMPremisesRegisterInitial = async (
  premises: Premises,
  cancelToken?: CancelToken
): Promise<
  | APIResponse<
      IIdentityPacket<PremisesRegisterWorkflowInitialResponse> | IdentityPacket
    >
  | undefined
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<PremisesRegisterWorkflowInitialResponse> | IdentityPacket
    >(`api/hm/internal/premises/register-initial`, premises, { cancelToken });
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postHMPremisesRegister = async (
  data: PremisesRegisterWorkflowRequest,
  cancelToken?: CancelToken
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/hm/internal/premises/register`,
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
