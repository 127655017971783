import { AmsRoute } from "@app/products/ams/constants/ams.route";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewAssetButton = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <CCNavButton
        title="New Asset"
        onClick={() => {
          history.push(AmsRoute.ASSET_NEW_PATH);
        }}
      />
    </React.Fragment>
  );
};
