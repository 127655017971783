import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colExhibition: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNo,
    title: "Application Number",
    width: 250,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ApplicantName,
    title: "Applicant",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Category,
    title: "Proposal",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.Type,
    title: "Type",
    width: 250,
  },
  {
    field: TownPlanningFieldMapping.DateDecisionToAdvertise,
    title: "Decision",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DateAdvertisingStarts,
    title: "Starts",
    width: 250,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 250,
  },
];
