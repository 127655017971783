import { mockSingleBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/mock";
import { IBuildingPoolsSpas } from "@app/products/building/pools-or-spas/[id]/model";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";

export const getBuildingPoolsSpasById = async (
  id?: string
): Promise<IBuildingPoolsSpas | undefined> => {
  await sleep(1000);
  const response = cloneDeep(mockSingleBuildingPoolsSpas);
  return response;
};
