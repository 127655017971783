import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { StateSvcStandardUpdate } from "@app/products/crms/service-standards/batch-update/model";
import { useSelectionListener } from "@components/cc-grid/hooks/useSelectionListener";
import { SelectTypeEnum } from "@components/cc-grid/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export interface IServiceStandardBatchUpdateProps {
  isDisabled?: boolean;
  isVisible?: boolean;
  gridId?: string;
}

export const SvcStandardBatchUpdateButton = observer(
  ({
    isDisabled = false,
    isVisible = true,
    gridId,
  }: IServiceStandardBatchUpdateProps) => {
    const history = useHistory();
    const { gridSelectedIds } = useCCProductListViewStore();
    const { loader } = useSelectionListener(gridId);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleOnClick = async () => {
      setIsLoading(true);
      let rowIds = gridSelectedIds;
      if (loader.current.peek.type === SelectTypeEnum.ALL) {
        rowIds = (
          await loader.current.process({ $select: ["ID"], $flat: true })
        ).data;
      }
      setIsLoading(false);
      history.push(`${CRMS_SERVICE_STANDARDS_ROUTE}/batch-update`, {
        gridSelectedIds: rowIds,
      } as StateSvcStandardUpdate);
    };

    if (!isVisible) return null;
    return (
      <CCNavButton
        title="Batch Update"
        disabled={isDisabled || gridSelectedIds.length < 1 || isLoading}
        onClick={handleOnClick}
        isLoading={isLoading}
      />
    );
  }
);
