import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { isSuccessResponse } from "@common/apis/util";
import { putCheckDueActions } from "@common/pages/actions/components/action-bar/buttons/check-due-actions/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface CheckDueActionsButtonProps {
  disabled?: boolean;
}

export const CheckDueActionsButton = observer(
  ({ disabled }: CheckDueActionsButtonProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();

    const handleCheckDueActions = async () => {
      setIsLoading(true);
      const response = await putCheckDueActions();
      if (isSuccessResponse(response)) {
        clearErrorNotification();
        pushNotification({
          title: "Checked due actions successfully",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        pushNotification({
          title: "Checked due actions failed",
          description: response.data.Errors ?? response.statusText,
          type: "error",
        });
      }
      setIsLoading(false);
    };

    return (
      <CCNavButton
        title="Check Due Actions"
        isLoading={isLoading}
        onClick={handleCheckDueActions}
        disabled={disabled}
      />
    );
  }
);
