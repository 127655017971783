import { DeleteButtonCommon } from "@app/core/delete/buttons/common/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IDeleteButtonProps {
  recordType?: RECORDTYPE;
  disabled?: boolean;
  visible?: boolean;
  refreshGridIds?: string[];
  gridId?: string;
  primaryField?: string;
}

export const DeleteButton = observer(
  ({
    primaryField,
    recordType,
    disabled,
    visible = true,
    refreshGridIds = [],
    gridId,
  }: IDeleteButtonProps) => {
    const { gridSelectedIds, gridSelectedRows } = useCCProductListViewStore();

    // primaryField use for case: records have the same ID
    // Use another field to make the primary field
    const selectedIds = useMemo(() => {
      if (!isNil(primaryField) && primaryField !== "") {
        return uniq(
          gridSelectedRows
            .map((item: any) => item?.[primaryField])
            .filter((primaryField) => primaryField !== null)
        );
      }
      return gridSelectedIds;
    }, [primaryField, gridSelectedRows, gridSelectedIds]);

    return (
      <DeleteButtonCommon
        recordType={recordType}
        disabled={disabled}
        ids={selectedIds}
        visible={visible}
        refreshGridIds={refreshGridIds}
        gridId={gridId}
      />
    );
  }
);
