import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const advancedSearchRoute: ICCRoute = {
  path: "advanced-search",
  name: "Advanced Search",
  enum: eMenuAction.ApplicationSearch,
  children: [
    {
      path: "searches",
      name: "Searches",
      enum: eMenuAction.AdvancedSearch_List,
      component: require("./searches/_index").default,
    },
    {
      path: "footprints",
      name: "Footprints",
      enum: eMenuAction.AdvancedSearch_Footprints,
      component: require("./footprints/_index").default,
    },
    {
      path: "searches/:id([0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
