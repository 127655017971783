import { IAccountInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/model";
export interface ISchemeFormGeneral {
  SchemeId: number;
  IsActive?: boolean;
  SuppressProcessing?: boolean;
  Comments?: string;
  DateCommenced?: Date;
  DateInitiated?: Date;
  DateDeclared?: Date;
  ShowOnAssessmentAmountOwingForm?: boolean;
  IncludeInAssessmentAmountOwingBalances?: boolean;
  RaiseInterestOnLastDayOnMonth?: boolean;
  UsingPenaltyInterest?: boolean;
  RaiseInterestEvery?: number;
  InterestToBeNextRaisedOn?: Date;
  InterestWasLastRaisedOn?: Date;
  InstalmentFrequency?: number;
  PaymentCommencement?: Date;
  NumberOfInstalments?: number;
  DaysToPayInstalment?: number;
  NextInstalmentDate?: Date;
  DiscountOnPayout?: number;
  DiscountOnPayoutIfPaidBy?: string;
  InterestAndInstalmentDetailsGL: ISchemesGL[];
  SchemeNumber?: string;
  Name?: string;
  SchemeType?: string;
  InstalmentType?: string;
  Costs?: ISchemesCosts[];
  SchemesInterestRate?: ISchemesInterestRate[];
}
export interface ISchemeForm {
  General?: ISchemeFormGeneral;
  Accounts?: IAccountInTheScheme[] | never[];
}
export interface ISchemesCosts {
  Costs: string;
  Estimated?: number;
  Actual?: number;
  isDisableEdit?: boolean;
}
export interface ISchemesGL {
  GLAccountType: string;
  GLAccReference?: string;
  LineItem?: string;
}

export interface ISchemesInterestRate {
  Id: string;
  EffectiveFrom: Date;
  InterestRate?: number | null;
  PenaltyRate?: number | null;
  Status: eSchemesInterestRateStatus;
  IsDisabledRow?: boolean;
}
export enum eSchemesInterestRateStatus {
  NEW = "New",
  IN_USE = "In Use",
  PROPOSED = "Proposed",
}
