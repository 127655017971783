import Breadcrumb from "@app/products/property/system-admin/settings/components/breadcrumb/_index";
import { MODE_SETTING } from "@app/products/town-planning/ppr/system-admin/settings/_category/model";
import { ICategoryConfig } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { defaultGlobalCategoryConfig } from "@common/pages/settings/system-admin/global-settings/_category/config";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import GlobalSettingManage from "@common/pages/settings/system-admin/global-settings/_id/_index";
import { GlobalSettingHistoryTab } from "@common/pages/settings/system-admin/global-settings/_id/sidebar/history/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBarStore } from "@components/cc-list-view-action-bar/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { EListType } from "@components/cc-product-list-view/components/hooks/useStoreStateListView";
import { useReferenceSideBarStore } from "@components/cc-reference-sidebar/store";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

export default observer(() => {
  const referenceSideBarStore = useReferenceSideBarStore();
  const location = useLocation();
  const history = useHistory();
  const state: any = location.state;
  const [isLoadingPermission, setIsLoadingPermission] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoadingPermission(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SETTINGS_MENU,
        1
      );
    }
    setIsLoadingPermission(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();
    return () => {
      resetStore();
    };
  });

  const actionBarStore = useCCListViewActionBarStore();
  const {
    loadDataSetting,
    responseLoadError,
    dataSetting,
    modeSetting,
    currentBreadcrumb,
    isLoading,
    columnFields,
    primaryKeyTable,
    setModeSetting,
  } = useSettingGlobalStore();

  const title = "settings";
  const actionBarLeft = [
    <SettingsActionBarNavDropdown category={settingsRoute.path} />,
  ];
  const actionBarRight = [
    <ActionBarExportIcon disabled={!hasPermission} />,
    <ActionBarFilterIcon disabled={!hasPermission} />,
    <ActionBarInfoIcon disabled={!hasPermission} />,
  ];

  useEffect(() => {
    actionBarStore.setTitle(title);
    actionBarStore.setLeftComponents(actionBarLeft);
    actionBarStore.setRightComponents(actionBarRight);
    referenceSideBarStore.setReferenceComponents([
      {
        title: "History",
        component: <GlobalSettingHistoryTab />,
      },
    ]);
    return () => {
      actionBarStore.resetDefault();
      referenceSideBarStore.resetDefault();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  useEffect(() => {
    const newConfig = processCategoryConfig();
    if (newConfig && newConfig.endPoint !== "") {
      let requestUrl =
        state && state.requestUrl ? state.requestUrl : newConfig.endPoint;
      loadDataSetting(requestUrl);
    } else {
      setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, history.location.state]);

  const processCategoryConfig = () => {
    let categoryConfig: ICategoryConfig | undefined = undefined;
    const categoryState = state?.category;
    if (!isNil(categoryState)) {
      categoryConfig = {
        name: state?.category,
        endPoint: state?.requestUrl,
        product: state?.product,
      };
    } else {
      categoryConfig = {
        name: defaultGlobalCategoryConfig.name,
        endPoint: defaultGlobalCategoryConfig.endPoint,
        product: defaultGlobalCategoryConfig.product,
      };
    }
    return categoryConfig;
  };

  if (isLoading || isLoadingPermission)
    return <Loading isLoading isFullScreen />;

  return (
    <div className="cc-system-admin-setting">
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            const newConfig = processCategoryConfig();
            if (newConfig && newConfig.endPoint !== "") {
              let requestUrl =
                state && state.requestUrl
                  ? state.requestUrl
                  : newConfig.endPoint;
              loadDataSetting(requestUrl);
            } else {
              setModeSetting(MODE_SETTING.SETTINGS_FOLDER);
            }
          }}
        />
      ) : (
        <>
          <Breadcrumb
            breadcrumb={currentBreadcrumb}
            isSettingFolder={modeSetting === MODE_SETTING.SETTINGS_FOLDER}
          />
          {modeSetting === MODE_SETTING.SETTINGS_FOLDER ? (
            <CCProductListView
              columnFields={columnFields ?? []}
              data={dataSetting ?? []}
              primaryField={primaryKeyTable}
              disabled={!hasPermission}
              typeList={EListType.Setting}
            />
          ) : modeSetting === MODE_SETTING.SETTINGS_ITEM ? (
            <>
              {dataSetting && (
                <GlobalSettingManage
                  isNew={state?.isNew ?? false}
                  data={dataSetting}
                />
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
});
