import { INewChargeDetailsTransactionStepsLOVs } from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

// being use in another component

configure({ enforceActions: "always" });
class NewChargeDetailsStepStore {
  private _newChargeDetailsStepLOVs?: INewChargeDetailsTransactionStepsLOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get newChargeDetailsStepLOVs() {
    return this._newChargeDetailsStepLOVs;
  }
  setNewChargeDetailsStepLOVs = (
    newChargeDetailsStepLOVs?: INewChargeDetailsTransactionStepsLOVs
  ) => {
    runInAction(() => {
      this._newChargeDetailsStepLOVs = newChargeDetailsStepLOVs;
    });
  };
}

const newChargeDetailsStepStoreContext = createContext(
  new NewChargeDetailsStepStore()
);
export const useNewChargeDetailsStepStore = () => {
  return useContext(newChargeDetailsStepStoreContext);
};
