import {
  DTO_LOV_Levy,
  DTO_LOV_RatingPeriod,
  IDataLOvsGroupByFinancialGroup,
  INewChargeDetailsTransactionStepsLOVs,
} from "@app/products/property/journals/[id]/components/forms/existed/components/form-steps/add-transaction/components/form-elements/new-charge-details/model";
import { cloneDeep } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewChargeDetailsAddTransactionStepStore {
  private _originNewChargeDetailsStepLOVs?: INewChargeDetailsTransactionStepsLOVs =
    undefined;
  private _newChargeDetailsStepLOVs?: INewChargeDetailsTransactionStepsLOVs =
    undefined;
  private _dataLOvsGroupByFinancialGroup?: IDataLOvsGroupByFinancialGroup =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get originNewChargeDetailsStepLOVs() {
    return toJS(this._originNewChargeDetailsStepLOVs);
  }
  setOriginNewChargeDetailsStepLOVs = (
    originNewChargeDetailsStepLOVs?: INewChargeDetailsTransactionStepsLOVs
  ) => {
    runInAction(() => {
      this._originNewChargeDetailsStepLOVs = originNewChargeDetailsStepLOVs;
      const newData = cloneDeep(originNewChargeDetailsStepLOVs);
      if (newData) {
        const ratingPeriod = newData["RatingPeriods"]?.reduce(
          (group: any, item: DTO_LOV_RatingPeriod) => {
            const { Financial_Groups } = item;
            const listFinancialGroups = Financial_Groups ?? [];
            listFinancialGroups?.forEach((financialGroup: number) => {
              group[financialGroup] = group[financialGroup] || [];
              group[financialGroup].push(item);
            });
            return group;
          },
          {}
        );
        const levy = newData["Levy"]?.reduce(
          (group: any, item: DTO_LOV_Levy) => {
            const { Financial_Group_Id } = item;
            if (Financial_Group_Id) {
              group[Financial_Group_Id] = group[Financial_Group_Id] || [];
              group[Financial_Group_Id].push(item);
            }
            return group;
          },
          {}
        );
        this._dataLOvsGroupByFinancialGroup = {
          RatingPeriods: ratingPeriod,
          Levy: levy,
        };
      }
    });
  };

  get newChargeDetailsStepLOVs() {
    return toJS(this._newChargeDetailsStepLOVs);
  }
  setNewChargeDetailsStepLOVs = (
    newChargeDetailsStepLOVs?: INewChargeDetailsTransactionStepsLOVs
  ) => {
    runInAction(() => {
      this._newChargeDetailsStepLOVs = newChargeDetailsStepLOVs;
    });
  };

  get dataLOvsGroupByFinancialGroup() {
    return toJS(this._dataLOvsGroupByFinancialGroup);
  }
}

const newChargeDetailsAddTransactionStepStoreContext = createContext(
  new NewChargeDetailsAddTransactionStepStore()
);
export const useNewChargeDetailsAddTransactionStepStore = () => {
  return useContext(newChargeDetailsAddTransactionStepStoreContext);
};
