import { CSLUrl } from "@app/products/csl/config";
import { CSLResponse } from "@app/products/csl/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { globalStoreInstance } from "@common/stores/global/store";

export const getCSLById = async (id: string) => {
  try {
    const response = await CoreAPIService.getClient().get<CSLResponse>(
      `${globalStoreInstance.allSetting.baseAPIUrl}${CSLUrl.GET_CSL.replace(
        "{id}",
        id
      )}`
    );

    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
