import {
  IMailMergeButtonProps,
  MailMergeButton,
} from "@app/core/new-mail-merge/buttons/components/buttons/_index";

import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil, uniq } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

interface IMailMergeButtonListViewProps extends IMailMergeButtonProps {
  primaryField?: string;
}
export const MailMergeButtonListView = observer(
  (props: IMailMergeButtonListViewProps) => {
    const { isEmptyGridSelectedRow, gridSelectedRows, gridSelectedIds } =
      useCCProductListViewStore();
    const { primaryField } = props;

    // primaryField use for case: records have the same ID
    // Use another field to make the primary field
    const selectedIds = useMemo(() => {
      if (!isNil(primaryField) && primaryField !== "") {
        return uniq(
          gridSelectedRows
            .map((item: any) => item?.[primaryField])
            .filter((primaryField) => primaryField !== null)
        );
      }
      return gridSelectedIds;
    }, [primaryField, gridSelectedRows, gridSelectedIds]);

    return (
      <MailMergeButton
        {...props}
        isEmptyGridSelectedRow={isEmptyGridSelectedRow}
        gridSelectedRows={gridSelectedRows}
        selectedRecordIds={selectedIds}
        titleButton="Mail Merge"
      />
    );
  }
);
