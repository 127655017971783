import { DTO_Component_Certificates } from "@app/products/property/parcels/[id]/components/child-screens/certificates/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";

export const loadParcelCertificate = async (
  parcelId: number
): Promise<DTO_Component_Certificates | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_Certificates>(
        `/api/property/internal/parcel/${parcelId}/certificates`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
