import { PRODUCT_TYPE } from "@common/constants/productType";
import { getAssetWarrantyChart } from "@common/pages/home/api";
import AMSWarrantyInsight from "@common/pages/home/components/insights/components/ams-warranty-insight/_index";

import { CrmsInsights } from "@common/pages/home/components/crms/insights/_index";
import HMComplaintsReceivedInsight from "@common/pages/home/components/insights/components/hm-complaints-received-insight/_index";
import HMFoodSafetyProgramsInsight from "@common/pages/home/components/insights/components/hm-food-safety-programs-insight/_index";
import HMPremisesByTypensights from "@common/pages/home/components/insights/components/hm-premises-by-type-insight/_index";
import { PropertyInsights } from "@common/pages/home/components/property/insights/_index";
import { CurrentReferralsByRegion } from "@common/pages/home/components/townplanning/insights/components/current-referrals-by-region/_index";
import { EnquiriesByDueStatus } from "@common/pages/home/components/townplanning/insights/components/enquiries-by-due-status/_index";
import { PlanningReferralResults } from "@common/pages/home/components/townplanning/insights/components/planning-referral-results/_index";
import {
  IAmsDashBoardWarrantyChart,
  IDashboardModelInsights,
} from "@common/pages/home/model";
import { useGlobalStore } from "@common/stores/global/store";
import { TOWN_PLANNING_CONFIGURATION_MODE } from "@common/stores/products/town-planning/enum";
import { useCommonTownPlanningStore } from "@common/stores/products/town-planning/store";
import Loading from "@components/loading/Loading";
import "hammerjs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

interface IInsights {
  queryDateTime: string;
  insights: IDashboardModelInsights;
}

export const Insights = observer(({ queryDateTime, insights }: IInsights) => {
  const { isVisibleAndIsCommunityCentral, productsActive } = useGlobalStore();
  const { townPlanningMode } = useCommonTownPlanningStore();
  const [premiseByType, setPremiseByType] = useState([]);
  const [amsWarrantyChartData, setAmsWarrantyChartData] =
    useState<IAmsDashBoardWarrantyChart>({
      categorydescription: "",
      expiredinlast2months: 0,
      expiresin1month: 0,
      expiresbetweennext1and2months: 0,
      expiresbetweennext2and3months: 0,
    });
  const [complaintsReceived, setComplaintsReceived] = useState({
    xData: [""],
    yData: [""],
  });

  const isVisibleHealthManager =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.HealthManager) === "visible";
  const isVisibleCommunityAsset =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CommunityAsset) === "visible";
  const isVisibleCommunityProperty =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CommunityProperty) ===
    "visible";
  const isVisibleTownPlanning =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.TownPlanning) === "visible";

  const isVisibleCrmsInsights =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CustomerService) === "visible";

  const isVisiblePPR = useMemo(
    () =>
      isVisibleTownPlanning &&
      townPlanningMode === TOWN_PLANNING_CONFIGURATION_MODE.PPR,
    [isVisibleTownPlanning, townPlanningMode]
  );

  useEffectOnce(() => {
    if (isVisibleCommunityAsset) {
      getAssetWarrantyChart()
        .then((response: IAmsDashBoardWarrantyChart) => {
          setAmsWarrantyChartData(response);
        })
        .catch((error: any) => {
          console.error(error);
          // setIsLoading(false);
        });
    }
  });

  useEffect(() => {
    let premiseByTypeData: any = [];
    if (insights && insights.premiseByType) {
      Object.keys(insights.premiseByType).forEach((x) =>
        premiseByTypeData.push({
          name: x,
          share: insights.premiseByType[x],
        })
      );
      setPremiseByType(premiseByTypeData);
    }

    if (insights && insights.complaintsReceived) {
      let complaintsReceivedData: any = { xData: [], yData: [] };
      Object.keys(insights.complaintsReceived).forEach((x) => {
        complaintsReceivedData.xData.push(x);
        complaintsReceivedData.yData.push(insights.complaintsReceived[x]);
      });
      setComplaintsReceived(complaintsReceivedData);
    }
  }, [insights]);

  return (
    <div className="cc-home-section cc-insight-section">
      <div className="cc-section-heading">Insights</div>
      <div className="cc-section-content">
        {productsActive.length > 0 ? (
          <>
            {isVisiblePPR && (
              <>
                <EnquiriesByDueStatus />
                <CurrentReferralsByRegion />
                <PlanningReferralResults />
              </>
            )}

            {isVisibleHealthManager && (
              <>
                <HMPremisesByTypensights
                  premiseByType={premiseByType}
                  queryDateTime={queryDateTime}
                />
                <HMFoodSafetyProgramsInsight queryDateTime={queryDateTime} />
                <HMComplaintsReceivedInsight
                  queryDateTime={queryDateTime}
                  complaintsReceived={complaintsReceived}
                />
              </>
            )}

            {isVisibleCommunityAsset && amsWarrantyChartData && (
              <AMSWarrantyInsight
                amsWarrantyInsightData={amsWarrantyChartData}
                queryDateTime={queryDateTime}
              />
            )}

            {isVisibleCrmsInsights && <CrmsInsights />}

            {isVisibleCommunityProperty && <PropertyInsights />}
          </>
        ) : (
          <Loading isLoading />
        )}
      </div>
    </div>
  );
});
