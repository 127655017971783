import { SaveButtonFeeTypes } from "@common/pages/settings/lookups/fee-types/_id/components/buttons/save/_index";
import { FeeTypeForm } from "@common/pages/settings/lookups/fee-types/_id/components/general/_index";
import { TitleFeeTypeManagePage } from "@common/pages/settings/lookups/fee-types/constant";
import { FeeType } from "@common/pages/settings/lookups/fee-types/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewManageFeeType = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const feeType = dataForms?.GeneralForm as FeeType;

  return (
    <>
      <FormTitle title={`New ${TitleFeeTypeManagePage}`} />
      <CCManagePageActionBar leftActions={[<SaveButtonFeeTypes />]} />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {feeType && <FeeTypeForm />}
          </div>
        </div>
      </div>
    </>
  );
});
