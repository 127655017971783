import { getEntityStreetLocality } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/api";
import {
  ComboboxSearchAPI,
  IEventOnChangeComboBox,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/_index";
import {
  EAddressStepperMode,
  EDeliveryOptions,
  dataDeliveryFirst,
  dataDeliverySecond,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import {
  VO_Entity_Locality,
  VO_StreetName,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/model";

import { isShowFieldsPersonStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/util";
import {
  checkTypeContact,
  processContactAddresses,
  propertyFieldGroupedByDelivery,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/util";
import {
  IRadioGroupData,
  MODE_CONTACT,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { nameOfLov } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { getDropdownValue } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  ComboBoxChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { cloneDeep, isNil } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./_index.scss";
export const PostalAndPhysicalAddressFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (value: any, valueGetter: (name: string) => any) => {
      if (props?.options?.isReadOnly) return undefined;
      const selectedType = valueGetter(
        `${props?.options?.keyOfPersonStep}.Type`
      );
      if (
        checkTypeContact(selectedType) === MODE_CONTACT.PERSON &&
        !value.AddressFormatted
      ) {
        return "Please input address!";
      }
      return "";
    },
    [props?.options]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    isLLS: false,
    contactLOVs: undefined,
    setIsDisableDialog: () => {},
    keyOfPersonStep: "EntityDetails",
  },
  localNotificationRef,
}: IFormStepElement) => {
  const contactLOVs = options?.contactLOVs;

  //state
  const [dataDelivery, setDataDelivery] =
    useState<IRadioGroupData[]>(dataDeliveryFirst);
  //LOVs
  const contactLovs = useMemo(() => {
    const unitType = options?.contactLOVs?.BuildingUnit ?? [];
    const floorType = options?.contactLOVs?.FloorType ?? [];
    const streetType = options?.contactLOVs?.StreetType ?? [];
    const state = options?.contactLOVs?.State ?? [];
    const country = options?.contactLOVs?.Country ?? [];
    const postalDeliveryType = options?.contactLOVs?.PostalDeliveryType ?? [];
    return {
      unitType,
      floorType,
      streetType,
      state,
      country,
      postalDeliveryType,
    };
  }, [options?.contactLOVs]);
  //props
  const { valueGetter, onChange } = formRenderProps;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  //get data field
  const typeValue = valueGetter(`${options.keyOfPersonStep}.Type`);
  const deliveryValue = getFieldValue("Delivery");
  const isSameAsPhysical = getFieldValue("Same_As_Physical");

  //Export ref
  if (options?.onChangeRef) {
    options.onChangeRef.current = { onChange, nameOf };
  }

  //useEffect set list data for delivery field
  useEffect(() => {
    if (isNil(typeValue)) return;
    let delivery = dataDeliveryFirst;
    if (checkTypeContact(typeValue) === MODE_CONTACT.PERSON) {
      delivery = dataDeliveryFirst;
    } else if (isShowFieldsPersonStep(typeValue)) {
      delivery = dataDeliverySecond;
    }
    setDataDelivery(delivery);
  }, [typeValue]);

  //set default value for option delivery
  useEffect(() => {
    if (
      checkTypeContact(typeValue) === MODE_CONTACT.PERSON &&
      deliveryValue === EDeliveryOptions.DXMail
    ) {
      onChange(nameOf("Delivery"), { value: EDeliveryOptions.STANDARD });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeValue, deliveryValue]);

  /**
   * handle Street Name field
   * set value for others field
   * calling api
   * @param event
   */
  const handleStreetName = async (event: IEventOnChangeComboBox) => {
    options?.setIsDisableDialog(true);
    onChange(nameOf("Street_Name"), {
      value: event?.value?.Street,
    });
    onChange(nameOf("_option.Street_Name"), {
      value: event?.value?.Street,
    });
    onChange(nameOf("_option.Street_Name_Data"), {
      value: event?.data ?? [],
    });

    const valueStreetName = event?.target?.value?.Street;
    if (valueStreetName) {
      const streetName = valueStreetName;
      onChange(nameOf("_option.Loading.Street_Name"), { value: true });
      const response = await getEntityStreetLocality({ streetName });
      if (isSuccessResponse(response)) {
        const data = response.data?.dtoStreetAndLocality;
        onChange(nameOf("Street_Type"), { value: data?.StreetType });
        onChange(nameOf("Locality"), { value: data?.Locality });
        onChange(nameOf("_option.Locality"), {
          value: { Locality_Code: data?.Locality },
        });
        onChange(nameOf("_option.Locality_Data"), {
          value: [],
        });
        onChange(nameOf("State"), { value: data?.State });
        onChange(nameOf("Post_Code"), { value: data?.PostCode });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response.error ?? "Load failed",
          type: "error",
          autoClose: false,
        });
      }
      onChange(nameOf("_option.Loading.Street_Name"), { value: false });
    }
    options?.setIsDisableDialog(false);
  };

  /**
   * handle Locality field
   * set data other field
   * calling api
   * @param event
   */
  const handleLocality = async (event: IEventOnChangeComboBox) => {
    onChange(nameOf("Locality"), {
      value: event?.value?.Locality_Code,
    });
    onChange(nameOf("_option.Locality"), {
      value: event?.value?.Locality_Code,
    });
    onChange(nameOf("_option.Locality_Data"), {
      value: event?.data ?? [],
    });
    const postcodeId = event?.target?.value?.Postcode_Id;
    if (!isNil(postcodeId)) {
      onChange(nameOf("_option.Loading.Locality"), { value: true });
      const response = await getEntityStreetLocality({ postcodeId });
      if (isSuccessResponse(response)) {
        const data = response.data?.dtoStreetAndLocality;
        onChange(nameOf("State"), { value: data?.State });
        onChange(nameOf("Post_Code"), { value: data?.PostCode });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response.error ?? "Load failed",
          type: "error",
          autoClose: false,
        });
      }
      onChange(nameOf("_option.Loading.Locality"), { value: false });
    }
  };

  /**
   * check condition showing Locality field
   */
  const isShowLocality = useMemo(() => {
    return (
      deliveryValue === EDeliveryOptions.STANDARD ||
      deliveryValue === EDeliveryOptions.SPECIAL ||
      (isShowFieldsPersonStep(typeValue) &&
        deliveryValue === EDeliveryOptions.DXMail)
    );
  }, [deliveryValue, typeValue]);

  /**
   * check condition showing Formatted Address field
   */
  const isShowFormattedAddress = useMemo(() => {
    return (
      deliveryValue === EDeliveryOptions.STANDARD ||
      deliveryValue === EDeliveryOptions.SPECIAL ||
      deliveryValue === EDeliveryOptions.INTERNATIONAL ||
      (isShowFieldsPersonStep(typeValue) &&
        deliveryValue === EDeliveryOptions.DXMail)
    );
  }, [deliveryValue, typeValue]);

  const unitTypeSelectedItem = useMemo(() => {
    return getDropdownValue(
      getFieldValue("Unit_Type"),
      contactLovs.unitType,
      nameOfLov("Code")
    );
  }, [contactLovs.unitType, getFieldValue]);

  const floorTypeSelectedItem = useMemo(() => {
    return getDropdownValue(
      getFieldValue("Floor_Type"),
      contactLovs.floorType,
      nameOfLov("Code")
    );
  }, [contactLovs.floorType, getFieldValue]);

  /**
   * Combine value other field to show in Formatted Address
   */
  const valueFormattedAddress = useMemo(() => {
    return processContactAddresses(
      deliveryValue,
      typeValue,
      unitTypeSelectedItem?.[nameOfLov("Name")],
      floorTypeSelectedItem?.[nameOfLov("Name")],
      valueGetter,
      getFieldValue
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deliveryValue,
    typeValue,
    unitTypeSelectedItem,
    floorTypeSelectedItem,
    valueGetter,
    getFieldValue,
  ]);

  useEffect(() => {
    onChange(nameOf("AddressFormatted"), { value: valueFormattedAddress });
    //  Once we change values in Postal step and "Same as postal"in Physical step is turned on
    //  => Clone value from Postal step to Physical step
    if (
      options?.stepperMode === EAddressStepperMode.Physical &&
      valueGetter(`${options?.keyOfRelatedAddressStep}.Same_As_Physical`)
    ) {
      const postalStepValues = cloneDeep(getFieldValue(""));
      onChange(options.keyOfRelatedAddressStep, {
        value: { ...postalStepValues, Same_As_Physical: true },
      });
    }
    // eslint-disable-next-line
  }, [valueFormattedAddress]);

  return (
    <section className="cc-field-group cc-postal-address-step">
      <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
        {options?.stepperMode === EAddressStepperMode.Postal && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <Field
                name={nameOf("Same_As_Physical")}
                component={Checkbox}
                label={"Same as physical address"}
                disabled={options?.isReadOnly}
                onChange={(event: CheckboxChangeEvent) => {
                  //Once "Same as postal" is turned on=> Clone value from Postal step to Physical step
                  if (event.value && options?.keyOfRelatedAddressStep) {
                    const postalStepValues = cloneDeep(
                      valueGetter(options.keyOfRelatedAddressStep)
                    );
                    onChange(nameOf(""), {
                      value: postalStepValues,
                    });
                  }
                  onChange(nameOf("Same_As_Physical"), { value: event.value });
                }}
              />
            </div>
          </div>
        )}
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Delivery" />
            <Field
              name={nameOf("Delivery")}
              component={CCRadioGroup}
              data={dataDelivery}
              value={deliveryValue}
              layout={"horizontal"}
              disabled={options?.isReadOnly || isSameAsPhysical}
            />
          </div>
        </div>
        {deliveryValue === EDeliveryOptions.STANDARD && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Property name" />
              <Field
                name={nameOf(
                  propertyFieldGroupedByDelivery[EDeliveryOptions.STANDARD]
                )}
                component={CCInput}
                placeholder={"Property name"}
                readOnly={options?.isReadOnly || isSameAsPhysical}
              />
            </div>
          </div>
        )}
        {deliveryValue === EDeliveryOptions.INTERNATIONAL && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Property name" />
              <Field
                name={nameOf(
                  propertyFieldGroupedByDelivery[EDeliveryOptions.INTERNATIONAL]
                )}
                component={CCInput}
                placeholder={"Property name"}
                readOnly={options?.isReadOnly || isSameAsPhysical}
              />
            </div>
          </div>
        )}
        {deliveryValue === EDeliveryOptions.INTERNATIONAL && (
          <>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Address" />
                <Field
                  name={nameOf("Address")}
                  component={CCTextArea}
                  rows={3}
                  placeholder={"Address"}
                  readOnly={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Country" />
                <Field
                  name={nameOf("Country")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.country}
                  component={CCSearchComboBox}
                  isUseDefaultOnchange
                  disabled={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
            </div>
          </>
        )}
        {deliveryValue === EDeliveryOptions.SPECIAL && (
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <CCLabel title="Postal delivery type" />
              <Field
                name={nameOf("Postal_Delivery_Type")}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                data={contactLovs.postalDeliveryType}
                component={CCSearchComboBox}
                isUseDefaultOnchange
                disabled={options?.isReadOnly || isSameAsPhysical}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Postal delivery number" />
              <Field
                name={nameOf("Postal_Delivery_Number")}
                component={CCInput}
                placeholder={"Postal delivery number"}
                readOnly={options?.isReadOnly || isSameAsPhysical}
              />
            </div>
          </div>
        )}
        {deliveryValue === EDeliveryOptions.STANDARD && (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Unit type" />
                <Field
                  name={nameOf("Unit_Type")}
                  data={contactLovs.unitType}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly || isSameAsPhysical}
                  value={unitTypeSelectedItem}
                  onChange={(e: ComboBoxChangeEvent) => {
                    onChange(nameOf("_option.Unit_Type"), {
                      value: e.target.value?.[nameOfLov("Name")],
                    });
                    onChange(nameOf("Unit_Type"), {
                      value: e.target.value?.[nameOfLov("Code")],
                    });
                  }}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Unit number" />
                <Field
                  name={nameOf("Unit_Number")}
                  component={CCInput}
                  placeholder={"Unit number"}
                  readOnly={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Floor/Level type" />
                <Field
                  name={nameOf("Floor_Type")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.floorType}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly || isSameAsPhysical}
                  value={floorTypeSelectedItem}
                  onChange={(e: ComboBoxChangeEvent) => {
                    onChange(nameOf("_option.Floor_Type"), {
                      value: e.target.value?.[nameOfLov("Name")],
                    });
                    onChange(nameOf("Floor_Type"), {
                      value: e.target.value?.[nameOfLov("Code")],
                    });
                  }}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Floor/Level number" />
                <Field
                  name={nameOf("Floor_Number")}
                  component={CCInput}
                  placeholder={"Floor/Level number"}
                  readOnly={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
            </div>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="House number" />
                <Field
                  name={nameOf("House_Number")}
                  component={CCInput}
                  placeholder={"House number"}
                  readOnly={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Street name"
                  isLoading={getFieldValue("_option.Loading.Street_Name")}
                />
                <Field
                  name={nameOf("_option.Street_Name")}
                  textField="Street"
                  dataItemKey="Street"
                  urlAPI={`/odata/property/internal/entitystreetnameRegister`}
                  component={(event) => ComboboxSearchAPI<VO_StreetName>(event)}
                  keySearch={"Street"}
                  disabled={options?.isReadOnly || isSameAsPhysical}
                  data={getFieldValue("_option.Street_Name_Data")}
                  onChange={handleStreetName}
                  allowCustom
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Street type" />
                <Field
                  name={nameOf("Street_Type")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.streetType}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
            </div>
          </>
        )}
        {(deliveryValue === EDeliveryOptions.STANDARD ||
          deliveryValue === EDeliveryOptions.SPECIAL) && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Additional address" />
              <Field
                name={nameOf("Additional_Address")}
                component={CCTextArea}
                rows={3}
                placeholder={"Additional address"}
                readOnly={options?.isReadOnly || isSameAsPhysical}
              />
            </div>
          </div>
        )}

        <div className="cc-form-cols-3">
          {isShowFieldsPersonStep(typeValue) &&
            deliveryValue === EDeliveryOptions.DXMail && (
              <div className="cc-field">
                <CCLabel title="DX mail number" />
                <Field
                  name={nameOf("Postal_Delivery_Number")}
                  component={CCInput}
                  placeholder={"DX mail number"}
                  readOnly={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
            )}
          {isShowLocality && (
            <div className="cc-field">
              <CCLabel
                title="Locality"
                isLoading={getFieldValue("_option.Loading.Locality")}
              />
              <Field
                name={nameOf("_option.Locality")}
                textField="Locality_Code"
                dataItemKey="Locality_Code"
                urlAPI={`/odata/property/internal/entitylocalityRegister`}
                component={(event) =>
                  ComboboxSearchAPI<VO_Entity_Locality>(event)
                }
                itemRender={ItemRenderLocality}
                keySearch={"Locality_Code"}
                disabled={options?.isReadOnly || isSameAsPhysical}
                data={getFieldValue("_option.Locality_Data")}
                onChange={handleLocality}
              />
            </div>
          )}
          {(deliveryValue === EDeliveryOptions.STANDARD ||
            deliveryValue === EDeliveryOptions.SPECIAL) && (
            <>
              <div className="cc-field">
                <CCLabel title="State" />
                <Field
                  name={nameOf("State")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.state}
                  isUseDefaultOnchange
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Postcode" />
                <Field
                  name={nameOf("Post_Code")}
                  component={CCInput}
                  placeholder={"Postcode"}
                  readOnly={options?.isReadOnly || isSameAsPhysical}
                />
              </div>
              {options?.isLLS && (
                <div className="cc-field">
                  <CCLabel title="Country" />
                  <Field
                    name={nameOf("Country")}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={contactLOVs?.Country ?? []}
                    value={getDropdownValue(
                      valueGetter(nameOf("Country")),
                      contactLOVs?.Country,
                      nameOfLov("Code")
                    )}
                    component={CCSearchComboBox}
                    onChange={(event: ComboBoxChangeEvent) =>
                      onChange(nameOf("Country"), {
                        value: event.value?.Code,
                      })
                    }
                    disabled={options?.isReadOnly || isSameAsPhysical}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {isShowFormattedAddress && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel
                title="Formatted name and address"
                isMandatory={
                  checkTypeContact(typeValue) === MODE_CONTACT.PERSON
                }
              />
              <Field
                name={nameOf("AddressFormatted")}
                component={CCTextArea}
                rows={6}
                readOnly
                placeholder={"Formatted name and address"}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
const ItemRenderLocality = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = <>{dataItem?.Locality_Name}</>;
  return React.cloneElement(li, li.props, itemChildren);
};
