import { useWriteOffBalanceDialogStore } from "@app/products/property/deferred-duty/components/action-bar/form-steps/write-off-balances/store";
import { getDropdownValue } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const NEW_JOURNAL_FORM_STEP = "NewJournal";
export const WriteOffBalanceNewJournalFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { writeOffBalanceLOVs } = useWriteOffBalanceDialogStore();
    const { valueGetter, onChange } = formRenderProps;

    return (
      <section className="cc-field-group">
        <div className="cc-field">
          <label className="cc-label">Journal type</label>
          <Field
            name={nameOf("Journal_Type")}
            component={CCSearchComboBox}
            textField="Name"
            dataItemKey="Code"
            data={writeOffBalanceLOVs?.JournalTypes ?? []}
            value={getDropdownValue(
              valueGetter(nameOf("Journal_Type"))?.toString(),
              writeOffBalanceLOVs?.JournalTypes ?? [],
              "Code"
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(nameOf("Journal_Type"), {
                value: event.target.value?.Code ?? null,
              });
            }}
            disabled
          />
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Reference</label>
            <Field
              name={nameOf("Jnl_Reference")}
              component={CCInput}
              placeholder="Reference"
              readOnly={options.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Description</label>
            <Field
              name={nameOf("Jnl_Description")}
              placeholder="Description"
              component={CCInput}
              readOnly={options.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Narration</label>
            <Field
              name={nameOf("Jnl_Notes")}
              placeholder="Narration"
              rows={4}
              component={CCTextArea}
              readOnly={options.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
