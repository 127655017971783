import { APIResponse } from "@common/apis/model";
import { FINANCESYSTEM } from "@common/constants/enumerations";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { usePageType } from "@common/hooks/usePageType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getFeeTypeById } from "@common/pages/settings/lookups/fee-types/_id/api";
import {
  FeeType,
  TApiFeeType,
} from "@common/pages/settings/lookups/fee-types/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const FeeTypesDetailsTab = observer(() => {
  //#region Store ========/
  const { dataForms } = useFlexibleFormStore();
  const { lastSelectedId } = useCCProductListViewStore();
  //#endregion Store =====/

  //#region State ========/
  const [feeType, setFeeType] = useState<FeeType>();
  //#endregion State =====/

  //#region Hook ========/
  const { cancelToken } = useCancelRequest();
  const { isManagePage, isProductListView } = usePageType();
  const { settings } = useCommonCoreStore();
  //#endregion Hook =====/

  //#region VARIABLE ========/
  const { id } = useParams<{ id: string }>();
  const feeTypeId: string | number = id ? id : lastSelectedId;

  const financeSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FinanceSystem]
  );
  const subsidyIsEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_Enable_Subsidies]
  );

  let costCodeLabel = useMemo(() => {
    if (financeSystem === FINANCESYSTEM.MYOB) {
      return "Item code";
    }
    return "Ledger code";
  }, [financeSystem]);

  let isHideSubsidyEnableAndChecked = useMemo(() => {
    if (subsidyIsEnabled && feeType?.Subsidy === true) {
      return false;
    }
    return true;
  }, [subsidyIsEnabled, feeType?.Subsidy]);
  //#endregion VARIABLE =====/

  //#region API ========/
  const feeTypesDetailsTabSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => [
          // getFeeTypeLOVs(cancelToken()),
          getFeeTypeById(+feeTypeId, cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const [
            // _feeTypeLovsIdentityPacket,
            _feeTypeIdentityPacket,
          ]: [
            // APIResponse<TApiFeeType["Lovs"]>,
            APIResponse<TApiFeeType["Value"]>
          ] = dataFromApi;

          // const _feeTypeLovs = _feeTypeLovsIdentityPacket.data?.ReturnObj;
          const _feeType = _feeTypeIdentityPacket.data?.ReturnObj;

          // setFeeTypeLovs(_feeTypeLovs);
          setFeeType(_feeType);
        },
      },
    ],
  });
  //#endregion API =====/

  useEffect(() => {
    if (isManagePage) {
      setFeeType(dataForms?.GeneralForm as FeeType);
    }
    if (isProductListView) {
      feeTypesDetailsTabSlider.fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManagePage, isProductListView, dataForms, feeTypeId]);

  if (feeTypesDetailsTabSlider.isFetching) return <Loading isLoading />;
  if (feeTypesDetailsTabSlider.errorComponent) {
    return feeTypesDetailsTabSlider.errorComponent;
  }
  if (!feeType) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Fee type ID:"}
        value={feeType?.FeeType_ID?.toString()}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow title="Name:" value={feeType?.Name} />
        {isHideSubsidyEnableAndChecked && (
          <ProductReferenceRow title={costCodeLabel} value={feeType?.Code} />
        )}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
