import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { SiteUser } from "@app/products/waste-water/[id]/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { AdditionalHearing } from "@common/pages/appeal/_id/components/child-screens/general/components/form-element/components/additonal-hearing/_index";
import { IAppeal } from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { formatDisplayValue } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { TooltipValidator } from "@components/TooltipValidator/TooltipValidator";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { TextArea } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

interface IAppealFormElementProps {
  formRenderProps: FormRenderProps;
}

export const AppealFormElement = observer(
  ({ formRenderProps }: IAppealFormElementProps) => {
    const { lovs, ancestor, parentDecision, isInactive } = useAppealStore();
    const nameOf = nameOfFactory<IAppeal>();
    const { valueGetter, onSubmit, onChange } = formRenderProps;
    const getFieldValue = (name: keyof IAppeal) => valueGetter(nameOf(name));
    const { pushNotification } = useCCAppNotificationStore();

    const handlePickOfficer = (value: SiteUser | null, name: string) => {
      if (!value) {
        onChange(name, {
          value: null,
        });
        return;
      }
      onChange(name, {
        value: value.DisplayName,
      });
      const officerName = name.split(".")[0];
      onChange(`${officerName}_ID`, {
        value: value?.Contact_ID ?? null,
      });
    };

    const AppealField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement onSubmit={onSubmit}>
        <section className="cc-field-group">
          {ancestor && (
            <>
              <section className="cc-field-group">
                {ancestor.lblParent1 !== "" && (
                  <div className="cc-form-cols-3">
                    {ancestor.litParent1 && (
                      <CCValueField
                        label={ancestor.lblParent1}
                        value={sanitizeHtml(ancestor.litParent1)}
                      />
                    )}
                    {ancestor.litParent2 && (
                      <CCValueField
                        label={ancestor.lblParent2}
                        value={sanitizeHtml(ancestor.litParent2)}
                      />
                    )}
                    {ancestor.litParent3 && (
                      <CCValueField
                        label={ancestor.lblParent3}
                        value={sanitizeHtml(ancestor.litParent3)}
                      />
                    )}
                    {ancestor.litParent4 && (
                      <CCValueField
                        label={ancestor.lblParent4}
                        value={sanitizeHtml(ancestor.litParent4)}
                      />
                    )}
                    {ancestor.litParent5 && (
                      <CCValueField
                        label={ancestor.lblParent5}
                        value={ancestor.litParent5}
                        format={CURRENCY_FORMAT.CURRENCY4}
                      />
                    )}
                    {ancestor.litParent6 && (
                      <CCValueField
                        label={ancestor.lblParent6}
                        value={sanitizeHtml(ancestor.litParent6)}
                      />
                    )}
                  </div>
                )}
              </section>
              <hr className="cc-divider" />
            </>
          )}
          <section>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Applicant</label>
                <AppealField
                  name={nameOf("ApplicantDetails")}
                  component={CCInput}
                  placeholder={"Applicant"}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Cost of works</label>
                <AppealField
                  name={nameOf("CostOfWorks")}
                  component={CCCurrencyInput}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Appeal number
                  <TooltipValidator />
                </label>
                <AppealField
                  name={nameOf("AppealNo")}
                  component={CCInput}
                  validator={requiredValidator}
                  placeholder="Appeal number"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Date appealed
                  <TooltipValidator />
                </label>
                <AppealField
                  name={nameOf("DateAppealed")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  validator={requiredValidator}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  Grounds
                  <TooltipValidator />
                </label>
                <AppealField
                  name={nameOf("Grounds_KWD")}
                  component={CCSearchComboBox}
                  data={lovs?.Grounds ?? []}
                  validator={requiredValidator}
                  textField="Value"
                  dataItemKey="Key"
                  isUseDefaultOnchange
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">
                  VCAT representative
                  <TooltipValidator />
                </label>
                <AppealField
                  name={nameOf("Appellant_Details")}
                  component={CCInput}
                  validator={requiredValidator}
                  placeholder="VCAT representative"
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Phone</label>
                <AppealField
                  name={nameOf("AppellantContact_Details2")}
                  component={CCInput}
                  placeholder="Phone"
                />
              </div>
              <div className="cc-field">
                <AppealField
                  label="Email"
                  name={nameOf("AppellantContact_Details")}
                  placeholder="Email"
                  component={CCInputEmail}
                  validator={validateEmail}
                  onChangeForm={formRenderProps.onChange}
                />
              </div>
            </div>
            <hr className="cc-divider" />
          </section>

          {parentDecision && (
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Decision"
                  value={parentDecision?.DecisionName}
                />
                <CCValueField
                  label="Decision date"
                  value={formatDisplayValue(
                    parentDecision?.DecisionDate,
                    DATE_FORMAT.DATE
                  )}
                  tooltip={
                    parentDecision?.DecisionValue ? (
                      <CCTooltip
                        type="info"
                        position="right"
                        content={parentDecision?.DecisionValue}
                      />
                    ) : undefined
                  }
                />
              </div>
            </section>
          )}
          <section>
            <label className="cc-label">Hearing</label>
            <div className="cc-custom-sub-panel-bar">
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Hearing type</label>
                    <AppealField
                      name={nameOf("HearingType_KWD")}
                      component={CCSearchComboBox}
                      data={lovs?.HearingType ?? []}
                      textField="Value"
                      dataItemKey="Key"
                      isUseDefaultOnchange
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Hearing duration</label>
                    <AppealField
                      name={nameOf("HearingDuration")}
                      component={CCSearchComboBox}
                      data={lovs?.HearingDuration ?? []}
                      textField="Value"
                      dataItemKey="Key"
                      value={getDropdownValue(
                        +getFieldValue("HearingDuration"),
                        lovs?.HearingDuration ?? [],
                        "Key"
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange(nameOf("HearingDuration"), {
                          value: event.value?.Key ?? null,
                        });
                      }}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Hearing date and time</label>
                    <AppealField
                      name={nameOf("HearingDate")}
                      format={DATETIME_FORMAT.DATETIME_CONTROL}
                      component={CCDateTimePicker}
                    />
                  </div>
                  <div className="cc-field">
                    <div className="cc-field">
                      <label className="cc-label">Officer</label>
                      <AppealField
                        name={"HearingRepresentationOfficer.DisplayName"}
                        component={InputPickerSearch}
                        placeholder={"Officer"}
                        onChange={(value: SiteUser) =>
                          handlePickOfficer(
                            value,
                            "HearingRepresentationOfficer.DisplayName"
                          )
                        }
                        options={optionInputPickerOfficer}
                        onError={(error: any) => {
                          pushNotification({
                            title: error,
                            type: "error",
                            autoClose: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Witness</label>
                    <AppealField
                      name={nameOf("Witness_Deatils")}
                      component={CCInput}
                      placeholder="Witness"
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">
                      Application representation
                    </label>
                    <AppealField
                      name={nameOf("AppRepresentation_Details")}
                      component={CCInput}
                      placeholder="Application representaion"
                    />
                  </div>

                  <div className="cc-field">
                    <label className="cc-label">DTP attending hearing</label>
                    <AppealField
                      name={nameOf("Flag_DotAttendingHearing")}
                      component={CCSwitch}
                      checked={valueGetter(nameOf("Flag_DotAttendingHearing"))}
                    />
                  </div>
                </div>
              </section>
            </div>
          </section>
          <section>
            <label className="cc-label">Practice day hearing</label>
            <div className="cc-custom-sub-panel-bar">
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">Hearing duration</label>
                    <AppealField
                      name={nameOf("PracticeHearingDuration")}
                      component={CCSearchComboBox}
                      data={lovs?.PracticeHearingDuration ?? []}
                      textField="Value"
                      dataItemKey="Key"
                      value={getDropdownValue(
                        +getFieldValue("PracticeHearingDuration"),
                        lovs?.PracticeHearingDuration ?? [],
                        "Key"
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        onChange(nameOf("PracticeHearingDuration"), {
                          value: event.value?.Key ?? null,
                        });
                      }}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Hearing date & time</label>
                    <AppealField
                      name={nameOf("PracticeHearingDate")}
                      format={DATETIME_FORMAT.DATETIME_CONTROL}
                      component={CCDateTimePicker}
                    />
                  </div>
                  <div className="cc-field">
                    <div className="cc-field">
                      <label className="cc-label">Officer</label>
                      <AppealField
                        name={
                          "PracticeHearingRepresentationOfficer.DisplayName"
                        }
                        component={InputPickerSearch}
                        placeholder={"Officer"}
                        onChange={(value: SiteUser) =>
                          handlePickOfficer(
                            value,
                            "PracticeHearingRepresentationOfficer.DisplayName"
                          )
                        }
                        options={optionInputPickerOfficer}
                        onError={(error: any) => {
                          pushNotification({
                            title: error,
                            type: "error",
                            autoClose: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">DTP attending hearing</label>
                    <AppealField
                      name={nameOf("Flag_PracticeHearingDotAttending")}
                      component={CCSwitch}
                      checked={valueGetter(
                        nameOf("Flag_PracticeHearingDotAttending")
                      )}
                    />
                  </div>
                </div>
              </section>
            </div>
          </section>
          <section>
            <label className="cc-label">Compulsory conference</label>
            <div className="cc-custom-sub-panel-bar">
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">CC duration</label>
                    <AppealField
                      name={nameOf("CCDuration")}
                      component={CCSearchComboBox}
                      data={lovs?.CCDuration ?? []}
                      textField="Value"
                      dataItemKey="Key"
                      isUseDefaultOnchange
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">CC date & time</label>
                    <AppealField
                      name={nameOf("CCDateTime")}
                      format={DATETIME_FORMAT.DATETIME_CONTROL}
                      component={CCDateTimePicker}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Officer</label>
                    <AppealField
                      name={"CCRepresentationOfficer.DisplayName"}
                      component={InputPickerSearch}
                      placeholder={"Officer"}
                      onChange={(value: SiteUser) =>
                        handlePickOfficer(
                          value,
                          "CCRepresentationOfficer.DisplayName"
                        )
                      }
                      options={optionInputPickerOfficer}
                      onError={(error: any) => {
                        pushNotification({
                          title: error,
                          type: "error",
                          autoClose: false,
                        });
                      }}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">DTP attending</label>
                    <AppealField
                      name={nameOf("Flag_CCDotAttending")}
                      component={CCSwitch}
                      checked={valueGetter(nameOf("Flag_CCDotAttending"))}
                    />
                  </div>
                </div>
              </section>
            </div>
          </section>
          <section>
            <label className="cc-label">Additional hearings</label>
            <div className="cc-custom-sub-panel-bar">
              <AdditionalHearing />
            </div>
          </section>
          <section>
            <label className="cc-label">SOG details</label>
            <div className="cc-custom-sub-panel-bar">
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">SOG decision</label>
                    <AppealField
                      name={nameOf("SOGDecision_ENUM")}
                      component={CCSearchComboBox}
                      data={lovs?.SOGDecision ?? []}
                      textField="Value"
                      dataItemKey="Key"
                      isUseDefaultOnchange
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">SOG due date</label>
                    <AppealField
                      name={nameOf("SOGDueDate")}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                    />
                  </div>
                </div>
              </section>
            </div>
          </section>
          <section>
            <label className="cc-label">Decision</label>
            <div className="cc-custom-sub-panel-bar">
              <section className="cc-field-group">
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">VCAT decision</label>
                    <AppealField
                      name={nameOf("AppealDecision_KWD")}
                      component={CCSearchComboBox}
                      data={lovs?.AppealDecision ?? []}
                      textField="Value"
                      dataItemKey="Key"
                      isUseDefaultOnchange
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Decision date</label>
                    <AppealField
                      name={nameOf("DecisionDate")}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <label className="cc-label">VCAT member</label>
                    <AppealField
                      name={nameOf("TribunalMember")}
                      component={CCInput}
                      placeholder="VCAT member"
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Decision status</label>
                    <AppealField
                      name={nameOf("DecisionStatus")}
                      component={CCInput}
                      placeholder="Decision status"
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Hearing cost</label>
                    <AppealField
                      name={nameOf("HearingCost")}
                      component={CCCurrencyInput}
                    />
                  </div>
                </div>
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">Decision/policy issues</label>
                    <AppealField
                      name={nameOf("DecisionPolicyIssues")}
                      component={TextArea}
                      placeholder="Decision/policy issues"
                      rows={3}
                    />
                  </div>
                  <div className="cc-field">
                    <label className="cc-label">Key issues</label>
                    <AppealField
                      name={nameOf("KeyIssues")}
                      component={TextArea}
                      placeholder="Key issues"
                      rows={3}
                    />
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
      </FormElement>
    );
  }
);
