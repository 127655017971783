import { ICCRoute } from "@common/constants/ICCRoute";
export const localGovernmentRoute: ICCRoute = {
  path: "local-government",
  name: "Local Government",
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
