import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { colAdministrativeSiteUsers } from "@common/pages/settings/security/administrative-site-users/config";
import { API_ADMINISTRATIVE_SITE_USERS } from "@common/pages/settings/security/administrative-site-users/constant";
import { administrativeSiteUsers } from "@common/pages/settings/security/administrative-site-users/util";
import { SiteUserView } from "@common/pages/settings/security/site-users/model";
import { SiteUserTabDetails } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/_index";
import { SiteUserHistoryTab } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/history/_index";
import { SITE_USER_ROUTE } from "@common/pages/settings/security/site-users/_id/constant";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<SiteUserView>();

export default observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SECURITY_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SITE_USER_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_SiteUser_CorporateAuthorisation}
        detail={administrativeSiteUsers.getBookmarkDetail}
        displayName={administrativeSiteUsers.getBookmarkDisplayName}
        listViewDetail={administrativeSiteUsers.getBookmarkListViewDetail}
        listViewDisplayName={
          administrativeSiteUsers.getBookmarkListViewDisplayName
        }
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SiteUserTabDetails /> },
      {
        title: "History",
        component: <SiteUserHistoryTab />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colAdministrativeSiteUsers}
      dataUrl={API_ADMINISTRATIVE_SITE_USERS}
      primaryField={nameOf("ID")}
      disabled={!hasPermission}
    />
  );
});
