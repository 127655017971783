import { CategorySubCategoryDialog } from "@app/products/crms/components/dialogs/category-sub-category/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface ICRMSPickKeysServiceStandardButtonProps {
  onSubmit: (data: any) => void;
  buttonToolTip?: string;
}
export const CrmsPickKeysServiceStandardButton = ({
  onSubmit,
  buttonToolTip = "Pick service standard",
}: ICRMSPickKeysServiceStandardButtonProps) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  return (
    <>
      <Button
        type="button"
        onClick={() => setIsOpenDialog(true)}
        title={buttonToolTip}
        iconClass="fas fa-plus"
      />
      {isOpenDialog && (
        <CategorySubCategoryDialog
          onClosePopup={() => setIsOpenDialog(false)}
          selectableMode="multiple"
          onSubmit={onSubmit}
          titleHeader="Please Select Service Standard(s)"
        />
      )}
    </>
  );
};
