import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { DTO_Workflow_Supplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/complete-incomplete/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const postProcessSupplementary = async (
  workflowType: WorkflowProcessMode,
  data?: DTO_Workflow_Supplementary
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_Supplementary>>
> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/supplementary/process/${workflowType}`,
      data
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
