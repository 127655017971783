import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";

const nameOf = nameOfFactory<VO_Workflow_Draft>();

export const colSecondaryWorkflowReadOnly: IColumnFields[] = [
  {
    field: nameOf("Workflow"),
    title: "Workflow Name",
  },
  {
    field: nameOf("Workflow_Status_Name"),
    title: "Workflow Status",
  },
  {
    field: nameOf("Created_By"),
    title: "Officer",
  },
  {
    field: nameOf("Created_On"),
    title: "Created",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("WD_Completion_DateTime"),
    title: "Completed Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Workflow_Approval_Status_Name"),
    title: "Approval Status",
  },
  {
    field: nameOf("Current_Step_Approval_Group"),
    title: "Functional Group",
  },
];
