import { DTO_DebtRecoveryAssessment } from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/model";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<DTO_DebtRecoveryAssessment>();

export const colDebtRecoveryDetail: IColumnFields[] = [
  {
    field: nameOf("AssessmentNumberFormatted"),
    title: "Assessment Number",
  },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("PropertyName_Address_Locality"),
    title: "Property Address",
  },
  {
    field: nameOf("CurrentOwner"),
    title: "Current Owner",
  },
  {
    field: nameOf("InitialCharges"),
    title: "Initial Charges",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("InitialInterest"),
    title: "Initial Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("CostsIncurred"),
    title: "Costs Incurred",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("TotalDebt"),
    title: "Total Debt",
    format: CURRENCY_FORMAT.CURRENCY1,
  },

  {
    field: nameOf("DebtCostBalance"),
    title: "Debt+costs Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("AssessmentBalance"),
    title: "Assessment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
