import { IFormStepElement } from "@components/cc-form-step/model";
import { FieldArray } from "@progress/kendo-react-form";
import React from "react";
export const MAIL_MERGE_WORKFLOW_FORM_STEP = "Workflow";

export const WorkflowFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = () => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <p>NONE</p>
        </div>
      </div>
    </section>
  );
};
