import { PropertySettingRefreshCacheButton } from "@app/products/property/system-admin/settings/_id/components/action-bar/components/action-bar/buttons/refresh-cache/_index";
import { useSettingManageFormActionBarStore } from "@app/products/property/system-admin/settings/_id/components/action-bar/store";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";

export const SettingManageFormActionBar = observer((props: any) => {
  const { isLoadingSubmitNew } = useSettingManageFormActionBarStore();

  // [CP-69] Property Refresh API:
  // Conditionally assign leftActions based on the isShowRefreshCache  prop
  const leftActions: ReactElement[] | undefined = props?.isShowRefreshCache
    ? [<PropertySettingRefreshCacheButton />]
    : undefined;

  const SaveButton = (
    <CCNavButton
      title="Save"
      htmlFor="btn-submit"
      disabled={isLoadingSubmitNew}
      isLoading={isLoadingSubmitNew}
    />
  );

  const SlideBarButton = (
    <CCNavButton
      title="Slide Bar"
      iconClass="fal fa-info-circle"
      isActive={props.isShowSlideBar}
      onClick={() => {
        props.onClick();
      }}
    />
  );

  return props.isNew ? (
    <CCManagePageActionBar leftActions={[SaveButton]} />
  ) : (
    <CCManagePageActionBar
      rightActions={[SlideBarButton]}
      leftActions={leftActions}
    />
  );
});
