import { eventEmitter } from "@/App";
import { ManagePageEventType } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/config";
import { isRefreshData } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/util";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { usePageType } from "@common/hooks/usePageType";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ReferenceSidebarEventType } from "@components/cc-reference-sidebar/config";

export const useRefreshData = (
  workflowHeader: DTO_WorkflowHeader | undefined
) => {
  const { isManagePage } = usePageType();
  const refreshPage = (callback?: Function) => {
    const handleRefreshData = () => {
      if (isManagePage) {
        //Refresh the manage page
        eventEmitter.emit(ManagePageEventType.RefreshData, callback);
      } else {
        //In the List View page, refresh the grid and reference sidebar
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(ReferenceSidebarEventType.RefreshData);
        if (callback) callback();
      }
    };

    if (isRefreshData(workflowHeader)) {
      handleRefreshData();
    } else if (callback) {
      callback();
    }
  };
  return { refreshPage };
};
