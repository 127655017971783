import { REGISTER_LOOKUP_API_URL } from "@app/products/direct-debit/system-admin/module-item-types/[id]/components/general/components/form-element/constant";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CancelToken } from "axios";
import { DTO_Register } from "./model";

export const getSearchRegisterID = async (
  searchString: string,
  cancelToken: CancelToken
): Promise<APIResponse<DTO_Register[]>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_Register[]>(
      REGISTER_LOOKUP_API_URL,
      {
        params: {
          searchString,
        },
        cancelToken,
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
