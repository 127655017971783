import { IReference } from "@app/products/property/fire-prevention/components/dialogs/reference/model";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
export interface IReferenceDialogProps {
  onCloseDialog: () => void;
  onConfirmReference: (dataItem: IReference) => void;
  noticeGridSelectData: IReference[] | undefined;
}
export const ReferenceDialog = ({
  onCloseDialog,
  onConfirmReference,
  noticeGridSelectData,
}: IReferenceDialogProps) => {
  const handleSubmit = (dataItem: any) => {
    onConfirmReference(dataItem as IReference);
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={noticeGridSelectData && noticeGridSelectData[0]}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <CCDialog
            maxWidth={"25%"}
            height={"auto"}
            titleHeader={"Notice Reference"}
            onClose={onCloseDialog}
            bodyElement={
              <div className="cc-form">
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Notice ID</label>
                      <Field name="NoticeId" disabled component={CCInput} />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Reference
                        <CCTooltip type="validator" position="right" />
                      </label>
                      <Field
                        name="Reference"
                        rows={4}
                        component={CCTextArea}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                </section>
              </div>
            }
            footerElement={
              <React.Fragment>
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onCloseDialog}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    type={"submit"}
                    className="cc-dialog-button"
                    disabled={!formRenderProps.valid}
                  >
                    OK
                  </Button>
                </div>
              </React.Fragment>
            }
          />
        </FormElement>
      )}
    />
  );
};
