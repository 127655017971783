import { CCResult } from "@components/cc-result/_index";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";
export interface ICCNotFoundPageProps {
  status?: "info" | "error" | "success" | "warning";
  title?: string;
  subTitle?: string;
}
export const CCNotFoundPage = ({
  status = "warning",
  title = "Sorry, you cannot view this page",
  subTitle = "No permission to access this page. To access, please contact with the administrator",
}: ICCNotFoundPageProps) => {
  const history = useHistory();
  return (
    <CCResult
      status={status}
      title={title}
      subTitle={subTitle}
      className="cc-not-found-page"
      extra={
        <Button themeColor="primary" onClick={() => history.push("/")}>
          Go to home page
        </Button>
      }
    />
  );
};
