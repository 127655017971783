import { AnimalsMenu } from "@app/products/animals/model";
import { ICCRoute } from "@common/constants/ICCRoute";

export const registerRoute: ICCRoute = {
  path: "register",
  name: "Animals",
  enum: AnimalsMenu.Animals,
  children: [
    {
      path: "active",
      name: "Active",
      enum: AnimalsMenu.AnimalsActive,
      component: require("./active/_index").default,
    },
    {
      path: "all",
      name: "Register",
      enum: AnimalsMenu.AnimalsRegister,
      component: require("./register/_index").default,
    },
    {
      path: "dogs-by-breed",
      name: "Dogs By Breed",
      enum: AnimalsMenu.AnimalsByTypeDogs,
      component: require("./dogs-by-breed/_index").default,
    },
    {
      path: "cats-by-breed",
      name: "Cats By Breed",
      enum: AnimalsMenu.AnimalsByTypeCats,
      component: require("./cats-by-breed/_index").default,
    },
    {
      path: "other-by-breed",
      name: "Other By Breed",
      enum: AnimalsMenu.AnimalsByTypeOther,
      component: require("./other-by-breed/_index").default,
    },
    {
      path: "animals-by-type",
      name: "Animals By Type",
      enum: AnimalsMenu.AnimalsByType,
      component: require("./animals-by-type/_index").default,
    },
    {
      path: "deceased",
      name: "Deceased",
      enum: AnimalsMenu.AnimalsDeceased,
      component: require("./deceased/_index").default,
    },
    {
      path: "inactive",
      name: "Inactive",
      enum: AnimalsMenu.AnimalsInactive,
      component: require("./inactive/_index").default,
    },
    {
      path: "lost-tags",
      name: "Lost Tags",
      enum: AnimalsMenu.AnimalsLostTag,
      component: require("./lost-tags/_index").default,
    },
    {
      path: "life-time",
      name: "Life Time",
      enum: AnimalsMenu.AnimalsLifeTime,
      component: require("./life-time/_index").default,
    },

    {
      path: "quick-register",
      name: "Quick Register",
      enum: AnimalsMenu.AnimalsQuickRegister,
      component: require("./quick-register/_index").default,
    },
    {
      path: "dog-register",
      name: "Dog Register",
      enum: AnimalsMenu.DogRegister,
      component: require("./dog-register/_index").default,
    },
    {
      path: "cat-register",
      name: "Cat Register",
      enum: AnimalsMenu.CatRegister,
      component: require("./cat-register/_index").default,
    },
    {
      path: "cat-breeders",
      name: "Cat Breeders",
      enum: AnimalsMenu.BreedingCatRegister,
      component: require("./cat-breeders/_index").default,
    },
    {
      path: "dangerous-dogs",
      name: "Dangerous Dogs",
      enum: AnimalsMenu.DangerousDogs,
      component: require("./dangerous-dogs/_index").default,
    },
    {
      path: "dog-exemption",
      name: "Dog Exemption",
      enum: AnimalsMenu.DogExemption,
      component: require("./dog-exemption/_index").default,
    },
  ],
};
