import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { GlobalSettingDropdown } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-dropdown/_index";
import { GlobalSettingSwitch } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/application-setting-switch/_index";
import { GlobalSettingNumeric } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/components/numeric-input/_index";
import {
  renderGeneral2SwitchListEnums,
  renderGeneral3SwitchListEnums,
  renderGeneralSwitchListEnums,
} from "@common/pages/settings/system-admin/global-settings/_id/main/settings/general/forms/general-general-settings/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { NumericTextboxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/numeric-textbox/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { configData, getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <InputGlobalSettings
          data={
            configData[ECorporateSettingsField.CorporateSettings_SiteBaseURL]
          }
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_CommunityCentral_SiteBaseURL
            ]
          }
          isEditing={isEditing}
        />
        <InputGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_ApplicationBasePath
            ]
          }
          isEditing={isEditing}
        />
        <GlobalSettingNumeric
          enumParam={
            ECorporateSettingsField.MAILMERGE_MAXIMUM_RECORD_COUNT_FOR_COLLATE
          }
        />
        <GlobalSettingNumeric
          enumParam={ECorporateSettingsField.CorporateSettings_FlatGridPageSize}
        />
        <GlobalSettingNumeric
          enumParam={
            ECorporateSettingsField.CorporateSettings_GroupedGridPageSize
          }
        />
        <GlobalSettingNumeric
          enumParam={
            ECorporateSettingsField.CorporateSettings_CategoriesGridPageSize
          }
        />
        {renderGeneralSwitchListEnums.map(
          (enumParam: ECorporateSettingsField) => {
            return (
              <GlobalSettingSwitch
                enumParam={enumParam}
                formRenderProps={formRenderProps}
              />
            );
          }
        )}
        <GlobalSettingNumeric
          enumParam={
            ECorporateSettingsField.Global_AttachmentSizeRestrictionSize
          }
        />

        <NumericTextboxGlobalSettings
          data={getConfigDataField(
            `${ECorporateSettingsField.CorporateSettings_FileNameLength}`
          )}
          isEditing={isEditing}
          min={1}
          max={150}
        />

        <GlobalSettingSwitch
          enumParam={
            ECorporateSettingsField.Global_EnableFileAttachmentMultiple
          }
          formRenderProps={formRenderProps}
        />
      </div>
      {configData[
        ECorporateSettingsField.CorporateSettings_FileExtension.toString()
      ] && (
        <div className="cc-form-cols-1">
          <InputGlobalSettings
            data={
              configData[
                ECorporateSettingsField.CorporateSettings_FileExtension
              ]
            }
            isEditing={isEditing}
          />
        </div>
      )}
      <div className="cc-form-cols-3">
        {renderGeneral2SwitchListEnums.map(
          (enumParam: ECorporateSettingsField) => {
            return (
              <GlobalSettingSwitch
                enumParam={enumParam}
                formRenderProps={formRenderProps}
              />
            );
          }
        )}
        <GlobalSettingDropdown
          enumParam={ECorporateSettingsField.CorporateSettings_ComplaintsMenu}
          isManualRequired
        />
        {renderGeneral3SwitchListEnums.map(
          (enumParam: ECorporateSettingsField) => {
            return (
              <GlobalSettingSwitch
                enumParam={enumParam}
                formRenderProps={formRenderProps}
              />
            );
          }
        )}
        <InputGlobalSettings
          data={
            configData[ECorporateSettingsField.CorporateSettings_WebAPIBaseURL]
          }
          isEditing={isEditing}
        />
        <GlobalSettingSwitch
          enumParam={ECorporateSettingsField.CorporateSettings_EnableWorkflow}
          formRenderProps={formRenderProps}
        />
      </div>
    </section>
  );
});
