import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { Officers } from "@app/core/inspections/[id]/model";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { nameOfFactory } from "@common/utils/common";

const nameOfOfficer = nameOfFactory<Officers>();
export const optionOfficer: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Officer",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfOfficer("Name"),
    colShowComboboxSearch: [{ field: nameOfOfficer("Name"), title: "Name" }],
  },
  grid: {
    columnFields: [
      { field: nameOfOfficer("Name"), title: "Name" },
      {
        field: nameOfOfficer("Position"),
        title: "Position",
      },
      { field: nameOfOfficer("Email"), title: "Email" },
      { field: nameOfOfficer("WorkPhone"), title: "Phone" },
    ],
    primaryField: nameOfOfficer("ID"),
    dataUrl: CORE_OFFICERS_ODATA,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfOfficer("Name"),
          dir: "asc",
        },
      ],
    },
  },
};
