import { kennelsRoute } from "@app/products/animals/kennels/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { eAnimalsRoute } from "./e-animals/route";
import { financeRoute } from "./finance/route";
import { inspectionPlannerRoute } from "./inspection-planner/route";
import { inspectionsRoute } from "./inspections/route";
import { kennelsRenewalsRoute } from "./kennels-renewals/route";
import { noticesRoute } from "./notices/route";
import { poundRegisterRoute } from "./pound-register/route";
import { registerRoute } from "./register/route";
import { renewalsRoute } from "./renewals/route";
import { systemAdminRoute } from "./system-admin/route";
import { viewCrmsEventRoute } from "./view-crms-event/route";

export const animalRoute: ICCRoute = {
  path: "animals",
  name: "Animals",
  enum: eProductType.Animals,
  children: [
    registerRoute,
    renewalsRoute,
    inspectionPlannerRoute,
    inspectionsRoute,
    viewCrmsEventRoute,
    noticesRoute,
    financeRoute,
    eAnimalsRoute,
    poundRegisterRoute,
    kennelsRenewalsRoute,
    systemAdminRoute,
    kennelsRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
