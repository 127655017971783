import { getUUID } from "@common/utils/common";
import {
  IAppNotificationItem,
  IAppNotificationItemAddProps,
  NotificationType,
} from "@components/cc-app-notification/components/notification-item/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class CCAppNotificationStore {
  private _notifications: IAppNotificationItem[] = [];
  private _errorNotificationsIds: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get notifications() {
    return toJS(this._notifications);
  }

  setNotifications = (notifications: IAppNotificationItem[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  get errorNotificationsIds() {
    return toJS(this._errorNotificationsIds);
  }

  setErrorNotificationsIds = (errorNotificationsIds: string[]) => {
    runInAction(() => {
      this._errorNotificationsIds = errorNotificationsIds;
    });
  };

  //Custom Getter
  get isEmptyNotification() {
    return this._notifications.length === 0;
  }
  get totalNotifications() {
    return this._notifications.length;
  }
  //Action
  clearNotifications = (type?: NotificationType, isKeepLock?: boolean) => {
    runInAction(() => {
      if (!type && !isKeepLock) {
        this._notifications = [];
        return;
      }
      const newNotifications = this._notifications.filter((notification) => {
        return (
          (type ? notification.type !== type : true) &&
          (isKeepLock ? notification.lock : true)
        );
      });
      this._notifications = newNotifications;
    });
  };
  pushNotification = (notification: IAppNotificationItemAddProps): string => {
    return runInAction(() => {
      let newNotifications = [...this._notifications];
      const newNotificationId = getUUID();

      newNotifications.unshift({
        ...notification,
        id: newNotificationId,
      });

      this._notifications = newNotifications;
      if (notification.type === "error") {
        this.addErrorNotification(newNotificationId);
      }
      return newNotificationId;
    });
  };

  addErrorNotification = (id: string) => {
    let newId = [...this.errorNotificationsIds];
    newId.push(id);
    this.setErrorNotificationsIds(newId);
  };

  clearErrorNotification = () => {
    this.setNotifications(
      this.notifications.filter(
        (notification) => !this.errorNotificationsIds.includes(notification.id)
      )
    );
    this.setErrorNotificationsIds([]);
  };

  updateNotification = (notification: IAppNotificationItem) => {
    runInAction(() => {
      this._notifications = this._notifications.map((item) => {
        if (item.type === "error") {
          this.addErrorNotification(item.id);
        }
        return item.id === notification.id
          ? { ...item, ...notification }
          : item;
      });
    });
  };

  removeNotification = (
    notificationId: string,
    isCallOnClose: boolean | undefined = true
  ) => {
    runInAction(() => {
      const notificationRemove = this._notifications.find(
        (notification) => notification.id === notificationId
      );
      // Call native onClose function of notification
      if (isCallOnClose && notificationRemove && notificationRemove.onClose)
        notificationRemove.onClose(notificationRemove);

      //Remove on list
      this._notifications = this._notifications.filter(
        (notification) => notification.id !== notificationId
      );
    });
  };
}

export const appNotificationStore = new CCAppNotificationStore();
const storeContext = createContext(appNotificationStore);

export const useCCAppNotificationStore = () => {
  return useContext(storeContext);
};
