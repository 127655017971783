import { ICCRoute } from "@common/constants/ICCRoute";

export const amendmentsRoute: ICCRoute = {
  path: "amendments",
  name: "Amendments",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
  ],
};
