import {
  mockReadingOfficer,
  mockReadingStatus,
} from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/mock";
import {
  IManualMeterReading,
  MAX_READING,
} from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/manual-reading/model";
import { ConfirmReadingDialog } from "@app/products/property/meters/components/dialogs/confirm-reading/_index";
import { handleMeterReadingValidateForm } from "@app/products/property/meters/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { differenceInCalendarDays, subDays } from "date-fns";
import React, { useState } from "react";

const nameOf = nameOfFactory<IManualMeterReading>();
interface IFormManualReading {
  onClose: () => void;
  onSubmit: (data: any) => void;
  meterInfoExtend: IManualMeterReading | undefined;
  isLoading: boolean;
  title: string;
}
export const FormManualReading = ({
  onClose,
  onSubmit,
  meterInfoExtend,
  isLoading,
  title,
}: IFormManualReading) => {
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const handleOnSubmitClick = (values: any) => {
    if (
      values?.CurrentReading <= values?.PreviousReading ||
      values?.CurrentReading > MAX_READING
    ) {
      setShowWarningDialog(true);
    } else {
      setShowWarningDialog(true);
      onSubmit({});
    }
  };
  return (
    <Form
      onSubmit={(value: any) => {
        handleOnSubmitClick(value);
      }}
      initialValues={meterInfoExtend}
      key={JSON.stringify(meterInfoExtend)}
      validator={handleMeterReadingValidateForm}
      render={(formRenderProps: FormRenderProps) => {
        const { onChange, valueGetter } = formRenderProps;
        const handleOnChangeCurrentReading = (
          event: NumericTextBoxChangeEvent
        ) => {
          onChange("CurrentReading", {
            value: event.target.value,
          });

          const previousReading = valueGetter("PreviousReading");
          const currentReading = valueGetter("CurrentReading");

          if (previousReading && typeof currentReading === "number") {
            onChange("Usage", {
              value:
                currentReading >= previousReading
                  ? currentReading - previousReading
                  : MAX_READING - previousReading + currentReading,
            });
          }
        };
        const handleOnChangeCurrentReadingDate = (
          event: DateTimePickerChangeEvent
        ) => {
          onChange("CurrentReadingDate", {
            value: event.target.value,
          });
          if (
            valueGetter("PreviousReadingDate") &&
            valueGetter("CurrentReadingDate")
          ) {
            onChange("NumberOfDays", {
              value: Math.max(
                differenceInCalendarDays(
                  valueGetter("CurrentReadingDate"),
                  valueGetter("PreviousReadingDate")
                ),
                0
              ),
            });
          }
        };

        const onConfirmYes = () => {
          setShowWarningDialog(false);
          onSubmit({});
        };
        return (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="50%"
              titleHeader={title}
              onClose={onClose}
              bodyElement={
                <React.Fragment>
                  {isLoading ? (
                    <Loading isLoading={isLoading} />
                  ) : (
                    <div className="cc-form">
                      <section className="cc-field-group">
                        <div className="cc-form-cols-2">
                          <div className="cc-field ">
                            <label className="cc-label">Meter number</label>
                            <Field
                              name={nameOf("MeterNumber")}
                              disabled
                              component={CCInput}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Meter ID</label>
                            <Field
                              name={nameOf("Id")}
                              disabled
                              component={CCInput}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">Property address</label>
                            <Field
                              name={nameOf("PropertyAddress")}
                              component={CCInput}
                              placeholder={"Property address"}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Account name</label>
                            <Field
                              name={nameOf("AccountName")}
                              placeholder={"Account name"}
                              component={CCInput}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2 ">
                          <div className="cc-field ">
                            <label className="cc-label">Reading status</label>
                            <Field
                              name={nameOf("ReadingStatus")}
                              data={mockReadingStatus ?? []}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Reading officer</label>
                            <Field
                              name={nameOf("ReadingOfficer")}
                              data={mockReadingOfficer ?? []}
                              textField="Value"
                              dataItemKey="Key"
                              component={CCSearchComboBox}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2 ">
                          <div className="cc-field ">
                            <label className="cc-label">
                              Previous reading date
                            </label>
                            <Field
                              name={nameOf("PreviousReadingDate")}
                              format={DATETIME_FORMAT.DATETIME_CONTROL}
                              component={CCDateTimePicker}
                              disabled
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Previous reading</label>
                            <Field
                              name={nameOf("PreviousReading")}
                              disabled
                              component={CCNumericTextBox}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2 ">
                          <div className="cc-field ">
                            <label className="cc-label">
                              Current reading date
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("CurrentReadingDate")}
                              format={DATETIME_FORMAT.DATETIME_CONTROL}
                              min={
                                valueGetter("PreviousReadingDate")
                                  ? subDays(
                                      valueGetter("PreviousReadingDate"),
                                      -1
                                    )
                                  : undefined
                              }
                              onChange={handleOnChangeCurrentReadingDate}
                              component={CCDateTimePicker}
                              max={new Date()}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">
                              Current reading
                              <CCTooltip type="validator" position="right" />
                            </label>
                            <Field
                              name={nameOf("CurrentReading")}
                              component={CCNumericTextBox}
                              onChange={(event: NumericTextBoxChangeEvent) =>
                                handleOnChangeCurrentReading(event)
                              }
                              min={0}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2 ">
                          <div className="cc-field ">
                            <label className="cc-label">Number of days</label>

                            <Field
                              name={nameOf("NumberOfDays")}
                              component={CCInput}
                              disabled
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Usage</label>
                            <div className="cc-custom-input-group">
                              <Field
                                name={nameOf("Usage")}
                                component={CCInput}
                                disabled
                              />
                              <div className="cc-input-group-postfix">
                                Kilolitre
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">Comment</label>
                            <Field
                              name={nameOf("Comment")}
                              component={CCTextArea}
                              rows={3}
                              placeholder="Comment"
                            />
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                </React.Fragment>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={formRenderProps.onSubmit}
                    disabled={!formRenderProps.valid}
                  >
                    Enter & Exit
                  </Button>
                </div>
              }
            />
            {showWarningDialog && (
              <ConfirmReadingDialog
                onClosePopup={() => setShowWarningDialog(false)}
                onConfirmYes={onConfirmYes}
              />
            )}
          </FormElement>
        );
      }}
    />
  );
};
