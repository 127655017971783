import { DTO_Session_Journals } from "@app/products/property/journals/session-list/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getJournalSessionListDetailById = async (
  sessionId: number | string
): Promise<APIResponse<DTO_Session_Journals | undefined>> => {
  try {
    const response = await CoreAPIService.getClient().get<DTO_Session_Journals>(
      `/api/property/int/session/${sessionId}/journalsummaryList`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
