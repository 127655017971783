import {
  AnimalType,
  RegistrationFeeAdjustment,
  RegistrationFeeRule,
  SVC_FeeCalculator_RegistrationFee_AdjustmentType,
} from "@app/products/animals/model";
import {
  endDayValidator,
  startDayValidator,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/dialogs/registration-fee-rules-dialog/util";
import { RegistrationFeeRulesType } from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import {
  checkRegistrationAdjustmentAllowPercent,
  checkShowAdjustmentLabel,
  generateAdjustmentDescription,
  generateRegistrationFeeRuleDescription,
  getNameOfAdjustmentType,
} from "@app/products/animals/system-admin/animal-types/[id]/util";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCPercentInput } from "@components/cc-percent-input/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IRegistrationFeeRulesDialogProps {
  onClose: () => void;
  initialValues: RegistrationFeeRule;
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();
const nameOfKeyValuePacket = nameOfFactory<KeyValuePacket>();
const nameOfRegistrationFeeRule = nameOfFactory<RegistrationFeeRule>();
const nameOfAdjustmentType = nameOfFactory<RegistrationFeeAdjustment>();

export const RegistrationFeeRulesDialog = observer(
  ({
    onClose,
    initialValues,
    animalTypeFormObject,
    formRenderProps,
  }: IRegistrationFeeRulesDialogProps) => {
    const {
      onChange: onChangeAnimalTypeForm,
      valueGetter: valueGetterAnimalTypeForm,
    } = formRenderProps;
    const {
      feeRuleDialogLOVs,
      setFeeRuleTypeEnum,
      responseLoadErrorDialog,
      setupRegistrationFeeRulesDialog,
      isLoadingDialog,
      feeRuleObj,
    } = useAnimalTypeStore();
    const { settings } = useCommonCoreStore();
    const isEnableProRata = getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnableProRata]
    );

    const [isFirstYearOnly, setIsFirstYearOnly] = useState(false);

    // Handle logic to change note of the First Year Only field
    const firstYearOnlyNote = useMemo(() => {
      let note = "";
      if (isFirstYearOnly && !isEnableProRata) {
        note = "*Fee Rule is used for New Registrations only";
      } else if (isFirstYearOnly && isEnableProRata) {
        note = "*Fee Rule is used for New Registrations Pro Rata Calculation";
      } else if (!isFirstYearOnly) {
        note = "*Fee Rule is used for Renewals and Registrations";
      }
      return note;
    }, [isEnableProRata, isFirstYearOnly]);

    // Get name of adjustment
    const adjustmentType1Name = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment1"),
      nameOfAdjustmentType("AdjustmentType_ENUM")
    );

    const adjustmentType2Name = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment2"),
      nameOfAdjustmentType("AdjustmentType_ENUM")
    );

    const adjustment1DateAfterName = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment1"),
      nameOfAdjustmentType("Adjustment_Date")
    );

    const adjustment2DateAfterName = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment2"),
      nameOfAdjustmentType("Adjustment_Date")
    );

    const adjustment1AmountName = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment1"),
      nameOfAdjustmentType("Adjustment_Amount")
    );

    const adjustment2AmountName = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment2"),
      nameOfAdjustmentType("Adjustment_Amount")
    );

    const adjustment1CaptionName = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment1"),
      nameOfAdjustmentType("Adjustment_Label")
    );
    const adjustment2CaptionName = getNameOfAdjustmentType(
      nameOfRegistrationFeeRule("Adjustment2"),
      nameOfAdjustmentType("Adjustment_Label")
    );

    // Handle for submit dialog.
    const handleOnSubmit = (event: FormSubmitClickEvent) => {
      const feeRuleList = valueGetterAnimalTypeForm(
        nameOfAnimalType("FeeRulesList")
      );
      const newFeeRule = event.values as RegistrationFeeRule;

      // Generate description
      newFeeRule.Adjustment1 = {
        ...newFeeRule.Adjustment1,
        Description: generateAdjustmentDescription(newFeeRule.Adjustment1),
      };
      newFeeRule.Adjustment2 = {
        ...newFeeRule.Adjustment2,
        Description: generateAdjustmentDescription(newFeeRule.Adjustment2),
      };

      const newRegistrationFeeRules =
        animalTypeFormObject.FeeRulesList?.RegistrationFeeRules.filter(
          (feeRule: RegistrationFeeRule) =>
            feeRule.RuleType_ENUM !== newFeeRule.RuleType_ENUM
        ) ?? [];

      newRegistrationFeeRules.push({
        ...newFeeRule,
        Description: generateRegistrationFeeRuleDescription(newFeeRule),
      } as RegistrationFeeRule);

      onChangeAnimalTypeForm(nameOfAnimalType("FeeRulesList"), {
        value: {
          ...feeRuleList,
          RegistrationFeeRules: newRegistrationFeeRules,
        },
      });

      setFeeRuleTypeEnum(RegistrationFeeRulesType.Default);
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmitClick={handleOnSubmit}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, valid, onSubmit, onChange } = formRenderProps;

          const feeTypeValue = valueGetter(
            nameOfRegistrationFeeRule("FeeType_Name")
          );

          const isEnableRVP = valueGetter(
            nameOfRegistrationFeeRule("EnableRVP")
          );

          const adjustmentType1Enum = valueGetter(adjustmentType1Name);
          const adjustmentType2Enum = valueGetter(adjustmentType2Name);

          const isShowAdjustment1DateAfter =
            adjustmentType1Enum ===
            SVC_FeeCalculator_RegistrationFee_AdjustmentType.DateAfter;

          const isShowAdjustment2DateAfter =
            adjustmentType2Enum ===
            SVC_FeeCalculator_RegistrationFee_AdjustmentType.DateAfter;

          const isShowAdjustment1Label =
            checkShowAdjustmentLabel(adjustmentType1Enum);

          const isAdjustment1Percent =
            checkRegistrationAdjustmentAllowPercent(adjustmentType1Enum);

          const isShowAdjustment2Label =
            checkShowAdjustmentLabel(adjustmentType2Enum);

          const isAdjustment2Percent =
            checkRegistrationAdjustmentAllowPercent(adjustmentType2Enum);

          // Handle on field change
          const handleOnChangeCombobox = (
            event: ComboBoxChangeEvent,
            fieldName: keyof RegistrationFeeRule
          ) => {
            onChange(fieldName, {
              value: event.value?.Key ?? undefined,
            });
          };

          const handleOnChangeFirstYearOnly = (event: SwitchChangeEvent) => {
            setIsFirstYearOnly(event.value);
            onChange(nameOfRegistrationFeeRule("IsOnlyFirstYear"), {
              value: event.value,
            });
          };

          const handleOnChangeFeeType = (value: any) => {
            onChange(nameOfRegistrationFeeRule("FeeType_ID"), {
              value: value.ID,
            });
            onChange(nameOfRegistrationFeeRule("FeeType_Name"), {
              value: value.Name,
            });
          };

          const handleOnChangeAdjustmentType1 = (
            event: ComboBoxChangeEvent
          ) => {
            onChange(adjustmentType1Name, {
              value:
                event.value?.Key ??
                SVC_FeeCalculator_RegistrationFee_AdjustmentType.Default,
            });
            onChange(adjustment1DateAfterName, { value: undefined });
            onChange(adjustment1AmountName, { value: 0 });
            onChange(adjustment1CaptionName, { value: "" });
          };

          const handleOnChangeAdjustmentType2 = (
            event: ComboBoxChangeEvent
          ) => {
            onChange(adjustmentType2Name, {
              value:
                event.value?.Key ??
                SVC_FeeCalculator_RegistrationFee_AdjustmentType.Default,
            });
            onChange(adjustment2DateAfterName, { value: undefined });
            onChange(adjustment2AmountName, { value: 0 });
            onChange(adjustment2CaptionName, { value: "" });
          };

          return (
            <CCDialog
              maxWidth="40%"
              height={"auto"}
              titleHeader="Fee Rule"
              onClose={onClose}
              isLoading={isLoadingDialog}
              bodyElement={
                <>
                  {responseLoadErrorDialog ? (
                    <CCLoadFailed
                      responseError={responseLoadErrorDialog}
                      onReload={() =>
                        setupRegistrationFeeRulesDialog(
                          RegistrationFeeRulesType.RegistrationFeeRules,
                          feeRuleObj,
                          true
                        )
                      }
                    />
                  ) : (
                    <FormElement className="cc-form">
                      <div className="cc-field-group">
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <CCLabel title="Rule type" isMandatory />
                            <Field
                              name={nameOfRegistrationFeeRule("RuleType_ENUM")}
                              component={CCComboBox}
                              textField={nameOfKeyValuePacket("Value")}
                              dataItemKey={nameOfKeyValuePacket("Key")}
                              data={feeRuleDialogLOVs?.RuleType}
                              onChange={(event: ComboBoxChangeEvent) => {
                                handleOnChangeCombobox(
                                  event,
                                  nameOfRegistrationFeeRule("RuleType_ENUM")
                                );
                              }}
                              value={getDropdownValue(
                                valueGetter(
                                  nameOfRegistrationFeeRule("RuleType_ENUM")
                                ),
                                feeRuleDialogLOVs?.RuleType ?? [],
                                nameOfKeyValuePacket("Key")
                              )}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Base amount" isMandatory />
                            <Field
                              name={nameOfRegistrationFeeRule("BaseAmount")}
                              component={CCCurrencyInput}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Sort order" isMandatory />
                            <Field
                              name={nameOfRegistrationFeeRule("SortOrder")}
                              component={CCNumericTextBox}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Priority rule?" isMandatory />
                            <Field
                              name={nameOfRegistrationFeeRule("IsPriority")}
                              checked={valueGetter(
                                nameOfRegistrationFeeRule("IsPriority")
                              )}
                              component={CCSwitch}
                              validator={requiredValidator}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="First year only?" isMandatory />
                            <Field
                              name={nameOfRegistrationFeeRule(
                                "IsOnlyFirstYear"
                              )}
                              checked={valueGetter(
                                nameOfRegistrationFeeRule("IsOnlyFirstYear")
                              )}
                              component={CCSwitch}
                              onChange={handleOnChangeFirstYearOnly}
                              validator={requiredValidator}
                            />
                            <CCValueField
                              value={firstYearOnlyNote}
                              className={"cc-text-info cc-padding-text-info"}
                            />
                          </div>
                          <div className="cc-field">
                            <CCLabel title="Fee type" isMandatory />
                            <Field
                              name={nameOfRegistrationFeeRule("FeeType_Name")}
                              component={FeeTypesPicker}
                              isGetDetail={false}
                              selectableMode="single"
                              validator={requiredValidator}
                              onPickFeeTypes={handleOnChangeFeeType}
                              value={feeTypeValue}
                              productType={PRODUCT_TYPE_NUMBER.Animals}
                            />
                          </div>
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <CCLabel
                              title="Enable rule valid period?"
                              isMandatory
                            />
                            <Field
                              name={nameOfRegistrationFeeRule("EnableRVP")}
                              checked={valueGetter(
                                nameOfRegistrationFeeRule("EnableRVP")
                              )}
                              component={CCSwitch}
                              validator={requiredValidator}
                            />
                          </div>
                        </div>
                        {isEnableRVP && (
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <CCLabel title="Start day" isMandatory />
                              <Field
                                name={nameOfRegistrationFeeRule("RVPStartDay")}
                                component={CCNumericTextBox}
                                max={99}
                                validator={startDayValidator}
                              />
                            </div>
                            <div className="cc-field">
                              <CCLabel title="Start month" isMandatory />
                              <Field
                                name={nameOfRegistrationFeeRule("RVPStartMon")}
                                component={CCComboBox}
                                textField={nameOfKeyValuePacket("Value")}
                                dataItemKey={nameOfKeyValuePacket("Key")}
                                data={feeRuleDialogLOVs?.RVPStartMonth}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  handleOnChangeCombobox(
                                    event,
                                    nameOfRegistrationFeeRule("RVPStartMon")
                                  );
                                }}
                                value={getDropdownValue(
                                  valueGetter(
                                    nameOfRegistrationFeeRule("RVPStartMon")
                                  ),
                                  feeRuleDialogLOVs?.RVPStartMonth ?? [],
                                  nameOfKeyValuePacket("Key")
                                )}
                                validator={requiredValidator}
                              />
                            </div>
                            <div className="cc-field">
                              <CCLabel title="End day" isMandatory />
                              <Field
                                name={nameOfRegistrationFeeRule("RVPEndDay")}
                                component={CCNumericTextBox}
                                max={99}
                                validator={endDayValidator}
                              />
                            </div>
                            <div className="cc-field">
                              <CCLabel title="End month" isMandatory />
                              <Field
                                name={nameOfRegistrationFeeRule("RVPEndMon")}
                                component={CCComboBox}
                                textField={nameOfKeyValuePacket("Value")}
                                dataItemKey={nameOfKeyValuePacket("Key")}
                                data={feeRuleDialogLOVs?.RVPEndMonth}
                                onChange={(event: ComboBoxChangeEvent) => {
                                  handleOnChangeCombobox(
                                    event,
                                    nameOfRegistrationFeeRule("RVPEndMon")
                                  );
                                }}
                                value={getDropdownValue(
                                  valueGetter(
                                    nameOfRegistrationFeeRule("RVPEndMon")
                                  ),
                                  feeRuleDialogLOVs?.RVPEndMonth ?? [],
                                  nameOfKeyValuePacket("Key")
                                )}
                                validator={requiredValidator}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <hr className="cc-divider" />
                      <div className="cc-field-group">
                        <div className="cc-form-cols-2">
                          <CCLabel title="Adjustment 1" />
                        </div>
                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <CCLabel title="Adjustment type" />
                              <Field
                                name={adjustmentType1Name}
                                component={CCComboBox}
                                textField={nameOfKeyValuePacket("Value")}
                                dataItemKey={nameOfKeyValuePacket("Key")}
                                data={feeRuleDialogLOVs?.AdjustmentType1}
                                onChange={handleOnChangeAdjustmentType1}
                                value={getDropdownValue(
                                  valueGetter(adjustmentType1Name),
                                  feeRuleDialogLOVs?.AdjustmentType1 ?? [],
                                  nameOfKeyValuePacket("Key")
                                )}
                              />
                            </div>
                            {isShowAdjustment1DateAfter && (
                              <div className="cc-field">
                                <CCLabel title="Date after" isMandatory />
                                <Field
                                  name={adjustment1DateAfterName}
                                  component={CCDatePicker}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  validator={requiredValidator}
                                />
                              </div>
                            )}
                            {isShowAdjustment1Label && (
                              <div className="cc-field">
                                <CCLabel title="Caption" />
                                <Field
                                  name={adjustment1CaptionName}
                                  component={CCInput}
                                />
                              </div>
                            )}
                            {!!adjustmentType1Enum && (
                              <div className="cc-field">
                                <CCLabel title="Amount" isMandatory />
                                <Field
                                  name={adjustment1AmountName}
                                  component={
                                    isAdjustment1Percent
                                      ? CCPercentInput
                                      : CCCurrencyInput
                                  }
                                  validator={requiredValidator}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className="cc-divider" />
                      <div className="cc-field-group">
                        <div className="cc-form-cols-2">
                          <CCLabel title="Adjustment 2" />
                        </div>
                        <div className="cc-custom-sub-panel-bar">
                          <div className="cc-form-cols-2">
                            <div className="cc-field">
                              <CCLabel title="Adjustment type" />
                              <Field
                                name={adjustmentType2Name}
                                component={CCComboBox}
                                textField={nameOfKeyValuePacket("Value")}
                                dataItemKey={nameOfKeyValuePacket("Key")}
                                data={feeRuleDialogLOVs?.AdjustmentType2}
                                onChange={handleOnChangeAdjustmentType2}
                                value={getDropdownValue(
                                  valueGetter(adjustmentType2Name),
                                  feeRuleDialogLOVs?.AdjustmentType2 ?? [],
                                  nameOfKeyValuePacket("Key")
                                )}
                              />
                            </div>
                            {isShowAdjustment2DateAfter && (
                              <div className="cc-field">
                                <CCLabel title="Date after" isMandatory />
                                <Field
                                  name={adjustment2DateAfterName}
                                  component={CCDatePicker}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  validator={requiredValidator}
                                />
                              </div>
                            )}
                            {isShowAdjustment2Label && (
                              <div className="cc-field">
                                <CCLabel title="Caption" />
                                <Field
                                  name={adjustment2CaptionName}
                                  component={CCInput}
                                />
                              </div>
                            )}
                            {!!adjustmentType2Enum && (
                              <div className="cc-field">
                                <CCLabel title="Amount" isMandatory />
                                <Field
                                  name={adjustment2AmountName}
                                  component={
                                    isAdjustment2Percent
                                      ? CCPercentInput
                                      : CCCurrencyInput
                                  }
                                  validator={requiredValidator}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  )}
                </>
              }
              footerElement={
                <div className="cc-dialog-footer-actions-right">
                  <Button className="cc-dialog-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    themeColor="primary"
                    disabled={
                      !valid || !!responseLoadErrorDialog || isLoadingDialog
                    }
                    onClick={onSubmit}
                    className="cc-dialog-button"
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
