import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { RosterForm } from "@app/products/crms/system-admin/rosters/[id]/components/forms/components/child-screens/general/_index";
import { useRosterStore } from "@app/products/crms/system-admin/rosters/[id]/store";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewRosterComponent = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, responseLoadError, loadRoster, onSubmit, roster } =
    useRosterStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Roster"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadRoster(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New Roster" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {roster && <RosterForm />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

const renderProps: IPermissionsWrapperProps = {
  component: NewRosterComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.CustomerService },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.CUSTOMERSERVICE_Form_Roster,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewRoster = renderWithPermissionsWrapper(renderProps);
