import { useBuildingRegistersSitingApprovalsStore } from "@app/products/building/registers/siting-approvals/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedBuildingRegisterSitingApprovals } from "./components/forms/existed/_index";
import { NewBuildingRegisterSitingApprovals } from "./components/forms/new/_index";

const ManageBuildingRegisterSitingApprovals = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { resetStore, loadBuildingRegistersSitingApprovals } =
    useBuildingRegistersSitingApprovalsStore();

  const isNew = useIsNew();

  useEffect(() => {
    if (isNew) return;
    loadBuildingRegistersSitingApprovals(+id);
  }, [id, isNew, loadBuildingRegistersSitingApprovals]);

  useEffectOnce(() => {
    return () => resetStore();
  });

  if (isNew) {
    return <NewBuildingRegisterSitingApprovals />;
  }
  return <ExistedBuildingRegisterSitingApprovals />;
});

export default ManageBuildingRegisterSitingApprovals;
