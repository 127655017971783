import {
  getSelectedType,
  handleRetainNames,
  handleUpdateRetainedNames,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/util";
import {
  loadChangeOfOwnerShipAssessment,
  loadRebateEntitlementsAssessment,
  loadRetainNames,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/api";
import {
  colAssessments,
  colTitles,
  requiredAssessmentTypes,
  requiredTitleTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/config";
import {
  DTO_ChangeOfOwnership_Assessment,
  DTO_ChangeOfOwnership_Assessment_Rebates,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/model";
import {
  EPropertyStepGridType,
  propertyFormStepGridValidator,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/util";
import {
  DTO_Assessment,
  DTO_RebateEntitlementOwners,
  DTO_Rebates,
  DTO_Title,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { AddAssessmentLookupDialog } from "@app/products/property/components/dialogs/add-assessment-lookup/_index";
import { fnt_Assessment_LookupResult } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FieldArray } from "@progress/kendo-react-form";
import { subDays } from "date-fns";
import { map } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const nameOfTitle = nameOfFactory<DTO_Title>();
export const PropertyFormStep = (props: IFormStepElement) => {
  const newValidator = useCallback(
    (value: any, valueGetter: (name: string) => any) => {
      if (props?.options?.isReadOnly) return undefined;
      const selectedTypeId = valueGetter(`${EKeysOfSteps.Type}.Type`);
      if (requiredAssessmentTypes.includes(selectedTypeId)) {
        return propertyFormStepGridValidator(
          EPropertyStepGridType.Assessment,
          value,
          props?.options?.assessmentLabel ?? "assessment"
        );
      } else if (requiredTitleTypes.includes(selectedTypeId)) {
        return propertyFormStepGridValidator(
          EPropertyStepGridType.Title,
          value,
          props?.options?.titleLabel ?? "title"
        );
      }
      return "";
    },
    [props?.options]
  );
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={newValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    setStepsVisible,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { changeOfOwnershipLOVs } = useChangeOfOwnershipDialogStore();

    const [confirmDialog, setConfirmDialog] = useState<
      | {
          title: string;
          message: string;
          subMessage: string;
          data: any;
        }
      | undefined
    >(undefined);
    const [showAddAssessmentDialog, setShowAddAssessmentDialog] =
      useState(false);
    const [isLoadingAddAssessment, setIsLoadingAddAssessment] = useState(false);

    const selectedType = getSelectedType(valueGetter);
    const selectedTypeId = selectedType?.Change_of_Ownership_Type ?? 0;
    const selectedTitle = getFieldValue("_option.TitleSelected");
    const currentTitles = getFieldValue("Titles") ?? [];
    const currentAssessments = getFieldValue("Assessments") ?? [];
    const currentRebateEntitlement =
      valueGetter(`${EKeysOfSteps.Rebates}`) ?? {};
    const selectedAssessment = getFieldValue("_option.AssessmentSelected");

    //Get labels
    const [
      assessmentLowercaseLabel,
      assessmentLabel,
      titleLowercaseLabel,
      titlesLabel,
      assessmentNumberLabel,
      assessmentIDLabel,
      titleIDLabel,
    ] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.AssessmentLowercase,
      ECustomColNameProperty.Assessment,
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Titles,
      ECustomColNameProperty.AssessmentNumber,
      ECustomColNameProperty.AssessmentID,
      ECustomColNameProperty.Title_ID,
    ]);

    const newAssessmentCols = useMemo(() => {
      return colAssessments.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOfAssessment("Assessment_Number"):
            return {
              ...column,
              title: assessmentNumberLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentNumber}`,
            };

          case nameOfAssessment("Assessment_Id"):
            return {
              ...column,
              title: assessmentIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentGroup}`,
            };
          default:
            return column;
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const newTitleCols = useMemo(() => {
      return colTitles.map((column: IColumnFields) => {
        switch (column.field) {
          case nameOfTitle("Title_Id"):
            return {
              ...column,
              title: titleIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentNumber}`,
            };
          case nameOfTitle("Assessment_Id"):
            return {
              ...column,
              title: assessmentIDLabel ?? column.title,
              id: `cc-grid-cell-${ECustomColNameProperty.AssessmentNumber}`,
            };
          default:
            return column;
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const assessmentMandatory = useMemo(() => {
      const isMandatory = requiredAssessmentTypes.includes(selectedTypeId);
      const errorMessage = isMandatory ? errors?.[nameOf("")] : undefined;
      return { isMandatory, errorMessage };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, selectedTypeId]);
    const titleMandatory = useMemo(() => {
      const isMandatory = requiredTitleTypes.includes(selectedTypeId);
      const errorMessage = isMandatory ? errors?.[nameOf("")] : undefined;
      return { isMandatory, errorMessage };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, selectedTypeId]);

    const handleAddAssessment = (
      propertyData: DTO_ChangeOfOwnership_Assessment
    ) => {
      if (propertyData?.Assessment) {
        let newAssessments = [...currentAssessments];
        if (
          newAssessments.find(
            (assessment: DTO_Assessment) =>
              assessment?.Assessment_Id ===
              propertyData.Assessment?.Assessment_Id
          )
        ) {
          newAssessments = newAssessments.filter(
            (assessment: DTO_Assessment) =>
              assessment?.Assessment_Id !==
              propertyData.Assessment?.Assessment_Id
          );
          localNotificationRef?.current?.pushNotification({
            title: `${assessmentLabel} ${
              propertyData.Assessment?.Assessment_Id ?? ""
            } - ${
              propertyData.Assessment?.Property_Address ?? ""
            } has been added`,
            type: "success",
          });
        } else {
          newAssessments.unshift(propertyData?.Assessment);
          onChange(nameOf("Assessments"), { value: newAssessments });
          localNotificationRef?.current?.pushNotification({
            title: `Add ${assessmentLowercaseLabel} successfully`,
            type: "success",
          });
        }
      }

      if (propertyData?.Title) {
        const newTitles = propertyData.Title.map((title: DTO_Title) => {
          return {
            ...title,
            Id: `new_${getUUID()}`,
          };
        });
        const titleAdd = [...currentTitles, ...newTitles];
        onChange(nameOf("Titles"), { value: titleAdd });
      }
    };

    const handleAddRebate = (
      rebateData: DTO_ChangeOfOwnership_Assessment_Rebates
    ) => {
      if (
        !currentAssessments
          ?.map((item: any) => item?.Assessment_Id)
          ?.includes(rebateData?.AssessmentId) &&
        rebateData?.RebateEntitlements?.Rebates?.length
      ) {
        const rebateEntitlements = rebateData?.RebateEntitlements;
        const isClearRebate = valueGetter(
          `${EKeysOfSteps.Type}._option.Type`
        )?.COOT_Clear_Rebates;
        let newRebates = rebateEntitlements?.Rebates ?? [];
        if (isClearRebate) {
          newRebates = rebateEntitlements?.Rebates?.map(
            (rebateItem: DTO_Rebates) => {
              rebateItem.is_InActive = isClearRebate;
              if (rebateItem?.Effective_Date) {
                rebateItem.Loss_Of_Eligibility_Date = subDays(
                  rebateItem.Effective_Date,
                  1
                );
              } else {
                rebateItem.Loss_Of_Eligibility_Date = new Date();
              }
              return rebateItem;
            }
          );
        }

        onChange(`${EKeysOfSteps.Rebates}.Rebates`, {
          value: [...currentRebateEntitlement?.Rebates, ...newRebates],
        });
        onChange(`${EKeysOfSteps.Rebates}.Owners`, {
          value: [
            ...currentRebateEntitlement?.Owners,
            ...rebateEntitlements?.Owners,
          ],
        });

        setStepsVisible([
          {
            visible:
              valueGetter(`${EKeysOfSteps.Type}._option.Type`)
                ?.COOT_Display_Rebates &&
              currentRebateEntitlement?.Rebates?.length,
            key: EKeysOfSteps.Rebates,
            isClearData: false,
          },
        ]);
      }
    };

    const handleGridSelectionChange = (dataItem: any, field: string) => {
      let newSelected = dataItem ? dataItem[0] : undefined;
      onChange(nameOf(field), {
        value: newSelected,
      });
    };

    const handleRemoveTitle = (title: DTO_Title) => {
      if (title) {
        let newTitles = currentTitles.filter(
          (item: DTO_Title) => item?.Id !== title?.Id
        );
        if (newTitles[0]) {
          handleGridSelectionChange([newTitles[0]], "_option.TitleSelected");
        } else {
          handleGridSelectionChange([], "_option.TitleSelected");
        }
        onChange(nameOf("Titles"), { value: newTitles });
      }
    };

    const handleRemoveAssessment = (
      assessment: DTO_Assessment,
      isRemoveTitle: boolean
    ) => {
      if (assessment) {
        let newAssessments = currentAssessments.filter(
          (item: DTO_Assessment) =>
            item?.Assessment_Id !== assessment?.Assessment_Id
        );
        if (newAssessments[0]) {
          newAssessments[0].selected = true;
          handleGridSelectionChange(
            [newAssessments[0]],
            "_option.AssessmentSelected"
          );
        } else {
          handleGridSelectionChange([], "_option.AssessmentSelected");
        }
        if (isRemoveTitle) {
          let newTitles = currentTitles.filter(
            (item: DTO_Title) =>
              item?.Assessment_Id !== assessment?.Assessment_Id
          );
          onChange(nameOf("Titles"), { value: newTitles });
          handleGridSelectionChange([], "_option.TitleSelected");
        }
        let newRebates =
          currentRebateEntitlement?.Rebates?.filter(
            (rebateItem: DTO_Rebates) =>
              rebateItem?.Assessment_Id !== assessment?.Assessment_Id
          ) ?? [];
        let newOwnersAssociated =
          currentRebateEntitlement?.Owners?.filter(
            (owners: DTO_RebateEntitlementOwners) =>
              owners?.Assessment_Id !== assessment?.Assessment_Id
          ) ?? [];
        setStepsVisible([
          {
            visible:
              valueGetter(`${EKeysOfSteps.Type}._option.Type`)
                ?.COOT_Display_Rebates && newRebates?.length,
            key: EKeysOfSteps.Rebates,
            isClearData: false,
          },
        ]);

        onChange(`${EKeysOfSteps.Rebates}.Rebates`, { value: newRebates });
        onChange(`${EKeysOfSteps.Rebates}.Owners`, {
          value: newOwnersAssociated,
        });
        onChange(nameOf("Assessments"), { value: newAssessments });
        handleUpdateRelatedNames();
      }
    };

    const handleLookupAssessment = async (
      data: fnt_Assessment_LookupResult[]
    ) => {
      setIsLoadingAddAssessment(true);
      const assessmentIds = map(data, nameOfAssessment("Assessment_Id"));
      const [assessmentData, rebateData, retainedNames] = await Promise.all([
        loadChangeOfOwnerShipAssessment(data?.[0]?.Assessment_Id ?? 0),
        loadRebateEntitlementsAssessment(data?.[0]?.Assessment_Id ?? 0),
        loadRetainNames({
          AssessmentIds: assessmentIds,
          PIC_Ids: [],
        }),
      ]);
      if (
        isSuccessResponse(assessmentData) &&
        isSuccessResponse(rebateData) &&
        isSuccessResponse(retainedNames) &&
        assessmentData?.data &&
        rebateData?.data &&
        retainedNames?.data
      ) {
        handleAddRebate(rebateData?.data);
        handleAddAssessment(assessmentData?.data);
        handleRetainNames(
          selectedType,
          changeOfOwnershipLOVs,
          retainedNames?.data,
          valueGetter,
          onChange,
          options?.setIsLoadingStep
        );
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Add ${assessmentLowercaseLabel} failed`,
          type: "error",
          autoClose: false,
        });
      }
      setIsLoadingAddAssessment(false);
      setShowAddAssessmentDialog(false);
    };

    const handleUpdateRelatedNames = async () => {
      options?.setIsLoadingStep(true);
      const assessmentIds = map(
        valueGetter(`${EKeysOfSteps.Property}.Assessments`) || [],
        nameOfAssessment("Assessment_Id")
      );
      const retainedNames = await loadRetainNames({
        AssessmentIds: assessmentIds,
        PIC_Ids: [],
      });
      if (isSuccessResponse(retainedNames) && retainedNames?.data) {
        const newRetainedNames = [
          ...(retainedNames?.data?.OwnerDetails?.Contacts || []),
          ...(retainedNames?.data?.AssociatedNamesDetails || []),
          ...(retainedNames?.data?.RatePayerDetails?.Contacts || []),
        ];
        handleUpdateRetainedNames(
          newRetainedNames,
          changeOfOwnershipLOVs,
          valueGetter,
          onChange,
          options?.setIsLoadingStep
        );
      } else {
        localNotificationRef?.current?.pushNotification({
          title: `Remove retained names failed.`,
          type: "error",
          autoClose: false,
        });
      }
      options?.setIsLoadingStep(false);
    };

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel
                title={`${assessmentLabel}s for which change is to apply`}
                isMandatory={assessmentMandatory.isMandatory}
                errorMessage={assessmentMandatory.errorMessage}
              />
              <CCGrid
                className="cc-assessment-change-of-ownership"
                data={currentAssessments ?? []}
                selectedRows={selectedAssessment ? [selectedAssessment] : []}
                columnFields={newAssessmentCols}
                primaryField={nameOfAssessment("Assessment_Id")}
                readOnly={options?.isReadOnly}
                selectableMode="single"
                isLimitDetailWidth
                onSelectionChange={(dataItem) => {
                  handleGridSelectionChange(
                    dataItem,
                    "_option.AssessmentSelected"
                  );
                }}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      iconClass="fas fa-plus"
                      title={`Add ${assessmentLowercaseLabel}`}
                      onClick={() => {
                        setShowAddAssessmentDialog(true);
                      }}
                    />
                    <Button
                      iconClass="fas fa-minus"
                      title={`Remove ${assessmentLowercaseLabel}`}
                      disabled={!selectedAssessment}
                      onClick={() => {
                        setConfirmDialog({
                          title: `Remove ${assessmentLabel}?`,
                          message: "",
                          subMessage: `Do you wish to remove the corresponding ${titleLowercaseLabel}s for selected  ${assessmentLowercaseLabel}s from the change of ownership?`,
                          data: selectedAssessment,
                        });
                      }}
                    />
                  </div>
                }
              />
            </div>

            <div className="cc-field">
              <CCLabel
                title={`${titlesLabel} for which change is to apply`}
                isMandatory={titleMandatory.isMandatory}
                errorMessage={titleMandatory.errorMessage}
              />
              <CCGrid
                className="cc-title-change-of-ownership"
                data={currentTitles}
                selectedRows={selectedTitle ? [selectedTitle] : undefined}
                columnFields={newTitleCols}
                readOnly={options?.isReadOnly}
                selectableMode="single"
                onSelectionChange={(dataItem: any) => {
                  handleGridSelectionChange(dataItem, "_option.TitleSelected");
                }}
                primaryField={nameOfTitle("Id")}
                toolbar={
                  <div className="cc-grid-tools-bar">
                    <Button
                      iconClass="fas fa-minus"
                      title={`Remove ${titleLowercaseLabel}`}
                      disabled={!selectedTitle}
                      onClick={() => {
                        handleRemoveTitle(selectedTitle);
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </section>
        {confirmDialog && (
          <ConfirmDialog
            onClosePopup={() => {
              setConfirmDialog(undefined);
            }}
            height="auto"
            title={confirmDialog.title}
            message={confirmDialog.message}
            subMessage={confirmDialog.subMessage}
            onConfirmYes={() => {
              handleRemoveAssessment(confirmDialog.data, true);
              setConfirmDialog(undefined);
            }}
            onConfirmNo={() => {
              handleRemoveAssessment(confirmDialog.data, false);
              setConfirmDialog(undefined);
            }}
          />
        )}
        {showAddAssessmentDialog && (
          <AddAssessmentLookupDialog
            onClose={() => {
              setShowAddAssessmentDialog(false);
            }}
            handleAddAssessment={handleLookupAssessment}
            isLoadingFinish={isLoadingAddAssessment}
            //Update multiple when api updated
            selectableMode="single"
          />
        )}
      </>
    );
  }
);
