import { eventEmitter } from "@/App";
import { ParcelZonesEventType } from "@app/products/property/parcels/[id]/components/child-screens/zones/constant";
import { DTO_ParcelZone } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ParcelZone>();
export const colParcelZones: IColumnFields[] = [
  {
    field: nameOf("ZoningScheme"),
    title: "Zoning Scheme",
    handleOnClick: (dataItem: DTO_ParcelZone) => {
      eventEmitter.emit(ParcelZonesEventType.zoneDetails, dataItem.Id);
    },
  },
  {
    field: nameOf("ActiveFrom"),
    title: "Active From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
  {
    field: nameOf("Id"),
    title: "Zone ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
