import { TOWN_PLANNING_PPR_CURRENT_APPLICATION_LIST_VIEW_URL } from "@app/products/town-planning/ppr/permit-referrals/all/register/contant";
import { PPRApplication } from "@app/products/town-planning/ppr/permit-referrals/all/register/model";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { DashboardView } from "@common/pages/home/components/event-section/components/dashboard-view/_index";
import {
  colBookmark,
  colPPRMyApplications,
} from "@common/pages/home/components/event-section/config";
import { useGlobalStore } from "@common/stores/global/store";
import { TOWN_PLANNING_CONFIGURATION_MODE } from "@common/stores/products/town-planning/enum";
import { useCommonTownPlanningStore } from "@common/stores/products/town-planning/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IBookmark } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { useNewBookmarkStore } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/store";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";
// const nameOf = nameOfFactory<ISampleProducts>();
const nameOfPPRMyApplications = nameOfFactory<PPRApplication>();

export const EventsSection = observer(() => {
  const { townPlanningMode } = useCommonTownPlanningStore();
  const { productsActive, currentUserInfo, isVisibleAndIsCommunityCentral } =
    useGlobalStore();

  // const isVisibleCRMS =
  //   isVisibleAndIsCommunityCentral(PRODUCT_TYPE.CustomerService) === "visible";
  const isVisibleTownPlanning =
    isVisibleAndIsCommunityCentral(PRODUCT_TYPE.TownPlanning) === "visible";

  const isVisiblePPR = useMemo(
    () =>
      isVisibleTownPlanning &&
      townPlanningMode === TOWN_PLANNING_CONFIGURATION_MODE.PPR,
    [isVisibleTownPlanning, townPlanningMode]
  );

  const {
    bookmarks,
    removeBookmarks,
    bookmarksSelected,
    setBookmarksSelected,
    isLoading,
  } = useNewBookmarkStore();
  const nameOfBookmark = nameOfFactory<IBookmark>();

  // const sampleProducts = [
  //   {
  //     Description: "Jason McSmith called",
  //     TargetDate: "Target Date 31 Mar 2020",
  //     Status: "Scheduled",
  //     Id: 1,
  //   },
  //   {
  //     Description: "Jason McDonald visited",
  //     TargetDate: "Target Date 31 Apr 2020",
  //     Status: "Scheduled",
  //     Id: 2,
  //   },
  //   {
  //     Description: "Lisa Ross called",
  //     TargetDate: "Target Date 31 Apr 2020",
  //     Status: "Scheduled",
  //     Id: 3,
  //   },
  // ];

  // const state = {
  //   data: sampleProducts,
  // };

  const [open, setOpen] = useState(false);
  const [menuWrapperRef, setMenuWrapperRef] = useState<HTMLDivElement | null>();

  const handleOnSelect = (e: any) => {
    setOpen(false);
  };

  const onPopupOpen = () => {
    if (menuWrapperRef != null) {
      const element = menuWrapperRef.querySelector(
        "[tabindex]"
      ) as HTMLDivElement;
      element.focus();
    }
  };

  const onBlurHandler = () => {
    setOpen(false);
  };

  // const CRMSEvent = () => {
  //   return (
  //     <>
  //       <div>
  //         <div className="cc-event-title">My Messages</div>
  //         <CCGrid
  //           className="cc-event-grid"
  //           data={state.data}
  //           columnFields={colMyMessage}
  //           primaryField={nameOf("Id")}
  //         />
  //       </div>
  //       <div>
  //         <div className="cc-event-title">My Recorded Events</div>
  //         <CCGrid
  //           className="cc-event-grid"
  //           data={state.data}
  //           columnFields={colMyMessage}
  //           primaryField={nameOf("Id")}
  //         />
  //       </div>
  //     </>
  //   );
  // };

  return (
    <div className="cc-event-section cc-home-section">
      <div className="cc-section-heading">Events</div>
      {productsActive.length > 0 ? (
        <>
          <Popup
            offset={{ left: 0, top: 0 }}
            show={open}
            onOpen={onPopupOpen}
            popupClass={"popup-content"}
          >
            <div
              onBlur={onBlurHandler}
              tabIndex={-1}
              ref={(el) => setMenuWrapperRef(el)}
            >
              <Menu
                vertical
                style={{ display: "inline-block" }}
                onSelect={handleOnSelect}
              >
                <MenuItem text="View" />
                <MenuItem text="Hide" />
                <MenuItem text="Update Status" />
              </Menu>
            </div>
          </Popup>
          <div className="cc-form">
            <section className="cc-field-group">
              <div className="cc-form-cols-3 cc-section-content">
                {/* 
                TODO: Using for CRMS
                {isVisibleCRMS && <CRMSEvent />} */}
                {<DashboardView />}
                <div>
                  <div className="cc-event-title">My Bookmarks</div>
                  <CCGrid
                    isLoading={isLoading}
                    data={bookmarks}
                    columnFields={colBookmark}
                    primaryField={nameOfBookmark("Bookmark_ID")}
                    selectableMode="multiple"
                    onSelectionChange={(dataItem) =>
                      setBookmarksSelected([...dataItem] as IBookmark[])
                    }
                    selectedRows={bookmarksSelected}
                    toolbar={
                      <div className="cc-grid-tools-bar">
                        <Button
                          iconClass="fas fa-minus"
                          title="Remove Bookmark"
                          disabled={!bookmarksSelected.length}
                          onClick={() => {
                            removeBookmarks(
                              bookmarksSelected,
                              currentUserInfo?.UserPKID
                            );
                            setBookmarksSelected([]);
                          }}
                        />
                      </div>
                    }
                  />
                </div>
                <div>
                  {isVisiblePPR && (
                    <>
                      <div className="cc-event-title">My Applications</div>
                      <CCGrid
                        columnFields={colPPRMyApplications}
                        primaryField={nameOfPPRMyApplications("ID")}
                        dataUrl={
                          TOWN_PLANNING_PPR_CURRENT_APPLICATION_LIST_VIEW_URL
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <Loading isLoading />
      )}
    </div>
  );
});
