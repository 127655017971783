import { ServiceStandardView } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ServiceStandardView>();

export const colCategory: IColumnFields[] = [
  { title: "Category", field: nameOf("Category") },
  { title: "Sub Category", field: nameOf("SubCategory") },
  { title: "Precis", field: nameOf("Name") },
  { title: "Org Structure", field: nameOf("OrgStructure") },
  { title: "Action Officer", field: nameOf("ActionOfficer") },
];
