import { CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE } from "@app/products/crms/system-admin/categories/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export interface INewSubCategoryButtonProps {
  disabled?: boolean;
  categoryId?: string;
}

export const NewSubCategoryButton = ({
  disabled,
  categoryId,
}: INewSubCategoryButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title={"New subcategory"}
      disabled={disabled || !categoryId}
      onClick={() =>
        history.push(
          `${CRMS_SYSTEM_ADMIN_CATEGORY_ROUTE}/${categoryId}/subcategory/new`
        )
      }
    />
  );
};
