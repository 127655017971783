import { useFurtherInfoStore } from "@app/core/further-info/[id]/store";
import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const FurtherInfoHistoryTab = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { furtherInfoId: id, furtherInfo } = useFurtherInfoStore();
  const furtherInfoId: number = lastSelectedRow?.ID ?? id;
  return (
    <Journal
      id={furtherInfoId}
      triggersReload={furtherInfo}
      recordType={RECORDTYPE.CORE_FurtherInformationRequest}
    />
  );
});
