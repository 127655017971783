import { eventEmitter } from "@/App";
import { AcknowledgeEventsDialog } from "@app/products/crms/components/dialogs/acknowledge-events/_index";
import { postSaveAcknowledgement } from "@app/products/crms/components/dialogs/acknowledge-events/api";
import { EventBatchAcknowledge } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { FormSubmitClickEvent } from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export interface IAcknowledgeEventsButtonProps {
  isDisabled?: boolean;
  isVisible?: boolean;
}

export const AcknowledgeEventsButton = observer(
  ({ isDisabled = false, isVisible = true }: IAcknowledgeEventsButtonProps) => {
    const { gridSelectedIds, clearSelectedItems } = useCCProductListViewStore();
    const [isShowAcknowledgeEventsDialog, setIsShowAcknowledgeEventsDialog] =
      useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (event: FormSubmitClickEvent) => {
      const values = cloneDeep(event.values);
      delete values?._option;
      const params = {
        ...values,
        SelectedRows: gridSelectedIds,
        Show_MailMerge: true,
      };
      setIsLoading(true);
      const response = await postSaveAcknowledgement(
        params as EventBatchAcknowledge
      );
      setIsShowAcknowledgeEventsDialog(false);
      setIsLoading(false);
      if (isSuccessResponse(response)) {
        if (
          response.data?.ReturnObj?.MergeResult &&
          response.data?.ReturnObj?.ContentType &&
          response.data?.ReturnObj?.FileNameNoSpaces
        ) {
          DownloadFile(
            response.data?.ReturnObj?.MergeResult,
            response.data?.ReturnObj?.ContentType,
            response.data?.ReturnObj?.FileNameNoSpaces
          );
        }
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Event(s) could not be acknowledged.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    };

    if (!isVisible) return null;

    return (
      <>
        <CCNavButton
          title={"Acknowledge Event(s)"}
          onClick={() => {
            setIsShowAcknowledgeEventsDialog(true);
          }}
          disabled={isLoading || gridSelectedIds.length === 0 || isDisabled}
        />

        {isShowAcknowledgeEventsDialog && (
          <AcknowledgeEventsDialog
            onClosePopup={() => {
              setIsShowAcknowledgeEventsDialog(false);
            }}
            handleSubmit={onSubmit}
            isLoading={isLoading}
          />
        )}
      </>
    );
  }
);
