import { eventEmitter } from "@/App";
import { AddAssessmentDialog } from "@app/products/property/components/action-bar/dialog/add-assessment/_index";
import { postAddAssessmentSupplementary } from "@app/products/property/components/action-bar/dialog/add-assessment/api";
import { defaultWorkflowHeader } from "@app/products/property/components/action-bar/dialog/add-assessment/config";
import {
  DTO_Workflow_Supplementary_Associate,
  VO_Assessment_Ratepayer,
} from "@app/products/property/components/action-bar/dialog/add-assessment/model";
import { WorkflowProcessMode } from "@app/products/property/model";
import { PROPERTY_ASSESSMENT_RATEPAYERS_REGISTER_URL } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/assessment/add/constant";
import { ASSESSMENT_ACCORDION_GRID_ID } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/_index";
import { SUPPLEMENTARY_TYPES } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export const AddAssessmentButton = observer(() => {
  const { id } = useParams<{ id: string }>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  //store
  const { supplementaryAssessments } = useSupplementaryRatesStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { reLoadSupplementaryRates } = useSupplementaryRatesStore();

  //state local
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);

  const isDisabled = useMemo(() => {
    return (
      supplementaryAssessments?.SupplementaryDetail?.Type ===
        SUPPLEMENTARY_TYPES.ASSOCIATE_ASSESSMENTS ||
      supplementaryAssessments?.SupplementaryDetail?.IsComplete
    );
  }, [supplementaryAssessments]);

  /**
   * handle add assessment (Finish button)
   * @param assessment
   * @returns
   */
  const handleAddAssessment = async (assessment: VO_Assessment_Ratepayer) => {
    //payload send to api
    const payload: DTO_Workflow_Supplementary_Associate = {
      WorkflowHeader: defaultWorkflowHeader,
      WorkflowDetail: {
        Component_Number: 7, //default
        Supplementary_Id: +id,
        SelectFromMyList: false, //default
        IsMultipleAllowed: false, //default
        Component_Ids: assessment.Assessment_Id + "", //Assessment Id was selected}
      },
    };
    setIsLoadingFinish(true);
    //call api
    const response = await postAddAssessmentSupplementary(
      payload,
      WorkflowProcessMode.Finish
    );

    if (isSuccessResponse(response) && response && response?.data?.IsSuccess) {
      setShowAddDialog(false);
      await reLoadSupplementaryRates(); //reload new record
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [ASSESSMENT_ACCORDION_GRID_ID],
      }); //Reload assessment accordion
      pushNotification({
        title:
          response.data.SuccessMessage ?? "Assessment created successfully",
        type: "success",
      });
    } else {
      //local notification
      notificationRef?.current?.pushNotification({
        autoClose: false,
        title: response.data.ErrorMessage ?? `Assessment created failed`,
        type: "error",
      });
    }
    setIsLoadingFinish(false);
  };

  return (
    <>
      <CCNavButton
        title="Add"
        disabled={isDisabled}
        onClick={() => {
          setShowAddDialog(true);
        }}
      />
      {showAddDialog && (
        <AddAssessmentDialog
          dataUrl={PROPERTY_ASSESSMENT_RATEPAYERS_REGISTER_URL}
          onClose={() => {
            setShowAddDialog(false);
          }}
          handleFinish={handleAddAssessment}
          notificationRef={notificationRef}
          isLoadingFinish={isLoadingFinish}
        />
      )}
    </>
  );
});
