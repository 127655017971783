export const processExternalRouteStateGlobalSettings = [
  {
    name: "ProcessExternal_ProcessExternal",
    component: require("./forms/process-external").default,
  },
  {
    name: "ProcessExternal_ExternalInterfaceForModules",
    component: require("./forms/external-interface-for-modules").default,
  },
  {
    name: "ProcessExternal_PropertyReturnQueryExtensionForSearching",
    component: require("./forms/property-return-query-extension-for-searching")
      .default,
  },
  {
    name: "ProcessExternal_MappingInterface",
    component: require("./forms/mapping-interface").default,
  },
  {
    name: "ProcessExternal_ProcessExternalSummary",
    component: require("./forms/process-external-summary").default,
  },
];
