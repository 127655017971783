import { displayDotByLimit } from "@common/utils/formatting";
import { isArray } from "lodash";
import React from "react";
import "./_index.scss";

interface IFormTitleProps {
  title?: string;
  badge?: number | string | string[];
  prefixTitle?: React.ReactNode;
  titleLength?: number;
  status?: string;
}

// Common Title template
export const FormTitle = ({
  title,
  badge,
  titleLength,
  prefixTitle,
}: IFormTitleProps) => {
  const renderBadge = () => {
    if (badge) {
      if (isArray(badge) && badge.length > 0) {
        return (
          <div className="cc-list-badge">
            {badge.map((item, index) => (
              <span key={`${item}-${index}`} className="cc-standard-badge">
                {item}
              </span>
            ))}
          </div>
        );
      }
      return <span className="cc-standard-badge">{badge}</span>;
    }
    return "";
  };

  return (
    <div className={`cc-common-title`}>
      {prefixTitle && <span className="prefix-title">{prefixTitle}</span>}
      <h2>
        {titleLength ? displayDotByLimit(title || "", titleLength) : title}
      </h2>
      {renderBadge()}
    </div>
  );
};
