import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getOrgUnitTabDetailsById } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/detail/api";
import { OrgUnitTabDetail } from "@common/pages/settings/security/org-structure/_id/components/reference-sidebar/detail/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const SecurityOrgStructureTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { orgUnit } = useOrgStructureStore();
  const [isLoading, setIsLoading] = useState(false);
  const [orgStructureTabDetailsInfo, setOrgStructureTabDetailsInfo] =
    useState<OrgUnitTabDetail>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const orgUnitID =
    orgUnit?.OrgUnit_ID ?? lastSelectedRow?.OrgUnit_ID ?? lastSelectedRow?.ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getOrgUnitTabDetailsById(orgUnitID);
    if (isSuccessResponse(response)) {
      setOrgStructureTabDetailsInfo(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!orgUnitID) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [orgUnit, orgUnitID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Org Unit Name:"}
          value={orgStructureTabDetailsInfo?.OrgUnit_Name}
        />
        <ProductReferenceRow
          title={"Phone:"}
          value={orgStructureTabDetailsInfo?.Phone}
        />
        <ProductReferenceRow
          title={"Users:"}
          value={orgStructureTabDetailsInfo?.Users?.reduce(
            (pre, cur) => pre + ", " + cur.Value,
            ""
          )
            .slice(1)
            .trim()}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
