import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colPaidFees: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ReferenceNumber,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ContactName,
    title: "Applicant",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CreatedDate,
    title: "Recorded",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.FeeType,
    title: "Type",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.AmountIssued,
    title: "Issued",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.AmountPaid,
    title: "Paid",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ReceiptNumber,
    title: "Receipt Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ReceiptDate,
    title: "Receipt Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.ReceiptedBy,
    title: "Receipted By",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Method,
    title: "Method",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CostCode,
    title: "Cost Code",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.DebtorNumber,
    title: "Debtor Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.SecurePay_Reference,
    title: "Payment Reference",
    width: 150,
  },
];
