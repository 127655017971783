import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const actionsLettersRoute: ICCRoute = {
  path: "actions-or-letters",
  name: "Actions/Letters",
  enum: eMenuAction.DebtRecovery_ListActionsAndLetters,
  children: [
    {
      path: "all-actions-and-letters",
      name: "All Actions and Letters",
      enum: eMenuAction.DebtRecovery_ListActionsAndLetters_All,
      component: require("./all-actions-and-letters/_index").default,
    },
    {
      path: "all-actions-and-letters-for-active-debt-recoveries",
      name: "All Actions and Letters for Active Debt Recoveries",
      enum: eMenuAction.DebtRecovery_ListActionsAndLetters_DebtRecoveries,
      component:
        require("./all-actions-and-letters-for-active-debt-recoveries/_index")
          .default,
    },
    {
      path: "letters-that-have-been-issued",
      name: "Letters that have been Issued",
      enum: eMenuAction.DebtRecovery_ListActionsAndLetters_Issued,
      component: require("./letters-that-have-been-issued/_index").default,
    },
    {
      path: "letters-to-be-issued-for-active-debt-recoveries",
      name: "Letters to be Issued for Active Debt Recoveries",
      enum: eMenuAction.DebtRecovery_ListActionsAndLetters_TobeIssuedForDebtRecoveries,
      component:
        require("./letters-to-be-issued-for-active-debt-recoveries/_index")
          .default,
    },
  ],
};
