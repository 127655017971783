export class ContactRelationshipResponse {
  _BubbleUps: ICommonBubbleUp[] = [];
  _ExtensionType: any[] = [];
  _KeywordTags: any[] = [];
  _SaveTriggers: any[] = [];
  Sys_TimeStamp: string = "";
  RID: number = 0;
  ContactRelationshipType_ENUM: string = "";
  Contact?: ICommonContact;
  Sys_EnterpriseKey: string = "";
  Sys_CreatedDate?: Date;
  Sys_CreatedBy: string = "";
  Sys_CreatedBy_ID: number = 0;
  Sys_DBRowState: string = "";
  Sys_TransactionDate?: Date;
  Sys_ModifiedDate?: Date;
  Sys_ModifiedBy_ID: number = 0;
  Sys_StatClockStatus_ENUM: string = "";
}
export interface ICommonBubbleUp {
  PKID: number;
  SourceIdentifier: ICommonSourceIdentifier;
  BubbleUpType_ENUM: string;
  Sys_CreatedDate: Date;
}
export interface ICommonSourceIdentifier {
  _RecordSource_ID: number;
  _RecordSourceType_ENUM: string;
  _RecordSource_ColumnName: string;
  _RecordSource_StringID: string;
  _RecordSource_FileNumber: string;
}
export interface ICommonContact {
  Sys_TimeStamp: string;
  _PreferredMethod_Formatted: string;
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _ExtensionType: string[];
  _SaveTriggers: any[];
  Salutation: string;
  GenderPronoun: string;
  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: string;
  PreferredMethod_ENUM: string;
  NARNumber: string;
  Flag_SendSMSAlerts: boolean;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  ContactRoles_Count: number;
  Organisation: string;
  PostalAddress: ICommonAddress;
  AlternateAddress: ICommonAddress;
  AddressBook: ICommonAddressBook;
  AddressBookFlag_ENUM: string;
  CopiedFrom_Contact_ID: number;
  SynchDate: Date;
  Extension: ICommonExtension;
  ContactAlert: string;
  Flag_Duplicate: boolean;
  IsContactCopied: boolean;
  ABNValid: boolean;
  ACNValid: boolean;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}
export interface ICommonExtension {
  VerificationQuestion: string;
  VerificationResponse: string;
  JobTitle: string;
  Organisation: string;
  LicenceNo: string;
  SecondLanguage: string;
}
export interface ICommonAddressBook {
  Sys_TimeStamp: string;
  _ExtensionType: any[];
  Sys_EnterpriseKey: string;
  Sys_TransactionDate: Date;
  AddressBook_ID: number;
  AddressBook_Name: string;
  AddressBookSection_ENUM: string;
  ProductType_ENUM: string;
  IsSystemRecord: boolean;
}
export interface ICommonAddress {
  _BubbleUps: any[];
  Sys_TimeStamp: string;
  Address_ID: number;
  AddressClassification_ENUM: string;
  ContactAddressType_Name: string;
  PropertyName: string;
  UnitNo: string;
  StreetNo: string;
  StreetName: string;
  Suburb: string;
  State: string;
  Postcode: string;
  LGA: string;
  AreaCode: string;
  Region: string;
  Location_Description: string;
  Map_Reference: string;
  AssessmentNo: string;
  NearestCrossStreet: string;
  PoBoxNo: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  Country: string;
  PropertyType: string;
  PropertySubType: string;
  RIN: string;
  ComplexAddress: boolean;
  Flag_StreetName_HasParts: boolean;
  StreetNamePart_Name: string;
  StreetNamePart_Type: string;
  PropertyAssessment_ID: number;
  PropertyAssessment: ICommonPropertyAssessment;
  PostalDelivery_Identifier: string;
  PostalDelivery_Barcode: string;
  VMAS_GeoCode: string;
  PropertyAlert: string;
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_DBRowState: string;
  Sys_TransactionDate: Date;
  Sys_ModifiedDate: Date;
  Sys_ModifiedBy_ID: number;
  Sys_StatClockStatus_ENUM: string;
}
export interface ICommonPropertyAssessment {
  PropertyAssessment_ID: number;
  Flag_PrimaryAddress: boolean;
  Flag_RuralHouseNumber: boolean;
  Flag_OutsideProperty: boolean;
  CrownAllotment: string;
  LotNo: string;
  County: string;
  CertOfTitle: string;
  Valuation_Number: string;
  Owners_Name: string;
  Owners_Attention_Of: string;
  Owners_Care_Of: string;
  Owners_Address: string;
  Owners_Locality: string;
  Owners_State: string;
  Owners_Postcode: string;
  RatePayers_Name: string;
  RatePayers_Attention_Of: string;
  RatePayers_Care_Of: string;
  RatePayers_Address: string;
  RatePayers_Locality: string;
  RatePayers_State: string;
  RatePayers_Postcode: string;
  RatePayers_Phone: string;
  RatePayers_Email: string;
  RatePayers_Mobile: string;
  RatePayers_Fax: string;
}
export class ContactRelationshipRequest extends ContactRelationshipResponse {}

export class ContactRelationshipFormData extends ContactRelationshipResponse {
  ContactType: string = "";
  Position: string = "";
  SecondLanguage: string = "";
  LicenceNo: string = "";
  PostalAddress: string = "";
  StreetAddress: string = "";
  AlternateAddress: string = "";
}
