import { ValuationInputCell } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/valuations/component/custom-cell/valuation-input-cell/_index";
import { DTO_ValuationValues } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/valuations/model";
import { formatDynamicValuationsForCell } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ValuationValues>();
export const colMasterPropertyValuation: IColumnFields[] = [
  {
    field: nameOf("Valuation_Type"),
    title: "Valuation Type",
  },
  {
    field: nameOf("Previous_Valuation"),
    title: "Previous Valuation",
    format: formatDynamicValuationsForCell,
    filter: "numeric",
  },
  {
    field: nameOf("Current_Valuation"),
    title: "Supplementary Valuation",
    format: formatDynamicValuationsForCell,
    filter: "numeric",
    editable: true,
    editCell: ValuationInputCell,
  },
  {
    field: nameOf("Valuation_Difference"),
    title: "Difference",
    format: formatDynamicValuationsForCell,
    filter: "numeric",
  },
];
