import {
  CertificateMode,
  DTO_Certificate_AreaOfBalance,
  DTO_Certificate_Rebate,
} from "@app/products/property/certificates/[id]/components/child-screens/balances/model";
import { useCertificateBalanceStore } from "@app/products/property/certificates/[id]/components/child-screens/balances/store";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { BalanceOutStandingSAP } from "@app/products/property/components/grid/balance-out-standing-sap/_index";
import { nameOfFactory } from "@common/utils/common";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
const nameOfBalancesFinancial = nameOfFactory<DTO_Certificate_AreaOfBalance>();
const nameOfRebates = nameOfFactory<DTO_Certificate_Rebate>();
export const CertificateBalances = observer(() => {
  const {
    isLoading,
    data,
    colRebate,
    colBalances,
    responseLoadError,
    loadViews,
    loadCertificate,
    resetStore,
  } = useCertificateBalanceStore();
  const { certificate, certificateId } = useCertificateStore();

  useEffectOnce(() => {
    loadViews();
    return () => resetStore();
  });

  useEffect(() => {
    loadCertificate(certificateId);
  }, [certificateId, loadCertificate]);

  return (
    <div className="cc-field-group">
      {isLoading === CertificateMode.ALL ? (
        <Loading isLoading />
      ) : responseLoadError?.View ? (
        <CCLoadFailed
          responseError={responseLoadError?.View}
          onReload={() => {
            loadViews();
          }}
        />
      ) : (
        <>
          {isLoading === CertificateMode.DATA ? (
            <Loading isLoading />
          ) : responseLoadError?.Data ? (
            <CCLoadFailed
              responseError={responseLoadError?.Data}
              onReload={() => {
                loadCertificate(certificateId);
              }}
            />
          ) : (
            <>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Balance by financial area</label>
                  <CCGrid
                    data={data?.AreaOfBalances ?? []}
                    primaryField={nameOfBalancesFinancial("Financial_Area_Id")}
                    columnFields={colBalances}
                  />
                </div>
              </div>
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">Balance owing</label>
                  <CCCurrencyInput
                    name={"BalanceOwing"}
                    disabled
                    value={data?.Balance_Owing}
                    className="cc-form-control"
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Discount allowed</label>
                  <CCCurrencyInput
                    name={"DiscountAllowed"}
                    disabled
                    value={data?.Discount_Allowed}
                    className="cc-form-control"
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Rebates</label>
                  <CCGrid
                    data={data?.Rebates ?? []}
                    primaryField={nameOfRebates("Rebate_Type_Id")}
                    columnFields={colRebate}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <BalanceOutStandingSAP
                    sapId={certificate?.Cert_Applicant_SAP_Number}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
});
