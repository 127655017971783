import { Application_Status } from "@app/products/town-planning/ppr/constant";

export const permitCompletedValidatorListByFlagPPR = [
  Application_Status.RevisionReceived,
  Application_Status.AssessedByPlanner,
  Application_Status.PreliminaryAssessment,
  Application_Status.Validated,
  Application_Status.FurtherInfo,
  Application_Status.FurtherInfoReceived,
  Application_Status.FurtherInfoWaitingForApproval,
  Application_Status.FurtherInfoApproved,
  Application_Status.ReadyToSendForApproval,
  Application_Status.ReadyForDecision,
  Application_Status.ApprovedByTeamLeader,
  Application_Status.PlannerAssessment,
  Application_Status.PermitIssued,
  Application_Status.AmendmentIssued,
  Application_Status.NoticeOfDecision,
  Application_Status.PermitRefused,
  Application_Status.AmendmentRefused,
  Application_Status.Appealed,
  Application_Status.AppealComplete,
  Application_Status.ApplicationComplete,
];
