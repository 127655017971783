import { crsRoute } from "@app/products/crs/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface ICrsActionBarNavDropdownProps {
  category: string;
}

export const CrsActionBarNavDropdown = ({
  category,
}: ICrsActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={crsRoute.path}
      route={crsRoute}
    />
  );
};
