import { ColumnLinkTo } from "@components/cc-grid/model";
import { isNil } from "lodash";
import React, { AnchorHTMLAttributes } from "react";
import { Link } from "react-router-dom";

interface IDynamicValueCellProps {
  value?: string | null;
  dataItem?: any;
  linkTo?: ColumnLinkTo;
  href?: (dataItem: any) => AnchorHTMLAttributes<any> | string | undefined;
}

export const DynamicValueCell = ({
  value,
  dataItem,
  linkTo,
  href,
}: IDynamicValueCellProps) => {
  if (linkTo && !isNil(linkTo(dataItem)))
    return <Link to={linkTo(dataItem) || ""}>{value}</Link>;
  if (href) {
    const hrefData = href(dataItem);
    if (typeof hrefData === "string") {
      return <a href={hrefData}>{value}</a>;
    } else if (typeof hrefData === "object") {
      return <a {...hrefData}>{value}</a>;
    }
  }
  return <>{value}</>;
};
