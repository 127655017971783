import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockChargeStep = {
  Names: [],
};

export const listRole: IKeyValuePacket[] = [
  { Key: 1, Value: "Reservation Contact" },
  { Key: 2, Value: "Reserved for" },
  { Key: 3, Value: "Next of Kin" },
  { Key: 4, Value: "Deceased" },
];
