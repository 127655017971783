import { mockRemoveMeter } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/remove-meter/mock";
import { addOptionAPI } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/remove-meter/util";
import { sleep } from "@common/utils/common";

export const getRemoveMeter = async () => {
  await sleep(1000);
  return addOptionAPI(
    { ...mockRemoveMeter, DateOfRemoval: new Date() },
    { StatusOfExistingMeterList: "StatusOfExistingMeter" }
  );
};
