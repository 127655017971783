import { PROPERTYSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isNumber } from "lodash";
import { useEffect, useState } from "react";

export const useShowHideLogicPropertiesSettings = (
  formRenderProps: FormRenderProps
) => {
  const { valueGetter } = formRenderProps;
  const ddlPropertiesSystem = +valueGetter(
    ECorporateSettingsField.CorporateSettings_PropertySystem.toString()
  );
  const [showConnectionMethod, setShowConnectionMethod] = useState(false);
  const [showPropertyCustom, setShowPropertyCustom] = useState(false);
  const [showPropertiesEXE, setShowPropertiesEXE] = useState(false);
  const [showPropertiesConnectionString, setShowPropertiesConnectionString] =
    useState(false);
  const [showPropertiesAutoPopup, setShowPropertiesAutoPopup] = useState(false);
  const [
    showPropertySelectionReturnToPropertyDialog,
    setShowPropertySelectionReturnToPropertyDialog,
  ] = useState(false);

  useEffect(() => {
    if (isNumber(ddlPropertiesSystem)) {
      setShowConnectionMethod(false);
      setShowPropertyCustom(false);
      setShowPropertiesEXE(false);
      setShowPropertiesConnectionString(false);
      setShowPropertiesAutoPopup(false);
      setShowPropertySelectionReturnToPropertyDialog(false);

      switch (ddlPropertiesSystem) {
        case PROPERTYSYSTEM.Proclaim:
        case PROPERTYSYSTEM.Authority:
        case PROPERTYSYSTEM.Lynx:
        case PROPERTYSYSTEM.Pathway:
          setShowPropertiesConnectionString(true);
          setShowConnectionMethod(true);
          setShowPropertiesAutoPopup(true);
          setShowPropertySelectionReturnToPropertyDialog(true);
          break;
        case PROPERTYSYSTEM.PropertyGov:
          setShowPropertiesConnectionString(true);
          setShowPropertiesEXE(true);
          setShowPropertiesAutoPopup(true);
          setShowPropertySelectionReturnToPropertyDialog(true);
          break;
        case PROPERTYSYSTEM.Custom:
          setShowPropertiesConnectionString(true);
          setShowPropertiesEXE(true);
          setShowPropertyCustom(true);
          setShowConnectionMethod(true);
          setShowPropertiesAutoPopup(true);
          setShowPropertySelectionReturnToPropertyDialog(true);
          break;
        case PROPERTYSYSTEM.None:
        default:
          setShowPropertiesConnectionString(false);
          setShowPropertyCustom(false);
          break;
      }
    }
  }, [ddlPropertiesSystem]);

  return {
    showConnectionMethod,
    showPropertyCustom,
    showPropertiesEXE,
    showPropertiesConnectionString,
    showPropertiesAutoPopup,
    showPropertySelectionReturnToPropertyDialog,
  };
};
