import { PROPERTY_CHARGE_RUNS_ROUTE } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/constant";
import { DTO_AssociatedItem_ChargeRun } from "@app/products/property/components/associations/components/charge-run/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_ChargeRun>();
export const colListChargeRuns: IColumnFields[] = [
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
    locked: true,
    linkTo: (dataItem: DTO_AssociatedItem_ChargeRun) =>
      `${PROPERTY_CHARGE_RUNS_ROUTE}/${dataItem.Id}`,
  },
  {
    field: nameOf("ChargeRun_Type"),
    title: "Type",
  },
  {
    field: nameOf("Rating_Period"),
    title: "Rating Period",
  },
  {
    field: nameOf("Instalment_Plan"),
    title: "Instalment Plan",
  },
  {
    field: nameOf("Charge_Date"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ChargeRun_Name"),
    title: "Name",
  },
  {
    field: nameOf("ChargeRun_Code"),
    title: "Code",
  },
  {
    field: nameOf("ChargeRun_Description"),
    title: "Description",
  },
  {
    field: nameOf("Created_By"),
    title: "Created By",
  },
  {
    field: nameOf("Created_On"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Journal_Number"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Number_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Number_of_Levies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Total_Gross_Levy_Amount"),
    title: "Total Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Total_Rebate_Amount"),
    title: "Total Rebate Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
