import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
export const colFirePreventionActivities: IColumnFields[] = [
  {
    field: "TypeId",
    title: "Type ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "ActivityType",
    title: "Activity Type",
  },
  {
    field: "ActivityDateTime",
    title: "Activity Date/Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "CaseId",
    title: "Case ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "InspectionId",
    title: "Inspection ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "NoticeId",
    title: "Notice ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "InfringementId",
    title: "Infringement ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "WorkOrderId",
    title: "Work Order ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DocumentId",
    title: "Document ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Comments",
    title: "Comments",
  },
  {
    field: "UserId",
    title: "User ID",
  },
  {
    field: "ActivityId",
    title: "Activity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
