import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import { PermitContactsTab } from "@app/products/local-laws/components/sidebar/contacts/_index";
import { PermitDetailsTab } from "@app/products/local-laws/components/sidebar/details/_index";
import { PermitHistoryTab } from "@app/products/local-laws/components/sidebar/history/_index";
import { NewPermitButton } from "@app/products/local-laws/components/toolbar/buttons/new-local-laws/_index";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { mockLocalLawsAll } from "@app/products/local-laws/mock";
import { Svc_LLPermit } from "@app/products/local-laws/permits/model";
import { LocalLawsPermitsNewApplicationBookmark } from "@app/products/local-laws/permits/new-applications/util";
import { permitsRoute } from "@app/products/local-laws/permits/route";
import { localLawsRoute } from "@app/products/local-laws/route";
import { LLBookmark } from "@app/products/local-laws/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { colLocalLawsPermitsNewApplications } from "./config";

const nameOf = nameOfFactory<Svc_LLPermit>();
export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });
  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={permitsRoute.path} />,
    ],
    centerComponents: [
      <NewPermitButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Permit}
        recordType={RECORDTYPE.LLP_Permit}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        recordType={RECORDTYPE.LLP_Permit}
        keyField={"ID"}
        isDisabled={
          !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMUNICATION)
        }
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={LOCAL_LAWS_PERMIT_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        //TODO don't have exactly record type, temporary use LLP_Permit
        recordType={RECORDTYPE.LLP_Permit}
        detail={LLBookmark.getBookmarkDetail}
        displayName={LLBookmark.getBookmarkDisplayName}
        listViewDetail={
          LocalLawsPermitsNewApplicationBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          LocalLawsPermitsNewApplicationBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <PermitDetailsTab />,
      },
      {
        title: "Map",
        component: (
          <MapTab
            addressField={[nameOf("SiteAddress"), nameOf("Locality")]}
            zoom={18}
            recordType={RECORDTYPE.LLP_Permit}
            isInManagePage={false}
          />
        ),
      },
      { title: "Contacts", component: <PermitContactsTab /> },
      { title: "History", component: <PermitHistoryTab /> },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        data={mockLocalLawsAll}
        columnFields={colLocalLawsPermitsNewApplications}
        primaryField={"ID"}
      />
    </LoadingPermissionWrapper>
  );
});
