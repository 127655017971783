import { SETTING_LOCAL_GOVERNMENT_ROUTE } from "@common/pages/settings/lookups/local-government/constants";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const colLocalGovernment: IColumnFields[] = [
  {
    title: "Name",
    field: "Name",
    linkTo: (dataItem) => `${SETTING_LOCAL_GOVERNMENT_ROUTE}/${dataItem.ID}`,
  },
  { title: "State", field: "State" },
  { title: "Council Address", field: "CouncilAddress" },
  { title: "Postal Address", field: "CouncilPostalAddress" },
  { title: "Officer", field: "Officer" },
  { title: "Officer Email", field: "OfficerEmail" },
  { title: "Website", field: "Website" },
];

export const getBookmarkListViewDisplayName = () => {
  return "Setting - Local Government";
};

export const getBookmarkListViewDetail = () => {
  return "Setting - Lookups - Local Government";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Setting - Local Government ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.Address]);
};
