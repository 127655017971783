import { IFeeTypesGrid } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/child-screens/general/components/form-element/model";
import { ApplicationCategoryFee } from "@app/products/town-planning/ppr/[id]/model";

export const processFeeTypeGridData = (
  applicationCategoryFees: ApplicationCategoryFee[]
): IFeeTypesGrid[] => {
  return applicationCategoryFees.map(
    (item: ApplicationCategoryFee): IFeeTypesGrid => {
      return {
        ID: item.CoreLinkId == null ? item.Index : item.CoreLinkId,
        Index: item.Index,
        FeeType: item.ApplicationCategoryFeeType_Name,
        CalculatedFeeType: item.ApplicationCategoryCalculatedFee_Name,
        ApplicationCategoryFeeType_ID: item.ApplicationCategoryFeeType_ID,
        Inherit: item.InheritApplicationFeeType || false,
        Amount: item.ApplicationCategoryFee_Amount,

      };
    }
  );
};
