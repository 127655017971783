import { SiteUserForm } from "@common/pages/settings/security/site-users/_id/components/child-screens/general/_index";
import { SiteUserSubmitActions } from "@common/pages/settings/security/site-users/_id/model";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewSiteUser = observer(() => {
  const { onSubmit, isLoading, responseLoadError, loadSiteUser } =
    useSiteUserStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Site User" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSiteUser()}
        />
      ) : (
        <>
          <FormTitle title="New Site User" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={SiteUserSubmitActions.New}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <SiteUserForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
