import { ISearchResultData } from "./model";

export const searchResultDataMock: ISearchResultData = {
  Id: 1,
  Name: "New Result Search",
  ListView: "Arrangement - All",
  ResultData: [
    {
      AssessmentNumber: 1290,
      LegalDescription: "Lot 14 SP3227",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Active",
    },
    {
      AssessmentNumber: 523645,
      LegalDescription: "Lot 14 SP3227",
      AssessmentType: "Interim Subdivision Assessment",
      AssessmentStatus: "Deferred",
    },
    {
      AssessmentNumber: 90010000,
      PropertyAddress: "Gladesville Bridge",
      Ratepayer: "B F & M M LEE",
      LegalDescription: "No Details Available",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90010200,
      PropertyName: "Pilkington's Bridge",
      PropertyAddress: "Gladesville Rd",
      PropertyLocation: "Near Prince Edward St",
      Ratepayer: "B F 8i R J Wiseman",
      LegalDescription: "Road",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90010300,
      PropertyName: "Searle Monument",
      PropertyAddress: "Parramatta River",
      Ratepayer: "B F & M M LEE",
      LegalDescription: "No Details Available",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90010800,
      PropertyName: "Punt Rd Bedlam Point Wharf",
      PropertyAddress: "Looking Glass Bay",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90010900,
      PropertyName: "Wharf/Road Reserve",
      PropertyAddress: "Herberton Ave",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90011100,
      PropertyAddress: "8 Amy St",
      PropertyLocality: "Archies Creek",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90011200,
      PropertyAddress: "9 Anne Rd",
      PropertyLocality: "Archies Creek",
      Ratepayer: "B F 8c R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90011400,
      PropertyName: "Former wharf site",
      PropertyAddress: "Mount St",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90011600,
      PropertyAddress: "13 Princes St",
      PropertyLocality: "Archies Creek",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90011700,
      PropertyAddress: "Department Of Housing",
      Ratepayer: "B F 8c R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90011900,
      PropertyName: "Former Precision Woodware factory site along Woolwich Rd",
      PropertyAddress: "2 Clarke Rd",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90012000,
      PropertyAddress: "16 Brew St",
      PropertyLocality: "Archies Creek",
      Ratepayer: "B F 8c R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90012100,
      PropertyName: "Drainage reserve (remains of Wandella Ave and garden)",
      PropertyAddress: "Wandella Ave",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
    {
      AssessmentNumber: 90012200,
      PropertyAddress: "Brandville Wharf (William St)",
      Ratepayer: "B F & R J Wiseman",
      AssessmentType: "Normal Assessment",
      AssessmentStatus: "Historical",
    },
  ],

  Filter: {
    Assessment: {
      logic: "and",
      filters: [
        {
          field: "Property Address",
          operator: "contains",
          value: "Kunyung",
        },
        {
          field: "Number",
          operator: "eq",
          value: 9,
        },
      ],
    },
    Title: {
      logic: "and",
      filters: [
        {
          field: "Lot",
          operator: "eq",
          value: 5,
        },
        {
          field: "Volume",
          operator: "startswith",
          value: "VOL",
        },
        {
          field: "Type",
          operator: "startswith",
          value: "Lot",
        },
      ],
    },
  },
};
