import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { getDirectDebitBankAccountList } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/api";
import {
  DirectDebitBankAccount,
  DirectDebitStatusCode,
} from "@app/products/direct-debit/system-admin/model";
import { getDirectDebitStatusCodeById } from "@app/products/direct-debit/system-admin/status-codes/[id]/api";
import { ExistManageDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/existed/_index";
import { NewManageDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/new/_index";
import { checkIsInactiveDirectDebitStatusCode } from "@app/products/direct-debit/system-admin/status-codes/[id]/util";
import {
  TitleDirectDebitStatusCodeManagePage,
  manageDirectDebitStatusCodeAliasSlider,
} from "@app/products/direct-debit/system-admin/status-codes/constant";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { nameOfFactory } from "@common/utils/common";
import { CCCheckPermissionWrapper } from "@components/cc-check-permission-wrapper/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { orderBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

const nameOfBankAccount = nameOfFactory<DirectDebitBankAccount>();

const ManageDirectDebitStatusCode = () => {
  //#region STORE ========/
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const history = useHistory();
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const statusCodeId = useMemo(() => {
    return isNew ? 0 : +id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const [hasAllowNewPermission, setHasAllowNewPermission] = useState(false);

  const manageDirectDebitStatusCodeSlider = useFlexibleFetchData({
    alias: manageDirectDebitStatusCodeAliasSlider,
    slides: [
      {
        fetch: () => [
          getDirectDebitStatusCodeById(statusCodeId, cancelToken()),
          getDirectDebitBankAccountList(cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const [responseGetById, bankAccountList]: [
            APIResponse<DirectDebitStatusCode>,
            APIResponse<DirectDebitBankAccount[]>
          ] = dataFromApi;
          const ddStatusCodeFormData = responseGetById?.data;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: ddStatusCodeFormData,
            GeneralFormLovs: {
              bankAccountList: orderBy(
                bankAccountList?.data,
                [nameOfBankAccount("Description")],
                ["asc"]
              ),
            },
          });
          //#endregion save data to store =====/

          //#region Notification ========/
          listenNotificationPortal();

          if (checkIsInactiveDirectDebitStatusCode()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
          //#endregion Notification =====/
        },
      },
    ],
  });

  useEffect(() => {
    if (!isNew || (isNew && hasAllowNewPermission)) {
      manageDirectDebitStatusCodeSlider.fetchApi();
    }
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasAllowNewPermission]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitStatusCodeSlider.isFetching ||
          isLoadingForm ||
          routeDataStoreInstance.isLoadingFor(
            EWatchStatusLoading.IsLoadingPermission
          )
        }
        isFullScreen
      />
      <FormNavigation title={TitleDirectDebitStatusCodeManagePage} />
      {manageDirectDebitStatusCodeSlider.errorComponent ? (
        manageDirectDebitStatusCodeSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrapper
          onChangeHasPermission={() => setHasAllowNewPermission(true)}
          permission={{
            formIdentifier: FormIdentifier.DirectDebit_Dialog_StatusCode,
            productType: PRODUCT_TYPE_NUMBER.DirectDebit,
            formAction: [FormAction.CORE_ALLOW_NEW, FormAction.CORE_ALLOW_SAVE],
          }}
        >
          <NewManageDirectDebitStatusCode />
        </CCCheckPermissionWrapper>
      ) : (
        <ExistManageDirectDebitStatusCode />
      )}
    </>
  );
};

export default observer(ManageDirectDebitStatusCode);
