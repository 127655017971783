import { DTO_CentrelinkResponseBatchDetail } from "@app/products/property/centrelink-customer-confirmation/view-responses/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Field, FormElement } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<DTO_CentrelinkResponseBatchDetail>();
export const CentreLinkResponseBatchDetailsFormElement = observer(() => {
  return (
    <FormElement>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Request file date" />
            <Field
              name={nameOf("RequestFileDate")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Responses import date" />
            <Field
              name={nameOf("ResponsesImportDate")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rejections import date" />
            <Field
              name={nameOf("RejectionsImportDate")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Number of centrelink responses" />
            <Field
              name={nameOf("NumberOfCentreLinkResponses")}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Number of external responses" />
            <Field
              name={nameOf("NumberOfExternalResponses")}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Number of rejections" />
            <Field
              name={nameOf("NumberOfRejections")}
              component={CCNumericTextBox}
              format={NUMBER_FORMAT.NUMBER2}
              disabled
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Response file imported by" />
            <Field
              name={nameOf("ImportedByResponse")}
              component={CCInput}
              readOnly
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rejection file imported by" />
            <Field
              name={nameOf("ImportedByRejection")}
              component={CCInput}
              readOnly
            />
          </div>
        </div>
      </section>
    </FormElement>
  );
});
