import { IFee } from "@app/core/fees/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IFee>();
export const colFees: IColumnFields[] = [
  {
    field: nameOf("InvoiceDate"),
    title: "Invoice Date",
    locked: true,
  },
  {
    field: nameOf("ServiceDate"),
    title: "Service Date",
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("CodeCost"),
    title: "Code Cost",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
  },
  {
    field: nameOf("Owing"),
    title: "Owing",
  },
  {
    field: nameOf("Invoice"),
    title: "Invoice",
  },
];
