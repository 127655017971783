import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
export const colOccurrencesLeakage: IColumnFields[] = [
  {
    field: "MeterLeakageId",
    title: "Meter Leakage ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "LeakageOccurredApproxFrom",
    title: "Leakage Occurred Approximately From",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "LeakageOccurredUpTo",
    title: "Leakage Occurred Up To",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "EstimatedLeakage",
    title: "Estimated Leakage",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "DescriptionOfLeak",
    title: "Description of leak/Comments",
  },
  {
    field: "EnteredBy",
    title: "Entered By",
  },
  {
    field: "EnteredOn",
    title: "Entered On",
    format: DATETIME_FORMAT.DATETIME,
  },
];

export const colReportLeakage: IColumnFields[] = [
  {
    field: "DateTime",
    title: "Date & Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "Memo",
    title: "Memo",
  },
];
