import {
  BookmarkIcon,
  IBookmarkIconProps,
} from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/bookmark/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const ListViewBookmarkIcon = observer((props: IBookmarkIconProps) => {
  const {
    gridSelectedIds,
    isEmptyGridSelectedRow,
    gridSelectedRows,
    gridTotalSelected,
  } = useCCProductListViewStore();

  const propsBookmarkIcon = {
    ...props,
    gridSelectedIds: props.gridSelectedIds ?? gridSelectedIds,
    isEmptyGridSelectedRow,
    gridSelectedRows,
    gridTotalSelected: gridTotalSelected,
  };
  return <BookmarkIcon {...propsBookmarkIcon} />;
});
