import { CCDropDownCell } from "@app/products/property/components/grid/components/cc-dropdown-cell/_index";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colProduceLetter: IColumnFields[] = [
  {
    field: "Condition",
    title: "Condition",
    editable: false,
  },
  {
    field: "GraceDays",
    title: "Grace Days",

    format: NUMBER_FORMAT.NUMBER1,
    editable: true,
  },
  {
    field: "MiniumAmount",
    title: "Minium Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
  },
  {
    field: "IssueLetter",
    title: "Issue Letter",
    width: 150,
    editable: true,
    cell: CCDropDownCell,
  },
  {
    field: "ChangeStatusTo",
    title: "Change Status To",
    width: 150,
    editable: true,
    cell: CCDropDownCell,
  },
];
