import { DTO_ParcelLandUse } from "@app/products/property/parcels/[id]/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_ParcelLandUse>();

export const colParcelLandUses: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Land Use",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("IsActive"),
    title: "Is Active",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ChangedOn"),
    title: "Changed On",

    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ChangedBy"),
    title: "Changed By",
  },
];
