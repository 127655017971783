import { contactActiveRoute } from "@app/core/contacts/active/route";
import { contactAlertRoute } from "@app/core/contacts/alert/route";
import { contactChangeQueueRoute } from "@app/core/contacts/change-queue/route";
import { contactDeletedRoute } from "@app/core/contacts/deleted/route";
import { contactDuplicatesRoute } from "@app/core/contacts/duplicates/route";
import { contactGoldenContactsRoute } from "@app/core/contacts/golden-contacts/route";
import { contactVIPRoute } from "@app/core/contacts/vip/route";
import { ICCRoute } from "@common/constants/ICCRoute";

export const contactRoute: ICCRoute = {
  path: "contact",
  name: "Contacts",
  children: [
    contactActiveRoute,
    contactAlertRoute,
    contactDuplicatesRoute,
    contactDeletedRoute,
    contactVIPRoute,
    contactGoldenContactsRoute,
    contactChangeQueueRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./_id/_index").default,
    },
  ],
};
