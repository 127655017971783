import { apiGetBalancesOutstanding } from "@app/core/components/common/utils";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getSAPData = async (
  sapId?: string,
  includeDetails: boolean = false
): Promise<APIResponse<string> | undefined> => {
  if (isNil(sapId)) return;
  try {
    return await CoreAPIService.getClient().get(
      apiGetBalancesOutstanding(sapId, includeDetails)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
