import { EGenderAnimal } from "@app/products/animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<AnimalsRegister>();
export const animalsLookupList: IColumnFields[] = [
  {
    field: nameOf("TagNo"),
    title: "Tag Number",
  },
  {
    field: nameOf("MicrochipNo"),
    title: "Chip Number",
  },
  {
    field: nameOf("RegistrationNumber"),
    title: "Registration Number",
  },
  { field: nameOf("AnimalOwner"), title: "Animal Owner" },
  {
    field: nameOf("SiteAddress"),
    title: "Registered Address",
  },
  { field: nameOf("Locality"), title: "Locality" },
  { field: nameOf("Type"), title: "Animal Type" },
  { field: nameOf("Breed"), title: "Breed" },
  { field: nameOf("AnimalName"), title: "Name" },
  { field: nameOf("Colour"), title: "Colour" },
  { field: nameOf("Gender"), title: "Gender", filterByEnum: EGenderAnimal },
];
