import { useAssessmentModifyChargeDetailDialogStore } from "@app/products/property/assessments/components/dialogs/modify-charge-details/store";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const ChargeDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { assessmentModifyChargeDetailLOVs } =
      useAssessmentModifyChargeDetailDialogStore();
    return (
      <section className="cc-field-group cc-form-cols-1">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Charge name" />
            <Field
              name={nameOf("Name")}
              component={CCInput}
              placeholder="Charge name"
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Description" />
            <Field
              name={nameOf("Description")}
              component={CCTextArea}
              placeholder="Description"
              rows="3"
              readOnly={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Rating period" isMandatory />
            <Field
              name={nameOf("RatingPeriodId")}
              component={CCSearchComboBox}
              data={convertValueLOVToNumber(
                assessmentModifyChargeDetailLOVs?.RatingPeriod ?? []
              )}
              textField={"Name"}
              dataItemKey={"Code"}
              isUseDefaultOnchange
              disabled={options?.isReadOnly}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Charge date" />
            <Field
              name={nameOf("Date")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              disabled={options?.isReadOnly}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Charge code" />
            <Field
              name={nameOf("Code")}
              component={CCInput}
              placeholder="Charge code"
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel title="Instalment plan" />
          <Field
            name={nameOf("InstallmentPlanTypeId")}
            component={CCSearchComboBox}
            data={convertValueLOVToNumber(
              assessmentModifyChargeDetailLOVs?.InstalmentPlan ?? []
            )}
            textField={"Name"}
            dataItemKey={"Code"}
            isUseDefaultOnchange
            disabled={options?.isReadOnly}
          />
        </div>
      </section>
    );
  }
);
