import { CORE_INSPECTION_TYPES_ODATA } from "@app/core/components/common/constant";
import { EventTypeColumns } from "@app/products/event-management/[id]/components/child-screens/general/components/form-element/config";
import {
  bookingContactData,
  dropdownStatusData,
  parentPage,
} from "@app/products/event-management/[id]/components/child-screens/general/components/form-element/mock";
import { EventManagementFieldMapping } from "@app/products/event-management/constant";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { FormRoute } from "@common/constants/menu";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  requiredValidator,
  validateRequired,
} from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

export interface IEventManagementFormElementProps {
  formRenderProps: FormRenderProps;
}

export const EventManagementFormElement = observer(
  ({ formRenderProps }: IEventManagementFormElementProps) => {
    const isNew = useIsNew();
    const history = useHistory();
    const { pushNotification } = useCCAppNotificationStore();
    const [calendarFilter, setcalendarFilter] = useState({
      Meeting: false,
      Coffee: false,
      English: false,
      Oncall: false,
    });
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const getValue = formRenderProps.valueGetter;
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }

      if (!formRenderProps.valid) return;

      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    // INPUT PICKER REQUEST BY
    const navigateRequestedByToContactRelationship = () => {
      const preferredContact_rid = 220411; //TODO: Waiting value RID from API
      const isNull = !getValue("RequestedBy");
      if (isNull) {
        return history.push(
          `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${
            isNew ? "new" : preferredContact_rid
          }`,
          {
            flag_ContactFromNar: true,
            title: "Requested By",
          }
        );
      } else {
        return history.push(
          `${FormRoute.FORM_CONTACT_REPLATIONSHIP}/${preferredContact_rid}`,
          {
            parentPage,
            flag_ContactFromNar: true,
            title: "Requested By",
          }
        );
      }
    };

    const eventTypeFields = (formRenderProps: FormRenderProps) => ({
      isMultiSelect: true,
      titleHeader: "Pick Event Type(s)",
      placeholder: "Event Type(s)",
      tableTypes: EventTypeColumns,
      url: CORE_INSPECTION_TYPES_ODATA,
      disabled: true,
      fieldName: "EventTypes",
      value: formRenderProps.valueGetter("EventTypes")?.join(", "),
      onChange: (event: any) => {
        if (event.target !== null) {
          formRenderProps.onChange("", {
            value: event.target.value,
          });
        } else {
          formRenderProps.onChange("EventTypes", {
            value: event.detail.map((cate: any) => cate.Name),
          });
        }
      },
    });

    return (
      <FormElement
        onSubmit={formRenderProps.onSubmit}
        className="cc-event-management-form-element"
      >
        {/* Reference by a label in ActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Requested by
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                onClick={navigateRequestedByToContactRelationship}
                name={EventManagementFieldMapping.RequestedBy}
                validator={validateRequired}
                component={InputPicker}
                placeholder={"Requested by"}
              />
            </div>
            <CCValueField
              label="Contact details"
              value={getValue(
                EventManagementFieldMapping.RequestedBy_ContactDetails
              )}
            />
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Event description
                <CCTooltip type="validator" position="right" />
              </label>
              <Field
                name={EventManagementFieldMapping.Description}
                rows={8}
                placeholder="Description"
                value={getValue(EventManagementFieldMapping.Description)}
                validator={requiredValidator}
                component={CCTextArea}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                name={EventManagementFieldMapping.Status}
                dataItemKey="Key"
                textField="Value"
                data={dropdownStatusData}
                component={CCSearchComboBox}
                isUseDefaultOnchange
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Booking contact &nbsp;
                <Button
                  fillMode="flat"
                  className="cc-event-owner-button"
                  iconClass="fa fa-chevron-circle-down"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      getValue(EventManagementFieldMapping.RequestedBy)
                        ?.length > 0
                    ) {
                      formRenderProps.onChange(
                        EventManagementFieldMapping.BookingContact,
                        {
                          value: getValue(
                            EventManagementFieldMapping.RequestedBy_ContactDetails
                          ),
                        }
                      );
                      formRenderProps.onChange(
                        EventManagementFieldMapping.LastName,
                        {
                          value: bookingContactData.LastName,
                        }
                      );
                      formRenderProps.onChange(
                        EventManagementFieldMapping.FirstName,
                        {
                          value: bookingContactData.FirstName,
                        }
                      );
                      formRenderProps.onChange(
                        EventManagementFieldMapping.OrganisationName,
                        {
                          value: bookingContactData.OrganisationName,
                        }
                      );
                      formRenderProps.onChange(
                        EventManagementFieldMapping.Mobile,
                        {
                          value: bookingContactData.Mobile,
                        }
                      );

                      formRenderProps.onChange(
                        EventManagementFieldMapping.HomePhone,
                        {
                          value: bookingContactData.HomePhone,
                        }
                      );
                      formRenderProps.onChange(
                        EventManagementFieldMapping.WorkPhone,
                        {
                          value: bookingContactData.WorkPhone,
                        }
                      );
                      formRenderProps.onChange(
                        EventManagementFieldMapping.Email,
                        {
                          value: bookingContactData.Email,
                        }
                      );

                      formRenderProps.onChange(
                        EventManagementFieldMapping.BookingContact,
                        {
                          value: getValue(
                            EventManagementFieldMapping.RequestedBy_ContactDetails
                          ),
                        }
                      );
                    }
                  }}
                />
              </label>
              <Field
                name={EventManagementFieldMapping.BookingContact}
                placeholder={"Booking contact"}
                component={CCInput}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">First name</label>
              <Field
                name={EventManagementFieldMapping.FirstName}
                placeholder={"First name"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Last name</label>
              <Field
                name={EventManagementFieldMapping.LastName}
                placeholder={"Last name"}
                component={CCInput}
              />
            </div>
          </div>

          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Organisation name</label>
              <Field
                name={EventManagementFieldMapping.OrganisationName}
                placeholder={"Organisation name"}
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Mobile</label>
              <Field
                name={EventManagementFieldMapping.Mobile}
                placeholder={"Mobile"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.Mobile}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Home phone</label>
              <Field
                name={EventManagementFieldMapping.HomePhone}
                placeholder={"Home phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.HomePhone}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">Work phone</label>
              <Field
                name={EventManagementFieldMapping.WorkPhone}
                placeholder={"Work phone"}
                component={CCInputPhone}
                validator={validatePhoneNumber}
                onChangeForm={formRenderProps.onChange}
                skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                mode={EModePhoneNumber.WorkPhone}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <Field
                label="Email"
                name={EventManagementFieldMapping.Email}
                placeholder={"Email"}
                component={CCInputEmail}
                validator={validateEmail}
                onChangeForm={formRenderProps.onChange}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={EventManagementFieldMapping.DebtorNumber}
                placeholder={"Debtor number"}
                component={CCInput}
              />
            </div>

            <div className="cc-field">
              <label className="cc-label">File number</label>
              <Field
                name={EventManagementFieldMapping.FileNumber}
                placeholder={"File number"}
                component={CCInput}
              />
            </div>
            <CCValueField
              label="OS fees"
              value={getValue(EventManagementFieldMapping.OSFees)}
              format={CURRENCY_FORMAT.CURRENCY1}
            />
          </div>
          <div className="cc-form-cols-3">
            {!isNew && (
              <CCValueField
                label="Master facility"
                value={getValue(
                  EventManagementFieldMapping.RequestedBy_ContactDetails
                )}
              />
            )}
            {isNew && (
              <div className="cc-field">
                <label className="cc-label">Master facility</label>
                <Field
                  name={EventManagementFieldMapping.MasterFacility}
                  dataItemKey="Key"
                  textField="Value"
                  disabled={!isNew}
                  data={dropdownStatusData}
                  component={CCSearchComboBox}
                  isUseDefaultOnchange
                />
              </div>
            )}
          </div>
          {getValue(EventManagementFieldMapping.MasterFacility) && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Event type(s)</label>
                <Field
                  component={InputPicker}
                  {...eventTypeFields(formRenderProps)}
                  name={EventManagementFieldMapping.EventTypes}
                  validator={validateRequired}
                />
              </div>
            </div>
          )}
          {getValue(EventManagementFieldMapping.EventTypes) && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Event date(s)</label>
                <Field
                  disabled
                  onClick={() => {
                    setIsShowDialog(true);
                  }}
                  name={"EventDates"}
                  className="disabled"
                  component={InputPicker}
                />
              </div>
            </div>
          )}
        </section>

        {isShowDialog && (
          <CCDialog
            titleHeader={"Calendar Filter"}
            maxWidth="70%"
            height={"auto"}
            onClose={() => setIsShowDialog(false)}
            bodyElement={
              <div className="cc-form">
                <div className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <span>Pick Facilities to View in the Calendar:</span>
                  </div>
                  <div className="cc-form-cols-4">
                    <Checkbox
                      label={"Meeting room"}
                      onChange={(event: CheckboxChangeEvent) => {
                        setcalendarFilter({
                          ...calendarFilter,
                          Meeting: !calendarFilter.Meeting,
                        });
                      }}
                    />
                    <Checkbox
                      label={"Coffee house"}
                      onChange={(event: CheckboxChangeEvent) => {
                        setcalendarFilter({
                          ...calendarFilter,
                          Coffee: !calendarFilter.Coffee,
                        });
                      }}
                    />
                    <Checkbox
                      label={"English class"}
                      onChange={(event: CheckboxChangeEvent) => {
                        setcalendarFilter({
                          ...calendarFilter,
                          English: !calendarFilter.English,
                        });
                      }}
                    />
                    <Checkbox
                      label={"Oncall interview"}
                      onChange={(event: CheckboxChangeEvent) => {
                        setcalendarFilter({
                          ...calendarFilter,
                          Oncall: !calendarFilter.Oncall,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={() => {
                    history.push(`/managefacilitybookingcalendar/${"123"}`, {
                      calendarFilter: calendarFilter,
                    });
                  }}
                >
                  Open Calendar
                </Button>
                <Button
                  className="cc-dialog-button"
                  onClick={() => {
                    setIsShowDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            }
          />
        )}
      </FormElement>
    );
  }
);
