import { HMFieldMapping } from "@app/products/hm/model";

export const colHMFinancePaidFees = [
  {
    field: HMFieldMapping.ReferenceNumber,
    title: "Registration Number",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.TradingName,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.CreatedDate,
    title: "Recorded",
    width: 150,
  },
  {
    field: HMFieldMapping.FeeType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountIssued,
    title: "Issued",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountPaid,
    title: "Paid",
    width: 150,
  },
  {
    field: HMFieldMapping.ReceiptNumber,
    title: "Receipt Number",
    width: 150,
  },
  {
    field: HMFieldMapping.ReceiptDate,
    title: "Receipt Date",
    width: 150,
  },
  {
    field: HMFieldMapping.ReceiptedBy,
    title: "Receipted By",
    width: 150,
  },
  {
    field: HMFieldMapping.Method,
    title: "Method",
    width: 150,
  },
  {
    field: HMFieldMapping.CostCode,
    title: "Cost Code",
    width: 150,
  },
  {
    field: HMFieldMapping.InvoiceNumber,
    title: "Invoice Number",
    width: 150,
  },
  {
    field: HMFieldMapping.DebtorNumber,
    title: "Debtor Number",
    width: 150,
  },
  {
    field: HMFieldMapping.SecurePay_Reference,
    title: "Payment Reference",
    width: 150,
  },
];
