import { loadRegisterTagsHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/api";
import { colRegisterTagHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/config";
import { ITagHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<ITagHistory>();

export const RegisterTagHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tagHistoryData, setTagHistoryData] = useState<ITagHistory[]>([]);

  useEffectOnce(() => {
    setIsLoading(true);
    loadRegisterTagsHistory().then((response) => {
      if (!response?.TagsHistory || response.TagsHistory.length === 0) {
        setIsLoading(false);
        return;
      }
      setTagHistoryData(response.TagsHistory);
      setIsLoading(false);
    });
  });

  return (
    <div className="cc-register-tag-history">
      <CCGrid
        isLoading={isLoading}
        className="cc-register-tag-history-grid"
        data={tagHistoryData}
        columnFields={colRegisterTagHistory}
        primaryField={nameOf("Row")}
      />
    </div>
  );
};
