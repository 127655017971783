import { CRSFormData } from "@app/products/crs/model";
import { formatDateByKendo } from "@common/utils/formatting";
import { mockCRS } from "./mock";

export const getAllCRS = (): CRSFormData[] => {
  const records: any[] = JSON.parse(JSON.stringify(mockCRS));
  records.forEach((item: any) => {
    item.ChildDateOfBirth = formatDateByKendo(item.ChildDateOfBirth);
    item.DateofBirth = formatDateByKendo(item.DateofBirth);
    item.ChildDateOfBirth = formatDateByKendo(item.ChildDateOfBirth);
    item.CreatedDate = formatDateByKendo(item.CreatedDate);
    item.ModifiedDate = formatDateByKendo(item.ModifiedDate);
    item.Schedule = formatDateByKendo(item.Schedule);
    item.Activation = formatDateByKendo(item.Activation);
    item.ActivationDate = formatDateByKendo(item.ActivationDate);
    item.RegistrationDate = formatDateByKendo(item.RegistrationDate);
    item.DateOfBirth = formatDateByKendo(item.DateOfBirth);
    item.OfferLetterSentDate = formatDateByKendo(item.OfferLetterSentDate);
    item.DateOffered = formatDateByKendo(item.DateOffered);
    item.LodgementDate = formatDateByKendo(item.LodgementDate);
    item.DatePlaced = formatDateByKendo(item.DatePlaced);
    item.DaysReq = formatDateByKendo(item.DaysReq);
    item.DaysAlloc = formatDateByKendo(item.DaysAlloc);
    item.CancelledDate = formatDateByKendo(item.CancelledDate);
    item.Year = new Date(item.Year).getFullYear();
    item.CHILD_BIRTHDATE = formatDateByKendo(item.CHILD_BIRTHDATE);
    item.ALLOCATION_YEARATTENDING = formatDateByKendo(
      item.ALLOCATION_YEARATTENDING
    );
    item.Sys_CreatedDate = formatDateByKendo(item.Sys_CreatedDate);
    item.TYO_YearAttending = formatDateByKendo(item.TYO_YearAttending);
    item.AllocationYear = formatDateByKendo(item.AllocationYear);
    item.RecordedDate = formatDateByKendo(item.RecordedDate);
    item.DateTarget = formatDateByKendo(item.DateTarget);
  });
  return records;
};
