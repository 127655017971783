import { ICCRoute } from "@common/constants/ICCRoute";

export const contactAlertRoute: ICCRoute = {
  path: "alert",
  name: "Contact Alerts",
  children: [
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
    },
  ],
};
