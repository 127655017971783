import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { getVerifySPEAR } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/spear/spear-settings/api";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { requiredValidator } from "@common/utils/field-validators";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import "./_index.scss";

export type ISpearSettingsFormProps = {
  formRenderProps: FormRenderProps;
};

export const SpearSettingsForm = observer(
  ({ formRenderProps }: ISpearSettingsFormProps) => {
    const { valueGetter } = formRenderProps;
    const { configData = {} } = useSettingPPRStore();
    const { isEditing } = useSettingPPRManageStore();
    const { pushNotification, setNotifications } = useCCAppNotificationStore();
    const [isVerifying, setIsVerifying] = useState(false);
    const [echoMsg, setEchoMsg] = useState("This is SPEAR Echo Message...!");

    const fieldSPEARRef =
      configData[ECorporateSettingsField.TP_SPEAR.toString()];
    const fieldSPEAR =
      configData[ECorporateSettingsField.TP_SPEAR_Integration.toString()];
    const fieldSPEARLabel =
      configData[ECorporateSettingsField.TP_SPEAR_Label.toString()];
    const fieldSPEARWebServiceURL =
      configData[ECorporateSettingsField.TP_SPEAR_WebServiceURL.toString()];
    const fieldSPEARWebServiceCertPath =
      configData[
        ECorporateSettingsField.TP_SPEAR_WebServiceCertPath.toString()
      ];
    const fieldSPEARWebServiceCertPwd =
      configData[ECorporateSettingsField.TP_SPEAR_WebServiceCertPwd.toString()];
    const fieldSPEARA2AUsername =
      configData[ECorporateSettingsField.TP_SPEAR_A2A_Username.toString()];
    const fieldA2APwd =
      configData[ECorporateSettingsField.TP_SPEAR_A2A_Pwd.toString()];

    //Value Getter
    const isSPEARRef = valueGetter(ECorporateSettingsField.TP_SPEAR.toString());
    const isSPEAR = valueGetter(
      ECorporateSettingsField.TP_SPEAR_Integration.toString()
    );

    const handleVerifySPEAR = useCallback(
      (echoMessage: string) => {
        setIsVerifying(true);
        setNotifications([]);
        getVerifySPEAR(echoMessage).then((response) => {
          if (isSuccessResponse(response)) {
            pushNotification({
              type: response.data?.Success ? "success" : "error",
              title: response.data?.Success ? "Message" : "Alert",
              description: response.data?.Success
                ? response.data?.ResponseMessage
                : response.data?.ExceptionMessage,
              autoClose: false,
            });
          } else {
            pushNotification({
              type: "error",
              title: "Verify SPEAR failed",
              description: response.error,
              autoClose: false,
            });
          }
          setIsVerifying(false);
        });
      },
      [pushNotification, setNotifications]
    );

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={fieldSPEARRef?.Title ?? ""} />
            <Field
              name={fieldSPEARRef?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isSPEARRef}
            />
          </div>
          <div className="cc-field">
            <CCLabel title={fieldSPEAR?.Title ?? ""} />
            <Field
              name={fieldSPEAR?.FieldName}
              component={CCSwitch}
              disabled={!isEditing}
              checked={isSPEAR}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={fieldSPEARLabel?.Title ?? ""}
              isMandatory={fieldSPEARLabel.IsMandatory}
            />
            <Field
              name={fieldSPEARLabel?.FieldName}
              component={CCInput}
              disabled={!isEditing}
              validator={
                fieldSPEARLabel.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldSPEARWebServiceURL?.Title ?? ""}
              isMandatory={fieldSPEARWebServiceURL.IsMandatory}
            />
            <Field
              name={fieldSPEARWebServiceURL?.FieldName}
              component={CCInput}
              disabled={!isEditing}
              validator={
                fieldSPEARWebServiceURL.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        </div>
        <div className="cc-field">
          <CCLabel
            title={fieldSPEARWebServiceCertPath?.Title ?? ""}
            isMandatory={fieldSPEARWebServiceCertPath.IsMandatory}
          />
          {fieldSPEARWebServiceCertPath?.Description && (
            <label className="cc-settings-description">
              {sanitizeHtml(fieldSPEARWebServiceCertPath?.Description ?? "")}
            </label>
          )}
          <Field
            name={fieldSPEARWebServiceCertPath?.FieldName}
            component={CCInput}
            disabled={!isEditing}
            validator={
              fieldSPEARWebServiceCertPath.IsMandatory
                ? requiredValidator
                : undefined
            }
          />
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={fieldSPEARWebServiceCertPwd?.Title ?? ""}
              isMandatory={fieldSPEARWebServiceCertPwd.IsMandatory}
            />
            <Field
              name={fieldSPEARWebServiceCertPwd?.FieldName}
              component={CCInput}
              disabled={!isEditing}
              validator={
                fieldSPEARWebServiceCertPwd.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldSPEARA2AUsername?.Title ?? ""}
              isMandatory={fieldSPEARA2AUsername.IsMandatory}
            />
            <Field
              name={fieldSPEARA2AUsername?.FieldName}
              component={CCInput}
              disabled={!isEditing}
              validator={
                fieldSPEARA2AUsername.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
          <div className="cc-field">
            <CCLabel
              title={fieldA2APwd?.Title ?? ""}
              isMandatory={fieldA2APwd.IsMandatory}
            />
            <Field
              name={fieldA2APwd?.FieldName}
              component={CCInput}
              disabled={!isEditing}
              validator={
                fieldA2APwd.IsMandatory ? requiredValidator : undefined
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title={"Echo Message"} />
            <CCInput
              value={echoMsg}
              disabled={!isEditing}
              onChange={(event) => setEchoMsg(event.value)}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">&nbsp;</label>
            <div>
              <Button
                disabled={!isEditing || isVerifying}
                onClick={() => handleVerifySPEAR(echoMsg)}
                title="Echo Test"
                iconClass={isVerifying ? "fas fa-spinner fa-spin" : ""}
              >
                Echo Test
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
);
