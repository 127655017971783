import { KeyValuePair } from "@app/core/documents/model";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { BELL_CHARACTER } from "@common/constants/characters";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./index.scss";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter, onChange } = formRenderProps;
  const { configData } = useSettingGlobalStore();

  const data = useDataSetting(configData, [
    "CorporateSettings_Finance_CustomExportColumnCount_Oracle",
    "CorporateSettings_CustomFieldSeparator_Oracle",
    "CorporateSettings_Fee_CustomExportFields_Oracle",
    "CorporateSettings_Finance_CustomExportGroupColumn_Oracle",
  ]);

  //Get values
  const customisedFields: KeyValuePair<string, string>[] =
    valueGetter(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`
    ) ?? [];

  const numberOfFields = valueGetter(
    data.CorporateSettings_Finance_CustomExportColumnCount_Oracle
      ?.FieldName as string
  );
  const [rowCount, setRowCount] = useState<number>(numberOfFields);
  const [initCustomFieldsValues] =
    useState<KeyValuePair<string, string>[]>(customisedFields);

  //Handle events
  const processData = (
    valueList: KeyValuePair<string, string>[] | undefined
  ) => {
    let currentValue = "";
    if (valueList) {
      valueList.forEach(
        (item: KeyValuePair<string, string>) =>
          (currentValue +=
            item?.Key + BELL_CHARACTER + item?.Value + BELL_CHARACTER)
      );
    }
    return currentValue;
  };

  const handleChangeTitle = (index: number, event: InputChangeEvent) => {
    const clonedCustomisedFields = JSON.parse(JSON.stringify(customisedFields));

    if (!clonedCustomisedFields[index]) {
      clonedCustomisedFields[index] = {
        Key: "",
        Value: "",
      };
    }
    clonedCustomisedFields[index].Key = event.value?.toString() || "";
    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`,
      {
        value: clonedCustomisedFields,
      }
    );
    onChange(
      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`,
      {
        value: processData(clonedCustomisedFields),
      }
    );
  };

  const handleChangeDateFormat = (index: number, event: InputChangeEvent) => {
    const clonedCustomisedFields = JSON.parse(JSON.stringify(customisedFields));
    if (!clonedCustomisedFields[index]) {
      clonedCustomisedFields[index] = {
        Key: "",
        Value: "",
      };
    }
    clonedCustomisedFields[index].Value = event.value?.toString() || "";

    onChange(
      `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`,
      {
        value: clonedCustomisedFields,
      }
    );
    onChange(
      `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`,
      {
        value: processData(clonedCustomisedFields),
      }
    );
  };

  const renderCustomisedFields = () => {
    const tempArray = Array.from({ length: rowCount }, (_, index) => index);
    return tempArray.map((index: number) => {
      return (
        <div className="cc-flex-container">
          <div className="cc-index-col">
            <CCLabel title={`${index + 1}`} />
          </div>
          <div className="cc-content-col cc-finance-setting-cols-3 ">
            <div className="cc-field cc-custom-export-title-field">
              <Field
                name={`Title${index + 1}`}
                component={CCInput}
                disabled={!isEditing}
                defaultValue={initCustomFieldsValues[index]?.Key || ""}
                onChange={(event: InputChangeEvent) => {
                  handleChangeTitle(index, event);
                }}
              />
            </div>
            <div className="cc-field cc-custom-export-date-format-field">
              <Field
                name={`Data Format${index + 1}`}
                component={CCInput}
                disabled={!isEditing}
                min={0}
                defaultValue={initCustomFieldsValues[index]?.Value || ""}
                onChange={(event: InputChangeEvent) => {
                  handleChangeDateFormat(index, event);
                }}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  return configData ? (
    <div className="cc-form">
      <section className="cc-field-group">
        {data.CorporateSettings_CustomFieldSeparator_Oracle && (
          <InputGlobalSettings
            data={data.CorporateSettings_CustomFieldSeparator_Oracle}
            isEditing={isEditing}
          />
        )}
        {configData["ddlFinanceCustomAvailableExportFields_Oracle"] && (
          <div className="cc-form-cols-1 cc-form-cols-finance">
            <CCSearchComboBoxGlobalSettings
              data={configData["ddlFinanceCustomAvailableExportFields_Oracle"]}
              isEditing={isEditing}
            />
          </div>
        )}
        {data.CorporateSettings_Finance_CustomExportGroupColumn_Oracle && (
          <InputGlobalSettings
            data={data.CorporateSettings_Finance_CustomExportGroupColumn_Oracle}
            isEditing={isEditing}
          />
        )}
        <div>
          <CCLabel
            title={
              data.CorporateSettings_Finance_CustomExportColumnCount_Oracle
                ?.Title ?? ""
            }
          />
        </div>
        <div className="cc-flex-container">
          <div className="cc-index-col-empty"></div>
          {data.CorporateSettings_Finance_CustomExportColumnCount_Oracle && (
            <div className="cc-content-col cc-form-cols-3">
              <Field
                name={
                  data.CorporateSettings_Finance_CustomExportColumnCount_Oracle
                    .FieldName
                }
                component={CCNumericTextBox}
                min={0}
                max={100}
                format={NUMBER_FORMAT.NUMBER2}
                disabled={!isEditing}
                onChange={(event: NumericTextBoxChangeEvent) => {
                  onChange(
                    `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Finance_CustomExportColumnCount_Oracle}`,
                    {
                      value: event.value,
                    }
                  );
                  const clonedCustomisedFields = cloneDeep(customisedFields);
                  clonedCustomisedFields?.splice(event.value ?? 0);
                  onChange(
                    `option.ValueDisplayDetails_${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`,
                    {
                      value: clonedCustomisedFields,
                    }
                  );
                  onChange(
                    `${CORPORATE_SETTINGS_FIELD.CorporateSettings_Fee_CustomExportFields_Oracle}`,
                    {
                      value: processData(clonedCustomisedFields),
                    }
                  );
                }}
              />
              <div className="cc-field">
                <Button
                  className="cc-apply-button"
                  type="button"
                  disabled={!isEditing}
                  onClick={() => {
                    setRowCount(numberOfFields);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
        </div>
        {rowCount > 0 && (
          <>
            <div className="cc-flex-container">
              <div className="cc-index-col"></div>
              <div className="cc-content-col cc-finance-setting-cols-3">
                <div className="cc-field cc-custom-export-title-field">
                  <CCLabel title="Title" />
                  {data.CorporateSettings_Fee_CustomExportFields_Oracle
                    ?.Description && (
                    <label className="cc-settings-description">
                      {sanitizeHtml(
                        data.CorporateSettings_Fee_CustomExportFields_Oracle?.Description.split(
                          "|"
                        )[0]
                      )}
                    </label>
                  )}
                </div>
                <div className="cc-field">
                  <CCLabel title="Date format" />
                  {data.CorporateSettings_Fee_CustomExportFields_Oracle
                    ?.Description && (
                    <label className="cc-settings-description">
                      {sanitizeHtml(
                        data.CorporateSettings_Fee_CustomExportFields_Oracle?.Description.split(
                          "|"
                        )[1]
                      )}
                    </label>
                  )}
                </div>
              </div>
            </div>
            {renderCustomisedFields()}
          </>
        )}
      </section>
    </div>
  ) : null;
});
