import {
  IAmountsOwingAtCurrentDate,
  IAmountsOwingAtCurrentDateForm,
} from "@app/products/property/schemes/[id]/account/[id]/components/child-screens/balances/model";

export const mockAmountsOwingAtCurrentDate: IAmountsOwingAtCurrentDate[] = [
  {
    Description: "Overdue Balance",
    BalanceStatus: "Overdue",
    PrincipalAmount: 0,
    InterestRaised: 0,
    OtherCostsAmount: 0,
    DueAmounts: null,
    OverdueAmounts: 0,
    Pending: null,
    OriginalTotal: 0,
  },
  {
    Description: "Due Balance",
    BalanceStatus: "Due",
    PrincipalAmount: 8208.56,
    InterestRaised: 0,
    OtherCostsAmount: 0,
    DueAmounts: 8208.56,
    OverdueAmounts: null,
    Pending: null,
    OriginalTotal: 8208.56,
  },
  {
    Description: "Pending Balance",
    BalanceStatus: "Pending",
    PrincipalAmount: 0,
    InterestRaised: 0,
    OtherCostsAmount: 0,
    DueAmounts: null,
    OverdueAmounts: null,
    Pending: 0,
    OriginalTotal: 0,
  },
];

export const mockAmountsOwingAtCurrentDateForm: IAmountsOwingAtCurrentDateForm =
  {
    Estimated: 8208.56,
    Actual: null,
    PrincipalBalance: 8208.56,
    PrincipalDue: 8208.56,
    InterestBalance: 0.0,
    OtherBalance: 0.0,
    AmountOwing: 8208.56,
    Balance: 8208.56,
    WithheldInterestBalance: 0.0,
    Overdue: 0.0,
    Due: 8208.56,
    Pending: null,
    OverPayments: null,
    BalanceOwing: 8208.56,
    PayoutAmount: 8208.56,
    ShowPaidCharges: true,
  };
