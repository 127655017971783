import { useFeesStore } from "@app/core/fees/[id]/store";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const FinanceFeeDetailTab = observer(() => {
  const isNew = useIsNew();
  const { lastSelectedId } = useCCProductListViewStore();
  const { isLoadingDetails, responseDetailLoadError, loadDetails, financeFee } =
    useFeesStore();

  const { id } = useParams<{ id: string }>();
  const feesId: number = id ? parseInt(id) : lastSelectedId;

  useEffect(() => {
    if (!isNew && !id) loadDetails(feesId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesId]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseDetailLoadError) {
    return (
      <CCLoadFailed
        responseError={responseDetailLoadError}
        onReload={() => {
          feesId && loadDetails(feesId);
        }}
      />
    );
  }

  if (!financeFee) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Fee type:"}
          value={financeFee?.FinanceFee?.FeeType?.Name}
        />
        <ProductReferenceRow
          title={"Invoiced:"}
          value={formatDisplayValue(
            financeFee?.FinanceFee?.InvoicedDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Invoice number:"}
          value={financeFee?.FinanceFee?.InvoiceNumber}
        />
        <ProductReferenceRow
          title={"Debtor number:"}
          value={financeFee?.FinanceFee?.DebtorNumber}
        />
        <ProductReferenceRow
          title={"Exported to finance:"}
          value={formatDisplayValue(
            financeFee?.FinanceFee?.ExportedToFinance,
            BOOLEAN_FORMAT.BOOLEAN
          )}
        />
        <ProductReferenceRow
          title={"Amount:"}
          value={formatDisplayValue(
            financeFee?.FinanceFee?.Amount,
            CURRENCY_FORMAT.CURRENCY1
          )}
          format="currency"
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
