import { useRegisterTransactionsStore } from "@app/products/property/registers/[id]/components/child-screens/transactions/store";
import { AdjustAccountBalanceDialog } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/adjust-account-balance/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const AdjustAccountBalanceButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const { pushNotification } = useCCAppNotificationStore();
  const { selectedRegisterTransactions } = useRegisterTransactionsStore();
  return (
    <>
      <CCNavButton
        title="Adjust account balance"
        onClick={() => setIsShowDialog(true)}
        disabled={
          selectedRegisterTransactions &&
          selectedRegisterTransactions.length === 0
        }
      />

      {isShowDialog && (
        <AdjustAccountBalanceDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={() => {
            pushNotification({
              title: "The account balance adjusted successfully",
              type: "success",
            });
            setIsShowDialog(false);
          }}
        />
      )}
    </>
  );
});
