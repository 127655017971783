import { DTO_DeferredDuty } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/model";
import { useDebtRecoveryDeferredDuttyStore } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/store";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colDebtRecoveryDeferredDuty } from "./config";

const nameOf = nameOfFactory<DTO_DeferredDuty>();

export const DebtRecoveryDeferredDuty = observer(() => {
  const { isLoading, deferredDutty, loadDeferredDutty, resetStore } =
    useDebtRecoveryDeferredDuttyStore();
  const { debtRecoveryId } = useDebtRecoveryStore();

  useEffect(() => {
    if (debtRecoveryId) loadDeferredDutty(debtRecoveryId);
    return () => {
      resetStore();
    };
  }, [debtRecoveryId, loadDeferredDutty, resetStore]);

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              data={deferredDutty?.DebtRecoveryDeferredDuty ?? []}
              columnFields={colDebtRecoveryDeferredDuty}
              primaryField={nameOf("AssessmentId")}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
