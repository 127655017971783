import { IToAssessmentStepsLOVs } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/transfer-transaction/components/form-elements/to-assessments/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ToAssessmentStepStore {
  private _toAssessmentStepLOVs?: IToAssessmentStepsLOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get toAssessmentStepLOVs() {
    return this._toAssessmentStepLOVs;
  }
  setToAssessmentStepLOVs = (toAssessmentStepLOVs?: IToAssessmentStepsLOVs) => {
    runInAction(() => {
      this._toAssessmentStepLOVs = toAssessmentStepLOVs;
    });
  };
}

const toAssessmentStepStoreContext = createContext(new ToAssessmentStepStore());
export const useToAssessmentStepStore = () => {
  return useContext(toAssessmentStepStoreContext);
};
