import { useHistoryStack } from "@components/cc-form/navigation/components/history-stack/_index";
import { FormNavigationHelper } from "@components/cc-form/navigation/util";
import { Button } from "@progress/kendo-react-buttons";
import { isArray, map } from "lodash";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

interface IFormNavigationProps {
  title?: string;
  id?: string;
  badge?: number | string | (string | undefined)[];
}

// Common Title template
export const FormNavigation = ({ title, id, badge }: IFormNavigationProps) => {
  const history = useHistory();

  const renderBadge = () => {
    if (!badge) return "";
    if (isArray(badge) && badge.length) {
      return (
        <div className="cc-list-badge">
          {map(
            badge,
            (item: string | undefined) =>
              item && (
                <span key={item} className="cc-standard-badge">
                  {item}
                </span>
              )
          )}
        </div>
      );
    }
    return <span className="cc-standard-badge">{badge}</span>;
  };
  const backStack: Location[] = useHistoryStack() as unknown as Location[];

  const handleNavigation = useCallback(() => {
    if (FormNavigationHelper.hasExternalNavigation(backStack)) {
      return FormNavigationHelper.navigateToHome(history);
    }
    return history.goBack();
  }, [history, backStack]);

  return (
    <div className="cc-common-form-navigation">
      <Button
        className="cc-rounded-back-button"
        onClick={handleNavigation}
        iconClass="fal fa-arrow-left"
      />

      <h5 className="cc-form-title" id={id ?? ""}>
        {title?.toLocaleUpperCase()}
      </h5>
      {renderBadge()}
    </div>
  );
};
