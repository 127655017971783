import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { ChecklistQuestion } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/model";

import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import {
  isShowCustomAnswerType,
  isShowLabelIsHeading,
} from "@common/pages/settings/lookups/dynamic-lists/_id/util";
import { ANSWER_FIELD_TEMP_NAME } from "@common/pages/settings/lookups/dynamic-lists/constant";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import Loading from "@components/loading/Loading";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import { default as React, useMemo } from "react";

export const QUESTION_FORM_STEP = "QuestionStep";
export const QuestionStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const nameOf = nameOfFactory<ChecklistQuestion>();
const nameOfKeyValue = nameOfFactory<KeyValuePacket>();

const FormStepElement = observer(
  ({
    formRenderProps,
    options = {
      isDisabled: false,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const { isFirstLoadingCheckListQuestionDialog, checkListQuestionLovs } =
      useDynamicQuestionListsStore();

    const checklistAnswerType = valueGetter(nameOf("ChecklistAnswerType_ENUM"));
    const numberOfAnswersField = valueGetter(nameOf("NumberOfAnswersField"));

    if (isFirstLoadingCheckListQuestionDialog) {
      return <Loading isLoading={isFirstLoadingCheckListQuestionDialog} />;
    }

    const listAnswers = useMemo(() => {
      const listAnswerItems = [];
      if (numberOfAnswersField > 0) {
        for (let index = 0; index < numberOfAnswersField; index++) {
          listAnswerItems.push(
            <div className="cc-field" key={`answer_field_${index}`}>
              <CCLabel title={`${String(index + 1).padStart(2, "0")}.`} />
              <Field
                name={`${QUESTION_FORM_STEP}.${ANSWER_FIELD_TEMP_NAME}${index}`}
                component={CCInput}
                readOnly={options?.isDisabled}
              />
            </div>
          );
        }
      }
      return listAnswerItems;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfAnswersField]);

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Question" isMandatory />
            <Field
              name={nameOf("Question")}
              validator={requiredValidator}
              component={CCInput}
              readOnly={options?.isDisabled}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Select parent question" />
            <Field
              name={nameOf("ParentQuestionId")}
              dataItemKey={nameOfKeyValue("Key")}
              textField={nameOfKeyValue("Value")}
              data={checkListQuestionLovs?.ParentQuestion ?? []}
              component={CCSearchComboBox}
              isUseDefaultOnchange
              disabled={options?.isDisabled}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Answer type" isMandatory />
            <Field
              name={nameOf("ChecklistAnswerType_ENUM")}
              dataItemKey={nameOfKeyValue("Key")}
              textField={nameOfKeyValue("Value")}
              data={checkListQuestionLovs?.AnswerType ?? []}
              component={CCSearchComboBox}
              isUseDefaultOnchange
              validator={requiredValidator}
              disabled={options?.isDisabled}
            />
          </div>
        </div>
        {isShowCustomAnswerType(checklistAnswerType) && (
          <>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Number of answer fields" />
                <Field
                  name={nameOf("NumberOfAnswersField")}
                  component={CCNumericTextBox}
                  disabled={options?.isDisabled}
                  min={0}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Answer fields" />
              </div>
            </div>
            <div className="cc-form-cols-2">{listAnswers}</div>
          </>
        )}
        {isShowLabelIsHeading(checklistAnswerType) && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Use question as heading" />
              <Field
                name={nameOf("UseLabelAsHeading")}
                component={CCSwitch}
                checked={valueGetter(nameOf("UseLabelAsHeading"))}
                disabled={options?.isDisabled}
              />
            </div>
          </div>
        )}
      </section>
    );
  }
);
