import { KeyValuePair } from "@app/core/documents/model";
import {
  CS_RequestorType,
  ElementControl,
  LookupTable,
} from "@app/products/crms/[id]/model";
import { Contact_Relation } from "@app/products/waste-water/[id]/model";
import { ContactMethodPreferred } from "@common/constants/enumerations";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export enum PhoneMessageStatus {
  Default = 0,
  Draft = 1,
  Sent = 2,
  Completed = 3,
}

export enum PhoneMessageUpdateTriggers {
  SavePhoneMessage,
  ClearRequestedByExternal,
  UpdateRequestedByExternal,
}
export interface PhoneMessage extends DTOBaseClass_Standard {
  Comment: Comment;
  PhoneMessage_ID: number;
  Event_SeqId: number | null;
  Event_ReferenceNo: string;
  Event_ID: number | null;
  Status_ENUM: PhoneMessageStatus;
  Status_Name: string;
  Sender_SiteUser_ID: number;
  Sender_SiteUser_DisplayName: string;
  DateSent: Date | null;
  DateTarget: Date | null;
  DateForwarded: Date | null;
  DateCompleted: Date | null;
  ToRecipientsList: KeyValuePair<number, KeyValuePair<string, string>>[];
  ToRecipientsList_Names: string;
  CcList: KeyValuePair<number, KeyValuePair<string, string>>[];
  CcList_Names: string;
  BccList: KeyValuePair<number, KeyValuePair<string, string>>[];
  BccList_Names: string;
  ContactType_ENUM: CS_RequestorType;
  ContactType_Name: string;
  Contact_SiteUser_ID: number | null;
  Contact_SiteUser_DisplayName: string;
  Contact_SiteUser_Position: string;
  Contact_SiteUser_Hierarchy: string;
  Contact_SiteUser_OrgUnitNames: string[];
  Contact_Organisation: string;
  Contact_External: Contact_Relation;
  Contact_External_ID: number | null;
  Contact_External_RID: number | null;
  Contact_External_DisplayName: string;
  NewContact_Firstname: string;
  NewContact_Lastname: string;
  NewContact_Organisation: string;
  Contact_UnitNo: string;
  Contact_StreetNo: string;
  Contact_StreetName: string;
  Contact_Suburb: string;
  Contact_State: string;
  Contact_Postcode: string;
  WorkPhone: string;
  Mobile: string;
  HomePhone: string;
  Email: string;
  Facsimile: string;
  PreferredMethod_ENUM: ContactMethodPreferred | null;
  PreferredMethod_Name: string;
  PreferredMethod_Formatted: string;
  Flag_ReturnReceipt: boolean;
  MessageFlag_Names: string;
  MessageFlag_IDs: string;
  Flag_WebServiceRequest: boolean | null;
  Message: string;
  CallBackDays: number;
  CalculateTargetInHours: boolean | null;
  CallBackHours: number;
  LastComment_Send: string;
  SaveTriggers: PhoneMessageUpdateTriggers[];
}

export interface PhoneMessageLOVs {
  ContactType: LookupTable[];
  State: string[];
  PreferredMethod: IKeyValuePacket[];
  Flags: IKeyValuePacket[];
}

export enum Svc_PhoneMessageFormAction {
  SystemInitialise,
  Form_AddTo,
  Form_AddCc,
  Form_AddBcc,
  Form_ChangeContact,
  Form_ChangeContactType,
}

export interface PhoneMessageEventHandlerRequest {
  PhoneMessageFormAction: Svc_PhoneMessageFormAction;
  PhoneMessage: PhoneMessage;
  EventArgs: any;
  isFirstTimeLoad: boolean;
}

export interface PhoneMessageUIControl {
  LblContact: ElementControl;
  DivNewContact: ElementControl;
  DivNewContactAddress: ElementControl;
}

export interface PhoneMessageEventHandlerResponse {
  PhoneMessage: PhoneMessage;
  UIControl: PhoneMessageUIControl;
}

export interface ForwardOnRequest {
  RecipientId: number;
  CommentMessage: string;
  PhoneMessage: PhoneMessage;
}

export interface SendToActionOfficerRequest {
  CommentMessage: string;
  PhoneMessage: PhoneMessage;
}

export interface ShowNewPhoneMessageDialog {
  isShowDialog: boolean;
  isProductListView: boolean;
}
