import { BuildDialog_DateExtended } from "@app/core/components/dialogs/date-extended/model";
import { DateExtendedDialog } from "@app/core/components/dialogs/date-extended/_index";
import { putPSARExtendTargetDate } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/extend-target-date/api";
import { ExtendTargetDate } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/tools/extend-target-date/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const PSARExtendTargetDateButton = observer(() => {
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();
  const { ppr, pprId, setPPRWithLoading } = usePSARStore();
  const { settings } = useCommonProductStore();
  const [showExtendTargetDateDialog, setShowExtendTargetDateDialog] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [initialValues, setInitialValues] = useState<BuildDialog_DateExtended>(
    new BuildDialog_DateExtended()
  );

  const targetDaysEnabled = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_StatTargetDaysEnabled]
  );

  const handleOnClick = () => {
    setInitialValues({
      ...initialValues,
      SelectedDate: ppr?.StatDays.TargetDate ?? null,
      WindowTitle: "Extended Target Date",
      CommentCaption: "Reason for Extension",
      HasComment: true,
      MandatoryComment: true,
    });
    setShowExtendTargetDateDialog(true);
  };

  const handleOnSubmit = (data: BuildDialog_DateExtended) => {
    if (!pprId) return;
    setIsSubmitting(true);
    const reqBody: ExtendTargetDate = {
      TargetDate: data?.SelectedDate,
      Reason: data?.Comment,
    };
    putPSARExtendTargetDate(pprId, reqBody).then((response) => {
      setIsSubmitting(false);
      if (isSuccessIdentityPacket(response) && response.data?.Application) {
        setShowExtendTargetDateDialog(false);
        setPPRWithLoading(response.data.Application);
        clearNotifications();
        pushNotification({
          type: "success",
          title: "Target date extended successfully",
        });
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Extend target date failed",
            type: "error",
            description: response?.data?.Errors ?? response?.error,
          },
        ]);
      }
    });
  };

  return targetDaysEnabled ? (
    <>
      <CCNavButton title="Extend target date" onClick={handleOnClick} />
      {showExtendTargetDateDialog && (
        <DateExtendedDialog
          notifications={notifications}
          initialValues={initialValues}
          isSubmitting={isSubmitting}
          onClose={() => {
            setShowExtendTargetDateDialog(false);
            setNotifications([]);
          }}
          onSubmit={handleOnSubmit}
        />
      )}
    </>
  ) : null;
});
