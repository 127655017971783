import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colRegister: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.ApplicationNumber,
    title: "Number",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.PlanningApplicationNumber,
    title: "Application Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.SpearRef,
    title: "SPEAR Reference",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.POSNo,
    title: "PS Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.LodgedDate,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.SiteAddress,
    title: "Site Address",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Locality,
    title: "Locality",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Postcode,
    title: "Postcode",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ReasonForPermit,
    title: "Description of Proposal",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ReferredDate,
    title: "Referred",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.DecisionDate,
    title: "Application Decision",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.CertifiedDate,
    title: "Certified",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.RecertifiedDate,
    title: "Recertified",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.ComplianceDate,
    title: "Compliance",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.TitlesOffice,
    title: "Titles Office",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: TownPlanningFieldMapping.Applicant,
    title: "Applicant",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.ApplicantAddress,
    title: "Address",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Ward,
    title: "Ward",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Lots,
    title: "Lots",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.CommercialLots,
    title: "Commercial Lots",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.SpaceRequired,
    title: "Space Required",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Valuation,
    title: "Valuation",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.POSDue,
    title: "POS Due",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Estate,
    title: "Estate",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.Planner,
    title: "Planner",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.FileNo,
    title: "File Number",
    width: 150,
  },
  {
    field: TownPlanningFieldMapping.AssessmentNo,
    title: "Assess Number",
    width: 150,
  },
];
