import { EventManagement } from "@app/products/event-management/[id]/components/child-screens/general/model";
import { IEventManagementRegister } from "@app/products/event-management/[id]/model";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { sleep } from "@common/utils/common";
import { cloneDeep } from "lodash";
import { singleEventManagement } from "./mock";

export const getEventManagementById = async (
  id: number
): Promise<APIResponse<IEventManagementRegister | undefined>> => {
  await sleep(1000);
  const dataResponse = cloneDeep(singleEventManagement.data);
  if (dataResponse && id !== 9999) {
    return {
      data: dataResponse as IEventManagementRegister,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};

export const getEventManagementLovs = async () => {
  // const response: IResponseData = await getAsync(EventManagementUrl.GET_LOCALLAWS_LOVS);
  await sleep(1000);
  const response: IResponseData = cloneDeep(singleEventManagement);
  if (!response) {
    return undefined;
  }
  return response.data;
};

export const postEventManagement = async (requestBody: EventManagement) => {
  await sleep(2000);
};

export const putEventManagement = async (requestBody: EventManagement) => {
  await sleep(2000);
};
