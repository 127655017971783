import { INoticeTypeLov } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/model";
import { DTO_LOV_NoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NoticeRunNoticeTypeStepStore {
  private _noticeTypeLOVs?: INoticeTypeLov = undefined;
  private _dataSelectedNoticeType?: DTO_LOV_NoticeType = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get noticeTypeLOVs() {
    return toJS(this._noticeTypeLOVs);
  }
  setNoticeTypeLOVs = (noticeTypeLOVs?: INoticeTypeLov) => {
    runInAction(() => {
      this._noticeTypeLOVs = noticeTypeLOVs;
    });
  };

  get dataSelectedNoticeType() {
    return toJS(this._dataSelectedNoticeType);
  }
  setDataSelectedNoticeType = (data?: DTO_LOV_NoticeType) => {
    runInAction(() => {
      this._dataSelectedNoticeType = data;
    });
  };
}

const noticeRunNoticeTypeStepStoreContext = createContext(
  new NoticeRunNoticeTypeStepStore()
);
export const useNoticeRunNoticeTypeStepStore = () => {
  return useContext(noticeRunNoticeTypeStepStoreContext);
};
