export interface VO_Supplementary {
  IP_Name: string;
  ST_Is_Active: boolean | null;
  ST_Raise_Charges: boolean | null;
  ST_Allow_Levy_Changes: boolean | null;
  ST_Allow_Valuation_Changes: boolean | null;
  ST_Name: string;
  Amount_Journalled: number | null;
  No_of_Assessments: number | null;
  No_of_Valuations: number | null;
  Supp_Use_Existing_Instal_Plan: boolean | null;
  Def_Bal_Trf_Valuation_Type: number | null;
  Def_Supp_Balance_Trf_Method_Id: number | null;
  Supp_Enable_Levy_Calcs: boolean | null;
  Supp_Creation_UserId: string;
  Supp_Creation_DateTime: Date | null;
  Supp_Is_Complete: boolean | null;
  Supp_Notes: string;
  Supp_Date: Date | null;
  Supp_Reference: string;
  Supp_Name: string;
  Instalment_Plan_Id: number | null;
  Supplementary_Type: number | null;
  Supplementary_Id: number;
  Supp_VM2000_Id: number | null;
}

export enum SupplementaryMode {
  None = 0,
  CreateNew = 1,
  SelectFromList = 2,
  SelectFromMyList = 3,
}
