import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useSendToActionOfficerButtonStore } from "@common/pages/actions/components/action-bar/buttons/send-to-action-officer/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface ISendToActionOfficerButtonProps {
  disabled?: boolean;
}

export const SendToActionOfficerButton = observer(
  ({ disabled }: ISendToActionOfficerButtonProps) => {
    const { settings } = useCommonCoreStore();
    const { action, onSubmit, parent } = useActionStore();
    const { isLoading } = useSendToActionOfficerButtonStore();
    const [title, setTitle] = useState("");

    const sendToActionOfficerLabel = getStringValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Common_SendToActionOfficerLabel
      ]
    );

    const isRegulatoryAction = useMemo(() => {
      return (
        action?.ActionType &&
        action?.ActionType.IsRegulatoryAction &&
        action?.ActionType.IsRegulatoryAction === true
      );
    }, [action]);

    const isVisible = useMemo(() => {
      if (
        action?.Status_ENUM === ACTIONSTATUS.NEW &&
        !isRegulatoryAction &&
        action?.ActionRequired &&
        !action?.ActionType.IsApproval &&
        !action?.ActionType.Flag_IsMeeting
      )
        return true;
      return false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    useEffectOnce(() => {
      if (
        parent?.recordType === RECORDTYPE.CUSTOMERSERVICE_Event &&
        sendToActionOfficerLabel
      ) {
        setTitle(`Send to ${sendToActionOfficerLabel}`);
        return;
      }

      if (
        action?.ActionType &&
        (action?.ActionType.Flag_ExternalOfficer ||
          (action?.ActionType.Flag_IsReferral && action?.DateSent === null))
      ) {
        setTitle("Mark as sent");
      } else setTitle("Send to action officer");
    });

    return isVisible ? (
      <>
        <CCNavButton
          title={title}
          isLoading={isLoading}
          disabled={isLoading || disabled}
          onClick={onSubmit}
          name={ActionSubmitActions.SendToActionOfficer}
        />
      </>
    ) : null;
  }
);
