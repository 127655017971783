import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import {
  loadSearchComboBox,
  loadSearchComboBoxWithoutFilter,
} from "@components/cc-combobox-search-api/api";
import { ccComboBoxSearchApiConfigs } from "@components/cc-combobox-search-api/config";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
} from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { isString, trim } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

export interface IEventOnChangeComboBox extends ComboBoxChangeEvent {
  data?: any;
}
export interface IComboboxDDSearchAPIProps
  extends Omit<ComboBoxProps, "onChange"> {
  visited?: boolean;
  onError?: (value: any) => void;
  textProduce?: (value: any) => string | undefined;
  isLoadingParent?: boolean;
  urlAPI: string;
  keySearch: string;
  onChange: (event: IEventOnChangeComboBox | undefined) => void;
  isFilterable?: boolean;
  isClearedData?: boolean;
  setIsClearedData?: (value: boolean) => void;
  aliasSlider?: string;
  minCharsSearch?: number;
}

export const CCComboBoxSearchAPI = (props: IComboboxDDSearchAPIProps) => {
  const {
    isClearedData = false,
    setIsClearedData,
    visited,
    validationMessage,
    onChange,
    className,
    value,
    onError,
    textProduce,
    isLoadingParent,
    itemRender,
    urlAPI,
    dataItemKey,
    keySearch,
    data,
    allowCustom = false,
    textField = "Name", //By default "Name",
    isFilterable = true,
    aliasSlider,
    minCharsSearch,
    ...others
  } = props;
  const { cancelToken, cancelRequest } = useCancelRequest();
  const [dataSearch, setDataSearch] = useState<any>(data);

  const comboBoxValue = useMemo(() => {
    if (dataItemKey && dataSearch?.length) {
      return dataSearch?.find((obj: any) => obj[dataItemKey] === value) ??
        (allowCustom && isString(value))
        ? {
            [textField]: value,
            [dataItemKey]: value,
          }
        : null;
    }
    if (dataItemKey && allowCustom && isString(value)) {
      return {
        [textField]: value,
        [dataItemKey]: value,
      };
    }
    return value;
  }, [textField, dataItemKey, dataSearch, value, allowCustom]);

  const searchSlider = useFlexibleFetchData({
    alias: aliasSlider,
    cancelRequest,
    beforeFetch: ({ initialData }) => {
      const event = initialData as ComboBoxFilterChangeEvent;
      return {
        api: isFilterable
          ? loadSearchComboBox
          : loadSearchComboBoxWithoutFilter,
        param: [urlAPI, event.filter.value, keySearch, cancelToken()],
      };
    },
    slides: [
      {
        fetch: ({ dataFromBeforeFetch }) => {
          const { api, param } = dataFromBeforeFetch;
          return api.apply(null, param);
        },
        handleSuccess: ({ dataFromApi }) => {
          setDataSearch(dataFromApi?.data ?? []);
        },
        handleError: (errors) => {
          if (onError) onError(errors?.errorFromApi);
        },
        handleCancelRequest: () => {
          // clear value & dropdown data
          setDataSearch([]);
          onChange(undefined);
        },
      },
    ],
  });

  const handleSearch = (event: ComboBoxFilterChangeEvent) => {
    const searchText = trim(event.filter.value);
    const minCharacters =
      minCharsSearch ?? ccComboBoxSearchApiConfigs.minCharacters;
    if (searchText.length < minCharacters) return;
    setDataSearch([]);
    searchSlider.debounce(() => {
      searchSlider.fetchApi({ initialData: event });
    }, ccComboBoxSearchApiConfigs.typeSpeed);
  };

  const handleOnChange = (event: ComboBoxChangeEvent) => {
    if (!onChange) return;
    const newValue = event.value;
    if (!newValue) return onChange({ ...event, value: null });
    return onChange({
      ...event,
      value: newValue,
      data: dataSearch,
    } as any);
  };

  useEffect(() => {
    if (isClearedData) {
      setDataSearch([]);
      if (onChange) onChange(undefined);
      if (setIsClearedData) setIsClearedData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearedData]);
  return (
    <>
      <div className={`${className ?? ""}`}>
        <ComboBox
          {...others}
          allowCustom={allowCustom}
          textField={textField}
          filterable
          suggest
          data={dataSearch}
          itemRender={itemRender}
          loading={searchSlider.isFetching || isLoadingParent}
          onFilterChange={handleSearch}
          value={comboBoxValue}
          onChange={handleOnChange}
          popupSettings={{ className: "cc-picker-search-api" }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
};
