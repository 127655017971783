import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataAmalgamateAssessment,
  postProcessAmalgamateAssessment,
} from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/api";
import { AssessmentDetailsFormStep } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/assessment-details/_index";
import { AssociationFormStep } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/associations/_index";
import { PhysicalAddressGridFormStep } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/physical-address-grid/_index";
import { DetailsFormStep } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/components/form-elements/select-titles/_index";
import {
  DIFFTYPEERROR,
  DTO_Address,
  DTO_WorkflowDetail_Amalgamate_Assessment,
  DTO_Workflow_Amalgamate_Assessment,
  EKeysOfSteps,
  RequestAmalgamateAssessmentObj,
  VO_Assessment_GIS,
  keysOfSendSteps,
} from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { useAmalgamateAssessmentStore } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/store";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  Collection,
  DTO_Assessment_LOVs,
} from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { WorkflowGroupButtonsSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial";
import { IButtonSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import {
  getNotificationSpatialWorkflow,
  isDisabledApproveButton,
  isHiddenRejectButton,
  isSpatialGISMode,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  getWorkflowInstructions,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { useRefreshData } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useRefreshData/useRefreshData";
import {
  IListDialog,
  IProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { processDataLovInAmalgamate } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/util";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket, ResponsePacket } from "@common/models/identityPacket";
import { DTO_LOV } from "@common/models/odataResponse";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { getUUID } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { EBadgeDialogType, IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, head, isEmpty, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAmalgamateAssessmentDialogProps {
  onClose: () => void;
  assessmentID: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isSaveOnNextStep?: boolean;
  suffixTitle?: string;
  requestAmalgamateObj: RequestAmalgamateAssessmentObj;
}

export const AmalgamateAssessmentDialog = observer(
  ({
    onClose,
    assessmentID,
    dataFromActionList,
    prefixTitle,
    isSaveOnNextStep = false,
    suffixTitle,
    requestAmalgamateObj,
  }: IAmalgamateAssessmentDialogProps) => {
    // Store
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setIsLoadingOnDialog, setLOVsAmalgamateAssessment, resetStore } =
      useAmalgamateAssessmentStore();
    const { buttonMapping, buttonFetch, setButtonMapping, setButtonFetch } =
      useWorkflowGroupButtonsSpatialStore();
    const { isLLS } = CommunityProperty.getFlagOfStates();
    const {
      isFromActionList,
      isReadOnly,
      isShowCancelWorkflowButton,
      isShowReasonRejection,
      isIncompleteMode,
      statusBadge,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);

    // State
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isDifferentType, setIsDifferentType] = useState<boolean>(false);
    const [listIDs, setListIDs] = useState<number[]>(
      requestAmalgamateObj?.Assessment_Ids ?? []
    );
    const [amalgamateAssessmentInitData, setAmalgamateAssessmentInitData] =
      useState<DTO_Workflow_Amalgamate_Assessment>();
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    const { refreshPage } = useRefreshData(
      amalgamateAssessmentInitData?.WorkflowHeader
    );

    const initialValue = useMemo(() => {
      const workflowDetail = amalgamateAssessmentInitData?.WorkflowDetail;
      const workflowHeader = amalgamateAssessmentInitData?.WorkflowHeader;

      const officerInitData = officerUtilProcessing.processDataInit(
        amalgamateAssessmentInitData
      );

      // Step details
      let lovReason: DTO_LOV[] = workflowDetail?.LOVs?.ReasonForCreation ?? [];
      lovReason = convertValueLOVToNumber(lovReason ?? [], "Code") ?? [];
      let initDetails: any = {
        ...workflowDetail?.Details,
        ReasonForCreation: lovReason,
        Instructions: getWorkflowInstructions(
          workflowHeader?.WorkflowApprovals ?? []
        ),
        ...officerInitData,
      };

      // Step Associations
      let cloneAssociationAssessment: VO_Assessment_w_Detail[] = [];
      let assessmentNumber;
      // Get assessment number for assessment details step
      if (workflowDetail?.Associations?.Assessments?.length) {
        // BE not return the primary => handle by FE
        cloneAssociationAssessment =
          workflowDetail?.Associations?.Assessments?.map(
            (item: VO_Assessment_w_Detail) => {
              const isPrimary =
                item?.Assessment_Id ===
                workflowDetail?.Associations?.Primary_Assessment_Id;
              if (isPrimary) {
                assessmentNumber = item?.Assessment_Number;
              }
              return {
                ...item,
                Is_Primary: isPrimary,
              };
            }
          );
      }

      let initAssociation: any = {
        ...workflowDetail?.Associations,
        Assessments: cloneAssociationAssessment,
      };

      // Step Physical Address
      let initPhysicalAddress: any = [];
      let clonePhysicalAddresses: DTO_Address[] = [];
      if (workflowDetail?.PhysicalAddress?.Addresses?.length) {
        // BE not set the primary again => return number of the IsAddressPrimary > 1 but rule allow only one IsAddressPrimary => handle by FE
        // Add RowId for edit
        clonePhysicalAddresses =
          workflowDetail?.PhysicalAddress?.Addresses?.map((item) => ({
            ...item,
            RowId: getUUID(),
          }));
      }
      initPhysicalAddress = {
        ...workflowDetail?.PhysicalAddress,
        Addresses: [...clonePhysicalAddresses],
      };

      // Step Assessment Details
      let cloneDetailGIS: VO_Assessment_GIS[] = [];
      // Add RowId for edit
      if (workflowDetail?.AssessmentDetails?.Assessment_Gis_Interface?.length) {
        cloneDetailGIS =
          workflowDetail?.AssessmentDetails?.Assessment_Gis_Interface?.map(
            (item: VO_Assessment_GIS) => ({
              ...item,
              Id: getUUID(),
            })
          );
      }

      // get LOVs for assessment step
      let lovAssessmentDetail: any = {};
      const assessmentTypes =
        workflowDetail?.AssessmentDetails?.Assessment_Types;
      const assessmentGroups =
        workflowDetail?.AssessmentDetails?.Assessment_Groups;
      const differentialRateClassifications =
        workflowDetail?.AssessmentDetails?.Differential_Rate_Classifications;
      const lgas = workflowDetail?.AssessmentDetails?.LGAs;
      const primaryLandUses =
        workflowDetail?.AssessmentDetails?.Primary_Land_Uses;
      const secondaryLandUses =
        workflowDetail?.AssessmentDetails?.Secondary_Land_Uses;

      if (!isEmpty(assessmentTypes)) {
        lovAssessmentDetail.Assessment_Types =
          processDataLovInAmalgamate(assessmentTypes);
      }

      if (!isEmpty(assessmentGroups)) {
        lovAssessmentDetail.Assessment_Groups =
          processDataLovInAmalgamate(assessmentGroups);
      }

      if (!isEmpty(differentialRateClassifications)) {
        lovAssessmentDetail.Differential_Rate_Classifications =
          processDataLovInAmalgamate(differentialRateClassifications);
      }

      if (!isEmpty(lgas)) {
        lovAssessmentDetail.LGAs = processDataLovInAmalgamate(lgas);
      }

      if (!isEmpty(primaryLandUses)) {
        lovAssessmentDetail.Primary_Land_Uses =
          processDataLovInAmalgamate(primaryLandUses);
      }

      if (!isEmpty(secondaryLandUses)) {
        lovAssessmentDetail.Secondary_Land_Uses =
          processDataLovInAmalgamate(secondaryLandUses);
      }

      let initAssessmentDetail: any = {
        ...workflowDetail?.AssessmentDetails,
        Assessment_Gis_Interface: cloneDetailGIS,
        lovAssessmentDetail: lovAssessmentDetail,
        Assessment_Number: assessmentNumber,
        OfficerIsAdministrator: workflowHeader?.OfficerIsAdministrator,
      };

      //---step Secondary Workflow---
      const initSecondaryWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(
          amalgamateAssessmentInitData
        );

      return {
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.PhysicalAddress]: initPhysicalAddress,
        [EKeysOfSteps.AssessmentDetail]: initAssessmentDetail,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
        [EKeysOfSteps.SecondaryWorkflow]: initSecondaryWorkflow,
        [EKeysOfSteps.Collection]: collectionUtilProcessing.processDataInit<
          Collection[]
        >(amalgamateAssessmentInitData),
        [EKeysOfSteps.Association]: initAssociation,
      };
    }, [amalgamateAssessmentInitData]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue.Details,
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        isValid: false,
        options: {
          isReadOnly,
          isShowReasonRejection,
          isToBeApprovalMode,
          workflowDraftId,
        },
      },
      {
        label: "Related",
        initialValues: initialValue.Associations,
        component: AssociationFormStep,
        visible: true,
        key: EKeysOfSteps.Association,
        options: {
          isReadOnly,
          processAssessment: {
            isDifferentType,
            setIsDifferentType,
            listIDs,
          },
        },
      },
      {
        label: "Physical Address",
        initialValues: initialValue.PhysicalAddress,
        component: PhysicalAddressGridFormStep,
        visible: true,
        key: EKeysOfSteps.PhysicalAddress,
        options: {
          isReadOnly,
          isLLS,
          setIsLoadingDialog,
        },
      },
      {
        label: `${assessmentLabel} Details`,
        id: `cc-step-label-${ECustomColNameProperty.Assessment}`,
        initialValues: initialValue.AssessmentDetails,
        component: AssessmentDetailsFormStep,
        visible: true,
        key: EKeysOfSteps.AssessmentDetail,
        options: {
          isReadOnly,
          isLLS,
        },
      },
      {
        label: "Collections",
        initialValues: initialValue[EKeysOfSteps.Collection],
        component: CollectionFormStep,
        visible: true,
        key: EKeysOfSteps.Collection,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          componentId: assessmentID,
          workflowType: WorkflowTypes.Amalgamate_Assessment,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    const handleSubmit = (data: any, buttonId?: string, actions?: any) => {
      //reset notification before go next calling
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.resetNotifications();
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          sendSaveHolding(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          sendSaveHolding(
            processData(data),
            false,
            true,
            actions?.isShowNotification
          );
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          handleParkProcess(processData(data));
          break;
        case EListSubmitButton.MappingSpatialWorkflow:
          handleMappingSpatialProcess(processData(data));
          break;
        case EListSubmitButton.FetchSpatialWorkflow:
          handleFetchSpatialProcess(processData(data));
          break;
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessAmalgamateAssessment,
          dataReject: data,
          defaultSuccessMessage: `Amalgamate ${assessmentLabel.toLowerCase()} application was rejected successfully`,
          defaultErrorMessage: `Amalgamate ${assessmentLabel.toLowerCase()} reject failed`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessAmalgamateAssessment,
        dataSendBack: payload,
        defaultSuccessMessage: `Amalgamate ${assessmentLabel.toLowerCase()} approval sent back successfully`,
        defaultErrorMessage: `Send amalgamate ${assessmentLabel.toLowerCase()} back failed`,
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessAmalgamateAssessment,
        dataReallocate: payload,
        defaultSuccessMessage: `Amalgamate ${assessmentLabel.toLowerCase()} approved successfully`,
        defaultErrorMessage: `Approve amalgamate ${assessmentLabel.toLowerCase()} failed`,
      });
    };

    const processData: DTO_Workflow_Amalgamate_Assessment | any = (
      data: any
    ) => {
      const newData = cloneDeep(data);
      let workflowHeader: any = {
        ...amalgamateAssessmentInitData?.WorkflowHeader,
      };

      //Officer process data
      //field update: WD_Assessment_Group_Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(data, EKeysOfSteps.Details);
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;

      let workflowDetail: any = {};
      const sendSteps = pickBy(newData, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep;
        if (key === EKeysOfSteps.Collection) {
          dataStep = [...(value ?? [])];
        } else {
          dataStep = { ...value };
        }
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (dataStep?.BuildingUnitNumber === "") {
          dataStep.BuildingUnitNumber = null;
        }
        if (key === EKeysOfSteps.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[
            key as keyof DTO_WorkflowDetail_Amalgamate_Assessment
          ] = dataStep;
        }
        delete workflowDetail?.AssessmentDetails?.OfficerIsAdministrator;
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * handle approve process
     * * @param payload
     */
    const handleApproveProcess = (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_Amalgamate_Assessment> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            refreshPage(() =>
              pushNotification({
                title:
                  e?.Notification ??
                  `Amalgamate ${assessmentLabel.toLowerCase()} approved successfully`,
                type: "success",
              })
            );
          },
          defaultFailedMessage: `Approve amalgamate ${assessmentLabel.toLowerCase()} failed`,
          modeProcess: WorkflowProcessMode.Approve,
        };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (payload: DTO_Workflow_Amalgamate_Assessment) => {
      //set loading button and dialog
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_Amalgamate_Assessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
        setIsLoadingOnDialog(false);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(
            processData(payload, WorkflowProcessMode.Finish)
          ),
        confirmMessage: `The ${assessmentLabel.toLowerCase()} will be modified based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_Amalgamate_Assessment>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }
      const response = await postProcessAmalgamateAssessment(
        modeProcess,
        payload
      );

      if (isSuccessResponse(response)) {
        setLoading();
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
          //Show secondary workflow
          if (
            modeProcess === WorkflowProcessMode.Finish &&
            secondaryWorkflowSelectedList?.length
          ) {
            setListDialog(secondaryWorkflowSelectedList);
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_Amalgamate_Assessment> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                e?.Notification ??
                `Amalgamate ${assessmentLabel.toLowerCase()} created successfully`,
              type: "success",
            };
            refreshPage(() => pushNotification(notificationContent));
          },
          defaultFailedMessage: `Amalgamate ${assessmentLabel.toLowerCase()} failed`,
          modeProcess: WorkflowProcessMode.Finish,
        };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const sendSaveHolding = async (
      payload: DTO_Workflow_Amalgamate_Assessment,
      isCloseDialog: boolean = false,
      isRefreshWorkflowData: boolean = false,
      isShowNotification: boolean = true
    ) => {
      setIsLoadingOnNext(true);
      const response = await postProcessAmalgamateAssessment(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = `${assessmentLabel} saved successfully`;
      const defaultFailedMessage = `The amalgamate ${assessmentLabel.toLowerCase()} process could not be saved`;
      if (response.data?.ErrorMessage === DIFFTYPEERROR) {
        setIsDifferentType(true);
      }

      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // get new data from draftId after first save
            if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
              setAmalgamateAssessmentInitData({
                WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
                WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
              });
            }
          }
          // TODO: Show notification after reloading the step -> enhance later
          if (isRefreshWorkflowData) {
            notificationFormStepRef?.current?.setLoadingFormStep(true);
            if (isShowNotification) {
              getWorkflowData().then(() => {
                notificationFormStepRef?.current
                  ?.getNotificationFormStep()
                  ?.current?.pushNotification({
                    title:
                      response?.data?.Notification ?? defaultSuccessMessage,
                    type: "success",
                  });
              });
            } else {
              // After set primary, add new, remove in association step => need to call api 0
              setAmalgamateAssessmentInitData({
                WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
                WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
              });
              setListIDs(
                response.data?.ReturnData?.WorkflowDetail?.Associations
                  ?.Assessment_Ids ?? []
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    const handleNextButton = async (data: any) => {
      const newData = processData(data);
      return sendSaveHolding(newData);
    };

    const titleHeader = useMemo(() => {
      const formId =
        amalgamateAssessmentInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) ?? `Amalgamate ${assessmentLabel}`;

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          amalgamateAssessmentInitData?.WorkflowHeader?.WorkflowApprovals ?? []
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      amalgamateAssessmentInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id,
      amalgamateAssessmentInitData?.WorkflowHeader?.WorkflowApprovals,
      assessmentLabel,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
    ]);

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * Handle cancel process
     * @param data
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessAmalgamateAssessment,
          dataCancel: data,
          defaultSuccessMessage: `Amalgamate ${assessmentLabel.toLowerCase()} was cancelled successfully.`,
          defaultErrorMessage: `Amalgamate ${assessmentLabel.toLowerCase()} could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_Amalgamate_Assessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const requestAmalgamatePayload = {
        Assessments: requestAmalgamateObj?.Assessments,
        Assessment_Ids: requestAmalgamateObj?.Assessment_Ids,
      };
      return await getInitialDataAmalgamateAssessment(
        WorkflowTypes.Amalgamate_Assessment,
        requestAmalgamatePayload,
        id
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsAssessment, workflowData] = response;
          if (
            isSuccessResponse(lovsAssessment) &&
            isSuccessResponse(workflowData) &&
            lovsAssessment?.data &&
            workflowData?.data
          ) {
            setListIDs(
              workflowData?.data?.WorkflowDetail?.Associations
                ?.Assessment_Ids ?? []
            );
            // Set data lovs
            setLOVsAmalgamateAssessment(
              lovsAssessment.data?.dtoCreate_Assessment_LOVs
            );
            // Set data workflow
            setAmalgamateAssessmentInitData({
              WorkflowDetail: workflowData.data?.WorkflowDetail,
              WorkflowHeader: workflowData.data?.WorkflowHeader,
            });
            if (workflowData.data?.WorkflowHeader) {
              //Get notification for Spatial
              const notificationSpatial = getNotificationSpatialWorkflow(
                workflowData.data?.WorkflowHeader
              );
              if (!isNil(notificationSpatial)) {
                if (notificationSpatial?.mapping) {
                  setButtonMapping((preState: IButtonSpatial) => ({
                    ...preState,
                    ...notificationSpatial?.mapping,
                  }));
                }
                if (notificationSpatial?.fetch) {
                  setButtonFetch((preState: IButtonSpatial) => ({
                    ...preState,
                    ...notificationSpatial?.fetch,
                  }));
                }
              }
              setWorkflowDraftId(
                workflowData.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_Assessment_LOVs
              | DTO_Workflow_Amalgamate_Assessment
              | ResponsePacket
            > = lovsAssessment;
            if (!isSuccessResponse(workflowData)) {
              responseError = workflowData;
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    /**
     * handle mapping button at Spatial
     * Call 2 apis (Process, Get)
     * @param payload
     */
    const handleMappingSpatialProcess = async (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      //set loading dialog
      setIsLoadingDialog(true);
      //set info mapping
      setButtonMapping((preState: IButtonSpatial) => ({
        ...preState,
        isLoading: true,
      }));
      let mappingInfo: any = {};
      //Calling api process
      const responsePost = await postProcessAmalgamateAssessment(
        WorkflowProcessMode.Mapping,
        payload
      );
      if (isSuccessResponse(responsePost)) {
        const resSuccessPost =
          responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_Amalgamate_Assessment>;
        if (resSuccessPost?.IsSuccess) {
          //open new tab spatial
          window.open(responsePost?.data?.Gis_Mapping_Launch_Url, "_blank");
          mappingInfo = {
            isLoading: false,
            loadFail: undefined,
            loadSuccess: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            mappingInfo.loadSuccess = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.SUCCESS,
            };
          }
          setAmalgamateAssessmentInitData({
            WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
            WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
          });
        } else {
          mappingInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            mappingInfo.loadFail = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.ERROR,
            };
          }
          if (resSuccessPost?.ErrorMessage?.length) {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: resSuccessPost?.Notification?.length
                  ? resSuccessPost?.Notification
                  : "Mapping data failed",
                description: resSuccessPost?.ErrorMessage,
                type: "error",
                autoClose: false,
              });
          }
        }
      } else {
        //TODO: fix type for temporary
        const responseFail = responsePost?.data as any as IdentityPacket;
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (responseFail?.Errors?.length) {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: "Mapping data failed.",
              description: head(responseFail?.Errors),
              type: "error",
              autoClose: false,
            });
        }
      }
      setButtonMapping((preState: IButtonSpatial) => ({
        ...preState,
        ...mappingInfo,
      }));
      setIsLoadingDialog(false);
    };

    const handleFetchSpatialProcess = async (
      payload: DTO_Workflow_Amalgamate_Assessment
    ) => {
      //set loading dialog
      setIsLoadingDialog(true);
      //set info fetch
      setButtonFetch((preState: IButtonSpatial) => ({
        ...preState,
        isLoading: true,
        loadFail: undefined,
        loadSuccess: undefined,
      }));
      let fetchInfo: any = {};
      const responsePost = await postProcessAmalgamateAssessment(
        WorkflowProcessMode.Fetch,
        payload
      );
      if (isSuccessResponse(responsePost)) {
        const resSuccessPost =
          responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_Amalgamate_Assessment>;
        if (resSuccessPost?.IsSuccess) {
          fetchInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            fetchInfo.loadSuccess = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.SUCCESS,
            };
          }
          setAmalgamateAssessmentInitData({
            WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
            WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
          });
        } else {
          fetchInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            fetchInfo.loadFail = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.ERROR,
            };
          }
          if (resSuccessPost?.ErrorMessage?.length) {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: resSuccessPost?.Notification?.length
                  ? resSuccessPost?.Notification
                  : "Fetch data failed.",
                description: resSuccessPost?.ErrorMessage,
                type: "error",
                autoClose: false,
              });
          }
        }
      } else {
        //TODO: fix type for temporary
        const resFailPost = responsePost?.data as any as IdentityPacket;
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resFailPost?.Errors?.length) {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: "Fetch data failed.",
              description: head(resFailPost?.Errors),
              type: "error",
              autoClose: false,
            });
        }
      }
      setButtonFetch((preState: IButtonSpatial) => ({
        ...preState,
        ...fetchInfo,
      }));
      setIsLoadingDialog(false);
    };

    const newBadge = useMemo(() => {
      if (!isNil(statusBadge)) return statusBadge;
      let newStatus: undefined | IBadgeDialog[];
      if (isSpatialGISMode(amalgamateAssessmentInitData?.WorkflowHeader)) {
        newStatus = [];
        if (
          buttonMapping?.loadSuccess &&
          buttonMapping?.loadSuccess?.message?.messageTitle
        ) {
          newStatus.push({
            content:
              buttonMapping?.loadSuccess?.message?.messageTitle ??
              "Mapping in progress",
          });
        } else if (
          buttonMapping?.loadFail &&
          buttonMapping?.loadFail?.message?.messageTitle
        ) {
          newStatus.push({
            content:
              buttonMapping?.loadFail?.message?.messageTitle ??
              "Mapping in failed",
            type: EBadgeDialogType.ERROR,
          });
        }
        if (
          buttonFetch?.loadSuccess &&
          buttonFetch?.loadSuccess?.message?.messageTitle
        )
          newStatus.push({
            content:
              buttonFetch?.loadSuccess?.message?.messageTitle ??
              "Fetch data successful",
            type: EBadgeDialogType.SUCCESS,
          });
        else if (
          buttonFetch?.loadFail &&
          buttonFetch?.loadFail?.message?.messageTitle
        )
          newStatus.push({
            content:
              buttonFetch?.loadFail?.message?.messageTitle ??
              "Fetch data failed",
            type: EBadgeDialogType.ERROR,
          });
      }
      return newStatus;
    }, [
      statusBadge,
      amalgamateAssessmentInitData?.WorkflowHeader,
      buttonMapping,
      buttonFetch,
    ]);

    useEffectOnce(() => {
      getWorkflowData();
      return () => {
        resetStore();
      };
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              disabled={isLoadingApprove || isLoadingDialog || isLoadingOnNext}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              badge={newBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      amalgamateAssessmentInitData?.WorkflowHeader
                        ?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled ||
                              isDisabledApproveButton(
                                amalgamateAssessmentInitData?.WorkflowHeader
                              )
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          {isHiddenRejectButton(
                            amalgamateAssessmentInitData?.WorkflowHeader
                          ) ? null : (
                            <Button
                              themeColor="primary"
                              id={EListSubmitButton.Reject}
                              disabled={renderProps.nextButton.disabled}
                              className={"cc-dialog-button"}
                              onClick={renderProps.submitButton.onClick}
                            >
                              Reject
                            </Button>
                          )}
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  <div className="cc-dialog-footer-actions-left">
                    <WorkflowGroupButtonsSpatial
                      workflowHeader={
                        amalgamateAssessmentInitData?.WorkflowHeader
                      }
                      actionSubmit={renderProps.nextButton.onClick}
                    />
                  </div>
                </>
              }
            />
          );
        }}
      />
    );
  }
);
