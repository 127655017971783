import ReplaceMeterDialog from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/replace-meter/_index";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IReplaceMeterButtonProps {
  meterInfo: IMeterFormGeneral | undefined;
  onReplace: (value: IMeterFormGeneral) => void;
  disabled?: boolean;
}

export const ReplaceMeterButton = observer(
  ({ meterInfo, onReplace, disabled }: IReplaceMeterButtonProps) => {
    const [showReplaceMeterDialog, setShowReplaceMeterDialogs] =
      useState<boolean>(false);

    return (
      <>
        <CCNavButton
          title="Replace meter"
          onClick={() => setShowReplaceMeterDialogs(true)}
          disabled={disabled}
        />

        {showReplaceMeterDialog && (
          <ReplaceMeterDialog
            meterInfo={meterInfo}
            onClose={() => {
              setShowReplaceMeterDialogs(false);
            }}
            onReplace={(data: IMeterFormGeneral) => {
              onReplace(data);
              setShowReplaceMeterDialogs(false);
            }}
          />
        )}
      </>
    );
  }
);
