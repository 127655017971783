import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { getCommunicationTemplateTabDetailsById } from "@common/pages/settings/communication/template/_id/components/reference-sidebar/detail/api";
import { Svc_CommunicationTemplateTabDetails } from "@common/pages/settings/communication/template/_id/components/reference-sidebar/detail/model";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { formatDateByKendo } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const CommunicationTemplateTabDetails = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();
  const { communicationTemplate, communicationTemplateId } =
    useCommunicationTemplateStore();
  const [isLoading, setIsLoading] = useState(false);
  const [
    communicationTemplateTabDetailsInfo,
    setCommunicationTemplateTabDetailsInfo,
  ] = useState<Svc_CommunicationTemplateTabDetails>();
  const [responseLoadError, setResponseLoadError] =
    useState<APIResponseError>();
  const communicationTemplateID =
    communicationTemplateId ?? lastSelectedRow?.ID;

  const loadDetailData = async () => {
    setIsLoading(true);
    const response = await getCommunicationTemplateTabDetailsById(
      communicationTemplateID
    );
    if (isSuccessResponse(response)) {
      setCommunicationTemplateTabDetailsInfo(response.data);
    } else {
      setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!communicationTemplateID) return;
    loadDetailData();
    // eslint-disable-next-line
  }, [communicationTemplate, communicationTemplateID]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => loadDetailData()}
      />
    );
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Name:"}
          value={communicationTemplateTabDetailsInfo?.Name}
        />
        <ProductReferenceRow
          title={"Communication usage:"}
          value={communicationTemplateTabDetailsInfo?.CommunicationUsageName}
        />
        <ProductReferenceRow
          title={"Get data from:"}
          value={communicationTemplateTabDetailsInfo?.GetDataFrom}
        />
        <ProductReferenceRow
          title={"Created date:"}
          value={formatDateByKendo(
            communicationTemplateTabDetailsInfo?.CreatedDate
          )}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
