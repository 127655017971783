export const mockSearchAssessment: any[] = [
  {
    ScheduledReadDate: new Date("17-Aug-2017"),
    DateRead: new Date("13-Nov-2017 11:33"),
    SpecialReadType: "Change of Ownership",
    AssessmentNumber: 23300,
    PropertyAddress: "Wharf Res Huntleys Point Rd",
    Name: "S & VRigas",
    AssignedReadingDevice: "PDA1",
    ReadingOfficer: "1, Meter Reader",
    CreatedOn: new Date("17-Aug-2017 15:33"),
    CreatedBy: "JP505",
    AssessmentId: 657,
    MeterSpecialReadId: 12,
    Owners: "A L & J L LESTER",
  },
  {
    ScheduledReadDate: new Date("17-Aug-2017"),
    DateRead: new Date("13-Nov-2017 11:33"),
    SpecialReadType: "Change of Ownership",
    AssessmentNumber: 24300,
    PropertyAddress: "Wandella Avenue/14 Durham St",
    Name: "M & M BOGOVIC",
    AssignedReadingDevice: "PDA1",
    ReadingOfficer: "1, Meter Reader",
    JournalNumber: 6080,
    CreatedOn: new Date("17-Aug-2017 15:33"),
    CreatedBy: "JP505",
    AssessmentId: 658,
    MeterSpecialReadId: 12,
    Owners: "Wharf Res Huntleys Point Rd",
  },
];
