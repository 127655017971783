import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Schema_Acccount_ID
    ? `- ${selectedRow.Schema_Acccount_ID}`
    : "";
  return `Property - Deferred Duty ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Account_Number,
    selectedRow.RatePayer_Name_Address,
  ]);
};

export const getBookmarkListViewDisplayName = () => {
  return "Property - Deferred Duty";
};
