import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getSearchByURL = async (
  apiUrl: string,
  searchText: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      apiUrl.replace("[searchText]", `${searchText}`)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSearchOdata = async (
  dataUrl: string,
  limit: number,
  skip?: number
): Promise<APIResponse<any>> => {
  try {
    const extraParams = skip ? `$top=${limit}&$skip=${skip}` : `$top=${limit}`;
    return await CoreAPIService.getClient().get<any>(dataUrl + extraParams);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAllOdata = async (
  dataUrl: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(dataUrl);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCallBackDataByID = async (
  ID: number,
  apiUrlCallBackByID: string
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get(
      apiUrlCallBackByID.replace("[ID]", `${ID}`)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
