import { getDisplayTextWithDashes } from "@common/utils/common";

export const administrativeSiteUsers = {
  getBookmarkDetail(selectedRow: any) {
    return getDisplayTextWithDashes([
      selectedRow.ID,
      selectedRow.UserName + ", " + selectedRow.DisplayName,
    ]);
  },
  getBookmarkDisplayName(selectedRow: any) {
    const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
    return `Security - Administrative Site Users ${dynamicDisplayName}`;
  },
  getBookmarkListViewDisplayName() {
    return "Security - Administrative Site Users";
  },
  getBookmarkListViewDetail() {
    return "Security - Administrative Site Users";
  },
};
