import { HMFieldMapping } from "@app/products/hm/model";

export const colHMSystemAdminNCItems = [
  {
    field: HMFieldMapping.Identifier,
    title: "Identifier",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.Category_1,
    title: "Category 1",
    width: 150,
  },
  {
    field: HMFieldMapping.Category_2,
    title: "Category 2",
    width: 150,
  },
  {
    field: HMFieldMapping.Category_3,
    title: "Category 3",
    width: 150,
  },
  {
    field: HMFieldMapping.Category_4,
    title: "Category 4",
    width: 150,
  },
  {
    field: HMFieldMapping.Category_5,
    title: "Category 5",
    width: 150,
  },
  {
    field: HMFieldMapping.Category_6,
    title: "Category 6",
    width: 150,
  },
  {
    field: HMFieldMapping.ObservationSummary,
    title: "Observation Summary",
    width: 150,
  },
  {
    field: HMFieldMapping.ComplianceInstruction,
    title: "Compliance Instruction",
    width: 150,
  },
  {
    field: HMFieldMapping.AFSAQuestion,
    title: "AFSA",
    width: 150,
  },
  {
    field: HMFieldMapping.FoodActSection,
    title: "Legislation Section",
    width: 150,
  },
  {
    field: HMFieldMapping.FSANZClause,
    title: "Clause",
    width: 150,
  },
  {
    field: HMFieldMapping.FoodSafetyRatingQuestion_ID,
    title: "Assessment Question",
    width: 150,
  },
  {
    field: HMFieldMapping.Classification,
    title: "Inspection Type Classification",
    width: 150,
  },
];
