import { isSuccessResponse } from "@common/apis/util";
import { DBRowState } from "@common/models/baseClassStandard";
import { globalStoreInstance } from "@common/stores/global/store";
import {
  getBookmarksByUserId,
  postBookmark,
} from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/api";
import {
  IBookmark,
  IBookmarkItem,
} from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" }); // Strict mode: runInAction

class Bookmarker {
  private _bookmarks: IBookmark[] = [];
  private _bookmarksSelected: IBookmark[] = [];
  private _isLoading: boolean = false;
  private _isLoadingRemoveAll: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get bookmarks() {
    return toJS(this._bookmarks);
  }
  setBookmark = (bookmarks: IBookmark[]) => {
    runInAction(() => {
      this._bookmarks = bookmarks;
    });
  };

  get bookmarksSelected() {
    return this._bookmarksSelected;
  }
  setBookmarksSelected = (bookmarksSelected: IBookmark[]) => {
    runInAction(() => {
      this._bookmarksSelected = bookmarksSelected;
    });
  };

  get isLoading() {
    return toJS(this._isLoading);
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingRemoveAll() {
    return toJS(this._isLoadingRemoveAll);
  }
  setIsLoadingRemoveAll = (isLoadingRemoveAll: boolean) => {
    runInAction(() => {
      this._isLoadingRemoveAll = isLoadingRemoveAll;
    });
  };

  get isEmptyBookmark() {
    return this._bookmarks.length === 0;
  }

  get totalBookmark() {
    return this._bookmarks.length;
  }

  //Actions
  resetStore = () => {
    this._bookmarks = [];
    this._bookmarksSelected = [];
    this._isLoading = false;
    this._isLoadingRemoveAll = false;
  };

  saveBookmark = async (bookmarkList: IBookmarkItem[], isActive: boolean) => {
    this.setIsLoading(true);
    try {
      // All list has been bookmarked, change them to deactivate
      if (isActive) {
        const deactiveBookmarkList = bookmarkList.map((bookmark) => {
          const bookmarkIndex = this.bookmarks.findIndex(
            (bookmarkItem) => bookmarkItem.LinkUrl === bookmark.LinkUrl
          );
          if (bookmarkIndex !== -1) {
            return {
              ...bookmark,
              Sys_DBRowState: DBRowState.Inactive,
              Sys_TimeStamp: this.bookmarks[bookmarkIndex].Sys_TimeStamp,
            };
          }
          return bookmark;
        });
        await postBookmark(deactiveBookmarkList);
      } else {
        //Filter page hasn't been bookmarked => bookmark them
        const newBookmarks = bookmarkList.filter(
          (item: IBookmarkItem) =>
            !this.bookmarks.some(
              (bookmark: IBookmark) => item.LinkUrl === bookmark.LinkUrl
            )
        );
        await postBookmark([...newBookmarks]);
      }
    } catch (error) {
      console.error(error);
    }
    if (globalStoreInstance?.currentUserInfo)
      await this.loadBookmarks(globalStoreInstance?.currentUserInfo?.UserPKID);
    this.setIsLoading(false);
  };

  loadBookmarks = async (userId: string) => {
    this.setIsLoading(true);
    const response = await getBookmarksByUserId(userId);
    if (isSuccessResponse(response)) {
      if (!response.data) return;
      this.setBookmark(response.data);
    } else {
      this.setBookmark([]);
    }
    this.setIsLoading(false);
  };

  removeBookmarks = async (bookmarks: IBookmark[], userId?: string) => {
    if (bookmarks.length === this._bookmarks.length) {
      this.setIsLoadingRemoveAll(true);
    }
    this.setIsLoading(true);
    try {
      if (!userId) return;
      const newBookmarks = bookmarks.map((item: IBookmark) => {
        return {
          ProductType_ENUM: item.ProductType_ENUM,
          RecordType_ENUM: item.RecordType_ENUM,
          Record_ID: item.Record_ID,
          User_ID: item.User_ID,
          Text: item.Text,
          LinkText: item.LinkText,
          LinkUrl: item.LinkUrl,
          Sys_TimeStamp: item.Sys_TimeStamp,
          Sys_DBRowState: DBRowState.Inactive,
        };
      });
      await postBookmark(newBookmarks);
      await this.loadBookmarks(userId);
    } catch (error) {
      console.error(error);
    }
    this.setIsLoadingRemoveAll(false);
    this.setIsLoading(false);
  };
}

const newBookmarkStoreContext = createContext(new Bookmarker()); //TODO: Rename when remove old store
export const useNewBookmarkStore = () => {
  return useContext(newBookmarkStoreContext);
};
