import { odataCoreGetHelpDocuments } from "@app/core/components/common/utils";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ICoreHelpDocument } from "@common/models/coreHelpDocuments";
import { LookupHelpDocumentTabHistory } from "@common/pages/settings/lookups/help-documents/_id/components/reference-sidebar/history/_index";
import { SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTE as SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTING } from "@common/pages/settings/lookups/help-documents/_id/constant";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@common/pages/settings/lookups/help-documents/util";
import { usePermissionStore } from "@common/pages/settings/store";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { SettingsActionBarNavDropdown } from "../../components/nav-dropdown/_index";
import { FormActionCheckPermission } from "../../constant";
import { settingsRoute } from "../../route";
import { colLookupsHelpDocuments } from "./config";

const nameOf = nameOfFactory<ICoreHelpDocument>();
const gridId = getUUID();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();
  const { isLoadingPermission, checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_HelpDocument,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const history = useHistory();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      PRODUCT_TYPE_NUMBER.Core
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();
    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTING}/new`);
        }}
        disabled={
          !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_NEW)
        }
      />,
      <DeleteButton
        primaryField="ID"
        recordType={RECORDTYPE.CORE_Link}
        refreshGridIds={[gridId]}
        disabled={
          !hasPermission || !checkPermissions(FormAction.CORE_ALLOW_DELETE)
        }
        gridId={gridId}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTINGS_LOOKUPS_HELP_DOCUMENTS_ROUTING}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_Link}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDetail={getBookmarkListViewDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "History",
        component: <LookupHelpDocumentTabHistory />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper
      isLoadingPermission={isLoadingPermission || isLoading}
    >
      <CCProductListView
        columnFields={colLookupsHelpDocuments}
        primaryField={nameOf("Attachment_ID")}
        dataUrl={odataCoreGetHelpDocuments()}
        state={{
          sort: [{ field: nameOf("RecordedDate"), dir: "asc" }],
        }}
        disabled={!hasPermission}
        gridId={gridId}
      />
    </LoadingPermissionWrapper>
  );
};
