import { useLocalLawsSettingStore } from "@app/products/local-laws/system-admin/settings/_category/store";
import { ICommonFormProps } from "@app/products/local-laws/system-admin/settings/_id/main/model";
import { useLocalLawsSettingManageStore } from "@app/products/local-laws/system-admin/settings/_id/store";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const { getConfigDataField } = useLocalLawsSettingStore();
  const { isEditing } = useLocalLawsSettingManageStore();

  const eDefaultSenderEmail =
    ECorporateSettingsField.LLP_Communication_DefaultSenderEmail_KeywordID;
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <ComboBoxGlobalSettings
          data={getConfigDataField(eDefaultSenderEmail)}
          isEditing={isEditing}
          onChange={(e: ComboBoxChangeEvent) => {
            onChange(`${eDefaultSenderEmail}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eDefaultSenderEmail}`),
            getConfigDataField(eDefaultSenderEmail)?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
