import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { loadSearchName } from "@app/products/property/components/fields/search-name/api";
import { searchNameConfig } from "@app/products/property/components/fields/search-name/config";
import { DTO_Entity } from "@app/products/property/components/fields/search-name/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { DuplicateContactButton } from "@app/products/property/contacts-central-names/list/components/dialogs/components/button/duplicate-contact/_index";
import { AssociationsLLSFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/associations-lls/_index";
import { AttributesFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/_index";
import { ConcessionCardsFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/concession-cards/_index";
import { processConcessionCards } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/concession-cards/ultil";
import { ContactDetailFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/details/_index";
import { PersonFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/_index";
import { processPersonData } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/util";
import { PostalAndPhysicalAddressFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/_index";
import {
  EAddressStepperMode,
  EDeliveryOptions,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import {
  processAddressData,
  propertyFieldGroupedByDelivery,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/util";
import { VotingFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/voting/_index";
import {
  getInitialDataContact,
  postProcessNewContact,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/api";
import {
  defaultAddress,
  defaultAddressLLS,
  defaultPerson,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/config";
import {
  DTO_Create_Entity_LOVs,
  DTO_Entity_Concession_Card,
  DTO_Entity_LOVs,
  DTO_Entity_PostalAddress,
  DTO_Role,
  DTO_Workflow_CreateContact,
  EKeysOfNewContactSteps,
  EntityType,
  keysOfSendNewContactSteps,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { CommunityProperty } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { nameOfFactory } from "@common/utils/common";
import { addUniqueRowId } from "@common/utils/grid";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import axios, { CancelTokenSource } from "axios";
import { cloneDeep, head, isEmpty, isEqual, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface INewContactDialogProps {
  onClose: () => void;
  handleSubmitDialog: (entity: any, responseSuccess: any) => void;
  currentEntity?: any;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  suffixTitle?: string;
  isSaveOnNextStep?: boolean;
  isRedirectManagePage?: boolean;
  isOpenedFromParentWorkflow?: boolean;
}

interface SearchContactParams {
  Surname?: string;
  GivenName?: string;
  OrganisationName?: string;
  EntityType?: number;
}
const nameOfPostal = nameOfFactory<DTO_Entity_PostalAddress>();
const nameOfRole = nameOfFactory<DTO_Role>();
export const NewContactDialog = observer(
  ({
    onClose,
    currentEntity = defaultPerson,
    dataFromActionList,
    prefixTitle,
    suffixTitle,
    isRedirectManagePage = true,
    isSaveOnNextStep = false,
    handleSubmitDialog,
    isOpenedFromParentWorkflow = false,
  }: INewContactDialogProps) => {
    const {
      isReadOnly,
      isIncompleteMode,
      isShowCancelWorkflowButton,
      statusBadge,
      isFromActionList,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);
    // store
    const { currentFormTitle } = useCommonProductStore();
    const { isLLS, isActro, isGeneral } = CommunityProperty.getFlagOfStates();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();

    const history = useHistory();
    //state local
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    useState<boolean>(false);
    const [isDisableDialog, setIsDisableDialog] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowInitDataFromNew, setWorkflowInitDataFromNew] =
      useState<DTO_Workflow_CreateContact>();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0, WD_Workflow_Status: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const onChangeRef = useRef<any>();
    const timeOutRef = useRef<NodeJS.Timeout | null>(null);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [duplicateEntities, setDuplicateEntities] = useState<DTO_Entity[]>(
      []
    );
    const [searchDuplicate, setSearchDuplicate] = useState<SearchContactParams>(
      {
        Surname: undefined,
        GivenName: undefined,
        OrganisationName: undefined,
        EntityType: EntityType.Person,
      }
    );
    const [newContactLOVs, setNewContactLOVs] =
      useState<DTO_Create_Entity_LOVs>();
    const concessionCardLOV = useMemo(() => {
      return newContactLOVs?.ConcessionCards ?? [];
    }, [newContactLOVs]);
    const cancelRequest = useRef<CancelTokenSource>();

    /**
     * process title dialog
     */
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Create Contact";

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      workflowHeader,
      prefixTitle,
      suffixTitle,
      isToBeApprovalMode,
      workflowHeader?.WorkflowApprovals,
    ]);

    // Start initial value for workflow
    const initialValue = useMemo(() => {
      const officerInitData = officerUtilProcessing.processDataInit(
        workflowInitDataFromNew
      );
      let initDetails: any = {
        ...workflowInitDataFromNew?.WorkflowDetail?.Details,
        ...officerInitData,
      };
      let initEntityDetails: any = {
        ...defaultPerson,
        ...currentEntity,
      };
      let initPostalAddress: any = {
        ...(isLLS ? defaultAddressLLS : defaultAddress),
      };
      let initPhysicalAddress: any = {
        ...(isLLS ? defaultAddressLLS : defaultAddress),
      };
      let initAttributes: any = {};
      let initAssociations: any = {};
      let initConcessionCard: any = {};
      const concessionCardLOVs = [...concessionCardLOV];
      let workflowConcessionCards: DTO_Entity_Concession_Card[] = [];
      let initSecondaryWorkflow: any = {};
      initSecondaryWorkflow.WorkflowApprovals =
        workflowInitDataFromNew?.WorkflowHeader?.WorkflowApprovals;
      initSecondaryWorkflow.WD_Require_Approval =
        workflowInitDataFromNew?.WorkflowHeader?.WD_Require_Approval;
      if (workflowInitDataFromNew) {
        const workflowDetail = workflowInitDataFromNew?.WorkflowDetail;

        workflowConcessionCards = cloneDeep(
          workflowDetail?.Concession_Cards ?? []
        );
        initEntityDetails = {
          ...initEntityDetails,
          ...workflowDetail?.EntityDetails,
          _option: {
            Job_Description: {
              Job_Description: workflowDetail?.EntityDetails?.Job_Description,
            },
          },
        };
        initPostalAddress = {
          ...initPostalAddress,
          ...workflowDetail?.PostalAddress,
          _option: {
            Locality: {
              Locality_Code: workflowDetail?.PostalAddress?.Locality,
            },
            Street_Name: {
              Street: workflowDetail?.PostalAddress?.Street_Name,
            },
          },
          [propertyFieldGroupedByDelivery[
            workflowDetail?.PostalAddress?.Delivery as EDeliveryOptions
          ]]: workflowDetail?.PostalAddress?.Property_Name,
        };
        initPhysicalAddress = {
          ...initPhysicalAddress,
          ...workflowDetail?.PhysicalAddress,
          _option: {
            Locality: {
              Locality_Code: workflowDetail?.PhysicalAddress?.Locality,
            },
            Street_Name: {
              Street: workflowDetail?.PhysicalAddress?.Street_Name,
            },
          },
          [propertyFieldGroupedByDelivery[
            workflowDetail?.PhysicalAddress?.Delivery as EDeliveryOptions
          ]]: workflowDetail?.PhysicalAddress?.Property_Name,
        };
        newContactLOVs?.Type?.forEach((type) => {
          initAttributes[type.Code] = {
            ...workflowDetail?.Attributes,
            _option: {
              AlsoKnownAsNames:
                workflowDetail?.Attributes?.AlsoKnownAsNames?.join("\n") ?? "",
            },
          };
        });

        initAssociations = {
          Associated_Entities: addUniqueRowId(
            [...(workflowDetail?.Associated_Entities ?? [])],
            nameOfRole("Id")
          ),
        };

        //---step Secondary Workflow---
        initSecondaryWorkflow = secondaryWorkflowUtilProcessing.processDataInit(
          workflowInitDataFromNew
        );
      }
      const processConcessionCard = processConcessionCards(
        workflowConcessionCards,
        concessionCardLOVs,
        //If isReadOnly is true: completed workflow in view mode => don't need to map the lovs(setting status)
        !isReadOnly
      );
      initConcessionCard = {
        Concession_Cards: processConcessionCard ?? [],
      };
      if (workflowInitDataFromNew) {
        initSecondaryWorkflow["SecondaryWorkflow"] =
          workflowInitDataFromNew?.WorkflowHeader?.AvailableSecondaryWorkflows;
        setWorkflowHeader(workflowInitDataFromNew?.WorkflowHeader);
        initSecondaryWorkflow.WorkflowApprovals =
          workflowInitDataFromNew?.WorkflowHeader?.WorkflowApprovals;
        initSecondaryWorkflow.WD_Require_Approval =
          workflowInitDataFromNew?.WorkflowHeader?.WD_Require_Approval;
      }

      return {
        [EKeysOfNewContactSteps.Details]: initDetails,
        [EKeysOfNewContactSteps.Person]: initEntityDetails,
        [EKeysOfNewContactSteps.PostalAddress]: initPostalAddress,
        [EKeysOfNewContactSteps.PhysicalAddress]: initPhysicalAddress,
        [EKeysOfNewContactSteps.Attributes]: initAttributes,
        [EKeysOfNewContactSteps.ConcessionCards]: initConcessionCard,
        [EKeysOfNewContactSteps.Associations]: initAssociations,
        [EKeysOfNewContactSteps.Voting]: {},
        [EKeysOfNewContactSteps.Comments]: {},
        [EKeysOfNewContactSteps.Documents]: {},
        [EKeysOfNewContactSteps.SecondaryWorkflow]: initSecondaryWorkflow,
      };
      // eslint-disable-next-line
    }, [concessionCardLOV, currentEntity, workflowInitDataFromNew]);

    /**
     * Initial steps of workflow
     */
    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue[EKeysOfNewContactSteps.Details],
        component: ContactDetailFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.Details,
        options: {
          isReadOnly,
          isDisabled: workflowDraftId,
          contactLOVs: newContactLOVs,
          isUpdate: false,
        },
      },
      {
        label: "Contact",
        initialValues: initialValue.EntityDetails,
        component: PersonFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.Person,
        options: {
          isReadOnly,
          isLLS,
          contactLOVs: newContactLOVs,
          setIsDisableDialog,
          keyOfPostalStep: EKeysOfNewContactSteps.PostalAddress,
          onChangeRef,
        },
      },
      {
        label: "Physical Address",
        initialValues: initialValue[EKeysOfNewContactSteps.PhysicalAddress],
        component: PostalAndPhysicalAddressFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.PhysicalAddress,
        options: {
          isReadOnly,
          isLLS,
          contactLOVs: newContactLOVs,
          setIsDisableDialog,
          keyOfPersonStep: EKeysOfNewContactSteps.Person,
          keyOfRelatedAddressStep: EKeysOfNewContactSteps.PostalAddress,
          stepperMode: EAddressStepperMode.Physical,
          onChangeRef,
        },
      },
      {
        label: "Postal Address",
        initialValues: initialValue[EKeysOfNewContactSteps.PostalAddress],
        component: PostalAndPhysicalAddressFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.PostalAddress,
        options: {
          isReadOnly,
          isLLS,
          contactLOVs: newContactLOVs,
          setIsDisableDialog,
          keyOfPersonStep: EKeysOfNewContactSteps.Person,
          keyOfRelatedAddressStep: EKeysOfNewContactSteps.PhysicalAddress,
          stepperMode: EAddressStepperMode.Postal,
          onChangeRef,
        },
      },

      {
        label: "Attributes",
        initialValues: initialValue.Attributes,
        component: AttributesFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.Attributes,
        options: {
          isReadOnly,
          isActro,
          isLLS,
          contactLOVs: newContactLOVs,
        },
      },
      {
        label: "Related",
        initialValues: initialValue.Associated_Entities,
        component: AssociationsLLSFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.Associations,
        options: {
          isReadOnly,
          contactLOVs: newContactLOVs,
        },
      },
      {
        label: "Concession Cards",
        initialValues: initialValue.Concession_Cards,
        component: ConcessionCardsFormStep,
        visible: !isLLS,
        key: EKeysOfNewContactSteps.ConcessionCards,
        options: {
          isReadOnly,
          onChangeRef,
        },
      },
      {
        label: "Voting",
        component: VotingFormStep,
        initialValues: initialValue.VotingDetails,
        //Glenorchy (General) - Temporarily hidden, implement in the future
        visible: !isActro && !isLLS && !isGeneral,
        key: EKeysOfNewContactSteps.Voting,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Entity,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: !isLLS,
        key: EKeysOfNewContactSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Create_Entity,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfNewContactSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];
    //End initial value for workflow

    /**
     * process data payload
     * @param data
     * @returns
     */
    const processData = (data: any) => {
      let workflowDetail: any = {};
      // process workflow header to send the WD_Assessment_Group_Id/ Officer Region Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(data, EKeysOfNewContactSteps.Details);
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendNewContactSteps.includes(key as EKeysOfNewContactSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep: any = cloneDeep(value);
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        switch (key) {
          case EKeysOfNewContactSteps.Person:
            workflowDetail[key] = processPersonData(dataStep.Type, dataStep);
            break;
          case EKeysOfNewContactSteps.PostalAddress:
          case EKeysOfNewContactSteps.PhysicalAddress:
            workflowDetail[key] = processAddressData(
              dataStep?.[nameOfPostal("Delivery")],
              dataStep
            );
            break;
          case EKeysOfNewContactSteps.Attributes:
            const type = workflowDetail?.EntityDetails?.Type ?? 0;
            workflowDetail[key] = {
              ...(dataStep?.[type] ?? {}),
            };
            delete workflowDetail[key]._option;
            break;
          case EKeysOfNewContactSteps.Associations:
            workflowDetail[key] = value.Associated_Entities;
            break;
          case EKeysOfNewContactSteps.ConcessionCards:
            if (isLLS) {
              //Concessioncard is hidden in LLS
              workflowDetail[key] = [];
            } else {
              workflowDetail[key] = value[key];
            }
            break;
          case EKeysOfNewContactSteps.SecondaryWorkflow:
            secondaryWorkflowUtilProcessing.processData(
              value,
              workflowHeader,
              workflowDetail
            );
            break;
          default:
            workflowDetail[key as keyof DTO_Workflow_CreateContact] = dataStep;
        }
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_CreateContact>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessNewContact(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_CreateContact
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Approve);
      //props send to process workflow
      const approveProps: INewProcessWorkflow<DTO_Workflow_CreateContact> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.SuccessMessage ?? "Create contact was approved successfully.",
            description: e?.Notification,
            type: "success",
          });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Create contact could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps);
    };

    /**
     * handle save and next
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_CreateContact,
      keyStep?: string,
      isRefreshWorkflowData: boolean = false,
      isShowNotification: boolean = true
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);

      //Calling process Save at next button
      const response = await postProcessNewContact(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultSuccessMessage = "Create contact was saved successfully.";
      const defaultFailedMessage = "Create contact could not be saved.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
            description: response?.data?.Notification,
            type: "success",
          });
        } else {
          // process onChange data to update the form specific step
          if (keyStep && onChangeRef?.current) {
            const { onChange, nameOf } = onChangeRef.current;
            switch (keyStep) {
              case EKeysOfNewContactSteps.Person:
                onChange(nameOf(""), {
                  value: {
                    ...response?.data?.ReturnData?.WorkflowDetail
                      ?.EntityDetails,
                    _option: {
                      Job_Description: {
                        Job_Description:
                          response?.data?.ReturnData?.WorkflowDetail
                            ?.EntityDetails?.Job_Description,
                      },
                    },
                  },
                });
                break;
              case EKeysOfNewContactSteps.PostalAddress:
              case EKeysOfNewContactSteps.PhysicalAddress:
                onChange(nameOf(""), {
                  value: {
                    ...payload.WorkflowDetail[keyStep],
                    _option: {
                      Locality: {
                        Locality_Code:
                          payload.WorkflowDetail[keyStep]?.Locality,
                      },
                      Street_Name: {
                        Street: payload.WorkflowDetail[keyStep]?.Street_Name,
                      },
                    },
                    [propertyFieldGroupedByDelivery[
                      payload.WorkflowDetail[keyStep]
                        ?.Delivery as EDeliveryOptions
                    ]]: payload.WorkflowDetail[keyStep]?.Property_Name,
                  },
                });
                break;
              case EKeysOfNewContactSteps.ConcessionCards:
                onChange(nameOf("Concession_Cards"), {
                  value: payload.WorkflowDetail.Concession_Cards,
                });
                onChange(nameOf(`_option.SelectedConcessionCardHeld`), {
                  value: undefined,
                });
                break;
            }
          }
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            //set current workflowDraft Id
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
            // get new data from draftId after first save
            if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
              setWorkflowInitDataFromNew({
                WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
                WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
              });
            }
          }
        }
        // TODO: Show notification after reloading the step -> enhance later
        if (isRefreshWorkflowData) {
          notificationFormStepRef?.current?.setLoadingFormStep(true);
          if (isShowNotification) {
            getWorkflowData().then(() => {
              notificationFormStepRef?.current
                ?.getNotificationFormStep()
                ?.current?.pushNotification({
                  title: response?.data?.Notification ?? defaultSuccessMessage,
                  type: "success",
                });
            });
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        }
        return true;
      } else {
        const showNotification = () => {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title:
                (isRefreshWorkflowData
                  ? head(response?.data?.Errors)
                  : response.data?.ErrorMessage) ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        };
        if (isRefreshWorkflowData) {
          getWorkflowData().then(showNotification);
        } else {
          showNotification();
        }
        return false;
      }
    };

    /**
     * handle when hitting next button
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (
      data: DTO_Workflow_CreateContact,
      step: any,
      keyStep: string
    ) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload, keyStep);
    };

    /**
     * Handle cancel process
     */
    const handleCancelButton = async (data: DTO_Workflow_CreateContact) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessNewContact,
          dataCancel: data,
          defaultSuccessMessage: "Create contact was cancelled successfully.",
          defaultErrorMessage: "Create contact could not be cancelled.",
          customActionCloseWorkflow: isOpenedFromParentWorkflow
            ? onClose
            : undefined,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_CreateContact,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);

      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };

      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_CreateContact> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          !isOpenedFromParentWorkflow &&
            pushNotification({
              title:
                e?.data?.Notification ??
                "Create contact was parked successfully.",
              type: "success",
            });
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        actionClose: () => {
          mode === EListSubmitButton.ConfirmCloseYes &&
            actionCloseRetainDialog();
        },
        defaultFailedMessage: "Create contact could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };

    /**
     * handle close dialog
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (payload: DTO_Workflow_CreateContact) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);

      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_CreateContact> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          handleSubmitDialog && handleSubmitDialog(payload, e);
          !isOpenedFromParentWorkflow &&
            !isRedirectManagePage &&
            pushNotification({
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                "Contact created successfully.",
              type: "success",
            });
          isRedirectManagePage &&
            history.push(
              `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${e?.Component_ID}`,
              {
                notification: {
                  title:
                    e?.SuccessMessage ??
                    e?.Notification ??
                    "Contact created successfully.",
                  type: "success",
                },
              }
            );
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Contact could not be created.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };

    const handleConfirmFinish = async (payload: DTO_Workflow_CreateContact) => {
      const dataProcessed = processData(payload);
      setDataForFinishDialog({
        finishCallback: async () => {
          await handleFinishProcess(dataProcessed);
        },
        confirmMessage:
          "The contact will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    const handleSubmit = async (
      data: DTO_Workflow_CreateContact,
      buttonId?: string
    ) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data));
          break;
        case EListSubmitButton.SaveWorkflow:
          await handleSaveAndNext(processData(data), undefined, true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_CreateContact
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessNewContact,
        dataSendBack: payload,
        defaultSuccessMessage: "Create contact was sent back successfully.",
        defaultErrorMessage: "Create contact could not be sent back.",
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_CreateContact
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessNewContact,
        dataReallocate: payload,
        defaultSuccessMessage: "Create contact was reallocated successfully.",
        defaultErrorMessage: "Create contact could not be reallocated.",
      });
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessNewContact,
          dataReject: data,
          defaultSuccessMessage: "Create contact was rejected successfully.",
          defaultErrorMessage: "Create contact could not be rejected.",
        });
      } else {
        onClose();
      }
    };

    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      return await getInitialDataContact(WorkflowTypes.Create_Entity, id).then(
        (response) => {
          if (Array.isArray(response)) {
            const [lovsContact, workflowData] = response;
            if (
              isSuccessResponse(response[0]) &&
              isSuccessResponse(response[1]) &&
              lovsContact?.data &&
              workflowData?.data
            ) {
              //set Lovs data Contact
              setNewContactLOVs(lovsContact?.data?.dtoEntity_LOVs);
              setWorkflowInitDataFromNew({
                WorkflowDetail: workflowData?.data?.WorkflowDetail,
                WorkflowHeader: workflowData?.data?.WorkflowHeader,
              });
              if (workflowData?.data?.WorkflowHeader) {
                //set new contact InitData
                setWorkflowHeader(workflowData?.data?.WorkflowHeader);
                setWorkflowDraftId(
                  workflowData?.data?.WorkflowHeader?.WorkflowDraft
                    ?.Workflow_Draft_Id ?? 0
                );
              }
              notificationFormStepRef?.current?.setLoadingFormStep(false);
            } else {
              let responseError: APIResponse<
                DTO_Entity_LOVs | DTO_Workflow_CreateContact | ResponsePacket
              > = response[0];
              if (response[1] && !isSuccessResponse(response[1])) {
                responseError = response[1];
              }
              notificationFormStepRef?.current?.setLoadingFormStep(false);
              notificationFormStepRef?.current?.setLoadFailedFormStep({
                onReload: () => getWorkflowData(),
                responseError: {
                  status: responseError.status,
                  error:
                    (responseError.data as ResponsePacket)?.Errors ??
                    "Load workflow failed.",
                },
              });
            }
          } else {
            const responseError = response as APIResponse;
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error: "Load workflow failed.",
              },
            });
          }
        }
      );
    };
    useEffectOnce(() => {
      getWorkflowData();
    });

    //#region <Handle check duplicate entity>
    const checkDuplicateEntity = (searchContactParams: SearchContactParams) => {
      const isOrganisationSearch =
        searchContactParams.EntityType !== EntityType.Person &&
        searchContactParams.EntityType !== EntityType.DeceasedEstate;
      if (
        (!isOrganisationSearch &&
          !searchContactParams?.Surname &&
          !searchContactParams?.GivenName) ||
        (isOrganisationSearch && !searchContactParams?.OrganisationName)
      ) {
        setDuplicateEntities([]);
        return;
      }
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
      timeOutRef.current = setTimeout(() => {
        cancelRequest.current?.cancel("CancelSearching");
        cancelRequest.current = axios.CancelToken.source();
        setIsSearching(true);
        const surname =
          !searchContactParams?.Surname || isEmpty(searchContactParams.Surname)
            ? "{SURNAME}"
            : searchContactParams.Surname;
        const givenName =
          !searchContactParams?.GivenName ||
          isEmpty(searchContactParams.GivenName)
            ? "{GIVENNAME}"
            : searchContactParams.GivenName;
        const organisationName =
          !searchContactParams?.OrganisationName ||
          isEmpty(searchContactParams.OrganisationName)
            ? "{ORGANISATIONNAME}"
            : searchContactParams.OrganisationName;
        const searchString = isOrganisationSearch
          ? organisationName
          : `${givenName} ${surname}`;
        loadSearchName(
          searchString,
          isOrganisationSearch
            ? "do_OrganisationName_Search=true"
            : "do_EntityName_Search=true",
          cancelRequest.current
        ).then((response) => {
          setDuplicateEntities(response?.data?.Entities ?? []);
          if (response?.error !== "CancelSearching") {
            setIsSearching(false);
          }
        });
      }, searchNameConfig.typeSpeed);
    };

    const handleOnValueChange = (value: any) => {
      const surname = value?.[EKeysOfNewContactSteps.Person]?.Surname;
      const givenName = value?.[EKeysOfNewContactSteps.Person]?.GivenName;
      const organisationName =
        value?.[EKeysOfNewContactSteps.Person]?.Organisation_Name;
      const entityType = value?.[EKeysOfNewContactSteps.Person]?.Type;
      const newSearchObject: SearchContactParams = {
        Surname: surname,
        GivenName: givenName,
        OrganisationName: organisationName,
        EntityType: entityType,
      };
      if (!isEqual(newSearchObject, searchDuplicate)) {
        setSearchDuplicate(newSearchObject);
        checkDuplicateEntity(newSearchObject);
      }
    };
    //#endregion

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          onValueChange={handleOnValueChange}
          listButtonId={listSubmitButton}
          initialSteps={steps}
          initialValues={initialValue}
          saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              maxHeight="90%"
              titleHeader={titleHeader}
              disabled={isLoadingProcess !== undefined || isDisableDialog}
              onClose={() => handleCloseDialog(renderProps)}
              badge={statusBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Park
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled ||
                          isLoadingProcess === WorkflowProcessMode.Park
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!isReadOnly && (
                      <DuplicateContactButton
                        isSearching={isSearching}
                        duplicateContact={duplicateEntities}
                        onClose={onClose}
                        entityType={searchDuplicate.EntityType}
                      />
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={!isNil(isLoadingProcess) || isSearching}
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}
                    {isToBeApprovalMode &&
                      workflowHeader?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.nextButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingProcess ===
                                WorkflowProcessMode.Approve ||
                              renderProps.nextButton.disabled
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingProcess === WorkflowProcessMode.Approve
                                ? "fas fa-spinner fa-spin"
                                : ""
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reject}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.nextButton.onClick}
                          >
                            Reject
                          </Button>
                        </>
                      )}
                    {!renderProps.prevButton.disabled ? (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    ) : null}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingProcess === WorkflowProcessMode.Save ||
                            renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingProcess === WorkflowProcessMode.Save
                              ? "fas fa-spinner fa-spin"
                              : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingProcess === WorkflowProcessMode.Save
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          (isLoadingProcess === WorkflowProcessMode.Save &&
                            isSaveOnNextStep) ||
                          renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Save &&
                          isSaveOnNextStep
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingProcess === WorkflowProcessMode.Save &&
                        isSaveOnNextStep
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          )}
        />
      </>
    );
  }
);
