import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { SiteUser } from "@app/products/waste-water/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { postActionBatchUpdate } from "@common/pages/actions/api";
import { yieldNotificationPortal_ACTION_BATCH_UPDATE_DIALOG } from "@common/pages/actions/components/action-bar/dialog/batch-update-action/constant";
import { ActionBatchUpdate } from "@common/pages/actions/components/action-bar/dialog/batch-update-action/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCSwitch } from "@components/cc-switch/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IBatchUpdateActionDialogProps {
  actionsIds?: number[];
  onClose: () => void;
}

const nameOf = nameOfFactory<ActionBatchUpdate>();

export const BatchUpdateActionDialog = observer(
  ({ actionsIds, onClose }: IBatchUpdateActionDialogProps) => {
    const { pushNotificationPortal, clearNotifications } =
      useNotificationPortalStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    const [actionOfficerDisplayName, setActionOfficerDisplayName] =
      useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (event: FormSubmitClickEvent) => {
      const { values } = event;
      setIsLoading(true);
      const response = await postActionBatchUpdate(values as ActionBatchUpdate);
      setIsLoading(false);

      if (isSuccessResponse(response)) {
        onClose();
        pushNotificationPortal({
          title:
            "Action batch update has been queued to run. You will be emailed on completion.",
          type: "info",
        });
        clearSelectedItems();
      } else {
        pushNotificationPortal({
          title: "Action batch update failed",
          type: "error",
          autoClose: false,
          placeId: yieldNotificationPortal_ACTION_BATCH_UPDATE_DIALOG,
          description: response?.data?.Errors ?? response?.error,
        });
      }
    };

    const initialValues = useMemo(() => {
      return {
        SelectedRows: actionsIds,
        Update_ClosureDate: false,
        Update_ActionOfficer: false,
      };
    }, [actionsIds]);

    useEffectOnce(() => {
      clearNotifications();
    });

    return (
      <Form
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        onSubmitClick={handleSubmit}
        render={({
          onSubmit,
          modified,
          valid,
          onChange,
          valueGetter,
        }: FormRenderProps) => {
          const onChangeActionOfficer = (value: SiteUser) => {
            onChange(nameOf("ActionOfficer_ID"), {
              value: value?.Contact_ID ?? null,
            });
            setActionOfficerDisplayName(value?.DisplayName ?? "");
          };
          const isSubmited: boolean =
            modified &&
            (valueGetter(nameOf("Update_ActionOfficer")) ||
              valueGetter(nameOf("Update_ClosureDate")));

          return (
            <CCDialog
              maxWidth="45%"
              height="auto"
              titleHeader={`Action Batch Update - Actions Selected: ${actionsIds?.length}`}
              onClose={onClose}
              disabled={isLoading}
              yieldNotificationPortal={
                yieldNotificationPortal_ACTION_BATCH_UPDATE_DIALOG
              }
              bodyElement={
                <FormElement className="cc-form">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Update action officer" />
                        <Field
                          name={nameOf("Update_ActionOfficer")}
                          checked={
                            valueGetter(nameOf("Update_ActionOfficer")) ?? false
                          }
                          component={CCSwitch}
                        />
                      </div>
                      {valueGetter(nameOf("Update_ActionOfficer")) ? (
                        <div className="cc-field">
                          <CCLabel title="Action officer" isMandatory />
                          <Field
                            name={nameOf("ActionOfficer_ID")}
                            value={actionOfficerDisplayName}
                            placeholder="Action officer"
                            component={InputPickerSearch}
                            onChange={onChangeActionOfficer}
                            options={optionInputPickerOfficer}
                            validator={requiredValidator}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="cc-form-cols-3">
                      <div className="cc-field">
                        <CCLabel title="Update closure date" />
                        <Field
                          name={nameOf("Update_ClosureDate")}
                          checked={
                            valueGetter(nameOf("Update_ClosureDate")) ?? false
                          }
                          component={CCSwitch}
                        />
                      </div>
                      {valueGetter(nameOf("Update_ClosureDate")) ? (
                        <div className="cc-field">
                          <CCLabel title="Closure date" isMandatory />
                          <Field
                            name={nameOf("ClosureDate")}
                            component={CCDatePicker}
                            validator={requiredValidator}
                            format={DATE_FORMAT.DATE_CONTROL}
                          />
                        </div>
                      ) : null}
                    </div>
                  </section>
                </FormElement>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!isSubmited || !valid}
                    themeColor="primary"
                    iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
