import { ResponseMessage } from "@app/products/property/model";
import {
  DTO_Supplementary_MasterProperty_Valuation,
  MasterPropertyValuation,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/master-property-adjustments/components/valuations/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getMasterPropertyValuations = async (
  supplementaryId?: number,
  masterpropertyId?: number
): Promise<APIResponse<DTO_Supplementary_MasterProperty_Valuation[]>> => {
  try {
    return await CoreAPIService.getClient().get(
      `api/property/internal/supplementary/masterpropertyadjustment/valuation/${supplementaryId}/${masterpropertyId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const createMasterPropertyValuation = async (
  supplementaryId: number,
  masterpropertyId: number,
  payload: MasterPropertyValuation
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().post(
      `api/property/internal/supplementary/masterpropertyvaluation/${supplementaryId}/${masterpropertyId}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const updateMasterPropertyValuation = async (
  valuationId: number,
  payload: DTO_Supplementary_MasterProperty_Valuation
): Promise<APIResponse<ResponseMessage>> => {
  try {
    return await CoreAPIService.getClient().put(
      `api/property/internal/supplementary/masterpropertyvaluation/${valuationId}`,
      payload
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
