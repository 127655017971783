import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import { DTO_LOV_CertType } from "@app/products/property/certificates/[id]/components/form-steps/replace-certificate/model";

configure({ enforceActions: "always" });
class CertificateDetailStepStore {
  private _certificateDetailStepLOVs?: DTO_LOV_CertType[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get certificateDetailStepLOVs() {
    return toJS(this._certificateDetailStepLOVs);
  }
  setCertificateDetailStepLOVs = (data?: DTO_LOV_CertType[]) => {
    runInAction(() => {
      this._certificateDetailStepLOVs = data;
    });
  };
}

const certificateDetailStepStoreContext = createContext(
  new CertificateDetailStepStore()
);
export const useCertificateDetailStepStore = () => {
  return useContext(certificateDetailStepStoreContext);
};
