import { DTO_AssociatedItem_SchemeAccount } from "@app/products/property/components/associations/components/scheme-account/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_SchemeAccount>();

export const colAssociationsSchemeAccount: IColumnFields[] = [
  {
    field: nameOf("Association"),
    title: "Association",
  },
  {
    field: nameOf("BalanceOwing"),
    title: "Balance Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Scheme"),
    title: "Reference",
  },
  {
    field: nameOf("AccountReference"),
    title: "Account Reference",
  },
  {
    field: nameOf("AmountOwing"),
    title: "Amount Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PrincipalAmount"),
    title: "Principal Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PayoutAmount"),
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Discount"),
    title: "Discount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("AccountStatus"),
    title: "Account Status",
  },
  {
    field: nameOf("IsEstimate"),
    title: "Is Estimate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("Id"),
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
