import { VO_Street_Locality } from "@app/products/property/assessments/components/form-steps/new-assessment/components/street-search/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchStreetsConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

const nameOf = nameOfFactory<VO_Street_Locality>();

export const colSearchStreet: IColumnFields[] = [
  {
    field: nameOf("Street_Name_Locality"),
    title: "Street Name Locality",
    width: 250,
  },
];
