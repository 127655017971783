import { searchResultDataMock } from "@app/products/property/advanced_search/[id]/mock";
import { mockResultViewData } from "@app/products/property/advanced_search/components/action-bar/dialogs/new-search/components/form-elements/results/mock";
import { nameOfLov } from "@app/products/property/model";
import { getDropdownValue } from "@common/utils/common";
import { validateRequired } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

interface IArrangementProduceLetterDialog {
  onClose: () => void;
}

export const ChangeViewDialog = ({
  onClose,
}: IArrangementProduceLetterDialog) => {
  const initialView = {
    ListView: searchResultDataMock.ListView,
  };

  return (
    <Form
      initialValues={initialView}
      render={(formRenderProps: FormRenderProps) => (
        <CCDialog
          titleHeader={"Change Search Results View"}
          onClose={onClose}
          maxWidth="25%"
          maxHeight="20%"
          bodyElement={
            <div className="cc-new-arrangement cc-form">
              <FormElement className="cc-field-group">
                <div className="cc-form-cols-1">
                  <div className="cc-field">
                    <label className="cc-label">List view</label>
                    <Field
                      name="ListView"
                      component={CCSearchComboBox}
                      validator={validateRequired}
                      data={mockResultViewData}
                      textField={nameOfLov("Name")}
                      dataItemKey={nameOfLov("Code")}
                      value={getDropdownValue(
                        formRenderProps.valueGetter("ListView"),
                        mockResultViewData,
                        nameOfLov("Code")
                      )}
                      onChange={(event: ComboBoxChangeEvent) => {
                        formRenderProps.onChange("ListView", {
                          value: event.target.value?.Code ?? null,
                        });
                      }}
                    />
                  </div>
                </div>
              </FormElement>
            </div>
          }
          footerElement={
            <div className="cc-dialog-footer-actions-right">
              <Button className="cc-dialog-button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                className="cc-dialog-button"
                themeColor="primary"
                onClick={() => {
                  onClose();
                }}
                type={"submit"}
                disabled={!formRenderProps.valid}
              >
                OK
              </Button>
            </div>
          }
        />
      )}
    />
  );
};
