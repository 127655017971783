import { HMClosePremisesButton } from "@app/products/hm/premises/[id]/components/buttons/close-premises/_index";
import { HMPremisesRegisterButton } from "@app/products/hm/premises/[id]/components/buttons/register-premises/_index";
import { SavePremisesButton } from "@app/products/hm/premises/[id]/components/buttons/save/_index";
import { PremiseForm } from "@app/products/hm/premises/[id]/components/child-screens/general/_index";
import { TITLE_HM_PREMISES_MANAGE_PAGE } from "@app/products/hm/premises/[id]/constant";
import { IPremisesFormSecurity } from "@app/products/hm/premises/[id]/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const NewHMManagePremise = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const premise = dataForms?.GeneralForm;
  const parentSection = dataForms?.ParentSection as IPremisesFormSecurity;
  const allowRegisterPremisesButton = useMemo(() => {
    return parentSection ? parentSection.AllowRegisterPremisesButton : true;
  }, [parentSection]);
  const allowClosePremisesButton = useMemo(() => {
    return parentSection ? parentSection.AllowClosePremisesButton : true;
  }, [parentSection]);

  const workflowButtonMemorized = useMemo(() => {
    return (
      <CCNavButton
        title={"Workflow"}
        className="cc-workflow-button"
        type="sub-workflow"
      >
        <HMPremisesRegisterButton isDisabled={!allowRegisterPremisesButton} />
        <HMClosePremisesButton isDisabled={!allowClosePremisesButton} />
      </CCNavButton>
    );
  }, [allowRegisterPremisesButton, allowClosePremisesButton]);

  return (
    <>
      <FormTitle title={`New ${TITLE_HM_PREMISES_MANAGE_PAGE}`} />
      <CCManagePageActionBar
        leftActions={[workflowButtonMemorized, <SavePremisesButton />]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {premise && <PremiseForm />}
          </div>
        </div>
      </div>
    </>
  );
});
