import { IRecordsTypes } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/model";
import { RecordsTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/records-picker/_index";
import { ITrimLocation } from "@app/products/town-planning/ppr/[id]/components/input-picker/trim-location-picker/model";
import { TrimLocationPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/trim-location-picker/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface ITrimAttactmentDocumentType {
  formRenderProps: FormRenderProps;
}
const nameOfRecordType = nameOfFactory<IRecordsTypes>();
const nameOfTrimLocation = nameOfFactory<ITrimLocation>();
export const TrimAttactmentDocumentType = observer(
  ({ formRenderProps }: ITrimAttactmentDocumentType) => {
    const { isEditing } = useSettingGlobalManageStore();
    const { getConfigDataField } = useSettingGlobalStore();

    const { valueGetter, onChange } = formRenderProps;

    const fieldAttachmentRecordType = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentRecordType
    );
    const fieldTRIMDocumentType = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentHasDocumentType
    );
    const fieldTRIMDocumentTypeFieldName = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentDocumentTypeFieldName
    );
    const fieldTRIMDocumentTypeFieldValueLabel = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentDocumentTypeFieldValue
    );
    const fieldEnableRecordTypeSelection = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_EnableRecordTypeSelection
    );
    const fieldTRIMDefaultAddressee = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_DocumentAddressee
    );
    const fieldTRIMDefaultAuthor = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_DocumentAuthor
    );
    const fieldTRIMAuthorNameFormat = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AuthorNameFormat
    );
    const fieldrTitleFormat = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentTitleFormat
    );
    const fieldTRIMTitleLength = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_TitleLength
    );
    const fieldTRIMAttachmentTitleLength = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentTitleLength
    );
    const fieldTRIMLaunch = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_AttachmentLaunch
    );
    const fieldTRIMSaveCommunication = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_SaveCommunications
    );
    const fieldCommunicationRecordType = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_CommunicationRecordType
    );
    const fieldDefaultAttachType = getConfigDataField(
      ECorporateSettingsField.TRIMAdmin_DefaultAttachType
    );
    const attactmentRecordTypesValueDisplayDetails: IRecordsTypes[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_AttachmentRecordType}`
      ) ?? [];
    const communicationRecordTypesValueDisplayDetails: IRecordsTypes[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_CommunicationRecordType}`
      ) ?? [];
    const trimDefaultAddresseeDisplayDetails: ITrimLocation[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_DocumentAddressee}`
      ) ?? [];
    const trimDefaultDefaultAuthorDisplayDetails: ITrimLocation[] =
      valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_DocumentAuthor}`
      ) ?? [];

    const handleOnChangeAttactmentRecordTypes = (type: IRecordsTypes) => {
      onChange(fieldAttachmentRecordType?.FieldName ?? "", {
        value: type.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_AttachmentRecordType}`,
        {
          value: [type],
        }
      );
    };

    const handleOnChangeCommunicationRecordTypes = (type: IRecordsTypes) => {
      onChange(fieldCommunicationRecordType?.FieldName ?? "", {
        value: type.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_CommunicationRecordType}`,
        {
          value: [type],
        }
      );
    };

    const handleOnChangerimDefaultAddressee = (location: ITrimLocation) => {
      onChange(fieldTRIMDefaultAddressee?.FieldName ?? "", {
        value: location.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_DocumentAddressee}`,
        {
          value: [location],
        }
      );
    };

    const handleOnChangerimDefaultAuthor = (location: ITrimLocation) => {
      onChange(fieldTRIMDefaultAuthor?.FieldName ?? "", {
        value: location.ID.toString(),
      });
      onChange(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TRIMAdmin_DocumentAuthor}`,
        {
          value: [location],
        }
      );
    };

    return (
      <>
        {fieldAttachmentRecordType && (
          <div className="cc-field">
            <CCLabel
              title={fieldAttachmentRecordType?.Title ?? ""}
              isMandatory={fieldAttachmentRecordType.IsMandatory}
            />
            <Field
              name={fieldAttachmentRecordType?.FieldName ?? ""}
              placeholder={fieldAttachmentRecordType?.Title ?? ""}
              value={attactmentRecordTypesValueDisplayDetails[0] ?? null}
              component={RecordsTypesPicker}
              recordsTypeListData={
                fieldAttachmentRecordType?.ValueDisplayDetails &&
                fieldAttachmentRecordType?.ValueDisplayDetails.length > 0
                  ? fieldAttachmentRecordType?.ValueDisplayDetails
                  : []
              }
              disabled={!isEditing}
              selectableMode="single"
              onPickTypes={handleOnChangeAttactmentRecordTypes}
              textField={nameOfRecordType("Name")}
              textProduce={(data: IRecordsTypes) => data.Name}
            />
          </div>
        )}
        {fieldTRIMDocumentType && (
          <div className="cc-form-cols-1">
            <SwitchGlobalSettings
              data={fieldTRIMDocumentType}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
        )}

        {valueGetter(fieldTRIMDocumentType?.FieldName ?? "") && (
          <>
            {fieldTRIMDocumentTypeFieldName && (
              <div className="cc-form-cols-1">
                <CCSearchComboBoxGlobalSettings
                  data={fieldTRIMDocumentTypeFieldName}
                  isEditing={isEditing}
                />
              </div>
            )}

            {fieldTRIMDocumentTypeFieldValueLabel && (
              <div className="cc-form-cols-1">
                <InputGlobalSettings
                  data={fieldTRIMDocumentTypeFieldValueLabel}
                  isEditing={isEditing}
                />
              </div>
            )}
          </>
        )}

        {fieldEnableRecordTypeSelection && (
          <div className="cc-form-cols-1">
            <SwitchGlobalSettings
              data={fieldEnableRecordTypeSelection}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          </div>
        )}

        {/* Add Default Addressee Keyword picker
        Add Default Author Keyword picker */}
        {fieldTRIMDefaultAddressee && (
          <div className="cc-field">
            <CCLabel
              title={fieldTRIMDefaultAddressee?.Title ?? ""}
              isMandatory={fieldTRIMDefaultAddressee.IsMandatory}
            />
            <Field
              name={fieldTRIMDefaultAddressee?.FieldName ?? ""}
              placeholder={fieldTRIMDefaultAddressee?.Title ?? ""}
              value={trimDefaultAddresseeDisplayDetails[0] ?? null}
              component={TrimLocationPickerSearch}
              disabled={!isEditing}
              selectableMode="single"
              onPickTypes={handleOnChangerimDefaultAddressee}
              textField={nameOfTrimLocation("Name")}
              textProduce={(data: ITrimLocation) => data.Name}
            />
          </div>
        )}

        {fieldTRIMDefaultAuthor && (
          <div className="cc-field">
            <CCLabel
              title={fieldTRIMDefaultAuthor?.Title ?? ""}
              isMandatory={fieldTRIMDefaultAuthor.IsMandatory}
            />
            <Field
              name={fieldTRIMDefaultAuthor?.FieldName ?? ""}
              placeholder={fieldTRIMDefaultAuthor?.Title ?? ""}
              value={trimDefaultDefaultAuthorDisplayDetails[0] ?? null}
              component={TrimLocationPickerSearch}
              disabled={!isEditing}
              selectableMode="single"
              onPickTypes={handleOnChangerimDefaultAuthor}
              textField={nameOfTrimLocation("Name")}
              textProduce={(data: ITrimLocation) => data.Name}
            />
          </div>
        )}

        {fieldTRIMAuthorNameFormat && (
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={fieldTRIMAuthorNameFormat}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldrTitleFormat && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldrTitleFormat}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldTRIMTitleLength && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMTitleLength}
              isEditing={isEditing}
            />
          </div>
        )}

        {fieldTRIMAttachmentTitleLength && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMAttachmentTitleLength}
              isEditing={isEditing}
            />
          </div>
        )}

        <div className="cc-form-cols-3">
          {fieldTRIMLaunch && (
            <SwitchGlobalSettings
              data={fieldTRIMLaunch}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}

          {fieldTRIMSaveCommunication && (
            <SwitchGlobalSettings
              data={fieldTRIMSaveCommunication}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>

        {fieldCommunicationRecordType &&
          valueGetter(fieldTRIMSaveCommunication?.FieldName ?? "") && (
            <div className="cc-field">
              <CCLabel
                title={fieldCommunicationRecordType?.Title ?? ""}
                isMandatory={fieldCommunicationRecordType.IsMandatory}
              />
              <Field
                name={fieldCommunicationRecordType?.FieldName ?? ""}
                placeholder={fieldCommunicationRecordType?.Title ?? ""}
                value={communicationRecordTypesValueDisplayDetails[0] ?? null}
                component={RecordsTypesPicker}
                recordsTypeListData={
                  fieldCommunicationRecordType?.ValueDisplayDetails &&
                  fieldCommunicationRecordType?.ValueDisplayDetails.length > 0
                    ? fieldCommunicationRecordType?.ValueDisplayDetails
                    : []
                }
                isGetDetail={false}
                disabled={!isEditing}
                selectableMode="single"
                onPickTypes={handleOnChangeCommunicationRecordTypes}
                textField={nameOfRecordType("Name")}
                textProduce={(data: IRecordsTypes) => data.Name}
              />
            </div>
          )}

        {fieldDefaultAttachType && (
          <div className="cc-form-cols-1">
            <CCSearchComboBoxGlobalSettings
              data={fieldDefaultAttachType}
              isEditing={isEditing}
            />
          </div>
        )}
      </>
    );
  }
);
