import { MAILMERGESAVEFORMAT } from "@app/core/new-mail-merge/dialogs/model";
import { DBRowState } from "@common/models/baseClassStandard";
import { eBooleanType } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/model";
import { LookupDocumentFormElement } from "@common/pages/settings/lookups/documents/_id/components/child-screens/general/components/form-element/_index";
import {
  CCDocument,
  CCDocument_Ext,
  LookupDocumentActions,
} from "@common/pages/settings/lookups/documents/_id/model";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const LookupDocumentForm = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { document, saveDocument, setOnSubmit } = useLookupDocumentStore();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const action = event.event?.currentTarget.name as LookupDocumentActions;
    if (!(action in LookupDocumentActions)) return;

    if (!isValid) return;

    if (
      !isModified &&
      [LookupDocumentActions.Save, LookupDocumentActions.New].includes(action)
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    const data = values as CCDocument_Ext;
    const documentInfo = {
      ...values,
      Dataset_ENUM: data?.Dataset_ENUM_Int,
      Document_Usage_ENUM: data?.Document_Usage_ENUM_Int,
      SaveFormat_ENUM:
        data?._options?.SaveAsFormat === eBooleanType.TRUE
          ? MAILMERGESAVEFORMAT.Pdf
          : MAILMERGESAVEFORMAT.Default,
      WhenLoaded: new Date(),
      Sys_DBRowState:
        data?._options?.Availability === eBooleanType.TRUE
          ? DBRowState.Active
          : DBRowState.Inactive,
    } as CCDocument_Ext;

    if (documentInfo && documentInfo._options) {
      delete documentInfo._options;
    }

    saveDocument(documentInfo as CCDocument, action);
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, document]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={document}
        key={JSON.stringify(document)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <LookupDocumentFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
