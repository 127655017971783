import { RECORDTYPE } from "@common/constants/recordtype";
import { IParentActionSection } from "@common/pages/actions/model";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddReferralButton {
  id?: number;
  recordType: RECORDTYPE;
}

export const AddReferralButton = ({ id, recordType }: IAddReferralButton) => {
  const history = useHistory();

  return (
    <CCNavButton
      title={"Referral"}
      onClick={() => {
        history.push(`${ACTIONS_ROUTE}/new`, {
          parent: {
            id: id,
            recordType: recordType,
          } as IParentActionSection,
        });
      }}
    />
  );
};
