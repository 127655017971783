import { useDeferredDutyNoticeRunsStore } from "@app/products/property/deferred-duty/notice-runs/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { NoticeRunFormElement } from "./form-element/_index";

export const NoticeRunRequestForm = observer(() => {
  const { deferredDutyNoticeRuns } = useDeferredDutyNoticeRunsStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={deferredDutyNoticeRuns}
        render={(formRenderProps: FormRenderProps) => (
          <NoticeRunFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
