import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  checkProductActiveAndFlagCommunication,
  getManageHubURL,
} from "@app/products/crms/util";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActiveProduct } from "@components/layout/model";
import React from "react";
import { Link } from "react-router-dom";

export const getAnimalPath = (
  animalsRegistrationID: number,
  animalRef: string,
  productsActive: ActiveProduct[],
  siteBaseUrl: string
) => {
  if (
    !checkProductActiveAndFlagCommunication(
      productsActive,
      PRODUCT_TYPE.Animals
    )
  ) {
    if (siteBaseUrl.length > 0 && !siteBaseUrl.endsWith("/"))
      siteBaseUrl += "/";

    return (
      <a
        href={`${siteBaseUrl}${getManageHubURL(
          RECORDTYPE.Animals_Registration,
          animalsRegistrationID
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {animalRef}
      </a>
    );
  }

  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: `${ANIMALS_ROUTE}/${animalsRegistrationID}`,
      }}
    >
      {animalRef}
    </Link>
  );
};

export const getCRMSEventPath = (
  crmsEventID: number,
  crmsEventRef: string,
  productsActive: ActiveProduct[],
  siteBaseUrl: string
) => {
  if (
    !checkProductActiveAndFlagCommunication(
      productsActive,
      PRODUCT_TYPE.CustomerService
    )
  ) {
    if (siteBaseUrl.length > 0 && !siteBaseUrl.endsWith("/"))
      siteBaseUrl += "/";

    return (
      <a
        href={`${siteBaseUrl}${getManageHubURL(
          RECORDTYPE.CUSTOMERSERVICE_Event,
          crmsEventID
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {crmsEventRef}
      </a>
    );
  }

  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: `${CRMS_ROUTE}/${crmsEventID}`,
      }}
    >
      {crmsEventRef}
    </Link>
  );
};
