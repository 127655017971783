import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default ({ formRenderProps }: ICommonFormProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();

  if (!configData) return <></>;

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_ActionType_Tags
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_UseCommTemplate_SendtoActionOfficer
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_UseCommTemplate_ReturnToSender
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        {/* <div className="cc-form-cols-2">
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_UseCommTemplate_Closed
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
      </div> */}
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_EnableActionOutcomeGrouping
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField.CorporateSettings_Action_ShowActionOutcome
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_ShowDetailsForMeetings
            ]
          }
          // Justin disabled due to the conflict with CRMS logic, and it is no longer used
          disabled
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_EnableActionCompletionEmailAlert
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
        <SwitchGlobalSettings
          data={
            configData[
              ECorporateSettingsField
                .CorporateSettings_Action_AlwaysPinOpenActionWorkflowBar
            ]
          }
          formRenderProps={formRenderProps}
          isEditing={isEditing}
        />
      </div>
    </section>
  );
};
