import { CRMSPhoneMessageFormElement } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/_index";
import { CRMS_PHONE_MESSAGE_FLAGS } from "@app/products/crms/phone-messages/[id]/components/forms/components/child-screens/general/components/form-element/constant";
import { PhoneMessage } from "@app/products/crms/phone-messages/[id]/model";
import { useCRMSPhoneMessageStore } from "@app/products/crms/phone-messages/[id]/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const CRMSPhoneMessageForm = observer(() => {
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const {
    existCrmsPhoneMessage,
    saveCRMSPhoneMessage,
    setOnSubmit,
    setupCompleteMessageHandler,
    setIsShowCloseEventDialog,
    setIsShowCommentDialog,
  } = useCRMSPhoneMessageStore();
  const { pushNotification } = useCCAppNotificationStore();

  const handleSubmit = (event: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = event;
    const actionSubmit = event.event?.currentTarget.name as ActionSubmitActions;

    if (!(actionSubmit in ActionSubmitActions)) return;
    if (!isValid) return;
    if (!isModified && actionSubmit === ActionSubmitActions.Save) {
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    }

    let phoneMessageSaveObj = JSON.parse(JSON.stringify(values));
    delete phoneMessageSaveObj[`${CRMS_PHONE_MESSAGE_FLAGS}`];

    if (actionSubmit === ActionSubmitActions.Save)
      saveCRMSPhoneMessage(phoneMessageSaveObj as PhoneMessage);

    if (actionSubmit === ActionSubmitActions.CompleteMessage) {
      setIsShowCloseEventDialog(true);
      setupCompleteMessageHandler(phoneMessageSaveObj as PhoneMessage);
    }

    if (actionSubmit === ActionSubmitActions.ActionOfficerComment) {
      setIsShowCommentDialog(true);
    }
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleSubmit}
        initialValues={existCrmsPhoneMessage}
        key={JSON.stringify(existCrmsPhoneMessage)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <CRMSPhoneMessageFormElement
                  formRenderProps={formRenderProps}
                />
              }
            />
          );
        }}
      />
    </div>
  );
});
