import { colOwnerEntitledRebate } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/rebate_entitlements/components/detail/config";
import {
  DTO_Owners,
  DTO_Parcel,
  DTO_Title,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
interface IRebateEntitlementsDetailProps {
  data: any;
  errors: any;
  handleDataChange: (dataItem: any[], fieldName: string) => void;
  gridDetailRowProps: GridDetailRowProps;
  options?: any;
}

const nameOfParcel = nameOfFactory<DTO_Parcel>();
const nameOfTitle = nameOfFactory<DTO_Title>();
const nameOfOwner = nameOfFactory<DTO_Owners>();
export const SelectRebatesToBeEntitledDetail = ({
  data,
  errors,
  handleDataChange,
  gridDetailRowProps,
  options = {
    isReadOnly: false,
    isLLS: false,
    isActro: false,
    isWA: false,
    lotLabelName: "lot",
    parcelColumns: [],
    lotColumns: [],
  },
}: IRebateEntitlementsDetailProps) => {
  const {
    isReadOnly,
    isLLS,
    isActro,
    lotLabelName,
    parcelColumns,
    lotColumns,
  } = options;
  const { dataItem } = gridDetailRowProps;
  const { Lots, Parcels, Owners } = data;
  return (
    <section className="cc-field-group">
      {dataItem.RT_Is_ParcelBased && (
        <>
          {isLLS && (
            <div className="cc-field">
              <CCLabel
                title={`Which ${lotLabelName}(s) is the rebate to be applied`}
                isMandatory
                informationTooltip={`If the rebate entitled is ${lotLabelName} based at least one ${lotLabelName} must be selected`}
                errorMessage={errors?.[`Lots${dataItem.Rebate_Type_Id}`]}
              />
              <CCGrid
                data={Lots}
                primaryField={nameOfTitle("TitleId")}
                columnFields={lotColumns}
                readOnly={isReadOnly}
                editableMode="cell"
                onDataChange={(dataItem: DTO_Title[]) => {
                  handleDataChange(dataItem, "Lots");
                }}
              />
            </div>
          )}

          {isActro && (
            <div className="cc-field">
              <CCLabel
                title="Which parcel(s) is the rebate to be applied"
                isMandatory
                informationTooltip={`If the rebate entitled is ${lotLabelName} based at least one parcel must be selected`}
                errorMessage={errors?.[`Parcels${dataItem.Rebate_Type_Id}`]}
              />
              <CCGrid
                data={Parcels}
                primaryField={nameOfParcel("Parcel_Id")}
                columnFields={parcelColumns}
                readOnly={isReadOnly}
                editableMode="cell"
                onDataChange={(dataItem: DTO_Parcel[]) => {
                  handleDataChange(dataItem, "Parcels");
                }}
              />
            </div>
          )}
        </>
      )}

      <div className="cc-field">
        <CCLabel
          title="Which owners/life tenants are entitled to receive the rebate"
          isMandatory={dataItem.Is_ConcessionHeld}
          informationTooltip={
            dataItem.Is_ConcessionHeld
              ? "If the rebate entitled has a concession card held at least one owner must be selected"
              : undefined
          }
          errorMessage={errors?.[`Owners${dataItem.Rebate_Type_Id}`]}
        />
        <CCGrid
          data={Owners}
          primaryField={nameOfOwner("Entity_Id")}
          columnFields={colOwnerEntitledRebate}
          readOnly={isReadOnly}
          editableMode="cell"
          onDataChange={(dataItem: DTO_Owners[]) => {
            handleDataChange(dataItem, "Owners");
          }}
        />
      </div>
    </section>
  );
};
