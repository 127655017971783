import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SecurityTemplateSubmitActions } from "@common/pages/settings/security/security-templates/model";
import { SecurityTemplateForm } from "@common/pages/settings/security/security-templates/_id/child-screens/general/_index";
import { SecurityActionAllowButton } from "@common/pages/settings/security/security-templates/_id/components/buttons/allow-actions/_index";
import { SecurityActionDenyButton } from "@common/pages/settings/security/security-templates/_id/components/buttons/deny-actions/_index";
import { SecurityTemplateTabDetails } from "@common/pages/settings/security/security-templates/_id/components/reference-sidebar/detail/_index";
import { SecurityTemplateTabHistory } from "@common/pages/settings/security/security-templates/_id/components/reference-sidebar/history/_index";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageSecurityTemplate = observer(() => {
  const {
    isLoading,
    securityTemplate,
    loadSecurityTemplate,
    responseLoadError,
    onSubmit,
  } = useSecurityTemplateStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([securityTemplate?.TemplateName])}`,
      LinkUrl: managePageUrl,
      LinkText: `Security - Forms - Security Template - ${
        securityTemplate?.SecurityTemplate_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_SecurityTemplate,
      Record_ID: securityTemplate?.SecurityTemplate_ID ?? 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Security Template"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSecurityTemplate(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={securityTemplate?.TemplateName ?? ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={SecurityTemplateSubmitActions.Save}
              />,
              <SecurityActionAllowButton />,
              <SecurityActionDenyButton />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {securityTemplate && <SecurityTemplateForm />}
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <SecurityTemplateTabDetails />,
                    },
                    {
                      title: "History",
                      component: <SecurityTemplateTabHistory />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
