import { IntegratedReport } from "@common/pages/report/integrated-reports/custom-reports/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayNameReport = (selectedRow: IntegratedReport) => {
  return getDisplayTextWithDashes([
    selectedRow.ReportName,
    selectedRow.Category,
    selectedRow.ID,
  ]);
};

export const getBookmarkDetailReport = (selectedRow: IntegratedReport) => {
  return `Integrated Reports - Custom Reports`;
};

export const getBookmarkListViewDisplayNameReport = () => {
  return "Integrated Reports - Custom Reports";
};

export const getBookmarkListViewDetailReport = (): string => {
  return `Integrated Reports - Custom Reports`;
};
