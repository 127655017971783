import { TabAddress } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/tab-address/_index";
import { TabDetails } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/tab-details/_index";
import { TabMap } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/tab-map/_index";
import { TabPeople } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/tab-people/_index";
import { Address } from "@app/products/waste-water/[id]/model";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { useState } from "react";
import { TabSummary } from "./componets/tab-summary/_index";

interface IPropertyDetailDialogProps {
  initialValues?: Address;
  onClose: () => void;
  onSubmit: (value: Address) => void;
}
export const PropertyDetailDialog = ({
  initialValues,
  onClose,
  onSubmit,
}: IPropertyDetailDialogProps) => {
  const [tabSelected, setTabSelected] = useState(0);

  const handleSelect = (e: any) => {
    setTabSelected(e.selected);
  };

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    event.values._option = undefined;
    onSubmit(event.values as Address);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      initialValues={initialValues}
      key={JSON.stringify(initialValues)}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit } = formRenderProps;
        return (
          <CCDialog
            onClose={onClose}
            maxWidth={"80%"}
            height={"80%"}
            titleHeader={"Property Details"}
            bodyElement={
              <FormElement>
                <TabStrip selected={tabSelected} onSelect={handleSelect}>
                  <TabStripTab title={"Address"}>
                    <TabAddress formRenderProps={formRenderProps} />
                  </TabStripTab>
                  <TabStripTab title={"Map"}>
                    <TabMap formRenderProps={formRenderProps} />
                  </TabStripTab>
                  <TabStripTab title={"Details"}>
                    <TabDetails formRenderProps={formRenderProps} />
                  </TabStripTab>
                  <TabStripTab title={"People"}>
                    <TabPeople formRenderProps={formRenderProps} />
                  </TabStripTab>
                  <TabStripTab title={"Summary"}>
                    <TabSummary formRenderProps={formRenderProps} />
                  </TabStripTab>
                </TabStrip>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={onSubmit}
                >
                  Save & Exit
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
