import { CCTooltip } from "@components/cc-tooltip/_index";
import { getColorStyleForCurrency } from "@components/reference-sidebar/util";
import React, { useEffect, useRef, useState } from "react";
import "./ProductReferenceRow.scss";

interface IProductReferenceRow {
  title?: any;
  value?: any;
  customValue?: any;
  primary?: boolean;
  danger?: boolean;
  success?: boolean;
  warning?: boolean;
  secondary?: boolean;
  badge?: boolean;
  badgeSmall?: boolean;
  image?: string | undefined;
  format?: "currency" | "number";
  id?: string;
  style?:
    | ((value?: any) => React.CSSProperties | undefined)
    | React.CSSProperties;
  isPropertyStyle?: boolean;
}

export const ProductReferenceRow = (props: IProductReferenceRow) => {
  let { value, style, isPropertyStyle = true } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  let valueStyle: any =
    style && typeof style === "function" ? style(value) : style;

  const getColorStyle = (type: string) => {
    if (props.primary) {
      return type + "-accent";
    } else if (props.danger) {
      return type + "-error";
    } else if (props.success) {
      return type + "-success";
    } else if (props.warning) {
      return type + "-warning";
    } else if (props.secondary) {
      return type + "-disabled";
    }
    //Add color based on value if it's currency
    else if (props.format === "currency" && isPropertyStyle) {
      return getColorStyleForCurrency(props.value, type);
    }
    return " ";
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const isOverflowing = container?.scrollWidth > container?.clientWidth;

      setIsTextOverflowing(isOverflowing);
    }
  }, [value]);
  return (
    <>
      {props.image ? (
        <>
          <div className="cc-product-ref-row">
            {props?.title && (
              <div
                className={`cc-product-ref-title ${
                  isTextOverflowing ? "cc-overflow-dot" : ""
                }`}
                id={props.id ?? ""}
                ref={containerRef}
              >
                {isTextOverflowing && (
                  <CCTooltip
                    type="custom"
                    classNameWrapper="cc-custom-tooltip"
                    content={props.title}
                  />
                )}
                {props.title}
              </div>
            )}
          </div>
          <img
            src={props.image}
            alt="Product"
            className="cc-product-ref-image"
            style={valueStyle}
          />
        </>
      ) : props.badge || props.badgeSmall ? (
        <div
          className={`${
            props.badgeSmall
              ? "cc-product-ref-badge-small"
              : "cc-product-ref-badge"
          } ${getColorStyle(
            `cc-product-ref-badge${props.badge ? "" : "-small"}-color`
          )}`}
        >
          {value || value === 0 ? value : " "}
        </div>
      ) : props.customValue ? (
        <>{props.customValue}</>
      ) : (
        <div className="cc-product-ref-row">
          <div
            className={`cc-product-ref-title ${
              isTextOverflowing ? "cc-overflow-dot" : ""
            }`}
            id={props.id ?? ""}
            ref={containerRef}
          >
            {isTextOverflowing && (
              <CCTooltip
                type="custom"
                classNameWrapper="cc-custom-tooltip"
                content={props.title}
              />
            )}
            {props.title}
          </div>

          <div
            className={`cc-product-ref-value ${getColorStyle(
              "cc-product-ref-value-color"
            )} ${props.format === "currency" ? "cc-format-currency" : ""} ${
              props.format === "number" ? "cc-format-number" : ""
            }`}
            style={valueStyle}
          >
            {value || value === 0 ? value : " "}
          </div>
        </div>
      )}
    </>
  );
};
