import { DTO_Transaction_Item } from "@app/products/property/model";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { loadAssessmentTransactionAllocation } from "./api";
import { colTransactionsAllocation } from "./config";

const AppliedOnAssessmentDetails = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const [isLoading, setIsLoading] = useState(true);
  const [columnTransactionsAllocation, setColumnTransactionsAllocation] =
    useState<IColumnFields[]>(colTransactionsAllocation);
  const [data, setData] = useState<DTO_Transaction_Item[]>([]);

  useEffectOnce(() => {
    setIsLoading(true);
    loadAssessmentTransactionAllocation(
      dataItem.assessmentId,
      dataItem.TransactionId
    ).then(
      (response) => {
        if (
          !response?.TransactionAllocations.TransactionItem ||
          response.TransactionAllocations.TransactionItem.length === 0
        ) {
          setIsLoading(false);
          return;
        }
        setData(response.TransactionAllocations.TransactionItem);

        const transactionAllocationTotals: any =
          response.TransactionAllocations.Totals;
        if (transactionAllocationTotals) {
          columnTransactionsAllocation.forEach((item) => {
            if (Object.keys(transactionAllocationTotals).includes(item.field)) {
              item.footerCell = (
                <CurrencyCell value={transactionAllocationTotals[item.field]} />
              );
            }
          });
          setColumnTransactionsAllocation(columnTransactionsAllocation);
        }

        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  });

  return (
    <>
      <label className="cc-label">Transaction allocation</label>
      <CCGrid
        isLoading={isLoading}
        className="cc-rebates-applied-on-assessment-detail-grid"
        data={data}
        columnFields={colTransactionsAllocation}
        primaryField="ChargeBalancesId"
      />
    </>
  );
};
export default AppliedOnAssessmentDetails;
