import {
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colConcessionCardsHeld: IColumnFields[] = [
  { field: "ConcessionCard", title: "Concession Card" },
  { field: "CardNumber", title: "Card Number", format: NUMBER_FORMAT.NUMBER2 },
  { field: "IsActive", title: "Is Active", format: ICON_FORMAT.ICON_BOOLEAN },
  {
    field: "DatePresented",
    title: "Date Presented",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "ConfirmationDate",
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
];

export const colVerificationHistory: IColumnFields[] = [
  {
    field: "ConfirmationDate",
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  { field: "CardNumber", title: "Card Number" },
  { field: "IsDeceased", title: "Is Deceased?" },
  { field: "HasPensionConcessionCard", title: "Has Pension Concession Card?" },
  { field: "HasHealthConcessionCard", title: "Has Health Concession Card?" },
  { field: "HasSeniorsHealthCard", title: "Has Seniors Health Card?" },
  { field: "IsWarWindow", title: "Is War Window?" },
  { field: "GoldCard", title: "Gold Card" },
  { field: "PrisonerOfWar", title: "Prisoner of War" },
  { field: "Disability", title: "Disability" },
  {
    field: "SpecialDisabilityAssessment",
    title: "Special Disability Assessment",
  },
  { field: "FirstName", title: "First Name" },
  { field: "MiddleName", title: "Middle Name" },
  { field: "SurName", title: "Sur Name" },
  { field: "DateOfBirth", title: "Date of Birth", format: DATE_FORMAT.DATE },
  { field: "Address", title: "Address" },
  { field: "DidPostcodeMatch", title: "Did Postcode Match" },
  { field: "MatchDescription", title: "Match Description" },
  { field: "RequestDate", title: "Request Date" },
];
