import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

export const colScheduleSpecialReadings: IColumnFields[] = [
  {
    field: "Utility",
    title: "Utility",
  },
  {
    field: "MeterNumber",
    title: "Meter Number",
  },
  {
    field: "ScheduleRead",
    title: "Schedule Read?",
    format: BOOLEAN_FORMAT.BOOLEAN,
    editable: true,
  },
  {
    field: "BillingGroup",
    title: "Billing Group",
  },
  {
    field: "MeterId",
    title: "Meter Installation ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
