import { getEnquiryStatus } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/change-status/api";
import { RegisterStatus_Int } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { ChangeStatus } from "@app/products/town-planning/ppr/enquiries/components/action-bar/buttons/batch-complete/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLocalNotification } from "@components/cc-app-notification/_index";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";

export interface IPPREnquiryUpdateStatusDialogProps {
  isUpdating?: boolean;
  onClose: () => void;
  onSubmit: (data?: ChangeStatus) => void;
}

const nameOf = nameOfFactory<ChangeStatus>();

export const PPREnquiryUpdateStatusDialog = ({
  isUpdating,
  onClose,
  onSubmit,
}: IPPREnquiryUpdateStatusDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [status, setStatus] = useState<IKeyValuePacket[]>();

  useEffect(() => {
    const resStatus = async () => {
      setIsLoading(true);
      const resEnquiryStatus = await getEnquiryStatus();
      setIsLoading(false);
      if (isSuccessResponse(resEnquiryStatus)) {
        if (resEnquiryStatus?.data) {
          setStatus(resEnquiryStatus?.data);
        }
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title:
              resEnquiryStatus.statusText ??
              "The status list could not be loaded.",
            type: "error",
          },
        ]);
      }
    };
    resStatus();
  }, []);

  const handleOnSubmitClick = (event: FormSubmitClickEvent) => {
    const data = event.values as ChangeStatus;
    onSubmit(data);
  };

  return (
    <Form
      onSubmitClick={handleOnSubmitClick}
      render={(formRenderProps: FormRenderProps) => {
        const { valid, onSubmit } = formRenderProps;
        return (
          <FormElement>
            <CCDialog
              maxWidth="25%"
              height="auto"
              titleHeader={"Application Status"}
              onClose={onClose}
              disabled={isUpdating}
              bodyElement={
                isLoading ? (
                  <Loading isLoading={isLoading} />
                ) : (
                  <>
                    {notifications?.length ? (
                      <div>
                        <CCLocalNotification
                          defaultNotifications={notifications}
                        />
                      </div>
                    ) : null}
                    <div className="cc-form">
                      <section className="cc-field-group">
                        <div className="cc-form-cols-1">
                          <div className="cc-field">
                            <label className="cc-label">
                              Status
                              <CCTooltip
                                type="validator"
                                content="Please select the new status"
                              />
                            </label>
                            <Field
                              name={nameOf("RegisterStatus")}
                              textField={"Value"}
                              dataItemKey={"Key"}
                              component={CCSearchComboBox}
                              data={status ?? []}
                              isUseDefaultOnchange
                              validator={requiredValidator}
                            />
                          </div>
                          {formRenderProps?.valueGetter(
                            nameOf("RegisterStatus") ?? ""
                          ) === RegisterStatus_Int.Completed && (
                            <>
                              <div className="cc-field">
                                <CCLabel
                                  title={"Reason for completion"}
                                  isMandatory={true}
                                />
                                <Field
                                  name={nameOf("Reason")}
                                  component={CCTextArea}
                                  rows={3}
                                  placeholder={"Reason for completion"}
                                  validator={requiredValidator}
                                />
                              </div>

                              <div className="cc-field">
                                <CCLabel
                                  title={"Date of Action"}
                                  isMandatory={true}
                                />
                                <Field
                                  name={nameOf("DateOfAction")}
                                  format={DATE_FORMAT.DATE_CONTROL}
                                  component={CCDatePicker}
                                  validator={requiredValidator}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </section>
                    </div>
                  </>
                )
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!valid}
                    themeColor="primary"
                    onClick={onSubmit}
                    className={"cc-dialog-button"}
                    iconClass={isUpdating ? "fas fa-spinner fa-spin" : ""}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </FormElement>
        );
      }}
    />
  );
};
