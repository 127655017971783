import { IParkingPermitsFormElementProps } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/_index";
import { PermitsDisabledQuestionDialog } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/components/form-default/components/disabled-question-dialog/_index";
import { ParkingPermitsFormDisabled } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/components/form-default/components/form-disabled/_index";
import { ParkingPermitsFormParking } from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/components/form-default/components/form-parking/_index";
import {
  mockColourData,
  mockPrintOnPermitData,
  mockProofOfResidencyData,
  mockRegistrationStateData,
  mockVehicleTypeData,
} from "@app/products/parking-permits/[id]/components/child-screens/general/components/form-element/components/mock";
import { ParkingPermitsFieldMapping } from "@app/products/parking-permits/model";
import {
  behalfOfProps,
  contactPersonProps,
  fileNumberProps,
  isSamePermitAddressProps,
  makeModelProps,
  onBehalfOfProps,
  onlyValidProps,
  permitHolderProps,
  postalAddressProps,
  registrationNoProps,
  specialPurposeProps,
  tablePermitType,
} from "@app/products/parking-permits/util";
import { RegisterAddressPopup } from "@app/products/property/components/register-address-popup/_index";
import { nameOfLov } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  createDefaultDropdownItem,
  getDropdownValue,
  populateValue,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import InputPicker from "@components/input-picker/InputPicker";
import { Button } from "@progress/kendo-react-buttons";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const ParkingPermitsFormDefault = observer(
  ({ formRenderProps }: IParkingPermitsFormElementProps) => {
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    const [dataChoose, setDataChoose] = useState<any>();
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const [isShowDialog, setIsShowDialog] = useState(true);
    const [isShowFormDefault, setIsShowFormDefault] = useState(true);
    const [typePermit, setTypePermit] = useState("");
    const { id } = useParams<{ id: string }>();
    const isNewParking = id.toLowerCase() === "new-parking";
    const isNewDisabled = id.toLowerCase() === "new-disabled";
    const history = useHistory();
    const navigateToContactRelationship = () => {
      history.push(`/managecontactrelationship/${"1300"}`, {
        recordType: "210",
      });
    };
    const getValue = formRenderProps.valueGetter;

    const location: any = useLocation();
    const sameAddressData = location.state?.sameAddressData;

    useEffect(() => {
      sameAddressData && isNewParking && setIsShowFormDefault(false);
    }, [setIsShowFormDefault, sameAddressData, isNewParking]);
    return (
      <>
        {showAddressPopup && (
          <RegisterAddressPopup
            onClosePopup={() => setShowAddressPopup(false)}
          />
        )}

        {isNewParking && isShowDialog && !sameAddressData && (
          <GridSelectionDialog
            height="60%"
            titleHeader="Pick Permit Type"
            onCloseDialog={() => {
              setIsShowDialog(false);
            }}
            columnFields={tablePermitType}
            setShowDialog={(isShow: boolean) => {
              setIsShowFormDefault(false);
              setIsShowDialog(isShow);
            }}
            onSubmit={setDataChoose}
          />
        )}
        {isNewDisabled && isShowDialog && (
          <PermitsDisabledQuestionDialog
            onCloseDialog={() => {
              setIsShowDialog(false);
            }}
            onSubmitDialog={(value: any) => {
              setTypePermit(value);
              setIsShowFormDefault(false);
              setIsShowDialog(false);
            }}
          />
        )}

        {isShowFormDefault && (
          <>
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                {/* ROW 1 */}
                <CCValueField
                  label="Individual/org"
                  value={getValue("Individual_Org")}
                />
                <CCValueField
                  label="Permit category"
                  value={getValue("PermitCategory")}
                />
                <CCValueField
                  label="Permit code"
                  value={getValue("PermitCode")}
                />
              </div>
            </section>
            <hr className="cc-divider" />

            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label ">
                    Permit Holder
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    onClick={navigateToContactRelationship}
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.PermitHolder}
                    component={InputPicker}
                    validator={requiredValidator}
                    {...permitHolderProps(formRenderProps)}
                  />
                </div>
                <CCValueField
                  label="Contact"
                  value={getValue(
                    ParkingPermitsFieldMapping.ContactPermitHolder
                  )}
                />
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <div>
                    <label className="cc-label">
                      Contact person&nbsp;
                      <Button
                        fillMode="flat"
                        className="cc-parking-permits-owner-button"
                        iconClass="fa fa-chevron-circle-down"
                        onClick={(e) => {
                          e.preventDefault();
                          formRenderProps.onChange(
                            ParkingPermitsFieldMapping.ContactNumPerson,
                            {
                              value: getValue(
                                ParkingPermitsFieldMapping.ContactPermitHolder
                              ),
                            }
                          );
                          formRenderProps.onChange(
                            ParkingPermitsFieldMapping.ContactPerson,
                            {
                              value: getValue(
                                ParkingPermitsFieldMapping.PermitHolder
                              ),
                            }
                          );
                        }}
                      />
                    </label>
                  </div>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.ContactPerson}
                    component={CCInput}
                    {...contactPersonProps(formRenderProps)}
                  />
                </div>
                <CCValueField
                  label="Contact"
                  value={getValue(ParkingPermitsFieldMapping.ContactNumPerson)}
                />
              </div>
            </section>
            <hr className="cc-divider" />

            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Vehicle type</label>
                  <Field
                    data={mockVehicleTypeData}
                    name={ParkingPermitsFieldMapping.VehicleType}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.VehicleType),
                      mockVehicleTypeData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.VehicleType,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />

            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">On behalf of?</label>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.OnBehalfOf}
                    className="cc-label"
                    component={Checkbox}
                    {...onBehalfOfProps(formRenderProps)}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Field
                    onClick={navigateToContactRelationship}
                    autoComplete="off"
                    name={"Behalfof"}
                    component={InputPicker}
                    {...behalfOfProps(
                      formRenderProps,
                      !getValue(ParkingPermitsFieldMapping.OnBehalfOf),
                      !getValue(ParkingPermitsFieldMapping.OnBehalfOf)
                    )}
                  />
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Permit address&nbsp;
                    <Button
                      fillMode="flat"
                      className="cc-parking-permits-owner-button"
                      iconClass="fa fa-chevron-circle-down"
                      onClick={() => {
                        formRenderProps.onChange(
                          ParkingPermitsFieldMapping.PermitAddress,
                          {
                            value: getValue(
                              ParkingPermitsFieldMapping.AddressPermitHolder
                            ),
                          }
                        );
                      }}
                    />
                  </label>
                  <div className="cc-label-field">
                    {populateValue(
                      getValue(ParkingPermitsFieldMapping.PermitAddress)
                    )}
                  </div>
                </div>
                <CCValueField
                  label="Property number"
                  value={getValue(ParkingPermitsFieldMapping.PropertyNumber)}
                />
                <CCValueField
                  label="Permit area allowed"
                  value={getValue(ParkingPermitsFieldMapping.PermitAreaAllowed)}
                />
              </div>
              {/* ROW 3 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Proof of residency 1</label>
                  <Field
                    data={mockProofOfResidencyData}
                    name={ParkingPermitsFieldMapping.ProofofResidency1}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.ProofofResidency1),
                      mockProofOfResidencyData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ProofofResidency1,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Proof of residency 2</label>
                  <Field
                    data={mockProofOfResidencyData}
                    name={ParkingPermitsFieldMapping.ProofofResidency2}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.ProofofResidency2),
                      mockProofOfResidencyData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ProofofResidency2,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Proof of membership</label>
                  <Field
                    name={ParkingPermitsFieldMapping.ProofofMembership}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={mockProofOfResidencyData}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.ProofofMembership),
                      mockProofOfResidencyData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ProofofMembership,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Print on permit</label>
                  <Field
                    data={mockPrintOnPermitData}
                    name={ParkingPermitsFieldMapping.PrintStatus}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.PrintStatus),
                      mockPrintOnPermitData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.PrintStatus,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
              </div>
              {/* ROW 4 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Home phone</label>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.HomePhone}
                    placeholder="Home Phone"
                    component={CCInputPhone}
                    validator={validatePhoneNumber}
                    onChangeForm={formRenderProps.onChange}
                    skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                    mode={EModePhoneNumber.HomePhone}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Business phone</label>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.BusinessPhone}
                    placeholder="Business Phone"
                    component={CCInputPhone}
                    validator={validatePhoneNumber}
                    onChangeForm={formRenderProps.onChange}
                    skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                    mode={EModePhoneNumber.Business}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Mobile phone</label>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.MobilePhone}
                    placeholder="Mobile Phone"
                    component={CCInputPhone}
                    validator={validatePhoneNumber}
                    onChangeForm={formRenderProps.onChange}
                    skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                    mode={EModePhoneNumber.Mobile}
                  />
                </div>
              </div>
              {/* ROW 5 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">File number</label>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.FileNumber}
                    component={CCInput}
                    {...fileNumberProps(formRenderProps)}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />

            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <Field
                    label="Same as permit address"
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.IsSameAsPermitAddress}
                    className="cc-label"
                    component={Checkbox}
                    {...isSamePermitAddressProps(formRenderProps, getValue)}
                  />
                </div>
                <div className="cc-field">
                  <Field
                    label="Property address?"
                    autoComplete="off"
                    name={"IsPropertyAddress"}
                    className="cc-label"
                    component={Checkbox}
                  />
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">On behalf of?</label>
                  <Field
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.OnBehalfOf}
                    className="cc-label"
                    component={Checkbox}
                    {...onBehalfOfProps(formRenderProps)}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <Field
                    onClick={navigateToContactRelationship}
                    autoComplete="off"
                    name={"Behalfof"}
                    component={InputPicker}
                    {...behalfOfProps(
                      formRenderProps,
                      !getValue(ParkingPermitsFieldMapping.OnBehalfOf),
                      !getValue(ParkingPermitsFieldMapping.OnBehalfOf)
                    )}
                  />
                </div>
              </div>
              {/* ROW 3 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Postal address</label>
                  <Field
                    onClick={() => setShowAddressPopup(true)}
                    autoComplete="off"
                    name={ParkingPermitsFieldMapping.PostalAddress}
                    component={InputPicker}
                    {...postalAddressProps(
                      formRenderProps,
                      getValue,
                      getValue(
                        ParkingPermitsFieldMapping.IsSameAsPermitAddress
                      ),
                      getValue(ParkingPermitsFieldMapping.IsSameAsPermitAddress)
                    )}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />

            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Original issue date</label>
                  <Field
                    name={ParkingPermitsFieldMapping.OriginalIssueDate}
                    defaultValue={new Date()}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">
                    Issue date
                    <CCTooltip type="validator" position="right" />
                  </label>
                  <Field
                    name={ParkingPermitsFieldMapping.IssueDate}
                    defaultValue={new Date()}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Issue time</label>
                  <Field
                    value={
                      getValue(ParkingPermitsFieldMapping.IssueTime)
                        ? new Date(
                            getValue(ParkingPermitsFieldMapping.IssueTime)
                          )
                        : new Date()
                    }
                    name={ParkingPermitsFieldMapping.IssueTime}
                    component={TimePicker}
                  />
                </div>
              </div>
              {/* ROW 3 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Expiry date</label>
                  <Field
                    name={ParkingPermitsFieldMapping.ExpiryDate}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={CCDatePicker}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Expiry time</label>
                  <Field
                    value={
                      getValue(ParkingPermitsFieldMapping.ExpiryTime)
                        ? new Date(
                            getValue(ParkingPermitsFieldMapping.ExpiryTime)
                          )
                        : new Date()
                    }
                    name={ParkingPermitsFieldMapping.ExpiryTime}
                    component={TimePicker}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />

            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Registration number</label>
                  <Field
                    name={ParkingPermitsFieldMapping.RegNo}
                    component={CCInput}
                    {...registrationNoProps(formRenderProps)}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Make/Model</label>
                  <Field
                    name={ParkingPermitsFieldMapping.Make_Model}
                    component={CCInput}
                    {...makeModelProps(formRenderProps)}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Colour</label>
                  <Field
                    name={ParkingPermitsFieldMapping.Colour}
                    data={mockColourData}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.Colour),
                      mockColourData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.Colour,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
              </div>
              {/* ROW 2 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Proof of registration</label>
                  <Field
                    name={ParkingPermitsFieldMapping.ProofofRegistration}
                    defaultItem={
                      createDefaultDropdownItem("Proof of Registration").Value
                    }
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={mockProofOfResidencyData}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.ProofofRegistration),
                      mockProofOfResidencyData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.ProofofRegistration,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Registration state</label>
                  <Field
                    name={ParkingPermitsFieldMapping.RegistrationState}
                    data={mockRegistrationStateData}
                    component={CCSearchComboBox}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    value={getDropdownValue(
                      getValue(ParkingPermitsFieldMapping.RegistrationState),
                      mockRegistrationStateData,
                      nameOfLov("Code")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(
                        ParkingPermitsFieldMapping.RegistrationState,
                        {
                          value: event.target.value?.Code ?? null,
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Special purpose</label>
                  <Field
                    name={ParkingPermitsFieldMapping.SpecialPurpose}
                    component={CCInput}
                    {...specialPurposeProps(formRenderProps)}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Only valid</label>
                  <Field
                    name={ParkingPermitsFieldMapping.OnlyValid}
                    component={CCInput}
                    {...onlyValidProps(formRenderProps)}
                  />
                </div>
              </div>
            </section>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {/* ROW 1 */}
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Debtor number"
                  value={getValue(ParkingPermitsFieldMapping.DebtorNumber)}
                />
                <div className="cc-field">
                  <label className="cc-label">Outstanding fees</label>
                  <Field
                    name={ParkingPermitsFieldMapping.OSFees}
                    component={CCCurrencyInput}
                  />
                </div>
              </div>
            </section>
          </>
        )}

        {!isShowFormDefault && isNewParking ? (
          <ParkingPermitsFormParking
            formRenderProps={formRenderProps}
            dataChoose={dataChoose}
            sameAddressData={sameAddressData}
          />
        ) : (
          !isShowFormDefault &&
          isNewDisabled && (
            <ParkingPermitsFormDisabled
              formRenderProps={formRenderProps}
              typePermit={typePermit}
              sameAddressData={sameAddressData}
            />
          )
        )}
      </>
    );
  }
);
