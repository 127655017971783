import { eLevySummaryTypes } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/levy-summary/model";
import { ViewConfiguration } from "@app/products/property/model";

export const levyBalancesGrids = [
  {
    title: "Assessment Status",
    id: eLevySummaryTypes.Assessment_Status,
  },
  {
    title: "Assessment Type",
    id: eLevySummaryTypes.Assessment_Type,
  },
  {
    title: "Financial Area",
    id: eLevySummaryTypes.Financial_Area,
  },
  {
    title: "Financial Group",
    id: eLevySummaryTypes.Financial_Group,
  },
  {
    title: "Property Locality",
    id: eLevySummaryTypes.Property_Locality,
  },
  {
    title: "Region",
    id: eLevySummaryTypes.Region,
  },
];

export const getViewConfigurationsByType = (type: eLevySummaryTypes) => {
  switch (type) {
    case eLevySummaryTypes.Assessment_Status:
      return ViewConfiguration.Charge_Run_LevySummary_Assessment_Status;
    case eLevySummaryTypes.Assessment_Type:
      return ViewConfiguration.Charge_Run_LevySummary_Assesment_Type;
    case eLevySummaryTypes.Financial_Area:
      return ViewConfiguration.Charge_Run_LevySummary_FinancialArea;
    case eLevySummaryTypes.Financial_Group:
      return ViewConfiguration.Charge_Run_LevySummary_FinancialGroup;
    case eLevySummaryTypes.Property_Locality:
      return ViewConfiguration.Charge_Run_LevySummary_Property_Locality;
    case eLevySummaryTypes.Region:
      return ViewConfiguration.Charge_Run_LevySummary_Region;
    default:
      return undefined;
  }
};
