import { HMActionBarNavDropdown } from "@app/products/hm/components/action-bar/nav-dropdown/_index";
import { InspScheduleCalendarDropdown } from "@app/products/hm/insp-schedule/calendar/components/insp-calendar-pick-officer-dropdown/_index";
import { useHMInspectionScheduleCalendarStore } from "@app/products/hm/insp-schedule/calendar/store";
import { inspScheduleRoute } from "@app/products/hm/insp-schedule/route";
import { HMInspScheduleBookmark } from "@app/products/hm/insp-schedule/utils";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { hmRoute } from "@app/products/hm/route";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCCalendar } from "@components/cc-calendar/_index";
import { CalendarViewEnum } from "@components/cc-calendar/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce, useUpdateEffect } from "react-use";
import "./_index.scss";

export default observer(() => {
  const {
    calendarData,
    loadCalendar,
    loadCalendarByOfficer,
    isLoading,
    responseLoadError,
    selectedSiteUser,
  } = useHMInspectionScheduleCalendarStore();

  useCCListViewActionBar({
    title: hmRoute.name,
    leftComponents: [
      <HMActionBarNavDropdown category={inspScheduleRoute.path} />,
      <InspScheduleCalendarDropdown />,
    ],
    centerComponents: [],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PREMISES_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        detail={HMInspScheduleBookmark.getBookmarkDetailDisplayName}
        displayName={HMInspScheduleBookmark.getBookmarkListViewDetailRecord}
        listViewDetail={() =>
          HMInspScheduleBookmark.getBookmarkListViewDetail("Calendar")
        }
        listViewDisplayName={
          HMInspScheduleBookmark.getBookmarkDetailDisplayName
        }
      />,
    ],
  });

  useEffectOnce(() => {
    loadCalendar();
  });

  useUpdateEffect(() => {
    if (selectedSiteUser) {
      loadCalendarByOfficer(selectedSiteUser?.Name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSiteUser]);

  return (
    <div className="cc-insp-schedule-calendar-container ">
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCalendar()}
        />
      ) : (
        <CCCalendar
          detailUrl={PREMISES_MANAGE_ROUTE + "/"}
          data={calendarData}
          defaultView={CalendarViewEnum.AGENDA}
        />
      )}
    </div>
  );
});
