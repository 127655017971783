import { NoticeRunFormElement } from "@app/products/property/schemes/notice-runs/[id]/components/child-screens/general/components/form-element/_index";
import { useSchemesNoticeRunsStore } from "@app/products/property/schemes/notice-runs/[id]/store";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const NoticeRunRequestForm = observer(() => {
  const { schemesNoticeRuns } = useSchemesNoticeRunsStore();
  return (
    <div className="cc-form">
      <Form
        initialValues={schemesNoticeRuns}
        render={(formRenderProps: FormRenderProps) => (
          <NoticeRunFormElement formRenderProps={formRenderProps} />
        )}
      />
    </div>
  );
});
