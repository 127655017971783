import { GlobalSettingAddressDialog } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/forms/property-dialog/components/child-screens/address-dialog";
import { GlobalSettingPropertyDialog } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/properties/forms/property-dialog/components/child-screens/property-dialog";
import { CustomPanelBar } from "@components/custom-panelbar/CustomPanelBar";
import { IListPanelBar } from "@components/custom-panelbar/model";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface IGlobalSettingsProps {
  formRenderProps: FormRenderProps;
}

export default ({ formRenderProps }: IGlobalSettingsProps) => {
  const listPanelBar: IListPanelBar[] = [
    {
      title: "Property Dialog",
      component: (
        <GlobalSettingPropertyDialog formRenderProps={formRenderProps} />
      ),
    },
    {
      title: "Address Dialog",
      component: (
        <GlobalSettingAddressDialog formRenderProps={formRenderProps} />
      ),
    },
  ];
  return (
    <div className="cc-manage-container">
      <div className="cc-form cc-manage-left">
        <div className="cc-manage-form-body">
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-1">
              <CustomPanelBar
                listPanelBar={listPanelBar}
                sort={false}
                expandMode="multiple"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
