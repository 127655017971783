import { ApplicationSettingSwitch } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-setting-switch/_index";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export type IRegistersGeneralFormProps = {
  formRenderProps: FormRenderProps;
};

export const RegistersGeneralForm = observer(
  ({ formRenderProps }: IRegistersGeneralFormProps) => {
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_AssessmentConfirmation}
            formRenderProps={formRenderProps}
          />
          <ApplicationSettingSwitch
            enumParam={ECorporateSettingsField.TP_RegisterRecordsOnSaveOrLodge}
            formRenderProps={formRenderProps}
          />
        </div>
      </section>
    );
  }
);
