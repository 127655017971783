import { VO_Meter } from "@app/products/property/meters/list/model";

export const mockList: VO_Meter[] = [
  {
    MeterId: 2926,
    RouteNumber: 3001,
    ReadingSeqInRoute: 211500,
    MeterNumber: "C013224",
    Category: "Whitsunday Water Usage (W1)",
    MeterType: "Mains",
    Size: 25000,
    UnitOfMeasure: "Kilolitre",
    InstallationDate: new Date("22 Dec 2010"),
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 2",
  },
  {
    MeterId: 3481,
    MeterNumber: "0000223",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 792,
    RouteNumber: 1003,
    ReadingSeqInRoute: 417100,
    MeterNumber: "0000228",
    Category: "Proserpine Water Usage (P1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 4",
  },
  {
    MeterId: 3915,
    MeterNumber: "0000232",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Being Tested",
  },
  {
    MeterId: 1475,
    RouteNumber: 5000,
    ReadingSeqInRoute: 109200,
    MeterNumber: "0000233",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 1",
  },
  {
    MeterId: 3115,
    RouteNumber: 3002,
    ReadingSeqInRoute: 307600,
    MeterNumber: "0000236",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "Right of Drive",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 3",
  },
  {
    MeterId: 985,
    MeterNumber: "0000237",
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3308,
    RouteNumber: 5001,
    ReadingSeqInRoute: 204100,
    MeterNumber: "0000238",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "not in use- traffic island - stormvogel drive",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 2",
  },
  {
    MeterId: 425,
    RouteNumber: 5000,
    ReadingSeqInRoute: 126700,
    MeterNumber: "0000240",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 1",
  },
  {
    MeterId: 3632,
    MeterNumber: "0000243",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 562,
    RouteNumber: 9999,
    MeterNumber: "0000245",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    ReadingFrequency: 3,
    Location: "Reading on replacement",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 2302,
    RouteNumber: 1000,
    ReadingSeqInRoute: 131200,
    MeterNumber: "0000250",
    Category: "Proserpine Water Usage (P1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "METER CUL-DE-SAC SIDE OF HOUSE",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 1",
  },
  {
    MeterId: 3077,
    RouteNumber: 3001,
    ReadingSeqInRoute: 216400,
    MeterNumber: "0000441",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 2",
  },
  {
    MeterId: 1159,
    RouteNumber: 9999,
    MeterNumber: "0000442",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    ReadingFrequency: 3,
    Location: "Reading on replacement",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",

    RouteName: "Meters not being read",
  },
  {
    MeterId: 1677,
    MeterNumber: "0000444",
    MeterType: "Mains",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 1945,
    RouteNumber: 1002,
    ReadingSeqInRoute: 307300,
    MeterNumber: "0000445",
    Category: "Proserpine Water Usage (P1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 3",
  },
  {
    MeterId: 2184,
    RouteNumber: 1002,
    ReadingSeqInRoute: 308200,
    MeterNumber: "0000446",
    Category: "Proserpine Water Usage (P1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 3",
  },
  {
    MeterId: 1210,
    RouteNumber: 9999,
    MeterNumber: "0000447",
    Category: "Check Meter - Statistics Only",
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("30 Apr 2015"),
    ReadingFrequency: 6,
    Location: "Only Just Commenced Read 30jun03",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 3645,
    MeterNumber: "0000450",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 2291,
    RouteNumber: 1004,
    ReadingSeqInRoute: 514200,
    MeterNumber: "0000451",
    Category: "Proserpine Water Usage (P1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 5",
  },
  {
    MeterId: 2947,
    MeterNumber: "0000452",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3248,
    MeterNumber: "0000453",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3255,
    RouteNumber: 3001,
    ReadingSeqInRoute: 220100,
    MeterNumber: "0000454",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 2",
  },
  {
    MeterId: 3642,
    MeterNumber: "0000455",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3269,
    RouteNumber: 3001,
    ReadingSeqInRoute: 220200,
    MeterNumber: "0000456",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 2",
  },
  {
    MeterId: 3709,
    RouteNumber: 9999,
    MeterNumber: "0000458",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    ReadingFrequency: 3,
    Location: "Reading on replacement",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 3699,
    MeterNumber: "0000459",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 836,
    RouteNumber: 4000,
    ReadingSeqInRoute: 20700,
    MeterNumber: "0000461",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "left boundary next to stairs",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Airlie",
  },
  {
    MeterId: 3093,
    RouteNumber: 5001,
    ReadingSeqInRoute: 219900,
    MeterNumber: "0000462",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 2",
  },
  {
    MeterId: 2791,
    RouteNumber: 9999,
    MeterNumber: "0000463",
    Category: "Check Meter - Statistics Only",
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("30 Apr 2015"),
    ReadingFrequency: 6,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 3657,
    RouteNumber: 3001,
    ReadingSeqInRoute: 233700,
    MeterNumber: "0000464",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "l/h boundary - 8 Solway Ave",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 2",
  },
  {
    MeterId: 3729,
    RouteNumber: 3001,
    ReadingSeqInRoute: 234700,
    MeterNumber: "0000465",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 1",
  },
  {
    MeterId: 1765,
    MeterNumber: "0000467",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 2135,
    RouteNumber: 5001,
    ReadingSeqInRoute: 224600,
    MeterNumber: "0000470",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 2",
  },
  {
    MeterId: 1386,
    MeterNumber: "0000828",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3259,
    MeterNumber: "0001320",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 1910,
    MeterNumber: "0001322",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3920,
    RouteNumber: 3002,
    ReadingSeqInRoute: 333400,
    MeterNumber: "0001323",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 3",
  },
  {
    MeterId: 2196,
    MeterNumber: "0001325",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3711,
    RouteNumber: 9999,
    MeterNumber: "0001327",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2015"),
    ReadingFrequency: 4,
    Location: "meter numbers dont match",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 3730,
    RouteNumber: 9999,
    MeterNumber: "0001331",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    ReadingFrequency: 3,
    Location: "Reading on replacement",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 3277,
    MeterNumber: "0001332",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3670,
    MeterNumber: "0001335",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 3916,
    RouteNumber: 3003,
    ReadingSeqInRoute: 411700,
    MeterNumber: "0001336",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Cannonvale 4",
  },
  {
    MeterId: 3867,
    MeterNumber: "0001342",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 523,
    RouteNumber: 1000,
    ReadingSeqInRoute: 121000,
    MeterNumber: "0001353",
    Category: "Proserpine Water Usage (PI)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 1",
  },
  {
    MeterId: 3764,
    MeterNumber: "0001356",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 1477,
    MeterNumber: "0001358",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 1888,
    RouteNumber: 9999,
    MeterNumber: "0001359",
    Category: "Proserpine Water Usage (P1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    ReadingFrequency: 3,
    Location: "Reading on replacement",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Retired",
    RouteName: "Meters not being read",
  },
  {
    MeterId: 3033,
    MeterNumber: "000216",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    Status: "Retired",
  },
  {
    MeterId: 1919,
    RouteNumber: 1002,
    ReadingSeqInRoute: 307100,
    MeterNumber: "00023192",
    Category: "Proserpine Water Usage (PI)",
    MeterType: "Mains",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "24 Rose Street",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Proserpine 3",
  },
  {
    MeterId: 3309,
    RouteNumber: 5001,
    ReadingSeqInRoute: 204300,
    MeterNumber: "000246",
    Category: "Whitsunday Water Usage (W1)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "Note in Use - traffic island - Stormvogel Dr",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 2",
  },
  {
    MeterId: 2101,
    RouteNumber: 1002,
    ReadingSeqInRoute: 304600,
    MeterNumber: "00027119",
    Category: "Proserpine Water Usage (PI)",
    Size: 20000,
    UnitOfMeasure: "Kilolitre",
    NextReadingDate: new Date("28 Oct 2020"),
    ReadingFrequency: 4,
    Location: "16 Carnation St",
    KeysRequired: false,
    PhoneBeforeRead: false,
    Status: "Active",
    ReadingDeviceName: "PDA_ALL",
    RouteName: "Jubilee 2",
  },
];
