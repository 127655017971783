import { toast, ToastContent, ToastOptions, TypeOptions } from "react-toastify";
export interface IToastData {
  content: ToastContent;
  type: TypeOptions;
}
const options: ToastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

const displayToast = (param: IToastData = { content: "", type: "info" }) => {
  switch (param.type) {
    case "info":
      toast.info(param.content, options);
      break;
    case "success":
      toast.success(param.content, options);
      break;
    case "warning":
      toast.warning(param.content, options);
      break;
    case "error":
      toast.error(param.content, options);
      break;
    case "dark":
      toast.dark(param.content, options);
      break;

    default:
      toast(param.content, options);
      break;
  }
};

export const Toast = {
  displayToast,
};
