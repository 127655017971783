import { IAccountInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/model";

export const mockAccountsInTheScheme: IAccountInTheScheme[] = [
  {
    AccountNumber: "32050.000100",
    AssessmentNumber: "3054054100",
    PropertyAddress: "71 Bold Hill Rd, Pakenham",
    PrincipalBalance: 23118.48,
    InterestBalance: 0.0,
    BalanceOutstanding: 23118.48,
    AmountDue: 23118.48,
    PayoutAmount: 23118.48,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 23118.48,
    EstimatedShareOfPercentageCosts: 0.22,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11495",
    SchemeAccountId: 804,
  },
  {
    AccountNumber: "32050.000200",
    AssessmentNumber: "3054054200",
    PropertyAddress: "73 Bold Hill Rd, Pakenham",
    AccountName: "Antolovic, J I & N",
    PrincipalBalance: 34695.73,
    InterestBalance: 0.0,
    BalanceOutstanding: 34695.73,
    AmountDue: 34695.73,
    PayoutAmount: 34695.73,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 34695.73,
    EstimatedShareOfPercentageCosts: 0.34,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11496",
    SchemeAccountId: 805,
  },
  {
    AccountNumber: "32050.000300",
    AssessmentNumber: "3054054400",
    PropertyAddress: "FY1/75 Bold Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 17498.47,
    InterestBalance: 0.0,
    BalanceOutstanding: 17498.47,
    AmountDue: 17498.47,
    PayoutAmount: 17498.47,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 17498.47,
    EstimatedShareOfPercentageCosts: 0.17,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11498",
    SchemeAccountId: 806,
  },
  {
    AccountNumber: "32050.000400",
    AssessmentNumber: "3054054500",
    PropertyAddress: "FY 2/75 Bald Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 8602.72,
    InterestBalance: 0.0,
    BalanceOutstanding: 8602.72,
    AmountDue: 8602.72,
    PayoutAmount: 8602.72,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 8602.72,
    EstimatedShareOfPercentageCosts: 0.08,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11499",
    SchemeAccountId: 807,
  },
  {
    AccountNumber: "32050.000500",
    AssessmentNumber: "3054054600",
    PropertyAddress: "FY 3/75 Bald Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 8602.72,
    InterestBalance: 0.0,
    BalanceOutstanding: 8602.72,
    AmountDue: 8602.72,
    PayoutAmount: 8602.72,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 8602.72,
    EstimatedShareOfPercentageCosts: 0.08,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11500",
    SchemeAccountId: 808,
  },
  {
    AccountNumber: "32050.000600",
    AssessmentNumber: "3054054700",
    PropertyAddress: "FY 4/75 Bald Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 6718.91,
    InterestBalance: 0.0,
    BalanceOutstanding: 6718.91,
    AmountDue: 6718.91,
    PayoutAmount: 6718.91,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 6718.91,
    EstimatedShareOfPercentageCosts: 0.07,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11501",
    SchemeAccountId: 809,
  },
  {
    AccountNumber: "32050.000700",
    AssessmentNumber: "3054054800",
    PropertyAddress: "FY 5/75 Bald Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 6718.91,
    InterestBalance: 0.0,
    BalanceOutstanding: 6718.91,
    AmountDue: 6718.91,
    PayoutAmount: 6718.91,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 6718.91,
    EstimatedShareOfPercentageCosts: 0.07,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11502",
    SchemeAccountId: 810,
  },
  {
    AccountNumber: "32050.000800",
    AssessmentNumber: "3054054900",
    PropertyAddress: "FY 6/75 Bald Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 8477.13,
    InterestBalance: 0.0,
    BalanceOutstanding: 8477.13,
    AmountDue: 8477.13,
    PayoutAmount: 8477.13,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 8477.13,
    EstimatedShareOfPercentageCosts: 0.08,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11503",
    SchemeAccountId: 811,
  },
  {
    AccountNumber: "32050.000900",
    AssessmentNumber: "3054055000",
    PropertyAddress: "FY 7/75 Bald Hill Rd, Pakenham",
    AccountName: "Davis, CA",
    PrincipalBalance: 11302.84,
    InterestBalance: 0.0,
    BalanceOutstanding: 11302.84,
    AmountDue: 11302.84,
    PayoutAmount: 11302.84,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 11302.84,
    EstimatedShareOfPercentageCosts: 0.11,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11504",
    SchemeAccountId: 812,
  },
  {
    AccountNumber: "32050.001000",
    AssessmentNumber: "3054055100",
    PropertyAddress: "77-79 Bald Hill Rd, Pakenham",
    AccountName: "Catford, AH M & Richards-Cole, J M",
    PrincipalBalance: 630300.05,
    InterestBalance: 0.0,
    BalanceOutstanding: 630300.05,
    AmountDue: 630300.05,
    PayoutAmount: 630300.05,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 630300.05,
    EstimatedShareOfPercentageCosts: 6.12,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11505",
    SchemeAccountId: 813,
  },
  {
    AccountNumber: "32050.001100",
    AssessmentNumber: "3054055300",
    PropertyAddress: "83 Bald Hill Rd, Pakenham",
    AccountName: "D Xerri",
    PrincipalBalance: 69429.92,
    InterestBalance: 0.0,
    BalanceOutstanding: 69429.92,
    AmountDue: 69429.92,
    PayoutAmount: 69429.92,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 69429.92,
    EstimatedShareOfPercentageCosts: 0.67,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11507",
    SchemeAccountId: 814,
  },
  {
    AccountNumber: "32050.001200",
    AssessmentNumber: "3054055400",
    PropertyAddress: "Bald Hill Rd, Pakenham",
    AccountName: "Ferrinda, R J",
    PrincipalBalance: 356703.79,
    InterestBalance: 0.0,
    BalanceOutstanding: 356703.79,
    AmountDue: 356703.79,
    PayoutAmount: 356703.79,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 356703.79,
    EstimatedShareOfPercentageCosts: 3.46,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11508",
    SchemeAccountId: 815,
  },
  {
    AccountNumber: "32050.001300",
    AssessmentNumber: "3054055500",
    PropertyAddress: "85 Bald Hill Rd, Pakenham",
    AccountName: "Katie Dietrich",
    PrincipalBalance: 103619.8,
    InterestBalance: 0.0,
    BalanceOutstanding: 103619.8,
    AmountDue: 103619.8,
    PayoutAmount: 103619.8,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 103619.8,
    EstimatedShareOfPercentageCosts: 1.01,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11509",
    SchemeAccountId: 816,
  },
  {
    AccountNumber: "32050.001400",
    AssessmentNumber: "3054055600",
    PropertyAddress: "87 Bald Hill Rd, Pakenham",
    AccountName: "Peter Janousek",
    PrincipalBalance: 41355.72,
    InterestBalance: 0.0,
    BalanceOutstanding: 41355.72,
    AmountDue: 41355.72,
    PayoutAmount: 41355.72,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 41355.72,
    EstimatedShareOfPercentageCosts: 0.4,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11510",
    SchemeAccountId: 817,
  },
  {
    AccountNumber: "32050.001500",
    AssessmentNumber: "3054055700",
    PropertyAddress: "89 Bald Hill Rd, Pakenham",
    PrincipalBalance: 24568.0,
    InterestBalance: 0.0,
    BalanceOutstanding: 24568.0,
    AmountDue: 24568.0,
    PayoutAmount: 24568.0,
    DiscountAmountOnPayout: 0.0,
    NumberOfInstalments: 16,
    InstalmentType:
      "Fixed Instalment of varying Principal & Actual Interest Raised",
    AccountStatus: "Active (Estimated costs)",
    OverpaymentsToComeOffPrincipal: true,
    EstimatedShareOfCosts: 24568.0,
    EstimatedShareOfPercentageCosts: 0.24,
    LastChangedBy: "ClelandS",
    LastChangedOn: new Date("14-Aug-2018 9:48"),
    AssessmentId: "11511",
    SchemeAccountId: 818,
  },
];

export const mockImportSchemeAccounts: IAccountInTheScheme = {
  AccountNumber: "32050.001600",
  AssessmentNumber: "3054055700",
  PropertyAddress: "90 Bald Hill Rd, Pakenham",
  PrincipalBalance: 24568.0,
  InterestBalance: 0.0,
  BalanceOutstanding: 24568.0,
  AmountDue: 24568.0,
  PayoutAmount: 24568.0,
  DiscountAmountOnPayout: 0.0,
  NumberOfInstalments: 16,
  InstalmentType:
    "Fixed Instalment of varying Principal & Actual Interest Raised",
  AccountStatus: "Active (Estimated costs)",
  OverpaymentsToComeOffPrincipal: true,
  EstimatedShareOfCosts: 24568.0,
  EstimatedShareOfPercentageCosts: 0.24,
  LastChangedBy: "ClelandS",
  LastChangedOn: new Date("14-Aug-2018 9:48"),
  AssessmentId: "11511",
  SchemeAccountId: 818,
};
