import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkListViewDisplayName = () => {
  return "Settings - Lookups";
};

export const getBookmarkListViewDetail = () => {
  return "Settings - Lookups - State Roads";
};

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `Settings - State Roads ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.ID + ", " + selectedRow.RoadName,
  ]);
};
