import { putCloneEntityProperty } from "@app/products/property/components/fields/search-name/api";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ContactLookahead_JSON } from "@common/models/contact";
import { isFunction, isNil } from "lodash";

export interface ISearchNameProcessParams {
  data: ContactLookahead_JSON;
  setLoading: (isLoading: boolean) => void;
  processDuplicateData?: () => void;
  handleLoadNameDetail: (data: any) => Promise<void>;
  notificationAction: {
    canNotAddName: (response: APIResponse<any> | undefined) => void;
  };
  isCheckEntityId?: boolean;
}

export const handleSearchNameProcess = async (
  params: ISearchNameProcessParams
) => {
  //Props data
  const {
    setLoading,
    data,
    processDuplicateData,
    handleLoadNameDetail,
    notificationAction,
    isCheckEntityId = true,
  } = params;

  //Variables
  const entityId = data?.Entity_Id;
  const hasEntityId =
    !isNil(entityId) && !isNaN(parseInt(entityId)) && isCheckEntityId;

  const idSearch = data?.ID;
  const hasID = !isNil(idSearch);

  if (hasEntityId) {
    if (isFunction(processDuplicateData)) {
      processDuplicateData();
      return;
    }
    setLoading(true);
    //Handle load detail data
    await handleLoadNameDetail({ ...data });
    setLoading(false);
  } else if (hasID) {
    setLoading(true);
    /**
     * The cloning API method to return the DTO_Entity_Create object
     *  after cloning the Core Contact into Property.
     **/
    const response = await putCloneEntityProperty(idSearch);
    if (isSuccessResponse(response) && response?.data?.Entity_Name_Address) {
      ///Handle load detail data
      await handleLoadNameDetail({
        ...response.data.Entity_Name_Address,
      });
    } else {
      //Show notification if can't add contact
      notificationAction.canNotAddName(response);
    }
    setLoading(false);
  }
};
