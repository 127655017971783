import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { globalStoreInstance } from "@common/stores/global/store";
import { Toast } from "@components/toast";

export const getPositions = async (productType: number) => {
  try {
    const response = await CoreAPIService.getClient().get(
      `${globalStoreInstance.allSetting.baseAPIUrl}/api/core/internal/position/${productType}`
    );

    if (
      !response ||
      response.status !== APIResponseStatus.SUCCESS ||
      !response.data
    ) {
      console.warn(`load list position with id: ${productType} fail`);
      Toast.displayToast({
        content: "🚀 Load Inspection Fail !",
        type: "error",
      });
      return;
    }

    return response.data;
  } catch (error) {
    Toast.displayToast({ content: "🚀 Load Inspection Fail !", type: "error" });
    console.error("Load Inspection Fail !", error);
  }
};
