import { ICCRoute } from "@common/constants/ICCRoute";
import { homepageShortcutsRoute } from "@common/pages/settings/homepage/homepage-shortcuts/route";
import { homepageViewsRoute } from "@common/pages/settings/homepage/homepage-views/route";

export const homepageRoute: ICCRoute = {
  path: "homepage",
  name: "Homepage",
  children: [
    homepageViewsRoute,
    homepageShortcutsRoute,
    {
      path: "users-by-shortcuts",
      name: "Users by Shortcuts",
      component: require("./users-by-shortcuts/_index").default,
    },
    {
      path: "users-by-views",
      name: "Users by Views",
      component: require("./users-by-views/_index").default,
    },
  ],
};
