import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { odataCoreGetKeywordsByType } from "@app/core/components/common/utils";
import { Officers } from "@app/core/inspections/[id]/model";
import { Svc_NoticeType } from "@app/core/notices/[id]/model";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { nameOfFactory } from "@common/utils/common";

const nameOfNoticeType = nameOfFactory<Svc_NoticeType>();
const nameOfOfficer = nameOfFactory<Officers>();
const nameOfCoreKeyword = nameOfFactory<CoreKeyword>();

export const noticeOptionData = [
  {
    label: "Complied",
    value: "Complied",
  },
  {
    label: "Revoked",
    value: "Revoked",
  },
];

export const exposedToAPIOptionData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const renderOptionNoticeType = (dataURL: string) => {
  const optionNoticeType: IOptionInputPicker = {
    dialog: {
      titleHeader: "Pick Notice Type",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfNoticeType("Name"),
      colShowComboboxSearch: [
        { field: nameOfNoticeType("Name"), title: "Name" },
      ],
    },
    grid: {
      columnFields: [
        { field: nameOfNoticeType("Name"), title: "Name" },
        {
          field: nameOfNoticeType("Classification"),
          title: "Classification",
        },
        {
          field: nameOfNoticeType("PenaltyUnits"),
          title: "Penalty Units",
          format: NUMBER_FORMAT.NUMBER2,
        },
        {
          field: nameOfNoticeType("Description"),
          title: "Section Of Legislation",
        },
        {
          field: nameOfNoticeType("Code"),
          title: "Code",
        },
      ],
      primaryField: nameOfNoticeType("ID"),
      dataUrl: dataURL,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOfNoticeType("Name"),
            dir: "asc",
          },
        ],
      },
    },
  };

  return optionNoticeType;
};

export const optionOfficer: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Officer",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfOfficer("Name"),
    colShowComboboxSearch: [{ field: nameOfOfficer("Name"), title: "Name" }],
  },
  grid: {
    columnFields: [
      { field: nameOfOfficer("Name"), title: "Name" },
      {
        field: nameOfOfficer("Position"),
        title: "Position",
      },
      { field: nameOfOfficer("Email"), title: "Email" },
      { field: nameOfOfficer("WorkPhone"), title: "Phone" },
    ],
    primaryField: nameOfOfficer("ID"),
    dataUrl: CORE_OFFICERS_ODATA,
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfOfficer("Name"),
          dir: "asc",
        },
      ],
    },
  },
};

export const renderOptionNoticeKeyWord = (
  titleHeader: string,
  keywordType: KEYWORD_TYPE,
  productType: PRODUCT_TYPE_NUMBER,
  selectableMode: "none" | "single" | "multiple" | undefined
) => {
  const option: IOptionInputPicker = {
    dialog: {
      titleHeader: titleHeader ?? "",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfCoreKeyword("Name"),
      colShowComboboxSearch: [
        { field: nameOfCoreKeyword("Name"), title: "Name" },
      ],
    },
    grid: {
      columnFields: [{ field: nameOfCoreKeyword("Name"), title: "Name" }],
      primaryField: nameOfCoreKeyword("Keyword_ID"),
      dataUrl: odataCoreGetKeywordsByType(keywordType, productType),
      selectableMode: selectableMode,
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOfCoreKeyword("Name"),
            dir: "asc",
          },
        ],
      },
    },
  };

  return option;
};
