import { getViewConfigurations } from "@app/products/property/assessments/master-properties/api";
import { DTO_MasterProperty_Assessment } from "@app/products/property/assessments/master-properties/[id]/components/child-screens/assessments/model";
import { ICCViewConfiguration } from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export type ILoadMPAssessmentsResponse =
  | [
      APIResponse<DTO_MasterProperty_Assessment>,
      APIResponse<ICCViewConfiguration | undefined>
    ]
  | APIResponse<DTO_MasterProperty_Assessment | ICCViewConfiguration>
  | undefined;

export const loadMasterPropertyAssessments = async (
  viewConfigurationId: number,
  masterPropertyId: number
): Promise<ILoadMPAssessmentsResponse> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_MasterProperty_Assessment>(
        `/api/property/internal/masterproperty/${masterPropertyId}/assessments?includeInactive=false`
      ),
      getViewConfigurations(viewConfigurationId),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
