import { getFinancialSummaryDetailsTabById } from "@app/products/property/assessments/financial-summaries/[id]/components/reference-sidebar/detail/api";
import { IFinancialSummaryDetail } from "@app/products/property/assessments/financial-summaries/[id]/components/reference-sidebar/detail/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const AssessmentFinancialSummaryDetailTab = observer(() => {
  const [financialSummaryInfo, setFinancialSummaryInfo] = useState<
    IFinancialSummaryDetail | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const { lastSelectedRow } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const financialSummaryId: number = parseInt(id) || lastSelectedRow.Id;

  useEffect(() => {
    if (!financialSummaryId) return;
    setIsLoading(true);
    getFinancialSummaryDetailsTabById(financialSummaryId).then((data) => {
      setFinancialSummaryInfo(data);
      setIsLoading(false);
    });
  }, [financialSummaryId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!financialSummaryInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded
          title={
            <ProductReferenceHeading
              title={"ID:"}
              value={financialSummaryInfo?.ID + ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Name:"}
                value={financialSummaryInfo?.Name}
              />
              <ProductReferenceRow
                title={"Total Amount:"}
                value={formatDisplayValue(
                  financialSummaryInfo?.TotalAmount,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Total Interest:"}
                value={formatDisplayValue(
                  financialSummaryInfo?.TotalInterest,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
              <ProductReferenceRow
                title={"Total Balance:"}
                value={formatDisplayValue(
                  financialSummaryInfo?.TotalBalance,
                  CURRENCY_FORMAT.CURRENCY1
                )}
                format="currency"
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
