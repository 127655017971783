import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { IColumnFields } from "@components/cc-grid/model";

export const colApplicationCategories: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.Name,
    title: "Name",
    width: 300,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.Type,
    title: "Type",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.IsHidden,
    title: "Is Hidden",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IsSelectable,
    title: "Is Selectable",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.IsActive,
    title: "Is Active",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.HasOtherInputs,
    title: "Has Other Inputs",
    width: 200,
  },
  {
    field: TownPlanningFieldMapping.CostOfWorkv,
    title: "Cost of Work",
    width: 300,
    dataType: "numeric",
  },
  {
    field: TownPlanningFieldMapping.SubdivisionLots,
    title: "Subdivision Lots",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.CategoryType,
    title: "Category Type",
    width: 300,
  },
];
