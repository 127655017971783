import { DEFAULT_DYNAMIC_GRID } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/util";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { nameOfFactory } from "@common/utils/common";

import { CCGrid } from "@components/cc-grid/_index";
import { gridDynamicRender } from "@components/setting/setting-dynamic-grid-fields/config";
import { FormRenderProps } from "@progress/kendo-react-form";
import React from "react";

interface IDynamicGridFieldsProps {
  formRenderProps: FormRenderProps;
  isEditing: boolean;
}

const nameOf = nameOfFactory<SettingField>();

export const SettingDynamicGridFields = ({
  formRenderProps,
  isEditing,
}: IDynamicGridFieldsProps) => {
  const { valueGetter, onChange } = formRenderProps;
  return valueGetter(DEFAULT_DYNAMIC_GRID)?.length ? (
    <CCGrid
      readOnly={!isEditing}
      columnFields={gridDynamicRender}
      primaryField={nameOf("FieldName")}
      data={valueGetter(DEFAULT_DYNAMIC_GRID) ?? []}
      editableMode="cell"
      onDataRowChange={(
        dataRow: any,
        fieldChange: string,
        valueChange?: any
      ) => {
        const preData = valueGetter(DEFAULT_DYNAMIC_GRID) ?? [];
        preData.forEach((item: any) => {
          if (item?.FieldName === dataRow?.FieldName) {
            item.Value = valueChange;
            onChange(item.FieldName, { value: valueChange });
          }
        });
        onChange(DEFAULT_DYNAMIC_GRID, { value: preData });
      }}
      itemPerPage={10}
    />
  ) : null;
};
