import { DTO_Assessment } from "@app/products/property/components/dialogs/add-assessment-lookup/model";
import { GISReferenceDialog } from "@app/products/property/components/dialogs/gis-reference/_index";
import { colGISReference } from "@app/products/property/components/dialogs/gis-reference/config";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { SwineBrandDialog } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/swine-brands/_index";
import { colSwineBrand } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/config";
import {
  EPICDetailGridMode,
  EPicDetailDialog,
  IPicDetailDialogProps,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/model";
import { DTO_PIC_Swine } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { useReactivatePICDetailStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/components/form-element/pic-details/store";
import { EKeysOfStepsReactivatePIC } from "@app/products/property/pic/list/components/action-bar/form-steps/reactivate-pic/model";
import { picDetailsValidator } from "@app/products/property/pic/list/util";
import { getDropdownValue, getUUID } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const PicDetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? picDetailsValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const formErrors = errors?.[nameOf("")]
      ? JSON.parse(errors?.[nameOf("")])
      : undefined;

    //store
    const { lovPICDetail } = useReactivatePICDetailStepStoreContext();
    //state
    const [showDialog, setShowDialog] = useState<
      IPicDetailDialogProps | undefined
    >();
    //get value fields
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const gis = getFieldValue("GisReferences") ?? [];
    const swineBrand = getFieldValue("Swines") ?? [];
    const selectedGIS = getFieldValue("_option.GISSelected") ?? [];
    const districtLOVs = getFieldValue("DistrictLOVs");
    const selectedSwineBrand =
      getFieldValue("_option.SwineBrandSelected") ?? [];
    const tradingNameLov = useMemo(() => {
      const holdingList =
        valueGetter(`${EKeysOfStepsReactivatePIC.Association}`)?.Holdings ?? [];
      const newHoldingList = [...holdingList];
      newHoldingList.forEach((item: DTO_Assessment) => {
        if (isNil(item?.Ratepayer_Name)) {
          item.Ratepayer_Name = "";
        }
      });
      return newHoldingList;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * handle add item for (GIS Reference and Swine Brand)
     * @param data
     * @param field
     */
    const handleAddItem = (data: any, mode: EPicDetailDialog) => {
      if (mode === EPicDetailDialog.NewGISReference) {
        //get previous data GIS
        const previousGIS = gis?.length ? [...gis] : [];
        //add more temporary ID for new record
        const newGIS = { ...data, Id: `new_${getUUID()}` };
        //merge previous data and new one
        const gisData = [newGIS, ...previousGIS];
        onChange(nameOf("GisReferences"), {
          value: gisData,
        });
      } else if (mode === EPicDetailDialog.NewSwineBrand) {
        //add more temporary ID for new record
        const newSwineBrand = { ...data, Id: `new_${getUUID()}` };
        //get previous data Swine Brand
        const previousSwineBrand = swineBrand?.length ? [...swineBrand] : [];
        //merge previous data and new one
        const swineBrandData = [newSwineBrand, ...previousSwineBrand];
        onChange(nameOf("Swines"), {
          value: swineBrandData,
        });
      }
    };

    /**
     * handle delete item (GIS Reference and Swine Brand)
     * @param field
     */
    const handleDeleteItem = (mode: EPICDetailGridMode) => {
      if (mode === EPICDetailGridMode.GISReference) {
        const gisId = selectedGIS?.[0]?.Id;
        //filter new list GIS without selected record
        const newGIS = gis.filter((item: DTO_GIS) => item.Id !== gisId);
        onChange(nameOf("GisReferences"), {
          value: newGIS,
        });
        onChange(nameOf("_option.GISSelected"), {
          value: [],
        });
      } else if (mode === EPICDetailGridMode.SwineBrand) {
        const swineBrandId = selectedSwineBrand?.[0]?.Id;
        //filter new list SwineBrand without selected record
        const newSwineBrand = swineBrand.filter(
          (item: DTO_PIC_Swine) => item.Id !== swineBrandId
        );
        onChange(nameOf("Swines"), {
          value: newSwineBrand,
        });
        onChange(nameOf("_option.SwineBrandSelected"), {
          value: [],
        });
      }
    };

    /**
     * handle data change grid for (GIS and SwineBrand)
     * using when editing mode (in row)
     * @param mode
     * @param dataRow
     * @param valueChange
     */
    const handleDataChangeGrid = (
      mode: EPICDetailGridMode,
      dataRow: any,
      valueChange: string
    ) => {
      //GIS Reference
      if (mode === EPICDetailGridMode.GISReference) {
        const gisId = dataRow?.Id;
        let newGIS = [...gis];
        // update value Effective_To and Effective_From
        newGIS = newGIS.map((item: DTO_GIS) => {
          if (
            item.Id === gisId &&
            (valueChange === "Effective_To" || valueChange === "Effective_From")
          ) {
            return {
              ...item,
              Effective_To: dataRow["Effective_To"] ?? null,
              Effective_From: dataRow["Effective_From"] ?? null,
            };
          }
          return item;
        });
        onChange(nameOf("GisReferences"), { value: newGIS });
        //Swine Brand
      } else if (mode === EPICDetailGridMode.SwineBrand) {
        const swineId = dataRow?.Id;
        let newSwine = [...swineBrand];
        newSwine = newSwine.map((item: DTO_PIC_Swine) => {
          if (
            item.Id === swineId &&
            (valueChange === "Effective_To" || valueChange === "Effective_From")
          ) {
            // update value Effective_To and Effective_From
            return {
              ...item,
              Effective_To: dataRow["Effective_To"] ?? null,
              Effective_From: dataRow["Effective_From"] ?? null,
            };
          }
          return item;
        });
        onChange(nameOf("Swines"), { value: newSwine });
      }
    };

    const isDisplayAgentType = useMemo(() => {
      return getDropdownValue(
        getFieldValue("PICTypeId"),
        lovPICDetail?.PICTypes ?? [],
        "Code"
      )?.AgentType;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue("PICTypeId"), lovPICDetail?.PICTypes]);

    return (
      <section className="cc-field-group cc-pic-detail">
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            {/* Always readOnly */}
            {/* TODO: TBC */}
            {/* Assess_Number - Assess_Property_Address (PrimaryHolding) */}
            <CCValueField
              label={"Primary holding"}
              value={getFieldValue("PrimaryHolding")}
            />
          </div>
          <div className="cc-field">
            {/* Always readOnly */}
            {/* Ratepayer_Name (PrimaryHolding) */}
            <CCValueField
              label={"Occupier"}
              value={getFieldValue("Occupier")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field cc-col-span-2">
            {/* Always readOnly */}
            {/* Assess_Property_Address (PrimaryHolding) */}
            <CCValueField
              label={"PIC address"}
              value={getFieldValue("_option.PICAddress")}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Trading name</label>
            <Field
              name={nameOf("TradingNameId")}
              component={CCSearchComboBox}
              data={tradingNameLov ?? []}
              textField="Ratepayer_Name"
              dataItemKey="Assessment_Id"
              isUseDefaultOnchange
              disabled
            />
          </div>
          <div className="cc-field cc-col-span-2">
            {/* Always readOnly */}
            {/* RLP Assessment (PrimaryHolding) */}
            <CCValueField
              label={"RLP board"}
              value={getFieldValue("_option.RLPBoards")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Type
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("PICTypeId")}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
              component={CCSearchComboBox}
              data={lovPICDetail?.PICTypes ?? []}
              textField="Name"
              dataItemKey="Code"
              disabled
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Land use</label>
            <Field
              name={nameOf("PICLandUseId")}
              component={CCSearchComboBox}
              data={lovPICDetail?.PICLandUses ?? []}
              textField="Name"
              dataItemKey="Code"
              disabled
            />
          </div>
          {isDisplayAgentType && (
            <div className="cc-field">
              <label className="cc-label">Agent license</label>
              <Field
                name={nameOf("AgentLicenseNumber")}
                placeholder="Agent license"
                component={CCInput}
                readOnly={options?.isReadOnly}
              />
            </div>
          )}
          <div className="cc-field">
            <label className="cc-label">District</label>
            <Field
              name={nameOf("DistrictId")}
              component={CCSearchComboBox}
              data={districtLOVs ?? []}
              textField="Name"
              dataItemKey="Code"
              disabled
            />
          </div>
          <div className="cc-field">
            {/* Always readOnly */}
            <CCValueField
              label={"PIC number"}
              value={getFieldValue("PICNumber")}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel
              title="GIS references"
              errorMessage={formErrors?.GISReferences}
            />
            <CCGrid
              readOnly={options?.isReadOnly}
              toolbar={
                !options?.isReadOnly ? (
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fas fa-plus"
                      title="Add"
                      onClick={(event: any) => {
                        event.preventDefault();
                        setShowDialog({
                          mode: EPicDetailDialog.NewGISReference,
                        });
                      }}
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove"
                      onClick={(event: any) => {
                        event.preventDefault();
                        handleDeleteItem(EPICDetailGridMode.GISReference);
                      }}
                      disabled={selectedGIS?.length !== 1}
                    />
                  </div>
                ) : null
              }
              onDataRowChange={(dataRow: any, fieldName: any) => {
                handleDataChangeGrid(
                  EPICDetailGridMode.GISReference,
                  dataRow,
                  fieldName
                );
              }}
              editableMode={!options?.isReadOnly ? "row" : undefined}
              className="cc-gis-reference-grid-data"
              data={gis ?? []}
              columnFields={colGISReference}
              primaryField={"Id"}
              selectableMode="single"
              onSelectionChange={(dataItem: any) => {
                onChange(nameOf("_option.GISSelected"), {
                  value: dataItem,
                });
              }}
              selectedRows={selectedGIS}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Swine brand" errorMessage={formErrors?.Swines} />
            <CCGrid
              readOnly={options?.isReadOnly}
              toolbar={
                !options?.isReadOnly ? (
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      iconClass="fas fa-plus"
                      title="Add"
                      onClick={(event: any) => {
                        event.preventDefault();
                        setShowDialog({ mode: EPicDetailDialog.NewSwineBrand });
                      }}
                    />
                    <Button
                      type="button"
                      iconClass="fas fa-minus"
                      title="Remove"
                      onClick={(event: any) => {
                        event.preventDefault();
                        handleDeleteItem(EPICDetailGridMode.SwineBrand);
                      }}
                      disabled={selectedSwineBrand?.length !== 1}
                    />
                  </div>
                ) : null
              }
              editableMode={!options?.isReadOnly ? "row" : undefined}
              data={swineBrand ?? []}
              columnFields={colSwineBrand}
              primaryField={"Id"}
              selectableMode="single"
              onSelectionChange={(dataItem: any) => {
                onChange(nameOf("_option.SwineBrandSelected"), {
                  value: dataItem,
                });
              }}
              onDataRowChange={(dataRow: any, fieldName: any) => {
                handleDataChangeGrid(
                  EPICDetailGridMode.SwineBrand,
                  dataRow,
                  fieldName
                );
              }}
              selectedRows={selectedSwineBrand}
            />
          </div>
        </div>
        {showDialog?.mode === EPicDetailDialog.NewGISReference ? (
          <GISReferenceDialog
            onClose={() => {
              setShowDialog(undefined);
            }}
            onSubmit={(data: DTO_GIS) => {
              handleAddItem(data, EPicDetailDialog.NewGISReference);
              setShowDialog(undefined);
            }}
            gisReferenceData={gis ?? []}
            gisTypes={lovPICDetail?.GISType ?? []}
          />
        ) : null}
        {showDialog?.mode === EPicDetailDialog.NewSwineBrand ? (
          <SwineBrandDialog
            onClose={() => {
              setShowDialog(undefined);
            }}
            onSubmit={(data: DTO_PIC_Swine) => {
              handleAddItem(data, showDialog?.mode);
              setShowDialog(undefined);
            }}
          />
        ) : null}
      </section>
    );
  }
);
