import {
  colVotingEntitlementByElection,
  colVotingNominateProperty,
} from "@common/pages/contacts/config";
import { CCGrid } from "@components/cc-grid/_index";
import React from "react";
import { mockVotingList } from "./mock";

const Voting = () => {
  return (
    <div className="cc-contact-voting cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Nominate property</label>
            <CCGrid
              className="cc-contact-voting-nominate-grid"
              data={mockVotingList.NominateProperty ?? []}
              columnFields={colVotingNominateProperty}
              primaryField="Id"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Voting entitlement by election</label>
            <CCGrid
              className="cc-contact-voting-entitlement-grid"
              data={mockVotingList.EntitlementByElection ?? []}
              columnFields={colVotingEntitlementByElection}
              primaryField="Id"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voting;
