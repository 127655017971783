import { TownPlanningRecordsState } from "@app/products/town-planning/[id]/model";
import { mockWasteWater } from "@app/products/waste-water/mock";
import { WasteWaterFormData } from "@app/products/waste-water/model";
import { formatDateByKendo } from "@common/utils/formatting";

export const loadAllWastewater = (): WasteWaterFormData[] => {
  const records: any[] = JSON.parse(JSON.stringify(mockWasteWater));
  records.forEach((item: any) => {
    item.DateServed = formatDateByKendo(item.DateServed);
    item.DateDue = formatDateByKendo(item.DateDue);
    item.DateRevoked = formatDateByKendo(item.DateRevoked);
    item.CreatedDate = formatDateByKendo(item.CreatedDate);
  });
  return records;
};

export const loadWastewaterById = async (id?: string): Promise<any> => {
  const records: any[] = JSON.parse(JSON.stringify(mockWasteWater));

  return records.find((record) => String(record.ID) === String(id));
};

export const loadWastewaterRecords = async (
  id: number,
  recordType: number
): Promise<TownPlanningRecordsState | undefined> => {
  return undefined; // TODO: Implement logic based on API
};
