import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IOptionsDefaultFinance } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/model";
import { getIndexField } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/components/common-form-finance/util";
import { logic } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/logic";
import { IDataLogic } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/finance/forms/finance-integration-settings/model";
import { FormRenderProps } from "@progress/kendo-react-form";

export interface IOptionsInvoiceNumberSettings {
  item: any;
  options: IOptionsDefaultFinance;
  index: number;
}

export const optionsInvoiceNumberSettings = (
  item: any,
  formRenderProps: FormRenderProps
): IOptionsInvoiceNumberSettings => {
  let options: IOptionsDefaultFinance = {
    showField: false,
    typeField: "",
    rowsField: 5,
    disabled: undefined,
  };
  let position = undefined;
  const enumFieldName = +item.FieldName || item.FieldName;

  let groupShow: ECorporateSettingsField[] = [];

  /* ====  Logic  ==== */
  const groupShowLogic: ECorporateSettingsField[] = [];
  const dataLogic: IDataLogic = logic(formRenderProps).IntegrationSettingEnums;
  const dataLogicArr = Object.keys(dataLogic);
  dataLogicArr.forEach((item: string) => {
    if (dataLogic[item].isVisible) {
      groupShowLogic.push(...dataLogic[item].child);
    }
  });
  /* ====  / Logic  ==== */
  groupShow = groupShowLogic;

  /* ====  Defined type  ==== */
  let groupTextArea: number[] = [];

  let groupTable: number[] = [];

  let groupTextOnly: number[] = [];
  /* ====  / Defined type  ==== */

  /* ====  Set options  ==== */
  if (groupShow.includes(enumFieldName)) {
    options.showField = true;
  }

  if (groupTextArea.includes(enumFieldName)) {
    options.typeField = "textArea";
  }

  if (groupTable.includes(enumFieldName)) {
    options.typeField = "table";
  }

  if (groupTextOnly.includes(enumFieldName)) {
    options.typeField = "textOnly";
  }

  const index = getIndexField(groupShow, position, enumFieldName);
  /* ====  / Set options  ==== */
  return {
    item,
    options,
    index,
  };
};
