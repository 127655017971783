import { ICCRoute } from "@common/constants/ICCRoute";

export const outStandingActionsRoute: ICCRoute = {
  path: "outstanding-actions",
  name: "Outstanding Actions",
  children: [
    {
      path: "recorded-on",
      name: "Recorded On",
      component: require("./recorded-on/_index").default,
    },
    {
      path: "status",
      name: "Status",
      component: require("./status/_index").default,
    },
    {
      path: "type",
      name: "Type",
      component: require("./type/_index").default,
    },
    {
      path: "action-by",
      name: "Action By",
      component: require("./action-by/_index").default,
    },
  ],
};
