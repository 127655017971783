import { TooltipSearch } from "@common/pages/search/components/search-results/components/tooltip-search/_index";
import { getURLPage } from "@common/pages/search/util";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  GridCellProps,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import React from "react";
import { Link } from "react-router-dom";

export const SearchComponentCell = ({
  className,
  dataItem,
  style,
  colSpan,
  ariaColumnIndex,
  isSelected,
  columnIndex,
  id,
}: GridCellProps) => {
  const navigationAttributes = useTableKeyboardNavigation(id + "");
  return (
    <td
      className={`${className} cc-grid-link-text`}
      style={style}
      colSpan={colSpan}
      role={"gridcell"}
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: columnIndex,
      }}
      {...navigationAttributes}
    >
      <CCTooltip
        className="cc-tooltip-search"
        customTemplate={<TooltipSearch dataItem={dataItem} />}
        position="auto"
        type="custom"
        content=" "
      >
        <div className="cc-search-item">
          <Link
            to={{
              pathname: getURLPage(dataItem),
            }}
          >
            <span className="cc-search-item-value">{dataItem.Component}</span>
          </Link>
        </div>
      </CCTooltip>
    </td>
  );
};
