import { IAttributeData } from "@app/products/property/model";
import { usePicAttributesStore } from "@app/products/property/pic/[id]/components/child-screens/attributes/store";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { colAttributes } from "./config";

export const PicAttributes = observer(() => {
  const { picId } = usePicStore();
  const { isLoading, attributes, loadAttributes, resetStore } =
    usePicAttributesStore();
  const nameOf = nameOfFactory<IAttributeData>();

  useEffect(() => {
    if (picId) loadAttributes(picId);
    return () => {
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picId, loadAttributes]);

  return (
    <div className="cc-pics-attributes">
      <div className="cc-grid-control-between">
        <div className="cc-grid-control-left">
          <label className="cc-label">User defined attributes</label>
        </div>
      </div>

      <CCGrid
        isLoading={isLoading}
        className="cc-attributes-grid"
        data={attributes || []}
        columnFields={colAttributes}
        primaryField={nameOf("Id")}
        editableMode="row"
        itemPerPage={10}
      />
    </div>
  );
});
