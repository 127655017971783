import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
import { LOOKUPNAME } from "./ams.lookups";

// export const AssetRegisterGridViewColumns1 : IColumnFieldDef[] = [
//   { field: "AssetNumber", title: "Number", width: 175, type: "text" },
//   { field: "AssetDescription", title: "Description", width: 300, type: "text" },
//   { field: "AssetCategory", title: "Category", width: 300, type: "text" },
//   { field: "Status", title: "Status", width: 150, type: "text" },
//   { field: "DesignLife", title: "Design Life", width: 150, type: "text" },
//   { field: "RemainingLife", title: "Remaining Life", width: 150, type: "text" },
//   { field: "InstallDate", title: "Install Date", width: 150, type: "text" },
//   { field: "CurrentValue", title: "Current Value", width: 150, type: "text" },
//   { field: "ReplacementCost", title: "Replacement Cost", width: 250, type: "text" },
// ];

export const AssetRegisterGridViewColumns: IColumnFields[] = [
  //{ field: "Id", title: "Id", width: 175, type: "number" },
  //{ field: "CatalogueId", title: "Catalogue Id", width: 175, type: "number" },
  {
    field: "AssetNumber",
    title: "Asset Number",
    width: 150,
    locked: true,
    linkTo: (dataItem) => {
      return `/ams/assets/register` + dataItem.ID;
    },
  },
  {
    field: "AssetDescription",
    title: "Description",
    width: 450,
    dataType: "text",
  },
  {
    field: "CategoryId",
    title: "Category",
    width: 175,
    lookup: LOOKUPNAME.AssetCategory,
    dataType: "enumLookup",
  },
  // { field: "CategoryName", title: "Category", width: 300, type: "text", dataType:"enum" },
  {
    field: "AssetStatusId",
    title: "Status",
    width: 120,
    lookup: LOOKUPNAME.AssetStatus,
    format: NUMBER_FORMAT.NUMBER2,
    dataType: "enumLookup",
  },
  //{ field: "AssetStatus", title: "Status", width: 175, type: "text" },
  //{ field: "IsWrittenOffId", title: "Written-Off", width: 175, lookup:'System YesNo', type: "number" },
  //{ field: "IsWrittenOff", title: "Written-Off", width: 175, type: "text" },
  //{ field: "OwnerTypeId", title: "Owner Type", width: 175, lookup:'Owner Type Lookup', type: "number" },
  //{ field: "OwnerType", title: "Owner Type", width: 175, type: "text" },
  {
    field: "SerialNumber",
    title: "Serial Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "EquipmentNumber",
    title: "Equipment Number",
    width: 175,
    dataType: "text",
  },
  //{ field: "IsReportableId", title: "REPORTABLE_ID", width: 175, lookup:'System YesNo', type: "number" },
  //{ field: "IsReportable", title: "Is Reportable", width: 175, type: "text" },
  //{ field: "IsBulkAssetId", title: "Is Bulk Asset Id", width: 175, lookup:'System YesNo', type: "number" },
  //{ field: "IsBulkAsset", title: "Is Bulk Asset", width: 175, type: "text" },
  //{ field: "CompanyId", title: "Company Id", width: 175, lookup:'System AR Company', type: "number" },
  //{ field: "CompanyName", title: "Company", width: 175, type: "text" },
  //{ field: "DivisionId", title: "Division Id", width: 175, lookup:'System AR Division', type: "number" },
  //{ field: "DivisionName", title: "Division", width: 175, type: "text" },
  //{ field: "OfficerId", title: "Officer Id", width: 175, lookup:'System AR Officer', type: "number" },
  //{ field: "OfficerName", title: "Officer", width: 175, type: "text" },
  //{ field: "OwnerDate", title: "Owner Date", width: 175, type: "date" },
  //{ field: "ControllingOrgId", title: "Organisation Id", width: 175, lookup:'System AR Controlling Organisation', type: "number" },
  //{ field: "ControllingOrg", title: "Organisation", width: 175, type: "text" },
  //{ field: "AssetManagerId", title: "Asset Mgr Id", width: 175, lookup:'System AR Asset Manager', type: "number" },
  //{ field: "AssetManagerName", title: "Asset Mgr", width: 175, type: "text" },
  //{ field: "PurchaseDate", title: "Purchase Date", width: 150, type: "date" },
  //{ field: "InstallationDate", title: "Install Date", width: 150, type: "date" },
  //{ field: "PurchaseCost", title: "Purchase Cost", width: 150, type: "number" },

  //{ field: "PrimaryServiceManagerId", title: "Primary Service Manager", width: 150, lookup:'System AR Primary Service Manager', type: "number" },
  //{ field: "PrimaryServiceManagerName", title: "Primary Service Manager Name", width: 150, type: "number" },
  //{ field: "SecondaryServiceManagerId", title: "Secondary Service Manager", width: 150, lookup:'System AR Secondary Service Manager', type: "number" },
  //{ field: "SecondaryServiceManagerName", title: "Secondary Service Manager Name", width: 150, type: "number" },
  //{ field: "PrimaryServiceNameId", title: "Primary Service Name", width: 150, lookup:'System AR Primary Service Name', type: "number" },
  //{ field: "PrimaryServiceName", title: "Primary Service Name", width: 150, type: "number" },
  //{ field: "SecondaryServiceNameId", title: "Secondary Service Name", width: 150, lookup:'System AR Secondary Service Name', type: "number" },
  //{ field: "SecondaryServiceName", title: "Secondary Service Name", width: 150, type: "number" },
  //{ field: "PrimaryMaintenanceContractId", title: "Primary Maintenance Contract", width: 150, lookup:'System AR Primary Maintenance Contract', type: "number" },
  //{ field: "PrimaryMaintenanceContractName", title: "Primary Maintenance Contract", width: 150, type: "number" },
  //{ field: "SecondaryMaintenanceContractId", title: "Secondary Maintenance Contract", width: 150, lookup:'System AR Secondary Maintenance Contract', type: "number" },
  //{ field: "SecondaryMaintenanceContractName", title: "Secondary Maintenance Contract", width: 150, type: "number" },
  //{ field: "PrimaryMaintenanceManagerId", title: "Primary Maintenance Manager", width: 150, lookup:'System AR Primary Maintenance Manager', type: "number" },
  //{ field: "PrimaryMaintenanceManagerName", title: "Primary Maintenance Manager", width: 150, type: "number" },
  //{ field: "SecondaryMaintenanceManagerId", title: "Secondary Maintenance Manager", width: 150, lookup:'System AR Secondary Maintenance Manager', type: "number" },
  //{ field: "SecondaryMaintenanceManagerName", title: "Secondary Maintenance Manager", width: 150, type: "number" },

  //{ field: "InstallationCost", title: "Install Cost", width: 150, type: "number" },
  //{ field: "PurchaseOrderNumber", title: "Purchase Number", width: 175, type: "text" },
  {
    field: "WarrantyEndDate",
    title: "Warranty End",
    width: 120,
    format: DATE_FORMAT.DATE,
    dataType: "date",
  },
  //{ field: "ReplacementValue", title: "Replacement Cost", width: 250, type: "number" },
  {
    field: "DesignLife",
    title: "Design Life",
    width: 120,
    dataType: "numeric",
  },
  //{ field: "SalvageValue", title: "Salvage Value", width: 250, type: "number" },
  //{ field: "ItemNumber", title: "Item Number", width: 175, type: "text" },
  //{ field: "ManufacturerId", title: "Manufacturer Id", width: 175, lookup:'System AR Manufacturer', type: "number" },
  //{ field: "ManufacturerName", title: "Manufacturer", width: 175, type: "text" },
  //{ field: "SupplierId", title: "Supplier Id", width: 175, lookup:'System AR Supplier', type: "number" },
  //{ field: "SupplierName", title: "Supplier", width: 175, type: "text" },

  //{ field: "PriorityEnvironmentId", title: "PriorityEnvironmentId", width: 150, lookup:'System AR Environment', type: "number" },
  //{ field: "PriorityEnvironment", title: "PriorityEnvironment", width: 150, type: "text" },
  //{ field: "PrioritySafetyId", title: "PrioritySafetyId", width: 150, lookup:'System AR Safety', type: "number" },
  //{ field: "PrioritySafety", title: "PrioritySafety", width: 150, type: "text" },
  //{ field: "PriorityProductId", title: "PriorityProductionId", width: 150, lookup:'System AR Production', type: "number" },
  //{ field: "PriorityProduct", title: "Priority Production", width: 150, type: "text" },
  //{ field: "PriorityQualityId", title: "PriorityQualityId", width: 150, lookup:'System AR Quality', type: "number" },
  //{ field: "PriorityQuality", title: "PriorityQuality", width: 150, type: "text" },
  //{ field: "TotalCost", title: "TotalCost", width: 150, type: "number" },
  //{ field: "YearToDateCost", title: "YearToDateCost", width: 150, type: "number" },
  //{ field: "IsBookableId", title: "IsBookableId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsBookable", title: "IsBookable", width: 150, type: "text" },
  //{ field: "IsLeaseableId", title: "IsLeaseableId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsLeaseable", title: "IsLeaseable", width: 150, type: "text" },
  //{ field: "IsLockableId", title: "IsLockableId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsLockable", title: "IsLockable", width: 150, type: "text" },
  //{ field: "IsInssuredId", title: "IsInssuredId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsInssured", title: "IsInssured", width: 150, type: "text" },
  //{ field: "IsGenerateRevenueId", title: "IsGenerateRevenueId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsGenerateRevenue", title: "IsGenerateRevenue", width: 150, type: "text" },
  //{ field: "IsProduceWasteId", title: "IsProduceWasteId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsProduceWaste", title: "IsProduceWaste", width: 150, type: "text" },
  //{ field: "IsProduceEnergyId", title: "IsProduceEnergyId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsProduceEnergy", title: "IsProduceEnergy", width: 150, type: "text" },
  //{ field: "IsProduceWaterId", title: "IsProduceWaterId", width: 150, lookup:'System YesNo', type: "number" },
  //{ field: "IsProduceWater", title: "IsProduceWater", width: 150, type: "text" },

  //{ field: "AssetLastModifiedDate", title: "Last Modified Date", width: 175, type: "date" },
  //{ field: "AssetLastModifiedBy", title: "Modified By", width: 175, type: "text" },

  //{ field: "ProjectId", title: "ProjectId", width: 150, lookup:'System AR Project', type: "number" },
  //{ field: "BudgetId", title: "BudgetId", width: 150, lookup:'System AR Budget', type: "number" },
  //{ field: "CostCodeId", title: "CostCodeId", width: 150, lookup:'System AR Cost Code', type: "number" },
  //{ field: "ComplexityId", title: "ComplexityId", width: 150, lookup:'System AR Complexity', type: "number" },
  //{ field: "RepairCost", title: "RepairCost", width: 150, type: "number" },
  //{ field: "RequiredAnnualMaintenanceAmount", title: "RequiredAnnualMaintenanceAmount", width: 150, type: "number" },
  //{ field: "CurrentAnnualMaintenanceAmount", title: "CurrentAnnualMaintenanceAmount", width: 150, type: "number" },
  //{ field: "AdditionTypeId", title: "AdditionTypeId", width: 150, lookup:'Addition Type', type: "number" },
  //{ field: "DisposalDate", title: "DisposalDate", width: 175, type: "date" },
  //{ field: "DisposalCost", title: "DisposalCost", width: 150, type: "number" },
  //{ field: "DisposalMethodId", title: "DisposalMethodId", width: 150, lookup:'Disposal Methods', type: "number" },
  //{ field: "ProceedsOfDisposal", title: "ProceedsOfDisposal", width: 150, type: "number" },
  //{ field: "DisposedTo", title: "DisposedTo", width: 150, type: "text" },

  //{ field: "EvalAssetSys", title: "Eva Asset Sys", width: 175, type: "number" },
  //{ field: "ConditionRating", title: "Condition", width: 175, type: "number" },
  //{ field: "EvalReplacementCost", title: "Replacement Cost", width: 150, type: "number" },
  //{ field: "CurrentValue", title: "Current Value", width: 150, type: "number" },
  //{ field: "ResidualLife", title: "Remaining Life", width: 150, type: "number" },
  //{ field: "EvaluationDate", title: "Assessment Date", width: 175, type: "date" },

  //{ field: "EvalDescription", title: "EvalDescription", width: 150, type: "text" },
  //{ field: "EvaluatedBy", title: "EvaluatedBy", width: 150, type: "text" },
];

export const AssetRegisterItemColumns: IColumnFields[] = [
  { field: "Id", title: "Id", width: 175, dataType: "numeric" },
  {
    field: "CatalogueId",
    title: "Catalogue Id",
    width: 175,
    dataType: "numeric",
  },
  { field: "AssetNumber", title: "Asset Number", width: 150, dataType: "text" },
  {
    field: "AssetDescription",
    title: "Description",
    width: 450,
    dataType: "text",
  },
  {
    field: "CategoryId",
    title: "Category",
    width: 175,
    lookup: LOOKUPNAME.AssetCategory,
    dataType: "numeric",
  },
  {
    field: "AssetStatusId",
    title: "Status",
    width: 120,
    lookup: LOOKUPNAME.AssetStatus,
    dataType: "numeric",
  },
  {
    field: "DesignLife",
    title: "Design Life",
    width: 120,
    dataType: "numeric",
  },
  {
    field: "EquipmentNumber",
    title: "Equipment Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "SerialNumber",
    title: "Serial Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "WarrantyEndDate",
    title: "Warranty End",
    width: 120,
    dataType: "date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "EmployeeCustodian",
    title: "Employee Custodian",
    width: 175,
    dataType: "text",
  },
  {
    field: "EmployeeNumber",
    title: "Employee Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "DepartmentId",
    title: "Department",
    width: 175,
    lookup: LOOKUPNAME.department,
    dataType: "numeric",
  },
  {
    field: "LocationId",
    title: "Location",
    width: 175,
    lookup: LOOKUPNAME.location,
    dataType: "numeric",
  },
  {
    field: "CostCentreId",
    title: "Cost Center",
    width: 175,
    lookup: LOOKUPNAME.costCentre,
    dataType: "numeric",
  },
  {
    field: "OsProductId",
    title: "Os Product",
    width: 175,
    lookup: LOOKUPNAME.osProduct,
    dataType: "numeric",
  },
];

export const AmsLLSAssetComputerReportColumns: IColumnFields[] = [
  {
    field: "Asset_Number",
    title: "Asset Number",
    width: 150,
    dataType: "text",
  },
  {
    field: "Asset_Description",
    title: "Description",
    width: 450,
    dataType: "text",
  },
  {
    field: "Status",
    title: "Status",
    width: 120,
    lookup: LOOKUPNAME.AssetStatus,
    dataType: "numeric",
  },
  {
    field: "Serial_Number",
    title: "Serial Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "Equipment_Number",
    title: "Equipment Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "Warranty_End_Date",
    title: "Warranty End",
    width: 120,
    dataType: "date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Design_Life",
    title: "Design Life",
    width: 120,
    dataType: "numeric",
  },
  {
    field: "Employee_Custodian",
    title: "Employee Custodian",
    width: 175,
    dataType: "text",
  },
  {
    field: "Employee_Number",
    title: "Employee Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "Department",
    title: "Department",
    width: 175,
    lookup: LOOKUPNAME.department,
    dataType: "numeric",
  },
  {
    field: "Cost_Centre",
    title: "Cost Center",
    width: 175,
    lookup: LOOKUPNAME.costCentre,
    dataType: "numeric",
  },
  {
    field: "Office_Location",
    title: "Location",
    width: 175,
    lookup: LOOKUPNAME.location,
    dataType: "numeric",
  },
  {
    field: "Os_Product",
    title: "Os Product",
    width: 175,
    lookup: LOOKUPNAME.osProduct,
    dataType: "numeric",
  },
  { field: "Comments", title: "Comment", width: 300, dataType: "text" },
];

export const AmsLLSComputerWarrantyReportColumns: IColumnFields[] = [
  {
    field: "Asset_Number",
    title: "Asset Number",
    width: 150,
    dataType: "text",
  },
  {
    field: "Asset_Description",
    title: "Description",
    width: 450,
    dataType: "text",
  },
  {
    field: "Status",
    title: "Status",
    width: 120,
    lookup: LOOKUPNAME.AssetStatus,
    dataType: "numeric",
  },
  {
    field: "Serial_Number",
    title: "Serial Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "Equipment_Number",
    title: "Equipment Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "Warranty_End_Date",
    title: "Warranty End",
    width: 120,
    dataType: "date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "Design_Life",
    title: "Design Life",
    width: 120,
    dataType: "numeric",
  },
  {
    field: "Employee_Custodian",
    title: "Employee Custodian",
    width: 175,
    dataType: "text",
  },
  {
    field: "Employee_Number",
    title: "Employee Number",
    width: 175,
    dataType: "text",
  },
  {
    field: "Department",
    title: "Department",
    width: 175,
    lookup: LOOKUPNAME.department,
    dataType: "numeric",
  },
  {
    field: "Cost_Centre",
    title: "Cost Center",
    width: 175,
    lookup: LOOKUPNAME.costCentre,
    dataType: "numeric",
  },
  {
    field: "Office_Location",
    title: "Location",
    width: 175,
    lookup: LOOKUPNAME.location,
    dataType: "numeric",
  },
  {
    field: "Os_Product",
    title: "Os Product",
    width: 175,
    lookup: LOOKUPNAME.osProduct,
    dataType: "numeric",
  },
  { field: "Comments", title: "Comment", width: 300, dataType: "text" },
];

export const WorkOrderGridViewColumns: IColumnFields[] = [
  { field: "WorkOrderNumber", title: "Number", width: 160, dataType: "text" },
  { field: "Description", title: "Description", width: 300, dataType: "text" },
  {
    field: "StatusId",
    lookup: "WorkOrderStatus",
    title: "Status",
    width: 150,
    dataType: "text",
  },
  {
    field: "PriorityId",
    title: "Priority",
    width: 150,
    lookup: "WorkOrderPriority",
    dataType: "text",
  },
  //{ field: "PriorityId", title: "Priority", width: 200, type: 'currency', format: "{0:c}" },
  {
    field: "ActioningOfficerId",
    title: "Actioning Officer",
    width: 200,
    lookup: "ActioningOfficer",
    dataType: "text",
  },
  {
    field: "CreateDate",
    title: "Create Date",
    width: 110,
    dataType: "date",
    format: DATE_FORMAT.DATE,
  },
  //{ field: "FinishDate", title: "Finish Date", width: 150 },
  {
    field: "MaintenanceTypeId",
    title: "Maintenance Type",
    width: 150,
    lookup: "MaintenanceType",
    dataType: "text",
  },
  {
    field: "ProjectId",
    title: "Project",
    width: 250,
    lookup: "Project",
    dataType: "text",
  },
  //{ field: "AssetCount", title: "Assets", width: 100, type: 'number' },
];
