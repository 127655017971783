import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  Input,
  InputChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import React from "react";

interface IProps {
  name: string;
  value: string | number;
  label: string;
  isMandatory?: boolean;
  useAutoComplete?: boolean;
  disabled?: boolean;
  onChange?: (arg0: TextAreaChangeEvent | InputChangeEvent) => void;
  colWidth?: number;
  isMultiline?: boolean;
}

export const AmsTextInput: React.FC<IProps> = ({
  name,
  label,
  value,
  isMandatory,
  useAutoComplete,
  disabled,
  onChange,
  isMultiline,
}) => {
  return (
    <div className={`cc-ams-text-input`}>
      <label className="cc-label">
        {label}
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </label>
      {isMultiline ? (
        <div>
          <CCTextArea
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled ?? false}
            autoComplete={useAutoComplete ? "on" : "off"}
            cols={184}
          />
        </div>
      ) : (
        <Input
          className="cc-form-control"
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled ?? false}
          autoComplete={useAutoComplete ? "on" : "off"}
        />
      )}
    </div>
  );
};
