import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface ICreatePICButtonProps {
  assessmentIds?: number[];
  titleIds?: number[];
  isDisabled?: boolean;
}

export const CreatePICButton = observer(
  ({
    assessmentIds = [],
    titleIds = [],
    isDisabled = false,
  }: ICreatePICButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    return (
      <CCNavButton
        title="Create PIC"
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.PIC_Create,
              data: {},
              props: {
                assessmentIds,
                titleIds,
              },
            },
          ]);
        }}
      />
    );
  }
);
