import { ImportCentrelinkFileRestrictions } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/import-centrelink-file/config";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { isEmpty, isNil } from "lodash";

export const requiredCentrelinkFileValidator = (files: UploadFileInfo[]) => {
  if (!isNil(files) && !isEmpty(files)) {
    return files?.every((file) =>
      ImportCentrelinkFileRestrictions.ACCEPT_FORMAT.includes(
        file.extension ?? ""
      )
    )
      ? undefined
      : "Some files are invalid.";
  }
  return "Some files are invalid.";
};
