import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { DeleteFinancialSummaryButton } from "@app/products/property/assessments/financial-summaries/components/action-bar/buttons/delete-financial-summary/_index";
import { EditFinancialSummaryButton } from "@app/products/property/assessments/financial-summaries/components/action-bar/buttons/edit-financial-summary/_index";
import { NewFinancialSummaryButton } from "@app/products/property/assessments/financial-summaries/components/action-bar/buttons/new-financial-summary/_index";
import { colFinancialSummaries } from "@app/products/property/assessments/financial-summaries/config";
import { GET_VIEW_PROPERTY_ASSESSMENTS_FINANCIAL_SUMMARIES } from "@app/products/property/assessments/financial-summaries/constant";
import { VO_Assessment_Financial_Summary } from "@app/products/property/assessments/financial-summaries/model";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/financial-summaries/util";
import { AssessmentFinancialSummaryDetailTab } from "@app/products/property/assessments/financial-summaries/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ASSESSMENT_FINANCIAL_SUMMARIES_ROUTE } from "@app/products/property/assessments/financial-summaries/[id]/constant";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
const nameOf = nameOfFactory<VO_Assessment_Financial_Summary>();

const PropertyFinancialSummaries = (props: any) => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewFinancialSummaryButton />
        <EditFinancialSummaryButton />
        <DeleteFinancialSummaryButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Assessment}
        recordType={RECORDTYPE.CommunityProperty_Financial_Summary}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Financial_Summary}
        keyField={"Id"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ASSESSMENT_FINANCIAL_SUMMARIES_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentFinancialSummaryDetailTab /> },
    ],
  });

  return (
    <CCProductListView
      dataUrl={GET_VIEW_PROPERTY_ASSESSMENTS_FINANCIAL_SUMMARIES}
      columnFields={colFinancialSummaries}
      primaryField={nameOf("Id")}
    />
  );
};

export default PropertyFinancialSummaries;
