import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedCommunicationTemplate } from "@common/pages/settings/communication/template/_id/components/forms/existed/_index";
import { NewCommunicationTemplate } from "@common/pages/settings/communication/template/_id/components/forms/new/_index";
import { ICommunicationTemplateParentSection } from "@common/pages/settings/communication/template/_id/model";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { CheckPermissionSettingWrapper } from "@common/pages/settings/components/check-permission-wrapper/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCommunicationTemplate = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const communicationTemplateId = parseInt(params.id);
  const { resetStore, loadCommunicationTemplate, setParentSection } =
    useCommunicationTemplateStore();
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();

  const [isHasPermission, setIsHasPermission] = useState<boolean>(false);

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ICommunicationTemplateParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (isHasPermission) {
      loadCommunicationTemplate(communicationTemplateId, isNew);
    }
  }, [
    communicationTemplateId,
    isNew,
    loadCommunicationTemplate,
    isHasPermission,
  ]);

  return (
    <CheckPermissionSettingWrapper
      formAction={FormActionCheckPermission.COMMUNICATION_MENU}
      setIsHasPermission={setIsHasPermission}
    >
      {isNew ? <NewCommunicationTemplate /> : <ExistedCommunicationTemplate />}
    </CheckPermissionSettingWrapper>
  );
};

export default observer(ManageCommunicationTemplate);
