import { eventEmitter } from "@/App";
import { eComponent } from "@app/products/property/components/associations/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { disassociateAssessmentSupplementary } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/assessment/remove/api";
import { DTO_Workflow_Supplementary_Disassociate } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/assessment/remove/model";
import { ASSESSMENT_ACCORDION_GRID_ID } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/_index";
import { VO_Supplementary_Assessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/model";
import { SUPPLEMENTARY_TYPES } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { Label } from "@common/stores/products/config";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";
import React, { useState } from "react";

export const RemoveAssessmentButton = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();

  //Get label
  const assessmentLowercaseLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.AssessmentLowercase
  );
  const {
    supplementaryAssessments,
    supplementaryRatesId,
    assessmentsSelected,
    setAssessmentsSelected,
  } = useSupplementaryRatesStore();
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveAssessment = async () => {
    setIsLoading(true);
    const payload: DTO_Workflow_Supplementary_Disassociate = {
      WorkflowHeader: {
        WorkflowDraft: {
          Workflow_Draft_Id: 0,
          WD_Workflow_Status: 3,
        },
        WorkflowApprovals: [],
        AvailableSecondaryWorkflows: [],
      },
      WorkflowDetail: {
        Component_Number: eComponent.Assessment,
        Supplementary_Id: supplementaryRatesId,
        SelectFromMyList: false,
        IsMultipleAllowed: false,
        Component_Ids: assessmentsSelected
          .map(
            (assessment: VO_Supplementary_Assessment) =>
              assessment.Assessment_Id
          )
          .toString(),
      },
    };
    const response = await disassociateAssessmentSupplementary(payload);
    setIsLoading(false);
    if (isSuccessResponse(response) && response.data?.IsSuccess) {
      // Reload data
      eventEmitter.emit(CCGridEventType.RefreshOData, {
        gridIds: [ASSESSMENT_ACCORDION_GRID_ID],
      });
      setAssessmentsSelected([]);
      setIsShowConfirmDialog(false);
      pushNotification({
        title:
          response.data?.SuccessMessage ??
          `Disassociate ${pluralize(
            assessmentLowercaseLabel,
            assessmentsSelected.length
          )} successfully.`,
        type: "success",
      });
    } else {
      setIsShowConfirmDialog(false);
      pushNotification({
        autoClose: false,
        title:
          response.data?.ErrorMessage ??
          `Disassociate ${pluralize(
            assessmentLowercaseLabel,
            assessmentsSelected.length
          )} failed.`,
        type: "error",
      });
    }
  };

  return (
    <>
      <CCNavButton
        title="Remove"
        disabled={
          supplementaryAssessments?.SupplementaryDetail?.Type ===
            SUPPLEMENTARY_TYPES.ASSOCIATE_ASSESSMENTS ||
          assessmentsSelected.length === 0
        }
        onClick={() => {
          setIsShowConfirmDialog(true);
        }}
      />
      {isShowConfirmDialog && (
        <ConfirmDialog
          title="Confirm Delete"
          subMessage={`Are you sure you wish to delete the selected ${pluralize(
            assessmentLowercaseLabel,
            assessmentsSelected.length
          )} from the supplementary?`}
          isLoadingYes={isLoading}
          onClosePopup={() => setIsShowConfirmDialog(false)}
          onAsyncConfirm={handleRemoveAssessment}
        />
      )}
    </>
  );
});
