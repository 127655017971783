import { loadViewConfiguresColumns } from "@app/products/property/api";
import { ViewConfiguration } from "@app/products/property/model";
import { colSupMasterPropertyAssessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/components/detail/config";
import { VO_Supplementary_MasterProperty_Assessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/components/detail/model";
import { VO_Supplementary_MasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Supplementary_MasterProperty_Assessment>();

export const MasterPropertyAssessmentDetail = ({
  dataItem,
}: GridDetailRowProps) => {
  const supplementaryMasterPropertyData =
    dataItem as VO_Supplementary_MasterProperty;
  const [listConfigurationDetail, setListConfigurationDetail] = useState<
    IColumnFields[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);

  const loadMasterPropertyAssessmentConfig = async () => {
    setIsLoading(true);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Supplementary_MasterProperty_Assessments,
      colSupMasterPropertyAssessment
    );
    if (Array.isArray(response)) {
      setListConfigurationDetail(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadMasterPropertyAssessmentConfig();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadMasterPropertyAssessmentConfig();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <div className="cc-master-property-list-detail">
      <CCGrid
        className="cc-associated-assessment-grid"
        dataUrl={`/odata/property/internal/supplementaryratesmasterpropertyassessmentregister/${supplementaryMasterPropertyData.Supplementary_Id}?$count=true&`}
        columnFields={listConfigurationDetail}
        primaryField={nameOf("Assessment_Id")}
        state={{
          filter: {
            logic: "and",
            filters: [
              {
                field: nameOf("Master_Property_Id"),
                operator: "eq",
                value: supplementaryMasterPropertyData.Master_Property_Id,
              },
            ],
          },
        }}
      />
    </div>
  );
};
