import { UITownPlanning } from "@app/products/town-planning/model";
import { PSARCouncilContacts } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/model";
import {
  Application,
  PPRApplicationIdentityPacket,
} from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const getPSARSummaryById = async (
  pprId: number,
  isPSAR: boolean = false
): Promise<APIResponse<Application | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Application>(
      `/api/tp/Internal/tpapplication/${pprId}/?isPSAR=${isPSAR}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getApplicantTypes = async (): Promise<
  APIResponse<IKeyValuePacket[]>
> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[]>(
      `/api/tp/ppr/internal/application/psar/applicanttypes`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getHasOSReferral = async (
  id?: number
): Promise<APIResponse<boolean>> => {
  try {
    return await CoreAPIService.getClient().get<boolean>(
      `/api/tp/ppr/internal/application/psar/${id}/hasosreferral`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCouncilContacts = async (
  id?: number
): Promise<APIResponse<PSARCouncilContacts[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<PSARCouncilContacts[]>(
      `/api/tp/ppr/internal/application/psar/${id}/councilcontacts`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postPSAR = async (
  requestBody: Application
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().post<Application>(
      `/api/tp/ppr/internal/application/psarapplication`,
      requestBody
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const putPSARValidateSave = async (
  workflow: UITownPlanning,
  saveApplication: boolean,
  id: number,
  application?: Application
): Promise<APIResponse<PPRApplicationIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().put<PPRApplicationIdentityPacket>(
      `/api/tp/ppr/internal/application/psar/${id}/validatesave/?saveApplication=${saveApplication}&workFlow=${workflow}`,
      application
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
