import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { NewDebtRecoveryCommonButton } from "@app/products/property/components/action-bar/button/new-debt-recovery/common/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const NewDebtRecoveryButtonAtCollections = observer(() => {
  const { gridSelectedRows, isEmptyData } = useDetailAssessmentListViewStore();
  const { id } = useParams<{ id: string }>();

  const isDisabled = useMemo(() => {
    return (
      (!isEmptyData && gridSelectedRows.length !== 1 && !id) ||
      (!id && isEmptyData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridSelectedRows.length, id, isEmptyData]);

  const recordID = useMemo(() => {
    return gridSelectedRows[0]?.Assessment_Id ?? id;
  }, [gridSelectedRows, id]);

  return <NewDebtRecoveryCommonButton isDisabled={isDisabled} id={recordID} />;
});
