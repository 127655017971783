import { bankAccountNumberValidator } from "@app/products/direct-debit/authorisations/[id]/components/dialogs/create-authorisation/util";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { stringLengthValidator } from "@app/products/direct-debit/authorisations/util";
import { validateBSB } from "@app/products/direct-debit/system-admin/bank-accounts/[id]/components/general/components/form-element/direct-debits/util";
import { nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMaskedTextBox } from "@components/cc-masked-textbox/_index";
import { Field } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<DirectDebitAuthorisation>();

const bankAccountNameValidator = (value: any) =>
  stringLengthValidator(value, 1, 100);

export const DDAuthorisationBankingSection = observer(() => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Bank account name" isMandatory />
          <Field
            name={nameOf("Bank_Account_Name")}
            placeholder={"Bank account name"}
            component={CCInput}
            validator={bankAccountNameValidator}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="BSB number" isMandatory />
          <Field
            name={nameOf("BSB_No")}
            placeholder="BSB number"
            component={CCMaskedTextBox}
            mask="000-000"
            validator={validateBSB}
          />
        </div>
        <div className="cc-field">
          <CCLabel title="Bank account number" isMandatory />
          <Field
            name={nameOf("Bank_Account_No")}
            placeholder="Bank account number"
            component={CCInput}
            // Confirmation 21/05/2024 - No longer than 9 and numberic
            validator={bankAccountNumberValidator}
          />
        </div>
      </div>
    </section>
  );
});
