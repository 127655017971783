import {
  DTO_Transaction,
  eTransactionSources,
} from "@app/products/property/assessments/[id]/components/child-screens/rebates/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import React from "react";
const nameOf = nameOfFactory<DTO_Transaction>();
export const colAppliedOnAssessment: IColumnFields[] = [
  {
    field: nameOf("JournalNumber"),
    title: "Journal Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("TransactionType"), title: "Type" },
  {
    field: nameOf("DateTime"),
    title: "Transaction Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell />,
  },
  { field: nameOf("Description"), title: "Description" },
  {
    field: nameOf("TransactionSource"),
    title: "Source",
    filterByEnum: eTransactionSources,
  },
  {
    field: nameOf("SourceId"),
    title: "Source ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("IsProposed"),
    title: "Is Proposed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("IsCancelled"),
    title: "Is Cancelled",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("AutoAllocate"),
    title: "Auto Allocate",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOf("FinancialYear"), title: "Financial Year" },
  {
    field: nameOf("CreatedOn"),
    title: "Created On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("RebateClaimId"),
    title: "Rebate Claim",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("AssessmentRebateEntitlementId"),
    title: "Rebate Entitlement ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOf("CreatedBy"), title: "Created By" },
  {
    field: nameOf("AssessmentReference"),
    title: "Assessment Reference",
  },
  {
    field: nameOf("AssessmentPropertyAddress"),
    title: "Assessment Property Address",
  },
  {
    field: nameOf("AssessmentRatePayer"),
    title: "Assessment Ratepayer",
  },
  {
    field: nameOf("TransactionId"),
    title: "Transaction ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
