import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { getSiteUserTabDetailsById } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/api";
import { Svc_SiteUser_TabDetail } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/model";
import { CCSiteUserDetailTab } from "@common/pages/settings/security/site-users/_id/components/reference-sidebar/detail/util";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

interface SiteUserTabDetailsProps {
  id?: number;
}

export const SiteUserTabDetails = observer(
  ({ id }: SiteUserTabDetailsProps) => {
    const { lastSelectedRow } = useCCProductListViewStore();
    const { siteUser, siteUserId } = useSiteUserStore();
    const [isLoading, setIsLoading] = useState(false);
    const [siteUserTabDetailsInfo, setSiteUserTabDetailsInfo] =
      useState<Svc_SiteUser_TabDetail>();
    const [responseLoadError, setResponseLoadError] =
      useState<APIResponseError>();
    const ID = id ?? siteUserId ?? lastSelectedRow?.ID;

    const loadDetailData = async () => {
      setIsLoading(true);
      const response = await getSiteUserTabDetailsById(ID);
      if (isSuccessResponse(response)) {
        setSiteUserTabDetailsInfo(response.data);
      } else {
        setResponseLoadError({
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        });
      }
      setIsLoading(false);
    };

    useAddUniqueEventEmitter([
      {
        eventType: CCSiteUserDetailTab.RefreshData,
        listener: () => {
          loadDetailData();
        },
      },
    ]);

    useEffect(() => {
      if (!ID) return;
      loadDetailData();
      // eslint-disable-next-line
    }, [siteUser, ID]);

    if (isLoading) return <Loading isLoading={isLoading} />;
    if (responseLoadError)
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDetailData()}
        />
      );
    return (
      <ProductReferenceBody>
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Login name:"}
            value={siteUserTabDetailsInfo?.LoginName}
          />
          <ProductReferenceRow
            title={"Display name:"}
            value={siteUserTabDetailsInfo?.DisplayName}
          />
          <ProductReferenceRow
            title={"Email:"}
            value={siteUserTabDetailsInfo?.Email}
          />
        </ProductReferenceBlock>
      </ProductReferenceBody>
    );
  }
);
