import { IData } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/forms/model";
import { SettingField } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { getUUID } from "@common/utils/common";
import _, { cloneDeep, isNil } from "lodash";

export const DEFAULT_DYNAMIC_GRID = "GRID_RENDER_ALL_TEXT_FIELD";
export const processDataForm = (
  data: SettingField[],
  listExistedField: (ECorporateSettingsField | string)[] = []
): IData | undefined => {
  if (isNil(data)) return undefined;
  const cloneData = cloneDeep(data);
  let dataForm = null;
  const option: any = {};

  const dataGridDynamic: any = [];
  dataForm = cloneData.reduce((field: any, item: SettingField) => {
    const fieldName = item.FieldName as string;
    let fieldNameConvertToEnumn: string | number = +fieldName;
    if (isNaN(fieldNameConvertToEnumn)) {
      fieldNameConvertToEnumn = fieldName; // get fieldName if fieldName is string. ex: SYSENUM_503
    }
    if (
      listExistedField?.length &&
      !isNil(fieldNameConvertToEnumn) &&
      !listExistedField.includes(fieldNameConvertToEnumn) // check field haven't been manual integrated yet
    ) {
      if (!field.hasOwnProperty(DEFAULT_DYNAMIC_GRID)) {
        field[DEFAULT_DYNAMIC_GRID] = []; // create array for dynamic grid if no having
      }
      dataGridDynamic.push(item); // add field to dynamic grid
    } else {
      const keyName = item["FieldName"];
      field[keyName] = item?.Value;
      if (item.DataType === "Lookup" && item?.ValueDisplayDetails) {
        const customField = `ValueDisplayDetails_${keyName}`;
        option[customField] = item?.ValueDisplayDetails;
      }
    }
    return field;
  }, {});
  if (dataGridDynamic?.length && !isNil(dataForm)) {
    dataForm[DEFAULT_DYNAMIC_GRID] = dataGridDynamic; // return dynamic grid if having
  }
  if (isNil(dataForm)) return undefined;
  return { ...dataForm, option };
};

export const processConfigData = (data: SettingField[]) => {
  if (isNil(data)) return undefined;
  const cloneData = cloneDeep(data);
  return _.keyBy(cloneData, "FieldName");
};

export const convertObjectToArray = (data: IData) => {
  if (!data) return [];
  return Object.keys(data).map((key) => ({
    key,
    value: data[key],
  }));
};

export const CCMainMenuEventType = {
  RefreshMainMenuData: getUUID(),
};
