import { AnimalType } from "@app/products/animals/model";
import { FeeTypesPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/fee-types-picker/_index";
import { IFeeTypes } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { NoData } from "@components/no-data/NoData";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { default as React, useState } from "react";

interface IOptionsProps {
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
  isShowCatOption: boolean;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();

export const Options = ({
  isShowCatOption,
  formRenderProps,
}: IOptionsProps) => {
  const { onChange, valueGetter } = formRenderProps;
  const initFlagBreedingCat = valueGetter(nameOfAnimalType("Flag_BreedingCat"));
  const [isShowBreedingCatFeeType, setIsShowBreedingCatFeeType] =
    useState(initFlagBreedingCat);

  const handleOnChangeBreedingCat = (event: SwitchChangeEvent) => {
    onChange(nameOfAnimalType("Flag_BreedingCat"), { value: event.value });
    setIsShowBreedingCatFeeType(event.value);
  };

  const handleOnChangeBreedingCatFeeType = (data: IFeeTypes) => {
    const id = data ? data.ID : null;
    const nameValue = data ? data.Name : null;
    onChange(nameOfAnimalType("BreedingCatFeeType_ID"), { value: id });
    onChange(nameOfAnimalType("BreedingCatFeeType_Name"), { value: nameValue });
  };

  if (!isShowCatOption) return <NoData />;

  return (
    <div className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Breeding cat?" isMandatory />
          <Field
            name={nameOfAnimalType("Flag_BreedingCat")}
            component={CCSwitch}
            validator={requiredValidator}
            checked={valueGetter(nameOfAnimalType("Flag_BreedingCat"))}
            onChange={handleOnChangeBreedingCat}
          />
        </div>
        {isShowBreedingCatFeeType && (
          <div className="cc-field">
            <CCLabel title="Breeding cat fee type" />
            <Field
              name={nameOfAnimalType("BreedingCatFeeType_Name")}
              component={FeeTypesPicker}
              isGetDetail={false}
              selectableMode="single"
              onPickFeeTypes={handleOnChangeBreedingCatFeeType}
              showClearButton
            />
          </div>
        )}
      </div>
    </div>
  );
};
