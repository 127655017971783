import { useLandTaxExemptionButtonStore } from "@app/products/property/assessments/compliance/candidates/components/action-bar/buttons/land-tax-exemption/store";
import { LandTaxExemptionDialog } from "@app/products/property/assessments/compliance/candidates/components/action-bar/dialogs/land-tax-exemption/_index";
import { useComplianceAssessmentStore } from "@app/products/property/assessments/compliance/[id]/components/child-screens/assessment/store";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export const LandTaxExemptionButton = observer(() => {
  //Handle open or close dialog by Store
  const { isShowDialog, setIsShowDialog } = useLandTaxExemptionButtonStore();
  const { gridSelectedRows } = useCCProductListViewStore(); //Selected assessment ID in Compliance manage page
  const { selectedAssessment } = useComplianceAssessmentStore(); //Selected assessment ID in Assessments/Compliance > Candidates list
  const { assessmentId: assessmentIdInManagePage } = useAssessmentStore(); //Assessment ID in Assessment manage page

  const [assessmentId, setAssessmentId] = useState<number>();

  useEffect(() => {
    if (gridSelectedRows?.length === 1) {
      setAssessmentId(gridSelectedRows[0].Assessment_Id);
    } else {
      setAssessmentId(
        selectedAssessment?.Assessment_Id ?? assessmentIdInManagePage
      );
    }
  }, [gridSelectedRows, selectedAssessment, assessmentIdInManagePage]);

  return (
    <>
      <CCNavButton
        title="Land tax exemption"
        onClick={() => {
          setIsShowDialog(true);
        }}
        disabled={isNil(assessmentId)}
      />
      {isShowDialog && (
        <LandTaxExemptionDialog
          onClose={() => setIsShowDialog(false)}
          assessmentId={assessmentId}
        />
      )}
    </>
  );
});
