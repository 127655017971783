import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";

export const assetsManagerRoute: ICCRoute = {
  path: "assets",
  name: "Assets Manager",
  enum: eProductType.CommunityAsset,
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "manage/:id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
