export interface DTO_ChargeRun_Summary {
  UpdatedOn: Date | null;
  TotalRoundingAmount: number;
  TotalNettLevyAmount: number | null;
  TotalPostponedAmount: number | null;
  TotalDiscountAmount2: number | null;
  TotalDiscountAmount1: number | null;
  TotalRebateAmount: number;
  TotalGrossLevyAmount: number;
  NumberOfAssessments: number | null;
  NumberOfLevies: number | null;
  JournalNumber: number | null;
  InstalmentPlan: string;
  UpdatedBy: string;
  CreatedOn: Date | null;
  CreatedBy: string;
  SelectionCriteria: string;
  Description: string;
  Code: string;
  ChargeName: string;
  ChargeDate: Date | null;
  ChargeRunType: string;
  RatingPeriod: string;
  CRT_Is_Pic: boolean | null;
  ChargeRunId: number | null;
  AssessmentGroupName: string;
}

export enum eChargeRunType {
  RateRun = "Rate Run",
  PIC = "PIC",
}
