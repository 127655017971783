import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { MeterContactsTab } from "@app/products/property/meters/[id]/components/reference-sidebar/contact/_index";
import { MeterDetailTab } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/_index";
import { EnterReadingsButton } from "@app/products/property/meters/list/components/action-bar/buttons/enter-readings/_index";
import { MoveCommentsToInstructionsButton } from "@app/products/property/meters/list/components/action-bar/buttons/move-comments-to-instructions/_index";
import { MoveCommentsToLocationButton } from "@app/products/property/meters/list/components/action-bar/buttons/move-comments-to-location/_index";
import { NewMeterButton } from "@app/products/property/meters/list/components/action-bar/buttons/new-meter/_index";
import { ScheduleSpecialReadingsButton } from "@app/products/property/meters/list/components/action-bar/buttons/schedule-special-readings/_index";
import { AssociateMeterButton } from "@app/products/property/meters/list/components/action-bar/buttons/tools/associate-meter/_index";
import { ResequenceMeterButton } from "@app/products/property/meters/list/components/action-bar/buttons/tools/re-sequence-meter/_index";
import { UpdateMasterMeterButton } from "@app/products/property/meters/list/components/action-bar/buttons/tools/update-master-meter/_index";
import { mockList } from "@app/products/property/meters/list/mock";
import { metersRoute } from "@app/products/property/meters/route";
import { propertyRoute } from "@app/products/property/route";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce, useLocation } from "react-use";
import { colList } from "./config";

export default observer(() => {
  const location = useLocation();
  const history = useHistory();
  const state = location?.state?.state;
  const { gridDataState, setGridDataState, setGridSelectedRows } =
    useCCProductListViewStore();

  useEffectOnce(() => {
    if (state && state.filter) {
      const { field, value } = state.filter;
      const newFilterState: CompositeFilterDescriptor = {
        filters: [
          {
            field: field,
            operator: "eq",
            value: value,
          },
        ],
        logic: "and",
      };
      setGridDataState({ ...gridDataState, filter: newFilterState });

      setGridSelectedRows([]);
      history.replace(history.location.pathname);
    }
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],

    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <EnterReadingsButton />
        <ScheduleSpecialReadingsButton />
        <MoveCommentsToLocationButton />
        <MoveCommentsToInstructionsButton />
      </CCNavButton>,
      <NewMeterButton />,
      <CCNavButton title={"Tools"} type="more">
        <AssociateMeterButton />
        <UpdateMasterMeterButton />
        <ResequenceMeterButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={PROPERTY_METERS_ROUTE}
      //   displayName={getBookmarkDisplayName}
      //   detail={getBookmarkDetail}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <MeterDetailTab />,
      },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Related",
      },
      {
        title: "Contacts",
        component: <MeterContactsTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab recordType={RECORDTYPE.CommunityProperty_Meter} />
        ),
      },
    ],
  });

  return (
    <CCProductListView
      data={mockList}
      columnFields={colList}
      primaryField={"MeterId"}
    />
  );
});
