import { ExistManageParkingPermits } from "@app/products/parking-permits/[id]/components/forms/existed/_index";
import { NewManageParkingPermits } from "@app/products/parking-permits/[id]/components/forms/new/_index";
import { useParkingPermitsStore } from "@app/products/parking-permits/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export default observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { resetStore, loadParkingPermits } = useParkingPermitsStore();

  useEffectOnce(() => {
    if (isNew) return;
    loadParkingPermits(+id);
    return () => resetStore();
  });

  if (isNew) {
    return <NewManageParkingPermits />;
  }
  return <ExistManageParkingPermits />;
});
