import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadAllWastewater } from "@app/products/waste-water/api";
import { NewWastewaterButton } from "@app/products/waste-water/components/action-bar/buttons/new-waste-water/_index";
import { WasteWaterActionBarNavDropdown } from "@app/products/waste-water/components/action-bar/nav-dropdown/_index";
import { WastewaterFieldMapping } from "@app/products/waste-water/model";
import { wasteWaterRoute } from "@app/products/waste-water/route";
import { colWastewaterSystemsBySystemType } from "@app/products/waste-water/systems/by-system-type/config";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/waste-water/systems/by-system-type/util";
import { systemsRoute } from "@app/products/waste-water/systems/route";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/waste-water/util";
import { WasteWaterContactTab } from "@app/products/waste-water/[id]/components/reference-sidebar/contact/_index";
import { WasteWaterDetailsTab } from "@app/products/waste-water/[id]/components/reference-sidebar/details/_index";
import { WasteWaterHistoryTab } from "@app/products/waste-water/[id]/components/reference-sidebar/history/_index";
import { WASTE_WATER_ROUTE } from "@app/products/waste-water/[id]/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: wasteWaterRoute.name,
    leftComponents: [
      <WasteWaterActionBarNavDropdown category={systemsRoute.path} />,
    ],
    centerComponents: [
      <NewWastewaterButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.WasteWater}
        dataSetFilter={MAILMERGEDATASET.WasteWater_System}
        recordType={RECORDTYPE.WW_System}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.WasteWater}
        recordType={RECORDTYPE.WW_System}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={WASTE_WATER_ROUTE}
        productType={PRODUCT_TYPE.WasteWater}
        recordType={RECORDTYPE.WW_System}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <WasteWaterDetailsTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: <WasteWaterContactTab />,
      },
      {
        title: "History",
        component: <WasteWaterHistoryTab />,
      },
    ],
  });

  return (
    <CCProductListView
      columnFields={colWastewaterSystemsBySystemType}
      data={loadAllWastewater() as any}
      state={{
        sort: [{ field: WastewaterFieldMapping.PermitNumber, dir: "desc" }],
        group: [{ field: WastewaterFieldMapping.SystemType, dir: "asc" }],
      }}
      primaryField="ID"
    />
  );
};
