import { mockMeterEnterReading } from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/mock";
import { SearchField } from "@app/products/property/meters/list/components/action-bar/dialogs/enter-readings/model";
import { sleep } from "@common/utils/common";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";

export const loadSearch = async (filter: FilterDescriptor) => {
  const dataList = mockMeterEnterReading.map((meter: any) => {
    return {
      ...meter,
      [SearchField.MeterNumber]: meter.MeterNumber,
      [SearchField.MeterId]: meter.MeterId,
    };
  });

  const data = dataList.slice();
  await sleep(1000);
  return filterBy(data, filter);
};
