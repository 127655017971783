import {
  AnimalType,
  NonRegistrationFeeRule,
} from "@app/products/animals/model";
import { AddFeeRuleButton } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/buttons/add-rule-button/_index";
import { DeleteRulesButton } from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/components/buttons/delete-rule-button/_index";
import {
  getColNonRegistrationFeeRules,
  nameOfNonRegistrationFeeRuleList,
} from "@app/products/animals/system-admin/animal-types/[id]/components/forms/components/child-screens/general/components/fee-calculation-accordion/components/fee-rule-grid/config";
import {
  NonRegistrationFeeRuleList,
  RegistrationFeeRulesType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { useAnimalTypeStore } from "@app/products/animals/system-admin/animal-types/[id]/store";
import { generateNonRegistrationFeeRuleData } from "@app/products/animals/system-admin/animal-types/[id]/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface INonRegistrationFeeRulesProps {
  animalTypeFormObject: AnimalType;
  formRenderProps: FormRenderProps;
}

const nameOfAnimalType = nameOfFactory<AnimalType>();

export const NonRegistrationFeeRules = observer(
  ({
    animalTypeFormObject,
    formRenderProps,
  }: INonRegistrationFeeRulesProps) => {
    const { setupRegistrationFeeRulesDialog } = useAnimalTypeStore();

    const [gridSelectedRows, setGridSelectedRows] = useState<
      NonRegistrationFeeRuleList[]
    >([]);

    const { valueGetter, onChange } = formRenderProps;

    const nonRegistrationFeeRulesData = useMemo(() => {
      return generateNonRegistrationFeeRuleData(
        animalTypeFormObject?.FeeRulesList?.NonRegistrationFeeRules
      );
    }, [animalTypeFormObject?.FeeRulesList?.NonRegistrationFeeRules]);

    const feeRulesList = valueGetter(nameOfAnimalType("FeeRulesList"));

    const submitDeleteHandler = () => {
      const rulesEnum = gridSelectedRows.map(
        (item: NonRegistrationFeeRuleList) => item.RuleType_Enum
      );
      let nonRegistrationFeeRulesList = cloneDeep(
        animalTypeFormObject.FeeRulesList?.NonRegistrationFeeRules ?? []
      );
      nonRegistrationFeeRulesList = nonRegistrationFeeRulesList.filter(
        (feeRule: NonRegistrationFeeRule) =>
          !rulesEnum.includes(feeRule.NonRegistrationFeeRuleType_ENUM)
      );
      onChange(nameOfAnimalType("FeeRulesList"), {
        value: {
          ...feeRulesList,
          NonRegistrationFeeRules: nonRegistrationFeeRulesList,
        },
      });
      setGridSelectedRows([]);
    };

    return (
      <>
        <CCLabel
          title={`Non registration fee rules${
            nonRegistrationFeeRulesData?.length > 0
              ? ` (${nonRegistrationFeeRulesData?.length})`
              : ""
          }`}
        />
        <CCGrid
          selectableMode="multiple"
          primaryField={nameOfNonRegistrationFeeRuleList("RuleType_Enum")}
          columnFields={getColNonRegistrationFeeRules(
            setupRegistrationFeeRulesDialog,
            animalTypeFormObject
          )}
          data={nonRegistrationFeeRulesData ?? []}
          onSelectionChange={(dataItem: NonRegistrationFeeRuleList[]) => {
            setGridSelectedRows([...dataItem]);
          }}
          toolbar={
            <div className="cc-grid-tools-bar">
              <AddFeeRuleButton
                feeRulesType={RegistrationFeeRulesType.NonRegistrationFeeRules}
              />
              <DeleteRulesButton
                gridSelectedRows={gridSelectedRows}
                submitDeleteHandler={submitDeleteHandler}
              />
            </div>
          }
        />
      </>
    );
  }
);
