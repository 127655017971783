import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { CRMS_PRODUCT_NAME } from "@app/products/crms/config";
import { SubCategoryAccordion } from "@app/products/crms/system-admin/categories/[id]/components/accordions/subcategory-accordion/_index";
import { NewSubCategoryButton } from "@app/products/crms/system-admin/categories/[id]/components/forms/components/action-bar/buttons/new-subcategory/_index";
import { CRMSSystemAminCategoryForm } from "@app/products/crms/system-admin/categories/[id]/components/forms/components/child-screens/general/_index";
import { useCRMSSytemAdminCategoryStore } from "@app/products/crms/system-admin/categories/[id]/store";
import { CRMSSystemAdminCategoryDetailsTab } from "@app/products/crms/system-admin/categories/components/reference-sidebar/details/_index";
import { CRMSSystemAdminCategoryHistoryTab } from "@app/products/crms/system-admin/categories/components/reference-sidebar/history/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedCRMSSystemAminCategory = observer(() => {
  const {
    crmsCategory,
    isLoading,
    isDisabled,
    responseLoadError,
    crmsCategoryId,
    loadCategory,
    onSubmit,
  } = useCRMSSytemAdminCategoryStore();
  const { currentUserInfo } = useGlobalStore();
  const { settings } = useCommonCoreStore();
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const isNew = useIsNew();

  const [showSlideBar, setShowSlideBar] = useState(true);

  const cxLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Common_ProductName]
  );
  const listPanelBar: ITabProps[] = [
    {
      title: "Subcategories",
      component: <SubCategoryAccordion />,
    },
  ];

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${CRMS_PRODUCT_NAME} - System Admin - Category`,
      LinkUrl: managePageUrl,
      LinkText: `${CRMS_PRODUCT_NAME} - System Admin - Category ${
        crmsCategoryId ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CustomerService,
      RecordType_ENUM: RECORDTYPE.CUSTOMERSERVICE_ServiceStandardCategory,
      Record_ID: crmsCategoryId ? +crmsCategoryId : 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCategory(parseInt(id), isNew)}
        />
      ) : (
        <>
          <FormNavigation title={`${cxLabel ?? CRMS_PRODUCT_NAME} Category`} />
          <FormTitle title={`${crmsCategory?.Name ?? ""} `} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={isDisabled}
              />,
              <NewSubCategoryButton disabled={isDisabled} categoryId={id} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {crmsCategory && <CRMSSystemAminCategoryForm />}
                <TabTable
                  recordType={
                    RECORDTYPE.CUSTOMERSERVICE_ServiceStandardSubCategory
                  }
                  initialPanels={listPanelBar}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CRMSSystemAdminCategoryDetailsTab />,
                    },
                    {
                      title: "History",
                      component: <CRMSSystemAdminCategoryHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
