import { ICCRoute } from "@common/constants/ICCRoute";

export const groupBookingsRoute: ICCRoute = {
  path: "group-bookings",
  name: "Group Bookings",
  children: [
    {
      path: "all",
      name: "All",
      component: require("./all/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-requestor",
      name: "By Requestor",
      component: require("./by-requestor/_index").default,
    },
  ],
};
