import { loadViewConfiguresColumns } from "@app/products/property/api";
import { fnt_Parcel_w_Association_FactorResult } from "@app/products/property/assessments/[id]/components/child-screens/parcels/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";
import { colParcels } from "./config";

interface IParcelsProps {
  assessmentId: number;
}

const nameOf = nameOfFactory<fnt_Parcel_w_Association_FactorResult>();
export const Parcels = ({ assessmentId }: IParcelsProps) => {
  const [configuredCols, setConfiguredCols] =
    useState<IColumnFields[]>(colParcels);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Assessment_Parcels,
      colParcels
    );
    if (Array.isArray(response)) {
      setConfiguredCols(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCGrid
      dataUrl={`odata/property/internal/assessmentparcelsregister?$count=true&key=${assessmentId}&`}
      columnFields={configuredCols}
      primaryField={nameOf("Parcel_Id")}
      selectableMode="multiple"
    />
  );
};
