import { CORE_LOCATION_REGISTER_ROUTE } from "@app/core/location-register/[id]/constant";
import { LocationRegisterView } from "@app/products/crms/system-admin/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<LocationRegisterView>();

export const colLocationRegister: IColumnFields[] = [
  {
    field: nameOf("Name"),
    title: "Name",
    locked: true,
    linkTo: (dataItem: LocationRegisterView) =>
      `${CORE_LOCATION_REGISTER_ROUTE}/${dataItem.ID}`,
  },
  { field: nameOf("Type"), title: "Type" },
  {
    field: nameOf("Address"),
    title: "Address",
  },
  {
    field: nameOf("Contact"),
    title: "Contact",
  },
  {
    field: nameOf("RegisterType"),
    title: "Register Type",
  },
];
