import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { IChangeStatus } from "@app/products/town-planning/ppr/components/action-bar/dialogs/update-status/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { postSendCommunication } from "@common/pages/settings/communication/components/buttons/send-communication/api";
import { CommunicationQueueDetailsTabEventType } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/config";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ISendCommunicationButtonProps {
  id?: number;
  disabled?: boolean;
}

export const SendCommunicationButton = observer(
  ({ id, disabled }: ISendCommunicationButtonProps) => {
    const { gridSelectedIds } = useCCProductListViewStore();
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [isLoading, setIsLoading] = useState(false);

    const handleSendCommunication = async (data: IChangeStatus) => {
      setIsLoading(true);
      const response = await postSendCommunication(
        !isEmpty(gridSelectedIds) ? gridSelectedIds : [id]
      );
      clearErrorNotification();
      if (isSuccessIdentityPacket(response)) {
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CommunicationQueueDetailsTabEventType.RefreshData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        pushNotification({
          title: "Communication sent successfully",
          description: response.data?.Notifications,
          type: "success",
        });
      } else {
        pushNotification({
          title: "Send communication failed",
          autoClose: false,
          description: response.data?.Notifications,
          type: "error",
        });
      }
      setIsLoading(false);
    };

    return (
      <>
        <CCNavButton
          title="Send Communication"
          onClick={handleSendCommunication}
          disabled={(isEmpty(gridSelectedIds) && !id) || disabled}
          isLoading={isLoading}
        />
      </>
    );
  }
);
