import { VO_Valuations_By_Group } from "@app/products/property/supplementary-rates/valuations-by-group/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Valuations_By_Group>();

export const colValuationsByGroup: IColumnFields[] = [
  {
    field: nameOf("Valuation_Group_Name"),
    title: "Valuation Group Name",
    locked: true,
  },
  {
    field: nameOf("No_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_1"),
    title: "Unimproved Value",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_2"),
    title: "Average Unimproved Value",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_3"),
    title: "Part Land Tax UV",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_4"),
    title: "Rateable UV",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_5"),
    title: "Sum_Valuation_5",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_6"),
    title: "Sum_Valuation_6",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_7"),
    title: "Sum_Valuation_7",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Sum_Valuation_8"),
    title: "Sum_Valuation_8",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("RatingPeriod_Name"),
    title: "Rating Period",
  },
  {
    field: nameOf("Rating_Period_Id"),
    title: "Rating Period ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOf("Valuation_Group_Id"),
    title: "Valuation Group",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
