import { ExistedManageAsset } from "@app/products/ams/assets/[id]/form/existed/_index";
import { NewManageAsset } from "@app/products/ams/assets/[id]/form/new/_index";
import { useAssetManagerStore } from "@app/products/ams/assets/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageAssetManager = observer(() => {
  const isNew = useIsNew();
  const { id } = useParams<{ id: string }>();
  const { resetMenu } = useCCSubActionBarStore();
  const { loadAsset, resetStore, loadLovs } = useAssetManagerStore();

  useEffect(() => {
    if (isNew) {
      loadLovs();
      return;
    }
    loadAsset(+id);
  }, [id, isNew, loadAsset, loadLovs]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });
  if (isNew) {
    return <NewManageAsset />;
  }
  return <ExistedManageAsset />;
});

export default ManageAssetManager;
