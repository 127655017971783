import { RECORDTYPE } from "@common/constants/recordtype";

export interface IRelatedData {
  Id: number;
  Name?: string;
  RecordType?: string;
  RecordType_ENUM?: RECORDTYPE;
  DebtorNo?: string;
  CreatedDate?: Date;
  CreatedBy?: string;
}

export interface RelatedRecord {
  Related_Id: number;
  Relationship_Int?: number;
  Source_Record_ID: number;
  Source_RecordType_Int: number;
  Related_Record_ID: number;
  Related_RecordType_Int?: number;
}

export enum Related_Relationship {
  SystemInitialise = 0,
  Manual = 1,
  EmployerEmployee = 2,
  Supervisor = 4,
  MultiTenancy = 5,
  Licence = 6,
  NonCompliance = 7,
  PlanningEnquiry = 8,
  BuildingEnquiry = 9,
  BuildingEnforcement = 10,
  HealthEnquiry = 11,
}
