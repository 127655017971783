import {
  colAssessmentAssociate,
  colUnaffectedAssessment,
} from "@app/products/property/assessments/components/form-steps/associate-assessment/components/form-elements/associations/config";
import { CCDatePicker } from "@components/cc-date-picker/_index";

import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";

import { loadViewConfiguresColumns } from "@app/products/property/api";
import { DTO_Assessment } from "@app/products/property/assessments/components/form-steps/associate-assessment/model";
import { isUnAffectedAssociateColField } from "@app/products/property/assessments/components/form-steps/associate-assessment/utils";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ViewConfiguration } from "@app/products/property/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { nameOfFactory } from "@common/utils/common";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const dateValidator = (value: any): string | undefined => {
  if (value?.Assessments?.length) {
    let isDateError = false;
    let isPrimaryError = 0;
    value?.Assessments?.forEach((item: any) => {
      if (
        !isNil(item?.ActiveFrom) &&
        !isNil(item?.ActiveTo) &&
        item.ActiveFrom > item.ActiveTo
      ) {
        return (isDateError = true);
      }
      if (item?.IsPrimaryAssessment) {
        return (isPrimaryError += 1);
      }
    });
    if (isDateError) {
      return `"Active To" must be greater than or equal to "Active From"`;
    }
    if (isPrimaryError < 1) {
      return "Primary assessment needs to be set";
    }
  }
  return undefined;
};

const nameOfAssociate = nameOfFactory<DTO_Assessment>();

export const AssociationAssessmentFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={props?.options?.isReadOnly ? undefined : dateValidator}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    // Variable
    const assessmentsAssociate = getFieldValue("Assessments") ?? [];
    const assessmentSelected =
      getFieldValue("AssessmentsAssociateSelected") ?? [];
    const assessmentsUnaffected = getFieldValue("UnAffectedAssessments") ?? [];
    const assessmentUnaffectedSelected =
      getFieldValue("AssessmentsUnaffectedSelected") ?? [];

    // State
    const [columnsAssociate, setColumnsAssociate] = useState<IColumnFields[]>(
      colAssessmentAssociate
    );
    const [columnsUnaffectedAssociate, setColumnsUnaffectedAssociate] =
      useState<IColumnFields[]>(colUnaffectedAssessment);

    //Get labels
    const [ratepayerLabel, assessmentLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Ratepayer,
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    /**
     * handle delete item
     */
    const handleMoveItemDown = (isMoveDown: boolean = true) => {
      if (isMoveDown) {
        const newAssessmentUnaffected = [
          ...assessmentsUnaffected,
          assessmentSelected?.[0] ?? [],
        ];

        onChange(nameOf("UnAffectedAssessments"), {
          value: newAssessmentUnaffected,
        });

        const assessmentId = assessmentSelected?.[0]?.Assessment_Id;
        const newAssessment = assessmentsAssociate.filter(
          (item: DTO_Assessment) => assessmentId !== item.Assessment_Id
        );
        onChange(nameOf("Assessments"), {
          value: newAssessment,
        });
        onChange(nameOf("AssessmentsAssociateSelected"), {
          value: [],
        });
      } else {
        const newAssessmentsAssociate = [
          ...assessmentsAssociate,
          assessmentUnaffectedSelected?.[0] ?? [],
        ];

        onChange(nameOf("Assessments"), {
          value: newAssessmentsAssociate,
        });

        const assessmentId = assessmentUnaffectedSelected?.[0]?.Assessment_Id;
        const newAssessment = assessmentsUnaffected.filter(
          (item: DTO_Assessment) => assessmentId !== item.Assessment_Id
        );
        onChange(nameOf("UnAffectedAssessments"), {
          value: newAssessment,
        });
        onChange(nameOf("AssessmentsUnaffectedSelected"), {
          value: [],
        });
      }
    };

    /**
     * handle set Primary Assessment
     */
    const handleSetPrimaryAssessment = () => {
      const idAssessment = assessmentSelected?.[0].Assessment_Id;
      if (!idAssessment || !assessmentsAssociate.length) return;
      const newAssessment = assessmentsAssociate.map(
        (item: DTO_Assessment) => ({
          ...item,
          IsPrimaryAssessment: idAssessment === item.Assessment_Id,
        })
      );
      onChange(nameOf("Assessments"), {
        value: newAssessment ?? [],
      });

      onChange(nameOf("AssessmentsAssociateSelected"), {
        value: [],
      });
    };

    const loadViewConfig = async () => {
      setIsLoadingStep(true);
      const response = await loadViewConfiguresColumns(
        ViewConfiguration.Associate_Assessment,
        colAssessmentAssociate
      );
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        setColumnsAssociate(response);
        let newResponse = [...response];
        newResponse = newResponse?.filter((column: IColumnFields) => {
          return isUnAffectedAssociateColField(column.field);
        });
        setColumnsUnaffectedAssociate(newResponse);
      } else {
        setLoadFailedStep({
          onReload: () => {
            loadViewConfig();
          },
          responseError: {
            status: response.status,
            error: response.error ?? "Load failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      loadViewConfig();
    });

    const handleDataChangeGrid = (dataRow: any, fieldChange: string) => {
      const AssociateId = dataRow?.Assessment_Id;
      let newAssociate = [...assessmentsAssociate];
      // update value Active_To and Active_From
      newAssociate = newAssociate.map((item: DTO_Assessment) => {
        if (
          item.Assessment_Id === AssociateId &&
          (fieldChange === nameOfAssociate("ActiveTo") ||
            fieldChange === nameOfAssociate("ActiveFrom"))
        ) {
          return {
            ...item,
            ActiveTo: dataRow[nameOfAssociate("ActiveTo")] ?? null,
            ActiveFrom: dataRow[nameOfAssociate("ActiveFrom")] ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("Assessments"), { value: newAssociate });
    };

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          responseError={loadFailedStep?.responseError}
          onReload={loadFailedStep?.onReload}
        />
      );
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Association date" />
            <Field
              name={nameOf("AssociationDate")}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
              format={DATE_FORMAT.DATE_CONTROL}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label={ratepayerLabel}
              value={getFieldValue("Ratepayer")}
            />
          </div>
        </div>
        <div className="cc-form-cols-1 cc-associate-assessment">
          <div className="cc-field">
            <label className="cc-label">
              {assessmentLabel}'s to be associated
              <CCTooltip type="validator" position="right" />
              <CCTooltip
                type="custom"
                position="auto"
                content=" "
                customTemplate={
                  <div>
                    &#8226; Primary needs to be set
                    <br />
                    &#8226; You need to set another {
                      assessmentLowercaseLabel
                    }{" "}
                    as the primary before you can move it to the unaffected
                    grid.
                  </div>
                }
              >
                <i className="fa fa-info-circle ml-1 text-accent" />
              </CCTooltip>
              {errors?.[nameOf("")] ? (
                <Error>{errors[nameOf("")]}</Error>
              ) : null}
            </label>
            <CCGrid
              data={assessmentsAssociate ?? []}
              columnFields={columnsAssociate}
              selectableMode="single"
              primaryField={nameOfAssociate("Assessment_Id")}
              selectedRows={assessmentSelected ?? []}
              editableMode={!options?.isReadOnly ? "cell" : undefined}
              onSelectionChange={(dataItems: DTO_Assessment[]) => {
                onChange(nameOf("AssessmentsAssociateSelected"), {
                  value: dataItems ?? [],
                });
              }}
              readOnly={options?.isReadOnly}
              onDataRowChange={(dataRow: any, fieldChange: any) => {
                handleDataChangeGrid(dataRow, fieldChange);
              }}
              toolbar={
                !options?.isReadOnly ? (
                  <div className="cc-grid-tools-bar">
                    <Button
                      type="button"
                      disabled={
                        options?.isReadOnly ||
                        assessmentSelected?.length <= 0 ||
                        assessmentSelected?.[0].IsPrimaryAssessment
                      }
                      themeColor="primary"
                      onClick={handleSetPrimaryAssessment}
                    >
                      Set as primary
                    </Button>
                    <Button
                      iconClass="fas fa-arrow-alt-down"
                      title="Move down"
                      onClick={() => handleMoveItemDown()}
                      disabled={
                        options?.isReadOnly ||
                        assessmentSelected?.length <= 0 ||
                        assessmentSelected?.[0].IsPrimaryAssessment
                      }
                    />
                  </div>
                ) : null
              }
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field cc-association-parcels">
            <label className="cc-label">
              Unaffected {assessmentLowercaseLabel}
            </label>
            <CCGrid
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-arrow-alt-up"
                    type="button"
                    title="Move up"
                    onClick={() => {
                      handleMoveItemDown(false);
                    }}
                    disabled={
                      options?.isReadOnly ||
                      assessmentUnaffectedSelected?.length <= 0
                    }
                  />
                </div>
              }
              selectableMode="single"
              data={assessmentsUnaffected ?? []}
              primaryField={nameOfAssociate("Assessment_Id")}
              columnFields={columnsUnaffectedAssociate}
              selectedRows={assessmentUnaffectedSelected ?? []}
              onSelectionChange={(dataItems: DTO_Assessment[]) => {
                onChange(nameOf("AssessmentsUnaffectedSelected"), {
                  value: dataItems ?? [],
                });
              }}
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
