import { DTO_LOV_Transaction } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { nameOfLov } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { getDropdownValue } from "@common/utils/common";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import React, { useState } from "react";

export const TransactionFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    transactionTypes: [],
  },
}: IFormStepElement) => {
  const { onChange, valueGetter } = formRenderProps;
  const [transactionTypesData, setTransactionTypesData] =
    useState<DTO_LOV_Transaction>(options?.transactionTypes);

  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <label className="cc-label">Transaction date</label>
        <Field
          name={nameOf("TransactionDateTime")}
          component={CCDateTimePicker}
          format={DATETIME_FORMAT.DATETIME_CONTROL}
          disabled={options?.isReadOnly}
        />
      </div>
      <div className="cc-field">
        <Checkbox
          name="OnlyShowWriteOff"
          label={"Only show Write Off Transaction Types"}
          onChange={(event: CheckboxChangeEvent) => {
            if (event?.value) {
              setTransactionTypesData(
                options?.transactionTypes?.filter(
                  (transactionType: DTO_LOV_Transaction) =>
                    transactionType.Classifications === "8" ||
                    transactionType.Classifications === "10"
                )
              );
            } else {
              setTransactionTypesData(options?.transactionTypes);
            }
          }}
          disabled={options?.isReadOnly}
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">Transaction type</label>
        <Field
          name={nameOf("Transaction_Type")}
          component={CCSearchComboBox}
          dataItemKey={nameOfLov("Code")}
          textField={nameOfLov("Name")}
          data={transactionTypesData ?? []}
          disabled={options?.isReadOnly}
          value={getDropdownValue(
            valueGetter(nameOf("Transaction_Type"))?.toString(),
            transactionTypesData ?? [],
            "Code"
          )}
          onChange={(event: ComboBoxChangeEvent) => {
            onChange(nameOf("Transaction_Type"), {
              value: event.target.value?.Code ?? null,
            });
          }}
        />
      </div>
      <div className="cc-field">
        <label className="cc-label">Description</label>
        <Field
          name={nameOf("TransactionDescription")}
          component={CCTextArea}
          readOnly={options?.isReadOnly}
          rows={4}
        />
      </div>
    </section>
  );
};
