import { ISpecialReadingDialogData } from "@app/products/property/meters/components/dialogs/enter-special-reading/model";
import { EnterSpecialReadingDialog } from "@app/products/property/meters/components/dialogs/enter-special-reading/_index";
import { loadEditSpecialReadingData } from "@app/products/property/meters/special-reading-list/components/action-bar/buttons/edit-special-reading/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const EditSpecialReadingButton = observer(() => {
  const { gridSelectedRows } = useCCProductListViewStore();
  const [specialReadingData, setSpecialReadingData] = useState<
    ISpecialReadingDialogData | undefined
  >();
  const [showEnterSpecialReadingDialog, setShowEnterSpecialReadingDialog] =
    useState(false);
  const isDisableButton = useMemo(() => {
    return (
      gridSelectedRows.length !== 1 ||
      gridSelectedRows.some((meter) => typeof meter.JournalNumber === "number")
    );
  }, [gridSelectedRows]);
  const { pushNotification } = useCCAppNotificationStore();

  return (
    <React.Fragment>
      <CCNavButton
        title="Edit Special Reading"
        onClick={() => {
          loadEditSpecialReadingData(gridSelectedRows[0]).then(
            (data: ISpecialReadingDialogData) => {
              if (!data) return;
              setSpecialReadingData(data);
              setShowEnterSpecialReadingDialog(true);
            }
          );
        }}
        disabled={isDisableButton}
      />

      {showEnterSpecialReadingDialog && (
        <EnterSpecialReadingDialog
          title={"Edit Special Reading"}
          onClose={() => {
            setShowEnterSpecialReadingDialog(false);
          }}
          onEnterAndExit={() => {
            pushNotification({
              title: "Edit special reading successfully",
              type: "success",
            });
            setShowEnterSpecialReadingDialog(false);
          }}
          specialReadingData={specialReadingData!}
        />
      )}
    </React.Fragment>
  );
});
