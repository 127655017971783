import { mockMeterFormGeneral } from "@app/products/property/meters/[id]/mock";
import { APIResponse } from "@common/apis/model";
import { APIResponseStatus } from "@common/constants/response-status";
import { sleep } from "@common/utils/common";
import { IMeterFormGeneral } from "./model";

export const loadMeterDetailById = async (
  id: number
): Promise<APIResponse<IMeterFormGeneral | undefined>> => {
  let dataResponse;
  await sleep(1000);
  if (+id !== 9999) dataResponse = { ...mockMeterFormGeneral, MeterId: +id };
  if (dataResponse) {
    return {
      data: dataResponse as IMeterFormGeneral,
      status: APIResponseStatus.SUCCESS,
      statusText: "Ok",
      headers: {},
      config: {},
    };
  } else {
    return {
      data: undefined,
      status: APIResponseStatus.NOT_FOUND,
      statusText: "Not Found",
      headers: {},
      config: {},
    };
  }
};
