import { getDirectDebitBankAccountURL } from "@app/products/direct-debit/constant";
import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { nameOfFactory } from "@common/utils/common";

const nameOfDirectDebitBankAccount = nameOfFactory<DirectDebitBankAccount>();
export const directDebitBankAccountPickerOptions: IOptionInputPicker = {
  dialog: {
    titleHeader: "Bank Accounts",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfDirectDebitBankAccount("Description"),
    colShowComboboxSearch: [
      {
        field: nameOfDirectDebitBankAccount("Description"),
        title: "Description",
      },
    ],
  },
  grid: {
    columnFields: [
      {
        field: nameOfDirectDebitBankAccount("Description"),
        title: "Description",
      },
      {
        field: nameOfDirectDebitBankAccount("Direct_Debit_Bank_Acc_No"),
        locked: true,
        title: "Account Number",
      },
      {
        field: nameOfDirectDebitBankAccount("Direct_Debit_Bank_Acc_Name"),
        title: "Account Name",
      },
      {
        field: nameOfDirectDebitBankAccount("Direct_Debit_Bank_BSB"),
        title: "BSB",
      },
    ],
    primaryField: nameOfDirectDebitBankAccount("Direct_Debit_Bank_Account_Id"),
    dataUrl: getDirectDebitBankAccountURL(true),
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
  },
};
