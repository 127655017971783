import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { eComponent } from "@app/products/property/components/associations/model";
import { CreateDocumentDialog } from "@app/products/property/components/dialogs/create-document/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CreateComplianceDocumentButton = observer(() => {
  const { complianceId } = useComplianceStore();
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <>
      <CCNavButton
        title="Create document"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <CreateDocumentDialog
          title="Create Compliance Document"
          componentId={complianceId}
          componentNumber={eComponent.Compliance}
          //TODO: Waiting api in task 8168 to add document types
          documentTypes={[]}
          onClose={() => setIsShowDialog(false)}
        />
      )}
    </>
  );
});
