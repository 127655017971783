import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SelectionGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/selection/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { getDropdownValue } from "@common/utils/common";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

interface ISmsAndFaxSettingsProps {
  formRenderProps: FormRenderProps;
}

export default observer(({ formRenderProps }: ISmsAndFaxSettingsProps) => {
  const { valueGetter } = formRenderProps;

  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const data = useDataSetting(configData, [
    "Global_Communication_EnableSendToFax",
    "Global_FaxGateway",
    "Global_Communication_EnableSendToSMS",
    "CorporateSettings_SMSDeliveryMethod",
    "CorporateSettings_SMSProvider",
    "CorporateSettings_SMSRequestType",
    "CorporateSettings_SMSAPIUrl",
    "CorporateSettings_SMSAPIUserName",
    "CorporateSettings_SMSAPIPassword",
    "CorporateSettings_SMSAppendText",
    "Global_SMSGateway",
    "Global_SMSFromAddress",
    "CorporateSettings_SMSAPIFrom",
    "CorporateSettings_SMSContent",
    "CorporateSettings_ReplaceCarriageReturnWith",
    "Global_SMSPrefix",
  ]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={data.Global_Communication_EnableSendToFax}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        {valueGetter(
          data.Global_Communication_EnableSendToFax?.FieldName as string
        ) && (
          <InputGlobalSettings
            data={data.Global_FaxGateway}
            isEditing={isEditing}
          />
        )}
      </div>
      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={data.Global_Communication_EnableSendToSMS}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
      </div>
      {valueGetter(
        data.Global_Communication_EnableSendToSMS?.FieldName as string
      ) && (
        <>
          <div className="cc-form-cols-3">
            <CCSearchComboBoxGlobalSettings
              data={data.CorporateSettings_SMSDeliveryMethod}
              isEditing={isEditing}
            />
            {valueGetter(
              data.CorporateSettings_SMSDeliveryMethod?.FieldName as string
            ) ===
              getDropdownValue(
                "API",
                data.CorporateSettings_SMSDeliveryMethod?.LookupItems ?? [],
                "Name"
              )?.Id && (
              <>
                <CCSearchComboBoxGlobalSettings
                  data={data.CorporateSettings_SMSProvider}
                  isEditing={isEditing}
                />
                <CCSearchComboBoxGlobalSettings
                  data={data.CorporateSettings_SMSRequestType}
                  isEditing={isEditing}
                />
              </>
            )}
          </div>
          {valueGetter(
            data.CorporateSettings_SMSDeliveryMethod?.FieldName as string
          ) ===
          getDropdownValue(
            "API",
            data.CorporateSettings_SMSDeliveryMethod?.LookupItems ?? [],
            "Name"
          )?.Id ? (
            <>
              <div className="cc-form-cols-3">
                <InputGlobalSettings
                  data={data.CorporateSettings_SMSAPIUrl}
                  isEditing={isEditing}
                />
                <InputGlobalSettings
                  data={data.CorporateSettings_SMSAPIUserName}
                  isEditing={isEditing}
                />
                <InputGlobalSettings
                  data={data.CorporateSettings_SMSAPIPassword}
                  isEditing={isEditing}
                />
              </div>
              <div className="cc-form-cols-3">
                <InputGlobalSettings
                  data={data.CorporateSettings_SMSAppendText}
                  isEditing={isEditing}
                />
                <InputGlobalSettings
                  data={data.CorporateSettings_SMSAPIFrom}
                  isEditing={isEditing}
                />
              </div>
              <InputGlobalSettings
                data={data.Global_SMSPrefix}
                isEditing={isEditing}
              />
            </>
          ) : (
            <>
              <InputGlobalSettings
                data={data.Global_SMSGateway}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.Global_SMSFromAddress}
                isEditing={isEditing}
              />
              {data.CorporateSettings_SMSContent && (
                <SelectionGlobalSettings
                  field={data.CorporateSettings_SMSContent}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                  isMultiple={false}
                />
              )}
              <InputGlobalSettings
                data={data.CorporateSettings_ReplaceCarriageReturnWith}
                isEditing={isEditing}
              />
              <InputGlobalSettings
                data={data.Global_SMSPrefix}
                isEditing={isEditing}
              />
            </>
          )}
        </>
      )}
    </section>
  );
});
