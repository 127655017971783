import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const actionsRoute: ICCRoute = {
  path: "actions",
  name: "Actions",
  enum: eMenuAction.Task_Folder,
  children: [
    {
      path: "draft-list",
      name: "Draft List",
      enum: eMenuAction.Task_ParkedList,
      component: require("./draft-list/_index").default,
    },
    {
      path: "to-be-approved-list",
      name: "To Be Approved List",
      enum: eMenuAction.Task_ToBeApprovedList,
      component: require("./to-be-approved-list/_index").default,
    },
    {
      path: "being-approved",
      name: "Being Approved",
      enum: eMenuAction.Task_BeingApprove,
      component: require("./being-approved-list/_index").default,
    },
    {
      path: "my-approvals",
      name: "My Approvals",
      enum: eMenuAction.Task_MyApprovals,
      component: require("./my-approvals-list/_index").default,
    },
    {
      path: "approved-list",
      name: "Approved List",
      enum: eMenuAction.Task_ApprovedList,
      component: require("./approved-list/_index").default,
    },
    {
      path: "rejected-list",
      name: "Rejected List",
      enum: eMenuAction.Task_RejectedList,
      component: require("./rejected-list/_index").default,
    },
    {
      path: "cancelled-list",
      name: "Cancelled List",
      enum: eMenuAction.Task_CancelledList,
      component: require("./cancelled-list/_index").default,
    },
  ],
};
