import { APIResponse } from "@common/apis/model";
import { DTO_ChargeRun_PIC_Summary } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";

export const getPICChargeRunSummaryById = async (
  id: number
): Promise<APIResponse<DTO_ChargeRun_PIC_Summary | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_ChargeRun_PIC_Summary>(
      `api/property/internal/picchargerun/${id}/summary`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
