import React from "react";
import "./_index.scss";
import { CCMaskedEditCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/custom-cells/cc-masked-edit-cell/_index";
import { ICustomEditCell } from "@components/cc-grid/model";
import { formatInputMask } from "@common/utils/formatting";
import { CCTooltip } from "@components/cc-tooltip/_index";

export const CardNumberEditCell = (props: ICustomEditCell) => {
  const inputMask = props.dataItem?.Card_Number_InputMask;

  return (
    <div className="cc-masked-edit-cell-group">
      <CCMaskedEditCell
        className="cc-masked-edit-cell"
        mask={formatInputMask(inputMask)}
        {...props}
      />
      <CCTooltip
        content={
          inputMask
            ? `The card number format is defined to ${inputMask}`
            : "The card number format is not defined"
        }
        position="right"
      />
    </div>
  );
};
