import { VO_Compliance_Document } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/model";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
const nameOf = nameOfFactory<VO_Compliance_Document>();
export const allDocumentYetToBeIssuedColList: IColumnFields[] = [
  {
    field: nameOf("Compliance_Id"),
    title: "Compliance ID",
    format: NUMBER_FORMAT.NUMBER2,
    locked: true,
    linkTo: (dataItem) =>
      `${PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}/${dataItem.Compliance_Id}`,
  },
  {
    field: nameOf("CD_Created_On"),
    title: "Created On",

    format: DATETIME_FORMAT.DATETIME,
  },
  { field: nameOf("CD_Created_By"), title: "Created By", width: 250 },
  { field: nameOf("CDT_Name"), title: "Type" },
  {
    field: nameOf("NA_Name"),
    title: "Name",
  },
  {
    field: nameOf("Compliance_Document_Id"),
    title: "Document ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
