export enum EDialogStepAssociation {
  Holding = 1,
  Folio = 2,
  DeleteHolding = 3,
  DeleteFolio = 4,
}

export enum EPICGridType {
  Holding = 1,
  Folio = 2,
  ViewConfiguration = 3,
}

export enum EPICStatus {
  Active = 0,
  Inactive = 1,
  Historical = 2,
  Pending = 3,
}
