import { RECORDTYPE } from "@common/constants/recordtype";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { IAppeal, IParentAppealSection } from "@common/pages/appeal/_id/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

interface IAddAppealButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  data?: IAppeal;
}

export const AddAppealButton = ({
  id,
  recordType,
  data,
}: IAddAppealButtonProps) => {
  const history = useHistory();
  return (
    <CCNavButton
      title={"Appeal"}
      onClick={() => {
        history.push(`${APPEAL_ROUTE}/new`, {
          parent: {
            id: id,
            recordType: recordType,
            data: data,
          } as IParentAppealSection,
        });
      }}
    />
  );
};
