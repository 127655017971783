import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { putReferral } from "@app/products/town-planning/ppr/psa-referrals/_id/components/buttons/workflow/referral/api";
import { ISendReferral } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/model";
import { ReferralDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/dialogs/referral/_index";
import { PSARSubmitActions } from "@app/products/town-planning/ppr/psa-referrals/_id/model";
import { usePSARStore } from "@app/products/town-planning/ppr/psa-referrals/_id/store";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ReferralButton = observer(() => {
  const { ppr, onSubmit, pprId, setPPRWithLoading } = usePSARStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  useAddUniqueEventEmitter([
    {
      eventType: UITownPlanning.AddMultiReferrals.toString(),
      listener: () => {
        setIsShowDialog(true);
      },
    },
  ]);

  const isVisible = useMemo(() => {
    return (
      ppr?.PPRDetails?.Flag_IsReferralRequired == null &&
      ppr?.Status_ENUM === Application_Status.PreliminaryAssessment
    );
  }, [ppr]);

  const handleSubmit = async (data: ISendReferral) => {
    if (!pprId || !data.ActionTypeIDs) return;
    setIsLoading(true);
    const response = await putReferral(pprId, data.ActionTypeIDs);
    setNotifications([]);
    if (isSuccessIdentityPacket(response) && response.data?.Application) {
      setIsShowDialog(false);
      appNotificationStore.clearNotifications();
      setPPRWithLoading(response.data.Application);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Record saved successfully",
      });
      if (
        response.data?.Notifications &&
        response.data?.Notifications.length > 0
      ) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Referral",
          type: "warning",
          description: response.data?.Notifications,
        });
      }
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Referral failed",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        },
      ]);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isVisible ? (
        <CCNavButton
          title="Referral"
          onClick={onSubmit}
          isLoading={isLoading}
          name={PSARSubmitActions.Referral}
        />
      ) : null}

      {isShowDialog && (
        <ReferralDialog
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleSubmit}
          isSubmitLoading={isLoading}
          notifications={notifications}
        />
      )}
    </>
  );
});
