import {
  HMPremisesRegisterView,
  HMPremisesView,
} from "@app/products/hm/premises/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IGenerateColFieldBySetting } from "@common/models/settings";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDisplayTextWithDashes, nameOfFactory } from "@common/utils/common";
import { convertToTitleCase } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep } from "lodash";

// #region These codes are still use for another list view
export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `HM - Premises ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.PremisesType]);
};
// #endregion

export const HMPremisesBookmark = {
  getBookmarkDetailDisplayName() {
    return "Health Manager - Premises";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Health Manager - Premises - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(selectedRow: HMPremisesRegisterView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Health Manager - Premises ${dynamicDisplayName}`;
  },
};

export const nameOfHMPremisesView = nameOfFactory<HMPremisesView>();

export const generateHMPremisesColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateColFieldBySetting) => {
  if (!settings) return defaultConfig;

  const columnRegNoModifier = (column: IColumnFields) => {
    const registrationNoLabel = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_RegistrationNoLabel]
    );
    const title = registrationNoLabel
      ? convertToTitleCase(registrationNoLabel)
      : column.title;
    return { ...column, title };
  };
  const columnMapper = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("RegNo"):
        return columnRegNoModifier(column);
      default:
        return column;
    }
  };
  const columnFilterAmountOS = (_column: IColumnFields) => {
    return getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowLastInspOSFees]
    );
  };
  const columnFilter = (column: IColumnFields) => {
    switch (column.field) {
      case nameOfHMPremisesView("AmountOS"):
        return columnFilterAmountOS(column);
      default:
        return true;
    }
  };
  return cloneDeep(defaultConfig).filter(columnFilter).map(columnMapper);
};
