import { nameOfLov } from "@app/products/property/model";
import { mockScheme } from "@app/products/property/schemes/[id]/components/forms/existed/components/form-step/import-scheme-accounts/components/form-elements/options/mock";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getDropdownValue } from "@common/utils/common";
import { formatBytes } from "@common/utils/formatting";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import React, { useRef } from "react";

const validator = (values: any) => {
  if (!values.SchemeType) {
    return "Please select at least one scheme type";
  }
  if (values.SchemeUploadFile.length === 0) {
    return "Please upload file to import Scheme Accounts";
  }
};
const ACCEPT_FORMAT = [".xlsx", ".csv", ".xlsm", ".xltx", ".xls"];
export const OPTIONS_FORM_STEP = "Options";
export const OptionsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validator}
    />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { valueGetter, onChange } = formRenderProps;
  const { fileSize } = useCommonCoreStore();
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  const hanleOnAddFile = (event: UploadOnAddEvent) => {
    if (
      event.affectedFiles[0].validationErrors &&
      event.affectedFiles[0].validationErrors.length
    ) {
      //setIsDisable(true);
      if (
        event.affectedFiles[0].validationErrors.includes("invalidFileExtension")
      ) {
        notificationRef.current?.pushNotification({
          title: `Only allow file type in ${ACCEPT_FORMAT.join(", ")}`,
          type: "warning",
        });
      } else if (
        event.affectedFiles[0].validationErrors.includes("invalidMaxFileSize")
      ) {
        notificationRef.current?.pushNotification({
          title: `Maximum allowed file size is ${formatBytes(fileSize)}`,
          type: "warning",
        });
      }
    } else if (event.newState) {
      onChange(nameOf("SchemeUploadFile"), {
        value: event.newState,
      });
    }
  };

  const handleonRemove = (event: UploadOnRemoveEvent) => {
    onChange(nameOf("SchemeUploadFile"), {
      value: event.newState,
    });
  };

  return (
    <section className="cc-field-group">
      <CCLocalNotification ref={notificationRef} />
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Scheme</label>
          <Field
            name={nameOf("SchemeType")}
            data={mockScheme}
            component={CCSearchComboBox}
            textField={nameOfLov("Name")}
            dataItemKey={nameOfLov("Code")}
            value={getDropdownValue(
              valueGetter(nameOf("SchemeType")),
              mockScheme,
              nameOfLov("Code")
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(nameOf("SchemeType"), {
                value: event.target.value?.Code ?? null,
              });
            }}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Import actual amounts only</label>
          <Field
            name={nameOf("ImportActualAmountOnly")}
            component={CCSwitch}
            checked={getFieldValue("ImportActualAmountOnly")}
          />
        </div>
        <div className="cc-field">
          <Field
            name={nameOf("SchemeUploadFile")}
            component={CCUploadFile}
            batch={false}
            autoUpload={false}
            showActionButtons={false}
            multiple={false}
            files={getFieldValue("SchemeUploadFile")}
            accept={ACCEPT_FORMAT.join(",")}
            withCredentials={false}
            restrictions={{
              allowedExtensions: ACCEPT_FORMAT,
            }}
            onAdd={(event: UploadOnAddEvent) => {
              hanleOnAddFile(event);
            }}
            onRemove={(event: UploadOnRemoveEvent) => {
              handleonRemove(event);
            }}
          />
        </div>
      </div>
    </section>
  );
};
