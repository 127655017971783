import { history } from "@/AppRoutes";
import {
  CSLSystemSettingCategoriesForm,
  ICSL,
} from "@app/products/csl/[id]/model";
import { Toast } from "@components/toast";
import { mockCategory } from "./mock";

//CSL System Admin Categories
export const loadCSLSystemAdminCategoriesById = async (
  id?: string
): Promise<ICSL> => {
  const cslSystemSettingCategoriesRegister: CSLSystemSettingCategoriesForm[] =
    mockCategory;
  return cslSystemSettingCategoriesRegister[0];
};
export const postCSLSystemAdminCategories = async (requestBody: ICSL) => {
  console.error("CSLSystemAdminCategories form data !", requestBody);
  mockApiSystemAdminCategoriesAlwaySuccess(requestBody);
};

export const putCSLSystemAdminCategories = async (requestBody: ICSL) => {
  mockApiSystemAdminCategoriesAlwaySuccess(requestBody);
  history.replace(window.location.pathname, {
    status: "success",
  });
  return "MockDataReturning";
};

// TODO:
// DELETE IT WHEN WE HAVE API IMPLEMENTATION
const mockApiSystemAdminCategoriesAlwaySuccess = (requestBody: ICSL) => {
  setTimeout(() => {
    history.replace(`/csl/managelicencecategories${requestBody?.id || 1}`, {
      status: "success",
    });

    Toast.displayToast({
      content: "🚀 Save Licence Categories successfully !",
      type: "success",
    });
  }, 2000);
};
