import { isSuccessResponse } from "@common/apis/util";
import { exportReportPB } from "@common/pages/report/powerbi/list/api";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface ExportReportButtonProps {
  disabled?: boolean;
}

export const ExportReportButton = observer(
  ({ disabled }: ExportReportButtonProps) => {
    const { gridSelectedIds, gridSelectedRows } = useCCProductListViewStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { pushNotification } = useCCAppNotificationStore();

    const downloadReport = async () => {
      setIsLoading(true);

      const response = await exportReportPB(gridSelectedRows[0].id);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        const fileName = `${
          gridSelectedRows[0]?.name.replaceAll(" ", "") ?? "Report"
        }.pbix`;
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      } else {
        pushNotification({
          autoClose: false,
          title: "Export report failed",
          type: "error",
        });
      }
    };

    return (
      <>
        <CCNavButton
          title="Export report"
          isLoading={isLoading}
          disabled={disabled || gridSelectedIds.length !== 1}
          onClick={downloadReport}
        />
      </>
    );
  }
);
