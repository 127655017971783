import { postUpdateKeyWords } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/public-portal/components/button/update-keywords/api";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const UpdateKeyWords = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clearNotifications, pushNotification } = useCCAppNotificationStore();

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await postUpdateKeyWords();
    setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      clearNotifications();
      pushNotification({
        type: "success",
        title: "Update keywords successfully",
      });
    } else {
      pushNotification({
        title: "Update keywords failed",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
        autoClose: false,
      });
    }
  };

  return (
    <Button
      disabled={isLoading}
      themeColor="primary"
      onClick={handleSubmit}
      iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
      className={"cc-dialog-button"}
    >
      Update Keywords
    </Button>
  );
});
