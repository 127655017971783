import { getContact } from "@app/products/event-management/components/booking-calendar/api";
import {
  displayDate,
  sampleCalendarData,
} from "@app/products/event-management/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { guid } from "@progress/kendo-react-common";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Popup } from "@progress/kendo-react-popup";
import {
  AgendaView,
  DayView,
  MonthView,
  Scheduler,
  SchedulerItem,
  SchedulerItemProps,
  TimelineView,
  WeekView,
} from "@progress/kendo-react-scheduler";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

const BookingCalendar = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState(sampleCalendarData);
  const isNew = !props.proprietorRID;
  const history: any = useHistory();

  const filterColumn = [
    { text: "Meeting", value: "Meeting", color: "#74b9ff" },
    { text: "Coffee", value: "Coffee", color: "#a29bfe" },
    { text: "English", value: "English", color: "#e17055" },
    { text: "Oncall", value: "Oncall", color: "#00b894" },
  ];

  const [calendarFilter, setSelectedCalendarFilter] = useState<any>([]);

  useEffect(() => {
    if (!isNew) {
      setIsLoading(true);
      getContact(props.proprietorRID).then((response: any) => {
        response && setData(response.data.Contact);
        setIsLoading(false);
      });
      setIsLoading(false);
    }

    for (const key in history.location.state.calendarFilter) {
      if (history.location.state.calendarFilter?.[key]) {
        calendarFilter.push(key);
        setSelectedCalendarFilter(calendarFilter);
      }
    }

    setData(
      sampleCalendarData.filter((o) => {
        return calendarFilter.includes(o.type);
      })
    );

    return () => {
      setIsLoading(false);
    };
  }, [props, history, isNew, calendarFilter]);

  const handleSubmit = (dataItem: any) => {
    history.goBack();
  };

  const handleDataChange = ({ created, updated, deleted }: any) => {
    setData((old) =>
      old
        .filter(
          (item) =>
            deleted.find((current: any) => current.id === item.id) === undefined
        )
        .map(
          (item) =>
            updated.find((current: any) => current.id === item.id) || item
        )
        .concat(
          created.map((item: any) => Object.assign({}, item, { id: guid() }))
        )
    );
  };

  const CustomSchedulerItem = (itemProps: SchedulerItemProps) => {
    const ref = React.useRef<any>(null);
    const [show, setShow] = React.useState(false);

    const handleMouseOver = React.useCallback(
      (event) => {
        setShow(true);
        // Call the default `onFocus` handler
        if (itemProps.onMouseOver) {
          itemProps.onMouseOver(event);
        }
      },
      [itemProps]
    );

    const handleMouseLeave = React.useCallback(
      (event) => {
        setShow(false);

        // Call the default `onFocus` handler
        if (itemProps.onMouseOut) {
          itemProps.onMouseOut(event);
        }
      },
      [itemProps]
    );

    return (
      <React.Fragment>
        <SchedulerItem
          {...itemProps}
          className={"scheduler-item"}
          ref={ref}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        />
        <Popup
          show={show}
          anchorAlign={{
            horizontal: "left",
            vertical: "bottom",
          }}
          popupAlign={{
            horizontal: "left",
            vertical: "top",
          }}
          id="scheduler-popup"
          anchor={ref.current && ref.current.element}
        >
          <div className="calendar-popup">
            <div>
              <h6>{props.title}</h6>
              <div>
                <b>Start:</b>{" "}
                {formatDateByKendo(props.start, DATETIME_FORMAT.DATETIME)}
              </div>
              <div>
                <b>End:</b>{" "}
                {formatDateByKendo(props.end, DATETIME_FORMAT.DATETIME)}
              </div>
              <hr />
              <div>
                <b>Description:</b> {props.description}
              </div>
            </div>
          </div>
        </Popup>
      </React.Fragment>
    );
  };

  const selectedFacilites = () => (
    <>
      {filterColumn
        .filter((it) => calendarFilter.includes(it.text))
        .map((item, index) => (
          <span
            key={index}
            style={{
              background: item.color,
              color: "#fff",
              marginRight: 10,
              padding: 5,
            }}
            className={"font-weight-bold"}
          >
            {item.text}
          </span>
        ))}
    </>
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Back to Event Management" />
      <FormTitle
        title={"Booking Calendar - Venue 1 - 201840"}
        badge={"Submitted"}
      />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Save & Exit"}
            htmlFor={"btn-submit"}
            onClick={handleSubmit}
          />,
        ]}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <div className="cc-manage-container">
              <div className="cc-form cc-manage-left">
                <div className="cc-manage-form-body">
                  <section className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCValueField
                          label="Selected facilities:"
                          value={selectedFacilites()}
                        />
                      </div>
                      <div className="cc-form-cols-5">
                        <div className="cc-field">
                          <CCValueField
                            label="Minimum notice period"
                            value={"Not Applicable"}
                          />
                        </div>
                        <div className="cc-field">
                          <CCValueField
                            label="Minimum duration"
                            value={"Not Applicable"}
                          />
                        </div>
                        <div className="cc-field">
                          <CCValueField
                            label="Maximum duration"
                            value={"Not Applicable"}
                          />
                        </div>
                        <div className="cc-field">
                          <CCValueField
                            label="Gap time"
                            value={"Not Applicable"}
                          />
                        </div>
                        <div className="cc-field">
                          <CCValueField
                            label="Bookable until"
                            value={"05-Jan-2023 (730 days limit)"}
                          />
                        </div>
                      </div>
                    </div>
                    <Scheduler
                      editable={false}
                      data={data}
                      defaultDate={displayDate}
                      onDataChange={handleDataChange}
                      className="cc-field"
                      item={CustomSchedulerItem}
                      resources={[
                        {
                          name: "Meeting Type",
                          data: [...filterColumn],
                          field: "type",
                          valueField: "value",
                          textField: "text",
                          colorField: "color",
                        },
                      ]}
                    >
                      <MonthView />
                      <AgendaView />

                      <TimelineView />
                      <DayView />
                      <WeekView />
                    </Scheduler>
                  </section>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </>
  );
};
export interface IParentSection {
  key: number;
  label: string;
  value: string;
}
export default BookingCalendar;
