import {
  ServiceStandard,
  ServiceStandardMapObj,
} from "@app/products/crms/service-standards/[id]/model";
import { useCRMSServiceStandardStore } from "@app/products/crms/service-standards/[id]/store";
import { isFieldDisable, isFieldVisible } from "@app/products/crms/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOf = nameOfFactory<ServiceStandard>();
const nameOfServiceStandardMapObj = nameOfFactory<ServiceStandardMapObj>();

export interface AssetDetailProps {
  formRenderProps: FormRenderProps;
}
export const AssetDetail = observer(({ formRenderProps }: AssetDetailProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const { serviceStandardLovs, accessRight, uiControl } =
    useCRMSServiceStandardStore();

  const isDisabled = accessRight === ACCESSRIGHTS.CanRead;

  const isDisabledSendToAssetSystem = isFieldDisable(
    uiControl?.RblSendToAssetSystem?.DisplayStatus
  );

  const isShowAssetSendToExternalSystemOnClosure = isFieldVisible(
    uiControl?.DivAssetSendToExternalSystemOnClosure?.DisplayStatus
  );

  const isShowAssetJobPriority = isFieldVisible(
    uiControl?.DivAssetJobPriority?.DisplayStatus
  );

  const isShowAssetAddtionalDetails = isFieldVisible(
    uiControl?.DivAssetAddtionalDetails?.DisplayStatus
  );

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel
            title={
              uiControl?.FlAssetSendToAssetSystem?.Label ??
              "Send To Confirm on Send For Action"
            }
          />
          <Field
            disabled={isDisabled || isDisabledSendToAssetSystem}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Flag_AssetSendToAssetSystem"
            )}`}
            checked={valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Flag_AssetSendToAssetSystem"
              )}`
            )}
            component={CCSwitch}
          />
        </div>

        {isShowAssetSendToExternalSystemOnClosure && (
          <div className="cc-field">
            <CCLabel
              title={
                uiControl?.FlAssetSendToAssetSystemOnClosure?.Label ??
                "Send to confirm on closure"
              }
            />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Flag_AssetSendToAssetSystemOnClosure"
              )}`}
              checked={valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "Flag_AssetSendToAssetSystemOnClosure"
                )}`
              )}
              component={CCSwitch}
            />
          </div>
        )}
      </div>

      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Asset is mandatory" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "Flag_AssetIsMandatory"
            )}`}
            checked={valueGetter(
              `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "Flag_AssetIsMandatory"
              )}`
            )}
            component={CCSwitch}
          />
        </div>
      </div>

      <div className="cc-form-cols-3">
        <div className="cc-field">
          <CCLabel title="Work identifier" />
          <Field
            disabled={isDisabled}
            name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
              "WorkIdentifier_KWD"
            )}`}
            textField="Value"
            dataItemKey="Key"
            data={serviceStandardLovs?.WorkIdentifier ?? []}
            component={CCSearchComboBox}
            value={getDropdownValue(
              valueGetter(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "WorkIdentifier_KWD"
                )}`
              ),
              serviceStandardLovs?.WorkIdentifier,
              "Key"
            )}
            onChange={(event: ComboBoxChangeEvent) => {
              onChange(
                `${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                  "WorkIdentifier_KWD"
                )}`,
                {
                  value: event.target.value?.Key ?? null,
                }
              );
            }}
          />
        </div>
      </div>

      {isShowAssetAddtionalDetails && (
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel
              title={uiControl?.FlAssetServiceCode?.Label ?? "Service code"}
            />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "AssetServiceCode"
              )}`}
              component={CCInput}
              placeholder={
                uiControl?.FlAssetServiceCode?.Label ?? "Service code"
              }
            />
          </div>

          <div className="cc-field">
            <CCLabel
              title={uiControl?.FlAssetSubjectCode?.Label ?? "Subject code"}
            />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "AssetSubjectCode"
              )}`}
              component={CCInput}
              placeholder={
                uiControl?.FlAssetSubjectCode?.Label ?? "Subject code"
              }
            />
          </div>

          <div className="cc-field">
            <CCLabel
              title={uiControl?.FlAssetCategoryCode?.Label ?? "Category code"}
            />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "AssetCategoryCode"
              )}`}
              component={CCInput}
              placeholder={
                uiControl?.FlAssetCategoryCode?.Label ?? "Category code"
              }
            />
          </div>
        </div>
      )}

      {isShowAssetJobPriority && (
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Priority" />
            <Field
              disabled={isDisabled}
              name={`${nameOfServiceStandardMapObj("ServiceStandard")}.${nameOf(
                "AssetJobPriority"
              )}`}
              component={CCNumericTextBox}
              placeholder="Priority"
            />
          </div>
        </div>
      )}
    </section>
  );
});
