import { RaiseAssessmentInterestCommonButton } from "@app/products/property/assessments/components/action-bar/buttons/raise-assessment-interest/common/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const RaiseAssessmentInterestButtonAtCollections = observer(() => {
  const { lastSelectedRow } = useCCProductListViewStore();

  const assessmentId = useMemo(() => {
    return lastSelectedRow?.Assessment_Id;
  }, [lastSelectedRow]);

  return <RaiseAssessmentInterestCommonButton assessmentID={assessmentId} />;
});
