import { colFunctionalityRestrictions } from "@app/products/property/assessments/compliance/[id]/components/child-screens/functionality-restrictions/config";
import { DTO_Functionality } from "@app/products/property/assessments/compliance/[id]/model";
import { useComplianceStore } from "@app/products/property/assessments/compliance/[id]/store";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
const nameOf = nameOfFactory<DTO_Functionality>();
const ComplianceFunctionalityRestrictions = () => {
  const { complianceFunctionalities } = useComplianceStore();
  return (
    <div className="cc-assessment-compliance-functionality-restrictions">
      <CCGrid
        className="cc-assessment-compliance-functionality-restrictions-grid"
        data={complianceFunctionalities}
        columnFields={colFunctionalityRestrictions}
        primaryField={nameOf("FunctionalityName")}
      />
    </div>
  );
};

export default observer(ComplianceFunctionalityRestrictions);
