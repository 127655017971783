import { loadTransactionsDetail } from "@app/products/property/meters/[id]/components/child-screens/transactions/components/detail/api";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colTransactionAllocation } from "./config";

const MeterTransactionDetails = (props: GridDetailRowProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTransactionDetail, setDataTransactionDetail] = useState<any>([]);

  useEffectOnce(() => {
    setIsLoading(true);
    loadTransactionsDetail().then((data) => {
      setDataTransactionDetail(data);
      setIsLoading(false);
    });
  });
  return (
    <div className="cc-transaction-detail-grid">
      <label className="cc-label">
        Allocation of transaction to charges, levies and instalments
      </label>
      <CCGrid
        isLoading={isLoading}
        className="cc-transaction-grid"
        data={dataTransactionDetail}
        columnFields={colTransactionAllocation}
        primaryField="ChargeBalancesId"
      />
    </div>
  );
};
export default MeterTransactionDetails;
