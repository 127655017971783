import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { DynamicQuestionListsForm } from "@common/pages/settings/lookups/dynamic-lists/_id/components/child-screens/general/_index";
import { DynamicListsHistoryTab } from "@common/pages/settings/lookups/dynamic-lists/_id/components/reference-sidebar/history/_index";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedDynamicQuestionLists = observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_ManageDynamicQuestionList,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    dynamicQuestionLists,
    isLoading,
    responseLoadError,
    loadDynamicQuestionLists,
    dynamicQuestionListsId,
    onSubmit,
    isInactive,
  } = useDynamicQuestionListsStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        dynamicQuestionLists?.DynamicQuestionList_Name,
        dynamicQuestionLists?.DynamicQuestionList_ID,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Lookups - Dynamic Lists - ${
        dynamicQuestionLists?.DynamicQuestionList_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_DynamicQuestionList,
      Record_ID: dynamicQuestionListsId ?? 0,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation title={"Dynamic List"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDynamicQuestionLists(parseInt(id), false)}
        />
      ) : (
        <>
          <FormTitle
            title={dynamicQuestionLists?.DynamicQuestionList_Name || ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={ActionSubmitActions.Save}
                onClick={onSubmit}
                disabled={
                  isInactive ||
                  !checkPermissions([
                    FormAction.CORE_ALLOW_EDIT,
                    FormAction.CORE_ALLOW_SAVE,
                  ])
                }
              />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {dynamicQuestionLists && <DynamicQuestionListsForm />}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "History",
                      component: <DynamicListsHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
