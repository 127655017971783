import {
  options,
  targetDaysOrHourOptions,
} from "@app/products/crms/service-standards/batch-update/constant";
import { getNameOfSvcStandardBatchUpdate } from "@app/products/crms/service-standards/batch-update/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";

export interface ITargetDaysProps {
  formRenderProps: FormRenderProps;
  isDisabled?: boolean;
}

export const TargetDays = ({
  formRenderProps,
  isDisabled,
}: ITargetDaysProps) => {
  const { valueGetter } = formRenderProps;

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update has target days" />
            <Field
              name={getNameOfSvcStandardBatchUpdate("Update_TargetDays")}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_TargetDays")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(getNameOfSvcStandardBatchUpdate("Update_TargetDays")) ? (
            <div className="cc-field">
              <CCLabel title="Has target days" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("TargetDays")}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update calculate completion target in days/hours" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_TargetDays_Or_Hours"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_TargetDays_Or_Hours")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_TargetDays_Or_Hours")
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Calculate completion target in days/hours"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate("TargetDays_Or_Hours")}
                component={RadioGroup}
                data={targetDaysOrHourOptions}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update use acknowledgement" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_UseAcknowledgement"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_UseAcknowledgement")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_UseAcknowledgement")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Use acknowledgement" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("UseAcknowledgement")}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update may be rescheduled" />
            <Field
              name={getNameOfSvcStandardBatchUpdate("Update_MayBeRescheduled")}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_MayBeRescheduled")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_MayBeRescheduled")
          ) ? (
            <div className="cc-field">
              <CCLabel title="May be rescheduled" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("MayBeRescheduled")}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update notify customer if rescheduled" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_NotifyCustomerIfRescheduled"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_NotifyCustomerIfRescheduled"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_NotifyCustomerIfRescheduled"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel title="Notify customer if rescheduled" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "NotifyCustomerIfRescheduled"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update final notification" />
            <Field
              name={getNameOfSvcStandardBatchUpdate("Update_FinalNotification")}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_FinalNotification")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_FinalNotification")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Final notification?" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("FinalNotification")}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update include weekends in target calculation" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_IncludeWeekendsInTargetCalculation"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_IncludeWeekendsInTargetCalculation"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_IncludeWeekendsInTargetCalculation"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel
                title="Include weekends in target calculation?"
                isMandatory
              />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "IncludeWeekendsInTargetCalculation"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update enable internal closure comments" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_EnableInternalClosureComment"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_EnableInternalClosureComment"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_EnableInternalClosureComment"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel title="Enable internal closure comments?" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "EnableInternalClosureComment"
                )}
                component={RadioGroup}
                data={options}
                layout={"horizontal"}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>
      </section>

      <hr className="cc-divider" />

      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update completion" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_ActualCompletionTarget"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_ActualCompletionTarget")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_ActualCompletionTarget")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Completion" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("ActualCompletionTarget")}
                component={CCNumericTextBox}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update urgent completion" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_UrgentCompletionTarget"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_UrgentCompletionTarget")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_UrgentCompletionTarget")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Urgent completion" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("UrgentCompletionTarget")}
                component={CCNumericTextBox}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update acknowledgement" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_AcknowledgementTarget"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_AcknowledgementTarget")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_AcknowledgementTarget")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Acknowledgement" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("AcknowledgementTarget")}
                component={CCNumericTextBox}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update urgent acknowledgement" />
            <Field
              name={getNameOfSvcStandardBatchUpdate(
                "Update_UrgentAcknowledgementTarget"
              )}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate(
                  "Update_UrgentAcknowledgementTarget"
                )
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate(
              "Update_UrgentAcknowledgementTarget"
            )
          ) ? (
            <div className="cc-field">
              <CCLabel title="Urgent acknowledgement" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate(
                  "UrgentAcknowledgementTarget"
                )}
                component={CCNumericTextBox}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>

        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Update reschedule max days" />
            <Field
              name={getNameOfSvcStandardBatchUpdate("Update_RescheduleMaxDays")}
              checked={valueGetter(
                getNameOfSvcStandardBatchUpdate("Update_RescheduleMaxDays")
              )}
              component={CCSwitch}
              disabled={isDisabled}
            />
          </div>
          {valueGetter(
            getNameOfSvcStandardBatchUpdate("Update_RescheduleMaxDays")
          ) ? (
            <div className="cc-field">
              <CCLabel title="Reschedule max days" isMandatory />
              <Field
                name={getNameOfSvcStandardBatchUpdate("RescheduleMaxDays")}
                component={CCNumericTextBox}
                disabled={isDisabled}
                validator={requiredValidator}
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};
