import { BuildingStormwaterResponse } from "@app/products/building/registers/stormwater/[id]/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockSingleBuildingStormwater: BuildingStormwaterResponse = {
  ID: 30548,
  Status: "Closed",
  Contact: "Developer OpenOffice",
  ContactAddress: "5 Ramsey Ct - Mulgrave VIC 3170",
  ContactContact: "+61 470 228 403",
  RegisterNumber: 15612,
  Address: "31 Caroline Avenue",
  Owners: "Wilson K M",
  Details: "L87 LP9628",
  Description: "Test By Jophy",
  Officer: "Jophy Wilson",
  AppNo: "",
  RefNumber: "154784",
  FileNumber: "154784",
  CreatedDate: new Date("10/17/2019"),
  Lodged: new Date("10/27/2019"),
  DecisionType: "Approved",
  DecisionDate: new Date("11/20/2018"),
  OSFees: 50,
  DebtorNumber: "1547445",
  PlannerAssessment: "11111",
};

export const mockStatusDropdownData: IKeyValuePacket[] = [
  { Key: 1, Value: "New" },
  { Key: 2, Value: "Open" },
  { Key: 3, Value: "Closed" },
];
