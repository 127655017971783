import { useNewPICContactStepStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/contacts/store";
import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React from "react";

export const RolePICManagerCustomEditCell = observer(
  ({ value, onChange, dataIndex, dataItem, field }: ICustomEditCell) => {
    //store
    const { lovContact } = useNewPICContactStepStoreContext();
    return (
      <DropDownList
        textField="Role_Name"
        dataItemKey="Role_Id"
        data={lovContact?.Role ?? []}
        value={getDropdownValue(value, lovContact?.Role ?? [], "Role_Name")}
        onChange={(event) => {
          if (onChange) {
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: event.value?.Role_Name,
            });
          }
        }}
      />
    );
  }
);
