export enum TypeNotice {
  AMOUNTS_DUE = "Amounts Due",
  INSTALMENT_NOTICES = "Instalment Notices",
  NOTICES_FOR_JOURNALS_OF_ASSESSMENT_CHARGES = "Notices for Journals of Assessment Charges",
  RATE_NOTICE_FROM_CHARGE_RUN = "Rate Notice from Charge Run",
  RATE_NOTICE_FROM_CHARGE_RUN_AND_JOURNALS = "Rate Notice from Charge Run and Journals (eg. Utility Charges)",
  REMINDER_NOTICES = "Reminder Notices",
  SEPARATE_SUPPLEMENTARY_NOTICES_PER_PERIOD = "Separate Supplementary Notices per period",
  SUPPLEMENTARY_NOTICES = "Supplementary Notices",
  VALUATION_NOTICES = "Valuation Notices",
  VIC_FSPL_RECONCILIATION = "VIC FSPL Reconciliation",
  VIC_FSPL_REMITTANCE = "VIC FSPL Remittance",
  VIC_FSPL_RETURN = "VIC FSPL Return",
}
