export const singleEventManagement = {
  data: {
    ID: 1,
    Description:
      "Need to add costs and logistics (registration/advance purchase, transportation and parking, and other details).",
    Status: "withdrawal",
    FirstName: "Rachael",
    LastName: "Hauck",
    OrganisationName: "PTN Global",
    Mobile: "501-718-4008",
    HomePhone: "812-110-4954",
    WorkPhone: "290-634-0030",
    Email: "racheal.hauck@gmail.com",
    DebtorNo: "A9223",
    FileNumber: 76863,
    OSFees: 63244,
    MasterFacility: 10,
    DebtorNumber: "5221312",
    EventTypes: ["Opening Ceremony", "Appreciation Events"],
    Status_ENUM: "Active",
    BookingContact: "William Lin",
    EventDates: "25 Dec 2021",
    RequestedBy: "Tanner Johnson",
    RequestedBy_ContactDetails: "742 Goyette Coves Laramie, WY 82070",
  },
  headers: "",
  status: 200,
};
