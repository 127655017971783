import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class AdjustChargeDialogStore {
  private _adjustChargeLOVs?: DTO_Journal_LOVs = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get adjustChargeLOVs() {
    return this._adjustChargeLOVs;
  }
  setAdjustChargeLOVs = (adjustChargeLOVs?: DTO_Journal_LOVs) => {
    runInAction(() => {
      this._adjustChargeLOVs = adjustChargeLOVs;
    });
  };
}

const adjustChargeDialogStoreContext = createContext(
  new AdjustChargeDialogStore()
);
export const useAdjustChargeDialogStore = () => {
  return useContext(adjustChargeDialogStoreContext);
};
