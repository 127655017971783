import { animalRoute } from "@app/products/animals/route";
import { IFlatCCMenuItem } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import {
  convertRouteDynamic,
  IConvertRouteDynamic,
} from "@common/utils/convertRouteDynamic";
import { observer } from "mobx-react-lite";
import React from "react";
import { PrivateRoute } from "../AppRoutes";
import { ProductRouterSwitch } from "../ProductRouterSwitch";

export const AnimalRouter = observer((props: any) => {
  const { isProductActive } = useCommonProductStore();

  return (
    <ProductRouterSwitch>
      {animalRoute.children &&
        convertRouteDynamic(animalRoute.children).map(
          (route: IConvertRouteDynamic, index: number): JSX.Element | null => {
            const fullPath = `${props.match.url}/${route.path}`;
            const flatMenuData: IFlatCCMenuItem | undefined =
              isProductActive(fullPath);

            if (!flatMenuData && route.path.search(":") === -1) {
              return null;
            }

            return (
              route.component && (
                <PrivateRoute
                  exact
                  key={index}
                  path={`${props.match.url}/${route.path}`}
                  component={route.component}
                />
              )
            );
          }
        )}
    </ProductRouterSwitch>
  );
});
