import { InfringementsGrid } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/inspection/components/infringements/_index";
import { InspectionGrid } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/inspection/components/inspections/_index";
import { NoticesGrid } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/inspection/components/notices/_index";
import React from "react";
interface IInspection {
  setNoticeGridSelectData: (data: any) => void;
}

const Inspection = ({ setNoticeGridSelectData }: IInspection) => {
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <InspectionGrid />
        </div>
        <div className="cc-field">
          <NoticesGrid setNoticeGridSelectData={setNoticeGridSelectData} />
        </div>
        <div className="cc-field">
          <InfringementsGrid />
        </div>
      </div>
    </section>
  );
};

export default Inspection;
