export const loggingRouteStateGlobalSettings = [
  {
    name: "Logging_GeneralLoggingSettings",
    component: require("./forms/general-logging").default,
  },
  {
    name: "Logging_LoggingTextField",
    component: require("./forms/logging-text-field").default,
  },
  {
    name: "Logging_SerilogSettings",
    component: require("./forms/serilog-settings").default,
  },
];
