import { defaultCalendarDropDownData } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/config";
import { useAppealHearingCalendarStore } from "@app/products/town-planning/ppr/permit-appeals/hearing-calendar/store";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef } from "react";

export const HearingCalendarDropdown = observer(() => {
  const { siteUser, setSelectedSiteUser, selectedSiteUser } =
    useAppealHearingCalendarStore();

  const getDropDownData = useMemo(() => {
    if (siteUser) {
      return [defaultCalendarDropDownData, ...siteUser];
    }
    return [defaultCalendarDropDownData];
  }, [siteUser]);

  const onSelectedChange = (event: DropDownListChangeEvent) => {
    setSelectedSiteUser(event.target.value);
  };
  const dropDownListWrap = useRef<HTMLDivElement>(null);
  return (
    <div ref={dropDownListWrap} className="cc-navbar-dropdown-single">
      <CCDropDownList
        data={getDropDownData}
        textField="Name"
        dataItemKey="ID"
        value={selectedSiteUser}
        className="cc-navbar-dropdown"
        onChange={onSelectedChange}
        popupSettings={{
          popupClass: "cc-drop-down-list-wrap",
          appendTo: dropDownListWrap.current ?? undefined,
        }}
      />
    </div>
  );
});
