import { LocalGovernmentSubmitActions } from "@common/pages/settings/lookups/local-government/model";
import { useLocalGovernmentStore } from "@common/pages/settings/lookups/local-government/store";
import { LocalGovernmentForm } from "@common/pages/settings/lookups/local-government/[id]/components/child-screens/general/_index";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

export const NewLocalGovernment = observer(() => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, responseLoadError, loadLocalGovernment, onSubmit } =
    useLocalGovernmentStore();

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Local Government"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadLocalGovernment(parseInt(id), true)}
        />
      ) : (
        <>
          <FormTitle title="New Local Government" />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                name={LocalGovernmentSubmitActions.New}
                onClick={onSubmit}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <LocalGovernmentForm />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
