import { CSLSystemSettingCategoriesForm } from "@app/products/csl/[id]/model";
import { useIsNew } from "@common/hooks/useIsNew";
import { Toast } from "@components/toast";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistManageCSLSystemAdminCategories } from "./components/forms/existed/_index";
import { NewManageCSLSystemSettingCategories } from "./components/forms/new/_index";

const ManageCSLSystemAdminCategories = observer(() => {
  const isNew = useIsNew();
  const history = useHistory<any>();

  const [isLoading, setIsLoading] = useState(false);

  const [cslSystemSettingCategoriesInfo] = useState<
    CSLSystemSettingCategoriesForm | undefined
  >(undefined);

  useEffectOnce(() => {
    const { state } = history.location;
    if (state?.status === "success") {
      Toast.displayToast({
        content: "🚀 Save CSL System Admin Categories successfully !",
        type: "success",
      });
      history.replace(window.location.pathname); // remove history states
    }
  });

  if (isNew) {
    return <NewManageCSLSystemSettingCategories setIsLoading={setIsLoading} />;
  }
  return (
    <ExistManageCSLSystemAdminCategories
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      cslSystemAdminCategoriesInfo={cslSystemSettingCategoriesInfo}
    />
  );
});
export default ManageCSLSystemAdminCategories;
