import { mockActionsRejectedDetailTab } from "@app/products/property/actions/rejected-list/components/reference-sidebar/detail/mock";
import { IActionsRejectedDetailTab } from "@app/products/property/actions/rejected-list/components/reference-sidebar/detail/model";
import { sleep } from "@common/utils/common";

export const getRejectedDetailsTabById = async (
  recordId: number
): Promise<IActionsRejectedDetailTab | undefined> => {
  await sleep(1000);
  return { Id: recordId, ...mockActionsRejectedDetailTab };
};
