import { DTO_ChangeOfOwnership_NoticeOfSale_LOVs } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ChangeOfOwnershipNoSDialogStore {
  private _changeOfOwnershipNoSLOVs?: DTO_ChangeOfOwnership_NoticeOfSale_LOVs =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get changeOfOwnershipNoSLOVs() {
    return this._changeOfOwnershipNoSLOVs;
  }
  setChangeOfOwnershipNoSLOVs = (
    changeOfOwnershipNoSLOVs?: DTO_ChangeOfOwnership_NoticeOfSale_LOVs
  ) => {
    runInAction(() => {
      this._changeOfOwnershipNoSLOVs = changeOfOwnershipNoSLOVs;
    });
  };
}

const changeOfOwnershipNoSDialogStoreContext = createContext(
  new ChangeOfOwnershipNoSDialogStore()
);
export const useChangeOfOwnershipNoSDialogStore = () => {
  return useContext(changeOfOwnershipNoSDialogStoreContext);
};
