import { CRMSViews } from "@app/products/crms/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";

export const knowledgeBaseRoute: ICCRoute = {
  path: "knowledge-base",
  name: "Knowledge Base",
  enum: CORPORATE_SETTINGS_FIELD.CUSTOMERSERVICE_Views_KnowledgeBase,
  children: [
    {
      path: "published",
      name: "Published",
      enum: CRMSViews.KB_Published,
      component: require("./published/_index").default, // navigated to Radium already
    },
    {
      path: "published-by-org-structure",
      name: "Published By Org Structure",
      enum: CRMSViews.KB_PublishedByOrgStructure,
      component: require("./published-by-org-structure/_index").default, // navigated to Radium already
    },
    {
      path: "unreleased",
      name: "Unreleased",
      enum: CRMSViews.KB_Unreleased,
      component: require("./unreleased/_index").default, // navigated to Radium already
    },
    {
      path: "due-for-review",
      name: "Due for Review",
      enum: CRMSViews.KB_DueForReview,
      component: require("./due-for-review/_index").default, // navigated to Radium already
    },
    {
      path: "under-review",
      name: "Under Review",
      enum: CRMSViews.KB_UnderReview,
      component: require("./under-review/_index").default, // navigated to Radium already
    },
    {
      path: "expired",
      name: "Expired",
      enum: CRMSViews.KB_Expired,
      component: require("./expired/_index").default, // navigated to Radium already
    },
    {
      path: "drafts",
      name: "Drafts",
      enum: CRMSViews.KB_Drafts,
      component: require("./drafts/_index").default, // navigated to Radium already
    },
    {
      path: "no-service-standards",
      name: "No Service Standard(s)",
      enum: CRMSViews.KB_NoServiceStandards,
      component: require("./no-service-standards/_index").default, // navigated to Radium already
    },
    {
      path: "alerts",
      name: "Alerts",
      enum: CRMSViews.KB_Alerts,
      component: require("./alerts/_index").default, // navigated to Radium already
    },
    {
      path: "topics",
      name: "Topics",
      enum: CRMSViews.KB_Topics,
      component: require("./topics/_index").default, // navigated to Radium already
    },
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default, // navigated to Radium already
    },
  ],
};
