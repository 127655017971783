import { IRouteStateForm } from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";

export const generalRouteStateGlobalSettings: IRouteStateForm[] = [
  {
    name: "General_Details",
    component: require("./forms/general-details").default,
  },
  {
    name: "General_MailSettings",
    component: require("./forms/general-mail-settings").default,
  },
  {
    name: "General_ProxySettings",
    component: require("./forms/general-proxy-settings").default,
  },
  {
    name: "General_GeneralSettings",
    component: require("./forms/general-general-settings").default,
  },
  {
    name: "General_AddDocumentOptionsDefaultValue",
    component: require("./forms/general-add-document-options-default-value")
      .default,
  },
  {
    name: "General_AddDocumentOptions",
    component: require("./forms/general-add-document-options").default,
  },
  {
    name: "General_Conditions",
    component: require("./forms/general-conditions").default,
  },
  {
    name: "General_PhoneNumberFormatValidation",
    component: require("./forms/general-phone-number-format-validation")
      .default,
  },
  {
    name: "General_SiteUserSettings",
    component: require("./forms/general-site-user").default,
  },
  {
    name: "General_PublicHolidays",
    component: require("./forms/general-public-holidays").default,
  },
  {
    name: "General_BusinessHours",
    component: require("./forms/general-business-hour").default,
  },
  {
    name: "General_MenuGroups",
    component: require("./forms/general-menu-groups").default,
  },
  {
    name: "General_MailMerge",
    component: require("./forms/general-mail-merge").default,
  },
  {
    name: "General_JobQueue",
    component: require("./forms/general-job-queue").default,
  },
  {
    name: "General_Actions",
    component: require("./forms/general-actions").default,
  },
  {
    name: "General_Advertising",
    component: require("./forms/general-advertising").default,
  },
  {
    name: "General_FurtherInformation",
    component: require("./forms/general-further-information").default,
  },
  {
    name: "General_Keywords",
    component: require("./forms/general-keywords").default,
  },
  {
    name: "General_NCItemSettings",
    component: require("./forms/general-nc-item").default,
  },
  {
    name: "General_QualificationSettings",
    component: require("./forms/general-qualification").default,
  },
  {
    name: "General_OrgStructureSettings",
    component: require("./forms/general-org-structure").default,
  },
];
