import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfHolding = nameOfFactory<VO_Assessment_w_Detail>();
export const colAssessmentDisassociate: IColumnFields[] = [
  {
    field: nameOfHolding("Assessment_Reference"),
    title: "Number",
  },
  {
    field: nameOfHolding("Assessment_Number_Formatted"),
    title: "Number",
  },
  {
    field: nameOfHolding("Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOfHolding("Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfHolding("Property_Locality"),
    title: "Property Locality",
  },
  {
    field: nameOfHolding("Property_Location_Descriptor"),
    title: "Property Location",
  },
  { field: nameOfHolding("Legal_Description"), title: "Legal Description" },
  { field: nameOfHolding("Improvements"), title: "Improvements" },
  {
    field: nameOfHolding("Primary_Land_Use_Name"),
    title: "Primary Land Use",
  },
  { field: nameOfHolding("Assessment_Type_Name"), title: "Type" },
  { field: nameOfHolding("Assessment_Status_Name"), title: "Status" },
  { field: nameOfHolding("Owners_Name"), title: "Owners Name" },
  { field: nameOfHolding("Owners_State"), title: "Owners State" },
  { field: nameOfHolding("Owners_Address"), title: "Owners Address" },
  { field: nameOfHolding("Owners_Locality"), title: "Owners Locality" },
  {
    field: nameOfHolding("Owners_Name_Address"),
    title: "Owners Name Address",
  },
  { field: nameOfHolding("Owners_Postcode"), title: "Owners Postcode" },
  {
    field: nameOfHolding("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
