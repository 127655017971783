import { Inspection } from "@app/core/inspections/[id]/model";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { InspectionFormElement } from "./components/inspection-form-element/_index";

export const InspectionForm = observer(() => {
  const { inspections, saveInspections, setOnSubmit, isFormModified } =
    useInspectionsStore();
  const { pushNotification } = useCCAppNotificationStore();
  const isNew = useIsNew();
  const submitRef =
    useRef<(event: React.SyntheticEvent<any>) => void | undefined>();
  const handleOnSubmit = (submitEvent: FormSubmitClickEvent) => {
    const { values, isModified, isValid } = submitEvent;
    const actionSubmit = submitEvent.event?.currentTarget
      .name as ActionSubmitActions;
    if (!(actionSubmit in ActionSubmitActions)) return;

    if (!isValid) return;
    if (
      !isModified &&
      [ActionSubmitActions.Save].includes(actionSubmit) &&
      !isFormModified
    )
      return pushNotification({
        title: "The form is not modified",
        type: "warning",
      });

    saveInspections(
      { ...values, Description: values.Description ?? "" } as Inspection,
      isNew
    );
  };

  useEffect(() => {
    if (submitRef.current) setOnSubmit(submitRef.current);
    //eslint-disable-next-line
  }, [submitRef.current, inspections]);

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={inspections}
        key={JSON.stringify(inspections)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = formRenderProps.onSubmit;
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <InspectionFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
