import { IConfirmation } from "@app/products/property/assessments/components/form-steps/new-assessment/dialog/confirmation/model";
import { ConfirmationDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirmation/_index";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { DTO_Rejection_LOVs } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/model";
import { getWorkflowRejectionReasons } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/api";

export interface IRejectDialogProps {
  onClose: () => void;
  onSubmitData: (data: IConfirmation) => void;
  description?: string;
  dataLOVsReason?: DTO_LOV[];
  isLoadingSendData?: boolean;
  notifications?: IAppNotificationItem[];
}

export const RejectDialog = (props: IRejectDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rejectionLOVs, setRejectionLOVs] = useState<DTO_Rejection_LOVs>();
  const [responseLoadError, setResponseError] = useState<
    APIResponseError | undefined
  >();

  const loadRejectDataLOVs = async () => {
    setIsLoading(true);
    const response = await getWorkflowRejectionReasons();
    let errorResponse;
    if (isSuccessResponse(response)) {
      setRejectionLOVs(response.data);
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setResponseError(errorResponse);
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadRejectDataLOVs();
  });

  return (
    <ConfirmationDialog
      {...props}
      titleHeader="Reject Confirmation"
      subject="Are you sure you want to reject this workflow?"
      customLabelReason="Rejected Reason"
      dataLOVsReason={rejectionLOVs?.ReasonForRejection ?? []}
      isLoading={isLoading}
      responseLoadError={responseLoadError}
      onReload={() => loadRejectDataLOVs()}
    />
  );
};
