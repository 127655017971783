import { DocumentTemplateForm } from "@app/products/property/system-admin/document-template/[id]/components/child-screens/general/_index";
import { useDocumentTemplateStore } from "@app/products/property/system-admin/document-template/[id]/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";

export const NewDocumentTemplate = observer(() => {
  const { isLoading, isLoadingSave } = useDocumentTemplateStore();
  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Document Template"} />

      <FormTitle title="New Document Template" />

      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Save"}
            htmlFor={"btn-submit"}
            isLoading={isLoadingSave}
          />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <div className="cc-manage-form-body">
            <DocumentTemplateForm />
          </div>
        </div>
      </div>
    </>
  );
});
