import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { AssociateMeterButton } from "@app/products/property/meters/list/components/action-bar/buttons/tools/associate-meter/_index";
import { UpdateMasterMeterButton } from "@app/products/property/meters/list/components/action-bar/buttons/tools/update-master-meter/_index";
import { ResequenceMeterButton } from "@app/products/property/meters/reading-routes/components/action-bar/buttons/tools/re-sequence-meter/_index";
import { colMeterReadingRoutes } from "@app/products/property/meters/reading-routes/config";
import { mockMeterReadingRoutes } from "@app/products/property/meters/reading-routes/mock";
import { MeterReadingRouteDetailTab } from "@app/products/property/meters/reading-routes/[id]/components/reference-sidebar/detail/_index";
import { metersRoute } from "@app/products/property/meters/route";
import { propertyRoute } from "@app/products/property/route";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";

export default () => {
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={metersRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title={"Tools"} type="more">
        <AssociateMeterButton />
        <UpdateMasterMeterButton />
        <ResequenceMeterButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon url={PROPERTY_METERS_LIST_ROUTE} />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <MeterReadingRouteDetailTab /> },
    ],
  });

  return (
    <CCProductListView
      data={mockMeterReadingRoutes}
      columnFields={colMeterReadingRoutes}
      primaryField={"ReadingRouteId"}
    />
  );
};
