import { MemoDialog } from "@app/products/property/meters/[id]/components/action-bar/dialogs/memo/_index";
import { IMeterMemo } from "@app/products/property/meters/[id]/components/child-screens/memos/model";
import { useMeterMemoStore } from "@app/products/property/meters/[id]/components/child-screens/memos/store";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useEffectOnce } from "react-use";

export const nameOf = nameOfFactory<IMeterMemo>();

export const MeterMemos = observer(() => {
  const {
    memos,
    isLoading,
    loadMemos,
    selectedMemo,
    setSelectedMemo,
    gridColumns,
    editMemos,
  } = useMeterMemoStore();
  const { pushNotification } = useCCAppNotificationStore();

  useEffectOnce(() => {
    loadMemos();
  });

  const handleEditMemo = useCallback(
    (data) => {
      editMemos(data as IMeterMemo);
      setSelectedMemo(undefined);
      pushNotification({
        title: `Memo edited successfully`,
        type: "success",
      });
    },
    [editMemos, setSelectedMemo, pushNotification]
  );

  return (
    <div className="cc-meter-memos">
      <CCGrid
        isLoading={isLoading}
        className="cc-meter-memos-grid"
        data={memos}
        columnFields={gridColumns}
        primaryField={nameOf("MemoId")}
      />
      {selectedMemo ? (
        <MemoDialog
          existingMemo={selectedMemo}
          onClose={() => {
            setSelectedMemo(undefined);
          }}
          onSubmit={handleEditMemo}
        />
      ) : null}
    </div>
  );
});
