export const mockBuildingCalendar = [
  {
    TaskID: 1,
    OwnerID: 2,
    Title: "PTN New Year Meeting",
    RoomID: 1,
    Description: "Annualy Meeting!",
    StartTimezone: null,
    Start: "2020-12-20T01:00:00.000Z",
    End: "2020-12-20T03:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "Meeting",
  },
  {
    TaskID: 2,
    OwnerID: 3,
    Title: "PTN Coffee Time",
    RoomID: 2,
    Description: "",
    StartTimezone: null,
    Start: "2020-12-21T05:00:00.000Z",
    End: "2020-12-21T06:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "messi",
  },

  {
    TaskID: 3,
    OwnerID: 4,
    Title: "Status Update Meetings",
    RoomID: 3,
    Description: "Status Update Meetings",
    StartTimezone: null,
    Start: "2020-12-22T02:00:00.000Z",
    End: "2020-12-22T05:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "abby",
  },

  {
    TaskID: 5,
    OwnerID: 6,
    Title: "PTN - December Job Interview - Oncall #3",
    RoomID: 5,
    Description: "PTN - December Job Interview - Oncall #3",
    StartTimezone: null,
    Start: "2020-12-24T05:00:00.000Z",
    End: "2020-12-24T07:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "agata",
  },

  {
    TaskID: 6,
    OwnerID: 7,
    Title: "PTN - English Club",
    RoomID: 6,
    Description: "PTN - English Club",
    StartTimezone: null,
    Start: "2020-12-23T05:00:00.000Z",
    End: "2020-12-23T07:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "alexander",
  },

  {
    TaskID: 7,
    OwnerID: 8,
    Title: "PTN - English Speaking",
    RoomID: 7,
    Description: "PTN - English Speaking",
    StartTimezone: null,
    Start: "2020-12-18T05:00:00.000Z",
    End: "2020-12-18T09:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "alexander",
  },

  {
    TaskID: 7,
    OwnerID: 8,
    Title: "PTN - EClub",
    RoomID: 7,
    Description: "PTN - EClub",
    StartTimezone: null,
    Start: "2020-12-01T05:00:00.000Z",
    End: "2020-12-01T09:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "alexander",
  },

  {
    TaskID: 8,
    OwnerID: 9,
    Title: "Research Festival",
    RoomID: 8,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-01T05:00:00.000Z",
    End: "2020-12-04T09:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "Meeting",
  },

  {
    TaskID: 9,
    OwnerID: 10,
    Title: "Technical Meeting",
    RoomID: 9,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-12T05:00:00.000Z",
    End: "2020-12-13T09:00:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "Meeting",
  },

  {
    TaskID: 10,
    OwnerID: 11,
    Title: "Coffee Hour",
    RoomID: 10,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-12T05:00:00.000Z",
    End: "2020-12-12T05:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "messi",
  },

  {
    TaskID: 11,
    OwnerID: 12,
    Title: "Coffee Hour",
    RoomID: 11,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-10T05:00:00.000Z",
    End: "2020-12-10T05:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "messi",
  },

  {
    TaskID: 12,
    OwnerID: 13,
    Title: "Coffee Hour",
    RoomID: 12,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-06T05:00:00.000Z",
    End: "2020-12-06T05:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "messi",
  },

  {
    TaskID: 13,
    OwnerID: 14,
    Title: "Coffee Hour",
    RoomID: 13,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-08T05:00:00.000Z",
    End: "2020-12-08T05:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "messi",
  },

  {
    TaskID: 13,
    OwnerID: 14,
    Title: "Coffee Hour",
    RoomID: 13,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-30T05:00:00.000Z",
    End: "2020-12-30T05:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "messi",
  },

  {
    TaskID: 14,
    OwnerID: 15,
    Title: "Oncall Interview #1",
    RoomID: 14,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-02T05:00:00.000Z",
    End: "2020-12-02T07:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "agata",
  },

  {
    TaskID: 15,
    OwnerID: 16,
    Title: "Oncall Interview #1",
    RoomID: 15,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-07T05:00:00.000Z",
    End: "2020-12-07T07:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "agata",
  },

  {
    TaskID: 16,
    OwnerID: 17,
    Title: "Oncall Interview #1",
    RoomID: 16,
    Description: "Research Festival",
    StartTimezone: null,
    Start: "2020-12-15T05:00:00.000Z",
    End: "2020-12-15T07:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "agata",
  },

  {
    TaskID: 17,
    OwnerID: 18,
    Title: "Oncall Interview #1",
    RoomID: 17,
    Description: "Oncall Interview #1",
    StartTimezone: null,
    Start: "2020-12-15T05:00:00.000Z",
    End: "2020-12-15T07:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "abby",
  },
  {
    TaskID: 18,
    OwnerID: 19,
    Title: "Oncall Interview #2",
    RoomID: 18,
    Description: "Oncall Interview #2",
    StartTimezone: null,
    Start: "2020-12-16T03:00:00.000Z",
    End: "2020-12-16T08:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "abby",
  },
  {
    TaskID: 19,
    OwnerID: 20,
    Title: "Oncall Interview #3",
    RoomID: 19,
    Description: "Oncall Interview #3",
    StartTimezone: null,
    Start: "2020-12-17T03:00:00.000Z",
    End: "2020-12-17T08:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "abby",
  },

  {
    TaskID: 20,
    OwnerID: 20,
    Title: "Oncall Interview #4",
    RoomID: 19,
    Description: "Oncall Interview #4",
    StartTimezone: null,
    Start: "2020-12-29T03:00:00.000Z",
    End: "2020-12-29T08:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "abby",
  },

  {
    TaskID: 21,
    OwnerID: 21,
    Title: "Oncall Interview #4",
    RoomID: 20,
    Description: "Oncall Interview #5",
    StartTimezone: null,
    Start: "2020-12-31T03:00:00.000Z",
    End: "2020-12-31T08:30:00.000Z",
    EndTimeZone: null,
    RecurrenceRule: null,
    RecurrenceID: null,
    RecurrenceException: null,
    isAllDay: false,
    name: "abby",
  },
];
