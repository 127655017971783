import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import React from "react";

export const CustomFilter = (props: any) => {
  const onChange = (event: DropDownListChangeEvent) => {
    if (!event.target.value) {
      return;
    }
    props.onFilterChange.call(undefined, {
      nextFilter: { ...props.filter, value: event.target.value?.id },
    });
  };

  return (
    <DropDownList
      textField="description"
      dataItemKey="id"
      onChange={onChange}
      value={null}
      data={[]}
    />
  );
};
