import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export interface DTO_Workflow_ModifyGIS {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ModifyGIS;
}

export interface DTO_Form {
  FormId: number;
  ComponentNo: number | null;
}

export interface DTO_WorkflowDetail_ModifyGIS {
  ParcelId: number;
  GISTypes: DTO_LOV[];
  SelectGISReference: DTO_GIS[];
  ParcelReference?: string;
}

export class IActionOnSubmitGISReference {
  notification?: {
    success?: string;
    error?: string;
  };
  action?: (res?: any) => void;
}
