import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { observer } from "mobx-react-lite";
import React from "react";

export const PermitReferralMailMergeButton = observer(() => {
  const { settings } = useCommonProductStore();
  const displayOtherRegisters = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_DisplayRegister]
  );
  return !displayOtherRegisters ? (
    <MailMergeButtonListView
      productType={PRODUCT_TYPE_NUMBER.TownPlanning}
      dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_PPR_Applications}
      recordType={RECORDTYPE.TP_PPRApplication}
    />
  ) : null;
});
