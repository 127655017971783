import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { DynamicValueCell } from "@components/cc-grid/components/grid-cells/components/dynamic-value-cell/_index";
import { ColumnLinkTo, ICustomEditCell } from "@components/cc-grid/model";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  DateTimePicker,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import React, { ComponentType } from "react";
import { ICCGridCellProps } from "../model";

interface IDateTimeCellProps extends ICCGridCellProps {
  value?: Date | null;
  format?: DATE_FORMAT | DATETIME_FORMAT;
  linkTo?: ColumnLinkTo;
  editable?: boolean;
  editCell?: ComponentType<ICustomEditCell>;
  onEnterEditCell?: (dataItem: any, field: string) => void;
}

export const DateTimeCell = (props: IDateTimeCellProps) => {
  const {
    id,
    dataItem,
    ariaColumnIndex,
    columnIndex,
    colSpan,
    isSelected,
    value = null,
    format = DATE_FORMAT.DATE,
    isDisable = false,
    style,
    handleOnClick,
    linkTo,
    href,
    className,
    editable,
    onChange,
    dataIndex,
    field,
    onEnterEditCell,
    editCell: EditCell,
  } = props;

  const defaultStyle: React.CSSProperties = {
    textAlign: "right",
  };
  let customStyle: React.CSSProperties | undefined =
    typeof style === "function" ? style(value) : style;
  customStyle = customStyle
    ? { ...defaultStyle, ...customStyle }
    : defaultStyle;

  const formatValue = formatDateByKendo(value, format);

  const navigationAttributes = useTableKeyboardNavigation(id + "");

  const handleOnChange = (event: DateTimePickerChangeEvent) => {
    if (onChange)
      onChange({
        syntheticEvent: event.syntheticEvent,
        dataItem: dataItem,
        dataIndex: dataIndex ?? 0,
        field: field,
        value: event.target.value,
      });
  };

  const handleInEdit = () => {
    if (onEnterEditCell) onEnterEditCell(dataItem, field ?? "");
  };
  const willNavigate = (linkTo && linkTo(dataItem)) || (href && href(dataItem));
  if ((handleOnClick || willNavigate) && !isDisable) {
    return (
      <td
        className={`${className} cc-grid-link-text`}
        style={customStyle}
        colSpan={colSpan}
        role={"gridcell"}
        aria-colindex={ariaColumnIndex}
        aria-selected={isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: columnIndex,
        }}
        {...navigationAttributes}
        onClick={(event: any) => {
          // In order to abort rowSelection
          event.stopPropagation();
          // In order to abort cell selection
          if (!willNavigate) {
            event.preventDefault();
          }
          if (typeof handleOnClick === "function") handleOnClick(dataItem);
        }}
      >
        <DynamicValueCell
          value={formatValue}
          dataItem={dataItem}
          linkTo={linkTo}
          href={href}
        />
      </td>
    );
  }

  return (
    <td
      className={`${className}`}
      onClick={handleInEdit}
      style={customStyle}
      colSpan={colSpan}
      role={"gridcell"}
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: columnIndex,
      }}
      {...navigationAttributes}
    >
      {editable ? (
        EditCell ? (
          <EditCell
            value={value}
            field={field}
            format={format}
            dataItem={dataItem}
            dataIndex={dataIndex}
            onChange={onChange}
          />
        ) : (
          <DateTimePicker
            value={value === undefined ? null : value}
            format={DATETIME_FORMAT.DATETIME_CONTROL}
            onChange={handleOnChange}
          />
        )
      ) : formatValue === null ? (
        ""
      ) : (
        formatValue
      )}
    </td>
  );
};
