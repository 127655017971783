import { MergeContactDialog } from "@app/core/contacts/merge-contact/merge-contact-dialog/_index";
import { useMergeContactStore } from "@app/core/contacts/merge-contact/store";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";
interface IMergeContactButtonProps {
  disabled?: boolean;
}

export const MergeContactButton = observer(
  ({ disabled = false }: IMergeContactButtonProps) => {
    const { settings } = useCommonCoreStore();
    const { isShowMergeDialog, setIsShowMergeDialog } = useMergeContactStore();
    const { gridSelectedIds } = useCCProductListViewStore();
    const isEnableMergeDuplicateContacts = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableMergeDuplicateContacts
      ]
    );
    return (
      <>
        <CCNavButton
          title="Merge selected duplicates"
          onClick={() => {
            setIsShowMergeDialog(true);
          }}
          disabled={disabled || gridSelectedIds.length === 0}
          invisible={!isEnableMergeDuplicateContacts}
        />

        {isShowMergeDialog && (
          <MergeContactDialog
            listIdSelected={gridSelectedIds}
            title="Merge Contacts"
            onClosePopup={() => {
              setIsShowMergeDialog(false);
            }}
          />
        )}
      </>
    );
  }
);
