import { history } from "@/AppRoutes";
import { colHMPremisesAlert } from "@app/products/hm/config";
import { HMPremisesView } from "@app/products/hm/premises/model";
import { nameOfHMPremisesView } from "@app/products/hm/premises/util";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { isEmpty, isNil } from "lodash";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesPrelodgements = [
  {
    field: nameOfHMPremisesView("RegNo"),
    title: "Registration Number",
    locked: true,
    handleOnClick: (rowValue: HMPremisesView) => {
      // Confirmed with Zach and Rebecca in the catch-up meeting 03/10/2024
      // If the RegNo is empty or null, click on the blank cell to navigate
      if (isEmpty(rowValue?.RegNo) || isNil(rowValue?.RegNo)) {
        history.push(`${PREMISES_MANAGE_ROUTE}/${rowValue.ID}`);
      }
    },
    linkTo: (dataItem: HMPremisesView) => {
      return `${PREMISES_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  ...colHMPremisesAlert,
  {
    field: nameOfHMPremisesView("TradingName"),
    title: "Trading Name",
  },
  {
    field: nameOfHMPremisesView("LocationDesc"),
    title: "Location Description",
  },
  {
    field: nameOfHMPremisesView("StreetNo"),
    title: "Number",
  },
  {
    field: nameOfHMPremisesView("Street"),
    title: "Street",
  },
  {
    field: nameOfHMPremisesView("Suburb"),
    title: "Suburb",
  },
  {
    field: nameOfHMPremisesView("Area"),
    title: "Area",
  },
  {
    field: nameOfHMPremisesView("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOfHMPremisesView("Phone"),
    title: "Phone",
  },
  {
    field: nameOfHMPremisesView("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfHMPremisesView("Risk"),
    title: "Risk",
  },
  {
    field: nameOfHMPremisesView("Officer"),
    title: "Officer",
  },
  {
    field: nameOfHMPremisesView("RefNo"),
    title: "Reference Number",
  },
  {
    field: nameOfHMPremisesView("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfHMPremisesView("DebtorNo"),
    title: "Debtor Number",
  },
  {
    field: nameOfHMPremisesView("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfHMPremisesView("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("Email"),
    title: "Email",
  },
  {
    field: nameOfHMPremisesView("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfHMPremisesView("PostalAddress2"),
    title: "Postal Address 2",
  },
  {
    field: nameOfHMPremisesView("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfHMPremisesView("NoEmployees"),
    title: "Employees",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfHMPremisesView("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfHMPremisesView("FSPContact"),
    title: "FSS Contact",
  },
  {
    field: nameOfHMPremisesView("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesView("Fax"),
    title: "Fax",
  },
  {
    field: nameOfHMPremisesView("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
