import { getSchemeAccountById } from "@app/products/property/schemes/[id]/account/[id]/api";
import { ISchemeAccountResponse } from "@app/products/property/schemes/[id]/account/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class SchemesAccountStore {
  private _schemesAccount?: ISchemeAccountResponse = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };
  get schemesAccount() {
    return this._schemesAccount;
  }
  setSchemesAccount = (accountId?: ISchemeAccountResponse) => {
    runInAction(() => {
      this._schemesAccount = accountId;
    });
  };
  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get schemesAccountId() {
    return this.schemesAccount?.AccountId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._schemesAccount = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };
  loadSchemesAccount = async (accountId: number) => {
    this.setIsLoading(true);
    const response = await getSchemeAccountById(accountId);
    let errorResponse = undefined;
    let newSchemesAccount = undefined;
    if (isSuccessResponse(response)) {
      newSchemesAccount = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setSchemesAccount(newSchemesAccount);
    this.setIsLoading(false);
  };
}

const schemesAccountStoreContext = createContext(new SchemesAccountStore());
export const useSchemesAccountStore = () => {
  return useContext(schemesAccountStoreContext);
};
