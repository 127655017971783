import { DateTimePickerSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/date-time-picker/_index";
import { InputSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/input/_index";
import { SearchComboBoxSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/search-combobox/_index";
import { SwitchSettings } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/components/setting-field/switch/_index";
import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { onSubmitFormGlobalSettings } from "@common/pages/settings/system-admin/global-settings/_id/main/util";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};

export const DirectExportForm = ({ formRenderProps }: ICommonFormProps) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();
  const { valueGetter, onChange } = formRenderProps;
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoadingResetButton, setIsLoadingResetButton] = useState(false);
  const data = useDataSetting(configData, [
    "CorporateSettings_EnableContactExportSystem",
    "CorporateSettings_ContactExportSystem",
    "CorporateSettings_ContactsExportFileDirectory_Oracle",
    "CorporateSettings_AgentContactExportLastRunTime",
    "CorporateSettings_WorkRequestURL",
    "CorporateSettings_ContactExportSystemURL",
    "CorporateSettings_ContactExportSystemUserName",
    "CorporateSettings_ContactExportSystemPassword",
    "CorporateSettings_ContactExportEmailNotification",
    "CorporateSettings_MDMErrorNotification",
    "CorporateSettings_ExportToMDM_CRS",
    "CorporateSettings_ExportToMDM_HM",
  ]);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleResetButton = async (event: any) => {
    setIsLoadingResetButton(true);
    const lastExportRunTime = valueGetter(
      data.CorporateSettings_AgentContactExportLastRunTime?.FieldName as string
    );
    if (lastExportRunTime !== "01/01/1950 5:30:00 AM") {
      onChange(
        data.CorporateSettings_AgentContactExportLastRunTime
          ?.FieldName as string,
        {
          value: "01/01/1950 5:30:00 AM",
        }
      );
      await onSubmitFormGlobalSettings(event);
    } else {
      pushNotification({
        title: "Full extract has already resetted",
        type: "info",
        autoClose: true,
      });
    }
    setIsShowDialog(false);
    setIsLoadingResetButton(false);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <SwitchSettings
          data={data.CorporateSettings_EnableContactExportSystem}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        {valueGetter(
          data.CorporateSettings_EnableContactExportSystem?.FieldName as string
        ) && (
          <>
            <SearchComboBoxSettings
              data={data.CorporateSettings_ContactExportSystem}
              isEditing={isEditing}
            />
            {valueGetter(
              data.CorporateSettings_ContactExportSystem?.FieldName as string
            ) === "1" && (
              <>
                <InputSettings
                  data={data.CorporateSettings_WorkRequestURL}
                  isEditing={isEditing}
                />
                <InputSettings
                  data={data.CorporateSettings_ContactExportSystemURL}
                  isEditing={isEditing}
                />
                <InputSettings
                  data={data.CorporateSettings_ContactExportSystemUserName}
                  isEditing={isEditing}
                />
                <InputSettings
                  data={data.CorporateSettings_ContactExportSystemPassword}
                  isEditing={isEditing}
                />
                <InputSettings
                  data={data.CorporateSettings_ContactExportEmailNotification}
                  isEditing={isEditing}
                />
                <div className="cc-field">
                  <label className="cc-label">&nbsp;</label>
                  <div>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsShowDialog(true);
                      }}
                      disabled={!isEditing}
                    >
                      {isLoadingResetButton ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : null}
                      Reset For Full Extract
                    </Button>
                  </div>
                </div>
                <SwitchSettings
                  data={data.CorporateSettings_MDMErrorNotification}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
                <SwitchSettings
                  data={data.CorporateSettings_ExportToMDM_CRS}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
                <SwitchSettings
                  data={data.CorporateSettings_ExportToMDM_HM}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              </>
            )}
            {valueGetter(
              data.CorporateSettings_ContactExportSystem?.FieldName as string
            ) === "4" && (
              <>
                <InputSettings
                  data={
                    data.CorporateSettings_ContactsExportFileDirectory_Oracle
                  }
                  isEditing={isEditing}
                />
                <DateTimePickerSettings
                  data={data.CorporateSettings_AgentContactExportLastRunTime}
                  isEditing={isEditing}
                  formRenderProps={formRenderProps}
                />
              </>
            )}
            {isShowDialog && (
              <ConfirmDialog
                title="Confirm Reset"
                message={
                  "This will reset the contact export agent for a full extract, continue?"
                }
                onClosePopup={() => {
                  setIsShowDialog(false);
                }}
                onAsyncConfirm={(e) => {
                  handleResetButton(e);
                }}
              />
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default observer(DirectExportForm);
