export interface Svc_Animals_InspectionPlanner {
  ID: number;
  Area: string;
  Address: string;
  Location: string;
  Details: string;
  Coordinator: object;
  Officer: string;
  DateRecorded: Date | null;
  StartDate: Date | null;
  InspectionFrequencyInUnits: number | null;
  InspectionFrequency: string;
  InspectionFrequencyType: number | null;
  InspectionFrequencyType_Name: string;
  Priority: string;
  IsActive: boolean | null;
  PrioritySortIndex: number;
  LastInspection: Date | null;
  SystemFilter_ENUMS: string;
  NextInspection: Date | null;
  ColourCode: number | null;
  ProductType: number | null;
}

export enum InspectionPlannerType {
  ByArea = "area",
  ByPriority = "priority",
  ByOfficer = "officer",
  OverDue = "overdue",
}
