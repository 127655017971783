import { PPRAppeal } from "@app/products/town-planning/ppr/permit-appeals/all/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { APPEAL_ROUTE } from "@common/pages/appeal/_id/constant";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<PPRAppeal>();

export const colPPRAppealsCurrentByMonth: IColumnFields[] = [
  {
    field: nameOf("AppealNo"),
    title: "Appeal Number",
    locked: true,
    linkTo: (dataItem) => `${APPEAL_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("Ward"),
    title: "Ward",
  },
  {
    field: nameOf("ApplicationNo"),
    title: "Application Number",
  },
  {
    field: nameOf("AppealNo"),
    title: "Appeal Number",
  },
  {
    field: nameOf("SiteAddress"),
    title: "Site Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("DotRegion"),
    title: "DoT Region",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("CouncilRepresentation"),
    title: "Council Representation",
  },
  {
    field: nameOf("Category"),
    title: "Application Category",
  },
  {
    field: nameOf("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ApplicationOfficerDecision"),
    title: "Officer Decision",
  },
  {
    field: nameOf("ApplicationDecision"),
    title: "Council Decision",
  },
  {
    field: nameOf("ApplicationDateDecided"),
    title: "Decision Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateAppealed"),
    title: "Appeal Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Grounds"),
    title: "Grounds",
  },
  {
    field: nameOf("HearingDate"),
    title: "Hearing Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("HearingType"),
    title: "Hearing Type",
  },
  {
    field: nameOf("SOGDueDate"),
    title: "SOG Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Flag_DotAttendingHearing"),
    title: "Dot Attending Hearing",
  },
  {
    field: nameOf("PracticeHearingDate"),
    title: "Practice Hearing Date",
    format: DATE_FORMAT.DATE,
  },

  {
    field: nameOf("PracticeHearingDuration"),
    title: "Practice Hearing Duration",
  },
  {
    field: nameOf("PracticeHearingRepresentationOfficer"),
    title: "Practice Hearing Representation Officer",
  },
  {
    field: nameOf("Flag_DotAttendingPracticeHearing"),
    title: "Dot Attending Practice Hearing",
  },
  {
    field: nameOf("CCDate"),
    title: "CC Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CCDuration"),
    title: "CC Duration",
  },
  {
    field: nameOf("CCOfficer"),
    title: "CC Officer",
  },
  {
    field: nameOf("Flag_CCDotAttending"),
    title: "Dot Attending CC",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Decision"),
    title: "Tribunal Decision",
  },

  {
    field: nameOf("DecisionDate"),
    title: "Tribunal Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("TribunalMember"),
    title: "Tribunal Member",
  },
  {
    field: nameOf("DecisionStatus"),
    title: "Tribunal Status",
  },
  {
    field: nameOf("KeyIssues"),
    title: "Key Issues",
  },
  {
    field: nameOf("DecisionPolicyIssues"),
    title: "Decision/Policy Issues",
  },
  {
    field: nameOf("CouncilPosition"),
    title: "Council Position",
  },
  {
    field: nameOf("CouncilPositionDate"),
    title: "Position Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
  },
  {
    field: nameOf("CostOfWorks"),
    title: "Cost",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("DateAppealedMonth"),
    title: "Date Appealed Month",
    format: DATE_FORMAT.MONTH_YEAR,
  },
];
