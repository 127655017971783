import { ICustomEditCell } from "@components/cc-grid/model";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import React from "react";

export const DateCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  format,
}: ICustomEditCell) => {
  return (
    <DatePicker
      value={value}
      onChange={(event: DatePickerChangeEvent) => {
        if (onChange)
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value,
          });
      }}
      format={format}
    />
  );
};
