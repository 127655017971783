import { DTO_WorkflowHeader } from "@app/products/property/model";

export enum EKeysOfSteps {
  Details = "Details",
}

export const keysOfSendSteps = [EKeysOfSteps.Details];

export interface DTO_Workflow_Supplementary_Modify {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_Supplementary_Modify;
}

export interface RequestSupplementaryModifyObj {
  SupplementaryId: number;
}

export interface DTO_WorkflowDetail_Supplementary_Modify {
  SupplementaryId?: number;
  SupplementaryType?: number;
  InstalmentPlanId?: number;
  SupplementaryName: string;
  SupplementaryReference: string;
  SupplementaryDate: Date;
  Notes: string;
  BalanceTransferMethodId: number;
  BalanceTransferValuationType?: number;
  UseAssessmentsExistingInstalmentPlan: boolean;
  TaskType: string;
  EmailRecipients: string;
  RunImmediately: boolean;
  RunAt?: Date;
  TaskRunId?: number;
}

export enum AssessmentTypeName {
  ByValuation = "By Valuation",
}
