import { loadSearchAssessment } from "@app/products/property/meters/special-reading-list/components/action-bar/dialogs/create-journal/components/search-assessment/api";
import { CCComboBox } from "@components/cc-combo-box/_index";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import React from "react";
interface ISearchName {
  fieldName: string;
  label: string;
  onChange: (values: any) => void;
  formRenderProps: FormRenderProps;
}
export const SearchAssessmentField = ({
  fieldName,
  onChange,
  label,
  formRenderProps,
}: ISearchName) => {
  const handleSearchName = (event: ComboBoxFilterChangeEvent) => {
    let numLetters = 1;

    if (event.filter.value.length >= numLetters) {
      formRenderProps.onChange("_option.SearchAssessment.Loading", {
        value: true,
      });
      loadSearchAssessment(event.filter).then((data: any) => {
        const notJournalNumberItems = data.filter(
          (item: any) => !item.JournalNumber
        );
        formRenderProps.onChange("_option.SearchAssessment.Data", {
          value: notJournalNumberItems,
        });
        formRenderProps.onChange("_option.SearchAssessment.Loading", {
          value: false,
        });
      });
    } else {
      formRenderProps.onChange("_option.SearchAssessment.Data", {
        value: [],
      });
    }
  };
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>{`${itemProps.dataItem[fieldName.replace(/\s/g, "")]} - ${
        itemProps.dataItem.PropertyAddress
      }`}</span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <section className="cc-field-group">
      <div className="cc-field">
        <label className="cc-label">{label}</label>
        <Field
          name={"SearchAssessment"}
          filterable
          onFilterChange={handleSearchName}
          data={formRenderProps.valueGetter("_option.SearchAssessment.Data")}
          loading={formRenderProps.valueGetter(
            "_option.SearchAssessment.Loading"
          )}
          textField={fieldName.replace(/\s/g, "")}
          component={CCComboBox}
          itemRender={itemRender}
          onChange={(event: ComboBoxChangeEvent) => {
            onChange(event.target.value);
            formRenderProps.onChange("_option.SearchAssessment.Data", {
              value: [],
            });
          }}
        />
      </div>
    </section>
  );
};
