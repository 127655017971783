import { BuildingParentSection } from "@app/products/building/components/forms/building-parent-section/_index";
import { officerFields } from "@app/products/building/registers/essential-safety/[id]/components/child-screens/general/components/form-element/config";
import { bcaDropdownDataMock } from "@app/products/building/registers/essential-safety/[id]/components/child-screens/general/components/form-element/mock";
import { IBuildingEssentialSafetyFormData } from "@app/products/building/registers/essential-safety/[id]/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validateRequired,
} from "@common/utils/field-validators";
import { formatOSFees } from "@common/utils/formatting";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import InputPicker from "@components/input-picker/InputPicker";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

interface IBuildingRegisterEssentialSafetyFormElementProps {
  formRenderProps: FormRenderProps;
}

export const BuildingRegisterEssentialSafetyFormElement = observer(
  ({ formRenderProps }: IBuildingRegisterEssentialSafetyFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };

    const nameOf = nameOfFactory<IBuildingEssentialSafetyFormData>();
    const getValue = formRenderProps.valueGetter;

    return (
      <FormElement>
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />

        <BuildingParentSection formRenderProps={formRenderProps} />
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Building description</label>
              <Field
                rows={8}
                name={nameOf("Description")}
                value={getValue(nameOf("Description"))}
                placeholder="Building description"
                component={CCTextArea}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Officer</label>
              <Field
                component={InputPicker}
                {...officerFields(formRenderProps)}
                name={nameOf("Officer")}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">BCA</label>
              <Field
                dataItemKey="Key"
                textField="Value"
                name={nameOf("BCA")}
                data={bcaDropdownDataMock}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">
                Inspection frequency
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.Contact}
                />
              </label>
              <Field
                name={nameOf("InspectionFrequency")}
                component={NumericTextBox}
                defaultValue={0}
                format={"#"}
                placeholder="Inspection frequency"
                validator={validateRequired}
              />
            </div>
            <CCValueField
              name={nameOf("LastInspection")}
              label="Last inspection"
              value={
                getValue(nameOf("LastInspection"))
                  ? getValue(nameOf("LastInspection"))
                  : "Not Yet Inspected"
              }
            />
            <CCValueField
              name={nameOf("NextInspection")}
              label="Next inspection"
              value={
                getValue(nameOf("NextInspection"))
                  ? getValue(nameOf("NextInspection"))
                  : "Not Yet Inspected"
              }
            />
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Items
                <CCTooltip
                  type="validator"
                  position="right"
                  content={formRenderProps.errors.Contact}
                />
              </label>
              <Field
                rows={8}
                name={nameOf("Items")}
                placeholder="Items"
                value={getValue(nameOf("Items"))}
                component={CCTextArea}
                validator={requiredValidator}
              />
            </div>
          </div>
        </div>
        <hr className="cc-divider" />
        <div className="cc-field-group">
          <div className="cc-form-cols-3">
            <CCValueField
              name={nameOf("OSFees")}
              label="OS fees"
              value={getValue(nameOf("OSFees"))}
              format={CURRENCY_FORMAT.CURRENCY1}
              style={formatOSFees(getValue(nameOf("OSFees")))}
            />
            <div className="cc-field">
              <label className="cc-label">Debtor number</label>
              <Field
                name={nameOf("DebtorNumber")}
                placeholder="Debtor number"
                component={CCInput}
              />
            </div>
          </div>
        </div>
      </FormElement>
    );
  }
);
