import {
    mockAddMemo,
    mockDropdownMemoCategory,
} from "@app/products/property/fire-prevention/hazld-case/[id]/components/dialogs/add-memo/mock";
import { IFirePreventionAddMemo } from "@app/products/property/fire-prevention/hazld-case/[id]/components/dialogs/add-memo/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

export const nameOf = nameOfFactory<IFirePreventionAddMemo>();
interface IAddMemoDialogProps {
  onClose: () => void;
  onSubmit: (values: any) => void;
}
export const AddMemoDialog = ({ onClose, onSubmit }: IAddMemoDialogProps) => {
  return (
    <Form
      initialValues={mockAddMemo}
      key={JSON.stringify(mockAddMemo)}
      onSubmitClick={onSubmit}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, valid, valueGetter, onChange } = formRenderProps;
        return (
          <CCDialog
            titleHeader={"New Memo"}
            onClose={onClose}
            maxWidth={"30%"}
            height={"auto"}
            bodyElement={
              <FormElement className="cc-form">
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">Category</label>
                      <Field
                        name={nameOf("Category")}
                        component={CCSearchComboBox}
                        textField="Value"
                        dataItemKey="Key"
                        data={mockDropdownMemoCategory}
                        value={getDropdownValue(
                          valueGetter("Category"),
                          mockDropdownMemoCategory,
                          "Key"
                        )}
                        onChange={(event: ComboBoxChangeEvent) => {
                          onChange("Category", {
                            value: event.target.value?.Key ?? null,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">
                        Memo
                        <CCTooltip
                          type="validator"
                          position="right"
                          content="You must have at least one record to go to the next step"
                        />
                      </label>
                      <Field
                        name={nameOf("Memo")}
                        component={CCTextArea}
                        rows={4}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                </section>
              </FormElement>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={!valid}
                  onClick={onSubmit}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
