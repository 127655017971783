import { getViewConfigurations } from "@app/products/property/api";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
import {
  DTO_COOLeaseTransferBuyer,
  DTO_COOLeaseTransferException,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/model";
import {
  getChangesOfOwnershipLeaseTransferBuyers,
  getChangesOfOwnershipLeaseTransferExceptions,
} from "@app/products/property/changes-of-ownership/lease-transfers/components/detail/api";
configure({ enforceActions: "always" });

class ChangesOfOwnershipLeaseTransfersStore {
  private _changesOfOwnershipLeaseTransferBuyers: DTO_COOLeaseTransferBuyer[] =
    [];
  private _changesOfOwnershipLeaseTransferExceptions: DTO_COOLeaseTransferException[] =
    [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _colBuyersConfiguration: ICCViewColumn[] = [];
  private _colExceptionsConfiguration: ICCViewColumn[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get changesOfOwnershipLeaseTransferBuyers() {
    return this._changesOfOwnershipLeaseTransferBuyers;
  }
  setChangesOfOwnershipLeaseTransferBuyers = (
    changesOfOwnershipLeaseTransferBuyers: DTO_COOLeaseTransferBuyer[]
  ) => {
    runInAction(() => {
      this._changesOfOwnershipLeaseTransferBuyers =
        changesOfOwnershipLeaseTransferBuyers;
    });
  };

  get changesOfOwnershipLeaseTransferExceptions() {
    return this._changesOfOwnershipLeaseTransferExceptions;
  }
  setChangesOfOwnershipLeaseTransferExceptions = (
    changesOfOwnershipLeaseTransferExceptions: DTO_COOLeaseTransferException[]
  ) => {
    runInAction(() => {
      this._changesOfOwnershipLeaseTransferExceptions =
        changesOfOwnershipLeaseTransferExceptions;
    });
  };

  get colBuyersConfiguration() {
    return this._colBuyersConfiguration;
  }
  setColBuyersConfiguration = (colBuyersConfiguration: ICCViewColumn[]) => {
    runInAction(() => {
      this._colBuyersConfiguration = colBuyersConfiguration;
    });
  };

  get colExceptionsConfiguration() {
    return this._colExceptionsConfiguration;
  }
  setColExceptionsConfiguration = (
    colExceptionsConfiguration: ICCViewColumn[]
  ) => {
    runInAction(() => {
      this._colExceptionsConfiguration = colExceptionsConfiguration;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  loadChangesOfOwnershipLeaseTransferBuyers = async (
    leaseTransferId: number
  ) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const [colBuyersConfiguration, buyersData] = await Promise.all([
      getViewConfigurations(
        ViewConfiguration.ChangeOfOwnership_LeaseTransfers_Buyers
      ),
      getChangesOfOwnershipLeaseTransferBuyers(leaseTransferId),
    ]);
    if (
      isSuccessResponse(colBuyersConfiguration) &&
      isSuccessResponse(buyersData) &&
      colBuyersConfiguration?.data
    ) {
      this.setColBuyersConfiguration(
        colBuyersConfiguration.data.ColumnDefinitions?.Columns
      );
      this.setChangesOfOwnershipLeaseTransferBuyers(
        buyersData?.data?.Buyers ?? []
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  loadChangesOfOwnershipLeaseTransferExceptions = async (
    leaseTransferId: number,
    assessmentId: number
  ) => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const [colExceptionsConfiguration, exceptionsData] = await Promise.all([
      getViewConfigurations(
        ViewConfiguration.ChangeOfOwnership_LeaseTransfers_Exceptions
      ),
      getChangesOfOwnershipLeaseTransferExceptions(
        leaseTransferId,
        assessmentId
      ),
    ]);
    if (
      isSuccessResponse(colExceptionsConfiguration) &&
      isSuccessResponse(exceptionsData) &&
      colExceptionsConfiguration?.data
    ) {
      this.setColExceptionsConfiguration(
        colExceptionsConfiguration.data.ColumnDefinitions?.Columns
      );
      this.setChangesOfOwnershipLeaseTransferExceptions(
        exceptionsData?.data?.Exceptions ?? []
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._changesOfOwnershipLeaseTransferBuyers = [];
      this._changesOfOwnershipLeaseTransferExceptions = [];
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._colBuyersConfiguration = [];
      this._colExceptionsConfiguration = [];
    });
  };
}

const changesOfOwnershipLeaseTransfersStoreContext = createContext(
  new ChangesOfOwnershipLeaseTransfersStore()
);
export const useChangesOfOwnershipLeaseTransfersStore = () => {
  return useContext(changesOfOwnershipLeaseTransfersStoreContext);
};
