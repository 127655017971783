import { ICCRoute } from "@common/constants/ICCRoute";
export const permitAppealsRoute: ICCRoute = {
  path: "permit-appeals",
  name: "Permit Appeals",
  children: [
    {
      path: "all",
      name: "All",
      component: require("./all/_index").default,
    },
    {
      path: "my-appeals",
      name: "My Appeals",
      component: require("./my-appeals/_index").default,
    },
    {
      path: "current-register",
      name: "Current Register",
      component: require("./current-register/_index").default,
    },
    {
      path: "current-by-status",
      name: "Current By Status",
      component: require("./current-by-status/_index").default,
    },
    {
      path: "current-by-month",
      name: "Current By Month",
      component: require("./current-by-month/_index").default,
    },
    {
      path: "hearing-calendar",
      name: "Hearing Calendar",
      component: require("./hearing-calendar/_index").default,
    },
  ],
};
