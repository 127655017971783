import { IFeeTypes } from "@app/products/town-planning/ppr/system-admin/application-categories/[id]/components/dialogs/add-fee-types/model";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IFeeTypes>();

export const colFeeTypesPicker: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  { title: "Description", field: nameOf("Description") },
  {
    title: "Amount",
    field: nameOf("DefaultAmount"),
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  { title: "Cost Code", field: nameOf("AccountCode") },
  { title: "Category Code", field: nameOf("CategoryCode") },
  { title: "GST", field: nameOf("Flag_GSTApplies") },
];
