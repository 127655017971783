export enum EKeysOfStepsParcel {
  ParcelId = "ParcelId",
  Details = "Details",
  Associations = "Associations",
  ParcelDetails = "ParcelDetails",
  Address = "Address",
  SelectZones = "SelectZones",
  SelectLandUses = "SelectLandUses",
  SelectConstraints = "SelectConstraints",
  Comments = "Comments",
  Documents = "Documents",
  Collection = "SelectedCollection",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfSendStepsParcel = [
  EKeysOfStepsParcel.ParcelId,
  EKeysOfStepsParcel.Details,
  EKeysOfStepsParcel.Associations,
  EKeysOfStepsParcel.ParcelDetails,
  EKeysOfStepsParcel.Address,
  EKeysOfStepsParcel.SelectZones,
  EKeysOfStepsParcel.SelectLandUses,
  EKeysOfStepsParcel.SelectConstraints,
  EKeysOfStepsParcel.Collection,
  EKeysOfStepsParcel.SecondaryWorkflow,
];
