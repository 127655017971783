import { history } from "@/AppRoutes";
import { getKeywords } from "@common/apis/coreKeyword";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { ActionType } from "@common/models/actionType";
import { DBRowState } from "@common/models/baseClassStandard";
import { CoreKeyword } from "@common/models/coreKeyword";
import {
  getActionTypeById,
  postActionType,
} from "@common/pages/settings/lookups/action-types/_id/api";
import { SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE } from "@common/pages/settings/lookups/action-types/_id/constant";
import {
  ILookupActionTypeNotification,
  LookupActionTypeSubmitActions,
} from "@common/pages/settings/lookups/action-types/_id/model";
import { getActiveProducts } from "@common/stores/products/api";
import { eProductType } from "@common/stores/products/enum";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ActionTypesStore {
  private _actionType?: ActionType = undefined;
  private _defaultLocation?: CoreKeyword[] = [];
  private _isShowWorkerLink: boolean = false;
  private _isInactive: boolean = false;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _actionTypeNotification?: ILookupActionTypeNotification = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get actionTypeNotification() {
    return this._actionTypeNotification;
  }
  setActionTypeNotification = (
    actionTypeNotification: ILookupActionTypeNotification
  ) => {
    runInAction(() => {
      this._actionTypeNotification = actionTypeNotification;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get actionType() {
    return toJS(this._actionType);
  }
  setActionType = (actionType?: ActionType) => {
    runInAction(() => {
      this._actionType = actionType;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._actionType = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._isInactive = false;
      this._actionTypeNotification = undefined;
      this._onSubmit = undefined;
    });
  };

  get actionTypeId() {
    return toJS(this.actionType?.ActionType_ID);
  }

  get isShowWorkerLink() {
    return toJS(this._isShowWorkerLink);
  }
  setIsShowWorkerLink = (isShowWorkerLink: boolean) => {
    runInAction(() => {
      this._isShowWorkerLink = isShowWorkerLink;
    });
  };

  get defaultLocation() {
    return toJS(this._defaultLocation);
  }
  setDefaultLocation = (defaultLocation?: CoreKeyword[]) => {
    runInAction(() => {
      this._defaultLocation = defaultLocation;
    });
  };

  reloadActionTypes = async (): Promise<boolean> => {
    if (this.actionTypeId) {
      return await this.loadActionType(this.actionTypeId);
    }
    return false;
  };

  loadActionType = async (
    actionTypeID: number,
    isNew?: boolean
  ): Promise<boolean> => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const resKeywords = await getKeywords(
      KEYWORD_TYPE.Core_ActionMeetingLocation,
      PRODUCT_TYPE_NUMBER.Core
    );
    if (isSuccessResponse(resKeywords)) {
      if (resKeywords?.data) this.setDefaultLocation(resKeywords?.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: resKeywords?.error ?? "Default location load failed",
        type: "warning",
      });
    }

    const resActiveProducts = await getActiveProducts();
    if (isSuccessResponse(resActiveProducts)) {
      if (resActiveProducts?.data) {
        if (
          resActiveProducts?.data.find((lst) => {
            return lst.ProductType_ENUM === eProductType.DWCMS;
          })
        )
          this.setIsShowWorkerLink(true);
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: resActiveProducts?.error ?? "Show worker link load failed",
          type: "warning",
        });
      }
    }

    if (isNew) {
      this.setActionType();
    } else {
      let newActionType = undefined;
      const response = await getActionTypeById(actionTypeID);
      if (isSuccessResponse(response)) {
        newActionType = response.data as ActionType;

        if (response.data?.Sys_DBRowState === DBRowState.Inactive) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      } else {
        errorResponse = {
          status: APIResponseStatus.INTERNAL_SERVER_ERROR,
          error: "Server error",
        };
      }
      this.setActionType(newActionType);
      if (this.actionTypeNotification?.notification) {
        this.actionTypeNotification?.notification.forEach(
          (notification: IAppNotificationItemAddProps) => {
            appNotificationStore.pushNotification(notification);
          }
        );
        this.setActionTypeNotification({
          ...this._actionTypeNotification,
          notification: [],
        } as ILookupActionTypeNotification);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    return errorResponse === undefined;
  };

  saveActionType = async (
    actionTypeInfo: ActionType,
    action: LookupActionTypeSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await postActionType(actionTypeInfo);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.actionTypeId) await this.loadActionType(this.actionTypeId);
      this.runActions(action, response);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save action type failed",
        type: "error",
        description: response.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (
    action: LookupActionTypeSubmitActions,
    response?: APIResponse
  ) => {
    switch (action) {
      case LookupActionTypeSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Action type saved successfully",
          type: "success",
        });
        break;
      case LookupActionTypeSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(
          `${SETTINGS_LOOKUPS_ACTION_TYPES_ROUTE}/${response?.data?.ID}`,
          {
            notification: [
              { title: "Action type saved successfully", type: "success" },
            ],
          }
        );
        break;
      default:
        break;
    }
  };
}

export const actionTypesStore = new ActionTypesStore();
const ActionTypesStoreContext = createContext(actionTypesStore);
export const useActionTypesStore = () => {
  return useContext(ActionTypesStoreContext);
};
