import { ICCRoute } from "@common/constants/ICCRoute";

export const contactChangeQueueRoute: ICCRoute = {
  path: "change-queue",
  name: "Change Queue",
  children: [
    {
      path: "list",
      name: "List",
      component: require("./list/_index").default,
    },
  ],
};
