import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { VO_Charge_Run_Rebate_LevySummary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/rebate-summary/component/model";

const nameOfRebateLevySummary =
  nameOfFactory<VO_Charge_Run_Rebate_LevySummary>();
export const colRebateSummaryDetail: IColumnFields[] = [
  {
    field: nameOfRebateLevySummary("LevyName"),
    title: "Levy",
  },
  {
    field: nameOfRebateLevySummary("LC_Rate"),
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfRebateLevySummary("Levy_Amount"),
    title: "Levy Amount",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfRebateLevySummary("No_of_Levies"),
    title: "Number of Levies",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfRebateLevySummary("Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfRebateLevySummary("Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
    width: "150",
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Valuation", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("Base_Amount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Base_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("RateByValue_Amount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("RateByValue_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("Increments_to_Minimum"),
    title: "Increments to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Increments_to_Minimum", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("No_of_Increments_to_Minimum"),
    title: "Number of Increments to Minimum",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOfRebateLevySummary("Gross_Levy_Amount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Gross_Levy_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("Rebates"),
    title: "Rebates for Type",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Rebates", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("Nett_Levy_Amount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Nett_Levy_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("Attributable_Value"),
    title: "Attributable Value",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Attributable_Value", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("Postponed_Amount"),
    title: "Postponed Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("Postponed_Amount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfRebateLevySummary("No_of_Postponed"),
    title: "Number of Postponed Amount",
    format: NUMBER_FORMAT.NUMBER2,
  },
];
