import { eChangeOfOwnershipType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import {
  DTO_Assessment,
  DTO_Title,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfAssessment = nameOfFactory<DTO_Assessment>();
const nameOfTitle = nameOfFactory<DTO_Title>();
export const colAssessments: IColumnFields[] = [
  {
    field: nameOfAssessment("Assessment_Number"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: nameOfAssessment("Property_Address"), title: "Property Address" },
  {
    field: nameOfAssessment("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colTitles: IColumnFields[] = [
  {
    field: nameOfTitle("Legal_Description"),
    title: "Legal Description",
  },
  { field: nameOfTitle("Owner"), title: "Owner" },
  {
    field: nameOfTitle("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfTitle("Title_Id"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
//Types required at least 1 assessment/holding
export const requiredAssessmentTypes = [
  eChangeOfOwnershipType.Change_of_Name_Address,
  eChangeOfOwnershipType.Change_of_Associated_Name_Address,
  eChangeOfOwnershipType.Full_Transfer_of_Occupier,
  eChangeOfOwnershipType.Partial_Transfer_of_Occupier,
];
//Types required at least 1 title/lot
export const requiredTitleTypes = [
  eChangeOfOwnershipType.Full_Transfer_of_Ownership,
  eChangeOfOwnershipType.Partial_Transfer_of_Ownership,
];
