import { PROPERTY_SUPPLEMENTARY_RATES_ROUTE } from "@app/products/property/supplementary-rates/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

export const NewSupplementaryButton = observer(() => {
  const history = useHistory();
  return (
    <CCNavButton
      title="New supplementary"
      onClick={() => history.push(`${PROPERTY_SUPPLEMENTARY_RATES_ROUTE}/new`)}
    />
  );
});
