import {
  DTO_MasterProperty_LOVs,
  DTO_Workflow_MasterProperty_Edit,
  RequestMasterPropertyObj,
} from "@app/products/property/assessments/master-properties/components/form-steps/modify-master-property/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getNewWorkflowModifyMasterProperty = async (
  requestObjSpatial: RequestMasterPropertyObj | object = {}
): Promise<APIResponse<DTO_Workflow_MasterProperty_Edit>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifymasterproperty/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowModifyMasterProperty = async (
  workflowId: number
): Promise<APIResponse<DTO_Workflow_MasterProperty_Edit>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/modifymasterproperty/${workflowId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataMasterProperty =
  | [
      APIResponse<DTO_MasterProperty_LOVs>,
      APIResponse<DTO_Workflow_MasterProperty_Edit>
    ]
  | APIResponse<DTO_MasterProperty_LOVs | DTO_Workflow_MasterProperty_Edit>
  | undefined;
export const getInitialDataMasterProperty = async (
  workflowType: WorkflowTypes,
  masterPropertyId: number,
  workflowId: number | undefined,
  isNewAssessment: boolean = false
): Promise<IGetInitialDataMasterProperty> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_MasterProperty_LOVs>(
        `/api/property/internal/masterproperty/lovs?workflowType=${workflowType}`
      ),
      !isNewAssessment && !isNil(workflowId)
        ? getWorkflowModifyMasterProperty(workflowId)
        : getNewWorkflowModifyMasterProperty({
            MasterPropertyId: masterPropertyId.toString(),
          }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessModifyMasterProperty = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_MasterProperty_Edit
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_MasterProperty_Edit>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/modifymasterproperty/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
