import { IMailMergeDocumentsTemplate } from "@app/core/new-mail-merge/form-steps/template/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { CoreKeyword } from "@common/models/coreKeyword";

export enum MAILMERGESAVEFORMAT {
  Default = 0,
  Doc = 1,
  Docx = 2,
  Pdf = 4,
}

export class MailMerge_SelectMergeDocument {
  Filters: MailMerge_SelectMergeDocumentFilters = {};
  DocumentUsage_Filters: MAILMERGEDOCUMENTUSAGE[] = [];
  Dataset_Filters: MAILMERGEDATASET[] = [];
  DocumentIDs: number[] = [];
  SelectedDocumentID: number | null = null;
  SelectedDocumentIDs: number[] = [];
  JoinDocumentParts: boolean = false;
  SelectedRows: number[] = [];
  MergeIdentifiers: MailMergeIdentifier[] = [];
  AppendToRecordSource: boolean = false;
  AllowOptionEditing: boolean = false;
  AllowAppendOptionEditing: boolean = false;
  ShowAppendToRecordSourceOption: boolean = false;
  Collate: boolean = false;
  AllowCollateOptionEdititng: boolean = false;
  ShowCollateOption: boolean = false;
  Sections: number[] = [];
  BatchPrint: boolean = false;
  AllowBatchPrintOptionEditing: boolean = false;
  ShowBatchPrintOption: boolean = false;
  ShowPublishDocumentOption: boolean = false;
  AllowPublishDocumentOptionEditing: boolean = false;
  SaveFormat: MAILMERGESAVEFORMAT = MAILMERGESAVEFORMAT.Default;
  AllowSaveFormatOptionEditing: boolean = false;
  ShowSaveFormatOption: boolean = false;
  ShowUsePreferredMethod: boolean = false;
  AllowUsePreferredMethod: boolean = false;
  UsePreferredMethod: boolean = false;
  PublishDocument: boolean = false;
  SchemaOnly: boolean = false;
  UseRegions: boolean = false;
  RecordType: string = "";
  RecordTypeName: string = "";
  Category_KWD: number | null = null;
  OriginInformation: string = "";
  Tag: string = "";
  PreferredAuthor_ID: number | null = null;
  PreferredAuthor_DisplayName: string = "";
  PreferredAuthor_UserName: string = "";
  Manager_DisplayName: string = "";
  Manager_UserName: string = "";
  Records_Notes: string = "";
  Records_AllowUserDefinedParentRecordId: boolean = false;
  Records_UserDefinedParentRecordId: string = "";
  CustomFileTitle: string = "";
  CustomFileName: string = "";
  AccessToken: string = "";
  HasFollowUpAction: boolean = false;
  FollowUpActionObj: any = {};
  MailMergeWatermark: MailMergeWatermark | null = null;
  ShowExternalDocumentOption: boolean | null = null;
  ExternalDocument: boolean | null = null;
  AllowExternalDocumentOptionEditing: boolean | null = null;
  SaveLocalCopy: boolean = false;
  DocumentTemplate: DocumentTemplate | null = null;
  ComponentDataTable: MailMerge_ComponentDataTable | null = null;
  ComponentDataSet: MailMerge_ComponentDataSet[] = [];
  ProductType: PRODUCT_TYPE_NUMBER | null = null;
  RecordType_ENUM: RECORDTYPE | null = null;
  SelectedRecordIDs: number[] = [];
  CustomFields: string = "";
  CategoryName: string = "";

  constructor() {
    this.SelectedRows = [];
    this.DocumentIDs = [];
    this.SelectedDocumentIDs = [];
    this.MergeIdentifiers = [];
    this.DocumentUsage_Filters = [];
    this.Dataset_Filters = [];
    this.AppendToRecordSource = false;
    this.AllowOptionEditing = true;
    this.AllowAppendOptionEditing = true;
    this.ShowAppendToRecordSourceOption = true;
    this.Collate = false;
    this.AllowCollateOptionEdititng = true;
    this.ShowCollateOption = true;
    this.BatchPrint = false;
    this.AllowBatchPrintOptionEditing = true;
    this.ShowBatchPrintOption = true;
    this.SaveFormat = MAILMERGESAVEFORMAT.Default;
    this.AllowSaveFormatOptionEditing = true;
    this.ShowSaveFormatOption = true;
    this.ShowUsePreferredMethod = false;
    this.AllowUsePreferredMethod = false;
    this.Records_AllowUserDefinedParentRecordId = false;
    this.AccessToken = "";
    this.ShowExternalDocumentOption = false;
    this.AllowExternalDocumentOptionEditing = false;
  }
}

export interface MailMerge_SelectMergeDocumentFilters {}

export interface MailMergeIdentifier {
  ID: number;
  Sys_EnterpriseKey: string;
  SourceIdentifier: RecordSourceIdentifier;
  BubbleUps: BubbleUpIdentifier[];
  CollatedBubbleUps: BubbleUpIdentifier[];
  CustomFieldList: KeyValuePair<string, string>[];
  AvailableFieldsList: KeyValuePair<string, string>[];
  AssessmentNumber: string;
  Records_UserDefinedParentRecordId: string;
  Records_AuthorName: string;
  FormattedAuthorName: string;
  UseParentFileNumber: boolean;
}

export interface RecordSourceIdentifier {
  RecordSource_ID: number;
  RecordSourceType_ENUM: RECORDTYPE | null;
  RecordSourceType_Name: string;
  RecordSource_ColumnName: string;
  RecordSource_StringID: string;
  RecordSource_FileNumber: string;
}

export interface BubbleUpIdentifier {
  PKID: number;
  SourceIdentifier: RecordSourceIdentifier;
  BubbleUpType_ENUM: BubbleUpType;
  BubbleUpType_Name: string;
  Sys_CreatedDate: Date;
  Sys_DeactivationDate: Date | null;
}

export interface KeyValuePair<TKey, TValue> {
  Key: TKey;
  Value: TValue;
}

export interface DocumentTemplate {
  DocumentTemplate_ID: number;
  Name: string;
  FileEntry: FileStreamEntry;
  SaveFormat_ENUM: MAILMERGESAVEFORMAT;
  UseRegions: boolean;
  DocumentTemplate_Images: DocumentTemplate_Image[];
}

export interface DocumentTemplate_Image {
  DocumentTemplate_Image_ID: number;
  DocumentTemplate_ID: number;
  FieldName: string;
  Width: number | null;
  Height: number | null;
  FileAttachment: string;
}

export interface MailMerge_ComponentDataTable {
  Component_Details: MailMerge_ComponentDetail[];
  // Component_DataTable: DataTable;
}

export interface MailMerge_ComponentDataSet {
  Component_Detail: MailMerge_ComponentDetail;
  // Component_DataSet: DataSet;
}

export interface MailMerge_ComponentDetail {
  Component_ID: number;
  ParentContainerNumber: string;
  RecordTitle: string;
}

export interface FileStreamEntry {
  FileName: string;
  ContentType: string;
  FileSize: number;
  FileAttachment: string;
  Base64FileAttachment: string;
  IsLightweight: boolean;
}

export enum BubbleUpType {
  SystemInitialise = 0,
  Parent = 1,
  Ancestor = 2,
  Association = 3,
  Shared = 4,
}

export enum MAILMERGEDOCUMENTUSAGE {
  SystemInitialise = 0,
  CustomDocumentTemplates = 51,
  CORE_LiveData = 52,
  HM_RegistrationCertificate = 101,
  HM_RenewalNotice = 102,
  HM_RegistrationCertificate_NoNotifications = 103,
  HM_RegistrationCertificate_OnlyNotifications = 104,
  HM_OutstandingFeesLetter = 180,
  HM_OutstandingRenewalFeesLetter = 181,
  HM_Invoice = 191,
  HM_Receipt = 192,
  HM_Premises = 101,
  CRS_CBCRegistration = 301,
  CRS_3yoRegistration = 302,
  CRS_4yoRegistration = 303,
  CRS_HBCRegistration = 304,
  CRS_Registration = 305,
  CRS_AcknowledgementService = 306,
  CRS_SwitchFromCentreToGroup3YO = 307,
  CRS_SwitchFromCentreToGroup4YO = 308,
  CRS_POA_3YO = 309,
  CRS_POA_4YO = 310,
  DISRES_PermitLabel_SpecialPurpose_Paper = 401,
  DISRES_PermitLabel_SpecificVehicle_Paper = 402,
  DISRES_PermitLabel_Staff_Paper = 403,
  DISRES_PermitLabel_Variable_Paper = 404,
  DISRES_PermitLabel_Tradesman_Paper = 405,
  DISRES_PermitLabel_TemporaryStaff_Paper = 406,
  DISRES_PermitLabel_Visitor_Paper = 407,
  DISRES_PermitLabel_ContractManagedGeneral_Paper = 408,
  DISRES_PermitLabel_TemporaryResident_Paper = 409,
  DISRES_PermitLabel_Disabled_Paper = 410,
  DISRES_PermitLabel_DisabledOrg_Paper = 411,
  DISRES_PermitLabel_SpecialPurpose_Sticky = 412,
  DISRES_PermitLabel_SpecificVehicle_Sticky = 413,
  DISRES_PermitLabel_Staff_Sticky = 414,
  DISRES_PermitLabel_Variable_Sticky = 415,
  DISRES_PermitLabel_Tradesman_Sticky = 416,
  DISRES_PermitLabel_TemporaryStaff_Sticky = 417,
  DISRES_PermitLabel_Visitor_Sticky = 418,
  DISRES_PermitLabel_ContractManaged_SpecificVehicle_Sticky = 419,
  DISRES_PermitLabel_TemporaryResident_Sticky = 420,
  DISRES_PermitLabel_Disabled_Sticky = 421,
  DISRES_PermitLabel_DisabledOrg_Sticky = 422,
  DISRES_PermitLabel_DisabledTemporary_Paper = 423,
  DISRES_PermitLabel_Municipal_Paper = 424,
  DISRES_AddressLabel = 425,
  DISRES_PermitLabel_DisabledTemporary_Sticky = 426,
  DISRES_PermitLabel_Municipal_Sticky = 427,
  DISRES_PermitLabel_ContractManaged_SpecificVehicle_Paper = 428,
  DISRES_PermitLabel_ContractManagedGeneral_Sticky = 429,
  CM_PES = 501,
  CM_LicenceCertificate = 502,
  CM_TOL = 503,
  CM_LicenceRenewalQuote = 504,
  CM_ExhumationOrder = 505,
  CM_RenewalNotice = 506,
  CM_ExpiryNotice1 = 507,
  CM_ExpiryNotice2 = 508,
  CM_SurrenderLicenceDocument = 509,
  CM_SurrenderLicenceCompletionNotice = 510,
  CM_MemorialWorksOrder = 511,
  CM_MemorialWorksOrderPermit = 512,
  CM_CrematedRemainsRemovalOrderDocument = 513,
  CM_CremationOrderPrintLabel = 514,
  CM_BookingReceipt_BurialInterment = 515,
  CM_BookingReceipt_ChapelService = 516,
  CM_BookingReceipt_CremationOrder = 517,
  CM_BookingReceipt_CremationMemorial = 518,
  CM_BookingReceipt_Exhumation = 519,
  CM_BookingConfirmation_BurialInterment = 520,
  CM_BookingConfirmation_ChapelService = 521,
  CM_BookingConfirmation_CremationOrder = 522,
  CM_BookingConfirmation_CremationMemorial = 523,
  CM_BookingConfirmation_Exhumation = 524,
  CM_ConsultationQuote = 525,
  CM_ConsultationConfirmation = 526,
  CM_POSTransactionReceipt = 527,
  CM_MemorialServiceReceipt_ChapelService = 528,
  CM_MemorialServiceConfirmation_ChapelService = 529,
  CM_CRIO_AttendanceConfirmation = 530,
  CM_CRSO_PrintLabels = 531,
  CM_BookingConfirmation_TrustManagement = 532,
  CM_BookingInvoice_FuneralDirector = 533,
  CM_Booking_BurialCertificate = 534,
  CM_Booking_CremationCertificate = 535,
  CM_CrematedRemains_PrintLabels = 536,
  SRS_Certificate = 601,
  CUSTOMERSERVICE_Event = 701,
  CUSTOMERSERVICE_Event_FinalNotice = 702,
  CUSTOMERSERVICE_ColdChainBreach_Advice = 703,
  CUSTOMERSERVICE_Event_Acknowledgement = 704,
  TOWNPLANNING_Application = 801,
  TOWNPLANNING_Application_Advertising = 802,
  TOWNPLANNING_Application_Appeals = 803,
  TOWNPLANNING_Application_Fees = 804,
  TOWNPLANNING_Application_FurtherInformationRequests = 805,
  TOWNPLANNING_Application_Inspections = 806,
  TOWNPLANNING_Application_Notices = 807,
  TOWNPLANNING_Application_WithRegions = 808,
  TOWNPLANNING_Subdivision = 809,
  BUILDING_CertificateOfCompletion = 851,
  BUILDING_Application_FurtherInformationRequests = 852,
  LAMP_ConditionPreview = 901,
  LAMP_Application = 902,
  LAMP_Authorisation = 903,
  LAMP_Application_Advertising = 904,
  LAMP_Application_FurtherInformationRequests = 905,
  LAMP_Application_Region = 906,
  LAMP_Authorisation_Region = 907,
  LAMP_Invoice_WithDetails = 910,
  LAMP_Invoice_NoDetails = 911,
  LAMP_AuthorisationCertificate = 912,
  LAMP_AuthorisationDraftCertificate = 913,
  Register_CouncilConsent = 1101,
  Register_Demolition = 1102,
  Register_ESBuilding = 1103,
  Register_NonCompliance = 1104,
  Register_PlanningCertficate = 1105,
  Register_PlanningEnquiry = 1106,
  Register_PlansToComply = 1107,
  Register_POPE = 1108,
  Register_SecondaryConsent = 1109,
  Register_EOT = 1110,
  Register_FurtherInformationRequests = 1111,
  Register_BuildingCertificate = 1112,
  Register_Contacts = 1113,
  Register_BuildingPoolSpa = 1114,
  MCH_Appointment = 1201,
  MCH_Registration = 1202,
  AMS_Appointment = 1251,
  EMS_EventBooking = 1301,
  EMS_EventBooking_WithRegion = 1302,
  TrainingManager_CourseOutline = 1351,
  TrainingManager_CourseSchedule = 1352,
  TrainingManager_TrainingRequest = 1353,
  TrainingManager_CourseOutline_Doc2 = 1354,
  TrainingManager_CourseSchedule_Doc2 = 1355,
  TrainingManager_TrainingRequest_Doc2 = 1356,
  TrainingManager_CourseOutline_Doc3 = 1357,
  TrainingManager_CourseSchedule_Doc3 = 1358,
  TrainingManager_TrainingRequest_Doc3 = 1359,
  DWCMS_RegistrationCertificate = 1401,
  DWCMS_Application = 1402,
  DWCMS_Worker = 1403,
  DWCMS_Registration = 1404,
}

export enum MAILMERGEDATASET {
  SystemInitialise = 0,
  CORE_Action = 1,
  CORE_Complaint = 2,
  CORE_Fee = 3,
  CORE_Notice = 4,
  CORE_LiveData = 5,
  CORE_Contact = 6,
  CORE_InspectionPlanner = 7,
  CORE_Recall = 8,
  CORE_ComplaintInspection = 9,
  CORE_Interview = 10,
  CORE_Complaint_Interview = 11,
  CORE_Complaint_Notice = 12,
  CORE_Undertaking = 13,
  CORE_Investigation = 14,
  CORE_ActionPlan = 15,
  CORE_SearchWarrant = 16,
  CORE_PaymentPlan = 17,
  CORE_EducationProvider = 18,
  CORE_Education = 19,
  CORE_Prosecution = 20,
  CORE_Invoice = 21,
  HealthManager_Premises = 102,
  HealthManager_Prosecution = 120,
  HealthManager_Notice = 121,
  HealthManager_Inspection = 122,
  HealthManager_Sample = 123,
  HealthManager_Fees = 124,
  HealthManager_Recall = 125,
  HealthManager_Action = 126,
  HealthManager_SwimmingPool = 127,
  HealthManager_CoolingTower = 128,
  HealthManager_Complaint = 129,
  HealthManager_ActivityReport = 130,
  HealthManager_Invoice = 131,
  WasteWater_System = 201,
  WasteWater_Fees = 202,
  WasteWater_Sample = 203,
  WasteWater_Prosecution = 204,
  WasteWater_Notice = 205,
  WasteWater_Inspection = 206,
  WasteWater_Complaint = 207,
  WasteWater_Product = 208,
  WasteWater_Product_Fees = 209,
  WasteWater_Product_Sample = 210,
  WasteWater_Product_Prosecution = 211,
  WasteWater_Product_Notice = 212,
  WasteWater_Product_Inspection = 213,
  WasteWater_Product_Complaint = 214,
  WasteWater_Scheme = 215,
  WasteWater_Scheme_Fees = 216,
  WasteWater_Scheme_Sample = 217,
  WasteWater_Scheme_Prosecution = 218,
  WasteWater_Scheme_Notice = 219,
  WasteWater_Scheme_Inspection = 220,
  WasteWater_Scheme_Complaint = 221,
  WasteWater_Installation = 222,
  WasteWater_Installation_Fees = 223,
  WasteWater_Installation_Sample = 224,
  WasteWater_Installation_Prosecution = 225,
  WasteWater_Installation_Notice = 226,
  WasteWater_Installation_Inspection = 227,
  WasteWater_Installation_Complaint = 228,
  WasteWater_Action = 229,
  WasteWater_System_FurtherInformationRequests = 230,
  CRS_Registration = 301,
  CRS_CBCRegistration = 302,
  CRS_3yoRegistration = 303,
  CRS_4yoRegistration = 304,
  CRS_HBCRegistration = 305,
  CRS_AcknowledgementService = 306,
  CRS_4yoRegistration_Invoice = 307,
  CRS_Registration_LateCollection = 308,
  CRS_CBCRegistrationOffer = 310,
  CRS_3yoRegistrationOffer = 311,
  CRS_4yoRegistrationOffer = 312,
  CRS_Debtor_Statement = 313,
  CRS_3yoRegistration_Invoice = 314,
  DISRES_Permit = 401,
  DISRES_PropertyException = 402,
  DISRES_PropertyRestriction = 403,
  DISRES_ResidentParkingAreaDefinition = 404,
  DISRES_StaffParkingAreaDefinition = 405,
  DISRES_PermitLabel = 406,
  DISRES_Fees = 407,
  CUSTOMERSERVICE_Event = 701,
  CUSTOMERSERVICE_Event_Actions = 703,
  CUSTOMERSERVICE_BulletinBoard_Actions = 704,
  CUSTOMERSERVICE_KBPage = 705,
  CUSTOMERSERVICE_PhoneMessage = 706,
  CUSTOMERSERVICE_Event_Inspections = 707,
  TOWNPLANNING_Application = 801,
  TOWNPLANNING_Application_Advertising = 802,
  TOWNPLANNING_Application_Appeals = 803,
  TOWNPLANNING_Application_Fees = 804,
  TOWNPLANNING_Application_FurtherInformationRequests = 805,
  TOWNPLANNING_Application_Inspections = 806,
  TOWNPLANNING_Application_Notices = 807,
  TOWNPLANNING_Application_Contacts = 808,
  TOWNPLANNING_Application_Complaints = 809,
  TOWNPLANNING_Application_Prosecution = 810,
  TOWNPLANNING_Application_Actions = 811,
  TOWNPLANNING_Subdivision = 812,
  TOWNPLANNING_BuildingApplication = 813,
  TOWNPLANNING_Building_Fees = 814,
  TOWNPLANNING_Building_Inspections = 816,
  TOWNPLANNING_Building_Notices = 817,
  TOWNPLANNING_Building_Complaints = 819,
  TOWNPLANNING_Building_Prosecution = 820,
  TOWNPLANNING_Building_Actions = 821,
  TOWNPLANNING_PSA_Applications = 822,
  TOWNPLANNING_PPR_Applications = 823,
  TOWNPLANNING_PPR_Actions = 824,
  TOWNPLANNING_PPR_Appeals = 825,
  TOWNPLANNING_PPR_FurtherInformationRequests = 826,
  TOWNPLANNING_Building_Interviews = 827,
  TOWNPLANNING_PNF_Applications = 828,
  TOWNPLANNING_PNF_DueDiligence = 829,
  TOWNPLANNING_PNF_Operation = 830,
  TOWNPLANNING_PNF_Actions = 831,
  TOWNPLANNING_PNF_FieldObservation = 832,
  BUILDING_Application_FurtherInformationRequests = 833,
  MCH_Registration = 1001,
  MCH_Appointment = 1002,
  Register_CouncilConsent = 1101,
  Register_Demolition = 1102,
  Register_ESBuilding = 1103,
  Register_NonCompliance = 1104,
  Register_PlanningCertficate = 1105,
  Register_PlanningEnquiry = 1106,
  Register_PlansToComply = 1107,
  Register_POPE = 1108,
  Register_SecondaryConsent = 1109,
  Register_StreetProtection = 1110,
  Register_Fees = 1111,
  Register_Inspections = 1112,
  Register_Notices = 1113,
  Register_Prosecution = 1114,
  Register_Action = 1115,
  Register_MultiTenancy = 1116,
  Register_BuildingPoolsSpa = 1117,
  Register_BuildingEnquiry = 1118,
  Register_BuildingStormwater = 1119,
  Register_BuildingEasements = 1120,
  Register_BuildingEnforcements = 1121,
  Register_BuildingSitingApprovals = 1122,
  Register_Appeals = 1123,
  Register_EOT = 1124,
  Register_Advertising = 1125,
  Register_FurtherInformationRequests = 1126,
  Register_BuildingCertificate = 1127,
  Register_Contacts = 1128,
  Register_HealthEnquiry = 1129,
  LLP_Permit = 1201,
  LLP_Application_Fees = 1204,
  LLP_Application_Inspections = 1206,
  LLP_Application_Notices = 1207,
  LLP_Application_Prosecution = 1210,
  LLP_Application_Action = 1211,
  Tender = 1212,
  Permit = 1213,
  Animals_Registration = 1301,
  Animals_Fees = 1304,
  Animals_Inspections = 1306,
  Animals_Notices = 1307,
  Animals_Prosecution = 1310,
  Animals_Actions = 1311,
  Animals_PoundRegister = 1312,
  Animals_PoundRegister_Actions = 1313,
  Animals_PoundRegister_Fees = 1314,
  Animals_InspectionPlannerInspections = 1315,
  Animals_Kennel = 1316,
  Animals_Kennel_Actions = 1317,
  Animals_Kennel_Fees = 1318,
  Animals_Invoice = 1319,
  CSM_Licence = 1401,
  CSM_Fees = 1404,
  CSM_Inspections = 1406,
  CSM_Samples = 1407,
  CSM_Prosecution = 1410,
  CSM_Notice = 1411,
  CSM_Action = 1412,
  CSM_Poison_PestObservation = 1413,
  CSM_Poison_PoisonRegister = 1414,
  CSM_Poison_RiskAssessment = 1415,
  CSM_Poison_PestAnimalProgram = 1416,
  CSM_Poison_Indemnity = 1417,
  CSM_Poison_IssuePoison = 1418,
  CSM_Poison_AerialBaiting = 1419,
  AMS_Appointment = 1501,
  EMS_EventBooking = 1601,
  EMS_GroupEventBooking = 1602,
  TrainingManager_CourseOutline = 1701,
  TrainingManager_CourseSchedule = 1702,
  TrainingManager_TrainingRequest = 1703,
  NSP_Register = 1801,
  NSP_Actions = 1802,
  InspectionPlanner_Inspection = 1901,
  RAD_Register = 1926,
  RAD_Notice = 1927,
  RAD_Inspection = 1928,
  RAD_Fees = 1929,
  RAD_Action = 1930,
  RAD_Register_Licence = 1931,
  RAD_Register_Source = 1932,
  RAD_Register_Place = 1933,
  RAD_Register_Accreditation = 1934,
  RAD_Incident = 1935,
  RAD_ResearchProject = 1936,
  RAD_Refund = 1937,
  SSMS_ChangeRequest = 2001,
  SRU_Audit = 3001,
  SRU_Application = 3002,
  SRU_Organisation = 3003,
  SRU_Issue = 3004,
  SRU_Registration = 3005,
  SRU_Seizure = 3006,
  SRU_Notice = 3007,
  SRU_Statement = 3008,
  SRU_SRSUndertaking = 3009,
  SRU_Sanction = 3010,
  SRU_AppointAdministrator = 3011,
  SRU_ReportableTransaction = 3012,
  SRU_RegistrationStatement = 3014,
  SRU_RegistrationInspection = 3015,
  SRU_RegistrationNotice = 3016,
  SRU_RegistrationProsecution = 3017,
  SRU_RegistrationFees = 3018,
  SRU_ComplianceInstruction = 3019,
  SRU_Inspections = 3020,
  DWCMS_Audit = 3101,
  DWCMS_Application = 3102,
  DWCMS_Worker = 3203,
  DWCMS_Registration = 3105,
  DWCMS_Employment = 3106,
  DWCMS_DisciplinaryAction = 3107,
  DWCMS_Impairment = 3108,
  DWCMS_Insurance = 3109,
  DWCMS_CriminalHistory = 3110,
  DWCMS_ServiceProvider = 3111,
  DWCMS_Worker_Undertaking = 3113,
  DWCMS_Division = 3114,
  DWCMS_Address_History = 3115,
  DWCMS_RegistrationType = 3116,
  DWCMS_OtherNamesPracticed = 3117,
  DWCMS_ProfessionalRegistration = 3118,
  DWCMS_ProfessionalCompetence = 3119,
  DWCMS_PrimaryPlaceOfPractice = 3120,
  DWCMS_Worker_Request = 3121,
  DWCMS_Condition = 3122,
  DWCMS_Enquiry = 3123,
  CEM_RegisterApplication = 3201,
  CEM_Entry = 3202,
  INFRINGEMENTS_TICKET = 3301,
  INFRINGEMENTS_Ticket_Appeals = 3302,
  Register_InfringementCourts = 3303,
  FLEET_EventBooking = 3401,
  FLEET_GroupEventBooking = 3402,
  LANDMANAGEMENT_LandHoldingApplications = 3501,
  LANDMANAGEMENT_Certification = 3502,
  LANDMANAGEMENT_Notification = 3503,
  LANDMANAGEMENT_Enquiry = 3504,
  LANDMANAGEMENT_Inspection = 3505,
  LANDMANAGEMENT_FurtherInformationRequests = 3506,
  COMMUNITYPROPERTY_Assessment = 3601,
  COMMUNITYPROPERTY_Parcel = 3602,
  COMMUNITYPROPERTY_Title = 3603,
  COMMUNITYPROPERTY_Supplementary = 3604,
  COMMUNITYPROPERTY_Scheme = 3605,
  COMMUNITYPROPERTY_Meter = 3606,
  COMMUNITYPROPERTY_Journal = 3607,
  COMMUNITYPROPERTY_Change_of_Ownership = 3608,
  COMMUNITYPROPERTY_Entity = 3609,
  COMMUNITYPROPERTY_Certificate = 3610,
  COMMUNITYPROPERTY_Deferred_Duty = 3611,
  COMMUNITYPROPERTY_Fire_Prevention = 3612,
  COMMUNITYPROPERTY_Register = 3613,
  COMMUNITYPROPERTY_Compliance = 3614,
  COMMUNITYPROPERTY_Debt_Recovery = 3615,
  WasteWater_System_Invoice = 3616,
  TOWNPLANNING_Application_Invoice = 3617,
  TOWNPLANNING_BuildingApplication_Invoice = 3618,
  LLP_Application_Invoice = 3619,
  EMS_EventBooking_Invoice = 3620,
  CEM_Entry_Invoice = 3621,
  CEM_Registration_Invoice = 3622,
  COMMUNITYPROPERTY_Charge_Run = 3623,
  COMMUNITYPROPERTY_Notice_Run = 3624,
  COMMUNITYPROPERTY_PIC = 3626,
}

export enum MailMergeWatermark {
  Default = 0,
  Draft = 1,
  Confidential = 2,
}

export interface MailMerge_SelectMergeDocument_Ext
  extends MailMerge_SelectMergeDocument {
  _options?: {
    SaveAsPDF?: boolean;
    MailMergeDocumentsTemplate?: IMailMergeDocumentsTemplate[];
    SelectedMailMergeDocumentsTemplate?: IMailMergeDocumentsTemplate[];
    Categories?: CoreKeyword[];
    DocPreviewData?: string | null;
  };
}

export interface IDocument extends DTOBaseClass_Standard {
  Document_ID: number;
  Name: string;
  Document_Usage_ENUM: MAILMERGEDOCUMENTUSAGE;
  Document_Usage_Name: string;
  Dataset_ENUM: MAILMERGEDATASET;
  Dataset_Name: string;
  SaveFormat_ENUM: MAILMERGESAVEFORMAT;
  SaveFormat_Name: string;
  SaveFormat: string;
  WhenLoaded: Date;
  AdditionalInformation: string;
  FileEntry: FileStreamEntry;
  UseRegions: boolean;
  AllowLiveMerge: boolean;
  Council_ID: number | null;
  EmailSubject: string;
  EmailContent: string;
  SMSContent: string;
  ImageFieldSettings: string;
  Category_KWD: number | null;
}
