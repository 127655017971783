import { IconCell } from "@app/products/crms/config";
import { calculatorDynamicWidthIconColumn } from "@app/products/crms/util";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { PUBLIC_HOLIDAYS_ROUTE } from "@common/pages/settings/lookups/public-holidays/_id/constant";
import {
  SettingState,
  Svc_View_LookUpDatesList,
} from "@common/pages/settings/lookups/public-holidays/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<Svc_View_LookUpDatesList>();

const PublicHolidayIcon = ({
  dataItem,
  propName,
}: {
  dataItem: Svc_View_LookUpDatesList;
  propName: keyof Svc_View_LookUpDatesList;
}) => {
  return dataItem?.[propName] ? (
    <i
      className="far fa-check"
      style={{
        color: "var(--success)",
      }}
    />
  ) : (
    <i
      className="far fa-times"
      style={{
        color: "var(--error)",
      }}
    />
  );
};

const addColumnState = (
  isByYear: boolean,
  fieldTile: string,
  fieldName: keyof Svc_View_LookUpDatesList,
  colPublicHolidaysGrid: IColumnFields[],
  fieldDescriptionTitle?: string,
  fieldDescriptionName?: keyof Svc_View_LookUpDatesList
) => {
  if (isByYear) {
    colPublicHolidaysGrid.push({
      field: fieldName,
      title: fieldTile,
      format: BOOLEAN_FORMAT.BOOLEAN,
      cell: (props: GridCellProps) => {
        return (
          <IconCell props={props}>
            <PublicHolidayIcon dataItem={props.dataItem} propName={fieldName} />
          </IconCell>
        );
      },
    });
  } else {
    colPublicHolidaysGrid.push(
      {
        field: fieldName,
        title: fieldTile,
        format: BOOLEAN_FORMAT.BOOLEAN,
        cell: (props: GridCellProps) => {
          return (
            <IconCell props={props}>
              <PublicHolidayIcon
                dataItem={props.dataItem}
                propName={fieldName}
              />
            </IconCell>
          );
        },
        dynamicStyle: (state: State) => {
          return {
            // Get dynamic width column
            width: calculatorDynamicWidthIconColumn(65, state, fieldName),
          };
        },
      },
      {
        field: fieldDescriptionName ?? "",
        title: fieldDescriptionTitle ?? "",
      }
    );
  }
};

export const generateColPublicHolidaysGrid = (
  isByYear: boolean,
  stateList: SettingState,
  setPublicHolidayID?: React.Dispatch<React.SetStateAction<number>>,
  setIsShowPublicHolidayDialog?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const colPublicHolidaysGrid: IColumnFields[] = isByYear
    ? [
        {
          field: nameOf("Hol_Date"),
          title: "Date",
          format: DATE_FORMAT.DATE,
          handleOnClick: (event: any) => {
            setPublicHolidayID?.(event.ID);
            setIsShowPublicHolidayDialog?.(true);
          },
          minWidth: 110,
        },
        {
          field: nameOf("Hol_Day"),
          title: "Day",
        },
      ]
    : [
        {
          field: nameOf("Hol_Date"),
          title: "Date",
          format: DATE_FORMAT.DAY_MONTH,
          linkTo: (dataItem: Svc_View_LookUpDatesList) => {
            return `${PUBLIC_HOLIDAYS_ROUTE}/${dataItem.Hol_Year}`;
          },
        },
        {
          field: nameOf("Hol_Year"),
          title: "Year",
          format: NUMBER_FORMAT.NUMBER2,
        },
        {
          field: nameOf("Hol_Day"),
          title: "Day",
        },
      ];
  if (stateList.isHaveACT) {
    addColumnState(
      isByYear,
      "ACT",
      "ACT_Holiday",
      colPublicHolidaysGrid,
      "Description ACT",
      "ACT_Description"
    );
  }

  if (stateList.isHaveNSW) {
    addColumnState(
      isByYear,
      "NSW",
      "NSW_Holiday",
      colPublicHolidaysGrid,
      "Description NSW",
      "NSW_Description"
    );
  }

  if (stateList.isHaveNT) {
    addColumnState(
      isByYear,
      "NT",
      "NT_Holiday",
      colPublicHolidaysGrid,
      "Description NT",
      "NT_Description"
    );
  }

  if (stateList.isHaveQLD) {
    addColumnState(
      isByYear,
      "QLD",
      "QLD_Holiday",
      colPublicHolidaysGrid,
      "Description QLD",
      "QLD_Description"
    );
  }

  if (stateList.isHaveSA) {
    addColumnState(
      isByYear,
      "SA",
      "SA_Holiday",
      colPublicHolidaysGrid,
      "Description SA",
      "SA_Description"
    );
  }

  if (stateList.isHaveTAS) {
    addColumnState(
      isByYear,
      "TAS",
      "TAS_Holiday",
      colPublicHolidaysGrid,
      "Description TAS",
      "TAS_Description"
    );
  }

  if (stateList.isHaveVIC) {
    addColumnState(
      isByYear,
      "VIC",
      "VIC_Holiday",
      colPublicHolidaysGrid,
      "Description VIC",
      "VIC_Description"
    );
  }

  if (stateList.isHaveWA) {
    addColumnState(
      isByYear,
      "WA",
      "WA_Holiday",
      colPublicHolidaysGrid,
      "Description WA",
      "WA_Description"
    );
  }

  return colPublicHolidaysGrid;
};
