import { eventManagementRoute } from "@app/products/event-management/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface IEventManagementActionBarNavDropdownProps {
  category: string;
}

export const EventManagementActionBarNavDropdown = ({
  category,
}: IEventManagementActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={eventManagementRoute.path}
      route={eventManagementRoute}
    />
  );
};
