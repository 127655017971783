import { LOCAL_LAWS_MANAGE_ROUTE } from "@app/products/local-laws/[id]/constant";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";
export const colLocalLawsCRMSApplicationsDraft: IColumnFields[] = [
  {
    field: "PermitNumber",
    title: "Permit Number",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => ({
      pathname: `${LOCAL_LAWS_MANAGE_ROUTE}/` + dataItem.ID,
    }),
    locked: true,
  },
  {
    field: "CRMSEventReferenceNumber",
    title: "CRMS Event Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "Applicant",
    title: "Permit Holder",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: "SiteAddress", title: "Address" },
  { field: "Locality", title: "Locality" },
  { field: "Type", title: "Permit Type" },
  { field: "StatusDueStatus", title: "Status" },
];
