import { APIResponse, APIResponseError } from "@common/apis/model";
import { isCancelResponse, isSuccessResponse } from "@common/apis/util";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { isArray } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

export type TErrorComponent = JSX.Element | null;
interface IUseCallApiHandleLoadFailed {
  setIsLoading:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((status: boolean) => void);
  api: () => Promise<any>;
  handler: (response: APIResponse<any> | any) => Promise<void>;
  watch: any[];
  isActive?: boolean;
  eventType?: string;
}

export const useCallApiHandleLoadFailed = ({
  setIsLoading,
  api,
  handler,
  watch,
  isActive = true,
  eventType,
}: IUseCallApiHandleLoadFailed) => {
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  useEffect(() => {
    if (isActive) handleLoadAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch)]);

  useAddUniqueEventEmitter([
    {
      eventType: eventType ?? "NoEvent",
      listener: () => {
        if (isActive) handleLoadAPI();
      },
    },
  ]);

  const handleLoadAPI = useCallback(async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response: APIResponse<any> = await api();

    if (isArray(response)) {
      await handler(response);
    } else {
      if (isSuccessResponse(response)) {
        await handler(response);
      } else {
        const errorResponse = {
          status: response.status,
          error: response.error,
        };
        if (!isCancelResponse(response)) setResponseLoadError(errorResponse);
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch)]);

  if (isActive && responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={handleLoadAPI}
      />
    );
  } else {
    return null;
  }
};
