import { VO_CentreLink_External_Response } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/external-concession-cards/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_CentreLink_External_Response>();
export const colExternalConcessionCardsRequiringActions: IColumnFields[] = [
  {
    field: nameOf("CMC_Followup_Actions"),
    title: "Action",
  },
  {
    field: nameOf("CER_Customer_Id"),
    title: "Customer ID",
  },
  {
    field: nameOf("CER_Org_Type"),
    title: "Organisation Type",
  },
  {
    field: nameOf("CER_Confirmation_Id"),
    title: "Confirmation ID",
  },
  {
    field: nameOf("Name"),
    title: "Name",
    linkTo: (dataItem: VO_CentreLink_External_Response) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem.Entity_Id}`,
  },
  {
    field: nameOf("CMC_Description"),
    title: "Matched?",
  },
  {
    field: nameOf("CER_Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CER_Deceased"),
    title: "Deceased",
  },
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment ID",
  },
  {
    field: nameOf("Entity_Id"),
    title: "Contact ID",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Entity_Email_Address"),
    title: "Email",
  },
  {
    field: nameOf("Entity_Phone_Number_Mobile"),
    title: "Mobile",
  },
];
