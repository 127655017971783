import { CSLSystemAdminConditionsFormData } from "@app/products/csl/[id]/model";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Toast } from "@components/toast";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

export interface ICSLSystemAdminConditionsFormElementProps {
  isNew: boolean;
  formRenderProps: FormRenderProps;
}
export const CSLSystemAdminConditionsFormElement = ({
  formRenderProps,
}: ICSLSystemAdminConditionsFormElementProps) => {
  const validateForm = (event: React.SyntheticEvent<any>) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    Toast.displayToast({
      content: "🚀 The form is not modified !",
      type: "warning",
    });
  };

  const { valueGetter, onChange } = formRenderProps;

  const cslGroup = [
    { Key: 1, Value: "Group 1" },
    { Key: 2, Value: "Group 2" },
    { Key: 3, Value: "Group 3" },
    { Key: 4, Value: "Group 4" },
    { Key: 5, Value: "Group 5" },
  ];
  const cslSelection = [
    { Key: 1, Value: "Selection 1" },
    { Key: 2, Value: "Selection 2" },
    { Key: 3, Value: "Selection 3" },
    { Key: 4, Value: "Selection 4" },
    { Key: 5, Value: "Selection 5" },
  ];

  const nameOf = nameOfFactory<CSLSystemAdminConditionsFormData>();
  return (
    <FormElement onSubmit={formRenderProps.onSubmit}>
      <input hidden type="submit" id="btn-submit" onClick={validateForm} />
      {/* SECTION 1 */}
      {/* ROW 1 */}
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label-field cc-label-field-danger">
              - To create a new line within a condition or clause use [NEWLINE]
            </label>
            <label className="cc-label-field cc-label-field-danger">
              - To sort the group, enter the sort number between two pipes e.g.
              |1|GROUP NAME
            </label>
          </div>
        </div>
      </div>
      <hr className="cc-divider" />

      {/* SECTION 2*/}
      <div className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              Sort order
              <CCTooltip type="validator" position="right" />
            </label>

            <Field
              name={nameOf("SortOrder")}
              component={CCInput}
              validator={requiredValidator}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              Group
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Group")}
              textField="Value"
              dataItemKey="Key"
              data={cslGroup}
              component={CCSearchComboBox}
              validator={requiredValidator}
              value={getDropdownValue(
                valueGetter(nameOf("Group")),
                cslGroup,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Group"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
        </div>
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">
              Title
              <CCTooltip type="validator" position="right" />
            </label>
            <Field
              name={nameOf("Title")}
              component={CCInput}
              validator={requiredValidator}
            />
          </div>
        </div>
      </div>
      <hr className="cc-divider" />

      {/* SECTION 3 */}
      <div className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Field selection</label>
            <Field
              name={nameOf("EnterableFields")}
              textField="Value"
              dataItemKey="Key"
              data={cslSelection}
              component={CCSearchComboBox}
              value={getDropdownValue(
                valueGetter(nameOf("EnterableFields")),
                cslSelection,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("EnterableFields"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
            />
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label-empty cc-label-empty"></label>
              <Button themeColor="primary">{"Add Field"}</Button>
            </div>
          </div>
        </div>
        {/* ROW 2 */}
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Conditions</label>
            <Field name={nameOf("Condition")} rows={8} component={CCTextArea} />
          </div>
        </div>
      </div>
    </FormElement>
  );
};
