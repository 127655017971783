import { CategoryPickerOfficerAssessment } from "@app/products/town-planning/ppr/components/action-bar/dialogs/officer-assessment/components/input-pickers/category-picker/_index";
import { PPRPermitCompleted } from "@app/products/town-planning/ppr/components/action-bar/dialogs/permit-completed/model";
import { PPRApplicationCategory } from "@app/products/town-planning/ppr/system-admin/application-categories/model";
import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { ApplicationUpdateTriggers } from "@app/products/town-planning/ppr/[id]/model";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { Keyword } from "@common/models/keyword";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { ICCInputPickerChangeEvent } from "@components/cc-input-picker/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import React, { useRef } from "react";

export const ALLOCATION_FORM_STEP = "Allocation";

export const AllocationFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps }: IFormStepElement) => {
  const { onChange } = formRenderProps;
  const nameOf = nameOfFactory<PPRPermitCompleted>();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

  return (
    <section className="cc-field-group">
      <CCLocalNotification ref={notificationRef} />
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">
            Reason for permit
            <CCTooltip type="validator" position="right" />
          </label>

          <Field
            name={nameOf("ReasonForPermit")}
            component={KeyWordsPicker}
            keyWordType={KEYWORD_TYPE.Core_ReasonForPermit}
            productType={PRODUCT_TYPE_NUMBER.TownPlanning}
            validator={requiredValidator}
            onInputChange={(event: InputChangeEvent) => {
              onChange("ReasonForPermit", {
                value: event.target.value,
              });
            }}
            onPickKeyWords={(value: string) => {
              onChange("ReasonForPermit", {
                value: value,
              });
            }}
            readOnly={false}
            onError={(error: any) => {
              notificationRef.current?.pushNotification({
                title: error,
                type: "error",
              });
            }}
            isGetDetail={false}
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Application category <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={"_option.ApplicationCategories"}
            placeholder={"Application category"}
            component={CategoryPickerOfficerAssessment}
            validator={requiredValidator}
            onChange={(event: ICCInputPickerChangeEvent) => {
              onChange("_option.ApplicationCategories", {
                value: event.value
                  ? event.value
                      .reduce(
                        (pre: any, cur: any) =>
                          pre + ", " + cur.ApplicationCategory_Name,
                        ""
                      )
                      .slice(1)
                  : null,
              });
              onChange("ApplicationCategories", {
                value: event.value
                  ? event.value.map((item: PPRApplicationCategory) => item.ID)
                  : [],
              });
              onChange("SaveTriggers", {
                value: [ApplicationUpdateTriggers.UpdateApplicationCategory],
              });
            }}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <div className="cc-field">
            <label className="cc-label">Rating</label>
            <Field
              name={nameOf("Rating_Name")}
              placeholder={"Rating"}
              component={KeyWordsPicker}
              keyWordType={KEYWORD_TYPE.TP_ApplicationRating}
              productType={PRODUCT_TYPE_NUMBER.TownPlanning}
              onPickKeyWords={(value: CoreKeyword) => {
                onChange(nameOf("Rating_KWD"), {
                  value: value.Keyword_ID,
                });
              }}
              onChange={(event: ICCInputPickerChangeEvent) => {
                const rating: Keyword = event.value;
                onChange(nameOf("Rating_Name"), {
                  value: rating.Name,
                });
              }}
              onError={(error: any) => {
                notificationRef.current?.pushNotification({
                  title: error,
                  type: "error",
                });
              }}
            />
          </div>
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Planner <CCTooltip type="validator" position="right" />
          </label>
          <Field
            name={"_option.Planner"}
            component={OfficerPicker}
            onPickOfficer={(value: IOfficer) => {
              onChange("Planner_ID", {
                value: value?.ID,
              });
              onChange("_option.Planner", {
                value: value?.Name,
              });
            }}
            placeholder={"Planner"}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Comments</label>
          <Field
            name={nameOf("AllocationComments")}
            component={CCTextArea}
            rows={3}
            placeholder={"Comments"}
          />
        </div>
      </div>
    </section>
  );
};
