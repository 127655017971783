import { mockAllEntitiesWithMultipleExemptions } from "@app/products/property/assessments/compliance/entities-with-multiple-exemptions/mock";
import { mockExcludeIfAlreadyUnderInvestigation } from "./exclude-if-already-under-investigation/mock";
import { IEntitiesWithMultipleExemptions } from "./model";

export const loadEntitiesWithMultipleExemptionsById = async (id: string) => {
  let response = null;
  response = mockExcludeIfAlreadyUnderInvestigation.find(
    (entitiesWithMultipleExemptions: IEntitiesWithMultipleExemptions) =>
      entitiesWithMultipleExemptions.AssessmentId === id
  );

  if (!response) {
    response = mockAllEntitiesWithMultipleExemptions.find(
      (entitiesWithMultipleExemptions: IEntitiesWithMultipleExemptions) =>
        entitiesWithMultipleExemptions.AssessmentId === id
    );
  }
  return response;
};
