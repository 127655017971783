import { odataGetDebtorNumbers } from "@app/core/components/common/utils";
import { RECORDTYPE } from "@common/constants/recordtype";
import { colPickDebtorNumber } from "@common/input-pickers/debtor-number/config";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { nameOfFactory } from "@common/utils/common";
import {
  CCInputPicker,
  ICCInputPickerChangeEvent,
  ICCInputPickerProps,
} from "@components/cc-input-picker/_index";
import React from "react";

const nameOf = nameOfFactory<RefNumberUsage>();

export interface IDebtorNumberProps extends ICCInputPickerProps {
  onPickHandle: (value: any) => void;
  onInputChange: (value: any) => void;
  recordsTypeListData: any[];
  selectableMode?: "single" | "multiple" | "none";
  recordId: number;
  contactId: number;
  recordType: RECORDTYPE;
  onlyDisableInput?: boolean;
}
export const DebtorNumberPicker = (props: IDebtorNumberProps) => {
  const {
    onPickHandle,
    onInputChange,
    recordsTypeListData,
    onChange,
    onError,
    selectableMode,
    textField,
    textProduce,
    valueField,
    valueProduce,
    recordId,
    contactId,
    recordType,
    onlyDisableInput = false,
    ...other
  } = props;
  const handleOnChange = (event: ICCInputPickerChangeEvent) => {
    const value: RefNumberUsage = event.value;
    if (onPickHandle) onPickHandle(value);
  };

  return (
    <CCInputPicker
      {...other}
      dialog={{
        titleHeader: "Pick Debtor Number",
        maxWidth: "60%",
      }}
      grid={{
        columnFields: colPickDebtorNumber,
        primaryField: nameOf("Record_Id"),
        selectableMode: selectableMode,
        dataUrl: odataGetDebtorNumbers(contactId, recordId, recordType),
        isAutoHiddenPager: false,
      }}
      onChange={handleOnChange}
      valueField={valueField}
      valueProduce={valueProduce}
      textField={textField}
      textProduce={textProduce}
      readOnly={false}
      onInputChange={onInputChange}
      onlyDisableInput={onlyDisableInput}
    />
  );
};
