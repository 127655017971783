export enum AusState {
  AUS_STATE_VIC = "VIC", //Victoria
  AUS_STATE_NSW = "NSW", //New South Wales
  AUS_STATE_QLD = "QLD", //Queensland
  AUS_STATE_SA = "SA", //South Australia
  AUS_STATE_TAS = "TAS", //Tasmania
  AUS_STATE_WA = "WA", //Western Australia
  AUS_TERRITORY_ACT = "ACT", //Australian Capital Territory
  AUS_TERRITORY_NT = "NT", //Northern Territory
}
