import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class HelperServices {
  private _isShowHelper: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isShowHelper() {
    return this._isShowHelper;
  }
  setIsShowHelper = (isLoading: boolean) => {
    runInAction(() => {
      this._isShowHelper = isLoading;
    });
  };
}

export const helperServicesStore = new HelperServices();
const helperServicesContext = createContext(helperServicesStore);
export const useHelperServicesStore = () => {
  return useContext(helperServicesContext);
};
