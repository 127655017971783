import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { NewBuildingButton } from "@app/products/building/components/action-bar/buttons/new-building/_index";
import { BuildingActionBarNavDropdown } from "@app/products/building/components/action-bar/nav-dropdown/_index";
import {
  displayDate,
  sampleCalendarData,
} from "@app/products/building/insp-schedule/calendar/api";
import { CalendarDropdown } from "@app/products/building/insp-schedule/calendar/components/action-bar/calendar-dropdown/_index";
import { CalendarViewEnum } from "@app/products/building/insp-schedule/calendar/model";
import { useInspScheduleCalendarStore } from "@app/products/building/insp-schedule/calendar/store";
import { BuildingInspScheduleCalendarBookmark } from "@app/products/building/insp-schedule/calendar/util";
import { inspScheduleRoute } from "@app/products/building/insp-schedule/route";
import { buildingRoute } from "@app/products/building/route";
import { BUILDING_MANAGE_ROUTE } from "@app/products/building/[id]/constant";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { formatDateByKendo } from "@common/utils/formatting";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { guid } from "@progress/kendo-react-common";
import { Popup } from "@progress/kendo-react-popup";
import {
  AgendaView,
  DayView,
  MonthView,
  Scheduler,
  SchedulerItem,
  SchedulerItemProps,
  TimelineView,
  WeekView,
} from "@progress/kendo-react-scheduler";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffectOnce } from "react-use";

export const BuildingCalendarForm = () => {
  const { resetStore } = useCCProductListViewStore();
  useCCListViewActionBar({
    title: buildingRoute.name,

    leftComponents: [
      <BuildingActionBarNavDropdown category={inspScheduleRoute.path} />,
      <CalendarDropdown />,
    ],
    centerComponents: [
      <NewBuildingButton />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Building}
        dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_BuildingApplication}
        recordType={RECORDTYPE.TP_BuildingApplication}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Building}
        recordType={RECORDTYPE.TP_BuildingApplication}
      />,
    ],
    rightComponents: [
      <ListViewBookmarkIcon
        linkUrl={BUILDING_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Building}
        recordType={RECORDTYPE.BUILDING}
        listViewDetail={
          BuildingInspScheduleCalendarBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          BuildingInspScheduleCalendarBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });
  //TODO: will remove resetStore when applied CCProductListView
  useEffectOnce(() => resetStore());
  const { selectedBuildingCalendarFilter } = useInspScheduleCalendarStore();
  const [data, setData] = React.useState(sampleCalendarData);
  const filterColumn = [
    { text: "Leo Messi", value: "messi", color: "#74b9ff" },
    { text: "Alexander Ryan", value: "alexander", color: "#a29bfe" },
    { text: "Abby Dickson", value: "abby", color: "#e17055" },
    { text: "Agata Snelling", value: "agata", color: "#00b894" },
  ];
  React.useEffect(() => {
    if (selectedBuildingCalendarFilter === "everyone") {
      setData(sampleCalendarData);
    } else {
      setData(
        sampleCalendarData.filter((o) => {
          return o.name === selectedBuildingCalendarFilter;
        })
      );
    }
  }, [selectedBuildingCalendarFilter]);

  const handleDataChange = ({ created, updated, deleted }: any) => {
    setData((old) =>
      old
        .filter(
          (item) =>
            deleted.find((current: any) => current.id === item.id) === undefined
        )
        .map(
          (item) =>
            updated.find((current: any) => current.id === item.id) || item
        )
        .concat(
          created.map((item: any) => Object.assign({}, item, { id: guid() }))
        )
    );
  };

  const CustomSchedulerItem = (props: SchedulerItemProps) => {
    const ref = React.useRef<any>(null);
    const [show, setShow] = React.useState(false);

    const handleMouseOver = React.useCallback(
      (event) => {
        setShow(true);
        // Call the default `onFocus` handler
        if (props.onMouseOver) {
          props.onMouseOver(event);
        }
      },
      [props]
    );

    const handleMouseLeave = React.useCallback(
      (event) => {
        setShow(false);

        // Call the default `onFocus` handler
        if (props.onMouseOut) {
          props.onMouseOut(event);
        }
      },
      [props]
    );

    return (
      <>
        <SchedulerItem
          {...props}
          className={"cc-scheduler-item"}
          ref={ref}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        />
        <Popup
          show={show}
          anchorAlign={{
            horizontal: "left",
            vertical: "bottom",
          }}
          popupAlign={{
            horizontal: "left",
            vertical: "top",
          }}
          id="scheduler-popup"
          anchor={ref.current && ref.current.element}
        >
          <div className="cc-calendar-popup">
            <h6>{props.title}</h6>
            <div>
              <b>Start:</b>
              <span>
                {formatDateByKendo(props.start, DATETIME_FORMAT.DATETIME)}
              </span>
            </div>
            <div>
              <b>End:</b>
              <span>
                {formatDateByKendo(props.end, DATETIME_FORMAT.DATETIME)}
              </span>
            </div>
            <div>
              <b>Description:</b>
              <span>{props.description}</span>
            </div>
          </div>
        </Popup>
      </>
    );
  };
  return (
    <div className="cc-calendar">
      <div className="cc-event-scheduler-container">
        <Scheduler
          editable={true}
          data={data}
          className="cc-event-scheduler"
          defaultDate={displayDate}
          onDataChange={handleDataChange}
          item={CustomSchedulerItem}
          defaultView={CalendarViewEnum.MONTH}
          resources={[
            {
              name: "Meeting Type",
              data: [...filterColumn],
              field: "name",
              valueField: "value",
              textField: "text",
              colorField: "color",
            },
          ]}
        >
          <TimelineView />
          <DayView />
          <WeekView />
          <MonthView />
          <AgendaView />
        </Scheduler>
      </div>
    </div>
  );
};

export default observer(BuildingCalendarForm);
