import {
  DTO_MasterProperty_LOVs,
  DTO_Workflow_MasterProperty_Create,
  RequestMasterPropertyObj,
} from "@app/products/property/assessments/master-properties/components/form-steps/add-new-master-property/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const getNewMasterPropertyLOVs = async (
  workflowType: WorkflowTypes
): Promise<APIResponse<DTO_MasterProperty_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get<DTO_MasterProperty_LOVs>(
      `/api/property/internal/masterproperty/lovs?workflowType=${workflowType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNewWorkflowCreateMasterProperty = async (
  requestMasterPropertyObj: RequestMasterPropertyObj | object = {}
): Promise<APIResponse<DTO_Workflow_MasterProperty_Create>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createmasterproperty/new`,
      requestMasterPropertyObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedWorkflowCreateMasterProperty = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_MasterProperty_Create>> => {
  try {
    const response = await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/createmasterproperty/${workflowDraftId}`
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataMasterProperty =
  | [
      APIResponse<DTO_MasterProperty_LOVs>,
      APIResponse<DTO_Workflow_MasterProperty_Create>
    ]
  | APIResponse<DTO_MasterProperty_LOVs | DTO_Workflow_MasterProperty_Create>
  | undefined;
export const getInitialDataMasterProperty = async (
  workflowType: WorkflowTypes,
  workflowDraftId: number | undefined,
  isNewMasterProperty: boolean = false
): Promise<IGetInitialDataMasterProperty> => {
  try {
    return await Promise.all([
      getNewMasterPropertyLOVs(workflowType),
      !isNewMasterProperty && !isNil(workflowDraftId)
        ? getSavedWorkflowCreateMasterProperty(workflowDraftId)
        : getNewWorkflowCreateMasterProperty(),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessCreateMasterProperty = async (
  workflowType: WorkflowProcessMode,
  data: DTO_Workflow_MasterProperty_Create
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_MasterProperty_Create>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/createmasterproperty/process/${workflowType}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
