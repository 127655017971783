export enum SharePointCredentialsEnum {
  DefaultCredentials = "0",
  DefaultNetworkCredentials = "1",
  NetworkCredentialsSiteUserRecords = "2",
  OnlineCredentials = "3",
  NetworkCredentialsProxy = "4",
  None = "5",
  SharePointOnlineOAuthTokenPassthrough = "6",
  OAuthClientCredentialToken = "7",
}
