import ResequenceMeterDialog from "@app/products/property/meters/components/dialogs/re-sequence-meter/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ResequenceMeterButton = observer(() => {
  const [showResequenceMeterDialog, setShowResequenceMeterDialog] =
    useState(false);
  const { gridSelectedRows } = useCCProductListViewStore();

  return (
    <>
      <CCNavButton
        title="Re-sequence Meter"
        onClick={() => setShowResequenceMeterDialog(true)}
        disabled={!gridSelectedRows.length}
      />
      {showResequenceMeterDialog && (
        <ResequenceMeterDialog
          onClosePopup={() => {
            setShowResequenceMeterDialog(false);
          }}
          onConfirmYes={() => {
            setShowResequenceMeterDialog(false);
          }}
        />
      )}
    </>
  );
});
