import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ComplianceAssociationsTab } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/associations/_index";
import { ComplianceDetailTab } from "@app/products/property/assessments/compliance/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE } from "@app/products/property/assessments/compliance/[id]/constant";
import { ASSESSMENT_COMPLIANCE_ALL_DOCUMENT } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/constant";
import { VO_Compliance_Document } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/model";
import { useComplianceDocumentsStore } from "@app/products/property/assessments/compliance/actions-and-letters/all-documents/all/store";
import { IssueDocumentsButton } from "@app/products/property/assessments/compliance/actions-and-letters/components/action-bar/buttons/issue-documents/_index";
import { DocumentIssuedButton } from "@app/products/property/assessments/compliance/actions-and-letters/components/action-bar/buttons/populate-documents/_index";
import { ViewDocumentsButton } from "@app/products/property/assessments/compliance/actions-and-letters/components/action-bar/buttons/view-documents/_index";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useEffectOnce } from "react-use";
import { allDocumentAllColList } from "./config";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "./util";
const nameOf = nameOfFactory<VO_Compliance_Document>();
export default observer(() => {
  const { populateDocumentsData, resetStore } = useComplianceDocumentsStore();
  const {
    gridDataState,
    setGridDataState,
    setIsShowFilterBar,
    resetGridDataState,
  } = useCCProductListViewStore();
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <ComplianceDetailTab /> },
      { title: "Map", component: <MapSTripTab /> },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab componentNumber={eComponent.Compliance} />
        ),
      },
      { title: "Related", component: <ComplianceAssociationsTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Compliance}
          />
        ),
      },
    ],
  });
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Compliance}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
      />,
      <DocumentIssuedButton />,
      <IssueDocumentsButton />,
      <ViewDocumentsButton />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Compliance}
        keyField={"Compliance_Id"}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ASSESSMENT_COMPLIANCE_ROUTE}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useEffectOnce(() => {
    resetGridDataState();
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    if (populateDocumentsData) {
      const newFilter = [];

      if (populateDocumentsData.IssuedFrom) {
        newFilter.push({
          field: "CD_Issued_On",
          value: populateDocumentsData.IssuedFrom,
          operator: "gte",
        });
      }
      if (populateDocumentsData.IssuedTo) {
        newFilter.push({
          field: "CD_Issued_On",
          value: populateDocumentsData.IssuedTo,
          operator: "lte",
        });
      }
      if (newFilter?.length > 0) {
        setIsShowFilterBar(true);
        setGridDataState({
          ...gridDataState,
          filter: {
            filters: newFilter,
            logic: "and",
          },
        });
      } else if ((gridDataState.filter?.filters?.length ?? 0) > 0) {
        setIsShowFilterBar(false);
        setGridDataState({
          ...gridDataState,
          filter: { logic: "and", filters: [] },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateDocumentsData]);
  return (
    <CCProductListView
      dataUrl={ASSESSMENT_COMPLIANCE_ALL_DOCUMENT}
      columnFields={allDocumentAllColList}
      primaryField={nameOf("Compliance_Id")}
    />
  );
});
