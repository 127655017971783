import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface IPPRSettingManageFormActionBarProps {
  isShowSlideBar: boolean;
  onClick: () => void;
}
export const PPRSettingManageFormActionBar = observer(
  ({
    isShowSlideBar = false,
    onClick,
  }: IPPRSettingManageFormActionBarProps) => {
    return (
      <CCManagePageActionBar
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={isShowSlideBar}
            onClick={onClick}
          />,
        ]}
      />
    );
  }
);
