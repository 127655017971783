import { eChangeOfOwnershipType } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";

export const hiddenSwitchTypes = [
  eChangeOfOwnershipType.Partial_Transfer_of_Ownership,
  eChangeOfOwnershipType.Change_of_Name_Address,
  eChangeOfOwnershipType.Change_of_Associated_Name_Address,
  eChangeOfOwnershipType.PIC_Name_and_Address_Change,
];

export const hiddenSomeInputsTypes = [
  eChangeOfOwnershipType.Change_of_Name_Address,
  eChangeOfOwnershipType.Change_of_Associated_Name_Address,
  eChangeOfOwnershipType.Entity_Name_and_Address_Change,
  eChangeOfOwnershipType.PIC_Name_and_Address_Change,
];
