import { TOWN_PLANNING_PPR_ENQUIRIES_ROUTE } from "@app/products/town-planning/ppr/enquiries/_id/constant";
import { IPPREnquiries } from "@app/products/town-planning/ppr/[id]/components/child-screens/enquiries/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IPPREnquiries>();

export const colPPREnquiries: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    locked: true,
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => `${TOWN_PLANNING_PPR_ENQUIRIES_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("Date_Lodged"),
    title: "Lodged",
    locked: true,
    format: DATE_FORMAT.DATE,
  },

  {
    field: nameOf("TeamLeader"),
    title: "Delegated Approver",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("Date_Due"),
    title: "Due",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
];
