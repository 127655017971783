import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export interface INewPermitButtonProps {
  isDisabled?: boolean;
}
export const NewPermitButton = ({ isDisabled }: INewPermitButtonProps) => {
  const history = useHistory();
  return (
    <CCNavButton
      title="New"
      onClick={() => {
        history.push(`${LOCAL_LAWS_PERMIT_ROUTE}/new`);
      }}
      disabled={isDisabled}
    />
  );
};
