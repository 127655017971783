import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colSchemeAccountAttributesUDA } from "./config";
import { mockSchemeAccountAttributesUDA } from "./mock";

const SchemeAccountAttributesUDA = () => {
  const [data, setData] = useState<any>(null);

  useEffectOnce(() => {
    setData(mockSchemeAccountAttributesUDA.Attributes);
  });

  return (
    <div className="cc-scheme-account-attributes">
      <CCGrid
        className="cc-scheme-account-attributes"
        data={data ?? []}
        columnFields={colSchemeAccountAttributesUDA}
        primaryField="ID"
        editableMode="row"
      />
    </div>
  );
};

export default SchemeAccountAttributesUDA;
