import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IParentSection } from "@common/pages/contacts/model";

export const parentPage: IParentSection[] = [
  {
    key: 1,
    label: "Type",
    value: "Licence to Possess Regulation 25 Poisons – Sodium Fluoroacetate",
  },
  { key: 2, label: "Applicant", value: "CAYGILL, Vanderina" },
  { key: 3, label: "Licence number", value: "3/16" },
];

export const cslDecision: IKeyValuePacket[] = [
  { Key: 1, Value: "Approved" },
  { Key: 2, Value: "Portal" },
  { Key: 3, Value: "Rejected" },
];
