import { getRecordTrimClassification } from "@app/core/trim/trim-classification/api";
import { getTrimClassificationInputPickerOption } from "@app/core/trim/trim-classification/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { Svc_TrimClassification } from "@common/models/corporateSetting";
import { nameOfFactory } from "@common/utils/common";
import React from "react";

interface ITrimContainerProps {
  value: any;
  showNone?: boolean;
  electronicOnly?: boolean;
  selectedRows?: any[] | undefined;
}

export const TrimClassificationInputPicker = (props: ITrimContainerProps) => {
  const { value, selectedRows, ...others } = props;
  const nameOfTrimClassification = nameOfFactory<Svc_TrimClassification>();

  return (
    <InputPickerSearch
      nameDisplayMerge={nameOfTrimClassification("Name")}
      options={getTrimClassificationInputPickerOption(undefined, {
        selectableMode: "single",
      })}
      value={value}
      localSearchOptions={{
        enableLocalSearch: true,
        searchingPropKey: nameOfTrimClassification("Name"),
      }}
      customGetData={async () => await getRecordTrimClassification()}
      showClearButton={true}
      {...others}
    />
  );
};
