import { generateNoticesAPI } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/generate-notice/api";
import { ECustomColNameProperty } from "@app/products/property/config";
import { Label } from "@common/stores/products/config";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IGenerateNoticeDialogProps {
  onClose: () => void;
  noticeRunId: number;
  actionAfterFinish: (data: any) => void;
}

export const GenerateNoticeDialog = observer(
  ({ onClose, noticeRunId, actionAfterFinish }: IGenerateNoticeDialogProps) => {
    const assessmentsLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessments
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleConfirmDialog = async () => {
      setIsLoading(true);
      const response = await generateNoticesAPI(noticeRunId);
      setIsLoading(false);
      actionAfterFinish(response?.data);
      onClose();
    };
    return (
      <ConfirmDialog
        title="Confirmation"
        subMessage={`Are you sure you with to generate notices for all related ${assessmentsLabel.toLocaleLowerCase()}?`}
        btnYesTitle="OK"
        btnNoTitle="Cancel"
        onClosePopup={onClose}
        onAsyncConfirm={handleConfirmDialog}
        isLoadingYes={isLoading}
      />
    );
  }
);
