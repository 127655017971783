import { FURTHER_INFO_ROUTE } from "@app/core/further-info/[id]/constant";
import { TPFurtherInfoRequest } from "@app/products/town-planning/ppr/fi-requests/all/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<TPFurtherInfoRequest>();
export const colPPRFIRequestAll: IColumnFields[] = [
  {
    field: nameOf("ApplicationNumber"),
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => `${FURTHER_INFO_ROUTE}/${dataItem.ID}`,
  },
  {
    field: nameOf("SiteAddress"),
    title: "Site Address",
  },
  {
    field: nameOf("Locality"),
    title: "Locality",
  },
  {
    field: nameOf("Category"),
    title: "Proposal",
  },
  {
    field: nameOf("Details"),
    title: "Details",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("DateRequested"),
    title: "Requested",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateDue"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("DateInfoReceived"),
    title: "Received",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("FileNo"),
    title: "File Number",
    ignoreGlobalFilter: true,
  },
  {
    field: nameOf("DueStatus"),
    title: "Due Status",
  },
];
