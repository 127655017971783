import { useEffect, useState } from "react";

export const useIsLoadingStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFullScreen, setIsLoadingFullScreen] = useState(false);

  useEffect(() => {
    const checkLoadingStatus = () => {
      const loadingElement = document.querySelector(".cc-loading");
      setIsLoading(!!loadingElement);

      if (loadingElement) {
        const isFullScreenMode =
          window.innerHeight === loadingElement.clientHeight &&
          window.innerWidth === loadingElement.clientWidth;
        setIsLoadingFullScreen(isFullScreenMode);
      } else {
        setIsLoadingFullScreen(false);
      }
    };

    const observer = new MutationObserver(() => checkLoadingStatus());
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial check
    checkLoadingStatus();

    return () => observer.disconnect();
  }, []);

  return { isLoading, isLoadingFullScreen };
};
