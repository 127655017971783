import { ICCRoute } from "@common/constants/ICCRoute";
import { corporateAuthRoute } from "@common/pages/settings/security/corporate-authorisations/route";
import { orgStructureRoute } from "@common/pages/settings/security/org-structure/route";
import { securityTemplatessRoute } from "@common/pages/settings/security/security-templates/_id/route";
import { siteUsersRoute } from "./site-users/route";

export const securityRoute: ICCRoute = {
  path: "security",
  name: "Security",
  children: [
    corporateAuthRoute,
    orgStructureRoute,
    securityTemplatessRoute,
    siteUsersRoute,
    {
      path: "site-users-by-org-structure",
      name: "Site Users by Org Structure",
      component: require("./site-users-by-org-structure/_index").default,
    },
    {
      path: "site-users-by-ad-hoc-group",
      name: "Site Users by Ad hoc Group",
      component: require("./site-users-by-ad-hoc-group/_index").default,
    },
    {
      path: "site-users-by-security-template",
      name: "Site Users by Security Template",
      component: require("./site-users-by-security-template/_index").default,
    },
    {
      path: "ad-sync ",
      name: "AD Sync",
      component: require("./ad-sync/_index").default,
    },
    {
      path: "administrative-site-users",
      name: "Administrative Site Users",
      component: require("./administrative-site-users/_index").default,
    },
  ],
};
