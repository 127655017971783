import { eventEmitter } from "@/App";
import { CancelDialog } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/components/cancel/_index";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { IConfirmation } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirmation/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowProcessMode } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const ConfirmCancelDialog = observer(() => {
  //State
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  //Store
  const { setDataForCancelDialog, dataForCancelDialog, resetStore } =
    useConfirmCancelDialogStore();
  const { removeDialog, listDialog } = usePropertyWorkflowStore();
  const { clearSelectedItems } = useCCProductListViewStore();

  /**
   * handle Cancel
   */
  const handleCancelWorkflow = async (data: IConfirmation) => {
    if (!dataForCancelDialog) return;

    const {
      dataCancel,
      cancelAPI,
      customActionCloseWorkflow,
      actionSuccess,
      defaultSuccessMessage,
      defaultErrorMessage,
    } = dataForCancelDialog;

    setNotifications([]);
    setIsLoadingCancel(true);
    appNotificationStore.clearErrorNotification();
    //Call API cancel workflow
    const cancelData = {
      ...dataCancel,
      WorkflowHeader: {
        ...dataCancel?.WorkflowHeader,
        WorkflowDraft: {
          ...dataCancel?.WorkflowHeader?.WorkflowDraft,
          WD_Cancellation_Reason_Id: data?.Reason,
          WD_Cancellation_Additional_Comments: data?.Comments,
        },
      },
    };

    const response = await cancelAPI(WorkflowProcessMode.Cancel, cancelData);
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      //Close Cancel dialog
      setDataForCancelDialog(undefined);

      // Check if a custom action for closing the workflow is provided
      if (typeof customActionCloseWorkflow === "function") {
        // Execute custom logic to close the workflow
        customActionCloseWorkflow();
      } else {
        // If no custom action is provided, perform default actions:
        //Close current workflow
        if (listDialog?.[0]?.type) {
          removeDialog(listDialog[0].type);
        }
        //Reload Grid
        if (listDialog?.[0]?.typeList) {
          clearSelectedItems();
          eventEmitter.emit(CCGridEventType.RefreshOData);
        }
        //Show notification
        appNotificationStore.pushNotification({
          title: response.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      }

      if (typeof actionSuccess === "function") {
        actionSuccess();
      }
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: response?.data?.ErrorMessage ?? defaultErrorMessage,
          type: "error",
        },
      ]);
    }
    setIsLoadingCancel(false);
  };

  useEffectOnce(() => {
    return () => resetStore();
  });

  return (
    <>
      {dataForCancelDialog && (
        <CancelDialog
          onSubmitData={handleCancelWorkflow}
          onClose={() => {
            setNotifications([]);
            setDataForCancelDialog(undefined);
          }}
          isLoadingSendData={isLoadingCancel}
          notifications={notifications}
        />
      )}
    </>
  );
});
