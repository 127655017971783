import { ItemTypes } from "@app/products/ams/util/itemTypes";
import { useObserver } from "mobx-react-lite";
import React, { Fragment } from "react";
import { useDrag } from "react-dnd";

export interface IGroupDragItem {
  type: string;
  parentModelName: string;
  parentGroupName: string;
}

interface IProps {
  parentModel: string;
  title: string;
  counter?: number;
}
export const GroupLayoutElement: React.FC<IProps> = ({
  parentModel,
  title,
  counter,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.PanelGroupLayoutElement,
      parentModelName: parentModel,
      parentGroupName: title,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return useObserver(() => (
    <Fragment>
      <div
        className="bg-secondary"
        ref={drag}
        style={{ opacity: isDragging ? "0.5" : "1", width: "100%" }}
      >
        <h6 style={{ cursor: "pointer" }}>
          {title}&nbsp;
          {counter && <span className="disabled">{`( ${counter} )`}</span>}
        </h6>
      </div>
    </Fragment>
  ));
};

export default GroupLayoutElement;
