import { ICCRoute } from "@common/constants/ICCRoute";
import { ReactElement } from "react";

const recursive = (
  arrChild: ICCRoute[],
  baseUrl: string,
  actionRoute: IConvertRouteDynamic[]
) => {
  if (arrChild.length > 0) {
    arrChild.forEach((item: ICCRoute) => {
      const baseUrlTemp = baseUrl ? `${baseUrl}/` : "";
      const path = item.path ? `${baseUrlTemp}${item.path}` : baseUrl;
      if (item.component) {
        actionRoute.push({
          path,
          component: item.component,
        });
      }
      if (item.children) {
        recursive(item.children, path, actionRoute);
      }
    });
  }
};

export interface IConvertRouteDynamic {
  path: string;
  component: ReactElement;
}

export const convertRouteDynamic = (
  arr: ICCRoute[]
): IConvertRouteDynamic[] => {
  return arr.flatMap((item) => {
    const actionRoute: Array<any> = [];
    recursive([item], "", actionRoute);
    if (Array.isArray(item.children)) {
      recursive(item.children, item.path, actionRoute);
    }
    return actionRoute;
  });
};
