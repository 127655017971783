import { ICCRoute } from "@common/constants/ICCRoute";

export const systemsRoute: ICCRoute = {
  path: "systems",
  name: "Systems",
  children: [
    {
      path: "register",
      name: "Register",
      component: require("./register/_index").default,
    },
    {
      path: "by-manufacturer",
      name: "By Manufacturer",
      component: require("./by-manufacturer/_index").default,
    },
    {
      path: "by-material",
      name: "By Material",
      component: require("./by-material/_index").default,
    },
    {
      path: "by-capacity",
      name: "By Capacity",
      component: require("./by-capacity/_index").default,
    },
    {
      path: "by-location",
      name: "By Location",
      component: require("./by-location/_index").default,
    },
    {
      path: "by-risk-level",
      name: "By Risk Level",
      component: require("./by-risk-level/_index").default,
    },
    {
      path: "by-status",
      name: "By Status",
      component: require("./by-status/_index").default,
    },
    {
      path: "by-authority",
      name: "By Authority",
      component: require("./by-authority/_index").default,
    },
    {
      path: "by-system-name",
      name: "By System Name",
      component: require("./by-system-name/_index").default,
    },
    {
      path: "by-system-type",
      name: "By System Type",
      component: require("./by-system-type/_index").default,
    },
    {
      path: "pti-not-issued",
      name: "PTI Not Issued",
      component: require("./pti-not-issued/_index").default,
    },
    {
      path: "atu-not-issued",
      name: "ATU Not Issued",
      component: require("./atu-not-issued/_index").default,
    },
    {
      path: "archive",
      name: "Archive",
      component: require("./archive/_index").default,
    },
    {
      path: "decommissioned",
      name: "Decommissioned",
      component: require("./decommissioned/_index").default,
    },
  ],
};
