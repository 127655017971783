//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";

export const colHMFSRComplaintTypes = [
  {
    field: HMFieldMapping.Category,
    title: "Category",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.SubCategory,
    title: "Sub Category",
    width: 150,
  },
  {
    field: HMFieldMapping.FSRType,
    title: "FSR Type",
    width: 150,
  },
  {
    field: HMFieldMapping.FSRCode,
    title: "FSR Code",
    width: 150,
  },
];
