import {
  getComplianceSummary,
  getComplianceSummaryLOV,
} from "@app/products/property/assessments/compliance/[id]/api";
import {
  DTO_Compliance_Summary,
  DTO_Compliance_Summary_Lovs_Submit,
  DTO_Functionality,
} from "@app/products/property/assessments/compliance/[id]/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class ComplianceStore {
  private _compliance?: DTO_Compliance_Summary | undefined = undefined;
  private _complianceLOV?: DTO_Compliance_Summary_Lovs_Submit | undefined =
    undefined;
  private _complianceFunctionalities: DTO_Functionality[] = [];
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get complianceLOV() {
    return this._complianceLOV;
  }
  setComplianceLOV = (complianceLOV?: DTO_Compliance_Summary_Lovs_Submit) => {
    runInAction(() => {
      this._complianceLOV = complianceLOV;
    });
  };

  get compliance() {
    return toJS(this._compliance);
  }
  setCompliance = (compliance?: DTO_Compliance_Summary) => {
    runInAction(() => {
      this._compliance = compliance;
    });
  };

  get complianceFunctionalities() {
    return toJS(this._complianceFunctionalities);
  }
  setComplianceFunctionalities = (
    complianceFunctionalities?: DTO_Functionality[]
  ) => {
    runInAction(() => {
      this._complianceFunctionalities = complianceFunctionalities ?? [];
    });
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };
  get complianceId() {
    return this.compliance?.ComplianceId;
  }

  //Action
  resetStore = () => {
    runInAction(() => {
      this._compliance = undefined;
      this._isLoading = false;
      this._responseLoadError = undefined;
    });
  };

  reLoadCompliance = async () => {
    if (this.complianceId) await this.loadCompliance(this.complianceId);
  };

  loadCompliance = async (
    complianceId?: number,
    notification?: IAppNotificationItemAddProps
  ) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    const responseLOV = await getComplianceSummaryLOV();
    if (isSuccessResponse(responseLOV)) {
      // convert a array of [{Code: string, Name: string}] to [{Code: number, Name: string}, ]
      const ComplianceStatusesConverted = convertValueLOVToNumber<DTO_LOV>(
        responseLOV.data?.ComplianceStatuses ?? [],
        "Code"
      );
      const ComplianceTypesConverted = convertValueLOVToNumber<DTO_LOV>(
        responseLOV.data?.ComplianceTypes ?? [],
        "Code"
      );
      this.setComplianceLOV({
        ComplianceStatuses: ComplianceStatusesConverted ?? [],
        ComplianceTypes: ComplianceTypesConverted ?? [],
      });
    } else {
      errorResponse = {
        status: responseLOV.status,
        error: responseLOV.error,
      };
    }
    if (complianceId) {
      const response = await getComplianceSummary(complianceId);

      let newCompliance: DTO_Compliance_Summary | undefined = undefined;
      if (isSuccessResponse(response)) {
        newCompliance = response.data;
        this.setComplianceFunctionalities(newCompliance?.Functionalities);
      } else {
        errorResponse = {
          status: response.status,
          error: response.error,
        };
      }
      if (newCompliance?.Compliance?.ComplianceAlertMessage) {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: newCompliance.Compliance.ComplianceAlertMessage,
          type: "warning",
        });
      }
      //Set initial data for responsible officer
      if (newCompliance?.Compliance?.Officer_Id) {
        newCompliance.Compliance.ResponsibleOfficer = {
          FullName: newCompliance.Compliance.OfficerName,
          Id: newCompliance.Compliance.Officer_Id,
        };
      }
      this.setCompliance(newCompliance);
      if (notification) {
        appNotificationStore.pushNotification(notification);
      }
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };
}

const complianceStoreContext = createContext(new ComplianceStore());
export const useComplianceStore = () => {
  return useContext(complianceStoreContext);
};
