import { CRMS_SERVICE_STANDARDS_ROUTE } from "@app/products/crms/service-standards/[id]/constant";
import { NewServiceStandardFromSubCategoryParams } from "@app/products/crms/service-standards/[id]/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React from "react";
import { useHistory } from "react-router-dom";

export interface INewSubCategoryButtonProps {
  isDisabled?: boolean;
  data?: NewServiceStandardFromSubCategoryParams;
}

export const NewServiceStandardButton = ({
  isDisabled,
  data,
}: INewSubCategoryButtonProps) => {
  const history = useHistory();

  return (
    <CCNavButton
      title={"New service standard"}
      disabled={isDisabled}
      onClick={() =>
        history.push(`${CRMS_SERVICE_STANDARDS_ROUTE}/new`, {
          dataFromSubCategory: data,
        })
      }
    />
  );
};
