import {
  actionLaunchRoute,
  conditionDisableBookmarkAction,
} from "@common/pages/actions/util";
import { IBookmarkIconProps } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/bookmark/_index";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const ActionListViewBookmarkIcon = observer(
  (props: IBookmarkIconProps) => {
    const { lastSelectedRow, gridSelectedRows } = useCCProductListViewStore();

    return (
      <ListViewBookmarkIcon
        linkUrl={actionLaunchRoute(lastSelectedRow?.FormIdentifier_ENUM)}
        productType={props.productType}
        recordType={props.recordType}
        detail={props.detail}
        displayName={props.displayName}
        listViewDetail={props.listViewDetail}
        listViewDisplayName={props.listViewDisplayName}
        isDisabledRecordListView={props.isDisabledRecordListView}
        disabled={conditionDisableBookmarkAction(
          gridSelectedRows,
          lastSelectedRow
        )}
      />
    );
  }
);
