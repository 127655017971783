import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { TownPlanningFieldMapping } from "@app/products/town-planning/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colInbox: IColumnFields[] = [
  {
    field: TownPlanningFieldMapping.SpearReference,
    title: "SPEAR Reference",
    width: 300,
    locked: true,
    linkTo: (dataItem) => `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${dataItem.ID}`,
  },
  {
    field: TownPlanningFieldMapping.ApplicantName,
    title: "Applicant Name",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.ApplicationStatus,
    title: "Application Status",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.ApplicationType,
    title: "Application Type",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.CouncilName,
    title: "Council Name",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.FullAddress,
    title: "Address",
    width: 300,
  },
  {
    field: TownPlanningFieldMapping.SubmittedDate,
    title: "Submitted Date",
    width: 300,
    format: DATE_FORMAT.DATE,
  },
];
