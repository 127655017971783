import { DTO_Component_User_Field } from "@app/products/property/meters/[id]/components/child-screens/user-field/model";

export const mockUserField: DTO_Component_User_Field = {
  ItemData: {
    "100 Point Check": null,
    "1996 Land Value": 227000,
    "96 Reval Prop Num": 30000000003,
    "96 Revaluation Date": 10796,
    Area: null,
    "Area(SQM)": 522.7,
    "Ass Address Valid": null,
    "Ass BLG Unit Id 1": null,
    "Ass BLG Unit Prefix 1": null,
    "Ass Building Name": null,
    "Ass Clean Address": null,
    "Ass Complex Name": null,
    "Ass Floor No 1": null,
    "Ass Floor No 2": null,
    "Ass Floor Prefix 1": null,
    "Ass Floor Prefix 2": null,
    "Ass Floor Suff 1": null,
    "Ass Floor Suff 2": null,
    "Ass Floor Type": null,
    "Ass House No 1": null,
    "Ass House No 2": null,
    "Ass House Prefix 1": null,
    "Ass House Prefix 2": null,
    "Ass House Suff 1": null,
    "Ass House Suff 2": null,
    "Ass Locality": null,
    "Ass Postcode": null,
    "Ass Premises Type": null,
    "Ass Removed from Address": null,
    "Ass Side of Street": null,
    "Ass Street Name": null,
    "Ass Street Number": null,
    "Ass Street Suffix": null,
    "Ass Street Type": null,
    "Ass Unit Id 2": null,
    "Ass Unit Prefix 2": null,
    "Ass Unit Suff 1": null,
    "Ass Unit Suff 2": null,
    "Awaiting Response": null,
    "Base map number": null,
    "CCeS PCC DocID": null,
    "Census code": null,
    "Cert Plan Q2 Zone Code": null,
    "Check Digit Pre0506": null,
    CheckDigit: null,
    Classification: null,
    "Clause 12 applies": null,
    "Clause 18": null,
    "Conservation Item": "NO",
    "Contaminated a": null,
    "Contaminated b": null,
    "Contaminated c": null,
    "Contaminated d": null,
    "Contributory Item": "YES",
    County: null,
    "Date Sew Chge": null,
    "Date Wat Chge": null,
    "DCP 10 applies": null,
    "DCP 11 applies": null,
    "DCP 14 applies": null,
    "DCP 15 applies": null,
    "DCP 16 applies": null,
    "DCP 17 applies": null,
    "dcp 21 applies": null,
    "DCP number": "A",
    "DCP number Desc": "DCP Numbers 12/15",
    dimensions: "13.72 X 38.1",
    "Dup notice indicator": null,
    "Folio Number": null,
    "Follow Up Date": null,
    "Foreshore BL": null,
    "Foreshore BL Desc": null,
    FSPA: "NO",
    "Garb started": null,
    "Garbage Bin Ref Number": null,
    "GarbageZone 2015": 4,
    "GarbageZone 2015 Desc": "Thursday Zone A",
    "Has Draft LEP": null,
    "Heritage item": "NO",
    "Heritage item Desc": "Not Heritage",
    "Land for acquiition": null,
    "Land Use Code": null,
    "LOT/VOL/FOLIO": null,
    "Lot Number": null,
    "Merge Applicant Address1": null,
    "Merge Applicant Address2": null,
    "Merge Applicant Address3": null,
    "Merge Applicant Name": null,
    "Merge Balance OS": null,
    "Merge Current Bal": null,
    "Merge Discount Amount": null,
    "Merge Instalment Amount": null,
    "Merge Interest Bal": null,
    "Merge Letter Date": null,
    "Merge Letter Sent": null,
    "Merge Officer Init": null,
    "Merge Payment Amount": null,
    "Merge Payment Method": null,
    "Merge Pension Amount": null,
    "Merge Pension Dept": null,
    "Merge Purchase Details": null,
    "Merge Received Date": null,
    "Merge Special Read Date": null,
    "Merge Special Reading": null,
    "NewYearsEvePass IsEntitled": false,
    "Novated Lease Start Date": null,
    "NYEPass 2007": null,
    "NYEPass 2008": null,
    "NYEPass 2009": null,
    "NYEPass 2010": null,
    "NYEPass 2011": null,
    "NYEPass 2012": null,
    "NYEPass 2013": null,
    "NYEPass 2014": null,
    "NYEPass 2015": null,
    "NYEPass 2016": null,
    "NYEPass 2017": null,
    "Owner Name 1": "Balsom David",
    "Owner Name 2": "Balsom Jennifer Lee",
    "Owner Name 3": null,
    "Owner Name 4": null,
    "Owner Name 5": null,
    "Owner Name 6": null,
    Parish: null,
    "Parking comm": null,
    "Pensioner Indicator": null,
    "Postponed code": null,
    "Postponed code Desc": null,
    "Prev Improvments": null,
    "PREVIOUS PROP NUMBER": null,
    "Property File Number": "1005/20",
    "PROPERTY NAME": null,
    "Property Type": null,
    "Property status": null,
    "RBS Date Garb Started": null,
    "Resolution Rezoning": null,
    "Riding/Ward": "N",
    "Riding/Ward Desc": " North",
    "SREP 2005 item": "NO",
    "Street locality": null,
    Type: null,
    "Volume Number": null,
    "Water Connected": null,
    Zone: null,
  },
};
