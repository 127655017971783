import { CRSFieldMapping } from "@app/products/crs/model";
import { CRS_MANAGE_ROUTE } from "@app/products/crs/[id]/constant";
import { BOOLEAN_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { IColumnFields } from "@components/cc-grid/model";

export const colCRSFees4yoDeposit: IColumnFields[] = [
  {
    field: CRSFieldMapping.Registration_RefenceNo,
    title: "Application Number",
    locked: true,
    linkTo: (dataItem) => {
      return `${CRS_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  { field: CRSFieldMapping.Child_DisplayName, title: "Child Name" },
  //Note
  {
    field: CRSFieldMapping.Gender,
    title: "Deposit Charged",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: CRSFieldMapping.Deposit_Amount,
    title: "Deposit Amount",
  },
  {
    field: CRSFieldMapping.Deposit_Balance,
    title: "Deposit Balance",
  },
  {
    field: CRSFieldMapping.DebtorNumber,
    title: "Debtor Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.ServiceStatus_Name,
    title: "Status",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: CRSFieldMapping.YearAttending,
    title: "Year Att",
    format: NUMBER_FORMAT.NUMBER2,
  },
  { field: CRSFieldMapping.Parent1Name, title: "Parent Name" },
  { field: CRSFieldMapping.Parent1Phone, title: "Parent Phone" },
  {
    field: CRSFieldMapping.Parent1Address,
    title: "Parent Address",
  },
  { field: CRSFieldMapping.PreferredMethod, title: "Preference Method" },
];
