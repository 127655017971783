import {
  AnimalType,
  FeeRulesList,
  NonRegistrationFeeRule,
  RegistrationFeeAdditionalChargeRule,
  RegistrationFeeAdjustment,
  RegistrationFeeRule,
  SVC_FeeCalculator_RegistrationFee_AdjustmentType,
} from "@app/products/animals/model";
import {
  SVC_FeeCalculator_NonRegistrationFee_RuleType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType,
  SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType,
  SVC_FeeCalculator_RegistrationFee_RuleType,
} from "@app/products/animals/system-admin/animal-types/[id]/model";
import { DATE_FORMAT, DECIMAL_FORMAT } from "@common/constants/common-format";
import {
  formatCurrency,
  formatDateByKendo,
  formatNumberDynamic,
} from "@common/utils/formatting";

// Get name of field
export const getNameOfAnimalType = (
  animalTypeKey: keyof AnimalType,
  feeRulesListKey: keyof FeeRulesList
) => {
  return `${animalTypeKey}.${feeRulesListKey}`;
};

export const getNameOfAdjustmentType = (
  registrationFeeRuleTypeKey: keyof RegistrationFeeRule,
  adjustmentTypeKey: keyof RegistrationFeeAdjustment
) => {
  return `${registrationFeeRuleTypeKey}.${adjustmentTypeKey}`;
};

// Get friendly name
export const getRegistrationFeeRuleTypeFriendlyName = (
  feeRuleTypeEnum: SVC_FeeCalculator_RegistrationFee_RuleType
) => {
  const registrationFeeRulesFriendlyNames: Record<
    SVC_FeeCalculator_RegistrationFee_RuleType,
    string
  > = {
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Standard]: "Standard",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Sterilized]:
      "Sterilized",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Over10Years]:
      "Over 10 Years",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Under1Year]:
      "Under 1 Years",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_ClubMember]:
      "Club member",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Microchipped]:
      "Microchipped",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Obedience]:
      "Obedience Certificate",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_GuideDog]: "Guide Dog",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Customs]: "Customs",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Working]: "Working Dog",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Organisation]:
      "Organisation",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_PureBred]: "Pure-bred",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Greyhound]: "Greyhound",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_DogsHomeAdoption]:
      "Dogs Home Adoption",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Pensioner]: "Pensioner",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Dangerous]: "Dangerous",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Menacing]:
      "Menacing (Guard Dog)",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_HuntingDog]:
      "Hunting Dog",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Transferred]:
      "Transferred",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Standard_Pensioner]:
      "Standard + Pensioner",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_Sterilized_Pensioner]:
      "Sterilized + Pensioner",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_CertifiedAssistance]:
      "Certified Assistance",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_RestrictedBreed]:
      "Restricted Breed",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Default]: "",
    [SVC_FeeCalculator_RegistrationFee_RuleType.Animal_GuardDog]: "",
  };
  return registrationFeeRulesFriendlyNames[feeRuleTypeEnum] || "";
};

export const getRFAdditionalChargeRulesFriendlyName = (
  feeRuleTypeEnum: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType
) => {
  const additionalChargeRulesFriendlyNames: Record<
    SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType,
    string
  > = {
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Animal_Government]:
      "Government",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Animal_Obedience]:
      "Obedience Certificate",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Animal_Pensioner]:
      "Pensioner",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Animal_HealthCareCard]:
      "Health Care Card",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Working_Dog]:
      "Working Dog",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_RuleType.Default]: "",
  };
  return additionalChargeRulesFriendlyNames[feeRuleTypeEnum] || "";
};

export const getAdditionalChargeAdjustmentTypeFriendlyName = (
  adjustmentTypeEnum: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType
) => {
  const additionalChargeRulesDescriptions: Record<
    SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType,
    string
  > = {
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.Add]:
      "Add Amount ($)",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.Deduct]:
      "Deduct Amount ($)",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.PercentAddition]:
      "Add Amount (%)",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.PercentDeduction]:
      "Deduct Amount (%)",
    [SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.Default]:
      "",
  };
  return additionalChargeRulesDescriptions[adjustmentTypeEnum] || "";
};

export const getNonRegistrationFeeRuleTypeFriendlyName = (
  adjustmentTypeEnum: SVC_FeeCalculator_NonRegistrationFee_RuleType
) => {
  const nonRegistrationFeeRulesFriendlyNames: Record<
    SVC_FeeCalculator_NonRegistrationFee_RuleType,
    string
  > = {
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_NewTag]: "New Tag",
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_ReplacementTag]:
      "Replacement Tag",
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_TransferFee]:
      "Transfer Fee",
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_LifetimeTag]:
      "Lifetime Tag",
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_ComplaintFee]:
      "Complaint Fee",
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Animal_PenaltyFee]:
      "Penalty Fee",
    [SVC_FeeCalculator_NonRegistrationFee_RuleType.Default]: "",
  };
  return nonRegistrationFeeRulesFriendlyNames[adjustmentTypeEnum] || "";
};

export const getAdjustmentTypeFriendlyName = (
  adjustmentTypeEnum: SVC_FeeCalculator_RegistrationFee_AdjustmentType
) => {
  const adjustmentTypeFriendlyName: Record<
    SVC_FeeCalculator_RegistrationFee_AdjustmentType,
    string
  > = {
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.DateAfter]: "Date After",
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.Add]: "Add Amount ($)",
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.Deduct]:
      "Deduct Amount ($)",
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.PercentAddition]:
      "Add Amount (%)",
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.PercentDeduction]:
      "Deduct Amount (%)",
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.PensionerOverride]:
      "Pensioner Override",
    [SVC_FeeCalculator_RegistrationFee_AdjustmentType.Default]: "",
  };
  return adjustmentTypeFriendlyName[adjustmentTypeEnum] || "";
};

// Generate data for fee rules
export const generateRegistrationFeeRuleData = (
  feeRules: RegistrationFeeRule[]
) => {
  return feeRules?.map((item: RegistrationFeeRule) => ({
    RuleType_Enum: item.RuleType_ENUM,
    SortOrder: item.SortOrder,
    RuleType_Name: getRegistrationFeeRuleTypeFriendlyName(item.RuleType_ENUM),
    Description: item.Description,
    IsPriority: item.IsPriority ? 0 : 1,
  }));
};

export const generateRFAdditionalChargeRulesData = (
  feeRules: RegistrationFeeAdditionalChargeRule[]
) => {
  return feeRules?.map((item: RegistrationFeeAdditionalChargeRule) => ({
    RuleType_Enum: item.AdditionalChargeRuleType_ENUM,
    RuleType_Name: getRFAdditionalChargeRulesFriendlyName(
      item.AdditionalChargeRuleType_ENUM
    ),
    Description: `${getAdditionalChargeAdjustmentTypeFriendlyName(
      item.AdjustmentType_ENUM
    )} ${formatCurrency(item.Amount)}`,
  }));
};

export const generateNonRegistrationFeeRuleData = (
  feeRules: NonRegistrationFeeRule[]
) => {
  return feeRules?.map((item: NonRegistrationFeeRule) => ({
    RuleType_Enum: item.NonRegistrationFeeRuleType_ENUM,
    RuleType_Name: getNonRegistrationFeeRuleTypeFriendlyName(
      item.NonRegistrationFeeRuleType_ENUM
    ),
    Description: `${getNonRegistrationFeeRuleTypeFriendlyName(
      item.NonRegistrationFeeRuleType_ENUM
    )} ${formatNumberDynamic(item.Amount, DECIMAL_FORMAT.DECIMAL_2_DIGIT)} ${
      item.AddGST ? ", Add GST" : ""
    }`,
  }));
};

// Generate Description base on item data
export const generateAdjustmentDescription = (
  adjustment: RegistrationFeeAdjustment
) => {
  let description = "";
  if (
    adjustment.AdjustmentType_ENUM ===
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.Default
  )
    return description;

  if (
    adjustment.AdjustmentType_ENUM ===
      SVC_FeeCalculator_RegistrationFee_AdjustmentType.DateAfter &&
    adjustment.Adjustment_Date
  ) {
    description +=
      (description ? ", " : "") +
      "After " +
      formatDateByKendo(adjustment.Adjustment_Date, DATE_FORMAT.DATE) +
      " Use " +
      formatCurrency(adjustment.Adjustment_Amount ?? 0);
  } else if (
    adjustment.AdjustmentType_ENUM ===
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.PensionerOverride
  ) {
    description +=
      (description ? ", " : "") +
      getAdjustmentTypeFriendlyName(adjustment.AdjustmentType_ENUM) +
      ", Use " +
      formatCurrency(adjustment.Adjustment_Amount ?? 0);
  } else {
    if (adjustment.Adjustment_Label) {
      description += (description ? ", " : "") + adjustment.Adjustment_Label;
    }
    if (adjustment.Adjustment_Amount) {
      description +=
        (description ? ", " : "") +
        getAdjustmentTypeFriendlyName(adjustment.AdjustmentType_ENUM) +
        " " +
        formatNumberDynamic(
          adjustment.Adjustment_Amount,
          DECIMAL_FORMAT.DECIMAL_2_DIGIT
        );
    }
  }
  return description;
};

export const generateRegistrationFeeRuleDescription = (
  feeRule: RegistrationFeeRule
) => {
  let description = "";
  if (feeRule.IsPriority) {
    description += (description ? ", " : "") + "Priority Rule";
  }

  description +=
    (description ? ", " : "") + "Base = " + formatCurrency(feeRule.BaseAmount);

  if (
    feeRule.Adjustment1 != null &&
    feeRule.Adjustment1.AdjustmentType_ENUM !==
      SVC_FeeCalculator_RegistrationFee_AdjustmentType.Default
  ) {
    description +=
      (description ? ", " : "") +
      "Adjustment 1: " +
      feeRule.Adjustment1.Description;
  }
  if (
    feeRule.Adjustment2 != null &&
    feeRule.Adjustment2.AdjustmentType_ENUM !==
      SVC_FeeCalculator_RegistrationFee_AdjustmentType.Default
  ) {
    description +=
      (description ? ", " : "") +
      "Adjustment 2: " +
      feeRule.Adjustment2.Description;
  }
  description += feeRule.EnableRVP
    ? " Valid only between :(" +
      feeRule.RVPStartDay +
      "/" +
      feeRule.RVPStartMon +
      ") and (" +
      feeRule.RVPEndDay +
      "/" +
      feeRule.RVPEndMon +
      ")"
    : "";
  return description;
};

//  Check the field is percent type
export const checkShowAdjustmentLabel = (
  adjustmentType: SVC_FeeCalculator_RegistrationFee_AdjustmentType
) => {
  const adjustmentTypeToShowLabel = [
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.Add,
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.Deduct,
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.PercentAddition,
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.PercentDeduction,
  ];
  return adjustmentTypeToShowLabel.includes(adjustmentType);
};

export const checkRegistrationAdjustmentAllowPercent = (
  adjustmentType: SVC_FeeCalculator_RegistrationFee_AdjustmentType
) => {
  const adjustmentTypeAllowPercent = [
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.PercentAddition,
    SVC_FeeCalculator_RegistrationFee_AdjustmentType.PercentDeduction,
  ];
  return adjustmentTypeAllowPercent.includes(adjustmentType);
};

export const checkAdditionalChargeRuleAdjustmentAllowPercent = (
  adjustmentType: SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType
) => {
  const adjustmentTypeAllowPercent = [
    SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.PercentAddition,
    SVC_FeeCalculator_RegistrationFee_AdditionalCharge_AdjustmentType.PercentDeduction,
  ];
  return adjustmentTypeAllowPercent.includes(adjustmentType);
};
