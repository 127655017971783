import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { DTO_AssociatedItem_AssessmentRebateEntitlement } from "@app/products/property/components/associations/components/assessment-rebate-entitlement/model";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_AssociatedItem_AssessmentRebateEntitlement>();
export const colContactAssociationAssessmentRebateEntitlement: IColumnFields[] =
  [
    {
      field: nameOf("AssessmentNumber"),
      title: "Assessment Number",
      linkTo: (dataItem: DTO_AssociatedItem_AssessmentRebateEntitlement) =>
        `${PROPERTY_ASSESSMENT_ROUTE}/${dataItem.Assessment_Id}`,
    },
    {
      field: nameOf("Association"),
      title: "Association",
    },
    {
      field: nameOf("PropertyName_Address_Locality"),
      title: "Property Address",
    },
    {
      field: nameOf("RebateType"),
      title: "Rebate Type",
    },
    {
      field: nameOf("Percentage"),
      title: "Percentage of Full Rebate",
      format: PERCENTAGE_FORMAT.PERCENTAGE,
    },
    {
      field: nameOf("ApplicationDate"),
      title: "Application Date",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("EffectiveDate"),
      title: "Effective Date",
      format: DATE_FORMAT.DATE,
    },
    {
      field: nameOf("EntitledToAlternateRebateCap"),
      title: "Alternate Rebate Cap",
      format: BOOLEAN_FORMAT.BOOLEAN,
    },
    {
      field: nameOf("EntitledEntitiesAsString"),
      title: "Entitled Contacts",
    },
    {
      field: nameOf("ChangedOn"),
      title: "Changed On",
      format: DATETIME_FORMAT.DATETIME,
    },
    {
      field: nameOf("ChangedBy"),
      title: "Changed By",
    },
    {
      field: nameOf("Assessment_Id"),
      title: "Assessment ID",
      format: NUMBER_FORMAT.NUMBER2,
      style: formatStyleUnRelevant,
    },
    {
      field: nameOf("Id"),
      title: "ID",
      format: NUMBER_FORMAT.NUMBER2,
      style: formatStyleUnRelevant,
    },
  ];
