import { ApplicantColumns } from "@app/products/building/model";
import { CSLUrl } from "@app/products/csl/config";
import { FormRenderProps } from "@progress/kendo-react-form";

export const appNoFields = (formRenderProps: FormRenderProps) => ({
  titleHeader: "Pick Application Number",
  readOnly: true,
  placeholder: "Application Number",
  tableTypes: ApplicantColumns,
  url: CSLUrl.GET_CLS_REGISTER_EASEMENTS_APPLITION,
  fieldName: "AppNo",
  value: formRenderProps.valueGetter("AppNo"),
  onChange: (event: CustomEvent) => {
    formRenderProps.onChange("AppNo", {
      value: event.detail.Name,
    });
  },
});
