import { DATETIME_FORMAT } from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { requiredValidator } from "@common/utils/field-validators";
import { CCLabel } from "@components/cc-label/_index";
import { CCTimePicker } from "@components/cc-time-picker/_index";
import { Field } from "@progress/kendo-react-form";
import React from "react";

interface IApplicationSettingTextProps {
  enumParam: ECorporateSettingsField;
}

export const GlobalSettingDateTime = (props: IApplicationSettingTextProps) => {
  const { configData } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { enumParam } = props;

  if (!configData) return <></>;
  return configData[enumParam.toString()] ? (
    <div className="cc-field">
      <CCLabel
        title={configData[enumParam.toString()].Title ?? ""}
        isMandatory={configData[enumParam.toString()].IsMandatory}
      />
      <Field
        name={configData[enumParam.toString()].FieldName}
        component={CCTimePicker}
        format={DATETIME_FORMAT.TIME}
        disabled={!isEditing}
        validator={
          configData[enumParam.toString()].IsMandatory
            ? requiredValidator
            : undefined
        }
      />
    </div>
  ) : null;
};
