import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { settingsRoute } from "@common/pages/settings/route";
import { SecurityTemplateTabDetails } from "@common/pages/settings/security/security-templates/_id/components/reference-sidebar/detail/_index";
import { SecurityTemplateTabHistory } from "@common/pages/settings/security/security-templates/_id/components/reference-sidebar/history/_index";
import { AddSiteUsersSTButton } from "@common/pages/settings/security/security-templates/components/action-bar/buttons/add-user";
import { DeactiveSecurityTemplateButton } from "@common/pages/settings/security/security-templates/components/action-bar/buttons/deactive-templates/_index";
import { NewSecurityTemplateButton } from "@common/pages/settings/security/security-templates/components/action-bar/buttons/new/_index";
import { RemoveSiteUsersSTButton } from "@common/pages/settings/security/security-templates/components/action-bar/buttons/remove-user";
import { colSecurityTemplates } from "@common/pages/settings/security/security-templates/config";
import {
  SECURITY_TEMPLATE_ODATA,
  SECURITY_TEMPLATE_ROUTE,
} from "@common/pages/settings/security/security-templates/constant";
import { SecurityTemplate } from "@common/pages/settings/security/security-templates/model";
import { securityTemplate } from "@common/pages/settings/security/security-templates/utils";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<SecurityTemplate>();

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    const responseCheckGlobalSettings = await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );

    if (responseCheckGlobalSettings) {
      await checkPermissionForCurrentMenu(
        FormActionCheckPermission.SECURITY_MENU,
        1
      );
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <NewSecurityTemplateButton disabled={!hasPermission} />,
      <CCNavButton title={"Tools"} type="sub" disabled={!hasPermission}>
        <AddSiteUsersSTButton />
        <RemoveSiteUsersSTButton />
      </CCNavButton>,
      <DeactiveSecurityTemplateButton disabled={!hasPermission} />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SECURITY_TEMPLATE_ROUTE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_SecurityTemplate}
        detail={securityTemplate.getBookmarkDetail}
        displayName={securityTemplate.getBookmarkDisplayName}
        listViewDetail={securityTemplate.getBookmarkListViewDetail}
        listViewDisplayName={securityTemplate.getBookmarkListViewDisplayName}
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <SecurityTemplateTabDetails /> },
      {
        title: "History",
        component: <SecurityTemplateTabHistory />,
      },
    ],
  });

  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={colSecurityTemplates}
      dataUrl={SECURITY_TEMPLATE_ODATA}
      primaryField={nameOf("SecurityTemplate_ID")}
      disabled={!hasPermission}
      state={{
        sort: [
          { field: nameOf("ProductType_Name"), dir: "asc" },
          { field: nameOf("TemplateName"), dir: "asc" },
        ],
      }}
    />
  );
};
