import { OptionButton } from "@app/products/property/assessments/financial-summaries/components/manage-dialog/components/general-form-dialog/model";
import { IFinancialSummariesFormData } from "@app/products/property/assessments/financial-summaries/model";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { ReactElement, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PropertyFinancialSummariesFormElement } from "./components/form-element/_index";

interface Props {
  isNew?: boolean;
  title: string;
  formData: IFinancialSummariesFormData | undefined;
  onClose: () => void;
}
// interface IContentNotification {
//   msgContent: string;
//   typeNotification: TYPE_NOTIFICATION;
// }
export const URL_FINANCIAL_SUMMARIES =
  "/property/assessments/financial-summaries";
export function PropertyFinancialSummariesForm({
  isNew = false,
  title: header,
  formData: initialValues,
  onClose,
}: Props): ReactElement {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const history = useHistory();

  const params: { id: string } = useParams();
  const financialId = params.id;
  const handleSubmit = (values: any, event: any) => {
    if (event.target.name === OptionButton.SAVE) {
      if (financialId) {
        history.push(URL_FINANCIAL_SUMMARIES);
      }
    } else if (event.target.name === OptionButton.RUN) {
      if (isNew) {
        history.push(URL_FINANCIAL_SUMMARIES + `/101`);
      } else {
        if (!financialId) {
          history.push(URL_FINANCIAL_SUMMARIES + `/${initialValues?.ID}`);
        }
      }
    }
    onClose();
  };

  const validateForm = (event: any, formRenderProps: FormRenderProps) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    notificationRef.current?.pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };

  return (
    <Form
      onSubmit={(values: any, event: any) => handleSubmit(values, event)}
      initialValues={initialValues}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <CCDialog
            maxWidth="60%"
            maxHeight="70%"
            titleHeader={header}
            onClose={onClose}
            bodyElement={
              <div className="cc-form">
                <CCLocalNotification ref={notificationRef} />
                <PropertyFinancialSummariesFormElement
                  formRenderProps={formRenderProps}
                />
              </div>
            }
            footerElement={
              <React.Fragment>
                <div className="cc-dialog-footer-actions-right">
                  <Button
                    className="cc-dialog-button"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    themeColor="primary"
                    type={"submit"}
                    name="save"
                    className="cc-dialog-button"
                    onClick={(e: any) => {
                      validateForm(e, formRenderProps);
                    }}
                  >
                    SAVE
                  </Button>
                  <Button
                    themeColor="primary"
                    type={"submit"}
                    name="run"
                    className="cc-dialog-button"
                    onClick={(e: any) => {
                      validateForm(e, formRenderProps);
                    }}
                  >
                    RUN
                  </Button>
                </div>
              </React.Fragment>
            }
          />
        </FormElement>
      )}
    />
  );
}
