import { PROPERTY_SCHEMES_ACCOUNT_ROUTE } from "@app/products/property/schemes/[id]/account/[id]/constant";
import { IAccountInTheScheme } from "@app/products/property/schemes/[id]/components/child-screens/accounts/model";
import { PROPERTY_SCHEMES_ROUTE } from "@app/products/property/schemes/[id]/constant";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colAccountsInTheScheme = (id: string): IColumnFields[] => [
  {
    field: "AccountNumber",
    title: "Account Number",
    locked: true,
    linkTo: (dataItem: IAccountInTheScheme) => {
      return (
        `${PROPERTY_SCHEMES_ROUTE}/${id}${PROPERTY_SCHEMES_ACCOUNT_ROUTE}/` +
        dataItem.SchemeAccountId
      );
    },
    footerCell: <TextCell value={"Overall Total"} />,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
  },
  {
    field: "PropertyAddress",
    title: "Property Address",
  },
  {
    field: "AccountName",
    title: "Account Name",
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PrincipalBalance", gridData),
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("InterestBalance", gridData),
  },
  {
    field: "OtherBalances",
    title: "Other Balances",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OtherBalances", gridData),
  },
  {
    field: "BalanceOutstanding",
    title: "Balance Outstanding",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("BalanceOutstanding", gridData),
  },
  {
    field: "AmountDue",
    title: "Amount Due",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("AmountDue", gridData),
  },
  {
    field: "PayoutAmount",
    title: "Payout Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PayoutAmount", gridData),
  },
  {
    field: "DiscountAmountOnPayout",
    title: "Discount Amount on Payout",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("DiscountAmountOnPayout", gridData),
  },
  {
    field: "NumberOfInstalments",
    title: "Number of Instalments",
    format: NUMBER_FORMAT.NUMBER1,
  },

  {
    field: "InstalmentType",
    title: "Instalment Type",
  },
  {
    field: "AccountStatus",
    title: "Account Status",
  },
  {
    field: "OverpaymentsToComeOffPrincipal",
    title: "Overpayments to Come Off Principal",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "EstimatedShareOfCosts",
    title: "Estimated Share of Costs",
    format: CURRENCY_FORMAT.CURRENCY1,
    footerCell: <CurrencyCell style={formatStyleProminent} />,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("EstimatedShareOfCosts", gridData),
  },
  {
    field: "EstimatedShareOfPercentageCosts",
    title: "Estimated Share of Percentage Costs",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: "LastChangedBy",
    title: "Last Changed By",
  },
  {
    field: "LastChangedOn",
    title: "Last Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    style: formatStyleUnRelevant,
  },
  {
    field: "SchemeAccountId",
    title: "Scheme Account ID",
    style: formatStyleUnRelevant,
  },
];
