import { ExistedPropertyScheme } from "@app/products/property/schemes/[id]/components/forms/existed/_index";
import { NewPropertyScheme } from "@app/products/property/schemes/[id]/components/forms/new/_index";
import { useSchemesStore } from "@app/products/property/schemes/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

function ManagePropertyScheme() {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const schemeId = params.id;
  const { loadSchemes, resetStore } = useSchemesStore();

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  useEffect(() => {
    loadSchemes(+schemeId, isNew);
  }, [isNew, schemeId, loadSchemes]);

  if (isNew) {
    return <NewPropertyScheme />;
  }
  return <ExistedPropertyScheme />;
}

export default ManagePropertyScheme;
