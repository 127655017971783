import { RefObject } from "react";

export const scrollToView = (ref?: RefObject<any>, config?: Object) => {
  const defaultConfig = {
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  };
  return (
    ref && ref.current && ref.current?.scrollIntoView(config ?? defaultConfig)
  );
};
