import { Fee } from "@app/products/town-planning/ppr/[id]/model";

export interface Fees {
  ID: number;
  RecordType_Id: string;
  InvoiceDate: Date | null;
  ServiceDate: Date | null;
  Type: string;
  CodeCost: string;
  Amount: number;
  Owing: number;
  Invoice: string;
  ExportedToFinance: boolean | null;
  Description: string;
  GSTFee: number;
  ItemType: string;
  FeeTypeProductId: string;
  SubsidyTypeName: string;
  SubsidyProductId: string;
  SubsidyAmount: number | null;
  FinalAmount: number | null;
  U2GFeeOS: number;
  PaidInFull: boolean;
}

export interface Svc_ViewConfiguration {
  ColumnDefinitions: Svc_IViewColumn[];
}

export interface Svc_IViewColumn {
  ColumnName: string;
  DataColumn: string;
  IsHidden: boolean;
  IsCurrency: boolean | null;
  DecimalPlaces: number | null;
}

export enum EViewConfiguration {
  TabTable_Fees = 0,
}

export interface Svc_FinanceFee {
  FinanceFee: Fee;
  TotalPaymentAmount: number | null;
  UpdateFinanceStagingFee: boolean | null;
}
