import { DATE_FORMAT } from "@common/constants/common-format";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { DescriptionLabel } from "@components/description-label/DescriptionLabel";
import { SettingButtonEdit } from "@components/setting/setting-button-edit/_index";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { FormRenderProps } from "@progress/kendo-react-form";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import React from "react";
import "./_index.scss";
interface IProps {
  name: string;
  value: Date;
  label: string;
  isMandatory?: boolean;
  disabled?: boolean;
  description?: string;
  onChange?: (arg0: DatePickerChangeEvent) => void;
  isEnabledEditButton?: boolean;
  formRenderProps?: FormRenderProps;
  handleOnEdit?: any;
  handleOnCancel?: any;
  isLoading?: boolean;
}

export const SettingDateInput: React.FC<IProps> = ({
  name,
  label,
  value = null,
  isMandatory,
  disabled,
  onChange,
  description = "",
  isEnabledEditButton,
  handleOnCancel = () => {},
  handleOnEdit = () => {},
  formRenderProps = undefined,
  isLoading,
  ...others
}) => {
  return (
    <div className="cc-setting-date-input">
      <div className="cc-label-flex">
        <DescriptionLabel title={label} description={description} />
        {isMandatory && <CCTooltip type="validator" position="right" />}
      </div>
      <div className={`${isEnabledEditButton ? "cc-field-flex" : ""}`}>
        <div className="cc-date-input">
          <LocalizationProvider language="en-AU">
            <IntlProvider locale="en">
              <CCDatePicker
                name={name}
                format={DATE_FORMAT.DATE_CONTROL}
                value={value}
                disabled={disabled ?? false}
                onChange={onChange}
                {...others}
              />
            </IntlProvider>
          </LocalizationProvider>
        </div>
        {isEnabledEditButton ? (
          <SettingButtonEdit
            isEditing={!disabled}
            handleOnEdit={handleOnEdit}
            handleOnCancel={handleOnCancel}
            formRenderProps={formRenderProps}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};
