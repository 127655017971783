import { history } from "@/AppRoutes";
import {
  getTownPlanningSummaryById,
  postTownPlanning,
  putTownPlanning,
} from "@app/products/town-planning/[id]/api";
import {
  TownPlanningRegisterForm,
  TownPlanningRegisterRequest,
} from "@app/products/town-planning/[id]/model";
import { convertTownPlanningResponseToForm } from "@app/products/town-planning/[id]/util";
import { TOWN_PLANNING_ROUTE } from "@app/products/town-planning/contant";
import { getKeywordsByType } from "@common/apis/coreKeyword";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { KeywordLite } from "@common/models/keyword";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { ICCNotification } from "@components/cc-notification/components/cc-notification-item/model";
import { isArray } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });

class TownPlanningStore {
  private _townPlanning?: TownPlanningRegisterForm = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _notifications: ICCNotification[] = [];
  private _zoneKeywordList: KeywordLite[] = [];
  private _overlayKeywordList: KeywordLite[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get townPlanning() {
    return this._townPlanning;
  }
  setTownPlanning = (townPlanning?: TownPlanningRegisterForm | undefined) => {
    runInAction(() => {
      this._townPlanning = townPlanning;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get notifications() {
    return this._notifications;
  }
  setNotifications = (notifications: ICCNotification[]) => {
    runInAction(() => {
      this._notifications = notifications;
    });
  };

  get townPlanningId() {
    return this.townPlanning?.Application_ID;
  }

  get zoneKeywordList() {
    return toJS(this._zoneKeywordList);
  }
  setZoneKeywordList = (zoneKeywordList?: KeywordLite[]) => {
    runInAction(() => {
      this._zoneKeywordList = zoneKeywordList ?? [];
    });
  };

  get overlayKeywordList() {
    return toJS(this._overlayKeywordList);
  }
  setOverlayKeywordList = (overlayKeywordList?: KeywordLite[]) => {
    runInAction(() => {
      this._overlayKeywordList = overlayKeywordList ?? [];
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._townPlanning = undefined;
      this._isLoading = false;
      this._notifications = [];
      this._responseLoadError = undefined;
      this._zoneKeywordList = [];
      this._overlayKeywordList = [];
    });
  };

  loadTownPlanning = async (townPlanningId: string) => {
    this.setIsLoading(true);
    const response = await getTownPlanningSummaryById(townPlanningId);
    let errorResponse = undefined;
    let newTownPlanning = undefined;
    if (isSuccessResponse(response)) {
      if (response.data) {
        newTownPlanning = convertTownPlanningResponseToForm(response.data);
      }
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setTownPlanning(newTownPlanning);
    this.setIsLoading(false);
  };

  createTownPlanning = async (townPlanning: TownPlanningRegisterRequest) => {
    this.setIsLoading(true);
    const response = await postTownPlanning(townPlanning);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Town planning created successfully",
        type: "success",
        onClose: () => {
          history.push(
            `${TOWN_PLANNING_ROUTE.MANAGE_ROUTE}/${
              response.data?.Application_ID ?? 68686868
            }`
          );
        },
      });
    } else {
      appNotificationStore.pushNotification({
        title: response?.error ?? "Town planning create failed",
        type: "error",
        autoClose: false,
      });
    }
    this.setIsLoading(false);
  };

  updateTownPlanning = async (townPlanning: TownPlanningRegisterRequest) => {
    this.setIsLoading(true);
    const response = await putTownPlanning(townPlanning);
    if (isSuccessResponse(response)) {
      appNotificationStore.pushNotification({
        title: "Town planning updated successfully",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        title: response?.error ?? "Town planning update failed",
        type: "error",
        autoClose: false,
      });
    }
    this.setIsLoading(false);
  };

  loadZoneAndOverlayKeywords = async () => {
    const response = await Promise.all([
      getKeywordsByType(
        KEYWORD_TYPE.TP_AssessmentPlanner_Zone,
        PRODUCT_TYPE_NUMBER.TownPlanning
      ),
      getKeywordsByType(
        KEYWORD_TYPE.TP_AssessmentPlanner_Overlay,
        PRODUCT_TYPE_NUMBER.TownPlanning
      ),
    ]);

    if (isArray(response)) {
      const [zoneKWResponse, overlayKWResponse] = response;
      this.setZoneKeywordList(zoneKWResponse?.data?.value ?? []);
      this.setOverlayKeywordList(overlayKWResponse?.data?.value ?? []);
    }
  };
}

const townPlanningStoreContext = createContext(new TownPlanningStore());
export const useTownPlanningStore = () => {
  return useContext(townPlanningStoreContext);
};
