import {
  IRouteStateForm,
  routeStateGlobalConfig,
} from "@common/pages/settings/system-admin/global-settings/_id/main/routeState";
import { NoData } from "@components/no-data/NoData";
import { FormRenderProps } from "@progress/kendo-react-form";
import { filter } from "lodash";
import React, { memo } from "react";

export interface IGlobalComponentFormProps {
  formRenderProps: FormRenderProps;
  setObjectCustomSubmit: (dataOfState: (data: any) => void) => void;
  dataSetting: any;
}

export const GlobalComponentSettingForm = memo(
  ({
    formRenderProps,
    setObjectCustomSubmit,
    dataSetting,
  }: IGlobalComponentFormProps) => {
    const handleSwitchRouteLoadComponent = (
      dataSetting: any,
      routeStateGlobalConfig: IRouteStateForm[],
      formRenderProps: FormRenderProps,
      setObjectCustomSubmit: (dataOfState: (data: any) => void) => void
    ) => {
      let componentActive = <NoData vertical />;
      const settingActive = filter(routeStateGlobalConfig, {
        name: dataSetting?.Name,
      });
      if (settingActive.length) {
        const ComponentActive = settingActive[0]?.component;
        componentActive = (
          <ComponentActive
            formRenderProps={formRenderProps}
            setObjectCustomSubmit={setObjectCustomSubmit}
            dataSetting={dataSetting}
          />
        );
      }
      return componentActive;
    };

    return handleSwitchRouteLoadComponent(
      dataSetting,
      routeStateGlobalConfig,
      formRenderProps,
      setObjectCustomSubmit
    );
  }
);
