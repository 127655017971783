import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.Parcel_Id
    ? `- ${selectedRow.Parcel_Id}`
    : "";
  return `Property - Parcels ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([
    selectedRow.Legal_Description,
    selectedRow.Property_Name_Address_Locality,
  ]);
};
