import { useAssessmentTransferStore } from "@app/products/property/assessments/[id]/components/child-screens/transfer/store";
import { CancelOwnerChange } from "@app/products/property/changes-of-ownership/list/components/dialogs/cancel_owner_change/_index";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const CancelOwnerChangeButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { transferSelected } = useAssessmentTransferStore();

  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Assessment_Cancel_Owner_Change}
      >
        <CCNavButton
          title="Cancel owner change"
          disabled={
            !transferSelected || transferSelected?.Status === "Cancelled"
          }
          onClick={() => setIsShowDialog(true)}
        />
      </PrivilegeWrapper>
      {isShowDialog && (
        <CancelOwnerChange
          onClose={() => {
            setIsShowDialog(false);
          }}
          changeOfOwnershipID={transferSelected?.Id ?? 0}
        />
      )}
    </>
  );
});
