import { useAssessmentMeterStore } from "@app/products/property/assessments/[id]/components/child-screens/meters/store";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { RemoveMeterDialog } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/dialogs/remove-meter/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const RemoveMeterButton = observer(() => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { meterSelected } = useAssessmentMeterStore();
  const location = useLocation();

  const isDisable = useMemo(() => {
    const path = /(.+)(\/\d+)/gi.exec(location.pathname) || [];
    return path[1] === PROPERTY_ASSESSMENT_ROUTE && meterSelected.length !== 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, meterSelected]);

  return (
    <>
      <CCNavButton
        title="Remove meter"
        disabled={isDisable}
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <RemoveMeterDialog onClose={() => setIsShowDialog(false)} />
      )}
    </>
  );
});
