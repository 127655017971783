export const PREMISES_MANAGE_ROUTE = "/hm/premises";
export const TITLE_HM_PREMISES_MANAGE_PAGE = "Premises";
export const HM_PREMISES_HANDLER_SLIDER = "hmPremisesHandlerSlider";
export const HM_PREMISES_GET_SLIDER = "hmPremisesGetByIdSlider";
export const HM_PREMISES_REGISTER_GET_INIT_SLIDER =
  "hmPremisesRegisterGetInitSlider";
export const HM_PREMISES_REGISTER_SLIDER = "hmPremisesRegisterSlider";
export const HM_PREMISES_RENEWAL_OPTION_DIALOG_PLACE_ID =
  "hmPremisesRenewalOptionDialogPlaceId";
export const HM_PREMISES_CLOSE_DIALOG_PLACE_ID = "hmPremisesCloseDialogPlaceId";
