import { getNoticeRunsDetailTab } from "@app/products/property/deferred-duty/notice-runs/[id]/components/reference-sidebar/details/api";
import { IDeferredDutyNoticeRun } from "@app/products/property/deferred-duty/notice-runs/[id]/components/reference-sidebar/details/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const DeferredDutyNoticeRunDetailTab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const productListViewStore = useCCProductListViewStore();
  const { lastSelectedRow } = productListViewStore;
  const [noticeRunsInfo, setNoticeRunsInfo] = useState<
    IDeferredDutyNoticeRun | undefined
  >();
  const params: { id: string } = useParams();
  const noticeRunId: number = params.id
    ? parseInt(params.id)
    : lastSelectedRow?.DDS_Notice_Run_Id;
  useEffect(() => {
    if (noticeRunId) {
      setIsLoading(true);
      getNoticeRunsDetailTab(noticeRunId).then((data) => {
        setNoticeRunsInfo(data);
        setIsLoading(false);
      });
    }
  }, [noticeRunId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Notice Run ID:"}
          value={noticeRunsInfo?.NoticeRunID}
        />
        <ProductReferenceRow title={"Run:"} value={noticeRunsInfo?.Run} />
        <ProductReferenceRow
          title={"Issue Date:"}
          value={formatDisplayValue(
            noticeRunsInfo?.IssueDate,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Period Start:"}
          value={formatDisplayValue(
            noticeRunsInfo?.PeriodStart,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Period End:"}
          value={formatDisplayValue(
            noticeRunsInfo?.PeriodEnd,
            DATE_FORMAT.DATE
          )}
        />
        <ProductReferenceRow
          title={"Create By:"}
          value={noticeRunsInfo?.CreateBy}
        />
        <ProductReferenceRow
          title={"Create On:"}
          value={formatDisplayValue(noticeRunsInfo?.CreateOn, DATE_FORMAT.DATE)}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
};
