import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { adminRoute } from "./admin/route";
import { bookingsRoute } from "./bookings/route";
import { crmsApplicationsRoute } from "./crms-applications/route";
import { financeRoute } from "./finance/route";
import { groupBookingsRoute } from "./group-bookings/route";

export const eventManagementRoute: ICCRoute = {
  path: "event-management",
  name: "Event Management",
  enum: eProductType.EventManagementSystem,
  children: [
    bookingsRoute,
    groupBookingsRoute,
    crmsApplicationsRoute,
    financeRoute,
    adminRoute,
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
