import { ExistManageEventManagement } from "@app/products/event-management/[id]/components/forms/existed/_index";
import { NewManageEventManagement } from "@app/products/event-management/[id]/components/forms/new/_index";
import { useEventManagementStore } from "@app/products/event-management/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ManageEventManagement = observer(() => {
  const { id } = useParams<{ id: string }>();
  const isNew = useIsNew();
  const { loadEventManagement, resetStore } = useEventManagementStore();

  useEffect(() => {
    if (isNew) return;
    loadEventManagement(parseInt(id), isNew);
  }, [isNew, loadEventManagement, id]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });
  if (isNew) {
    return <NewManageEventManagement />;
  }

  return <ExistManageEventManagement />;
});

export default ManageEventManagement;
