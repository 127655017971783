import {
  DTO_Workflow_DisassociateAssessment,
  RequestObjFromSpatial,
} from "@app/products/property/assessments/components/form-steps/disassociate-assessment/model";
import { DTO_Assessment_LOVs } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postProcessDisassociateAssessment = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_DisassociateAssessment
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_DisassociateAssessment>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/DisassociateAssessment/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postNewWorkflowDisassociateAssessment = async (
  requestObjSpatial: RequestObjFromSpatial | object = {}
): Promise<APIResponse<DTO_Workflow_DisassociateAssessment>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/internal/workflow/DisassociateAssessment/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowDisassociateAssessment = async (
  workflowDraftId: number
): Promise<APIResponse<DTO_Workflow_DisassociateAssessment>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/internal/workflow/DisassociateAssessment/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataAssessment =
  | [
      APIResponse<DTO_Assessment_LOVs>,
      APIResponse<DTO_Workflow_DisassociateAssessment>
    ]
  | APIResponse<DTO_Assessment_LOVs | DTO_Workflow_DisassociateAssessment>
  | undefined;
export const getInitialDataAssessment = async (
  workflowType: WorkflowTypes,
  requestObjSpatial: RequestObjFromSpatial | object,
  workflowDraftId: number | undefined
): Promise<IGetInitialDataAssessment> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<DTO_Assessment_LOVs>(
        `/api/property/internal/workflow/createassessment/lovs?workflowType=${workflowType}`
      ),
      !isNil(workflowDraftId) && workflowDraftId !== 0
        ? getWorkflowDisassociateAssessment(workflowDraftId)
        : postNewWorkflowDisassociateAssessment(requestObjSpatial),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
