import { IParcelLookup } from "@app/products/property/registers/[id]/components/dialogs/associate/model";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const mockParcelLookup: IParcelLookup[] = [
  {
    ParcelId: 654,
    ParcelReference: "65411",
    Address: "66 Frank St, MARYKNOLL, VIC 2111 ",
    Locality: "Ardies Creek",
    MapNumber: 11654,
    MapReference: "MAP1212",
    LegalDescription: "Lot 12 DP17071",
    ElectoralDivision: "North Sydney",
    LandArea: "736.3 m2",
    Name: "McPhie, R&K",
  },
  {
    ParcelId: 835,
    ParcelReference: "83511",
    Address: "94 Amanda, Archies Creek",
    Locality: "Ardies Creek",
    MapNumber: 123444,
    MapReference: "MAP1212",
    LegalDescription: "Lot 12 DP17071",
    ElectoralDivision: "North Sydney",
    LandArea: "736.3 m2",
    Name: "Ben Borzini",
    LandUses: "House",
    Zoning: "R3 Medium Density Residential",
    Status: "Active",
    Owners: "Ben Borzini",
  },
  {
    ParcelId: 901,
    ParcelReference: "90111",
    Address: "94 Amanda, Archies Creek",
    Locality: "Ardies Creek",
    SideOfStreet: "South",
    MapNumber: 123444,
    MapReference: "MAP1212",
    LegalDescription: "Lot 12 DP17071",
    ElectoralDivision: "South",
    LandArea: "736.3 m2",
    Name: "L J GILES 14 Silver St Lysterfield VIC 3156",
  },
];
export const mockDropdownSearchParcel = [
  { Key: 1, Value: "Address" },
  { Key: 2, Value: "Name" },
  { Key: 3, Value: "Legal Description" },
  { Key: 4, Value: "Parcel Id" },
  { Key: 5, Value: "Parcel Reference" },
];
export const mockSearchOptions: IKeyValuePacket[] = [
  { Key: 1, Value: "Keyword" },
  { Key: 2, Value: "Soundex" },
  { Key: 3, Value: "Only Active Entities" },
];
export const mockInitAssociate = {
  ParcelSearchBy: { Key: 4, Value: "Parcel Id" },
};
