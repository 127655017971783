export const mockDOTPositionPSAR = [
  {
    Code: "PSASupported",
    Value: "Supported",
  },
  {
    Code: "PSARSupportedWithChanges",
    Value: "Support With Changes",
  },
  {
    Code: "OBJECTION",
    Value: "Objection",
  },
  {
    Code: "NOOBJECTION",
    Value: "No Objection",
  },
];
