import { VO_Meter } from "@app/products/property/meters/list/model";
import { PROPERTY_METERS_ROUTE } from "@app/products/property/meters/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Meter>();
export const colList: IColumnFields[] = [
  {
    field: nameOf("MeterId"),
    title: "Meter ID",
    locked: true,
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem) => {
      return `${PROPERTY_METERS_ROUTE}/` + dataItem.MeterId;
    },
  },
  {
    field: nameOf("RouteNumber"),
    title: "Route Number",
    format: NUMBER_FORMAT.NUMBER2,
  },

  {
    field: nameOf("ReadingSeqInRoute"),
    title: "Reading Sequence in Route",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("MeterNumber"),
    title: "Meter Number",
  },
  {
    field: nameOf("MasterMeterNumber"),
    title: "Master Meter Number",
  },
  {
    field: nameOf("Category"),
    title: "Category",
  },
  {
    field: nameOf("MeterType"),
    title: "Meter Type",
  },
  {
    field: nameOf("Make"),
    title: "Make",
  },
  {
    field: nameOf("Size"),
    title: "Size",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("UnitOfMeasure"),
    title: "Unit of Measure",
  },
  {
    field: nameOf("PurchaseDate"),
    title: "Purchase Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("InstallationDate"),
    title: "Installation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("RetestDate"),
    title: "Retest Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("NextReadingDate"),
    title: "Next Reading Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ReadingFrequency"),
    title: "Reading Frequency",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: nameOf("Location"),
    title: "Location",
  },
  {
    field: nameOf("KeysRequired"),
    title: "Keys Required?",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("PhoneBeforeRead"),
    title: "Phone Before Read",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("ContactName"),
    title: "Contact Name",
  },
  {
    field: nameOf("ContactPhoneNumber"),
    title: "Contact Phone Number",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("ReadingDeviceName"),
    title: "Reading Device Name",
  },
  {
    field: nameOf("RouteName"),
    title: "Route Name",
  },
];
