import { MapshareSuggestion } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/complex/components/form-elements/address-validation/model";
import { PropertyAddressList } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

export const searchAddressesConfig = {
  typeSpeed: 300,
  minCharacters: 3,
};

const nameOf = nameOfFactory<MapshareSuggestion>();
const nameOfPropertyAddress = nameOfFactory<PropertyAddressList>();

export const colSearchAddresses: IColumnFields[] = [
  {
    field: nameOf("text"),
    title: "Address",
    width: 1000,
  },
];

export const colSearchPropertyAddresses: IColumnFields[] = [
  {
    field: nameOfPropertyAddress("Address"),
    title: "Address",
    width: 200,
  },
  {
    field: nameOfPropertyAddress("LegalDescription"),
    title: "Legal Description",
    width: 200,
  },
  {
    field: nameOfPropertyAddress("OwnersName"),
    title: "Owners",
    width: 150,
  },
  {
    field: nameOfPropertyAddress("StreetNo"),
    title: "Number",
    width: 100,
  },
  {
    field: nameOfPropertyAddress("StreetName"),
    title: "Street",
    width: 100,
  },
  {
    field: nameOfPropertyAddress("Suburb"),
    title: "Locality",
    width: 100,
  },
  {
    field: nameOfPropertyAddress("State"),
    title: "State",
    width: 80,
  },
  {
    field: nameOfPropertyAddress("Postcode"),
    title: "Postcode",
    width: 100,
  },
  {
    field: nameOfPropertyAddress("PropertyName"),
    title: "Property Name",
    width: 100,
  },
  {
    field: nameOfPropertyAddress("ID"),
    title: "ID",
    width: 100,
  },
];
