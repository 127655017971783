import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colCentrelinkConcessionCards } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/centrelink-concession-cards/config";
import { VO_CentreLink_Response } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/child-screens/centrelink-concession-cards/model";
import { getCentreLinkResponseOdataUrl } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/config";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_CentreLink_Response>();
export const CentrelinkConcessionCards = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [processedCols, setProcessedCols] = useState<IColumnFields[]>(
    colCentrelinkConcessionCards
  );

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    let response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_CentrelinkResponse_MatchedRequiresNoFurtherAction,
      colCentrelinkConcessionCards
    );
    setIsLoading(false);
    if (Array.isArray(response)) {
      setProcessedCols(response);
    } else {
      setResponseLoadError(response);
    }
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadViewConfig();
          }}
        />
      ) : (
        <CCGrid
          //OData Filter: CentreLink_Match_Code IN 1, 2, 9
          dataUrl={getCentreLinkResponseOdataUrl()}
          state={{
            filter: {
              logic: "or",
              filters: [
                {
                  field: nameOf("CentreLink_Match_Code"),
                  operator: "eq",
                  value: 1,
                },
                {
                  field: nameOf("CentreLink_Match_Code"),
                  operator: "eq",
                  value: 2,
                },
                {
                  field: nameOf("CentreLink_Match_Code"),
                  operator: "eq",
                  value: 9,
                },
              ],
            },
          }}
          columnFields={processedCols}
          primaryField={nameOf("CentreLink_Response_Id")}
        />
      )}
    </>
  );
});
