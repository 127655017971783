import { getDropdownValue } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroup } from "@progress/kendo-react-inputs";
import React from "react";
import {
  mockCategory,
  mockCountry,
  mockDelivery,
  mockDistinction,
  mockFloorLevelType,
  mockLocality,
  mockPostalDeliveryType,
  mockPropertyName,
  mockState,
  mockStreetName,
  mockStreetType,
  mockTitle,
  mockType,
  mockUnitType,
} from "./mock";

export const PERSON_FORM_STEP = "Person";

export const PersonFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <label className="cc-label">Type</label>
            <Field
              name={nameOf("Type")}
              component={CCSearchComboBox}
              data={mockType}
              textField="Value"
              dataItemKey="Key"
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Category</label>
            <Field
              name={nameOf("Category")}
              textField="Value"
              dataItemKey="Key"
              data={mockCategory}
              value={getDropdownValue(
                getFieldValue("Category"),
                mockCategory,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                formRenderProps.onChange(nameOf("Category"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
              component={CCSearchComboBox}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Title</label>
            <Field
              name={nameOf("Title")}
              textField="Value"
              dataItemKey="Key"
              data={mockTitle}
              value={getDropdownValue(getFieldValue("Title"), mockTitle, "Key")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChange(nameOf("Title"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
              component={CCSearchComboBox}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Given names</label>
            <Field
              name={nameOf("GivenName")}
              component={CCInput}
              placeholder={"Given names"}
            />
          </div>
          <div className="cc-field">
            <label className="cc-label">Surname</label>
            <Field
              name={nameOf("Surname")}
              component={CCInput}
              placeholder={"Surname"}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">Distinction</label>
            <Field
              name={nameOf("Distinction")}
              textField="Value"
              dataItemKey="Key"
              data={mockDistinction}
              value={getDropdownValue(
                getFieldValue("Distinction"),
                mockDistinction,
                "Key"
              )}
              onChange={(event: ComboBoxChangeEvent) => {
                formRenderProps.onChange(nameOf("Distinction"), {
                  value: event.target.value?.Key ?? null,
                });
              }}
              component={CCSearchComboBox}
            />
          </div>
        </div>
      </section>
      <br />
      <section className="cc-field-group">
        <label className="cc-label">Current address</label>
        <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Delivery</label>
              <Field
                name={nameOf("Delivery")}
                component={RadioGroup}
                data={mockDelivery}
                value={getFieldValue("Delivery")}
                layout={"horizontal"}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Care of</label>
              <Field
                name={nameOf("CareOf")}
                component={CCInput}
                placeholder={"Care of"}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Job description</label>
              <Field
                name={nameOf("JobDescription")}
                component={CCInput}
                placeholder={"Job description"}
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Organisation</label>
              <Field
                name={nameOf("Organisation")}
                component={CCInput}
                placeholder={"Organisation"}
              />
            </div>
          </div>
          {(getFieldValue("Delivery") === "Standard" ||
            getFieldValue("Delivery") === "International") && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <label className="cc-label">Property name</label>
                <Field
                  name={nameOf("PropertyName")}
                  textField="Value"
                  dataItemKey="Key"
                  data={mockPropertyName}
                  value={getDropdownValue(
                    getFieldValue("PropertyName"),
                    mockPropertyName,
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    formRenderProps.onChange(nameOf("PropertyName"), {
                      value: event.target.value?.Key ?? null,
                    });
                  }}
                  component={CCSearchComboBox}
                />
              </div>
            </div>
          )}
          {getFieldValue("Delivery") === "International" && (
            <>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Address</label>
                  <Field
                    name={nameOf("Address")}
                    component={CCTextArea}
                    rows={3}
                    placeholder={"Address"}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Country</label>
                  <Field
                    name={nameOf("Country")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockCountry}
                    value={getDropdownValue(
                      getFieldValue("Country"),
                      mockCountry,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("Country"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                    component={CCSearchComboBox}
                  />
                </div>
              </div>
            </>
          )}
          {getFieldValue("Delivery") === "Special" && (
            <div className="cc-form-cols-3">
              <div className="cc-field cc-col-span-2">
                <label className="cc-label">Postal delivery type</label>
                <Field
                  name={nameOf("PostalDeliveryType")}
                  textField="Value"
                  dataItemKey="Key"
                  data={mockPostalDeliveryType}
                  value={getDropdownValue(
                    getFieldValue("PostalDeliveryType"),
                    mockPostalDeliveryType,
                    "Key"
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    formRenderProps.onChange(nameOf("PostalDeliveryType"), {
                      value: event.target.value?.Key ?? null,
                    });
                  }}
                  component={CCSearchComboBox}
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Postal delivery number</label>
                <Field
                  name={nameOf("PostalDeliveryNumber")}
                  component={CCInput}
                  placeholder={"Postal delivery number"}
                />
              </div>
            </div>
          )}
          {getFieldValue("Delivery") === "Standard" && (
            <>
              <div className="cc-form-cols-3">
                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Unit type</label>
                  <Field
                    name={nameOf("UnitType")}
                    data={mockUnitType}
                    textField="Value"
                    dataItemKey="Key"
                    value={getDropdownValue(
                      getFieldValue("UnitType"),
                      mockUnitType,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("UnitType"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                    component={CCSearchComboBox}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Unit number</label>
                  <Field
                    name={nameOf("UnitNumber")}
                    component={CCInput}
                    placeholder={"Unit number"}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field cc-col-span-2">
                  <label className="cc-label">Floor/Level type</label>
                  <Field
                    name={"FloorLevelType"}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockFloorLevelType}
                    value={getDropdownValue(
                      getFieldValue("FloorLevelType"),
                      mockFloorLevelType,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("FloorLevelType"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                    component={CCSearchComboBox}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Floor/Level number</label>
                  <Field
                    name={nameOf("FloorLevelNumber")}
                    component={CCInput}
                    placeholder={"Floor/Level number"}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">House number</label>
                  <Field
                    name={nameOf("HouseNo")}
                    component={CCInput}
                    placeholder={"House number"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Street name</label>
                  <Field
                    name={nameOf("StreetName")}
                    component={CCSearchComboBox}
                    data={mockStreetName}
                    textField="Value"
                    dataItemKey="Key"
                    value={getDropdownValue(
                      getFieldValue("StreetName"),
                      mockStreetName,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("StreetName"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Street type</label>
                  <Field
                    name={nameOf("StreetType")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockStreetType}
                    value={getDropdownValue(
                      getFieldValue("StreetType"),
                      mockStreetType,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("StreetType"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                    component={CCSearchComboBox}
                  />
                </div>
              </div>
            </>
          )}
          {(getFieldValue("Delivery") === "Standard" ||
            getFieldValue("Delivery") === "Special") && (
            <>
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">Additional address</label>
                  <Field
                    name={nameOf("AdditionalAddress")}
                    component={CCTextArea}
                    rows={3}
                    placeholder={"Additional address"}
                  />
                </div>
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Locality</label>
                  <Field
                    name={nameOf("Locality")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockLocality}
                    value={getDropdownValue(
                      getFieldValue("Locality"),
                      mockLocality,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("Locality"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                    component={CCSearchComboBox}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">State</label>
                  <Field
                    name={nameOf("State")}
                    textField="Value"
                    dataItemKey="Key"
                    data={mockState}
                    value={getDropdownValue(
                      getFieldValue("State"),
                      mockState,
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      formRenderProps.onChange(nameOf("State"), {
                        value: event.target.value?.Key ?? null,
                      });
                    }}
                    component={CCSearchComboBox}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Post code</label>
                  <Field
                    name={nameOf("PostCode")}
                    component={CCInput}
                    placeholder={"Post code"}
                  />
                </div>
              </div>
            </>
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <label className="cc-label">Formatted name and address</label>
              <Field
                name={nameOf("AddressFormatted")}
                component={CCTextArea}
                rows={3}
                placeholder={"Formatted name and address"}
              />
            </div>
            {(getFieldValue("Delivery") === "Standard" ||
              getFieldValue("Delivery") === "Special") && (
              <div className="cc-field">
                <label className="cc-label">DPID</label>
                <Field name={"DPID"} component={CCInput} readOnly={true} />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
