import { Svc_Animals_Finance } from "@app/products/animals/finance/model";

export const AnimalFinanceInvoicesNotSentToFinanceBookmark = {
  getBookmarkListViewDisplayName() {
    return "Animal - Finance";
  },

  getBookmarkListViewDetail() {
    return "Animal - Finance - Invoices Not Sent To Finance";
  },

  getBookmarkListViewDetailRecord(selectedRow: Svc_Animals_Finance) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Animal - Invoices Not Sent To Finance ${dynamicDisplayName}`;
  },
};
