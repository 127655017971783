import {
  apiCoreGetFinanceFee,
  apiCoreSaveFinanceFee,
} from "@app/core/components/common/utils";
import { Fee, Svc_FinanceFee } from "@app/core/fees/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";

export const getFinanceFeesById = async (
  id: number,
  parentID?: string,
  amount?: string
): Promise<APIResponse<IIdentityPacket<Svc_FinanceFee | undefined>>> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_FinanceFee>
    >(apiCoreGetFinanceFee(id, parentID, amount));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSaveFinanceFee = async (
  data: Fee
): Promise<APIResponse<IdentityPacket>> => {
  try {
    const response = await CoreAPIService.getClient().post<IdentityPacket>(
      apiCoreSaveFinanceFee(),
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
