import { FormIdentifier, ViewActionButton } from "@app/products/crms/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { CORE_VIEW_ACTION_PERMISSION_URL } from "@common/constants/core-common-constants";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";

export const getActionButtonPermission = async (
  formIdentifier: FormIdentifier,
  productType: PRODUCT_TYPE_NUMBER
): Promise<APIResponse<ViewActionButton[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<ViewActionButton[]>(
      `${CORE_VIEW_ACTION_PERMISSION_URL}?formIdentifier=${formIdentifier}&productType=${productType}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
