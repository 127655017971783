import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { DTO_ChargeRun_PIC_TabDetails } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/reference-sidebar/detail/model";
import { useParams } from "react-router-dom";
import { getPICChargeRunDetailTabId } from "@app/products/property/charge-and-notice-runs/pic-charge-runs/[id]/components/reference-sidebar/detail/api";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { APIResponseError } from "@common/apis/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";

export const ChargeAndNoticeRunsDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [picChargeRunInfo, setPICChargeRunInfo] = useState<
    DTO_ChargeRun_PIC_TabDetails | undefined
  >();
  const { lastSelectedId } = useCCProductListViewStore();
  const { id } = useParams<{ id: string }>();
  const picChargeRunId: number = useMemo(() => {
    return id ? +id : lastSelectedId;
  }, [lastSelectedId, id]);

  const loadPICChargeRunDetailTabId = async (picChargeRunId: number) => {
    let errorResponse;
    setIsLoading(true);
    const response = await getPICChargeRunDetailTabId(picChargeRunId);
    if (isSuccessResponse(response)) {
      setPICChargeRunInfo(response?.data);
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    setResponseLoadError(errorResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    if (picChargeRunId) {
      loadPICChargeRunDetailTabId(picChargeRunId);
    }
  }, [picChargeRunId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError) {
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadPICChargeRunDetailTabId(picChargeRunId);
        }}
      />
    );
  }
  if (!picChargeRunInfo) return <NoData />;

  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Number of PIC's:"}
              value={picChargeRunInfo?.NumberOfPICs + ""}
              primary
            />
          }
        >
          <ProductReferenceBlock>
            <ProductReferenceRow
              title={"Number of Charges:"}
              value={picChargeRunInfo?.NumberOfCharges ?? ""}
            />
            <ProductReferenceRow
              title={"Total Amount:"}
              value={formatDisplayValue(
                picChargeRunInfo?.TotalAmount,
                CURRENCY_FORMAT.CURRENCY1
              )}
              format="currency"
            />
          </ProductReferenceBlock>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
