import { AnimalsRegister } from "@app/products/animals/model";
import { PickAnimalsLookupDialog } from "@app/products/crms/components/dialogs/pick-animals-lookup/_index";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

export interface AddAnimalLookupsButtonProps {
  onSubmit: (data: AnimalsRegister[]) => void;
}

export const AddAnimalsLookupButton = ({
  onSubmit,
}: AddAnimalLookupsButtonProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleAddAnimalsLookup = (data: AnimalsRegister[]) => {
    setIsShowDialog(false);
    onSubmit(data);
  };

  return (
    <>
      <Button
        type="button"
        iconClass="fas fa-plus"
        title="Add"
        onClick={() => setIsShowDialog(true)}
      />

      {isShowDialog && (
        <PickAnimalsLookupDialog
          onCloseDialog={() => setIsShowDialog(false)}
          onSubmit={handleAddAnimalsLookup}
        />
      )}
    </>
  );
};
