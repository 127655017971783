import { eventEmitter } from "@/App";
import { ITEMS_ACCORDION_GRID_ID } from "@app/products/property/journals/[id]/components/child-screens/items/constant";
import { ModifyTransactionDialog } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/modify-transaction/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IModifyTransactionButtonProps {
  disabled?: boolean;
  nameForTransactionId?: string;
}

export const ModifyTransactionButton = observer(
  ({
    disabled = false,
    nameForTransactionId,
  }: IModifyTransactionButtonProps) => {
    const [showModifyDialog, setShowModifyDialog] = useState<boolean>(false);

    return (
      <>
        <CCNavButton
          title="Modify"
          disabled={disabled}
          onClick={() => {
            setShowModifyDialog(true);
          }}
        />
        {showModifyDialog && (
          <ModifyTransactionDialog
            onClose={() => {
              setShowModifyDialog(false);
            }}
            onSubmit={() => {
              setShowModifyDialog(false);
              eventEmitter.emit(CCGridEventType.RefreshOData, {
                gridIds: [ITEMS_ACCORDION_GRID_ID],
              });
            }}
            nameForTransactionId={nameForTransactionId}
          />
        )}
      </>
    );
  }
);
