import { isErrorFilter } from "@common/utils/grid";
import { IColumnFields } from "@components/cc-grid/model";
import { getFilterType } from "@components/cc-grid/util";
import { getColumnDataType } from "@components/cc-product-list-view/components/list-view-filter-bar/config";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  FieldSettings,
  Filter,
  FilterChangeEvent,
} from "@progress/kendo-react-data-tools";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import "./_index.scss";

export interface IListViewFilterBarProps {
  columns: IColumnFields[];
  filter?: CompositeFilterDescriptor;
  onChangeFilter: (filter: CompositeFilterDescriptor) => void;
  setFilterBarState: (filter: CompositeFilterDescriptor) => void;
}

export const ListViewFilterBar = ({
  columns,
  filter,
  onChangeFilter,
  setFilterBarState,
}: IListViewFilterBarProps) => {
  const [filterState, setFilterState] = useState<CompositeFilterDescriptor>({
    logic: "and",
    filters: [],
  });
  const [fields, setFields] = useState<FieldSettings[]>([]);
  useEffect(() => {
    if (filter && !isEqual(filter, filterState)) {
      setFilterState(filter);
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    let columnFieldsClone = columns.map((item: IColumnFields) =>
      Object.assign({}, item)
    );
    let columnDataClone: any = [];
    columnFieldsClone.forEach((item: any) => {
      columnDataClone.push({
        name: item.field,
        label: item.title,
        ...getColumnDataType(getFilterType(item), item),
      });
    });
    setFields(columnDataClone);
  }, [columns]);

  const isDisableFilter = useMemo((): boolean => {
    return isErrorFilter(filterState);
  }, [filterState]);

  return (
    <div className="cc-list-view-filter">
      <div className="cc-list-view-filter-header">
        <Button
          className="k-primary"
          onClick={() => {
            onChangeFilter(filterState);
          }}
          disabled={isDisableFilter}
        >
          Filter
        </Button>

        <Button
          className="k-text-primary"
          onClick={() => {
            onChangeFilter({
              logic: "and",
              filters: [],
            });
          }}
          iconClass="fas fa-eraser"
        >
          Clear all
        </Button>
      </div>
      <div className="cc-list-view-filter-body">
        <Filter
          value={filterState}
          onChange={(event: FilterChangeEvent) => {
            setFilterBarState(event.filter);
            setFilterState(event.filter);
          }}
          fields={fields}
        />
      </div>
    </div>
  );
};
