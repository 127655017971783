export const reportsRouteStateGlobalSettings = [
  {
    name: "Reports_ReportsSettings",
    component: require("./forms/reports-settings").default,
  },
  {
    name: "Reports_CustomTimePeriodFields",
    component: require("./forms/custom-time-period-fields").default,
  },
];
