import { loadMeterDetailTabById } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/api";
import { IReferenceSideBarDetails } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/model";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const MeterDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [meterInfo, setMeterInfo] = useState<IReferenceSideBarDetails>();
  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const meterId = params.id || lastSelectedRow.MeterId;
  useEffect(() => {
    setIsLoading(true);
    if (!meterId) {
      setIsLoading(false);
      return;
    }
    loadMeterDetailTabById(meterId).then((data) => {
      if (!data) return;
      setMeterInfo(data);
      setIsLoading(false);
    });
  }, [meterId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  return (
    <ProductReferenceBody>
      <ProductReferenceBlock>
        <ProductReferenceRow title={"Meter Id:"} value={meterInfo?.MeterId} />
        <ProductReferenceRow
          title={"Meter Number:"}
          value={meterInfo?.MeterNumber}
        />
        <ProductReferenceRow
          title={"Property Address:"}
          value={meterInfo?.PropertyAddress}
        />
        <ProductReferenceRow
          title={"Account Names:"}
          value={meterInfo?.AccountNames}
        />
        <ProductReferenceRow title={"Status:"} value={meterInfo?.Status} />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
