import {
  apiCoreSaveBatchRecall,
  apiCoreUpdateBatchRecall,
} from "@app/core/components/common/utils";
import {
  Svc_BatchRecallPacket,
  Svc_BatchRecallRequest,
  Svc_BatchRecallUpdateRequest,
} from "@app/core/recall/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { CancelToken } from "axios";

export const postSaveRecallsInBatch = async (
  data: Svc_BatchRecallRequest,
  cancelToken?: CancelToken
): Promise<APIResponse<Svc_BatchRecallPacket>> => {
  try {
    return await CoreAPIService.getClient().post<Svc_BatchRecallPacket>(
      apiCoreSaveBatchRecall(),
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postUpdateRecallsInBatch = async (
  data: Svc_BatchRecallUpdateRequest,
  cancelToken?: CancelToken
): Promise<APIResponse<Svc_BatchRecallPacket>> => {
  try {
    return await CoreAPIService.getClient().post<Svc_BatchRecallPacket>(
      apiCoreUpdateBatchRecall(),
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
