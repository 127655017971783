import {
  eOptionsAssessment,
  IPopulateCandidateLandRentDataDialog,
} from "@app/products/property/assessments/compliance/candidates-land-rent/model";
import { configure, makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ComplianceCandidateLandStore {
  private _isLoading: boolean = false;
  private _isOpenCandidateLandDialog: boolean = false;
  private _populateCandidateLandData?: IPopulateCandidateLandRentDataDialog =
    undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isOpenCandidateLandDialog() {
    return this._isOpenCandidateLandDialog;
  }
  setIsOpenCandidateLandDialog = (isOpenCandidateLandDialog: boolean) => {
    runInAction(() => {
      this._isOpenCandidateLandDialog = isOpenCandidateLandDialog;
    });
  };

  get populateCandidateLandData() {
    return this._populateCandidateLandData;
  }
  setPopulateCandidateLandData = (
    populateCandidateLandData?: IPopulateCandidateLandRentDataDialog
  ) => {
    runInAction(() => {
      this._populateCandidateLandData = populateCandidateLandData;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isOpenCandidateLandDialog = false;
      this._populateCandidateLandData = undefined;
    });
  };

  loadCandidateLand = async (
    FromAssessment?: string | undefined,
    ToAssessment?: string | undefined,
    Assessment?: eOptionsAssessment
  ) => {
    this.setPopulateCandidateLandData({
      FromAssessment,
      ToAssessment,
      Assessment,
    });
    this.setIsOpenCandidateLandDialog(false);
  };
}

const complianceCandidateLandStoreContext = createContext(
  new ComplianceCandidateLandStore()
);

export const useComplianceCandidateLandStore = () => {
  return useContext(complianceCandidateLandStoreContext);
};
