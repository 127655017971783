import {
  decryptionHtmlForEditor,
  encryptionHtmlForEditor,
} from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { EditorUtils } from "@progress/kendo-react-editor";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import * as React from "react";
import { useEffect, useState } from "react";
import "./index.scss";

export const CCEditHTMLTool = (props: any) => {
  const { view } = props;
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [initialValues, setInitialValues] = useState<{ contentHTML: string }>();

  useEffect(() => {
    if (view?.state) {
      const editorContent = EditorUtils.getHtml(view?.state) ?? "";
      setInitialValues({
        contentHTML: decryptionHtmlForEditor(editorContent), // remove decryptionHtmlForEditor function if you want to debug.
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <Button
        onClick={() => setIsShowDialog(true)}
        icon="k-i-html k-i-source-code k-i-view-source"
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
      />
      {isShowDialog && (
        <Form
          initialValues={initialValues}
          render={({ valueGetter }: FormRenderProps) => {
            const handleUpdateHTML = () => {
              EditorUtils.setHtml(
                view,
                encryptionHtmlForEditor(valueGetter("contentHTML") ?? "")
              );
              setIsShowDialog(false);
            };
            return (
              <CCDialog
                dialogWrapperClassName="cc-form-view-html"
                maxWidth="600px"
                maxHeight="500px"
                titleHeader={"View HTML"}
                onClose={() => setIsShowDialog(false)}
                bodyElement={
                  <FormElement>
                    <Field
                      className="cc-textarea-view-html"
                      name={"contentHTML"}
                      component={CCTextArea}
                      autoSize
                    />
                  </FormElement>
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className="cc-dialog-button"
                      onClick={() => setIsShowDialog(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      themeColor="primary"
                      className="cc-dialog-button"
                      type={"submit"}
                      onClick={handleUpdateHTML}
                    >
                      Update
                    </Button>
                  </div>
                }
              />
            );
          }}
        />
      )}
    </>
  );
};
