import { Svc_AddContactRelation } from "@app/core/contacts/components/dialogs/additional-contact/model";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { IdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";

export const postContactRelationship = async (
  contactRelation: Svc_AddContactRelation
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket | undefined>(
      `/api/core/internal/contact-relationships/additional-contacts`,
      contactRelation
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getContactRelationshipType = async (
  relationshipFilters: ContactRelationshipType[]
): Promise<APIResponse<IKeyValuePacket[] | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IKeyValuePacket[] | undefined>(
      `/api/core/internal/contactrelationshiptypes`,
      {
        params: {
          relationshipFilters,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
