import { sleep } from "@common/utils/common";

export const postSendVerify = async (code: string) => {
  await sleep(2000);
  if (code !== "123")
    return {
      data: { Errors: "Failed to verify officer authorisation." },
      status: 500,
    };
  return {
    data: {},
    status: 200,
  };
};
