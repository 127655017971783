import {
  DTO_NoticeRun_LOVs,
  DTO_NoticeRun_Summary,
} from "@app/products/property/charge-and-notice-runs/notice-runs/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";

export const getNoticeRunsById = async (
  id: number
): Promise<APIResponse<DTO_NoticeRun_Summary>> => {
  try {
    return CoreAPIService.getClient().get<DTO_NoticeRun_Summary>(
      `/api/property/int/noticerun/${id}/summary`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getNoticeRunsLovs = async (): Promise<
  APIResponse<DTO_NoticeRun_LOVs>
> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/noticerun/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
