import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponseStatus } from "@common/constants/response-status";
import { DTO_Component_Certificates } from "./model";

export const loadAssessmentCertificate = async (
  assessmentId: number
): Promise<DTO_Component_Certificates | undefined> => {
  try {
    const response =
      await CoreAPIService.getClient().get<DTO_Component_Certificates>(
        `/api/property/internal/assessment/${assessmentId}/certificates`
      );
    return response.status === APIResponseStatus.SUCCESS
      ? response.data
      : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};
