import { ILocalGovernment } from "@common/pages/settings/lookups/local-government/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ILocalGovernment>();

export const colCouncilPicker: IColumnFields[] = [
  { title: "Name", field: nameOf("Name") },
  {
    title: "Officer",
    field: nameOf("Officer"),
  },
  {
    title: "Address",
    field: nameOf("CouncilAddress"),
  },
];
