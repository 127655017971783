import { getContact } from "@app/core/contacts/_id/api";
import { getAnimalLOVs } from "@app/products/animals/[id]/api";
import { AnimalsLOVs } from "@app/products/animals/[id]/model";
import { AnimalType } from "@app/products/animals/model";
import { getAnimalTypeByID } from "@app/products/animals/system-admin/animal-types/[id]/api";
import { isSuccessResponse } from "@common/apis/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { isEmpty, isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
const lblAnimalRegisteredWhenCollected =
  "Was the animal registered in this council when found";

class CRMSEventCaseDetailsStore {
  private _animalLOVs?: AnimalsLOVs = undefined;
  private _breedLOVs?: IKeyValuePacket[] = [];
  private _animalTypeId?: number = undefined;
  private _isLoadingAnimalWandering: boolean = false;
  private _animalType?: AnimalType = undefined;
  private _councilContact: string = lblAnimalRegisteredWhenCollected;

  constructor() {
    makeAutoObservable(this);
  }
  get animalLOVs() {
    return toJS(this._animalLOVs);
  }
  setAnimalLOVs = (animalLOVs?: AnimalsLOVs) => {
    runInAction(() => {
      this._animalLOVs = animalLOVs;
    });
  };

  get breedLOVs() {
    return toJS(this._breedLOVs);
  }
  setBreedLOVs = (breedLOVs: IKeyValuePacket[]) => {
    runInAction(() => {
      this._breedLOVs = breedLOVs;
    });
  };

  get animalTypeId() {
    return toJS(this._animalTypeId);
  }
  setAnimalTypeId = (animalTypeId: number) => {
    runInAction(() => {
      this._animalTypeId = animalTypeId;
    });
  };

  get animalType() {
    return toJS(this._animalType);
  }
  setAnimalType = (animalType?: AnimalType) => {
    runInAction(() => {
      this._animalType = animalType;
    });
  };

  get councilContact() {
    return toJS(this._councilContact);
  }
  setCouncilContact = (councilContact: string) => {
    runInAction(() => {
      this._councilContact = councilContact;
    });
  };

  get isLoadingAnimalWandering() {
    return toJS(this._isLoadingAnimalWandering);
  }
  setIsLoadingAnimalWandering = (isLoadingAnimalWandering: boolean) => {
    runInAction(() => {
      this._isLoadingAnimalWandering = isLoadingAnimalWandering;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._animalLOVs = undefined;
      this._breedLOVs = [];
      this._animalTypeId = undefined;
      this._animalType = undefined;
      this._councilContact = lblAnimalRegisteredWhenCollected;
      this._isLoadingAnimalWandering = false;
    });
  };

  loadAnimalLOVs = async () => {
    if (!isNil(this.animalLOVs)) return;
    const response = await getAnimalLOVs();
    if (isSuccessResponse(response)) {
      this.setAnimalLOVs(response?.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Load animal lovs failed.",
        type: "error",
      });
    }
  };

  loadCouncilDetails = async (councilDetailsID: number) => {
    if (this.councilContact !== lblAnimalRegisteredWhenCollected) return;
    const response = await getContact(councilDetailsID);
    if (isSuccessResponse(response)) {
      const councilContact = response?.data;
      if (councilContact && !isEmpty(councilContact?.DisplayName)) {
        var newLblAnimalRegisteredWhenCollected =
          lblAnimalRegisteredWhenCollected.replace(
            "this council",
            councilContact.DisplayName.trim()
          );
        this.setCouncilContact(newLblAnimalRegisteredWhenCollected);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Load council display failed.",
        type: "error",
      });
    }
  };

  populateBreeds = async (animalTypeId?: number) => {
    if (!isNil(animalTypeId)) {
      const response = await getAnimalTypeByID(animalTypeId);
      if (isSuccessResponse(response) && response?.data) {
        const newAnimalType = response.data;
        this.setAnimalTypeId(animalTypeId);
        this.setAnimalType(newAnimalType);

        if (animalTypeId && newAnimalType) {
          if (
            newAnimalType?.Name &&
            newAnimalType?.Name.toLocaleLowerCase().includes("cat")
          ) {
            this.setBreedLOVs(this.animalLOVs?.Animals_CatBreeds ?? []);
          } else if (
            newAnimalType?.Name &&
            newAnimalType?.Name.toLocaleLowerCase().includes("dog")
          ) {
            this.setBreedLOVs(this.animalLOVs?.Animals_DogBreeds ?? []);
          } else {
            this.setBreedLOVs(this.animalLOVs?.Animals_OtherBreeds ?? []);
          }
        }
      } else {
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Load animal type failed.",
          type: "error",
        });
      }
    }
  };
}

const crmsEventCaseDetailsStoreContext = createContext(
  new CRMSEventCaseDetailsStore()
);
export const useCRMSEventCaseDetailsStore = () => {
  return useContext(crmsEventCaseDetailsStoreContext);
};
