import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  formatStyleProminent,
  formatStyleUnRelevant,
} from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

export const colSchemesAccountInstalments: IColumnFields[] = [
  {
    field: "Instalment",
    title: "Instalment",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "DueDate",
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "InstalmentNumber",
    title: "Instalment Number",
    format: NUMBER_FORMAT.NUMBER1,
  },
  {
    field: "InstalmentAmount",
    title: "Instalment Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("InstalmentAmount", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "InstalmentBalance",
    title: "Instalment Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("InstalmentBalance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "PaymentReceivedDate",
    title: "Payment Received Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "PrincipalIncludesOther",
    title: "Principal (Includes Other)",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: "PrincipalBalance",
    title: "Principal Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("PrincipalBalance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "InterestBalance",
    title: "Interest Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("InterestBalance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "OtherBalance",
    title: "Other Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField("OtherBalance", gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: "Id",
    title: "ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
