import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";

export const TabMap = () => {
  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">GIS (latitude, longitude)</label>
            <Input placeholder={"GIS (latitude, longitude)"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map number</label>
            <Input placeholder={"Map number"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Map reference</label>
            <Input placeholder={"Map reference"} />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">PFI</label>
            <Input placeholder={"PFI"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Postal delivery</label>
            <Input placeholder={"Postal delivery"} />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">GIS (latitude)</label>
            <Input placeholder={"GIS (latitude)"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">GIS (longitude)</label>
            <Input placeholder={"GIS (longitude)"} />
          </div>
        </div>
      </div>
      <div className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street map reference</label>
            <Input placeholder={"Street map reference"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street map number</label>
            <Input placeholder={"Street map number"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street PFI</label>
            <Input placeholder={"Street PFI"} />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street even start</label>
            <NumericTextBox placeholder={"Street even start"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street even end</label>
            <NumericTextBox placeholder={"Street even end"} />
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Street odd start</label>
            <NumericTextBox placeholder={"Street odd start"} />
          </div>
          <div className="cc-field">
            <label className="cc-label">Street odd end</label>
            <NumericTextBox placeholder={"Street odd end"} />
          </div>
        </div>
      </div>
    </div>
  );
};
