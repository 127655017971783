import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { IInspection } from "@app/core/inspections/inspections-accordion/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<IInspection>();
export const colInspections: IColumnFields[] = [
  {
    field: nameOf("DateInspected"),
    title: "Inspected",
    locked: true,
    format: DATE_FORMAT.DATE,
    linkTo: (dataItem: IInspection) => {
      return `${INSPECTIONS_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  {
    field: nameOf("InspectionType"),
    title: "Type",
  },
  {
    field: nameOf("Compliance"),
    title: "Complied",
  },
  {
    field: nameOf("Reason"),
    title: "Reason",
  },
  {
    field: nameOf("Officer"),
    title: "Officer",
  },
  {
    field: nameOf("ReferenceNumber"),
    title: "Reference Number",
  },
];
