import { ICCRoute } from "@common/constants/ICCRoute";

export const eBuildingRoute: ICCRoute = {
  path: "e-Building",
  name: "eBuilding",
  children: [
    {
      path: "new-applications",
      name: "New Applications",
      component: require("./new-applications/_index").default,
    },
  ],
};
